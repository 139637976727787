import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/core/services/data.service';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, RecentAgent, RecentLead, UserType } from 'src/app/models';
import { AlertModel } from 'src/app/models/alert.model';
import { ReleaseNotes } from 'src/app/models/release-notes.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { HeaderMenuItem } from './models/header-menu.model';
import { Subscription } from 'rxjs';
import { FirebaseServicesService } from 'src/app/services/firebase';
import { ChannelService } from 'src/app/services/channel.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent extends ApplicationContextBoundComponent {

  @Input()
  user = {};

  menu: HeaderMenuItem[] = [];

  config;

  @Input()
  unreadConversations: boolean;

  @Output()
  mentionsClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  niceInContactClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  notificationsClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  dialpadClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  alertsCreated: EventEmitter<AlertModel[]> = new EventEmitter<AlertModel[]>();

  @Output()
  conversationsToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  newLeadClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  newAgentClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  newContactClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  newApplicationClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  viewLeadClicked: EventEmitter<RecentLead> = new EventEmitter<RecentLead>();

  @Output()
  viewAgentClicked: EventEmitter<RecentAgent> = new EventEmitter<RecentAgent>();

  releaseNotes: ReleaseNotes;
  companyId: number;
  userIsImpersonating: boolean;
  companyName: string;

  private _status: boolean = false;

  private _versionUpdateSubscription: Subscription;
  private _contextChangesSubscription: Subscription;

  constructor(
    private readonly _firebaseServicesService: FirebaseServicesService,
    private dataService: DataService,
    private readonly injector: Injector,
    private readonly _router: Router,
    private readonly _localStorageService: LocalStorageService,
    private readonly _navigationService: NavigationService,
    private readonly _channelService: ChannelService
  ) {
    super(injector);
    this._contextChangesSubscription = this.applicationContextService.changes.subscribe(context => {
      this.updateRecentlyVisitedList(context);
    });
    this._versionUpdateSubscription = this.applicationContextService.applicationVersionUpdated.subscribe(releaseNotes => {
      this.releaseNotes = releaseNotes;
    })
  }

  ngOnInit() {
    this.companyId = this.applicationContext.userPermissions.companyId;
    this.userIsImpersonating = this.applicationContext.userPermissions.superAdmin && this.applicationContext.userPermissions.companyId != 1;
    this.companyName = this.applicationContext.globalConfig.company.find(x => x.companyId == this.applicationContext.userPermissions.companyId)?.companyName;
    this._status = false;
    document.getElementById('navigation').style.left = '-225px';
    const url = this._router.url;


    this.dataService.getRelativePath('./assets/app-data.json').subscribe((res) => {
      this.menu = res.menu;
      if (this.companyId == 172 && this.menu[0].children.length > 3) {
        var menuItem = this.menu[0].children.splice(3, 1);
        this.menu[0].children.unshift(...menuItem);
      }
      this.selectItem(url, this.menu);
      this.config = res.app;

      this.removeExternalCompanyManagementMenuItemIfOnlyRetail();

      if (this.applicationContext && !this.applicationContext.userPermissions.superAdmin) {
        this.updateRecentlyVisitedList(this.applicationContext);
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._contextChangesSubscription) {
      this._contextChangesSubscription.unsubscribe();
    }
    if (this._versionUpdateSubscription) {
      this._versionUpdateSubscription.unsubscribe();
    }
  }

  onDismissVersionUpdateNoticeClicked = () => {
    const version = this.releaseNotes.version;
    this.releaseNotes = null;
    this._firebaseServicesService.viewReleaseNotes(version.toString()).subscribe({
      next: () => {
      },
      error: () => { }
    })
  }

  onAlertsCreated = (alerts: AlertModel[]) => {
    this.alertsCreated.emit(alerts);
  }

  onNotificationsClicked = (notifications: any) => {
    this.notificationsClicked.emit(notifications);
  }

  onMentionsClicked = (open: boolean) => {
    this.mentionsClicked.emit(open);
  }

  onNiceInContactClicked = (activeTab: string) => {
    this.niceInContactClicked.emit(activeTab);
  }

  onConversationsToggled = (open: boolean) => {
    this.conversationsToggled.emit(open);
  }

  onDialpadClicked = () => {
    this.dialpadClicked.emit();
  }

  toggleNav() {
    this._status = !this._status;
    if (this._status) {
      document.getElementById('navigation').style.left = '0px';
    } else {
      document.getElementById('navigation').style.left = '-225px';
    }
  }

  onMenuItemClicked = (menuItem: HeaderMenuItem) => {
    if (!menuItem.children?.length) {
      this.toggleNav();
    }
  }

  onNewLeadClicked = () => {
    this.newLeadClicked.emit();
  }

  onNewAgentClicked = () => {
    this.newAgentClicked.emit();
  }

  onNewContactClicked = () => {
    this.newContactClicked.emit();
  }

  onNewApplicationClicked = () => {
    this.newApplicationClicked.emit();
  }

  onViewLeadClicked = (lead: RecentLead) => {
    this.viewLeadClicked.emit(lead);
  }

  onViewAgentClicked = (agent: RecentAgent) => {
    this.viewAgentClicked.emit(agent);
  }

  onStopImpersonatingClicked = () => {

    var companyGuid = this.applicationContext.globalConfig.company.find(x => x.companyId == 1)?.companyGUID;
    if (companyGuid) {
      const companyToGoTo = this.applicationContext.currentlyLoggedInUser.allUserCompanyData.find((c) => c.companyGuid === companyGuid && c.userType === UserType.Admin);
      if (companyToGoTo) {
        this._navigationService.navigateToCompanySwitchUrl(companyToGoTo);
      }
    }
  }

  private removeExternalCompanyManagementMenuItemIfOnlyRetail = () => {
    if (this.applicationContext && this.applicationContext.userPermissions.enabledChannels !== "Disabled") {
      const enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(this.applicationContext.userPermissions.enabledChannels);
      if (enabledChannels.length === 1 && enabledChannels[0].value.trim().toLowerCase() === 'retail') {
        const adminMenuItem = this.menu.find(mi => mi.text.trim().toLowerCase() === 'admin');
        if (adminMenuItem) {
          const externalCompanyManagementMenuItemIndex = adminMenuItem.children?.findIndex(mi => mi.text.trim().toLowerCase() === 'external company management');
          if (externalCompanyManagementMenuItemIndex >= 0) {
            adminMenuItem.children?.splice(externalCompanyManagementMenuItemIndex, 1);
          }
        }
      }
    }
  }

  private updateRecentlyVisitedList = (context: ApplicationContext) => {
    const recentMenuItems: HeaderMenuItem[] = [];
    if (context != null) {
      const { recentApplications, recentLeads, recentAgents } = context.userPermissions;

      if (recentApplications || recentLeads || recentAgents) {
        if (recentApplications?.length) {
          const recentAppChildren = recentApplications.map(app => new HeaderMenuItem(null, "fas fa-folder", app.displayText, `app-details/${app.applicationId}`));
          const recentAppItem = new HeaderMenuItem(recentAppChildren, 'fas fa-folder', 'Recent Apps', null);

          recentMenuItems.push(recentAppItem)
        }

        if (recentLeads?.length) {
          const recentLeadsChildren = recentLeads.map(lead => new HeaderMenuItem(null, "fas fa-flag", Utils.getPersonsDisplayName(lead) || "<Missing Borrower Name>", null, null, 'openLeadDrawer', { lead: lead }));
          const recentLeadItem = new HeaderMenuItem(recentLeadsChildren, 'fas fa-flag', 'Recent Leads', null);

          recentMenuItems.push(recentLeadItem)
        }

        if (recentAgents?.length) {
          const recentAgentsChildren = recentAgents.map(agent => new HeaderMenuItem(null, "fas fa-user-tie", Utils.getPersonsDisplayName(agent), null, null, 'openAgentDrawer', { agent: agent }));
          const recentAgentItem = new HeaderMenuItem(recentAgentsChildren, 'fas fa-user-tie', 'Recent Agents', null);

          recentMenuItems.push(recentAgentItem)
        }

        const recentlyVisited = new HeaderMenuItem(recentMenuItems, "fas fa-star", "Recently Visited", null);
        const existingOne = this.menu.find(mi => mi.text === 'Recently Visited');
        if (!existingOne) {
          this.menu.push(recentlyVisited);
        } else {
          existingOne.children = recentlyVisited.children;
        }
      }

      if (!context.userPermissions.showNewSubmissionForm && !context.userPermissions.superAdmin) {
        const newMenuIndex = this.menu.findIndex(m => m.text == 'New');
        const newApplicationIndex = this.menu[newMenuIndex].children.findIndex(ch => ch.text == 'New Application');
        this.menu[newMenuIndex].children[newApplicationIndex].hide = true;
      }
    }
  }

  private selectItem = (url: string, elements: HeaderMenuItem[]) => {
    if (!elements) return;

    const item = elements.find(item => url.indexOf(item.link) > -1);
    if (item) {
      item.selected = true;
      return;
    }

    if (!item) {
      elements.forEach(el => {
        if (!el.children?.length) {
          return;
        }
        this.selectItem(url, el.children);
      })
    }
  }
}
