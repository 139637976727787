import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { of, Subscription } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { Borrower, LoanApplication, SendSms } from 'src/app/models';
import { Message } from 'src/app/models/message.model';
import { AgentFull } from 'src/app/modules/app-details/models/agent.model';
import { BorrowerFull } from 'src/app/modules/app-details/models/full-borrower.model';
import { ChatService } from 'src/app/services/chat.service';
import { EmailTemplate } from 'src/app/modules/correspondence/models/email-template.model';
import { CorrespondenceService } from 'src/app/modules/correspondence/services/correspondence.service';
import { RecordType } from 'src/app/modules/dialer/models/dial-list-record-basic.model';
import { PhoneType } from 'src/app/modules/dialer/models/phone-type.model';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from '../application-context-bound.component';
import { CommonSendEmailComponent, EmailInfo } from '../common-send-email/send-email.component';
import { CommonSendSmsComponent, SmsInfo, SmsRecipient } from '../common-send-sms/send-sms.component';

@Component({
  selector: 'common-send-email-sms',
  templateUrl: './send-email-sms.component.html',
  styleUrls: ['./send-email-sms.component.scss']
})
export class CommonSendEmailSmsComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild("commonEmailSender")
  commonEmailSender: CommonSendEmailComponent;

  @ViewChild("commonSmsSender")
  commonSmsSender: CommonSendSmsComponent;

  @Input() smsRecipients: SmsRecipient[] = [];
  @Input() smsToPhoneNumber: string;

  @Input() emailTemplates: EmailTemplate[] = [];
  @Input() emailBody: string = "";
  @Input() emailSubject: string;
  @Input() emailTo: string;

  @Input() recordType: RecordType;
  @Input() recordId: number;
  @Input() record: Lead | BorrowerFull | AgentFull | LoanApplication;

  @Input() showEmailTemplates: boolean = true;

  @Output() emailSent = new EventEmitter<EmailInfo>();
  @Output() smsSent = new EventEmitter<SendSms>();

  sendEmail: boolean = true;

  private allBorrowers: Borrower[] = [];
  private userId: string;
  private userPhoneNumber: string;

  private _applicationContextSubscription: Subscription;

  constructor(
    private readonly _spinner: NgxSpinnerService,
    private readonly _correspondenceService: CorrespondenceService,
    private readonly _chatService: ChatService,
    private readonly _notificationService: NotificationService,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {

    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      this.userId = context.userPermissions.userId;
      this.userPhoneNumber = context.currentlyLoggedInUserProfile.telephonyService.fromPhoneNumber;
      this.allBorrowers = context.borrowers;

      this.getEmailTemplates();
      this.setSmsRecipients();
      this.setEmailToAdresses();
      this.setSmsToPhoneNumbers();
    })
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
  }

  getEmailTemplates = () => {
    if (this.emailTemplates.length == 0) {

      let observable;
      if (this.recordType == RecordType.Lead) {
        observable = this._correspondenceService.getLeadEmailTemplates();
      }
      if (this.recordType == RecordType.Application) {
        observable = this._correspondenceService.getLoanEmailTemplates(this.userId);
      }
      else {
        observable = of([]);
      }

      observable.subscribe(templates => {
        this.emailTemplates = templates;
      })
    }
  }

  setSmsRecipients = () => {

    if (this.smsRecipients.length == 0 && this.record) {

      if (this.recordType == RecordType.Lead) {
        this.addLeadSmsRecipient();
      } else if (this.recordType == RecordType.Borrower) {
        this.addBorrowerSmsRecipient((this.record as BorrowerFull).borrower);
      }
      else if (this.recordType == RecordType.Agent) {
        this.addAgentSmsRecipient();
      }
      else if (this.recordType == RecordType.Application) {
        this.addLoanSmsRecipient();
      }
    }
  }

  setEmailToAdresses = () => {
    if (!this.emailTo) {
      if (this.recordType == RecordType.Lead) {
        this.emailTo = (this.record as Lead).email;
      } else if (this.recordType == RecordType.Borrower) {
        this.emailTo = (this.record as BorrowerFull).borrower.email;
      }
      else if (this.recordType == RecordType.Agent) {
        this.emailTo = (this.record as AgentFull).agent.email;
      }
      else if (this.recordType == RecordType.Application) {
        let loanBorrowers = this.allBorrowers.filter(b => b.applicationId == this.recordId && b.isPrimary);
        this.emailTo = loanBorrowers.length > 0 ? loanBorrowers[0].email : null;
      }
    }
  }

  setSmsToPhoneNumbers = () => {
    if (!this.smsToPhoneNumber) {
      if (this.smsRecipients.length == 1 && this.smsRecipients[0].phoneNumbers.length == 1) {
        this.smsToPhoneNumber = this.smsRecipients[0].phoneNumbers[0].number;
      }
    }
  }

  addLeadSmsRecipient = () => {
    let record = this.record as Lead;

    let mobilePhone, workPhone;

    if (record.mobilePhone || record.workPhone) {

      if (record.mobilePhone && record.mobilePhone.trim()) {
        mobilePhone = {
          number: record.mobilePhone.trim(),
          type: PhoneType.mobile
        };
      }


      if (record.workPhone && record.workPhone.trim()) {
        workPhone = {
          number: record.workPhone.trim(),
          type: PhoneType.work
        }
      }

      this.smsRecipients.push({
        firstName: record.firstName,
        lastName: record.lastName,
        phoneNumbers: [mobilePhone, workPhone].filter(item => item)
      });
    }

    if (record.coMobilePhone || record.coWorkPhone) {

      if (record.coMobilePhone && record.coMobilePhone.trim()) {
        mobilePhone = {
          number: record.coMobilePhone.trim(),
          type: PhoneType.mobile
        };
      }

      if (record.coWorkPhone && record.coWorkPhone.trim()) {
        workPhone = {
          number: record.coWorkPhone.trim(),
          type: PhoneType.work
        }
      }

      this.smsRecipients.push({
        firstName: record.coFirstName,
        lastName: record.coLastName,
        phoneNumbers: [mobilePhone, workPhone].filter(item => item)
      });
    }
  }

  addBorrowerSmsRecipient = (record: Borrower) => {

    let mobilePhone, workPhone, homePhone;

    if (record.mobilePhone || record.workPhone || record.homePhone) {

      if (record.mobilePhone && record.mobilePhone.trim()) {
        mobilePhone = {
          number: record.mobilePhone.trim(),
          type: PhoneType.mobile
        };
      }

      if (record.workPhone && record.workPhone.trim()) {
        workPhone = {
          number: record.workPhone.trim(),
          type: PhoneType.work
        }
      }

      if (record.homePhone && record.homePhone.trim()) {
        workPhone = {
          number: record.homePhone.trim(),
          type: PhoneType.home
        }
      }

      this.smsRecipients.push({
        firstName: record.firstName,
        lastName: record.lastName,
        phoneNumbers: [mobilePhone, workPhone, homePhone].filter(item => item)
      });
    }
  }

  addAgentSmsRecipient = () => {
    let record = (this.record as AgentFull).agent;

    let mobilePhone, workPhone;

    if (record.mobilePhone || record.workPhone) {

      if (record.mobilePhone && record.mobilePhone.trim()) {
        mobilePhone = {
          number: record.mobilePhone.trim(),
          type: PhoneType.mobile
        };
      }

      if (record.workPhone && record.workPhone.trim()) {
        workPhone = {
          number: record.workPhone.trim(),
          type: PhoneType.work
        }
      }

      this.smsRecipients.push({
        firstName: record.firstName,
        lastName: record.lastName,
        phoneNumbers: [mobilePhone, workPhone].filter(item => item)
      });
    }
  }

  addLoanSmsRecipient = () => {

    let loanBorrowers = this.allBorrowers.filter(b => b.applicationId == this.recordId);

    loanBorrowers.forEach((record: Borrower) => {
      this.addBorrowerSmsRecipient(record);
    });
  }

  onSendClicked = () => {
    if (this.sendEmail) {
      this.doSendEmail();
      return;
    }
    this.sendSms();
  }

  selectedEmailTemplateChanged = (selectedTemplate: EmailTemplate) => {

    if (selectedTemplate) {
      let observable;
      if (this.recordType == RecordType.Lead) {
        observable = this._correspondenceService.getEmailTemplateForLead(selectedTemplate.emailTemplateId, this.recordId);
      }
      else if (this.recordType == RecordType.Application) {
        observable = this._correspondenceService.getEmailTemplateForLoan(selectedTemplate.emailTemplateId, this.recordId);
      }
      else {
        observable = of(null);
      }

      observable.subscribe(template => {
        this.emailBody = template.emailText;
        this.emailSubject = template.subject;
      }, () => {

      });
    }
    else {
      this.emailBody = null;
      this.emailSubject = null;
    }
  }

  private doSendEmail = () => {
    if (!this.commonEmailSender.validate()) {
      return;
    }

    let emailInfo = this.commonEmailSender.getEmailInfo();

    let emailPayload = new Message();

    let recordIdFieldName = Utils.getRecordIdFieldName(this.recordType);
    emailPayload[recordIdFieldName] = this.recordId;
    emailPayload.to = emailInfo.to;
    emailPayload.body = emailInfo.body;
    emailPayload.subject = emailInfo.subject;
    emailPayload.cc = emailInfo.cc;
    emailPayload.bcc = emailInfo.bcc;

    this._spinner.show();

    let observable;
    if (this.recordType == RecordType.Lead) {
      observable = this._correspondenceService.sendLeadEmail(emailPayload, emailInfo.appendEmailSignature)
    }
    else if (this.recordType == RecordType.Application) {
      observable = this._correspondenceService.sendLoanEmail(emailPayload, emailInfo.appendEmailSignature)
    }
    else if (this.recordType == RecordType.Borrower) {
      emailPayload.applicationId = 0;
      observable = this._correspondenceService.sendContactEmail(emailPayload, emailInfo.appendEmailSignature)
    }
    else if (this.recordType == RecordType.Agent) {
      emailPayload.applicationId = 0;
      observable = this._correspondenceService.sendAgentEmail(emailPayload, emailInfo.appendEmailSignature)
    }

    observable.subscribe(() => {
      this._spinner.hide();

      this.emailSent.emit(emailInfo);

      this._notificationService.showSuccess("Email has been successfully sent.", "Success!");
    }, (err) => {
      this._spinner.hide();
      this._notificationService.showError(err.error.message || err.message, "Error!");
    });
  }

  private sendSms = () => {
    if (!this.commonSmsSender.validate()) {
      return;
    }

    let smsInfo = this.commonSmsSender.getSmsInfo();

    let smsPayload = new SendSms();

    let recordIdFieldName = Utils.getRecordIdFieldName(this.recordType);
    smsPayload[recordIdFieldName] = this.recordId;
    smsPayload.to = smsInfo.to;
    smsPayload.body = smsInfo.body;
    smsPayload.appendSmsSignature = smsInfo.appendSmsSignature;
    smsPayload.mediaFiles = smsInfo.mediaFiles;

    this._spinner.show();

    let observable;
    if (this.recordType != RecordType.Lead && this.recordType != RecordType.Application &&
      this.recordType != RecordType.Borrower && this.recordType != RecordType.Agent) {
      smsPayload.from = this.userPhoneNumber;
      observable = this._chatService.sendSms(smsPayload);
    } else {
      observable = this._chatService.queueSms(smsPayload);
    }

    observable.subscribe(() => {
      this._spinner.hide();

      this.smsSent.emit(smsInfo);

      this._notificationService.showSuccess("SMS has been successfully sent.", "Success!");

    }, (err) => {
      this._spinner.hide();
      this._notificationService.showError(err.error.message || err.message, "Error!");
    });
  }
}
