import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PendingChangesGuard } from 'src/app/core/route-guards/pending-changes.guard';
import { QuickApplyLoanInfoComponent } from './qa-loan-info/qa-loan-info.component';
import { QuickApplyFinancialInfoComponent } from './qa-financial-info/qa-financial-info.component';
import { QuickApplyFHAInfoComponent } from './qa-fha-info/qa-fha-info.component';
import { QuickApplyVAInfoComponent } from './qa-va-info/qa-va-info.component';
import { QuickApplyBorrowersInfoComponent } from './qa-borrowers-info/qa-borrowers-info.component';

const routes: Routes = [
  {
    path: 'qa-loan-info',
    canDeactivate: [PendingChangesGuard],
    component: QuickApplyLoanInfoComponent,
  },
  {
    path: 'qa-borrower-info',
    canDeactivate: [PendingChangesGuard],
    component: QuickApplyBorrowersInfoComponent,
  },
  {
    path: 'qa-financial-info',
    canDeactivate: [PendingChangesGuard],
    component: QuickApplyFinancialInfoComponent,
  },
  {
    path: 'qa-fha-info',
    canDeactivate: [PendingChangesGuard],
    component: QuickApplyFHAInfoComponent,
  }, {
    path: 'qa-va-info',
    canDeactivate: [PendingChangesGuard],
    component: QuickApplyVAInfoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuickApplyRoutingModule { }
