<div class="card-body" *ngIf="processSubmissionData" style="min-height: 400px;">
	<form class="form-horizontal auth-form" #reviewDataForm="ngForm" novalidate id="reviewDataForm" name="reviewDataForm"
		action="#">
		<div class="row justify-content-center">
			<div class="d-md-none col-lg-1"></div>
			<div class="col-md-12 col-lg-11">
				<div class="row">
					<div class="col-xs-12 col-md-3">
						<div class="row" *ngIf="hasSuggestedBorrowers">
							<div class="col-xs-12">
								<div class="lead-box-1"
									[hidden]="processSubmissionData.mortgageViewModel.borrowers.length === 1 && processSubmissionData.suggestedBorr[0] && processSubmissionData.suggestedBorr[0].length == 0">
									<div
										*ngFor="let borr of processSubmissionData.mortgageViewModel.borrowers | orderBy: 'printApplicationIndex'; let index = index"
										[hidden]="!processSubmissionData.suggestedBorr[index] || processSubmissionData.suggestedBorr[index].length == 0">
										<h6>Borrower {{index + 1}}</h6>
										<div
											*ngIf="processSubmissionData.suggestedBorr[index] && processSubmissionData.suggestedBorr[index].length == 0">
											<span>----</span>
											<br />
											<br />
										</div>
										<div
											*ngIf="processSubmissionData.suggestedBorr[index] && processSubmissionData.suggestedBorr[index].length > 0">
											<span class="alert alert-block alert-danger" style="display: inline-block">
												<i class="fa fa-exclamation-circle"></i>
												<span>
													We found another borrower/application with duplicate information.
													What would you like to do?
												</span>
											</span>
											<div class="form-group mb-2">
												<select (change)="updateLinkedBorrowers(index, subBorrowers[index])"
													id="new-app-review-data-select-borrower-{{index}}" [(ngModel)]="subBorrowers[index]"
													class="form-select" required #subBorr="ngModel"
													[ngClass]="{'is-invalid' : subBorr && subBorr.touched && subBorr.invalid}"
													name="duplicateBorrower + {{index}}">
													<option value="">--Select One--</option>
													<option value="0" *ngIf="!applicationContext.userPermissions.restrictCreateNewOption">
														Create New Borrower
													</option>
													<option *ngFor="let suBorr of processSubmissionData.suggestedBorr[index]"
														value="{{suBorr.borrowerId}}">
														Link to {{suBorr.firstName}} {{suBorr.lastName}}
														({{suBorr.dateCreateBorrower | date:'longDate' }})
													</option>
												</select>
												<div class="invalid-feedback">This field is required</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-md-9">
						<div class="row">
							<div class="col-xs-12 col-sm-6 col-md-7">
								<div class="card-body">

									<div class="card">
										<div class="card-header">
											<p class="card-subtitle font-16">
												<i class="fas fa-users me-2"></i>
												<strong>Please Confirm the Borrower Print Order</strong>
											</p>
										</div>

										<div class="card-body text-center">
											<review-data-borrowers
                        #reviewDataBorrowers
												[isCompanyDeepHaven]="isCompanyDeepHaven"
												[mortgageBorrowers]="processSubmissionData.mortgageViewModel.borrowers">
											</review-data-borrowers>
										</div>
									</div>

									<div class="card">
										<div class="card-header">
											<p class="card-subtitle font-16">
												<i class="fas fa-home me-2"></i>
												<strong>Subject Property</strong>
											</p>
										</div>
										<div class="card-body">

											<div class="d-flex align-items-center justify-content-center my-2">
												<i class="fas fa-map-pin fa-2x me-2" style="color: #E22B35;"></i>
												<p class="text-dark font-weight-semibold mb-0" title="Subject Property Address"> {{
													processSubmissionData.mortgageViewModel.subjectProperty?.address1 ||
													'No Street Address' }}
													<span
														*ngIf="
                                                        processSubmissionData.mortgageViewModel.subjectProperty?.city ||
                                                        processSubmissionData.mortgageViewModel.subjectProperty?.state ||
                                                        processSubmissionData.mortgageViewModel.subjectProperty?.zipCode">
														,
														{{
														processSubmissionData.mortgageViewModel.subjectProperty.city
														? processSubmissionData.mortgageViewModel.subjectProperty.city +
														','
														: ''
														}}
														{{
														formatMailingState(processSubmissionData.mortgageViewModel.subjectProperty?.state)
														|| '—'}},
														{{processSubmissionData.mortgageViewModel.subjectProperty?.zipCode
														|| '—'}}
													</span>
												</p>
											</div>

											<div class="row mt-3">
												<div class="col-sm-4 pe-0 text-end"><strong>Loan Type: </strong></div>
												<div class="col-sm-8">
													{{getLoanTypeNameById(processSubmissionData.loanTypeId)}}</div>
											</div>

											<div class="row mt-2">
												<div class="col-sm-4 pe-0 text-end"><strong>Loan Purpose: </strong>
												</div>
												<div class="col-sm-8">
													{{getLoanPurposeNameById(processSubmissionData.loanPurposeId)}}
												</div>
											</div>

											<div class="row mt-2">
												<div class="col-sm-4 pe-0 text-end"><strong>Loan Amount: </strong></div>
												<div class="col-sm-8">
													{{(processSubmissionData.mortgageViewModel.mortgageTerm.amount |
													currency) || '—'}}</div>
											</div>

											<div class="row mt-2">
												<div class="col-sm-4 pe-0 text-end"><strong>Appraised Value: </strong>
												</div>
												<div class="col-sm-8">
													{{(processSubmissionData.mortgageViewModel.mortgageTerm.appraisedValue
													| currency) || '—'}}</div>
											</div>

											<div class="row mt-2">
												<div class="col-sm-4 pe-0 text-end"><strong>LTV/CLTV: </strong></div>
												<div class="col-sm-8">{{((ltv * 100) | number: '1.3-3') || '—'}}% /
													{{((cltv *
													100) | number: '1.3-3') || '—'}}%</div>
											</div>

											<div class="row mt-2">
												<div class="col-sm-4 pe-0 text-end"><strong>Rate: </strong></div>
												<div class="col-sm-8">
													{{processSubmissionData.mortgageViewModel.mortgageTerm.interestRate
													|| '—'}}%</div>
											</div>

										</div>
									</div>

									<div class="card">
										<div class="card-header">
											<p class="card-subtitle font-16">
												<i class="fas fa-home me-2"></i>
												<strong>Please Confirm/Provide the Loan Purpose and Loan Type</strong>
											</p>
										</div>
										<div class="card-body">
											<div class="form-group mb-3 row">
												<label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Loan
													Purpose </label>
												<div class="col-sm-8">
													<select class="form-select" required [(ngModel)]="processSubmissionData.loanPurposeId"
														[ngClass]="{'is-invalid' : loanPurpose && loanPurpose.touched && loanPurpose.invalid}"
														#loanPurpose="ngModel" name="loanPurposeId" id="new-app-review-data-loan-purpose">
														<option value="">--Select--</option>
														<option *ngFor="let item of availableLoanPurpose" value="{{item.loanPurposeId}}"
															[selected]="processSubmissionData.loanPurposeId == item.loanPurposeId">
															{{item.loanPurposeName}}
														</option>
													</select>
													<div class="invalid-feedback">Please select a loan purpose</div>
												</div>
											</div>

											<div class="form-group mb-3 row">
												<label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Loan
													Type </label>
												<div class="col-sm-8">
													<select class="form-select" required [(ngModel)]="processSubmissionData.loanTypeId"
														[ngClass]="{'is-invalid' : loanType && loanType.touched && loanType.invalid}"
														#loanType="ngModel" name="loanTypeId" id="new-app-review-data-loan-type">
														<option value=""> --Select-- </option>
														<option *ngFor="let item of availableLoanTypes" value="{{item.loanTypeId}}"
															[selected]="processSubmissionData.loanTypeId == item.loanTypeId">
															{{item.loanTypeName}}
														</option>
													</select>
													<div class="invalid-feedback">Please select a loan type</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xs-12 col-sm-6 col-md-5">
								<div class="card-body border" *ngIf="processSubmissionData.linkedBorrowers.length > 0">
									<p class="card-subtitle font-16 mb-2"><strong>Link with</strong></p>
									<div *ngFor="let linkedBorrower of processSubmissionData.linkedBorrowers; let index = index"
										class="mb-2">
										<h6 class="card-subtitle font-14 mb-2"><strong>Borrower {{index + 1}}</strong>
										</h6>
										<div
											*ngIf="linkedBorrower.borrower !== undefined && linkedBorrower.borrower != '0' && linkedBorrower.borrower !== ''">
											<strong>Name: </strong>{{linkedBorrower.borrower.firstName}}
											{{linkedBorrower.borrower.lastName}}
											<br />
											<strong>Last 4 SSN:
											</strong>{{maskSsnNumber(linkedBorrower.borrower.last4Ssn) || '—'}}
											<br />
											<strong>Phone: </strong>{{(linkedBorrower.borrower.homePhone | phone) || '-'
											}}
											<br />
											<strong>DOB: </strong>{{(linkedBorrower.borrower.birthDate | date:
											'longDate') ?? '—'}}
											<br />
											<strong>Email: </strong> {{linkedBorrower.borrower.email || '—'}}
											<br />
											<br />
											<strong>Registered: </strong>{{linkedBorrower.borrower.isRegister ? 'Yes' :
											'No'}}
											<br />
											<strong>Portal Username: </strong>{{linkedBorrower.borrower.portalUsername
											|| '—'}}
											<br />
											<strong>Last Updated: </strong>{{(linkedBorrower.borrower.dateUpdated |
											date: 'short') ?? '—'}}
											<br />
											<strong>Date Created: </strong>{{(linkedBorrower.borrower.dateCreateBorrower
											| date: 'short') ?? '—'}}
											<br />
											<strong>Last Login: </strong>{{(linkedBorrower.borrower.lastTimeLogin |
											date: 'short') ?? '—'}}
											<br />
										</div>
										<div *ngIf="linkedBorrower.borrower !== undefined && linkedBorrower.borrower == '0'">
											<span>Option to create a new application has been selected.</span>
										</div>
										<div *ngIf="linkedBorrower.borrower == ''">
											<span>----</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
<div class="text-center">
	<button class="btn btn-primary me-2" (click)="back()">Back</button>
	<button id="new-app-review-data-proceed-btn" class="btn btn-primary"
		(click)="proceed()">
		Proceed To {{ showKeyDates ?
		'Key Dates' : showMissingInfo ?
		'Missing Information' : allowedLoanCharacterstics.length > 0 ?
		'Loan Characteristics' : allowedBorrowerCharacterstics.length > 0 ?
		'Borrower Characteristics' : 'Final Review' }}
	</button>
</div>
