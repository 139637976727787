<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        Borrowers
      </div>
    </h4>
  </div>
  <div class="card-body">
    <div class="row" *ngFor="let borrower of docPrep.borrowers; let i = index;">
      <document-preparation-borrower-v2
        [borrower]="borrower"
        [index]="i"
        [borrowerTypes]="borrowerTypes"
        [borrowerRoles]="borrowerRoles"
        [entityTypes]="entityTypes"
        [suffixes]="suffixes"
        [maritalStatuses]="maritalStatuses"
        (deleteBorrowerClicked)="onDeleteBorrowerClicked($event)">
      </document-preparation-borrower-v2>
    </div>
    <div class="row mt-3">
      <div class="form-group">
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="onAddBorrowerClicked()">
          <i class="fas fa-plus me-1"></i>
          Add Borrower </button>
      </div>
    </div>
  </div>
</div>