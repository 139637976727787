import { Component, Input, OnInit } from '@angular/core';
import { RegulationZDetail } from 'src/app/models';

@Component({
  selector: 'points-and-fees',
  templateUrl: 'points-and-fees.component.html',
  styleUrls: ['./points-and-fees.component.scss']
})

export class PointsAndFeesComponent implements OnInit {

  @Input()
  regulationZDetail: RegulationZDetail;

  constructor() { }

  ngOnInit() { }
}