import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from 'src/app/core/services/data.service';
import { Profile, ThirdPartyCredential, ThirdPartyCredentialType, ThirdPartyKeyValue } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EmailServiceModel } from 'src/app/modules/email-configuration/models';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProfileService } from '../../../profile.service';
import { EmailService } from 'src/app/models/user/email-service.model';
import { clone } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { OutlookCalendar } from 'src/app/modules/events/models/outlook-calendar.model';
import { EventsService } from 'src/app/modules/events/services/events.service';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit {
  @Input()
  set profile (value: Partial<Profile>) {
    this._profile = value;
    if (value.emailService?.serviceType === 'ExchangeMicrosoftGraph') {
      const mg = value.thirdPartyCredentials?.find(el => el.vendorName === 'MicrosoftGraph');

      if (!mg) {
        return;
      }

      this.initMicrosoftGraphUserName = mg.userName;
      this.initMicrosoftGraphCalendarCheckboxValue = mg.thirdPartyKeyValuePairs
        ?.find(kvp => kvp.key === "ConnectToOutlookCalendar")
        ?.value;
    }
  }

  get profile() {
    return this._profile;
  }

  @Input()
  tenantKvp: ThirdPartyKeyValue;

  @Input()
  isSelfUserProfile: boolean;

  @Input()
  companyCredentialId: number;

  @ViewChild("emailSettingsForm")
  emailSettingsForm: NgForm;

  @ViewChild("serviceType")
  serviceType: HTMLSelectElement;

  amazonWebServices: ThirdPartyCredential;

  microsoftGraph: ThirdPartyCredential;

  awsRegionKvp: ThirdPartyKeyValue;
  connectToOutlookCalendarKvp: ThirdPartyKeyValue;
  syncedCalendarIdKvp: ThirdPartyKeyValue;

  users: any[] = [];
  awsRegions: EnumerationItem[] = [];
  outlookCalendars: OutlookCalendar[] = [];

  emailServiceUserNameToDisplay: string = null;
  userNameToDisplay: string;
  initMicrosoftGraphUserName: string;
  initMicrosoftGraphCalendarCheckboxValue: string;

  isLoading = false;
  loadingUsersForMG = false;
  displayMicrosoftGraphInfoForUser: boolean = true;
  displayMicrosoftGraphInfoForEmailServiceUser: boolean = true;
  showMGselect: boolean = false;

  private _profile: Partial<Profile>;

  constructor(
    private readonly _dataService: DataService,
    private readonly _service: ProfileService,
    private readonly _notify: NotificationService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _enumService: EnumerationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _eventsService: EventsService,
    private readonly _cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this._applicationContextService.context.subscribe(context => {
      this.showMGselect = context.userPermissions.admin && !this.isSelfUserProfile;

      this.profile.emailService = { ...new EmailServiceModel(), ...this.profile.emailService };
      this.emailServiceUserNameToDisplay = this.profile.emailService.userName ? this.profile.emailService.userName : null;
      this.userNameToDisplay = this.profile.userName ? this.profile.userName : null;

      if (this.profile.emailService?.serviceType === "AmazonSes") {
        this.initAmazonWebServices();
      } else if (this.profile.emailService?.serviceType === 'ExchangeMicrosoftGraph') {
        this.initMicrosoftGraphService();
      }

      this.handleUserChange(this.emailServiceUserNameToDisplay ?? this.userNameToDisplay);
      this.getDataForMicrosoftGraph();
    });

    this.awsRegions = this._enumService.awsRegions;
  }

  autoDiscoverExchangeUrl = () => {

    this._spinner.show();
    this._service.autoDiscoverExchangeUrl(this.profile.emailService)
      .subscribe({
        next: (res) => {
          this._spinner.hide();
          this.profile.emailService.servicesUrl = res.replace('"', '').replace('"', '');
          this._notify.showSuccess('Exchange url discovered successfully', 'Profile Service');
        },
        error: (error) => {
          this._spinner.hide();
          this._notify.showError(error ? error.message : 'Unable to discover exchange url', 'Profile Service');
        }
      })
  }

  sendTestEmail = () => {
    if (!this.emailSettingsForm) return;
    this.emailSettingsForm.form.markAllAsTouched();
    if (!this.emailSettingsForm.form.valid) return;

    let testingObserver;
    if (this.profile.emailService.serviceType === "AmazonSes") {
      if (!this.amazonWebServices.userName || !this.amazonWebServices.password || !this.awsRegionKvp.value) {
        this._notify.showError("Please provide Account ID, Account Secret, Region and save", "Error");
        return;
      }
      testingObserver = this._service.sendTestEmailForThirdParty(this.amazonWebServices, "AmazonSes", this.profile.emailService?.userName);
    } else {
      testingObserver = this._service.sendEmailTest(this.profile.emailService);
    }

    this.isLoading = true;
    testingObserver.subscribe({
      next: () => {
        this._notify.showSuccess('Message sent successfuly', 'Profile Service');
      },
      error: (error) => {
        this._notify.showError(error ? error.message : 'Unable to send Test', 'Profile Service');
      }
    }).add(() => this.isLoading = false);
  }

  handleUserChange(value): void {

    this.profile.emailService.userName = value;
    this.emailServiceUserNameToDisplay = value
      ? clone(value.toLowerCase())
      : null;

    this.matchUsers();

    this.microsoftGraph = this.profile.thirdPartyCredentials.find(el => el.vendorName === 'MicrosoftGraph');
    if (!this.microsoftGraph) {
      return;
    }

    this.microsoftGraph.userName = value;

    if (this.connectToOutlookCalendarKvp) {
      if (this.initMicrosoftGraphUserName != this.microsoftGraph.userName) {
        this.connectToOutlookCalendarKvp.value = "0";
        this.initMicrosoftGraphCalendarCheckboxValue = "0";
        this.updateProfileConnectToOutlook();
      }

      if (this.connectToOutlookCalendarKvp.value == "1") {
        this.getOutlookCalendar();
      }
    }
  }

  onServiceTypeChanged() {
    this.cleanServiceProperties();

    if (this.profile.emailService.serviceType === "AmazonSes") {
      this.initAmazonWebServices();
    } else if (this.profile.emailService?.serviceType === 'ExchangeMicrosoftGraph') {
      this.initMicrosoftGraphService();
    }
  }

  updateThirdPartyCredsForRegion() {
    const matchingKvp = this.amazonWebServices.thirdPartyKeyValuePairs.find(kvp => kvp.key === 'AwsRegion');
    if (!matchingKvp) {
      this.amazonWebServices.thirdPartyKeyValuePairs.push(this.awsRegionKvp);
    } else {
      matchingKvp.value = this.awsRegionKvp.value;
    }
  }

  toggleConnectOutlookCalendar() {
    // make sure it updates profile object
    this.updateProfileConnectToOutlook();

    if (this.connectToOutlookCalendarKvp.value == "1") {
      this.getOutlookCalendar();
    } else {
      this.syncedCalendarIdKvp.value = null;
    }
  }

  private getDataForMicrosoftGraph = () => {
    if (!this.tenantKvp?.value) return;

    if (!this.tenantKvp.userName) {
      this.tenantKvp.userName = this.profile.emailService.userName;
    }
    this.getUsers();
  }

  private getUsers = () => {
    this.loadingUsersForMG = true;
    this._dataService.get(`api/Email/Impersonation/Users?tenantId=${this.tenantKvp.value}`)
      .subscribe({
        next: res => {
          this.users = res?.map(user => {
            user.email?.toLowerCase();
            return user;
          }) || [];
          this.matchUsers();
        },
        error: (err) => {
          this.outlookCalendars = [];
          this._notify.showError(err ? err.message : 'Unable to get impersonation users', 'Profile Service');
        }
      }).add(() => this.loadingUsersForMG = false);
  }

  private matchUsers() {
    if (!this.userNameToDisplay && !this.emailServiceUserNameToDisplay) {
      this.displayMicrosoftGraphInfoForEmailServiceUser = true;
      this.displayMicrosoftGraphInfoForUser = true;
      return;
    }

    this.displayMicrosoftGraphInfoForEmailServiceUser = this.emailServiceUserNameToDisplay ?
      this.users.find(u => u.email === this.emailServiceUserNameToDisplay?.toLowerCase()) :
      this.users.find(u => u.email === this.userNameToDisplay?.toLowerCase());

    this.displayMicrosoftGraphInfoForUser = this.userNameToDisplay ?
      this.users.find(u => u.email === this.userNameToDisplay?.toLowerCase()) :
      this.users.find(u => u.email === this.emailServiceUserNameToDisplay?.toLowerCase());
  }

  private cleanServiceProperties() {
    if (!this.profile.emailService?.serviceType) {
      this.profile.emailService = new EmailService();
      this.removeAws();
      return;
    }

    switch (this.profile.emailService.serviceType) {
      case "Smtp":
        this.cleanServiceForSmtp();
        this.removeMicrosoftGraphCreds();
        break;
      case "ExchangeWebServices":
        this.cleanServiceForExchangeWebServices();
        this.removeMicrosoftGraphCreds();
        break;
      case "SendGrid":
        this.cleanServiceForSendGrid();
        this.removeMicrosoftGraphCreds();
        break;
      case "ExchangeMicrosoftGraph":
        this.cleanServiceForExchangeMicrosoftGraph();
        break;
      case "AmazonSes":
        this.cleanServiceForAmazonSes();
        this.removeMicrosoftGraphCreds();
        break;
    }
  }

  private cleanServiceForSmtp() {
    this.profile.emailService = new EmailService({
      serviceType: this.profile.emailService.serviceType,
      userName: this.profile.emailService.userName,
      server: this.profile.emailService.server,
      isSecure: this.profile.emailService.isSecure,
      password: this.profile.emailService.password,
      port: this.profile.emailService.port
    }, this.profile.emailService);

    this.removeAws();
  }

  private cleanServiceForExchangeWebServices() {
    this.profile.emailService = new EmailService({
      serviceType: this.profile.emailService.serviceType,
      userName: this.profile.emailService.userName,
      servicesUrl: this.profile.emailService.servicesUrl,
      password: this.profile.emailService.password,
    }, this.profile.emailService);

    this.removeAws();
  }

  private cleanServiceForSendGrid() {
    this.profile.emailService = new EmailService({
      serviceType: this.profile.emailService.serviceType,
      apiKey: this.profile.emailService.apiKey,
    }, this.profile.emailService);

    this.removeAws();
  }

  private cleanServiceForExchangeMicrosoftGraph() {
    this.profile.emailService = new EmailService({
      serviceType: this.profile.emailService.serviceType,
      userName: this.profile.emailService.userName,
    }, this.profile.emailService);

    this.removeAws();
  }

  private cleanServiceForAmazonSes() {
    this.profile.emailService = new EmailService({
      serviceType: this.profile.emailService.serviceType,
      userName: this.profile.emailService.userName,
    }, this.profile.emailService);
  }

  private initAmazonWebServices = () => {
    this.amazonWebServices = this.profile.thirdPartyCredentials.find(el => el.vendorName === 'AmazonWebServices');
    if (!this.amazonWebServices) {
      this.amazonWebServices = new ThirdPartyCredential(ThirdPartyCredentialType.CloudPlatform, 'AmazonWebServices', this.profile.userProfile?.userCompanyGuid);
      this.profile.thirdPartyCredentials.push(this.amazonWebServices);
    }

    this.addAwsRegionToKvps();
    this.awsRegions = this._enumService.awsRegions;
  }

  private initMicrosoftGraphService() {
    this.microsoftGraph = this.profile.thirdPartyCredentials.find(el => el.vendorName === 'MicrosoftGraph');

    if (!this.microsoftGraph) {
      this.microsoftGraph = new ThirdPartyCredential(ThirdPartyCredentialType.Email, 'MicrosoftGraph', this.profile.userProfile?.userCompanyGuid);
      this.microsoftGraph.userName = this.profile.emailService.userName;
      this.profile.thirdPartyCredentials.push(this.microsoftGraph);
    }

    this._initMicrosoftGraphKvps();
  }

  private _initMicrosoftGraphKvps() {
    if (!this.microsoftGraph.thirdPartyKeyValuePairs) {
      this.microsoftGraph.thirdPartyKeyValuePairs = [];
    }

    this.connectToOutlookCalendarKvp = this.microsoftGraph.thirdPartyKeyValuePairs.find(kvp => kvp.key === "ConnectToOutlookCalendar");
    if (!this.connectToOutlookCalendarKvp) {
      this.connectToOutlookCalendarKvp = new ThirdPartyKeyValue("ConnectToOutlookCalendar", "0");
      this.microsoftGraph.thirdPartyKeyValuePairs.push(this.connectToOutlookCalendarKvp);
    }

    this.syncedCalendarIdKvp = this.microsoftGraph.thirdPartyKeyValuePairs.find(kvp => kvp.key === "SyncedCalendarId");
    if (!this.syncedCalendarIdKvp) {
      this.syncedCalendarIdKvp = new ThirdPartyKeyValue("SyncedCalendarId", null);
      this.microsoftGraph.thirdPartyKeyValuePairs.push(this.syncedCalendarIdKvp);
    }
  }

  private addAwsRegionToKvps() {
    if (!this.amazonWebServices.thirdPartyKeyValuePairs) {
      this.awsRegionKvp = new ThirdPartyKeyValue("AwsRegion", null)
      this.amazonWebServices.thirdPartyKeyValuePairs = [this.awsRegionKvp];
    } else {
      this.awsRegionKvp = this.amazonWebServices.thirdPartyKeyValuePairs.find(kvp => kvp.key === 'AwsRegion');
      if (!this.awsRegionKvp) {
        this.awsRegionKvp = new ThirdPartyKeyValue("AwsRegion", null);
        this.amazonWebServices.thirdPartyKeyValuePairs.push(this.awsRegionKvp);
      } else {
        this.awsRegionKvp.value = this.awsRegionKvp.value || null; // set default value
      }
    }
  }

  private removeAws() {
    const awsRegionIndex = this.amazonWebServices?.thirdPartyKeyValuePairs?.findIndex(kvp => kvp.key === "AwsRegion");
    if (awsRegionIndex > -1) this.amazonWebServices?.thirdPartyKeyValuePairs?.splice(awsRegionIndex, 1)

    const awsIndex = this.profile.thirdPartyCredentials?.findIndex(el => el.vendorName === 'AmazonWebServices');
    if (awsIndex > -1) this.profile.thirdPartyCredentials?.splice(awsIndex, 1);
  }

  private removeMicrosoftGraphCreds() {
    const msGraphCredsIndex = this.profile.thirdPartyCredentials?.findIndex(el => el.vendorName === 'MicrosoftGraph');
    if (msGraphCredsIndex > -1) this.profile.thirdPartyCredentials?.splice(msGraphCredsIndex, 1);
  }

  private getOutlookCalendar() {
    if (this.profile.emailService.serviceType !== 'ExchangeMicrosoftGraph' || !this.microsoftGraph.userName) {
      return;
    }

    if (this.initMicrosoftGraphUserName !== this.microsoftGraph.userName) {
      return;
    }

    this._spinner.show();
    this._eventsService.getOutlookCalendars(this.profile.userProfile?.userCompanyGuid).subscribe({
      next: (res) => {
        this.outlookCalendars = res || [];

        const defaultCalendars = this.outlookCalendars.filter(c => c.isDefault);
        if (defaultCalendars.length === 1) {
          this.syncedCalendarIdKvp.value = defaultCalendars[0].thirdPartyCalendarId;
        }
      },
      error: (error) => {
        this._notify.showError(error?.message || "Couldn't load outlook calendars", "Error!");
      }
    }).add(() => {
      this._spinner.hide();
    })
  }

  private updateProfileConnectToOutlook() {
    const microsoftGraph = this.profile.thirdPartyCredentials.find(el => el.vendorName === 'MicrosoftGraph');
    microsoftGraph.thirdPartyKeyValuePairs.find(kvp => kvp.key === "ConnectToOutlookCalendar").value = this.connectToOutlookCalendarKvp.value;
  }
}
