<div class="grid-layout has-border">
    <div class="grid-header mb-1">
        <div class="grid-header-left">
            <div>2d. Other Liabilities and Expenses</div>
        </div>
        <div class="grid-header-right">
            <a class="btn btn-sm btn-soft-primary" role="button" (click)="addOtherLiability()"
                *ngIf="!isReadOnly && !thereIsAtLeastOneInvalidLineItem && !inEditMode && !isNewLiability">
                <i class="fas fa-plus me-2"></i>Add Other Liability
            </a>
        </div>
    </div>
    <div class="row mb-2">
        <div class="table-responsive" style="border-bottom: none;">
            <table class="table mb-0">
                <thead class="table-light">
                    <tr>
                        <td class="col-md-3 col-sm-3 col-xs-3">Account Owner</td>
                        <td class="col-md-2 col-sm-2 col-xs-2">Account Type</td>
                        <td class="col-md-2 col-sm-2 col-xs-2"></td>
                        <td class="col-md-1 col-sm-1 col-xs-1">MO Pmt</td>
                        <td class="col-md-1 col-sm-1 col-xs-1">MO Left</td>
                        <td class="col-md-1 col-sm-1 col-xs-1">Unpaid Bal</td>
                        <td class="col-md-1 col-sm-1 col-xs-1">Payoffs</td>
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center">Action</td>
                    </tr>

                </thead>
                <tbody>
                    <tr *ngFor="let liability of otherLiabilities; let index = index;">
                        <td class="col-md-3 col-sm-3 col-xs-3">
                            <ng-select2 [hidden]="index !== editingIx" class="select2 mb-3 select2-multiple"
                                name="owningBorrowerIds{{liability.liabilityId}}" [(ngModel)]="liability.owningBorrowerIds"
                                (ngModelChange)="onAccountOwnersChanged(liability)"
                                [options]="optionsMultipleSelect" [data]="possibleAccountOwners" required>
                            </ng-select2>
                            <div class="owningBorrowerIds-readonly" *ngIf="index !== editingIx"
                                [ngClass]="{ 'ng-invalid-requested no-border' : liability.owningBorrowerIds.length === 0 }"
                                [ngStyle]="{height: liability.owningBorrowerIds.length === 0 ? '22px' : 'auto'}">
                                <span *ngFor="let owner of liability['owners']">{{owner.name}}<br></span>
                            </div>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2">
                            <urla-dropdown [(ngModel)]="liability.typeOfLiability"
                                [options]="otherLiabilityTypeOptions" name="typeOfLiability{{liability.liabilityId}}"
                                [showBorder]="index === editingIx" [readonly]="index !== editingIx"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.liability.typeOfLiability'"
                                [required]="urlaFieldsConfig['mortgage.liability.typeOfLiability']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.liability.typeOfLiability']?.required == 'requested'">
                            </urla-dropdown>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2"></td>
                        <td class="col-md-1 col-sm-1 col-xs-1">
                            <urla-currency-input name="monthlyPayment{{liability.liabilityId}}" [(ngModel)]="liability.monthlyPayment"
                            (ngModelChange)="calculateSubTotals()" [readonly]="index !== editingIx" [showBorder]="index === editingIx" [isOmitted]=liability.isExcluded></urla-currency-input>
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1">
                            <urla-digits-input name="monthsLeftToPay{{liability.liabilityId}}" [(ngModel)]="liability.monthsLeftToPay"
                            [readonly]="index !== editingIx" [showBorder]="index === editingIx"></urla-digits-input>
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1">
                            <urla-currency-input name="unpaidBalance{{liability.liabilityId}}" [(ngModel)]="liability.unpaidBalance"
                            (ngModelChange)="calculateSubTotals()" [readonly]="index !== editingIx" [showBorder]="index === editingIx" [isOmitted]=liability.isExcluded></urla-currency-input>
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1">
                            <urla-currency-input name="partialPayoffAmount{{liability.liabilityId}}" [(ngModel)]="liability.partialPayoffAmount"
                            (ngModelChange)="calculateSubTotals()" [readonly]="index !== editingIx" [showBorder]="index === editingIx" [isOmitted]=liability.isExcluded></urla-currency-input>
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1">
                            <div class="action" *ngIf="!isReadOnly">
                                <a title="Edit Liability" href="javascript: void(0);" *ngIf="index !== editingIx && !thereIsAtLeastOneInvalidLineItem && !isNewLiability" (click)="editOtherLiability(liability, index)"><i class="fa fa-pencil-alt"></i></a>
                                <a title="Confirm Liability Changes" href="javascript: void(0);" *ngIf="index === editingIx && liability.owningBorrowerIds && liability.owningBorrowerIds.length"
                                  (click)="onEditConfirmed()"><i class="fa fa-check text-success"></i></a>
                                <a title="Cancel Liability Changes" href="javascript: void(0);"
                                  *ngIf="index === editingIx && liability.owningBorrowerIds && liability.owningBorrowerIds.length" (click)="cancelEdit()"><i class="fa fa-times text-success"></i></a>

                                <a title="Omit Liability From Calculations" href="javascript: void(0);" *ngIf="!liability.isExcluded" (click)="exclude(liability, true)"><i class="fa fa-ban"></i></a>
                                <a title="Include Liability In Calculations" href="javascript: void(0);" *ngIf="liability.isExcluded" (click)="exclude(liability, false)"><i class="fa fa-ban red-icon"></i></a>

                                <a title="Delete Liability" href="javascript: void(0);" (click)="deleteLiability(index)" *ngIf="!isNewLiability"><i class="fa fa-trash-alt"></i></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td class="text-end">Sub Total </td>
                        <td><strong>{{otherMonthlyPaymentSubTotal | currency }}</strong></td>
                        <td></td>
                        <td><strong>{{otherUnpaidBalanceSubTotal | currency }}</strong></td>
                        <td><strong>{{otherPartialPayoffAmount | currency }}</strong></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="3" class="text-end"> <strong> Total Liabilities, Expenses, and Payoffs </strong> </td>
                        <td><strong>{{(monthlyPaymentSubTotal + otherMonthlyPaymentSubTotal) | currency }}</strong></td>
                        <td></td>
                        <td><strong>{{(unpaidBalanceSubTotal + otherUnpaidBalanceSubTotal) | currency }}</strong></td>
                        <td><strong>{{(partialPayoffAmount + otherPartialPayoffAmount) | currency }}</strong></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
