import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { debounce } from 'lodash';
import { Subscription, firstValueFrom } from 'rxjs';
import { LoanApplication, UserType } from 'src/app/models';
import { FeeService } from 'src/app/services/fee.service';
import { LosService } from 'src/app/services/los.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'mortgage-cto-fee-wizard',
  templateUrl: 'mortgage-cto-fee-wizard.component.html',
  styleUrls: ['./mortgage-cto-fee-wizard.component.scss']
})
export class MortgageCtoFeeWizardComponent extends ApplicationContextBoundComponent implements OnInit {

  @Output()
  wizardCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  wizardCompleted: EventEmitter<any> = new EventEmitter<any>();

  preparingForFees: boolean = false;
  losSyncCompleted: boolean = false;
  loanCreateAttempts: number = 0;
  loanSyncAttempts: number = 0;
  error: string = null;

  protected application: LoanApplication;

  protected isTpo: boolean;

  protected feeWizardUrl: SafeResourceUrl;
  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _losService: LosService,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _router: Router,
    private readonly _feeService: FeeService,
  ) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.application = context.application;
        this.isTpo = context.isTpo;
        this.initializeDebounce();
      }
    });
  }

  async ngOnInit() {
    if (this.applicationContext?.application?.applicationId) {
      this.application = this.applicationContext.application;
      this.initializeDebounce();
    }

    window.addEventListener('message', event => {
      console.log("window.message", event);
      if (event.data?.source !== 'react-devtools-content-script') {
        if (event.data?.shouldCloseEsignDocIframe) {
          const urlRoot = this.isTpo ? 'tpo' : 'admin';
          this._router.navigate([`/${urlRoot}/app-details/${this.application.applicationId}/fees`], {
            state: {
            },
            queryParams: {
              pull: true,
              v: Date.now()
            }
          });
        }
      }
    }, false);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    window.removeAllListeners();
  }

  initializeDebounce = debounce(this.initialize, 1000);

  private async initialize() {
    if (this.applicationContext.userPermissions.userType == UserType.Tpo) {
      if (!this.losSyncCompleted) {
        if (!this.applicationContext.application.losIdentifier) {
          while (this.loanCreateAttempts < 3 && !this.losSyncCompleted) {
            this.loanCreateAttempts++;
            await this.autoCreateLosLoan();
          }
        }
        else {
          while (this.loanSyncAttempts < 3 && !this.losSyncCompleted) {
            this.loanSyncAttempts++;
            await this.autoSyncLosLoan();
          }
        }
      }
      else {
        await this.getMctoFeeWizardUrl();
      }
    } else {
      await this.getMctoFeeWizardUrl();
    }
  }

  private getMctoFeeWizardUrl = async () => {
    this.preparingForFees = true;
    try {
      const url = await firstValueFrom(
        this._feeService.getMctoWizardUrl(this.application.applicationId),
      );

      this.error = undefined;
      const decodedUrl = decodeURIComponent(url);
      this.feeWizardUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(decodedUrl);

    } catch (e) {
      this.error = e.message?.replace('{' + this.application.losIdentifier + '}', this.application.refNumber) || "There was an error attempting to prepare your loan for fees. Please contact your AE.";
    } finally {
      this.preparingForFees = false;
    }
  }

  private autoCreateLosLoan = async () => {
    this.preparingForFees = true;
    try {
      const losAppOpResult = await firstValueFrom(
        this._losService.autoCreateLosLoan(this.application.applicationId),
      );
      this.error = undefined;
      this.losSyncCompleted = true;
      this.applicationContextService.updateMortgageAndApplication(losAppOpResult.mortgage, losAppOpResult.application, losAppOpResult.customData);
    } catch (e) {
      console.error(e);
      this.error = e.message?.replace('{' + this.application.losIdentifier + '}', this.application.refNumber) || "There was an error attempting to prepare your loan for fees. Please contact your AE.";
      this.preparingForFees = false;
    }
  }

  private autoSyncLosLoan = async () => {
    this.preparingForFees = true;
    try {
      const losAppOpResult = await firstValueFrom(
        this._losService.autoSyncLosLoan(this.application.applicationId),
      );
      this.error = undefined;
      this.losSyncCompleted = true;
      this.applicationContextService.updateMortgageAndApplication(losAppOpResult.mortgage, losAppOpResult.application, losAppOpResult.customData);
    } catch (e) {
      console.error(e);
      this.error = e.message?.replace('{' + this.application.losIdentifier + '}', this.application.refNumber) || "There was an error attempting to prepare your loan for fees. Please contact your AE.";
      this.preparingForFees = false;
    }
  }

  onCancelClicked() {
    this.wizardCancelled.emit();
  }
}
