import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss'],
})
export class SaveButtonComponent {
  /**
   * Emitted when the button is clicked if it is not disabled or in the saving
   * state.
   */
  @Output() onClick = new EventEmitter<void>();

  /**
   * Whether the button is in the saving state. An animated spinner will be
   * shown and the button will be disabled in this state.
   */
  @Input() saving: boolean = false;

  /**
   * Whether the button is disabled. Changes the button's appearance and
   * prevents clicks.
   */
  @Input() isDisabled: boolean = false;

  protected onButtonClick() {
    if (!this.saving) {
      this.onClick.emit();
    }
  }
}
