<div class='card'>
  <form
    #form='ngForm'
    class='card-body row m-0'
  >
    <ng-container *ngIf='income'>
      <!-- Income Type select -->
      <div class='form-group col-12 col-lg-3'>
        <label [for]='id("type-of-income")'>Income Type</label>

        <select
          #typeOfIncomeControl='ngModel'
          [(ngModel)]='income.typeOfIncome'
          [id]='id("type-of-income")'
          [required]='true'
          class='form-select'
          name='typeOfIncome'
        >
          <option [ngValue]='undefined'>Select an income type</option>

          <option
            *ngFor='let typeOfIncome of typeOfIncomeOptions'
            [ngValue]='typeOfIncome.value'
          >
            {{ typeOfIncome.name }}
          </option>
        </select>

        <div *ngIf='isInvalid(typeOfIncomeControl)' class='invalid-feedback'>
          <ng-container *ngIf='getErrors(typeOfIncomeControl) as errors'>
            <ng-container *ngIf='errors.required'>
              <ng-container *ngTemplateOutlet='requiredError'></ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- Amount input -->
      <div class='form-group custom-form-group col-12 col-lg-3'>
        <label [for]='id("amount")'>Amount</label>

        <currency-input
          [(ngModel)]='income.amount'
          [allowNegative]='false'
          [id]='id("amount")'
          [required]='true'
          name='amount'
        ></currency-input>
      </div>

      <!-- Pay Period select -->
      <div class='form-group col-12 col-lg-3'>
        <label [for]='id("pay-period")'>Pay Period</label>

        <select
          #payPeriodControl='ngModel'
          (ngModelChange)='onChangePayPeriod($event)'
          [(ngModel)]='income.payPeriod'
          [id]='id("pay-period")'
          [required]='true'
          class='form-select'
          name='payPeriod'
        >
          <option
            *ngFor='let payPeriod of payPeriodItems'
            [ngValue]='payPeriod.value'
          >
            {{ payPeriod.name }}
          </option>
        </select>

        <div *ngIf='isInvalid(payPeriodControl)' class='invalid-feedback'>
          <ng-container *ngIf='getErrors(payPeriodControl) as errors'>
            <ng-container *ngIf='errors.required'>
              <ng-container *ngTemplateOutlet='requiredError'></ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- Hours per Week input -->
      <div class='form-group col-12 col-lg-2'>
        <label [for]='id("hours-per-week")'>Hours per Week</label>

        <number-input
          *ngLet='income.payPeriod === PayPeriod.Hourly as enabled'
          [(ngModel)]='income.hoursPerWeek'
          [disabled]='!enabled'
          [id]='id("hours-per-week")'
          [min]='enabled ? 0 : null'
          [required]='enabled'
          name='hoursPerWeek'
          placeholder='0'
        ></number-input>
      </div>

      <!-- Monthly income output -->
      <div class='col-12 col-lg-1 d-flex flex-column align-items-lg-end'>
        <p class='text-muted'>Monthly</p>
        <p class='mt-1 monthly-amount'>
          {{ income.monthlyIncome | currency }}
        </p>
      </div>
    </ng-container>
  </form>

  <div class='card-footer d-flex justify-content-end align-items-stretch gap-2'>
    <span *ngIf='externalError as error' class='text-danger align-self-center'>
      {{ error }}
    </span>

    <qa-cancel-button (qaClick)='onClickCancel()'></qa-cancel-button>

    <qa-ok-button (qaClick)='onClickClose()'></qa-ok-button>
  </div>
</div>

<ng-template #requiredError>
  This field is required.
</ng-template>
