import { Component, Input, OnInit } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { MortgageCalculationService } from '../../services/mortgage-calculation.service';
import { ChannelEnum, LoanApplication } from 'src/app/models';
@Component({
  selector: 'credit-details',
  templateUrl: 'credit-details.component.html',
  viewProviders: [formViewProvider]
})
export class CreditDetailsComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  application: LoanApplication;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  purchaseCreditTypes: EnumerationItem[];

  @Input()
  inEditMode: boolean = false;

  lenderCreditAmount: number;

  isRefinance: boolean;

  ChannelEnum = ChannelEnum;

  private _lenderCreditEnumValue: string;
  private _sellerCreditEnumValue: string;

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _calculationService: MortgageCalculationService) { }

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this._lenderCreditEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LenderCredit);
      this._sellerCreditEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.SellerCredit);
      const lenderCredit = this.mortgage.transactionDetail.purchaseCredits.find(p => p.purchaseCreditType == this._lenderCreditEnumValue);
      if (lenderCredit) {
        this.mortgage.calculatedStats.lenderCredit = lenderCredit.purchaseCreditAmount;
      }
    })

    this.isRefinance = this._calculationService.isPurposeOfLoanRefinance(this.mortgage);
  }

  sellerCreditChange = () => {
    const sellerCredit = this.mortgage.transactionDetail.purchaseCredits.find(p => p.purchaseCreditType == this._sellerCreditEnumValue);
    if (sellerCredit) {
      sellerCredit.purchaseCreditAmount = this.mortgage.transactionDetail.sellerPaidClosingCostsAmount;
    } else {
      var credit = this._getDefaultPurchaseCredit();
      credit.purchaseCreditType = this._sellerCreditEnumValue;
      credit.purchaseCreditAmount = Number(this.mortgage.transactionDetail.sellerPaidClosingCostsAmount);
      credit.sourceType = "PropertySeller";
      this.mortgage.transactionDetail.purchaseCredits.push(credit);
    }
    this.calculateTotalOtherCredit();
  }

  calculateTotalOtherCredit = () => {
    this._calculationService.calculateMortgageStatistics(this.mortgage);
  }

  lenderCreditChange = () => {
    const lenderCredit = this.mortgage.transactionDetail.purchaseCredits.find(p => p.purchaseCreditType == this._lenderCreditEnumValue);
    if (lenderCredit) {
      lenderCredit.purchaseCreditAmount = this.mortgage.calculatedStats.lenderCredit;
    } else {
      var credit = this._getDefaultPurchaseCredit();
      credit.purchaseCreditType = this._lenderCreditEnumValue;
      credit.purchaseCreditAmount = Number(this.mortgage.calculatedStats.lenderCredit);
      credit.sourceType = "Lender";
      this.mortgage.transactionDetail.purchaseCredits.push(credit);
    }
    this.calculateTotalOtherCredit();
  }

  private _getDefaultPurchaseCredit = (transactionDetailId?: number) => {
    return {
      purchaseCreditId: 0,
      purchaseCreditType: null,
      purchaseCreditTypeOtherDescription: null,
      sourceTypeOtherDescription: null,
      sourceType: null,
      purchaseCreditAmount: 0,
      explanation: null,
      transactionDetailId: transactionDetailId || null,
    };
  }
}
