import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { Address } from 'src/app/models';
import { UrlaTextInputComponent } from '../../../common/components';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

@Component({
  selector: 'employer-name-input',
  templateUrl: 'employer-name-input.component.html',
  styleUrls: ['employer-name-input.component.scss'],
  providers: [MakeProvider(EmployerNameNameComponent)],
})
export class EmployerNameNameComponent extends UrlaTextInputComponent {

  @Input()
  isRetired: boolean;

  @Input()
  isPrimary: boolean;

  @Input()
  isCurrent: boolean;

  @Input()
  showIsRetiredCheckbox: boolean = true;

  @Input()
  showIsPrimaryCheckbox: boolean = true;

  @Output()
  isRetiredChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  isPrimaryChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  employerAddressChanged: EventEmitter<Address> = new EventEmitter<Address>();

  @Output()
  switchEmploymentType: EventEmitter<any> = new EventEmitter<any>();

  private _employerAddress: Address = new Address();

  googlePlacesOptions: Partial<Options>  = {
    types: ['establishment'],
  };

  constructor() {
    super();
  }

  onIsRetiredCheckChanged = (e: any) => {
    const checked = e.currentTarget.checked;
    this.isRetiredChanged.emit(checked);
  }

  onIsPrimaryCheckChanged = (e: any) => {
    const checked = e.currentTarget.checked;
    this.isPrimaryChanged.emit(checked);
  }

  onSwitchEmploymentTypeClicked = () => {
    this.switchEmploymentType.emit();
  }

  ngOnInit() { }

  protected onChangeEmployerAddress(e: Partial<Address>): void {
    const employerAddress = this._employerAddress;
    employerAddress.address1 = ''; // to reset the last populated address.

    setTimeout(() => {
      employerAddress.address1 = e.address1;
      employerAddress.city = e.city;
      employerAddress.state = e.state;
      employerAddress.zipCode = e.zipCode;
      employerAddress.country = e.country;
    }, 200);

    this.employerAddressChanged.emit(e);
  }
}
