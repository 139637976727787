import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DeclarationExplanation, DeclarationExplanationType } from '../../../../../models';
import { createIsRequiredFunction } from '../../../urla-utils';
import { MortgageFieldConfig } from '../../../models/urla-fields-config.model';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'declaration-explanation-editor',
  templateUrl: './declaration-explanation-editor.component.html',
  styleUrls: ['./declaration-explanation-editor.component.scss'],
  viewProviders: [formViewProvider],
})
export class DeclarationExplanationEditorComponent
  implements OnInit, OnChanges {

  @Input() explanation: DeclarationExplanation;

  @Input() explanationType: DeclarationExplanationType;

  @Input() explanationTypeOtherDescription?: string;

  @Input() urlaFieldsConfig: Record<string, MortgageFieldConfig>;

  @Input()
  inEditMode: boolean = false;

  @Output() delete = new EventEmitter<void>();

  protected isRequired: (fieldName: string) => boolean;

  protected uuid: string;

  ngOnInit() {
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);
    this.uuid = uuidv4();
  }

  ngOnChanges(changes: SimpleChanges) {
    const explanationTypeChange = changes.explanationType;
    if (explanationTypeChange) {
      this.explanation.explanationType = explanationTypeChange.currentValue;
    }

    const explanationTypeOtherDescriptionChange
      = changes.explanationTypeOtherDescription;
    if (explanationTypeOtherDescriptionChange) {
      this.explanation.explanationTypeOtherDescription
        = explanationTypeOtherDescriptionChange.currentValue;
    }
  }

  protected onClickDelete(): void {
    this.delete.emit();
  }
}
