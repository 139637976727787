import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MakeProvider } from '../../../core/abstract-value-accessor';
import { NgModel, ValidationErrors } from '@angular/forms';
import { GenericAbstractValueAccessor } from '../../../core/generic-abstract-value-accessor';

const dateMask = 'M0/d0/0000';
const datePlaceholder = 'mm/dd/yyyy';

@Component({
  selector: 'date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    MakeProvider(DateTimeInputComponent),
  ],
})
export class DateTimeInputComponent
  extends GenericAbstractValueAccessor<Date | undefined>
  implements AfterViewInit, OnChanges {

  @ViewChild('model') model: NgModel;

  @Input() required: boolean = false;

  @Input() showTime: boolean = false;

  @Input() skin: 'plain' | 'outlined' = 'plain';

  @Input() readonly: boolean = false;

  set value(v: Date | undefined) {
    if (v?.getTime() === this.dateValue?.getTime()) {
      return;
    }

    super.value = v;

    this._dateValue = v;
    this.textValue = dateToString(v);
  }

  private _dateValue: Date | undefined;
  protected get dateValue(): Date | undefined {
    return this._dateValue;
  }

  protected set dateValue(v: Date | undefined) {
    this.value = v;
  }

  protected textValue: string | undefined;

  protected mask: string = dateMask;

  protected placeholder: string = 'mm/dd/yyyy';

  ngAfterViewInit(): void {
    this.initShowTimeDependentProperties();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const showTimeChange = changes.showTime;
    if (showTimeChange != null) {
      this.initShowTimeDependentProperties();
    }
  }

  private initShowTimeDependentProperties(): void {
    if (this.showTime) {
      this.mask = `${dateMask} Hh:m0`;
      this.placeholder = `${datePlaceholder} HH:MM`;
    } else {
      this.mask = dateMask;
      this.placeholder = datePlaceholder;
    }
  }

  private setErrors(errors: ValidationErrors) {
    this.model?.control.setErrors(errors);
  }

  protected onBlur(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    const date = stringToDate(value, this.showTime);

    if (date != null) {
      this.value = date;
      return;
    }

    if (value !== '') {
      this.setErrors({ 'invalid': true });
    }
    this.value = undefined;
  }
}

const isValidDate = (value: unknown): boolean => {
  return value instanceof Date && !isNaN(value.valueOf());
};

const stringToDate = (value: string, showTime: boolean): Date | undefined => {
  const [datePart, timePart] = value.split(' ');
  const [month, day, year] = datePart.split('/').map(Number);
  let [hours, minutes] = [0, 0];

  if (showTime) {
    if (timePart == null) {
      return undefined;
    }

    const [h, m] = timePart.split(':').map(Number);
    if (h == null || m == null) {
      return undefined;
    }

    hours = h;
    minutes = m;
  } else if (timePart != null) {
    return undefined;
  }

  const dateString = `${year}-${month}-${day} ${hours}:${minutes}`;
  const date = new Date(dateString);
  if (isValidDate(date)) {
    return date;
  }
};

function dateToString(value: Date): string | undefined {
  if (!isValidDate(value)) {
    return undefined;
  }

  const day = value.getDate().toString().padStart(2, '0');
  const month = (value.getMonth() + 1).toString().padStart(2, '0');
  const year = value.getFullYear();
  if (value.getHours() !== 0 || value.getMinutes() !== 0) {
    const hour = value.getHours().toString().padStart(2, '0');
    const minute = value.getMinutes().toString().padStart(2, '0');
    return `${month}/${day}/${year} ${hour}:${minute}`;
  } else {
    return `${month}/${day}/${year}`;
  }
}
