<div [ngClass]="{'group-container-repeat': !mouseCursorOnDeleteButton,
  'group-container-repeat-pending-delete': mouseCursorOnDeleteButton}">
  <div class="grid-layout has-border" *ngIf="ausResult">
    <div class='grid-layout-row' style="flex-direction: row-reverse;" *ngIf="!inEditMode">
      <a class="top-right-close link" (mouseover)="mouseCursorOnDeleteButton=true" *ngIf="!isReadOnly"
        (mouseout)="mouseCursorOnDeleteButton=false" (click)="onDeleteClicked()">
        <i class="fa fa-times-circle"></i>
      </a>
    </div>
    <div class="grid-layout-row">
      <urla-text-input [readonly]="isReadOnly" [label]="'Case Identifier'" class="col-sm-3 col-xs-6"
        [name]="'caseIdentifier' + index" [(ngModel)]="ausResult.caseIdentifier" [inEditMode]="inEditMode"
        [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.ausData.ausResult.caseIdentifier'"
        [required]="urlaFieldsConfig['mortgage.ausData.ausResult.caseIdentifier']?.required == 'required' || urlaFieldsConfig['mortgage.ausData.ausResult.caseIdentifier']?.required == 'requested'">
      </urla-text-input>
      <urla-text-input [readonly]="isReadOnly" [label]="'Aus System Type'" class="col-sm-3 col-xs-6"
        [name]="'ausSystemType' + index" [(ngModel)]="ausResult.ausSystem" [inEditMode]="inEditMode"
        [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.ausData.ausResult.ausSystem'" disabled="true"
        [required]="urlaFieldsConfig['mortgage.ausData.ausResult.ausSystem']?.required == 'required' || urlaFieldsConfig['mortgage.ausData.ausResult.ausSystem']?.required == 'requested'">
      </urla-text-input>
      <urla-text-input *ngIf="ausResult.ausSystem == 'Other'" [readonly]="isReadOnly" [label]="'Aus System Other Desc.'"
        class="col-sm-3 col-xs-6" [name]="'ausSystemOtherDescription' + index"
        [(ngModel)]="ausResult.ausSystemOtherDescription" [inEditMode]="inEditMode"
        [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.ausData.ausResult.ausSystemOtherDescription'" [required]=" urlaFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription']?.required == 'required' ||
          urlaFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription']?.required == 'requested'">
      </urla-text-input>
      <div *ngIf="ausResult.ausSystem !== 'Other' && !inEditMode" class="grid-layout-spacer col-sm-3 col-xs-6">
        &nbsp;</div>
      <urla-date-input class="col-sm-3 col-xs-6" [name]="'decisionDateTime' + index" [label]="'Decision Date Time'"
        [(ngModel)]="decisionDateTime" [readonly]="isReadOnly" [inEditMode]="inEditMode" (ngModelChange)="onDecisionDateTimeChanged()"
        [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.ausData.ausResult.decisionDateTime'"
        [required]="urlaFieldsConfig['mortgage.ausData.ausResult.decisionDateTime']?.required == 'required' || urlaFieldsConfig['mortgage.ausData.ausResult.decisionDateTime']?.required == 'requested'">
      </urla-date-input>
    </div>
    <div class="grid-layout-row">
      <urla-text-input [readonly]="isReadOnly" [label]="'Aus Result Value'" class="col-sm-3 col-xs-6"
        [name]="'ausResultValue' + index" [(ngModel)]="ausResult.ausResultValue" [inEditMode]="inEditMode"
        [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.ausData.ausResult.ausResultValue'"
        [required]="urlaFieldsConfig['mortgage.ausData.ausResult.ausResultValue']?.required == 'required' || urlaFieldsConfig['mortgage.ausData.ausResult.ausResultValue']?.required == 'requested'">
      </urla-text-input>
      <urla-text-input [readonly]="isReadOnly" [label]="'Recommendation Description'" class="col-sm-3 col-xs-6"
        [name]="'recommendationDescription' + index" [(ngModel)]="ausResult.recommendationDescription"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
        [fieldBeingEdited]="'mortgage.ausData.ausResult.recommendationDescription'"
        [required]="urlaFieldsConfig['mortgage.ausData.ausResult.recommendationDescription']?.required == 'required' || urlaFieldsConfig['mortgage.ausData.ausResult.recommendationDescription']?.required == 'requested'">
      </urla-text-input>
      <urla-radio-group [readonly]="isReadOnly" class="col-sm-3 col-xs-6" [options]="yesNoOptions"
        label="Includes Document Waiver" [name]="'includesDocumentWaiver'+ index"
        [(ngModel)]="ausResult.includesDocumentWaiver" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
        [fieldBeingEdited]="'mortgage.ausData.ausResult.includesDocumentWaiver'"
        [required]="urlaFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver']?.required == 'required' || urlaFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver']?.required == 'requested'"></urla-radio-group>
    </div>
  </div>
</div>