<div class="grid-layout has-border">
    <div class="grid-header">
        <div class="grid-header-left">
            <div> MORTGAGE LOAN DETAILS </div>
        </div>
        <div class="grid-header-right">
        </div>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-12 col-xs-12" [label]="'I. Loan Amount'">
        </urla-grid-cell>
    </div>
    <div class="grid-layout-row">
        <div class="grid-layout-spacer col-md-1 col-xs-1">&nbsp;</div>
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-7 col-xs-7"
            [label]="'Loan Amount Excluding Financed Mortgage Insurance (or Mortgage Insurance Equivalent) *'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="isReadOnly || inEditMode" class="col-md-4 col-xs-4" name="amount" [(ngModel)]="mortgage.mortgageTerm.amount"
            (change)="loanAmountChange()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
      <div class='grid-layout-spacer col-1 d-none d-md-block'>&nbsp;</div>
      <urla-grid-cell
        [disabled]='isReadOnly'
        class='col-12 col-md-7'
        label='Financed Mortgage Insurance (or Mortgage Insurance Equivalent) Amount'>
        <finance-entire-fee-checkbox
          (valueChange)='onFinanceAllChanged()'
          [mortgage]='mortgage'
          class='align-self-center ms-3'>
        </finance-entire-fee-checkbox>
      </urla-grid-cell>
      <urla-currency-input
        (blur)='emitMiAndFundingFeeChanged(miOrFundingFeeAmount)'
        (ngModelChange)='onMiOrFundingFeeAmountChange($event)'
        [ngModel]='miOrFundingFeeAmount'
        [readonly]='
        mortgage.mortgageInsuranceDetail.financeEntireMiOrFundingFee ||
        isReadOnly ||
        inEditMode
      '
        class='col'
        name='miAndFundingFeeFinancedAmount'>
      </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8"
            [label]="'J. Other New Mortgage Loans on the Property the Borrower(s) is Buying or Refinancing'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="isReadOnly" class="col-md-4 col-xs-4" name="totalLoanOrDrawAmount"
            [(ngModel)]="mortgage.calculatedStats.totalLoanOrDrawAmount" [readonly]="true">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'K. TOTAL MORTGAGE LOANS (Total of I and J)'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="isReadOnly" class="col-md-4 col-xs-4" name="totalMortgageLoans"
            [(ngModel)]="mortgage.calculatedStats.totalMortgageLoans" [readonly]="true">
        </urla-currency-input>
    </div>
</div>
