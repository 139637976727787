import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Select2OptionData } from 'ng-select2';
import { Asset, PurchaseCredit } from 'src/app/models/mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import Swal from 'sweetalert2';
import { resetArrayTo } from '../qa-fi-income/reset-object.to';

@Component({
  selector: 'qa-fi-assets',
  templateUrl: './qa-fi-assets.component.html',
  styleUrls: ['./qa-fi-assets.component.scss']
})
export class QaFiAssetsComponent implements OnInit {

  private _mortgage: UrlaMortgage;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    this.initializePossibleAccountOwners();
    this.initializeAssets();

    this.clearCreatingItemId();
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  assets: Asset[] = [];
  assetsTotal: number = 0;
  creditsTotal: number = 0;
  credits: PurchaseCredit[] = [];

  doesNotApply: boolean = false;

  expandedItems: { [key: number]: boolean } = {};
  hoveredItems: { [key: number]: boolean } = {};
  selectedRows: { [key: number]: boolean } = {};
  existingItems: { [key: number]: boolean } = {};

  expandedItemsCredit: { [key: number]: boolean } = {};
  hoveredItemsCredit: { [key: number]: boolean } = {};
  selectedRowsCredit: { [key: number]: boolean } = {};
  protected existingCredits: { [key: number]: boolean } = {};

  possibleAccountOwners: Select2OptionData[] = [];
  assetTypes: EnumerationItem[];
  otherAssetTypes: EnumerationItem[];
  purchaseCreditTypes: EnumerationItem[];
  allAssetTypes: EnumerationItem[];
  giftGrantTypes: EnumerationItem[];
  giftGrantSources: EnumerationItem[];

  private creatingItemId: number | null = null;
  protected get isCreatingItem(): boolean {
    return this.creatingItemId != null;
  }

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _utilityService: UtilityService,
    private readonly _calculationService: MortgageCalculationService
  ) { }

  ngOnInit(): void {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      let assetTypes = enums[Constants.enumerations.assetTypes];

      this.assetTypes = _.orderBy(assetTypes.filter(type =>
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Bond) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CheckingAccount) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BridgeLoanNotDeposited) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.LifeInsurance) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CertificateOfDepositTimeDeposit) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.IndividualDevelopmentAccount) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MoneyMarketFund) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MutualFund) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RetirementFund) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SavingsAccount) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.TrustAccount) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Annuity) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Boat) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BorrowerEstimatedTotalAssets) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BorrowerPrimaryHome) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.EmployerAssistance) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RealEstateOwned) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RecreationalVehicle) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SavingsBond) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SeverancePackage) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.PendingNetSaleProceedsFromRealEstateAssets) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Stock) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.StockOptions) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.GiftsTotal) ||
        type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.GiftsNotDeposited)
      ), x => x.name);

      this.otherAssetTypes = _.orderBy(assetTypes.filter(asset =>
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CashOnHand) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.PendingNetSaleProceedsFromRealEstateAssets) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SaleOtherAssets) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SecuredBorrowedFundsNotDeposited) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.ProceedsFromSecuredLoan) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.ProceedsFromUnsecuredLoan) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.OtherLiquidAssets) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.OtherNonLiquidAssets) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Annuity) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Automobile) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.EarnestMoneyCashDepositTowardPurchase) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.NetWorthOfBusinessOwned) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.ProceedsFromSaleOfNonRealEstateAsset)
      ), x => x.name);

      this.giftGrantTypes = _.orderBy(assetTypes.filter(asset =>
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CashGift) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.GiftOfEquity) ||
        asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Grant)
      ), x => x.name);

      this.giftGrantSources = enums[Constants.enumerations.giftGrantSource];

      let creditTypes = enums[Constants.enumerations.purchaseCreditTypes];
      this.purchaseCreditTypes = _.orderBy(creditTypes.filter(credit =>
        credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.EmployerAssistedHousing) ||
        credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LotEquity) ||
        credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.RelocationFunds) ||
        credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LeasePurchaseFund) ||
        credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.TradeEquity) ||
        credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.Other) ||
        credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.SweatEquity) ||
        credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.DepositOnSalesContract) ||
        credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.MIPremiumRefund) ||
        credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.SellerCredit)
      ), x => x.name);

      this.allAssetTypes = [
        ...this.assetTypes.map(t => {
          t.groupName = "Bank/Retirement";
          return t;
        }),
        ...this.otherAssetTypes.map(t => {
          t.groupName = "Other Assets";
          return t;
        }),
        ...this.giftGrantTypes.map(t => {
          t.groupName = "Gifts or Grants";
          return t;
        })
      ];

    });

    this.calculateSubTotal();
  }

  haveMultipleRowSelected = () => {
    return (
      Object.keys(this.selectedRows).filter(r => this.selectedRows[r]).length +
      Object.keys(this.selectedRowsCredit).filter(r => this.selectedRowsCredit[r]).length
    ) > 0;
  }

  isLiquidAsset = (type: string) => {
    return this.assetTypes.map(te => te.value).includes(type);
  }


  isGiftOrGrantType = (type: string) => {
    return type ? this.giftGrantTypes.map(t => t.value).includes(type) : false;
  }

  private clearCreatingItemId(): void {
    this.creatingItemId = null;
  }

  private onCollapseItem(collapsedItemId: number): void {
    if (this.creatingItemId === collapsedItemId) {
      this.creatingItemId = null;
    }
  }

  protected onChangeAsset(): void {
    this.updateMortgageAssets();
    this.calculateSubTotal();
  }

  private updateMortgageAssets() {
    resetArrayTo(this._mortgage.assets, this.assets);
  }

  protected onClickCloseAsset(assetId: number): void {
    this.collapseAsset(assetId);
  }

  protected onClickCancelAsset(assetId: number): void {
    this.collapseAsset(assetId);

    const isExisting = this.existingItems[assetId];
    if (!isExisting) {
      this.removeAsset(assetId);
    }

    this.calculateSubTotal();
  }

  private removeAsset(assetId: number) {
    this.assets = this.assets.filter(a => a.assetId != assetId);
    this.updateMortgageAssets();
  }

  protected toggleExpandAsset(assetId: number): void {
    if (this.expandedItems[assetId]) {
      this.collapseAsset(assetId);
    } else {
      this.expandAsset(assetId);
    }
  }

  private expandAsset(assetId: number): void {
    this.expandedItems[assetId] = true;
  }

  private collapseAsset(assetId: number): void {
    this.expandedItems[assetId] = false;
    this.onCollapseItem(assetId);
  }

  addAssetClicked = () => {
    let asset = new Asset();
    asset.assetId = this._utilityService.getUniqueId();
    asset.owningBorrowerIds = this.possibleAccountOwners && this.possibleAccountOwners.length == 1 ?
      [this.possibleAccountOwners[0].id] : [];
    asset.isDeposited = true;

    this.assets = [...this.assets, asset];
    this.updateMortgageAssets();
    this.expandAsset(asset.assetId);
    this.existingItems[asset.assetId] = false;
    this.creatingItemId = asset.assetId;
  }

  protected onChangeCredit(): void {
    this.updateMortgageCredits();
    this.calculateSubTotal();
  }

  private updateMortgageCredits(): void {
    resetArrayTo(this._mortgage.transactionDetail.purchaseCredits, this.credits);
  }

  protected onClickCloseCredit(creditId: number): void {
    this.collapseCredit(creditId);
  }

  protected onClickCancelCredit(creditId: number): void {
    this.collapseCredit(creditId);

    const isExisting = this.existingCredits[creditId];
    if (!isExisting) {
      this.removeCredit(creditId);
    }

    this.calculateSubTotal();
  }

  private removeCredit(creditId: number) {
    this.credits = this.credits.filter(c => c.purchaseCreditId != creditId);
    this.updateMortgageCredits();
  }

  protected toggleExpandCredit(creditId: number) {
    if (this.expandedItemsCredit[creditId]) {
      this.collapseCredit(creditId);
    } else {
      this.expandCredit(creditId);
    }
  }

  private expandCredit(creditId: number) {
    this.expandedItemsCredit[creditId] = true;
  }

  private collapseCredit(creditId: number) {
    this.expandedItemsCredit[creditId] = false;
    this.onCollapseItem(creditId);
  }

  addCreditClicked = () => {
    let credit = new PurchaseCredit();
    credit.purchaseCreditId = this._utilityService.getUniqueId();

    this.credits = [...this.credits, credit];
    this.updateMortgageCredits();
    this.expandCredit(credit.purchaseCreditId);
    this.existingCredits[credit.purchaseCreditId] = false;
    this.creatingItemId = credit.purchaseCreditId;
  }

  protected async bulkDeleteClicked() {
    let selectedAssetIds = Object.keys(this.selectedRows).filter(r => this.selectedRows[r]);
    let selectedCreditIds = Object.keys(this.selectedRowsCredit).filter(r => this.selectedRowsCredit[r]);

    const result = await Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this ' + (selectedAssetIds.length + selectedCreditIds.length) + ' selected records?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    });

    if (!result?.value) return;

    selectedAssetIds.forEach(id => {
      delete this.expandedItems[id];
      delete this.selectedRows[id];
      delete this.hoveredItems[id];
      this.assets.splice(this.assets.findIndex(a => a.assetId == Number(id)), 1);
    });

    selectedCreditIds.forEach(id => {
      delete this.expandedItemsCredit[id];
      delete this.selectedRowsCredit[id];
      delete this.hoveredItemsCredit[id];
      this.credits.splice(this.credits.findIndex(a => a.purchaseCreditId == Number(id)), 1);
    });

    const mortgage = this._mortgage;
    mortgage.assets = [...this.assets];

    this.calculateSubTotal();

    const { transactionDetail } = mortgage;
    if (transactionDetail) {
      transactionDetail.purchaseCredits = [...this.credits];
    }

    // close all expanded rows
    this.assets.forEach(a => {
      this.collapseAsset(a.assetId);
    });
  }

  calculateSubTotal = () => {
    this.assetsTotal = 0;
    this.creditsTotal = 0;

    this.assets.forEach(asset => {
      if (asset.isDeposited) {
        this.assetsTotal += Number(asset.cashMarketValue || 0);
      }
    });

    this.credits.forEach(credit => {
      if (!credit['isOmitted']) {
        this.creditsTotal += Number(credit.purchaseCreditAmount || 0);
      }
    });

    this._mortgage.calculatedStats.totalOtherCredit = this._calculationService.calculateTotalOtherCredit(this._mortgage);
    this._mortgage.calculatedStats.totalCredit = this._calculationService.calculateTotalCredit(this._mortgage);
    this._mortgage.calculatedStats.totalDueFromBorrowers = this._calculationService.calculateTotalDueFromBorrowers(this._mortgage);
    this._mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this._mortgage);
  }

  private isFilteredAsset = (assetType, assetInsertionType) => {
    if (assetInsertionType == 1) return true;

    return assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Bond) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CheckingAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BridgeLoanNotDeposited) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.LifeInsurance) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CertificateOfDepositTimeDeposit) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.IndividualDevelopmentAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MoneyMarketFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MutualFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RetirementFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SavingsAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Stock) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.TrustAccount);
  }

  private initializeAssets = () => {
    this.assets = [];
    if (this._mortgage.assets) {
      this._mortgage.assets.forEach(asset => {
        if (this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) {
          let borrIds = [];
          asset.owningBorrowerIds.forEach(borrowerId => {
            borrIds.push(borrowerId.toString());
          });
          asset.owningBorrowerIds = borrIds;
          this.assets.push(asset);
        }
      });
    }
    this.credits = [];
    if (this.mortgage.transactionDetail.purchaseCredits) {
      this.mortgage.transactionDetail.purchaseCredits.forEach(c => {
        this.credits.push(c);
      });
    }
  }

  private initializePossibleAccountOwners = () => {
    this.possibleAccountOwners = [];
    if (this._mortgage.borrowers !== null) {
      this._mortgage.borrowers.forEach(borrower => {
        const borrowerFullName = this._utilityService.getBorrowerFullName(borrower);

        const possibleAccountOwner = {
          id: borrower.borrowerId.toString(),
          text: borrowerFullName
        };
        this.possibleAccountOwners.push(possibleAccountOwner);
      });
    }
  }


}
