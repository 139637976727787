<div class="row">

  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}"
    *ngIf="enabledChannels.length > 0 && !isSolar">
    <label for="channel">Channel</label>
    <select #loanChannel id="channel" name="channel" class="form-select col-sm-4" [(ngModel)]="lead.channel" (ngModelChange)="onChannelChanged()" #channelInput="ngModel"
      [ngClass]="{'is-invalid' : channelInput && channelInput.touched && channelInput.invalid}" required>
      <option [ngValue]="null">-- Select One --</option>
      <option *ngFor="let item of enabledChannels" [ngValue]="item.name" [hidden]="item.name == 'Wholesale' && !isTpo">
        {{ item.name }}
      </option>
    </select>
    <div class="invalid-feedback">This field is required</div>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}" *ngIf="!isTpo && !isSolar">
    <label for="assignedUserName">Lead Assignment</label>
    <select id="assignedUserName" name="assignedUserName" class="form-select col-sm-4"
      [(ngModel)]="lead.leadContactUserId">
      <option [ngValue]="null">-- Select One --</option>
      <option *ngFor="let user of users" [ngValue]="user.userCompanyGuid">
        {{ user.lastName + ', ' + user.firstName }}
      </option>
    </select>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}"
    *ngIf="enabledChannels.length == 0 && !isSolar">
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="loanPurposeId">Loan Purpose</label>
    <select #loanPurpose class="form-select" name="loanPurposeId" id="loanPurposeId" [(ngModel)]="lead.loanPurposeId"
      #loanPurposeIdInput="ngModel" [ngClass]="{
        'is-invalid':
          loanPurposeIdInput &&
          loanPurposeIdInput.touched &&
          loanPurposeIdInput.invalid
      }" (ngModelChange)="onLoanPurposeChanged()" required>
      <option [ngValue]="null">--Select One--</option>
      <option *ngFor="let loanPurpose of loanPurposes" [ngValue]="loanPurpose.loanPurposeId">
        {{ loanPurpose.loanPurposeName }}
      </option>
    </select>
    <div class="invalid-feedback">This field is required</div>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}" *ngIf="!isSolar">
    <label for="loanTypeId">Loan Type</label>
    <select #loanType class="form-select" name="loanTypeId" id="loanTypeId" [(ngModel)]="lead.loanTypeId">
      <option [ngValue]="null">--Select One--</option>
      <option *ngFor="let loanType of loanTypes" [ngValue]="loanType.loanTypeId">
        {{ loanType.loanTypeName }}
      </option>
    </select>
  </div>
  <!-- <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="leadStatus">Lead Status</label>
    <select id="leadStatus" name="leadStatus" class="form-select mb-1" [(ngModel)]="lead.leadStatusId"
      [disabled]="!lead.leadId">
      <option [ngValue]="null">-- Select One --</option>
      <option *ngFor="let leadStatus of leadStatuses" [ngValue]="leadStatus.loanStatusId">
        {{ leadStatus.loanStatusName }}
      </option>
    </select>
    <span *ngIf="!agentsLoading && lead.leadId && !lead.loanPurposeId" class="error">Please select loan purpose in order
      to populate the Lead Status</span>
    <span *ngIf="!agentsLoading && !lead.leadId" style="color: #275c41; font-weight: 400">
      You must save this lead before you can change lead status.
    </span>
  </div> -->

  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}" *ngIf="!isSolar">
    <label for="referralSources">Referral Source</label>

    <div class="d-flex">
      <div class="flex-grow-1">
        <ng-select width="100%" name="referralSources" bindLabel="displayName" bindValue="agentId"
          placeholder="Select Referral Source" [closeOnSelect]="true" [(ngModel)]="lead.referralSource"
          [items]="referralSources">
        </ng-select>
      </div>
      <a class="btn btn-outline-primary" style="margin-left: 3px;" (click)="onAddNewAgentClicked()">
        <i class="float-end fas fa-plus" style="margin-top: 4px"></i>
      </a>
    </div>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}" *ngIf="!isSolar">
    <label for="insideReferralSource">Inside Referral Source</label>
    <select id="insideReferralSource" name="insideReferralSource" class="form-select"
      [(ngModel)]="lead.insideReferralSource">
      <option [ngValue]="null">-- Select One --</option>
      <option *ngFor="let user of insideReferralSources" [ngValue]="user.userCompanyGuid">
        {{ user.lastName + ', ' + user.firstName }}
      </option>
    </select>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}" *ngIf="lead.referralSource && !isSolar">
    <label for="secondaryReferralSource">Secondary Referral Source</label>

    <div class="d-flex">
      <div class="flex-grow-1">
        <ng-select width="100%" name="secondaryReferralSource" bindLabel="displayName" bindValue="agentId"
          placeholder="Select Referral Source" [closeOnSelect]="true" [(ngModel)]="lead.secondaryReferralSource"
          [items]="referralSources">
        </ng-select>
      </div>
      <a class="btn btn-outline-primary" style="margin-left: 3px;" (click)="onAddNewAgentClicked()">
        <i class="float-end fas fa-plus" style="margin-top: 4px"></i>
      </a>
    </div>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}" *ngIf="!isSolar">
    <label for="referredTo">Referred To</label>

    <div class="d-flex">
      <div class="flex-grow-1">
        <ng-select width="100%" name="referredTo" bindLabel="displayName" bindValue="agentId"
          placeholder="Select Referred To" [closeOnSelect]="true" [(ngModel)]="lead.referredTo"
          [items]="referralSources">
        </ng-select>
      </div>
      <a class="btn btn-outline-primary" style="margin-left: 3px;" (click)="onAddNewAgentClicked()">
        <i class="float-end fas fa-plus" style="margin-top: 4px"></i>
      </a>
    </div>
  </div>

  <!-- tags -->
  <div class="row border py-3" style="margin-left: 0px" [hidden]="!showTags">
    <div class="col-md-6">
      <h5>Tags </h5>

      <ng-template [ngIf]="!loadingLeadsList" [ngIfElse]="loadingAllLeadsSpinner">
        <ng-multiselect-dropdown name="leadListsDropdown" [placeholder]="'-- Select --'" [settings]="multiSelectSettings"
          [data]="allLeadLists" [(ngModel)]="leadLists" (onSelect)="selectTag($event)" (onDeSelect)="deSelectTag($event)">
        </ng-multiselect-dropdown>

        <div class="input-group mt-2">
          <input class="form-control" placeholder="New Tag" name="newTag" [(ngModel)]="newListTag"
            [ngModelOptions]="{standalone: true}">
          <span class="input-group-append">
            <button class="btn btn-soft-primary border" name="addNewTagButton" type="button" [disabled]="!newListTag"
              (click)="addNewTag()">
              Add New Tag
            </button>
          </span>
        </div>
      </ng-template>
      <ng-template #loadingAllLeadsSpinner>
        <loading-indicator [loadingMessage]="null"></loading-indicator>
      </ng-template>
    </div>
  </div>
</div>
