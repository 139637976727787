<qa-page-body>
  <ng-container slot='header'>
    FHA Loan Info
  </ng-container>

  <ng-container slot='actions'>
    <save-button (onClick)='save()' [isDisabled]='!canSave'
                 [saving]='isLoading'>
    </save-button>
  </ng-container>

  <div slot='body' class='fha-body' #body>
    <ng-container *ngIf='isInitialized; else loadingTemplate'>
      <ng-container *ngTemplateOutlet='bodyContent'>
      </ng-container>
    </ng-container>
  </div>
</qa-page-body>

<ng-template #loadingTemplate>
  <loading-indicator [loadingMessage]="'Loading, please wait...'">
  </loading-indicator>
</ng-template>

<ng-template #bodyContent>
  <ng-container [formGroup]='form'>
    <!-- HUD/FHA Addendum -->
    <ng-container formGroupName='hudFhaAddendum'>
      <section class='card fha-section'>
        <div class='fha-section-header'>
          <h5>HUD/FHA Addendum</h5>
          <span class='text-muted'>Form 26-1802a</span>
        </div>

        <div class='fha-form-body'>
          <!-- Agency Case Number -->
          <!-- FIXME: Duplicated from qa-va-info.component -->
          <div class='form-group'>
            <label for='agencyCaseNumber'>
              2. Agency Case Number

              <!-- FIXME: This definition is from the internet -->
              <i class='fas fa-info-circle ms-1'
                 ngbTooltip='The Agency Case Number is the number assigned to the
                loan application by the VA. This number is required to obtain a
                Certificate of Eligibility (COE) from the VA.'
              >
              </i>
            </label>
            <input class='form-control' formControlName='agencyCaseNumber'
                   id='agencyCaseNumber' type='text'>
          </div>

          <div class='horizontal-divider'></div>

          <!-- Section of the Act * -->
          <div class='form-group'>
            <label for='sectionOfTheAct'>
              4. Section of the Act
            </label>
            <select class='form-control' formControlName='sectionOfTheAct'
                    id='sectionOfTheAct'>
              <option value=''>-- Select One --</option>
              <option value='1'>203(b) Basic Program, Purchase, Refis</option>
            </select>
          </div>

          <div class='horizontal-divider'></div>

          <!-- Lender/Mortgage I.D. Code -->
          <div class='form-group'>
            <label for='lenderOrMortgageIdCode'>
              13. Lender/Mortgage I.D. Code
            </label>
            <input class='form-control' formControlName='lenderOrMortgageIdCode'
                   id='lenderOrMortgageIdCode' type='text'>
          </div>

          <div class='horizontal-divider'></div>

          <!-- Sponsor/Agent I.D. Code -->
          <div class='form-group'>
            <label for='sponsorOrAgentIdCode'>
              14. Sponsor/Agent I.D. Code
            </label>
            <input class='form-control' formControlName='sponsorOrAgentIdCode'
                   id='sponsorOrAgentIdCode' type='text'>
          </div>
        </div>
      </section>
    </ng-container>

    <!-- Other Info -->
    <ng-container formGroupName='otherInfo'>
      <section class='card fha-section'>
        <div class='fha-section-header'>
          <h5>Other Info</h5>
        </div>

        <div class='fha-form-body'>
          <!-- Positive Rental History -->
          <div class='form-group'>
            <label for='positiveRentalHistory'>
              Positive Rental History *

              <!-- FIXME: This is a dummy definition -->
              <i class='fas fa-info-circle ms-1'
                 ngbTooltip='The borrower must have a positive rental history for
                the period of time the borrower has been renting.'
              >
              </i>
            </label>
            <select class='form-control' formControlName='positiveRentalHistory'
                    id='positiveRentalHistory'>
              <option value=''>-- Select One --</option>
              <option value='1'>Yes</option>
              <option value='0'>No</option>
            </select>
          </div>

          <div class='horizontal-divider'></div>

          <!-- MIP Refund Amount -->
          <div class='form-group'>
            <label for='mipRefundAmount'>
              MIP Refund Amount
            </label>
            <input class='form-control' currencyMask
                   formControlName='mipRefundAmount' id='mipRefundAmount'
                   type='text'>
          </div>

          <div class='horizontal-divider'></div>

          <!-- Seller Concession -->
          <div class='form-group'>
            <label for='sellerConcession'>
              Seller Concession
            </label>
            <input class='form-control' formControlName='sellerConcession'
                   id='sellerConcession' type='text'>
          </div>

          <div class='horizontal-divider'></div>

          <!-- Sponsor Originator EIN -->
          <div class='form-group'>
            <label for='sponsorOriginatorEin'>
              Sponsor Originator EIN
            </label>
            <input class='form-control' formControlName='sponsorOriginatorEin'
                   id='sponsorOriginatorEin' type='text'>
          </div>

          <div class='horizontal-divider'></div>

          <!-- Mortgage Credit Cert -->
          <div class='form-group'>
            <label for='mortgageCreditCert'>
              Mortgage Credit Cert
            </label>
            <input class='form-control' formControlName='mortgageCreditCert'
                   id='mortgageCreditCert' type='text'>
          </div>

          <!-- CAIVRID -->
          <ng-container
            *ngFor='let person of persons; index as i'
            formGroupName='persons'>
            <div class='horizontal-divider'></div>

            <ng-container formGroupName='person{{i}}'>
              <div class='form-group'>
                <label for='caivrid{{i}}'>{{person.name}}'s CAIVRID #</label>
                <input class='form-control' formControlName='caivrid'
                       id='caivrid{{i}}' type='text'>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </section>
    </ng-container>
  </ng-container>
</ng-template>
