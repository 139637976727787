import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mortgage-save-dialog',
  templateUrl: 'mortgage-save-dialog.component.html'
})

export class MortgageSaveDialogComponent implements OnInit {

  message: string = "You have changes that are not saved. Would you like to save your changes? If you choose Discard below, your changes will be lost.";

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

}
