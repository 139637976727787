import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Liability } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaMortgage } from '../../../models/urla-mortgage.model';
import {MortgageCalculationService} from "../../../services/mortgage-calculation.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnterPayoffDialogComponent } from '../enter-payoff-dialog/enter-payoff-dialog.component';

@Component({
  selector: 'add-edit-liability',
  templateUrl: './add-edit-liability.component.html',
  styleUrls: ['./add-edit-liability.component.scss']
})
export class AddEditLiabilityComponent implements OnInit {

  @ViewChild("liabilityForm") liabilityForm: NgForm | undefined;

  @Input()
  liability: Liability;

  @Input()
  possibleReoAddresses;

  @Input()
  liabilityTypeOptions;

  @Input()
  possibleAccountOwners: Array<{
    id: number;
    text: string;
  }>;

  @Input()
  mortgage: UrlaMortgage;

  @Output()
  saveLiability: EventEmitter<Liability> = new EventEmitter<Liability>();

  @Output()
  closeDrawer: EventEmitter<any> = new EventEmitter<any>();

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
  };

  liabilityTypeHELOC;

  liabilityTypeMortgageLoan;

  monthlyPaymentOptions = [];

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.liabilityTypeHELOC = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC);
    this.liabilityTypeMortgageLoan = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan);

    if (this.liability.typeOfLiability == this.liabilityTypeHELOC ||
      this.liability.typeOfLiability == this.liabilityTypeMortgageLoan) {
      this.populateReoAddresses();
    }
    this.setMonthlyPaymentOptions(this.liability);
    this.liability.owningBorrowerIds = this.liability.owningBorrowerIds.map(id => Number(id));
  }

  populateReoAddresses = () => {
    let counter = 0;
    this.possibleReoAddresses = [];
    if (this.mortgage.realEstateOwned && this.mortgage.realEstateOwned.length > 0) {
      this.mortgage.realEstateOwned.forEach(reo => {
        this.liability.owningBorrowerIds.forEach(borrowerId => {
          if (reo.owningBorrowerIds.indexOf(Number(borrowerId)) >= 0) {
            let exists = this.possibleReoAddresses.find(p => p.value == reo.reoId);
            if (!exists) {
              this.possibleReoAddresses.push(new EnumerationItem(reo.address1, reo.reoId));
            }
          } else {
            counter++;
          }
        });

      });
      if (counter == this.mortgage.realEstateOwned.length * this.liability.owningBorrowerIds.length) {
        this.liability.reoId = null;
      }
    }
  }

  accountTypeOrBorrowerChange = () => {
    if (this.liability.typeOfLiability == this.liabilityTypeHELOC ||
      this.liability.typeOfLiability == this.liabilityTypeMortgageLoan) {
      this.populateReoAddresses();
    } else {
      this.liability.reoId = null;
    }
  }

  checkPayoffAmount = () => {
    if (this.liability.partialPayoffAmount >= this.liability.unpaidBalance) {
      this.liability.partialPayoffAmount = this.liability.unpaidBalance;
    }
  }

  onCancelClicked = () => {
    this.closeDrawer.emit();
  }

  onSaveClicked = () => {
    if (this.liabilityForm) {
      this.liabilityForm.form.markAllAsTouched();
      if (this.liabilityForm.form.valid) {
        this.saveLiability.emit(this.liability);
        this.closeDrawer.emit();
      }
    }
  }

  setMonthlyPaymentOptions = (liability: Liability) => {
    this.monthlyPaymentOptions = this._calculationService.setMonthlyPaymentOptions(liability);
  };

  setMonthlyPayment = (liability: Liability, percent: number) => {
    this._calculationService.setMonthlyPayment(liability, percent);
  }

  openPayoffDialog = (liability: Liability) => {
    const modalRef = this._modalService.open(EnterPayoffDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.partialPayoffAmount = liability.partialPayoffAmount;
    modalRef.componentInstance.unpaidBalance = liability.unpaidBalance;

    modalRef.result.then((result) => {
      if (!result) {
        result = 0;
      }
      liability.partialPayoffAmount = result;
    });
  }

  setPayoffAmountToFull = (liability: Liability) => {
    liability.partialPayoffAmount = liability.unpaidBalance;
    liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full);
    this.mortgage.calculatedStats.totalPaidOffForRefinance = this._calculationService.calculateTotalPayOffForRefinance(this.mortgage);
  }

  setPayoffAmountToNone = (liability: Liability) => {
    liability.partialPayoffAmount = 0;
    liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.None);
    this.mortgage.calculatedStats.totalPaidOffForRefinance = this._calculationService.calculateTotalPayOffForRefinance(this.mortgage);
  }
}
