import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { BorrowerAlias, Declarations, Demographics, DomesticRelationshipTypeEnum, HousingExpense } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MortgageService } from '../../../../services/mortgage.service';
import { MortgageAppliedForTypeEnum } from '../../../app-details/components/title-history/models/title-order.model';
import { UrlaBorrower } from '../../models/urla-mortgage.model';
import { UtilityService } from '../../services/utility.service';
import { createCleanUpDependentFieldFunction, createCleanUpOtherDescriptionFunction, createIsRequiredFunction } from '../../urla-utils';
import { AliasInfoComponent } from '../alias-info/alias-info.component';
import { DependentsDialogComponent } from './dependents/dependents-dialog.component';

@Component({
  selector: 'contact-info',
  templateUrl: 'contact-info.component.html',
  viewProviders: [formViewProvider]
})
export class ContactInfoComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('eConsentPopoverLink') eConsentPopoverLink: ElementRef;

  @ViewChildren('aliasInfoComponents') aliasInfoComponents: QueryList<AliasInfoComponent> | undefined

  private _aliasInfoComponentEventSubscriptions: any[] = [];

  @Input()
  borrower: UrlaBorrower;

  @Input()
  loanBorrower: BorrowerDto;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  hiddenFields: string[];

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  isCorrespondent: boolean;

  @Output()
  signingRoleChanged: EventEmitter<any> = new EventEmitter<any>();

  suffixes: EnumerationItem[] = [];

  residencyTypes: EnumerationItem[] = [];

  maritalStatuses: EnumerationItem[] = [];

  domesticRelationshipTypes: EnumerationItem[] = [];

  authorizationMethods: EnumerationItem[] = [];

  yesNoOptions: EnumerationItem[] = [];

  languagePreferenceOptions: EnumerationItem[] = [];

  entityVestingTypeOptions: EnumerationItem[] = [];

  emailPattern = Constants.regexPatterns.email;

  unmarriedAddendum: boolean = undefined;
  isSsnHidden: boolean = false;

  dependentAges: string = '';

  marriedEnumValue: string = "";
  unmarriedEnumValue: string = "";
  titleOnlyEnumValue: string = "";
  nonTitledSpouseEnumValue: string = "";

  isTitleOnlyOrNonTitledSpouse: boolean = false;

  identifyingDocumentsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  protected isRequired: (fieldName: string) => boolean;

  protected DomesticRelationshipTypeEnum = DomesticRelationshipTypeEnum;

  protected signingRoleOptions: EnumerationItem[] = [];
  protected mortgagePartyTypeOptions: EnumerationItem[] = [];

  protected onHasRefusedToProvideLanguageChange: () => void;
  protected onLanguagePreferenceChanged: () => void;

  protected isFhaBorrower: boolean = false;
  protected isTpo: boolean = false;
  protected isCompanyDeepHaven: boolean = false;

  private _dependentModalOptions: NgbModalOptions;

  private _appliedForChangeSubscription: Subscription | null = null;

  private _enums: any;

  private _applicationContextSubscription: Subscription;

  @Output()
  borrowerValidityStatusChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _enumsService: EnumerationService,
    private readonly _modalService: NgbModal,
    private readonly _utilsService: UtilityService,
    private readonly _drawerService: DrawerService,
    private readonly _mortgageService: MortgageService,
    private readonly _applicationContextService: ApplicationContextService
  ) {
    this._dependentModalOptions = {
      //size: 'xl',
      backdrop: 'static',
      centered: true,
      scrollable: true
    };
  }

  ngOnInit() {
    this.isSsnHidden = this.hiddenFields.indexOf("Social Security Number") > -1;

    this._applicationContextSubscription = this._applicationContextService.context.subscribe((ctx) => {
      this.isTpo = ctx.isTpo;
      this.isCompanyDeepHaven = ctx.isCompanyDeepHaven;

      this._enumsService.getMortgageEnumerations().subscribe((result) => {
        this._enums = result;
        this.suffixes = this._enums[Constants.enumerations.suffix];
        this.residencyTypes = this._enums[Constants.enumerations.residencyTypes];
        this.languagePreferenceOptions = this._enumsService.languages;
        this.maritalStatuses = this._enums[Constants.enumerations.maritalStatuses];
        this.domesticRelationshipTypes = this._enums[Constants.enumerations.domesticRelationshipTypes];
        this.signingRoleOptions = this._enums[Constants.mortgageEnumerations.signingRole];
        this.entityVestingTypeOptions = this._enums[Constants.mortgageEnumerations.entityVestingType];
        this.mortgagePartyTypeOptions = this._enums[Constants.mortgageEnumerations.mortgagePartyType];

        if (this.isCompanyDeepHaven) {
          const indexOfTrustOption = this.mortgagePartyTypeOptions.findIndex((option) => option.value === 'Trust');
          if (indexOfTrustOption > -1) {
            this.mortgagePartyTypeOptions.splice(indexOfTrustOption, 1);
          }
        }

        this.yesNoOptions = this._enumsService.getYesNoEnumItems();
        this.unmarriedAddendum = this.borrower.domesticRelationshipType != null || this.borrower.unmarriedRelationshipState != null;
        this.marriedEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.MaritalStatusType.Married);
        this.unmarriedEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.MaritalStatusType.Single);
        this.titleOnlyEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.SigningRole.TitleOnly);
        this.nonTitledSpouseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.SigningRole.NonTitledSpouse);

        this.borrower.declarations = this.borrower.declarations || new Declarations();
        this.borrower.currentHousingExpenses = this.borrower.currentHousingExpenses || new HousingExpense();
        this.borrower.governmentMonitors = this.borrower.governmentMonitors || new Demographics();
        this.authorizationMethods = cloneDeep(this._enumsService.authorizationMethods);

        if (!this.borrower.authorizationMethod) {
          const indexForInternetOption = this.authorizationMethods.findIndex(m => m.value === 'Internet');
          if (indexForInternetOption >= 0) {
            this.authorizationMethods.splice(indexForInternetOption, 1);
          }
        }

        if (!this.borrower.languagePreference) {
          this.borrower.languagePreference = null;
        }
      });
    });

    this.isTitleOnlyOrNonTitledSpouse = this.borrower.signingRole == this.titleOnlyEnumValue ||
      this.borrower.signingRole == this.nonTitledSpouseEnumValue;

    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);
    this.initDependents();
    this.initCleanUpDependentFieldFunctions();
    this.subscribeToAppliedForChange();
  }

  ngAfterViewInit(): void {
    if (this.aliasInfoComponents) {
      this.aliasInfoComponents.changes.subscribe((r) => {
        if (r._results && r._results.length > 0) {
          this._aliasInfoComponentEventSubscriptions.forEach(subscription => {
            subscription.unsubscribe();
          });
          this._aliasInfoComponentEventSubscriptions = [];
          this.subscribeToAliasInfoEvents();
        }
      });

      this.subscribeToAliasInfoEvents();
    }
  }

  ngOnDestroy() {
    this._appliedForChangeSubscription?.unsubscribe();
    this._applicationContextSubscription?.unsubscribe();
  }

  onCreditAuthorizationCheckChanged = (authorized: boolean) => {
    this.borrowerValidityStatusChanged.emit();
  }

  initDependents = () => {
    if (this.borrower.dependents) {
      this.borrower.dependentCount = this.borrower.dependents.length;
      this.dependentAges = this.borrower.dependents.map(age => age).toString();
    }
  }

  onRefusalToProvideInfoDecisionChanged = (value: boolean) => {
    this.borrower.hasRefusedToProvideLanguage = value;
    this.borrowerValidityStatusChanged.emit();
  }

  onDetailsClickedForEConsent = () => {
    this._renderer.selectRootElement(this.eConsentPopoverLink.nativeElement).click();
  }

  onEntityTypeChanged = () => {
  }

  onMortgagePartyTypeChanged = () => {
  }

  private initCleanUpDependentFieldFunctions(): void {
    // Initialize onHasRefusedToProvideLanguageChange
    (() => {
      const onHasRefusedToProvideLanguageChange =
        createCleanUpDependentFieldFunction({
          sourcePropertyName: 'hasRefusedToProvideLanguage',
          dependentPropertyName: 'languagePreference',
          cleaningValue: true,
        }).bind(undefined, this.borrower);
      this.onHasRefusedToProvideLanguageChange = () => {
        onHasRefusedToProvideLanguageChange();
        this.onLanguagePreferenceChanged();
      };
    })();

    this.onLanguagePreferenceChanged = createCleanUpOtherDescriptionFunction({
      sourcePropertyName: 'languagePreference',
      cleaningValue: 'Other',
    }).bind(undefined, this.borrower);
  }

  private subscribeToAppliedForChange() {
    this._appliedForChangeSubscription?.unsubscribe();

    this._appliedForChangeSubscription = this._mortgageService.appliedFor$
      .subscribe((value) => {
        this.isFhaBorrower = value === MortgageAppliedForTypeEnum.FHA;
      });
  }

  dependentEditClick() {
    const modalRef = this._modalService.open(DependentsDialogComponent, this._dependentModalOptions);
    modalRef.componentInstance.dependents = this.borrower.dependents;
    modalRef.result.then((result) => {
      if (result !== 'cancel')
        this.borrower.dependents = result;
      this.initDependents();
    }, (err) => {
      console.log(err);
    });
  }

  onAliasAdded = () => {
    let alias = new BorrowerAlias();
    alias.borrowerAliasId = this._utilsService.getUniqueId();
    if (!this.borrower.aliases) {
      this.borrower.aliases = [];
    }
    this.borrower.aliases.push(alias);
  }

  onSigningRoleChanged = () => {
    let tempIsTitleOnlyOrNonTitledSpouse = this.borrower.signingRole == this.titleOnlyEnumValue ||
      this.borrower.signingRole == this.nonTitledSpouseEnumValue;

    if (tempIsTitleOnlyOrNonTitledSpouse != this.isTitleOnlyOrNonTitledSpouse) {
      this.borrower.employments = [];
      this.borrower.nonEmploymentIncomes = [];
      this.signingRoleChanged.emit();
    }

    this.isTitleOnlyOrNonTitledSpouse = tempIsTitleOnlyOrNonTitledSpouse;
  }

  protected onMaritalStatusChanged(value: string) {
    if (value != this.unmarriedEnumValue) {
      this.unmarriedAddendum = false;
      this.onUnmarriedAddendumChanged(false);
    }
  }

  protected onUnmarriedAddendumChanged(value: boolean) {
    if (!value) {
      delete this.borrower.domesticRelationshipType;
      delete this.borrower.unmarriedRelationshipState;
      this.onDomesticRelationshipTypeChanged();
    }
  }

  protected onDomesticRelationshipTypeChanged(value?: DomesticRelationshipTypeEnum) {
    if (value !== DomesticRelationshipTypeEnum.Other) {
      delete this.borrower.unmarriedRelationshipTypeOther;
    }
  }

  onAttemptedToDeleteAlias = (alias: BorrowerAlias) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this alias?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        const index = self.borrower.aliases.indexOf(alias);
        if (index >= 0) {
          self.borrower.aliases.splice(index, 1)
        }
      }
    });
  }

  onMobilePhoneCopy = () => {
    this.borrower.homePhone = this.borrower.mobilePhone;
  }

  onIdentifyingDocumentsDrawerToggled = () => {
    this._drawerService.show("identifyingDocumentsDrawer3", 10);
  }

  private subscribeToAliasInfoEvents = () => {
    if (this.aliasInfoComponents) {
      const components: AliasInfoComponent[] = this.aliasInfoComponents.toArray();
      components.forEach(item => {
        const deleteItemClickedEventSubscription = item.aliasDeletionAttempted.subscribe(e => this.onAttemptedToDeleteAlias(e));
        this._aliasInfoComponentEventSubscriptions.push(deleteItemClickedEventSubscription);
      });
    }
  }
}
