import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NotificationService} from 'src/app/services/notification.service';
import {ProfileService} from '../../../profile.service';
import {DrawerOptions, DrawerService, DrawerSize} from '../../../../../shared/services/drawer.service';
import { Profile } from 'src/app/models';
import { SendSmsRequest } from 'src/app/models/send-sms-request.model';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'profile-sms-settings',
    templateUrl: './profile-sms-settings.component.html',
    styleUrls: ['./profile-sms-settings.component.scss']
})
export class ProfileSmsSettingsComponent implements OnInit {

    @ViewChild('smsSettingsForm')
    smsSettingsForm: NgForm;

    @Input()
    userProfile: Profile;

    @Input()
    smsAlertsEnabled: boolean;

    protected isLoading = false;

    protected phoneNumberToTestSmsWith: string;

    setupPhoneNumberDrawerOptions: DrawerOptions = {
        size: DrawerSize.XXLarge,
        containerWrapperId: null
    };

    constructor(
        private _service: ProfileService, private _notify: NotificationService,
        private readonly _spinner: NgxSpinnerService,
        private readonly _drawerService: DrawerService
    ) {
    }

    ngOnInit(): void {
    }

    onSendTestSMS() {
        if (this.smsSettingsForm) {
          this.smsSettingsForm.form.markAllAsTouched();
          if (!this.smsSettingsForm.form.valid) {
            return;
          }
        }
        this._spinner.show();
        const request: SendSmsRequest = {
          serviceType: this.userProfile.telephonyService.serviceType,
          fromPhoneNumber: this.userProfile.telephonyService.fromPhoneNumber,
          accountIdentifier: this.userProfile.telephonyService.accountIdentifier,
          authToken: this.userProfile.telephonyService.authToken,
          toPhoneNumber: this.phoneNumberToTestSmsWith,
          message: 'This is a test SMS.',
          companyId: this.userProfile.telephonyService.companyId
        };
        this._service.sendTestSMS(request).subscribe(res => {
                this._notify.showSuccess(
                    'Message sent successfuly',
                    'Profile Service'
                );
            },
            (error) => {
                this._notify.showError(
                    error ? error.message : 'Unable to send test sms',
                    'Profile Service'
                );
            }).add(() => {
            this._spinner.hide();
        });
    }

    setupPhoneNumber() {
        this._drawerService.show('setupPhoneNumberDrawer', 100);
    }
}
