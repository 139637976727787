<div class="card tpo-report-card">
    <div class="card-header">
        <h4 class="card-title"><i class="fas fa-globe"></i> Origination Info </h4>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="form-group col-md-4 col-sm-6 col-xs-6">
                <h5 class="mt-0 mb-1">{{(application.originationDate | date: 'MM/dd/yy' : 'UTC') ?
                    application.originationDate : '---'}}</h5>
                <span class="text-secondary">Origination Date</span>
                <!-- <label for="originationDate"> Origination Date </label>
                <input data-mask="99/99/9999" date-picker class="form-control" id="originationDate" type="text" readonly
                    disabled> -->
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-6">
                <h5 class="mt-0 mb-1">{{application.productPricing.lockExpirationDate ? (application.productPricing.lockExpirationDate | date: 'MM/dd/yy' : 'UTC') : '---'}}</h5>
                <span class="text-secondary">Lock Expiration Date</span>
                <!-- <label for="lockExpirationDate">Lock Expiration Date</label>
                <input data-mask="99/99/9999" date-picker class="form-control" id="lockExpirationDate" type="text"
                    readonly disabled> -->
            </div>

            <div class="form-group col-md-4 col-sm-6 col-xs-6">
                <h5 class="mt-0 mb-1">{{application.cdIssueDate ? (application.cdIssueDate | date: 'MM/dd/yy') : '---'}}</h5>
                <span class="text-secondary">CD Issue Date</span>
                <!-- <label for="cdIssueDate">CD Issue Date</label>
                <input data-mask="99/99/9999" date-picker class="form-control" id="cdIssueDate" type="text" readonly
                    disabled> -->
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-6">
                <h5 class="mt-0 mb-1">{{application.cdSignedDate ? (application.cdSignedDate | date: 'MM/dd/yy') : '---'}}</h5>
                <span class="text-secondary">CD Signed Date</span>
                <!-- <label for="cdSignedDate">CD Signed Date</label>
                <input data-mask="99/99/9999" date-picker class="form-control" id="cdSignedDate" type="text" readonly
                    disabled> -->
            </div>
        </div>
    </div>
</div>
