<div class="form-input bg-transparent">
  <form #radioGroupForm="ngForm">
    <div class="boolean-checkbox boolean-checkbox-horizontal">
      <label class="boolean-checkbox-item" [for]="guid">
        <input class='form-check-input econsentCheckbox' type="checkbox" name="{{guid}}" [id]="guid" [(ngModel)]="borrower.hasValidEConsent"
          [value]="borrower.hasValidEConsent" [ngModelOptions]="{standalone: true}" disabled />
        E-CONSENT AUTHORIZED
      </label>
      <ng-container *ngIf="borrower.hasValidEConsent">
        <a class="e-consent-link float-end" placement="bottom" [popover]="eConsentHistoryPopoverTemplate" containerClass="eConsent"
          #eConsentPopover="bs-popover"
          [outsideClick]="true">
          <i class="fas fa-eye"></i>
        </a>
        <ng-template #eConsentHistoryPopoverTemplate>
          <e-consent-history-popover [borrower]="borrower"> </e-consent-history-popover>
        </ng-template>
      </ng-container>
    </div>
  </form>
</div>
