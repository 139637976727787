<div class="p-3">
  <div class="row mb-3">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h4 class="card-title">
          Insurance
        </h4>
        <button class="btn btn-primary btn-sm" type="button" [outsideClick]="false" [popover]="newCoverageTemplate"
          #pop="bs-popover" data-bs-container="body" data-bs-toggle="popover" placement="bottom">
          <i class="fas fa-plus me-1"></i> New Coverage
        </button>
        <ng-template #newCoverageTemplate>
          <form #newCoverageForm="ngForm" novalidate id="newCoverage" name="newCoverage">
            <div class="form-group">
              <label for="coverageType"> Coverage Type </label>
              <select class="form-select aus-system-type-select" [ngClass]="{
                    'is-invalid':
                    coverageInput &&
                    coverageInput.touched &&
                    coverageInput.invalid
                  }" name="coverageType" #coverageInput="ngModel" required [(ngModel)]="selectedType">
                <option *ngFor="let type of hazardInsuranceCoverageTypes" [ngValue]="type.value">
                  {{ type.name }}
                </option>
              </select>
              <div class="invalid-feedback">Please select a type.</div>
            </div>
          </form>
          <div class="row mb-3">
            <div class="col-md-12 text-end">
              <button class="btn btn-primary btn-sm me-2" (click)="onAddNew(pop)">
                Save
              </button>
              <button class="btn btn-danger btn-sm" (click)="onCancel(pop)">Cancel</button>
            </div>
          </div>
          
        </ng-template>
      </div>
      <div class="card-body">
        <hazard-insurance-details
          *ngFor="let hazardInsuranceDetail of subjectPropertyTaxesAndInsuranceDetails.subjectProperty.hazardInsuranceDetails; let index = index"
          [hazardInsuranceDetail]="hazardInsuranceDetail"
          [index]="index"
          [selectedType]="selectedType"
          (attemptedToDeleteDetail)="onAttemptedToDeleteDetailClicked($event)">
        </hazard-insurance-details>
        <ng-container *ngIf="subjectPropertyTaxesAndInsuranceDetails.subjectProperty.hazardInsuranceDetails.length < 1">
          <span> There is no hazard insurance detail. </span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="hasSaveCancelButtons">
    <div class="col-md-12 text-end">
      <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
      <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
        Save
      </button>
    </div>
  </div>
</div>