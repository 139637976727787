import { ExpressionGroup } from "../expressions/expression-group.model";
import { AuthenticationParameter, CampaignAction, CampaignActionFile } from "./campaign-action.model";
import { PerformanceCounter } from "./performance-counter.model";
import { FacebookOption, GoogleMyBusinessOptionFlat, InstagramOption, PinterestOption, RedditOption, TwitterOption, YouTubeOption } from "./platform.model";

export interface CampaignModel {
  campaignId: number;
  campaignGuid: string;
  name: string;
  contactListId: number;
  preferencesCenterCampaignName: string;
  preventDuplicateSendsPerAction: boolean;
  isTransactionalCampaign: boolean;
  excludeExpressionGroupId: number;
  includeExpressionGroupId: number;
  allowItemsToReEnterCampaign: boolean;
  maxNumberOfReEntries: number;
  minMinutesBetweenReEntry: number;
  excludeExpressionGroup: ExpressionGroup;
  includeExpressionGroup: ExpressionGroup;
  actions: CampaignAction[];
  performanceCounters: PerformanceCounter;
  active: boolean;
  copyFromCampaignId: number;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
  lastUpdated?: string;
  updatedByName?: string;
}

export interface EditEmailAction extends FromSignature {
  campaignActionId?: number;
  description?: string;
  toStaticValues?: string[];
  ccStaticValues?: string[];
  bccStaticValues?: string[];
  recipients: string[];
  ccList: string[];
  bccList: string[];
  templateId: number;
  actionTriggerType: string;
  triggerAfterActionId: number;
  fromDisplayName: boolean;
  fromDisplayNameStaticValue: string;

  body: string;
  subject: string;
  englishBody?: string;
  englishSubject?: string;
  spanishSubject?: string;
  spanishBody?: string;
  chineseSubject?: string;
  chineseBody?: string;
  koreanSubject?: string;
  koreanBody?: string;
  vietnameseSubject?: string;
  vietnameseBody?: string;
  tagalongSubject?: string;
  tagalongBody?: string;

  usingStripoEditor?: boolean;

}

export interface EditSMSAction extends FromSignature {
  campaignActionId: number;
  description?: string;
  toStaticValues: string[];
  recipients: string[];
  actionTriggerType: string;
  triggerAfterActionId: number;
  files: CampaignActionFile[];

  // smsBody: string;
  text: string;
  spanishText: string;
  chineseText: string;
  koreanText: string;
  vietnameseText: string;
  tagalongText: string;
}

export interface FromSignature {
  useCompanyDefault: boolean;
  sendFromCreds: boolean;
  sendFromMergeField: boolean;
  sendFromStaticValue: boolean;
  sendFromLoanContact: boolean;
  appendLoanContactSignature: boolean;
  serviceModelId: number;
  servicePoolId?: number;
  incomingSmsLeadRouteGroupId?: number;
  fromStaticValue: string;
}

export interface LinkedInOption {
  mediaCaptions?: string[];
}

export interface EditSocialMediaAction extends FromSignature {
  campaignActionId: number;
  description: string;
  body: string;
  actionTriggerType: string;
  triggerAfterActionId: number;
  enabledPlatforms: Array<string>;
  facebookOptions: FacebookOption
  linkedInOptions: LinkedInOption,
  instagramOptions: InstagramOption,
  twitterOptions: TwitterOption,
  youTubeOptions: YouTubeOption,
  redditOptions: RedditOption,
  pinterestOptions: PinterestOption,
  googleMyBusinessOptions: GoogleMyBusinessOptionFlat
}

export interface EditApiAction {
  campaignActionId: number;
  description: string;
  url: string;
  httpMethod: HttpActionMethod;
  params: Array<AuthenticationParameter>
  authenticationScheme: AuthenticationScheme;
  headers: Array<AuthenticationParameter>
  body: string;
  bearerToken: string;
  username: string;
  password: string;
  actionTriggerType: string;
  triggerAfterActionId: number;
}

export interface EditVoicemailAction extends FromSignature {
  campaignId: number;
  campaignActionId: number;
  voicemailActionDataId?: number;
  description: string;
  recipients: number[];
  file?: string | File;
  fileName?: string;
  fileGuid?: string;
  actionTriggerType: string;
  triggerAfterActionId: number;
}

export interface EditTaskAction {
  campaignActionId: number;
  description: string;
  taskActionDataId?: number;
  taskId: number;
  actionTriggerType: string;
  triggerAfterActionId: number;
}

export interface GlobalService {
  id: number;
  name: string;
  serviceType: string;
  userName?: string
}

export enum CampaignActionTargetType {
  None = 'None',
  To = 'To',
  Cc = 'Cc',
  Bcc = 'Bcc'
}

export enum ActionTriggerType {
  Immediate = 'Immediate',
  Absolute = 'Absolute',
  TriggerAfter = 'TriggerAfter'
}

export enum ActionType {
  Email = 'Email',
  Sms = 'Sms',
  Voicemail = 'Voicemail',
  API = 'API',
  SocialMedia = 'SocialMedia',
  Task = 'Task'
}

export enum HttpActionMethod {
  Get = 'Get',
  Post = 'Post',
  Put = 'Put',
  Delete = 'Delete',
  Head = 'Head',
  Options = 'Options',
  Patch = 'Patch',
  Merge = 'Merge',
  Copy = 'Copy'
}

export enum AuthenticationScheme {
  NoAuth = 'NoAuth',
  BearerToken = 'BearerToken',
  BasicAuth = 'BasicAuth'
}

export enum HttpParamType {
  GetOrPost = 'GetOrPost',
  UrlSegment = 'UrlSegment',
  HttpHeader = 'HttpHeader',
  QueryString = 'QueryString',
  QueryStringWithoutEncode = 'QueryStringWithoutEncode'
}

export enum SocialMediaPlatform {
  Facebook = 'Facebook',
  FacebookGroups = 'FacebookGroups',
  GoogleMyBusiness = 'GoogleMyBusiness',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Pinterest = 'Pinterest',
  Reddit = 'Reddit',
  Telegram = 'Telegram',
  TikTok = 'TikTok',
  Twitter = 'Twitter',
  YouTube = 'YouTube'
}


