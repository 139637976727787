import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HazardInsuranceDetail, SubjectProperty } from 'src/app/models';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'homeowners-insurance-details',
  templateUrl: 'homeowners-insurance-details.component.html',
  styleUrls: ['./homeowners-insurance-details.component.scss']
})

export class HomeownersInsuranceDetailsComponent implements OnInit {

  @Output()
  changesApproved: EventEmitter<HomeownersInsuranceDetails> = new EventEmitter<HomeownersInsuranceDetails>();

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set subjectProperty(subjectProperty: SubjectProperty) {
    this._subjectProperty = cloneDeep(subjectProperty);
  }

  get subjectProperty(): SubjectProperty {
    return this._subjectProperty;
  }

  @Input()
  coverageAmountDollar: number;

  protected homeownersInsurance: HazardInsuranceDetail;

  protected expirationDate: string;
  protected nextPremiumDueDate: string;
  protected policyCancellationDate: string;

  private _subjectProperty: SubjectProperty;

  constructor() { }

  ngOnInit() { 
   this.homeownersInsurance = this._subjectProperty.hazardInsuranceDetails.find(hI => hI.coverageType === 'Homeowners');
   if (!this.homeownersInsurance) {
    this.homeownersInsurance = new HazardInsuranceDetail();
    this.homeownersInsurance.coverageType = 'Homeowners';
    this._subjectProperty.hazardInsuranceDetails.push(this.homeownersInsurance);
   }
   this.checkExistingDates();
  }

  onSaveClicked = () => {
    this.checkEditingDates();
    const homeownersInsuranceDetails = new HomeownersInsuranceDetails();
    homeownersInsuranceDetails.subjectProperty = this._subjectProperty;
    homeownersInsuranceDetails.coverageAmountDollar = this.coverageAmountDollar;
    this.changesApproved.emit(homeownersInsuranceDetails);
  }

  private checkEditingDates = () => {
    if (this.expirationDate) {
      this.homeownersInsurance.expirationDate = new Date(this.expirationDate).toISOString();
    }
    if (this.nextPremiumDueDate) {
      this.homeownersInsurance.nextPremiumDueDate = new Date(this.nextPremiumDueDate).toISOString();
    }
    if (this.policyCancellationDate) {
      this.homeownersInsurance.policyCancellationDate = new Date(this.policyCancellationDate).toISOString();
    }
  }

  private checkExistingDates = () => {
    if (this.homeownersInsurance.expirationDate) {
      this.expirationDate = new Date(this.homeownersInsurance.expirationDate).toLocaleDateString("en-US");
    }
    if (this.homeownersInsurance.nextPremiumDueDate) {
      this.nextPremiumDueDate = new Date(this.homeownersInsurance.nextPremiumDueDate).toLocaleDateString("en-US");
    }
    if (this.homeownersInsurance.policyCancellationDate) {
      this.policyCancellationDate = new Date(this.homeownersInsurance.policyCancellationDate).toLocaleDateString("en-US");
    }
  }
}

export class HomeownersInsuranceDetails {
  subjectProperty: SubjectProperty;
  coverageAmountDollar: number;
}