import { Component, ElementRef, ViewChild } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { Constants } from 'src/app/services/constants';
import { UrlaTextInputComponent } from '../urla-text-input/urla-text-input.component';

@Component({
  selector: 'urla-ssn-text-input',
  templateUrl: 'urla-ssn-text-input.component.html',
  providers: [MakeProvider(UrlaSsnTextComponent)],
})
export class UrlaSsnTextComponent extends UrlaTextInputComponent {
  @ViewChild('ssn') ssnInput: ElementRef;

  ssnPattern = Constants.regexPatterns.ssn;

  inEditModeForSSN: boolean = false;

  constructor() {
    super();
  }

  onEditModeToggled = () => {
    this.inEditModeForSSN = true;
    if (this.ssnInput) {
      setTimeout(() => {
        this.ssnInput.nativeElement.focus();
      });
    }
  };

  onSsnBlurred = (event: any) => {
    this.inEditModeForSSN = false;
    this.blur.emit(event);
  };
}
