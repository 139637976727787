import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RealEstateOwned } from 'src/app/models';

@Component({
  selector: 'app-reo-gross-rental-calculator-dialog',
  templateUrl: './reo-gross-rental-calculator-dialog.component.html',
  styleUrls: ['./reo-gross-rental-calculator-dialog.component.scss']
})
export class ReoGrossRentalCalculatorDialogComponent implements OnInit {

  @ViewChild('grossRentForm')
  grossRentForm: NgForm | undefined;

  @Input()
  reo: RealEstateOwned;

  grossRentalIncome: number = 0;
  netRentalIncome: number = 0;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.netRentalIncome = this.reo.netRentalIncome || 0;
    this.grossRentalIncome = this.reo.grossRentalIncome || 0;
  }

  onNetRentalIncomeChanged = () => {

    let grossRentalIncome = 0;

    if (!this.reo.isSubjectProperty) {
      grossRentalIncome = this.netRentalIncome + this.reo.monthlyMiscExpenses;
    }
    grossRentalIncome += (this.reo.mortgagePayment || 0);

    grossRentalIncome = grossRentalIncome /
      ((((this.reo.vacancyFactor || 0) / 100) * ((this.reo.percentOwned || 100) / 100)) || 1);

    this.grossRentalIncome = grossRentalIncome;

  }

  onOkClicked = () => {
    if (this.grossRentForm) {
      this.grossRentForm.form.markAllAsTouched();
      if (this.grossRentForm.form.valid) {
        this.activeModal.close({ gross: this.grossRentalIncome, net: this.netRentalIncome });
      }
    }
  }

}
