import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { LoanPassFieldMappingsService } from 'src/app/services/loan-pass-field-mappings.service';
import { PricingCustomFieldService } from 'src/app/services/pricing-custom-field.service';
import { ThirdPartyCredentialsService } from '../../services/third-party-credentials.service';
import { SharedModule } from '../../shared/shared.module';
import { ConfigSharedModule } from '../admin/config-shared/config-shared.module';
import { LenderConfigService } from '../admin/lender-config/services/lender-config.service';
import { EmailConfigModule } from '../email-configuration/email-configuration.module';
import { PrequalLetterSettingsModule } from '../prequal-letter-settings/prequal-letter-settings.module';
import { SmsConfigModule } from '../sms-configuration/sms-configuration.module';
import { AgentPortalSettingsComponent } from './components/agent-portal-settings/agent-portal-settings.component';
import { AppPasswordsDialogComponent } from './components/app-passwords/app-passwords-dialog/app-passwords-dialog.component';
import { AppPasswordsComponent } from './components/app-passwords/app-passwords.component';
import { DeleteAppPasswordDialogComponent } from './components/app-passwords/delete-app-password-dialog/delete-app-password-dialog.component';
import { ApplicationSettingsComponent } from './components/application-settings/application-settings.component';
import { CustomDataItemsModalComponent } from './components/application-settings/custom-data-items-modal/custom-data-items-modal.component';
import { CustomDataSettingComponent } from './components/application-settings/custom-data-setting/custom-data-setting.component';
import { CustomDataUsagesModal } from './components/application-settings/custom-data-usages-modal/custom-data-usages-modal.component';
import { BorrowerPortalSettingsComponent } from './components/borrower-portal-settings/borrower-portal-settings.component';
import { BusinessDaysCalendarComponent } from './components/business-days-calendar/business-days-calendar.component';
import {
  BusinessDaysListViewComponent,
} from './components/business-days-calendar/business-days-list-view/business-days-list-view.component';
import { UpsertCalendarEventComponent } from './components/business-days-calendar/upsert-calendar-event/upsert-calendar-event.component';
import { CompanyProfileExtComponent } from './components/company-profile-ext/company-profile-ext.component';
import { DocumentExportFormatComponent } from './components/document-export-format/document-export-format.component';
import {
  AppraisalCredentialModalComponent,
} from './components/integrations/appraisal/appraisal-credential-modal/appraisal-credential-modal.component';
import { AppraisalComponent } from './components/integrations/appraisal/appraisal.component';
import { AssetVerificationComponent } from './components/integrations/asset-verification/asset-verification.component';
import {
  FormFreeAssetVerificationModal,
} from './components/integrations/asset-verification/form-free-asset-verification-modal/form-free-asset-verification-modal.component';
import { TruvAssetVerificationModal } from './components/integrations/asset-verification/truv-asset-verification-modal/truv-asset-verification-modal.component';
import { AusComponent } from './components/integrations/aus/aus.component';
import {
  DoCredentialEditorDialogComponent,
} from './components/integrations/aus/do-credential-editor-dialog/do-credential-editor-dialog.component';
import {
  RolesFinalizeDoFindingsModalComponent,
} from './components/integrations/aus/do-credential-editor-dialog/roles-finalize-do-findings-modal/roles-finalize-do-findings-modal.component';
import {
  DuCredentialEditorDialogComponent,
} from './components/integrations/aus/du-credential-editor-dialog/du-credential-editor-dialog.component';
import {
  LpaCredentialEditorDialogComponent,
} from './components/integrations/aus/lpa-credential-editor-dialog/lpa-credential-editor-dialog.component';
import {
  CloudPlatformConfigEditorComponent,
} from './components/integrations/cloud-platform/cloud-platform-config-editor/cloud-platform-config-editor.component';
import {
  CloudPlatformExpressionEditorComponent,
} from './components/integrations/cloud-platform/cloud-platform-config-editor/cloud-platform-expression-editor/cloud-platform-expression-editor.component';
import {
  RockConnectionConfigEditorComponent,
} from './components/integrations/cloud-platform/rock-connection/rock-connection-config-editor.component';
import { TransUnionConfigEditorComponent } from './components/integrations/cloud-platform/trans-union/trans-union-config-editor.component';
import { CreditMonitoringComponent } from './components/integrations/credit-monitoring/credit-monitoring.component';
import { CreditModalComponent } from './components/integrations/credit/credit-modal/credit-modal.component';
import { CreditComponent } from './components/integrations/credit/credit.component';
import {
  DisclosuresCredentialModalComponent,
} from './components/integrations/disclosures/disclosures-credential-modal/disclosures-credential-modal.component';
import { DisclosuresIntegrationComponent } from './components/integrations/disclosures/disclosures.component';
import { DocusignCredentialsEditorDialogComponent } from './components/integrations/e-signing/docusign-credentials-editor-dialog/docusign-credentials-editor-dialog.component';
import { ESigningComponent } from './components/integrations/e-signing/e-signing.component';
import {
  ErnstProviderEditorDialogComponent,
} from './components/integrations/fees/ernst-provider-editor-dialog/ernst-provider-editor-dialog.component';
import {
  FeeProviderPickerDialogComponent,
} from './components/integrations/fees/fee-provider-picker-dialog/fee-provider-picker-dialog.component';
import { FeesComponent } from './components/integrations/fees/fees.component';
import {
  LodestarProviderEditorDialogComponent,
} from './components/integrations/fees/lodestar-provider-editor-dialog/lodestar-provider-editor-dialog.component';
import {
  MortgageCTOProviderEditorDialogComponent,
} from './components/integrations/fees/mcto-provider-editor-dialog/mcto-provider-editor-dialog.component';
import { GlobalEmailModalComponent } from './components/integrations/global-email/global-email-modal/global-email-modal.component';
import { GlobalEmailComponent } from './components/integrations/global-email/global-email.component';
import { GlobalRvmModalComponent } from './components/integrations/global-rvm/global-rvm-modal/global-rvm-modal.component';
import { GlobalRvmComponent } from './components/integrations/global-rvm/global-rvm.component';
import { GlobalSmsModalComponent } from './components/integrations/global-sms/global-sms-modal/global-sms-modal.component';
import { GlobalSmsComponent } from './components/integrations/global-sms/global-sms.component';
import { HomeOwnersInsuranceComponent } from './components/integrations/home-owners-insurance/home-owners-insurance.component';
import {
  IncomeCalculationModal,
} from './components/integrations/income-calculation/income-calculation-modal/income-calculation-modal.component';
import { IncomeCalculationComponent } from './components/integrations/income-calculation/income-calculation.component';
import {
  IncomeEmploymentVerificationComponent,
} from './components/integrations/income-employment-verification/income-employment-verification.component';
import { TruvIncomeEmploymentVerificationModal } from './components/integrations/income-employment-verification/truv-income-employment-verification-modal/truv-income-employment-verification-modal.component';
import {
  TwnIncomeEmploymentVerificationModal
} from './components/integrations/income-employment-verification/twn-income-employment-verification-modal/twn-income-employment-verification-modal.component';
import { IntegrationContainerComponent } from './components/integrations/integration-container/integration-container.component';
import { IntegrationPricingComponent } from './components/integrations/integration-pricing/integration-pricing.component';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { LeadPostingComponent } from './components/integrations/lead-posting/lead-posting.component';
import { LdeModalComponent } from './components/integrations/lender-data-exchange/lde-modal/lde-modal.component';
import { LdeComponent } from './components/integrations/lender-data-exchange/lde.component';
import { LicenseValidationVendorEditorDialogComponent } from './components/integrations/license-validation/license-validation-vendor-editor-dialog/license-validation-vendor-editor-dialog.component';
import { LicenseValidationComponent } from './components/integrations/license-validation/license-validation.component';
import { LosIntegrationComponent } from './components/integrations/los/los.component';
import {
  TestCredentialResultsModalComponent,
} from './components/integrations/los/test-credential-results-modal/test-credential-results-modal.component';
import { MersComponent } from './components/integrations/mers/mers/mers.component';
import { MortgageInsuranceQuoteComponent } from './components/integrations/mortgage-insurance-quote/mortgage-insurance-quote.component';
import { PmiRateProVendorEditorDialogComponent } from './components/integrations/mortgage-insurance-quote/pmi-rate-pro-vendor-editor-dialog/pmi-rate-pro-vendor-editor-dialog.component';
import { DdaVendorEditorDialogComponent } from './components/integrations/ocr/dda-vendor-editor-dialog/dda-vendor-editor-dialog.component';
import { OCRVendorPickerDialogComponent } from './components/integrations/ocr/ocr-vendor-picker-dialog/ocr-vendor-picker-dialog.component';
import { OcrComponent } from './components/integrations/ocr/ocr.component';
import {
  VisionetVendorEditorDialogComponent,
} from './components/integrations/ocr/visionet-vendor-editor-dialog/visionet-vendor-editor-dialog.component';
import { OnlineApplicationComponent } from './components/integrations/online-application/online-application.component';
import {
  LenderPriceVendorEditorDialogComponent,
} from './components/integrations/pricing/lenderprice-vendor-editor-dialog/lenderprice-vendor-editor-dialog.component';
import { LoanPassIFrameVendorEditorDialogComponent } from './components/integrations/pricing/loanpass-iframe-vendor-editor-dialog/loanpass-iframe-vendor-editor-dialog.component';
import {
  LoanPassProfileVendorEditorDialogComponent,
} from './components/integrations/pricing/loanpass-profile-vendor-editor-dialog/loanpass-profile-vendor-editor-dialog.component';
import {
  FieldMappingsDialogComponent,
} from './components/integrations/pricing/loanpass-vendor-editor-dialog/field-mappings-dialog/field-mappings-dialog.component';
import {
  LoanPassEnumMappingDialogComponent,
} from './components/integrations/pricing/loanpass-vendor-editor-dialog/field-mappings-dialog/loan-pass-enum-mapping-dialog/loan-pass-enum-mapping-dialog.component';
import {
  LoanPassFieldsMapperComponent,
} from './components/integrations/pricing/loanpass-vendor-editor-dialog/field-mappings-dialog/loan-pass-fields-mapper/loan-pass-fields-mapper.component';
import {
  ResetToDefaultMappingsDialogComponent,
} from './components/integrations/pricing/loanpass-vendor-editor-dialog/field-mappings-dialog/reset-to-default-mappings-dialog/reset-to-default-mappings-dialog.component';
import { LoanpassCustomFieldsDialogComponent } from './components/integrations/pricing/loanpass-vendor-editor-dialog/loanpass-custom-fields-dialog/loanpass-custom-fields-dialog.component';
import {
  LoanPassVendorEditorDialogComponent,
} from './components/integrations/pricing/loanpass-vendor-editor-dialog/loanpass-vendor-editor-dialog.component';
import {
  MeridianVendorEditorDialogComponent,
} from './components/integrations/pricing/meridian-vendor-editor-dialog/meridian-vendor-editor-dialog.component';
import {
  MortechVendorEditorDialogComponent,
} from './components/integrations/pricing/mortech-vendor-editor-dialog/mortech-vendor-editor-dialog.component';
import { OBVendorEditorDialogComponent } from './components/integrations/pricing/ob-vendor-editor-dialog/ob-vendor-editor-dialog.component';
import {
  PollyVendorEditorDialogComponent,
} from './components/integrations/pricing/polly-vendor-editor-dialog/polly-vendor-editor-dialog.component';
import {
  PricingCustomFieldListOptionsComponent,
} from './components/integrations/pricing/pricing-custom-field-list-options/pricing-custom-field-list-options.component';
import { PricingCustomFieldsComponent } from './components/integrations/pricing/pricing-custom-fields/pricing-custom-fields.component';
import { PricingComponent } from './components/integrations/pricing/pricing.component';
import { RepriceFieldsDialogComponent } from './components/integrations/pricing/reprice-fields-dialog/reprice-fields-dialog.component';
import { ReportingComponent } from './components/integrations/reporting/reporting.component';
import { AdditionalConfigComponent } from './components/integrations/shared/additional-config/additional-config.component';
import { ConfirmDeleteComponent } from './components/integrations/shared/confirm-delete/confirm-delete.component';
import { CreateCredentialV2Component } from './components/integrations/shared/create-credential-v2/create-credential-v2.component';
import { CreateCredentialComponent } from './components/integrations/shared/create-credential/create-credential.component';
import { CredentialItemV2Component } from './components/integrations/shared/credential-item-v2/credential-item-v2.component';
import { CredentialItemComponent } from './components/integrations/shared/credential-item/credential-item.component';
import { CredentialModalBasicComponent } from './components/integrations/shared/credential-modal-basic/credential-modal-basic.component';
import { CredentialModalV2Component } from './components/integrations/shared/credential-modal-v2/credential-modal-v2.component';
import { CredentialModalComponent } from './components/integrations/shared/credential-modal/credential-modal.component';
import { CredentialsBasicComponent } from './components/integrations/shared/credentials-basic/credentials-basic.component';
import { DeleteCredentialsComponent } from './components/integrations/shared/delete-credentials/delete-credentials.component';
import { EnableMessageComponent } from './components/integrations/shared/enable-message/enable-message.component';
import { ToggleConfigurationComponent } from './components/integrations/shared/toggle-configuration/toggle-configuration.component';
import { SocialMediaComponent } from './components/integrations/social-media/social-media.component';
import { TitleOrderingModalComponent } from './components/integrations/title-ordering/title-ordering-modal/title-ordering-modal.component';
import { TitleOrderingComponent } from './components/integrations/title-ordering/title-ordering.component';
import { VendorPickerModalComponent } from './components/integrations/vendor-picker-modal/vendor-picker-modal.component';
import { VoiceSmsComponent } from './components/integrations/voice-sms/voice-sms.component';
import { CreditAuthSettingsComponent } from './components/online-app-settings/credit-auth-settings/credit-auth-settings.component';
import { EConsentSettingsComponent } from './components/online-app-settings/e-consent-settings/e-consent-settings.component';
import { GeneralSettingsComponent } from './components/online-app-settings/general-settings/general-settings.component';
import { OnlineAppSettingsComponent } from './components/online-app-settings/online-app-settings.component';
import { SupportToolsComponent } from './components/support-tools/support-tools.component';
import { SystemLevelRoutingModule } from './system-level-routing.module';
import { SystemLevelComponent } from './system-level.component';

@NgModule({
  declarations: [
    SystemLevelComponent,
    ApplicationSettingsComponent,
    CustomDataSettingComponent,
    CustomDataItemsModalComponent,
    BorrowerPortalSettingsComponent,
    AgentPortalSettingsComponent,
    OnlineAppSettingsComponent,
    GeneralSettingsComponent,
    EConsentSettingsComponent,
    CreditAuthSettingsComponent,
    DocumentExportFormatComponent,
    CompanyProfileExtComponent,
    IntegrationsComponent,
    AppPasswordsComponent,
    AppPasswordsDialogComponent,
    DeleteAppPasswordDialogComponent,
    LeadPostingComponent,
    OnlineApplicationComponent,
    CreditComponent,
    AdditionalConfigComponent,
    DeleteCredentialsComponent,
    CredentialsBasicComponent,
    EnableMessageComponent,
    ESigningComponent,
    AusComponent,
    ConfirmDeleteComponent,
    PricingComponent,
    FeesComponent,
    AssetVerificationComponent,
    IncomeEmploymentVerificationComponent,
    IncomeCalculationComponent,
    LosIntegrationComponent,
    TestCredentialResultsModalComponent,
    CredentialItemComponent,
    CreateCredentialComponent,
    CredentialModalComponent,
    DisclosuresIntegrationComponent,
    CredentialModalBasicComponent,
    DisclosuresCredentialModalComponent,
    TitleOrderingComponent,
    HomeOwnersInsuranceComponent,
    TitleOrderingModalComponent,
    AppraisalComponent,
    AppraisalCredentialModalComponent,
    OcrComponent,
    GlobalEmailComponent,
    GlobalEmailModalComponent,
    GlobalSmsComponent,
    GlobalSmsModalComponent,
    GlobalRvmComponent,
    GlobalRvmModalComponent,
    CreditMonitoringComponent,
    ReportingComponent,
    CreditModalComponent,
    VoiceSmsComponent,
    ToggleConfigurationComponent,
    SupportToolsComponent,
    IntegrationPricingComponent,
    SocialMediaComponent,
    LdeComponent,
    LdeModalComponent,
    CloudPlatformConfigEditorComponent,
    RockConnectionConfigEditorComponent,
    CloudPlatformExpressionEditorComponent,
    DuCredentialEditorDialogComponent,
    DoCredentialEditorDialogComponent,
    LpaCredentialEditorDialogComponent,
    IntegrationContainerComponent,
    TransUnionConfigEditorComponent,
    CredentialModalV2Component,
    CredentialItemV2Component,
    CreateCredentialV2Component,
    OBVendorEditorDialogComponent,
    LenderPriceVendorEditorDialogComponent,
    PollyVendorEditorDialogComponent,
    MortechVendorEditorDialogComponent,
    LoanPassVendorEditorDialogComponent,
    LoanPassIFrameVendorEditorDialogComponent,
    LoanPassProfileVendorEditorDialogComponent,
    MersComponent,
    RolesFinalizeDoFindingsModalComponent,
    BusinessDaysCalendarComponent,
    UpsertCalendarEventComponent,
    BusinessDaysListViewComponent,
    ErnstProviderEditorDialogComponent,
    FeeProviderPickerDialogComponent,
    LodestarProviderEditorDialogComponent,
    MortgageCTOProviderEditorDialogComponent,
    FormFreeAssetVerificationModal,
    TwnIncomeEmploymentVerificationModal,
    IncomeCalculationModal,
    CustomDataUsagesModal,
    PricingCustomFieldsComponent,
    PricingCustomFieldListOptionsComponent,
    MeridianVendorEditorDialogComponent,
    OCRVendorPickerDialogComponent,
    DdaVendorEditorDialogComponent,
    VisionetVendorEditorDialogComponent,
    FieldMappingsDialogComponent,
    LoanPassFieldsMapperComponent,
    LoanPassEnumMappingDialogComponent,
    ResetToDefaultMappingsDialogComponent,
    LicenseValidationComponent,
    LicenseValidationVendorEditorDialogComponent,
    MortgageInsuranceQuoteComponent,
    PmiRateProVendorEditorDialogComponent,
    RepriceFieldsDialogComponent,
    LoanpassCustomFieldsDialogComponent,
    DocusignCredentialsEditorDialogComponent,
    TruvAssetVerificationModal,
    VendorPickerModalComponent,
    TruvIncomeEmploymentVerificationModal
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbTooltipModule,
    SystemLevelRoutingModule,
    SharedModule,
    ConfigSharedModule,
    EmailConfigModule,
    SmsConfigModule,
    PrequalLetterSettingsModule,
    TableModule,
    NgSelectModule,
    FullCalendarModule,
    TimepickerModule.forRoot(),
    DropdownModule
  ],
  exports: [
    IntegrationsComponent,
    CreditComponent,
    ESigningComponent,
    AusComponent,
    LosIntegrationComponent,
    SocialMediaComponent,
    IntegrationPricingComponent
  ],
  providers: [
    ThirdPartyCredentialsService,
    LenderConfigService,
    PricingCustomFieldService,
    LoanPassFieldMappingsService
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SystemLevelModule {
}
