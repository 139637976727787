<div class="grid-layout has-border">
    <div class="grid-header mb-1">
        <div class="grid-header-left">
            <div>2b. Other Assets and Credits</div>
        </div>
        <div class="grid-header-right" *ngIf="!thereIsAtLeastOneInvalidLineItem && !isNewAsset">
            <button type="button" *ngIf="!isReadOnly && !inEditMode" class="btn btn-sm btn-soft-primary dropdown-toggle me-2" data-bs-toggle="dropdown"
                aria-expanded="false"><i class="fas fa-plus me-2"></i> Add</button>
            <div class="dropdown-menu">
                <a class="dropdown-item" role="button" (click)="addAsset()">Asset</a>
                <a class="dropdown-item" role="button" (click)="addAsset('EarnestMoneyCashDepositTowardPurchase')" *ngIf="isPurchase">Earnest Money Deposit</a>
                <a class="dropdown-item" role="button" (click)="addPurchaseCredit()" *ngIf="isPurchase">Purchase Credit</a>
                <a class="dropdown-item" role="button" (click)="addCredit()">Credit</a>
                <a class="dropdown-item" role="button" >Gift/Grant - (Add in 4d)</a>
            </div>
        </div>
        <div class="grid-header-right" *ngIf="thereIsAtLeastOneInvalidLineItem || isNewAsset"></div>
    </div>
    <div class="row mb-2">
        <div class="table-responsive" style="border-bottom: none;">
            <table class="table mb-0">
                <thead class="table-light">
                    <tr>
                        <td class="col-md-3 col-sm-3 col-xs-3 ">Account Owner</td>
                        <td class="col-md-2 col-sm-2 col-xs-2 ">Asset Type</td>
                        <td class="col-md-4 col-sm-4 col-xs-4 ">Account Type</td>
                        <td class="col-md-2 col-sm-2 col-xs-2 ">Cash/Market Value</td>
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center">Action</td>
                    </tr>

                </thead>
                <tbody>
                    <tr *ngFor="let asset of otherAssets; let index = index">
                        <td class="col-md-3 col-sm-3 col-xs-3">
                            <ng-select2 [hidden]="index !== assetEditingIx" class="select2 mb-3 select2-multiple"
                                name="owningBorrowerIds{{asset.assetId}}" [(ngModel)]="asset.owningBorrowerIds"
                                (ngModelChange)="onAccountOwnersChanged(asset)"
                                [options]="optionsMultipleSelect" [data]="possibleAccountOwners" required [readonly]="isReadOnly">
                            </ng-select2>
                            <div class="owningBorrowerIds-readonly" *ngIf="index !== assetEditingIx"
                                [ngClass]="{ 'ng-invalid-requested no-border' : asset.owningBorrowerIds.length === 0 }"
                                [ngStyle]="{height: asset.owningBorrowerIds.length === 0 ? '22px' : 'auto'}">
                                <span *ngFor="let owner of asset['owners']">{{owner.name}}<br></span>
                            </div>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2">Asset</td>
                        <td class="col-md-4 col-sm-4 col-xs-4">
                            <urla-dropdown [(ngModel)]="asset.assetType"
                                [options]="otherAssetTypes" name="accountType{{asset.assetId}}"
                                [showBorder]="index === assetEditingIx" [readonly]="index !== assetEditingIx"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.assetType'"
                                [required]="urlaFieldsConfig['mortgage.asset.assetType']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.asset.assetType']?.required == 'requested'">
                            </urla-dropdown>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2 text-end">
                            <urla-currency-input name="cashMarketValue{{asset.assetId}}"
                                [(ngModel)]="asset.cashMarketValue" [readonly]="index !== assetEditingIx"
                                [showBorder]="index === assetEditingIx" (ngModelChange)="calculateSubTotal()" [isOmitted]="!asset.isDeposited"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.cashMarketValue'"
                                [required]="urlaFieldsConfig['mortgage.asset.cashMarketValue']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.asset.cashMarketValue']?.required == 'requested'">
                            </urla-currency-input>
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1">
                            <div class="action" *ngIf="!isReadOnly && !inEditMode">
                                <a href="javascript: void(0);" *ngIf="index !== assetEditingIx && !thereIsAtLeastOneInvalidLineItem && !isNewAsset"
                                    (click)="editOtherAsset(index, asset)"><i class="fa fa-pencil-alt"></i></a>
                                <a href="javascript: void(0);" *ngIf="index === assetEditingIx && asset.owningBorrowerIds && asset.owningBorrowerIds.length"
                                    (click)="assetEditingIx = -1; isNewAsset = false;"><i class="fa fa-check text-success"></i></a>
                                <a href="javascript: void(0);" *ngIf="index === assetEditingIx"
                                    (click)="cancelEditAsset()"><i class="fa fa-times text-success"></i></a>

                                <a href="javascript: void(0);" *ngIf="asset.isDeposited"
                                    (click)="omitAsset(asset, true)"><i class="fa fa-ban"></i></a>
                                <a href="javascript: void(0);" *ngIf="!asset.isDeposited"
                                    (click)="omitAsset(asset, false)"><i class="fa fa-ban red-icon"></i></a>

                                <a href="javascript: void(0);" *ngIf="!isNewAsset" (click)="deleteAssetOrCredit(index, 'otherAsset')"><i
                                        class="fa fa-trash-alt"></i></a>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="otherAssets.length > 0" style="background-color: #aedff314;">
                        <td></td>
                        <td></td>
                        <td class="text-end"> Subtotal of Assets </td>
                        <td><strong>{{otherAssetsSubTotal | currency }}</strong></td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let credit of credits; let index = index">
                        <td class="col-md-3 col-sm-3 col-xs-3">—</td>
                        <td class="col-md-2 col-sm-2 col-xs-2">Credit</td>
                        <td class="col-md-4 col-sm-4 col-xs-4">
                            <urla-dropdown [(ngModel)]="credit.purchaseCreditType"
                                [options]="purchaseCreditTypes"
                                name="accountTypeCredit{{credit.purchaseCreditId}}"
                                [showBorder]="index === creditEditingIx" [readonly]="index !== creditEditingIx"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.assetType'"
                                [required]="urlaFieldsConfig['mortgage.asset.assetType']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.asset.assetType']?.required == 'requested'">
                            </urla-dropdown>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2">
                            <urla-currency-input name="purchaseCreditAmount{{credit.purchaseCreditId}}"
                                [(ngModel)]="credit.purchaseCreditAmount" [readonly]="index !== creditEditingIx"
                                [showBorder]="index === creditEditingIx" [isOmitted]="credit['isOmitted']" (ngModelChange)="calculateSubTotal()"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.cashMarketValue'"
                                [required]="urlaFieldsConfig['mortgage.asset.cashMarketValue']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.asset.cashMarketValue']?.required == 'requested'">
                            </urla-currency-input>
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1">
                            <div class="action" *ngIf="!isReadOnly && !inEditMode">
                                <a title="Edit Asset" href="javascript: void(0);" *ngIf="index !== creditEditingIx && !isNewAsset"
                                    (click)="editCredit(index, credit)"><i class="fa fa-pencil-alt"></i></a>
                                <a title="Confirm Asset Changes" href="javascript: void(0);" *ngIf="index === creditEditingIx"
                                    (click)="creditEditingIx = -1"><i class="fa fa-check text-success"></i></a>
                                <a title="Cancel Asset Changes" href="javascript: void(0);" *ngIf="index === creditEditingIx"
                                    (click)="cancelEditCredit()"><i class="fa fa-times text-success"></i></a>

                                <a title="Omit Asset from Calculations" href="javascript: void(0);" *ngIf="!credit['isOmitted']"
                                    (click)="omitCredit(credit, true)"><i class="fa fa-ban"></i></a>
                                <a title="Include Asset in Calculations" ref="javascript: void(0);" *ngIf="credit['isOmitted']"
                                    (click)="omitCredit(credit, false)"><i class="fa fa-ban red-icon"></i></a>

                                <a title="Delete Asset" href="javascript: void(0);" (click)="deleteAssetOrCredit(index, 'credit')"><i
                                    *ngIf="!isNewAsset" class="fa fa-trash-alt"></i></a>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="credits.length > 0" style="background-color: #aedff314;">
                        <td></td>
                        <td></td>
                        <td class="text-end"> Subtotal of Credits </td>
                        <td><strong>{{creditsSubTotal | currency }}</strong></td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let asset of giftAssets; let index = index">
                        <td class="col-md-3 col-sm-3 col-xs-3">
                            <ng-select2 [hidden]="index !== giftEditingIx" class="select2 mb-3 select2-multiple"
                                name="owningBorrowerIds{{asset.assetId}}" [(ngModel)]="asset.owningBorrowerIds"
                                (ngModelChange)="onAccountOwnersChanged(asset)"
                                [options]="optionsMultipleSelect" [data]="possibleAccountOwners" required [readonly]="isReadOnly">
                            </ng-select2>
                            <div class="owningBorrowerIds-readonly" *ngIf="index !== giftEditingIx"
                                [ngClass]="{ 'ng-invalid-requested no-border' : asset.owningBorrowerIds.length === 0 }"
                                [ngStyle]="{height: asset.owningBorrowerIds.length === 0 ? '22px' : 'auto'}">
                                <span *ngFor="let owner of asset['owners']">{{owner.name}}<br></span>
                            </div>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2">Gift</td>
                        <td class="col-md-4 col-sm-4 col-xs-4">
                            <urla-dropdown [(ngModel)]="asset.assetType"
                                [options]="giftTypes" name="accountType{{asset.assetId}}"
                                [showBorder]="index === giftEditingIx" [readonly]="index !== giftEditingIx"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.assetType'"
                                [required]="urlaFieldsConfig['mortgage.asset.assetType']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.asset.assetType']?.required == 'requested'">
                            </urla-dropdown>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2 text-end">
                            <urla-currency-input name="cashMarketValue{{asset.assetId}}"
                                [(ngModel)]="asset.cashMarketValue" [readonly]="index !== giftEditingIx"
                                [showBorder]="index === giftEditingIx" (ngModelChange)="calculateSubTotal()" [isOmitted]="!asset.isDeposited"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.cashMarketValue'"
                                [required]="urlaFieldsConfig['mortgage.asset.cashMarketValue']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.asset.cashMarketValue']?.required == 'requested'">
                            </urla-currency-input>
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1">
                            <div class="action" *ngIf="!isReadOnly && !inEditMode">
                                <a href="javascript: void(0);" *ngIf="index !== giftEditingIx && !thereIsAtLeastOneInvalidLineItem && !isNewGift"
                                    (click)="editGiftAsset(index, asset)"><i class="fa fa-pencil-alt"></i></a>
                                <a href="javascript: void(0);" *ngIf="index === giftEditingIx && asset.owningBorrowerIds && asset.owningBorrowerIds.length"
                                    (click)="giftEditingIx = -1; isNewGift = false;"><i class="fa fa-check text-success"></i></a>
                                <a href="javascript: void(0);" *ngIf="index === giftEditingIx"
                                    (click)="cancelEditGift()"><i class="fa fa-times text-success"></i></a>

                                <a href="javascript: void(0);" *ngIf="asset.isDeposited"
                                    (click)="omitAsset(asset, true)"><i class="fa fa-ban"></i></a>
                                <a href="javascript: void(0);" *ngIf="!asset.isDeposited"
                                    (click)="omitAsset(asset, false)"><i class="fa fa-ban red-icon"></i></a>

                                <a href="javascript: void(0);" *ngIf="!isNewGift" (click)="deleteAssetOrCredit(index, 'gift')"><i
                                        class="fa fa-trash-alt"></i></a>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="giftAssets.length > 0" style="background-color: #aedff314;">
                        <td></td>
                        <td></td>
                        <td class="text-end"> Subtotal of Gifts </td>
                        <td><strong>{{giftsSubTotal | currency }}</strong></td>
                        <td></td>
                    </tr>
                    <tr style="background-color: #aedff314;">
                        <td></td>
                        <td></td>
                        <td class="text-end"><strong>Total Assets and Credits and Gifts </strong> </td>
                        <td><strong>{{(financialAssetsSubTotal + otherAssetsSubTotal + giftsSubTotal + creditsSubTotal) | currency }}</strong></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
