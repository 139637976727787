import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseKeyDate } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { TpoDisclosurePathPermissions } from 'src/app/modules/admin/tpo-config/models/tpo-configuration.model';
import { LoanService } from 'src/app/services/loan';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'disclosure-path',
  templateUrl: './disclosure-path.component.html',
  styleUrls: ['./disclosure-path.component.scss']
})
export class DisclosurePathComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  disclosurePathPermissions: string;

  @Input()
  tridAppDate: BaseKeyDate;

  @Input()
  disclosurePath: string;

  disclosurePathPermissionsOptions: Array<EnumerationItem> = [];

  disclosuresWillBeSentReadOnly: boolean = false;

  constructor(
    private readonly injector: Injector,
    private readonly _loanService: LoanService
  ) {
    super(injector);

  }

  ngOnInit(): void {
    this._loanService.getKeyDatesByType(this.applicationContext.application.applicationId).subscribe(keyDatesByType => {
      let leIssueDate = keyDatesByType.initialDisclosureSent?.eventDate || keyDatesByType.leIssueDate?.eventDate || keyDatesByType.tpoManualDisclosureRequested?.eventDate;

      if (this.disclosurePathPermissions != TpoDisclosurePathPermissions.BrokerAndLender) {
        this.disclosurePathPermissionsOptions.push(new EnumerationItem(this.disclosurePathPermissions, this.disclosurePathPermissions));
      } else {
        this.disclosurePathPermissionsOptions.push(new EnumerationItem("TBD", TpoDisclosurePathPermissions.Tbd));
        this.disclosurePathPermissionsOptions.push(new EnumerationItem(TpoDisclosurePathPermissions.Broker, TpoDisclosurePathPermissions.Broker));
        this.disclosurePathPermissionsOptions.push(new EnumerationItem(TpoDisclosurePathPermissions.Lender, TpoDisclosurePathPermissions.Lender));
      }

      if (this.disclosurePathPermissions == TpoDisclosurePathPermissions.Lender || this.disclosurePathPermissions == TpoDisclosurePathPermissions.Broker) {
        this.disclosurePath = this.disclosurePathPermissions;
        this.disclosuresWillBeSentReadOnly = true;
      }
      if (leIssueDate) {
        this.disclosurePath = TpoDisclosurePathPermissions.Lender;
        this.disclosuresWillBeSentReadOnly = true;
      }
      if (this.applicationContext.userPermissions.companyId === 222) {
        this.disclosuresWillBeSentReadOnly = true;
      }
    });
  }
}
