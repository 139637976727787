<div *ngIf="!inEditMode" class="form-input" [ngClass]="
  {
    'ng-invalid-requested' : !isValid && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
    'ng-invalid-required' : !isValid && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
    'bg-transparent' : isReadOnly
  }"
  [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden">
    <span class="input-label" style="display: inline;" title="">
        <span class="input-label-overflow">{{label}}</span>
    </span>
    <div class="row" style="margin-left: 0;">
        <div *ngFor="let choice of possibleChoices; let i = index"
            class="form-check form-check-custom form-check-solid">
            <input class="form-check-input" id="checkBoxChoices_{{choice.borrowerId}}_{{uniqueId}}" name="checkBoxChoices_{{choice.borrowerId}}_{{uniqueId}}"
                type="checkbox" [checked]="isSelected(choice)" [disabled]="isDisabled(choice) || isReadOnly"
                (change)="selectionChanged($event.target, choice)" />
            <label class="form-check-label fs-6" for="checkBoxChoices_{{choice.borrowerId}}_{{uniqueId}}">
                {{choice.firstName + ' ' + choice.lastName}}
            </label>
        </div>
        <input [(ngModel)]="borrowerPickerModel" #model="ngModel" [required]="required" name="borrowerPickerModel" hidden />
    </div>
</div>
<edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="true">
</edit-mode-input>
