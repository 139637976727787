import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { LateChargeComponent } from "./late-charge.component";

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    LateChargeComponent
  ],
  exports: [
    LateChargeComponent
  ],
  providers: [
  ]
})
export class LateChargeModule {
  constructor() {
  }
}
