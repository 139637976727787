<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #meridianForm="ngForm" novalidate id="meridianForm">
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Username </label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [(ngModel)]="vendor.userName" #userNameInput="ngModel" name="userName"
          id="userName" [ngClass]="{'is-invalid': userNameInput && userNameInput.touched && userNameInput.invalid}"
          required>
        <div class="invalid-feedback"> This field is required </div>
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Password </label>
      </div>
      <div class="col-md-4">
        <password-input [(ngModel)]="vendor.password" [name]="vendor.vendorName" required="true"></password-input>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"> Url </label>
      </div>
      <div class="col-md-10">
        <input type="text" name="url-field" class="form-control" [(ngModel)]="vendor.url" #urlInput="ngModel"
          [ngClass]="{'is-invalid': urlInput && urlInput.touched && urlInput.invalid}" required />
        <div class="invalid-feedback"> This field is required </div>
      </div>
    </div>
    <ng-container *ngIf="scope !== 'User' && scope !== 'Branch'">
      <div class="row mt-3">
        <div class="col-md-2 text-end">
          <label class="custom-control-label"> Client Id </label>
        </div>
        <div class="col-md-4">
          <input type="text" class="form-control" [(ngModel)]="meridianLinkKeyValuePairs.clientId"
            #clientIdInput="ngModel" name="client-id"
            [ngClass]="{'is-invalid': clientIdInput && clientIdInput.touched && clientIdInput.invalid}" required />
          <div class="invalid-feedback"> This field is required </div>
        </div>
        <div class="col-md-2 text-end">
          <label class="custom-control-label"> Client Secret </label>
        </div>
        <div class="col-md-4">
          <password-input [(ngModel)]="meridianLinkKeyValuePairs.clientSecret" name="clientSecret"
            required="true"></password-input>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 text-end">
          <label class="custom-control-label"> Auth Url </label>
        </div>
        <div class="col-md-10">
          <input type="text" name="auth-url-field" class="form-control" [(ngModel)]="meridianLinkKeyValuePairs.authUrl"
            #authUrlInput="ngModel"
            [ngClass]="{'is-invalid': authUrlInput && authUrlInput.touched && authUrlInput.invalid}" required />
          <div class="invalid-feedback"> This field is required </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 text-end">
          <label class="custom-control-label"> App Code </label>
        </div>
        <div class="col-md-4">
          <input type="text" class="form-control" [(ngModel)]="meridianLinkKeyValuePairs.appCode"
            #appCodeInput="ngModel" name="app-code"
            [ngClass]="{'is-invalid': appCodeInput && appCodeInput.touched && appCodeInput.invalid}" required />
          <div class="invalid-feedback"> This field is required </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="scope == 'User'">
      <div class="row mt-3">
        <div class="col-md-2 text-end">
          <label class="custom-control-label"> Client Secret </label>
        </div>
        <div class="col-md-4">
          <password-input [(ngModel)]="meridianLinkKeyValuePairs.clientSecret" name="clientSecret"
            required="true"></password-input>
        </div>
      </div>
    </ng-container>

    <div class="row mt-3" *ngIf="vendor?.credentialId">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <button type="button" class="btn btn-primary me-2" (click)="onRepriceFieldsClicked()">
          Field Editor
        </button>
      </div>
    </div>
    <div *ngIf="scope !== 'User' && scope !== 'Branch'" class="row mt-3">
      <div class="row pt-4">
        <div class="col-md-12 ">
          <div class="fieldset-title">
            <div class="row">
              <div class="col-md-2 pt-2">Custom Fields</div>
              <div class="col-md-4 text-start">
                <button class="btn btn-outline-primary" (click)="addCustomField()">
                  Add Custom Field
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-indicator *ngIf="loadingCustomFields" [loadingMessage]="'Loading Custom Fields...'"
        [customClass]="'no-shadow'"></loading-indicator>
      <pricing-custom-fields *ngIf="!loadingCustomFields" [customFields]="customFields"></pricing-custom-fields>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>
