<form *ngLet='errors as errors'>
  <div
    *ngIf='!inEditMode'
    [class.bg-transparent]='readonly'
    [class.ng-invalid-requested]='errors.requested'
    [class.ng-invalid-required]='errors.required'
    class='form-input'
    [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden"
  >
      <div class="d-flex justify-content-between">
        <span class="input-label" title="">
          <span class="input-label-overflow">{{label}}</span>
          <button *ngIf="copyButtonVisible" (click)="onCopyClicked.emit()" [hidden]="readonly || disabled" style="float: right; background: transparent; border: none;" title="{{copyTitleText}}">
            <i class="far fa-copy"></i>
          </button>
        </span>
        <div class="d-flex" *ngIf="hasTBDCheckbox">
          <label style="float: right; text-transform: none; margin-top: 5px">
            <input type="checkbox"
              [(ngModel)]="isTBDChecked"
              (ngModelChange)="onTBDCheckChanged()"
              name="isChecked_{{id}}"
              [disabled]="readonly || inEditMode"/>
          </label>
          <span class="input-label-overflow ps-1" style="padding-top: 2px;"> TBD </span>
        </div>
      </div>
    <!-- <span class="input-label" title="">
      <span class="input-label-overflow">{{label}}</span>
    </span> -->
    <address-autocomplete-input
      #control='ngModel'
      (addressChange)='handleAddressChange($event)'
      (blur)='onBlurred($event)'
      *viewKey='autocompleteInputKey'
      [(ngModel)]='value'
      [disabled]='isAddressInputDisabled'
      [placeholder]="placeholder ? placeholder : ''"
      [readonly]='readonly'
      [required]='required'
      id='{{id}}-autocomplete'
      inputClass='input-element'
      name='{{name}}-autocomplete'
    ></address-autocomplete-input>
  </div>
  <edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
  </edit-mode-input>
</form>
