import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LoanStatusComponent,
  LoanTypeComponent,
  SubStatusComponent,
  AdverseReasonComponent,
  LoanPurposeComponent,
  LeadStatusAssociationComponent,
  LeadStatusFlowComponent,
  LoanStatusAssociationComponent,
  LoanStatusFlowComponent,
  KeyDatesComponent,
} from './components';

export const routes: Routes = [
  {
    path: 'loan-status',
    data: {
      breadcrumb: 'Loan Status',
    },
    component: LoanStatusComponent,
  },
  {
    path: 'sub-status',
    data: {
      breadcrumb: 'Sub Status',
    },
    component: SubStatusComponent,
  },
  {
    path: 'loan-type',
    data: {
      breadcrumb: 'Loan Type',
    },
    component: LoanTypeComponent,
  },
  {
    path: 'loan-purpose',
    data: {
      breadcrumb: 'Loan Purpose',
    },
    children: [
      { path: '', component: LoanPurposeComponent },
      {
        path: 'lead-status-association/:loanPurposeId/:loanPurposeName',
        component: LeadStatusAssociationComponent
      },
      {
        path: 'lead-status-flow/:loanPurposeId/:loanPurposeName',
        component: LeadStatusFlowComponent,
      },
      {
        path: 'loan-status-association/:loanPurposeId/:loanPurposeName',
        component: LoanStatusAssociationComponent,
      },
      {
        path: 'loan-status-flow/:loanPurposeId/:loanPurposeName',
        component: LoanStatusFlowComponent,
      },
    ]
  },
  {
    path: 'adverse-reason',
    data: {
      breadcrumb: 'Adverse Reason',
    },
    component: AdverseReasonComponent,
  },
  {
    path: 'key-dates',
    data: {
      breadcrumb: 'Key Dates',
    },
    component: KeyDatesComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoanConfigRoutingModule {}
