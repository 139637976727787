<div class="row">
  <div
    class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}"
    *ngIf="leadCampaigns && leadCampaigns.length > 0"
  >
    <label for="leadCampaign">Lead Campaign</label>
    <select
      id="leadCampaign"
      name="leadCampaign"
      class="form-select"
      [disabled]="lead.leadCampaignId > 0 && !isAdmin"
      [(ngModel)]="lead.leadCampaignId"
      (ngModelChange)="leadCampaignChanged($event)"
    >
      <option [ngValue]="null"></option>
      <option
        *ngFor="let leadCampaign of leadCampaigns"
        [ngValue]="leadCampaign.leadCampaignId"
      >
        {{ leadCampaign.name }}
      </option>
    </select>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="leadSource">Lead Source</label>
    <input
      class="form-control"
      id="leadSource"
      name="leadSource"
      type="text"
      [(ngModel)]="lead.leadSource"
      [disabled]="lead.leadCampaignId > 0  && !isAdmin"
    />
  </div>
</div>
