import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { BaseUrlaInputComponent } from '../base-urla-input.component';
import { MortgageFieldConfig } from '../../../models/urla-fields-config.model';
import { UrlaRequiredState } from '../../../services/utility.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'edit-mode-input',
  templateUrl: 'edit-mode-input.component.html',
  styleUrls: ['./edit-mode-input.component.scss'],
  providers: [MakeProvider(EditModeInputComponent)]
})
export class EditModeInputComponent implements OnInit {

    @ViewChild("urlaFieldsConfigPopover")
    urlaFieldsConfigPopover: NgbPopover;

    @Input()
    label: string;

    @Input()
    name: string;

    @Input()
    fieldBeingEdited: string = '';
  
    @Input()
    urlaFieldsConfig: {};

    @Input()
    showBorder: boolean = true;

    constructor(private elRef: ElementRef) {
    }

    ngOnInit(): void {
        if (!this.urlaFieldsConfig[this.fieldBeingEdited]) {
            this.urlaFieldsConfig[this.fieldBeingEdited] = new MortgageFieldConfig(UrlaRequiredState.None);
        }
    }

    onReadonlyToggled = () => {
        if (this.urlaFieldsConfig[this.fieldBeingEdited].readonly) {
            this.urlaFieldsConfig[this.fieldBeingEdited].hidden = false;
            this.urlaFieldsConfig[this.fieldBeingEdited].required = UrlaRequiredState.None;
        }
    }

    onHiddenToggled = () => {
        if (this.urlaFieldsConfig[this.fieldBeingEdited].hidden) {
            this.urlaFieldsConfig[this.fieldBeingEdited].readonly = false;
            this.urlaFieldsConfig[this.fieldBeingEdited].required = UrlaRequiredState.None;
        }
    }

}