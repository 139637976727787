<div [ngClass]="{'group-container-repeat': !mouseCursorOnDeleteButton,
  'group-container-repeat-pending-delete': mouseCursorOnDeleteButton}">
  <div class="grid-layout">
    <div class="grid-layout-row grid-row">
      <a
        (click)='onDeleteClicked()'
        (mouseout)='mouseCursorOnDeleteButton=false'
        (mouseover)='mouseCursorOnDeleteButton=true'
        *ngIf='!isReadOnly && !inEditMode'
        class='top-right-close link'
      >
        <i class='fa fa-times-circle'></i>
      </a>
    </div>

    <div class="grid-layout-row">
      <!-- Alias Type -->
      <urla-dropdown
        [(ngModel)]='alias.aliasType'
        [inEditMode]='inEditMode'
        [name]="'aliasType' + alias.borrowerAliasId"
        [options]='aliasTypeOptions'
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.alias.aliasType")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-12 col-sm-2'
        fieldBeingEdited='mortgage.borrower.alias.aliasType'
        label='Alias Type'
      ></urla-dropdown>

      <!-- First Name -->
      <urla-text-input
        [(ngModel)]='alias.firstName'
        [inEditMode]='inEditMode'
        [name]="'firstNameAlias' + alias.borrowerAliasId"
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.alias.firstName")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-sm-3'
        fieldBeingEdited='mortgage.borrower.alias.firstName'
        label='First Name'
      ></urla-text-input>

      <!-- Middle Name -->
      <urla-text-input
        [(ngModel)]='alias.middleName'
        [inEditMode]='inEditMode'
        [name]="'middleNameAlias' + alias.borrowerAliasId"
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.alias.middleName")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-sm-3'
        fieldBeingEdited='mortgage.borrower.alias.middleName'
        label='Middle Name'
      ></urla-text-input>

      <!-- Last Name -->
      <urla-text-input
        [(ngModel)]='alias.lastName'
        [inEditMode]='inEditMode'
        [name]="'lastNameAlias' + alias.borrowerAliasId"
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.alias.lastName")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-8 col-sm-3'
        fieldBeingEdited='mortgage.borrower.alias.lastName'
        label='Last Name'
      ></urla-text-input>

      <!-- Name Suffix -->
      <urla-dropdown
        [(ngModel)]='alias.nameSuffix'
        [inEditMode]='inEditMode'
        [name]="'suffixAlias' + alias.borrowerAliasId"
        [options]='suffixes'
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.alias.nameSuffix")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-4 col-sm-1'
        fieldBeingEdited='mortgage.borrower.alias.nameSuffix'
        label='Suffix'
      ></urla-dropdown>
    </div>
  </div>
</div>
