import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { UrlaBorrower } from '../../../models/urla-mortgage.model';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { createIsRequiredFunction } from '../../../urla-utils';
import { DeclarationExplanation, DeclarationExplanationType } from 'src/app/models';
import { NotificationService } from '../../../../../services/notification.service';
import { ExplanationGroupManager } from '../../explanation-group-manager';
import { MortgageFieldConfig } from '../../../models/urla-fields-config.model';

@Component({
  selector: 'about-property',
  templateUrl: 'about-property.component.html',
  styleUrls: ['./about-property.component.scss'],
  viewProviders: [formViewProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AboutPropertyComponent implements OnInit, OnChanges {

  @Input()
  borrower: UrlaBorrower;

  @Input()
  isReadOnly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: Record<string, MortgageFieldConfig>;

  @Input()
  isPurchase: boolean = false;

  yesNoOptions: EnumerationItem[] = [];

  propertyTypesOwned: EnumerationItem[] = [];

  holdTitles: EnumerationItem[] = [];

  protected explanationGroupManager: ExplanationGroupManager;

  protected isRequired: (fieldName: string) => boolean;

  protected readonly DeclarationExplanationType = DeclarationExplanationType;

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _enumsService: EnumerationService,
    private readonly _notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.resetExplanationGroupManager();
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);

    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.propertyTypesOwned = enums[Constants.enumerations.propertyTypesOwned];
      this.yesNoOptions = this._enumsService.getYesNoEnumItems();
      this.holdTitles = enums[Constants.enumerations.holdTitles];
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const borrowerChange = changes.borrower;
    if (borrowerChange) {
      this.resetExplanationGroupManager();
    }
  }

  private resetExplanationGroupManager(): void {
    this.explanationGroupManager = new ExplanationGroupManager(
      this._notificationService,
      this.borrower.declarations,
    );
  }

  protected borrowedDownPaymentChange(): void {
    if (!this.borrower.declarations.borrowedDownPayment) {
      this.borrower.declarations.undisclosedMoneyAmount = null;
    }
  }

  protected occupyPropertyChange(): void {
    if (!this.borrower.declarations.occupyProperty) {
      this.borrower.declarations.havePropertiesOwnership = null;
      this.borrower.declarations.typeOfProperty = null;
      this.borrower.declarations.heldTitleHow = null;
    }
  }

  protected havePropertiesOwnershipChange(): void {
    if (!this.borrower.declarations.havePropertiesOwnership) {
      this.borrower.declarations.typeOfProperty = null;
      this.borrower.declarations.heldTitleHow = null;
      this.borrower.declarations.isFirstTimeHomeBuyer = this.isPurchase ? true : null;
    }
  }

  protected getExplanationGroup(
    explanationType: DeclarationExplanationType,
  ): readonly DeclarationExplanation[] {
    return this.explanationGroupManager.getExplanationGroup(explanationType);
  }

  protected onClickAddExplanation(
    explanationType: DeclarationExplanationType,
  ): void {
    this.explanationGroupManager.addExplanation(explanationType);
  }

  public async onClickDeleteExplanation(
    explanation: DeclarationExplanation,
    indexInGroup: number,
  ): Promise<void> {
    await this.explanationGroupManager.deleteExplanationWithConfirmation(
      explanation,
      indexInGroup,
    );
    this._cdr.markForCheck();
  }
}
