import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Utils } from 'src/app/core/services/utils';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import {
  ChangeOfCircumstanceDetail,
  ChangeOfCircumstanceFeeDetail,
  ChangeOfCircumstanceStatus,
  CocReason,
  DisclosureValidationFeeDetail
} from '../../disclosure-tracking/models/disclosure-tracking.model';
import {
  DisclosureTrackingService
} from '../../disclosure-tracking/services/disclosure-tracking.service';
import { catchError, map, of, switchMap } from 'rxjs';
import { CocReasonsService } from '../coc-details.service';
import { NgForm } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

export enum CocDetailTypeEnum {
  loanEstimate = "LoanEstimate",
  closingDisclosure = "ClosingDisclosure"
}
@Component({
  selector: 'coc-details',
  templateUrl: './coc-details.component.html',
  styleUrls: ['./coc-details.component.scss'],
  viewProviders: [formViewProvider]
})
export class CocDetailsComponent implements OnInit {

  @Input()
  cocDetails: ChangeOfCircumstanceDetail;

  @Input()
  applicationId: number;

  @Input()
  mortgageId: number;

  @Input()
  urlaFieldsConfig: any;

  @Input()
  readonly?: boolean;

  @Output()
  onCocDetailsSaved = new EventEmitter<ChangeOfCircumstanceDetail>();

  @Output()
  onCocDetailsClosed = new EventEmitter<never>();

  @ViewChild("cocDetailsForm")
  cocDetailsForm: NgForm;

  componentId: number;

  revisedDueDate: string;
  selectedOtherReason: string;

  multiSelectSettings: IDropdownSettings = {
    idField: 'value',
    textField: 'name',
    allowSearchFilter: true,
    itemsShowLimit: 3
  }

  reasons: EnumerationItem[] = [];
  selectedReasons: EnumerationItem[] = [];
  allFees: DisclosureValidationFeeDetail[] = [];
  tableFees: DisclosureValidationFeeDetail[] = [];
  selectedFees: DisclosureValidationFeeDetail[] = [];
  cocReasons: CocReason[] = [];
  statuses: EnumerationItem<ChangeOfCircumstanceStatus>[] = [
    {
      value: ChangeOfCircumstanceStatus.Approved,
      name: ChangeOfCircumstanceStatus.Approved
    },
    {
      value: ChangeOfCircumstanceStatus.Cancelled,
      name: ChangeOfCircumstanceStatus.Cancelled
    },
    {
      value: ChangeOfCircumstanceStatus.Disclosed,
      name: ChangeOfCircumstanceStatus.Disclosed
    },
    {
      value: ChangeOfCircumstanceStatus.Rejected,
      name: ChangeOfCircumstanceStatus.Rejected
    },
    {
      value: ChangeOfCircumstanceStatus.Requested,
      name: ChangeOfCircumstanceStatus.Requested
    }
  ];

  showAllFees = false;
  isFeeLevelDisclosure = false;
  isOtherReasonSelected = false;
  isSaving = false;

  constructor(
    private readonly _enumerationService: EnumerationService,
    private readonly _disclosureTrackingService: DisclosureTrackingService,
    private readonly _notifsService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _cocReasonsService: CocReasonsService
  ) {
    this.componentId = Utils.getUniqueId() * (-1);
  }

  ngOnInit(): void {
    if (!this.cocDetails.changeOfCircumstanceReason) {
      this.cocDetails.changeOfCircumstanceReason = null;
    }

    // init readonly value
    this.readonly = this.readonly || this.cocDetails.changeOfCircumstanceStatus !== ChangeOfCircumstanceStatus.Requested;

    this.reasons = this._enumerationService.disclosureReasons;

    this._setSelectedReasons();

    if (this.cocDetails && this.cocDetails.changesOfCircumstanceReceivedDate && !this.revisedDueDate) {
      const currentDate = new Date(this.cocDetails.changesOfCircumstanceReceivedDate);
      this.revisedDueDate = Utils.formatDateWithoutTime(Utils.addBusinessDays(currentDate, 2));
    }

    this._spinnerService.show()
    this._disclosureTrackingService.checkIfDisclosureIsValid(this.applicationId).pipe(
      map(response => response.fees || []),
      switchMap((fees) => {
        this.allFees = fees.map(f => ({
          ...f,
          ['_id']: uuidv4()
        }))

        const increasedFees = this.allFees.filter(fee => fee.didIncrease);

        if (increasedFees.length > 0) {
          this.cocDetails.hasChangeOfCircumstance = true;

          const selectedFees = this.allFees.filter(f => this.cocDetails.feeDetail.find(fd => fd.baselineLoanFeeHistoryId === f.disclosedLoanFeeHistoryId));
          if (selectedFees.length >= increasedFees.length) {
            this._parseDisclosedFees(selectedFees);
          } else {
            this._parseDisclosedFees(increasedFees);
          }

          return this._disclosureTrackingService.getCocReasons();
        }

        return of(null);
      }),
      catchError(error => {
        throw error;
      })
    ).subscribe({
      next: (cocReasons) => {
        this.cocReasons = cocReasons || [];
      },
      error: (error) => {
        this._notifsService.showError(
          error?.message || "Couldn't load data.",
          "Error!"
        );
      }
    }).add(() => this._spinnerService.hide());
  }

  onRowToggled = () => {
    this.cocDetails.feeDetail = this.selectedFees.map(f => ({
      baselineLoanFeeHistoryId: f.disclosedLoanFeeHistoryId
    })) as ChangeOfCircumstanceFeeDetail[];
  }

  onAllRowsToggled = () => {
    this._seeCocFeeDetailBasedOnSelectedFees();
  }

  onDisclosureReasonsChanged = () => {
    this.cocDetails.disclosureReason = this.selectedReasons.map(r => r.value).join(",");
    
    this.isOtherReasonSelected = !!this.selectedReasons.find(r => r.value === "Other");
    if (!this.isOtherReasonSelected) {
      this.cocDetails.disclosureReasonOtherDescription = null;
    }
  }

  getCocReasons = () => {
    if (this.cocReasons?.length > 0) {
      return;
    }
  
    this._spinnerService.show();
    this._disclosureTrackingService.getCocReasons()
      .subscribe({
        next: (cocReasons: CocReason[]) => {
          this.cocReasons = cocReasons;
        },
        error: (err) => {
          this._notifsService.showError(err.message || 'Unable to get coc reasons.', 'Error!');
        }
      }).add(() => this._spinnerService.hide());
  }

  onChangeCircumstanceStatusChanged = () => {
    //
  }

  onCircumstanceReasonyTypeChanged = () => {
    if (!this.cocDetails.changeOfCircumstanceReason) {
      return;
    }

    const reasonType = this.cocReasons.find(t => t.reasonType === this.cocDetails.changeOfCircumstanceReason);
    this.cocDetails.disclosureReasonAdditionalDescription = reasonType.description;
    this.cocDetails.disclosureComments = reasonType.comments;
  }

  onReceivedDateChanged = () => {
    if(this.cocDetails.changesOfCircumstanceReceivedDate && this.cocDetails.changesOfCircumstanceReceivedDate.toString().length){
      const currentDate = new Date(this.cocDetails.changesOfCircumstanceReceivedDate);
      this.revisedDueDate =  Utils.formatDateWithoutTime(Utils.addBusinessDays(currentDate, 2));
    } else {
      this.revisedDueDate = null;
    }
  }

  onChangedCircumstance = () => {
    if (!this.cocDetails.hasChangeOfCircumstance) {
      this.cocDetails.changeOfCircumstanceReason = null;
    } else {
      this.getCocReasons();
    }
  }

  onSaveClicked() {
    this.cocDetailsForm.form.markAllAsTouched();
    if (!this.cocDetailsForm.valid) {
      return;
    }

    this.isSaving = true;

    if (!this.cocDetails.hasChangeOfCircumstance) {
      this.cocDetails.feeDetail = [];
    }

    this._cocReasonsService.upsertCocDetails(this.mortgageId, this.cocDetails).subscribe({
      next: (response) => {
        this._notifsService.showSuccess(
          'Change of circumstance saved successfully.',
          'Success!'
        );

        this.onCocDetailsSaved.emit(response);
      },
      error: (error) => {
        this._notifsService.showError(
          error?.message || "Couldn't upsert change of circumstance",
          "Error!"
        );
      }
    }).add(() => {
      this.isSaving = false;
    });
  }

  onCloseClicked() {
    this.onCocDetailsClosed.emit();
  }

  onShowAllFeesToggled() {
    if (!this.showAllFees) {
      this.tableFees = this.allFees.filter(fee => fee.didIncrease);
      this.selectedFees = [];
      this._seeCocFeeDetailBasedOnSelectedFees();
    } else {
      this.tableFees = [...this.allFees];
    }
  }

  private _setSelectedReasons = () => {
    this.selectedReasons = [];

    if (this.cocDetails.disclosureReason) {
      this.selectedReasons = this.reasons.filter(r => this.cocDetails.disclosureReason.replaceAll(' ', '').split(',').includes(r.value));
      this.isOtherReasonSelected = !!this.selectedReasons.find(r => r.value === "Other");
    }
  }

  private _setSelectedFees = (feeDetail: ChangeOfCircumstanceFeeDetail[]) => {
    this.selectedFees = this.tableFees.filter(fee => !!feeDetail.find(fDetail => fee.disclosedLoanFeeHistoryId == fDetail.baselineLoanFeeHistoryId));
  }

  private _parseDisclosedFees = (disclosedFees: DisclosureValidationFeeDetail[]) => {
    if (this.readonly) {
      this.tableFees = disclosedFees?.filter(f => this.cocDetails.feeDetail.find(df => df.baselineLoanFeeHistoryId === f.disclosedLoanFeeHistoryId)) || [];
    } else {
      this.tableFees = disclosedFees || [];
    }

    // if we are adding a new one check all
    if (!this.cocDetails.changeOfCircumstanceId) {
      if (this.tableFees.length > 0) {
        this.cocDetails.hasChangeOfCircumstance = true;
      }
      this.selectedFees = [...this.tableFees];
      this._seeCocFeeDetailBasedOnSelectedFees();
    } else {
      this._setSelectedFees(this.cocDetails.feeDetail);
    }
  }

  private _seeCocFeeDetailBasedOnSelectedFees = () => {
    this.cocDetails.feeDetail = this.selectedFees.map(f => ({
      baselineLoanFeeHistoryId: f.disclosedLoanFeeHistoryId
    })) as ChangeOfCircumstanceFeeDetail[];
  }
}

