import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { DataService } from '../core/services/data.service';
import { LosAppOperationResult, LosCredential, LosCredentialsArray, LosTransactionHistory, LosVendor, SearchResponseItem } from '../models';
import { LosCondition } from '../models/los/los-condition.model';
import { LosOptionModel } from '../models/los/los-option.model';
import { LosProduct } from '../models/los/los-product.model';
import { SyncLodaDoc } from '../modules/loan-docs/models/sync-loan-docs/sync-loda-doc-with-los-lde.model';
import { LoanValidationResponse } from '../modules/loan-services/request-models/loan-validation-response.model';
import { LosSetFieldRequest, LosSetFieldResponse } from '../modules/loan-services/request-models/los-set-field-request';
import { LosDisclosureGenJob, LosDisclosureGenOptions } from '../modules/loan-services/request-models/los-disclosure-gen-job';

@Injectable()
export class LosService {
  constructor(private readonly _dataService: DataService) { }

  private filterCredentials(allowedFeatures?: Array<string>) {
    return map((credentials: any) => {
      if (!allowedFeatures) return credentials;

      const filteredCredentials = [];

      credentials.forEach(credential => {
        if (this.featureExists(credential, allowedFeatures)) {
          filteredCredentials.push(credential);
        }
      });

      return filteredCredentials;
    });
  }

  private featureExists(credential: any, allowedFeatures: Array<string>) {
    if (!credential.vendorFeatures) return false;
    return credential.vendorFeatures.some(cF => allowedFeatures.indexOf(cF) > -1);
  }

  getLosCredentials({ forConfig, allowedFeatures }: {
    forConfig?: boolean;
    allowedFeatures?: Array<string>;
  }): Observable<LosCredentialsArray> {
    return this._dataService
      .get(`api/admin/losConfig/losVendors/credentials?forConfig=${forConfig ? 'true' : 'false'}`)
      .pipe(this.filterCredentials(allowedFeatures));
  }

  getLosCredentialsForLoan(applicationId: number): Observable<LosCredentialsArray> {
    return this._dataService
      .get(`api/Loan/LOS/losVendors/${applicationId}`)
  }

  getLosCredentialsForLinkLoanWithLos(applicationId: number): Observable<LosCredentialsArray> {
    return this._dataService
      .get(`api/Loan/LOS/link/losVendors/${applicationId}`)
  }

  getLosCredentialsForCreateLoanInLos(applicationId: number): Observable<LosCredentialsArray> {
    return this._dataService
      .get(`api/Loan/LOS/create/losVendors/${applicationId}`)
  }

  getLosCredentialsForImportLoanFromLos(): Observable<LosCredentialsArray> {
    return this._dataService.get(`api/Loan/LOS/import/losVendors`)
  }

  getVendors = (): Observable<any> => {
    return this._dataService.get(`api/Admin/LosConfig/losVendors`);
  }

  searchLos(filterUrl: string): Observable<SearchResponseItem[]> {
    const url = `api/Loan/LOS/search/${filterUrl}`;
    return this._dataService.get(url);
  }

  getTransactionHistory = (applicationId: number): Observable<LosTransactionHistory> => {
    const url = `api/loan/los/info/${applicationId}`;
    return this._dataService.get(url);
  }

  link = (applicationId: number, credentialId: number, losIdentifier: string, refNumber: string): Observable<LosAppOperationResult> => {
    const url = `api/Loan/LOS/link/${applicationId}/${credentialId}?losIdentifier=${losIdentifier}&refNumber=${refNumber}`;
    return this._dataService.post(url, {});
  }

  unlink = (applicationId: number) => {
    const url = `api/Loan/LOS/unlink/${applicationId}`;
    return this._dataService.post(url, {});
  }

  reference = (applicationId: number, refNumber: string): Observable<LosAppOperationResult> => {
    const url = `api/Loan/LOS/reference/${applicationId}?refNumber=${refNumber}`;
    return this._dataService.post(url, {});
  }

  getLosOptions = (applicationId: number, credentialId?: number): Observable<LosOptionModel> => {
    const url = `api/loan/los/create/options/${applicationId}/${credentialId}`;
    return this._dataService.get(url);
  }

  getSyncDocs = (applicationId: number, type: string): Observable<SyncLodaDoc[]> => {
    const url = `api/loan/los/docSync/${type}/${applicationId}`;
    return this._dataService.get(url);
  }

  create = (applicationId: number, useCredentialId: number, data: any): Observable<LosAppOperationResult> => {
    const url = `api/Loan/LOS/create/${applicationId}/${useCredentialId}`;
    return this._dataService.post(url, data);
  }

  pullFromLos = (applicationId: number): Observable<LosAppOperationResult> => {
    const url = `api/Loan/LOS/pull/${applicationId}`;
    return this._dataService.post(url, {});
  }

  pushToLos = (applicationId: number) => {
    const url = `api/Loan/LOS/push/${applicationId}`;
    return this._dataService.post(url, {});
  }

  partialPushToLos = (applicationId: number) => {
    const url = `api/Loan/LOS/push/${applicationId}/selective`;
    return this._dataService.post(url, {});
  }

  syncDirection = (applicationId: number, losSyncDir: string) => {
    const url = `api/Loan/LOS/info/${applicationId}/syncDir/${losSyncDir}`;
    return this._dataService.post(url, {});
  }

  getLosVendorProducts = (losVendor: LosVendor): Observable<LosProduct[]> => {
    const url = `api/pricing/losvendor/${losVendor}/products`;
    return this._dataService.get(url);
  }

  getLosConditions = (applicationId: number): Observable<LosCondition[]> => {
    const url = `api/Loan/LOS/conditions/${applicationId}`;
    return this._dataService.get(url);
  }

  getLosConditionsUsingCredentialId = (losCredentialId: number, loanId: string): Observable<LosCondition[]> => {
    const url = `api/Loan/LOS/conditions/${losCredentialId}/${loanId}`;
    return this._dataService.get(url);
  }

  getLosVendorsForImportConditions = (applicationId: number): Observable<LosCredential[]> => {
    const url = `api/Loan/LOS/conditions/losVendors/${applicationId}`;
    return this._dataService.get(url);
  }

  // disclosures
  validateLoanForDisclosureGen(credentialId: number, applicationId: number, lockId: string): Observable<LoanValidationResponse> {
    let url = `api/Loan/LOS/disclosures/${credentialId}/validate?applicationId=${applicationId}&lockId=${lockId}`;
    return this._dataService.post(url, {});
  }

  updateIncorrectLoanFields(credentialId: number, fieldValues: LosSetFieldRequest[], applicationId: number, lockId: string): Observable<LosSetFieldResponse[]> {
    let url = `api/Loan/LOS/disclosures/${credentialId}/validate/correct?applicationId=${applicationId}&lockId=${lockId}`;
    return this._dataService.post(url, fieldValues);
  }

  generateDisclosures(credentialId: number, options: LosDisclosureGenJob, lockId: string): Observable<LosDisclosureGenJob> {
    let url = `api/Loan/LOS/disclosures/${credentialId}/generate?lockId=${lockId}`;
    return this._dataService.post(url, options);
  }

  checkDisclosureGenStatus(credentialId: number, options: LosDisclosureGenJob, lockId: string): Observable<LosDisclosureGenJob> {
    let url = `api/Loan/LOS/disclosures/${credentialId}/generate/status?lockId=${lockId}`;
    return this._dataService.post(url, options);
  }

  sendDisclosures(credentialId: number, options: LosDisclosureGenJob, lockId: string): Observable<LosDisclosureGenJob> {
    let url = `api/Loan/LOS/disclosures/${credentialId}/send-disclosures?lockId=${lockId}`;
    return this._dataService.post(url, options);
  }

  checkDisclosureSendStatus(credentialId: number, options: LosDisclosureGenJob, lockId: string): Observable<LosDisclosureGenJob> {
    let url = `api/Loan/LOS/disclosures/${credentialId}/send-disclosures/status?lockId=${lockId}`;
    return this._dataService.post(url, options);
  }

  lockApplication(credentialId: number, applicationId: number): Observable<string> {
    return this._dataService.get(`api/Loan/LOS/lock/${credentialId}/${applicationId}`, { responseType: "text" });
  }

  unlockApplication(credentialId: number, applicationId: number, lockId: string): Observable<any> {
    let url = `api/Loan/LOS/unlock/${credentialId}/${applicationId}?lockId=${lockId}`;
    return this._dataService.post(url, {});
  }

  auditDisclosures(credentialId: number, options: LosDisclosureGenOptions, applicationId: number, lockId: string): Observable<LosDisclosureGenJob> {
    let url = `api/Loan/LOS/disclosures/${credentialId}/audit?applicationId=${applicationId}&lockId=${lockId}`;
    return this._dataService.post(url, options);
  }

  checkDisclosureAuditStatus(credentialId: number, options: LosDisclosureGenJob, lockId: string): Observable<LosDisclosureGenJob> {
    let url = `api/Loan/LOS/disclosures/${credentialId}/audit/status?lockId=${lockId}`;
    return this._dataService.post(url, options);
  }

  autoCreateLosLoan(applicationId: number): Observable<LosAppOperationResult> {
    let url = `api/Loan/LOS/tpo/${applicationId}/auto-create-in-los`;
    return this._dataService.post(url, {});
  }

  autoSyncLosLoan(applicationId: number): Observable<LosAppOperationResult> {
    let url = `api/Loan/LOS/tpo/${applicationId}/auto-sync-with-los`;
    return this._dataService.post(url, {});
  }

  submitTpoLoan(credentialId: number, applicationId: number, lockId: string): Observable<any> {
    let url = `api/Loan/LOS/tpo/${credentialId}/submit?applicationId=${applicationId}`;
    if (lockId)
      url += `&lockId=${lockId}`;
    return this._dataService.post(url, {});
  }

  reSubmitTpoLoan(credentialId: number, applicationId: number, lockId: string): Observable<any> {
    let url = `api/Loan/LOS/tpo/${credentialId}/resubmit?applicationId=${applicationId}`;
    if (lockId)
      url += `&lockId=${lockId}`;
    return this._dataService.post(url, {});
  }
}
