import { Component, Input, OnInit } from '@angular/core';
import { EnergyImprovementTypeEnum, FHADirectEndorsementApprovalTypeEnum, FHAVALoanPurposeTypeEnum, GovernmentLoanDetail, SubjectPropertyValuation } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { Utils } from 'src/app/core/services/utils';
import { formViewProvider } from 'src/app/core/services/form-view.provider';

@Component({
  selector: 'government-loan-info',
  templateUrl: './government-loan-info.component.html',
  styleUrls: ['./government-loan-info.component.scss'],
  viewProviders: [formViewProvider]
})
export class GovernmentLoanInfoComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  governmentLoanSectionOfActTypes: EnumerationItem[] = [];
  fhaInsuranceProgramTypes: EnumerationItem[] = [];
  yesNoOptions: EnumerationItem[] = [];

  applicationTypes: EnumerationItem[] = [];
  fhavaLoanPurposeTypes: EnumerationItem[] = [];
  fhaAlimonyLiabilityTreatmentTypes: EnumerationItem[] = [];
  energyRelatedImprovementTypes: EnumerationItem[] = [];
  fhaDirectEndorsementApprovalTypes: EnumerationItem[] = [];
  vaAppraisalTypes: EnumerationItem[] = [];
  vaLoanProcedureTypes: EnumerationItem[] = [];
  vaTitleVestingTypes: EnumerationItem[] = [];
  vaCashOutRefinanceTypes: EnumerationItem[] = [];
  meetsNtb: boolean = false;
  paymentDiff: number = 0;
  vaEligibleClosingCosts: number = 0;
  recoupMonths: number = 0;

  constructor(private readonly _enumsService: EnumerationService) { }

  ngOnInit() {
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();

    if (!this.mortgage.governmentLoanDetail) {
      this.mortgage.governmentLoanDetail = new GovernmentLoanDetail();
    }

    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.governmentLoanSectionOfActTypes = enums[Constants.mortgageEnumerations.governmentLoanSectionOfActType] || [];
      this.fhaInsuranceProgramTypes = enums[Constants.mortgageEnumerations.fhaInsuranceProgramType] || [];
      this.applicationTypes = enums[Constants.mortgageEnumerations.governmentLoanApplicationType] || [];
      this.fhaAlimonyLiabilityTreatmentTypes = enums[Constants.mortgageEnumerations.fHAAlimonyLiabilityTreatmentType] || [];
      this.vaAppraisalTypes = enums[Constants.mortgageEnumerations.vaAppraisalType] || [];
      this.vaLoanProcedureTypes = enums[Constants.mortgageEnumerations.vaLoanProcedureType] || [];
      this.vaTitleVestingTypes = enums[Constants.mortgageEnumerations.vaTitleVestingType] || [];
      this.vaCashOutRefinanceTypes = enums[Constants.mortgageEnumerations.vaCashOutRefinanceType] || [];

      this.splitEnumNames(this.governmentLoanSectionOfActTypes);
      this.splitEnumNames(this.fhaInsuranceProgramTypes);
      this.splitEnumNames(this.applicationTypes);
      this.splitEnumNames(this.fhaAlimonyLiabilityTreatmentTypes);
      this.splitEnumNames(this.vaAppraisalTypes);
      this.splitEnumNames(this.vaLoanProcedureTypes);
      this.splitEnumNames(this.vaTitleVestingTypes);

      this.getFhaVaLoanPurposeTypes();
      this.getEnergyRelatedImprovementTypes();
      this.getFhaDirectEndorsementApprovalTypes();

      this.reCalculateNTB();
    })

    if (!this.mortgage.subjectProperty.subjectPropertyValuation) {
      this.mortgage.subjectProperty.subjectPropertyValuation = new SubjectPropertyValuation();
    }
  }

  reCalculateNTB = () => {
    this.paymentDiff = (this.mortgage.governmentLoanDetail?.vaExistingPrincipalAndInterest ?? 0) - (this.mortgage.proposedHousingExpense?.firstMortgagePrincipalAndInterest ?? 0);
    this.vaEligibleClosingCosts = (this.mortgage.transactionDetail?.estimatedClosingCostsExcludingPrepaidsAmount ?? 0) + (this.mortgage.transactionDetail?.borrowerPaidDiscountPointsTotalAmount ?? 0) - (this.mortgage.calculatedStats?.lenderCredit ?? 0);
    this.recoupMonths = this.vaEligibleClosingCosts / this.paymentDiff;
    this.meetsNtb = this.recoupMonths <= 36;
  }

  onVaStuffReviewChanged = () => {
    if (!this.mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment) {
      this.mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier = undefined;
      this.mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate = undefined;
    }
  }

  getFhaVaLoanPurposeTypes = () => {
    for (let enumMember in FHAVALoanPurposeTypeEnum) {
      let enumName = this.getEnumName(FHAVALoanPurposeTypeEnum[enumMember]);
      if (enumName) {
        this.fhavaLoanPurposeTypes.push({ name: enumName, value: FHAVALoanPurposeTypeEnum[enumMember] });
      }
    }
  }


  getEnergyRelatedImprovementTypes = () => {
    for (let enumMember in EnergyImprovementTypeEnum) {
      let enumName = this.getEnumName(EnergyImprovementTypeEnum[enumMember]);
      if (enumName) {
        this.energyRelatedImprovementTypes.push({ name: enumName, value: EnergyImprovementTypeEnum[enumMember] });
      }
    }
  }

  getFhaDirectEndorsementApprovalTypes = () => {
    for (let enumMember in FHADirectEndorsementApprovalTypeEnum) {
      let enumName = this.getEnumName(FHADirectEndorsementApprovalTypeEnum[enumMember]);
      if (enumName) {
        this.fhaDirectEndorsementApprovalTypes.push({ name: enumName, value: FHADirectEndorsementApprovalTypeEnum[enumMember] });
      }
    }
  }

  private getEnumName = (enumValue: string): string => {
    enumValue = enumValue && enumValue != "None" && enumValue != "Unknown" ? String(enumValue) : null;
    return enumValue ? Utils.splitCamelCaseString(enumValue) : null;
  }

  private splitEnumNames = (array: EnumerationItem[]) => {
    array.forEach(e => {
      e.name = Utils.splitCamelCaseString(e.name).replaceAll("F H A", "FHA").replaceAll("V A", "VA").replaceAll("H U D", "HUD").replaceAll("A R M", "ARM");
    });
  }

}
