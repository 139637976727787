import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ErrorMessage } from 'src/app/shared/models/error.model';

@Component({
  selector: 'dependents-dialog',
  templateUrl: 'dependents-dialog.component.html'
})

export class DependentsDialogComponent implements OnInit {
  @ViewChild('dependentForm') dependentForm: NgForm | undefined;

  @Input() public dependents: Array<number>;
  modalDependents: Array<{ age: number }>;
  error: ErrorMessage | undefined;

  constructor(public activeModal: NgbActiveModal) {

  }

  ngOnInit(){
    if(this.dependents) {
      this.modalDependents = this.dependents.map(age => ({ age }));
    }

    if(!this.modalDependents) {
      this.modalDependents = [];
      this.add();
    }
  }

  onDependentAgeChanged = (dependent: { age: number }) => {
    if (dependent.age > 130) {
      dependent.age = 130;
    }
  }

  add = () => {
    this.modalDependents.push({ age: 0 });
  }

  remove = (index) => {
    this.modalDependents.splice(index, 1);
  }

  validate() {
    let valid = true;
    this.modalDependents.forEach((dependent) => {
      if (dependent.age < 0) {
        this.error = new ErrorMessage('Age Error', 'Age must be positive number!');
        valid = false;
      } else if (dependent.age > 130) {
        this.error = new ErrorMessage('Age Error', 'Age cannot be greater then 130!');
        valid = false;
      }
    });
    return valid;
  }

  save = () => {
    this.error = undefined;

    if (!this.dependentForm) return;
    this.dependentForm.form.markAllAsTouched();
    if (!this.dependentForm.form.valid || !this.validate()) return;

    this.activeModal.close(this.modalDependents.map(dep => dep.age));
  }
}
