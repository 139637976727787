import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UrlaMortgage} from '../../models/urla-mortgage.model';
import {MortgageBorrower, NetRentalIncomeParams} from 'src/app/models';
import {EnumerationItem} from 'src/app/models/simple-enum-item.model';
import {EnumerationService} from 'src/app/services/enumeration-service';
import {formViewProvider} from 'src/app/core/services/form-view.provider';
import {MortgageUtils} from '../../../../shared/utils/mortgage/mortgage-utils';
import { MortgageCalculationService } from '../../services/mortgage-calculation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'rental-income-on-purchases',
  templateUrl: 'rental-income-on-purchases.component.html',
  viewProviders: [formViewProvider],
})
export class RentalIncomeOnPurchasesComponent {

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    this.borrower = MortgageUtils.getPrimaryBorrowerOrFirst(mortgage);
    this.isRefi = this._calculationService.isPurposeOfLoanRefinance(this._mortgage);

    this.calculateExpectedNetMonthlyRentalIncome();

    this.hasSubjectPropertyRentalIncome =
      MortgageUtils.hasSubjectPropertyRentalIncome(mortgage);
    this.hasSubjectPropertyRentalIncomeChange.emit(
      this.hasSubjectPropertyRentalIncome,
    );
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  isReadOnly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Output()
  hasSubjectPropertyRentalIncomeChange = new EventEmitter<boolean>();

  @Output()
  borrowerReosChanged: EventEmitter<any> = new EventEmitter<any>();

  private _mortgage: UrlaMortgage;

  protected borrower: MortgageBorrower;

  protected yesNoOptions: EnumerationItem[] = [];

  protected hasSubjectPropertyRentalIncome: boolean;

  protected isRefi: boolean;

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _mortgageService: MortgageService,
    private readonly _calculationService: MortgageCalculationService
  ) {
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
  }

  calculateExpectedNetMonthlyRentalIncome() {
    
    const params = new NetRentalIncomeParams();
    params.grossRentalIncome = this._mortgage.subjectProperty.expectedGrossMonthlyRentalIncome || 0;
    params.occupancyFactor = this._mortgage.subjectProperty.occupancyPercent || 100;
    if (!isNaN(params.occupancyFactor)) {
      params.occupancyFactor = Number(params.occupancyFactor);
    }
    params.miscExpenses = 0;
    params.mortgagePayment = this._calculationService.calculateHousingExpenseTotal(
      this._mortgage.proposedHousingExpense
    );

    if (!params.grossRentalIncome || !params.occupancyFactor) {
      this._mortgage.subjectProperty.expectedNetMonthlyRentalIncome = 0;
      return;
    }

    this._spinnerService.show();

    this._mortgageService.calculateNetRentalIncome(params).subscribe({
      next: (response) => {
        this._mortgage.subjectProperty.expectedNetMonthlyRentalIncome = response.value;
      },
      error: (err) => {
        this._notificationService.showError(err?.message || "unable to calculate net rental income", "Error");
      }
    }).add(() => this._spinnerService.hide());
  }

  calculateOoccupancyPercent() {
    const expectedGrossMonthlyRentalIncome = this.mortgage.subjectProperty.expectedGrossMonthlyRentalIncome ?? 0;
    if (expectedGrossMonthlyRentalIncome === 0) {
      return;
    }

    const expectedNetMonthlyRentalIncome = this.mortgage.subjectProperty.expectedNetMonthlyRentalIncome ?? 0;
    if (expectedNetMonthlyRentalIncome < 0) {
      return;
    }

    this.mortgage.subjectProperty.occupancyPercent = expectedNetMonthlyRentalIncome * 100 / expectedGrossMonthlyRentalIncome;
  }

  protected onHasSubjectPropertyRentalIncomeChange(value: boolean) {
    this.hasSubjectPropertyRentalIncome = value;
    this.hasSubjectPropertyRentalIncomeChange.emit(value);
  }

/*
Do not sync - subject property net is based on proposed expenses, not current expenses of subject property REO

  protected expectedGrossMonthlyRentalIncomeChanged = () => {
    if (this.isRefi) {
      const existingReo = this.mortgage.realEstateOwned.find(reo => reo.isSubjectProperty);
      if (existingReo) {
        existingReo.grossRentalIncome = this.mortgage.subjectProperty.expectedGrossMonthlyRentalIncome;
      }
    }    
  }

  protected expectedNetMonthlyRentalIncomeChanged = () => {
    if (this.isRefi) {
      const existingReo = this.mortgage.realEstateOwned.find(reo => reo.isSubjectProperty);
      if (existingReo) {
        existingReo.netRentalIncome = this.mortgage.subjectProperty.expectedNetMonthlyRentalIncome;
      }
    }    
  }

  */
}
