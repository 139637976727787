import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns the possessive form of the given string.
 *
 * FIXME: Implement a more comprehensive solution to handle special cases
 *  like "Charles's", etc.
 */
@Pipe({
  name: 'possessive',
})
export class PossessivePipe implements PipeTransform {
  transform(value: string): string {
    if (value.endsWith('s')) {
      return `${value}'`;
    } else {
      return `${value}'s`;
    }
  }
}
