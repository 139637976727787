import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MortgageCalculationService } from '../../urla/services/mortgage-calculation.service';
import {
  catchError,
  concat,
  concatMap,
  EMPTY,
  from,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import {
  ChannelEnum,
  LoanApplication,
  Mortgage,
  PurchaseCredit,
  PurchaseCreditSourceType,
  TransactionDetail,
} from '../../../models';
import { UrlaMortgage } from '../../urla/models/urla-mortgage.model';
import { MortgageService } from '../../../services/mortgage.service';
import { LoanFee } from '../../fees-v2/fees.model';
import {
  FeeSection,
  FeeSectionViewType,
} from '../../fees-v2/fee-section.model';
import { autoId, handleNonErrorDismissals } from '../../../core/services/utils';
import { NotificationService } from '../../../services/notification.service';
import { tap } from 'rxjs/operators';
import { Constants } from '../../../services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MortgageCalculationDetails } from '../../../models/mortgage-calculation-details.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { createFeeSections } from '../../fees-v2/create-fee-sections';
import { CalculateCreditsDialogComponent } from '../calculate-credits-dialog/calculate-credits-dialog.component';
import { MiOrFundingFeeUtils } from '../../../shared/utils/mortgage/mi-or-funding-fee-utils';
import { FeesV2Service } from '../../fees-v2/services/fees-v2.service';

type KeyOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

@Component({
  selector: 'cash-to-close',
  templateUrl: './cash-to-close.component.html',
  styleUrls: ['./cash-to-close.component.scss'],
})
export class CashToCloseComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input()
  set fees(value: LoanFee[]) {
    const feeSections = createFeeSections(value);
    this._feeSectionByType = new Map(feeSections.map(section => [section.type, section]));
  }

  @Input()
  application: LoanApplication;

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  editable: boolean = true;

  /**
   * The calculation details used to initialize the cash to close items.
   */
  @Input()
  set calculationDetails(value: MortgageCalculationDetails) {
    this._calculationDetails = value;
  }

  /**
   * If the items are recalculated, this will be hold the new calculation details. It is initialized
   * with the value of {@link calculationDetails} input property. This is the effective value of the
   * calculation details that the component uses.
   * @private
   */
  private _calculationDetails: MortgageCalculationDetails;

  /**
   * Emits when there is a change in the {@link mortgage} that should be saved.
   */
  @Output()
  readonly save = new EventEmitter<void>();

  @ViewChild('templateCalculateLenderCredits', {static: true})
  private readonly _templateCalculateLenderCredits: TemplateRef<any>;

  @ViewChild('templateCalculateSellerCredits', {static: true})
  private readonly _templateCalculateSellerCredits: TemplateRef<any>;

  @ViewChild('templateCalculateOtherCredits', {static: true})
  private readonly _templateCalculateOtherCredits: TemplateRef<any>;

  @ViewChild('templateFinanceAll', {static: true})
  private readonly _templateFinanceAll: TemplateRef<any>;

  protected readonly PurchaseCreditSourceType = PurchaseCreditSourceType;

  protected readonly ChannelEnum = ChannelEnum;

  /**
   * Emits when the cash to close items should be recalculated.
   * @remarks This is used to trigger the recalculation of the cash to close items. This is
   * necessary because the items depend on both the input properties, enumeration data, and template
   * references (e.g. `@ViewChild`). We use {@link ReplaySubject} because the input properties
   * might be set before {@link ngOnInit} is called. (i.e., when the component is created with
   * the input properties already set)
   * @private
   */
  private readonly _initCashToCloseItems$ = new ReplaySubject<void>(1);

  private _feeSectionByType: Readonly<Map<FeeSectionViewType, FeeSection>> = new Map();

  protected readonly id: string = `cash-to-close-${autoId()}`;

  protected cashToCloseItems: readonly CashToCloseItem[];
  protected totalCashToCloseItem: CashToCloseItem = {description: '', amount: 0};

  protected isRefinance: boolean;

  private invalidateMiOrFundingFeeAmountField?: (mortgage: Mortgage) => void;

  private get _fundsToClose(): MortgageCalculationDetails['fundsToClose'] {
    return this._calculationDetails?.fundsToClose;
  }

  // We need to wait for the after view init to ensure that the ViewChild fields are initialized.
  private _afterViewInit$ = new Subject<void>();

  private _initCashToCloseItemsSubscription?: Subscription;
  private _calculateCreditsSubscription?: Subscription;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _mortgageService: MortgageService,
    private readonly _feeService: FeesV2Service,
    private readonly _mortgageCalculationService: MortgageCalculationService,
    private readonly _notificationService: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    let shouldInitCashToCloseItems =
      'fees' in changes ||
      'application' in changes ||
      'mortgage' in changes ||
      'calculationDetails' in changes;

    if (shouldInitCashToCloseItems) {
      this._initCashToCloseItems$.next();
    }
  }

  ngOnInit(): void {
    this.subscribeToInitCashToCloseItems();
  }

  ngAfterViewInit(): void {
    // `setTimeout` is used to avoid ExpressionChangedAfterItHasBeenCheckedError.
    setTimeout(() => {
      this._afterViewInit$.complete();
    });
  }

  ngOnDestroy() {
    this._initCashToCloseItemsSubscription?.unsubscribe();
    this._calculateCreditsSubscription?.unsubscribe();
  }

  private subscribeToInitCashToCloseItems(): void {
    this._initCashToCloseItemsSubscription?.unsubscribe();

    this._initCashToCloseItemsSubscription = concat(
      this._afterViewInit$,
      this._initCashToCloseItems$,
      from(this._feeService.awaitInitialization())
    ).subscribe(() => {
      this.initIsRefinance();
      this.initCashToCloseItems();
      this.initTotalCashToCloseItem();
    });
  }

  private initIsRefinance(): void {
    if (!this.mortgage) {
      return;
    }
    this.isRefinance = this._mortgageCalculationService.isPurposeOfLoanRefinance(this.mortgage);
  }

  private initCashToCloseItems(): void {
    const cashToCloseItems = [];
    cashToCloseItems.push(...this.createFundsDueFromBorrowerItems());
    cashToCloseItems.push(...this.createLoanDetailItems());
    cashToCloseItems.push(...this.createTotalCreditsAppliedItems());
    this.cashToCloseItems = cashToCloseItems;
  }

  private createFundsDueFromBorrowerItems(): CashToCloseItem[] {
    const fundsToClose = this._fundsToClose;
    const isRefinance = this.isRefinance;

    if (!fundsToClose) {
      return [];
    }

    return [
      // This item's value differs based on whether the loan is a refinance or not
      (() =>
        isRefinance
          ? {description: 'Loan Amount', amount: this.application.loanAmount}
          : {
              description: 'Down Payment/Funds from Borrower',
              amount: this._mortgageService.calculateDownPayment(this.mortgage),
            })(),
      // Only show this item if it has a valid amount
      ((amount: number | undefined) =>
        amount > 0 ? {description: 'Improvements, Renovations and Repairs', amount} : null)(
        this.mortgage.transactionDetail?.alterationsImprovementsAndRepairsAmount
      ),
      this.createFeeSectionItem('Origination Fees', 'totalFee', FeeSectionViewType.Origination),
      this.createFeeSectionItem('Third Party Fees', 'totalFee', FeeSectionViewType.TitleCharges),
      this.createFeeSectionItem(
        'Taxes and Other Government Fees',
        'totalFee',
        FeeSectionViewType.GovernmentTaxesAndFees
      ),
      {
        description: 'Prepaids and Initial Escrow',
        amount: ((transactionDetail: TransactionDetail | undefined) => {
          return (
            (transactionDetail?.prepaidItemsEstimatedAmount || 0) +
            (transactionDetail?.prepaidEscrowsTotalAmount || 0)
          );
        })(this.mortgage.transactionDetail),
      },
      // This item's value differs based on whether the loan is a refinancing or not
      (() =>
        isRefinance
          ? {
              description: 'Total Payoffs and Closing Costs',
              tooltip: `For Refinancing: This includes the balance of Mortgage loans on the property
              to be paid off`,
              amount: fundsToClose.totalPaidOffForRefinance_D,
            }
          : {
              description: 'Estimated Total Payoffs',
              amount: fundsToClose.debtsPaidOff_E,
            })(),
      {
        description: 'Funds Due from Borrower',
        highlighted: true,
        amount:
          (fundsToClose.totalDueFromBorrowerAThroughG_H || 0) -
          (fundsToClose.totalMortgageLoansIThroughJ_K || 0),
      },
    ].filter(item => item != null);
  }

  /**
   * Creates a fee section item based on the provided parameters.
   *
   * @param {string} description - The description of the fee section item.
   * @param {KeyOfType<LoanFee['calculatedValues'], number>} calculatedValuesKey - The key used to
   * calculate the fee amount from the loan fee's calculated values.
   * @param {...Readonly<FeeSectionViewType[]>} sectionTypes - The types of fee section views to
   * include in the calculation.
   * @returns {CashToCloseItem} The created fee section item.
   * @private
   */
  private createFeeSectionItem(
    description: string,
    calculatedValuesKey: KeyOfType<LoanFee['calculatedValues'], number>,
    ...sectionTypes: Readonly<FeeSectionViewType[]>
  ): CashToCloseItem {
    // Calculate the amount for a given fee section type
    const calculateAmount = (type: FeeSectionViewType) => {
      return (
        this._feeSectionByType
          .get(type)
          ?.fees.reduce(
            (acc, fee) =>
              acc +
              (!this._feeService.isSummaryFeeType(fee.feeType)
                ? fee.calculatedValues?.[calculatedValuesKey] || 0
                : 0),
            0
          ) || 0
      );
    };

    return {
      description: description,
      // Sum the amounts for each fee section type
      amount: sectionTypes.reduce((acc, type) => acc + calculateAmount(type), 0),
    };
  }

  protected openCalculateCreditsDialog(creditSourceType: PurchaseCreditSourceType): void {
    const mortgage = this.mortgage;

    const modalRef = this._modalService.open(
      CalculateCreditsDialogComponent,
      Constants.modalOptions.large
    );
    const instance = modalRef.componentInstance as CalculateCreditsDialogComponent;

    instance.props = {
      credits: mortgage.transactionDetail?.purchaseCredits ?? [],
      sourceType: creditSourceType,
    };
    instance.okButtonLabel = 'Save';

    this._calculateCreditsSubscription?.unsubscribe();
    this._calculateCreditsSubscription = from(modalRef.result)
      .pipe(
        catchError(error => {
          handleNonErrorDismissals(error);
          return EMPTY;
        })
      )
      .pipe(
        tap(() => this._spinnerService.show()),
        concatMap((result: PurchaseCredit[]) => {
          const mortgage = this.mortgage;

          const transactionDetail = mortgage.transactionDetail;
          if (transactionDetail == null) {
            throw new Error('Transaction detail is not available.');
          }
          transactionDetail.purchaseCredits = replaceSourceTypeCredits(
            transactionDetail.purchaseCredits,
            result,
            creditSourceType
          );

          const calculationService = this._mortgageCalculationService;
          calculationService.calculateMortgageStatistics(mortgage);
          mortgage.calculatedStats.sourceOfFunds =
            calculationService.calculateSourceOfFundsValue(mortgage);

          return this._mortgageService.redoMortgageCalculationDetails(mortgage);
        })
      )
      .subscribe({
        next: calculationDetails => {
          if (calculationDetails == null) {
            return;
          }

          this._calculationDetails = calculationDetails;
          this.initCashToCloseItems();

          this.save.emit();

          // The spinner should be hidden by parent component.
        },
        error: error => {
          // noinspection JSIgnoredPromiseFromCall
          this._spinnerService.hide();

          const defaultMessage =
            'An error occurred while calculating the credits.';
          console.error(defaultMessage, error);

          const message = error?.message || defaultMessage;
          this._notificationService.showError(message, 'Error');
        },
      });
  }

  private createTotalCreditsAppliedItems(): CashToCloseItem[] {
    const fundsToClose = this._fundsToClose;

    const items: CashToCloseItem[] = [
      {
        description: 'Lender Credits',
        amount: fundsToClose?.lenderCredits || 0,
        templateAction: this._templateCalculateLenderCredits,
      },
      {
        description: 'Seller Credits',
        amount: fundsToClose?.sellerCredits_L || 0,
        templateAction: this._templateCalculateSellerCredits,
      },
      {
        description: 'Other Credits',
        amount: fundsToClose?.otherCredits_M || 0,
        templateAction: this._templateCalculateOtherCredits,
      },
    ];

    items.push({
      description: 'Total Credits Applied',
      highlighted: true,
      amount: items.reduce((acc, item) => acc + item.amount, 0) + (fundsToClose?.emd || 0),
    });

    return items;
  }

  private initTotalCashToCloseItem(): void {
    const fundsToClose = this._fundsToClose;

    this.totalCashToCloseItem = {
      description: 'Estimated Cash to Close',
      tooltip: '"Funds Due from Borrower" minus "Total Credits Applied"',
      amount: fundsToClose?.cashFromToBorrower || 0,
    };
  }

  protected tryCastToEditableCashToCloseItem(
    item: CashToCloseItem
  ): EditableCashToCloseItem<any> | null {
    return item instanceof EditableCashToCloseItem ? item : null;
  }

  private createLoanDetailItems(): CashToCloseItem[] {
    const fundsToClose = this._fundsToClose;

    return [
      {
        description: 'Loan Amount',
        amount: fundsToClose ? fundsToClose.loanAmount_I : 0,
      },
      new EditableCashToCloseItem({
        description: 'Loan Amount Excl. Financed MI',
        tooltip:
          'Loan Amount Excluding Financed Mortgage Insurance (or Mortgage Insurance Equivalent)',
        amount: fundsToClose ? fundsToClose.loanAmountExcludingFinancedMip : 0, // Initial value
        target: this.mortgage?.mortgageTerm,
        amountKey: 'amount',
        onEditingFinished: isChanged => {
          if (isChanged) {
            this.save.emit();
          }
        },
      }),
      this.createFinancedMiItem(),
      {
        description: 'Other New Loans on Property',
        tooltip:
          'Other New Mortgage Loans on the Property the Borrower(s) is Buying or Refinancing',
        amount: fundsToClose ? fundsToClose.otherNewMortgageLoansOnSubjectProperty_J : 0,
      },
      {
        description: 'Total Mortgage Loans',
        tooltip:
          'Total mortgage loans (total of Loan Amount and Other New Mortgage Loans on the' +
          ' Property the Borrower(s) is Buying or Refinancing)',
        highlighted: true,
        amount: fundsToClose ? fundsToClose.totalMortgageLoansIThroughJ_K : 0,
      },
    ];
  }

  private createFinancedMiItem(): CashToCloseItem {
    if (!this.mortgage) {
      return {
        description: 'Financed MI',
        amount: 0,
      };
    }
    const isTotal = this.mortgage.mortgageInsuranceDetail?.financeEntireMiOrFundingFee || 0;

    const scope = {
      miOrFundingFeeAmount: 0,
    };
    this.invalidateMiOrFundingFeeAmountField =
      MiOrFundingFeeUtils.invalidateMiOrFundingFeeAmountField.bind(scope);
    this.invalidateMiOrFundingFeeAmountField(this.mortgage);

    return new EditableCashToCloseItem({
      description: 'Financed MI',
      tooltip: 'Financed Mortgage Insurance (or Mortgage Insurance Equivalent) Amount',
      amount: Math.floor(scope.miOrFundingFeeAmount),
      target: scope,
      amountKey: 'miOrFundingFeeAmount',
      onEditingFinished: (isChanged: boolean) => {
        if (isChanged) {
          MiOrFundingFeeUtils.trySetEntireMiOrFundingFee(this.mortgage.mortgageInsuranceDetail);

          this.save.emit();
        }
      },
      editable: !isTotal,
      templateAction: this._templateFinanceAll,
    });
  }

  protected onFinanceAllChange(value: boolean): void {
    if (!value) {
      // This is a little hacky, but it's the easiest way to re-create the
      // Financed MI item for now.
      this.subscribeToInitCashToCloseItems();
      return;
    }

    this.invalidateMiOrFundingFeeAmountField?.(this.mortgage);
    MiOrFundingFeeUtils.trySetEntireMiOrFundingFee(this.mortgage.mortgageInsuranceDetail);

    this.save.emit();
  }
}

interface CashToCloseItem {
  readonly description: string;
  readonly tooltip?: string;
  readonly highlighted?: boolean;
  readonly templateAction?: TemplateRef<any>;
  amount: number;
}

class EditableCashToCloseItem<T extends Record<string, any>> implements CashToCloseItem {
  readonly description: string;
  readonly tooltip?: string;
  readonly highlighted?: boolean;
  readonly templateAction?: TemplateRef<any>;
  readonly editable: boolean;

  private readonly _onEditingStarted?: () => void;
  private readonly _onEditingFinished?: (isChanged: boolean) => void;
  private _editedAmount: number | null = null;

  private readonly _target: T;
  private readonly _amountKey: KeyOfType<T, number>;

  set amount(value: number) {
    if (this._target) {
      (this._target as any)[this._amountKey] = value;
    }
  }

  get amount(): number {
    return this._target ? this._target[this._amountKey] : 0;
  }

  constructor(
    props: CashToCloseItem & {
      target: T;
      amountKey: KeyOfType<T, number>;
      onEditingStarted?: () => void;
      onEditingFinished?: (isChanged: boolean) => void;
      editable?: boolean;
    }
  ) {
    this.description = props.description;
    this.tooltip = props.tooltip;
    this.highlighted = props.highlighted;
    this.templateAction = props.templateAction;
    this._target = props.target;
    this._amountKey = props.amountKey;
    this._onEditingStarted = props.onEditingStarted;
    this._onEditingFinished = props.onEditingFinished;
    this.editable = props.editable ?? true;

    const amount = props.amount;
    if (amount !== this.amount) {
      this.amount = amount;
    }
  }

  public startEditing(): void {
    this._editedAmount = this.amount;
    this._onEditingStarted?.();
  }

  public finishEditing(): void {
    const isChanged = this._editedAmount !== this.amount;
    this._editedAmount = null;
    this._onEditingFinished?.(isChanged);
  }
}

/**
 * Replaces the existing purchase credits with the same source type with the new purchase credits.
 * @remarks This function assumes that all the purchase credits in the {@link source} array have the
 * {@link sourceType} source type.
 * @param {readonly PurchaseCredit[]} target - The existing purchase credits.
 * @param {readonly PurchaseCredit[]} source - The new purchase credits.
 * @param {PurchaseCreditSourceType} sourceType - The source type of the new purchase credits.
 * This cannot be taken from {@link source} because it may be empty.
 * In that case, all the purchase credits in the {@link target} array with {@link sourceType} will be removed.
 * @returns {PurchaseCredit[]} The new purchase credits.
 */
function replaceSourceTypeCredits(
  target: readonly PurchaseCredit[],
  source: readonly PurchaseCredit[],
  sourceType: PurchaseCreditSourceType
): PurchaseCredit[] {
  // Remove the existing purchase credits with the same source type
  const nonSourceTargetCredits = target.filter(credit => credit.sourceType !== sourceType);

  // Add the new purchase credits
  return nonSourceTargetCredits.concat(source);
}
