import { Component, Input, OnInit } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { UrlaMortgage } from '../../models/urla-mortgage.model';

@Component({
    selector: 'calculated-totals',
    templateUrl: 'calculated-totals.component.html',
    viewProviders: [formViewProvider]
})

export class CalculatedTotalsComponent implements OnInit {

    @Input()
    mortgage: UrlaMortgage;

    @Input()
    isReadOnly: boolean = false;

    constructor() { }

    ngOnInit() { }
}
