/**
 * Resets the properties of the object to the values of the source object.
 * @param {Record<string, any>} object The object to reset.
 * This object will be modified.
 * @param {Record<string, any>} source The source object.
 * @example
 * const object = { a: 1, b: 2, c: { d: 3, e: 4 } };
 * const source = { a: 5, c: { d: 6 } };
 * resetObjectTo(object, source);
 * // object is now { a: 5, c: { d: 6 } }
 */
export function resetObjectTo(object: Record<string, any>, source: Record<string, any>): void {
  Object.assign(object, source);

  // Remove properties that are not in the source object.
  for (const key in object) {
    if (!(key in source)) {
      delete object[key];
    }
  }
}

/**
 * Resets elements of the array to the elements of the source array.
 * @param {any[]} array The array to reset.
 * This array will be modified.
 * @param {any[]} source The source array.
 * @example
 * const array = [1, 2, 3];
 * const source = [4, 5];
 * resetArrayTo(array, source);
 * // array is now [4, 5]
 */
export function resetArrayTo(array: any[], source: any[]): void {
  array.length = 0;
  array.push(...source);
}
