import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoanDoc } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { DocFile } from '../../models/doc-file.model';
import { FileTrackingInfo } from '../../models/file-tracking-info.model';
import { MergeDocFilesRequest } from '../../models/merge-files/merge-doc-files-request.model';
import { LoanDocsService } from '../../services/loan-docs.service';
import { LoanDocsTrackingDialogComponent } from '../loan-docs-tracking-dialog/loan-docs-tracking-dialog.component';
import { ProcessAdrOcrDialogComponent } from '../process-adr-ocr-dialog/process-adr-ocr-dialog.component';
import * as _ from 'lodash';
import { DocFilesEditDialogComponent } from '../doc-files-edit-dialog/doc-files-edit-dialog.component';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { Utils } from 'src/app/core/services/utils';

@Component({
    selector: 'doc-files',
    templateUrl: 'doc-files.component.html',
    styleUrls: ['doc-files.component.scss']
})

export class DocFilesComponent implements OnInit {

    @Input()
    file: DocFile;

    @Input()
    loanDoc: LoanDoc;

    @Input()
    editLoanDocs: LoanDoc[];

    @Input()
    icOcrEnabled: boolean;

    @Input()
    isTpoUser: boolean;

    @Input()
    appId: number;

    @Input()
    globalConfig: GlobalConfig;

    @Input()
    downloadUserId: string;

    @Output()
    loanDocUpdated: EventEmitter<LoanDoc> = new EventEmitter<LoanDoc>();

    showConvertToPdf: boolean;

    tempFileName: string;

    shouldEdit: boolean;

    fileName: string;
    fileExtension: string;

    activeDeletionIndex: number = -1;

    fileTrackingInfo: FileTrackingInfo;

    modalOptions: NgbModalOptions;

    constructor(private readonly _loanDocsService: LoanDocsService,
        private readonly _notifyService: NotificationService,
        private readonly _spinner: NgxSpinnerService,
        private readonly _modalService: NgbModal) {
        this.modalOptions = {
            windowClass: 'modal-full-width',
            backdrop: 'static',
            centered: true,
        };
    }

    ngOnInit() {
        this.initialize();
    }

    onEditFileName = () => {
        this.shouldEdit = true;
    }

    cancelLoanDocEdit = () => {
        const { fileName } = Utils.getFileNameAndExtension(this.tempFileName);
        this.fileName = fileName;
        this.shouldEdit = false;
    }

    toggleFileSelected = (file: DocFile) => {
        if (file.selectedForAction == true)
            file.selectedForAction = false;
        else
            file.selectedForAction = true;
    }

    viewLoanDocument = (file: DocFile) => {
        this._spinner.show();
        this._loanDocsService.viewDocFileContent(this.appId, this.downloadUserId, file.docFileId, file.losDocFileId).subscribe({
            next: (data) => {
                const blob = new Blob([data], { type: data['type'] });
                const url = window.URL.createObjectURL(blob);
                window.open(url);
            },
            error: (error) => { }
        }).add(() => this._spinner.hide());
    }

    downloadDocument = (file: DocFile) => {
        this._spinner.show();
        this._loanDocsService.getLoanDocContent(file.guid).subscribe({
            next: data => {
                const blob = new Blob([data], { type: data['type'] });
                let downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                let fileName = this.fileName + "." + this.fileExtension;
                downloadLink.setAttribute('download', fileName);
                document.body.appendChild(downloadLink);
                downloadLink.click();
            },
            error: (error) => { }
        }).add(() => this._spinner.hide());
    }

    onShowTrackingModalClicked = (file: DocFile) => {
        this._loanDocsService.getFileTrackingInfo(file.guid).subscribe(result => {
            this.fileTrackingInfo = new FileTrackingInfo(file.fileName, {});

            Object.assign(this.fileTrackingInfo.trackingInfo, result);

            let modalRef = this._modalService.open(LoanDocsTrackingDialogComponent, Constants.modalOptions.xlarge);
            modalRef.componentInstance.title = this.fileTrackingInfo.description;
            modalRef.componentInstance.trackingInfos = this.fileTrackingInfo.trackingInfo;
            modalRef.result.then(result => {

            });
        }, err => {
            this._notifyService.showError(err.message || 'Error retrieving doc file tracking information', 'Error!');
        })
    }


    onShowMergeFilesClicked = (file: DocFile) => {
        const modalRef = this._modalService.open(DocFilesEditDialogComponent, this.modalOptions)
        modalRef.componentInstance.title = 'Document File Editor';
        modalRef.componentInstance.file = file;
        modalRef.componentInstance.fileExtension = this.fileExtension;
        modalRef.componentInstance.appId = this.appId;
        modalRef.componentInstance.loanDocs = this.editLoanDocs;
        modalRef.componentInstance.globalConfig = this.globalConfig;
        modalRef.componentInstance.modalOptions = this.modalOptions;
        modalRef.result.then((result) => {

        }, (res) => {
        });
    }

    onSyncDocFileWithLosClicked = (loanDoc: LoanDoc, docFileId: number) => {
        this._spinner.show();
        this._loanDocsService.syncDocFileWithLos(loanDoc, docFileId).subscribe(result => {
            this.file = result;
            this._spinner.hide();
            this._notifyService.showSuccess('Pushed to los successfully!', 'Success');
        }, err => {
            this._spinner.hide();
            this._notifyService.showError(err.message || 'Unable to push to los', 'Failure');
        })
    }

    saveLoanDoc = () => {
        this._spinner.show();
        this.tempFileName = this.fileName + "." + this.fileExtension;

        let file = new MergeDocFilesRequest(null, null, this.file.guid, (this.fileName + "." + this.fileExtension), null, false);
        this.file.fileName = this.fileName + "." + this.fileExtension;

        this._loanDocsService.mergeDocFiles(file, this.loanDoc.loanDocId).subscribe(result => {
            this._spinner.hide();
            this.shouldEdit = false;
            this._notifyService.showSuccess('Save Document', 'Success!');
        }, err => {
            this._spinner.hide();
            this._notifyService.showError(err.message || 'Edit Document', 'Fail!');
        })
    }

    onDeleteLoanDocCancelClicked = () => {
        this.activeDeletionIndex = -1;
    }

    onDeleteLoanDocClicked = (index: number) => {
        this.activeDeletionIndex = index;
    }

    onDeleteLoanDocConfirmClicked = (file: DocFile) => {
        this._spinner.show();
        this.activeDeletionIndex = -1;
        this._loanDocsService.deleteDocFile(this.loanDoc, file.guid).subscribe(result => {
            this._spinner.hide();
            this._notifyService.showSuccess('Moved file to trash!', 'Success');
            const index = this.loanDoc.docFiles.indexOf(file);
            if (index >= 0) {
                this.loanDoc.docFiles.splice(index, 1);
                if (!this.loanDoc.docFiles.length) {
                    this.loanDoc.documentTypeId = -1;
                }
                this.loanDocUpdated.emit(this.loanDoc);
            }
        }, err => {
            this._spinner.hide();
            this._notifyService.showError(err.message || 'Unable to move file to trash!', 'Failure');
        })
    }

    onConvertToPdfClicked = (file: DocFile) => {
        this._spinner.show();
        this._loanDocsService.convertToPdf(file, file.guid).subscribe(result => {
            this._spinner.hide();
            this._notifyService.showSuccess('Conversion was successful.', 'Success!');
            this.showConvertToPdf = false;
            if (result) {
                this.tempFileName = _.cloneDeep(result);

                const { fileName, extension } = Utils.getFileNameAndExtension(this.file.fileName);

                this.fileName = fileName;
                this.fileExtension = extension;
            }
        }, err => {
            this._spinner.hide();
            this._notifyService.showError(err ? err.message : 'Conversion failed.', 'Error converting document to pdf!');
        })
    }

    onProcessAdrOcrClicked = (file: DocFile) => {
        this._spinner.show();
        this._loanDocsService.setProcessAdrOcr(file, file.guid).subscribe(result => {
            file.ocrProcessed = true;
            this._spinner.hide();
            let modalRef = this._modalService.open(ProcessAdrOcrDialogComponent, Constants.modalOptions.large);
            modalRef.result.then(result => {

            });
        }, err => {
            this._spinner.hide();
            this._notifyService.showError(err ? err.message : 'Error with processing a file.', 'Error!');
        })
    }

    private initialize = () => {
        this.shouldEdit = false;

        if (this.file && this.file.fileName) {
            this.showConvertToPdf = !this.file.fileName.toLowerCase().endsWith(".pdf");
            this.tempFileName = _.cloneDeep(this.file.fileName);

            const { fileName, extension } = Utils.getFileNameAndExtension(this.file.fileName);

            this.fileName = fileName;
            this.fileExtension = extension;
        } else {
            this.showConvertToPdf = false;
            this.tempFileName = '';
        }
    }
}
