import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Address } from '../../../../../../../../models';
import { EnumerationItem } from '../../../../../../../../models/simple-enum-item.model';
import { EnumerationService } from '../../../../../../../../services/enumeration-service';
import { ZipCodeLookupResult } from '../../../../../../../../models/zipcode-lookup-result.model';
import { AddressLookupService } from '../../../../../../../../services/address-lookup.service';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { v4 as uuidv4 } from 'uuid';
import { splitCamelCase, toKebabCase } from '../../../../../../../../core/services/string-utils';
import { AddressLookupResult } from '../../../../../../../../../utils/address-lookup-parser';
import { FormGroup, NgForm, ValidationErrors } from '@angular/forms';

type FormValue = Partial<Address>;

@Component({
  selector: 'qa-fi-employment-address',
  templateUrl: './qa-fi-employment-address.component.html',
  styleUrls: ['./qa-fi-employment-address.component.scss'],
})
export class QaFiEmploymentAddressComponent implements OnChanges, OnInit, OnDestroy {
  @Input() address?: Address;
  @Output() addressChange = new EventEmitter<FormValue>();

  @Input() employerName: string;
  @Output() employerNameChange = new EventEmitter<string>();

  @Input() set fieldsConfig(value: Partial<FieldsConfig>) {
    const valueOrDefault = (key: keyof FieldsConfig) =>
      value?.[key] || { required: false, requested: false };

    this.effectiveFieldsConfig = {
      employer: valueOrDefault('employer'),
      address1: valueOrDefault('address1'),
      address2: valueOrDefault('address2'),
      city: valueOrDefault('city'),
      state: valueOrDefault('state'),
      zipCode: valueOrDefault('zipCode'),
    };
  }
  protected effectiveFieldsConfig: FieldsConfig;

  @ViewChild('form') protected ngForm: NgForm;
  protected get form(): FormGroup | undefined {
    return this.ngForm?.form;
  }

  protected addressLookupOptions: Readonly<Partial<Options>> = {
    types: ['establishment'],
  };

  protected value: FormValue = {};

  protected stateItems: EnumerationItem[];

  protected zipCode: string;

  @HostBinding() id: string = `qa-fi-employment-address-${uuidv4()}`;
  protected controlIds: Readonly<Partial<Record<keyof Address | 'employer', string>>>;

  constructor(
    private readonly _addressLookupService: AddressLookupService,
    enumService: EnumerationService,
  ) {
    this.stateItems = enumService.states;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const addressChange = changes.address;
    if (addressChange) {
      const address = addressChange.currentValue;
      this.invalidateForm(address);
    }
  }

  ngOnInit(): void {
    if (this.address == null) {
      this.invalidateForm();
    }

    this.initControlIds();
  }

  ngOnDestroy(): void {
    this.addressChange.complete();
  }

  private invalidateForm(address?: Address): void {
    const factory = new FormValueFactory({ address: address || {} });
    this.value = factory.createFormValue();
    this.zipCode = address?.zipCode;
  }

  private initControlIds(): void {
    const keys: Array<keyof Address | 'employer'> = [
      'employer',
      'address1',
      'city',
      'state',
      'zipCode',
    ];

    this.controlIds = keys.reduce((acc, key) => {
      acc[key] = `${this.id}-${toKebabCase(splitCamelCase(key))}`;
      return acc;
    }, {} as Record<keyof Address, string>);
  }

  protected isRequired(key: keyof FieldsConfig): boolean {
    const config = this.effectiveFieldsConfig[key];
    if (config == null) {
      return false;
    }

    return config.required || config.requested;
  }

  protected getErrors(path: string): ValidationErrors | undefined {
    const control = this.form?.get(path);
    if (control == null) {
      return undefined;
    }

    const config = this.effectiveFieldsConfig[path as keyof FieldsConfig];
    if (config == null) {
      return undefined;
    }

    if (!(control.invalid && (control.dirty || control.touched))) {
      return undefined;
    }

    if (!control.errors?.required) {
      return undefined;
    }

    return config;
  }

  protected onChangeControlValue(): void {
    this.addressChange.emit(this.value);
  }

  protected onChangeAddress(address: Partial<AddressLookupResult>): void {
    this.employerNameChange.emit(address.name);
    this.addressChange.emit({
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      state: address.state,
      zipCode: address.zipCode,
    });
  }

  protected onChangeZipCode(result: ZipCodeLookupResult): void {
    const address = this._addressLookupService.parseZipCodeLookup(result);
    if (address.zipCode == null) {
      return;
    }

    this.addressChange.emit({
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      state: address.state,
      zipCode: address.zipCode,
      country: 'us'
    });
  }
}

class FormValueFactory {
  private readonly _address: FormValue;

  constructor({ address }: {address: FormValue}) {
    this._address = address || {};
  }

  createFormValue(): FormValue {
    const address = this._address;

    return {
      address1: address?.address1 || null,
      address2: address?.address2 || null,
      city: address?.city || null,
      state: address?.state || null,
      zipCode: address?.zipCode || null,
    };
  }
}

export interface FieldsConfig {
  address1: FieldConfig;
  address2: FieldConfig;
  city: FieldConfig;
  state: FieldConfig;
  zipCode: FieldConfig;
  employer: FieldConfig;
}

export interface FieldConfig {
  required: boolean;
  requested: boolean;
}
