<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      <div>
        Points and Fees
      </div>
    </h4>
  </div>
  <div class="card-body">
    <form #pointsAndFeesForm="ngForm" id="pointsAndFeesForm">
      <div class="row">
        <div class="col-md-5">
          <div class="row">
            <div class="col-md-6 form-group">
              <label for="excludedBonaFideDiscountPoints" title="Excluded Bona Fide Discount Points"> Excluded Bona Fide
                Discount Pts. </label>
              <percent-input [(ngModel)]="regulationZDetail.excludedBonaFideDiscountPoints"
                name="excludedBonaFideDiscountPoints">
              </percent-input>
            </div>
            <div class="col-md-6 form-group">
              <label for="totalAffiliateFees"> Total Affiliate Fees </label>
              <currency-input [allowNegative]="false" [(ngModel)]="regulationZDetail.totalAffiliateFees"
                name="totalAffiliateFees">
              </currency-input>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-4 form-group">
              <label for="totalPointsAndFees"> Total Points and Fees </label>
              <currency-input [allowNegative]="false" [(ngModel)]="regulationZDetail.totalPointsAndFees"
                name="totalPointsAndFees">
              </currency-input>
            </div>
            <div class="col-md-5 form-group">
              <label for="regulationZTotalLoanAmount"> Regulation Z Total Loan Amount </label>
              <currency-input [allowNegative]="false" [(ngModel)]="regulationZDetail.regulationZTotalLoanAmount"
                name="regulationZTotalLoanAmount">
              </currency-input>
            </div>
            <div class="col-md-3 form-group">
              <label for="isHighCostLoan"> Is High Cost Loan </label>
              <select name="isHighCostLoan" id="isHighCostLoan" class="form-select"
                [(ngModel)]="regulationZDetail.isHighCostLoan">
                <option [ngValue]="null">-- Select One --</option>
                <option [ngValue]="true">Yes</option>
                <option [ngValue]="false">No</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>