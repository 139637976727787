<form #qualifyingTheBorrowerForm="ngForm">
  <div class="grid-layout has-border" *ngIf="mortgage.mortgageId != null">
    <due-from-borrower
      [mortgage]="mortgage"
      [landValueType]="landValueType"
      [isReadOnly]="isReadOnly"
      [inEditMode]="inEditMode"
      (purchasePriceAmountChanged)="onPurchasePriceAmountChanged($event)"
    >
    </due-from-borrower>
    <mortgage-loan-details
      [mortgage]="mortgage"
      [isReadOnly]="isReadOnly"
      [inEditMode]="inEditMode"
      (loanAmountChanged)="loanAmountChange($event)"
      (miAndFundingFeeChanged)="miAndFundingFeeChange($event)"
    >
    </mortgage-loan-details>
    <credit-details
      [mortgage]="mortgage"
      [application]="application"
      [purchaseCreditTypes]="purchaseCreditTypes"
      [isReadOnly]="isReadOnly"
      [inEditMode]="inEditMode"
    >
    </credit-details>
    <calculated-totals
      [mortgage]="mortgage"
      [isReadOnly]="isReadOnly">
    </calculated-totals>
    <borrower-qualification
      [mortgage]="mortgage"
      [isReadOnly]="isReadOnly"
      [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [yesNoOptions]="yesNoOptions"
    >
    </borrower-qualification>
    <underwriting
      *ngIf="mortgage.companyId != 229"
      [mortgage]="mortgage"
      [ausSystemTypes]="ausSystemTypes"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [yesNoOptions]="yesNoOptions"
      [freddieMacLoanPrograms]="freddieMacLoanPrograms"
      [freddieMacRefinanceProgram]="freddieMacRefinanceProgram"
      [inEditMode]="inEditMode"
      [isReadOnly]="isReadOnly"
    >
    </underwriting>
  </div>
</form>
<loading-indicator
  *ngIf="mortgage.mortgageId == null"
  [loadingMessage]="'Loading borrower qual. info...'"
></loading-indicator>
