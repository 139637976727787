<form #assetDetailsForm="ngForm" novalidate id="assetDetailsForm" name="assetDetailsForm" class="p-3">

  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="isDownPaymentSource">Is Downpayment Source?</label>
      <div class="form-check">
        <input class="form-check-input" name="isDownPaymentSource" type="checkbox" id="isDownPaymentSource"
          [(ngModel)]="asset.isDownPaymentSource" />
      </div>
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="isLiquid">Is Liquid?</label>
      <div class="form-check">
        <input class="form-check-input" name="isLiquid" id="isLiquid" type="checkbox"
          [(ngModel)]="asset.isLiquid" />
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="verificationStatus">Verification Status</label>
      <select class="form-select" name="verificationStatus" id="verificationStatus"
        [(ngModel)]="asset.verificationStatus" (change)="onVerificationStatusChanged()">
        <option [ngValue]="null">--Select One--</option>
        <option [ngValue]="option.value" *ngFor="let option of verificationStatusOptions">
          {{ option.name }}
        </option>
      </select>
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="verifiedBy">Verified By</label>
      <input class="form-control" [disabled]="asset.verificationStatus !== 'Verified'"
        name="verifiedBy" [(ngModel)]="asset.verifiedBy" />
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="giftOrGrantSource">Gift or Grant Source</label>
      <select class="form-select" name="giftOrGrantSource" id="giftOrGrantSource"
        [(ngModel)]="asset.giftOrGrantSource" (change)="onGiftOrGrantSourceChanged()">
        <option [ngValue]="null">--Select One--</option>
        <option [ngValue]="option.value" *ngFor="let option of giftOrGrantSourceOptions">
          {{ option.name }}
        </option>
      </select>
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="giftOrGrantSourceOtherDescription">Gift Grant Source Description</label>
      <input class="form-control" [disabled]="asset.giftOrGrantSource !== 'Other'" id="giftOrGrantSourceOtherDescription"
        name="giftOrGrantSourceOtherDescription" [(ngModel)]="asset.giftOrGrantSourceOtherDescription" />
    </div>
  </div>


  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="isDeposited">Include In Calculations?</label>
      <div class="form-check">
        <input class="form-check-input" name="isDeposited" type="checkbox" id="isDeposited"
          [(ngModel)]="asset.isDeposited" />
      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="hasSaveCancelButtons">
    <div class="col-md-12 text-end">
      <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
      <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
        Save
      </button>
    </div>
  </div>
</form>
