<div class="modal-header">
  <h5 class="modal-title"> Products & Pricing <span *ngIf="transactionType">( {{transactionType == 'reprice' ? 'Reprice' : 'Program Change'}} )</span></h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <pricing-search-v2
      [lockStatus]="lockStatus"
      [transactionType]="transactionType"
      [canPriceApplyToLoan]="!isLoanReadOnly"
      [currentApplication]="currentApplication"
      [productSearchRequestInfo]="productSearchRequestInfo"
      [showScenarios]="false"
      [currentMortgage]="currentMortgage"
      (productPriceSelectedForApplicationFinished)="onProductPriceSelectedForApplicationFinished($event)"
      (productRepriceSelectedForApplicationFinished)="onProductRepriceSelectedForApplicationFinished($event)"
      (scenariosAdded)="onScenariosAdded()">
    </pricing-search-v2>
  </div>
</div>
<div class="modal-footer modal-footer--sticky">
  <button type="button" class="btn btn-primary" (click)="activeModal.close()" data-bs-dismiss="modal"> OK </button>
</div>
