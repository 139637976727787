<loading-indicator *ngIf="loadingContacts" [loadingMessage]="'Loading external contacts, please wait...'">
</loading-indicator>
<div class="row" *ngIf="!loadingContacts">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title"> External Contacts </h4>
      </div>
      <div class="card-body">
        <div class="row">
          <p-table #externalContactTable [value]="filteredExternalContacts" [paginator]="true" [rows]="25" [rowsPerPageOptions]="[10,25,50]"
            [resizableColumns]="true" responsiveLayout="scroll" [autoLayout]="true"
            [globalFilterFields]="['lastName','firstName','agentType.agentTypeName','mobilePhone','email','companyName','fax','nmlsId','mailingStreet', 'mailingCity','mailingPOBox','mailingCountry','mailingZip','mailingState']"
            [showCurrentPageReport]="true" [(selection)]="selectedRows"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines" [sortField]="'agentType.agentTypeName'" sortMode="single" [sortOrder]="1">
            <ng-template pTemplate="caption">
              <div class="p-d-flex">
                <div class="col-sm-3">
                  <div class="btn-group" role="group" aria-label="Filters">
                    <input type="radio" class="btn-check radio-button-primary" name="all" id="all" value="All" [(ngModel)]="filter"
                      autocomplete="off" (change)="onFilterChanged()" />
                    <label class="btn btn btn-md btn-soft-primary" for="all">All</label>

                    <input type="radio" class="btn-check radio-button-primary" name="existing" id="existing" value="Existing" [(ngModel)]="filter"
                      autocomplete="off" (change)="onFilterChanged()" />
                    <label class="btn btn btn-md btn-soft-primary" for="existing">Existing</label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <button class="btn btn-primary me-3"
                    [routerLink]="'/' + applicationMode + '/app-details/' + appId + '/associate-file-contact'">
                    <i class='fas fa-plus'></i> Associate
                  </button>
                  <button [disabled]="(selectedRows && selectedRows.length === 0) || !selectedRows"
                    class="btn btn-outline-primary pull-right me-3" (click)="mailExternalContact()">
                    <i class='fa fa-envelope'></i> Send Email
                  </button>
                </div>
                <div class="col-sm-6 d-flex">
                  <button [disabled]="(selectedRows && selectedRows.length === 0) || !selectedRows"
                    class="btn btn-outline-primary" (click)="copyExternalContactToClipboard()">
                    <i class='fa fa-clipboard'></i> Copy Contact Info
                  </button>
                  <span class="p-input-icon-right p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="form-control" (ngModelChange)="externalContactTable.first=0"
                      (input)="externalContactTable.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                  </span>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 15%;">
                  Action
                </th>
                <th [pSortableColumn]="'agentType.agentTypeName'" style="width: 20%;">
                  <i class="fa fa-fw fa-user hidden-md hidden-sm hidden-xs"></i> Agent Type
                  <p-sortIcon [field]="'agentType.agentTypeName'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'lastName'">
                  <i class="fa fa-fw fa-user hidden-md hidden-sm hidden-xs"></i> Name / Company
                  <p-sortIcon [field]="'lastName'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'mobilePhone'">
                  <i class="fa fa-fw fa-phone hidden-md hidden-sm hidden-xs"></i> Contact Info
                  <p-sortIcon [field]="'mobilePhone'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'mailingStreet'">
                  <i class="hidden-md hidden-sm hidden-xs"></i> Address
                  <p-sortIcon [field]="'mailingStreet'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'nmlsId'">
                  <i class="hidden-md hidden-sm hidden-xs"></i> License Info
                  <p-sortIcon [field]="'nmlsId'"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-externalContact let-index>
              <tr>
                <td>
                  <div class="d-flex align-items-left justify-content-left w-100">
                    <p-tableCheckbox *ngIf="externalContact.externalContactId" [value]="externalContact">
                    </p-tableCheckbox>
                    <a class="hover" (click)="externalContact['expanded'] = !externalContact['expanded'];"
                      title="Show Details" style="color: #35aaef;">
                      <i class="fas fa-edit mx-1"></i>
                    </a>
                    <ng-container *ngIf="externalContact.externalContactId">
                      <a *ngIf="(activeDeletionIndex !== index)" (click)="onDeleteAgentClicked(index)"><i
                          class="fa fa-trash-alt"></i></a>
                      <a class="danger" *ngIf="activeDeletionIndex === index" (click)="onCancelDeleteClicked()">
                        <i class="fa fa-times"></i></a>
                      <a class="danger" *ngIf="activeDeletionIndex === index"
                        (click)="onConfirmDeleteClicked(externalContact.externalContactId)"><i
                          class="fa fa-check-circle"></i></a>
                    </ng-container>
                    <span *ngIf="externalContact.email" class="text-muted mx-1">|</span>
                    <a *ngIf="externalContact.email" (click)="onExternalContactEmailClicked(externalContact)"><i
                        class="far fa-fw fa-envelope text-muted hidden-md hidden-sm hidden-xs"></i>
                    </a>
                    <span *ngIf="externalContact.email" class="text-muted mx-1">|</span>
                    <a class="hover" (click)="onAssociateClicked(externalContact)" style="color: #35aaef;">
                      <span class="mx-2"> {{externalContact.agentId ? 'Re-Associate' :
                        'Associate'}} </span>
                    </a>
                  </div>
                </td>
                <td>
                  <span [title]="externalContact.agentType?.agentTypeName || '--'" class="text-cell-primary text-ellipsis cell-mw225">
                    {{externalContact.agentType?.agentTypeName ? externalContact.agentType.agentTypeName :
                    '--'}} </span>
                </td>
                <td>
                  <span title="{{externalContact.lastName || '--'}}, {{externalContact.firstName || '--'}}"
                    class="text-cell-primary text-ellipsis cell-mw150">
                    {{externalContact.lastName || '--'}}, {{externalContact.firstName || '--'}}
                  </span>
                  <br>
                  <span title="{{externalContact.companyName || '--'}}" class="text-muted text-ellipsis cell-mw150">
                    {{externalContact.companyName || '--'}}
                  </span>
                </td>
                <td>
                  <table-cell-contact-info
                    [contactInfo]="externalContact"
                    [recordType]="'Agent'"
                    [dialerEnabled]="true"
                    [showFax]="true"
                    [showHomePhone]="false"
                    [recordId]="externalContact.agentId">
                  </table-cell-contact-info>
                </td>
                <td>
                  <span style="display: block;width: 120px;white-space: normal;word-break: break-all;">
                      <span title="Street Address"> {{externalContact.mailingStreet ?
                        externalContact.mailingStreet : '--'}}
                      </span> /
                      <span title="PO Box"> {{externalContact.mailingPOBox ? externalContact.mailingPOBox :
                        '--'}}
                      </span>
                    <br>
                    <span title="City"> {{externalContact.mailingCity || '--'}} </span>
                      <span title="State"> {{(externalContact.mailingState | uppercase) || '--'}} </span>
                      <span title="Zip Code"> {{externalContact.mailingZip || '--'}} </span>
                    <ng-container
                      *ngIf="!!externalContact.mailingCountry && externalContact.mailingCountry !== 'us'">
                      <br>
                      <span title="Country"> {{(externalContact.mailingCountry | uppercase)}}
                      </span>
                    </ng-container>
                  </span>
                </td>
                <td>
                  <span *ngIf="externalContact.nmlsId">NMLS: {{externalContact.nmlsId ? externalContact.nmlsId :
                    '--'}}</span>
                  <br>
                  <span *ngIf="externalContact.stateLicenseId"> State Lic: {{externalContact.stateLicenseId}} </span>
                </td>
              </tr>
              <tr *ngIf="externalContact['expanded']">
                <td colspan="7">
                  <basic-information [externalContact]="externalContact" [countries]="countries"
                    (cancelClicked)="externalContact['expanded'] = !externalContact['expanded'];"
                    (saveClicked)="onBasicInfoSaveClicked($event);">
                  </basic-information>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="7" class="text-center"> No data available in table </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<drawer [title]="'Send E-mail'" [name]="'sendEmailDrawerForExternalContacts'" [templateRef]="sendEmailContentRef"
  [options]="drawerOptionsXl" (closed)="onSendEmailDrawerClosed()">
</drawer>
<ng-template #sendEmailContentRef>
  <div style="padding: 20px">
    <send-email-sms-dialog
      [isModal]="false"
      [to]="emailRecipients"
      [isShowOnlyEmail]="true"
      [showBorrowerPicker]="false"
      (sentSuccessfully)="onEmailSentSuccessfully()"
      (cancelled)="onEmailSendCancelled()">
    </send-email-sms-dialog>
  </div>
</ng-template>
