import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { CalculatorHousingParams } from '../housing-calculator/housing-calculator.component';

@Component({
  selector: 'cash-out-calculator',
  templateUrl: './cash-out-calculator.component.html',
})
export class CashOutCalculatorComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  proposedParameters = new CalculatorHousingParams();

  cashOutAmount: number = 0;

  imageData: SafeResourceUrl;

  constructor(
    private readonly _mortgageCalculationService: MortgageCalculationService,
    private readonly _utilityService: UtilityService
  ) {
    this._utilityService.getImageFileContentAsBase64("assets/images/cash-out.png", (result) => {
      this.imageData = result;
    });
  }

  ngOnInit(): void {
    let sectionAmounts = this._mortgageCalculationService.getFtcSectionAmounts(this.mortgage);
    let total = sectionAmounts["allTotal"];
    if (total > 0) {
      this.cashOutAmount = total;
    }
  }

  onParametersChanged = (newParams: CalculatorHousingParams) => {
    this.proposedParameters = { ...newParams };

  }

}
