<div class="row">
  <div class="form-group col-xs-12 col-sm-8 col-lg-4" [ngClass]="{'col-lg-8': isDrawer}"
    *ngIf="!isSubjectPropertyAddressHidden">
    <label for="subjectPropertyStreet">Subject Property Address
      <a (click)="copyAddressFromTo('present', 'subjectProperty')" class="ms-2">Copy from Borrower Present</a>
    </label>
    <address-input [name]="'subjectPropertyStreet'" [(ngModel)]="lead.subjectPropertyAddress1"
      [placeholder]="'123 Main St.'" (addressChanged)="onAddressChanged($event)"></address-input>
  </div>
  <div class="form-group col-xs-12 col-sm-4 col-lg-2" [ngClass]="{'col-lg-4': isDrawer}"
    *ngIf="!isSubjectPropertyAddressHidden">
    <label for="subjectPropertyPOBox">Unit/Suite</label>
    <input id="subjectPropertyPOBox" name="subjectPropertyPOBox" type="text" class="form-control"
      [(ngModel)]="lead.subjectPropertyAddress2" />
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="subjectPropertyCity">City</label>
    <input id="subjectPropertyCity" name="subjectPropertyCity" type="text" class="form-control"
      [(ngModel)]="lead.subjectPropertyCity" />
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <div class="row">
      <div class="col">
        <label for="subjectPropertyState">State</label>
        <select id="subjectPropertyState" name="subjectPropertyState" class="form-select" placeholder="State"
          [(ngModel)]="lead.subjectPropertyState">
          <option [ngValue]="null"></option>
          <option *ngFor="let state of states" [ngValue]="state.code">
            {{ state.name }}
          </option>
        </select>
      </div>
      <div class="col">
        <label for="subjectPropertyZip">Zip Code</label>
        <zip-code-input [(ngModel)]="lead.subjectPropertyZip" [name]="'subjectPropertyZip'" [required]="false"
          [disabled]="false" [readonly]="false" (selectionChanged)="
            onZipCodeRelatedInfoChanged($event, 'subjectProperty')
          "></zip-code-input>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <ng-container *ngIf="lead.leadId || (!lead.leadId && lead.loanPurposeId != 2)">
      <label for="propertyWillBe">Property Will Be</label>
      <select class="form-select" name="propertyWillBe" id="propertyWillBe" [(ngModel)]="lead.propertyWillBe">
        <option [ngValue]="null">--Select One--</option>
        <option *ngFor="let option of propertyWillBeOptions" value="{{option.alternateValue}}">
          {{option.name}}
        </option>
      </select>
    </ng-container>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="propertyUse">Property Use</label>
    <select class="form-select" name="propertyUse" id="propertyUse" [(ngModel)]="lead.propertyUse">
      <option [ngValue]="null">--Select One--</option>
      <option value="Primary">Primary</option>
      <option value="SecondaryOrVacation">Secondary Or Vacation</option>
      <option value="InvestmentOrRental">Investment Or Rental</option>
    </select>
  </div>
</div>

<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="attachmentType">Attachment Type</label>
    <select class="form-select" name="attachmentType" id="attachmentType" [(ngModel)]="lead.attachmentType"
      (ngModelChange)="onAttachmentTypeChanged()">
      <option [ngValue]="null">--Select One--</option>
      <option *ngFor="let option of attachmentTypeOptions" value="{{option.alternateValue}}">
        {{option.name}}
      </option>
    </select>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="projectType">Project Type<i *ngIf="lead.attachmentType !== 'Attached'"
        class="fa fa-info-circle text-primary ms-1" placement="top"
        ngbTooltip="You can select Project Type only when Attachment Type is set to 'Attached'"></i></label>
    <select class="form-select" name="projectType" id="projectType" [(ngModel)]="lead.projectType"
      [disabled]="lead.attachmentType !== 'Attached'">
      <option [ngValue]="null">--Select One--</option>
      <option *ngFor="let option of projectTypeOptions" value="{{option.alternateValue}}">
        {{option.name}}
      </option>
    </select>
  </div>
</div>

<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="constructionMethod">Construction Method</label>
    <select class="form-select" name="constructionMethod" id="constructionMethod" [(ngModel)]="lead.constructionMethod">
      <option [ngValue]="null">--Select One--</option>
      <option *ngFor="let option of constructionMethodOptions" value="{{option.alternateValue}}">
        {{option.name}}
      </option>
    </select>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="noOfUnits">Number Of Units</label>
    <input class="form-control" name="noOfUnits" id="noOfUnits" [(ngModel)]="lead.noOfUnits" numeric [min]="1"
      [max]="10" />
  </div>
</div>

<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label class="form-check form-check-inline form-check-solid me-5 mx-2">
      <input class="form-check-input" name="isPlannedUnitDevelopment" type="checkbox"
        [(ngModel)]="lead.isPlannedUnitDevelopment" />
      <span class="fs-6"><strong> Is Planned Unit Development</strong></span>
    </label>
  </div>
</div>