import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxPrintElementModule } from 'ngx-print-element';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { LenderConfigModule } from '../admin/lender-config/lender-config.module';
import { LenderConfigService } from '../admin/lender-config/services/lender-config.service';
import { AgentInfoModule } from '../agent-info/agent-info.module';
import { CopyApplicationAccordionComponent } from '../applications/copy-application-accordion/copy-application-accordion.component';
import { CopyApplicationDialogComponent } from '../applications/copy-application-dialog/copy-application-dialog.component';
import { BorrowerModule } from '../borrower/borrower.module';
import { CorrespondenceModule } from '../correspondence/correspondence.module';
import { DisclosureTrackingModule } from '../disclosure-tracking/disclosure-tracking.module';
import { EsignatureModule } from '../esignature/esignature.module';
import { EventsModule } from '../events/events.module';
import { FeesModule } from '../fees/fees.module';
import { KeyDatesModule } from '../key-dates/key-dates.module';
import { LdeInfoModule } from '../lde-info/lde.module';
import { LoanActivityModule } from '../loan-activity/loan-activity.module';
import { LoanDocsModule } from '../loan-docs/loan-docs.module';
import { AusModule } from '../loan-services/aus/aus.module';
import { CreditModule } from '../loan-services/credit/credit.module';
import { LoanCreditModule } from '../loan-services/loan-credit/loan-credit.module';
import { LoanSummaryBarModule } from '../loan-summary-bar/loan-summary-bar.module';
import { LosInfoModule } from '../los-info/los.module';
import { McrDataAuditModule } from '../mcr-data-audit/mcr-data-audit.module';
import { PricingModule } from '../pricing/pricing.module';
import { ReferralSourceModule } from '../referral-source/referral-source.module';
import { TasksModule } from '../tasks/tasks.module';
import { UrlaModule } from '../urla/urla.module';
import { AppDetailsRoutingModule } from './app-details-routing.module';
import { AdminLoanSummaryTopBarComponent } from './components/admin-loan-summary-top-bar/admin-loan-summary-top-bar.component';
import { LoanSummaryMoreMenuComponent } from './components/admin-loan-summary-v2-more-menu/loan-summary-more-menu.component';
import { AddressDetailViewDialogComponent } from './components/admin-loan-summary/address-detail-view-dialog/address-detail-view-dialog.component';
import { AdminLoanSummaryComponent } from './components/admin-loan-summary/admin-loan-summary.component';
import { AnnualUsageDialogComponent } from './components/admin-loan-summary/annual-usage-dialog/annual-usage-dialog.component';
import { LoanSummaryHEAComponent } from './components/admin-loan-summary/loan-summary-hea/loan-summary-hea.component';
import { LoanSummaryMortgageComponent } from './components/admin-loan-summary/loan-summary-mortgage/loan-summary-mortgage.component';
import { LoanSummarySolarComponent } from './components/admin-loan-summary/loan-summary-solar/loan-summary-solar.component';
import { AppChecklistsComponent } from './components/app-checklists/app-checklists.component';
import { AppDetailsMenuComponent } from './components/app-details-v2/app-details-menu/app-details-menu.component';
import { AppDetailsV2Component } from './components/app-details-v2/app-details-v2.component';
import { AppHmdaComponent } from './components/app-hmda/app-hmda.component';
import { AppTasksComponent } from './components/app-tasks/app-tasks.component';
import { AtrQmManagementModule } from './components/atr-qm-management/atr-qm-management.module';
import { BenefitCalculatorsComponent } from './components/benefit-calculators/benefit-calculators.component';
import { CashOutCalculatorComponent } from './components/benefit-calculators/cash-out-calculator/cash-out-calculator.component';
import { DebtManagementCalculatorComponent } from './components/benefit-calculators/debt-management-calculator/debt-management-calculator.component';
import { HousingCalculatorComponent } from './components/benefit-calculators/housing-calculator/housing-calculator.component';
import { LowerPaymentCalculatorComponent } from './components/benefit-calculators/lower-payment-calculator/lower-payment-calculator.component';
import { ReduceTermCalculatorComponent } from './components/benefit-calculators/reduce-term-calculator/reduce-term-calculator.component';
import { RefiBenefitsCalculatorComponent } from './components/benefit-calculators/refi-benefits-calculator/refi-benefits-calculator.component';
import { CreateWireRequestAddress } from './components/create-wire-request/create-wire-request-address/create-wire-request-address.component';
import { CreateWireRequestBorrower } from './components/create-wire-request/create-wire-request-borrower/create-wire-request-borrower.component';
import { CreateWireRequestCoBorrower } from './components/create-wire-request/create-wire-request-coborrower/create-wire-request-coborrower.component';
import { CreateWireRequestInfo } from './components/create-wire-request/create-wire-request-info/create-wire-request-info.component';
import { CreateWireRequestLoan } from './components/create-wire-request/create-wire-request-loan/create-wire-request-loan.component';
import { CreateWireRequestDrawer } from './components/create-wire-request/create-wire-request.component';
import { AssetAndCashFlowComponent } from './components/deal-structure/asset-and-cash-flow/asset-and-cash-flow.component';
import { BorrowerIncomeComponent } from './components/deal-structure/borrower-income/borrower-income.component';
import { CashFlowAnalysisComponent } from './components/deal-structure/cash-flow-analysis/cash-flow-analysis.component';
import { CurrentMortgageInfoComponent } from './components/deal-structure/current-mortgage-info/current-mortgage-info.component';
import { DealStructureComponent } from './components/deal-structure/deal-structure.component';
import { EmploymentIncomeInDetailComponent } from './components/deal-structure/employment-income-in-detail/employment-income-in-detail.component';
import { IncomeAnalysisComponent } from './components/deal-structure/income-analysis/income-analysis.component';
import { LiabilitiesInDetailComponent } from './components/deal-structure/liabilities-in-detail/liabilities-in-detail.component';
import { LoanBorrowerInfoComponent } from './components/deal-structure/loan-borrower-info/loan-borrower-info.component';
import { LoanLiabilitiesDialogComponent } from './components/deal-structure/loan-liabilities-dialog/loan-liabilities-dialog.component';
import { LoanLiabilitiesComponent } from './components/deal-structure/loan-liabilities/loan-liabilities.component';
import { LoanReferralInfoComponent } from './components/deal-structure/loan-referral-info/loan-referral-info.component';
import { OtherIncomeInDetailComponent } from './components/deal-structure/other-income-in-detail/other-income-in-detail.component';
import { ProductAndPricingComponent } from './components/deal-structure/product-and-pricing/product-and-pricing.component';
import { PropertyInfoComponent } from './components/deal-structure/property-info/property-info.component';
import { ProposedMortgageInfoComponent } from './components/deal-structure/proposed-mortgage-info/proposed-mortgage-info.component';
import { DebtIncomeSummaryComponent } from './components/debt-income-summary/debt-income-summary.component';
import { ChangeLoanPurposeDialogComponent } from './components/loan-info/loan-details/change-loan-purpose-dialog/change-loan-purpose-dialog.component';
import { LenderInfoDialogComponent } from './components/loan-info/loan-details/lender-info-dialog/lender-info-dialog.component';
import { LoanStatusHistoryDialogComponent } from './components/loan-status-history-dialog/loan-status-history-dialog.component';
import { PipelineStatusCardComponent } from './components/pipeline-status-card/pipeline-status-card.component';
import { PricingDetailsCardComponent } from './components/pricing-details-card/pricing-details-card.component';
import { TitleHistoryDetailComponent } from './components/title-history/title-history-detail/title-history-detail.component';
import { TitleHistoryComponent } from './components/title-history/title-history.component';
import { DocumentPreparationModule } from './document-preparation/document-preparation.module';
import { CharacteristicsModule } from './modules/characteristics/characteristics.module';
import { IdentifyingDocumentsModule } from './modules/identifying-documents/identifying-documents.module';
import { QuickApplyModule } from './modules/quick-apply/quick-apply.module';
import { AppDetailsService } from './services/app-details.service';
import { WireRequestService } from './services/wire-request.service';
import { RunDisclosuresModule } from '../loan-services/run-disclosures/run-disclosures.module';
import { MortgageCtoFeeWizardModule } from '../fees/mortgage-cto-fee-wizard/mortgage-cto-fee-wizard.module';
import { LoanDocsV2Module } from '../loan-docs-v2/loan-docs-v2.module';
import { LastAttemptedContactDialogComponent } from './components/last-attempted-contact-dialog/last-attempted-contact-dialog.component';
import { CustomDataInfoModule } from '../admin/custom-data-info/custom-data-info.module';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

@NgModule({
  declarations: [
    AppDetailsV2Component,
    AppDetailsMenuComponent,
    AppTasksComponent,
    LoanStatusHistoryDialogComponent,
    DealStructureComponent,
    ChangeLoanPurposeDialogComponent,
    LenderInfoDialogComponent,
    CopyApplicationDialogComponent,
    CopyApplicationAccordionComponent,
    AdminLoanSummaryComponent,
    AdminLoanSummaryTopBarComponent,
    PipelineStatusCardComponent,
    DebtIncomeSummaryComponent,
    LoanSummaryMoreMenuComponent,
    PricingDetailsCardComponent,
    TitleHistoryComponent,
    TitleHistoryDetailComponent,
    LoanBorrowerInfoComponent,
    LoanReferralInfoComponent,
    LoanLiabilitiesComponent,
    LiabilitiesInDetailComponent,
    PropertyInfoComponent,
    CurrentMortgageInfoComponent,
    ProposedMortgageInfoComponent,
    ProductAndPricingComponent,
    BorrowerIncomeComponent,
    CashFlowAnalysisComponent,
    IncomeAnalysisComponent,
    AssetAndCashFlowComponent,
    LoanLiabilitiesDialogComponent,
    BenefitCalculatorsComponent,
    LowerPaymentCalculatorComponent,
    DebtManagementCalculatorComponent,
    CashOutCalculatorComponent,
    ReduceTermCalculatorComponent,
    EmploymentIncomeInDetailComponent,
    OtherIncomeInDetailComponent,
    HousingCalculatorComponent,
    RefiBenefitsCalculatorComponent,
    AddressDetailViewDialogComponent,
    AppChecklistsComponent,
    LoanSummarySolarComponent,
    LoanSummaryMortgageComponent,
    LoanSummaryHEAComponent,
    AppHmdaComponent,
    AnnualUsageDialogComponent,
    CreateWireRequestDrawer,
    CreateWireRequestBorrower,
    CreateWireRequestCoBorrower,
    CreateWireRequestAddress,
    CreateWireRequestInfo,
    CreateWireRequestLoan,
    LastAttemptedContactDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppDetailsRoutingModule,
    TasksModule,
    TableModule,
    EventsModule,
    CorrespondenceModule,
    BorrowerModule,
    EsignatureModule,
    DisclosureTrackingModule,
    RunDisclosuresModule,
    LoanActivityModule,
    ButtonModule,
    PopoverModule.forRoot(),
    NgCircleProgressModule.forRoot({
      outerStrokeGradient: true,
      outerStrokeWidth: 5,
      innerStrokeWidth: 5,
      title: "",
      titleFontSize: '25',
      subtitleFontWeight: '500',
      animation: true,
      animateTitle: false,
      animationDuration: 1000,
      showUnits: false,
      showBackground: false,
      clockwise: true,
      startFromZero: true
    }),
    NgWizardModule.forRoot(ngWizardConfig),
    NgxPrintElementModule,
    McrDataAuditModule,
    KeyDatesModule,
    AgentInfoModule,
    ReferralSourceModule,
    NgSelectModule,
    LenderConfigModule,
    LosInfoModule,
    LdeInfoModule,
    UrlaModule,
    CreditModule,
    PricingModule,
    AusModule,
    DropdownModule,
    LoanDocsModule,
    LoanDocsV2Module,
    QuickApplyModule,
    AtrQmManagementModule,
    DocumentPreparationModule,
    CustomDataInfoModule,
    CharacteristicsModule,
    LoanSummaryBarModule,
    LoanCreditModule,
    FeesModule,
    IdentifyingDocumentsModule,
    MortgageCtoFeeWizardModule
  ],
  exports: [
    DealStructureComponent,
    AdminLoanSummaryComponent,
    AdminLoanSummaryTopBarComponent,
    DebtIncomeSummaryComponent,
    LoanSummaryMoreMenuComponent,
    LoanSummaryMortgageComponent
  ],
  providers: [AppDetailsService, LenderConfigService, WireRequestService],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppDetailsModule { }
