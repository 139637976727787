import { Component, Input, OnInit } from '@angular/core';
import { DefinedEmailTemplate, EmailServiceModel } from '../models';
import { SystemLevel, ThirdPartyCredential, ThirdPartyCredentialType } from '../../../models';
import { Configuration } from '../../../models/configuration.model';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { DrawerOptions, DrawerSize } from 'src/app/shared/services/drawer.service';
import { StripoEmailEditorComponent } from 'src/app/shared/components/stripo-email-editor/stripo-email-editor.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { BranchSettings } from '../../admin/company/models/branch.model';

@Component({
  selector: 'email-configuration',
  templateUrl: './email-configuration.component.html'
})
export class EmailConfigurationComponent implements OnInit {

  @Input()
  emailService: EmailServiceModel;

  @Input()
  definedEmailTemplate: DefinedEmailTemplate;

  // branch level
  @Input()
  microsoftGraph: ThirdPartyCredential;

  @Input()
  set branchSettings(settings: BranchSettings) {
    this._branchSettings = settings;
    this.definedEmailTemplate = settings.definedEmailTemplate;
  }

  get branchSettings(): BranchSettings {
    return this._branchSettings;
  }

  @Input()
  type: 'branch' | 'system-level';

  allowEmailImpersonation?: Configuration;

  restrictedUserEmailTemplateOverrides?: Configuration;

  reminderEmailEnabled: Configuration;

  reminderEmailInterval: Configuration;

  companyId: number;

  amazonWebServices: ThirdPartyCredential;

  loadingSystemData: boolean;

  currentTab: string = 'email-settings';

  editTemplateDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXXLarge,
    containerWrapperId: null
  };

  private _branchSettings: BranchSettings;

  constructor(private readonly _systemLevelService: SystemLevelService,
    private _spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    if (this.type === 'branch') {
      return;
    }
    if (!this.type) {
      this.type = 'system-level';
    }
    this.getSystemLevel();
  }

  private getSystemLevel = () => {
    if (this.type === 'branch') {
      return;
    }
    this.loadingSystemData = true;
    this._spinner.show();
    this._systemLevelService.getSystemLevel()
      .subscribe({
        next: (data: SystemLevel) => {
          const systemLevel = JSON.parse(JSON.stringify(data));
          this.companyId = systemLevel.company.companyId;
          this.emailService = systemLevel.emailService || {};
          this.definedEmailTemplate = systemLevel.definedEmailTemplate || {};
          this.allowEmailImpersonation = systemLevel.allowEmailImpersonation || {};
          this.restrictedUserEmailTemplateOverrides = systemLevel.restrictedUserEmailTemplateOverrides || {};
          this.reminderEmailEnabled = systemLevel.reminderEmailEnabled || {};
          this.reminderEmailInterval = systemLevel.reminderEmailInterval || {};
          this.microsoftGraph = systemLevel.thirdPartyCredentials.find(el => el.vendorName === 'MicrosoftGraph');
          this.amazonWebServices = systemLevel.thirdPartyCredentials.find(el => el.vendorName === 'AmazonWebServices')
            || new ThirdPartyCredential(ThirdPartyCredentialType.CloudPlatform, 'AmazonWebServices');
        }
      }).add(() => {
        this.loadingSystemData = false;
        this._spinner.hide();
      });
  }
}
