<qa-page-body>
  <ng-container slot='header'>
    VA Loan Info
  </ng-container>

  <ng-container slot='actions'>
    <save-button
      (onClick)='save()'
      [isDisabled]='isSaving'
      [saving]='isSaving'
    ></save-button>
  </ng-container>

  <ng-container slot='body'>
    <ul class='nav nav-tabs' role='tablist'>
      <li class='nav-item' *ngFor='let tab of tabs'>
        <a [ngClass]='{active: tab === activeTab}' class='nav-link'
           data-bs-toggle='tab' href='#{{tab.route}}' role='tab'
           [attr.aria-selected]='tab === activeTab'
        >
          {{tab.title}}
        </a>
      </li>
    </ul>

    <div class='tab-content' #tabContent>
      <div class='tab-pane fade show active' role='tabpanel'
           *ngFor='let tab of tabs' [ngClass]='{active: tab === activeTab}'
           id='{{tab.route}}'
      >
        <ng-container *ngIf='isInitialized; else loadingTemplate'>
          <ng-container *ngTemplateOutlet='tab.template'></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</qa-page-body>

<ng-template #loadingTemplate>
  <loading-indicator [loadingMessage]="'Loading, please wait...'">
  </loading-indicator>
</ng-template>

<ng-template #incomeDetailsTabTemplate>
  <qa-va-income-details
    [formGroup]='formGroup'
    [formAdapter]='formAdapter'
    [mortgage]='mortgage'
  ></qa-va-income-details>
</ng-template>

<ng-template #disclosuresInfoTabTemplate>
  <qa-va-disclosures-info
    [formGroup]='formGroup'
    [formAdapter]='formAdapter'
    [mortgage]='mortgage'
  ></qa-va-disclosures-info>
</ng-template>
