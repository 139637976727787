<div class="row align-items-center justify-content-between m-0 px-0 py-2">
  <div class="col-sm-8">
    <span class="text-nowrap header-font-size">
      <span class="fw-bold">Monthly Liabilities:
        <span class="fw-bold text-danger ms-1">{{
          financialMonthlyPaymentSubTotal | currency
          }}</span>
      </span>
      <span class="vertical-divider">
        <span></span>
      </span>
    </span>
    <span class="text-nowrap" style="color: #a4abc5;">Paid Off:
      <span class="fw-bold text-dark ms-1">{{
        financialPartialPayoffAmount | currency
        }}</span>
      <span class="ms-2" placement="bottom" ngbTooltip="Payoff Amount">
        <i class="fa fa-info-circle text-secondary"></i> </span>
      <span class="vertical-divider">
        <span></span>
      </span>
    </span>
    <span class="text-nowrap" style="color: #a4abc5;">Omitted:
      <span class="fw-bold text-dark ms-1">{{
        omittedAmount | currency
        }}</span>
      <span class="vertical-divider">
        <span></span>
      </span>
    </span>
    <span class="text-nowrap" style="color: #a4abc5;">Total:
      <span class="fw-bold text-dark ms-1">{{
        financialUnpaidBalanceSubTotal | currency
        }}</span></span>
  </div>

  <div class="col-sm-4 d-flex justify-content-end align-items-end pe-0">
    <div class="form-check">
      <input id="doesNotApply" class="form-check-input" type="checkbox" name="doesNotApply"
        [(ngModel)]="doesNotApply" />
      <label class="form-check-label text-dark" for="doesNotApply">
        Does not apply
      </label>
    </div>
    <button class="btn btn-sm btn-soft-danger ms-2" *ngIf="haveMultipleRowSelected()" (click)="bulkDeleteClicked()">
      <i class="fa fa-trash-alt me-1"></i> Delete Selected
    </button>
  </div>
</div>
<div class="qa-table-header-border"> </div>
<div class="table-responsive">
  <table class="table table-hover">
    <tbody>
      <ng-container *ngFor="let liability of liabilities">
        <tr (mouseenter)="hoveredItems[liability.liabilityId] = true"
          (mouseleave)="hoveredItems[liability.liabilityId] = false">
          <td style="width: 20%">
            <span class="d-inline-flex align-items-center">
              <span class="me-2" style="width: 15px">
                <div class="form-check" *ngIf="
                    hoveredItems[liability.liabilityId] ||
                    selectedRows[liability.liabilityId]
                  ">
                  <input id="selectedRows-{{ liability.liabilityId }}" class="form-check-input" type="checkbox"
                    name="selectedRows-{{ liability.liabilityId }}" [(ngModel)]="selectedRows[liability.liabilityId]" />
                </div>
              </span>
              <i (click)="
                  toggleExpandLiability(liability.liabilityId);
                  existingItems[liability.liabilityId] = true
                " class="me-2 cursor {{
                  expandedItems[liability.liabilityId]
                    ? 'fa fa-chevron-down'
                    : 'fa fa-chevron-right'
                }}"></i>
              <span class="d-inline-flex align-items-center">
                <select-input [(ngModel)]="liability.typeOfLiability"
                  (ngModelChange)="accountTypeOrBorrowerChange(liability)" [optionValueKey]="'value'"
                  [optionNameKey]="'name'" [options]="allLiabilityTypes" [groupingEnabled]="true"
                  name="liabilityTypes-{{ liability.liabilityId }}" [editorMode]="'Inline'"
                  [placeholder]="'Account Type'" [disabled]="expandedItems[liability.liabilityId]"></select-input>
              </span>
            </span>
          </td>
          <td style="width: 20%">
            <span class="d-inline-flex align-items-center">
              <label class="me-1">of</label>
              <select-input [editorMode]="'Inline'" [isMultiple]="true" [required]="true" [optionValueKey]="'id'"
                [optionNameKey]="'text'" [placeholder]="'Account Owner'" [(ngModel)]="liability.owningBorrowerIds"
                (ngModelchange)="onAccountOwnersChanged(liability)" [options]="possibleAccountOwners"
                name="owningBorrowerIds{{ liability.liabilityId }}"
                [disabled]="expandedItems[liability.liabilityId]"></select-input>
            </span>
          </td>
          <td style="width: 20%">
            <span class="d-inline-flex align-items-center">
              <label class="me-1">to</label>
              <text-input [(ngModel)]="liability.holderName" name="holderName{{ liability.liabilityId }}"
                [editorMode]="'Inline'" [required]="true" [placeholder]="'Holder Name'"
                [disabled]="expandedItems[liability.liabilityId]"></text-input>

              <a href="javascript: void(0);" class="ms-3" data-bs-container="body" data-bs-toggle="popover"
                placement="bottom" #multiSelectPopover="bs-popover" [popover]="templateOmitOptions" data-trigger="click"
                [outsideClick]="true" [containerClass]="'templateOmitOptions'"
                *ngIf="!expandedItems[liability.liabilityId]">
                <i class="far fa-caret-square-down"></i>
              </a>
              <ng-template #templateOmitOptions>
                <div class="w-100">
                  <div class="form-check">
                    <label class="form-check-label text-dark" for="omittedOption-{{ liability.liabilityId }}">
                      Omitted
                    </label>
                    <input id="omittedOption-{{ liability.liabilityId }}" class="form-check-input" type="checkbox"
                      name="omittedOption-{{ liability.liabilityId }}" [(ngModel)]="liability.isExcluded"
                      (ngModelChange)="excludeChanged()" [disabled]="expandedItems[liability.liabilityId]" />
                  </div>
                </div>
              </ng-template>
            </span>
          </td>
          <td class="text-end" style="width: 20%">
            <span class="d-inline-flex align-items-center" [ngClass]="{'omitted-amount': liability.isExcluded, 'paid-off': !liability.isExcluded && ((liability.payoffType == 'Full' && liability.unpaidBalance > 0))}">
              <currency-input [(ngModel)]="liability.unpaidBalance" (blur)="calculateSubTotals()"
                name="unpaidBalance-{{ liability.liabilityId }}" [editorMode]="'Inline'"
                [placeholder]="'Unpaid Balance'" [disabled]="expandedItems[liability.liabilityId] || liability.isExcluded"></currency-input>
              <label class="ps-1">Balance</label>
            </span>
            <span *ngIf="liability.isExcluded" class="ms-2 text-danger"> <i class="fa fa-ban"></i> Omitted</span>
            <span *ngIf="!liability.isExcluded && ((liability.payoffType == 'Full' && liability.unpaidBalance > 0))" class="ms-2 text-success"> <i class="fa fa-check"></i> Paid off</span>
          </td>
          <td class="text-end" style="width: 20%">
            <span class="d-inline-flex align-items-center" [ngClass]="{'omitted-amount': liability.isExcluded, 'paid-off': !liability.isExcluded && ((liability.payoffType == 'Full' && liability.unpaidBalance > 0))}">
              <currency-input [(ngModel)]="liability.monthlyPayment" (blur)="calculateSubTotals()"
                name="monthlyPayment-{{ liability.liabilityId }}" [editorMode]="'Inline'"
                [placeholder]="'Monthly Payment'" [disabled]="expandedItems[liability.liabilityId] || liability.isExcluded"></currency-input>
              <label class="ms-1 me-3 text-nowrap">/ mo</label>

              <a href="javascript: void(0);" data-bs-toggle="dropdown" aria-expanded="false" class="dropdown-toggle"
                *ngIf="liability.unpaidBalance && !expandedItems[liability.liabilityId]" (click)="setMonthlyPaymentOptions(liability)">
                <i class="far fa-caret-square-down"></i>
              </a>
              <div class="dropdown-menu">
                <div class="dropdown-submenu">
                  <a class="dropdown-item dropdown-toggle w-100">
                    Set Payoff
                    <i class="fas fa-caret-right ps-1 pull-right"></i>
                  </a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item w-100" role="button" (click)="setPayoffAmountToFull(liability)">Full</a>
                    <a class="dropdown-item w-100" role="button" (click)="openPayoffDialog(liability)">Partial</a>
                    <a class="dropdown-item w-100" role="button" (click)="setPayoffAmountToNone(liability)">None</a>
                  </div>
                </div>
                <div class="dropdown-submenu">
                  <a class="dropdown-item dropdown-toggle w-100">
                    Monthly Payment
                    <i class="fas fa-caret-right ps-1 pull-right"></i>
                  </a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item w-100" role="button"
                      *ngFor="let option of monthlyPaymentOptions"
                      (click)="setMonthlyPayment(liability, option.value)">
                      {{ option.displayText }}
                    </a>
                  </div>
                </div>
              </div>
            </span>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            <expansion-panel
              [content]='liabilityEditorTemplate'
              [isExpanded]='expandedItems[liability.liabilityId]'
            ></expansion-panel>

            <ng-template #liabilityEditorTemplate>
              <qa-fi-liability-editor
                (cancel)='onClickCancelLiability(liability.liabilityId)'
                (close)='onClickCloseLiability(liability.liabilityId)'
                (update)='onChangeLiability($event)'
                [liabilityTypes]='liabilityTypes'
                [liability]='liability'
                [lienPositionTypes]='lienPositionTypes'
                [mode]='existingItems[liability.liabilityId] ? "edit" : "create"'
                [mortgageAppliedForTypes]='mortgageAppliedForTypes'
                [otherLiabilityTypes]='otherLiabilityTypes'
                [possibleAccountOwners]='possibleAccountOwners'
                [possibleReoAddresses]='possibleReoAddresses[liabilities.indexOf(liability)]'
                [realEstateOwned]='mortgage.realEstateOwned'
                [states]='states'></qa-fi-liability-editor>
            </ng-template>
          </td>
        </tr>
      </ng-container>

      <tr *ngIf='!isCreatingItem'>
        <td colspan="6">
          <button type="button" class="btn btn-sm btn-soft-primary" (click)="addLiabilityClicked()">
            <i class="fas fa-plus me-2"></i> Liability
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
