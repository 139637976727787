<form #balloonDetailsForm="ngForm" novalidate id="balloonDetailsForm"
    name="balloonDetailsForm" class="p-3">
    <div class="row mb-3">
        <div class="form-group col-md-6 mb-0">
            <label class="mb-2" for="balloonTermMonths">Balloon Term</label>
            <input class="form-control"
                numeric [allowNegative]="false"
                [(ngModel)]='extension.balloonTermMonths'
                [required]='isRequired("mortgage.extension.balloonTermMonths")'
                name='balloonTermMonths' [readonly]="readonly"/>
        </div>
        <div class="form-group col-md-6 mb-0">
            <label class="mb-2" for="balloonPaymentAmount">Balloon Payment Amount</label>
            <currency-input
                [name]="'balloonPaymentAmount'"
                id="balloonPaymentAmount"
                [(ngModel)]="extension.balloonPaymentAmount"
                [required]='isRequired("mortgage.extension.balloonPaymentAmount")'
                [readonly]="readonly">
            </currency-input>
        </div>
    </div>
    <div class="row mb-3" *ngIf="hasSaveCancelButtons && !readonly">
        <div class="col-md-12 text-end">
            <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
            <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
                Save
            </button>
        </div>
    </div>
</form>