<div class="grid-layout has-border">
    <div class="grid-header mb-1">
        <div class="grid-header-left">
            <div>4a. Loan & Property Info</div>
        </div>
        <div class="grid-header-right">
        </div>
    </div>
    <div class="grid-layout">
        <div class="grid-layout-row">
            <urla-currency-input class="col-md-3 col-xs-8" [isZeroValid]="false" [name]="'loanAmount'"
              [allowEmpty]="true"
              [allowDecimals]="false"
              (change)="loanAmountChange()" [label]="'Loan Amount'"
              [(ngModel)]="mortgage.mortgageTerm.amount" [readonly]="isReadOnly && !(isTpoUser && isTBDChecked)"
              [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.mortgageTerm.amount'"
              [required]="urlaFieldsConfig['mortgage.mortgageTerm.amount']?.required == 'required' ||
              urlaFieldsConfig['mortgage.mortgageTerm.amount']?.required == 'requested'"
              [hasCalculator]="true"
              [isEditableEvenIfItHasACalculator]="true"
              (calculatorClicked)="onLoanAmountCalculatorClicked()">
            </urla-currency-input>
            <urla-dropdown class="col-md-3 col-xs-4" 
                [name]="'loanPurpose'" 
                [label]="'Purpose of Loan'" 
                [options]="loanPurposes"
                [(ngModel)]="purposeOfLoan" 
                (change)="onPurposeOfLoanChange()"
                [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.purposeOfLoan'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.purposeOfLoan']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.purposeOfLoan']?.required == 'requested'">
            </urla-dropdown>
            <div *ngIf="isRefi || inEditMode" class="col-md-3 col-xs-8">
                <urla-dropdown [label]="'Type of Refinance'" [name]="'refiPurpose'" [options]="refinancePurposes"
                    [(ngModel)]="mortgage.subjectProperty.refiPurpose" (change)="onPurposeOfRefinanceChanged()"
                    [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.refiPurpose'"
                    [required]="urlaFieldsConfig['mortgage.subjectProperty.refiPurpose']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.subjectProperty.refiPurpose']?.required == 'requested'">
                </urla-dropdown>
            </div>
            <div *ngIf="isRefi || inEditMode" class="col-md-3 col-xs-8">
                <urla-dropdown [label]="'Purpose of Refinance'" [name]="'refinancePrimaryPurpose'" [options]="refinancePrimaryPurposes"
                    [(ngModel)]="mortgage.subjectProperty.refinancePrimaryPurpose" (change)="onPurposeOfRefinanceChanged()"
                    [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.refinancePrimaryPurpose'"
                    [required]="urlaFieldsConfig['mortgage.subjectProperty.refinancePrimaryPurpose']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.subjectProperty.refinancePrimaryPurpose']?.required == 'requested'">
                </urla-dropdown>
            </div>
            <urla-currency-input #sourceOfFundsValue *ngIf="isPurchase && !inEditMode" class="col-md-3 col-xs-8"
                [name]="'sourceOfFunds'" [label]="'Purchase Credits'" [hasCalculator]="true" [readonly]="isReadOnly"
                [(ngModel)]="mortgage.calculatedStats.sourceOfFunds" (change)="onSourceOfFundsChanged()">
            </urla-currency-input>
            <urla-dropdown class="col-md-3 col-xs-4" [name]="'occupancy'" [label]="'Occupancy'" [options]="propertyTypes"
                [(ngModel)]="mortgage.subjectProperty.propertyWillBe" [readonly]="isReadOnly || isEntityTypeLLC"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.propertyWillBe'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.propertyWillBe']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.propertyWillBe']?.required == 'requested'">
            </urla-dropdown>

            <div *ngIf="isRefi && !inEditMode" class="grid-layout-spacer col-md-9 col-xs-8">&nbsp;</div>
            <div *ngIf="!isRefi && !isPurchase && !inEditMode" class="grid-layout-spacer col-md-3 col-xs-4">&nbsp;</div>
        </div>
        <div class="grid-layout-row">
            <urla-street-address #streetAddress *ngIf="!isSubjectPropertyAddressHidden || inEditMode"
              class="col-md-3 col-xs-12"
              [label]="'Subject Property Addr.'"
              [(ngModel)]="mortgage.subjectProperty.address1"
              [name]="'address'"
              [zipcodeLookupEnabled]="true"
              [readonly]="isReadOnly && !(isTpoUser && isTBDChecked)"
              [hasTBDCheckbox]="!hasApplicationReceivedKeyDate && !isRefi"
              [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.address1'"
              [required]="!hasTBDCheckbox && (urlaFieldsConfig['mortgage.subjectProperty.address1']?.required == 'required' ||
              urlaFieldsConfig['mortgage.subjectProperty.address1']?.required == 'requested')"
              (addressChanged)="onSubjectPropertyStreetAddressChanged($event)"
              (checkboxChanged)="onSubjectPropertyCheckboxChanged($event)"
              [disabled]="hasTBDCheckbox">
            </urla-street-address>
            <urla-text-input class="col-md-2 col-xs-4" label="Unit #" [(ngModel)]="mortgage.subjectProperty.address2"
                [name]="'unitNo'" [readonly]="isReadOnly && !(isTpoUser && isTBDChecked)" [pattern]="alphaNumericPattern"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.address2'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.address2']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.address2']?.required == 'requested'">
            </urla-text-input>
            <urla-text-input class="col-md-2 col-xs-4" [name]="'city'" [label]="'City'"
                [(ngModel)]="mortgage.subjectProperty.city" [readonly]="isReadOnly && !(isTpoUser && isTBDChecked)"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.city'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.city']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.city']?.required == 'requested'">
            </urla-text-input>
            <urla-dropdown class="col-md-1 col-xs-2" [readonly]="isReadOnly && !(isTpoUser && isTBDChecked)"
                [name]="'state'" [label]="'State'" [options]="states" [(ngModel)]="mortgage.subjectProperty.state"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.state'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.state']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.state']?.required == 'requested'">
            </urla-dropdown>
            <urla-digits-input class="col-md-2 col-xs-2" label="Zip Code" [(ngModel)]="mortgage.subjectProperty.zipCode"
                [name]="'zipCode'" [readonly]="isReadOnly && !(isTpoUser && isTBDChecked)" (blur)="onZipcodeChanged()"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.zipCode'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.zipCode']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.zipCode']?.required == 'requested'">
            </urla-digits-input>
            <urla-text-input class="col-md-2 col-xs-6" [name]="'county'" [label]="'County'"
                [(ngModel)]="mortgage.subjectProperty.county" [readonly]="isReadOnly && !(isTpoUser && isTBDChecked)"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.county'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.county']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.county']?.required == 'requested'">
            </urla-text-input>
        </div>
        <div class="grid-layout-row">
            <urla-digits-input class="col-md-3 col-xs-6" label="Number Of Units" [max]="10" [min]="1"
                [(ngModel)]="mortgage.subjectProperty.noOfUnits" [name]="'noOfUnits'"
                [readonly]="isReadOnly && !(isTpoUser && isTBDChecked)"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.noOfUnits'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.noOfUnits']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.noOfUnits']?.required == 'requested'">
            </urla-digits-input>
            <urla-dropdown class="col-md-3 col-xs-6"
                [name]="'constructionMethod'"
                [label]="'Construction Method'"
                [options]="propertyConstructionMethods"
                [(ngModel)]="mortgage.subjectProperty.constructionMethod"
                [readonly]="isReadOnly"
                [inEditMode]="inEditMode"
                (ngModelChange)="onConstructionMethodChanged()"
                [hasEditButton]="mortgage.subjectProperty.constructionMethod == mhAdvantageEnumValue ||
                mortgage.subjectProperty.constructionMethod == manufacturedEnumValue "
                (editClickEvent)="onEditManufacturedHomeDetailsClicked()"
                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.constructionMethod'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.constructionMethod']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.constructionMethod']?.required == 'requested'">
            </urla-dropdown>
            <urla-text-input *ngIf="mortgage.subjectProperty.constructionMethod == 'Other'" class="col-md-3 col-xs-6" [name]="'constructionMethodOtherDescription'" [label]="'Const. Method Other Descr.'"
                [(ngModel)]="mortgage.subjectProperty.constructionMethodOtherDescription" [readonly]="isReadOnly"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.constructionMethodOtherDescription'"
                [required]="(urlaFieldsConfig['mortgage.subjectProperty.constructionMethodOtherDescription']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.constructionMethodOtherDescription']?.required == 'requested') && mortgage.subjectProperty.constructionMethod == 'Other'">
            </urla-text-input>
            <urla-dropdown *ngIf="isConstruction" class="col-md-3 col-xs-6" [name]="'constructionStatus'" [label]="'Construction Status'"
                [options]="propertyConstructionStatuses" [(ngModel)]="mortgage.subjectProperty.constructionStatus"
                [readonly]="isReadOnly" [inEditMode]="inEditMode"
                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.constructionStatus'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.constructionStatus']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.constructionStatus']?.required == 'requested'">
            </urla-dropdown>
            <urla-radio-group *ngIf="isFha" class="col-md-3 col-xs-6" label="FHA Secondary Residence"
                [(ngModel)]="mortgage.subjectProperty.isFhaSecondaryResidence" [readonly]="isReadOnly"
                [options]="yesNoOptions" [name]="'isFhaSecondaryResidence'" [layout]="'horizontal'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence']?.required == 'requested'"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.isFhaSecondaryResidence'">
            </urla-radio-group>
            <div *ngIf='!isFha'
              class='grid-layout-spacer col-md-3 col-xs-6'
            ></div>
            <div *ngIf='!isConstruction'
              class='grid-layout-spacer col-md-3 col-xs-6'
            ></div>
        </div>
        <div class="grid-layout-row">
            <urla-currency-input *ngIf="isPurchase"
                [isZeroValid]="false"
                class="col-md-3 col-xs-7"
                [name]="'purchasePrice'"
                (change)="onPurchasePriceChanged();"
                [label]="'Purchase Price'"
                [(ngModel)]="mortgage.transactionDetail.purchasePriceAmount"
                [readonly]="isReadOnly && !(isTpoUser && isTBDChecked)"
                [inEditMode]="inEditMode"
                [urlaFieldsConfig]="urlaFieldsConfig"
                [fieldBeingEdited]="'mortgage.transactionDetail.purchasePriceAmount'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.presentValue']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.presentValue']?.required == 'requested'">
            </urla-currency-input>
            <urla-currency-input *ngIf="!isPurchase"
                [isZeroValid]="false"
                class="col-md-3 col-xs-7"
                [name]="'estimatedValue'"
                (change)="onAppraisedValueChange(); onPresentValueChanged();"
                [label]="'Estimated Value'"
                [(ngModel)]="mortgage.subjectProperty.presentValue"
                [readonly]="isReadOnly"
                [inEditMode]="inEditMode"
                [urlaFieldsConfig]="urlaFieldsConfig"
                [fieldBeingEdited]="'mortgage.subjectProperty.presentValue'"
                [required]="urlaFieldsConfig['mortgage.subjectProperty.presentValue']?.required == 'required' ||
                urlaFieldsConfig['mortgage.subjectProperty.presentValue']?.required == 'requested'">
            </urla-currency-input>
            <urla-currency-input *ngIf="!isAppraisedValueHidden || inEditMode"
                [isZeroValid]="false"
                class="col-md-3 col-xs-7"
                [name]="'appraisedValue'"
                (change)="onAppraisedValueChange()"
                [label]="'Appraised Value'"
                [(ngModel)]="mortgage.mortgageTerm.appraisedValue"
                [readonly]="isReadOnly"
                [customIconClass]="'fa fa-pencil-alt'"
                (customIconClicked)="onSubjectPropertyValuationClicked()"
                [inEditMode]="inEditMode"
                [urlaFieldsConfig]="urlaFieldsConfig"
                [fieldBeingEdited]="'mortgage.mortgageTerm.appraisedValue'"
                [required]="urlaFieldsConfig['mortgage.mortgageTerm.appraisedValue']?.required == 'required' ||
                urlaFieldsConfig['mortgage.mortgageTerm.appraisedValue']?.required == 'requested'">
            </urla-currency-input>
            <urla-grid-cell class="col-md-4 col-xs-4 grid-layout-text" [disabled]="isReadOnly"
              [label]="'If you will occupy the property, will you set aside space within the property to operate your own business? (e.g., daycare facility, medical office, beauty/barber shop)'">
            </urla-grid-cell>
            <urla-radio-group class="col-md-2 col-xs-2" [(ngModel)]="mortgage.subjectProperty.isMixedUseProperty"
                [readonly]="isReadOnly" [options]="yesNoOptions" [name]="'isMixedUseProperty'" [layout]="'horizontal'"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.mortgageTerm.isMixedUseProperty'"
                [required]="urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty']?.required == 'required' ||
                urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty']?.required == 'requested'">
            </urla-radio-group>
        </div>
        <div class="grid-layout-row" *ngIf="['ConstructionOnly','ConstructionToPermanent'].includes(purposeOfLoan)">
          <urla-dropdown class="col-md-3 col-xs-4" [name]="'constructionLoanPurpose'" [label]="'Construction Loan Purpose'" [options]="constructionLoanPurposeEnums"
            [(ngModel)]="mortgage.subjectProperty.constructionLoanPurpose" [readonly]="isReadOnly"
            [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.constructionLoanPurpose'"
            [required]="urlaFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose']?.required == 'required' ||
            urlaFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose']?.required == 'requested'">
          </urla-dropdown>
          <urla-dropdown class="col-md-3 col-xs-4" [name]="'constructionToPermanentClosingType'" [label]="'Construction To Permanent Closing Type'" [options]="constructionToPermanentClosingTypeEnums"
            [(ngModel)]="mortgage.subjectProperty.constructionToPermanentClosingType" [readonly]="isReadOnly"
            [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.subjectProperty.constructionToPermanentClosingType'"
            [required]="urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == 'required' ||
            urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == 'requested'">
          </urla-dropdown>
          <div class="grid-layout-spacer col-md-6 col-xs-4">&nbsp;</div>
        </div>
    </div>

    <div class="borrower-container border-0" *ngIf="isSalesPriceExceedsAppraisedValue()">
        <ul class="nav nav-tabs d-flex" role="tablist" id="borrower-tabs" style="border-bottom: 1px solid #d5d9d9;">
          <li *ngFor="let borrowers of borrowerGroups" class="nav-item borrower-group group-button-list me-2">
            <ng-container *ngFor="let borrower of borrowers; let i = index; let last = last">
              <a class="nav-link" data-bs-toggle="tab" role="tab"
               (click)="onBorrowerSelected(borrower, true)" aria-selected="true"
               [ngClass]="{ active: isSelectedBorrower(borrower), 'pe-2': i % 2 == 0 && !last, 'ps-2': i % 2 == 1 }">
                <span>
                  {{getBorrowerDisplayName(borrower)}}
                </span>
              </a>
              <span *ngIf="!last" class="vertical-divider">
                <span></span>
              </span>
            </ng-container>
          </li>
        </ul>

        <div class="tab-content">
          <div *ngFor="let borrower of mortgage.borrowers | sort : 'isPrimary'" [hidden]="!isSelectedBorrower(borrower)" class="tab-pane"
            [ngClass]="{ active: isSelectedBorrower(borrower)}" role="tabpanel">

            <div class="grid-layout-row">
                <div>
                    <div class="btn-group w-100" role="group"
                    [ngClass]="{
                      'invalid-requested-border' : borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == undefined
                        && urlaFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType']?.required == 'requested',
                      'invalid-required-border' : borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == undefined
                        && urlaFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType']?.required == 'required'
                    }">
                      <ng-container *ngFor="let enum of appraisedValueTypeEnums">
                        <input type="radio" class="mx-2" name="{{borrower.borrowerId}}_{{enum.value}}"
                          id="{{borrower.borrowerId}}_{{enum.value}}" value="{{enum.value}}" autocomplete="off"
                          [(ngModel)]="borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType"
                          [required]="urlaFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType']?.required == 'required' ||
                            urlaFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType']?.required == 'requested'"
                           />
                        <label class="form-label m-2"
                          for="{{borrower.borrowerId}}_{{enum.value}}">{{enum.name}}</label>
                      </ng-container>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
</div>

<drawer [title]="'Details'" #dynamicDrawer [name]="'dynamicDrawerUrlaPropertyInfo'" [options]="dynamicDrawerOptions">
</drawer>
