import { Component, EventEmitter, Output } from '@angular/core';
import { RelatedMortgage } from '../../../../../models';
import { DrawerOptions, DrawerService, DrawerSize } from '../../../../../shared/services/drawer.service';

const defaultTitle = 'Related Mortgage Loan';

@Component({
  selector: 'related-mortgage-loan-editor-drawer',
  templateUrl: './related-mortgage-loan-editor-drawer.component.html',
  styleUrls: ['./related-mortgage-loan-editor-drawer.component.scss'],
})
export class RelatedMortgageLoanEditorDrawer {
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<RelatedMortgage> =
    new EventEmitter<RelatedMortgage>();

  protected readonly drawerName: string = 'onMortgageLoanEditorDrawer';
  protected readonly drawerOptions: DrawerOptions = Object.freeze({
    size: DrawerSize.XXLarge,
    containerWrapperId: null,
  });

  protected title: string = defaultTitle;
  protected value: RelatedMortgage | undefined;

  constructor(
    private readonly _drawerService: DrawerService,
  ) {
  }

  public async show(purpose: OnMortgageLoanEditorDrawerPurpose): Promise<void> {
    this.title = titleFromPurpose(purpose);
    this.value = purpose instanceof UpdateOnMortgageLoanPurpose
      ? purpose.value
      : undefined;

    await this._drawerService.show(this.drawerName);
  }

  public async hide(): Promise<void> {
    await this._drawerService.hide(this.drawerName);
  }

  protected async onCancel(): Promise<void> {
    this.cancel.next();
    await this.hide();
  }

  protected async onSave(value: RelatedMortgage): Promise<void> {
    this.save.next(value);
    await this.hide();
  }
}

export abstract class OnMortgageLoanEditorDrawerPurpose {
  protected constructor(readonly titlePrefix: string | null) {
  }
}

export class UpdateOnMortgageLoanPurpose extends OnMortgageLoanEditorDrawerPurpose {
  constructor(readonly value: RelatedMortgage) {
    super('Update');
  }
}

function titleFromPurpose(purpose: OnMortgageLoanEditorDrawerPurpose): string {
  const titlePrefix = purpose.titlePrefix;
  return titlePrefix
    ? `${titlePrefix} ${defaultTitle}`
    : defaultTitle;
}
