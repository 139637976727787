import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  ParsedLegalDescription,
  UnparsedLegalDescription,
  UnparsedLegalDescriptionTypeEnum,
  UnplattedLandTypeEnum,
} from '../../../../../../models';
import { getOrDefault, mapOf } from '../legal-description-utils';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'legal-descriptions-editor',
  templateUrl: './legal-descriptions-editor.component.html',
  styleUrls: ['./legal-descriptions-editor.component.scss'],
})
export class LegalDescriptionsEditorComponent implements OnInit, OnChanges {
  /**
   * The value to edit. It can be omitted to create a new value.
   */
  @Input() value?: LegalDescriptionEditorValue;

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<LegalDescriptionEditorValue>();

  protected formValue: LegalDescriptionEditorFormValue;

  ngOnInit(): void {
    this.formValue ??=
      new LegalDescriptionEditorFormValue(createDefaultEditorValue());
  }

  ngOnChanges(changes: SimpleChanges) {
    const valueChange = changes.value;
    if (valueChange != null) {
      const currentValue = valueChange.currentValue;
      const effectiveValue = currentValue
        ? cloneDeep(currentValue)
        : createDefaultEditorValue();
      this.formValue = new LegalDescriptionEditorFormValue(effectiveValue);
    }
  }

  protected onCancel(): void {
    this.cancel.emit();
  }

  protected onSave(): void {
    this.save.emit(this.formValue.toEditorValue());
  }

  protected addOtherUnparsedDescription(): void {
    this.formValue.unparsedDescriptions.other.push(new UnparsedLegalDescription(UnparsedLegalDescriptionTypeEnum.Other));
  }

  protected removeOtherUnparsedDescription(index: number): void {
    this.formValue.unparsedDescriptions.other.splice(index, 1);
  }

  protected addOtherParsedDescription(): void {
    this.formValue.parsedDescriptions.other.push(new ParsedLegalDescription(UnplattedLandTypeEnum.Other));
  }

  protected removeOtherParsedDescription(index: number): void {
    this.formValue.parsedDescriptions.other.splice(index, 1);
  }
}

export interface LegalDescriptionEditorValue {
  unparsedLegalDescriptions: UnparsedLegalDescription[];
  parsedLegalDescriptions: ParsedLegalDescription[];
}

function createDefaultEditorValue(): LegalDescriptionEditorValue {
  return {
    unparsedLegalDescriptions: [],
    parsedLegalDescriptions: [],
  };
}

/**
 * Helper class to convert between the editor value and the form value.
 */
class LegalDescriptionEditorFormValue {
  unparsedDescriptions: {
    full: UnparsedLegalDescription;
    abbreviated: UnparsedLegalDescription;
    other: UnparsedLegalDescription[];
  };

  parsedDescriptions: {
    governmentSurvey: ParsedLegalDescription;
    landGrant: ParsedLegalDescription;
    metesAndBounds: ParsedLegalDescription;
    nativeAmericanLand: ParsedLegalDescription;
    ranchero: ParsedLegalDescription;
    other: ParsedLegalDescription[];
  };

  /**
   * Maps each description type to a property on the form value.
   * @param {LegalDescriptionEditorValue} value The editor value to
   * convert.
   */
  constructor(value: LegalDescriptionEditorValue) {
    // Unparsed descriptions
    const unparsedDescriptionsByType = mapOf(value.unparsedLegalDescriptions)('legalDescriptionType');
    const getUnparsedDescriptionOrDefault = getOrDefault(unparsedDescriptionsByType)(UnparsedLegalDescription);
    this.unparsedDescriptions = {
      full: getUnparsedDescriptionOrDefault(UnparsedLegalDescriptionTypeEnum.Long),
      abbreviated: getUnparsedDescriptionOrDefault(UnparsedLegalDescriptionTypeEnum.Short),
      other: value.unparsedLegalDescriptions.filter(d => d.legalDescriptionType == UnparsedLegalDescriptionTypeEnum.Other) || [],
    };

    // Parsed descriptions
    const parsedDescriptionsByType = mapOf(value.parsedLegalDescriptions)('unplattedLandType');
    const getParsedDescriptionOrDefault = getOrDefault(parsedDescriptionsByType)(ParsedLegalDescription);
    this.parsedDescriptions = {
      governmentSurvey: getParsedDescriptionOrDefault(UnplattedLandTypeEnum.GovernmentSurvey),
      landGrant: getParsedDescriptionOrDefault(UnplattedLandTypeEnum.LandGrant),
      metesAndBounds: getParsedDescriptionOrDefault(UnplattedLandTypeEnum.MetesAndBounds),
      nativeAmericanLand: getParsedDescriptionOrDefault(UnplattedLandTypeEnum.NativeAmericanLand),
      ranchero: getParsedDescriptionOrDefault(UnplattedLandTypeEnum.Ranchero),
      other: value.parsedLegalDescriptions.filter(d => d.unplattedLandType == UnplattedLandTypeEnum.Other) || [],
    };
  }

  /**
   * Converts back the form value to the editor value. (The inverse of the
   * constructor.)
   */
  toEditorValue(): LegalDescriptionEditorValue {
    function isValid(value: string | undefined): boolean {
      return value != null && value.length > 0;
    }

    return {
      unparsedLegalDescriptions: [
        ...([
          this.unparsedDescriptions.full,
          this.unparsedDescriptions.abbreviated,
        ].filter(d => isValid(d.legalDescription))),
        ...(this.unparsedDescriptions.other.filter(d => isValid(d.legalDescriptionTypeOtherDescription))),
      ],
      parsedLegalDescriptions: [
        ...([
          this.parsedDescriptions.governmentSurvey,
          this.parsedDescriptions.landGrant,
          this.parsedDescriptions.metesAndBounds,
          this.parsedDescriptions.nativeAmericanLand,
          this.parsedDescriptions.ranchero,
        ].filter(d => isValid(d.unplattedLandSurveyTownshipIdentifier))),
        ...(this.parsedDescriptions.other.filter(d => isValid(d.unplattedLandTypeOtherDescription))),
      ],
    };
  }
}
