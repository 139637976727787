<ng-template [ngIf]="loading">
  <loading-indicator [loadingMessage]="'Loading conditions, please wait...'"></loading-indicator>
</ng-template>
<div *ngIf="!loading">
  <div class="alert alert-dismissible d-flex-row align-items-center mb-3"
    *ngIf="(resubmitResult) && isTpo"
    [ngClass]="{'alert-outline-success': resubmitResult.passed, 'alert-outline-danger': !resubmitResult.passed}"
    role="alert">
    <div *ngIf="resubmitResult.passed">
        <ng-template>
            <span>
                <strong>Success:</strong> Submission passed
            </span>
        </ng-template>
    </div>
    <div *ngIf="resubmitResult.errorMessage">
        <strong>Error:</strong> {{resubmitResult.errorMessage}}
    </div>

    <ng-container *ngIf="resubmitResult.validationResults && resubmitResult.validationResults.length">
        <div *ngFor="let validationResult of resubmitResult.validationResults">
            <div *ngIf="!validationResult.passed">
              <strong>Validation Error:</strong> {{validationResult.validationRule ? validationResult.validationRule : validationResult.errorMessage ? validationResult.errorMessage : '' }}
            </div>
        </div>
    </ng-container>

    <button type="button" class="btn-close" data-bs-dismiss="alert" (click)="closeErrors()" aria-label="Close"></button>
  </div>
  <div class="card" *ngIf="isTpo && isTpoSubmitted">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h4 class="p-component resubmit-toolbar-explanation">
        <i class="fas fa-exclamation-triangle me-2 m-auto"></i>
        Please upload conditions below and then click Resubmit Loan
      </h4>
      <button class="btn btn-primary" (click)="resubmitTpoLoan()">Resubmit Loan</button>
    </div>
  </div>
  <div *ngIf="isTpo">
    <conditions-table [borrowers]="borrowers" [appId]="appId"></conditions-table>
  </div>
  <admin-condition-table *ngIf="!isTpo" #conditionsTable [conditionsTableData]="conditionsTasks" [appId]="appId"
    [users]="users" [context]="context" (conditionsUpdated)="onConditionsUpdated()"></admin-condition-table>
</div>
