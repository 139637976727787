<div class="card-body">
    <div class="table-responsive">
        <table class="table mb-0 table-centered text-center">
            <thead class="table-light">
                <tr>
                    <th>On File</th>
                    <th>Borrower Name</th>
                    <td>Status</td>
                    <td>Report Date</td>
                    <td>Report Id</td>
                    <td>View Report</td>
                    <td>Import Assets</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let order of orderHistory">
                    <td>
                        <i *ngIf="order.statusDescription ==='Report Ready'" class="text-success fa fa-check"></i>
                    </td>
                    <td>
                        {{order.firstName + ' ' + order.lastName}}
                    </td>
                    <td>
                        {{order.statusDescription}}
                    </td>
                    <td>
                        {{order.reportDate | date:'MM/dd/yyyy h:mma'}}
                    </td>
                    <td>
                        <span *ngIf="order.reportId">{{order.reportId}}</span>
                    </td>
                    <td>
                        <a *ngIf="order.reportData" (click)="downloadReport(order.reportData)"
                            href="javascript: void(0);" download="application.pdf">
                            <i class='fa fa-md fa-file-pdf'></i>
                        </a>
                    </td>
                    <td>
                        <a *ngIf="!isVoaAlert(order)" (click)="importAssets(order);" href="javascript: void(0);">
                            <i class="fas fa-file-import"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>