<div class="modal-header">
  <h5 class="modal-title w-100">Possible Duplicate(s)</h5>

  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>

<div class="modal-body">
  We found the following possible duplicate Borrower(s)/Co Borrower(s). Please
  select if you wish to still create the new Borrower/Co Borrower or use an
  existing Borrower/Co Borrower.
  <br />
  <br />
  <div *ngIf="dupBorrowers.length" class="form-group">
    <label class="label-control">Select an option for the Borrower</label>
    <select [(ngModel)]="selections.selectedBorrowerId" class="form-select">
      <option [ngValue]="0">Create New</option>
      <optgroup label="Create New Loan Under Existing Contact">
        <option
          *ngFor="let borrower of dupBorrowers"
          [ngValue]="borrower.id"
        >
          Create Under {{ borrower.firstName }} {{ borrower.lastName
          }}{{ !!borrower.email ? ' - ' + borrower.email : '' }} - Created:
          {{ borrower.dateInserted | date: 'shortDate'
          }}{{
            !!borrower.borrowerContact
              ? ' - Contact: ' + borrower.borrowerContact
              : ''
          }}
        </option>
      </optgroup>
    </select>
  </div>
  <div *ngIf="dupCoBorrowers.length" class="form-group">
    <label class="label-control">Select an option for the Co Borrower</label>
    <select [(ngModel)]="selections.selectedCoBorrowerId" class="form-control">
      <option [ngValue]="0">Create New</option>
      <optgroup label="Create New Loan Under Existing Contact">
        <option
          *ngFor="let borrower of dupCoBorrowers"
          [ngValue]="borrower.id"
        >
          Create Under {{ borrower.firstName }} {{ borrower.lastName
          }}{{ !!borrower.email ? ' - ' + borrower.email : '' }} - Created:
          {{ borrower.dateInserted | date: 'shortDate'
          }}{{
            !!borrower.borrowerContact
              ? ' - Contact: ' + borrower.borrowerContact
              : ''
          }}
        </option>
      </optgroup>
    </select>
  </div>
</div>

<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-secondary "
    (click)="activeModal.dismiss()"
  >
    <i class="fa fa-close"></i> Cancel
  </button>

  <button
    type="button"
    class="btn btn-primary "
    (click)="activeModal.close(selections)"
  >
    Submit
  </button>
</div>
