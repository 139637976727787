import { Component, Injector, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { PricingService } from 'src/app/modules/pricing/services/pricing.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'tpo-appraisal-management',
  templateUrl: 'tpo-appraisal-management.component.html'
})
export class TpoAppraisalManagementComponent implements OnDestroy {
  isTpo: boolean = false;


  private readonly _contextSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _pricingService: PricingService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _notifyService: NotificationService) {

    this._contextSubscription = this._applicationContextService.context.subscribe(context => {
      this.isTpo = context.isTpo;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }
}
