<form #ssnInputForm="ngForm">
  <div *ngIf="!inEditMode"
    class="form-input"
    [ngClass]="
      {
        'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
        'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
        'ng-invalid-pattern' : model && model.invalid && model.errors && model.errors.pattern,
        'ng-invalid-mask' : model && model.invalid && model.errors && model.errors.mask,
        'bg-transparent' : readonly
      }"
    [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden" >
    <span class="input-label" title="">
      <span class="input-label-overflow">{{label}}</span>
    </span>
    <input
      type="text"
      [hidden]="!inEditModeForSSN"
      [name]="name"
      #ssn
      [pattern]="ssnPattern"
      [required]="required"
      [placeholder]="placeholder ? placeholder : ''"
      [ngClass]="
        {
          'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
          'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
          'ng-invalid-pattern' : model && model.invalid && model.errors && model.errors.pattern,
          'ng-invalid-mask' : model && model.invalid && model.errors && model.errors.mask
        }"
      [mask]="'000-00-0000'"
      [showMaskTyped]="true"
      [dropSpecialCharacters]="false" (blur)="onSsnBlurred($event)"
      [disabled]="disabled"
      [(ngModel)]="value"
      class="input-element"
      #model="ngModel"
      [readonly]="readonly"/>
    <span *ngIf="!inEditModeForSSN" tabindex="0" (focus)="onEditModeToggled()" (click)="onEditModeToggled()" style="height:25px;line-height:25px" title="{{value | mask: '000-00-0000'}}">
    ###-##-####
    </span>
  </div>
  <edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
  </edit-mode-input>
</form>
