<form #currencyInputForm="ngForm" style="height:100%">
  <div [hidden]="inEditMode || (fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden)" [ngClass]="
      { 'ng-invalid-requested' : (model && model.invalid && model.errors && model.errors.required && ((required && !fieldBeingEdited) || (fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested'))) || ((fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required != 'none') && (failsValidationWithZeroValue && urlaFieldsConfig[fieldBeingEdited]?.required !== 'required')),
        'ng-invalid-required' : (model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required') ,
        'form-input' : showBorder || (!showBorder && label),
        'no-border' : !showBorder && !label,
        'bg-transparent' : readonly || (hasCalculator && !isEditableEvenIfItHasACalculator)
      }">
    <span class="input-label" title="" *ngIf="label && label.length > 0">
      <span class="input-label-overflow">{{label}}</span>
    </span>
    <div class="row calculated-field align-right justify-content-between">
      <div class="col-xs-6 px-2 urla-currency" [ngClass]="{ 'col-sm-11': !suffix, 'col-sm-7': suffix }">

          <input
            [(ngModel)]="value"
            type="text"
            #control
            name="{{name}}"
            #model="ngModel"
            placeholder="$"
            [required]="required"
            [readonly]="readonly || (hasCalculator && !isEditableEvenIfItHasACalculator)"
            (keydown)="onKeyDown($event)"
            (blur)="onBlurred($event)"
            (focus)="onFocused($event)"
            (paste)="onPaste($event)"
            [disabled]="disabled"
            class="input-element"
            [ngClass]="
            { 'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && ((required && !fieldBeingEdited) || (fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested')) || ((fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required != 'none') && (failsValidationWithZeroValue && urlaFieldsConfig[fieldBeingEdited]?.required !== 'required')),
              'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
              'no-border' : !showBorder,
              'text-decoration': isOmitted
            }"
            >

      </div>
      <div *ngIf="suffix" class="col-sm-4 col-xs-6" style="padding-top: 3px">{{suffix}}</div>
      <a (click)="onCalculatorClicked()"
        class="col-sm-1 col-xs-6"
        style="float: right;"
        *ngIf="hasCalculator && !readonly"
        [ngClass]="{'disabled': (readonly || disabled)}">
        <i class="fa fa-calculator"></i>
      </a>

      <a (click)="onCustomIconClicked()"
        class="col-sm-1 col-xs-6"
        style="float: right;"
        *ngIf="customIconClass && !readonly"
        [ngClass]="{'disabled': (readonly || disabled)}">
        <i class="{{customIconClass}}"></i>
      </a>
    </div>
  </div>

  <edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
  </edit-mode-input>
</form>
