<div class="modal-header">
  <h5 class="modal-title"> {{title}} </h5>
  <button type="button" class="btn-close" (click)="onCancelClicked()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <label class="col-md-3 col-form-label" for="zipcode"> ZIP Code </label>
    <div class="col-md-9">
      <zip-code-input [(ngModel)]="zipcodeInfo.zipcode" [name]="'zipcode'"
        (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
      </zip-code-input>
    </div>
  </div>
  <div class="row">
    <div class="col-md-9 mt-3">
      <div class="row">
        <label class="col-md-4 col-form-label" for="city"> City </label>
        <div class="col-md-8">
          <input type="text" class="col-md-3 col-xs-4 form-control" [name]="'city'" [(ngModel)]="zipcodeInfo.city"
            [readonly]="true" />
        </div>
      </div>
    </div>
    <div class="col-md-3 mt-3">
      <div class="row">
        <label class="col-md-6 col-form-label" for="state"> State </label>
        <div class="col-md-6">
          <select class="col-md-1 col-xs-2 form-control" [disabled]="true" [name]="'state'"
            [(ngModel)]="zipcodeInfo.state">
            <option value="{{zipcodeInfo.state}}"> {{zipcodeInfo.state}} </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-3">
      <div class="row">
        <label class="col-md-3 col-form-label" for="county"> County </label>
        <div class="col-md-9">
          <input type="text" class="col-md-3 col-xs-4 form-control" [name]="'county'" [(ngModel)]="zipcodeInfo.county"
            [readonly]="true" />
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancelClicked()" data-bs-dismiss="modal"> Close
  </button>
  <button type="button" class="btn btn-primary" [disabled]="!isOkEnabled" (click)="onOkClicked()"
    data-bs-dismiss="modal"> OK
  </button>
</div>