<form #buydownDetailsForm="ngForm" novalidate id="buydownDetailsForm"
    name="buydownDetailsForm" class="p-3">
    <div class="row mb-3">
        <div class="form-group col-md-6 mb-0">
            <label class="mb-2" for="balloonTermMonths">Initial Buydown Rate</label>
            <percent-input name="initialBuydownRate" [(ngModel)]="buydownDetail.initialBuydownRate"
                [required]='isRequired("mortgage.extension.buydownDetail.initialBuydownRate")'
                [readonly]="readonly">
            </percent-input>
        </div>
        <div class="form-group col-md-6 mb-0">
            <label class="mb-2" for="buydownCalculationType">Buydown Calculation Type</label>
            <select class="form-select" name="buydownCalculationType" id="buydownCalculationType"
                [(ngModel)]="buydownDetail.buydownCalculationType" 
                [required]='isRequired("mortgage.extension.buydownDetail.buydownCalculationType")'
                [disabled]="readonly">
                <option [ngValue]="null">--Select One--</option>
                <option [ngValue]="option.value" *ngFor="let option of buydownCalculationTypes">
                {{ option.name }}
                </option>
            </select>
        </div>
    </div>
    <div class="mb-3">
        <button class="btn btn-sm btn-outline-primary" (click)="addBuydownSchedule()" *ngIf="!readonly">
            <i class="fa fa-plus"></i> Add Buydown Schedule
        </button>
    </div>
    <div *ngIf="buydownDetail.buydownSchedule && buydownDetail.buydownSchedule.length > 0">
        <div class="row mb-3" *ngFor="let item of buydownDetail.buydownSchedule; let index = index">
            <div class="form-group col-md-5 mb-0">
                <label class="mb-2" *ngIf="index == 0">Adjustment Percent</label>
                <percent-input name="adjustmentPercent_{{index}}" [rateIsTrueToTextValue]="false"
                    [(ngModel)]='item.adjustmentPercent'
                    [required]='isRequired("mortgage.extension.buydownDetail.buydownSchedule.adjustmentPercent")'
                    [readonly]="readonly">
                </percent-input>
            </div>
            <div class="form-group col-md-5 mb-0">
                <label class="mb-2" *ngIf="index == 0">Payment Count</label>
                <input class="form-control" numeric [allowNegative]="false"
                  [(ngModel)]='item.paymentCount' 
                  [required]='isRequired("mortgage.extension.buydownDetail.buydownSchedule.paymentCount")'
                  name='paymentCount_{{index}}'[readonly]="readonly" />
            </div>
            <div class="col-md-2 mb-0 d-flex justify-content-center align-items-center flex-column">
                <label class="mb-2" *ngIf="index == 0">&nbsp;</label>
                <button *ngIf="!readonly" class="btn btn-sm btn-danger" (click)="removeBuydownSchedule(index)">
                    Remove
                </button>
            </div>
        </div>
    </div>
    <div class="row mb-3" *ngIf="hasSaveCancelButtons && !readonly">
        <div class="col-md-12 text-end">
            <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
            <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
                Save
            </button>
        </div>
    </div>
</form>