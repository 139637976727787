<form class='row g-0 align-items-stretch' #form="ngForm">
  <!-- Main Content -->
  <div class='col-12 col-lg'>
    <!-- Address -->
    <div class='row'>
      <!-- Address -->
      <div class='col'>
        <div class='row'>
          <!-- Address1 -->
          <div class='form-group col-12 col-lg-4'>
            <div
              class='d-flex gap-4 justify-content-between'
              style='overflow-x: hidden;white-space: nowrap;'
            >
              <label for='address1-{{id}}'>
                {{ streetAddressLabel }}
                <span *ngIf="isRequestedOrRequired('mortgage.borrower.residencyAddress.address1')">*</span>
              </label>
            </div>

            <address-autocomplete-input
              #addressModel="ngModel"
              [(ngModel)]='address.address.address1'
              (ngModelChange)="onEmptyStreetControl()"
              (addressChange)='onAddressChange($event)'
              [class.invalid-requested]="hasRequiredValidationError(addressModel) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.address1'] == 'requested'"
              [class.invalid-required]="hasRequiredValidationError(addressModel) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.address1'] == 'required'"
              [required]="isRequestedOrRequired('mortgage.borrower.residencyAddress.address1')"
              id='address1-{{id}}'
              name='address1{{id}}'
              placeholder=''
            ></address-autocomplete-input>
          </div>

          <!-- City, State, Zip -->
          <div class='col-12 col-lg-8'>
            <div class='row g-0'>

              <!-- Unit # -->
              <div class='form-group col-12 col-lg-2'>
                <label for='address2-{{id}}'>
                  Unit #
                  <span *ngIf="isRequestedOrRequired('mortgage.borrower.residencyAddress.address2')">*</span>
                </label>

                <input
                  #address2="ngModel"
                  [class.invalid-requested]='false'
                  [class.invalid-required]='false'
                  class='form-control custom-input-left-side'
                  [class.invalid-requested]="hasRequiredValidationError(address2) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.address2'] == 'requested'"
                  [class.invalid-required]="hasRequiredValidationError(address2) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.address2'] == 'required'"
                  [(ngModel)]='address.address.address2'
                  (blur)='onBorrowerPresentAddressChanged(address)'
                  [required]="isRequestedOrRequired('mortgage.borrower.residencyAddress.address2')"
                  id='address2-{{id}}'
                  name="address2{{id}}"
                  placeholder=''
                  type='text'
                />
              </div>

              <!-- City -->
              <div class='form-group col-12 col-lg-4'>
                <label for='city-{{id}}'>
                  City
                  <span *ngIf="isRequestedOrRequired('mortgage.borrower.residencyAddress.city')">*</span>
                </label>

                <input
                  #city="ngModel"
                  [class.invalid-requested]='false'
                  [class.invalid-required]='false'
                  class='form-control custom-input-left-side custom-input-right-side'
                  [class.invalid-requested]="hasRequiredValidationError(city) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.city'] == 'requested'"
                  [class.invalid-required]="hasRequiredValidationError(city) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.city'] == 'required'"
                  [(ngModel)]='address.address.city'
                  (blur)='onBorrowerPresentAddressChanged(address)'
                  [required]="isRequestedOrRequired('mortgage.borrower.residencyAddress.city')"
                  id='city-{{id}}'
                  name="city{{id}}"
                  placeholder=''
                  type='text'
                />
              </div>

              <!-- State -->
              <div class='form-group col-12 col-lg-2'>
                <label for='state-{{id}}'>
                  State
                  <span *ngIf="isRequestedOrRequired('mortgage.borrower.residencyAddress.state')">*</span>
                </label>

                <select
                  #state="ngModel"
                  [class.invalid-requested]='false'
                  [class.invalid-required]='false'
                  class='form-select custom-input-left-side custom-input-right-side'
                  [class.invalid-requested]="hasRequiredValidationError(state) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.state'] == 'requested'"
                  [class.invalid-required]="hasRequiredValidationError(state) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.state'] == 'required'"
                  [(ngModel)]='address.address.state'
                  (ngModelChange)='onBorrowerPresentAddressChanged(address)'
                  [required]="isRequestedOrRequired('mortgage.borrower.residencyAddress.state')"
                  id='state-{{id}}'
                  name="state{{id}}"
                >
                  <option [ngValue]='null'>-- Select One --</option>
                  <option *ngFor='let state of stateOptions' [ngValue]='state.value'>
                    {{ state.name }}
                  </option>
                </select>
              </div>

              <!-- Zip Code -->
              <div class='form-group col-12 col-lg-4'>
                <label for='zip-code-{{id}}'>
                  Zip Code
                  <span *ngIf="isRequestedOrRequired('mortgage.borrower.residencyAddress.zipCode')">*</span>
                </label>

                <zip-code-input
                  #zipCode="ngModel"
                  (selectionChanged)='onZipCodeChange($event)'
                  [(ngModel)]='address.address.zipCode'
                  [class.invalid-requested]="hasRequiredValidationError(zipCode) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.zipCode'] == 'requested'"
                  [class.invalid-required]="hasRequiredValidationError(zipCode) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.zipCode'] == 'required'"
                  [required]="isRequestedOrRequired('mortgage.borrower.residencyAddress.zipCode')"
                  class='custom-input-right-side'
                  id='zip-code-{{id}}'
                  name='zipCode{{id}}'
                ></zip-code-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Years and Months Spent -->
      <ng-container *ngIf='showDuration'>
        <div class='col-12 col-lg-4'>
          <div class='row'>
            <!-- Years Spent -->
            <div class='form-group col-12 col-lg-6'>
              <label for='duration-years-{{id}}' style='white-space: nowrap'>
                Years Spent
                <span *ngIf="isRequestedOrRequired('mortgage.borrower.residencyAddress.durationYears')">*</span>
              </label>

              <input
                #durationYears="ngModel"
                [allowNegative]='false'
                [class.invalid-requested]='false'
                [class.invalid-required]='false'
                class='form-control'
                [(ngModel)]="address.durationYears"
                (ngModelChange)="onDurationYearsChange()"
                [class.invalid-requested]="hasRequiredValidationError(durationYears) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.durationYears'] == 'requested'"
                [class.invalid-required]="hasRequiredValidationError(durationYears) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.durationYears'] == 'required'"
                [required]="isRequestedOrRequired('mortgage.borrower.residencyAddress.durationYears')"
                name="durationYears{{id}}"
                id='duration-years-{{id}}'
                numeric
                placeholder=''
                type='number'
              />
            </div>

            <!-- Months Spent -->
            <div class='form-group col-12 col-lg-6'>
              <label for='duration-months-{{id}}' style='white-space: nowrap'>
                Months Spent
                <span *ngIf="isRequestedOrRequired('mortgage.borrower.residencyAddress.durationMonths')">*</span>
              </label>

              <input
                #durationMonths="ngModel"
                [allowNegative]='false'
                [class.invalid-requested]='false'
                [class.invalid-required]='false'
                class='form-control'
                [(ngModel)]="address.durationMonths"
                (ngModelChange)="onDurationMonthsChange()"
                [class.invalid-requested]="hasRequiredValidationError(durationMonths) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.durationMonths'] == 'requested'"
                [class.invalid-required]="hasRequiredValidationError(durationMonths) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.durationMonths'] == 'required'"
                [required]="isRequestedOrRequired('mortgage.borrower.residencyAddress.durationMonths')"
                id='duration-months-{{id}}'
                name="durationMonths{{id}}"
                numeric
                placeholder=''
                type='number'
              />
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Housing -->
    <div *ngIf='showHousing' class='row'>
      <!-- Housing Type -->
      <div class='form-group col-12 col-lg-2'>
        <label for='residency-basis-{{id}}'>
          Housing
          <span *ngIf="isRequestedOrRequired('mortgage.borrower.residencyAddress.residencyBasis')">*</span>
        </label>

        <select
          #residencyBasis='ngModel'
          class='form-select'
          [(ngModel)]='address.residencyBasis'
          (ngModelChange)="onResidencyBasisChanged()"
          [class.invalid-requested]="hasRequiredValidationError(residencyBasis) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis'] == 'requested'"
          [class.invalid-required]="hasRequiredValidationError(residencyBasis) && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis'] == 'required'"
          [required]="isRequestedOrRequired('mortgage.borrower.residencyAddress.residencyBasis')"
          id='residency-basis-{{id}}'
          name='residencyBasis{{id}}'
        >
          <option [ngValue]='null'>-- Select One --</option>
          <option *ngFor='let housingType of housingOptions' [ngValue]='housingType.value'>
            {{ housingType.name }}
          </option>
        </select>
      </div>

      <!-- Rent -->
      <ng-container *ngIf="address.residencyBasis === 'Rent'">
        <div class='form-group col-12 col-lg-2'>
          <label for='rent-{{id}}'>
            Rent
            <span *ngIf="isRequestedOrRequired('mortgage.borrower.residencyAddress.rent')">*</span>
          </label>

          <!-- FIXME: Giving id to currency-input causes its internal input's id to be NaN.
                      It uses "name" instead of "id" for the internal input's id.
          -->
          <currency-input
            [name]="'rent' + id"
            [placeholder]="'Enter Rent'"
            [isZeroValid]="false"
            [allowDecimals]="false"
            [(ngModel)]="address.rent"
            [ngClass]="{'invalid-requested': !address.rent && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.rent'] == 'requested',
                        'invalid-required': !address.rent && quickApplyFieldsConfig['mortgage.borrower.residencyAddress.rent'] == 'required'}"
            [required]="quickApplyFieldsConfig['mortgage.borrower.residencyAddress.rent'] == 'required' ||
                                quickApplyFieldsConfig['mortgage.borrower.residencyAddress.rent'] == 'requested'">
           </currency-input>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="col-auto" *ngIf="allowDelete">
    <button (click)='onAttemptedToDeleteAddress()'
      class='btn btn-outline-danger ms-3'
      style='margin-top: 1.25rem;'
      title='Delete Address'>
      <i class='fas fa-trash-alt'></i>
    </button>
  </div>
</form>
