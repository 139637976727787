import { Component, Injector, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { PinnedScenariosComponent } from 'src/app/modules/pricing/components/pinned-scenarios/pinned-scenarios.component';
import { PricingScenario } from 'src/app/modules/pricing/models/pricing/pricing-scenario.model';
import { ProductSearchRequestInfo } from 'src/app/modules/pricing/models/pricing/product-search-request-info.model';
import { PricingService } from 'src/app/modules/pricing/services/pricing.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'tpo-quick-pricer',
  templateUrl: 'tpo-quick-pricer.component.html'
})
export class TpoQuickPricerComponent implements OnDestroy {

  productSearchRequestInfo: ProductSearchRequestInfo = new ProductSearchRequestInfo();

  @Input()
  canPriceApplyToLoan: boolean = false;

  @ViewChild("pinnedScenarios")
  pinnedScenariosComponent: PinnedScenariosComponent;

  isTpo: boolean = false;

  isScenariosSectionOpen: boolean = false;

  scenarios: PricingScenario[] = [];

  atLeastOneScenarioIsRefreshing: boolean = false;

  protected requestLockEnabled: boolean = false;

  private readonly _contextSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _pricingService: PricingService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _notifyService: NotificationService) {

    this._contextSubscription = this._applicationContextService.context.subscribe(context => {
      this.isTpo = context.isTpo;
      this.requestLockEnabled = this.isTpo && (context.userPermissions.companyId === 2 || context.userPermissions.companyId === 222);
      this.productSearchRequestInfo = this._pricingService.getDefaultPricingRequest();
    });
  }

  ngOnInit() {
    this.getScenarios();
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  private getScenarios = () => {
    this._pricingService.getPricingScenariosGlobal().subscribe({
      next: (scenarios) => {
        this.scenarios = scenarios;
      },
      error: (err) => {
        this._notifyService.showError(err.message || err, "Error!");
      }
    });
  }
}
