<div class='grid-layout has-border'>
  <div class='grid-header mb-1'>
    <div class='grid-header-left'>
      <div>5a. About This Property and Your Money for this Loan</div>
    </div>

    <div class='grid-header-right'></div>
  </div>

  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text'
      label='A. Will you occupy the property as your primary residence?'
    ></urla-grid-cell>

    <urla-radio-group
      (change)='occupyPropertyChange()'
      [(ngModel)]='borrower.declarations.occupyProperty'
      [inEditMode]='inEditMode'
      [name]="'occupyProperty' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.occupyProperty")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.occupyProperty'
      layout='horizontal'>
    </urla-radio-group>

    <div class='row-spacer' *ngIf='!isReadOnly && !inEditMode'></div>
  </div>

  <div
    *ngIf='borrower.declarations.occupyProperty || inEditMode'
    class='grid-layout-row px-2 mb-2'
  >
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text left-space'
      label='A.1 If Yes, have you had an ownership interest in another property in the last three years'
    ></urla-grid-cell>

    <urla-radio-group
      (change)='havePropertiesOwnershipChange()'
      [(ngModel)]='borrower.declarations.havePropertiesOwnership'
      [inEditMode]='inEditMode'
      [name]="'havePropertiesOwnership' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.havePropertiesOwnership")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.havePropertiesOwnership'
      layout='horizontal'>
    </urla-radio-group>

    <div class='row-spacer' *ngIf='!isReadOnly && !inEditMode'></div>
  </div>

  <div
    *ngIf='(borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty) || inEditMode'
    class='grid-layout-row px-2 mb-2'
  >
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text left-space'
      label='A1.1 What type of property did you own?'>
    </urla-grid-cell>

    <urla-dropdown
      [(ngModel)]='borrower.declarations.typeOfProperty'
      [inEditMode]='inEditMode'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='propertyTypesOwned'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.typeOfProperty")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.typeOfProperty'
      name='typeOfProperty'>
    </urla-dropdown>

    <div class='row-spacer' *ngIf='!isReadOnly && !inEditMode'></div>
  </div>

  <div
    *ngIf='(borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty) || inEditMode'
    class='grid-layout-row px-2 mb-2'
  >
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text left-space'
      label='A1.2 How did you hold title to the property?'
    ></urla-grid-cell>

    <urla-dropdown
      [(ngModel)]='borrower.declarations.heldTitleHow'
      [inEditMode]='inEditMode'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='holdTitles'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.heldTitleHow")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.heldTitleHow'
      name='heldTitleHow'
    ></urla-dropdown>

    <div class='row-spacer' *ngIf='!isReadOnly && !inEditMode'></div>
  </div>

  <div
    class='grid-layout-row px-2 mb-2'
  >
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text left-space'
      label='A.2 Does the borrower qualify as a first time home buyer?'
    ></urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.isFirstTimeHomeBuyer'
      [inEditMode]='inEditMode'
      [name]="'isFirstTimeHomeBuyer' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.isFirstTimeHomeBuyer")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.isFirstTimeHomeBuyer'
      layout='horizontal'
    ></urla-radio-group>

    <div class='row-spacer' *ngIf='!isReadOnly && !inEditMode'></div>
  </div>

  <div
    *ngIf='isPurchase || inEditMode'
    class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text left-space'
      label='B. If this is a Purchase Transaction: Do you have a family relationship or business affiliation with the seller of the property?'
    ></urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.haveRelationshipWithSeller'
      [inEditMode]='inEditMode'
      [name]="'haveRelationshipWithSeller' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.haveRelationshipWithSeller")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.haveRelationshipWithSeller'
      layout='horizontal'
    ></urla-radio-group>

    <div class='row-spacer' *ngIf='!isReadOnly && !inEditMode'></div>
  </div>

  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text left-space'
      label='C. Are you borrowing any money for this real estate transaction (e.g., money for your closing costs or down payment) or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?'
    ></urla-grid-cell>

    <urla-radio-group
      (change)='borrowedDownPaymentChange()'
      [(ngModel)]='borrower.declarations.borrowedDownPayment'
      [inEditMode]='inEditMode'
      [name]="'borrowedDownPayment' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.borrowedDownPayment")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.borrowedDownPayment'
      layout='horizontal'
    ></urla-radio-group>

    <add-explanation-button
      *ngIf='!isReadOnly && !inEditMode'
      (clickAdd)='onClickAddExplanation(DeclarationExplanationType.BorrowedDownPayment)'
    ></add-explanation-button>
  </div>

  <ng-container
    *ngTemplateOutlet='
      explanationsTemplate;
      context: { $implicit: DeclarationExplanationType.BorrowedDownPayment }'
  ></ng-container>

  <div
    *ngIf='borrower.declarations.borrowedDownPayment'
    class='grid-layout-row px-2 mb-2'
  >
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text left-space'
      label='C.1 If Yes, what is the amount of this money?'>
    </urla-grid-cell>

    <urla-currency-input
      [(ngModel)]='borrower.declarations.undisclosedMoneyAmount'
      [disabled]='isUrlaReadonly'
      [inEditMode]='inEditMode'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.undisclosedMoneyAmount")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.undisclosedMoneyAmount'
      name='undisclosedMoneyAmount'
    ></urla-currency-input>

    <div class='row-spacer' *ngIf='!isReadOnly && !inEditMode'></div>
  </div>

  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text left-space'
      label='D.1 Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?'>
    </urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.applyingForOtherMortgage'
      [inEditMode]='inEditMode'
      [name]="'applyingForOtherMortgage' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.applyingForOtherMortgage")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.applyingForOtherMortgage'
      layout='horizontal'
    ></urla-radio-group>

    <div class='row-spacer' *ngIf='!isReadOnly && !inEditMode'></div>
  </div>

  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text left-space'
      label='D.2 Have you or will you be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing this loan that is not disclosed on this application?'>
    </urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.applyingForNonMortgageCredit'
      [inEditMode]='inEditMode'
      [name]="'applyingForNonMortgageCredit' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.applyingForNonMortgageCredit")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.applyingForNonMortgageCredit'
      layout='horizontal'
    ></urla-radio-group>

    <div class='row-spacer' *ngIf='!isReadOnly && !inEditMode'></div>
  </div>

  <div class='grid-layout-row px-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text left-space'
      label='E. Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?'>
    </urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.propertyHasLienWithCleanEnergyProgram'
      [inEditMode]='inEditMode'
      [name]="'propertyHasLienWithCleanEnergyProgram' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'
      layout='horizontal'
    ></urla-radio-group>

    <div class='row-spacer' *ngIf='!isReadOnly && !inEditMode'></div>
  </div>
</div>

<ng-template let-explanationType #explanationsTemplate>
  <ng-container *ngIf='getExplanationGroup(explanationType) as explanationGroup'>
    <div
      *ngFor='let explanation of explanationGroup; let i = index; let first = first; let last = last'
      [class.mb-2]='last'
      [style.margin-top.rem]='first ? -0.5 : null'
      class='grid-layout-row'
    >
      <declaration-explanation-editor
        [explanation]='explanation'
        [explanationType]='explanationType'
        [urlaFieldsConfig]='urlaFieldsConfig'
        [inEditMode]="inEditMode"
        (delete)='onClickDeleteExplanation(explanation, i)'
      ></declaration-explanation-editor>
    </div>
  </ng-container>
</ng-template>
