import { AfterViewInit, Component, Injector, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { orderBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ApplicationContext, Borrower, DocumentType, LoanApplication, UserPermissions } from 'src/app/models';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { DocumentTemplate } from 'src/app/models/config/global-config.model';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanStatusHistoryItem } from 'src/app/models/loan/loan-status-history.model';
import { DashboardTaskCounts } from 'src/app/models/task/dashboard-task-counts.model';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { EventEditorComponent } from 'src/app/modules/events/components/event-editor/event-editor.component';
import { Event, EventType, EventVisibility } from 'src/app/modules/events/models/event.model';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { McrAuditDataLookup } from 'src/app/modules/reports/mortgage-call/models/mcr-audit-data.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { Constants } from 'src/app/services/constants';
import { LoanService } from 'src/app/services/loan';
import { MortgageCallReportService } from 'src/app/services/mortgage-call-report.service';
import { MortgageService } from 'src/app/services/mortgage.service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { FileImportDialogComponent } from 'src/app/shared/components';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { BreadcrumbService } from 'src/app/shared/services/bread-crumb.service';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { AppDetailsService } from '../../services/app-details.service';
import { AppTasksComponent } from '../app-tasks/app-tasks.component';
import { LoanStatusHistoryDialogComponent } from '../loan-status-history-dialog/loan-status-history-dialog.component';
import { LoanPurposeTypeEnum } from '../title-history/models/title-order.model';
import { LoanDetailsInfo } from 'src/app/models/loan/loan-details-info.model';

@Component({
  selector: 'app-details-v2',
  templateUrl: 'app-details-v2.component.html',
  styleUrls: ['app-details-v2.component.scss']
})
export class AppDetailsV2Component extends ApplicationContextBoundComponent implements AfterViewInit {

  @ViewChild('taskCounters')
  taskCounters: AppTasksComponent;

  @ViewChild('eventEditor')
  addNewEventEditor: EventEditorComponent;

  @ViewChild('createEventDrawer')
  createEventDrawer: DrawerComponent;

  application: LoanApplication;

  mortgage: UrlaMortgage;

  permissions: UserPermissions;

  isTpoUser: boolean;

  taskCounts: DashboardTaskCounts;

  isLoadingApplicationDetails: boolean = false;

  filterId: number;

  tasks: LoanDocDashboardTask[];
  allowedDocumentTemplates: DocumentTemplate[] = [];

  isRefinance: boolean = false;

  applicationMode: string;

  applicationLoadFailed: boolean = false;

  protected isWideContent: boolean = false;

  documentTypes: DocumentType[] = [];

  drawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: 'drawer-wrapper'
  }

  drawerOptionsXl: DrawerOptions = {
    size: DrawerSize.XLarge,
    containerWrapperId: 'drawer-wrapper'
  }

  drawerOptionsLargeSlideOver: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  emailSmsHistoryDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  esignHistoryDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  loanSummaryDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  disclosureTrackingDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXXLarge,
    containerWrapperId: null
  }

  identifyingDocumentsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  checkListsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  denialDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  primaryBorrower: Borrower = null;

  borrowerNameById: Map<number, string> = new Map<number, string>();

  attachments: DocFile[] = null;

  @Input()
  secondPageActionBarVisible: boolean = false;

  private _loanId: number;

  private _taskSubscription: Subscription;

  private _niceInContactSubscription: Subscription;

  private _loanInfoLoadErrorSubscription: Subscription;

  private _routerEventSubscription: Subscription;

  private _documentDeletedEventSubscription: Subscription;

  private _isDocumentSigningRequiredReview: boolean;

  private _loanActivityDrawerOpened: boolean;

  private _currentRoute: string;

  private _loanPurposes: LoanPurpose[];

  loanActivityDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: "drawer-wrapper"
  }

  keyDatesDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  mcrDataAuditDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  viewTitleHistoryDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  benefitCalculatorsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  monsterInsightsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  docGenDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null,
  }

  creditInquiryDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null,
  }

  createWireRequestDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null,
  }

  purchaseAdviceDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null,
  }

  mcrAuditLookup: McrAuditDataLookup[] = [];

  mobileMenuExpanded: boolean = false;

  isPrequalLetterDocGenerationActive: boolean = false;
  isNiceInContactVisible: boolean = false;

  private _dynamicEventSubscriptions: any[] = [];

  private _loanInfoChangesSubscription: Subscription;
  private _loanStatusChangedSubscription: Subscription;
  private _callControlPanelChangesSubscription: Subscription;

  private _activatedRouteParamsSubscription: Subscription;
  private _activatedRouteQueryParamsSubscription: Subscription;

  constructor(
    private readonly _router: Router,
    private readonly _drawerService: DrawerService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _notificationService: NotificationService,
    private readonly _navigationService: NavigationService,
    private readonly _dashboardService: DashboardService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _loanService: LoanService,
    private readonly _breadcrumbService: BreadcrumbService,
    private readonly _utilityService: UtilityService,
    private readonly _mortgageService: MortgageService,
    private readonly _mortgageCallReportService: MortgageCallReportService,
    injector: Injector,
  ) {
    super(injector);

    this._loanStatusChangedSubscription = this._appDetailsService.loanStatusChanged.subscribe((e) => {
      this.loadApplicationTaskCounts();
    }
    );
    this.secondPageActionBarVisible = this.applicationContext.isCallControlPanelOpen;
    this._callControlPanelChangesSubscription = this.applicationContextService.callControlPanelStatChanges.subscribe(context => {
      this.secondPageActionBarVisible = context.isCallControlPanelOpen;
    })
    this.isNiceInContactVisible = this.applicationContext.isNiceInContactVisible;
    this.permissions = this.applicationContext.userPermissions;
    this.documentTypes = orderBy(this.applicationContext.globalConfig.documentType?.filter(doc => doc.documentTypeId > 0), 'documentTypeName');
    this.isTpoUser = this.applicationContext.isTpo;
    this._loanPurposes = this.applicationContext.globalConfig.loanPurpose;
    this.isLoadingApplicationDetails = true;

    this._loanInfoLoadErrorSubscription = this.applicationContextService.loanInfoLoadError.subscribe(error => {
      setTimeout(() => {
        this.taskCounts = this.generateAllZeroTaskCounts();
        this.applicationLoadFailed = true;
        this._router.navigateByUrl('/admin/dashboard');
      }, 2000)
    });
    this._taskSubscription = this.applicationContextService.loanTasksChanges.subscribe((context) => {
      if (!context.application) {
        return;
      }
      this.loadApplicationTaskCounts();
    });

    this._niceInContactSubscription = this.applicationContextService.niceInContactVisibilityStateChanges.subscribe((isVisible) => {
      this.isNiceInContactVisible = isVisible;
    });

    this._loanInfoChangesSubscription =
      this.applicationContextService.loanInfoChanges.subscribe(
        (context) => {
          if (!context.application) {
            return;
          }
          this.tasks = null;
          this.filterId = null;
          this.application = context.application;
          this.mortgage = context.currentMortgage;

          this.primaryBorrower = context.borrowers.find(b => b.borrowerId == this.application.primaryBorrowerId);

          this.borrowerNameById.clear();
          context.borrowers.forEach(borr => {
            this.borrowerNameById.set(borr.borrowerId, `${borr.firstName} ${borr.lastName}`);
          });
          this.isLoadingApplicationDetails = false;

          this.appendApplicationInfoToBreadcrumbs(context);
          this.loadApplicationTaskCounts();
          this.checkLoanPurpose();
        },
        (error) => {
          this.isLoadingApplicationDetails = false;
          this._notificationService.showError(
            error ? error.message : 'Unable open the loan.',
            'Error!'
          );
        }
      );

    this._routerEventSubscription = this._router.events.subscribe(
      (event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this._currentRoute = event.url;

          this.isWideContent = event.url.endsWith("/fees");
        }
      }
    );

    this._documentDeletedEventSubscription = this._dashboardService.documentDeleted.subscribe(() => {
      this.tasks = null;
    });
  }

  ngOnInit(): void {
    this.filterId = null;
    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
      this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';

    this._activatedRouteQueryParamsSubscription = this._activatedRoute.queryParams.subscribe((queryParams) => {
      this._activatedRouteParamsSubscription = this._activatedRoute.params.subscribe((routeParams) => {
        this._loanId = Number(this._activatedRoute.snapshot.paramMap.get('id'));
        if (this.taskCounters) {
          this.taskCounters.taskCounts = null;
        }
        if (this._loanId && !isNaN(this._loanId)) {
          this._spinner.show();
          this.applicationContextService.updateLoanInfo(this._loanId).subscribe({
            next: (context) => {
              this.allowedDocumentTemplates = context.globalConfig.documentTemplates.filter(t => {
                if (!t.restrictedUserRoles) {
                  return true;
                }
                return t.restrictedUserRoles.indexOf(context.userPermissions.roleId) >= 0;
              });
            },
            error: (error) => {
              this.applicationLoadFailed = true;
              this.isLoadingApplicationDetails = false;
              this._notificationService.showError(
                error ? error.message : 'Unable update loan info.',
                'Error!'
              );
            }
          }).add(() => this._spinner.hide());
        }
      });
    });
    this.loadDocumentSigningReviewRequiredConfig();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._activatedRouteParamsSubscription) {
      this._activatedRouteParamsSubscription.unsubscribe();
    }
    if (this._activatedRouteQueryParamsSubscription) {
      this._activatedRouteQueryParamsSubscription.unsubscribe();
    }
    if (this._callControlPanelChangesSubscription) {
      this._callControlPanelChangesSubscription.unsubscribe();
    }
    if (this._loanStatusChangedSubscription) {
      this._loanStatusChangedSubscription.unsubscribe();
    }
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._taskSubscription) {
      this._taskSubscription.unsubscribe();
    }
    if (this._niceInContactSubscription) {
      this._niceInContactSubscription.unsubscribe();
    }
    if (this._loanInfoLoadErrorSubscription) {
      this._loanInfoLoadErrorSubscription.unsubscribe();
    }
    if (this._routerEventSubscription) {
      this._routerEventSubscription.unsubscribe();
    }
    if (this._documentDeletedEventSubscription) {
      this._documentDeletedEventSubscription.unsubscribe();
    }
    this._dynamicEventSubscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  onMobileMenuClicked = () => {
    this.mobileMenuExpanded = !this.mobileMenuExpanded;
  }

  onCloseTaskTableClicked = () => {
    this.taskCounters.unselectAll();
    this.onStatSelected(null);
  }

  onLoadApplicationTaskCounts = () => {
    this.loadApplicationTaskCounts();
  }

  onExportMismoClicked = () => {
    this._spinner.show();
    this._mortgageService.exportMismoFile(this.application.applicationId).subscribe(() => {
      this._spinner.hide();
    }, error => {
      this._spinner.hide();
    });
  }

  onExportDUClicked = () => {
    this._spinner.show();
    this._mortgageService.exportDUFile(this.application.applicationId).subscribe(() => {
      this._spinner.hide();
    }, error => {
      this._spinner.hide();
    });
  }

  onImportMismoClicked = () => {
    const modalRef = this._modalService.open(FileImportDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.title = "Import MISMO 3.4";
    modalRef.componentInstance.message = "Please upload a valid MISMO 3.4 XML file.";
    modalRef.componentInstance.multiple = false;
    modalRef.componentInstance.accept = ".xml";
    modalRef.result.then((result) => {
      if (result && result.length > 0) {
        this._spinner.show();
        this._mortgageService.importMismoFile(this.mortgage.applicationId, this.mortgage.mortgageId, result[0]).subscribe(mortgage => {
          this._mortgageService.getUrlaMortgage(this.mortgage.applicationId).subscribe(mortgage => {
            this.applicationContextService.updateMortgageAndApplication(mortgage, this.applicationContext.application);
            this._spinner.hide();
            this._notificationService.showSuccess("MISMO 3.4 file has been successfully imported.", "Success");
          }, error => {
            this._spinner.hide();
          });
        }, error => {
          this._spinner.hide();
        });
      }
    }, (err) => {
    });
  }

  onStatSelected = (statId: number) => {
    this.filterId = statId;
    if (!this.filterId) {
      this.tasks = null;
      return;
    }
    this.loadLoanDashboardTasks(true);
  }

  reloadTasks = () => {
    this.applicationContextService.updateLoanTasks();
  }

  onSetAppointmentClicked = () => {
    let event = new Event();
    event.eventType = EventType.Appointment;
    event.applicationId = this.application.applicationId;
    event.borrowerId = this.application.primaryBorrowerId;
    event.companyId = this.application.companyId;
    event.userId = this.applicationContext.userPermissions.userId;
    event.privilege = EventVisibility.Private;
    const startDate = new Date();
    event.dateStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() + 1, 0, 0, 0).toISOString();
    event.dateEnd = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() + 1, 15, 0, 0).toISOString();

    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = EventEditorComponent;
    dynamicComponentInfo.parameters.set('event', event);

    this._drawerService.show("createEventDrawer", 200, "Set Appointment", dynamicComponentInfo, this.drawerOptions).then(() => {
      const subscription = this.createEventDrawer.componentInstance.eventSaved.subscribe((event) => {
        this.onEventSaved(event);
      });
      this._dynamicEventSubscriptions.push(subscription);
    });
  }

  onSendEmailSmsClicked = () => {
    this.attachments = null;
    this._drawerService.show("sendEmailSmsDrawer", 200);
  }

  onSendEmailClicked = () => {
    this.attachments = null;
    this.applicationContextService.sendEmailDialogLaunched();
    this._drawerService.show("sendEmailSmsDrawer", 200);
  }

  onSendEmailWithAttachmentClicked = (attachmentDocFile: DocFile) => {
    this.attachments = [attachmentDocFile];
    this._drawerService.show("sendEmailSmsDrawer", 200);
  }

  onEmailSmsHistoryClicked = () => {
    this._drawerService.show("emailSmsHistoryDrawer", 200);
  }

  onLoanSummaryClicked = () => {
    this._drawerService.show("loanSummaryDrawer", 200);
  }

  onDisclosureTrackingClicked = () => {
    this._drawerService.show("disclosureTrackingDrawer", 200);
  }

  onIdentifyingDocumentsClicked = () => {
    this._drawerService.show("identifyingDocumentsDrawer2", 200);
  }

  onViewEsignatureHistoryClicked = () => {
    this._drawerService.show("esignHistoryDrawer", 200);
  }

  onViewTitleHistoryClicked = () => {
    this._drawerService.show("viewTitleHistoryDrawer", 200);
  }

  onEventSaved = (updatedEvent: Event) => {
    this._spinner.hide();
    this._drawerService.hide('createEventDrawer');
  }

  onEmailSmsSentSuccessfully = () => {
    this._drawerService.hide('sendEmailSmsDrawer');
  }

  onEmailSmsSendCancelled = () => {
    this._drawerService.hide('sendEmailSmsDrawer');
  }

  onSendEmailSmsDrawerclosed = () => {
    this.applicationContextService.sendEmailDialogClosed();
  }

  onLoanStatusHistoryClicked = () => {
    this._spinner.show();
    this._loanService.getLoanStatusHistory(this.application.applicationId).subscribe((history: LoanStatusHistoryItem[]) => {
      const modalRef = this._modalService.open(LoanStatusHistoryDialogComponent, Constants.modalOptions.large);
      modalRef.componentInstance.history = history;
      this._spinner.hide();
    }, error => {
      this._spinner.hide();
      this._notificationService.showError(
        error ? error.message : 'Unable to get loan status history.',
        'Error!'
      );
    });
  }

  onLoanActivityToggled = () => {
    if (!this._loanActivityDrawerOpened) {
      this._drawerService.show("loanActivityDrawer", 10);
      this._loanActivityDrawerOpened = true;
    } else {
      this._drawerService.hide("loanActivityDrawer", 10);
      this._loanActivityDrawerOpened = false;
    }
  }

  onBenefitCalculatorsclicked = () => {
    this.isRefinance = this._loanPurposes.find(x => x.loanPurposeId === this.application.loanPurposeId)?.mortgageLoanPurpose == "Refinance";
    this._drawerService.show("benefitCalculatorsDrawer", 10);
  }

  onMonsterInsightsClicked = () => {
    this._drawerService.show("monsterInsightsDrawer", 10);
  }

  generateMonsterInsightsUrl = () => {
    this._loanService.getMonsterInsightsUrl(this.application.applicationId).subscribe(monster => {
      this.application.monsterInsightsUrl = monster.insightUrl;
    }, err=>{
      this._notificationService.showError("Details: " + err, "Error");
    });
  }

  onLoanActivityDrawerClosed = () => {
    this._drawerService.hide("loanActivityDrawer", 10);
    this._loanActivityDrawerOpened = false;
  }

  getLoanSummaryDrawerTitle = () => {
    return "Loan Summary" + (this.primaryBorrower ? ' - ' + this._utilityService.getBorrowerFullName(this.primaryBorrower) : '');
  }

  onKeyDatesClicked = () => {
    this._drawerService.show("keyDatesDrawer", 10);
  }

  onMcrAuditDataClicked = (willBeDrawerOpen: boolean = true) => {
    this._spinner.show();

    this._mortgageCallReportService.getAuditDataForApplication(this.application.applicationId)
      .subscribe({
        next: (data) => {
          this.mcrAuditLookup = data || [];

          this._spinner.hide();
          if (willBeDrawerOpen) {
            this._drawerService.show("mcrAuditDataSummaryEditorDrawer", 10);
          }
        },
        error: (err) => {
          this._spinner.hide();
          if (willBeDrawerOpen) {
            this._drawerService.show("mcrAuditDataSummaryEditorDrawer", 10);
          }
        }
      })
  }

  onGenerateDocClicked = (isPrequalLetterActive: boolean) => {
    this.isPrequalLetterDocGenerationActive = isPrequalLetterActive;
    this._drawerService.show("docGenDrawer", 10);
  }

  onGenerateDocClosed = () => {
    this._drawerService.hide("docGenDrawer", 10);
  }

  onCreditInquiryClicked = () => {
    this._drawerService.show("creditInquiryDrawer", 10);
  }

  onCreditInquiryClosed = () => {
    this._drawerService.hide("creditInquiryDrawer", 10);
  }

  onCreateWireRequestClicked = () => {
    this._drawerService.show("createWireRequestDrawer", 10);
  }

  onCreateWireRequestClosed = () => {
    this._drawerService.hide("createWireRequestDrawer", 10);
  }

  onPurchaseAdviceClicked = () => {
    this._drawerService.show("purchaseAdviceDrawer", 10);
  }

  onPurchaseAdviceClosed = () => {
    this._drawerService.hide("purchaseAdviceDrawer", 10);
  }

  onPurchaseAdviceSaved = (loanInfo: LoanDetailsInfo) => {
    this._drawerService.hide("purchaseAdviceDrawer", 10);
    this.applicationContextService.updateMortgageAndApplication(loanInfo.application.mortgageLoan, loanInfo.application);
  }

  onTaskTableDrawerClosed = (): void => {
    this.filterId = null;
    this.loadApplicationTaskCounts();
  };

  private loadApplicationTaskCounts = (): void => {
    this._spinner.show();
    this._appDetailsService
      .getApplicationTaskCounts(this.application.applicationId)
      .subscribe(
        (taskCounts) => {
          this._spinner.hide();
          this.taskCounts = taskCounts;
          //this.tasks = null;
          if (this.filterId) {
            this.onStatSelected(this.filterId);
          }
        },
        (error) => {
          this._spinner.hide();
          this._notificationService.showError(
            error ? error.message : 'Unable to get tasks.',
            'Error!'
          );
        }
      );
  };

  private generateAllZeroTaskCounts = () => {
    return {
      bt_c: 0,
      bt_p: 0,
      bt_s: 0,
      cpa: 0,
      ct: 0,
      doc_exp: 0,
      doc_l5exp: 0,
      filter11: 0,
      filter12: 0,
      mt_g2: 0,
      mt_l2: 0,
      ot_g2: 0,
      ot_l2: 0,
      tpoHp: 0,
      tpoSub: 0
    }
  }

  private appendApplicationInfoToBreadcrumbs = (context: ApplicationContext) => {
    let primaryBorrower = context.borrowers.find(b => b.isPrimary);
    if (!primaryBorrower) {
      primaryBorrower = context.borrowers[0];
    }
    const breadcrumbs = [];
    breadcrumbs.push({
      label: `App Details (${primaryBorrower.lastName} - ${this.application.mailingStreet})`
    });
    // breadcrumbs.push({
    //   label: `Application(${this.application.mailingStreet})`
    // });
    // if (!primaryBorrower) {
    //   primaryBorrower = context.borrower[0];
    // }
    // breadcrumbs.push({
    //   label: `Borrower(${primaryBorrower.lastName})`,
    //   url: `borrower/details/${primaryBorrower.borrowerId}`
    // });
    this._breadcrumbService.append(breadcrumbs);
  }

  private loadLoanDashboardTasks = (openDrawer?: boolean) => {
    this._spinner.show();
    this._dashboardService.getTaskByFilter(this.filterId, this._loanId).subscribe((response) => {
      this.tasks = response;
      this._spinner.hide();
    }, (error) => {
      this._spinner.hide();
      this._notificationService.showError(
        error ? error.message : 'Unable to get tasks.',
        'Error!'
      );
    });
  }

  private loadDocumentSigningReviewRequiredConfig = () => {
    this._loanDocsService.loadDocumentSigningReviewRequiredConfig().subscribe(result => {
      this._isDocumentSigningRequiredReview = result;
    })
  }

  private navigateToErrorPage = (error: string) => {
    const parts = this._currentRoute.split('/');
    if (parts.length > 1) {
      const lastSegment = parts[parts.length - 1];
      let errorUrl: string = "";
      if (isNaN(Number(lastSegment))) {
        errorUrl = this._currentRoute.replace(lastSegment, "error");
      } else {
        errorUrl = this._currentRoute + "/error";
      }
      this._router.navigateByUrl(errorUrl, { state: { error: error } });
    }
  }

  private checkLoanPurpose = () => {
    if (!this.mortgage?.subjectProperty || !this.application.loanPurposeId) return;

    const loanPurposeName = this._loanPurposes.find(lp => lp.loanPurposeId === this.application.loanPurposeId)?.mortgageLoanPurpose;
    if (loanPurposeName && loanPurposeName !== this.mortgage.subjectProperty.purposeOfLoan) {
      Swal.fire({
        title: `The loan purpose on the URLA shows '${this.mortgage.subjectProperty.purposeOfLoan}', while the application loan purpose is '${loanPurposeName}'.`,
        text: 'Would you like to update the URLA to match?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result: SweetAlertResult) => {
        if (!result.value) {
          return;
        }
        this.mortgage.subjectProperty.purposeOfLoan = loanPurposeName;
        this._mortgageService.loanPurpose =
          loanPurposeName as LoanPurposeTypeEnum;
      });
    }
  }

  onCheckListsClicked = () => {
    this._drawerService.show("checkListsDrawer", 10);
  }

  onDenialClicked = () => {
    this._drawerService.show("denialDrawer", 10);
  }

  onHmdaDrawerClosed = () => {
    this._drawerService.hide("denialDrawer", 10);
  }

  onHmdaAfterSave = (updatedMortgage: UrlaMortgage) => {
    if (!updatedMortgage) {
      return;
    }
    this.applicationContextService.updateMortgage(updatedMortgage);
  }

  getDeniedByUserName = (): string => {
    if (!this.mortgage.denialTerminationChangeDetail.deniedByUserId) {
      return '';
    }
    const matchingUser = this.applicationContext.globalConfig.users
      .find(u => u.userCompanyGuid === this.mortgage.denialTerminationChangeDetail.deniedByUserId)
    return !matchingUser ? '' : matchingUser.lastName
      ? `${matchingUser.firstName} ${matchingUser.lastName}` : matchingUser.firstName
  }
}
