import { AfterViewInit, Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SystemLevel } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';
import { UrlaMortgage } from '../models/urla-mortgage.model';
import { UrlaSectionComponent } from '../urla-section/urla-section.component';

@Component({
  selector: 'loan-originator-info',
  templateUrl: 'loan-originator-info.component.html',
})
export class LoanOriginatorInfoComponent extends UrlaSectionComponent
  implements AfterViewInit, OnChanges {

  @Input()
  mortgage: UrlaMortgage;

  @ViewChild('loanOriginatorForm')
  urlaSectionForm: NgForm;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  isRequiredLoanAndCaseNumbers: boolean = true;

  @Input()
  isGeneratingMers: boolean = false;

  @Input()
  isMersEnabled: boolean = false;

  @Input()
  isAdmin: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  systemLevel: SystemLevel;

  menuItem: string = Constants.menu.urlaMenuItems.loanOriginatorInfo;

  @Output()
  refreshClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  generateMersClicked: EventEmitter<any> = new EventEmitter<void>();

  emailPattern = Constants.regexPatterns.email;

  states: EnumerationItem[] = [];

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _urlaValidationService: UrlaValidationService,
    injector: Injector,
  ) {
    super(injector);
    this.states = this._enumsService.states;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.systemLevel && changes.systemLevel.currentValue) {
      this.populateFhaVaInfoFromConfigs();
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.onChangeStateLicenseState(this.mortgage.originatorInformation.stateLicense);
    this.onChangeOrganizationLicenseState(this.mortgage.originatorInformation.organizationStateLicense);

    this.mortgage.originatorInformation.stateLicenseState =
      this.mortgage.originatorInformation.stateLicenseState ? this.mortgage.originatorInformation.stateLicenseState.toLowerCase() : null;
    this.mortgage.originatorInformation.organizationStateLicenseState =
      this.mortgage.originatorInformation.organizationStateLicenseState ? this.mortgage.originatorInformation.organizationStateLicenseState.toLowerCase() : null;
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForLoanOriginatorInfo(this.mortgage, this.isMersEnabled, this.isRequiredLoanAndCaseNumbers);
    super.setMenuItemStatus(validityStatus);
  }

  private populateFhaVaInfoFromConfigs = () => {
    if (this.mortgage.mortgageTerm.mortgageAppliedFor === 'FHA' || this.inEditMode) {
      this.mortgage.originatorInformation.originatorFhaLenderId = this.systemLevel.company.fhaLenderId;
      this.mortgage.originatorInformation.originatorFhaSponsorId = this.systemLevel.company.fhaSponsorId;
    }

    if (this.mortgage.mortgageTerm.mortgageAppliedFor === 'VA' || this.inEditMode) {
      this.mortgage.originatorInformation.organizationVaLenderId = this.systemLevel.company.vaLenderId;
      this.mortgage.originatorInformation.organizationVaSponsorId = this.systemLevel.company.vaSponsorId;
    }
  }

  protected onRefreshOriginatorInfoClicked() {
    this.refreshClicked.emit();
  };

  protected generateMERS() {
    this.generateMersClicked.emit();
  };

  protected onChangeStateLicenseState(value: string) {
    if (!this.hasStateLicense(value)) {
      delete this.mortgage.originatorInformation.stateLicense;
    }
  }

  protected onChangeOrganizationLicenseState(value: string) {
    if (!this.hasStateLicense(value)) {
      delete this.mortgage.originatorInformation.organizationStateLicense;
    }
  }

  protected hasStateLicense(value: string) {
    return value !== 'tx';
  }
}
