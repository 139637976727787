import { Component, Injector, OnInit } from '@angular/core';
import { finalize, forkJoin, Subscription } from 'rxjs';
import { ApplicationContext, BaseKeyDate, Borrower, LoanApplication, ProductPricing } from 'src/app/models';
import { TpoConfiguration } from 'src/app/models/config/tpo-config.model';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';
import { ValidationResult } from 'src/app/models/loan/validation-result.model';
import { Message } from 'src/app/models/message/message.model';
import { AdminService } from 'src/app/services/admin.service';
import { LoanService } from 'src/app/services/loan';
import { TaskService, TpoTaskFilterEnum } from 'src/app/services/task.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { KeyDatesService } from 'src/app/services/key-dates.service';
import { cloneDeep } from 'lodash';
import { LicenseValidationResponse } from './models/license-validation-response.model';
import { ValidationType } from '../../models/loan/validation-type.model';
import { MenuItemStatus } from '../tpo/models/enums/menu-item-status.enum';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';

@Component({
  selector: 'submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.scss']
})
export class SubmissionComponent extends ApplicationContextBoundComponent implements OnInit {

  validation: Array<ValidationResult>;
  otherValidations: Array<ValidationResult>;
  protected licenseValidationData: LicenseValidationResponse | undefined;

  conditions: Array<LoanDocTask>;

  productPricing: Array<ProductPricing>;

  tpoConfig: TpoConfiguration;

  borrowers: Borrower[];

  itemsRequiredForSubmissionData: Array<LoanDocTask> = [];
  itemsRequestedForSubmission: Array<LoanDocTask> = [];

  finalSubmissionInfoData: {
    kD_RequestedClosingDate: BaseKeyDate;
    kD_FirstPaymentDate: BaseKeyDate;
    kD_CommitmentDate: BaseKeyDate;
    kD_CloseOfEscrow: BaseKeyDate;
  }

  tridAppDate: BaseKeyDate;
  leIssueDate: BaseKeyDate;



  disclosurePathPermissions: string;

  internalMessage: Message = new Message();

  isLoading: boolean = true;

  isSubmitLoanDisabled: boolean = false;

  isCompanyPulseProcessing : boolean = false;

  private _application: LoanApplication;

  private _loanInfoChangesSubscription: Subscription;

  constructor(
    injector: Injector,
    private readonly _loanService: LoanService,
    private readonly _taskService: TaskService,
    private readonly _adminService: AdminService,
    private readonly _keyDatesService: KeyDatesService,
    private readonly _messageService: MessageService,
    private readonly _notifyService: NotificationService,
    private readonly _navigationService: NavigationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _urlaValidationService: UrlaValidationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.applicationContext?.application) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          this.initialize(context);
        }
      });
    } else {
      this.initialize(this.applicationContext);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  loadData = () => {
    this.internalMessage.content = "";
    this.internalMessage.applicationId = this._application.applicationId;
    this.internalMessage.userId = "0";
    this.internalMessage.notifyPartyId = [];

    forkJoin([
      this._loanService.validateForSubmission(this._application.applicationId),
      this._taskService.getTaskByLoan(this._application.applicationId),
      this._loanService.getProductPricing(this._application.applicationId),
      this._adminService.getTpoConfiguration(),
      this._keyDatesService.getKeyDates(this._application.applicationId)
    ]).subscribe(([validation, conditions, productPricing, tpoConfig, loanKeyDates]) => {
      this.otherValidations = validation.validationResults.filter(v => (v.passed == false) && (v.validationType != 'Task' && v.validationType != 'CustomTask'));
      this.validation = validation.validationResults.filter(v => (v.passed == false) && (v.validationType == 'Task' || v.validationType == 'CustomTask'));
      this.licenseValidationData = validation.validationResults.find(v => v.validationType == ValidationType.Licensing)?.licenseValidationData;
      this.conditions = conditions;
      this.productPricing = productPricing;
      this.tpoConfig = tpoConfig;

      this.itemsRequiredForSubmissionData = conditions.filter(x => x.taskStatus == 'Pending' && (x.taskType == 'LosEsign' || x.taskType == 'EsignDocument')) || [];
      this.validation.forEach(validate => {
        let task = this.conditions.find(item => item.loanDocTaskId == validate.modelId);
        if (task) {
          task.errorMessage = validate.errorMessage;
          this.itemsRequiredForSubmissionData.push(task);
        }
      });

      let ldtIds = this.itemsRequiredForSubmissionData.map(x => x.loanDocTaskId);
      this.itemsRequestedForSubmission = conditions.filter(x => (x.taskStatus == 'Pending' || x.taskStatus == 'Rejected') && ldtIds.indexOf(x.loanDocTaskId) < 0) || [];

      this.finalSubmissionInfoData = {
        kD_RequestedClosingDate: loanKeyDates.find(keyDate => keyDate.keyDateConfigurationId == this.tpoConfig.kD_RequestedClosingDateId),
        kD_FirstPaymentDate: loanKeyDates.find(keyDate => keyDate.keyDateConfigurationId == this.tpoConfig.kD_FirstPaymentDateId),
        kD_CommitmentDate: loanKeyDates.find(keyDate => keyDate.keyDateConfigurationId == this.tpoConfig.kD_CommitmentDateId),
        kD_CloseOfEscrow: loanKeyDates.find(keyDate => keyDate.keyDateConfigurationId == this.tpoConfig.kD_CloseOfEscrowId),
      };


      this.tridAppDate = loanKeyDates.find(ele => ele.keyDateConfigurationId == tpoConfig.kD_AppDateId);

      this.disclosurePathPermissions = this.tpoConfig.disclosurePathPermissions;
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    });
  }

  fetchValidateTasks = () => {
    this.isLoading = true;
    this._taskService.getAllTpoTask(this.applicationContext.application.applicationId, TpoTaskFilterEnum.Outstanding).subscribe((response) => {
      this.conditions = response;
      this.itemsRequiredForSubmissionData = this.conditions.filter(x => x.taskStatus == 'Pending' && (x.taskType == 'LosEsign' || x.taskType == 'EsignDocument')) || [];
      this._loanService.validateForSubmission(this._application.applicationId)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(validation => {
          this.validation = validation.validationResults.filter(v => (v.passed == false) && (v.validationType == 'Task' || v.validationType == 'CustomTask'));
          this.validation.forEach(validate => {
            let task = this.conditions.find(item => item.loanDocTaskId == validate.modelId);
            if (task) {
              task.errorMessage = validate.errorMessage;
              this.itemsRequiredForSubmissionData.push(task);
            }
          });
          let ldtIds = this.itemsRequiredForSubmissionData.map(x => x.loanDocTaskId);
          this.itemsRequestedForSubmission = this.conditions.filter(x => (x.taskStatus == 'Pending' || x.taskStatus == 'Rejected') && ldtIds.indexOf(x.loanDocTaskId) < 0) || [];
          this.licenseValidationData = validation.validationResults.find(v => v.validationType == ValidationType.Licensing)?.licenseValidationData;
        });
    }, (err) => {
      this.isLoading = false;
    });

  }

  submitLoan = () => {
    let keyDates = cloneDeep(this.finalSubmissionInfoData);
    let params = [];

    if (keyDates.kD_RequestedClosingDate) {
      params.push(keyDates.kD_RequestedClosingDate);
    }
    if (keyDates.kD_FirstPaymentDate) {
      params.push(keyDates.kD_FirstPaymentDate);
    }
    if (keyDates.kD_CommitmentDate) {
      params.push(keyDates.kD_CommitmentDate);
    }
    if (keyDates.kD_CloseOfEscrow) {
      params.push(keyDates.kD_CloseOfEscrow);
    }

    if (this.tridAppDate) {
      params.push(this.tridAppDate);
    }

    if (params.length > 0) {
      params.forEach(keyDate => {
        if (keyDate) {
          delete keyDate.order;
          delete keyDate.displayName;
          delete keyDate.editByRole;
        }
      });
    }

    let promises = [this._loanService.submitTpoLoan(this._application.applicationId),
    this._keyDatesService.postUpsertKeyDates(params, this._application.applicationId)];

    if (this.internalMessage.content.trim() != "") {
      promises.push(this._messageService.postInternalMessage(this.internalMessage));
    }

    if (promises.length) {
      this._spinner.show();
      forkJoin(promises).subscribe((responses) => {
        if (!responses[0].errorMessage) {
          this._notifyService.showSuccess('Loan submitted successfully.', 'Successful!');
          this._spinner.hide();
          this.applicationContextService.updateLoanInfo(this._application.applicationId).subscribe(() => {
            this._navigationService.navigateToPath(`/tpo/app-details/${this._application.applicationId}/loan-summary`);
          })
        } else {
          this.itemsRequiredForSubmissionData = [];
          this._spinner.hide();
          if (responses[0].passed == false) {
            this.validation = responses[0].validationResults.filter(v => (v.passed == false) && (v.validationType == 'Task' || v.validationType == 'CustomTask'));
            this.validation.forEach(validate => {
              let task = this.conditions.find(item => item.loanDocTaskId == validate.modelId);
              this.itemsRequiredForSubmissionData.push(task);
            })
            this.licenseValidationData = responses[0].validationResults.find(v => v.validationType == ValidationType.Licensing)?.licenseValidationData;
          }
          this._notifyService.showError(responses[0].errorMessage, 'Error');
          this.applicationContextService.applicationTrackingStatusesChanged();
        }
        this.internalMessage.content = "";
      }, (err) => {
        this._notifyService.showError(err.message, 'Error');
        this._spinner.hide();
        this.applicationContextService.applicationTrackingStatusesChanged();
      });
    }
  }

  private initialize = (context: ApplicationContext) => {
    this._application = context.application;
    this.borrowers = context.borrowers;
    this.isCompanyPulseProcessing = context.isCompanyPulseProcessing;

    if (context.application.mortgageLoan.companyId == 229 || context.application.mortgageLoan.companyId == 276) {
      const urlaStatus = this._urlaValidationService.getStatusForUrla(context.application.mortgageLoan, context.userPermissions?.mersEnabled, context.application.channel !== 'Wholesale' || !this.applicationContext.isTpo, true);
      if (urlaStatus == MenuItemStatus.Error) {
        this.isSubmitLoanDisabled = true;
      }
    }
    
    this.loadData();
  }
}
