import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { Address } from 'src/app/models';
import { BaseUrlaInputComponent } from '../base-urla-input.component';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { autoId } from '../../../../../core/services/utils';
import { ApplicationContextService } from '../../../../../services/application-context.service';

@Component({
  selector: 'urla-street-address',
  templateUrl: 'urla-street-address.component.html',
  providers: [MakeProvider(UrlaStreetAddressComponent)]
})
export class UrlaStreetAddressComponent extends BaseUrlaInputComponent implements OnInit, OnDestroy {

  @Input()
  copyTitleText: string;

  @Input()
  copyButtonVisible: boolean = false;

  @Output()
  addressChanged: EventEmitter<Address> = new EventEmitter<Address>();

  @Output()
  checkboxChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onCopyClicked: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('control')
  protected control: NgModel;

  protected autocompleteInputKey: string = autoId();

  private _loanInfoChangesSubscription?: Subscription;

  constructor(
    private readonly _applicationContextService: ApplicationContextService,
  ) {
    super();
  }

  isTBDChecked: boolean = false;

  protected get isAddressInputDisabled(): boolean {
    return this.disabled || this.isTBDChecked;
  }

  protected get errors(): Errors {
    const fieldsConfig = this.urlaFieldsConfig ?? {};
    const fieldConfig = fieldsConfig[this.fieldBeingEdited] ?? {};

    const required = this.control?.errors?.required ?? false;

    return {
      required: required && fieldConfig?.required === 'required',
      requested: required && fieldConfig?.required === 'requested',
    };
  }

  ngOnInit(): void {
    this.id = autoId();

    this.subscribeToLoanInfoChanges();
  }

  ngOnDestroy(): void {
    this.checkAndRemoveGooglePlacesElements();
    this._loanInfoChangesSubscription?.unsubscribe();
  }

  private subscribeToLoanInfoChanges() {
    const loanInfoChanges$ = this._applicationContextService.loanInfoChanges;

    this._loanInfoChangesSubscription?.unsubscribe();
    this._loanInfoChangesSubscription = loanInfoChanges$.subscribe(() => {
      // Force Google Places API to reload the input element by recreating the component when loan
      // info changes.
      // This resolves an issue where the API stops working for persistent address input fields when
      // some other address input fields are recreated.
      this.autocompleteInputKey = autoId();
    });
  }

  override registerOnChange(fn: (_: string | null) => void): void {
    this.onChange = (value) => {
      if (this.hasTBDCheckbox && value && value.toLocaleLowerCase() === "tbd") {
        this.isTBDChecked = true;
      }
      fn(value);
    };
  }

  onBlurred = (e: any) => {
    let elements: any = $('.pac-item');
    const keys = Object.keys(elements);
    let needsEventTrigerring = true;
    keys.forEach(key => {
      if (!isNaN(key as any) && elements[key].matches(':hover')) {
        e.preventDefault();
        needsEventTrigerring = false;
        return;
      }
    })
    if (needsEventTrigerring) {
      this.blur.emit(e);
    }
  }

  onTBDCheckChanged = () => {
    this.checkboxChanged.emit(this.isTBDChecked);
  }

  protected handleAddressChange(e: Partial<Address>): void {
    setTimeout(() => {
      const address = Object.assign(
        new Address(),
        {
          address1: e.address1,
          city: e.city,
          state: e.state,
          zipCode: e.zipCode,
          county: e.county,
          country: e.country,
        },
      );

      this.addressChanged.emit(address);
    }, 200);
  }

  private checkAndRemoveGooglePlacesElements = (): void => {
    const elements: any = document.querySelectorAll('.pac-container');
    for (const e of elements) {
      e.remove();
    }
  }
}

interface Errors {
  required: boolean;
  requested: boolean;
}
