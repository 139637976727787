import { AfterViewInit, Component, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { UrlaCurrencyInputComponent } from '../../common/components/urla-currency-input/urla-currency-input.component';
import { SourceOfFundsDialogComponent } from './source-of-funds-dialog/source-of-funds-dialog.component';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { MortgageCalculationService } from '../../services/mortgage-calculation.service';
import { UrlaStreetAddressComponent } from '../../common/components/urla-street-address/urla-street-address.component';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { ZipCodeService } from 'src/app/services/zipcode.service';
import { CityCountyPickerDialogComponent } from '../../components/city-county-picker-dialog/city-county-picker-dialog.component';
import { CityCountyState } from 'src/app/models/city-county-state.model';
import { Address, MortgageBorrower, SubjectPropertyValuation } from 'src/app/models';
import { Observer, Subscription } from 'rxjs';
import { ZipCodePickerDialogComponent } from './zip-code-picker-dialog/zip-code-picker-dialog.component';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import { UrlaSubjectPropertyValuationComponent } from './urla-subject-property-valuation/urla-subject-property-valuation.component';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { groupBy } from 'lodash';
import { SubjectPropertyManufacturedHomeDetailsComponent } from './subject-property-manufactured-home-details/subject-property-manufactured-home-details.component';
import { MortgageService } from 'src/app/services/mortgage.service';
import { LoanPurposeTypeEnum } from '../../../app-details/components/title-history/models/title-order.model';
import { UtilityService } from '../../services/utility.service';
import { LoanAmountCalculatorDialogComponent } from 'src/app/modules/loan-amount-calculator/loan-amount-calculator-dialog/loan-amount-calculator-dialog.component';
import { MortgageCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'loan-property-info',
  templateUrl: 'loan-property-info.component.html',
  styleUrls: ['./loan-property-info.component.scss'],
  viewProviders: [formViewProvider]
})
export class LoanPropertyInfoComponent implements OnInit, AfterViewInit, OnDestroy {

  // Although there is a single of these on the page, we treat it as an array - so that we can react to it being taken out and put back on the page with *ngIf...
  @ViewChildren('sourceOfFundsValue') sourceOfFundsComponent: QueryList<UrlaCurrencyInputComponent> | undefined;

  @ViewChild('streetAddress')
  streetAddressComponent: UrlaStreetAddressComponent;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  hasApplicationReceivedKeyDate: boolean = false;

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  set mortgage(value: UrlaMortgage) {
    this._mortgage = value;
    if (value) {
      this._mortgage.calculatedStats.sourceOfFunds = this._calculationService.calculateSourceOfFundsValue(this._mortgage);
      this.onAppraisedValueChange(); // checking if aprraised value is null or not
      if (this._mortgage.mortgageTerm && this._mortgage.mortgageTerm.amount) {
        this._mortgage.mortgageTerm.amount = Math.floor(this._mortgage.mortgageTerm.amount);
      }
      this.purposeOfLoan = this.mortgage.subjectProperty.purposeOfLoan;
      this.isPurchase = this._calculationService.isPurposeOfLoanPurchase(this._mortgage);
      this.isRefi = this._calculationService.isPurposeOfLoanRefinance(this._mortgage);
      this.isFha = this._calculationService.isFhaLoan(this._mortgage);
      this.isConstruction = this._calculationService.isPurposeOfLoanConstructionToPerm(this._mortgage)
        || this._calculationService.isPurposeOfLoanConstructionOnly(this._mortgage);
    }
  }

  @Input()
  isSubjectPropertyAddressHidden: boolean;

  @Input()
  isAppraisedValueHidden: boolean;

  @Input()
  loanPurposes: EnumerationItem[];

  @Input()
  refinancePurposes: EnumerationItem[];

  @Input()
  refinancePrimaryPurposes: EnumerationItem[];

  @Input()
  propertyTypes: EnumerationItem[];

  @Input()
  yesNoOptions: EnumerationItem[];

  @Input()
  propertyConstructionMethods: EnumerationItem[];

  @Input()
  propertyConstructionStatuses: EnumerationItem[];

  @Input()
  purchaseCreditTypes: EnumerationItem[];

  @Input()
  downPaymentSourceTypes: EnumerationItem[];

  @Input()
  states: EnumerationItem[];

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  isTpoUser: boolean = false;

  @Output()
  loanPurposeChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loanAmountChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  presentValueChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dynamicDrawer')
  dynamicDrawer: DrawerComponent;

  loanPurposeTypeRefinance: string;

  isPurchase: boolean = false;

  isRefi: boolean = false;

  isConstruction: boolean = false;

  isFha: boolean = false;

  isTBDChecked: boolean = false;

  hasTBDCheckbox: boolean = false;

  isEntityTypeLLC: boolean = false;

  borrowerGroups: MortgageBorrower[][] = [];

  selectedBorrower: MortgageBorrower;

  appraisedValueTypeEnums: EnumerationItem[] = [];

  constructionLoanPurposeEnums: EnumerationItem[] = [];
  constructionToPermanentClosingTypeEnums: EnumerationItem[] = [];

  manufacturedEnumValue: string;
  mhAdvantageEnumValue: string;

  alphaNumericPattern = /^[a-zA-Z0-9\s]*$/;

  dynamicDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  };

  protected purposeOfLoan: string;

  private _sourceOfFundsEventSubscription: any = undefined;

  private _mortgage: UrlaMortgage;

  protected getBorrowerDisplayName: (borrower: MortgageBorrower) => string = () => '';

  private _loanPurposeSubscription: Subscription | null = null;
  private _sourceOfFundsComponentChangesSubscription: Subscription;
  private _dynamicEventSubscriptions: any[] = [];
  private _appliedForChangeSubscription: Subscription | null = null;
  private _entityTypeChangeSubscription: Subscription | null = null;

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _modalService: NgbModal,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _zipCodeService: ZipCodeService,
    private readonly _drawerService: DrawerService,
    private readonly _mortgageService: MortgageService,
    private readonly _utilityService: UtilityService,
    private readonly _spinner: NgxSpinnerService
  ) {
    this.subscribeToAppliedForChange();
    this.subscribeToEntityTypeChange();
  }

  ngOnInit() {
    this.getBorrowerDisplayName = this._utilityService.getBorrowerDisplayName;

    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.appraisedValueTypeEnums = enums.FHAVABorrowerCertificationSalesPriceExceedsAppraisedValueType;
      this.constructionLoanPurposeEnums = enums.ConstructionLoanPurposeType;
      this.constructionToPermanentClosingTypeEnums = enums.ConstructionToPermanentClosingType;
      this.mhAdvantageEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyConstructionMethod.MHAdvantage);
      this.manufacturedEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyConstructionMethod.Manufactured);
    });
    if (this._mortgage.subjectProperty) {
      if ((!this._mortgage.subjectProperty.county || !this._mortgage.subjectProperty.countyFips) && this._mortgage.subjectProperty.zipCode) {
        Swal.close();
        this.onZipcodeChanged();
      }
    }
    if (!this.selectedBorrower) {
      this.onBorrowerSelected(this._mortgage.borrowers[0], false);
    }
    this.adjustBorrowers(this.mortgage.borrowers);

    this.subscribeToLoanPurpose();

    this.isTBDChecked = this.mortgage?.subjectProperty?.address1.toLocaleLowerCase() === 'tbd';
  }

  ngAfterViewInit(): void {
    if (this.sourceOfFundsComponent) {
      this._sourceOfFundsComponentChangesSubscription = this.sourceOfFundsComponent.changes.subscribe((r) => {
        if (r._results && r._results.length > 0) {
          if (this._sourceOfFundsEventSubscription) {
            this._sourceOfFundsEventSubscription.unsubscribe();
          }
          this.subscribeToSourceOfFundsCalculatorEvents();
        }
      });

      this.subscribeToSourceOfFundsCalculatorEvents();
    }
  }

  ngOnDestroy(): void {
    if (this._sourceOfFundsComponentChangesSubscription) {
      this._sourceOfFundsComponentChangesSubscription.unsubscribe();
    }
    if (this._sourceOfFundsEventSubscription) {
      this._sourceOfFundsEventSubscription.unsubscribe();
    }
    this._dynamicEventSubscriptions.forEach(s => {
      s.unsubscribe();
    });

    this._loanPurposeSubscription?.unsubscribe();
    this._appliedForChangeSubscription?.unsubscribe();
    this._entityTypeChangeSubscription?.unsubscribe();
  }

  /**
   * Subscribes to the loan purpose changes in case the loan purpose is changed
   * from the outside of this component.
   * e.g. "Would you like to update the URLA to match?" dialog
   */
  private subscribeToLoanPurpose(): void {
    this._loanPurposeSubscription?.unsubscribe();

    const loanPurpose$ = this._mortgageService.loanPurpose$;
    this._loanPurposeSubscription = loanPurpose$.subscribe((loanPurpose) => {
      this.purposeOfLoan = loanPurpose;
      this.resetPurposeOfLoanProperties();
    });
    this._loanPurposeSubscription.add(() => {
      this._loanPurposeSubscription = null;
    });
  }

  onBorrowerSelected = (borrower: MortgageBorrower, isRedirection: boolean) => {
    this.selectedBorrower = borrower;
  }

  selectBorrowerTab = (borrower: MortgageBorrower) => {
    this.selectedBorrower = borrower;
  }

  isSelectedBorrower(borrower: MortgageBorrower): boolean {
    return this.selectedBorrower?.borrowerId === borrower.borrowerId;
  }

  isSalesPriceExceedsAppraisedValue = () => {
    return (this.mortgage.mortgageTerm.mortgageAppliedFor === 'VA' || this.mortgage.mortgageTerm.mortgageAppliedFor === 'FHA') &&
      this.isPurchase && this.mortgage.transactionDetail.purchasePriceAmount && this.mortgage.mortgageTerm.appraisedValue &&
      this.mortgage.transactionDetail.purchasePriceAmount > this.mortgage.mortgageTerm.appraisedValue;
  }

  onSubjectPropertyStreetAddressChanged = (address: Address) => {
    this.mortgage.subjectProperty.address1 = address.address1;
    this.mortgage.subjectProperty.city = address.city;
    this.mortgage.subjectProperty.zipCode = address.zipCode;
    this.mortgage.subjectProperty.state = address.state;
    this.mortgage.subjectProperty.county = address.county;
    this.onZipcodeChanged();
  }

  onSubjectPropertyCheckboxChanged = (isChecked: boolean) => {
    if (!isChecked) {
      this.hasTBDCheckbox = false;
      return;
    }
    const modalRef = this._modalService.open(ZipCodePickerDialogComponent, { ...Constants.modalOptions.medium, scrollable: false });
    modalRef.componentInstance.title = "Select Zip Code";
    const existingZipcode = new ZipCodeLookupResult();
    existingZipcode.zipcode = this.mortgage.subjectProperty.zipCode;
    modalRef.componentInstance.zipcodeInfo = existingZipcode;
    modalRef.result.then((zipcodeInfo: ZipCodeLookupResult) => {
      if (zipcodeInfo) {
        this.mortgage.subjectProperty.city = _.startCase(_.toLower(zipcodeInfo.city));
        this.mortgage.subjectProperty.zipCode = zipcodeInfo.zipcode;
        this.mortgage.subjectProperty.state = zipcodeInfo.state.toLowerCase();
        this.mortgage.subjectProperty.county = zipcodeInfo.county;
        this.mortgage.subjectProperty.country = 'us';
        this.mortgage.subjectProperty.address1 = 'TBD';
        this.hasTBDCheckbox = true;
      } else {
        this.streetAddressComponent.isTBDChecked = false;
      }
    }, err => { })
  }

  loanAmountChange = () => {
    this._spinner.show();
    const observer: Observer<MortgageCalculationDetails> = {
      next: (calculationDetails => {
        this.mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest =
          calculationDetails.proposedExpenses.firstMortgagePrincipalAndInterest;
        this._calculationService.calculateMortgageStatistics(this.mortgage);
        this.loanAmountChanged.emit(this.mortgage.mortgageTerm.amount);
      }),
      error: (() => {
      }),
      complete: (() => {
      })
    }
    this._mortgageService.redoMortgageCalculationDetails(this.mortgage).subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  };

  onPurchasePriceChanged = () => {
    this.mortgage.subjectProperty.presentValue = this.mortgage.transactionDetail.purchasePriceAmount ?? 0;
    this.presentValueChanged.emit(this.mortgage.subjectProperty.presentValue);
  }

  onPresentValueChanged = () => {
    this.presentValueChanged.emit(this.mortgage.subjectProperty.presentValue);
  }

  onZipcodeChanged = () => {
    if (!this.mortgage.subjectProperty.zipCode) {
      return;
    }
    this._zipCodeService.lookupZipCode(this.mortgage.subjectProperty.zipCode, false).subscribe(result => {
      if (result.length > 1) {
        const matchingResult = result.filter(r =>
          r.city && r.city.toLowerCase() === this.mortgage.subjectProperty.city?.toLowerCase() &&
          r.county && (r.county.toLowerCase() === this.mortgage.subjectProperty.county?.toLowerCase()
            || `${r.county.toLowerCase()} county` === this.mortgage.subjectProperty.county?.toLowerCase())
        );
        if (matchingResult.length === 1) {
          this.mortgage.subjectProperty.city = matchingResult[0].city;
          this.mortgage.subjectProperty.county = matchingResult[0].county;
          this.mortgage.subjectProperty.countyFips = matchingResult[0].countyFips;
          this.mortgage.subjectProperty.state = matchingResult[0].state.toLowerCase();
          return;
        }
        const modalRef = this._modalService.open(CityCountyPickerDialogComponent, Constants.modalOptions.medium);
        modalRef.componentInstance.title = "Pick a City/County for Subject Property";
        modalRef.componentInstance.optionsToPickFrom = result;
        modalRef.result.then((selectedCityAndCounty: CityCountyState) => {
          if (selectedCityAndCounty) {
            this.mortgage.subjectProperty.city = selectedCityAndCounty.city;
            this.mortgage.subjectProperty.county = selectedCityAndCounty.county;
            this.mortgage.subjectProperty.countyFips = selectedCityAndCounty.countyFips;
            this.mortgage.subjectProperty.state = selectedCityAndCounty.state.toLowerCase();

          }
        }, err => { })
      } else if (result.length === 1) {
        this.mortgage.subjectProperty.city = result[0].city;
        this.mortgage.subjectProperty.county = result[0].county;
        this.mortgage.subjectProperty.countyFips = result[0].countyFips;
        this.mortgage.subjectProperty.state = result[0].state.toLowerCase();
      }
    });
  }

  onSubjectPropertyValuationClicked = () => {
    const dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = UrlaSubjectPropertyValuationComponent;
    dynamicComponentInfo.parameters.set('subjectPropertyValuation', this.mortgage.subjectProperty.subjectPropertyValuation);
    dynamicComponentInfo.parameters.set('urlaFieldsConfig', this.urlaFieldsConfig);

    this._drawerService.show('dynamicDrawerUrlaPropertyInfo', 10, 'Property Valuation Details', dynamicComponentInfo, {
      ...this.dynamicDrawerOptions,
    }).then(() => {
      const subscription1 = this.dynamicDrawer.componentInstance.changesApproved.subscribe((newData: SubjectPropertyValuation) => {
        this.mortgage.subjectProperty.subjectPropertyValuation = newData;
        this._drawerService.hide("dynamicDrawerUrlaPropertyInfo");
      });
      this._dynamicEventSubscriptions.push(subscription1);
      const subscription2 = this.dynamicDrawer.componentInstance.cancelled.subscribe(() => {
        this._drawerService.hide("dynamicDrawerUrlaPropertyInfo");
      });
      this._dynamicEventSubscriptions.push(subscription2);
    });
  }

  onConstructionMethodChanged = () => {
    if (this.mortgage.subjectProperty.constructionMethod == "Other") {
      this.mortgage.subjectProperty.constructionMethodOtherDescription = undefined;
    }
  }

  onEditManufacturedHomeDetailsClicked = () => {
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = SubjectPropertyManufacturedHomeDetailsComponent;
    dynamicComponentInfo.parameters.set('subjectProperty', this.mortgage.subjectProperty);
    this._drawerService.show("dynamicDrawerUrlaPropertyInfo", 100, "Manufactured Home Details", dynamicComponentInfo).then(() => {
      const cancelledEventSubscription = this.dynamicDrawer.componentInstance.cancelled.subscribe(() => {
        this._drawerService.hide("dynamicDrawerUrlaPropertyInfo");
      });
      this._dynamicEventSubscriptions.push(cancelledEventSubscription);
      const changesApprovedEventSubscription = this.dynamicDrawer.componentInstance.changesApproved.subscribe((editedSubjectProperty) => {
        this._drawerService.hide("dynamicDrawerUrlaPropertyInfo");
        this.mortgage.subjectProperty = editedSubjectProperty;
      });
      this._dynamicEventSubscriptions.push(changesApprovedEventSubscription);
    });
  }

  private subscribeToAppliedForChange() {
    this._appliedForChangeSubscription?.unsubscribe();

    this._appliedForChangeSubscription = this._mortgageService.appliedFor$
      .subscribe((value) => {
        if (this._mortgage) {
          this.isFha = this._calculationService.isFhaLoan(this._mortgage);
          if (!this.isFha) {
            this._mortgage.subjectProperty.isFhaSecondaryResidence = null;
          }
        }
      });
  }

  private subscribeToEntityTypeChange() {
    this._entityTypeChangeSubscription?.unsubscribe();

    this._entityTypeChangeSubscription = this._mortgageService.enttiyTypeChanged$
      .subscribe((value) => {
        this.isEntityTypeLLC = value == 'LimitedLiabilityCorporation';
        if(this.isEntityTypeLLC){
          this.mortgage.subjectProperty.propertyWillBe = "Investment";
        }
      });
  }

  private showChangeConfirmationDialogForProperty(propertyName: string) {
    return Swal.fire({
      title: 'Are you sure?',
      text: `Changing ${propertyName} will reset your Lead Status to the
       beginning. Are you sure you want to do this?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      cancelButtonColor: '#DC3741',
      reverseButtons: true,
    });
  }

  private resetPurposeOfLoanProperties = () => {
    this.isPurchase = this._calculationService.isPurposeOfLoanPurchase(this._mortgage);
    this.isRefi = this._calculationService.isPurposeOfLoanRefinance(this._mortgage);
    this.isConstruction = this._calculationService.isPurposeOfLoanConstructionToPerm(this._mortgage)
      || this._calculationService.isPurposeOfLoanConstructionOnly(this._mortgage);
    if (!this.isPurchase) {
      if (this.mortgage.transactionDetail)
        this.mortgage.transactionDetail.purchasePriceAmount = null;
      this.mortgage.transactionDetail.sellerPaidClosingCostsAmount = null;
    }
    if (!this.isRefi) {
      this.mortgage.subjectProperty.originalCostYear = null;
      this.mortgage.subjectProperty.originalCost = null;
      this.mortgage.subjectProperty.amountExistingLiens = null;
      this.mortgage.subjectProperty.improvementCost = null;
      this.mortgage.subjectProperty.refiPurpose = null;
      this.mortgage.subjectProperty.refinancePrimaryPurpose = null;
      this.mortgage.subjectProperty.describeImprovement = null;
      this.mortgage.subjectProperty.improvementStatus = null;
      this.mortgage.calculatedStats.totalPaidOffForRefinance = 0;
      this.mortgage.calculatedStats.financialPartialPayoffTotalAmount = this._calculationService.calculateFinancialPartialPayoffTotalAmount(this.mortgage);
      this.mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this.mortgage);
      this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);
    }
    if (this.isRefi) {
      this.mortgage.calculatedStats.totalPaidOffForRefinance = this._calculationService.calculateTotalPayOffForRefinance(this.mortgage);
      this.mortgage.calculatedStats.financialPartialPayoffTotalAmount = this._calculationService.calculateFinancialPartialPayoffTotalAmount(this.mortgage);
      this.mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this.mortgage);
      this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);
    }
    this.loanPurposeChanged.emit();
  }

  protected onPurposeOfLoanChange = () => {
    this.showChangeConfirmationDialogForProperty('Purpose of Loan').then(result => {
      if (result.isConfirmed) {
        this.mortgage.subjectProperty.purposeOfLoan = this.purposeOfLoan;
        this.resetPurposeOfLoanProperties();
        this._mortgageService.loanPurpose =
          this.purposeOfLoan as LoanPurposeTypeEnum;
      } else {
        this.purposeOfLoan = this.mortgage.subjectProperty.purposeOfLoan;
      }
    });
  }

  onSourceOfFundsChanged = () => {
    this.mortgage.calculatedStats.sourceOfFunds = this._calculationService.calculateSourceOfFundsValue(this.mortgage);
  }

  onLoanAmountCalculatorClicked = () => {
    const modalRef = this._modalService.open(LoanAmountCalculatorDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.purchasePriceAmount = this.mortgage.transactionDetail.purchasePriceAmount;
    modalRef.componentInstance.loanAmount = this.mortgage.mortgageTerm.amount;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        this.mortgage.mortgageTerm.amount = result;
        this.loanAmountChange();
      }
    }, (err) => {
      console.log(err);
    });
  }

  onAppraisedValueChange = () => {
    if (this.mortgage?.subjectProperty && !this.mortgage.subjectProperty.presentValue) {
      this.mortgage.subjectProperty.presentValue = null;
    }
  }

  private openSourceOfFundsCalculator = () => {
    const modalRef = this._modalService.open(SourceOfFundsDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.data = this.mortgage;
    modalRef.componentInstance.purchaseCredits = this.mortgage.transactionDetail.purchaseCredits;
    modalRef.componentInstance.purchaseCreditTypes = this.purchaseCreditTypes;
    modalRef.componentInstance.downPaymentSourceTypes = this.downPaymentSourceTypes;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        this.mortgage.transactionDetail.purchaseCredits = result;
        this._calculationService.calculateMortgageStatistics(this.mortgage);
        this.onSourceOfFundsChanged();
      }

    }, (err) => {
      console.log(err);
    });
  }

  private subscribeToSourceOfFundsCalculatorEvents = () => {
    if (this.sourceOfFundsComponent) {
      const components: UrlaCurrencyInputComponent[] = this.sourceOfFundsComponent.toArray();
      components.forEach(item => {
        this._sourceOfFundsEventSubscription = item.calculatorClicked.subscribe(e => this.openSourceOfFundsCalculator());
      });
    }
  }

  private adjustBorrowers = (borrowers: MortgageBorrower[]) => {
    this.borrowerGroups = [];
    this.borrowerGroups = borrowers.length > 0 ? Object.values(groupBy(borrowers, 'printApplicationIndex')) : [];
  }
}
