
<form #militaryServiceForm="ngForm">
  <div
    *ngIf='mortgage.mortgageId != null'
    class='borrower-container'
  >
    <borrower-military-service
      *ngFor='let borrower of mortgage.borrowers'
      [borrower]='borrower'
      [inEditMode]='inEditMode'
      [isReadOnly]='isReadOnly'
      [urlaFieldsConfig]='urlaFieldsConfig'
      [mortgageAppliedFor]="mortgage.mortgageTerm.mortgageAppliedFor"
    ></borrower-military-service>
  </div>
</form>

<loading-indicator
  *ngIf='mortgage.mortgageId == null'
  [loadingMessage]="'Loading military service info...'"
></loading-indicator>
