import { Component, Injector, OnInit, Input } from '@angular/core';
import { Borrower, ApplicationContext, LoanApplication, LoanStatus } from 'src/app/models';
import { MortgageCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { Constants } from 'src/app/services/constants';
import { LoanService } from 'src/app/services/loan';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { MenuItemStatus } from '../../models/enums/menu-item-status.enum';

@Component({
  selector: 'tpo-loan-summary',
  templateUrl: 'tpo-loan-summary.component.html',
  styleUrls: ['./tpo-loan-summary.component.scss']
})
export class TpoLoanSummaryComponent extends ApplicationContextBoundComponent implements OnInit {

  application: LoanApplication;
  mortgage: UrlaMortgage;
  mortgageCalculationDetails: MortgageCalculationDetails;
  borrowers: Borrower[];

  loanStatus: LoanStatus;

  @Input()
  isTpoSubmitted: boolean = false;

  protected isCompanyPulseProcessing: boolean = false;

  private _applicationContextSubscription: any;

  constructor(private readonly injector: Injector,
    private readonly _loanService: LoanService) {
    super(injector);
  }

  ngOnInit() {
    this.loadLoanRelatedData(this.applicationContext);
    this._applicationContextSubscription =
      this.applicationContextService.loanInfoChanges.subscribe((context) => {
        this.loadLoanRelatedData(context);
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._applicationContextSubscription) {
      this._applicationContextSubscription.unsubscribe();
    }
  }

  private loadLoanRelatedData = (context: ApplicationContext) => {
    this.isCompanyPulseProcessing = context.isCompanyPulseProcessing;

    if (context.application) {
      this.application = context.application;
      this.mortgage = context.currentMortgage;
      this.mortgageCalculationDetails = context.currentMortgageCalculationDetails;
      const loanStatus = context.globalConfig.loanStatus.find(s => s.loanStatusId == this.application.loanStatusId);
      this.loanStatus = loanStatus;
      this._loanService.getMenuStatuses(this.application.applicationId).subscribe((result) => {
        const statusItem = result[Constants.menu.servicesMenuItems.submission];
        this.isTpoSubmitted = (statusItem && statusItem.toLowerCase() === MenuItemStatus.Success);
      }, (error) => { });
    }
    if (context.borrowers) {
      this.borrowers = context.borrowers;
    }
  };
}
