export class MortgageModelFieldsConfig {
  mortgage = {
    borrower: {
      firstName: RequiredState.Requested,
      middleName: RequiredState.None,
      lastName: RequiredState.Requested,
      nameSuffix: RequiredState.None,
      primaryEmail: RequiredState.Requested,
      mobilePhone: RequiredState.Requested,
      homePhone: RequiredState.Requested,
      socialSecNum: RequiredState.Requested,
      dateOfBirth: RequiredState.Requested,
      authorizationMethod: RequiredState.Requested,
      maritalStatus: RequiredState.Requested,
      unmarriedAddendum: RequiredState.Requested,
      domesticRelationshipType: RequiredState.Requested,
      unmarriedRelationshipState: RequiredState.Requested,
      dateAuthorizedCreditCheck: RequiredState.Requested,
      hasServedInMilitary: RequiredState.Requested,
      militaryStatus: RequiredState.Requested,
      militaryTourEndDate: RequiredState.Requested,
      fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType: RequiredState.Requested,

      alias: {
        firstName: RequiredState.Requested,
        middleName: RequiredState.None,
        lastName: RequiredState.Requested,
        nameSuffix: RequiredState.None
      },

      residencyAddress: {
        address1: RequiredState.Requested,
        address2: RequiredState.None,
        city: RequiredState.Requested,
        state: RequiredState.Requested,
        zipCode: RequiredState.Requested,
        country: RequiredState.Requested,
        residencyBasis: RequiredState.Requested,
        durationAtAddress: RequiredState.Requested,
        rent: RequiredState.Requested,
        isSubjectProperty: RequiredState.Requested
      },

      employment: {
        employer: RequiredState.Requested,
        position: RequiredState.None,
        startDate: RequiredState.Requested,
        endDate: RequiredState.Requested,
        address: {
          address1: RequiredState.Requested,
          address2: RequiredState.None,
          city: RequiredState.Requested,
          state: RequiredState.Requested,
          zipCode: RequiredState.Requested,
          country: RequiredState.Requested
        },
        employerPhone: RequiredState.Requested,
        yearsInLineOfWork: RequiredState.None,
        selfEmployed: RequiredState.None,
        borrowerOwnershipShare: RequiredState.Requested,
        selfEmploymentMonthlyIncomeOrLoss: RequiredState.Requested
      },

      declarations: {
        residenceStatus: RequiredState.Requested,
        occupyProperty: RequiredState.Requested,
        havePropertiesOwnership: RequiredState.Requested,
        typeOfProperty: RequiredState.Requested,
        heldTitleHow: RequiredState.Requested,
        haveRelationshipWithSeller: RequiredState.Requested,
        borrowedDownPayment: RequiredState.Requested,
        undisclosedMoneyAmount: RequiredState.None,
        applyingForOtherMortgage: RequiredState.Requested,
        applyingForNonMortgageCredit: RequiredState.Requested,
        propertyHasLienWithCleanEnergyProgram: RequiredState.Requested,
        coSignedLoan: RequiredState.Requested,
        haveJudgement: RequiredState.Requested,
        haveTaxDebt: RequiredState.Requested,
        haveLawsuit: RequiredState.Requested,
        haveConveyedTitleInLieuOfForeclosure: RequiredState.Requested,
        haveCompletedShortSale: RequiredState.Requested,
        beenForclosed: RequiredState.Requested,
        declaredBankruptcy: RequiredState.Requested,
        bankruptcyType: RequiredState.Requested,

      },

      governmentMonitors: {
        ethnicity: RequiredState.Requested,
        sex: RequiredState.Requested,
        race: RequiredState.Requested,
        applicationTakenMethod: RequiredState.Requested,
        isEthnicityBasedOnVisualOrSurname: RequiredState.Requested,
        isGenderBasedOnVisualOrSurname: RequiredState.Requested,
        isRaceBasedOnVisualOrSurname: RequiredState.Requested
      }

    },

    asset: {
      assetType: RequiredState.Requested,
      depository: RequiredState.Requested,
      accountNumber: RequiredState.Requested,
      cashMarketValue: RequiredState.Requested,
      giftOrGrantSource: RequiredState.Requested,
      isDeposited: RequiredState.Requested
    },

    liability: {
      typeOfLiability: RequiredState.Requested,
      holderName: RequiredState.Requested,
      accountNumber: RequiredState.Requested
    },

    realEstateOwned: {
      address1: RequiredState.Requested,
      city: RequiredState.Requested,
      state: RequiredState.Requested,
      zipCode: RequiredState.Requested,
      borrowerPicker: RequiredState.Requested,
      currentUsageType: RequiredState.Requested,
      dispositionStatus: RequiredState.Requested,
      intendedUsageType: RequiredState.Requested,
      isSubjectProperty: RequiredState.Requested,
      typeOfProperty: RequiredState.Requested,
      marketValue: RequiredState.Requested,
      monthlyMiscExpenses: RequiredState.Requested,
      percentOwned: RequiredState.None,
      vacancyFactor: RequiredState.None,
      grossRentalIncome: RequiredState.None,
      netRentalIncome: RequiredState.None,
    },

    mortgageTerm: {
      amount: RequiredState.Requested,
      appraisedValue: RequiredState.Requested,
      isMixedUseProperty: RequiredState.None,
      lenderCaseNumber: RequiredState.Requested,
      universalLoanNumber: RequiredState.Requested,
      agencyCaseNumber: RequiredState.Requested,
      mortgageAppliedFor: RequiredState.Requested,
      interestRate: RequiredState.None,
      noOfMonths: RequiredState.None,
      lienPosition: RequiredState.Requested,
      amortizationType: RequiredState.Requested,
      loanAmortizationPeriodType: RequiredState.None,
    },

    subjectProperty: {
      purposeOfLoan: RequiredState.Requested,
      refiPurpose: RequiredState.Requested,
      refinancePrimaryPurpose: RequiredState.Requested,
      propertyWillBe: RequiredState.Requested,
      address1: RequiredState.Requested,
      address2: RequiredState.None,
      city: RequiredState.Requested,
      state: RequiredState.Requested,
      zipCode: RequiredState.Requested,
      county: RequiredState.Requested,
      noOfUnits: RequiredState.Requested,
      constructionMethod: RequiredState.Requested,
      isFhaSecondaryResidence: RequiredState.Requested,
      presentValue: RequiredState.Requested,
      expectedGrossMonthlyRentalIncome: RequiredState.None,
      expectedNetMonthlyRentalIncome: RequiredState.None,
      refinanceProgram: RequiredState.Requested,
      constructionToPermanentClosingType: RequiredState.Requested,
      attachmentType: RequiredState.Requested,
      projectType: RequiredState.Requested,
      projectDesignType: RequiredState.Requested,
      propertyHasLienWithCleanEnergyProgram: RequiredState.Requested,
      improvementCost: RequiredState.None,
      lotAcquiredDate: RequiredState.None,
      lotOriginalCost: RequiredState.None,
      lotAppraisedValue: RequiredState.None,
      mannerTitleWillBeHeld: RequiredState.Requested,
      estate: RequiredState.Requested,
      leaseHoldExpirationDate: RequiredState.Requested,
      titleWillBeHeldNames: RequiredState.None,
      titleCurrentlyHeldInNames: RequiredState.Requested,
      trustClassification: RequiredState.None,
      nativeAmericanLandsType: RequiredState.None,
      communityLendingProductType: RequiredState.None,
      communitySecondsRepaymentType: RequiredState.None
    },

    relatedMortgage: {
      lienType: RequiredState.Requested,
      monthlyPayment: RequiredState.Requested,
      loanOrDrawAmount: RequiredState.Requested,
      creditLimit: RequiredState.Requested,
      isHeloc: RequiredState.Requested
    },

    originatorInformation: {
      organizationName: RequiredState.Requested,
      originatorFhaLenderId: RequiredState.None,
      originatorFhaSponsorId: RequiredState.None,
      organizationAddress1: RequiredState.Requested,
      organizationAddress2: RequiredState.None,
      organizationCity: RequiredState.Requested,
      organizationState: RequiredState.Requested,
      organizationZipCode: RequiredState.Requested,
      name: RequiredState.Requested,
      organizationNmls: RequiredState.Requested,
      stateLicense: RequiredState.Requested,
      email: RequiredState.Requested,
      phone: RequiredState.Requested,
      fax: RequiredState.None,
      nmls: RequiredState.None,
      stateLicenseState: RequiredState.None,
      organizationStateLicense: RequiredState.None,
      organizationStateLicenseState: RequiredState.None,
      organizationVaSponsorId: RequiredState.None,
      organizationVaLenderId: RequiredState.None,
      originatorVaLenderId: RequiredState.None,
      underwriterId: RequiredState.None,
    },
        
    proposedHousingExpense: {
      otherMortgageLoanPrincipalAndInterest: RequiredState.None,
      homeownersInsurance: RequiredState.None,
      supplementalPropertyInsurance: RequiredState.Requested,
      realEstateTax: RequiredState.Requested,
      homeownersAssociationDuesAndCondominiumFees: RequiredState.Requested,
      otherHousingExpense: RequiredState.None
    },

    extension: {
        balloonTermMonths: RequiredState.Requested,
        interestOnlyTermMonths: RequiredState.Requested,
        prepaymentPenaltyTermMonths: RequiredState.Requested,
        balloonPaymentAmount: RequiredState.None,

        buydown: {
            initialBuydownRate: RequiredState.Requested,
            buydownCalculationType: RequiredState.Requested,

            buydownScheduleItem: {
                adjustmentPercent: RequiredState.None,
                paymentCount: RequiredState.None
            }
        }
    },

    ausData: {
      negativeAmortizationType: RequiredState.Requested,
      armIndexSourceType: RequiredState.Requested,
      armIndexCurrent: RequiredState.Requested,
      armMarginRate: RequiredState.Requested,
      armQualifyingRate: RequiredState.Requested,
      firstPaymentAdjustmentMonths: RequiredState.Requested,
      subsequentPaymentAdjustmentMonths: RequiredState.Requested
    },

    governmentLoanDetail: {
      borrowerFundingFeePercent: RequiredState.None,
      refundProratedUnearnedUpFront: RequiredState.None,
      roundToNearest50Dollars: RequiredState.None,
      insuranceChargesIncludedInLoanPayments: RequiredState.None,
      insuranceChargesCollectedAtClosing: RequiredState.None,
      lenderPaidMortgageInsurance: RequiredState.None,
      borrowerHasPositiveRentalHistory: RequiredState.None,
      sectionOfActType: RequiredState.None,
      fhaMiPremiumRefundAmount: RequiredState.None,
      fhaAnnualPremiumPercent: RequiredState.None,
      fhaAssignmentDate: RequiredState.None,
      fhaEndorsementDate: RequiredState.None,
      fhaEnergyRelatedRepairsOrImprovementsAmount: RequiredState.None,
      fhaGeneralServicesAdministrationIdentifier: RequiredState.None,
      fhaInsuranceProgramType: RequiredState.None,
      fhaPremiumAnniversaryYearToDateRemittanceAmount: RequiredState.None,
      fhaRefinanceInterestOnExistingLienAmount: RequiredState.None,
      fhaRefinanceOriginalExistingFhaCaseIdentifier: RequiredState.None,
      fhaRefinanceOriginalExistingUpfrontMIPremiumAmount: RequiredState.None,
      fhaMIRenewalRatePercent: RequiredState.None,
      vaResidualIncomeAmount: RequiredState.None,
      vaEntitlementIdentifier: RequiredState.None,
    }

  };
}

export class MortgageFieldConfig {
  hidden: boolean = false;
  required: string = RequiredState.None;
  readonly: boolean = false;
  hiddenEnumOptions: string[] = [];

  constructor (required: string, hidden?: boolean, readonly?: boolean, hiddenEnumOptions?: string[]) {
    this.required = required;
    this.hidden = hidden ? hidden : false;
    this.readonly = readonly ? readonly : false;
    this.hiddenEnumOptions = hiddenEnumOptions ? hiddenEnumOptions : [];
  }
}

enum RequiredState {
  None = 'none',
  Requested = 'requested',
  Required = 'required'
}
