<div class="grid-layout has-border">
  <div class="grid-header">
    <div class="grid-header-left">
      <div>1a. Contact Info</div>
    </div>
    <div class="grid-header-right">
      <a (click)='onIdentifyingDocumentsDrawerToggled()'
        *ngIf='!isReadOnly && !inEditMode && !isTitleOnlyOrNonTitledSpouse' class='btn btn-sm btn-soft-primary me-2'
        role='button'>
        Identifying Documents
      </a>
      <a (click)='onAliasAdded()' *ngIf='!isReadOnly && !inEditMode && !isTitleOnlyOrNonTitledSpouse'
        class='btn btn-sm btn-soft-primary me-2' role='button'>
        <i class='fas fa-plus me-2'></i>

        Add Alternate Name
      </a>
    </div>
  </div>
</div>
<div class="grid-layout has-border">

  <div class="grid-layout-row" *ngIf="borrower.mortgagePartyType === 'Trust'">
    <!-- First Name -->
    <urla-text-input [(ngModel)]='borrower.trustName' [inEditMode]='inEditMode'
      [name]="'trustName' + borrower.borrowerId" [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.trustName")' [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6 col-sm-9' fieldBeingEdited='mortgage.borrower.trustName' label='Trust Name'></urla-text-input>

    <urla-date-input [(ngModel)]='borrower.trustDate' [inEditMode]='inEditMode'
      [name]="'trustDate' + borrower.borrowerId" [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.trustDate")' [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6 col-sm-3' fieldBeingEdited='mortgage.borrower.trustDate' label='Trust Date'></urla-date-input>
  </div>
  <div class="grid-layout-row">
    <!-- First Name -->
    <urla-text-input [(ngModel)]='borrower.firstName' [inEditMode]='inEditMode'
      [name]="'firstName' + borrower.borrowerId" [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.firstName")' [urlaFieldsConfig]='urlaFieldsConfig'
      class="col-8 col-sm-2" fieldBeingEdited='mortgage.borrower.firstName' label='First Name'></urla-text-input>

    <!-- Middle Name -->
    <urla-text-input [(ngModel)]='borrower.middleName' [inEditMode]='inEditMode'
      [name]="'middleName'  + borrower.borrowerId" [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.middleName")' [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6 col-sm-2' fieldBeingEdited='mortgage.borrower.middleName' label='Middle Name'></urla-text-input>

    <!-- Last Name -->
    <urla-text-input [(ngModel)]='borrower.lastName' [inEditMode]='inEditMode' [maxlength]='40'
      [name]="'lastName'  + borrower.borrowerId" [readonly]='isReadOnly'
      [required]="isRequired('mortgage.borrower.lastName')" [urlaFieldsConfig]='urlaFieldsConfig' class="col-8 col-sm-2"
      fieldBeingEdited='mortgage.borrower.lastName' label='Last Name'></urla-text-input>

    <!-- Name Suffix -->
    <urla-dropdown [(ngModel)]='borrower.nameSuffix' [inEditMode]='inEditMode' [name]="'suffix' + borrower.borrowerId"
      [options]='suffixes' [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.nameSuffix")'
      [urlaFieldsConfig]='urlaFieldsConfig' class='col-4 col-sm-2' fieldBeingEdited='mortgage.borrower.nameSuffix'
      label='Suffix'></urla-dropdown>

    <!-- Signing Role -->
    <urla-dropdown (change)='onSigningRoleChanged()' [(ngModel)]='borrower.signingRole' [inEditMode]='inEditMode'
      [name]="'signingRole' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='signingRoleOptions' [readonly]='isReadOnly' [required]="isRequired('mortgage.borrower.signingRole')"
      [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-2' fieldBeingEdited='mortgage.borrower.signingRole'
      label='Signing Role'></urla-dropdown>

    <urla-dropdown (change)='onMortgagePartyTypeChanged()' [(ngModel)]='borrower.mortgagePartyType'
      [inEditMode]='inEditMode' [name]="'mortgagePartyType' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='mortgagePartyTypeOptions' [readonly]='isReadOnly'
      [required]="isRequired('mortgage.borrower.mortgagePartyType')" [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6 col-sm-2' fieldBeingEdited='mortgage.borrower.mortgagePartyType'
      label='Borrower Type'>
    </urla-dropdown>
  </div>

  <div *ngIf="!isTitleOnlyOrNonTitledSpouse">
    <alias-info #aliasInfoComponents *ngFor='let alias of borrower.aliases' [alias]='alias' [inEditMode]='inEditMode'
      [isReadOnly]='isReadOnly' [urlaFieldsConfig]='urlaFieldsConfig'></alias-info>
  </div>

  <div class="grid-layout-row">
    <!-- Primary Email -->
    <urla-text-input [(ngModel)]='borrower.primaryEmail' [inEditMode]='inEditMode'
      [name]="'email' + borrower.borrowerId" [pattern]='emailPattern' [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.primaryEmail")' [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6 col-sm-3' fieldBeingEdited='mortgage.borrower.primaryEmail' label='E-mail'></urla-text-input>

    <!-- Secondary Email -->
    <urla-text-input [(ngModel)]='borrower.secondaryEmail' [inEditMode]='inEditMode'
      [name]="'secondaryEmail' + borrower.borrowerId" [pattern]='emailPattern' [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.secondaryEmail")' [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6 col-sm-3' fieldBeingEdited='mortgage.borrower.secondaryEmail'
      label='Secondary E-mail'></urla-text-input>

    <!-- Mobile Phone -->
    <urla-text-input [(ngModel)]='borrower.mobilePhone'
      [inEditMode]='inEditMode'
      [name]="'cellPhone' + borrower.borrowerId"
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.mobilePhone")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6 col-sm-3'
      fieldBeingEdited='mortgage.borrower.mobilePhone' label='Cell Phone' mask='(000) 000-0000'
      placeholder='(###) ###-####'>
    </urla-text-input>

    <!-- Home Phone -->
    <urla-text-input [(ngModel)]='borrower.homePhone'
      [inEditMode]='inEditMode'
      [name]="'homePhone' + borrower.borrowerId"
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.homePhone")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6 col-sm-3'
      fieldBeingEdited='mortgage.borrower.homePhone' label='Home Phone' mask='(000) 000-0000'
      placeholder='(###) ###-####'
      copyTitleText='Copy from mobile phone'
      [copyButtonVisible]='!!borrower.mobilePhone'
      (copyClicked)='onMobilePhoneCopy()' >
    </urla-text-input>
  </div>

  <div class="grid-layout-row">
    <!-- Social Security Number -->
    <urla-ssn-text-input *ngIf='!isSsnHidden || inEditMode' [(ngModel)]='borrower.socialSecNum'
      [inEditMode]='inEditMode' [name]="'ssn' + borrower.borrowerId" [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.socialSecNum")' [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6 col-sm-3' [ngClass]="{'col-sm-4': isTitleOnlyOrNonTitledSpouse}"
      fieldBeingEdited='mortgage.borrower.socialSecNum' label='Social Security #'></urla-ssn-text-input>

    <!-- Date of Birth -->
    <urla-date-input [(ngModel)]='borrower.dateOfBirth' [inEditMode]='inEditMode'
      [name]="'birthdate' + borrower.borrowerId" [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.dateOfBirth")' [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6 col-sm-3' [ngClass]="{'col-sm-4': isTitleOnlyOrNonTitledSpouse}"
      fieldBeingEdited='mortgage.borrower.dateOfBirth' label='Date of Birth'></urla-date-input>

    <!-- Residence Status -->
    <urla-dropdown *ngIf="!isTitleOnlyOrNonTitledSpouse" [(ngModel)]='borrower.declarations.residenceStatus'
      [inEditMode]='inEditMode' [name]="'residencyType' + borrower.borrowerId" [options]='residencyTypes'
      [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.declarations.residenceStatus")'
      [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-3'
      fieldBeingEdited='mortgage.borrower.declarations.residenceStatus' label='Citizenship'></urla-dropdown>

    <!-- Marital Status -->
    <urla-dropdown *ngIf="isTitleOnlyOrNonTitledSpouse" (ngModelChange)='onMaritalStatusChanged($event)'
      [(ngModel)]='borrower.maritalStatus' [inEditMode]='inEditMode' [name]="'maritalStatus' + borrower.borrowerId"
      [options]='maritalStatuses' [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.maritalStatus")'
      [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-4' fieldBeingEdited='mortgage.borrower.maritalStatus'
      label='Marital Status'></urla-dropdown>

    <!-- Language Preference Other Description -->
    <language-preferences *ngIf="!isTitleOnlyOrNonTitledSpouse" [name]="'languagePreference' + borrower.borrowerId"
      [options]='languagePreferenceOptions' [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-3'
      label='Prefer. Language?' [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.languagePreference")'
      fieldBeingEdited='mortgage.borrower.languagePreference' [(ngModel)]='borrower.languagePreference'
      [borrowerRefusesToProvideLanguagePreference]="borrower.hasRefusedToProvideLanguage"
      (ngModelChange)='onLanguagePreferenceChanged()'
      (refusalToProvideInfoDecisionChanged)="onRefusalToProvideInfoDecisionChanged($event)">
    </language-preferences>

  </div>

  <div class='grid-layout-row' *ngIf="!isTitleOnlyOrNonTitledSpouse">
    <borrower-credit-authorization [borrower]='borrower' [inEditMode]='inEditMode'
      [name]="'creditAuth' + borrower.borrowerId" [readonly]='isReadOnly || borrower.authorizationMethod === "Internet"'
      [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-3'
      (creditAuthorizationCheckChanged)="onCreditAuthorizationCheckChanged($event)"></borrower-credit-authorization>

    <!-- Authorized Credit Check -->
    <urla-dropdown *ngIf='borrower.authorizedCreditCheck || inEditMode' [(ngModel)]='borrower.authorizationMethod'
      [inEditMode]='inEditMode' [name]="'authMethod' + borrower.borrowerId" [options]='authorizationMethods'
      [readonly]='isReadOnly || borrower.authorizationMethod === "Internet"'
      [required]='!isTpo && isRequired("mortgage.borrower.authorizationMethod")' [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-12 col-sm-3' fieldBeingEdited='mortgage.borrower.authorizationMethod' label='Authorization Method'>
    </urla-dropdown>

    <!-- Spacer for Authorized Credit Check -->
    <div *ngIf='!borrower.authorizedCreditCheck  && !inEditMode' class='grid-layout-spacer col-6 col-sm-3'></div>

    <!-- Credit Reference Number -->
    <urla-text-input [(ngModel)]='borrower.creditRefNumber' [inEditMode]='inEditMode'
      [name]="'creditRefNumber' + borrower.borrowerId" [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.creditRefNumber") && !isCorrespondent'
      [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-3' fieldBeingEdited='mortgage.borrower.creditRefNumber'
      label='Credit Ref #'></urla-text-input>

    <!-- Credit Score -->
    <urla-digits-input class="col-6 col-sm-3"
      [allowedNegative]="false"
      [name]="'creditScore' + borrower.borrowerId"
      [(ngModel)]="borrower.creditScore"
      [readonly]='loanBorrower?.scoreFromCreditReport || isReadOnly'
      [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [required]='isRequired("mortgage.borrower.creditScore")'
      fieldBeingEdited='mortgage.borrower.creditScore'
      label='Credit Score'>
    </urla-digits-input>
  </div>

  <div class='grid-layout-row' *ngIf="!isTitleOnlyOrNonTitledSpouse">

    <urla-econsent class="col-6 col-sm-3" [borrower]='borrower'>
    </urla-econsent>

    <!-- Marital Status -->
    <urla-dropdown (ngModelChange)='onMaritalStatusChanged($event)' [(ngModel)]='borrower.maritalStatus'
      [inEditMode]='inEditMode' [name]="'maritalStatus' + borrower.borrowerId" [options]='maritalStatuses'
      [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.maritalStatus")'
      [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-3' fieldBeingEdited='mortgage.borrower.maritalStatus'
      label='Marital Status'></urla-dropdown>

    <!-- Dependent Count -->
    <urla-text-input (editClickEvent)='dependentEditClick()' [(ngModel)]='borrower.dependentCount'
      [hasEditButton]='!inEditMode' [name]="'dependents' + borrower.borrowerId" [readonly]='isReadOnly'
      class='col-6 col-sm-3' label='# of dependents'></urla-text-input>

    <!-- Dependent Ages -->
    <urla-text-input [(ngModel)]='dependentAges' [name]="'dependentAges' + borrower.borrowerId" [readonly]='isReadOnly'
      class='col-6 col-sm-3' disabled label='dependent ages'></urla-text-input>

    <urla-dropdown *ngIf="(borrower.mortgagePartyType == 'Individual' || borrower.mortgagePartyType == 'Entity') && isCompanyDeepHaven" 
        [(ngModel)]='borrower.entityVestingType'
        [inEditMode]='inEditMode' [name]="'entityVestingType' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
        [options]='entityVestingTypeOptions' [readonly]='isReadOnly'
        [required]="isRequired('mortgage.borrower.entityVestingType')" [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-sm-3' fieldBeingEdited='mortgage.borrower.entityVestingType'
        label='Entity Vesting Type'>
    </urla-dropdown>
  </div>

  <div class="grid-layout-row" *ngIf="(!isTitleOnlyOrNonTitledSpouse && isFhaBorrower) || inEditMode">
    <!-- FHA Borrower Certification Sales Price Amount -->
    <urla-currency-input class='col-6 col-sm-3' [(ngModel)]='borrower.fhaBorrowerCertificationSalesPriceAmount'
      [inEditMode]='inEditMode' [name]="'fhaBorrowerCertificationSalesPriceAmount' + borrower.borrowerId"
      [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.fhaBorrowerCertificationSalesPriceAmount")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.fhaBorrowerCertificationSalesPriceAmount'
      label='FHA Certification Sales Price'>
    </urla-currency-input>

    <!-- CAIVRS Identifier -->
    <urla-text-input class='col-sm-6 col-md-4' [(ngModel)]='borrower.caivrsIdentifier' [disabled]='isReadOnly'
      [inEditMode]='inEditMode' [name]="'fhaCaivrsIdentifier' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}" [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.caivrsIdentifier")' [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.caivrsIdentifier' label='CAIVRS Identifier'>
    </urla-text-input>
    <!-- Spacer for FHA Borrower Certification Sales Price Amount -->
    <div class='grid-layout-spacer col-6 col-sm-5'></div>
  </div>

  <ng-container *ngIf='(!isTitleOnlyOrNonTitledSpouse && borrower.maritalStatus == unmarriedEnumValue) || inEditMode'>
    <div class='grid-layout-row'>
      <urla-grid-cell [disabled]='isReadOnly' class='col-6 col-sm-5 grid-layout-text'>
        <span>
          Is there a person who is not your legal spouse, but who currently has
          real property rights similar to those of a legal spouse?
        </span>
      </urla-grid-cell>

      <!-- Unmarried Addendum -->
      <urla-radio-group (ngModelChange)='onUnmarriedAddendumChanged($event)' [(ngModel)]='unmarriedAddendum'
        [inEditMode]='inEditMode' [name]="'unmarriedAddendum' + borrower.borrowerId" [options]='yesNoOptions'
        [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.unmarriedAddendum")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-3'
        fieldBeingEdited='mortgage.borrower.unmarriedAddendum'></urla-radio-group>

      <!-- Domestic Relationship Type -->
      <urla-dropdown (ngModelChange)='onDomesticRelationshipTypeChanged($event)' *ngIf='unmarriedAddendum || inEditMode'
        [(ngModel)]='borrower.domesticRelationshipType' [inEditMode]='inEditMode'
        [name]="'domesticRelationshipType' + borrower.borrowerId" [options]='domesticRelationshipTypes'
        [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.domesticRelationshipType")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-2'
        fieldBeingEdited='mortgage.borrower.domesticRelationshipType' label='Relationship Type'></urla-dropdown>

      <!-- Domestic Relationship State -->
      <urla-state-dropdown *ngIf='unmarriedAddendum || inEditMode' [(ngModel)]='borrower.unmarriedRelationshipState'
        [inEditMode]='inEditMode' [name]="'domesticRelationshipState' + borrower.borrowerId" [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.unmarriedRelationshipState")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-sm-2' fieldBeingEdited='mortgage.borrower.unmarriedRelationshipState'
        label='Relationship State'></urla-state-dropdown>

      <!-- Spacer for Domestic Relationship State -->
      <div *ngIf='!unmarriedAddendum && !inEditMode' class='grid-layout-spacer col-12 col-sm-4'>&nbsp;</div>
    </div>

    <div *ngIf='borrower.domesticRelationshipType === DomesticRelationshipTypeEnum.Other' class='grid-layout-row'>
      <!-- Unmarried Relationship Type Other -->
      <urla-text-input [(ngModel)]='borrower.unmarriedRelationshipTypeOther' [inEditMode]='inEditMode'
        [name]="'unmarriedRelationshipTypeOther' + borrower.borrowerId" [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.unmarriedRelationshipTypeOther")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-3'
        fieldBeingEdited='mortgage.borrower.unmarriedRelationshipTypeOther'
        label='Specify Relationship Type'></urla-text-input>

      <div class='grid-layout-spacer col-6 col-sm-9'></div>
    </div>
  </ng-container>
</div>

<drawer [options]='identifyingDocumentsDrawerOptions' [templateRef]='identifyingDocumentsContentRef3'
  name='identifyingDocumentsDrawer3' title='Identifying Documents'></drawer>

<ng-template #identifyingDocumentsContentRef3>
  <identifying-documents [selectedBorrower]="borrower"></identifying-documents>
</ng-template>
