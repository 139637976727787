import {MonoTypeOperatorFunction, Observable} from 'rxjs';

/**
 * Emits a value from the source observable only if the value is different from
 * the last emitted value from the source observable or any of the other
 * sources.
 * @remarks This operator works similarly to the `distinctUntilChanged`
 * operator, but it also compares the emitted values from the other sources.
 * @template T - The type of the emitted values.
 * @param {Observable<T>[]} otherSources - The other sources to compare the
 * emitted values with.
 * @param {(a: T, b: T) => boolean} [comparator] - The function to compare the
 * emitted values. If not provided, the strict equality operator (`===`) is
 * used.
 */
export function distinctUntilChangedWith<T>(
  otherSources: Observable<T>[],
  comparator?: (a: T, b: T) => boolean,
): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) =>
    new Observable<T>(subscriber => {
      let lastEmittedValue: T | undefined;

      const subscriptions = [source, ...otherSources].map(otherSource =>
        otherSource.subscribe({
          next(value) {
            if (
              comparator?.(value, lastEmittedValue) ??
              value !== lastEmittedValue
            ) {
              lastEmittedValue = value;
              subscriber.next(value);
            }
          },
          error(error) {
            subscriber.error(error);
          },
          complete() {
            subscriber.complete();
          },
        }),
      );

      return () => {
        subscriptions.forEach(subscription => subscription.unsubscribe());
      };
    });
}
