<section
  [class.mt-0]='isCollapsible'
  [class.my-3]='!isCollapsible'
  class='col-lg-12'
>
  <div class='row align-items-baseline'>
    <div
      class='d-flex col-auto row flex-grow-1 align-items-center ps-2 pe-0'
      *ngIf='!isCollapsible; else collapsibleHeader'
    >
      <ng-container *ngTemplateOutlet='header'></ng-container>
    </div>

    <ng-template #collapsibleHeader>
      <button
        class='btn d-flex col-auto row flex-grow-1 align-items-center ps-2 pe-0
         py-3'
        [attr.data-bs-target]='"#" + contentWrapperId'
        data-bs-toggle='collapse'
        #collapseButton
      >
        <ng-container *ngTemplateOutlet='header'></ng-container>
      </button>
    </ng-template>

    <!-- Spacer for Edit Button -->
    <div class='col-lg-1 text-end'>
      <button
        (click)='edit.emit()'
        *ngIf='isEditable'
        class='btn btn-link px-0'
        type='button'
      >
        <i class='fas fa-pencil-alt me-1'></i>

        Edit
      </button>
    </div>
  </div>

  <ng-container *ngIf='!isCollapsible'>
    <ng-container *ngTemplateOutlet='content'></ng-container>
  </ng-container>

  <div
    [id]='contentWrapperId'
    class='collapse ps-3 mb-0'
    [class.show]='!isCollapsed'
  >
    <ng-container *ngTemplateOutlet='content'></ng-container>
  </div>
</section>

<ng-template #header>
  <h5 class='col-auto mb-0'>
    {{ title }}

    <!-- Collapse Icon -->
    <ng-container *ngIf='isCollapsible'>
      <i
        class='fas font-12 ms-1'
        *ngLet='collapsed as isCollapsed'
        [ngClass]='{
                "fa-chevron-down": !isCollapsed,
                "fa-chevron-up": isCollapsed
              }'
      ></i>
    </ng-container>
  </h5>

  <!-- Subtitle -->
  <ng-container *ngIf='templateSubtitle'>
    <div class='col-auto mb-0 ms-2'>
      <ng-container *ngTemplateOutlet='templateSubtitle'></ng-container>
    </div>
  </ng-container>

  <!-- Filler -->
  <div class='col-auto flex-grow-1'></div>

  <!-- Value -->
  <b class='col-auto' *ngIf='value'>
    {{ value }}
  </b>
</ng-template>

<!-- Wrap the content in a template to be able to use it twice -->
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
