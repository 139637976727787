<div class="modal-header">
  <h5 class="modal-title"> {{title == null ? '' : title}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
      <h3 class="col-md-12 text-center"> {{message}} </h3>
  </div>
  <div class="row">
    <file-upload [(ngModel)]="uploadedFiles" multiple="multiple" name="fileUpload" [accept]="accept"></file-upload>
  </div>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
          <button type="button" class="btn btn-link" (click)="activeModal.close('')" >Cancel</button>
          <input type="button" [disabled]="!uploadedFiles || (uploadedFiles && uploadedFiles.length === 0)" value="Import" (click)="activeModal.close(uploadedFiles)" class="btn btn-primary">
      </div>
    </div>
  </div>
</div>
