<div class="multi-borrower-fico">
  <div class="d-flex justify-content-between">
    <h4 class="card-title">
      <strong> History </strong>
    </h4>
  </div>
  <hr>
  <div class="row">
    <div class="col-md-12">
      <p-table *ngIf="!isLoading; else loading" [value]="histories" responsiveLayout="scroll" [resizableColumns]="true">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-start"> Borrower </th>
            <th> Consent Type </th>
            <th> Event Type </th>
            <th> Client Ip Address </th>
            <th> Date </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-history>
          <tr>
            <td class="text-start"> {{borrowerFullName}} </td>
            <td>{{history.consentType ? history.consentType : '--' }}</td>
            <td>{{history.eventType ? history.eventType : '--'}}</td>
            <td>{{history.clientIpAddress ? history.clientIpAddress : '--'}}</td>
            <td>{{history.clientIpAddress ? (history.dateInserted | date: 'short') : '--'}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="5" class="text-center">
              There is no history information.
            </td>
          </tr>
        </ng-template>
      </p-table>

      <ng-template #loading>
        <loading-indicator></loading-indicator>
      </ng-template>
    </div>
  </div>
</div>