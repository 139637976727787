<div class="modal-header">
    <h5 class="modal-title"> {{title}} </h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body" id="copyAppDialog" *ngIf="copyFormApp">
    <div class="row">
        <!-- <div *ngIf="borrowerNamesWithMissingSSN.length > 0" class="alert alert-danger mt-2">
            <span> All borrowers must have a social security number to copy an application.
                <br /><br />Borrowers missing SSN:
            </span>
            <ul>
                <li *ngFor="let item of borrowerNamesWithMissingSSN"> {{item}} </li>
            </ul>
        </div> -->
        <h5> Please select any loan docs you want to be copied to the new file. </h5>
        <div class="form-check checkbox form-check-inline pointer mt-2">
            <input type="checkbox" id="selectAll" (click)="onSelectAllClicked(isSelectAllClicked)"
                [(ngModel)]="isSelectAllClicked">
            <label for="selectAll" class="pointer"> Select All </label>
        </div>
        <div class="accordionCopyApp" id="accordionCopyApp" *ngIf="loanDocs.length > 0">
            <ng-container *ngFor="let document of documentTypes">
                <copy-application-accordion *ngIf="showDoctype(document)" [isSelectAllClicked]="isSelectAllClicked"
                    [document]="document" [loanDocs]="loanDocs">
                </copy-application-accordion>
            </ng-container>
        </div>
        <div class="col-md-12">
            <div class="row mt-4">
                <label class="col-md-2 col-form-label" for="loantype"> Loan Type </label>
                <div class="col-md-4">
                    <select class="form-select " id="loantype" name="loantype" [(ngModel)]="copyFormApp.loanTypeId">
                        <option [ngValue]="null"> --Select One-- </option>
                        <option *ngFor="let option of loanTypes" value="{{option.loanTypeId}}">
                            {{option.loanTypeName}} </option>
                    </select>
                </div>
                <label class="col-md-2 col-form-label" for="loanPurpose"> Loan Purpose </label>
                <div class="col-md-4">
                    <select class="form-select " id="loanPurpose" name="loanPurpose"
                        [(ngModel)]="copyFormApp.loanPurposeId">
                        <option [ngValue]="null"> --Select One-- </option>
                        <option *ngFor="let option of loanPurposes" value="{{option.loanPurposeId}}">
                            {{option.loanPurposeName}} </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row mt-4">
                <label class="col-md-2 col-form-label" for="loantype"> Copy Reason </label>
                <div class="col-md-4">
                    <select class="form-select " id="loantype" name="loantype" [(ngModel)]="copyFormApp.copyReason">
                        <option value=""> --Select One-- </option>
                        <option value="Reorigination"> Re-Origination </option>
                        <option value="ChangeLender"> Change Lender </option>
                        <option value="Other"> Other </option>
                    </select>
                </div>
                <label class="col-md-2 col-form-label" for="alertUserOfNewFile"> Alert LO of New File </label>
                <div class="col-md-4">
                    <select class="form-select " id="alertUserOfNewFile" name="alertUserOfNewFile" 
                        [(ngModel)]="copyFormApp.alertUserOfNewFile">
                        <option [ngValue]="false"> No </option>
                        <option [ngValue]="true"> Yes </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()"> Cancel </button>
  <button class="btn btn-primary me-1" type="button" [disabled]="disableNextButton"
    (click)="onNextClicked()">
    <span *ngIf="disableNextButton" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {{ disableNextButton ? 'Please wait...' : 'Next' }}
  </button>
</div>
