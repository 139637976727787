<div class="form-input" *ngIf="options && !inEditMode" [ngClass]="{
  'ng-invalid-required' : required && value == null && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required' ,
  'ng-invalid-requested' : required && value == null && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
  'bg-transparent' : disabled || readonly
}"
  [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden">
    <span class="input-label" title="" *ngIf="label && label.length > 0">
      <span class="input-label-overflow">{{label}}</span>
    </span>
    <div class="radio-group radio-group-container"
      [ngClass]="{'radio-group-vertical': layout == 'vertical', 'radio-group-horizontal': layout == 'horizontal'}">
      <label class="radio-group-item" *ngFor="let option of options" [for]="name">
        <input
          type="radio"
          name="{{name}}"
          [id]="name + '_' + option.value"
          [(ngModel)]="value"
          [value]="option.value"
          [required]="required"
          [disabled]="disabled ? true : null"
          [readonly]="readonly ? true : null"/>
          {{option.name}}
      </label>
      <div *ngIf="readonly" class="disabled-overlay"></div>
    </div>
</div>

<edit-mode-input *ngIf="inEditMode" 
  [label]="label" 
  [name]="name" 
  [urlaFieldsConfig]="urlaFieldsConfig" 
  [fieldBeingEdited]="fieldBeingEdited" 
  [showBorder]="showBorder">
</edit-mode-input>

<!-- <div *ngIf="inEditMode" class="form-input"
    [ngClass]="
    { 'ng-invalid-requested' : urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
      'ng-invalid-required' : urlaFieldsConfig[fieldBeingEdited]?.required == 'required'
    }"
  >
    <span class="input-label" style="display: inline;" title="" *ngIf="label && label.length > 0">
      <span class="input-label-overflow">{{label}}</span>
    </span>
    <select class="form-select" [(ngModel)]="urlaFieldsConfig[fieldBeingEdited].required" name="{{name}}_Config"
      [ngClass]="
        { 'ng-invalid-requested' : urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
          'ng-invalid-required' : urlaFieldsConfig[fieldBeingEdited]?.required == 'required'
        }"
      >
        <option value="none">None</option>
        <option value="requested">Requested</option>
        <option value="required">Required</option>
    </select>
  </div> -->

