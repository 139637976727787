import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { SubjectPropertyTaxesAndInsuraceDetails } from '../urla-main.component';
import { EscrowDetail, HazardInsuranceDetail } from 'src/app/models';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'subject-property-taxes-insurance',
  templateUrl: 'subject-property-taxes-insurance.component.html',
  styleUrls: ['subject-property-taxes-insurance.component.scss']
})

export class SubjectPropertyTaxesInsuranceComponent implements OnInit {

  @ViewChild('newCoverageForm')
  newCoverageForm: NgForm | undefined;

  @Input()
  set subjectPropertyTaxesAndInsuranceDetails(subjectPropertyTaxesAndInsuranceDetails: SubjectPropertyTaxesAndInsuraceDetails) {
    this._subjectPropertyTaxesAndInsuranceDetails = cloneDeep(subjectPropertyTaxesAndInsuranceDetails);
  }

  get subjectPropertyTaxesAndInsuranceDetails(): SubjectPropertyTaxesAndInsuraceDetails {
    return this._subjectPropertyTaxesAndInsuranceDetails;
  }

  @Input()
  hasSaveCancelButtons: boolean = true;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApproved: EventEmitter<SubjectPropertyTaxesAndInsuraceDetails> = new EventEmitter<SubjectPropertyTaxesAndInsuraceDetails>();

  selectedType: string;
  protected originalSelectedType: string;

  protected hazardInsuranceCoverageTypes: EnumerationItem[] = [];
  private _subjectPropertyTaxesAndInsuranceDetails: SubjectPropertyTaxesAndInsuraceDetails;
  private _copyOfhazardInsuranceCoverageTypes: EnumerationItem[];

  constructor(private readonly _enumerationService: EnumerationService) {
    this._enumerationService.getMortgageEnumerations().subscribe(enums => {
      this.hazardInsuranceCoverageTypes = enums[Constants.mortgageEnumerations.hazardInsuranceCoverageType];
    })
  }

  ngOnInit() {
    this._copyOfhazardInsuranceCoverageTypes = cloneDeep(this.hazardInsuranceCoverageTypes);
    this.checkExistingHazardInsuranceCoverageTypes();

    if (!this.subjectPropertyTaxesAndInsuranceDetails.escrowDetails) {
      this.subjectPropertyTaxesAndInsuranceDetails.escrowDetails = new EscrowDetail();
    }
    if (!this.subjectPropertyTaxesAndInsuranceDetails.subjectProperty.hazardInsuranceDetails) {
      this.subjectPropertyTaxesAndInsuranceDetails.subjectProperty.hazardInsuranceDetails = [];
    }
  }

  onSaveClicked = () => {
    this.changesApproved.emit(this._subjectPropertyTaxesAndInsuranceDetails);
  }

  addNewHazardInsuranceDetail = () => {
    const detail = new HazardInsuranceDetail();
    this.subjectPropertyTaxesAndInsuranceDetails.subjectProperty.hazardInsuranceDetails.push(detail);
  }

  onAddNew = (pop: any) => {
    if (!this.newCoverageForm) return;

    this.newCoverageForm.form.markAllAsTouched();
    if (!this.newCoverageForm.form.valid) return;

    this.originalSelectedType = this.selectedType;
    let newHazarInsuranceDetail = new HazardInsuranceDetail();
    newHazarInsuranceDetail.coverageType = this.selectedType;
    this.subjectPropertyTaxesAndInsuranceDetails.subjectProperty?.hazardInsuranceDetails.push(newHazarInsuranceDetail);

    const selectedType = this.hazardInsuranceCoverageTypes.findIndex(type => type.value === this.selectedType);
    this.hazardInsuranceCoverageTypes.splice(selectedType, 1);
    this.selectedType = null;
    pop.hide();
  }

  onCancel = (pop: any) => {
    this.selectedType = null;
    pop.hide();
  }

  onAttemptedToDeleteDetailClicked = (detail: HazardInsuranceDetail) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this detail?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        const index = self.subjectPropertyTaxesAndInsuranceDetails.subjectProperty.hazardInsuranceDetails.indexOf(detail);
        if (index >= 0) {
          self.subjectPropertyTaxesAndInsuranceDetails.subjectProperty.hazardInsuranceDetails.splice(index, 1);
        }
      }
    });
  }

  private checkExistingHazardInsuranceCoverageTypes = () => {
    if (this.subjectPropertyTaxesAndInsuranceDetails.subjectProperty?.hazardInsuranceDetails.length > 0) {
      this.hazardInsuranceCoverageTypes = [...this._copyOfhazardInsuranceCoverageTypes];
      this.subjectPropertyTaxesAndInsuranceDetails.subjectProperty?.hazardInsuranceDetails.forEach(insuranceDetail => {
        if (insuranceDetail.coverageType) {
          const selectedTypeIndex = this.hazardInsuranceCoverageTypes.findIndex(type => type.value === insuranceDetail.coverageType);
          if (selectedTypeIndex >= 0) {
            this.hazardInsuranceCoverageTypes.splice(selectedTypeIndex, 1);
          }
        }
      })
    }
  }
}