import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ApplicationMenu } from '../services/application-menu';

export class Role {
  readonly roleId: number;
  userRole?: UserRoleEnum | '';
  roleName: string;
  isLoanContact?: boolean;
  order?: number;
  useForCreditMonitoring?: boolean;
  tpoEnabled?: boolean;
  menuConfig: ApplicationMenu = new ApplicationMenu();
  roleChannels?: RoleChannel[];
  channelsCSV?: string;
  allowAccessAllLoans?: boolean;
  allowBorrowerAssignment?: boolean;
  allowCopyLoan?: boolean;
  allowExportLeadsToCsv?: boolean;
  bulkEditTasks?: boolean;
  readOnlyTasks?: boolean;
  restrictAlignmentChanges?: boolean;
  restrictFromEditingProfile?: boolean;
  disableCreditMonitoring?: boolean;
  showOnPortals?: boolean;
  showNewSubmissionForm?: boolean;
  allowDeleteTask?: boolean;
  allowCancelEsign?: boolean;
  disablePullVOE?: boolean;
  allowMortgageCallReports?: boolean;
  defaultDashboardConfigId?: number;
  readonly companyId?: number;
  readonly insertedBy?: string;
  readonly dateInserted?: string;
  readonly updatedBy?: string;
  readonly dateUpdated?: string;
}

export interface RoleChannel {
  readonly roleChannelId: number;
  roleId: number;
  readonly channel: string;
  readonly order?: number;
  disableAlignmentChanges: boolean;
  disableEditLoan: boolean;
  readonly roleNameOverride?: string;
  isVisibleOnTpo: boolean;
  readonly companyId: number;
  readonly insertedBy?: string;
  readonly dateInserted?: string;
  readonly updatedBy?: string;
  readonly dateUpdated?: string;
}

export interface RoleChannelCheckboxItem {
  name: string;
  isChecked: boolean;
  options: Array<RoleChannelPropertyOption>;
}

export interface RoleChannelPropertyOption extends EnumerationItem {
  isChecked?: boolean;
  isDisabled?: boolean;
  isVisible?: boolean;
}

export enum UserRoleEnum {
  Unknown = 'Unknown',
  FileStarter = 'FileStarter',
  Intake = 'Intake',
  LoanOfficer = 'LoanOfficer',
  TPO = 'TPO',
  TPOProcessor = 'TPOProcessor',
  Processor = 'Processor',
  Underwriter = 'Underwriter',
  Closer = 'Closer',
  Funder = 'Funder',
  Shipper = 'Shipper',
  PostCloser = 'PostCloser'
}

export type RoleList = Array<Role>;
export type RoleChannelList = Array<RoleChannel>;
