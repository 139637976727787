import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HazardInsuranceDetail } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'hazard-insurance-details',
  templateUrl: 'hazard-insurance-details.component.html',
  styleUrls: ['hazard-insurance-details.component.scss']
})

export class HazardInsuranceDetailsComponent implements OnInit {

  @Output()
  attemptedToDeleteDetail: EventEmitter<HazardInsuranceDetail> = new EventEmitter<HazardInsuranceDetail>();

  @Input()
  hazardInsuranceDetail: HazardInsuranceDetail;

  @Input()
  selectedType: string = '-';

  @Input()
  index: number;

  protected mouseCursorOnDeleteButton: boolean = false;
  protected expirationDate: string;
  protected nextPremiumDueDate: string;
  protected policyCancellationDate: string;

  constructor() { }

  ngOnInit() {
    this.checkExistingDates();
  }

  onDeleteClicked = () => {
    this.attemptedToDeleteDetail.emit(this.hazardInsuranceDetail);
  }

  protected onDateChanged = () => {
    if (this.expirationDate) {
      this.hazardInsuranceDetail.expirationDate = new Date(this.expirationDate).toISOString();
    }
    if (this.nextPremiumDueDate) {
      this.hazardInsuranceDetail.nextPremiumDueDate = new Date(this.nextPremiumDueDate).toISOString();
    }
    if (this.policyCancellationDate) {
      this.hazardInsuranceDetail.policyCancellationDate = new Date(this.policyCancellationDate).toISOString();
    }
  }

  private checkExistingDates = () => {
    if (this.hazardInsuranceDetail.expirationDate) {
      this.expirationDate = new Date(this.hazardInsuranceDetail.expirationDate).toLocaleDateString("en-US");
    }
    if (this.hazardInsuranceDetail.nextPremiumDueDate) {
      this.nextPremiumDueDate = new Date(this.hazardInsuranceDetail.nextPremiumDueDate).toLocaleDateString("en-US");
    }
    if (this.hazardInsuranceDetail.policyCancellationDate) {
      this.policyCancellationDate = new Date(this.hazardInsuranceDetail.policyCancellationDate).toLocaleDateString("en-US");
    }
  }
}