import { Component } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { YearsMonthsDuration } from '../../../models/years-months-duration.model';
import { BaseUrlaInputComponent } from '../base-urla-input.component';

@Component({
  selector: 'urla-months-years-duration',
  templateUrl: 'urla-months-years-duration.component.html',
  providers: [MakeProvider(UrlaMonthsYearsDurationComponent)]
})

export class UrlaMonthsYearsDurationComponent extends BaseUrlaInputComponent {

  years: number;
  months: number;

  writeValue(value: any) {
    if (value) {
      this.years = value.years;
      this.months = value.months;
    }
    super.writeValue(value);
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v) {
      this.years = v.years;
      this.months = v.months;
    }
    this.doSetValue(v);
  }

  onYearsChanged = () => {
    this.onAfterValueChanged();
  }

  onMonthsChanged = () => {
    if (this.years == undefined) {
      this.years = 0;
    }
    if (this.months == undefined) {
      this.months = 0;
    }

    this.onAfterValueChanged();
  }

  onAfterValueChanged = () => {
    if (!this.years && !this.months) {
      this.writeValue(null);
    } else {
      const value = new YearsMonthsDuration(this.years, this.months);
      this.writeValue(value);
    }
  }
}
