export enum FiAccountEnum {}

export interface LiteAccountInfo {
  readonly accountId: string;
  accountType: string; // AssetType enum
  AccountName: string;
  AccountNumber: string;
  AccountHolder: string;
  FiName: string;
  FiAccountType: string;
  FiPlanName: string;
  Balance: number;
  BalanceDate: string;
}

export type LiteAccountInfoReadonlyArray = ReadonlyArray<LiteAccountInfo>;
