<div class='leading'>
  <i class='fas fa-exclamation-circle font-12 pe-0'></i>

  <b>Note:</b>
</div>

<!-- Warning message -->
<div class='warning-message'>
  <ng-content></ng-content>
</div>
