<form id="checkListItemForm" #checkListItemForm="ngForm" name="checkListItemForm">
  <div class="p-3">
    <div class="mb-3">
      <label class="control-label" for="checkListName">Checklist Name</label>
      <input id="checkListName" name="checkListName" class="form-control" [(ngModel)]="checkListItemData.checkListName"
        [ngClass]="{'is-invalid': checkListNameInput && checkListNameInput.touched && checkListNameInput.invalid}"
        #checkListNameInput="ngModel" required />
    </div>
    <div class="mb-3">
      <label class="control-label" for="loanStatus">Loan Status</label>
      <select id="loanStatus" name="loanStatus" class="form-select" [(ngModel)]="checkListItemData.loanStatusId">
        <option [ngValue]="null">-- Select One --</option>
        <option *ngFor="let status of loanStatuses" [ngValue]="status.loanStatusId">
          {{ status.loanStatusName }}
        </option>
      </select>
    </div>
  </div>
</form>

<div class="text-end">
  <button type="button" class="btn btn-secondary me-2" (click)="onClose()">
    <i class="fa fa-close"></i> Cancel
  </button>
  <button type="button" class="btn btn-primary me-2" (click)="save()" [disabled]="isSaving">
    <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving"></span>
    Save
  </button>
</div>
