<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">NEW SUBMISSION FORM</h4>
            </div>
            <div class="card-body" >
                <ng-wizard [config]="config" (stepChanged)="stepChanged($event)" *ngIf="applicationContext" name="newApp">
                    <ng-wizard-step [title]="'Upload File'">
                        <upload-file [context]="applicationContext" [isImportFromAusAllowed]="isImportFromAusAllowed" (nextStep)="nextStep($event)" (file)="duFile($event)"></upload-file>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Review Data'">
                        <review-data *ngIf="processSubmissionData"
                            [context]="applicationContext"
                            [processSubmissionData]="processSubmissionData"
                            [showKeyDates]="showKeyDates"
                            (nextStep)="nextStep($event)"
                            (previousStep)="showPreviousStep($event)"
                            [showMissingInfo]="showMissingInfo"
                            (backToUploadFile)="backToUploadFile()"
                            [parsedFileData]="parsedFileData"
                            [allowedLoanCharacterstics]="allowedLoanCharacterstics"
                            [allowedBorrowerCharacterstics]="allowedBorrowerCharacterstics">
                        </review-data>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Key Dates'"
                        [state]="showKeyDates ? 'normal' : 'hidden'">
                      <key-dates *ngIf="processSubmissionData && showKeyDates"
                        [processSubmissionData]="processSubmissionData"
                        [context]="applicationContext"
                        (nextStep)="nextStep($event)"
                        (previousStep)="showPreviousStep($event)"
                        [showMissingInfo]="showMissingInfo"
                        (backToUploadFile)="backToUploadFile()"
                        [parsedFileData]="parsedFileData"
                        [allowedLoanCharacterstics]="allowedLoanCharacterstics"
                        [allowedBorrowerCharacterstics]="allowedBorrowerCharacterstics">
                      </key-dates>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Missing Information'" [state]="showMissingInfo ? 'normal' : 'hidden'">
                        <missing-information *ngIf="processSubmissionData && loadMissingInfo" [processSubmissionData]="processSubmissionData" [context]="applicationContext"
                            [isCorrSearchForCreditOnSubmissionEnabled]="isCorrSearchForCreditOnSubmissionEnabled"
                            (nextStep)="nextStep($event)" (previousStep)="showPreviousStep($event)"
                            [allowedLoanCharacterstics]="allowedLoanCharacterstics" [allowedBorrowerCharacterstics]="allowedBorrowerCharacterstics"></missing-information>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Loan Characteristic(s)'" [state]="allowedLoanCharacterstics.length > 0 ? 'normal' : 'hidden'" *ngIf="!isHomesiteHack">
                        <loan-characteristics [processSubmissionData]="processSubmissionData" [context]="applicationContext"
                            [allowedLoanCharacterstics]="allowedLoanCharacterstics" [allowedBorrowerCharacterstics]="allowedBorrowerCharacterstics"
                            (nextStep)="nextStep($event)" (previousStep)="showPreviousStep($event)"></loan-characteristics>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Borrower Characteristic(s)'" [state]="allowedBorrowerCharacterstics.length > 0 ? 'normal' : 'hidden'" *ngIf="!isHomesiteHack">
                        <borrower-characteristics [processSubmissionData]="processSubmissionData" [context]="applicationContext"
                            [allowedBorrowerCharacterstics]="allowedBorrowerCharacterstics"
                            (nextStep)="nextStep($event)" (previousStep)="showPreviousStep($event)"></borrower-characteristics>
                    </ng-wizard-step>

                    <ng-wizard-step [title]="'Submission'">
                        <submission [processSubmissionData]="processSubmissionData"
                            (submit)="submit($event)" (previousStep)="showPreviousStep($event)">
                        </submission>
                    </ng-wizard-step>
                </ng-wizard>
            </div>
        </div>
    </div>
</div>
