<form #textInputForm="ngForm">
  <div *ngIf="!inEditMode"
    [ngClass]="
      { 'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
        'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
        'ng-invalid-pattern' : model && model.invalid && model.errors && model.errors.pattern,
        'ng-invalid-mask' : model && model.invalid && model.errors && model.errors.mask,
        'form-input' : showBorder,
        'no-border' : !showBorder,
        'bg-transparent' : readonly
      } "
    [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden">
    <span class="input-label" style="display: inline;" title="{{label}}" *ngIf="label && label.length > 0">
      <span class="input-label-overflow">{{label}}</span>
      <button (click)="editClickEvent.emit()" [hidden]="!hasEditButton || readonly || disabled" style="float: right; background: transparent; border: none;">
        <i class="fas fa-pencil-alt"></i>
      </button>
      <button *ngIf="copyButtonVisible" (click)="copyClicked.emit()" style="float: right; background: transparent; border: none;" title="{{copyTitleText}}">
        <i class="far fa-copy"></i>
      </button>
    </span>
    <textarea
      [(ngModel)]="value"
      #control
      #model="ngModel"
      name="{{ name }}"
      rows="{{ rows }}"
      [required]="required"
      [readonly]="readonly || (hasEditButton && !isEditableEvenIfItHasEditButton)"
      [disabled]="disabled"
      placeholder="{{ placeholder }}"
      [maxlength]="maxlength"
      style="resize: none; height: unset !important;"
      (blur)="onBlurred($event)"
      [ngClass]="
      { 'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
        'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
        'ng-invalid-pattern' : model && model.invalid && model.errors && model.errors.pattern,
        'ng-invalid-mask' : model && model.invalid && model.errors && model.errors.mask,
        'no-border' : !showBorder
      }"
      class="input-element"
    >
    </textarea>

  </div>
  <edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
  </edit-mode-input>
</form>
