import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'qa-delete-button',
  templateUrl: './qa-delete-button.component.html',
})
export class QaDeleteButtonComponent {
  @Output() qaClick = new EventEmitter();

  @Input() isDisabled = false;
}
