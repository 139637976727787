<ng-container
  *ngLet='formGroup.controls.disclosuresInfo as formGroup'
  [formGroup]='formGroup'
>
  <!-- Nearest Living Relative -->
  <section class='row m-2 mb-0 mt-5'>
    <div class='col-12 col-lg-4 col-xl-3'>
      <h5>Nearest Living Relative</h5>

      <!-- Borrower Select -->
      <div class='form-group row align-items-baseline'>
        <select
          [(ngModel)]='nearestLivingRelativeSection.selectedRelative'
          [disabled]='formGroup.controls.nearestLivingRelatives.disabled'
          [ngModelOptions]='{ standalone: true }'
          class='form-select w-auto col-auto ms-2'
          id='nearest-living-relative-borrower-select'
          style='border-radius: 0 !important;'
        >
          <option
            *ngFor='let control of formGroup.controls.nearestLivingRelatives.controls'
            [ngValue]='control'
          >
            {{ control.controls.borrower.controls.name.value }}
          </option>
        </select>
      </div>
    </div>

    <div
      *ngLet='nearestLivingRelativeSection.selectedRelative.controls as controls'
      [formGroup]='nearestLivingRelativeSection.selectedRelative'
      class='col'
    >
      <!-- First Name -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='nearest-living-relative-first-name'
        >
          First Name of the Relative
        </label>

        <div class='col-12 col-lg-4'>
          <input
            [formControl]='controls.firstName'
            class='form-control'
            id='nearest-living-relative-first-name'
            type='text'
          >
        </div>
      </div>

      <!-- FIXME: Missing from MortgageBorrower -->
      <!-- Last Name -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='nearest-living-relative-last-name'
        >
          Last Name of the Relative
        </label>

        <div class='col-12 col-lg-4'>
          <input
            [formControl]='controls.lastName'
            class='form-control'
            id='nearest-living-relative-last-name'
            type='text'
          >
        </div>
      </div>

      <!-- Relationship -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='nearest-living-relative-relationship'
        >
          Relationship with the Relative
        </label>

        <div class='col-12 col-lg-4'>
          <input
            [formControl]='controls.relationship'
            class='form-control'
            id='nearest-living-relative-relationship'
            type='text'
          >
        </div>
      </div>

      <!-- Phone Number -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='nearest-living-relative-phone-number'
        >
          Phone Number of the Relative
        </label>

        <div class='col-12 col-lg-4'>
          <input
            [formControl]='controls.phoneNumber'
            class='form-control'
            id='nearest-living-relative-phone-number'
            type='text'
          >
        </div>
      </div>

      <!-- Address -->
      <div class='form-group row align-items-baseline'>
        <label
          class='col-12 col-lg'
          for='nearest-living-relative-address'
        >
          Address of the Relative
        </label>

        <div class='col-12 col-lg-4'>
          <div
            *ngLet='controls.address as address'
            class='input-group'
          >
            <ng-container *ngLet='address.disabled as disabled'>
              <input
                [formControl]='address.controls.summary'
                [readonly]='true'
                class='form-control'
                id='nearest-living-relative-address'
                style='
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                '
                [ngStyle]='{
                  "background-color": disabled ? null : "white"
                }'
                type='text'
              >

              <!-- Edit Address Button -->
              <div class='input-group-append'>
                <button
                  (click)='openAddressEditorModal(address)'
                  [disabled]='disabled'
                  class='btn btn-outline-primary'
                  style='border-radius: 0 0.5rem 0.5rem 0 !important;'
                  title='Open Address Editor'
                  type='button'
                >
                  <i class='fa fa-pencil-alt'></i>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- HUD/VA Addendum -->
  <section
    *ngLet='formGroup.controls.hudVaAddendum as formGroup'
    [formGroup]='formGroup'
    class='row m-2 mb-0 mt-5'
  >
    <div class='col-12 col-lg-4 col-xl-3'>
      <h5>HUD/VA Addendum</h5>
      <span class='text-muted'>Form 26-1802a</span>
    </div>

    <div class='col'>
      <!-- 13. Lender / Mortgage ID Code -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='lender-mortgage-id-code'
        >
          Lender / Mortgage ID Code
        </label>

        <div class='col-12 col-lg-4'>
          <input
            [formControl]='formGroup.controls.lenderOrMortgageIdCode'
            class='form-control'
            id='lender-mortgage-id-code'
            type='text'
          >
        </div>
      </div>

      <!-- 14. Agent / Sponsor ID Code -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='agent-sponsor-id-code'
        >
          Agent / Sponsor ID Code
        </label>

        <div class='col-12 col-lg-4'>
          <input
            [formControl]='formGroup.controls.agentOrSponsorIdCode'
            class='form-control'
            id='agent-sponsor-id-code'
            type='text'
          >
        </div>
      </div>

      <!-- 20. Purpose of Loan -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='purpose-of-loan'
        >
          Purpose of Loan
        </label>

        <div class='col-12 col-lg-4'>
          <select
            class='form-select'
            id='purpose-of-loan'
            [formControl]='formGroup.controls.purposeOfLoan'
          >
            <option [ngValue]='null'>Select</option>

            <option
              *ngFor='let option of purposeOfLoanOptions'
              [ngValue]='option.value'
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- 23. Have you ever had a VA home loan? -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1 mt-2'>
        <label class='col-12 col-lg'>
          Have you ever had a VA home loan?
        </label>

        <div class='col-4 d-flex gap-3'>
          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.haveYouEverHadVAHomeLoan'
              [value]='true'
              class='form-check-input'
              id='have-had-home-loan-yes'
              name='haveHadHomeLoan'
              type='radio'
            >

            <label
              class='form-check-label'
              for='have-had-home-loan-yes'
            >
              Yes
            </label>
          </div>

          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.haveYouEverHadVAHomeLoan'
              [value]='false'
              class='form-check-input'
              id='have-had-home-loan-no'
              name='haveHadHomeLoan'
              type='radio'
            >

            <label
              class='form-check-label'
              for='have-had-home-loan-no'
            >
              No
            </label>
          </div>
        </div>
      </div>

      <!-- AUS Result -->
      <div class='form-group row align-items-baseline'>
        <label class='col-12 col-lg'>
          AUS Result
        </label>

        <div class='col-12 col-lg-4'>
          <select
            [formControl]='formGroup.controls.ausResult'
            class='form-select'
            id='aus-result'
          >
            <option [ngValue]='null'>Select</option>

            <option
              *ngFor='let option of ausResultOptions'
              [ngValue]='option.value'
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </section>

  <!-- Loan Analysis -->
  <section
    *ngLet='formGroup.controls.loanAnalysis as formGroup'
    [formGroup]='formGroup'
    class='row m-2 mb-0 mt-5'
  >
    <div class='col-12 col-lg-4 col-xl-3'>
      <h5>Loan Analysis</h5>
      <span class='text-muted'>Form 266393</span>
    </div>

    <div class='col'>
      <!-- Past Credit Record -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label class='col-12 col-lg'>
          Past Credit Record
        </label>

        <div class='col-12 col-lg-4'>
          <select
            class='form-select'
            id='past-credit-record'
            [formControl]='formGroup.controls.pastCreditRecord'
          >
            <option [ngValue]='null'>Select</option>

            <option
              *ngFor='let option of pastCreditRecordOptions'
              [ngValue]='option.value'
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Does loan meet VA credit standards? -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1 mt-2'>
        <label class='col-12 col-lg'>
          Does loan meet VA credit standards?
        </label>

        <div class='col-4 d-flex gap-3'>
          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.meetVaCreditStandards'
              [value]='true'
              class='form-check-input'
              id='meets-va-credit-standards-yes'
              name='meetsVaCreditStandards'
              type='radio'
            >

            <label
              class='form-check-label'
              for='meets-va-credit-standards-yes'
            >
              Yes
            </label>
          </div>

          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.meetVaCreditStandards'
              [value]='false'
              class='form-check-input'
              id='meets-va-credit-standards-no'
              name='meetsVaCreditStandards'
              type='radio'
            >

            <label
              class='form-check-label'
              for='meets-va-credit-standards-no'
            >
              No
            </label>
          </div>
        </div>
      </div>

      <!-- Utilities Included? -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1 mt-2'>
        <label class='col-12 col-lg'>
          Utilities Included?
        </label>

        <div class='col-4 d-flex gap-3'>
          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.utilitiesIncluded'
              [value]='true'
              class='form-check-input'
              id='utilities-included-yes'
              name='utilitiesIncluded'
              type='radio'
            >

            <label
              class='form-check-label'
              for='utilities-included-yes'
            >
              Yes
            </label>
          </div>

          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.utilitiesIncluded'
              [value]='false'
              class='form-check-input'
              id='utilities-included-no'
              name='utilitiesIncluded'
              type='radio'
            >

            <label
              class='form-check-label'
              for='utilities-included-no'
            >
              No
            </label>
          </div>
        </div>
      </div>

      <!-- Add remarks on VA Credit standards -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='va-credit-standards-remarks'
        >
          Add remarks on VA Credit standards
        </label>

        <div class='col-12 col-lg-4'>
          <textarea
            [formControl]='formGroup.controls.remarks'
            class='form-control'
            id='va-credit-standards-remarks'
            rows='3'
          ></textarea>
        </div>
      </div>

      <!-- Application Recommended? -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1 mt-2'>
        <label class='col-12 col-lg'>
          Application Recommended?
        </label>

        <div class='col-4 d-flex gap-3'>
          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.applicationRecommended'
              [value]='true'
              class='form-check-input'
              id='application-recommended-yes'
              name='applicationRecommended'
              type='radio'
            >

            <label
              class='form-check-label'
              for='application-recommended-yes'
            >
              Approved
            </label>
          </div>

          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.applicationRecommended'
              [value]='false'
              class='form-check-input'
              id='application-recommended-no'
              name='applicationRecommended'
              type='radio'
            >

            <label
              class='form-check-label'
              for='application-recommended-no'
            >
              Rejected
            </label>
          </div>
        </div>
      </div>

      <!-- 56. Final Loan Action -->
      <div class='form-group row align-items-baseline'>
        <label class='col-12 col-lg'>
          Final Loan Action
        </label>

        <div class='col-12 col-lg-4'>
          <select
            [formControl]='formGroup.controls.finalLoanAction'
            class='form-select'
            id='final-loan-action'
          >
            <option [ngValue]='null'>Select</option>

            <option
              *ngFor='let option of finalLoanActionOptions'
              [ngValue]='option.value'
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </section>

  <!-- Loan Summary Sheet -->
  <section
    *ngLet='formGroup.controls.loanSummarySheet as formGroup'
    [formGroup]='formGroup'
    class='row m-2 mb-0 mt-5'
  >
    <div class='col-12 col-lg-4 col-xl-3'>
      <h5>Loan Summary Sheet</h5>
      <span class='text-muted'>Form 260286 VA</span>
    </div>

    <div class='col'>
      <!-- 7. Entitlement ID -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <div class='col d-flex flex-column gap-1'>
          <label for='entitlement-id'>
            Entitlement ID
          </label>

          <span class='text-muted'>
            (01 to 11, from VA Certificate of Eligibility)
          </span>
        </div>

        <div class='col-12 col-lg-4'>
          <input
            [formControl]='formGroup.controls.entitlementId'
            class='form-control'
            id='entitlement-id'
            type='text'
          >
        </div>
      </div>

      <!-- 12. VA Loan Procedure Type -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='va-loan-procedure-type'
        >
          VA Loan Procedure Type
        </label>

        <div class='col-12 col-lg-4'>
          <select
            [formControl]='formGroup.controls.vaLoanProcedureType'
            class='form-select'
            id='va-loan-procedure-type'
          >
            <option [ngValue]='null'>Select</option>

            <option
              *ngFor='let option of vaLoanProcedureTypeOptions'
              [ngValue]='option.value'
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- 25. Appraisal Type -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='appraisal-type'
        >
          Appraisal Type
        </label>

        <div class='col-12 col-lg-4'>
          <select
            [formControl]='formGroup.controls.appraisalType'
            class='form-select'
            id='appraisal-type'
          >
            <option [ngValue]='null'>Select</option>

            <option
              *ngFor='let option of appraisalTypeOptions'
              [ngValue]='option.value'
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- 29. MICRV No. -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='micrv-number'
        >
          MICRV No.
        </label>

        <div class='col-12 col-lg-4'>
          <input
            [formControl]='formGroup.controls.micrvNumber'
            class='form-control'
            id='micrv-number'
            type='text'
          >
        </div>
      </div>

      <!-- 30. Lender SAR ID No. -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='lender-sar-id-number'
        >
          Lender SAR ID No.
        </label>

        <div class='col-12 col-lg-4'>
          <input
            [formControl]='formGroup.controls.lenderSarIdNumber'
            class='form-control'
            id='lender-sar-id-number'
            type='text'
          >
        </div>
      </div>

      <!-- 40. Gross Living Area (Sq. Ft.) -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='gross-living-area'
        >
          Gross Living Area (Sq. Ft.)
        </label>

        <div class='col-12 col-lg-4'>
          <number-input
            [(ngModel)]='formAdapter.disclosuresInfo.loanSummarySheet.grossLivingArea.value'
            [allowNegative]='false'
            [disabled]='formGroup.controls.grossLivingArea.disabled'
            [ngModelOptions]='{ standalone: true }'
            class='w-auto px-0'
            id='gross-living-area'
          ></number-input>
        </div>
      </div>

      <!-- 42. Date SAR Issued Notification of Value -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='sar-issued-notification-of-value'
        >
          Date SAR Issued Notification of Value
        </label>

        <div class='col-12 col-lg-4'>
          <date-input
            [(ngModel)]='formAdapter.disclosuresInfo.loanSummarySheet.dateSarIssuedNotificationOfValue.value'
            [disabled]='formGroup.controls.dateSarIssuedNotificationOfValue.disabled'
            [ngModelOptions]='{ standalone: true }'
            class='w-auto px-0'
            id='sar-issued-notification-of-value'
          ></date-input>
        </div>
      </div>

      <!-- 43. Total Room Count -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='total-room-count'
        >
          Total Room Count
        </label>

        <div class='col-12 col-lg-4'>
          <number-input
            [(ngModel)]='formAdapter.disclosuresInfo.loanSummarySheet.totalRoomCount.value'
            [allowNegative]='false'
            [disabled]='formGroup.controls.totalRoomCount.disabled'
            [ngModelOptions]='{ standalone: true }'
            class='w-auto px-0'
            id='total-room-count'
          ></number-input>
        </div>
      </div>

      <!-- 44. Bath Count -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='bath-count'
        >
          Bath Count
        </label>

        <div class='col-12 col-lg-4'>
          <number-input
            [(ngModel)]='formAdapter.disclosuresInfo.loanSummarySheet.bathCount.value'
            [allowNegative]='false'
            [disabled]='formGroup.controls.bathCount.disabled'
            [ngModelOptions]='{ standalone: true }'
            class='w-auto px-0'
            id='bath-count'
          ></number-input>
        </div>
      </div>

      <!-- 45. Bedroom Count -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='bedroom-count'
        >
          Bedroom Count
        </label>

        <div class='col-12 col-lg-4'>
          <number-input
            [(ngModel)]='formAdapter.disclosuresInfo.loanSummarySheet.bedroomCount.value'
            [allowNegative]='false'
            [disabled]='formGroup.controls.bedroomCount.disabled'
            [ngModelOptions]='{ standalone: true }'
            class='w-auto px-0'
            id='bedroom-count'
          ></number-input>
        </div>
      </div>

      <!-- 46. Processed under LAPP? -->
      <div class='form-group row align-items-baseline mt-2'>
        <label class='col-12 col-lg'>
          46. If processed under LAPP, was the fee appraisal's original value
          estimate changed or repair recommendations revised, or the SAR
          otherwise make significant adjustments?
        </label>

        <div class='col-4 d-flex gap-3'>
          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.processedUnderLapp'
              [value]='true'
              class='form-check-input'
              id='processed-under-lapp-yes'
              name='processedUnderLapp'
              type='radio'
            >

            <label
              class='form-check-label'
              for='processed-under-lapp-yes'
            >
              Yes
            </label>
          </div>

          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.processedUnderLapp'
              [value]='false'
              class='form-check-input'
              id='processed-under-lapp-no'
              name='processedUnderLapp'
              type='radio'
            >

            <label
              class='form-check-label'
              for='processed-under-lapp-no'
            >
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Verification of VA Benefits -->
  <section
    *ngLet='formGroup.controls.verificationOfVaBenefits as formGroup'
    [formGroup]='formGroup'
    class='row m-2 mb-0 mt-5'
  >
    <div class='col-12 col-lg-4 col-xl-3'>
      <h5>Verification of VA Benefits</h5>
      <span class='text-muted'>Form 26-8937</span>
    </div>

    <div class='col'>
      <!-- 7. Has VA Benefit Related Indebtedness? -->
      <div class='form-group row align-items-baseline mt-2'>
        <label class='col-12 col-lg'>
          7. I hereby certify that I do have a VA benefit-related indebtedness
          to my knowledge.
        </label>

        <div class='col-4 d-flex gap-3'>
          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.indebtednessCertification'
              [value]='true'
              class='form-check-input'
              id='has-va-benefit-related-indebtedness-yes'
              name='hasVaBenefitRelatedIndebtedness'
              type='radio'
            >

            <label
              class='form-check-label'
              for='has-va-benefit-related-indebtedness-yes'
            >
              Yes
            </label>
          </div>

          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.indebtednessCertification'
              [value]='false'
              class='form-check-input'
              id='has-va-benefit-related-indebtedness-no'
              name='hasVaBenefitRelatedIndebtedness'
              type='radio'
            >

            <label
              class='form-check-label'
              for='has-va-benefit-related-indebtedness-no'
            >
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Request for a Certificate of Eligibility -->
  <section
    *ngLet='formGroup.controls.certificateEligibilityRequest as formGroup'
    [formGroup]='formGroup'
    class='row m-2 mb-0 mt-5'
  >
    <div class='col-12 col-lg-4 col-xl-3'>
      <h5>Request for a Certificate of Eligibility</h5>
      <span class='text-muted'>Form 261880</span>
    </div>

    <div class='col'>
      <!-- 9B. VA Claim Number -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='va-claim-number'
        >
          9B. VA Claim Number
        </label>

        <div class='col-12 col-lg-4'>
          <input
            [formControl]='formGroup.controls.vaClaimNumber'
            class='form-control'
            id='va-claim-number'
            type='text'
          >
        </div>
      </div>

      <!-- 10B. Are you a purple heart recipient? -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1 mt-2'>
        <label class='col-12 col-lg'>
          10B. Are you a purple heart recipient?
        </label>

        <div class='col-4 d-flex gap-3'>
          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.purpleHeartRecipient'
              [value]='true'
              class='form-check-input'
              id='is-purple-heart-recipient-yes'
              name='isPurpleHeartRecipient'
              type='radio'
            >

            <label
              class='form-check-label'
              for='is-purple-heart-recipient-yes'
            >
              Yes
            </label>
          </div>

          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.purpleHeartRecipient'
              [value]='false'
              class='form-check-input'
              id='is-purple-heart-recipient-no'
              name='isPurpleHeartRecipient'
              type='radio'
            >

            <label
              class='form-check-label'
              for='is-purple-heart-recipient-no'
            >
              No
            </label>
          </div>
        </div>
      </div>

      <!-- 10C. Do you have a pre-discharge claim pending with the VA? -->
      <div class='form-group row align-items-baseline mt-2'>
        <label class='col-12 col-lg'>
          10C. Do you have a pre-discharge claim pending with the VA?
        </label>

        <div class='col-4 d-flex gap-3'>
          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.preDischargeClaimPending'
              [value]='true'
              class='form-check-input'
              id='has-pre-discharge-claim-pending-yes'
              name='hasPreDischargeClaimPending'
              type='radio'
            >

            <label
              class='form-check-label'
              for='has-pre-discharge-claim-pending-yes'
            >
              Yes
            </label>
          </div>

          <div class='form-check'>
            <input
              [formControl]='formGroup.controls.preDischargeClaimPending'
              [value]='false'
              class='form-check-input'
              id='has-pre-discharge-claim-pending-no'
              name='hasPreDischargeClaimPending'
              type='radio'
            >

            <label
              class='form-check-label'
              for='has-pre-discharge-claim-pending-no'
            >
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Report & Certification of Loan Disbursement -->
  <section
    *ngLet='formGroup.controls.rclDisbursement as formGroup'
    [formGroup]='formGroup'
    class='row m-2 mb-0 mt-5'
  >
    <div class='col-12 col-lg-4 col-xl-3'>
      <h5>Report & Certification of Loan Disbursement</h5>
      <span class='text-muted'>Form 261820.VA</span>
    </div>

    <div class='col'>
      <!-- 31A. Underwriter -->
      <div class='form-group row align-items-baseline border-bottom pb-1 mb-1'>
        <label
          class='col-12 col-lg'
          for='underwriter'
        >
          31A. Underwriter
        </label>

        <div class='col-12 col-lg-4'>
          <select
            class='form-select'
            id='underwriter'
            [formControl]='formGroup.controls.underwriter'
          >
            <option [ngValue]='null'>Select</option>

            <option
              *ngFor='let option of underwriterOptions'
              [ngValue]='option.value'
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- 36. The undersigned veteran, certify that: -->
      <div
        *ngLet='formGroup.controls.occupancy as control'
        class='row mt-2'
      >
        <div class='col-12 d-flex flex-column gap-2 mb-2'>
          <label>
            36. The undersigned veteran, certify that:
          </label>

          <b>
            Occupancy
          </b>
        </div>

        <div
          class='col-12 form-group row align-items-baseline pb-1 mb-1 ms-1'
          style='text-align: justify'
        >
          <div class='form-check'>
            <input
              [formControl]='control'
              [value]='Occupancy.OccupyAsHome'
              class='form-check-input'
              id='occupancy-as-home'
              name='occupancy'
              type='radio'
            >

            <label
              class='form-check-label'
              for='occupancy-as-home'
            >
              I now actually occupy the above-described property as my home or intend to move into and occupy said
              property as my home within a reasonable period of time or intend to reoccupy it after the completion of
              major alterations, repairs or improvements.
            </label>
          </div>
        </div>

        <div
          class='form-group row align-items-baseline pb-1 mb-1 ms-1'
          style='text-align: justify'
        >
          <div class='form-check'>
            <input
              [formControl]='control'
              [value]='Occupancy.SpouseActiveDuty'
              class='form-check-input'
              id='occupancy-spouse-on-active-military-duty'
              name='occupancy'
              type='radio'
            >

            <label
              class='form-check-label'
              for='occupancy-spouse-on-active-military-duty'
            >
              My spouse is on active military duty and in his or her absence, I occupy or intend to occupy the property
              securing this loan as my home.
            </label>
          </div>
        </div>

        <div
          class='form-group row align-items-baseline pb-1 mb-1 ms-1'
          style='text-align: justify'
        >
          <div class='form-check'>
            <input
              [formControl]='control'
              [value]='Occupancy.DependentChildOccupies'
              class='form-check-input'
              id='occupancy-dependent-child-of-veteran'
              name='occupancy'
              type='radio'
            >

            <label
              class='form-check-label'
              for='occupancy-dependent-child-of-veteran'
            >
              The veteran is on active military duty and in his or her absence, I certify that a dependent child of the
              veteran occupies or will occupy the property securing this loan as their home. (NOTE: this require that
              the veteran's attorney-in-fact or legal guardian of the dependent child sign in item 40.)
            </label>
          </div>
        </div>

        <div
          class='form-group row align-items-baseline pb-1 mb-1 ms-1'
          style='text-align: justify'
        >
          <div class='form-check'>
            <input
              [formControl]='control'
              [value]='Occupancy.PreviouslyOccupiedAsHome'
              class='form-check-input'
              id='occupancy-previously-occupied'
              name='occupancy'
              type='radio'
            >

            <label
              class='form-check-label'
              for='occupancy-previously-occupied'
            >
              I previously occupied the property securing this loan as my home.(For interest rate reduction loans.)
            </label>
          </div>
        </div>

        <div
          class='form-group row align-items-baseline pb-1 mb-1 ms-1'
          style='text-align: justify'
        >
          <div class='form-check'>
            <input
              [formControl]='control'
              [value]='Occupancy.SpousePreviouslyOccupied'
              class='form-check-input'
              id='occupancy-spouse-was-on-active-military-duty'
              name='occupancy'
              type='radio'
            >

            <label
              class='form-check-label'
              for='occupancy-spouse-was-on-active-military-duty'
            >
              While my spouse was on active military duty and unable to occupy the property securing this loan, I
              previously occupied the property that is securing this loan as home. (For interest rate reduction loans)
            </label>
          </div>
        </div>

        <div
          class='form-group row align-items-baseline pb-1 mb-1 ms-1'
          style='text-align: justify'
        >
          <div class='form-check'>
            <input
              [formControl]='control'
              [value]='Occupancy.DependentChildPreviouslyOccupied'
              class='form-check-input'
              id='occupancy-veteran-was-on-active-military-duty'
              name='occupancy'
              type='radio'
            >

            <label
              class='form-check-label'
              for='occupancy-veteran-was-on-active-military-duty'
            >
              While the veteran was on active military duty and unable to occupy the property securing this loan, the
              property was occupied by the veteran's dependent child as his or her home. (For interest rate reduction
              loans.) (NOTE: this requires that the veteran's attorney-in-fact guardian of the dependent child sign in
              item 40.)
            </label>
          </div>
        </div>
      </div>
    </div>
  </section>

  <qa-address-editor-modal
    #addressEditorModal
    (closed)='onCloseAddressEditorModal($event)'
  >
  </qa-address-editor-modal>
</ng-container>
