import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UrlaBorrower } from '../../models/urla-mortgage.model';
import { BorrowerCounselingEvent } from 'src/app/models/mortgage.model';
import { v4 as uuidv4 } from 'uuid';
import { Utils } from 'src/app/core/services/utils';
import { createIsRequiredFunction } from '../../urla-utils';
import { EnumerationService } from '../../../../services/enumeration-service';
import { Address } from 'src/app/models';

@Component({
  selector: 'urla-borrower-counseling-event',
  templateUrl: './urla-borrower-counseling-event.component.html',
  styleUrls: ['./urla-borrower-counseling-event.component.scss'],
  viewProviders: [formViewProvider]
})
export class UrlaBorrowerCounselingEventComponent implements OnInit {

  @Input()
  borrower: UrlaBorrower;

  @Input()
  isReadOnly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  borrowerCounselingFormatTypes: EnumerationItem[] = [];

  @Input()
  event: BorrowerCounselingEvent;

  @Output()
  attemptedToDeleteEvent: EventEmitter<BorrowerCounselingEvent> = new EventEmitter<BorrowerCounselingEvent>();

  mouseCursorOnDeleteButton: boolean = false;

  protected stateOptions: EnumerationItem[] = [];

  protected componentId: string;

  protected isRequired: (fieldName: string) => boolean;

  constructor(
    private readonly _enumerationService: EnumerationService,
  ) {
    this.componentId = uuidv4();
  }

  ngOnInit(): void {
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);

    this.stateOptions = this._enumerationService.states;
  }

  protected onDeleteClicked() {
    this.attemptedToDeleteEvent.emit(this.event);
  }

  protected onCompletionDateChanged() {
    this.event.borrowerHasCompleted = false;
    if (this.event.completedDate) {
      if (Utils.isValidDate(this.event.completedDate)) {
        this.event.borrowerHasCompleted = true;
      }
    }
  }

  protected onCounselingAgencyAddressChanged = (address: Address) => {
    this.event.counselingAgencyAddress1 = address.address1;
    this.event.counselingAgencyCity = address.city;
    this.event.counselingAgencyZipCode = address.zipCode;
    this.event.counselingAgencyState = address.state;
  }
}
