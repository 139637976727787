import { DeclarationExplanation, DeclarationExplanationType } from '../../../models';
import { groupBy } from 'lodash';

export function createExplanation(
  type: DeclarationExplanationType,
): DeclarationExplanation {
  const explanation = {
    description: '',
    explanationType: type,
  }

  if (type !== DeclarationExplanationType.Other) {
    return explanation;
  }

  return {
    ...explanation,
    explanationTypeOtherDescription:
      'PreForeclosureSaleOrShortSalePastSevenYears',
  };
}

export type ExplanationGroups
  = Record<DeclarationExplanationType, DeclarationExplanation[]>;

export function groupExplanations(
  explanations: readonly DeclarationExplanation[]
): ExplanationGroups {
  const lookup = groupBy(explanations, 'explanationType');

  const groups = Object.entries(lookup).reduce(
    (acc, [key, value]) => {
      acc[key as DeclarationExplanationType] = value;
      return acc;
    },
    {} as Record<DeclarationExplanationType, DeclarationExplanation[]>
  );

  return Object.values(DeclarationExplanationType).reduce(
    (acc, key) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      return acc;
    },
    groups
  );
}
