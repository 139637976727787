import { ControlValueAccessor } from '@angular/forms';

export { MakeProvider } from './abstract-value-accessor';

export abstract class GenericAbstractValueAccessor<T> implements ControlValueAccessor {
  protected _value: T;

  get value(): T {
    return this._value;
  };

  set value(v: T) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  writeValue(value: T) {
    this._value = value;
    // warning: comment below if only want to emit on user intervention
    this.onChange(value);
  }

  onChange = (_: T) => {
  };

  onTouched = () => {
  };

  registerOnChange(fn: (_: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => T): void {
    this.onTouched = fn;
  }
}
