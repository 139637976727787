import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { groupBy } from 'lodash';
import {
  Address,
  ChannelEnum,
  MortgageBorrower,
  RealEstateOwned,
  ResidencyAddress,
  ResidencyBasis,
  ResidencyType,
  SubjectProperty,
  SystemLevel,
} from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { UrlaBorrower, UrlaMortgage } from '../../models/urla-mortgage.model';
import { UtilityService } from '../../services/utility.service';
import { UrlaSectionComponent } from '../../urla-section/urla-section.component';
import { ResidencyAddressChangedEvent } from '../address-info/residency-address-base.component';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { MenuItemStatus } from 'src/app/modules/tpo/models/enums/menu-item-status.enum';
import { BorrowerValidityStatus } from '../borrower-info/borrower-info.component';
import { BorrowerTabSelectedEvent, TabbedBorrowerComponentType } from '../../urla-main/urla-main.component';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'borrowers-info',
  templateUrl: 'borrowers-info.component.html',
  styleUrls: ['./borrowers-info.component.scss']
})

export class BorrowersInfoComponent extends UrlaSectionComponent implements OnChanges, OnInit, OnDestroy {

  @ViewChild("borrowersForm")
  urlaSectionForm: NgForm;

  menuItem: string = Constants.menu.urlaMenuItems.borrowerInfo;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    if (!this.selectedBorrower || !this._mortgage.borrowers.find(b => b.borrowerId === this.selectedBorrower.borrowerId)) {
      this.onBorrowerSelected(this.mortgage.borrowers[0]);
    }
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  loanBorrowers: BorrowerDto[] = [];

  @Input()
  isReadOnly: boolean = false;

  @Input()
  hiddenFields: string[];

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  systemLevel: SystemLevel;

  @Output()
  borrowerReosChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  borrowerTabSelected: EventEmitter<BorrowerTabSelectedEvent> = new EventEmitter<BorrowerTabSelectedEvent>();

  borrowerPresentAddressSubjectPropertyAssociation: Map<number, boolean> = new Map<number, boolean>();

  borrowerValidityStatusInfo: Map<number, MenuItemStatus> = new Map<number, MenuItemStatus>();

  selectedBorrower: MortgageBorrower;

  borrowerGroups: MortgageBorrower[][] = [];

  isCorrespondent: boolean = false

  isExistingReo: boolean;

  protected isStatusLoaded: boolean = false;

  protected getBorrowerDisplayName: (borrower: MortgageBorrower) => string = () => '';

  private _isTpo: boolean = false;

  private readonly applicationContextSubscription: Subscription;

  private _mortgage: UrlaMortgage;

  constructor(
    injector: Injector,
    private readonly _utilityService: UtilityService,
    private readonly _urlaValidationService: UrlaValidationService,
    private readonly _applicationContextService: ApplicationContextService,
  ) {
    super(injector);
    this.applicationContextSubscription = this._applicationContextService.context.subscribe(ctx => {
      this._isTpo = ctx.isTpo;
    });
  }

  ngOnInit(): void {
    this.getBorrowerDisplayName = this._utilityService.getBorrowerDisplayName;
    this.initialize();
  }

  ngOnChanges() {
    this.initialize();
  }

  ngOnDestroy(): void {
    this.applicationContextSubscription?.unsubscribe();
  }

  onPushPresentAddressToSubjectPropertyClicked = (borrower: UrlaBorrower) => {
    const presentAddresses = borrower.residencyAddresses.filter(a => a.residencyType === ResidencyType.PresentAddress);
    if (presentAddresses.length) {
      const presentAddress = presentAddresses[0];
      if (presentAddress.address) {
        if (!this.mortgage.subjectProperty) {
          this.mortgage.subjectProperty = new SubjectProperty();
        }
        this.mortgage.subjectProperty.address1 = presentAddress.address.address1;
        this.mortgage.subjectProperty.city = presentAddress.address.city;
        this.mortgage.subjectProperty.state = presentAddress.address.state;
        this.mortgage.subjectProperty.zipCode = presentAddress.address.zipCode;
      }
    }
  }

  onPullPresentAddressSubjectPropertyClicked = (residencyAddress: ResidencyAddress) => {
    residencyAddress.address.address1 = this.mortgage.subjectProperty.address1;
    residencyAddress.address.city = this.mortgage.subjectProperty.city;
    residencyAddress.address.state = this.mortgage.subjectProperty.state;
    residencyAddress.address.zipCode = this.mortgage.subjectProperty.zipCode;
  }

  onBorrowerPresentAddressSubjectPropertyStatusChanged = (status: boolean, borrower: UrlaBorrower) => {
    const presentAddresses = borrower.residencyAddresses.filter(a => a.residencyType === ResidencyType.PresentAddress);
    if (presentAddresses.length) {
      const presentAddress = presentAddresses[0];
      if (presentAddress.residencyBasis === ResidencyBasis.Own) { // If set as subject property
        const existingReo = this.mortgage.realEstateOwned.find(reo =>
          reo.address1.toLowerCase() === presentAddress.address?.address1?.toLowerCase() // Add city/state/zipcode
        )
        if (existingReo) {
          existingReo.isSubjectProperty = status;
          this.borrowerPresentAddressSubjectPropertyAssociation.set(borrower.borrowerId, status);
        }
      }
    }
  }

  onBorrowerPresentStreetAddressChanged = (e: ResidencyAddressChangedEvent, borrowerId: number) => {
    if (!this._isTpo) {
      const existingReo = this.mortgage.realEstateOwned.find(reo => reo.address1?.toLocaleLowerCase() === e.oldAddress.address?.address1?.toLocaleLowerCase() && 
        reo.city?.toLocaleLowerCase() === e.oldAddress.address?.city?.toLocaleLowerCase() && 
        reo.state?.toLocaleLowerCase() === e.oldAddress.address?.state?.toLocaleLowerCase() && 
        reo.zipCode?.toLocaleLowerCase().slice(0, 5) === e.oldAddress.address?.zipCode?.toLocaleLowerCase().slice(0, 5));
      this.consolidateReoWithPresentAddress(existingReo, e.newAddress, borrowerId);
    }
    
  }

  onBorrowerPresentAddressCityStateOrZipcodeChanged = (e: ResidencyAddress, borrowerId: number) => {
    if (!this._isTpo) {
      const existingReo = this.mortgage.realEstateOwned.find(reo => reo.address1?.toLocaleLowerCase() === e.address?.address1?.toLocaleLowerCase() && 
        reo.city?.toLocaleLowerCase() === e.address?.city?.toLocaleLowerCase() && 
        reo.state?.toLocaleLowerCase() === e.address?.state?.toLocaleLowerCase() && 
        reo.zipCode?.toLocaleLowerCase().slice(0, 5) === e.address?.zipCode?.toLocaleLowerCase().slice(0, 5));
      this.consolidateReoWithPresentAddress(existingReo, e, borrowerId);
    }
  }

  onBorrowerPresentAddressResidencyBasisChanged = (e: ResidencyAddress, borrowerId: number) => {
    if (!this._isTpo) {
      const existingReo = this.mortgage.realEstateOwned.find(reo => reo.address1?.toLocaleLowerCase() === e.address?.address1?.toLocaleLowerCase() && 
        reo.city?.toLocaleLowerCase() === e.address?.city?.toLocaleLowerCase() && 
        reo.state?.toLocaleLowerCase() === e.address?.state?.toLocaleLowerCase() && 
        reo.zipCode?.toLocaleLowerCase().slice(0, 5) === e.address?.zipCode?.toLocaleLowerCase().slice(0, 5));
      this.consolidateReoWithPresentAddress(existingReo, e, borrowerId);
    }
  }

  selectBorrowerTab = (borrower: MortgageBorrower) => {
    this.selectedBorrower = borrower;
  }

  protected loanBorrowersMap: Map<number, BorrowerDto> = new Map<number, BorrowerDto>();

  private initialize = () => {
    this.isCorrespondent = this.mortgage.channel === ChannelEnum.Correspondent;

    this.mortgage.borrowers = _.orderBy(this.mortgage.borrowers, ['printApplicationIndex', 'jointWithBorrowerId'], ['asc', 'desc']);
    this.mortgage.borrowers.forEach(borrower => {

      const borrowerBasedValidityStatus = this._urlaValidationService.getStatusForBorrowerInfo(borrower, this.isCorrespondent);
      this.borrowerValidityStatusInfo.set(borrower.borrowerId, borrowerBasedValidityStatus);

      const loanBorrower = this.loanBorrowers.find(b => b.borrowerId === borrower.contactId);
      this.loanBorrowersMap.set(borrower.borrowerId, loanBorrower);

      const residencyAddresses = borrower.residencyAddresses.filter(a => a.residencyType === ResidencyType.PresentAddress);
      if (residencyAddresses.length) {
        const presentResidencyAddress = residencyAddresses[0];
        if (presentResidencyAddress.residencyBasis === ResidencyBasis.Own) { // If set as subject property
          const compareAddresses = createAddressComparer(
            'address1',
            'city',
            'state',
            'zipCode',
          );
          const compareAddress = (address: Partial<Address>) => compareAddresses(
            presentResidencyAddress.address,
            address,
          );
          const existingReo = this.mortgage.realEstateOwned.find(compareAddress);
          if (existingReo) {
            this.borrowerPresentAddressSubjectPropertyAssociation.set(borrower.borrowerId, existingReo.isSubjectProperty);
          } else {
            const presentAddressIsSubjectProperty = compareAddress(this.mortgage.subjectProperty)
            this.borrowerPresentAddressSubjectPropertyAssociation.set(
              borrower.borrowerId,
              presentAddressIsSubjectProperty,
            );
          }
        }
      }
    })
    this.isStatusLoaded = true;
    if (!this.selectedBorrower) {
      this.onBorrowerSelected(this.mortgage.borrowers[0]);
    }
    this.adjustBorrowers(this.mortgage.borrowers);
  }

  isSelectedBorrower(borrower: MortgageBorrower): boolean {
    return this.selectedBorrower?.borrowerId === borrower.borrowerId;
  }

  onBorrowerValidityStatusChanged = (validityStatus: BorrowerValidityStatus) => {
    let isCorrespondent = this.mortgage.channel === ChannelEnum.Correspondent;
    const borrower = this.mortgage.borrowers.find(b => b.borrowerId == validityStatus.borrowerId);
    if (borrower) {
      const borrowerBasedValidityStatus = this._urlaValidationService.getStatusForBorrowerInfo(borrower, isCorrespondent, this._isTpo);
      this.borrowerValidityStatusInfo.set(validityStatus.borrowerId, borrowerBasedValidityStatus);
      this.validate();
    }
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForBorrowersInfo(this.mortgage, this._isTpo);
    super.setMenuItemStatus(validityStatus);
  }

  protected onBorrowerSelected = (borrower: MortgageBorrower) => {
    this.selectedBorrower = borrower;
    this.borrowerTabSelected.emit({ selectedBorrower: this.selectedBorrower, sourceComponent: TabbedBorrowerComponentType.BorrowersInfo });
  }

  private adjustBorrowers = (borrowers: MortgageBorrower[]) => {
    this.borrowerGroups = [];
    this.borrowerGroups = borrowers.length > 0 ? Object.values(groupBy(borrowers, 'printApplicationIndex')) : [];
  }

  private consolidateReoWithPresentAddress = (existingReo: RealEstateOwned, presentAddress: ResidencyAddress, borrowerId: number) => {
    if (existingReo) {
      if (presentAddress.residencyBasis !== ResidencyBasis.Own) {
        this.isExistingReo = true;
      //   // DO NOT delete other people's REOs.You might have the same address, because you're living with the owners' of the house rent free.
      //   if (existingReo.owningBorrowerIds?.includes(borrowerId)) {
      //     const index = this.mortgage.realEstateOwned.findIndex(reo => reo.reoId == existingReo.reoId);
      //     if (index >= 0) {
      //       this.mortgage.realEstateOwned.splice(index, 1);
      //       this.borrowerReosChanged.emit();
      //     }
      //     return;
      //   }
      // } else {
      //   existingReo.address1 = presentAddress.address.address1;
      //   existingReo.city = presentAddress.address.city;
      //   existingReo.state = presentAddress.address.state;
      //   existingReo.zipCode = presentAddress.address.zipCode;

      //   if (!existingReo.owningBorrowerIds?.includes(borrowerId)) {
      //     existingReo.owningBorrowerIds.push(borrowerId);
      //   }

        this.borrowerReosChanged.emit();
      }
    } else if (this.isExistingReo) {
      this.isExistingReo = false;
      this.borrowerReosChanged.emit();
    }
    if (!existingReo && presentAddress.residencyBasis === ResidencyBasis.Own) {
      let newReo = new RealEstateOwned();
      const presentAddressIsSubjectProperty = this.mortgage.subjectProperty.purposeOfLoan === 'Refinance' && this.mortgage.subjectProperty?.address1?.toLowerCase() ===
        presentAddress.address?.address1?.toLowerCase() && this.mortgage.subjectProperty?.city?.toLowerCase() === presentAddress.address?.city?.toLowerCase() && this.mortgage.subjectProperty?.state?.toLowerCase() === presentAddress.address?.state?.toLowerCase() && this.mortgage.subjectProperty?.zipCode === presentAddress.address?.zipCode?.toLowerCase();
      this.borrowerPresentAddressSubjectPropertyAssociation.set(borrowerId, presentAddressIsSubjectProperty);
      newReo.isSubjectProperty = presentAddressIsSubjectProperty;
      newReo.reoId = this._utilityService.getUniqueId();
      newReo.owningBorrowerIds = [borrowerId];
      newReo.address1 = presentAddress.address.address1;
      newReo.city = presentAddress.address.city;
      newReo.state = presentAddress.address.state;
      newReo.zipCode = presentAddress.address.zipCode;
      this.mortgage.realEstateOwned.push(newReo);
      this.borrowerReosChanged.emit();
    }
  }
}

function createAddressComparer(...keys: Array<keyof Address>) {
  return (
    a: Partial<Address> | undefined,
    b: Partial<Address> | undefined,
  ) => keys.every((k) => a?.[k]?.toString().toLowerCase() === b?.[k]?.toString().toLowerCase());
}
