<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title col-5" style="display: inline-block;"> Loan & Property Info
        </h4>
        <div class="float-end button-items">
        </div>
      </div>
      <div class="card-body" *ngIf="application">
        <div class="card pt-1 pe-1 pb-1 mb-2 tpo-report-card sticky-button-bar sticky-button-bar-vertical"
          [ngClass]="{'sticky-nav-tabs-open-dialer': secondPageActionBarVisible }">
          <div class="button-items">
            <div class="float-start">
              <ul class="nav nav-pills" role="tablist" style='background-color: initial'>
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="{ active: tab === 'loan-info' }" (click)="selectTab('loan-info')"
                    data-bs-toggle="tab" href="#loan-info" role="tab" aria-selected="true"> Loan Info </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="{ active: tab === 'property-info' }"
                    (click)="selectTab('property-info')" data-bs-toggle="tab" href="#property-info" role="tab"
                    aria-selected="false"> Property Info </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="{ active: tab === 'title-info' }" (click)="selectTab('title-info');"
                    data-bs-toggle="tab" href="#title-info" role="tab" aria-selected="false">
                    Title Info</a>
                </li>
              </ul>
            </div>
            <div class="float-end">
              <button class="btn btn-outline-primary" (click)="onOrderCreditDrawerToggled()">Order Credit</button>
              <button type="button"
                [disabled]="isLoanReadOnly"
                class="btn btn-primary"
                (click)="saveLoanInfo()">
                <i class="fas fa-save"></i>
                 {{isLoanReadOnly ? 'Save (Readonly)' : 'Save'}}
              </button>
            </div>
          </div>
        </div>

        <div class="tab-content my-3">
        <form #qaLoanInfoForm="ngForm" novalidate id="qaLoanInfoForm" name="qaLoanInfoForm">
          <div>
            <div #loanInfo id="loan-info" style="min-height: 600px;">
              <!-- <div class="card"> -->
                <div class="card-header header-bg mb-4">
                  <h4 class="card-title" style="display: inline-block;">
                    <i class="fas fa-university me-2"></i>
                    Loan Info
                  </h4>
                </div>
                <!-- <div class="card-body"> -->
                  <div class="row">
                    <div class="col-12 col-md-6 pr">
                      <div class="mb-2">
                        <label class="form-label" for="loanPurpose">Loan Purpose</label>
                        <div>
                          <div class="btn-group w-100" role="group" aria-label="Loan Purpose"
                          [ngClass]="{
                            'invalid-requested-border' : !mortgage.subjectProperty.purposeOfLoan
                              && quickApplyFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] == 'requested',
                            'invalid-required-border' : !mortgage.subjectProperty.purposeOfLoan
                              && quickApplyFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] == 'required'
                          }">
                            <ng-container *ngFor="let lp of loanPurposes">
                              <input type="radio" class="btn-check radio-button-primary" name="{{lp.value}}"
                                id="{{lp.value}}" value="{{lp.value}}"
                                [(ngModel)]="loanPurpose" autocomplete="off" (change)="onLoanPurposeChanged()"
                                [required]="quickApplyFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] == 'required' ||
                                    quickApplyFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] == 'requested'"/>
                              <label class="btn btn btn-md btn-soft-primary"
                                for="{{lp.value}}">{{lp.name}}</label>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-12 col-sm-6 mb-2 custom-div-left-side" *ngIf="!isRefinance">
                          <label class="form-label" for="purchasePrice">Purchase Price</label>
                          <currency-input [name]="'purchasePrice'" class="custom-input-left-side"
                            [placeholder]="'Enter Purchase Price'" [isZeroValid]="false" [allowDecimals]="false"
                            [(ngModel)]="mortgage.transactionDetail.purchasePriceAmount" (blur)="onPurchasePriceChanged()"
                            [ngClass]="{'invalid-requested': !mortgage.transactionDetail.purchasePriceAmount
                            && quickApplyFieldsConfig['mortgage.subjectProperty.presentValue'] == 'requested',
                            'invalid-required': !mortgage.transactionDetail.purchasePriceAmount
                            && quickApplyFieldsConfig['mortgage.subjectProperty.presentValue'] == 'required'}"
                            [required]="quickApplyFieldsConfig['mortgage.subjectProperty.presentValue'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.subjectProperty.presentValue'] == 'requested'">
                          </currency-input>
                        </div>
                        <div class="col-12 col-sm-6" [ngClass]="{'custom-div-right-side': !isRefinance, 'custom-div-left-side': isRefinance }">
                          <label class="form-label" for="appraisedValue">Appraised Value</label>
                          <currency-input [name]="'appraisedValue'"
                            [placeholder]="'Enter Appraised Value'" [isZeroValid]="false" [allowDecimals]="false"
                            [(ngModel)]="mortgage.mortgageTerm.appraisedValue" (blur)="onAppraisedValueChanged()"
                            [ngClass]="{'custom-input-right-side': !isRefinance, 'custom-input-left-side': isRefinance,
                              'invalid-requested': !mortgage.mortgageTerm.appraisedValue
                            && quickApplyFieldsConfig['mortgage.mortgageTerm.appraisedValue'] == 'requested',
                            'invalid-required': !mortgage.mortgageTerm.appraisedValue
                            && quickApplyFieldsConfig['mortgage.mortgageTerm.appraisedValue'] == 'required'}"
                            [required]="quickApplyFieldsConfig['mortgage.mortgageTerm.appraisedValue'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.mortgageTerm.appraisedValue'] == 'requested'">
                          </currency-input>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-left-side" *ngIf="!isRefinance">
                          <div class="d-flex">
                            <label class="form-label" for="downPayment" >Down Payment</label>
                            <!-- <a
                              class="ms-2 mb-1 qa-btn "
                              placement='bottom'
                              [popover]='qaSourcesPopoverTemplate'
                              containerClass='sources-popover'
                              #qaSourcesPopover='bs-popover'
                              (onShown)='onShownPopover(qaSourcesPopover)'
                              (onHidden)='onHiddenPopover()'
                            > <i class="fas fa-pencil-alt"></i> Sources
                            </a> -->
                            <ng-template #qaSourcesPopoverTemplate>
                              <downpayment-sources-popover [mortgage]="mortgage" (closeRequested)='onPopoverCloseRequested()'></downpayment-sources-popover>
                            </ng-template>
                          </div>

                          <currency-input [name]="'downPayment'"
                            [isZeroValid]="true"
                            [allowDecimals]="false"
                            [allowNegative]="false"
                            class="custom-input-left-side"
                            [(ngModel)]="downPayment" (blur)="onDownPaymentChanged()">
                          </currency-input>
                        </div>

                        <div class="col-12 col-sm-6 row custom-div-right-side pe-0">
                          <div class="col-12 col-sm-8 pe-0">
                            <label class="form-label" for="baseLoanAmount">Base Loan Amount</label>
                            <currency-input [name]="'baseLoanAmount'"
                              class="custom-input-left-side custom-input-right-side"
                              [placeholder]="'Enter Base Loan Amount'" [isZeroValid]="false" [allowDecimals]="false"
                              [(ngModel)]="mortgage.mortgageTerm.amount" (blur)="onBaseLoanAmountChanged()"
                              [ngClass]="{'invalid-requested': !mortgage.mortgageTerm.amount
                                && quickApplyFieldsConfig['mortgage.mortgageTerm.amount'] == 'requested',
                                'invalid-required': !mortgage.mortgageTerm.amount
                                && quickApplyFieldsConfig['mortgage.mortgageTerm.amount'] == 'required'}"
                                [required]="quickApplyFieldsConfig['mortgage.mortgageTerm.amount'] == 'required' ||
                                quickApplyFieldsConfig['mortgage.mortgageTerm.amount'] == 'requested'">
                            </currency-input>
                          </div>
                          <div class="col-12 col-sm-4 custom-div-right-side pe-0">
                            <label class="form-label" for="ltv">LTV</label>
                            <percent-input [name]="'ltv'" [required]="true"
                              class="custom-input-right-side"
                              [(ngModel)]="ltv" #ltvValue="ngModel"
                              (blur)="onLtvChanged()">
                            </percent-input>
                          </div>
                        </div>

                      </div>

                      <div class="row mb-2" *ngIf="isRefinance">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="refiPurpose">Refinance Type</label>
                          <select class="form-select custom-input-left-side" id="refiPurpose"
                            name="refiPurpose" [(ngModel)]="mortgage.subjectProperty.refiPurpose" #refiPurpose="ngModel"

                            [required]="quickApplyFieldsConfig['mortgage.subjectProperty.refiPurpose'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.subjectProperty.refiPurpose'] == 'requested'"
                            [ngClass]="{
                              'invalid-requested' : refiPurpose && refiPurpose.invalid && refiPurpose.errors && refiPurpose.errors.required
                                && quickApplyFieldsConfig['mortgage.subjectProperty.refiPurpose'] == 'requested',
                              'invalid-required' : refiPurpose && refiPurpose.invalid && refiPurpose.errors && refiPurpose.errors.required
                                && quickApplyFieldsConfig['mortgage.subjectProperty.refiPurpose'] == 'required'
                            }">
                            <option [ngValue]="null">--Select a Refinance Purpose--</option>
                            <option *ngFor="let refiPurpose of refinancePurposes" [ngValue]="refiPurpose.value">
                              {{refiPurpose.name}}</option>
                          </select>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side">
                          <label class="form-label" for="targetCashoutAmount">Target Cashout Amount</label>
                          <currency-input [name]="'targetCashoutAmount'" [placeholder]="'Enter Target Cashout Amount'"
                            class="custom-input-right-side"
                            [isZeroValid]="false" [allowDecimals]="false"
                            [(ngModel)]="mortgage.transactionDetail.targetCashoutAmount">
                          </currency-input>
                        </div>
                      </div>
                      <div class="row mb-2" *ngIf="!isRefinance && !isPurchase">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="lotOriginalCost">Lot Orig. Cost</label>
                          <currency-input [name]="'lotOriginalCost'" [placeholder]="'Enter Lot Orig. Cost'"
                            [isZeroValid]="false" [allowDecimals]="false"
                            class="custom-input-left-side"
                            [(ngModel)]="mortgage.subjectProperty.lotOriginalCost"
                            [ngClass]="{'invalid-requested': !mortgage.subjectProperty.lotOriginalCost
                            && quickApplyFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] == 'requested',
                            'invalid-required': !mortgage.subjectProperty.lotOriginalCost
                            && quickApplyFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] == 'required'}"
                            [required]="quickApplyFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] == 'requested'">
                          </currency-input>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side">
                          <label class="form-label" for="lotAppraisedValue">Lot Appraised Value</label>
                          <currency-input [name]="'lotAppraisedValue'" [placeholder]="'Enter Lot Appraised Value'"
                            class="custom-input-right-side" [isZeroValid]="true" [allowDecimals]="false"
                            [(ngModel)]="mortgage.subjectProperty.lotAppraisedValue"
                            [ngClass]="{'invalid-requested': !mortgage.subjectProperty.lotAppraisedValue
                            && quickApplyFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] == 'requested',
                            'invalid-required': !mortgage.subjectProperty.lotAppraisedValue
                            && quickApplyFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] == 'required'}"
                            [required]="quickApplyFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] == 'requested'">
                          </currency-input>
                        </div>
                      </div>
                      <div class="mb-2" *ngIf="isRefinance">
                        <label class="form-label" for="governmentRefinanceType">Refinance Program</label>
                        <select class="form-select" id="governmentRefinanceType" name="governmentRefinanceType"
                          [(ngModel)]="mortgage.subjectProperty.refinanceProgram" #governmentRefinanceType="ngModel"
                          [required]="quickApplyFieldsConfig['mortgage.subjectProperty.refinanceProgram'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.subjectProperty.refinanceProgram'] == 'requested'"
                          [ngClass]="{
                            'invalid-requested' : governmentRefinanceType && governmentRefinanceType.invalid && governmentRefinanceType.errors && governmentRefinanceType.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.refinanceProgram'] == 'requested',
                            'invalid-required' : governmentRefinanceType && governmentRefinanceType.invalid && governmentRefinanceType.errors && governmentRefinanceType.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.refinanceProgram'] == 'required'
                          }"
                          >
                          <option [ngValue]="null">--Select a Doc Type--</option>
                          <option *ngFor="let governmentRefinanceType of governmentRefinanceTypes"
                            [ngValue]="governmentRefinanceType.value">
                            {{governmentRefinanceType.name}}</option>
                        </select>
                        <div class="invalid-feedback">This field is required</div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="mortgageAppliedFor">Mortgage Type</label>
                          <select class="form-select custom-input-left-side" id="mortgageAppliedFor" name="mortgageAppliedFor"
                            [(ngModel)]="mortgage.mortgageTerm.mortgageAppliedFor" #mortgageAppliedFor="ngModel"
                            [required]="quickApplyFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] == 'requested'"
                            [ngClass]="{
                              'invalid-requested' : mortgageAppliedFor && mortgageAppliedFor.invalid && mortgageAppliedFor.errors && mortgageAppliedFor.errors.required
                                && quickApplyFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] == 'requested',
                              'invalid-required' : mortgageAppliedFor && mortgageAppliedFor.invalid && mortgageAppliedFor.errors && mortgageAppliedFor.errors.required
                                && quickApplyFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] == 'required'
                            }"
                            >
                            <option [ngValue]="null">--Select a Mortgage Type--</option>
                            <option *ngFor="let mortgageType of mortgageTypes" [ngValue]="mortgageType.value">
                              {{mortgageType.name}}</option>
                          </select>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side">
                          <label class="form-label" for="lienPosition">Lien Position</label>
                          <select class="form-select custom-input-right-side" id="lienPosition" name="lienPosition"
                            [(ngModel)]="mortgage.mortgageTerm.lienPosition" #lienPosition="ngModel"
                            [required]="quickApplyFieldsConfig['mortgage.mortgageTerm.lienPosition'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.mortgageTerm.lienPosition'] == 'requested'"
                            [ngClass]="{
                              'invalid-requested' : lienPosition && lienPosition.invalid && lienPosition.errors && lienPosition.errors.required
                                && quickApplyFieldsConfig['mortgage.mortgageTerm.lienPosition'] == 'requested',
                              'invalid-required' : lienPosition && lienPosition.invalid && lienPosition.errors && lienPosition.errors.required
                                && quickApplyFieldsConfig['mortgage.mortgageTerm.lienPosition'] == 'required'
                            }"
                            >
                            <option [ngValue]="null">--Select a Lien Position--</option>
                            <option *ngFor="let lienPosition of lienPositionTypes" [ngValue]="lienPosition.value">
                              {{lienPosition.name}}</option>
                          </select>
                        </div>
                      </div>
                      <!-- <div class="row mb-2" *ngIf="isRefinance">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="fundingFees">Funding Fees</label>
                          <select class="form-select custom-input-left-side" id="fundingFees" name="fundingFees"> No model
                            <option>Financed</option>
                          </select>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side">
                          <div class="d-flex justify-content-end">
                            <div class="form-check form-switch mb-0">
                              <input class="form-check-input" type="checkbox" [(ngModel)]="manual" id="manual"
                                name="manual"> No model
                              <label class="form-label">Manual</label>
                            </div>
                          </div>
                          <currency-input [name]="'fundingFeesAmount'" [isZeroValid]="false" [allowDecimals]="false"
                            [readonly]="!manual" class="custom-input-right-side"> No model
                          </currency-input>
                        </div>
                      </div> -->
                      <div class="row mb-2">
                        <h5>Total Loan Amount:
                          <strong>{{mortgage.mortgageTerm.totalLoanAmount | currency}}</strong>
                        </h5>
                      </div>
                      <!--<div class="row mb-2">
                        <div class="col-12 col-sm-6">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="subordinate_liens"
                              name="subordinate_liens">
                            No model
                            <label class="form-check-label" for="subordinate_liens">Subordinate Liens</label>
                          </div>
                        </div>
                      </div>-->
                      <div class="row mb-2 mx-1 pb-2 card flex-row justify-content-between">
                        <div class="col-12 col-sm-3 d-block">
                          <div class="d-flex justify-content-between align-items-center">
                            <label>LTV</label>
                            <percent-input [(ngModel)]="ltv" name="ls_ltv" [disabled]="true"></percent-input>
                          </div>
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" [style.width.%]="(ltv || 0)"
                              attr.aria-valuenow="{{ (ltv || 0) }}" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-3 d-block">
                          <div class="d-flex justify-content-between align-items-center">
                            <label>CLTV</label>
                            <percent-input [(ngModel)]='cltv' name="ls_cltv" [disabled]="true"></percent-input>
                          </div>
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" [style.width.%]="(cltv || 0)"
                              attr.aria-valuenow="{{ (cltv || 0) }}" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-3 d-block">
                          <div class="d-flex justify-content-between align-items-center">
                            <label>HCLTV</label>
                            <percent-input [(ngModel)]="hcltv" name="ls_hltv" [disabled]="true"></percent-input>
                          </div>
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" [style.width.%]="(hcltv || 0)"
                              attr.aria-valuenow="{{ (hcltv || 0) }}" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-12 col-sm-12">
                          <label class="form-label" for="amortizationType">Amortization Type</label>
                          <select class="form-select" id="amortizationType" name="amortizationType" (change)="onChangeAmortizatonType()"
                            [(ngModel)]="mortgage.mortgageTerm.amortizationType" #amortizationType="ngModel"
                            [required]="quickApplyFieldsConfig['mortgage.mortgageTerm.amortizationType'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.mortgageTerm.amortizationType'] == 'requested'"
                            [ngClass]="{
                              'invalid-requested' : amortizationType && amortizationType.invalid && amortizationType.errors && amortizationType.errors.required
                                && quickApplyFieldsConfig['mortgage.mortgageTerm.amortizationType'] == 'requested',
                              'invalid-required' : amortizationType && amortizationType.invalid && amortizationType.errors && amortizationType.errors.required
                                && quickApplyFieldsConfig['mortgage.mortgageTerm.amortizationType'] == 'required'
                            }"
                            >
                            <option [ngValue]="null">--Select an Amortization Type--</option>
                            <option *ngFor="let amortizationType of amortizationTypes"
                              [ngValue]="amortizationType.value">
                              {{amortizationType.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="note_rate">Note Rate</label>
                          <percent-input [name]="'note_rate'" [(ngModel)]="mortgage.mortgageTerm.interestRate" [shiftInputToLeftWhenEditingInline]="true"
                                         (blur)='onNoteRateChanged()'
                            [ngClass]="{'custom-input-left-side': isAmortizationTypeFixed, 'invalid-requested': !mortgage.mortgageTerm.interestRate
                            && quickApplyFieldsConfig['mortgage.mortgageTerm.interestRate'] == 'requested',
                              'invalid-required': !mortgage.mortgageTerm.interestRate
                            && quickApplyFieldsConfig['mortgage.mortgageTerm.interestRate'] == 'required'}"
                            [required]="quickApplyFieldsConfig['mortgage.mortgageTerm.interestRate'] == 'required' ||
                              quickApplyFieldsConfig['mortgage.mortgageTerm.interestRate'] == 'requested'">
                          </percent-input>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side" *ngIf="isAmortizationTypeFixed">
                          <label class="form-label" for="qualifying_rate">{{!isAmortizationTypeARM ? 'ARM' : ''}} Qualifying Rate</label>
                          <percent-input [name]="'qualifying_rate'" [(ngModel)]="mortgage.mortgageTerm.qualifyingRate"
                            class="custom-input-right-side"
                            [readonly]="isAmortizationTypeFixed"
                            [ngClass]="{'invalid-requested': !mortgage.mortgageTerm.qualifyingRate
                            && quickApplyFieldsConfig['mortgage.mortgageTerm.qualifyingRate'] == 'requested',
                              'invalid-required': !mortgage.mortgageTerm.qualifyingRate
                            && quickApplyFieldsConfig['mortgage.mortgageTerm.qualifyingRate'] == 'required'}"
                            [required]="quickApplyFieldsConfig['mortgage.mortgageTerm.qualifyingRate'] == 'required' ||
                              quickApplyFieldsConfig['mortgage.mortgageTerm.qualifyingRate'] == 'requested'">
                          </percent-input>
                        </div>
                      </div>
                      <div class="row mb-2" *ngIf="isPurchase">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="interestRateBuydown">Interest Rate Buydown</label>
                          <select class="form-select custom-input-left-side" id="buydownSelect" name="buydownSelect"
                            [(ngModel)]="buydown">
                            <option [ngValue]="null">--Select one--</option>
                            <option *ngFor="let buydownType of buydownTypes"
                              [ngValue]="buydownType.value">
                              {{buydownType.name}}
                            </option>
                          </select>
                        </div>
                        <div class="col-12 col-xs-8 col-sm-4 custom-div-right-side pe-0">
                          <label class="form-label">Paid By</label>
                          <select class="form-select custom-input-left-side custom-input-right-side" id="sellerPaidSelect" name="sellerPaidSelect">
                            <option [ngValue]="'sellerPaid'">
                              Seller Paid</option>
                          </select>
                        </div>
                        <div class="col-12 col-xs-4 col-sm-2 ps-0" >
                          <div style="height: 27.5px;"> </div>
                          <button class="btn btn-outline-primary" placement='top'
                            [popover]='qaBuydownPopoverTemplate'
                            [outsideClick]="true"
                            containerClass='buydown-popover'
                            #qaBuydownPopover='bs-popover'>
                            Summary
                          </button>
                          <ng-template #qaBuydownPopoverTemplate>
                            <buydown-popover [mortgage]="mortgage" [buydownType]="buydown" (closeRequested)="onClosePopover()"></buydown-popover>
                          </ng-template>
                        </div>
                      </div>

                      <div class="row mb-2">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="amortization_term">Amortization Term (Months)</label>
                          <number-input [allowNegative]="false" [(ngModel)]="mortgage.mortgageTerm.noOfMonths"
                            name="amortization_term" (blur)="onAmortizationTermChanged()"
                            [placeholder]="'Amortization Term (Months)'" class="custom-input-left-side"
                            [ngClass]="{'invalid-requested': !mortgage.mortgageTerm.noOfMonths
                            && quickApplyFieldsConfig['mortgage.mortgageTerm.noOfMonths'] == 'requested',
                              'invalid-required': !mortgage.mortgageTerm.noOfMonths
                            && quickApplyFieldsConfig['mortgage.mortgageTerm.noOfMonths'] == 'required'}"
                            [required]="quickApplyFieldsConfig['mortgage.mortgageTerm.noOfMonths'] == 'required' ||
                              quickApplyFieldsConfig['mortgage.mortgageTerm.noOfMonths'] == 'requested'">
                          </number-input>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side">
                          <div class="d-flex justify-content-between">
                            <label class="form-label" for="interest_only">Interest Only
                            </label>
                            <div class="form-check form-switch">
                              <input [(ngModel)]="mortgage.extension.isInterestOnly" class="form-check-input"
                                type="checkbox" id="interestOnlySwitchCheck" name="interestOnlySwitchCheck">
                            </div>
                          </div>

                          <number-input [allowNegative]="false" [(ngModel)]="mortgage.extension.interestOnlyTermMonths"
                            name="interest_only_months" [readonly]="!mortgage.extension.isInterestOnly"
                            class="custom-input-right-side"
                            [ngClass]="{'invalid-requested': !mortgage.extension.interestOnlyTermMonths && mortgage.extension.isInterestOnly
                            && quickApplyFieldsConfig['mortgage.extension.interestOnlyTermMonths'] == 'requested',
                              'invalid-required': !mortgage.extension.interestOnlyTermMonths && mortgage.extension.isInterestOnly
                            && quickApplyFieldsConfig['mortgage.extension.interestOnlyTermMonths'] == 'required'}"
                            [required]="quickApplyFieldsConfig['mortgage.extension.interestOnlyTermMonths'] == 'required' &&
                              mortgage.extension.isInterestOnly ||
                              quickApplyFieldsConfig['mortgage.extension.interestOnlyTermMonths'] == 'requested' &&
                              mortgage.extension.isInterestOnly" >
                          </number-input>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <!--<div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="compoundWaiver">Compound Waiver</label>
                          <select class="form-select custom-input-left-side" id="compoundWaiver" name="compoundWaiver"> No model
                            <option [ngValue]="null">None Waived</option>
                          </select>
                        </div>-->
                        <div class="col-12 col-sm-6">
                          <div class="d-flex justify-content-between">
                            <label class="form-label" for="loanFico">Loan FICO
                            </label>
                            <div class="form-check form-switch mb-0">
                              <input [(ngModel)]="loanFico" class="form-check-input" type="checkbox"
                                id="loanFicoSwitchCheck" name="loanFicoSwitchCheck"> <!--No model-->
                              <label class="form-label">No FICO</label>
                            </div>
                          </div>

                          <number-input [allowNegative]="false" name="loanFico" [readonly]="loanFico" [(ngModel)]="ficoOnFile"
                            >
                          </number-input>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 pl">
                      <div class="row mb-2 px-2" >
                        <div class="table-container">
                        <table class="table table-bordered table-proposed-monthly-payment m-0">
                          <thead>
                            <tr>
                              <td colspan="5">
                                <div class="d-flex justify-content-between align-items-center">
                                  <h5 class="m-1">Proposed Monthly Payment</h5>
                                  <!--
                                  <a href="javascript:void(0)"><i class="fa fa-eye"></i> Preview</a>
                                  -->
                                </div>

                              </td>
                            </tr>
                            <tr class="text-muted" style="font-size: 0.7rem;">
                              <td class="col-4">PARAMETERS</td>
                              <td class="col-1"></td>
                              <td class="col-2" style="min-width: 110px;">FACTOR</td>
                              <td class="col-3">VALUE</td>
                              <td class="col-2 text-end">MONTHLY</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="table-static-bg">
                              <td>First Mortgage</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td class="text-end">{{mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest | currency}}</td>
                            </tr>
                            <tr>
                              <td>Other Financing (P&I)</td>
                              <td class="text-center">

                              </td>
                              <td>
                                <select class="input-element no-border" id="otherFinancingFactor"
                                  name="otherFinancingFactor" [(ngModel)]="otherFinancingFactor" (ngModelChange)="onOtherFinancingExpensesChanged()">
                                  <option *ngFor="let payPeriod of incomePayPeriods" [ngValue]="payPeriod.value">
                                    {{payPeriod.name}}</option>
                                </select>
                              </td>
                              <td>
                                <currency-input
                                  [name]="'otherFinancingAmount'"
                                  [(ngModel)]="otherFinancingAmount"
                                  (change)="onOtherFinancingExpensesChanged()">
                                </currency-input>
                              </td>
                              <td class="text-end">{{mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest | currency}}</td>
                            </tr>
                            <tr>
                              <td>HOI</td>
                              <td class="text-center">

                              </td>
                              <td>
                                <select class="input-element no-border" id="hoiFactor"
                                  name="hoiFactor" [(ngModel)]="hoiFactor" (ngModelChange)="onHomeownersInsuranceChanged()">
                                  <option *ngFor="let payPeriod of incomePayPeriods" [ngValue]="payPeriod.value">
                                    {{payPeriod.name}}</option>
                                </select>
                              </td>
                              <td>
                                <currency-input
                                  [name]="'hoiAmount'"
                                  [(ngModel)]="hoiAmount"
                                  (change)="onHomeownersInsuranceChanged()">
                                </currency-input>
                              </td>
                              <td class="text-end">{{mortgage.proposedHousingExpense.homeownersInsurance | currency}}</td>
                            </tr>
                            <tr>
                              <td>Supplemental</td>
                              <td class="text-center">
                                <a (click)="onSupplementalPropertyInsuranceBreakDownClicked()"><i class="fa fa-calculator"></i></a>
                              </td>
                              <td>
                                <select class="input-element no-border" id="supplementalFactor"
                                  name="supplementalFactor" [(ngModel)]="supplementalFactor" (ngModelChange)="onSupplementalPropertyInsurancePayPeriodChanged()">
                                  <option *ngFor="let payPeriod of incomePayPeriods" [ngValue]="payPeriod.value">
                                    {{payPeriod.name}}</option>
                                </select>
                              </td>
                              <td>
                                <currency-input [name]="'supplementalAmount'"
                                  [disabled]="isSupplementalPropertyInsuranceBrokenDown"
                                  [(ngModel)]="supplementalAmount" (change)="onSupplementalPropertyInsuranceTotalWithoutBreakdownChanged()">
                                </currency-input>
                              </td>
                              <td class="text-end">{{mortgage.proposedHousingExpense.supplementalPropertyInsurance | currency}}</td>
                            </tr>
                            <tr>
                              <td>Property Taxes{{ propertyTaxesFieldNameSuffix }}</td>
                              <td class="text-center">
                                <a (click)="onRealEstateTaxBreakDownClicked()"><i class="fa fa-calculator"></i></a>
                              </td>
                              <td>
                                <select class="input-element no-border" id="propertyTaxesFactor"
                                  name="propertyTaxesFactor" [(ngModel)]="propertyTaxesFactor" (ngModelChange)="onRealEstateTaxPayPeriodChanged()">
                                  <option *ngFor="let payPeriod of incomePayPeriods" [ngValue]="payPeriod.value">
                                    {{payPeriod.name}}</option>
                                </select>
                              </td>
                              <td>
                                <currency-input [name]="'propertyTaxesAmount'"
                                  [disabled]="isRealEstateTaxBrokenDown"
                                  [(ngModel)]="propertyTaxesAmount" (change)="onRealEstateTaxTotalWithoutBreakdownChanged()">
                                </currency-input>
                              </td>
                              <td class="text-end">{{mortgage.proposedHousingExpense.realEstateTax | currency }}</td>
                            </tr>
                            <tr>
                              <td>MI</td>
                              <td class="text-center">
                                <a (click)="onMortgageInsuranceClicked()"><i class="fa fa-calculator"></i></a>
                              </td>
                              <td>
                                <select class="input-element no-border" id="mortgageInsuranceFactor"
                                  name="mortgageInsuranceFactor" [(ngModel)]="mortgageInsuranceFactor" (ngModelChange)="onMortgageInsuranceChanged()">
                                  <option *ngFor="let payPeriod of incomePayPeriods" [ngValue]="payPeriod.value">
                                    {{payPeriod.name}}</option>
                                </select>
                              </td>
                              <td>
                                <currency-input [name]="'mortgageInsuranceAmount'"
                                  [(ngModel)]="mortgageInsuranceAmount" (change)="onMortgageInsuranceChanged()">
                                </currency-input>
                              </td>
                              <td class="text-end">{{mortgage.proposedHousingExpense.mortgageInsurance | currency }}</td>
                            </tr>
                            <tr>
                              <td>Association Dues</td>
                              <td class="text-center">
                                <a (click)="onHoaAndCondoFeesBreakDownClicked()"><i class="fa fa-calculator"></i></a>
                              </td>
                              <td>
                                <select class="input-element no-border" id="associationDuesFactor"
                                  name="associationDuesFactor" [(ngModel)]="associationDuesFactor" (ngModelChange)="onHoaAndCondoFeesPayPeriodChanged()">
                                <option *ngFor="let payPeriod of incomePayPeriods" [ngValue]="payPeriod.value">
                                  {{payPeriod.name}}</option>
                              </select>
                              </td>
                              <td>
                                <currency-input [name]="'associationDuesAmount'"
                                  [disabled]="isHoaAndCondoFeesBrokenDown"
                                  [(ngModel)]="associationDuesAmount" (change)="onHoaAndCondoFeesTotalWithoutBreakdownChanged()">
                                </currency-input>
                              </td>
                              <td class="text-end">{{mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees | currency}}</td>
                            </tr>
                            <tr>
                              <td>Other</td>
                              <td colspan="2">
                                <text-input name="otherDescription" [placeholder]="'Enter Description'"
                                [(ngModel)]="mortgage.proposedHousingExpense.otherHousingExpenseDescription"></text-input>
                              </td>
                              <td>
                                <currency-input [name]="'otherHousingExpense'"
                                [(ngModel)]="mortgage.proposedHousingExpense.otherHousingExpense"
                                (change)="onOtherHousingExpensesChanged()">
                              </currency-input>
                              </td>
                              <td class="text-end">{{mortgage.proposedHousingExpense.otherHousingExpense | currency}}</td>
                            </tr>
                            <tr>
                              <td colspan="5">
                                <div class="d-flex justify-content-between align-items-center">
                                  <h5>Total PITI</h5>
                                  <h5>{{mortgage.calculatedStats.proposedMonthlyPaymentTotal | currency}}</h5>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      </div>

                      <div class="row mb-2 px-2 mt-5" *ngIf="isPurchase">
                        <div class="table-container">
                        <table class="table table-bordered m-0">
                          <thead>
                            <tr>
                              <td colspan="5">
                                <div class="d-flex justify-content-between align-items-center">
                                  <h5 class="m-1">Purchase Credits:
                                    <span class="pct-total-color">{{mortgage.transactionDetail.purchaseCredits
                                    && mortgage.transactionDetail.purchaseCredits.length > 0 ? (purchaseCreditsTotal | currency ) : '--'}}
                                    </span>
                                  </h5>
                                  <a href="javascript:void(0)" (click)="addPurchaseCredit()"><i class="fa fa-plus"></i> Add</a>
                                </div>

                              </td>
                            </tr>
                            <tr class="text-muted" style="font-size: 0.7rem;">
                              <td class="col-4">CREDIT TYPE</td>
                              <td class="col-4">SOURCE TYPE</td>
                              <td class="col-3">AMOUNT</td>
                              <td class="col-1"></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let purchaseCredit of mortgage.transactionDetail.purchaseCredits">
                              <td>
                                <select class="input-element no-border" name="creditType_{{purchaseCredit.purchaseCreditId}}"
                                  id="creditType_{{purchaseCredit.purchaseCreditId}}"
                                  [(ngModel)]="purchaseCredit.purchaseCreditType">
                                  <option [ngValue]="null || undefined">
                                    --Select--</option>
                                  <option *ngIf="purchaseCredit.purchaseCreditType == lenderCreditType && 
                                    applicationContext.application.channel == ChannelEnum.Wholesale" [ngValue]="lenderCreditType">Lender Credit</option>
                                  <option *ngFor="let creditType of purchaseCreditTypes" [ngValue]="creditType.value">
                                    {{creditType.name}}</option>
                                </select>
                              </td>
                              <td>
                                <select class="input-element no-border" name="sourceType_{{purchaseCredit.purchaseCreditId}}"
                                  id="sourceType_{{purchaseCredit.purchaseCreditId}}"
                                  [(ngModel)]="purchaseCredit.sourceType">
                                  <option *ngIf="purchaseCredit.sourceType == PurchaseCreditSourceType.Lender && 
                                    applicationContext.application.channel == ChannelEnum.Wholesale" [ngValue]="PurchaseCreditSourceType.Lender">Lender</option>
                                  <option *ngFor="let sourceType of downPaymentSourceTypes" [ngValue]="sourceType.value">
                                    {{sourceType.name}}</option>
                                </select>
                              </td>
                              <td>
                                <currency-input name="purchaseCreditAmount_{{purchaseCredit.purchaseCreditId}}"
                                  [(ngModel)]="purchaseCredit.purchaseCreditAmount" [placeholder]="'Enter Amount'"
                                  [allowDecimals]="false" (ngModelChange)="calculatePurchaseCreditsTotal()"
                                  [readonly]="(purchaseCredit.purchaseCreditType == lenderCreditType || purchaseCredit.sourceType == PurchaseCreditSourceType.Lender) && 
                                  applicationContext.application.channel == ChannelEnum.Wholesale"></currency-input>
                              </td>
                              <td class="text-center">
                                <a class="text-danger" (click)="removePurchaseCredit(purchaseCredit.purchaseCreditId)"><i class="fas fa-trash-alt"></i></a>
                              </td>
                            </tr>
                            <tr *ngIf="!mortgage.transactionDetail.purchaseCredits || mortgage.transactionDetail.purchaseCredits.length == 0">
                              <td colspan="4" class="text-center">
                                No records found
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      </div>

                      <!-- <div class="row mt-4 mb-3">
                        <div class="col-12">
                          <label class="form-label" for="propertiesFinanced">Properties Financed</label>
                          <text-input name="propertiesFinanced"></text-input>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="projectedReserveAmount">Projected Reserve Amount (LPA)</label>
                          <currency-input [name]="'projectedReserveAmount'" [isZeroValid]="false"
                            [allowDecimals]="false" class="custom-input-left-side">
                          </currency-input>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side">
                          <label class="form-label" for="reserves">Reserves</label>
                          <number-input [(ngModel)]="mortgage.transactionDetail.reserves" [allowNegative]="false"
                            name="reserves" class="custom-input-right-side">
                          </number-input>
                        </div>
                      </div> -->
                      <div class="row mb-3">
                        <div class="col-12">
                          <label class="form-label" for="alterationsImprovementsAndRepairsAmount">Improvements,
                            Renovations, and Repair
                          </label>
                          <currency-input name="alterationsImprovementsAndRepairsAmount"
                            [(ngModel)]="mortgage.transactionDetail.alterationsImprovementsAndRepairsAmount"
                            [allowDecimals]="false"></currency-input>
                        </div>
                      </div>
                      <div class="row mb-3" *ngIf="isPurchase">
                        <div class="col-12">
                          <label class="form-label" for="communityLendingProduct">Community Lending Product
                          </label>
                          <select class="form-select" id="communityLendingProduct" name="communityLendingProduct"
                              [(ngModel)]="mortgage.subjectProperty.communityLendingProductType"  #communityLendingProductType="ngModel"
                              [required]="quickApplyFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] == 'required' ||
                              quickApplyFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] == 'requested'"
                              [ngClass]="{
                                'invalid-requested' : communityLendingProductType && communityLendingProductType.invalid && communityLendingProductType.errors && communityLendingProductType.errors.required
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] == 'requested',
                                'invalid-required' : communityLendingProductType && communityLendingProductType.invalid && communityLendingProductType.errors && communityLendingProductType.errors.required
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] == 'required'
                              }">
                            <option [ngValue]="null">--Select One--</option>
                            <option *ngFor="let lendingProductType of lendingProductTypes" [ngValue]="lendingProductType.value">
                              {{lendingProductType.name}}</option>
                          </select>
                        </div>
                      </div>
                      <!-- <div class="row mb-3">
                        <div class="col-12">
                          <label class="form-label" for="productDescription">Product Description (AUS/DU)
                          </label>
                          <text-input name="productDescription"></text-input>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="lpaOffering" name="lpaOffering">
                            <label class="form-check-label" for="lpaOffering">LPA Offering</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="alternateDocLoan"
                              name="alternateDocLoan">
                            <label class="form-check-label" for="alternateDocLoan">Alternate Doc Loan</label>
                          </div>
                        </div>
                      </div> -->
                      <div class="row mb-2">
                        <div class="col-12">
                          <a href="javascript:void(0)" (click)="showAdvancedFeatures = !showAdvancedFeatures">Advanced Features
                            <i class="ms-1 fas" [ngClass]="{'fa-angle-up': showAdvancedFeatures, 'fa-angle-down': !showAdvancedFeatures}" ></i>
                          </a>
                        </div>
                      </div>
                      <div class="row mb-2" [hidden]="!showAdvancedFeatures">
                        <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="prepayPenalty"
                              name="prepayPenalty" [(ngModel)]="mortgage.extension.isPenaltyChargedInEventOfPrepayment">
                            <label class="form-check-label" for="prepayPenalty">Pre-Pay Penalty</label>
                          </div>
                        </div>
                        <!-- <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="seasoning"
                              name="seasoning">
                            <label class="form-check-label" for="seasoning">Seasoning</label>
                          </div>
                        </div> -->
                        <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="balloon"
                              name="balloon" [(ngModel)]="mortgage.extension.isBalloonPaymentRequiredToPayOffLoan">
                            <label class="form-check-label" for="balloon">Balloon</label>
                          </div>
                        </div>
                        <!-- <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="mortgageLates"
                              name="mortgageLates">
                            <label class="form-check-label" for="mortgageLates">Mortgage Lates</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="sellerProvidedBelowMarketFinancing" name="sellerProvidedBelowMarketFinancing">

                            <label class="form-check-label" for="sellerProvidedBelowMarketFinancing">Seller Provided Below Market Financing</label>
                          </div>
                        </div> -->
                        <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="negativeAmortization"
                              name="negativeAmortization" [(ngModel)]="mortgage.extension.hasNegativeAmortization">
                            <label class="form-check-label" for="negativeAmortization">Negative Amortization</label>
                          </div>
                        </div>
                        <!-- <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="affordableLoan"
                              name="affordableLoan">
                            <label class="form-check-label" for="affordableLoan">Affordable Loan</label>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>

                <!-- </div> -->
              <!-- </div> -->
            </div>
            <div #propertyInfo id="property-info" style="min-height: 600px;" class="pt-3">
              <!-- <div class="card"> -->
                <div class="card-header header-bg mb-4">
                  <h4 class="card-title" style="display: inline-block;">
                    <i class="fas fa-home me-2"></i>
                    Property Info
                  </h4>
                </div>
                <!-- <div class="card-body"> -->
                  <div class="row">
                    <div class="col-12 col-sm-6 pr">
                      <ng-container  *ngIf="!isSubjectPropertyAddressHidden">
                        <div class="d-flex justify-content-end" *ngIf="!hasApplicationReceivedKeyDate">
                          <div class="form-check form-switch mb-0">
                            <input [(ngModel)]="isTbd" class="form-check-input" type="checkbox"
                              id="tbdSwitchCheck" name="tbdSwitchCheck"
                              (ngModelChange)="onTBDCheckChanged()">
                            <label class="form-label mb-0">To be determined</label>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="form-group col-xs-12 col-sm-8 custom-div-left-side">
                            <label for="address1">Subject Property Address</label>
                            <address-autocomplete-input
                              #address='ngModel'
                              (addressChange)='handleAddressChange($event)'
                              [(ngModel)]='mortgage.subjectProperty.address1'
                              [disabled]='isTbd && !hasApplicationReceivedKeyDate'
                              [ngClass]="{
                                'invalid-requested' : address && address.invalid && address.errors && address.errors.required
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.address1'] == 'requested',
                                'invalid-required' : address && address.invalid && address.errors && address.errors.required
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.address1'] == 'required'
                              }"
                              [required]="quickApplyFieldsConfig['mortgage.subjectProperty.address1'] == 'required' ||
                                quickApplyFieldsConfig['mortgage.subjectProperty.address1'] == 'requested'"
                              class='custom-input-left-side'
                              id='address1'
                              name='address1'
                              placeholder=''
                              type='text'
                            ></address-autocomplete-input>
                          </div>
                          <div class="form-group col-xs-12 col-sm-4 custom-div-right-side">
                            <label for="address2">Unit/Suite</label>
                            <input id="address2" name="address2" type="text" class="form-control custom-input-right-side"
                              [(ngModel)]="mortgage.subjectProperty.address2" [disabled]="isTbd && !hasApplicationReceivedKeyDate"/>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="form-group col-xs-12 col-sm-4 custom-div-left-side">
                            <label for="propertyCity">City</label>
                            <input id="propertyCity" name="propertyCity" type="text" class="form-control custom-input-left-side"
                              [(ngModel)]="mortgage.subjectProperty.city" #propertyCity="ngModel"
                              [required]="quickApplyFieldsConfig['mortgage.subjectProperty.city'] == 'required' ||
                              quickApplyFieldsConfig['mortgage.subjectProperty.city'] == 'requested'"
                              [ngClass]="{
                                'invalid-requested' : propertyCity && propertyCity.invalid && propertyCity.errors && propertyCity.errors.required
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.city'] == 'requested',
                                'invalid-required' : propertyCity && propertyCity.invalid && propertyCity.errors && propertyCity.errors.required
                                  && quickApplyFieldsConfig['mortgage.subjectProperty.city'] == 'required'
                              }" />
                          </div>
                          <div class="form-group col-xs-12 col-sm-2 px-0">
                                <label for="propertyState">State</label>
                                <select id="propertyState" name="propertyState" #propertyState="ngModel"
                                  class="form-select custom-input-left-side custom-input-right-side"
                                  placeholder="State" [(ngModel)]="mortgage.subjectProperty.state"
                                  [required]="quickApplyFieldsConfig['mortgage.subjectProperty.state'] == 'required' ||
                                  quickApplyFieldsConfig['mortgage.subjectProperty.state'] == 'requested'"
                                  [ngClass]="{
                                    'invalid-requested' : propertyState && propertyState.invalid && propertyState.errors && propertyState.errors.required
                                      && quickApplyFieldsConfig['mortgage.subjectProperty.state'] == 'requested',
                                    'invalid-required' : propertyState && propertyState.invalid && propertyState.errors && propertyState.errors.required
                                      && quickApplyFieldsConfig['mortgage.subjectProperty.state'] == 'required'
                                  }" >
                                  <option [ngValue]="null"></option>
                                  <option *ngFor="let state of states" [ngValue]="state.value">
                                    {{ state.name }}
                                  </option>
                                </select>
                              </div>
                              <div class="form-group col-xs-12 col-sm-3 px-0">
                                <label for="propertyZip">Zip Code</label>
                                <zip-code-input [(ngModel)]="mortgage.subjectProperty.zipCode" [name]="'propertyZip'"
                                  class="custom-input-left-side custom-input-right-side" (blur)="onZipCodeRelatedInfoChanged($event)"
                                  (selectionChanged)="onZipCodeRelatedInfoChanged($event)"
                                  [required]="quickApplyFieldsConfig['mortgage.subjectProperty.zipCode'] == 'required' ||
                                  quickApplyFieldsConfig['mortgage.subjectProperty.zipCode'] == 'requested'"
                                  [ngClass]="{
                                    'invalid-requested' : !mortgage.subjectProperty.zipCode
                                      && quickApplyFieldsConfig['mortgage.subjectProperty.zipCode'] == 'requested',
                                    'invalid-required' : !mortgage.subjectProperty.zipCode
                                      && quickApplyFieldsConfig['mortgage.subjectProperty.zipCode'] == 'required'
                                  }" >
                                </zip-code-input>
                              </div>
                              <div class="form-group col-xs-12 col-sm-3 custom-div-right-side">
                                <label for="propertyCounty">County</label>
                                <input id="propertyCounty" name="propertyCounty" type="text" class="form-control custom-input-right-side"
                                  [(ngModel)]="mortgage.subjectProperty.county" [required]="true" #propertyCounty="ngModel"
                                  [required]="quickApplyFieldsConfig['mortgage.subjectProperty.county'] == 'required' ||
                                  quickApplyFieldsConfig['mortgage.subjectProperty.county'] == 'requested'"
                                  [ngClass]="{
                                    'invalid-requested' : propertyCounty && propertyCounty.invalid && propertyCounty.errors && propertyCounty.errors.required
                                      && quickApplyFieldsConfig['mortgage.subjectProperty.county'] == 'requested',
                                    'invalid-required' : propertyCounty && propertyCounty.invalid && propertyCounty.errors && propertyCounty.errors.required
                                      && quickApplyFieldsConfig['mortgage.subjectProperty.county'] == 'required'
                                  }"  />
                              </div>
                        </div>
                      </ng-container>
                      <div class="row mb-2">
                        <div class="form-group col-12 ">
                          <label class="form-label" for="occupancy">Occupancy</label>
                          <div>
                            <div class="btn-group w-100" role="group" aria-label="Occupancy"
                            [ngClass]="{
                              'invalid-requested-border' : !mortgage.subjectProperty.propertyWillBe
                                && quickApplyFieldsConfig['mortgage.subjectProperty.propertyWillBe'] == 'requested',
                              'invalid-required-border' : !mortgage.subjectProperty.propertyWillBe
                                && quickApplyFieldsConfig['mortgage.subjectProperty.propertyWillBe'] == 'required'
                            }">
                              <ng-container *ngFor="let propertyType of propertyOccupancyTypes">
                                <input type="radio" class="btn-check radio-button-primary" name="{{propertyType.value}}"
                                  id="{{propertyType.value}}" value="{{propertyType.value}}" #occupancy="ngModel"
                                  [(ngModel)]="mortgage.subjectProperty.propertyWillBe" autocomplete="off"
                                  [required]="quickApplyFieldsConfig['mortgage.subjectProperty.propertyWillBe'] == 'required' ||
                                    quickApplyFieldsConfig['mortgage.subjectProperty.propertyWillBe'] == 'requested'"
                                   />
                                <label class="btn btn btn-md btn-soft-primary"
                                  for="{{propertyType.value}}">{{propertyType.name}}</label>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-12 col-sm-6">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="mixedUseProperty"
                              name="mixedUseProperty" [(ngModel)]="mortgage.subjectProperty.isMixedUseProperty">
                            <label class="form-check-label" for="mixedUseProperty">Mixed Use Property</label>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="isFhaSecondaryResidence"
                              name="isFhaSecondaryResidence"
                              [(ngModel)]="mortgage.subjectProperty.isFhaSecondaryResidence">
                            <label class="form-check-label" for="isFhaSecondaryResidence">FHA Secondary
                              Residence</label>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="mixedUseProperty"
                              name="mixedUseProperty"> <!--No model-->
                            <label class="form-check-label" for="nonOccupantCoborrower">Non Occupant Co-Borrower</label>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <h6 class="mb-1">Community Property State</h6>
                        <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="propertyIsInCommunityPropertyState"
                              name="propertyIsInCommunityPropertyState"
                              [(ngModel)]="mortgage.subjectProperty.propertyIsInCommunityPropertyState">
                            <label class="form-check-label" for="propertyIsInCommunityPropertyState">This property is in
                              community property state</label>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <h6 class="mb-1">Energy Improvement</h6>
                        <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input"
                              id="mortgageWillFinanceEnergyRelatedImprovements"
                              name="mortgageWillFinanceEnergyRelatedImprovements"
                              [(ngModel)]="mortgage.subjectProperty.mortgageWillFinanceEnergyRelatedImprovements">
                            <label class="form-check-label" for="mortgageWillFinanceEnergyRelatedImprovements">Mortgage
                              loan will finance energy-related improvements</label>
                          </div>
                        </div>
                        <!--<div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="priorityOverTheFirstMortgageLien"
                              name="priorityOverTheFirstMortgageLien"> No model
                            <label class="form-check-label" for="priorityOverTheFirstMortgageLien">Property lien could
                              take priority over the first mortgage lien </label>
                          </div>
                        </div>-->
                      </div>
                      <div class="row mb-2">
                        <h6 class="mb-1">Transaction Detail</h6>
                        <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input"
                              id="isConversionOfContractForDeedOrLandContract"
                              name="isConversionOfContractForDeedOrLandContract"
                              [(ngModel)]="mortgage.subjectProperty.isConversionOfContractForDeedOrLandContract">
                            <label class="form-check-label"
                              for="isConversionOfContractForDeedOrLandContract">Conversation of Contract for Deed or
                              Land Contract</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="isRenovation" name="isRenovation"
                              [(ngModel)]="mortgage.subjectProperty.isRenovation">
                            <label class="form-check-label" for="isRenovation">Renovation</label>
                          </div>
                        </div>
                        <!--<div class="col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="isConstructionOnlyLoan"
                              [checked]="isConstructionOnlyLoan"
                              [disabled]="isConstructionOnlyLoan"
                              name="isConstructionOnlyLoan">
                            No model
                            <label class="form-check-label" for="isConstructionLoan">Construction Loan</label>
                          </div>
                        </div>-->
                      </div>
                      <div class="row mb-2">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="lotAcquiredDate">Lot Acquired Date</label>
                          <date-input name="lotAcquiredDate" id="lotAcquiredDate" class="custom-input-left-side"
                            [(ngModel)]="mortgage.subjectProperty.lotAcquiredDate" #lotAcquiredDate="ngModel"
                            [required]="quickApplyFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] == 'requested'"
                            [ngClass]="{
                              'invalid-requested' : lotAcquiredDate && lotAcquiredDate.invalid && lotAcquiredDate.errors && lotAcquiredDate.errors.required
                                && quickApplyFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] == 'requested',
                              'invalid-required' : lotAcquiredDate && lotAcquiredDate.invalid && lotAcquiredDate.errors && lotAcquiredDate.errors.required
                                && quickApplyFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] == 'required'
                            }">
                          </date-input>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side">
                          <label class="form-label" for="originalCostOfLot">Original Cost of Lot</label>
                          <currency-input [name]="'originalCostOfLot'" [allowDecimals]="false"
                            [(ngModel)]="mortgage.subjectProperty.lotOriginalCost" class="custom-input-right-side"
                            [ngClass]="{'invalid-requested': !mortgage.subjectProperty.lotOriginalCost
                            && quickApplyFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] == 'requested',
                            'invalid-required': !mortgage.subjectProperty.lotOriginalCost
                            && quickApplyFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] == 'required'}"
                            [required]="quickApplyFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] == 'requested'">
                          </currency-input>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 pl">
                      <div class="row mb-2">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="noOfUnits">No. of Units</label>
                          <number-input [allowNegative]="false" [(ngModel)]="mortgage.subjectProperty.noOfUnits"
                            name="noOfUnits" class="custom-input-left-side"
                            [ngClass]="{'invalid-requested': !mortgage.subjectProperty.noOfUnits
                            && quickApplyFieldsConfig['mortgage.subjectProperty.noOfUnits'] == 'requested',
                              'invalid-required': !mortgage.subjectProperty.noOfUnits
                            && quickApplyFieldsConfig['mortgage.subjectProperty.noOfUnits'] == 'required'}"
                            [required]="quickApplyFieldsConfig['mortgage.subjectProperty.noOfUnits'] == 'required' ||
                              quickApplyFieldsConfig['mortgage.subjectProperty.noOfUnits'] == 'requested'">
                          </number-input>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side">
                          <label class="form-label" for="constructionMethod">Construction Method</label>
                          <select class="form-select custom-input-right-side" id="constructionMethod" name="constructionMethod"
                            [(ngModel)]="mortgage.subjectProperty.constructionMethod"  #constructionMethod="ngModel"
                            [required]="quickApplyFieldsConfig['mortgage.subjectProperty.constructionMethod'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.subjectProperty.constructionMethod'] == 'requested'"
                            [ngClass]="{
                              'invalid-requested' : constructionMethod && constructionMethod.invalid && constructionMethod.errors && constructionMethod.errors.required
                                && quickApplyFieldsConfig['mortgage.subjectProperty.constructionMethod'] == 'requested',
                              'invalid-required' : constructionMethod && constructionMethod.invalid && constructionMethod.errors && constructionMethod.errors.required
                                && quickApplyFieldsConfig['mortgage.subjectProperty.constructionMethod'] == 'required'
                            }">
                            <option [ngValue]="null">--Select One--</option>
                            <option *ngFor="let constructionMethod of propertyConstructionMethods"
                              [ngValue]="constructionMethod.value">
                              {{constructionMethod.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="locatedInProject">Located in Project?</label>
                          <select class="form-select custom-input-left-side" id="locatedInProject" name="locatedInProject"
                              [(ngModel)]="locatedInProject" (ngModelChange)="onLocatedInProjectChanged()">
                            <option [ngValue]="null">--Select One--</option>
                            <option [ngValue]="false">No</option>
                            <option [ngValue]="true">Yes</option>
                          </select>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side" *ngIf="locatedInProject">
                          <label class="form-label" for="projectDesignType">Project Design Type</label>
                          <select class="form-select custom-input-right-side" id="projectDesignType" name="projectDesignType"
                            [(ngModel)]="mortgage.subjectProperty.projectDesignType"  #projectDesignType="ngModel"
                            [required]="quickApplyFieldsConfig['mortgage.subjectProperty.projectDesignType'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.subjectProperty.projectDesignType'] == 'requested'"
                            [ngClass]="{
                              'invalid-requested' : projectDesignType && projectDesignType.invalid && projectDesignType.errors && projectDesignType.errors.required
                                && quickApplyFieldsConfig['mortgage.subjectProperty.projectDesignType'] == 'requested',
                              'invalid-required' : projectDesignType && projectDesignType.invalid && projectDesignType.errors && projectDesignType.errors.required
                                && quickApplyFieldsConfig['mortgage.subjectProperty.projectDesignType'] == 'required'
                            }">
                            <option [ngValue]="null">--Select One--</option>
                            <option *ngFor="let projectDesignType of projectDesignTypes"
                              [ngValue]="projectDesignType.value">
                              {{projectDesignType.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="yearBuilt">Year Built</label>
                          <number-input [allowNegative]="false" [(ngModel)]="mortgage.subjectProperty.yearBuilt"
                            name="yearBuilt" class="custom-input-left-side">
                          </number-input>
                        </div>
                        <!-- <div class="col-12 col-sm-6 custom-div-right-side">
                          <label class="form-label" for="acreage">Acreage (acres)</label>
                          <number-input [allowNegative]="false" name="acreage"
                            class="custom-input-right-side">
                          </number-input>
                        </div> -->
                      </div>
                      <div class="row mb-2">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="originalCost">Original Cost</label>
                          <currency-input [name]="'originalCost'" [allowDecimals]="false"
                            [(ngModel)]="mortgage.subjectProperty.originalCost" class="custom-input-left-side">
                          </currency-input>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side">
                          <label class="form-label" for="originalCostYear">Year Lot Acquired</label>
                          <number-input [allowNegative]="false" [(ngModel)]="mortgage.subjectProperty.originalCostYear"
                            name="originalCostYear" class="custom-input-right-side">
                          </number-input>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-12 col-sm-6 custom-div-left-side">
                          <label class="form-label" for="improvementStatus">Improvements</label> <!--No model-->
                          <select class="form-select custom-input-left-side" id="improvementStatus" name="improvementStatus"
                            [(ngModel)]="mortgage.subjectProperty.improvementStatus">
                            <option [ngValue]="null">--Select One--</option>
                            <option *ngFor="let improvementStatusType of improvementStatusTypes"
                              [ngValue]="improvementStatusType.value">{{improvementStatusType.name}}</option>
                          </select>
                        </div>
                        <div class="col-12 col-sm-6 custom-div-right-side">
                          <label class="form-label" for="improvementCost">Improvement Costs</label>
                          <currency-input [name]="'improvementCost'" [allowDecimals]="false"
                            [(ngModel)]="mortgage.subjectProperty.improvementCost" class="custom-input-right-side"
                            [ngClass]="{'invalid-requested': !mortgage.subjectProperty.improvementCost
                            && quickApplyFieldsConfig['mortgage.subjectProperty.improvementCost'] == 'requested',
                            'invalid-required': !mortgage.subjectProperty.improvementCost
                            && quickApplyFieldsConfig['mortgage.subjectProperty.improvementCost'] == 'required'}"
                            [required]="quickApplyFieldsConfig['mortgage.subjectProperty.improvementCost'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.subjectProperty.improvementCost'] == 'requested'">
                          </currency-input>
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- </div> -->
              <!-- </div> -->
              <div #titleInfo id="title-info" style="min-height: 200px; margin-bottom: 600px;" class="pt-3">
                <!-- <div class="card"> -->
                  <div class="card-header header-bg mb-4">
                    <h4 class="card-title" style="display: inline-block;">
                      <i class="fas fa-file-alt me-2"></i>
                      Title Info
                    </h4>
                  </div>
                  <!-- <div class="card-body"> -->
                    <div class="row mb-2">
                      <div class="col-12 col-sm-6 pr">
                        <label class="form-label" for="mannerInWhichTitleWillBeHeld">Manner In Which Title Will Be
                          Held</label>
                        <select class="form-select" id="mannerInWhichTitleWillBeHeld"
                          name="mannerInWhichTitleWillBeHeld"
                          [(ngModel)]="mortgage.subjectProperty.mannerTitleWillBeHeld"
                          #mannerTitleWillBeHeld="ngModel"
                          [required]="quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] == 'required' ||
                          quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] == 'requested'"
                          [ngClass]="{
                            'invalid-requested' : mannerTitleWillBeHeld && mannerTitleWillBeHeld.invalid && mannerTitleWillBeHeld.errors && mannerTitleWillBeHeld.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] == 'requested',
                            'invalid-required' : mannerTitleWillBeHeld && mannerTitleWillBeHeld.invalid && mannerTitleWillBeHeld.errors && mannerTitleWillBeHeld.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] == 'required'
                          }">
                          <option [ngValue]="null">--Select a Manner In Which Title Will Be Held--</option>
                          <option *ngFor="let titleType of titleTypes" [ngValue]="titleType.value">
                            {{titleType.name}}</option>
                        </select>
                      </div>
                      <div class="col-12 col-sm-6 pl">
                        <label class="form-label" for="nativeAmericanLandsType">Indian Country Land Tenure</label>
                        <select class="form-select" id="nativeAmericanLandsType" name="nativeAmericanLandsType"
                          [(ngModel)]="mortgage.subjectProperty.nativeAmericanLandsType" #nativeAmericanLandsType="ngModel"
                          [required]="quickApplyFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] == 'required' ||
                          quickApplyFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] == 'requested'"
                          [ngClass]="{
                            'invalid-requested' : nativeAmericanLandsType && nativeAmericanLandsType.invalid && nativeAmericanLandsType.errors && nativeAmericanLandsType.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] == 'requested',
                            'invalid-required' : nativeAmericanLandsType && nativeAmericanLandsType.invalid && nativeAmericanLandsType.errors && nativeAmericanLandsType.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] == 'required'
                          }">
                          <option [ngValue]="null">--Select a Indian Country Land Tenure--</option>
                          <option *ngFor="let nativeAmericanLandsType of nativeAmericanLandsTypes"
                            [ngValue]="nativeAmericanLandsType.value">
                            {{nativeAmericanLandsType.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-12 col-sm-6 pr">
                        <label class="form-label" for="titleWillBeHeldNames">Title To Property Will Be Held In What
                          Name(s)</label>
                        <text-input [(ngModel)]="mortgage.subjectProperty.titleWillBeHeldNames"
                          name="titleWillBeHeldNames" #titleWillBeHeldNames="ngModel"
                          [required]="quickApplyFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] == 'required' ||
                          quickApplyFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] == 'requested'"
                          [ngClass]="{
                            'invalid-requested' : titleWillBeHeldNames && titleWillBeHeldNames.invalid && titleWillBeHeldNames.errors && titleWillBeHeldNames.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] == 'requested',
                            'invalid-required' : titleWillBeHeldNames && titleWillBeHeldNames.invalid && titleWillBeHeldNames.errors && titleWillBeHeldNames.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] == 'required'
                          }"></text-input>
                      </div>
                      <div class="col-12 col-sm-6 pl">
                        <label class="form-label" for="estate">Estate Will Be Held In</label>
                        <select class="form-select" id="estate" name="estate"
                          [(ngModel)]="mortgage.subjectProperty.estate" #estate="ngModel"
                          [required]="quickApplyFieldsConfig['mortgage.subjectProperty.estate'] == 'required' ||
                          quickApplyFieldsConfig['mortgage.subjectProperty.estate'] == 'requested'"
                          [ngClass]="{
                            'invalid-requested' : estate && estate.invalid && estate.errors && estate.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.estate'] == 'requested',
                            'invalid-required' : estate && estate.invalid && estate.errors && estate.errors.required
                              && quickApplyFieldsConfig['mortgage.subjectProperty.estate'] == 'required'
                          }">
                          <option [ngValue]="null">--Select One--</option>
                          <option *ngFor="let estateType of estateTypes" [ngValue]="estateType.value">
                            {{estateType.name}}</option>
                        </select>
                      </div>
                    </div>
                  <!-- </div> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
        </form>
        </div>
      </div>
    </div>

<drawer [title]="'Order Credit'"
  [name]="'orderCreditDrawerLoanInfo'"
  [templateRef]="orderCreditContentRef"
  [options]="orderCreditDrawerOptions">
</drawer>
<ng-template #orderCreditContentRef>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 mb-3">
        <div class="card-body pt-1">
          <span class="col-md-11 text-soft-pull credit-reporting-inner-html"
            [innerHtml]="creditReportingHtml"></span>
        </div>
        <div class="row" *ngFor="let borr of mortgage.borrowers">
          <div class="col-md-12">
            <div class="row soft-pull">
              <span class="col-md-7 ms-3 color-soft-pull">
                <strong>{{borr.firstName}}, {{borr.lastName}}</strong>
                 - Do we have your consent for the prequalification check?
              </span>
              <div class="col-md-4">
                <div class="radio-group">
                  <div class="radio radio-info form-check-inline">
                    <input type="radio" id="optionYes_{{borr.borrowerId}}" [value]="true" name="prequalificationCheck_{{borr.borrowerId}}"
                      [(ngModel)]="borr.authorizedCreditCheck" (ngModelChange)="onYesNoOptionChanged(borr)"
                      [disabled]="isPrequalificationCheckReadonly[borr.borrowerId]" [ngStyle]="{'cursor': isPrequalificationCheckReadonly[borr.borrowerId] ? 'default' : 'pointer'}">
                    <label for="optionYes_{{borr.borrowerId}}" class="o-1"> Yes </label>
                  </div>
                  <div class="radio radio-info form-check-inline ms-3">
                    <input type="radio" id="optionNo_{{borr.borrowerId}}" [value]="false" name="prequalificationCheck_{{borr.borrowerId}}"
                      [(ngModel)]="borr.authorizedCreditCheck" (ngModelChange)="onYesNoOptionChanged(borr)"
                      [disabled]="isPrequalificationCheckReadonly[borr.borrowerId]" [ngStyle]="{'cursor': isPrequalificationCheckReadonly[borr.borrowerId] ? 'default' : 'pointer'}">
                    <label for="optionNo_{{borr.borrowerId}}" class="o-1"> No </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-3">
          <loan-credit [mortgage]="mortgage" [embedded]="true"></loan-credit>
        </div>
      </div>
    </div>
  </div>
</ng-template>
