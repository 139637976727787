import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { UrlaBorrower } from '../../models/urla-mortgage.model';
import { formatDate } from '@angular/common';
import { formViewProvider } from 'src/app/core/services/form-view.provider';

@Component({
  selector: 'borrower-credit-authorization',
  templateUrl: 'borrower-credit-authorization.component.html',
  styleUrls: ['borrower-credit-authorization.component.scss'],
  providers: [MakeProvider(BorrowerCreditAuthorizationComponent)],
})

export class BorrowerCreditAuthorizationComponent implements OnInit {

  @Input()
  borrower: UrlaBorrower

  @Input()
  readonly: boolean = false;

  @Input()
  name: string;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Output()
  creditAuthorizationCheckChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() { }

  onAuthorizationCheckChanged = () => {
    if (this.borrower.authorizedCreditCheck) {
      if (!this.borrower.dateAuthorizedCreditCheck) {
        const today = new Date();
        this.borrower.dateAuthorizedCreditCheck = formatDate(today, 'MM/dd/yyyy', 'en-US')
      }
    } else {
      this.borrower.dateAuthorizedCreditCheck = null;
      this.borrower.authorizationMethod = null;
    }
    this.creditAuthorizationCheckChanged.emit(this.borrower.authorizedCreditCheck);
  }
}
