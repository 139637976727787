import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { MortgageModelFieldsConfig } from 'src/app/modules/urla/models/urla-fields-config.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { v4 as uuidv4 } from 'uuid';
import {
  Address,
  RealEstateOwned,
  ResidencyAddress,
  ResidencyBasis,
  ResidencyType,
} from '../../../../../../../models';
import { EnumerationItem } from '../../../../../../../models/simple-enum-item.model';
import { ZipCodeLookupResult } from '../../../../../../../models/zipcode-lookup-result.model';
import { AddressLookupService } from '../../../../../../../services/address-lookup.service';
import { Constants } from '../../../../../../../services/constants';
import { EnumerationService } from '../../../../../../../services/enumeration-service';
import { monthsToYearsAndMonths } from '../../../quick-apply-utils';
import { ApplicationContextService } from 'src/app/services/application-context.service';

@Component({
  selector: 'qa-address-history',
  templateUrl: './qa-address-history.component.html',
  styleUrls: ['./qa-address-history.component.scss'],
  viewProviders: [formViewProvider]
})
export class QaAddressHistoryComponent implements OnInit, OnDestroy {

  @Input()
  address?: ResidencyAddress;

  @Input()
  quickApplyFieldsConfig: MortgageModelFieldsConfig;

  @Input()
  allowDelete: boolean = false;

  @Output()
  addressChange = new EventEmitter<ResidencyAddress>();

  @Output()
  durationChange = new EventEmitter<{
    durationYears: number;
    durationMonths: number;
  }>();

  @Output()
  attemptedToDeleteAddress = new EventEmitter<ResidencyAddress>();

  @Output()
  borrowerReosChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  streetAddressLabel: string = 'Address';

  /**
   * Whether to show the duration fields (years and months).
   */
  @Input()
  showDuration: boolean = true;

  /**
   * Whether to show the housing details (housing type and its subfields).
   */
  @Input()
  showHousing: boolean = true;

  @Input()
  mortgage: UrlaMortgage;

  @HostBinding('id')
  protected id: string = `qa-address-history-${uuidv4()}`;

  @ViewChild('form', { static: true }) formElement: NgForm;

  protected stateOptions: EnumerationItem[] = [];
  protected housingOptions: EnumerationItem[] = [];
  protected yesNoOptions: EnumerationItem[] = [];

  protected oldPresentAddress: Address;

  private _isTpo: boolean = false;

  private _initEnumerationOptionsSubscription: Subscription | null = null;
  private _formValueSubscription?: Subscription;
  private readonly applicationContextSubscription: Subscription;

  constructor(
    private readonly _enumerationService: EnumerationService,
    private readonly _addressLookupService: AddressLookupService,
    private readonly _utilityService: UtilityService,
    private readonly _applicationContextService: ApplicationContextService,
  ) {
    this.applicationContextSubscription = this._applicationContextService.context.subscribe(ctx => {
      this._isTpo = ctx.isTpo;
    });
  }

  ngOnInit(): void {
    this.initEnumerationOptions();

    this.oldPresentAddress = {...this.address.address};
    if(this.address.address.address1){
      this.onBorrowerPresentAddressChanged(this.address);
    }

    setTimeout(() => {
      this.subscribeToFormValueChanges();
    });
  }

  ngOnDestroy(): void {
    this._initEnumerationOptionsSubscription?.unsubscribe();
    this._formValueSubscription?.unsubscribe();
    this.applicationContextSubscription?.unsubscribe();
  }

  private subscribeToFormValueChanges() {
    this._formValueSubscription?.unsubscribe();
    this._formValueSubscription = this.formElement.valueChanges.pipe(
      debounceTime(200),
    ).subscribe(() => { this.addressChange.emit(this.address); });
  }

  onAddressChange(address: Address): void {
    this.address.address = address;

    this.onStreetAddresschanged();
  }

  onDurationYearsChange(): void {
    this.calculateDuration();
  }

  onDurationMonthsChange(): void {
    this.calculateDuration();
  }

  onResidencyBasisChanged(): void {
    this.onBorrowerPresentAddressChanged(this.address);
  }

  onEmptyStreetControl(): void {
    if (!this.mortgage) {
      return;
    }

    const existingReoIdx = this.mortgage.realEstateOwned
      .findIndex(reo =>
        reo.address1?.toLocaleLowerCase() === this.oldPresentAddress?.address1?.toLocaleLowerCase() &&
        reo.city?.toLocaleLowerCase() === this.oldPresentAddress?.city?.toLocaleLowerCase() &&
        reo.state?.toLocaleLowerCase() === this.oldPresentAddress?.state?.toLocaleLowerCase() &&
        reo.zipCode?.toLocaleLowerCase() === this.oldPresentAddress?.zipCode?.toLocaleLowerCase());

    const existingReo = existingReoIdx > -1 ? this.mortgage.realEstateOwned[existingReoIdx] : null;
    const newAddress = this.address.address.address1;
    const borrowerId = this.address.borrowerId;

    if(!newAddress){
      this.address.address.city = null;
      this.address.address.state = null;
      this.address.address.zipCode = null;
    }

    if (!newAddress && existingReo && existingReo.owningBorrowerIds.includes(borrowerId) &&
      this.address.residencyBasis == ResidencyBasis.Own &&
      this.address.residencyType == ResidencyType.PresentAddress
    ) {
      const borrIndex = existingReo.owningBorrowerIds.findIndex(id => id == borrowerId);
      if (borrIndex > -1) {
        existingReo.owningBorrowerIds.splice(borrIndex, 1);
      }

      if (!existingReo.owningBorrowerIds.length) {
        this.mortgage.realEstateOwned.splice(existingReoIdx, 1);
      }

      this.borrowerReosChanged.emit();
    }
  }

  onStreetAddresschanged(): void {
    if (!this.mortgage || this._isTpo) {
      return;
    }

    if (this.address.residencyBasis == ResidencyBasis.Own &&
      this.address.residencyType == ResidencyType.PresentAddress
    ) {
      const existingReo = this.mortgage.realEstateOwned.find(reo =>
        reo.address1?.toLocaleLowerCase() === this.address?.address?.address1?.toLocaleLowerCase() &&
        reo.city?.toLocaleLowerCase() === this.address?.address?.city?.toLocaleLowerCase() &&
        reo.state?.toLocaleLowerCase() === this.address?.address?.state?.toLocaleLowerCase() &&
        reo.zipCode?.toLocaleLowerCase().slice(0, 5) === this.address?.address?.zipCode?.toLocaleLowerCase().slice(0, 5));

      this.consolidateReoWithPresentAddress(existingReo, this.address);
      this.oldPresentAddress = {...this.address.address};
    }
  }

  private initEnumerationOptions(): void {
    this._initEnumerationOptionsSubscription?.unsubscribe();
    this.stateOptions = this._enumerationService.states;
    this.yesNoOptions = this._enumerationService.getYesNoEnumItems();
    this._initEnumerationOptionsSubscription = this._enumerationService
      .getMortgageEnumerations().pipe(
        finalize(() => this._initEnumerationOptionsSubscription = null),
      ).subscribe(enums => {
        this.housingOptions = enums[Constants.enumerations.residencyBasisTypes];
      });
  }

  private calculateDuration = () => {
    const years = Number(this.address.durationYears) || 0;
    const months = Number(this.address.durationMonths) || 0;

    let totalMonths = years * 12 + months;
    const [
      durationYears,
      durationMonths,
    ] = monthsToYearsAndMonths(totalMonths);
    const durationValue = {
      durationYears,
      durationMonths,
    };
    this.addressChange.emit(this.address);
    this.durationChange.emit(durationValue);
  }

  protected onZipCodeChange(result: ZipCodeLookupResult): void {
    const address = this.address.address;
    const value = this._addressLookupService.parseZipCodeLookup(result);

    address.city = value.city;
    address.state = value.state;
    address.zipCode = value.zipCode;
    address.county = value.county;
    address.country = 'us';

    this.onBorrowerPresentAddressChanged(this.address);
  }

  protected onAttemptedToDeleteAddress(): void {
    this.attemptedToDeleteAddress.emit(this.address);
  }

  protected isRequestedOrRequired(fieldName: string): boolean {
    return this.quickApplyFieldsConfig[fieldName] == 'requested'
      || this.quickApplyFieldsConfig[fieldName] == 'required'
  }

  protected hasRequiredValidationError(field: NgModel): boolean {
    return field && field.invalid && field.errors && field.errors.required;
  }

  protected onBorrowerPresentAddressChanged = (e: ResidencyAddress) => {
    if (!this.mortgage) {
      return;
    }

    if (this.address.residencyType != ResidencyType.PresentAddress || this._isTpo) {
      return;
    }

    const existingReo = this.mortgage.realEstateOwned.find(reo =>
      reo.address1?.toLocaleLowerCase() === e.address?.address1?.toLocaleLowerCase() &&
      reo.city?.toLocaleLowerCase() === e.address?.city?.toLocaleLowerCase() &&
      reo.state?.toLocaleLowerCase() === e.address?.state?.toLocaleLowerCase() &&
      reo.zipCode?.toLocaleLowerCase().slice(0, 5) === e.address?.zipCode?.toLocaleLowerCase().slice(0, 5));

    this.consolidateReoWithPresentAddress(existingReo, e);
  }

  private consolidateReoWithPresentAddress = (existingReo: RealEstateOwned, presentAddress: ResidencyAddress) => {
    if (!this.mortgage) {
      return;
    }

    const borrowerId = this.address.borrowerId;

    if (existingReo) {
      if (presentAddress.residencyBasis !== ResidencyBasis.Own) {
        const index = this.mortgage.realEstateOwned.findIndex(reo => reo.reoId == existingReo.reoId);
        if (index >= 0) {
          this.mortgage.realEstateOwned.splice(index, 1);
          this.borrowerReosChanged.emit();
        }
        return;
      } else {
        existingReo.address1 = presentAddress.address.address1;
        existingReo.city = presentAddress.address.city;
        existingReo.state = presentAddress.address.state;
        existingReo.zipCode = presentAddress.address.zipCode;

        if (!existingReo.owningBorrowerIds?.includes(borrowerId)) {
          existingReo.owningBorrowerIds.push(borrowerId);
        }
        this.borrowerReosChanged.emit();
      }
    }

    if (!existingReo && presentAddress.residencyBasis === ResidencyBasis.Own) {
      let newReo = new RealEstateOwned();
      const presentAddressIsSubjectProperty = this.mortgage.subjectProperty.purposeOfLoan === 'Refinance' && this.mortgage.subjectProperty?.address1?.toLowerCase() ===
        presentAddress.address?.address1?.toLowerCase() && this.mortgage.subjectProperty?.city?.toLowerCase() === presentAddress.address?.city?.toLowerCase() && this.mortgage.subjectProperty?.state?.toLowerCase() === presentAddress.address?.state?.toLowerCase() && this.mortgage.subjectProperty?.zipCode === presentAddress.address?.zipCode?.toLowerCase();
      newReo.isSubjectProperty = presentAddressIsSubjectProperty;
      newReo.reoId = this._utilityService.getUniqueId();
      newReo.owningBorrowerIds = [borrowerId];
      newReo.address1 = presentAddress.address.address1;
      newReo.city = presentAddress.address.city;
      newReo.state = presentAddress.address.state;
      newReo.zipCode = presentAddress.address.zipCode;
      this.mortgage.realEstateOwned.push(newReo);
      this.borrowerReosChanged.emit();
    }
  }
}
