import { Component, Injector, Input, OnInit } from '@angular/core';
import {
  Company,
  DocumentType,
  LoanStatus,
  Profile,
  SystemLevel,
  ThirdPartyCredential,
  ThirdPartyCredentialArray,
  ThirdPartyCredentialType,
} from '../../../../models';
import { ApplicationContextBoundComponent } from '../../../../shared/components';
import { TransUnionConfiguration } from './cloud-platform/cloud-platform-config-editor/cloud-platform-config-editor.component';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';

@Component({
  selector: 'integrations',
  templateUrl: './integrations.component.html'
})
export class IntegrationsComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  scope: ScopeType; //company - system level, user - profile

  @Input()
  systemLevel: SystemLevel;

  @Input()
  profileLevel: Profile;

  @Input() externalCompanyId?: number;

  currentTab: string;

  loanStatus: Array<LoanStatus>;
  documentTypes: Array<DocumentType>;
  company: Company;

  du: ThirdPartyCredential[] = [];
  do: ThirdPartyCredential[] = [];
  lpa: ThirdPartyCredential[] = [];

  optimalBlueCreds: ThirdPartyCredential[] = [];
  pricingVendors: ThirdPartyCredential[] = [];
  feeProviders: ThirdPartyCredential[] = [];
  lenderPriceCreds: ThirdPartyCredential[] = [];
  pollyPriceCreds: ThirdPartyCredential[] = [];
  meridianLinkPricingCreds: ThirdPartyCredential[] = [];
  loanPassPricingCreds: ThirdPartyCredential[] = [];
  loanPassIframePricingCreds: ThirdPartyCredential[] = [];
  miQuoteCreds: ThirdPartyCredential[] = [];
  eSigningVendors: ThirdPartyCredential[] = [];

  enabledPricingEngines: PricingVendor[] = [];

  defaultFeeProviderVal: string;

  ernst: ThirdPartyCredential;
  formFree: ThirdPartyCredential;
  twn: ThirdPartyCredential;
  loanBeam: ThirdPartyCredential;
  ayrshare: ThirdPartyCredential;
  microsoftGraph: ThirdPartyCredential;
  rockConnection: ThirdPartyCredential;
  mersCredential: ThirdPartyCredential;
  licenseValidationCredential: ThirdPartyCredential;

  disclosureCredentials: ThirdPartyCredentialArray;
  assetVerificationCredentials: ThirdPartyCredentialArray;
  incomeEmploymentVerificationCredentials: ThirdPartyCredentialArray;
  incomeCalculationCredentials: ThirdPartyCredentialArray;
  titleCredentials: ThirdPartyCredentialArray;
  appraisalCredentials: ThirdPartyCredentialArray;
  ocrCredentials: ThirdPartyCredentialArray;
  creditCredentials: ThirdPartyCredentialArray;
  ldeCredentials: ThirdPartyCredentialArray;

  isDesktopUnderwriterEnabledForCompany: boolean;
  isDesktopOriginatorEnabledForCompany: boolean;
  isLpaEnabledForCompany: boolean;
  isLdeEnabledForCompany: boolean;
  isLosEnabledForCompany: boolean;
  isFeesEnabledForCompany: boolean;
  isLoanBeamEnabledForCompany: boolean;
  isCreditMonitoringEnabled: boolean;
  isMersEnabled: boolean;
  isTitleOrderingEnabled: boolean;
  isHomeOwnersInsuranceEnabled: boolean;
  isAdmin: boolean;

  dataForType: Profile | SystemLevel;

  transUnionConfiguration: TransUnionConfiguration;

  protected get canAccessPricing(): boolean {
    return this.scope === 'User'
      ? this.isAdmin
      : true;
  }

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.scope) {
      this.scope = 'Company';
    }
    this.enabledPricingEngines = this.applicationContext.userPermissions.enabledPricingEngines;
    this.documentTypes = this.applicationContext.globalConfig.documentType;
    this.company = this.applicationContext.globalConfig.company.find(c => c.companyId == this.applicationContext.userPermissions.companyId);
    this.loanStatus = this.applicationContext.globalConfig.loanStatus;
    this.defaultFeeProviderVal = this.applicationContext.userPermissions.defaultFeeProvider;
    this.isAdmin = this.applicationContext.userPermissions.admin;

    if (this.scope === 'User') {
      this.dataForType = this.profileLevel;
      this.isDesktopUnderwriterEnabledForCompany = this.applicationContext.userPermissions.desktopUnderwriterEnabled;
      this.isDesktopOriginatorEnabledForCompany = this.applicationContext.userPermissions.desktopOriginatorEnabled;
      this.isMersEnabled = this.applicationContext.userPermissions.mersEnabled;
      this.isCreditMonitoringEnabled = false;//setting to false because credit monitoring tab does not show for profile screen.
      this.isLpaEnabledForCompany = this.applicationContext.userPermissions.lpaEnabled;
      this.isLdeEnabledForCompany = this.applicationContext.userPermissions.ldeEnabled;
      this.isLosEnabledForCompany = this.applicationContext.userPermissions.losEnabled;
      this.isFeesEnabledForCompany = this.applicationContext.userPermissions.feesEnabled;

      this.eSigningVendors = this.profileLevel.thirdPartyCredentials.filter(el => el.vendorName === 'DocuSign');
      this.du = this.profileLevel.thirdPartyCredentials.filter(el => el.vendorName === 'DU');
      this.do = this.profileLevel.thirdPartyCredentials.filter(el => el.vendorName === 'FannieMae' && el.credentialType === 'AUSOriginator');
      this.lpa = this.profileLevel.thirdPartyCredentials.filter(el => el.vendorName === 'LPA');
      this.rockConnection = this.profileLevel.thirdPartyCredentials.find(el => el.vendorName === 'RockConnection');
      this.pricingVendors = this.profileLevel.thirdPartyCredentials.filter(el => el.credentialType === ThirdPartyCredentialType.PricingVendor);
      this.feeProviders = this.profileLevel.thirdPartyCredentials.filter(el => el.credentialType === ThirdPartyCredentialType.Fees)

      this.optimalBlueCreds = this.pricingVendors.filter(el => el.vendorName === PricingVendor.OptimalBlue);
      this.lenderPriceCreds = this.pricingVendors.filter(el => el.vendorName === PricingVendor.LenderPrice);
      this.pollyPriceCreds = this.pricingVendors.filter(el => el.vendorName === PricingVendor.Polly);
      this.meridianLinkPricingCreds = this.pricingVendors.filter(el => el.vendorName === PricingVendor.MeridianLink);
      this.loanPassPricingCreds = this.pricingVendors.filter(el => el.vendorName === PricingVendor.LoanPass);
      this.loanPassIframePricingCreds = this.pricingVendors.filter(el => el.vendorName === PricingVendor.LoanPassIframe);

      this.formFree = this.profileLevel.thirdPartyCredentials.find(el => el.vendorName === 'FormFree');
      this.twn = this.profileLevel.thirdPartyCredentials.find(el => el.vendorName === 'TWN');
      this.loanBeam = this.profileLevel.thirdPartyCredentials.find(el => el.vendorName === 'LoanBeam');
      this.ayrshare = this.profileLevel.thirdPartyCredentials.find(el => el.vendorName === 'Ayrshare');
      this.mersCredential = this.profileLevel.thirdPartyCredentials.find(el => el.vendorName === 'Mers');
      this.licenseValidationCredential = this.profileLevel.thirdPartyCredentials.find(el => el.credentialType === 'LicenseValidation');

      this.disclosureCredentials = this.profileLevel.thirdPartyCredentials.filter(el => el.credentialType === 'Disclosure');
      this.titleCredentials = this.profileLevel.thirdPartyCredentials.filter(el => el.credentialType === 'Title');
      this.appraisalCredentials = this.profileLevel.thirdPartyCredentials.filter(el => el.credentialType === 'AppraisalVendor');
      this.ocrCredentials = this.profileLevel.thirdPartyCredentials.filter(el => el.credentialType === 'OcrVendor');
      this.creditCredentials = this.profileLevel.thirdPartyCredentials.filter(el => el.credentialType === 'CreditVendor');
      this.incomeEmploymentVerificationCredentials = this.profileLevel.thirdPartyCredentials.filter(el => el.credentialType === 'VOE/VOI' || el.credentialType === 'VOI');
    } else {
      this.dataForType = this.systemLevel;
      this.isDesktopUnderwriterEnabledForCompany = this.systemLevel.company.desktopUnderwriterEnabled;
      this.isDesktopOriginatorEnabledForCompany = this.systemLevel.company.desktopOriginatorEnabled;
      this.isCreditMonitoringEnabled = this.systemLevel.company.creditMonitoringEnabled;
      this.isLpaEnabledForCompany = this.systemLevel.company.lpaEnabled;
      this.isLdeEnabledForCompany = this.systemLevel.company.ldeEnabled;
      this.isLosEnabledForCompany = this.systemLevel.company.losEnabled;
      this.isFeesEnabledForCompany = this.systemLevel.company.feesEnabled;
      this.isLoanBeamEnabledForCompany = this.systemLevel.company.loanBeamEnabled;
      this.isMersEnabled = this.systemLevel.company.mersEnabled;
      this.isTitleOrderingEnabled = this.systemLevel.company.titleOrderingEnabled;
      this.isHomeOwnersInsuranceEnabled = this.systemLevel.company.homeOwnersInsuranceEnabled;

      this.eSigningVendors = this.systemLevel.thirdPartyCredentials.filter(el => el.vendorName === 'DocuSign');
      this.du = this.systemLevel.thirdPartyCredentials.filter(el => el.vendorName === 'DU');
      this.do = this.systemLevel.thirdPartyCredentials.filter(el => el.vendorName === 'FannieMae' &&
        el.credentialType === 'AUSOriginator');
      this.lpa = this.systemLevel.thirdPartyCredentials.filter(el => el.vendorName === 'LPA');
      this.rockConnection = this.systemLevel.thirdPartyCredentials.find(el => el.vendorName === 'RockConnection');
      this.pricingVendors = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === ThirdPartyCredentialType.PricingVendor);
      this.feeProviders = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === ThirdPartyCredentialType.Fees)
      this.formFree = this.systemLevel.thirdPartyCredentials.find(el => el.vendorName === 'FormFree');
      this.twn = this.systemLevel.thirdPartyCredentials.find(el => el.vendorName === 'TWN');
      this.loanBeam = this.systemLevel.thirdPartyCredentials.find(el => el.vendorName === 'LoanBeam');
      this.ayrshare = this.systemLevel.thirdPartyCredentials.find(el => el.vendorName === 'Ayrshare');
      this.microsoftGraph = this.systemLevel.thirdPartyCredentials.find(el => el.vendorName === 'MicrosoftGraph');
      this.mersCredential = this.systemLevel.thirdPartyCredentials.find(el => el.vendorName === 'Mers');
      this.licenseValidationCredential = this.systemLevel.thirdPartyCredentials.find(el => el.credentialType === 'LicenseValidation');
      this.miQuoteCreds = this.systemLevel.thirdPartyCredentials.filter(el => el.vendorName === 'PmiRatePro');

      this.disclosureCredentials = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === 'Disclosure');
      this.titleCredentials = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === 'Title');
      this.appraisalCredentials = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === 'AppraisalVendor');
      this.ocrCredentials = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === 'OcrVendor');
      this.creditCredentials = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === 'CreditVendor');
      this.ldeCredentials = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === 'LdeVendor');
      this.assetVerificationCredentials = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === 'VOA');
      this.incomeEmploymentVerificationCredentials = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === 'VOE/VOI' || el.credentialType === 'VOI');
      this.incomeCalculationCredentials = this.systemLevel.thirdPartyCredentials.filter(el => el.credentialType === 'LoanBeam');

      this.transUnionConfiguration = {
        transunionImportEnabled: this.systemLevel.transunionImportEnabled,
        transunionImportLeadLoanPurposeId: this.systemLevel.transunionImportLeadLoanPurposeId,
        transunionImportLeadChannel: this.systemLevel.transunionImportLeadChannel,
        transunionImportLeadLoanTypeId: this.systemLevel.transunionImportLeadLoanTypeId,
        transunionImportLeadCampaignId: this.systemLevel.transunionImportLeadCampaignId
      }
    }

    this.currentTab = location.hash.replace('#', '') || "lead-posting";
  }

  setCurrentTab(tabName: string) {
    location.hash = `#${tabName}`;
    this.currentTab = tabName;
  }

  mersCredsUpdated(updatedCreds: ThirdPartyCredential) {
    this.mersCredential = updatedCreds;
  }

  onLicenseValidationCredentialChange(credential: ThirdPartyCredential) {
    this.licenseValidationCredential = credential;
    const credentials = this.scope === 'User'
      ? this.profileLevel.thirdPartyCredentials
      : this.systemLevel.thirdPartyCredentials;

    const addOrReplace = addOrReplaceCredentialByType(credentials);
    addOrReplace(credential.credentialType, credential);
  }

  onDefaultfeeproviderChanged = (newProvider: string) => {
    this.defaultFeeProviderVal = newProvider;
  }
}

export type ScopeType = "User" | "Branch" | "Company" | "TpoUser";

function addOrReplaceCredentialByType(credentials: Array<ThirdPartyCredential>) {
  return function (type: string, credential: ThirdPartyCredential) {
    const index = credentials.findIndex(x => x.credentialType === type);
    if (index >= 0) {
      credentials[index] = credential;
    } else {
      credentials.push(credential);
    }
  };
}
