<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mt-1 col-5"> Borrower Info
        </h4>
      </div>
      <div class="card-body">
        <ng-container *ngIf="mortgage">
        <form #qaBorrowerInfoForm="ngForm" id="qaBorrowerInfoForm" name="qaBorrowerInfoForm">
            <ul class="nav nav-tabs d-flex justify-content-between" role="tablist" id="borrower-tabs" style="border-bottom: 1px solid #d5d9d9;">
              <div class="d-flex">
              <li *ngFor="let borrowers of borrowerGroups" class="nav-item borrower-group group-button-list me-2">
                <ng-container *ngFor="let borrower of borrowers; let i = index; let last = last">
                  <a class="nav-link" data-bs-toggle="tab" href="#borr_{{borrower.borrowerId}}" role="tab"
                   (click)="selectBorrower(borrower)" aria-selected="true"
                   [ngClass]="{ active: isSelectedBorrower(borrower), 'pe-2': i % 2 == 0 && !last, 'ps-2': i % 2 == 1 }">
                   <span class="tab-1003-val-icon">
                    <i class="borrower-details-icon fas" [ngClass]="{
                      'fa': borrowerValidityStatusInfo.get(borrower.borrowerId) === 'success',
                      'fa-check-circle': borrowerValidityStatusInfo.get(borrower.borrowerId) === 'success',
                      'text-success': borrowerValidityStatusInfo.get(borrower.borrowerId) === 'success',

                      'fa-question-circle': borrowerValidityStatusInfo.get(borrower.borrowerId) === 'pending',
                      'text-warning': borrowerValidityStatusInfo.get(borrower.borrowerId) === 'pending',

                      'fa-times-circle': borrowerValidityStatusInfo.get(borrower.borrowerId) === 'error',
                      'text-danger': borrowerValidityStatusInfo.get(borrower.borrowerId) === 'error',

                      'fa-circle-notch': !validityStatus || borrowerValidityStatusInfo.get(borrower.borrowerId) === 'loading',
                      'fa-spin': !validityStatus || borrowerValidityStatusInfo.get(borrower.borrowerId) === 'loading'}">
                    </i>
                  </span>
                    <span>
                      {{getBorrowerDisplayName(borrower)}}
                    </span>
                  </a>
                  <span *ngIf="!last" class="vertical-divider">
                    <span></span>
                  </span>
                </ng-container>
              </li>
            </div>
              <div class="button-items" *ngIf="!isLoanReadOnly">
                <button type="button" class="btn btn-sm btn-outline-primary" (click)="onAddNewBorrowerClicked()">
                  Add Borrower
                </button>

                <button type="button" class="btn btn-sm btn-outline-primary me-2" (click)="onManageBorrowerClicked()">
                  <i class="fas fa-users"></i> Manage Borrowers
                </button>
              </div>
          </ul>

          <div class="tab-content my-3">
            <div *ngFor="let borrower of mortgage.borrowers | sort : 'isPrimary'; let index = index" [hidden]="!isSelectedBorrower(borrower)" class="tab-pane"
              [ngClass]="{ active: isSelectedBorrower(borrower)}" id="borr_{{borrower.borrowerId}}" role="tabpanel">
              <qa-borrower-details
                [isLoanReadOnly]="isLoanReadOnly"
                #qaBorrowerDetails
                [creditReportingHtml]="creditReportingHtml"
                (invalidate)='invalidateDirtyState()'
                [borrower]="borrower"
                [mortgage]="mortgage"
                [loanBorrower]="loanBorrowersMap.get(borrower.borrowerId)"
                [(subTab)]="borrowerDetailsSubTabs[index]"
                (saveBorrowerButtonClicked)="onSaveBorrowerButtonClicked()"
                (borrowerValidationStatusChanged)="onBorrowerValidationStatusChanged($event)">
              </qa-borrower-details>
            </div>
          </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</div>
