import { Component, Input, OnInit, Injector } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Liability } from 'src/app/models';
import { Select2OptionData } from 'ng-select2';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UtilityService } from '../../services/utility.service';
import { Constants } from 'src/app/services/constants';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { MortgageCalculationService } from '../../services/mortgage-calculation.service';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'other-liabilities',
  templateUrl: './other-liabilities.component.html',
  styleUrls: ['./other-liabilities.component.scss'],
  viewProviders: [formViewProvider]
})
export class OtherLiabilitiesComponent extends ApplicationContextBoundComponent implements OnInit {

  private _mortgage: UrlaMortgage;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    this.initializePossibleAccountOwners();
    this.initializeOtherLiabilities()
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  liabilityTypes: EnumerationItem[];

  @Input()
  monthlyPaymentSubTotal: number;

  @Input()
  unpaidBalanceSubTotal: number;

  @Input()
  partialPayoffAmount: number;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  otherLiabilityTypeOptions: EnumerationItem[];

  borrowers = [];

  otherLiabilities: Array<Liability> = [];

  editingIx: number = -1;

  editedOtherLiability: Liability;

  possibleReoAddresses = [];

  possibleAccountOwners: Array<{
    id: number;
    text: string;
  }> = [];

  liabilityTypeHELOC;

  liabilityTypeMortgageLoan;

  otherMonthlyPaymentSubTotal: number;

  otherUnpaidBalanceSubTotal: number;

  otherPartialPayoffAmount: number;

  thereIsAtLeastOneInvalidLineItem: boolean = false;

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
  };

  isNewLiability = false;

  constructor(private readonly injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _utilityService: UtilityService,
    private readonly _calculationService: MortgageCalculationService) {
    super(injector);
  }

  ngOnInit(): void {
    this.otherLiabilityTypeOptions = this.liabilityTypes.filter(liability =>
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Alimony) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildSupport) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.JobRelatedExpenses) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.SeparateMaintenanceExpense) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.CarMaintenance) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.CharitableContributions) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildCare) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Clothing) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.DryCleaning) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Entertainment) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.GroceryToiletry) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HealthInsurance) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Medical) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MiscellaneousLivingExpenses) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.NetRentalExpense) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.PayrollInsuranceDeduction) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.PayrollMiscellaneousDeductions) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.PayrollProfitSharingDeduction) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.PayrollRetirementDeduction) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.PayrollTaxDeduction) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.UnionDues) ||
      liability.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.OtherExpense)
    );

    this.calculateSubTotals();
  }

  onAccountOwnersChanged = (liability: Liability) => {
    this.thereIsAtLeastOneInvalidLineItem = !liability.owningBorrowerIds.length;
    this.setLiabilityOwnerNames(liability);
  }

  calculateSubTotals = () => {
    let otherMonthlyPaymentSubTotal = 0;
    let otherUnpaidBalanceSubTotal = 0;
    let otherPartialPayoffAmount = 0;

    this.otherLiabilities.forEach(liability => {
      if (!liability.isExcluded) {
        otherMonthlyPaymentSubTotal += Number(liability.monthlyPayment) || 0;
        otherUnpaidBalanceSubTotal += Number(liability.unpaidBalance) || 0;
        otherPartialPayoffAmount += Number(liability.partialPayoffAmount) || 0;
      }
    });

    this.otherMonthlyPaymentSubTotal = otherMonthlyPaymentSubTotal;
    this.otherUnpaidBalanceSubTotal = otherUnpaidBalanceSubTotal;
    this.otherPartialPayoffAmount = otherPartialPayoffAmount;

    this.mortgage.calculatedStats.financialOtherPartialPayoffAmount = otherPartialPayoffAmount;
    this.mortgage.calculatedStats.financialPartialPayoffTotalAmount = this._calculationService.calculateFinancialPartialPayoffTotalAmount(this.mortgage);
    this.mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this.mortgage);
    this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);
  }

  getBorrowerName = (borrowerId) => {
    let borrower = this.possibleAccountOwners.find(owner => owner.id == borrowerId);
    return borrower?.text || '';
  }

  deleteLiability = (deleteIx: number) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        if (deleteIx === self.editingIx) {
          self.editingIx = -1;
        }
        let liability = self.otherLiabilities[deleteIx];
        if (liability) {
          let indexInMortgageLiabilities = self.mortgage.liabilities.indexOf(liability);
          if (indexInMortgageLiabilities >= 0) {
            self.mortgage.liabilities.splice(indexInMortgageLiabilities, 1);
          }
        }
        self.otherLiabilities.splice(deleteIx, 1);
        const oneWithEmptyAccountOwnersIndex = self.otherLiabilities.findIndex(a => !a.owningBorrowerIds.length);
        self.thereIsAtLeastOneInvalidLineItem = oneWithEmptyAccountOwnersIndex >= 0;
        self.calculateSubTotals();
        this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
      }
    });
  }

  addOtherLiability = () => {
    let liability = new Liability();
    liability.liabilityId = this._utilityService.getUniqueId();
    liability.owningBorrowerIds = this.possibleAccountOwners && this.possibleAccountOwners.length == 1 ?
      [this.possibleAccountOwners[0].id] : [];
    this.setLiabilityOwnerNames(liability);
    this.otherLiabilities.push(liability);
    this.mortgage.liabilities.push(liability);
    if (this.editingIx != -1) {
      this.otherLiabilities[this.editingIx] = this.editedOtherLiability;
      const liabilityInMainList = this.mortgage.liabilities.find(l => l.liabilityId == this.editedOtherLiability.liabilityId);
      if (liabilityInMainList) {
        const indexInMainList = this.mortgage.liabilities.indexOf(liabilityInMainList);
        if (indexInMainList >= 0) {
          this.mortgage.liabilities[indexInMainList] = this.editedOtherLiability;
        }
      }
      this.calculateSubTotals();
      this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
    }
    this.editingIx = this.otherLiabilities.length - 1;
    this.isNewLiability = true;
    this.thereIsAtLeastOneInvalidLineItem = !liability.owningBorrowerIds.length;
    this.editedOtherLiability = _.cloneDeep(liability);
  };

  exclude = (otherLiability: Liability, isExcluded: boolean) => {
    otherLiability.isExcluded = isExcluded;
    this.calculateSubTotals();
    this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
  }

  onTypeOfLiabilityChanged = (liability) => {
    if (liability.typeOfLiability == this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.OtherLiability)) {
      liability.typeOfLiabilityOtherDescription = null;
    }
    else {
      liability.typeOfLiabilityOtherDescription = null;
    }

  }

  isFilteredLiability = (liabilityType: string) => {
    return liabilityType === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Alimony) ||
      liabilityType === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildSupport) ||
      liabilityType === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildCare) ||
      liabilityType === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.JobRelatedExpenses) ||
      liabilityType === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.SeparateMaintenanceExpense) ||
      liabilityType === this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.OtherExpense);
  }

  editOtherLiability = (liability, liabilityIx) => {
    if (this.editingIx != -1) {
      const indexInMainList = this.mortgage.liabilities.indexOf(liability);
      if (indexInMainList >= 0) {
        this.mortgage.liabilities[indexInMainList] = this.editedOtherLiability;
      }
      this.otherLiabilities[this.editingIx] = this.editedOtherLiability;
      this.calculateSubTotals();
      this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
    }
    this.editedOtherLiability = _.cloneDeep(liability);
    this.editingIx = liabilityIx;
  }

  onEditConfirmed = () => {
    this.editingIx = -1;
    this.isNewLiability = false;
    this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
  }

  cancelEdit = () => {
    const liability = this.otherLiabilities[this.editingIx];
    const indexInMainList = this.mortgage.liabilities.indexOf(liability);
    if (this.isNewLiability) {
      if (indexInMainList >= 0) {
        this.mortgage.liabilities.splice(indexInMainList, 1);
      }
      this.otherLiabilities.splice(this.editingIx, 1);
      this.thereIsAtLeastOneInvalidLineItem = false;
    } else {
      if (indexInMainList >= 0) {
        this.mortgage.liabilities[indexInMainList] = this.editedOtherLiability;
      }
      this.otherLiabilities[this.editingIx] = this.editedOtherLiability;
      this.thereIsAtLeastOneInvalidLineItem = !this.editedOtherLiability.owningBorrowerIds.length;
    }

    this.isNewLiability = false;
    this.calculateSubTotals();
    this.editingIx = -1;
  }

  initializeOtherLiabilities = () => {
    this.otherLiabilities = [];
    if (this._mortgage.liabilities) {
      this.mortgage.liabilities.forEach(liability => {
        if (this.isFilteredLiability(liability.typeOfLiability)) {
          if (liability.payoffType == this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
            liability.partialPayoffAmount = liability.unpaidBalance;
          }
          let borrIds = [];
          liability['owners'] = [];
          liability.owningBorrowerIds.forEach(borrowerId => {
            borrIds.push(borrowerId.toString());
            liability['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(borrowerId) })
          });
          liability.owningBorrowerIds = borrIds;
          this.otherLiabilities.push(liability);
        }
      });
    }
  }

  private initializePossibleAccountOwners = () => {
    this.possibleAccountOwners = [];
    if (this.mortgage.borrowers) {
      this.mortgage.borrowers.forEach(borrower => {
        const borrowerFullName = this._utilityService.getBorrowerFullName(borrower);
        let borrowerOption = new EnumerationItem(borrowerFullName, borrower.borrowerId);
        this.borrowers.push(borrowerOption);

        const possibleAccountOwner = {
          id: borrower.borrowerId,
          text: borrowerFullName
        }
        this.possibleAccountOwners.push(possibleAccountOwner);
      });
    }
  }

  private setLiabilityOwnerNames = (liability: Liability) => {
    liability['owners'] = [];
    liability.owningBorrowerIds.forEach(borrowerId => {
      liability['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(borrowerId) });
    });
  }
}
