import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { firstValueFrom, forkJoin, Observer, Subscription } from 'rxjs';
import { CreditVendorEnum, ThirdPartyCredentialType } from 'src/app/models';
import { AusDuAgenciesArray, AusDuAgency } from 'src/app/models/aus';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { LoanServicesService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';
import { UtilityService } from '../urla/services/utility.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditTechnicalAffiliate } from 'src/app/models/credit/credit-technical-affiliate.model';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

@Component({
	selector: 'credit-credentials-configuration',
	templateUrl: 'credit-credentials-configuration.component.html',
	styleUrls: ['./credit-credentials-configuration.component.scss']
})
export class CreditCredentialsConfigurationComponent implements OnInit, OnDestroy {

	@ViewChild('createCreditCredentialsForm')
	createCreditCredentialsForm: NgForm;

	@Output()
	credentialsSaved: EventEmitter<void> = new EventEmitter<void>();

	protected searchString: string = '';

	protected showAllAvailableVendorsInDropDown: boolean = false;

	protected selectedProviderNumber: string = '';

	protected loading: boolean = false;

	protected saving: boolean = false;

	protected errorLoadingProviders: boolean = false;

	protected preSelectedDuCreditProviders: AusDuAgency[] = [];

	protected allDuCreditProviders: AusDuAgency[] = [];

	protected filteredDuCreditProviders: AusDuAgency[] = [];

	protected userName: string = '';

	protected password: string = '';

	private _providerNumbersToShowAsButtonSelections = ["128", "78", "277", "287", "128", "308", "63", "229", "913", "912", "311", "277", "6", "299", "51", "301"];

	private _contextSubscription: Subscription;

	private _userId: string;

	private _companyId: number = 0;
	private _channels: string;

	private _technicalAffiliates: CreditTechnicalAffiliate[] = null;

	private _lodaCreditProvider: string = '';
	private _lodaCreditProviderUrl: string = '';
	private _lodaCreditProviderTechnicalAffiliateId: number = 0;

	constructor(private readonly _loanService: LoanServicesService,
		private readonly _notifyService: NotificationService,
		private readonly _applicationContextService: ApplicationContextService,
		private readonly _utilityService: UtilityService,
		private readonly _systemLevelService: SystemLevelService,
		private readonly _spinner: NgxSpinnerService
	) { }

	ngOnInit() {
		this._contextSubscription = this._applicationContextService.context.subscribe(context => {
			this._userId = context.userPermissions.userId;
			this._companyId = context.userPermissions.companyId;
			this._channels = context.userPermissions.enabledChannels;
			this.loadDuAgencies();
		});
	}

	ngOnDestroy(): void {
		this._contextSubscription?.unsubscribe();
	}

	protected onSearchStringChanged = () => {
		this.filteredDuCreditProviders = this.preSelectedDuCreditProviders.filter(x => x.name.toLowerCase().includes(this.searchString.toLowerCase()));
	}

	protected onIDontSeeMyVendorClicked = () => {
		this.selectedProviderNumber = null;
		this.showAllAvailableVendorsInDropDown = true;
	}

	protected onBackToVendorButtonsViewClicked = () => {
		this.showAllAvailableVendorsInDropDown = false;
	}

	protected onCreditVendorSelectionChanged = () => {
	}

	protected onSaveClicked = () => {
		if (this.createCreditCredentialsForm) {
			this.createCreditCredentialsForm.form.markAllAsTouched();
			if (!this.createCreditCredentialsForm.form.valid) {
				return;
			}
		}

		const keyValuePairsForAusCredential: any[] = [];
		const keyValuePairsForCreditCredential: any[] = [];

		const selectedDuCreditProvider = this.allDuCreditProviders.find(x => x.providerNumber === this.selectedProviderNumber);
		if (selectedDuCreditProvider) {
			const keyValuePairForCreditProvider = {
				key: 'CreditProvider:' + selectedDuCreditProvider.name,
				value: this.userName + "," + this.password,
				alias: selectedDuCreditProvider.name,
				userName: this.userName,
				password: this.password,
				companyId: this._companyId
			};

			const keyValuePairForDefaultCreditProvider = {
				key: 'DefaultCreditProvider',
				value: selectedDuCreditProvider.name,
				companyId: this._companyId
			};

			keyValuePairsForAusCredential.push(keyValuePairForCreditProvider);
			keyValuePairsForAusCredential.push(keyValuePairForDefaultCreditProvider);
		}

		const ausCredentialToCreate = {
			credentialType: ThirdPartyCredentialType.AusVendor,
			vendorName: 'DU',
			thirdPartyKeyValuePairs: keyValuePairsForAusCredential,
			alias: '',
			url: '',
			userName: null,
			password: null,
			userId: this._userId,
			companyId: this._companyId
		};

		let creditCredentialToCreate = null;
		if (this._lodaCreditProvider) {

			if (this._lodaCreditProviderTechnicalAffiliateId) {
				const keyValuePairForTechnicalAffiliate = {
					key: 'TechnicalAffiliate',
					value: this._lodaCreditProviderTechnicalAffiliateId,
					companyId: this._companyId
				};
				keyValuePairsForCreditCredential.push(keyValuePairForTechnicalAffiliate);
			}

			creditCredentialToCreate = {
				credentialType: ThirdPartyCredentialType.CreditVendor,
				vendorName: this._lodaCreditProvider,
				thirdPartyKeyValuePairs: keyValuePairsForCreditCredential,
				url: this._lodaCreditProviderUrl,
				userName: this.userName,
				password: this.password,
				userId: this._userId,
				companyId: this._companyId,
				channels: this._channels,
			}
		}

		const apiCalls: any[] = [];
		apiCalls.push(this._systemLevelService.saveCredential(ausCredentialToCreate));
		if (creditCredentialToCreate) {
			apiCalls.push(this._systemLevelService.saveCredential(creditCredentialToCreate));
		}

		if (apiCalls.length) {
			this._spinner.show();
			this.saving = true;
			forkJoin(apiCalls).subscribe(results => {
				this.showSuccessMessage();
			}, errors => {
				this._notifyService.showError(
					getErrorMessageOrDefault(errors, {
						defaultMessage: 'Error saving credit credentials.',
					}),
					'Error!'
				);
			}).add(() => {
				this.saving = false;
				this._spinner.hide();
			});
		}
	}

	protected onAgencySelected = async (agency: AusDuAgency) => {
		this.selectedProviderNumber = agency.providerNumber;
		this.onFannieProviderNumberSelected(agency.providerNumber);
	}

	protected onFannieProviderNumberSelected = async (providerNumber: string) => {
		this.selectedProviderNumber = providerNumber;
		this.showAllAvailableVendorsInDropDown = true;

		const mappings = this._utilityService.getCreditProviderMappings();

		const creditProviderMapping = mappings.find(m => m.fannieId === this.selectedProviderNumber);
		if (creditProviderMapping && creditProviderMapping.lodaVendorName) {
			this._lodaCreditProvider = creditProviderMapping.lodaVendorName;
			if (creditProviderMapping.lodaVendorName === CreditVendorEnum.MeridianLinkHardPull) {
				if (!this._technicalAffiliates) {
					this._technicalAffiliates = await this.getTechnicalAffiliates();
					if (this._technicalAffiliates && this._technicalAffiliates.length) {
						const technicalAffiliate = this._technicalAffiliates.find(a => a.creditTechnicalAffiliateId === creditProviderMapping.technicalAffiliateId);
						if (technicalAffiliate) {
							this._lodaCreditProviderUrl = technicalAffiliate.url;
							this._lodaCreditProviderTechnicalAffiliateId = technicalAffiliate.creditTechnicalAffiliateId;
						}
					}
				} else {
					const technicalAffiliate = this._technicalAffiliates.find(a => a.creditTechnicalAffiliateId === creditProviderMapping.technicalAffiliateId);
					if (technicalAffiliate) {
						this._lodaCreditProviderUrl = technicalAffiliate.url;
						this._lodaCreditProviderTechnicalAffiliateId = technicalAffiliate.creditTechnicalAffiliateId;
					}
				}
			} else {
				this._lodaCreditProviderUrl = null;
				if (creditProviderMapping.lodaVendorName === CreditVendorEnum.Xactus) {
					this._lodaCreditProviderUrl = "https://www.ultraamps.com/uaweb/mismo3";
				} else if (creditProviderMapping.lodaVendorName === CreditVendorEnum.ISoftPull) {
					this._lodaCreditProviderUrl = "https://app.isoftpull.com/api/v2/reports";
				}
			}
		}
	}

	private getTechnicalAffiliates = async (): Promise<CreditTechnicalAffiliate[]> => {
		this._spinner.show();
		try {
			const technicalAffiliates = await firstValueFrom(
				this._loanService.getTechnicalAffiliates(CreditVendorEnum.MeridianLinkHardPull),
			);
			return technicalAffiliates.filter(a => a.mlcid && a.code);
		} catch (error) {
			this._notifyService.showError(
				getErrorMessageOrDefault(error, {
					defaultMessage: 'Error getting credit technical affiliates.',
				}),
				'Error!'
			);
		} finally {
			this._spinner.hide();
		}
	};

	private loadDuAgencies = () => {
		const observer: Observer<AusDuAgenciesArray> = {
			next: (agencies: AusDuAgenciesArray) => {
				const duCreditProviders: AusDuAgency[] = [];
				this._providerNumbersToShowAsButtonSelections.forEach((providerNumber: string) => {
					const agency = agencies.find(x => x.providerNumber === providerNumber);
					if (agency) {
						duCreditProviders.push(agency);
					}
				});
				this.preSelectedDuCreditProviders = _.orderBy(duCreditProviders, ["name"], ["asc"]);
				this.allDuCreditProviders = _.orderBy(agencies, ["name"], ["asc"]);
				this.filteredDuCreditProviders = [...duCreditProviders];
			},
			error: (err: any) => {
				this.errorLoadingProviders = true;
				this._notifyService.showError(
					getErrorMessageOrDefault(err, {
						defaultMessage: 'Error fetching DU credit vendor options.',
					}),
					'Error!'
				);
			},
			complete: () => {
			}
		}
		this.loading = true;
		this._loanService.getDuAgencies(true).subscribe(observer)
			.add(() => {
				this.loading = false;
			});
	}

	private showSuccessMessage = () => {
		Swal.fire(
			'Success!',
			'Your credentials have been saved successfully.',
			'success'
		).then(() => {
			this.credentialsSaved.emit();
		});
	}
}