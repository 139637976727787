<ng-template [ngIf]="isLoading" [ngIfElse]="content">
  <loading-indicator [customClass]="'loading-indicator'"></loading-indicator>
</ng-template>

<ng-template #content>
  <div class="row mb-1">
    <a [routerLink]="['/admin/app-details/' + appId + '/coc-details']">Changes of Circumstance</a>
  </div>
  <div class="row le-cd">
    <p-table #dt1 [value]="disclosureValidation.items" [paginator]="true" [rows]="25" *ngIf="disclosureValidation?.items.length > 0"
      [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th> Field </th>
          <th> Loan Value </th>
          <th> Assigned Value </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-validationItem>
        <tr>
          <td> {{validationItem.pricingField}} </td>
          <td> {{validationItem.loanValue}} </td>
          <td [ngClass]="{'highlight-pricing': validationItem.hasChanged}"> {{validationItem.assignedValue}} </td>
        </tr>
      </ng-template>
    </p-table>
    <span *ngIf="!disclosureValidation || disclosureValidation?.items.length == 0"> There is no data. </span>
  </div>
</ng-template>