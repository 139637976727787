import { Component, OnInit, Input } from '@angular/core';
import { LosDisclosureInfo, LosGeneratedDocument } from '../../../request-models/los-disclosure-gen-job';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'encompass-disclosure-preview-pdf',
  templateUrl: './encompass-disclosure-preview-pdf.component.html',
  styleUrls: ['./encompass-disclosure-preview-pdf.component.scss']
})
export class EncompassDisclosurePreviewPdfComponent implements OnInit {

  @Input() documentData: any;

  documentUrl = null;

  constructor(private readonly _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    const decodedData = window.atob(this.documentData);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    const blob = new Blob([uInt8Array], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    this.documentUrl =  this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
