import {
  AfterViewInit,
  Component,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {NgForm} from '@angular/forms';
import * as _ from 'lodash';
import {Address} from 'src/app/models/address.models';
import {EnumerationItem} from 'src/app/models/simple-enum-item.model';
import {Constants} from 'src/app/services/constants';
import {EnumerationService} from 'src/app/services/enumeration-service';
import {UrlaValidationService} from 'src/app/services/urla-validation.service';
import {UrlaMortgage} from '../models/urla-mortgage.model';
import {UrlaSectionComponent} from '../urla-section/urla-section.component';
import {createIsRequiredFunction} from '../urla-utils';
import {MortgageService} from 'src/app/services/mortgage.service';
import {setNestedPathIfUndefined} from '../../../shared/utils/object/utils';

@Component({
  selector: 'borrowing-entity-info',
  templateUrl: 'borrowing-entity-info.component.html',
})
export class BorrowingEntityInfoComponent
  extends UrlaSectionComponent
  implements OnChanges, OnInit, AfterViewInit
{
  @Input()
  mortgage: UrlaMortgage;

  @ViewChild('borrowingEntityForm')
  urlaSectionForm: NgForm;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  menuItem: string = Constants.menu.urlaMenuItems.loanOriginatorInfo;

  signingPartyTitles: EnumerationItem[] = [
    {
      name: "Member",
      value: "Member"
    },
    {
      name: "Managing Member",
      value: "Managing Member"
    }
  ];

  states: EnumerationItem[] = [];
  protected borrowerTypeOptions: EnumerationItem[] = [];

  protected limitExceeded: boolean = false;
  protected minFirstMemberRequired: boolean = false;

  protected isRequired: (fieldName: string) => boolean;

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _urlaValidationService: UrlaValidationService,
    private readonly _mortgageService: MortgageService,
    injector: Injector,
  ) {
    super(injector);
    this.states = this._enumsService.states;
  }

  ngOnChanges(changes: SimpleChanges) {
    const mortgage = changes['mortgage']?.currentValue;
    if (mortgage) {
      const path = ['extension', 'borrowingEntity'];
      setNestedPathIfUndefined(mortgage, [...path, 'entityAddress'], new Address());
      setNestedPathIfUndefined(mortgage, [...path, 'entityMailingAddress'], new Address());
    }
  }

  ngOnInit(): void {
    this._enumsService.getMortgageEnumerations().subscribe((result) => {
      this.borrowerTypeOptions = result[Constants.mortgageEnumerations.mortgageEntityType];
    });
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);
    this.onEntityTypeChanged();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  shouldShowEntityForm() {
    return this.mortgage.borrowers.some(x => x.mortgagePartyType == "Entity");
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForBorrowingEntityInfo(this.mortgage);
    super.setMenuItemStatus(validityStatus);
  }

  onOwnershipPercentageChanged = () => {
    this.controlPercentageLimitExceeded();
  }

  onMemberNameChanged = () => {
    this.controlPercentageLimitExceeded();
  }

  onEntityTypeChanged = () => {
    this._mortgageService.publishEntityTypeChanged(this.mortgage.extension.borrowingEntity.entityType);
  }

  protected onEntityAddressChanged = (address: Address) => {
    this.mortgage.extension.borrowingEntity.entityAddress = address;
  }

  protected onEntityAddressCopyClicked = () => {
    this.mortgage.extension.borrowingEntity.entityMailingAddress = _.cloneDeep(this.mortgage.extension.borrowingEntity.entityAddress);
  }

  protected controlPercentageLimitExceeded = (): void => {
    if (!this.urlaSectionForm) {
      return;
    }

    const ownershipPercent = this.toNumber(this.mortgage.extension.borrowingEntity.member1.ownershipPercent);
    const ownershipPercent2 = this.toNumber(this.mortgage.extension.borrowingEntity.member2.ownershipPercent);
    const ownershipPercent3 = this.toNumber(this.mortgage.extension.borrowingEntity.member3.ownershipPercent);
    const ownershipPercent4 = this.toNumber(this.mortgage.extension.borrowingEntity.member4.ownershipPercent);

    const percentageTotal = (this.mortgage.extension.borrowingEntity.member1?.name?.length ? ownershipPercent : 0) +
      (this.mortgage.extension.borrowingEntity.member2?.name?.length ? ownershipPercent2 : 0) +
      (this.mortgage.extension.borrowingEntity.member3?.name?.length ? ownershipPercent3 : 0) +
      (this.mortgage.extension.borrowingEntity.member4?.name?.length ? ownershipPercent4 : 0);

    const percentageExceeded = percentageTotal > 100;
    const minExceed = (ownershipPercent && ownershipPercent < 25) || (ownershipPercent2 && ownershipPercent2 < 25) || (ownershipPercent3 && ownershipPercent3 < 25) || (ownershipPercent4 && ownershipPercent4 < 25)
    const maxExceed = (ownershipPercent > 100) || (ownershipPercent2 > 100) || (ownershipPercent3 > 100) || (ownershipPercent4 > 100)

    this.limitExceeded = percentageExceeded || minExceed || maxExceed;
    this.minFirstMemberRequired = !percentageTotal;
  }

  private toNumber = (val): number => {
    return val ? Number(val) : 0;
  }

}
