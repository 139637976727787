<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="scope !== 'User' && scope !== 'Branch'" [ngTemplateOutlet]="systemLevelConfig"></ng-container>
  <ng-container *ngIf="scope === 'User' || scope === 'Branch'" [ngTemplateOutlet]="otherConfig"></ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>

<ng-template #systemLevelConfig>
  <div class="row">
    <!-- <div class="col-md-2 text-end mt-3">
      <label class="custom-control-label"> Business Channels </label>
    </div>
    <div class="col-md-10 mt-3">
      <tag-input theme='bootstrap' [(ngModel)]="channels" [placeholder]="'+ Add Channel'" [onlyFromAutocomplete]="true">
        <tag-input-dropdown [autocompleteItems]="businessChannelsNames"
          [showDropdownIfEmpty]="true"></tag-input-dropdown>
      </tag-input>
    </div> -->
    <div class="col-md-2 text-end mt-3">
      <label class="custom-control-label"> Business Channels </label>
    </div>
    <div class="col-md-10 mt-3">
      <ng-multiselect-dropdown name="channels" id="channels" required [placeholder]="'-- Select --'"
        [settings]="multiSelectSettings" [data]="channelOptions" [(ngModel)]="selectedChannels">
      </ng-multiselect-dropdown>
    </div>
  </div>

  <div class="row mt-3" *ngIf="vendor?.credentialId">
    <div class="col-md-2"></div>
    <div class="col-md-4">
      <button type="button" class="btn btn-primary me-2" (click)="onRepriceFieldsClicked()">
        Field Editor
      </button>
    </div>
  </div>
</ng-template>

<ng-template #otherConfig>
  <form #editorForm="ngForm">
    <div class="row">
      <div class="col-md-4 col-xs-12 text-start">
        <button class="btn btn-outline-primary" (click)="addBusinessChannel()">
          Add Business Channels
        </button>
      </div>
    </div>
    <div class="row mt-2" *ngFor="let item of kvpOriginators; let i = index;  trackBy: trackByIndex">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Business Channels</label>
      </div>
      <div class="col-md-4">
        <select class="form-select" name="businessChannelName_{{i}}" [(ngModel)]="item.key" #bcNameField="ngModel"
          [ngClass]="{'is-invalid': bcNameField && bcNameField.touched && bcNameField.invalid}"
          (change)="clearOriginator(item)" required>
          <option value="">-- Select One --</option>
          <option *ngFor="let ele of businessChannels" value={{ele.businessChannelId}}>
            {{ele.name}}
          </option>
        </select>
      </div>
      <div class="col-md-1 text-end">
        <label class="custom-control-label">Originators</label>
      </div>
      <div class="col-md-4">
        <select class="form-select" name="originatorName_{{i}}" [(ngModel)]="item.value"
          #originatorNameField="ngModel"
          [ngClass]="{'is-invalid': originatorNameField && originatorNameField.touched && originatorNameField.invalid}"
          required>
          <option value="">-- Select One --</option>
          <option *ngFor="let ele of businessChannelOriginators[item.key]" value={{ele.originatorId}}>
            {{ele.name}}
          </option>
        </select>
      </div>
      <div class="col-md-1">
        <confirm-delete [list]="kvpOriginators" [index]="i"></confirm-delete>
      </div>
    </div>
  </form>
</ng-template>
