import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ResidencyAddress } from 'src/app/models';
import { YearsMonthsDuration } from '../../models/years-months-duration.model';
import * as _ from 'lodash';

@Component({
  template: '',
})

export abstract class ResidencyAddressBaseComponent {

  @Output()
  durationAtAddressChanged: EventEmitter<YearsMonthsDuration> = new EventEmitter<YearsMonthsDuration>();

  @Input()
  isReadOnly: boolean = false;

  @Input()
  set residencyAddress(residencyAddress: ResidencyAddress) {
    this._residencyAddress = residencyAddress;
    if (!residencyAddress) {
      this._residencyAddress = new ResidencyAddress();
    }
    this._oldResidencyAddress = _.cloneDeep(residencyAddress);
    if (this._residencyAddress) {
      if (this._residencyAddress.durationMonths == null && this._residencyAddress.durationYears == null) {
        this.durationAtAddress = null;
      } else {
        this.durationAtAddress = new YearsMonthsDuration(residencyAddress.durationYears, residencyAddress.durationMonths);
      }
    }
  }

  get residencyAddress(): ResidencyAddress {
    return this._residencyAddress;
  }

  @Output()
  residencyStreetAddressChanged: EventEmitter<ResidencyAddressChangedEvent> = new EventEmitter<ResidencyAddressChangedEvent>();

  @Output()
  residencyCityBlurred: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Output()
  residencyStateBlurred: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Output()
  residencyZipCodeBlurred: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Output()
  residencyUnitNoBlurred: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  durationAtAddress: YearsMonthsDuration;

  private _residencyAddress: ResidencyAddress;

  protected _oldResidencyAddress: ResidencyAddress;

  constructor() {
  }

  onDurationChanged = () => {
    this.nullifyDurations();
    if (this.durationAtAddress) {
      this.residencyAddress.durationYears = this.durationAtAddress.years;
      this.residencyAddress.durationMonths = this.durationAtAddress.months;
    }
    this.durationAtAddressChanged.emit(this.durationAtAddress);
  }

  onStreetAddressChanged() {
    if (this._oldResidencyAddress.address && this.residencyAddress.address) {
      if (this.residencyAddress.address.address1?.toLocaleLowerCase() !=
        this._oldResidencyAddress.address.address1?.toLocaleLowerCase()) {
        this.residencyStreetAddressChanged.emit({ oldAddress: this._oldResidencyAddress, newAddress: this.residencyAddress });
        this._oldResidencyAddress = _.cloneDeep(this.residencyAddress);
      }
    } else if (!this._oldResidencyAddress.address || !this.residencyAddress.address) {
      this.residencyStreetAddressChanged.emit({ oldAddress: this._oldResidencyAddress, newAddress: this.residencyAddress });
      this._oldResidencyAddress = _.cloneDeep(this.residencyAddress);
    }
  }

  onCityBlurred() {
    this.residencyCityBlurred.emit(this._residencyAddress);
  }

  onStateBlurred() {
    this.residencyStateBlurred.emit(this._residencyAddress);
  }

  onZipCodeBlurred() {
    this.residencyZipCodeBlurred.emit(this._residencyAddress);
  }

  onUnitNoBlurred(){
    this.residencyUnitNoBlurred.emit(this._residencyAddress);
  }

  private nullifyDurations = () => {
    this.residencyAddress.durationYears = null;
    this.residencyAddress.durationMonths = null;
  }
}

export class ResidencyAddressChangedEvent {
  oldAddress: ResidencyAddress;
  newAddress: ResidencyAddress;
}
