<div class='p-inputgroup' [ngClass]='skin'>
  <input
    #model='ngModel'
    (blur)='onBlur($event)'
    [leadZeroDateTime]='true'
    [mask]='mask'
    [ngModel]='textValue'
    [placeholder]='placeholder'
    [required]='required'
    [readonly]="readonly"
    pInputText
    type='text'
  />

  <button
    (click)='overlayPanel.show($event)'
    icon='fa fa-calendar'
    pButton
    type='button'
    [hidden]="readonly"
  ></button>
</div>

<p-overlayPanel #overlayPanel styleClass='date-time-input-calendar'>
  <ng-template pTemplate='content'>
    <p-calendar
      [(ngModel)]='dateValue'
      [focusTrap]='false'
      [inline]='true'
      [showTime]='showTime'
    ></p-calendar>
  </ng-template>
</p-overlayPanel>

<div *ngIf='model && model.touched && model.errors' class='invalid-feedback'>
  <ng-container *ngIf='model.errors.required'>
    This field is required
  </ng-container>

  <ng-container *ngIf='model.errors.invalid'>
    Invalid date
  </ng-container>
</div>
