import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';
import { SubjectProperty } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';

@Component({
  selector: 'app-flood-details',
  templateUrl: './flood-details.component.html',
  styleUrls: ['./flood-details.component.scss']
})
export class FloodDetailsComponent implements OnInit {

  @Input()
  set subjectProperty(subjectProperty: SubjectProperty) {
    this._subjectProperty = cloneDeep(subjectProperty);
  }

  get subjectProperty(): SubjectProperty {
    return this._subjectProperty;
  }


  @Input()
  hasSaveCancelButtons: boolean = true;

  @Input()
  readonly: boolean = false;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApproved: EventEmitter<SubjectProperty> = new EventEmitter<SubjectProperty>();

  private _subjectProperty: SubjectProperty;

  nFIPCommunityParticipationStatuses: EnumerationItem[] = [];

  constructor(private readonly _enumsService: EnumerationService) { 
    this._enumsService.getMortgageEnumerations().subscribe((enums) => {
      this.nFIPCommunityParticipationStatuses = enums[Constants.mortgageEnumerations.nFIPCommunityParticipationStatus];
    });
  }

  ngOnInit(): void {
  }

  onSaveClicked = () => {
    this.changesApproved.emit(this.subjectProperty);
  }

}
