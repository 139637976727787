<div class="card">
  <div class="card-header d-flex">
    <h4 class="card-title">
      <i class="fab fa-wpforms me-2"></i>
      Declarations for '{{borrower.firstName}} {{borrower.lastName}}'
    </h4>
  </div>
  <div class="card-body">
    <qa-about-property 
      [borrower]="borrower"
      [isPurchase]="isPurchase"
      [propertyTypesOwned]="propertyTypesOwned"
      [yesNoOptions]="yesNoOptions"
      [holdTitles]="holdTitles">
    </qa-about-property>
    <div class="layout-spacer col-sm-2 col-xs-6">&nbsp;</div>
    <qa-about-finance 
      [borrower]="borrower"
      [bankruptcyType]="bankruptcyType"
      [yesNoOptions]="yesNoOptions">
    </qa-about-finance>
  </div>
</div>