import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { chain, isNil } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, Subscription } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, Borrower, Branch, Configuration, CustomData, HousingExpense, Income, LoanApplication, MortgageBorrower, ProductPricing } from 'src/app/models';
import { AddressLookupResult } from 'src/app/models/address-lookup-result.model';
import { LeadCampaign } from 'src/app/models/config/global-config.model';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanProduct } from 'src/app/models/config/product.model';
import { LoanDetailsInfo } from 'src/app/models/loan/loan-details-info.model';
import { MortgageDti } from 'src/app/models/mortgage-dti.model';
import { ReferralSource } from 'src/app/models/referral-source.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { UpsertReferralSourceComponent } from 'src/app/modules/referral-source/components/upsert-referral-source/upsert-referral-source.component';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { AgentService } from 'src/app/services/agent.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanService } from 'src/app/services/loan';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { AgentFull } from '../../../models/agent.model';
import { AppDetailsService } from '../../../services/app-details.service';
import { AddressDetailViewDialogComponent } from '../address-detail-view-dialog/address-detail-view-dialog.component';
import { MortgageCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';

@Component({
  selector: 'loan-summary-hea',
  templateUrl: './loan-summary-hea.component.html',
  styleUrls: ['./loan-summary-hea.component.scss']
})
export class LoanSummaryHEAComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild("loanForm")
  loanForm: NgForm;

  @Output()
  dialClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loadingStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  application: LoanApplication;
  mortgage: UrlaMortgage;
  mortgageCalculationDetails: MortgageCalculationDetails;

  ftcSectionAmounts: any = {};

  proposedHousingExpenseTotal: number;
  currentHousingExpenseTotal: number;
  purchaseCreditsTotal: number;
  reoTotal: number;
  assetTotal: number;
  liabilityTotal: number;
  incomeTotal: number;
  totalMonthlyDebts: number;
  housingPaymentsTotal: number;
  unPaidBalanceTotal: number;

  currentHousingExpense: HousingExpense;
  proposedHousingExpense: HousingExpense;

  borrowers: MortgageBorrower[] = [];
  loanBorrowers: Borrower[] = [];
  incomes: Income[] = [];

  totalDownPayment: number;
  downPaymentSources: string;

  piTiInfo: any;
  totalPiTi: number;

  haveMapError: boolean = false;

  mapId: number = Utils.getUniqueId();

  companyId: number;
  enabledChannels: EnumerationItem[] = [];
  externalCompanyBranches: Branch[] = [];

  agents: ReferralSource[] = [];
  users: User[] = [];

  leadCampaigns: LeadCampaign[] = [];

  customData: CustomData;

  allLoanPurposes: LoanPurpose[] = [];
  products: LoanProduct[] = [];

  ficoOnFile: number | undefined;

  productTypes: EnumerationItem[] = [];
  attachmentTypes: EnumerationItem[] = [];
  propertyTypes: EnumerationItem[] = [];
  lienPositionTypes: EnumerationItem[] = [];
  amortizationTypes: EnumerationItem[] = [];
  mortgageAppliedForTypes: EnumerationItem[] = [];
  governmentRefinanceTypes: EnumerationItem[] = [];
  propertyOccupancyTypes: EnumerationItem[] = [];
  loanPurposeOptions: EnumerationItem[] = [];
  states: EnumerationItem[] = [];

  yesNoOptions: EnumerationItem[] = [
    { name: "Yes", value: true },
    { name: "No", value: false }
  ];

  showPrequalDetailsSection: boolean = false;
  showCurrentLoanInfoSection: boolean = false;
  secondPageActionBarVisible: boolean = false;

  isPurchase: boolean = false;
  isRefi: boolean = false;

  hiddenFields: string[] = [];

  isSubjectPropertyAddressHidden: boolean = false;
  isAppraisedValueHidden: boolean = false;

  private _loanPurposeValueChanged: boolean;
  private _oldLoanPurposeValue: number;

  private _loanPurposePurchaseEnumValue: string;
  private _loanPurposeRefiEnumValue: string;

  private _contextSubscription: Subscription;
  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _utilityService: UtilityService,
    private readonly _mortgageService: MortgageService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _loanService: LoanService,
    private readonly _agentService: AgentService,
    private readonly _enumsService: EnumerationService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this._contextSubscription = this.applicationContextService.context.subscribe(appContext => {
      if (appContext.application) {
        this.initialize(appContext);
      }
    });

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(appContext => {
      if (appContext.application) {
        this.initialize(appContext);
      }
    });

  }

  ngAfterViewInit() {
    if (this.mortgage) {
      this.initPropertyAddressMap();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._contextSubscription) {
      this._contextSubscription.unsubscribe();
    }
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  initialize = (appContext: ApplicationContext) => {
    this.loadingStateChange.emit(true);

    this.secondPageActionBarVisible = appContext.isCallControlPanelOpen;
    this.application = _.cloneDeep(appContext.application);
    if (!this.application.productPricing) {
      this.application.productPricing = new ProductPricing();
    }
    this.application.productPricing.rate = this.application.productPricing.rate / 100;
    this.mortgage = _.cloneDeep(appContext.currentMortgage);
    this.borrowers = _.cloneDeep(appContext.currentMortgage?.borrowers) || [];
    this.borrowers = _.orderBy(this.borrowers, ['printApplicationIndex', 'jointWithBorrowerId'], ['asc', 'desc']);

    this.mortgageCalculationDetails = appContext.currentMortgageCalculationDetails;

    const creditScores = this.borrowers.filter(b => b.creditScore).map(b => b.creditScore);
    this.ficoOnFile = Math.min(...creditScores);

    this.companyId = appContext.userPermissions.companyId;
    this.enabledChannels = _.cloneDeep(appContext.globalConfig.enabledChannels);
    this.users = _.cloneDeep(appContext.globalConfig.users) || [];
    this.users.forEach(u => {
      u["userFullName"] = this.getUserFullName(u.userCompanyGuid);
    });

    this.leadCampaigns = _.cloneDeep(appContext.globalConfig.leadCampaigns.filter(x => x.active || x.leadCampaignId == this.application.leadCampaignId)) || [];
    this.customData = _.cloneDeep(appContext.customData);
    this.allLoanPurposes = _.cloneDeep(appContext.globalConfig.loanPurpose);
    this.products = _.cloneDeep(appContext.globalConfig.product) || [];

    this._oldLoanPurposeValue = this.application.loanPurposeId;

    this.showPrequalDetailsSection = this.allLoanPurposes.find(x => x.loanPurposeId === this.application.loanPurposeId)?.mortgageLoanPurpose == "Purchase";
    this.showCurrentLoanInfoSection = !this.showPrequalDetailsSection;

    if (!this.mortgage.subjectProperty.presentValue) {
      this.mortgage.subjectProperty.presentValue = null;
    }

    this.loadLoanHiddenFields();

    if (this.companyId) {
      this.loadCompanyBranches();
    }

    let incomes = [];
    this.borrowers.forEach(b => {
      let employmentIncomes = [];
      b.employments.forEach(e => {
        employmentIncomes.push(...e.incomes);
      });
      incomes.push(...employmentIncomes);
      incomes.push(...b.nonEmploymentIncomes);
    })
    this.incomes = incomes;
    this.incomeTotal = this.calculateTotal(this.incomes, "monthlyIncome");

    if (this.application) {

      this.loadLoanBorrowers(this.application);
      this.loadAgents();

    }

    this.loadMortgageEnums();

    if (this.mortgage) {

      this.currentHousingExpense = this.mortgage.proposedHousingExpense.isCurrent ? this.mortgage.proposedHousingExpense : new HousingExpense();
      this.proposedHousingExpense = this.mortgage.proposedHousingExpense.isCurrent ? new HousingExpense() : this.mortgage.proposedHousingExpense;

      this.purchaseCreditsTotal = this._calculationService.calculateSourceOfFundsValue(this.mortgage);
      this.reoTotal = this.calculateTotal(this.mortgage.realEstateOwned, "marketValue");
      this.assetTotal = this.calculateTotal(this.mortgage.assets, "cashMarketValue");
      this.liabilityTotal = this.calculateTotal(this.mortgage.liabilities, "monthlyPayment");
      this.totalMonthlyDebts = this._calculationService.calculateBorrowerMonthlyDebts(this.mortgage)

      this.housingPaymentsTotal = this._calculationService.calculateHousingExpenseTotal(this.mortgage.proposedHousingExpense)
        + this._calculationService.calculateBorrowerMonthlyDebts(this.mortgage);

      this.unPaidBalanceTotal = this.calculateTotal(this.mortgage.liabilities, "unpaidBalance");

      this.piTiInfo = this._calculationService.getPiTiPaymentInfo(this.mortgage);
      this.totalPiTi = this.piTiInfo.pi + this.piTiInfo.ti;

      let isPurchase = this.mortgage.subjectProperty.purposeOfLoan && this.mortgage.subjectProperty.purposeOfLoan == "Purchase";
      this.totalDownPayment = isPurchase ? this.calculateTotal(this.mortgage.transactionDetail.purchaseCredits, "purchaseCreditAmount") : null;
      this.downPaymentSources = isPurchase ? this.mortgage.transactionDetail.purchaseCredits.map(c => c.sourceType).map(t => this.getWords(t)).join("/") : null;

      this.currentHousingExpenseTotal = this._calculationService.calculateHousingExpenseTotal(this.currentHousingExpense);
      this.proposedHousingExpenseTotal = this._calculationService.calculateHousingExpenseTotal(this.proposedHousingExpense);

      this.ftcSectionAmounts = this._calculationService.getFtcSectionAmounts(this.mortgage);

    }
    this.loadingStateChange.emit(false);
  }

  initPropertyAddressMap = () => {

    let queryStr = this.mortgage.subjectProperty.address1;

    if (!queryStr) {
      return;
    }

    let el = document.getElementById("propertyAddressMap" + this.mapId) as HTMLElement;
    if (!el) {
      return;
    }

    const map = new google.maps.Map(el);

    let neighbourhoodService = new google.maps.places.PlacesService(map);


    if (this.mortgage.subjectProperty.city) {
      queryStr += ', ' + this.mortgage.subjectProperty.city;
    }

    if (this.mortgage.subjectProperty.state) {
      queryStr += ', ' + this.mortgage.subjectProperty.state.toUpperCase();
    }

    if (this.mortgage.subjectProperty.zipCode) {
      queryStr += ' ' + this.mortgage.subjectProperty.zipCode;
    }

    neighbourhoodService.textSearch({ query: queryStr }, (results: google.maps.places.PlaceResult[], status: google.maps.places.PlacesServiceStatus, pagination: google.maps.places.PlaceSearchPagination) => {

      if (status == google.maps.places.PlacesServiceStatus.OK && results.length) {

        let location = results[0].geometry.location;
        let placeId = results[0].place_id;

        let mapProp: google.maps.MapOptions = {
          center: location,
          zoom: 18,
          gestureHandling: "none",
          streetViewControl: false,
          zoomControl: false,
          fullscreenControl: false,
          keyboardShortcuts: false,
          mapTypeId: google.maps.MapTypeId.SATELLITE,
          tilt: 0
        };

        map.setOptions(mapProp);

        const marker = new google.maps.Marker({
          position: location,
          map: map,
          icon: 'http://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=home|FFFF00',
          label: { text: this.mortgage.subjectProperty.address1, color: "white", fontWeight: "bold", className: "property-address-marker" }
        } as google.maps.ReadonlyMarkerOptions);

        google.maps.event.addListener(marker, 'click', () => {
          this.openPropertyAddressDialog(placeId)
        });

        google.maps.event.trigger(map, "resize");

      }
      else {
        this.haveMapError = true;
      }
    })

  }

  onLoanPurposeChanged() {
    this._loanPurposeValueChanged = (this._oldLoanPurposeValue != this.application.loanPurposeId);
    this.showPrequalDetailsSection = this.allLoanPurposes.find(x => x.loanPurposeId === this.application.loanPurposeId)?.mortgageLoanPurpose == "Purchase";
    this.showCurrentLoanInfoSection = !this.showPrequalDetailsSection;
  }

  onBaseLoanAmountChanged = async (): Promise<void> => {
    const amount = !this.mortgage.mortgageTerm.amount ? 0 : Number(this.mortgage.mortgageTerm.amount);
    const miOrFundingFeeFinancedAmount = !this.mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount ? 0 : Number(this.mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount);
    this.mortgage.mortgageTerm.totalLoanAmount = amount + miOrFundingFeeFinancedAmount;
    if (amount) {
      this.mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalPercent =
        (this.mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount / amount) * 100;
    }

    await this.recalculateFirstMortgagePAndI();

    this.mortgage.calculatedStats.totalLoanOrDrawAmount = this._calculationService.calculateTotalLoanOrDrawAmount(this.mortgage);
    this.mortgage.calculatedStats.totalMortgageLoans = this._calculationService.calculateTotalMortgage(this.mortgage);
    this.mortgage.calculatedStats.totalDueFromBorrowers = this._calculationService.calculateTotalDueFromBorrowers(this.mortgage);
    this.mortgage.calculatedStats.totalMortgageLoansAndCredits = this.mortgage.calculatedStats.totalMortgageLoans + this.mortgage.calculatedStats.totalCredit;
    this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);

    this.calculateLtvCltvRatios(this.mortgage.mortgageTerm.amount, null, null);
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.mortgage.subjectProperty.state = zipCode.state.toLowerCase();
      this.mortgage.subjectProperty.city = Utils.toTitleCase(zipCode.city);
      this.mortgage.subjectProperty.zipCode = zipCode.zipcode;
      this.mortgage.subjectProperty.county = zipCode.county;
      this.mortgage.subjectProperty.country = 'us';
    }
  }

  onHandleAddressChange = (lookupResult: AddressLookupResult) => {
    this.mortgage.subjectProperty.address1 = null; // to reset the last populated address.

    if (!lookupResult) {
      this.mortgage.subjectProperty.city = null;
      this.mortgage.subjectProperty.state = null;
      this.mortgage.subjectProperty.zipCode = null;
      this.mortgage.subjectProperty.county = null;
    }
    else {
      if (lookupResult.city) {
        this.mortgage.subjectProperty.city = lookupResult.city;
      }

      if (lookupResult.state) {
        this.mortgage.subjectProperty.state = lookupResult.state;
      }

      if (lookupResult.zipCode) {
        this.mortgage.subjectProperty.zipCode = lookupResult.zipCode;
      }

      if (lookupResult.address) {
        this.mortgage.subjectProperty.address1 = lookupResult.address;
      }

      if (lookupResult.county) {
        this.mortgage.subjectProperty.county = lookupResult.county;
      }
    }

    this.initPropertyAddressMap();

  }

  onBorrowerDeleted = (loanBorrowers: Borrower[]) => {
    this.loanBorrowers = loanBorrowers;
    this._loanService.getApplicationModel(this.application.applicationId, true).subscribe(loanInfoModel => {
      this.applicationContextService.updateMortgageAndApplication(loanInfoModel.mortgageLoan, loanInfoModel, undefined, loanBorrowers);
      this._spinner.hide();
    }, (error) => {
      this._spinner.hide();
      this._notifyService.showError(
        error ? error.message : 'Unable to reload loan',
        'Error!'
      );
    });
  }

  onBorrowerUpdated = (loanBorrowers: Borrower[]) => {
    this.applicationContextService.updateBorrowers(loanBorrowers);
  }

  onUpdatedPrimaryBorrower = (data: { application: LoanApplication, borrowers: Borrower[] }) => {
    this.application = data.application;
    this.mortgage = data.application.mortgageLoan || this.mortgage; // compatibilty: admin is deployed before api

    this.applicationContextService.updateMortgageAndApplication(this.mortgage, this.application, this.customData, data.borrowers);
  }

  onLtvValueChanged = () => {
    this.calculateBaseLoanAmount();
  }

  calculateTotalDue = () => {
    this.mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this.mortgage);
    this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);

    this.mortgage.transactionDetail.estimatedClosingCostsAmount =
      (this.mortgage.transactionDetail.prepaidItemsEstimatedAmount ?? 0) +
      (this.mortgage.transactionDetail.prepaidEscrowsTotalAmount ?? 0) +
      (this.mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount ?? 0);
  }

  onMortgageTermChanged = async (): Promise<void> => {
    await this.recalculateFirstMortgagePAndI();
  }

  productTypeChanged = () => {
    if (!['FHA', 'VA', 'USDA'].includes(this.mortgage.mortgageTerm.mortgageAppliedFor)) {
      this.mortgage.governmentLoanDetail.sectionOfActType = null;
    }
  }

  loadMortgageEnums = () => {
    this._enumsService.getMortgageEnumerations().subscribe((result) => {
      this.productTypes = result[Constants.mortgageEnumerations.mortgageAppliedForType];
      this.attachmentTypes = result[Constants.mortgageEnumerations.attachmentType];
      this.propertyTypes = result[Constants.mortgageEnumerations.propertyType];
      this.propertyOccupancyTypes = result[Constants.enumerations.propertyTypes];

      this.lienPositionTypes = result[Constants.mortgageEnumerations.lienPositionType];
      this.amortizationTypes = result[Constants.mortgageEnumerations.amortizationType];
      this.mortgageAppliedForTypes = result[Constants.mortgageEnumerations.mortgageAppliedForType];
      this.governmentRefinanceTypes = result[Constants.mortgageEnumerations.governmentRefinanceType];

      this.loanPurposeOptions = result[Constants.enumerations.loanPurposes];

      this.states = this._enumsService.states;

      this._loanPurposePurchaseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Purchase);
      this._loanPurposeRefiEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Refinance);
      this.isPurchase = this.isPurposeOfLoanPurchase();
      this.isRefi = this.isPurposeOfLoanRefinance();


    });
    if (!this.mortgage.proposedHousingExpense.supplementalPropertyInsurance) {
      this.mortgage.proposedHousingExpense.supplementalPropertyInsurance = 0;
    }
  }

  loadLoanHiddenFields = () => {
    this._appDetailsService.getLoanHiddenFields().subscribe((response) => {
      this.hiddenFields = this.getHiddenFields(response);

      this.isSubjectPropertyAddressHidden = this.hiddenFields.findIndex(f => f === "Subject Property") > -1;
      this.isAppraisedValueHidden = this.hiddenFields.findIndex(f => f === "Appraised Value") > -1;

    });
  }

  onPurposeOfLoanChanged = () => {
    this.isPurchase = this.isPurposeOfLoanPurchase();
    this.isRefi = this.isPurposeOfLoanRefinance();
    if (!this.isPurchase) {
      if (this.mortgage.transactionDetail)
        this.mortgage.transactionDetail.purchasePriceAmount = null;
      this.mortgage.transactionDetail.sellerPaidClosingCostsAmount = null;
    }
    if (!this.isRefi) {
      this.mortgage.subjectProperty.originalCostYear = null;
      this.mortgage.subjectProperty.originalCost = null;
      this.mortgage.subjectProperty.amountExistingLiens = null;
      this.mortgage.subjectProperty.improvementCost = null;
      this.mortgage.subjectProperty.refiPurpose = null;
      this.mortgage.subjectProperty.describeImprovement = null;
      this.mortgage.subjectProperty.improvementStatus = null;
      this.mortgage.calculatedStats.totalPaidOffForRefinance = 0;
      this.mortgage.calculatedStats.financialPartialPayoffTotalAmount = this._calculationService.calculateFinancialPartialPayoffTotalAmount(this.mortgage);
      this.mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this.mortgage);
      this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);
    }
    else {
      this.mortgage.calculatedStats.totalPaidOffForRefinance = this._calculationService.calculateTotalPayOffForRefinance(this.mortgage);
      this.mortgage.calculatedStats.financialPartialPayoffTotalAmount = this._calculationService.calculateFinancialPartialPayoffTotalAmount(this.mortgage);
      this.mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this.mortgage);
      this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);
    }
  }

  openPropertyAddressDialog = (placeID: string) => {
    let modalRef = this._modalService.open(AddressDetailViewDialogComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.googlePlaceId = placeID;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  getWords = (str: string) => {
    return Utils.splitCamelCaseString(str);
  }

  getPersonalFullNameByEmployment = (incomeBorrowerId: number | null, incomeEmploymentId?: number): string => {
    let borrower;
    if (incomeEmploymentId) {
      borrower = this.borrowers.find(b => b.employments.findIndex(e => e.employmentId == incomeEmploymentId));
    } else {
      borrower = this.borrowers.find(b => b.borrowerId == incomeBorrowerId);
    }
    return this._utilityService.getBorrowerFullName(borrower);
  }

  getPersonFullNames = (borrowerIds: number[], isLoanBorrower: boolean = false): string => {
    let borrowerArray = isLoanBorrower ? this.loanBorrowers : this.borrowers as any[];
    let matchedPersons = borrowerIds.map(id => borrowerArray.find(b => b.borrowerId == id));
    return matchedPersons.length > 0 ? matchedPersons.map(b => this._utilityService.getBorrowerFullName(b)).join(",") : null;
  }

  calculateTotal = (items: any[], fieldName: string) => {
    return items.length > 0 ? items.reduce((prev, curr) => prev + (curr[fieldName] || 0), 0) : 0;
  }

  loadLoanBorrowers = (application: LoanApplication) => {
    if (application.applicationId) {
      this._loanService.getBorrowers(application.applicationId)
        .subscribe((borrowers: Borrower[]) => {
          this.loanBorrowers = borrowers;
        })
    }
  }

  loadCompanyBranches = () => {
    this._appDetailsService.getAllBranches(this.companyId).subscribe((branches) => {
      if (branches && branches.length > 0) {
        this.externalCompanyBranches = branches;
      }
    },
      (error) => {
        this._notifyService.showError(
          error ? error.message : 'Unable to get branches',
          'Error!'
        );
      });
  }

  loadAgents = (defaultSelectedAgent: AgentFull = null) => {
    this._agentService.getAllReferralSources().subscribe(response => {
      this.agents = chain(response)
        .filter(a => !isNil(a.firstName) || !isNil(a.lastName))
        .orderBy(['lastName', 'firstName'])
        .value();

      this.agents.forEach(a => {
        a["agentFullName"] = this.getAgentFullName(a.agentId);
      })

      if (defaultSelectedAgent) {
        const referralSourceToSelect = this.agents.find(a => a.firstName.toLocaleLowerCase() === defaultSelectedAgent.agent.firstName.toLocaleLowerCase() &&
          a.lastName.toLocaleLowerCase() === defaultSelectedAgent.agent.lastName.toLocaleLowerCase());
        if (referralSourceToSelect) {
          this.application.referralSource = referralSourceToSelect.agentId;
        }
      }
    });
  }

  saveLoanInfo = () => {
    this.loanForm.form.markAllAsTouched();
    if (!this.loanForm.form.valid) {
      return;
    }

    if (this.application.productPricing && this.application.productPricing.productId) {
      const product = this.products.find(el => el.productId.toString() == this.application.productPricing.productId);
      if (product) {
        this.application.productPricing.productName = product.productName;
      }
    }

    this.adjustCustomData();

    const payLoad = new LoanDetailsInfo();
    payLoad.application = this.application;
    payLoad.customData = this.customData;

    if (payLoad.application.productPricing && payLoad.application.productPricing.rate) {
      payLoad.application.productPricing.rate = parseFloat((payLoad.application.productPricing.rate * 100).toFixed(3));
    }

    this._spinner.show();

    forkJoin({
      loanInfo: this._appDetailsService.saveLoanInfo(this.application.applicationId, payLoad)
        .pipe(
          map((res) => res),
          catchError(error => {
            this._notifyService.showError(
              error.message || 'Unable to save Loan Info',
              'Error!'
            );
            return of(null);
          })),
      mortgage: this._mortgageService.saveMortgage(this.mortgage)
        .pipe(
          map((res) => res),
          catchError(error => {
            this._notifyService.showError(
              error.message || 'Unable to save Mortgage',
              'Error!'
            );
            return of(null);
          }))
    })
      .subscribe({
        next: (multiResult) => {
          if (!multiResult.loanInfo || !multiResult.mortgage) {
            this._spinner.hide();
            return;
          }

          this.application = multiResult.loanInfo.application;
          this.mortgage = multiResult.mortgage;
          this._oldLoanPurposeValue = this.application.loanPurposeId;

          this.applicationContextService.updateMortgageAndApplication(this.mortgage, this.application, this.customData);

          this._notifyService.showSuccess(
            'Your loan has been saved successfully.',
            'Success!'
          );

          if (this._loanPurposeValueChanged) {
            this._loanPurposeValueChanged = false;
            this.saveLoanStatus();
          } else {
            this._spinner.hide();
          }
        },
        error: () => {
          this._spinner.hide();
        }
      })
  }

  onAddHomeInsuranceDialog() {
    const modalRef = this._modalService.open(UpsertReferralSourceComponent, Constants.modalOptions.fullScreen);
    this.application.referralSource = null;

    modalRef.result.then((newAgent: AgentFull) => {
      this.loadAgents(newAgent);
    }, () => {
    });
  }

  adjustCustomData() {
    if (Array.isArray(this.customData.customData1)) {
      this.customData.customData1 = JSON.stringify(this.customData.customData1)
    }
    if (Array.isArray(this.customData.customData2)) {
      this.customData.customData2 = JSON.stringify(this.customData.customData2)
    }
    if (Array.isArray(this.customData.customData3)) {
      this.customData.customData3 = JSON.stringify(this.customData.customData3)
    }
    if (Array.isArray(this.customData.customData4)) {
      this.customData.customData4 = JSON.stringify(this.customData.customData4)
    }
    if (Array.isArray(this.customData.customData5)) {
      this.customData.customData5 = JSON.stringify(this.customData.customData5)
    }
    if (Array.isArray(this.customData.customData6)) {
      this.customData.customData6 = JSON.stringify(this.customData.customData6)
    }
    if (Array.isArray(this.customData.customData7)) {
      this.customData.customData7 = JSON.stringify(this.customData.customData7)
    }
    if (Array.isArray(this.customData.customData8)) {
      this.customData.customData8 = JSON.stringify(this.customData.customData8)
    }
    if (Array.isArray(this.customData.customData9)) {
      this.customData.customData9 = JSON.stringify(this.customData.customData9)
    }
    if (Array.isArray(this.customData.customData10)) {
      this.customData.customData10 = JSON.stringify(this.customData.customData10)
    }
  }

  selectProduct(productId: string) {
    if (!productId) {
      return;
    }

    this._appDetailsService.getProductById(Number(productId)).subscribe((response) => {
      if (response.productId == Number(productId)) {
        this.application.productPricing.term = response.term;
      } else {
        this.application.productPricing.term = null;
      }
    },
      (error) => {
        this._notifyService.showError(
          error ? error.message : 'Unable to get Product',
          'Error!'
        );
      });
  }

  onPurchasePriceAmountChanged = () => {
    this.calculateLtvCltvRatios(null, null, this.mortgage.transactionDetail.purchasePriceAmount);
  }

  onAppraisedPropertyValueChanged = () => {
    this.calculateLtvCltvRatios(null, this.mortgage.subjectProperty.presentValue, null);
  }

  onInterestRateChanged = async (): Promise<void> => {
    await this.recalculateFirstMortgagePAndI();
  }

  private getAgentFullName = (agentId: number) => {
    if (agentId) {
      const referToToSelect = this.agents.find(a => a.agentId == agentId);
      return referToToSelect ? Utils.getPersonsDisplayName(referToToSelect) : null;
    }

    return null;
  }

  private getUserFullName = (userCompanyGuid: string) => {
    if (userCompanyGuid) {
      let user = this.users.find(u => u.userCompanyGuid == userCompanyGuid);
      return user ? Utils.getPersonsDisplayName(user) : null;
    }

    return null;
  }

  private saveLoanStatus = () => {
    this._appDetailsService.setLoanStatus(this.application.applicationId).subscribe((result) => {
      if (result) {
        const loanStatusId = this.application.loanStatusId ? this.application.loanStatusId : -1
        this._appDetailsService.setNextLoanStatus(this.application.loanPurposeId, loanStatusId, this.application.applicationId).subscribe(() => {
          this._spinner.hide();
        });
      }
      this._spinner.hide();
    }, error => {
      this._notifyService.showError(
        error ? error.message : 'Unable to get Loan Status',
        'Error!'
      );
      this._spinner.hide();
    });
  }

  private isPurposeOfLoanRefinance = () => {
    return this.isLoanPurposeSet() && this.mortgage.subjectProperty.purposeOfLoan == this._loanPurposeRefiEnumValue;
  }

  private isPurposeOfLoanPurchase = () => {
    return this.isLoanPurposeSet() && this.mortgage.subjectProperty.purposeOfLoan == this._loanPurposePurchaseEnumValue;
  }

  private isLoanPurposeSet = () => {
    return this.mortgage.subjectProperty && this.mortgage.subjectProperty.purposeOfLoan;
  }

  private getHiddenFields = (loanHiddenFields?: Configuration): Array<string> => {
    if (!loanHiddenFields?.valueStr) return [];
    return loanHiddenFields.valueStr?.split(",").map(el => el.trim());
  }

  private calculateLtvCltvRatios = (newLoanAmount: number, newPresentValue: number, newPurchasePriceAmount: number) => {
    let loanAmount = newLoanAmount ?? this.mortgage.mortgageTerm.amount;
    let presentValue = newPresentValue ?? this.mortgage.subjectProperty.presentValue;
    let sub = this.mortgage.transactionDetail.subordinateLienAmount;
    let purchasePriceAmount = newPurchasePriceAmount ?? this.mortgage.transactionDetail.purchasePriceAmount;

    const minValue = this.minOfSalesPriceAndAppraisedValue(purchasePriceAmount, presentValue);
    if (minValue == 0) {
      this.application.ltv = 0;
      this.application.cltv = 0;
    } else {
      this.application.ltv = loanAmount / minValue;
      if (!sub) {
        this.application.cltv = this.application.ltv;
      } else {
        this.application.cltv = (loanAmount + sub) / minValue;
      }
    }
  }

  private calculateBaseLoanAmount = () => {
    let presentValue = this.mortgage.subjectProperty.presentValue;
    let sub = this.mortgage.transactionDetail.subordinateLienAmount;
    let purchasePriceAmount = this.mortgage.transactionDetail.purchasePriceAmount;

    let minValue = this.minOfSalesPriceAndAppraisedValue(purchasePriceAmount, presentValue);
    if (!minValue) {
      minValue = 0;
    }
    this.mortgage.mortgageTerm.amount = this.application.ltv * minValue;
    if (!sub) {
      this.application.cltv = this.application.ltv;
    }
  }

  private minOfSalesPriceAndAppraisedValue = (sales: number, appraised: number) => {
    if (this.mortgage.subjectProperty.purposeOfLoan === 'Purchase') {

      var salesPrice = sales ?? Number.MAX_VALUE;
      var appraisedValue = appraised ?? Number.MAX_VALUE;
      var min = Math.min(salesPrice, appraisedValue);
      return min != Number.MAX_VALUE ? min : 0;

    } else {
      // Refinance
      return appraised;
    }
  };

  private async recalculateFirstMortgagePAndI(): Promise<void> {
    await this._spinner.show();

    try {
      const calculationDetails = await firstValueFrom(
        this._mortgageService.redoMortgageCalculationDetails(
          this.mortgage
        )
      );
      this.mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest =
        calculationDetails.proposedExpenses.firstMortgagePrincipalAndInterest;

      this.mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest =
        calculationDetails.proposedExpenses.otherMortgageLoanPrincipalAndInterest;

      this.mortgage.calculatedStats.proposedMonthlyPaymentTotal = this._calculationService.calculateHousingExpenseTotal(this.mortgage.proposedHousingExpense);

    } catch (e) {
      this._notifyService.showError(
        e?.message || 'Unable to recalculate LTV and related values',
        'Error!'
      );
    } finally {
      await this._spinner.hide();
    }
  }
}
