<div class="card">
  <div class="card-header d-flex">
    <h4 class="card-title">
      <i class="fas fa-user me-2"></i>
      Demographics for '{{borrower.firstName}} {{borrower.lastName}}'
    </h4>
  </div>
  <div class="card-body">
    <div class="grid-layout">
      <div class="row mb-4">
        <div class="col-sm-6" style="height: 100%;">
          <div class="form-input" [ngClass]="{
            'invalid-required-field': !isEthnicitySelected && quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] == 'required',
            'invalid-requested-field': !isEthnicitySelected && quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] == 'requested'}">
              <span class="fw-bold">Ethnicity</span>
            <div class="select-many-checkbox select-many-checkbox-horizontal">
              <multi-checkbox-picker [name]="borrower.borrowerId + '_ethnicity'" [options]="ethnicityOptions"
                [(ngModel)]="ethnicities" (change)="ethnicityChanged()" [small]="true">
              </multi-checkbox-picker>
            </div>
            <div class="border-outline grid-layout mt-10" *ngIf="isHispanicOrLatinoSelected">
              <div class="row">
                <div class="select-many-checkbox select-many-checkbox-horizontal field-editor-container">
                  <multi-checkbox-picker [name]="borrower.borrowerId + '_ethnicityOrigin'" [options]="hispanicOriginOptions"
                    [(ngModel)]="ethnicityOrigins" (change)="ethnicityOriginChanged()" [small]="true">
                  </multi-checkbox-picker>
                </div>
              </div>

              <div class="row" *ngIf="isOtherSelectedForHispanicOrLatinoEthnicity">
                <input type="text" name="ethnicityOriginOtherDescription" class="input-element"
                [(ngModel)]="borrower.governmentMonitors.ethnicityOriginOtherDescription" placeholder="Enter origin here">
              </div>
            </div>
          </div>
          <div class="form-input"
            [ngClass]="{
            'invalid-required-field': !isSexSelected && quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.sex'] == 'required',
            'invalid-requested-field': !isSexSelected && quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.sex'] == 'requested'}">
            <span class="fw-bold">Sex</span>
            <div class="select-many-checkbox select-many-checkbox-horizontal">
              <div class="row">
                <div class="col-sm-12" *ngFor="let sex of sexOptions">
                </div>
                <multi-checkbox-picker [name]="borrower.borrowerId + '_sex'" [options]="sexOptions" [(ngModel)]="sexes"
                  (change)="onSexInformationChanged()" [vertical]="true" [small]="true">
                </multi-checkbox-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-input"
            [ngClass]="{
              'invalid-required-field': !isRaceSelected && quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.race'] == 'required',
              'invalid-requested-field': !isRaceSelected && quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.race'] == 'requested'}">
              <span class="fw-bold">Race</span>
            <div class="select-many-checkbox select-many-checkbox-horizontal">
              <div class="row">
                <div class="col-sm-12" *ngFor="let race of raceOptions">
                  <div class="form-check form-check-inline">
                    <div class="checkbox-primary me-2">
                      <input type="hidden" [(ngModel)]="fakeRaceFieldSoRaceCheckboxesRegisterOnFormAsControls"
                        [name]="borrower.borrowerId + '_race_' + race.name + '_fake'">
                      <input id="{{borrower.borrowerId}}_race_{{race.name}}" class="form-check-input"
                        [value]="race.value" type="checkbox" [name]="borrower.borrowerId + '_race_' + race.name"
                        (change)="onRaceChanged($event, race.value)" [checked]="races.includes(race.value)">
                      <label for="{{borrower.borrowerId}}_race_{{race.name}}">
                        {{race.name}}
                      </label>
                    </div>
                  </div>

                  <div class="border-outline grid-layout mt-10 mb-10"
                    *ngIf="race.value == nativeAmericanRace && isNativeAmericanSelected">
                    <div class="row">
                      <input type="text" [name]="'tribeName_' + borrower.borrowerId" class="input-element"
                        [(ngModel)]="borrower.governmentMonitors.nativeAmericanTribeName"
                        placeholder="Enter name of enrolled or principal tribe"/>
                    </div>
                  </div>

                  <div class="border-outline grid-layout mt-10 mb-10" *ngIf="race.value == asianRace && isAsianSelected">
                    <div class="row">
                      <div class="select-many-checkbox select-many-checkbox-horizontal field-editor-container">
                        <multi-checkbox-picker [name]="borrower.borrowerId + '_asianRaceDesignation'" [options]="asianRaceDesignationOptions"
                          [(ngModel)]="raceDesignations" (change)="raceDesignationChanged()" [small]="true">
                        </multi-checkbox-picker>
                      </div>
                    </div>

                    <div class="row" *ngIf="isOtherSelectedForAsianRace">
                      <input type="text" [(ngModel)]="borrower.governmentMonitors.otherAsianRace" class="input-element"
                        placeholder="Enter race here"  [name]="'otherAsian_' + borrower.borrowerId"/>
                    </div>
                  </div>

                  <div class="border-outline grid-layout mt-10 mb-10"
                    *ngIf="race.value == pacificIslanderRace && isPacificIslanderSelected">
                    <div class="row">
                      <div class="select-many-checkbox select-many-checkbox-horizontal field-editor-container">
                        <multi-checkbox-picker [name]="borrower.borrowerId + '_pacificIslanderRaceDesignation'" [options]="pacificIslanderRaceDesignationOptions"
                          [(ngModel)]="raceDesignations" (change)="raceDesignationChanged()" [small]="true">
                        </multi-checkbox-picker>
                      </div>
                    </div>

                    <div class="row" *ngIf="isOtherSelectedForPacificIslanderRace">
                      <input type="text" [name]="'otherPacificislander_' + borrower.borrowerId" class="input-element"
                        [(ngModel)]="borrower.governmentMonitors.otherPacificIslanderRace" placeholder="Enter race here"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> Application Taken By </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.governmentMonitors.applicationTakenMethod == null &&
          quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] == 'required' ,
          'ng-invalid-requested' : borrower.governmentMonitors.applicationTakenMethod == null && quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] == 'requested'}">
          <select id="applicationTakenMethod{{borrower.borrowerId}}"
            name="applicationTakenMethod{{borrower.borrowerId}}"
            [(ngModel)]="borrower.governmentMonitors.applicationTakenMethod" (ngModelChange)="onMethodByWhichApplicationIsTakenChanged()"
            [required]="quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] == 'required' ||
            quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] == 'requested'">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let type of applicationChannelOptions" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> Was the ethnicity of the Borrower collected on the basis of visual observation or surname? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == null &&
          quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] == 'required' ,
          'ng-invalid-requested' : borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == null && quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'isEthnicityBasedOnVisualOrSurname' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'isEthnicityBasedOnVisualOrSurname' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> Was the sex of the Borrower collected on the basis of visual observation or surname? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == null &&
          quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] == 'required' ,
          'ng-invalid-requested' : borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == null && quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'isGenderBasedOnVisualOrSurname' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'isGenderBasedOnVisualOrSurname' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.governmentMonitors.isGenderBasedOnVisualOrSurname"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> Was the race of the Borrower collected on the basis of visual observation or surname? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == null &&
          quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] == 'required' ,
          'ng-invalid-requested' : borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == null && quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'isRaceBasedOnVisualOrSurname' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'isRaceBasedOnVisualOrSurname' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.governmentMonitors.isRaceBasedOnVisualOrSurname"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
