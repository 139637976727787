<div class='grid-layout has-border'>
  <div class='grid-header mb-1'>
    <div class='grid-header-left'>
      {{borrower.firstName + ' ' + borrower.lastName}}
    </div>

    <div class='grid-header-right'></div>
  </div>

  <!-- Has Served In Military -->
  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'bg-transparent': !isReadOnly}"
      class='col-md-10 grid-layout-text'
      label='Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?'></urla-grid-cell>

    <urla-radio-group (change)='onIsServingUnitedStatesArmedForcesChange()' [(ngModel)]='borrower.hasServedInMilitary'
      [inEditMode]='inEditMode' [name]="'hasServedInMilitary' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}" [options]='yesNoOptions' [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.hasServedInMilitary")' [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-md-2' fieldBeingEdited='mortgage.borrower.hasServedInMilitary' layout='horizontal'></urla-radio-group>
  </div>



  <!-- Military Service Fields -->
  <ng-container *ngIf='borrower.hasServedInMilitary || inEditMode'>
    <div class='grid-layout-row px-2 mb-2'>
      <div class="grid-layout-spacer col-md-4 col-sm-0">&nbsp;</div>
      <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'bg-transparent': !isReadOnly}"
        class='col-md-6 grid-layout-text' label='First time use?'></urla-grid-cell>

      <urla-radio-group [(ngModel)]='borrower.isFirstUseOfVaBenefit' [inEditMode]='inEditMode'
        [name]="'isFirstUseOfVaBenefit' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
        [options]='yesNoOptions' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.isFirstUseOfVaBenefit")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-md-2' fieldBeingEdited='mortgage.borrower.isFirstUseOfVaBenefit'
        layout='horizontal'></urla-radio-group>
    </div>

    <div class='grid-layout-row px-2 mb-2'>
      <div class="grid-layout-spacer col-md-4 col-sm-0">&nbsp;</div>
      <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'bg-transparent': !isReadOnly}"
        class='col-md-6 grid-layout-text' label='Exempt from funding fee?'></urla-grid-cell>

      <urla-radio-group [(ngModel)]='borrower.isVaFundingFeeExempt' [inEditMode]='inEditMode'
        [name]="'isVaFundingFeeExempt' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
        [options]='yesNoOptions' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.isVaFundingFeeExempt")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-md-2' fieldBeingEdited='mortgage.borrower.isVaFundingFeeExempt'
        layout='horizontal'></urla-radio-group>
    </div>

    <div class='grid-layout-row px-2 mb-2'>
      <div class="grid-layout-spacer col-md-4 col-sm-0">&nbsp;</div>
      <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'bg-transparent': !isReadOnly}"
        class='col-md-6 grid-layout-text text-right' label='Surviving spouse?'></urla-grid-cell>

      <urla-radio-group [(ngModel)]='borrower.isSurvivingSpouseOfMilitary' [inEditMode]='inEditMode'
        [name]="'isSurvivingSpouseOfMilitary' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
        [options]='yesNoOptions' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.isSurvivingSpouseOfMilitary")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-md-2' fieldBeingEdited='mortgage.borrower.isSurvivingSpouseOfMilitary'
        layout='horizontal'></urla-radio-group>
    </div>


    <!-- Military Status (& Other Description), Start Date, Expected Completion Date -->
    <div class='grid-layout-row px-2 mb-2'>
      <!-- Military Status -->
      <urla-dropdown (change)='onMilitaryStatusChange()' [(ngModel)]='borrower.militaryStatus' [inEditMode]='inEditMode'
        [name]="'militaryStatus' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
        [options]='militaryStatusOptions' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.militaryStatus")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-12 col-md-6 col-xl-3' fieldBeingEdited='mortgage.borrower.militaryStatus'
        label='Military Status'></urla-dropdown>

      <!-- Military Status Other Description -->
      <urla-text-input *ngIf='borrower.militaryStatus === MilitaryStatus.Other || inEditMode'
        [(ngModel)]='borrower.militaryStatusOtherDescription' [inEditMode]='inEditMode'
        [name]="'militaryStatusOtherDescription' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
        [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.militaryStatusOtherDescription")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-12 col-md-6 col-xl-3'
        fieldBeingEdited='mortgage.borrower.militaryStatusOtherDescription'
        label='Military Status Other Description'></urla-text-input>

      <!-- Start Date -->
      <urla-date-input *ngIf='borrower.isActiveDutyMilitary || inEditMode' [(ngModel)]='borrower.militaryTourStartDate'
        [inEditMode]='inEditMode' [name]="'startDate' + borrower.borrowerId" [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.militaryTourStartDate")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-12 col-md-6 col-xl-3' fieldBeingEdited='mortgage.borrower.militaryTourStartDate'
        label='Start Date'></urla-date-input>

      <!-- Expected Completion Date -->
      <urla-date-input *ngIf='borrower.isActiveDutyMilitary || inEditMode' [(ngModel)]='borrower.militaryTourEndDate'
        [inEditMode]='inEditMode' [name]="'expectedCompletionDate' + borrower.borrowerId" [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.militaryTourEndDate")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-12 col-md-6 col-xl-3' fieldBeingEdited='mortgage.borrower.militaryTourEndDate'
        label='Expected Completion Date'></urla-date-input>

      <!-- Spacer for Expected Completion Date -->

      <div *ngIf='!borrower.isActiveDutyMilitary && !inEditMode' class='grid-layout-spacer col-12 col-md-6 col-xl-6'>
      </div>

      <!-- Spacer for Military Status Other Description -->
      <div *ngIf='borrower.militaryStatus !== MilitaryStatus.Other && !inEditMode'
        class='grid-layout-spacer col-12 col-md-6 col-xl-3'></div>

    </div>

    <!-- Military Branch (& Other Description), Service Number, Served As -->
    <div class='grid-layout-row px-2 mb-2'>
      <!-- Military Branch -->
      <urla-dropdown (ngModelChange)='onChangeBranchOfService()' [(ngModel)]='borrower.branchOfService'
        [disabled]='isReadOnly' [inEditMode]='inEditMode' [name]="'branchOfService' + borrower.borrowerId"
        [ngClass]="{'bg-transparent': !isReadOnly}" [options]='militaryBranchOptions' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.branchOfService")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-12 col-md-6 col-xl-3' fieldBeingEdited='mortgage.borrower.branchOfService'
        label='Military Branch'></urla-dropdown>

      <!-- Military Branch Other Description -->
      <urla-text-input *ngIf='borrower.branchOfService === BranchOfService.Other || inEditMode'
        [(ngModel)]='borrower.branchOfServiceOtherDescription' [disabled]='isReadOnly' [inEditMode]='inEditMode'
        [name]="'branchOfServiceOtherDescription' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
        [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.branchOfServiceOtherDescription")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-12 col-md-6 col-xl-3'
        fieldBeingEdited='mortgage.borrower.branchOfServiceOtherDescription'
        label='Military Branch Other Description'></urla-text-input>

      <!-- Spacer for Military Branch Other Description -->
      <div *ngIf='borrower.branchOfService !== BranchOfService.Other && !inEditMode'
        class='grid-layout-spacer col-12 col-md-6 col-xl-3'></div>

      <!-- Military Service Number -->
      <urla-text-input [(ngModel)]='borrower.militaryServiceNumber' [disabled]='isReadOnly' [inEditMode]='inEditMode'
        [name]="'militaryServiceNumber' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
        [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.militaryServiceNumber")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-xl-3'
        fieldBeingEdited='mortgage.borrower.militaryServiceNumber' label='Service Number'></urla-text-input>

      <!-- Military Served As Name -->
      <urla-text-input [(ngModel)]='borrower.militaryServedAsName' [disabled]='isReadOnly' [inEditMode]='inEditMode'
        [name]="'militaryServedAsName' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
        [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.militaryServedAsName")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-xl-3'
        fieldBeingEdited='mortgage.borrower.militaryServedAsName' label='Served As'></urla-text-input>
    </div>

    <!-- VA Borrower -->
    <ng-container *ngIf='isVaBorrower'>
      <div class='grid-layout-row px-2 mb-2'>

        <!-- CAIVRS Identifier -->
        <urla-text-input [(ngModel)]='borrower.caivrsIdentifier' [disabled]='isReadOnly' [inEditMode]='inEditMode'
          [name]="'caivrsIdentifier' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
          [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.caivrsIdentifier")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-6 col-md-4'
          fieldBeingEdited='mortgage.borrower.caivrsIdentifier' label='CAIVRS Identifier'></urla-text-input>

        <urla-grid-cell class='col-sm-6 col-md-4'>
          <div class='d-flex flex-column flex-grow-1'>
            <div class='d-flex justify-content-between align-items-baseline'>
              <p class='mb-0 text-uppercase'>Nearest Living Relative</p>

              <!-- Edit Nearest Living Relative fields button -->
              <button (click)='onEditNearestLivingRelative()' class='btn text-primary' style='margin-right: -0.375rem;'
                title='Edit Nearest Living Relative'>
                <i class='fa fa-pencil-alt'></i>
              </button>
            </div>

            <p class='mb-0' style='min-height: 1rem;'>
              {{borrower.nearestLivingRelativeName}}
            </p>
          </div>
        </urla-grid-cell>

        <!-- VA Borrower Certification Occupancy Type -->
        <urla-dropdown [(ngModel)]='borrower.vaBorrowerCertificationOccupancyType' [disabled]='isReadOnly'
          [inEditMode]='inEditMode' [name]="'vaBorrowerCertificationOccupancyType' + borrower.borrowerId"
          [ngClass]="{'bg-transparent': !isReadOnly}" [options]='vaCertOccupancyOptions' [readonly]='isReadOnly'
          [required]='isRequired("mortgage.borrower.vaBorrowerCertificationOccupancyType")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-6 col-md-4'
          fieldBeingEdited='mortgage.borrower.vaBorrowerCertificationOccupancyType'
          label='VA Borrower Certification Occupancy Type'></urla-dropdown>

      </div>
      <div class='grid-layout-row px-2 mb-2'>
        
        <!-- Has Filed VA Disability Claim -->
        <urla-radio-group [(ngModel)]='borrower.hasFiledVADisabilityClaim' [inEditMode]='inEditMode'
          [name]="'hasFiledVADisabilityClaim' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
          [options]='yesNoOptions' [readonly]='isReadOnly'
          [required]='isRequired("mortgage.borrower.hasFiledVADisabilityClaim")' [urlaFieldsConfig]='urlaFieldsConfig'
          class='col-sm-6 col-md-3' fieldBeingEdited='mortgage.borrower.hasFiledVADisabilityClaim'
          label='Has Filed VA Disability Claim'></urla-radio-group>

        <!-- Has VA Benefit Related Indebtedness -->
        <urla-radio-group [(ngModel)]='borrower.hasVABenefitRelatedIndebtedness' [inEditMode]='inEditMode'
          [name]="'hasVABenefitRelatedIndebtedness' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
          [options]='yesNoOptions' [readonly]='isReadOnly'
          [required]='isRequired("mortgage.borrower.hasVABenefitRelatedIndebtedness")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-6 col-md-3'
          fieldBeingEdited='mortgage.borrower.hasVABenefitRelatedIndebtedness'
          label='Has VA Benefit Related Indebtedness'></urla-radio-group>

        <!-- Is VA Past Credit Record Satisfactory -->
        <urla-radio-group [(ngModel)]='borrower.isVAPastCreditRecordSatisfactory' [inEditMode]='inEditMode'
          [name]="'isVAPastCreditRecordSatisfactory' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
          [options]='yesNoOptions' [readonly]='isReadOnly'
          [required]='isRequired("mortgage.borrower.isVAPastCreditRecordSatisfactory")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-6 col-md-3'
          fieldBeingEdited='mortgage.borrower.isVAPastCreditRecordSatisfactory'
          label='Is VA Past Credit Record Satisfactory'></urla-radio-group>

        <!-- Has Current Pre-Discharge Claim -->
        <urla-radio-group [(ngModel)]='borrower.hasCurrentPreDischargeClaim' [inEditMode]='inEditMode'
          [name]="'hasCurrentPreDischargeClaim' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
          [options]='yesNoOptions' [readonly]='isReadOnly'
          [required]='isRequired("mortgage.borrower.hasCurrentPreDischargeClaim")' [urlaFieldsConfig]='urlaFieldsConfig'
          class='col-sm-6 col-md-3' fieldBeingEdited='mortgage.borrower.hasCurrentPreDischargeClaim'
          label='Has Current Pre-Discharge Claim'></urla-radio-group>

      </div>

      <div class='grid-layout-row px-2 mb-2'>
        <!-- VA Social Security Tax Amount -->
        <urla-currency-input [(ngModel)]='borrower.vaSocialSecurityTaxAmount' [disabled]='isReadOnly'
          [inEditMode]='inEditMode' [name]="'vaSocialSecurityTaxAmount' + borrower.borrowerId"
          [ngClass]="{'bg-transparent': !isReadOnly}" [readonly]='isReadOnly'
          [required]='isRequired("mortgage.borrower.vaSocialSecurityTaxAmount")' [urlaFieldsConfig]='urlaFieldsConfig'
          class='col-sm-6 col-md-3' fieldBeingEdited='mortgage.borrower.vaSocialSecurityTaxAmount'
          label='VA Social Security Tax Amount'></urla-currency-input>

        <!-- VA Federal Tax Amount -->
        <urla-currency-input [(ngModel)]='borrower.vaFederalTaxAmount' [disabled]='isReadOnly' [inEditMode]='inEditMode'
          [name]="'vaFederalTaxAmount' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
          [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.vaFederalTaxAmount")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-6 col-md-3'
          fieldBeingEdited='mortgage.borrower.vaFederalTaxAmount' label='VA Federal Tax Amount'></urla-currency-input>

        <!-- VA State Tax Amount -->
        <urla-currency-input [(ngModel)]='borrower.vaStateTaxAmount' [disabled]='isReadOnly' [inEditMode]='inEditMode'
          [name]="'vaStateTaxAmount' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
          [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.vaStateTaxAmount")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-6 col-md-3'
          fieldBeingEdited='mortgage.borrower.vaStateTaxAmount' label='VA State Tax Amount'></urla-currency-input>

        <!-- VA Local Tax Amount -->
        <urla-currency-input [(ngModel)]='borrower.vaLocalTaxAmount' [disabled]='isReadOnly' [inEditMode]='inEditMode'
          [name]="'vaLocalTaxAmount' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
          [readonly]='isReadOnly' [required]='isRequired("mortgage.borrower.vaLocalTaxAmount")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-6 col-md-3'
          fieldBeingEdited='mortgage.borrower.vaLocalTaxAmount' label='VA Local Tax Amount'></urla-currency-input>
      </div>


    </ng-container>

    <!-- Is VA Purple Heart Recipient -->
    <div class='row px-2 mb-2' *ngIf='!inEditMode'>
      <label class='form-check form-check-inline form-check-solid me-5 mx-2'>
        <input [(ngModel)]='borrower.isVAPurpleHeartRecipient' [disabled]='isReadOnly' class='form-check-input'
          name='vaPurpleHeartRecipient{{borrower.borrowerId}}' type='checkbox' />

        <span class='fs-6'>Purple Heart recipient</span>
      </label>
    </div>

    <!-- Is Active Duty On Day Following Date Of Separation -->
    <div class='row px-2 mb-2' *ngIf='!inEditMode'>
      <label class='form-check form-check-inline form-check-solid me-5 mx-2'>
        <input [(ngModel)]='borrower.isActiveDutyOnDayFollowingDateOfSeparation' [disabled]='isReadOnly'
          class='form-check-input' name='activeDutyOnDayFollowingDateOfSeparation{{borrower.borrowerId}}'
          type='checkbox' />

        <span class='fs-6'>Active duty on day following date of separation</span>
      </label>
    </div>

    <!-- Has Separated From Military Service Due To Disability -->
    <div class='row px-2 mb-2' *ngIf='!inEditMode && borrower.militaryStatus == "Separated"'>
      <label class='form-check form-check-inline form-check-solid me-5 mx-2'>
        <input [(ngModel)]='borrower.hasSeparatedFromMilitaryServiceDueToDisability' [disabled]='isReadOnly'
          class='form-check-input' name='separatedFromMilitaryService{{borrower.borrowerId}}' type='checkbox' />

        <span class='fs-6'>Separated due to disability</span>
      </label>
    </div>
  </ng-container>
</div>

<nearest-living-relative-editor-drawer #nearestLivingRelativeEditorDrawer
  [drawerName]='"" + borrower.borrowerId'></nearest-living-relative-editor-drawer>