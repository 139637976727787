import { Component, OnInit, Input } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { MortgageBorrower } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';

@Component({
  selector: 'qa-borrower-declarations',
  templateUrl: 'qa-borrower-declarations.component.html',
  styleUrls: ['./qa-borrower-declarations.component.scss'],
  viewProviders: [formViewProvider]
})

export class QuickApplyBorrowerDeclarationsComponent implements OnInit {

  @Input()
  borrower: MortgageBorrower;

  @Input()
  isPurchase: boolean = false;
  
  propertyTypesOwned: EnumerationItem[] = [];
  yesNoOptions: EnumerationItem[] = [];
  holdTitles: EnumerationItem[] = [];
  bankruptcyType: EnumerationItem[] = [];

  constructor(private readonly _enumsService: EnumerationService) {
  }

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.propertyTypesOwned = enums[Constants.enumerations.propertyTypesOwned];
      this.yesNoOptions = this._enumsService.getYesNoEnumItems();
      this.holdTitles = enums[Constants.enumerations.holdTitles];
      this.bankruptcyType = enums[Constants.enumerations.bankruptcyTypes];
    });
  }
}