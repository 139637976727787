<div class='card'>
  <div class='card-header'>
    {{ mode === 'edit' ? 'Edit' : 'Add' }} Purchase Credit
  </div>

  <div class='card-body'>
    <form
      #form='ngForm'
      class='w-100'
      id='creditForm'
      name='creditForm'
      novalidate
    >
      <div class='row'>
        <div class='form-group col-md-4 mb-3'>
          <label
            [for]='id("credit-type")'
            class='form-label mb-lg-0 text-start mb-2'
          >
            Credit Type
          </label>

          <div>
            <select
              #creditType='ngModel'
              [(ngModel)]='credit.purchaseCreditType'
              [id]='id("credit-type")'
              [ngClass]="{
                'is-invalid': creditType && creditType.touched && creditType.invalid
              }"
              class='form-select col-md-6'
              name='creditType'
              required
            >
              <option *ngFor='let type of creditTypes' [ngValue]='type.value'>
                {{ type.name }}
              </option>
            </select>

            <div class='invalid-feedback'>Credit Type is required.</div>
          </div>
        </div>

        <div class='form-group col-md-4 mb-3'>
          <label
            [for]='id("credit-amount")'
            class='form-label mb-lg-0 text-start mb-2'
          >
            Credit Amount
          </label>

          <div>
            <currency-input
              [(ngModel)]='credit.purchaseCreditAmount'
              [id]='id("credit-amount")'
              [required]='true'
              name='creditAmount'
            ></currency-input>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class='card-footer d-flex justify-content-end align-items-stretch gap-2'>
    <qa-cancel-button (qaClick)='onClickCancel()'></qa-cancel-button>

    <qa-ok-button (qaClick)='onClickClose()'></qa-ok-button>
  </div>
</div>
