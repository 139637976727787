import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Employment, EmploymentTypeEnum, Income, TypeOfIncomeEnum } from '../../../../../../../models';
import { combineLatestWith, firstValueFrom, map, merge, race, ReplaySubject, startWith, Subject, Subscription } from 'rxjs';
import { splitCamelCase } from '../../../../../../../core/services/string-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  IncomeDialogComponent,
} from '../../../../../../urla/borrower-information/employment-income/income-dialog/income-dialog.component';
import { Constants } from '../../../../../../../services/constants';
import { EnumerationService } from '../../../../../../../services/enumeration-service';
import { EnumerationItem } from '../../../../../../../models/simple-enum-item.model';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { isCurrentEmployment, toSafeDate } from '../../../quick-apply-utils';
import { NgForm } from '@angular/forms';
import { IncomeCalculatorDialogComponent } from './income-calculator-dialog/income-calculator-dialog.component';
import * as _ from 'lodash';
import { MortgageModelFieldsConfig } from 'src/app/modules/urla/models/urla-fields-config.model';
import { MortgageService } from 'src/app/services/mortgage.service';
import { FieldsConfig } from './qa-fi-employment-address/qa-fi-employment-address.component';
import { resetArrayTo, resetObjectTo } from '../reset-object.to';
import { handleNonErrorDismissals } from '../../../../../../../core/services/utils';

interface SelfEmploymentDetails {
  borrowerOwnershipShare: string;
  selfEmploymentForm: string;
  selfEmploymentMonthlyIncomeOrLoss: number;
}

@Component({
  selector: 'qa-fi-employment-editor',
  templateUrl: './qa-fi-employment-editor.component.html',
  styleUrls: ['./qa-fi-employment-editor.component.scss'],
})
export class QaFiEmploymentEditorComponent
  implements OnInit, OnChanges, OnDestroy {
  /**
   * The employment object to edit. Can be omitted if the component is used
   * to create new employment. This will not be modified by the component.
   * Instead, the component will emit a new partial employment object when the
   * form is saved.
   */
  @Input()
  employment?: Employment;
  private _originalEmployment?: Employment;

  @Input()
  externalError?: string;

  @Output() readonly close = new EventEmitter<void>();

  @Output() readonly cancel = new EventEmitter<void>();

  @Output() readonly update = new EventEmitter<Partial<Employment>>();

  @ViewChild('form')
  formElement: NgForm;

  protected addressFieldsConfig: Partial<FieldsConfig>;

  private _incomeTypeItems: readonly EnumerationItem[];
  protected ownershipShareItems: readonly EnumerationItem[];
  protected selfEmploymentFormOptions: readonly EnumerationItem[];

  private readonly _isRetiredChanges$ = new Subject<boolean>();
  private readonly _selfEmployedChanges$ = new Subject<boolean>();
  private readonly _selfEmploymentMonthlyIncomeOrLossChanges$ =
    new Subject<number>();
  private readonly _save$ = new Subject<void>();
  private readonly _incomeChanges$ = new Subject<Partial<Income>[]>();

  protected monthlyTotal: number = 0;

  protected quickApplyFieldsConfig: MortgageModelFieldsConfig;

  private get _addableIncomeTypeItems(): EnumerationItem[] {
    const currentIncomeTypes = new Set(this.employment.incomes.map(
      (income) => income.typeOfIncome,
    ));

    return this._incomeTypeItems.filter(
      (item) => !currentIncomeTypes.has(item.value as TypeOfIncomeEnum),
    );
  }

  protected get isSelfEmploymentFormDisplayed(): boolean {
    const value = this.employment.borrowerOwnershipShare;
    return hasSelfEmploymentForm(value);
  }

  protected get isCurrent(): boolean {
    return isCurrentEmployment(this.employment);
  }

  protected set isCurrent(value: boolean) {
    this.employment.employmentType = value
      ? EmploymentTypeEnum.CurrentEmployer
      : EmploymentTypeEnum.FormerEmployer;
  }

  protected get isRetired(): boolean {
    return this.employment.employer === 'Retired';
  }

  protected set isRetired(value: boolean) {
    this._isRetiredChanges$.next(value);
  }

  protected get selfEmployed(): boolean {
    return this.employment.selfEmployed;
  }

  protected set selfEmployed(value: boolean) {
    this.employment.selfEmployed = value;
    this._selfEmployedChanges$.next(value);
  }

  protected get selfEmploymentMonthlyIncomeOrLoss(): number {
    return this.employment.selfEmploymentMonthlyIncomeOrLoss;
  }

  protected set selfEmploymentMonthlyIncomeOrLoss(value: number) {
    this.employment.selfEmploymentMonthlyIncomeOrLoss = value;
    this._selfEmploymentMonthlyIncomeOrLossChanges$.next(value);
  }

  protected get minEndDate(): Date | null {
    return toValidationSafeDate(this.employment.startDate);
  }

  protected get maxEndDate(): Date {
    return new Date();
  }

  private _monthlyIncomeChangesSubscription?: Subscription;
  private _isRetiredChangesSubscription?: Subscription;
  private _selfEmployedChangesSubscription?: Subscription;
  private _savingChangesSubscription?: Subscription;

  private _destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _enumService: EnumerationService,
    private readonly _mortgageService: MortgageService
  ) {
    this.quickApplyFieldsConfig = this._mortgageService.quickApplyFieldsConfig;
  }

  async ngOnInit(): Promise<void> {
    await this.initEnumerations();
    this.initAddressFieldsConfig();

    if (this.employment == null) {
      this.setEmployment();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const employmentChange = changes['employment'];
    if (employmentChange) {
      this.setEmployment(employmentChange.currentValue);
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private async initEnumerations(): Promise<void> {
    const enums: Enumerations =
      await firstValueFrom(this._enumService.getMortgageEnumerations());

    this.initIncomeTypeItems(enums);
    this.initOwnershipShareItems(enums);
    this.initSelfEmploymentFormOptions(enums);
  }

  private initAddressFieldsConfig(): void {
    const getConfigValue = (key: string) =>
      this.quickApplyFieldsConfig[`mortgage.borrower.employment.${key}`];
    const createConfig = (key: string) => {
      const config = getConfigValue(key);
      return {
        required: config === 'required',
        requested: config === 'requested',
      }
    }

    this.addressFieldsConfig = {
      employer: createConfig('employer'),
      address1: createConfig('address1'),
      address2: createConfig('address2'),
      city: createConfig('city'),
      state: createConfig('state'),
      zipCode: createConfig('zipCode'),
    }
  }

  private setEmployment(employment?: Employment): void {
    // Edit or create new employment mode.
    const isEditMode = employment != null;

    employment ??= new Employment();
    employment.incomes ??= [];
    const incomes = this.addMissingRequiredIncomes(employment.incomes);
    resetArrayTo(employment.incomes, incomes);
    employment.employmentType ??= EmploymentTypeEnum.FormerEmployer;

    this.employment = employment;
    this._originalEmployment = _.cloneDeep(employment);

    setTimeout(() => {
      if (isEditMode) {
        // Display form errors from the start if in edit mode.
        this.formElement.form.markAllAsTouched();
      }

      this.subscribeToFormChanges();

      this.update.emit(employment);
    });
  }

  private subscribeToFormChanges(): void {
    this.subscribeToMonthlyIncomeChanges();
    this.subscribeToIsRetiredChanges();
    this.subscribeToSelfEmployedChanges();
    this.subscribeToSavingChanges();
  }

  /**
   * Makes sure the incomes contain the required income types.
   *
   * @param incomes The incomes to check.
   * @returns A new array of incomes that contains the required income types.
   */
  private addMissingRequiredIncomes(incomes: Iterable<Income>): Income[] {
    const missingRequiredTypes =
      new Set<TypeOfIncomeEnum>(irremovableIncomeTypes);
    const availableRequiredIncomes = new Set<Income>();
    const indexesOfAvailableRequiredTypes: number[] = [];
    Array.from(incomes, (income, index) => {
      const { typeOfIncome } = income;
      if (!irremovableIncomeTypes.has(typeOfIncome)) {
        return;
      }

      // Index must be added to the beginning as this indexes will be used to
      // remove the available incomes from the list. (When removing multiple
      // items from an array, it is important to remove them in reverse order.)
      indexesOfAvailableRequiredTypes.unshift(index);
      availableRequiredIncomes.add(income);
      missingRequiredTypes.delete(typeOfIncome);
    });

    /**
     * Creates a new array of Income objects that contains the missing required
     * income types.
     */
    const getMissingIncomes = (): Income[] => {
      if (missingRequiredTypes.size === 0) {
        return [];
      }

      return Array.from(missingRequiredTypes, (typeOfIncome) =>
        Object.assign<Income, Record<string, any>>(
          new Income(),
          {
            typeOfIncome,
            monthlyIncome: 0,
          },
        ));
    };

    const result = [...incomes];
    const missingIncomes = getMissingIncomes();
    if (missingIncomes.length > 0) {
      // Remove the available required incomes first. Then add the missing
      // incomes to the beginning of the list to keep the required incomes at
      // the top.
      indexesOfAvailableRequiredTypes.forEach((index) => {
        result.splice(index, 1);
      });

      result.unshift(...[
        ...availableRequiredIncomes,
        ...missingIncomes,
      ]);
    }

    return result;
  }

  private getValidIncomeTypes(): TypeOfIncomeEnum[] {
    const incomeTypesObject = Constants.enumerationValueNames.IncomeType;

    return Object.values(incomeTypesObject)
      .map((type) => this._enumService.getEnumValue(type) as TypeOfIncomeEnum);
  };

  private initIncomeTypeItems(enums: Enumerations): void {
    const validIncomeTypes = new Set(this.getValidIncomeTypes());

    this._incomeTypeItems = enums[Constants.enumerations.incomeType]
      .filter((item) => validIncomeTypes.has(item.value as TypeOfIncomeEnum));
  }

  private initOwnershipShareItems(enums: Enumerations): void {
    this.ownershipShareItems
      = enums[Constants.enumerations.employmentOwnershipShare];
  }

  private initSelfEmploymentFormOptions(enums: Enumerations): void {
    this.selfEmploymentFormOptions
      = enums[Constants.enumerations.selfEmploymentForm];
  }

  /**
   * Subscribes to changes in the monthly income of the incomes and the
   * self-employment monthly income or loss.
   * Updates the monthly total.
   */
  private subscribeToMonthlyIncomeChanges(): void {
    this._monthlyIncomeChangesSubscription?.unsubscribe();

    const selfEmploymentMonthlyIncomeOrLossChanges$ =
      this._selfEmploymentMonthlyIncomeOrLossChanges$.pipe(
        startWith(Number(this.employment.selfEmploymentMonthlyIncomeOrLoss) || 0),
        map((value) => ({ monthlyIncome: value })),
      );

    this._monthlyIncomeChangesSubscription = this._incomeChanges$.pipe(
      takeUntil(this._destroyed$),
      startWith(this.employment.incomes),
      combineLatestWith(selfEmploymentMonthlyIncomeOrLossChanges$),
      map(([incomes, selfEmploymentMonthlyIncomeOrLoss]) => [
        ...incomes,
        selfEmploymentMonthlyIncomeOrLoss,
      ]),
    ).subscribe((incomes) => {
      this.monthlyTotal = incomes.reduce(
        (total, income) => total + (Number(income.monthlyIncome) || 0)
        , 0,
      );
    });
  }

  /**
   * Sets the employer name according to the value of isRetired.
   */
  private subscribeToIsRetiredChanges(): void {
    this._isRetiredChangesSubscription?.unsubscribe();

    const initialEmployer = this.employment.employer;

    // Set the employer name to 'Retired' when isRetired is set to true. If
    // isRetired is later set to false, reset the employer name to the stored
    // value.
    this._isRetiredChangesSubscription = this._isRetiredChanges$.pipe(
      takeUntil(this._destroyed$),
      startWith(this.employment.employer === 'Retired'),
    ).subscribe((isRetired) => {
      this.employment.employer = isRetired
        ? 'Retired'
        : initialEmployer !== 'Retired'
          ? initialEmployer
          : '';
    });
  }

  private subscribeToSelfEmployedChanges(): void {
    this._selfEmployedChangesSubscription?.unsubscribe();

    const properties = [
      'borrowerOwnershipShare',
      'selfEmploymentForm',
      'selfEmploymentMonthlyIncomeOrLoss',
    ];

    const getSelfEmploymentDetails = () =>
      properties.reduce((result, property) => {
        result[property] = this.employment[property];
        return result;
      }, {} as SelfEmploymentDetails);

    const emptyValue = {
      borrowerOwnershipShare: '',
      selfEmploymentForm: '',
      selfEmploymentMonthlyIncomeOrLoss: 0,
    };

    let lastValue: SelfEmploymentDetails | null = null;

    const isRetiredChanges$ = this._isRetiredChanges$.pipe(
      // Reset self-employed when isRetired is set to true.
      map((isRetired) => !isRetired),
    );

    // Add or remove self-employment details based on selfEmployed and
    // isRetired.
    this._selfEmployedChangesSubscription = merge(
      this._selfEmployedChanges$,
      isRetiredChanges$,
    ).pipe(
      takeUntil(this._destroyed$),
      startWith(this.employment.selfEmployed),
      distinctUntilChanged(), // Preserve lastValue if the state is the same.
    ).subscribe((value) => {
      if (value) {
        // Restore the saved values of the self-employment details.
        Object.assign(this.employment, lastValue);
      } else {
        lastValue = getSelfEmploymentDetails();
        // Reset the self-employment details to their initial values.
        Object.assign(this.employment, emptyValue);
        this.onChangeSelfEmploymentMonthlyIncomeOrLoss(0);
      }
    });
  }

  private subscribeToSavingChanges(): void {
    this._savingChangesSubscription?.unsubscribe();

    const cancelOrDestroy$ = race(
      this._destroyed$.pipe(map(() => true)),
      this.cancel.pipe(map(() => false)),
    ).pipe(
      tap((shouldSave) => {
        if (shouldSave) {
          // Prevent ExpressionChangedAfterItHasBeenCheckedError.
          setTimeout(() => {
            this.save();
          });
        }
      }),
    );

    const subscription = this._savingChangesSubscription = this._save$.pipe(
      takeUntil(cancelOrDestroy$),
      debounceTime(200),
    ).subscribe(() => {
      this.save();
    });

    subscription.add(
      this.formElement.valueChanges.pipe(
        takeUntil(this._destroyed$),
      ).subscribe(() => {
        this.enqueueSave();
      }),
    );
  }

  protected onChangeSelfEmploymentMonthlyIncomeOrLoss(value: number): void {
    this._selfEmploymentMonthlyIncomeOrLossChanges$.next(Number(value) || 0);
  }

  protected onChangeMonthlyIncome(value: number, index: number): void {
    const incomeLike = { monthlyIncome: Number(value) || 0 };
    const incomes = [
      ...this.employment.incomes.slice(0, index),
      incomeLike,
      ...this.employment.incomes.slice(index + 1),
    ];
    this._incomeChanges$.next(incomes);
  }

  protected async onClickAddIncome(): Promise<void> {
    const modal = this._modalService.open(
      IncomeDialogComponent,
      Constants.modalOptions.large,
    );

    const { componentInstance } = modal;
    componentInstance.title = 'Add Income';
    componentInstance.incomeTypes = this._addableIncomeTypeItems;

    try {
      const result = await modal.result;
      if (result == null || result === 'cancel') {
        return;
      }
      const incomes = result as Income[];
      this.addIncomes(incomes);
    } catch (e) {
      // FIXME: The modal shouldn't throw an error when dismissed.
    }
  }

  private addIncomes(incomes: Income[]): void {
    incomes.forEach((income) => {
      income = Object.assign(new Income(), income, {
        monthlyIncome: income.monthlyIncome ?? 0,
      });

      this.employment.incomes.push(income);
    });

    this._incomeChanges$.next(this.employment.incomes);
  }

  protected onClickRemoveIncome(index: number): void {
    this.employment.incomes.splice(index, 1);

    this._incomeChanges$.next(this.employment.incomes);
  }

  protected isIncomeRemovable(typeOfIncome: TypeOfIncomeEnum): boolean {
    return !irremovableIncomeTypes.has(typeOfIncome);
  }

  protected calculateMonthlyIncome = (income: Income, index: number) => {
    const modal = this._modalService.open(IncomeCalculatorDialogComponent, Constants.modalOptions.large);
    modal.componentInstance.income = _.cloneDeep(income);

    modal.result.then((result) => {
      if (result) {
        this.employment.incomes[index] = result;
      }
    }).catch(handleNonErrorDismissals);
  }

  protected enqueueSave(): void {
    this._save$.next();
  }

  private save(): void {
    const form = this.formElement.form;
    form.markAllAsTouched();

    // FIXME: Since we are now modifying the employment object directly, this filtering causes
    //        the incomes to be deleted when the form value changes.
    // this.filterOutZeroIncomes();
    this.invalidateIncomeBorrowerIds();

    this.emitUpdate();
  }

  /**
   * Filters out the incomes that have a monthly income of 0.
   * @private
   */
  private filterOutZeroIncomes(): void {
    const employment = this.employment;
    const incomes = employment.incomes;
    employment.incomes = incomes.filter((income) => {
      const monthlyIncome = income.monthlyIncome;
      return monthlyIncome != null && monthlyIncome !== 0;
    });
  }

  /**
   * Sets the borrowerId of the incomes to the borrowerId of the employment.
   * @private
   */
  private invalidateIncomeBorrowerIds(): void {
    const employment = this.employment;
    const borrowerId = employment.borrowerId;
    employment.incomes.forEach((income) => {
      income.borrowerId = borrowerId;
    });
  }

  private emitUpdate(): void {
    this.update.emit(this.employment);
  }

  private resetEmploymentChanges(): void {
    const employment = this.employment;
    if (employment == null) {
      console.error('Cannot reset changes as the employment is null.');
      return;
    }

    const originalEmployment = this._originalEmployment;
    if (originalEmployment == null) {
      console.error('Cannot reset changes as the original employment is null.');
      return;
    }

    resetObjectTo(employment, originalEmployment);
  }

  protected onClickCancel(): void {
    this.resetEmploymentChanges();

    this.cancel.emit();
  }

  protected onClickClose(): void {
    this.close.emit();
  }

  protected getIncomeDisplayName(income: Income): string {
    return splitCamelCase(String(income.typeOfIncome));
  }
}

type Enumerations = { [k: string]: EnumerationItem[] };

// The required income types that must be present in the income array.
// These income types cannot be removed.
const irremovableIncomeTypes = Object.freeze(new Set([
  TypeOfIncomeEnum.Base,
  TypeOfIncomeEnum.Bonus,
  TypeOfIncomeEnum.Commissions,
  TypeOfIncomeEnum.Overtime,
]));

function hasSelfEmploymentForm(ownershipShare: string) {
  // FIXME: Do not use the enum value directly
  return ownershipShare === 'GreaterOrEqualTo25Percent';
}

// FIXME: Duplicated from qa-fi-employment-date.component.ts
function toValidationSafeDate(date: string | undefined): Date | null {
  if (date?.length !== 10)
    return null;

  return toSafeDate(date) ?? null;
}
