<div class="grid-layout has-border">
  <div class="grid-header">
    <div class="grid-header-left">
      <div>1a. Address Info</div>
    </div>
    <div class="grid-header-right" *ngIf="!inEditMode">
      <span class="address-history-indicator me-2"
        [ngClass]="{'text-success': numberOfYears * 12 + numberOfMonths >= 24, 'text-danger': numberOfYears * 12 + numberOfMonths < 24}">
        <i class="fa" [ngClass]="{'fa-check': numberOfYears * 12 + numberOfMonths >= 24, 'fa-times-circle': numberOfYears * 12 + numberOfMonths < 24}"></i>
          {{numberOfYears}} year(s) {{numberOfMonths}} month(s)
      </span>
      <ul class="list-unstyled topbar-nav float-end mb-0 mx-2" *ngIf="!isReadOnly">
        <li class="dropdown">
          <a class="btn btn-sm btn-soft-primary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#" role="button">
            <i class="fas fa-plus me-2"></i>Add Address
          </a>
          <div class="dropdown-menu dropdown-menu-end">
              <a class="dropdown-item" (click)="onAddFormerAddressClicked()">Former</a>
              <a class="dropdown-item" (click)="onAddMailingAddressClicked()">Mailing</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="grid-header-right" *ngIf="inEditMode"></div>
  </div>
</div>
<div class="grid-layout has-border">
  <input [name]="'hasMoreThan2YearsOfHistory_'+ borrower.borrowerId" type="hidden" [(ngModel)]="monthsOfHistoryForValidation"
    [ngClass]="{'ng-invalid-required': hasMoreThan2YearsOfHistory === false}" required/>
  <present-address
    *ngFor="let presentAddress of presentAddresses; let i = index"
    [index]="i"
    [isReadOnly]="isReadOnly"
    [showHousingInfo]="this.borrower.signingRole == 'Borrower' ||
      this.borrower.signingRole == 'CoSigner'"
    [residencyAddress]="presentAddress"
    [isSubjectProperty]="isSubjectPropertyPresentAddress"
    [isSubjectPropertyPickerVisible]="isSubjectPropertyPickerVisibleForPresentAddress"
    [otherResidencyAddresses]="borrower.residencyAddresses"
    [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    (subjectPropertyStatusChanged)="onSubjectPropertyStatusChanged($event)"
    (durationAtAddressChanged)="onDurationAtAddressChanged()"
    (residencyStreetAddressChanged)="onPresentStreetAddressChanged($event)"
    (residencyCityBlurred)="onPresentAddressCityBlurred($event)"
    (residencyStateBlurred)="onPresentAddressStateBlurred($event)"
    (residencyZipCodeBlurred)="onPresentAddressZipCodeBlurred($event)"
    (residencyBasisChanged)="onResidencyBasisChanged($event)"
    (pushAddressToSubjectPropertyClicked)="onPushPresentAddressToSubjectPropertyClicked()"
    (pullAddressSubjectPropertyClicked)="onPullPresentAddressSubjectPropertyClicked($event)"
  ></present-address>

  <non-present-address
    #nonPresentAddress
    *ngFor="let mailingAddress of mailingAddresses"
    [isReadOnly]="isReadOnly"
    [addressLabel]="'Mailing Address'"
    [residencyAddress]="mailingAddress"
    [otherResidencyAddresses]="borrower.residencyAddresses"
    [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
  ></non-present-address>

  <non-present-address
    #nonPresentAddress
    *ngFor="let formerAddress of formerAddresses"
    [isReadOnly]="isReadOnly"
    [addressLabel]="'Former Address'"
    [askForDurationAtAddress]="true"
    [residencyAddress]="formerAddress"
    [otherResidencyAddresses]="borrower.residencyAddresses"
    (durationAtAddressChanged)="onDurationAtAddressChanged()"
    [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
  ></non-present-address>
</div>

