<ng-container *ngIf="provider == 'Truv'">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table mb-0 table-centered text-center">
        <thead class="table-light">
          <tr>
            <th>On File</th>
            <th>Borrower Name</th>
            <td>Status</td>
            <td>Report Date</td>
            <td>Report Id</td>
            <td>View Report</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let order of orderHistory">
            <td>
              <i *ngIf="order.statusDescription ==='Report Ready'" class="text-success fa fa-check"></i>
            </td>
            <td>
              {{order.firstName + ' ' + order.lastName}}
            </td>
            <td>
              {{order.statusDescription}}
            </td>
            <td>
              {{order.reportDate | date:'MM/dd/yyyy h:mma'}}
            </td>
            <td>
              <span *ngIf="order.reportId">{{order.reportId}}</span>
            </td>
            <td>
              <a *ngIf="order.reportData" (click)="downloadReport(order.reportData)" href="javascript: void(0);"
                download="application.pdf">
                <i class='fa fa-md fa-file-pdf'></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="provider == 'TWN'">
  <div class="card-body">
    <p-table #voiVoeHistory [value]="twnHistoriesExcludedData" [paginator]="true" [rows]="10"
      name="voi-voe-history-table" [rowsPerPageOptions]="[10, 25, 50]" [autoLayout]="true"
      [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th>Borrower Name</th>
          <th>Ref#</th>
          <th>Verify Type</th>
          <th>Date</th>
          <th>View</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>
            <div *ngIf="mortgage.borrowers">
              {{getBorrowerNameById(data.borrowerId)}}
            </div>
          </td>
          <td>
            <div class="text-center">
              {{data.ref ? 'data.ref' : '--'}}
            </div>
          </td>
          <td>
            <div *ngIf="data.requestType">
              {{data.requestType == 0 ? 'Employment Only' : 'Employment + Income'}}
            </div>
          </td>
          <td>
            <div *ngIf="data.dateInserted">
              {{data.dateInserted | date: 'MM/dd/yyyy hh:mm a'}}
            </div>
          </td>
          <td>
            <div *ngIf="data.integrationHistoryId" class="text-center">
              <span *ngIf="loadingIntegrationHistoryId == data.integrationHistoryId"
                class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <a *ngIf="loadingIntegrationHistoryId != data.integrationHistoryId && data.isSuccessful"
                (click)="openDoc(data.integrationHistoryId)">
                <i class="fa fa-lg fa-file-pdf"></i>
              </a>
              <span *ngIf="!data.isSuccessful"> -- </span>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5" class="text-center"> There are no history items. </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-container>
