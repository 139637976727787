import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { NgForm } from '@angular/forms';
import { UrlaSectionComponent } from '../../urla-section/urla-section.component';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';
import { LoanApplication, SystemLevel } from 'src/app/models';

@Component({
  selector: 'financial-info',
  templateUrl: './financial-info.component.html',
  styleUrls: ['./financial-info.component.scss']
})
export class FinancialInfoComponent extends UrlaSectionComponent implements OnInit {

  menuItem: string = Constants.menu.urlaMenuItems.financialInfo;

  @ViewChild("financialInfoForm")
  urlaSectionForm: NgForm;

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  application: LoanApplication;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  systemLevel: SystemLevel;

  isUrlaReadonly: boolean = false;

  assetTypes: EnumerationItem[] = [];

  purchaseCreditTypes: EnumerationItem[] = [];
  downPaymentSourceTypes: EnumerationItem[] = [];

  liabilityTypes: EnumerationItem[] = [];

  assetsSubTotal: number;

  monthlyPaymentSubTotal: number;

  unpaidBalanceSubTotal: number;

  partialPayoffAmount: number;

  // If there are no assets/liabilities etc on the form initially, we cannot product a status to communicate to the left menu
  // This hidden control helps us to do that.
  triggerValidation: string = '';

  constructor(private readonly _injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _urlaValidationService: UrlaValidationService
    ) {
    super(_injector);
  }

  ngOnInit(): void {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.assetTypes = enums[Constants.enumerations.assetTypes];
      this.purchaseCreditTypes = enums[Constants.enumerations.purchaseCreditTypes];
      this.downPaymentSourceTypes = enums[Constants.enumerations.downPaymentSourceTypes];
      this.liabilityTypes = enums[Constants.enumerations.liabilityTypes];
    });
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForFinancialInfo(this.mortgage);
    super.setMenuItemStatus(validityStatus);
  }

  assetsSubTotalChange = (subTotal: number) => {
    this.assetsSubTotal = subTotal;
  }

  liabilitiesSubTotalChange = (subtotals) => {
    this.monthlyPaymentSubTotal = subtotals.monthlyPaymentSubTotal;
    this.unpaidBalanceSubTotal = subtotals.unpaidBalanceSubTotal;
    this.partialPayoffAmount = subtotals.partialPayoffAmount;
  }
}
