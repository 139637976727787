import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { UrlaMortgage } from '../models/urla-mortgage.model';
import { UrlaSectionComponent } from '../urla-section/urla-section.component';
import { NgForm } from '@angular/forms';
import { NativeAmericanLandsType, PropertyTitleType, RecordingJurisdictionType } from '../../../models';
import { createCleanUpOtherDescriptionFunction, createIsRequiredFunction } from '../urla-utils';
import { MortgageService } from 'src/app/services/mortgage.service';
import { Subscription } from 'rxjs';
import { MortgageCalculationService } from '../services/mortgage-calculation.service';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';

@Component({
  selector: 'title-information',
  templateUrl: './title-information.component.html',
  styleUrls: ['title-information.component.scss']
})
export class TitleInformationComponent extends UrlaSectionComponent implements OnInit, OnDestroy {

  @Input() set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    const subjectProperty = this.mortgage?.subjectProperty;
    if (subjectProperty == null) {
      return;
    }

    this.isRefinance = this._mortgageCalculationService.isPurposeOfLoanRefinance(this._mortgage);
    if (!this.isRefinance && subjectProperty) {
      subjectProperty.mannerTitleCurrentlyHeld = null;
      this.onMannerTitleCurrentlyHeldChange?.();
    }
    this.isLeaseHold = this._mortgage.subjectProperty && subjectProperty.estate &&
      subjectProperty.estate == this._estateLeaseHoldEnumValue;
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input() isReadOnly: boolean;

  @ViewChild('titleForm')
  urlaSectionForm: NgForm;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  menuItem: string = Constants.menu.urlaMenuItems.titleInfo;

  protected yesNoOptions: EnumerationItem[] = [];
  protected titleTypes: EnumerationItem[] = [];
  protected estateTypes: EnumerationItem[] = [];
  protected propertyTrustClassifications: EnumerationItem[] = [];
  protected nativeAmericanLandsTypes: EnumerationItem[] = [];
  protected recordingJurisdictionTypes: EnumerationItem[] = [];
  protected propertyTitleTypes: EnumerationItem[] = [];

  protected onMannerTitleCurrentlyHeldChange: () => void;
  protected onMannerTitleWillBeHeldChange: () => void;
  protected onRecordingJurisdictionTypeChange: () => void;
  protected onNativeAmericanLandsTypeChange: () => void;

  protected isRequired: (fieldName: string) => boolean;

  protected NativeAmericanLandsType = NativeAmericanLandsType;
  protected RecordingJurisdictionType = RecordingJurisdictionType;
  protected PropertyTitleType = PropertyTitleType;

  protected isRefinance = false;
  protected isLeaseHold: boolean;

  dhHiddenHack: boolean = false;

  private _mortgageChangeEventsSubscription: Subscription;

  private _mortgage: UrlaMortgage;

  private _estateLeaseHoldEnumValue: string;

  constructor(
    private readonly _mortgageService: MortgageService,
    private readonly _enumsService: EnumerationService,
    private readonly _mortgageCalculationService: MortgageCalculationService,
    private readonly _urlaValidationService: UrlaValidationService,
    injector: Injector,
  ) {
    super(injector);
    this._mortgageChangeEventsSubscription = this._mortgageService.loanPurpose$.subscribe((_ => {
      const subjectProperty = this.mortgage?.subjectProperty;
      if (subjectProperty == null) {
        return;
      }

      this.isRefinance = this._mortgageCalculationService.isPurposeOfLoanRefinance(this._mortgage);
      if (!this.isRefinance && subjectProperty) {
        subjectProperty.mannerTitleCurrentlyHeld = null;
        this.onMannerTitleCurrentlyHeldChange();
      }
    }));
  }

  ngOnInit() {
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);

    this.dhHiddenHack = this.mortgage.companyId == 229 || this.mortgage.companyId == 276;

    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
    this._enumsService.getMortgageEnumerations().subscribe((result) => {
      this.estateTypes = result[Constants.mortgageEnumerations.estateType];
      this.titleTypes = result[Constants.mortgageEnumerations.titleType];
      this.propertyTrustClassifications = result[Constants.mortgageEnumerations.propertyTrustClassification];
      this.nativeAmericanLandsTypes = result[Constants.mortgageEnumerations.nativeAmericanLandsType];
      this.recordingJurisdictionTypes = result[Constants.mortgageEnumerations.recordingJurisdictionType];
      this.propertyTitleTypes = result[Constants.mortgageEnumerations.propertyTitleType];
      this._estateLeaseHoldEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.EstateType.Leasehold);
    });

    this.initCleanUpOtherDescriptionFunctions();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._mortgageChangeEventsSubscription?.unsubscribe();
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForTitleInfo(this.mortgage);
    super.setMenuItemStatus(validityStatus);
  }

  onEstateChanged = () => {
    this.isLeaseHold = this._mortgage.subjectProperty && this._mortgage.subjectProperty.estate &&
      this._mortgage.subjectProperty.estate == this._estateLeaseHoldEnumValue;
  }

  private initCleanUpOtherDescriptionFunctions(): void {
    const subjectProperty = this.mortgage.subjectProperty;

    this.onMannerTitleCurrentlyHeldChange
      = createCleanUpOtherDescriptionFunction({
        sourcePropertyName: 'mannerTitleCurrentlyHeld',
        cleaningValue: PropertyTitleType.Other,
      }).bind(undefined, subjectProperty);

    this.onMannerTitleWillBeHeldChange
      = createCleanUpOtherDescriptionFunction({
        sourcePropertyName: 'mannerTitleWillBeHeld',
        cleaningValue: PropertyTitleType.Other,
      }).bind(undefined, subjectProperty);

    this.onRecordingJurisdictionTypeChange
      = createCleanUpOtherDescriptionFunction({
        sourcePropertyName: 'recordingJurisdictionType',
        cleaningValue: RecordingJurisdictionType.Other,
      }).bind(undefined, subjectProperty);

    this.onNativeAmericanLandsTypeChange
      = createCleanUpOtherDescriptionFunction({
        sourcePropertyName: 'nativeAmericanLandsType',
        cleaningValue: NativeAmericanLandsType.Other,
      }).bind(undefined, subjectProperty);
  }
}
