import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxPrintElementModule } from "ngx-print-element";
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from "primeng/chart";
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { PricingCustomFieldService } from "src/app/services/pricing-custom-field.service";
import { SharedModule } from "src/app/shared/shared.module";
import {
  CompensationComponent, GovernmentDetailsComponent, LoanPricingSearchDialogComponent, PricingComponent, PricingDetailsComponent, PricingHistoryTableComponent, PropertyDetailsComponent
} from ".";
import { AmortizationModule } from '../amortization/amortization.module';
import { AtrQmManagementModule } from "../app-details/components/atr-qm-management/atr-qm-management.module";
import { QuickApplyModule } from "../app-details/modules/quick-apply/quick-apply.module";
import { FeesModule } from "../fees/fees.module";
import { RoleBasedInternalContactsModule } from "../new-application/components/upload-file/role-based-internal-contacts/role-based-internal-contacts.module";
import { ApplicationPricingScenariosComponent } from "./components/application-pricing-scenarios/application-pricing-scenarios.component";
import { CreateScenarioNameDialogComponent } from "./components/edit-scenario-name-dialog/create-scenario-name-dialog.component";
import { IneligibleProductDetailDialogComponent } from './components/ineligible-product-detail-dialog/ineligible-product-detail-dialog.component';
import { InitialFeesWorksheetDialog } from "./components/initial-fees-worksheet-dialog/initial-fees-worksheet-dialog.component";
import { LenderPricePricerModule } from "./components/lenderprice-pricer/lenderprice-pricer.module";
import { LoanPassPricerModule } from "./components/loanpass-pricer/loanpass-pricer.module";
import { LockExtensionModalComponent } from './components/lock-extension-modal/lock-extension-modal.component';
import { MiQuoteSearchDialogComponent } from './components/mi-quote-search-dialog/mi-quote-search-dialog.component';
import { MiQuoteSearchParametersComponent } from './components/mi-quote-search-parameters/mi-quote-search-parameters.component';
import { QuoteAdditionalBorrowerEditorComponent } from './components/mi-quote-search-parameters/quote-additional-borrower-editor/quote-additional-borrower-editor.component';
import { MiQuoteSearchResultsComponent } from './components/mi-quote-search-results/mi-quote-search-results.component';
import { MiQuoteSearchComponent } from './components/mi-quote-search/mi-quote-search.component';
import { MiQuoteSelectDialogComponent } from './components/mi-quote-select-dialog/mi-quote-select-dialog.component';
import { PinnedScenarioCardComponent } from "./components/pinned-scenario-card/pinned-scenario-card.component";
import { ScenarioNotesEditorComponent } from './components/pinned-scenario-card/scenario-notes-editor/scenario-notes-editor.component';
import { ScenarioTaxesEditorComponent } from './components/pinned-scenario-card/scenario-taxes-editor/scenario-taxes-editor.component';
import { PinnedScenariosComponent } from "./components/pinned-scenarios/pinned-scenarios.component";
import { PricingParametersComponent } from "./components/pricing-parameters/pricing-parameters.component";
import { SecondLientAmountCalculatorDialogComponent } from "./components/pricing-parameters/second-lien-amount-calculator-dialog/second-lien-amount-calculator-dialog.component";
import { PricingScenarioChangesDialogComponent } from './components/pricing-scenario-changes-dialog/pricing-scenario-changes-dialog.component';
import { PricingSearchV2Component } from './components/pricing-search-v2/pricing-search-v2.component';
import { PricingValidationDialogComponent } from "./components/pricing-validation-dialog/pricing-validation-dialog.component";
import { ImportVendorProductsDialog } from './components/pricing-vendor-products-dialog/import-vendor-products-dialog.component';
import { ProductDetailDialogComponent } from './components/product-detail-dialog/product-detail-dialog.component';
import { ProductSearchResultsComponent } from "./components/product-search-results/product-search-results.component";
import { QmDetailsDialogComponent } from './components/qm-details-dialog/qm-details-dialog.component';
import { RequestLockDialogComponent } from './components/request-lock-dialog/request-lock-dialog.component';
import { ScenarioComparisonSelectDialogComponent } from './components/scenario-comparison-select-dialog/scenario-comparison-select-dialog.component';
import { ScenarioComparisonWorksheetDialogComponent } from './components/scenario-comparison-worksheet-dialog/scenario-comparison-worksheet-dialog.component';
import { SelectedPriceCardComponent } from './components/selected-price-card/selected-price-card.component';
import { ViewAdjustmentsDialogComponent } from './components/view-adjustments-dialog/view-adjustments-dialog.component';
import { ViewNotesAdvisoriesDialogComponent } from './components/view-notes-advisories-dialog/view-notes-advisories-dialog.component';
import { PricingRoutingModule } from "./pricing-routing.module";
import { PricingConfigurationService } from "./services/pricing-configuration.service";
import { PricingService } from "./services/pricing.service";
import { RepriceLockChangesDialogComponent } from './components/reprice-lock-changes-dialog/reprice-lock-changes-dialog.component';
import { InitialFeesWorksheetEmail } from "./components/initial-fees-worksheet-email/initial-fees-worksheet-email.component";
import { QuickPriceHistoryModule } from "./modules/quick-price-history/quick-price-history.module";

@NgModule({
  imports: [
    SharedModule,
    TableModule,
    InputTextModule,
    PricingRoutingModule,
    AmortizationModule,
    AtrQmManagementModule,
    CarouselModule,
    FeesModule,
    ChartModule,
    PopoverModule.forRoot(),
    NgxPrintElementModule,
    OverlayPanelModule,
    NgSelectModule,
    TypeaheadModule.forRoot(),
    QuickApplyModule,
    RoleBasedInternalContactsModule,
    LenderPricePricerModule,
    LoanPassPricerModule,
    QuickPriceHistoryModule
  ],
  declarations: [
    PricingComponent,
    CompensationComponent,
    GovernmentDetailsComponent,
    PricingDetailsComponent,
    PricingDetailsComponent,
    PricingHistoryTableComponent,
    PropertyDetailsComponent,
    PricingParametersComponent,
    LoanPricingSearchDialogComponent,
    ProductSearchResultsComponent,
    ProductDetailDialogComponent,
    IneligibleProductDetailDialogComponent,
    SelectedPriceCardComponent,
    PricingScenarioChangesDialogComponent,
    ViewAdjustmentsDialogComponent,
    ViewNotesAdvisoriesDialogComponent,
    QmDetailsDialogComponent,
    PinnedScenariosComponent,
    CreateScenarioNameDialogComponent,
    PinnedScenarioCardComponent,
    PricingValidationDialogComponent,
    InitialFeesWorksheetDialog,
    SecondLientAmountCalculatorDialogComponent,
    PricingSearchV2Component,
    ScenarioNotesEditorComponent,
    ScenarioTaxesEditorComponent,
    ScenarioComparisonSelectDialogComponent,
    ScenarioComparisonWorksheetDialogComponent,
    ApplicationPricingScenariosComponent,
    RequestLockDialogComponent,
    ImportVendorProductsDialog,
    LockExtensionModalComponent,
    MiQuoteSearchDialogComponent,
    MiQuoteSearchComponent,
    MiQuoteSearchParametersComponent,
    MiQuoteSearchResultsComponent,
    QuoteAdditionalBorrowerEditorComponent,
    MiQuoteSelectDialogComponent,
    RepriceLockChangesDialogComponent,
    InitialFeesWorksheetEmail
  ],
  exports: [
    PricingComponent,
    CompensationComponent,
    GovernmentDetailsComponent,
    PricingDetailsComponent,
    PricingDetailsComponent,
    PricingHistoryTableComponent,
    PropertyDetailsComponent,
    PricingParametersComponent,
    LoanPricingSearchDialogComponent,
    ProductSearchResultsComponent,
    ProductDetailDialogComponent,
    PinnedScenariosComponent,
    CreateScenarioNameDialogComponent,
    PinnedScenarioCardComponent,
    PricingValidationDialogComponent,
    InitialFeesWorksheetDialog,
    PricingSearchV2Component,
    ApplicationPricingScenariosComponent,
    InitialFeesWorksheetEmail
  ],
  providers: [
    PricingService,
    PricingCustomFieldService,
    PricingConfigurationService
  ]
})
export class PricingModule {
  constructor() {
  }
}
