import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { UrlaMortgage } from '../models/urla-mortgage.model';
import { NgForm } from '@angular/forms';
import { Constants } from 'src/app/services/constants';
import { UrlaSectionComponent } from '../urla-section/urla-section.component';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { BorrowerCounselingEvent, MortgageBorrower } from 'src/app/models/mortgage.model';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';

@Component({
  selector: 'homeownership-education',
  templateUrl: './homeownership-education.component.html',
  styleUrls: ['./homeownership-education.component.scss']
})
export class HomeownershipEducationComponent extends UrlaSectionComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @ViewChild("homeownershipEducationForm")
  urlaSectionForm: NgForm;

  menuItem: string = Constants.menu.urlaMenuItems.homeownershipEducation;

  constructor(private readonly _urlaValidationService: UrlaValidationService,
    private readonly _injector: Injector) {
    super(_injector);
  }

  ngOnInit(): void {
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForHomeownershipEducation(this.mortgage);
    super.setMenuItemStatus(validityStatus);
  }

}
