import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactListColumnDefinition } from 'src/app/models/contact-list.model';
import { AutoCompleteItem } from 'src/app/models/expressions/auto-complete-item.model';
import { ExpressionConfig } from 'src/app/models/expressions/expression-config.model';
import { Operator } from 'src/app/models/expressions/operator.model';
import { ConfigurationResponse } from 'src/app/models/loan-pass/configuration-response.model';
import { LoanPassField } from 'src/app/models/loan-pass/loan-pass-field.model';
import { LoanPassMapping } from 'src/app/models/loan-pass/loan-pass-mapping.model';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';
import { BaseRequest } from 'src/app/modules/pricing/models/pricing/base-request.model';
import { BasicPropertyDetail } from 'src/app/modules/pricing/models/pricing/basic-property-detail.model';
import { CustomField } from 'src/app/modules/pricing/models/pricing/custom-fields.model';
import { PricingService } from 'src/app/modules/pricing/services/pricing.service';
import { Constants } from 'src/app/services/constants';
import { LoanPassFieldMappingsService, LoanPassMappingType } from 'src/app/services/loan-pass-field-mappings.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ExpressionEditorDialogComponent } from 'src/app/shared/components/expressions/expression-editor-dialog/expression-editor-dialog.component';
import { LoanPassEnumMappingDialogComponent } from '../loan-pass-enum-mapping-dialog/loan-pass-enum-mapping-dialog.component';
import * as _ from 'lodash';
import { EnumerationService } from 'src/app/services/enumeration-service';

@Component({
  selector: 'loan-pass-fields-mapper',
  templateUrl: 'loan-pass-fields-mapper.component.html',
  styleUrls: ['loan-pass-fields-mapper.component.scss']
})

export class LoanPassFieldsMapperComponent implements OnInit {

  @Input()
  operators: Operator[];

  @Input()
  isMappingFromLoanPassToLoda: boolean;

  @Input()
  credentialId: number;

  @Input()
  mappingType: LoanPassMappingType;

  @Input()
  loanPassFieldsConfig: ConfigurationResponse;

  @Input()
  fieldType: string;

  @Input()
  expressionFields: ContactListColumnDefinition[];

  @Input()
  mappings: LoanPassMapping[];

  @Input()
  vendor: PricingVendor.LoanPass | PricingVendor.LoanPassIframe = PricingVendor.LoanPass;

  destinationFieldPaths: BasicPropertyDetail[] = [];
  customFields: CustomField[] = [];

  isEnumByLoanPassField: Map<string, boolean> = new Map();
  mappingByLoanPassField: Map<string, LoanPassMapping> = new Map();
  isEnumByLodaField: Map<string, boolean> = new Map();
  mappingByLodaField: Map<string, LoanPassMapping> = new Map();

  activeDeletionIndex: number = -1;

  customMappings: LoanPassMapping[];

  tab: string = "fieldMappings";

  destinationFields: LoanPassField[] | BasicPropertyDetail[] = [];

  constructor(
    private readonly _loanPassFieldMappingService: LoanPassFieldMappingsService,
    private readonly _pricingService: PricingService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _enumerationService: EnumerationService,
    private readonly _modalService: NgbModal
  ) {
  }

  ngOnInit(): void {

    this.customMappings = this.mappings.filter(m => !!m.pricingCustomFieldId);

    if (this.isMappingFromLoanPassToLoda) {
      this.getFieldPaths();
    }
    else {
      this.destinationFields = this.loanPassFieldsConfig[this.fieldType] as LoanPassField[];
    }

    if ([LoanPassMappingType.toExecuteRequest, LoanPassMappingType.toProductSearchRequest].includes(this.mappingType)) {
      this.getCustomFields();

      if (this.mappingType == LoanPassMappingType.toExecuteRequest) {
        this.loanPassFieldsConfig[this.fieldType].forEach((field: LoanPassField) => {
          this.isEnumByLoanPassField.set(field.id, field.valueType.type === 'enum');
          this.mappingByLoanPassField.set(field.id, this.getLoanPassFieldMappingForLoanPassFieldId(field));
        })
      }
    }

  }

  onEditCustomExpressionClicked = (item: LoanPassMapping) => {
    const modalRef = this._modalService.open(ExpressionEditorDialogComponent, Constants.modalOptions.xlarge);
    const pricingFieldNames = this.expressionFields.map(p => new AutoCompleteItem('[' + p.columnDefinition.name + ']', '[' + p.columnDefinition.name + ']'));
    modalRef.componentInstance.config = new ExpressionConfig(this.operators, pricingFieldNames);
    modalRef.componentInstance.expression = item.expression;
    modalRef.result.then((result) => {
      if (result != null) {
        item.expression = result;
      }
    }, (res) => {
    });
  }

  getDestinationFieldTypeName = (path: string): string => {
    const dfp = this.destinationFieldPaths.find(p => p.path == path);
    return dfp ? dfp.typeName.replace("System.", "").replace("Int32", "Integer") : null;
  }

  onEditEnumClicked(field: LoanPassField | BasicPropertyDetail) {
    const fieldMapping = this.mappingType == LoanPassMappingType.toExecuteRequest ? this.mappingByLoanPassField.get((field as LoanPassField).id) : this.mappingByLodaField.get((field as BasicPropertyDetail).path);
    const lodaField = this.mappingType == LoanPassMappingType.toProductSearchRequest ? (field as BasicPropertyDetail) : null;
    const loanPassField = this.mappingType == LoanPassMappingType.toExecuteRequest ? (field as LoanPassField) : this.loanPassFieldsConfig[this.fieldType].find((f: LoanPassField) => f.id == fieldMapping.loanPassFieldId) as LoanPassField;

    this.populateLodaEnumItems(lodaField?.typeName, (enums) => {
      const modalRef = this._modalService.open(LoanPassEnumMappingDialogComponent, Constants.modalOptions.large);
      modalRef.componentInstance.isMappingFromLoanPassToLoda = this.isMappingFromLoanPassToLoda;

      modalRef.componentInstance.loanPassFields = this.loanPassFieldsConfig[this.fieldType];
      modalRef.componentInstance.loanPassEnumerations = this.loanPassFieldsConfig.enumerations;
      modalRef.componentInstance.loanPassField = loanPassField;

      modalRef.componentInstance.lodaField = lodaField;
      modalRef.componentInstance.lodaEnumPricingFields = this.mappingType == LoanPassMappingType.toExecuteRequest ? enums : this.destinationFieldPaths.filter(dfp => dfp.isEnum);
      modalRef.componentInstance.lodaFieldEnumItems = this.mappingType == LoanPassMappingType.toExecuteRequest ? [] : enums;
      modalRef.componentInstance.lodaFields = this.mappingType == LoanPassMappingType.toExecuteRequest ? [] : this.destinationFieldPaths;

      modalRef.componentInstance.operators = this.operators;
      modalRef.componentInstance.loanPassFieldMapping = fieldMapping;

      modalRef.result.then((loanPassFieldMapping) => {
        if (loanPassFieldMapping) {
          if (this.mappingType == LoanPassMappingType.toExecuteRequest) {
            this.mappingByLoanPassField.set((field as LoanPassField).id, loanPassFieldMapping);
          }
          else {
            this.mappingByLodaField.set((field as BasicPropertyDetail).path, loanPassFieldMapping);
          }
          this.onEnumMapppingsChanged(loanPassFieldMapping);
        }

      }, (res) => {
      });
    });


  }

  private populateLodaEnumItems = (typeName: string, cb: Function) => {
    if (this.mappingType == LoanPassMappingType.toExecuteRequest) {
      this._spinnerService.show();
      this._loanPassFieldMappingService.getLodaEnumsForLoanPassPricingRequest().subscribe({
        next: (enums) => {
          this._spinnerService.hide();
          cb(enums);
        },
        error: (err) => {
          this._spinnerService.hide();
          this._notificationService.showError(err?.message || "An error occurred while getting loda enumaration options.", "Error!");
        }
      });

    }
    else {
      this._spinnerService.show();
      this._enumerationService.getEnumerationsByType(typeName).subscribe({
        next: (enums) => {
          this._spinnerService.hide();
          cb(enums);
        },
        error: (err) => {
          this._spinnerService.hide();
          this._notificationService.showError(err?.message || "An error occurred while getting loda enumaration options.", "Error!");
        }
      });
    }
  }

  private onEnumMapppingsChanged = (fieldMapping: LoanPassMapping) => {
    const changedMapping = this.mappings.find(m => m.loanPassFieldMappingId == fieldMapping.loanPassFieldMappingId);
    if (changedMapping) {
      const index = this.mappings.indexOf(changedMapping);
      if (index >= 0) {
        this.mappings[index] = fieldMapping;
      }
    }
  }

  private getFieldPaths = () => {
    this._spinnerService.show();
    this._loanPassFieldMappingService.getDestinationFieldPaths(this.credentialId, this.mappingType).subscribe({
      next: (res) => {
        this._spinnerService.hide();
        this.destinationFieldPaths = res || [];
        this.destinationFields = _.cloneDeep(this.destinationFieldPaths) as BasicPropertyDetail[];

        if (this.mappingType == LoanPassMappingType.toProductSearchRequest) {
          this.destinationFieldPaths.forEach((field: BasicPropertyDetail) => {
            this.isEnumByLodaField.set(field.path, !!field.isEnum);
            this.mappingByLodaField.set(field.path, this.getLodaFieldMappingForLodaFieldId(field));
          })
        }

        this.adjustDisabledOptions();
      },
      error: (err) => {
        this._spinnerService.hide();
        this._notificationService.showError(err?.message || "An error occurred while getting destiantion field paths.", "Error!");
      }
    })
  }

  private getCustomFields = () => {
    let req = new BaseRequest();
    req.credentialId = this.credentialId;

    this._spinnerService.show();
    this._pricingService.getCustomFields(this.vendor, req).subscribe({
      next: (res) => {
        this._spinnerService.hide();
        this.customFields = res || [];
      },
      error: (err) => {
        this._spinnerService.hide();
        this._notificationService.showError(err?.message || "An error occurred while getting custom fields.", "Error!");
      }
    })
  }

  private getLoanPassFieldMappingForLoanPassFieldId = (loanPassField: LoanPassField): LoanPassMapping => {
    let mapping: LoanPassMapping = this.mappings.find(m => m.loanPassFieldId === loanPassField.id);
    if (!mapping) {
      mapping = new LoanPassMapping();
      mapping.loanPassFieldId = loanPassField.id;
      this.mappings.push(mapping);
    }
    mapping.loanPassFieldType = loanPassField.valueType.type;
    mapping.loanPassEnumTypeId = loanPassField.valueType.enumTypeId;
    return mapping;
  }

  private getLodaFieldMappingForLodaFieldId = (lodaField: BasicPropertyDetail): LoanPassMapping => {
    let mapping: LoanPassMapping = this.mappings.find(m => m.destinationFieldPath === lodaField.path);
    if (!mapping) {
      mapping = new LoanPassMapping();
      mapping.destinationFieldPath = lodaField.path;
      this.mappings.push(mapping);
    }
    return mapping;
  }

  addMapping = (isCustom?: boolean) => {
    let mapping = new LoanPassMapping();
    mapping.mappingType = this.mappingType;
    mapping.credentialId = this.credentialId;

    this.mappings.unshift(mapping);
    if (isCustom) {
      this.customMappings.unshift(mapping);
    }

    this.adjustDisabledOptions();
  }

  destinationFieldPathChanged = () => {
    this.adjustDisabledOptions();
  }

  pricingCustomFieldChanged = (mapping: LoanPassMapping) => {

    if (mapping) {
      if (!mapping.pricingCustomFieldId) {
        mapping.pricingCustomField = null;
      }
      else {
        mapping.pricingCustomField = this.customFields.find(cf => cf.pricingCustomFieldId == mapping.pricingCustomFieldId);
      }
    }
  }

  onDeleteConfirmClicked = (index: number) => {
    if (this.tab == "customMappings") {
      const customMap = this.customMappings[index];
      if (customMap.loanPassFieldMappingId) {
        const mapIdx = this.mappings.findIndex(m => m.loanPassFieldMappingId == customMap.loanPassFieldMappingId);
        this.mappings.splice(mapIdx, 1);
      }
      this.customMappings.splice(index, 1);
    }
    else {
      this.mappings.splice(index, 1);
    }

    this.adjustDisabledOptions();

    this.activeDeletionIndex = -1;
  }

  adjustDisabledOptions = () => {
    this.destinationFieldPaths.forEach(p => {
      if (!['ToExecuteRequest', 'ToProductSearchRequest'].includes(this.mappingType)) {
        p["disabled"] = this.mappings.map(m => m.destinationFieldPath).filter(dfp => !!dfp).includes(p.path);
      }
    });
  }

  onDeleteButtonClicked = (index: number) => {
    this.activeDeletionIndex = index;
  }

  onDeleteCancelled = () => {
    this.activeDeletionIndex = -1;
  }

}
