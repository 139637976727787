import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges, SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

/**
 * Recreates the view when the key changes.
 * @example
 * <ng-container *viewKey="Key Value">
 *   this content is re-rendered everytime viewKey changes.
 * </ng-container>
 */
@Directive({
  selector: '[viewKey]'
})
export class RecreateViewDirective implements OnChanges {
  @Input() viewKey: any;

  protected viewRef: EmbeddedViewRef<any>;

  constructor(
    private readonly _templateRef: TemplateRef<any>,
    private readonly _viewContainerRef: ViewContainerRef,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    const viewKeyChange = changes.viewKey;
    if (viewKeyChange) {
      if (viewKeyChange.firstChange) {
        this.createView();
      } else {
        const previousKey = viewKeyChange.previousValue;
        const currentKey = viewKeyChange.currentValue;
        if (previousKey !== currentKey) {
          this.destroyView();
          this.createView();
        }
      }
    }
  }

  private createView(): void {
    this.viewRef = this._viewContainerRef.createEmbeddedView(this._templateRef);
  }

  private destroyView(): void {
    if (!this.viewRef) {
      return;
    }

    this.viewRef.destroy();
    this.viewRef = null;
  }
}
