import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {Constants} from 'src/app/services/constants';
import {
  AusData,
  BuydownDetail,
  EscrowDetail,
  Extension,
  HousingExpense,
  MortgageTerm,
  SubjectProperty,
} from 'src/app/models';
import {EnumerationItem} from 'src/app/models/simple-enum-item.model';
import {EnumerationService} from 'src/app/services/enumeration-service';
import {UrlaMortgage} from '../models/urla-mortgage.model';
import {NgForm} from '@angular/forms';
import {UrlaSectionComponent} from '../urla-section/urla-section.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PropertyTaxBreakdownDialogComponent} from './property-tax-breakdown-dialog/property-tax-breakdown-dialog.component';
import {HoaDuesCondoFeesBreakdownDialogComponent} from './hoa-dues-condo-fees-breakdpwn-dialog/hoa-dues-condo-fees-breakdown-dialog.component';
import * as _ from 'lodash';
import {
  SubjectPropertyAndProposedHousingExpenses,
  SupplementalPropertyInsuranceBreakdownDialogComponent,
} from './supplemental-property-insurance-breakdown-dialog/supplemental-property-insurance-breakdown-dialog.component';
import {MortgageCalculationService} from '../services/mortgage-calculation.service';
import {MortgageInsuranceDialogComponent} from '../mortgage-insurance/mortgage-insurance-dialog/mortgage-insurance-dialog.component';
import {
  DrawerOptions,
  DrawerService,
  DrawerSize,
  DynamicComponentInfo,
} from 'src/app/shared/services/drawer.service';
import {UrlaArmFeaturesComponent} from '../components/urla-arm-features/urla-arm-features.component';
import {createIsRequiredFunction} from '../urla-utils';
import {BalloonDetailsComponent} from './balloon-details/balloon-details.component';
import {DrawerComponent} from 'src/app/shared/components/drawer/drawer.component';
import {BuydownDetailsComponent} from './buydown-details/buydown-details.component';
import {LoanFeaturesDetailsComponent} from './loan-features-details/loan-features-details.component';
import {FloodDetailsComponent} from './flood-details/flood-details.component';
import {LateChargeComponent} from './late-charge/late-charge.component';
import {
  HomeownersInsuranceDetails,
  HomeownersInsuranceDetailsComponent,
} from './homeowners-insurance-details/homeowners-insurance-details.component';
import {UrlaValidationService} from 'src/app/services/urla-validation.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observer} from 'rxjs';
import {MortgageCalculationDetails} from 'src/app/models/mortgage-calculation-details.model';
import {MortgageService} from 'src/app/services/mortgage.service';

@Component({
  selector: 'mortgage-loan-info',
  templateUrl: './mortgage-loan-info.component.html',
  styleUrls: ['./mortgage-loan-info.component.scss'],
})
export class MortgageLoanInfoComponent extends UrlaSectionComponent implements OnInit, OnDestroy {
  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    if (this._mortgage) {
      if (!this._mortgage.escrowDetail) {
        this._mortgage.escrowDetail = new EscrowDetail();
      }
      if (!this._mortgage.proposedHousingExpense) {
        this._mortgage.proposedHousingExpense = new HousingExpense();
      }
      if (!this._mortgage.mortgageTerm) {
        this._mortgage.mortgageTerm = new MortgageTerm();
      }
      if (!this._mortgage.proposedHousingExpense.supplementalPropertyInsurance) {
        this._mortgage.proposedHousingExpense.supplementalPropertyInsurance = 0;
      }
      if (!this._mortgage.extension.buydownDetail) {
        this.mortgage.extension.buydownDetail = new BuydownDetail();
      }
      this.isRefinance = this._mortgage.subjectProperty.purposeOfLoan != 'Purchase';
    }
  }

  @ViewChild('mortgageLoanInfoForm')
  urlaSectionForm: NgForm;

  menuItem: string = Constants.menu.urlaMenuItems.mortgageLoanInfo;

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  isReadOnly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  isTpoUser: boolean = false;

  @Output()
  productTypeSelectionChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  prepaymentPenaltyClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('dynamicDrawer')
  dynamicDrawer: DrawerComponent;

  @ViewChild('armFeaturesDrawer')
  armFeaturesDrawer: DrawerComponent;

  // @ViewChild('dynamicDrawerHomeownersInsurance')
  // dynamicDrawerHomeownersInsurance: DrawerComponent;

  isAmortizationTypeARM: string;

  protected propertyTaxesFieldNameSuffix: string = '';

  productTypes: EnumerationItem[] = [];

  lendingProductTypes: EnumerationItem[] = [];

  secondsRepaymentTypes: EnumerationItem[] = [];

  amortizationTypes: EnumerationItem[] = [];

  lienPositionTypes: EnumerationItem[] = [];

  loanAmortizationPeriodTypes: EnumerationItem[] = [];

  negativeAmortizationTypes: EnumerationItem[] = [];

  armIndexTypes: EnumerationItem[] = [];
  armIndexSourceTypes: EnumerationItem[] = [];

  isRefinance: boolean = false;
  isTBDChecked: boolean = false;
  dhHiddenHack: boolean = false;

  dynamicDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null,
  };

  private _dynamicEventSubscriptions: any[] = [];

  protected get isRealEstateTaxBrokenDown(): boolean {
    return this._isRealEstateTaxBrokenDown;
  }

  protected set isRealEstateTaxBrokenDown(value: boolean) {
    this._isRealEstateTaxBrokenDown = value;
    this.resetPropertyTaxesFieldNameSuffix();
  }

  get isHoaAndCondoFeesBrokenDown(): boolean {
    return this._isHoaAndCodoFeesBrokenDown;
  }

  get isSupplementalPropertyInsuranceBrokenDown(): boolean {
    return this._isSupplementalPropertyInsuranceBrokenDown;
  }

  protected isRequired: (fieldName: string) => boolean;

  private _mortgage: UrlaMortgage;

  private _isRealEstateTaxBrokenDown: boolean = false;
  private _isHoaAndCodoFeesBrokenDown: boolean = false;
  private _isSupplementalPropertyInsuranceBrokenDown: boolean = false;

  drawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: 'drawer-wrapper',
  };

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _enumsService: EnumerationService,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _drawerService: DrawerService,
    private readonly _urlaValidationService: UrlaValidationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _mortgageService: MortgageService,
    injector: Injector
  ) {
    super(injector);
  }

  isArm = (): boolean => {
    return this._mortgage.mortgageTerm.amortizationType == this.isAmortizationTypeARM;
  };

  balloonPayment = () => {
    if (this._mortgage.extension != null) {
      if (!this._mortgage.extension.isBalloonPaymentRequiredToPayOffLoan)
        this._mortgage.extension.balloonTermMonths = null;
    }
  };

  onOtherHousingExpenseChanged = () => {
    if (!this._mortgage.proposedHousingExpense.otherHousingExpense) {
      this._mortgage.proposedHousingExpense.otherHousingExpenseDescription = null;
    }
  };

  onMortgageInsuranceClicked = () => {
    const modalRef = this._modalService.open(
      MortgageInsuranceDialogComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.mortgage = this._mortgage;
    modalRef.result.then(
      (mortgage: UrlaMortgage) => {
        this._mortgage.mortgageInsuranceDetail = mortgage.mortgageInsuranceDetail;
        this._mortgage.governmentLoanDetail = mortgage.governmentLoanDetail;
        this._mortgage.mortgageTerm = mortgage.mortgageTerm;
        this._mortgage.proposedHousingExpense.mortgageInsurance =
          mortgage.mortgageInsuranceDetail.level1MonthlyAmount;
        this.calculateMonthlyMiscExpenses();
      },
      () => {}
    );
  };

  onHomeownersInsuranceClicked = () => {
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = HomeownersInsuranceDetailsComponent;
    dynamicComponentInfo.parameters.set('subjectProperty', this.mortgage.subjectProperty);
    dynamicComponentInfo.parameters.set(
      'coverageAmountDollar',
      this.mortgage.proposedHousingExpense.homeownersInsurance
    );
    this._drawerService
      .show('mortgageLoanDynamicDrawer', 10, 'Homeowners Insurace Detail', dynamicComponentInfo, {
        ...this.dynamicDrawerOptions,
      })
      .then(() => {
        const subscription1 = this.dynamicDrawer.componentInstance.changesApproved.subscribe(
          (homeownersInsuranceDetails: HomeownersInsuranceDetails) => {
            this.mortgage.subjectProperty = homeownersInsuranceDetails.subjectProperty;
            this.mortgage.proposedHousingExpense.homeownersInsurance =
              homeownersInsuranceDetails.coverageAmountDollar;
            this.onDynamicDrawerClosed();
          }
        );
        this._dynamicEventSubscriptions.push(subscription1);
        const subscription2 = this.dynamicDrawer.componentInstance.cancelled.subscribe(() => {
          this.onDynamicDrawerClosed();
        });
        this._dynamicEventSubscriptions.push(subscription2);
      });
  };

  openPrepaymentPenaltyDetails = () => {
    this.prepaymentPenaltyClicked.emit();
  };

  ngOnInit() {
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);
    this.dhHiddenHack = this.mortgage.companyId == 229 || this.mortgage.companyId == 276;

    this._enumsService.getMortgageEnumerations().subscribe(result => {
      this.productTypes = result[Constants.mortgageEnumerations.mortgageAppliedForType];
      this.lendingProductTypes = result[Constants.mortgageEnumerations.lendingProductType];
      this.secondsRepaymentTypes = result[Constants.mortgageEnumerations.secondsRepaymentType];
      this.amortizationTypes = result[Constants.mortgageEnumerations.amortizationType];
      this.negativeAmortizationTypes =
        result[Constants.mortgageEnumerations.negativeAmortizationType];
      this.lienPositionTypes = result[Constants.enumerations.lienPositionType];
      this.armIndexTypes = result[Constants.mortgageEnumerations.armIndexType];
      this.armIndexSourceTypes = result[Constants.mortgageEnumerations.armIndexSourceType];
      this.loanAmortizationPeriodTypes = result[Constants.enumerations.loanAmortizationPeriodType];

      this.isAmortizationTypeARM = this._enumsService.getEnumValue(
        Constants.enumerations.enumItemNames.isAmortizationTypeArm
      );

      this.isRealEstateTaxBrokenDown = this.checkIfRealEstateTaxIsBrokenDown();
      this._isHoaAndCodoFeesBrokenDown = this.checkIfHoaAndCondoFeesIsBrokenDown();
    });
    if (!this._mortgage.proposedHousingExpense.supplementalPropertyInsurance) {
      this._mortgage.proposedHousingExpense.supplementalPropertyInsurance = 0;
    }

    this.isTBDChecked = this.mortgage?.subjectProperty?.address1.toLocaleLowerCase() === 'tbd';
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForMortgageLoanInfo(this.mortgage);
    super.setMenuItemStatus(validityStatus);
  };

  onPropertyTaxesBreakDownClicked = () => {
    const modalRef = this._modalService.open(
      PropertyTaxBreakdownDialogComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.mortgage = this._mortgage;
    modalRef.componentInstance.isEscrowWaived =
      this._mortgage.escrowDetail.escrowsAreWaivedByLender;
    modalRef.result.then(
      (subjectPropertyAndProposedHousingExpenses: SubjectPropertyAndProposedHousingExpenses) => {
        if (subjectPropertyAndProposedHousingExpenses) {
          this._mortgage.proposedHousingExpense = _.cloneDeep(
            subjectPropertyAndProposedHousingExpenses.proposedHousingExpense
          );
          this._mortgage.subjectProperty = _.cloneDeep(
            subjectPropertyAndProposedHousingExpenses.subjectProperty
          );
          this.isRealEstateTaxBrokenDown = this.checkIfRealEstateTaxIsBrokenDown();
          this.calculateSubTotal();
          this.calculateMonthlyMiscExpenses();
        }
      },
      () => {}
    );
  };

  onSupplementalPropertyInsuranceBreakDownClicked = () => {
    const modalRef = this._modalService.open(
      SupplementalPropertyInsuranceBreakdownDialogComponent,
      Constants.modalOptions.xlarge
    );
    modalRef.componentInstance.mortgage = this._mortgage;
    modalRef.result.then(
      (subjectPropertyAndProposedHousingExpenses: SubjectPropertyAndProposedHousingExpenses) => {
        if (subjectPropertyAndProposedHousingExpenses) {
          this._mortgage.proposedHousingExpense = _.cloneDeep(
            subjectPropertyAndProposedHousingExpenses.proposedHousingExpense
          );
          this._mortgage.subjectProperty = _.cloneDeep(
            subjectPropertyAndProposedHousingExpenses.subjectProperty
          );
          this._isSupplementalPropertyInsuranceBrokenDown =
            this.checkIfSupplementalPropertyInsuranceIsBrokenDown();
          this.calculateSubTotal();
          this.calculateMonthlyMiscExpenses();
        }
      },
      () => {}
    );
  };

  onHoaAndCondoFeesBreakDownClicked = () => {
    const modalRef = this._modalService.open(
      HoaDuesCondoFeesBreakdownDialogComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.proposedHousingExpenses = this._mortgage.proposedHousingExpense;
    modalRef.result.then(
      expenses => {
        if (expenses) {
          this._mortgage.proposedHousingExpense = _.cloneDeep(expenses);
          this._isHoaAndCodoFeesBrokenDown = this.checkIfHoaAndCondoFeesIsBrokenDown();
          this.calculateSubTotal();
          this.calculateMonthlyMiscExpenses();
        }
      },
      () => {}
    );
  };

  onRealEstateTaxChanged = () => {
    if (!this._isRealEstateTaxBrokenDown) {
      this._mortgage.proposedHousingExpense.countyPropertyTax =
        this._mortgage.proposedHousingExpense.realEstateTax;
    }
    this.resetPropertyTaxesFieldNameSuffix();
    this.calculateSubTotal();
  };

  onHoaAndCodoFeesChanged = () => {
    if (!this._isHoaAndCodoFeesBrokenDown) {
      this._mortgage.proposedHousingExpense.homeownersAssociationDues =
        this._mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees;
    }
    this.calculateSubTotal();
  };

  onSupplementalPropertyInsuranceChanged = () => {
    if (!this._isSupplementalPropertyInsuranceBrokenDown) {
      this._mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance =
        this._mortgage.proposedHousingExpense.supplementalPropertyInsurance;
    }
    this.calculateSubTotal();
  };

  calculateSubTotal = () => {
    setTimeout(() => {
      this._mortgage.calculatedStats.proposedMonthlyPaymentTotal =
        this._calculationService.calculateHousingExpenseTotal(
          this._mortgage.proposedHousingExpense
        );

      this._mortgage.calculatedStats.currentMonthlyPaymentTotal =
        this._calculationService.calculateHousingExpenseTotal(this._mortgage.currentHousingExpense);
    }, 100);
  };

  onInterestRateChanged = () => {
    this.recalculateFirstMortgagePAndI();
  };

  onMortgageTermChanged = () => {
    this.recalculateFirstMortgagePAndI();
  };

  onInterestOnlyChanged = () => {
    this.recalculateFirstMortgagePAndI();
  };

  productTypeChanged = () => {
    if (!['FHA', 'VA', 'USDA'].includes(this._mortgage.mortgageTerm.mortgageAppliedFor)) {
      this._mortgage.governmentLoanDetail.sectionOfActType = null;
    }
    this.productTypeSelectionChanged.emit(this._mortgage.mortgageTerm.mortgageAppliedFor);
  };

  calculateMonthlyMiscExpenses = () => {
    if (this._mortgage.realEstateOwned && this._mortgage.realEstateOwned.length > 0) {
      let index = this.mortgage.realEstateOwned.findIndex(r => r.isSubjectProperty);
      if (index > -1) {
        let subtotal = 0;
        subtotal += this._mortgage.proposedHousingExpense?.homeownersInsurance || 0;
        subtotal += this._mortgage.proposedHousingExpense?.supplementalPropertyInsurance || 0;
        subtotal += this._mortgage.proposedHousingExpense?.realEstateTax || 0;
        subtotal += this._mortgage.proposedHousingExpense?.mortgageInsurance || 0;
        subtotal +=
          this._mortgage.proposedHousingExpense?.homeownersAssociationDuesAndCondominiumFees || 0;
        this._mortgage.realEstateOwned[index].monthlyMiscExpenses = subtotal;
      }
    }
  };

  openArmFeatures = () => {
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = UrlaArmFeaturesComponent;
    dynamicComponentInfo.parameters.set('mortgage', this.mortgage);
    dynamicComponentInfo.parameters.set('urlaFieldsConfig', this.urlaFieldsConfig);
    dynamicComponentInfo.parameters.set('armIndexTypes', this.armIndexTypes);
    dynamicComponentInfo.parameters.set('armIndexSourceTypes', this.armIndexSourceTypes);
    dynamicComponentInfo.parameters.set('inEditMode', this.inEditMode);
    dynamicComponentInfo.parameters.set('isReadOnly', this.isReadOnly);

    this._drawerService
      .show('armFeaturesDrawer', 200, 'Arm Features', dynamicComponentInfo, {
        size: DrawerSize.Large,
        containerWrapperId: !this.isTpoUser ? 'drawer-wrapper' : null,
      })
      .then(() => {
        const subscription1 = this.armFeaturesDrawer.componentInstance.changesApproved.subscribe(
          (ausData: AusData) => {
            this.mortgage.ausData = ausData;
            this.validate();
            this._drawerService.hide('armFeaturesDrawer');
          }
        );
        this._dynamicEventSubscriptions.push(subscription1);

        const subscription2 = this.armFeaturesDrawer.componentInstance.cancel.subscribe(() => {
          this._drawerService.hide('armFeaturesDrawer');
        });

        this._dynamicEventSubscriptions.push(subscription2);
      });
  };

  openBalloonDetails = () => {
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = BalloonDetailsComponent;
    dynamicComponentInfo.parameters.set('extension', this.mortgage.extension);
    dynamicComponentInfo.parameters.set('urlaFieldsConfig', this.urlaFieldsConfig);
    dynamicComponentInfo.parameters.set('readonly', this.isReadOnly);

    this._drawerService
      .show('mortgageLoanDynamicDrawer', 10, 'Balloon Details', dynamicComponentInfo, {
        ...this.dynamicDrawerOptions,
      })
      .then(() => {
        const subscription1 = this.dynamicDrawer.componentInstance.changesApproved.subscribe(
          (newData: Extension) => {
            this.onDynamicDrawerClosed();
            this.mortgage.extension = newData;
          }
        );
        this._dynamicEventSubscriptions.push(subscription1);
        const subscription2 = this.dynamicDrawer.componentInstance.cancelled.subscribe(() => {
          this.onDynamicDrawerClosed();
        });
        this._dynamicEventSubscriptions.push(subscription2);
      });
  };

  openBuydownDetails = () => {
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = BuydownDetailsComponent;
    dynamicComponentInfo.parameters.set(
      'buydownDetail',
      this.mortgage.extension.buydownDetail || new BuydownDetail()
    );
    dynamicComponentInfo.parameters.set('urlaFieldsConfig', this.urlaFieldsConfig);
    dynamicComponentInfo.parameters.set('readonly', this.isReadOnly);

    this._drawerService
      .show('mortgageLoanDynamicDrawer', 10, 'Buydown Details', dynamicComponentInfo, {
        ...this.dynamicDrawerOptions,
      })
      .then(() => {
        const subscription1 = this.dynamicDrawer.componentInstance.changesApproved.subscribe(
          (newData: BuydownDetail) => {
            this.onDynamicDrawerClosed();
            if (!this.mortgage.extension.buydownDetail) {
              this.mortgage.extension.buydownDetail = new BuydownDetail();
            }
            this.mortgage.extension.buydownDetail = newData;
          }
        );
        this._dynamicEventSubscriptions.push(subscription1);
        const subscription2 = this.dynamicDrawer.componentInstance.cancelled.subscribe(() => {
          this.onDynamicDrawerClosed();
        });
        this._dynamicEventSubscriptions.push(subscription2);
      });
  };

  openLoanFeaturesDetails = () => {
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = LoanFeaturesDetailsComponent;
    dynamicComponentInfo.parameters.set('subjectProperty', this.mortgage.subjectProperty);
    dynamicComponentInfo.parameters.set('mortgageTerm', this.mortgage.mortgageTerm);
    dynamicComponentInfo.parameters.set('readonly', this.isReadOnly);

    this._drawerService
      .show('mortgageLoanDynamicDrawer', 10, 'Loan Features Details', dynamicComponentInfo, {
        ...this.dynamicDrawerOptions,
      })
      .then(() => {
        const subscription1 = this.dynamicDrawer.componentInstance.changesApproved.subscribe(
          (newData: {subjectProperty: SubjectProperty; mortgageTerm: MortgageTerm}) => {
            this.mortgage.subjectProperty = newData.subjectProperty;
            this.mortgage.mortgageTerm = newData.mortgageTerm;
            this.onDynamicDrawerClosed();
          }
        );
        this._dynamicEventSubscriptions.push(subscription1);
        const subscription2 = this.dynamicDrawer.componentInstance.cancelled.subscribe(() => {
          this.onDynamicDrawerClosed();
        });
        this._dynamicEventSubscriptions.push(subscription2);
      });
  };

  openFloodDetails = () => {
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = FloodDetailsComponent;
    dynamicComponentInfo.parameters.set('subjectProperty', this.mortgage.subjectProperty);
    dynamicComponentInfo.parameters.set('readonly', this.isReadOnly);

    this._drawerService
      .show('mortgageLoanDynamicDrawer', 10, 'Flood Details', dynamicComponentInfo, {
        ...this.dynamicDrawerOptions,
      })
      .then(() => {
        const subscription1 = this.dynamicDrawer.componentInstance.changesApproved.subscribe(
          (newData: SubjectProperty) => {
            this.mortgage.subjectProperty = newData;
            this.onDynamicDrawerClosed();
          }
        );
        this._dynamicEventSubscriptions.push(subscription1);
        const subscription2 = this.dynamicDrawer.componentInstance.cancelled.subscribe(() => {
          this.onDynamicDrawerClosed();
        });
        this._dynamicEventSubscriptions.push(subscription2);
      });
  };

  onEditLateChargeDetailsClicked = () => {
    this._drawerService.hide('mortgageLoanDynamicDrawer');
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = LateChargeComponent;
    dynamicComponentInfo.parameters.set('mortgageTerm', this.mortgage.mortgageTerm);
    dynamicComponentInfo.parameters.set('readonly', this.isReadOnly);
    this._drawerService
      .show('mortgageLoanDynamicDrawer', 100, 'Late Charge', dynamicComponentInfo)
      .then(() => {
        const cancelledEventSubscription = this.dynamicDrawer.componentInstance.cancelled.subscribe(
          () => {
            this._drawerService.hide('mortgageLoanDynamicDrawer');
          }
        );
        this._dynamicEventSubscriptions.push(cancelledEventSubscription);
        const changesApprovedEventSubscription =
          this.dynamicDrawer.componentInstance.changesApproved.subscribe(
            (mortgageTerm: MortgageTerm) => {
              this._drawerService.hide('mortgageLoanDynamicDrawer');
              this.mortgage.mortgageTerm = mortgageTerm;
            }
          );
        this._dynamicEventSubscriptions.push(changesApprovedEventSubscription);
      });
  };

  onDynamicDrawerClosed = () => {
    this._drawerService.hide('mortgageLoanDynamicDrawer');
  };

  private checkIfSupplementalPropertyInsuranceIsBrokenDown(): boolean {
    const isBrokenDown: boolean =
      (this._mortgage.proposedHousingExpense.earthquakeInsurance &&
        Number(this._mortgage.proposedHousingExpense.earthquakeInsurance) > 0) ||
      (this._mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance != null &&
        Number(this._mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance) > 0 &&
        this._mortgage.proposedHousingExpense.supplementalPropertyInsurance !=
          this._mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance) ||
      (this._mortgage.proposedHousingExpense.floodInsurance != null &&
        Number(this._mortgage.proposedHousingExpense.floodInsurance) > 0) ||
      (this._mortgage.proposedHousingExpense.volcanoInsurance != null &&
        Number(this._mortgage.proposedHousingExpense.volcanoInsurance) > 0) ||
      (this._mortgage.proposedHousingExpense.hailInsurance != null &&
        Number(this._mortgage.proposedHousingExpense.hailInsurance) > 0) ||
      (this._mortgage.proposedHousingExpense.windAndStormInsurance != null &&
        Number(this._mortgage.proposedHousingExpense.windAndStormInsurance) > 0);
    return isBrokenDown;
  }

  private checkIfRealEstateTaxIsBrokenDown(): boolean {
    const isBrokenDown: boolean =
      (this._mortgage.proposedHousingExpense.statePropertyTax &&
        Number(this._mortgage.proposedHousingExpense.statePropertyTax) > 0) ||
      (this._mortgage.proposedHousingExpense.countyPropertyTax != null &&
        Number(this._mortgage.proposedHousingExpense.countyPropertyTax) > 0 &&
        this._mortgage.proposedHousingExpense.countyPropertyTax !=
          this._mortgage.proposedHousingExpense.realEstateTax) ||
      (this._mortgage.proposedHousingExpense.districtPropertyTax != null &&
        Number(this._mortgage.proposedHousingExpense.districtPropertyTax) > 0) ||
      (this._mortgage.proposedHousingExpense.boroughPropertyTax != null &&
        Number(this._mortgage.proposedHousingExpense.boroughPropertyTax) > 0) ||
      (this._mortgage.proposedHousingExpense.cityPropertyTax != null &&
        Number(this._mortgage.proposedHousingExpense.cityPropertyTax) > 0) ||
      (this._mortgage.proposedHousingExpense.townPropertyTax != null &&
        Number(this._mortgage.proposedHousingExpense.townPropertyTax) > 0) ||
      (this._mortgage.proposedHousingExpense.villagePropertyTax != null &&
        Number(this._mortgage.proposedHousingExpense.villagePropertyTax) > 0) ||
      (this._mortgage.proposedHousingExpense.schoolPropertyTax != null &&
        Number(this._mortgage.proposedHousingExpense.schoolPropertyTax) > 0);
    return isBrokenDown;
  }

  private checkIfHoaAndCondoFeesIsBrokenDown(): boolean {
    const isBrokenDown: boolean =
      (this._mortgage.proposedHousingExpense.condominiumAssociationDues &&
        Number(this._mortgage.proposedHousingExpense.condominiumAssociationDues) > 0) ||
      (this._mortgage.proposedHousingExpense.homeownersAssociationDues != null &&
        Number(this._mortgage.proposedHousingExpense.homeownersAssociationDues) > 0 &&
        this._mortgage.proposedHousingExpense.homeownersAssociationDues !=
          this._mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees) ||
      (this._mortgage.proposedHousingExpense.cooperativeAssociationDues != null &&
        Number(this.mortgage.proposedHousingExpense.cooperativeAssociationDues) > 0);
    return isBrokenDown;
  }

  private resetPropertyTaxesFieldNameSuffix() {
    this.propertyTaxesFieldNameSuffix =
      !this._isRealEstateTaxBrokenDown && this.mortgage.proposedHousingExpense.countyPropertyTax > 0
        ? ' (County)'
        : '';
  }

  private recalculateFirstMortgagePAndI = () => {
    this._spinner.show();
    const observer: Observer<MortgageCalculationDetails> = {
      next: calculationDetails => {
        this.mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest =
          calculationDetails.proposedExpenses.firstMortgagePrincipalAndInterest;
        this.mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest =
          calculationDetails.proposedExpenses.otherMortgageLoanPrincipalAndInterest;
        this.calculateSubTotal();
      },
      error: () => {},
      complete: () => {},
    };
    this._mortgageService
      .redoMortgageCalculationDetails(this.mortgage)
      .subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  };
}
