import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/core/route-guards/pending-changes.guard';
import { Utils } from 'src/app/core/services/utils';
import {
  Address,
  ApplicationContext,
  ChannelEnum,
  Configuration,
  LoanApplication,
  PurchaseCredit,
  PurchaseCreditSourceType,
} from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { PayPeriod } from 'src/app/modules/leads/models/lead-employment.model';
import { LeadUtils } from 'src/app/modules/leads/services/utils';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageInsuranceDialogComponent } from 'src/app/modules/urla/mortgage-insurance/mortgage-insurance-dialog/mortgage-insurance-dialog.component';
import { HoaDuesCondoFeesBreakdownDialogComponent } from 'src/app/modules/urla/mortgage-loan-info/hoa-dues-condo-fees-breakdpwn-dialog/hoa-dues-condo-fees-breakdown-dialog.component';
import { PropertyTaxBreakdownDialogComponent } from 'src/app/modules/urla/mortgage-loan-info/property-tax-breakdown-dialog/property-tax-breakdown-dialog.component';
import {
  SubjectPropertyAndProposedHousingExpenses,
  SupplementalPropertyInsuranceBreakdownDialogComponent,
} from 'src/app/modules/urla/mortgage-loan-info/supplemental-property-insurance-breakdown-dialog/supplemental-property-insurance-breakdown-dialog.component';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import {
  DrawerOptions,
  DrawerService,
  DrawerSize,
} from 'src/app/shared/services/drawer.service';
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';
import { NgForm } from '@angular/forms';
import { LoanDetailsInfo } from 'src/app/models/loan/loan-details-info.model';
import { AppDetailsService } from '../../../services/app-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { MortgageService } from 'src/app/services/mortgage.service';
import { DiffChecker } from 'src/utils/diff-checker';
import { showNotSavedDialog } from '../quick-apply-utils';
import { MenuService } from 'src/app/services/menu.service';
import { MortgageCalculationDetails } from '../../../../../models/mortgage-calculation-details.model';
import { MortgageModelFieldsConfig } from 'src/app/modules/urla/models/urla-fields-config.model';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'qa-loan-info',
  templateUrl: './qa-loan-info.component.html',
  styleUrls: ['qa-loan-info.component.scss'],
})
export class QuickApplyLoanInfoComponent
  extends ApplicationContextBoundComponent
  implements OnInit, AfterViewInit, OnDestroy, ComponentCanDeactivate
{
  @ViewChild('loanInfo') loanInfo: ElementRef;
  @ViewChild('propertyInfo') propertyInfo: ElementRef;
  @ViewChild('titleInfo') titleInfo: ElementRef;
  @ViewChild('qaLoanInfoForm') qaLoanInfoForm: NgForm;
  @ViewChild('qaBuydownPopover') qaBuydownPopover;

  tab: string;
  secondPageActionBarVisible: boolean;

  protected loanPurposes: EnumerationItem[] = [];
  protected refinancePurposes: EnumerationItem[] = [];
  protected governmentRefinanceTypes: EnumerationItem[] = [];
  protected mortgageTypes: EnumerationItem[] = [];
  protected lienPositionTypes: EnumerationItem[] = [];
  protected amortizationTypes: EnumerationItem[] = [];
  protected estateTypes: EnumerationItem[] = [];
  protected titleTypes: EnumerationItem[] = [];
  protected nativeAmericanLandsTypes: EnumerationItem[] = [];
  protected states: EnumerationItem[] = [];
  protected propertyTypes: EnumerationItem[] = [];
  protected propertyOccupancyTypes: EnumerationItem[] = [];
  protected attachmentTypes: EnumerationItem[] = [];
  protected propertyConstructionMethods: EnumerationItem[] = [];
  protected projectDesignTypes: EnumerationItem[] = [];
  protected improvementStatusTypes: EnumerationItem[] = [];
  protected incomePayPeriods: EnumerationItem[] = [];
  protected purchaseCreditTypes: EnumerationItem[] = [];
  protected downPaymentSourceTypes: EnumerationItem[] = [];
  protected lendingProductTypes: EnumerationItem[] = [];
  protected buydownTypes: EnumerationItem[] = [];

  protected get mortgage(): UrlaMortgage {
    return this.application.mortgageLoan;
  }
  protected application: LoanApplication;

  protected ltv: number | null = 0;
  protected cltv: number | null = 0;
  protected hcltv: number | null = 0;
  protected downPayment: number | null = 0;

  protected loanPurpose: string;

  protected purchaseCreditsTotal: number = 0;
  protected loanFico: boolean;
  protected manual: boolean;
  protected isTbd: boolean = false;

  protected showAdvancedFeatures: boolean = false;
  protected isPurchase: boolean;
  protected isRefinance: boolean;
  protected isConstructionOnlyLoan: boolean;

  protected otherFinancingFactor: PayPeriod = PayPeriod.Monthly;
  protected hoiFactor: PayPeriod = PayPeriod.Monthly;
  protected supplementalFactor: PayPeriod = PayPeriod.Monthly;
  protected propertyTaxesFactor: PayPeriod = PayPeriod.Monthly;
  protected mortgageInsuranceFactor: PayPeriod = PayPeriod.Monthly;
  protected associationDuesFactor: PayPeriod = PayPeriod.Monthly;

  protected otherFinancingAmount: number = 0;
  protected hoiAmount: number = 0;
  protected supplementalAmount: number = 0;
  protected propertyTaxesAmount: number = 0;
  protected mortgageInsuranceAmount: number = 0;
  protected associationDuesAmount: number = 0;

  protected creditReportingHtml: string = '';

  protected quickApplyFieldsConfig: MortgageModelFieldsConfig;

  protected isLoanReadOnly: boolean = false;

  protected orderCreditDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null,
  };

  protected ficoOnFile: number | undefined;
  protected locatedInProject: boolean = false;

  protected isSubjectPropertyAddressHidden: boolean = false;

  protected isPrequalificationCheckReadonly: any = {};

  protected propertyTaxesFieldNameSuffix: string = '';

  private _amortizationTypeFixedEnumValue: string;
  private _amortizationTypeARMEnumValue: string;
  private _loanPurposePurchaseEnumValue: string;
  private _loanPurposeRefiEnumValue: string;
  private _loanPurposeConstructionOnlyEnumValue: string;

  private _shownPopover: PopoverDirective | undefined;

  protected get isRealEstateTaxBrokenDown(): boolean {
    return this._isRealEstateTaxBrokenDown;
  }

  protected set isRealEstateTaxBrokenDown(value: boolean) {
    this._isRealEstateTaxBrokenDown = value;
    this.resetPropertyTaxesFieldNameSuffix();
  }

  get isHoaAndCondoFeesBrokenDown(): boolean {
    return this._isHoaAndCodoFeesBrokenDown;
  }

  get isSupplementalPropertyInsuranceBrokenDown(): boolean {
    return this._isSupplementalPropertyInsuranceBrokenDown;
  }

  protected isAmortizationTypeFixed: boolean = false;
  protected isAmortizationTypeARM: boolean = false;
  protected hasApplicationReceivedKeyDate: boolean = false;
  protected lenderCreditType: string;

  protected ChannelEnum = ChannelEnum;
  protected PurchaseCreditSourceType = PurchaseCreditSourceType;

  private _isRealEstateTaxBrokenDown: boolean = false;
  private _isHoaAndCodoFeesBrokenDown: boolean = false;
  private _isSupplementalPropertyInsuranceBrokenDown: boolean = false;

  private _loanPurposeValueChanged: boolean;
  private _oldLoanPurposeValue: number;

  private _loanInfoFormValueChangesSubscription: Subscription;
  private _appContextChangesSubscription: Subscription;

  buydown = null;

  readonly options: any = {
    types: ['geocode'],
    componentRestrictions: { country: 'us' },
  };

  constructor(
    injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _utilityService: UtilityService,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _modalService: NgbModal,
    private readonly _drawerService: DrawerService,
    private readonly _configurationService: ConfigurationService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _mortgageService: MortgageService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _menuService: MenuService
  ) {
    super(injector);
    this.quickApplyFieldsConfig = this._mortgageService.quickApplyFieldsConfig;
    this._appContextChangesSubscription =
      this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          this.initialize(context);
        }
      });

    this._appDetailsService.getLoanHiddenFields().subscribe((response) => {
      const hiddenFields = this.getHiddenFields(response);
      this.isSubjectPropertyAddressHidden =
        hiddenFields.findIndex((f) => f === 'Subject Property') > -1;
    });
  }

  canDeactivate = (): boolean | Observable<boolean> => {
    // don't check when session expires
    if (!this.applicationContext || !this.application) return true;

    return !this.isDirty();
  };

  confirm(): boolean | Observable<boolean> {
    return showNotSavedDialog(() => this.saveLoanInfo());
  }

  private getHiddenFields = (
    loanHiddenFields?: Configuration
  ): Array<string> => {
    if (!loanHiddenFields?.valueStr) return [];
    return loanHiddenFields.valueStr?.split(',').map((el) => el.trim());
  };

  private isDirty(): boolean {
    const diffChecker = new DiffChecker(
      this.applicationContext.application,
      this.application,
      'application',
      { floatPrecision: 2 }
    );

    return diffChecker.calculateDiff(true) != null;
  }

  ngOnInit() {
    super.ngOnInit();

    this.secondPageActionBarVisible =
      this.applicationContext.isCallControlPanelOpen;
    this._enumsService.getMortgageEnumerations().subscribe((enums) => {
      this._loanPurposePurchaseEnumValue = this._enumsService.getEnumValue(
        Constants.enumerationValueNames.LoanPurposeType.Purchase
      );
      this._loanPurposeRefiEnumValue = this._enumsService.getEnumValue(
        Constants.enumerationValueNames.LoanPurposeType.Refinance
      );
      this._loanPurposeConstructionOnlyEnumValue =
        this._enumsService.getEnumValue(
          Constants.enumerationValueNames.LoanPurposeType.ConstructionOnly
        );
      // this._propertyWillBePrimaryEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.PrimaryResidence);
      // this._propertyWillBeSecondaryEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.SecondaryResidence);
      // this._propertyWillBeInvestmentEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.Investment);
      this.loanPurposes = enums[Constants.enumerations.loanPurposes];
      this.refinancePurposes = enums[Constants.enumerations.refinancePurposes];
      this.governmentRefinanceTypes =
        enums[Constants.mortgageEnumerations.governmentRefinanceType];
      this.mortgageTypes =
        enums[Constants.mortgageEnumerations.mortgageAppliedForType];
      this.propertyOccupancyTypes = enums[Constants.enumerations.propertyTypes];
      this.propertyTypes = enums[Constants.mortgageEnumerations.propertyType];
      this.attachmentTypes =
        enums[Constants.mortgageEnumerations.attachmentType];
      this.propertyConstructionMethods =
        enums[Constants.enumerations.propertyConstructionMethod];
      this.lienPositionTypes = enums[Constants.enumerations.lienPositionType];
      this.projectDesignTypes =
        enums[Constants.mortgageEnumerations.projectDesignType];
      this.improvementStatusTypes =
        enums[Constants.mortgageEnumerations.improvementStatusType];
      // this.giftGrantSources = enums[Constants.enumerations.giftGrantSource];
      // this.assetTypes = enums[Constants.enumerations.assetTypes];
      this.titleTypes = enums[Constants.mortgageEnumerations.titleType];
      this.nativeAmericanLandsTypes =
        enums[Constants.mortgageEnumerations.nativeAmericanLandsType];
      this.estateTypes = enums[Constants.mortgageEnumerations.estateType];
      this.lendingProductTypes =
        enums[Constants.mortgageEnumerations.lendingProductType];
      this.purchaseCreditTypes =
        enums[Constants.enumerations.purchaseCreditTypes];
      this.downPaymentSourceTypes =
        enums[Constants.enumerations.downPaymentSourceTypes];
      this.amortizationTypes =
        enums[Constants.mortgageEnumerations.amortizationType];
    });
    this._amortizationTypeFixedEnumValue = this._enumsService.getEnumValue(
      Constants.enumerationValueNames.AmortizationType.Fixed
    );
    this._amortizationTypeARMEnumValue = this._enumsService.getEnumValue(
      Constants.enumerationValueNames.AmortizationType.ARM
    );
    this.states = this._enumsService.states;
    this.buydownTypes = this._enumsService.buydownTypes;
    this.incomePayPeriods = this._enumsService.getIncomePayPeriods();
    this.lenderCreditType = this._enumsService.getEnumValue(
      Constants.enumerationValueNames.PurchaseCreditType.LenderCredit
    );

    this.purchaseCreditTypes = this.purchaseCreditTypes.filter(
      (purchaseCreditType) =>
        purchaseCreditType.value != this.lenderCreditType ||
        this.applicationContext.application.channel != ChannelEnum.Wholesale
    );

    this.downPaymentSourceTypes = this.downPaymentSourceTypes.filter(
      (downPaymentSourceType) =>
        downPaymentSourceType.value != PurchaseCreditSourceType.Lender ||
        this.applicationContext.application.channel != ChannelEnum.Wholesale
    );

    
    this._configurationService.getCompanyConfiguration('CreditReportingScript').subscribe(creditReportingScript => {
      this.creditReportingHtml = creditReportingScript.valueStr ?? "";
    })
    if (this.applicationContext.application) {
      this.initialize(this.applicationContext);
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.qaLoanInfoForm) {
      this._loanInfoFormValueChangesSubscription =
        this.qaLoanInfoForm.valueChanges.subscribe((status) => {
          setTimeout(() => {
            const menuItemStatus = this._menuService.getStatusForLoanInfo(
              this.mortgage
            );
            this._menuService.setStatus('qaLoanInfo', menuItemStatus);
          });
        });
    }
  }

  ngOnDestroy(): void {
    this._appContextChangesSubscription?.unsubscribe();
    this._loanInfoFormValueChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  @HostListener('document:click', ['$event'])
  onClickBody = (event: Event) => {
    const { _shownPopover } = this;
    if (_shownPopover == null) {
      return;
    }

    const path = event.composedPath() as HTMLElement[];

    const pathContainsClass = (className: string) =>
      path.some((e) => e.classList?.contains(className));

    const preventHideCheckClasses = [
      // Clicked on shown popover.
      'popover-body',
      // Clicked on the popover button of the currently shown popover to open it in the first place.
      'qa-btn',
      // Clicked on a modal that is opened from the shown popover.
      'modal',

      'swal2-container',
    ];
    if (preventHideCheckClasses.some(pathContainsClass)) {
      return;
    }

    _shownPopover.hide();
  };

  onShownPopover(ref: PopoverDirective) {
    const setRef = () => (this._shownPopover = ref);

    if (this._shownPopover) {
      this._shownPopover.hide();
      setTimeout(setRef);
    } else {
      setRef();
    }
  }

  onHiddenPopover() {
    this._shownPopover = undefined;
  }

  onPopoverCloseRequested = () => {
    this._shownPopover?.hide();
  };

  onOrderCreditDrawerToggled = () => {
    this._drawerService.show('orderCreditDrawerLoanInfo', 10);
  };

  onClosePopover = () => {
    this.qaBuydownPopover._popover.hide();
  };

  onYesNoOptionChanged = (borrower) => {
    // FIXME: If the today date is changed after component init, the dirty check
    //  will be broken.
    const today = new Date();
    borrower.dateAuthorizedCreditCheck = borrower.authorizedCreditCheck
      ? formatDate(today, 'MM/dd/yyyy', 'en-US')
      : undefined;
  };

  protected onTBDCheckChanged = () => {
    if (this.isTbd) {
      this.mortgage.subjectProperty.address1 = 'TBD';
      this.mortgage.subjectProperty.address2 = '';
    }
  };

  protected onLoanPurposeChanged = async () => {
    const result =
      await this.showChangeConfirmationDialogForProperty('Purpose of Loan');
    if (result.isConfirmed) {
      this.mortgage.subjectProperty.purposeOfLoan = this.loanPurpose;
      this.isPurchase = this.isPurposeOfLoanPurchase();
      this.isRefinance = this.isPurposeOfLoanRefi();
      this.isConstructionOnlyLoan = this.isPurposeOfLoanConstructionOnly();
      //this.resetPurposeOfLoanProperties();
    } else {
      this.loanPurpose = this.mortgage.subjectProperty.purposeOfLoan;
    }
    this._loanPurposeValueChanged =
      this._oldLoanPurposeValue != this.application.loanPurposeId;
  };

  private async onLtvRelatedValuesChanged(
    requestedLtv?: number
  ): Promise<void> {
    await this.recalculateLtvAndRelatedValues(requestedLtv);
    this.calculateDownPayment();
  }

  protected onPurchasePriceChanged(): Promise<void> {
    return this.onLtvRelatedValuesChanged();
  }

  protected async onBaseLoanAmountChanged(): Promise<void> {
    await this.onLtvRelatedValuesChanged();
    this.calculateRelatedStatsAfterLoanAmountChange();
  }

  protected async onAppraisedValueChanged(): Promise<void> {
    return this.onLtvRelatedValuesChanged();
  }

  protected async onLtvChanged(): Promise<void> {
    await this.onLtvRelatedValuesChanged(this.ltv);
  }

  protected onAmortizationTermChanged = async (): Promise<void> => {
    await this.recalculateFirstMortgagePAndI();
  };

  protected async onDownPaymentChanged(): Promise<void> {
    let homeValue = this.calculateHomeValue();
    this.mortgage.mortgageTerm.amount = homeValue - this.downPayment;

    await this.recalculateLtvAndRelatedValues();
    this.calculateRelatedStatsAfterLoanAmountChange();
  }

  protected addPurchaseCredit = () => {
    let credit = new PurchaseCredit();
    credit.purchaseCreditId = this._utilityService.getUniqueId();
    if (this.mortgage.transactionDetail.purchaseCredits == undefined) {
      this.mortgage.transactionDetail.purchaseCredits = [];
    }
    this.mortgage.transactionDetail.purchaseCredits.push(credit);
  };

  protected removePurchaseCredit = (purchaseCreditId) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: "Are you sure you'd want to delete this record?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then(function (result: any) {
      if (result.value) {
        const index = self.mortgage.transactionDetail.purchaseCredits.findIndex(
          (p) => p.purchaseCreditId == purchaseCreditId
        );
        self.mortgage.transactionDetail.purchaseCredits.splice(index, 1);
        self.calculatePurchaseCreditsTotal();
      }
    });
  };

  protected calculatePurchaseCreditsTotal = () => {
    this.purchaseCreditsTotal = 0;
    this.mortgage.transactionDetail.purchaseCredits.forEach(
      (purchaseCredit) => {
        if (purchaseCredit.purchaseCreditAmount) {
          this.purchaseCreditsTotal += purchaseCredit.purchaseCreditAmount;
        }
      }
    );
  };

  protected onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.mortgage.subjectProperty['state'] = zipCode.state.toLowerCase();
      this.mortgage.subjectProperty['city'] = Utils.toTitleCase(zipCode.city);
      this.mortgage.subjectProperty['zipCode'] = zipCode.zipcode;
      this.mortgage.subjectProperty['county'] = Utils.toTitleCase(
        zipCode.county
      );
      this.mortgage.subjectProperty['country'] = 'us';
    }
  };

  protected handleAddressChange(e: Partial<Address>): void {
    const subjectProperty = this.mortgage.subjectProperty;
    subjectProperty.address1 = ''; // to reset the last populated address.

    setTimeout(() => {
      subjectProperty.address1 = e.address1;
      subjectProperty.city = e.city;
      subjectProperty.state = e.state;
      subjectProperty.zipCode = e.zipCode;
      subjectProperty.county = e.county;
    }, 200);
  }

  protected selectTab = (tab: any) => {
    var sectionElement = this.getElementForSection(tab);
    if (sectionElement) {
      // sectionElement.scrollIntoView();
      const y =
        sectionElement.getBoundingClientRect().top + window.scrollY - 200;
      window.scroll({
        top: y,
        behavior: 'smooth',
      });
    }
  };

  onOtherFinancingExpensesChanged = () => {
    this.mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.otherFinancingAmount,
        this.otherFinancingFactor as PayPeriod
      );
    this.calculateSubTotal();
  };

  onOtherHousingExpensesChanged = () => {
    this.calculateSubTotal();
  };

  onHomeownersInsuranceChanged = () => {
    this.mortgage.proposedHousingExpense.homeownersInsurance =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.hoiAmount,
        this.hoiFactor as PayPeriod
      );
    this.calculateMonthlyMiscExpenses();
    this.calculateSubTotal();
  };

  onHoaAndCondoFeesPayPeriodChanged = () => {
    // We can just update everything 'HOA and Confo Fees' related here, with the selected pay period
    // Broken down, or not does not matter...
    this.calculateHoaAndCondoFeesBreakdownsWithPayPeriod();
    this.calculateMonthlyMiscExpenses();
    this.calculateSubTotal();
  };

  onHoaAndCondoFeesTotalWithoutBreakdownChanged = () => {
    // The user changed the total, this effectively makes in 'not-broken-down'. So, zero out the individual breakdown pieces
    this.setNotBrokenDownHoaAndCondoFeesTotal(this.associationDuesAmount);
    this.calculateHoaAndCondoFeesBreakdownsWithPayPeriod();
    this.calculateMonthlyMiscExpenses();
    this.calculateSubTotal();
  };

  onSupplementalPropertyInsurancePayPeriodChanged = () => {
    // We can just update everything 'supplemental insurance' related here, with the selected pay period
    // Broken down, or not does not matter...
    this.calculateSupplementalInsuranceBreakdownsWithPayPeriod();
    this.calculateMonthlyMiscExpenses();
    this.calculateSubTotal();
  };

  onSupplementalPropertyInsuranceTotalWithoutBreakdownChanged = () => {
    // The user changed the total, this effectively makes in 'not-broken-down'. So, zero out the individual breakdown pieces
    this.setNotBrokenDownSupplementalInsuranceTotal(this.supplementalAmount);
    this.calculateSupplementalInsuranceBreakdownsWithPayPeriod();
    this.calculateMonthlyMiscExpenses();
    this.calculateSubTotal();
  };

  onRealEstateTaxPayPeriodChanged = () => {
    // We can just update everything 'real estate tax' related here, with the selected pay period
    // Broken down, or not does not matter...
    this.calculateRealEstatTaxBreakdownsWithPayPeriod();
    this.calculateMonthlyMiscExpenses();
    this.calculateSubTotal();
  };

  onRealEstateTaxTotalWithoutBreakdownChanged = () => {
    // The user changed the total, this effectively makes in 'not-broken-down'. So, zero out the individual breakdown pieces
    this.setNotBrokenDownRealEstateTaxTotal(this.propertyTaxesAmount);
    this.calculateRealEstatTaxBreakdownsWithPayPeriod();
    this.resetPropertyTaxesFieldNameSuffix();
    this.calculateMonthlyMiscExpenses();
    this.calculateSubTotal();
  };

  onMortgageInsuranceChanged = () => {
    this.mortgage.proposedHousingExpense.mortgageInsurance =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgageInsuranceAmount,
        this.mortgageInsuranceFactor as PayPeriod
      );
    this.calculateMonthlyMiscExpenses();
    this.calculateSubTotal();
  };

  onMortgageInsuranceClicked = () => {
    const modalRef = this._modalService.open(
      MortgageInsuranceDialogComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.mortgage = this.mortgage;
    modalRef.result.then(
      (mortgage: UrlaMortgage) => {
        this.mortgage.mortgageInsuranceDetail =
          mortgage.mortgageInsuranceDetail;
        this.mortgage.governmentLoanDetail = mortgage.governmentLoanDetail;
        this.mortgage.mortgageTerm = mortgage.mortgageTerm;
        this.mortgage.proposedHousingExpense.mortgageInsurance =
          mortgage.mortgageInsuranceDetail.level1MonthlyAmount;
        this.mortgageInsuranceAmount =
          LeadUtils.calculateSelectedPayPeriodAmountByMonthlyAmount(
            this.mortgage.proposedHousingExpense.mortgageInsurance,
            this.mortgageInsuranceFactor
          );
        this.calculateMonthlyMiscExpenses();
      },
      (error) => {}
    );
  };

  onRealEstateTaxBreakDownClicked = () => {
    const modalRef = this._modalService.open(
      PropertyTaxBreakdownDialogComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.mortgage = this.mortgage;
    modalRef.result.then(
      (result: SubjectPropertyAndProposedHousingExpenses) => {
        if (result) {
          this.mortgage.proposedHousingExpense = _.cloneDeep(
            result.proposedHousingExpense
          );
          this.mortgage.subjectProperty = _.cloneDeep(result.subjectProperty);
          this.propertyTaxesAmount =
            LeadUtils.calculateSelectedPayPeriodAmountByMonthlyAmount(
              this.mortgage.proposedHousingExpense.realEstateTax,
              this.propertyTaxesFactor
            );
          this.isRealEstateTaxBrokenDown =
            this.checkIfRealEstateTaxIsBrokenDown();
          this.calculateSubTotal();
          this.calculateMonthlyMiscExpenses();
        }
      },
      (err) => {}
    );
  };

  onSupplementalPropertyInsuranceBreakDownClicked = () => {
    const modalRef = this._modalService.open(
      SupplementalPropertyInsuranceBreakdownDialogComponent,
      Constants.modalOptions.xlarge
    );
    modalRef.componentInstance.mortgage = this.mortgage;
    modalRef.result.then(
      (
        subjectPropertyAndProposedHousingExpenses: SubjectPropertyAndProposedHousingExpenses
      ) => {
        if (subjectPropertyAndProposedHousingExpenses) {
          this.mortgage.proposedHousingExpense = _.cloneDeep(
            subjectPropertyAndProposedHousingExpenses.proposedHousingExpense
          );
          this.mortgage.subjectProperty = _.cloneDeep(
            subjectPropertyAndProposedHousingExpenses.subjectProperty
          );
          this.supplementalAmount =
            LeadUtils.calculateSelectedPayPeriodAmountByMonthlyAmount(
              this.mortgage.proposedHousingExpense
                .supplementalPropertyInsurance,
              this.supplementalFactor
            );
          this._isSupplementalPropertyInsuranceBrokenDown =
            this.checkIfSupplementalPropertyInsuranceIsBrokenDown();
          this.calculateSubTotal();
          this.calculateMonthlyMiscExpenses();
        }
      },
      () => {}
    );
  };

  onHoaAndCondoFeesBreakDownClicked = () => {
    const modalRef = this._modalService.open(
      HoaDuesCondoFeesBreakdownDialogComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.proposedHousingExpenses =
      this.mortgage.proposedHousingExpense;
    modalRef.result.then(
      (expenses) => {
        if (expenses) {
          this.mortgage.proposedHousingExpense = _.cloneDeep(expenses);
          this.associationDuesAmount =
            LeadUtils.calculateSelectedPayPeriodAmountByMonthlyAmount(
              this.mortgage.proposedHousingExpense
                .homeownersAssociationDuesAndCondominiumFees,
              this.associationDuesFactor
            );
          this._isHoaAndCodoFeesBrokenDown =
            this.checkIfHoaAndCondoFeesIsBrokenDown();
          this.calculateSubTotal();
          this.calculateMonthlyMiscExpenses();
        }
      },
      (err) => {}
    );
  };

  onChangeAmortizatonType = () => {
    this.isAmortizationTypeFixed =
      this.mortgage.mortgageTerm.amortizationType ==
      this._amortizationTypeFixedEnumValue;
    this.isAmortizationTypeARM =
      this.mortgage.mortgageTerm.amortizationType ==
      this._amortizationTypeARMEnumValue;
    if (this.isAmortizationTypeFixed) {
      this.mortgage.mortgageTerm.qualifyingRate =
        this.mortgage.mortgageTerm.interestRate;
    }
  };

  onNoteRateChanged = async (): Promise<void> => {
    if (this.isAmortizationTypeFixed) {
      this.mortgage.mortgageTerm.qualifyingRate =
        this.mortgage.mortgageTerm.interestRate;
    }
    await this.recalculateFirstMortgagePAndI();
  };

  calculateSubTotal = () => {
    setTimeout(() => {
      this.mortgage.calculatedStats.proposedMonthlyPaymentTotal =
        this._calculationService.calculateHousingExpenseTotal(
          this.mortgage.proposedHousingExpense
        );
    }, 100);
  };

  calculateMonthlyMiscExpenses = () => {
    if (
      this.mortgage.realEstateOwned &&
      this.mortgage.realEstateOwned.length > 0
    ) {
      let index = this.mortgage.realEstateOwned.findIndex(
        (r) => r.isSubjectProperty
      );
      if (index > -1) {
        let subtotal = 0;
        subtotal +=
          this.mortgage.proposedHousingExpense?.homeownersInsurance || 0;
        subtotal +=
          this.mortgage.proposedHousingExpense?.supplementalPropertyInsurance ||
          0;
        subtotal += this.mortgage.proposedHousingExpense?.realEstateTax || 0;
        subtotal +=
          this.mortgage.proposedHousingExpense?.mortgageInsurance || 0;
        subtotal +=
          this.mortgage.proposedHousingExpense
            ?.homeownersAssociationDuesAndCondominiumFees || 0;
        this.mortgage.realEstateOwned[index].monthlyMiscExpenses = subtotal;
      }
    }
  };

  protected onLocatedInProjectChanged = () => {
    this.mortgage.subjectProperty.isPropertyNotInAProject =
      !this.locatedInProject;
    if (!this.locatedInProject) {
      this.mortgage.subjectProperty.projectDesignType = null;
    }
  };

  protected async saveLoanInfo(): Promise<boolean> {
    this.qaLoanInfoForm.form.markAllAsTouched();
    // if (!this.qaLoanInfoForm.form.valid) {
    //   return;
    // }

    const payLoad = new LoanDetailsInfo();
    payLoad.application = this.application;

    await this._spinner.show();

    try {
      const loanInfo$ = this._appDetailsService.saveLoanInfo(
        this.application.applicationId,
        payLoad
      );
      const loanInfo = await firstValueFrom(loanInfo$);

      const application = loanInfo.application;
      this._oldLoanPurposeValue = application.loanPurposeId;

      this.applicationContextService.updateMortgageAndApplication(
        application.mortgageLoan,
        application
      );

      this._notifyService.showSuccess(
        'Your loan has been saved successfully.',
        'Success!'
      );

      if (this._loanPurposeValueChanged) {
        this._loanPurposeValueChanged = false;
        await this.saveLoanStatus(application);
      }

      return true;
    } catch (e) {
      this._notifyService.showError(
        e?.message || 'Unable to save Loan Info',
        'Error!'
      );

      return false;
    } finally {
      await this._spinner.hide();
    }
  }

  private async saveLoanStatus(application: LoanApplication): Promise<boolean> {
    const result$ = this._appDetailsService.setLoanStatus(
      application.applicationId
    );

    try {
      const result = await firstValueFrom(result$);
      if (!result) return false;

      const loanStatusId = application.loanStatusId || -1;

      await firstValueFrom(
        this._appDetailsService.setNextLoanStatus(
          application.loanPurposeId,
          loanStatusId,
          application.applicationId
        )
      );
    } catch (e) {
      const message = e?.message || 'Unable to get Loan Status';
      e.message = message;
      throw e;
    }

    return true;
  }

  private checkIfHoaAndCondoFeesIsBrokenDown(): boolean {
    const isBrokenDown: boolean =
      (this.mortgage.proposedHousingExpense.condominiumAssociationDues &&
        Number(
          this.mortgage.proposedHousingExpense.condominiumAssociationDues
        ) > 0) ||
      (this.mortgage.proposedHousingExpense.homeownersAssociationDues != null &&
        Number(this.mortgage.proposedHousingExpense.homeownersAssociationDues) >
          0 &&
        this.mortgage.proposedHousingExpense.homeownersAssociationDues !=
          this.mortgage.proposedHousingExpense
            .homeownersAssociationDuesAndCondominiumFees) ||
      (this.mortgage.proposedHousingExpense.cooperativeAssociationDues !=
        null &&
        Number(
          this.mortgage.proposedHousingExpense.cooperativeAssociationDues
        ) > 0);
    return isBrokenDown;
  }

  private checkIfSupplementalPropertyInsuranceIsBrokenDown(): boolean {
    const isBrokenDown: boolean =
      (this.mortgage.proposedHousingExpense.earthquakeInsurance &&
        Number(this.mortgage.proposedHousingExpense.earthquakeInsurance) > 0) ||
      (this.mortgage.proposedHousingExpense
        .otherSupplementalPropertyInsurance != null &&
        Number(
          this.mortgage.proposedHousingExpense
            .otherSupplementalPropertyInsurance
        ) > 0 &&
        this.mortgage.proposedHousingExpense.supplementalPropertyInsurance !=
          this.mortgage.proposedHousingExpense
            .otherSupplementalPropertyInsurance) ||
      (this.mortgage.proposedHousingExpense.floodInsurance != null &&
        Number(this.mortgage.proposedHousingExpense.floodInsurance) > 0) ||
      (this.mortgage.proposedHousingExpense.volcanoInsurance != null &&
        Number(this.mortgage.proposedHousingExpense.volcanoInsurance) > 0) ||
      (this.mortgage.proposedHousingExpense.hailInsurance != null &&
        Number(this.mortgage.proposedHousingExpense.hailInsurance) > 0) ||
      (this.mortgage.proposedHousingExpense.windAndStormInsurance != null &&
        Number(this.mortgage.proposedHousingExpense.windAndStormInsurance) > 0);
    return isBrokenDown;
  }

  private checkIfRealEstateTaxIsBrokenDown(): boolean {
    const isBrokenDown: boolean =
      (this.mortgage.proposedHousingExpense.statePropertyTax &&
        Number(this.mortgage.proposedHousingExpense.statePropertyTax) > 0) ||
      (this.mortgage.proposedHousingExpense.countyPropertyTax != null &&
        Number(this.mortgage.proposedHousingExpense.countyPropertyTax) > 0 &&
        this.mortgage.proposedHousingExpense.countyPropertyTax !=
          this.mortgage.proposedHousingExpense.realEstateTax) ||
      (this.mortgage.proposedHousingExpense.districtPropertyTax != null &&
        Number(this.mortgage.proposedHousingExpense.districtPropertyTax) > 0) ||
      (this.mortgage.proposedHousingExpense.boroughPropertyTax != null &&
        Number(this.mortgage.proposedHousingExpense.boroughPropertyTax) > 0) ||
      (this.mortgage.proposedHousingExpense.cityPropertyTax != null &&
        Number(this.mortgage.proposedHousingExpense.cityPropertyTax) > 0) ||
      (this.mortgage.proposedHousingExpense.townPropertyTax != null &&
        Number(this.mortgage.proposedHousingExpense.townPropertyTax) > 0) ||
      (this.mortgage.proposedHousingExpense.villagePropertyTax != null &&
        Number(this.mortgage.proposedHousingExpense.villagePropertyTax) > 0) ||
      (this.mortgage.proposedHousingExpense.schoolPropertyTax != null &&
        Number(this.mortgage.proposedHousingExpense.schoolPropertyTax) > 0);
    return isBrokenDown;
  }

  private calculateHoaAndCondoFeesBreakdownsWithPayPeriod = () => {
    this.mortgage.proposedHousingExpense.condominiumAssociationDues =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.condominiumAssociationDues,
        this.associationDuesFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.cooperativeAssociationDues =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.cooperativeAssociationDues,
        this.associationDuesFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.homeownersAssociationDues =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.homeownersAssociationDues,
        this.associationDuesFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense
          .homeownersAssociationDuesAndCondominiumFees,
        this.associationDuesFactor as PayPeriod
      );
  };

  private calculateSupplementalInsuranceBreakdownsWithPayPeriod = () => {
    this.mortgage.proposedHousingExpense.earthquakeInsurance =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.earthquakeInsurance,
        this.supplementalFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance,
        this.supplementalFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.floodInsurance =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.floodInsurance,
        this.supplementalFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.volcanoInsurance =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.volcanoInsurance,
        this.supplementalFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.hailInsurance =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.hailInsurance,
        this.supplementalFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.windAndStormInsurance =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.windAndStormInsurance,
        this.supplementalFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.supplementalPropertyInsurance =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.supplementalPropertyInsurance,
        this.supplementalFactor as PayPeriod
      );
  };

  private calculateRealEstatTaxBreakdownsWithPayPeriod = () => {
    this.mortgage.proposedHousingExpense.statePropertyTax =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.statePropertyTax,
        this.propertyTaxesFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.countyPropertyTax =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.countyPropertyTax,
        this.propertyTaxesFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.districtPropertyTax =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.districtPropertyTax,
        this.propertyTaxesFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.boroughPropertyTax =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.boroughPropertyTax,
        this.propertyTaxesFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.cityPropertyTax =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.cityPropertyTax,
        this.propertyTaxesFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.townPropertyTax =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.townPropertyTax,
        this.propertyTaxesFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.villagePropertyTax =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.villagePropertyTax,
        this.propertyTaxesFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.schoolPropertyTax =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.schoolPropertyTax,
        this.propertyTaxesFactor as PayPeriod
      );
    this.mortgage.proposedHousingExpense.realEstateTax =
      LeadUtils.calculateMonthlyAmountByPayPeriod(
        this.mortgage.proposedHousingExpense.realEstateTax,
        this.propertyTaxesFactor as PayPeriod
      );
  };

  private initialize(context: ApplicationContext): void {
    this.supplementalFactor = PayPeriod.Monthly;
    this.hoiFactor = PayPeriod.Monthly;
    this.associationDuesFactor = PayPeriod.Monthly;
    this.propertyTaxesFactor = PayPeriod.Monthly;
    this.mortgageInsuranceFactor = PayPeriod.Monthly;
    this.otherFinancingFactor = PayPeriod.Monthly;

    this.isLoanReadOnly = context.applicationIsReadOnly;

    this.application = cloneDeep(context.application);
    let mortgage = this.mortgage;

    if (context.applicationKeyDatesByType) {
      this.hasApplicationReceivedKeyDate =
        !!context.applicationKeyDatesByType['applicationReceived']?.eventDate;
    }

    if (mortgage.subjectProperty) {
      if (!mortgage.subjectProperty.estate)
        mortgage.subjectProperty.estate = 'FeeSimple';
      if (!mortgage.subjectProperty.propertyWillBe)
        mortgage.subjectProperty.propertyWillBe = 'PrimaryResidence';
    }

    this.setMortgageCalculationDetails(
      context.currentMortgageCalculationDetails
    );

    this.loanPurpose = mortgage.subjectProperty.purposeOfLoan;
    this.isPurchase = this.isPurposeOfLoanPurchase();
    this.isRefinance = this.isPurposeOfLoanRefi();
    this.isTbd = mortgage.subjectProperty?.address1?.toLowerCase() === 'tbd';
    const creditScores = mortgage.borrowers
      .filter((b) => b.creditScore)
      .map((b) => b.creditScore);
    if (creditScores && creditScores.length) {
      this.ficoOnFile = Math.min(...creditScores);
    }
    this._oldLoanPurposeValue = this.application.loanPurposeId;
    const transactionDetail = mortgage.transactionDetail;
    if (!transactionDetail.purchaseCredits) {
      transactionDetail.purchaseCredits = [];
    }
    this.calculatePurchaseCreditsTotal();
    if (mortgage.mortgageTerm) {
      mortgage.mortgageTerm.amount = Math.floor(
        mortgage.mortgageTerm.amount ?? 0
      );
    }
    if (mortgage.mortgageTerm && mortgage.mortgageTerm.totalLoanAmount) {
      mortgage.mortgageTerm.totalLoanAmount = Math.floor(
        mortgage.mortgageTerm.totalLoanAmount
      );
    }
    this.calculateDownPayment();

    const proposedHousingExpense = mortgage.proposedHousingExpense;
    this.otherFinancingAmount =
      proposedHousingExpense.otherMortgageLoanPrincipalAndInterest || 0;
    this.hoiAmount = proposedHousingExpense.homeownersInsurance || 0;
    this.supplementalAmount =
      proposedHousingExpense.supplementalPropertyInsurance || 0;
    this.propertyTaxesAmount = proposedHousingExpense.realEstateTax || 0;
    this.mortgageInsuranceAmount =
      proposedHousingExpense.mortgageInsurance || 0;
    this.associationDuesAmount =
      proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees || 0;

    this.locatedInProject =
      mortgage.subjectProperty?.isPropertyNotInAProject == false;
    this.onChangeAmortizatonType();

    this.mortgage.borrowers.forEach((borrower) => {
      if (borrower.authorizationMethod === 'Internet') {
        this.isPrequalificationCheckReadonly[borrower.borrowerId.toString()] =
          true;
        borrower.authorizedCreditCheck = true;
      }
    });

    this.isRealEstateTaxBrokenDown = this.checkIfRealEstateTaxIsBrokenDown();
    this._isHoaAndCodoFeesBrokenDown =
      this.checkIfHoaAndCondoFeesIsBrokenDown();
    this._isSupplementalPropertyInsuranceBrokenDown =
      this.checkIfSupplementalPropertyInsuranceIsBrokenDown();
  }

  private getElementForSection = (section: string): HTMLElement => {
    if (section === 'loan-info') {
      return this.loanInfo.nativeElement;
    }
    if (section === 'property-info') {
      return this.propertyInfo.nativeElement;
    }
    if (section === 'title-info') {
      return this.titleInfo.nativeElement;
    }
    return null;
  };

  private showChangeConfirmationDialogForProperty(propertyName: string) {
    return Swal.fire({
      title: 'Are you sure?',
      text: `Changing ${propertyName} will reset your Lead Status to the
       beginning. Are you sure you want to do this?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      cancelButtonColor: '#DC3741',
      reverseButtons: true,
    });
  }

  private isPurposeOfLoanRefi = () => {
    return (
      this.isLoanPurposeSet() &&
      this.mortgage.subjectProperty.purposeOfLoan ==
        this._loanPurposeRefiEnumValue
    );
  };

  private isPurposeOfLoanPurchase = () => {
    return (
      this.isLoanPurposeSet() &&
      this.mortgage.subjectProperty.purposeOfLoan ==
        this._loanPurposePurchaseEnumValue
    );
  };

  private isPurposeOfLoanConstructionOnly = () => {
    return (
      this.isLoanPurposeSet() &&
      this.mortgage.subjectProperty.purposeOfLoan ==
        this._loanPurposeConstructionOnlyEnumValue
    );
  };

  private isLoanPurposeSet = () => {
    return (
      this.mortgage.subjectProperty &&
      this.mortgage.subjectProperty.purposeOfLoan
    );
  };

  private calculateHomeValue = () => {
    let presentValue =
      this.mortgage.mortgageTerm?.appraisedValue ??
      this.mortgage.subjectProperty?.presentValue ??
      0;
    let purchasePriceAmount =
      this.mortgage.transactionDetail?.purchasePriceAmount ?? 0;

    let minValue = this.minOfSalesPriceAndAppraisedValue(
      purchasePriceAmount,
      presentValue
    );
    if (!minValue) {
      minValue = 0;
    }
    return minValue;
  };

  private minOfSalesPriceAndAppraisedValue = (
    sales: number,
    appraised: number
  ) => {
    if (this.mortgage.subjectProperty.purposeOfLoan === 'Purchase') {
      var salesPrice = sales ? sales : Number.MAX_VALUE;
      var appraisedValue = appraised ? appraised : Number.MAX_VALUE;
      var min = Math.min(salesPrice, appraisedValue);
      return min != Number.MAX_VALUE ? min : 0;
    } else {
      // Refinance
      return appraised;
    }
  };

  private calculateDownPayment = () => {
    const homeValue = this.calculateHomeValue();
    this.downPayment = 0;
    const baseLoanAmount = Number(this.mortgage.mortgageTerm?.amount) || 0;
    if (homeValue && baseLoanAmount) {
      this.downPayment = homeValue - baseLoanAmount;
    }
  };

  private async recalculateLtvAndRelatedValues(
    requestedLtv?: number
  ): Promise<void> {
    await this._spinner.show();

    try {
      const calculationDetails = await firstValueFrom(
        this._mortgageService.redoMortgageCalculationDetails(
          this.mortgage,
          requestedLtv
        )
      );
      this.setMortgageCalculationDetails(calculationDetails);

      this.calculateSubTotal();

    } catch (e) {
      this._notifyService.showError(
        e?.message || 'Unable to recalculate LTV and related values',
        'Error!'
      );
    } finally {
      await this._spinner.hide();
    }
  }

  private async recalculateFirstMortgagePAndI(): Promise<void> {
    await this._spinner.show();

    try {
      const calculationDetails = await firstValueFrom(
        this._mortgageService.redoMortgageCalculationDetails(
          this.mortgage
        )
      );
      this.mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest =
        calculationDetails.proposedExpenses.firstMortgagePrincipalAndInterest;
      this.mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest =
        calculationDetails.proposedExpenses.otherMortgageLoanPrincipalAndInterest;

      this.calculateSubTotal();

    } catch (e) {
      this._notifyService.showError(
        e?.message || 'Unable to recalculate LTV and related values',
        'Error!'
      );
    } finally {
      await this._spinner.hide();
    }
  }

  /**
   * Sets the related values of the application based on the calculation
   * details.
   * @param calculationDetails The calculation results.
   */
  private setMortgageCalculationDetails(
    calculationDetails: MortgageCalculationDetails
  ): void {
    const { ltv } = calculationDetails;
    const mortgageTerm = this.mortgage.mortgageTerm;

    mortgageTerm.amount = ltv.baseLoanAmount;
    mortgageTerm.totalLoanAmount = ltv.totalLoanAmount;
    this.ltv = ltv.ltv;
    this.cltv = ltv.cltv;
    this.hcltv = ltv.hcltv;

    this.mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest =
      calculationDetails.proposedExpenses.firstMortgagePrincipalAndInterest;
    this.mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest =
      calculationDetails.proposedExpenses.otherMortgageLoanPrincipalAndInterest;
  }

  private calculateRelatedStatsAfterLoanAmountChange = () => {
    const mortgage = this.mortgage;
    const amount = !mortgage.mortgageTerm.amount
      ? 0
      : Number(mortgage.mortgageTerm.amount);
    const insuranceDetail = mortgage.mortgageInsuranceDetail;
    const miOrFundingFeeFinancedAmount =
      !insuranceDetail.miOrFundingFeeFinancedAmount
        ? 0
        : Number(insuranceDetail.miOrFundingFeeFinancedAmount);
    mortgage.mortgageTerm.totalLoanAmount =
      amount + miOrFundingFeeFinancedAmount;
    if (amount) {
      insuranceDetail.miOrFundingFeeTotalPercent =
        (insuranceDetail.miOrFundingFeeTotalAmount / amount) * 100;
    }

    const calculationService = this._calculationService;
    
    const calculatedStats = mortgage.calculatedStats;
    calculatedStats.proposedMonthlyPaymentTotal =
      calculationService.calculateHousingExpenseTotal(
        mortgage.proposedHousingExpense
      );
    calculatedStats.totalLoanOrDrawAmount =
      calculationService.calculateTotalLoanOrDrawAmount(mortgage);
    calculatedStats.totalMortgageLoans =
      calculationService.calculateTotalMortgage(mortgage);
    calculatedStats.totalDueFromBorrowers =
      calculationService.calculateTotalDueFromBorrowers(mortgage);
    calculatedStats.totalMortgageLoansAndCredits =
      calculatedStats.totalMortgageLoans + calculatedStats.totalCredit;
    calculatedStats.cashFromOrToTheBorrower =
      calculationService.calculateCashFromOrToTheBorrower(mortgage);
  };

  private setNotBrokenDownSupplementalInsuranceTotal = (
    supplementalInsuranceTotal: number
  ) => {
    this.mortgage.proposedHousingExpense.supplementalPropertyInsurance =
      supplementalInsuranceTotal;
    this.mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance =
      supplementalInsuranceTotal;

    this.mortgage.proposedHousingExpense.earthquakeInsurance = 0;
    this.mortgage.proposedHousingExpense.floodInsurance = 0;
    this.mortgage.proposedHousingExpense.volcanoInsurance = 0;
    this.mortgage.proposedHousingExpense.hailInsurance = 0;
    this.mortgage.proposedHousingExpense.windAndStormInsurance = 0;
  };

  private setNotBrokenDownRealEstateTaxTotal = (realEstateTaxTotal: number) => {
    this.mortgage.proposedHousingExpense.realEstateTax = realEstateTaxTotal;
    this.mortgage.proposedHousingExpense.countyPropertyTax = realEstateTaxTotal;

    this.mortgage.proposedHousingExpense.boroughPropertyTax = 0;
    this.mortgage.proposedHousingExpense.cityPropertyTax = 0;
    this.mortgage.proposedHousingExpense.districtPropertyTax = 0;
    this.mortgage.proposedHousingExpense.schoolPropertyTax = 0;
    this.mortgage.proposedHousingExpense.statePropertyTax = 0;
    this.mortgage.proposedHousingExpense.townPropertyTax = 0;
    this.mortgage.proposedHousingExpense.villagePropertyTax = 0;
  };

  private setNotBrokenDownHoaAndCondoFeesTotal = (
    hoaAndCondoFeesTotal: number
  ) => {
    this.mortgage.proposedHousingExpense.homeownersAssociationDues =
      hoaAndCondoFeesTotal;
    this.mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees =
      hoaAndCondoFeesTotal;

    this.mortgage.proposedHousingExpense.condominiumAssociationDues = 0;
    this.mortgage.proposedHousingExpense.cooperativeAssociationDues = 0;
  };

  private resetPropertyTaxesFieldNameSuffix() {
    this.propertyTaxesFieldNameSuffix =
      !this._isRealEstateTaxBrokenDown &&
      this.mortgage.proposedHousingExpense.countyPropertyTax > 0
        ? ' (County)'
        : '';
  }
}
