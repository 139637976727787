import { AfterViewInit, Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuItem } from 'primeng/api';
import { Subscription, finalize, forkJoin } from 'rxjs';
import { ApplicationContext, BaseKeyDate, DisclosureDocumentSet, DisclosureHistory, DisclosureRequestData, FloodCertificationTypeEnum, LoanApplication, MortgageLanguage, ThirdPartyCredentialType, ThirdPartyKeyValue } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { DisclosureLoanPlan } from 'src/app/models/third-party/disclosure/disclosure-loan-plan.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanService } from 'src/app/services/loan';
import { LoanServicesService } from 'src/app/services/loan/loan-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { PreCheckDisclosureWizardDialogComponent } from '../pre-check-disclosure-wizard-dialog/pre-check-disclosure-wizard-dialog.component';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { Utils } from 'src/app/core/services/utils';
import { SystemLevelService } from 'src/app/services/system-level.service';
import * as _ from 'lodash';

@Component({
  selector: 'disclosures-wizard',
  templateUrl: './disclosures-wizard.component.html',
  styleUrls: ['./disclosures-wizard.component.scss']
})
export class DisclosuresWizardComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('disclosuresForm') disclosuresForm: NgForm | undefined;

  @Input() hasCancelButton: boolean = false;
  @Output() canceled = new EventEmitter<any>();

  steps: MenuItem[];

  activeStepIndex: number = 0;
  nextButtonLabel: string = "Skip >";

  atFirstStep: boolean = true;
  isStepRunning: boolean = false;
  isWizardCompleted: boolean = false;
  disableNextButton: boolean = false;

  refreshHistory: boolean = false;
  loanId: number;

  documentSets: EnumerationItem[] = [];
  preferredLanguages: EnumerationItem[] = [];
  floodCertificationTypes: EnumerationItem[] = [
    new EnumerationItem("Basic", FloodCertificationTypeEnum.Basic),
    new EnumerationItem("Check", FloodCertificationTypeEnum.Check),
    new EnumerationItem("Life Of Loan", FloodCertificationTypeEnum.LifeOfLoan),
  ];

  loanPlans: DisclosureLoanPlan[] = [];
  deliveryMethods: EnumerationItem[] = [];

  recentDisclosure: DisclosureHistory = new DisclosureHistory();
  selectedLoanPlan: DisclosureLoanPlan;
  requesting: boolean;
  isRecentDisclosure: boolean;

  thereAreNoLoanPlans: boolean = false;

  loadingData: boolean;
  refreshingPlans: boolean;

  estimatedClosingCostsExpiration: Date | string;

  mortgage: UrlaMortgage;

  disclosureTrackingDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  disclosureAuditResultsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: "drawer-wrapper"
  }

  context: ApplicationContext;

  selectedAuditCategories: string[] = [];

  private _loanInfoChangesSubscription: Subscription;
  private _keyDatesSubscription: Subscription;

  private _predisclosureEnumValue: string;
  private _redisclosuresEnumValue: string;

  protected cachedLoanPlans: DisclosureLoanPlan[] = [];
  protected isTpoUser: boolean;

  protected cachedDocumentSets: DisclosureDocumentSet[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _loanService: LoanService,
    private readonly _drawerService: DrawerService,
    private readonly _enumerationService: EnumerationService,
    private readonly _notificationService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _mortgageCalculationsService: MortgageCalculationService,
    private readonly _systemLevelService: SystemLevelService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.steps = [
      { label: 'Run Disclosure Request' },
      { label: 'Show Request Errors/Warnings' },
      { label: 'Preview Disclosures' },
      { label: 'Disclosure Results' },
    ];

    this.loadingData = true;
    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        this.context = context;
        if (context.application) {
          this.initialize();
        }
      });
    } else {
      this.context = this.applicationContext;
      this.initialize();
    }

    this._keyDatesSubscription = this.applicationContextService.keyDatesChanges.subscribe(() => {
      this.requestAfterKeyDateChanges();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._keyDatesSubscription) {
      this._keyDatesSubscription.unsubscribe();
    }
  }

  sendDisclosuresForESignChanged = () => {
    this.nextButtonLabel = this.recentDisclosure.requestData.shouldSendESignLinkToBorrowers
      ? "Generate & Send Disclosures"
      : "Generate Disclosures"
  }

  handleRefreshHistoryChange(flag: boolean) {
    this.refreshHistory = flag;
  }

  handleLoanPlanChange() {
    this.selectedLoanPlan = this.loanPlans.find(
      (lp) => lp.code === this.recentDisclosure.requestData.loanPlanCode
    );
  }

  requestDisclosure() {
    this.requesting = true;
    this.isStepRunning = true;

    const requestData: DisclosureRequestData = {
      applicationId: this.loanId,
      ...this.recentDisclosure.requestData
    };

    this._loanServicesService
      .requestDisclosure(requestData)
      .pipe(finalize(() => { this.requesting = false; this.isStepRunning = false; }))
      .subscribe({
        next: (res) => {
          this.recentDisclosure = res?.item1 || this.initDefaultDisclosure();
          this.isRecentDisclosure = true;

          if(this.recentDisclosure.disclosureResponse?.errors?.length){
            return;
          }
          else {
            if (this.activeStepIndexWithOffset == 3) {
              this.cachedDocumentSets = _.cloneDeep(this.recentDisclosure?.disclosureResponse?.documentSets || []);
            }
          }

          if (this.activeStepIndexWithOffset == 3) {
            this.recentDisclosure.requestData.shouldSendESignLinkToBorrowers = true; // default sent
            this.sendDisclosuresForESignChanged();
          }
          else if (this.activeStepIndexWithOffset == 4) {
            this.isWizardCompleted = true;
          }
          if (!requestData.isAudit) {
            if (this.loanId) {
              this.updateMortgageAfterDisclosureRun();
            }
          }
          this.handleRefreshHistoryChange(true);

          if (this.loanId) {
            this.applicationContextService.updateLoanTasks();
          }

        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't request disclosure",
            'Disclosure'
          );
          if (!requestData.isAudit && this.loanId) {
            this.applicationContextService.applicationTrackingStatusesChanged();
          }
        }
      });
  }

  displayDocumentSetTooltip(): string {
    const document = this.documentSets.find(
      (doc) =>
        doc.alternateValue === this.recentDisclosure.requestData?.documentSet
    );
    return document ? document.name : '';
  }

  displayLoanPlanTooltip(): string {
    const plan = this.loanPlans.find(
      (plan) => plan.code === this.recentDisclosure.requestData?.loanPlanCode
    );
    return plan ? `${plan.description} (${plan.code})` : '';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setNextButtonLabel();
    });
  }

  onBackClicked = () => {
    this.navigateBackward();
  }

  onNextClicked = () => {
    this.navigateForward();
  }

  onSendESignLinkChanged = () => {
    this.updateCurrentStepRelatedState();
  }

  onCancelClicked = () => {
    if (this.hasCancelButton) {
      this.canceled.emit();
    }
    else {
      this.activeStepIndex = 0;
      this.updateCurrentStepRelatedState();
      this.ngOnInit();
    }
  }

  onDisclosureTrackingClicked = () => {
    this._drawerService.show("disclosureTrackingDrawer", 10);
  }

  onDisclosureTrackingDrawerClosed = () => {
    this._drawerService.hide("disclosureTrackingDrawer", 10);
  }

  onShowAuditResultsClicked = () => {
    this._drawerService.show("disclosureAuditResultsDrawer", 10);
  }

  disclosureAuditResultsDrawerClosed = () => {
    this._drawerService.hide("disclosureAuditResultsDrawer", 10);
  }

  validateFields = (): boolean => {

    if (this.activeStepIndexWithOffset == 1) {
      if (this.disclosuresForm) {
        this.disclosuresForm.form.markAllAsTouched();
        if (!this.disclosuresForm.form.valid) {
          return false;
        }
      }
    }
    else if (this.activeStepIndexWithOffset == 2) {
      if (this.recentDisclosure.disclosureResponse?.errors?.length) {
        return false;
      }
    }

    return true;
  }

  navigateForward = () => {

    if (!this.validateFields()) {
      return;
    }

    if (this.activeStepIndexWithOffset == 1) {
      this.recentDisclosure.requestData.isAudit = true;
      this.recentDisclosure.requestData.shouldSendESignLinkToBorrowers = false;
      this.recentDisclosure.requestData.isDocumentPreview = false;
      this.requestDisclosure();
    } else if (this.activeStepIndexWithOffset == 2) {
      this.recentDisclosure.requestData.isAudit = false;
      this.recentDisclosure.requestData.isDocumentPreview = true;
      this.requestDisclosure()
    } else if (this.activeStepIndexWithOffset == 3) {
      this.recentDisclosure.requestData.isAudit = false;
      this.recentDisclosure.requestData.isDocumentPreview = false;
      this.requestDisclosure()
    }

    if (this.steps.length - 1 > this.activeStepIndex) {
      this.activeStepIndex++;
      this.updateCurrentStepRelatedState();
    }
  };

  navigateBackward = () => {
    if (this.activeStepIndex > 0) {
      this.activeStepIndex--;
    }
    this.updateCurrentStepRelatedState();
  }

  onRefreshAudits = (selectedCategories: string[]) => {
    this.selectedAuditCategories = selectedCategories;

    this.recentDisclosure.requestData.isAudit = true;
    this.recentDisclosure.requestData.shouldSendESignLinkToBorrowers = false;
    this.recentDisclosure.requestData.isDocumentPreview = false;
    this.requestDisclosure();
  }

  onUsePrintFulFillmentChanged = () => {
    if (!this.recentDisclosure.requestData.usePrintFulfillment) {
      this.recentDisclosure.requestData.deliveryMethod = null;
      this.recentDisclosure.requestData.deliveryMethodOtherDescription = null;
      this.recentDisclosure.requestData.printFulfillmentDeliverToName = null;
      this.recentDisclosure.requestData.printFulfillmentAttentionToName = null;
    }
  }

  onRefreshLoanPlans = () => {
    this.refreshingPlans = true;

    forkJoin({
      systemLevel: this._systemLevelService.getSystemLevel(),
      loanPlans: this._loanServicesService.getDisclosureLoanPlans()
    }).subscribe({
      next: ({ systemLevel, loanPlans }) => {

        const plans = loanPlans || [];

        let cred = systemLevel.thirdPartyCredentials.find(cred => cred.vendorName == "DocMagic" && cred.credentialType == ThirdPartyCredentialType.Disclosure);
        if (cred) {
          let loanPlanCache = cred.thirdPartyKeyValuePairs.find(p => p.key == Constants.disclosureLoanPlansCacheKey);
          if (loanPlanCache) {
            loanPlanCache.value = JSON.stringify(plans);
          }
          else {
            loanPlanCache = new ThirdPartyKeyValue(Constants.disclosureLoanPlansCacheKey, JSON.stringify(plans));
            cred.thirdPartyKeyValuePairs.push(loanPlanCache);
          }

          this._systemLevelService.saveCredential(cred).subscribe({
            next: () => {
              this.cachedLoanPlans = plans;
            },
            error: (err) => {
              this._notificationService.showError(`Loan plans couldn't be saved to cache`, 'System Level');
            }
          })
            .add(() => this.refreshingPlans = false)

        }
      },
      error: error => {
        this.refreshingPlans = false;
        this._notificationService.showError(`${error ? error.message : ''}`, 'System Level');
      }
    })
  }

  private initialize() {
    this.loanId = this.context.application.applicationId;
    this.mortgage = this.context.currentMortgage;
    this.isTpoUser = this.context.isTpo;
    this.deliveryMethods = this._enumerationService.docDeliveryMethods;

    if (this.isTpoUser) {
      this.disclosureAuditResultsDrawerOptions.containerWrapperId = null;
    }

    this.getDicslosureLoanPlans(this.afterLoanPlansAreLoaded);
    this.getDisclosureDocuments();
    this.getPreferedLanguages();

    this.checkKeyDates();

    if (this.mortgage?.transactionDetail?.estimatedClosingCostsExpiration) {
      this.estimatedClosingCostsExpiration = this.mortgage?.transactionDetail?.estimatedClosingCostsExpiration;
    }
    else {
      const currentDate = new Date();
      this.estimatedClosingCostsExpiration = Utils.formatDateWithoutTime(Utils.addBusinessDays(currentDate, 10));
    }
  }

  private afterLoanPlansAreLoaded = () => {
    if (!this.cachedLoanPlans.length) {
      this.thereAreNoLoanPlans = true;
    } else {
      this.loanPlans = this.cachedLoanPlans.filter(dlp =>
        dlp.loanType && this.mortgage.mortgageTerm?.mortgageAppliedFor == dlp.loanType &&
        dlp.rateType && this.mortgage.mortgageTerm?.amortizationType == dlp.rateType);

      this.thereAreNoLoanPlans = !this.loanPlans.length;
    }
  }

  private getDicslosureLoanPlans = (callback: Function) => {
    this._systemLevelService.getSystemLevel().subscribe({
      next: (systemLevel) => {
        const cred = systemLevel.thirdPartyCredentials.find(cred => cred.vendorName == "DocMagic" && cred.credentialType == ThirdPartyCredentialType.Disclosure);
        if (cred) {
          const loanPlanCache = cred.thirdPartyKeyValuePairs.find(p => p.key == Constants.disclosureLoanPlansCacheKey);
          if (loanPlanCache) {
            this.cachedLoanPlans = JSON.parse(loanPlanCache.value);
          }

          callback();
        }
      },
      error: () => {
        this._notificationService.showError("Could not get loan plans", "System Level");
      }
    })
  }

  private getDisclosureDocuments = () => {
    forkJoin({
      allEnums: this._enumerationService.getMortgageDocumentSetEnumerations(),
      documentSets: this._loanServicesService.getDisclosureDocumentSets(this.loanId)
    })
      .subscribe({
        next: ({ allEnums, documentSets }) => {
          this._predisclosureEnumValue = this._enumerationService.getEnumValue(Constants.enumerationValueNames.MortgageDocumentSet.Predisclosure);
          this._redisclosuresEnumValue = this._enumerationService.getEnumValue(Constants.enumerationValueNames.MortgageDocumentSet.Redisclosure);

          let allDocSetEnums = allEnums[Constants.documentSetEnumerations.mortgageDocumentSet] || [];
          this.documentSets = documentSets && documentSets.length ? allDocSetEnums.filter(e => documentSets.findIndex((le => le == e.value)) > -1) : [];
          const initialDisclosures = this.documentSets.find(ds => ds.value === this._predisclosureEnumValue);
          if (!initialDisclosures) {
            const redisclosure = allDocSetEnums.find(e => e.value === this._redisclosuresEnumValue);
            if (redisclosure) {
              this.documentSets.push(redisclosure);
            }
          }

          this.getRecentDisclosure();
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't load documents",
            'Disclosure'
          );
        }
      })
  }

  private getPreferedLanguages = () => {
    this._enumerationService
      .getMortgageLanguageEnumerations()
      .subscribe({
        next: (res) => {
          this.preferredLanguages = res[Constants.mortgageEnumerations.language];
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || "Couldn't load mortgage languages",
            'Disclosure'
          );
        }
      });
  }

  private initDefaultDisclosure(): DisclosureHistory {
    const initialDisclosures = this.documentSets.find(ds => ds.value === this._predisclosureEnumValue);
    const defaultDisclosures = initialDisclosures ? initialDisclosures.value : this._redisclosuresEnumValue;
    return <DisclosureHistory>{
      requestData: {
        isAudit: true,
        shouldSendESignLinkToBorrowers: true,
        documentSet: defaultDisclosures,
        loanPlanCode: '',
        preferredLanguage: this.getDefaultLanguagePreference(),
        floodCertificationType: '',
        sendNotificationToRequestor: false,
        includeLoanAnalysisReports: false,
      } as unknown as DisclosureRequestData,
    };
  }

  private getDefaultLanguagePreference = (): MortgageLanguage => {
    return this.context.currentMortgage.borrowers[0]?.languagePreference ?? this.context.application.languagePreference ?? "English";
  }

  private getRecentDisclosure = () => {
    this.loadingData = true;
    this._loanServicesService.getDisclosureHistory(this.loanId, true)
      .pipe(finalize(() => this.loadingData = false))
      .subscribe({
        next: (history: DisclosureHistory[]) => {
          this.recentDisclosure = history[0] || this.initDefaultDisclosure();

          if (!this.recentDisclosure.requestData.preferredLanguage) {
            this.recentDisclosure.requestData.preferredLanguage = this.getDefaultLanguagePreference();
          }

          if (this.recentDisclosure.disclosureResponse && this.recentDisclosure.disclosureResponse.auditMessages.length) {
            if (this.steps.length < 5) {
              this.steps.unshift({ label: 'Latest Results' });
              this.steps = [...this.steps];
            }
          }
          this.isRecentDisclosure = history.length > 0;
          if (this.isRecentDisclosure) {
            this.handleLoanPlanChange();
          }

          this.setActionsStatuses();
          this.updateCurrentStepRelatedState();
        },
        error: (err) => {
          this.recentDisclosure = this.initDefaultDisclosure();
          this._notificationService.showError(
            err?.message || "Couldn't load most recent disclosure",
            'Disclosures'
          );
        }
      });
  }

  /**
 * sets proper checkboxes state based on their selection
 */
  private setActionsStatuses() {
    this.recentDisclosure.requestData.shouldSendESignLinkToBorrowers = !this.recentDisclosure.requestData.isAudit;
  }

  private updateCurrentStepRelatedState = () => {
    this.atFirstStep = this.activeStepIndex === 0;
    this.setNextButtonLabel();
  }

  private setNextButtonLabel = () => {
    if (this.activeStepIndexWithOffset === 0) {
      this.nextButtonLabel = "Next >";
    }
    else if (this.activeStepIndexWithOffset === 1) {
      this.nextButtonLabel = "Run Audit";
    }
    else if (this.activeStepIndexWithOffset === 2) {
      this.nextButtonLabel = "Preview Disclosures";
    }
    else if (this.activeStepIndexWithOffset === 3) {
      this.nextButtonLabel = "Generate Disclosures";
    }
    else if (this.activeStepIndexWithOffset === 4) {
      this.nextButtonLabel = `Send Disclosures`;
    }
  }

  private updateMortgageAfterDisclosureRun = () => {
    this._loanService.getApplicationModel(this.loanId, true)
      .subscribe({
        next: (loanApp: LoanApplication) => {
          this.applicationContextService.updateMortgage(loanApp.mortgageLoan);
        },
        error: (err) => {
          this._notificationService.showError(err.error.message || err.error, "Error!");
        }
      })
  }

  private checkKeyDates = () => {
    this._loanService.getKeyDatesByType(this.loanId).subscribe({
      next: (keyDates) => {
        const estimatedClosingKeyDate = keyDates ? keyDates["estimatedClosing"] : null;
        const closingKeyDate = keyDates ? keyDates["closing"] : null;

        if (!estimatedClosingKeyDate?.eventDate && !closingKeyDate?.eventDate) {
          this.openPreCheckDisclosureWizardDialog(estimatedClosingKeyDate);
        }
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Unable to precheck disclosure wizard", "Error!");
      }
    })
  }

  private openPreCheckDisclosureWizardDialog = (estimatedClosingKeyDate: BaseKeyDate) => {
    const modalRef = this._modalService.open(PreCheckDisclosureWizardDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.estimatedClosingKeyDate = estimatedClosingKeyDate;
    modalRef.componentInstance.applicationId = this.loanId;
    modalRef.componentInstance.isPurchase = this._mortgageCalculationsService.isPurposeOfLoanPurchase(this.mortgage);

    modalRef.result.then(() => {
      this.requestAfterKeyDateChanges();
    }, () => { })
  }

  private requestAfterKeyDateChanges = () => {
    this.recentDisclosure.requestData.isAudit = true;
    this.recentDisclosure.requestData.shouldSendESignLinkToBorrowers = false;
    this.requestDisclosure();
  }

  get activeStepIndexWithOffset() {
    return this.steps.length === 4
      ? this.activeStepIndex + 1
      : this.activeStepIndex;
  }

}
