import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UrlaDropdownComponent } from '../../../common/components';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';

@Component({
  selector: 'language-preferences',
  templateUrl: 'language-preferences.component.html',
  providers: [MakeProvider(LanguagePreferencesComponent)],
})
export class LanguagePreferencesComponent extends UrlaDropdownComponent {

  @Input()
  languageOptions: EnumerationItem[] = [];

  @Output()
  refusalToProvideInfoDecisionChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  borrowerRefusesToProvideLanguagePreference: boolean | null = null;

  @Input()
  readOnly: boolean = false;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  onRefusalDecisionChanged = (e: any) => {
    this.borrowerRefusesToProvideLanguagePreference = e.target.checked;
    this.refusalToProvideInfoDecisionChanged.emit(this.borrowerRefusesToProvideLanguagePreference);
    if (this.borrowerRefusesToProvideLanguagePreference) {
      this.doSetValue(null);
    }
  }

  onPreferredLanguageChanged = () => {
    this.borrowerRefusesToProvideLanguagePreference = !!!this.value;
  }

  getOptionLabel = () => {
    if (this.readonly) {
      let label = '—';
      if (this.options && (this.value != null)) {
        this.options.forEach(option => {
          if (option.value.toString() == this.value.toString()) {
            label = option.name;
          }
        });
      }
      return label;
    }
  }
}
