import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'file-import-dialog',
  templateUrl: 'file-import-dialog.component.html'
})

export class FileImportDialogComponent implements OnInit {

  @Input() accept: string = "/*";

  uploadedFiles: Array<File> = [];

  title: string;
  message: string;

  multiple: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

  handleFileInput(files: FileList) {
  }
}
