import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { QuickApplyLoanInfoComponent } from "./qa-loan-info.component";
import { LoanCreditModule } from "src/app/modules/loan-services/loan-credit/loan-credit.module";

@NgModule({
  imports: [
    SharedModule,
    LoanCreditModule
  ],
  declarations: [
    QuickApplyLoanInfoComponent
  ],
  exports: [
    QuickApplyLoanInfoComponent
  ],
  providers: [
  ]
})
export class QaLoanInfoModule {
  constructor() {
  }
}
