import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { MortgageBorrower } from 'src/app/models';

@Component({
  selector: 'borrower-picker',
  templateUrl: 'borrower-picker.component.html',
  styleUrls: ['borrower-picker.component.scss'],
  providers: [MakeProvider(BorrowerPickerComponent)],
  viewProviders: [formViewProvider]
})
export class BorrowerPickerComponent {
  @ViewChild('model') model: NgModel;

  private _selectedItems: MortgageBorrower[] = [];

  @Input() possibleChoices: MortgageBorrower[] = [];

  uniqueId: string;

  @Output() itemSelected = new EventEmitter<MortgageBorrower>();
  @Output() itemDeselected = new EventEmitter<MortgageBorrower>();

  @Input() disabledChoices: MortgageBorrower[] = [];

  @Input()
  label: string = '';

  @Input()
  required: boolean = false;

  isValid: boolean = true;

  @Input() get selectedItems(): MortgageBorrower[] {
    return this._selectedItems;
  }

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  fieldBeingEdited: string = '';

  @Input()
  urlaFieldsConfig: {};

  borrowerPickerModel;

  set selectedItems(items: MortgageBorrower[]) {
    setTimeout(() => {
      this._selectedItems = items;
      this.validate();
    });
  }

  constructor() {}

  validate = (): boolean => {
    if (this.required) {
      this.isValid = this._selectedItems.length > 0;
    }

    if (this.isValid) {
      this.borrowerPickerModel = 'any';
      if (this.model)
        this.model.control.setErrors(null);
    } 
    else {
      this.borrowerPickerModel = null;
      if (this.model)
        this.model.control.setErrors({'invalid': true});
    }

    return this.isValid;
  }

  selectionChanged = (target: any, choice: MortgageBorrower): void => {
    setTimeout(() => {
      if (target.checked) {
        if (
          !this._selectedItems.find((i) => i.borrowerId === choice.borrowerId)
        ) {
          this._selectedItems.push(choice);
          this.itemSelected.emit(choice);
        }
      } else {
        const item = this._selectedItems.find(
          (i) => i.borrowerId == choice.borrowerId
        );
        if (item) {
          var index = this._selectedItems.indexOf(item);
          if (index >= 0) {
            this._selectedItems.splice(index, 1);
            this.itemDeselected.emit(item);
          }
        }
      }
      this.validate();
    });
  };

  isSelected = (choice: MortgageBorrower): boolean => {
    var item = this._selectedItems.find(
      (i) => i.borrowerId === choice.borrowerId
    );
    return item !== undefined;
  };

  isDisabled = (choice: MortgageBorrower): boolean => {
    var item = this.disabledChoices.find(
      (i) => i.borrowerId === choice.borrowerId
    );
    return item !== undefined;
  };

  ngOnInit() {
    this.uniqueId = Date.now().toString();
    this.validate();
  }
}
