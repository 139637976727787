<div class='qa-table-header'>
  <div class='qa-table-header__start'>
    Monthly Income:
    <span class='monthly-income'>
      {{ monthlyIncome | currency }}
    </span>
  </div>

  <div class='qa-table-header__end'>
    <div class='employment-history {{ employmentHistoryModifier }}'>
      <i [class.fa-check]='employmentHistoryModifier === "qa-success"'
         [class.fa-times-circle]='employmentHistoryModifier === "qa-danger"'
         [title]='employmentHistoryModifier === "qa-success"
          ? "Employment history is sufficient"
          : "Must have at least 2 years of employment history"'
         class='fa ms-1'>
      </i>

      <span title='Total employment duration'>
        {{ employmentHistoryText }}
      </span>
    </div>

    <qa-delete-button (qaClick)='onClickDeleteSelectedItems()'
                      *ngIf='areThereAnySelectedItems'>
      Delete Selected
    </qa-delete-button>
  </div>
</div>
<div class="qa-table-header-border"> </div>
<form #form='ngForm' class='table-responsive'>
  <table class='table table-hover'>
    <tbody>
    <ng-container *ngFor='let item of items; index as i'>
      <!-- Values row -->
      <tr>
        <!-- Actions -->
        <td [class.selected-item]='item.isSelected'>
          <span class='d-flex align-items-center gap-2'>
            <!-- Row selection checkbox -->
            <input (change)='onChangeSelectItem(i)'
                   class='form-check-input mt-0 select-row-checkbox'
                   title='Select Income' type='checkbox'>

            <!-- Collapse icon -->
            <i (click)='item.toggleExpanded()'
               [class.expanded]='item.isExpanded'
               class='cursor fa fa-chevron-right expand-icon'
               title='Expand to Edit'></i>
          </span>
        </td>

        <!-- Borrower and collapse icon -->
        <td>
          <div class='d-flex gap-2 align-items-center'>
            <select-input
              (ngModelChange)='onChangeBorrowerId($event, i)'
              [(ngModel)]='item.value.borrowerId'
              [editorMode]='EditorMode.Inline'
              [inlinePlaceholder]='borrowerPlaceholder'
              [noneOptionDisabled]='true'
              [options]='borrowerOptions'
              name='borrowerId{{ i }}'
              optionNameKey='text'
              optionValueKey='id'
            ></select-input>

            <qa-badge *ngIf='item.isCurrent'
                      title='This employment is currently active'
                      variant='indigo'>
              Current
            </qa-badge>
          </div>
        </td>

        <!-- Income Type (Employment or other income) -->
        <td class='text-muted'>
          <span *ngIf='!item.isExpanded' @fade>
            {{ item.incomeTypeName }}
          </span>
        </td>

        <!-- Employer Name -->
        <td>
          <div *ngIf='!item.isExpanded' @fade>
            <span *ngIf='!item.isExpanded'
              class='d-inline-flex align-items-center gap-2'>
              <ng-container *ngIf='item.isEmployment'>
                <span *ngIf='item.isRetired' class='text-muted'>
                  Retired
                </span>

                <ng-container *ngIf='!item.isRetired'>
                  <span class='text-muted'>At</span>

                  <text-input
                    [(ngModel)]='item.employment.employer'
                    [editorMode]='EditorMode.Inline'
                    name='employer{{ i }}'
                    placeholder='Employer Name'>
                  </text-input>

                  <qa-more-popover *ngIf='item.isPrimary'
                                   [content]='employmentBadgesTemplate'
                                   title='Show employment attributes'>
                  </qa-more-popover>

                  <ng-template #employmentBadgesTemplate>
                    <div class='employment-badges'>
                      <qa-badge *ngIf='item.isPrimary'
                                title='This is the primary employment'
                                variant='green'>
                        Primary
                      </qa-badge>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>

              <span *ngIf='!item.isEmployment' class='text-muted'>
                Non employment
              </span>
            </span>
          </div>
        </td>

        <!-- Start Date (since ...) -->
        <td>
            <span *ngIf='!item.isExpanded' @fade
                  class='d-inline-flex align-items-center gap-2'>
              <ng-container *ngIf='item.employment as employment'>
                <ng-container *ngIf='item.isCurrent'>
                  <span class='text-muted'>Since</span>
                  <date-input (ngModelChange)='onChangeEmploymentDate()'
                              [(ngModel)]='employment.startDate'
                              [editorMode]='EditorMode.Inline'
                              [required]='true'
                              id='start-date{{ i }}'
                              name='startDate{{ i }}'>
                  </date-input>
                </ng-container>

                <ng-container *ngIf='!item.isCurrent'>
                  <ng-container
                    *ngTemplateOutlet='formerEmploymentDateTemplate;
                     context: {employment: employment}'>
                  </ng-container>
                </ng-container>
              </ng-container>

              <span *ngIf='!item.isEmployment'
                    class='text-muted'>&mdash;</span>
            </span>
        </td>

        <!-- Amount (monthly) -->
        <td class='text-end'>
          <div *ngIf='!item.isExpanded' @fade>
            <span class='d-inline-flex align-items-center gap-2'>
              <span>
                {{ item.monthlyIncome | currency }}
              </span>

              <span class='text-muted'> / mo</span>
            </span>
          </div>
        </td>
      </tr>

      <!-- Editor row -->
      <tr>
        <td colspan='6'>
          <expansion-panel
            [content]='itemEditorTemplate'
            [isExpanded]='item.isExpanded'
          ></expansion-panel>

          <ng-template #itemEditorTemplate>
            <qa-fi-employment-editor
              (cancel)='onCancelUpdateItem(item)'
              (close)='item.toggleExpanded()'
              (update)='onUpdateEmployment(i);'
              *ngIf='item.isEmployment'
              [employment]='item.employment'
            ></qa-fi-employment-editor>

            <qa-fi-income-editor
              (cancel)='onCancelUpdateItem(item)'
              (close)='item.toggleExpanded()'
              (update)='onUpdateIncome(i)'
              *ngIf='item.income'
              [income]='item.income'
            ></qa-fi-income-editor>
          </ng-template>
        </td>
      </tr>
    </ng-container>

    <!-- Empty state -->
    <tr *ngIf='items.length === 0 && !beingCreatedItemState'>
      <td colspan='6' class='text-center'>
        <span class='text-muted'>No income entries</span>
      </td>
    </tr>

    <!-- add buttons -->
    <tr *ngIf='!beingCreatedItemState'>
      <td colspan='6'>
        <div class='d-flex gap-2'>
          <qa-add-button (onClick)='onClickCreateIncome()'>
            Income
          </qa-add-button>

          <qa-add-button (onClick)='onClickCreateEmployment()'>
            Employment
          </qa-add-button>
        </div>
      </td>
    </tr>

    <!-- New entry editor -->
    <ng-container *ngIf='beingCreatedItemState as state'>
      <!-- New income row -->
      <tr>
        <!-- Borrower -->
        <td colspan='2'>
          <div class='form-group mb-0'>
            <select-input
              [(ngModel)]='state.borrowerId'
              [editorMode]='EditorMode.Inline'
              [ngModelOptions]='{standalone: true}'
              [noneOptionDisabled]='true'
              [options]='borrowerOptions'
              id='being-created-borrower-id'
              name='beingCreatedBorrowerId'
              optionNameKey='text'
              optionValueKey='id'
            ></select-input>
          </div>
        </td>

        <td colspan='4'></td>
      </tr>

      <!-- Editor row -->
      <tr>
        <td colspan='6'>
          <ng-container *ngIf='state.isEmployment'>
            <qa-fi-employment-editor
              (cancel)='onCancelCreateItem()'
              (close)='onCreateEmployment(state.employment)'
              (update)='state.employment = $event;'
              [externalError]='state.externalError'
            ></qa-fi-employment-editor>
          </ng-container>

          <ng-container *ngIf='state.isIncome'>
            <qa-fi-income-editor
              (cancel)='onCancelCreateItem()'
              (close)='onCreateIncome(state.income)'
              (update)='state.income = $event;'
              [externalError]='state.externalError'>
            </qa-fi-income-editor>
          </ng-container>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</form>

<ng-template #formerEmploymentDateTemplate let-employment='employment'>
  <span class='text-muted'>
    For {{ calculateEmploymentDurationAsString(employment) }}
  </span>

  <qa-more-popover #popover [content]='formerEmploymentDatePopoverTemplate'
                   [outsideClick]='false'>
  </qa-more-popover>

  <ng-template #formerEmploymentDatePopoverTemplate>
    <qa-fi-employment-date
      (cancel)='popover.hide()'
      (save)='onSaveEmploymentDate(employment, $event, popover)'
      [employment]='employment'>
    </qa-fi-employment-date>
  </ng-template>
</ng-template>
