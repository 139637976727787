<form #borrowingEntityForm="ngForm" *ngIf="shouldShowEntityForm()">
  <div class="grid-layout has-border">
    <div class="grid-header">
      <div class="grid-header-left">
        <div>Entity Info</div>
      </div>
      <div class="grid-header-right">

      </div>
    </div>
  </div>
  <div class="grid-layout has-border mb-3">

    <!-- LimitedLiabilityCorporation -->

    <div class="grid-layout-row">
      <!-- First Name -->
      <urla-text-input [(ngModel)]='mortgage.extension.borrowingEntity.entityName' [inEditMode]='inEditMode'
        [name]="'entityName'" [readonly]='isReadOnly'
        [required]='isRequired("mortgage.extension.borrowingEntity.entityName")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-sm-6' fieldBeingEdited='mortgage.extension.borrowingEntity.entityName'
        label='Entity Name'></urla-text-input>

      <urla-text-input [(ngModel)]='mortgage.extension.borrowingEntity.entityTaxIdentifier' [inEditMode]='inEditMode'
        [name]="'entityTaxIdentifier'" [readonly]='isReadOnly'
        [required]='isRequired("mortgage.extension.borrowingEntity.entityTaxIdentifier")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-3'
        fieldBeingEdited='mortgage.extension.borrowingEntity.entityTaxIdentifier'
        label='Entity Tax Identifier'></urla-text-input>

      <urla-dropdown (change)='onEntityTypeChanged()' [(ngModel)]='mortgage.extension.borrowingEntity.entityType'
        [inEditMode]='inEditMode' [name]="'entityType'" [ngClass]="{'bg-transparent': !isReadOnly}"
        [options]='borrowerTypeOptions' [readonly]='isReadOnly'
        [required]="isRequired('mortgage.extension.borrowingEntity.entityType')" [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-sm-3' fieldBeingEdited='mortgage.extension.borrowingEntity.entityType'
        label='Entity Type'></urla-dropdown>
    </div>
    <div class="grid-layout-row">
      <!-- First Name -->

      <urla-text-input [(ngModel)]='mortgage.extension.borrowingEntity.filingLocation' [inEditMode]='inEditMode'
        [name]="'filingLocation'" [readonly]='isReadOnly'
        [required]='isRequired("mortgage.extension.borrowingEntity.filingLocation")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-6'
        fieldBeingEdited='mortgage.extension.borrowingEntity.filingLocation' label='Filing Location'></urla-text-input>

      <urla-text-input [(ngModel)]='mortgage.extension.borrowingEntity.stateOfOrganization' [inEditMode]='inEditMode'
        [name]="'stateOfOrganization'" [readonly]='isReadOnly'
        [required]='isRequired("mortgage.extension.borrowingEntity.stateOfOrganization")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-3'
        fieldBeingEdited='mortgage.extension.borrowingEntity.stateOfOrganization' label='State Of Filing'></urla-text-input>

      <urla-date-input [(ngModel)]='mortgage.extension.borrowingEntity.dateOfOrgFiling' [inEditMode]='inEditMode'
        [name]="'dateOfOrgFiling'" [readonly]='isReadOnly'
        [required]='isRequired("mortgage.extension.borrowingEntity.dateOfOrgFiling")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-3'
        fieldBeingEdited='mortgage.extension.borrowingEntity.dateOfOrgFiling'
        label='Date of Org Filing'></urla-date-input>
    </div>

    <div class="group-container-repeat">
      <urla-address [isReadOnly]="isReadOnly" [addressLabel]="'Entity Address'" [zipcodeLookupEnabled]="true"
        [address]="mortgage.extension.borrowingEntity.entityAddress" [showAllCountries]="true" [inEditMode]="inEditMode"
        [urlaFieldsConfig]="urlaFieldsConfig" (addressChanged)="onEntityAddressChanged($event)"
        [fieldBeingEdited]="'mortgage.extension.borrowingEntity.entityAddress'">
      </urla-address>
      <urla-address [isReadOnly]="isReadOnly" [addressLabel]="'Entity Mailing Address'" [zipcodeLookupEnabled]="true"
        [address]="mortgage.extension.borrowingEntity.entityMailingAddress" [showAllCountries]="true"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
        [copyButtonVisible]="!!mortgage.extension.borrowingEntity.entityAddress?.address1"
        [copyTitleText]="'Copy From Entity Address'" (copyClicked)="onEntityAddressCopyClicked()"
        [fieldBeingEdited]="'mortgage.extension.borrowingEntity.entityMailingAddress'">
      </urla-address>
    </div>

    <div class="grid-layout-row">
      <urla-text-input [(ngModel)]='mortgage.extension.borrowingEntity.signingPartyName' [inEditMode]='inEditMode'
        [name]="'signingPartyName'" [readonly]='isReadOnly'
        [required]='isRequired("mortgage.extension.borrowingEntity.signingPartyName")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-6'
        fieldBeingEdited='mortgage.extension.borrowingEntity.signingPartyName'
        label='Signing Party Name'></urla-text-input>

      <urla-dropdown
        class="col-md-3 col-sm-6"
        [readonly]="isReadOnly"
        [name]="'signingPartyTitle'"
        label='Signing Party Title'
        [options]="signingPartyTitles"
        [inEditMode]="inEditMode"
        [urlaFieldsConfig]='urlaFieldsConfig'
        fieldBeingEdited='mortgage.extension.borrowingEntity.signingPartyTitle'
        [required]='isRequired("mortgage.extension.borrowingEntity.signingPartyTitle")'
        [(ngModel)]='mortgage.extension.borrowingEntity.signingPartyTitle'
      ></urla-dropdown>
    </div>
    <div class="group-container-repeat">

      <div class="grid-layout-row" *ngIf="limitExceeded || minFirstMemberRequired">
        <urla-grid-cell class="w-100">
          <span class="invalid-feedback d-block">
            <span  *ngIf="limitExceeded">The ownership % should be at least 25% for each person and the total between persons cannot exceed 100%.</span>
            <span *ngIf="minFirstMemberRequired"> At least 1 member is required.</span>
          </span>
        </urla-grid-cell>
      </div>

      <div class="grid-layout-row">
        <urla-text-input [(ngModel)]='mortgage.extension.borrowingEntity.member1.name'
          (ngModelChange)="onMemberNameChanged()" [inEditMode]='inEditMode' [name]="'member1.name'"
          [readonly]='isReadOnly' [required]='isRequired("mortgage.extension.borrowingEntity.member1.name")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-6'
          fieldBeingEdited='mortgage.extension.borrowingEntity.member1.name' label='Member 1 Name'></urla-text-input>

        <urla-digits-input [(ngModel)]='mortgage.extension.borrowingEntity.member1.ownershipPercent'
          (ngModelChange)="onOwnershipPercentageChanged()" [inEditMode]='inEditMode'
          [name]="'member1.ownershipPercentage'" [readonly]='isReadOnly'
          [required]='isRequired("mortgage.extension.borrowingEntity.member1.ownershipPercentage")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-6'
          fieldBeingEdited='mortgage.extension.borrowingEntity.member1.ownershipPercentage'
          label='Ownership Percentage'></urla-digits-input>
      </div>

      <div class="grid-layout-row">
        <urla-text-input [(ngModel)]='mortgage.extension.borrowingEntity.member2.name'
          (ngModelChange)="onMemberNameChanged()" [inEditMode]='inEditMode' [name]="'member2.name'"
          [readonly]='isReadOnly' [required]='isRequired("mortgage.extension.borrowingEntity.member2.name")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-6'
          fieldBeingEdited='mortgage.extension.borrowingEntity.member2.name' label='Member 2 Name'></urla-text-input>

        <urla-digits-input [(ngModel)]='mortgage.extension.borrowingEntity.member2.ownershipPercent'
          (ngModelChange)="onOwnershipPercentageChanged()" [inEditMode]='inEditMode'
          [name]="'member2.ownershipPercentage'" [readonly]='isReadOnly'
          [required]='isRequired("mortgage.extension.borrowingEntity.member2.ownershipPercentage")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-6'
          fieldBeingEdited='mortgage.extension.borrowingEntity.member2.ownershipPercentage'
          label='Ownership Percentage'></urla-digits-input>
      </div>

      <div class="grid-layout-row">
        <urla-text-input [(ngModel)]='mortgage.extension.borrowingEntity.member3.name'
          (ngModelChange)="onMemberNameChanged()" [inEditMode]='inEditMode' [name]="'member3.name'"
          [readonly]='isReadOnly' [required]='isRequired("mortgage.extension.borrowingEntity.member3.name")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-6'
          fieldBeingEdited='mortgage.extension.borrowingEntity.member3.name' label='Member 3 Name'></urla-text-input>

        <urla-digits-input [(ngModel)]='mortgage.extension.borrowingEntity.member3.ownershipPercent'
          (ngModelChange)="onOwnershipPercentageChanged()" [inEditMode]='inEditMode'
          [name]="'member3.ownershipPercentage'" [readonly]='isReadOnly'
          [required]='isRequired("mortgage.extension.borrowingEntity.member3.ownershipPercentage")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-6'
          fieldBeingEdited='mortgage.extension.borrowingEntity.member3.ownershipPercentage'
          label='Ownership Percentage'></urla-digits-input>
      </div>

      <div class="grid-layout-row">
        <urla-text-input [(ngModel)]='mortgage.extension.borrowingEntity.member4.name'
          (ngModelChange)="onMemberNameChanged()" [inEditMode]='inEditMode' [name]="'member4.name'"
          [readonly]='isReadOnly' [required]='isRequired("mortgage.extension.borrowingEntity.member4.name")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-6'
          fieldBeingEdited='mortgage.extension.borrowingEntity.member4.name' label='Member 4 Name'></urla-text-input>

        <urla-digits-input [(ngModel)]='mortgage.extension.borrowingEntity.member4.ownershipPercent'
          (ngModelChange)="onOwnershipPercentageChanged()" [inEditMode]='inEditMode'
          [name]="'member4.ownershipPercentage'" [readonly]='isReadOnly'
          [required]='isRequired("mortgage.extension.borrowingEntity.member4.ownershipPercentage")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-sm-6'
          fieldBeingEdited='mortgage.extension.borrowingEntity.member4.ownershipPercentage'
          label='Ownership Percentage'></urla-digits-input>
      </div>
    </div>

  </div>
</form>
