import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { concat, firstValueFrom, Observable, Subscription } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/core/route-guards/pending-changes.guard';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { ApplicationContext, LoanApplication } from '../../../../../models';
import { cloneDeep } from 'lodash';
import { DiffChecker } from '../../../../../../utils/diff-checker';
import { showNotSavedDialog } from '../quick-apply-utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppDetailsService } from '../../../services/app-details.service';
import { NotificationService } from '../../../../../services/notification.service';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { NgForm } from '@angular/forms';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'qa-financial-info',
  templateUrl: './qa-financial-info.component.html',
})
export class QuickApplyFinancialInfoComponent
  extends ApplicationContextBoundComponent
  implements OnInit, AfterViewInit, ComponentCanDeactivate, OnDestroy {

  @ViewChild("qaFinancialInfoForm") qaFinancialInfoForm: NgForm;

  private _application: LoanApplication | null = null;
  protected mortgage: UrlaMortgage | null = null;
  private _pristineMortgage: UrlaMortgage | null = null;

  protected isSaving: boolean = false;

  protected isLoanReadOnly: boolean = false;

  private get _context$(): Observable<ApplicationContext> {
    const initialValue = (async () => {
      const hasApplication = this.applicationContext?.application != null;
      if (hasApplication) {
        this.isLoanReadOnly = this.applicationContext.applicationIsReadOnly;
        return this.applicationContext;
      }

      const context$ = this.applicationContextService.context;

      return firstValueFrom(context$);
    })();

    return concat(
      initialValue,
      this.applicationContextService.loanInfoChanges,
    );
  }
  private _contextChangesSubscription: Subscription | null = null;

  private _statusChangeSubscriptions: Subscription[] = [];

  constructor(
    injector: Injector,
    private readonly _appContextService: ApplicationContextService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _notificationService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _menuService: MenuService
  ) {
    super(injector);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.subscribeToContextChanges();
    setTimeout(() => {
      if (this.qaFinancialInfoForm) {
        const subscription = this.qaFinancialInfoForm.valueChanges.subscribe(
          () => {
            this._menuService.setStatus('qaFinancialInfo', this._menuService.getStatusForFinancialInfo(this.mortgage));
          }
        );
        this._statusChangeSubscriptions.push(subscription);
      }
    }, 200);
  }

  ngOnDestroy() {
    this._contextChangesSubscription?.unsubscribe();
    this._statusChangeSubscriptions.forEach(s => s?.unsubscribe());
    super.ngOnDestroy();
  }

  private subscribeToContextChanges(): void {
    this._contextChangesSubscription?.unsubscribe();
    this._contextChangesSubscription = this._context$.subscribe((context) => {
      const application = context.application;
      if (!application) {
        return;
      }
      this._application = application;
      this.mortgage = cloneDeep(application.mortgageLoan);
      this._pristineMortgage = cloneDeep(application.mortgageLoan);
    });
  }

  canDeactivate = (): boolean | Observable<boolean> => {
    // don't check when session expires
    if (!this.applicationContext) {
      return true;
    }

    return !this.isDirty();
  };

  confirm(): boolean | Observable<boolean> {
    return showNotSavedDialog(() => this.save());
  }

  private isDirty(): boolean {
    const diffChecker = new DiffChecker(
      this._pristineMortgage,
      this.mortgage,
      'mortgage',
      { floatPrecision: 2 },
    );

    const diff = diffChecker.calculateDiff(true);
    return diff != null;
  }

  protected async setIsSaving(value: boolean): Promise<void> {
    this.isSaving = value;
    const spinnerService = this._spinnerService;

    if (value) {
      await spinnerService.show();
    } else {
      await spinnerService.hide();
    }
  }

  protected async save(): Promise<boolean> {
    await this.setIsSaving(true);

    // This is the original mortgage loan that we want to restore if the update
    // fails.
    const application = this._application;
    const mortgageFallback = application.mortgageLoan;

    try {
      application.mortgageLoan = this.mortgage;

      const loanInfo = {
        application,
        customData: null,
      };

      const response = await firstValueFrom(
        this._appDetailsService.saveLoanInfo(
          application.applicationId,
          loanInfo,
        ),
      );

      const applicationResponse = response.application;

      this._appContextService.updateMortgageAndApplication(
        applicationResponse.mortgageLoan,
        applicationResponse,
      );

      this._notificationService.showSuccess(
        'The financial info has been saved successfully.',
        'Success',
      );

      return true;
    } catch (e) {
      // Restore the original mortgage loan.
      application.mortgageLoan = mortgageFallback;

      console.error(e);
      const message = e?.message
        || 'An error occurred while saving the financial info.';

      this._notificationService.showError(
        message,
        'Error',
      );

      return false;
    } finally {
      await this.setIsSaving(false);
    }
  }

  protected invalidateDirtyState(): void {
    this._pristineMortgage = cloneDeep(this.mortgage);
  }
}
