import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginCompanyPickerDialogComponent } from 'src/app/components/login-company-picker-dialog/login-company-picker-dialog.component';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { AuthenticationUserCompany } from 'src/app/models/auth/authentication-response.model';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/services/constants';
import { MentionEvent, MentionsService } from 'src/app/services/mentions.service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { AlertsCreatedEvent, AlertsService } from 'src/app/services/alerts.service';
import { forkJoin, Subscription } from 'rxjs';
import { AlertModel, AlertType } from 'src/app/models/alert.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { WizardUrl } from 'src/app/models';
import { copyToClipboard } from 'src/utils';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.scss'],
})
export class HeaderActionsComponent implements OnDestroy, OnInit {

  @ViewChild("mentions")
  mentionsDrawer: DrawerComponent;

  @Input() user;

  @Input()
  unreadConversations: boolean;

  @Output()
  mentionsClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  niceInContactClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  dialpadClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  notificationsClicked: EventEmitter<Notifications> = new EventEmitter<Notifications>();

  @Output()
  alertsCreated: EventEmitter<AlertModel[]> = new EventEmitter<AlertModel[]>();

  @Output()
  conversationsToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  companies: AuthenticationUserCompany[] = [];

  areNotificationsVisible = false;

  alertsAndNotifications: Notifications = new Notifications();

  unreadMentionCount: number = 0;

  searchText: string = '';

  searchMode: string = 'Contains';

  loading = false;

  mentionsDrawerIsVisible: boolean = false;
  applicationMode: ApplicationMode;

  companyId: number;
  wizardUrls: WizardUrl[] = [];
  restrictFromEditingProfile: boolean = true;
  currentUserIsPrimaryRoleUser: boolean = false;
  isSuperAdmin: boolean = false;
  niceInContactEnabled: boolean = true;

  private _currentRoute: string;

  private _routerEventSubscription: any;

  private _applicationContextSubscription: Subscription;

  private _alertsSubscription: Subscription;
  private _mentionsSubscription: Subscription;

  constructor(
    private readonly _alertsService: AlertsService,
    private readonly _authService: AuthService,
    private readonly _navigationService: NavigationService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _modalService: NgbModal,
    private readonly _router: Router,
    private readonly _spinner: NgxSpinnerService,
    private readonly _mentionsService: MentionsService,
    private readonly _notifyService: NotificationService,
    private readonly _applicationContentextService: ApplicationContextService
  ) {
    this.applicationMode = this._navigationService.applicationMode;
    this._applicationContextSubscription = this._applicationContentextService.context.subscribe(context => {
      this.companyId = context.userPermissions.companyId;
      this.wizardUrls = context.userPermissions.onlineApplications?.wizardUrls.filter(x => x.isDefault) ?? [];
      this.currentUserIsPrimaryRoleUser = context.globalConfig.firstRole?.roleId == context.userPermissions.roleId;
      this.isSuperAdmin = context.userPermissions.superAdmin;
      this.restrictFromEditingProfile = context.userPermissions.restrictFromEditingProfile;
    });
    this._alertsSubscription = this._alertsService.events.subscribe((event: AlertsCreatedEvent) => {
      if (!event) {
        return;
      }
      this.alertsCreated.emit(event.alerts);
    });

    this._mentionsSubscription = this._mentionsService.events.subscribe((event: MentionEvent) => {
      if (!event) return;
      if (event.type === "reload") {
        setTimeout(this.getUnreadMentionsCount, 1000);
      }
    })

    this.alertsAndNotifications = this.initializeAlertsAndNotifications();

    this._routerEventSubscription = this._router.events.subscribe(
      (event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this._currentRoute = event.url;
        }
      }
    );
  }

  ngOnInit() {
    this.loading = true;

    const authData = this._localStorageService.authorizationData;
    this.companies = authData.companies;

    this.getAlerts([])
  }

  ngOnDestroy(): void {
    if (this._routerEventSubscription) {
      this._routerEventSubscription.unsubscribe();
    }
    if (this._applicationContextSubscription) {
      this._applicationContextSubscription.unsubscribe();
    }
    if (this._alertsSubscription) {
      this._alertsSubscription.unsubscribe();
    }
    if (this._mentionsSubscription) {
      this._mentionsSubscription.unsubscribe();
    }
  }

  copyLink = (wizardUrl: WizardUrl) => {
    copyToClipboard(wizardUrl.url);
    this._notifyService.showInfo("Link copied to clipboard", "Info");
  }

  onSupportLinkClicked = () => {
    window.open("https://lodasoft.atlassian.net/servicedesk/customer/portals", "_blank");
  }

  showMentions() {
    this.mentionsClicked.emit(true);
  }

  showNiceInContact(activeTab: string) {
    this.niceInContactClicked.emit(activeTab);
  }

  showDialpad() {
    this.dialpadClicked.emit();
  }

  showNotifications() {
    this.areNotificationsVisible = false;
    this.notificationsClicked.emit(this.alertsAndNotifications);
  }

  showConversations = () => {
    this.conversationsToggled.emit(true);
  }

  onSearchIconClicked = () => {
    setTimeout(() => {
      let searchBox = document.getElementById("searchInput");
      searchBox.focus();
    });
  }

  onLogoutClicked = () => {
    this._spinner.show();
    this._authService.logout().subscribe(
      () => {
        this._spinner.hide();
      },
      (error) => {
        this._spinner.hide();
      }
    );
  };

  onGotoNextGenClicked = () => {
    if (this._currentRoute.indexOf('loda-nextgen') === -1) {
      this._navigationService.applicationMode = ApplicationMode.NextGen;
      const route = this._currentRoute.replace('admin', 'loda-nextgen');
      this._router.navigateByUrl(route);
    }
    else {
      this._navigationService.applicationMode = ApplicationMode.Classic;
      const route = this._currentRoute.replace('loda-nextgen', 'admin');
      this._router.navigateByUrl(route);
    }
  };

  onSwitchCompanyClicked = () => {
    const modalRef = this._modalService.open(
      LoginCompanyPickerDialogComponent,
      Constants.modalOptions.medium
    );
    modalRef.componentInstance.availableCompanies = this.companies;
  };

  search = () => {
    if (this.searchText != '') {
      const url = "admin/search?param=" + encodeURIComponent(this.searchText) + "&searchMode=" + this.searchMode;
      this._router.navigateByUrl(url);

      this.searchText = '';
    }
  }

  private initializeAlertsAndNotifications = (): Notifications => {
    const alertsAndNotifications = new Notifications();

    const borrowerMessagesBucket = new AlertBucket("Borrower Messages", AlertType.BorrowerMessageAlert, 1501);
    const taskStatusChangesBucket = new AlertBucket("Task Status Changes", AlertType.TaskStatusAlert, 1502);
    const notificationsBucket = new AlertBucket("Misc Notifications", AlertType.GeneralAlert, 1503);
    const loanStatusChangesBucket = new AlertBucket("Loan Status Changes", AlertType.LoanStatusAlert, 1504);
    const emailAlertsBucket = new AlertBucket("Failed E-mails", AlertType.FailedEmailAlert, 1505);
    const smsAlertsBucket = new AlertBucket("Failed SMS", AlertType.FailedSmsAlert, 1506);
    const newLeadAlertsBucket = new AlertBucket("New Leads", AlertType.NewLeadAlert, 1507);
    const borrowerPrequalBucket = new AlertBucket("Borrower Generated Prequal", AlertType.BorrowerGeneratedPrequal, 1509);
    const agentPrequalBucket = new AlertBucket("Agent Generated Prequal", AlertType.AgentGeneratedPrequal, 1510);
    const ocrSuccessBucket = new AlertBucket("OCR Completed Success", AlertType.OCRCompleted_Success, 1511);
    const OcrFailureBucket = new AlertBucket("OCR Completed Failure", AlertType.OCRCompleted_Failure, 1512);
    const TridAlertBucket = new AlertBucket("TRID Triggers", AlertType.TridHasBeenTriggered, 1513);

    alertsAndNotifications.alertBuckets.push(newLeadAlertsBucket);
    alertsAndNotifications.alertBuckets.push(notificationsBucket);
    alertsAndNotifications.alertBuckets.push(loanStatusChangesBucket);
    alertsAndNotifications.alertBuckets.push(taskStatusChangesBucket);
    alertsAndNotifications.alertBuckets.push(borrowerPrequalBucket);
    alertsAndNotifications.alertBuckets.push(agentPrequalBucket);
    alertsAndNotifications.alertBuckets.push(ocrSuccessBucket);
    alertsAndNotifications.alertBuckets.push(OcrFailureBucket);
    alertsAndNotifications.alertBuckets.push(borrowerMessagesBucket); //TODO: remove me when conversations works fully
    alertsAndNotifications.alertBuckets.push(emailAlertsBucket);
    alertsAndNotifications.alertBuckets.push(smsAlertsBucket);
    alertsAndNotifications.alertBuckets.push(TridAlertBucket);

    return alertsAndNotifications;
  }

  // TODO: adjust to v1
  private getAlerts(data) {
    this.getUnreadMentionsCount();
    this.fillAlertsBuckets(this.alertsAndNotifications);
  }

  // TODO: adjust to v1
  private _updateAlerts(data) {

  }

  private fillAlertsBuckets = (notifications: Notifications) => {
    const apiCalls: any[] = [];
    notifications.alertBuckets.forEach(b => {
      const apiCall = this._alertsService.getAlertsByType(b.typeId);
      apiCalls.push(apiCall);
    });

    if (apiCalls.length) {
      forkJoin(apiCalls).subscribe(results => {
        results.forEach((alerts: AlertModel[], index) => {
          const bucketToFill = notifications.alertBuckets[index];
          if (bucketToFill.type === AlertType.FailedEmailAlert || bucketToFill.type === AlertType.FailedSmsAlert) {
            alerts = alerts.filter((a: AlertModel) => !a.isRead)
          }
          bucketToFill.alerts = alerts;
        })
      }, errors => {

      });
    }
  }

  private getUnreadMentionsCount = () => {
    this._mentionsService.getMentionsCount().subscribe(counts => {
      this.unreadMentionCount = counts.unreadMentionCount ?? 0;
    });
  }
}

export class Notifications {

  alertBuckets: AlertBucket[] = [];

  getAlertsInBucketsOfTypes = (types: AlertType[]) => {
    const buckets = this.alertBuckets.filter(b => types.includes(b.type));
    const allAlerts = buckets.reduce((r, { alerts }) => [...r, ...alerts], []);
    return allAlerts;
  }

  get totalAlertCount(): number {
    let totalAlertCount: number = this.alertBuckets.map(b => b.alerts.length).reduce((a, b) => a + b);
    return totalAlertCount;
  }
}

export class AlertBucket {
  name: string;
  type: AlertType;
  typeId: number;
  alerts: AlertModel[] = [];

  constructor(name: string, type: AlertType, typeId: number) {
    this.name = name;
    this.type = type;
    this.typeId = typeId;
  }
}
