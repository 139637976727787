import { Component, EventEmitter, Input, OnInit, Output, Injector } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Liability } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import Swal from 'sweetalert2';
import { UtilityService } from '../../services/utility.service';
import * as _ from 'lodash';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { MortgageCalculationService } from '../../services/mortgage-calculation.service';
import { Subscription } from 'rxjs';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnterPayoffDialogComponent } from './enter-payoff-dialog/enter-payoff-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'liabilities',
  templateUrl: './liabilities.component.html',
  styleUrls: ['./liabilities.component.scss'],
  viewProviders: [formViewProvider]
})
export class LiabilitiesComponent extends ApplicationContextBoundComponent implements OnInit {

  private _mortgage: UrlaMortgage;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;

    if (!this.liabilityTypeHELOC || !this.liabilityTypeMortgageLoan) {
      this.liabilityTypeHELOC = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC);
      this.liabilityTypeMortgageLoan = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan);
    }
    this.initializePossibleAccountOwners();
    this.initializeLiabilities()
  }

  @Input()
  isReadOnly: boolean = false;

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  liabilityTypes: EnumerationItem[];

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Output()
  subTotalChange: EventEmitter<any> = new EventEmitter();

  liabilityTypeOptions: EnumerationItem[];

  borrowers = [];

  liabilities: Array<Liability> = null;

  editedLiability: Liability;

  possibleReoAddresses = [];

  possibleAccountOwners: Array<{
    id: number;
    text: string;
  }> = [];

  liabilityTypeHELOC;

  liabilityTypeMortgageLoan;

  liabilityTypeCollections;

  financialMonthlyPaymentSubTotal: number;

  financialUnpaidBalanceSubTotal: number;

  financialPartialPayoffAmount: number;

  liabilityEventSubscription: Subscription;

  thereIsAtLeastOneInvalidLineItem: boolean = false;

  monthlyPaymentOptions = [];

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
  };

  addEditLiabilityDrawerOptions: DrawerOptions = {
    size: DrawerSize.Medium,
    containerWrapperId: null
  }

  constructor(
    private readonly injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _utilityService: UtilityService,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _drawerService: DrawerService,
    private readonly _modalService: NgbModal) {
    super(injector);
    this.liabilityEventSubscription = this._calculationService.liabilitySubject.subscribe(() => {
      this.possibleReoAddresses = [];
      this.liabilities.forEach(liability => {
        if (liability.typeOfLiability == this.liabilityTypeHELOC ||
          liability.typeOfLiability == this.liabilityTypeMortgageLoan) {
          this.populateReoAddresses(liability);
        }
      });
    });
  }

  ngOnInit(): void {
    this.liabilityTypeOptions = this.liabilityTypes.filter(liability =>
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Alimony) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildSupport) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.JobRelatedExpenses) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.SeparateMaintenanceExpense) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.CarMaintenance) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.CharitableContributions) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildCare) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Clothing) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.DryCleaning) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Entertainment) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.GroceryToiletry) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HealthInsurance) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Medical) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MiscellaneousLivingExpenses) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.NetRentalExpense) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.PayrollInsuranceDeduction) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.PayrollMiscellaneousDeductions) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.PayrollProfitSharingDeduction) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.PayrollRetirementDeduction) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.PayrollTaxDeduction) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.UnionDues) &&
      liability.value !== this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.OtherExpense)
    );

    this.liabilityTypeHELOC = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC);
    this.liabilityTypeMortgageLoan = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan);
    this.liabilityTypeCollections = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.CollectionsJudgementsAndLiens);

    this.calculateSubTotals();
  }

  calculateSubTotals = () => {
    let subTotals = {
      monthlyPaymentSubTotal: 0,
      unpaidBalanceSubTotal: 0,
      partialPayoffAmount: 0
    }

    this.liabilities.forEach(liability => {
      if (!liability.isExcluded) {
        if (liability.payoffType !== this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
          subTotals.monthlyPaymentSubTotal += Number(liability.monthlyPayment) || 0;
        }
        subTotals.unpaidBalanceSubTotal += Number(liability.unpaidBalance) || 0;
        subTotals.partialPayoffAmount += Number(liability.partialPayoffAmount) || 0;
      }
    });

    this.financialMonthlyPaymentSubTotal = subTotals.monthlyPaymentSubTotal;
    this.financialUnpaidBalanceSubTotal = subTotals.unpaidBalanceSubTotal;
    this.financialPartialPayoffAmount = subTotals.partialPayoffAmount;

    this.subTotalChange.emit(subTotals);
    this.mortgage.calculatedStats.financialPartialPayoffAmount = subTotals.partialPayoffAmount;
    this.mortgage.calculatedStats.financialPartialPayoffTotalAmount = this._calculationService.calculateFinancialPartialPayoffTotalAmount(this.mortgage);
    this.mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this.mortgage);
    this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);
  }

  getBorrowerName = (borrowerId) => {
    let borrower = this.possibleAccountOwners.find(owner => owner.id == borrowerId);
    if (borrower && borrower.text)
      return borrower.text;
    return '';
  }

  deleteLiability = (deleteIx: number) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        let liability = self.liabilities[deleteIx];
        if (liability) {
          let indexInMortgageLiabilities = self.mortgage.liabilities.indexOf(liability);
          if (indexInMortgageLiabilities >= 0) {
            self.mortgage.liabilities.splice(indexInMortgageLiabilities, 1);
          } else {
            if (self.mortgage.realEstateOwned && self.mortgage.realEstateOwned.length > 0) {
              self.mortgage.realEstateOwned.forEach(reo => {
                if (reo.liabilities && reo.liabilities.length > 0) {
                  var indexInReoLiabilities = reo.liabilities.indexOf(liability);
                  if (indexInReoLiabilities >= 0) {
                    reo.liabilities.splice(indexInReoLiabilities, 1);
                  }
                }
              });
            }
          }
        }
        self.liabilities.splice(deleteIx, 1);
        self.calculateSubTotals();
        self.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
        self._calculationService.sendReoEvent();
        const oneWithEmptyAccountOwnersIndex = self.liabilities.findIndex(a => !a.owningBorrowerIds.length);
        self.thereIsAtLeastOneInvalidLineItem = oneWithEmptyAccountOwnersIndex >= 0;
      }
    });
  }

  setPayoffTypeAndAmount = (liability: Liability, isInit?: boolean) => {
    if (isInit) return; // dont change types coming from backend

    if (_.isUndefined(liability.partialPayoffAmount) || _.isUndefined(liability.unpaidBalance))
      return;

    if (liability.partialPayoffAmount == null || liability.partialPayoffAmount == 0) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.None);
    }
    else if (Number(liability.unpaidBalance) > Number(liability.partialPayoffAmount)) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial);
    }
    else if (Number(liability.partialPayoffAmount) == Number(liability.unpaidBalance)) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full);
    }
    this.calculateSubTotals();
    this.mortgage.calculatedStats.totalPaidOffForRefinance = this._calculationService.calculateTotalPayOffForRefinance(this.mortgage);
  }

  addLiability = () => {
    let liability = new Liability();
    liability.liabilityId = this._utilityService.getUniqueId();
    liability.owningBorrowerIds = this.possibleAccountOwners && this.possibleAccountOwners.length == 1 ?
      [this.possibleAccountOwners[0].id] : [];
    this.setLiabilityOwnerNames(liability);
    this.editedLiability = _.cloneDeep(liability);
    this._drawerService.show("addEditLiabilityDrawer", 100);
  };

  accountTypeOrBorrowerChange = (liability: Liability) => {
    if (liability.typeOfLiability == this.liabilityTypeHELOC ||
      liability.typeOfLiability == this.liabilityTypeMortgageLoan) {
      this.populateReoAddresses(liability);
    }
    this.thereIsAtLeastOneInvalidLineItem = !liability.owningBorrowerIds.length;
    this.setLiabilityOwnerNames(liability);
  }

  saveLiability = (liability: Liability) => {
    liability['displayAccountNumber'] = ( liability.accountNumber && liability.accountNumber.length > 5 ) ? '...' + liability.accountNumber.slice(liability.accountNumber.length-5, liability.accountNumber.length) : (liability.accountNumber);
    let index = this.liabilities.findIndex(l => l.liabilityId == liability.liabilityId);
    if (index >= 0) {
      this.liabilities[index] = liability;
    } else {
      this.liabilities.push(liability);
    }

    this.setLiabilityOwnerNames(liability);
    this.setPayoffTypeAndAmount(liability);

    this.calculateSubTotals();

    index = this.liabilities.findIndex(l => l.liabilityId == liability.liabilityId);
    let indexInMainList = this.mortgage.liabilities.findIndex(l => l.liabilityId == liability.liabilityId);
    if (indexInMainList >= 0) {
      this.mortgage.liabilities[indexInMainList] = liability;
    } else {
      this.mortgage.liabilities.push(liability);
    }

    this.populateReoAddresses(this.liabilities[index]);
    if (liability.typeOfLiability == this.liabilityTypeHELOC ||
      liability.typeOfLiability == this.liabilityTypeMortgageLoan) {
      this.reoSelectionChanged(liability);
    }
    this._calculationService.sendReoEvent();
    this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
  }

  closeDrawer = () => {
    this._drawerService.hide("addEditLiabilityDrawer", 100);
  }

  openPayoffDialog = (liability: Liability) => {
    const modalRef = this._modalService.open(EnterPayoffDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.partialPayoffAmount = liability.partialPayoffAmount;
    modalRef.componentInstance.unpaidBalance = liability.unpaidBalance;

    modalRef.result.then((result) => {
      if (!result) {
        result = 0;
      }
      liability.partialPayoffAmount = result;
      this.setPayoffTypeAndAmount(liability);
      this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
      if (this.editedLiability &&  this.editedLiability.liabilityId == liability.liabilityId) {
        this.editedLiability.partialPayoffAmount = liability.partialPayoffAmount;
        this.editedLiability.payoffType = liability.payoffType;
      }
    });
  }

  setPayoffAmountToFull = (liability: Liability) => {
    liability.partialPayoffAmount = liability.unpaidBalance;
    liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full);
    this.mortgage.calculatedStats.totalPaidOffForRefinance = this._calculationService.calculateTotalPayOffForRefinance(this.mortgage);
    this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
    this.calculateSubTotals();
    if (this.editedLiability &&  this.editedLiability.liabilityId == liability.liabilityId) {
      this.editedLiability.partialPayoffAmount = liability.partialPayoffAmount;
      this.editedLiability.payoffType = liability.payoffType;
    }
  }

  setPayoffAmountToNone = (liability: Liability) => {
    liability.partialPayoffAmount = 0;
    liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.None);
    this.mortgage.calculatedStats.totalPaidOffForRefinance = this._calculationService.calculateTotalPayOffForRefinance(this.mortgage);
    this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
    this.calculateSubTotals();
    if (this.editedLiability &&  this.editedLiability.liabilityId == liability.liabilityId) {
      this.editedLiability.partialPayoffAmount = liability.partialPayoffAmount;
      this.editedLiability.payoffType = liability.payoffType;
    }
  }

  editLiability = (liability, liabilityIx) => {
    this.editedLiability = _.cloneDeep(liability);
    this._drawerService.show("addEditLiabilityDrawer", 100);
  }

  populateReoAddresses = (liability: Liability, liabilities: Liability[] = null) => {
    let liabilityIx = liabilities ? liabilities.indexOf(liability) : this.liabilities.indexOf(liability);
    if (this.mortgage.realEstateOwned && this.mortgage.realEstateOwned.length > 0) {
      this.mortgage.realEstateOwned.forEach(reo => {
        liability.owningBorrowerIds.forEach(borrowerId => {
          if (reo.owningBorrowerIds.indexOf(Number(borrowerId)) >= 0) {
            if (!this.possibleReoAddresses[liabilityIx]) {
              this.possibleReoAddresses[liabilityIx] = [];
            }
            let exists = this.possibleReoAddresses[liabilityIx].find(p => p.value == reo.reoId);
            if (!exists) {
              this.possibleReoAddresses[liabilityIx].push(new EnumerationItem(reo.address1, reo.reoId));
            }
          }
        });
      });
    }
  }

  reoSelectionChanged = (liability) => {
    // First of all, if this liability was linked to any REOs before, that link needs to be severed.
    if (this.mortgage.realEstateOwned && this.mortgage.realEstateOwned.length > 0) {
      this.mortgage.realEstateOwned.forEach(reo => {
        if (reo.liabilities) {
          let linkedLiabilityIndex = reo.liabilities.indexOf(liability);
          if (linkedLiabilityIndex >= 0) {
            reo.liabilities.splice(linkedLiabilityIndex, 1);
          }
        }
      });
    }
    let reoId = liability.reoId;
    // If the liability did not get linked to an REO (nothing selected for REO), put liability into mortgage.liabilities if not already there
    if (!reoId) {
      if (!this.mortgage.liabilities.find(l => l.liabilityId == liability.liabilityId)) {
        this.mortgage.liabilities.push(liability);
      }
    }

    if (reoId < 0) {
      // This means the liability just got related to a new REO which did not exist before.
      // We need to remove the liability from mortgage.liabilities array and stick it to the reo.liabilities.
      let reo = this.mortgage.realEstateOwned.find(r => r.reoId == reoId);
      if (reo) {
        let index = this.mortgage.liabilities.indexOf(liability);
        if (index >= 0) {
          this.mortgage.liabilities.splice(index, 1);
          if (!reo.liabilities) {
            reo.liabilities = [];
          }
          reo.liabilities.push(liability);
        }
      }
    }

    //summaryDataService.calculateCountOfMtgsReos();
  }

  isFilteredLiability = (liabilityType: string) => {
    return liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Alimony) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildSupport) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildCare) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.JobRelatedExpenses) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.SeparateMaintenanceExpense) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.OtherExpense);
  }

  exclude = (liability: Liability, isExcluded: boolean) => {
    liability.isExcluded = isExcluded;
    this.setPayoffTypeAndAmount(liability);
    this.calculateSubTotals();
    this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
    if (this.editedLiability &&  this.editedLiability.liabilityId == liability.liabilityId) {
      this.editedLiability.isExcluded = isExcluded;
    }
  }

  initializeLiabilities = () => {
    const liabilities = [];
    if (this._mortgage.liabilities) {
      this._mortgage.liabilities.forEach(liability => {
        liability['displayAccountNumber'] = ( liability.accountNumber && liability.accountNumber.length > 5 ) ? '...' + liability.accountNumber.slice(liability.accountNumber.length-5, liability.accountNumber.length) : (liability.accountNumber);
        this.setPayoffTypeAndAmount(liability, true);

        if (liability.payoffType == this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full)) {
          liability.partialPayoffAmount = liability.unpaidBalance;
        }
        if (this.isFilteredLiability(liability.typeOfLiability)) {
          let borrIds = [];
          liability['owners'] = [];
          liability.owningBorrowerIds.forEach(borrowerId => {
            borrIds.push(borrowerId.toString());
            liability['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(borrowerId) })
          });
          liability.owningBorrowerIds = borrIds;
          liabilities.push(liability);
          if (liability.typeOfLiability == this.liabilityTypeHELOC ||
            liability.typeOfLiability == this.liabilityTypeMortgageLoan) {
            this.populateReoAddresses(liability, liabilities);
          }
        }
      });
      this.liabilities = liabilities;
    }
  }

  initializePossibleAccountOwners = () => {
    this.possibleAccountOwners = [];
    if (this._mortgage.borrowers) {
      this._mortgage.borrowers.forEach(borrower => {
        const borrowerFullName = this._utilityService.getBorrowerFullName(borrower);
        let borrowerOption = new EnumerationItem(borrowerFullName, borrower.borrowerId);
        this.borrowers.push(borrowerOption);

        const possibleAccountOwner = {
          id: borrower.borrowerId,
          text: borrowerFullName
        }
        this.possibleAccountOwners.push(possibleAccountOwner);
      });
    }
  }

  setMonthlyPaymentOptions = (liability: Liability) => {
    this.monthlyPaymentOptions = this._calculationService.setMonthlyPaymentOptions(liability);
  };

  setMonthlyPayment = (liability: Liability, percent: number) => {
    this._calculationService.setMonthlyPayment(liability, percent);
    if (this.editedLiability &&  this.editedLiability.liabilityId == liability.liabilityId) {
      this.editedLiability.monthlyPayment = liability.monthlyPayment;
    }
  }

  private setLiabilityOwnerNames = (liability: Liability) => {
    liability['owners'] = [];
    liability.owningBorrowerIds.forEach(borrowerId => {
      liability['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(borrowerId) });
    });
  }
}
