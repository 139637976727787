<div class="modal-header">
  <h5 class="modal-title"> REO Expense Breakdown </h5>
  <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #breakdownForm="ngForm" novalidate id="breakdownForm" name="breakdownForm" style="padding-left: 60px;">
    <div class="loan-app">
      <div class="grid-layout edit-area">
        <div class="grid-layout-row breakdown-row">
          <div class="form-check col-5">
            <input class="form-check-input" type="radio" name="enterTotalValue" id="enterTotalValue" [value]="false"
              [(ngModel)]="isBrokenDown"
              (ngModelChange)="onIsBrokenDownChanged()">
            <label class="form-check-label radio-label" for="enterTotalValue">
              Enter Total Misc. Expenses:
            </label>
          </div>
          <urla-currency-input class="col-6 breakdown-input" *ngIf="!isBrokenDown"
              name="supplementalPropertyInsurance" [label]="''" [required]="true"
              [(ngModel)]="reo.monthlyMiscExpenses"
              (ngModelChange)="onEnteredTotalChanged()">
            </urla-currency-input>
          <span class="col-6 breakdown-readonly" *ngIf="isBrokenDown">
            {{ !reo.monthlyMiscExpenses ? '--' : (reo.monthlyMiscExpenses | currency :'USD':'symbol':'1.2-2') }}
          </span>
        </div>
        <div class="grid-layout-row">
          <div class="form-check col-4">
            <input class="form-check-input" type="radio" name="breakItDown" id="breakItDown" [value]="true"
            [(ngModel)]="isBrokenDown"
            (ngModelChange)="onIsBrokenDownChanged()">
            <label class="form-check-label radio-label" for="breakItDown">
              Or Break It Down
            </label>
          </div>
          <div class="breakdown-section">
            <div class="row breakdown-row">
              <div class="col-7">
                Other Financing
              </div>
              <div class="col-4">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="otherFinance"
                  [label]="''" [(ngModel)]="reo.otherFinance" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
            </div>
            <div class="row breakdown-row">
              <div class="col-7">
                Hazard Insurance
              </div>
              <div class="col-4">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="hazardInsurance"
                  [label]="''" [(ngModel)]="reo.hazardInsurance" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
            </div>
            <div class="row breakdown-row">
              <div class="col-7">
                Property Tax
              </div>
              <div class="col-4">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="propertyTax"
                  [label]="''" [(ngModel)]="reo.propertyTax" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
            </div>
            <div class="row breakdown-row">
              <div class="col-7">
                Mortgage Insurance
              </div>
              <div class="col-4">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="mortgageInsurance"
                  [label]="''" [(ngModel)]="reo.mortgageInsurance" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
            </div>
            <div class="row breakdown-row">
              <div class="col-7">
                HOA Dues
              </div>
              <div class="col-4">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="hoa"
                  [label]="''" [(ngModel)]="reo.hoa" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
            </div>
            <div class="row breakdown-row">
              <div class="col-7">
                Other Expenses
              </div>
              <div class="col-4">
                <urla-currency-input class="breakdown-input" *ngIf="isBrokenDown" name="otherMonthlyExpense"
                  [label]="''" [(ngModel)]="reo.otherMonthlyExpense" (ngModelChange)="onBreakdownItemChanged()">
                </urla-currency-input>
                <span class="col-6 breakdown-readonly" *ngIf="!isBrokenDown">
                    --
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      Monthly Total: <b>{{ total | currency :'USD':'symbol':'1.2-2' }}</b>
    </div>
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;">
        <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()">Cancel</button>
        <button type="button" class="btn btn-primary right" (click)="onOkClicked()">OK</button>
      </div>
    </div>
  </div>
