import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';
import { Liability } from 'src/app/models/mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import {
  EnterPayoffDialogComponent,
} from 'src/app/modules/urla/financial-information/liabilities/enter-payoff-dialog/enter-payoff-dialog.component';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import {
  MortgageCalculationService,
} from 'src/app/modules/urla/services/mortgage-calculation.service';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import Swal from 'sweetalert2';
import { InvalidateableComponent } from '../../invalidateable-component';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { resetArrayTo } from '../qa-fi-income/reset-object.to';

@Component({
  selector: 'qa-fi-liabilities',
  templateUrl: './qa-fi-liabilities.component.html',
  styleUrls: [
    './qa-fi-liabilities.component.scss',
    '../../styles/expansion-panel-table-utils.scss',
  ],
})
export class QaFiLiabilitiesComponent implements OnInit, OnDestroy, InvalidateableComponent {
  @Output()
  readonly invalidate = new EventEmitter<void>();

  private _mortgage: UrlaMortgage;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;

    // Lock the operation until the enums are initialized, as it is dependent on them.
    this._enumsInitialized$.pipe(
      takeUntil(this._destroyed$),
    ).subscribe({
      complete: () => {
        this.initializeLiabilityTypes();
        this.initializePossibleAccountOwners();
        this.initializeLiabilities();

        this.clearCreatingItemId();
      },
    });
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  doesNotApply: boolean = false;
  expandedItems: { [key: number]: boolean } = {};
  hoveredItems: { [key: number]: boolean } = {};
  selectedRows: { [key: number]: boolean } = {};
  existingItems: { [key: number]: boolean } = {};

  possibleAccountOwners: Array<{
    id: number;
    text: string;
  }> = [];
  liabilityTypes: EnumerationItem[] = [];
  otherLiabilityTypes: EnumerationItem[] = [];
  allLiabilityTypes: EnumerationItem[] = [];
  lienPositionTypes: EnumerationItem[] = [];
  mortgageAppliedForTypes: EnumerationItem[] = [];
  states: EnumerationItem[];

  liabilities: Liability[] = null;

  possibleReoAddresses = [];

  liabilityTypeHELOC: string;
  liabilityTypeMortgageLoan: string;
  private _liabilityTypeOrders: Map<string, number> = new Map<string, number>();

  financialMonthlyPaymentSubTotal: number;
  financialUnpaidBalanceSubTotal: number;
  financialPartialPayoffAmount: number;
  omittedAmount: number;

  monthlyPaymentOptions = [];

  liabilityEventSubscription: Subscription;

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
  };

  private creatingItemId: number | null = null;
  protected get isCreatingItem(): boolean {
    return this.creatingItemId != null;
  }

  private readonly _enumsInitialized$ = new BehaviorSubject<boolean>(false);
  private readonly _destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _utilityService: UtilityService,
    private readonly _modalService: NgbModal,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _contextService: ApplicationContextService
  ) {
    this.liabilityEventSubscription = this._calculationService.liabilitySubject.subscribe((liability) => {
      if (liability) {
        const index = this.liabilities.findIndex(l => l.liabilityId == liability.liabilityId);
        if (index > -1) {
          this.liabilities[index].reoId = liability.reoId;
        }
      } else {
        this.possibleReoAddresses = [];
        this.liabilities.forEach(l => {
          if (l.typeOfLiability == this.liabilityTypeHELOC ||
            l.typeOfLiability == this.liabilityTypeMortgageLoan) {
            this.populateReoAddresses(l);
          }
        });
      }

    });
  }

  ngOnInit(): void {
    this.initializeEnums();
    this.initializeLiabilityTypes();

    this.calculateSubTotals();
    this.invalidate.emit();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private initializeEnums(): void {
    if (this._enumsInitialized$.value) {
      throw new Error('The enums have already been initialized.');
    }

    const enumsService = this._enumsService;

    this.states = enumsService.states;

    enumsService.getMortgageEnumerations().pipe(
      takeUntil(this._destroyed$),
    ).subscribe({
      next: (enums) => {
        const enumsService = this._enumsService;

        let types = enums[Constants.enumerations.liabilityTypes];
        this.lienPositionTypes = enums[Constants.mortgageEnumerations.lienPositionType];
        this.mortgageAppliedForTypes = enums[Constants.mortgageEnumerations.mortgageAppliedForType];

        this.liabilityTypes = types.filter(createLiabilityItemFilter(enumsService));
        this.otherLiabilityTypes = types.filter(createOtherLiabilityItemFilter(enumsService));

        this.allLiabilityTypes = [
          ...this.liabilityTypes.map(t => {
            t.groupName = 'Credits, Debts, and Leases';
            return t;
          }),
          ...this.otherLiabilityTypes.map(t => {
            t.groupName = 'Other Liabilities and Expenses';
            return t;
          }),
        ];

        this._enumsInitialized$.next(true);
      },
      complete: () => this._enumsInitialized$.complete(),
    });
  }

  private initializeLiabilityTypes() {
    const { LiabilityType } = Constants.enumerationValueNames;
    const getEnumValue = (value: string) => this._enumsService.getEnumValue(value);

    this.liabilityTypeHELOC = getEnumValue(LiabilityType.HELOC);
    this.liabilityTypeMortgageLoan = getEnumValue(LiabilityType.MortgageLoan);
    this._liabilityTypeOrders = new Map<string, number>([
      LiabilityType.MortgageLoan,
      LiabilityType.HELOC,
      LiabilityType.Revolving,
      LiabilityType.OtherLiability,
      LiabilityType.CollectionsJudgementsAndLiens,
    ].map((value, index) => ([getEnumValue(value), index])));
  }

  haveMultipleRowSelected = () => {
    return Object.keys(this.selectedRows).filter(r => this.selectedRows[r]).length > 0;
  }

  private clearCreatingItemId(): void {
    this.creatingItemId = null;
  }

  private onCollapseItem(collapsedItemId: number): void {
    if (this.creatingItemId === collapsedItemId) {
      this.clearCreatingItemId();
    }
  }

  protected onChangeLiability(newLiability: Liability): void {
    this.saveLiability(newLiability);
  }

  private saveLiability(newLiability: Liability) {
    let index = this.liabilities.findIndex(a => a.liabilityId == newLiability.liabilityId);

    this.liabilities[index] = newLiability;

    let indexInMainList = this.mortgage.liabilities.findIndex(l => l.liabilityId == newLiability.liabilityId);
    if (indexInMainList >= 0) {
      this.mortgage.liabilities[indexInMainList] = newLiability;
    } else {
      this.mortgage.liabilities.push(newLiability);
    }

    this.populateReoAddresses(this.liabilities[index]);
    if (newLiability.typeOfLiability == this.liabilityTypeHELOC ||
      newLiability.typeOfLiability == this.liabilityTypeMortgageLoan) {
      this.reoSelectionChanged(newLiability);
    }
    this.setPayoffTypeAndAmount(this.liabilities[index]);
    this._calculationService.sendReoEvent();
    this._contextService.mortgageIncomeOrLiabilitiesChanged();

    this.calculateSubTotals();
    this.sortLiabilities();
    this.updateMortgageLiability();
  }

  private updateMortgageLiability(): void {
    resetArrayTo(this.mortgage.liabilities, this.liabilities);
  }

  private calculateSubTotals(): void {
    const subTotals = this._calculationService.calculateLiabilitySubTotal(this.mortgage);

    this.financialMonthlyPaymentSubTotal = subTotals.monthlyPaymentSubTotal;
    this.financialUnpaidBalanceSubTotal = subTotals.unpaidBalanceSubTotal;
    this.financialPartialPayoffAmount = subTotals.partialPayoffAmount;
    this.omittedAmount = subTotals.omittedAmount;

    this.mortgage.calculatedStats.financialPartialPayoffAmount = subTotals.partialPayoffAmount;
    this.mortgage.calculatedStats.financialPartialPayoffTotalAmount = this._calculationService.calculateFinancialPartialPayoffTotalAmount(this.mortgage);
    this.mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this.mortgage);
    this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);
  }

  protected onClickCloseLiability(liabilityId: number): void {
    this.collapseLiability(liabilityId);
  }

  protected onClickCancelLiability(liabilityId: number): void {
    this.collapseLiability(liabilityId);

    const isExisting = this.existingItems[liabilityId];
    if (!isExisting) {
      this.removeLiability(liabilityId);
    }

    this.calculateSubTotals();
  }

  private removeLiability(liabilityId: number): void {
    this.liabilities = this.liabilities.filter(l => l.liabilityId != liabilityId);
    this.updateMortgageLiability();
  }

  protected toggleExpandLiability(liabilityId: number): void {
    if (this.expandedItems[liabilityId]) {
      this.collapseLiability(liabilityId);
    } else {
      this.expandLiability(liabilityId);
    }
  }

  private expandLiability(liabilityId: number): void {
    this.expandedItems[liabilityId] = true;
  }

  private collapseLiability(liabilityId: number): void {
    this.expandedItems[liabilityId] = false;
    this.onCollapseItem(liabilityId);
  }

  addLiabilityClicked = () => {
    let liability = new Liability();
    liability.liabilityId = this._utilityService.getUniqueId();
    liability.owningBorrowerIds = this.possibleAccountOwners && this.possibleAccountOwners.length == 1 ?
      [this.possibleAccountOwners[0].id] : [];

    this.liabilities = [...this.liabilities, liability];
    this.sortLiabilities();
    this.expandedItems[liability.liabilityId] = true;
    this.existingItems[liability.liabilityId] = false;
    this.creatingItemId = liability.liabilityId;
  }

  bulkDeleteClicked = () => {
    let selectedLiabilityIds = Object.keys(this.selectedRows).filter(r => this.selectedRows[r]);
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this ' + selectedLiabilityIds.length + ' selected records?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result: any) => {
      if (result.value) {

        selectedLiabilityIds.forEach(id => {
          delete this.expandedItems[id];
          delete this.selectedRows[id];
          delete this.hoveredItems[id];
          this.liabilities.splice(this.liabilities.findIndex(a => a.liabilityId == Number(id)), 1);
        });
        // We shouldn't need to do sort the liabilities here as removing the
        // items from the array should not change the order of the remaining
        // items.

        this._mortgage.liabilities = [...this.liabilities];
        this.calculateSubTotals();

        // close all expanded rows
        this.liabilities.forEach(a => {
          this.expandedItems[a.liabilityId] = false;
        });

      }
    });
  }

  setMonthlyPaymentOptions = (liability: Liability) => {
    this.monthlyPaymentOptions = this._calculationService.setMonthlyPaymentOptions(liability);
  };

  setMonthlyPayment = (liability: Liability, percent: number) => {
    this._calculationService.setMonthlyPayment(liability, percent);
  }

  accountTypeOrBorrowerChange = (liability: Liability) => {
    if (liability.typeOfLiability == this.liabilityTypeHELOC ||
      liability.typeOfLiability == this.liabilityTypeMortgageLoan) {
      this.populateReoAddresses(liability);
    }
    this.sortLiabilities();
  }

  onAccountOwnersChanged = (liability: Liability) => {
    this.accountTypeOrBorrowerChange(liability);
  }

  openPayoffDialog = (liability: Liability) => {
    const modalRef = this._modalService.open(EnterPayoffDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.partialPayoffAmount = liability.partialPayoffAmount;
    modalRef.componentInstance.unpaidBalance = liability.unpaidBalance;

    modalRef.result.then((result) => {
      if (!result) {
        result = 0;
      }
      liability.partialPayoffAmount = result;
      this.setPayoffTypeAndAmount(liability);
      this._contextService.mortgageIncomeOrLiabilitiesChanged();
      this.calculateSubTotals();
    });
  }

  setPayoffAmountToFull = (liability: Liability) => {
    liability.partialPayoffAmount = liability.unpaidBalance;
    liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full);
    this.mortgage.calculatedStats.totalPaidOffForRefinance = this._calculationService.calculateTotalPayOffForRefinance(this.mortgage);
    this._contextService.mortgageIncomeOrLiabilitiesChanged();
    this.calculateSubTotals();
  }

  setPayoffAmountToNone = (liability: Liability) => {
    liability.partialPayoffAmount = 0;
    liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.None);
    this._contextService.mortgageIncomeOrLiabilitiesChanged();
    this.calculateSubTotals();
  }

  excludeChanged = () => {
    this.calculateSubTotals();
    this._contextService.mortgageIncomeOrLiabilitiesChanged();
  }

  private reoSelectionChanged = (liability) => {
    // First of all, if this liability was linked to any REOs before, that link needs to be severed.
    if (this.mortgage.realEstateOwned && this.mortgage.realEstateOwned.length > 0) {
      this.mortgage.realEstateOwned.forEach(reo => {
        if (reo.liabilities) {
          let linkedLiabilityIndex = reo.liabilities.findIndex(l => l.liabilityId == liability.liabilityId);
          if (linkedLiabilityIndex >= 0) {
            reo.liabilities.splice(linkedLiabilityIndex, 1);
          }
        }
      });
    }
    let reoId = liability.reoId;
    // If the liability did not get linked to an REO (nothing selected for REO), put liability into mortgage.liabilities if not already there
    if (!reoId) {
      if (!this.mortgage.liabilities.find(l => l.liabilityId == liability.liabilityId)) {
        this.mortgage.liabilities.push(liability);
      }
    }

    if (reoId < 0) {
      // This means the liability just got related to a new REO which did not exist before.
      // We need to remove the liability from mortgage.liabilities array and stick it to the reo.liabilities.
      let reo = this.mortgage.realEstateOwned.find(r => r.reoId == reoId);
      if (reo) {
        let index = this.mortgage.liabilities.findIndex(l => l.liabilityId == liability.liabilityId);
        if (index >= 0) {
          this.mortgage.liabilities.splice(index, 1);
          if (!reo.liabilities) {
            reo.liabilities = [];
          }
          reo.liabilities.push(liability);
        }
      }
    }

    //summaryDataService.calculateCountOfMtgsReos();
  }

  private setPayoffTypeAndAmount = (liability: Liability) => {
    if (_.isUndefined(liability.partialPayoffAmount) || _.isUndefined(liability.unpaidBalance))
      return;

    if (liability.partialPayoffAmount == null || liability.partialPayoffAmount == 0) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.None);
    }
    else if (Number(liability.unpaidBalance) > Number(liability.partialPayoffAmount)) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial);
    }
    else if (Number(liability.partialPayoffAmount) == Number(liability.unpaidBalance)) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full);
      this.mortgage.calculatedStats.totalPaidOffForRefinance = this._calculationService.calculateTotalPayOffForRefinance(this.mortgage);
    }
  }

  private isFilteredLiability = (liabilityType: string) => {
    return true;
    return liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Alimony) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildSupport) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildCare) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.JobRelatedExpenses) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.SeparateMaintenanceExpense) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.OtherExpense);
  }

  private populateReoAddresses = (liability: Liability, liabilities: Liability[] = null) => {
    let liabilityIx = liabilities ? liabilities.indexOf(liability) : this.liabilities.indexOf(liability);
    if (this.mortgage.realEstateOwned && this.mortgage.realEstateOwned.length > 0) {
      this.mortgage.realEstateOwned.forEach(reo => {
        liability.owningBorrowerIds.forEach(borrowerId => {
          if (reo.owningBorrowerIds.map(id => Number(id)).indexOf(Number(borrowerId)) >= 0) {
            if (!this.possibleReoAddresses[liabilityIx]) {
              this.possibleReoAddresses[liabilityIx] = [];
            }
            let exists = this.possibleReoAddresses[liabilityIx].find(p => p.value == reo.reoId);
            if (!exists) {
              this.possibleReoAddresses[liabilityIx].push(new EnumerationItem(reo.address1, reo.reoId));
            }
          }
        });
      });
    }
  }

  private initializeLiabilities = () => {
    const liabilities = [];
    if (this._mortgage.liabilities) {
      this._mortgage.liabilities.forEach(liability => {
        this.setPayoffTypeAndAmount(liability);

        if (this.isFilteredLiability(liability.typeOfLiability)) {
          let borrIds = [];
          liability.owningBorrowerIds.forEach(borrowerId => {
            borrIds.push(borrowerId);
          });
          liability.owningBorrowerIds = borrIds;
          liabilities.push(liability);
          if (liability.typeOfLiability == this.liabilityTypeHELOC ||
            liability.typeOfLiability == this.liabilityTypeMortgageLoan) {
            this.populateReoAddresses(liability, liabilities);
          }
        }
      });
      this.liabilities = liabilities;
      this.sortLiabilities();
    }
  }

  /**
   * Sorts the liabilities by the order of the liability types in the
   * {@link _liabilityTypeOrders} array.
   */
  private sortLiabilities() {
    this.liabilities.sort((a, b) => {
      const aIndex = this._liabilityTypeOrders.get(a.typeOfLiability);
      const bIndex = this._liabilityTypeOrders.get(b.typeOfLiability);

      // If both types of liabilities are not in the ordered list, keep them
      // as they are.
      if (aIndex == null && bIndex == null) {
        return 0;
      }

      // If the type of the next liability is not in the ordered list, put this
      // before the next.
      if (aIndex == null) {
        return 1;
      }

      // If the type of the current liability is not in the ordered list, put
      // this after the next.
      if (bIndex == null) {
        return -1;
      }

      return aIndex - bIndex;
    });
  }

  private initializePossibleAccountOwners = () => {
    this.possibleAccountOwners = [];
    if (this._mortgage.borrowers !== null) {
      this._mortgage.borrowers.forEach(borrower => {
        const borrowerFullName = this._utilityService.getBorrowerFullName(borrower);

        const possibleAccountOwner = {
          id: borrower.borrowerId,
          text: borrowerFullName
        };
        this.possibleAccountOwners.push(possibleAccountOwner);
      });
    }
  }


}

const getOtherLiabilityTypes = (enumsService: EnumerationService) => {
  const getEnumValue = (value: string) => enumsService.getEnumValue(value);
  const { LiabilityType } = Constants.enumerationValueNames;

  return [
    LiabilityType.Alimony,
    LiabilityType.ChildSupport,
    LiabilityType.JobRelatedExpenses,
    LiabilityType.SeparateMaintenanceExpense,
    LiabilityType.CarMaintenance,
    LiabilityType.CharitableContributions,
    LiabilityType.ChildCare,
    LiabilityType.Clothing,
    LiabilityType.DryCleaning,
    LiabilityType.Entertainment,
    LiabilityType.GroceryToiletry,
    LiabilityType.HealthInsurance,
    LiabilityType.Medical,
    LiabilityType.MiscellaneousLivingExpenses,
    LiabilityType.NetRentalExpense,
    LiabilityType.PayrollInsuranceDeduction,
    LiabilityType.PayrollMiscellaneousDeductions,
    LiabilityType.PayrollProfitSharingDeduction,
    LiabilityType.PayrollRetirementDeduction,
    LiabilityType.PayrollTaxDeduction,
    LiabilityType.UnionDues,
    LiabilityType.OtherExpense,
  ].map(getEnumValue);
}

const createLiabilityItemFilter = (enumsService: EnumerationService) => {
  const liabilityTypes = new Set(getOtherLiabilityTypes(enumsService));

  return ({ value }: EnumerationItem) => !liabilityTypes.has(value);
};

const createOtherLiabilityItemFilter = (enumsService: EnumerationService) => {
  const liabilityTypes = new Set(getOtherLiabilityTypes(enumsService));

  return ({ value }: EnumerationItem) => liabilityTypes.has(value);
};
