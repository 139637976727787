import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Borrower, LoanApplication, UserPermissions } from 'src/app/models';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { MenuService } from 'src/app/services/menu.service';
import Swal from 'sweetalert2';
import { LoanService } from 'src/app/services/loan';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import * as feather from 'feather-icons';
import { TransactionType } from 'src/app/models/config/loan-purpose.model';
import { firstValueFrom, Subscription } from 'rxjs';
import { MenuItemStatus } from 'src/app/modules/tpo/models/enums/menu-item-status.enum';
import { ConversationService } from 'src/app/modules/conversations/services/conversation.service';
import { ApplicationMenu, ApplicationMenuItem, ApplicationMenuItemDb } from '../../../../../services/application-menu';
import { MenuItemComponent } from 'src/app/modules/tpo/components/menu-item/menu-item.component';
import { TimerService } from 'src/app/core/services/timer.service';
import { DateTime } from 'luxon';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';

@Component({
  selector: 'app-details-menu',
  templateUrl: 'app-details-menu.component.html',
  styleUrls: ['app-details-menu.component.scss']
})
export class AppDetailsMenuComponent extends ApplicationContextBoundComponent implements OnInit, OnChanges {

  @Input()
  permissions: UserPermissions;

  @Input()
  set application(application: LoanApplication) {
    this.initApplication(application);
  }

  get application(): LoanApplication {
    return this._application;
  }

  @Input()
  borrowerId: number;

  @Input()
  borrowers: Borrower[];

  @Input()
  isTpoUser: boolean;

  @Input()
  menuHorizontal: boolean;

  @Input()
  secondPageActionBarVisible: boolean = false;

  @Output()
  generateDocClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  viewEsignatureHistoryClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  setAppointmentClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  sendEmailSmsClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  menuItemClicked: EventEmitter<string> = new EventEmitter<string>();

  @ViewChildren(MenuItemComponent) menuItems:
    | QueryList<MenuItemComponent>
    | undefined;

  protected readonly ApplicationMenuItemDb = ApplicationMenuItemDb;
  protected readonly MenuItemStatus = MenuItemStatus;

  loanId: number;

  applicationMode: string;

  borrowerName: string;

  tab: string;

  loanPurposeTransactionType: TransactionType;
  mortgageType: string;

  protected applicationMenu: ApplicationMenu;
  protected get isAppMenuLoading(): boolean {
    return this.applicationMenu == null;
  }

  protected isAmortizationTableMenuItemVisible: boolean = false;
  protected pricingVendorsAreOnlyIframes: boolean = false;

  protected companyId: number;

  private _application: LoanApplication;

  private _routerEventSubscription: Subscription;
  private _loanInfoChangesSubscription: Subscription
  private _callControlPanelChangesSubscription: Subscription;
  private _navigationEventsSubscription: Subscription;
  private _menuItemStatusSubscription: Subscription;

  constructor(
    injector: Injector,
    private readonly _conversationService: ConversationService,
    private readonly _navigationService: NavigationService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _menuService: MenuService,
    private readonly _loanService: LoanService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifsService: NotificationService,
    private readonly _timerService: TimerService,
  ) {
    super(injector);
    this._spinner.show();
    this.secondPageActionBarVisible = this.applicationContext.isCallControlPanelOpen;
    this._callControlPanelChangesSubscription = this.applicationContextService.callControlPanelStatChanges.subscribe(context => {
      this.secondPageActionBarVisible = context.isCallControlPanelOpen;
    })
    this._routerEventSubscription = this._router.events.subscribe(
      (event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this._getInitTab();
        }
      }
    );

    this._menuItemStatusSubscription = this._menuService.menuItemStatusChanged.subscribe(e => {
      const menuItem = this.menuItems.find(mi => mi.id === e.id);
      if (menuItem) {
        menuItem.status = e.status;
      }
    });

    this.pricingVendorsAreOnlyIframes = this.checkIfPricingVendorsAreOnlyIframesOrNot();
  }

  ngOnInit() {
    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
      this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';

    const borrower = this.borrowers?.find(b => b.borrowerId === this.borrowerId);
    this.borrowerName = `${borrower?.firstName || ""} ${borrower?.lastName || ""}`;

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(context => {
      if (context.currentMortgage) {
        this.mortgageType = context.currentMortgage.mortgageTerm?.mortgageAppliedFor;
        this.initApplication(context.application);
      }
    });

    this.companyId = this.applicationContext.application.companyId;

    this.mortgageType = this.applicationContext.currentMortgage.mortgageTerm?.mortgageAppliedFor;
    this.isAmortizationTableMenuItemVisible = !!this.applicationContext.application.productPricing?.rate;

    (<any>$('#metismenu')).metisMenu();
    feather.replace();

    this._navigationEventsSubscription = this._activatedRoute.params.subscribe(params => {
      this._getInitTab();
    });

    this._menuService.setStatus('cocDetails', this._menuService.getStatusForLoanEstimateClosingDisclosures(this.application.mortgageLoan));
  }

  ngOnChanges() {
    let allLoanPurposes = this.applicationContext.globalConfig.loanPurpose;
    this.loanPurposeTransactionType = allLoanPurposes.find(x => x.loanPurposeId == this.application.loanPurposeId)?.transactionType;
    this._getInitTab();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._routerEventSubscription?.unsubscribe();
    this._navigationEventsSubscription?.unsubscribe();
    this._loanInfoChangesSubscription?.unsubscribe();
    this._callControlPanelChangesSubscription?.unsubscribe();
    this._menuItemStatusSubscription?.unsubscribe();
  }

  private checkIfPricingVendorsAreOnlyIframesOrNot = (): boolean => {
    const pricingEngines = this.applicationContext.userPermissions.enabledPricingEngines;
    let thereIsAtLeastOneVendorThatIsNotIframe = false;
    if (pricingEngines && pricingEngines.length > 0) {
      pricingEngines.forEach(pe => {
        if (pe !== PricingVendor.LenderPriceIframe && pe !== PricingVendor.LoanPassIframe) {
          thereIsAtLeastOneVendorThatIsNotIframe = true;
        }
      });
    }
    return !thereIsAtLeastOneVendorThatIsNotIframe;
  }

  private initApplication(application: LoanApplication): Promise<void> {
    const appIdChanged = this._application?.applicationId
      !== application?.applicationId;

    this._application = application;
    this.isAmortizationTableMenuItemVisible = !!application?.productPricing?.rate;

    return (this.application && appIdChanged)
      ? this.initRoleBasedMenuItems()
      : Promise.resolve();
  }

  private async initRoleBasedMenuItems(): Promise<void> {
    try {
      const applicationMenu = await firstValueFrom(
        this._loanService.getAppMenu(this.application.applicationId),
      );
      this.applicationMenu = applicationMenu;
    } catch (e) {
      console.error(e);
      const message = e?.message
        || e?.error?.message
        || 'Error loading application menu';
      this._notifsService.showError(message, 'Error');
    }
  }

  protected getMenuItem(
    { id }: { id: string, [_k: string]: any },
  ): ApplicationMenuItem | undefined {
    return this.applicationMenu?.getItemById(id);
  }

  onMenuItemClicked = (e: any, tabToActivate: string) => {
    if (this.tab === tabToActivate) {
      e.preventDefault();
      return;
    }
    this.menuItemClicked.emit(this.tab);
    this.tab = tabToActivate;
  }

  onUrlaMenuItemClicked = (menuItemId: string) => {
    this.tab = 'urla';
    setTimeout(() => {
      this._menuService.onMenuItemClicked(menuItemId);
    });
  }

  confirmConvertToMismo34Application = () => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to convert this application to MISMO 3.4? This action is NOT reversable!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        self._spinner.show();
        self._loanService.convertToMismo34Application(self.application.applicationId).subscribe(() => {
          self.application.isMismo34Application = true;
          self._notifsService.showSuccess("Successfully converted the application to MISMO 3.4.", "Success!");
        }, error => {
          const errorMessage = error && error.message ? error.message : "An error occurred converting application to MISMO 3.4.";
          self._notifsService.showError(errorMessage, "Error!");
        }).add(() => self._spinner.hide())
      }
    });
  };

  isLoanSummaryBarVisible = (): boolean => {
    return false; //TODO - Implement this!
  }

  borrowerChat = () => {
    this._conversationService.addBorrowerChat(this.application.applicationId, this.borrowerId, { senderDisplayName: this.borrowerName }, true);
  }

  onSendEmailSmsClicked = () => {
    this.sendEmailSmsClicked.emit();
  }

  onSetAppointmentClicked = () => {
    this.setAppointmentClicked.emit();
  }

  onGenerateDocClicked = () => {
    this.generateDocClicked.emit();
  }

  onViewEsignatureHistoryClicked = () => {
    this.viewEsignatureHistoryClicked.emit();
  }

  private _getInitTab = () => {
    const splitUrl = this._router.url.split("/");

    // init page
    setTimeout(() => {
      if (splitUrl.length < 5) {
        this.tab = 'loan-summary'
      } else {
        const pageType = splitUrl[splitUrl.length - 1];
        this.tab = pageType ? pageType : 'loan-summary';
      }
    })
  }
}
