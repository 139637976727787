import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThirdPartyCredential, ThirdPartyKeyValue, ThirdPartyKeyValueArray } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';

import { finalize, Subscription } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { EnumType } from '../../../../../../models/loan-pass/enum-type.model';
import { LoanPassFieldMappingsService, LoanPassMappingType } from '../../../../../../services/loan-pass-field-mappings.service';
import { RepriceFieldsDialogComponent } from '../reprice-fields-dialog/reprice-fields-dialog.component';
import { FieldMappingsDialogComponent } from './field-mappings-dialog/field-mappings-dialog.component';
import { LoanpassCustomFieldsDialogComponent } from './loanpass-custom-fields-dialog/loanpass-custom-fields-dialog.component';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';

const API_KEY_KEY = 'ApiKey';
const DEFAULT_PRICING_PROFILE_ID_KEY = 'DefaultPricingProfileId';
const ALLOW_USER_ACCOUNTS_KEY = 'AllowUserAccounts';
const FORCE_USER_ACCOUNTS_KEY = 'ForceUserAccounts';

@Component({
  selector: 'loanpass-vendor-editor-dialog',
  templateUrl: 'loanpass-vendor-editor-dialog.component.html',
  styleUrls: ['./loanpass-vendor-editor-dialog.component.scss'],
})
export class LoanPassVendorEditorDialogComponent implements OnDestroy {
  @Input()
  inEditMode: boolean = true;

  protected apiKeyItem: ThirdPartyKeyValue = new ThirdPartyKeyValue(API_KEY_KEY);
  protected defaultPricingProfileIdItem: ThirdPartyKeyValue = new ThirdPartyKeyValue(DEFAULT_PRICING_PROFILE_ID_KEY);
  protected pricingProfileOptions: EnumType[] | null = null;
  protected allowUserAccountsItem: ThirdPartyKeyValue = new ThirdPartyKeyValue(ALLOW_USER_ACCOUNTS_KEY);
  protected forceUserAccountsItem: ThirdPartyKeyValue = new ThirdPartyKeyValue(FORCE_USER_ACCOUNTS_KEY);

  private _fetchPricingProfileOptionsSubscription?: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _loanPassFieldMappingsService: LoanPassFieldMappingsService,
  ) {
  }

  private _vendor: ThirdPartyCredential;

  get vendor(): ThirdPartyCredential {
    return this._vendor;
  }

  @Input() set vendor(value: ThirdPartyCredential) {
    this._vendor = value;

    const initItem = findOrAddThirdPartyItem(this._vendor?.thirdPartyKeyValuePairs ?? []);
    this.apiKeyItem = initItem(API_KEY_KEY);
    this.defaultPricingProfileIdItem = initItem(DEFAULT_PRICING_PROFILE_ID_KEY);
    this.allowUserAccountsItem = initItem(ALLOW_USER_ACCOUNTS_KEY);
    this.forceUserAccountsItem = initItem(FORCE_USER_ACCOUNTS_KEY);

    this.fetchPricingProfileOptions();
  }

  private static deleteEmptyThirdPartyItems(thirdPartyKeyValuePairs: any[]) {
    const deleteEmptyItem = deleteEmptyThirdPartyItem(thirdPartyKeyValuePairs);
    deleteEmptyItem(API_KEY_KEY);
    deleteEmptyItem(DEFAULT_PRICING_PROFILE_ID_KEY);
    deleteEmptyItem(ALLOW_USER_ACCOUNTS_KEY);
    deleteEmptyItem(FORCE_USER_ACCOUNTS_KEY);
  }

  onFieldsMappingClicked = (mappingType: LoanPassMappingType) => {
    const modalRef = this._modalService.open(FieldMappingsDialogComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.credentialId = this._vendor.credentialId;
    modalRef.componentInstance.mappingType = mappingType;
  }

  onCustomFieldsMappingClicked = () => {
    const modalRef = this._modalService.open(LoanpassCustomFieldsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.credentialId = this._vendor.credentialId;
  }

  onResetClicked = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to reset all configuration?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }

      this._spinner.show();
      this._loanPassFieldMappingsService.deleteConfig(this.vendor.credentialId).subscribe({
        next: () => {
          this._spinner.hide();
          this._notificationService.showSuccess("Configuration has been successfully reset.",'Success!');
        },
        error: (err) => {
          this._spinner.hide();
          this._notificationService.showError(err?.message || 'An error has been encountered when reseting configuration', 'Error');
        }
      })


    });
  }

  ngOnDestroy() {
    this._fetchPricingProfileOptionsSubscription?.unsubscribe();
  }

  saveCredential = () => {
    const thirdPartyKeyValuePairs = [...(this._vendor.thirdPartyKeyValuePairs ?? [])];
    LoanPassVendorEditorDialogComponent.deleteEmptyThirdPartyItems(thirdPartyKeyValuePairs);
    const vendor = { ...this._vendor, thirdPartyKeyValuePairs };

    this._spinner.show();

    this._systemLevelService.saveCredential(vendor).pipe(
      finalize(() => this._spinner.hide()),
    ).subscribe({
      next: (result => {
        this.activeModal.close(result);
      }),
      error: (error => {
        this._notificationService.showError(error?.message || 'An error has been encountered when saving a credential', 'Error');
      }),
    });
  }

  onRepriceFieldsClicked = () => {
    const modalRef = this._modalService.open(RepriceFieldsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.credentialId = this._vendor.credentialId;
    modalRef.componentInstance.vendor = PricingVendor.LoanPass;
  }

  private fetchPricingProfileOptions() {
    this.pricingProfileOptions = null;

    this._fetchPricingProfileOptionsSubscription?.unsubscribe();
    this._fetchPricingProfileOptionsSubscription =
      this._loanPassFieldMappingsService.getLoanPassFields(this._vendor.credentialId)
        .subscribe({
          next: (response) => {
            this.pricingProfileOptions = response?.pricingProfiles ?? [];
          },
          error: (error) => {
            const message = 'An error has been encountered when fetching pricing profile options';
            console.error(message, error);

            this._notificationService.showError(message, 'Error');

            this.pricingProfileOptions = [];
          },
        });
  }
}

function findOrAddThirdPartyItem(thirdPartyKeyValuePairs: ThirdPartyKeyValueArray) {
  return function (key: ThirdPartyKeyValue['key']) {
    let item = thirdPartyKeyValuePairs.find(x => x.key === key);
    if (item == null) {
      item = new ThirdPartyKeyValue(key);
      thirdPartyKeyValuePairs.push(item);
    }
    return item;
  };
}

function deleteEmptyThirdPartyItem(thirdPartyKeyValuePairs: ThirdPartyKeyValueArray) {
  return function (key: ThirdPartyKeyValue['key']) {
    const index = thirdPartyKeyValuePairs.findIndex(x => x.key === key);
    if (index < 0) {
      return;
    }

    const item = thirdPartyKeyValuePairs[index];
    const value = item?.value;
    if (value == null || value === '') {
      thirdPartyKeyValuePairs.splice(index, 1);
    }
  };
}
