<div class="grid-layout has-border">
    <div class="grid-header">
      <div class="grid-header-left">
        <div>1c. Income</div>
      </div>
      <div class="grid-header-right"></div>
    </div>
</div>
<div class="grid-layout has-border">
    <div class="grid-layout-row" *ngIf="borrower.nonEmploymentIncomes && borrower.nonEmploymentIncomes[0]">
      <!-- Qualifying Income -->
      <urla-currency-input
        (change)="montlyIncomeChanged()"
        [(ngModel)]="borrower.nonEmploymentIncomes[0].monthlyIncome"
        [inEditMode]='inEditMode'
        [name]="'monthlyIncome' + borrower.borrowerId"
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.nonEmploymentIncomes.monthlyIncome")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-sm-6'
        fieldBeingEdited='mortgage.borrower.nonEmploymentIncomes.monthlyIncome'
        label='Qualifying Income'
      ></urla-currency-input>

    </div>
</div>