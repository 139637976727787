<!-- Will be deprecated, it's the one with no sections and here for backward compatibility -->
<div class="page-action-bar page-action-bar-sticky margin-bottom-10"*ngIf="!useActionBarSections && hasPageActionBar">
  <div class="page-action-bar" *ngIf="!useActionBarSections">
    <ng-container *ngTemplateOutlet="pageActionBarContentTemplate"></ng-container>
  </div>
</div>
<div class="page-action-bar-sticky">
  <div class="page-action-bar page-action-bar-sticky border-bottom-0 margin-bottom-0" *ngIf="hasPageActionBar" [hidden]="!hasSecondaryPageActionBar">
    <div class="d-flex flex-sm-column align-items-md-start">
      <call-control-panel-action-bar></call-control-panel-action-bar>
    </div>
  </div>

  <div class="page-action-bar margin-bottom-10 mb-xs-2" *ngIf="useActionBarSections && hasPageActionBar">
    <div class="d-flex flex-xs-column justify-content-xs-center text-xs-center mb-xs-2">
      <div class="page-action-bar-left d-flex"
        [ngClass]="{'page-action-bar-left-margin' : pageActionBarLeftContentTemplate}">
        <ng-container *ngTemplateOutlet="pageActionBarLeftContentTemplate"></ng-container>
        <div *ngIf="isSuperAdmin" class="d-flex align-items-center ms-2" style="font-weight: 500;">/  {{companyName}}</div>
      </div>
      <div class="ms-xs-0 ms-2 page-action-bar-middle flex-grow-1">
        <ng-container *ngTemplateOutlet="pageActionBarMiddleContentTemplate"></ng-container>
      </div>
      <div class="page-action-bar-right justify-content-xs-between text-xs-center mx-xs-2 align-items-xs-center"
        [ngClass]="{'page-action-bar-right-margin' : pageActionBarRightContentTemplate}">
        <ng-container *ngTemplateOutlet="pageActionBarRightContentTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>
<div class="page-wrapper">
  <div class="page-content">
    <div class="container-fluid p-1-u" [ngClass]="{'ps-0': isNiceInContactVisible}" id="drawer-wrapper">
      <ng-container *ngTemplateOutlet="pageMainContentTemplate"></ng-container>
    </div>
  </div>
</div>
