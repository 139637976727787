/**
 * Scroll to the top of the element after view checked by the change in the top 
 * of the element before and after the view checked.
 * @param {HTMLElement} element The element to scroll to the top of.
 */
export function scrollByElementTopAfterViewChecked(element: HTMLElement): void {
  const getTop = () => element.getBoundingClientRect().top;

  const previousTop = getTop();

  setTimeout(() => {
    const currentTop = getTop();
    const topDiff = currentTop - previousTop;
    window.scrollBy({
      top: topDiff,
      left: 0,
      behavior: "instant",
    } as unknown as ScrollOptions);
  });
}