import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { RelatedMortgage } from 'src/app/models';
import { UtilityService } from '../../services/utility.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { MortgageCalculationService } from '../../services/mortgage-calculation.service';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import {
  RelatedMortgageLoanEditorDrawer,
  OnMortgageLoanEditorDrawerPurpose,
  UpdateOnMortgageLoanPurpose,
} from './related-mortgage-loan-editor-drawer/related-mortgage-loan-editor-drawer.component';
import { concatMap, from, merge, Subject, Subscription, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'other-new-mortgage-loans',
  templateUrl: 'other-new-mortgage-loans.component.html',
  styleUrls: ['./other-new-mortgage-loans.component.scss'],
  viewProviders: [formViewProvider]
})

export class OtherNewMortgageLoansComponent implements OnDestroy {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  lienPositionTypes: EnumerationItem[];

  @Input()
  giftGrantSources: EnumerationItem[];

  @Input()
  yesNoOptions: EnumerationItem[];

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @ViewChild('editorDrawer')
  protected editorDrawer: RelatedMortgageLoanEditorDrawer;
  private _editorDrawerSubscription: Subscription | null = null;

  private get isEditorDrawerOpen(): boolean {
    const closed = this._editorDrawerSubscription?.closed ?? true;
    return !closed;
  }

  editingIx: number = -1;

  isHeloc: boolean = false;

  originalRelatedMortgage: RelatedMortgage;

  private _destroyed$ = new Subject<void>();

  constructor(
    private readonly _utilityService: UtilityService,
    private readonly _calculationService: MortgageCalculationService,
  ) {
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  addRelatedMortgage = () => {
    let relatedMortgage = new RelatedMortgage();
    relatedMortgage.relatedMortgageId = this._utilityService.getUniqueId();
    this.mortgage.relatedMortgages.push(relatedMortgage);
    if (this.editingIx != -1) {
      this.mortgage.relatedMortgages[this.editingIx] = this.originalRelatedMortgage;
    }
    this.editRelatedMortgage(this.mortgage.relatedMortgages.length - 1, relatedMortgage);
  }

  protected async showRelatedMortgageDetails(index: number) {
    const purpose = new UpdateOnMortgageLoanPurpose(
      this.mortgage.relatedMortgages[index]);

    const result = await this.showEditorDrawer(purpose).catch(console.error);
    if (result == null) {
      return;
    }

    this.mortgage.relatedMortgages[index] = result as RelatedMortgage;
  }

  private showEditorDrawer(
    purpose: OnMortgageLoanEditorDrawerPurpose,
  ): Promise<RelatedMortgage | undefined> {
    let resolve: (value: RelatedMortgage | undefined) => void;
    let reject: (reason?: any) => void;
    const resultPromise = new Promise<RelatedMortgage | undefined>(
      (res, rej) => {
        resolve = res;
        reject = rej;
      });

    if (this.isEditorDrawerOpen) {
      reject(new Error('Cannot open editor drawer while it is already open'));
      return resultPromise;
    }

    const onClose = () => {
      this._editorDrawerSubscription = null;
      // Callings resolve() multiple times is safe.
      resolve(undefined);
    };

    this._editorDrawerSubscription = from(this.editorDrawer.show(purpose)).pipe(
      takeUntil(this._destroyed$),
      concatMap(() => merge(
        this.editorDrawer.cancel,
        this.editorDrawer.save,
      ).pipe(take(1))),
    ).subscribe(resolve);
    this._editorDrawerSubscription.add(onClose);

    return resultPromise;
  }

  editRelatedMortgage = (index: number, relatedMortgage: RelatedMortgage) => {
    this.originalRelatedMortgage = _.cloneDeep(relatedMortgage);
    this.editingIx = index;
  }

  cancelEdit = () => {
    this.mortgage.relatedMortgages[this.editingIx] = this.originalRelatedMortgage;
    this.editingIx = -1;
  }

  confirmEdit = () => {
    this.editingIx = -1;
    this._calculationService.publishOtherMortgagesChangedEvent();
  }

  deleteRelatedMortgage = (removeIx: number) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        self.editingIx = -1;
        const index = self.mortgage.relatedMortgages.indexOf(self.mortgage.relatedMortgages[removeIx]);
        if (index >= 0) {
          self.mortgage.relatedMortgages.splice(index, 1);
          self._calculationService.publishOtherMortgagesChangedEvent();
        }
      }
    });
  }

  calculateTotalLoanOrDrawAmount = () => {
    this.mortgage.calculatedStats.totalLoanOrDrawAmount = this._calculationService.calculateTotalLoanOrDrawAmount(this.mortgage);
    this.mortgage.calculatedStats.totalMortgageLoans = this._calculationService.calculateTotalMortgage(this.mortgage);
    this.mortgage.calculatedStats.totalDueFromBorrowers = this._calculationService.calculateTotalDueFromBorrowers(this.mortgage);
    this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);
  }

  omitRelatedMortgage = (relatedMortgage: RelatedMortgage, isOmit: boolean) => {
    relatedMortgage["isOmitted"] = isOmit;
  }
}
