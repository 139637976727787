import { Component, Input, OnInit } from '@angular/core';
import { MortgageService } from 'src/app/services/mortgage.service';
import { DisclosureTracking } from '../../../models/disclosure-tracking.model';

@Component({
  selector: 'disclosure-tracking-reasons',
  templateUrl: 'disclosure-tracking-reasons.component.html',
  styleUrls: ['disclosure-tracking-reasons.component.scss']
})

export class DisclosureTrackingReasonsComponent implements OnInit {

  @Input()
  disclosureTrackingItem: DisclosureTracking;

  urlaFieldsConfig: any = {};

  constructor(private readonly _mortgageService: MortgageService) {
    this.urlaFieldsConfig = this._mortgageService.urlaFieldsConfig;
  }

  ngOnInit(): void {

  }

}
