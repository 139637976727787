<div class="inline-editor" *ngIf="editorMode != 'InlineReadOnly' && !disabled" [ngClass]="{
    'input-group': editorMode == 'Inline',
    'shifted-input': editorMode == 'Inline' && shiftInputToLeftWhenEditingInline
  }" [hidden]="
    !(
      editorMode == 'Classic' ||
      (editorMode == 'Inline' &&
        (isEditActive || (model && model.touched && model.invalid)))
    )
  ">
  <!-- <ng-select
    *ngIf="isMultiple"
    width="100%"
    [name]="name"
    [bindLabel]="optionNameKey"
    [bindValue]="optionValueKey"
    placeholder="Select Items"
    class="custom-ng-select"
    [multiple]="true"
    [(ngModel)]="value"
    [items]="options"
  >
  </ng-select> -->

  <p-dropdown #dropdown *ngIf="!isMultiple" #model="ngModel" name="{{ name }}" [readonly]="readonly"
    [required]="required" [id]="id" styleClass="w-100 {{
      model && model.touched && model.invalid ? 'is-invalid' : ''
    }}" [options]="groupedOptions" [optionLabel]="optionNameKey" [optionValue]="optionValueKey"
    [optionGroupLabel]="optionGroupNameKey" [group]="groupShowingEnabled" [(ngModel)]="value"
    placeholder="{{ placeholder }}" [disabled]="disabled" (onBlur)="onBlur($event)" (onClear)="onClear($event)"
    [virtualScroll]="true" [filter]="true" [showClear]="true" [itemSize]="20">
    <ng-template let-group pTemplate="group">
      <div class="flex align-items-center">
        <span>{{ group.label }}</span>
      </div>
    </ng-template>
  </p-dropdown>
  <div class="invalid-feedback d-block" *ngIf="model && model.touched && model.invalid">
    This field is required
  </div>

  <button class="btn p-0 border-0 bg-transparent ms-1" title="Apply" type="button" *ngIf="editorMode == 'Inline'"
    (click)="applyClicked()">
    <i class="fa fa-check-circle text-success"></i>
  </button>
  <button class="btn p-0 border-0 bg-transparent ms-1" title="Cancel" type="button" *ngIf="editorMode == 'Inline'"
    (click)="cancelClicked()">
    <i class="fa fa-times-circle text-danger"></i>
  </button>
</div>

<span [ngClass]="{
    'empty-field': value == null || value == undefined || value == ''
  }" class="inline-editor-container form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}" title="Click to Edit" *ngIf="
    editorMode == 'Inline' &&
    !isEditActive &&
    !(model && model.touched && model.invalid)
  " (click)="isEditActive = true">
  <span class="val" *ngIf="value && getSelectedOptionName(value)">{{
    getSelectedOptionName(value)
    }}</span>
  <span class="placeholder" *ngIf="!value || !getSelectedOptionName(value)">--</span>
</span>
<span [ngClass]="{
    'empty-field': value == null || value == undefined || value == ''
  }" class="inline-editor-container-readonly form-control bg-transparent display-inline px-0 {{
    inlineTextClass
  }}" *ngIf="
    editorMode == 'InlineReadOnly' || disabled">
  <span class="val" *ngIf="value && getSelectedOptionName(value)">{{
    getSelectedOptionName(value)
    }}</span>
  <span class="placeholder" *ngIf="!value || !getSelectedOptionName(value)">--</span>
</span>