import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { CalculatorHousingParams } from '../housing-calculator/housing-calculator.component';

@Component({
  selector: 'lower-payment-calculator',
  templateUrl: './lower-payment-calculator.component.html',
})
export class LowerPaymentCalculatorComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  currentLoanInterestRate: number;

  @Input()
  currentLoanTerm: number;

  todayParameters = new CalculatorHousingParams();
  proposedParameters = new CalculatorHousingParams();

  paymentReductionResuls = {
    monthlySavings: 0,
    threeMonthsSavings: 0,
    sixMonthsSavings: 0,
    annualSavings: 0
  };

  imageData: SafeResourceUrl;
  imageData2: SafeResourceUrl;

  constructor(private readonly _utilityService: UtilityService) {

    this._utilityService.getImageFileContentAsBase64("assets/images/lower-payment-img1.jpg",  (result) => {
      this.imageData = result;
    });

    this._utilityService.getImageFileContentAsBase64("assets/images/lower-payment-img2.png",  (result2) => {
      this.imageData2 = result2;
    });

  }

  ngOnInit(): void {
    this.calculatePaymentReductionResults();
  }

  onParametersChanged = (parameters: CalculatorHousingParams, isToday: boolean = false) => {
    if (isToday) {
      this.todayParameters = { ...parameters };
    }
    else {
      this.proposedParameters = { ...parameters };
    }

    this.calculatePaymentReductionResults();
  }

  calculatePaymentReductionResults = () => {
    let monthlySaving = this.todayParameters.totalHousing - this.proposedParameters.totalHousing;

    this.paymentReductionResuls = {
      monthlySavings: monthlySaving,
      threeMonthsSavings: 3 * monthlySaving,
      sixMonthsSavings: 6 * monthlySaving,
      annualSavings: 12 * monthlySaving
    }
  }

}
