<form class="form-horizontal" id="credentialForm" name="credentialForm" #credentialForm="ngForm" (ngSubmit)="saveCredential()" novalidate>
  <div class="modal-header">
    <h5 class="modal-title">FormFree Credential</h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <credential-modal-basic [scope]="'Company'" [credential]="credential" [vendorSelectionDisabled]="!!credential.vendorName">
        </credential-modal-basic>
      </div>
      <div class="col-md-12 mt-1">
        <label class="custom-control-label">Url</label>
        <input type="text" class="form-control" name="url"  [(ngModel)]="credential.url">
      </div>
      <div class="col-md-6 mt-1">
        <label class="custom-control-label">Username</label>
        <input type="text" class="form-control" name="userName" [(ngModel)]="credential.userName">
      </div>
      <div class="col-md-6 mt-1">
        <label class="custom-control-label">Password</label>
        <password-input [name]="'password'" [(ngModel)]="credential.password"></password-input>
      </div>
      <div class="col-md-12 mt-1">
        <label class="custom-control-label">Document Type</label>
        <select class="form-select" name="savedDocumentTypeId"  [(ngModel)]="keyValuePairs.savedDocumentTypeId.value">
          <option value="">-- Select One --</option>
          <option *ngFor="let item of documentTypes" value={{item.documentTypeId}}>
            {{item.documentTypeName}}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 pt-2">
        <div class="row">
          <div class="col-md-12">
            <div class="fieldset-title">
              <div class="row">
                <div class="col-md-6 pt-2">Additional Config</div>
                <div class="col-md-6 pt-2 text-end">
                  <a href="javascript: void(0);" (click)="addConfig()">
                    <i class="fa fa-plus"></i>
                    Add Config Item
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row mt-1" *ngFor="let ele of additionalConfigs; let index = index">
              <div class="col-md-6">
                <label class="custom-control-label">Key</label>
                <select class="form-select" name="key-{{index}}" [(ngModel)]="ele.key">
                  <option *ngFor="let item of keys" value={{item.key}}>
                    {{item.displayText}}
                  </option>
                </select>
              </div>
              <div class="col-md-5">
                <label class="custom-control-label">Value</label>
                <select class="form-select" name="value-{{index}}" [(ngModel)]="ele.value">
                  <option value="">-- Select One --</option>
                  <option *ngFor="let item of (ele.key ? options[ele.key] : [])" value={{item.key}}>
                    {{item.displayText}}
                  </option>
                </select>
              </div>
              <div class="col-md-1 pt-4 mt-1">
                <confirm-delete
                  [list]="additionalConfigs"
                  [index]="index"
                ></confirm-delete>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
      Cancel
    </button>
    <button
      type="submit"
      class="btn btn-primary right"
      [disabled]="isSaving"
    >
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
    </button>
  </div>
</form>
