import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Income } from 'src/app/models/mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'other-income',
  templateUrl: 'other-income.component.html',
  styleUrls: ['other-income.component.scss']
})
export class OtherIncomeComponent implements OnInit {

  @Input()
  incomes: Income[] = [];

  @Input()
  isReadOnly: boolean = false;

  @Input()
  incomeTypes: EnumerationItem[] = [];

  @Output()
  incomeDeleted: EventEmitter<Income> = new EventEmitter<Income>();

  @Output()
  editClicked: EventEmitter<Income> = new EventEmitter<Income>()

  indexToDelete: number = -1;

  constructor() { }

  ngOnInit() { }

  onEditClicked = (income: Income) => {
    this.editClicked.emit(income);
  }

  onDeleteConfirmed = (index: number) => {
    const incomeToDelete = this.incomes[index];
    if (incomeToDelete) {
      this.incomes.splice(index, 1);
      this.incomeDeleted.emit(incomeToDelete);
    }
    this.indexToDelete = -1;
  }

  incomeTypeName = (income: Income): string => {
    const type = this.incomeTypes.find(t => t.value == income.typeOfIncome);
    if (type) {
      if (type.value === "OtherTypesOfIncome") {
        return income.typeOfIncomeOtherDescription;
      }
      return type.name;
    }
    return "--";
  }
}
