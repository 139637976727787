<div [ngClass]="{'group-container-repeat': !mouseCursorOnDeleteButton,
  'group-container-repeat-pending-delete': mouseCursorOnDeleteButton}">
  <div
    *ngIf='employment'
    class='grid-layout has-border'
  >
    <div
      *ngIf='!inEditMode'
      class='grid-layout-row'
      style='flex-direction: row-reverse;'
    >
      <div *ngIf='employment.verificationStatus === VerificationStatus.Verified'>
        <i
          aria-hidden='true'
          class='fa fa-check icon-check'
        ></i>
        <span class='digitally-verified'>Digitally Verified</span>
      </div>

      <a
        (click)='onDeleteClicked()'
        (mouseout)='mouseCursorOnDeleteButton=false'
        (mouseover)='mouseCursorOnDeleteButton=true'
        *ngIf='!isReadOnly'
        class='top-right-close link'
      >
        <i class='fa fa-times-circle'></i>
      </a>
    </div>

    <div class='grid-layout-row'>
      <employer-name-input
        #employerName
        (employerAddressChanged)='onEmployerAddressChanged($event)'
        (isPrimaryChanged)='onIsPrimaryChanged($event)'
        (isRetiredChanged)='onIsRetiredChanged($event)'
        (switchEmploymentType)="onSwitchedEmploymentType()"
        *ngIf='!inEditMode'
        [(ngModel)]='employment.employer'
        [isPrimary]='employment.isPrimary'
        [isRetired]='isRetired'
        [isCurrent]="employment.employmentType == currentEmployerEnumValue"
        [label]="employment.employmentType == currentEmployerEnumValue ? 'Current Employer' : 'Former Employer'"
        [name]="'employerName' + componentId"
        class='col-sm-4 col-xs-12'
        [readonly]='isReadOnly'
        [required]="urlaFieldsConfig['mortgage.borrower.employment.employer']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.employer']?.required == 'requested'"
        [showIsPrimaryCheckbox]='employment.employmentType == currentEmployerEnumValue'
        [showIsRetiredCheckbox]='employment.employmentType == currentEmployerEnumValue'
        [urlaFieldsConfig]='urlaFieldsConfig'
        fieldBeingEdited='mortgage.borrower.employment.employer'
      ></employer-name-input>

      <urla-text-input
        *ngIf='inEditMode'
        [(ngModel)]='employment.employer'
        [inEditMode]='inEditMode'
        [name]="'employerName' + componentId"
        [readonly]='isReadOnly'
        [required]="urlaFieldsConfig['mortgage.borrower.employment.employer']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.employer']?.required == 'requested'"
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-12'
        fieldBeingEdited='mortgage.borrower.employment.employer'
        label='Employer'
      ></urla-text-input>

      <urla-text-input
        [(ngModel)]='employment.position'
        [hidden]='isRetired'
        [inEditMode]='inEditMode'
        [name]="'position' + componentId"
        [readonly]='isReadOnly'
        [required]="(urlaFieldsConfig['mortgage.borrower.employment.position']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.position']?.required == 'requested') && !isRetired"
        [urlaFieldsConfig]='urlaFieldsConfig'
        [ngClass]="{'col-sm-2': employment.employmentType == formerEmployerEnumValue,
          'col-sm-3': employment.employmentType != formerEmployerEnumValue}"
        class='col-xs-6'
        fieldBeingEdited='mortgage.borrower.employment.position'
        label='Position'
      ></urla-text-input>

      <urla-date-input
        [(ngModel)]='employment.startDate'
        (ngModelChange)='onStartDateChanged()'
        [inEditMode]='inEditMode'
        [maxDate]='maxDate'
        [name]="'startDate' + componentId"
        [readonly]='isReadOnly'
        [required]="(urlaFieldsConfig['mortgage.borrower.employment.startDate']?.required == 'required' ||
          urlaFieldsConfig['mortgage.borrower.employment.startDate']?.required == 'requested') && !isRetired"
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-2 col-xs-6'
        fieldBeingEdited='mortgage.borrower.employment.startDate'
        label='Start Date'
      ></urla-date-input>

      <urla-date-input
        *ngIf='employment.employmentType == formerEmployerEnumValue || inEditMode'
        [(ngModel)]='employment.endDate'
        (ngModelChange)='onEndDateChanged()'
        [hidden]='isRetired'
        [inEditMode]='inEditMode'
        [minDate]='employment.startDate'
        [name]="'endDate' + componentId"
        [readonly]='isReadOnly'
        [required]="(urlaFieldsConfig['mortgage.borrower.employment.endDate']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.endDate']?.required == 'requested') && !isRetired"
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-2 col-xs-6'
        fieldBeingEdited='mortgage.borrower.employment.endDate'
        label='End Date'
      ></urla-date-input>

      <div
        *ngIf='employment.employmentType == currentEmployerEnumValue'
        class='grid-layout-spacer col-sm-1 col-xs-6'
      ></div>

      <urla-currency-input
        #monthlyIncome
        (calculatorClicked)='onMonthlyIncomeCalculatorClicked()'
        *ngIf='!inEditMode'
        [(ngModel)]='employment.calculatedStats.monthlyIncome'
        [hasCalculator]='true'
        [hidden]='isRetired'
        [name]="'monthlyIncome' + componentId"
        [readonly]='isReadOnly'
        [required]="(urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome']?.required == 'requested') && !isRetired && employment.calculatedStats.monthlyIncome > 0"
        class='col-sm-2 col-xs-6'
        label='Monthly Income'
      ></urla-currency-input>
    </div>

    <urla-address
      [address]='employment.address'
      [hidden]='isRetired'
      [inEditMode]='inEditMode'
      [isReadOnly]='isReadOnly'
      [showAllCountries]="true"
      [urlaFieldsConfig]='urlaFieldsConfig'
      [zipcodeLookupEnabled]='true'
      addressLabel='Employer Street Address'
      fieldBeingEdited='mortgage.borrower.employment.address'
    ></urla-address>

    <div class='grid-layout-row'>
      <urla-text-input
        [(ngModel)]='employment.employerPhone'
        [hidden]='isRetired'
        [inEditMode]='inEditMode'
        [name]="'businessPhone' + componentId"
        [readonly]='isReadOnly'
        [required]="(urlaFieldsConfig['mortgage.borrower.employment.employerPhone']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.employerPhone']?.required == 'requested') && !isRetired"
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-2 col-xs-6'
        fieldBeingEdited='mortgage.borrower.employment.employerPhone'
        label='Business Phone'
        mask='(000) 000-0000'
        placeholder='(###) ###-####'
      ></urla-text-input>

      <urla-digits-input
        [(ngModel)]='employment.yearsInLineOfWork'
        [hidden]='isRetired'
        [inEditMode]='inEditMode'
        [name]="'yearsInProfession' + componentId"
        [readonly]='isReadOnly'
        [required]="(urlaFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork']?.required == 'requested') && !isRetired"
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-2 col-xs-6'
        fieldBeingEdited='mortgage.borrower.employment.yearsInLineOfWork'
        label='Years in Profession'
      ></urla-digits-input>

      <urla-radio-group
        [(ngModel)]='employment.selfEmployed'
        (ngModelChange)='selfEmployedChanged(employment.selfEmployed)'
        [hidden]='isRetired'
        [inEditMode]='inEditMode'
        [name]="'selfEmployed' + componentId"
        [options]='yesNoOptions'
        [readonly]='isReadOnly'
        [required]="(urlaFieldsConfig['mortgage.borrower.employment.selfEmployed']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.selfEmployed']?.required == 'requested') && !isRetired"
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-2 col-xs-6'
        fieldBeingEdited='mortgage.borrower.employment.selfEmployed'
        label='Self Employed'
      ></urla-radio-group>

      <urla-dropdown
        *ngIf='employment.selfEmployed'
        [(ngModel)]='employment.borrowerOwnershipShare'
        [inEditMode]='inEditMode'
        [name]="'ownershipShare' + componentId"
        [options]='ownershipShareTypes'
        [readonly]='isReadOnly'
        [required]="(urlaFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare']?.required == 'requested') && !isRetired"
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-2 col-xs-6'
        fieldBeingEdited='mortgage.borrower.employment.borrowerOwnershipShare'
        label='Ownership Share'
      ></urla-dropdown>

      <urla-dropdown
        *ngIf="employment.selfEmployed && employment.borrowerOwnershipShare === 'GreaterOrEqualTo25Percent'"
        [(ngModel)]='employment.selfEmploymentForm'
        [inEditMode]='inEditMode'
        [name]="'selfEmploymentForm' + componentId"
        [options]='selfEmploymentForms'
        [readonly]='isReadOnly'
        [required]="(urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentForm']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentForm']?.required == 'requested') && !isRetired"
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-2 col-xs-6'
        fieldBeingEdited='mortgage.borrower.employment.selfEmploymentForm'
        label='Self Empl. Form'
      ></urla-dropdown>

      <urla-currency-input
        (blur)='selfEmployedMonthlyIncomeChanged()'
        *ngIf='employment.selfEmployed'
        [(ngModel)]='employment.selfEmploymentMonthlyIncomeOrLoss'
        [inEditMode]='inEditMode'
        [name]="'selfEmploymentMonthlyIncomeLoss' + componentId"
        [ngClass]="{'col-sm-2': employment.selfEmployed && employment.borrowerOwnershipShare === 'GreaterOrEqualTo25Percent',
        'col-sm-4': !employment.selfEmployed || employment.borrowerOwnershipShare !== 'GreaterOrEqualTo25Percent'}"
        [readonly]='isReadOnly || isRetired'
        [required]="(urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss']?.required == 'requested') && !isRetired"
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-xs-6'
        fieldBeingEdited='mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss'
        label='Monthly Income/Loss'
      ></urla-currency-input>
    </div>

    <div
      *ngIf='!isRetired || inEditMode'
      class='grid-layout-row'
    >
      <!-- Verification Status -->
      <urla-dropdown
        [(ngModel)]='employment.verificationStatus'
        [inEditMode]='inEditMode'
        [name]="'verificationStatus' + componentId"
        [options]='verificationStatusTypes'
        [readonly]='isReadOnly'
        [required]="(urlaFieldsConfig['mortgage.borrower.employment.verificationStatus']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.employment.verificationStatus']?.required == 'requested') && !isRetired"
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-lg-2'
        fieldBeingEdited='mortgage.borrower.employment.verificationStatus'
        label='Verification Status'
      ></urla-dropdown>

      <!-- Aliases -->
      <div
        *ngIf='!isRetired || !inEditMode'
        class='grid-cell-tag col-6 col-lg-4'
      >
        <div class='form-input d-flex flex-column' [ngClass]="{'bg-transparent' : isReadOnly}">
          <!-- Header -->
          <div class='d-flex justify-content-between align-items-center'>
            <div class='input-label'>
              <div class='input-label-overflow'>
                Aliases
              </div>
            </div>

            <button
              (click)='onClickEditAliases()'
              *ngIf='!isReadOnly && !inEditMode'
              class='btn py-0'
              type='button'
            >
              <i class='fas fa-pencil-alt'></i>
            </button>
          </div>

          <!-- Alias tags -->
          <div class='alias-tags d-flex gap-1 flex-wrap'>
            <span
              *ngFor='let alias of employment.aliases'
              class='inline-tag'
            >
              {{ alias }}
            </span>
          </div>
        </div>
      </div>

      <!-- Employer ID  -->
      <urla-text-input
      [(ngModel)]='employment.employerIdentificationNumber'
      [hidden]='isRetired'
      [inEditMode]='inEditMode'
      [name]="'employerIdentificationNumber' + componentId"
      [readonly]='isReadOnly'
      [required]="(urlaFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber']?.required == 'required' ||
      urlaFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber']?.required == 'requested') && !isRetired"
      [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6 col-lg-2'
      fieldBeingEdited='mortgage.borrower.employment.employerIdentificationNumber'
      label='Employer Id'
    ></urla-text-input>
    </div>
  </div>
</div>
