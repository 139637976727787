<ng-template #pageActionBarRight>
  <loan-summary-bar
    [options]="loanSummaryOptions"
    [borrowers]="applicationContext ? applicationContext.borrowers : []">
  </loan-summary-bar>
</ng-template>

<ng-template #pageActionBarLeft>
  <bread-crum class="tpo-bread-crumb"></bread-crum>
</ng-template>

<ng-template #mainContent>
  <div class="row">
    <div class="col-lg-2">
      <div class="card mb-0" style="position: sticky; top: -223px;">
        <div class="card-body">
          <loan-summary-card [loanSummary]="loanSummary"></loan-summary-card>
          <tpo-menu *ngIf="application && !useV2Menu"
            [loanId]="loanId"
            [featurePermissions]="permissions">
          </tpo-menu>
          <tpo-menu-v2 *ngIf="application && useV2Menu"
            [loanId]="loanId"
            [featurePermissions]="permissions"
            [stepStatuses]="stepStatuses"
            [showStepLabels]="!loanSubmissionCompleted">
          </tpo-menu-v2>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isLoadingApplicationDetails; else loading">
      <div class="col-lg-10 g-0">
        <pizza-tracker [steps]="trackerSteps" *ngIf="isPizzaTrackerEnabled && trackerSteps.length" [activeStepOrder]="activeTrackerStepOrder"></pizza-tracker>
        <router-outlet></router-outlet>
      </div>
    </ng-container>

    <ng-template #loading>
      <div class="col-lg-10 col-xxl-10 col-xxxl-10">
        <div class="card" style="min-height:731px">
          <div class="card-body d-flex justify-content-center align-items-center">
            <loading-indicator id="tpo-app-detail-loader"
              [loadingMessage]="'Loading application details...'"></loading-indicator>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="escalationButton" *ngIf="!isPRMG">
      <button class="btn btn-primary"
        (click)="createEscalationDialogModal()">E<br>s<br>c<br>a<br>l<br>a<br>t<br>i<br>o<br>n<br></button>
    </div>
  </div>

</ng-template>

<tpo-page-template
  [pageMainContentTemplate]="mainContent"
  [pageActionBarLeftContentTemplate]="pageActionBarLeft"
  [pageActionBarRightContentTemplate]="pageActionBarRight">
</tpo-page-template>
