/**
 * Returns a function that maps an array of objects to a map of the specified
 * field.
 * @template V The type of the value.
 * @param {readonly V[]} array The array to map.
 */
export function mapOf<V>(
  array: readonly V[],
): <F extends keyof V>(field: F) => Map<V[F], V> {
  return function <F extends keyof V>(field: F): Map<V[F], V> {
    return new Map(array.map(v => [v[field], v]));
  };
}

/**
 * Returns a function that returns the value of the specified field of the
 * specified object or constructs a new instance of the specified class if the
 * value is null or undefined.
 * @template K The type of the key.
 * @template V The type of the value.
 * @param {Map<K, V>} map The map to get the value from.
 */
export function getOrDefault<K, V>(
  map: Map<K, V>,
): (ctor: new (k: K) => V) => (key: K) => V {
  return function(ctor: new (k: K) => V): (key: K) => V {
    return function(key: K): V {
      return map.get(key) ?? new ctor(key);
    };
  };
}
