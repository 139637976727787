import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThirdPartyCredential, ThirdPartyKeyValue } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BusinessChannel, OBChannel } from 'src/app/modules/pricing/models/business-channel.model';
import { PricingService } from 'src/app/modules/pricing/services/pricing.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { RepriceFieldsDialogComponent } from '../reprice-fields-dialog/reprice-fields-dialog.component';
import { Constants } from 'src/app/services/constants';
import { Utils } from 'src/app/core/services/utils';
import { chain } from 'lodash';
import { NgForm } from '@angular/forms';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';

@Component({
  selector: 'ob-vendor-editor-dialog',
  templateUrl: 'ob-vendor-editor-dialog.component.html',
  styleUrls: ['./ob-vendor-editor-dialog.component.scss'],
})

export class OBVendorEditorDialogComponent implements OnInit {
  @Input()
  vendor: ThirdPartyCredential;

  @Input()
  inEditMode: boolean = true;

  @Input()
  businessChannelsNames: string[] = [];

  @Input()
  optimalBlueKeyValuePairs: any;

  @Input()
  scope: 'User' | 'Branch';

  @Input()
  branchId?: number;

  @Input()
  userCompanyGuid?: string;

  @ViewChild("editorForm")
  editorForm: NgForm;

  channels: { display: string, value: string }[] = [];

  channelOptions: EnumerationItem[] = [];
  selectedChannels: EnumerationItem[] = [];
  businessChannelOriginators = {};
  kvpOriginators: ThirdPartyKeyValue[] = [];

  businessChannels: OBChannel[] = [];

  multiSelectSettings: IDropdownSettings = {
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };

  constructor(public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _pricingService: PricingService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService) { }

  ngOnInit() {
    this.loadBusinessChannels();
    this.channels = this.vendor.thirdPartyKeyValuePairs
      .filter(el => el.key.includes('Channel:'))
      .map(el => ({
        display: el.key.replace('Channel:', ''),
        value: el.value,
      }));

    if (this.scope === 'Branch' || this.scope === 'User') {
      this.kvpOriginators = chain(this.vendor.thirdPartyKeyValuePairs)
        .cloneDeep()
        .filter(el => el.key.includes('Originator:'))
        .map(el => {
          el.key = el.key.replace('Originator:', '');
          return el;
        })
        .value();

      if (this.kvpOriginators.length === 0) {
        this.addBusinessChannel();
      }
    }
  }

  saveCredential = () => {
    if (this.scope === 'User' || this.scope === 'Branch') {
      this.editorForm.form.markAllAsTouched();
      if (!this.editorForm.valid) {
        return;
      }
    }

    if (this.scope !== 'User' && this.scope !== 'Branch') {
      this.vendor.thirdPartyKeyValuePairs = this.selectedChannels.map(el => ({
        key: `Channel:${el.name}`,
        value: this.businessChannels.find(e => e.businessChannelId == el.value)?.businessChannelId.toString() || null,
        alias: '',
        userName: '',
        password: ''
      }));
    }

    if (this.scope === 'Branch') {
      this.vendor.branchId = String(this.branchId);

      this.vendor.thirdPartyKeyValuePairs = chain(this.kvpOriginators)
        .cloneDeep()
        .map(el => {
          el.key = `Originator:${el.key}`;
          return el;
        })
        .value();
    }

    if (this.scope === 'User') {
      this.vendor.userId = this.userCompanyGuid;

      this.vendor.thirdPartyKeyValuePairs = chain(this.kvpOriginators)
        .cloneDeep()
        .map(el => {
          el.key = `Originator:${el.key}`;
          return el;
        })
        .value();
    }

    const observer = {
      next: (result => {
        this.activeModal.close(result);
        this._spinner.hide();
      }),
      error: (error => {
        this._spinner.hide();
      })
    }
    this._spinner.show();
    this._systemLevelService.saveCredential(this.vendor).subscribe(observer);
  }

  onRepriceFieldsClicked = () => {
    const modalRef = this._modalService.open(RepriceFieldsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.credentialId = this.vendor.credentialId;
    modalRef.componentInstance.vendor = PricingVendor.OptimalBlue;
  }

  addBusinessChannel = () => {
    this.kvpOriginators.push(new ThirdPartyKeyValue('', ''))
  }

  clearOriginator = (item) => {
    item.value = '';
  }

  private loadBusinessChannels = () => {
    this._spinner.show();
    this._pricingService
      .getBusinessChannels(true)
      .subscribe(result => {
        this.businessChannels = result || [];
        this.setSelectedChannels();
        this.setChannelOptions();
        this.businessChannelsNames = result.map(el => el.name);

        this.businessChannels.forEach(businessChannel => {
          this.businessChannelOriginators[businessChannel.businessChannelId] = chain(businessChannel.originators)
            .map(originator => ({
              originatorId: originator.originatorId,
              name: Utils.getPersonsDisplayName(originator)
            }))
            .orderBy(["name"], ["asc"])
            .value();;
        });
      }, ({ error }) => {
        this._notificationService.showError(
          error ? error.message : 'Unable to load business channels',
          'Business Channels'
        );
      }).add(() => this._spinner.hide());
  }

  private setChannelOptions = (): void => {
    this.channelOptions = this.businessChannels.map(c => {
      return new EnumerationItem(c.name, c.businessChannelId)
    });
  }

  private setSelectedChannels = () => {
    const ids: string[] = [];
    this.channels.forEach(key => {
      ids.push(key.value);
    })
    this.selectedChannels = this.businessChannels
      .filter(bC => ids.includes(bC.businessChannelId.toString()))
      .map(c => new EnumerationItem(c.name, c.businessChannelId));
  }
}
