<div class="row m-0 px-0 py-2">
  <div class="col-8">
    <span class="text-nowrap header-font-size"><span class="fw-bold">Real Estate Owned: <span
          class="ms-1 fw-bold text-info">{{totalMarketValue | currency}}</span> </span>
          <span class="vertical-divider">
            <span></span>
          </span>
    </span>
    <span class="text-nowrap" style="color: #a4abc5;">Gross Monthly Expense: <span class="text-danger ms-1">{{grossMonthlyExpense |
        currency}}</span></span>
  </div>

  <div class="col-4 d-flex justify-content-end align-items-end pe-0">
    <div class="form-check">
      <input id="doesNotApply" class="form-check-input" type="checkbox" name="doesNotApply"
        [(ngModel)]="doesNotApply" />
      <label class="form-check-label text-dark" for="doesNotApply">
        Does not apply
      </label>
    </div>
    <button class="btn btn-sm btn-soft-danger ms-2" *ngIf="haveMultipleRowSelected()" (click)="bulkDeleteClicked()"><i
        class="fa fa-trash-alt me-1"></i> Delete Selected</button>
  </div>
</div>
<div class="qa-table-header-border"> </div>
<div class="table-responsive">
  <table class="table table-hover">
    <tbody>
      <ng-container *ngFor="let reoItem of reoItems; let i = index">
        <tr (mouseenter)="hoveredItems[reoItem.reoId] = true" (mouseleave)="hoveredItems[reoItem.reoId] = false">
          <td style="width: 40%">
            <span class="d-inline-flex align-items-center">
              <span class="me-2" style="width: 15px">
                <div class="form-check" *ngIf="hoveredItems[reoItem.reoId] || selectedRows[reoItem.reoId]">
                  <input id="selectedRows-{{i}}" class="form-check-input" type="checkbox" name="selectedRows-{{i}}"
                    [(ngModel)]="selectedRows[reoItem.reoId]" />
                </div>
              </span>
              <i (click)="toggleExpandReo(reoItem); editItems[reoItem.reoId] = true"
                class="me-2 cursor {{
                                expandedItems[reoItem.reoId] ? 'fa fa-chevron-down' : 'fa fa-chevron-right'}}"></i>

              <text-input [(ngModel)]="reoItem.address1" [editorMode]="'Inline'" name="reo_address1-{{ reoItem.reoId }}"
                [disabled]="expandedItems[reoItem.reoId]">
              </text-input>,
              <text-input [(ngModel)]="reoItem.city" [editorMode]="'Inline'" name="reo_city-{{ reoItem.reoId }}"
                [disabled]="expandedItems[reoItem.reoId]">
              </text-input>,
              <select-input [(ngModel)]="reoItem.state" [editorMode]="'Inline'" name="reo_state-{{ reoItem.reoId }}"
                [placeholder]="'State'" [options]="states" [disabled]="expandedItems[reoItem.reoId]">
              </select-input>
              <qa-badge variant='green' class="mx-1" *ngIf="reoItem['status']">
                {{reoItem['status']}}
              </qa-badge>
              <qa-badge variant='yellow' class="me-1" *ngIf="reoItem['currentOccupancy']">
                {{reoItem['currentOccupancy']}}
              </qa-badge>
            </span>

          </td>
          <td style="width: 15%">
            <span class="d-inline-flex align-items-center">
              <label class="me-1">of</label>
              <select-input [editorMode]="'Inline'" [isMultiple]="true" [required]="true" [optionValueKey]="'id'"
                [optionNameKey]="'text'" [placeholder]="'Account Owner'" [(ngModel)]="reoItem.owningBorrowerIds"
                [options]="possibleAccountOwners" name="reo_owningBorrowerIds_{{ reoItem.reoId }}"
                [disabled]="expandedItems[reoItem.reoId]"></select-input>
            </span>
          </td>
          <td style="width: 15%">
            <span class="d-inline-flex align-items-center">
              <label class="me-1">Liens</label>
              <currency-input [(ngModel)]="reoItem.mortgagePayment" [editorMode]="'Inline'"
                name="liens-{{ reoItem.reoId }}" [disabled]="expandedItems[reoItem.reoId]">
              </currency-input>
            </span>
          </td>
          <td style="width: 15%">
            <span class="d-inline-flex align-items-center">
              <label class="me-1">Value</label>
              <currency-input [(ngModel)]="reoItem.marketValue" (change)="calculateSubTotal()"
                name="marketValue-{{ reoItem.reoId }}" [editorMode]="'Inline'" [placeholder]="'Market Value'"
                [disabled]="expandedItems[reoItem.reoId]">
              </currency-input>
            </span>
          </td>
          <td style="width: 15%">
            <span class="d-inline-flex align-items-center">
              <label class="me-1">Monthly (+/-) </label>
              <currency-input [(ngModel)]="reoItem.monthlyMiscExpenses" (change)="calculateSubTotal()"
                name="monthlyMiscExpenses-{{ reoItem.reoId }}" [editorMode]="'Inline'" [placeholder]="'Monthly'"
                [disabled]="expandedItems[reoItem.reoId]">
              </currency-input>
            </span>
          </td>
        </tr>
        <tr>
          <td colspan="5">
            <expansion-panel
              [content]='reoEditorTemplate'
              [isExpanded]='expandedItems[reoItem.reoId]'
            ></expansion-panel>

            <ng-template #reoEditorTemplate>
              <qa-fi-reo-editor-v2
                (cancel)='onClickCancelReo(reoItem)'
                (close)='onClickCloseReo(reoItem)'
                (update)='onChangeReo($event)'
                [currentPropertyWillBeTypes]='currentPropertyWillBeTypes'
                [intendedPropertyWillBeTypes]='intendedPropertyWillBeTypes'
                [liabilityTypeHELOC]='liabilityTypeHELOC'
                [liabilityTypeMortgageLoan]='liabilityTypeMortgageLoan'
                [mode]='editItems[reoItem.reoId] ? "edit" : "create"'
                [mortgage]='mortgage'
                [possibleAccountOwners]='possibleAccountOwners'
                [propertyStatusOptions]='propertyStatusOptions'
                [reoPropertyTypes]='reoPropertyTypes'
                [reo]='reoItem'
                [states]='states'
              ></qa-fi-reo-editor-v2>
            </ng-template>
          </td>
        </tr>
      </ng-container>

      <tr *ngIf='!isCreatingItem'>
        <td colspan="6">
          <button type="button" class="btn btn-sm btn-soft-primary" (click)="addReoClicked()">
            <i class="fas fa-plus me-2"></i> REO
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
