import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { QaVaInfoPlaceholderEnums, VaInfoForm, VaInfoFormAdapter } from '../qa-va-info.model';
import { FormGroup } from '@angular/forms';
import { UrlaMortgage } from '../../../../../urla/models/urla-mortgage.model';
import { EnumerationItem } from '../../../../../../models/simple-enum-item.model';
import { ApplicationContextService } from '../../../../../../services/application-context.service';
import { concatMap, startWith, Subscription } from 'rxjs';
import { enumToEnumerationItems } from '../../../../../../core/services/utils';
import { Address, VAAppraisalTypeEnum, VALoanProcedureTypeEnum } from '../../../../../../models';
import { QaAddressEditorModalComponent } from '../../components/qa-address-editor-modal/qa-address-editor-modal.component';
import { addressToString } from '../qa-va-utils';

// FIXME: Use the real enum values
import Occupancy = QaVaInfoPlaceholderEnums.Occupancy;
import AusResult = QaVaInfoPlaceholderEnums.AusResult;
import PastCreditRecord = QaVaInfoPlaceholderEnums.PastCreditRecord;
import FinalLoanAction = QaVaInfoPlaceholderEnums.FinalLoanAction;
import Underwriter = QaVaInfoPlaceholderEnums.Underwriter;

type NearestLivingRelativeSection = {
  selectedRelative: FormGroup<VaInfoForm>['controls']['disclosuresInfo']['controls']['nearestLivingRelatives']['controls'][number];
}

type FormAddress = FormGroup<VaInfoForm>['controls']['disclosuresInfo']['controls']['nearestLivingRelatives']['controls'][number]['controls']['address'];

@Component({
  selector: 'qa-va-disclosures-info',
  templateUrl: './qa-va-disclosures-info.component.html',
  styleUrls: ['./qa-va-disclosures-info.component.scss']
})
export class QaVaDisclosuresInfoComponent implements OnChanges, OnInit,
  OnDestroy {

  @Input() mortgage: UrlaMortgage;
  @Input() formGroup: FormGroup<VaInfoForm>;
  @Input() formAdapter: VaInfoFormAdapter;

  @ViewChild('addressEditorModal')
  addressEditorModal: QaAddressEditorModalComponent;

  protected Occupancy = Occupancy;

  protected nearestLivingRelativeSection: NearestLivingRelativeSection;

  protected purposeOfLoanOptions: readonly EnumerationItem[] = [];
  protected ausResultOptions: readonly EnumerationItem[] =
    enumToEnumerationItems(AusResult);
  protected pastCreditRecordOptions: readonly EnumerationItem[] =
    enumToEnumerationItems(PastCreditRecord);
  protected finalLoanActionOptions: readonly EnumerationItem[] =
    enumToEnumerationItems(FinalLoanAction);
  // FIXME: Use EnumerationService?
  protected vaLoanProcedureTypeOptions: readonly EnumerationItem[] =
    enumToEnumerationItems(VALoanProcedureTypeEnum);
  // FIXME: Use EnumerationService?
  protected appraisalTypeOptions: readonly EnumerationItem[] =
    enumToEnumerationItems(VAAppraisalTypeEnum);
  protected underwriterOptions: readonly EnumerationItem[] =
    enumToEnumerationItems(Underwriter);

  private _applicationContextSubscription: Subscription | null = null;

  protected get context$() {
    return this._applicationContextService.context.pipe(
      concatMap(context =>
        this._applicationContextService.changes.pipe(
          startWith(context),
        ),
      ));
  }

  constructor(
    private readonly _applicationContextService: ApplicationContextService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const formGroupChange = changes['formGroup'];
    if (formGroupChange) {
      this.nearestLivingRelativeSection = {
        selectedRelative: formGroupChange.currentValue.controls.disclosuresInfo.controls.nearestLivingRelatives.controls[0],
      };
    }
  }

  ngOnInit(): void {
    this.subscribeToApplicationContext();
  }

  ngOnDestroy() {
    this._applicationContextSubscription?.unsubscribe();
  }

  private subscribeToApplicationContext() {
    this._applicationContextSubscription?.unsubscribe();

    this._applicationContextSubscription = this.context$.subscribe({
      next: (context) => {
        this.purposeOfLoanOptions = context.globalConfig.loanPurpose.map(x =>
          new EnumerationItem(x.loanPurposeName, x.loanPurposeId));
      },
      complete: () => {
        this._applicationContextSubscription = null;
      },
    });
  }

  protected openAddressEditorModal(address: FormAddress): void {
    this.addressEditorModal.open(address.value);
  }

  protected onCloseAddressEditorModal(address: Address | undefined): void {
    if (address == null) {
      return;
    }

    const addressControl =
      this.nearestLivingRelativeSection.selectedRelative.controls.address;
    const value = {
      summary: addressToString(address),
      ...address,
    }
    addressControl.patchValue(value);
  }
}
