import { Address, MortgageBorrower } from '../../../../../models';

/**
 * FIXME: This is a temporary solution to get the borrower's full name. Check if
 *  there is a standard way to do this.
 * @param borrower
 */
export function getBorrowerDisplayName(borrower: MortgageBorrower): string {
    const firstName = borrower.firstName;
    const lastName = borrower.lastName;
    return lastName ? firstName + " " + lastName : firstName;
}

export function addressToString(address: Address): string {
  const address1 = address.address1;
  const address2 = address.address2;
  const city = address.city;
  const state = address.state;
  const zipCode = address.zipCode;
  return [address1, address2, city, state, zipCode].filter(Boolean).join(', ');
}
