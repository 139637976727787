
  <div *ngIf="borrower"
    class="form-input"
    [ngClass]="
      {
        'ng-invalid-requested' : borrower.authorizedCreditCheck && !borrower.dateAuthorizedCreditCheck && !inEditMode &&
          urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck']?.required == 'requested',
        'ng-invalid-required' : borrower.authorizedCreditCheck && !borrower.dateAuthorizedCreditCheck && !inEditMode &&
          urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck']?.required == 'required',
        'ng-invalid-requested': inEditMode && urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck']?.required == 'requested',
        'ng-invalid-required' : inEditMode && urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck']?.required == 'required',
        'bg-transparent' : readonly
      }">
      <div class="d-flex justify-content-between">
        <span class="input-label" style="display: inline;" title="">
          <span class="input-label-overflow">Credit Check Authorized</span>
        </span>
        <div style="display: grid;">
          <label style="float: right; text-transform: none; margin-top: 5px">
            <input type="checkbox"
              class="form-check-input"
              [(ngModel)]="borrower.authorizedCreditCheck"
              (ngModelChange)="onAuthorizationCheckChanged()"
              name="isAuthorized_{{name}}"
              [disabled]="readonly || inEditMode"/>
          </label>
        </div>
      </div>
    <div class="d-flex" *ngIf="borrower.authorizedCreditCheck || inEditMode">
      <label class="me-2">Date:</label>
      <urla-date-input
        #dateAuthorizedCreditCheck="ngModel"
        [(ngModel)]="borrower.dateAuthorizedCreditCheck"
        [readonly]="readonly"
        [showBorder]="false"
        [name]="'auth_date' + name"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.dateAuthorizedCreditCheck'"
        [required]="urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck']?.required == 'required' || 
        urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck']?.required == 'requested'">
      </urla-date-input>
    </div>
  </div>

