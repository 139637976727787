import { Component, Injector, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constants } from 'src/app/services/constants';
import { UrlaMortgage } from '../models/urla-mortgage.model';
import { UrlaSectionComponent } from '../urla-section/urla-section.component';
import { UrlaRequiredState } from '../services/utility.service';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';

@Component({
  selector: 'military-service',
  templateUrl: 'military-service.component.html',
})

export class MilitaryServiceComponent extends UrlaSectionComponent {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: Record<string, UrlaRequiredState>;

  @ViewChild('militaryServiceForm')
  urlaSectionForm: NgForm;

  menuItem: string = Constants.menu.urlaMenuItems.militaryService;

  constructor(injector: Injector,
    private readonly _urlaValidationService: UrlaValidationService) {
    super(injector);
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForMilitaryService(this.mortgage);
    super.setMenuItemStatus(validityStatus);
  }
  
}
