<div class="left-sidenav sticky-menu-bar">
  <div class="menu-content h-100" data-simplebar>
    <ngx-spinner bdColor="rgba(51,51,51,0.3)" size="large" color="#fff" type="ball-scale-multiple" [fullScreen]="false"
      [name]="'urla-menu-spinner'">
      <p style="font-size: 16px; color: white">Loading...</p>
    </ngx-spinner>
    <ul
      class="metismenu left-sidenav-menu left-sidenav-menu-padding"
      id="metismenu"
    >
      <menu-item
        [label]="'1. Borrower Info'"
        (menuItemClicked)="onMenuItemClicked($event)"
        [status]="'loading'"
        [id]="'borrowerInfo'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        [label]="'2. Financial Info'"
        (menuItemClicked)="onMenuItemClicked($event)"
        [status]="'loading'"
        [id]="'financialInfo'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        (menuItemClicked)="onMenuItemClicked($event)"
        [label]="'3. Real Estate Owned'"
        [status]="'loading'"
        [id]="'reo'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        (menuItemClicked)="onMenuItemClicked($event)"
        [label]="'4. Loan & Property Info'"
        [status]="'loading'"
        [id]="'loanAndProperty'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        (menuItemClicked)="onMenuItemClicked($event)"
        [label]="'5. Declarations'"
        [status]="'loading'"
        [id]="'declarations'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        (menuItemClicked)="onMenuItemClicked($event)"
        [label]="'7. Military Service'"
        [status]="'loading'"
        [id]="'militaryService'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        (menuItemClicked)="onMenuItemClicked($event)"
        [label]="'8. Demographics'"
        [status]="'loading'"
        [id]="'demographics'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        (menuItemClicked)="onMenuItemClicked($event)"
        [label]="'9. Loan Originator Info'"
        [status]="'loading'"
        [id]="'loanOriginatorInfo'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        (menuItemClicked)="onMenuItemClicked($event)"
        [label]="'L1. Property & Loan Info'"
        [status]="'loading'"
        [id]="'propertyLoanInfo'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        (menuItemClicked)="onMenuItemClicked($event)"
        [label]="'L2. Title Info'"
        [status]="'loading'"
        [id]="'titleInfo'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        (menuItemClicked)="onMenuItemClicked($event)"
        [label]="'L3. Mortgage Loan Info'"
        [status]="'loading'"
        [id]="'mortgageLoanInfo'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        (menuItemClicked)="onMenuItemClicked($event)"
        [label]="'L4. Qualifying the Borr'"
        [status]="'loading'"
        [id]="'qualifyingTheBorr'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
      <menu-item
        (menuItemClicked)="onMenuItemClicked($event)"
        [label]="'L5. Homeownership Education & Housing Counseling'"
        [status]="'loading'"
        [id]="'homeownershipEducation'"
        [groupId]="'urla'"
        [hiddenIcon]="isReadOnly"
      >
      </menu-item>
    </ul>
  </div>
</div>
