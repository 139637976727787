import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'qa-page-body',
  templateUrl: './qa-page-body.component.html',
  styleUrls: ['./qa-page-body.component.scss'],
})
export class QaPageBodyComponent implements AfterViewInit {

  @ViewChild('header')
  protected header: ElementRef<HTMLDivElement>;

  protected headerOptions: HeaderOptions | null = null;

  ngAfterViewInit() {
    setTimeout(() => {
      this.initStickyHeader();
    });
  }

  private initStickyHeader(): void {
    // Calculate offset of the top level sticky headers to align the sticky
    // header to them.
    const adminHeader = document.querySelector('admin-header');
    const topLevelHeader = document.querySelector('.page-action-bar-sticky');
    const offsetTop = [adminHeader, topLevelHeader]
      .filter(Boolean)
      .reduce((acc, el: HTMLElement) => acc + el.offsetHeight, 0);

    this.headerOptions = {
      isSticky: false,
      offsetTop,
    }
    this.onScroll();
  }

  @HostListener('window:scroll')
  private onScroll(): void {
    const headerEl = this.header.nativeElement;

    this.headerOptions.isSticky
      = window.scrollY > headerEl.getBoundingClientRect().bottom;
  }
}

type HeaderOptions = {
  isSticky: boolean;
  offsetTop: number;
}
