import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../core/services/data.service';
import { EnvironmentService } from '../core/services/environment/environment.service';
import {
  Address,
  AusData,
  BorrowingEntityViewModel,
  Declarations,
  Demographics,
  DenialTerminationChangeDetail,
  DenialTerminationGenerateDocRequest,
  EscrowDetail,
  Extension,
  GovernmentLoanDetail,
  HousingExpense,
  LoanAmortizationPeriodTypeEnum,
  LoanDoc,
  MemberInfoOwnershipViewModel,
  Mortgage,
  MortgageBorrower,
  MortgageCalculationResponse,
  MortgageInsuranceCalculationResult,
  MortgageInsuranceDetail,
  MortgageTerm,
  NetRentalIncomeParams,
  OriginatorInformation,
  RealEstateOwned,
  SubjectProperty,
  TransactionDetail,
} from '../models';
import { MortgageCalculationDetails } from '../models/mortgage-calculation-details.model';
import { LoanPurposeTypeEnum, MortgageAppliedForTypeEnum } from '../modules/app-details/components/title-history/models/title-order.model';
import { DocPrep, DocPrepDocumentType, DocPrepPacketType } from '../modules/app-details/document-preparation/models/doc-prep.model';
import {
  BorrowerCalculatedStats,
  EmploymentCalculatedStats,
  MortgageCalculatedStats,
  UrlaBorrower,
  UrlaEmployment,
  UrlaMortgage,
} from '../modules/urla/models/urla-mortgage.model';
import { UtilityService } from '../modules/urla/services/utility.service';
import { compareNonEmploymentIncomes } from '../core/services/income-utils';

@Injectable()
export class MortgageService implements OnDestroy {

  private _appliedFor: MortgageAppliedForTypeEnum | null = null;
  private _appliedFor$ = new Subject<MortgageAppliedForTypeEnum>();
  private _enttiyTypeChanged$ = new Subject<string>();

  set appliedFor(value: MortgageAppliedForTypeEnum) {
    if (this._appliedFor === value) {
      return;
    }

    this._appliedFor = value;
    this._appliedFor$.next(value);
  }

  get appliedFor$(): Observable<MortgageAppliedForTypeEnum> {
    return this._appliedFor$.asObservable();
  }

  get enttiyTypeChanged$(): Observable<string> {
    return this._enttiyTypeChanged$.asObservable();
  }

  private _loanPurpose: LoanPurposeTypeEnum | null = null;
  private _loanPurpose$ = new Subject<LoanPurposeTypeEnum>();

  set loanPurpose(value: LoanPurposeTypeEnum) {
    if (this._loanPurpose === value) {
      return;
    }

    this._loanPurpose = value;
    this._loanPurpose$.next(value);
  }

  get loanPurpose$(): Observable<LoanPurposeTypeEnum> {
    return this._loanPurpose$.asObservable();
  }

  urlaFieldsConfig: any = {};
  quickApplyFieldsConfig: any = {};
  deepHavenUrlaFieldsConfig: any = {};

  constructor(
    private readonly _dataService: DataService,
    private readonly _environmentService: EnvironmentService,
    private readonly _http: HttpClient,
    private readonly _utilityService: UtilityService
  ) {
    this._utilityService.loadDefaultUrlaFieldsConFig(this.urlaFieldsConfig);
    this._utilityService.loadDefaultQuickApplyFieldsConFig(this.quickApplyFieldsConfig);
    this._utilityService.loadDeepHavenUrlaFieldsConFig(this.deepHavenUrlaFieldsConfig);
  }

  ngOnDestroy(): void {
    this._appliedFor$.complete();
    this._loanPurpose$.complete();
  }

  updateUrlaFieldConfigBasedOnMortgage = (mortgage: UrlaMortgage, isLinkectToLos: boolean) => {
    this._utilityService.updateUrlaFieldConfigBasedOnMortgage(this.urlaFieldsConfig, mortgage, isLinkectToLos);
  }

  publishLoanPurposeChangedEvent = (loanPurpose: LoanPurposeTypeEnum) => {
    this._loanPurpose$.next(loanPurpose);
  }

  publishEntityTypeChanged = (entityType: string) => {
    this._enttiyTypeChanged$.next(entityType);
  }

  calculateMortgageInsurance = (mortgage: UrlaMortgage): Observable<MortgageInsuranceCalculationResult> => {
    const url = `api/mortgage/mortgage-insurance/calculate`;
    return this._dataService.post(url, mortgage);
  };

  calculateNetRentalIncome = (params: NetRentalIncomeParams): Observable<MortgageCalculationResponse> => {
    const url = `api/mortgages/calculations/net-rental-income?grossRentalIncome=${params.grossRentalIncome}&occupancyFactor=${params.occupancyFactor}&mortgagePayment=${params.mortgagePayment}&miscExpenses=${params.miscExpenses}`;
    return this._dataService.post(url, {});
  };

  postRefreshOriginator = (applicationId: number): Observable<OriginatorInformation> => {
    const url = `api/mortgages/${applicationId}/refresh-originator`;
    return this._dataService.post(url, applicationId);
  };

  getMortgage = (applicationId: number): Observable<Mortgage> => {
    const url = `api/mortgages/urla2020?applicationId=${applicationId}`;
    return this._dataService.get(url);
  }

  getMortgageDetails = (applicationId: number): Observable<Mortgage> => {
    const url = `api/mortgages?applicationId=${applicationId}&autoCreateIfNotFound=false`;
    return this._dataService.get(url);
  }

  getMortgageCalculationDetails = (mortgageId: number): Observable<MortgageCalculationDetails> => {
    // ensure that there wont be any issues with undefined
    if (!mortgageId) return of(new MortgageCalculationDetails());

    const url = `api/mortgages/${mortgageId}/calculation-detail`;
    return this._dataService.get(url);
  }

  redoMortgageCalculationDetails = (mortgage: UrlaMortgage, requestedLtv: number | null = null): Observable<MortgageCalculationDetails> => {
    let url = `api/mortgages/calculation-detail`;
    if (requestedLtv) {
      url += `?requestedLtv=${requestedLtv}`;
    }
    return this._dataService.post(url, mortgage);
  }

  saveMortgage = (mortgage: Mortgage): Observable<UrlaMortgage> => {
    const url = `api/mortgages/urla2020/${mortgage.mortgageId}`;
    return this._dataService.post(url, mortgage).pipe(map(result => {
      this.initializeMortgage(result);
      return result;
    }));
  }

  refreshOriginator = (applicationId: number): Observable<OriginatorInformation> => {
    const url = `api/mortgages/${applicationId}/refresh-originator`;
    return this._dataService.post(url, {});
  }

  getUrlaMortgage = (applicationId: number): Observable<UrlaMortgage> => {
    const url = `api/mortgages/urla2020?applicationId=${applicationId}`;
    return this._dataService.get(url).pipe(map(result => {
      this.initializeMortgage(result);
      return result;
    }));
  }

  getDocPreparationData = (applicationId: number) => {
    const url = `api/mortgage/doc-prep?applicationId=${applicationId}`;
    return this._dataService.get(url);
  }

  saveDocPreparationData = (applicationId: number, docPrepData: DocPrep): Observable<DocPrep> => {
    const url = `api/mortgage/doc-prep/${applicationId}`;
    return this._dataService.post(url, docPrepData);
  }

  generateDocument = (applicationId: number, docType: DocPrepDocumentType): Observable<LoanDoc> => {
    const url = `api/mortgage/doc-prep/generate-pdf?applicationId=${applicationId}&documentType=${docType}`;
    return this._dataService.post(url, {});
  }

  generatePacket = (applicationId: number, packetType: DocPrepPacketType): Observable<LoanDoc> => {
    const url = `api/mortgage/doc-prep/generate-packet?applicationId=${applicationId}&packetType=${packetType}`;
    return this._dataService.post(url, {});
  }

  getMortgageBorrowers = (mortgageId: number): Observable<MortgageBorrower[]> => {
    const url = `api/mortgages/${mortgageId}/borrowers`;
    return this._dataService.get(url);
  }

  insertBorrowerToMortgage = (mortgageId: number, borrower: MortgageBorrower): Observable<MortgageBorrower> => {
    const url = `api/mortgages/${mortgageId}/borrowers`;
    return this._dataService.post(url, borrower);
  }

  deleteBorrowerFromMortgage = (borrowerId: number, cleanupOrphanedContacts: boolean = false): Observable<any> => {
    const url = `api/mortgage/borrowers/${borrowerId}?cleanupOrphanedContacts=${cleanupOrphanedContacts}`;
    return this._dataService.delete(url);
  }

  exportUrlaDocs = (applicationId: number): Observable<any> => {
    const url = this._environmentService.apiInfo.apiBaseUrl + 'api/mortgages/' + applicationId + '/generate-urla-docs';
    return this._http.post(url, {});
  }

  exportMismoFile = (applicationId: number, mappingTarget: string = 'BaseILADExport'): Observable<any> => {
    const url = this._environmentService.apiInfo.apiBaseUrl + 'api/mortgages/' + applicationId + `/export-mismo34?mappingTarget=${mappingTarget}`;
    const options: any = { responseType: 'blob', observe: 'response' };
    return this._http.post(url, {}, options).pipe(map((response: any) => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(response.body);

      const contentDisposition = response.headers.get('content-disposition');
      a.download = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].replaceAll("\"", "").trim();

      a.click();
    }));
  }

  exportDUFile = (applicationId: number): Observable<any> => {
    const url = this._environmentService.apiInfo.apiBaseUrl + 'api/mortgages/' + applicationId + '/export-du';
    const options: any = { responseType: 'blob', observe: 'response' };
    return this._http.post(url, {}, options).pipe(map((response: any) => {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(response.body);

      const contentDisposition = response.headers.get('content-disposition');
      a.download = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].replaceAll("\"", "").trim();

      a.click();
    }));
  }

  generateDenialTerminationDocument = (request: DenialTerminationGenerateDocRequest): Observable<number> => {
    const url = `api/mortgage/${request.mortgageId}/denial-termination-change/generate-document`;
    return this._dataService.post(url, request);
  }

  importMismoFile = (applicationId: number, mortgageId: number, file: File): Observable<UrlaMortgage> => {
    let url = 'api/mortgages/' + applicationId + '/import-mismo34/' + mortgageId;
    var formData = new FormData();
    formData.append("file", file);
    return this._dataService.postFormData(url, formData);
  }

  calculateDownPayment = (mortgage) => {
    const propertyPurchasePriceAmount = Number(mortgage.transactionDetail?.purchasePriceAmount) || 0;
    const loanAmount = Number(mortgage.mortgageTerm?.amount) || 0;
    return propertyPurchasePriceAmount - loanAmount;
  }

  initializeMortgage = (mortgage: UrlaMortgage) => {
    if (!mortgage.mortgageTerm) {
      mortgage.mortgageTerm = new MortgageTerm();
    }
    if (!mortgage.assets) {
      mortgage.assets = [];
    }
    if (!mortgage.liabilities) {
      mortgage.liabilities = [];
    }
    mortgage.liabilities.forEach(liability => {
      if (liability.payoffType === 'Full') {
        liability.partialPayoffAmount = liability.unpaidBalance;
      }
    });
    if (!mortgage.realEstateOwned) {
      mortgage.realEstateOwned = [];
    }
    mortgage.realEstateOwned.forEach(reo => {
      this.setReoDefaults(reo, mortgage);
    });
    if (!mortgage.proposedHousingExpense) {
      mortgage.proposedHousingExpense = new HousingExpense();
    }
    if (!mortgage.subjectProperty) {
      mortgage.subjectProperty = new SubjectProperty();
    }
    if (!mortgage.subjectProperty.expectedGrossMonthlyRentalIncome) {
      mortgage.subjectProperty.expectedGrossMonthlyRentalIncome = 0;
    }
    if (mortgage.subjectProperty.expectedNetMonthlyRentalIncome == null) {
      mortgage.subjectProperty.expectedNetMonthlyRentalIncome = 0;
    }
    if (!mortgage.subjectProperty.legalDescriptions) {
      mortgage.subjectProperty.legalDescriptions = [];
    } else {
      mortgage.subjectProperty.legalDescriptions.forEach(legalDescription => {
        if (!legalDescription.parcelIdentifications) {
          legalDescription.parcelIdentifications = [];
        }
      });
    }
    if (!mortgage.escrowDetail) {
      mortgage.escrowDetail = new EscrowDetail();
    }
    if (!mortgage.originatorInformation) {
      mortgage.originatorInformation = new OriginatorInformation();
    }
    if (!mortgage.extension) {
      mortgage.extension = new Extension();
    }
    if (!mortgage.extension.borrowingEntity) {
      mortgage.extension.borrowingEntity = new BorrowingEntityViewModel();
      mortgage.extension.borrowingEntity.entityAddress = new Address();
      mortgage.extension.borrowingEntity.entityMailingAddress = new Address();
    }
    if (!mortgage.extension.borrowingEntity.member1) {
      mortgage.extension.borrowingEntity.member1 = new MemberInfoOwnershipViewModel();
    }

    if (!mortgage.extension.borrowingEntity.member2) {
      mortgage.extension.borrowingEntity.member2 = new MemberInfoOwnershipViewModel();
    }

    if (!mortgage.extension.borrowingEntity.member3) {
      mortgage.extension.borrowingEntity.member3 = new MemberInfoOwnershipViewModel();
    }

    if (!mortgage.extension.borrowingEntity.member4) {
      mortgage.extension.borrowingEntity.member4 = new MemberInfoOwnershipViewModel();
    }
    if (!mortgage.mortgageInsuranceDetail) {
      mortgage.mortgageInsuranceDetail = new MortgageInsuranceDetail();
    }
    if (!mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount) {
      mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount = 0;
    }
    if (!mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount) {
      mortgage.mortgageInsuranceDetail.miOrFundingFeeFinancedAmount = 0;
    }
    if (!mortgage.governmentLoanDetail) {
      mortgage.governmentLoanDetail = new GovernmentLoanDetail();
    }
    if (!mortgage.ausData) {
      mortgage.ausData = new AusData();
    }
    if (!mortgage.transactionDetail) {
      mortgage.transactionDetail = new TransactionDetail();
    } else {
      if (!mortgage.transactionDetail.purchaseCredits) {
        mortgage.transactionDetail.purchaseCredits = [];
      }
    }

    if (!mortgage.denialTerminationChangeDetail) {
      mortgage.denialTerminationChangeDetail = new DenialTerminationChangeDetail();
    }

    if (
      mortgage.transactionDetail.estimatedClosingCostsAmount &&
      !mortgage.transactionDetail.prepaidItemsEstimatedAmount &&
      !mortgage.transactionDetail.prepaidEscrowsTotalAmount &&
      !mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount
    ) {
      mortgage.transactionDetail.estimatedClosingCostsExcludingPrepaidsAmount = mortgage.transactionDetail.estimatedClosingCostsAmount;
    }

    if (!mortgage.mortgageTerm.loanAmortizationPeriodType) {
      mortgage.mortgageTerm.loanAmortizationPeriodType = LoanAmortizationPeriodTypeEnum.Month;
    }

    mortgage.calculatedStats = new MortgageCalculatedStats();
    let nextIndex = Math.max.apply(Math, mortgage.borrowers.map(o => o.printApplicationIndex > -1 ? o.printApplicationIndex : -1)) + 1;
    mortgage.borrowers.forEach(borrower => {
      if (borrower.printApplicationIndex == undefined || borrower.printApplicationIndex == null) {
        borrower.printApplicationIndex = nextIndex;
        nextIndex++;
      }

      if (!borrower.governmentMonitors) {
        borrower.governmentMonitors = new Demographics();
      }
      if (!borrower.aliases) {
        borrower.aliases = [];
      }
      if (!borrower.currentHousingExpenses) {
        borrower.currentHousingExpenses = new HousingExpense();
      }
      if (!borrower.declarations) {
        borrower.declarations = new Declarations();
      }
      if (!borrower.dependents) {
        borrower.dependents = [];
      }
      if (!borrower.employments) {
        borrower.employments = [];
      } else {
        borrower.employments.forEach(e => {
          if (!e.address) {
            e.address = new Address();
          } else {
            if (e.address.state) { // fixing for old records
              e.address.country = "us";
            }
          }
        });
      }
      if (!borrower.nonEmploymentIncomes) {
        borrower.nonEmploymentIncomes = [];
      } else {
        this.sortBorrowerNonEmploymentIncomes(borrower)
      }
      if (borrower.homePhone) {
        borrower.homePhone = borrower.homePhone.trim();
      }
      if (borrower.mobilePhone) {
        borrower.mobilePhone = borrower.mobilePhone.trim();
      }
      if (!borrower.residencyAddresses) {
        borrower.residencyAddresses = [];
      }
      borrower.residencyAddresses.forEach(ra => {
        if (!ra.address) {
          ra.address = new Address();
        }
      });
      (<UrlaBorrower>borrower).calculatedStats = new BorrowerCalculatedStats();
      borrower.employments.forEach(employment => {
        (<UrlaEmployment>employment).calculatedStats = new EmploymentCalculatedStats();
        if (!employment.incomes) {
          employment.incomes = [];
        }
      })
    });
    mortgage.borrowers = _.orderBy(mortgage.borrowers, ['printApplicationIndex', 'jointWithBorrowerId'], ['asc', 'desc']);

    const initializeSubjects = () => {
      const appliedFor =
        mortgage.mortgageTerm.mortgageAppliedFor as MortgageAppliedForTypeEnum;
      if (this._appliedFor !== appliedFor) {
        this.appliedFor = appliedFor;
      }

      const loanPurpose =
        mortgage.subjectProperty.purposeOfLoan as LoanPurposeTypeEnum;
      if (this._loanPurpose !== loanPurpose) {
        this.loanPurpose = loanPurpose;
      }
    }
    initializeSubjects();
  }

  private sortBorrowerNonEmploymentIncomes(borrower: MortgageBorrower) {
    borrower.nonEmploymentIncomes.sort(
      compareNonEmploymentIncomes
    );
  }

  private setReoDefaults(reo: RealEstateOwned, mortgage: UrlaMortgage) {
    if (!reo.mortgagePayment) {
      reo.mortgagePayment = 0;
    }
    if (!reo.address2) {
      reo.address2 = undefined;
    }
    if (!reo.typeOfProperty) {
      reo.typeOfProperty = undefined;
    }
    if (!reo.dispositionStatus) {
      reo.dispositionStatus = undefined;
    }
    if (!reo.intendedUsageType) {
      reo.intendedUsageType = undefined;
    }
    if (!reo.currentUsageType) {
      reo.currentUsageType = undefined;
    }
    if (!reo.assetAccountId) {
      reo.assetAccountId = undefined;
    }
    if (!reo.monthlyMiscExpenses) {
      reo.monthlyMiscExpenses = 0;
    }
    this.calculateMortgagePayment(reo, mortgage);
  }

  private calculateMortgagePayment = (reo: RealEstateOwned, mortgage: UrlaMortgage) => {
    let existingLiabilitiesAttachedToReo = mortgage.liabilities.filter(l => l.reoId == reo.reoId && l.unpaidBalance > 0 &&
      l.owningBorrowerIds.some(borrowerId => reo.owningBorrowerIds.indexOf(Number(borrowerId)) >= 0));
    reo.amountOfMortgage = existingLiabilitiesAttachedToReo?.reduce((total, liability) => total + (Number(liability.unpaidBalance) || 0) - (Number(liability.partialPayoffAmount) || 0), 0);
  }
}
