import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {ApplicationContext, Borrower, LoanApplication} from '../../../../models';
import {autoId} from '../../../../core/services/utils';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConditionsTask} from '../../../../models/task/conditons-task.model';
import {TaskService} from '../../../../services/task.service';
import {defaultIfEmpty, firstValueFrom, Subscription, take} from 'rxjs';
import {NotificationService} from '../../../../services/notification.service';
import {LoanDocDashboardTask} from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import {ApplicationContextService} from '../../../../services/application-context.service';

const defaultActionLabel: string = 'Send Reminder';

@Component({
  selector: 'task-email-sender',
  templateUrl: './task-email-sender.component.html',
  styleUrls: ['./task-email-sender.component.scss'],
})
export class TaskEmailSenderComponent implements OnChanges, OnInit, OnDestroy {

  @Input() 
  borrower: Borrower;

  @Input() 
  task: ConditionsTask | LoanDocDashboardTask;

  get forceEmailUpdate() {
    return this._forceEmailUpdate;
  }

  @Input()
  set forceEmailUpdate(forceEmailUpdate) {
    this._forceEmailUpdate = forceEmailUpdate;
    this.isEmailOverridden = this.forceEmailUpdate;
  }

  @Output() 
  cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output() 
  send: EventEmitter<void> = new EventEmitter<void>();

  protected email: string = '';
  protected isEmailOverridden: boolean = false;
  protected borrowerName: string = '';

  protected isEmailDirty: boolean = false;
  protected actionLabel: string = defaultActionLabel;
  protected isLoading: boolean = true;

  private _id: string = autoId();
  private _originatorEmail: string;
  private _forceEmailUpdate: boolean;

  private _contextSubscription?: Subscription;
  private _loanChangeSubscription?: Subscription;

  constructor(
    private readonly _taskService: TaskService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.borrower) {
      this.onChangeEmail(this.borrower?.email ?? '');
      this.borrowerName = getFullName(this.borrower);
    }

    this.invalidateStateProperties();
  }

  ngOnInit(): void {
    this.invalidateStateProperties();
    this.isEmailOverridden = this.forceEmailUpdate;
    this.isLoading = true;
    this._contextSubscription = this._applicationContextService.context.subscribe((context) => {
      this.initOriginatorEmail(context);
      this.isLoading = false;
    });
    this._loanChangeSubscription = this._applicationContextService.loanInfoChanges.subscribe((context) => {
      this.initOriginatorEmail(context);
    });
  }

  ngOnDestroy(): void {
    this._loanChangeSubscription?.unsubscribe();
    this._contextSubscription?.unsubscribe();
  }

  private initOriginatorEmail(context: ApplicationContext) {
    if (!context.application) {
      return;
    }
    const originator = context.globalConfig.allUsersIncludingTpo.find(u => u.userCompanyGuid === context.application.userId);
    if (originator) {
      this._originatorEmail = originator.email;
    }
  }

  protected id(elementId: string): string {
    return `${elementId}-${this._id}`;
  }

  protected onChangeEmail(email: string): void {
    this.email = email;
    this.invalidateIsEmailDirty();
  }

  protected onChangeEmailOverride(checked: boolean): void {
    this.isEmailOverridden = checked;
    this.invalidateActionLabel();
  }

  protected onClickCancel(): void {
    this.cancel.emit();
  }

  private invalidateStateProperties(): void {
    this.invalidateIsEmailDirty();
    this.invalidateActionLabel();
  }

  private invalidateIsEmailDirty(): void {
    this.isEmailDirty = this.email !== this.borrower?.email;
  }

  private invalidateActionLabel(): void {
    this.actionLabel = this.isEmailOverridden
      ? `Update Borrower Email and ${defaultActionLabel}`
      : defaultActionLabel;
  }

  protected async sendEmail(): Promise<void> {
    if (this.email && this.email.toLowerCase() === this._originatorEmail?.toLowerCase()) {
      this._notificationService.showError(
        "Borrower's email address cannot be the same as the Loan Originator",
        'Error'
      );
      return;
    }

    await this._spinnerService.show();

    let params: Parameters<TaskService['sendEsignEmail']>[1] = {};
    params.recipientEmailOverride = this.email;

    if (this.isEmailOverridden) {
      params.writeOverrideEmailToBorrower = true;
    }

    try {
      await firstValueFrom(
        this._taskService.sendEsignEmail(this.task, params).pipe(defaultIfEmpty(undefined))
      );

      this.send.emit();
    } catch (error) {
      const defaultMessage = 'Failed to send email.';
      console.error('Failed to send email.', error);

      const message = error?.message ?? defaultMessage;

      this._notificationService.showError(message, 'Error');

      throw error;
    } finally {
      await this._spinnerService.hide();
    }
  }
}

function getFullName(borrower: Borrower): string {
  const { fullName, firstName, middleName, lastName } = borrower;
  if (fullName) {
    return fullName;
  }

  return [firstName, middleName, lastName].filter(Boolean).join(' ');
}
