import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ProcessSubmissionModel } from '../../models/process-submission.model';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { ApplicationContextBoundComponent } from '../../../../shared/components';
import { ChannelEnum } from "../../../../models";

@Component({
    selector: 'missing-information',
    templateUrl: './missing-information.component.html',
    styleUrls: ['./missing-information.component.scss']
})
export class MissingInformationComponent extends ApplicationContextBoundComponent implements OnInit {
    @ViewChild('missingInformationForm') missingInformationForm: NgForm | undefined;

    @Input()
    processSubmissionData: ProcessSubmissionModel;

    @Input()
    context;

    @Input()
    allowedLoanCharacterstics;

    @Input()
    allowedBorrowerCharacterstics;

    @Input()
    isCorrSearchForCreditOnSubmissionEnabled: boolean;

    @Output()
    nextStep: EventEmitter<{ processSubmissionData: ProcessSubmissionModel }> = new EventEmitter<any>();

    @Output()
    previousStep: EventEmitter<any> = new EventEmitter<any>();

    urlaFieldsConfig: {} = {};
    showSubjectProperty: boolean = false;
    showCreditRefNumber: boolean = true;

    applications: Array<any> = [];
    nothingMissing: boolean = false;
    
    constructor(
        private readonly injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        //validate property info is there
        this.showSubjectProperty = !this.processSubmissionData.loanData?.mortgage?.subjectProperty?.noOfUnits || false;
        this.showCreditRefNumber =
            this.processSubmissionData.channel !== ChannelEnum.Correspondent ||
            (this.processSubmissionData.channel === ChannelEnum.Correspondent && this.isCorrSearchForCreditOnSubmissionEnabled);

        if (!!this.processSubmissionData.loanData?.mortgage) {
            _.orderBy(this.processSubmissionData.loanData.mortgage.borrowers, [["printApplicationIndex", "asc"]]).forEach(borr => {
                if (borr.printApplicationIndex > -1) {
                    var app = this.applications.find(x => x.appIndex == borr.printApplicationIndex);
                    if (!app)
                        this.applications.push({ appIndex: borr.printApplicationIndex, displayName: borr.firstName + ' ' + borr.lastName, mortgageBorrowerId: borr.borrowerId, creditRefNumber: borr.creditRefNumber });
                    else
                        app.displayName = app.displayName + " & " + borr.firstName + ' ' + borr.lastName;
                }
            });
        } else {
            this.nothingMissing = true;
        }
    }

    back = () => {
        this.previousStep.emit();
    }

    proceed = () => {
        if (!this.processSubmissionData.loanData) {
            this.nextStep.emit({ processSubmissionData: this.processSubmissionData });
            return;
        }

        this.missingInformationForm.form.markAllAsTouched();
        if (this.missingInformationForm.form.valid) {
            this.processSubmissionData.loanData.mortgage.borrowers.forEach(borr => {
                borr.creditRefNumber = this.applications.find(x => x.appIndex == borr.printApplicationIndex)?.creditRefNumber;
            });
            this.nextStep.emit({ processSubmissionData: this.processSubmissionData });
        }
    }
}
