<form #borrowerDemographicForm="ngForm" name="borrowerDemographicForm{{borrower.borrowerId}}" id="borrowerDemographicForm{{borrower.borrowerId}}">
<div class="grid-layout">
  <div class="grid-layout px-2">
    <div class="row mb-4">
      <div class="col-sm-6" style="height: 100%;">
        <div class="form-input" *ngIf="!inEditMode" [attr.disabled]="isReadOnly ? '' : null"
          [ngClass]="{
            'invalid-required-field': !isEthnicitySelected && urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity']?.required == 'required',
            'invalid-requested-field': !isEthnicitySelected && urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity']?.required == 'requested',
            'disabled-field': isReadOnly}">
          <div class="field-description">
            <span class="left-space">Ethnicity</span>
          </div>

          <div class="grid-row-separator"></div>

          <div class="select-many-checkbox select-many-checkbox-horizontal">
            <multi-checkbox-picker
              (change)='ethnicityChanged()'
              [(ngModel)]='ethnicities'
              [name]="borrower.borrowerId + '_ethnicity'"
              [options]='ethnicityOptions'
              [readonly]="isReadOnly"
              [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity']?.required == 'required' ||
              urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity']?.required == 'requested'"
              [small]='true'>
            </multi-checkbox-picker>
          </div>

          <div class="grid-row-separator"></div>

          <div class="border-outline grid-layout mt-10" *ngIf="isHispanicOrLatinoSelected">
            <div class="grid-row">
              <div class="select-many-checkbox select-many-checkbox-horizontal field-editor-container">
                <multi-checkbox-picker
                  [name]="borrower.borrowerId + '_ethnicityOrigin'"
                  [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOrigin']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOrigin']?.required == 'requested'"
                  [options]="hispanicOriginOptions"
                  [readonly]="isReadOnly"
                  [(ngModel)]="ethnicityOrigins"
                  (change)="ethnicityOriginChanged()"
                  [small]="true">
                </multi-checkbox-picker>
              </div>
            </div>

            <div class="grid-row" *ngIf="isOtherSelectedForHispanicOrLatinoEthnicity">
              <urla-text-input class="col-sm-12 col-xs-12" [readonly]="isReadOnly" name="ethnicityOriginOtherDescription"
                [(ngModel)]="borrower.governmentMonitors.ethnicityOriginOtherDescription" placeholder="Enter origin here">
              </urla-text-input>
            </div>
          </div>

          <div class="grid-row-separator"></div>

          <div class="border-outline grid-layout  mt-10" *ngIf="isOtherSelectedForEthnicity">
            <div class="grid-row">
              <urla-text-input
                [(ngModel)]='borrower.governmentMonitors.ethnicityOtherDescription'
                [readonly]='isReadOnly'
                [required]='urlaFieldsConfig["mortgage.borrower.governmentMonitors.ethnicityOtherDescription"] == "required" ||
                  urlaFieldsConfig["mortgage.borrower.governmentMonitors.ethnicityOtherDescription"] == "requested"'
                class='col-12'
                name='ethnicityOtherDescription'
                placeholder='Enter ethnicity here'
              ></urla-text-input>
            </div>
          </div>
        </div>
        <div class="form-input" *ngIf="inEditMode" [ngClass]="{
          'invalid-required-field': urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity']?.required == 'required',
          'invalid-requested-field': urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity']?.required == 'requested'
        }">
          
          <edit-mode-input 
            [label]="'Ethnicity'" 
            [name]="'mortgageBorrowerGovernmentMonitorsEthnicityConfig'" 
            [urlaFieldsConfig]="urlaFieldsConfig" 
            [fieldBeingEdited]="'mortgage.borrower.governmentMonitors.ethnicity'">
          </edit-mode-input>
        </div>
        <div class="grid-row-separator"></div>
        <div class="form-input" *ngIf="!inEditMode" [attr.disabled]="isReadOnly ? '' : null"
          [ngClass]="{
            'invalid-required-field': !isSexSelected && urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex']?.required == 'required',
            'invalid-requested-field': !isSexSelected && urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex']?.required == 'requested',
            'disabled-field': isReadOnly}">
          <div class="field-description">
            <span class="left-space">Sex</span>
          </div>

          <div class="grid-row-separator"></div>

          <div class="select-many-checkbox select-many-checkbox-horizontal">
            <div class="row">
              <div class="col-sm-12" *ngFor="let sex of sexOptions">
              </div>
              <multi-checkbox-picker
                [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex']?.required == 'required' ||
                  urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex']?.required == 'requested'"
                [name]="borrower.borrowerId + '_sex'"
                [options]="sexOptions"
                [readonly]="isReadOnly"
                [(ngModel)]="sexes"
                (change)="onSexInformationChanged()"
                [vertical]="true"
                [small]="true">
              </multi-checkbox-picker>
            </div>
          </div>

          <div class="grid-row-separator"></div>

        </div>
        <div class="form-input" *ngIf="inEditMode" [ngClass]="{
          'invalid-required-field': urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex']?.required == 'required',
          'invalid-requested-field': urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex']?.required == 'requested'
        }">
          <edit-mode-input 
            [label]="'Sex'" 
            [name]="'mortgageBorrowerGovernmentMonitorsSexConfig'" 
            [urlaFieldsConfig]="urlaFieldsConfig" 
            [fieldBeingEdited]="'mortgage.borrower.governmentMonitors.sex'">
          </edit-mode-input>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-input" *ngIf="!inEditMode" [attr.disabled]="isReadOnly ? '' : null"
          [ngClass]="{
            'invalid-required-field': !isRaceSelected && urlaFieldsConfig['mortgage.borrower.governmentMonitors.race']?.required == 'required',
            'invalid-requested-field': !isRaceSelected && urlaFieldsConfig['mortgage.borrower.governmentMonitors.race']?.required == 'requested',
            'disabled-field': isReadOnly
          }">
          <div class="field-description">
            <span class="left-space">Race</span>
          </div>

          <div class="grid-row-separator"></div>

          <div class="select-many-checkbox select-many-checkbox-horizontal">
            <div class="row">

              <input type="hidden" [(ngModel)]="borrower.governmentMonitors.race"
                        [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.race']?.required == 'required' ||
                          urlaFieldsConfig['mortgage.borrower.governmentMonitors.race']?.required == 'requested'"
                        [name]="borrower.borrowerId + '_race'">
              <div class="col-sm-12" *ngFor="let race of raceOptions">

                <div class="form-check form-check-inline">
                  <div class="checkbox-primary me-2">
                    <input id="{{borrower.borrowerId}}_race_{{race.name}}" class="form-check-input"
                        [value]="race.value" type="checkbox" [name]="borrower.borrowerId + '_race_' + race.name"
                        (change)="raceChanged($event, race.value)" [checked]="races.includes(race.value)"
                        [disabled]="isReadOnly">
                    <label for="{{borrower.borrowerId}}_race_{{race.name}}">
                      {{race.name}}
                    </label>
                  </div>
                </div>

                <div class="border-outline grid-layout mt-10 mb-10"
                  *ngIf="race.value == nativeAmericanRace && isNativeAmericanSelected">
                  <div class="grid-row">
                    <urla-text-input
                      [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.nativeAmericanTribeName']?.required == 'required' ||
                        urlaFieldsConfig['mortgage.borrower.governmentMonitors.nativeAmericanTribeName']?.required == 'requested'"
                      class="col-sm-12 col-xs-12"
                      [name]="'tribeName_' + borrower.borrowerId"
                      [(ngModel)]="borrower.governmentMonitors.nativeAmericanTribeName"
                      [readonly]="isReadOnly"
                      placeholder="Enter name of enrolled or principal tribe">
                    </urla-text-input>
                  </div>
                </div>

                <div class="border-outline grid-layout mt-10 mb-10" *ngIf="race.value == asianRace && isAsianSelected">
                  <div class="grid-row">
                    <div class="select-many-checkbox select-many-checkbox-horizontal field-editor-container">
                      <multi-checkbox-picker
                        [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.raceDesignation']?.required == 'required' ||
                          urlaFieldsConfig['mortgage.borrower.governmentMonitors.raceDesignation']?.required == 'requested'"
                        [name]="borrower.borrowerId + '_asianRaceDesignation'"
                        [options]="asianRaceDesignationOptions"
                        [(ngModel)]="raceDesignations"
                        [readonly]="isReadOnly"
                        (change)="raceDesignationChanged()"
                        [small]="true">
                      </multi-checkbox-picker>
                    </div>
                  </div>

                  <div class="grid-row" *ngIf="isOtherSelectedForAsianRace">
                    <urla-text-input
                      [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.otherAsianRace']?.required == 'required' ||
                        urlaFieldsConfig['mortgage.borrower.governmentMonitors.otherAsianRace']?.required == 'requested'"
                      class="col-sm-12 col-xs-12"
                      [(ngModel)]="borrower.governmentMonitors.otherAsianRace"
                      placeholder="Enter race here"
                      [name]="'otherAsian_' + borrower.borrowerId"
                      [readonly]="isReadOnly">
                    </urla-text-input>
                  </div>
                </div>

                <div class="border-outline grid-layout mt-10 mb-10"
                  *ngIf="race.value == pacificIslanderRace && isPacificIslanderSelected">
                  <div class="grid-row">
                    <div class="select-many-checkbox select-many-checkbox-horizontal field-editor-container">
                      <multi-checkbox-picker
                        [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.raceDesignation']?.required == 'required' ||
                          urlaFieldsConfig['mortgage.borrower.governmentMonitors.raceDesignation']?.required == 'requested'"
                        [name]="borrower.borrowerId + '_pacificIslanderRaceDesignation'"
                        [options]="pacificIslanderRaceDesignationOptions"
                        [readonly]="isReadOnly"
                        [(ngModel)]="raceDesignations"
                        (change)="raceDesignationChanged()"
                        [small]="true">
                      </multi-checkbox-picker>
                    </div>
                  </div>

                  <div class="grid-row" *ngIf="isOtherSelectedForPacificIslanderRace">
                    <urla-text-input
                      [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.otherPacificIslanderRace']?.required == 'required' ||
                        urlaFieldsConfig['mortgage.borrower.governmentMonitors.otherPacificIslanderRace']?.required == 'requested'"
                      class="col-sm-12 col-xs-12"
                      [name]="'otherPacificislander_' + borrower.borrowerId"
                      [readonly]="isReadOnly"
                      [(ngModel)]="borrower.governmentMonitors.otherPacificIslanderRace"
                      placeholder="Enter race here">
                    </urla-text-input>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="grid-row-separator"></div>

        </div>
        <div class="form-input" *ngIf="inEditMode" [ngClass]="{
          'invalid-required-field': urlaFieldsConfig['mortgage.borrower.governmentMonitors.race']?.required == 'required',
          'invalid-requested-field': urlaFieldsConfig['mortgage.borrower.governmentMonitors.race']?.required == 'requested'
        }">
          <edit-mode-input 
            [label]="'Race'" 
            [name]="'mortgageBorrowerGovernmentMonitorsRaceConfig'" 
            [urlaFieldsConfig]="urlaFieldsConfig" 
            [fieldBeingEdited]="'mortgage.borrower.governmentMonitors.race'">
          </edit-mode-input>
        </div>
      </div>
    </div>
  </div>
  <div class="grid-layout has-border px-2">
    <div class="grid-layout-row">
      <urla-grid-cell [disabled]="isReadOnly" class="col-md-10 grid-layout-text left-space"
          [label]="'Application Taken By'" [ngClass]="{'bg-transparent': !isReadOnly}">
      </urla-grid-cell>
      <urla-dropdown [readonly]="isReadOnly" class="col-md-2" [ngClass]="{'bg-transparent': !isReadOnly}" [options]="applicationChannelOptions"
        [name]="'applicationChannel_' + borrower.borrowerId"
        [(ngModel)]="borrower.governmentMonitors.applicationTakenMethod" (ngModelChange)="onMethodByWhichApplicationIsTakenChanged()"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.governmentMonitors.applicationTakenMethod'"
        [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod']?.required == 'required' ||
        urlaFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod']?.required == 'requested'">
      </urla-dropdown>
    </div>
    <div class="grid-layout-row" *ngIf="borrower.governmentMonitors.applicationTakenMethod == 'FaceToFace' || borrower.companyId != 229">
      <urla-grid-cell [disabled]="isReadOnly" class="col-md-10 grid-layout-text left-space" [ngClass]="{'bg-transparent': !isReadOnly}"
          [label]="'Was the ethnicity of the Borrower collected on the basis of visual observation or surname?'">
      </urla-grid-cell>
      <urla-radio-group [readonly]="isReadOnly" class="col-md-2" [ngClass]="{'bg-transparent': !isReadOnly}" [(ngModel)]="borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname"
            [options]="yesNoOptions" [name]="'isEthnicityBasedOnVisualOrSurname_' + borrower.borrowerId" layout="horizontal"
            [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'"
            [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname']?.required == 'required' ||
            urlaFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname']?.required == 'requested'">
      </urla-radio-group>
    </div>
    <div class="grid-layout-row" *ngIf="borrower.governmentMonitors.applicationTakenMethod == 'FaceToFace' || borrower.companyId != 229">
      <urla-grid-cell [disabled]="isReadOnly" class="col-md-10 grid-layout-text left-space" [ngClass]="{'bg-transparent': !isReadOnly}"
          [label]="'Was the sex of the Borrower collected on the basis of visual observation or surname?'">
      </urla-grid-cell>
      <urla-radio-group [readonly]="isReadOnly" class="col-md-2" [ngClass]="{'bg-transparent': !isReadOnly}" [(ngModel)]="borrower.governmentMonitors.isGenderBasedOnVisualOrSurname"
            [options]="yesNoOptions" [name]="'isGenderBasedOnVisualOrSurname_' + borrower.borrowerId" layout="horizontal"
            [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'"
            [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname']?.required == 'required' ||
            urlaFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname']?.required == 'requested'">
      </urla-radio-group>
    </div>
    <div class="grid-layout-row" *ngIf="borrower.governmentMonitors.applicationTakenMethod == 'FaceToFace' || borrower.companyId != 229">
      <urla-grid-cell [disabled]="isReadOnly" class="col-md-10 grid-layout-text left-space" [ngClass]="{'bg-transparent': !isReadOnly}"
          [label]="'Was the race of the Borrower collected on the basis of visual observation or surname?'">
      </urla-grid-cell>
      <urla-radio-group [readonly]="isReadOnly" class="col-md-2" [ngClass]="{'bg-transparent': !isReadOnly}" [(ngModel)]="borrower.governmentMonitors.isRaceBasedOnVisualOrSurname"
            [options]="yesNoOptions" [name]="'isRaceBasedOnVisualOrSurname_' + borrower.borrowerId" layout="horizontal"
            [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'"
            [required]="urlaFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname']?.required == 'required' ||
            urlaFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname']?.required == 'requested'">
      </urla-radio-group>
    </div>
  </div>
</div>
</form>
