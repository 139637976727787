import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { BaseUrlaInputComponent } from '../base-urla-input.component';

@Component({
  selector: 'urla-textarea-input',
  templateUrl: 'urla-textarea-input.component.html',
  providers: [MakeProvider(UrlaTextAreaInputComponent)]
})
export class UrlaTextAreaInputComponent extends BaseUrlaInputComponent {

  @Output()
  editClickEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  copyClicked: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  rows: number = 3;

  @Input()
  hasEditButton = false;

  @Input()
  isEditableEvenIfItHasEditButton: boolean = false;

  @Input()
  maxlength: number;

  @Input()
  copyTitleText: string;

  @Input()
  copyButtonVisible: boolean = false;

  constructor() {
    super();
  }

}
