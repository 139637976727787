import { AfterViewInit, Component, Injector, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { Utils } from "src/app/core/services/utils";
import { ApplicationContext } from "src/app/models";
import { Constants } from 'src/app/services/constants';
import { ApplicationContextBoundComponent } from "src/app/shared/components";
import { ThisMonth, DateRangeBase } from "src/app/shared/components/date-range-filter/date-range-filter.component";
import { DrawerComponent } from "src/app/shared/components/drawer/drawer.component";
import { FilterOption, GenericFilterComponent } from "src/app/shared/components/generic-filter/generic-filter.component";
import { Column } from "src/app/shared/models/table-config.model";
import { DrawerOptions, DrawerService, DrawerSize } from "src/app/shared/services/drawer.service";
import { LeadTableComponent } from "../lead-table/lead-table.component";
import { LeadTableConfig } from "../lead-table/models/lead-table-config.model";
import { LeadFilterDialogComponent } from "./components/dialogs/lead-filter-dialog/lead-filter-dialog.component";
import { LeadIntervalConfigDialogComponent } from "./components/dialogs/lead-interval-config-dialog/lead-interval-config-dialog.component";
import { PowerUserLeadTableComponent } from "./components/power-user-lead-table/power-user-lead-table.component";
import { LeadFilters } from "./models/lead-filters.model";
import { Lead } from "./models/lead.model";
import { LeadsService } from "./services/leads.service";
import { CommonService } from "src/app/services/common.service";
import { LeadsPreferences, WebPreferences } from "src/app/models/web-preferences.model";

@Component({
  selector: 'leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren("leadsFilter")
  filters: QueryList<GenericFilterComponent>;

  @ViewChild('leadTable')
  leadTable: LeadTableComponent;

  @ViewChild('powerUserLeadTable')
  powerUserLeadTable: PowerUserLeadTableComponent;


  @ViewChild('editLeadEditorDrawer')
  editLeadEditorDrawer: DrawerComponent;

  @ViewChild('addLeadEditorDrawer')
  addLeadEditorDrawer: DrawerComponent;

  selectedFilters: LeadFilters = new LeadFilters();

  isMetricsOpened: boolean = false;
  isMenuOpen: boolean = false;

  leadTableConfig: LeadTableConfig;

  usersForFiltering: FilterOption[] = [];
  campaignsForFiltering: FilterOption[] = [];
  branchesForFiltering: FilterOption[] = [];
  statesForFiltering: FilterOption[] = [];
  tagsForFiltering: FilterOption[] = [];
  leadStatusesForFiltering: FilterOption[] = [];

  exportOnlyColumnsMapping: Map<string, Column[]> = new Map<string, Column[]>();

  leadToEdit: Lead = null;

  newLeadDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  editLeadDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  showUnassignedLeads: boolean = false;
  showInactiveUsers: boolean = false;

  intervalConfig: any = null;

  masterRefreshCounterDisplay: string = null;

  inPowerUserMode: boolean = false;

  context: ApplicationContext;

  webPreferences: WebPreferences;

  private _refreshInterval = null;
  private _autoRefreshCounter: number = 0;

  private _activatedRouteSubcription: Subscription;
  private _applicationContextSubscription: Subscription;
  private _leadInsertedSubscription: Subscription;
  private _updateLeadLeadSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _leadService: LeadsService,
    private readonly _drawerService: DrawerService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _commonService: CommonService
  ) {
    super(injector);
    this.selectedFilters.dateRange = new ThisMonth();
  }

  ngOnInit(): void {
    this.getAllLeadLists();

    this._applicationContextSubscription = this.applicationContextService.context.subscribe(context => {
      this.context = _.cloneDeep(context);

      this.campaignsForFiltering = context.globalConfig.leadCampaigns.map(c => ({
        displayName: c.name,
        value: c.leadCampaignId,
        groupName: "All",
        isSelected: false,
      }));

      this.branchesForFiltering = (context.userPermissions.admin ?
        context.globalConfig.branches :
        context.globalConfig.enabledBranches).map(b => ({
          displayName: b.branchName,
          value: b.branchId,
          groupName: "All",
          isSelected: false,
        }));

      this.statesForFiltering = Object.keys(context.globalConfig.states).map(key => ({
        displayName: context.globalConfig.states[key],
        value: key,
        groupName: "All",
        isSelected: false,
      }))

      this.leadStatusesForFiltering = context.globalConfig.leadStatus.map(status => ({
        displayName: status.loanStatusName,
        value: status.loanStatusId,
        groupName: "All",
        isSelected: false,
      }))

      this.getAllLeadUsers(context);
    })

    this.leadTableConfig = {
      columns: [
        { field: 'lastName', header: 'Name', order: 1, visible: true },
        { field: 'mobilePhone', header: 'Contact Info', order: 2, visible: true },
        { field: 'leadStatusId', header: 'Status / Note', order: 3, visible: true },
        { field: 'loanAmount', header: 'Rate / Loan Amount', order: 4, visible: true },
        { field: 'leadContactUserId', header: 'Assigned To', order: 5, visible: true },
        { field: 'leadCampaignId', header: 'Campaign / Source / Ref', order: 6, visible: false },
        { field: 'leadListIds', header: 'Referral Source / Tags', order: 7, visible: true },
        { field: 'contactAttempts', header: 'Attempts / Redistributions', order: 8, visible: true },
        { field: 'lastSuccessfulContact', header: 'Attempted Contact / Last Success', order: 9, visible: true },
        { field: 'dateInserted', header: 'Created On', order: 10, visible: true },
      ],
      isAddTodialListButtonVisible: true,
      isNewButtonVisible: true,
      isSearchButtonVisible: true,
      isFilterButtonsVisible: true,
      isDeleteButtonVisible: this.applicationContext.userPermissions.admin,
      isRowSelectionVisible: true,
      isColumnSelectionVisible: true,
      isReassignLeadVisible: true,
      isExportToCsvVisible: this.applicationContext.userPermissions.allowExportLeadsToCsv,
      scrollOffset: 300,
      extraGlobalFilterFields: ['firstName', 'lastName', 'mobilePhone', 'homePhone', 'workPhone', 'email', 'leadSource', 'mostRecentNote', 'subjectPropertyAddress1', 'subjectPropertyCity', 'subjectPropertyState', 'subjectPropertyZip']
    };

    this.setExportOnlyColumnMappingsForStandardMode();

    this._commonService.getWebPreferences().subscribe(response => {
      this.webPreferences = response || new WebPreferences();
      if (!this.webPreferences.leadsPreferences) {
        this.webPreferences.leadsPreferences = new LeadsPreferences();
      }
      if (this.webPreferences.leadsPreferences.powerUserModeToggledOn != undefined) {
        this.inPowerUserMode = this.webPreferences.leadsPreferences.powerUserModeToggledOn;
      }
      this.fetchFilter();
    });

    this.intervalConfig = this._localStorageService.getItem("leads-interval-config") || {};
    this.initializeAutoRefreshTimer();

    this._updateLeadLeadSubscription = this._leadService.updateLeadsTableSubject.subscribe((lead) => {
      this.updateLeadTable(lead.leadId);
    })
  }

  ngAfterViewInit(): void {
    this._activatedRouteSubcription = this._activatedRoute.queryParams.subscribe(params => {
      if (params.filter === 'unassigned') {
        this.leadTable.showUnassignedLeads = true
        this.leadTable.getLeads();
      }
    })
  }

  ngOnDestroy(): void {
    if (this._refreshInterval) {
      this.clearAutoRefreshInterval();
    }
    if (this._activatedRouteSubcription) {
      this._activatedRouteSubcription.unsubscribe();
    }
    if (this._applicationContextSubscription) {
      this._applicationContextSubscription.unsubscribe();
    }
    if (this._leadInsertedSubscription) {
      this._leadInsertedSubscription.unsubscribe();
    }
    if (this._updateLeadLeadSubscription) {
      this._updateLeadLeadSubscription.unsubscribe();
    }
  }

  unassignedChange = (isChecked: boolean): void => {
    this.showUnassignedLeads = isChecked;
    this.selectedFilters = { ...this.selectedFilters };
  }

  showAsArchiveChange = (isChecked: boolean): void => {
    this.selectedFilters.showArchived = isChecked;
    this.selectedFilters = { ...this.selectedFilters };
  }

  showInactiveUsersChange = (isChecked: boolean): void => {
    this.showInactiveUsers = isChecked;
    this.getAllLeadUsers(this.context);
    this.selectedFilters = { ...this.selectedFilters };
  }

  onLeadEditorDrawerClosed = () => {
    this._drawerService.hide("addLeadEditorDrawer", 100);
    this._drawerService.hide("editLeadEditorDrawer", 100);
  }

  onAddNewLeadClicked = () => {
    this._drawerService.hide("editLeadEditorDrawer", 100);

    this.leadToEdit = null;
    this.addLeadEditorDrawer.title = "New Lead";
    this.newLeadDrawerOptions.size = DrawerSize.XXLarge;
    this._drawerService.show("addLeadEditorDrawer", 100);
  }

  onNewLeadAdded = (lead: Lead, openEdit?: boolean) => {
    this.onEditRequestedForLead(lead);
  }

  onLeadUpdated = (lead: Lead) => {
    this.updateLeadTable(lead.leadId);
    // this._drawerService.hide("editLeadEditorDrawer", 100);
  }

  onEditRequestedForLead = (lead: Lead) => {
    this._drawerService.hideAll();
    // this._drawerService.hide("addLeadEditorDrawer", 100);
    // this._drawerService.hide("editLeadEditorDrawer", 100);

    this.leadToEdit = lead;
    this.editLeadEditorDrawer.title = "Editing Lead - " + Utils.getPersonsDisplayName(lead);

    setTimeout(() => {
      this._drawerService.show("editLeadEditorDrawer", 100);
    }, 100);
  }

  openFilterDialog = (): void => {
    let modalRef = this._modalService.open(LeadFilterDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.filters = this.selectedFilters;

    modalRef.result.then((result: { filters: LeadFilters, filterString: string }) => {
      this.selectedFilters = { ...result.filters };
      // this.selectedFiltersText = result.filterString; // deprecated
    }, () => {
    });
  }

  inPowerUserModeChange = () => {
    this.webPreferences.leadsPreferences.powerUserModeToggledOn = this.inPowerUserMode;
    this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => {
    });
  }

  onRangesFilterChanged = (selectedOption: FilterOption) => {
    this.selectedFilters = {
      ...this.selectedFilters,
      dateRange: selectedOption ? selectedOption.dateRange : null
    };

    this.webPreferences.leadsPreferences.leadsFilterSetting = this.selectedFilters;
    this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
      this._localStorageService.setItem('leadsFilterSetting', this.selectedFilters);
    });
  }

  onUsersFilterChanged = (selectedOptions: FilterOption[]) => {
    this.setSelectedFilter(selectedOptions, "showUserId");
  }

  onCampaignsFilterChanged = (selectedOptions: FilterOption[]) => {
    this.setSelectedFilter(selectedOptions, "campaignId");
  }

  onCampaignsFilterChangedDebounce = _.debounce(this.onCampaignsFilterChanged, 1500);

  onBranchesFilterChanged = (selectedOptions: FilterOption[]) => {
    this.setSelectedFilter(selectedOptions, "branchId");
  }

  onLeadStatusesFilterChanged = (selectedOptions: FilterOption[]) => {
    this.setSelectedFilter(selectedOptions, "leadStatusIds");
  }

  onLeadStatusesFilterChangedDebounce = _.debounce(this.onLeadStatusesFilterChanged, 1500);

  onStatesFilterChanged = (selectedOptions: FilterOption[]) => {
    this.setSelectedFilter(selectedOptions, "state");
  }

  onStatesFilterChangedDebounce = _.debounce(this.onStatesFilterChanged, 1500);

  onTagsFilterChanged = (selectedOptions: FilterOption[]) => {
    this.setSelectedFilter(selectedOptions, "leadListId");
  }

  onRefreshClicked = () => {
    if (!this.intervalConfig.intervalTime || this.intervalConfig.intervalTime == 0) return;

    this.refreshLeads();
    this.initializeAutoRefreshTimer();
  }

  openIntervalSettingsDialog = () => {
    const modalRef = this._modalService.open(LeadIntervalConfigDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.config = _.clone(this.intervalConfig || {});

    modalRef.result.then((config) => {
      this.intervalConfig = config;
      this.initializeAutoRefreshTimer();
    }).catch(() => {

    })
  }

  clearAll = () => {
    this.selectedFilters = new LeadFilters();
    this.showUnassignedLeads = false;
    this.showInactiveUsers = false;
    this.inPowerUserMode = false;

    if (this.leadTable) {
      this.leadTable.onSearchReset();
    }

    this.webPreferences.leadsPreferences.leadsFilterSetting = this.selectedFilters;
    this.webPreferences.leadsPreferences.powerUserModeToggledOn = this.inPowerUserMode;
    this.filters.toArray().forEach(f => {
      f.clear();
    });

    this.webPreferences.leadsPreferences.leadsFilterSetting = this.selectedFilters;
    this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
      this._localStorageService.setItem('leadsFilterSetting', this.selectedFilters);
    });

  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  private updateLeadTable = (leadId: number) => {
    this.leadTable.updateAfterAddOrEdit(leadId);
  }

  private getAllLeadLists = () => {
    this._leadService.getLeadLists().subscribe(lists => {
      this.tagsForFiltering = lists
        .filter(l => l.name != null && l.name.length > 0)
        .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
        .map(l => ({
          displayName: l.name,
          value: l.leadListId,
          groupName: "All",
          isSelected: false,
        }))
      if (this.selectedFilters.leadListId) {
        const tagForFiltering = this.tagsForFiltering.find(u => u.value == this.selectedFilters.leadListId);
        if (tagForFiltering) {
          tagForFiltering.isSelected = true;
        }
      }
    });
  }

  private getAllLeadUsers = (context: ApplicationContext) => {
    const users = context.globalConfig.accessibleUsersForLeads || [];
    const loggedInAdminUserId = context.currentlyLoggedInUser.userCompanyGuid;
    const adminUser = context.globalConfig.usersAll.filter(user => user.userCompanyGuid == loggedInAdminUserId)[0];

    if (adminUser && !users.some(el => el.userCompanyGuid == adminUser.userCompanyGuid)) {
      users.push({ userCompanyGuid: adminUser.userCompanyGuid, firstName: adminUser.firstName, lastName: adminUser.lastName, userType: adminUser.userType });
    }

    this.usersForFiltering = users
      .filter(u => this.showInactiveUsers || (!this.showInactiveUsers && u.active))
      .sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
      .sort((a, b) => (a.firstName || "").localeCompare(b.firstName || ""))
      .map(u => ({
        displayName: `${u.lastName}, ${u.firstName}` + (u.active ? "" : " (Inactive)"),
        value: u.userCompanyGuid,
        groupName: "All",
        isSelected: this.selectedFilters?.showUserId == u.userCompanyGuid,
      }))

    if (this.usersForFiltering.findIndex(u => u.isSelected) === -1) {
      this.selectedFilters.showUserId = null;
      this.selectedFilters = { ...this.selectedFilters };
    }
  }

  private setSelectedFilter = (selectedOptions: FilterOption[], key: keyof LeadFilters) => {

    const selections = selectedOptions.filter(opt => opt.isSelected);
    const filterItemValue = selections ? selections.map(s => s.value) : null;

    if (filterItemValue != this.selectedFilters[key]) {
      this.selectedFilters = {
        ...this.selectedFilters,
        [key]: filterItemValue
      };
    }

    this.webPreferences.leadsPreferences.leadsFilterSetting = this.selectedFilters;
    this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
      this._localStorageService.setItem('leadsFilterSetting', this.selectedFilters);
    });
  }

  private initializeAutoRefreshTimer = () => {
    if (!this.intervalConfig.intervalTime || this.intervalConfig.intervalTime == 0) {
      if (this._refreshInterval) {
        this.clearAutoRefreshInterval();
      }
      return;
    };

    if (this._refreshInterval) {
      this.clearAutoRefreshInterval();
    }

    let interval = (this.intervalConfig.intervalTime * 60) + 1;
    this._autoRefreshCounter = interval;

    this._refreshInterval = setInterval(() => {
      this._autoRefreshCounter--;
      this.masterRefreshCounterDisplay =
        Utils.pad(parseInt((this._autoRefreshCounter / 60).toString())).toString() + ':' +
        Utils.pad(this._autoRefreshCounter % 60);

      if (this._autoRefreshCounter <= 0) {
        this.refreshLeads();
        this.initializeAutoRefreshTimer();
      }
    }, 1000);
  }

  private refreshLeads = () => {
    this.leadTable.getTodayLeads();
  }

  private clearAutoRefreshInterval = () => {
    clearInterval(this._refreshInterval);
    this.masterRefreshCounterDisplay = null;
  }

  private fetchFilter = () => {
    const localLeadsFilterSetting = this._localStorageService.getItem("leadsFilterSetting") as LeadFilters;
    if (localLeadsFilterSetting) {
      this.webPreferences.leadsPreferences.leadsFilterSetting = localLeadsFilterSetting;
      this._localStorageService.removeItem('leadsFilterSetting')
    }

    const leadsFilterSetting = this.webPreferences.leadsPreferences?.leadsFilterSetting;
    if (leadsFilterSetting) {
      if (leadsFilterSetting.dateRange) {
        this.selectedFilters.dateRange = leadsFilterSetting.dateRange as DateRangeBase;
        if (this.selectedFilters.dateRange.id === 28) {
          this.selectedFilters.dateRange.startDate = this.selectedFilters.dateRange.startDate
            ? new Date(this.selectedFilters.dateRange.startDate)
            : null;
          this.selectedFilters.dateRange.endDate = this.selectedFilters.dateRange.endDate
            ? new Date(this.selectedFilters.dateRange.endDate)
            : null;
        } else {
          this.selectedFilters.dateRange = Utils.getDateRangeById(this.selectedFilters.dateRange, this.selectedFilters.dateRange.id);
        }
      }

      this.selectedFilters.campaignId = leadsFilterSetting.campaignId;
      if (this.selectedFilters.campaignId) {
        this.selectedFilters.campaignId = this.selectedFilters.campaignId.filter(x=> this.campaignsForFiltering.map(x=>x.value).indexOf(x) > -1);
        this.campaignsForFiltering.forEach(u => {
          let matched = this.selectedFilters.campaignId.find(s => s == u.value);
          if (matched) {
            u.isSelected = true;
          }
        });
        this.campaignsForFiltering = [...this.campaignsForFiltering];
      }

      this.selectedFilters.branchId = leadsFilterSetting.branchId;
      if (this.selectedFilters.branchId) {
        let matched = this.branchesForFiltering.find(b => b.value == this.selectedFilters.branchId);
        if (matched) {
          matched.isSelected = true;
        }
        this.branchesForFiltering = [...this.branchesForFiltering];
      }

      this.selectedFilters.showUserId = leadsFilterSetting.showUserId;
      if (this.selectedFilters.showUserId) {
        let matched = this.usersForFiltering.find(u => u.value == this.selectedFilters.showUserId);
        if (matched) {
          matched.isSelected = true;
        }
        this.usersForFiltering = [...this.usersForFiltering];
      }

      this.selectedFilters.state = leadsFilterSetting.state;
      if (this.selectedFilters.state) {
        this.statesForFiltering.forEach(u => {
          let matched = this.selectedFilters.state.find(s => s == u.value);
          if (matched) {
            u.isSelected = true;
          }
        })
        this.statesForFiltering = [...this.statesForFiltering];
      }

      this.selectedFilters.leadListId = leadsFilterSetting.leadListId;
      if (this.selectedFilters.leadListId) {
        let matched = this.tagsForFiltering.find(u => u.value == this.selectedFilters.leadListId);
        if (matched) {
          matched.isSelected = true;
        }
        this.tagsForFiltering = [...this.tagsForFiltering];
      }

      this.selectedFilters.leadStatusIds = leadsFilterSetting.leadStatusIds;
      if (this.selectedFilters.leadStatusIds) {
        let matched = this.leadStatusesForFiltering.find(b => b.value == this.selectedFilters.leadStatusIds);
        if (matched) {
          matched.isSelected = true;
        }
        this.leadStatusesForFiltering = [...this.leadStatusesForFiltering];
      }

      this.selectedFilters.showArchived = leadsFilterSetting.showArchived;
    }
  }

  private setExportOnlyColumnMappingsForStandardMode = () => {
    this.exportOnlyColumnsMapping.set("lastName", [
      { header: "First Name", field: "firstName" },
      { header: "Last Name", field: "lastName" }
    ]);
    this.exportOnlyColumnsMapping.set("mobilePhone", [
      { header: "Email", field: "email" },
      { header: "Mobile Phone", field: "mobilePhone" },
      { header: "Home Phone", field: "phone" }
    ]);
    this.exportOnlyColumnsMapping.set("address", [
      { header: "Address", field: "presentAddress1" },
      { header: "City", field: "presentCity" },
      { header: "State", field: "presentState" },
      { header: "Zip Code", field: "presentZip" },
    ]);
    this.exportOnlyColumnsMapping.set("mailingAddress", [
      { header: "Mailing Address", field: "mailingAddress1" },
      { header: "Mailing City", field: "mailingCity" },
      { header: "Mailing State", field: "mailingState" },
      { header: "Mailing Zip Code", field: "mailingZip" },
    ]);
    this.exportOnlyColumnsMapping.set("leadStatusId", [
      { header: "Status", field: "leadStatusId" },
    ]);
    this.exportOnlyColumnsMapping.set("loanAmount", [
      { header: "Loan Amount", field: "loanAmount" }
    ]);
    this.exportOnlyColumnsMapping.set("leadContactUserId", [
      { header: "Assigned To", field: "leadContactUserId" }
    ]);
    this.exportOnlyColumnsMapping.set("referralSource", [
      { header: "Referral Source", field: "referralSource" }
    ]);
    this.exportOnlyColumnsMapping.set("leadCampaignId", [
      { header: "Campaign", field: "leadCampaignId" }
    ]);
    this.exportOnlyColumnsMapping.set("leadListIds", [
      { header: "Tags", field: "leadListIds" }
    ]);
    this.exportOnlyColumnsMapping.set("contactAttempts", [
      { header: "Attempts", field: "contactAttempts" }
    ]);
    this.exportOnlyColumnsMapping.set("lastSuccessfulContact", [
      { header: "Last Success", field: "lastSuccessfulContact" }
    ]);
    this.exportOnlyColumnsMapping.set("dateInserted", [
      { header: "Created On", field: "dateInserted" }
    ]);
  }
}
