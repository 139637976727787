import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { PrepaymentPenaltyDetailsComponent } from './prepayment-penalty-details.component';



@NgModule({
  declarations: [
    PrepaymentPenaltyDetailsComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    PrepaymentPenaltyDetailsComponent
  ]
})
export class PrepaymentPenaltyDetailsModule { }
