<div [ngClass]="{'group-container-repeat': !mouseCursorOnDeleteButton,
  'group-container-repeat-pending-delete': mouseCursorOnDeleteButton}">
  <div class="grid-layout">
    <div class="grid-layout-row grid-row">
      <div class="grid-header">
        <div class="grid-header-left">
          <a class="link" (click)="copyFromPresentAddress()" *ngIf="otherResidencyAddresses.length == 2 && !isReadOnly">Copy From Present</a>
          <ul class="list-unstyled mb-0 mx-2" *ngIf="otherResidencyAddresses.length > 2">
            <li class="dropdown">
              <a class="link dropdown-toggle" *ngIf="!isReadOnly" data-bs-toggle="dropdown" aria-haspopup="false" (click)="onCopyFromClicked()" aria-expanded="false" href="#" role="button">
                Copy from
              </a>
              <div class="dropdown-menu dropdown-menu-end" style="z-index: 9999">
                <a class="dropdown-item" (click)="copyFrom(address)" *ngFor="let address of addressesToPickFrom">{{getFullAddress(address)}}</a>
                <p class="dropdown-item mb-0" *ngIf="addressesToPickFrom?.length === 0">No residencies to copy from.</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="grid-header-right"></div>
      </div>
      <a class="top-right-close link" (mouseover)="mouseCursorOnDeleteButton=true" *ngIf="!isReadOnly"
          (mouseout)="mouseCursorOnDeleteButton=false" (click)="onDeleteClicked()">
        <i class="fa fa-times-circle"></i>
      </a>
    </div>
    <urla-address
      [isReadOnly]="isReadOnly"
      [addressLabel]="addressLabel"
      [zipcodeLookupEnabled]="true"
      [address]="residencyAddress.address"
      [showAllCountries]="true"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.residencyAddress'">
    </urla-address>
    <div *ngIf="askForDurationAtAddress" class="grid-layout-row">
      <urla-dropdown
        class="col-sm-3 col-xs-6"
        [readonly]="isReadOnly"
        [name]="'housing_' + residencyAddress.residencyAddressId"
        [label]="'Housing'"
        [options]="housingTypes"
        [(ngModel)]="residencyAddress.residencyBasis"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.residencyAddress.residencyBasis'"
        [required]="isRequired('mortgage.borrower.residencyAddress.residencyBasis')"
      ></urla-dropdown>

      <urla-months-years-duration
        class="col-sm-4 col-xs-6"
        [name]="'duration_' + residencyAddress.residencyAddressId"
        [(ngModel)]="durationAtAddress"
        [readonly]="isReadOnly"
        [label]="'How long at this address?'"
        (ngModelChange)="onDurationChanged()"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.residencyAddress.durationAtAddress'"
        [required]="isRequired('mortgage.borrower.residencyAddress.durationAtAddress')"
      ></urla-months-years-duration>

      <urla-currency-input
        class="col-sm-2 col-xs-6"
        [name]="'rent_' + residencyAddress.residencyAddressId"
        *ngIf="residencyAddress.residencyBasis == 'Rent'"
        [(ngModel)]="residencyAddress.rent"
        [label]="'Rent'"
        [readonly]="isReadOnly"
        [suffix]="'/month'"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.residencyAddress.rent'"
        [required]="isRequired('mortgage.borrower.residencyAddress.rent')"
      ></urla-currency-input>

    </div>
  </div>
</div>
