import { NgModule } from '@angular/core';
import { DocuViewareService } from 'src/app/services/docuvieware.service';
import { LoanDocsV2Component } from './components/loan-docs-v2.component';
import { SharedModule } from "src/app/shared/shared.module";
import { LoanDocsTableViewModule } from './components/loan-docs-table-view/loan-docs-table-view.module';
import { LoanDocsV2RoutingModule } from './loan-docs-v2-routing.module';
import { LoanDocsCardViewModule } from './components/loan-docs-card-view/loan-docs-card-view.module';
import { LoanDocsV2DialogComponent } from './components/loan-docs-dialog/loan-docs-v2-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    LoanDocsTableViewModule,
    LoanDocsCardViewModule,
    LoanDocsV2RoutingModule,
    // DocFilesEditDialogModule
  ],
  declarations: [
    LoanDocsV2Component,
    LoanDocsV2DialogComponent,
  ],
  exports: [
    LoanDocsV2Component,
  ],
  providers: [DocuViewareService]
})
export class LoanDocsV2Module {
  constructor() { }
}
