import { AfterViewInit, Component, Injector, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MenuService } from 'src/app/services/menu.service';
import { MenuItemStatus } from '../../tpo/models/enums/menu-item-status.enum';
import { Subscription } from 'rxjs';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';
import { MortgageService } from 'src/app/services/mortgage.service';
@Component({
  template: ''
})

export abstract class UrlaSectionComponent implements AfterViewInit, OnDestroy {

  abstract urlaSectionForm: NgForm;

  abstract menuItem: string;

  abstract validate();

  protected menuService: MenuService;

  protected urlaValidationService: UrlaValidationService;

  private _statusChangeSubscriptions: Subscription[] = [];

  constructor(protected readonly injector: Injector) {
    this.menuService = this.injector.get(MenuService);
  }

  ngAfterViewInit(): void {
    if (this.urlaSectionForm) {
      const subscription = this.urlaSectionForm.valueChanges.subscribe(
        changeEvent => {
          if (changeEvent) {
            const fieldNames = Object.keys(changeEvent).filter(key => key !== 'triggerValidation');
            for (let i = 0; i < fieldNames.length; i++) {
              const fieldControl = this.urlaSectionForm.controls[fieldNames[i]];
              if (fieldControl.touched || this.menuService.isTouched) {
                this.menuService.isTouched = true;
                setTimeout(() => {
                  this.validate();
                });
                break;
              }
            }
          }
        }
      );
      this._statusChangeSubscriptions.push(subscription);
    }
  }

  ngOnDestroy(): void {
    this._statusChangeSubscriptions.forEach(s => s?.unsubscribe());
    this.menuService.isTouched = false;
  }

  // protected onFormValidationStatusChanged(status: string) {
  //   const menuItemStatus = this.decideOnMenuItemStatusBasedOnFormValidity(this.menuItem + "Section", status);
  //   this.setMenuItemStatus(menuItemStatus);
  // }

  protected setMenuItemStatus(status: MenuItemStatus) {
    this.menuService.setStatus(this.menuItem, status);
  }

  // protected decideOnMenuItemStatusBasedOnFormValidity(idOfElementToSearchForInvalidControls: string, status: string): MenuItemStatus {
  //   switch (status) {
  //     case "VALID":
  //       return MenuItemStatus.Success;
  //     case "INVALID":
  //       let menuStatus = MenuItemStatus.Pending;
  //       let element = document.getElementById(idOfElementToSearchForInvalidControls);
  //       if (element) {
  //         let requiredList = element.querySelectorAll('.ng-invalid-required');
  //         if (requiredList && requiredList.length > 0) {
  //           return MenuItemStatus.Error;
  //         }
  //         let patternErrorList = element.querySelectorAll('.ng-invalid-pattern');
  //         if (patternErrorList && patternErrorList.length > 0) {
  //           return MenuItemStatus.Error;
  //         }
  //         let maskErrorList = element.querySelectorAll('.ng-invalid-mask');
  //         if (maskErrorList && maskErrorList.length > 0) {
  //           return MenuItemStatus.Error;
  //         }
  //         let dateErrorList = element.querySelectorAll('.ng-invalid-date');
  //         if (dateErrorList && dateErrorList.length > 0) {
  //           return MenuItemStatus.Error;
  //         }
  //       }
  //       return menuStatus;
  //   }
  // }
}
