<div class="mb-3 row" *ngIf="firstRole">
  <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">{{
    firstRole.roleName }}</label>
  <div  [ngClass]="{'col-sm-6' : alignments.length > 1 && secondaryRoles.length, 'col-sm-9': !alignments.length || !secondaryRoles.length}" class="position-relative">
    <select-input-vscroll 
        name="primaryInternalContact" 
        id="primary-internal-contact"
        [required]="true"
        [(ngModel)]="primaryRoleUserId"
        (ngModelChange)="onPrimaryRoleUserChanged($event)"
        [options]="primaryRoleUsers"
        [optionValueKey]="'userCompanyGuid'"
        [optionNameKey]="'userFullName'"
        [placeholder]="'Select One'" >
      </select-input-vscroll>
  </div>
  <div class="col-sm-3" *ngIf="alignments.length > 1 && secondaryRoles.length">
    <a class="action ms-1" (click)="onShowAvailableAlignmentsClicked()">
      Alignments
    </a>
  </div>
</div>
<ng-container *ngIf="isTpoUser">
  <div class="mb-3 row" *ngFor="let role of secondaryRoles">
    <label class="col-sm-3 form-label align-self-center mb-lg-0 text-end">{{
      role.roleName }}</label>
    <div class="col-sm-9 position-relative">
      <select-input-vscroll 
        [name]="'internalContact' + role.roleId" 
        id="new-app-info-internal-contact-{{role.roleId}}"
        [required]="true"
        [(ngModel)]="tpoRoleUsersMap[role.roleId]"
        (ngModelChange)="onSecondaryRoleUserChanged($event)"
        [options]="secondaryRoleUsers"
        [optionValueKey]="'userCompanyGuid'"
        [optionNameKey]="'userFullName'"
        [optionGroupNameKey]="'roleName'"
        [placeholder]="'Select One'" >
      </select-input-vscroll>
    </div>
  </div>
</ng-container>

