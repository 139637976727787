import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ParcelIdentification, ParcelIdentificationTypeEnum } from 'src/app/models';
import { UrlaRequiredState } from '../../../services/utility.service';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import { ParcelIdentificationDetailsComponent } from './parcel-identification-details/parcel-identification-details.component';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { MortgageFieldConfig } from '../../../models/urla-fields-config.model';

@Component({
  selector: 'parcel-identifications',
  templateUrl: './parcel-identifications.component.html',
  styleUrls: ['./parcel-identifications.component.scss']
})
export class ParcelIdentificationsComponent implements OnInit {

  @Input()
  parcelIdentifications: ParcelIdentification[];

  @Input() isReadOnly: boolean = false;

  @Input() inEditMode: boolean = false;

  @Input() urlaFieldsConfig: Record<string, MortgageFieldConfig>;

  @Output() descriptionsChanged: EventEmitter<ParcelIdentification[]> = new EventEmitter<ParcelIdentification[]>()

  @ViewChild('parcelIdentificationDrawer')
  detailsDrawer: DrawerComponent;

  showingParcelId: string;

  detailsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  };

  private _eventSubscriptions: Subscription[] = [];

  constructor(private readonly _drawerService: DrawerService) { }

  ngOnInit(): void {
    if (this.parcelIdentifications && this.parcelIdentifications.length > 0) {
      let parcelIdNumber = this.parcelIdentifications.find(id => id.identificationType == ParcelIdentificationTypeEnum.ParcelIdentificationNumber);
      if (parcelIdNumber) {
        this.showingParcelId = parcelIdNumber.parcelIdentifier;
      }
      else {
        let data = this.parcelIdentifications[0];
        this.showingParcelId = data.identificationType != ParcelIdentificationTypeEnum.Other ? data.parcelIdentifier : data.identificationTypeOtherDescription;
      }
    }
  }

  ngOnDestroy(): void {
    this._eventSubscriptions.forEach(subscription => {
      subscription?.unsubscribe();
    })
  }

  onParcelIdentifierClicked = () => {

    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = ParcelIdentificationDetailsComponent;
    dynamicComponentInfo.parameters.set('parcelIdentifications', this.parcelIdentifications);
    dynamicComponentInfo.parameters.set('isReadOnly', this.isReadOnly);
    dynamicComponentInfo.parameters.set('inEditMode', this.inEditMode);
    dynamicComponentInfo.parameters.set('urlaFieldsConfig', this.urlaFieldsConfig);

    this._drawerService.show("parcelIdentifierDrawer", 100, "Parcel Identifications", dynamicComponentInfo).then(() => {
      const cancelledEventSubscription = this.detailsDrawer.componentInstance.cancelled.subscribe(() => {
        this._drawerService.hide("parcelIdentifierDrawer");
      });
      this._eventSubscriptions.push(cancelledEventSubscription);
      const changesApprovedEventSubscription = this.detailsDrawer.componentInstance.changesApproved.subscribe((currentParcelIdentifications: ParcelIdentification[]) => {
        this._drawerService.hide("parcelIdentifierDrawer");
        this.parcelIdentifications = currentParcelIdentifications;
        this.ngOnInit();
        this.descriptionsChanged.emit(currentParcelIdentifications);
      });
      this._eventSubscriptions.push(changesApprovedEventSubscription);
    });
  }
}
