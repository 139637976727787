import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Address, Liability, NetRentalIncomeParams, RealEstateOwned, ResidencyBasis, ResidencyType } from 'src/app/models';
import { CityCountyState } from 'src/app/models/city-county-state.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { ZipCodeService } from 'src/app/services/zipcode.service';
import { BorrowerPickerComponent } from '../../common/components/borrower-picker/borrower-picker.component';
import { UrlaStreetAddressComponent } from '../../common/components/urla-street-address/urla-street-address.component';
import { CityCountyPickerDialogComponent } from '../../components/city-county-picker-dialog/city-county-picker-dialog.component';
import { UrlaBorrower, UrlaMortgage } from '../../models/urla-mortgage.model';
import { MortgageCalculationService } from '../../services/mortgage-calculation.service';
import { SubjectPropertyInputComponent } from '../subject-property-input/subject-property-input.component';
import { ReoGrossRentalCalculatorDialogComponent } from '../reo-gross-rental-calculator-dialog/reo-gross-rental-calculator-dialog.component';
import { ReoExpenseBreakdownComponent } from '../reo-expense-breakdown-dialog/reo-expense-breakdown-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'reo-item',
  templateUrl: 'reo-item.component.html',
  viewProviders: [formViewProvider]
})
export class ReoItemComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output()
  attemptedToDeleteREO: EventEmitter<RealEstateOwned> = new EventEmitter<RealEstateOwned>();

  @ViewChild("subjectProperty")
  subjectProperty: SubjectPropertyInputComponent;

  @ViewChild("borrowerPicker")
  borrowerPicker: BorrowerPickerComponent;

  @ViewChild('streetAddress')
  streetAddressComponent: UrlaStreetAddressComponent;

  @Input()
  reo: RealEstateOwned;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  states: EnumerationItem[];

  @Input()
  yesNoOptions: EnumerationItem[];

  @Input()
  currentPropertyWillBeType: EnumerationItem[];

  @Input()
  set propertyStatusOptions(propertyStatusOptions: EnumerationItem[]) {
    this._propertyStatusOptions = [...propertyStatusOptions];
    this._originalPropertyStatusOptions = [...propertyStatusOptions];
  }

  get propertyStatusOptions(): EnumerationItem[] {
    return this._propertyStatusOptions;
  }

  @Input()
  intendedPropertyWillBeType: EnumerationItem[];

  @Input()
  reoPropertyTypes: EnumerationItem[];

  @Input()
  liabilityTypeHELOC: string;

  @Input()
  liabilityTypeMortgageLoan: string;

  @Input()
  borrowers: UrlaBorrower[] = [];

  @Input()
  allLiabilities: Liability[] = [];

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  protected isInvestmentOrTwoToFourUnitProperty: boolean = false;

  @Output()
  subjectPropertyStatusChanged: EventEmitter<RealEstateOwned> = new EventEmitter<RealEstateOwned>();

  @Output()
  selectedToImportFromSubjectProperty: EventEmitter<RealEstateOwned> = new EventEmitter<RealEstateOwned>();

  @Output()
  selectedToPushToSubjectProperty: EventEmitter<RealEstateOwned> = new EventEmitter<RealEstateOwned>();

  mouseCursorOnDeleteButton: boolean = false;

  reoLiabilities: Liability[] = [];

  error: boolean = false;

  private _mortgage: UrlaMortgage;

  private _propertyStatusOptions: EnumerationItem[];

  private _originalPropertyStatusOptions: EnumerationItem[];

  private _reoEventSubscription: Subscription;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    this.isInvestmentOrTwoToFourUnitProperty = this.checkIfInvestmentOrTwoToFourUnitProperty(this.reo);
    if (!this.isInvestmentOrTwoToFourUnitProperty) {
      if (this.reo.netRentalIncome == null) {
        this.reo.netRentalIncome = 0;
      }
    }

    this.calculateNetRentalIncome();
    this.checkAddress();
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  constructor(private readonly _calculationService: MortgageCalculationService,
    private readonly _zipCodeService: ZipCodeService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _mortgageService: MortgageService,
    private readonly _modalService: NgbModal) {
    this._reoEventSubscription = this._calculationService.reoLiabilityAssociationChanged.subscribe(() => {
      this.calculateMortgagePayment(this.reo);
    });
  }

  ngOnInit() {
    this.isInvestmentOrTwoToFourUnitProperty = this.checkIfInvestmentOrTwoToFourUnitProperty(this.reo);
    this.calculateMortgagePayment(this.reo);
    this.removeRetainedOptionFromDispositionStatusesIfInvestment();
  }

  ngAfterViewInit(): void {
    if (this.borrowerPicker && !this.inEditMode) {
      if (this.reo.owningBorrowerIds.length == 0 && this.borrowers.length == 1)
        this.reo.owningBorrowerIds.push(this.borrowers[0].borrowerId)
      this.borrowerPicker.itemSelected.subscribe(e => this.onBorrowerSelectionChanged());
      this.borrowerPicker.itemDeselected.subscribe(e => this.onBorrowerSelectionChanged());
      let selectedBorrowers: UrlaBorrower[] = [];
      this.reo.owningBorrowerIds.forEach(id => {
        const selectedBorrower = this.borrowers.find(b => b.borrowerId == id);
        if (selectedBorrower) {
          selectedBorrowers.push(selectedBorrower);
        }
      });

      this.borrowerPicker.selectedItems = selectedBorrowers;
    }
    if (this.streetAddressComponent) {
      this.streetAddressComponent.addressChanged.subscribe(address => {
        this.setReoAddress(address);
      });
    }
  }

  ngOnDestroy(): void {
    if (this._reoEventSubscription) {
      this._reoEventSubscription.unsubscribe();
    }
  }

  onReoMiscExpensesChanged = () => {
    this.zeroOutReoExpenseBreakdownItems();
    this.calculateNetRentalIncome();
  }

  onReoExpenseBreakdownCalculatorClicked = () => {
    const modalRef = this._modalService.open(ReoExpenseBreakdownComponent, Constants.modalOptions.large);
    modalRef.componentInstance.reo = this.reo;
    modalRef.result.then((reo: RealEstateOwned) => {
      if (reo) {
        this.reo.otherFinance = reo.otherFinance;
        this.reo.hazardInsurance = reo.hazardInsurance;
        this.reo.propertyTax = reo.propertyTax;
        this.reo.mortgageInsurance = reo.mortgageInsurance;
        this.reo.hoa = reo.hoa;
        this.reo.otherMonthlyExpense = reo.otherMonthlyExpense;
        this.reo.monthlyMiscExpenses = reo.monthlyMiscExpenses;
        this.calculateNetRentalIncome();
      }
    }, error => () => { })
  }

  protected onAddressChanged(address: Address): void {
    this.setReoAddress(address);
  }

  private setReoAddress(address: Address) {
    const reo = this.reo;

    reo.address1 = address.address1;
    reo.city = address.city;
    reo.zipCode = address.zipCode;
    reo.state = address.state;

    this.checkAddress();
  }

  checkAddress = () => {
    const existingBorrowerAddress = this.mortgage.borrowers.find(borr => borr.residencyAddresses.find(a =>
      a.residencyType === ResidencyType.PresentAddress &&
      a.residencyBasis !== ResidencyBasis.Own &&
      this.reo.address1?.toLocaleLowerCase() === a.address?.address1?.toLocaleLowerCase() &&
      this.reo.city?.toLocaleLowerCase() === a.address?.city?.toLocaleLowerCase() &&
      this.reo.state?.toLocaleLowerCase() === a.address?.state?.toLocaleLowerCase() &&
      this.reo.zipCode?.toLocaleLowerCase() === a.address?.zipCode?.toLocaleLowerCase()));

    if (existingBorrowerAddress) {
      this.error = true;
    } else {
      this.error = false;
    }
  }

  onZipcodeBlurred = () => {
    this._zipCodeService.lookupZipCode(this.reo.zipCode, false).subscribe(result => {
      if (result.length > 1) {
        const modalRef = this._modalService.open(CityCountyPickerDialogComponent, Constants.modalOptions.medium);
        modalRef.componentInstance.optionsToPickFrom = result;
        modalRef.result.then((selectedCityAndCounty: CityCountyState) => {
          if (selectedCityAndCounty) {
            this.reo.city = selectedCityAndCounty.city;
            this.reo.state = selectedCityAndCounty.state.toLocaleLowerCase();
          }
        })
      } else if (result.length === 1) {
        this.reo.city = result[0].city;
        this.reo.state = result[0].state.toLocaleLowerCase();
      }
    });
    this.checkAddress();
  }

  onImportLoanInfoClicked = () => {
    this.selectedToImportFromSubjectProperty.emit(this.reo);
  }

  onPushToLoanInfoClicked = () => {
    this.selectedToPushToSubjectProperty.emit(this.reo)
  }

  calculateNetRentalIncome = () => {
    if (this.isInvestmentOrTwoToFourUnitProperty) {
      
      const params = new NetRentalIncomeParams();
      params.grossRentalIncome = this.reo.grossRentalIncome || 0;
      
      params.occupancyFactor = this.reo.vacancyFactor || 100;
      if (!isNaN(params.occupancyFactor)) {
        params.occupancyFactor = Number(params.occupancyFactor);
      }
      
      params.mortgagePayment = this.reo.mortgagePayment || 0;
      params.miscExpenses = this.reo.monthlyMiscExpenses || 0;

      this._spinnerService.show();

      this._mortgageService.calculateNetRentalIncome(params).subscribe({
        next: (response) => {
          this.reo.netRentalIncome = response.value;

          if (this.reo.isSubjectProperty) {
            this.mortgage.subjectProperty.expectedNetMonthlyRentalIncome = this.reo.netRentalIncome;
            this.mortgage.subjectProperty.expectedGrossMonthlyRentalIncome = this.reo.grossRentalIncome;
          }
        },
        error: (err) => {
          this._notificationService.showError(err?.message || "unable to calculate net rental income", "Error");
        }
      }).add(() => this._spinnerService.hide());
    }
  }

  onGrossRentCalculatorClicked = () => {
    const modalRef = this._modalService.open(ReoGrossRentalCalculatorDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.reo = this.reo;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        this.reo.grossRentalIncome = result.gross;
        this.reo.netRentalIncome = result.net;
        if (this.reo.isSubjectProperty) {
          this.mortgage.subjectProperty.expectedNetMonthlyRentalIncome = this.reo.netRentalIncome;
          this.mortgage.subjectProperty.expectedGrossMonthlyRentalIncome = this.reo.grossRentalIncome;
        }
      }
    }, (err) => {
      console.log(err);
    });
  }

  onDeleteClicked = () => {
    this.attemptedToDeleteREO.emit(this.reo);
  }

  calculateMortgagePayment = (reo: RealEstateOwned) => {
    this.reoLiabilities = [];
    if (this.reo.liabilities) {
      this.reoLiabilities = reo.liabilities.filter(l => l.reoId == reo.reoId &&
        (l.typeOfLiability == this.liabilityTypeHELOC ||
          l.typeOfLiability == this.liabilityTypeMortgageLoan) &&
        l.owningBorrowerIds.some(borrowerId => reo.owningBorrowerIds.indexOf(Number(borrowerId)) >= 0)
      );
    }
    if (this.allLiabilities) {
      let existingLiabilitiesAttachedToReo = this.allLiabilities.filter(l => l.reoId == reo.reoId &&
        l.owningBorrowerIds.some(borrowerId => reo.owningBorrowerIds.indexOf(Number(borrowerId)) >= 0));
      this.reoLiabilities = this.reoLiabilities.concat(existingLiabilitiesAttachedToReo);
    }
    if (this.reoLiabilities) {
      reo.mortgagePayment = this.reoLiabilities.reduce((total, liability) => total + (Number(liability.monthlyPayment) || 0), 0);
      reo.amountOfMortgage = this.reoLiabilities.reduce((total, liability) => total + (Number(liability.unpaidBalance) || 0) - (Number(liability.partialPayoffAmount) || 0), 0);
      this.calculateNetRentalIncome();
    }
  }

  onBorrowerSelectionChanged = () => {
    const selectedBorrowerIds = this.borrowerPicker.selectedItems.map(b => b.borrowerId);
    this.reo.owningBorrowerIds = selectedBorrowerIds;
    this.calculateMortgagePayment(this.reo);
    this.calculateNetRentalIncome();
    this._calculationService.sendLiabilityEvent();
  }

  onSubjectPropertyStatusChanged = (reo: RealEstateOwned) => {
    this.subjectPropertyStatusChanged.emit(reo);
    if (reo.isSubjectProperty) {
      let subtotal = 0;
      subtotal += this.mortgage.proposedHousingExpense?.firstMortgagePrincipalAndInterest || 0;
      subtotal += this.mortgage.proposedHousingExpense?.homeownersInsurance || 0;
      subtotal += this.mortgage.proposedHousingExpense?.supplementalPropertyInsurance || 0;
      subtotal += this.mortgage.proposedHousingExpense?.realEstateTax || 0;
      subtotal += this.mortgage.proposedHousingExpense?.mortgageInsurance || 0;
      subtotal += this.mortgage.proposedHousingExpense?.homeownersAssociationDuesAndCondominiumFees || 0;
      subtotal += this.mortgage.proposedHousingExpense?.otherHousingExpense || 0;
      reo.monthlyMiscExpenses = subtotal;
    }
    this.calculateNetRentalIncome();
  }

  onProposedOccupancyChanged = () => {
    this.isInvestmentOrTwoToFourUnitProperty = this.checkIfInvestmentOrTwoToFourUnitProperty(this.reo);
    if (!this.isInvestmentOrTwoToFourUnitProperty) {
      this.reo.grossRentalIncome = null;
      this.reo.vacancyFactor = null;
      this.reo.percentOwned = null;
      this.reo.netRentalIncome = null;
    }
    this.removeRetainedOptionFromDispositionStatusesIfInvestment();
  }

  onDispositionStatusChanged = () => {
    if (this.reo.dispositionStatus == "RetainForPrimaryOrSecondaryResidence" &&
      !this.reo.intendedUsageType) {
      this.reo.intendedUsageType = this.reo.currentUsageType;
    }
    if (this.reo.dispositionStatus == "Sold") {
      this.reo.intendedUsageType = null;
    }
    this.isInvestmentOrTwoToFourUnitProperty = this.checkIfInvestmentOrTwoToFourUnitProperty(this.reo);
  }

  private checkIfInvestmentOrTwoToFourUnitProperty = (reo: RealEstateOwned) => {
    if (['RetainForPrimaryOrSecondaryResidence', 'Retained', 'RentalProperty'].indexOf(reo.dispositionStatus) < 0) return false;
    return reo.typeOfProperty == "TwoToFourUnitProperty" || reo.intendedUsageType == "Investment" || reo.intendedUsageType == "SecondaryResidence";
  }

  private removeRetainedOptionFromDispositionStatusesIfInvestment = () => {
    const index = this._propertyStatusOptions.findIndex(p => p.value === 'RetainForPrimaryOrSecondaryResidence');
    if (this.reo.intendedUsageType === 'Investment') {
      if (this.reo.dispositionStatus === 'RetainForPrimaryOrSecondaryResidence') {
        this.reo.dispositionStatus = null;
      }
      if (index >= 0) {
        this._propertyStatusOptions.splice(index, 1);
      }
    } else {
      if (index < 0) {
        this._propertyStatusOptions = [...this._originalPropertyStatusOptions];
      }
    }
  }

  private zeroOutReoExpenseBreakdownItems = () => {
    this.reo.otherFinance = 0;
    this.reo.hazardInsurance = 0;
    this.reo.propertyTax = 0;
    this.reo.mortgageInsurance = 0;
    this.reo.hoa = 0;
    this.reo.otherMonthlyExpense = 0;
  }
}
