import {deepFreeze} from '../shared/utils/object/utils';

/**
 * Represents a single menu item in the application.
 */
export class ApplicationMenuItem {
  readonly id: string;
  // The id of the parent menu item. If null, it is a top level menu item.
  readonly parentId: string | null;
  // It is displayed in the menu.
  readonly label: string;
  // If true, it is a container menu item and does not have a page to display.
  // It is only used to group other menu items.
  readonly containerOnly: boolean;

  constructor({
    id,
    parentId = null,
    label,
    containerOnly = false,
  }: {
    id: string,
    parentId?: string,
    label: string,
    containerOnly?: boolean,
  }) {
    this.id = id;
    this.parentId = parentId;
    this.label = label;
    this.containerOnly = containerOnly;
  }

  public equals(other: ApplicationMenuItem): boolean {
    return this.id === other.id &&
      this.parentId === other.parentId &&
      this.label === other.label &&
      this.containerOnly === other.containerOnly;
  }
}

export const ApplicationMenuItemDb = deepFreeze({
  LoanSummary: {
    id: '4272461170',
    QuickApply: { id: '8166089543' },
  },
  DealStructure: { id: '5814759163' },
  Urla: { id: '9464892526' },
  LoanDocs: { id: '8775280465' },
  LoanDocsV2: { id: '8928785431' },
  Pricing: { id: '6284924450' },
  Fees: {
    id: '4123754126',
    EscrowSchedule: { id: '8936478905' },
  },
  AtrQmManagement: { id: '2830473406' },
  Conditions: { id: '6211259922' },
  Services: {
    id: '7217181952',
    Credit: { id: '5537024497' },
    VoiVoe: { id: '3551874165' },
    Voa: { id: '2755496787' },
    Aus: { id: '9322059223' },
    Disclosures: { id: '7927251814' },
    Appraisal: { id: '1214260645' },
  },
  FileContacts: {
    id: '1426279915',
    Internal: { id: '9982136733' },
    External: { id: '9976974581' }
  },
  DocPreparation: { id: '9148145711' },
  DisclosureDocuments: {
    id: '6794302563',
    CocReasons: { id: '8943647802' },
    TaxTranscripts: { id: '5307328244' }
  }
});

/**
 * All menu items in the application. The array and all items are frozen to
 * prevent accidental modification.
 */
const allItems: readonly ApplicationMenuItem[] = deepFreeze([
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.LoanSummary.id,
    label: 'Loan Summary',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.LoanSummary.QuickApply.id,
    parentId: ApplicationMenuItemDb.LoanSummary.id,
    label: 'Quick Apply',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.DealStructure.id,
    label: 'Deal Structure',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Urla.id,
    label: 'URLA',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.LoanDocs.id,
    label: 'Loan Docs',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.LoanDocsV2.id,
    label: 'Loan Docs V2',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Pricing.id,
    label: 'Pricing',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Fees.id,
    label: 'Fees',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Fees.EscrowSchedule.id,
    parentId: ApplicationMenuItemDb.Fees.id,
    label: 'Escrow Schedule',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.AtrQmManagement.id,
    label: 'ATR / QM Management',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Conditions.id,
    label: 'Conditions',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Services.id,
    label: 'Services',
    containerOnly: true,
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Services.Credit.id,
    parentId: ApplicationMenuItemDb.Services.id,
    label: 'Credit',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Services.VoiVoe.id,
    parentId: ApplicationMenuItemDb.Services.id,
    label: 'VOI/VOE',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Services.Voa.id,
    parentId: ApplicationMenuItemDb.Services.id,
    label: 'VOA',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Services.Aus.id,
    parentId: ApplicationMenuItemDb.Services.id,
    label: 'AUS',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Services.Disclosures.id,
    parentId: ApplicationMenuItemDb.Services.id,
    label: 'Disclosures',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.Services.Appraisal.id,
    parentId: ApplicationMenuItemDb.Services.id,
    label: 'Appraisal',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.FileContacts.id,
    label: 'File Contacts',
    containerOnly: true,
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.FileContacts.Internal.id,
    parentId: ApplicationMenuItemDb.FileContacts.id,
    label: 'Internal Contacts',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.FileContacts.External.id,
    parentId: ApplicationMenuItemDb.FileContacts.id,
    label: 'External Contacts',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.DocPreparation.id,
    label: 'Doc Preparation',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.DisclosureDocuments.id,
    label: 'Disclosures / CoC',
    containerOnly: true,
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.DisclosureDocuments.TaxTranscripts.id,
    parentId: ApplicationMenuItemDb.DisclosureDocuments.id,
    label: 'Tax Transcripts',
  }),
  new ApplicationMenuItem({
    id: ApplicationMenuItemDb.DisclosureDocuments.CocReasons.id,
    parentId: ApplicationMenuItemDb.DisclosureDocuments.id,
    label: 'CoC / Reasons',
  }),
].map((item) => Object.freeze(item)));

/**
 * Creates a map of menu items by their id. Can be used to quickly find a menu
 * item by id.
 * @param items The menu items to create the map from.
 * @returns The map of menu items by their id.
 */
function createItemsByIdMap(
  items: readonly ApplicationMenuItem[],
): Map<string, ApplicationMenuItem> {
  return new Map(
    items.map((item) => [item.id, item]),
  );
}

/**
 * Creates a map of menu items by their parent id. Can be used to quickly find
 * children of a menu item.
 * @param items The menu items to create the map from.
 * @returns The map of menu items by their parent id.
 */
function createItemsByParentIdMap(
  items: readonly ApplicationMenuItem[],
): Map<string, ApplicationMenuItem[]> {
  return items.reduce((acc, item) => {
    // If the parentId is nullish, use null as the key to indicate that it is
    // a top level menu item.
    const parentId = item.parentId ?? null;
    const items = acc.get(parentId) ?? [];
    items.push(item);
    acc.set(parentId, items);
    return acc;
  }, new Map<string, ApplicationMenuItem[]>());
}

/**
 * Validate that all menu item ids are valid (exist in the allItems array).
 * @param ids The ids to validate.
 * @throws Error if any of the ids are invalid.
 */
function validateIdsAreKnown(ids: readonly string[]): void {
  const allIds = new Set(allItems.map((item) => item.id));
  const invalidIds = ids.filter((id) => !allIds.has(id))
    // Convert to string to in case the id is null.
    .map((id) => String(id));
  if (invalidIds.length > 0) {
    throw new Error(
      `Invalid menu item ids: ${invalidIds.join(', ')}`,
    );
  }
}

/**
 * Validate that all menu item ids are valid (exist in the allItems array) and
 * return the valid ids.
 * It logs an error for any invalid ids.
 * @param ids The ids to clean.
 * @returns The valid ids.
 */
function cleanUnknownIds(ids: readonly string[]): string[] {
  try {
    validateIdsAreKnown(ids);
    return [...ids];
  } catch (e) {
    // Get the invalid ids from the error message.
    const invalidIdsString = e.message.split(': ')[1].split(', ')
      // Add quotes around each id in case of empty string or spaces.
      .map((id: string) => typeof id !== 'string' ? id : `"${id}"`).join(', ');

    const message = 'The following application menu item ids are not' +
      ` recognized and will be ignored: ${invalidIdsString}`;
    console.error(message);

    const allIdsSet = new Set(allItems.map((item) => item.id));
    return ids.filter((id) => allIdsSet.has(id));
  }
}

/**
 * Validate that all menu item ids are unique.
 * @param ids The ids to validate.
 * @throws Error if any of the ids are not unique.
 */
function validateIdsAreUnique(ids: readonly string[]): void {
  // Group the ids by their value and check if any of the groups have more than
  // one item.
  const duplicates = Array.from(ids.reduce((acc, id) => {
    acc.set(id, (acc.get(id) ?? 0) + 1);
    return acc;
  }, new Map<string, number>()).entries()).filter(([, count]) => count > 1);

  if (duplicates.length > 0) {
    throw new Error(
      'Menu item ids must be unique. The following ids repeat: ' +
      duplicates.map(([id]) => id).join(', '),
    );
  }
}

/**
 * Checks if all menu items are valid (exist in the {@link allItems} array) and
 * they have the same properties as the menu items in the {@link allItems}
 * array.
 * @param items The menu items to validate.
 */
function validateMenuItemsAreValid(
  items: readonly ApplicationMenuItem[],
): void {
  const allItemsById = new Map<string, ApplicationMenuItem>(
    allItems.map((item) => [item.id, item]),
  );

  for (let item of items) {
    const baseItem = allItemsById.get(item.id);
    if (baseItem == null) {
      throw new Error(`Menu item id not recognized: ${item.id}`);
    }

    if (!baseItem.equals(item)) {
      throw new Error(
        `Menu item does not match the base menu item: ${item.id}`,
      );
    }
  }
}

/**
 * Validate that all menu items have a valid (exists in the allItems array)
 * parent id.
 * @param items The menu items to validate.
 * @throws Error if any of the menu items have an invalid parent id.
 */
function validateParentIdsAreValid(
  items: readonly ApplicationMenuItem[],
): void {
  const allIds = new Set(items.map((item) => item.id));
  // Get all the parent ids that are not null and not in the allIds set.
  // Make them a set to remove duplicates, then convert back to an array.
  const invalidParentIds = Array.from(new Set(
    items.filter(({ parentId }) => parentId != null && !allIds.has(parentId))
      .map((item) => item.parentId)
  ));
  if (invalidParentIds.length > 0) {
    throw new Error(
      `Menu items contain invalid parent ids: ${invalidParentIds.join(', ')}`,
    );
  }
}

/**
 * Holds information about the application menu items.
 */
export class ApplicationMenu {
  readonly items: readonly ApplicationMenuItem[];

  private readonly _itemsById: Map<string, ApplicationMenuItem>;

  private readonly _itemsByParentId: Map<string, ApplicationMenuItem[]>;

  get isEmpty(): boolean {
    return this.items.length === 0;
  }

  /**
   * @param items The menu items to include in the menu. If not specified, it
   * includes all menu items.
   */
  constructor(items?: readonly ApplicationMenuItem[]) {
    if (items == null) {
      items = allItems;
    } else {
      items = Object.freeze(items.map((item) => Object.freeze(item)));
    }

    const ids = cleanUnknownIds(items.map((item) => item.id));

    validateIdsAreUnique(ids);
    validateParentIdsAreValid(items);

    this.items = items;
    this._itemsById = Object.freeze(createItemsByIdMap(this.items));
    this._itemsByParentId = Object.freeze(createItemsByParentIdMap(this.items));
  }

  /**
   * Create a new ApplicationMenu instance from a list of menu item ids.
   * @param ids The ids of the menu items to include in the new menu.
   */
  static fromIds(ids: readonly string[]): ApplicationMenu {
    const knownIds = cleanUnknownIds(ids);

    const idSet = new Set(knownIds);
    const items = allItems.filter((item) => idSet.has(item.id));
    return new ApplicationMenu(items);
  }

  /**
   * Get a menu item by its id. It uses an internal map to quickly find the
   * menu item.
   * @param id The id of the menu item to find.
   * @returns The menu item if found, otherwise undefined.
   */
  public getItemById(id: string): ApplicationMenuItem | undefined {
    return this._itemsById.get(id);
  }

  /**
   * Get all menu items that have the specified parent id, e.g., all children of
   * a menu item. It uses an internal map to quickly find the menu items.
   * @param parentId The parent id of the menu items to find.
   * @returns Children of the menu item. If the menu item does not have any
   * children, it returns an empty array. If the menu item does not exist with
   * the specified id, it returns undefined.
   */
  public getItemsByParentId(
    parentId: string,
  ): ApplicationMenuItem[] | undefined {
    return this._itemsByParentId.get(parentId) ??
      (this.getItemById(parentId) != null ? [] : undefined);
  }

  /**
   * Compare the tree structure of the menu to the specified menu items.
   * @param menuItems The menu items to check.
   * @returns True if the menu items have the same tree structure as the menu,
   * otherwise false.
   * @throws Error if any of the menu items have an invalid id.
   */
  public compareTree(menuItems: ApplicationMenuItem[]): boolean {
    validateMenuItemsAreValid(menuItems);

    // If the length is not the same, then the tree structure is not the same.
    if (menuItems.length !== this.items.length) {
      return false;
    }

    // If the ids are the same, then we can assume that the tree structure is
    // the same.
    return menuItems.every((item, index) => item.id === this.items[index].id);
  }
}
