<form #searchForm="ngForm" class="pricer-form mt-2" novalidate id="searchForm" name="searchForm">
  <div class="row">
    <div class="col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-2 me-1' : ''}}">
      <div class="row">
        <div class="col-md-12">
          <div class="mb-3">
            <label for="vendorPicker" class="form-label" style="min-width: 90px;"> Vendor </label>
            <select class="form-select" id="vendorPicker" name="vendorPicker" #pricingVendor="ngModel" required
              [ngClass]="{'is-invalid' : pricingVendor && pricingVendor.touched && pricingVendor.invalid}"
              [(ngModel)]="selectedVendor" (ngModelChange)="onSelectedVendorChanged(selectedVendor)">
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let vendor of filteredPricingVendors" [ngValue]="vendor.value">
                {{vendor.name}}</option>
            </select>
            <div class="invalid-feedback">Please, pick a pricing vendor</div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="selectedVendor == 'OptimalBlue'">
        <div class="col-md-12">
          <div class="mb-3">
            <label for="pricingProfile" class="form-label" style="min-width: 90px;">OB - Pricing Profile: </label>
            <select class="form-select" id="pricingProfile" name="pricingProfile" #pricingProfileInput="ngModel"
              [(ngModel)]="businessChannelId" (ngModelChange)="onOptimalBlueChannelChanged()" required
              [ngClass]="{'is-invalid' : pricingProfileInput && pricingProfileInput.touched && pricingProfileInput.invalid}">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let profile of groupedVendorProfiles">
                <optgroup *ngIf="profile.externalCompanyName else noCompanyGroup"
                  label="{{profile.externalCompanyName}}">
                  <ng-container *ngFor="let compCred of profile.externalCompanyCreds">
                    <ng-container *ngIf="compCred.branchName else noBranchGroup">
                      <option disabled class="option-group">
                        {{compCred.branchName}}
                      </option>
                      <option *ngFor="let cred of compCred.branchCreds" [ngValue]="cred.pricingChannelId">
                        &nbsp;&nbsp;{{cred.name}}</option>
                    </ng-container>
                    <ng-template #noBranchGroup>
                      <option *ngFor="let cred of compCred.branchCreds" [ngValue]="cred.pricingChannelId">
                        {{cred.name}}</option>
                    </ng-template>
                  </ng-container>
                </optgroup>
                <ng-template #noCompanyGroup>
                  <option *ngFor="let cred of profile.externalCompanyCreds" [ngValue]="cred.pricingChannelId">
                    {{cred.name}}</option>
                </ng-template>
              </ng-container>
            </select>
            <div class="invalid-feedback">Please, pick a pricing profile</div>
          </div>
        </div>
      </div>
      <div class="row"
        *ngIf="selectedVendor === 'LenderPrice' || selectedVendor === 'Polly' || selectedVendor === 'MeridianLink'">
        <div class="col-md-12">
          <div class="mb-3">
            <label for="pricingProfile" class="form-label" style="min-width: 90px;">
              {{selectedVendor == 'LenderPrice' ? 'LP' : selectedVendor }} - Pricing Profile:
            </label>
            <select class="form-select" id="pricingProfile" name="pricingProfile" #pricingProfileInput="ngModel"
              [(ngModel)]="selectedProfileChannel" (ngModelChange)="onPollyLenderPriceChannelChanged($event)" required
              [ngClass]="{'is-invalid' : pricingProfileInput && pricingProfileInput.touched && pricingProfileInput.invalid}">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let profile of groupedVendorProfiles">
                <optgroup *ngIf="profile.externalCompanyName else noCompanyGroup"
                  label="{{profile.externalCompanyName}}">
                  <ng-container *ngFor="let compCred of profile.externalCompanyCreds">
                    <ng-container *ngIf="compCred.branchName else noBranchGroup">
                      <option disabled class="option-group">
                        {{compCred.branchName}}
                      </option>
                      <option *ngFor="let cred of compCred.branchCreds" [ngValue]="cred">
                        &nbsp;&nbsp;{{cred.name}}</option>
                    </ng-container>
                    <ng-template #noBranchGroup>
                      <option *ngFor="let cred of compCred.branchCreds" [ngValue]="cred">
                        {{cred.name}}</option>
                    </ng-template>
                  </ng-container>
                </optgroup>
                <ng-template #noCompanyGroup>
                  <option *ngFor="let cred of profile.externalCompanyCreds" [ngValue]="cred">
                    {{cred.name}}</option>
                </ng-template>
              </ng-container>
            </select>
            <div class="invalid-feedback">Please, pick a pricing profile</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.LoanPurpose')">
          <div class="mb-3">
            <label class="form-label" for="loanPurposeField">Loan Purpose</label>
            <select class="form-select" id="loanPurposeField" section="loan-information" name="loanPurposeField"
              #loanPurposeField="ngModel"
              [ngClass]="{'is-invalid' : loanPurposeField && loanPurposeField.touched && loanPurposeField.invalid}"
              [(ngModel)]="productSearchRequest.loanInformation.loanPurpose" required
              (ngModelChange)="onLoanPurposeChanged()"
              [disabled]="readonlyFields.includes('LoanInformation.LoanPurpose')">
              <option [ngValue]="null">--Select One--</option>
              <option *ngFor="let option of loanPurposeOptions" value="{{option.alternateValue}}">
                {{option.name}}</option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6"
          *ngIf="productSearchRequest.loanInformation.loanPurpose === 'Purchase' && !hiddenFields.includes('PropertyInformation.SalesPrice')">
          <div class="mb-3">
            <label class="form-label" for="subject">Purchase Price </label>
            <currency-input [name]="'purchasePrice'" [required]="true" [isZeroValid]="false" [allowNegative]="false"
              [allowDecimals]="false" section="loan-information"
              [disabled]="readonlyFields.includes('PropertyInformation.SalesPrice')"
              [(ngModel)]="productSearchRequest.propertyInformation.salesPrice" (blur)="onPurchasePriceChanged()">
            </currency-input>
            <div></div>
          </div>
        </div>
        <div class="col-md-6"
          *ngIf="['RefinanceCashOut', 'Refinance', 'StreamlineRefinance'].includes(productSearchRequest.loanInformation.loanPurpose) && !hiddenFields.includes('LoanInformation.RefinancePurpose')">
          <div class="mb-3">
            <label class="form-label" for="refinancePurposeField">Refinance Purpose</label>
            <select class="form-select" id="refinancePurposeField" section="loan-information"
              name="refinancePurposeField" #refinancePurposeField="ngModel"
              [ngClass]="{'is-invalid' : refinancePurposeField && refinancePurposeField.touched && refinancePurposeField.invalid}"
              [(ngModel)]="productSearchRequest.loanInformation.refinancePurpose"
              [disabled]="readonlyFields.includes('LoanInformation.RefinancePurpose')"
              (ngModelChange)="onRefiPurposeChanged()" required>
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of refinancePurposeOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('LoanInformation.RefinancePurpose')?.includes(option.alternateValue)">
                  {{option.name}}
                </option>
              </ng-container>

            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12"
          *ngIf="visibleFeatureFields.includes('Loan_IsStudentLoanCashout') && productSearchRequest.loanInformation.refinancePurpose === 'CashOutOther' && !hiddenFields.includes('LoanInformation.IsStudentLoanCashout')">
          <div class="form-check form-check-inline">
            <div class="checkbox">
              <input id="isStudentLoanCashout" type="checkbox" class="checkbox-input-relative-position"
                [disabled]="readonlyFields.includes('LoanInformation.IsStudentLoanCashout')" name="isStudentLoanCashout"
                [(ngModel)]="productSearchRequest.loanInformation.isStudentLoanCashout">
              <label for="isStudentLoanCashout">
                Is Student Loan Cashout
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row"
        *ngIf="visibleFeatureFields.includes('Loan_DocumentationType') && !hiddenFields.includes('LoanInformation.DocumentationType')">
        <div class="mb-3">
          <label class="form-label" for="documentationType">Documentation Type</label>
          <select class="form-select" id="documentationType" section="loan-information" name="documentationType"
            #documentationType="ngModel" [disabled]="readonlyFields.includes('LoanInformation.DocumentationType')"
            [ngClass]="{'is-invalid' : documentationType && documentationType.touched && documentationType.invalid}"
            [(ngModel)]="productSearchRequest.loanInformation.documentationType" required>
            <option [ngValue]="null">--Select One--</option>
            <ng-container *ngFor="let option of filteredDocumentationTypes">
              <option value="{{option.alternateValue}}"
                *ngIf="!hiddenEnumOptionsByField.get('LoanInformation.DocumentationType')?.includes(option.alternateValue)">
                {{option.name}}</option>
            </ng-container>
          </select>
          <div class="invalid-feedback">This field is required</div>
        </div>
      </div>
      <div class="row" *ngIf="!hiddenFields.includes('LoanInformation.LienType')">
        <div class="mb-3">
          <label class="form-label" for="lienPositionType">Lien Type</label>
          <select class="form-select" id="lienPositionType" section="loan-information" name="lienPositionType"
            #lienPositionType="ngModel" [disabled]="readonlyFields.includes('LoanInformation.LienType')"
            [ngClass]="{'is-invalid' : lienPositionType && lienPositionType.touched && lienPositionType.invalid}"
            [(ngModel)]="productSearchRequest.loanInformation.lienType" (ngModelChange)="onLienTypeChanged()" required>
            <option [ngValue]="null">--Select One--</option>
            <ng-container *ngFor="let option of lienPositionTypeOptions">
              <option value="{{option.value}}"
                *ngIf="!hiddenEnumOptionsByField.get('LoanInformation.LienType')?.includes(option.value)">
                {{option.name}}</option>
            </ng-container>
          </select>
          <div class="invalid-feedback">This field is required</div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" *ngIf="productSearchRequest.loanInformation.lienType == 'FirstLien' &&
        isHomeEquityLoanType === false && helocOrMortgageLoanType == 'Mortgage' &&
        !hiddenFields.includes('LoanInformation.SecondLienAmount')">
          <div class="mb-3">
            <label class="form-label" for="secondLienAmount">Second Lien Amount</label>
            <div class="d-flex">
              <div class="flex-grow-1">
                <currency-input [name]="'secondLienAmount'"
                  [disabled]="true && readonlyFields.includes('LoanInformation.SecondLienAmount')" [required]="true"
                  section="loan-information" [(ngModel)]="productSearchRequest.loanInformation.secondLienAmount"
                  (blur)="onSecondLienAmountChanged()">
                </currency-input>
                <div class="invalid-feedback">This field is required</div>
              </div>
              <a class="x" (click)="onSecondLienAmountCalculatorClicked()"><i
                  class="fa fa-calculator ms-2 mt-2"></i></a>
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="productSearchRequest.loanInformation.lienType == 'FirstLien' &&
        isHomeEquityLoanType === false &&
        helocOrMortgageLoanType == 'HELOC' && !hiddenFields.includes('LoanInformation.HelocDrawnAmount')">
          <div class="mb-3">
            <label class="form-label" for="helocDrawnAmount">HELOC Drawn Amount</label>
            <div class="d-flex">
              <div class="flex-grow-1">
                <currency-input [name]="'helocDrawnAmount'"
                  [disabled]="true && readonlyFields.includes('LoanInformation.HelocDrawnAmount')" [required]="true"
                  section="loan-information" [(ngModel)]="productSearchRequest.loanInformation.helocDrawnAmount"
                  (blur)="onSecondLienAmountChanged()">
                </currency-input>
                <div class="invalid-feedback">This field is required</div>
              </div>
              <a class="x" (click)="onSecondLienAmountCalculatorClicked()"><i
                  class="fa fa-calculator ms-2 mt-2"></i></a>
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="productSearchRequest.loanInformation.lienType == 'FirstLien' &&
        isHomeEquityLoanType === false &&
      helocOrMortgageLoanType == 'HELOC' && !hiddenFields.includes('LoanInformation.HelocLineAmount')">
          <div class="mb-3">
            <label class="form-label" for="helocLineAmount">HELOC Line Amount</label>
            <div class="d-flex">
              <div class="flex-grow-1">
                <currency-input [name]="'helocLineAmount'"
                  [disabled]="true && readonlyFields.includes('LoanInformation.HelocLineAmount')" [required]="true"
                  section="loan-information" [(ngModel)]="productSearchRequest.loanInformation.helocLineAmount"
                  (blur)="onSecondLienAmountChanged()">
                </currency-input>
                <div class="invalid-feedback">This field is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.BaseLoanAmount')">
          <div class="mb-3">
            <label class="form-label" for="loanAmount">Base Loan Amount </label>
            <currency-input [name]="'loanAmount'" [required]="true" [isZeroValid]="false" [allowDecimals]="false"
              [disabled]="readonlyFields.includes('LoanInformation.BaseLoanAmount')" section="loan-information"
              [(ngModel)]="productSearchRequest.loanInformation.baseLoanAmount" (blur)="onBaseLoanAmountChanged()">
            </currency-input>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6"
          *ngIf="productSearchRequest.loanInformation.lienType != 'FirstLien' && !hiddenFields.includes('LoanInformation.FirstLienAmount')">
          <div class="mb-3">
            <label class="form-label" for="firstLienAmount">First Lien Amount</label>
            <currency-input [name]="'firstLienAmount'" [required]="true" [allowNegative]="false"
              section="loan-information" [(ngModel)]="productSearchRequest.loanInformation.firstLienAmount"
              [disabled]="readonlyFields.includes('LoanInformation.FirstLienAmount')"
              (blur)="onFirstLienAmountChanged()">
            </currency-input>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" *ngIf="!hiddenFields.includes('PropertyInformation.AppraisedValue')">
          <div class="mb-3">
            <label class="form-label" for="appraisedValue">Appraised Value </label>
            <currency-input [name]="'appraisedValue'" [required]="true" [allowNegative]="false" [allowDecimals]="false"
              section="loan-information" [(ngModel)]="productSearchRequest.propertyInformation.appraisedValue"
              [disabled]="readonlyFields.includes('PropertyInformation.AppraisedValue')"
              (blur)="onAppraisedValueChanged()">
            </currency-input>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.CashOutAmount')">
          <div class="mb-3">
            <label class="form-label" for="cashOutAmount">Cash-Out Amount</label>
            <currency-input [name]="'cashOutAmount'" [required]="!isPurchase" section="loan-information"
              [disabled]="readonlyFields.includes('LoanInformation.CashOutAmount')" [allowDecimals]="false"
              [(ngModel)]="productSearchRequest.loanInformation.cashOutAmount">
            </currency-input>
          </div>
          <div class="invalid-feedback">This field is required</div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.Ltv')">
          <div class="mb-3">
            <label class="form-label" for="ltvAmount">LTV</label>
            <percent-input [name]="'ltvAmount'" [required]="true" section="loan-information"
              [rateIsTrueToTextValue]="false" [(ngModel)]="productSearchRequest.loanInformation.ltv"
              [disabled]="readonlyFields.includes('LoanInformation.Ltv')" #ltvAmount="ngModel"
              [ngClass]="{'is-invalid' : ltvAmount && ltvAmount.touched && ltvAmount.invalid}"
              (keydown)="onLtvKeyDown($event)" (blur)="onLtvValueChanged()">
            </percent-input>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.Cltv')">
          <div class="mb-3">
            <label class="form-label" for="cltvAmount">CLTV</label>
            <percent-input [name]="'cltvAmount'" [required]="true" section="loan-information"
              [rateIsTrueToTextValue]="false" [(ngModel)]="productSearchRequest.loanInformation.cltv"
              #cltvAmount="ngModel" [disabled]="readonlyFields.includes('LoanInformation.Cltv')"
              [ngClass]="{'is-invalid' : (cltvAmount && cltvAmount.touched && cltvAmount.invalid) || productSearchRequest.loanInformation.ltv > productSearchRequest.loanInformation.cltv}"
              (keydown)="onCltvKeyDown($event)" (blur)="onCltvValueChanged()">
            </percent-input>
            <div *ngIf="productSearchRequest.loanInformation.ltv > productSearchRequest.loanInformation.cltv"
              class="invalid-feedback"> Cltv value must be equal or greater than Ltv </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.WaiveEscrows')">
          <div class="mb-3">
            <label class="form-label" for="waiveEscrows">Waive Escrows</label>
            <select class="form-select" id="waiveEscrows" name="waiveEscrows" required
              [disabled]="readonlyFields.includes('LoanInformation.WaiveEscrows')"
              [(ngModel)]="productSearchRequest.loanInformation.waiveEscrows">
              <option [value]="true">Yes</option>
              <option [value]="false">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('BorrowerInformation.MonthsReserves')">
          <div class="mb-3">
            <label class="form-label" for="monthsOfReserves">Months of Reserves</label>
            <input class="form-control" name="monthsOfReserves" required mask="separator.3" section="loan-information"
              [(ngModel)]="productSearchRequest.borrowerInformation.monthsReserves" #monthsOfReserves="ngModel"
              [disabled]="readonlyFields.includes('BorrowerInformation.MonthsReserves')"
              [ngClass]="{'is-invalid' : monthsOfReserves && monthsOfReserves.touched && monthsOfReserves.invalid}" />
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.TaxesAndInsuranceMonthly')">
          <div class="mb-3">
            <label class="form-label" for="taxesAndInsurance">Taxes and Insurance (mo)</label>
            <currency-input [name]="'taxesAndInsurance'"
              [disabled]="readonlyFields.includes('LoanInformation.TaxesAndInsuranceMonthly')"
              [(ngModel)]="productSearchRequest.loanInformation.taxesAndInsuranceMonthly">
            </currency-input>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6" *ngIf="!hiddenFields.includes('RepresentativeCreditScore')">
          <div class="mb-3">
            <label class="form-label" for="fico">FICO</label>
            <input class="form-control numeric-without-arrows" name="fico" id="fico" section="borrower-info"
              type="number" [disabled]="disableCreditScore" required numeric
              [disabled]="readonlyFields.includes('RepresentativeCreditScore')"
              [(ngModel)]="productSearchRequest.representativeCreditScore" #fico="ngModel"
              [ngClass]="{'is-invalid' : fico && fico.touched && fico.invalid}" />
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('BorrowerInformation.SelfEmployed')">
          <div class="mb-3">
            <label class="form-label" for="selfEmployed">Self Employed</label>
            <select class="form-select" id="selfEmployed" name="selfEmployed"
              [disabled]="readonlyFields.includes('BorrowerInformation.SelfEmployed')"
              [(ngModel)]="productSearchRequest.borrowerInformation.selfEmployed">
              <option [ngValue]="null">--Select One--</option>
              <option [ngValue]="true">Yes</option>
              <option [ngValue]="false">No</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" *ngIf="showMonthlyIncome && !hiddenFields.includes('BorrowerInformation.MonthlyIncome')">
          <div class="mb-3">
            <label class="form-label" for="monthlyIncome">Monthly Income</label>
            <currency-input [name]="'monthlyIncome'" id="monthlyIncome"
              [(ngModel)]="productSearchRequest.borrowerInformation.monthlyIncome"
              [disabled]="readonlyFields.includes('BorrowerInformation.MonthlyIncome')"
              [min]="selectedVendor === 'LenderPrice' ? 1 : 0">
            </currency-input>
          </div>
        </div>
        <div class="col-md-6"
          *ngIf="visibleFeatureFields.includes('Borrower_TotalLiquidAssets') && !hiddenFields.includes('BorrowerInformation.TotalLiquidAssets')">
          <div class="mb-3">
            <label class="form-label" for="totalLiquidAssets">Total Liquid Assets</label>
            <currency-input [name]="'totalLiquidAssets'"
              [disabled]="readonlyFields.includes('BorrowerInformation.TotalLiquidAssets')"
              [(ngModel)]="productSearchRequest.borrowerInformation.totalLiquidAssets">
            </currency-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanLevelDebtToIncomeRatio')">
          <div class="mb-3">
            <label class="form-label" for="loanLevelDebtToIncomeRatio">DTI Ratio</label>
            <percent-input name="loanLevelDebtToIncomeRatio" [rateIsTrueToTextValue]="false"
              [disabled]="readonlyFields.includes('LoanLevelDebtToIncomeRatio')"
              [(ngModel)]="productSearchRequest.loanLevelDebtToIncomeRatio" #loanLevelDebtToIncomeRatio="ngModel">
            </percent-input>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('BorrowerInformation.Citizenship')">
          <div class="mb-3">
            <label class="form-label" for="citizenship">Citizenship</label>
            <select class="form-select" id="citizenship" name="citizenship"
              [(ngModel)]="productSearchRequest.borrowerInformation.citizenship"
              [disabled]="readonlyFields.includes('BorrowerInformation.Citizenship')">
              <option [value]="null">--Select One--</option>
              <ng-container *ngFor="let option of citizenshipOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('BorrowerInformation.Citizenship')?.includes(option.alternateValue)">
                  {{option.name}}</option>
              </ng-container>

            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('BorrowerInformation.FirstTimeHomeBuyer')">
          <div class="mb-3">
            <label class="form-label" for="firstTimeHomeBuyer">First Time Home Buyer</label>
            <select class="form-select" name="firstTimeHomeBuyer" id="firstTimeHomeBuyer"
              [disabled]="readonlyFields.includes('BorrowerInformation.FirstTimeHomeBuyer')"
              [(ngModel)]="productSearchRequest.borrowerInformation.firstTimeHomeBuyer">
              <option [ngValue]="null">--Select One--</option>
              <option [ngValue]="true">Yes</option>
              <option [ngValue]="false">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-6"
          *ngIf="visibleFeatureFields.includes('Borrower_NumberOfFinancedProperties') && !hiddenFields.includes('BorrowerInformation.NumberOfFinancedProperties')">
          <div class="mb-3">
            <label class="form-label" for="numberOfFinancedProperties">No Of Financed Properties</label>
            <input numeric class="form-control" id="numberOfFinancedProperties" name="numberOfFinancedProperties"
              [disabled]="readonlyFields.includes('BorrowerInformation.NumberOfFinancedProperties')"
              [(ngModel)]="productSearchRequest.borrowerInformation.numberOfFinancedProperties" mask="separator.3">
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.NonOccupyingCoBorrower')">
          <div class="mb-3">
            <label class="form-label" for="intentToOccupy">Non-Occupant Coborrower</label>
            <select class="form-select" name="intentToOccupy" id="intentToOccupy" required
              [disabled]="readonlyFields.includes('LoanInformation.NonOccupyingCoBorrower')"
              [(ngModel)]="productSearchRequest.loanInformation.nonOccupyingCoBorrower">
              <option [ngValue]="true">Yes</option>
              <option [ngValue]="false">No</option>
            </select>
          </div>
        </div>
      </div>

    </div>
    <div class="col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-2 me-1' : ''}}">

      <div class="row">
        <div class="col-md-12" *ngIf="!hiddenFields.includes('PropertyInformation.Occupancy')">
          <div class="mb-3">
            <label class="form-label" for="occupancy">Occupancy</label>
            <select class="form-select" name="occupancy" id="occupancy"
              [(ngModel)]="productSearchRequest.propertyInformation.occupancy"
              [disabled]="readonlyFields.includes('PropertyInformation.Occupancy')">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of occupancyOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.Occupancy')?.includes(option.alternateValue)">
                  {{option.name}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('PropertyInformation.ConstructionMethod')">
          <div class="mb-3">
            <label class="form-label" for="constructionMethod">Construction Method</label>
            <select class="form-select" id="constructionMethod" name="constructionMethod" #constructionMethod="ngModel"
              [(ngModel)]="productSearchRequest.propertyInformation.constructionMethod"
              [disabled]="readonlyFields.includes('PropertyInformation.ConstructionMethod')">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of constructionMethodOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.ConstructionMethod')?.includes(option.alternateValue)">
                  {{option.name}}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('PropertyInformation.AttachmentType')">
          <div class="mb-3">
            <label class="form-label" for="propertyAttachmentType">Attachment Type</label>
            <select class="form-select" id="propertyAttachmentType" name="propertyAttachmentType"
              #propertyAttachmentType="ngModel" [(ngModel)]="productSearchRequest.propertyInformation.attachmentType"
              [disabled]="readonlyFields.includes('PropertyInformation.AttachmentType')">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of attachmentTypeOptions">
                <option
                  *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.AttachmentType')?.includes(option.alternateValue)"
                  value="{{option.alternateValue}}">
                  {{option.name}}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-12" *ngIf="!hiddenFields.includes('PropertyInformation.ManufacturedHomeWidthType')">
          <div class="mb-3" *ngIf="isConstructionTypeManufactured()">
            <label class="form-label" for="manufacturedHomeWidthType">Home Width Type</label>
            <select class="form-select" name="manufacturedHomeWidthType" id="manufacturedHomeWidthType"
              [disabled]="readonlyFields.includes('PropertyInformation.ManufacturedHomeWidthType')"
              [(ngModel)]="productSearchRequest.propertyInformation.manufacturedHomeWidthType">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of manufacturedHomeWidthTypeOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.ManufacturedHomeWidthType')?.includes(option.alternateValue)">
                  {{option.name}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('PropertyInformation.ProjectType')">
          <div class="mb-3">
            <label class="form-label" for="projectType">Project Type</label>
            <select class="form-select" name="projectType" id="projectType"
              [disabled]="readonlyFields.includes('PropertyInformation.ProjectType')"
              [(ngModel)]="productSearchRequest.propertyInformation.projectType">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of projectTypeOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.ProjectType')?.includes(option.alternateValue)">
                  {{option.name}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('PropertyInformation.ProjectDesignType')">
          <div class="mb-3" *ngIf="isProjectTypeCondominium()">
            <label class="form-label" for="projectDesignType">Project Design Type</label>
            <select class="form-select" name="projectDesignType" id="projectDesignType"
              [disabled]="readonlyFields.includes('PropertyInformation.ProjectDesignType')"
              [(ngModel)]="productSearchRequest.propertyInformation.projectDesignType">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of projectDesignTypeOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.ProjectDesignType')?.includes(option.alternateValue)">
                  {{option.name}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-12"
          *ngIf="showNonWarrantableProjectCheckBox() && !hiddenFields.includes('PropertyInformation.IsNonWarrantableProject')">
          <div class="form-check form-check-inline">
            <div class="checkbox">
              <input id="isNonWarrantableProject" type="checkbox" class="checkbox-input-relative-position"
                name="isNonWarrantableProject"
                [disabled]="readonlyFields.includes('PropertyInformation.IsNonWarrantableProject')"
                [(ngModel)]="productSearchRequest.propertyInformation.isNonWarrantableProject">
              <label for="isNonWarrantableProject">
                Non Warrantable Project
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-12"
          *ngIf="isProjectTypeCondominium() && visibleFeatureFields.includes('Property_Condotel') && !hiddenFields.includes('PropertyInformation.IsCondotel')">
          <div class="form-check form-check-inline">
            <div class="checkbox">
              <input id="isCondotel" type="checkbox" class="checkbox-input-relative-position" name="isCondotel"
                [disabled]="readonlyFields.includes('PropertyInformation.IsCondotel')"
                [(ngModel)]="productSearchRequest.propertyInformation.isCondotel">
              <label for="isCondotel">
                Is Condotel
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="!hiddenFields.includes('PropertyInformation.IsPlannedUnitDevelopment')">
          <div class="form-check form-check-inline">
            <div class="checkbox">
              <input id="isPlannedUnitDevelopment" type="checkbox" class="checkbox-input-relative-position"
                name="isPlannedUnitDevelopment"
                [disabled]="readonlyFields.includes('PropertyInformation.IsPlannedUnitDevelopment')"
                [(ngModel)]="productSearchRequest.propertyInformation.isPlannedUnitDevelopment">
              <label for="isPlannedUnitDevelopment">
                Planned Unit Development (PUD)
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('PropertyInformation.NumberOfUnits')">
          <div class="mb-3">
            <label class="form-label" for="numberOfUnits">Number of Units</label>
            <select class="form-select" name="numberOfUnits" id="numberOfUnits"
              [(ngModel)]="productSearchRequest.propertyInformation.numberOfUnits"
              [disabled]="readonlyFields.includes('PropertyInformation.NumberOfUnits')">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of numberOfUnitsOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.NumberOfUnits')?.includes(option.alternateValue)">
                  {{option.name}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('PropertyInformation.NumberOfStories')">
          <div class="mb-3">
            <label class="form-label" for="numberOfStories">Number of Stories</label>
            <input numeric class="form-control" id="numberOfStories" name="numberOfStories" mask="separator.3"
              [disabled]="readonlyFields.includes('PropertyInformation.NumberOfStories')"
              [(ngModel)]="productSearchRequest.propertyInformation.numberOfStories">
          </div>
        </div>
        <div class="col-md-12" *ngIf="!hiddenFields.includes('PropertyInformation.ZipCode')">
          <label class="form-label" for="propertyZIPfield">Property Zip</label>
          <div class="input-group mb-3">
            <zip-code-input [(ngModel)]="productSearchRequest.propertyInformation.zipCode" [required]="true"
              (blur)="onZipCodeBlur($event)" style="width:100%" [name]="'propertyZIPfield'"
              [disabled]="readonlyFields.includes('PropertyInformation.ZipCode')"
              (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
            </zip-code-input>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('PropertyInformation.State')">
          <div class="mb-3">
            <label class="form-label" for="stateField">State</label>
            <select class="form-select" id="stateField" name="stateField" [name]="'stateField'" #stateField="ngModel"
              [(ngModel)]="productSearchRequest.propertyInformation.state"
              [disabled]="true && readonlyFields.includes('PropertyInformation.State')"
              [ngClass]="{'is-invalid' : stateField && stateField.touched && stateField.invalid}" required>
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of stateOptions">
                <option value="{{option.value}}"
                  *ngIf="!hiddenEnumOptionsByField.get('PropertyInformation.State')?.includes(option.value)">
                  {{option.name}}</option>
              </ng-container>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>

        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('PropertyInformation.County')">
          <div class="mb-3">
            <label class="form-label" for="countyField">County</label>
            <input class="form-control" name="countyField" required #countyField="ngModel"
              [ngClass]="{'is-invalid' : countyField && countyField.touched && countyField.invalid}"
              [disabled]="readonlyFields.includes('PropertyInformation.County')"
              (typeaheadOnSelect)="onCountySelect($event)" [(ngModel)]="productSearchRequest.propertyInformation.county"
              [typeahead]="countyList" typeaheadOptionField="name" [typeaheadScrollable]="true"
              [typeaheadOptionsInScrollableView]="10" />
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6"
          *ngIf="visibleFeatureFields.includes('Property_AreaMedianIncome') && !hiddenFields.includes('PropertyInformation.AreaMedianIncome')">
          <div class="mb-3">
            <label class="form-label" for="areaMedianIncome">Area Median Income</label>
            <currency-input [name]="'areaMedianIncome'" section="loan-information"
              [disabled]="readonlyFields.includes('PropertyInformation.AreaMedianIncome')"
              [(ngModel)]="productSearchRequest.propertyInformation.areaMedianIncome">
            </currency-input>
          </div>
        </div>
        <div class="col-md-12"
          *ngIf="visibleFeatureFields.includes('Property_IsInRuralArea') && !hiddenFields.includes('PropertyInformation.IsInRuralArea')">
          <div class="form-check form-check-inline">
            <div class="checkbox">
              <input id="isInRuralArea" type="checkbox" class="checkbox-input-relative-position" name="isInRuralArea"
                [disabled]="readonlyFields.includes('PropertyInformation.IsInRuralArea')"
                [(ngModel)]="productSearchRequest.propertyInformation.isInRuralArea">
              <label for="isInRuralArea">
                Is In Rural Area
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-12"
          *ngIf="visibleFeatureFields.includes('Property_IsSeasonalProperty') && !hiddenFields.includes('PropertyInformation.IsSeasonalProperty')">
          <div class="form-check form-check-inline">
            <div class="checkbox">
              <input id="isSeasonalProperty" type="checkbox" class="checkbox-input-relative-position"
                [disabled]="readonlyFields.includes('PropertyInformation.IsSeasonalProperty')" name="isSeasonalProperty"
                [(ngModel)]="productSearchRequest.propertyInformation.isSeasonalProperty">
              <label for="isSeasonalProperty">
                Is Seasonal Property
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12"
          *ngIf="multipleLoanTypesVisible === false && !hiddenFields.includes('LoanInformation.LoanType')">
          <div class="mb-3">
            <label class="form-label" for="loanType"> Loan Type </label>
            <select class="form-select" name="loanType" id="loanType"
              [disabled]="readonlyFields.includes('LoanInformation.LoanType')"
              [(ngModel)]="productSearchRequest.loanInformation.loanType" (ngModelChange)="onLoanTypeChanged()">
              <option [ngValue]="null"> --Select One-- </option>
              <ng-container *ngFor="let option of filteredLoanTypeOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('LoanInformation.LoanType')?.includes(option.alternateValue)">
                  {{option.name}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <!-- <div class="col-12"> -->
        <!-- <h5>Loan Type(s)</h5>
      <div class="inline-radio-button">
        <div class="form-check" *ngFor="let option of loanTypeOptions">
          <div class="radio radio-primary">
            <input id="loanType-{{option.name}}" id="loanType-{{option.name}}" [value]="option.alternateValue"
              type="radio" name="loanType-{{option.name}}" [(ngModel)]="productSearchRequest.loanInformation.loanType"
              [checked]="productSearchRequest.loanInformation.loanType == option.name">
            <label for="loanType-{{option.name}}">
              {{option.name}}
            </label>
          </div>
        </div>
      </div> -->
        <!-- <ng-multiselect-dropdown name="loanTypes" [placeholder]="'-- Select --'"
        [settings]="accessListMultiSelectSettings" [data]="loanTypeOptions" [(ngModel)]="selectedLoanTypes"
        (ngModelChange)="loanTypesChanged()">
      </ng-multiselect-dropdown> -->
        <!-- </div> -->
        <hr>
        <div class="col-12">
          <h5>Loan Term(s)</h5>
          <ng-multiselect-dropdown name="loanTerms" [placeholder]="'-- Select --'" [settings]="multiSelectSettings"
            [data]="loanTermOptions" [(ngModel)]="selectedLoanTerms" (ngModelChange)="loanTermsChanged()">
          </ng-multiselect-dropdown>
        </div>
        <div class="col-12 mt-2" *ngIf="!hiddenFields.includes('AmortizationType')">
          <h5>Amortization Type(s)</h5>
          <ng-multiselect-dropdown name="amortTypes" [placeholder]="'-- Select --'" [settings]="multiSelectSettings"
            [data]="amortizationTypeOptions" [(ngModel)]="selectedAmortizationTypes"
            [disabled]="readonlyFields.includes('AmortizationType')"
            (ngModelChange)="amortizationTypeChanged()">
          </ng-multiselect-dropdown>
        </div>
        <div class="col-12 mt-2" *ngIf="productSearchRequest.amortizationTypes.includes('ARM')">
          <h5>ARM Fixed Term(s)</h5>
          <ng-multiselect-dropdown name="armFixedTerms" [placeholder]="'-- Select --'" [settings]="multiSelectSettings"
            [data]="armFixedTermOptions" [(ngModel)]="selectedArmFixedTerms" (ngModelChange)="armFixedTermChanged()">
          </ng-multiselect-dropdown>
        </div>
        <div class="col-12 mt-2" *ngIf="!hiddenFields.includes('ProductType')">
          <h5>Product Type(s)</h5>
          <ng-multiselect-dropdown name="productTypes" [placeholder]="'-- Select --'" [settings]="multiSelectSettings"
            [data]="filteredProductTypeOptions" [(ngModel)]="selectedProductTypes"
            [disabled]="readonlyFields.includes('ProductType')"
            (ngModelChange)="productTypeChanged()">
          </ng-multiselect-dropdown>
        </div>
        <div class="col-12 mt-2" *ngIf="multipleLoanTypesVisible && !hiddenFields.includes('LoanInformation.LoanType')">
          <h5>Loan Type(s)</h5>
          <ng-multiselect-dropdown name="loanTypes" [placeholder]="'-- Select --'"
            [disabled]="readonlyFields.includes('LoanInformation.LoanType')" [settings]="loanTypesMultiSelectSettings"
            [data]="filteredLoanTypeOptions" [(ngModel)]="selectedLoanTypes"
            (ngModelChange)="onMultipleLoanTypesChanged()">
          </ng-multiselect-dropdown>
        </div>
        <hr class="mt-4">
      </div>
    </div>
    <div class="col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-2 ms-n2' : ''}}">
      <div class="row">
        <div class="col-md-6" *ngIf="!hiddenFields.includes('DesiredPrice')">
          <div class="mb-3">
            <label class="form-label" for="desiredPrice">Desired Price</label>
            <input name="'desiredPrice'" numeric [decimals]="3" class="form-control"
              [disabled]="readonlyFields.includes('DesiredPrice')" [(ngModel)]="productSearchRequest.desiredPrice" />
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('DesiredRate')">
          <div class="mb-3">
            <label class="form-label" for="desiredRate">Desired Rate</label>
            <percent-input name="desiredRate" [(ngModel)]="productSearchRequest.desiredRate"
              [disabled]="readonlyFields.includes('DesiredRate')" [rateIsTrueToTextValue]="false"
              #desiredRate="ngModel">
            </percent-input>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('DesiredLockPeriod')">
          <div class="mb-3">
            <label class="form-label" for="desiredRate">Desired Lock Period</label>
            <select class="form-select" id="desiredLockPeriod" [name]="'desiredLockPeriod'"
              [disabled]="readonlyFields.includes('DesiredLockPeriod')"
              [(ngModel)]="productSearchRequest.desiredLockPeriod">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of desiredLockPeriodsList">
                <option value="{{option.value}}"
                  *ngIf="!hiddenEnumOptionsByField.get('DesiredLockPeriod')?.includes(option.value)">
                  {{option.value}}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('InterestOnly')">
          <div class="mb-3">
            <label class="form-label" for="interestOnly">Interest Only</label>
            <select class="form-select" id="interestOnly" [name]="'interestOnly'"
              [disabled]="readonlyFields.includes('InterestOnly')" [(ngModel)]="productSearchRequest.interestOnly">
              <option [ngValue]="null">--Select One--</option>
              <option [ngValue]="true">Yes</option>
              <option [ngValue]="false">No</option>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('Buydown')">
          <div class="mb-3">
            <label class="form-label" for="buydown">BuyDown</label>
            <select class="form-select" id="buydown" [name]="'buydown'" [(ngModel)]="productSearchRequest.buydown"
              [disabled]="readonlyFields.includes('Buydown')">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of filteredBuyDownOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('Buydown')?.includes(option.alternateValue)">{{option.name}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.BorrowerPaidMi')">
          <div class="mb-3">
            <label class="form-label" for="borrowerPaidMi">Borrower Pays MI <small>(if required)</small></label>
            <select class="form-select" id="borrowerPaidMi" [name]="'borrowerPaidMi'"
              [disabled]="readonlyFields.includes('LoanInformation.BorrowerPaidMi')"
              [(ngModel)]="productSearchRequest.loanInformation.borrowerPaidMi">
              <option [ngValue]="null">--Select One--</option>
              <option [ngValue]="true">Yes</option>
              <option [ngValue]="false">No</option>
            </select>
          </div>
        </div>
        <div [class]="applicationChannel !== 'Correspondent' ? 'col-md-6' : 'col-md-12'"
          *ngIf="!hiddenFields.includes('LoanInformation.AutomatedUnderwritingSystem')">
          <div class="mb-3">
            <label class="form-label" for="automatedUnderwritingSystem">Automated U/W System</label>
            <select class="form-select" id="automatedUnderwritingSystem" [name]="'automatedUnderwritingSystem'"
              [disabled]="readonlyFields.includes('LoanInformation.AutomatedUnderwritingSystem')"
              [(ngModel)]="productSearchRequest.loanInformation.automatedUnderwritingSystem">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of automatedUnderwritingSystemOptions">
                <option value="{{option.alternateValue}}"
                  [disabled]="readonlyFields.includes('LoanInformation.AutomatedUnderwritingSystem')">
                  {{option.name}}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div
          *ngIf="applicationChannel !== 'Correspondent' && !hiddenFields.includes('LoanInformation.IncludeFeesInBasePrice')"
          class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="includeFeesInBasePrice">Fees In</label>
            <select class="form-select" id="includeFeesInBasePrice" name="includeFeesInBasePrice"
              #includeFeesInBasePrice="ngModel" required section="product-search-criteria"
              [disabled]="readonlyFields.includes('LoanInformation.IncludeFeesInBasePrice')"
              [ngClass]="{'is-invalid' : includeFeesInBasePrice && includeFeesInBasePrice.touched && includeFeesInBasePrice.invalid}"
              [(ngModel)]="productSearchRequest.loanInformation.includeFeesInBasePrice">
              <option [ngValue]="null">--Select One--</option>
              <option [ngValue]="true">Yes (Fees In)
              </option>
              <option [ngValue]="false">No (Fees Out)
              </option>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.PrepaymentPenalty')">
          <div class="mb-3">
            <label class="form-label" for="prepaymentPenalty">Prepayment Penalty</label>
            <select class="form-select" id="prepaymentPenalty" [name]="'prepaymentPenalty'"
              [(ngModel)]="productSearchRequest.loanInformation.prepaymentPenalty" section="product-search-criteria"
              [disabled]="readonlyFields.includes('LoanInformation.PrepaymentPenalty')">
              <ng-container *ngFor="let option of prepaymentPenaltyOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('LoanInformation.PrepaymentPenalty')?.includes(option.alternateValue)">
                  {{option.name}}</option>
              </ng-container>

            </select>
          </div>
        </div>
        <div
          *ngIf="applicationChannel !== 'Correspondent' && !hiddenFields.includes('CompInformation.IncludeLoCompensationInPricing')"
          class="col-md-6">
          <div class="mb-3">
            <label class="form-label" for="lenderPaidCompensation">Compensation</label>
            <select class="form-select" id="lenderPaidCompensation" [name]="'lenderPaidCompensation'"
              [(ngModel)]="productSearchRequest.compInformation.includeLoCompensationInPricing"
              [ngClass]="{'is-invalid' : lenderPC && lenderPC.touched && lenderPC.invalid}" #lenderPC="ngModel" required
              section="product-search-criteria"
              [disabled]="readonlyFields.includes('CompInformation.IncludeLoCompensationInPricing')">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of includeLoCompensationInPricingOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('CompInformation.IncludeLoCompensationInPricing')?.includes(option.alternateValue)">
                  {{option.name}}</option>
              </ng-container>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="productSearchRequest.compInformation.includeLoCompensationInPricing === 'BorrowerPaid'">
        <div class="col-md-6"
          *ngIf="visibleFeatureFields.includes('Comp_Percent') && !hiddenFields.includes('CompInformation.Percent')">
          <div class="mb-3">
            <label class="form-label" for="compPercent">Compensation %</label>
            <percent-input name="compPercent" [(ngModel)]="productSearchRequest.compInformation.percent"
              [disabled]="readonlyFields.includes('CompInformation.Percent')" [rateIsTrueToTextValue]="false"
              #compPercent="ngModel">
            </percent-input>
          </div>
        </div>
        <div class="col-md-6"
          *ngIf="visibleFeatureFields.includes('Comp_PercentBasedOn') && !hiddenFields.includes('CompInformation.PercentBasedOn')">
          <div class="mb-3">
            <label class="form-label" for="percentBasedOn">% Based On</label>
            <select class="form-select" id="percentBasedOn" [name]="'percentBasedOn'"
              [disabled]="readonlyFields.includes('CompInformation.PercentBasedOn')"
              [(ngModel)]="productSearchRequest.compInformation.percentBasedOn">
              <option [ngValue]="null">--Select One--</option>
              <ng-container *ngFor="let option of compensationPercentBasedOnOptions">
                <option value="{{option.alternateValue}}"
                  *ngIf="!hiddenEnumOptionsByField.get('CompInformation.PercentBasedOn')?.includes(option.alternateValue)">
                  {{option.name}}</option>
              </ng-container>
            </select>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12"
          *ngIf="isHomeEquityLoanType === false && productSearchRequest.loanInformation.lienType !== 'SecondLien' && !hiddenFields.includes('LoanInformation.CalculateTotalLoanAmount')">
          <div class="form-check form-check-inline">
            <div class="checkbox">
              <input name="manuallyEnterPmiMipFfGFeeDetails" id="manuallyEnterPmiMipFfGFeeDetails"
                [value]="manuallyEnterPmiMipFfGFeeDetails" type="checkbox" class="checkbox-input-relative-position"
                [(ngModel)]="manuallyEnterPmiMipFfGFeeDetails"
                [disabled]="readonlyFields.includes('LoanInformation.CalculateTotalLoanAmount')"
                (ngModelChange)="onManuallyEnterPmiMipFfGFeeDetailsChanged()">
              <label for="manuallyEnterPmiMipFfGFeeDetails">
                Manually enter PMI/MIP/FF/G Fee Details
              </label>
            </div>
          </div>
        </div>

        <ng-container *ngIf="manuallyEnterPmiMipFfGFeeDetails">
          <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfPercent')">
            <div class="mb-3">
              <label class="form-label" for="upfrontPmiMipFfGfPercent">PMI/MIP/FF/G Fee %</label>
              <percent-input name="upfrontPmiMipFfGfPercent"
                [(ngModel)]="productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent"
                [disabled]="readonlyFields.includes('LoanInformation.UpfrontPmiMipFfGfPercent')"
                [rateIsTrueToTextValue]="false" (blur)="onUpfrontPmiMipFfGfPercentChanged()"
                #upfrontPmiMipFfGfPercent="ngModel">
              </percent-input>
            </div>
          </div>
          <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfAmount')">
            <div class="mb-3">
              <label class="form-label" for="upfrontPmiMipFfGfAmount">PMI/MIP/FF/G Fee Amount</label>
              <currency-input [name]="'upfrontPmiMipFfGfAmount'"
                [(ngModel)]="productSearchRequest.loanInformation.upfrontPmiMipFfGfAmount"
                [disabled]="readonlyFields.includes('LoanInformation.UpfrontPmiMipFfGfAmount')"
                (ngModelChange)="onUpfrontPmiMipFfGfAmountChanged()">
              </currency-input>
            </div>
          </div>
          <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfPaidinCash')">
            <div class="mb-3">
              <label class="form-label" for="upfrontPmiMipFfGfPaidinCash">PMI/MIP/FF/G Fee Paid in Cash</label>
              <currency-input [name]="'upfrontPmiMipFfGfPaidinCash'"
                [(ngModel)]="productSearchRequest.loanInformation.upfrontPmiMipFfGfPaidinCash"
                [disabled]="readonlyFields.includes('LoanInformation.UpfrontPmiMipFfGfPaidinCash')"
                (ngModelChange)="onUpfrontPmiMipFfGfPaidinCashChanged()" [disabled]="financeEntireAmount">
              </currency-input>
            </div>
          </div>
          <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfFinancedAmount')">
            <div class="mb-3">
              <label class="form-label" for="upfrontPmiMipFfGfFinancedAmount">PMI/MIP/FF/G Fee Financed</label>
              <currency-input [name]="'upfrontPmiMipFfGfFinancedAmount'"
                [(ngModel)]="productSearchRequest.loanInformation.upfrontPmiMipFfGfFinancedAmount"
                [disabled]="readonlyFields.includes('LoanInformation.UpfrontPmiMipFfGfFinancedAmount')"
                (ngModelChange)="onUpfrontPmiMipFfGfFinancedAmountChanged()">
              </currency-input>
            </div>
          </div>
          <ng-container *ngIf="isFHA">
            <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.FhaCaseAssigned')">
              <div class="mb-3">
                <label class="form-label" for="fhaCaseAssigned">FHA Case Assigned Date</label>
                <date-input [name]="'fhaCaseAssigned'" [id]="'fhaCaseAssigned'"
                  [disabled]="readonlyFields.includes('LoanInformation.FhaCaseAssigned')"
                  [(ngModel)]="productSearchRequest.loanInformation.fhaCaseAssigned">
                </date-input>
              </div>
            </div>
            <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.FhaCaseEndorsement')">
              <div class="mb-3">
                <label class="form-label" for="fhaCaseEndorsement">FHA Endorsement Date</label>
                <date-input [name]="'fhaCaseEndorsement'" [id]="'fhaCaseEndorsement'"
                  [disabled]="readonlyFields.includes('LoanInformation.FhaCaseEndorsement')"
                  [(ngModel)]="productSearchRequest.loanInformation.fhaCaseEndorsement">
                </date-input>
              </div>
            </div>
          </ng-container>
          <div class="col-md-12">
            <div class="form-check form-check-inline">
              <div class="checkbox">
                <input class="checkbox-input-relative-position" id="financeEntireAmount" [value]="financeEntireAmount"
                  type="checkbox" (change)="onChangeFinanceEntireAmount()" name="financeEntireAmount"
                  [(ngModel)]="financeEntireAmount">
                <label for="financeEntireAmount">
                  Finance Entire Amount
                </label>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="isVALoan">
          <hr class="mt-4">
          <div class="col-md-6" *ngIf="!hiddenFields.includes('LoanInformation.ExemptFromVaFundingFee')">
            <div class="mb-3">
              <label class="form-label" for="exemptFromVaFundingFee"> Exempt from Funding Fee </label>
              <select class="form-select" id="exemptFromVaFundingFee" [name]="'exemptFromVaFundingFee'"
                #exemptFromVaFundingFee="ngModel"
                [(ngModel)]="productSearchRequest.loanInformation.exemptFromVaFundingFee"
                (change)="onExemptFromFundingFeeChanged()" required
                [disabled]="readonlyFields.includes('LoanInformation.ExemptFromVaFundingFee')"
                [ngClass]="{'is-invalid' : exemptFromVaFundingFee && exemptFromVaFundingFee.touched && exemptFromVaFundingFee.invalid}">
                <option [ngValue]="null"> --Select One-- </option>
                <option [ngValue]="true"> Yes </option>
                <option [ngValue]="false"> No </option>
              </select>
              <div class="invalid-feedback">This field is required</div>
            </div>
          </div>
          <div class="col-md-6" *ngIf="!hiddenFields.includes('BorrowerInformation.VaFirstTimeUse')">
            <div class="mb-3">
              <label class="form-label" for="vaFirstTimeUse"> VA First Time Use </label>
              <select class="form-select" id="vaFirstTimeUse" [name]="'vaFirstTimeUse'" #vaFirstTimeUse="ngModel"
                [(ngModel)]="productSearchRequest.borrowerInformation.vaFirstTimeUse"
                (ngModelChange)="onVaFirstTimeUseChanged()"
                [disabled]="readonlyFields.includes('BorrowerInformation.VaFirstTimeUse')"
                [ngClass]="{'is-invalid' : vaFirstTimeUse && vaFirstTimeUse.touched && vaFirstTimeUse.invalid}"
                [required]="true">
                <option [ngValue]="null"> --Select One-- </option>
                <option [ngValue]="true"> Yes </option>
                <option [ngValue]="false"> No </option>
              </select>
              <div class="invalid-feedback">This field is required</div>
            </div>
          </div>
          <div class="col-md-12"
            *ngIf="!visibleFeatureFields.includes('Borrower_DisableVeteranType') && !hiddenFields.includes('BorrowerInformation.TypeOfVeteran')">
            <div class="mb-3">
              <label class="form-label" for="typeOfVeteran"> Va Veteran Type/History </label>
              <select class="form-select" name="typeOfVeteran" id="typeOfVeteran" #typeOfVeteran="ngModel"
                [(ngModel)]="productSearchRequest.borrowerInformation.typeOfVeteran"
                [disabled]="readonlyFields.includes('BorrowerInformation.TypeOfVeteran')"
                [ngClass]="{'is-invalid' : typeOfVeteran && typeOfVeteran.touched && typeOfVeteran.invalid}"
                [required]="true">
                <option [ngValue]="null"> --Select One-- </option>
                <ng-container *ngFor="let option of typeOfVeteranOptions">
                  <option value="{{option.alternateValue}}"
                    *ngIf="!hiddenEnumOptionsByField.get('BorrowerInformation.TypeOfVeteran')?.includes(option.alternateValue)">
                    {{option.name}}
                  </option>
                </ng-container>
              </select>
              <div class="invalid-feedback">This field is required</div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="col-md-12" *ngFor="let field of customFields; let i = index;">
          <div class="mb-3" *ngIf="field.type === 'List'">
            <label class="form-label" for="'{{field.name}}'">{{field.displayName}}</label>
            <select class="form-select" name="'{{field.name}}'" #customFieldSelect="ngModel"
              [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]"
              (ngModelChange)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)"
              [ngClass]="{'is-invalid' : customFieldSelect && customFieldSelect.touched && customFieldSelect.invalid}"
              [required]="field.isRequired" section="custom">
              <option [ngValue]="null">--Select One--</option>
              <option value="{{opt.value}}" *ngFor="let opt of field.options">
                {{opt.description}}
              </option>
            </select>
          </div>
          <div class="mb-3" *ngIf="field.type === 'Text'">
            <label class="form-label" for="'{{field.name}}'">{{field.displayName}}</label>
            <input class="form-control" type="text" class="form-control"
              (change)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)" section="custom"
              [required]="field.isRequired" name="'{{field.name}}'" #customFieldTextInput="ngModel"
              [ngClass]="{'is-invalid' : customFieldTextInput && customFieldTextInput.touched && customFieldTextInput.invalid}"
              [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]">
          </div>
          <div class="mb-3" *ngIf="field.type === 'Number'">
            <label class="form-label" for="'{{field.name}}'">{{field.displayName}}</label>
            <input class="form-control" type="number" class="form-control"
              (change)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)" section="custom"
              [required]="field.isRequired" name="'{{field.name}}'" #customFieldNumberInput="ngModel"
              [ngClass]="{'is-invalid' : customFieldNumberInput && customFieldNumberInput.touched && customFieldNumberInput.invalid}"
              [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]">
          </div>
          <div class="form-check form-check-inline" *ngIf="field.type === 'Checkbox'">
            <div class="checkbox">
              <input type="checkbox" name="'{{field.name}}'" id="'{{field.name}}'"
                [(ngModel)]="customFieldValues[field.thirdPartyCustomFieldId]"
                (change)="onCustomFieldValueChanged(field.thirdPartyCustomFieldId, field.name)">
              <label for="'{{field.name}}'">{{field.displayName}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 g-0 accordion mb-1">
      <div class="accordion-item">
        <h5 class="accordion-header m-0" id="expandedGuidelines">
          <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
            data-bs-target="#guidelines" aria-expanded="true" aria-controls="guidelines">
            Expanded Guidelines
          </button>
        </h5>
        <div id="guidelines" class="accordion-collapse collapse" aria-labelledby="expandedGuidelines">
          <div class="accordion-body">
            <div class="row">
              <div class="mb-3 col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-3 ms-n2' : ''}}">
                <div class="row">
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_BankruptcyType') && !hiddenFields.includes('ExpandedGuidelines.BankruptcyType')">
                    <div class="mb-3">
                      <label class="form-label" for="bankruptcyType">Bankruptcy Type</label>
                      <select class="form-select" id="bankruptcyType" [name]="'bankruptcyType'"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.bankruptcyType"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.BankruptcyType')"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of bankruptcyType">
                          <option value="{{option.alternateValue}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.BankruptcyType')?.includes(option.alternateValue)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_MonthsBankruptcy') && productSearchRequest.loanInformation.expandedGuidelines.bankruptcyType != 'None' && !hiddenFields.includes('ExpandedGuidelines.BankruptcyMonths')">
                    <div class="mb-3">
                      <label class="form-label" for="bankruptcyMonths">Bankruptcy(mon)</label>
                      <input class="form-control numeric-without-arrows" name="bankruptcyMonths"
                        section="expanded-guidelines" type="number"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.BankruptcyMonths')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.bankruptcyMonths" />
                    </div>
                  </div>
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_BankruptcyOutcome') && productSearchRequest.loanInformation.expandedGuidelines.bankruptcyType != 'None' && !hiddenFields.includes('ExpandedGuidelines.BankruptcyOutcome')">
                    <div class="mb-3">
                      <label class="form-label" for="bankruptcyOutcome">Bankruptcy Outcome</label>
                      <select class="form-select" id="bankruptcyOutcome" [name]="'bankruptcyOutcome'"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.BankruptcyOutcome')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.bankruptcyOutcome"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of bankruptcyOutcome">
                          <option value="{{option.alternateValue}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.BankruptcyOutcome')?.includes(option.alternateValue)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_BankruptcySeasoning') && productSearchRequest.loanInformation.expandedGuidelines.bankruptcyType != 'None' && !hiddenFields.includes('ExpandedGuidelines.BankruptcySeasoning')">
                    <div class="mb-3">
                      <label class="form-label" for="bankruptcySeasoning">Bankruptcy Seasoning</label>
                      <select class="form-select" id="bankruptcySeasoning" [name]="'bankruptcySeasoning'"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.bankruptcySeasoning"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.BankruptcySeasoning')"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of bankruptcySeasoning">
                          <option value="{{option.alternateValue}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.BankruptcySeasoning')?.includes(option.alternateValue)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_HousingEventType') && !hiddenFields.includes('ExpandedGuidelines.HousingEventType')">
                    <div class="mb-3">
                      <label class="form-label" for="housingEventType">Housing Event Type</label>
                      <select class="form-select" id="housingEventType" [name]="'housingEventType'"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.housingEventType"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.HousingEventType')"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of housingEventType">
                          <option value="{{option.alternateValue}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.HousingEventType')?.includes(option.alternateValue)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_HousingEventSeasoning') && productSearchRequest.loanInformation.expandedGuidelines.housingEventType != 'NotApplicable' && !hiddenFields.includes('ExpandedGuidelines.HousingEventSeasoning')">
                    <div class="mb-3">
                      <label class="form-label" for="housingEventSeasoning">Housing Event Seasoning</label>
                      <select class="form-select" id="housingEventSeasoning" [name]="'housingEventSeasoning'"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.housingEventSeasoning"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.HousingEventSeasoning')"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of housingEventSeasoning">
                          <option value="{{option.alternateValue}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.HousingEventSeasoning')?.includes(option.alternateValue)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row"
                  *ngIf="visibleFeatureFields.includes('Expanded_IncomeVerificationMethod') && !hiddenFields.includes('ExpandedGuidelines.IncomeVerificationMethod')">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label" for="incomeVerificationMethod">Income Verification Method</label>
                      <select class="form-select" id="incomeVerificationMethod" [name]="'incomeVerificationMethod'"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.IncomeVerificationMethod')"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of incomeVerificationMethod">
                          <option value="{{option.alternateValue}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.IncomeVerificationMethod')?.includes(option.alternateValue)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row"
                  *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'BankStatement'">
                  <div class="col-md-12"
                    *ngIf="visibleFeatureFields.includes('Expanded_BankStatementExpenseMethod') && !hiddenFields.includes('ExpandedGuidelines.BankStatementExpenseMethod')">
                    <div class="mb-3">
                      <label class="form-label" for="bankStatementExpenseMethod">Bank Stmt. Expense Method</label>
                      <select class="form-select" id="bankStatementExpenseMethod" [name]="'bankStatementExpenseMethod'"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.bankStatementExpenseMethod"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.BankStatementExpenseMethod')"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of bankStatementExpenseMethod">
                          <option value="{{option.alternateValue}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.BankStatementExpenseMethod')?.includes(option.alternateValue)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_MonthsPersonalBankStatements') && !hiddenFields.includes('ExpandedGuidelines.MonthsPersonalBankStatements')">
                    <div class="mb-3">
                      <label class="form-label" for="mo">Months Personal Bank Statement</label>
                      <select class="form-select" id="monthsPersonalBankStatements"
                        [name]="'monthsPersonalBankStatements'"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.MonthsPersonalBankStatements')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.monthsPersonalBankStatements"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of months1224">
                          <option value="{{option.value}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.MonthsPersonalBankStatements')?.includes(option.value)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_MonthsBusinessBankStatements') && !hiddenFields.includes('ExpandedGuidelines.MonthsBusinessBankStatements')">
                    <div class="mb-3">
                      <label class="form-label" for="mo">Months Business Bank Statement</label>
                      <select class="form-select" id="monthsBusinessBankStatements"
                        [name]="'monthsBusinessBankStatements'"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.MonthsBusinessBankStatements')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.monthsBusinessBankStatements"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of months1224">
                          <option value="{{option.value}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.MonthsBusinessBankStatements')?.includes(option.value)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row"
                  *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'VOE'">
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_VOEAmount') && !hiddenFields.includes('ExpandedGuidelines.VoeAmount')">
                    <div class="mb-3">
                      <label class="form-label" for="voeAmount">VOE Amount</label>
                      <input class="form-control numeric-without-arrows" name="voeAmount" section="expanded-guidelines"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.VoeAmount')" type="number"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.voeAmount" />
                    </div>
                  </div>
                </div>
                <div class="row"
                  *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'AssetQualification'">
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_AssetQualificationAmount') && !hiddenFields.includes('ExpandedGuidelines.AssetAmount')">
                    <div class="mb-3">
                      <label class="form-label" for="assetAmount">Asset Amount</label>
                      <input class="form-control numeric-without-arrows" name="assetAmount"
                        section="expanded-guidelines" type="number"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.AssetAmount')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.assetAmount" />
                    </div>
                  </div>
                </div>
                <div class="row"
                  *ngIf="visibleFeatureFields.includes('Expanded_IncomeVerificationType') && !hiddenFields.includes('ExpandedGuidelines.IncomeVerificationType')">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label" for="incomeVerificationType">Income Verification Type</label>
                      <select class="form-select" id="incomeVerificationType" [name]="'incomeVerificationType'"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationType"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.IncomeVerificationType')"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of incomeVerificationType">
                          <option value="{{option.alternateValue}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.IncomeVerificationType')?.includes(option.alternateValue)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row"
                  *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'Method1099'">
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_Months1099') && !hiddenFields.includes('ExpandedGuidelines.Months1099')">
                    <div class="mb-3">
                      <label class="form-label" for="months1099">1099 Months</label>
                      <select class="form-select" id="months1099" [name]="'months1099'"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.months1099"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.Months1099')"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of months1224">
                          <option value="{{option.value}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.Months1099')?.includes(option.value)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="(productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationType == 'InvestorDscr'
                  || productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'DSCR')">
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_DebtServiceCoverageRatio') && !hiddenFields.includes('ExpandedGuidelines.DebtServiceCoverageRatio')">
                    <div class="mb-3">
                      <label class="form-label" for="debtServiceCoverageRatio">DSCR %</label>
                      <percent-input name="debtServiceCoverageRatio"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.DebtServiceCoverageRatio')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.debtServiceCoverageRatio"
                        [rateIsTrueToTextValue]="true" #debtServiceCoverageRatio="ngModel">
                      </percent-input>
                    </div>
                  </div>
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_InvestorExperience') && !hiddenFields.includes('ExpandedGuidelines.InvestorExperience')">
                    <div class="mb-3">
                      <label class="form-label" for="investorExperience">Investor Experience</label>
                      <select class="form-select" id="investorExperience" [name]="'investorExperience'"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.investorExperience"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.InvestorExperience')"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of investorExperience">
                          <option value="{{option.alternateValue}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.InvestorExperience')?.includes(option.alternateValue)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row"
                  *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'CPAPAndL'">
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_MonthsCpaPandL') && !hiddenFields.includes('ExpandedGuidelines.MonthsCpaPandL')">
                    <div class="mb-3">
                      <label class="form-label" for="monthsCpaPandL">CPA P&L Months</label>
                      <select class="form-select" id="monthsCpaPandL" [name]="'monthsCpaPandL'"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.MonthsCpaPandL')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.monthsCpaPandL"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of months1224">
                          <option value="{{option.value}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.MonthsCpaPandL')?.includes(option.value)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row"
                  *ngIf="productSearchRequest.loanInformation.expandedGuidelines.incomeVerificationMethod == 'FullDocument'">
                  <div class="col-md-6"
                    *ngIf="visibleFeatureFields.includes('Expanded_MonthsFullDocMethod') && !hiddenFields.includes('ExpandedGuidelines.MonthsFullDoc')">
                    <div class="mb-3">
                      <label class="form-label" for="monthsFullDoc">Full Doc (mon)</label>
                      <select class="form-select" id="monthsFullDoc" [name]="'monthsFullDoc'"
                        [disabled]="readonlyFields.includes('ExpandedGuidelines.MonthsFullDoc')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.monthsFullDoc"
                        section="expanded-guidelines">
                        <ng-container *ngFor="let option of months1224">
                          <option value="{{option.value}}"
                            *ngIf="!hiddenEnumOptionsByField.get('ExpandedGuidelines.MonthsFullDoc')?.includes(option.value)">
                            {{option.name}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-3 ms-n2' : ''}}">
                <div class="row">
                  <div class="col-md-6" *ngIf="visibleFeatureFields.includes('Expanded_MonthsChargeOff') && !hiddenFields.includes('ExpandedGuidelines.ChargeOffMonths')">
                    <div class="mb-3">
                      <label class="form-label" for="chargeOffMonths">Charge Off (mon)</label>
                      <input class="form-control numeric-without-arrows" name="chargeOffMonths"
                        section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.ChargeOffMonths')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.chargeOffMonths" />
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="visibleFeatureFields.includes('Expanded_MonthsDeedInLieu') && !hiddenFields.includes('ExpandedGuidelines.DeedinLieuMonths')">
                    <div class="mb-3">
                      <label class="form-label" for="deedinLieuMonths">Deed in Lieu (mon)</label>
                      <input class="form-control numeric-without-arrows" name="deedinLieuMonths"
                        section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.DeedinLieuMonths')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.deedinLieuMonths" />
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="visibleFeatureFields.includes('Expanded_MonthsNoticeOfDefault') && !hiddenFields.includes('ExpandedGuidelines.DefaultNoticeMonths')">
                    <div class="mb-3">
                      <label class="form-label" for="defaultNoticeMonths">Default Notice (mon)</label>
                      <input class="form-control numeric-without-arrows" name="defaultNoticeMonths"
                        section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.DefaultNoticeMonths')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.defaultNoticeMonths" />
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="visibleFeatureFields.includes('Expanded_MonthsForeclosure') && !hiddenFields.includes('ExpandedGuidelines.ForeclosureMonths')">
                    <div class="mb-3">
                      <label class="form-label" for="foreclosureMonths">Foreclosure (mon)</label>
                      <input class="form-control numeric-without-arrows" name="foreclosureMonths"
                        section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.ForeclosureMonths')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.foreclosureMonths" />
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="visibleFeatureFields.includes('Expanded_MonthsLoanModification') && !hiddenFields.includes('ExpandedGuidelines.LoanModificationMonths')">
                    <div class="mb-3">
                      <label class="form-label" for="loanModificationMonths">Loan Modification (mon)</label>
                      <input class="form-control numeric-without-arrows" name="loanModificationMonths"
                        section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.LoanModificationMonths')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.loanModificationMonths" />
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="visibleFeatureFields.includes('Expanded_MonthsShortSale') && !hiddenFields.includes('ExpandedGuidelines.ShortSaleMonths')">
                    <div class="mb-3">
                      <label class="form-label" for="shortSaleMonths">Short Sale (mon)</label>
                      <input class="form-control numeric-without-arrows" name="shortSaleMonths"
                        section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.ShortSaleMonths')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.shortSaleMonths" />
                    </div>
                  </div>

                  <div class="col-md-6" *ngIf="visibleFeatureFields.includes('Expanded_AssetDepletionAmount') && !hiddenFields.includes('ExpandedGuidelines.AssetDepletionAmount')">
                    <div class="mb-3">
                      <label class="form-label" for="assetDepletionAmount">Asset Depletion Amount</label>
                      <input class="form-control numeric-without-arrows" name="assetDepletionAmount"
                        section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.AssetDepletionAmount')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.assetDepletionAmount" />
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="visibleFeatureFields.includes('Expanded_PropertiesOwned') && !hiddenFields.includes('ExpandedGuidelines.PropertiesOwned')">
                    <div class="mb-3">
                      <label class="form-label" for="propertiesOwned">Properties Owned</label>
                      <input class="form-control numeric-without-arrows" name="propertiesOwned"
                        section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.PropertiesOwned')"
                        [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.propertiesOwned" />
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="visibleFeatureFields.includes('Expanded_DebtConsolidation') && !hiddenFields.includes('ExpandedGuidelines.DebtConsolidation')">
                  <div class="col-md-12">
                    <div class="form-check form-check-inline">
                      <div class="checkbox">
                        <input name="debtConsolidation" id="debtConsolidation"
                          [value]="productSearchRequest.loanInformation.expandedGuidelines.debtConsolidation"
                          type="checkbox" class="checkbox-input-relative-position" [disabled]="readonlyFields.includes('ExpandedGuidelines.DebtConsolidation')"
                          [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.debtConsolidation">
                        <label for="debtConsolidation">
                          Debt Consolidation
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="visibleFeatureFields.includes('Expanded_UniqueProperty') && !hiddenFields.includes('ExpandedGuidelines.UniqueProperty')">
                  <div class="col-md-12">
                    <div class="form-check form-check-inline">
                      <div class="checkbox">
                        <input name="uniqueProperty" id="uniqueProperty"
                          [value]="productSearchRequest.loanInformation.expandedGuidelines.uniqueProperty"
                          type="checkbox" class="checkbox-input-relative-position" [disabled]="readonlyFields.includes('ExpandedGuidelines.UniqueProperty')"
                          [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.uniqueProperty">
                        <label for="uniqueProperty">
                          Unique Property
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-xs-12 {{showMultipleColumns ? 'col-md-4 border p-3 ms-n2' : ''}}">
                <div class="border p-2 position-relative"
                  *ngIf="visibleFeatureFields.includes('Expanded_MortgageLates')">
                  <span class="group-label"
                    style="background-color: #fff; padding: 3px; margin-top: -22px; position: absolute;"> Mtg Lates
                    (0-12 months)
                  </span>
                  <div class="row">
                    <div class="col-md-6" *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx3012Mos')">
                      <div class="mb-3">
                        <label class="form-label" for="ml30">Mtg Lates x 30</label>
                        <input class="form-control numeric-without-arrows" name="ml30" id="ml30"
                          section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx3012Mos')"
                          [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx3012Mos" />
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx6012Mos')">
                      <div class="mb-3">
                        <label class="form-label" for="ml60">Mtg Lates x 60</label>
                        <input class="form-control numeric-without-arrows" name="ml60" id="ml60"
                          section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx6012Mos')"
                          [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx6012Mos" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6" *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx9012Mos')">
                      <div class="mb-3">
                        <label class="form-label" for="ml90">Mtg Lates x 90</label>
                        <input class="form-control numeric-without-arrows" name="ml90" id="ml90"
                          section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx9012Mos')"
                          [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx9012Mos" />
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx12012Mos')">
                      <div class="mb-3">
                        <label class="form-label" for="ml120">Mtg Lates x 120</label>
                        <input class="form-control numeric-without-arrows" name="ml120" id="ml120"
                          section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx12012Mos')"
                          [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx12012Mos" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="border p-2 position-relative" style="margin-top:8px; margin-bottom: 8px;"
                  *ngIf="visibleFeatureFields.includes('Expanded_MortgageLates')">
                  <span class="group-label"
                    style="background-color: #fff; padding: 3px; margin-top: -22px; position: absolute;"> Mtg Lates
                    (13-24 months)
                  </span>
                  <div class="row">
                    <div class="col-md-6" *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx3013To24Mos')">
                      <div class="mb-3">
                        <label class="form-label" for="ml30-1324">Mtg Lates x 30</label>
                        <input class="form-control numeric-without-arrows" name="ml30-1324" id="ml30-1324"
                          section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx3013To24Mos')"
                          [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx3013To24Mos" />
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx6013To24Mos')">
                      <div class="mb-3">
                        <label class="form-label" for="ml60-1324">Mtg Lates x 60</label>
                        <input class="form-control numeric-without-arrows" name="ml60-1324" id="ml60-1324"
                          section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx6013To24Mos')"
                          [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx6013To24Mos" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6" *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx9013To24Mos')">
                      <div class="mb-3">
                        <label class="form-label" for="ml90-1324">Mtg Lates x 90</label>
                        <input class="form-control numeric-without-arrows" name="ml90-1324" id="ml90-1324"
                          section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx9013To24Mos')"
                          [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx9013To24Mos" />
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="!hiddenFields.includes('ExpandedGuidelines.MortgageLatesx12013To24Mos')">
                      <div class="mb-3">
                        <label class="form-label" for="ml120-1324">Mtg Lates x 120</label>
                        <input class="form-control numeric-without-arrows" name="ml120-1324" id="ml120-1324"
                          section="expanded-guidelines" type="number" [disabled]="readonlyFields.includes('ExpandedGuidelines.MortgageLatesx12013To24Mos')"
                          [(ngModel)]="productSearchRequest.loanInformation.expandedGuidelines.mortgageLatesx12013To24Mos" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
