import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PayPeriod } from '../../../modules/leads/models/lead-employment.model';
import { EnumerationItem } from '../../../models/simple-enum-item.model';
import { EnumerationService } from '../../../services/enumeration-service';
import {
  MortgageCalculatedStats,
  UrlaMortgage,
} from '../../../modules/urla/models/urla-mortgage.model';
import { HousingExpense } from '../../../models';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { skip, Subscription } from 'rxjs';
import { LeadUtils } from '../../../modules/leads/services/utils';
import { MortgageCalculationService } from '../../../modules/urla/services/mortgage-calculation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  SubjectPropertyAndProposedHousingExpenses,
  SupplementalPropertyInsuranceBreakdownDialogComponent,
} from '../../../modules/urla/mortgage-loan-info/supplemental-property-insurance-breakdown-dialog/supplemental-property-insurance-breakdown-dialog.component';
import { Constants } from '../../../services/constants';
import { cloneDeep } from 'lodash';
import { PropertyTaxBreakdownDialogComponent } from '../../../modules/urla/mortgage-loan-info/property-tax-breakdown-dialog/property-tax-breakdown-dialog.component';
import { MortgageInsuranceDialogComponent } from '../../../modules/urla/mortgage-insurance/mortgage-insurance-dialog/mortgage-insurance-dialog.component';
import { HoaDuesCondoFeesBreakdownDialogComponent } from '../../../modules/urla/mortgage-loan-info/hoa-dues-condo-fees-breakdpwn-dialog/hoa-dues-condo-fees-breakdown-dialog.component';
import { handleNonErrorDismissals } from '../../../core/services/utils';
import { distinctUntilChanged } from 'rxjs/operators';
import calculateMonthlyAmountByPayPeriod = LeadUtils.calculateMonthlyAmountByPayPeriod;
import calculateSelectedPayPeriodAmountByMonthlyAmount = LeadUtils.calculateSelectedPayPeriodAmountByMonthlyAmount;

type KeyOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

const propertyTaxesBaseName = 'Property Taxes';

@Component({
  selector: 'proposed-monthly-payment',
  templateUrl: './proposed-monthly-payment.component.html',
  styleUrls: ['./proposed-monthly-payment.component.scss'],
})
export class ProposedMonthlyPaymentComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input()
  mortgage: UrlaMortgage;

  @Input()
  editable: boolean = true;

  /**
   * Emits when there is a change in the {@link ProposedMonthlyPaymentComponent#mortgage} that
   * should be saved.
   */
  @Output()
  readonly save = new EventEmitter<void>();

  protected form: FormType;
  protected payPeriodOptions: EnumerationItem<PayPeriod>[] = [];

  private readonly _disabledControls: Set<AbstractControl> = new Set();
  private readonly _focusValueByControl = new Map<AbstractControl, any>();

  private _formSubscription?: Subscription;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _enumerationService: EnumerationService,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _modalService: NgbModal
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('mortgage' in changes) {
      this.resetForm();
    } else if ('editable' in changes && this.form != null) {
      this.resetEditability();
    }
  }

  ngOnInit(): void {
    this.payPeriodOptions = this._enumerationService.getIncomePayPeriods();

    if (this.form == null) {
      this.resetForm();
    }
  }

  ngOnDestroy() {
    this._formSubscription?.unsubscribe();
  }

  protected saveFocusValue(control: AbstractControl): void {
    this._focusValueByControl.set(control, control.value);
  }

  protected emitSaveIfFocusValueChanged(control: AbstractControl): void {
    const previousValue = this._focusValueByControl.get(control);
    const value = control.value;
    if ((previousValue != null || value != null) && previousValue !== value) {
      this.save.emit();
      this._focusValueByControl.clear();
    }
  }

  private subscribeToPayPeriodChangesOf(control: FormPaymentType) {
    return (onChange: (value: OnChangePayment) => void) => {
      return control
        .get('factor.payPeriod')
        .valueChanges.pipe(distinctUntilChanged())
        .subscribe((payPeriod) => {
          onChange({ payPeriod });
          this.save.emit();
        });
    };
  }

  private resetForm(): void {
    const mortgage = this.getOrCreateMortgage();
    const calculatedStats = mortgage.calculatedStats;
    const expense = mortgage.proposedHousingExpense;

    this._formSubscription?.unsubscribe();
    this._formSubscription = new Subscription();
    const fb = this._formBuilder;

    this.form = fb.group({
      payments: fb.array<FormPaymentType>([]),
      total: this.createTotalFormControl(calculatedStats),
    });

    // Change listeners of the payment controls depend on the form's existence and its `total`
    // control. So, we need to create the form first before we can set the payment controls to
    // avoid possible race conditions.
    const payments = fb.array([
      this.createFirstMortgageFormGroup(expense),
      this.createOtherFinancingFormGroup(expense),
      this.createHoiFormGroup(expense),
      this.createSupplementalInsuranceFormGroup(expense),
      this.createPropertyTaxesFormGroup(expense),
      this.createMortgageInsuranceFormGroup(expense),
      this.createAssociationDuesFormGroup(expense),
      this.createOtherHousingExpenseFormGroup(expense),
    ]);
    this.form.setControl('payments', payments, { emitEvent: false });

    this.resetDisabledControlsSet();
    this.resetEditability();
  }

  private resetEditability(): void {
    if (this.editable) {
      this.enableFormControls();
    } else {
      this.disableFormControls();
    }
  }

  private resetDisabledControlsSet(): void {
    this._disabledControls.clear();

    for (const control of iterControls(this.form)) {
      if (control.disabled) {
        this._disabledControls.add(control);
      }
    }
  }

  private enableFormControls(): void {
    for (const control of iterControls(this.form)) {
      if (!this._disabledControls.has(control)) {
        control.enable({ emitEvent: false });
      }
    }
    this._disabledControls.clear();
  }

  private disableFormControls(): void {
    for (const control of iterControls(this.form)) {
      if (control.disabled) {
        this._disabledControls.add(control);
      }
      control.disable({ emitEvent: false });
    }
  }

  private getOrCreateMortgage(): UrlaMortgage {
    const mortgage = this.mortgage || new UrlaMortgage();

    if (!mortgage.calculatedStats) {
      mortgage.calculatedStats = new MortgageCalculatedStats();
    }

    if (!mortgage.proposedHousingExpense) {
      mortgage.proposedHousingExpense = new HousingExpense();
    }

    return mortgage;
  }

  private resetTotal(): void {
    const mortgage = this.mortgage;
    const calculatedStats = mortgage.calculatedStats;
    const housingExpense = mortgage.proposedHousingExpense;

    calculatedStats.proposedMonthlyPaymentTotal =
      this._calculationService.calculateHousingExpenseTotal(housingExpense);

    this.form
      .get('total')
      .setValue(calculatedStats.proposedMonthlyPaymentTotal);
  }

  private resetMonthlyMiscExpenses(): void {
    const realEstateOwned = this.mortgage.realEstateOwned;
    const subjectProperty = realEstateOwned?.find(
      (property) => property.isSubjectProperty
    );
    if (subjectProperty == null) {
      return;
    }

    subjectProperty.monthlyMiscExpenses = this.calculateMonthlyMiscExpenses();
  }

  private calculateMonthlyMiscExpenses() {
    const expense = this.mortgage.proposedHousingExpense;
    if (expense == null) {
      return 0;
    }

    return sum(
      expense.homeownersInsurance,
      expense.supplementalPropertyInsurance,
      expense.realEstateTax,
      expense.mortgageInsurance,
      expense.homeownersAssociationDuesAndCondominiumFees
    );
  }

  private isExpenseGreaterThanZero = (
    key: KeyOfType<HousingExpense, Number>
  ): boolean => safeNumber(this.mortgage.proposedHousingExpense?.[key]) > 0;

  private createTotalFormControl(
    calculatedStats: MortgageCalculatedStats
  ): FormControl<number> {
    const control = this._formBuilder.control(
      calculatedStats?.proposedMonthlyPaymentTotal || 0
    );

    const valueChanges = control.valueChanges.pipe(skip(1));

    this._formSubscription.add(
      valueChanges.subscribe(() => {
        calculatedStats.proposedMonthlyPaymentTotal = control.value;
      })
    );

    return control;
  }

  private createFirstMortgageFormGroup(
    expense: HousingExpense
  ): FormPaymentType {
    const fb = this._formBuilder;

    return fb.group({
      name: fb.control('First Mortgage'),
      monthly: fb.control(expense.firstMortgagePrincipalAndInterest),
    });
  }

  private createOtherFinancingFormGroup(
    expense: HousingExpense
  ): FormPaymentType {
    const fb = this._formBuilder;

    const control = fb.group({
      name: fb.control('Other Financing (P&I)'),
      factor: fb.group({
        payPeriod: fb.control(PayPeriod.Monthly),
      }),
      value: fb.control(expense.otherMortgageLoanPrincipalAndInterest),
      monthly: fb.control(expense.otherMortgageLoanPrincipalAndInterest),
    }) as FormPaymentType;

    const onChange = ({ value, payPeriod }: OnChangePayment) => {
      expense.otherMortgageLoanPrincipalAndInterest =
        calculateMonthlyAmountByPayPeriod(
          value ?? control.get('value').value,
          payPeriod ?? control.get('factor.payPeriod').value
        );

      control
        .get('monthly')
        .setValue(expense.otherMortgageLoanPrincipalAndInterest);

      this.resetTotal();
    };

    this._formSubscription.add(
      this.subscribeToPayPeriodChangesOf(control)(onChange)
    );

    this._formSubscription.add(
      control.get('value').valueChanges.subscribe((value) => {
        onChange({ value });
      })
    );

    return control;
  }

  private createHoiFormGroup(expense: HousingExpense): FormPaymentType {
    const fb = this._formBuilder;

    const control = fb.group({
      name: fb.control('Homeowners Insurance'),
      factor: fb.group({
        payPeriod: fb.control(PayPeriod.Monthly),
      }),
      value: fb.control(expense.homeownersInsurance),
      monthly: fb.control(expense.homeownersInsurance),
    }) as FormPaymentType;

    const onChange = ({ value, payPeriod }: OnChangePayment) => {
      expense.homeownersInsurance = calculateMonthlyAmountByPayPeriod(
        value ?? control.get('value').value,
        payPeriod ?? control.get('factor.payPeriod').value
      );

      control.get('monthly').setValue(expense.homeownersInsurance);

      this.resetMonthlyMiscExpenses();
      this.resetTotal();
    };

    this._formSubscription.add(
      this.subscribeToPayPeriodChangesOf(control)(onChange)
    );

    this._formSubscription.add(
      control.get('value').valueChanges.subscribe((value) => {
        onChange({ value });
      })
    );

    return control;
  }

  private createSupplementalInsuranceFormGroup(
    expense: HousingExpense
  ): FormPaymentType {
    const fb = this._formBuilder;

    const control = fb.group({
      name: fb.control('Supplemental'),
      factor: fb.group({
        payPeriod: fb.control(PayPeriod.Monthly),
        onCalculate: fb.control(() => {
          this.openSupplementalInsuranceBreakdownModal(control);
        }),
      }),
      value: fb.control(expense.supplementalPropertyInsurance),
      monthly: fb.control(expense.supplementalPropertyInsurance),
    }) as FormPaymentType;

    const onChange = ({ value, payPeriod, preCalculate }: OnChangePayment) => {
      payPeriod ??= control.get('factor.payPeriod').value;
      expense.supplementalPropertyInsurance = calculateMonthlyAmountByPayPeriod(
        value ?? control.get('value').value,
        payPeriod
      );

      control.get('monthly').setValue(expense.supplementalPropertyInsurance);

      preCalculate?.();
      this.calculateSupplementalInsurancePayment(payPeriod);
      this.resetMonthlyMiscExpenses();
      this.resetTotal();
    };

    this._formSubscription.add(
      this.subscribeToPayPeriodChangesOf(control)(onChange)
    );

    this._formSubscription.add(
      control.get('value').valueChanges.subscribe((value) => {
        onChange({
          value,
          preCalculate: () => {
            this.calculateSupplementalInsuranceNonBreakdownPayment(value);
          },
        });
      })
    );

    if (this.isSupplementalInsuranceBrokenDown()) {
      control.get('value').disable({ emitEvent: false });
    }

    return control;
  }

  private openSupplementalInsuranceBreakdownModal(
    control: FormPaymentType
  ): void {
    const mortgage = this.mortgage;

    const modal = this._modalService.open(
      SupplementalPropertyInsuranceBreakdownDialogComponent,
      Constants.modalOptions.xlarge
    );
    const instance =
      modal.componentInstance as SupplementalPropertyInsuranceBreakdownDialogComponent;
    instance.mortgage = mortgage;

    modal.result
      .then((expenses: SubjectPropertyAndProposedHousingExpenses) => {
        if (!expenses) {
          return;
        }

        mortgage.proposedHousingExpense = cloneDeep(
          expenses.proposedHousingExpense
        );
        mortgage.subjectProperty = cloneDeep(expenses.subjectProperty);

        const payPeriod = control.get('factor.payPeriod').value;
        const value = calculateSelectedPayPeriodAmountByMonthlyAmount(
          mortgage.proposedHousingExpense.supplementalPropertyInsurance,
          payPeriod
        );
        const valueControl = control.get('value');
        valueControl.setValue(value, {
          emitEvent: !this.isSupplementalInsuranceBrokenDown(),
        });

        if (this.isSupplementalInsuranceBrokenDown()) {
          valueControl.disable({ emitEvent: false });
        } else {
          valueControl.enable({ emitEvent: false });
        }

        this.resetMonthlyMiscExpenses();
        this.resetTotal();

        this.save.emit();
      })
      .catch(handleNonErrorDismissals);
  }

  private isSupplementalInsuranceBrokenDown(): boolean {
    if (!this.mortgage) {
      return;
    }
    const expense = this.mortgage.proposedHousingExpense;
    if (this.isExpenseGreaterThanZero('otherSupplementalPropertyInsurance')) {
      if (
        expense.otherSupplementalPropertyInsurance !==
        expense.supplementalPropertyInsurance
      ) {
        return true;
      }
    }

    return [
      'earthquakeInsurance',
      'floodInsurance',
      'volcanoInsurance',
      'hailInsurance',
      'windAndStormInsurance',
    ].some(this.isExpenseGreaterThanZero);
  }

  private calculateSupplementalInsuranceNonBreakdownPayment(
    amount: number
  ): void {
    const expense = this.mortgage.proposedHousingExpense;

    expense.supplementalPropertyInsurance = amount;
    expense.otherSupplementalPropertyInsurance = amount;

    expense.earthquakeInsurance = 0;
    expense.floodInsurance = 0;
    expense.volcanoInsurance = 0;
    expense.hailInsurance = 0;
    expense.windAndStormInsurance = 0;
  }

  private calculateSupplementalInsurancePayment(payPeriod: PayPeriod): void {
    const expense = this.mortgage.proposedHousingExpense;

    const calculate = createExpenseMonthlyAmountCalculator(expense, payPeriod);

    [
      'earthquakeInsurance',
      'otherSupplementalPropertyInsurance',
      'floodInsurance',
      'volcanoInsurance',
      'hailInsurance',
      'windAndStormInsurance',
      'supplementalPropertyInsurance',
    ].forEach(calculate);
  }

  private createPropertyTaxesFormGroup(
    expense: HousingExpense
  ): FormPaymentType {
    const fb = this._formBuilder;

    const control = fb.group({
      name: fb.control(this.calculatePropertyTaxesName()),
      factor: fb.group({
        payPeriod: fb.control(PayPeriod.Monthly),
        onCalculate: fb.control(() => {
          this.openPropertyTaxesBreakdownModal(control);
        }),
      }),
      value: fb.control(expense.realEstateTax),
      monthly: fb.control(expense.realEstateTax),
    }) as FormPaymentType;

    const onChange = ({ value, payPeriod, preCalculate }: OnChangePayment) => {
      payPeriod ??= control.get('factor.payPeriod').value;

      control
        .get('monthly')
        .setValue(
          calculateMonthlyAmountByPayPeriod(
            value ?? control.get('value').value,
            payPeriod
          )
        );

      preCalculate?.();
      this.setPropertyTaxesName(control);

      this.calculatePropertyTaxesPayment(payPeriod);
      this.resetMonthlyMiscExpenses();
      this.resetTotal();
    };

    this._formSubscription.add(
      this.subscribeToPayPeriodChangesOf(control)(onChange)
    );

    this._formSubscription.add(
      control.get('value').valueChanges.subscribe((value) => {
        onChange({
          value,
          preCalculate: () => {
            this.calculatePropertyTaxesNonBreakdownPayment(value);
          },
        });
      })
    );

    if (this.isPropertyTaxesBrokenDown()) {
      control.get('value').disable({ emitEvent: false });
    }

    return control;
  }

  private openPropertyTaxesBreakdownModal(control: FormPaymentType): void {
    const mortgage = this.mortgage;

    const modal = this._modalService.open(
      PropertyTaxBreakdownDialogComponent,
      Constants.modalOptions.large
    );
    const instance =
      modal.componentInstance as PropertyTaxBreakdownDialogComponent;
    instance.mortgage = mortgage;

    modal.result
      .then((expenses: SubjectPropertyAndProposedHousingExpenses) => {
        if (!expenses) {
          return;
        }

        mortgage.proposedHousingExpense = cloneDeep(
          expenses.proposedHousingExpense
        );
        mortgage.subjectProperty = cloneDeep(expenses.subjectProperty);

        const payPeriod = control.get('factor.payPeriod').value;
        const value = calculateSelectedPayPeriodAmountByMonthlyAmount(
          mortgage.proposedHousingExpense.realEstateTax,
          payPeriod
        );
        const valueControl = control.get('value');
        const isBrokenDown = this.isPropertyTaxesBrokenDown();
        valueControl.setValue(value, { emitEvent: !isBrokenDown });

        const monthlyControl = control.get('monthly');
        monthlyControl.setValue(value);

        if (isBrokenDown) {
          valueControl.disable({ emitEvent: false });
        } else {
          valueControl.enable({ emitEvent: false });
        }

        this.setPropertyTaxesName(control, isBrokenDown);

        this.resetMonthlyMiscExpenses();
        this.resetTotal();

        this.save.emit();
      })
      .catch(handleNonErrorDismissals);
  }

  private calculatePropertyTaxesNonBreakdownPayment(amount: number): void {
    const expense = this.mortgage.proposedHousingExpense;

    expense.realEstateTax = amount;
    expense.countyPropertyTax = amount;

    expense.boroughPropertyTax = 0;
    expense.cityPropertyTax = 0;
    expense.districtPropertyTax = 0;
    expense.schoolPropertyTax = 0;
    expense.statePropertyTax = 0;
    expense.townPropertyTax = 0;
    expense.villagePropertyTax = 0;
  }

  private calculatePropertyTaxesPayment(payPeriod: PayPeriod): void {
    const expense = this.mortgage.proposedHousingExpense;

    const calculate = createExpenseMonthlyAmountCalculator(expense, payPeriod);

    [
      'statePropertyTax',
      'countyPropertyTax',
      'districtPropertyTax',
      'boroughPropertyTax',
      'cityPropertyTax',
      'townPropertyTax',
      'villagePropertyTax',
      'schoolPropertyTax',
      'realEstateTax',
    ].forEach(calculate);
  }

  private isPropertyTaxesBrokenDown(): boolean {
    if (!this.mortgage) {
      return;
    }
    const expense = this.mortgage.proposedHousingExpense;
    if (this.isExpenseGreaterThanZero('countyPropertyTax')) {
      if (expense.countyPropertyTax !== expense.realEstateTax) {
        return true;
      }
    }

    return [
      'statePropertyTax',
      'districtPropertyTax',
      'boroughPropertyTax',
      'cityPropertyTax',
      'townPropertyTax',
      'villagePropertyTax',
      'schoolPropertyTax',
    ].some(this.isExpenseGreaterThanZero);
  }

  private calculatePropertyTaxesName(isBrokenDown?: boolean): string {
    return !(isBrokenDown ?? this.isPropertyTaxesBrokenDown()) &&
      this.mortgage?.proposedHousingExpense.countyPropertyTax > 0
      ? propertyTaxesBaseName + ' (County)'
      : propertyTaxesBaseName;
  }

  private setPropertyTaxesName(
    control: FormPaymentType,
    isBrokenDown?: boolean
  ): void {
    const name = this.calculatePropertyTaxesName(isBrokenDown);
    const nameControl = control.get('name');
    nameControl.setValue(name, { emitEvent: false });
  }

  private createMortgageInsuranceFormGroup(
    expense: HousingExpense
  ): FormPaymentType {
    const fb = this._formBuilder;

    const control = fb.group({
      name: fb.control('Mortgage Insurance'),
      factor: fb.group({
        payPeriod: fb.control(PayPeriod.Monthly),
        onCalculate: fb.control(() => {
          this.openMortgageInsuranceBreakdownModal(control);
        }),
      }),
      value: fb.control(expense.mortgageInsurance),
      monthly: fb.control(expense.mortgageInsurance),
    }) as FormPaymentType;

    const onChange = ({ value, payPeriod }: OnChangePayment) => {
      expense.mortgageInsurance = calculateMonthlyAmountByPayPeriod(
        value ?? control.get('value').value,
        payPeriod ?? control.get('factor.payPeriod').value
      );

      control.get('monthly').setValue(expense.mortgageInsurance);

      this.resetMonthlyMiscExpenses();
      this.resetTotal();
    };

    this._formSubscription.add(
      this.subscribeToPayPeriodChangesOf(control)(onChange)
    );

    this._formSubscription.add(
      control.get('value').valueChanges.subscribe((value) => {
        onChange({ value });
      })
    );

    return control;
  }

  private openMortgageInsuranceBreakdownModal(control: FormPaymentType): void {
    const mortgage = this.mortgage;

    const modal = this._modalService.open(
      MortgageInsuranceDialogComponent,
      Constants.modalOptions.large
    );
    const instance =
      modal.componentInstance as MortgageInsuranceDialogComponent;
    instance.mortgage = this.mortgage;

    modal.result
      .then((result: UrlaMortgage) => {
        mortgage.mortgageInsuranceDetail = result.mortgageInsuranceDetail;
        mortgage.governmentLoanDetail = result.governmentLoanDetail;
        mortgage.mortgageTerm = result.mortgageTerm;
        mortgage.proposedHousingExpense.mortgageInsurance =
          result.mortgageInsuranceDetail.level1MonthlyAmount;

        const payPeriod = control.get('factor.payPeriod').value;
        const value = calculateSelectedPayPeriodAmountByMonthlyAmount(
          mortgage.proposedHousingExpense.mortgageInsurance,
          payPeriod
        );
        const valueControl = control.get('value');
        // emitEvent: true is intentional here. See `qa-loan-info.component.ts` for more details.
        valueControl.setValue(value, { emitEvent: true });

        this.resetMonthlyMiscExpenses();
        this.resetTotal();

        this.save.emit();
      })
      .catch(handleNonErrorDismissals);
  }

  private createAssociationDuesFormGroup(
    expense: HousingExpense
  ): FormPaymentType {
    const fb = this._formBuilder;

    const control = fb.group({
      name: fb.control('Association Dues'),
      factor: fb.group({
        payPeriod: fb.control(PayPeriod.Monthly),
        onCalculate: fb.control(() => {
          this.openAssociationDuesBreakdownModal(control);
        }),
      }),
      value: fb.control(expense.homeownersAssociationDuesAndCondominiumFees),
      monthly: fb.control(expense.homeownersAssociationDuesAndCondominiumFees),
    }) as FormPaymentType;

    const onChange = ({ value, payPeriod, preCalculate }: OnChangePayment) => {
      payPeriod ??= control.get('factor.payPeriod').value;
      expense.homeownersAssociationDuesAndCondominiumFees =
        calculateMonthlyAmountByPayPeriod(
          value ?? control.get('value').value,
          payPeriod
        );

      control
        .get('monthly')
        .setValue(expense.homeownersAssociationDuesAndCondominiumFees);

      preCalculate?.();
      this.calculateAssociationDuesPayment(payPeriod);
      this.resetMonthlyMiscExpenses();
      this.resetTotal();
    };

    this._formSubscription.add(
      this.subscribeToPayPeriodChangesOf(control)(onChange)
    );

    this._formSubscription.add(
      control.get('value').valueChanges.subscribe((value) => {
        onChange({
          value,
          preCalculate: () => {
            this.calculateAssociationDuesNonBreakdownPayment(value);
          },
        });
      })
    );

    if (this.isAssociationDuesBrokenDown()) {
      control.get('value').disable({ emitEvent: false });
    }

    return control;
  }

  private openAssociationDuesBreakdownModal(control: FormPaymentType): void {
    const mortgage = this.mortgage;

    const modal = this._modalService.open(
      HoaDuesCondoFeesBreakdownDialogComponent,
      Constants.modalOptions.large
    );
    const instance =
      modal.componentInstance as HoaDuesCondoFeesBreakdownDialogComponent;
    instance.proposedHousingExpenses = mortgage.proposedHousingExpense;

    modal.result
      .then((expense: HousingExpense) => {
        if (!expense) {
          return;
        }

        mortgage.proposedHousingExpense = cloneDeep(expense);

        const payPeriod = control.get('factor.payPeriod').value;
        const value = calculateSelectedPayPeriodAmountByMonthlyAmount(
          mortgage.proposedHousingExpense
            .homeownersAssociationDuesAndCondominiumFees,
          payPeriod
        );
        const valueControl = control.get('value');
        valueControl.setValue(value, {
          emitEvent: !this.isAssociationDuesBrokenDown(),
        });

        if (this.isAssociationDuesBrokenDown()) {
          valueControl.disable({ emitEvent: false });
        } else {
          valueControl.enable({ emitEvent: false });
        }

        this.resetMonthlyMiscExpenses();
        this.resetTotal();

        this.save.emit();
      })
      .catch(handleNonErrorDismissals);
  }

  private calculateAssociationDuesNonBreakdownPayment(amount: number): void {
    const expense = this.mortgage.proposedHousingExpense;

    expense.homeownersAssociationDues = amount;
    expense.homeownersAssociationDuesAndCondominiumFees = amount;

    expense.condominiumAssociationDues = 0;
    expense.cooperativeAssociationDues = 0;
  }

  private calculateAssociationDuesPayment(payPeriod: PayPeriod): void {
    const expense = this.mortgage.proposedHousingExpense;

    const calculate = createExpenseMonthlyAmountCalculator(expense, payPeriod);

    [
      'condominiumAssociationDues',
      'cooperativeAssociationDues',
      'homeownersAssociationDues',
      'homeownersAssociationDuesAndCondominiumFees',
    ].forEach(calculate);
  }

  private isAssociationDuesBrokenDown(): boolean {
    if (!this.mortgage) {
      return;
    }
    const expense = this.mortgage.proposedHousingExpense;
    if (this.isExpenseGreaterThanZero('homeownersAssociationDues')) {
      if (
        expense.homeownersAssociationDues !==
        expense.homeownersAssociationDuesAndCondominiumFees
      ) {
        return true;
      }
    }

    return ['condominiumAssociationDues', 'cooperativeAssociationDues'].some(
      this.isExpenseGreaterThanZero
    );
  }

  private createOtherHousingExpenseFormGroup(
    expense: HousingExpense
  ): FormPaymentType {
    const fb = this._formBuilder;

    return fb.group({
      name: fb.control('Other'),
      monthly: fb.control(expense.otherHousingExpense),
    });
  }
}

type FormPaymentType = FormGroup<{
  name: FormControl<string>;
  factor?: FormGroup<{
    payPeriod: FormControl<PayPeriod>;
    onCalculate?: FormControl<() => void>;
  }>;
  factorDescription?: FormControl<string>;
  value?: FormControl<number>;
  monthly: FormControl<number>;
}>;

type FormType = FormGroup<{
  payments: FormArray<FormPaymentType>;
  total: FormControl<number>;
}>;

interface OnChangePayment {
  value?: number;
  payPeriod?: PayPeriod;
  preCalculate?: () => void;
}

export function sum(...values: number[]): number {
  return values.reduce((total, value) => total + safeNumber(value), 0);
}

export function safeNumber(value?: any): number {
  return Number(value) || 0;
}

function createExpenseMonthlyAmountCalculator(
  expense: HousingExpense,
  payPeriod: PayPeriod
): (key: KeyOfType<typeof expense, Number>) => void {
  return key => {
    expense[key] = calculateMonthlyAmountByPayPeriod(expense[key], payPeriod);
  };
}

function* iterControls(control: AbstractControl): Iterable<AbstractControl> {
  if (control instanceof FormGroup || control instanceof FormArray) {
    for (const key in control.controls) {
      yield* iterControls(control.controls[key]);
    }
  } else {
    yield control;
  }
}
