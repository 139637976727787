import { Component, OnInit, Input } from '@angular/core';
import { LosDisclosureInfo, LosGeneratedDocument } from '../../../request-models/los-disclosure-gen-job';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'encompass-disclosure-preview-le',
  templateUrl: './encompass-disclosure-preview-le.component.html',
  styleUrls: ['./encompass-disclosure-preview-le.component.scss']
})
export class EncompassDisclosurePreviewLeComponent implements OnInit {

  @Input() info: LosDisclosureInfo;

  leDocumentData = null;

  constructor(private readonly _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    const leDocument = this.info.documents.find(d => d.fileName == "Loan Estimate.pdf");
    this.leDocumentData = leDocument.downloadedContent;
  }

}
