import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Demographics, EthnicityType } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { UrlaBorrower } from '../../models/urla-mortgage.model';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { MenuService } from 'src/app/services/menu.service';
import { MenuItemStatus } from 'src/app/modules/tpo/models/enums/menu-item-status.enum';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BorrowerValidityStatus } from '../../borrower-information/borrower-info/borrower-info.component';

@Component({
  selector: 'borrower-demographics',
  templateUrl: 'borrower-demographics.component.html',
  styleUrls: ['./borrower-demographics.component.scss'],
  viewProviders: [formViewProvider],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BorrowerDemographicsComponent implements OnInit {

  @ViewChild('borrowerDemographicForm')
  borrowerDemographicForm: NgForm;

  @Output()
  borrowerValidityStatusChanged: EventEmitter<BorrowerValidityStatus> = new EventEmitter<BorrowerValidityStatus>();

  @Input()
  isReadOnly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  private _borrower: UrlaBorrower;

  sexOptions: EnumerationItem[] = [];
  ethnicityOptions: EnumerationItem[] = [];
  hispanicOriginOptions: EnumerationItem[] = [];
  raceOptions: EnumerationItem[] = [];
  raceDesignationOptions: EnumerationItem[] = [];
  asianRaceDesignationOptions: EnumerationItem[] = [];
  pacificIslanderRaceDesignationOptions: EnumerationItem[] = [];

  applicationChannelOptions: EnumerationItem[] = [];
  yesNoOptions: EnumerationItem[] = [];

  asianRace: string;
  pacificIslanderRace: string;
  nativeAmericanRace: string;

  ethnicities: EthnicityType[] = [];

  ethnicityOrigins: string[] = [];

  sexes: string[] = [];

  races: string[] = [];

  raceDesignations: string[] = [];

  protected isOtherSelectedForHispanicOrLatinoEthnicity: boolean = false;
  protected isHispanicOrLatinoSelected: boolean = false;
  protected isNativeAmericanSelected: boolean = false;
  protected isRaceSelected: boolean = false;
  protected isOtherSelectedForPacificIslanderRace: boolean = false;
  protected isPacificIslanderSelected: boolean = false;
  protected isSexSelected: boolean = false;
  protected isAsianSelected: boolean = false;
  protected isOtherSelectedForAsianRace: boolean = false;
  protected isEthnicitySelected: boolean = false;
  protected isOtherSelectedForEthnicity: boolean = false;

  private _formValidationEventsSubscription: Subscription;

  @Input()
  set borrower(borrower: UrlaBorrower) {
    this._borrower = borrower;
    if (!this._borrower.governmentMonitors) {
      this._borrower.governmentMonitors = new Demographics();
    }
  }

  get borrower(): UrlaBorrower {
    return this._borrower;
  }

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _menuService: MenuService) {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.ethnicityOptions = enums[Constants.enumerations.ethnicityType];
      this.hispanicOriginOptions = enums[Constants.enumerations.ethnicityOriginType];
      this.raceOptions = enums[Constants.enumerations.raceType];
      this.sexOptions = enums[Constants.enumerations.sexType];
      this.raceDesignationOptions = enums[Constants.enumerations.raceDesignation];
      this.applicationChannelOptions = enums[Constants.enumerations.applicationTakenMethodType];
      this.yesNoOptions = this._enumsService.getYesNoEnumItems();
      this.asianRaceDesignationOptions = this.raceDesignationOptions.filter(t =>
        t.value == this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceDesignationAsianIndian) ||
        t.value == this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceDesignationChinese) ||
        t.value == this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceDesignationFilipino) ||
        t.value == this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceDesignationJapanese) ||
        t.value == this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceDesignationKorean) ||
        t.value == this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceDesignationVietnamese) ||
        t.value == this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceDesignationOtherAsian));

      this.asianRace = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceTypeAsian);

      this.pacificIslanderRaceDesignationOptions = this.raceDesignationOptions.filter(t =>
        t.value == this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceDesignationGuamanianOrChamorro) ||
        t.value == this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceDesignationNativeHawaiian) ||
        t.value == this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceDesignationSamoan) ||
        t.value == this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceDesignationOtherPacificIslander));

      this.pacificIslanderRace = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceTypeNativeHawaiianOrOtherPacificIslander);
      this.nativeAmericanRace = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceTypeAmericanIndianOrAlaskaNative);
    });
  }

  ngOnInit() {
    if (this.borrower.governmentMonitors.ethnicity && this.borrower.governmentMonitors.ethnicity.length > 0) {
      const ethnicities = this.borrower.governmentMonitors.ethnicity.split(",");
      this.ethnicities = ethnicities.map(e => e.trim()) as EthnicityType[];
    }
    if (this.borrower.governmentMonitors.ethnicityOrigin && this.borrower.governmentMonitors.ethnicityOrigin.length > 0) {
      this.ethnicityOrigins = this.borrower.governmentMonitors.ethnicityOrigin.split(",");
      this.ethnicityOrigins = this.ethnicityOrigins.map(e => e.trim());
    }
    if (this.borrower.governmentMonitors.sex && this.borrower.governmentMonitors.sex.length > 0) {
      this.sexes = this.borrower.governmentMonitors.sex.split(",");
      this.sexes = this.sexes.map(s => s.trim());
    }
    if (this.borrower.governmentMonitors.race && this.borrower.governmentMonitors.race.length > 0) {
      this.races = this.borrower.governmentMonitors.race.split(",");
      this.races = this.races.map(r => r.trim());
    }
    if (this.borrower.governmentMonitors.raceDesignation && this.borrower.governmentMonitors.raceDesignation.length > 0) {
      this.raceDesignations = this.borrower.governmentMonitors.raceDesignation.split(",");
      this.raceDesignations = this.raceDesignations.map(r => r.trim());
    }
    this.setInitialState();
  }

  ngAfterViewInit(): void {
    if (this.borrowerDemographicForm) {
      this._formValidationEventsSubscription = this.borrowerDemographicForm.valueChanges.subscribe((changeEvent) => {
        if (changeEvent) {
          const fieldNames = Object.keys(changeEvent).filter(key => key !== 'triggerValidation');
          for (let i = 0; i < fieldNames.length; i++) {
            const fieldControl = this.borrowerDemographicForm.controls[fieldNames[i]];
            if (fieldControl.touched) {
              setTimeout(() => {
                this.borrowerValidityStatusChanged.emit({ borrowerId: this.borrower.borrowerId, formValidityStatus: "" });
              });
              break;
            }
          }
        }
      })
    }
  }

  ngOnDestroy(): void {
    this._formValidationEventsSubscription?.unsubscribe();
  }

  onMethodByWhichApplicationIsTakenChanged = () => {
    if (this.borrower.governmentMonitors.applicationTakenMethod != this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.applicationTakenMethodTypeFaceToFace)) {
      this.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname = false;
      this.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname = false;
      this.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname = false;
    } else {
      this.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname = null;
      this.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname = null;
      this.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname = null;
    }
  }

  ethnicityChanged = () => {
    this.borrower.governmentMonitors.ethnicity = '' as any;

    if (this.ethnicities.length != 0 && this.ethnicities[this.ethnicities.length - 1] == EthnicityType.NotApplicable) {
      this.ethnicities = [EthnicityType.NotApplicable];
      this.borrower.governmentMonitors.ethnicity = EthnicityType.NotApplicable;
    } else if (this.ethnicities.length != 0 && this.ethnicities[this.ethnicities.length - 1] == EthnicityType.InformationNotProvided) {
      this.ethnicities = [EthnicityType.InformationNotProvided];
      this.borrower.governmentMonitors.ethnicity = EthnicityType.InformationNotProvided;
    } else if (this.ethnicities.length != 0) {
      if (this.ethnicities.includes(EthnicityType.NotApplicable)) {
        const index = this.ethnicities.findIndex(ethnicity => ethnicity == EthnicityType.NotApplicable);
        this.ethnicities.splice(index, 1);
        this.ethnicities = [...this.ethnicities];
      }
      if (this.ethnicities.includes(EthnicityType.InformationNotProvided)) {
        const index = this.ethnicities.findIndex(ethnicity => ethnicity == EthnicityType.InformationNotProvided);
        this.ethnicities.splice(index, 1);
        this.ethnicities = [...this.ethnicities];
      }

      this.ethnicities.forEach(ethnicity => {
        this.borrower.governmentMonitors.ethnicity += ethnicity + ',';
      });
      this.borrower.governmentMonitors.ethnicity = this.borrower.governmentMonitors.ethnicity.slice(0, this.borrower.governmentMonitors.ethnicity.length - 1);
    }
    this.checkIfEthnicityIsSelected();
    this.checkIfHispanicOrLatinoIsSelected();
    this.checkIfOtherIsSelectedForEthnicity();
  }

  ethnicityOriginChanged = () => {
    this.borrower.governmentMonitors.ethnicityOrigin = '';

    if (this.ethnicityOrigins.length != 0) {
      this.ethnicityOrigins.forEach(ethnicityOrigin => {
        this.borrower.governmentMonitors.ethnicityOrigin += ethnicityOrigin + ',';
      });
      this.borrower.governmentMonitors.ethnicityOrigin = this.borrower.governmentMonitors.ethnicityOrigin.slice(0, this.borrower.governmentMonitors.ethnicityOrigin.length - 1);
    }

    this.checkIfOtherIsSelectedForHispanicOrLatinoEthnicity();
  }

  raceChanged = (event: any, value: string) => {
    const index = this.races.findIndex(v => v == value);
    if (event.target.checked) {
      if (value == EthnicityType.NotApplicable || value == EthnicityType.InformationNotProvided) {
        this.races = [value];
      } else {
        if (index < 0) {
          this.races.push(value);
        }
      }
    } else {
      if (index >= 0) {
        this.races.splice(index, 1);
      }
    }

    this.borrower.governmentMonitors.race = '';

    if (this.races.length > 1) {
      [EthnicityType.NotApplicable, EthnicityType.InformationNotProvided].forEach(r => {
        const index = this.races.findIndex(race => race == r);
        if (index >= 0) {
          this.races.splice(index, 1);
        }
      })
    }

    this.borrower.governmentMonitors.race = this.races.join(',');

    this.checkIfRaceIsSelected();
    this.checkIfNativeAmericanIsSelected();
    this.checkIfPacificIslanderIsSelected();
    this.checkIfAsianIsSelected();
  }

  raceDesignationChanged = () => {
    this.borrower.governmentMonitors.raceDesignation = '';

    if (this.raceDesignations.length != 0) {
      this.raceDesignations.forEach(raceDesignation => {
        this.borrower.governmentMonitors.raceDesignation += raceDesignation + ',';
      });
      this.borrower.governmentMonitors.raceDesignation = this.borrower.governmentMonitors.raceDesignation.slice(0, this.borrower.governmentMonitors.raceDesignation.length - 1);
    }
    this.checkIfOtherIsSelectedForPacificIslanderRace();
    this.checkIfOtherIsSelectedForAsianRace();
  }

  onSexInformationChanged = () => {
    this.borrower.governmentMonitors.sex = '';

    if (this.sexes.length != 0 && this.sexes[this.sexes.length - 1] == EthnicityType.NotApplicable) {
      this.sexes = [EthnicityType.NotApplicable];
      this.borrower.governmentMonitors.sex = EthnicityType.NotApplicable;
    } else if (this.sexes.length != 0 && this.sexes[this.sexes.length - 1] == EthnicityType.InformationNotProvided) {
      this.sexes = [EthnicityType.InformationNotProvided];
      this.borrower.governmentMonitors.sex = EthnicityType.InformationNotProvided;
    } else if (this.sexes.length != 0) {
      if (this.sexes.includes(EthnicityType.NotApplicable)) {
        const index = this.sexes.findIndex(sex => sex == EthnicityType.NotApplicable);
        this.sexes.splice(index, 1);
        this.sexes = [...this.sexes];
      }
      if (this.sexes.includes(EthnicityType.InformationNotProvided)) {
        const index = this.sexes.findIndex(sex => sex == EthnicityType.InformationNotProvided);
        this.sexes.splice(index, 1);
        this.sexes = [...this.sexes];
      }

      this.sexes.forEach(sex => {
        this.borrower.governmentMonitors.sex += sex + ',';
      });
      this.borrower.governmentMonitors.sex = this.borrower.governmentMonitors.sex.slice(0, this.borrower.governmentMonitors.sex.length - 1);
    }

    this.checkIfSexIsSelected();

    if (!this.isSexSelected) {
      this._menuService.setStatus(Constants.menu.urlaMenuItems.demographics, MenuItemStatus.Pending);
    } else {
      this._menuService.setStatus(Constants.menu.urlaMenuItems.demographics, MenuItemStatus.Success);
    }
  }

  replaceSpaces = (text: string): string => {
    return text.replace(/\s/g, "");
  }

  private setInitialState = () => {
    this.checkIfSexIsSelected();
    this.checkIfRaceIsSelected();
    this.checkIfEthnicityIsSelected();
    this.checkIfOtherIsSelectedForEthnicity();
    this.checkIfAsianIsSelected();
    this.checkIfHispanicOrLatinoIsSelected();
    this.checkIfNativeAmericanIsSelected();
    this.checkIfPacificIslanderIsSelected();
    this.checkIfOtherIsSelectedForAsianRace();
    this.checkIfOtherIsSelectedForHispanicOrLatinoEthnicity();
    this.checkIfOtherIsSelectedForPacificIslanderRace();
  }

  private checkIfEthnicityIsSelected = (): boolean => {
    const selected = this.borrower.governmentMonitors.ethnicity != null &&
      this.borrower.governmentMonitors.ethnicity.length > 0;
    if (!selected) {
      this.borrower.governmentMonitors.ethnicityOrigin = null;
    }
    return this.isEthnicitySelected = selected;
  }

  private checkIfOtherIsSelectedForEthnicity(): void {
    const selected = this.ethnicities.includes(EthnicityType.Other);
    if (!selected) {
      delete this.borrower.governmentMonitors.ethnicityOtherDescription;
    }
    this.isOtherSelectedForEthnicity = selected;
  }

  private checkIfOtherIsSelectedForAsianRace = () => {
    var selected = this.raceDesignations.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.raceDesignationOtherAsian));
    if (!selected) {
      this.borrower.governmentMonitors.otherAsianRace = null;
    }

    return this.isOtherSelectedForAsianRace = selected;
  }

  private checkIfAsianIsSelected = () => {
    const selected = this.races.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.raceTypeAsian));
    if (!selected) {
      if (this.raceDesignations && this.raceDesignations.length > 0) {
        this.asianRaceDesignationOptions.forEach(asianRaceDesignation => {
          const index = this.raceDesignations.findIndex(raceDesignation => raceDesignation == asianRaceDesignation.value);
          if (index >= 0) {
            this.raceDesignations.splice(index, 1);
            this.raceDesignationChanged();
          }
        });
      }

    }
    this.isAsianSelected = selected;
  }

  private checkIfSexIsSelected = () => {
    const selected = this.borrower.governmentMonitors.sex != null &&
      this.borrower.governmentMonitors.sex.length > 0;
    this.isSexSelected = selected;
  }

  private checkIfPacificIslanderIsSelected = () => {
    const selected = this.races.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.raceTypeNativeHawaiianOrOtherPacificIslander));
    if (!selected) {
      if (this.raceDesignations && this.raceDesignations.length > 0) {
        this.pacificIslanderRaceDesignationOptions.forEach(pacificIslanderRaceDesignation => {
          const index = this.raceDesignations.findIndex(raceDesignation => raceDesignation == pacificIslanderRaceDesignation.value);
          if (index >= 0) {
            this.raceDesignations.splice(index, 1);
            this.raceDesignationChanged();
          }
        });
      }
    }
    return this.isPacificIslanderSelected = selected;
  }

  private checkIfOtherIsSelectedForPacificIslanderRace = () => {
    var selected = this.raceDesignations.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.raceDesignationOtherPacificIslander));
    if (!selected) {
      this.borrower.governmentMonitors.otherPacificIslanderRace = null;
    }
    this.isOtherSelectedForPacificIslanderRace = selected;
  }

  private checkIfRaceIsSelected = () => {
    const selected = this.borrower.governmentMonitors.race != null &&
      this.borrower.governmentMonitors.race.length > 0;
    if (!selected) {
      this.borrower.governmentMonitors.raceDesignation = null;
      this.raceDesignations = [];
    }
    this.isRaceSelected = selected;
  }

  private checkIfOtherIsSelectedForHispanicOrLatinoEthnicity = () => {
    const selected = this.ethnicityOrigins.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.ethnicityOriginTypeOther));
    if (!selected) {
      this.borrower.governmentMonitors.ethnicityOriginOtherDescription = null;
    }
    this.isOtherSelectedForHispanicOrLatinoEthnicity = selected;
  }

  private checkIfHispanicOrLatinoIsSelected = () => {
    const selected = (this.ethnicities as string[])
      .includes(this._enumsService
        .getEnumValue(Constants.enumerations.
          enumItemNames.ethnicityTypeHispanicOrLatino));
    if (!selected) {
      this.ethnicityOrigins = [];
    }
    this.isHispanicOrLatinoSelected = selected;
  }

  private checkIfNativeAmericanIsSelected = () => {
    const selected = this.races.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.raceTypeAmericanIndianOrAlaskaNative));
    if (!selected) {
      this.borrower.governmentMonitors.nativeAmericanTribeName = null;
    }
    this.isNativeAmericanSelected = selected;
  }
}
