
<form #financialInfoForm="ngForm">
  <div *ngIf="mortgage.mortgageId != null">
      <input type="hidden" name="triggerValidation" [(ngModel)]="triggerValidation"/>
      <assets
        [isReadOnly]="isReadOnly"
        [mortgage]="mortgage"
        [assetTypes]="assetTypes"
        [systemLevel]="systemLevel"
        [inEditMode]="inEditMode" 
        [urlaFieldsConfig]="urlaFieldsConfig"
        (subTotalChange)="assetsSubTotalChange($event)">
      </assets>
      <other-assets
        [isReadOnly]="isReadOnly"
        [mortgage]="mortgage"
        [application]="application"
        [assetTypes]="assetTypes"
        [purchaseCreditTypes]="purchaseCreditTypes"
        [downPaymentSourceTypes]="downPaymentSourceTypes"
        [inEditMode]="inEditMode" 
        [urlaFieldsConfig]="urlaFieldsConfig"
        [financialAssetsSubTotal]="assetsSubTotal">
      </other-assets>
      <liabilities
        [isReadOnly]="isReadOnly"
        [mortgage]="mortgage"
        [liabilityTypes]="liabilityTypes"
        [inEditMode]="inEditMode" 
        [urlaFieldsConfig]="urlaFieldsConfig"
        (subTotalChange)="liabilitiesSubTotalChange($event)">
      </liabilities>
      <other-liabilities
        [isReadOnly]="isReadOnly"
        [mortgage]="mortgage"
        [liabilityTypes]="liabilityTypes"
        [monthlyPaymentSubTotal]="monthlyPaymentSubTotal"
        [unpaidBalanceSubTotal]="unpaidBalanceSubTotal"
        [partialPayoffAmount]="partialPayoffAmount"
        [inEditMode]="inEditMode" 
        [urlaFieldsConfig]="urlaFieldsConfig">
      </other-liabilities>
  </div>
</form>
<loading-indicator *ngIf="mortgage.mortgageId == null" [loadingMessage]="'Loading financial info...'"></loading-indicator>
