<div class="modal-header">
  <h5 class="modal-title"> Import Assets into Loan </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
      <voa-import-assets *ngIf="order" [order]="order"></voa-import-assets>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close()" data-bs-dismiss="modal"> Close
  </button>
</div>
