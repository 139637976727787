<div class="form-check form-check-inline" *ngFor="let option of options; let i=index" [ngClass]="{'col-sm-12': vertical}">
  <div class="checkbox-primary" [ngClass]="{'checkbox': !small, 'me-2': small}">
    <input id="{{name}}-{{option.name}}" [ngClass]="{'form-check-input': small}"
      [value]="option.value" type="checkbox" name="{{name}}-{{option.name}}" [disabled]="readonly"
      [(ngModel)]="checkedStatuses[i]" (ngModelChange)="onOptionSelectionChanged($event, option.value)">
    <label [for]="readonly ? name : name + '-' + option.name">
      {{option.name}}
    </label>
  </div>
</div>
