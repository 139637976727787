import { Component, Input, OnInit } from '@angular/core';
import { RealEstateOwned } from 'src/app/models';
import { cloneDeep } from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'reo-expense-breakdown-dialog',
  templateUrl: 'reo-expense-breakdown-dialog.component.html',
  styleUrls: ['./reo-expense-breakdown-dialog.component.scss']
})
export class ReoExpenseBreakdownComponent implements OnInit {

  @Input()
  set reo(reo: RealEstateOwned) {
    this._reo = cloneDeep(reo);
    this.isBrokenDown = (!!this._reo.otherFinance ||
      !!this._reo.hazardInsurance ||
      !!this._reo.propertyTax ||
      !!this._reo.mortgageInsurance ||
      !!this._reo.hoa ||
      !!this._reo.otherMonthlyExpense);
    this.calculateTotal();
  }

  get reo(): RealEstateOwned {
    return this._reo;
  }

  protected isBrokenDown: boolean = false;
  protected total: number = 0;

  private _reo: RealEstateOwned;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

  onIsBrokenDownChanged = () => {
    if (!this.isBrokenDown) {
      this._reo.monthlyMiscExpenses = this.total;
      this.zeroOutReoExpenseBreakdownItems();
    } else {
      this._reo.monthlyMiscExpenses = 0;
    }
  }

  onBreakdownItemChanged = () => {
    this.calculateTotal();
  }

  onEnteredTotalChanged = () => {
    this.total = this._reo.monthlyMiscExpenses;
  }

  onCancelClicked = () => {
    this.activeModal.dismiss();
  }

  onOkClicked = () => {
    if (this.isBrokenDown) {
      this._reo.monthlyMiscExpenses = this.total;
    }
    this.activeModal.close(this._reo);
  }

  private zeroOutReoExpenseBreakdownItems = () => {
    this._reo.otherFinance = 0;
    this._reo.hazardInsurance = 0;
    this._reo.propertyTax = 0;
    this._reo.mortgageInsurance = 0;
    this._reo.hoa = 0;
    this._reo.otherMonthlyExpense = 0;
  }

  private calculateTotal = () => {
    this.total = (this._reo.otherFinance || 0) +
      (this._reo.hazardInsurance || 0) +
      (this._reo.propertyTax || 0) +
      (this._reo.mortgageInsurance || 0) +
      (this._reo.hoa || 0) +
      (this._reo.otherMonthlyExpense || 0);
  }
}
