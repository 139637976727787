import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export type QaButtonVariant = 'primary' | 'primary-soft' | 'danger' | 'muted';
export type QaButtonSize = 'x-small' | 'small' | 'medium' | 'large' | 'x-large';

@Component({
  selector: 'qa-button',
  templateUrl: './qa-button.component.html',
  styleUrls: ['./qa-button.component.scss'],
})
export class QaButtonComponent implements OnChanges {
  @Input() variant: QaButtonVariant = 'primary';
  @Input() size: QaButtonSize = 'medium';
  @Input() isDisabled = false;

  @Output() qaClick = new EventEmitter();

  protected modifierClasses: string[] | undefined;

  constructor() {
    // Just in case the variant is left as default
    this.initModifierClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const variantChange = changes.variant;
    if (variantChange) {
      this.initModifierClasses();
    }
  }

  private initModifierClasses() {
    const variantClass = this.getVariantClass();
    const sizeClass = this.getSizeClass();

    const classes = [variantClass, sizeClass].filter(Boolean);

    this.modifierClasses = classes.length > 0 ? classes : undefined;
  }

  private getVariantClass(): string | undefined {
    switch (this.variant) {
      case 'primary':
        return 'btn-primary';
      case 'primary-soft':
        return 'btn-soft-primary';
      case 'danger':
        return 'btn-soft-danger';
      case 'muted':
        return 'btn-secondary';
      default:
        return undefined;
    }
  }

  private getSizeClass(): string | undefined {
    switch (this.size) {
      case 'x-small':
        return 'btn-xs';
      case 'small':
        return 'btn-sm';
      case 'medium':
        return undefined; // default size is medium
      case 'large':
        return 'btn-lg';
      case 'x-large':
        return 'btn-xl';
      default:
        return undefined;
    }
  }
}
