import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaMortgage } from '../models/urla-mortgage.model';
import { UrlaSectionComponent } from '../urla-section/urla-section.component';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';
import { LoanApplication } from 'src/app/models';

@Component({
  selector: 'qualifying-the-borr',
  templateUrl: 'qualifying-the-borr.component.html',
  styleUrls: ['./qualifying-the-borr.component.scss']
})
export class QualifyingTheBorrComponent extends UrlaSectionComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  application: LoanApplication;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Output()
  purchasePriceAmountChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loanAmountChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  miAndFundingFeeChanged: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild("qualifyingTheBorrowerForm")
  urlaSectionForm: NgForm;

  menuItem: string = Constants.menu.urlaMenuItems.qualifyingTheBorr;

  landValueType: EnumerationItem[] = [];
  purchaseCreditTypes: EnumerationItem[] = [];
  ausSystemTypes: EnumerationItem[] = [];
  yesNoOptions: EnumerationItem[] = [];
  freddieMacRefinanceProgram: EnumerationItem[] = [];
  freddieMacLoanPrograms: EnumerationItem[] = [];

  constructor(private readonly _injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _urlaValidationService: UrlaValidationService) {
    super(_injector);
  }

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.landValueType = enums[Constants.mortgageEnumerations.landValueType];
      this.purchaseCreditTypes = enums[Constants.enumerations.purchaseCreditTypes];
      this.ausSystemTypes = enums[Constants.mortgageEnumerations.ausSystemType];
      this.freddieMacRefinanceProgram = enums[Constants.mortgageEnumerations.freddieMacRefinanceProgram];
      this.freddieMacLoanPrograms = enums[Constants.mortgageEnumerations.freddieMacLoanProgram];
    })
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForQualifyingTheBorr(this.mortgage);
    super.setMenuItemStatus(validityStatus);
  }

  onPurchasePriceAmountChanged = (amount: number) => {
    this.purchasePriceAmountChanged.emit(amount);
  }

  loanAmountChange = (amount: number) => {
    this.loanAmountChanged.emit(amount);
  }
  
  miAndFundingFeeChange = (miAndFundingAmount: number) => {
    this.miAndFundingFeeChanged.emit(miAndFundingAmount);
  }
}
