import { Component, Input, OnInit } from '@angular/core';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import * as _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'zip-code-picker-dialog',
  templateUrl: 'zip-code-picker-dialog.component.html'
})

export class ZipCodePickerDialogComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  zipcodeInfo: ZipCodeLookupResult;

  isOkEnabled: boolean = false;

  constructor(public activeModal: NgbActiveModal) {
    this.zipcodeInfo = new ZipCodeLookupResult();
  }

  ngOnInit() {
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.zipcodeInfo = zipCode;
      this.isOkEnabled = true;
    }
  }

  onOkClicked = () => {
    this.activeModal.close(this.zipcodeInfo);
  };

  onCancelClicked = () => {
    this.activeModal.close(null);
  };
}