import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Address, ResidencyAddress, ResidencyBasis, ResidencyType, SubjectProperty } from 'src/app/models';
import { UrlaBorrower } from '../../models/urla-mortgage.model';
import { NonPresentAddressComponent } from './non-present-address/non-present-address.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UtilityService } from '../../services/utility.service';
import { ResidencyAddressChangedEvent } from './residency-address-base.component';

@Component({
  selector: 'address-info',
  templateUrl: 'address-info.component.html',
  styleUrls: ['address-info.component.scss'],
  viewProviders: [formViewProvider]
})
export class AddressInfoComponent implements OnInit, AfterViewInit {

  @ViewChildren('nonPresentAddress') nonPresentAddresses: QueryList<NonPresentAddressComponent> | undefined

  private _borrower: UrlaBorrower;

  private _nonPresentAddressEventSubscriptions: any[] = [];

  @Output()
  presentStreetAddressChanged: EventEmitter<ResidencyAddressChangedEvent> = new EventEmitter<ResidencyAddressChangedEvent>();

  @Output()
  presentAddressCityBlurred: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Output()
  presentAddressStateBlurred: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Output()
  presentAddressZipCodeBlurred: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Output()
  residencyBasisChanged: EventEmitter<ResidencyBasis> = new EventEmitter<ResidencyBasis>();

  @Input()
  isReadOnly: boolean = false;

  @Output()
  presentAddressSubjectPropertyStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  pushPresentAddressToSubjectPropertyClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pullPresentAddressSubjectPropertyClicked: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Input()
  isSubjectPropertyPresentAddress: boolean = false;

  @Input()
  isSubjectPropertyPickerVisibleForPresentAddress: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  set borrower(borrower: UrlaBorrower) {
    this._borrower = borrower;
    if (this._borrower.residencyAddresses) {
      this.presentAddresses = this._borrower.residencyAddresses.filter(a => a.residencyType === ResidencyType.PresentAddress);
      if (!this.presentAddresses.length) {
        this.presentAddresses = [new ResidencyAddress()];
        this.presentAddresses[0].residencyType = ResidencyType.PresentAddress;
        this._borrower.residencyAddresses.push(this.presentAddresses[0]);
      } else {
        this.presentAddresses.forEach(address => {
          if (!address.durationYears && !address.durationMonths) {
            address.durationMonths = null;
            address.durationYears = null;
          }
        })
      }
      this.mailingAddresses = this._borrower.residencyAddresses.filter(a => a.residencyType === ResidencyType.MailingAddress);
      this.formerAddresses = this._borrower.residencyAddresses.filter(a => a.residencyType === ResidencyType.FormerAddress);
    } else {
      this._borrower.residencyAddresses = [];
      this.presentAddresses = [new ResidencyAddress()];
      this.presentAddresses[0].address = new Address();
      this.presentAddresses[0].residencyType = ResidencyType.PresentAddress;
      this._borrower.residencyAddresses.push(this.presentAddresses[0]);
    }
    this.calculateNumberOfYearsAndMonths();
  }

  get borrower(): UrlaBorrower {
    return this._borrower;
  }

  presentAddresses: ResidencyAddress[] = [];
  formerAddresses: ResidencyAddress[] = [];
  mailingAddresses: ResidencyAddress[] = [];

  numberOfMonths: number = 0;
  numberOfYears: number = 0;

  hasMoreThan2YearsOfHistory: boolean | undefined = undefined;

  monthsOfHistoryForValidation: number | undefined = 2;

  constructor(private readonly _utilityService: UtilityService) {
  }

  ngAfterViewInit(): void {
    if (this.nonPresentAddresses) {
      this.nonPresentAddresses.changes.subscribe((r) => {
        if (r._results && r._results.length > 0) {
          this._nonPresentAddressEventSubscriptions.forEach(subscription => {
            subscription.unsubscribe();
          });
          this._nonPresentAddressEventSubscriptions = [];
          this.subscribeToNonPresentAddressEvents();
        }
      });

      this.subscribeToNonPresentAddressEvents();
    }
  }

  ngOnInit() {

  }

  onPushPresentAddressToSubjectPropertyClicked = () => {
    this.pushPresentAddressToSubjectPropertyClicked.emit();
  }

  onPullPresentAddressSubjectPropertyClicked = (address: ResidencyAddress) => {
    this.pullPresentAddressSubjectPropertyClicked.emit(address);
  }

  onSubjectPropertyStatusChanged = (e: boolean) => {
    this.presentAddressSubjectPropertyStatusChanged.emit(e);
  }

  onPresentStreetAddressChanged = (e: ResidencyAddressChangedEvent) => {
    this.presentStreetAddressChanged.emit(e);
  }

  onPresentAddressCityBlurred = (e: ResidencyAddress) => {
    this.presentAddressCityBlurred.emit(e);
  }

  onPresentAddressStateBlurred = (e: ResidencyAddress) => {
    this.presentAddressStateBlurred.emit(e);
  }

  onPresentAddressZipCodeBlurred = (e: ResidencyAddress) => {
    this.presentAddressZipCodeBlurred.emit(e);
  }

  onResidencyBasisChanged = (e: ResidencyBasis) => {
    this.residencyBasisChanged.emit(e);
  }

  onAttemptedToDeleteAddress = (residencyAddress: ResidencyAddress) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this address?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        const index = self._borrower.residencyAddresses.indexOf(residencyAddress);
        if (index >= 0) {
          self._borrower.residencyAddresses.splice(index, 1)
          self.formerAddresses = self._borrower.residencyAddresses.filter(a => a.residencyType === ResidencyType.FormerAddress);
          self.mailingAddresses = self._borrower.residencyAddresses.filter(a => a.residencyType === ResidencyType.MailingAddress);
          self.calculateNumberOfYearsAndMonths();
        }
      }
    });
  }

  onAddFormerAddressClicked = () => {
    this.addAddress(ResidencyType.FormerAddress);
    this.formerAddresses = this._borrower.residencyAddresses.filter(a => a.residencyType === ResidencyType.FormerAddress);
    this.calculateNumberOfYearsAndMonths();
  }

  onAddMailingAddressClicked = () => {
    this.addAddress(ResidencyType.MailingAddress);
    this.mailingAddresses = this._borrower.residencyAddresses.filter(a => a.residencyType === ResidencyType.MailingAddress);
  }

  onDurationAtAddressChanged = () => {
    this.calculateNumberOfYearsAndMonths();
  }

  private addAddress = (type: ResidencyType) => {
    let residencyAddress = new ResidencyAddress();
    residencyAddress.address = new Address();
    residencyAddress.residencyAddressId = this._utilityService.getUniqueId();
    residencyAddress.residencyType = type;
    residencyAddress.address.country = "us";
    this._borrower.residencyAddresses.push(residencyAddress);
  }

  private subscribeToNonPresentAddressEvents = () => {
    if (this.nonPresentAddresses) {
      const components: NonPresentAddressComponent[] = this.nonPresentAddresses.toArray();
      components.forEach(item => {
        const deleteItemClickedEventSubscription = item.attemptedToDeleteAddress.subscribe(e => this.onAttemptedToDeleteAddress(e));
        this._nonPresentAddressEventSubscriptions.push(deleteItemClickedEventSubscription);
      });
    }
  }

  private calculateNumberOfYearsAndMonths = () => {
    const residencyAddresses = this._borrower.residencyAddresses.filter(a => a.residencyType !== ResidencyType.MailingAddress);

    this.numberOfMonths = 0;
    this.numberOfYears = 0;

    residencyAddresses.forEach(address => {
      this.numberOfMonths += Number(address.durationMonths) | 0;
      this.numberOfYears += Number(address.durationYears) | 0;
    })

    let months: number = this.numberOfYears * 12 + this.numberOfMonths;
    this.numberOfYears = Math.floor(months / 12);
    this.numberOfMonths = months - this.numberOfYears * 12;

    this.hasMoreThan2YearsOfHistory = (this.numberOfYears * 12 + this.numberOfMonths >= 24);
    this.monthsOfHistoryForValidation = !this.hasMoreThan2YearsOfHistory ? undefined : this.numberOfYears * 12 + this.numberOfMonths;
  }
}
