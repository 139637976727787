import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import sha1 from 'crypto-js/sha1';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, firstValueFrom, forkJoin } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, LoanApplication, Mortgage } from 'src/app/models';
import { CalculatedFeeTypeEnum } from 'src/app/models/fee/calculated-fee-type.enum';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { FeeTypeEnum } from 'src/app/models/fee/fee-type.enum';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { LoanPassExecuteRequest } from 'src/app/models/loan-pass/loan-pass-execute-request.model';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';
import { PricingEngineVendor } from 'src/app/modules/admin/tpo-config/models/configuration-product.model';
import { Channel } from 'src/app/modules/leads/models/lead.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Constants } from 'src/app/services/constants';
import { FeeService } from 'src/app/services/fee.service';
import { LoanPassFieldMappingsService } from 'src/app/services/loan-pass-field-mappings.service';
import { LosService } from 'src/app/services/los.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PricingCustomFieldService } from 'src/app/services/pricing-custom-field.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { EnabledBusinessChannel, VendorProfile } from '../../models/business-channel.model';
import { AssignedProductPrice } from '../../models/pricing/assigned-product-price.model';
import { BaseRequest } from '../../models/pricing/base-request.model';
import { CustomField } from '../../models/pricing/custom-fields.model';
import { LoanPassIframeLockPriceMessage } from '../../models/pricing/loan-pass-iframe-lock-price-message.model';
import { LockRepriceRequest } from '../../models/pricing/lock-reprice-request.model';
import { LosCredentials } from '../../models/pricing/los-credentials.model';
import { PriceCell } from '../../models/pricing/price-cell.model';
import { Price, PricedProductRow } from '../../models/pricing/priced-product-row.model';
import { PricingFieldSpec } from '../../models/pricing/pricing-field-spec.model';
import { Adjustment, Quote } from '../../models/pricing/pricing-quote.model';
import { PricingRunResult } from '../../models/pricing/pricing-run-result.model';
import { PricingScenario } from '../../models/pricing/pricing-scenario.model';
import { PricedProduct } from '../../models/pricing/product-pricing-detail.model';
import { LoCompensationInPricing, PricingSearchMode, ProductSearchRequest, ProductSearchRequestInfo } from '../../models/pricing/product-search-request-info.model';
import { BaseProduct, Product, ProductCalculatedValues, VendorFeatureRequest } from '../../models/pricing/product-search-result.model';
import { PricingRateCreditApplicationMethod } from '../../models/pricing/rate.model';
import { SelectedPriceCardViewModel } from '../../models/pricing/selected-price-card-view.model';
import { QuickPriceHistoryComponent } from '../../modules/quick-price-history/quick-price-history.component';
import { PricingUtils } from '../../pricing-utils';
import { PricingConfigurationService } from '../../services/pricing-configuration.service';
import { PricingService } from '../../services/pricing.service';
import { CreateScenarioNameDialogComponent } from '../edit-scenario-name-dialog/create-scenario-name-dialog.component';
import { IneligibleProductDetailDialogComponent } from '../ineligible-product-detail-dialog/ineligible-product-detail-dialog.component';
import { InitialFeesWorksheetDialog } from '../initial-fees-worksheet-dialog/initial-fees-worksheet-dialog.component';
import { PinnedScenariosComponent } from '../pinned-scenarios/pinned-scenarios.component';
import { PricingTransactionType } from '../pricing-details/pricing-details.component';
import { PricingParametersComponent } from '../pricing-parameters/pricing-parameters.component';
import { PricingScenarioChangesDialogComponent } from '../pricing-scenario-changes-dialog/pricing-scenario-changes-dialog.component';
import { ProductDetailDialogComponent } from '../product-detail-dialog/product-detail-dialog.component';
import { ProductPricePinEvent, ProductSearchResultsComponent } from '../product-search-results/product-search-results.component';
import { QmDetailsDialogComponent } from '../qm-details-dialog/qm-details-dialog.component';
import { RepriceLockChangesDialogComponent } from '../reprice-lock-changes-dialog/reprice-lock-changes-dialog.component';
import { RequestLockDialogComponent } from '../request-lock-dialog/request-lock-dialog.component';
import { PricingScenarioFieldChange } from '../../models/pricing/pricing-scenario-field-change.model';

export enum PricingSearchViewType {
  Grid = 1,
  Tabular = 2,
  Iframe = 3
}

export function mergeCustomizer(objValue: any, srcValue: any) {
  if (objValue instanceof Array || srcValue instanceof Array) {
    if (objValue) {
      if (_.isArray(objValue) && objValue.length === 0) {
        if (_.isArray(srcValue) && srcValue.length > 0) {
          return srcValue;
        }
      }
      return objValue;
    }
  } else if (objValue instanceof Object || srcValue instanceof Object) {
    return _.mergeWith(objValue, srcValue, mergeCustomizer);
  }
  if (objValue != null) {
    return objValue;
  }
  return srcValue;
}

@Component({
  selector: 'pricing-search-v2',
  templateUrl: './pricing-search-v2.component.html',
  styleUrls: ['./pricing-search-v2.component.scss']
})
export class PricingSearchV2Component implements OnInit {

  @ViewChild('quickPricerHistory')
  quickPricerHistory: QuickPriceHistoryComponent | undefined;

  @ViewChild('pricingParameters')
  pricingParameters: PricingParametersComponent | undefined;

  @ViewChild('searchResults')
  searchResults: ProductSearchResultsComponent | undefined;

  @ViewChild("pinnedScenarios")
  pinnedScenariosComponent: PinnedScenariosComponent | undefined;

  @Input()
  viewType: PricingSearchViewType = PricingSearchViewType.Grid;

  @Input()
  showScenarios: boolean = true;

  @Input()
  pricingScenarios: PricingScenario[] = [];

  @Input()
  canPriceApplyToLoan: boolean;

  @Input()
  enableChannelSelection: boolean = false;

  @Input()
  lockStatus: string;

  @Input()
  transactionType: PricingTransactionType = null;

  @Input()
  currentApplication: LoanApplication | null;

  @Input()
  requestLockEnabled: boolean = false;

  @Input()
  currentMortgage: Mortgage | null;

  @Input()
  set productSearchRequestInfo(productSearchRequestInfo: ProductSearchRequestInfo) {
    if (!productSearchRequestInfo) {
      return;
    }
    this._productSearchRequestInfo = productSearchRequestInfo;
    if (this.transactionType && this.transactionType == PricingTransactionType.reprice) {
      productSearchRequestInfo.request.productIds = [this.currentApplication.productPricing.pricingVendorProductId];
      productSearchRequestInfo.request.desiredRate = this.currentApplication.productPricing.rate / 100;
    }

    this._productSearchRequest = productSearchRequestInfo.request;
  }

  get productSearchRequestInfo(): ProductSearchRequestInfo {
    return this._productSearchRequestInfo;
  }

  @Output()
  productPriceSelectedForApplicationFinished: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  productRepriceSelectedForApplicationFinished: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  scenariosAdded: EventEmitter<PricingScenario[]> = new EventEmitter<PricingScenario[]>();

  @Output()
  scenarioDeleted: EventEmitter<PricingScenario[]> = new EventEmitter<PricingScenario[]>();

  @Output()
  onRefreshAllFinished: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  scenariosSectionOpeningChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  tab: 'pricingParameters' | 'pricingResults' | 'pricingScenarios' = 'pricingParameters';

  businessChannelId: number | string = 0;

  enabledChannels: EnabledBusinessChannel[] = [];

  allEnabledBusinessChannelPerVendor: any;

  pricesByProductId: Map<string, ProductPricingDetails> = new Map<string, ProductPricingDetails>();

  pricingRunResult: PricingRunResult | undefined;
  pricingRunRequest: ProductSearchRequest | undefined;

  isSubmitted: boolean = false;
  isSearching: boolean = false;

  filteredLosProviders: LosCredentials[] = [];

  customFields: CustomField[] = [];

  qualifiedMortgageDetails: PricedProduct;

  qualifiedMortgageDetailsProducts: PricedProduct;

  existingPinnedPricesByScenario: Scenario[] = [];

  pricedScenarios: PricedScenario[] = [];

  isScenariosSectionOpen: boolean = false;

  selectedVendorName: string;

  visibleFeatureFields: string[] = [];

  parameterPanelFullScreen: boolean = false;
  isTpoUser: boolean = false;
  loanpassUrl: string = "";
  vendorSelection: string;
  fieldSpecs: PricingFieldSpec[];
  showLoanPassIFrame: boolean = false;
  showLenderPriceIFrame: boolean = false;
  hideQmColumn: boolean = false;

  protected isShowVendorsPanel: boolean = false;

  protected isQuickPriceHistoryCollapsed: boolean = true;

  private _modalOptions: NgbModalOptions;

  private _ineligibleProducts: Product[] = [];

  private _eligibleProducts: Product[] = [];

  private _pricedScenarios: Map<string, PricedScenario> = new Map<string, PricedScenario>();

  private _defaultFees: LoanFee[] = [];

  private _defaultProductSearchRequestInfo: ProductSearchRequestInfo;
  private _productSearchRequestInfo: ProductSearchRequestInfo;

  private _originalProductSearchRequest: ProductSearchRequest;
  private _productSearchRequest: ProductSearchRequest;

  private _upfrontCosts: UpfrontCosts;

  private get totalLoanAmount(): number {
    const totalLoanAmount = this._productSearchRequest.loanInformation.baseLoanAmount +
      (this._productSearchRequest.loanInformation.upfrontPmiMipFfGfFinancedAmount
        ? this._productSearchRequest.loanInformation.upfrontPmiMipFfGfFinancedAmount
        : 0);
    return totalLoanAmount;
  }

  private _loadedViewType: PricingSearchViewType;
  private _appContext: ApplicationContext;
  private _contextSubscription: Subscription;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _pricingService: PricingService,
    private readonly _pricingConfigService: PricingConfigurationService,
    private readonly _loanPassfieldMappingService: LoanPassFieldMappingsService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notifsService: NotificationService,
    private readonly _feesService: FeeService,
    private readonly _losService: LosService,
    private readonly _pricingCustomFieldService: PricingCustomFieldService,
    private readonly _applicationContextService: ApplicationContextService,
    public router: Router
  ) {
    this._modalOptions = {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      scrollable: true,
    };
    this._contextSubscription = this._applicationContextService.context.subscribe(context => {
      this._appContext = context;
      this._loadedViewType = this.viewType;
      this.isTpoUser = context.isTpo;
      this.decideToShowVendorPickerOrNot(context);
    });
  }

  private initialize = () => {
    if (this.viewType != null) {
      if (this.currentApplication) {
        // Ok? Now, I can default some fees from the loan!!!
        this._feesService.getFees(this.currentApplication.applicationId).subscribe(fees => {
          this._defaultFees = fees.map(f => {
            f.applicationId = null;
            return f;
          })
        }, err => {
          this._spinnerService.hide();
          this._notifsService.showError(err.error.message || 'Errors have been detected!', 'Failure');
        });
      }

      this._defaultProductSearchRequestInfo = this._pricingService.getDefaultPricingRequest();

      if (this.currentMortgage) {
        this._pricingService.getMortgagePricingRequest(this.currentMortgage.mortgageId).subscribe(
          (response) => {
            this.copyProductSearchCriteriFromDefaults(response);
            this.productSearchRequestInfo = _.merge(this._defaultProductSearchRequestInfo, response);
            this._productSearchRequest = this.productSearchRequestInfo.request;

            if (!this._productSearchRequest.desiredRate && this.currentMortgage.mortgageTerm.interestRate) {
              this._productSearchRequest.desiredRate = this.currentMortgage.mortgageTerm.interestRate / 100;
              this._productSearchRequest.desiredPrice = null;
            }
            else {
              this._productSearchRequest.desiredPrice = 100;
            }
            if (this._productSearchRequest.loanLevelDebtToIncomeRatio) {
              if (this._productSearchRequest.loanLevelDebtToIncomeRatio >= 999) {
                this._productSearchRequest.loanLevelDebtToIncomeRatio = 0.01;
              } else {
                this._productSearchRequest.loanLevelDebtToIncomeRatio = this._productSearchRequest.loanLevelDebtToIncomeRatio / 100;
              }
            }
            if (this._productSearchRequest.loanInformation && this._productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent) {
              this._productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = this._productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent / 100;
            }

            if (!this._productSearchRequest.loanInformation.taxesAndInsuranceMonthly) {
              //this._productSearchRequest.taxesAndInsurance = ??? TODO: ayilmaz, what to set this to from mortgage model?
            }

            if (this._productSearchRequest.loanInformation.waiveEscrows == null) {
              this._productSearchRequest.loanInformation.waiveEscrows = false;
            }

            this._originalProductSearchRequest = _.cloneDeep(this._productSearchRequest);
            this._productSearchRequest.loanInformation.ltv = this.calculateLtv(this._productSearchRequest);
            if (
              this._productSearchRequest.loanInformation.secondLienAmount == 0 ||
              !this._productSearchRequest.loanInformation.secondLienAmount
            ) {
              this._productSearchRequest.loanInformation.cltv = this._productSearchRequest.loanInformation.ltv;
            } else {
              //this._productSearchRequest.loanInformation.cltv = this._productSearchRequest.loanInformation.cltv / 100;
            }
          },
          (err) => {
            this._notifsService.showError(
              err.message || 'Unable to get Mortgage Pricings',
              'Error!'
            );
          }
        );
      }
      if (this.viewType !== PricingSearchViewType.Iframe) {
        this.loadBusinessChannels();
      }
    }
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy(): void {
    if (this._contextSubscription) {
      this._contextSubscription.unsubscribe();
    }
  }

  changeVendorsClicked = () => {
    this.decideToShowVendorPickerOrNot(this._appContext);
  }

  onQuickPriceHistoryAccordionClicked = () => {
    this.isQuickPriceHistoryCollapsed = !this.isQuickPriceHistoryCollapsed;
  }

  private decideToShowVendorPickerOrNot = (context: ApplicationContext) => {
    const pricingEngines = context.userPermissions.enabledPricingEngines;
    this.vendorSelection = null;
    this.isShowVendorsPanel = false;
    this.viewType = this._loadedViewType;
    if (pricingEngines.length === 1) {
      this.vendorSelection = 'Others';
      const iframeOne = pricingEngines.find(e => e === PricingVendor.LenderPriceIframe || e === PricingVendor.LoanPassIframe);
      if (iframeOne) {
        this.vendorSelection = iframeOne;
        this.viewType = PricingSearchViewType.Iframe;
      }
    } else {
      const hasAtLeastOneIFrame = pricingEngines.filter(e => e === PricingVendor.LenderPriceIframe || e === PricingVendor.LoanPassIframe).length > 0;
      if (hasAtLeastOneIFrame) {
        this.showLenderPriceIFrame = pricingEngines.filter(e => e === PricingVendor.LenderPriceIframe).length > 0;
        this.showLoanPassIFrame = pricingEngines.filter(e => e === PricingVendor.LoanPassIframe).length > 0;
        this.viewType = null;
        this.isShowVendorsPanel = true;
      }
    }
  }

  onPricingVendorSelectionChanged = () => {
    if (this.vendorSelection == 'LoanPassIframe' || this.vendorSelection == 'LenderPriceIframe') {
      this.viewType = PricingSearchViewType.Iframe;
    } else if (this.vendorSelection == 'Others') {
      this.viewType = this._loadedViewType;
    }

    this.initialize();
  }

  selectTab = (tab: any) => {
    this.tab = tab;
  }

  getPricingParameterHeight = (): string => {
    if (this.isTpoUser && this.parameterPanelFullScreen) {
      return '72vh';
    } else if (this.currentApplication?.applicationId) {
      return '75vh';
    } else {
      return '70vh';
    }
  }

  refreshAllScenarios = () => {
    if (this.pinnedScenariosComponent) {
      this.pinnedScenariosComponent.refreshAllScenarios();
    }
  }

  onRefreshAllScenariosFinished = () => {
    this.onRefreshAllFinished.emit();
  }

  onCreateToScenariosRequested = (request: { product: BaseProduct, price: Price, pricedScenario: PricedScenario }) => {
    const modalRef = this._modalService.open(CreateScenarioNameDialogComponent, Constants.modalOptions.medium);

    let scenarioName: string = "";
    modalRef.result.then((newName: string) => {
      scenarioName = newName;
      this.startCreatingProcess(scenarioName, request.product, request.price, request.pricedScenario);
    }, () => {

    });
  }

  onShowLoanQuoteClickedForPinnedPrice = (request: { product: BaseProduct, price: Price, pricedScenario: PricedScenario }) => {
    const selectedSearchId = request.pricedScenario.searchId;
    let searchRequest = this.fixPercentageValues(request.pricedScenario.pricingScenario);
    searchRequest.thirdPartySearchId = selectedSearchId;
    if (this.transactionType && this.transactionType == PricingTransactionType.reprice) {
      searchRequest.productIds = [request.product.productId];
    }
    searchRequest.optimalBlueChannelId = this.businessChannelId as number;

    let price = request.product?.quotes?.find(q => q.adjustedRate === request.price.rate && q.lockPeriod === request.price.lockPeriod);
    if (price) {

      //need to add fees to show in quote
      if (this.currentApplication) {
        var discFee = this._defaultFees.find(x => x.feeType == FeeTypeEnum.LoanDiscountPoints);
        if (!discFee) {
          discFee = new LoanFee();
          discFee.feeSection = FeeSectionEnum.Origination;
          discFee.feeType = FeeTypeEnum.LoanDiscountPoints;
          discFee.calculatedFeeType = CalculatedFeeTypeEnum.Default;
          discFee.hudNumber = "802e";
        }
        if (request.price.discountDollars > 0) {
          discFee.borrowerFeeDollar = request.price.discountDollars;
          discFee.borrowerFeePercent = request.price.discountPercent;
          discFee.calculatedValues.totalFee = request.price.discountDollars;
        }
        else if (request.price.rebateDollars > 0) {
          discFee.borrowerFeeDollar = 0 - request.price.rebateDollars;
          discFee.borrowerFeePercent = 0 - request.price.rebatePercent;
          discFee.calculatedValues.totalFee = 0 - request.price.rebateDollars;
        }
      }
      const scenarioToShowLoanQuoteFor = this.createScenarioFromPinnedPrice(request.product.productId, price, searchRequest);
      const modalRef = this._modalService.open(InitialFeesWorksheetDialog, Constants.modalOptions.xlarge);
      modalRef.componentInstance.scenario = scenarioToShowLoanQuoteFor;
      modalRef.componentInstance.fees = this._defaultFees;
    } else {
      this._spinnerService.show();
      this._pricingService.getProductPricingDetails(this.pricingRunResult.pricingVendor, searchRequest)
        .subscribe((pricedProduct: PricedProduct) => {
          const quotes = pricedProduct?.quotes?.filter(
            (q) => q.adjustedRate > 0 && q.lockPeriod > 0
          );
          const price = quotes.find(q => q.adjustedRate === request.price.rate && q.lockPeriod === request.price.lockPeriod);

          const scenarioToShowLoanQuoteFor = this.createScenarioFromPinnedPrice(request.product.productId, price, searchRequest);
          const modalRef = this._modalService.open(InitialFeesWorksheetDialog, Constants.modalOptions.xlarge);
          modalRef.componentInstance.scenario = scenarioToShowLoanQuoteFor;
        }).add(() => this._spinnerService.hide());
    }
  }

  onAddToScenariosRequested = (pricedScenario: PricedScenario) => {

    this._spinnerService.show();
    const apiCallsToMake: any[] = [];

    pricedScenario.pinnedProducts.forEach(scenarioProduct => {
      scenarioProduct.pinnedPrices.forEach(pinnedPrice => {
        const price = scenarioProduct.product.product.quotes.find(q => q.adjustedRate === pinnedPrice.rate && q.lockPeriod === pinnedPrice.lockPeriod);
        if (price) {
          const scenarioModel = this.createPricingScenario(scenarioProduct.product.product, price, pricedScenario.pricingScenario);
          const apiCall = this._pricingService.createPricingScenario(scenarioModel);
          apiCallsToMake.push(apiCall);
        }
      })
    });
    if (apiCallsToMake.length > 0) {
      forkJoin(apiCallsToMake).subscribe((createdScenarios: PricingScenario[]) => {
        if (createdScenarios) {
          // If NOT quick pricer
          if (this.currentApplication) {
            let feeApiCalls = [];
            createdScenarios.forEach(scenario => {
              const feesToSave = this._defaultFees.map(f => {
                f.loanFeeId = null;
                f.pricingScenarioId = scenario.pricingScenarioId;
                return f;
              });
              const price = pricedScenario.pinnedProducts.find(x => x.product.productId == scenario.productId)?.product?.product.quotes.find(q => q.adjustedRate === scenario.adjustedRate && q.lockPeriod === scenario.lockTerm);
              if (price) {
                var discFee = feesToSave.find(x => x.feeType == FeeTypeEnum.LoanDiscountPoints);
                if (!discFee) {
                  discFee = new LoanFee();
                  discFee.feeSection = FeeSectionEnum.Origination;
                  discFee.feeType = FeeTypeEnum.LoanDiscountPoints;
                  discFee.calculatedFeeType = CalculatedFeeTypeEnum.Default;
                  discFee.hudNumber = "802e";
                  feesToSave.push(discFee);
                }
                if (price.discountDollars > 0) {
                  discFee.borrowerFeeDollar = price.discountDollars;
                  discFee.borrowerFeePercent = price.discountPercent;
                  discFee.calculatedValues.totalFee = price.discountDollars;
                }
                else if (price.rebateDollars > 0) {
                  discFee.borrowerFeeDollar = 0 - price.rebateDollars;
                  discFee.borrowerFeePercent = 0 - price.rebatePercent;
                  discFee.calculatedValues.totalFee = 0 - price.rebateDollars;
                }
              }
              else
                console.error("Missing price");

              const feeApiCall = this._feesService.saveScenarioFees(scenario.pricingScenarioId, feesToSave);
              feeApiCalls.push(feeApiCall);
            });
            if (feeApiCalls.length) {
              forkJoin(feeApiCalls).subscribe(() => {
                this.onScenariosAdded(createdScenarios);
                this._notifsService.showSuccess('Pinned Scenarios have been saved!', 'Success');
              }, error => {
                this._notifsService.showError(error.error.message || 'Errors have been detected!', 'Failure');
              }).add(() => this._spinnerService.hide());
            }
          } else {
            this.onScenariosAdded(createdScenarios);
            this._spinnerService.hide();
            this._notifsService.showSuccess('Pinned Scenarios have been saved!', 'Success');
          }
        }
      }, (error) => {
        this._spinnerService.hide();
        this._notifsService.showError(error.error.message || 'Errors have been detected!', 'Failure');
      })
    }
  }

  onPriceUnpinned = (e: ProductPricePinEvent) => {

    let currentScenario: PricedScenario;
    if (e.scenarioKey) {
      this._pricedScenarios.forEach(scenario => {
        if (e.scenarioKey == scenario.scenarioKey) {
          currentScenario = scenario;
        }
      })
    } else {
      const currentScenarioKey = sha1(JSON.stringify(this.productSearchRequestInfo.request)).toString();
      currentScenario = this._pricedScenarios.get(currentScenarioKey);
    }

    if (!currentScenario) {
      currentScenario = (Array.from(this._pricedScenarios).pop())[1];
    }

    const alreadyPinnedProduct = currentScenario.pinnedProducts.find(p => p.product.productId == e.product.product.productId);
    if (alreadyPinnedProduct) {
      const alreadyPinnedPriceIndex = alreadyPinnedProduct.pinnedPrices.findIndex(p => p.rate == e.price.rate && p.lockPeriod == e.price.lockPeriod);
      if (alreadyPinnedPriceIndex >= 0) {
        alreadyPinnedProduct.pinnedPrices.splice(alreadyPinnedPriceIndex, 1);
        if (e.scenarioKey == sha1(JSON.stringify(this.productSearchRequestInfo.request)).toString()) {
          this.searchResults.unpinPrice(e.price.lockPeriod, e.price.rate);
        }
      }
      if (alreadyPinnedProduct.pinnedPrices.length === 0) {
        const alreadyPinnedProductIndex = currentScenario.pinnedProducts.indexOf(alreadyPinnedProduct);
        if (alreadyPinnedPriceIndex >= 0) {
          currentScenario.pinnedProducts.splice(alreadyPinnedProductIndex, 1);
        }
      }
    }
    this.pricedScenarios = _.cloneDeep(Array.from(this._pricedScenarios.values()));
  }

  onPricePinned = (e: ProductPricePinEvent) => {
    const currentScenarioKey = sha1(JSON.stringify(this.productSearchRequestInfo.request)).toString();
    const currentScenario = this._pricedScenarios.get(currentScenarioKey);
    if (!currentScenario) {
      // swal error and return
      Swal.fire({
        title: 'Warning',
        icon: 'warning',
        text: 'The scenario has changed. You cannot pin pricing before you perform a new search.',
        confirmButtonText: 'OK'
      }).then((result: SweetAlertResult) => {
        this.searchResults.unpinLastPin();
        if (!result.value) return;
      });
    } else {
      const alreadyPinnedProduct = currentScenario.pinnedProducts.find(p => p.product.productId == e.product.productId);
      if (alreadyPinnedProduct) {
        const alreadyPinnedPrice = alreadyPinnedProduct.pinnedPrices.find(p => p.rate == e.price.rate && p.lockPeriod == e.price.lockPeriod);
        if (!alreadyPinnedPrice) {
          alreadyPinnedProduct.pinnedPrices.push(_.clone(e.price));
        }
      } else {
        const productToPin = new PinnedProduct(e.product);
        currentScenario.pinnedProducts.push(productToPin);
        currentScenario.scenarioKey = currentScenarioKey;
        currentScenario.searchId = this.pricingRunResult.searchId;

        productToPin.pinnedPrices.push(_.clone(e.price));
      }
      this.pricedScenarios = _.cloneDeep(Array.from(this._pricedScenarios.values()));
    }
  }

  onRunQuickPriceSearchFromHistoryItemClicked = (searchRequest: ProductSearchRequest) => {
    if (searchRequest.desiredRate) {
      searchRequest.desiredRate = parseFloat((searchRequest.desiredRate / 100).toFixed(3));
    }
    if (searchRequest.borrowerInformation.debtToIncomeRatio) {
      searchRequest.borrowerInformation.debtToIncomeRatio = parseFloat((searchRequest.borrowerInformation.debtToIncomeRatio / 100).toFixed(3));
    }
    if (searchRequest.loanLevelDebtToIncomeRatio) {
      searchRequest.loanLevelDebtToIncomeRatio = parseFloat((searchRequest.loanLevelDebtToIncomeRatio / 100).toFixed(3));
    }
    if (searchRequest.loanInformation.upfrontPmiMipFfGfPercent) {
      searchRequest.loanInformation.upfrontPmiMipFfGfPercent = parseFloat((searchRequest.loanInformation.upfrontPmiMipFfGfPercent / 100).toFixed(3));
    }
    if (searchRequest)
      this.productSearchRequestInfo.request = searchRequest;
    this.productSearchRequestInfo = { ...this.productSearchRequestInfo };
    this.pricingRunResult = undefined;
    this.isQuickPriceHistoryCollapsed = true;
  }

  onSubmitClicked = () => {
    this.pricingRunResult = undefined;
    if (this.pricingParameters) {
      const valid = this.pricingParameters.validate();
      if (valid) {
        this.isSubmitted = true;
        this.productSearchRequestInfo.request.optimalBlueChannelId = this.businessChannelId as number;
        if (this.selectedVendorName !== 'OptimalBlue' && (
          !this.productSearchRequestInfo.request.credentialId || !this.productSearchRequestInfo.request.pricingChannelId)) {
          this.productSearchRequestInfo.request.credentialId = this.pricingParameters.selectedProfileChannel.credentialId;
          this.productSearchRequestInfo.request.pricingChannelId = this.pricingParameters.selectedProfileChannel.pricingChannelId;
        }
        if (this.currentApplication?.channel === Channel.Correspondent) {
          this.productSearchRequestInfo.request.loanInformation.includeFeesInBasePrice = null;
          this.productSearchRequestInfo.request.compInformation.includeLoCompensationInPricing = null;
          this.productSearchRequestInfo.request.compInformation.percent = null;
          this.productSearchRequestInfo.request.compInformation.percentBasedOn = null;
        }
        this.pricesByProductId.clear();

        const request = this.fixPercentageValues(this.productSearchRequestInfo.request);
        this._spinnerService.show();
        this.isSearching = true;
        if (this.currentApplication) {
          request.applicationId = this.currentApplication.applicationId;
        }
        if (this.pricingParameters.multipleLoanTypesVisible) {
          request.loanInformation.loanType = null;
        } else {
          request.loanTypes = null;
        }
        if (!request.propertyInformation.projectType && request.propertyInformation.isNonWarrantableProject) {
          request.propertyInformation.isNonWarrantableProject = false;
        }
        if (!this.visibleFeatureFields.includes('Loan_DocumentationType')) {
          request.loanInformation.documentationType = null;
        }
        if (request.compInformation.includeLoCompensationInPricing !== LoCompensationInPricing.BorrowerPaid) {
          request.compInformation.percent = null;
          request.compInformation.percentBasedOn = null;
        }

        this.setPricingSearchMode(request);

        this._pricingService.searchProductsByVendor(this.selectedVendorName, request).subscribe((productsResponse) => {

          if (!this.canPriceApplyToLoan) {
            this.quickPricerHistory?.refresh();
          }

          this._upfrontCosts = {
            totalLoanAmount: productsResponse.calculatedTotalLoanAmount,
            pmiMipFfGfAmount: productsResponse.calculatedUpfrontPmiMipFfGfAmount,
            pmiMipFfGfFinancedAmount: productsResponse.calculatedUpfrontPmiMipFfGfFinancedAmount,
            pmiMipFfGfPaidinCash: productsResponse.calculatedUpfrontPmiMipFfGfPaidinCash,
            pmiMipFfGfPercent: productsResponse.calculatedUpfrontPmiMipFfGfPercent
          };

          const scenarioKey = sha1(JSON.stringify(this.productSearchRequestInfo.request)).toString();
          if (!this._pricedScenarios.has(scenarioKey)) {
            const pricedScenario = new PricedScenario(_.cloneDeep(this.productSearchRequestInfo.request));
            this._pricedScenarios.set(scenarioKey, pricedScenario);
          }
          // If they ask that the pins reflect the live prices, get rid of the cloneDeep below!
          this.pricedScenarios = Array.from(this._pricedScenarios.values());

          this._eligibleProducts = productsResponse.products.filter(product => product.isEligible && product.quotes?.length);
          let eligibleProductRows: PricedProductRow[] = [];
          let ineligibleProductRows: PricedProductRow[] = [];
          if (this._eligibleProducts) {
            this._eligibleProducts.forEach((p) => {
              const prices = [
                new Price(
                  p.quotes[0].adjustedRate,
                  p.quotes[0].adjustedPrice,
                  p.quotes[0].lockPeriod,
                  p.quotes[0].principalAndInterest,
                  p.quotes[0].monthlyMi,
                  this.productSearchRequestInfo.request.loanInformation.taxesAndInsuranceMonthly,
                  p.quotes[0].discountPercent,
                  p.quotes[0].discountDollars,
                  p.quotes[0].rebatePercent,
                  p.quotes[0].rebateDollars,
                ),
              ];
              eligibleProductRows.push(
                new PricedProductRow(
                  p,
                  prices,
                  true,
                  this.totalLoanAmount,
                  p.priceStatus
                )
              );

              if (this.selectedVendorName == 'MeridianLink') {
                const priceCells = p.quotes.map(
                  (q) =>
                    new PriceCell(
                      q.adjustedRate,
                      q.adjustedPrice,
                      q.lockPeriod,
                      q.principalAndInterest,
                      q.monthlyMi,
                      q.discountDollars,
                      q.discountPercent,
                      q.rebateDollars,
                      q.rebatePercent,
                      q.totalClosingCost,
                      q.totalPayment,
                      q.adjustments
                    )
                );
                let pricesForProduct = new ProductPricingDetails(
                  priceCells,
                  [],
                  p.quotes[0].adjustments,
                  p.calculatedValues
                );
                this.pricesByProductId.set(p.productId, pricesForProduct);
              }
            });
          }
          this._ineligibleProducts = productsResponse.products.filter(product => !product.isEligible || !product.quotes?.length);
          if (this._ineligibleProducts) {
            this._ineligibleProducts.forEach(
              (p) => {
                ineligibleProductRows.push(
                  new PricedProductRow(
                    p,
                    [],
                    false,
                    this.totalLoanAmount,
                    p.priceStatus
                  )
                );
              }
            );
          }

          this.pricingRunResult = new PricingRunResult(
            eligibleProductRows,
            ineligibleProductRows,
            productsResponse.searchId,
            productsResponse.pricingVendor || this.selectedVendorName
          );

          this.pricingRunRequest = request;

          this.isSubmitted = false;
          if (this.viewType == 2) {
            this.selectTab("pricingResults");
          }
        },
          (error) => {
            this.isSubmitted = false;
            let errorMessage = "Unable to search Products";
            if (error) {
              if (typeof error === 'string') {
                errorMessage = error;
              } else {
                errorMessage = error.message || error.error;
              }
            }
            this._notifsService.showError(
              errorMessage,
              'Error!'
            );
          }
        ).add(() => { this.parameterPanelFullScreen = false; this._spinnerService.hide(); this.isSearching = false; });
      }
    }
  };

  onProductPriceSelectedForApplication = async (selection: any) => {
    //in case of pinned scenario the selection parameter is going to have the PricingScenario that was clicked.
    const selectedPricingScenario = selection.pricingScenario || this._productSearchRequest;
    const changes = PricingUtils.findMortgageModelChanges(selectedPricingScenario, this._originalProductSearchRequest);
    if (changes.length > 0 && this.vendorSelection && this.vendorSelection == PricingEngineVendor.LoanPassIframe) {
      this.adjustChangesForLoanPassIframe(selectedPricingScenario, this._originalProductSearchRequest, changes);
    }
    const modalRef = this._modalService.open(
      PricingScenarioChangesDialogComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.hasLenderCredit = selection.price.price > 100;
    modalRef.componentInstance.currentApplication = this.currentApplication;
    modalRef.componentInstance.changes = changes;
    modalRef.result.then(
      (selectedCreditMethod: PricingRateCreditApplicationMethod) => {
        this.onProductPriceSelectedForApplicationPrivate(selection, selectedCreditMethod);
      },
      () => { }
    );
  };

  onProductPriceRepricedForApplication = async (selection: any) => {
    this.onProductRepriceSelectedForApplicationPrivate(true, selection, true, (res) => {
      const modalRef = this._modalService.open(
        RepriceLockChangesDialogComponent,
        Constants.modalOptions.large
      );
      modalRef.componentInstance.currentApplication = this.currentApplication;
      modalRef.componentInstance.selection = selection;
      modalRef.componentInstance.data = res;
      modalRef.result.then(
        () => {
          this.onProductRepriceSelectedForApplicationPrivate(true, selection, false);
        },
        () => { }
      );
    });
  }

  onProductPriceProgramChangeForApplication = async (selection: any) => {
    this.onProductRepriceSelectedForApplicationPrivate(false, selection, true, (res) => {
      const modalRef = this._modalService.open(
        RepriceLockChangesDialogComponent,
        Constants.modalOptions.large
      );
      modalRef.componentInstance.currentApplication = this.currentApplication;
      modalRef.componentInstance.selection = selection;
      modalRef.componentInstance.data = res;
      modalRef.componentInstance.isReprice = false;
      modalRef.result.then(
        () => {
          this.onProductRepriceSelectedForApplicationPrivate(false, selection, false);
        },
        () => { }
      );
    });
  }

  onPriceLockClicked = (data: LoanPassIframeLockPriceMessage) => {
    let req = new LoanPassExecuteRequest();
    req.productId = data.product.id.toString();
    req.pricingProfileId = data.pricingProfile.id.toString();
    req.currentTime = DateTime.now().toISO();
    req.creditApplicationFields = data.creditApplicationFields;

    this._spinnerService.show();
    this._loanPassfieldMappingService.convertExecuteRequest(data.credentialId, req).subscribe({
      next: (request) => {
        this._productSearchRequest = request;

        this._productSearchRequest.credentialId = data.credentialId;

        const selection = {
          productId: data.product.id,
          price: {
            rate: data.scenario.adjustedRate,
            price: data.scenario.adjustedPrice,
            selecetedMiQuote: null,
            lockPeriod: data.scenario.adjustedRateLockPeriod.count
          }
        }

        if (!this.transactionType) {
          this.onProductPriceSelectedForApplication(selection);
        } else {
          if (this.transactionType == PricingTransactionType.reprice) {
            this.onProductPriceRepricedForApplication(selection);
          } else if (this.transactionType == PricingTransactionType.programChange) {
            this.onProductPriceProgramChangeForApplication(selection);
          }
        }

      },
      error: (e) => {
        this._notifsService.showError(`Error converting execute request. Details: ${e.message}`, 'Error!');
      }
    })
      .add(() => this._spinnerService.hide());
  }

  private adjustChangesForLoanPassIframe = (productSearchRequest: ProductSearchRequest, originalProductSearchRequest: ProductSearchRequest,
    changes: PricingScenarioFieldChange[]) => {
    if (changes.length > 0) {
      const indexOfZipcode = changes.findIndex(x => x.fieldName == "Zip Code");
      if (indexOfZipcode > -1) {
        changes.splice(indexOfZipcode, 1);
      }

      const indexOfWaiveEscrows = changes.findIndex(x => x.fieldName == "Waive Escrows");
      if (indexOfWaiveEscrows > -1) {
        if (!originalProductSearchRequest.loanInformation.waiveEscrows && !productSearchRequest.loanInformation.waiveEscrows) {
          changes.splice(indexOfWaiveEscrows, 1);
        }
      }

      const indexOfFirstTimeHomeBuyer = changes.findIndex(x => x.fieldName == "First Time Buyer");
      if (indexOfWaiveEscrows > -1) {
        if (!originalProductSearchRequest.borrowerInformation.firstTimeHomeBuyer && !productSearchRequest.borrowerInformation.firstTimeHomeBuyer) {
          changes.splice(indexOfFirstTimeHomeBuyer, 1);
        }
      }

      const indexOfSelfEmployed = changes.findIndex(x => x.fieldName == "First Time Buyer");
      if (indexOfWaiveEscrows > -1) {
        if (!originalProductSearchRequest.borrowerInformation.selfEmployed && !productSearchRequest.borrowerInformation.selfEmployed) {
          changes.splice(indexOfSelfEmployed, 1);
        }
      }

      const indexOfNoOfUnits = changes.findIndex(x => x.fieldName == "No of Units");
      if (indexOfNoOfUnits > -1) {
        if (originalProductSearchRequest.propertyInformation.numberOfUnits == "OneUnit" && !productSearchRequest.propertyInformation.numberOfUnits) {
          changes.splice(indexOfNoOfUnits, 1);
        }
      }
    }
  }

  private autoCreateLosLoanAndSelectPricing = async (selection: any, selectedCreditMethod: PricingRateCreditApplicationMethod): Promise<boolean> => {
    try {
      const losAppOpResult = await firstValueFrom(
        this._losService.autoCreateLosLoan(this.currentApplication.applicationId),
      );
      this._applicationContextService.updateMortgageAndApplication(losAppOpResult.mortgage, losAppOpResult.application, losAppOpResult.customData);
      return true;
    } catch (e) {
      console.error(e);
      this._notifsService.showError(`Error creating your loan to apply pricing. Details: ${e.message}`, 'Error!');
      return false;
    }
  }

  private onProductPriceSelectedForApplicationPrivate = async (selection: any, selectedCreditMethod: PricingRateCreditApplicationMethod) => {
    if (this.isTpoUser && this.currentApplication?.applicationId && (this.selectedVendorName == "Polly" || this.selectedVendorName == "MeridianLink") && !this.currentApplication.losIdentifier) {
      this._spinnerService.show();
      var createResult = await this.autoCreateLosLoanAndSelectPricing(selection, selectedCreditMethod);
      if (createResult == false) {
        this._spinnerService.hide();
        return;
      }
    }

    let assignedProductPrice = new AssignedProductPrice();

    const selectedPricingScenario = selection.pricingScenario || this._productSearchRequest;
    const productSearchRequest = this.fixPercentageValues(selectedPricingScenario);

    const selectedVendor = (this.vendorSelection == "Others" || !this.vendorSelection) ? this.selectedVendorName : this.vendorSelection;

    assignedProductPrice.request = productSearchRequest;
    assignedProductPrice.productId = selection.productId;
    assignedProductPrice.rate = selection.price.rate;
    assignedProductPrice.price = selection.price.price;
    assignedProductPrice.miQuote = selection.price.selectedMiQuote;
    assignedProductPrice.lockTerm = selection.price.lockPeriod;
    assignedProductPrice.pricingEngineVendor = selectedVendor;
    assignedProductPrice.channelId = this.businessChannelId as number;
    assignedProductPrice.rateCreditApplicationMethod = selectedCreditMethod

    this._spinnerService.show();
    this._pricingService
      .assignProductPriceToLoan(
        this.currentApplication?.applicationId,
        assignedProductPrice
      )
      .subscribe({
        next: (result) => {
          this._applicationContextService.reloadApplicationAndMortgagePostAction(this.currentApplication?.applicationId).subscribe(context => {
            this.productPriceSelectedForApplicationFinished.emit();
            this._spinnerService.hide();
          });
        },
        error: (err) => {
          this._spinnerService.hide();
          this._notifsService.showError(
            err
              ? (err.message || err.error)
              : 'Unable to assign product/price to application.',
            'Error!'
          );
          this._applicationContextService.applicationTrackingStatusesChanged();
        }
      });
  }

  private onProductRepriceSelectedForApplicationPrivate = (isReprice: boolean, selection: any, previewChanges: boolean, cb?: Function) => {
    let assignedProductPrice = new LockRepriceRequest();

    const selectedPricingScenario = selection.pricingScenario || this._productSearchRequest;
    const productSearchRequest = this.fixPercentageValues(selectedPricingScenario);

    assignedProductPrice.productSearchRequest = productSearchRequest;
    assignedProductPrice.productId = selection.product.productId;
    assignedProductPrice.rate = selection.price.rate;
    assignedProductPrice.lockTerm = selection.price.lockPeriod;

    assignedProductPrice.applicationId = this.currentApplication?.applicationId;
    assignedProductPrice.losIdentifier = this.currentApplication?.losIdentifier;
    assignedProductPrice.assignedSearchId = this.searchResults?.searchId;
    assignedProductPrice.productCode = selection.product.product.productCode;
    assignedProductPrice.pmlTemplateId = this.currentApplication?.productPricing?.pmlTemplateId;
    assignedProductPrice.repriceUsingCurrentRate = selection.price.rate == this.currentApplication?.productPricing?.rate;
    assignedProductPrice.skipLosPull = true;
    assignedProductPrice.previewChanges = previewChanges;

    this._spinnerService.show();
    this._pricingService[isReprice ? 'repriceLock' : 'programChange'](this.currentApplication?.applicationId, assignedProductPrice)
      .subscribe({
        next: (result) => {
          if (!result.success) {
            this._spinnerService.hide();

            const messageContent = JSON.parse(result.errorMessage);

            if (messageContent.error) {
              this._notifsService.showError(
                messageContent.detail,
                'Error!'
              );
              return;
            }

            if (messageContent.errors) {
              let str = "";
              Object.keys(messageContent.errors).forEach(f => {
                str += "<tr><td>" + f + "</td><td>";

                const messages = messageContent.errors[f];
                if (messages && messages.length) {
                  str += "<ul>";
                  messages.forEach(m => {
                    str += "<li>" + m + "</li>";
                  });

                  str += "</ul>";
                }
                str += "</td></tr>";
              });

              Swal.fire({
                title: 'Validation Errors',
                icon: 'warning',
                html: `
                <table class="table">
                  <thead>
                    <th>Field</th>
                    <th>Message</th>
                  </thead>
                  <tbody>
                    ${str}
                  </tbody>
                </table>
                `,
                confirmButtonText: 'OK'
              }).then((result: SweetAlertResult) => {
                if (!result.value) return;
              });
              return;
            }
          }

          if (!previewChanges) {
            this._applicationContextService.reloadApplicationAndMortgagePostAction(this.currentApplication?.applicationId).subscribe(context => {
              this._notifsService.showSuccess("Your request has been submitted", isReprice ? 'Reprice Lock' : 'Program Change');
              this.productRepriceSelectedForApplicationFinished.emit(isReprice);
              this._spinnerService.hide();
            });
          }
          else {
            this._spinnerService.hide();
            cb(result);
          }

        },
        error: (err) => {
          this._spinnerService.hide();
          this._notifsService.showError(
            err
              ? (err.message || err.error)
              : 'Unable to reprice product/price to application.',
            'Error!'
          );
          this._applicationContextService.applicationTrackingStatusesChanged();
        }
      });
  }

  onIneligibleProductDetailsClicked = (productId: string) => {
    const ineligibleProduct = this._ineligibleProducts.find(
      (p) => p.productId === productId
    );
    if (ineligibleProduct) {

      if (this.pricingRunResult.pricingVendor == 'LoanPass') {
        this._spinnerService.show();

        const searchRequest = this.fixPercentageValues(this.productSearchRequestInfo.request);
        searchRequest.thirdPartySearchId = this.pricingRunResult.searchId;;
        searchRequest.productIds = [ineligibleProduct.productId];
        searchRequest.optimalBlueChannelId = this.businessChannelId as number;

        this.setPricingSearchMode(searchRequest);

        this._pricingService.getProductPricingDetails(this.pricingRunResult.pricingVendor, searchRequest).subscribe((pricedProduct: PricedProduct) => {
          const modalRef = this._modalService.open(
            IneligibleProductDetailDialogComponent,
            Constants.modalOptions.large
          );
          modalRef.componentInstance.product = pricedProduct;
          modalRef.result.then(
            (product) => { },
            (res) => { }
          );

          this._spinnerService.hide();
        }, (err) => {
          this._spinnerService.hide();
        });
      } else {
        const modalRef = this._modalService.open(
          IneligibleProductDetailDialogComponent,
          Constants.modalOptions.large
        );
        modalRef.componentInstance.product = ineligibleProduct;
        modalRef.result.then(
          (product) => { },
          (res) => { }
        );
      }
    }
  };

  onQualifiedMortgageDetailsClicked = (qmRequest: { productId: string, lockPeriod: number, rate: number, psRequest: ProductSearchRequest }) => {

    let searchRequest = _.cloneDeep(this.fixPercentageValues(qmRequest.psRequest || this._productSearchRequest));
    searchRequest.thirdPartySearchId = this.pricingRunResult.searchId;
    searchRequest.productIds = [qmRequest.productId];
    searchRequest.optimalBlueChannelId = this.businessChannelId as number;
    searchRequest.desiredRate = qmRequest.rate;
    searchRequest.desiredPrice = !qmRequest.rate ? 100 : null;
    searchRequest.desiredLockPeriod = qmRequest.lockPeriod;

    this.setPricingSearchMode(searchRequest);

    this._spinnerService.show();
    this._pricingService.getProductPricingDetails(this.pricingRunResult.pricingVendor, searchRequest).subscribe((qualifiedMortgageDetailsResponse) => {
      this.qualifiedMortgageDetails = qualifiedMortgageDetailsResponse;
      this.qualifiedMortgageDetails.quotes = this.qualifiedMortgageDetails.quotes.filter(x => x.adjustedRate == qmRequest.rate && x.lockPeriod == qmRequest.lockPeriod);
      this._spinnerService.hide();
      const modalRef = this._modalService.open(QmDetailsDialogComponent, this._modalOptions);
      modalRef.componentInstance.productSearchRequest = searchRequest;
      modalRef.componentInstance.qualifiedMortgageDetails = this.qualifiedMortgageDetails;
      modalRef.componentInstance.product = this._eligibleProducts.find(product => product.productId === qmRequest.productId);
    }, (error) => {
      this._spinnerService.hide();
      this._notifsService.showError(
        error
          ? error.message
          : 'Unable to get QM Product details.',
        'Error!'
      );
    });
  }

  onEligibleProductDetailsClicked = (productId: string) => {
    const selectedProduct = this.pricingRunResult.eligibleProducts.find(
      (p) => p.productId === productId
    );
    const selectedSearchId = this.pricingRunResult.searchId;
    let pricesForProduct = this.pricesByProductId.get(productId);
    if (pricesForProduct) {
      this.showProductPricingDetailsDialog(selectedProduct, pricesForProduct);
    } else {
      this._spinnerService.show();

      const searchRequest = this.fixPercentageValues(this.productSearchRequestInfo.request);
      searchRequest.thirdPartySearchId = selectedSearchId;
      searchRequest.productIds = [selectedProduct.productId];
      searchRequest.optimalBlueChannelId = this.businessChannelId as number;

      this.setPricingSearchMode(searchRequest);

      this._pricingService.getProductPricingDetails(this.pricingRunResult.pricingVendor, searchRequest).subscribe((pricedProduct: PricedProduct) => {
        const quotes = pricedProduct?.quotes?.filter(
          (q) => q.adjustedRate > 0 && q.lockPeriod > 0
        );

        if (quotes) {
          const eligibleProduct = this._eligibleProducts.find(p => p.productId === productId);
          if (eligibleProduct) {
            eligibleProduct.quotes = quotes;
          }

          const priceCells = quotes.map(
            (q) =>
              new PriceCell(
                q.adjustedRate,
                q.adjustedPrice,
                q.lockPeriod,
                q.principalAndInterest,
                q.monthlyMi,
                q.discountDollars,
                q.discountPercent,
                q.rebateDollars,
                q.rebatePercent,
                q.totalClosingCost,
                q.totalPayment,
                q.adjustments
              )
          );
          pricesForProduct = new ProductPricingDetails(
            priceCells,
            pricedProduct.notesAndAdvisories,
            pricedProduct.quotes[0].adjustments,
            pricedProduct.calculatedValues
          );
          this.pricesByProductId.set(productId, pricesForProduct);
          this._spinnerService.hide();
          this.showProductPricingDetailsDialog(
            selectedProduct,
            pricesForProduct
          );
        } else {
          this._spinnerService.hide();
          this._notifsService.showError('Product detail not found', 'Error');
        }
      },
        (error) => {
          this._spinnerService.hide();
          this._notifsService.showError(
            error ? error.message : 'Unable to search Products',
            'Error!'
          );
        }
      );
    }
  };

  onBusinessChannelChanged = (selectedChannel: { type: string, id: number }) => {
    this.businessChannelId = selectedChannel.type === 'OptimalBlue' ? selectedChannel?.id : null;
    if (this.businessChannelId) {
      this.loadCustomFields();
    }
    if (selectedChannel.id) {
      this.loadFeatures();
    }
  }

  onVendorChanged = (selectedVendorName: string) => {
    this.selectedVendorName = selectedVendorName;

    if (this.selectedVendorName) {
      this.populateBusinessChannelsForSelectedVendor(this.selectedVendorName);
    }
  }

  onCustomFieldChanged = (customFieldId: string, name: string, value: string) => {
    const field =
      this._productSearchRequest.loanInformation.customFieldValues.find(
        (el) => el.customFieldId == customFieldId
      );
    if (!field) {
      this._productSearchRequest.loanInformation.customFieldValues.push({
        customFieldId,
        name,
        value,
      });
    } else {
      field.value = value;
    }
  }

  onScenarioDeleted = (scenarios: PricingScenario[]) => {
    this.scenarioDeleted.emit(scenarios);
  }

  onScenariosAccordionHeaderClicked = () => {
    this.isScenariosSectionOpen = !this.isScenariosSectionOpen;
    this.scenariosSectionOpeningChanged.emit(this.isScenariosSectionOpen);
  }

  onRequestLockClicked = (request: { product: BaseProduct, price: Price, pricedScenario: PricedScenario }) => {
    const modalRef = this._modalService.open(RequestLockDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.product = request.product;
    modalRef.componentInstance.price = request.price;
    modalRef.componentInstance.request = request.pricedScenario?.pricingScenario ?? this.productSearchRequestInfo.request;
    modalRef.componentInstance.vendorName = this.selectedVendorName;
    modalRef.componentInstance.isTpoUser = this.isTpoUser;
    // only quick pricer
    modalRef.componentInstance.isRequestLockEnabled = !this.canPriceApplyToLoan && this.selectedVendorName === PricingEngineVendor.Polly;
  }

  private populateBusinessChannelsForSelectedVendor = (vendor: string) => {
    if (!this.allEnabledBusinessChannelPerVendor) {
      return;
    }
    const channels: VendorProfile[] = this.allEnabledBusinessChannelPerVendor[Utils.lowerCaseFirstLetter(vendor)];
    if (_.isArray(channels)) {
      this.enabledChannels = _.orderBy(channels, "name").map(c => {
        return {
          channel: c.channel,
          pricingChannelId: c.pricingVendorChannel,
          originatorId: Number(c.pricingVendorOriginator),
          name: c.name,
          credentialId: c.credentialId,
        }
      });
      this.businessChannelId = vendor == 'OptimalBlue' ? this.enabledChannels[0]?.pricingChannelId : undefined;
      this.productSearchRequestInfo.request.credentialId = this.enabledChannels[0]?.credentialId;
      this.productSearchRequestInfo.request.channel = this.enabledChannels[0]?.channel;
      this.productSearchRequestInfo.request.pricingChannelId = vendor != 'OptimalBlue' ? this.enabledChannels[0]?.pricingChannelId : undefined;
      this.productSearchRequestInfo.request.optimalBlueOriginatorId = vendor == 'OptimalBlue'
        ? this.enabledChannels[0]?.originatorId
        : undefined;
    }
    this.loadFeatures();
    this.loadCustomFields();
  }

  private loadBusinessChannels = () => {
    this._spinnerService.show();
    this._pricingService.getPricingCredentials(this.currentApplication?.applicationId).subscribe(
      (pricingCreds) => {
        this.allEnabledBusinessChannelPerVendor = pricingCreds;
        if (this.selectedVendorName) {
          this.populateBusinessChannelsForSelectedVendor(this.selectedVendorName);
        }
      },
      (error) => {
        this._notifsService.showError(
          error ? error.message : 'Unable to get channels',
          'Error!'
        );

      }).add(() => {
        this._spinnerService.hide()
      });
  }

  private calculateLtv = (request: ProductSearchRequest): number => {
    const minValue = this.minOfSalesPriceAndAppraisedValue(request);
    if (minValue == 0) {
      return 0;
    }
    let ltv = request.loanInformation.baseLoanAmount / minValue;
    return ltv;
  }

  private setPricingSearchMode = (request: ProductSearchRequest) => {
    if (this.transactionType) {
      if (this.transactionType == PricingTransactionType.programChange) {
        request.pricingSearchMode = PricingSearchMode.ProgramChange;
      } else if (this.transactionType == PricingTransactionType.reprice) {
        request.pricingSearchMode = PricingSearchMode.Reprice;
      }
    } else {
      if (!this.canPriceApplyToLoan) {
        request.pricingSearchMode = PricingSearchMode.Hypothetical;
      } else {
        request.pricingSearchMode = PricingSearchMode.Assignment;
      }
    }
  }

  private minOfSalesPriceAndAppraisedValue = (
    request: ProductSearchRequest
  ): number => {
    var salesPrice = request.propertyInformation.salesPrice
      ? request.propertyInformation.salesPrice
      : Number.MAX_VALUE;
    var appraisedValue = request.propertyInformation.appraisedValue
      ? request.propertyInformation.appraisedValue
      : Number.MAX_VALUE;
    var min = Math.min(salesPrice, appraisedValue);
    return min != Number.MAX_VALUE ? min : 0;
  }

  private onScenariosAdded = (newOnes: PricingScenario | PricingScenario[]) => {
    this.pricingScenarios = this.pricingScenarios.concat(newOnes);
    this.scenariosAdded.emit(this.pricingScenarios);
  }

  private fixPercentageValues = (productSearchRequest: ProductSearchRequest) => {
    // ** NEED to make a copy here, if not UI values bound to the source object will show wrong numbers
    let request = { ...productSearchRequest };
    let borrowerInformation = { ...productSearchRequest.borrowerInformation };
    let loanInformation = { ...productSearchRequest.loanInformation };
    request.borrowerInformation = borrowerInformation;
    request.loanInformation = loanInformation;
    if (request.desiredRate) {
      request.desiredRate = parseFloat((productSearchRequest.desiredRate * 100).toFixed(3));
    }
    request.borrowerInformation.debtToIncomeRatio = parseFloat((productSearchRequest.borrowerInformation.debtToIncomeRatio * 100).toFixed(3));
    request.loanLevelDebtToIncomeRatio = parseFloat((productSearchRequest.loanLevelDebtToIncomeRatio * 100).toFixed(3));
    request.loanInformation.upfrontPmiMipFfGfPercent = parseFloat((productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent * 100).toFixed(3));
    return request;
  }

  private loadFeatures = () => {
    const payload: VendorFeatureRequest = {
      optimalBlueOriginatorId: this.selectedVendorName === 'OptimalBlue' ? this.productSearchRequestInfo.request.optimalBlueOriginatorId : undefined,
      optimalBlueChannelId: this.selectedVendorName === 'OptimalBlue' ? this.businessChannelId as number : undefined,
      applicationId: undefined,
      credentialId: this.productSearchRequestInfo.request.credentialId,
      pricingChannelId: this.selectedVendorName !== 'OptimalBlue' ? this.productSearchRequestInfo.request.pricingChannelId : undefined,
      encompassTpoOrgId: undefined,
      encompassBrokerPriceGroup: undefined,
      encompassDelegatedPriceGroup: undefined,
      encompassNonDelegatedPriceGroup: undefined,
    }
    this._spinnerService.show();

    //call
    if (payload.credentialId) {
      this.loadFieldConfigsForVendor(payload.credentialId);
    }

    this._pricingService
      .getFeaturesByVendor(this.selectedVendorName, payload).subscribe((res) => {
        this.visibleFeatureFields = res;
        this._spinnerService.hide();
      },
        (error) => {
          this._spinnerService.hide();
          this._notifsService.showError(
            error ? error.message : 'Unable to retrieve custom fields.',
            'Error!'
          );
        }).add(() => this._spinnerService.hide());
  }

  private loadFieldConfigsForVendor = (credId: number) => {
    this._spinnerService.show();
    let obs;

    if (this.selectedVendorName === 'OptimalBlue') {
      obs = this._pricingConfigService.getConfigByVendor(PricingEngineVendor.OptimalBlue);
    }
    else {
      obs = this._pricingConfigService.getConfig(credId);
    }

    obs.subscribe({
      next: (config) => {
        this.fieldSpecs = config?.fieldSpecs;
      }, error: (err) => {
        this._notifsService.showError(err.message || err, "Fields Config Error!");
      }
    }).add(() => {
      this._spinnerService.hide();
    })
  }

  private loadCustomFields = () => {
    this.hideQmColumn = this.selectedVendorName === 'Polly';

    if (this.selectedVendorName === 'OptimalBlue' || this.selectedVendorName === 'LoanPass') {
      const baseRequest = new BaseRequest(this.businessChannelId as number);
      this._spinnerService.show();
      this._pricingService
        .getCustomFields(this.selectedVendorName, baseRequest).subscribe((res) => {
          this.customFields = res;
          this._spinnerService.hide();
        },
          (error) => {
            this._spinnerService.hide();
            this._notifsService.showError(
              error ? error.message : 'Unable to retrieve custom fields.',
              'Error!'
            );
          }
        );
    } else if (this.selectedVendorName === 'Polly' || this.selectedVendorName === 'LenderPrice'
      || this.selectedVendorName === 'MeridianLink') {
      this._spinnerService.show();
      this._pricingCustomFieldService.getCustomFields(this.selectedVendorName).subscribe({
        next: ((result: CustomField[]) => {
          this.customFields = result.map(cf => ({
            ...cf,
            thirdPartyCustomFieldId: cf.name
          }));
        }),
        error: ((error: any) => {
          this._notifsService.showError(error?.message || `Unable to get custom fields for ${this.selectedVendorName}`, 'Failure!');
        })
      }).add(() => this._spinnerService.hide());
    }
  };

  private showProductPricingDetailsDialog = (
    selectedProduct: PricedProductRow,
    productPricingDetails: ProductPricingDetails
  ) => {
    const modalRef = this._modalService.open(ProductDetailDialogComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.selectedProduct = selectedProduct;
    modalRef.componentInstance.totalLoanAmount = this.totalLoanAmount;
    modalRef.componentInstance.taxesAndInsurance = this._productSearchRequest.loanInformation.taxesAndInsuranceMonthly || 0;
    modalRef.componentInstance.notesAndAdvisories = productPricingDetails.notesAndAdvisories;
    modalRef.componentInstance.adjustments = productPricingDetails.adjustments;
    modalRef.componentInstance.prices = productPricingDetails.prices;

    const upfrontCosts = { ...this._upfrontCosts };
    upfrontCosts.totalLoanAmount = (productPricingDetails.calculatedValues && productPricingDetails.calculatedValues.totalLoanAmount) ?
      productPricingDetails.calculatedValues.totalLoanAmount : upfrontCosts.totalLoanAmount;
    upfrontCosts.pmiMipFfGfAmount = (productPricingDetails.calculatedValues && productPricingDetails.calculatedValues.upfrontPmiMipFfGfAmount) ?
      productPricingDetails.calculatedValues.upfrontPmiMipFfGfAmount : upfrontCosts.pmiMipFfGfAmount;
    upfrontCosts.pmiMipFfGfFinancedAmount = (productPricingDetails.calculatedValues && productPricingDetails.calculatedValues.upfrontPmiMipFfGfFinancedAmount) ?
      productPricingDetails.calculatedValues.upfrontPmiMipFfGfFinancedAmount : upfrontCosts.pmiMipFfGfFinancedAmount;
    upfrontCosts.pmiMipFfGfPaidinCash = (productPricingDetails.calculatedValues && productPricingDetails.calculatedValues.upfrontPmiMipFfGfPaidinCash) ?
      productPricingDetails.calculatedValues.upfrontPmiMipFfGfPaidinCash : upfrontCosts.pmiMipFfGfPaidinCash;
    upfrontCosts.pmiMipFfGfPercent = (productPricingDetails.calculatedValues && productPricingDetails.calculatedValues.upfrontPmiMipFfGfPercent) ?
      productPricingDetails.calculatedValues.upfrontPmiMipFfGfPercent : upfrontCosts.pmiMipFfGfPercent;

    modalRef.componentInstance.upfrontCosts = upfrontCosts;

    modalRef.result.then(
      (selectedPrices: SelectedPriceCardViewModel[]) => {
        setTimeout(() => {
          this.updateProductPriceOptions(selectedProduct, selectedPrices);
        });
      },
      (res) => { }
    );
  };

  private updateProductPriceOptions = (selectedProduct: PricedProductRow, selectedPrices: SelectedPriceCardViewModel[]): void => {
    if (!selectedPrices || selectedPrices.length === 0) {
      return;
    }
    let productPricingRow = this.pricingRunResult.eligibleProducts.find(p => p.productId === selectedProduct.productId);
    productPricingRow.prices = [];
    selectedPrices.forEach((p) => {
      const price = new Price(
        p.rate,
        p.price,
        p.lockPeriod,
        p.principalAndInterest,
        p.monthlyMi,
        p.taxesAndInsurance,
        p.discountPercent,
        p.discountDollars,
        p.rebatePercent,
        p.rebateDollars,
        p.isPinned
      );
      productPricingRow.prices.push(price);
    });
  }

  private createScenarioFromPinnedPrice = (productId: string, price: Quote, searchRequest: ProductSearchRequest) => {
    const product = this._eligibleProducts.find(p => p.productId === productId);
    if (product) {
      const scenarioModel = this.createPricingScenario(product, price, searchRequest);
      return scenarioModel;
    }
    return null;
  }

  private startCreatingProcess = (scenarioName: string, product: BaseProduct, pinnedPrice: Price, pricedScenario) => {
    const selectedSearchId = pricedScenario.searchId;
    const searchRequest = this.fixPercentageValues(pricedScenario.pricingScenario);
    searchRequest.thirdPartySearchId = selectedSearchId;
    searchRequest.productIds = [product.productId];
    searchRequest.optimalBlueChannelId = this.businessChannelId as number;

    const price = product.quotes.find(q => q.adjustedRate === pinnedPrice.rate && q.lockPeriod === pinnedPrice.lockPeriod);
    const scenarioModel = this.createPricingScenario(product, price, pricedScenario.pricingScenario);
    scenarioModel.name = scenarioName;

    this._spinnerService.show();
    this._pricingService.createPricingScenario(scenarioModel).subscribe(createdScenario => {
      if (this.currentApplication) {
        let feesToSave = this._defaultFees.map(f => {
          f.loanFeeId = null;
          f.pricingScenarioId = createdScenario.pricingScenarioId;
          return f;
        });
        var discFee = feesToSave.find(x => x.feeType == FeeTypeEnum.LoanDiscountPoints);
        if (!discFee) {
          discFee = new LoanFee();
          discFee.feeSection = FeeSectionEnum.Origination;
          discFee.feeType = FeeTypeEnum.LoanDiscountPoints;
          discFee.calculatedFeeType = CalculatedFeeTypeEnum.Default;
          discFee.hudNumber = "802e";
          feesToSave.push(discFee);
        }
        if (price.discountDollars > 0) {
          discFee.borrowerFeePercent = price.discountPercent;
        }
        else if (price.rebateDollars > 0) {
          discFee.borrowerFeePercent = 0 - price.rebatePercent;
        }
        this._feesService.saveScenarioFees(createdScenario.pricingScenarioId, feesToSave).subscribe(createdScenarioFees => {
          this.onScenariosAdded(createdScenario);
          this._notifsService.showSuccess('Pinned Scenarios have been saved!', 'Success');
        }, err => {
          this._notifsService.showError(err.error.message || 'Errors have been detected!', 'Failure');
        }).add(() => this._spinnerService.hide());
      } else {
        this.onScenariosAdded(createdScenario);
        this._spinnerService.hide();
        this._notifsService.showSuccess('Pinned Scenarios have been saved!', 'Success');
      }
    }, err => {
      this._notifsService.showError(err.error.message || 'Errors have been detected!', 'Failure');
    }).add(() => this._spinnerService.hide());
  }

  private copyProductSearchCriteriFromDefaults = (requestInfo: ProductSearchRequestInfo) => {
    requestInfo.request.amortizationTypes = this._defaultProductSearchRequestInfo.request.amortizationTypes;
    requestInfo.request.loanTerms = this._defaultProductSearchRequestInfo.request.loanTerms;
    requestInfo.request.productTypes = this._defaultProductSearchRequestInfo.request.productTypes;
    requestInfo.request.armFixedTerms = this._defaultProductSearchRequestInfo.request.armFixedTerms;
  }

  private createPricingScenario = (product: BaseProduct, price: Quote, searchRequest: ProductSearchRequest) => {
    const scenario = new PricingScenario(
      this.currentApplication ? this.currentApplication.applicationId : null,
      product.rateSheetDate,
      product.productId,
      product.productName,
      product.investorId,
      product.investor,
      price.lockPeriod,
      price.adjustedRate,
      price.adjustedPrice,
      price.margin,
      price.principalAndInterest,
      price.monthlyMi,
      //product.ineligibleReason,
      price.totalPayment,
      price.totalClosingCost,
      price.discountDollars,
      price.discountPercent,
      price.baseRate,
      price.basePrice,
      price.totalPriceAdjustment,
      price.totalRateAdjustment,
      price.totalMarginAdjustment,
      price.totalSrpAdjustment,
      price.apr,
      price.monthlyInsurance,
      price.monthlyTaxes,
      price.loCompensationDollars,
      price.loCompensationPercent,
      price.borrowerPaidClosingCostDollars,
      price.currentFeeCreditDollar,
      price.totalFeesDollars,
      price.totalFeesPercent,
      price.originationDollars,
      price.originationPercent,
      price.lenderFeesDollars,
      price.lenderFeesPercent,
      price.thirdPartyFeesDollars,
      price.thirdPartyFeesPercent,
      price.qmStatus,
      price.adjustments,
      price.pricingLastUpdated,
      price.rebatePercent,
      price.rebateDollars);

    scenario.pricingVendor = this.pricingRunResult.pricingVendor;

    const pricingRequest = this.fixPercentageValues(searchRequest);
    scenario.pricingRequestPayload = pricingRequest;
    return scenario;
  }
}

export class ProductPricingDetails {
  prices: PriceCell[] = [];
  notesAndAdvisories: string[] = [];
  adjustments: Adjustment[] = [];
  calculatedValues: ProductCalculatedValues;

  constructor(prices: PriceCell[], notesAndAdvisories: string[], adjustments: Adjustment[], calculatedValues: ProductCalculatedValues) {
    this.prices = prices;
    this.notesAndAdvisories = notesAndAdvisories;
    this.adjustments = adjustments;
    this.calculatedValues = calculatedValues;
  }
}

export class Scenario {
  requestInfo: ProductSearchRequest;
  pinnedPrices: number[];
  productName: string;
  pinnedPrice: Price;
  isEligible: boolean;
  productId: string;
}

export class PricedScenario {
  pricingScenario: ProductSearchRequest;
  pinnedProducts: PinnedProduct[] = [];
  displayText: string;
  scenarioKey: string;
  searchId: string;

  constructor(pricingScenario: ProductSearchRequest) {
    this.pricingScenario = pricingScenario;
  }
}

export class PinnedProduct {
  product: PricedProductRow;
  pinnedPrices: Price[] = [];

  constructor(product: PricedProductRow) {
    this.product = product;
  }
}

export class UpfrontCosts {
  totalLoanAmount: number;
  pmiMipFfGfAmount: number;
  pmiMipFfGfFinancedAmount: number;
  pmiMipFfGfPaidinCash: number;
  pmiMipFfGfPercent: number;
}


