import { Address, LoanDoc, ResidencyAddress } from '.';
import { TaxTranscriptRequest } from '../modules/disclosure-documents/models/tax-transcript-request.model';
import { BorrowerType } from '../modules/leads/models/lead-credit.model';
import { PayPeriod } from '../modules/leads/models/lead-employment.model';
import { IHaveMortgageLtvCalculationDetails } from './mortgage-calculation-details.model';
import { AmortizationType } from '../modules/reports/mortgage-call/models/mcr-audit-data.model';
import { LoanPurposeTypeEnum, MortgageAppliedForTypeEnum } from '../modules/app-details/components/title-history/models/title-order.model';
import { ChangeOfCircumstanceDetail } from '../modules/disclosure-tracking/models/disclosure-tracking.model';
import { MortgageSigner } from '../modules/loan-docs/models/external-contact.model';

export class Mortgage {
  mortgageId?: number;
  readonly applicationId?: null | number;
  subjectPropertyId?: null | number;
  readonly mortgageTermId?: null | number;
  readonly transactionDetailId?: null | number;
  subjectProperty?: null | SubjectProperty;
  escrowDetail?: null | EscrowDetail;
  mortgageTerm: MortgageTerm;
  originatorInformationId?: null | number;
  originatorInformation: OriginatorInformation;
  relatedMortgages: Array<RelatedMortgage>;
  transactionDetail?: null | TransactionDetail;
  borrowers: Array<MortgageBorrower>;
  assets: Array<Asset>;
  liabilities: Array<Liability>;
  governmentLoanDetail: null | GovernmentLoanDetail;
  mortgageInsuranceDetail: null | MortgageInsuranceDetail;
  realEstateOwned: Array<RealEstateOwned>;
  readonly companyId?: null | number;
  readonly insertedBy?: null | string;
  readonly dateInserted?: null | string;
  readonly updatedBy?: null | string;
  readonly dateUpdated?: null | string;
  proposedHousingExpense?: null | HousingExpense;
  currentHousingExpense?: null | HousingExpense;
  extension?: null | Extension;
  ausData?: null | AusData;
  ausDataId: number;
  denialTerminationChangeDetail: DenialTerminationChangeDetail;
  regulationZDetail: null | RegulationZDetail;
}

export class Extension {
  extensionId: number;
  fannieMaeProjectClassificationType: string;
  isBalloonPaymentRequiredToPayOffLoan: boolean;
  willEscrowBeWaived: boolean;
  isLoanCoveredUnderHomeOwnershipEquityProtectionAct: boolean;
  isPreApprovalLoan: boolean;
  fannieMaeProductDesciptionType: string;
  productCode: string;
  fannieMaeAdjustableRateMortgagePlanNumber: string;
  isLoanAssumableByAnotherParty: boolean;
  fannieMaePaymentFrequencyType: string;
  isPenaltyChargedInEventOfPrepayment: boolean;
  isPrepaymentOfLoanRestricted: boolean;
  fannieMaeRepaymentType: string;
  buydownInterestRateChangeFrequencyInMonths: number;
  buydownInterestRateDurationInMonths: number;
  buydownInterestRateAdjustment: number;
  isLenderFundingInterestRateBuydown: boolean;
  fannieMaeBuydownBaseDateType: string;
  isBuydownPermanent: boolean;
  balloonTermMonths: number;
  isInterestOnly: boolean;
  interestOnlyTermMonths: number;
  hasNegativeAmortization: boolean;
  otherLoanFeatures: string;
  belowMarketSubordinateFinancingProvided: boolean;
  prepaymentPenaltyDetail: PrepaymentPenaltyDetail;
  taxTranscriptRequests: TaxTranscriptRequest[];

  additionalBorrowerAssetsAreConsidered: boolean;
  additionalBorrowerAssetsAreNotConsidered: boolean;
  hmdaPreapprovalType: HMDAPreapprovalTypeEnum;
  relatedInvestorLoanId: string;
  preliminaryConditions: LoanPreliminaryCondition[];
  loanEstimateDocumentDetail: LoanEstimateDocumentDetail;
  closingDisclosureDocumentDetail: ClosingDisclosureDocumentDetail;

  balloonPaymentAmount: number;
  buydownDetail: BuydownDetail;

  borrowingEntity: BorrowingEntityViewModel;


  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}


export class BorrowingEntityViewModel {
  entityName: string;
  entityType: string;
  entityTaxIdentifier: string;
  filingLocation: string;
  stateOfOrganization: string;
  dateOfOrgFiling: Date;
  entityAddress: Address;
  entityMailingAddress: Address
  signingPartyName: string;
  signingPartyTitle: string;
  member1: MemberInfoOwnershipViewModel;
  member2: MemberInfoOwnershipViewModel;
  member3: MemberInfoOwnershipViewModel;
  member4: MemberInfoOwnershipViewModel;
}

export class MemberInfoOwnershipViewModel {
  name: string;
  ownershipPercent: number;
}

export class LoanEstimateDocumentDetail {
  changeOfCircumstanceDetail: ChangeOfCircumstanceDetail;
}

export class ClosingDisclosureDocumentDetail {
  changeOfCircumstanceDetail: ChangeOfCircumstanceDetail;
}

export class PrepaymentPenaltyDetail {
  isPrepaymentFinanceChargeRefundable: boolean;
  prepaymentPenaltyExpirationMonthsCount: number = 0;
  prepaymentPenaltyPrincipalBalanceType: PrepaymentPenaltyPrincipalBalanceType = null;
  prepaymentPenaltyMaximumAmount: number = 0;
  penaltyItems: PrepaymentPenaltyItem[] = [];
}

export class PrepaymentPenaltyItem {
  prepaymentPenaltyOption: PrepaymentPenaltyOptionType = null;
  prepaymentPenaltyTermMonths: number;
  prepaymentPenaltyPercent: number;
}

export enum PrepaymentPenaltyOptionType {
  Hard = "Hard",
  Soft = "Soft"
}

export enum PrepaymentPenaltyPrincipalBalanceType {
  CurrentPrincipalBalance = "CurrentPrincipalBalance",
  ExcessPrincipal = "ExcessPrincipal",
  OriginalPrincipalBalance = "OriginalPrincipalBalance",
  Other = "Other"
}

export enum HMDAPreapprovalTypeEnum {
  NotApplicable = "NotApplicable",
  PreapprovalWasNotRequested = "PreapprovalWasNotRequested",
  PreapprovalWasRequested = "PreapprovalWasRequested"
}

export class LoanPreliminaryCondition {
  prelimConditionId: number;
  priorTo: LoanConditionMilestoneEnum;
  category: LoanConditionCategoryEnum;
  isFulfilled: boolean;
  fulfilledDate: Date;
  fulfilledByUserId: string;
  losLoanRefId: string;
  losConditionRefId: string;
  conditionType: LoanConditionTypeEnum;
  title: string;
  description: string;
  borrowerId: number;
  borrowerName: string;
  status: LoanLosConditionStatusEnum;
  statusDate: Date;
  daysToReceive: number;
  requestedFrom: string;
  createdDate: Date;
  createdByUserId: string;
  isRequested: boolean;
  requestedDate: Date;
  requestedByUserId: string;
  isRerequested: boolean;
  rerequestedDate: Date;
  rerequestedByUserId: string;
  isReceived: boolean;
  receivedDate: Date;
  receivedByUserId: string;
  isAddedToConditionSet: boolean;
  comments: LoanComment[];
  losDocumentType: string;
  documents: LoanDoc[];
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: Date;
  dateUpdated: Date;
}

export class RegulationZDetail {
  regulationZDetailId: number;
  averagePrimeOfferRate: number;
  excludedBonaFideDiscountPoints: number;
  totalAffiliateFees: number;
  totalPointsAndFees: number;
  regulationZTotalLoanAmount: number;
  preDiscountedInterestRate: number;
  isHighCostLoan: boolean;
  qualifyingRateType: string;
  qualifyingRateTypeOtherDescription: string;
  qualifyingRatePercent: number;
  abilityToRepayMethodType: string;
  qualifiedMortgageType: string;
  qualifiedMortgageTypeOtherDescription: string;
  summaryInterestedPartyContributionsPercent: number;
  presumptionOfComplianceType: string;
  qualifiedMortgageTemporaryGSEType: string;
  abilityToRepayExemptionCreditorOrganizationType: string;
  abilityToRepayExemptionCreditorOrganizationTypeOtherDescription: string;
  abilityToRepayExemptionLoanProgramType: string;
  abilityToRepayExemptionLoanProgramTypeOtherDescription: string;
  abilityToRepayExemptionIsBasedOnNumberOfPropertyUnits: boolean;
  abilityToRepayExemptionIsBasedOnCreditPurpose: boolean;
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: string;
  dateUpdated: string;
}

export class BuydownDetail {
  hasBuydown: boolean;
  initialBuydownRate: number;
  buydownCalculationType: string;
  buydownContributorType: string;
  buydownSchedule: BuydownScheduleItem[] = [];
  buydownInterestRateChangeFrequencyInMonths: number;
  buydownInterestRateDurationInMonths: number;
  buydownInterestRateAdjustment: number;
  fannieMaeBuydownBaseDateType: string;
  isBuydownPermanent: boolean;
}

export class BuydownScheduleItem {
  adjustmentPercent: number;
  paymentCount: number;
}

export class LoanComment {
  comments: string;
  dateCreated: Date;
  createdByName: string;
}

export enum LoanConditionMilestoneEnum {
  Approval = "Approval",
  Docs = "Docs",
  Funding = "Funding",
  Closing = "Closing",
  Purchase = "Purchase"
}

export enum LoanConditionCategoryEnum {
  Assets = "Assets",
  Credit = "Credit",
  Income = "Income",
  Legal = "Legal",
  Misc = "Misc",
  Property = "Property"
}

export enum LoanConditionTypeEnum {
  Preliminary = "Preliminary",
  Underwriting = "Underwriting",
  PostClosing = "PostClosing"
}

export enum LoanLosConditionStatusEnum {
  Added = "Added",
  Expected = "Expected",
  Requested = "Requested",
  Received = "Received",
  Rerequested = "Rerequested",
  Fulfilled = "Fulfilled",
  Reviewed = "Reviewed",
  Sent = "Sent",
  Cleared = "Cleared",
  Waived = "Waived",
  Expired = "Expired",
  Rejected = "Rejected"
}
export class AusData {
  ausDataId: number;
  armIndexCurrent: number;
  armMarginRate: number;
  armIndexType: string;
  armIndexSourceType: string;
  armIndexSourceTypeOtherDescription: string;
  armFullyIndexedRate: number;
  armFloorRate: number;
  firstPaymentAdjustmentMonths: number;
  firstRateAdjustmentMonths: number;
  subsequentRateAdjustmentMonths: number;
  rateAdjustmentPeriodicCap: number;
  subsequentRateAdjustmentPeriodicCap: number;
  armRateAdjustmentLifetimeCap: number;
  negativeAmortizationType: string;
  isPrimaryBorrowerMarriedToCoBorrower: boolean;
  freddieMacRefinanceProgram: string;
  refinanceProgramIdentifierOtherDescription: string;
  freddieMacLoanPrograms: string[] = [];
  hudIncomeLimitAdjustmentPercent: number;
  hmdaRateSpread: number;
  caseStateType: string;
  theWorkNumberReportId: string;
  ausResults: AusResult[];
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class AusResult {
  caseIdentifier: string;
  ausSystem: string;
  ausSystemOtherDescription: string;
  decisionDateTime: string;
  ausResultValue: string;
  recommendationDescription: string;
  includesDocumentWaiver: boolean;
}

export class RelatedMortgage {
  relatedMortgageId: number;
  mortgageId: number;
  creditorName: string;
  lienType: string;
  monthlyPayment: number;
  loanOrDrawAmount: number;
  creditLimit: number;
  isAffordableLoan: boolean;
  isHeloc: boolean;
  isPrincipalAndInterestDeferredFirstFiveYears: boolean;
  fundsSource: string;
  fundsSourceOtherDescription: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;

  // These properties are from TFS-15267
  willBeRefinancedIntoSubjectLoan: boolean;
  willBeModifiedIntoSubjectLoan: boolean;
  lenderLoanIdentifier: string;
  agencyCaseIdentifier: string;
  investorLoanIdentifier: string;
  amortizationType: AmortizationType;
  mortgageType: MortgageAppliedForTypeEnum;
  loanTermMonths: number;
  remainingTermToMaturityMonths: number;
  unpaidBalance: number;
  noteRate: number;
  ltv: number;
  feeSummaryTotalOfAllPayments: number;
  loanPurpose: LoanPurposeTypeEnum;
  miPremiumMonthlyPayment: number;
  vaResidualIncome: number;
  assignmentDate: string;
  assignedToName: string;
  isAssumable: boolean;
  balloonPaymentAmount: number;
  borrowerName: string;
  lenderName: string;
  isLienHolderSameAsSubjectLoan: boolean;
  noteDate: string;
  mortgageDate: string;
  hasPrepaymentPenalty: boolean;
  titleHeldByName: string;
  trusteeName: string;
  priorRecordingBookNumber: string;
  priorRecordingInstrumentNumber: string;
  priorRecordingPageNumber: string;
  priorRecordingDateTime: string;
  priorRecordingJurisdictionName: string;
  isDeedOfTrust: boolean;
}

export class SubjectProperty {
  subjectPropertyId: number;
  addressId: number;
  subjectPropertyValuationId: number;
  purposeOfLoan: string;
  loanPurposeOtherDescription: string;
  propertyWillBe: string;
  occupancyPercent: number;
  apn: string;
  lot: string;
  tract: string;
  unit: string;
  noOfUnits: number;
  yearBuilt: number;
  propertyType: string;
  isCondominiumHotel: boolean;
  isPlannedUnitDevelopment: boolean;
  isPropertyNotInAProject: boolean;
  constructionPeriodMonths: number;
  presentValue: number;
  improvementCost: number;
  amountExistingLiens: number;
  buildingStatus: string;
  originalCostYear: number;
  originalCost: number;
  refiPurpose: string;
  refinancePrimaryPurpose: string;
  describeImprovement: string;
  improvementStatus: string;
  cost: number;
  refinanceWithOriginalCreditor: boolean;
  titleWillBeHeldNames: string;
  downPaymentSource: string;
  explain: string;
  manner: string;
  estate: string;
  leaseHoldExpirationDate: string;
  legalDescriptionType: string;
  nativeAmericanLandsType: string;
  isCommunityLandTrust: boolean;
  isSubjectToPropertyInclusionZoning: boolean;
  uniqueDwellingType: string;
  sellerFirstName: string;
  sellerLastName: string;
  sellerMiddleName: string;
  sellerNameSuffix: string;
  atLeastOneBorrowerLivesInCommunityPropertyState: boolean;
  propertyIsInCommunityPropertyState: boolean;
  communityLendingProductType: string;
  communitySecondsRepaymentType: string;
  isConversionOfContractForDeedOrLandContract: boolean;
  isRenovation: boolean;
  isConstructionConversionOrConstructionToPermanent: boolean;
  constructionToPermanentClosingType: string;
  constructionLoanPurpose: string;
  projectType: string;
  projectDesignType: string;
  attachmentType: string;
  constructionMethod: string;

  constructionMethodOtherDescription: string; //
  isEnergyEfficientHome: boolean; //
  livingAreaSquareFeet: number;
  isDamagedByDisaster: boolean;
  propertyWillBeGroupHome: boolean;
  condominiumOrPudDeclaration: string;
  currentOccupancyType: PropertyOccupancyTypeEnum;
  currentOccupantName: string;
  currentOwnerType: UnitOwnedByTypeEnum;
  currentUsageType: CurrentPropertyWillBeTypeEnum;
  currentUsageTypeOtherDescription: string;
  msaIdentifier: string;
  bathroomCount: number;
  bedroomCount: number;
  livableRoomCount: number;
  projectName: string;
  projectClassificationIdentifier: string;
  condominiumProjectStatus: CondominiumProjectStatusTypeEnum;
  fnmCondominiumProjectManagerProjectIdentifier: string;
  floodProgramParticipationStatus: NFIPCommunityParticipationStatusEnum;
  isOnNFIPMap: boolean;
  isInProtectedArea: boolean;
  isInSpecialFloodHazardArea: boolean;
  legalDescriptions: LegalDescriptions[];

  constructionStatus: string;
  manufacturedHomeWidthType: string;
  mannerTitleWillBeHeld: string;
  mannerTitleWillBeHeldOtherDescription: string;
  trustClassification: string;
  mortgageWillFinanceEnergyRelatedImprovements: boolean;
  propertyHasLienWithCleanEnergyProgram: boolean;
  refinanceProgram: string;
  refinanceProgramOther: string;
  currentFirstMortgageHolderType: string;
  lotOriginalCost: number;
  lotAppraisedValue: number;
  landValueType: string;
  lotAcquiredDate: string;
  titleCurrentlyHeldInNames: string;
  isFhaSecondaryResidence: boolean;
  isMixedUseProperty: boolean;
  expectedGrossMonthlyRentalIncome: number;
  expectedNetMonthlyRentalIncome: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  country: string;
  countyFips: string;
  stateFips: string;
  subjectPropertyValuation: SubjectPropertyValuation;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
  groundLeaseExpirationDate: Date;
  nativeAmericanLandsTypeOtherDescription: string;
  titlePreliminaryReportDate?: Date;
  titleReportItemsDescription: string;
  titleReportRequiredEndorsementsDescription: string;
  recordingJurisdictionName: string;
  recordingJurisdictionType?: RecordingJurisdictionType;
  recordingJurisdictionTypeOtherDescription: string;
  isDeedRestricted?: boolean;
  deedRestrictionTermMonths?: number;
  mannerTitleCurrentlyHeld?: PropertyTitleType;
  mannerTitleCurrentlyHeldOtherDescription: string;

  realEstateTaxAssessedTotal: null | number;
  realEstateTaxAuthorityAccountBillIdentifier: null | string;
  realEstateTaxContractIdentifier: null | string;
  hazardInsuranceDetails: null | HazardInsuranceDetail[];

  manufacturedHomeManufacturerName: string;
  manufacturedHomeModelIdentifier: string;
  manufacturedHomeManufactureYear: number;
  manufacturedHomeConditionDescriptionType: string;
  manufacturedHomeLengthInFeet: number;
  manufacturedHomeWidthInFeet: number;
  isManufacturedHomeAttachedToFoundation: boolean;
  manufacturedHomeSerialNumber: string;
  manufacturedHomeHudDataPlateIdentifier: string;
  manufacturedHomeCertificateOfTitleIdentifier: string;
  manufacturedHomeCertificateOfTitleType: string;
  manufacturedHomeCertificateOfTitleTypeOtherDescription: string;
  manufacturedHomeLotOwnershipType: string;
}

export class HazardInsuranceDetail {
  coverageType: string;
  coverageAmount: number;
  expirationDate: string;
  nextPremiumDueDate: string;
  policyCancellationDate: string;
  policyIdentifier: string;
  hasInsurance: boolean;
  isInsuranceRequired: boolean;
  otherMortgageLoanPrincipalInterestTaxesAndInsurance?: number;
}

export enum PropertyOccupancyTypeEnum {
  Abandoned = "Abandoned",
  AdverseOccupied = "AdverseOccupied",
  OccupiedByUnknown = "OccupiedByUnknown",
  OwnerOccupied = "OwnerOccupied",
  PartiallyVacant = "PartiallyVacant",
  TenantOccupied = "TenantOccupied",
  Unknown = "Unknown",
  Vacant = "Vacant"
}

export enum UnitOwnedByTypeEnum {
  Developer = "Developer",
  Investor = "Investor",
  OwnerOccupier = "OwnerOccupier"
}

export enum CurrentPropertyWillBeTypeEnum {
  PrimaryResidence = "PrimaryResidence",
  SecondaryResidence = "SecondaryResidence",
  Investment = "Investment",
  Other = "Other"
}

export enum CondominiumProjectStatusTypeEnum {
  Established = "Established",
  New = "New"
}

export enum NFIPCommunityParticipationStatusEnum {
  Emergency = "Emergency",
  NonParticipating = "NonParticipating",
  Other = "Other",
  Probation = "Probation",
  Regular = "Regular",
  Suspended = "Suspended",
  Unknown = "Unknown"
}

export class LegalDescriptions {
  unparsedLegalDescriptions: UnparsedLegalDescription[] = [];
  parcelIdentifications: ParcelIdentification[] = [];
  parsedLegalDescriptions: ParsedLegalDescription[] = [];
}

export class UnparsedLegalDescription {
  legalDescription: string;
  legalDescriptionType: UnparsedLegalDescriptionTypeEnum;
  legalDescriptionTypeOtherDescription: string;

  constructor(type: UnparsedLegalDescriptionTypeEnum) {
    this.legalDescriptionType = type;

    if (type == UnparsedLegalDescriptionTypeEnum.Other) {
      this.legalDescription = undefined;
    }
  }
}

export class ParcelIdentification {
  parcelIdentifier: string;
  identificationType: ParcelIdentificationTypeEnum;
  identificationTypeOtherDescription: string;

  constructor(type: ParcelIdentificationTypeEnum) {
    this.identificationType = type;
  }
}

export class ParsedLegalDescription {
  unplattedLandSurveyTownshipIdentifier: string;
  unplattedLandType: UnplattedLandTypeEnum;
  unplattedLandTypeOtherDescription: string;

  constructor(type: UnplattedLandTypeEnum) {
    this.unplattedLandType = type;

    if (type == UnplattedLandTypeEnum.Other) {
      this.unplattedLandSurveyTownshipIdentifier = undefined;
    }
  }
}

export enum UnparsedLegalDescriptionTypeEnum {
  Long = "Long",
  Other = "Other",
  Short = "Short"
}

export enum ParcelIdentificationTypeEnum {
  AssessorUnformattedIdentifier = "AssessorUnformattedIdentifier",
  Other = "Other",
  ParcelIdentificationNumber = "ParcelIdentificationNumber",
  TaxMapIdentifier = "TaxMapIdentifier",
  TaxParcelIdentifier = "TaxParcelIdentifier",
  TorrensCertificateIdentifier = "TorrensCertificateIdentifier"
}

export enum UnplattedLandTypeEnum {
  GovernmentSurvey = "GovernmentSurvey",
  LandGrant = "LandGrant",
  MetesAndBounds = "MetesAndBounds",
  NativeAmericanLand = "NativeAmericanLand",
  Other = "Other",
  Ranchero = "Ranchero"
}

export class SubjectPropertyValuation {
  subjectPropertyValuationId: number;
  appraisalIdentifier: string;
  appraisalFormType: MortgageAppraisalFormType = null;
  appraisalFormTypeOtherDescription: string;
  appraisalMethodType: MortgageAppraisalMethodType = null;
  appraisalMethodTypeOtherDescription: string;
  propertyInspectionType: AppraisalPropertyInspectionType = null;
  propertyInspectionTypeOtherDescription: string;
  appraiserCompanyName: string;
  appraiserName: string;
  effectiveDate: Date;
  appraisalComments?: string;
  estimatedRemainingEconomicLifeYears?: number;
  vaStaffAppraisalReviewerIdentifier: string;
  hasVAStaffAppraisalReviewValuationAdjustment: boolean;
  vaStaffAppraisalReviewValueNotificationDate: Date;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class EscrowDetail {
  escrowDetailId: number;
  aggregateAccountingAdjustment: number;
  cushionMonths: number;
  initialBalance: number;
  minimumBalance: number;
  currentBalance: number;
  initialEscrowDepositIncludesAllInsurance: boolean;
  initialEscrowDepositIncludesAllPropertyTaxes: boolean;
  miEscrowIncludedInAggregate: boolean;
  escrowHoldbacks: EscrowHoldback[];
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: string;
  dateUpdated: string;

  escrowsAreWaivedByLender: boolean;
}

export class EscrowHoldback {
  escrowHoldbackAmount: number;
  improvements: EscrowHoldbackImprovement[];
}

export class EscrowHoldbackImprovement {
  escrowHoldbackAmount: number;
  improvementCost: number;
  improvementDescription: string;
}

export enum MortgageAppraisalFormType {
  FNM1004FRE70 = "FNM1004FRE70",
  FNM1004BFRE439 = "FNM1004BFRE439",
  FNM1004CFRE70B = "FNM1004CFRE70B",
  FNM1004DFRE442 = "FNM1004DFRE442",
  FNM1025FRE72 = "FNM1025FRE72",
  FNM1073FRE465 = "FNM1073FRE465",
  FNM1075FRE466 = "FNM1075FRE466",
  FNM2000FRE1032 = "FNM2000FRE1032",
  FNM2000AFRE1072 = "FNM2000AFRE1072",
  FNM2055FRE2055 = "FNM2055FRE2055",
  FNM2065 = "FNM2065",
  FNM2075 = "FNM2075",
  FNM2090 = "FNM2090",
  FNM2095 = "FNM2095",
  FRE2070 = "FRE2070",
  Other = "Other",
  GeneralAddendum = "GeneralAddendum",
  FNM1004MC = "FNM1004MC",
  ERC2001 = "ERC2001",
  ERC2010 = "ERC2010",
  HUD92051 = "HUD92051",
  HUD928005B = "HUD928005B",
  HUD92564CN = "HUD92564CN",
  HUD92900B = "HUD92900B",
  HUD92300 = "HUD92300",
  HUD92900A = "HUD92900A",
  NPMA99B = "NPMA99B",
  NPMA99A = "NPMA99A",
  VA261805 = "VA261805",
  VA268712 = "VA268712"
}

export enum MortgageAppraisalMethodType {
  AutomatedValuationModel = "AutomatedValuationModel",
  BrokerPriceOpinion = "BrokerPriceOpinion",
  DriveBy = "DriveBy",
  EmployeeRelocationCouncil2001 = "EmployeeRelocationCouncil2001",
  FNM1004 = "FNM1004",
  FNM1025 = "FNM1025",
  FNM1073 = "FNM1073",
  FNM2055Exterior = "FNM2055Exterior",
  FNM2055InteriorAndExterior = "FNM2055InteriorAndExterior",
  FNM2065 = "FNM2065",
  FRE2070Exterior = "FRE2070Exterior",
  FRE2070Interior = "FRE2070Interior",
  FNM2075 = "FNM2075",
  FNM2095Exterior = "FNM2095Exterior",
  FNM2095InteriorAndExterior = "FNM2095InteriorAndExterior",
  Form261805 = "Form261805",
  Form268712 = "Form268712",
  FullAppraisal = "FullAppraisal",
  None = "None",
  Other = "Other",
  PriorAppraisalUsed = "PriorAppraisalUsed",
  TaxValuation = "TaxValuation",
  DesktopAppraisal = "DesktopAppraisal"
}

export enum AppraisalPropertyInspectionType {
  ExteriorAndInterior = "ExteriorAndInterior",
  ExteriorOnly = "ExteriorOnly",
  None = "None",
  Other = "Other"
}

export class BorrowerCounselingEvent {
  borrowerCounselingEventId: number;
  borrowerId: number;
  counselingAgencyName: string;
  counselingAgencyIdentifier: string;
  borrowerHasCompleted: boolean;
  completedDate: string;
  counselingType: string;
  format: string;
  formatOtherDescription: string;
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: string;
  dateUpdated: string;
  counselingAgencyAddress1: string;
  counselingAgencyAddress2: string;
  counselingAgencyCity: string;
  counselingAgencyState: string;
  counselingAgencyZipCode: string;
}

export class HousingExpense {
  expenseId: number;
  borrowerId: number;
  residencyAddressId: number;
  firstMortgagePrincipalAndInterest: number;
  homeownersInsurance: number;
  homeownersAssociationDuesAndCondominiumFees: number;
  condominiumAssociationDues: number;
  cooperativeAssociationDues: number;
  homeownersAssociationDues: number;
  mortgageInsurance: number;
  otherHousingExpense: number;
  otherHousingExpenseDescription: string;
  otherMortgageLoanPrincipalAndInterest: number;
  realEstateTax: number;
  statePropertyTax: number;
  countyPropertyTax: number;
  districtPropertyTax: number;
  boroughPropertyTax: number;
  cityPropertyTax: number;
  townPropertyTax: number;
  villagePropertyTax: number;
  schoolPropertyTax: number;
  supplementalPropertyInsurance: number;
  earthquakeInsurance: number;
  floodInsurance: number;
  hailInsurance: number;
  volcanoInsurance: number;
  windAndStormInsurance: number;
  otherSupplementalPropertyInsurance: number;
  isRealEstateTaxEscrowed: boolean;
  rent: number;
  isCurrent: boolean;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class MortgageTerm {
  mortgageTermId?: null | number;
  mortgageAppliedFor?: null | string;
  loanTypeOtherDescription?: null | string;
  incomeAssetsOtherThanBorrowerWillBeUsed?: null | boolean;
  incomeAssetsOfSpouseNotUsed?: null | boolean;
  agencyCaseNumber?: null | string;
  lenderCaseNumber?: null | string;
  lenderLoanNumber?: null | string;
  universalLoanNumber?: null | string;
  // universalLoanNumber?: null | number;
  mersMortgageIdentifier?: null | string;
  amount?: null | number;
  totalLoanAmount?: null | number;
  totalMortgageLoanAmount?: null | number;
  appraisedValue?: null | number;
  interestRate?: null | number;
  qualifyingRate?: null | number;
  noOfMonths?: null | number;
  amortizationType?: null | string;
  // amortizationType?: null | AmortizationTypeEnum;
  loanAmortizationPeriodType: null | LoanAmortizationPeriodTypeEnum;

  creditorServicingOfLoanStatementType: null | string;
  creditorServicingOfLoanStatementTypeOtherDescription: null | string;
  loanUnderwritingSubmitterType: null | string;
  loanUnderwritingSubmitterTypeOtherDescription: null | string;
  paymentBillingFrequency: null | string;
  paymentRemittanceDay: null | number;
  isAffordableLoan: null | boolean;
  isCapitalizedLoan: null | boolean;
  isConvertible: null | boolean;
  hasENote: null | boolean;
  hasHomeBuyersHomeownershipEducationCertificate: null | boolean;
  hasPropertyInspectionWaiver: null | boolean;
  isRelocationLoan: null | boolean;
  isSharedEquityLoan: null | boolean;
  loanTermMaximumMonths: null | number;
  lateChargeGracePeriodDays: null | number;
  lateChargeType: null | string;
  lateChargeRate: null | number;
  lateChargeAmount: null | number;
  lateChargeMinimumAmount: null | number;
  lateChargeMaximumAmount: null | number;
  partialPaymentAllowed: null | boolean;
  partialPaymentApplicationMethodType: null | string;
  paymentFrequency: null | string;
  isLoanManuallyUnderwritten: null | boolean;
  areDeficiencyRightsPreserved: null | boolean;
  isAssumable: null | boolean;
  isAssumed: null | boolean;
  hasDemandFeature: null | boolean;
  canLoanAmountIncrease: null | boolean;
  canPaymentIncrease: null | boolean;
  lienPositionOtherDescription: null | string;
  borrowerRequestedInterestRate: null | boolean;
  borrowerRequestedLoanAmount: null | boolean;
  isDepositBalanceRequired: null | boolean;
  underwritingComments: null | string;

  amortizationOtherDescription?: null | string;
  armTextualDescription?: null | string;
  lienPosition?: null | string;

  closingAgentOrderNumber?: null | string;

  companyId?: null | number;
  insertedBy?: null | string;
  dateInserted?: null | string;
  updatedBy?: null | string;
  dateUpdated?: null | string;
}

export enum LoanAmortizationPeriodTypeEnum {
  Biweekly = 'Biweekly',
  Day = 'Day',
  Month = 'Month',
  Quarter = 'Quarter',
  Semimonthly = 'Semimonthly',
  Week = 'Week',
  Year = 'Year',
}

export class OriginatorInformation {
  originatorInformationId?: number;
  name?: string;
  nmls?: string;
  stateLicense?: string;
  stateLicenseState?: string;
  email?: string;
  phone?: string;
  fax?: string;
  organizationName?: string;
  organizationAddress1?: string;
  organizationAddress2?: string;
  organizationCity?: string;
  organizationState?: string;
  organizationZipCode?: string;
  organizationNmls?: string;
  organizationStateLicense?: string;
  organizationStateLicenseState?: string;
  organizationTaxpayerIdentifier?: string;
  originatorFhaLenderId?: string;
  originatorFhaSponsorId?: string;
  organizationVaLenderId?: string;
  organizationVaSponsorId?: string;
  underwriterId: string;
  applicationTakenMethod?: string;
  applicationSignedDate?: string;
  companyId?: number;
  insertedBy?: string;
  dateInserted?: string;
  updatedBy?: string;
  dateUpdated?: string;
}

export class TransactionDetail implements IHaveMortgageLtvCalculationDetails {
  readonly transactionDetailId?: null | number;
  purchasePriceAmount?: null | number;
  readonly landAmount?: null | number;
  alterationsImprovementsAndRepairsAmount?: null | number;
  refinanceIncludingDebtsToBePaidOffAmount?: null | number;
  estimatedClosingCostsExcludingPrepaidsAmount?: null | number;
  prepaidItemsEstimatedAmount?: null | number;
  prepaidEscrowsTotalAmount?: null | number;
  estimatedClosingCostsAmount?: null | number;
  miAndFundingFeeTotalAmount?: null | number;
  borrowerPaidDiscountPointsTotalAmount?: null | number;
  subordinateLienAmount?: null | number;
  sellerPaidClosingCostsAmount?: null | number;
  miAndFundingFeeFinancedAmount?: null | number;
  cashFromToBorrowerAmount?: null | number;
  reserves?: null | number;
  salesConcessionTotal?: null | number;
  energyRelatedImprovementsAmount?: null | number;
  vaEntitlementAmount?: null | number;
  vaResidualIncomeAmount?: null | number;
  borrowerPaidFhaVaClosingCosts?: null | number;
  purchaseCredits?: null | PurchaseCredit[];

  initialPITIPaymentAmount?: null | number;
  initialPrincipalAndInterestAndMIMonthlyPaymentAmount?: null | number;
  initialPrincipalAndInterestPaymentAmount?: null | number;
  initialTaxAndInsurancePaymentAmount?: null | number;
  finalPaymentAmount?: null | number;

  otherPartyPaidClosingCostsAmount: number | null;
  personalPropertyInSalesContractAmount: number | null;
  salesContractDate: Date | null;
  cashToSellerAtClosingAmount: number | null;
  estimatedClosingCostsExpiration: Date | null;
  borrowerReservesMonthlyPaymentCount: number | null;
  isTitleOrderValid: boolean | null;

  ltv: number | null;
  cltv: number | null;
  hcltv: number | null;
  frontEndDti: number | null;
  backEndDti: number | null;
  collateralValue: number | null;

  isDisclosureValid: boolean | null;
  doesDisclosureIncludeCD: boolean | null;

  readonly companyId?: null | number;
  readonly insertedBy?: null | string;
  readonly dateInserted?: null | string;
  readonly updatedBy?: null | string;
  readonly dateUpdated?: null | string;

  constructor() {
    this.purchaseCredits = [];
  }
}

export enum MaritalStatusTypeEnum {
  Single = "Single",
  Married = "Married",
  Separated = "Separated",
  Divorced = "Divorced",
  NotProvided = "NotProvided",
  Other = "Other"
}

export enum DomesticRelationshipTypeEnum {
  CivilUnion = "CivilUnion",
  DomesticPartnership = "DomesticPartnership",
  RegisteredReciprocalBeneficiaryRelationship = "RegisteredReciprocalBeneficiaryRelationship",
  Other = "Other"
}

export enum SigningRole {
  Borrower = 'Borrower',
  CoSigner = 'CoSigner',
  TitleOnly = 'TitleOnly',
  NonTitledSpouse = 'NonTitledSpouse',
}

export class MortgageBorrower {
  borrowerId: number;
  mortgageId: number;
  contactId: number;
  printApplicationIndex: number;
  jointWithBorrowerSocialSecurityNumber: string;
  jointWithBorrowerId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  nameSuffix: string;
  yearsOfSchool: number;
  authorizedCreditCheck: boolean;
  dateAuthorizedCreditCheck: string;
  authorizationMethod: string;
  socialSecNum: string;
  maritalStatus: MaritalStatusTypeEnum;
  dateOfBirth: string;
  hasValidEConsent: boolean;
  acceptedEConsentDate: string;
  creditScore: number;
  creditScore_Equifax: number;
  creditScore_Experian: number;
  creditScore_TransUnion: number;
  creditRefNumber: string;
  homePhone: string;
  mobilePhone: string;
  workPhone: string;
  primaryEmail: string;
  secondaryEmail: string;
  typeOfBorrower: BorrowerType;
  rawSocialSecurityNumber: string;
  dependentCount: number;
  isMergedWithBorrower: boolean;
  mergedWithBorrowerId: number;
  onlyNonActivatedReserveOrNationalGuard: boolean;
  isSurvivingSpouseOfMilitary: boolean;
  hasOneYearLandlordExperience: boolean;
  fhaBorrowerCertificationSalesPriceAmount?: number;
  borrowerRelationshipTitleType?: BorrowerRelationshipTitleType;
  borrowerRelationshipTitleTypeOtherDescription: string;

  isFirstUseOfVaBenefit: boolean;
  isVaFundingFeeExempt: boolean;

  vaFederalTaxAmount: number;
  domesticRelationshipType: DomesticRelationshipTypeEnum;
  unmarriedRelationshipTypeOther: string;
  unmarriedRelationshipState: string;
  continuationSheetInformation: string;
  applicationSignedDate: string;
  authorizedTheWorkNumber: boolean;
  formFreeReportId: string;
  active: boolean;
  aliases: BorrowerAlias[] = [];
  counselingEvents: BorrowerCounselingEvent[] = [];
  currentHousingExpenses: HousingExpense;
  declarationId?: number
  governmentMonitorId?: number
  declarations: Declarations;
  dependents: number[] = [];
  employments: Employment[] = [];
  governmentMonitors: Demographics;
  nonEmploymentIncomes: Omit<Income, 'employmentId'>[] = [];
  residencyAddresses: ResidencyAddress[] = [];
  languagePreference?: MortgageLanguage;
  languagePreferenceOtherDescription: string;
  hasRefusedToProvideLanguage: boolean;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
  mortgagePartyType: string;
  identifyingDocuments: IdentifyingDocument[] = [];
  fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType?: boolean;
  entityVestingType: string;

  //DocPrep - Individual Only
  powerOfAttorney: boolean;
  powerOfAttorneyName: string;
  signingRole: SigningRole;

  //DocPrep - Trust Only
  trustName: string;
  trustDate: string;
  trustParties: DocPrepTrustParty[];

  //DocPrep - Entity Only
  entityName: string;
  entityType: string;
  entityTaxIdentifier: string;
  entityAddress: Address;
  entityMailingAddress: Address;
  signers: MortgageSigner[];

  // Military Service
  hasServedInMilitary: boolean;
  isActiveDutyMilitary: boolean;
  militaryStatus?: MilitaryStatus;
  militaryStatusOtherDescription: string;
  isRetiredDischargedSeparatedFromMilitary: boolean;
  branchOfService?: MilitaryBranch;
  branchOfServiceOtherDescription: string;
  militaryServiceNumber: string;
  militaryServedAsName: string;
  militaryTourStartDate: string;
  militaryTourEndDate: string;
  isVAPurpleHeartRecipient?: boolean;
  isActiveDutyOnDayFollowingDateOfSeparation?: boolean;
  hasSeparatedFromMilitaryServiceDueToDisability?: boolean;

  // VA
  caivrsIdentifier: string;
  isVAPastCreditRecordSatisfactory?: boolean;
  hasFiledVADisabilityClaim?: boolean;
  hasCurrentPreDischargeClaim?: boolean;
  hasVABenefitRelatedIndebtedness?: boolean;
  vaLocalTaxAmount?: number;
  vaSocialSecurityTaxAmount?: number;
  vaStateTaxAmount?: number;
  vaBorrowerCertificationOccupancyType?: VABorrowerCertificationOccupancyType;

  // Nearest Living Relative
  nearestLivingRelativeName: string;
  nearestLivingRelativeEmail: string;
  nearestLivingRelativePhone: string;
  nearestLivingRelativeAddress1: string;
  nearestLivingRelativeAddress2: string;
  nearestLivingRelativeCity: string;
  nearestLivingRelativeState: string;
  nearestLivingRelativeZipCode: string;
  nearestLivingRelativeCountry: string;
  nearestLivingRelativeRelationshipToBorrower: string;
}

export enum MilitaryStatus {
  ActiveDuty = 'ActiveDuty',
  Other = 'Other',
  ReserveNationalGuardNeverActivated = 'ReserveNationalGuardNeverActivated',
  Separated = 'Separated',
  Veteran = 'Veteran',
}

export enum MilitaryBranch {
  AirForce = 'AirForce',
  Army = 'Army',
  CoastGuard = 'CoastGuard',
  Marines = 'Marines',
  Navy = 'Navy',
  Other = 'Other',
  AirNationalGuard = 'AirNationalGuard',
  ArmyNationalGuard = 'ArmyNationalGuard',
  ArmyReserves = 'ArmyReserves',
  MarinesReserves = 'MarinesReserves',
  NavyReserves = 'NavyReserves',
}

export enum VABorrowerCertificationOccupancyType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

export class MortgageInsuranceDetail {
  miOrFundingFeeTotalAmount: number;
  miOrFundingFeeTotalPercent: number;
  miOrFundingFeeFinancedAmount: number;
  financeEntireMiOrFundingFee: boolean;
  miOrFundingFeeUpFrontAmount: number;
  isMIPrepaid: boolean;
  monthsPrepaid: number;
  prepaidPremiumTotal: number;
  cancelAtPercent: number;
  level1MonthlyAmount: number;
  level1Percent: number;
  level1Months: number;
  level2MonthlyAmount: number;
  level2Percent: number;
  level2Months: number;
  miCompanyNameType: string;
  miCompanyNameTypeOtherDescription: string;
  miOrderedDate: string;
  miReceivedDate: string;
  miCaseNumber: string;
  scheduledAmortizationMidpointDate: string;
  borrowerMiTerminationDate: string;
  isMortgageInsuranceRequired: boolean;
  mortgageInsuranceCoveragePercent: number;
  premiumRefundableType: string;
  premiumRefundableTypeOtherDescription: string;
  renewalCalculationType: string;
  renewalCalculationTypeOtherDescription: string;
  renewalType: string;
  premiumRatePlanType: string;
  paymentFrequency: string;
  paymentFrequencyOtherDescription: string;
  duration: string;
  premiumSource: string;
  calculationBasedOn: string;
  calculateBasedOnRemainingBalance: boolean;
  midpointPaymentCancellation: boolean;
  decliningRenewals: boolean;
  mortgageInsuranceDetailId: number;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class MortgageInsuranceCalculationResult {
  miOrFundingFeeTotalAmount: number;
  miOrFundingFeeTotalPercent: number;
  miOrFundingFeeFinancedAmount: number;
  totalLoanAmount: number;
  level1MonthlyAmount: number;
  level2MonthlyAmount: number;
  prepaidPremiumTotal: number;
}

export class GovernmentLoanDetail {
  borrowerFundingFeePercent: number;
  borrowerPaidFhaVaClosingCostsAmount: number;
  refundProratedUnearnedUpFront: boolean;
  roundToNearest50Dollars: boolean;
  insuranceChargesIncludedInLoanPayments: boolean;
  insuranceChargesCollectedAtClosing: boolean;
  lenderPaidMortgageInsurance: boolean;
  sectionOfActType: string;
  borrowerFinancedFhaDiscountPointsAmount: number;
  fhaMiPremiumRefundAmount: number;
  fhaAnnualPremiumPercent: number;
  fhaAssignmentDate: string;
  fhaEndorsementDate: string;
  fhaEnergyRelatedRepairsOrImprovementsAmount: number;
  fhaGeneralServicesAdministrationIdentifier: string;
  fhaInsuranceProgramType: string;
  fhaPremiumAnniversaryYearToDateRemittanceAmount: number;
  fhaRefinanceInterestOnExistingLienAmount: number;
  fhaRefinanceOriginalExistingFhaCaseIdentifier: string;
  fhaRefinanceOriginalExistingUpfrontMIPremiumAmount: number;
  fhaMIRenewalRatePercent: number;
  borrowerHasPositiveRentalHistory: boolean;
  vaEntitlementAmount: number;
  vaResidualIncomeAmount: number;
  vaEntitlementIdentifier: string;
  governmentLoanDetailId: number;

  masterCertificateOfReasonableValueExpirationDate: Date; //
  masterCertificateOfReasonableValueEconomicLifeYears: number; //
  masterCertificateOfReasonableValueId: string;
  applicationType: GovernmentLoanApplicationTypeEnum;
  fhavaLoanPurposeType: FHAVALoanPurposeTypeEnum;

  fhaAlimonyLiabilityTreatmentType: FHAAlimonyLiabilityTreatmentTypeEnum;
  energyRelatedImprovementType: EnergyImprovementTypeEnum;
  energyRelatedImprovementTypeOtherDescription: string;
  fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier: string;
  fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier: string;
  fhaDirectEndorsementApprovalType: FHADirectEndorsementApprovalTypeEnum;
  fhaLimitedDenialParticipationIdentifier: string;

  vaResidualIncomeGuidelineAmount: number;
  vaAppraisalType: VAAppraisalTypeEnum;
  vaAppraisalTypeOtherDescription: string;
  vaLoanProcedureType: VALoanProcedureTypeEnum;
  vaLoanProcedureTypeOtherDescription: string;
  vaTitleVestingType: VATitleVestingTypeEnum;
  vaTitleVestingTypeOtherDescription: string;
  vaMaintenanceExpenseMonthlyAmount: number;
  vaUtilityExpenseMonthlyAmount: number;
  vaExaminerComments: string;
  vaClaimFolderIdentifier: string;
  loanMeetsVACreditStandards: boolean;
  vaCreditStandardsDecisionRemarks: string;
  vaExistingLoanBalance: number;
  vaExistingReasonableAppraisedValue: number;
  vaOriginalLoanAmount: number;
  vaOriginalInterestRate: number;
  vaOriginalLoanTerm: number;
  vaExistingPrincipalAndInterest: number;
  vaCashOutRefinanceType: string;
  vaBenefitUsingBorrowerId: number;

  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum GovernmentLoanApplicationTypeEnum {
  HomeLoanGuaranty = "HomeLoanGuaranty",
  NationalHousingAct = "NationalHousingAct"
}

export enum FHAVALoanPurposeTypeEnum {
  PurchaseExistingHomePreviouslyOccupied = "PurchaseExistingHomePreviouslyOccupied",
  FinanceImprovementsToExistingProperty = "FinanceImprovementsToExistingProperty",
  Refinance = "Refinance",
  PurchaseNewCondominiumUnit = "PurchaseNewCondominiumUnit",
  PurchaseExistingCondominiumUnit = "PurchaseExistingCondominiumUnit",
  PurchaseExistingHomeNotPreviouslyOccupied = "PurchaseExistingHomeNotPreviouslyOccupied",
  ConstructHome = "ConstructHome",
  FinanceCooperativePurchase = "FinanceCooperativePurchase",
  PurchasePermanentlySitedManufacturedHome = "PurchasePermanentlySitedManufacturedHome",
  PurchasePermanentlySitedManufacturedHomeAndLot = "PurchasePermanentlySitedManufacturedHomeAndLot",
  RefinancePermanentlySitedManufacturedHomeToBuyLot = "RefinancePermanentlySitedManufacturedHomeToBuyLot",
  RefinancePermanentlySitedManufacturedHomeLotLoan = "RefinancePermanentlySitedManufacturedHomeLotLoan",
  PurchaseRehabilitationOfExistingProperty = "PurchaseRehabilitationOfExistingProperty",
  CreditQualifyingRefinanceWithConstructionEscrow = "CreditQualifyingRefinanceWithConstructionEscrow",
  RefinanceNewLessThanOneYear = "RefinanceNewLessThanOneYear"
}

export enum FHAAlimonyLiabilityTreatmentTypeEnum {
  AdditionToDebt = "AdditionToDebt",
  ReductionToIncome = "ReductionToIncome"
}

export enum EnergyImprovementTypeEnum {
  AdditionalNewFeature = "AdditionalNewFeature",
  InstallationSolar = "InstallationSolar",
  InsulationSealant = "InsulationSealant",
  Other = "Other",
  ReplacementMajorSystem = "ReplacementMajorSystem"
}

export enum FHADirectEndorsementApprovalTypeEnum {
  Approved = "Approved",
  ModifiedAndApproved = "ModifiedAndApproved"
}

export enum VAAppraisalTypeEnum {
  HUDConversion = "HUDConversion",
  LenderAppraisal = "LenderAppraisal",
  ManufacturedHome = "ManufacturedHome",
  MasterCertificateOfReasonableValueCase = "MasterCertificateOfReasonableValueCase",
  Other = "Other",
  PropertyManagementCase = "PropertyManagementCase",
  SingleProperty = "SingleProperty"
}

export enum VALoanProcedureTypeEnum {
  Automatic = "Automatic",
  AutomaticInterestRateReductionRefinanceLoan = "AutomaticInterestRateReductionRefinanceLoan",
  Other = "Other",
  VAPriorApproval = "VAPriorApproval"
}

export enum VATitleVestingTypeEnum {
  JointTwoOrMoreVeterans = "JointTwoOrMoreVeterans",
  JointVeteranAndNonVeteran = "JointVeteranAndNonVeteran",
  Other = "Other",
  Veteran = "Veteran",
  VeteranAndSpouse = "VeteranAndSpouse"
}

export class Dependent {
  dependentId: number;
  borrowerId: number;
  age: number;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class BorrowerAlias {
  borrowerAliasId: number;
  borrowerId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  nameSuffix: string;
  creditorName: string;
  accountNumber: string;
  aliasType: BorrowerAliasType;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum BorrowerAliasType {
  AlsoKnownAs = 'AlsoKnownAs',
  FormerlyKnownAs = 'FormerlyKnownAs',
  NowKnownAs = 'NowKnownAs',
}

export interface Expense {
  expenseId?: null | number;
  readonly borrowerId?: null | number;
  readonly monthlyAmount?: null | number;
  readonly expenseType?: null | ExpenseTypeEnum;
  readonly isCurrent?: null | boolean;
  readonly companyId?: null | number;
  readonly insertedBy?: null | string;
  readonly dateInserted?: null | string;
  readonly updatedBy?: null | string;
  readonly dateUpdated?: null | string;
}

export class Employment {
  employmentId: number;
  borrowerId: number;
  addressId: number;
  employer: string;
  employerEmail: string;
  employerPhone: string;
  aliases: string[] = [];
  employmentType: EmploymentTypeEnum;
  selfEmployed: boolean = null;
  isPrimary: boolean;
  position: string;
  specialRelationshipType: string;
  startDate: string;
  endDate: string;
  yearsOnJob: number;
  monthsOnJob: number;
  yearsInLineOfWork: number;
  fromCreditReport: boolean;
  monthsInLineOfWork: number;
  borrowerOwnershipShare: string = null;
  selfEmploymentForm?: null | string = null;
  borrowerHasSpecialRelationshipWithEmployer: boolean;
  selfEmploymentMonthlyIncomeOrLoss: number;
  theWorkNumberEmployerCode: string;
  verificationStatus: VerificationStatus;
  employerIdentificationNumber: string;
  address: Address;
  incomes: Income[] = [];
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class PurchaseCredit {
  purchaseCreditId: number;
  transactionDetailId: number;
  purchaseCreditType: string;
  purchaseCreditTypeOtherDescription: string;
  sourceTypeOtherDescription: string;
  sourceType: string;
  purchaseCreditAmount: number;
  explanation: string;
  companyId?: number;
  insertedBy?: string;
  dateInserted?: string;
  updatedBy?: string;
  dateUpdated?: string;
}

export class Income {
  incomeId?: null | number;
  borrowerId?: null | number; // null if employmentId is set
  monthlyIncome?: null | number;
  typeOfIncome?: null | TypeOfIncomeEnum;
  typeOfIncomeOtherDescription: string | null;
  fromCreditReport?: null | boolean;
  companyId?: null | number;
  insertedBy?: null | string;
  dateInserted?: null | string;
  updatedBy?: null | string;
  dateUpdated?: null | string;
  categoryOfIncome?: null | CategoryOfIncomeEnum;
  yearlyIncome?: null | number;
  employmentModel?: null | Employment;
  reoModel?: null | Reo;
  description?: null | string;
  employmentId?: null | number;
  verificationStatus: VerificationStatus;
  mccIncomeIsMappedAsTaxCredit?: null | boolean;

  amount: number; // fakes
  payPeriod: PayPeriod; //fake
  hoursPerWeek: number; //fake
}

export class Declarations {
  declarationId: number;
  haveJudgement: boolean;
  haveJudgementInfo: string;
  declaredBankruptcy: boolean;
  declaredBankruptcyInfo: string;
  beenForclosed: boolean;
  beenForclosedInfo: string;
  haveLawsuit: boolean;
  haveLawsuitInfo: string;
  haveLoanObligation: boolean;
  haveLoanObligationInfo: string;
  haveTaxDebt: boolean;
  haveTaxDebtInfo: string;
  haveChildSupport: boolean;
  haveChildSupportInfo: string;
  borrowedDownPayment: boolean;
  borrowedDownPaymentInfo: string;
  coSignedLoan: boolean;
  coSignedLoanInfo: string;
  isVeteran: boolean;
  residenceStatus: string;
  occupyProperty: boolean;
  havePropertiesOwnership: boolean;
  typeOfProperty: string;
  heldTitleHow: string;
  isFirstTimeHomeBuyer: boolean;
  explanations: DeclarationExplanation[] = [];
  fromCreditReport: boolean;
  haveRelationshipWithSeller: boolean;
  undisclosedMoneyAmount: number;
  applyingForOtherMortgage: boolean;
  applyingForNonMortgageCredit: boolean;
  propertyHasLienWithCleanEnergyProgram: boolean;
  haveConveyedTitleInLieuOfForeclosure: boolean;
  haveCompletedShortSale: boolean;
  bankruptcyType: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export interface DeclarationExplanation {
  description: string
  explanationType: DeclarationExplanationType
  explanationTypeOtherDescription?: string
}

export enum DeclarationExplanationType {
  AlimonyChildSupport = 'AlimonyChildSupport',
  BorrowedDownPayment = 'BorrowedDownPayment',
  CoMakerEndorserOnNote = 'CoMakerEndorserOnNote',
  DeclaredBankruptcyPastSevenYears = 'DeclaredBankruptcyPastSevenYears',
  DelinquencyOrDefault = 'DelinquencyOrDefault',
  DirectIndirectForeclosedPropertyPastSevenYears = 'DirectIndirectForeclosedPropertyPastSevenYears',
  ObligatedOnLoanForeclosedOrDeedInLieuOfJudgment = 'ObligatedOnLoanForeclosedOrDeedInLieuOfJudgment',
  Other = 'Other',
  OutstandingJudgments = 'OutstandingJudgments',
  PartyToLawsuit = 'PartyToLawsuit',
}

export class Asset {
  assetId: number;
  owningBorrowerIds: Array<string> = [];
  addressId: number;
  assetType: string;
  assetInsertionType: string;
  cashMarketValue: number;
  lifeInsuranceFaceValueAmount: number;
  accountNumber: string;
  depository: string;
  makeModel: string;
  year: number;
  disposition: string;
  morgagePayment: number;
  gross: number;
  netRentalIncome: number;
  dividendIncome: number;
  insMainTaxMisc: number;
  stockBondMutualFundShareCount: number;
  otherAssetTypeDescription: string;
  isDeposited: boolean;
  giftOrGrantSource: string;
  giftOrGrantSourceOtherDescription: string;
  holderAddress1: string;
  holderAddress2: string;
  holderCity: string;
  holderState: string;
  holderZipCode: string;
  holderCounty: string;
  holderCountry: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
  isDownPaymentSource: boolean;
  verificationStatus: string;
  verifiedBy: string;
  isLiquid: boolean;
}

export enum PayoffType {
  None = "None",
  Partial = "Partial",
  Full = "Full"
}

export enum NativeAmericanLandsType {
  AllottedTribalLand = 'AllottedTribalLand',
  HawaiianHomeLand = 'HawaiianHomeLand',
  Other = 'Other',
  FeeSimpleOnReservation = 'FeeSimpleOnReservation',
  IndividualTrustLand = 'IndividualTrustLand',
  TribalTrustLandOnReservation = 'TribalTrustLandOnReservation',
  TribalTrustLandOffReservation = 'TribalTrustLandOffReservation',
  AlaskaNativeCorporationLand = 'AlaskaNativeCorporationLand',
}

export enum RecordingJurisdictionType {
  County = "County",
  Other = "Other"
}

export enum PropertyTitleType {
  CommunityProperty = 'CommunityProperty',
  Individual = 'Individual',
  JointTenancyWithRightOfSurvivorship = 'JointTenancyWithRightOfSurvivorship',
  LeasedFee = 'LeasedFee',
  LifeEstate = 'LifeEstate',
  TenancyByTheEntirety = 'TenancyByTheEntirety',
  TenancyInCommon = 'TenancyInCommon',
  ToBeDecidedInEscrow = 'ToBeDecidedInEscrow',
  Other = 'Other',
}

export enum BorrowerRelationshipTitleType {
  AHusbandAndWife = 'AHusbandAndWife',
  AMarriedCouple = 'AMarriedCouple',
  AMarriedMan = 'AMarriedMan',
  AMarriedPerson = 'AMarriedPerson',
  AMarriedWoman = 'AMarriedWoman',
  AnUnmarriedMan = 'AnUnmarriedMan',
  AnUnmarriedPerson = 'AnUnmarriedPerson',
  AnUnmarriedWoman = 'AnUnmarriedWoman',
  ASameSexMarriedCouple = 'ASameSexMarriedCouple',
  AsDomesticPartners = 'AsDomesticPartners',
  ASingleMan = 'ASingleMan',
  ASinglePerson = 'ASinglePerson',
  ASingleWoman = 'ASingleWoman',
  AWidow = 'AWidow',
  AWidower = 'AWidower',
  AWifeAndHusband = 'AWifeAndHusband',
  HerHusband = 'HerHusband',
  HisWife = 'HisWife',
  JoinedInACivilUnion = 'JoinedInACivilUnion',
  JoinedInACommonLawMarriage = 'JoinedInACommonLawMarriage',
  NotApplicable = 'NotApplicable',
  Other = 'Other',
}

export class Liability {
  liabilityId: number;
  owningBorrowerIds: Array<number>;
  reoId: number;
  typeOfLiability: string;
  typeOfLiabilityOtherDescription: string;
  accountNumber: string;
  attention: string;
  description: string;
  monthlyPayment: number;
  monthsLeftToPay: number;
  unpaidBalance: number;
  creditLimit: number;
  interestRate: number;
  payoffType: PayoffType | string;
  partialPayoffAmount: number;
  isResubordinate: boolean;
  isExcluded: boolean;
  isSecondaryMortgageThatWillBeFinanced: boolean;
  isUwRequiredPayoff: boolean;
  lienPosition: string;
  holderName: string;
  holderAddress1: string;
  holderCity: string;
  holderState: string;
  holderZipCode: string;
  holderPhone: string;
  holderFax: string;
  holderComments: string;
  late30Days: number;
  late60Days: number;
  late90Days: number;
  late120Days: number;
  consumerDisputeIndicator: boolean;
  derogatoryDataIndicator: boolean;
  fromCreditReport: boolean;
  mortgageType: string;
  mortgageCreditLimit: number;
  mortgageMonthlyPaymentIncludesTaxesAndInsurance: boolean;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
  liabilityWillBePaidOffWithCurrentAssets: boolean;
}

export class RealEstateOwned {
  reoId: number;
  owningBorrowerIds: Array<number> = [];
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  typeOfProperty: string;
  dispositionStatus: string;
  marketValue: number;
  amountOfMortgage: number;
  percentOwned: number;
  vacancyFactor: number;
  grossRentalIncome: number;
  netRentalIncome: number;
  mortgagePayment: number;
  monthlyMiscExpenses: number;
  isCurrentResidence: boolean;
  isSharedCoborrowerReo: boolean;
  isSubjectProperty: boolean;
  omitPresentValueFromAssets: boolean;
  omitNetRentalIncome: boolean;
  includeTaxesInsuranceInDebtRatios: boolean;
  currentUsageType: string;
  intendedUsageType: string;
  unitCount: number;
  projectType: string;
  liabilities: Array<Liability> = [];
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
  assetAccountId: string;

  otherFinance: number;
  hazardInsurance: number;
  propertyTax: number;
  mortgageInsurance: number;
  hoa: number;
  otherMonthlyExpense: number;
}

export class Demographics {
  governmentMonitorId: number | undefined;
  sex: string | undefined;
  race: string | undefined;
  raceDesignation: string | undefined;
  ethnicity: string | undefined;
  ethnicityOtherDescription: string | undefined;
  ethnicityOrigin: string | undefined;
  ethnicityOriginOtherDescription: string | undefined;
  otherAsianRace: string | undefined;
  otherPacificIslanderRace: string | undefined;
  nativeAmericanTribeName: string | undefined;
  applicationTakenMethod: string | undefined;
  isEthnicityBasedOnVisualOrSurname: boolean | undefined;
  isGenderBasedOnVisualOrSurname: boolean | undefined;
  isRaceBasedOnVisualOrSurname: boolean | undefined;
}

export enum EthnicityType {
  HispanicOrLatino = 'HispanicOrLatino',
  InformationNotProvided = 'InformationNotProvided',
  NotApplicable = 'NotApplicable',
  NotHispanicOrLatino = 'NotHispanicOrLatino',
  Other = 'Other',
}

export class DocPrepTrustParty {
  first: string;
  middle: string;
  last: string;
  suffix: string;
  borrower: boolean;
  settlor: boolean;
  trustee: boolean;
}

export interface ExtendedEmployment extends Employment {
  duration: string;
}

export interface ExtendedMortgageBorrower extends MortgageBorrower {
  last4Ssn: string;
  employments: Array<ExtendedEmployment>;
  digitallySourcedEmployments: Array<any>;
  shouldAccordionExpanded: boolean;
  form: any;
}

export interface Reo {
  readonly address1?: null | string;
  readonly address2?: null | string;
  readonly city?: null | string;
  readonly state?: null | string;
  readonly zipCode?: null | string;
  readonly country?: null | string;
  readonly amountOfMortgage?: null | number;
  readonly borrowerId?: null | number;
  readonly companyId?: null | number;
  readonly dateInserted?: null | string;
  readonly dateUpdated?: null | string;
  readonly dispositionStatus?: null | DispositionStatusEnum;
  readonly grossRentalIncome?: null | number;
  readonly insertedBy?: null | string;
  readonly isCurrentResidence?: null | boolean;
  readonly isSubjectProperty?: null | boolean;
  readonly marketValue?: null | number;
  readonly monthlyMiscExpenses?: null | number;
  readonly mortgagePayment?: null | number;
  readonly netRentalIncome?: null | number;
  readonly reoId?: null | number;
  readonly typeOfProperty?: null | TypeOfPropertyEnum;
  readonly updatedBy?: null | string;
}

export class DenialTerminationChangeDetail {
  denialTerminationChangeDetailId: number | null;
  noCreditFile: boolean;
  limitedCreditExperience: boolean;
  insufficientCredit: boolean;
  insufficientNumberOfCreditReferencesProvided: boolean;
  unacceptableTypeOfCreditReferencesProvided: boolean;
  unableToVerifyCreditReferences: boolean;
  garneshmentAttachment: boolean;
  foreclosureRepossession: boolean;
  collectionJudgement: boolean;
  bankruptcy: boolean;
  excessiveObligationsInRelationToIncome: boolean;
  poorCreditPerformanceWithUs: boolean;
  delinquentPastOrPresentCreditObligationsWithOthers: boolean;
  numberOfRecentInquiriesOnCreditBureauReport: boolean;
  unacceptablePaymentRecordOnPreviousMortgage: boolean;
  factorsThatAdverseleyAffectedCreditScore: string | null;
  unableToVerifyEmployment: boolean;
  lengthOfEmployment: boolean;
  temporaryOrIrregularEmployment: boolean;
  incomeInsufficientForAmountOfCreditRequested: boolean;
  insuffientIncomeForTotalObligations: boolean;
  unableToVerifyIncome: boolean;
  temporaryResidence: boolean;
  lengthOfResidence: boolean;
  unableToVerifyResidence: boolean;
  descriptionOfActionTaken: string = 'Application denied by financial institution';
  descriptionOfAccount: string = 'Applying for mortgage';
  creditApplicationIncomplete: boolean;
  valueOrTypeOfCollateralIsNotSufficient: boolean;
  inadequateCollateral: boolean;
  unacceptableProperty: boolean;
  insufficientPropertyData: boolean;
  unacceptableAppraisal: boolean;
  unacceptableLeaseholdEstate: boolean;
  otherReasons: string[];
  deniedByUserId: string | null;
  deniedByUserName: string | null;
}

export class MortgageCalculationResponse {
  value: number;
}

export class DenialTerminationGenerateDocRequest {
  mortgageId: number;
  saveAsDocumentTypeId: number;
  sendToBorrowers: boolean = true;
}

export class IdentifyingDocument {
  identifyingDocumentId: number;
  borrowerId: number;
  docFileId: number;
  documentType: string;
  documentTypeOtherDescription: string;
  identifier: string;
  issuedByType: string;
  issuedByTypeOtherDescription: string;
  issuedByAgency: string;
  issuedByCountry: string;
  issuedByStateCode: string;
  issueDate: string;
  expirationDate: string;
  hasPhoto: boolean;
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: string;
  dateUpdated: string;

  file?: any;
  loanDocId?: number;
}

export class NetRentalIncomeParams {
  grossRentalIncome: number;
  occupancyFactor: number;
  mortgagePayment: number;
  miscExpenses: number;
}

//#region - enums
export enum EstateEnum {
  'Fee Simple' = 0,
  'Lease Hold' = 1,
}

export enum PropertyTypeEnum {
  'Single Family Residence - Detached' = 3,
  'Single Family Residence - Attached' = 0,
  'Condominium / Townhome' = 1,
  'Condominium - High Rise' = 4,
  'Condominium - Detached' = 10,
  'Cooperative' = 2,
  'Manufactured Housing' = 5,
  'PUD' = 7,
  // 'Modular' = 6,
  // 'Manufactured Housing Single Wide' = 8,
  // 'Manufactured Housing Double Wide' = 9,
  // 'Manufactured Home Condominium' = 11,
  // 'Manufactured Housing Multi Wide' = 12,
  // 'Manufactured Home Condominium Or PUD Or Cooperative' = 13,
  // 'Two To Four Unit Building' = 14,
  // 'Non Warranted Condo' = 15,
}

export enum LoanPurposeEnum {
  'Refinance' = 0,
  'Purchase' = 1,
  'Construction Only' = 2,
  'Construction To Permanent' = 3,
  'Other Loan Purpose' = 4,
  'Refinance Cash Out' = 5,
}

export enum PropertyWillBeEnum {
  'Primary Residence' = 0,
  'Secondary Residence' = 1,
  'Investment' = 2,
  // 'Short Term Rental' = 3,
}

export enum RefiPurposeEnum {
  'Purchase' = 0,
  'Refinance Cash Out' = 1,
  'Refinance No Cash Out' = 2,
  'Streamlined Refinance' = 3,
  'Construction' = 4,
}

export enum AmortizationTypeEnum {
  'Fixed Rate' = 0,
  'GEM' = 1,
  'GPM' = 2,
  'ARM' = 3,
  'Other Amortization' = 4,
  'GraduatedPaymentARM' = 5,
  'RateImprovementMortgage' = 6,
  'Step' = 7
}

export enum LevelOfEducationEnum {
  'Some High School' = 10,
  'High school diploma or GED' = 12,
  'Some college' = 13,
  "Associate's degree / Two-year" = 14,
  "Bachelor's degree" = 16,
  "Master's degree" = 17,
  'Doctoral degree' = 18,
  'None of the above' = 0,
}

export enum MaritalStatusEnum {
  'Single' = 0,
  'Married' = 1,
  'Separated' = 2,
}

export enum ExpenseTypeEnum {
  'First Mortgage Principal And Interest' = 0,
  'Hazard Insurance' = 1,
  'Homeowners Insurance' = 2,
  'Homeowners Association Dues And Condominium Fees' = 3,
  'Morgage Insurance' = 4,
  'Other Housing Expense' = 5,
  'Other Mortgage Loan Principal And Interest' = 6,
  'Real Estate Tax' = 7,
  'Rent' = 8,
}

export enum EmploymentTypeEnum {
  CurrentEmployer = 'CurrentEmployer',
  FormerEmployer = 'FormerEmployer',
}

export enum VerificationStatus {
  NotVerified = 'NotVerified',
  ToBeVerified = 'ToBeVerified',
  Verified = 'Verified',
}

export enum BusinessTypeEnum {
  'Sole Proprietor' = 0,
  'LLC' = 1,
  'S-Corp' = 2,
  'C-Corp' = 3,
  'Partnership' = 4,
}

export enum TypeOfIncomeEnum {
  'AlimonyChildSupport' = 'AlimonyChildSupport',
  'AutomobileExpenseAccount' = 'AutomobileExpenseAccount',
  'Base' = 'Base',
  'Bonus' = 'Bonus',
  'Commissions' = 'Commissions',
  'DividendsInterest' = 'DividendsInterest',
  'Employment Income' = 6,
  'FosterCare' = 'FosterCare',
  'NetRentalIncome' = 'NetRentalIncome',
  'Notes Receivable Installment' = 'NotesReceivableInstallment',
  'OtherTypesOfIncome' = 'OtherTypesOfIncome',
  'Overtime' = 'Overtime',
  'Pension' = 'Pension',
  'Social Security' = 'SocialSecurity',
  'SubjectPropertyNetCashFlow' = 'SubjectPropertyNetCashFlow',
  'Trust' = 'Trust',
  'Unemployment' = 'Unemployment',
  'PublicAssistance' = 'PublicAssistance',
  'VABenefitsNonEducational' = 'VABenefitsNonEducational',
  'MortgageDifferentiall' = 'MortgageDifferential',
  'MilitaryBasePay' = 'MilitaryBasePay',
  'MilitaryRationsAllowance' = 'MilitaryRationsAllowance',
  'MilitaryFlightPay' = 'MilitaryFlightPay',
  'MilitaryHazardPay' = 'MilitaryHazardPay',
  'MilitaryClothesAllowance' = 'MilitaryClothesAllowance',
  'MilitaryQuartersAllowance' = 'MilitaryQuartersAllowance',
  'MilitaryPropPay' = 'MilitaryPropPay',
  'MilitaryOverseasPay' = 'MilitaryOverseasPay',
  'MilitaryCombatPay' = 'MilitaryCombatPay',
  'MilitaryVariableHousingAllowance' = 'MilitaryVariableHousingAllowance',
}

export enum CategoryOfIncomeEnum {
  'Employment' = 'Employment',
  'IndepedentContractor' = 'IndependentContractor',
  'MilitaryPay' = 'MilitaryPay',
  'Rental' = 'Rental',
  'SocialSecurity' = 'SocialSecurity',
  'Pension' = 'Pension',
  'BuisnessSelfEmployment' = 'BuisnessSelfEmployment',
  'Other' = 'Other',
}

export enum DispositionStatusEnum {
  'Sold' = 0,
  'Pending Sale' = 1,
  'Retain For Primary Or Secondary Residence' = 2,
  'Rental Property' = 3,
}

export enum TypeOfPropertyEnum {
  'Single Family' = 0,
  'Condo' = 1,
  'Townhouse' = 2,
  'Coop' = 3,
  'Two To Four Unit Property' = 4,
  'Multifamily More Than Four Units' = 5,
  'Commercial Non Residential' = 6,
  'Mixed Use Residential' = 7,
  'Farm' = 8,
  'Home And Business Combined' = 9,
  'Land' = 10,
}

export enum PurchaseCreditType {
  BridgeLoan = "BridgeLoan",
  CashOnHand = "CashOnHand",
  CheckingSavings = "CheckingSavings",
  DepositOnSalesContract = "DepositOnSalesContract",
  EquityOnPendingSale = "EquityOnPendingSale",
  EquityOnSoldProperty = "EquityOnSoldProperty",
  EquityOnSubjectProperty = "EquityOnSubjectProperty",
  GiftFunds = "GiftFunds",
  LifeInsuranceCashValue = "LifeInsuranceCashValue",
  LotEquity = "LotEquity",
  OtherTypeOfDownPayment = "OtherTypeOfDownPayment",
  RentWithOptionToPurchase = "RentWithOptionToPurchase",
  RetirementFunds = "RetirementFunds",
  SaleOfChattel = "SaleOfChattel",
  SecuredBorrowedFunds = "SecuredBorrowedFunds",
  StocksAndBonds = "StocksAndBonds",
  SweatEquity = "SweatEquity",
  TradeEquity = "TradeEquity",
  TrustFunds = "TrustFunds",
  UnsecuredBorrowedFunds = "UnsecuredBorrowedFunds",
  EarnestMoney = "EarnestMoney",
  RelocationFunds = "RelocationFunds",
  EmployerAssistedHousing = "EmployerAssistedHousing",
  LeasePurchaseFund = "LeasePurchaseFund",
  Other = "Other",
  LenderCredit = "LenderCredit",
  SellerCredit = "SellerCredit",
  BorrowerPaidFees = "BorrowerPaidFees",
  FhaGiftSourceNotApplicable = "FhaGiftSourceNotApplicable",
  FhaGiftSourceRelative = "FhaGiftSourceRelative",
  FhaGiftSourceGovernmentAssistance = "FhaGiftSourceGovernmentAssistance",
  FhaGiftSourceEmployer = "FhaGiftSourceEmployer",
  FhaGiftSourceReligiousSellerFunded = "FhaGiftSourceReligiousSellerFunded",
  FhaGiftSourceReligiousNonSellerFunded = "FhaGiftSourceReligiousNonSellerFunded",
  SaleOfPrimaryResidence = "SaleOfPrimaryResidence",
  SecondaryFinancingClosedEnd = "SecondaryFinancingClosedEnd",
  SecondaryFinancingHELOC = "SecondaryFinancingHELOC",
  MIPremiumRefund = "MIPremiumRefund",
  TradeEquityFromPropertySwap = "TradeEquityFromPropertySwap",
  FuelCosts = "FuelCosts",
  Gift = "Gift",
  Grant = "Grant",
  ProceedsOfSubordinateLiens = "ProceedsOfSubordinateLiens",
  RebateCredit = "RebateCredit",
  RentFromSubjectProperty = "RentFromSubjectProperty",
  RepairCompletionEscrowHoldback = "RepairCompletionEscrowHoldback",
  Repairs = "Repairs",
  SatisfactionOfSubordinateLien = "SatisfactionOfSubordinateLien",
  SellersEscrowAssumption = "SellersEscrowAssumption",
  SellersMortgageInsuranceAssumption = "SellersMortgageInsuranceAssumption",
  SellersReserveAccountAssumption = "SellersReserveAccountAssumption",
  Services = "Services",
  SubordinateFinancingProceeds = "SubordinateFinancingProceeds",
  TenantSecurityDeposit = "TenantSecurityDeposit",
  TitlePremiumAdjustment = "TitlePremiumAdjustment",
  UnpaidUtilityEscrowHoldback = "UnpaidUtilityEscrowHoldback",
  BuydownFund = "BuydownFund",
  CommitmentOriginationFee = "CommitmentOriginationFee",
  FederalAgencyFundingFeeRefund = "FederalAgencyFundingFeeRefund",
  GiftOfEquity = "GiftOfEquity",
  ClosingCosts = "ClosingCosts",
  ExcessDeposit = "ExcessDeposit",
}

export enum PurchaseCreditSourceType {
  Borrower = "Borrower",
  PropertySeller = "PropertySeller",
  Lender = "Lender",
  Other = "Other",
  BuilderDeveloper = "BuilderDeveloper",
  Employer = "Employer",
  FederalAgency = "FederalAgency",
  LocalAgency = "LocalAgency",
  NonParentRelative = "NonParentRelative",
  Parent = "Parent",
  RealEstateAgent = "RealEstateAgent",
  StateAgency = "StateAgency",
  UnrelatedFriend = "UnrelatedFriend",
}

export type MortgageCalculationType =
  | 'AggregateAdjustment'
  | 'MonthlyPrincipalAndInterest'
  | 'MonthlyMortgageInsurance';

export type MortgageLanguage =
  | 'Chinese'
  | 'English'
  | 'Korean'
  | 'Spanish'
  | 'Tagalog'
  | 'Vietnamese'
  | 'Other'
  ;
//#endregion
