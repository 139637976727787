import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { EmailTemplate } from "src/app/modules/correspondence/models/email-template.model";
import { EmailCampaign } from "../models/email-campaign.model";

export enum EmailTemplateType {
  Loan = "Loan",
  Lead = "Lead"
}

@Injectable()
export class EmailCampaignConfigService {
  constructor(private readonly _dataService: DataService) { }

  getEmailTemplates = (): Observable<EmailTemplate[]> => {
    const url = `api/admin/email-campaigns/templates`;
    return this._dataService.get(url);
  };

  getEmailTemplate = (emailTemplateId: number): Observable<EmailTemplate> => {
    const url = `api/admin/email-campaigns/templates/${emailTemplateId}`;
    return this._dataService.get(url);
  };

  insertEmailTemplate = (template: EmailTemplate): Observable<EmailTemplate> => {
    const url = `api/admin/email-campaigns/templates`;
    return this._dataService.post(url, template);
  };

  updateEmailTemplate = (templateId: number, template: EmailTemplate): Observable<EmailTemplate> => {
    const url = `api/admin/email-campaigns/templates/${templateId}`;
    return this._dataService.post(url, template);
  };

  deleteEmailTemplate = (templateId: number): Observable<any> => {
    const url = `api/admin/email-campaigns/templates/${templateId}`;
    return this._dataService.delete(url);
  };

  reOrderEmailTemplates = (emailTemplateIds: number[]): Observable<any> => {
    const url = `api/admin/email-campaigns/UpdateEmailTemplateOrder`;
    return this._dataService.post(url, emailTemplateIds.map(id => {
      return {
        id: id
      }
    }));
  };

  getEmailManualTemplates = (emailTemplateType?: EmailTemplateType, userGuid?: string): Observable<EmailTemplate[]> => {
    let url = `api/admin/email-campaigns/templates`;

    if (emailTemplateType || userGuid) {
      url += '?' + _.compact([
        emailTemplateType ? `emailTemplateType=${emailTemplateType}` : '',
        userGuid ? `userGuid=${userGuid}` : ''
      ]).join('&');
    }

    return this._dataService.get(url);
  };

  getEmailManualTemplate = (emailTemplateId: number, loanId?: number, leadId?: number): Observable<EmailTemplate> => {
    let url = `api/admin/email-campaigns/templates/${emailTemplateId}`;

    if (loanId || leadId) {
      url += '?' + _.compact([
        loanId ? `loanId=${loanId}` : '',
        leadId ? `leadId=${leadId}` : ''
      ]).join('&');
    }

    return this._dataService.get(url);
  }

  // email campaigns
  getEmailCampaigns = (): Observable<EmailCampaign[]> => {
    const url = `api/admin/email-campaigns/campaigns`;
    return this._dataService.get(url);
  };

  getEmailCampaign = (emailCampaignId: number): Observable<EmailCampaign> => {
    const url = `api/admin/email-campaigns/campaigns/${emailCampaignId}`;
    return this._dataService.get(url);
  };

  insertEmailCampaign = (campaign: EmailCampaign): Observable<EmailCampaign> => {
    const url = `api/admin/email-campaigns/campaigns`;
    return this._dataService.post(url, campaign);
  };

  updateEmailCampaign = (emailCampaignId: number, campaign: EmailCampaign): Observable<EmailCampaign> => {
    const url = `api/admin/email-campaigns/campaigns/${emailCampaignId}`;
    return this._dataService.post(url, campaign);
  };

  deleteEmailCampaign = (emailCampaignId: number): Observable<any> => {
    const url = `api/admin/email-campaigns/campaigns/${emailCampaignId}`;
    return this._dataService.delete(url);
  };

  emailCampaignUnsubscribe = (campaignGuid: string, emailAddress: string): Observable<void> => {
    const url = `api/campaign/${campaignGuid}/unsubscribe/${encodeURIComponent(emailAddress)}`;
    return this._dataService.post(url, null);
  };
}
