<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h4 class="card-title">Selected Product and Pricing</h4>                      
                    </div>
                </div>                                 
            </div>
            <div class="card-body">
                <div class="row table-responsive" style="border-bottom: none;">
                    <table class="table mb-0">
                        <thead class="table-light">
                            <tr>
                                <td class="col-md-3 col-sm-3 col-xs-3">Program Name</td>
                                <td class="col-md-2 col-sm-2 col-xs-2">Lock Status</td>
                                <td class="col-md-1 col-sm-1 col-xs-1">Rate</td>
                                <td class="col-md-2 col-sm-2 col-xs-1">APR</td>
                                <td class="col-md-2 col-sm-2 col-xs-1">Price</td>
                                <td class="col-md-2 col-sm-2 col-xs-1">Comp Type</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="productPricing">
                                <td class="col-md-3 col-sm-3 col-xs-3">{{productPricing.productName}}</td>
                                <td class="col-md-2 col-sm-2 col-xs-2">{{productPricing.lockStatus}}</td> <!-- not mapped  -->
                                <td class="col-md-1 col-sm-1 col-xs-1">{{productPricing.rate/100 | percent:'1.2-3'}}</td>
                                <td class="col-md-2 col-sm-2 col-xs-1">{{productPricing.apr}}</td>
                                <td class="col-md-2 col-sm-2 col-xs-1">{{productPricing.price}}</td>
                                <td class="col-md-2 col-sm-2 col-xs-1">{{productPricing.compType}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
            </div>
        </div>
    </div>
</div>