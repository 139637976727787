import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThirdPartyCredential, ThirdPartyKeyValue } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { LenderPriceKeyValuePairs } from '../pricing.component';
import { PricingCustomFieldService } from 'src/app/services/pricing-custom-field.service';
import { combineLatest } from 'rxjs';
import { CustomField } from 'src/app/modules/pricing/models/pricing/custom-fields.model';
import { cloneDeep, isArray, orderBy } from 'lodash';
import { RepriceFieldsDialogComponent } from '../reprice-fields-dialog/reprice-fields-dialog.component';
import { Constants } from 'src/app/services/constants';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';

@Component({
  selector: 'lenderprice-vendor-editor-dialog',
  templateUrl: 'lenderprice-vendor-editor-dialog.component.html',
  styleUrls: ['./lenderprice-vendor-editor-dialog.component.scss'],
})
export class LenderPriceVendorEditorDialogComponent implements OnInit {

  @ViewChild("lenderPriceForm")
  lenderPriceForm: NgForm;

  @Input()
  vendor: ThirdPartyCredential;

  @Input()
  scope: string;

  @Input()
  userCompanyGuid: string

  @Input()
  inEditMode: boolean = true;

  @Input()
  branchId?: number;

  lenderPriceKeyValuePairs: LenderPriceKeyValuePairs = new LenderPriceKeyValuePairs();
  customFields: CustomField[] = [];
  loadingCustomFields: boolean;
  orginalCustomFields: CustomField[] = [];

  vendorUrlList: string[] = [
    'https://uat-rapi.lenderprice.com',
    'https://ppe-rapi.lenderprice.com'
  ]

  vendoroAuthBaseUrlList: string[] = [
    'https://ppe-stg-auth.cre8techdev.com',
    'https://ppe-auth.lenderprice.com'
  ]

  constructor(public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _pricingCustomFieldService: PricingCustomFieldService) { }

  ngOnInit() {
    this.loadCustomFields();
    const apiKeyKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ApiKey');
    if (apiKeyKeyValuePair) {
      this.lenderPriceKeyValuePairs.apiKey = apiKeyKeyValuePair.value;
    }
    const companyIdKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'LenderPriceCompanyId');
    if (companyIdKeyValuePair) {
      this.lenderPriceKeyValuePairs.lenderPriceCompanyId = companyIdKeyValuePair.value;
    }
    const clientIdKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'UseLegacyAuth');
    this.lenderPriceKeyValuePairs.useLegacyAuth = clientIdKeyValuePair ? clientIdKeyValuePair.value : "0";

    const productCodeKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'IsProductCodeUnique');
    this.lenderPriceKeyValuePairs.useLegacyAuth = productCodeKeyValuePair ? clientIdKeyValuePair.value : "0";

    const oAuthBaseUrl = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'OAuthBaseUrl');
    this.lenderPriceKeyValuePairs.oAuthBaseUrl = oAuthBaseUrl?.value || '';
  }

  saveCredential = () => {
    this.lenderPriceForm.form.markAllAsTouched();
    if (!this.lenderPriceForm.form.valid) {
      return;
    }

    if (this.scope === 'User') {
      this.vendor.userId = this.userCompanyGuid;
    }

    if (this.scope === 'Branch') {
      this.vendor.branchId = String(this.branchId);
    }

    let apiKeyKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'ApiKey');
    if (!apiKeyKeyValuePair) {
      apiKeyKeyValuePair = new ThirdPartyKeyValue("ApiKey", this.lenderPriceKeyValuePairs.apiKey);
      this.vendor.thirdPartyKeyValuePairs.push(apiKeyKeyValuePair);
    } else {
      apiKeyKeyValuePair.value = this.lenderPriceKeyValuePairs.apiKey;
    }

    let companyIdValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'LenderPriceCompanyId');
    if (!companyIdValuePair) {
      companyIdValuePair = new ThirdPartyKeyValue("LenderPriceCompanyId", this.lenderPriceKeyValuePairs.lenderPriceCompanyId);
      this.vendor.thirdPartyKeyValuePairs.push(companyIdValuePair);
    } else {
      companyIdValuePair.value = this.lenderPriceKeyValuePairs.lenderPriceCompanyId;
    }

    let useLegacyAuthKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'UseLegacyAuth');
    if (!useLegacyAuthKeyValuePair) {
      useLegacyAuthKeyValuePair = new ThirdPartyKeyValue("UseLegacyAuth", this.lenderPriceKeyValuePairs.useLegacyAuth);
      this.vendor.thirdPartyKeyValuePairs.push(useLegacyAuthKeyValuePair);
    } else {
      useLegacyAuthKeyValuePair.value = this.lenderPriceKeyValuePairs.useLegacyAuth;
    }

    let isProductCodeUniqueKeyValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'IsProductCodeUnique');
    if (!isProductCodeUniqueKeyValuePair) {
      isProductCodeUniqueKeyValuePair = new ThirdPartyKeyValue("IsProductCodeUnique", this.lenderPriceKeyValuePairs.isProductCodeUnique);
      this.vendor.thirdPartyKeyValuePairs.push(isProductCodeUniqueKeyValuePair);
    } else {
      isProductCodeUniqueKeyValuePair.value = this.lenderPriceKeyValuePairs.isProductCodeUnique;
    }

    let oAuthBaseUrlValuePair = this.vendor.thirdPartyKeyValuePairs.find(p => p.key === 'OAuthBaseUrl');
    if (!oAuthBaseUrlValuePair) {
      oAuthBaseUrlValuePair = new ThirdPartyKeyValue("OAuthBaseUrl", this.lenderPriceKeyValuePairs.oAuthBaseUrl);
      this.vendor.thirdPartyKeyValuePairs.push(oAuthBaseUrlValuePair);
    } else {
      oAuthBaseUrlValuePair.value = this.lenderPriceKeyValuePairs.oAuthBaseUrl;
    }

    const observer = {
      next: ((result: any) => {
        this.activeModal.close(isArray(result) ? result[0] : result);
        this._spinner.hide();
        this._notificationService.showSuccess(
          'Vendor successfuly updated',
          'Success!'
        );
      }),
      error: ((error: any) => {
        this._spinner.hide();
        this._notificationService.showError(
          error?.message || 'Unable to update Vendor',
          'Failure!'
        );
      })
    }
    this._spinner.show();
    const obs: any = [
      this._systemLevelService.saveCredential(this.vendor)
    ];
    if (this.scope !== 'User' && this.scope !== 'Branch') {
      this.customFields.forEach((cf: CustomField, index: number) => {
        cf.sortOrder = index;
        if (cf.options?.length > 0 && cf.type !== 'List') {
          cf.options = [];
        }
        if (cf.pricingCustomFieldId) {
          obs.push(this._pricingCustomFieldService.updateCustomField(cf));
        } else {
          obs.push(this._pricingCustomFieldService.insertCustomField(cf));
        }
      });
      const deletedCustomFields = this.orginalCustomFields.filter(o => !this.customFields.map(c => c.pricingCustomFieldId).includes(o.pricingCustomFieldId));
      deletedCustomFields.forEach(del => obs.push(this._pricingCustomFieldService.deleteCustomField(del?.pricingCustomFieldId)));
    }
    combineLatest(obs).subscribe(observer);
  }

  onRepriceFieldsClicked = () => {
    const modalRef = this._modalService.open(RepriceFieldsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.credentialId = this.vendor.credentialId;
    modalRef.componentInstance.vendor = PricingVendor.LenderPrice;
  }

  isValChanged = (val: string, key: string): string => {
    if (key == 'useLegacyAuth') {
      return this.lenderPriceKeyValuePairs.useLegacyAuth = (val == "1") ? "0" : "1";
    } else {
      return this.lenderPriceKeyValuePairs.isProductCodeUnique = (val == "1") ? "0" : "1";
    }
  }


  loadCustomFields() {
    this.loadingCustomFields = true;
    this._pricingCustomFieldService.getCustomFields('LenderPrice').subscribe({
      next: ((result: CustomField[]) => {
        this.customFields = orderBy(result, 'sortOrder');
        this.orginalCustomFields = cloneDeep(result);
      }),
      error: ((error: any) => {
        this._notificationService.showError(error?.message || 'Unable to get custom fields for Lender Price', 'Failure!');
      })
    }).add(() => this.loadingCustomFields = false);
  }

  addCustomField(): void {
    this.customFields.push(new CustomField('LenderPrice'));
  }

  trackByIndex(index: number): number {
    return index;
  };
}
