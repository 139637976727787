<div
  *ngIf='externalError as error'
  class='alert alert-danger border-0'
  role='alert'
>
  <i class='fa fa-exclamation-triangle me-2'></i>
  {{ error }}
</div>

<div class='card'>
  <form
    #form='ngForm'
    class='card-body row m-0'
  >
    <ng-container *ngIf='employment'>
      <ng-container *ngIf='isRetired; else notRetired'>
        <!-- Employment Details -->
        <div class='col-6 d-flex flex-column align-items-start'>
          <h5>Employment Details</h5>

          <!-- isRetired checkbox -->
          <div class='form-check mb-2'>
            <input
              [(ngModel)]='isRetired'
              class='form-check-input'
              id='is-retired-in-retired'
              name='isRetiredInRetired'
              type='checkbox'
            >

            <label
              class='form-check-label'
              for='is-retired-in-retired'
            >
              Retired
            </label>
          </div>

          <!-- startDate -->
          <div class='form-group'>
            <label for='start-date-retired'>Start Date *</label>

            <date-input
              [(ngModel)]='employment.startDate'
              [required]='true'
              id='start-date-retired'
              name='startDateRetired'
            ></date-input>
          </div>
        </div>
      </ng-container>

      <ng-template #notRetired>
        <!-- Employment Details -->
        <div class='col-12 col-lg'>
          <h5>Employment Details</h5>

          <!-- isCurrent, isRetired and isPrimary checkboxes -->
          <div class='d-flex gap-3 my-2'>

            <div
              [ngClass]="{
                          'ng-invalid-requested' : employmentType && employmentType.invalid && employmentType.errors && employmentType.errors.required
                            && quickApplyFieldsConfig['mortgage.borrower.employment.employmentType'] == 'requested',
                          'ng-invalid-required' : employmentType && employmentType.invalid && employmentType.errors && employmentType.errors.required
                            && quickApplyFieldsConfig['mortgage.borrower.employment.employmentType'] == 'required'
                        }"
              class='radio-container'
            >
              <!-- isCurrent checkbox -->
              <div class='form-check'>
                <input
                  #employmentType='ngModel'
                  [(ngModel)]='isCurrent'
                  [required]="quickApplyFieldsConfig['mortgage.borrower.employment.employmentType'] == 'required' ||
                          quickApplyFieldsConfig['mortgage.borrower.employment.employmentType'] == 'requested'"
                  class='form-check-input'
                  id='is-current'
                  name='isCurrent'
                  type='checkbox'>
                <label class='form-check-label' for='is-current'>
                  Current
                </label>
              </div>
            </div>

            <!-- isRetired checkbox -->
            <div class='radio-container'>
              <div class='form-check'>
                <input
                  [(ngModel)]='isRetired'
                  class='form-check-input'
                  id='is-retired'
                  name='isRetired'
                  type='checkbox'
                >

                <label class='form-check-label' for='is-retired'>
                  Retired
                </label>
              </div>
            </div>

            <!-- isPrimary checkbox -->
            <div class='radio-container'>
              <div class='form-check'>
                <input
                  [(ngModel)]='employment.isPrimary'
                  class='form-check-input'
                  id='is-primary'
                  name='isPrimary'
                  type='checkbox'
                >

                <label
                  class='form-check-label'
                  for='is-primary'
                >
                  Primary
                </label>
              </div>
            </div>
          </div>

          <qa-fi-employment-address
            (addressChange)='employment.address = $event; enqueueSave()'
            (employerNameChange)='employment.employer = $event; enqueueSave()'
            [address]='employment.address'
            [employerName]='employment.employer'
            [fieldsConfig]='addressFieldsConfig'
          ></qa-fi-employment-address>

          <!-- position and employerPhone inputs -->
          <div class='row align-items-start'>
            <!-- position input -->
            <div class='col-12 col-lg-6'>
              <div class='form-group'>
                <label for='position'>Position or Title *</label>

                <text-input
                  #positionOrTitle='ngModel'
                  [(ngModel)]='employment.position'
                  [ngClass]="{
                  'invalid-requested' : positionOrTitle && positionOrTitle.invalid && positionOrTitle.errors && positionOrTitle.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.employment.position'] == 'requested',
                  'invalid-required' : positionOrTitle && positionOrTitle.invalid && positionOrTitle.errors && positionOrTitle.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.employment.position'] == 'required'
                }"
                  [required]="quickApplyFieldsConfig['mortgage.borrower.employment.position'] == 'required' ||
                        quickApplyFieldsConfig['mortgage.borrower.employment.position'] == 'requested'"
                  id='position'
                  name='position'>
                </text-input>
              </div>
            </div>

            <!-- employerPhone input -->
            <div class='col-12 col-lg-6'>
              <div class='compact-form-group'>
                <!-- phone number input -->
                <div class='flex-grow-1 form-group'>
                  <label for='employer-phone'>
                    Employer Contact No. *
                  </label>
                  <text-input
                    #employerPhone='ngModel'
                    [(ngModel)]='employment.employerPhone'
                    [ngClass]="{
                    'invalid-requested' : employerPhone && employerPhone.invalid && employerPhone.errors && employerPhone.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.employment.employerPhone'] == 'requested',
                    'invalid-required' : employerPhone && employerPhone.invalid && employerPhone.errors && employerPhone.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.employment.employerPhone'] == 'required'
                  }"
                    [required]="quickApplyFieldsConfig['mortgage.borrower.employment.employerPhone'] == 'required' ||
                        quickApplyFieldsConfig['mortgage.borrower.employment.employerPhone'] == 'requested'"
                    id='employer-phone'
                    name='employerPhone'>
                  </text-input>
                </div>

                <!-- phone extension input -->
                <div class='form-group'>
                  <label for='employer-phone-ext'>Ext.</label>

                  <!-- TODO: Bind to the model -->
                  <text-input
                    disabled
                    id='employer-phone-ext'
                    name='employerPhoneExt'
                  ></text-input>
                </div>
              </div>
            </div>
          </div>

          <!-- startDate and endDate inputs -->
          <div class='row align-items-start'>
            <!-- startDate -->
            <div class='col-12 col-lg'>
              <div class='form-group'>
                <label for='start-date'>Start Date *</label>

                <date-input
                  #startDate='ngModel'
                  [(ngModel)]='employment.startDate'
                  [ngClass]="{
                    'invalid-requested' : startDate && startDate.invalid && startDate.errors && startDate.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.employment.startDate'] == 'requested',
                    'invalid-required' : startDate && startDate.invalid && startDate.errors && startDate.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.employment.startDate'] == 'required'
                  }"
                  [required]="quickApplyFieldsConfig['mortgage.borrower.employment.startDate'] == 'required' ||
                        quickApplyFieldsConfig['mortgage.borrower.employment.startDate'] == 'requested'"
                  id='start-date'
                  name='startDate'>
                </date-input>
              </div>
            </div>

            <!-- endDate -->
            <div class='col-12 col-lg-6' *ngIf='!isCurrent'>
              <div class='form-group'>
                <label for='end-date'>End Date *</label>

                <date-input
                  #endDate='ngModel'
                  [(ngModel)]='employment.endDate'
                  [max]='maxEndDate'
                  [min]='minEndDate'
                  [ngClass]="{
                    'invalid-requested' : endDate && endDate.invalid && endDate.errors && endDate.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.employment.endDate'] == 'requested',
                    'invalid-required' : endDate && endDate.invalid && endDate.errors && endDate.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.employment.endDate'] == 'required'
                  }"
                  [required]="!isCurrent && (quickApplyFieldsConfig['mortgage.borrower.employment.endDate'] == 'required' ||
                        quickApplyFieldsConfig['mortgage.borrower.employment.endDate'] == 'requested')"
                  id='end-date'
                  name='endDate'
                ></date-input>
              </div>
            </div>
          </div>

          <!-- Employment duration inputs -->
          <div class='row align-items-start'>
          <span class='text-muted'>
            Employment duration in this line of work
          </span>

            <!-- years input -->
            <div class='col-12 col-lg-6'>
              <div class='form-group'>
                <label for='years-on-job'>
                  Years
                </label>

                <number-input
                  [(ngModel)]='employment.yearsOnJob'
                  [allowNegative]='false'
                  id='years-on-job'
                  name='yearsOnJob'
                ></number-input>
              </div>
            </div>

            <!-- months input -->
            <div class='col-12 col-lg-6'>
              <div class='form-group'>
                <label for='months-on-job'>
                  Months
                </label>

                <number-input
                  [(ngModel)]='employment.monthsOnJob'
                  [max]='11'
                  [min]='0'
                  id='months-on-job'
                  name='monthsOnJob'
                ></number-input>
              </div>
            </div>
          </div>
        </div>

        <!-- Gross Monthly Income -->
        <div class='col-12 col-lg d-lg-flex flex-column'>
          <h5>Gross Monthly Income</h5>

          <!-- selfEmployed checkbox -->
          <div class='form-check form-switch mb-1'>
            <input
              [(ngModel)]='selfEmployed'
              class='form-check-input'
              id='self-employed'
              name='selfEmployed'
              type='checkbox'
            >

            <label
              class='form-check-label text-muted'
              for='self-employed'
            >
              Business Owner or Self-Employed
            </label>
          </div>

          <!-- selfEmploymentDetails -->
          <ng-container *ngIf='selfEmployed'>
            <div class='row align-items-start'>
              <!-- borrowerOwnershipShare select -->
              <div class='col-12 col-lg'>
                <div class='form-group'>
                  <label for='borrower-ownership-share'>
                    Ownership Share
                  </label>

                  <select
                    [(ngModel)]='employment.borrowerOwnershipShare'
                    class='form-control'
                    id='borrower-ownership-share'
                    name='borrowerOwnershipShare'
                  >
                    <option value=''>-- Select One --</option>

                    <option
                      *ngFor='let item of ownershipShareItems'
                      [value]='item.value'
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- selfEmploymentForm select -->
              <div
                *ngIf='isSelfEmploymentFormDisplayed'
                class='col-12 col-lg'
              >
                <div class='form-group'>
                  <label for='self-employment-form'>
                    Self Employment Form
                  </label>

                  <select
                    [(ngModel)]='employment.selfEmploymentForm'
                    class='form-control'
                    id='self-employment-form'
                    name='selfEmploymentForm'
                  >
                    <option value=''>-- Select One --</option>

                    <option
                      *ngFor='let item of selfEmploymentFormOptions'
                      [value]='item.value'
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- monthlyIncomeOrLoss input -->
              <div class='col-12 col-lg'>
                <div class='form-group'>
                  <label for='monthly-income-or-loss'>
                    Monthly Income or Loss
                  </label>

                  <currency-input
                    [(ngModel)]='selfEmploymentMonthlyIncomeOrLoss'
                    id='monthly-income-or-loss'
                    name='monthlyIncomeOrLoss'
                  ></currency-input>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- income inputs -->
          <div class='income-list'>
            <div
              *ngFor='let income of employment.incomes; index as i'
              class='d-flex gap-2 mb-3'
            >
              <div class='form-group form-group--flat flex-grow-1 mb-0'>
                <label for='income-{{i}}'>
                  {{ getIncomeDisplayName(income) }}
                </label>

                <div class='d-flex'>
                  <currency-input
                    #monthlyIncome='ngModel'
                    (ngModelChange)='income.monthlyIncome = $event; onChangeMonthlyIncome($event, i)'
                    [allowNegative]='false'
                    [ngClass]="{
                    'invalid-requested' : !income.monthlyIncome && income.monthlyIncome !== 0 && isCurrent && income.typeOfIncome === 'Base'
                      && quickApplyFieldsConfig['mortgage.borrower.employment.incomes.monthlyIncome'] == 'requested',
                    'invalid-required' : isCurrent && !income.monthlyIncome && income.typeOfIncome === 'Base'
                      && quickApplyFieldsConfig['mortgage.borrower.employment.incomes.monthlyIncome'] == 'required'
                  }"
                    [ngModel]='income.monthlyIncome'
                    [required]="isCurrent && income.typeOfIncome === 'Base' && (quickApplyFieldsConfig['mortgage.borrower.employment.incomes.monthlyIncome'] == 'required' ||
                        quickApplyFieldsConfig['mortgage.borrower.employment.incomes.monthlyIncome'] == 'requested')"
                    class='w-100'
                    id='income-{{i}}'
                    name='monthlyIncome{{i}}'
                  ></currency-input>

                  <a
                    (click)='calculateMonthlyIncome(income, i)'
                    class='align-self-center ms-3'
                  >
                    <i class='fa fa-calculator'></i>
                  </a>
                </div>
              </div>

              <ng-container
                *ngLet='isIncomeRemovable(income.typeOfIncome) as removable'>
                <!-- remove income button -->
                <button (click)='onClickRemoveIncome(i)'
                        *ngIf='removable' class='icon-button icon-button--danger'>
                  <i class='fa fa-trash-alt'></i>
                </button>

                <!-- placeholder for the button -->
                <div *ngIf='!removable' class='button-placeholder'></div>
              </ng-container>
            </div>

            <!-- add income button, wrapped in a div to prevent
                 the button from being stretched -->
            <div class='d-flex income-list__button'>
              <qa-add-button (onClick)='onClickAddIncome()'>
                Income
              </qa-add-button>
            </div>
          </div>

          <!-- monthlyTotal -->
          <div class='split-row border-bottom mb-2'>
            <b>Monthly Total</b>

            <b>{{ monthlyTotal | currency }}</b>
          </div>

          <!-- isSeasonal and isForeign checkboxes
          <div class='d-flex gap-3'>
            <div class='form-check'>
              <input [(ngModel)]='value.isSeasonal' class='form-check-input'
                     id='is-seasonal' name='isSeasonal' type='checkbox'>
              <label class='form-check-label' for='is-seasonal'>
                Seasonal Income
              </label>
            </div>

            <div class='form-check'>
              <input [(ngModel)]='value.isForeign' class='form-check-input'
                     id='is-foreign' name='isForeign' type='checkbox'>
              <label class='form-check-label' for='is-foreign'>
                Foreign Income
              </label>
            </div>
          </div>

          <div class='form-check'>
            <input [(ngModel)]='value.isEmployedByFamily' class='form-check-input'
                   id='is-employed-by-family' name='isEmployedByFamily'
                   type='checkbox'>
            <label class='form-check-label' for='is-employed-by-family'>
              Employed by a family member, property seller, real estate agent,
              etc.
            </label>
          </div>-->
        </div>
      </ng-template>
    </ng-container>
  </form>

  <div class='card-footer d-flex justify-content-end align-items-stretch gap-2'>
    <qa-cancel-button (qaClick)='onClickCancel()'></qa-cancel-button>

    <qa-ok-button (qaClick)='onClickClose()'></qa-ok-button>
  </div>
</div>
