import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Utils } from 'src/app/core/services/utils';
import { AppraisalPropertyInspectionType, MortgageAppraisalFormType, MortgageAppraisalMethodType, SubjectPropertyValuation } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';

@Component({
  selector: 'urla-subject-property-valuation',
  templateUrl: './urla-subject-property-valuation.component.html',
  styleUrls: ['./urla-subject-property-valuation.component.scss'],
  viewProviders: [formViewProvider]
})
export class UrlaSubjectPropertyValuationComponent implements OnInit {

  @Input()
  set subjectPropertyValuation(subjectPropertyValuation: SubjectPropertyValuation) {
    this._subjectPropertyValuation = _.cloneDeep(subjectPropertyValuation);
  }

  get subjectPropertyValuation(): SubjectPropertyValuation {
    return this._subjectPropertyValuation;
  }

  @Input()
  urlaFieldsConfig: {};

  @Output()
  changesApproved: EventEmitter<SubjectPropertyValuation> = new EventEmitter<SubjectPropertyValuation>();

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('subjectPropertyValuationForm')
  subjectPropertyValuationForm: NgForm | undefined;

  propertyInspectionTypes: EnumerationItem[] = [];
  appraisalFormTypes: EnumerationItem[] = [];
  appraisalMethodTypes: EnumerationItem[] = [];

  private _subjectPropertyValuation: SubjectPropertyValuation;

  constructor(private readonly _enumsService: EnumerationService) {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.appraisalFormTypes = enums[Constants.mortgageEnumerations.appraisalFormType];
      //this.propertyInspectionTypes = enums[Constants.mortgageEnumerations.appraisalFormType];
      this.appraisalMethodTypes = enums[Constants.mortgageEnumerations.appraisalMethodType];
    })
  }

  ngOnInit(): void {
    if (!this._subjectPropertyValuation) {
      this._subjectPropertyValuation = new SubjectPropertyValuation();
    }

    this.getPropertyInspectionTypes();
  }

  propertyInspectionTypeChange = () => {
    if (this._subjectPropertyValuation.propertyInspectionType != "Other") {
      this._subjectPropertyValuation.propertyInspectionTypeOtherDescription = undefined;
    }
  }

  appraisalFormTypeChange = () => {
    if (this._subjectPropertyValuation.appraisalFormType != "Other") {
      this._subjectPropertyValuation.appraisalFormTypeOtherDescription = undefined;
    }
  }

  appraisalMethodTypeChange = () => {
    if (this._subjectPropertyValuation.appraisalMethodType != "Other") {
      this._subjectPropertyValuation.appraisalMethodTypeOtherDescription = undefined;
    }
  }

  getEnumName = (enumValue: string, splitCamelCase: boolean = true): string => {
    enumValue = enumValue && enumValue != "None" && enumValue != "Unknown" ? String(enumValue) : null;
    return enumValue ? (splitCamelCase ? Utils.splitCamelCaseString(enumValue) : enumValue) : null;
  }

  onSaveClicked = () => {
    if (this.subjectPropertyValuationForm) {
      this.subjectPropertyValuationForm.form.markAllAsTouched();
      if (this.subjectPropertyValuationForm.form.valid) {
        this.changesApproved.emit(this._subjectPropertyValuation);
      }
    }
  }

  private getPropertyInspectionTypes = () => {
    for (let enumMember in AppraisalPropertyInspectionType) {
      let enumName = this.getEnumName(AppraisalPropertyInspectionType[enumMember]);
      if (enumName) {
        this.propertyInspectionTypes.push({ name: enumName, value: AppraisalPropertyInspectionType[enumMember] });
      }
    }
  }
}
