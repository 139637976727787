<div class="account-body accountbg"
    [style.background-image]="backgroundImageUrl ? 'url(' + backgroundImageUrl + ')' : ''">
    <img #backgroundTestImage [src]="backgroundImageUrl" height="90" [hidden]="true"
        (error)="onTestBackgroundImageErrored()">
    <div class="container">
        <div class="row vh-100 d-flex justify-content-center align-self-center">

            <div class="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xs-10 mx-auto align-self-center">
                <div class="card">
                    <div class="card-body p-0 login-header">
                        <div class="text-center p-3">
                            <logo [logoUrlsToTry]="possibleLogoUrls"></logo>
                            <h4 class="mb-1" style="color: steelblue; margin-top: 0;">Would you like to unsubscribe from this campaign?
                            </h4>
                            <p class="text-muted  mb-0">Please enter your email address and click the unsubscribe button
                                below.</p>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <!-- Tab panes -->
                        <div class="tab-content">
                            <div class="px-3 pt-2" *ngIf="errorMessage">
                                <div class="alert alert-danger border-0 mb-0" role="alert">
                                    {{errorMessage}}
                                </div>
                            </div>

                            <div class="p-3">
                                <form class="form-horizontal auth-form" #unsubscribeForm="ngForm" novalidate
                                    id="unsubscribeForm" name="unsubscribeForm">
                                    <div class="form-group mb-2">
                                        <label class="form-label" for="emailAddress">Email Address</label>
                                        <div class="input-group">
                                            <input type="email" autocomplete="off" class="form-control"
                                                trim
                                                name="emailAddress" id="emailAddress"
                                                [disabled]="submitting" placeholder="Enter Email"
                                                #tv_emailAddress="ngModel"
                                                [ngClass]="{'is-invalid' : tv_emailAddress && tv_emailAddress.touched && tv_emailAddress.invalid}"
                                                required (keyup.enter)="onUnsubscribeClicked()"
                                                [(ngModel)]="emailAddress" email>
                                            <div class="invalid-feedback">Please enter an email address.</div>
                                        </div>
                                    </div>
                                    <div class="form-group mb-0 row">
                                        <div class="col-12">
                                            <button class="btn btn-primary w-100 waves-effect waves-light" type="button"
                                                *ngIf="!submitting" (click)="onUnsubscribeClicked()">
                                                Unsubscribe
                                            </button>
                                            <button class="btn btn-primary w-100 waves-effect waves-light" type="button"
                                                disabled="" *ngIf="submitting">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Please wait...
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
