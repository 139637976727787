import { Component, Injector, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import * as _ from "lodash";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin, Observable, of, Subscription, throwError } from "rxjs";
import { catchError, defaultIfEmpty, map, switchMap } from "rxjs/operators";
import { LocalStorageService } from "src/app/core/services/local-storage.service";
import { LeadStatus, LoanStatus, UserRoleEnum } from "src/app/models";
import { LeadStatusAssociation } from "src/app/models/config/lead-status-association.model";
import { LoanPurpose } from "src/app/models/config/loan-purpose.model";
import { LoanStatusAssociation } from "src/app/models/config/loan-status-association.model";
import { User } from "src/app/models/user/user.model";
import { NotificationService } from "src/app/services/notification.service";
import { ApplicationContextBoundComponent } from "src/app/shared/components";
import { DrawerOptions, DrawerService, DrawerSize } from "src/app/shared/services/drawer.service";
import Swal, { SweetAlertResult } from "sweetalert2";
import { DialListRecordsTableComponent } from "./components/dial-list-records-table/dial-list-records-table.component";
import { ConferenceLine } from "./models/conference-line.model";
import { DialListBasic } from "./models/dial-list-basic.model";
import { DialListRecordBasic, RecordType } from "./models/dial-list-record-basic.model";
import { VoiceHistory } from "./models/voice-history.model";
import { DialListRecord } from "./models/dial-list-record.model";
import { DialerEventType } from "./models/dialer-event.model";
import { PredefinedNote } from "./models/dialer-page-data.model";
import { PhoneType } from "./models/phone-type.model";
import { DialerEvent, DialerService } from "./services/dialer.service";
import { ContactListsService } from "../contact-lists/services/contact-lists.service";
import { TelephonyPool } from "../admin/dialer-config/models/telephony.model";
import { VoiceSmsSettingsService } from "../voice-sms-settings/services/voice-sms-settings.service";
import { CacheService } from "src/app/core/services/cache-service";
import { TimerService } from "src/app/core/services/timer.service";
import * as luxon from "luxon";

@Component({
  selector: 'dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.scss']
})
export class DialerComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChildren('dialList') dialListComponents: QueryList<DialListRecordsTableComponent> | undefined

  dialLists: DialListBasic[] = [];

  timezones: { name: string, checked: boolean }[] = [];
  selectedTimeZones: { name: string, checked: boolean }[] = [];
  hideTimezoneRestrictedRecords: boolean = false;
  dialUsingTelephonyPoolId: number = null;

  autoRefreshTimouts = {};
  autoRefreshIntervals = {};
  autoRefreshCounters = {};
  autoRefreshTimers = {};

  leadStatusAssociation: LeadStatusAssociation[] = [];
  loanStatusAssociation: LoanStatusAssociation[] = [];

  leadStatus: LeadStatus[] = [];
  loanPurpose: LoanPurpose[] = [];
  loanStatus: LoanStatus[] = [];
  loanOfficers: User[] = [];
  telephonyPools: TelephonyPool[] = []

  confererenceList: ConferenceLine;
  currentDialListRecord: DialListRecord;
  currentVoiceHistory: VoiceHistory;
  predefinedNotes: PredefinedNote[] = [];

  isLoading: boolean = false;

  selectedDialList: DialListBasic;
  selectedRecord: DialListRecordBasic;
  expandedRowKeys: { [dialListId: number]: boolean } = {};
  selectedRecordForCall: DialListRecordBasic;

  recordsCurrentPage: number;
  recordsPageItems: number;

  dialRecordDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  manualOpenCallPanelData: { record: DialListRecordBasic, phoneType: PhoneType } = null;

  dialListRefreshStatusesById: Map<number, boolean> = new Map();
  refreshedDialLists: Map<number, DialListBasic> = new Map();

  protected hourOptionsForStrikeOut: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
    25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48 ];

  protected strikeOutHours: number = 4;

  private _timerSubscriptionsByDialListId: Map<number, Subscription> = new Map<number, Subscription>();

  constructor(
    private readonly _dialerService: DialerService,
    private readonly _drawerService: DrawerService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _voiceSmsSettingsService: VoiceSmsSettingsService,
    private readonly _timerService: TimerService,
    private readonly injector: Injector,
  ) {
    super(injector);
    super.scrollOffset = 228;
    this._dialerService.events.subscribe((e: DialerEvent) => {
      if (!e) {
        return;
      }

      switch (e.eventType) {
        case DialerEventType.findOnDialList:
          this.listener_findOnDialList(e.data);
          break;
        case DialerEventType.dialerv2_recordCalled:
          this.listener_recordCalled(e.data);
          break;
        case DialerEventType.dialerv2_acceptAndDialFreeForAllLead:
          this.listener_acceptAndDialFreeForAllLead(e.data);
          break;
        case DialerEventType.dialerv2_toggleList:
          this.selectDialListClicked(e.data);
          break;
        case DialerEventType.DialListRecordsRemoved:
          this.listener_dialListRecordsRemoved(e.data);
          break;
        case DialerEventType.pushDialListRecord:
          this.listener_pushDialListRecord(e.data);
          break;
      }
    })
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.applicationContextService.context.subscribe(async context => {

      this.leadStatus = context.globalConfig.leadStatus;
      this.loanStatus = context.globalConfig.loanStatus;
      this.loanPurpose = context.globalConfig.loanPurpose;
      this.leadStatusAssociation = context.globalConfig.leadStatusAssociation;
      this.loanStatusAssociation = context.globalConfig.loanStatusAssociation;
      const loRole = context.globalConfig.roles?.find(r => r.userRole === UserRoleEnum.LoanOfficer);
      if (loRole) {
        this.loanOfficers = context.globalConfig.users.filter(u => u.roleId === loRole.roleId);
      }

      this.loadTelephonyPools();

      this._spinner.show();
      this.isLoading = true;

      // let contactLists: ContactList[] = [];
      // try {
      //   contactLists = await firstValueFrom(this._contactListsService.getContactLists());
      // } catch (error) {
      //   this._spinner.hide();
      //   this.isLoading = false;
      //   this._notifyService.showError(error.message || error || 'Error loading contact lists!', 'Error');
      //   return;
      // }

      this._dialerService.getDialerPageData()
        .subscribe(pageData => {
          this.dialLists = pageData.dialLists.filter(dl => dl.systemUseType !== 'ManualDial');

          this.confererenceList = pageData.confererenceList;
          this.currentDialListRecord = pageData.currentDialListRecord;
          this.currentVoiceHistory = pageData.currentVoiceHistory;
          this.predefinedNotes = pageData.predefinedNotes;

          let observables: Observable<any>[] = [];

          this.dialLists.forEach(item => {
            //const associatedContactList = contactLists.find(cl => cl.contactListId === item.contactListId);
            this.dialListRefreshStatusesById.set(item.dialListId, false);
            const timer = this._timerService.get('diallist-refresh-timer-' + item.dialListId);
            const date = new Date();
            const itemAutoRefreshIntervalMilis = item.autoRefreshInterval * 60 * 1000;

            if (item.adminManaged && (!timer || (date.valueOf() - timer.startedAt.valueOf()) < 100 || (date.valueOf() - timer.startedAt.valueOf()) > itemAutoRefreshIntervalMilis )) {
              observables.push(this.refreshList(item.dialListId, true));
            } else {
              this.initializeRefreshTimers(item, false);
            }
            this._spinner.hide();
          });

          forkJoin(observables)
            .pipe(
              defaultIfEmpty([]),
            )
            .subscribe({
              next: () => {
                let timezones = [];

                this.dialLists.forEach(item => {

                  this.initializeRefreshTimers(item, false);

                  if (item.adminManaged) {
                    let currentDialListData = this.refreshedDialLists.get(item.dialListId);
                    item.records = !currentDialListData ? item.records : currentDialListData?.records ? [...currentDialListData.records] : [];

                    this.setDialListRecordsData(item.records);
                    let recordsWithTimezone = item.records.filter(r => !!r.timeZone);
                    timezones = timezones.concat(recordsWithTimezone.map(item => item.timeZone).filter((value, index, self) => self.indexOf(value) === index));
                  } else {
                    this.setDialListRecordsData(item.records);
                    let recordsWithTimezone = item.records.filter(r => !!r.timeZone);
                    timezones = timezones.concat(recordsWithTimezone.map(item => item.timeZone).filter((value, index, self) => self.indexOf(value) === index));
                  }
                });

                timezones = timezones.filter((value, index, self) => self.indexOf(value) === index && value);
                this.timezones = Array.from(timezones).map(tz => {
                  return {
                    name: tz,
                    checked: false
                  }
                });

                this._spinner.hide();
                this.isLoading = false;

                //Warm Transfer 2nd screen
                let warmTransferLeadInfo = this._localStorageService.getItem('warmTransferLeadInfo');
                if (warmTransferLeadInfo) {
                  let warmTransferLeadInfoCopy = _.cloneDeep(warmTransferLeadInfo);
                  this._dialerService.publish({ eventType: DialerEventType.WarmTransferAccepted, data: warmTransferLeadInfoCopy })

                  this._localStorageService.setItem('warmTransferLeadInfo', undefined);
                }

                //acceptAndDialFreeLeadInfo
                let acceptAndDialFreeLeadInfo = this._localStorageService.getItem('acceptAndDialFreeLeadInfo') as any;
                if (acceptAndDialFreeLeadInfo) {
                  let selectedDialList = this.dialLists.find(dl => dl.dialListId === acceptAndDialFreeLeadInfo.dialListId);
                  let selectedRecord = selectedDialList.records.find(ele => ele.recordTypeId == acceptAndDialFreeLeadInfo.leadId);
                  this._dialerService.openCallControlPanel(selectedDialList, selectedRecord, this.dialLists, undefined, this.predefinedNotes, true, undefined, undefined, undefined)

                  this._localStorageService.setItem('acceptAndDialFreeLeadInfo', undefined);
                }

                const dialerActiveRecord = this._localStorageService.getItem('dialerv2-activeRecordToFind') as any;
                if (dialerActiveRecord) {
                  this._localStorageService.setItem('dialerv2-activeRecordToFind', null);
                  this._dialerService.publish({
                    eventType: DialerEventType.findOnDialList,
                    data: {
                      dialListId: dialerActiveRecord.dialListId,
                      dialListRecordId: dialerActiveRecord.dialListRecordId
                    }
                  });
                }
              },
              error: () => {
                this._spinner.hide();
                this.isLoading = false;
              }
            })
        }, () => {
          this.isLoading = false;
          this._spinner.hide();
        });
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.autoRefreshTimouts) {
      Object.keys(this.autoRefreshTimouts).forEach(key => {
        if (this.autoRefreshTimouts[key]) {
          clearTimeout(this.autoRefreshTimouts[key]);
        }
      });
    }
    this._dialerService.publish({
      eventType: DialerEventType.DialUsingChanged,
      data: null
    });

    if (this._timerSubscriptionsByDialListId) {
      Object.values(this._timerSubscriptionsByDialListId).forEach(s => {
        s.unsubscribe()
      })
    }
  }

  loadTelephonyPools = () => {
    this._voiceSmsSettingsService.getTelephonyPools().subscribe((response) => {
      this.telephonyPools = response;
    })
  }

  setManaulOpenDialPanelData = (e: { callImmediate: boolean; selectedDialList: DialListBasic, selectedRecord: DialListRecordBasic, selectedRecordForCall: DialListRecordBasic, phoneType: PhoneType }) => {
    this.manualOpenCallPanelData = {
      record: e.selectedRecord,
      phoneType: e.phoneType
    };
  }

  onDeleteDialListClicked = (dialListId: number) => {

    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this Dial List?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then(function (result: SweetAlertResult) {
      if (!result.value) {
        return;
      }
      self._spinner.show();
      self._dialerService.deleteDialList(dialListId).subscribe({
        next: () => {

          _.remove(self.dialLists, (dialList: DialListBasic) => dialList.dialListId === dialListId);

          self._spinner.hide();
          self._notifyService.showSuccess('Dial List deleted succesfully', 'Success');
        }, error: (err) => {
          self._spinner.hide();
          self._notifyService.showError(err?.message || 'Unable to delete dial list due to some reasons.', "Error");
        }
      });
    });
  }

  findAndSelectLead = (dialListId: number, dialListRecordId: number): void => {

    let selectedDialList = this.dialLists.find(dl => dl.dialListId === dialListId);
    if (!selectedDialList)
      return;

    let selectedRecord = selectedDialList.records.find(r => r.dialListRecordId === dialListRecordId);
    if (!selectedRecord)
      return;

    Object.keys(this.expandedRowKeys).forEach(id => {
      this.expandedRowKeys[id] = false; // collapse
    });

    this.expandedRowKeys[selectedDialList.dialListId] = true; // expand

    if ((this.selectedRecord && this.selectedRecord.dialListRecordId != dialListRecordId) || !this.selectedRecord) {
      this.selectedRecord = selectedRecord;
    }

    this._dialerService.publish({
      eventType: DialerEventType.dialerv2_expandCollapseRecord,
      data: selectedRecord
    });
  }

  initializeRefreshTimers = (dialList: DialListBasic, restartIfAlreadyRunning: boolean) => {

    if (dialList.autoRefreshInterval && !this.autoRefreshIntervals[dialList.dialListId]) {

      let interval = (dialList.autoRefreshInterval * 60);
      this.autoRefreshIntervals[dialList.dialListId] = interval;
      this.autoRefreshCounters[dialList.dialListId] = interval;

      // let refreshTimer = this.onTimeout(dialList, restartIfAlreadyRunning);
      this.autoRefreshTimouts[dialList.dialListId] = true;

      const timer = this._timerService.start("diallist-refresh-timer-" + dialList.dialListId, dialList.autoRefreshInterval, restartIfAlreadyRunning, true);

      this._timerSubscriptionsByDialListId[dialList.dialListId] = timer.ticked.subscribe((elapsedTime) => {
          this.autoRefreshCounters[dialList.dialListId] = this.autoRefreshIntervals[dialList.dialListId] - elapsedTime;
          let intervalTime = this.autoRefreshIntervals[dialList.dialListId] - elapsedTime;
          this.autoRefreshTimers[dialList.dialListId] = this.pad(parseInt((intervalTime / 60).toString())).toString() + ':' +
          this.pad(intervalTime % 60);

      });
      this._timerSubscriptionsByDialListId[dialList.dialListId] = timer.expired.subscribe(() => {
        timer.stop();
        // this._timerService.stop("diallist-refresh-timer-" + dialList.dialListId);
        // this._timerService.remove("diallist-refresh-timer-" + dialList.dialListId);
        this.refreshList(dialList.dialListId, false)
          .subscribe(() => {
            timer.start();
        });
      })
    }
  }

  pad = (val: number): string => {
    let valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  }

  setDialListRecordsData = (records: DialListRecordBasic[]) => {
    return records.map(record => {
      if (record.leadId) {
        record.recordType = RecordType.Lead;
        record.recordTypeId = record.leadId;
      } else if (record.applicationId) {
        record.recordType = RecordType.Application;
        record.recordTypeId = record.applicationId;
      } else if (record.agentId) {
        record.recordType = RecordType.Agent;
        record.recordTypeId = record.agentId;
      } else if (record.borrowerId) {
        record.recordType = RecordType.Borrower;
        record.recordTypeId = record.borrowerId;
      }
      record.visible = true;
      record.needsToBeStruckOut = this.needsToBeStruckOut(record);
    });
  }

  isToday = (someDate: Date): boolean => {

    let date = new Date(someDate);
    const today = new Date();

    return date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear();
  }

  timeZoneFiltersChanged = () => {
    this.selectedTimeZones = this.timezones.filter(t => !!t.checked);
    this.timezoneSelected();
  }

  onStrikeOutHoursChanged = () => {
    this.dialLists.forEach(dl => {
      dl.records.forEach(r => {
        r.needsToBeStruckOut = this.needsToBeStruckOut(r);
      })
    })
  }

  timezoneSelected = () => {
    this._spinner.show();

    let observables = this.dialLists.map(l => this.refreshList(l.dialListId, false));

    forkJoin(observables)
      .subscribe({
        next: () => {
          this._spinner.hide();
        },
        error: () => {

        }
      }
      );
  }

  fetchNextAvailableLead = () => {

    let dialListToInsertFetchedItemInto = this.selectedDialList;
    if (!dialListToInsertFetchedItemInto) {
      dialListToInsertFetchedItemInto = this.dialLists[0];
      this.selectedDialList = dialListToInsertFetchedItemInto;
      this.expandedRowKeys[dialListToInsertFetchedItemInto.dialListId] = true; // expand
    }

    this._dialerService.openCallControlPanel(dialListToInsertFetchedItemInto, undefined, this.dialLists,
      undefined, undefined, false, true, undefined, undefined);
  }

  refreshList = (dialListId: number, isManualRefresh: boolean) => {
    this.dialListRefreshStatusesById.set(dialListId, true);
    return this.refreshListRecords(dialListId)
      .pipe(
        catchError((error) => {
          this.autoRefreshCounters[dialListId] = this.autoRefreshIntervals[dialListId];
          this.dialListRefreshStatusesById.set(dialListId, false);

          return of(error)
        }),
        map((numberOfRecordsAdded) => {
          // Only show this message for the number of records added after refresh is > 0
          if (isManualRefresh && numberOfRecordsAdded > 0) {
            let selectedDialList = this.dialLists.find(dl => dl.dialListId === dialListId);
            this._notifyService.showSuccess(selectedDialList.name + ' list refreshed!', "Dial List Refresh")
          }

          this.dialListRefreshStatusesById.set(dialListId, false);

          this.autoRefreshCounters[dialListId] = this.autoRefreshIntervals[dialListId];
          }
        )
      )
  }

  onRefreshClicked = (dialListId: number) => {
    const dialList = this.dialLists.find(dl => dl.dialListId == dialListId);
    if (dialList.autoRefreshInterval) {
      this._timerService.stop("diallist-refresh-timer-" + dialListId);
      this._timerService.remove("diallist-refresh-timer-" + dialListId);
      this.autoRefreshIntervals[dialList.dialListId] = 0;
    }
    this.refreshList(dialListId, true).subscribe(() => {
      this.initializeRefreshTimers(dialList, true);
      if (this.selectedDialList) {
          this.selectedDialList.records.forEach(r => {
          r.needsToBeStruckOut = this.needsToBeStruckOut(r);
        })
      }
    });
  }

  refreshListRecords = (dialListId: number): Observable<any> => {

    let selectedDialList = this.dialLists.find(dl => dl.dialListId === dialListId);

    if (selectedDialList) {
      if (selectedDialList.adminManaged) {
        return this._dialerService.refreshDialListRecords(dialListId)
          .pipe(
            catchError((error) => of(error)),
            switchMap(() => this._dialerService.getBasicDialList(dialListId)
              .pipe(
                catchError((error) => of(error)),
                map((newList: DialListBasic) => {
                  this.refreshedDialLists.set(newList.dialListId, newList);
                  let numberOfRecordsAdded = this.consolidateListAfterRefresh(selectedDialList, newList);
                  return numberOfRecordsAdded;
                })
              )
            )
          );

      } else {

        return this._dialerService.getBasicDialList(dialListId)
          .pipe(
            catchError((error) => of(error)),
            map((newList: DialListBasic) => {
              let numberOfRecordsAdded = this.consolidateListAfterRefresh(selectedDialList, newList);
              return numberOfRecordsAdded;
            })
          )
      }
    } else {
      return throwError("No matching dial list found");
    }
  }

  checkTimezoneRestricted = (recordToDial) => {
    if (recordToDial.timezoneRestricted) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to dial outside of the state time zone dial rules?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then(function (result: SweetAlertResult) {
        if (!result.value) {
          return false;
        }
        return true;
      });
    }
    return true;
  }

  onPhoneNumberClicked = (data: { recordToDial: DialListRecordBasic, phoneType?: PhoneType }) => {
    if (this.checkTimezoneRestricted(data.recordToDial)) {
      this.selectedRecordForCall = { ...data.recordToDial };
      this._dialerService.openCallControlPanel(this.selectedDialList, this.selectedRecordForCall, this.dialLists, data.phoneType, this.predefinedNotes, true, false, undefined, undefined);
    }
  }

  consolidateListAfterRefresh = (selectedDialList: DialListBasic, newList: DialListBasic) => {
    let index = this.dialLists.indexOf(selectedDialList);
    if (index === -1)
      return;

    // Do not purge the whole list and swap it with the incoming one
    let newRecordsToAdd = [];
    let oldRecordsToRemove = [];
    let oldList = this.dialLists[index];
    let oldListCount = oldList.records.length;

    newList.records.forEach(function (newRecord) {
      let matchingOldRecord = oldList.records.find(r => r.dialListRecordId === newRecord.dialListRecordId);
      if (matchingOldRecord) {
        // This record was already in the list, just update it.
        Object.assign(matchingOldRecord, newRecord);
      } else {
        // This is a new record, needs to be put at the end of the list
        newRecordsToAdd.push(newRecord);
      }
    });

    oldList.records.forEach(function (oldRecord) {
      let matchingNewRecord = newList.records.find(nlr => nlr.dialListRecordId === oldRecord.dialListRecordId);
      if (matchingNewRecord) {
        // This record was already in the list, just update it - we handled this already
      } else {
        // There is no matching new record, we need to delete this record
        // Check to see if it's the active record, if yes, leave it
        if (this.selectedRecord) {
          if (oldRecord.dialListRecordId !== this.selectedRecord.dialListRecordId)
            oldRecordsToRemove.push(oldRecord.dialListRecordId);
        }
        if (this.selectedRecordForCall) {
          if (oldRecord.dialListRecordId !== this.selectedRecordForCall.dialListRecordId)
            oldRecordsToRemove.push(oldRecord.dialListRecordId);
        }
      }
    });
    this.dialLists[index].records = oldList.records.filter(record => oldRecordsToRemove.indexOf(record.dialListRecordId) < 0);
    this.dialLists[index].records.push(...newRecordsToAdd);
    this.dialLists[index] = { ...this.dialLists[index] };

    this.setDialListRecordsData(this.dialLists[index].records);
    this.filterByTimeZoneAndTimezoneRestricted(selectedDialList);
    return this.dialLists[index].records.length - oldListCount;
  }

  filterByTimeZoneAndTimezoneRestricted = (dialList: DialListBasic) => {
    if (this.selectedTimeZones.length === 0 && !this.hideTimezoneRestrictedRecords)
      return;

    if (dialList.records.length > 0) {
      if (this.selectedTimeZones.length > 0 || this.hideTimezoneRestrictedRecords) {
        dialList.records = dialList.records.filter(r => ( this.selectedTimeZones.map(tz => tz.name).includes(r.timeZone) || this.selectedTimeZones.length == 0 ) 
          && ( !this.hideTimezoneRestrictedRecords || !r.timezoneRestricted ))

        const ind = this.dialLists.findIndex(l => l.dialListId == dialList.dialListId);
        this.dialLists[ind] = { ...dialList };
      }
    }
  }

  onRecordTablePageChanged = (event: { currentPage: number, numOfItemsOnPage: number }) => {
    this.recordsCurrentPage = event.currentPage;
    this.recordsPageItems = event.numOfItemsOnPage;
  }

  // toggle list
  selectDialListClicked = (dialList: DialListBasic): void => {

    if (this.selectedDialList && (dialList.dialListId === this.selectedDialList.dialListId)) {
      this.selectedDialList = undefined;
      this.expandedRowKeys[dialList.dialListId] = false; // collapse
      this.publish_dialListChanged(null);
      this.manualOpenCallPanelData = null;
      return;
    }

    this.selectedDialList = dialList;
    this.expandedRowKeys[dialList.dialListId] = true; // expand
    this.publish_dialListChanged(this.selectedDialList.dialListId);

  }

  onEditRecordClicked = (data: { record: DialListRecordBasic, fromFindOnDiallist: boolean }): void => {
    this.selectedRecord = { ...data.record };
    this.selectedDialList = { ...this.dialLists.find(l => l.dialListId == data.record.dialListId) };
    this._drawerService.hide("dialRecordEditorDrawer", 100)
      .then(() => {
        this._drawerService.show("dialRecordEditorDrawer", 100);
      });
  }

  publish_dialListChanged = (dialListId: number) => {
    this._dialerService.publish({
      eventType: DialerEventType.changeSelectedDialList,
      data: dialListId
    })
  }

  listener_findOnDialList = (data) => {
    const dialerActiveRecord = this._localStorageService.getItem('dialerv2-activeRecordToFind');
    if (dialerActiveRecord)
      return;

    this.findAndSelectLead(data.dialListId, data.dialListRecordId);
  }

  listener_recordCalled = (data) => {
    
  }

  listener_pushDialListRecord = (data: { record: DialListRecordBasic, index: number }) => {
    let index = this.dialLists.findIndex(l => l.dialListId == this.selectedDialList.dialListId);

    this.publish_dialListChanged(this.selectedDialList.dialListId);

    this.setDialListRecordsData([data.record]);

    if (data.index == 0) {
      data.index = this.recordsPageItems * (this.recordsCurrentPage - 1);
    }

    this.dialLists[index].records.splice(data.index, 0, data.record); // adding mode

    const dialListComponentToRefresh = this.dialListComponents.toArray().find(c => c.selectedDialList.dialListId === this.dialLists[index].dialListId);
    if (dialListComponentToRefresh) {
      dialListComponentToRefresh.selectedRecordForCall = this.dialLists[index].records[data.index];
      dialListComponentToRefresh.refresh(this.dialLists[index]);
    }
  }

  listener_acceptAndDialFreeForAllLead = (data) => {
    //todo: refresh the diallist then load the record
    this.refreshList(data.dialListId, true)
      .subscribe(() => {
        let selectedDialList = this.dialLists.find(dl => dl.dialListId === data.dialListId);
        let selectedRecord = selectedDialList.records.find(ele => ele.recordTypeId == data.leadId);
        if (data.dialNow != false) {
          this._dialerService.openCallControlPanel(selectedDialList, selectedRecord, this.dialLists, undefined, this.predefinedNotes, true, undefined, undefined, undefined)
        }
      });
  }

  listener_dialListRecordsRemoved = (data) => {
    if (data && Array.isArray(data)) {
      data.forEach(item => {
        let dialListIndex = this.dialLists.findIndex(ele => ele.dialListId === item.dialListId);
        let indexOfRecordToRemove;

        if (dialListIndex != -1) {
          indexOfRecordToRemove = this.dialLists[dialListIndex].records.findIndex(ele => ele.dialListRecordId === item.dialListRecordId);
          if (indexOfRecordToRemove != -1) {
            this.dialLists[dialListIndex].records.splice(indexOfRecordToRemove, 1);
          }
        }
      });
    }
  }

  closeRecordDetailsDrawerClicked = () => {
    this._drawerService.hide("dialRecordEditorDrawer", 100);
  }

  onAfterSaveRecordDetails = (dialListId: number) => {
    this.onRefreshClicked(dialListId);
  }

  onAfterStatusChanged = (data: {isSubStatus: boolean, statusId: number, dialListId: number}) => {
    this.onRefreshClicked(data.dialListId);
  }

  dialUsingChanged = (event) => {
    this._dialerService.publish({
      eventType: DialerEventType.DialUsingChanged,
      data: this.dialUsingTelephonyPoolId
    });
  }
  
  private needsToBeStruckOut = (record: DialListRecordBasic): boolean => {
    // If the record has a last attempted contact time, and it's less than 4 hours ago, strike it out
    const lastAttemptedContact = record.lastAttemptedContact || record.lastSuccessfulContact;
    if (lastAttemptedContact) {
      const now = luxon.DateTime.fromJSDate(new Date());
      const lastAttemptedContact = (typeof record.lastAttemptedContact.getMonth === 'function') ? 
        luxon.DateTime.fromJSDate(record.lastAttemptedContact) :
        luxon.DateTime.fromISO(record.lastAttemptedContact.toString());
      const diff = now.diff(lastAttemptedContact, ["hours"])

      const needsToBeStruckOut = (diff as any).values.hours <= this.strikeOutHours;
      return needsToBeStruckOut;
    }
  }
}



