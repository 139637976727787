import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Income } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import Swal from 'sweetalert2';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { LeadUtils } from '../../../../leads/services/utils';
import { PayPeriod } from '../../../../leads/models/lead-employment.model';

@Component({
  selector: 'other-income-in-detail',
  templateUrl: 'other-income-in-detail.component.html',
  styleUrls: ['./other-income-in-detail.component.scss']
})
export class OtherIncomeInDetailComponent implements OnInit {

  @Output()
  incomesChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  incomes: Income[] = [];

  nonEmploymentIncomeTypes: EnumerationItem[] = [];

  incomePayPeriods: EnumerationItem[] = [];

  totalMonthlyIncome: number = 0;

  editingIx: number = -1;

  protected readonly PayPeriod = PayPeriod;

  constructor(private readonly _enumsService: EnumerationService) {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.nonEmploymentIncomeTypes = enums[Constants.enumerations.incomeType]
        .filter((t: any) => this.filterNonEmploymentIncomes(t.value));
      this.incomePayPeriods = this._enumsService.getIncomePayPeriods();
    });
  }

  ngOnInit() { }

  onAmountChanged = (income: Income) => {
    if (income.payPeriod !== PayPeriod.Hourly && income.hoursPerWeek) {
      income.hoursPerWeek = null;
    }
    if (!income.payPeriod) {
      income.payPeriod = PayPeriod.Monthly;
      income.amount = income.monthlyIncome;
    }
    income.monthlyIncome = LeadUtils.calculateMonthlyAmountByPayPeriod(
      income.amount,
      income.payPeriod as PayPeriod,
      income.hoursPerWeek,
    );
    this.incomesChanged.emit();
    this.setTotalMonthlyIncome();
  }

  addOtherIncome = () => {
    let income = new Income();
    income.payPeriod = this.incomePayPeriods[0].value;
    const typeOfOtherIncome = this.nonEmploymentIncomeTypes.find(income => income.value == "OtherTypesOfIncome");
    income.typeOfIncome = typeOfOtherIncome.value;
    this.incomes.push(income);
  }

  deleteIncomeClicked = (deleteIx: number) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        if (deleteIx === self.editingIx) {
          self.editingIx = -1;
        }
        self.incomes.splice(deleteIx, 1);
        self.incomesChanged.emit();
        self.setTotalMonthlyIncome();
      }
    });
  }

  private setTotalMonthlyIncome = () => {
    this.totalMonthlyIncome = LeadUtils.calculateTotalMonthlyIncome(this.incomes);
  }

  private filterNonEmploymentIncomes = (incomeType: string): boolean => {
    return !(incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Base) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Bonus) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Commissions) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Overtime));
  }
}
