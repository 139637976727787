<urla-deletable-cell (onClickDelete)='onClickDelete()' [isReadOnly]="inEditMode">
  <div class='grid-layout-row'>
    <!-- Description -->
    <urla-text-input
      [(ngModel)]='explanation.description'
      [required]='isRequired("mortgage.borrower.declarations.explanation.description")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      [inEditMode]='inEditMode'
      fieldBeingEdited='mortgage.borrower.declarations.explanation.description'
      label='Description'
      class='mb-0 pe-0'
      name='description{{uuid}}'
    ></urla-text-input>
  </div>
</urla-deletable-cell>
