import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubjectProperty } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { cloneDeep } from 'lodash';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'subject-property-manufactured-home-details',
  templateUrl: 'subject-property-manufactured-home-details.component.html',
  styleUrls: ['./subject-property-manufactured-home-details.component.scss'],
})
export class SubjectPropertyManufacturedHomeDetailsComponent implements OnInit {

  @Input()
  set subjectProperty(subjectProperty: SubjectProperty) {
    this._subjectProperty = cloneDeep(subjectProperty);
  }

  get subjectProperty(): SubjectProperty {
    return this._subjectProperty;
  }

  @Input()
  hasSaveCancelButtons: boolean = true;

  manufacturedHomeConditionTypes: EnumerationItem[] = [];
  certificateOfTitleTypes: EnumerationItem[] = [];
  lotOwnershipTypes: EnumerationItem[] = [];

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApproved: EventEmitter<SubjectProperty> = new EventEmitter<SubjectProperty>();

  manufacturedHomeWidthTypeOptions: EnumerationItem[] = [];

  private _subjectProperty: SubjectProperty;

  constructor(private readonly _enumerationService: EnumerationService) {
    this._enumerationService.getMortgageEnumerations().subscribe(enums => {
        this.manufacturedHomeConditionTypes = enums[Constants.mortgageEnumerations.manufacturedHomeConditionType];
        this.certificateOfTitleTypes = enums[Constants.mortgageEnumerations.certificateOfTitleType];
        this.lotOwnershipTypes = enums[Constants.mortgageEnumerations.lotOwnershipType];
    });
  }

  ngOnInit() {
    this._enumerationService.getMortgageEnumerations().subscribe((enums) => {
      this.manufacturedHomeWidthTypeOptions =
        enums[Constants.mortgageEnumerations.manufacturedHomeWidthType];
    });
   }

  onSaveClicked = () => {
    this.changesApproved.emit(this._subjectProperty);
  }

  onCertificateOfTitleTypeChanged = () => {
    if (this._subjectProperty.manufacturedHomeCertificateOfTitleType !== 'Other') {
      this._subjectProperty.manufacturedHomeCertificateOfTitleTypeOtherDescription = null;
    }
  }
}