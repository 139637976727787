import { AfterViewInit, Component, Injector, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Address, Employment, EmploymentTypeEnum, Income, SystemLevel } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BorrowerCalculatedStats, UrlaBorrower, UrlaEmployment } from '../../models/urla-mortgage.model';
import { EmploymentComponent } from './employment/employment.component';
import { IncomeDialogComponent } from './income-dialog/income-dialog.component';
import { OtherIncomeComponent } from './other-income/other-income.component';
import { compareNonEmploymentIncomes } from '../../../../core/services/income-utils';

@Component({
  selector: 'employment-income',
  templateUrl: 'employment-income.component.html',
  styleUrls: ['employment-income.component.scss'],
  viewProviders: [formViewProvider]
})
export class EmploymentAndIncomeComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit {

  @ViewChildren('employmentComponent') employments: QueryList<EmploymentComponent> | undefined;
  @ViewChild('otherIncome') otherIncome: OtherIncomeComponent | undefined;

  @Input()
  applicationId: number = 0;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  systemLevel: SystemLevel;

  numberOfYears: number = 0;
  numberOfMonths: number = 0;

  // start date cant be greater then now
  maxDate = { year: new Date().getFullYear() + 5, month: new Date().getMonth() + 1, day: new Date().getDate() }

  hasMoreThan2YearsOfHistory: boolean | undefined = undefined;
  monthsOfHistoryForValidation: number | undefined = 2;

  isTpo: boolean = false;

  // Although, there is ONLY one current employment, this trick is required to make the form validation behave
  // If we do not do this, when we remove the current employment, it does not get removed from the very outer form that is responsible for validation
  currentEmployments: Employment[] = [];
  additionalEmployments: Employment[] = [];
  formerEmployments: Employment[] = [];

  nonEmploymentIncomeTypes: EnumerationItem[] = [];

  private _borrower: UrlaBorrower;

  private _employmentEventSubscriptions: Subscription[] = [];

  @Input()
  set borrower(borrower: UrlaBorrower) {
    this._borrower = borrower;
    if (!this._borrower.employments) {
      this._borrower.employments = [];
    }
    this.setEmployments();
    if (!this._borrower.nonEmploymentIncomes) {
      this._borrower.nonEmploymentIncomes = [];
    }
    if (!this.inEditMode) {
      this.calculateTotalIncome();
      this.calculateTotalDuration();
    }
  }

  get borrower(): UrlaBorrower {
    return this._borrower;
  }

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _enumsService: EnumerationService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.nonEmploymentIncomeTypes = enums[Constants.enumerations.incomeType].filter(t => this.filterNonEmploymentIncomes(t.value));
    });
    this.isTpo = this.applicationContext.isTpo;
  }

  ngAfterViewInit(): void {
    if (this.employments) {
      this.employments.changes.subscribe((r) => {
        if (r._results && r._results.length > 0) {
          this._employmentEventSubscriptions.forEach(subscription => {
            subscription.unsubscribe();
          });
          this._employmentEventSubscriptions = [];
          this.subscribeToEmploymentEvents();
        }
      });
      this.subscribeToEmploymentEvents();
    }

    if (this._borrower?.nonEmploymentIncomes != null) {
      this.sortBorrowerNonEmploymentIncomes();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._employmentEventSubscriptions) {
      this._employmentEventSubscriptions.forEach(s => {
        s.unsubscribe();
      })
    }
  }

  private sortBorrowerNonEmploymentIncomes() {
    this._borrower.nonEmploymentIncomes.sort(
      compareNonEmploymentIncomes,
    );
  }

  onOtherIncomeDeleted = (income: Income) => {
    this.calculateTotalIncome();
    this.calculateTotalDuration();
    this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
  }

  onAddCurrentEmploymentClicked = () => {
    let employment = new UrlaEmployment();
    employment.address = new Address();
    employment.employmentType = EmploymentTypeEnum.CurrentEmployer;
    // employment.employmentId = Utils.getUniqueId();
    this._borrower.employments.push(employment);
    this.setCurrentEmployments();
    this.calculateTotalDuration();
  }

  onAddFormerEmploymentClicked = () => {
    let employment = new UrlaEmployment();
    // employment.employmentId = Utils.getUniqueId();
    employment.address = new Address();
    employment.employmentType = EmploymentTypeEnum.FormerEmployer;
    this._borrower.employments.push(employment);
    this.formerEmployments = this._borrower.employments.filter(e => e.employmentType === EmploymentTypeEnum.FormerEmployer);
    this.calculateTotalDuration();
  }

  onOtherIncomeEditClicked = (income: Income) => {
    const modalRef = this._modalService.open(IncomeDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.incomeTypes = this.nonEmploymentIncomeTypes;
    modalRef.componentInstance.incomes = [income];
    modalRef.componentInstance.editOnly = true;
    modalRef.componentInstance.title = "Editing Income";
    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        const incomeBeingEdited = this._borrower.nonEmploymentIncomes.find(i => i.incomeId == result[0].incomeId);
        if (incomeBeingEdited) {
          const index = this._borrower.nonEmploymentIncomes.indexOf(incomeBeingEdited);
          if (index >= 0) {
            this._borrower.nonEmploymentIncomes[index] = result[0];
            this.sortBorrowerNonEmploymentIncomes();
          }
        }
        this.calculateTotalIncome();
        this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
      }
    }, (err) => {
      console.log(err);
    });
  }

  onAddOtherIncomeClicked = () => {
    const modalRef = this._modalService.open(IncomeDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.incomeTypes = this.nonEmploymentIncomeTypes;
    modalRef.componentInstance.incomes = []; // This is add, do not feed all the incomes into it! this._borrower.nonEmploymentIncomes;
    modalRef.componentInstance.title = "Income from Other Sources";
    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        this._borrower.nonEmploymentIncomes = this._borrower.nonEmploymentIncomes.concat(result);
        this.sortBorrowerNonEmploymentIncomes();

        this.calculateTotalIncome();
        this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
      }

    }, (err) => {
      console.log(err);
    });
  }

  onEmploymentDurationChanged = () => {
    this.calculateTotalDuration();
  }

  onIsPrimaryChanged = (e: UrlaEmployment) => {
    this.additionalEmployments.forEach(employment => {
      if (e !== employment) {
        employment.isPrimary = false;
      }
    })
    this.currentEmployments.forEach(employment => {
      if (e !== employment) {
        employment.isPrimary = false;
      }
    })
  }

  private filterNonEmploymentIncomes = (incomeType: string): boolean => {
    return !(incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Base) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Bonus) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Commissions) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Overtime));
  }

  private calculateTotalIncome = () => {
    const currentEmployments = this._borrower.employments.filter(e => e.employmentType === EmploymentTypeEnum.CurrentEmployer);
    let totalEmploymentIncome: number = 0;
    let selfEmploymentMonthlyIncomeOrLoss: number = 0;
    currentEmployments.forEach(e => {
      let employmentIncome: number = 0;
      if (e.incomes.length) {
        employmentIncome = e.incomes.map(i => i.monthlyIncome).reduce(this.sum);
      }

      selfEmploymentMonthlyIncomeOrLoss = 0;
      if (e.selfEmploymentMonthlyIncomeOrLoss) {
        selfEmploymentMonthlyIncomeOrLoss = e.selfEmploymentMonthlyIncomeOrLoss;
        employmentIncome += selfEmploymentMonthlyIncomeOrLoss;
      }
      totalEmploymentIncome += employmentIncome;
    });

    let totalOtherIncome: number = 0;
    if (this._borrower.nonEmploymentIncomes.length) {
      totalOtherIncome = this._borrower.nonEmploymentIncomes.map(i => i.monthlyIncome).reduce(this.sum);
    }

    if (!this._borrower.calculatedStats) {
      this._borrower.calculatedStats = new BorrowerCalculatedStats();
    }
    this._borrower.calculatedStats.totalMonthlyIncome = totalOtherIncome + totalEmploymentIncome;
  }

  private calculateTotalDuration = () => {
    let totalDurationOfEmploymentInMonths: number = 0;
    if (this.employments) {
      const components: EmploymentComponent[] = this.employments.toArray();
      components.forEach(employment => {
        totalDurationOfEmploymentInMonths += employment.durationInMonths;
      });
    }
    this.numberOfYears = Math.floor(totalDurationOfEmploymentInMonths / 12);
    this.numberOfMonths = totalDurationOfEmploymentInMonths - this.numberOfYears * 12;
    this.hasMoreThan2YearsOfHistory = (this.numberOfYears * 12 + this.numberOfMonths >= 24);
    this.monthsOfHistoryForValidation = !this.hasMoreThan2YearsOfHistory ? undefined : this.numberOfYears * 12 + this.numberOfMonths;
  }

  private onAttemptedToDeleteEmployment = (employment: Employment) => {
    const self = this;

    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this employment?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        const index = self._borrower.employments.indexOf(employment);
        if (index >= 0) {
          self._borrower.employments.splice(index, 1);
          self.setEmployments();
          self.calculateTotalIncome();
          self.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
          setTimeout(() => {
            self.calculateTotalDuration();
          });
        }
      }
    });
  }

  onSwitchedEmploymentType = () => {
    this.setEmployments();
  }

  private setEmployments = () => {
    this.setCurrentEmployments();
    this.formerEmployments = this._borrower.employments.filter(e => e.employmentType === EmploymentTypeEnum.FormerEmployer);
    this.formerEmployments = this.formerEmployments.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
  }

  private setCurrentEmployments = () => {
    this.currentEmployments = [];
    const currentEmployments = this._borrower.employments.filter(e => e.employmentType === EmploymentTypeEnum.CurrentEmployer);
    if (currentEmployments.length > 0) {
      this.currentEmployments.push(currentEmployments[0]);
    } else {
      this.currentEmployments = [];
    }
    this.additionalEmployments = currentEmployments.slice(1, currentEmployments.length);
    this.additionalEmployments = this.additionalEmployments.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
    if (currentEmployments.length === 1) {
      // currentEmployments[0].isPrimary = false;
    } else {
      this.setPrimaryEmploymentBasedOnIncome(currentEmployments);
    }
  }

  private subscribeToEmploymentEvents = () => {
    if (this.employments) {
      const components: EmploymentComponent[] = this.employments.toArray();
      components.forEach(item => {
        const deleteItemClickedEventSubscription = item.attemptedToDeleteEmployment.subscribe(e => this.onAttemptedToDeleteEmployment(e));
        this._employmentEventSubscriptions.push(deleteItemClickedEventSubscription);
        const incomesChangedEventSubscription = item.incomesChanged.subscribe(e => {
          this.calculateTotalIncome();
          this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
        });
        this._employmentEventSubscriptions.push(incomesChangedEventSubscription);
      });
    }
  }

  private setPrimaryEmploymentBasedOnIncome = (currentEmployments: Employment[]) => {
    let employmentsToLookAt = currentEmployments.filter(e => e.isPrimary);
    if (!employmentsToLookAt.length) {
      employmentsToLookAt = currentEmployments;
    }

    let maxIncome = -9999;
    let employmentWithMaxIncome = null;
    employmentsToLookAt.forEach(employment => {
      employment.isPrimary = false;
      const totalIncome = employment.incomes.reduce((accumulator, income) => {
        return accumulator + income.monthlyIncome;
      }, 0);
      if (totalIncome >= maxIncome) {
        employmentWithMaxIncome = employment;
        maxIncome = totalIncome;
      }
    });
    if (employmentWithMaxIncome) {
      employmentWithMaxIncome.isPrimary = true;
    }
  }

  private sum = (a: number, b: number): number => {
    if (!a) a = 0;
    if (!b) b = 0;
    return a + b;
  }
}
