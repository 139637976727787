<div class="card">
    <div class="card-header">
        <h4 class="card-title">Please search for the loan from your LOS.</h4>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-11">
                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label class="form-label" for="losProvider">LOS Provider</label>
                            <select class="form-select" id="losProvider" [(ngModel)]="losCredentialId" (change)="changeSelectedLosProvider(losCredentialId)" required>
                                <option [ngValue]="null" selected> -- Select One -- </option>
                                <option *ngFor="let item of losProviders" value="{{item.credentialId}}">
                                    {{item.losVendorName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label class="form-label" for="losReference">LOS Reference #</label>
                            <input type="text" class="form-control" id="losReference" [(ngModel)]="refNumber" [disabled]="!losCredentialId">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label class="form-label" for="borrowerFirst">Borrower First</label>
                            <input type="text" class="form-control" id="borrowerFirst" [(ngModel)]="borrFirstName" [disabled]="!losCredentialId || selectedProvider['borrowerDisabled']">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-3">
                            <label class="form-label" for="borrowerLast">Borrower Last</label>
                            <input type="text" class="form-control" id="borrowerLast" [(ngModel)]="borrLastName" [disabled]="!losCredentialId || selectedProvider['borrowerDisabled']">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <div style="margin-top: 30px;">
                    <a *ngIf="!searchingLos" (click)="searchLos()" href="javascript: void(0);"
                        [ngStyle]="{ 'pointer-events': losCredentialId && (refNumber || borrFirstName || borrLastName) ? 'auto' : 'none' }">
                        <i class="fa fa-lg fa-search"></i>
                    </a>
                    <i class="fa fa-lg fa-sync fa-spin" *ngIf="searchingLos"></i>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                    <h3 *ngIf="loanList && loanList.length === 0">There are no loans matching the search criteria.</h3>

                <div class="table-responsive" style="max-height: 500px;">
                    <table class="table mb-0 table-centered" *ngIf="loanList" >
                        <thead>
                            <tr>
                                <td>Select</td>
                                <td>Loan Number</td>
                                <td>Borrower First</td>
                                <td>Borrower Last</td>
                                <td>Subject Property Address</td>
                                <td>Subject Property CSZ</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sr of loanList">
                                <td>
                                    <input
                                        type="radio"
                                        name="search"
                                        [(ngModel)]="selectedLoanId"
                                        value="{{sr.losIdentifier}}"
                                        (click)="setSelectedLoan(sr.loanNumber, sr.losIdentifier)" />
                                </td>
                                <td>{{sr.loanNumber}}</td>
                                <td>{{sr.borrowerFirstName}}</td>
                                <td>{{sr.borrowerLastName}}</td>
                                <td>{{sr.subjectPropertyAddress}}</td>
                                <td>{{sr.subjectPropertyCity}}, {{sr.subjectPropertyState}} {{sr.subjectPropertyZip}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card" [hidden]="!selectedLoan || enabledChannels.length === 0">
    <div class="card-header">
        <h4 class="card-title">Choose the Channel</h4>
    </div>
    <div class="card-body" [hidden]="selectedLoan && selectedLoan.losIdentifier.length === 0">
        <div class="row">
            <div class="col-md-12">
                <div class="mb-3 row">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Channel</label>
                    <div class="col-sm-5">
                        <select class="form-select" [(ngModel)]="selectedChannel" (change)="handleChannelChange(selectedChannel)" required>
                            <option value="" selected> -- Select One -- </option>
                            <option *ngFor="let c of enabledChannels" value="{{c.name}}">
                                {{c.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="mb-3 row" *ngIf="!isTpoUser && selectedLoan && selectedLoan.losIdentifier?.length && (selectedChannel === 'Wholesale' || selectedChannel === 'Correspondent')">
                    <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Company</label>
                    <div class="col-sm-5">
                        <select class="form-select" [(ngModel)]="selectedCompanyId" (change)="handleCompanyChange(selectedCompanyId)" required>
                            <option value="" selected> -- Select One -- </option>
                            <option *ngFor="let c of externalCompanies | sort: 'asc':'name'" title="{{c.name}}" value="{{c.externalCompanyId}}">
                                {{c.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card" [hidden]="!selectedLoan || !selectedLoan.losIdentifier.length || (enabledChannels.length > 0 && !selectedChannel)">
    <div class="card-header">
        <h4 class="card-title">Choose the {{ isTpoUser ? 'Contacts' : firstRole?.roleName }}</h4>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12">
              <role-based-internal-contacts [selectedCompanyId]="selectedCompanyId" [selectedChannel]="selectedChannel"
              [roleUsers]="availableRoleUsers" [firstRole]="firstRole" [secondaryRoles]="avaliableSecondaryRoles"
              [isTpoUser]="isTpoUser" [loadingUsers]="false" [primaryRoleUserId]="primaryRoleUserId"
              [primaryContactIsRequired]="false"
              (primaryRoleContactChanged)="primaryRoleUserId = $event;"
              (secondaryRoleContactChanged)="tpoAlignmentRoleUsers = $event;"
              >
              </role-based-internal-contacts>
            </div>
        </div>
    </div>
</div>
<div class="text-center">
    <button class="btn btn-primary" [disabled]="!selectedLoan || !selectedLoan.losIdentifier || !primaryRoleUserId || (!selectedChannel && enabledChannels.length !== 0)" (click)="import()">Import From LOS</button>
</div>
