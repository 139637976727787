<form class='row' #form='ngForm'>
  <!-- employer name input -->
  <div class='col-12'>
    <ng-container *ngLet='isRequired("employer") as isRequired'>
      <div
        *ngLet='controlIds.employer as id'
        class='flex-grow-1 form-group'
      >
        <label [for]='id'>
          Employer or Business Name

          <span *ngIf='isRequired' class='text-danger'>*</span>
        </label>

        <address-autocomplete-input
          (addressChange)='onChangeAddress($event)'
          (ngModelChange)='employerNameChange.emit($event); onChangeControlValue()'
          *ngLet='getErrors("employer") as errors'
          [addressLookupOptions]='addressLookupOptions'
          [class.invalid-requested]='errors?.requested'
          [class.invalid-required]='errors?.required'
          class='employer-name-input'
          [id]='id'
          [ngModel]='employerName'
          [required]='isRequired'
          name='employer'
        ></address-autocomplete-input>
      </div>
    </ng-container>
  </div>

  <!-- address and unit/suite inputs -->
  <div class='col-12 compact-form-group'>
    <!-- address input -->
    <ng-container *ngLet='isRequired("address1") as isRequired'>
      <div
        *ngLet='controlIds.address1 as id'
        class='flex-grow-1 form-group'
      >
        <label [for]='id'>
          Employer Address

          <span *ngIf='isRequired' class='text-danger'>*</span>
        </label>

        <input
          (ngModelChange)='value.address1 = $event; onChangeControlValue()'
          *ngLet='getErrors("address1") as errors'
          [class.invalid-requested]='errors?.requested'
          [class.invalid-required]='errors?.required'
          [id]='id'
          [ngModel]='value.address1'
          [required]='isRequired'
          class='form-control'
          name='address1'
          type='text'
        >
      </div>
    </ng-container>

    <!-- unit/suite input -->
    <ng-container *ngLet='isRequired("address2") as isRequired'>
      <div
        *ngLet='controlIds.address2 as id'
        class='flex-grow-1 form-group'
      >
        <label [for]='id'>
          Unit/Suite

          <span *ngIf='isRequired' class='text-danger'>*</span>
        </label>

        <input
          (ngModelChange)='value.address2 = $event; onChangeControlValue()'
          *ngLet='getErrors("address2") as errors'
          [class.invalid-requested]='errors?.requested'
          [class.invalid-required]='errors?.required'
          [id]='id'
          [ngModel]='value.address2'
          [required]='isRequired'
          class='form-control'
          name='address2'
          type='text'
        >
      </div>
    </ng-container>
  </div>

  <!-- city, state and zip code inputs -->
  <div class='col-12 compact-form-group'>
    <!-- city input -->
    <ng-container *ngLet='isRequired("city") as isRequired'>
      <div
        *ngLet='controlIds.city as id'
        class='form-group flex-grow-1'
      >
        <label [for]='id'>
          City

          <span *ngIf='isRequired' class='text-danger'>*</span>
        </label>

        <input
          (ngModelChange)='value.city = $event; onChangeControlValue()'
          *ngLet='getErrors("city") as errors'
          [class.invalid-requested]='errors?.requested'
          [class.invalid-required]='errors?.required'
          [id]='id'
          [ngModel]='value.city'
          [required]='isRequired'
          class='form-control'
          name='city'
          type='text'
        >
      </div>
    </ng-container>

    <!-- state select -->
    <ng-container *ngLet='isRequired("state") as isRequired'>
      <div
        *ngLet='controlIds.state as id'
        class='form-group'
      >
        <label [for]='id'>
          State

          <span *ngIf='isRequired' class='text-danger'>*</span>
        </label>

        <select
          (ngModelChange)='value.state = $event; onChangeControlValue()'
          *ngLet='getErrors("state") as errors'
          [class.invalid-requested]='errors?.requested'
          [class.invalid-required]='errors?.required'
          [id]='id'
          [ngModel]='value.state'
          [required]='isRequired'
          class='min-width-80p form-select'
          name='state'
        >
          <option [ngValue]='null'></option>

          <option
            *ngFor='let state of stateItems'
            [ngValue]='state.value'
          >
            {{ state.name }}
          </option>
        </select>
      </div>
    </ng-container>

    <!-- zip code input -->
    <ng-container *ngLet='isRequired("zipCode") as isRequired'>
      <div
        *ngLet='controlIds.zipCode as id'
        class='form-group'
      >
        <label [for]='id'>
          Zip Code

          <span *ngIf='isRequired' class='text-danger'>*</span>
        </label>

        <zip-code-input
          (ngModelChange)='value.zipCode = $event; onChangeControlValue()'
          (selectionChanged)='onChangeZipCode($event)'
          *ngLet='getErrors("zipCode") as errors'
          [class.invalid-requested]='errors?.requested'
          [class.invalid-required]='errors?.required'
          [id]='id'
          [ngModel]='value.zipCode'
          [required]='isRequired'
          class='border-radius-0'
          name='zipCode'
        ></zip-code-input>
      </div>
    </ng-container>
  </div>
</form>
