
<div class="group-container-repeat">
  <div class="grid-layout">
    <div class="grid-layout-row grid-row" *ngIf="index > 0">
      <div class="grid-header">
        <div class="grid-header-left">
          <ul class="list-unstyled mb-0 mx-2">
            <li class="dropdown">
              <a class="link dropdown-toggle"
                *ngIf="!isReadOnly"
                data-bs-toggle="dropdown"
                aria-haspopup="false"
                (click)="onCopyFromClicked()"
                aria-expanded="false"
                href="#"
                role="button">
                Copy from
              </a>
              <div class="dropdown-menu dropdown-menu-end" style="z-index: 9999">
                <a class="dropdown-item" (click)="copyFrom(address)" *ngFor="let address of addressesToPickFrom">{{getFullAddress(address)}}</a>
                <p class="dropdown-item mb-0" *ngIf="addressesToPickFrom?.length === 0">No residencies to copy from.</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="grid-header-right"></div>
      </div>
    </div>

    <urla-address
      [isReadOnly]="isReadOnly"
      [address]="residencyAddress.address"
      [zipcodeLookupEnabled]="true"
      (streetAddressBlurred)="onStreetAddressChanged()"
      (cityBlurred)="onCityBlurred()"
      (stateBlurred)="onStateBlurred()"
      (zipcodeBlurred)="onZipCodeBlurred()"
      (addressChanged)="onStreetAddressChanged()"
      (unitNoBlurred)="onUnitNoBlurred()"
      [addressLabel]="'Present Address'"
      [showAllCountries]="true"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.residencyAddress'">
    </urla-address>

    <div class="grid-layout-row" *ngIf="showHousingInfo">
      <urla-dropdown
        class="col-sm-3 col-xs-6"
        [readonly]="isReadOnly"
        [name]="'housing_' + residencyAddress.residencyAddressId"
        [label]="'Housing'"
        [options]="housingTypes"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.residencyAddress.residencyBasis'"
        [required]="isRequired('mortgage.borrower.residencyAddress.residencyBasis')"
        [(ngModel)]="residencyAddress.residencyBasis"
        (ngModelChange)="onBorrowerPresentAddressResidencyBasisChanged(residencyAddress)"
      ></urla-dropdown>

      <urla-months-years-duration
        class="col-sm-4 col-xs-6"
        [name]="'duration_' + residencyAddress.residencyAddressId"
        [readonly]="isReadOnly"
        [label]="'How long at this address?'"
        [(ngModel)]="durationAtAddress"
        (ngModelChange)="onDurationChanged()"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.residencyAddress.durationAtAddress'"
        [required]="isRequired('mortgage.borrower.residencyAddress.durationAtAddress')"
      ></urla-months-years-duration>

      <urla-currency-input
        *ngIf="residencyAddress.residencyBasis == 'Rent' || inEditMode"
        class="col-sm-2 col-xs-6"
        [readonly]="isReadOnly"
        [suffix]="'/month'"
        [name]="'rent_'+ residencyAddress.residencyAddressId"
        [label]="'Rent'"
        [isZeroValid]="residencyAddress.residencyBasis != 'Rent' || inEditMode"
        [(ngModel)]="residencyAddress.rent"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.residencyAddress.rent'"
        [required]="isRequired('mortgage.borrower.residencyAddress.rent')"
      ></urla-currency-input>

      <subject-property-input *ngIf="(isSubjectPropertyPickerVisible && residencyAddress.residencyBasis == 'Own')
          || inEditMode"
        #subjectProperty class="col-sm-4 col-xs-6"
        [ngClass]="{'col-md-3': inEditMode}"
        label="Subject Property"
        [(ngModel)]="isSubjectProperty"
        (change)="onSubjectPropertyStatusChanged(residencyAddress)"
        (pushToLoanInfoClicked)="onPushAddressToSubjectPropertyClicked()"
        (importLoanInfoClicked)="onPullAddressSubjectPropertyClicked(residencyAddress)"
        [readonly]="isReadOnly"
        [options]="yesNoOptions"
        [name]="'subjectPropertyPresentaddress_' + residencyAddress.residencyAddressId"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.borrower.residencyAddress.isSubjectProperty'"
        [required]="isRequired('mortgage.borrower.residencyAddress.isSubjectProperty')">
      </subject-property-input>
    </div>
  </div>
</div>
