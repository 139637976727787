import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../../../services/notification.service';
import { LdeService } from 'src/app/services/lde.service';
import { LdeTransactionHistory, LdeEvent, LosEventDirection, LosEventOperation, LosEventSource, LosEventStatus } from 'src/app/models';

@Component({
  selector: 'lde-transaction-history',
  templateUrl: 'lde-transaction-history.component.html',
  styleUrls: ['./lde-transaction-history.component.scss']
})
export class LdeTransactionHistoryComponent implements OnInit {
  @Input()
  applicationId: number;

  transactionHistory: LdeTransactionHistory;

  loadingHistory: boolean = false;

  applicationTimeStr: string = '';
  ldeLoanTimeStr: string = '';
  stateKey = 'los-lde-transaction-history-table-state';

  filteredTransactionHistoryEvents: LdeEvent[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public _datePipe: DatePipe,
    private readonly _notificationService: NotificationService,
    private readonly _ldeService: LdeService
  ) {

  }

  ngOnInit(): void {
    this.loadingHistory = true;
    this._ldeService.getTransactionHistory(this.applicationId)
      .subscribe({
        next: (response) => {
          this.transactionHistory = response || new LdeTransactionHistory();

          if(this.transactionHistory.applicationTime) {
            this.applicationTimeStr = this._datePipe.transform(this.transactionHistory.applicationTime, 'MMM d, y h:mm:ss a');
          }

          if(this.transactionHistory.ldeLoanTime) {
            this.ldeLoanTimeStr = this._datePipe.transform(this.transactionHistory.ldeLoanTime, 'MMM d, y h:mm:ss a');
          }
          
          this.filteredTransactionHistoryEvents = [...this.transactionHistory.events];
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message,
            'Error!'
          );
        }
      }).add(() => this.loadingHistory = false);
  }

  onFilterChanged({
    eventSourceFilter,
    directionFilter,
    operationFilter,
    statusFilter,
  }: {
    eventSourceFilter: LosEventSource
    directionFilter: LosEventDirection
    operationFilter: LosEventOperation
    statusFilter: LosEventStatus
  }) {
    this.filteredTransactionHistoryEvents = this.transactionHistory.events.filter(event => 
      (!eventSourceFilter || eventSourceFilter === event.eventSource) &&
      (!directionFilter || directionFilter === event.eventDirection) &&
      (!operationFilter || operationFilter === event.eventOperation) &&
      (!statusFilter || statusFilter === event.status)
    )
  }
}
