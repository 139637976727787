import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { HousingExpense, LoanApplication, MortgageInsuranceDetail } from 'src/app/models';
import { LoanDetailsInfo } from 'src/app/models/loan/loan-details-info.model';
import { AppDetailsService } from 'src/app/modules/app-details/services/app-details.service';
import { ExternalContactsV2Service } from 'src/app/modules/external-contacts-v2/services/external-contacts-v2.service';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'purchase-advice',
  templateUrl: 'purchase-advice.component.html',
  styleUrls: ['purchase-advice.component.scss']
})

export class PurchaseAdviceComponent implements OnInit {

  @ViewChild("purchaseAdviceForm")
  purchaseAdviceForm: NgForm | null = null;

  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onSave: EventEmitter<LoanDetailsInfo> = new EventEmitter<LoanDetailsInfo>();

  @Input()
  app: LoanApplication

  purchaseDate: string = "TBD";
  closingDate: string;
  disbursementDate: string;
  firstPaymentDate: string;
  lockExpirationDate: string;

  // loanAmtValue: number = 579.500;
  //TBD
  netSRPValue: number;
  lessNetAmtValue: number;
  lessEscrowBalanceValue: number = 0;
  interestPaidValue: number;
  lessNetMortgageInsuranceValue: number = 125.28;
  totalWireFundingAmt: number;

  baseSRPFromLockRate: number;
  baseSRPFromLockValue: number;
  costsRate: number;
  costsValue: number;
  discountsRate: number;
  discountsValue: number;
  holdbackRate: number;
  holdbackValue: number;
  totalSRPRate: number;

  totalCredits: number;

  //TBD
  taxServiceValue: number;
  floodCertificationValue: number;
  underwritingFeeValue: number;

  //TBD
  hazardInsurance: number;
  mudTaxes: number;
  aggregateTaxes: number;

  //TBD
  fromDate: string;
  toDate: string;
  interestPerDay: number;
  totalDays: number;

  //TBD
  miCosts: number;

  editorMode: string = 'Inline';

  application: LoanApplication;

  protected borrowerNames: string;
  protected loadingContacts: boolean = true;
  protected loadingKeyDates: boolean = true;
  protected titleCompanyName: string;

  constructor(
    private readonly _utilityService: UtilityService,
    private readonly _externalContactService: ExternalContactsV2Service,
    private readonly _notifyService: NotificationService,
    private readonly _loanService: LoanService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.application = _.cloneDeep(this.app);

    this.setLoanInformations();

    this.calculateTotalNetSRPAmount();
    this.calculateTotalNetSRPRate();
    this.calculateTotalCredits();
    this.calculateDueToUWM();
    this.calculateEscrowBreakdown();
    this.calculateInterestAdjustment();
    this.calculateMortgageInsurance();
  }

  onNetFundingBreakdownValueChanged = () => {
    this.calculateTotalWireFundingAmount();
  }

  onNetSRPBreakdownValueChanged = () => {
    this.calculateTotalNetSRPAmount();
  }

  onNetSRPBreakdownRateChanged = () => {
    this.calculateTotalNetSRPRate();
  }

  onAmountsDueToUWMChanged = () => {
    this.calculateDueToUWM();
  }

  onEscrowBreakdownValueChanged = () => {
    this.calculateEscrowBreakdown();
  }

  onInterestAdjustmentValueChanged = () => {
    this.calculateInterestAdjustment();
  }

  onMortgageInsuranceChanged = () => {
    this.calculateMortgageInsurance();
  }

  onCloseClicked() {
    this.onClose.emit();
  }

  onSaveClicked() {
    this._spinner.show();

    let loanInfo = new LoanDetailsInfo();
    loanInfo.application = this.application;
    loanInfo.customData = null;
    loanInfo.application.mortgageLoan = this.application.mortgageLoan;

    const request = {
      // "PurchaseDate": this.purchaseDate ? Utils.formatISODateWithoutTime(this.purchaseDate) : null,
      "EstimatedClosing": this.closingDate ? Utils.formatISODateWithoutTime(this.closingDate) : null,
      "Disbursement": this.disbursementDate ? Utils.formatISODateWithoutTime(this.disbursementDate) : null,
      "FirstPayment": this.firstPaymentDate ? Utils.formatISODateWithoutTime(this.firstPaymentDate) : null,
      "RateLockExpiration": this.lockExpirationDate ? Utils.formatISODateWithoutTime(this.lockExpirationDate) : null,
    }

    this._appDetailsService.saveLoanInfo(this.application.applicationId, loanInfo)
      .subscribe({
        next: (res) => {
          this._loanService.saveKeyDatesByType(request, this.application.applicationId).subscribe({
            next: () => {
              this.onSave.emit(res);
            },
            error: (err) => {
              this._notifyService.showError(err.message || 'Unable to save key dates.', 'Error!');
            }
          }).add(() => this._spinner.hide());

        },
        error: (err) => {
          this._notifyService.showError(err.message || 'Unable to save loan.', 'Error!');
        }
      });
  }

  private calculateTotalWireFundingAmount = () => {
    this.totalWireFundingAmt = (this.application.loanAmount || 0) + (this.netSRPValue || 0) + (this.lessNetAmtValue || 0) + (this.lessEscrowBalanceValue || 0)
      + (this.interestPaidValue || 0) + (this.lessNetMortgageInsuranceValue || 0);
  }

  private calculateTotalNetSRPAmount = () => {
    this.netSRPValue = (this.baseSRPFromLockValue || 0) + (this.costsValue || 0) + (this.discountsValue || 0) + (this.holdbackValue || 0);
    this.calculateTotalWireFundingAmount();
  }

  private calculateTotalNetSRPRate = () => {
    this.totalSRPRate = (this.baseSRPFromLockRate || 0) + (this.costsRate || 0) + (this.discountsRate || 0) + (this.holdbackRate || 0);
  }

  private calculateTotalCredits = () => {
    // this.totalCredits = 0;
  }

  private calculateDueToUWM = () => {
    this.lessNetAmtValue = (this.taxServiceValue || 0) + (this.floodCertificationValue || 0) + (this.underwritingFeeValue || 0);
    this.calculateTotalWireFundingAmount();
  }

  private calculateEscrowBreakdown = () => {
    if (!this.application.mortgageLoan?.proposedHousingExpense) {
      this.application.mortgageLoan.proposedHousingExpense = new HousingExpense();
    }
    this.lessEscrowBalanceValue = (this.hazardInsurance || 0) + (this.application.mortgageLoan.proposedHousingExpense.floodInsurance || 0) + (this.application.mortgageLoan.proposedHousingExpense.hailInsurance || 0) + (this.application.mortgageLoan.proposedHousingExpense.mortgageInsurance || 0)
      + (this.application.mortgageLoan.proposedHousingExpense.cityPropertyTax || 0) + (this.application.mortgageLoan.proposedHousingExpense.countyPropertyTax || 0) + (this.application.mortgageLoan.proposedHousingExpense.villagePropertyTax || 0) + (this.mudTaxes || 0) + (this.aggregateTaxes || 0);
    this.calculateTotalWireFundingAmount();
  }

  private calculateInterestAdjustment = () => {
    this.interestPaidValue = (this.interestPerDay || 0);
    this.calculateTotalWireFundingAmount();
  }

  private calculateMortgageInsurance = () => {
    if (!this.application.mortgageLoan?.mortgageInsuranceDetail) {
      this.application.mortgageLoan.mortgageInsuranceDetail = new MortgageInsuranceDetail();
    }
    this.lessNetMortgageInsuranceValue = (this.application.mortgageLoan.mortgageInsuranceDetail.prepaidPremiumTotal || 0) + (this.miCosts || 0);
    this.calculateTotalWireFundingAmount();
  }

  private setLoanInformations = () => {
    const borrowerNames = [];
    if (this.application) {
      this.application.mortgageLoan?.borrowers.forEach(b => {
        const fullName = this._utilityService.getBorrowerFullName(b);
        borrowerNames.push(fullName);
      });
      this.borrowerNames = borrowerNames.join(', ');

      this.loadExternalContactList();
      this.getKeyDates();
    }
  }

  private loadExternalContactList = () => {
    this.loadingContacts = true;
    this._externalContactService.getExternalContacts(this.application.applicationId).subscribe({
      next: (response) => {
        const externalContacts = response;
        const titleContact = externalContacts ?? externalContacts.filter(c => c.agentType?.agentType == "Title");
        this.titleCompanyName = titleContact.length > 0 ? titleContact[0].companyName : '--';
      },
      error: (error) => {
        const fallbackMessage = 'Unable to load external contacts';
        const message = error?.message || fallbackMessage;
        this._notifyService.showError(message, 'Error');
      }
    }).add(() => this.loadingContacts = false);
  }

  private getKeyDates = () => {
    this.loadingKeyDates = true;
    this._loanService.getKeyDatesByType(this.application.applicationId).subscribe({
      next: (response) => {
        this.closingDate = response['estimatedClosing']?.eventDate || null;
        this.disbursementDate = response['disbursement']?.eventDate || null;
        this.firstPaymentDate = response['firstPayment']?.eventDate || null;
        this.lockExpirationDate = response['rateLockExpiration']?.eventDate || null;
      },
      error: (err) => {
        this._notifyService.showError(err.message || 'Unable to get key dates.', 'Error!');
      }
    }).add(() => this.loadingKeyDates = false);
  }
}