<div class="grid-layout has-border">
  <div class='grid-header mb-1'>
    <div class='grid-header-left d-flex gap-4'>
      <div>4c. Rental Income On {{isRefi ? 'Refinance' : 'Purchases'}}</div>

      <label class='text-nowrap d-flex gap-2'>
        <input
          (ngModelChange)='onHasSubjectPropertyRentalIncomeChange($event)'
          [ngModel]='hasSubjectPropertyRentalIncome'
          name='hasSubjectPropertyRentalIncome'
          type='checkbox' />
        Subject Property has Rental Income
      </label>
    </div>
    <div class='grid-header-right'></div>
  </div>
  <div class='grid-layout'>
    <ng-container *ngIf='hasSubjectPropertyRentalIncome'>
      <div class="grid-layout-row">
        <urla-grid-cell
          class='col-md-8 col-xs-6 grid-layout-text'
          [disabled]='isReadOnly'
          [label]="
            'Borrower has at least a one-year history of receiving rental income or documented property management experience'
          ">
        </urla-grid-cell>
        <urla-radio-group
          [(ngModel)]="borrower.hasOneYearLandlordExperience"
          [inEditMode]="inEditMode"
          [options]='yesNoOptions'
          [readonly]='isReadOnly'
          [required]="
            urlaFieldsConfig[
              'mortgage.borrower.hasOneYearLandlordExperience'
            ].required == 'required' ||
            urlaFieldsConfig[
              'mortgage.borrower.hasOneYearLandlordExperience'
            ].required == 'requested'
          "
          [urlaFieldsConfig]="urlaFieldsConfig"
          class='col-md-4 col-xs-6'
          fieldBeingEdited='mortgage.borrower.hasOneYearLandlordExperience'
          layout='horizontal'
          name='hasOneYearLandlordExperience'>
        </urla-radio-group>
      </div>
      <div class="grid-layout-row">
        <urla-currency-input
          (change)="expectedGrossMonthlyRentalIncomeChanged()"
          [(ngModel)]='
            mortgage.subjectProperty.expectedGrossMonthlyRentalIncome
          '
          [inEditMode]='inEditMode'
          [isZeroValid]='false'
          [readonly]='isReadOnly'
          [required]="
            urlaFieldsConfig[
              'mortgage.subjectProperty.expectedGrossMonthlyRentalIncome'
            ] == 'required' ||
            urlaFieldsConfig[
              'mortgage.subjectProperty.expectedGrossMonthlyRentalIncome'
            ] == 'requested'
          "
          [urlaFieldsConfig]='urlaFieldsConfig'
          class='col-md-4 col-xs-6'
          fieldBeingEdited='mortgage.subjectProperty.expectedGrossMonthlyRentalIncome'
          label='GROSS RENT'
          name='expectedGrossMonthlyRentalIncome'
          (change)="calculateExpectedNetMonthlyRentalIncome()"
        ></urla-currency-input>

        <urla-percentage-input
          class='col-md-4 col-xs-6'
          name='occupancyPercent'
          label='VAC FACTOR'
          [readonly]='isReadOnly'
          [disabled]='isReadOnly'
          [required]="
            urlaFieldsConfig[
              'mortgage.subjectProperty.occupancyPercent'
            ] == 'required' ||
            urlaFieldsConfig[
              'mortgage.subjectProperty.occupancyPercent'
            ] == 'requested'
          "
          [inEditMode]='inEditMode'
          [(ngModel)]='mortgage.subjectProperty.occupancyPercent'
          [urlaFieldsConfig]='urlaFieldsConfig'
          fieldBeingEdited='mortgage.subjectProperty.occupancyPercent'
          (change)="calculateExpectedNetMonthlyRentalIncome()"
        ></urla-percentage-input>

        <urla-currency-input
          (change)="expectedNetMonthlyRentalIncomeChanged()"
          [(ngModel)]='mortgage.subjectProperty.expectedNetMonthlyRentalIncome'
          [inEditMode]='inEditMode'
          [isZeroValid]='false'
          [readonly]='isReadOnly'
          [required]="
            urlaFieldsConfig[
              'mortgage.subjectProperty.expectedNetMonthlyRentalIncome'
            ] == 'required' ||
            urlaFieldsConfig[
              'mortgage.subjectProperty.expectedNetMonthlyRentalIncome'
            ] == 'requested'
          "
          [urlaFieldsConfig]='urlaFieldsConfig'
          class='col-md-4 col-xs-6'
          fieldBeingEdited='mortgage.subjectProperty.expectedNetMonthlyRentalIncome'
          label='NET RENTAL INCOME'
          name='expectedNetMonthlyRentalIncome'
          (change)="calculateOoccupancyPercent()"
        ></urla-currency-input>
      </div>
    </ng-container>
  </div>
</div>
