import { Component, Input } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaDropdownComponent } from '../urla-dropdown/urla-dropdown.component';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'urla-state-dropdown',
  templateUrl: '../urla-dropdown/urla-dropdown.component.html',
  providers: [MakeProvider(UrlaStateDropdownComponent)],
})

export class UrlaStateDropdownComponent extends UrlaDropdownComponent {

  @Input()
  stateValuesAreLowerCase: boolean = true;

  constructor(private readonly _enumsService: EnumerationService) {
    super();
  }

  ngOnInit() {
    if (!this.stateValuesAreLowerCase) {
      let options = [];
      options = this._enumsService.states.map((option) => {
        return {
          value: option.value.toUpperCase(),
          name: option.name
        };
      });
      this.options = options;
    } else {
      this.options = this._enumsService.states;
    }
  }
}
