<div class="card">
  <div class="card-header">{{mode === 'edit' ? 'Edit' : 'Add'}} Asset</div>
  <div class="card-body">
    <form #assetForm="ngForm" novalidate id="assetForm" name="assetForm" class="w-100">
      <div class="row">
        <div class="form-group col-md-4 mb-3">
          <label
            [for]='id("owning-borrower-ids")'
            class="form-label mb-lg-0 text-start mb-2"
          >
            Account Owner
          </label>

          <ng-select
            #owningBorrowerIds='ngModel'
            [(ngModel)]='asset.owningBorrowerIds'
            [closeOnSelect]='false'
            [id]='id("owning-borrower-ids")'
            [items]='possibleAccountOwners'
            [multiple]='true'
            [ngClass]="{'is-invalid' : owningBorrowerIds && owningBorrowerIds.touched && owningBorrowerIds.invalid}"
            bindLabel='text'
            bindValue='id'
            class='custom-ng-select'
            name='owningBorrowerIds{{ asset.assetId }}'
            required
          ></ng-select>
          <div class="invalid-feedback">Please select a account owner.</div>
        </div>
        <div class="form-group col-md-4 mb-3">
          <label
            [for]='id("asset-type")'
            class='form-label mb-lg-0 text-start mb-2'
          >
            Account Type
          </label>

          <div>
            <select
              #assetType='ngModel'
              [(ngModel)]='asset.assetType'
              [id]='id("asset-type")'
              [ngClass]="{
                  'invalid-requested' : assetType && assetType.invalid && assetType.errors && assetType.errors.required
                    && quickApplyFieldsConfig['mortgage.asset.assetType'] == 'requested',
                  'invalid-required' : assetType && assetType.invalid && assetType.errors && assetType.errors.required
                    && quickApplyFieldsConfig['mortgage.asset.assetType'] == 'required'
                }"
              [required]="quickApplyFieldsConfig['mortgage.asset.assetType'] == 'required' ||
                quickApplyFieldsConfig['mortgage.asset.assetType'] == 'requested'"
              class='form-select col-md-6'
              name='assetType'
            >
              <optgroup label='Bank/Retirement'>
                <option *ngFor='let type of assetTypes' [ngValue]='type.value'>
                  {{ type.name }}
                </option>
              </optgroup>

              <optgroup label='Other Assets'>
                <option *ngFor='let type of otherAssetTypes' [ngValue]='type.value'>
                  {{ type.name }}
                </option>
              </optgroup>

              <optgroup label='Gifts or Grants'>
                <option *ngFor='let type of giftGrantTypes' [ngValue]='type.value'>
                  {{ type.name }}
                </option>
              </optgroup>
            </select>
          </div>
        </div>
        <div class="form-group col-md-4 mb-3" *ngIf="isGiftOrGrantType()">
          <label class="form-label mb-lg-0 text-start mb-2">Source</label>
          <div>
            <select class="form-select col-md-6" name="giftOrGrantSource" [(ngModel)]="asset.giftOrGrantSource" [ngClass]="{
                'is-invalid': giftOrGrantSource && giftOrGrantSource.touched && giftOrGrantSource.invalid
              }" #giftOrGrantSource="ngModel" [required]="quickApplyFieldsConfig['mortgage.asset.giftOrGrantSource'] == 'required' ||
              quickApplyFieldsConfig['mortgage.asset.giftOrGrantSource'] == 'requested'">
                <option *ngFor="let source of giftGrantSources" [ngValue]="source.value">
                  {{ source.name }}
                </option>
            </select>
            <div class="invalid-feedback">Source is required.</div>
          </div>
        </div>
        <div class="form-group col-md-4 mb-3" *ngIf="isGiftOrGrantType()">
          <label class="form-label mb-lg-0 text-start mb-2">Is Deposited</label>
          <div>
            <select class="form-select col-md-6" name="deposited" [(ngModel)]="asset.isDeposited" [ngClass]="{
                'is-invalid': deposited && deposited.touched && deposited.invalid
              }" #deposited="ngModel" required>
              <option *ngFor="let option of yesNoOptions" [ngValue]="option.value">
                {{ option.name }}
              </option>
            </select>
            <div class="invalid-feedback">Is Deposited is required.</div>
          </div>
        </div>
        <div class="form-group col-md-4 mb-3" *ngIf="!isGiftOrGrantType()">
          <label
            [for]='id("depository")'
            class='form-label mb-lg-0 text-start mb-2'
          >
            Institution
          </label>

          <div>
            <input
              #depository='ngModel'
              [(ngModel)]='asset.depository'
              [id]='id("depository")'
              [ngClass]="{
                  'invalid-requested' : depository && depository.invalid && depository.errors && depository.errors.required
                    && quickApplyFieldsConfig['mortgage.asset.depository'] == 'requested',
                  'invalid-required' : depository && depository.invalid && depository.errors && depository.errors.required
                    && quickApplyFieldsConfig['mortgage.asset.depository'] == 'required'
                }"
              [required]="quickApplyFieldsConfig['mortgage.asset.depository'] == 'required' ||
                quickApplyFieldsConfig['mortgage.asset.assetType'] == 'requested'"
              class='form-control'
              name='depository'
              type='text'
            />
          </div>
        </div>
        <div class="form-group col-md-4 mb-3" *ngIf="!isGiftOrGrantType()">
          <label
            [for]='id("account-number")'
            class="form-label mb-lg-0 text-start mb-2"
          >
            Account Number
          </label>

          <div>
            <input
              #accountNumber='ngModel'
              [(ngModel)]='asset.accountNumber'
              [id]='id("account-number")'
              [ngClass]="{
                  'invalid-requested' : accountNumber && accountNumber.invalid && accountNumber.errors && accountNumber.errors.required
                    && quickApplyFieldsConfig['mortgage.asset.accountNumber'] == 'requested',
                  'invalid-required' : accountNumber && accountNumber.invalid && accountNumber.errors && accountNumber.errors.required
                    && quickApplyFieldsConfig['mortgage.asset.accountNumber'] == 'required'
                }"
              [required]="quickApplyFieldsConfig['mortgage.asset.accountNumber'] == 'required' ||
                quickApplyFieldsConfig['mortgage.asset.accountNumber'] == 'requested'"
              class='form-control'
              name='accountNumber'
              type='text'
            />
          </div>
        </div>
        <div class="form-group col-md-4 mb-3">
          <label
            [for]='id("cash-market-value")'
            class='form-label mb-lg-0 text-start mb-2'
          >
            Cash/Market Value
          </label>

          <div>
            <currency-input
              #cashMarketValue='ngModel'
              [(ngModel)]='asset.cashMarketValue'
              [id]='id("cash-market-value")'
              [ngClass]="{
                  'invalid-requested' : !asset.cashMarketValue && quickApplyFieldsConfig['mortgage.asset.cashMarketValue'] == 'requested',
                  'invalid-required' : !asset.cashMarketValue && quickApplyFieldsConfig['mortgage.asset.cashMarketValue'] == 'required'
                }"
              [required]="quickApplyFieldsConfig['mortgage.asset.cashMarketValue'] == 'required' ||
                quickApplyFieldsConfig['mortgage.asset.cashMarketValue'] == 'requested'"
              name='cashMarketValue'
            ></currency-input>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer d-flex justify-content-end align-items-stretch gap-2">
    <qa-cancel-button (qaClick)='onClickCancel()'></qa-cancel-button>

    <qa-ok-button (qaClick)='onClickClose()'></qa-ok-button>
  </div>
</div>
