import { Component, Injector, OnInit } from '@angular/core';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContext, LoanApplication, LoanDoc, DocumentType, Role, UserPermissions, Borrower } from 'src/app/models';
import { LoanDocsV2Service } from '../services/loan-docs-v2.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { StackingOrderDocTypes } from 'src/app/models/config/stacking-order-doc-types.model';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { UtilityService } from '../../urla/services/utility.service';
import { User } from 'src/app/models/user/user.model';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';
import { TaskService } from 'src/app/services/task.service';
import { LoanDocsV2DialogComponent } from './loan-docs-dialog/loan-docs-v2-dialog.component';
import { Constants } from 'src/app/services/constants';
import { LoanDocUploadFile } from './loan-docs-table-view/components/loan-docs-table-view.component';

@Component({
  selector: 'loan-docs-v2',
  templateUrl: 'loan-docs-v2.component.html',
  styleUrls: ['loan-docs-v2.component.scss'],
})
export class LoanDocsV2Component extends ApplicationContextBoundComponent implements OnInit {

  loadingLoanDocs: boolean = true;
  errorMessage: string = null;
  isTpoUser: boolean;
  filteredStackingOrders: StackingOrderDocTypes[];
  selectedStackingOrderId: number;
  documentTypesPerRoles: DocumentType[] = [];
  filteredDocumentTypes: DocumentType[] = [];
  filteredDocTypesByLoanDoc: DocumentType[] = [];
  selectedDocumentTypeId: number = -99; //for select All
  selectedBorrowerId: number = 0; //for select All
  selectedLinkedId: number = 0 //for select All;
  userType: string;
  globalFilterText: string;

  currentApplication: LoanApplication;
  globalConfig: GlobalConfig;
  userPermissions: UserPermissions;
  roles: Role[];
  borrowers: Borrower[] = [];
  filteredDocs: LoanDoc[] = [];
  usersAll: User[] = [];
  loanDocTasks: LoanDocTask[] = [];

  selectedView: string = "tableView";

  isDocumentSigningRequiredReview: boolean;

  protected filteredLoanDocs: LoanDoc[] = [];

  private _roleId: number;
  private _uploadedFiles: Array<File> = [];
  private _originalFilteredDocTypes: DocumentType[] = [];

  private _loanDocsServiceEventSubscription: Subscription = null;
  private _loanInfoChangesSubscription: Subscription = null;
  private _loanTasksChangesSubscription: Subscription = null;
  private _documentUploadedSubscription: Subscription = null;

  private _loanDocs: LoanDoc[] = [];

  constructor(
    injector: Injector,
    private readonly _notifyService: NotificationService,
    private readonly _loanDocsV2Service: LoanDocsV2Service,
    private readonly _navigationService: NavigationService,
    private readonly _modalService: NgbModal,
    private readonly _utilityService: UtilityService,
    private readonly _taskService: TaskService,
  ) {
    super(injector);
    this.applicationContextService.loanInfoLoadError.subscribe(error => {
      this.errorMessage = error;
      this.loadingLoanDocs = false;
    });
    this._loanDocsServiceEventSubscription = this._loanDocsV2Service.documentGenerated.subscribe(() => {
      this.initializeLoanDocs();
    });
    this._documentUploadedSubscription = this._loanDocsV2Service.documentUploaded.subscribe(() => {
      this.initializeLoanDocs();
    });
    this._loanTasksChangesSubscription = this.applicationContextService.loanTasksChanges.subscribe(() => {
      this.initializeLoanDocsAndTasks();
    });
  }

  ngOnInit(): void {
    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          this.initialize(context);
        }
      });
    } else {
      this.initialize(this.applicationContext);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._loanDocsServiceEventSubscription?.unsubscribe();
    this._loanInfoChangesSubscription?.unsubscribe();
    this._loanTasksChangesSubscription?.unsubscribe();
    this._documentUploadedSubscription?.unsubscribe();
  }

  onDocumentTypeFilterChanged = () => {
    this.filteredDocumentTypes = this.filterDocTypes(this.selectedDocumentTypeId);
    this.filteredLoanDocs = this.filterLoanDocs(this._loanDocs);
  }

  onBorrowerFilterChanged = () => {
    this.filteredLoanDocs = this.filterLoanDocs(this._loanDocs);
  }

  getBorrowerFullName = (borrower: Borrower) => {
    return this._utilityService.getBorrowerFullName(borrower);
  }

  onStackingOrderFilterChanged = (selectedId: number) => {
    this.filteredDocumentTypes = this.filterDocTypesByStackingOrder(selectedId);
  }

  onLinkedFilterChanged = () => {
    this.filteredLoanDocs = this.filterLoanDocs(this._loanDocs);
  }

  onUploadClicked = (fileToUpload?: LoanDocUploadFile) => {
    const modalRef = this._modalService.open(LoanDocsV2DialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.title = 'Upload';
    modalRef.componentInstance.docTypes = this.documentTypesPerRoles;
    modalRef.componentInstance.appId = this.currentApplication.applicationId;
    modalRef.componentInstance.roleId = this._roleId;
    modalRef.componentInstance.isTpoUser = this.isTpoUser;
    modalRef.componentInstance.autoConvertToPdf = this.userPermissions.autoConvertToPdf;
    modalRef.componentInstance.uploadedFiles = fileToUpload?.files;
    modalRef.componentInstance.selectedDocumentTypeId = fileToUpload?.documentTypeId;
    modalRef.result.then((result) => {
      this._uploadedFiles = [];
      if (!result.documentTypeId) {
        result.documentTypeId = 0;
      }
      this._loanDocs.push(result);
      this._loanDocs = [...this._loanDocs];
      this.selectedDocumentTypeId = -99;
      this.onDocumentTypeFilterChanged();
    }, (res) => {
      this._uploadedFiles = [];
    });
  }

  onUploadFilesClicked = (fileToUpload: LoanDocUploadFile) => {
    this.onUploadClicked(fileToUpload);
  }

  onLoanDocUpdated = () => {
    this.initializeLoanDocs();
  }

  onTaskUpdated = () => {
    this.initializeLoanDocsAndTasks();
  }

  filterGlobal = (e: any) => {
    this.filteredLoanDocs = this.filterLoanDocs(this._loanDocs);
  }

  private filterWithSearchString = (loanDocsToFilter: LoanDoc[], searchString: string): LoanDoc[] => {
    if (!searchString) {
      return loanDocsToFilter;
    }
    const filteredLoanDocs = [];
    loanDocsToFilter.forEach(d => {
      const files = d.docFiles.filter(f => f.fileName.toLowerCase().includes(searchString.toLowerCase())
        || this.getInsertedBy(f.insertedBy).toLowerCase().includes(searchString.toLowerCase()));
      if (files.length) {
        filteredLoanDocs.push(d);
      }
    });
    return filteredLoanDocs;
  }

  private filterLoanDocs = (loanDocsToFilter: LoanDoc[]): LoanDoc[] => {
    // First filter by the available doc types - available doc types change based on the stacking order filter
    let filteredLoanDocs = loanDocsToFilter.filter(ld => this.filteredDocumentTypes.map(d => d.documentTypeId).includes(ld.documentTypeId));

    filteredLoanDocs = this.filterWithSearchString(loanDocsToFilter, this.globalFilterText);

    // Then filter by the selected doc type
    if (this.selectedDocumentTypeId !== -99) {
      filteredLoanDocs = filteredLoanDocs.filter(ld => ld.documentTypeId === this.selectedDocumentTypeId);
    }
    // Then filter by the selected borrower
    if (this.selectedBorrowerId !== 0) {
      if (this.selectedBorrowerId === null) {
        filteredLoanDocs = filteredLoanDocs.filter(ld => !ld.borrowerId);
      } else {
        filteredLoanDocs = filteredLoanDocs.filter(ld => ld.borrowerId === this.selectedBorrowerId);
      }
    }
    // Then filter by 'linked'
    if (this.selectedLinkedId) {
      filteredLoanDocs = this.filterLoanDocsByLinked(filteredLoanDocs, this.selectedLinkedId);
    }
    return filteredLoanDocs;
  }

  private filterDocTypes = (selectedDocTypeId: number) => {
    this.filteredDocumentTypes = this._originalFilteredDocTypes;
    if (selectedDocTypeId === -99) {
      return this.filteredDocumentTypes;
    }
    return this.filteredDocumentTypes.filter(d => d.documentTypeId === selectedDocTypeId);
  }

  private populateDocTypesForLoanDocsWeHave = () => {
    this.filteredDocTypesByLoanDoc = [];
    const docTypeById = new Map<number, DocumentType>(
      this.filteredDocumentTypes.map((docType) => [
        docType.documentTypeId,
        docType,
      ]),
    );

    this._loanDocs.forEach(loanDoc => {
      const docType = docTypeById.get(loanDoc.documentTypeId);
      if (docType && loanDoc.docFiles.length > 0) {
        const existingOne = this.filteredDocTypesByLoanDoc.findIndex(d => d.documentTypeId === docType.documentTypeId);
        if (!(existingOne >= 0)) {
          this.filteredDocTypesByLoanDoc.push(docType);
        }
      }
    })
  }

  private filterDocTypesByStackingOrder = (selectedId: number): DocumentType[] => {
    if (selectedId == 0) {
      return this.documentTypesPerRoles;
    }
    if (this.applicationContext.globalConfig.stackingOrderDocTypes) {
      const filteredDocumentTypes = [];
      let stOrder = this.applicationContext.globalConfig.stackingOrderDocTypes.find(x => x.stakingOrderId == selectedId);
      if (stOrder && stOrder.associate) {
        let associatedDocTypes = stOrder.associate.split(',');
        if (associatedDocTypes) {
          associatedDocTypes.forEach(associatedDocTypeId => {
            let documentType = this.documentTypesPerRoles.find(x => x.documentTypeId == Number(associatedDocTypeId));
            if (documentType && !filteredDocumentTypes.find(x => x.documentTypeId == documentType.documentTypeId)) {
              filteredDocumentTypes.push(documentType);
            }
          });
        }
      }
      return filteredDocumentTypes;
    }
    return this.documentTypesPerRoles;
  }

  private filterLoanDocsByLinked = (loanDocsToFilter: LoanDoc[], selectedId: number): LoanDoc[] => {
    if (selectedId == 0) {
      return loanDocsToFilter;
    }
    if (selectedId == 1) {
      return loanDocsToFilter.filter(loanDoc => loanDoc.loanDocId && loanDoc.losLoanDocId);
    }
    if (selectedId == 2) {
      return loanDocsToFilter.filter(loanDoc => loanDoc.loanDocId && !loanDoc.losLoanDocId);
    }
    if (selectedId == 3) {
      return loanDocsToFilter.filter(loanDoc => !loanDoc.loanDocId && loanDoc.losLoanDocId);
    }
  }

  private filterDocTypesByRoles = (type: DocumentType) => {
    if (type.restrictedToRoles && type.restrictedToRoles.length > 0) {
      this._roleId = this.applicationContext.userPermissions.roleId;
      let vals = type.restrictedToRoles.split(',');
      return vals.filter(x => Number(x) == this._roleId).length > 0 && (!this.isTpoUser || type.showOnTPOPortal);
    }
    return type.documentTypeId > (this.isTpoUser ? -1 : -2) && (!this.isTpoUser || type.showOnTPOPortal);
  }

  private initialize = async (context: ApplicationContext) => {
    this.usersAll = context.globalConfig.usersAll;
    this.isTpoUser = context.isTpo;
    this.borrowers = context.borrowers;
    this.globalConfig = context.globalConfig;
    this.currentApplication = context.application;
    this.filteredStackingOrders = this.globalConfig.stackingOrderDocTypes.filter(docType => !this.isTpoUser || docType.showOnTPOPortal);
    this.userPermissions = context.userPermissions;
    this.selectedStackingOrderId = this.userPermissions.stackingOrderId ? this.userPermissions.stackingOrderId : 0;
    this.roles = this.globalConfig.roles;
    this.documentTypesPerRoles = this.globalConfig.documentType.filter(docType => this.filterDocTypesByRoles(docType));
    this.filteredDocumentTypes = this.filterDocTypesByStackingOrder(this.selectedStackingOrderId);
    this._originalFilteredDocTypes = this.filteredDocumentTypes;
    this.loadDocumentSigningReviewRequiredConfig();
    await this.initializeLoanDocsAndTasks();
    if (this.isTpoUser) this.userType = 'tpo';
    else {
      this.userType = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
        this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';
    }
  }

  private initializeLoanDocs = async () => {
    this.loadingLoanDocs = true;
    try {
      this._loanDocs = await firstValueFrom(
        this._loanDocsV2Service.getLoanDocs(
          this.currentApplication.applicationId,
          true,
        ),
      );
      this._loanDocs.forEach(d => {
        if (d.docFiles && d.docFiles.length) {
          const inactiveFiles = d.docFiles.filter(f => !f.active);
          if (inactiveFiles.length === d.docFiles.length) {
            d.documentTypeId = -1;
          }
        }
      });
      this.filteredLoanDocs = [...this._loanDocs];
      this.populateDocTypesForLoanDocsWeHave();
    } catch (e) {
      console.error(e);
      const message = e?.message
        || 'An error occurred while loading loan docs.';
      this._notifyService.showError(
        message,
        'Error',
      );
    } finally {
      this.loadingLoanDocs = false;
    }
  }

  private initializeLoanDocsAndTasks = async () => {
    this.loadingLoanDocs = true;
    try {
      this.loanDocTasks = await firstValueFrom(
        this._taskService.getTaskByLoan(this.currentApplication.applicationId),
      );
      await this.initializeLoanDocs();
    } catch (e) {
      console.error(e);
      const message = e?.message
        || 'An error occurred while loading loan docs.';
      this._notifyService.showError(
        message,
        'Error',
      );
    } finally {
      this.loadingLoanDocs = false;
    }
  }

  private loadDocumentSigningReviewRequiredConfig = () => {
    this._loanDocsV2Service.loadDocumentSigningReviewRequiredConfig().subscribe(result => {
      this.isDocumentSigningRequiredReview = result;
    })
  }

  private getInsertedBy = (id: string) => {
    const findInserter = this.usersAll.find(u => u.userCompanyGuid == id);
    return this._utilityService.getBorrowerFullName(findInserter);
  }
}
