import { Component, Injector, Input, OnInit } from '@angular/core';
import { BorrowerCalculatedStats, UrlaBorrower } from '../../models/urla-mortgage.model';
import { createIsRequiredFunction } from '../../urla-utils';
import { Income, TypeOfIncomeEnum } from 'src/app/models';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'qualifying-income',
  templateUrl: './qualifying-income.component.html',
  styleUrls: ['./qualifying-income.component.scss']
})
export class QualifyingIncomeComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  borrower: UrlaBorrower;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  protected isRequired: (fieldName: string) => boolean;

  constructor(private injector: Injector) {
    super(injector);
   }

  ngOnInit(): void {
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);
  }

  ngAfterViewInit(): void {
    if (!this.borrower.nonEmploymentIncomes) {
      this.borrower.nonEmploymentIncomes = [];
    }
    
    if (!this.borrower.nonEmploymentIncomes.length) {
      this.borrower.nonEmploymentIncomes.push(new Income());
      this.borrower.nonEmploymentIncomes[0].typeOfIncome = TypeOfIncomeEnum.Base;
      this.borrower.nonEmploymentIncomes[0].monthlyIncome = 0;
    }    

    if (!this.borrower.calculatedStats) {
      this.borrower.calculatedStats = new BorrowerCalculatedStats();
    }

    this.borrower.calculatedStats.totalMonthlyIncome = 
        this.borrower.nonEmploymentIncomes[0].monthlyIncome;
    
    this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
  }

  montlyIncomeChanged = () => {
    this.borrower.calculatedStats.totalMonthlyIncome = 
      this.borrower.nonEmploymentIncomes[0].monthlyIncome;

    this.applicationContextService.mortgageIncomeOrLiabilitiesChanged();
  }

}
