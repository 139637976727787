import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { Utils } from 'src/app/core/services/utils';
import { PrepaymentPenaltyDetail, PrepaymentPenaltyItem, PrepaymentPenaltyOptionType } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaFieldsConfigBoundComponent } from 'src/app/shared/components/urla-fields-config-bound.component';

@Component({
  selector: 'app-prepayment-penalty-details',
  templateUrl: './prepayment-penalty-details.component.html',
  styleUrls: ['./prepayment-penalty-details.component.scss']
})
export class PrepaymentPenaltyDetailsComponent extends UrlaFieldsConfigBoundComponent implements OnInit {

  @ViewChild('prepaymentPenaltyForm')
  prepaymentPenaltyForm: NgForm | undefined;

  @Input()
  set details(details: PrepaymentPenaltyDetail) {
    if (!details) {
      return;
    }

    if (!details.penaltyItems) {
      details.penaltyItems = [];
    }
    if (!details.prepaymentPenaltyPrincipalBalanceType) {
      details.prepaymentPenaltyPrincipalBalanceType = null;
    }
    this._details = cloneDeep(details);
    this.calculateAmounts();
  }

  get details(): PrepaymentPenaltyDetail {
    return this._details;
  }

  @Input()
  totalLoanAmount: number;

  @Input()
  hasSaveCancelButtons: boolean = true;

  @Input()
  readonly: boolean = false;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApproved: EventEmitter<any> = new EventEmitter<any>();

  hardPrepaymentPeriod: number = 0;
  principalBalanceTypes: EnumerationItem[] = [];
  optionTypes: EnumerationItem[] = [];

  private _details: PrepaymentPenaltyDetail = new PrepaymentPenaltyDetail();

  constructor(
    private readonly injector: Injector,
    private readonly _enumsService: EnumerationService
  ) {
    super(injector);
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.principalBalanceTypes = enums[Constants.mortgageEnumerations.prepaymentPenaltyPrincipalBalanceType] || [];
      this.optionTypes = enums[Constants.mortgageEnumerations.prepaymentPenaltyOptionType] || [];

      this.splitEnumNames(this.principalBalanceTypes);
      this.splitEnumNames(this.optionTypes);
    });
  }

  ngOnInit(): void {
  }

  onSaveClicked = () => {
    if (!this.prepaymentPenaltyForm) return;

    this.prepaymentPenaltyForm.form.markAllAsTouched();
    if (!this.prepaymentPenaltyForm.form.valid) return;

    this.details.penaltyItems.forEach(i => {
      i.prepaymentPenaltyTermMonths = i.prepaymentPenaltyTermMonths ? Number(i.prepaymentPenaltyTermMonths) : 0;
    })

    this.changesApproved.emit(this.details);
  }

  addItem = () => {
    this.details.penaltyItems.push(new PrepaymentPenaltyItem());
  }

  deleteItem = (index: number) => {
    this.details.penaltyItems.splice(index, 1);
    this.calculateAmounts();
  }

  calculateAmounts = () => {
    const items = this.details.penaltyItems;

    const firstItem = items[0];
    const hasValidPrepaymentPenalty = items.length > 0 && firstItem?.prepaymentPenaltyTermMonths && firstItem?.prepaymentPenaltyOption;

    this._details.prepaymentPenaltyMaximumAmount = hasValidPrepaymentPenalty
      ? (firstItem.prepaymentPenaltyPercent / 100) * this.totalLoanAmount
      : 0;

    this.hardPrepaymentPeriod = items.filter(p => p.prepaymentPenaltyOption == PrepaymentPenaltyOptionType.Hard).reduce(
      (acc, current) => acc + (Number(current.prepaymentPenaltyTermMonths) || 0),
      0,
    );

    this.details.prepaymentPenaltyExpirationMonthsCount = items.reduce((acc, current) => acc + (Number(current.prepaymentPenaltyTermMonths) || 0), 0);
  }

  private splitEnumNames = (array: EnumerationItem[]) => {
    array.forEach(e => {
      e.name = Utils.splitCamelCaseString(e.name);
    });
  }

}
