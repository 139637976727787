import { Component } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { BaseUrlaInputComponent } from '../base-urla-input.component';

@Component({
  selector: 'urla-datetime-input',
  templateUrl: 'urla-datetime-input.component.html',
  styleUrls: ['urla-datetime-input.component.scss'],
  providers: [MakeProvider(UrlaDateTimeInputComponent)],
  viewProviders: [formViewProvider]
})
export class UrlaDateTimeInputComponent extends BaseUrlaInputComponent {
  constructor() {
    super();
  }
}
