import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Address, VAAppraisalTypeEnum, VALoanProcedureTypeEnum, VATitleVestingTypeEnum } from '../../../../../models';
import { FormModelAdapter } from '../qa-financial-info/qa-fi-income/qa-fi-income-utils';
import { UrlaMortgage } from '../../../../urla/models/urla-mortgage.model';
import { LoanPurposeTypeEnum } from '../../../components/title-history/models/title-order.model';
import { addressToString, getBorrowerDisplayName } from './qa-va-utils';
import AusResult = QaVaInfoPlaceholderEnums.AusResult;
import PastCreditRecord = QaVaInfoPlaceholderEnums.PastCreditRecord;
import FinalLoanAction = QaVaInfoPlaceholderEnums.FinalLoanAction;
import Underwriter = QaVaInfoPlaceholderEnums.Underwriter;

type FormAddress = FormGroup<VaInfoForm>['controls']['disclosuresInfo']['controls']['nearestLivingRelatives']['controls'][number]['controls']['address'];

export interface VaInfoForm {
  incomeDetails: FormGroup<{
    generalInfo: FormGroup<{
      agencyCaseNumber: FormControl<string>;
      mortgageCreditCertificate: FormControl<string>;
      vaTitleVestingType: FormControl<VATitleVestingTypeEnum>;
      vaExistingLoanBalance: FormControl<number>;
      vaExistingReasonableAppraisedValue: FormControl<number>;
      vaOriginalLoanAmount: FormControl<number>;
      vaOriginalInterestRate: FormControl<number>;
      vaOriginalLoanTerm: FormControl<number>;
      vaExistingPrincipalAndInterest: FormControl<number>;
      vaCashOutRefinanceType: FormControl<string>;
      vaBenefitUsingBorrowerId: FormControl<number>;
      vaEntitlement: FormGroup<{
        isManual: FormControl<boolean>;
        amount: FormControl<number>;
      }>;
      sellerConcessions: FormControl<number>;
      caivridInfo: FormArray<FormGroup<{
        borrower: FormGroup<{
          id: FormControl<number>;
          name: FormControl<string>;
        }>;
        caivrid: FormControl<string>;
      }>>
      borrowersInfo: FormArray<FormGroup<{
        borrower: FormGroup<{
          id: FormControl<number>;
          name: FormControl<string>;
        }>;
        isVaFundingFeeExempt: FormControl<boolean>;
      }>>;
    }>;
    vaResidualInfo: FormGroup<{
      isManual: FormControl<boolean>;
      residualIncome: FormControl<number>;
      monthlyMaintenanceAndUtilities: FormGroup<{
        isOverridden: FormControl<boolean>;
        overrides: FormGroup<{
          maintenanceExpenses: FormControl<number>;
          utilitiesExpenses: FormControl<number>;
        }>;
        totalSquareFootage: FormControl<number>;
      }>;
      monthlyIncomeTaxes: FormArray<FormGroup<{
        taxId: FormControl<number>;
        name: FormControl<string>;
        amount: FormControl<number>;
      }>>;
    }>;
  }>;
  disclosuresInfo: FormGroup<{
    nearestLivingRelatives: FormArray<FormGroup<{
      borrower: FormGroup<{
        id: FormControl<number>;
        name: FormControl<string>;
      }>;
      firstName: FormControl<string>;
      lastName: FormControl<string>;
      relationship: FormControl<string>;
      phoneNumber: FormControl<string>;
      address: FormGroup<{
        summary: FormControl<string>;
        address1: FormControl<string>;
        address2: FormControl<string>;
        city: FormControl<string>;
        state: FormControl<string>;
        zipCode: FormControl<string>;
        county: FormControl<string>;
      }>;
    }>>;
    hudVaAddendum: FormGroup<{
      lenderOrMortgageIdCode: FormControl<string>;
      agentOrSponsorIdCode: FormControl<string>;
      purposeOfLoan: FormControl<LoanPurposeTypeEnum>;
      haveYouEverHadVAHomeLoan: FormControl<boolean>;
      ausResult: FormControl<AusResult>;
    }>;
    loanAnalysis: FormGroup<{
      pastCreditRecord: FormControl<PastCreditRecord>;
      meetVaCreditStandards: FormControl<boolean>;
      utilitiesIncluded: FormControl<boolean>;
      remarks: FormControl<string>;
      applicationRecommended: FormControl<boolean>;
      finalLoanAction: FormControl<FinalLoanAction>;
    }>;
    loanSummarySheet: FormGroup<{
      entitlementId: FormControl<string>;
      vaLoanProcedureType: FormControl<VALoanProcedureTypeEnum>;
      appraisalType: FormControl<VAAppraisalTypeEnum>;
      micrvNumber: FormControl<string>;
      lenderSarIdNumber: FormControl<string>;
      grossLivingArea: FormControl<number>;
      dateSarIssuedNotificationOfValue: FormControl<Date>;
      totalRoomCount: FormControl<number>;
      bathCount: FormControl<number>;
      bedroomCount: FormControl<number>;
      processedUnderLapp: FormControl<boolean>;
    }>;
    verificationOfVaBenefits: FormGroup<{
      indebtednessCertification: FormControl<boolean>;
    }>;
    certificateEligibilityRequest: FormGroup<{
      vaClaimNumber: FormControl<string>;
      purpleHeartRecipient: FormControl<boolean>;
      preDischargeClaimPending: FormControl<boolean>;
    }>;
    rclDisbursement: FormGroup<{
      underwriter: FormControl<Underwriter>;
      occupancy: FormControl<QaVaInfoPlaceholderEnums.Occupancy>;
    }>;
  }>;
}

export interface VaInfoFormAdapter {
  incomeDetails: {
    generalInfo: {
      vaEntitlement: {
        amount: FormModelAdapter<number>;
      };
      sellerConcessions: FormModelAdapter<number>;
      vaExistingLoanBalance: FormModelAdapter<number>;
      vaExistingReasonableAppraisedValue: FormModelAdapter<number>;
      vaOriginalLoanAmount: FormModelAdapter<number>;
      vaOriginalInterestRate: FormModelAdapter<number>;
      vaOriginalLoanTerm: FormModelAdapter<number>;
      vaExistingPrincipalAndInterest: FormModelAdapter<number>;
      vaCashOutRefinanceType: FormModelAdapter<string>;
      vaBenefitUsingBorrowerId: FormModelAdapter<number>;
    };
    vaResidualInfo: {
      residualIncome: FormModelAdapter<number>;
      monthlyMaintenanceAndUtilities: {
        overrides: {
          maintenanceExpenses: FormModelAdapter<number>;
          utilitiesExpenses: FormModelAdapter<number>;
        };
        totalSquareFootage: FormModelAdapter<number>;
      };
      monthlyIncomeTaxes: readonly {
        amount: FormModelAdapter<number>;
      }[];
    };
  };
  disclosuresInfo: {
    loanSummarySheet: {
      grossLivingArea: FormModelAdapter<number>;
      dateSarIssuedNotificationOfValue: FormModelAdapter<Date>;
      totalRoomCount: FormModelAdapter<number>;
      bathCount: FormModelAdapter<number>;
      bedroomCount: FormModelAdapter<number>;
    };
  }
}

export function createVaInfoForm(builder: FormBuilder): (mortgage: UrlaMortgage) => FormGroup<VaInfoForm> {
  return (mortgage: UrlaMortgage): FormGroup<VaInfoForm> => {

    if (!mortgage.governmentLoanDetail.vaBenefitUsingBorrowerId) {
      if (mortgage.borrowers.length == 1) {
        mortgage.governmentLoanDetail.vaBenefitUsingBorrowerId = mortgage.borrowers[0].borrowerId;
      }
      else if (mortgage.borrowers.find(bor => bor.isFirstUseOfVaBenefit != null || bor.isVaFundingFeeExempt != null || bor.branchOfService != null || bor.militaryStatus != null)) {
        mortgage.governmentLoanDetail.vaBenefitUsingBorrowerId = mortgage.borrowers.find(bor => bor.isFirstUseOfVaBenefit != null || bor.isVaFundingFeeExempt != null || bor.branchOfService != null || bor.militaryStatus != null).borrowerId;
      }
    }

    const governmentLoanDetail = mortgage.governmentLoanDetail;
    const borrowers = mortgage.borrowers;

    return builder.group({
      incomeDetails: builder.group({
        generalInfo: builder.group({
          // FIXME: Missing from UrlaMortgage (There is a property in RelatedMortgage called agencyCaseIdentifier)
          agencyCaseNumber: builder.control(''),
          // FIXME: Missing from UrlaMortgage
          mortgageCreditCertificate: builder.control(''),
          vaTitleVestingType: builder.control(governmentLoanDetail.vaTitleVestingType ?? null),
          vaExistingLoanBalance: builder.control(governmentLoanDetail.vaExistingLoanBalance ?? 0),
          vaExistingReasonableAppraisedValue: builder.control(governmentLoanDetail.vaExistingReasonableAppraisedValue ?? 0),
          vaOriginalLoanAmount: builder.control(governmentLoanDetail.vaOriginalLoanAmount ?? 0),
          vaOriginalInterestRate: builder.control(governmentLoanDetail.vaOriginalInterestRate ?? 0),
          vaOriginalLoanTerm: builder.control(governmentLoanDetail.vaOriginalLoanTerm ?? 0),
          vaExistingPrincipalAndInterest: builder.control(governmentLoanDetail.vaExistingPrincipalAndInterest ?? 0),
          vaCashOutRefinanceType: builder.control(governmentLoanDetail.vaCashOutRefinanceType ?? null),
          vaBenefitUsingBorrowerId: builder.control(governmentLoanDetail.vaBenefitUsingBorrowerId ?? 0),
          vaEntitlement: builder.group({
            // FIXME: How to determine if it's manual initially?
            isManual: builder.control(governmentLoanDetail.vaEntitlementAmount != null),
            amount: builder.control(governmentLoanDetail.vaEntitlementAmount ?? 0),
          }),
          // FIXME: This is a part of LoanFee. How to use here?
          sellerConcessions: builder.control(0),
          // FIXME: Missing from UrlaMortgage
          caivridInfo: builder.array(
            borrowers.map((e) => builder.group({
              borrower: builder.group({
                id: builder.control(e.borrowerId),
                name: builder.control(getBorrowerDisplayName(e)),
              }),
              caivrid: builder.control(''),
            })),
          ),
          borrowersInfo: builder.array(
            borrowers.map((e) => builder.group({
                borrower: builder.group({
                  id: builder.control(e.borrowerId),
                  name: builder.control(getBorrowerDisplayName(e)),
                }),
                isVaFundingFeeExempt: builder.control(e.isVaFundingFeeExempt),
              })
            )
          )
        }),
        vaResidualInfo: builder.group({
          // FIXME: How to determine if it's manual initially?
          isManual: builder.control(governmentLoanDetail.vaResidualIncomeAmount != null),
          residualIncome: builder.control(governmentLoanDetail.vaResidualIncomeAmount ?? 0),
          monthlyMaintenanceAndUtilities: builder.group({
            // FIXME: How to determine if it's overridden initially?
            isOverridden: builder.control(false),
            overrides: builder.group({
              maintenanceExpenses: builder.control(governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount ?? 0),
              utilitiesExpenses: builder.control(governmentLoanDetail.vaUtilityExpenseMonthlyAmount ?? 0),
            }),
            // FIXME: Missing from UrlaMortgage
            totalSquareFootage: builder.control(0),
          }),
          // FIXME: Missing from UrlaMortgage
          monthlyIncomeTaxes: builder.array(
            [
              {
                taxId: builder.control(0),
                name: builder.control('Federal Income Tax (from pay stubs)'),
                amount: builder.control(0),
              },
              {
                taxId: builder.control(0),
                name: builder.control('State Income Tax (from pay stubs)'),
                amount: builder.control(0),
              },
              {
                taxId: builder.control(0),
                name: builder.control('Social Security (salaried or SE\'d)'),
                amount: builder.control(0),
              },
              {
                taxId: builder.control(0),
                name: builder.control('Local Tax (salary or SE\'d)'),
                amount: builder.control(0),
              },
            ].map((e) => builder.group(e)),
          ),
        }),
      }),
      disclosuresInfo: builder.group({
        nearestLivingRelatives: builder.array(
          borrowers.map((e) => builder.group({
              borrower: builder.group({
                id: builder.control(e.borrowerId),
                name: builder.control(getBorrowerDisplayName(e)),
              }),
              firstName: builder.control(e.nearestLivingRelativeName),
              // FIXME: Missing from MortgageBorrower
              lastName: builder.control(''),
              relationship:
                builder.control(e.nearestLivingRelativeRelationshipToBorrower),
              phoneNumber: builder.control(e.nearestLivingRelativePhone),
            address: (() => {
              const address: Partial<Address> = {
                address1: e.nearestLivingRelativeAddress1,
                address2: e.nearestLivingRelativeAddress2,
                city: e.nearestLivingRelativeCity,
                state: e.nearestLivingRelativeState,
                zipCode: e.nearestLivingRelativeZipCode,
                // FIXME: Missing from MortgageBorrower
                county: '',
              };

              return builder.group({
                summary: addressToString(address),
                ...address,
              }) as FormAddress;
            })(),
            }),
          )),
        hudVaAddendum: builder.group({
          lenderOrMortgageIdCode: builder.control(''),
          agentOrSponsorIdCode: builder.control(''),
          // FIXME: This is not the same as mortgage.subjectProperty.purposeOfLoan
          purposeOfLoan: builder.control(null),
          haveYouEverHadVAHomeLoan: builder.control(false),
          ausResult: builder.control(null),
        }),
        loanAnalysis: builder.group({
          pastCreditRecord: builder.control(null),
          meetVaCreditStandards: builder.control(false),
          utilitiesIncluded: builder.control(false),
          remarks: builder.control(''),
          applicationRecommended: builder.control(false),
          finalLoanAction: builder.control(null),
        }),
        loanSummarySheet: builder.group({
          entitlementId: builder.control(''),
          vaLoanProcedureType: builder.control(null),
          appraisalType: builder.control(null),
          micrvNumber: builder.control(''),
          lenderSarIdNumber: builder.control(''),
          grossLivingArea: builder.control(0),
          dateSarIssuedNotificationOfValue: builder.control(null),
          totalRoomCount: builder.control(0),
          bathCount: builder.control(0),
          bedroomCount: builder.control(0),
          processedUnderLapp: builder.control(false),
        }),
        verificationOfVaBenefits: builder.group({
          indebtednessCertification: builder.control(false),
        }),
        certificateEligibilityRequest: builder.group({
          vaClaimNumber: builder.control(''),
          purpleHeartRecipient: builder.control(false),
          preDischargeClaimPending: builder.control(false),
        }),
        rclDisbursement: builder.group({
          underwriter: builder.control(null),
          occupancy: builder.control(QaVaInfoPlaceholderEnums.Occupancy.OccupyAsHome),
        }),
      }),
    });
  };
}

export function createVaInfoFormAdapter(form: FormGroup<VaInfoForm>): VaInfoFormAdapter {
  const incomeDetails = form.controls.incomeDetails;
  const monthlyMaintenanceAndUtilities = incomeDetails.controls.vaResidualInfo.controls.monthlyMaintenanceAndUtilities;
  const monthlyMaintenanceAndUtilitiesOverrides = monthlyMaintenanceAndUtilities.controls.overrides;

  return {
    incomeDetails: {
      generalInfo: {
        vaEntitlement: {
          amount: new FormModelAdapter(incomeDetails.controls.generalInfo.controls.vaEntitlement.controls.amount),
        },
        sellerConcessions: new FormModelAdapter(incomeDetails.controls.generalInfo.controls.sellerConcessions),
        vaExistingLoanBalance: new FormModelAdapter(incomeDetails.controls.generalInfo.controls.vaExistingLoanBalance),
        vaExistingReasonableAppraisedValue: new FormModelAdapter(incomeDetails.controls.generalInfo.controls.vaExistingReasonableAppraisedValue),
        vaOriginalLoanAmount: new FormModelAdapter(incomeDetails.controls.generalInfo.controls.vaOriginalLoanAmount),
        vaOriginalInterestRate: new FormModelAdapter(incomeDetails.controls.generalInfo.controls.vaOriginalInterestRate),
        vaOriginalLoanTerm: new FormModelAdapter(incomeDetails.controls.generalInfo.controls.vaOriginalLoanTerm),
        vaExistingPrincipalAndInterest: new FormModelAdapter(incomeDetails.controls.generalInfo.controls.vaExistingPrincipalAndInterest),
        vaCashOutRefinanceType: new FormModelAdapter(incomeDetails.controls.generalInfo.controls.vaCashOutRefinanceType),
        vaBenefitUsingBorrowerId: new FormModelAdapter(incomeDetails.controls.generalInfo.controls.vaBenefitUsingBorrowerId),
      },
      vaResidualInfo: {
        residualIncome: new FormModelAdapter(incomeDetails.controls.vaResidualInfo.controls.residualIncome),
        monthlyMaintenanceAndUtilities: {
          overrides: {
            maintenanceExpenses: new FormModelAdapter(monthlyMaintenanceAndUtilitiesOverrides.controls.maintenanceExpenses),
            utilitiesExpenses: new FormModelAdapter(monthlyMaintenanceAndUtilitiesOverrides.controls.utilitiesExpenses),
          },
          totalSquareFootage: new FormModelAdapter(monthlyMaintenanceAndUtilities.controls.totalSquareFootage),
        },
        monthlyIncomeTaxes: incomeDetails.controls.vaResidualInfo.controls.monthlyIncomeTaxes.controls
          .map((e) => ({
            amount: new FormModelAdapter(e.controls.amount),
          })),
      },
    },
    disclosuresInfo: {
      loanSummarySheet: {
        grossLivingArea: new FormModelAdapter(form.controls.disclosuresInfo.controls.loanSummarySheet.controls.grossLivingArea),
        dateSarIssuedNotificationOfValue: new FormModelAdapter(form.controls.disclosuresInfo.controls.loanSummarySheet.controls.dateSarIssuedNotificationOfValue),
        totalRoomCount: new FormModelAdapter(form.controls.disclosuresInfo.controls.loanSummarySheet.controls.totalRoomCount),
        bathCount: new FormModelAdapter(form.controls.disclosuresInfo.controls.loanSummarySheet.controls.bathCount),
        bedroomCount: new FormModelAdapter(form.controls.disclosuresInfo.controls.loanSummarySheet.controls.bedroomCount),
      },
    }
  };
}

/**
 * Applies the values from the form to the mortgage.
 * @param {FormGroup<VaInfoForm>} form The form to apply.
 * @returns {(mortgage: UrlaMortgage) => void} A function that applies the form to the mortgage.
 */
export function applyVaInfoForm(form: FormGroup<VaInfoForm>): (mortgage: UrlaMortgage) => void {
  return function(mortgage: UrlaMortgage): void {
    const incomeDetails = form.controls.incomeDetails;
    const governmentLoanDetail = mortgage.governmentLoanDetail;

    const generalInfo = incomeDetails.controls.generalInfo;
    const vaEntitlement = generalInfo.controls.vaEntitlement;
    const monthlyMaintenanceAndUtilities = incomeDetails.controls.vaResidualInfo.controls.monthlyMaintenanceAndUtilities;
    const monthlyMaintenanceAndUtilitiesOverrides = monthlyMaintenanceAndUtilities.controls.overrides;

    governmentLoanDetail.vaTitleVestingType = generalInfo.controls.vaTitleVestingType.value;
    governmentLoanDetail.vaEntitlementAmount = vaEntitlement.controls.isManual.value
      ? vaEntitlement.controls.amount.value
      : null;
    governmentLoanDetail.vaExistingLoanBalance = generalInfo.controls.vaExistingLoanBalance.value;
    governmentLoanDetail.vaExistingReasonableAppraisedValue = generalInfo.controls.vaExistingReasonableAppraisedValue.value;
    governmentLoanDetail.vaOriginalLoanAmount = generalInfo.controls.vaOriginalLoanAmount.value;
    governmentLoanDetail.vaOriginalInterestRate = generalInfo.controls.vaOriginalInterestRate.value;
    governmentLoanDetail.vaOriginalLoanTerm = generalInfo.controls.vaOriginalLoanTerm.value;
    governmentLoanDetail.vaExistingPrincipalAndInterest = generalInfo.controls.vaExistingPrincipalAndInterest.value;
    governmentLoanDetail.vaCashOutRefinanceType = generalInfo.controls.vaCashOutRefinanceType.value;
    governmentLoanDetail.vaBenefitUsingBorrowerId = generalInfo.controls.vaBenefitUsingBorrowerId.value;
    governmentLoanDetail.vaResidualIncomeAmount = incomeDetails.controls.vaResidualInfo.controls.isManual.value
      ? incomeDetails.controls.vaResidualInfo.controls.residualIncome.value
      : null;
    governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount = monthlyMaintenanceAndUtilities.controls.isOverridden.value
      ? monthlyMaintenanceAndUtilitiesOverrides.controls.maintenanceExpenses.value
      : null;
    governmentLoanDetail.vaUtilityExpenseMonthlyAmount = monthlyMaintenanceAndUtilities.controls.isOverridden.value
      ? monthlyMaintenanceAndUtilitiesOverrides.controls.utilitiesExpenses.value
      : null;

    const formGroupByBorrowerId = new Map(
      generalInfo.controls.borrowersInfo.controls
        .map((e) => [e.controls.borrower.controls.id.value, e]),
    );

    mortgage.borrowers.forEach((borrower) => {
      const formGroup = formGroupByBorrowerId.get(borrower.borrowerId);
      const controls = formGroup.controls;
      borrower.isVaFundingFeeExempt = controls.isVaFundingFeeExempt.value;
    });
  
    (function applyNearestLivingRelatives() {
      const formGroupByBorrowerId = new Map(
        form.controls.disclosuresInfo.controls.nearestLivingRelatives.controls
          .map((e) => [e.controls.borrower.controls.id.value, e]),
      );
      mortgage.borrowers.forEach((borrower) => {
        const formGroup = formGroupByBorrowerId.get(borrower.borrowerId);
        const controls = formGroup.controls;
        borrower.nearestLivingRelativeName = controls.firstName.value;
        borrower.nearestLivingRelativeRelationshipToBorrower = controls.relationship.value;
        borrower.nearestLivingRelativePhone = controls.phoneNumber.value;
        borrower.isVaFundingFeeExempt

        const addressControls = controls.address.controls;
        borrower.nearestLivingRelativeAddress1 = addressControls.address1.value;
        borrower.nearestLivingRelativeAddress2 = addressControls.address2.value;
        borrower.nearestLivingRelativeCity = addressControls.city.value;
        borrower.nearestLivingRelativeState = addressControls.state.value;
        borrower.nearestLivingRelativeZipCode = addressControls.zipCode.value;
        // FIXME: Missing from MortgageBorrower
        //  borrower.nearestLivingRelativeCounty = addressControls.county.value;
      });
    })();
  };
}

// FIXME: Replace with the real enum values
export namespace QaVaInfoPlaceholderEnums {
  export enum AusResult {
    Approved = 'Approved',
    UnderReview = 'UnderReview',
    Denied = 'Denied',
  }

  export enum PastCreditRecord {
    Excellent = 'Excellent',
    Good = 'Good',
    Fair = 'Fair',
    Poor = 'Poor',
  }

  export enum FinalLoanAction {
    Approved = 'Approved',
    Denied = 'Denied',
    Pending = 'Pending',
  }

  export enum Underwriter {
    UnderwriterA = 'UnderwriterA',
    UnderwriterB = 'UnderwriterB',
    UnderwriterC = 'UnderwriterC',
  }

  export enum Occupancy {
    OccupyAsHome = 'OccupyAsHome',
    SpouseActiveDuty = 'SpouseActiveDuty',
    DependentChildOccupies = 'DependentChildOccupies',
    PreviouslyOccupiedAsHome = 'PreviouslyOccupiedAsHome',
    SpousePreviouslyOccupied = 'SpousePreviouslyOccupied',
    DependentChildPreviouslyOccupied = 'DependentChildPreviouslyOccupied',
  }
}
