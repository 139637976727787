import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Address } from '../../../../../../models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { EnumerationItem } from '../../../../../../models/simple-enum-item.model';
import { EnumerationService } from '../../../../../../services/enumeration-service';
import { NotificationService } from '../../../../../../services/notification.service';
import { DiffChecker } from '../../../../../../../utils/diff-checker';
import { AddressLookupService } from '../../../../../../services/address-lookup.service';
import { FormModelAdapter } from '../../qa-financial-info/qa-fi-income/qa-fi-income-utils';
import { ZipCodeLookupResult } from '../../../../../../models/zipcode-lookup-result.model';

@Component({
  selector: 'qa-address-editor',
  templateUrl: './qa-address-editor.component.html',
  styleUrls: ['./qa-address-editor.component.scss'],
})
export class QaAddressEditorComponent implements OnChanges, OnInit, OnDestroy {
  @Input() address?: Address;
  @Output() addressChange = new EventEmitter<Address>();

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<Address>();

  protected formGroup: FormGroup<AddressForm>;
  protected formAdapter: AddressFormAdapter;

  protected stateOptions: EnumerationItem[] = [];

  private _debouncing: boolean = false;

  private _formChangeSubscription: Subscription | null = null;

  protected get canSave(): boolean {
    return !this._debouncing;
  }

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _enumerationService: EnumerationService,
    private readonly _notificationService: NotificationService,
    private readonly _addressLookupService: AddressLookupService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const addressChange = changes['address'];
    if (addressChange) {
      const address = addressChange.currentValue;
      if (this.isFormGroupAddress(address)) {
        return;
      }
      this.resetFormGroup(address);
    }
  }

  ngOnInit(): void {
    if (this.address == null) {
      this.resetFormGroup();
    }

    this.stateOptions = this._enumerationService.states;
  }

  ngOnDestroy() {
    this.addressChange.complete();

    this._formChangeSubscription?.unsubscribe();
  }

  private isFormGroupAddress(address: Address): boolean {
    const formAddress = this.formGroup?.value;
    if (formAddress == null) {
      return false;
    }

    const diffChecker = new DiffChecker(
      formAddress,
      address,
      'address',
    );
    return diffChecker.calculateDiff() == null;
  }

  private resetFormGroup(address?: Address): void {
    address ??= address ?? new Address();
    this.formGroup = createAddressForm(this._formBuilder)(address);
    this.formAdapter = new AddressFormAdapter(this.formGroup);
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges(): void {
    this._formChangeSubscription?.unsubscribe();
    this._formChangeSubscription = this.formGroup.valueChanges.pipe(
      tap(() => this._debouncing = true),
      debounceTime(100),
      distinctUntilChanged(),
    ).subscribe({
      next: (value) => {
        const address = Object.assign(new Address(), value);
        this.addressChange.emit(address);
        this._debouncing = false;
      },
      complete: () => {
        this._debouncing = false;
        this._formChangeSubscription = null;
      }
    });
  }

  protected onCancel(): void {
    this.cancel.emit();
  }

  protected onSave(): void {
    if (!this.canSave) {
      throw new Error('Must be able to save.');
    }

    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      this._notificationService.showError(
        'Please correct the errors on the form.',
        'Error',
      );

      return;
    }

    const address = Object.assign(new Address(), this.formGroup.value);
    this.save.emit(address);
  }

  protected isInvalid(control: FormControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  protected onChangeAddress(address: Partial<Address>): void {
    this.formGroup.patchValue(address);
  }

  protected onChangeZipCodeLookup(result: ZipCodeLookupResult): void {
    const address = this._addressLookupService.parseZipCodeLookup(result);
    if (address.zipCode == null) {
      return;
    }

    this.formGroup.patchValue(address);
  }
}

interface AddressForm {
  readonly address1: FormControl<string>;
  readonly address2: FormControl<string>;
  readonly city: FormControl<string>;
  readonly state: FormControl<string>;
  readonly zipCode: FormControl<string>;
  readonly county: FormControl<string>;
}

class AddressFormAdapter {
  readonly zipCode: FormModelAdapter<string>

  constructor(formGroup: FormGroup<AddressForm>) {
    this.zipCode = new FormModelAdapter(formGroup.controls.zipCode);
  }
}

function createAddressForm(builder: FormBuilder): (address: Address) => FormGroup<AddressForm> {
  return (address: Address) => builder.group<AddressForm>({
    address1: builder.control(address.address1, [Validators.required]),
    address2: builder.control(address.address2),
    city: builder.control(address.city, [Validators.required]),
    state: builder.control(address.state),
    zipCode: builder.control(address.zipCode),
    county: builder.control(address.county),
  });
}
