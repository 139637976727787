import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'credit-credentials-configuration-dialog',
	templateUrl: 'credit-credentials-configuration-dialog.component.html',
})
export class CreditCredentialsConfigurationDialogComponent implements OnInit {

	constructor(public activeModal: NgbActiveModal) { }

	ngOnInit() {

	}

	onCredentialsSaved = () => {
		this.activeModal.dismiss();
	}
}
