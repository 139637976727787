<div class="grid-layout has-border" *ngIf="mortgage">
    <div class="grid-header mb-1">
        <div class="grid-header-left">
            <div>2a. Assets - Bank/Retirement</div>
        </div>
        <div class="grid-header-right">
            <span class="me-2" [ngClass]="{'text-success': isExceedFtc, 'text-danger': !isExceedFtc}">
              <i class="fa" [ngClass]="{'fa-check': isExceedFtc, 'fa-times-circle': !isExceedFtc}"></i>
              {{ isExceedFtc ? 'Assets exceed FTC' : 'Insufficient Assets' }}
            </span>
            <a class="btn btn-sm btn-soft-primary me-2" role="button" *ngIf="!isReadOnly && !inEditMode && systemLevel?.digitalAssetVerificationEnabled?.value"
                [routerLink]="['/' + (isTpo ? 'tpo/app-details/' : 'admin/app-details/') + applicationId + '/digital-asset-verification']">
                Digital Verify
            </a>
            <a class="btn btn-sm btn-soft-primary me-2" role="button" *ngIf="!isReadOnly && !thereIsAtLeastOneInvalidLineItem && !inEditMode && !isNewAsset" (click)="addAsset();">
                <i class="fas fa-plus me-2"></i>Add Asset
            </a>
        </div>
    </div>
    <div class="row mb-2">
        <div class="table-responsive" style="border-bottom: none;">
            <table class="table mb-0" *ngIf="assets.length > 0">
                <thead class="table-light">
                    <tr>
                        <td class="col-md-2 col-sm-2 col-xs-2">Account Owner</td>
                        <td class="col-md-2 col-sm-2 col-xs-2">Account Type</td>
                        <td class="col-md-2 col-sm-2 col-xs-2">Institution</td>
                        <td class="col-md-2 col-sm-2 col-xs-2">Account #</td>
                        <td class="col-md-2 col-sm-2 col-xs-2 ">Cash/Market Value</td>
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center">Digital (VOA)/Manual</td>
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let asset of assets; let index = index">
                        <td class="col-md-2 col-sm-2 col-xs-2">
                            <ng-select2 [hidden]="index !== editingIx" class="mb-3 select2 select2-multiple" name="owningBorrowerIds{{asset.assetId}}"
                                [(ngModel)]="asset.owningBorrowerIds" [options]="optionsMultipleSelect"
                                (ngModelChange)="onAccountOwnersChanged(asset)"
                                [data]="possibleAccountOwners" required>
                            </ng-select2>
                            <div class="owningBorrowerIds-readonly" *ngIf="index !== editingIx"
                                [ngClass]="{ 'ng-invalid-requested no-border' : asset.owningBorrowerIds.length === 0 }"
                                [ngStyle]="{height: asset.owningBorrowerIds.length === 0 ? '22px' : 'auto'}">
                                <span *ngFor="let owner of asset['owners']">{{owner.name}}<br></span>
                            </div>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2">
                            <urla-dropdown [(ngModel)]="asset.assetType" [options]="assetTypes" name="accountType{{asset.assetId}}"
                              [showBorder]="index === editingIx" [readonly]="index !== editingIx"
                              [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.assetType'"
                              [required]="urlaFieldsConfig['mortgage.asset.assetType']?.required == 'required' ||
                              urlaFieldsConfig['mortgage.asset.assetType']?.required == 'requested'">
                            </urla-dropdown>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2" >
                            <urla-text-input name="depository{{asset.assetId}}" [(ngModel)]="asset.depository" [readonly]="index !== editingIx"
                              [showBorder]="index === editingIx" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.depository'"
                              [required]="urlaFieldsConfig['mortgage.asset.depository']?.required == 'required' ||
                              urlaFieldsConfig['mortgage.asset.depository']?.required == 'requested'">
                            </urla-text-input>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2">
                            <urla-text-input name="accountNumber{{asset.assetId}}" [(ngModel)]="asset.accountNumber" [readonly]="index !== editingIx"
                                [showBorder]="index === editingIx" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.accountNumber'"
                                [required]="urlaFieldsConfig['mortgage.asset.accountNumber']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.asset.accountNumber']?.required == 'requested'">
                            </urla-text-input>
                        </td>
                        <td class="col-md-2 col-sm-2 col-xs-2 ">
                            <urla-currency-input name="cashMarketValue{{asset.assetId}}" [(ngModel)]="asset.cashMarketValue" (ngModelChange)="calculateSubTotal()"
                                [readonly]="index !== editingIx" [showBorder]="index === editingIx" [isOmitted]="asset['isOmitted']"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.cashMarketValue'"
                                [required]="urlaFieldsConfig['mortgage.asset.cashMarketValue']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.asset.cashMarketValue']?.required == 'requested'">
                            </urla-currency-input>
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1 text-center">
                                <span>{{asset.assetInsertionType == 'DigitalVOA' ? 'D' : 'M'}}</span>
                        </td>
                        <td class="col-md-1 col-sm-1 col-xs-1">
                            <div class="action" *ngIf="!isReadOnly">
                                <a title="Edit Asset" href="javascript: void(0);" *ngIf="index !== editingIx && !thereIsAtLeastOneInvalidLineItem && !isNewAsset" (click)="editAsset(index, asset)"><i class="fa fa-pencil-alt"></i></a>
                                <a title="Confirm Asset Changes" href="javascript: void(0);" *ngIf="index === editingIx && asset.owningBorrowerIds && asset.owningBorrowerIds.length"
                                  (click)="editingIx = -1; isNewAsset = false;"><i class="fa fa-check text-success"></i></a>
                                <a title="Cancel Asset Changes" href="javascript: void(0);" *ngIf="index === editingIx"
                                  (click)="cancelEdit()"><i class="fa fa-times text-success"></i></a>

                                <a title="Omit Asset from Calculations" href="javascript: void(0);" *ngIf="asset.isDeposited" (click)="omitAsset(asset, true)"><i class="fa fa-ban"></i></a>
                                <a title="Include Asset in Calculations" href="javascript: void(0);" *ngIf="!asset.isDeposited" (click)="omitAsset(asset, false)"><i class="fa fa-ban red-icon"></i></a>

                                <a title="Delete Asset" href="javascript: void(0);" (click)="deleteAsset(index)" *ngIf="!isNewAsset"><i class="fa fa-trash-alt"></i></a>
                                <a title="View Details" href="javascript: void(0);" (click)="onShowAssetDetailsClicked(asset)"><i class="fas fa-eye"></i></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-end">Sub-Total </td>
                        <td class="col-md-2 col-sm-2 col-xs-2"><strong>{{subTotal | currency}}</strong></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<drawer #assetDetailsDrawer [name]="'assetDetailsDrawer'"
  [options]="assetDetailsDrawerOptions">
</drawer>
