import { Component, Input, OnInit } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { UrlaBorrower } from '../../models/urla-mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { BorrowerCounselingEvent } from 'src/app/models/mortgage.model';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'urla-borrower-homeownership-education',
  templateUrl: './urla-borrower-homeownership-education.component.html',
  styleUrls: ['./urla-borrower-homeownership-education.component.scss'],
  viewProviders: [formViewProvider]
})
export class UrlaBorrowerHomeownershipEducationComponent implements OnInit {

  @Input()
  borrower: UrlaBorrower;

  @Input()
  isReadOnly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  yesNoOptions: EnumerationItem[] = [];
  formatTypes: EnumerationItem[] = [];

  hasHomebuyerEducation: boolean = false;
  hasCounseling: boolean = false;

  constructor(private readonly _enumService: EnumerationService) { }

  ngOnInit() {
    this.yesNoOptions = this._enumService.getYesNoEnumItems();
    this.formatTypes = this._enumService.borrowerCounselingFormatType;

    if (this.borrower.counselingEvents && this.borrower.counselingEvents.length > 0) {
      this.hasHomebuyerEducation = this.borrower.counselingEvents.findIndex(ev => ev.counselingType == "Education") > -1;
      this.hasCounseling = this.borrower.counselingEvents.findIndex(ev => ev.counselingType == "Counseling") > -1;
    }
  }

  onBorrowerCounselingEventChanged = (counselingType: string): void => {
    const hasEventForType = this.borrower.counselingEvents?.some(ev => ev.counselingType == counselingType);
    if (counselingType === 'Education') {
      if (this.hasHomebuyerEducation && !hasEventForType) {
        this.onAddNewCounselingEventClicked(counselingType);
      } else if (!this.hasHomebuyerEducation) {
        this.borrower.counselingEvents = this.borrower.counselingEvents.filter(ev => ev.counselingType != counselingType)
      }
    } else if (counselingType === 'Counseling') {
      if (this.hasCounseling && !hasEventForType) {
        this.onAddNewCounselingEventClicked(counselingType);
      } else if (!this.hasCounseling) {
        this.borrower.counselingEvents = this.borrower.counselingEvents.filter(ev => ev.counselingType != counselingType)
      }
    }
  }

  onAddNewCounselingEventClicked = (counselingType: string): void => {
    let newEvent = new BorrowerCounselingEvent();
    newEvent.borrowerId = this.borrower.borrowerId;
    newEvent.counselingType = counselingType;
    newEvent.borrowerCounselingEventId = Utils.getUniqueId();
    this.borrower.counselingEvents.push(newEvent);
  }

  onDelete = (e: BorrowerCounselingEvent) => {
    this.borrower.counselingEvents.splice(this.borrower.counselingEvents.findIndex(ev => ev.borrowerCounselingEventId == e.borrowerCounselingEventId), 1);
  }
}
