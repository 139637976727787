import { Component, Input, OnInit } from '@angular/core';
import { UrlaRequiredState } from '../../../services/utility.service';
import { BorrowerRelationshipTitleType, MortgageBorrower, PropertyTitleType } from '../../../../../models';
import { createCleanUpOtherDescriptionFunction, createIsRequiredFunction } from '../../../urla-utils';
import { EnumerationItem } from '../../../../../models/simple-enum-item.model';
import { EnumerationService } from '../../../../../services/enumeration-service';
import { Subscription } from 'rxjs';
import { Constants } from '../../../../../services/constants';
import { splitCamelCaseAndCapitals, toProperCase } from '../../../../../core/services/string-utils';
import { MortgageFieldConfig } from '../../../models/urla-fields-config.model';

@Component({
  selector: 'title-information-borrower',
  templateUrl: './title-information-borrower.component.html',
  styleUrls: ['./title-information-borrower.component.scss'],
})
export class TitleInformationBorrowerComponent implements OnInit {
  @Input() borrower: MortgageBorrower;

  @Input() isReadOnly: boolean;

  @Input() inEditMode: boolean = false;

  @Input() urlaFieldsConfig: Record<string, MortgageFieldConfig>;

  protected borrowerRelationshipTitleTypeOptions: EnumerationItem[] = [];
  protected signingRoleOptions: EnumerationItem[] = [];
  protected yesNoOptions: EnumerationItem[] = [];

  protected onBorrowerRelationshipTitleTypeChange: () => void;

  private _initEnumerationItemsSubscription: Subscription | null = null;

  protected isRequired: (fieldName: string) => boolean;

  protected readonly PropertyTitleType = PropertyTitleType;
  protected readonly BorrowerRelationshipTitleType = BorrowerRelationshipTitleType;

  constructor(
    private readonly _enumerationService: EnumerationService,
  ) {
  }

  ngOnInit(): void {
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);
    this.yesNoOptions = this._enumerationService.getYesNoEnumItems();
    this.initEnumerationItems();
    this.initCleanUpDependentFieldFunctions();
  }

  private initEnumerationItems(): void {
    this._initEnumerationItemsSubscription?.unsubscribe();

    this._initEnumerationItemsSubscription = this._enumerationService
      .getMortgageEnumerations().subscribe(enums => {
        const getEnumItem = createGetEnumerationItemsFunction(enums);

        this.borrowerRelationshipTitleTypeOptions =
          getEnumItem(Constants.mortgageEnumerations
            .borrowerRelationshipTitleType);
        this.signingRoleOptions =
          getEnumItem(Constants.mortgageEnumerations.signingRole);
      });
    this._initEnumerationItemsSubscription.add(() => {
      this._initEnumerationItemsSubscription = null;
    });
  }

  private initCleanUpDependentFieldFunctions(): void {
    this.onBorrowerRelationshipTitleTypeChange =
      createCleanUpOtherDescriptionFunction({
        sourcePropertyName: 'borrowerRelationshipTitleType',
        cleaningValue: BorrowerRelationshipTitleType.Other,
      }).bind(undefined, this.borrower);
  }
}

function createGetEnumerationItemsFunction(
  enumerations: Record<string, EnumerationItem[]>,
) {
  return function <T>(fieldName: string): EnumerationItem<T>[] {
    // FIXME: Some enum items have a name that is not properly cased at the time
    //  of this writing.
    //  (borrowerRelationshipTitleType and signingRole)
    //  This is a temporary fix.
    return (enumerations[fieldName] ?? []).map(item => ({
      ...item,
      name: toProperCase(splitCamelCaseAndCapitals(item.name)),
    }));
  };
}
