<ng-container *ngIf="isInitDataLoaded else loading">
  <div
    class="modal-body"
    [class.modal-body--scroll]="!isTpo"
    id="lead-editor-main"
  >
    <form
      id="upsertLead"
      #upsertLead="ngForm"
      name="upsertLead"
      *ngIf="!isLoading; else loading"
    >
      <div class="card">
        <div class="card-header">
          Status / Assignment Info
          <small>
            <a
              class="link ms-1"
              (click)="showAssignmentInfo = !showAssignmentInfo"
            >
              {{ showAssignmentInfo ? 'Hide' : 'Show' }} Details
            </a>
          </small>
          <span class="pull-right">
            <button
              class="btn btn-secondary btn-sm me-2"
              (click)="copyLeadRefToClipboard()"
              [hidden]="showTags"
            >
              Copy Lead Ref #
            </button>
            <button
              class="btn btn-secondary btn-sm me-2"
              (click)="showTags = true"
              [hidden]="showTags"
            >
              Add a Lead Tag
            </button>
            <button
              class="btn btn-secondary btn-sm me-2"
              (click)="openImportLeadModal()"
            >
              <i class="fa fa-upload"></i>
              Import Lead File
            </button>
          </span>
        </div>
        <div class="card-body" [hidden]="!showAssignmentInfo">
          <lead-assignment-info
            #leadAssignment
            [lead]="lead"
            [isDrawer]="true"
            [showTags]="showTags"
            (leadUpdated)="onLeadUpdated($event)"
            (loanPurposeChanged)="onLoanPurposeChanged($event)"
            (showTagsUpdated)="showTags = $event"
          >
          </lead-assignment-info>
        </div>
      </div>

      <lead-general-info
        [isDrawer]="true"
        [lead]="lead"
        [originalLeadData]="originalLeadData"
        [isManualDial]="isManualDial"
        [isSsnHidden]="isSsnHidden"
        [isSubjectPropertyAddressHidden]="isSubjectPropertyAddressHidden"
        [isAppraisedValueHidden]="isAppraisedValueHidden"
        [isPullCreditEnabled]="isPullCreditEnabled"
        [isSolar]="isSolar"
        (leadReloaded)="onLeadReloaded($event)"
        (dialClicked)="dialInfoClicked($event)"
      ></lead-general-info>

      <div class="card">
        <div class="card-header">
          Subject Property
          <small>
            <a
              class="link ms-1"
              (click)="showSubjectPropertyBody = !showSubjectPropertyBody"
            >
              {{ showSubjectPropertyBody ? 'Hide' : 'Show' }} Details
            </a>
          </small>
        </div>
        <div class="card-body" [hidden]="!showSubjectPropertyBody">
          <lead-subject-property
            [lead]="lead"
            [isDrawer]="true"
            [isSubjectPropertyAddressHidden]="isSubjectPropertyAddressHidden"
          ></lead-subject-property>
        </div>
      </div>

      <div class="card" *ngIf="lead.loanPurposeId !== 1 && !isTpo">
        <div class="card-header">
          Current Loan Info
          <small>
            <a
              class="link ms-1"
              (click)="showCurrentLoanInfoBody = !showCurrentLoanInfoBody"
            >
              {{ showCurrentLoanInfoBody ? 'Hide' : 'Show' }} Details
            </a>
          </small>
        </div>
        <div class="card-body" [hidden]="!showCurrentLoanInfoBody">
          <lead-current-loan-info
            [lead]="lead"
            [isDrawer]="true"
          ></lead-current-loan-info>
        </div>
      </div>

      <div class="card" *ngIf="!isTpo">
        <div class="card-header">
          New Loan Detail
          <small>
            <a
              class="link ms-1"
              (click)="showLoanDetailBody = !showLoanDetailBody"
            >
              {{ showLoanDetailBody ? 'Hide' : 'Show' }} Details
            </a>
          </small>
        </div>
        <div class="card-body" [hidden]="!showLoanDetailBody">
          <lead-loan-detail
            [lead]="lead"
            [isDrawer]="true"
            [isAppraisedValueHidden]="isAppraisedValueHidden"
          ></lead-loan-detail>
        </div>
      </div>

      <div class="card" *ngIf="!isTpo">
        <div class="card-header">
          Employment
          <small>
            <a
              class="link ms-1"
              (click)="showEmploymentBody = !showEmploymentBody"
            >
              {{ showEmploymentBody ? 'Hide' : 'Show' }} Details
            </a>
          </small>
        </div>
        <div class="card-body" [hidden]="!showEmploymentBody">
          <ng-template [ngIf]="loadingEmployments" [ngIfElse]="leadEmployment">
            <loading-indicator></loading-indicator>
          </ng-template>
          <ng-template #leadEmployment>
            <lead-employment
              [lead]="lead"
              [employments]="employments"
              [employmentBorrower]="employmentBorrower"
              [employmentCoBorrower]="employmentCoBorrower"
            ></lead-employment>
          </ng-template>
        </div>
      </div>

      <div class="card" *ngIf="!isTpo">
        <div class="card-header">
          Campaign / Lead Source
          <small>
            <a
              class="link ms-1"
              (click)="showCampaignLeadSourceBody = !showCampaignLeadSourceBody"
            >
              {{ showCampaignLeadSourceBody ? 'Hide' : 'Show' }} Details
            </a>
          </small>
        </div>
        <div class="card-body" [hidden]="!showCampaignLeadSourceBody">
          <lead-lead-source-or-compaign
            [lead]="lead"
            [isDrawer]="true"
          ></lead-lead-source-or-compaign>
        </div>
      </div>

      <div class="card" *ngIf="!isTpo">
        <div class="card-header">
          Custom Info
          <small>
            <a
              class="link ms-1"
              (click)="showCustomInfoBody = !showCustomInfoBody"
            >
              {{ showCustomInfoBody ? 'Hide' : 'Show' }} Details
            </a>
          </small>
        </div>
        <div class="card-body" [hidden]="!showCustomInfoBody">
          <lead-custom-data #leadCustomData [lead]="lead"></lead-custom-data>
        </div>
      </div>
    </form>

    <ng-template #loading>
      <loading-indicator loadingMessage="Loading lead..."></loading-indicator>
    </ng-template>
  </div>

  <div class="modal-footer w-100">
    <div class="d-flex justify-content-end">
      <button
        [hidden]="lead?.leadId"
        type="button"
        class="btn btn-secondary me-2"
        (click)="onCloseDrawer()"
      >
        <i class="fa fa-close"></i> Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="isSaving"
        (click)="save()"
      >
        <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
        <span *ngIf="isSaving">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Saving
        </span>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <loading-indicator loadingMessage="Retrieving Lead Info…"></loading-indicator>
</ng-template>
