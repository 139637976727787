<div class="card">
  <div class="card-body">
    <ng-container *ngIf="mortgage; else loading">
      <div class="card pt-1 pe-1 pb-1 mb-2 tpo-report-card sticky-button-bar sticky-button-bar-vertical"
        [ngClass]="{ 'sticky-nav-tabs-open-dialer': secondPageActionBarVisible, 'sticky-button-bar-tpo' : isTpoUser }">
        <div class="button-items">
          <button *ngIf="!isLoanReadOnly" type="button" class="btn btn-outline-primary" (click)="onImportClicked()" [hidden]="isReadOnly">
            <i class="fas fa-file-import"></i> Import
          </button>

          <button class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown">
            <i class="fas fa-file-export"></i>
            Export ... <i class="fas fa-caret-down"></i>
          </button>
          <span class="align-text-bottom fw-bold" *ngIf="activeEditingBorrowerName">
            Editing Borrower: <i _ngcontent-ket-c565="" class="fas fa-user ms-1" style="font-size: smaller;"></i> {{activeEditingBorrowerName}}
          </span>
          <div class="dropdown-menu">
            <a class="dropdown-item" (click)="onExportMismoClicked('BaseILADExport')">Download MISMO 3.4 XML</a>
            <a class="dropdown-item" (click)="onExportMismoClicked('DocMagic')">Download MISMO 3.4 XML (Full)</a>
            <a class="dropdown-item" (click)="onExportUrlaDocsClicked()">Generate URLA PDFs as Loan Docs</a>
          </div>

          <button type="button" (click)="onSaveClicked()" class="btn btn-primary right"
            [hidden]="isReadOnly || inEditMode" [disabled]="isUrlaSaving || isLoanReadOnly">
            <span *ngIf="!isUrlaSaving">
              <i class="fas fa-save"></i>
              {{ isLoanReadOnly ? 'Save (Readonly)' : 'Save' }}
            </span>
            <span *ngIf="isUrlaSaving">
              <span class="spinner-border spinner-border-sm" role="status" ></span>
              Saving
            </span>
          </button>
          <button type="button" (click)="editModeSave()" class="btn btn-primary right"
            [hidden]="!inEditMode">
              <i class="fas fa-save"></i>
              Save
          </button>

          <ng-container *ngIf="!isLoanReadOnly">
            <button type="button" class="btn btn-outline-primary right" (click)="onManageBorrowersClicked()" [hidden]="isReadOnly || inEditMode">
              <i class="fas fa-users"></i> Manage Borrowers
            </button>

            <button type="button" class="btn btn-outline-primary right" (click)="onAddNewBorrowerClicked()" [hidden]="isReadOnly || inEditMode">
              Add Borrower
            </button>
          </ng-container>

          <!-- <button type="button" class="btn btn-outline-primary right" (click)="editMode()"
            [ngClass]="{ 'active': inEditMode }">
            Edit Mode
          </button> -->
        </div>
      </div>

      <div class="accordion" id="urlaAccordion" class="loan-app">
        <div class="accordion-item" #borrowerInfo>
          <h5 class="accordion-header m-0" id="headingBorrowersInfo">
            <button (click)="onSectionHeadingClicked('borrowerInfo')" class="accordion-button fw-semibold"
              [ngClass]="{ collapsed: !sectionVisibilityStatuses['borrowerInfo'] && !inEditMode }"
              [attr.aria-expanded]="sectionVisibilityStatuses['borrowerInfo']" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseBorrowersInfo" aria-controls="collapseBorrowersInfo" [disabled]="inEditMode">
              1. Borrower Information
              <span class="mx-4 dti-info">{{ dtiInfoText }}</span>
            </button>
          </h5>
          <div id="collapseBorrowersInfo" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['borrowerInfo'] || inEditMode }"
            [attr.aria-labelledby]="'headingRequired'" aria-labelledby="headingBorrowersInfo">
            <div class="accordion-body">
              <borrowing-entity-info
                id="borrowingEntityInfoSection"
                [isReadOnly]="isReadOnly"
                [mortgage]="mortgage"
                [inEditMode]="inEditMode"
                [urlaFieldsConfig]="urlaFieldsConfig">
              </borrowing-entity-info>

              <borrowers-info #borrowersInfoComponent
                id="borrowerInfoSection"
                [isReadOnly]="isReadOnly"
                [mortgage]="mortgage"
                [loanBorrowers]="loanBorrowers"
                (borrowerReosChanged)="onBorrowerReosChanged()"
                [hiddenFields]="hiddenFields"
                [systemLevel]="systemLevel"
                [inEditMode]="inEditMode"
                [urlaFieldsConfig]="urlaFieldsConfig"
                (borrowerTabSelected)="onBorrowerTabSelected($event)">
              </borrowers-info>
            </div>
          </div>
        </div>
        <div class="accordion-item" #financialInfo>
          <h5 class="accordion-header m-0" id="headingFinancialInfo">
            <button (click)="onSectionHeadingClicked('financialInfo')" class="accordion-button fw-semibold"
              [ngClass]="{ collapsed: !sectionVisibilityStatuses['financialInfo'] && !inEditMode }"
              [attr.aria-expanded]="sectionVisibilityStatuses['financialInfo']" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseFinancialInfo" aria-controls="collapseFinancialInfo" [disabled]="inEditMode">
              2. Financial Information
            </button>
          </h5>
          <div id="collapseFinancialInfo" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['financialInfo'] || inEditMode }"
            aria-labelledby="headingFinancialInfo">
            <div class="accordion-body">
              <financial-info id="financialInfoSection" [isReadOnly]="isReadOnly" [mortgage]="mortgage" [application]="application"
                [systemLevel]="systemLevel" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig">
              </financial-info>
            </div>
          </div>
        </div>
        <div class="accordion-item" #reo>
          <h5 class="accordion-header m-0" id="headingReo">
            <button (click)="onSectionHeadingClicked('reo')" class="accordion-button fw-semibold"
              [ngClass]="{ collapsed: !sectionVisibilityStatuses['reo'] && !inEditMode }"
              [attr.aria-expanded]="sectionVisibilityStatuses['reo']" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseReo" aria-controls="collapseReo" [disabled]="inEditMode">
              3. Real Estate Owned
            </button>
          </h5>
          <div id="collapseReo" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['reo'] || inEditMode }" aria-labelledby="headingReo">
            <div class="accordion-body">
              <reo id="reoSection" [isReadOnly]="isReadOnly" [mortgage]="mortgage" [inEditMode]="inEditMode"
                [urlaFieldsConfig]="urlaFieldsConfig"></reo>
            </div>
          </div>
        </div>
        <div class="accordion-item" #loanAndProperty>
          <h5 class="accordion-header m-0" id="headingLoanPropertyInfo">
            <button (click)="onSectionHeadingClicked('loanAndProperty')" class="accordion-button fw-semibold" [ngClass]="{
            collapsed: !sectionVisibilityStatuses['loanAndProperty'] && !inEditMode
          }" [attr.aria-expanded]="sectionVisibilityStatuses['loanAndProperty']" type="button" [disabled]="inEditMode"
              data-bs-toggle="collapse" data-bs-target="#collapseLoanPropertyInfo"
              aria-controls="collapseLoanPropertyInfo">
              4. Loan & Property Info
              <span class="mx-4 ltv-info">LTV/CLTV: {{((mortgageCalculationDetails?.ltv?.ltv) / 100 || 0) | percent: '1.1-2'}} / {{((mortgageCalculationDetails?.ltv?.cltv / 100)
                || 0) | percent: '1.1-2'}}</span>
            </button>
          </h5>
          <div id="collapseLoanPropertyInfo" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['loanAndProperty'] || inEditMode }"
            aria-labelledby="headingLoanPropertyInfo">
            <div class="accordion-body">
              <loan-property
                id='loanAndPropertySection'
                [mortgage]="mortgage"
                [isReadOnly]="isReadOnly"
                [isSubjectPropertyAddressHidden]="isSubjectPropertyAddressHidden"
                [isAppraisedValueHidden]='isAppraisedValueHidden'
                [inEditMode]='inEditMode'
                (hasSubjectPropertyRentalIncomeChange)='hasSubjectPropertyRentalIncome = $event'
                [urlaFieldsConfig]='urlaFieldsConfig'
                [isTpoUser]='isTpoUser'
                [losIdentifier]="application.losIdentifier"
                (loanPurposeChanged)="onLoanPurposeChanged()"
                (loanAmountChanged)="onLoanAmountChanged($event)"
                (presentValueChanged)="onPresentValueChanged($event)"
                (borrowerReosChanged)="onBorrowerReosChanged()">
              </loan-property>
            </div>
          </div>
        </div>
        <div class="accordion-item" #declarations>
          <h5 class="accordion-header m-0" id="headingDeclerations">
            <button (click)="onSectionHeadingClicked('declarations')" class="accordion-button fw-semibold"
              [ngClass]="{ collapsed: !sectionVisibilityStatuses['declarations'] && !inEditMode }"
              [attr.aria-expanded]="sectionVisibilityStatuses['declarations']" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseDeclerations" aria-expanded="false" aria-controls="collapseDeclerations"
              [disabled]="inEditMode">
              5. Declarations
            </button>
          </h5>
          <div id="collapseDeclerations" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['declarations'] || inEditMode }"
            aria-labelledby="headingDeclerations">
            <div class="accordion-body">
              <declarations
                #declarationsComponent
                [urlaFieldsConfig]="urlaFieldsConfig"
                id="declarationsSection"
                [mortgage]="mortgage"
                [isReadOnly]="isReadOnly"
                [inEditMode]="inEditMode"
                (borrowerTabSelected)="onBorrowerTabSelected($event)">
              </declarations>
            </div>
          </div>
        </div>
        <div class="accordion-item" #militaryService>
          <h5 class="accordion-header m-0" id="headingMilitaryService">
            <button (click)="onSectionHeadingClicked('militaryService')" class="accordion-button fw-semibold" [ngClass]="{
            collapsed: !sectionVisibilityStatuses['militaryService'] && !inEditMode
          }" [attr.aria-expanded]="sectionVisibilityStatuses['militaryService']" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseMilitaryService" aria-expanded="false"
              aria-controls="collapseMilitaryService" [disabled]="inEditMode">
              7. Military Service
            </button>
          </h5>
          <div id="collapseMilitaryService" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['militaryService'] || inEditMode }"
            aria-labelledby="headingMilitaryService">
            <div class="accordion-body">
              <military-service id="militaryServiceSection" [mortgage]="mortgage" [isReadOnly]="isReadOnly"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"></military-service>
            </div>
          </div>
        </div>
        <div class="accordion-item" #demographics>
          <h5 class="accordion-header m-0" id="demographics">
            <button (click)="onSectionHeadingClicked('demographics')" class="accordion-button fw-semibold"
              [ngClass]="{ collapsed: !sectionVisibilityStatuses['demographics'] && !inEditMode }"
              [attr.aria-expanded]="sectionVisibilityStatuses['demographics']" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseDemographics" aria-expanded="false" aria-controls="collapseDemographics"
              [disabled]="inEditMode">
              8. Demographic Information of Borrower
            </button>
          </h5>
          <div id="collapseDemographics" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['demographics'] || inEditMode }"
            aria-labelledby="demographics">
            <div class="accordion-body">
              <demographics #demographicsComponent id="demographicsSection" [mortgage]="mortgage" [isReadOnly]="isReadOnly" [inEditMode]="inEditMode"
                [urlaFieldsConfig]="urlaFieldsConfig" (borrowerTabSelected)="onBorrowerTabSelected($event)"></demographics>
            </div>
          </div>
        </div>
        <div class="accordion-item" #loanOriginatorInfo>
          <h5 class="accordion-header m-0" id="headingLoanOriginatorInfo">
            <button (click)="onSectionHeadingClicked('loanOriginatorInfo')" class="accordion-button fw-semibold"
              [ngClass]="{
            collapsed: !sectionVisibilityStatuses['loanOriginatorInfo'] && !inEditMode
          }" [attr.aria-expanded]="sectionVisibilityStatuses['loanOriginatorInfo']" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseLoanOriginatorInfo" aria-expanded="false"
              aria-controls="collapseLoanOriginatorInfo" [disabled]="inEditMode">
              9. Loan Originator Info
            </button>
            <!-- <button class="accordion-header btn btn-primary" type="button"> Refresh </button> -->
          </h5>
          <div id="collapseLoanOriginatorInfo" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['loanOriginatorInfo'] || inEditMode }"
            aria-labelledby="headingLoanOriginatorInfo">
            <div class="accordion-body">
              <loan-originator-info id="loanOriginatorInfoSection" #loanOriginatorInfoComp [mortgage]="mortgage"
                [isReadOnly]="isReadOnly" (refreshClicked)="onRefreshLoanOriginatorInfoRequested()"
                [isMersEnabled]="isMersEnabled" (generateMersClicked)="onGenerateMERS()" [isAdmin]="isAdmin"
                [isGeneratingMers]="generatingMers" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                [isRequiredLoanAndCaseNumbers]="isRequiredLoanAndCaseNumbers" [systemLevel]="systemLevel">
              </loan-originator-info>
            </div>
          </div>
        </div>
        <div class="accordion-item" #propertyLoanInfo>
          <h5 class="accordion-header d-flex m-0" id="propertyLoanInformation">
            <button (click)="onSectionHeadingClicked('propertyLoanInfo')" class="accordion-button fw-semibold" [ngClass]="{
            collapsed: !sectionVisibilityStatuses['propertyLoanInfo'] && !inEditMode}"
              [attr.aria-expanded]="sectionVisibilityStatuses['propertyLoanInfo']" type="button"
              data-bs-toggle="collapse" data-bs-target="#propertyLoanAccordion" aria-expanded="false"
              aria-controls="propertyLoanAccordion" [disabled]="inEditMode">
              L1. Property and Loan Information
            </button>
            <div class="btn-section-heading-dropdown-container">
              <a class="btn dropdown-toggle btn-setion-heading-dropdown-item" data-bs-toggle="dropdown" *ngIf="!isReadOnly && !inEditMode"
                  aria-haspopup="false" aria-expanded="false" href="#" role="button">
                 <span> ... </span>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <a class="dropdown-item" (click)="onTexasAndInsuranceClicked()"> Taxes / Insurance </a>
                </div>
            </div>
          </h5>
          <div id="propertyLoanAccordion" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['propertyLoanInfo'] || inEditMode }"
            aria-labelledby="propertyLoanInformation">
            <div class="accordion-body">
              <property-loan-info id="propertyLoanInfoSection" [mortgage]="mortgage" [isReadOnly]="isReadOnly"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"></property-loan-info>
            </div>
          </div>
        </div>
        <div class="accordion-item" #titleInfo>
          <h5 class="accordion-header m-0" id="titleInfo">
            <button (click)="onSectionHeadingClicked('titleInfo')" class="accordion-button fw-semibold"
              [ngClass]="{ collapsed: !sectionVisibilityStatuses['titleInfo'] && !inEditMode }"
              [attr.aria-expanded]="sectionVisibilityStatuses['titleInfo']" type="button" data-bs-toggle="collapse"
              data-bs-target="#titleInfoAccordion" aria-expanded="false" aria-controls="titleInfoAccordion"
              [disabled]="inEditMode">
              L2. Title Information
            </button>
          </h5>
          <div id="titleInfoAccordion" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['titleInfo'] || inEditMode }" aria-labelledby="titleInfo">
            <div class="accordion-body">
              <title-information id="titleInfoSection" [mortgage]="mortgage" [isReadOnly]="isReadOnly"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"></title-information>
            </div>
          </div>
        </div>
        <div class="accordion-item" #mortgageLoanInfo>
          <h5 class="accordion-header d-flex m-0" id="loanInformation">
            <button (click)="onSectionHeadingClicked('mortgageLoanInfo')" class="accordion-button fw-semibold"
              [ngClass]="{
            collapsed: !sectionVisibilityStatuses['mortgageLoanInfo'] && !inEditMode
          }" [attr.aria-expanded]="sectionVisibilityStatuses['mortgageLoanInfo']" type="button"
              data-bs-toggle="collapse" data-bs-target="#loanInfoAccordion" aria-expanded="false"
              aria-controls="loanInfoAccordion" [disabled]="inEditMode">
              L3. Mortgage Loan Information
            </button>
            <div class="btn-section-heading-dropdown-container">
              <a class="btn dropdown-toggle btn-setion-heading-dropdown-item" data-bs-toggle="dropdown" *ngIf="!isReadOnly && !inEditMode"
                  aria-haspopup="false" aria-expanded="false" href="#" role="button">
                 <span> ... </span>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <a class="dropdown-item" (click)="onPrepaymentPenaltyClicked()"> Prepayment Penalty </a>
                  <a class="dropdown-item" (click)="onLateChargeClicked()"> Late Charge </a>
                </div>
            </div>
          </h5>
          <div id="loanInfoAccordion" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['mortgageLoanInfo'] || inEditMode }"
            aria-labelledby="loanInformation">
            <div class="accordion-body">
              <mortgage-loan-info id="mortgageLoanInfoSection" [mortgage]="mortgage" [isReadOnly]="isReadOnly"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [isTpoUser]="isTpoUser"
                (productTypeSelectionChanged)="onProductTypeChanged($event)"
                (prepaymentPenaltyClicked)="onPrepaymentPenaltyClicked()">
              </mortgage-loan-info>
            </div>
          </div>
        </div>
        <div class="accordion-item" #qualifyingTheBorr>
          <h5 class="accordion-header m-0" id="headingQualifyingTheBorr">
            <button (click)="onSectionHeadingClicked('qualifyingTheBorr')" class="accordion-button fw-semibold"
              [ngClass]="{
            collapsed: !sectionVisibilityStatuses['qualifyingTheBorr'] && !inEditMode
          }" [attr.aria-expanded]="sectionVisibilityStatuses['qualifyingTheBorr']" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseQualifyingTheBorr" aria-expanded="false"
              aria-controls="collapseQualifyingTheBorr" [disabled]="inEditMode">
              L4. Qualifying the Borrower - Minimum Required Funds or Cash Back
            </button>
          </h5>
          <div id="collapseQualifyingTheBorr" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['qualifyingTheBorr'] || inEditMode }"
            aria-labelledby="headingQualifyingTheBorr">
            <div class="accordion-body">
              <qualifying-the-borr
                id="qualifyingTheBorrSection"
                [mortgage]="mortgage"
                [application]="application"
                [isReadOnly]="isReadOnly"
                [urlaFieldsConfig]="urlaFieldsConfig"
                (purchasePriceAmountChanged)="onPurchasePriceAmountChanged($event)"
                (loanAmountChanged)="onLoanAmountChanged($event)"
                (miAndFundingFeeChanged)="miAndFundingFeeChange($event)"
                [inEditMode]="inEditMode">
              </qualifying-the-borr>
            </div>
          </div>
        </div>
        <div class="accordion-item" #homeownershipEducation>
          <h5 class="accordion-header m-0" id="headingHomeownershipEducation">
            <button (click)="onSectionHeadingClicked('homeownershipEducation')" class="accordion-button fw-semibold"
              [ngClass]="{
            collapsed: !sectionVisibilityStatuses['homeownershipEducation'] && !inEditMode
          }" [attr.aria-expanded]="sectionVisibilityStatuses['homeownershipEducation']" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseHomeownershipEducation" aria-expanded="false"
              aria-controls="collapseHomeownershipEducation" [disabled]="inEditMode">
              L5. Homeownership Education And Housing Counseling
            </button>
          </h5>
          <div id="collapseHomeownershipEducation" class="accordion-collapse collapse"
            [ngClass]="{ show: sectionVisibilityStatuses['homeownershipEducation'] || inEditMode }"
            aria-labelledby="headingHomeownershipEducation">
            <div class="accordion-body">
              <homeownership-education id="homeownershipEducationSection" [mortgage]="mortgage" [isReadOnly]="isReadOnly"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"></homeownership-education>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #loading>
      <loading-indicator [loadingMessage]="'Initializing URLA, please wait...'"></loading-indicator>
    </ng-template>
  </div>
</div>

<drawer #detailsDrawer [name]="'detailsDrawer'"
  [options]="detailsDrawerOptions">
</drawer>
