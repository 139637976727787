import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoanSummaryCardAdminModule } from 'src/app/modules/app-details/modules/loan-summary-card-admin/loan-summary-card-admin.module';
import { ConversationChatWindowComponent } from './components/conversation-chat-window/conversation-chat-window.component';
import { ConversationsPanelComponent } from './components/conversations-panel/conversations-panel.component';
import { ConversationListComponent } from './components/conversation-list/conversation-list.component';
import { ConversationListItemComponent } from './components/conversation-list-item/conversation-list-item.component';
import { ConversationDetailsComponent } from './components/conversation-details/conversation-details.component';
import { ConversationService } from './services/conversation.service';
import { ReactiveFormsModule } from '@angular/forms';
import { VideoRecorderModule } from 'src/app/shared/modules/video-recorder/video-recorder.module';

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    LoanSummaryCardAdminModule,
    VideoRecorderModule
  ],
  declarations: [
    ConversationsPanelComponent,
    ConversationDetailsComponent,
    ConversationChatWindowComponent,
    ConversationListComponent,
    ConversationListItemComponent,
  ],
  exports: [
    ConversationsPanelComponent,
    ConversationDetailsComponent,
    ConversationChatWindowComponent,
    ConversationListComponent,
    ConversationListItemComponent,
  ],
  providers: [
    ConversationService
  ]
})
export class ConversationsModule {
  constructor() { }
}
