import { Component, Input, OnInit } from '@angular/core';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'borrower-qualification',
  templateUrl: './borrower-qualification.component.html',
  styleUrls: ['./borrower-qualification.component.scss'],
  viewProviders: [formViewProvider]
})
export class BorrowerQualificationComponent implements OnInit {

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  yesNoOptions: EnumerationItem[];

  hMDAPreapprovalTypes: EnumerationItem[] = [];
  dhHiddenHack: boolean = false;

  constructor(private readonly _enumsService: EnumerationService) { }

  ngOnInit(): void {
    this.dhHiddenHack = this.mortgage.companyId == 229;
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.hMDAPreapprovalTypes = enums[Constants.mortgageEnumerations.hMDAPreapprovalType] || [];

      this.hMDAPreapprovalTypes.forEach(e => {
        e.name = Utils.splitCamelCaseString(e.name);
      });

    })

  }

}
