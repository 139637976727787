<div class="grid-layout has-border">
 <div class="grid-header">
      <div class="grid-header-left">
          <div> BORROWER QUALIFICATION </div>
      </div>
      <div class="grid-header-right">
      </div>
  </div>

  <div class="grid-layout-row">
    <urla-grid-cell class="col-sm-10 col-xs-10 grid-layout-text" [disabled]="isReadOnly">
      The income or assets of a person other than the borrower (including the spouse of the borrower) will be used as a basis for loan qualification.
    </urla-grid-cell>
    <urla-radio-group class="col-sm-2 col-xs-2" [readonly]="isReadOnly"
      [(ngModel)]="mortgage.extension.additionalBorrowerAssetsAreConsidered"
      name="additionalBorrowerAssetsAreConsidered" [options]="yesNoOptions" layout="horizontal"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.extension.additionalBorrowerAssetsAreConsidered'" [required]="urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered']?.required == 'required' ||
      urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered']?.required == 'requested'">
    </urla-radio-group>
  </div>

  <div class="grid-layout-row">
    <urla-grid-cell class="col-sm-10 col-xs-10 grid-layout-text" [disabled]="isReadOnly">
      The income or assets of the borrower's spouse will not be used as a basis for loan qualification, but his or her liabilities must be considered because the borrower resides in a community property state, the security property is located in a community property state, or the borrower is relying on other property located in a community property state as a basis for repayment of the loan.
    </urla-grid-cell>
    <urla-radio-group class="col-sm-2 col-xs-2" [readonly]="isReadOnly"
      [(ngModel)]="mortgage.extension.additionalBorrowerAssetsAreNotConsidered"
      name="additionalBorrowerAssetsAreNotConsidered" [options]="yesNoOptions" layout="horizontal"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.extension.additionalBorrowerAssetsAreNotConsidered'" [required]="urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered']?.required == 'required' ||
      urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered']?.required == 'requested'">
    </urla-radio-group>
  </div>

  <div class="grid-layout-row">
    <urla-currency-input class="col-sm-3 col-xs-6" [label]="'Other Party Paid Closing Costs'"
      [(ngModel)]="mortgage.transactionDetail.otherPartyPaidClosingCostsAmount" name="otherPartyPaidClosingCostsAmount" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.transactionDetail.otherPartyPaidClosingCostsAmount'" [required]="urlaFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount']?.required == 'required' ||
      urlaFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount']?.required == 'requested'">
    </urla-currency-input>
    <urla-currency-input class="col-sm-3 col-xs-6" [label]="'Personal Prop. In Sales Contract'"
      [(ngModel)]="mortgage.transactionDetail.personalPropertyInSalesContractAmount" name="personalPropertyInSalesContractAmount" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.transactionDetail.personalPropertyInSalesContractAmount'" [required]="urlaFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount']?.required == 'required' ||
      urlaFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount']?.required == 'requested'">
    </urla-currency-input>
    <urla-date-input class="col-sm-3 col-xs-6" [label]="'Sales Contract Date'" [readonly]="isReadOnly"
      [(ngModel)]="mortgage.transactionDetail.salesContractDate" name="salesContractDate" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.transactionDetail.salesContractDate'"
      [required]="urlaFieldsConfig['mortgage.transactionDetail.salesContractDate']?.required == 'required' ||
      urlaFieldsConfig['mortgage.transactionDetail.salesContractDate']?.required == 'requested'">
    </urla-date-input>
    <urla-currency-input class="col-sm-3 col-xs-6" [label]="'Cash To Seller At Closing'"
      [(ngModel)]="mortgage.transactionDetail.cashToSellerAtClosingAmount" name="cashToSellerAtClosingAmount" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.transactionDetail.cashToSellerAtClosingAmount'" [required]="urlaFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount']?.required == 'required' ||
      urlaFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount']?.required == 'requested'">
    </urla-currency-input>
  </div>
  
  <div class="grid-layout-row" *ngIf="!dhHiddenHack">
    <urla-datetime-input class="col-sm-3 col-xs-6" [label]="'Est. Closing Costs Expiration'" [readonly]="isReadOnly"
      [(ngModel)]="mortgage.transactionDetail.estimatedClosingCostsExpiration" name="estimatedClosingCostsExpiration" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.transactionDetail.estimatedClosingCostsExpiration'"
      [required]="urlaFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration']?.required == 'required' ||
      urlaFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration']?.required == 'requested'">
    </urla-datetime-input>
    <urla-digits-input class="col-sm-3 col-xs-6" label="Borr. Reser. Monthly Paym. Count" [allowedNegative]="false"
      [(ngModel)]="mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount" [name]="'borrowerReservesMonthlyPaymentCount'" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount'"
      [required]="urlaFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount']?.required == 'required' || urlaFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount']?.required == 'requested'">
    </urla-digits-input>
    <urla-dropdown class="col-sm-3 col-xs-6" label="HMDA Preapproval Type"
      [options]="hMDAPreapprovalTypes" [readonly]="isReadOnly"
      [(ngModel)]="mortgage.extension.hmdaPreapprovalType" name="hmdaPreapprovalType"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.extension.hmdaPreapprovalType'" [required]="urlaFieldsConfig['mortgage.mortgageTerm.hmdaPreapprovalType']?.required == 'required' ||
      urlaFieldsConfig['mortgage.extension.hmdaPreapprovalType']?.required == 'requested'">
    </urla-dropdown>
    <urla-text-input [readonly]="isReadOnly"
      [label]="'Related Investor Loan Id'" class="col-sm-3 col-xs-6"
      [name]="'relatedInvestorLoanId'"
      [(ngModel)]="mortgage.extension.relatedInvestorLoanId" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.extension.relatedInvestorLoanId'" [required]=" urlaFieldsConfig['mortgage.extension.relatedInvestorLoanId']?.required == 'required' ||
      urlaFieldsConfig['mortgage.extension.relatedInvestorLoanId']?.required == 'requested'">
    </urla-text-input>
  </div>
</div>
