<pricing-search-v2
  [viewType]="2"
  [channelSelectionEnabled]="enableChannelSelection"
  [productSearchRequestInfo]="productSearchRequestInfo"
  [currentApplication]="currentApplication"
  [canPriceApplyToLoan]="!isLoanReadOnly"
  [currentMortgage]="currentMortgage"
>
</pricing-search-v2>

