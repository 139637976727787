import { Component, OnInit, Input, Injector } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { MortgageBorrower } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { QuickApplyFieldsConfigBoundComponent } from 'src/app/shared/components/quick-apply-fields-config-bound.component';

@Component({
  selector: 'qa-about-property',
  templateUrl: 'qa-about-property.component.html',
  styleUrls: ['./qa-about-property.component.scss'],
  viewProviders: [formViewProvider]
})

export class QuickApplyAboutPropertyComponent extends QuickApplyFieldsConfigBoundComponent implements OnInit {

  @Input()
  borrower: MortgageBorrower;

  @Input()
  yesNoOptions: EnumerationItem[] = [];

  @Input()
  propertyTypesOwned: EnumerationItem[] = [];

  @Input()
  holdTitles: EnumerationItem[] = [];

  @Input()
  isPurchase: boolean = false;

  constructor(private readonly injector: Injector) {
    super(injector)
  }

  ngOnInit() {

  }

  borrowedDownPaymentChange = (): void => {
    if (!this.borrower.declarations.borrowedDownPayment) {
      this.borrower.declarations.undisclosedMoneyAmount = null;
    }
  }

  occupyPropertyChange = (val: any): void => {
    if (!this.borrower.declarations.occupyProperty) {
      this.borrower.declarations.havePropertiesOwnership = null;
      this.borrower.declarations.typeOfProperty = null;
      this.borrower.declarations.heldTitleHow = null;
    }
  }

  havePropertiesOwnershipChange = (): void => {
    if (!this.borrower.declarations.havePropertiesOwnership) {
      this.borrower.declarations.typeOfProperty = null;
      this.borrower.declarations.heldTitleHow = null;
      this.borrower.declarations.isFirstTimeHomeBuyer = this.isPurchase ? true : null;
    }
  }
}
