import { Component, OnInit, Input, Injector } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { MortgageBorrower } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { QuickApplyFieldsConfigBoundComponent } from 'src/app/shared/components/quick-apply-fields-config-bound.component';

@Component({
  selector: 'qa-about-finance',
  templateUrl: 'qa-about-finance.component.html',
  styleUrls: ['./qa-about-finance.component.scss'],
  viewProviders: [formViewProvider]
})
export class QuickApplyAboutFinanceComponent extends QuickApplyFieldsConfigBoundComponent implements OnInit {

  @Input()
  borrower: MortgageBorrower;

  @Input()
  bankruptcyType: EnumerationItem[] = [];

  @Input()
  yesNoOptions: EnumerationItem[] = [];

  constructor(private readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() { }
}
