<div class="modal-body">
  <form
    #parcelIdentificationForm="ngForm"
    novalidate
    id="parcelIdentificationForm"
    name="parcelIdentificationForm"
  >
    <div class="form-group">
      <label for="parcelIdentificationNumber"
        >Parcel Identification Number</label
      >
      <text-input
        [(ngModel)]="parcelIdentificationNumber.parcelIdentifier"
        name="parcelIdentificationNumber"
        id="parcelIdentificationNumber"
        [readonly]="isReadOnly"
        [required]="
          urlaFieldsConfig[
            'parcelIdentifications.parcelIdentificationNumber'
          ] == 'required' ||
          urlaFieldsConfig[
            'parcelIdentifications.parcelIdentificationNumber'
          ] == 'requested'
        "
      ></text-input>
    </div>

    <div class="form-group">
      <label for="taxMapIdentifier">Tax Map Identifier</label>
      <text-input
        [(ngModel)]="taxMapIdentifier.parcelIdentifier"
        name="taxMapIdentifier"
        id="taxMapIdentifier"
        [readonly]="isReadOnly"
        [required]="
          urlaFieldsConfig['parcelIdentifications.taxMapIdentifier'] ==
            'required' ||
          urlaFieldsConfig['parcelIdentifications.taxMapIdentifier'] ==
            'requested'
        "
      ></text-input>
    </div>

    <div class="form-group">
      <label for="taxParcelIdentifier">Tax Parcel Identifier</label>
      <text-input
        [(ngModel)]="taxParcelIdentifier.parcelIdentifier"
        name="taxParcelIdentifier"
        id="taxParcelIdentifier"
        [readonly]="isReadOnly"
        [required]="
          urlaFieldsConfig['parcelIdentifications.taxParcelIdentifier'] ==
            'required' ||
          urlaFieldsConfig['parcelIdentifications.taxParcelIdentifier'] ==
            'requested'
        "
      ></text-input>
    </div>

    <div class="form-group">
      <label for="assessorUnformattedIdentifier"
        >Assessor Unformatted Identifier</label
      >
      <text-input
        [(ngModel)]="assessorUnformattedIdentifier.parcelIdentifier"
        name="assessorUnformattedIdentifier"
        id="assessorUnformattedIdentifier"
        [readonly]="isReadOnly"
        [required]="
          urlaFieldsConfig[
            'parcelIdentifications.assessorUnformattedIdentifier'
          ] == 'required' ||
          urlaFieldsConfig[
            'parcelIdentifications.assessorUnformattedIdentifier'
          ] == 'requested'
        "
      ></text-input>
    </div>

    <div class="form-group">
      <label for="torrensCertificateIdentifier"
        >Torrens Certificate Identifier</label
      >
      <text-input
        [(ngModel)]="torrensCertificateIdentifier.parcelIdentifier"
        name="torrensCertificateIdentifier"
        id="torrensCertificateIdentifier"
        [readonly]="isReadOnly"
        [required]="
          urlaFieldsConfig[
            'parcelIdentifications.torrensCertificateIdentifier'
          ] == 'required' ||
          urlaFieldsConfig[
            'parcelIdentifications.torrensCertificateIdentifier'
          ] == 'requested'
        "
      ></text-input>
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-end">
        <button class="btn btn-sm btn-primary" (click)="onAddClicked()">
          Add Other Identifier
        </button>
      </div>
    </div>

    <div class="form-group" *ngFor="let parcelId of others; let i = index">
      <label for="identificationTypeOtherDescription-{{ i }}"
        >Other Parcel Identifier {{ others.length > 1 ? i + 1 : '' }}</label
      >

      <div class="d-flex align-items-center">
        <text-input
          class="flex-grow-1"
          [(ngModel)]="parcelId.identificationTypeOtherDescription"
          name="identificationTypeOtherDescription-{{ i }}"
          id="identificationTypeOtherDescription-{{ i }}"
          [readonly]="isReadOnly"
          [required]="
            urlaFieldsConfig[
              'parcelIdentifications.identificationTypeOtherDescription'
            ] == 'required' ||
            urlaFieldsConfig[
              'parcelIdentifications.identificationTypeOtherDescription'
            ] == 'requested'
          "
        ></text-input>

        <i class="fa fa-trash-alt cursor text-danger ms-2" title="Delete" (click)="onClickDelete(i)"></i>

      </div>
    </div>
  </form>
</div>

<div class="modal-footer justify-content-end">
  <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
  <button class="btn btn-primary ms-2" (click)="onSaveClicked()">Save</button>
</div>
