import { Utils } from "src/app/core/services/utils";
import { EmploymentTypeEnum, TypeOfIncomeEnum } from "src/app/models";
import { BorrowerCalculatedStats, EmploymentCalculatedStats } from "../../urla/models/urla-mortgage.model";

export class LeadEmployment {
  leadEmploymentId: number
  leadId: number
  companyName?: string
  position?: string
  employmentLengthYears?: number
  monthlyIncome?: number
  commissionsIncome: number
  overtimeIncome: number
  bonusIncome: number
  isCurrentEmployer?: boolean
  notes?: string
  companyId?: number
  isCoBorrowerEmployment?: boolean
  typeOfIncome?: TypeOfIncomeEnum
  selfEmploymentMonthlyIncomeOrLoss?: number
  borrowerOwnershipShare?: string
  startDate?: string
  endDate?: string
  isSelfEmployed?: boolean

  insertedBy?: string;
  updatedBy?: string;

  // inherit from UrlaEmployment
  employmentType?: EmploymentTypeEnum
  // selfEmployed?: boolean
  calculatedStats?: {
    monthlyIncome?: number
  }

  // ui purposes
  employmentId?: number

  constructor(leadId?: number) {
    this.leadId = leadId || null;
    this.employmentId = Utils.getUniqueId();
  }
}

export class LeadIncome {
  incomeId: number
  calculatedStats: {
    monthlyIncome: number;
  }
  companyId: number
  dateInserted: string
  dateUpdated: string
  insertedBy: string
  isCoBorrowerEmployment: boolean
  leadEmploymentId: number
  leadId: number
  monthlyIncome: number
  typeOfIncome: string
  updatedBy: string

  amount: number; // fake
  payPeriod: PayPeriod; //fake
  hoursPerWeek: number; //fake
}

export class LeadBorrowerEmployment {
  employments: LeadEmployment[] = [];
  nonEmploymentIncomes: LeadEmployment[] = [];
  firstName: string = ""
  lastName: string = ""
  calculatedStats: BorrowerCalculatedStats
}

export enum PayPeriod {
  Monthly = 1,
  Annually = 2,
  Biweekly = 3,
  Bimonthly = 4,
  Weekly = 5,
  Quarterly = 6,
  Semiannually = 7,
  Hourly = 8,
  Semimonthly = 9,
}
