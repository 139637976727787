<div class="grid-layout has-border">
    <div class="grid-header mb-1">
        <div class="grid-header-left">
            <div>4b. Other New Mortgages Loans</div>
        </div>
        <div class="grid-header-right">
            <a class="btn btn-sm btn-soft-primary me-2" (click)="addRelatedMortgage()" role="button" *ngIf="!isReadOnly && !inEditMode">
                <i class="fas fa-plus me-2"></i>Add Mortgage Loans
            </a>
        </div>
    </div>
    <div class="row mb-2">
            <div class="table-responsive" style="border-bottom: none;">
                <table class="table mb-0" *ngIf="mortgage.relatedMortgages.length > 0">
                    <thead class="table-light">
                        <tr>
                            <td class="col-md-2 col-sm-2 col-xs-2">Creditor</td>
                            <td class="col-md-1 col-sm-1 col-xs-1">Lien Type</td>
                            <td class="col-md-2 col-sm-2 col-xs-2">FHA Source</td>
                            <td class="col-md-1 col-sm-1 col-xs-1">MO PMT</td>
                            <td class="col-md-1 col-sm-1 col-xs-1">Loan Amt/Draw</td>
                            <td class="col-md-1 col-sm-1 col-xs-1 text-center">Credit Limit</td>
                            <td class="col-md-1 col-sm-1 col-xs-1 text-center">HELOC</td>
                            <td class="col-md-3 col-sm-3 col-xs-3 text-center">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let relatedMortgage of mortgage.relatedMortgages; let index = index">
                            <td class="col-md-2 col-sm-2 col-xs-2">
                                <urla-text-input [(ngModel)]="relatedMortgage.creditorName"
                                    name="creditorName_{{index}}" [readonly]="index !== editingIx"
                                    [showBorder]="index === editingIx">
                                </urla-text-input>
                            </td>
                            <td class="col-md-2 col-sm-2 col-xs-2">
                                <urla-dropdown [(ngModel)]="relatedMortgage.lienType" [options]="lienPositionTypes"
                                    name="lienType_{{index}}" [disabled]="isUrlaReadonly"
                                    [readonly]="index !== editingIx" [showBorder]="index === editingIx"
                                    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.relatedMortgage.lienType'"
                                    [required]="urlaFieldsConfig['mortgage.relatedMortgage.lienType']?.required == 'required' ||
                                    urlaFieldsConfig['mortgage.relatedMortgage.lienType']?.required == 'requested'">
                                </urla-dropdown>
                            </td>
                            <td class=" col-md-2 col-sm-2 col-xs-2">
                                <urla-dropdown [(ngModel)]="relatedMortgage.fundsSource" [options]="giftGrantSources"
                                    name="fhaSource_{{index}}" [showBorder]="index === editingIx"
                                    [readonly]="index !== editingIx"></urla-dropdown>
                            </td>
                            <td class="col-md-1 col-sm-1 col-xs-1">
                                <urla-currency-input name="moPmt_{{index}}" [(ngModel)]="relatedMortgage.monthlyPayment"
                                    [readonly]="index !== editingIx"
                                    [showBorder]="index === editingIx" [isOmitted]="relatedMortgage['isOmitted']"
                                    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.relatedMortgage.monthlyPayment'"
                                    [required]="urlaFieldsConfig['mortgage.relatedMortgage.monthlyPayment']?.required == 'required' ||
                                    urlaFieldsConfig['mortgage.relatedMortgage.monthlyPayment']?.required == 'requested'">
                                </urla-currency-input>
                            </td>
                            <td class="col-md-2 col-sm-2 col-xs-2">
                                <urla-currency-input name="loanOrDrawAmount_{{index}}"
                                    [(ngModel)]="relatedMortgage.loanOrDrawAmount"
                                    [readonly]="index !== editingIx" [showBorder]="index === editingIx"
                                    (change)="calculateTotalLoanOrDrawAmount()"
                                    [isOmitted]="relatedMortgage['isOmitted']"
                                    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.relatedMortgage.loanOrDrawAmount'"
                                    [required]="urlaFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount']?.required == 'required' ||
                                    urlaFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount']?.required == 'requested'">
                                </urla-currency-input>
                            </td>
                            <td class="col-md-1 col-sm-1 col-xs-1">
                                <urla-currency-input name="creditLimit{{index}}"
                                    [(ngModel)]="relatedMortgage.creditLimit"
                                    [readonly]="index !== editingIx" [showBorder]="index === editingIx"
                                    [isOmitted]="relatedMortgage['isOmitted']"
                                    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.relatedMortgage.creditLimit'"
                                    [required]="urlaFieldsConfig['mortgage.relatedMortgage.creditLimit']?.required == 'required' ||
                                    urlaFieldsConfig['mortgage.relatedMortgage.creditLimit']?.required == 'requested'">
                                </urla-currency-input>
                            </td>
                            <td class="col-md-1 col-sm-1 col-xs-1 text-center">
                                <urla-dropdown [(ngModel)]="relatedMortgage.isHeloc" [options]="yesNoOptions" name="isHeloc_{{index}}"
                                    [disabled]="isUrlaReadonly" [readonly]="index !== editingIx" [showBorder]="index === editingIx"
                                    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.relatedMortgage.isHeloc'"
                                    [required]="urlaFieldsConfig['mortgage.relatedMortgage.isHeloc']?.required == 'required' ||
                                    urlaFieldsConfig['mortgage.relatedMortgage.isHeloc']?.required == 'requested'">
                                </urla-dropdown>
                                <!-- <urla-boolean-checkbox [(ngModel)]="relatedMortgage.isHeloc" name="isHeloc_{{index}}" [readonly]="index !== editingIx"
                                [label]="''" name="isRenovation" [disabled]="isUrlaReadonly || inEditMode"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.relatedMortgage.isHeloc'"
                                [required]="urlaFieldsConfig['mortgage.relatedMortgage.isHeloc']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.relatedMortgage.isHeloc']?.required == 'requested'">
                              </urla-boolean-checkbox> -->
                            </td>
                            <td class="col-md-1 col-sm-1 col-xs-1">
                                <div class="action" *ngIf="!isReadOnly">
                                    <!-- Show Mortgage Details -->
                                    <a title="Show Mortgage Details" href="javascript: void(0);" *ngIf="index !== editingIx"
                                        (click)="showRelatedMortgageDetails(index)"><i
                                            class="fa fa-eye"></i></a>
                                    <a title="Edit Mortgage" href="javascript: void(0);" *ngIf="index !== editingIx"
                                        (click)="editRelatedMortgage(index, relatedMortgage)"><i
                                            class="fa fa-pencil-alt"></i></a>
                                    <a title="Confirm Mortgage Changes" href="javascript: void(0);" *ngIf="index === editingIx"
                                        (click)="confirmEdit()"><i class="fa fa-check text-success"></i></a>
                                    <a title="Cancel Mortgage Changes" href="javascript: void(0);" *ngIf="index === editingIx" (click)="cancelEdit()"><i
                                            class="fa fa-times text-success"></i></a>

                                    <a title="Omit Mortgage from Calculations" href="javascript: void(0);" *ngIf="!relatedMortgage['isOmitted']"
                                        (click)="omitRelatedMortgage(relatedMortgage, true)"><i
                                            class="fa fa-ban"></i></a>
                                    <a title="Include Mortgage in Calculations" href="javascript: void(0);" *ngIf="relatedMortgage['isOmitted']"
                                        (click)="omitRelatedMortgage(relatedMortgage, false)"><i
                                            class="fa fa-ban red-icon"></i></a>

                                    <a title="Delete Mortgage" href="javascript: void(0);" (click)="deleteRelatedMortgage(index)"><i
                                            class="fa fa-trash-alt"></i></a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
</div>

<related-mortgage-loan-editor-drawer #editorDrawer>
</related-mortgage-loan-editor-drawer>
