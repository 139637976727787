import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MiQuoteDetail } from 'src/app/models/mi-quote-search-result.model';
import { ProductSearchRequest } from '../../models/pricing/product-search-request-info.model';

export interface SelectedMiQuoteInfo {
  selectedMiQuote: MiQuoteDetail;
  quoteResults: MiQuoteDetail[]
}

@Component({
  selector: 'mi-quote-search-dialog',
  templateUrl: './mi-quote-search-dialog.component.html',
  styleUrls: ['./mi-quote-search-dialog.component.scss']
})
export class MiQuoteSearchDialogComponent implements OnInit {

  interestRate: number;
  selectedMiQuoteInfo: SelectedMiQuoteInfo = null;
  pricingRunRequest: ProductSearchRequest;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  onQuoteSelected = (data: SelectedMiQuoteInfo) => {
    this.selectedMiQuoteInfo = data;
  }

  onQuotedDetailSelectedAfterReRun = (data: SelectedMiQuoteInfo) => {
    this.selectedMiQuoteInfo = data;
    this.activeModal.close(data);
  }

}
