import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { BorrowerAlias } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { createIsRequiredFunction } from '../../urla-utils';

@Component({
  selector: 'alias-info',
  templateUrl: 'alias-info.component.html',
  viewProviders: [formViewProvider]
})

export class AliasInfoComponent implements OnInit {

  @Input()
  isReadOnly: boolean = false;

  @Input()
  alias: BorrowerAlias;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Output()
  aliasDeletionAttempted: EventEmitter<BorrowerAlias> = new EventEmitter<BorrowerAlias>();

  mouseCursorOnDeleteButton: boolean = false;

  protected suffixes: EnumerationItem[] = [];
  protected aliasTypeOptions: EnumerationItem[] = [];

  protected isRequired: (fieldName: string) => boolean;

  constructor(private readonly _enumsService: EnumerationService) { }

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe((result) => {
      this.suffixes = result[Constants.enumerations.suffix];
      this.aliasTypeOptions =
        result[Constants.mortgageEnumerations.borrowerAliasType];
    });

    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);
  }

  onDeleteClicked = () => {
    this.aliasDeletionAttempted.emit(this.alias);
  }
}
