<button
  (click)='onClickAdd()'
  class='btn btn-sm btn-soft-primary'
  title='Add Explanation'
  type='button'
>
  <i class='fas fa-plus me-2'></i>

  Explanation
</button>
