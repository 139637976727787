<div class="card" *ngIf="application; else loading">
  <div class="card-body">
    <div class="card">
      <p-table #dt1
        [value]="changeOfCircumstances"
        styleClass="p-datatable-gridlines"
      >
        <ng-template pTemplate="caption">
          <div class="d-flex justify-content-between" *ngIf="!readonly">
            <span></span>

            <span>
              <button type="button" class="btn btn-primary" (click)="onAddNewClicked()">
                <i class="fas fa-plus"></i> Add New
              </button>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5rem">
              Actions
            </th>
            <th>
              Status
            </th>
            <th>
              Disclosure Reason
            </th>
            <th>
              Changes Received Date
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td>
              <div class="d-flex align-items-center justify-content-center">
                <span>
                  <i *ngIf="!readonly && rowData.changeOfCircumstanceStatus === 'Requested'"
                    class="fa fa-pencil-alt cursor text-info"
                    (click)="onEditClicked(rowData)"
                    title="Edit"
                  ></i>

                  <i *ngIf="readonly || rowData.changeOfCircumstanceStatus !== 'Requested'"
                    class="fas fa-eye cursor text-info"
                    (click)="onEditClicked(rowData)"
                    title="Preview"
                  ></i>
                </span>
                <span *ngIf="!readonly && rowData.changeOfCircumstanceStatus === 'Requested'">
                  <span class="mx-1"> | </span>
                  <i
                    class="fa fa-trash-alt cursor text-danger"
                    (click)="onDeleteClicked(rowData.changeOfCircumstanceId)"
                    title="Delete"
                    id="btnMcrSummaryDelete"
                  ></i>
                </span>
              </div>
            </td>
            <td> {{ rowData.changeOfCircumstanceStatus }} </td>
            <td> {{ rowData.disclosureReason | cocReason }} </td>
            <td> {{ rowData.changesOfCircumstanceReceivedDate | date:'short' }} </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<ng-template #loading>
  <loading-indicator
    [loadingMessage]="'Loading change of circumstance / reasons ...'"
  ></loading-indicator>
</ng-template>

<drawer
  [title]="'Change Of Circumstance'"
  [name]="drawerName"
  [templateRef]="cocDetailsRef"
  [options]="cocDetailsDrawerOptions"
  (closed)="onCocDetailsDrawerClosed()"
></drawer>
<ng-template #cocDetailsRef>
  <coc-details
    *ngIf="selectedCocDetails"
    [cocDetails]="selectedCocDetails"
    [applicationId]="application.applicationId"
    [mortgageId]="application.mortgageId"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [readonly]="readonly"
    (onCocDetailsSaved)="onCocDetailsSaved($event)"
    (onCocDetailsClosed)="onCocDetailsDrawerClosed()"
  ></coc-details>
</ng-template>