<div class="grid-layout has-border" *ngIf="mortgage.mortgageId != null">
    <div class="grid-header">
        <div class="grid-header-left">
            <div> DUE FROM BORROWER(S) </div>
        </div>
        <div class="grid-header-right">
        </div>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'A. Sales Contract Price'">
        </urla-grid-cell>
        <urla-currency-input
            class="col-md-4 col-xs-4" name="purchasePriceAmount"
            [required]="!isRefiLoan" #purchasePriceAmountInput="ngModel"
            [ngClass]=" {'ng-invalid-requested b-none' : purchasePriceAmountInput.invalid && !isReadOnly }"
            [readonly]="isReadOnly || inEditMode || isRefiLoan"
            [(ngModel)]="mortgage.transactionDetail.purchasePriceAmount"
            (change)="calculateTotalDue(); onPurchasePriceAmountChange();">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'B. Improvements, Renovations, and Repairs'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="isReadOnly || inEditMode" class="col-md-4 col-xs-4" name="alterationsImprovementsAndRepairsAmount"
            [(ngModel)]="mortgage.transactionDetail.alterationsImprovementsAndRepairsAmount"
            (change)="calculateTotalDue()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-3 col-xs-3" [label]="'C. Land Value using'">
        </urla-grid-cell>
        <urla-dropdown [readonly]="isReadOnly || inEditMode" class="col-md-3 col-xs-3" [options]="landValueType" name="landValueType"
            [(ngModel)]="mortgage.subjectProperty.landValueType" (change)="landValueChange()">
        </urla-dropdown>
        <urla-grid-cell class="col-md-2 col-xs-2" [label]="'(defined in L1)'"></urla-grid-cell>
        <urla-currency-input [readonly]="isReadOnly" class="col-md-4 col-xs-4" name="mortgageStatslandValue"
            [(ngModel)]="mortgage.calculatedStats.landValue" [readonly]="true">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row" *ngIf="isRefiLoan">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8"
            [label]="'D. For Refinance: Balance of Mortgage Loans on the Property to be paid off in the Transaction'">
        </urla-grid-cell>
        <urla-currency-input class="col-md-4 col-xs-4" name="totalPaidOffForRefinance" [readonly]="true"
            [(ngModel)]="mortgage.calculatedStats.totalPaidOffForRefinance" (change)="calculateTotalDue()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'E. Credit Cards and Other Debts Paid off'">
        </urla-grid-cell>
        <urla-currency-input class="col-md-4 col-xs-4" name="financialPartialPayoffTotalAmount" [readonly]="true"
            [(ngModel)]="mortgage.calculatedStats.financialPartialPayoffTotalAmount" (change)="calculateTotalDue()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'F. Borrower Closing Costs (including Prepaid and Initial Escrow Payments)'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="true" class="col-md-4 col-xs-4" name="estimatedClosingCostsAmount"
            [(ngModel)]="mortgage.calculatedStats.estimatedClosingCostsAmount" (change)="calculateTotalDue()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <div class="grid-layout-spacer col-md-1 col-xs-1">&nbsp;</div>
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-7 col-xs-7" [label]="'Estimated Prepaid Items'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="true" class="col-md-4 col-xs-4" name="prepaidItemsEstimatedAmount"
            [(ngModel)]="mortgage.transactionDetail.prepaidItemsEstimatedAmount" (change)="calculateTotalDue()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <div class="grid-layout-spacer col-md-1 col-xs-1">&nbsp;</div>
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-7 col-xs-7" [label]="'Estimated Escrow Items'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="true" class="col-md-4 col-xs-4" name="prepaidEscrowsTotalAmount"
            [(ngModel)]="mortgage.transactionDetail.prepaidEscrowsTotalAmount" (change)="calculateTotalDue()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <div class="grid-layout-spacer col-md-1 col-xs-1">&nbsp;</div>
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-7 col-xs-7" [label]="'Estimated Closing Costs'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="true" class="col-md-4 col-xs-4" name="estimatedClosingCostsExcludingPrepaidsAmount"
            [(ngModel)]="mortgage.transactionDetail.estimatedClosingCostsExcludingPrepaidsAmount" (change)="calculateTotalDue()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <div class="grid-layout-spacer col-md-1 col-xs-1">&nbsp;</div>
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-7 col-xs-7" [label]="'PMI, MIP, Funding Fee'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="true" class="col-md-4 col-xs-4" name="miAndFundingFeeTotalAmount"
            [(ngModel)]="mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount" (change)="calculateTotalDue()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'G. Discount Points'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="isReadOnly || inEditMode || dhHiddenHack" class="col-md-4 col-xs-4" name="borrowerPaidDiscountPointsTotalAmount"
            [(ngModel)]="mortgage.transactionDetail.borrowerPaidDiscountPointsTotalAmount" [allowNegative]="true" (change)="calculateTotalDue()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'H. TOTAL DUE FROM BORROWER(S) (Total of A thru G)'">
        </urla-grid-cell>
        <urla-currency-input class="col-md-4 col-xs-4" name="totalDue"
            [(ngModel)]="mortgage.calculatedStats.totalDue" [readonly]="true">
        </urla-currency-input>
    </div>
</div>
