import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { CustomField } from 'src/app/modules/pricing/models/pricing/custom-fields.model';
import { NotificationService } from 'src/app/services/notification.service';
import { PricingCustomFieldService } from 'src/app/services/pricing-custom-field.service';

@Component({
  selector: 'app-loanpass-custom-fields-dialog',
  templateUrl: './loanpass-custom-fields-dialog.component.html',
  styleUrls: ['./loanpass-custom-fields-dialog.component.scss']
})
export class LoanpassCustomFieldsDialogComponent implements OnInit {

  @ViewChild("loanPassCustomFieldsForm") loanPassCustomFieldsForm: NgForm;

  @Input() credentialId: number;
  @Input() vendor: "LoanPass" | "LoanPassIframe" = "LoanPass";

  customFields: CustomField[] = [];
  orginalCustomFields: CustomField[] = [];

  loadingCustomFields: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _pricingCustomFieldService: PricingCustomFieldService
  ) { }

  ngOnInit(): void {
    this.loadCustomFields();
  }

  onSaveClicked = () => {

    this.loanPassCustomFieldsForm.form.markAllAsTouched();
    if (!this.loanPassCustomFieldsForm.form.valid) {
      return;
    }

    let obs: Observable<any>[] = [];

    this.customFields.forEach((cf: CustomField, index: number) => {
      cf.sortOrder = index;
      if (cf.options?.length > 0 && cf.type !== 'List') {
        cf.options = [];
      }
      if (cf.pricingCustomFieldId) {
        obs.push(this._pricingCustomFieldService.updateCustomField(cf));
      } else {
        obs.push(this._pricingCustomFieldService.insertCustomField(cf));
      }
    });
    const deletedCustomFields = this.orginalCustomFields.filter(o => !this.customFields.map(c => c.pricingCustomFieldId).includes(o.pricingCustomFieldId));
    deletedCustomFields.forEach(del => obs.push(this._pricingCustomFieldService.deleteCustomField(del?.pricingCustomFieldId)));

    this._spinnerService.show();
    forkJoin(obs).subscribe({
        next: ((result: any) => {
          this.activeModal.close();
          this._spinnerService.hide();
          this._notificationService.showSuccess(
            'Custom Fields successfuly updated',
            'Success!'
          );
        }),
        error: ((error: any) => {
          this._spinnerService.hide();
          this._notificationService.showError(
            error?.message || 'Unable to update custom fields',
            'Failure!'
          );
        })
    });
  }

  addCustomField(): void {
    this.customFields.push(new CustomField(this.vendor));
  }

  private loadCustomFields() {
    this.loadingCustomFields = true;
    this._pricingCustomFieldService.getCustomFields(this.vendor).subscribe({
      next: ((result: CustomField[]) => {
        this.customFields = _.orderBy(result, 'sortOrder');
        this.orginalCustomFields = _.cloneDeep(result);
      }),
      error: ((error: any) => {
        this._notificationService.showError(error?.message || 'Unable to get custom fields for Loan Pass', 'Failure!');
      })
    }).add(() => this.loadingCustomFields = false);
  }

}
