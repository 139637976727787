import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { ResidencyAddress, SystemLevel } from 'src/app/models';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { UrlaBorrower } from '../../models/urla-mortgage.model';
import { ResidencyAddressChangedEvent } from '../address-info/residency-address-base.component';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'borrower-info',
  templateUrl: 'borrower-info.component.html',
  viewProviders: [formViewProvider]
})
export class BorrowerInfoComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('borrowerForm')
  borrowerForm: NgForm;

  @Input()
  borrower: UrlaBorrower;

  @Input()
  applicationId: number = 0;

  @Input()
  loanBorrower: BorrowerDto;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  hiddenFields: string[];

  @Input()
  isSubjectPropertyPresentAddress: boolean = false;

  @Input()
  isSubjectPropertyPickerVisibleForPresentAddress: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  isCorrespondent: boolean;

  @Input()
  systemLevel: SystemLevel;

  @Output()
  borrowerPresentStreetAddressChanged: EventEmitter<ResidencyAddressChangedEvent> = new EventEmitter<ResidencyAddressChangedEvent>();

  @Output()
  borrowerPresentAddressCityStateOrZipCodeChanged: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Output()
  borrowerPresentResidencyBasisChanged: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Output()
  presentAddressSubjectPropertyStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  pushPresentAddressToSubjectPropertyClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pullPresentAddressSubjectPropertyClicked: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Output()
  borrowerValidityStatusChanged: EventEmitter<BorrowerValidityStatus> = new EventEmitter<BorrowerValidityStatus>();

  titleOnlyEnumValue: string = "";
  nonTitledSpouseEnumValue: string = "";

  isTitleOnlyOrNonTitledSpouse: boolean = false;

  private _formValidationEventsSubscription: Subscription;

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _menuService: MenuService) { }

  ngOnInit() {
    this.titleOnlyEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.SigningRole.TitleOnly);
    this.nonTitledSpouseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.SigningRole.NonTitledSpouse);
    this.isTitleOnlyOrNonTitledSpouse = this.borrower.signingRole === this.titleOnlyEnumValue ||
      this.borrower.signingRole === this.nonTitledSpouseEnumValue;
  }

  ngAfterViewInit(): void {
    if (this.borrowerForm) {
      this._formValidationEventsSubscription = this.borrowerForm.valueChanges.subscribe((changeEvent) => {
        if (changeEvent) {
          const fieldNames = Object.keys(changeEvent).filter(key => key !== 'triggerValidation');
          for (let i = 0; i < fieldNames.length; i++) {
            const fieldControl = this.borrowerForm.controls[fieldNames[i]];
            if (fieldControl.touched || this._menuService.isTouched) {
              this._menuService.isTouched = true;
              setTimeout(() => {
                this.borrowerValidityStatusChanged.emit({ borrowerId: this.borrower.borrowerId, formValidityStatus: "" });
              });
              break;
            }
          }
        }
      })
    }
  }

  ngOnDestroy(): void {
    this._formValidationEventsSubscription?.unsubscribe();
  }

  onBorrowerValidityStatusChanged = () => {
    this.borrowerValidityStatusChanged.emit({ borrowerId: this.borrower.borrowerId, formValidityStatus: "" });
  }

  onPushPresentAddressToSubjectPropertyClicked = () => {
    this.pushPresentAddressToSubjectPropertyClicked.emit();
  }

  onPullPresentAddressSubjectPropertyClicked = (address: ResidencyAddress) => {
    this.pullPresentAddressSubjectPropertyClicked.emit(address);
  }

  onPresentAddressSubjectPropertyStatusChanged = (e: boolean) => {
    this.presentAddressSubjectPropertyStatusChanged.emit(e);
  }

  onPresentStreetAddressChanged = (e: ResidencyAddressChangedEvent) => {
    this.borrowerPresentStreetAddressChanged.emit(e);
  }

  onPresentAddressCityBlurred = (e: ResidencyAddress) => {
    this.borrowerPresentAddressCityStateOrZipCodeChanged.emit(e);
  }

  onPresentAddressStateBlurred = (e: ResidencyAddress) => {
    this.borrowerPresentAddressCityStateOrZipCodeChanged.emit(e);
  }

  onPresentAddressZipCodeBlurred = (e: ResidencyAddress) => {
    this.borrowerPresentAddressCityStateOrZipCodeChanged.emit(e);
  }

  onPresidentAddressResidencyBasisChanged = (e: ResidencyAddress) => {
    this.borrowerPresentResidencyBasisChanged.emit(e);
  }

  onSigningRoleChanged = () => {
    this.isTitleOnlyOrNonTitledSpouse = this.borrower.signingRole === this.titleOnlyEnumValue ||
      this.borrower.signingRole === this.nonTitledSpouseEnumValue;
  }
}

export class BorrowerValidityStatus {
  borrowerId: number;
  formValidityStatus: string;
}
