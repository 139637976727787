import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { Select2OptionData } from 'ng-select2';
import { Asset } from 'src/app/models/mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import {
  QuickApplyFieldsConfigBoundComponent,
} from 'src/app/shared/components/quick-apply-fields-config-bound.component';
import { map, race, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { resetObjectTo } from '../../qa-fi-income/reset-object.to';
import { autoId } from '../../../../../../../core/services/utils';

@Component({
  selector: 'qa-fi-asset-editor',
  templateUrl: './qa-fi-asset-editor.component.html',
  styleUrls: ['./qa-fi-asset-editor.component.scss']
})
export class QaFiAssetEditorComponent extends QuickApplyFieldsConfigBoundComponent implements OnInit, OnDestroy {

  @Input() asset: Asset;
  @Output() assetChange = new EventEmitter<Asset>();

  @Input() mode: 'create' | 'edit' = 'create';

  @Input() possibleAccountOwners: Array<Select2OptionData> = [];
  @Input() assetTypes: EnumerationItem[] = [];
  @Input() otherAssetTypes: EnumerationItem[] = [];
  @Input() giftGrantTypes: EnumerationItem[] = [];
  @Input() giftGrantSources: EnumerationItem[] = [];

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('assetForm') protected formElement: NgForm;

  private readonly _id: string = `qa-fi-asset-editor-${autoId()}`;
  private _originalAsset: Asset;

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false
  }

  yesNoOptions: EnumerationItem[] = [];

  private _savingChangesSubscription?: Subscription;

  private readonly _save$ = new Subject<void>();
  private readonly _destroyed$ = new Subject<void>();

  constructor(
    injector: Injector,
    private _enumsService: EnumerationService,
  ) {
    super(injector)
  }

  ngOnInit(): void {
    this._originalAsset = _.cloneDeep(this.asset);
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();

    setTimeout(() => {
      this.subscribeToSavingChanges();
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private subscribeToSavingChanges(): void {
    this._savingChangesSubscription?.unsubscribe();
    const subscription = this._savingChangesSubscription = this.formElement.valueChanges.pipe(
      takeUntil(this._destroyed$),
    ).subscribe(() => {
      this.enqueueSave();
    });

    const cancelOrDestroy$ = race(
      this._destroyed$.pipe(map(() => true)),
      this.cancel.pipe(map(() => false)),
    ).pipe(
      tap((shouldSave) => {
        if (shouldSave) {
          // Prevent ExpressionChangedAfterItHasBeenCheckedError.
          setTimeout(() => {
            this.emitAssetChange();
          });
        }
      }),
    );

    subscription.add(
      this._save$.pipe(
        takeUntil(cancelOrDestroy$),
        debounceTime(200),
      ).subscribe(() => {
        this.emitAssetChange();
      }),
    );
  }

  private enqueueSave(): void {
    this._save$.next();
  }

  private emitAssetChange(): void {
    this.assetChange.emit(this.asset);
  }

  protected onClickCancel() {
    this.resetAssetChanges();

    this.cancel.emit();
  }

  private resetAssetChanges(): void {
    const asset = this.asset;
    if (asset == null) {
      console.error('Cannot reset changes as the asset is null.');
      return;
    }

    const originalAsset = this._originalAsset;
    if (originalAsset == null) {
      console.error('Cannot reset changes as the original asset is null.');
      return;
    }

    resetObjectTo(asset, originalAsset);
  }

  protected onClickClose(): void {
    this.close.emit();
  }

  isGiftOrGrantType = () => {
    return this.asset.assetType ? this.giftGrantTypes.map(t => t.value).includes(this.asset.assetType) : false;
  }

  protected id(elementId: string): string {
    return `${this._id}-${elementId}`;
  }
}
