import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppraisalCondition } from 'src/app/models/appraisal/appraisal-condition.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';

@Component({
  selector: 'add-appraisal-condition-dialog',
  templateUrl: './add-appraisal-condition-dialog.component.html',
  styleUrls: ['./add-appraisal-condition-dialog.component.scss']
})
export class AddAppraisalConditionDialogComponent implements OnInit {
  @Input() conditionCategories: Array<EnumerationItem>;
  newCondition: AppraisalCondition;
  constructor(public readonly activeModal: NgbActiveModal,
    private readonly _utilityService: UtilityService) { }

  ngOnInit(): void {
    this.newCondition = {
      thirdPartyConditionId: this._utilityService.getUniqueId(),
      conditionText: '',
      conditionCategory: ''
    }
  }
}
