<div class="modal-header">
  <h5 class="modal-title">There are Unsaved Changes on URLA</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <h4 class="text-center">{{message}}</h4>
</div>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-link" (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" (click)="activeModal.close('discard')" class="btn btn-danger">Discard Changes</button>
  <button type="button" (click)="activeModal.close('save')" class="btn btn-primary">Save & Continue</button>
</div>
