<form #selectInputForm="ngForm" style="height: 100%">
  <div
    *ngIf="!inEditMode"
    class="form-input"
    [ngClass]="
      {
        'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required &&
          ((required && !fieldBeingEdited) || (fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested')),
        'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
        'bg-transparent' : disabled || readonly,
        'form-input' : showBorder,
        'no-border' : !showBorder
      }"
    [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden">
    <span title="{{label}}" class="input-label" style="display: block;" *ngIf="label && label.length > 0">
      <span class="input-label-overflow">{{label}}</span>
      <a (click)="editClickEvent.emit()" class="float-end" [hidden]="!hasEditButton || readonly">
        <i class="fas fa-pencil-alt"></i>
      </a>
    </span>
    <select
      [hidden]="readonly"
      [(ngModel)]="value"
      #model="ngModel"
      name="{{name}}"
      [required]="required"
      id="{{id}}"
      [ngClass]="{
        'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && ((required && !fieldBeingEdited) || (fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested')),
        'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required'
      }"
      [attr.disabled]="disabled || !options || options.length === 0 ? '' : null"
      [attr.aria-disabled]="disabled || !options || options.length === 0 ? '' : null"
      class="input-element">
      <option [ngValue]="unselectedValue">Select One</option>
      <option [ngValue]="option.value" *ngFor="let option of options">{{option.name}}</option>
    </select>
    <input
      [hidden]="!readonly"
      type="text"
      value="{{getOptionLabel()}}"
      name="{{name}}_readonly"
      [ngClass]="
        {
          'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && ((required && !fieldBeingEdited) || (fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested')),
          'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
          'no-border' : !showBorder
        }"
      [readonly]="true"
      class="input-element" />
  </div>

  <edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
  </edit-mode-input>

  <!-- <div *ngIf="inEditMode" class="form-input"
    [ngClass]="
    { 'ng-invalid-requested' : urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
      'ng-invalid-required' : urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
      'form-input' : showBorder,
      'no-border' : !showBorder
    }"
  >
    <span class="input-label" title="" *ngIf="label && label.length > 0">
      <span class="input-label-overflow">{{label}}</span>
    </span>

    <select class="form-select" [(ngModel)]="urlaFieldsConfig[fieldBeingEdited].required" name="{{name}}_Config"
      [ngClass]="
      { 'ng-invalid-requested' : urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
        'ng-invalid-required' : urlaFieldsConfig[fieldBeingEdited]?.required == 'required'
      }"
    >
      <option value="none">None</option>
        <option value="requested">Requested</option>
        <option value="required">Required</option>
    </select>
  </div> -->

</form>

