<div class="grid-layout has-border">
  <div class="grid-header mb-1">
    <div class="grid-header-left">L2c. Parcel Identifications</div>

    <div class="grid-header-right"></div>
  </div>

  <div class="grid-layout-row px-2 mb-2">
    <urla-text-input
      [(ngModel)]="showingParcelId"
      [inEditMode]="inEditMode"
      [name]="'parcelIdentifier'"
      [ngClass]="{ 'bg-transparent': !isReadOnly }"
      [readonly]="isReadOnly"
      [hasEditButton]="true"
      [required]="
        urlaFieldsConfig['legalDescriptions.parcelIdentifier']?.required == 'required' ||
        urlaFieldsConfig['legalDescriptions.parcelIdentifier']?.required == 'requested'
      "
      [urlaFieldsConfig]="urlaFieldsConfig"
      class="col-md-4"
      [fieldBeingEdited]="'legalDescriptions.parcelIdentifier'"
      [label]="'Parcel Identifier'"
      (editClickEvent)="onParcelIdentifierClicked()"
    >
    </urla-text-input>
  </div>
</div>

<drawer #parcelIdentificationDrawer [name]="'parcelIdentifierDrawer'"
  [options]="detailsDrawerOptions">
</drawer>

