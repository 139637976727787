<div class="grid-layout has-border">
    <div class="grid-header mb-1">
        <div class="grid-header-left">
            <div>4d. Gifts or Grants</div>
        </div>
        <div class="grid-header-right">
            <a class="btn btn-sm btn-soft-primary me-2" (click)="addGiftOrGrant()" role="button" *ngIf="!isReadOnly && !inEditMode">
                <i class="fas fa-plus me-2"></i>Add Gift or Grant
            </a>
        </div>
    </div>
    <div class="row mb-2">
            <div class="table-responsive" style="border-bottom: none;">
                <table class="table mb-0" *ngIf="assets.length > 0">
                    <thead class="table-light">
                        <tr>
                            <td class="col-md-2 col-sm-2 col-xs-2">Account Owner</td>
                            <td class="col-md-2 col-sm-2 col-xs-2">Asset Type</td>
                            <td class="col-md-2 col-sm-2 col-xs-2">Source</td>
                            <td class="col-md-2 col-sm-2 col-xs-2">Deposited</td>
                            <td class="col-md-2 col-sm-2 col-xs-2">Cash/Market Value</td>
                            <td class="col-md-2 col-sm-2 col-xs-2 text-center">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let asset of assets; let index = index">
                            <td class="col-md-2 col-sm-2 col-xs-2">
                                <ng-select2 [hidden]="index !== editingIx" class="select2 mb-3 select2-multiple"
                                    name="owningBorrowerIds{{index}}" [(ngModel)]="asset.owningBorrowerIds"
                                    (ngModelChange)="onAssetOwnersChanged(asset)"
                                    [options]="optionsMultipleSelect" [data]="possibleAccountOwners" required>
                                </ng-select2>
                                <div class="owningBorrowerIds-readonly" *ngIf="index !== editingIx"
                                    [ngClass]="{ 'ng-invalid-requested no-border' : asset.owningBorrowerIds.length === 0 }"
                                    [ngStyle]="{height: asset.owningBorrowerIds.length === 0 ? '22px' : 'auto'}">
                                    <span *ngFor="let owner of asset['owners']">{{owner.name}}<br></span>
                                </div>
                            </td>
                            <td class=" col-md-2 col-sm-2 col-xs-2">
                                <urla-dropdown [(ngModel)]="asset.assetType" [options]="assetTypes"
                                    name="assetType_{{index}}" [disabled]="isUrlaReadonly"
                                    [readonly]="index !== editingIx" [showBorder]="index === editingIx"
                                    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.assetType'"
                                    [required]="urlaFieldsConfig['mortgage.asset.assetType']?.required == 'required' ||
                                    urlaFieldsConfig['mortgage.asset.assetType']?.required == 'requested'">
                                </urla-dropdown>
                            </td>
                            <td class=" col-md-2 col-sm-2 col-xs-2">
                                <urla-dropdown [(ngModel)]="asset.giftOrGrantSource" [options]="giftGrantSources"
                                    name="giftOrGrantSource_{{index}}" [disabled]="isUrlaReadonly"
                                    [readonly]="index !== editingIx" [showBorder]="index === editingIx"
                                    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.giftOrGrantSource'"
                                    [required]="urlaFieldsConfig['mortgage.asset.giftOrGrantSource']?.required == 'required' ||
                                    urlaFieldsConfig['mortgage.asset.giftOrGrantSource']?.required == 'requested'">
                                </urla-dropdown>
                            </td>
                            <td class=" col-md-2 col-sm-2 col-xs-2">
                                <urla-dropdown [(ngModel)]="asset.isDeposited" [options]="yesNoOptions"
                                    name="isDeposited_{{index}}" [disabled]="isUrlaReadonly"
                                    [readonly]="index !== editingIx" [showBorder]="index === editingIx"
                                    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.isDeposited'"
                                    [required]="urlaFieldsConfig['mortgage.asset.isDeposited']?.required == 'required' ||
                                    urlaFieldsConfig['mortgage.asset.isDeposited']?.required == 'requested'">
                                </urla-dropdown>
                            </td>
                            <td class="col-md-2 col-sm-2 col-xs-2">
                                <urla-currency-input name="cashMarketValue{{index}}" [(ngModel)]="asset.cashMarketValue"
                                    [readonly]="index !== editingIx" [showBorder]="index === editingIx"
                                    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.asset.cashMarketValue'"
                                    [required]="urlaFieldsConfig['mortgage.asset.cashMarketValue']?.required == 'required' ||
                                    urlaFieldsConfig['mortgage.asset.cashMarketValue']?.required == 'requested'">
                                </urla-currency-input>
                            </td>
                            <td class="col-md-2 col-sm-2 col-xs-2">
                                <div class="action" *ngIf="!isReadOnly">
                                    <a title="Edit Gift or Grant" href="javascript: void(0);" *ngIf="index !== editingIx"
                                        (click)="editGiftOrGrant(index, asset)"><i
                                            class="fa fa-pencil-alt"></i></a>
                                    <a title="Confirm Gift or Grant Changes" href="javascript: void(0);" *ngIf="index === editingIx"
                                        (click)="editingIx = -1"><i class="fa fa-check text-success"></i></a>
                                    <a title="Cancel Gift or Grant Changes" href="javascript: void(0);" *ngIf="index === editingIx" (click)="cancelEdit()"><i
                                            class="fa fa-times text-success"></i></a>

                                    <a title="Omit Gift or Grant from Calculations" href="javascript: void(0);" *ngIf="!isOmitGiftOrGrant(asset)"
                                        (click)="omitGiftOrGrant(asset, true)"><i
                                            class="fa fa-ban"></i></a>
                                    <a title="Include Gift or Grant in Calculations" href="javascript: void(0);" *ngIf="isOmitGiftOrGrant(asset)"
                                        (click)="omitGiftOrGrant(asset, false)"><i
                                            class="fa fa-ban red-icon"></i></a>

                                    <a title="Delete Gift or Grant" href="javascript: void(0);" (click)="deleteGiftOrGrant(index)"><i
                                            class="fa fa-trash-alt"></i></a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
</div>
