import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
  @Output() onClick = new EventEmitter<void>();

  /**
   * Whether the button is disabled. Changes the button's appearance and
   * prevents clicks.
   */
  @Input() isDisabled: boolean = false;

  protected onButtonClick() {
    this.onClick.emit();
  }
}
