import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { UrlaBorrower } from '../../../models/urla-mortgage.model';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { createIsRequiredFunction } from '../../../urla-utils';
import { NotificationService } from '../../../../../services/notification.service';
import { DeclarationExplanation, DeclarationExplanationType, MortgageBorrower } from '../../../../../models';
import { ExplanationGroupManager } from '../../explanation-group-manager';

@Component({
  selector: 'about-finances',
  templateUrl: 'about-finances.component.html',
  styleUrls: ['../about-property/about-property.component.scss'],
  viewProviders: [formViewProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutFinancesComponent implements OnInit, OnChanges {

  @Input()
  borrower: UrlaBorrower;

  @Input()
  isReadOnly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  bankruptcyType: EnumerationItem[] = [];

  yesNoOptions: EnumerationItem[] = [];

  protected explanationGroupManager: ExplanationGroupManager;

  protected isRequired: (fieldName: string) => boolean;

  protected readonly DeclarationExplanationType = DeclarationExplanationType;

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _enumsService: EnumerationService,
    private readonly _notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.resetExplanationGroupManager();
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);

    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.bankruptcyType = enums[Constants.enumerations.bankruptcyTypes];
      this.yesNoOptions = this._enumsService.getYesNoEnumItems();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const borrowerChanges = changes.borrower;
    if (borrowerChanges) {
      this.resetExplanationGroupManager();
    }
  }

  private resetExplanationGroupManager(): void {
    this.explanationGroupManager = new ExplanationGroupManager(
      this._notificationService,
      this.borrower.declarations,
    );
  }

  protected getExplanationGroup(
    explanationType: DeclarationExplanationType,
  ): readonly DeclarationExplanation[] {
    return this.explanationGroupManager.getExplanationGroup(explanationType);
  }

  protected onClickAddExplanation(
    explanationType: DeclarationExplanationType,
  ): void {
    this.explanationGroupManager.addExplanation(explanationType);
  }

  public async onClickDeleteExplanation(
    explanation: DeclarationExplanation,
    indexInGroup: number,
  ): Promise<void> {
    await this.explanationGroupManager.deleteExplanationWithConfirmation(
      explanation,
      indexInGroup,
    );
    this._cdr.markForCheck();
  }

  protected bankruptcyTypeChange(): void {
    if (!this.borrower.declarations.declaredBankruptcy) {
      this.borrower.declarations.bankruptcyType = null;
    }
  }
}
