<div class='grid-layout has-border'>
  <div class='grid-header mb-1'>
    <div class='grid-header-left'>
      1d. Title Information
    </div>

    <div class='grid-header-right'></div>
  </div>

  <!-- Property Title Type & Borrower Relationship Title Type -->
  <div class='grid-layout-row px-2 mb-2'>

    <!-- Borrower Relationship Title Type -->
    <urla-dropdown (ngModelChange)='onBorrowerRelationshipTitleTypeChange()'
      [(ngModel)]='borrower.borrowerRelationshipTitleType' [inEditMode]='inEditMode'
      [name]="'borrowerRelationshipTitleType' + borrower.borrowerId" [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='borrowerRelationshipTitleTypeOptions' [readonly]='isReadOnly'
      [required]="isRequired('mortgage.borrower.borrowerRelationshipTitleType')" [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-6' fieldBeingEdited='mortgage.borrower.borrowerRelationshipTitleType'
      label='Borrower Relationship Title Type'></urla-dropdown>
    <!-- Borrower Relationship Title Type Other Description -->
    <urla-text-input
      *ngIf='borrower.borrowerRelationshipTitleType === BorrowerRelationshipTitleType.Other || inEditMode'
      [(ngModel)]='borrower.borrowerRelationshipTitleTypeOtherDescription' [inEditMode]='inEditMode'
      [name]="'borrowerRelationshipTitleTypeOtherDescription' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}" [readonly]='isReadOnly'
      [required]="isRequired('mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription')"
      [urlaFieldsConfig]='urlaFieldsConfig' class='col-6'
      fieldBeingEdited='mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription'
      label='Borrower Relationship Title Type Other Description'></urla-text-input>
  </div>