<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      About Your Finances
    </h4>
  </div>
  <div class="card-body">
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> F. Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.coSignedLoan == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.coSignedLoan == null && quickApplyFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'coSignedLoan' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'coSignedLoan' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.coSignedLoan"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> G. Are there any outstanding judgments against you? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.haveJudgement == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.haveJudgement'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.haveJudgement == null && quickApplyFieldsConfig['mortgage.borrower.declarations.haveJudgement'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'haveJudgement' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'haveJudgement' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.haveJudgement"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.haveJudgement'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.haveJudgement'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> H. Are you currently delinquent or in default on a federal debt? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.haveTaxDebt == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.haveTaxDebt == null && quickApplyFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'haveTaxDebt' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'haveTaxDebt' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.haveTaxDebt"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> I. Are you a party to a lawsuit in which you potentially have any personal financial liability? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.haveLawsuit == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.haveLawsuit == null && quickApplyFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'haveLawsuit' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'haveLawsuit' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.haveLawsuit"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> J. Have you conveyed title to any property in lieu of foreclosure in the past 7 years? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.haveConveyedTitleInLieuOfForeclosure == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.haveConveyedTitleInLieuOfForeclosure == null && quickApplyFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'haveConveyedTitleInLieuOfForeclosure' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'haveConveyedTitleInLieuOfForeclosure' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.haveConveyedTitleInLieuOfForeclosure"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> K. Within the past 7 years, have you completed a pre-foreclosure sale or short sale? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.haveCompletedShortSale == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.haveCompletedShortSale == null && quickApplyFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'haveCompletedShortSale' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'haveCompletedShortSale' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.haveCompletedShortSale"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> L. Have you had property foreclosed upon in the last 7 years? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.beenForclosed == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.beenForclosed'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.beenForclosed == null && quickApplyFieldsConfig['mortgage.borrower.declarations.beenForclosed'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'beenForclosed' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'beenForclosed' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.beenForclosed"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.beenForclosed'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.beenForclosed'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> M. Have you declared bankruptcy within the past 7 years? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.declaredBankruptcy == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.declaredBankruptcy == null && quickApplyFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'declaredBankruptcy' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'declaredBankruptcy' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.declaredBankruptcy"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2" *ngIf="borrower.declarations.declaredBankruptcy">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> M.1 If Yes, identify the type(s) of bankruptcy </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.bankruptcyType == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.bankruptcyType == null && quickApplyFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] == 'requested'}">
          <select id="bankruptcyType{{borrower.borrowerId}}" name="bankruptcyType{{borrower.borrowerId}}"
            [(ngModel)]="borrower.declarations.bankruptcyType" [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] == 'required' ||
            quickApplyFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] == 'requested'">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let type of bankruptcyType" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
