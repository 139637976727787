import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, cloneDeep, orderBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { LoanStatus, Role } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ChannelService } from 'src/app/services/channel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent, RearrangeOrderComponent } from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { DeleteWithReplacementConfirmationDialogComponent } from '../../../company/components/shared/delete-with-replacement-confirmation-dialog/delete-with-replacement-confirmation-dialog.component';
import { LoanStatusService } from '../../services';

@Component({
  templateUrl: 'loan-status.component.html',
  styleUrls: ['loan-status.component.scss'],
})
export class LoanStatusComponent extends ApplicationContextBoundComponent implements OnInit {
  @ViewChild(Table)
  loanStatusTable: Table;

  @ViewChild('loanStatusOrderDrawer')
  loanStatusOrderDrawer: DrawerComponent;

  @ViewChild('loanStatusOrder')
  loanStatusOrder: RearrangeOrderComponent

  loanStatusOrderDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  upsertLoanStatusDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  currentLoanStatus: Partial<LoanStatus>;
  loanStatusColumns = [];
  loanStatuses: Array<LoanStatus> = [];
  filteredLoanStatuses: Array<LoanStatus> = [];
  roles: Array<Role> = [];
  enabledChannels: EnumerationItem[] = [];
  statusesToOrder: Array<{ name: string, value: number }> = [];

  companyId: number;

  selectedChannelNameForFilter: string;

  allowAddNew: boolean = false;
  channelsEnabled: boolean = false;
  savingOrder: boolean = false;

  globalFilterFields: string[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _loanStatusService: LoanStatusService,
    private readonly _modalService: NgbModal,
    private readonly _notificationService: NotificationService,
    private readonly _channelService: ChannelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _drawerService: DrawerService
  ) {
    super(injector);
    super.scrollOffset = 250;
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.applicationContextService.context.subscribe((res) => {
      this.companyId = this.applicationContext.userPermissions.companyId;
      this.allowAddNew = this.applicationContext.userPermissions.superAdmin ? false : true;
      this.roles = res.globalConfig.roles;
      this.enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(
        res.userPermissions.enabledChannels
      );
      this.channelsEnabled = !!this.enabledChannels.length;
      this.selectedChannelNameForFilter = 'All';
      this.loanStatusColumns = [
        { field: 'loanStatusName', header: 'Loan Status', visible: true, sortable: true },
        { field: 'enabledChannels', header: 'Channels', visible: this.channelsEnabled, sortable: true },
        { field: 'selectByRole', header: 'Select By Role', visible: true, sortable: true },
        { field: 'showAsArchive', header: 'Show As Archive', visible: true, sortable: false },
        { field: 'hideFromCounter', header: 'Hide From Counter', visible: true, sortable: false },
        { field: 'removeFromAllDialCampaigns', header: 'Remove From All Dial Campaigns', visible: true, sortable: false },
        { field: 'isUsedForBilling', header: 'Used For Billing', visible: true, sortable: false },
        { field: 'emailFromRoleId', header: 'Email/Sms From Role', visible: true, sortable: true },
        { field: 'loanStage', header: 'Loan Stage (for pipeline)', visible: true, sortable: true },
        { field: 'order', header: 'Sort', visible: true, sortable: true }
      ];
      if (!this.applicationContext.userPermissions.superAdmin) {
        const isUsedForBillingColumnIndex = this.loanStatusColumns.findIndex(c => c.field === 'isUsedForBilling');
        if (isUsedForBillingColumnIndex > -1) {
          this.loanStatusColumns.splice(isUsedForBillingColumnIndex, 1);
        }
      }
      this.globalFilterFields = this.loanStatusColumns.map(c => c.field);
      this.populateLoanStatuses();
    });
  }

  showUpsertDialog(rowData?: LoanStatus) {
    this.currentLoanStatus = cloneDeep(rowData || {});
    this._drawerService.show("upsertLoanStatusDrawer", 100);
  }

  onUpsertLoanStatusDrawerClose(updateLoanStatus: LoanStatus) {
    if (!updateLoanStatus) {
      this._drawerService.hide("upsertLoanStatusDrawer", 100);
      return;
    }
    this._drawerService.hide("upsertLoanStatusDrawer", 100);
    this.updateTableAfterUpsert(updateLoanStatus);
  }

  showDeleteDialog(rowData: LoanStatus) {
    const modalRef = this._modalService.open(
      DeleteWithReplacementConfirmationDialogComponent
    );
    modalRef.componentInstance.ItemsList = this.loanStatuses
      .filter((ls: LoanStatus) => rowData.loanStatusId !== ls.loanStatusId)
      .map((ls: LoanStatus) => ({
        value: ls.loanStatusId,
        name: ls.loanStatusName,
      }));
    modalRef.componentInstance.itemType = 'loan status';
    modalRef.result.then((assignedLoanStatusId: string) => {
      this.deleteRow(rowData, parseInt(assignedLoanStatusId));
    },
      () => { }
    );
  }

  showOrderDrawer() {
    if (!this.loanStatuses.length) {
      return;
    }

    this.statusesToOrder = this.setLoanStatusItems();
    this._drawerService.show("loanStatusOrderDrawer", 100);
  }

  closeOrderDrawer = () => {
    this._drawerService.hide("loanStatusOrderDrawer", 100);
  }

  onOrderSave(sortedLoanStatuses: Array<EnumerationItem>) {
    this.loanStatusOrder.saveInProgress = true;

    const sortedLoanStatusIds = this.setLoanStatusesInOrder(sortedLoanStatuses);

    this._loanStatusService.updateLoanStatusOrder(sortedLoanStatusIds).subscribe({
      next: () => {
        this.loanStatusTable.sortField = "";
        this.loanStatusTable.sortOrder = 0;
        this.loanStatusTable.reset();

        this.populateLoanStatuses();

        this._notificationService.showSuccess(
          'Loan status order updated successfully',
          'Loan Status'
        );
      },
      error: (err) => {
        this._notificationService.showError(
          err?.message || 'Unable to update loan status order ',
          'Loan Status'
        );
      }
    }).add(() => {
      this.loanStatusOrder.saveInProgress = false;
      this.closeOrderDrawer();
    });
  }

  getRoleNameCsv(roleIds: string): string {
    if (!roleIds) {
      return '';
    }
    const roleIdList = roleIds.split(',');
    return this.roles.filter(role => roleIdList.includes(role.roleId.toString()))
      .map(role => role.roleName)
      .join(',');
  }

  displayEmailSmsRole(roleId: number): string {
    return this.roles.find((role) => role.roleId == roleId)?.roleName || '';
  }

  onChannelFilterChange(selectedChannel: string) {
    this.filterLoanStatusByChannel();
  }

  private filterLoanStatusByChannel(): void {
    if (this.selectedChannelNameForFilter === 'All') {
      this.filteredLoanStatuses = this.sortLoanStatusByOrder(this.loanStatuses);
      return;
    }
    const filteredStatuses = this.getSelectedChannelLoanStatus;
    this.filteredLoanStatuses = this.sortLoanStatusByOrder(filteredStatuses);
  }

  private get getSelectedChannelLoanStatus() {
    return this.loanStatuses.filter((loanStatus: LoanStatus) => loanStatus.enabledChannels &&
      loanStatus.enabledChannels.includes(this.selectedChannelNameForFilter));
  }

  sortLoanStatusByOrder(loanStatus: Array<LoanStatus>, forceSortByOrderColumn: boolean = false): Array<LoanStatus> {
    if (this.loanStatusTable.sortField && !forceSortByOrderColumn) {
      return cloneDeep(loanStatus);
    }
    return orderBy(loanStatus, item => item.order);
  }

  private populateLoanStatuses() {
    this._spinner.show();
    this._loanStatusService.getAllLoanStatuses(this.companyId)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (res: Array<LoanStatus>) => {
          this.loanStatuses = res;
          this.filterLoanStatusByChannel();
        },
        error: (err) => {
          this._notificationService.showError(err?.message || "Couldn't load loan statuses.", 'Loan Status');
        }
      });
  }

  private updateTableAfterUpsert(result: LoanStatus) {
    const index = this.loanStatuses.findIndex((ls: LoanStatus) => ls.loanStatusId === result.loanStatusId);
    if (index === -1) {
      this.loanStatuses.push(result);
    } else {
      this.loanStatuses[index] = result;
    }
    this.filterLoanStatusByChannel();
  }

  private deleteRow(rowData: LoanStatus, assignedLoanStatusId: number) {
    this._loanStatusService.deleteLoanStatus(rowData.loanStatusId, this.companyId, assignedLoanStatusId)
      .subscribe({
        next: (res: LoanStatus) => {
          const index = this.loanStatuses.findIndex((ls: LoanStatus) => ls.loanStatusId === rowData.loanStatusId);
          this.loanStatuses.splice(index, 1);
          this._notificationService.showSuccess('Loan status removed succesfully.', 'Loan Status');
          this.populateLoanStatuses();
        },
        error: (err) => {
          this._notificationService.showError(err?.message || "Couldn't delete loan status", 'Loan Status');
        }
      });
  }

  private setLoanStatusItems(): EnumerationItem[] {
    return chain(this.loanStatuses)
      .filter((ls) => this.selectedChannelNameForFilter === "All" || ls.enabledChannels.includes(this.selectedChannelNameForFilter))
      .orderBy((ls) => ls.order)
      .map((ls) => ({
        name: ls.loanStatusName,
        value: ls.loanStatusId,
        alternateValue: ls.order,
      }))
      .value();
  }

  private setLoanStatusesInOrder(sortedLoanStatuses: EnumerationItem[] = []): Array<{ id: number }> {
    if (this.selectedChannelNameForFilter === "All") {
      return sortedLoanStatuses.map((ls) => ({ id: ls.value }));
    }

    // alternateValue treated as order
    const allowedSortedOrders: number[] = chain(sortedLoanStatuses)
      .orderBy(ls => ls.alternateValue)
      .map(ls => ls.alternateValue)
      .value();

    sortedLoanStatuses.forEach((ls, index) => {
      ls.alternateValue = allowedSortedOrders[index];
    })

    const otherLoanStatuses: EnumerationItem[] = this.loanStatuses
      .filter(ls => ls.enabledChannels.indexOf(this.selectedChannelNameForFilter) === -1)
      .map(ls => ({
        name: ls.loanStatusName,
        value: ls.loanStatusId,
        alternateValue: ls.order,
      }))

    return chain(sortedLoanStatuses.concat(otherLoanStatuses))
      .orderBy(ls => ls.alternateValue)
      .map((ls) => ({ id: ls.value }))
      .value();
  }
}
