import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { createIsRequiredFunction } from '../../urla-utils';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { Extension } from 'src/app/models';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'balloon-details',
  templateUrl: './balloon-details.component.html',
  styleUrls: ['./balloon-details.component.scss']
})
export class BalloonDetailsComponent implements OnInit {

  @Input()
  set extension(extension: Extension) {
    this._extension = cloneDeep(extension);
  }

  get extension(): Extension {
    return this._extension;
  }

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  hasSaveCancelButtons: boolean = true;

  @Input()
  readonly: boolean = false;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApproved: EventEmitter<Extension> = new EventEmitter<Extension>();

  protected isRequired: (fieldName: string) => boolean;

  private _extension: Extension;

  constructor() { }

  ngOnInit(): void {
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);
  }

  onSaveClicked = () => {
    this.changesApproved.emit(this._extension);
  }

}
