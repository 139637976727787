import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdentifyingDocumentsComponent } from './components/identifying-documents/identifying-documents.component';
import { IdentifyingDocumentsTableComponent } from './components/identifying-documents-table/identifying-documents-table.component';
import { TableModule } from 'primeng/table';
import { AddEditIdentifyingDocumentDialogComponent } from './components/add-edit-identifying-document-dialog/add-edit-identifying-document-dialog.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    IdentifyingDocumentsComponent,
    IdentifyingDocumentsTableComponent,
    AddEditIdentifyingDocumentDialogComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    SharedModule
  ],
  exports: [
    IdentifyingDocumentsComponent
  ]
})
export class IdentifyingDocumentsModule { }
