<div class="modal-header">
  <h5 class="modal-title">MI Quotes</h5>
  <button
    type="button"
    class="btn-close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <mi-quote-search
      [interestRate]="interestRate"
      [pricingRequest]="pricingRunRequest"
      (quotedDetailSelected)="onQuoteSelected($event)"
      (quotedDetailSelectedAfterReRun)="onQuotedDetailSelectedAfterReRun($event)"
    ></mi-quote-search>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="activeModal.close(selectedMiQuoteInfo)"
    data-bs-dismiss="modal"
  >
    OK
  </button>
</div>
