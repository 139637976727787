<div class="grid-layout-row">
  <div class="grid-header">
    <div class="grid-header-left">
      <div>Government Loan Details</div>
    </div>
  </div>
  <urla-percentage-input class="col-sm-4 col-xs-4" label="Funding Fee Percent"
    [(ngModel)]="mortgage.governmentLoanDetail.borrowerFundingFeePercent" [name]="'borrowerFundingFeePercent'"
    [readonly]="true" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.borrowerFundingFeePercent'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent']?.required == 'requested'">
  </urla-percentage-input>
</div>
<div class="grid-layout-row">
  <urla-radio-group class="col-sm-4 col-xs-6" [label]="'Prorated Unearned Upfront'" [options]="yesNoOptions"
    [name]="'refundProratedUnearnedUpFront'" [(ngModel)]="mortgage.governmentLoanDetail.refundProratedUnearnedUpFront"
    [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.refundProratedUnearnedUpFront'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront']?.required == 'requested'">
  </urla-radio-group>
  <urla-radio-group class="col-sm-4 col-xs-6" [label]="'Round to nearest 50 Dollars'" [options]="yesNoOptions"
    [name]="'roundToNearest50Dollars'" [(ngModel)]="mortgage.governmentLoanDetail.roundToNearest50Dollars"
    [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.roundToNearest50Dollars'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars']?.required == 'requested'">
  </urla-radio-group>
  <urla-radio-group class="col-sm-4 col-xs-6" [label]="'Charges Included In Loan Payments'" [options]="yesNoOptions"
    [name]="'insuranceChargesIncludedInLoanPayments'"
    [(ngModel)]="mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments" [readonly]="isReadOnly"
    [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments']?.required == 'requested'">
  </urla-radio-group>
</div>
<div class="grid-layout-row">
  <urla-radio-group class="col-sm-4 col-xs-6" [label]="'Charges Collected At Closing'" [options]="yesNoOptions"
    [name]="'insuranceChargesCollectedAtClosing'"
    [(ngModel)]="mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing" [readonly]="isReadOnly"
    [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing']?.required == 'requested'">
  </urla-radio-group>
  <urla-radio-group class="col-sm-4 col-xs-6" [label]="'Lender Paid Mortgage Insurance'" [options]="yesNoOptions"
    [name]="'lenderPaidMortgageInsurance'" [(ngModel)]="mortgage.governmentLoanDetail.lenderPaidMortgageInsurance"
    [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.lenderPaidMortgageInsurance'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance']?.required == 'requested'">
  </urla-radio-group>
  <urla-radio-group class="col-sm-4 col-xs-6" [label]="'Borrower Has Positive History'" [options]="yesNoOptions"
    [name]="'borrowerHasPositiveRentalHistory'"
    [(ngModel)]="mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory" [readonly]="isReadOnly"
    [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory']?.required == 'requested'">
  </urla-radio-group>
</div>
<div class="grid-layout-row">
  <urla-dropdown class="col-sm-4 col-xs-4" label="Section of Act Type"
    [(ngModel)]="mortgage.governmentLoanDetail.sectionOfActType" name="sectionOfActType"
    [options]="governmentLoanSectionOfActTypes" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.governmentLoanDetail.sectionOfActType'"
    [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType']?.required == 'requested'">
  </urla-dropdown>
  <urla-text-input class="col-sm-4 col-xs-4" [label]="'Master Cert. Of Reasonable Value (MCRV) Id'"
    [(ngModel)]="mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId"
    [name]="'masterCertificateOfReasonableValueId'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId']?.required == 'requested'">
  </urla-text-input>
  <urla-date-input class="col-sm-4 col-xs-4" [label]="'MCRV Expiration Date'"
    [(ngModel)]="mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate"
    [name]="'masterCertificateOfReasonableValueExpirationDate'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate'"
    [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate']?.required == 'requested'">
  </urla-date-input>
  <urla-digits-input class="col-sm-4 col-xs-4" [label]="'MCRV Economic Life Years'"
    name="masterCertificateOfReasonableValueEconomicLifeYears"
    [(ngModel)]="mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears"
    [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [allowedNegative]="false"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears'"
    [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears']?.required == 'requested'">
  </urla-digits-input>
  <urla-dropdown class="col-sm-4 col-xs-4" label="Application Type"
    [(ngModel)]="mortgage.governmentLoanDetail.applicationType" name="applicationType" [options]="applicationTypes"
    [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.applicationType'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.applicationType']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.applicationType']?.required == 'requested'">
  </urla-dropdown>
  <urla-dropdown class="col-sm-4 col-xs-4" label="FHA VA Loan Purpose Type"
    [(ngModel)]="mortgage.governmentLoanDetail.fhavaLoanPurposeType" name="fhavaLoanPurposeType"
    [options]="fhavaLoanPurposeTypes" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhavaLoanPurposeType'"
    [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType']?.required == 'requested'">
  </urla-dropdown>
</div>

<ng-container *ngIf="mortgage.mortgageTerm.mortgageAppliedFor === 'FHA' || inEditMode">
  <div class="grid-layout-row">
    <div class="grid-header">
      <div class="grid-header-left">
        <div>FHA Loan Details</div>
      </div>
    </div>
    <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="FHA MI Premium Refund Amount"
      class="col-sm-4 col-xs-4" [name]="'fhaMiPremiumRefundAmount'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount']?.required == 'requested'">
    </urla-currency-input>
    <!-- <urla-percentage-input class="col-sm-4 col-xs-4" label="FHA Annual Premium Percent"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaAnnualPremiumPercent" [name]="'fhaAnnualPremiumPercent'" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaAnnualPremiumPercent'"
      [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAnnualPremiumPercent']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAnnualPremiumPercent']?.required == 'requested'">
    </urla-percentage-input> -->
    <urla-date-input class="col-sm-4 col-xs-4" [label]="'FHA Assignment Date'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaAssignmentDate" [name]="'fhaAssignmentDate'" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaAssignmentDate'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate']?.required == 'requested'">
    </urla-date-input>
    <urla-date-input class="col-sm-4 col-xs-4" [label]="'FHA Endorsement Date'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaEndorsementDate" [name]="'fhaEndorsementDate'"
      [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaEndorsementDate'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate']?.required == 'requested'">
    </urla-date-input>
  </div>
  <div class="grid-layout-row">
    <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="Energy Related Repairs Amount"
      class="col-sm-4 col-xs-4" [name]="'fhaEnergyRelatedRepairsOrImprovementsAmount'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount'"
      [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == 'requested'">
    </urla-currency-input>
    <urla-text-input class="col-sm-4 col-xs-4" [label]="'FHA General Services Administration'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier"
      [name]="'fhaGeneralServicesAdministrationIdentifier'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier'"
      [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier']?.required == 'requested'">
    </urla-text-input>
    <urla-dropdown class="col-sm-4 col-xs-4" label="FHA Insurance Program Type"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaInsuranceProgramType" name="fhaInsuranceProgramType"
      [options]="fhaInsuranceProgramTypes" [readonly]="isReadOnly" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaInsuranceProgramType'"
      [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType']?.required == 'requested'">
    </urla-dropdown>
  </div>
  <div class="grid-layout-row">
    <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly"
      label="Premium Anniversary Year to Date Remittance" class="col-sm-4 col-xs-4"
      [name]="'fhaPremiumAnniversaryYearToDateRemittanceAmount'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount'"
      [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount']?.required == 'requested'">
    </urla-currency-input>
    <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="Refinance Interest on Existing Lien"
      class="col-sm-4 col-xs-4" [name]="'fhaRefinanceInterestOnExistingLienAmount'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount'"
      [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount']?.required == 'requested'">
    </urla-currency-input>
    <urla-text-input class="col-sm-4 col-xs-4" [label]="'Refinance Existing FHA Case'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier"
      [name]="'fhaRefinanceOriginalExistingFhaCaseIdentifier'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier'"
      [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier']?.required == 'requested'">
    </urla-text-input>
  </div>
  <div class="grid-layout-row">
    <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="MI Premium Amount"
      class="col-sm-4 col-xs-4" [name]="'fhaRefinanceOriginalExistingUpfrontMIPremiumAmount'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount'"
      [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount']?.required == 'requested'">
    </urla-currency-input>
    <urla-percentage-input class="col-sm-4 col-xs-4" label="MI Renewal Rate Percent"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaMIRenewalRatePercent" [name]="'fhaMIRenewalRatePercent'"
      [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaMIRenewalRatePercent'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent']?.required == 'requested'">
    </urla-percentage-input>
    <urla-dropdown class="col-sm-4 col-xs-4" label="FHA Alimony Liability Treatment Type"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType"
      name="fhaAlimonyLiabilityTreatmentType" [options]="fhaAlimonyLiabilityTreatmentTypes" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType']?.required == 'requested'">
    </urla-dropdown>
    <urla-dropdown class="col-sm-4 col-xs-4" label="Energy Related Improvement Type"
      [(ngModel)]="mortgage.governmentLoanDetail.energyRelatedImprovementType" name="energyRelatedImprovementType"
      [options]="energyRelatedImprovementTypes" [readonly]="isReadOnly" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.energyRelatedImprovementType'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == 'requested'">
    </urla-dropdown>
    <urla-text-input *ngIf="mortgage.governmentLoanDetail.energyRelatedImprovementType == 'Other'"
      class="col-sm-4 col-xs-4" [label]="'Energy Related Improvement Type Other Descr.'"
      [(ngModel)]="mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription"
      [name]="'energyRelatedImprovementTypeOtherDescription'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == 'requested'">
    </urla-text-input>
    <urla-text-input class="col-sm-4 col-xs-4" [label]="'U/W Computerized Homes U/W System (CHUMS) Id'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier"
      [name]="'fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier'" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier'"
      [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier']?.required == 'requested'">
    </urla-text-input>
    <urla-text-input class="col-sm-4 col-xs-4" [label]="'Appraisal Reviewer CHUMS Id'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier"
      [name]="'fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier'" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier'"
      [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier']?.required == 'requested'">
    </urla-text-input>
    <urla-dropdown class="col-sm-4 col-xs-4" label="Direct Endorsement Approval Type"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType"
      name="fhaDirectEndorsementApprovalType" [options]="fhaDirectEndorsementApprovalTypes" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType']?.required == 'requested'">
    </urla-dropdown>
    <urla-text-input class="col-sm-4 col-xs-4" [label]="'FHA Limited Denial Participation Id'"
      [(ngModel)]="mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier"
      [name]="'fhaLimitedDenialParticipationIdentifier'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier'"
      [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier']?.required == 'requested'">
    </urla-text-input>
  </div>
</ng-container>

<div class="grid-layout-row" *ngIf="mortgage.mortgageTerm.mortgageAppliedFor === 'VA' || inEditMode">
  <div class="grid-header">
    <div class="grid-header-left">
      <div>VA Loan Details</div>
    </div>
  </div>
  <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="VA Entitlement Amount"
    class="col-sm-4 col-xs-4" [name]="'vaEntitlementAmount'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaEntitlementAmount" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaEntitlementAmount'"
    [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementAmount']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementAmount']?.required == 'requested'">
  </urla-currency-input>
  <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="Residual Income Amount"
    class="col-sm-4 col-xs-4" [name]="'vaResidualIncomeAmount'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaResidualIncomeAmount" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaResidualIncomeAmount'"
    [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount']?.required == 'requested'">
  </urla-currency-input>
  <urla-text-input class="col-sm-4 col-xs-4" [label]="'VA Entitlement ID'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaEntitlementIdentifier" [name]="'vaEntitlementIdentifier'"
    [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaEntitlementIdentifier'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier']?.required == 'requested'">
  </urla-text-input>
  <urla-boolean-checkbox class="col-sm-4 col-xs-4 negative-margin-5" *ngIf="mortgage.borrowers?.length > 1"
    layout="single" [label]="'Borrower/Co-Borrower are Married'" [name]="'isPrimaryBorrowerMarriedToCoBorrower'"
    [(ngModel)]="mortgage.ausData.isPrimaryBorrowerMarriedToCoBorrower">
  </urla-boolean-checkbox>
  <urla-boolean-checkbox class="col-sm-4 col-xs-4 negative-margin-5" layout="single"
    [label]="'VA Staff has Appraisal Review Valuation'" [name]="'hasVAStaffAppraisalReviewValuationAdjustment'"
    [(ngModel)]="mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment"
    (ngModelChange)="onVaStuffReviewChanged()" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment'"
    [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment']?.required == 'required' ||
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment']?.required == 'requested'">
  </urla-boolean-checkbox>
  <urla-text-input class="col-sm-3 col-xs-3" [label]="'VA Staff Appraisal Reviewer ID'"
    *ngIf="mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment"
    [(ngModel)]="mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier"
    [name]="'vaStaffAppraisalReviewerIdentifier'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier'"
    [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier']?.required == 'required' ||
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier']?.required == 'requested'">
  </urla-text-input>
  <urla-date-input class="col-sm-3 col-xs-3" [label]="'VA Staff Appr. Review Notif. Date'"
    *ngIf="mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment"
    [(ngModel)]="mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate"
    [name]="'vaStaffAppraisalReviewValueNotificationDate'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate'"
    [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate']?.required == 'required' ||
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate']?.required == 'requested'">
  </urla-date-input>
  <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="VA Residual Income Guideline Amount"
    class="col-sm-4 col-xs-4" [name]="'vaResidualIncomeGuidelineAmount'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount']?.required == 'requested'">
  </urla-currency-input>
  <urla-dropdown class="col-sm-4 col-xs-4" label="VA Appraisal Type"
    [(ngModel)]="mortgage.governmentLoanDetail.vaAppraisalType" name="vaAppraisalType" [options]="vaAppraisalTypes"
    [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaAppraisalType'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType']?.required == 'requested'">
  </urla-dropdown>
  <urla-text-input *ngIf="mortgage.governmentLoanDetail.vaAppraisalType == 'Other'" class="col-sm-4 col-xs-4"
    [label]="'VA Appraisal Type Other Description'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaAppraisalTypeOtherDescription"
    [name]="'vaAppraisalTypeOtherDescription'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaAppraisalTypeOtherDescription'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType']?.required == 'requested'">
  </urla-text-input>
  <urla-dropdown class="col-sm-4 col-xs-4" label="VA Procedure Type"
    [(ngModel)]="mortgage.governmentLoanDetail.vaLoanProcedureType" name="vaLoanProcedureType"
    [options]="vaLoanProcedureTypes" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaLoanProcedureType'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType']?.required == 'requested'">
  </urla-dropdown>
  <urla-text-input *ngIf="mortgage.governmentLoanDetail.vaLoanProcedureType == 'Other'" class="col-sm-4 col-xs-4"
    [label]="'VA Procedure Type Other Description'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaLoanProcedureTypeOtherDescription"
    [name]="'vaLoanProcedureTypeOtherDescription'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaLoanProcedureTypeOtherDescription'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType']?.required == 'requested'">
  </urla-text-input>
  <urla-dropdown class="col-sm-4 col-xs-4" label="VA Title Vesting Type"
    [(ngModel)]="mortgage.governmentLoanDetail.vaTitleVestingType" name="vaTitleVestingType"
    [options]="vaTitleVestingTypes" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaTitleVestingType'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType']?.required == 'requested'">
  </urla-dropdown>
  <urla-text-input *ngIf="mortgage.governmentLoanDetail.vaTitleVestingType == 'Other'" class="col-sm-4 col-xs-4"
    [label]="'VA Title Vesting Type Other Description'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaTitleVestingTypeOtherDescription"
    [name]="'vaTitleVestingTypeOtherDescription'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaTitleVestingTypeOtherDescription'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType']?.required == 'requested'">
  </urla-text-input>
  <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="Energy Related Repairs Amount"
    class="col-sm-4 col-xs-4" [name]="'vaEnergyRelatedRepairsOrImprovementsAmount'"
    [(ngModel)]="mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaEnergyRelatedRepairsOrImprovementsAmount'"
    [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == 'requested'">
  </urla-currency-input>
  <urla-dropdown class="col-sm-4 col-xs-4" label="Energy Related Improvement Type"
    [(ngModel)]="mortgage.governmentLoanDetail.energyRelatedImprovementType" name="vaEnergyRelatedImprovementType"
    [options]="energyRelatedImprovementTypes" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.energyRelatedImprovementType'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == 'requested'">
  </urla-dropdown>
  <urla-text-input *ngIf="mortgage.governmentLoanDetail.energyRelatedImprovementType == 'Other'"
    class="col-sm-4 col-xs-4" [label]="'Energy Related Improvement Type Other Descr.'"
    [(ngModel)]="mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription"
    [name]="'vaEnergyRelatedImprovementTypeOtherDescription'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == 'requested'">
  </urla-text-input>
  <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="VA Maintenance Expense Monthly Amount"
    class="col-sm-4 col-xs-4" [name]="'vaMaintenanceExpenseMonthlyAmount'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount']?.required == 'requested'">
  </urla-currency-input>
  <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="VA Utility Expense Monthly Amount"
    class="col-sm-4 col-xs-4" [name]="'vaUtilityExpenseMonthlyAmount'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount']?.required == 'requested'">
  </urla-currency-input>
  <urla-text-input class="col-sm-4 col-xs-4" [label]="'VA Examiner Comments'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaExaminerComments" [name]="'vaExaminerComments'" [readonly]="isReadOnly"
    [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaExaminerComments'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments']?.required == 'requested'">
  </urla-text-input>
  <urla-text-input class="col-sm-4 col-xs-4" [label]="'VA Claim Folder Id'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaClaimFolderIdentifier" [name]="'vaClaimFolderIdentifier'"
    [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaClaimFolderIdentifier'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier']?.required == 'requested'">
  </urla-text-input>
  <urla-radio-group class="col-sm-4 col-xs-6" [label]="'Loan Meets VA Credit Standards'" [options]="yesNoOptions"
    [name]="'loanMeetsVACreditStandards'" [(ngModel)]="mortgage.governmentLoanDetail.loanMeetsVACreditStandards"
    [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.loanMeetsVACreditStandards'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards']?.required == 'requested'">
  </urla-radio-group>
  <urla-text-input class="col-sm-4 col-xs-4" [label]="'VA Credit Standards Decision Remarks'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks"
    [name]="'vaCreditStandardsDecisionRemarks'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks']?.required == 'requested'">
  </urla-text-input>

  <urla-dropdown class="col-sm-4 col-xs-4" label="VA Cash Out Refinance Type"
    [(ngModel)]="mortgage.governmentLoanDetail.vaCashOutRefinanceType" name="vaCashOutRefinanceType"
    [options]="vaCashOutRefinanceTypes" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaCashOutRefinanceType'"
    [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaCashOutRefinanceType']?.required == 'required' ||
      urlaFieldsConfig['mortgage.governmentLoanDetail.vaCashOutRefinanceType']?.required == 'requested'">
  </urla-dropdown>
</div>
<div class="grid-layout-row" *ngIf="mortgage.mortgageTerm.mortgageAppliedFor === 'VA' || inEditMode">
  <div class="grid-header">
    <div class="grid-header-left">
      <div>VA Previous Loan Info</div>
    </div>
  </div>

  <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="Original Loan Amount"
    class="col-sm-3 col-xs-3" [name]="'vaOriginalLoanAmount'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaOriginalLoanAmount" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaOriginalLoanAmount'"
    [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount']?.required == 'requested'">
  </urla-currency-input>
  <urla-digits-input class="col-sm-2 col-xs-2" [label]="'Original Loan Term'" name="vaOriginalLoanTerm"
    [(ngModel)]="mortgage.governmentLoanDetail.vaOriginalLoanTerm" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig" [allowedNegative]="false"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaOriginalLoanTerm'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm']?.required == 'requested'">
  </urla-digits-input>
  <urla-percentage-input class="col-sm-2 col-xs-2" label="Original Interest Rate"
    [(ngModel)]="mortgage.governmentLoanDetail.vaOriginalInterestRate" [name]="'vaOriginalInterestRate'"
    [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaOriginalInterestRate'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate']?.required == 'requested'">
  </urla-percentage-input>
  <urla-date-input class="col-sm-2 col-xs-2" [label]="'Existing Loan Date'" name="vaExistingLoanDate"
    [(ngModel)]="mortgage.governmentLoanDetail.vaExistingLoanDate" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig" [allowedNegative]="false"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaExistingLoanDate'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanDate']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanDate']?.required == 'requested'">
  </urla-date-input>
  <urla-date-input class="col-sm-3 col-xs-3" [label]="'Existing First Payment Date'" name="vaExistingFirstPaymentDate"
    [(ngModel)]="mortgage.governmentLoanDetail.vaExistingFirstPaymentDate" [readonly]="isReadOnly" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig" [allowedNegative]="false"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaExistingFirstPaymentDate'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingFirstPaymentDate']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingFirstPaymentDate']?.required == 'requested'">
  </urla-date-input>
</div>
<div class="grid-layout-row">
  <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="Existing Loan Balance"
    class="col-sm-3 col-xs-3" [name]="'vaExistingLoanBalance'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaExistingLoanBalance" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaExistingLoanBalance'"
    [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance']?.required == 'required' || urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance']?.required == 'requested'">
  </urla-currency-input>
  <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="Existing Payment (PI)"
    class="col-sm-2 col-xs-3" [name]="'vaExistingPrincipalAndInterest'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig" (blur)="reCalculateNTB()"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest'" [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest']?.required == 'required' ||
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest']?.required == 'requested'">
  </urla-currency-input>

  <div class="grid-layout-spacer col-sm-4 col-xs-1"></div>
  <urla-currency-input [allowNegative]="false" [readonly]="isReadOnly" label="Existing Reasonable Appraised Value"
    class="col-sm-3 col-xs-3" [name]="'vaExistingReasonableAppraisedValue'"
    [(ngModel)]="mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue" [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [fieldBeingEdited]="'mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue'"
    [required]="urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue']?.required == 'required' || urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue']?.required == 'requested'">
  </urla-currency-input>
</div>
<div class="grid-layout-row" *ngIf="mortgage.mortgageTerm.mortgageAppliedFor === 'VA' || inEditMode">
  <div class="grid-header mb-1">
    <div class="grid-header-left">
      <div>VA Recoupment - <span class="me-2" [ngClass]="{'text-success': meetsNtb, 'text-danger': !meetsNtb}">
          {{ meetsNtb ? 'Meets NTB' : 'Does NOT Meet NTB' }}
        </span></div>
    </div>
    <div class="grid-header-right">
      <span class="me-2" [ngClass]="{'text-success': meetsNtb, 'text-danger': !meetsNtb}" *ngIf="!inEditMode">
        <i class="fa" [ngClass]="{'fa-check': meetsNtb, 'fa-times-circle': !meetsNtb}"></i>
        Payment Diff of {{paymentDiff | currency }} recoups closing costs of {{vaEligibleClosingCosts| currency}} in
        {{recoupMonths | number : '0.2-2'}} months
      </span>
    </div>
  </div>
</div>