export enum FeeSectionType {
  Origination = 'Origination',
  ServicesNoShop = 'ServicesNoShop',
  Services = 'Services',
  GovernmentTaxesAndFees = 'GovernmentTaxesAndFees',
  Prepaids = 'Prepaids',
  Escrow = 'Escrow',
  Other = 'Other',
}

export enum FeeType {
  CityPropertyTaxes = 'CityPropertyTaxes',
  CityPropertyTaxesReserve = 'CityPropertyTaxesReserve',
  CountyPropertyTaxes = 'CountyPropertyTaxes',
  CountyPropertyTaxesReserve = 'CountyPropertyTaxesReserve',
  MudTax = 'MudTax',
  MudTaxReserve = 'MudTaxReserve',
  VillageTownSchoolTax = 'VillageTownSchoolTax',
  VillageTownSchoolTaxReserve = 'VillageTownSchoolTaxReserve',
  HomeownersInsurance = 'HomeownersInsurance',
  HomeownersInsuranceReserve = 'HomeownersInsuranceReserve',
  MortgageInsurance = 'MortgageInsurance',
  MortgageInsuranceReserve = 'MortgageInsuranceReserve',
  FloodInsurance = 'FloodInsurance',
  WindHailInsurance = 'WindHailInsurance',
  HoaDues = 'HoaDues',
  AggregateAccountingAdjustment = 'AggregateAccountingAdjustment',
  OddDaysInterest = 'OddDaysInterest',
  BuyerAgentRealEstateCommission = 'BuyerAgentRealEstateCommission',
  SellerAgentRealEstateCommission = 'SellerAgentRealEstateCommission',
  RealEstateCommissionTotal = 'RealEstateCommissionTotal',
  Ufmip = 'Ufmip',
  VaFundingFee = 'VaFundingFee',
  DiscountFee = 'DiscountFee',
  UsdaGuaranteeFee = 'UsdaGuaranteeFee',
  BoroughPropertyTax = 'BoroughPropertyTax',
  BoroughPropertyTaxReserve = 'BoroughPropertyTaxReserve',
  CondominiumAssociationDues = 'CondominiumAssociationDues',
  CondominiumAssociationDuesReserve = 'CondominiumAssociationDuesReserve',
  CondominiumAssociationSpecialAssessment = 'CondominiumAssociationSpecialAssessment',
  CondominiumAssociationSpecialAssessmentReserve = 'CondominiumAssociationSpecialAssessmentReserve',
  CooperativeAssociationDues = 'CooperativeAssociationDues',
  CooperativeAssociationDuesReserve = 'CooperativeAssociationDuesReserve',
  CooperativeAssociationSpecialAssessment = 'CooperativeAssociationSpecialAssessment',
  CooperativeAssociationSpecialAssessmentReserve = 'CooperativeAssociationSpecialAssessmentReserve',
  DistrictPropertyTax = 'DistrictPropertyTax',
  DistrictPropertyTaxReserve = 'DistrictPropertyTaxReserve',
  EarthquakeInsurancePremium = 'EarthquakeInsurancePremium',
  EarthquakeInsurancePremiumReserve = 'EarthquakeInsurancePremiumReserve',
  FloodInsurancePremium = 'FloodInsurancePremium',
  FloodInsurancePremiumReserve = 'FloodInsurancePremiumReserve',
  HailInsurancePremium = 'HailInsurancePremium',
  HailInsurancePremiumReserve = 'HailInsurancePremiumReserve',
  HazardInsurancePremium = 'HazardInsurancePremium',
  HazardInsurancePremiumReserve = 'HazardInsurancePremiumReserve',
  HomeownersAssociationDues = 'HomeownersAssociationDues',
  HomeownersAssociationDuesReserve = 'HomeownersAssociationDuesReserve',
  HomeownersAssociationSpecialAssessment = 'HomeownersAssociationSpecialAssessment',
  HomeownersAssociationSpecialAssessmentReserve = 'HomeownersAssociationSpecialAssessmentReserve',
  SchoolPropertyTax = 'SchoolPropertyTax',
  SchoolPropertyTaxReserve = 'SchoolPropertyTaxReserve',
  StatePropertyTax = 'StatePropertyTax',
  StatePropertyTaxReserve = 'StatePropertyTaxReserve',
  TownPropertyTax = 'TownPropertyTax',
  TownPropertyTaxReserve = 'TownPropertyTaxReserve',
  VillagePropertyTax = 'VillagePropertyTax',
  VillagePropertyTaxReserve = 'VillagePropertyTaxReserve',
  VolcanoInsurancePremium = 'VolcanoInsurancePremium',
  VolcanoInsurancePremiumReserve = 'VolcanoInsurancePremiumReserve',
  WindAndStormInsurancePremium = 'WindAndStormInsurancePremium',
  WindAndStormInsurancePremiumReserve = 'WindAndStormInsurancePremiumReserve',
  ApplicationFee = 'ApplicationFee',
  AppraisalDeskReviewFee = 'AppraisalDeskReviewFee',
  AppraisalFee = 'AppraisalFee',
  AppraisalFieldReviewFee = 'AppraisalFieldReviewFee',
  AppraisalManagementCompanyFee = 'AppraisalManagementCompanyFee',
  AsbestosInspectionFee = 'AsbestosInspectionFee',
  AssignmentPreparationFee = 'AssignmentPreparationFee',
  AssumptionFee = 'AssumptionFee',
  AttorneyFee = 'AttorneyFee',
  AutomatedUnderwritingFee = 'AutomatedUnderwritingFee',
  AVMFee = 'AVMFee',
  BankruptcyMonitoringFee = 'BankruptcyMonitoringFee',
  BondFee = 'BondFee',
  BondReviewFee = 'BondReviewFee',
  CertificationFee = 'CertificationFee',
  ChosenInterestRateCreditOrChargeTotal = 'ChosenInterestRateCreditOrChargeTotal',
  CommitmentFee = 'CommitmentFee',
  CopyOrFaxFee = 'CopyOrFaxFee',
  CourierFee = 'CourierFee',
  CreditDisabilityInsurancePremium = 'CreditDisabilityInsurancePremium',
  CreditLifeInsurancePremium = 'CreditLifeInsurancePremium',
  CreditPropertyInsurancePremium = 'CreditPropertyInsurancePremium',
  CreditReportFee = 'CreditReportFee',
  CreditUnemploymentInsurancePremium = 'CreditUnemploymentInsurancePremium',
  DebtCancellationInsurancePremium = 'DebtCancellationInsurancePremium',
  DebtSuspensionInsurancePremium = 'DebtSuspensionInsurancePremium',
  DeedPreparationFee = 'DeedPreparationFee',
  DisasterInspectionFee = 'DisasterInspectionFee',
  DiscountOnRepairsFee = 'DiscountOnRepairsFee',
  DocumentaryStampFee = 'DocumentaryStampFee',
  DocumentPreparationFee = 'DocumentPreparationFee',
  DownPaymentProtectionFee = 'DownPaymentProtectionFee',
  DryWallInspectionFee = 'DryWallInspectionFee',
  ElectricalInspectionFee = 'ElectricalInspectionFee',
  ElectronicDocumentDeliveryFee = 'ElectronicDocumentDeliveryFee',
  EnvironmentalInspectionFee = 'EnvironmentalInspectionFee',
  EscrowHoldbackFee = 'EscrowHoldbackFee',
  EscrowServiceFee = 'EscrowServiceFee',
  EscrowWaiverFee = 'EscrowWaiverFee',
  FilingFee = 'FilingFee',
  FloodCertification = 'FloodCertification',
  FoundationInspectionFee = 'FoundationInspectionFee',
  HeatingCoolingInspectionFee = 'HeatingCoolingInspectionFee',
  HighCostMortgageCounselingFee = 'HighCostMortgageCounselingFee',
  HomeInspectionFee = 'HomeInspectionFee',
  HomeownersAssociationServiceFee = 'HomeownersAssociationServiceFee',
  HomeWarrantyFee = 'HomeWarrantyFee',
  Item203KConsultantFee = 'Item203KConsultantFee',
  Item203KDiscountOnRepairs = 'Item203KDiscountOnRepairs',
  Item203KInspectionFee = 'Item203KInspectionFee',
  Item203KPermits = 'Item203KPermits',
  Item203KSupplementalOriginationFee = 'Item203KSupplementalOriginationFee',
  Item203KTitleUpdate = 'Item203KTitleUpdate',
  LeadInspectionFee = 'LeadInspectionFee',
  LendersAttorneyFee = 'LendersAttorneyFee',
  LoanDiscountPoints = 'LoanDiscountPoints',
  LoanLevelPriceAdjustment = 'LoanLevelPriceAdjustment',
  LoanOriginationFee = 'LoanOriginationFee',
  LoanOriginatorCompensation = 'LoanOriginatorCompensation',
  ManualUnderwritingFee = 'ManualUnderwritingFee',
  ManufacturedHousingInspectionFee = 'ManufacturedHousingInspectionFee',
  ManufacturedHousingProcessingFee = 'ManufacturedHousingProcessingFee',
  MERSRegistrationFee = 'MERSRegistrationFee',
  ModificationFee = 'ModificationFee',
  MoldInspectionFee = 'MoldInspectionFee',
  MortgageBrokerFee = 'MortgageBrokerFee',
  MortgageSurchargeCountyOrParish = 'MortgageSurchargeCountyOrParish',
  MortgageSurchargeMunicipal = 'MortgageSurchargeMunicipal',
  MortgageSurchargeState = 'MortgageSurchargeState',
  MortgageTaxCreditServiceFee = 'MortgageTaxCreditServiceFee',
  MultipleLoansClosingFee = 'MultipleLoansClosingFee',
  MunicipalLienCertificateFee = 'MunicipalLienCertificateFee',
  NotaryFee = 'NotaryFee',
  OurOriginationChargeTotal = 'OurOriginationChargeTotal',
  PayoffRequestFee = 'PayoffRequestFee',
  PestInspectionFee = 'PestInspectionFee',
  PlumbingInspectionFee = 'PlumbingInspectionFee',
  PowerOfAttorneyPreparationFee = 'PowerOfAttorneyPreparationFee',
  PowerOfAttorneyRecordingFee = 'PowerOfAttorneyRecordingFee',
  PreclosingVerificationControlFee = 'PreclosingVerificationControlFee',
  ProcessingFee = 'ProcessingFee',
  ProgramGuaranteeFee = 'ProgramGuaranteeFee',
  PropertyInspectionWaiverFee = 'PropertyInspectionWaiverFee',
  RadonInspectionFee = 'RadonInspectionFee',
  RateLockFee = 'RateLockFee',
  RealEstateCommissionBuyersBroker = 'RealEstateCommissionBuyersBroker',
  RealEstateCommissionSellersBroker = 'RealEstateCommissionSellersBroker',
  ReconveyanceFee = 'ReconveyanceFee',
  ReconveyanceTrackingFee = 'ReconveyanceTrackingFee',
  RecordingFeeForAssignment = 'RecordingFeeForAssignment',
  RecordingFeeForDeed = 'RecordingFeeForDeed',
  RecordingFeeForMortgage = 'RecordingFeeForMortgage',
  RecordingFeeForMunicipalLienCertificate = 'RecordingFeeForMunicipalLienCertificate',
  RecordingFeeForOtherDocument = 'RecordingFeeForOtherDocument',
  RecordingFeeForRelease = 'RecordingFeeForRelease',
  RecordingFeeForSubordination = 'RecordingFeeForSubordination',
  RecordingFeeTotal = 'RecordingFeeTotal',
  RecordingServiceFee = 'RecordingServiceFee',
  RedrawFee = 'RedrawFee',
  ReinspectionFee = 'ReinspectionFee',
  RenovationConsultantFee = 'RenovationConsultantFee',
  RepairsFee = 'RepairsFee',
  RoofInspectionFee = 'RoofInspectionFee',
  SepticInspectionFee = 'SepticInspectionFee',
  SettlementFee = 'SettlementFee',
  SettlementOrClosingFeesTotal = 'SettlementOrClosingFeesTotal',
  SigningAgentFee = 'SigningAgentFee',
  SmokeDetectorInspectionFee = 'SmokeDetectorInspectionFee',
  StateTitleInsuranceFee = 'StateTitleInsuranceFee',
  StructuralInspectionFee = 'StructuralInspectionFee',
  SubordinationFee = 'SubordinationFee',
  SurveyFee = 'SurveyFee',
  TaxServiceFee = 'TaxServiceFee',
  TaxStampForCityDeed = 'TaxStampForCityDeed',
  TaxStampForCityMortgage = 'TaxStampForCityMortgage',
  TaxStampForCountyDeed = 'TaxStampForCountyDeed',
  TaxStampForCountyMortgage = 'TaxStampForCountyMortgage',
  TaxStampForStateDeed = 'TaxStampForStateDeed',
  TaxStampForStateMortgage = 'TaxStampForStateMortgage',
  TaxStatusResearchFee = 'TaxStatusResearchFee',
  TemporaryBuydownAdministrationFee = 'TemporaryBuydownAdministrationFee',
  TemporaryBuydownPoints = 'TemporaryBuydownPoints',
  TitleAbstractFee = 'TitleAbstractFee',
  TitleBorrowerClosingProtectionLetterFee = 'TitleBorrowerClosingProtectionLetterFee',
  TitleCertificationFee = 'TitleCertificationFee',
  TitleClosingCoordinationFee = 'TitleClosingCoordinationFee',
  TitleClosingFee = 'TitleClosingFee',
  TitleClosingProtectionLetterFee = 'TitleClosingProtectionLetterFee',
  TitleDocumentPreparationFee = 'TitleDocumentPreparationFee',
  TitleEndorsementFee = 'TitleEndorsementFee',
  TitleExaminationFee = 'TitleExaminationFee',
  TitleFinalPolicyShortFormFee = 'TitleFinalPolicyShortFormFee',
  TitleInsuranceBinderFee = 'TitleInsuranceBinderFee',
  TitleInsuranceFee = 'TitleInsuranceFee',
  TitleLendersCoveragePremium = 'TitleLendersCoveragePremium',
  TitleNotaryFee = 'TitleNotaryFee',
  TitleOwnersCoveragePremium = 'TitleOwnersCoveragePremium',
  TitleServicesFeeTotal = 'TitleServicesFeeTotal',
  TitleServicesSalesTax = 'TitleServicesSalesTax',
  TitleUnderwritingIssueResolutionFee = 'TitleUnderwritingIssueResolutionFee',
  TransferTaxTotal = 'TransferTaxTotal',
  UnderwritingFee = 'UnderwritingFee',
  VerificationOfAssetsFee = 'VerificationOfAssetsFee',
  VerificationOfEmploymentFee = 'VerificationOfEmploymentFee',
  VerificationOfIncomeFee = 'VerificationOfIncomeFee',
  VerificationOfResidencyStatusFee = 'VerificationOfResidencyStatusFee',
  VerificationOfTaxpayerIdentificationFee = 'VerificationOfTaxpayerIdentificationFee',
  VerificationOfTaxReturnFee = 'VerificationOfTaxReturnFee',
  WaterTestingFee = 'WaterTestingFee',
  WellInspectionFee = 'WellInspectionFee',
  WireTransferFee = 'WireTransferFee',
  TaxStampCityCountyTotal = 'TaxStampCityCountyTotal',
  TaxStampStateTotal = 'TaxStampStateTotal',
}

export enum FeeSource {
  Manual = 'Manual',
  Template = 'Template',
  Ernst = 'Ernst',
  Los = 'Los',
  FeeIntegration = 'FeeIntegration',
  Loan = 'Loan',
  Permanent = 'Permanent',
  ImportedFile = 'ImportedFile',
}

export enum ThirdPartyIntegrationProvider {
  Connexions = 'Connexions',
  DocMagic = 'DocMagic',
  Ernst = 'Ernst',
  StatesTitle = 'StatesTitle',
  MicrosoftGraph = 'MicrosoftGraph',
  Ayrshare = 'Ayrshare',
  Sisense = 'Sisense',
  FormFree = 'FormFree',
  DocuSign = 'DocuSign',
  MeridianLink = 'MeridianLink',
  TurboInsurance = 'TurboInsurance',
  FannieMae = 'FannieMae',
  FreddieMac = 'FreddieMac',
  Equifax = 'Equifax',
  RockConnection = 'RockConnection',
  Halycon = 'Halycon',
  OpenAI = 'OpenAI',
  AmazonWebServices = 'AmazonWebServices',
  Mers = 'Mers',
  LenderPrice = 'LenderPrice',
  OptimalBlue = 'OptimalBlue',
  Lodestar = 'Lodestar',
  Encompass = 'Encompass',
  MortgageDirector = 'MortgageDirector',
  Xactus = 'Xactus',
  Reggora = 'Reggora',
  Polly = 'Polly',
  MortgageCTO = 'MortgageCTO',
  Dda = 'Dda',
  Visionet = 'Visionet',
  LoanPass = 'LoanPass',
  PmiRatePro = 'PmiRatePro',
  ISoftPull = 'ISoftPull',
  LoanPassIframe = 'LoanPassIframe',
  LenderPriceIframe = 'LenderPriceIframe',
  SoftPro = 'SoftPro',
  Custom = 'Custom'
}

export enum CalculatedFeeType {
  Default = 'Default',
  PropertyTaxUpFrontMonthlyPremium = 'PropertyTaxUpFrontMonthlyPremium',
  OddDaysInterest = 'OddDaysInterest',
  RealEstateCommission = 'RealEstateCommission',
  UpFrontMonthlyPremium = 'UpFrontMonthlyPremium',
  MonthlyPaymentPremium = 'MonthlyPaymentPremium',
  HazardInsurance = 'HazardInsurance',
}

export enum FeeProrationType {
  Charge = 'Charge',
  Credit = 'Credit',
}

export enum FeePercentOfField {
  LoanAmount = 'LoanAmount',
  TotalLoanAmount = 'TotalLoanAmount',
  PropertyAppraisedValueAmount = 'PropertyAppraisedValueAmount',
  PurchasePriceAmount = 'PurchasePriceAmount',
}

export enum FeePaidTo {
  Broker = 'Broker',
  BrokerAffiliate = 'BrokerAffiliate',
  Investor = 'Investor',
  Lender = 'Lender',
  LenderAffiliate = 'LenderAffiliate',
  Other = 'Other',
  ThirdPartyProvider = 'ThirdPartyProvider',
  Seller = 'Seller',
}

export enum EscrowPaymentFrequency {
  Annual = 'Annual',
  Monthly = 'Monthly',
  Other = 'Other',
  Quarterly = 'Quarterly',
  SemiAnnual = 'SemiAnnual',
  Unequal = 'Unequal',
}

export enum EscrowPremiumPaymentType {
  CollectAtClosing = 'CollectAtClosing',
  PaidOutsideOfClosing = 'PaidOutsideOfClosing',
  Waived = 'Waived',
}

export interface LoanFeeEscrowDisbursement {
  amount?: number;
  disbursementDate?: string;
  disbursedDate?: string;
}

export interface FeeCalculatedValues {
  isChargeUserEditable?: boolean;
  calculatedFeeType?: CalculatedFeeType;
  /** @format double */
  totalFee?: number | null;
  /** @format double */
  totalFeePercent?: number | null;
  /** @format double */
  borrowerTotal?: number | null;
  /** @format double */
  lenderTotal?: number | null;
  /** @format double */
  sellerTotal?: number | null;
  /** @format double */
  thirdPartyTotal?: number | null;
  /** @format double */
  borrowerPaidAtClosing?: number | null;
  /** @format double */
  lenderPaidAtClosing?: number | null;
  /** @format double */
  sellerPaidAtClosing?: number | null;
  /** @format double */
  thirdPartyPaidAtClosing?: number | null;
  /** @format double */
  totalPaidAtClosing?: number | null;
  /** @format double */
  prorationAmount?: number | null;
  prorationType?: FeeProrationType;
  /** @format date-time */
  prorationStartDate?: string | null;
  /** @format date-time */
  prorationEndDate?: string | null;
  /** @format double */
  percentOfAmount?: number | null;
  /** @format double */
  sellerConcessions?: number | null;

  // FIXME: These fields are not in the API model, but they are modified within the UI and are
  //        seemingly used for calculations in the backend. They also get persisted in the database.
  /** @format int32 */
  months?: number | null;
  /** @format double */
  monthlyFee?: number | null;
  /** @format int32 */
  oddDays?: number | null;
  /** @format date-time */
  estimatedClosingDate?: string | null;
  /** @format date-time */
  paidThroughDate?: string | null;
  calculationMethod?: PerDiemCalculationMethodType;
}

export enum PerDiemCalculationMethodType {
  Item360 = 'Item360',
  Item365 = 'Item365',
  Item365Or366 = 'Item365Or366',
}

export interface LoanFee {
  /** @format int32 */
  loanFeeId?: number;
  /** @format int32 */
  applicationId?: number | null;
  /** @format int32 */
  pricingScenarioId?: number | null;
  /** @format int32 */
  externalContactId?: number | null;
  hudNumber?: string | null;
  name?: string | null;
  feeSection?: FeeSectionType;
  feeType?: FeeType;
  feeTypeOtherDescription?: string | null;
  feeSource?: FeeSource;
  feeSourceIntegration?: ThirdPartyIntegrationProvider;
  latestFeeValueSource?: FeeSource;
  /** @format int32 */
  sumInHudNumber?: number | null;
  isSummaryFee?: boolean;
  isImpound?: boolean | null;
  isChargeUserEditable?: boolean;
  /** @format date-time */
  effectiveDate?: string | null;
  escrowDisbursements?: LoanFeeEscrowDisbursement[] | null;
  calculatedFeeType?: CalculatedFeeType;
  calculatedValues?: FeeCalculatedValues;
  allowSplit?: boolean;
  canSellerConcessionsApply?: boolean;
  canSetPaidTo?: boolean;
  isAprFee?: boolean | null;
  feePercentOf?: FeePercentOfField;
  /** @format double */
  borrowerFeeDollar?: number;
  /** @format double */
  borrowerFeePercent?: number;
  /** @format double */
  sellerFeeDollar?: number;
  /** @format double */
  sellerFeePercent?: number;
  /** @format double */
  brokerFeeDollar?: number | null;
  /** @format double */
  lenderFeeDollar?: number;
  /** @format double */
  lenderFeePercent?: number;
  /** @format double */
  thirdPartyFeeDollar?: number;
  /** @format double */
  thirdPartyFeePercent?: number;
  /** @deprecated */
  isChargeOverridden?: boolean | null;
  /** @format double */
  financedBorrowerAmount?: number | null;
  /** @format double */
  paidOutsideClosingBorrowerAmount?: number | null;
  /** @format double */
  paidOutsideClosingSellerAmount?: number | null;
  /** @format double */
  paidOutsideClosingBrokerAmount?: number | null;
  /** @format double */
  paidOutsideClosingLenderAmount?: number | null;
  /** @format double */
  paidOutsideClosingThirdPartyAmount?: number | null;
  paidToType?: FeePaidTo;
  paidTo?: string | null;
  borrowerSelectedServiceProvider?: boolean | null;
  allowShopOrCannotShopSelection?: boolean | null;
  canShopLoanEstimate?: boolean | null;
  didShopClosingDisclosure?: boolean | null;
  keepOriginalFeeSectionWhenShopping?: boolean | null;
  note?: string | null;
  showOnHUD?: boolean | null;
  includeInRegulationZPointsAndFeesCalculations?: boolean | null;
  includeInJurisdictionPointsAndFeesCalculations?: boolean | null;
  /** @format double */
  originalTotalFee?: number | null;
  /** @format int32 */
  sourceFeeTemplateId?: number | null;
  isOptionalCost?: boolean | null;
  isLoanCredit?: boolean | null;
  isReimbursable?: boolean | null;
  escrowPaymentFrequency?: EscrowPaymentFrequency;
  escrowPremiumPaymentType?: EscrowPremiumPaymentType;
  escrowInsurancePolicyId?: string | null;
  isBorrowerChosenProvider?: boolean | null;
  isIncludedInWireAmount?: boolean | null;
  hasRequiredServiceProvider?: boolean | null;
  /** @format int32 */
  companyId?: number;
  /** @format uuid */
  modelGuid?: string;
  insertedBy?: string | null;
  updatedBy?: string | null;
  /** @format date-time */
  dateInserted?: string | null;
  /** @format date-time */
  dateUpdated?: string | null;

  /** Not in API */
  /** @format double */
  percentPlusDollarAmount?: number | null;
}

export interface UpdateFeesFromLoanRequest {
  feesToUpdate?: LoanFee[];
  existingLoanFees?: LoanFee[];
  updateCharges?: boolean;
}

export interface FeeSystemDetails {
  feeTypes?: FeeSystemDetailsFeeTypeDetails[] | null;
  feeSections?: FeeSystemDetailsFeeSectionDetails[] | null;
}

export interface FeeSystemDetailsFeeTypeDetails {
  name?: string | null;
  description?: string | null;
  feeType?: FeeType;
  hudNumber?: string | null;
  /** @format int32 */
  sumInHudNumber?: number | null;
  isAprFee?: boolean;
  canSetPaidTo?: boolean;
  canSellerConcessionsApply?: boolean;
  feeSections?: FeeSectionType[] | null;
  calculatedFeeType?: CalculatedFeeType;
  feePercentOf?: FeePercentOfField;
  paidToType?: FeePaidTo;
}

export interface FeeSystemDetailsFeeSectionDetails {
  /** @format int32 */
  minHudNumber?: number | null;
  /** @format int32 */
  maxHudNumber?: number | null;
  feeSection?: FeeSectionType;
  name?: string | null;
  /** @format int32 */
  order?: number | null;
  availableFeeTypes?: FeeType[] | null;
  availableCalculatedFeeTypes?: CalculatedFeeType[] | null;
}
