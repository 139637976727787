import { AfterViewInit, Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MultiSelect } from 'primeng/multiselect';
import { finalize } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Utils } from 'src/app/core/services/utils';
import { ContactListType } from 'src/app/models';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ContactsService } from './services/contacts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  set contacts(contacts: BorrowerDto[]) {
    this._allContacts = contacts;
    this.filteredContacts = [...this._allContacts];
  }

  @Input()
  set selectedColumns(selectedColumns: any) {
    this._selectedColumns = selectedColumns;
    if (selectedColumns) {
      this._selectedColumns.sort((a, b) => a.order - b.order);
      this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
    }
  }

  get selectedColumns(): any { return this._selectedColumns; }

  @Input()
  rowSelection: boolean = true;

  @Input()
  columnSelection: boolean = true;

  @Input()
  hasActionBar: boolean = true;

  @Input()
  localStorageStateKey: string = "contacts-selectedColumns";

  @ViewChild('mobileColumnSelector')
  mobileColumnSelector: MultiSelect;

  filteredContacts: BorrowerDto[] = [];

  error: any;
  columns: any[] = [];
  selectedRows: any[] = [];
  globalFilterFields: string[] = [];
  contactListType: ContactListType = ContactListType.Borrower;

  globalSearchString: string = null;

  tableState: any;

  dialerEnabled: boolean = false;

  applicationMode: string;
  editContactTab: 'applications' | 'contact' | 'history' = 'contact'

  borrowerId: number;
  baseAvatarUrl: string;

  private _selectedColumns: any;
  private _allContacts: BorrowerDto[] = [];
  private _selectedColumnsLocalStorageKeyName: string = "contacts-selectedColumns";
  private _contactUpsertedSubscription: Subscription;

  addContactsDrawerOpened: boolean = false;

  editContactsDrawerOpened: boolean = false;

  addContactsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  editContactsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  constructor(private _service: ContactsService,
    private _notify: NotificationService,
    private _router: Router,
    private readonly _environmentService: EnvironmentService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _navigationService: NavigationService,
    private readonly _drawerService: DrawerService,
    private readonly injector: Injector,
    private readonly _localStorageService: LocalStorageService) {
    super(injector);
    super.scrollOffset = 250;
  }

  ngOnInit(): void {
    this._selectedColumnsLocalStorageKeyName = this.localStorageStateKey;
    this.getScreenSize();
    this.baseAvatarUrl = this._environmentService.apiInfo.apiBaseUrl;
    this.applicationContextService.context.subscribe(context => {
      this.dialerEnabled = context.userPermissions.dialerEnabled;
    });

    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
      this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';

    this.columns = [
      { field: 'displayName', header: 'Name', order: 1, visible: true },
      { field: 'homePhone', header: 'Contact Info', order: 2, visible: true },
      { field: 'borrowerContact', header: 'Borrower Contact', order: 3, visible: true },
      { field: 'isRegister', header: 'Portal Status', order: 4, visible: true },
      { field: 'creditMonitoringStatus', header: 'Credit Monitoring Status', order: 5, visible: true },
      { field: 'dateCreateBorrower', header: 'Date Created', order: 6, visible: true },
    ];

    this.columns.forEach((column) => {
      this.globalFilterFields.push(column.field);
    });
    this.globalFilterFields.push('firstName');
    this.globalFilterFields.push('email');
    this.globalFilterFields.push('lastName');
    this.globalFilterFields.push('mobilePhone');
    this.globalFilterFields.push('borrowerContactName');
    if (this.rowSelection) {
      this.loadContacts();
    }

    this.loadColumnsToDisplayOnTable();

    this._contactUpsertedSubscription = this._service.updateContactsTableSubject.subscribe(() => {
      this.onAddEditContact();
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableState = this._localStorageService.getItem('contacts-table-state');
      this.selectedRows = [];
    });
  }

  ngOnDestroy(): void {
    this._contactUpsertedSubscription?.unsubscribe();
  }

  onGlobalSearchStringChanged = () => {
    const globalSearchCache: any = {};
    globalSearchCache.globalSearchString = this.globalSearchString;
    this.filteredContacts = Utils.filter(this.globalFilterFields, this.globalSearchString, this._allContacts)
  }

  onRowSelected = (event: any) => {
    const borrower = event.data as BorrowerDto;
    let that = this;

    if (!this.dialerEnabled) {
      Swal.fire({
        title: 'Invalid Selection',
        text: 'You do not have permission to perform this operation because the dialer is not enabled.',
        icon: 'warning',
        confirmButtonText: 'OK',
        reverseButtons: true
      }).then(function (result: SweetAlertResult) {
        if (!result.value) {
          return;
        }

        that.selectedRows = that.selectedRows.filter(r => r.borrowerId != borrower.borrowerId);
      });
    }
    else if (!borrower.homePhone && !borrower.mobilePhone) {
      Swal.fire({
        title: 'Invalid Selection',
        text: 'The contact does not have any configured phone numbers, please edit the contact, set the phone numbers and try again.',
        icon: 'warning',
        confirmButtonText: 'OK',
        reverseButtons: true
      }).then(function (result: SweetAlertResult) {
        if (!result.value) {
          return;
        }

        that.selectedRows = that.selectedRows.filter(r => r.borrowerId != borrower.borrowerId);

        that.editContact(borrower.borrowerId);
      });
    }
  }

  loadContacts() {
    this._spinner.show();
    this._service.getContacts().pipe(
      finalize(() => {
        this._spinner.hide();
      })
    )
      .subscribe(
        (contacts) => {
          this.contacts = contacts;
          let filteredContacts = this._allContacts.map(c => {
            c['displayName'] = Utils.getPersonsDisplayName(c);
            c['fullName'] = Utils.getPersonsFullName(c);
            c['lastLogin'] = Utils.timeSinceDate(c.lastTimeLogin);
            let user = this.applicationContext.globalConfig.users.find(
              (x) => x.userCompanyGuid === c.borrowerContact
            );
            c.borrowerContactName = Utils.getPersonsDisplayName(user);
            return c;
          });
          this.filteredContacts = Utils.filter(this.globalFilterFields, this.globalSearchString, filteredContacts);
        },
        ({ error }) => {
          this._notify.showError(
            `${error ? error.message : 'Unable to get contacts'}`,
            'Contacts'
          );
          this.error = error;
        }
      );
  }

  addContact() {
    // this._router.navigate(['admin/contacts/add']);
    if (this.editContactsDrawerOpened) {
      this._drawerService.hide("editContactEditorDrawer", 10);
      this.editContactsDrawerOpened = false;
    }
    this.addContactsDrawerOpened = true;
    this._drawerService.show("addContactEditorDrawer", 10);
  }

  editContact = (borrowerId: number) => {
    this.borrowerId = borrowerId;
    if (this.editContactsDrawerOpened) {
      this._drawerService.hide("editContactEditorDrawer", 10);
    }
    if (this.addContactsDrawerOpened) {
      this._drawerService.hide("addContactEditorDrawer", 10);
    }
    setTimeout(() => {
      this.editContactsDrawerOpened = true;
      this.editContactTab = 'contact';
      this._drawerService.show("editContactEditorDrawer", 10);
    }, 100);
  }

  isEmpty = (value: string): boolean => {
    if (/^(""|'')$/.test(value) || value == null || value == '' || value === 'null' || value === 'string' || value.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  showDeleteDialog(rowData) {
    Swal.fire({
      title: 'Deleting Contact',
      text: 'Are you sure you want to delete this contact. All applications and any data associated with this contact will be removed. This action cannot be undone!',
      icon: 'question',
      input: 'text',
      html: `Please confirm by typing the <b>full name</b> of the borrower as shown here: <b>${rowData.firstName} ${rowData.lastName}</b>`,
      inputValue: '',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Please enter contact name.'
        }
        if (value !== `${rowData.firstName} ${rowData.lastName}`) {
          return 'Contact name does\'t match, please try again.'
        }
      }
    }).then((result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }
      this.deleteRow(rowData);
    });
  }

  onViewApplicationsClicked(row) {
    this.borrowerId = row.borrowerId;
    this.editContactTab = 'applications';

    if (this.editContactsDrawerOpened) {
      this._drawerService.hide("editContactEditorDrawer", 10);
    }
    if (this.addContactsDrawerOpened) {
      this._drawerService.hide("addContactEditorDrawer", 10);
    }

    setTimeout(() => {
      this.editContactsDrawerOpened = true;
      this._drawerService.show("editContactEditorDrawer", 10);
    }, 100);
  }

  openContactDetail = (row) => {
    this._router.navigate([`admin/contacts/${row.borrowerId}/details`]);
  }

  onAddContactEditorDrawerClosed = () => {
    this.addContactsDrawerOpened = false;
  }

  onEditContactEditorDrawerClosed = () => {
    this.editContactsDrawerOpened = false;
  }

  onAddContactEditorDrawerClose = () => {
    this._drawerService.hide("addContactEditorDrawer", 10);
    this.addContactsDrawerOpened = false;
  }

  onBorrowerSaved = () => {
    this.onAfterBorrowerSavedOrEditCancelled();
  }

  onBorrowerEditCancelled = () => {
    this.onAfterBorrowerSavedOrEditCancelled();
  }

  addedToDialList() {
    this.selectedRows = [];
  }

  openColumnSelectorForMobile = () => {
    setTimeout(() => {
      this.mobileColumnSelector?.containerViewChild?.nativeElement?.click();
    })
  }

  private onAfterBorrowerSavedOrEditCancelled = () => {
    this._drawerService.hide("editContactEditorDrawer", 10);
    this.editContactsDrawerOpened = false;
    // set to default
    this.editContactTab = 'contact';
  }

  private onAddEditContact = () => {
    this.loadContacts();
  }

  private loadColumnsToDisplayOnTable = () => {
    const selectedColumns = <[]>(
      this._localStorageService.getItem(this._selectedColumnsLocalStorageKeyName)
    );

    if (!(selectedColumns && selectedColumns.length)) {
      this.selectedColumns = [];
      this.columns.forEach(column => {
        if (column.visible) {
          this.selectedColumns.push(column);
        }
      });
      this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
    }
    else {
      this.selectedColumns = [];
      selectedColumns.forEach(column => {
        this.columns.forEach(c => {
          if (column['field'] === c.field) {
            this.selectedColumns.push(column);
          }
        })
      });
      this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
    }
  }

  private deleteRow(borrower: BorrowerDto) {
    this._service
      .delete(borrower.borrowerId)
      .subscribe(
        () => {

          this._notify.showSuccess(
            "Contact deleted succesfuly.",
            'Contact'
          );

          this.loadContacts();
        },
        (error) =>
          this._notify.showError(
            error?.message || "Couldn't delete contact",
            'Contact'
          )
      );
  }
}
