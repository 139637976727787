import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AusResult } from 'src/app/models';
import { UrlaMortgage } from '../../../models/urla-mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
@Component({
  selector: 'underwriting-item',
  templateUrl: 'underwriting-item.component.html',
  styleUrls: ['./underwriting-item.component.scss'],
  viewProviders: [formViewProvider]
})

export class UnderwritingItemComponent implements OnInit {

  @Output()
  attemptedToDeleteAusResult: EventEmitter<AusResult> = new EventEmitter<AusResult>();

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  ausResult: AusResult;

  @Input()
  yesNoOptions: EnumerationItem[];

  @Input()
  index: number;

  @Input()
  selectedType: string;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  protected mouseCursorOnDeleteButton: boolean = false;
  protected decisionDateTime: string;

  constructor() { }

  ngOnInit() {
    this.ausResult.ausSystem ??= this.selectedType;
    this.checkExistingDecisionDateTimes();
  }

  onDeleteClicked = () => {
    this.attemptedToDeleteAusResult.emit(this.ausResult);
  }

  onDecisionDateTimeChanged = () => {
    if (this.decisionDateTime) {
         this.ausResult.decisionDateTime = new Date(this.decisionDateTime).toISOString();
    }
  }

  private checkExistingDecisionDateTimes = () => {
    if (this.ausResult?.decisionDateTime) {
      this.decisionDateTime = new Date(this.ausResult.decisionDateTime).toLocaleDateString("en-US");
    }
  }
}