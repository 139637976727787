<ngx-spinner bdColor="rgba(51,51,51,0.3)" size="large" color="#fff" type="ball-scale-multiple" [fullScreen]="false"
  [name]="eventEditorSpinner">
  <p style="font-size: 20px; color: white">Please wait...</p>
</ngx-spinner>

<form class="form-horizontal" #eventForm="ngForm" novalidate id="eventForm" name="eventForm">
  <div class="row mb-3">
    <div class="event-informations">
      <div *ngIf="event && event.applicationId">
        <i class="fas fa-home home-icon"></i>
        <a [routerLink]="[url + event.applicationId]">
          Application: <span class="cursor">
            <em class="text-primary"> 1{{event.applicationId | padLeft:'0': 9 }} </em>
          </span>
        </a>
      </div>
      <div *ngIf="event && event.borrowerName">
        <i class="fas fa-user user-icon"></i>
        <a *ngIf="event.applicationId; else leadBorrower"
          [routerLink]="[url + event.applicationId + '/loan-info']"
          [queryParams]="{openBorrower: 'true'}">
          Borrower:
          <span class="cursor">
            <em class="text-primary"> {{event.borrowerName}} </em>
          </span>
        </a>
        <ng-template #leadBorrower>
          <a href="javascript:void(0)" (click)="onLeadOpened(event)">
            Lead:
            <span class="cursor">
              <em class="text-primary"> {{event.borrowerName}} </em>
            </span>
          </a>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="eventType">Event Type</label>
      <select name="eventType" id="eventType" class="form-select" style="width: 100%; height:36px;"
        [disabled]="!inEditMode" [(ngModel)]="event.eventType">
        <option [value]="option.value" *ngFor="let option of eventTypeOptions">{{option.name}}</option>
      </select>
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="visibility">Visibility</label>
      <select class="form-select" name="visibility" id="visibility" [(ngModel)]="event.privilege"
        [disabled]="!inEditMode" style="width: 100%; height:36px;">
        <option [value]="option.value" *ngFor="let option of visibilityOptions">{{option.name}}</option>
      </select>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-6">
      <div class="row">
        <div class="col-6">
          <label for="startDate" class="form-label">Start Date</label>
          <input class="form-control mb-3" type="date" id="startDate" name="startDate" [(ngModel)]="event.dateStart"
            id="startDate" [disabled]="!inEditMode">
          <label for="startTime" class="form-label">Start Time</label>
          <ng-template #popTemplate>
            <timepicker [(ngModel)]="startTime" name="startTimePicker" [showMeridian]="true" [minuteStep]="15"
              (ngModelChange)="onStartTimeChanged()" [disabled]="!inEditMode"></timepicker>
          </ng-template>
          <input [(ngModel)]="startTimeString" name="startTime" class="form-control" (blur)="onStartTimeStringChanged()"
            type="text" [popover]="popTemplate" [outsideClick]="true" id="startTime" placement="bottom"
            #eventStartTime="ngModel" [disabled]="!inEditMode"
            [ngClass]="{'is-invalid' : eventStartTime && eventStartTime.touched && eventStartTime.invalid}" required />
          <div class="invalid-feedback">Please enter start time.</div>
        </div>
        <div class="col-6">
          <span style="font-size: 12px;" class="badge badge-outline-primary mb-2">Existing Appointments</span>
          <ul>
            <li *ngFor="let event of userEvents">
              {{event.start + "-" + event.end}}
            </li>
          </ul>
        </div>
      </div>
      <ng-container *ngIf="event.applicationId || event.leadId">
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="form-check">
              <input type="checkbox" [(ngModel)]="appointmentForSomebodyElse" (ngModelChange)="onAppointmentForOthetUserCheckboxChanged()" class="form-check-input" id="appointmentForSomebodyElse" name="appointmentForSomebodyElse">
              <label class="form-check-label" for="appointmentForSomebodyElse">Appointment For Other User</label>
            </div>
          </div>
        </div>
        <div class="row mt-3" *ngIf="appointmentForSomebodyElse">
          <div class="col-md-12">
            <label for="contacts" class="control-label">Appointment For</label>
            <ng-select width="100%" name="contacts" bindLabel="formattedName" bindValue="userId"
              placeholder="Select User" [closeOnSelect]="true" [multiple]="false" groupBy="roleName"
              [(ngModel)]="event.userId" [items]="contacts" appendTo="body" [disabled]="event.eventId > 0" (ngModelChange)="onAppointmentForOthetUserChanged()">
            </ng-select>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="event.applicationId">
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="form-check">
              <input type="checkbox" [(ngModel)]="inviteBorrowerByDefault" class="form-check-input" id="inviteBorrowerByDefault" name="inviteBorrowerByDefault">
              <label class="form-check-label" for="inviteBorrowerByDefault">Invite Borrower</label>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-md-6">
      <div class="row mb-3" *ngIf="!event.allDay">
        <div class="col-6">
          <label class="mb-2" for="duration">Duration (minutes)</label>
          <input type="number" numeric [allowNegative]="false" name="duration" id="duration" [(ngModel)]="duration"
            [disabled]="!inEditMode" (ngModelChange)="onDurationChanged()" min="0" #eventDuration="ngModel"
            oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
            class="form-control numeric-without-arrows"
            [ngClass]="{'is-invalid' : eventDuration && eventDuration.touched && eventDuration.invalid}" required />
          <div class="invalid-feedback">Please enter duration.</div>
        </div>
        <div class="col-6">
          <label class="mb-2" for="endTime">End Time</label>
          <input type="text" name="endTime" id="endTime" [(ngModel)]="endTimeString" disabled class="form-control"
            [disabled]="!inEditMode" />
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="form-check">
            <input type="checkbox" [(ngModel)]="event.allDay" class="form-check-input" id="allDay" name="allDay"
              [disabled]="!inEditMode">
            <label class="form-check-label" for="allDay">All Day</label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-check" *ngIf="applicationContext.userPermissions.smsAlertsEnabled">
            <input type="checkbox" [(ngModel)]="event.remindSms" class="form-check-input" id="remindSms"
              name="remindViaSms" [disabled]="!inEditMode">
            <label class="form-check-label" for="remindSms">Remind via SMS</label>
          </div>
        </div>
        <div class="col-5">
          <div class="form-check">
            <input type="checkbox" [(ngModel)]="event.remindEmail" class="form-check-input" id="remindEmail"
              name="remindViaEmail" [disabled]="!inEditMode">
            <label class="form-check-label" for="remindEmail">Remind via Email</label>
          </div>
        </div>
        <div class="col mt-2" *ngIf="event.remindEmail || event.remindSms">
          <label class="mb-2" for="remindBeforeMinutes">Remind Me Before
            <span class="color-blue_gray">(minutes: </span>
            <span class="color-blue_gray"><span class="color-red">max 120</span>)</span>
          </label>
          <input type="number" numeric [allowNegative]="false" name="remindBeforeMinutes" #remindBeforeMinutes="ngModel"
            id="remindBeforeMinutes" [(ngModel)]="event.remindBeforeMinutes" class="form-control numeric-without-arrows"
            [disabled]="!inEditMode" />
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="title">Title</label>
      <input type="text" name="title" #title="ngModel" id="title" [(ngModel)]="event.title" class="form-control"
        [ngClass]="{'is-invalid' : title && title.touched && title.invalid}" required [disabled]="!inEditMode" />
      <div class="invalid-feedback">Please enter a title.</div>
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="location">Location</label>
      <input type="text" name="location" id="location" [(ngModel)]="event.location" class="form-control"
        [disabled]="!inEditMode" />
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-12">
      <label class="mb-2" for="description">Description</label>
      <textarea
        id="textarea"
        name="description"
        id="description"
        #description="ngModel"
        [(ngModel)]="event.description"
        class="form-control"
        [ngClass]="{'is-invalid': description && description.touched && description.invalid}"
        maxlength="550"
        rows="10"
        placeholder="Description"
        [disabled]="!inEditMode"
      ></textarea>
      <div class="invalid-feedback">Description needs to be 550 characters max.</div>
    </div>
  </div>
  <div *ngIf="showActionControls && inEditMode" class="row">
    <div class="col-sm-12" style="text-align: center;">
      <button *ngIf="inEditMode && (event.eventId > 0 || event.eventType == 'Appointment')" class="btn btn-danger" (click)="onDeleteClicked()">
        Delete
      </button>
      <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
        Save
      </button>
    </div>
  </div>
</form>
