<div class="grid-layout has-border">
  <div class="grid-header">
    <div class="grid-header-left">
      <div>{{inEditMode ? '1b. Employment' : '1b. Current Employment/Income'}}</div>
    </div>
    <div class="grid-header-right" *ngIf="!inEditMode">
      <span class="employment-history-indicator me-2"
        [ngClass]="{'text-success': numberOfYears * 12 + numberOfMonths >= 24, 'text-danger': numberOfYears * 12 + numberOfMonths < 24}">
        <i class="fa" [ngClass]="{'fa-check': numberOfYears * 12 + numberOfMonths >= 24, 'fa-times-circle': numberOfYears * 12 + numberOfMonths < 24}"></i>
          {{numberOfYears}} year(s) {{numberOfMonths}} month(s)
      </span>
      <ul class="list-unstyled topbar-nav float-end mb-0 mx-2">
        <li class="dropdown">
          <a class="btn btn-sm btn-soft-primary me-2" role="button" *ngIf="!isReadOnly && systemLevel?.digitalEmploymentVerificationEnabled?.value"
                [routerLink]="['/' + (isTpo ? 'tpo/app-details/' : 'admin/app-details/') + applicationId + '/digital-emp-income-verification']">
                Digital Verify
            </a>
          <a class="btn btn-sm btn-soft-primary dropdown-toggle" data-bs-toggle="dropdown" *ngIf="!isReadOnly"
              aria-haspopup="false" aria-expanded="false" href="#" role="button">
            <i class="fas fa-plus me-2"></i>Add Employment
          </a>
          <div class="dropdown-menu dropdown-menu-end">
              <a class="dropdown-item" (click)="onAddCurrentEmploymentClicked()">Current</a>
              <a class="dropdown-item" (click)="onAddFormerEmploymentClicked()">Former</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="grid-header-right" *ngIf="inEditMode"></div>
  </div>
</div>
<!-- Current employment is ONLY one, but need this trick, read the comment in the ts file-->
<employment *ngFor="let employment of currentEmployments"
  (employmentDurationChanged)="onEmploymentDurationChanged()"
  (switchEmploymentType)="onSwitchedEmploymentType()"
  #employmentComponent [isReadOnly]="isReadOnly"
  [maxDate]="maxDate"
  [employment]="employment"
  (isPrimaryChanged)="onIsPrimaryChanged($event)"
  [inEditMode]="inEditMode"
  [urlaFieldsConfig]="urlaFieldsConfig">
</employment>

<div class="grid-layout has-border" *ngIf="additionalEmployments.length >= 1">
  <div class="grid-header">
    <div class="grid-header-left">
      <div>1c. Additional Employment</div>
    </div>
    <div class="grid-header-right"></div>
  </div>
</div>
<employment *ngFor="let additionalEmployment of additionalEmployments"
  (employmentDurationChanged)="onEmploymentDurationChanged()"
  (switchEmploymentType)="onSwitchedEmploymentType()"
  #employmentComponent [isReadOnly]="isReadOnly" [maxDate]="maxDate"
  [employment]="additionalEmployment"
  (isPrimaryChanged)="onIsPrimaryChanged($event)"
  [urlaFieldsConfig]="urlaFieldsConfig">
</employment>

<div class="grid-layout has-border" *ngIf="formerEmployments.length > 0">
  <div class="grid-header">
    <div class="grid-header-left">
      <div>{{(additionalEmployments.length < 1 ? '1c. ' : '1d. ') + 'Former Employment'}}</div>
    </div>
    <div class="grid-header-right"></div>
  </div>
</div>
<employment *ngFor="let formerEmployment of formerEmployments"
  (employmentDurationChanged)="onEmploymentDurationChanged()"
  #employmentComponent
  (switchEmploymentType)="onSwitchedEmploymentType()"
  [isReadOnly]="isReadOnly"
  [employment]="formerEmployment"
  [urlaFieldsConfig]="urlaFieldsConfig">
</employment>

<div class="grid-layout" *ngIf="!inEditMode">
  <div class="grid-header">
    <div class="grid-header-left">
      <div *ngIf="additionalEmployments.length === 0 && formerEmployments.length === 0">1c. Income from Other Sources</div>
      <div *ngIf="(additionalEmployments.length > 0 && formerEmployments.length === 0) ||
        (additionalEmployments.length === 0 && formerEmployments.length > 0)">1d. Income from Other Sources</div>
      <div *ngIf="additionalEmployments.length > 0 && formerEmployments.length > 0">1e. Income from Other Sources</div>
    </div>
    <div class="grid-header-right">
      <a class="btn btn-sm btn-soft-primary me-2" (click)="onAddOtherIncomeClicked()" role="button" *ngIf="!isReadOnly">
        <i class="fas fa-plus me-2"></i>Add Other Income
      </a>
    </div>
  </div>
</div>
<div class="grid-layout has-border" *ngIf="!inEditMode">
  <other-income
    #otherIncome
    [isReadOnly]="isReadOnly"
    [incomeTypes]="nonEmploymentIncomeTypes"
    [incomes]="borrower.nonEmploymentIncomes"
    (incomeDeleted)="onOtherIncomeDeleted($event)"
    (editClicked)="onOtherIncomeEditClicked($event)">
  </other-income>
  <div class="grid-layout-row">
    <div class="grid-cell-tag col-sm-10 grid-layout-text left-space">
      <div class="form-input" style="text-align: right;"> <!--Fix this inline style-->
          <b>Total Monthly Income:</b>
      </div>
    </div>
    <div class="grid-cell-tag col-sm-2 right-space">
      <div class="form-input">
        <div class='cell-content'>
          {{ (borrower.calculatedStats?.totalMonthlyIncome || 0)| currency}} / month
        </div>
      </div>
    </div>
  </div>
  <input [name]="'hasMoreThan2YearsOfHistory_'+ borrower.borrowerId" type="hidden" [(ngModel)]="monthsOfHistoryForValidation" [ngClass]="{'ng-invalid-required': hasMoreThan2YearsOfHistory === false}" required/>
</div>



