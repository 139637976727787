import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'qa-va-income-section',
  templateUrl: './qa-va-income-section.component.html',
  styleUrls: ['./qa-va-income-section.component.scss'],
})
export class QaVaIncomeSectionComponent {
  @Input() title: string;
  @Input() templateSubtitle?: TemplateRef<any>;
  @Input() isCollapsible: boolean = false;
  @Input() isCollapsed: boolean = true;
  @Input() value?: string;
  @Input() isEditable: boolean = false;

  @Output() edit = new EventEmitter<void>();

  @ViewChild('collapseButton') collapseButton?: ElementRef<HTMLElement>;

  protected contentWrapperId: string = `qa-va-income-section-${uuidv4()}`;

  protected get collapsed(): boolean {
    if (!this.isCollapsible) {
      return false;
    }

    const collapseButton = this.collapseButton?.nativeElement;
    return collapseButton?.classList.contains('collapsed') ?? false;
  }
}
