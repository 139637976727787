
<div *ngIf="!inEditMode"
  class="form-input"
  [ngClass]="
    { 'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
      'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
      'bg-transparent' : disabled || readonly
    }"
  [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden">
  <span class="input-label" title="">
    <span class="input-label-overflow">{{label}}</span>
  </span>
  <form #durationInputForm="ngForm">
    <div class="row px-2">
      <div class="col-sm-3 col-xs-6">
        <input type="hidden" name="{{name}}" #model="ngModel" [(ngModel)]="value" [required]="required"/>
        <input
          numeric
          [allowNegative]="false"
          type="text"
          [(ngModel)]="years"
          #yearsModel="ngModel"
          name="{{name}}_years"
          [required]="required"
          placeholder="Years"
          [ngClass]="{
            'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
            'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required'
          }"
          (ngModelChange)="onYearsChanged()"
          [disabled]="disabled"
          [readonly]="readonly"
          class="input-element" />
      </div>
      <div class="col-sm-3 col-xs-6">Year(s)</div>
      <div class="col-sm-3 col-xs-6">
        <input
          numeric
          [allowNegative]="false"
          type="text"
          [(ngModel)]="months"
          #monthsModel="ngModel"
          name="{{name}}_months"
          [required]="required"
          [min]="0"
          [max]="11"
          placeholder="Months"
          [ngClass]="{
            'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
            'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required'
          }"
          (keyup)="onMonthsChanged()"
          [disabled]="disabled"
          [readonly]="readonly"
          class="input-element" />
      </div>
      <div class="col-sm-3 col-xs-6">Month(s)</div>
    </div>
  </form>
</div>
<edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
</edit-mode-input>
