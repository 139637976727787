import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { NotificationService } from 'src/app/services/notification.service';
import { BorrowersService } from 'src/app/modules/borrower/services/borrowers.service';
import { EConsentEvent } from '../../../models/e-consent-event.model';
import { MortgageBorrower } from 'src/app/models';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';

@Component({
  selector: 'e-consent-history-popover',
  templateUrl: 'e-consent-history-popover.component.html',
  styleUrls: ['./e-consent-history-popover.component.scss'],
})
export class EConsentHistoryPopoverComponent implements OnInit {

  @Input()
  borrower: MortgageBorrower;

  isLoading: boolean = false;
  borrowerFullName: string;

  protected histories: EConsentEvent[] = [];

  constructor(private readonly _notifsService: NotificationService,
    private readonly _borrowersService: BorrowersService,
    private readonly _utilsService: UtilityService) { }

  ngOnInit() {
    this.borrowerFullName = this._utilsService.getBorrowerFullName(this.borrower);
    this.fetchHistory();
  }

  private fetchHistory = () => {
    this.isLoading = true;
    this._borrowersService.getBorrowerEConsentHistory(this.borrower.contactId).subscribe({
      next: (eConsentHistories: EConsentEvent[]) => {
        if (eConsentHistories) {
          this.histories = _.orderBy(eConsentHistories, ['dateInserted'], ['desc']);
        }
      },
      error: (error) => {
        this._notifsService.showError(error.message || 'Unable to get Borrower EConsent History', 'Error');
      }
    }).add(() => this.isLoading = false);
  }
}
