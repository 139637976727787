<div class='modal-header address-header border-bottom pt-3'>
  <h5 class='modal-title'>
    Address
  </h5>

  <!-- Close Button -->
  <button
    (click)='onCancel()'
    class='btn-close'
    title='Cancel'
    type='button'
  ></button>
</div>

<!-- Address Form -->
<form
  *ngLet='formGroup.controls as controls'
  [formGroup]='formGroup'
  class='row g-0 mt-3 mb-2'
>
  <!-- Street Address -->
  <div
    *ngLet='controls.address1 as control'
    class='col-12 col-lg-9 form-group'
  >
    <label
      class='required-label'
      for='address1'
    >
      Street Address
    </label>

    <address-autocomplete-input
      (addressChange)='onChangeAddress($event)'
      [formControl]='control'
      id='address1'
    ></address-autocomplete-input>

    <ng-container *ngIf='isInvalid(control)'>
      <ng-container
        *ngIf='control.errors.required'
        [ngTemplateOutletContext]='{ message: "Street Address is required" }'
        [ngTemplateOutlet]='validationError'
      ></ng-container>
    </ng-container>
  </div>

  <!-- Unit / Apt # -->
  <div class='col-12 col-lg-3 form-group'>
    <!-- Empty label for alignment -->
    <label
      for='address2'
      style='visibility: hidden;'
    >
      Unit / Apt #
    </label>

    <input
      [formControl]='controls.address2'
      class='form-control'
      id='address2'
      placeholder='Unit / Apt #'
      type='text'
    />
  </div>

  <!-- City -->
  <div class='col-12 col-lg-6 form-group'>
    <label
      class='required-label'
      for='city'
    >
      City
    </label>

    <input
      [formControl]='controls.city'
      class='form-control'
      id='city'
      type='text'
    />

    <ng-container *ngIf='isInvalid(controls.city)'>
      <ng-container
        *ngIf='controls.city.errors.required'
        [ngTemplateOutletContext]='{ message: "City is required" }'
        [ngTemplateOutlet]='validationError'
      ></ng-container>
    </ng-container>
  </div>

  <!-- State -->
  <div class='col-12 col-lg-6 form-group'>
    <label for='state'>
      State
    </label>

    <select
      [formControl]='controls.state'
      class='form-control'
      id='state'
    >
      <option [ngValue]='null'>
        Select a State
      </option>

      <option
        *ngFor='let state of stateOptions'
        [ngValue]='state.value'
      >
        {{ state.name }}
      </option>
    </select>
  </div>

  <!-- Zip Code -->
  <div class='col-12 col-lg-6 form-group'>
    <label for='zip-code'>
      Zip Code
    </label>

    <zip-code-input
      (blur)='onChangeZipCodeLookup($event)'
      (selectionChanged)='onChangeZipCodeLookup($event)'
      [(ngModel)]='formAdapter.zipCode.value'
      [ngModelOptions]='{ standalone: true }'
      id='zip-code'
    ></zip-code-input>
  </div>

  <!-- County -->
  <div class='col-12 col-lg-6 form-group'>
    <label for='county'>
      County
    </label>

    <input
      [formControl]='controls.county'
      class='form-control'
      id='county'
      type='text'
    />
  </div>
</form>

<!-- Footer (Cancel / Save) -->
<div class='address-footer border-top'>
  <!-- Cancel Button -->
  <button
    (click)='onCancel()'
    class='btn btn-secondary'
    title='Cancel'
    type='button'
  >
    Cancel
  </button>

  <!-- Save Button -->
  <button
    (click)='onSave()'
    [disabled]='!canSave'
    class='btn btn-primary'
    title='Save'
    type='button'
  >
    Save
  </button>
</div>

<ng-template #validationError let-message='message'>
  <div class='invalid-feedback d-block'>
    {{ message }}
  </div>
</ng-template>
