import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'qa-badge',
  templateUrl: './qa-badge.component.html',
  styleUrls: ['./qa-badge.component.scss']
})
export class QaBadgeComponent implements AfterViewInit {
  @ViewChild('badge') protected badge: ElementRef<HTMLDivElement>;

  @Input() variant: 'green' | 'indigo' | 'blue' | 'yellow' = 'green';

  constructor() { }

  ngAfterViewInit(): void {
    this.badge.nativeElement.classList.add(`qa-badge--${this.variant}`);
  }
}
