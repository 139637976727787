export class BorrowerVerificationDocument {
  documentType: BorrowerVerificationDocumentType
  reportType: BorrowerVerificationReportType;
  thirdPartyDocumentId: string;
  fileName: string;
  extension: string;
  mimeType: string;
  docFileGuid: string;
  documentCreatedDate: Date;
}

export enum BorrowerVerificationDocumentType {
  Report = "Report",
  Statement = "Statement",
  W2 = "W2"
}

export enum BorrowerVerificationReportType {
  Income = "Income",
  IncomeAndEmployment = "IncomeAndEmployment",
  Assets = "Assets",
  DirectDepositSwitch = "DirectDepositSwitch",
  Insurance = "Insurance",
  AutoInsurance = "AutoInsurance",
  HomeInsurance = "HomeInsurance",
  ScoringAttribute = "ScoringAttribute"
}
