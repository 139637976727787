<form #homeownershipEducationForm="ngForm">
  <div class="grid-layout has-border" *ngIf="mortgage.mortgageId != null">
    <div class="grid-layout-row">
      <urla-grid-cell class="col-sm-12 col-xs-12 grid-layout-text" [disabled]="isReadOnly">
        <span>Housing counseling and homeownership education programs are offered by
        independent third parties to help the Borrower understand the rights and
        responsibilities of homeownership. A list of HUD-approved housing
        counseling agencies can be found at: <b class="mx-1">www.hud.gov</b> or
        <b class="mx-1">www.consumerfinance.gov</b></span>
      </urla-grid-cell>
    </div>
    <div class="borrower-container">
      <urla-borrower-homeownership-education *ngFor="let borrower of mortgage.borrowers | sort : 'isPrimary'"
        [borrower]="borrower" [isReadOnly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig">
      </urla-borrower-homeownership-education>
    </div>
  </div>
</form>
<loading-indicator *ngIf="mortgage.mortgageId == null" [loadingMessage]="''"></loading-indicator>
