<div class="modal-header" *ngIf="hostedInModal">
  <h5 class="modal-title">{{!startDocumentGeneration ? 'Task Information' : 'Task Document Generation Template'}} {{ taskReadonly ? '(readonly)' : ''}}</h5>
  <button type="button" class="btn-close" (click)="onDocumentGeneratedCanceled()" aria-label="Close"></button>
</div>

<ng-container *ngIf="globalConfig && !startDocumentGeneration
                && task.taskType !== 'QuickClientUpdatePreApproved'
                && task.taskType !== 'QuickClientUpdateInProcess'
                && task.taskType !== 'QuickClientUpdateReferralLead'
                && task.taskType !== 'GatherPayment'
                && task.taskType !== 'EsignDocument'
                && task.taskType !== 'LosEsign'
                && task.taskType !== 'Escalation'
                && task.taskType !== 'ChangeOfCircumstance'
                && task.taskType !== 'RequestDocument'
                && task.taskType !== 'GenerateDocument'
                && task.taskType !== 'WetSignDocument'
                && task.taskType !== 'DigitalAssetVerification'
                && task.taskType !== 'PerformAction'">
  <task-editor-other [task]="task" [taskReadonly]="taskReadonly" [refreshMentions]="refreshMentions" [hostedInModal]="hostedInModal" [openFileUpload]="openFileUpload"></task-editor-other>
</ng-container>

<ng-container *ngIf="!startDocumentGeneration; else docGenContainer">
  <ng-container *ngIf="task.taskType === 'QuickClientUpdatePreApproved' || task.taskType === 'QuickClientUpdateInProcess' || task.taskType === 'QuickClientUpdateReferralLead'">
    <task-editor-qcu
      [task]="task"
      [taskReadonly]="taskReadonly"
      [refreshMentions]="refreshMentions"
      [hostedInModal]="hostedInModal"
      [borrowers]="borrowers"
      (cancelClicked)="onCancelQcuTaskEditClicked()"
      (saveClicked)="onSaveClicked()"
    ></task-editor-qcu>
  </ng-container>

  <ng-container *ngIf="task.taskType === 'GatherPayment'">
    <task-editor-gather-payment [task]="task" [taskReadonly]="taskReadonly" [refreshMentions]="refreshMentions" [hostedInModal]="hostedInModal" [openFileUpload]="openFileUpload"></task-editor-gather-payment>
  </ng-container>

  <ng-container *ngIf="task.taskType === 'EsignDocument' || task.taskType === 'LosEsign'">
    <task-editor-esign
      [task]="task"
      [filterId]="filterId"
      [taskReadonly]="taskReadonly"
      [refreshMentions]="refreshMentions"
      [hostedInModal]="hostedInModal"
      [openFileUpload]="openFileUpload"
      (taskRefreshed)="onEsignTaskRefreshed()">
    </task-editor-esign>
  </ng-container>

  <ng-container *ngIf="task.taskType === 'Escalation'">
    <task-editor-escalation [task]="task" [taskReadonly]="taskReadonly" [refreshMentions]="refreshMentions" [hostedInModal]="hostedInModal" [openFileUpload]="openFileUpload"></task-editor-escalation>
  </ng-container>

  <ng-container *ngIf="task.taskType === 'ChangeOfCircumstance'">
    <task-editor-coc [task]="task" [taskReadonly]="taskReadonly" [refreshMentions]="refreshMentions" [hostedInModal]="hostedInModal"></task-editor-coc>
  </ng-container>

  <ng-container *ngIf="task.taskType === 'RequestDocument' || task.taskType === 'GenerateDocument'
    || task.taskType === 'WetSignDocument' || task.taskType === 'DigitalAssetVerification'
    || task.taskType === 'PerformAction'">
    <task-editor-document [task]="task" [taskReadonly]="taskReadonly"
      [refreshMentions]="refreshMentions" [hostedInModal]="hostedInModal"
      [(startDocumentGeneration)]="startDocumentGeneration"
      [openFileUpload]="openFileUpload">
    </task-editor-document>
  </ng-container>
</ng-container>

<ng-template #docGenContainer>
  <document-generate
    [loanDocTaskId]="task.loanDocTaskId"
    [appId]="task.applicationId"
    [selectedTemplateId]="task.documentTemplateModelId"
    [shouldDcoTemplateBeDisabled]="true"
    (onDocumentGenerated)="onDocumentGenerated()"
    (onCancel)="onDocumentGeneratedCanceled()">
  </document-generate>
</ng-template>


