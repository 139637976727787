<div class="row mb-3">
  <div class="col-md-2 text-end">
    <label class="custom-control-label">{{label}}</label>
  </div>
  <div class="col-md-2">
    <input type="text" class="form-control" [(ngModel)]="customDataConfigModel['label'+number+ 'Text']">
  </div>
  <div class="col-md-1 text-end">
    <label class="custom-control-label">Field Type</label>
  </div>
  <div class="col-md-2">
    <select class="form-select standard" [(ngModel)]="customDataConfigModel['field'+number+ 'Type']"
      (ngModelChange)="onDataTypeChanged()">
      <option [ngValue]="0">-- Select One --</option>
      <option *ngFor="let item of fieldTypeOptions" value={{item.value}}>
        {{item.name}}
      </option>
    </select>
  </div>
  <div class="col-md-1 text-end">
    <label class="custom-control-label">Editor Type</label>
  </div>
  <div class="col-md-2">
    <select class="form-select standard" [(ngModel)]="customDataConfigModel['editor'+number+ 'Type']">
      <option [ngValue]="0">-- Select One --</option>
      <option *ngFor="let item of filteredEditorTypeOptions" value={{item.value}}>
        {{item.name}}
      </option>
    </select>
  </div>
  <div class="col-md-1 pt-1" *ngIf="customDataConfigModel['editor'+number+ 'Type'] === 'Multiselect'">
    <button type="button" class="btn btn-soft-primary btn-sm" (click)="openCustomDataItemsModal()">
      Custom Items
    </button>
  </div>
  <div class="col-md-1 pt-1">
    <button type="button" class="btn btn-soft-danger btn-sm" (click)="removeCustomData()">
      Remove
    </button>
  </div>
</div>