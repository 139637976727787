import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { DisclosureTrackingService } from 'src/app/modules/disclosure-tracking/services/disclosure-tracking.service';
import { ProductPriceValidation } from 'src/app/modules/pricing/models/pricing/product-price-validation.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'le-cd-validation',
  templateUrl: 'le-cd-validation.component.html',
  styleUrls: ['./le-cd-validation.component.scss']
})

export class LEandCDValidationComponent implements OnInit {

  @Input()
  appId: number;

  protected isLoading: boolean = false;
  protected disclosureValidation: ProductPriceValidation;

  constructor(
    private readonly _disclosureTrackingService: DisclosureTrackingService,
    private readonly _notifyService: NotificationService
  ) { }

  ngOnInit() { 
    this.getDisclosureValidations();
  }

  private getDisclosureValidations = () => {
    this.isLoading = true;
    this._disclosureTrackingService.checkIfDisclosureIsValid(this.appId)
    .pipe(map(response => ({
      validity: response.validity,
      items: response.items || []
    })))
    .subscribe({
      next: (result) => {
        this.disclosureValidation = result;
      },
      error: (err) => {
        let errorMessage = "An error occurred while getting disclosure validations.";
        if (err && err.message) {
          errorMessage = err.message;
        }
        this._notifyService.showError(errorMessage, "Error!");
      }
    }).add(() => this.isLoading = false);
  }
}