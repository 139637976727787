export class CustomDataConfig {
  customDataConfigId: number;
  label1Text: string;
  field1Type: string;
  editor1Type: CustomDataEditorType;
  multiLookupType1: string;
  customDropDownOptions1: string;
  label2Text: string;
  field2Type: string;
  editor2Type: CustomDataEditorType;
  multiLookupType2: string;
  customDropDownOptions2: string;
  label3Text: string;
  field3Type: string;
  editor3Type: CustomDataEditorType;
  multiLookupType3: string;
  customDropDownOptions3: string;
  label4Text: string;
  field4Type: string;
  editor4Type: CustomDataEditorType;
  multiLookupType4: string;
  customDropDownOptions4: string;
  label5Text: string;
  field5Type: string;
  editor5Type: CustomDataEditorType;
  multiLookupType5: string;
  customDropDownOptions5: string;
  label6Text: string;
  field6Type: string;
  editor6Type: CustomDataEditorType;
  multiLookupType6: string;
  customDropDownOptions6: string;
  label7Text: string;
  field7Type: string;
  editor7Type: CustomDataEditorType;
  multiLookupType7: string;
  customDropDownOptions7: string;
  label8Text: string;
  field8Type: string;
  editor8Type: CustomDataEditorType;
  multiLookupType8: string;
  customDropDownOptions8: string;
  label9Text: string;
  field9Type: string;
  editor9Type: CustomDataEditorType;
  multiLookupType9: string;
  customDropDownOptions9: string;
  label10Text: string;
  field10Type: string;
  editor10Type: CustomDataEditorType;
  multiLookupType10: string;
  customDropDownOptions10: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum CustomDataEditorType {
  Unspecified = 'Unspecified',
  Text = 'Text',
  CheckBox = 'CheckBox',
  RadioButton = 'RadioButton',
  Email = 'Email',
  Phone = 'Phone',
  Currency = 'Currency',
  Percent = 'Percent',
  Multiline = 'Multiline',
  Multiselect = 'Multiselect',
  Date = 'Date',
}