import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { map, Observable } from "rxjs";
import { ApplicationContextService } from "src/app/services/application-context.service";

@Injectable({
  providedIn: 'root'
})
export class CanActivateAdminRoutesGuard implements CanActivate, CanActivateChild {

  constructor(private readonly _applicationContextService: ApplicationContextService,
    private readonly _router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._applicationContextService.context.pipe(map(context => {
      if (context.isTpo) {
        this._router.navigateByUrl('tpo/dashboard');
        return false;
      }
      return true;
    }))
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
}
