<div class="grid-layout-row" *ngIf="address">
    <urla-street-address #urlaStreetAddress class="col-sm-4 col-xs-6"  [label]="addressLabel"
      [(ngModel)]="address.address1" [name]="'address1' + id"
      (change)="onAddressBlurred()"
      [readonly]="isReadOnly" (addressChanged)="onAddressChanged($event)"
      [copyTitleText]="copyTitleText" [copyButtonVisible]="copyButtonVisible" (onCopyClicked)="copyClicked.emit()"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="fieldBeingEdited + '.address1'"
      [required]="urlaFieldsConfig[fieldBeingEdited + '.address1']?.required == 'required' ||
        urlaFieldsConfig[fieldBeingEdited + '.address1']?.required == 'requested'">
    </urla-street-address>

    <urla-text-input class="col-sm-1 col-xs-2" label="Unit #" [pattern]="alphaNumericPattern"
      [(ngModel)]="address.address2" (blur)="onAddress2Blurred()" [name]="'unitNo' + id" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="fieldBeingEdited + '.address2'"
      [required]="urlaFieldsConfig[fieldBeingEdited + '.address2']?.required == 'required' ||
        urlaFieldsConfig[fieldBeingEdited + '.address2']?.required == 'requested'">
    </urla-text-input>

    <urla-text-input class="col-sm-2 col-xs-4" label="City"
      [(ngModel)]="address.city" (blur)="onCityBlurred()" [name]="'city' + id" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="fieldBeingEdited + '.city'"
      [required]="urlaFieldsConfig[fieldBeingEdited + '.city']?.required == 'required' ||
        urlaFieldsConfig[fieldBeingEdited + '.city']?.required == 'requested'">
    </urla-text-input>

    <urla-state-dropdown class="col-sm-1 col-xs-4" [name]="'state' + id"
      [(ngModel)]="address.state" [label]="'State'"
      (ngModelChange)="onStateChanged()"
      [readonly]="isReadOnly"
      [disabled]="address.country && address.country !== 'us'"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="fieldBeingEdited + '.state'"
      [required]="(address.country && address.country === 'us') && (urlaFieldsConfig[fieldBeingEdited + '.state']?.required == 'required' ||
        urlaFieldsConfig[fieldBeingEdited + '.state']?.required == 'requested')">
    </urla-state-dropdown>

    <urla-digits-input class="col-sm-2 col-xs-8" label="Zip Code" [(ngModel)]="address.zipCode" [maxLength]="10"
        [name]="'zipCode' + id"
        [readonly]="isReadOnly || (address.country && address.country !== 'us')"
        (blur)="onZipCodeBlurred()"
        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="fieldBeingEdited + '.zipCode'"
        [required]="(address.country && address.country === 'us') && (urlaFieldsConfig[fieldBeingEdited + '.zipCode']?.required == 'required' ||
        urlaFieldsConfig[fieldBeingEdited + '.zipCode']?.required == 'requested')">
    </urla-digits-input>

    <urla-country-dropdown *ngIf="showCountry" class="col-sm-2 col-xs-4" [name]="'country' + id"
      [(ngModel)]="address.country"
      (ngModelChange)="onCountryChanged()"
      [label]="'Country'" [readonly]="isReadOnly"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="fieldBeingEdited + '.country'"
      [required]="urlaFieldsConfig[fieldBeingEdited + '.country']?.required == 'required' ||
        urlaFieldsConfig[fieldBeingEdited + '.country']?.required == 'requested'"
      [showAllCountries]="showAllCountries">
    </urla-country-dropdown>
</div>

