<div class="ineligible-reasons">
  <div class="modal-header">
    <h5 class="modal-title">Adjustments </h5>
    <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <table class="table table-striped table-bordered">
          <thead class="table-light">
              <tr>
                  <th>Amount</th>
                  <th>Reason</th>
                  <th>Type</th>
              </tr>
          </thead>
        <tbody>
            <tr *ngFor="let adjustment of adjustments | filter: { 'isHidden': false } | orderBy: 'valueType'">
                <td>
                  {{adjustment.formattedAmount}}
                </td>
                <td>
                  {{adjustment.reason || adjustment.description}}
                </td>
                <td>
                  {{adjustment.valueType}}
                </td>
            </tr>
        </tbody>
    </table>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()" data-bs-dismiss="modal"> Close </button>
  </div>
</div>
