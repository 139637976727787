import { Component, ComponentRef, EventEmitter, HostListener, Injector, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { Drawer, DrawerAction, DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from '../../services/drawer.service';
import { ApplicationContextBoundComponent } from '../application-context-bound.component';
import { ApplicationContextService } from 'src/app/services/application-context.service';

@Component({
  selector: 'drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef }) componentContainer: ViewContainerRef;

  ngUnsubscribe = new Subject<void>();

  @Input()
  title: string;

  @Input()
  isOpen: boolean = false;

  @Input()
  hasShrinkExpandButton: boolean;

  @Input()
  set options(options: DrawerOptions) {
    this._drawer.options = options;
    if (options) {
      this.setSizeDependentConfigValue(options.size);
    }
  }

  @Input()
  templateRef: TemplateRef<any>;

  @Output()
  opened: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  closed: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  componentLoaded: EventEmitter<any> = new EventEmitter<any>();

  componentInstance: any;

  @Input()
  name: string;

  id: string;

  isTpo: boolean;

  isCallControlPanelOpen: boolean;

  protected isShrink: boolean = false;

  private _rightPosition: string = "-580px";

  private _windowWidth: number = 480;

  private _drawer: Drawer;

  private _callcontrolPanelsubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _drawerService: DrawerService,
    private readonly _applicationContextService: ApplicationContextService
  ) {
    super(injector);

    this.id = uuidv4();
    this._drawer = new Drawer();
    this._callcontrolPanelsubscription = this._applicationContextService.callControlPanelStatChanges.subscribe(context => {
      this.isCallControlPanelOpen = context.isCallControlPanelOpen;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this._callcontrolPanelsubscription) {
      this._callcontrolPanelsubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.isCallControlPanelOpen = this.applicationContext.isCallControlPanelOpen;
    this.isTpo = this.applicationContext?.isTpo;

    this._drawer.name = this.name;
    this._drawerService.getDrawer(this._drawer)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((action: DrawerAction) => {
        if (action.show) {
          if (action.title) {
            this.title = action.title;
          }
          if (action.options) {
            this.setSizeDependentConfigValue(action.options.size);
          }
          this.open(action.dynamicComponentInfo);
          return;
        } else if (this.isOpen) {
          this.close();
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  private contentWidth() {
    const element = document.getElementById(this.id);
    const screenWidth = window.innerWidth;
    if (element.clientWidth >= screenWidth) {
      element.classList.add("full-screen-drawer");
    } else {
      element.classList.remove("full-screen-drawer");
    }
  }

  onCloseClicked = () => {
    const element = document.getElementById(this.id);
    if (element && element.classList.contains('full-screen-drawer')) {
      element.classList.remove("full-screen-drawer");
      this.isShrink = !this.isShrink;
    }
    this._drawerService.hide(this.name, 10);
  }

  onDrawerSizeToggleClicked = () => {
    this.isShrink = !this.isShrink;
    // this.setSizeDependentConfigValue(DrawerSize.XXXXLarge);
    const element = document.getElementById(this.id);
    if (element) {
      if (!this.isShrink) {
        element.classList.remove("full-screen-drawer");
      } else {
        element.classList.add("full-screen-drawer");
      }
    }
  }

  private open = (dynamicComponentInfo?: DynamicComponentInfo<any>) => {
    let dynamicComponentRef: ComponentRef<any> = null;
    if (dynamicComponentInfo) {
      dynamicComponentRef = this.loadDynamicComponent(dynamicComponentInfo);
    }
    this.doOpen(dynamicComponentRef);
  }

  private doOpen = (dynamicComponentRef?: ComponentRef<any>) => {
    this.isOpen = true;
    this.opened.emit(dynamicComponentRef);
    const element = document.getElementById(this.id);
    if (element) {
      const openDrawerCount = this._drawerService.openDrawerCount();
      element.style.right = "0px";
      element.style.width = this._windowWidth + "px";
      element.style.zIndex = (1001 + openDrawerCount).toString();
      setTimeout(() => {
        const screenWidth = window.innerWidth;
        if (this._windowWidth >= screenWidth) {
          element.classList.add("full-screen-drawer");
        } else {
          element.classList.remove("full-screen-drawer");
        }
      })
    }
  }

  private close = () => {
    this.isOpen = false;
    this.componentContainer.clear();
    this.closed.emit();
    const element = document.getElementById(this.id);
    if (element) {
      element.style.right = this._rightPosition;
    }
  }

  private loadDynamicComponent = (dynamicComponentInfo: DynamicComponentInfo<any>): ComponentRef<any> => {
    this.componentContainer.clear();
    if (dynamicComponentInfo && dynamicComponentInfo.componentType) {
      const componentRef = this.componentContainer.createComponent(dynamicComponentInfo.componentType);
      this.componentInstance = componentRef.instance;
      componentRef.instance.config = dynamicComponentInfo.data;

      for (const [key, value] of dynamicComponentInfo.parameters) {
        componentRef.instance[key] = value;
      }
      this.componentLoaded.emit(componentRef.instance);
      return componentRef;
    }
    return null;
  }

  private setSizeDependentConfigValue = (size: DrawerSize) => {
    switch (size) {
      case DrawerSize.Small:
        this._windowWidth = 320;
        this._rightPosition = "-320px";
        break;
      case DrawerSize.Medium:
        this._windowWidth = 480;
        this._rightPosition = "-480px";
        break;
      case DrawerSize.Large:
        this._windowWidth = 640;
        this._rightPosition = "-640px";
        break;
      case DrawerSize.XLarge:
        this._windowWidth = 780;
        this._rightPosition = "-780px";
        break;
      case DrawerSize.XXLarge:
        this._windowWidth = 1180;
        this._rightPosition = "-1180px";
        break;
      case DrawerSize.XXXLarge:
        this._windowWidth = 1600;
        this._rightPosition = "-1600px";
        break;
      case DrawerSize.XXXXLarge:
        this._windowWidth = 1880;
        this._rightPosition = "-1880px";
        break;
    }
  }
}

