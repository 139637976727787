import { AfterViewInit, Component, ElementRef, HostListener, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, firstValueFrom, from, map, Observable, Subscription } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/core/route-guards/pending-changes.guard';
import {
  BuydownDetail,
  EmploymentTypeEnum,
  EscrowDetail,
  Extension,
  KeyDatesByType,
  LegalDescriptions,
  LoanApplication,
  MortgageBorrower,
  MortgageTerm,
  PrepaymentPenaltyDetail,
  SubjectProperty,
  SystemLevel,
  UserType,
} from 'src/app/models';
import { Configuration } from 'src/app/models/configuration.model';
import { MortgageCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';
import { Constants } from 'src/app/services/constants';
import { MenuService } from 'src/app/services/menu.service';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { FileImportDialogComponent } from 'src/app/shared/components/file-import-dialog/file-import-dialog.component';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { LoanPurposeTypeEnum, MortgageAppliedForTypeEnum } from '../../app-details/components/title-history/models/title-order.model';
import {
  Section,
} from '../../app-details/modules/quick-apply/qa-borrowers-info/components/qa-borrower-details/qa-borrower-details.component';
import { AppDetailsService } from '../../app-details/services/app-details.service';
import { BorrowerDto } from '../../contacts/models/borrower-dto.model';
import { BorrowersInfoComponent } from '../borrower-information/borrowers-info/borrowers-info.component';
import { ManageBorrowersComponent } from '../borrower-information/manage-borrowers/manage-borrowers.component';
import { MortgageExportDialogComponent } from '../components/mortgage-export-dialog/mortgage-export-dialog.component';
import { MortgageSaveDialogComponent } from '../components/mortgage-save-dialog/mortgage-save-dialog.component';
import { DeclarationsComponent } from '../declarations/declarations.component';
import { DemographicsComponent } from '../demographics/demographics.component';
import { LoanOriginatorInfoComponent } from '../loan-originator-info/loan-originator-info.component';
import { UrlaBorrower, UrlaEmployment, UrlaMortgage } from '../models/urla-mortgage.model';
import { LateChargeComponent } from '../mortgage-loan-info/late-charge/late-charge.component';
import { PrepaymentPenaltyDetailsComponent } from '../mortgage-loan-info/prepayment-penalty-details/prepayment-penalty-details.component';
import { UtilityService } from '../services/utility.service';
import { AddMortgageBorrowerDialogComponent } from './add-mortgage-borrower-dialog/add-mortgage-borrower-dialog.component';
import { SubjectPropertyTaxesInsuranceComponent } from './subject-property-taxes-insurance/subject-property-taxes-insurance.component';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';
import { LoanService } from 'src/app/services/loan';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { NewApplicationService } from '../../new-application/services/new-application.service';
import { getErrorMessageOrDefault } from '../../../shared/utils/error-utils';
import { MiOrFundingFeeUtils } from '../../../shared/utils/mortgage/mi-or-funding-fee-utils';
import { MortgageUtils } from '../../../shared/utils/mortgage/mortgage-utils';
import { BorrowingEntityInfoComponent } from '../borrowing-entity-info/borrowing-entity-info.component';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { DiffChecker } from 'src/utils/diff-checker';

@Component({
  selector: 'urla-main',
  templateUrl: 'urla-main.component.html',
  styleUrls: ['urla-main.component.scss']
})
export class UrlaMainComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {

  @ViewChild("borrowerInfo") borrowerInfoSection: ElementRef;
  @ViewChild("financialInfo") financialInfoSection: ElementRef;
  @ViewChild("reo") reoSection: ElementRef;
  @ViewChild("loanAndProperty") loanAndPropertySection: ElementRef;
  @ViewChild("declarations") declarationsSection: ElementRef;
  @ViewChild("militaryService") militaryServiceSection: ElementRef;
  @ViewChild("demographics") demographicsSection: ElementRef;
  @ViewChild("loanOriginatorInfo") loanOriginatorInfoSection: ElementRef;
  @ViewChild("propertyLoanInfo") propertyLoanInfoSection: ElementRef;
  @ViewChild("titleInfo") titleInfoSection: ElementRef;
  @ViewChild("mortgageLoanInfo") mortgageLoanInfoSection: ElementRef;
  @ViewChild("qualifyingTheBorr") qualifyingTheBorrSection: ElementRef;
  @ViewChild("homeownershipEducation") homeownershipEducationSection: ElementRef;

  @ViewChild('detailsDrawer')
  detailsDrawer: DrawerComponent;

  @ViewChild("borrowingEntityInfoComponent")
  borrowingEntityInfo: BorrowingEntityInfoComponent;

  @ViewChild("loanOriginatorInfoComp")
  loanOriginatorInfo: LoanOriginatorInfoComponent;

  @ViewChild("declarationsComponent")
  declarationsComponent: DeclarationsComponent;

  @ViewChild("borrowersInfoComponent")
  borrowersInfoComponent: BorrowersInfoComponent;

  @ViewChild("demographicsComponent")
  demographicsComponent: DemographicsComponent;

  @Input()
  showAllSectionsInitially: boolean = true;

  @Input()
  showOnlyOneSectionAtATime: boolean = false;

  @Input()
  secondPageActionBarVisible: boolean = false;

  @Input()
  menuHorizontal: boolean;

  mortgage: UrlaMortgage = null;
  application: LoanApplication = null;
  loanBorrowers: BorrowerDto[] = [];

  dtiInfoText: string;

  isSubjectPropertyAddressHidden: boolean = false;
  isAppraisedValueHidden: boolean = false;
  isReadOnly: boolean = false;

  sectionVisibilityStatuses: any = {};

  hiddenFields: string[] = [];

  isTpoUser: boolean;

  isUrlaSaving: boolean = false;

  tpoSubmitted: boolean = false;

  protected isLoanReadOnly: boolean = false;

  private _sectionIntersectionObservers: Map<string, IntersectionObserver> = new Map<string, IntersectionObserver>();

  private _isListeningToSectionVisibilityEvents: boolean = false;

  detailsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Medium,
    containerWrapperId: null
  };

  private _sections: Section[] = [
    { header: 'collapseBorrowersInfo', id: 'borrowerInfo', order: 0 },
    { header: 'collapseFinancialInfo', id: 'financialInfo', order: 0 },
    { header: 'collapseReo', id: 'reo', order: 0 },
    { header: 'collapseLoanPropertyInfo', id: 'loanAndProperty', order: 0 },
    { header: 'collapseDeclerations', id: 'declarations', order: 0 },
    { header: 'collapseMilitaryService', id: 'militaryService', order: 0 },
    { header: 'collapseDemographics', id: 'demographics', order: 0 },
    { header: 'collapseLoanOriginatorInfo', id: 'loanOriginatorInfo', order: 0 },
    { header: 'propertyLoanAccordion', id: 'propertyLoanInfo', order: 0 },
    { header: 'titleInfoAccordion', id: 'titleInfo', order: 0 },
    { header: 'loanInfoAccordion', id: 'mortgageLoanInfo', order: 0 },
    { header: 'collapseQualifyingTheBorr', id: 'qualifyingTheBorr', order: 0 },
    { header: 'collapseHomeownershipEducation', id: 'homeownershipEducation', order: 0 }
  ];

  set selectedSection(section: string) {

    this._selectedSection = section;
    this.sectionVisibilityStatuses[section] = true;

    if (this.showOnlyOneSectionAtATime) {
      for (let [key, value] of Object.entries(Constants.menu.urlaMenuItems)) {
        if (section !== value) {
          this.sectionVisibilityStatuses[value] = false;
        }
      }
    }

    const sectionElement = this.getElementForSection(section);
    if (sectionElement) {
      const y = sectionElement.getBoundingClientRect().top + window.scrollY - 200;
      window.scroll({
        top: y,
        behavior: 'smooth'
      });
      setTimeout(() => {
        this.visibleSection = null;
      }, 1000)
    }
  }

  get selectedSection(): string {
    return this._selectedSection;
  }

  originalMortgage: UrlaMortgage;

  fakeMortgageModel: UrlaMortgage = new UrlaMortgage();

  urlaFieldsConfig: {} = {};

  originalUrlaFieldsConfig: {} = {};

  inEditMode: boolean = false;

  isRequiredLoanAndCaseNumbers: boolean = false;

  generatingMers: boolean = false;

  isAdmin: boolean = false;

  isMersEnabled: boolean = false;

  systemLevel: SystemLevel;

  protected visibleSection: Section = null;

  protected mortgageCalculationDetails: MortgageCalculationDetails;

  protected activeEditingBorrowerName: string;

  protected hasSubjectPropertyRentalIncome: boolean = true;

  private _visibleSections: Section[] = [this._sections[0]];

  private _selectedSection: string = 'test';

  private _applicationContextSubscription: any;

  private _purposeOfLoanChangedSubscription: Subscription;

  private _originalMortgage: UrlaMortgage;

  private _menuServiceEventSubscription: Subscription | null = null;

  private _incomeOrLiabilitiesChangedSubscription: Subscription | null = null;

  private _callControlPanelStatChangesSubscription: Subscription | null = null;

  private _selectedBorrowerViaTab: MortgageBorrower | null = null;

  private _eventSubscriptions: Subscription[] = [];

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _loanDocService: LoanDocService,
    private readonly _newApplicationService: NewApplicationService,
    private readonly _utilsService: UtilityService,
    private readonly _mortgageService: MortgageService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _menuService: MenuService,
    private readonly _drawerService: DrawerService,
    private readonly _appService: AppDetailsService,
    private readonly _urlaValidationService: UrlaValidationService,
    private readonly _loanService: LoanService,
    private readonly _systemLevelService: SystemLevelService,
    injector: Injector,
  ) {
    super(injector);
    this.secondPageActionBarVisible = this.applicationContext.isCallControlPanelOpen;
    this.isTpoUser = this.applicationContext.isTpo;
    this.isAdmin = this.applicationContext.userPermissions?.admin || this.applicationContext.userPermissions?.superAdmin;
    this.isMersEnabled = this.applicationContext.userPermissions?.mersEnabled;
    this._callControlPanelStatChangesSubscription = this.applicationContextService.callControlPanelStatChanges.subscribe(context => {
      this.secondPageActionBarVisible = context.isCallControlPanelOpen;
    })

    this._applicationContextSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.application = context.application;
        this.isLoanReadOnly = context.applicationIsReadOnly;
        this.mortgage = context.currentMortgage;
        this.loanBorrowers = context.borrowers;
        this.mortgageCalculationDetails = context.currentMortgageCalculationDetails;
        this._originalMortgage = _.cloneDeep(this.mortgage);
        this.setDtiInfoText(this.applicationContext.currentMortgageCalculationDetails);
        if (!this.isReadOnly) {
          this._utilsService.updateUrlaFieldConfigBasedOnMortgage(this.urlaFieldsConfig, this.mortgage, !!this.application.losIdentifier);
          this._urlaValidationService.updateUrlaFieldConfigBasedOnMortgage(this.mortgage, this.application);
        }
      }
    });

    this._purposeOfLoanChangedSubscription = this._mortgageService.loanPurpose$
      .subscribe((updatedPurposeOfLoan: string) => {
        const subjectProperty = this.mortgage?.subjectProperty;
        if (!subjectProperty) return;

        subjectProperty.purposeOfLoan = updatedPurposeOfLoan;
      });

    this._menuServiceEventSubscription = this._menuService.menuItemClicked.subscribe(menuItemId => {
      this._menuService.setActiveMenuItem(menuItemId);
      setTimeout(() => {
        this.visibleSection = this._sections.find(s => s.id === menuItemId);
        this.selectedSection = menuItemId;
      }, 100);
    });
  }

  ngOnInit(): void {

    if (this.isTpoUser) {
      this._loanService.getKeyDatesByType(this.applicationContext.application.applicationId).subscribe((keyDates: KeyDatesByType) => {
        if (this.application.channel == 'Wholesale' || this.application.channel == 'NonDelegatedCorrespondent') {
          if (keyDates?.leIssued?.eventDate || keyDates?.initialDisclosureSent?.eventDate || keyDates?.tpoSubmitted?.eventDate) {
            this.isReadOnly = true;
            this.urlaFieldsConfig = this._utilsService.allFieldStateNone();
          }
        }
        else if (this.application.channel == 'Correspondent') {
          if (keyDates?.purchased?.eventDate) {
            this.isReadOnly = true;
            this.urlaFieldsConfig = this._utilsService.allFieldStateNone();
          }
        }
      });
    }

    this.fakeMortgageModel = this._utilsService.loadFakeMortgageModel(this.fakeMortgageModel);

    if (this.applicationContext.application) {
      this.isLoanReadOnly = this.applicationContext.applicationIsReadOnly;
      this.application = this.applicationContext.application;
      this.mortgage = this.applicationContext.currentMortgage;
      this.loanBorrowers = this.applicationContext.borrowers;
      this.mortgageCalculationDetails = this.applicationContext.currentMortgageCalculationDetails;
      this.isRequiredLoanAndCaseNumbers = this.application.channel !== 'Wholesale' || !this.isTpoUser;

      this.urlaFieldsConfig = this.isReadOnly ? this._utilsService.allFieldStateNone() : (this.mortgage.companyId == 229 || this.mortgage.companyId == 276) ? 
        this._utilsService.loadDeepHavenUrlaFieldsConFig(this.urlaFieldsConfig) : this._utilsService.loadDefaultUrlaFieldsConFig(this.urlaFieldsConfig);

      if (!this.isReadOnly) {
        this._utilsService.updateUrlaFieldConfigBasedOnMortgage(this.urlaFieldsConfig, this.mortgage, !!this.application.losIdentifier);
        this._urlaValidationService.updateUrlaFieldConfigBasedOnMortgage(this.mortgage, this.application);
      }

      this._originalMortgage = _.cloneDeep(this.mortgage);

      this.setDtiInfoText(this.applicationContext.currentMortgageCalculationDetails);

      this._menuService.setActiveMenuItem(this._sections[0].id);


      // Initialize mortgageAppliedFor
      this.emitProductTypeChange(this.mortgage
        .mortgageTerm.mortgageAppliedFor as MortgageAppliedForTypeEnum);
    }

    this.originalUrlaFieldsConfig = _.cloneDeep(this.urlaFieldsConfig);

    if (this.isReadOnly) {
      this._utilsService.updateUrlaFieldConfigBasedOnMortgage(this.urlaFieldsConfig, this.mortgage, !!this.application.losIdentifier);
    }

    this._appService.getLoanHiddenFields().subscribe((response) => {
      this.hiddenFields = this.getHiddenFields(response);
      this.isSubjectPropertyAddressHidden = this.hiddenFields.findIndex(f => f === "Subject Property") > -1;
      this.isAppraisedValueHidden = this.hiddenFields.findIndex(f => f === "Appraised Value") > -1;
    });

    for (let [key, value] of Object.entries(Constants.menu.urlaMenuItems)) {
      if (key !== Constants.menu.urlaMenuItems.borrowerInfo) {
        this.sectionVisibilityStatuses[value] = this.showAllSectionsInitially;
      } else {
        this.sectionVisibilityStatuses[value] = true;
      }
    }

    this._systemLevelService.getSystemLevel().subscribe((response) => {
      this.systemLevel = response;
    }, (error) => {
      this._notificationService.showError(error && error.message ? error.message : "Unable to fetch System Level Info.", "Error!");
    })
  }

  ngAfterViewInit(): void {
    // this._incomeOrLiabilitiesChangedSubscription = this.applicationContextService.mortgageIncomeOrLiabilitiesChangedEvent.subscribe((mortgage) => {
    //   this.getRealtimeCalcDtiInfoText(mortgage);
    // })
    setTimeout(() => {
      this.startObservingSectionVisibility();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stopObservingSectionVisibility();
    if (this._menuServiceEventSubscription) {
      this._menuServiceEventSubscription.unsubscribe();
    }
    if (this._applicationContextSubscription) {
      this._applicationContextSubscription.unsubscribe();
    }
    if (this._callControlPanelStatChangesSubscription) {
      this._callControlPanelStatChangesSubscription.unsubscribe();
    }
    if (this._incomeOrLiabilitiesChangedSubscription) {
      this._incomeOrLiabilitiesChangedSubscription.unsubscribe();
    }
    if (this._purposeOfLoanChangedSubscription) {
      this._purposeOfLoanChangedSubscription.unsubscribe();
    }
    this._eventSubscriptions.forEach(subscription => {
      subscription?.unsubscribe();
    })
  }

  onBorrowerReosChanged = () => {
    this.mortgage = { ...this.mortgage };
    this._menuService.setStatus('reo', this._urlaValidationService.getStatusForReo(this.applicationContext.application.mortgageLoan));
    this.selectBorrowerTabs(this._selectedBorrowerViaTab);
  }

  onLoanPurposeChanged = () => {
    this._mortgageService.loanPurpose =
      this.mortgage.subjectProperty.purposeOfLoan as LoanPurposeTypeEnum;
    this.mortgage = { ...this.mortgage };
  }

  confirm = (): boolean | Observable<boolean> => {
    return from(this.showNotSavedDialog());
  }

  onSectionHeadingClicked = (section: string) => {
    this.sectionVisibilityStatuses[section] = !this.sectionVisibilityStatuses[section];
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // dont check when session expires
    if (!this.applicationContext) return true;

    const isDirty = this.checkIfMortgageIsDirty();
    return !isDirty;
  }

  onRefreshLoanOriginatorInfoRequested = () => {
    const isDirty = this.checkIfMortgageIsDirty();
    if (isDirty) {
      const modalRef = this._modalService.open(MortgageSaveDialogComponent, Constants.modalOptions.large);
      modalRef.result.then((result) => {
        if (result == 'save') {
          // Save and call refresh
          this._spinner.show();
          this.saveChanges().subscribe(() => {
            this.refreshOriginatorInfo();
          }, error => {
            this._spinner.hide();
            this._notificationService.showError(error && error.message ? error.message : "An error occurred while saving the mortgage.", "Error!");
          })
        } else if (result == 'discard') {
          this.discardChanges();
          this.refreshOriginatorInfo();
        }
      }, (error) => {
      });
    } else {
      this.refreshOriginatorInfo();
    }
  }

  onBorrowerTabSelected = (e: BorrowerTabSelectedEvent) => {
    this._selectedBorrowerViaTab = e.selectedBorrower;
    this.activeEditingBorrowerName = this._utilsService.getBorrowerFullName(e.selectedBorrower);
    switch (e.sourceComponent) {
      case TabbedBorrowerComponentType.Declarations:
        this.borrowersInfoComponent?.selectBorrowerTab(e.selectedBorrower);
        this.demographicsComponent?.selectBorrowerTab(e.selectedBorrower);
        break;
      case TabbedBorrowerComponentType.Demographics:
        this.borrowersInfoComponent?.selectBorrowerTab(e.selectedBorrower);
        this.declarationsComponent?.selectBorrowerTab(e.selectedBorrower);
        break;
      case TabbedBorrowerComponentType.BorrowersInfo:
        this.declarationsComponent?.selectBorrowerTab(e.selectedBorrower);
        this.demographicsComponent?.selectBorrowerTab(e.selectedBorrower);
        break;
    }
  }

  onGenerateMERS = () => {
    this.generatingMers = true;
    this._appService.generateMERS(this.application.applicationId)
      .subscribe({
        next: (response: MortgageTerm) => {
          this.mortgage.mortgageTerm = response;
        }, error: (err) => {
          this._notificationService.showError(err?.message || 'Unable to generate MERS Number', 'Error!');
        }
      })
      .add(() => this.generatingMers = false);
  }

  onImportClicked = () => {
    const modalRef = this._modalService.open(FileImportDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.title = "Import MISMO 3.4";
    modalRef.componentInstance.message = "Please upload a valid MISMO 3.4 XML file.";
    modalRef.componentInstance.multiple = false;
    modalRef.componentInstance.accept = ".xml";
    modalRef.result.then((result) => {
      if (result && result.length > 0) {
        this._spinner.show();
        this._mortgageService.importMismoFile(this.mortgage.applicationId,
          this.mortgage.mortgageId, result[0]).subscribe(mortgage => {

            var loanDoc = {
              loanDocId: null,
              applicationId: this.mortgage.applicationId,
              borrowerID: null,
              documentTypeId: 0,
              description: 'Subsequent submission MISMO file',
              note: 'Subsequent submission MISMO file',
              expirationDate: null,
              retask: false,
              active: true,
              docFiles: null
            };

            this._loanDocService.upsertLoanDoc(loanDoc).subscribe((loanDocResponse) => {
              this._newApplicationService.uploadFileForLoanDoc(result[0], loanDocResponse.loanDocId, false, false, false).subscribe();
            }).add(() => {
              this.applicationContextService.reloadApplicationAndMortgagePostAction(this.application.applicationId).subscribe(context => {
                this._spinner.hide();
                this._notificationService.showSuccess("MISMO 3.4 file has been successfully imported.", "Success");
              });
            });

          }, error => {
            this._spinner.hide();
            this._notificationService.showError(error ? error.message : "An error occurred while loading the mortgage.", "Error!")
          });
      }
    }, (err) => {
    });
  }

  onExportUrlaDocsClicked = () => {
    const isDirty = this.checkIfMortgageIsDirty();
    if (isDirty) {
      const modalRef = this._modalService.open(MortgageExportDialogComponent, Constants.modalOptions.xlarge);
      modalRef.result.then((result) => {
        if (result === 'save') {
          this._spinner.show();
          this.saveChanges().subscribe(() => {
            this._spinner.hide();
            this.exportUrlaDocs();
          }, error => {
            this._spinner.hide();
            this._notificationService.showError(error && error.message ? error.message : "An error occurred while saving the mortgage.", "Error!");
          })
        } else if (result === 'discard') {
          // Discarding changes - then, we'll export
          this.discardChanges();
          this.exportUrlaDocs();
        }
      }, (err) => {
        this._spinner.hide();
      });
    } else {
      this.exportUrlaDocs();
    }
  }

  onExportMismoClicked = (mappingTarget: string) => {
    const isDirty = this.checkIfMortgageIsDirty();
    if (isDirty) {
      const modalRef = this._modalService.open(MortgageExportDialogComponent, Constants.modalOptions.xlarge);
      modalRef.result.then((result) => {
        if (result === 'save') {
          this._spinner.show();
          this.saveChanges().subscribe(() => {
            this._spinner.hide();
            this.exportMismoFile(mappingTarget);
          }, error => {
            this._spinner.hide();
            this._notificationService.showError(error && error.message ? error.message : "An error occurred while saving the mortgage.", "Error!");
          })
        } else if (result === 'discard') {
          // Discarding changes - then, we'll export
          this.discardChanges();
          this.exportMismoFile(mappingTarget);
        }
      }, (err) => {
        this._spinner.hide();
      });
    } else {
      this.exportMismoFile(mappingTarget);
    }
  }

  onSaveClicked = () => {
    if (!this.validateFormerEmploymentStartEndDates()) {
      Swal.fire(
        'Invalid Start/End Dates',
        'There are former employments where the end of employment is earlier than the start.',
        'error'
      )
      return;
    }
    if (!this.validateOwningBorrowerIdsForFinancialSection()) {
      return;
    }

    if (this.haveSameEmails()) {
      Swal.fire(
        'Same Emails',
        'Borrowers cannot have the same emails.',
        'error'
      )
      return;
    }

    this.isUrlaSaving = true;
    this._spinner.show();
    this.saveChanges().subscribe(() => {
      this.isUrlaSaving = false;
      this._spinner.hide();
      this._notificationService.showSuccess("Your changes have been successfully saved.", "Success!");
    }, error => {
      this.isUrlaSaving = false;
      this._spinner.hide();
      this._notificationService.showError(error && error.message ? error.message : "An error occurred while saving the mortgage.", "Error!");
    });
  }

  haveSameEmails = (): boolean => {
    let sameEmailCounts = 0;

    for (let i = 0; i < this.mortgage.borrowers.length - 1; i++) {
      for (let j = i + 1; j < this.mortgage.borrowers.length; j++) {
        let oldEmail = this.mortgage.borrowers[i].primaryEmail;
        let newEmail = this.mortgage.borrowers[j].primaryEmail;
        if (oldEmail && newEmail && oldEmail == newEmail) {
          sameEmailCounts++;
          break;
        }
      }
      if (sameEmailCounts > 0) {
        break;
      }
    }

    return sameEmailCounts > 0;
  }

  onManageBorrowersClicked = () => {
    const isDirty = this.checkIfMortgageIsDirty();
    if (isDirty) {
      return from(this.showNotSaveDialogForManageBorrowers());
    } else {
      return this.showManageBorrowers();
    }
  }

  onAddNewBorrowerClicked = () => {
    const modalRef = this._modalService.open(AddMortgageBorrowerDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.mortgage = _.cloneDeep(this.mortgage);

    modalRef.result.then((selectedBorrower: MortgageBorrower) => {
      this._appService.getBorrowers(this.application.applicationId).subscribe((borrowers) => {
        selectedBorrower.mortgageId = this.mortgage.mortgageId;
        this.mortgage.borrowers.push(selectedBorrower);

        this.adjustBorrowers(this.mortgage.borrowers);
        this.mortgage = { ...this.mortgage };
        this.applicationContextService.updateMortgageAndApplication(this.mortgage, this.application, undefined, borrowers);
      })
    }, error => { })
  }

  private adjustBorrowers = (borrowers: MortgageBorrower[]) => {
    let nextIndex = Math.max.apply(Math, borrowers.map(o => o.printApplicationIndex > -1 ? o.printApplicationIndex : -1)) + 1;
    borrowers.forEach((borrower) => {
      if (borrower.printApplicationIndex == undefined || borrower.printApplicationIndex == null) {
        borrower.printApplicationIndex = nextIndex;
        nextIndex++;
      }
    });
    borrowers = _.orderBy(borrowers, ['printApplicationIndex', 'jointWithBorrowerId'], ['asc', 'desc']);
  }

  onPurchasePriceAmountChanged = (newPurchasePriceAmount: number) => {
    this.recalculateLtvAndRelatedValues();
  }

  onLoanAmountChanged = (newLoanAmount: number) => {
    this.recalculateLtvAndRelatedValues();
  }

  onPresentValueChanged = (newPresentValue: number) => {
    this.recalculateLtvAndRelatedValues();
  }

  onProductTypeChanged = (newProductType: string) => {
    this.recalculateLtvAndRelatedValues();
    this.emitProductTypeChange(newProductType as MortgageAppliedForTypeEnum);
  }

  private emitProductTypeChange(productType: MortgageAppliedForTypeEnum): void {
    this._mortgageService.appliedFor = productType;
  }

  miAndFundingFeeChange = (miAndFundingFee: number) => {
    this.recalculateLtvAndRelatedValues();
  }

  editMode = async () => {
    if (!this.inEditMode) {
      this.originalMortgage = this.mortgage;
      this.inEditMode = true;
      this.mortgage = this.fakeMortgageModel;
    } else {
      const diffChecker = new DiffChecker(this.urlaFieldsConfig, this.originalUrlaFieldsConfig, 'urlaFieldsConfig');
      const differences = diffChecker.calculateDiff();
      if (!!differences) {
        const result = await Swal.fire({
          showDenyButton: true,
          title: 'Are you sure?',
          text: `You're getting out of Edit Mode and you have unsaved changes. What would you like to do with them?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Save & Continue!',
          cancelButtonText: 'Cancel',
          cancelButtonColor: "#DD6B55",
          denyButtonText: `Discard & Continue!`,
          reverseButtons: true
        });

        if (result.isConfirmed) {
          this.originalUrlaFieldsConfig = _.cloneDeep(this.urlaFieldsConfig);
          this.mortgage = this.originalMortgage;
          this.inEditMode = false;
        }
        if (result.isDenied) {
          this.urlaFieldsConfig = _.cloneDeep(this.originalUrlaFieldsConfig);
          this.mortgage = this.originalMortgage;
          this.inEditMode = false;
        }
      } else {
        this.mortgage = this.originalMortgage;
        this.inEditMode = false;
      }
     
    }
  }

  editModeSave = () => {
    this.originalUrlaFieldsConfig = _.cloneDeep(this.urlaFieldsConfig);
  }

  protected onTexasAndInsuranceClicked = () => {
    const subjectPropertyTaxesAndInsuranceDetails = new SubjectPropertyTaxesAndInsuraceDetails();
    subjectPropertyTaxesAndInsuranceDetails.escrowDetails = this.mortgage.escrowDetail;
    subjectPropertyTaxesAndInsuranceDetails.subjectProperty = this.mortgage.subjectProperty;

    this._drawerService.hide("detailsDrawer");
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = SubjectPropertyTaxesInsuranceComponent;
    dynamicComponentInfo.parameters.set('subjectPropertyTaxesAndInsuranceDetails', subjectPropertyTaxesAndInsuranceDetails);
    this._drawerService.show("detailsDrawer", 100, "Subject Property Taxes and Insurance", dynamicComponentInfo).then(() => {
      const cancelledEventSubscription = this.detailsDrawer.componentInstance.cancelled.subscribe(() => {
        this._drawerService.hide("detailsDrawer");
      });
      this._eventSubscriptions.push(cancelledEventSubscription);
      const changesApprovedEventSubscription = this.detailsDrawer.componentInstance.changesApproved.subscribe((editedSubjectPropertyTaxesAndInsurance: SubjectPropertyTaxesAndInsuraceDetails) => {
        this._drawerService.hide("detailsDrawer");
        if (editedSubjectPropertyTaxesAndInsurance.escrowDetails) {
          if (!this.mortgage.escrowDetail) {
            this.mortgage.escrowDetail = new EscrowDetail();
          }
          this.mortgage.escrowDetail.escrowsAreWaivedByLender = editedSubjectPropertyTaxesAndInsurance.escrowDetails?.escrowsAreWaivedByLender || null;
        }
        if (editedSubjectPropertyTaxesAndInsurance.subjectProperty) {
          this.mortgage.subjectProperty = editedSubjectPropertyTaxesAndInsurance.subjectProperty;
        }
      });
      this._eventSubscriptions.push(changesApprovedEventSubscription);
    });
  }

  protected onPrepaymentPenaltyClicked = () => {
    this._drawerService.hide("detailsDrawer");
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = PrepaymentPenaltyDetailsComponent;

    if (!this.mortgage.extension) {
      this.mortgage.extension = new Extension();
    }

    dynamicComponentInfo.parameters.set('details', this.mortgage.extension.prepaymentPenaltyDetail);
    dynamicComponentInfo.parameters.set('totalLoanAmount', this.mortgage.mortgageTerm.totalLoanAmount);
    dynamicComponentInfo.parameters.set('readonly', this.isReadOnly);
    this._drawerService.show("detailsDrawer", 100, "Prepayment Penalty Details", dynamicComponentInfo).then(() => {
      const cancelledEventSubscription = this.detailsDrawer.componentInstance.cancelled.subscribe(() => {
        this._drawerService.hide("detailsDrawer");
      });
      this._eventSubscriptions.push(cancelledEventSubscription);
      const changesApprovedEventSubscription = this.detailsDrawer.componentInstance.changesApproved.subscribe((details: PrepaymentPenaltyDetail) => {
        this._drawerService.hide("detailsDrawer");
        this.mortgage.extension.prepaymentPenaltyDetail = details;
      });
      this._eventSubscriptions.push(changesApprovedEventSubscription);
    });
  }

  protected onLateChargeClicked = () => {
    this._drawerService.hide("detailsDrawer");
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = LateChargeComponent;
    dynamicComponentInfo.parameters.set('mortgageTerm', this.mortgage.mortgageTerm);
    this._drawerService.show("detailsDrawer", 100, "Late Charge", dynamicComponentInfo).then(() => {
      const cancelledEventSubscription = this.detailsDrawer.componentInstance.cancelled.subscribe(() => {
        this._drawerService.hide("detailsDrawer");
      });
      this._eventSubscriptions.push(cancelledEventSubscription);
      const changesApprovedEventSubscription = this.detailsDrawer.componentInstance.changesApproved.subscribe((mortgageTerm: MortgageTerm) => {
        this._drawerService.hide("detailsDrawer");
        this.mortgage.mortgageTerm = mortgageTerm;
      });
      this._eventSubscriptions.push(changesApprovedEventSubscription);
    });
  }

  private selectBorrowerTabs = (borrower: MortgageBorrower) => {
    this.borrowersInfoComponent?.selectBorrowerTab(borrower);
    this.declarationsComponent?.selectBorrowerTab(borrower);
    this.demographicsComponent?.selectBorrowerTab(borrower);
  }

  private recalculateLtvAndRelatedValues = () => {
    this._spinner.show();
    this._mortgageService
      .redoMortgageCalculationDetails(this.mortgage)
      .pipe(
        finalize(() => {
          this._spinner.hide();
        }),
      )
      .subscribe({
        next: (result: MortgageCalculationDetails) => {
          this.mortgageCalculationDetails = result;
        },
        error: (err: any) => {
          const defaultMessage =
            'An error occurred while recalculating LTV and related values.';
          console.error(defaultMessage, err);
          this._notificationService.showError(
            getErrorMessageOrDefault(err, { defaultMessage }),
            'Error',
          );
        },
      });
  };

  private validateOwningBorrowerIdsForFinancialSection = (): boolean => {
    const assetsWithoutOwners = this.mortgage.assets?.filter(a => !a.owningBorrowerIds || a.owningBorrowerIds.length === 0) || [];
    const liabilitiesWithoutOwners = this.mortgage.liabilities?.filter(l => !l.owningBorrowerIds || l.owningBorrowerIds.length === 0) || [];
    const reosWithoutOwners = this.mortgage.realEstateOwned?.filter(reo => !reo.owningBorrowerIds || reo.owningBorrowerIds.length === 0) || [];

    const isInvalid = assetsWithoutOwners.length || liabilitiesWithoutOwners.length || reosWithoutOwners.length;
    if (!isInvalid) {
      return true;
    }
    let invalidGroups: string[] = [];
    if (assetsWithoutOwners.length) {
      invalidGroups.push("Assets");
    }
    if (liabilitiesWithoutOwners.length) {
      invalidGroups.push("Liabilities");
    }
    if (reosWithoutOwners.length) {
      invalidGroups.push("Real Estate Owned");
    }
    var span = document.createElement("span");
    span.innerHTML = `The following information you provided under Financial Info have invalid items, since their account owners are not specified. Please make sure ` +
      `the account owners are properly set.<br><br><strong>${invalidGroups.join(", ")}</strong>`
    if (isInvalid) {
      Swal.fire({
        title: 'There are some invalid items',
        icon: 'error',
        html: span,
        confirmButtonText: 'OK'
      }).then((result: SweetAlertResult) => {
        if (!result.value) return;
      });
    }
  }

  private refreshOriginatorInfo = () => {
    this._mortgageService.refreshOriginator(this.mortgage.applicationId).subscribe(originatorInfo => {
      originatorInfo.stateLicenseState = originatorInfo.stateLicenseState ? originatorInfo.stateLicenseState.toLowerCase() : null;
      this.mortgage.originatorInformation = originatorInfo;
      if (originatorInfo.originatorInformationId > 0)
        this.mortgage.originatorInformationId = originatorInfo.originatorInformationId;
      // Save it one last time, so that the form is not dirty!
      this.saveChanges().subscribe(() => {
        this._notificationService.showSuccess("Originator info refreshed successfully.", "Success!");
      }, error => {
        this._notificationService.showError(error && error.message ? error.message : "An error occurred while saving the mortgage.", "Error!");
      }).add(() => {
        this._spinner.hide;
      });
    }, error => {
      this._notificationService.showError(error ? error.message : "An error occurred while refreshing the originator info.", "Error!");
    })
  }

  private exportMismoFile = (mappingTarget: string) => {
    this._spinner.show();
    this.mortgage.borrowers.forEach(borrower => {
      borrower.lastName = borrower.lastName.trim();
    });
    this._mortgageService.exportMismoFile(this._originalMortgage.applicationId, mappingTarget).subscribe(() => {
      this._spinner.hide();
    }, error => {
      this._spinner.hide();
      this._notificationService.showError(error ? error.message : "An error occurred while exporting MISMO.", "Error!");
    });
  }

  private exportUrlaDocs = () => {
    this._spinner.show();
    this._mortgageService.exportUrlaDocs(this._originalMortgage.applicationId).subscribe(() => {
      this._spinner.hide();
      this._notificationService.showSuccess("URLA docs exported successfully as loan docs.", "Success!");
    }, error => {
      this._spinner.hide();
      this._notificationService.showError(error ? error.message : "An error occurred while exporting URLA docs.", "Error!")
    });
  }

  private discardChanges = () => {
    this.mortgage = _.cloneDeep(this._originalMortgage);
    this._originalMortgage = _.cloneDeep(this.mortgage);
    this.applicationContextService.updateMortgage(this.mortgage);
  }

  private saveChanges = (): Observable<any> => {
    let loanInfo = {
      application: this.application,
      customData: null
    };

    this.mapCalculatedStatsToMortgage();

    // if (this.mortgage.ausData?.ausResults && this.mortgage.ausData.ausResults.length > 0) {
    //   this.mortgage.ausData.ausResults.forEach(res => {
    //     res.decisionDateTime = new Date(res.decisionDateTime).toISOString();
    //   })
    // }

    loanInfo.application.mortgageLoan = this.mortgage;
    this.mortgage.mortgageTerm.totalMortgageLoanAmount = this.mortgage.calculatedStats.totalMortgageLoans;
    MiOrFundingFeeUtils.trySetEntireMiOrFundingFee(this.mortgage.mortgageInsuranceDetail);
    if (!this.hasSubjectPropertyRentalIncome) {
      MortgageUtils.clearSubjectPropertyRentalIncomeProperties(this.mortgage);
    }
    this._menuService.isTouched = false;
    return this._appService.saveLoanInfo(this.application.applicationId, loanInfo).pipe(map(loanInfo => {

      if (loanInfo.application.losIdentifier && this.applicationContext.userPermissions.userType == UserType.Tpo) {
        //todo: sync with LOS on save

        this.applicationContextService.updateMortgageAndApplication(loanInfo.application.mortgageLoan, loanInfo.application);
        // this._loanService.auto
      }
      else {
        this.applicationContextService.updateMortgageAndApplication(loanInfo.application.mortgageLoan, loanInfo.application);

      }
    }));
  }

  private setDtiInfoText = (calcs: MortgageCalculationDetails) => {
    if (calcs && calcs.dti) {
      this.dtiInfoText = `DTI (front/back): ${calcs.dti?.frontEndDti}% / ${calcs.dti?.backEndDti}%`;
    } else {
      this.dtiInfoText = 'DTI (front/back): 0% / 0%';
    }
  }

  private checkIfMortgageIsDirty = () => {
    if (this.isReadOnly) {
      return false;
    }
    this._spinner.show();
    let copyForChangedMortgage = _.cloneDeep(this.mortgage);
    this.cleanMortgageFromCalculatedStatsAndOtherDefaults(copyForChangedMortgage);
    let copyForOriginalMortgage = _.cloneDeep(this._originalMortgage);
    this.cleanMortgageFromCalculatedStatsAndOtherDefaults(copyForOriginalMortgage);
    this._spinner.hide();
    return this._utilsService.logDiffs('mortgage', copyForOriginalMortgage, copyForChangedMortgage);
  }

  private cleanMortgageFromCalculatedStatsAndOtherDefaults = (mortgage: UrlaMortgage) => {
    if (!mortgage) {
      return;
    }
    mortgage.calculatedStats = undefined;
    if (mortgage.proposedHousingExpense) {
      if (!mortgage.proposedHousingExpense.countyPropertyTax)
        mortgage.proposedHousingExpense.countyPropertyTax = mortgage.proposedHousingExpense.realEstateTax;
      if (!mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance)
        mortgage.proposedHousingExpense.otherSupplementalPropertyInsurance = 0;
      if (!mortgage.proposedHousingExpense.homeownersAssociationDues)
        mortgage.proposedHousingExpense.homeownersAssociationDues = 0;
      if (!mortgage.proposedHousingExpense.supplementalPropertyInsurance)
        mortgage.proposedHousingExpense.supplementalPropertyInsurance = 0;
    }

    if (!mortgage.subjectProperty.legalDescriptions)
      mortgage.subjectProperty.legalDescriptions = new Array<LegalDescriptions>;

    if (mortgage.subjectProperty.legalDescriptions.length == 0)
      mortgage.subjectProperty.legalDescriptions.push(new LegalDescriptions());

    if (!mortgage.extension)
      mortgage.extension = new Extension();

    if (!mortgage.extension.buydownDetail)
      mortgage.extension.buydownDetail = new BuydownDetail();

    if (mortgage.ausData && !mortgage.ausData.freddieMacLoanPrograms)
      mortgage.ausData.freddieMacLoanPrograms = [];

    mortgage.subjectProperty?.legalDescriptions?.forEach(x => { if (!x.parsedLegalDescriptions) x.parsedLegalDescriptions = []; })

    mortgage.assets = mortgage.assets || [];
    mortgage.liabilities = mortgage.liabilities || [];
    mortgage.realEstateOwned = mortgage.realEstateOwned || [];
    mortgage.assets?.forEach(model => this.ConvertOwningBorrowerIds(model));
    mortgage.liabilities?.forEach(model => { this.ConvertOwningBorrowerIds(model); model['displayAccountNumber'] = undefined; });
    mortgage.realEstateOwned?.forEach(model => this.ConvertOwningBorrowerIds(model));

    mortgage.borrowers.forEach(borrower => {
      (<UrlaBorrower>borrower).calculatedStats = undefined;
      borrower.employments.forEach(employment => {
        (<UrlaEmployment>employment).calculatedStats = undefined;
      })
      borrower.residencyAddresses = borrower.residencyAddresses
        .filter(ad => !_.isNil(ad.residencyAddressId) ||
          !_.isNil(ad.address.address1) ||
          !_.isNil(ad.address.address2) ||
          !_.isNil(ad.address.city) ||
          !_.isNil(ad.address.state) ||
          !_.isNil(ad.address.zipCode) ||
          !_.isNil(ad.address.country) ||
          !_.isNil(ad.durationMonths) ||
          !_.isNil(ad.durationYears) ||
          !_.isNil(ad.residencyBasis)
        );
      borrower.residencyAddresses.forEach(address => {
        if (address.durationMonths === 0) {
          address.durationMonths = null;
        }
        if (address.durationYears === 0) {
          address.durationYears = null;
        }
      });
    });
  }

  private ConvertOwningBorrowerIds(model: any) {
    let borrIds = [];
    model.owningBorrowerIds.forEach(id => {
      borrIds.push(id.toString());
    });
    model.owningBorrowerIds = borrIds;
    delete model['owners'];
  }

  private getElementForSection = (section: string): HTMLElement => {
    if (section === 'borrowerInfo') {
      return this.borrowerInfoSection.nativeElement;
    }
    if (section === 'financialInfo') {
      return this.financialInfoSection.nativeElement;
    }
    if (section === 'reo') {
      return this.reoSection.nativeElement;
    }
    if (section === 'loanAndProperty') {
      return this.loanAndPropertySection.nativeElement;
    }
    if (section === 'declarations') {
      return this.declarationsSection.nativeElement
    }
    if (section === 'militaryService') {
      return this.militaryServiceSection.nativeElement
    }
    if (section === 'demographics') {
      return this.demographicsSection.nativeElement
    }
    if (section === 'loanOriginatorInfo') {
      return this.loanOriginatorInfoSection.nativeElement
    }
    if (section === 'propertyLoanInfo') {
      return this.propertyLoanInfoSection.nativeElement
    }
    if (section === 'titleInfo') {
      return this.titleInfoSection.nativeElement
    }
    if (section === 'mortgageLoanInfo') {
      return this.mortgageLoanInfoSection.nativeElement
    }
    if (section === 'qualifyingTheBorr') {
      return this.qualifyingTheBorrSection.nativeElement
    }
    if (section === 'homeownershipEducation') {
      return this.homeownershipEducationSection.nativeElement
    }
    return null;
  }

  private showManageBorrowers = (): void => {
    const modalRef = this._modalService.open(ManageBorrowersComponent, { centered: true });
    let allBorrowers = _.cloneDeep(this.mortgage.borrowers)
    modalRef.componentInstance.mortgageBorrowers = allBorrowers;

    //Correct logic for after we prompt them to save the loan before they edit the borrower ordering.
    modalRef.result.then(savedBorrowers => {
      let removedBorrower = this.mortgage.borrowers.length != savedBorrowers.length;

      if (removedBorrower) {
        this._spinner.show();
        this.applicationContextService.reloadApplicationAndMortgagePostAction(this.application.applicationId).subscribe(appContext => { }).add(() => { this._spinner.hide(); });
      }
      else {
        this.mortgage.borrowers = savedBorrowers;
        this.adjustBorrowers(this.mortgage.borrowers);
        this.applicationContextService.updateMortgageAndApplication(this.mortgage, this.application);
      }
    }, error => { });
  }

  private showNotSavedDialog = async (): Promise<boolean> => {
    const result = await Swal.fire({
      showDenyButton: true,
      title: 'Are you sure?',
      text: `You have changes that are not saved. Would you like to save your changes? If you choose Discard below, your changes will be lost.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Save & Continue!',
      cancelButtonText: 'Cancel',
      cancelButtonColor: "#DD6B55",
      denyButtonText: `Discard & Continue!`,
      reverseButtons: true
    });

    if (result.isDenied) {
      this.discardChanges();
      return true;
    }
    if (!result.isConfirmed) return false;

    this.onSaveClicked();
  }

  private showNotSaveDialogForManageBorrowers = async (): Promise<boolean> => {
    const result = await Swal.fire({
      showDenyButton: true,
      title: 'You have unsaved changes.',
      text: `Would you like to save your changes? If you choose Discard below, your changes will be lost.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Save & Continue!',
      cancelButtonText: 'Cancel',
      cancelButtonColor: "#DD6B55",
      denyButtonText: `Discard & Continue!`,
      reverseButtons: true
    });

    if (result.isDenied) {
      this.discardChanges();
      this.showManageBorrowers();
    }
    if (!result.isConfirmed) return false;

    await firstValueFrom(this.saveChanges());
    this.showManageBorrowers();
  }

  private getHiddenFields = (loanHiddenFields?: Configuration): Array<string> => {
    if (!loanHiddenFields?.valueStr) return [];
    return loanHiddenFields.valueStr?.split(",").map(el => el.trim());
  }

  private mapCalculatedStatsToMortgage = () => {
    this.mortgage.transactionDetail.cashFromToBorrowerAmount = this.mortgage.calculatedStats.cashFromOrToTheBorrower ?? 0;
  }

  private validateFormerEmploymentStartEndDates = (): boolean => {
    for (let borrower of this.mortgage.borrowers) {
      const formerEmployments = borrower.employments?.filter(e => e.employmentType === EmploymentTypeEnum.FormerEmployer);
      for (let formerEmployment of formerEmployments) {
        if (new Date(formerEmployment.endDate) < new Date(formerEmployment.startDate)) {
          return false;
        }
      }
    }
    return true;
  }

  private startObservingSectionVisibility = () => {
    const options = {
      root: null,
      rootMargin: '-120px',
      threshold: []
    };

    const thresholds = [];
    for (let i = 0; i <= 1; i += 0.01) {
      thresholds.push(i);
    }
    options.threshold = thresholds;

    this._sections.forEach(section => {
      const existingObserver = this._sectionIntersectionObservers.get(section.header);
      if (!existingObserver) {
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            const sectionId = entry.target.id.split('_')[0];
            const section = this._sections.find(s => s.header === sectionId);
            section.order = entry.intersectionRatio;
            this.handleSectionVisibilityEvent(sectionId, entry.intersectionRatio > 0);
          });
        }, options);
        const element = document.querySelector(`#${section.header}`);
        observer.observe(element);
        this._sectionIntersectionObservers.set(section.header, observer);
      }
    });
    this._isListeningToSectionVisibilityEvents = true;
  }

  private stopObservingSectionVisibility = () => {
    this._sections.forEach(section => {
      const observer = this._sectionIntersectionObservers.get(section.header);
      if (observer) {
        observer.disconnect();
        const element = document.querySelector(`#${section.header}`);
        if (element) {
          observer.unobserve(element);
        }
        this._sectionIntersectionObservers.delete(section.header);
      }
    });
    this._isListeningToSectionVisibilityEvents = false;
  }

  private handleSectionVisibilityEvent = (sectionHeading: string, isVisible: boolean) => {

    if (this.visibleSection) {
      return;
    }

    const existingVisible = this._visibleSections.find(s => s.header === sectionHeading);

    const section = this._sections.find(s => s.header === sectionHeading);
    if (isVisible) {
      if (!existingVisible) {
        this._visibleSections.push(section);
      }
    } else {
      if (existingVisible) {
        const index = this._visibleSections.indexOf(existingVisible);
        if (index >= 0) {
          this._visibleSections.splice(index, 1);
        }
      }
    }
    this._visibleSections = _.orderBy(this._visibleSections, ['order'], ['desc']);
    let newVisibleSection = this._visibleSections[0];
    if (newVisibleSection) {
      this._menuService.setActiveMenuItem(newVisibleSection.id);
    }
  }
}

export class BorrowerTabSelectedEvent {
  selectedBorrower: MortgageBorrower;
  sourceComponent: TabbedBorrowerComponentType
}

export enum TabbedBorrowerComponentType {
  BorrowersInfo = "BorrowersInfo",
  Declarations = "Declarations",
  Demographics = "Demographics"
}

export class SubjectPropertyTaxesAndInsuraceDetails {
  subjectProperty: SubjectProperty;
  escrowDetails: EscrowDetail;
}
