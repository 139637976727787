<div class="card-body" *ngIf="processSubmissionData" style="min-height:400px;">
    <form class="form-horizontal auth-form" #missingInformationForm="ngForm" novalidate id="missingInformationForm"
        name="missingInformationForm" action="#">
        <div class="row justify-content-center ms-4 me-2">
            <div class="d-md-none col-lg-1"></div>
            <div class="col-md-12 col-lg-10">

                <ng-container *ngIf="showCreditRefNumber">
                    <div class="row" *ngFor="let app of applications; let appIndex = index">
                        <div class="card">
                            <div class="card-header d-flex">
                                <h4 class="card-title">
                                    <i class="fas fa-user me-2"></i>
                                    {{app.displayName}}
                                </h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Credit Ref #</label>
                                        <input id="appRefNumber_{{appIndex}}" class="form-control" [(ngModel)]="app.creditRefNumber"
                                            [ngClass]="{'is-invalid' : appRefNum && appRefNum.touched && appRefNum.invalid}"
                                            #appRefNum="ngModel" name="appRefNum" type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="row" *ngIf="showSubjectProperty">
                    <div class="card">
                        <div class="card-header d-flex">
                            <h4 class="card-title">
                                <i class="fas fa-user me-2"></i>
                                Subject Property Missing Information
                            </h4>
                        </div>
                        <div class="card-body" *ngIf="processSubmissionData?.loanData?.mortgage?.subjectProperty">
                            <div class="row">
                                <div class="col-md-3">
                                    <label>Number of Units</label>
                                    <input class="form-control" required [(ngModel)]="processSubmissionData.loanData.mortgage.subjectProperty.noOfUnits"
                                        [ngClass]="{'is-invalid' : noOfUnits && noOfUnits.touched && noOfUnits.invalid}"
                                        #noOfUnits="ngModel" name="noOfUnits" type="text" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="!showSubjectProperty && !showCreditRefNumber">
                    <span class="alert alert-success">
                        
                                Nothing missing. Please proceed.
                           
                    </span>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="text-center">
    <button class="btn btn-primary me-2" (click)="back()">Back</button>
    <button id="new-app-missing-info-proceed-btn" class="btn btn-primary" (click)="proceed()">
        Proceed To
        {{
            allowedLoanCharacterstics.length > 0 ?
                'Loan Characteristics' : allowedBorrowerCharacterstics.length > 0 ?
                    'Borrower Characteristics' : 'Final Review'
        }}
    </button>
</div>