import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { finalize } from 'rxjs/operators';
import { LoanStatus, Role, StandardLoanStage } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ChannelService } from 'src/app/services/channel.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoanStatusService } from '../../../services';

@Component({
  selector: 'upsert-loan-status',
  templateUrl: 'upsert-loan-status-dialog.component.html',
})
export class UpsertLoanStatusDialog implements OnInit, OnChanges {
  @Input() loanStatus: LoanStatus;
  @Input() roles: Array<Role>;
  @Input() enabledChannels: EnumerationItem[];
  @Input() companyId: number;
  @Input() allLoanStatuses: LoanStatus[];

  @Output() close: EventEmitter<LoanStatus> = new EventEmitter<LoanStatus>();
  @ViewChild('upsertLoanStatusForm') upsertLoanStatusForm: NgForm | undefined;

  saving: boolean;
  editMode: boolean;

  multiselectSelectByRole: Array<Select2OptionData> = [];
  multiselectChannels: Array<Select2OptionData> = [];
  loanStages: Array<EnumerationItem> = [];
  multiselectSelectByRoleModel = [];
  multiselectChannelsModel = [];

  constructor(
    private readonly _loanStatusService: LoanStatusService,
    private readonly _notificationService: NotificationService,
    private readonly _channelService: ChannelService,
    private readonly _enumerationService: EnumerationService
  ) { }

  ngOnInit(): void {
    this.loanStages = this._enumerationService.loanStages;
    this.editMode = this.loanStatus.loanStatusId ? true : false;
    if (!this.loanStatus.emailFromRoleId) {
      this.loanStatus.emailFromRoleId = '';
    }
    if (!this.loanStatus.loanStage) {
      this.loanStatus.loanStage = StandardLoanStage.Unknown;
    }
    this.setMultiSelectData();
  }

  onClose() {
    this.close.emit();
  }

  isDuplicated = () => {
    if(this.loanStatus && this.loanStatus.loanStatusName){
      let matched = this.allLoanStatuses.find(s => s.loanStatusId != this.loanStatus.loanStatusId && s.loanStatusName == this.loanStatus.loanStatusName);
      return matched ? true : false;
    }
    else {
      return false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loanStatus.currentValue && changes.loanStatus.currentValue != changes.loanStatus.previousValue) {
      this.editMode = this.loanStatus.loanStatusId ? true : false;
      if (this.editMode) {
        if (!this.loanStatus.emailFromRoleId) {
          this.loanStatus.emailFromRoleId = '';
        }
        if (!this.loanStatus.loanStage) {
          this.loanStatus.loanStage = StandardLoanStage.Unknown;
        }
        this.setMultiSelectData();
      }
    }
  }

  save() {
    if (this.upsertLoanStatusForm) {
      this.upsertLoanStatusForm.form.markAllAsTouched();
      if (!this.upsertLoanStatusForm.form.valid || this.isDuplicated()) return;
    }
    this.loanStatus.enabledChannels = this.enabledChannels.length > 0
      ? this.multiselectChannelsModel?.join(", ")
      : null;

    this.loanStatus.selectByRole = this.multiselectSelectByRoleModel?.join(',');

    this.saving = true;
    if (this.editMode) {
      this._loanStatusService.updateLoanStatus(this.companyId, this.loanStatus)
        .pipe(finalize(() => this.saving = false))
        .subscribe({
          next: () => {
            this._notificationService.showSuccess('Loan status updated successfully', 'Loan Status');
            this.close.emit(this.loanStatus);
          },
          error: (err) => {
            this._notificationService.showError(
              err?.message || "Couldn't update selected loan status",
              'Loan Status'
            );
          }
        });
    } else {
      this._loanStatusService.insertLoanStatus(this.companyId, this.loanStatus)
        .pipe(finalize(() => this.saving = false))
        .subscribe({
          next: (res) => {
            this._notificationService.showSuccess('Loan status added successfully', 'Loan Status');
            this.close.emit(res);
          },
          error: (err) => {
            this._notificationService.showError(
              err?.message || "Couldn't insert new loan status",
              'Loan Status'
            );
          }
        });
    }
  }

  private setMultiSelectData() {
    this.multiselectChannels = this.enabledChannels?.map((channel) => ({
      id: channel.value,
      text: channel.name,
    }));

    this.multiselectSelectByRole = this.roles?.map((role) => ({
      id: role.roleId.toString(),
      text: role.roleName,
    }));

    this.multiselectSelectByRoleModel = this.loanStatus.selectByRole
      ?.split(',')
      .filter(lsRole => this.multiselectSelectByRole.some(r => r.id == lsRole));

    this.multiselectChannelsModel = this._channelService
      .getChannelsFromCommaDelimitedString(this.loanStatus.enabledChannels)
      .map((ch) => ch.name);
  }
}
