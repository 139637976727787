import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Address } from 'src/app/models';
import { CityCountyState } from 'src/app/models/city-county-state.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ZipCodeService } from 'src/app/services/zipcode.service';
import { CityCountyPickerDialogComponent } from '../../../components/city-county-picker-dialog/city-county-picker-dialog.component';
import { UrlaStreetAddressComponent } from '../urla-street-address/urla-street-address.component';

@Component({
  selector: 'urla-address',
  templateUrl: 'urla-address.component.html',
  viewProviders: [formViewProvider]
})
export class UrlaAddressComponent implements OnInit {

  @ViewChild('urlaStreetAddress')
  streetAddressComponent: UrlaStreetAddressComponent;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  address: Address;

  @Input()
  addressLabel: string;

  @Input()
  showCountry: boolean = true;

  @Input()
  zipcodeLookupEnabled: boolean = false;

  @Input()
  copyTitleText: string;

  @Input()
  copyButtonVisible: boolean = false;

  @Output()
  addressChanged: EventEmitter<Address> = new EventEmitter<Address>();

  @Output()
  streetAddressBlurred: EventEmitter<Address> = new EventEmitter<Address>();

  @Output()
  cityBlurred: EventEmitter<Address> = new EventEmitter<Address>();

  @Output()
  stateBlurred: EventEmitter<Address> = new EventEmitter<Address>();

  @Output()
  zipcodeBlurred: EventEmitter<Address> = new EventEmitter<Address>();

  @Output()
  unitNoBlurred: EventEmitter<Address> = new EventEmitter<Address>();

  @Output()
  copyClicked: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  required: boolean = true;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  fieldBeingEdited: string = '';

  @Input()
  showAllCountries: boolean = false;

  states: EnumerationItem[] = [];

  streetAddress: string;

  id: string;

  alphaNumericPattern = /^[a-zA-Z0-9\s]*$/;

  constructor(private readonly _modalService: NgbModal,
    private readonly _enumsService: EnumerationService,
    private readonly _zipCodeService: ZipCodeService) {
    this.states = this._enumsService.states;
    this.id = (Math.floor(Math.random() * Date.now())).toString();
  }

  ngOnInit(): void {
    if(!this.address)
      this.address = new Address();
    if (this.address.state) { // fixing for old records
      this.address.country = "us";
    }
  }

  onCityBlurred = () => {
    this.cityBlurred.emit(this.address);
  }

  onStateChanged = () => {
    this.stateBlurred.emit(this.address);
  }

  onCountryChanged = () => {
    const selectedCounty = this.address.country;
    if (selectedCounty !== 'us') {
      this.address.county = null;
      this.address.state = null;
      this.address.zipCode = null;
    }
  }

  onAddressBlurred = () => {
    this.streetAddressBlurred.emit(this.address);
  }

  onAddress2Blurred = () => {
    this.unitNoBlurred.emit(this.address);
  }

  onAddressChanged = (address: Address) => {
    this.address.address1 = '-';
    setTimeout(() => {
      this.address.address1 = address.address1;
      this.address.city = address.city;
      this.address.zipCode = address.zipCode;
      this.address.state = address.state;
      this.address.country = address.country;
      this.address.county = address.county;
      this.addressChanged.emit(this.address);
    })
  }

  onZipCodeBlurred = () => {
    this.zipcodeBlurred.emit(this.address);
    if (!this.zipcodeLookupEnabled) {
      return;
    }
    this._zipCodeService.lookupZipCode(this.address.zipCode, false).subscribe(result => {
      if (result.length > 1) {
        const modalRef = this._modalService.open(CityCountyPickerDialogComponent, Constants.modalOptions.medium);
        modalRef.componentInstance.optionsToPickFrom = result;
        modalRef.result.then((selectedCityAndCounty: CityCountyState) => {
          if (selectedCityAndCounty) {
            this.address.city = selectedCityAndCounty.city;
            this.address.state = selectedCityAndCounty.state.toLocaleLowerCase();
          }
        })
      } else if (result.length === 1) {
        this.address.city = result[0].city;
        this.address.state = result[0].state.toLocaleLowerCase();
      }
    });
  }
}
