import { Component, Injector, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { concat, firstValueFrom, Observable, Subscription } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/core/route-guards/pending-changes.guard';
import { ApplicationContext, LoanApplication, MortgageBorrower, SubjectProperty } from 'src/app/models';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { Constants } from 'src/app/services/constants';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { QuickApplyBorrowerDetailsComponent, ValidityStatus } from './components/qa-borrower-details/qa-borrower-details.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { DiffChecker } from 'src/utils/diff-checker';
import { showNotSavedDialog } from '../quick-apply-utils';
import { AppDetailsService } from '../../../services/app-details.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { ManageBorrowersComponent } from 'src/app/modules/urla/borrower-information/manage-borrowers/manage-borrowers.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { cloneDeep, groupBy } from 'lodash';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { MenuItemStatus } from 'src/app/modules/tpo/models/enums/menu-item-status.enum';
import { AddMortgageBorrowerDialogComponent } from 'src/app/modules/urla/urla-main/add-mortgage-borrower-dialog/add-mortgage-borrower-dialog.component';
import * as _ from 'lodash';
import { UtilityService } from '../../../../urla/services/utility.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'qa-borrowers-info',
  templateUrl: 'qa-borrowers-info.component.html',
  styleUrls: ['./qa-borrowers-info.component.scss']
})
export class QuickApplyBorrowersInfoComponent extends ApplicationContextBoundComponent implements OnInit, ComponentCanDeactivate, OnDestroy {

  @ViewChild("qaBorrowerInfoForm") qaBorrowerInfoForm: NgForm;

  @ViewChildren('qaBorrowerDetails') qaBorrowerDetails: QueryList<QuickApplyBorrowerDetailsComponent> | undefined;


  mortgage: UrlaMortgage | null = null;
  selectedBorrower: MortgageBorrower;
  isRefinance: boolean;
  subjectProperty: SubjectProperty;

  borrowerGroups: MortgageBorrower[][] = [];

  protected loanBorrowers: BorrowerDto[] = [];
  protected validityStatus: MenuItemStatus;
  protected borrowerValidityStatusInfo: Map<number, MenuItemStatus> = new Map<number, MenuItemStatus>();
  protected borrowerDetailsSubTabs: string[] = [];

  protected isLoanReadOnly: boolean = false;

  protected creditReportingHtml: string = '';

  protected getBorrowerDisplayName: (borrower: MortgageBorrower) => string = () => '';

  private get _context$(): Observable<ApplicationContext> {
    const initialValue = (async () => {
      const hasApplication = this.applicationContext?.application != null;
      if (hasApplication) {
        this.isLoanReadOnly = this.applicationContext.applicationIsReadOnly;
        return this.applicationContext;
      }

      const context$ = this.applicationContextService.context;

      return firstValueFrom(context$);
    })();

    return concat(
      initialValue,
      this.applicationContextService.loanInfoChanges,
    );
  }

  private _contextChangesSubscription: Subscription | null = null;

  private _justSaved: boolean = false;

  protected loanBorrowersMap: Map<number, BorrowerDto> = new Map<number, BorrowerDto>();

  private _application: LoanApplication | null = null;
  private _pristineMortgage: UrlaMortgage | null = null;

  constructor(
    injector: Injector,
    private readonly _spinner: NgxSpinnerService,
    private readonly _appContextService: ApplicationContextService,
    private readonly _notificationService: NotificationService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _modalService: NgbModal,
    private readonly _appService: AppDetailsService,
    private readonly _utilityService: UtilityService,
    private readonly _configurationService: ConfigurationService,
  ) {
    super(injector);

    this.subscribeToContextChanges();
  }

  canDeactivate = (): boolean | Observable<boolean> => {
    // don't check when session expires
    if (!this.applicationContext || !this._application) {
      return true;
    }

    const isDirty = this.checkIfMortgageIsDirty();
    return !isDirty;
  }

  confirm = (): boolean | Observable<boolean> => {
    return showNotSavedDialog(() => this.saveBorrowerInfo());
  }

  async ngOnInit() {
    super.ngOnInit();

    this.getBorrowerDisplayName = this._utilityService.getBorrowerDisplayName;

    this._configurationService.getCompanyConfiguration('CreditReportingScript').subscribe(creditReportingScript => {
      this.creditReportingHtml = creditReportingScript.valueStr ?? "";
    })

    const initMortgage = async () => {
      const ctx = await firstValueFrom(this._context$);
      const application = ctx.application;
      this.loanBorrowers = ctx.borrowers;
      this.initMortgage(application.mortgageLoan);
    };

    await Promise.all([
      initMortgage(),
    ]);
  }

  ngOnDestroy(): void {
    this._contextChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  onBorrowerValidationStatusChanged = (e: ValidityStatus) => {
    setTimeout(() => {
      this.validityStatus = e.validityStatus;
      this.borrowerValidityStatusInfo.set(e.borrowerId, e.validityStatus);
    })
  }

  private initMortgage(mortgage: UrlaMortgage) {
    mortgage = cloneDeep(mortgage);

    this.isRefinance = mortgage.subjectProperty.purposeOfLoan === 'Refinance';
    this.subjectProperty = mortgage.subjectProperty;
    if (mortgage.borrowers.length > 0) {
      this.adjustBorrowers(mortgage.borrowers);
      this.selectBorrower(mortgage.borrowers[0]);
    }

    this.setMortgage(mortgage);
  }

  /**
   * Sets the mortgage and resets the dirty state.
   * @param mortgage The mortgage to set.
   */
  private setMortgage(mortgage: UrlaMortgage): void {
    this.mortgage = mortgage;

    this.mortgage.borrowers.forEach(borrower => {
      const loanBorrower = this.loanBorrowers.find(b => b.borrowerId === borrower.contactId);
      this.loanBorrowersMap.set(borrower.borrowerId, loanBorrower);
    })

    this.invalidateDirtyState();
  }

  protected invalidateDirtyState() {
    this._pristineMortgage = cloneDeep(this.mortgage);
  }

  onSaveBorrowerButtonClicked = async () => {
    await this.saveBorrowerInfo();
  }

  onManageBorrowerClicked = () => {
    const modalRef = this._modalService.open(ManageBorrowersComponent, { centered: true });
    modalRef.componentInstance.mortgageBorrowers = cloneDeep(this.mortgage.borrowers);

    modalRef.result.then((savedBorrowers: MortgageBorrower[]) => {
      let removedBorrower = this.mortgage.borrowers.length != savedBorrowers.length;

      if (removedBorrower) {
        this.applicationContextService.reloadApplicationAndMortgagePostAction(this._application.applicationId).subscribe(() => {
        });
      }
      else {
        this._application.mortgageLoan.borrowers = savedBorrowers;
        this.mortgage.borrowers = savedBorrowers;
        this.applicationContextService.updateMortgageAndApplication(this.mortgage, this._application);
      }
    }, () => {
    })
  }

  saveBorrowerInfo = async (): Promise<boolean> => {
    // const valid = !this.qaBorrowerDetails.some((detail) => !detail.validate());
    this.qaBorrowerDetails.forEach(detail => detail.validate());
    const application = this._application;
    const fallbackMortgage = application.mortgageLoan;

    try {
      const loanInfo = {
        application,
        customData: null,
      };

      await this._spinner.show();

      application.mortgageLoan = this.mortgage;
      const result = await firstValueFrom(
        this._appDetailsService.saveLoanInfo(
          application.applicationId,
          loanInfo,
        ),
      );
      const applicationResult = result.application;

      this._justSaved = true;
      this._appContextService.updateMortgageAndApplication(
        applicationResult.mortgageLoan,
        applicationResult,
      );
      this.invalidateDirtyState();

      this._notificationService.showSuccess(
        'Mortgage has been saved successfully.',
        'Success!'
      );

      return true;
    }
    catch (e) {
      application.mortgageLoan = fallbackMortgage;
      const message = (e.error?.message || e.message) || 'An error occurred.';

      this._notificationService.showError(
        message,
        'Error!',
      );

      return false;
    } finally {
      await this._spinner.hide();
    }
  }

  selectBorrower = (borrower: MortgageBorrower) => {
    this.selectedBorrower = borrower;
  }

  isSelectedBorrower(borrower: MortgageBorrower): boolean {
    return this.selectedBorrower?.borrowerId === borrower.borrowerId;
  }

  onAddNewBorrowerClicked = () => {
    const modalRef = this._modalService.open(AddMortgageBorrowerDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.mortgage = _.cloneDeep(this.mortgage);

    modalRef.result.then((selectedBorrower: MortgageBorrower) => {
      this._appService.getBorrowers(this._application.applicationId).subscribe((borrowers) => {
        selectedBorrower.mortgageId = this.mortgage.mortgageId;
        this.mortgage.borrowers.push(selectedBorrower);

        this.adjustBorrowers(this.mortgage.borrowers);
        this.applicationContextService.updateMortgageAndApplication(this.mortgage, this._application, undefined, borrowers);
      })
    }, error => { })
  }

  private subscribeToContextChanges(): void {
    this._contextChangesSubscription?.unsubscribe();

    this._contextChangesSubscription = this._context$.subscribe((context) => {
      if (context.application) {
        this._application = context.application;
        const mortgage = cloneDeep(this._application.mortgageLoan);
        this.adjustBorrowers(mortgage.borrowers);
        this.setMortgage(mortgage);
        if (this._justSaved) {
          setTimeout(() => {
            this.qaBorrowerDetails.forEach(detail => detail.validate());
            this._justSaved = false;
          })
        }
      }
    });
  }

  private checkIfMortgageIsDirty = () => {
    const diffChecker = new DiffChecker(
      this._pristineMortgage,
      this.mortgage,
      'mortgage',
      { floatPrecision: 2 },
    );

    return diffChecker.calculateDiff(true) != null;
  }

  private adjustBorrowers = (borrowers: MortgageBorrower[]) => {
    this.borrowerGroups = [];
    this.borrowerGroups = borrowers.length > 0 ? Object.values(groupBy(borrowers, 'printApplicationIndex')) : [];
  }
}
