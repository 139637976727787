import { Component, Input, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { UrlaBorrower } from '../../models/urla-mortgage.model';

@Component({
  selector: 'urla-econsent',
  templateUrl: 'urla-econsent.component.html',
  styleUrls: ['./urla-econsent.component.scss']
})
export class UrlaEConsentComponent implements OnInit {

  @Input()
  borrower: UrlaBorrower;

  protected guid: string;

  constructor() {
    this.guid = uuidv4();
  }

  ngOnInit() {

  }
}
