import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExternalCompany, Role } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { NotificationService } from 'src/app/services/notification.service';
import { AlignmentRoleUsers } from '../../../models/process-submission.model';
import { NewApplicationService } from '../../../services/new-application.service';

@Component({
  selector: 'import-du',
  templateUrl: './import-du.component.html',
  styleUrls: ['./import-du.component.scss']
})
export class ImportDuComponent implements OnInit {
  @ViewChild('importDuForm') importDuForm: NgForm | undefined;

  duFiles: FileList;

  @Input()
  globalConfig: GlobalConfig;

  @Input()
  isTpoUser: boolean;

  @Input()
  enabledChannels: Array<EnumerationItem>;

  @Input()
  externalCompanies: ExternalCompany[];

  @Input()
  availableUsers: User[];

  @Input()
  selectedChannel: string;

  @Input()
  selectedCompanyId: number | '';

  @Input()
  firstRole: Role;

  @Input()
  userCompanyGuid: string;

  @Input()
  tpoEnabledRoles: Role[] = [];

  @Output()
  processDuFile = new EventEmitter();

  primaryRoleUserId: string;
  avaliableSecondaryRoles: Role[] = [];
  tpoAlignmentRoleUsers: AlignmentRoleUsers = {};
  availableRoleUsers: User[] = [];

  constructor(
    private readonly _newApplicationService: NewApplicationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService) { }

  ngOnInit(): void {
    const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
    this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';

    this.firstRole = this.getFirstRoleByChannel(this.selectedChannel);

    if (this.isTpoUser && this.firstRole) {
      this.avaliableSecondaryRoles = this.tpoEnabledRoles.filter(r => r.roleId != this.firstRole.roleId && r.order != 1 &&
        r.roleChannels.map(rc => rc.channel.toLocaleLowerCase()).includes(this.selectedChannel.toLowerCase()));
    }

    this.availableRoleUsers = cloneDeep(this.availableUsers);

    this.externalCompanies = this.externalCompanies.sort((a, b) => a.name.localeCompare(b.name));
  }

  handleFileInput = (files: FileList) => {
    this.duFiles = files;
  }

  handleChannelChange = (newVal) => {
    this.selectedChannel = newVal;
    this.selectedCompanyId = '';

    if (newVal == 'Wholesale' || newVal == 'Correspondent' || newVal == 'NonDelegatedCorrespondent') {
      this.availableRoleUsers = [];
      this.primaryRoleUserId = '';
    }

    this.firstRole = this.getFirstRoleByChannel(newVal);

    if (this.isTpoUser && this.firstRole) {
      this.avaliableSecondaryRoles = this.tpoEnabledRoles.filter(r => r.roleId != this.firstRole.roleId && r.order != 1 &&
        r.roleChannels.map(rc => rc.channel.toLocaleLowerCase()).includes(this.selectedChannel.toLowerCase()));
    }
    
    if (newVal != 'Wholesale')
      this.availableRoleUsers = cloneDeep(this.availableUsers);

    const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
    this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';
  }

  handleCompanyChange = (externalCompanyId: number) => {
    this.selectedCompanyId = externalCompanyId;
    if (!this.selectedCompanyId) {
      this.availableRoleUsers = [];
      return;
    }
    if (this.isTpoUser) {
      if (this.selectedCompanyId) {
        this.availableRoleUsers = this.availableUsers.filter(x => x.externalCompanyId == this.selectedCompanyId);
      }
      return;
    }
    this._spinner.show();
    this._newApplicationService.getExternalCompanyUsers(this.selectedCompanyId)
      .subscribe({
        next: (response) => {
          this.availableRoleUsers = response;

          const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
          this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';

          this._spinner.hide();
        }, error: (err) => {
          this.primaryRoleUserId = '';
          this._notificationService.showError(err?.message || "Couldn't get users for selected company", "Error")
          this._spinner.hide();
        }
      });
  }

  processFile = () => {
    this.importDuForm.form.markAllAsTouched();
    if (this.importDuForm.form.valid) {
      var data = {
        channel: this.selectedChannel,
        duFiles: this.duFiles,
        primaryRoleUserId: this.primaryRoleUserId,
        additionalAlignmentRoleUsers: this.tpoAlignmentRoleUsers
      }
      this.processDuFile.emit(data);
    }
  }

  private getFirstRoleByChannel = (channel: string) => {
    if (!channel) {
      return this.globalConfig.firstRole;
    }
    return this.globalConfig.channelRoles[channel.toLowerCase()][0];
  }
}
