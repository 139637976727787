import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'qa-add-button',
  templateUrl: './qa-add-button.component.html',
})
export class QaAddButtonComponent {
  @Output() onClick = new EventEmitter();

  @Input() isDisabled = false;
}
