import { Employment, Income } from '../../../../../models';
import { isCurrentEmployment } from '../quick-apply-utils';
import { enumLikeValueToDisplayName } from '../../../../../core/services/utils';

export type IncomeLikeType = Income | Employment;

/**
 * The value is either an {@link Employment} or an {@link Income}.
 */
export class IncomeLike<T extends IncomeLikeType = IncomeLikeType> {
  readonly id: number;

  /**
   * Whether the value is an employment.
   */
  readonly isEmployment: boolean;

  /**
   * The employment if the value is an {@link Employment}, or undefined
   * otherwise.
   */
  readonly employment?: Employment;

  /**
   * Whether the value is an income.
   */
  readonly isIncome: boolean;

  /**
   * The income if the value is an {@link Income}, or undefined otherwise.
   */
  readonly income?: Income;

  /**
   * The parent array reference if the value is an item in an array, or
   * undefined otherwise.
   */
  arrayReference?: T[];

  /**
   * The real value of the item.
   * This is either an {@link Employment} or an {@link Income}.
   */
  readonly value: T;

  constructor({
                value,
                arrayReference,
              }: {
    value: T;
    arrayReference?: T[];
  }) {
    this.value = value;
    this.arrayReference = arrayReference;

    const isEmployment = this.isEmployment = isEmploymentType(value);
    const employment = this.employment = isEmployment
      ? value as Employment
      : undefined;

    const isIncome = this.isIncome = isIncomeType(value);
    const income = this.income = isIncome
      ? value as Income
      : undefined;

    this.id = employment?.employmentId ?? income?.incomeId ?? 0;
  }

  /**
   * Whether the employment is current. This is only applicable if the value is
   * an employment.
   */
  get isCurrent(): boolean {
    const employment = this.employment;
    return employment != null && isCurrentEmployment(employment);
  }

  /**
   * Whether the employment is primary. This is only applicable if the value is
   * an employment.
   */
  get isPrimary(): boolean {
    const employment = this.employment;
    return employment != null && employment.isPrimary;
  }

  /**
   * Whether the employment is retired. This is only applicable if the value is
   * an employment.
   */
  get isRetired(): boolean {
    const employment = this.employment;
    return employment != null && employment.employer === 'Retired';
  }

  /**
   * The display name of the income type.
   */
  get incomeTypeName(): string {
    if (this.isEmployment) {
      return 'Employment Income';
    }

    const typeOfIncome = this.income?.typeOfIncome;
    if (!typeOfIncome) {
      return '\u2014';
    }

    return enumLikeValueToDisplayName(String(typeOfIncome));
  }

  get monthlyIncome(): number {
    if (this.isEmployment) {
      const employment = this.value as Employment;
      const totalIncome = employment.incomes.reduce(
        (sum, income) => sum + (Number(income.monthlyIncome) || 0), 0,
      );
      return totalIncome + (employment.selfEmploymentMonthlyIncomeOrLoss || 0);
    }

    const income = this.value as Income;
    return income.monthlyIncome ?? 0;
  }
}

function isEmploymentType(incomeLike: IncomeLikeType): boolean {
  // Ideally we would use only `instanceof Employment` here, but that doesn't
  // work if it is a plain object (e.g., when it is partial employment).
  return incomeLike instanceof Employment
    || 'incomes' in incomeLike;
}

function isIncomeType(incomeLike: IncomeLikeType): boolean {
  /**
   * See {@link isEmploymentType}.
   */
  return incomeLike instanceof Income
    || 'monthlyIncome' in incomeLike;
}
