import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { UrlaBorrower } from '../../models/urla-mortgage.model';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BorrowerValidityStatus } from '../../borrower-information/borrower-info/borrower-info.component';

@Component({
  selector: 'borrower-declarations',
  templateUrl: 'borrower-declarations.component.html',
  viewProviders: [formViewProvider],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BorrowerDeclarationsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('borrowerDeclarationForm')
  borrowerDeclarationForm: NgForm;

  @Output()
  borrowerValidityStatusChanged: EventEmitter<BorrowerValidityStatus> = new EventEmitter<BorrowerValidityStatus>();

  @Input()
  borrower: UrlaBorrower;

  @Input()
  isReadOnly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  isPurchase: boolean = false;

  private _formValidationEventsSubscription: Subscription;

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    if (this.borrowerDeclarationForm) {
      this._formValidationEventsSubscription = this.borrowerDeclarationForm.valueChanges.subscribe((changeEvent) => {
        if (changeEvent) {
          const fieldNames = Object.keys(changeEvent).filter(key => key !== 'triggerValidation');
          for (let i = 0; i < fieldNames.length; i++) {
            const fieldControl = this.borrowerDeclarationForm.controls[fieldNames[i]];
            if (fieldControl.touched) {
              setTimeout(() => {
                this.borrowerValidityStatusChanged.emit({ borrowerId: this.borrower.borrowerId, formValidityStatus: "" });
              });
              break;
            }
          }
        }
      })
    }
  }

  ngOnDestroy(): void {
    this._formValidationEventsSubscription?.unsubscribe();
  }
}
