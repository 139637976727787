import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import {
  ActivityLog,
  CustomData,
  DuHistoryDocument,
  KeyDatesByType,
  LoanApplication,
  LoanStatus,
  LpaHistoryDocument,
  Mortgage,
  RecentApp,
} from 'src/app/models';
import { BorrowerCharacteristic, LoanCharacteristic } from 'src/app/models/characteristics';
import { BorrowerCharacteristicsHistoryItem } from 'src/app/models/loan/borrower-characteristics-history-item.model';
import { LoanCharacteristicsHistoryItem } from 'src/app/models/loan/loan-characteristic-history.model';
import { LoanDocTask } from 'src/app/models/loan/loan-doc-task.model';
import { LoanField } from 'src/app/models/loan/loan-field.model';
import { LoanStatusHistoryItem } from 'src/app/models/loan/loan-status-history.model';
import { CopyFormApp } from 'src/app/modules/applications/models/copy-form-app-model';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { ApplicationMenu } from '../application-menu';
import { LookupService } from '../lookup.service';
import { MortgageService } from '../mortgage.service';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { MenuItemStatus, MenuStatus } from 'src/app/modules/tpo/models/enums/menu-item-status.enum';
import { SubmissionValidationResult } from '../../modules/submission/models/submission-validation-result.model';

@Injectable()
export class LoanService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _lookupService: LookupService,
    private readonly _mortgageService: MortgageService
  ) { }

  mergeFieldValues = (applicationId: number): Observable<LoanField[]> => {
    const url = `api/loan/${applicationId}/merge-field-values`;
    return this._dataService.get(url);
  }

  getApplicationModel = (applicationId: number, includeMortgage: boolean): Observable<LoanApplication> => {
    const url = `api/loan/${applicationId}/get-application-model?includeMortgage=${includeMortgage}`;
    return this._dataService.get(url).pipe(map(application => {
      if (includeMortgage && application.mortgageLoan) {
        this._mortgageService.initializeMortgage(application.mortgageLoan);
      }
      return application;
    }));
  };

  isLoanReadOnly = (applicationId: number): Observable<boolean> => {
    const url = `api/Loan/${applicationId}/is-read-only`;
    return this._dataService.get(url);
  };

  convertToMismo34Application = (applicationId: number): Observable<any> => {
    const url = `api/loan/${applicationId}/upgrade-mismo34`;
    return this._dataService.post(url, {});
  }

  openApplication = (applicationId: number): Observable<RecentApp> => {
    const url = `api/loan/${applicationId}/open-application`;
    return this._dataService.get(url);
  };

  getCustomData = (applicationId: number): Observable<CustomData> => {
    const url = `api/loan/${applicationId}/get-custom-data`;
    return this._dataService.get(url);
  };

  getMonsterInsightsUrl = (applicationId: number): Observable<any> => {
    const url = `api/monster/insights-url?applicationId=${applicationId}`;
    return this._dataService.get(url);
  };

  getBorrowers = <T extends BorrowerDto>(applicationId: number): Observable<BorrowerDto[]> => {
    const url = `api/loan/${applicationId}/borrowers`;
    return this._dataService.get(url);
  };

  markManualLoanTransfer = (applicationId: number, transferredUserId: string): Observable<BorrowerDto> => {
    const url = `api/loan/${applicationId}/mark-manual-warm-transfer/${transferredUserId}`;
    return this._dataService.post(url, {});
  };

  public getMortgage = (loanId: number): Observable<Mortgage> => {
    return this._dataService.get(`api/mortgages?applicationId=${loanId}`);
  };

  public addMortgage = (mortgage: Mortgage): Observable<Mortgage> => {
    return this._dataService.post(
      `api/mortgages/${mortgage.mortgageId}`,
      mortgage
    );
  };

  validateForSubmission = (appId: number): Observable<SubmissionValidationResult> => {
    const url = 'api/Loan/' + appId + '/validate-for-submission';
    return this._dataService.post(url, {});
  }

  getProductPricing = (appId: number) => {
    const url = 'api/Loan/' + appId + '/productpricing';
    return this._dataService.get(url);
  }

  submitTpoLoan = (appId: number) => {
    const url = 'api/Loan/' + appId + '/submit-tpo-loan';
    return this._dataService.post(url, {});
  }

  resubmitTpoLoan = (appId: number) => {
    const url = 'api/Loan/' + appId + '/resubmit-tpo-loan';
    return this._dataService.post(url, {});
  }

  /**
   * Get the application menu for the given loan.
   * @param loanId The loan id.
   * @returns The application menu for the given loan or the default menu if the
   * loan does not have a menu.
   */
  getAppMenu = (loanId: number): Observable<ApplicationMenu> => {
    const url = 'api/Loan/' + loanId + '/get-app-menu';
    return this._dataService.get(url).pipe(
      map((ids: readonly string[] | undefined) => {
        // A menu with no ids is not a valid menu.
        // Return the default menu in this case.
        if ((ids?.length ?? 0) === 0) {
          console.error('Empty menu config found in loan. Using default menu.');

          return new ApplicationMenu();
        }

        return ApplicationMenu.fromIds(ids);
      }),
    );
  }

  getMenuStatuses = (applicationId: number): Observable<MenuStatus[]> => {
    const url = `api/Loan/${applicationId}/get-menu-statuses`;
    return this._dataService.get(url, {});
  }

  getPizzaMenuStatuses = (loanId: number): Observable<{[menuKey: string]: MenuItemStatus}> => {
    return this._dataService.get(`api/Loan/${loanId}/get-pizza-menu-statuses`);
  };

  updateInternalContactForRole = (loanId: number, roleId: number, userId: string, isUpdateTasks: string, isUpdateBranch: string) => {
    const url = 'api/Loan/' + loanId + '/UpdateInternalContactForRole/' + roleId + '/' + userId + '?updateTasks=' + isUpdateTasks + '&updateBranch=' + isUpdateBranch;
    return this._dataService.post(url, {});
  }

  pauseEmailAndSms = (appId: number): Observable<any> => {
    const url = `api/Loan/${appId}/SetPauseMessagesFlagOnLoan/true`
    return this._dataService.post(url, {});
  }

  unPauseEmailAndSms = (appId: number): Observable<any> => {
    const url = `api/Loan/${appId}/SetPauseMessagesFlagOnLoan/false`
    return this._dataService.post(url, {});
  }

  saveLoanStatus = (appId: number, loanStatusId: number, subStatusId: number, loanDocTasks?: LoanDocTask[]): Observable<LoanDocTask[]> => {
    let url = `api/Loan/${appId}/UpdateLoanStatus/${loanStatusId}/`;
    if (subStatusId != null) {
      url = url + subStatusId;
    }
    return this._dataService.post(url, loanDocTasks);
  }

  getLoanStatusHistory = (appId: number): Observable<LoanStatusHistoryItem[]> => {
    const url = `api/Loan/${appId}/ViewLoanStatusHistory`;
    return this._dataService.get(url);
  }

  getLoanCharacteristics = (appId: number): Observable<LoanCharacteristic[]> => {
    const url = `api/Loan/${appId}/GetLoanCharacteristics`;
    return this._dataService.get(url);
  }

  saveLoanCharacteristicsPreview = (appId: number, characteristics: LoanCharacteristic[]): Observable<LoanDocTask[]> => {
    const url = `api/Loan/${appId}/UpsertLoanCharacteristicsPreview`;
    return this._dataService.post(url, characteristics);
  }

  saveLoanCharacteristics = (appId: number, characteristics: LoanCharacteristic[], loanDocTasks: LoanDocTask[]): Observable<LoanCharacteristic[]> => {
    const url = `api/Loan/${appId}/UpsertLoanCharacteristics`;
    const payLoad = {
      loanCharacteristics: characteristics,
      loanDocTasks: loanDocTasks
    }
    return this._dataService.post(url, payLoad);
  }

  saveBorrowerCharacteristicsPreview = (appId: number, characteristics: BorrowerCharacteristic[]): Observable<LoanDocTask[]> => {
    const url = `api/Loan/${appId}/UpsertBorrowerCharacteristicsPreview`;
    return this._dataService.post(url, characteristics);
  }

  saveBorrowerCharacteristics = (appId: number, characteristics: BorrowerCharacteristic[], loanDocTasks: LoanDocTask[]): Observable<BorrowerCharacteristic[]> => {
    const url = `api/Loan/${appId}/UpsertBorrowerCharacteristics`;
    const payLoad = {
      borrowerCharacteristics: characteristics,
      loanDocTasks: loanDocTasks
    }
    return this._dataService.post(url, payLoad);
  }

  getBorrowerCharacteristics = (appId: number): Observable<BorrowerCharacteristic[]> => {
    const url = `api/Loan/${appId}/GetBorrowerCharacteristics`;
    return this._dataService.get(url);
  }

  getLoanCharacteristicsHistory = (appId: number): Observable<LoanCharacteristicsHistoryItem[]> => {
    const url = `api/Loan/${appId}/GetLoanCharacteristicsHistory`;
    return this._dataService.get(url);
  }

  getBorrowerCharacteristicsHistory = (appId: number): Observable<BorrowerCharacteristicsHistoryItem[]> => {
    const url = `api/Loan/${appId}/GetBorrowerCharacteristicsHistory`;
    return this._dataService.get(url);
  }

  getMergeFieldValues = (applicationId: number) => {
    const url = 'api/Loan/' + applicationId + '/merge-field-values';
    return this._dataService.get(url);
  }

  getLoanActivityLogs = (applicationId: number, includeLosMessages: boolean): Observable<ActivityLog[]> => {
    const url = 'api/Loan/' + applicationId + '/GetActivityLogs?includeLosMessages=' + includeLosMessages;
    return this._dataService.get(url);
  }

  getAllLoanDocsFiltered = (loanId: number, importLosDocs: boolean = false) => {
    const url = 'api/Loan/' + loanId + '/GetAllLoanDocsFiltered?importLosDocs=' + importLosDocs;
    return this._dataService.get(url);
  }

  getAllFannieMaeApprovalDocs = (loanId: number): Observable<DuHistoryDocument[]> => {
    const url = 'api/Loan/' + loanId + '/doc-files/aus-approvals/fannie-mae';
    return this._dataService.get(url);
  }

  getAllFreddieMacApprovalDocs = (loanId: number): Observable<LpaHistoryDocument[]> => {
    const url = 'api/Loan/' + loanId + '/doc-files/aus-approvals/freddie-mac';
    return this._dataService.get(url);
  }

  getAllProofOfFundsDocs = (loanId: number): Observable<DocFile[]> => {
    const url = 'api/Loan/' + loanId + '/doc-files/proof-of-funds';
    return this._dataService.get(url);
  }

  getLoanCustomData = (loanId: number): Observable<CustomData> => {
    const url = 'api/Loan/' + loanId + '/custom-data';
    return this._dataService.get(url);
  }

  saveLoanCustomData = (loanId: number, customData: CustomData): Observable<CustomData> => {
    const url = 'api/Loan/' + loanId + '/custom-data';
    return this._dataService.post(url, customData);
  }

  getLoanStatusesForLoanPurpose = (loanPurposeId: number, loanStatusId: number, applicationId?: number, channel?: string): Observable<LoanStatus[]> => {
    let loanPurposeIdArg = loanPurposeId ? loanPurposeId : 0;
    let loanStatusIdArg = loanStatusId ? loanStatusId : -1;
    return this._lookupService.getLoanStatusForLoanPurpose(loanPurposeIdArg, loanStatusIdArg, applicationId, channel);
  }

  copyApplication = (appId: number, copyFormApp: CopyFormApp, contactSelection: string, data) => {
    let url = 'api/Loan/' + appId + '/copy?loanTypeId=' + copyFormApp.loanTypeId + '&loanPurposeId=' + copyFormApp.loanPurposeId;

    if (contactSelection == 'lo') {
      url += "&useAutoSelectedAlignment=true";
    }
    if (copyFormApp.copyReason != '') {
      url += "&copyReason=" + copyFormApp.copyReason;
    }
    if (copyFormApp.reoId) {
      url += "&reoId=" + copyFormApp.reoId;
    }
    if (copyFormApp.alertUserOfNewFile) {
      url += "&alertUserOfNewFile=" + copyFormApp.alertUserOfNewFile;
    }
    return this._dataService.post(url, data);
  }

  getKeyDatesByType = (applicationId: number): Observable<KeyDatesByType> => {
    const url = `api/Loan/${applicationId}/key-dates-by-type`;
    return this._dataService.get(url);
  }

  saveKeyDatesByType = (request: Record<string, string>, applicationId: number): Observable<any> => {
    const url = `api/Loan/${applicationId}/key-dates-by-type`;
    return this._dataService.post(url, request);
  }
}
