import { Component, Injector, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { RealEstateOwned } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import Swal from 'sweetalert2';
import { MenuItemStatus } from '../../tpo/models/enums/menu-item-status.enum';
import { UrlaMortgage } from '../models/urla-mortgage.model';
import { MortgageCalculationService } from '../services/mortgage-calculation.service';
import { UtilityService } from '../services/utility.service';
import { UrlaSectionComponent } from '../urla-section/urla-section.component';
import { ReoItemComponent } from './reo-item/reo-item.component';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';

@Component({
  selector: 'reo',
  templateUrl: 'reo.component.html'
})
export class ReoComponent extends UrlaSectionComponent {

  @ViewChild('reoForm')
  urlaSectionForm: NgForm;

  @ViewChildren('reoComponent') reoItems: QueryList<ReoItemComponent> | undefined;

  @Input()
  isReadOnly: boolean = false;

  menuItem: string = Constants.menu.urlaMenuItems.reo;

  yesNoOptions: EnumerationItem[] = [];

  currentReo: RealEstateOwned;

  additionalReoItems: RealEstateOwned[] = [];

  states: EnumerationItem[] = [];

  currentPropertyWillBeType: EnumerationItem[] = [];

  propertyStatusOptions: EnumerationItem[] = [];

  intendedPropertyWillBeType: EnumerationItem[] = [];

  reoPropertyTypes: EnumerationItem[] = [];

  liabilityTypeHELOC: string;

  liabilityTypeMortgageLoan: string;

  owningBorrowers: boolean[] = [];

  possibleBorrowers = [];

  borrowers = [];

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  private _mortgage: UrlaMortgage;

  private _reoEventSubscriptions: any[] = [];

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    if (this._mortgage) {
      if (this._mortgage && !this._mortgage.realEstateOwned) {
        this._mortgage.realEstateOwned = [];
      }
      this.setReoItems();
    }
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  constructor(private readonly _injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _mortgageStatsCalculationService: MortgageCalculationService,
    private readonly _utilityService: UtilityService,
    private readonly _urlaValidationService: UrlaValidationService) {
    super(_injector);
    this.states = this._enumsService.states;
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
    this.liabilityTypeHELOC = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC);
    this.liabilityTypeMortgageLoan = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan);
  }

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.currentPropertyWillBeType = enums[Constants.enumerations.currentPropertyWillBeType];
      this.propertyStatusOptions = enums[Constants.enumerations.propertyStatusOptions];
      this.intendedPropertyWillBeType = enums[Constants.enumerations.intendedPropertyWillBeType];
      this.reoPropertyTypes = enums[Constants.enumerations.reoPropertyTypes];
    })
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.reoItems) {
      this.reoItems.changes.subscribe((r) => {
        if (r._results && r._results.length > 0) {
          this._reoEventSubscriptions.forEach(subscription => {
            subscription.unsubscribe();
          });
          this._reoEventSubscriptions = [];
          this.subscribeToReoEvents();
        }
      });

      this.subscribeToReoEvents();
    }
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForReo(this.mortgage);
    super.setMenuItemStatus(validityStatus);
  }

  addReo = () => {
    let newReo = new RealEstateOwned();
    newReo.reoId = this._utilityService.getUniqueId();
    newReo.owningBorrowerIds = [];
    newReo.isSubjectProperty = false;
    this._mortgage.realEstateOwned.push(newReo);

    this.setReoItems();
  }

  private onImportFromSubjectPropertySelected = (reo: RealEstateOwned) => {
    reo.address1 = this.mortgage.subjectProperty.address1;
    reo.city = this.mortgage.subjectProperty.city;
    reo.state = this.mortgage.subjectProperty.state;
    reo.zipCode = this.mortgage.subjectProperty.zipCode;
    reo.marketValue = this.mortgage.subjectProperty.presentValue;
    reo.currentUsageType = this.mortgage.subjectProperty.propertyWillBe;
    reo.address2 = this.mortgage.subjectProperty.address2;
  }

  private onPushToSubjectPropertySelected = (reo: RealEstateOwned) => {
    this.mortgage.subjectProperty.address1 = reo.address1;
    this.mortgage.subjectProperty.address2 = reo.address2;
    this.mortgage.subjectProperty.city = reo.city;
    this.mortgage.subjectProperty.state = reo.state;
    this.mortgage.subjectProperty.zipCode = reo.zipCode;
    this.mortgage.subjectProperty.presentValue = reo.marketValue;
    this.mortgage.subjectProperty.propertyWillBe = reo.currentUsageType;
  }

  private onSubjectPropertyStatusChanged = (reo: RealEstateOwned) => {
    if (reo.isSubjectProperty) {
      this.mortgage.realEstateOwned.forEach(r => {
        if (reo.reoId != r.reoId) {
          r.isSubjectProperty = false;
        }
      })
    }
    this.mortgage.calculatedStats.totalPaidOffForRefinance = this._mortgageStatsCalculationService.calculateTotalPayOffForRefinance(this.mortgage);
    this.mortgage.calculatedStats.financialPartialPayoffTotalAmount = this._mortgageStatsCalculationService.calculateFinancialPartialPayoffTotalAmount(this.mortgage);
    this.mortgage.calculatedStats.totalDue = this._mortgageStatsCalculationService.calculateTotalDue(this.mortgage);
    this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._mortgageStatsCalculationService.calculateCashFromOrToTheBorrower(this.mortgage);

    this._mortgageStatsCalculationService.sendReoEvent();
  }

  private onAttemptedToDeleteREO = (reoItem: RealEstateOwned) => {
    const self = this;

    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this reo?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        const index = self._mortgage.realEstateOwned.indexOf(reoItem);
        if (index >= 0) {
          self._mortgage.realEstateOwned.splice(index, 1);
          self.setReoItems();
          if (reoItem.reoId) {
            const linkedLiability = self._mortgage.liabilities.find(l => l.reoId === reoItem.reoId);
            if (linkedLiability) {
              linkedLiability.reoId = null;
            }
          }
          self._mortgageStatsCalculationService.sendLiabilityEvent();
        }
      }
    });
  }

  private setReoItems = () => {
    this.currentReo = this._mortgage.realEstateOwned[0];
    const reoItems = this._mortgage.realEstateOwned;
    this.additionalReoItems = reoItems.slice(1, reoItems.length);
    if (reoItems.length === 0) {
      super.setMenuItemStatus(MenuItemStatus.Success);
    }
  }

  private subscribeToReoEvents = () => {
    if (this.reoItems) {
      const components: ReoItemComponent[] = this.reoItems.toArray();
      components.forEach(item => {
        const deleteItemClickedEventSubscription = item.attemptedToDeleteREO.subscribe(e => this.onAttemptedToDeleteREO(e));
        this._reoEventSubscriptions.push(deleteItemClickedEventSubscription);
        const selectedAsSubjectPropertyEventSubscription = item.subjectPropertyStatusChanged.subscribe(e => this.onSubjectPropertyStatusChanged(e));
        this._reoEventSubscriptions.push(selectedAsSubjectPropertyEventSubscription);
        const selectedToImportFromSubjectProperty = item.selectedToImportFromSubjectProperty.subscribe(e => this.onImportFromSubjectPropertySelected(e));
        this._reoEventSubscriptions.push(selectedToImportFromSubjectProperty);
        const selectedToPushToSubjectProperty = item.selectedToPushToSubjectProperty.subscribe(e => this.onPushToSubjectPropertySelected(e));
        this._reoEventSubscriptions.push(selectedToPushToSubjectProperty);
      });
    }
  }
}
