import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MortgageTerm } from 'src/app/models';
import { cloneDeep } from 'lodash';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { UrlaFieldsConfigBoundComponent } from 'src/app/shared/components/urla-fields-config-bound.component';

@Component({
  selector: 'late-charge',
  templateUrl: 'late-charge.component.html'
})
export class LateChargeComponent extends UrlaFieldsConfigBoundComponent implements OnInit {

  @Input()
  set mortgageTerm(mortgegTerm: MortgageTerm) {
    if (!mortgegTerm) {
      return;
    }
    this._mortgageTerm = cloneDeep(mortgegTerm);
    if (!this._mortgageTerm.lateChargeType) {
      this._mortgageTerm.lateChargeType = null;
    }
    this.setWhatLateChargeIsBasedOn();
  }

  get mortgageTerm(): MortgageTerm {
    return this._mortgageTerm;
  }

  @Input()
  hasSaveCancelButtons: boolean = true;

  @Input()
  readonly: boolean = false;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApproved: EventEmitter<MortgageTerm> = new EventEmitter<MortgageTerm>();

  protected lateChargeTypes: EnumerationItem[] = [];

  protected isPercentBased: boolean = false;

  private _mortgageTerm: MortgageTerm;
  private _noLateChargesEnumValue: string;
  private _flatDollarAmountEnumValue: string;

  constructor(private readonly injector: Injector, private readonly _enumsService: EnumerationService) {
    super(injector);
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.lateChargeTypes = enums[Constants.mortgageEnumerations.lateChargeType];
      this._noLateChargesEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LateChargeType.NoLateCharges);
      this._flatDollarAmountEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LateChargeType.FlatDollarAmount);
    });
  }

  ngOnInit() { }

  onSaveClicked = () => {
    this.changesApproved.emit(this._mortgageTerm);
  }

  protected onLateChargeTypeChanged = () => {
    this.setWhatLateChargeIsBasedOn();
  }

  private setWhatLateChargeIsBasedOn = () => {
    this.isPercentBased = false;
    if (this._mortgageTerm.lateChargeType && this._mortgageTerm.lateChargeType !== this._noLateChargesEnumValue) {
      this.isPercentBased = (this._mortgageTerm.lateChargeType !== this._flatDollarAmountEnumValue);
    }
    if (this.isPercentBased) {
      this._mortgageTerm.lateChargeAmount = null;
    } else {
      this._mortgageTerm.lateChargeRate = null;
    }
  }
}
