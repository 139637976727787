import { Component, Input } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaDropdownComponent } from '../urla-dropdown/urla-dropdown.component';

@Component({
  selector: 'urla-country-dropdown',
  templateUrl: '../urla-dropdown/urla-dropdown.component.html',
  providers: [MakeProvider(UrlaCountryDropdownComponent)],
})

export class UrlaCountryDropdownComponent extends UrlaDropdownComponent {

  @Input()
  showAllCountries: boolean = false;

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _contextService: ApplicationContextService
  ) {
    super();
  }

  ngOnInit() {
    if (this.showAllCountries) {

      this._contextService.context
        .subscribe((ctx) => {
          this.options = ctx.globalConfig.countries.map(c => new EnumerationItem(c.name, c.alpha2.toLowerCase()));
        });
    }
    else {
      this.options = this._enumsService.countries.filter(c => c.value === 'us');
    }

  }
}
