import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'qa-more-popover',
  templateUrl: './qa-more-popover.component.html',
  styleUrls: ['./qa-more-popover.component.scss'],
})
export class QaMorePopoverComponent {
  @ViewChild('popover', { static: true }) popover: PopoverDirective;
  @Input() content: string | TemplateRef<any> | undefined;
  @Input() outsideClick: boolean = true;

  show(): void {
    this.popover.show();
  }

  hide(): void {
    this.popover.hide();
  }
}
