import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Liability, PayoffType } from 'src/app/models';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';

@Component({
  selector: 'debt-management-calculator',
  templateUrl: './debt-management-calculator.component.html'
})
export class DebtManagementCalculatorComponent implements OnInit {

  @Input()
  set mortgage(value: UrlaMortgage) {
    this._mortgage = value;
    if(value){
      this.isRefinance = value.subjectProperty?.purposeOfLoan == "Refinance";
    }
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  savings: SavingsOverTime[] = [];
  today = new Date();

  totalDebtConsolidated: number = 0;
  totalDebtPayments: number = 0;
  monthlyResidualIncomeIncrease: number = 0;
  earningRate: number = 0;

  liabilities: Liability[] = [];

  currentPandI: number = 0;
  proposedPandI: number = 0;

  percentOfIncome: number = 0;

  imageData: SafeResourceUrl;
  imageData2: SafeResourceUrl;

  isRefinance: boolean = false;

  private _totalMonthlyIncome = 0;
  private _mortgage: UrlaMortgage;

  constructor(
    private readonly _mortgageCalculationService: MortgageCalculationService,
    private readonly _utilityService: UtilityService
  ) {
    this._utilityService.getImageFileContentAsBase64("assets/images/benefit-calculators/debt-management-calculator/1.png",  (result) => {
      this.imageData = result;
    });

    this._utilityService.getImageFileContentAsBase64("assets/images/benefit-calculators/debt-management-calculator/2.png",  (result) => {
      this.imageData2 = result;
    });
   }

  ngOnInit(): void {
    // Here, take the debts that are to be paid off, but if the mortgage for the subject property is linked as a liability, DO NOT take it
    this.liabilities = this.getDebtsOtherThanMortgageForSubjectProperty();

    this.totalDebtConsolidated = this.liabilities.map(l => l.unpaidBalance).reduce((prev, curr) => prev + curr, 0);
    this.totalDebtPayments = this.liabilities.map(l => l.monthlyPayment).reduce((prev, curr) => prev + curr, 0);;

    this.calculateSavings();
  }

  private calculateSavings = () => {
    this._totalMonthlyIncome = 0;
    this.mortgage.borrowers.forEach(b => {
      this._totalMonthlyIncome += this._mortgageCalculationService.calculateBorrowerTotalMonthlyIncome(b);
    });

    this.currentPandI = this.mortgage.currentHousingExpense?.firstMortgagePrincipalAndInterest || 0;
    this.proposedPandI = this.mortgage.proposedHousingExpense?.firstMortgagePrincipalAndInterest || 0;

    const savingsFromReducingMonthlyHousingExpenses = this.currentPandI - this.proposedPandI;

    this.monthlyResidualIncomeIncrease = savingsFromReducingMonthlyHousingExpenses + this.totalDebtPayments;

    if (this._totalMonthlyIncome > 0) {
      this.percentOfIncome = this.monthlyResidualIncomeIncrease / this._totalMonthlyIncome;
    }

    let nowDate = new Date();
    let savings = [];

    for (let i = 0; i < 5; i++) {
      const saving = new SavingsOverTime(i == 0 ? nowDate : new Date("1-1-" + (nowDate.getFullYear() + i)), this.monthlyResidualIncomeIncrease * 2 + (12 * this.monthlyResidualIncomeIncrease * i));
      savings.push(saving);
    }

    this.savings = savings;
  }

  private getDebtsOtherThanMortgageForSubjectProperty = (): Liability[] => {
    // Here, take the debts that are to be paid off, but if the mortgage for the subject property is linked as a liability, DO NOT take it
    let liabilities = this.mortgage.liabilities.filter(l => l.payoffType == PayoffType.Full);

    let indexForLiabilityForSubjectProperty: number | undefined = undefined;
    for (let i = 0; i < liabilities.length; i++) {
      if (liabilities[i].reoId) {
        const reo = this.mortgage.realEstateOwned.find(reo => reo.reoId === liabilities[i].reoId);
        if (reo && this.mortgage.subjectProperty) {
          if (reo.address1.toLocaleLowerCase() === this.mortgage.subjectProperty.address1.toLocaleLowerCase() &&
            reo.city.toLocaleLowerCase() === this.mortgage.subjectProperty.city.toLocaleLowerCase() &&
            reo.state.toLocaleLowerCase() === this.mortgage.subjectProperty.state.toLocaleLowerCase() &&
            reo.zipCode.toLocaleLowerCase() === this.mortgage.subjectProperty.zipCode.toLocaleLowerCase()) {
            indexForLiabilityForSubjectProperty = i;
            break;
          }
        }
      }
    }

    if (indexForLiabilityForSubjectProperty >= 0) {
      liabilities.splice(indexForLiabilityForSubjectProperty, 1);
    }

    return liabilities;
  }
}

export class SavingsOverTime {
  date: Date;
  amount: number;

  constructor(date: Date, amount: number) {
    this.date = date;
    this.amount = amount;
  }
}
