import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import * as _ from 'lodash';
import { TypeOfIncomeEnum } from 'src/app/models';
import { PayPeriod } from 'src/app/modules/leads/models/lead-employment.model';
import { LeadUtils } from 'src/app/modules/leads/services/utils';

@Component({
  templateUrl: 'lead-income-dialog.component.html',
  styleUrls: ['lead-income-dialog.component.scss']
})

export class LeadIncomeDialogComponent {

  @ViewChild("incomeForm")
  incomeForm: NgForm;

  incomePayPeriods: EnumerationItem[] = [];
  incomeTypes = [
    {
      name: "Base",
      value: TypeOfIncomeEnum.Base,
    },
    {
      name: "Bonus",
      value: TypeOfIncomeEnum.Bonus,
    },
    {
      name: "Comissions",
      value: TypeOfIncomeEnum.Commissions,
    },
    {
      name: "Overtime",
      value: TypeOfIncomeEnum.Overtime,
    }
  ]

  title: string = "";

  editOnly: boolean = false;

  protected readonly PayPeriod = PayPeriod;

  private _incomes: any[] = [];

  set incomes(incomes: any[]) {
    this._incomes = _.cloneDeep(incomes);
    this._incomes.forEach(i => {
      i.payPeriod = PayPeriod.Monthly;
    })
    this.calculateTotalIncome();
  }

  get incomes(): any[] {
    return this._incomes;
  }

  activeDeletionIndex: number = -1;

  totalIncome: number = 0;

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _utilsService: UtilityService,
    public activeModal: NgbActiveModal,
    private readonly _utilityService: UtilityService
  ) {
    this.incomePayPeriods = this._enumsService.getIncomePayPeriods();
  }

  onOkClicked = () => {
    if (!this.incomeForm) return;
    if (!this.incomeForm.form.valid) return;

    this.activeModal.close(this._incomes);
  }

  onCancelClicked = () => {
    this.activeModal.close('cancel');
  }

  onAmountChanged = (income: any) => {
    income.monthlyValue = LeadUtils.calculateMonthlyAmountByPayPeriod(
      income.amount,
      income.payPeriod,
      income.hoursPerWeek,
    );
    this.calculateTotalIncome();
  }

  private calculateTotalIncome = () => {
    this.totalIncome = this.incomes.reduce((pV, cV) => pV + cV.monthlyValue, 0);
  }
}
