import { Injectable } from "@angular/core";
import { EnvironmentService } from "../core/services/environment/environment.service";
import { LocalStorageService } from "../core/services/local-storage.service";
import {ApplicationContext, NewGroupMmsHistoryPosted, NewSmsHistoryPosted, UserType} from '../models';
import { AuthData } from "../models/auth/auth-data.model";
import * as signalR from "@microsoft/signalr";
import { ApplicationContextService } from "./application-context.service";
import { NewLeadReceivedDialogComponent } from "../layout/admin/lead-route-leads/dialogs/new-lead-received-dialog/new-lead-received-dialog.component";
import { EventEditorDialogComponent } from "../modules/events/components/event-editor-dialog/event-editor-dialog.component";
import { EditTaskDialogComponent } from "../modules/tasks/components/edit-task-dialog/edit-task-dialog.component";
import { DialerEventType } from "../modules/dialer/models/dialer-event.model";
import { AdminEventType, AdminService } from "./admin.service";
import { NotificationService } from "./notification.service";
import { LogService } from "./log.service";
import { DialerService } from "../modules/dialer/services/dialer.service";
import { VoiceService } from "./voice.service";
import { LeadRouteService } from "./lead-route.service";
import { LeadRouteEventType } from "../modules/leads/models/lead-event.model";
import { AlertsCreatedEvent, AlertsService } from "./alerts.service";
import { ChatService } from "./chat.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConversationService } from "../modules/conversations/services/conversation.service";
import { FirebaseServicesService } from "./firebase";
import { TaskService } from "./task.service";

@Injectable()
export class SignalRService {

  get connection(): signalR.HubConnection {
    return this._connection;
  }

  private _connection: signalR.HubConnection;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _localStorageService: LocalStorageService,
    private readonly _conversationService: ConversationService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _environment: EnvironmentService,
    private readonly _adminService: AdminService,
    private readonly _notificationService: NotificationService,
    private readonly _logService: LogService,
    private readonly _dialerService: DialerService,
    private readonly _voiceService: VoiceService,
    private readonly _alertsService: AlertsService,
    private readonly _leadRouteService: LeadRouteService,
    private readonly _firebaseServicesService: FirebaseServicesService,
    private readonly _taskService: TaskService,
  ) { }

  run = (context: ApplicationContext) => {
    let connection = this.createConnection(context);
    const self = this;

    connection.on('InitializeAlertData', function (alertList) {
      // alertFactory.SetAlertData(alertList);
    });

    connection.on('SendToUser', function (model) {
      console.debug('message received: ' + model);
      switch (model.msgType) {
        case 'NewLead':
          const modal = self._modalService.open(NewLeadReceivedDialogComponent)
          modal.componentInstance.notifyOnly = false;
          modal.componentInstance.leadModel = model;
          modal.componentInstance.isFreeForAllAccept = false;
          modal.componentInstance.closeDialog.subscribe(() => {
            modal.close();
          })

          break;
      }
    });

    connection.on('AddAlertData', function (payload) {
      console.debug('addAlertData called', payload);
      const alertsCreatedEvent = new AlertsCreatedEvent();
      alertsCreatedEvent.alerts = payload;
      self._alertsService.publish(alertsCreatedEvent);
    });

    connection.on('RemoveAlertData', function (payload) {
      console.debug('removeAlertData called', payload);
    });

    connection.on('NotifyUser', function ({ body, head }) {
      console.debug('notifyUser called', body, head);
      const message = `<i>${body}</i>`;
      self._notificationService.showInfo(
        message,
        head,
        {
          timer: 10000,
          timerProgressBar: true,
          showCloseButton: true,
        }
      );
    });

    const isTPOUser = context.userPermissions.userType == UserType.Tpo;

    if (!isTPOUser) {
      connection.on('ShowReleaseNotes', function (payload) {
        if (self._applicationContextService) {
          self._applicationContextService.updateApplicationVersion(payload);
        }
      });

      connection.on('NewUnassignedLeadPosted', function (leadId) {
        console.debug('NewUnassignedLeadPosted called', leadId);
        const message = `<i><a href='admin/leads?filter=unassigned'>Click Here</i> to go to lead list`;
        self._notificationService.showInfo(
          message,
          "New Lead Available!",
          {
            timer: 60000,
            timerProgressBar: true,
            showCloseButton: true,
          }
        );
      });

      connection.on('NewSmsHistoryPosted', function (payload: NewSmsHistoryPosted) {
        console.debug('NewSmsHistoryPosted called', payload);
        if (payload.direction == 'Inbound') {
          self._conversationService.openSmsChat({
            userName: payload.senderName || payload.from,
            userPhone: payload.from,
            smsMessage: payload,
            external: true
          })
        }
      });

      connection.on('NewGroupMmsHistoryPosted', function (payload: NewGroupMmsHistoryPosted) {
        console.debug('NewGroupMmsHistoryPosted called', payload);
        if (payload.direction == 'Inbound') {
          self._conversationService.openSmsChat({
            userName: payload.senderName || payload.senderNumber,
            userPhone: payload.senderNumber,
            smsMessage: payload,
            external: true
          })
        }
      });

      connection.on('NewBorrowerMessagePosted', function (model) {
        console.debug('NewBorrowerMessagePosted called', model);
        if (model.senderId !== context.userPermissions.userId) {
          self._conversationService.addBorrowerChat(
            model.applicationId,
            model.borrowerId,
            model,
            false,
            true
          )
        }
      });

      connection.on('NewDocumentReadyForReview', function (model) {
        console.debug('NewDocumentReadyForReview called', model);
        const { primaryBorrowerId, appId } = model;
        if (!primaryBorrowerId || !appId) return;

        const message = `<i><a href='admin/app-details/${appId}'>Click Here</a></i> to review now`;
        self._notificationService.showInfo(
          message,
          'New Document Ready for Review!',
          {
            timer: 15000,
            timerProgressBar: true,
            showCloseButton: true,
          }
        );
      });

      connection.on('NewUnassignedLeadTaken', function (leadId) {
        console.debug('NewUnassignedLeadTaken called', leadId);
      });

      connection.on('AppointmentComingUp', function (model) {
        console.debug('appointmentComingUp called', model);
        const modal = self._modalService.open(EventEditorDialogComponent);
        modal.componentInstance.event = model;
      });

      if (context.userPermissions.dialerEnabled) {
        connection.on(AdminEventType.LeadsOnToggle, (model) => {
          console.log("leadsOnToggle called", model);

          self._adminService.publish({
            eventType: AdminEventType.LeadsOnToggle,
            data: model
          })
        });

        connection.on(AdminEventType.CallsOnToggle, (model) => {
          self._adminService.publish({
            eventType: AdminEventType.CallsOnToggle,
            data: model,
          })
        });

        connection.on(DialerEventType.DialListRecordsRemoved, function (model) {
          console.log('dialListRecordsRemoved called', model);

          self._dialerService.publish({
            eventType: DialerEventType.DialListRecordsRemoved,
            data: model
          })
        });

        connection.on(DialerEventType.WarmTransferCompleted, (model) => {
          console.log('warmTransferCompleted called', model);
          self._logService.logSignalRAck(DialerEventType.WarmTransferCompleted, model.traceId);
          //self._voiceService.loadConferenceParticipants(model.data);

          self._dialerService.publish({
            eventType: DialerEventType.WarmTransferCompleted,
            data: model.data
          })
        });

        connection.on(DialerEventType.WarmTransferCanceled, (model) => {
          console.log('WarmTransferCanceled called', model);
          self._logService.logSignalRAck(DialerEventType.WarmTransferCanceled, model.traceId);
          //self._voiceService.loadConferenceParticipants(model.data);

          self._dialerService.publish({
            eventType: DialerEventType.WarmTransferCanceled,
            data: model.data
          })
        });

        connection.on(DialerEventType.WarmTransferReceived, (model) => {
          console.log('WarmTransferReceived called', model);
          self._logService.logSignalRAck(DialerEventType.WarmTransferReceived, model.traceId);
          //self._voiceService.loadConferenceParticipants(model.data);

          self._dialerService.publish({
            eventType: DialerEventType.WarmTransferReceived,
            data: model.data
          })
        });

        connection.on(DialerEventType.ScreenPopRecord, (model) => {
          console.log('ScreenPopRecord called', model);
          self._logService.logSignalRAck(DialerEventType.ScreenPopRecord, model.traceId);
          //self._voiceService.loadConferenceParticipants(model.data);

          self._dialerService.publish({
            eventType: DialerEventType.ScreenPopRecord,
            data: model.data
          })
        });

        connection.on(DialerEventType.CallStatusChanged, (model) => {
          console.log('callStatusChanged called', model);
          self._logService.logSignalRAck(DialerEventType.CallStatusChanged, model.traceId);

          self._dialerService.publish({
            eventType: DialerEventType.CallStatusChanged,
            data: model.data
          })
        });

        connection.on(DialerEventType.ConferenceEnded, (model) => {
          console.log('conferenceEnded called', model);
          self._logService.logSignalRAck(DialerEventType.ConferenceEnded, model.traceId);

          self._dialerService.publish({
            eventType: DialerEventType.ConferenceEnded,
            data: {
              conferenceId: model.data
            }
          })
        });

        connection.on(DialerEventType.WarmTransferConnected, (model) => {
          console.log('warmTransferConnected called', model);
          self._logService.logSignalRAck(DialerEventType.WarmTransferConnected, model.traceId);

          self._dialerService.publish({
            eventType: DialerEventType.WarmTransferConnected,
            data: model.data
          })
        });

        connection.on(LeadRouteEventType.FreeForAllLeadAvailable, (model) => {
          console.log('freeForAllLeadAvailable called', model);
          self._logService.logSignalRAck(LeadRouteEventType.FreeForAllLeadAvailable, model.traceId);
          self._leadRouteService.publish({
            eventType: LeadRouteEventType.FreeForAllLeadAvailable,
            data: model.data,
          })
        });

        connection.on(LeadRouteEventType.WarmTransferLeadAvailable, (model) => {
          console.log('warmTransferLeadAvailable called', model);
          self._logService.logSignalRAck(LeadRouteEventType.WarmTransferLeadAvailable, model.traceId);
          self._leadRouteService.publish({
            eventType: LeadRouteEventType.WarmTransferLeadAvailable,
            data: model.data,
          })
        });

        connection.on(LeadRouteEventType.CancelWarmTransferLeadAvailable, (model) => {
          console.log('cancelWarmTransferLeadAvailable called', model);
          self._logService.logSignalRAck(LeadRouteEventType.CancelWarmTransferLeadAvailable, model.traceId);
          self._leadRouteService.publish({
            eventType: LeadRouteEventType.CancelWarmTransferLeadAvailable,
            data: model.data,
          })
        });

        connection.on(LeadRouteEventType.FreeForAllLeadTaken, (model) => {
          console.log('freeForAllLeadTaken called', model);
          self._logService.logSignalRAck(LeadRouteEventType.FreeForAllLeadTaken, model.traceId);
          self._leadRouteService.publish({
            eventType: LeadRouteEventType.FreeForAllLeadTaken,
            data: model.data,
          })
        });

        connection.on(LeadRouteEventType.NewAssignedLeadPosted, (payload) => {
          console.log('NewAssignedLeadPosted called', payload);

          self._leadRouteService.publish({
            eventType: LeadRouteEventType.NewAssignedLeadPosted,
            data: payload,
          })
        });
      }

      if (context.userPermissions.ocrEnabled) {
        connection.on('DocFileSplittingComplete', function (model) {
          console.debug('Doc file event triggered', model);

          const { borrowerId, applicationId, fileName, applicationName } = model;

          const url = `app-details/${borrowerId}/${applicationId}`;
          const index = window.location.href.indexOf(url);
          const className = `notification-toaster-${Math.random().toString(26).slice(2)}`;

          let message = `File ${fileName} in ${applicationName} loan has been processed.`;
          if (index > -1) {
            message += `Please refresh the page. &nbsp <b><i class="${className} la la-refresh"></i></b>`;
          }

          self._notificationService.showInfo(
            message,
            "Info",
            {
              timer: 10000,
              timerProgressBar: true,
              showCloseButton: true,
            }
          );

          if (index === -1) return;

          setTimeout(() => {
            const refreshIcon = document.querySelector(`.${className}`) as HTMLElement;
            if (!refreshIcon) return;

            refreshIcon.onclick = location.reload;
          }, 100)
        });
      }
    }

    connection.on('UpdateRecentAppList', self.onRecentApplicationChanged);

    connection.on('UpdateRecentLeadList', self.onRecentLeadChanged);

    connection.on('UpdateRecentAgentList', self.onRecentAgentChanged);

    connection.on('CreditReportResponseReceived', function (model) {
      console.debug('creditReportResponseReceived called', model);
      self._firebaseServicesService.getReportContent(model).subscribe(result => {
        const newWindow = window.open('', '_blank');
        newWindow.document.write(result);
      })
    });

    connection.on('LoanDocTaskChangedStatus', function (model) {
      console.debug('loanDocTaskChangedStatus called', model);

      const className = `notification-toaster-${Math.random().toString(26).slice(2)}`
      const message = `<i class="${className}">Click Here</i> to view now`;
      self._notificationService.showInfo(
        message,
        'Task Status Changed!',
        {
          timer: 15000,
          timerProgressBar: true,
          showCloseButton: true,
        }
      );

      setTimeout(() => {
        const clickBtn = document.querySelector(`.${className}}`) as HTMLElement;
        if (!clickBtn) return;

        clickBtn.onclick = () => {
          self._taskService.getTaskDashboardViewById(model.loanDocTaskId).subscribe(task => {
            const modal = self._modalService.open(EditTaskDialogComponent);
            modal.componentInstance.task = task;
          })
        };
      }, 100)
    });
  }

  createConnection = (context: ApplicationContext): signalR.HubConnection => {
    let authData = this._localStorageService.authorizationData as AuthData;

    if (authData) {

      let userType = context.userPermissions.userType;
      if (!userType) {
        return;
      }

      if (this._connection) {
        return this._connection;
      }

      let connection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Trace)
        .withUrl(this._environment.apiInfo.apiBaseUrl + "usernotificationhub", { accessTokenFactory: () => authData.token })
        .withAutomaticReconnect()
        .build();

      connection.onclose(error => {
        console.assert(connection.state === signalR.HubConnectionState.Disconnected);
        this._localStorageService.setItem("HubStatus", connection.state)
        console.log('Live Sync connection closed.');
      });

      connection.onreconnecting(error => {
        console.assert(connection.state === signalR.HubConnectionState.Reconnecting);
        this._localStorageService.setItem("HubStatus", connection.state)
        console.log('We are currently experiencing difficulties with the connection. Reconnecting...');
      });

      connection.onreconnected(connectionId => {
        console.assert(connection.state === signalR.HubConnectionState.Connected);
        this._localStorageService.setItem("HubStatus", connection.state)
        console.log('Connection restored.');
      });

      connection.start().then(() => {
        this._localStorageService.setItem("HubStatus", connection.state);
      }).catch(err => {
        return console.error(err);
      });

      this._connection = connection;
      return connection;
    }
  }

  private onRecentApplicationChanged = (recentList) => {
    this._applicationContextService.updateRecentAppsList(recentList);
  }

  private onRecentLeadChanged = (recentList) => {
    this._applicationContextService.updateRecentLeadsList(recentList);
  }

  private onRecentAgentChanged = (recentList) => {
    this._applicationContextService.updateRecentAgentsList(recentList);
  }
}
