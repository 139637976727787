<div class="grid-layout has-border">
    <div class="grid-header">
        <div class="grid-header-left">
            <div> CALCULATED TOTALS </div>
        </div>
        <div class="grid-header-right">
        </div>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'TOTAL DUE FROM BORROWER(S)'">
        </urla-grid-cell>
        <urla-currency-input class="col-md-4 col-xs-4" name="totalDueFromBorrowers" [readonly]="isReadOnly"
            [(ngModel)]="mortgage.calculatedStats.totalDue" [readonly]="true">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'LESS TOTAL MORTGAGE LOANS AND TOTAL CREDITS'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="isReadOnly" class="col-md-4 col-xs-4" name="totalMortgageLoansAndCredits"
            [(ngModel)]="mortgage.calculatedStats.totalMortgageLoansAndCredits" [readonly]="true">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'Cash From/To the Borrower'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="isReadOnly" class="col-md-4 col-xs-4" name="cashFromOrToTheBorrower"
            [(ngModel)]="mortgage.calculatedStats.cashFromOrToTheBorrower" [readonly]="true">
        </urla-currency-input>
    </div>
</div>
