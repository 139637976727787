import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { LoanAmountCalculatorDialogComponent } from 'src/app/modules/loan-amount-calculator/loan-amount-calculator-dialog/loan-amount-calculator-dialog.component';
import { Constants } from 'src/app/services/constants';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'lead-loan-detail',
  templateUrl: './lead-loan-detail.component.html',
  styleUrls: ['./lead-loan-detail.component.scss'],
  viewProviders: [formViewProvider],
})
export class LeadLoanDetailComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  lead: Lead;

  @Input()
  isDrawer: boolean = false;

  @Input()
  isAppraisedValueHidden: boolean = false;

  totalLoanAmount: number = 0;
  ltv: number = 0;
  cltv: number = 0;

  loanPurposes: LoanPurpose[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _modalService: NgbModal
  ) {
    super(injector);
  }

  ngOnInit(): void {

    this.applicationContextService.context.subscribe(context => {
      this.loanPurposes = context.globalConfig.loanPurpose;
    });

    this.calculate_LA_AV_DP("LoanAmount");
  }

  changevalue = () => {
    let total = this.lead.loanAmount;
    this.totalLoanAmount = total;

    if (this.lead.appraisedValue == 0) {
      this.ltv = 0;
      this.cltv = 0;
    } else {
      this.ltv = this.lead.loanAmount / this.lead.appraisedValue;
      this.cltv = this.totalLoanAmount / this.lead.appraisedValue;
    }
  }

  onCalculatorClicked = () => {
    const modalRef = this._modalService.open(LoanAmountCalculatorDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.purchasePriceAmount = this.lead.appraisedValue;
    modalRef.componentInstance.loanAmount = this.lead.loanAmount;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        this.lead.loanAmount = result;
        this.calculate_LA_AV_DP('LoanAmount');
      }
    }, (err) => {
      console.log(err);
    });
  }

  calculate_LA_AV_DP = (valueChanging: string) => {
    switch (valueChanging) {
      case "LoanAmount":
        if (this.lead.appraisedValue > 0) {
          this.lead.downPayment = this.lead.appraisedValue - (this.lead.loanAmount || 0);
        } else if (this.lead.downPayment > 0) {
          this.lead.appraisedValue = this.lead.downPayment + (this.lead.loanAmount || 0);
        }
        break;
      case "AppraisedValue":
        if (this.lead.loanAmount > 0) {
          this.lead.downPayment = (this.lead.appraisedValue || 0) - this.lead.loanAmount;
        } else if (this.lead.downPayment > 0) {
          this.lead.loanAmount = (this.lead.appraisedValue || 0) - this.lead.downPayment;
        }
        break;
      case "DownPayment":
        if (this.lead.appraisedValue > 0) {
          this.lead.loanAmount = this.lead.appraisedValue - (this.lead.downPayment || 0);
        } else if (this.lead.loanAmount > 0) {
          this.lead.appraisedValue = (this.lead.downPayment || 0) + this.lead.loanAmount;
        }
        break;
    }

    this.changevalue();
  }

  isPurchaseTransaction = (loanPurposeId: number, defaultWhenUnset: boolean) => {
    if (!loanPurposeId) return defaultWhenUnset;
    var lp = this.loanPurposes.find(lp => lp.loanPurposeId === loanPurposeId);
    if (!lp) return defaultWhenUnset;
    return lp.mortgageLoanPurpose === 'Purchase';
  }

  onLtvValueChanged = () => {
    this.lead.loanAmount = this.ltv * this.lead.appraisedValue;
  }

}
