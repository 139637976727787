<div class='grid-layout has-border'>
  <div class='grid-header mb-1'>
    <div class='grid-header-left'>L2a. Legal Descriptions</div>

    <div class='grid-header-right'></div>
  </div>

  <div class='grid-layout-row px-2 mb-2'>
    <!-- Full Legal Description -->
    <urla-textarea-input
      [(ngModel)]='formValue.fullDescription.legalDescription'
      [inEditMode]='inEditMode'
      name='fullDescription'
      [ngClass]="{ 'bg-transparent': !isReadOnly }"
      [readonly]='isReadOnly'
      [required]="
        urlaFieldsConfig['legalDescriptions.fullDescription']?.required == 'required' ||
        urlaFieldsConfig['legalDescriptions.fullDescription']?.required == 'requested'
      "
      [urlaFieldsConfig]='urlaFieldsConfig'
      class='col-12'
      fieldBeingEdited='legalDescriptions.fullDescription'
      label='Full Legal Description'
      [rows]='2'
      [hasEditButton]='true'
      [isEditableEvenIfItHasEditButton]='!isDescriptionsEditorDrawerOpen'
      (editClickEvent)='onClickEditFullLegalDescription()'
    >
    </urla-textarea-input>
  </div>
</div>

<legal-descriptions-editor-drawer
  #legalDescriptionsEditorDrawer
></legal-descriptions-editor-drawer>
