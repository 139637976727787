<div class='row quick-apply'>
  <div class='col'>
    <div class='card'>
      <div #header [class.qa-sticky-header]='headerOptions?.isSticky'
           [style.top]='headerOptions?.isSticky
            ? headerOptions.offsetTop + "px"
            : null'
           class='card-header'>
        <h4 class='card-title'>
          <ng-content select='[slot=header]'></ng-content>
        </h4>

        <div class='card-actions'>
          <ng-content select='[slot=actions]'></ng-content>
        </div>
      </div>

      <div class='card-body'>
        <ng-content select='[slot=body]'></ng-content>
      </div>
    </div>
  </div>
</div>
