import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ParcelIdentification, ParcelIdentificationTypeEnum } from 'src/app/models';
import { UrlaRequiredState } from 'src/app/modules/urla/services/utility.service';

@Component({
  selector: 'parcel-identification-details',
  templateUrl: './parcel-identification-details.component.html',
  styleUrls: ['./parcel-identification-details.component.scss']
})
export class ParcelIdentificationDetailsComponent implements OnInit {

  @ViewChild('parcelIdentificationForm')
  parcelIdentificationForm: NgForm | undefined;

  get parcelIdentifications(): ParcelIdentification[] {
    return this._parcelIdentifications;
  }

  @Input()
  set parcelIdentifications(value: ParcelIdentification[]) {
    this._parcelIdentifications = value;
    if (value) {
      this.parcelIds = [...value]; // clone
      this.initializeParcelIdentifiers(value);
    }
    else {
      this.parcelIds = [];
      this.initializeParcelIdentifiers([]);
    }
  }

  @Input() isReadOnly: boolean = false;

  @Input() inEditMode: boolean = false;

  @Input() urlaFieldsConfig: Record<string, UrlaRequiredState>;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApproved: EventEmitter<any> = new EventEmitter<any>();

  parcelIds: ParcelIdentification[] = [];

  parcelIdentificationNumber: ParcelIdentification;
  taxMapIdentifier: ParcelIdentification;
  taxParcelIdentifier: ParcelIdentification;
  assessorUnformattedIdentifier: ParcelIdentification;
  torrensCertificateIdentifier: ParcelIdentification;
  others: ParcelIdentification[] = [];

  private _parcelIdentifications: ParcelIdentification[];

  constructor() { }

  ngOnInit(): void {
  }

  onSaveClicked = () => {
    if (!this.parcelIdentificationForm) return;

    this.parcelIdentificationForm.form.markAllAsTouched();
    if (!this.parcelIdentificationForm.form.valid) return;

    //
    let identifiers = [];

    if(this.parcelIdentificationNumber.parcelIdentifier?.trim()){
      identifiers.push(this.parcelIdentificationNumber);
    }

    if(this.taxMapIdentifier.parcelIdentifier?.trim()){
      identifiers.push(this.taxMapIdentifier);
    }

    if(this.taxParcelIdentifier.parcelIdentifier?.trim()){
      identifiers.push(this.taxParcelIdentifier);
    }

    if(this.assessorUnformattedIdentifier.parcelIdentifier?.trim()){
      identifiers.push(this.assessorUnformattedIdentifier);
    }

    if(this.torrensCertificateIdentifier.parcelIdentifier?.trim()){
      identifiers.push(this.torrensCertificateIdentifier);
    }

    if(this.others.length){
      this.others.forEach(o => {
        if(o.identificationTypeOtherDescription?.trim()){
          identifiers.push(o);
        }
      })
    }

    this.parcelIds = [...identifiers];


    this.changesApproved.emit(this.parcelIds);
  }

  onCancelClicked = () => {
    this.cancelled.emit();
  }

  onAddClicked = () => {
    this.others.push(new ParcelIdentification(ParcelIdentificationTypeEnum.Other));
  }

  onClickDelete = (index: number) => {
    this.others.splice(index, 1);
  }

  private initializeParcelIdentifiers = (vals: ParcelIdentification[]) => {
    //
    let parcelIdentificationNumber = vals.find(d => d.identificationType == ParcelIdentificationTypeEnum.ParcelIdentificationNumber);
    if (!parcelIdentificationNumber) {
      this.parcelIds.push(new ParcelIdentification(ParcelIdentificationTypeEnum.ParcelIdentificationNumber));
    }

    this.parcelIdentificationNumber = this.parcelIds.find(d => d.identificationType == ParcelIdentificationTypeEnum.ParcelIdentificationNumber);

    //
    let taxMapIdentifier = vals.find(d => d.identificationType == ParcelIdentificationTypeEnum.TaxMapIdentifier);
    if (!taxMapIdentifier) {
      this.parcelIds.push(new ParcelIdentification(ParcelIdentificationTypeEnum.TaxMapIdentifier));
    }

    this.taxMapIdentifier = this.parcelIds.find(d => d.identificationType == ParcelIdentificationTypeEnum.TaxMapIdentifier);

    //
    let taxParcelIdentifier = vals.find(d => d.identificationType == ParcelIdentificationTypeEnum.TaxParcelIdentifier);
    if (!taxParcelIdentifier) {
      this.parcelIds.push(new ParcelIdentification(ParcelIdentificationTypeEnum.TaxParcelIdentifier));
    }

    this.taxParcelIdentifier = this.parcelIds.find(d => d.identificationType == ParcelIdentificationTypeEnum.TaxParcelIdentifier);

    //
    let assessorUnformattedIdentifier = vals.find(d => d.identificationType == ParcelIdentificationTypeEnum.AssessorUnformattedIdentifier);
    if (!assessorUnformattedIdentifier) {
      this.parcelIds.push(new ParcelIdentification(ParcelIdentificationTypeEnum.AssessorUnformattedIdentifier));
    }

    this.assessorUnformattedIdentifier = this.parcelIds.find(d => d.identificationType == ParcelIdentificationTypeEnum.AssessorUnformattedIdentifier);

    //
    let torrensCertificateIdentifier = vals.find(d => d.identificationType == ParcelIdentificationTypeEnum.TorrensCertificateIdentifier);
    if (!torrensCertificateIdentifier) {
      this.parcelIds.push(new ParcelIdentification(ParcelIdentificationTypeEnum.TorrensCertificateIdentifier));
    }

    this.torrensCertificateIdentifier = this.parcelIds.find(d => d.identificationType == ParcelIdentificationTypeEnum.TorrensCertificateIdentifier);


    //
    let other = vals.find(d => d.identificationType == ParcelIdentificationTypeEnum.Other);
    if (!other) {
      this.parcelIds.push(new ParcelIdentification(ParcelIdentificationTypeEnum.Other));
    }

    this.others = this.parcelIds.filter(d => d.identificationType == ParcelIdentificationTypeEnum.Other);

  }

}
