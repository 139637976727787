import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Income } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { PayPeriod } from 'src/app/modules/leads/models/lead-employment.model';
import { LeadUtils } from 'src/app/modules/leads/services/utils';
import { EnumerationService } from 'src/app/services/enumeration-service';

@Component({
  selector: 'income-calculator-dialog',
  templateUrl: './income-calculator-dialog.component.html',
  styleUrls: ['./income-calculator-dialog.component.scss']
})
export class IncomeCalculatorDialogComponent implements OnInit {

  @ViewChild("incomeCalculateForm") incomeCalculateForm: NgForm;

  incomePayPeriods: EnumerationItem[] = [];

  income: Income;

  protected readonly PayPeriod = PayPeriod;

  constructor(private readonly _enumsService: EnumerationService,
    public activeModal: NgbActiveModal) {
    this.incomePayPeriods = this._enumsService.getIncomePayPeriods();
  }

  ngOnInit(): void {
    if (!this.income.amount && !this.income.payPeriod && this.income.monthlyIncome) {
      this.income.amount = this.income.monthlyIncome;
      this.income.payPeriod = PayPeriod.Monthly;
    }
  }

  onOkClicked = () => {
    if (this.incomeCalculateForm) {
      if (this.incomeCalculateForm.form.valid) {
        this.activeModal.close(this.income);
      }
    }
  }

  onAmountChanged = (income: Income) => {
    income.monthlyIncome = LeadUtils.calculateMonthlyAmountByPayPeriod(
      income.amount,
      income.payPeriod as PayPeriod,
      income.hoursPerWeek,
    );
  }
}
