import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from "ngx-bootstrap/popover";
import { NgxCurrencyModule } from 'ngx-currency';
import { TableModule } from 'primeng/table';
import { CreditModule } from "src/app/modules/loan-services/credit/credit.module";
import { SharedModule } from "src/app/shared/shared.module";
import { QaAddButtonComponent } from './components/qa-add-button/qa-add-button.component';
import { QaBadgeComponent } from './components/qa-badge/qa-badge.component';
import { QaPageBodyComponent } from './components/qa-page-body/qa-page-body.component';
import { QuickApplyAboutFinanceComponent } from "./qa-borrowers-info/components/qa-borrower-declarations/components/qa-about-finance/qa-about-finance.component";
import { QuickApplyAboutPropertyComponent } from "./qa-borrowers-info/components/qa-borrower-declarations/components/qa-about-property/qa-about-property.component";
import { QuickApplyBorrowerDeclarationsComponent } from "./qa-borrowers-info/components/qa-borrower-declarations/qa-borrower-declarations.component";
import { QuickApplyBorrowerDemographicsComponent } from "./qa-borrowers-info/components/qa-borrower-demographics/qa-borrower-demographics.component";
import { QuickApplyBorrowerDetailsComponent } from "./qa-borrowers-info/components/qa-borrower-details/qa-borrower-details.component";
import { QuickApplyCounselingEventsComponent } from "./qa-borrowers-info/components/qa-counseling-events/qa-counseling-events.component";
import { QuickApplyBorrowersInfoComponent } from "./qa-borrowers-info/qa-borrowers-info.component";
import { QuickApplyFHAInfoComponent } from "./qa-fha-info/qa-fha-info.component";
import { QaFiAssetEditorComponent } from './qa-financial-info/qa-fi-assets/qa-fi-asset-editor/qa-fi-asset-editor.component';
import { QaFiAssetsComponent } from './qa-financial-info/qa-fi-assets/qa-fi-assets.component';
import { QaFiCreditEditorComponent } from './qa-financial-info/qa-fi-assets/qa-fi-credit-editor/qa-fi-credit-editor.component';
import { QaFiEmploymentEditorComponent } from './qa-financial-info/qa-fi-income/qa-fi-employment-editor/qa-fi-employment-editor.component';
import { QaFiIncomeComponent } from './qa-financial-info/qa-fi-income/qa-fi-income.component';
import { QaFiLiabilitiesComponent } from './qa-financial-info/qa-fi-liabilities/qa-fi-liabilities.component';
import { QaFiLiabilityEditorComponent } from './qa-financial-info/qa-fi-liabilities/qa-fi-liability-editor/qa-fi-liability-editor.component';
import { QaFiReoEditorV2Component } from './qa-financial-info/qa-fi-reo/qa-fi-reo-editor-v2/qa-fi-reo-editor-v2.component';
import { QaFiReoComponent } from './qa-financial-info/qa-fi-reo/qa-fi-reo.component';
import { QuickApplyFinancialInfoComponent } from "./qa-financial-info/qa-financial-info.component";
import { BuydownPopoverComponent } from './qa-loan-info/popovers/buydown-popover/buydown-popover.component';
import { DownpaymentSourcesPopoverComponent } from './qa-loan-info/popovers/downpayment-sources-popover/downpayment-sources-popover.component';
import { QuickApplyVAInfoComponent } from "./qa-va-info/qa-va-info.component";
import { QuickApplyRoutingModule } from "./quick-apply-routing.module";
import { QaFiIncomeEditorComponent } from './qa-financial-info/qa-fi-income/qa-fi-income-editor/qa-fi-income-editor.component';
import { QaButtonComponent } from './components/qa-button/qa-button.component';
import { QaDeleteButtonComponent } from './components/qa-delete-button/qa-delete-button.component';
import { QaMenuComponent } from './qa-menu/qa-menu.component';
import { QaMorePopoverComponent } from './components/qa-more-popover/qa-more-popover.component';
import { QaFiEmploymentAddressComponent } from './qa-financial-info/qa-fi-income/qa-fi-employment-editor/qa-fi-employment-address/qa-fi-employment-address.component';
import { QaCancelButtonComponent } from './components/qa-cancel-button/qa-cancel-button.component';
import { QaOkButtonComponent } from './components/qa-ok-button/qa-ok-button.component';
import { QaFiEmploymentDateComponent } from './qa-financial-info/qa-fi-income/qa-fi-employment-date/qa-fi-employment-date.component';
import { QaVaDisclosuresInfoComponent } from './qa-va-info/qa-va-disclosures-info/qa-va-disclosures-info.component';
import { QaVaIncomeDetailsComponent } from './qa-va-info/qa-va-income-details/qa-va-income-details.component';
import { QaVaIncomeSectionComponent } from './qa-va-info/qa-va-income-details/qa-va-income-section/qa-va-income-section.component';
import { QaWarningNoteComponent } from './components/qa-warning-note/qa-warning-note.component';
import { ResidualChartComponent } from './qa-va-info/qa-va-income-details/residual-chart/residual-chart.component';
import { ResidualChartModalComponent } from './qa-va-info/qa-va-income-details/residual-chart-modal/residual-chart-modal.component';
import { QaAddressEditorComponent } from './components/qa-address-editor/qa-address-editor.component';
import { QaAddressEditorModalComponent } from './components/qa-address-editor-modal/qa-address-editor-modal.component';
import { QaLoanInfoModule } from "./qa-loan-info/qa-loan-info.module";
import { LoanCreditModule } from "src/app/modules/loan-services/loan-credit/loan-credit.module";
import { IncomeCalculatorDialogComponent } from './qa-financial-info/qa-fi-income/qa-fi-employment-editor/income-calculator-dialog/income-calculator-dialog.component';
import { UrlaModule } from "src/app/modules/urla/urla.module";
import { IdentifyingDocumentsModule } from "../identifying-documents/identifying-documents.module";
import { EConsentHistoryPopoverModule } from "./qa-borrowers-info/components/popovers/e-consent-history-popover/e-consent-history-popover.module";
import { QaAddressHistoryComponent } from './qa-borrowers-info/components/qa-address-history/qa-address-history.component';

@NgModule({
  imports: [
    QuickApplyRoutingModule,
    CommonModule,
    SharedModule,
    TableModule,
    PopoverModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    CreditModule,
    LoanCreditModule,
    QaLoanInfoModule,
    UrlaModule,
    IdentifyingDocumentsModule,
    EConsentHistoryPopoverModule
  ],
  declarations: [
    QuickApplyBorrowersInfoComponent,
    QuickApplyBorrowerDetailsComponent,
    QuickApplyCounselingEventsComponent,
    QuickApplyBorrowerDemographicsComponent,
    QuickApplyBorrowerDeclarationsComponent,
    QuickApplyFinancialInfoComponent,
    QuickApplyAboutFinanceComponent,
    QuickApplyAboutPropertyComponent,
    QuickApplyFHAInfoComponent,
    QuickApplyVAInfoComponent,
    DownpaymentSourcesPopoverComponent,
    BuydownPopoverComponent,
    QaPageBodyComponent,
    QaFiAssetsComponent,
    QaFiAssetEditorComponent,
    QaFiReoComponent,
    QaFiLiabilitiesComponent,
    QaFiLiabilityEditorComponent,
    QaFiReoEditorV2Component,
    QaFiIncomeComponent,
    QaBadgeComponent,
    QaFiEmploymentEditorComponent,
    QaAddButtonComponent,
    QaFiCreditEditorComponent,
    QaFiIncomeEditorComponent,
    QaButtonComponent,
    QaDeleteButtonComponent,
    QaMenuComponent,
    QaMorePopoverComponent,
    QaFiEmploymentAddressComponent,
    QaCancelButtonComponent,
    QaOkButtonComponent,
    QaFiEmploymentDateComponent,
    QaVaDisclosuresInfoComponent,
    QaVaIncomeDetailsComponent,
    QaVaIncomeSectionComponent,
    ResidualChartComponent,
    ResidualChartModalComponent,
    QaWarningNoteComponent,
    QaAddressEditorComponent,
    QaAddressEditorModalComponent,
    IncomeCalculatorDialogComponent,
    QaAddressHistoryComponent,
  ],
  exports: [
    QuickApplyBorrowersInfoComponent,
    QuickApplyBorrowerDetailsComponent,
    QuickApplyCounselingEventsComponent,
    QuickApplyBorrowerDemographicsComponent,
    QuickApplyBorrowerDeclarationsComponent,
    QuickApplyFinancialInfoComponent,
    QuickApplyAboutFinanceComponent,
    QuickApplyAboutPropertyComponent,
    QuickApplyFHAInfoComponent,
    QuickApplyVAInfoComponent,
    QaMenuComponent,
    QaCancelButtonComponent,
    QaOkButtonComponent,
  ],
  providers: [

  ]
})
export class QuickApplyModule {
  constructor() {
  }
}
