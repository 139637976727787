<div [ngClass]="{'group-container-repeat': !mouseCursorOnDeleteButton,
  'group-container-repeat-pending-delete': mouseCursorOnDeleteButton}">
    <div class="grid-layout has-border" *ngIf="reo">
        <div class='grid-layout-row' style="flex-direction: row-reverse;">
            <a class="top-right-close link" (mouseover)="mouseCursorOnDeleteButton=true" *ngIf="!isReadOnly && !inEditMode"
                (mouseout)="mouseCursorOnDeleteButton=false" (click)="onDeleteClicked()">
                <i class="fa fa-times-circle"></i>
            </a>
        </div>
        <div class="grid-layout-row" *ngIf="error && !inEditMode">
            <urla-grid-cell class="w-100">
              <span class="invalid-feedback d-block">
                <span><i class="fa fa-exclamation-triangle me-2"></i> This REO has the same address as the current address of the borrower, but current address is set as a rental. </span>
              </span>
            </urla-grid-cell>
        </div>
        <div class="grid-layout-row">
            <urla-street-address #streetAddress class="col-md-3 col-xs-6"
              [label]="'Property Address'" [(ngModel)]="reo.address1" [name]="'propertyAddress' + reo.reoId"
              [readonly]="isReadOnly" [inEditMode]="inEditMode" (addressChanged)='onAddressChanged($event)' (change)="checkAddress()"
              [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.address1'"
              [required]="urlaFieldsConfig['mortgage.realEstateOwned.address1']?.required == 'required' ||
              urlaFieldsConfig['mortgage.realEstateOwned.address1']?.required == 'requested'">
            </urla-street-address>
            <urla-text-input class="col-md-1 col-xs-6" [name]="'address2' + reo.reoId" [readonly]="isReadOnly"
              [label]="'Unit #'" [(ngModel)]="reo.address2" (change)="checkAddress()"
              [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.address2'"
              [required]="urlaFieldsConfig['mortgage.realEstateOwned.address2']?.required == 'required' ||
              urlaFieldsConfig['mortgage.realEstateOwned.address2']?.required == 'requested'">
            </urla-text-input>
            <urla-text-input class="col-md-2 col-xs-6" [name]="'city' + reo.reoId" [readonly]="isReadOnly"
              [label]="'City'" [(ngModel)]="reo.city" (change)="checkAddress()"
              [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.city'"
              [required]="urlaFieldsConfig['mortgage.realEstateOwned.city']?.required == 'required' ||
              urlaFieldsConfig['mortgage.realEstateOwned.city']?.required == 'requested'">
            </urla-text-input>
            <urla-dropdown class="col-md-1 col-xs-4" [name]="'state' + reo.reoId" [label]="'State'" [options]="states"
                [(ngModel)]="reo.state" [readonly]="isReadOnly" (change)="checkAddress()"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.state'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.state']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.state']?.required == 'requested'">
            </urla-dropdown>
            <urla-digits-input class="col-md-2 col-xs-4" label="Zip Code" [(ngModel)]="reo.zipCode"
                [name]="'zipCode' + reo.reoId" [readonly]="isReadOnly" (blur)="onZipcodeBlurred()"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.zipCode'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.zipCode']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.zipCode']?.required == 'requested'">
            </urla-digits-input>
            <borrower-picker #borrowerPicker class="col-md-3 col-xs-4" [label]="'Borrowers'"
                [possibleChoices]="borrowers" [isReadOnly]="isReadOnly"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.borrowerPicker'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.borrowerPicker']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.borrowerPicker']?.required == 'requested'">
            </borrower-picker>
        </div>
        <div class="grid-layout-row">
            <urla-dropdown class="col-md-3 col-xs-3" [name]="'typeOfProperty' + reo.reoId" [label]="'Property Type'"
                [options]="reoPropertyTypes" [(ngModel)]="reo.typeOfProperty" [readonly]="isReadOnly"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.typeOfProperty'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.typeOfProperty']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.typeOfProperty']?.required == 'requested'">
            </urla-dropdown>
            <urla-dropdown class="col-md-3 col-xs-3" [name]="'currentUsageType' + reo.reoId" [label]="'Curr Occupancy'"
                [options]="currentPropertyWillBeType" [readonly]="isReadOnly" [(ngModel)]="reo.currentUsageType"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.currentUsageType'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.currentUsageType']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.currentUsageType']?.required == 'requested'">
            </urla-dropdown>
            <urla-dropdown class="col-md-3 col-xs-3" [name]="'dispositionStatus' + reo.reoId" [label]="'Status'"
                [options]="propertyStatusOptions" [readonly]="isReadOnly" [(ngModel)]="reo.dispositionStatus"
                (change)="onDispositionStatusChanged()"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.dispositionStatus'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.dispositionStatus']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.dispositionStatus']?.required == 'requested'">
            </urla-dropdown>
            <urla-dropdown class="col-md-3 col-xs-3" 
                [readonly]="isReadOnly" 
                [name]="'intendedUsageType' + reo.reoId" 
                [label]="'Proposed Occupancy'"
                [options]="intendedPropertyWillBeType" [(ngModel)]="reo.intendedUsageType" *ngIf="this.reo.dispositionStatus != 'Sold'"
                (change)="onProposedOccupancyChanged()"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.intendedUsageType'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.intendedUsageType']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.intendedUsageType']?.required == 'requested'">
            </urla-dropdown>
        </div>
        <div class="grid-layout-row">
            <subject-property-input #subjectProperty class="col-md-4 col-xs-4" label="Subject Property"
                [(ngModel)]="reo.isSubjectProperty" (change)="onSubjectPropertyStatusChanged(reo)"
                (importLoanInfoClicked)="onImportLoanInfoClicked()"
                (pushToLoanInfoClicked)="onPushToLoanInfoClicked()"
                [readonly]="isReadOnly" [options]="yesNoOptions" [name]="'subjectProperty' + reo.reoId"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.isSubjectProperty'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.isSubjectProperty']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.isSubjectProperty']?.required == 'requested'">
            </subject-property-input>
            <urla-text-input class="col-md-3 col-xs-3" [name]="'assetAccountId' + reo.reoId" [readonly]="isReadOnly"
              [label]="'Asset Account ID'" [(ngModel)]="reo.assetAccountId"
              [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.assetAccountId'"
              [required]="urlaFieldsConfig['mortgage.realEstateOwned.assetAccountId']?.required == 'required' ||
              urlaFieldsConfig['mortgage.realEstateOwned.assetAccountId']?.required == 'requested'">
          </urla-text-input>
            <urla-currency-input class="col-md-3 col-xs-3" [name]="'marketValue' + reo.reoId" [label]="'Appraised Value'"
                [(ngModel)]="reo.marketValue" [readonly]="isReadOnly"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.marketValue'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.marketValue']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.marketValue']?.required == 'requested'">
            </urla-currency-input>
            <urla-digits-input *ngIf="!inEditMode" class="col-md-2 col-xs-2" label="# OF MTGS" [name]="'totalCountOfMtgs' + reo.reoId"
                [(ngModel)]="reoLiabilities.length" [disabled]="true" [readonly]="isReadOnly">
            </urla-digits-input>
        </div>
        <div class="grid-layout-row">
            <urla-currency-input
                *ngIf="reoLiabilities.length >= 0 && !inEditMode"
                class="col-md-2 col-xs-2" [name]="'mortgagePayment' + reo.reoId"
                [label]="'MO PMT P&I'"
                [readonly]="true"
                [(ngModel)]="reo.mortgagePayment"
                (change)="calculateNetRentalIncome()">
            </urla-currency-input>
            <urla-currency-input class="col-md-2 col-xs-2" [name]="'monthlyMiscExpenses' + reo.reoId" [label]="'MO INS, TAX, HOA'"
                [hasCalculator]="true"
                [isEditableEvenIfItHasACalculator]="true"
                (calculatorClicked)="onReoExpenseBreakdownCalculatorClicked()"
                [(ngModel)]="reo.monthlyMiscExpenses" [readonly]="isReadOnly"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                (change)="onReoMiscExpensesChanged()"
                [fieldBeingEdited]="'mortgage.realEstateOwned.monthlyMiscExpenses'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses']?.required == 'requested'">
            </urla-currency-input>
            <urla-percentage-input *ngIf="isInvestmentOrTwoToFourUnitProperty || inEditMode"
                class="col-md-2 col-xs-2" label="% OWNED" [(ngModel)]="reo.percentOwned" [readonly]="isReadOnly"
                [name]="'percentOwned' + reo.reoId" [inEditMode]="inEditMode"
                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.percentOwned'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.percentOwned']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.percentOwned']?.required == 'requested'">
            </urla-percentage-input>
            <urla-percentage-input *ngIf="isInvestmentOrTwoToFourUnitProperty || inEditMode" class="col-md-2 col-xs-2"
                label="VAC FACTOR" [(ngModel)]="reo.vacancyFactor" [readonly]="isReadOnly" [name]="'vacancyFactor' + reo.reoId"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" (change)="calculateNetRentalIncome()"
                [fieldBeingEdited]="'mortgage.realEstateOwned.vacancyFactor'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.vacancyFactor']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.vacancyFactor']?.required == 'requested'">
            </urla-percentage-input>
            <urla-currency-input *ngIf="isInvestmentOrTwoToFourUnitProperty || inEditMode" class="col-md-2 col-xs-2"
                [name]="'grossRentalIncome' + reo.reoId" [readonly]="isReadOnly" [label]="'GROSS RENT'" [(ngModel)]="reo.grossRentalIncome"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" (change)="calculateNetRentalIncome()"
                [isEditableEvenIfItHasACalculator]="true"
                [fieldBeingEdited]="'mortgage.realEstateOwned.grossRentalIncome'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.grossRentalIncome']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.grossRentalIncome']?.required == 'requested'"
                [hasCalculator]="true" (calculatorClicked)="onGrossRentCalculatorClicked()">
            </urla-currency-input>
            <urla-currency-input *ngIf="isInvestmentOrTwoToFourUnitProperty || inEditMode" class="col-md-2 col-xs-2" [readonly]="true"
                [name]="'netRentalIncome' + reo.reoId" [label]="'NET RENTAL INCOME'" [(ngModel)]="reo.netRentalIncome"
                [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.realEstateOwned.netRentalIncome'"
                [required]="urlaFieldsConfig['mortgage.realEstateOwned.netRentalIncome']?.required == 'required' ||
                urlaFieldsConfig['mortgage.realEstateOwned.netRentalIncome']?.required == 'requested'">
            </urla-currency-input>
        </div>
    </div>
</div>
