import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { RelatedMortgage } from '../../../../../models';
import { AmortizationType } from '../../../../reports/mortgage-call/models/mcr-audit-data.model';
import { LoanPurposeTypeEnum, MortgageAppliedForTypeEnum } from '../../../../app-details/components/title-history/models/title-order.model';
import { cloneDeep } from 'lodash';
import { EnumerationService } from '../../../../../services/enumeration-service';
import { EnumerationItem } from '../../../../../models/simple-enum-item.model';
import { Subject, Subscription } from 'rxjs';
import { Constants } from '../../../../../services/constants';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'related-mortgage-loan-editor',
  templateUrl: './related-mortgage-loan-editor.component.html',
  styleUrls: ['./related-mortgage-loan-editor.component.scss'],
})
export class RelatedMortgageLoanEditor implements OnInit, OnChanges, OnDestroy {
  /**
   * Can be omitted if a new {@link RelatedMortgage} is being created.
   */
  @Input() value?: Partial<RelatedMortgage>;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<RelatedMortgage> =
    new EventEmitter<RelatedMortgage>();

  protected formValue: Partial<RelatedMortgage> = createInitialFormValue();

  protected yesNoOptions: readonly EnumerationItem<string>[] = [];
  protected lienTypeOptions: readonly EnumerationItem<string>[] = [];
  protected fundsSourceOptions: readonly EnumerationItem<string>[] = [];
  protected amortizationTypeOptions
    : readonly EnumerationItem<AmortizationType>[] = [];
  protected mortgageTypeOptions
    : readonly EnumerationItem<MortgageAppliedForTypeEnum>[] = [];
  protected loanPurposeOptions
    : readonly EnumerationItem<LoanPurposeTypeEnum>[] = [];

  private _initEnumerationsSubscription: Subscription | null = null;

  private _destroyed$ = new Subject<void>();

  constructor(
    private readonly _enumerationService: EnumerationService,
  ) {
  }

  ngOnInit() {
    this.initEnumerations();
  }

  ngOnChanges(changes: SimpleChanges) {
    const valueChange = changes.value;
    if (valueChange != null) {
      const currentValue = valueChange.currentValue;
      this.formValue = currentValue
        ? cloneDeep(currentValue)
        : createInitialFormValue();
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private initEnumerations(): void {
    this._initEnumerationsSubscription?.unsubscribe();

    this.yesNoOptions = this._enumerationService.getYesNoEnumItems();
    this._initEnumerationsSubscription = this._enumerationService
      .getMortgageEnumerations().pipe(
        takeUntil(this._destroyed$),
      )
      .subscribe(enumerations => {
        this.lienTypeOptions = enumerations[Constants.enumerations.lienPositionType];
        this.fundsSourceOptions = enumerations[Constants.enumerations.giftGrantSource];
        this.amortizationTypeOptions = enumerations[Constants.mortgageEnumerations.amortizationType];
        this.mortgageTypeOptions = enumerations[Constants.mortgageEnumerations.mortgageAppliedForType];
        this.loanPurposeOptions = enumerations[Constants.mortgageEnumerations.loanPurpose];
      });
  }

  protected onSubmit(): void {
    this.save.next(this.formValue as RelatedMortgage);
  }

  protected onCancel(): void {
    this.cancel.next();
  }
}

function createInitialFormValue(): Partial<RelatedMortgage> {
  return {
    creditorName: '',
    lienType: null,
    fundsSource: null,
    monthlyPayment: 0,
    loanOrDrawAmount: 0,
    creditLimit: 0,
    isHeloc: false,

    // These properties are from TFS-15267
    willBeRefinancedIntoSubjectLoan: false,
    willBeModifiedIntoSubjectLoan: false,
    lenderLoanIdentifier: '',
    agencyCaseIdentifier: '',
    investorLoanIdentifier: '',
    amortizationType: null,
    mortgageType: null,
    loanTermMonths: 0,
    remainingTermToMaturityMonths: 0,
    unpaidBalance: 0,
    noteRate: 0,
    ltv: 0,
    feeSummaryTotalOfAllPayments: 0,
    loanPurpose: null,
    miPremiumMonthlyPayment: 0,
    vaResidualIncome: 0,
    assignmentDate: '',
    assignedToName: '',
    isAssumable: false,
    balloonPaymentAmount: 0,
    borrowerName: '',
    lenderName: '',
    isLienHolderSameAsSubjectLoan: false,
    noteDate: '',
    mortgageDate: '',
    hasPrepaymentPenalty: false,
    titleHeldByName: '',
    trusteeName: '',
    priorRecordingBookNumber: '',
    priorRecordingInstrumentNumber: '',
    priorRecordingPageNumber: '',
    priorRecordingDateTime: '',
    priorRecordingJurisdictionName: '',
    isDeedOfTrust: false,
    isAffordableLoan: null,
    isPrincipalAndInterestDeferredFirstFiveYears: false,
  };
}
