<div class="modal-header">
  <h5 class="modal-title">Enum Mappings for {{isMappingFromLoanPassToLoda ? 'Lodasoft' : 'LoanPass'}} Field - {{isMappingFromLoanPassToLoda ? lodaField?.name : loanPassField?.name}}</h5>
  <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
</div>
<div class="modal-body" style="min-height: 500px !important;">
  <div class="row g-3">
    <div class="col-md-12">
      <alert #alert></alert>
    </div>
  </div>
  <div class="row g-3 d-flex align-items-center">
    <div class="col-md-4">
      <label for="lodaEnumConfigurations" class="form-label">Mapping Values from {{isMappingFromLoanPassToLoda ?
        'LoanPass' : 'Lodasoft'}} Enumeration: </label>
    </div>
    <div class="col-md-4">
      <p-dropdown id="lodaEnumConfigurations" *ngIf="isMappingFromLoanPassToLoda" name="lodaEnumConfigurations"
        styleClass="w-100" [options]="loanPassEnumerations" [(ngModel)]="fieldMapping.loanPassEnumTypeId" [showClear]="true"
        [filter]="true" filterBy="name" (ngModelChange)="onLoanPassEnumSelected()" optionLabel="name" optionValue="id"
        placeholder="Choose one..."></p-dropdown>

      <p-dropdown id="lodaEnumConfigurations" *ngIf="!isMappingFromLoanPassToLoda" name="lodaEnumConfigurations"
        styleClass="w-100" [options]="lodaEnumPricingFields" [(ngModel)]="fieldMapping.lodasoftEnumFullName"
        [showClear]="true" [filter]="true" filterBy="name" (ngModelChange)="onLodaEnumSelected()"
        optionLabel="name" optionValue="fullName" placeholder="Choose one..."></p-dropdown>
    </div>
  </div>
  <hr>
  <div class="row g-3" *ngIf="fieldMapping">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-3 mb-3"> Destination {{isMappingFromLoanPassToLoda ? 'Lodasoft' : 'LoanPass'}} Value </div>
        <div class="col-md-3 mb-3"> Source {{isMappingFromLoanPassToLoda ? 'LoanPass' : 'Lodasoft'}} Value </div>
        <div class="col-md-3 mb-3"> Expression </div>
        <div class="col-md-3 mb-3 text-center"> Use as Default </div>
      </div>
      <div class="row g-3">
        <ng-container class="mb-1" *ngFor="let mapping of fieldMapping.enumMappings; let i = index">
          <label for="sourceValue_{{mapping.destinationVariantName}}"
            class="col-sm-3 col-form-label">{{mapping.destinationVariantName}}</label>
          <div class="col-md-3" *ngIf="!isDestinationEnumSelected">
            <input id="sourceValue_{{mapping.destinationVariantName}}"
              name="sourceValue_{{mapping.destinationVariantName}}" type="text" class="form-control"
              placeholder="Source Value" [(ngModel)]="mapping.sourceValue">
          </div>
          <div class="col-md-3" *ngIf="isDestinationEnumSelected">
            <select id="lodaEnumValues_{{i}}" name="lodaEnumValues_{{i}}" class="form-select"
              aria-placeholder="Choose one..." [(ngModel)]="mapping.sourceValue">
              <option selected value="">Choose...</option>
              <ng-container *ngIf="!isMappingFromLoanPassToLoda">
                <option *ngFor="let enumItem of lodaEnumerationThatIsBeingMapped?.enumValues" value="{{enumItem.ordinal}}">
                  {{enumItem.description}}
                </option>
              </ng-container>
              <ng-container *ngIf="isMappingFromLoanPassToLoda">
                <option *ngFor="let variant of loanPassEnumerationThatIsBeingMapped?.variants" value="{{variant.id}}">
                  {{variant.name}}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="col-md-3">
            <input id="expression_{{mapping.destinationValue}}" name="expression_{{mapping.destinationValue}}"
              type="text" class="form-control" id="expression" [readonly]="true" [(ngModel)]="mapping.customExpression"
              (click)="onEditCustomExpressionClicked(mapping)" placeholder="Custom Expression">
          </div>
          <div class="col-md-3">
            <div class="form-check d-flex justify-content-center">
              <input class="form-check-input" type="radio" name="useAsDefault" id="useAsDefault_{{i}}"
                [value]="mapping.loanPassEnumMappingId" [(ngModel)]="idOfEnumSelectedAsDefault">
              <label class="form-check-label" for="useAsDefault_{{i}}"></label>
            </div>
          </div>
        </ng-container>
        <div class="col-md-12" *ngIf="fieldMapping.enumMappings.length">
          <button type="button" class="btn btn-danger float-end" (click)="onClickClearDefault()">
            <i class="fa fa-undo me-1"></i>Clear Default</button>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onOkClicked()" [disabled]="!fieldMapping.enumMappings.length">Ok</button>
</div>
