<form class="form-horizontal" id="credentialForm" name="credentialForm" #credentialForm="ngForm"
  (ngSubmit)="saveCredential()" novalidate>
  <div class="modal-header">
    <h5 class="modal-title">Credential</h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <credential-modal-basic [credential]="credential"
          [supportsChannelConfig]="credential.vendorName == 'StatesTitle'">
        </credential-modal-basic>
      </div>
      <div class="col-md-6 mt-1">
        <label class="custom-control-label">Client ID</label>
        <input type="text" class="form-control" name="userName" [(ngModel)]="credential.userName">
      </div>
      <div class="col-md-6 mt-1">
        <label class="custom-control-label">Client Secret</label>
        <input type="text" class="form-control" name="password" [(ngModel)]="credential.password">
      </div>
      <div class="col-md-12 mt-1" *ngIf="credential.vendorName == 'StatesTitle'">
        <label class="custom-control-label">Contact Roles</label>
        <ng-select width="100%" name="roles" bindLabel="roleName" bindValue="roleId" class="custom-ng-select"
          placeholder="Select one or more Roles" [closeOnSelect]="false" [items]="roles" [(ngModel)]="selectedRoles"
          multiple="true" appendTo="body">
        </ng-select>
      </div>

      <ng-container *ngIf="credential.vendorName == 'SoftPro'">
        <div class="col-md-12 mt-1">
          <label class="custom-control-label">Url</label>
          <input name="url" type="text" class="form-control" [(ngModel)]="credential.url">
        </div>

        <div class="col-md-12 mt-1">
          <label class="custom-control-label">Auth Url</label>
          <input name="authUrl" type="text" class="form-control" [(ngModel)]="authUrl">
        </div>

        <div class="col-md-6 mt-1">
          <label class="custom-control-label">Routing Number</label>
          <input name="routingNumber" type="text" class="form-control" [(ngModel)]="routingNumber">
        </div>

        <div class="col-md-6 mt-1">
          <label class="custom-control-label">Api Version</label>
          <input name="apiVersion" type="text" class="form-control" [(ngModel)]="apiVersion">
        </div>

        <div class="col-md-6 mt-1">
          <label class="custom-control-label">Branch Alta Id</label>
          <input name="branchAltaId" type="text" class="form-control" [(ngModel)]="branchAltaId">
        </div>

        <div class="col-md-6 mt-1">
          <label class="custom-control-label">Provider Reference Name</label>
          <input name="providerReferenceName" type="text" class="form-control" [(ngModel)]="providerReferenceName">
        </div>

        <div class="col-md-6 mt-1">
          <label class="custom-control-label">Lender Linked Id</label>
          <input name="lenderLinkedId" type="text" class="form-control" [(ngModel)]="lenderLinkedId">
        </div>

        <!-- Custom Events -->
        <section
          class='col-12 mt-4'
          *ngIf="credential?.vendorName === 'SoftPro'"
        >
          <div class='custom-events-header row align-items-center'>
            <div class='col'>
              <h5>Custom Events</h5>
            </div>

            <div class='col-auto d-flex'>
              <button
                type='button'
                class='btn btn-link'
                (click)='addCustomEvent()'
              >
                <span><i class='fa fa-plus'></i></span>
                Add Custom Event
              </button>
            </div>
          </div>

          <div
            *ngFor='let customEvent of customEvents; let index = index'
            class='row align-items-stretch mt-2'
          >
            <div class='col-12 col-md-6'>
              <label class='d-flex flex-column'>
                <span class='custom-control-label pt-0'>Name</span>
                <input
                  name='customEvent.{{ index }}.name'
                  [ngClass]="{'is-invalid' : customEventName && customEventName.touched && customEventName.invalid}"
                  type='text'
                  #customEventName="ngModel"
                  class='form-control'
                  [(ngModel)]='customEvent.name'
                  [maxlength]="80"
                />
                <div class="invalid-feedback">Must be maximum 80 characters long.</div>
              </label>
            </div>

            <div class='col-12 col-md'>
              <label>
                <span class='custom-control-label pt-0'>Request Document</span>
                <input
                  [(ngModel)]='customEvent.requestDocument'
                  class='form-check-input d-block mt-2'
                  name='customEvent.{{ index }}.requestDocument'
                  type='checkbox'
                />
              </label>
            </div>

            <div class='col-auto d-flex align-items-center'>
              <button
                (click)='removeCustomEvent(index)'
                class='btn btn-lg btn-link text-danger'
                title='Remove Custom Event'
                type='button'
              >
                <span><i class='fa fa-trash-alt'></i></span>
              </button>
            </div>
          </div>
        </section>
      </ng-container>
    </div>
    <div *ngIf="showLenderLoadingError && credential.vendorName == 'StatesTitle'"
      class="alert alert-danger border-0 mt-3" role="alert">
      <i class="fa fa-exclamation-circle me-2"></i>There was an error loading Lenders
    </div>

    <div *ngIf="!showLenderLoadingError && credential.vendorName == 'StatesTitle'" class="row">
      <div class="col-md-12">
        <div class="fieldset-title mt-4">
          <div class="row">
            <div class="col-md-6 pt-2">Lenders</div>
            <div class="col-md-6 pt-2 text-end">
              <a href="javascript: void(0);" (click)="addLender()">
                <i class="fa fa-plus"></i>
                Add Lender
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row mt-1" *ngFor="let lender of selectedLenders; let index = index">
          <div class="col-md-6">
            <label class="custom-control-label">Lodasoft Lender</label>
            <select class="form-select" name="key_{{index}}" [(ngModel)]="lender.lodasoftLenderId">
              <option value="">-- Select One --</option>
              <option *ngFor="let item of lodasoftLenders" value={{item.lenderId}}>
                {{item.lenderName}}
              </option>
            </select>
          </div>
          <div class="col-md-5">
            <label class="custom-control-label">Doma Lender</label>
            <select class="form-select" name="value_{{index}}" [(ngModel)]="lender.thirdPartyLenderId">
              <option value="">-- Select One --</option>
              <option *ngFor="let item of titleLenders" value={{item.lenderId}}>
                {{item.lenderName}}
              </option>
            </select>
          </div>
          <div class="col-md-1 pt-4 mt-1">
            <confirm-delete [list]="selectedLenders" [index]="index"></confirm-delete>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer form-footer">
    <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
      Cancel
    </button>
    <button type="submit" class="btn btn-primary right" [disabled]="isSaving">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm"></span> Saving
      </span>
    </button>
  </div>
</form>
