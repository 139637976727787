<div *ngIf="!customFields?.length else cfgrid" class="row pt-3 text-center">
  <em>There are no custom fields added. Please click add button to add one.</em>
</div>
<ng-template #cfgrid>
  <p-table #customFieldsTable [value]="customFields" [paginator]="false" responsiveLayout="scroll" [autoLayout]="true"
    [reorderableColumns]="true">
    <ng-template pTemplate="header">
      <tr>
        <th style="width:3rem"></th>
        <th class="col-3"> Name</th>
        <th class="col-3"> Label </th>
        <th class="col-2"> Default </th>
        <th class="col-3"> Type </th>
        <th class="col-1">Required </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td>
          <span class="pi pi-bars" pReorderableRowHandle></span>
        </td>
        <td class="col-3">
          <input type="text" class="form-control" name="custom-field-name-{{item.tempUniquId}}" [(ngModel)]="item.name"
            #customFieldNameField="ngModel"
            [ngClass]="{'is-invalid': customFieldNameField && customFieldNameField.touched && customFieldNameField.invalid}"
            required />
        </td>
        <td class="col-3">
          <input type="text" class="form-control" name="custom-field-display-name-{{item.tempUniquId}}"
            [(ngModel)]="item.displayName" #customFieldDisplayNameField="ngModel"
            [ngClass]="{'is-invalid': customFieldDisplayNameField && customFieldDisplayNameField.touched && customFieldDisplayNameField.invalid}"
            required />
        </td>
        <td class="col-2">
          <input type="text" class="form-control" name="custom-field-default-value-{{item.tempUniquId}}"
            [(ngModel)]="item.defaultValue" />
        </td>
        <td class="col-3">
          <select class="form-select type-ddl" [(ngModel)]="item.type" name="custom-field-type-{{item.tempUniquId}}">
            <option value="List" selected> List</option>
            <option value="Checkbox">Checkbox </option>
            <option value="Text"> Text</option>
            <option value="Number">Number </option>
          </select>
          <span *ngIf="item.type === 'List'" class="text-primary" (click)="openListOptionsModal(item)" role="button">
            {{'Option(' + (item.options?.length || 0) + ')'}}
          </span>
        </td>
        <td>
          <div class="col-1 d-flex justify-content-left align-items-center w-100">
            <div class="form-check me-2 mt-1">
              <input type="checkbox" class="form-check-input me-1" name="custom-field-is-required-{{item.tempUniquId}}"
                [(ngModel)]="item.isRequired">
            </div>
            <confirm-delete [list]="customFields" [index]="index"></confirm-delete>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>