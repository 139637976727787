import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { concatMap, firstValueFrom, from, Observable, startWith, Subscription, switchMap, take } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/core/route-guards/pending-changes.guard';
import { FormValue, showNotSavedDialog } from '../quick-apply-utils';
import { UrlaMortgage } from '../../../../urla/models/urla-mortgage.model';
import { LoanApplication, MortgageBorrower } from '../../../../../models';
import { DiffChecker } from '../../../../../../utils/diff-checker';
import { AppDetailsService } from '../../../services/app-details.service';
import { NotificationService } from '../../../../../services/notification.service';
import { cloneDeep } from 'lodash';
import { applyVaInfoForm, createVaInfoForm, createVaInfoFormAdapter, VaInfoForm, VaInfoFormAdapter } from './qa-va-info.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationContextService } from '../../../../../services/application-context.service';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'qa-va-info',
  templateUrl: './qa-va-info.component.html',
  styleUrls: ['./qa-va-info.component.scss'],
})
export class QuickApplyVAInfoComponent implements OnInit, AfterViewInit,
  ComponentCanDeactivate {

  @ViewChild('tabContent')
  protected tabContent: ElementRef<HTMLDivElement>;

  @ViewChild('incomeDetailsTabTemplate')
  protected incomeDetailsTabTemplate: TemplateRef<any>;
  @ViewChild('disclosuresInfoTabTemplate')
  protected disclosuresInfoTabTemplate: TemplateRef<any>;

  protected formGroup: FormGroup<VaInfoForm>;
  protected formAdapter: VaInfoFormAdapter;

  private _pristineFormValue: FormValue<VaInfoForm> | null = null;

  protected isSaving: boolean = false;

  private _application: LoanApplication;

  protected get mortgage(): UrlaMortgage {

    return this._application?.mortgageLoan;
  }

  protected get isInitialized(): boolean {
    return this.mortgage != null;
  }

  protected get borrowers(): readonly MortgageBorrower[] | undefined {
    return this.mortgage.borrowers;
  }

  protected tabs: readonly Tab[];
  protected activeTab: Tab;

  protected applyFormChangesToMortgage: (mortgage: UrlaMortgage) => void;

  private _applicationContextSubscription: Subscription | null = null;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _notificationService: NotificationService,
  ) {
  }

  async ngOnInit() {
    this.subscribeToApplicationContext();
  }

  ngAfterViewInit() {
    this.tabs = [
      {
        title: 'Income Details',
        route: 'income-details',
        template: this.incomeDetailsTabTemplate,
      },
      {
        title: 'Disclosures Info',
        route: 'disclosures-info',
        template: this.disclosuresInfoTabTemplate,
      },
    ];
    this.activeTab = this.tabs[0];
  }

  ngOnDestroy() {
    this._applicationContextSubscription?.unsubscribe();
  }

  private isDirty(logDiff: boolean = false): boolean {
    const current = this.formGroup?.value;
    const previous = this._pristineFormValue;
    if (current == null || previous == null) return false;

    const diffCheckers = [
      new DiffChecker(
        previous,
        current,
        '',
      ),
    ];

    return diffCheckers
      .map((e) => e.calculateDiff(logDiff))
      .some(Boolean);
  }

  canDeactivate = (): boolean | Observable<boolean> => {
    if (!this.isInitialized) {
      return true;
    }

    return !this.isDirty(true);
  };

  confirm(): boolean | Observable<boolean> {
    return from(showNotSavedDialog(() => this.save()));
  }

  private subscribeToApplicationContext(): void {
    this._applicationContextSubscription?.unsubscribe();

    this._applicationContextService.context.pipe(
      concatMap((context) => this._applicationContextService.changes.pipe(
        startWith(context),
      )),
      tap((context) => {
        const application = this._application = context?.application;
        const mortgage = application?.mortgageLoan ?? new UrlaMortgage();

        this.formGroup = createVaInfoForm(this._formBuilder)(mortgage);
        this.formAdapter = createVaInfoFormAdapter(this.formGroup);
        this.applyFormChangesToMortgage = applyVaInfoForm(this.formGroup);
      }),
      // Save the pristine form value when the first form value is emitted.
      switchMap(() =>
        this.formGroup.valueChanges.pipe(
          // It emits many times when the form is initialized due to the
          // default values of the form controls (custom inputs like
          // currency-input).
          // We only want to get the latest value, when it no longer changes
          // for a while.
          debounceTime(100),
          take(1),
          tap((value) => {
            this._pristineFormValue = value as FormValue<VaInfoForm>;
          }),
        )),
    ).subscribe({
      complete: () => {
        this._applicationContextSubscription = null;
      },
    });
  }

  protected async save(): Promise<boolean> {
    if (this.isSaving) return false;
    this.isSaving = true;

    this.formGroup.markAllAsTouched();
    this.formGroup.disable();

    const application = this._application;
    const fallbackMortgage = this.mortgage;

    const mortgage = cloneDeep(this.mortgage);
    this.applyFormChangesToMortgage(mortgage);
    application.mortgageLoan = mortgage;

    const loanInfo = {
      application: application,
      customData: null,
    };

    try {
      const loanInfoResult = await firstValueFrom(
        this._appDetailsService.saveLoanInfo(
          application.applicationId,
          loanInfo,
        ),
      );
      const applicationResult = loanInfoResult.application;

      this._applicationContextService.updateMortgageAndApplication(
        applicationResult.mortgageLoan,
        applicationResult,
      );

      this._notificationService.showSuccess(
        'The loan info has been saved successfully.',
        'Success',
      );

      return true;
    } catch (e) {
      application.mortgageLoan = fallbackMortgage;

      console.error(e);
      const message = e?.message
        || 'An error occurred while saving the loan info.';

      this._notificationService.showError(
        message,
        'Error',
      );

      return false;
    } finally {
      this.isSaving = false;
      this.formGroup.enable();
    }
  }
}

interface Tab {
  title: string;
  route: string;
  template: TemplateRef<any>;
}
