import {CDK_DRAG_CONFIG, DragDropModule} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {FileUploadModule} from '@iplab/ngx-file-upload';
import {NgbCarouselModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxToggleModule} from '@nikiphoros/ngx-toggle';
import {MentionModule} from 'angular-mentions';
import {NgApexchartsModule} from 'ng-apexcharts';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {NgSelect2Module} from 'ng-select2';
import {NgSelectModule} from '@ng-select/ng-select';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TagInputModule} from 'ngx-chips';
import {ClipboardModule} from 'ngx-clipboard';
import {ContextMenuModule} from 'ngx-contextmenu';
import {NgxCurrencyModule} from 'ngx-currency';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {ImageCropperModule} from 'ngx-image-cropper';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {SortablejsModule} from 'ngx-sortablejs';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {InputNumberModule} from 'primeng/inputnumber';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';
import {StepsModule} from 'primeng/steps';
import {AdminPageTemplateComponent} from '../layout/admin/admin-page-template/admin-page-template.component';
import {
  CallControlPanelActionBarComponent,
} from '../modules/dialer/components/call-control-panel-action-bar/call-control-panel-action-bar.component';
import {MenuItemComponent} from '../modules/tpo/components/menu-item/menu-item.component';
import {DragDropModule as PDragDropModule} from 'primeng/dragdrop';
import {DndModule} from 'ngx-drag-drop';

import {
  AppUrlComponent,
  BreadCrumbComponent,
  CheckboxFlagsComponent,
  CurrencyInputComponent,
  DateInputComponent,
  FileImportDialogComponent,
  GenericInputDropdownComponent,
  GridTopActionBarComponent,
  ImageUploadComponent,
  IncomingActionsComponent,
  ListSorterComponent,
  LoadingIndicatorComponent,
  MultiCheckboxPickerComponent,
  NumberInputComponent,
  PercentInputComponent,
  RearrangeOrderComponent,
  ReleaseNoteDialogComponent,
  SecurityCodeDialog,
  SelectInputComponent,
  TextAreaInputComponent,
  TextInputComponent,
} from './components';
import {
  AddToDialListConfirmationDialogComponent,
} from './components/add-to-dial-list/add-to-dial-list-confirmation-dialog/add-to-dial-list-confirmation-dialog.component';
import {AddToDialListComponent} from './components/add-to-dial-list/add-to-dial-list.component';
import {
  DeleteListConfirmationDialogComponent,
} from './components/add-to-dial-list/delete-list-confirmation-dialog/delete-list-confirmation-dialog.component';
import {
  MoveToOtherListConfirmationDialogComponent,
} from './components/add-to-dial-list/move-to-other-list-confirmation-dialog/move-to-other-list-confirmation-dialog.component';
import {NewDialListDialogComponent} from './components/add-to-dial-list/new-dial-list-dialog/new-dial-list-dialog.component';
import {AppointmentsComponent} from './components/appointments/appointments.component';
import {CommonSendEmailSmsDialogComponent} from './components/common-send-email-sms-dialog/send-email-sms-dialog.component';
import {CommonSendEmailSmsComponent} from './components/common-send-email-sms/send-email-sms.component';
import {CommonSendEmailComponent} from './components/common-send-email/send-email.component';
import {CommonSendSmsComponent} from './components/common-send-sms/send-sms.component';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {ContactInfoComponent} from './components/contact-info/contact-info.component';
import {DateRangeFilterComponent} from './components/date-range-filter/date-range-filter.component';
import {DrawerComponent} from './components/drawer/drawer.component';
import {DrawingPadDialogComponent} from './components/drawing-pad-dialog/drawing-pad-dialog.component';
import {DuplicateDialogComponent} from './components/duplicate-dialog/duplicate-dialog.component';
import {ExpressionInputComponent} from './components/expression-input/expression-input.component';
import {GenericDateRangeFilterComponent} from './components/generic-date-range-filter/generic-date-range-filter.component';
import {GenericFilterComponent} from './components/generic-filter/generic-filter.component';
import {InfiniteScrollComponent} from './components/infinite-scroll/infinite-scroll.component';
import {LogoComponent} from './components/logo/logo.component';
import {MentionItemComponent} from './components/mentions/mention-item/mention-item.component';
import {MentionsStreamComponent} from './components/mentions/mentions-stream/mentions-stream.component';
import {MergeFieldContextMenuComponent} from './components/merge-field-context-menu/merge-field-context-menu.component';
import {MessageEditorWithMentionsComponent} from './components/message-editor-with-mentions/message-editor-with-mentions.component';
import {PasswordInputComponent} from './components/password-input/password-input.component';
import {ProfileImageComponent} from './components/profile-image/profile-image.component';
import {SlidePanelComponent} from './components/slide-panel/slide-panel.component';
import {UserAvatarComponent} from './components/user-avatar/user-avatar.component';
import {ZipCodeInputComponent} from './components/zip-code-input/zip-code-input.component';
import {DomChangeDirective} from './directives/dom-change.directive';
import {NumericDirective} from './directives/numeric.directive';
import {RerenderDirective} from './directives/re-render.directive';
import {RecreateViewDirective} from './directives/recreate-view.directive';
import {DownloadDirective} from './directives/download.directive';
import {FormControlHighlightDirective} from './directives/form-control-highlight.directive';
import {ConcealDirective} from './directives/conceal.directive';
import {
  CaseSizeValidatorDirective,
  MinValueValidatorDirective,
  RequireDigitValidatorDirective,
  RequireNonAplhanumericValidatorDirective,
} from './directives/validators';
import {FilterPipe, GroupByPipe, HTMLListPipe, LimitToPipe, PadLeftPipe, SortPipe, SpinalTapCasePipe} from './pipes';
import {PhonePipe} from './pipes/phone.pipe';
import {SafeHtml} from './pipes/safe-html.pipe';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {SearchPipe} from './pipes/search.pipe';
import {SplitCamelCasePipe} from './pipes/split-camel-case.pipe';
import {YesNoPipe} from './pipes/yes-no.pipe';
import {EsignatureCancelOrderDialogComponent} from './components/esignature-cancel-order-dialog/esignature-cancel-order-dialog.component';
import {InputTrimDirective} from './directives/trim-input-directive';
import {CanActivateAdminRoutesGuard} from './route-guards/can-activate-admin-routes.guard';
import {CanActivateTpoRoutesGuard} from './route-guards/can-activate-tpo-routes.guard';
import {SearchAppComponent} from './components/search-app/search-app.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {RichTextEditorComponent} from './components/rich-text-editor/rich-text-editor.component';
import {SimpleMergeFieldContextMenuComponent} from './components/simple-merge-field-context-menu/simple-merge-field-context-menu.component';
import {DynamicallyRenderedContentComponent} from './components/dynamically-rendered-content/dynamically-rendered-content.component';
import {MinTimeValidatorDirective} from './directives/validators/min-time-validator.directive';
import {MaxTimeValidatorDirective} from './directives/validators/max-time-validator.directive';
import {
  FunctionEditorWithContextParamsComponent,
} from './components/function-editor-with-context-params/function-editor-with-context-params.component';
import {SendMmsComponent} from './components/send-mms/send-mms.component';
import {CanActivateAdminRoleRoutesGuard} from './route-guards/can-activate-admin-role-routes.guard';
import {AddressInputComponent} from './components/address-input/address-input.component';
import {SelectInputVScrollComponent} from './components/select-input-vscroll/select-input-vscroll.component';
import {EmailPreferenceCenterComponent} from './components/email-preference-center/email-preference-center.component';
import {OrderByPipe} from './pipes/orderBy.pipe';
import {LodaChartComponent} from './components/loda-chart/loda-chart.component';
import {ChartModule} from 'primeng/chart';
import {SSNInputComponent} from './components/ssn-input/ssn-input.component';
import {ConditionalLinkComponent} from './components/conditional-link/conditional-link.component';
import {EnableSmsAlertsToggleComponent} from './components/enable-sms-alerts-toggle/enable-sms-alerts-toggle.component';
import {PortalStatusIndicatorComponent} from './components/portal-status-indicator/portal-status-indicator.component';
import {DateTimeInputComponent} from './components/date-time-input/date-time-input.component';
import {CalendarModule} from 'primeng/calendar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputTextModule} from 'primeng/inputtext';
import {
  VoiceCommunicationHistoryTableComponent,
} from './components/voice-communication-history-table/voice-communication-history-table.component';
import {SaveButtonComponent} from './components/save-button/save-button.component';
import {IconButtonComponent} from './components/icon-button/icon-button.component';
import {
  LosLdeTransactionHistoryFiltersComponent,
} from './components/los-lde-transaction-history-filters/los-lde-transaction-history-filters.component';
import {NonZeroValidatorDirective} from './directives/validators/non-zero-validator.directive';
import {LoanBorrowersComponent} from '../modules/app-details/components/admin-loan-summary/loan-borrowers/loan-borrowers.component';
import {ExportToCSVSelectorDialogComponent} from './components/export-to-csv-selector-dialog/export-to-csv-selector-dialog.component';
import {AutofocusDirective} from './directives/autofocus.directive';
import {LetDirective} from './directives/let.directive';
import {TpoPageTemplateComponent} from '../layout/tpo/tpo-page-template/tpo-page-template.component';
import {PossessivePipe} from './pipes/possessive.pipe';
import {TreeViewComponent} from './components/tree-view/tree-view.component';
import {TreeNodeComponent} from './components/tree-view/tree-node/tree-node.component';
import {AddressAutocompleteInputComponent} from './components/address-autocomplete-input/address-autocomplete-input.component';
import {PizzaTrackerComponent} from './components/pizza-tracker/pizza-tracker.component';
import {ProposedMonthlyPaymentComponent} from './components/proposed-monthly-payment/proposed-monthly-payment.component';
import {ExpansionPanelComponent} from './components/expansion-panel/expansion-panel.component';
import {ExpressionEditorDialogComponent} from './components/expressions/expression-editor-dialog/expression-editor-dialog.component';
import {ExpressionEditorComponent} from './components/expressions/expression-editor/expression-editor.component';
import {TextInputAutocompleteModule} from 'angular-text-input-autocomplete';
import {AlertComponent} from './components/alert/alert.component';
import {FinanceEntireFeeCheckboxComponent} from './components/finance-entire-fee-checkbox/finance-entire-fee-checkbox.component';
import {FaviconLoaderComponent} from './components/favicon-loader/favicon-loader.component';
import { RelatedEntitiesComponent } from './components/related-entities/related-entities.component';
import { EllipsisDirective } from './directives/ellipsis.directive';

const maskConfig: Partial<IConfig> = {
  validation: true,
};

const Validators = [MinValueValidatorDirective, CaseSizeValidatorDirective, RequireDigitValidatorDirective,
  RequireNonAplhanumericValidatorDirective, MinTimeValidatorDirective, MaxTimeValidatorDirective, NonZeroValidatorDirective];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    TableModule,
    StepsModule,
    InputNumberModule,
    NgApexchartsModule,
    DropdownModule,
    PopoverModule,
    MentionModule,
    NgxSpinnerModule,
    TagInputModule,
    SortablejsModule,
    NgbModule,
    NgbCarouselModule,
    RouterModule,
    DragDropModule,
    GooglePlaceModule,
    NgSelect2Module,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    FileUploadModule,
    CKEditorModule,
    NgxCurrencyModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),
    NgxMaskModule.forRoot(maskConfig),
    ClipboardModule,
    MultiSelectModule,
    NgxToggleModule,
    ImageCropperModule,
    AngularEditorModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    DropdownModule,
    ChartModule,
    CalendarModule,
    OverlayPanelModule,
    InputTextModule,
    PDragDropModule,
    ReactiveFormsModule,
    DndModule,
    TextInputAutocompleteModule
  ],
  declarations: [
    CheckboxFlagsComponent,
    BreadCrumbComponent,
    ImageUploadComponent,
    GroupByPipe,
    OrderByPipe,
    SortPipe,
    LimitToPipe,
    FilterPipe,
    YesNoPipe,
    SafeHtml,
    SafeUrlPipe,
    PhonePipe,
    PadLeftPipe,
    SplitCamelCasePipe,
    SpinalTapCasePipe,
    SearchPipe,
    HTMLListPipe,
    NumericDirective,
    InputTrimDirective,
    DomChangeDirective,
    RerenderDirective,
    RecreateViewDirective,
    DownloadDirective,
    FormControlHighlightDirective,
    ConcealDirective,
    EllipsisDirective,
    CurrencyInputComponent,
    PercentInputComponent,
    LoadingIndicatorComponent,
    DateInputComponent,
    GridTopActionBarComponent,
    ...Validators,
    FileImportDialogComponent,
    ExpressionInputComponent,
    UserAvatarComponent,
    MultiCheckboxPickerComponent,
    ConfirmModalComponent,
    MenuItemComponent,
    ProfileImageComponent,
    DateRangeFilterComponent,
    AddToDialListComponent,
    AddToDialListConfirmationDialogComponent,
    NewDialListDialogComponent,
    MoveToOtherListConfirmationDialogComponent,
    DeleteListConfirmationDialogComponent,
    SlidePanelComponent,
    ZipCodeInputComponent,
    DuplicateDialogComponent,
    ContactInfoComponent,
    AdminPageTemplateComponent,
    TpoPageTemplateComponent,
    LogoComponent,
    CommonSendSmsComponent,
    CommonSendEmailComponent,
    CommonSendEmailSmsDialogComponent,
    CommonSendEmailSmsComponent,
    AppointmentsComponent,
    MessageEditorWithMentionsComponent,
    FunctionEditorWithContextParamsComponent,
    ReleaseNoteDialogComponent,
    MentionItemComponent,
    MentionsStreamComponent,
    DrawerComponent,
    InfiniteScrollComponent,
    DrawingPadDialogComponent,
    PasswordInputComponent,
    AppUrlComponent,
    GenericFilterComponent,
    GenericDateRangeFilterComponent,
    RearrangeOrderComponent,
    ListSorterComponent,
    MergeFieldContextMenuComponent,
    CallControlPanelActionBarComponent,
    GenericInputDropdownComponent,
    SecurityCodeDialog,
    EsignatureCancelOrderDialogComponent,
    SearchAppComponent,
    RichTextEditorComponent,
    SimpleMergeFieldContextMenuComponent,
    DynamicallyRenderedContentComponent,
    SelectInputComponent,
    TextInputComponent,
    NumberInputComponent,
    SendMmsComponent,
    AddressInputComponent,
    TextAreaInputComponent,
    SelectInputVScrollComponent,
    EmailPreferenceCenterComponent,
    LodaChartComponent,
    SSNInputComponent,
    ConditionalLinkComponent,
    EnableSmsAlertsToggleComponent,
    LoanBorrowersComponent,
    PortalStatusIndicatorComponent,
    DateTimeInputComponent,
    VoiceCommunicationHistoryTableComponent,
    SaveButtonComponent,
    IconButtonComponent,
    LosLdeTransactionHistoryFiltersComponent,
    IncomingActionsComponent,
    ExportToCSVSelectorDialogComponent,
    AutofocusDirective,
    LetDirective,
    PossessivePipe,
    TreeNodeComponent,
    TreeViewComponent,
    AddressAutocompleteInputComponent,
    PizzaTrackerComponent,
    ProposedMonthlyPaymentComponent,
    ExpansionPanelComponent,
    ExpressionEditorDialogComponent,
    ExpressionEditorComponent,
    AlertComponent,
    FaviconLoaderComponent,
    FinanceEntireFeeCheckboxComponent,
    RelatedEntitiesComponent
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    NgApexchartsModule,
    NgxSpinnerModule,
    PopoverModule,
    TagInputModule,
    SortablejsModule,
    NgbModule,
    InputNumberModule,
    DropdownModule,
    CKEditorModule,
    MentionModule,
    NgbCarouselModule,
    RouterModule,
    DragDropModule,
    GooglePlaceModule,
    AngularEditorModule,
    NgSelect2Module,
    NgSelectModule,
    NgMultiSelectDropDownModule,
    NgxMaskModule,
    CheckboxFlagsComponent,
    GroupByPipe,
    OrderByPipe,
    SortPipe,
    LimitToPipe,
    ImageUploadComponent,
    FilterPipe,
    YesNoPipe,
    SafeHtml,
    SafeUrlPipe,
    PhonePipe,
    PadLeftPipe,
    SplitCamelCasePipe,
    SpinalTapCasePipe,
    SearchPipe,
    HTMLListPipe,
    BreadCrumbComponent,
    NumericDirective,
    InputTrimDirective,
    DomChangeDirective,
    RerenderDirective,
    RecreateViewDirective,
    DownloadDirective,
    FormControlHighlightDirective,
    ConcealDirective,
    EllipsisDirective,
    CurrencyInputComponent,
    PercentInputComponent,
    LoadingIndicatorComponent,
    DateInputComponent,
    GridTopActionBarComponent,
    FileUploadModule,
    ...Validators,
    FileImportDialogComponent,
    ExpressionInputComponent,
    UserAvatarComponent,
    MultiCheckboxPickerComponent,
    MenuItemComponent,
    ProfileImageComponent,
    DateRangeFilterComponent,
    AddToDialListComponent,
    SlidePanelComponent,
    ZipCodeInputComponent,
    DuplicateDialogComponent,
    ContactInfoComponent,
    AdminPageTemplateComponent,
    TpoPageTemplateComponent,
    LogoComponent,
    CommonSendSmsComponent,
    CommonSendEmailComponent,
    CommonSendEmailSmsDialogComponent,
    CommonSendEmailSmsComponent,
    AppointmentsComponent,
    MessageEditorWithMentionsComponent,
    FunctionEditorWithContextParamsComponent,
    ReleaseNoteDialogComponent,
    MentionItemComponent,
    MentionsStreamComponent,
    DrawerComponent,
    InfiniteScrollComponent,
    ClipboardModule,
    MultiSelectModule,
    DrawingPadDialogComponent,
    PasswordInputComponent,
    NgxToggleModule,
    AppUrlComponent,
    GenericFilterComponent,
    GenericDateRangeFilterComponent,
    RearrangeOrderComponent,
    ListSorterComponent,
    MergeFieldContextMenuComponent,
    CallControlPanelActionBarComponent,
    GenericInputDropdownComponent,
    SecurityCodeDialog,
    EsignatureCancelOrderDialogComponent,
    RichTextEditorComponent,
    SimpleMergeFieldContextMenuComponent,
    DynamicallyRenderedContentComponent,
    SelectInputComponent,
    TextInputComponent,
    NumberInputComponent,
    SendMmsComponent,
    AddressInputComponent,
    StepsModule,
    TextAreaInputComponent,
    SelectInputVScrollComponent,
    LodaChartComponent,
    SSNInputComponent,
    ConditionalLinkComponent,
    EnableSmsAlertsToggleComponent,
    LoanBorrowersComponent,
    PortalStatusIndicatorComponent,
    DateTimeInputComponent,
    SaveButtonComponent,
    IconButtonComponent,
    LosLdeTransactionHistoryFiltersComponent,
    IncomingActionsComponent,
    ExportToCSVSelectorDialogComponent,
    AutofocusDirective,
    LetDirective,
    PossessivePipe,
    TreeNodeComponent,
    TreeViewComponent,
    PDragDropModule,
    AddressAutocompleteInputComponent,
    DndModule,
    PizzaTrackerComponent,
    ProposedMonthlyPaymentComponent,
    ExpansionPanelComponent,
    ExpressionEditorDialogComponent,
    ExpressionEditorComponent,
    AlertComponent,
    FaviconLoaderComponent,
    FinanceEntireFeeCheckboxComponent,
    RelatedEntitiesComponent
  ],
  providers: [
    { provide: CDK_DRAG_CONFIG, useValue: { zIndex: 10000 } },
    SplitCamelCasePipe,
    CanActivateAdminRoutesGuard,
    CanActivateTpoRoutesGuard,
    CanActivateAdminRoleRoutesGuard
  ]
})
export class SharedModule {
  constructor() { }
}
