import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { map, merge, race, Subject, Subscription } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { Address, Liability, RealEstateOwned } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import {
  MortgageCalculationService,
} from 'src/app/modules/urla/services/mortgage-calculation.service';
import {
  QuickApplyFieldsConfigBoundComponent,
} from 'src/app/shared/components/quick-apply-fields-config-bound.component';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { resetObjectTo } from '../../qa-fi-income/reset-object.to';

@Component({
  selector: 'qa-fi-reo-editor-v2',
  templateUrl: './qa-fi-reo-editor-v2.component.html',
  styleUrls: ['./qa-fi-reo-editor-v2.component.scss']
})
export class QaFiReoEditorV2Component extends QuickApplyFieldsConfigBoundComponent implements OnInit, OnChanges, OnDestroy {

  @Input() reo: RealEstateOwned;

  @Input() mortgage: UrlaMortgage;

  @Input()
  possibleAccountOwners: Array<{
    id: number;
    text: string;
  }> = [];

  @Input() mode: 'create' | 'edit' = 'create';

  @Input() states: EnumerationItem[];
  @Input() currentPropertyWillBeTypes: EnumerationItem[] = [];
  @Input() propertyStatusOptions: EnumerationItem[] = [];
  @Input() intendedPropertyWillBeTypes: EnumerationItem[] = [];
  @Input() reoPropertyTypes: EnumerationItem[] = [];
  @Input() liabilityTypeHELOC: string;
  @Input() liabilityTypeMortgageLoan: string;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() update: EventEmitter<ReoEditorResult> = new EventEmitter<ReoEditorResult>();

  @ViewChild('form') protected formElement: NgForm | undefined;

  protected reoLiabilities: Liability[] = [];

  protected totalMonthlyReoExpenses: number = 0;

  protected isInvestmentOrTwoToFourUnitProperty: boolean = false;

  protected monthlyEarningEnterType: string = 'calculated';

  private _originalReo: RealEstateOwned;

  private _savingChangesSubscription?: Subscription;
  private _reoEventSubscription: Subscription;

  private readonly _save$ = new Subject<void>();
  private readonly _destroyed$ = new Subject<void>();

  constructor(
    injector: Injector,
    private readonly _calculationService: MortgageCalculationService,
  ) {
    super(injector);

    this._reoEventSubscription = merge(
      this._calculationService.reoLiabilityAssociationChanged,
      this._calculationService.liabilitySubject,
    ).subscribe(() => {
      this.calculateMortgagePayment(this.reo);
      this.onReoExpenseItemChanged();
    });
  }

  ngOnInit(): void {
    // Initializes the missing input fields (if any).
    const changes = {
      reo: this.reo ? { currentValue: this.reo } : undefined,
      mortgage: this.mortgage ? { currentValue: this.mortgage } : undefined,
    } as unknown as SimpleChanges;
    // This cast is safe because we use only the `currentValue` property in
    // the `ngOnChanges` method.

    // Remove undefined properties.
    Object.entries(changes)
      .forEach(([k, v]) => v === undefined && delete changes[k]);

    // Init the missing properties (if any).
    this.ngOnChanges(changes);

    setTimeout(() => {
      this.subscribeToSavingChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const reoChange = changes['reo'];
    const mortgageChange = changes['mortgage'];

    if (reoChange) {
      const reo: RealEstateOwned
        = reoChange.currentValue ?? new RealEstateOwned();
      this.initializeReo(reo, mortgageChange.currentValue);
    }

    if (mortgageChange) {
      const mortgage: UrlaMortgage
        = mortgageChange.currentValue ?? new UrlaMortgage();
      this.initializeMortgage(mortgage, reoChange.currentValue);
      this.onReoExpenseItemChanged();
    }
  }

  ngOnDestroy(): void {
    this._reoEventSubscription?.unsubscribe();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private initializeMortgage(mortgage: UrlaMortgage, reo?: RealEstateOwned): void {
    reo ??= this.reo;
    if (!reo) {
      reo = this.reo = new RealEstateOwned();
      this.initializeReo(reo, mortgage);
    }

    this.calculateMortgagePayment(reo);
    if (reo.isSubjectProperty) {
      const currentHousingExpense = mortgage.currentHousingExpense;
      reo.monthlyMiscExpenses = currentHousingExpense
        ? [
          currentHousingExpense.homeownersInsurance,
          currentHousingExpense.supplementalPropertyInsurance,
          currentHousingExpense.realEstateTax,
          currentHousingExpense.mortgageInsurance,
          currentHousingExpense.homeownersAssociationDuesAndCondominiumFees,
        ].reduce((a, b) => a + (Number(b) || 0), 0)
        : 0;
    } else {
      this.totalMonthlyReoExpenses = reo.monthlyMiscExpenses + reo.mortgagePayment;
      if (this.isInvestmentOrTwoToFourUnitProperty) {
        reo.netRentalIncome = 0;
      }
    }
  }

  private initializeReo(reo: RealEstateOwned, mortgage?: UrlaMortgage): void {
    const originalReo = this._originalReo = _.cloneDeep(reo);
    this.mode = reo.reoId > 0 ? 'edit' : 'create';
    this.isInvestmentOrTwoToFourUnitProperty = this.checkIfInvestmentOrTwoToFourUnitProperty(originalReo);
    mortgage ??= this.mortgage ??= new UrlaMortgage();
    this.calculateMortgagePayment(originalReo, mortgage);
    this.onReoExpenseItemChanged();
  }

  private subscribeToSavingChanges(): void {
    this._savingChangesSubscription?.unsubscribe();
    const subscription = this._savingChangesSubscription = this.formElement.valueChanges.pipe(
      takeUntil(this._destroyed$),
    ).subscribe(() => {
      this.enqueueSave();
    });

    const cancelOrDestroy$ = race(
      this._destroyed$.pipe(map(() => true)),
      this.cancel.pipe(map(() => false)),
    ).pipe(
      tap((shouldSave) => {
        if (shouldSave) {
          // Prevent ExpressionChangedAfterItHasBeenCheckedError.
          setTimeout(() => {
            this.emitUpdate();
          });
        }
      }),
    );

    subscription.add(
      this._save$.pipe(
        takeUntil(cancelOrDestroy$),
        debounceTime(200),
      ).subscribe(() => {
        this.emitUpdate();
      }),
    );
  }

  private enqueueSave(): void {
    this._save$.next();
  }

  private emitUpdate(): void {
    this.update.emit({ newReo: this.reo, reoLiabilities: this.reoLiabilities });
  }

  protected onClickCancel(): void {
    this.resetReoChanges();

    this.cancel.emit();
  }

  private resetReoChanges(): void {
    const reo = this.reo;
    if (reo == null) {
      console.error('Cannot reset changes as the reo is null.');
      return;
    }

    const originalReo = this._originalReo;
    if (originalReo == null) {
      console.error('Cannot reset changes as the original reo is null.');
      return;
    }

    resetObjectTo(reo, originalReo);
  }

  protected onClickClose(): void {
    if (this.validateForm()) {
      this.close.emit();
      return;
    }

    Swal.fire({
      title: 'Requested Fields Missing',
      text: 'There are requested fields that are not filled in. Do you want to proceed with missing data?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then((result: SweetAlertResult) => {
      if (result.value) {
        this.close.emit();
      }
    });
  }

  private validateForm(): boolean {
    if (!this.formElement) {
      return false;
    }

    this.formElement.form.markAllAsTouched();
    return this.formElement.form.valid;
  }

  onAccountOwnersChanged = (reo: RealEstateOwned) => {
    reo['owners'] = [];
    reo.owningBorrowerIds.forEach(borrowerId => {
      reo['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(borrowerId) });
    });
    this.calculateMortgagePayment(this.reo);
    this.calculateNetRentalIncome();
    this._calculationService.sendLiabilityEvent();
  }

  onReoExpenseItemChanged = () => {
    this.calculateNetRentalIncome();
    this.calculateTotalReoExpenses();
  }

  getBorrowerName = (borrowerId: Number): string => {
    let borrower = this.possibleAccountOwners.find(owner => Number(owner.id) == Number(borrowerId));
    if (borrower && borrower.text)
      return borrower.text;
    return ""
  }

  onChangeLiabilityLink = (liability, event) => {
    if (!event.target.checked) {
      liability.reoId = null;
    } else {
      liability.reoId = this.reo.reoId;
    }
  }

  protected onIsSubjectPropertyChanged = (): void => {
    this.mortgage.realEstateOwned.forEach(reo => {
      if (this.reo.reoId !== reo.reoId) {
        reo.isSubjectProperty = false;
      }
    });
    this.copyAddressFromSubjectProperty();
  }

  protected onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.reo["state"] = zipCode.state.toLowerCase();
      this.reo["city"] = Utils.toTitleCase(zipCode.city);
      this.reo["zipCode"] = zipCode.zipcode;
      this.reo["country"] = 'us';
    }
  }

  protected handleAddressChange = (e: Partial<Address>) => {
    const reo = this.reo;
    reo.address1 = ''; // to reset the last populated address.

    setTimeout(() => {
      reo.address1 = e.address1;
      reo.city = e.city;
      reo.state = e.state;
      reo.zipCode = e.zipCode;
    }, 200);
  }

  calculateNetRentalIncome = () => {
    if (this.isInvestmentOrTwoToFourUnitProperty) {
      this.reo.netRentalIncome = ((this.reo.grossRentalIncome || 0) * ((this.reo.vacancyFactor || 0) / 100) * ((this.reo.percentOwned || 100) / 100)) - this.reo.mortgagePayment;

      if (!this.reo.isSubjectProperty) {
        this.reo.netRentalIncome = this.reo.netRentalIncome - this.reo.monthlyMiscExpenses;
      }
    }
  }

  onProposedOccupancyChanged = () => {
    this.isInvestmentOrTwoToFourUnitProperty = this.checkIfInvestmentOrTwoToFourUnitProperty(this.reo);
  }

  onDispositionStatusChanged = () => {
    if (this.reo.dispositionStatus == "RetainForPrimaryOrSecondaryResidence" &&
      !this.reo.intendedUsageType) {
      this.reo.intendedUsageType = this.reo.currentUsageType;
    }
    if (this.reo.dispositionStatus == "Sold") {
      this.reo.intendedUsageType = null;
    }
    this.isInvestmentOrTwoToFourUnitProperty = this.checkIfInvestmentOrTwoToFourUnitProperty(this.reo);
  }

  calculateMortgagePayment = (reo: RealEstateOwned, mortgage?: UrlaMortgage) => {
    if (reo == undefined) {
      return;
    }
    this.reoLiabilities = [];
    mortgage ??= this.mortgage;
    // this for new liabilities 
    mortgage.realEstateOwned.filter(r => (!r.reoId || r.reoId <= 0) && r.liabilities).forEach(r => {
      this.reoLiabilities = this.reoLiabilities.concat(r.liabilities.filter(l => 
        (!l.liabilityId || l.liabilityId <= 0)
        && (l.typeOfLiability == this.liabilityTypeHELOC || l.typeOfLiability == this.liabilityTypeMortgageLoan)
        && l.owningBorrowerIds.some(borrowerId => reo.owningBorrowerIds.map(b => Number(b)).indexOf(Number(borrowerId)) >= 0)
      ));
    })

    if (mortgage?.liabilities) {
      // this is for exisiting liabilities
      const existingLiabilitiesAttachedToReo = mortgage.liabilities.filter(l => (l.typeOfLiability == this.liabilityTypeHELOC ||
        l.typeOfLiability == this.liabilityTypeMortgageLoan) &&
        l.owningBorrowerIds.some(borrowerId => reo.owningBorrowerIds.map(b => Number(b)).indexOf(Number(borrowerId)) >= 0));
      this.reoLiabilities = this.reoLiabilities.concat(existingLiabilitiesAttachedToReo);
    }

    if (this.reoLiabilities) {
      reo.mortgagePayment = 0;
      this.reoLiabilities.forEach(liability => {
        if (liability.reoId == reo.reoId) {
          reo.mortgagePayment += Number(liability.monthlyPayment) || 0;
        }
      });
    }
  }

  private copyAddressFromSubjectProperty = (): void => {
    const subjectProperty = this.mortgage.subjectProperty;

    this.reo.address1 = subjectProperty.address1;
    this.reo.city = Utils.toTitleCase(subjectProperty.city);
    this.reo.state = subjectProperty.state;
    this.reo.zipCode = subjectProperty.zipCode;
  }

  private checkIfInvestmentOrTwoToFourUnitProperty = (reo: RealEstateOwned) => {
    if (['RetainForPrimaryOrSecondaryResidence', 'Retained'].indexOf(reo.dispositionStatus) < 0) return false;
    return reo.typeOfProperty == "TwoToFourUnitProperty" || reo.intendedUsageType == "Investment" || reo.intendedUsageType == "SecondaryResidence";
  }

  private calculateTotalReoExpenses() {
    const reo = this.reo;

    reo.monthlyMiscExpenses = toNumber(reo.otherFinance) +
      toNumber(reo.hazardInsurance) +
      toNumber(reo.propertyTax) +
      toNumber(reo.mortgageInsurance) +
      toNumber(reo.hoa) +
      toNumber(reo.otherMonthlyExpense);
    this.totalMonthlyReoExpenses = reo.monthlyMiscExpenses + toNumber(reo.mortgagePayment);

    return;

    function toNumber(value: string | number | undefined): number {
      return Number(value) || 0;
    }
  }
}

export interface ReoEditorResult {
  newReo: RealEstateOwned;
  reoLiabilities: Liability[];
}
