import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import * as _ from 'lodash';
import { AbstractValueAccessor, MakeProvider } from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EditorMode } from '../currency-input/currency-input.component';

@Component({
  selector: 'select-input',
  templateUrl: 'select-input.component.html',
  providers: [MakeProvider(SelectInputComponent)],
  viewProviders: [formViewProvider]
})
export class SelectInputComponent extends AbstractValueAccessor
  implements OnInit {

  @ViewChild('model')
  model: NgModel;

  @ViewChild('control')
  input: ElementRef<HTMLInputElement>;

  @Input()
  readonly: boolean;

  @Input()
  disabled: boolean;

  @Input()
  name: string;

  @Input()
  required: boolean;

  @Input()
  editorMode: EditorMode = EditorMode.Classic;

  @Input()
  inlineTextClass: string;

  @Input()
  shiftInputToLeftWhenEditingInline: boolean = false;

  @Input()
  isMultiple: boolean = false;

  @Input()
  options: EnumerationItem[] | any[] = [];

  @Input()
  optionValueKey: string = "value";

  @Input()
  optionNameKey: string = "name";

  @Input()
  groupingEnabled: boolean = false;

  @Input()
  optionGroupByKey: string = "groupName";

  @Input()
  placeholder: string;

  @Input()
  /**
   * This is the placeholder that will be shown when the input is in inline mode
   * and the value is empty.
   */
  inlinePlaceholder: string = '--';

  /**
   * If true, the none (null) option (-- Select One --) will not be shown.
   */
  @Input()
  noneOptionDisabled: boolean = false;

  @Output()
  blur: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  editStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  id: string;

  groups: any[] = [];

  private _isEditActive: boolean = false;

  get isEditActive(): boolean {
    return this._isEditActive;
  }

  set isEditActive(value: boolean) {
    this._isEditActive = value;
    this.editStateChange.emit(value);
  }

  private _originalValue: any;

  ngOnInit(): void {
    this.id = this.name;
    this.name = this.name + Math.floor(Math.random() * Date.now());

    if(this.groupingEnabled){
      this.groups = _.chain(this.options)
      .groupBy(this.optionGroupByKey)
      .map((value, key) => ({ groupName: key, options: value }))
      .value()
    }
  }

  getGroups = () => {
    return Object.keys(this.groups);
  }

  onBlur = ($event: any) => {
    if (this.editorMode === 'Inline' && !this.isEditActive) {
      return;
    }
    this.blur.emit($event);
    this.apply();
  }

  getSelectedOptionName = (v: any): string => {
    if (v == null || v == undefined || !this.options) {
      return null;
    }

    if(this.isMultiple){
      let selections = this.options.filter(o => v.includes(o[this.optionValueKey]));
      if(selections.length){
        let allSelections = selections.map(s => s[this.optionNameKey])
        return allSelections.length >= 3 ?( allSelections[0]  +"," + allSelections[1] + " (" + (allSelections.length - 2) + " more)") : allSelections.join(",");
      }
      else {
        return this.inlinePlaceholder;
      }
    }
    else {
      let selected = this.options.find(o => o[this.optionValueKey] == v);
      return selected ? selected[this.optionNameKey] : null;
    }

  }

  onEditModeToggledOn = () => {
    this._originalValue = this._value;
    setTimeout(() => {
      this.input.nativeElement?.focus();
    });
    this.isEditActive = true;
  }

  override writeValue(value: any) {
    this._value = value;
    this.onChange(value);
  }

  onCancelClicked = (event: any) => {
    event.preventDefault();
    this.writeValue(this._originalValue);
    this.blur.emit();
    this.isEditActive = false;
  }

  onApplyClicked = (event: any) => {
    this.model.control.markAsTouched();
    event.preventDefault();
    if (this.model.valid) {
      this._originalValue = this.value;
      this.blur.emit();
      this.isEditActive = false;
    }
  }

  private apply = () => {
    if (this.model.valid) {
      this._originalValue = this.value;
      this.isEditActive = false;
    }
  }
}
