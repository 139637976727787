<div class="row">
  <div class="col-12">
    <div class="row mt-1">
      <div class="text-end">
        <button class="btn btn btn-outline-danger" (click)="onDeleteClicked()"><i class="fas fa-trash-alt me-2"></i> Delete </button>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="form-group col-12 col-md-6 col-lg-4">
        <label for="dateCompleted{{borrower.borrowerId}}"> Date Completed * </label>
        <date-input name="dateCompleted{{borrower.borrowerId}}" id="dateCompleted{{borrower.borrowerId}}"
          [(ngModel)]="event.completedDate" required="true">
        </date-input>
      </div>
      <div class="form-group col-12 col-md-6 col-lg-4">
        <label for="counselingFormat{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}"> What format was it in? *
        </label>
        <select id="counselingFormat{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}" 
        name="counselingFormat{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}"
          class="form-select" [(ngModel)]="event.format" #counselingFormatValue="ngModel" required="true"
          [ngClass]="{'is-invalid' : counselingFormatValue && counselingFormatValue.touched && counselingFormatValue.invalid}">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let option of borrowerCounselingFormatTypes" [ngValue]="option.value">
            {{ option.name }}
          </option>
        </select>
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div *ngIf="event.format == 'Other'" class="form-group col-12 col-md-6 col-lg-4">
        <label for="otherFormat{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}"> Other Description * </label>
        <input id="otherFormat{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}" 
          name="otherFormat{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}" type="text"
          class="form-control" [(ngModel)]="event.formatOtherDescription" #otherFormatValue="ngModel" required="true"
          [ngClass]="{'is-invalid' : otherFormatValue && otherFormatValue.touched && otherFormatValue.invalid}" />
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12 col-md-6 col-lg-4">
        <label for="agencyId{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}"> Agency ID * </label>
        <input id="agencyId{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}" numeric [allowNegative]="false"
          name="agencyId{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}" 
          class="form-control" #agencyIdValue="ngModel" required="true"
          [ngClass]="{'is-invalid' : agencyIdValue && agencyIdValue.touched && agencyIdValue.invalid}"
          [(ngModel)]="event.counselingAgencyIdentifier" />
        <div class="invalid-feedback">This field is required</div>
      </div>
      <div class="form-group col-12 col-md-6 col-lg-4">
        <label for="agencyName{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}"> Agency Name * </label>
        <input id="agencyName{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}" 
          name="agencyName{{borrower.borrowerId}}_{{event.counselingType}}_{{index}}" type="text"
          class="form-control" [(ngModel)]="event.counselingAgencyName" #agencyNameValue="ngModel" required="true"
          [ngClass]="{'is-invalid' : agencyNameValue && agencyNameValue.touched && agencyNameValue.invalid}" />
        <div class="invalid-feedback">This field is required</div>
      </div>
    </div>
    <hr>
  </div>
</div>