import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { MortgageBorrower } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { QuickApplyFieldsConfigBoundComponent } from 'src/app/shared/components/quick-apply-fields-config-bound.component';
import { InvalidateableComponent } from '../../../invalidateable-component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'qa-borrower-demographics',
  templateUrl: 'qa-borrower-demographics.component.html',
  styleUrls: ['./qa-borrower-demographics.component.scss'],
  viewProviders: [formViewProvider]
})

export class QuickApplyBorrowerDemographicsComponent
  extends QuickApplyFieldsConfigBoundComponent
  implements OnInit, InvalidateableComponent {

  @Input()
  borrower: MortgageBorrower;

  @Output()
  readonly invalidate: EventEmitter<void> = new EventEmitter<void>();

  applicationChannelOptions: EnumerationItem[] = [];
  yesNoOptions: EnumerationItem[] = [];
  sexOptions: EnumerationItem[] = [];
  ethnicityOptions: EnumerationItem[] = [];
  hispanicOriginOptions: EnumerationItem[] = [];
  raceOptions: EnumerationItem[] = [];
  raceDesignationOptions: EnumerationItem[] = [];
  asianRaceDesignationOptions: EnumerationItem[] = [];
  pacificIslanderRaceDesignationOptions: EnumerationItem[] = [];

  asianRace: string;
  pacificIslanderRace: string;
  nativeAmericanRace: string;

  ethnicities: string[] = [];
  ethnicityOrigins: string[] = [];
  sexes: string[] = [];
  races: string[] = [];
  raceDesignations: string[] = [];

  protected isOtherSelectedForHispanicOrLatinoEthnicity: boolean = false;
  protected isHispanicOrLatinoSelected: boolean = false;
  protected isNativeAmericanSelected: boolean = false;
  protected isRaceSelected: boolean = false;
  protected isOtherSelectedForPacificIslanderRace: boolean = false;
  protected isPacificIslanderSelected: boolean = false;
  protected isSexSelected: boolean = false;
  protected isAsianSelected: boolean = false;
  protected isOtherSelectedForAsianRace: boolean = false;
  protected isEthnicitySelected: boolean = false;

  protected fakeRaceFieldSoRaceCheckboxesRegisterOnFormAsControls: string = "";

  constructor(
    private readonly _enumsService: EnumerationService,
    injector: Injector,
  ) {
    super(injector)
  }

  async ngOnInit() {
    await this.initEnumerations();

    this.initBorrower();
    this.setInitialState();

    this.invalidate.emit();
  }

  /**
   * Initialize fields that are dependent on the {@link EnumerationService}.
   */
  private async initEnumerations(): Promise<void> {
    const enumsService = this._enumsService;
    const enums = await firstValueFrom(enumsService.getMortgageEnumerations());
    const enumsObject = Constants.enumerations;

    const getEnumValue =
      (name: string): string => enumsService.getEnumValue(name);

    this.ethnicityOptions = enums[enumsObject.ethnicityType];
    this.hispanicOriginOptions = enums[enumsObject.ethnicityOriginType];
    this.raceOptions = enums[enumsObject.raceType];
    this.sexOptions = enums[enumsObject.sexType];
    this.raceDesignationOptions = enums[enumsObject.raceDesignation];
    this.applicationChannelOptions = enums[enumsObject.applicationTakenMethodType];
    this.yesNoOptions = enumsService.getYesNoEnumItems();

    const enumItemNames = enumsObject.enumItemNames;
    const raceDesignationOptions = this.raceDesignationOptions;

    const initAsianRaceDesignationOptions = (): void => {
      const asianRaceDesignationTypes = new Set([
        enumItemNames.raceDesignationAsianIndian,
        enumItemNames.raceDesignationChinese,
        enumItemNames.raceDesignationFilipino,
        enumItemNames.raceDesignationJapanese,
        enumItemNames.raceDesignationKorean,
        enumItemNames.raceDesignationVietnamese,
        enumItemNames.raceDesignationOtherAsian,
      ].map(getEnumValue));

      this.asianRaceDesignationOptions = raceDesignationOptions.filter(
        t => asianRaceDesignationTypes.has(t.value)
      );
    }
    initAsianRaceDesignationOptions();

    this.asianRace = getEnumValue(enumItemNames.raceTypeAsian);

    const initPacificIslanderRaceDesignationOptions = (): void => {
      const pacificIslanderRaceDesignationTypes = new Set([
        enumItemNames.raceDesignationGuamanianOrChamorro,
        enumItemNames.raceDesignationNativeHawaiian,
        enumItemNames.raceDesignationSamoan,
        enumItemNames.raceDesignationOtherPacificIslander,
      ].map(getEnumValue));

      this.pacificIslanderRaceDesignationOptions = raceDesignationOptions.filter(
        t => pacificIslanderRaceDesignationTypes.has(t.value)
      );
    }
    initPacificIslanderRaceDesignationOptions();

    this.pacificIslanderRace = getEnumValue(enumItemNames.raceTypeNativeHawaiianOrOtherPacificIslander);
    this.nativeAmericanRace = getEnumValue(enumItemNames.raceTypeAmericanIndianOrAlaskaNative);
  }

  private initBorrower(): void {
    const borrower = this.borrower;
    const { ethnicity, ethnicityOrigin, race, raceDesignation, sex } =
      borrower.governmentMonitors;

    if (ethnicity && ethnicity.length > 0) {
      this.ethnicities = ethnicity.split(",");
      this.ethnicities = this.ethnicities.map(e => e.trim());
    }
    if (ethnicityOrigin && ethnicityOrigin.length > 0) {
      this.ethnicityOrigins = ethnicityOrigin.split(",");
      this.ethnicityOrigins = this.ethnicityOrigins.map(e => e.trim());
    }
    if (sex && sex.length > 0) {
      this.sexes = sex.split(",");
      this.sexes = this.sexes.map(s => s.trim());
    }
    if (race && race.length > 0) {
      this.races = race.split(",");
      this.races = this.races.map(r => r.trim());
    }
    if (raceDesignation && raceDesignation.length > 0) {
      this.raceDesignations = raceDesignation.split(",");
      this.raceDesignations = this.raceDesignations.map(r => r.trim());
    }
  }

  onMethodByWhichApplicationIsTakenChanged = () => {
    if (this.borrower.governmentMonitors.applicationTakenMethod != this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.applicationTakenMethodTypeFaceToFace)) {
      this.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname = false;
      this.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname = false;
      this.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname = false;
    } else {
      this.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname = null;
      this.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname = null;
      this.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname = null;
    }
  }

  ethnicityChanged = () => {
    this.borrower.governmentMonitors.ethnicity = '';

    if (this.ethnicities.length != 0 && this.ethnicities[this.ethnicities.length - 1] == 'NotApplicable') {
      this.ethnicities = ['NotApplicable'];
      this.borrower.governmentMonitors.ethnicity = 'NotApplicable';
    } else if (this.ethnicities.length != 0 && this.ethnicities[this.ethnicities.length - 1] == 'InformationNotProvided') {
      this.ethnicities = ['InformationNotProvided'];
      this.borrower.governmentMonitors.ethnicity = 'InformationNotProvided';
    } else if (this.ethnicities.length != 0) {
      if (this.ethnicities.includes('NotApplicable')) {
        const index = this.ethnicities.findIndex(ethnicity => ethnicity == 'NotApplicable');
        this.ethnicities.splice(index, 1);
        this.ethnicities = [...this.ethnicities];
      }
      if (this.ethnicities.includes('InformationNotProvided')) {
        const index = this.ethnicities.findIndex(ethnicity => ethnicity == 'InformationNotProvided');
        this.ethnicities.splice(index, 1);
        this.ethnicities = [...this.ethnicities];
      }

      this.ethnicities.forEach(ethnicity => {
        this.borrower.governmentMonitors.ethnicity += ethnicity + ',';
      });
      this.borrower.governmentMonitors.ethnicity = this.borrower.governmentMonitors.ethnicity.slice(0, this.borrower.governmentMonitors.ethnicity.length - 1);
    }
    this.checkIfEthnicityIsSelected();
    this.checkIfHispanicOrLatinoIsSelected();
  }

  ethnicityOriginChanged = () => {
    this.borrower.governmentMonitors.ethnicityOrigin = '';

    if (this.ethnicityOrigins.length != 0) {
      this.ethnicityOrigins.forEach(ethnicityOrigin => {
        this.borrower.governmentMonitors.ethnicityOrigin += ethnicityOrigin + ',';
      });
      this.borrower.governmentMonitors.ethnicityOrigin = this.borrower.governmentMonitors.ethnicityOrigin.slice(0, this.borrower.governmentMonitors.ethnicityOrigin.length - 1);
    }

    this.checkIfOtherIsSelectedForHispanicOrLatinoEthnicity();
  }

  onRaceChanged = (event: any, value: string) => {
    this.fakeRaceFieldSoRaceCheckboxesRegisterOnFormAsControls = event.target.checked ? value : null;
    const index = this.races.findIndex(v => v == value);
    if (event.target.checked) {
      if (value == 'NotApplicable' || value == 'InformationNotProvided') {
        this.races = [value];
      } else {
        if (index < 0) {
          this.races.push(value);
        }
      }
    } else {
      if (index >= 0) {
        this.races.splice(index, 1);
      }
    }

    this.borrower.governmentMonitors.race = '';

    if (this.races.length > 1) {
      ['NotApplicable', 'InformationNotProvided'].forEach(r => {
        const index = this.races.findIndex(race => race == r);
        if (index >= 0) {
          this.races.splice(index, 1);
        }
      })
    }

    this.borrower.governmentMonitors.race = this.races.join(',');

    this.checkIfRaceIsSelected();
    this.checkIfNativeAmericanIsSelected();
    this.checkIfPacificIslanderIsSelected();
    this.checkIfAsianIsSelected();
  }

  raceDesignationChanged = () => {
    this.borrower.governmentMonitors.raceDesignation = '';

    if (this.raceDesignations.length != 0) {
      this.raceDesignations.forEach(raceDesignation => {
        this.borrower.governmentMonitors.raceDesignation += raceDesignation + ',';
      });
      this.borrower.governmentMonitors.raceDesignation = this.borrower.governmentMonitors.raceDesignation.slice(0, this.borrower.governmentMonitors.raceDesignation.length - 1);
    }
    this.checkIfOtherIsSelectedForPacificIslanderRace();
    this.checkIfOtherIsSelectedForAsianRace();
  }

  onSexInformationChanged = () => {
    this.borrower.governmentMonitors.sex = '';

    if (this.sexes.length != 0 && this.sexes[this.sexes.length - 1] == 'NotApplicable') {
      this.sexes = ['NotApplicable'];
      this.borrower.governmentMonitors.sex = 'NotApplicable';
    } else if (this.sexes.length != 0 && this.sexes[this.sexes.length - 1] == 'InformationNotProvided') {
      this.sexes = ['InformationNotProvided'];
      this.borrower.governmentMonitors.sex = 'InformationNotProvided';
    } else if (this.sexes.length != 0) {
      if (this.sexes.includes('NotApplicable')) {
        const index = this.sexes.findIndex(sex => sex == 'NotApplicable');
        this.sexes.splice(index, 1);
        this.sexes = [...this.sexes];
      }
      if (this.sexes.includes('InformationNotProvided')) {
        const index = this.sexes.findIndex(sex => sex == 'InformationNotProvided');
        this.sexes.splice(index, 1);
        this.sexes = [...this.sexes];
      }

      this.sexes.forEach(sex => {
        this.borrower.governmentMonitors.sex += sex + ',';
      });
      this.borrower.governmentMonitors.sex = this.borrower.governmentMonitors.sex.slice(0, this.borrower.governmentMonitors.sex.length - 1);
    }

    this.checkIfSexIsSelected();
  }

  replaceSpaces = (text: string): string => {
    return text.replace(/\s/g, "");
  }

  private setInitialState = () => {
    this.checkIfSexIsSelected();
    this.checkIfRaceIsSelected();
    this.checkIfEthnicityIsSelected();
    this.checkIfAsianIsSelected();
    this.checkIfHispanicOrLatinoIsSelected();
    this.checkIfNativeAmericanIsSelected();
    this.checkIfPacificIslanderIsSelected();
    this.checkIfOtherIsSelectedForAsianRace();
    this.checkIfOtherIsSelectedForHispanicOrLatinoEthnicity();
    this.checkIfOtherIsSelectedForPacificIslanderRace();
  }

  private checkIfEthnicityIsSelected = (): boolean => {
    const selected = this.borrower.governmentMonitors.ethnicity != null &&
      this.borrower.governmentMonitors.ethnicity.length > 0;
    if (!selected) {
      this.borrower.governmentMonitors.ethnicityOrigin = null;
    }
    return this.isEthnicitySelected = selected;
  }

  private checkIfOtherIsSelectedForAsianRace = () => {
    var selected = this.raceDesignations.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.raceDesignationOtherAsian));
    if (!selected) {
      this.borrower.governmentMonitors.otherAsianRace = null;
    }
    return selected;
  }

  private checkIfAsianIsSelected = () => {
    const selected = this.races.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.raceTypeAsian));
    if (!selected) {
      if (this.raceDesignations && this.raceDesignations.length > 0) {
        this.asianRaceDesignationOptions.forEach(asianRaceDesignation => {
          const index = this.raceDesignations.findIndex(raceDesignation => raceDesignation == asianRaceDesignation.value);
          if (index >= 0) {
            this.raceDesignations.splice(index, 1);
            this.raceDesignationChanged();
          }
        });
      }

    }
    this.isAsianSelected = selected;
  }

  private checkIfSexIsSelected = () => {
    const selected = this.borrower.governmentMonitors.sex != null &&
      this.borrower.governmentMonitors.sex.length > 0;
    this.isSexSelected = selected;
  }

  private checkIfPacificIslanderIsSelected = () => {
    const selected = this.races.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.raceTypeNativeHawaiianOrOtherPacificIslander));
    if (!selected) {
      if (this.raceDesignations && this.raceDesignations.length > 0) {
        this.pacificIslanderRaceDesignationOptions.forEach(pacificIslanderRaceDesignation => {
          const index = this.raceDesignations.findIndex(raceDesignation => raceDesignation == pacificIslanderRaceDesignation.value);
          if (index >= 0) {
            this.raceDesignations.splice(index, 1);
            this.raceDesignationChanged();
          }
        });
      }
    }
    return this.isPacificIslanderSelected = selected;
  }

  private checkIfOtherIsSelectedForPacificIslanderRace = () => {
    var selected = this.raceDesignations.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.raceDesignationOtherPacificIslander));
    if (!selected) {
      this.borrower.governmentMonitors.otherPacificIslanderRace = null;
    }
    this.isOtherSelectedForPacificIslanderRace = selected;
  }

  private checkIfRaceIsSelected = () => {
    const selected = this.borrower.governmentMonitors.race != null &&
      this.borrower.governmentMonitors.race.length > 0;
    if (!selected) {
      this.borrower.governmentMonitors.raceDesignation = null;
      this.raceDesignations = [];
    }
    this.isRaceSelected = selected;
  }

  private checkIfOtherIsSelectedForHispanicOrLatinoEthnicity = () => {
    const selected = this.ethnicityOrigins.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.ethnicityOriginTypeOther));
    if (!selected) {
      this.borrower.governmentMonitors.ethnicityOriginOtherDescription = null;
    }
    this.isOtherSelectedForHispanicOrLatinoEthnicity = selected;
  }

  private checkIfHispanicOrLatinoIsSelected = () => {
    const selected = this.ethnicities.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.ethnicityTypeHispanicOrLatino));
    if (!selected) {
      this.ethnicityOrigins = [];
    }
    this.isHispanicOrLatinoSelected = selected;
  }

  private checkIfNativeAmericanIsSelected = () => {
    const selected = this.races.includes(this._enumsService.getEnumValue(Constants.enumerations.
      enumItemNames.raceTypeAmericanIndianOrAlaskaNative));
    if (!selected) {
      this.borrower.governmentMonitors.nativeAmericanTribeName = null;
    }
    this.isNativeAmericanSelected = selected;
  }
}
