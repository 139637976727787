import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ExternalContactsV2Service } from '../../services/external-contacts-v2.service';
import { Table } from 'primeng/table';
import { Subscription } from "rxjs";
import { CountryEnumerationItem } from 'src/app/services/enumeration-service';
import { AgentType } from 'src/app/modules/admin/company/models/agent-type.model';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { EditedExternalContact } from '../basic-information/basic-information.component';
import { ExternalContact } from 'src/app/modules/loan-docs/models/external-contact.model';
import { v4 as uuidv4 } from 'uuid';
import { NgxSpinnerService } from 'ngx-spinner';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { ApplicationContext } from 'src/app/models';

@Component({
  selector: 'external-contacts-v2',
  templateUrl: 'external-contacts-v2.component.html',
  styleUrls: ['./external-contacts-v2.component.scss']
})
export class ExternalContactsV2Component extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild("externalContactTable")
  table: Table;

  appId: number;

  borrowerId: number;

  externalContacts: ExternalContact[] = [];

  selectedRows: any[];

  borrowerLastName: string;

  mailingStreet: string;

  applicationMode: string;

  isTpoUser: boolean;

  loadingContacts: boolean;

  activeDeletionIndex: number = -1;

  agentId: number = 0;
  countries: CountryEnumerationItem[] = [];

  filter: string = "Existing";

  drawerOptionsXl: DrawerOptions = {
    size: DrawerSize.XLarge,
    containerWrapperId: 'drawer-wrapper'
  }

  protected agentTypes: AgentType[] = [];
  protected filteredExternalContacts: ExternalContact[] = [];

  protected emailRecipients: string;

  private _loanInfoChangesSubscription: Subscription;

  constructor(private readonly _externalContactsV2Service: ExternalContactsV2Service,
    private readonly _notifyService: NotificationService,
    private readonly _navigationService: NavigationService,
    private readonly _router: Router,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _drawerService: DrawerService,
    injector: Injector,) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.initializeFromContext(context);
      }
    });
  }

  ngOnInit(): void {
    if (this.applicationContext?.application?.applicationId) {
      this.initializeFromContext(this.applicationContext);
    }
    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
      this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onSendEmailDrawerClosed = () => {
  }

  onEmailSendCancelled = () => {
    this._drawerService.hide("sendEmailDrawerForExternalContacts", 200);
  }

  onEmailSentSuccessfully = () => {
    this._drawerService.hide("sendEmailDrawerForExternalContacts", 200);
  }

  mailExternalContact = () => {
    if (this.selectedRows && this.selectedRows.length > 0) {
      const emails = this.selectedRows.filter(ec => !!ec.email && ec.email !== 'null').map(c => c.email);
      this.emailRecipients = emails.join(',');
    }
    this.applicationContextService.sendEmailDialogLaunched();
    this._drawerService.show("sendEmailDrawerForExternalContacts", 200);
  }

  copyExternalContactToClipboard = () => {
    let text = '';
    if (this.selectedRows && this.selectedRows.length > 0) {
      this.selectedRows.forEach((externalContact) => {
        if (externalContact.orgName != null)
          text += externalContact.orgName + "\r\n";

        if (externalContact.firstName != null || externalContact.lastName != null) {
          text += (externalContact.lastName + ", " + externalContact.firstName);

          if (externalContact.agentType) {
            text += " (" + externalContact.agentType?.agentTypeName + ")";
          }

          text += "\r\n";
        }
        if (externalContact.phone != null && externalContact.phone != "")
          text += "P: " + externalContact.phone + "\r\n";

        if (externalContact.email != null)
          text += "E: " + externalContact.email + "\r\n";
        text += "\r\n"
      })
      navigator.clipboard.writeText(text);
      this._notifyService.showInfo("Contact info copied to clipboard", "Info");
    }
  }

  onCancelDeleteClicked = () => {
    this.activeDeletionIndex = -1;
  }

  onDeleteAgentClicked = (index: number) => {
    this.activeDeletionIndex = index;
  }

  onConfirmDeleteClicked = (externalContactId: number) => {
    this.activeDeletionIndex = -1;
    this._externalContactsV2Service.removeExternalContact(this.appId, externalContactId).subscribe({
      next: () => {
        this._notifyService.showSuccess('Contact deleted succesfully', 'Success');
        this.loadExternalContactList();
      }, error: (error) => {
        const fallbackMessage = 'Unable to delete agent';
        const message = error?.message || fallbackMessage;

        this._notifyService.showError(message, 'Error');
      },
    });
  };

  onBasicInfoSaveClicked = (editedContact: EditedExternalContact) => {
    this._spinnerService.show();
    const guid: string = editedContact.externalContact['guid'];
    delete editedContact.externalContact['guid'];
    this.filteredExternalContacts.forEach(contact => {
      delete contact['expanded'];
    });
    editedContact.externalContact.applicationId = this.appId;
    this._externalContactsV2Service.saveExternalContact(this.appId, editedContact.copyToAgent, editedContact.externalContact).subscribe({
      next: (response: ExternalContact) => {
        if (response) {
          const existingOneIndex = this.externalContacts.findIndex(contact => contact['guid'] == guid);
          if (existingOneIndex >= 0) {
            this.externalContacts[existingOneIndex] = editedContact.externalContact;
            this.onFilterChanged();
            this._notifyService.showSuccess('Contact saved succesfully', 'Success');
          }
        }
      },
      error: (err) => {
        const fallbackMessage = 'Unable to save external contacts';
        const message = err?.message || fallbackMessage;
        this._notifyService.showError(message, 'Error');
      }
    }).add(() => this._spinnerService.hide());
  }

  onAssociateClicked = (externalContact: ExternalContact) => {
    if (externalContact.agentId) {
      const self = this;
      Swal.fire({
        title: 'Re-Associate',
        text: 'The current contact information will be replaced with a new contact. Are you sure you want to continue?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, continue!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true
      }).then(function (result: any) {
        if (result.value) {
          self.navigateToAssociate(externalContact);
        }
      });
    } else {
      this.navigateToAssociate(externalContact);
    }
  }

  onExternalContactEmailClicked = (externalContact: ExternalContact) => {
    this.emailRecipients = externalContact.email;
    this.applicationContextService.sendEmailDialogLaunched();
    this._drawerService.show("sendEmailDrawerForExternalContacts", 200);
  }

  onFilterChanged = () => {
    if (this.filter === 'All') {
      this.filteredExternalContacts = [...this.externalContacts];
      return;
    } else {
      this.filteredExternalContacts = this.externalContacts.filter(c => !!c.externalContactId);
    }
  }

  private navigateToAssociate = (externalContact: ExternalContact) => {
    this._router.navigate([`/admin/app-details/${this.appId}/associate-file-contact`], {
      state: {
        agentType: externalContact.agentType
      },
      queryParams: {
      }
    });
  }

  private initializeFromContext = (context: ApplicationContext) => {
    this.appId = context.application.applicationId;
    this.borrowerId = context.application.primaryBorrowerId;
    this.borrowerLastName = context.borrowers[0].lastName;
    this.mailingStreet = context.application.mailingStreet;
    this.isTpoUser = context.isTpo;
    this.countries = context.globalConfig.countries;
    this.agentTypes = context.globalConfig.agentType;
    this.loadExternalContactList();
  }

  private loadExternalContactList = () => {
    this.loadingContacts = true;
    this._externalContactsV2Service.getExternalContacts(this.appId).subscribe({
      next: (response) => {
        this.externalContacts = response;
        this.checkDefaultExternalContactLists();
        this.onFilterChanged();
      },
      error: (error) => {
        const fallbackMessage = 'Unable to load external contacts';
        const message = error?.message || fallbackMessage;
        this._notifyService.showError(message, 'Error');
      }
    }).add(() => this.loadingContacts = false);
  }

  private checkDefaultExternalContactLists = () => {
    this.agentTypes.forEach(agentType => {
      const hasDefaultExternalContact = this.externalContacts.some(contactList => agentType.agentTypeId === contactList.agentTypeId);
      if (!hasDefaultExternalContact) {
        const newDefaultExternalContact = new ExternalContact();
        newDefaultExternalContact.agentType = agentType;
        this.externalContacts.push(newDefaultExternalContact);
      }
    });
    this.externalContacts.forEach(contact => {
      contact['guid'] = uuidv4();
    });
    this.filteredExternalContacts = [...this.externalContacts];
  }
}
