<form #manufacturedHomeDetailsForm="ngForm" novalidate id="manufacturedHomeDetailsForm" name="manufacturedHomeDetailsForm" class="p-3">

    <div class="row mb-3">
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeManufacturerName">Manufacturer Name</label>
            <input class="form-control" name="manufacturedHomeManufacturerName" id="manufacturedHomeManufacturerName"
                [(ngModel)]="subjectProperty.manufacturedHomeManufacturerName" />
        </div>
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeModelIdentifier">Model Identifier</label>
            <input class="form-control" name="manufacturedHomeModelIdentifier" id="manufacturedHomeModelIdentifier"
                [(ngModel)]="subjectProperty.manufacturedHomeModelIdentifier" />
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeManufactureYear">Manufacture Year</label>
            <input class="form-control" numeric [allowNegative]="false" name="manufacturedHomeManufactureYear" 
                id="manufacturedHomeManufactureYear" [(ngModel)]="subjectProperty.manufacturedHomeManufactureYear" />
        </div>
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeConditionDescriptionType">Condition</label>
            <select class="form-select" name="manufacturedHomeConditionDescriptionType" 
                id="manufacturedHomeConditionDescriptionType"
                [(ngModel)]="subjectProperty.manufacturedHomeConditionDescriptionType" >
                <option [ngValue]="option.value" *ngFor="let option of manufacturedHomeConditionTypes">
                {{ option.name }}
                </option>
            </select>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-6">
            <label class="form-label" for="manufacturedHomeWidthType">Home Width Type</label>
            <select class="form-select" name="manufacturedHomeWidthType" id="manufacturedHomeWidthType"
              [(ngModel)]="subjectProperty.manufacturedHomeWidthType">
              <option [ngValue]="null">--Select One--</option>
              <option value="{{option.alternateValue}}" *ngFor="let option of manufacturedHomeWidthTypeOptions">
                {{option.name}}
              </option>
            </select>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeLengthInFeet">Length In Feet</label>
            <input class="form-control" numeric [allowNegative]="false" name="manufacturedHomeLengthInFeet" 
                id="manufacturedHomeLengthInFeet" [(ngModel)]="subjectProperty.manufacturedHomeLengthInFeet" />
        </div>
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeWidthInFeet">Width In Feet</label>
            <input class="form-control" numeric [allowNegative]="false" name="manufacturedHomeWidthInFeet" 
                id="manufacturedHomeWidthInFeet" [(ngModel)]="subjectProperty.manufacturedHomeWidthInFeet" />
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-6">
          <label class="mb-2" for="isManufacturedHomeAttachedToFoundation">Is Attached To Foundation?</label>
          <div class="form-check">
            <input class="form-check-input" name="isManufacturedHomeAttachedToFoundation" type="checkbox" 
                id="isManufacturedHomeAttachedToFoundation" [(ngModel)]="subjectProperty.isManufacturedHomeAttachedToFoundation" />
          </div>
        </div>
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeSerialNumber">Serial Number</label>
            <input class="form-control" name="manufacturedHomeSerialNumber" 
                id="manufacturedHomeSerialNumber" [(ngModel)]="subjectProperty.manufacturedHomeSerialNumber" />
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeHudDataPlateIdentifier">HUD Data Plate Identifier</label>
            <input class="form-control" name="manufacturedHomeHudDataPlateIdentifier" 
                id="manufacturedHomeHudDataPlateIdentifier" 
                [(ngModel)]="subjectProperty.manufacturedHomeHudDataPlateIdentifier" />
        </div>
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeCertificateOfTitleIdentifier">Certificate Of Title Identifier</label>
            <input class="form-control" name="manufacturedHomeCertificateOfTitleIdentifier" 
                id="manufacturedHomeCertificateOfTitleIdentifier" 
                [(ngModel)]="subjectProperty.manufacturedHomeCertificateOfTitleIdentifier" />
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeCertificateOfTitleType">Certificate Of Title Type</label>
            <select class="form-select" name="manufacturedHomeCertificateOfTitleType" 
                id="manufacturedHomeCertificateOfTitleType" (change)="onCertificateOfTitleTypeChanged()"
                [(ngModel)]="subjectProperty.manufacturedHomeCertificateOfTitleType" >
                <option [ngValue]="option.value" *ngFor="let option of certificateOfTitleTypes">
                {{ option.name }}
                </option>
            </select>
        </div>
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeCertificateOfTitleTypeOtherDescription">&nbsp;</label>
            <input class="form-control" name="manufacturedHomeCertificateOfTitleTypeOtherDescription" 
                id="manufacturedHomeCertificateOfTitleTypeOtherDescription" placeholder="Other Description"
                [(ngModel)]="subjectProperty.manufacturedHomeCertificateOfTitleTypeOtherDescription" 
                [disabled]="subjectProperty.manufacturedHomeCertificateOfTitleType != 'Other' "/>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-6">
            <label class="mb-2" for="manufacturedHomeLotOwnershipType">Home Lot Ownership Type</label>
            <select class="form-select" name="manufacturedHomeLotOwnershipType" id="manufacturedHomeLotOwnershipType"
                [(ngModel)]="subjectProperty.manufacturedHomeLotOwnershipType" >
                <option [ngValue]="option.value" *ngFor="let option of lotOwnershipTypes">
                {{ option.name }}
                </option>
            </select>
        </div>
    </div>

    <div class="row mb-3" *ngIf="hasSaveCancelButtons">
        <div class="col-md-12 text-end">
            <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
            <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
                Save
            </button>
        </div>
    </div>
</form>