<form
  #ngForm='ngForm'
  class='modal-body modal-body--scroll'
>
  <ng-container *ngIf='fee; else templateLoadingFee'>
    <div
      class='card mt-2'
      *ngIf='isOddDaysInterest; else nonOddDaysInterest'
    >
      <div class='card-header'>
        <h4 class='card-title'>Odd Days Interest</h4>
      </div>

      <div class='card-body'>
        <odd-days-interest
          (onFeeValueUpdated)='onUpdateOddDaysInterest($event)'
          [fee]='fee'
          [hideTitle]='true'
        ></odd-days-interest>
      </div>
    </div>

    <ng-template #nonOddDaysInterest>
      <div class='row mt-2'>
        <div class="col-md-{{ showBasicInfo ? '12' : 6 }}">
          <div class='card h-100'>
            <div class='card-header'><h4 class='card-title'>Basic Details</h4></div>
            <div class='card-body'>
              <div class='row mb-2'>
                <div class='col-md-6 form-group'>
                  <label
                    [for]="id('hudNumber')"
                    class='control-label'
                  >Hud #</label
                  >
                  <input
                    [id]="id('hudNumber')"
                    type='text'
                    name='hudNumber'
                    class='form-control'
                    [(ngModel)]='fee.hudNumber'
                    (ngModelChange)='onHudNumberChanged()'
                    #hudNumber='ngModel'
                    [class.is-invalid]='
                    hudNumber && hudNumber.touched && (hudNumber.invalid || isExistHud)
                  '
                  />
                  <div
                    class='invalid-feedback'
                    *ngIf='isExistHud'
                  >
                    There is an existing fee with the same HUD#
                  </div>
                </div>
                <div class='col-md-6 form-group'>
                  <label
                    [for]="id('name')"
                    class='control-label'
                  >Name</label
                  >
                  <input
                    [id]="id('name')"
                    type='text'
                    name='name'
                    class='form-control'
                    [(ngModel)]='fee.name'
                  />
                </div>
              </div>
              <div class='row mb-2'>
                <div class='col-md-6 form-group'>
                  <label
                    [for]="id('feeType')"
                    class='control-label'
                  >Fee Type</label
                  >
                  <select
                    [id]="id('fee-type')"
                    name='feeType'
                    class='form-select'
                    [(ngModel)]='fee.feeType'
                  >
                    <option [ngValue]='null'>-- Select One --</option>
                    <option
                      *ngFor='let feeType of feeTypes'
                      [ngValue]='feeType.value'
                    >
                      {{ feeType.name | splitCamelcase }}
                    </option>
                  </select>
                </div>
                <div class='col-md-6 form-group'>
                  <ng-container *ngIf='!showBasicInfo'>
                    <label
                      [for]="id('feePercentOf')"
                      class='control-label'
                    >Fee % Of</label
                    >
                    <select
                      [id]="id('fee-percent-of')"
                      name='feePercentOf'
                      class='form-select'
                      [(ngModel)]='fee.feePercentOf'
                      (change)='onFeePercentOfChanged()'
                    >
                      <option [ngValue]='null'>-- Select One --</option>
                      <option
                        *ngFor='let feePercentOf of feePercentOfs'
                        [ngValue]='feePercentOf.value'
                      >
                        {{ feePercentOf.name | splitCamelcase }}
                      </option>
                    </select>
                  </ng-container>
                </div>
              </div>
              <div class='row mb-2'>
                <div class='col-md-6 form-group'>
                  <label [for]="id('paid-to-type')">Paid to Type</label>
                  <select
                    [id]="id('paid-to-type')"
                    name='paidToType'
                    class='form-select'
                    [(ngModel)]='fee.paidToType'
                    (change)='onPaidToTypeChanged()'
                  >
                    <option [ngValue]='null'>-- Select One --</option>
                    <option
                      *ngFor='let paidToType of paidToTypes'
                      [ngValue]='paidToType.value'
                    >
                      {{ paidToType.name | splitCamelcase }}
                    </option>
                  </select>
                </div>
                <div class='col-md-6 form-group'>
                  <label
                    [for]="id('paidTo')"
                    class='control-label'
                  >Paid to Name</label
                  >
                  <input
                    [id]="id('paid-to')"
                    name='paidTo'
                    class='form-control'
                    type='text'
                    [(ngModel)]='fee.paidTo'
                  />
                </div>
              </div>
              <div class='row mb-4 d-none-if-empty'>
                <div
                  class='col-md-6 form-group'
                  *ngIf='shouldAskForServiceProviderToPayTo && externalContacts?.length'
                >
                  <label [for]="id('service-providers')">Paid to Service Provider</label>
                  <p-dropdown
                    styleClass='w-100'
                    [id]="id('service-providers')"
                    name='serviceProviders'
                    [options]='externalContacts'
                    optionLabel='name'
                    optionValue='value'
                    [(ngModel)]='fee.externalContactId'
                    placeholder='-- Select One --'
                    [virtualScroll]='false'
                    [filter]='true'
                    [showClear]='true'
                  ></p-dropdown>
                </div>
                <div
                  *ngIf='canBeReimbursable'
                  class='col-md-6 form-group'
                >
                  <label
                    [for]="id('is-reimbursable')"
                    class='control-label'
                  >Reimbursable</label
                  >
                  <input
                    [id]="id('is-reimbursable')"
                    type='checkbox'
                    name='isReimbursable'
                    class='form-check-input d-block'
                    [(ngModel)]='fee.isReimbursable'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class='col-md-6'
          *ngIf='!showBasicInfo'
        >
          <div class='card h-100'>
            <div class='card-header'><h4 class='card-title'>Definition</h4></div>
            <div class='card-body'>
              <table class='table border-dashed mb-0'>
                <thead>
                <tr>
                  <th></th>
                  <th *ngIf='isPercentFee'>Percent</th>
                  <th>Dollar</th>
                  <th>Total</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Borrower</td>
                  <td *ngIf='isPercentFee'>
                    <percent-input
                      (ngModelChange)='onPercentChange(FeePayerType.Borrower, $event)'
                      [ngModel]='fee.borrowerFeePercent'
                      [id]="id('borrower-fee-percent')"
                      name='borrowerFeePercent'
                    ></percent-input>
                  </td>
                  <td>
                    <currency-input
                      (change)='onDollarChange(FeePayerType.Borrower)'
                      [(ngModel)]='fee.borrowerFeeDollar'
                      [id]="id('borrower-fee-dollar')"
                      name='borrowerFeeDollar'
                    >
                    </currency-input>
                  </td>
                  <td>
                    <currency-input
                      [id]="id('borrower-total')"
                      name='borrowerTotal'
                      [disabled]='true'
                      [(ngModel)]='fee.calculatedValues.borrowerTotal'
                    ></currency-input>
                  </td>
                </tr>
                <tr>
                  <td>Seller</td>
                  <td *ngIf='isPercentFee'>
                    <percent-input
                      (ngModelChange)='onPercentChange(FeePayerType.Seller, $event)'
                      [ngModel]='fee.sellerFeePercent'
                      [id]="id('seller-fee-percent')"
                      name='sellerFeePercent'
                    ></percent-input>
                  </td>
                  <td>
                    <currency-input
                      (change)='onDollarChange(FeePayerType.Seller)'
                      [(ngModel)]='fee.sellerFeeDollar'
                      [id]="id('seller-fee-dollar')"
                      name='sellerFeeDollar'
                    ></currency-input>
                  </td>
                  <td>
                    <currency-input
                      [id]="id('seller-total')"
                      name='calculatedValues.sellerTotal'
                      [disabled]='true'
                      [(ngModel)]='fee.calculatedValues.sellerTotal'
                    ></currency-input>
                  </td>
                </tr>
                <tr>
                  <td>Lender</td>
                  <td *ngIf='isPercentFee'>
                    <percent-input
                      (ngModelChange)='onPercentChange(FeePayerType.Other, $event)'
                      [ngModel]='fee.lenderFeePercent'
                      [id]="id('lender-fee-percent')"
                      name='lenderFeePercent'
                    ></percent-input>
                  </td>
                  <td>
                    <currency-input
                      (change)='onDollarChange(FeePayerType.Other)'
                      [(ngModel)]='fee.lenderFeeDollar'
                      [id]="id('lender-fee-dollar')"
                      name='lenderFeeDollar'
                    ></currency-input>
                  </td>
                  <td>
                    <currency-input
                      [id]="id('lender-total')"
                      name='calculatedValues.lenderTotal'
                      [disabled]='true'
                      [(ngModel)]='fee.calculatedValues.lenderTotal'
                    ></currency-input>
                  </td>
                </tr>
                <tr>
                  <td>3rd Party</td>
                  <td *ngIf='isPercentFee'>
                    <percent-input
                      (ngModelChange)='onPercentChange(FeePayerType.ThirdParty, $event)'
                      [ngModel]='fee.thirdPartyFeePercent'
                      [id]="id('third-party-fee-percent')"
                      name='thirdPartyFeePercent'
                    ></percent-input>
                  </td>
                  <td>
                    <currency-input
                      (change)='onDollarChange(FeePayerType.ThirdParty)'
                      [(ngModel)]='fee.thirdPartyFeeDollar'
                      [id]="id('third-party-fee-dollar')"
                      name='thirdPartyFeeDollar'
                    >
                    </currency-input>
                  </td>
                  <td>
                    <currency-input
                      [id]="id('third-party-total')"
                      name='calculatedValues.thirdPartyTotal'
                      [disabled]='true'
                      [(ngModel)]='fee.calculatedValues.thirdPartyTotal'
                    ></currency-input>
                  </td>
                </tr>
                <tr>
                  <td [attr.colspan]='isPercentFee ? 2 : 1'>
                    <div class='form-check'>
                      <input
                        type='checkbox'
                        class='form-check-input'
                        [id]="id('is-apr-fee')"
                        name='isAprFee'
                        [(ngModel)]='fee.isAprFee'
                      />
                      <label
                        class='form-check-label'
                        [for]="id('is-apr-fee')"
                      >
                        Is Apr Fee ?
                      </label>
                    </div>
                  </td>
                  <td class='text-end'>
                    <b>Grand Total</b>
                  </td>
                  <td>
                    <currency-input
                      [id]="id('grand-total')"
                      name='grandTotal'
                      [disabled]='true'
                      [(ngModel)]='totalBSLO'
                    ></currency-input>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class='card mt-2'>
        <div class='card-header'><h4 class='card-title'>Fee Amounts</h4></div>
        <div class='card-body'>
          <div class='row mt-4'>
            <table class='table border-dashed mb-0'>
              <thead>
              <tr>
                <th></th>
                <th>Financed</th>
                <th>Prior to Closing</th>
                <th>Paid at Closing</th>
                <th>Paid Outside Closing</th>
                <th>Amount Paid</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Borrower</td>
                <td>
                  <currency-input
                    [id]="id('financed-borrower-amount')"
                    name='financedBorrowerAmount'
                    [allowNegative]='false'
                    [(ngModel)]='fee.financedBorrowerAmount'
                    (change)='updateFee()'
                  ></currency-input>
                </td>
                <td>
                  <currency-input
                    [id]="id('borrower-paid-to-closing')"
                    name='borrowerPaidToClosing'
                    [(ngModel)]='borrowerPaidToClosing'
                    [disabled]='true'
                  ></currency-input>
                </td>
                <td>
                  <currency-input
                    [id]="id('borrower-paid-at-closing')"
                    name='calculatedValues.borrowerPaidAtClosing'
                    [(ngModel)]='fee.calculatedValues.borrowerPaidAtClosing'
                    [disabled]='true'
                  ></currency-input>
                </td>
                <td
                  *ngLet="form.get('paidOutsideClosingBorrowerAmount') as control"
                  class='max-width-input'
                >
                  <currency-input
                    (change)='onPaidOutsideClosingAmountChange(FeePayerType.Borrower)'
                    [(ngModel)]='fee.paidOutsideClosingBorrowerAmount'
                    [errors]='control?.errors'
                    [id]="id('paid-outside-closing-borrower-amount')"
                    name='paidOutsideClosingBorrowerAmount'
                  ></currency-input>
                </td>
                <td>
                  <currency-input
                    [id]="id('borrower-total')"
                    name='calculatedValues.borrowerTotal'
                    [(ngModel)]='fee.calculatedValues.borrowerTotal'
                    [disabled]='true'
                  ></currency-input>
                </td>
              </tr>
              <tr>
                <td>Seller</td>
                <td></td>
                <td></td>
                <td>
                  <currency-input
                    [id]="id('seller-paid-at-closing')"
                    name='calculatedValues.sellerPaidAtClosing'
                    [(ngModel)]='fee.calculatedValues.sellerPaidAtClosing'
                    [disabled]='true'
                  ></currency-input>
                </td>
                <td
                  *ngLet='form.get("paidOutsideClosingSellerAmount") as control'
                  class='max-width-input'
                >
                  <currency-input
                    (change)='onPaidOutsideClosingAmountChange(FeePayerType.Seller)'
                    [(ngModel)]='fee.paidOutsideClosingSellerAmount'
                    [disabled]='!fee.sellerFeeDollar || !fee.borrowerFeeDollar'
                    [errors]='control?.errors'
                    [id]="id('paid-outside-closing-seller-amount')"
                    name='paidOutsideClosingSellerAmount'
                  ></currency-input>
                </td>
                <td>
                  <currency-input
                    [id]="id('seller-total')"
                    name='calculatedValues.sellerTotal'
                    [(ngModel)]='fee.calculatedValues.sellerTotal'
                    [disabled]='true'
                  ></currency-input>
                </td>
              </tr>
              <tr>
                <td>Lender</td>
                <td></td>
                <td></td>
                <td>
                  <currency-input
                    [id]="id('lender-paid-at-closing')"
                    name='calculatedValues.lenderPaidAtClosing'
                    [(ngModel)]='fee.calculatedValues.lenderPaidAtClosing'
                    (change)='updateFee()'
                    [disabled]='true'
                  ></currency-input>
                </td>
                <td
                  *ngLet='form.get("paidOutsideClosingLenderAmount") as control'
                  class='max-width-input'
                >
                  <currency-input
                    (change)='onPaidOutsideClosingAmountChange(FeePayerType.Other)'
                    [(ngModel)]='fee.paidOutsideClosingLenderAmount'
                    [disabled]='!fee.borrowerFeeDollar'
                    [errors]='control?.errors'
                    [id]="id('paid-outside-closing-lender-amount')"
                    name='paidOutsideClosingLenderAmount'
                  ></currency-input>
                </td>
                <td>
                  <currency-input
                    [id]="id('lender-total')"
                    name='calculatedValues.lenderTotal'
                    [(ngModel)]='fee.calculatedValues.lenderTotal'
                    [disabled]='true'
                  ></currency-input>
                </td>
              </tr>
              <tr>
                <td>3rd Party</td>
                <td></td>
                <td></td>
                <td>
                  <currency-input
                    [id]="id('third-party-paid-at-closing')"
                    name='calculatedValues.thirdPartyPaidAtClosing'
                    [(ngModel)]='fee.calculatedValues.thirdPartyPaidAtClosing'
                    (change)='updateFee()'
                    [disabled]='true'
                  ></currency-input>
                </td>
                <td
                  *ngLet='form.get("paidOutsideClosingThirdPartyAmount") as control'
                  class='max-width-input'
                >
                  <currency-input
                    (change)='onPaidOutsideClosingAmountChange(FeePayerType.ThirdParty)'
                    [disabled]='!fee.borrowerFeeDollar'
                    [(ngModel)]='fee.paidOutsideClosingThirdPartyAmount'
                    [errors]='control?.errors'
                    [id]="id('paid-outside-closing-third-party-amount')"
                    name='paidOutsideClosingThirdPartyAmount'
                  ></currency-input>
                </td>
                <td>
                  <currency-input
                    [id]="id('third-party-total')"
                    name='calculatedValues.thirdPartyTotal'
                    [(ngModel)]='fee.calculatedValues.thirdPartyTotal'
                    [disabled]='true'
                  ></currency-input>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td
                  colspan='2'
                  class='text-end'
                >
                  <b>Total Paid By B/S/L/3rd</b>
                </td>
                <td>
                  <currency-input
                    [id]="id('total-bslo')"
                    name='totalBSLO'
                    [(ngModel)]='totalBSLO'
                    [disabled]='true'
                  ></currency-input>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td
                  colspan='2'
                  class='text-end'
                >
                  <b>Total Fee Amount</b>
                </td>
                <td>
                  <currency-input
                    [id]="id('total-fee')"
                    name='calculatedValues.totalFee'
                    [(ngModel)]='fee.calculatedValues.totalFee'
                    [disabled]='true'
                  ></currency-input>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td
                  colspan='2'
                  class='text-end'
                >
                  <b>Total Fee Percent</b>
                </td>
                <td>
                  <percent-input
                    [id]="id('total-fee-percent')"
                    name='calculatedValues.totalFeePercent'
                    [(ngModel)]='fee.calculatedValues.totalFeePercent'
                    [disabled]='true'
                  ></percent-input>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #templateLoadingFee>
    <div class='d-flex justify-content-center align-items-center h-100'>
      <div class='spinner-border text-primary' role='status'>
        <span class='visually-hidden'>Loading...</span>
      </div>
    </div>
  </ng-template>
</form>

<div class="modal-footer w-100">
  <div class="d-flex justify-content-end">
    <button
      type='button'
      class='btn btn-md btn-secondary me-1'
      (click)='onCancelClick()'
    >
      Cancel
    </button>
    <button
      (click)='onSaveClick()'
      [disabled]='isUpdating'
      class='btn btn-md btn-primary'
      type='button'
    >
      Apply
    </button>
  </div>
</div>
