import { Component, Injector, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ApplicationContext, KeyDate, KeyDateConfig, KeyDateGroup } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { KeyDatesService } from 'src/app/services/key-dates.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { KeyDatesHistoryDialogComponent } from './key-dates-history-dialog/key-dates-history-dialog.component';

@Component({
  selector: 'key-dates',
  templateUrl: 'key-dates.component.html',
  styleUrls: ['key-dates.component.scss']
})
export class KeyDatesComponent extends ApplicationContextBoundComponent implements OnInit {

  loanId: number;

  companyId: number;

  loanKeyDatesGroups: KeyDateGroup[] = [];

  loanKeyDates: KeyDate[] = [];

  loadingKeyDates: boolean = true;

  isSavingProcess: boolean;

  userRoleId: number;

  visibleFirstColumn: boolean;

  keyDateGroupEditEnabledStatuses: Map<number, boolean> = new Map<number, boolean>();

  protected isLoanReadOnly: boolean = false;

  private _loanInfoChangesSubscription: Subscription;

  private _contextSubscription: Subscription;

  private _keyDatesSubscription: Subscription;

  private _originalLoanKeyDateGroups: KeyDateGroup[] = [];

  private _updatedKeyDateConfigIds: number[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _keyDatesService: KeyDatesService,
    private readonly _userService: UserService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService
  ) {
    super(injector);
    this._keyDatesSubscription = this.applicationContextService.keyDatesChanges.subscribe(() => {
      if (this.applicationContext?.application) {
        this.initialize(this.applicationContext);
      }
    });

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.initialize(context);
      }
    });
  }

  ngOnInit() {
    this.getUserRoleId();
    this._contextSubscription = this.applicationContextService.context.subscribe((context) => {
      if (context.application) {
        this.initialize(context);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._contextSubscription?.unsubscribe();
    this._loanInfoChangesSubscription?.unsubscribe();
    this._keyDatesSubscription?.unsubscribe();
  }

  onKeyDateChanged = (keyDate: KeyDate) => {
    if (this._updatedKeyDateConfigIds.includes(keyDate.keyDateConfigurationId)) {
      return;
    }
    this._updatedKeyDateConfigIds.push(keyDate.keyDateConfigurationId);
  }

  onEditModeToggled = (groupId: number) => {
    const enabledState = this.keyDateGroupEditEnabledStatuses.get(groupId);
    this.keyDateGroupEditEnabledStatuses.set(groupId, !enabledState);
    if (enabledState) {
      this.saveLoanKeyDates(groupId);
    }
  }

  onCancelEditClicked = (groupId: number) => {
    this.keyDateGroupEditEnabledStatuses.set(groupId, false);
    const originalKeyDateGroup = this._originalLoanKeyDateGroups.find(g => g.keyDateGroupId === groupId);
    if (originalKeyDateGroup) {
      const indexOfGroupToRevert = this.loanKeyDatesGroups.findIndex(g => g.keyDateGroupId === groupId);
      if (indexOfGroupToRevert >= 0) {
        this.loanKeyDatesGroups[indexOfGroupToRevert] = _.cloneDeep(originalKeyDateGroup);
      }
    }
  }

  onShowLoanKeyDatesHistoryClicked = (keyDateId: number) => {
    const modalRef = this._modalService.open(
      KeyDatesHistoryDialogComponent,
      { ...Constants.modalOptions.xlarge, scrollable: false }
    );
    modalRef.componentInstance.title = 'Key Date History';
    modalRef.componentInstance.appId = this.loanId;
    modalRef.componentInstance.keyDateId = keyDateId;
    modalRef.result.then(
      (data) => { },
      (res) => { }
    );
  };

  loadLoanKeyDates = () => {
    this.loadingKeyDates = true;
    this.loanKeyDates = this.applicationContext.applicationKeyDates;
    this.setLoanKeyDates();
    this.setLoanKeyDateGroups();
  };

  setLoanKeyDates = () => {
    let keyDateFound: KeyDate = null;
    this.applicationContext.globalConfig.keyDates.forEach((kD) => {
      keyDateFound = <KeyDate>this.loanKeyDates.find(
        (keyDate) =>
          keyDate.keyDateConfigurationId === kD.keyDateConfigurationId
      );
      if (!keyDateFound) {
        this.loanKeyDates.push({
          keyDateId: null,
          eventDate: null,
          applicationId: this.loanId,
          keyDateConfigurationId: kD.keyDateConfigurationId,
          order: kD.order,
          displayName: kD.displayName,
          editByRole: kD.editByRole,
        });
      } else {
        keyDateFound.order = kD.order;
        keyDateFound.displayName = kD.displayName;
        keyDateFound.editByRole = kD.editByRole;
      }
    });
  };

  setLoanKeyDateGroups = () => {
    this._keyDatesService
      .getKeyDatesGroups(this.companyId)
      .subscribe((groups) => {
        this.loanKeyDatesGroups = [];
        groups.forEach((group: KeyDateGroup) => {
          let keyDateFound: KeyDate = null;
          const newGroup: KeyDateGroup = {
            keyDateGroupId: group.keyDateGroupId,
            keyDateGroupName: group.keyDateGroupName,
            column: group.column,
            order: group.order,
            keyDateConfigurations: [],
          };

          group.keyDateConfigurations.forEach((keyDate) => {
            if (!this.applicationContext.application.channel || !keyDate.enabledChannels || keyDate.enabledChannels.includes(this.applicationContext.application.channel)) {
              keyDateFound = this.loanKeyDates.find(
                (loanKeyDate) =>
                  loanKeyDate.keyDateConfigurationId ===
                  keyDate.keyDateConfigurationId
              );
              if (!keyDateFound) {
                newGroup.keyDateConfigurations.push({
                  displayName: keyDate.displayName,
                  order: keyDate.order,
                  keyDateGroupId: keyDate.keyDateGroupId,
                  editByRole: keyDate.editByRole,
                  keyDateConfigurationId: keyDate.keyDateConfigurationId,
                  applicationId: this.loanId,
                  eventDate: null,
                });
              } else {
                keyDateFound.displayName = keyDate.displayName;
                keyDateFound.order = keyDate.order;
                keyDateFound.keyDateGroupId = keyDate.keyDateGroupId;
                keyDateFound.editByRole = keyDate.editByRole;
                newGroup.keyDateConfigurations.push(<KeyDateConfig>keyDateFound);
              }
            }
          });
          this.loanKeyDatesGroups.push(newGroup);
          this.keyDateGroupEditEnabledStatuses.set(newGroup.keyDateGroupId, false);
        });
        this.visibleFirstColumn = this.loanKeyDatesGroups.some(g => g.column == 1 && g.keyDateConfigurations.length > 0)
        this._originalLoanKeyDateGroups = _.cloneDeep(this.loanKeyDatesGroups);
      }, error => {
        this._notifyService.showError(
          error.message || 'Unable to load key date groups',
          'Load Key Dates'
        );
      })
      .add(() => { this.loadingKeyDates = false; });
  };

  saveLoanKeyDates = (groupId: number) => {
    this.isSavingProcess = true;
    this._spinner.show();
    let params: KeyDateConfig[] = [];

    if (this.loanKeyDatesGroups.length > 0) {
      const groups = _.cloneDeep(this.loanKeyDatesGroups);
      groups.forEach((group) => {
        let groupToSave = group;
        if (group.keyDateGroupId !== groupId) {
          groupToSave = _.clone(this._originalLoanKeyDateGroups.find(g => g.keyDateGroupId === groupToSave.keyDateGroupId));
        }
        groupToSave.keyDateConfigurations.forEach((key) => {
          params.push(key);
        });
      });
    } else {
      params = <Array<KeyDateConfig>>_.cloneDeep(this.loanKeyDates);
    }

    params.forEach((keyDate) => {
      delete keyDate.order;
      delete keyDate.displayName;
      delete keyDate.editByRole;
      if (this._updatedKeyDateConfigIds.includes(keyDate.keyDateConfigurationId)) {
        keyDate['isAutomatedTrigger'] = false;
      }
    });

    this._keyDatesService.postUpsertKeyDates(params, this.loanId).subscribe(
      (result) => {
        if (result) {
          this.loanKeyDates = result;
          this.setLoanKeyDates();
          this.setLoanKeyDateGroups();

          this.applicationContextService.updateKeyDates(result);

          this.isSavingProcess = false;
          this._spinner.hide();
          this._notifyService.showSuccess(
            'Key Date added Successfully',
            'Update Key Dates'
          );
        }
      },
      (error) => {
        this.isSavingProcess = false;
        this._spinner.hide();
        this._notifyService.showError(
          error.message || 'Unable to add Key Date',
          'Update Key Dates'
        );
      }
    );
  };

  private initialize = (context: ApplicationContext) => {
    this.loanId = context.application.applicationId;
    this.companyId = context.application.companyId;
    this.isLoanReadOnly = context.applicationIsReadOnly;
    this.loadLoanKeyDates();
  }

  private getUserRoleId = () => {
    this._userService.getUserPermissions().subscribe((user) => {
      this.userRoleId = user.roleId;
    });
  };
}
