<form id="checkListItemForm" #checkListItemForm="ngForm" name="checkListItemForm" *ngIf="checkListItem">
  <div class="p-3">
    <div class="row">
      <div class="col-sm-6 mb-3">
        <label class="control-label" for="checkListName">Checklist Name</label>
        <input id="checkListName" name="checkListName" class="form-control" [(ngModel)]="checkListItem.checkListName"
          [ngClass]="{'is-invalid': checkListNameInput && checkListNameInput.touched && checkListNameInput.invalid}"
          #checkListNameInput="ngModel" required />
      </div>
      <div class="col-sm-6 mb-3">
        <label class="control-label" for="loanStatus">Loan Status</label>
        <select id="loanStatus" name="loanStatus" class="form-select" [(ngModel)]="checkListItem.loanStatusId">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let status of loanStatuses" [ngValue]="status.loanStatusId">
            {{ status.loanStatusName }}
          </option>
        </select>
      </div>
    </div>
  </div>
</form>
<div class="row p-3">
  <div class="col col-7">
    <div *ngIf="checklistLoading">
      <loading-indicator [customClass]="'no-shadow'"> </loading-indicator>
    </div>
    <div *ngIf="!checklistLoading">
      <div class="d-flex align-items-center justify-content-center">
        <h3 class="flex-grow-1">Checklist Questions</h3>
        <div>
          <button type="button" (click)="expandAll()" class="btn btn-secondary btn-sm me-1">
            Expand All
          </button>
          <button type="button" class="btn btn-secondary btn-sm" (click)="collapseAll()">
            Collapse All
          </button>
        </div>
      </div>
      <div *ngIf="hasError">
        <span class="text-danger">
          Invalid Drop. A question cannot have a question as a child.
        </span>
      </div>
      <p-tree [value]="treeViewData" [draggableNodes]="true" [droppableNodes]="true" draggableScope="checklist"
        droppableScope="checklist" class="w-100 h-100" (onNodeDrop)="onDropOnTreeView($event)">
        <ng-template let-node pTemplate="default">
          {{ node.label }}

          <button type="button" class="btn ms-1" aria-label="Close" *ngIf="!node.data.questionAnswerId"
            (click)="removeNode(node)">
            <i class="fa fa-times text-danger"></i>
          </button>
        </ng-template>
        <ng-template pTemplate="empty">
          <div class="m-5 text-center">
            drag questions here
          </div>
        </ng-template>
      </p-tree>
    </div>
  </div>

  <div class="col col-5">
    <div *ngIf="allQuestionsLoading">
      <loading-indicator [customClass]="'no-shadow'"> </loading-indicator>
    </div>
    <div *ngIf="!allQuestionsLoading">
      <h3>
        Questions
        <button type="button" class="btn btn-primary btn-sm pull-right" (click)="addNewQuestionClicked()">
          <i class="fas fa-plus"></i> Add New
        </button>
      </h3>
      <ng-select
        class="typeFilterSelect"
        name="typeFilter"
        bindLabel="name"
        bindValue="value"
        placeholder="Filter by type"
        [(ngModel)]="selectedTypes"
        [closeOnSelect]="false"
        [multiple]="true"
        [items]="questionTypeOptions"
        (change)="onTypeFilterChanged()">
        <ng-template ng-option-tmp let-item="item">
          <span>{{item.name}}</span>
        </ng-template>
      </ng-select>

      <p-tree [value]="questionNodes" [draggableNodes]="true" [droppableNodes]="false" draggableScope="checklist"
        droppableScope="checklist" scrollHeight="75vh" [filter]="true">
        <ng-template let-node pTemplate="default">
          <div *ngIf="!node.questionType">
            {{ node.label }}
          </div>
          <div *ngIf="node.questionType" class="d-flex align-items-center"> <button type="button"
              class="btn btn-primary p-1 flex-grow-1 text-start" title="Click for show this question answers"
              draggable="true" (click)="onQuestionAnswersClicked(node.data)">
              {{ node.label }} ({{ node.questionType }})
            </button>

            <i class="fa fa-pencil-alt cursor text-primary mx-1" (click)="editQuestionClicked(node.data)"
              title="Edit Question" draggable="false"></i>

            <i class="fa fa-trash-alt cursor text-danger mx-1" (click)="onDeleteQuestionClicked(node.data)"
              title="Delete Question" draggable="false"></i>
          </div>
        </ng-template>
        <ng-template pTemplate="empty">
          <div class="m-5 text-center">
            no questions available.
          </div>
        </ng-template>
      </p-tree>
    </div>
  </div>
</div>

<div class="text-end">
  <button type="button" class="btn btn-secondary me-2" (click)="onClose()">
    <i class="fa fa-close"></i> Cancel
  </button>
  <button type="button" class="btn btn-primary me-2" (click)="save()" [disabled]="isSaving">
    <span class="spinner-border spinner-border-sm" role="status" *ngIf="isSaving"></span>
    Save
  </button>
</div>


<drawer #upsertChecklistQuestionDrawer title="{{ editQuestion?.questionId ? 'Edit' : 'New' }} Question"
  [name]="'upsertChecklistQuestionDrawer'" [templateRef]="upsertChecklistQuestionRef"
  [options]="upsertChecklistQuestionDrawerOptions">
</drawer>
<ng-template #upsertChecklistQuestionRef>
  <upsert-checklist-question #upsertChecklistQuestion [globalConfig]="globalConfig" [companyId]="companyId"
    [question]="editQuestion" (close)="onUpsertQuestionDrawerClose($event)">
  </upsert-checklist-question>
</ng-template>
