import { Component, Input, OnInit } from '@angular/core';
import { CustomField } from 'src/app/modules/pricing/models/pricing/custom-fields.model';
import { PricingCustomFieldListOptionsComponent } from '../pricing-custom-field-list-options/pricing-custom-field-list-options.component';
import { Constants } from 'src/app/services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'pricing-custom-fields',
  templateUrl: './pricing-custom-fields.component.html',
  styleUrls: ['./pricing-custom-fields.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class PricingCustomFieldsComponent implements OnInit {
  @Input() customFields: CustomField[];
  constructor(private readonly _modalService: NgbModal) { }

  ngOnInit(): void {
  }

  openListOptionsModal(customField: CustomField) {
    let modalRef = this._modalService.open(PricingCustomFieldListOptionsComponent, {
      ...Constants.modalOptions.large,
    });
    modalRef.componentInstance.customField = cloneDeep(customField);
    modalRef.result.then(
      (updatedCustomField: CustomField) => {
        customField.options = updatedCustomField.options
      },
      () => { }
    );
  }
}
