<form #loanOriginatorForm="ngForm">
    <div class="grid-layout has-border" *ngIf="mortgage && mortgage.mortgageId != null">
        <div class="grid-header mb-1">
            <div class="grid-header-left">
                <div>Loan Identifiers</div>
            </div>
            <div class="grid-header-right">
                <div class="text-end">
                    <button *ngIf="isMersEnabled && !mortgage.mortgageTerm?.mersMortgageIdentifier"
                        [disabled]="isGeneratingMers" class="btn btn-primary btn-xs grid-header-link text-end me-1"
                        type="button" (click)="generateMERS()">
                        <span *ngIf="isGeneratingMers" class="spinner-border spinner-border-xs me-1" role="status"
                            aria-hidden="true"></span>Generate MERS ID
                    </button>
                    <button class="btn btn-primary btn-xs grid-header-link text-end" type="button"
                        *ngIf="!isReadOnly && !inEditMode" (click)="onRefreshOriginatorInfoClicked()">
                        <i class="fas fa-sync-alt me-1"></i> Refresh </button>
                </div>
            </div>

            <div class="grid-layout">
                <div class="grid-layout-row">
                    <urla-text-input
                        [class]="(isMersEnabled && ['VA', 'FHA', 'USDA'].indexOf(this.mortgage.mortgageTerm?.mortgageAppliedFor) > -1) ? 'col-md-3' : 'col-md-4'"
                        class="col-xs-6" label="Loan Number" [(ngModel)]="mortgage.mortgageTerm.lenderCaseNumber"
                        [name]="'lenderCaseNumber'" [readonly]="isReadOnly || mortgage.companyId == 229" [inEditMode]="inEditMode"
                        [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.mortgageTerm.lenderCaseNumber'"
                        [required]="isRequiredLoanAndCaseNumbers && (urlaFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber']?.required == 'required' ||
                        urlaFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber']?.required == 'requested')">
                    </urla-text-input>
                    <urla-text-input *ngIf="isMersEnabled"
                        [class]="(isMersEnabled && ['VA', 'FHA', 'USDA'].indexOf(this.mortgage.mortgageTerm?.mortgageAppliedFor) > -1) ? 'col-md-3' : 'col-md-4'"
                        class="col-xs-6" label="MERS" [(ngModel)]="mortgage.mortgageTerm.mersMortgageIdentifier"
                        [name]="'mersMortgageIdentifier'" [readonly]="isReadOnly || !isAdmin || mortgage.companyId == 229" [inEditMode]="inEditMode"
                        [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.mortgageTerm.mersMortgageIdentifier'" [required]="urlaFieldsConfig['mortgage.mortgageTerm.mersMortgageIdentifier']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.mortgageTerm.mersMortgageIdentifier']?.required == 'requested'">
                    </urla-text-input>
                    <urla-text-input
                        [class]="(isMersEnabled && ['VA', 'FHA', 'USDA'].indexOf(this.mortgage.mortgageTerm?.mortgageAppliedFor) > -1) ? 'col-md-3' : 'col-md-4'"
                        class="col-xs-6" label="Universal Loan Number"
                        [(ngModel)]="mortgage.mortgageTerm.universalLoanNumber" [name]="'universalLoanNumber'"
                        [readonly]="isReadOnly || mortgage.companyId == 229" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.mortgageTerm.universalLoanNumber'" [required]="isRequiredLoanAndCaseNumbers && (urlaFieldsConfig['mortgage.mortgageTerm.universalLoanNumber']?.required == 'required' ||
                urlaFieldsConfig['mortgage.mortgageTerm.universalLoanNumber']?.required == 'requested')">
                    </urla-text-input>
                    <urla-text-input
                        *ngIf="['VA', 'FHA', 'USDA'].indexOf(this.mortgage.mortgageTerm?.mortgageAppliedFor) > -1"
                        [class]="(isMersEnabled && ['VA', 'FHA', 'USDA'].indexOf(this.mortgage.mortgageTerm?.mortgageAppliedFor) > -1) ? 'col-md-3' : 'col-md-4'"
                        class="col-xs-6" label="Agency Case Number" [(ngModel)]="mortgage.mortgageTerm.agencyCaseNumber"
                        [name]="'agencyCaseNumber'" [readonly]="isReadOnly || mortgage.companyId == 229" [inEditMode]="inEditMode"
                        [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.mortgageTerm.agencyCaseNumber'" [required]="isRequiredLoanAndCaseNumbers && (urlaFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber']?.required == 'required' ||
                urlaFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber']?.required == 'requested')">
                    </urla-text-input>
                </div>
                <div class="grid-layout-row">
                    <urla-text-input class="col-md-6 col-xs-12" label="Lender Loan Number"
                        [(ngModel)]="mortgage.mortgageTerm.lenderLoanNumber" [name]="'lenderLoanNumber'"
                        [readonly]="isReadOnly || mortgage.companyId == 229" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.mortgageTerm.lenderLoanNumber'" [required]="urlaFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber']?.required == 'requested'">
                    </urla-text-input>
                    <urla-text-input class="col-md-6 col-xs-12" label="Closing Agent Order Number"
                        [(ngModel)]="mortgage.mortgageTerm.closingAgentOrderNumber"
                        [name]="'closingAgentOrderNumber'" [readonly]="isReadOnly || mortgage.companyId == 229" [inEditMode]="inEditMode"
                        [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.mortgageTerm.closingAgentOrderNumber'" [required]="urlaFieldsConfig['mortgage.originatorInformation.closingAgentOrderNumber']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.mortgageTerm.closingAgentOrderNumber']?.required == 'requested'">
                    </urla-text-input>
                </div>
            </div>
        </div>

        <div class="grid-header mb-1">
            <div class="grid-header-left">
                <div>Organization Information</div>
            </div>
            <div class="grid-header-right">
            </div>

            <div class="grid-layout">
                <div class="grid-layout-row">
                    <urla-text-input class="col-md-5 col-xs-12" label="Organization Name"
                        [(ngModel)]="mortgage.originatorInformation.organizationName" [name]="'organizationName'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.organizationName'" [required]="urlaFieldsConfig['mortgage.originatorInformation.organizationName']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.originatorInformation.organizationName']?.required == 'requested'">
                    </urla-text-input>
                    <urla-text-input class="col-md-2 col-xs-12" label="Org. NMLS ID#"
                        [(ngModel)]="mortgage.originatorInformation.organizationNmls" [name]="'organizationNmls'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.organizationNmls'" [required]="urlaFieldsConfig['mortgage.originatorInformation.organizationNmls']?.required == 'required' ||
                        urlaFieldsConfig['mortgage.originatorInformation.organizationNmls']?.required == 'requested'">
                    </urla-text-input>
                    <urla-state-dropdown class="col-md-2 col-xs-12"
                      [name]="'organizationStateLicenseState'"
                      [stateValuesAreLowerCase]="false"
                      [(ngModel)]="mortgage.originatorInformation.organizationStateLicenseState"
                      [label]="'Org. License State'"
                      (ngModelChange)="onChangeOrganizationLicenseState($event)"
                      [readonly]="isReadOnly"
                      [inEditMode]="inEditMode"
                      [urlaFieldsConfig]="urlaFieldsConfig"
                      [fieldBeingEdited]="'mortgage.originatorInformation.organizationStateLicenseState'"
                      [required]="urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicenseState']?.required == 'required' ||
                        urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicenseState']?.required == 'requested'">
                    </urla-state-dropdown>

                    <urla-text-input
                        *ngIf='hasStateLicense(mortgage.originatorInformation.organizationStateLicenseState)'
                        class="col-md-3 col-xs-12" label="Org. State License ID#"
                        [(ngModel)]="mortgage.originatorInformation.organizationStateLicense" [name]="'orgStateLicense'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.organizationStateLicense'" [required]="urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicense']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicense']?.required == 'requested'">
                    </urla-text-input>
                    <div *ngIf='!hasStateLicense(mortgage.originatorInformation.organizationStateLicenseState)'
                        class="grid-layout-spacer col-md-4 col-xs-12"></div>
                </div>

                <div class="grid-layout-row"
                    *ngIf="mortgage.mortgageTerm.mortgageAppliedFor === 'VA' || mortgage.mortgageTerm.mortgageAppliedFor === 'FHA' || inEditMode">

                    <urla-text-input class="col-md-3 col-xs-12" label="VA Lender Id"
                        *ngIf="mortgage.mortgageTerm.mortgageAppliedFor === 'VA' || inEditMode"
                        [(ngModel)]="mortgage.originatorInformation.organizationVaLenderId"
                        [name]="'organizationVaLenderId'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
                        [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.organizationVaLenderId'" [required]="urlaFieldsConfig['mortgage.originatorInformation.organizationVaLenderId']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.originatorInformation.organizationVaLenderId']?.required == 'requested'">
                    </urla-text-input>
                    <urla-text-input class="col-md-3 col-xs-12" label="VA Sponsor Id"
                        *ngIf="mortgage.mortgageTerm.mortgageAppliedFor === 'VA' || inEditMode"
                        [(ngModel)]="mortgage.originatorInformation.organizationVaSponsorId"
                        [name]="'organizationVaSponsorId'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
                        [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.organizationVaSponsorId'" [required]="urlaFieldsConfig['mortgage.originatorInformation.organizationVaSponsorId']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.originatorInformation.organizationVaSponsorId']?.required == 'requested'">
                    </urla-text-input>
                    <urla-text-input class="col-md-3 col-xs-12" label="FHA Lender Id"
                        *ngIf="mortgage.mortgageTerm.mortgageAppliedFor === 'FHA' || inEditMode"
                        [(ngModel)]="mortgage.originatorInformation.originatorFhaLenderId" [name]="'fhaLenderId'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.originatorFhaLenderId'" [required]="urlaFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId']?.required == 'requested'">
                    </urla-text-input>
                    <urla-text-input class="col-md-3 col-xs-12" label="FHA Sponsor Id"
                        *ngIf="mortgage.mortgageTerm.mortgageAppliedFor === 'FHA' || inEditMode"
                        [(ngModel)]="mortgage.originatorInformation.originatorFhaSponsorId" [name]="'fhaSponsorId'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.originatorFhaSponsorId'" [required]="urlaFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId']?.required == 'requested'">
                    </urla-text-input>
                    <div class="grid-layout-spacer col-sm-6 col-xs-0"
                        *ngIf="!(mortgage.mortgageTerm.mortgageAppliedFor === 'VA' || mortgage.mortgageTerm.mortgageAppliedFor === 'FHA' || inEditMode)">
                        &nbsp;</div>
                </div>
                <div class="grid-layout-row">
                    <urla-text-input class="col-md-5 col-xs-6" *ngIf="!isUrlaReadonly" label="Organization Address"
                        [(ngModel)]="mortgage.originatorInformation.organizationAddress1"
                        [name]="'organizationAddress1'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
                        [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.organizationAddress1'" [required]="urlaFieldsConfig['mortgage.originatorInformation.organizationAddress1']?.required == 'required' ||
                urlaFieldsConfig['mortgage.originatorInformation.organizationAddress1']?.required == 'requested'">
                    </urla-text-input>
                    <urla-text-input class="col-md-5 col-xs-6" *ngIf="isUrlaReadonly" label="Organization Address"
                        [(ngModel)]="mortgage.originatorInformation.organizationAddress1"
                        [name]="'organizationAddress1'" [readonly]="isReadOnly">
                    </urla-text-input>
                    <urla-digits-input class="col-md-2 col-xs-2" label="Unit #"
                        [(ngModel)]="mortgage.originatorInformation.organizationAddress2" [name]="'unitNo'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.organizationAddress2'" [required]="urlaFieldsConfig['mortgage.originatorInformation.organizationAddress2']?.required == 'required' ||
                urlaFieldsConfig['mortgage.originatorInformation.organizationAddress2']?.required == 'requested'">
                    </urla-digits-input>
                    <urla-text-input class="col-md-2 col-xs-6" label="City"
                        [(ngModel)]="mortgage.originatorInformation.organizationCity" [name]="'organizationCity'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.organizationCity'" [required]="urlaFieldsConfig['mortgage.originatorInformation.organizationCity']?.required == 'required' ||
                urlaFieldsConfig['mortgage.originatorInformation.organizationCity']?.required == 'requested'">
                    </urla-text-input>
                    <urla-dropdown class="col-md-1 col-xs-6" name="housing" [label]="'State'" [options]="states"
                        [(ngModel)]="mortgage.originatorInformation.organizationState" [readonly]="isReadOnly"
                        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.organizationState'" [required]="urlaFieldsConfig['mortgage.originatorInformation.organizationState']?.required == 'required' ||
                urlaFieldsConfig['mortgage.originatorInformation.organizationState']?.required == 'requested'">
                    </urla-dropdown>
                    <urla-text-input class="col-md-2 col-xs-6" label="Zip Code"
                        [(ngModel)]="mortgage.originatorInformation.organizationZipCode" [name]="'organizationZipCode'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.organizationZipCode'" [required]="urlaFieldsConfig['mortgage.originatorInformation.organizationZipCode']?.required == 'required' ||
                urlaFieldsConfig['mortgage.originatorInformation.organizationZipCode']?.required == 'requested'">
                    </urla-text-input>
                </div>
                <div class="grid-layout-row">

                    <urla-text-input class="col-md-6 col-xs-12" label="Underwriter Id"
                        [(ngModel)]="mortgage.originatorInformation.underwriterId " [name]="'underwriterId'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.underwriterId'" [required]="urlaFieldsConfig['mortgage.originatorInformation.underwriterId']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.originatorInformation.underwriterId']?.required == 'requested'">
                    </urla-text-input>
                    <div class="grid-layout-spacer col-md-6 col-xs-12"></div>
                </div>
            </div>
        </div>

        <div class="grid-header mb-1">
            <div class="grid-header-left">
                <div>Originator Information</div>
            </div>
            <div class="grid-header-right">
            </div>

            <div class="grid-layout">
                <div class="grid-layout-row">
                    <urla-text-input
                        class="col-md-{{mortgage.mortgageTerm.mortgageAppliedFor === 'VA' ? '2' : '4'}} col-xs-12"
                        label="Originator Name" [(ngModel)]="mortgage.originatorInformation.name" [name]="'name'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.name'" [required]="urlaFieldsConfig['mortgage.originatorInformation.name']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.originatorInformation.name']?.required == 'requested'">
                    </urla-text-input>
                    <urla-text-input class="col-md-2 col-xs-12" label="NMLS ID#"
                        [(ngModel)]="mortgage.originatorInformation.nmls" [name]="'nmls'" [readonly]="isReadOnly"
                        [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.nmls'" [required]="urlaFieldsConfig['mortgage.originatorInformation.nmls']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.originatorInformation.nmls']?.required == 'requested'">
                    </urla-text-input>
                    <urla-text-input *ngIf='hasStateLicense(mortgage.originatorInformation.stateLicenseState)'
                        class="col-md-2 col-xs-12" label=" State License ID#"
                        [(ngModel)]="mortgage.originatorInformation.stateLicense" [name]="'stateLicense'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.stateLicense'" [required]="urlaFieldsConfig['mortgage.originatorInformation.stateLicense']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.originatorInformation.stateLicense']?.required == 'requested'">
                    </urla-text-input>
                    <div *ngIf='!hasStateLicense(mortgage.originatorInformation.stateLicenseState)'
                      class="grid-layout-spacer col-md-2 col-xs-12"></div>
                    <urla-state-dropdown class="col-md-2 col-xs-12"
                      [name]="'stateLicenseState'"
                      [stateValuesAreLowerCase]="false"
                      [(ngModel)]="mortgage.originatorInformation.stateLicenseState"
                      [label]="'License State'"
                      (ngModelChange)="onChangeStateLicenseState($event)"
                      [readonly]="isReadOnly"
                      [inEditMode]="inEditMode"
                      [urlaFieldsConfig]="urlaFieldsConfig"
                      [fieldBeingEdited]="'mortgage.originatorInformation.stateLicenseState'"
                      [required]="urlaFieldsConfig['mortgage.originatorInformation.stateLicenseState']?.required == 'required' ||
                        urlaFieldsConfig['mortgage.originatorInformation.stateLicenseState']?.required == 'requested'">
                    </urla-state-dropdown>

                    <urla-date-input [readonly]="isReadOnly" class="col-md-2 col-xs-12" [name]="'applicationSignedDate'"
                        [label]="'LO 1003 Signature Date'"
                        [(ngModel)]="mortgage.originatorInformation.applicationSignedDate" [inEditMode]="inEditMode"
                        [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.applicationSignedDate'"
                        [required]="urlaFieldsConfig['mortgage.originatorInformation.applicationSignedDate']?.required == 'required' ||
                    urlaFieldsConfig['mortgage.originatorInformation.applicationSignedDate']?.required == 'requested'"></urla-date-input>
                    <div
                        class="grid-layout-spacer col-md-2 col-xs-12"></div>
                </div>

                <div class="grid-layout-row">
                    <urla-text-input class="col-md-4 col-xs-12" label="E-mail"
                        [(ngModel)]="mortgage.originatorInformation.email" [name]="'email'" [pattern]="emailPattern"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.email'" [required]="urlaFieldsConfig['mortgage.originatorInformation.email']?.required == 'required' ||
                urlaFieldsConfig['mortgage.originatorInformation.email']?.required == 'requested'">
                    </urla-text-input>
                    <urla-text-input class="col-md-2 col-xs-12" label="Phone" [mask]="'(000) 000-0000'"
                        [placeholder]="'(###) ###-####'" [(ngModel)]="mortgage.originatorInformation.phone"
                        [name]="'phone'" [readonly]="isReadOnly" [inEditMode]="inEditMode"
                        [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.phone'" [required]="urlaFieldsConfig['mortgage.originatorInformation.phone']?.required == 'required' ||
                urlaFieldsConfig['mortgage.originatorInformation.phone']?.required == 'requested'">
                    </urla-text-input>
                    <urla-text-input class="col-md-2 col-xs-12" label="Fax" [mask]="'(000) 000-0000'"
                        [placeholder]="'(###) ###-####'" [(ngModel)]="mortgage.originatorInformation.fax" [name]="'fax'"
                        [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
                        [fieldBeingEdited]="'mortgage.originatorInformation.fax'" [required]="urlaFieldsConfig['mortgage.originatorInformation.fax']?.required == 'required' ||
                urlaFieldsConfig['mortgage.originatorInformation.fax']?.required == 'requested'">
                    </urla-text-input>

                    <div class="grid-layout-spacer col-md-4 col-xs-12"></div>
                </div>
            </div>
        </div>
    </div>
</form>
<loading-indicator *ngIf="!mortgage || mortgage.mortgageId == null"
    [loadingMessage]="'Loading originator info...'"></loading-indicator>
