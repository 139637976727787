import { CdkDrag, CdkDragEnd, CdkDragStart, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isEmpty, isNil } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';

import {
  Borrower,
  CategoryOfIncomeEnum,
  Employment,
  EmploymentTypeEnum,
  ExtendedEmployment,
  ExtendedMortgageBorrower,
  FormFreeHistory,
  Income,
  Mortgage,
  MortgageBorrower,
  TwnHistoriesArray,
  TypeOfIncomeEnum,
  VerificationStatus
} from 'src/app/models';
import { ThirdPartyIntegrationProvider } from 'src/app/models/fee/fee.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BorrowerVerificationUserEnrollmentRequest } from 'src/app/models/voa/borrower-verification-user-enrollment-request';
import { ApplicationContextService } from 'src/app/services/application-context.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/internal/operators/finalize';
import { DocumentService } from 'src/app/core/services/document.service';
import { BorrowerVerificationReportType } from 'src/app/models/voa/borrower-verification-document';
import { GenerateBorrowerVerificationReportRequest } from 'src/app/models/voa/generate-borrower-verification-report-request';
import { Constants } from 'src/app/services/constants';
import { LoanService, LoanServicesService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { ErrorMessage } from 'src/app/shared/models/error.model';
import { InviteAllowedProductsDialogComponent } from './invite-allowed-products-dialog/invite-allowed-products-dialog.component';

@Component({
  selector: 'order-voi-voe',
  templateUrl: 'order-voi-voe.component.html',
  styleUrls: ['./order-voi-voe.component.scss'],
})
export class OrderVoiVoeComponent implements OnInit {

  @Input() loanId: number;
  @Input() isLoanReadOnly: boolean = false;
  @Input() borrowers: Borrower[];
  @Input() mortgage: Mortgage;
  @Input() twnHistories: TwnHistoriesArray;
  @Input('twnHistoriesExcludedData') twnHistoriesExcludedData: TwnHistoriesArray;
  @Input() isVoePullDisabled: boolean;
  @Input() orders: FormFreeHistory[];
  @Input() allowedProductOptions: EnumerationItem[] = [];
  @Input() provider: string;

  @Output() prepareOrdersHistoryToView = new EventEmitter<FormFreeHistory[]>();
  @Output() loadHistory = new EventEmitter();
  @Output() refreshedOrder = new EventEmitter();

  isRunningVOI = false;
  repulling = false;
  refreshing = false;

  error: ErrorMessage | null;
  mortgageBorowers: Array<ExtendedMortgageBorrower>;
  digitallySourcedEmployment: any = {};

  daysBackOptions: number[] = [ 30, 60, 90 ];

  constructor(
    private readonly _loanService: LoanService,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _ctxService: ApplicationContextService,
    private readonly _documentService: DocumentService,
    private readonly _modalService: NgbModal,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    if (this.provider == "TWN"){
      this.mortgageBorowers = this.extendMortgageBorrowers(
        this.mortgage.borrowers,
        this.twnHistories
      );
    }
  }

  exited = () => { };

  entered = () => {
    (
      document.querySelector('.history.cdk-drag-placeholder') as HTMLElement
    ).style.display = 'none';
  };

  displayVerifyTypeTitle = (value: string): string => {
    if (value === 'Employment') return 'Employment Only';
    if (value === 'IncomeAndEmployment') return 'Employment + Income';
  };

  displayVerifyTypeFrom = (value: string): string => {
    if (value === 'Active') return 'Current Employer(s)';
    if (value === 'Inactive') return 'Previous Employer(s)';
    if (value === 'Active,Inactive')
      return 'Current + Previous Employer(s)';
  };

  isRunVoiDisabled = () => {
    if (isEmpty(this.mortgageBorowers)) {
      return true;
    }

    const expandedBorrowers = this.mortgageBorowers.filter(
      (borrower) => borrower.shouldAccordionExpanded
    );

    if (isEmpty(expandedBorrowers)) {
      return true;
    }

    return expandedBorrowers.some(
      (borrower) => !borrower.authorizedTheWorkNumber
    );
  };

  dragStarted = (event: CdkDragStart, employment: ExtendedEmployment) => {
    this.digitallySourcedEmployment = employment;
    document.body.style.cursor = 'grabbing';
    document
      .querySelectorAll('.cdk-drop-list[data-allowdrop="true"]')
      .forEach((element) => {
        element.classList.add('can-drop');
      });
  };

  dragEnded = (event: CdkDragEnd) => {
    document.body.style.cursor = 'auto';
    document.querySelectorAll('.cdk-drop-list').forEach((element) => {
      element.classList.remove('can-drop');
    });
  };

  isDropValid = (isMacthed: boolean) => {
    return function (drag: CdkDrag, drop: CdkDropList) {
      return !isMacthed;
    };
  };

  dropEmployment = (targetEmployment: ExtendedEmployment) => {
    targetEmployment.employer = this.digitallySourcedEmployment.employerName;
    targetEmployment.position = this.digitallySourcedEmployment.position;
    targetEmployment.yearsOnJob = this.digitallySourcedEmployment.years;
    targetEmployment.monthsOnJob = this.digitallySourcedEmployment.months;
    targetEmployment.theWorkNumberEmployerCode =
      this.digitallySourcedEmployment.employerCode;
    targetEmployment.startDate =
      this.digitallySourcedEmployment.originalHireDate;
    targetEmployment.endDate = this.digitallySourcedEmployment.terminationDate;
    targetEmployment.address = this.digitallySourcedEmployment.address;
    targetEmployment.duration = this.digitallySourcedEmployment.duration;
    targetEmployment.employmentType =
      this.digitallySourcedEmployment.employmentStatus === 'Active' ? EmploymentTypeEnum.CurrentEmployer : EmploymentTypeEnum.FormerEmployer;
    targetEmployment.verificationStatus = VerificationStatus.Verified;
    targetEmployment.incomes = [];

    this.addIncomesToEmployment(targetEmployment);
    this.saveMortgage();
  };

  addNewEmployment = (borrower: MortgageBorrower) => {
    let employment = new Employment();
    employment.address = this.digitallySourcedEmployment.address;
    employment.addressId = 0;
    employment.borrowerId = borrower.borrowerId;
    employment.companyId = this.mortgage.companyId;
    employment.employmentId = 0;
    employment.employer = this.digitallySourcedEmployment.employerName;
    employment.employerEmail = '';
    employment.employerPhone = '';
    employment.employmentType =
      this.digitallySourcedEmployment.employmentStatus === 'Active'
        ? EmploymentTypeEnum.CurrentEmployer
        : EmploymentTypeEnum.FormerEmployer;
    employment.endDate = this.digitallySourcedEmployment.terminationDate;
    employment.fromCreditReport = null;
    employment.incomes = [];
    employment.verificationStatus = VerificationStatus.Verified;
    employment.isPrimary = null;
    employment.monthsOnJob = this.digitallySourcedEmployment.months;
    employment.position = this.digitallySourcedEmployment.position;
    employment.selfEmployed = null;
    employment.specialRelationshipType = null;
    employment.startDate = this.digitallySourcedEmployment.originalHireDate;
    employment.theWorkNumberEmployerCode =
      this.digitallySourcedEmployment.employerCode;
    employment.yearsInLineOfWork = null;
    employment.yearsOnJob = this.digitallySourcedEmployment.years;

    this.addIncomesToEmployment(employment);
    borrower.employments.push(employment);
    this.saveMortgage();
  };

  getMonthlyIncomeByType = (incomes: Array<Income>, type: TypeOfIncomeEnum) => {
    const income = incomes.find((x) => x.typeOfIncome === type);
    return income?.monthlyIncome;
  };

  calculateMonthlyIncome = (value: number) => {
    return value && Math.floor((value / 12) * 100) / 100;
  };

  runVoi = () => {
    this.isRunningVOI = true;
    this.error = null;

    const expandedBorrowersForms = this.mortgageBorowers
      .filter((borrower) => borrower.shouldAccordionExpanded)
      .map((borrower) => ({
        ...borrower.form
      }));

    if (isEmpty(expandedBorrowersForms)) {
      this.error = new ErrorMessage(
        'Unable to run VOI!',
        'Please select at least one borrower to run VOI/VOE request.'
      );
      this.isRunningVOI = false;
      return;
    }

    this._spinner.show();
    this._loanServicesService.runVoi(expandedBorrowersForms).subscribe({
      next: (res) => {
        if (res?.length > 0) {
          this._notificationService.showSuccess(
            'VOI/VOE request submitted successfully.',
            'Success!'
          );
        }

        res?.forEach((history) => {
          this.twnHistoriesExcludedData.push(history);
          this.twnHistories.push(history);
        });

        this.mortgageBorowers = this.extendMortgageBorrowers(
          this.mortgage.borrowers,
          this.twnHistories
        );
      },
      error: (error) => {
        this._notificationService.showError(
          error?.message || "Couldn't process borrower(s).",
          'Error!'
        );
      }
    }).add(() => {
      this.isRunningVOI = false;
      this._spinner.hide();
      this._ctxService.applicationTrackingStatusesChanged();
    });
  };

  private newIncome = (employment, value, typeOfIncome: TypeOfIncomeEnum) => {
    return {
      borrowerId: employment.borrowerId,
      businessType: null,
      categoryOfIncome: CategoryOfIncomeEnum.Employment,
      companyId: employment.companyId,
      monthlyIncome: value / 12,
      typeOfIncome: typeOfIncome,
    };
  };

  private addIncomesToEmployment = (employment) => {
    const income = this.digitallySourcedEmployment.incomes
      ?.sort((a, b) => (a.year > b.year ? -1 : 1))
      .find((_) => _);

    if (!income) return;

    if (income.base > 0) {
      const inc = this.newIncome(employment, income.base, TypeOfIncomeEnum.Base);
      employment.incomes.push(inc);
    }
    if (income.bonus > 0) {
      const inc = this.newIncome(employment, income.bonus, TypeOfIncomeEnum.Bonus);
      employment.incomes.push(inc);
    }
    if (income.commission > 0) {
      const inc = this.newIncome(employment, income.comission, TypeOfIncomeEnum.Commissions);
      employment.incomes.push(inc);
    }
    if (income.other > 0) {
      const inc = this.newIncome(employment, income.other, TypeOfIncomeEnum.OtherTypesOfIncome);
      employment.incomes.push(inc);
    }
    if (income.overtime > 0) {
      const inc = this.newIncome(employment, income.overtime, TypeOfIncomeEnum.Overtime);
      employment.incomes.push(inc);
    }
  };

  private findBorrowerFromTwnHistory = (
    twnHistory,
    borrower: ExtendedMortgageBorrower
  ) => {
    return twnHistory.find(
      (historyItem) =>
        isNil(historyItem.error) &&
        !isEmpty(historyItem?.transactions[0]) &&
        !isEmpty(historyItem?.transactions[0].employee.socialSecurityNumber) &&
        !isEmpty(borrower.socialSecNum) &&
        historyItem?.transactions[0].employee.socialSecurityNumber.replace(
          /-/g,
          ''
        ) == borrower.socialSecNum.replace(/-/g, '')
    );
  };

  private setDigitallySourcedEmplyement = (transaction, years, months) => {
    const {
      employer: { employerCode, name },
      employee: {
        employmentPositionTitle,
        status: { code },
      },
      annualCompensation,
      baseCompensation,
    } = transaction;

    return {
      employerWithStatus: `${name} Employer (${[
        1, 2, 3, 5, 6, 9, 10, 11, 13, 14, 17, 18, 19, 42, 43, 52, 53, 54, 58,
      ].includes(Number(code))
        ? 'Active'
        : 'Inactive'
        })`,
      originalHireDate: transaction.employee.originalHireDate,
      terminationDate: transaction.employee.terminationDate,
      address: transaction.employer.address,
      employmentStatus: [
        1, 2, 3, 5, 6, 9, 10, 11, 13, 14, 17, 18, 19, 42, 43, 52, 53, 54, 58,
      ].includes(Number(code))
        ? 'Active'
        : 'Inactive',
      isMatched: false,
      position: employmentPositionTitle,
      duration: years + 'yrs ' + months + 'mos',
      employerCode: employerCode,
      employerName: name,
      years: years,
      months: months,
      incomes: annualCompensation,
      baseComp: baseCompensation,
    };
  };

  private parseTransactions = (
    borrower: ExtendedMortgageBorrower,
    borrowerFromHistory
  ) => {
    borrowerFromHistory.transactions.forEach((transaction) => {
      const {
        employer: { employerCode, name },
        employee: { monthsInService, employmentPositionTitle },
      } = transaction;

      const years = Math.floor(monthsInService / 12) || 0;
      const months = (monthsInService % 12) || 0;

      const digitallySourcedEmployment = this.setDigitallySourcedEmplyement(
        transaction,
        years,
        months
      );

      const index = borrower.employments.findIndex(
        (employment) =>
          employment.employer == name &&
          employment.position == employmentPositionTitle &&
          employment.yearsOnJob == years &&
          employment.monthsOnJob == months &&
          employment.verificationStatus === VerificationStatus.Verified &&
          employment.theWorkNumberEmployerCode == employerCode
      );

      if (index === -1) {
        borrower.digitallySourcedEmployments[
          borrower.digitallySourcedEmployments.findIndex((item) => !item)
        ] = digitallySourcedEmployment;
        return;
      }

      borrower.employments = borrower.employments.filter(
        (b) => b.verificationStatus === VerificationStatus.Verified
      );

      digitallySourcedEmployment.isMatched = true;
      borrower.digitallySourcedEmployments[index] = digitallySourcedEmployment;
    });
  };

  private setFormData = (
    applicationId: number,
    borrower: ExtendedMortgageBorrower
  ) => {
    return {
      applicationId: applicationId,
      borrowerContactId: borrower.contactId,
      salaryKey: null,
      employerName: null,
      employerCode: null,
      alternateEmployeeId: null,
      requestType: 'IncomeAndEmployment',
      employeeStatusFilter: 'Active,Inactive',
    };
  };

  private extendMortgageBorrowers = (
    mortgageBorrowers: Array<MortgageBorrower>,
    twnHistories: TwnHistoriesArray
  ): Array<ExtendedMortgageBorrower> => {
    const result: Array<ExtendedMortgageBorrower> = [];

    mortgageBorrowers.forEach((borrower: ExtendedMortgageBorrower) => {
      borrower.last4Ssn = borrower.socialSecNum
        ? borrower.socialSecNum.substring(borrower.socialSecNum.length - 4)
        : '';

      borrower.employments.forEach((employment) => {
        employment.duration =
          (employment.yearsOnJob || 0) +
          Math.floor((employment.monthsOnJob || 0) / 12) +
          'yrs ' +
          ((employment.monthsOnJob || 0) % 12) +
          'mos';
      });

      const borrowerFromHistory = this.findBorrowerFromTwnHistory(
        twnHistories,
        borrower
      );

      borrower.digitallySourcedEmployments = [];
      if (!isNil(borrowerFromHistory)) {
        borrower.shouldAccordionExpanded = true;
        const newEmptyArr =
          borrower.employments.length < borrowerFromHistory.transactions.length
            ? borrowerFromHistory.transactions.length
            : borrower.employments.length;
        borrower.digitallySourcedEmployments = Array(newEmptyArr);

        this.parseTransactions(borrower, borrowerFromHistory);
      }

      if (isEmpty(borrower.form)) {
        borrower.form = this.setFormData(this.loanId, borrower);
      }

      if (borrower.shouldAccordionExpanded) {
        return result.push(borrower);
      }

      borrower.shouldAccordionExpanded = false;
      result.push(borrower);
    });

    return result;
  };

  private saveMortgage = () => {
    this._spinner.show();
    this._loanService.addMortgage(this.mortgage).subscribe({
      next: (res) => {
        this.mortgage = res;
        this.mortgageBorowers = this.extendMortgageBorrowers(
          this.mortgage.borrowers,
          this.twnHistories
        );

        this._notificationService.showSuccess(
          'Mortgage saved successfully.',
          'Success!'
        );

        this._loanServicesService.getTwnHistories(this.loanId, true).subscribe({
          next: (res) => {
            this.twnHistoriesExcludedData = res;
          },
          error: (error) => {
            this._notificationService.showError(
              error?.message || 'Unable to get updated TWN history.',
              'Error!'
            );
          }
        }).add(() => {
          this._spinner.hide();
        });
      },
      error: (error) => {
        this._spinner.hide();
        this._notificationService.showError(
          error?.message || 'Unable to save mortgage.',
          'Error!'
        );
      }
    });
  }

  private reloadLoanAfterRefresh = () => {
    this._spinner.show();
    this._ctxService.updateLoanInfo(this.loanId).subscribe({
      next: (ctx) => {
        this._spinner.hide();
      },
      error: (err) => {
        this._spinner.hide();
        this._notificationService.showError(err?.message || "Errors have been encountered when updating loan","Error");
      }
    })
  }

  private reloadHistory = (isExtended?: boolean) => {
    this._loanServicesService.getFormFreeHistory(this.loanId).subscribe(
      (history) => {
        this._notificationService.showSuccess(
          'History reloaded successfully',
          'VOA Loan Service'
        );
        this.prepareOrdersHistoryToView.emit(history || []);
        if (isExtended) {
          this.loadHistory.emit();
        }
      },
      ({ error }) => {
        this._notificationService.showError(
          error ? error.message : 'Unable to reaload history',
          'VOA Loan Service'
        );
      }
    );
  };

  inviteBorrower = (order: FormFreeHistory) => {

    const modalRef = this._modalService.open(InviteAllowedProductsDialogComponent, { ...Constants.modalOptions.medium, scrollable: false });
    modalRef.componentInstance.allowedProductOptions = this.allowedProductOptions.filter(po => ["Employment","Income"].includes(po.value));
    modalRef.result.then((selectedProductOptions: EnumerationItem[]) => {

      order['inviting'] = true;

      let req = new BorrowerVerificationUserEnrollmentRequest();
      req.firstName = order.firstName;
      req.lastName = order.lastName;
      req.last4Ssn = order.last4Ssn;
      req.email = order.email;
      req.applicationId = this.loanId;
      req.borrowerId = order.borrowerId;
      req.daysBack = order.daysBack;
      req.phoneNumber = order.phoneNumber;
      req.allowedProducts = selectedProductOptions.map(ap => ap.value);
      req.integrationProvider = ThirdPartyIntegrationProvider.Truv;

      this._loanServicesService.inviteBorrowerToVoa(req)
        .subscribe({
          next: (res) => {
            order['inviting'] = false;

            if (res.success) {
              this._notificationService.showSuccess(
                'Borrower invited successfully',
                'VOI/VOE Loan Service'
              );

              order.formFreeHistoryId = res.borrowerVerificationOrderId;
            } else {
              this._notificationService.showError(res.errorMessage, "Error occurred inviting borrower")
            }
          },
          error: (err) => {
            order['inviting'] = false;

            this._notificationService.showError(
              err?.message || err?.error || 'Unable to invite borrower',
              'VOA Loan Service'
            );
          }
        });

    }, err => { });
  }

  refreshOrder = (order: FormFreeHistory) => {
    this.refreshing = true;
    this._loanServicesService
      .refreshOrder(order.transactionId, order.daysBack)
      .subscribe({
        next: () => {
          this.refreshing = false;

          this.refreshedOrder.emit();

          this._notificationService.showSuccess(
            'Order refreshed successfully',
            'VOI/VOE Loan Service'
          );

          this.reloadLoanAfterRefresh();
        },
        error: (e) => {
          this.refreshing = false;

          this._notificationService.showError(
            e ? e.message : 'Unable to refresh order',
            'VOI/VOE Loan Service'
          );
        }
      });
  };

  processOrderEvents = (order: FormFreeHistory) => {
    this.refreshing = true;
    this._loanServicesService
      .processVerificationOrderEvents(order.formFreeHistoryId)
      .subscribe({
        next: () => {
          this.refreshing = false;

          this.refreshedOrder.emit();

          this._notificationService.showSuccess(
            'Events processed successfully',
            'VOI/VOE Loan Service'
          );

          this.reloadLoanAfterRefresh();
        },
        error: (e) => {
          this.refreshing = false;

          this._notificationService.showError(
            e ? e.message : 'Unable to process order events',
            'VOI/VOE Loan Service'
          );
        }
      });
  };

  generateReport = (order: FormFreeHistory) => {
    let req = new GenerateBorrowerVerificationReportRequest();
    req.applicationId = this.loanId;
    req.borrowerId = order.borrowerId;
    req.daysBack = order.daysBack;
    req.thirdPartyOrderId = order.accountChekOrderId;
    req.reportType = BorrowerVerificationReportType.IncomeAndEmployment;

    this.repulling = true;
    this._loanServicesService
      .generatePdfReport(order.formFreeHistoryId, req)
      .pipe(
        finalize(() => {
          this.repulling = false;
        })
      )
      .subscribe(
        () => {
          this._notificationService.showSuccess(
            'Report repulled successfully',
            'VOI/E Loan Service'
          );
          this.reloadHistory(true);
        },
        ({ error }) => {
          this._notificationService.showError(
            error ? error.message : 'Unable to repull report',
            'VOI/E Loan Service'
          );
        }
      );

  };

  repullReport = (accountChekOrderId: string, reportId: string) => {
    this.repulling = true;
    this._loanServicesService
      .repullPdfReport(accountChekOrderId, reportId)
      .pipe(
        finalize(() => {
          this.repulling = false;
        })
      )
      .subscribe(
        (res: string) => {
          this._notificationService.showSuccess(
            'Report repulled successfully',
            'VOA Loan Service'
          );
          this.reloadHistory(true);
        },
        ({ error }) => {
          this._notificationService.showError(
            error ? error.message : 'Unable to repull report',
            'VOA Loan Service'
          );
        }
      );
  };

  getReport = (transactionId: string) => {
    this._loanServicesService.getLatestReport(transactionId).subscribe(
      (res) => {
        if (!res.reportData) return console.log('Report file is empty');
        if (res.reportData) {
          const fileUrl = this._documentService.convertBase64IntoPdf(
            res.reportData
          );
          window.open(fileUrl);
        }
      },
      ({ error }) => {
        this._notificationService.showError(
          error ? error.message : 'Unable to get latest report',
          'VOI/E Loan Service'
        );
      }
    );
  };
}
