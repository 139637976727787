import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { BorrowerCounselingEvent, MortgageBorrower } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'qa-counseling-events',
  templateUrl: 'qa-counseling-events.component.html',
  styleUrls: ['./qa-counseling-events.component.scss'],
  viewProviders: [formViewProvider]
})
export class QuickApplyCounselingEventsComponent implements OnInit {

  @Output()
  attemptedToDeleteEvent: EventEmitter<BorrowerCounselingEvent> = new EventEmitter<BorrowerCounselingEvent>();

  @Input()
  borrower: MortgageBorrower;

  @Input()
  event: BorrowerCounselingEvent;

  @Input()
  index: number;

  @Input()
  borrowerCounselingFormatTypes: EnumerationItem[] = [];

  constructor() { }

  ngOnInit() { }

  onDeleteClicked = () => {
    this.attemptedToDeleteEvent.emit(this.event);
  }
}