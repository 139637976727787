<form>
  <div class="form-input" [ngClass]="
      {
        'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
        'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
        'bg-transparent' : readonly
      }">

    <div class="d-flex">
      <div class="d-flex flex-column flex-grow-1">
        <span class="input-label" style="display: inline;" title="">
          <span class="input-label-overflow">{{label}}</span>
        </span>
          <address-autocomplete-input
            #model='ngModel'
            (addressChange)='onChangeEmployerAddress($event)'
            [(ngModel)]='value'
            [addressLookupOptions]='googlePlacesOptions'
            [autocompleteDisabled]='isRetired'
            [disabled]='disabled'
            [ngClass]="{
              'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
              'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required'
            }"
            [placeholder]="placeholder ? placeholder : ''"
            [readonly]='readonly || isRetired'
            [required]='required'
            inputClass='input-element'
            name='{{name}}'
          ></address-autocomplete-input>
      </div>

      <div class="d-flex flex-column" style="margin-top:2px; margin-left: 8px">
        <div style="display: grid;" class="ms-4">
          <label
            *ngIf='showIsRetiredCheckbox'
            style='float: right; text-transform: none;'
          >
            <input
              (change)='onIsRetiredCheckChanged($event)'
              [checked]='isRetired'
              [disabled]='readonly'
              class='mx-1'
              name='isRetired_{{name}}'
              type='checkbox'
            />
            Is Retired
          </label>

          <label
            *ngIf='showIsPrimaryCheckbox'
            style='float: right; text-transform: none;'
          >
            <input
              (change)='onIsPrimaryCheckChanged($event)'
              [checked]='isPrimary'
              [disabled]='readonly'
              class='mx-1'
              name='isPrimary{{name}}'
              type='checkbox'
            />
            Is Primary
          </label>

          <button style="max-width: 90px; min-width: 90px;"
              class="btn btn-xs btn-soft-primary pb-0"
              (click)="onSwitchEmploymentTypeClicked()"
              [disabled]='readonly'>
              <b>Make {{isCurrent ? 'Former' : 'Current'}}</b>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
