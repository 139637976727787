<ng-container *ngIf="!isLoading && companyExtendedModel; else loadingContent">
  <div class="fieldset-title">Main Point of Contact - Platform administration & security</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">First Name *</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="First Name"
          [(ngModel)]="companyExtendedModel.mainPOCFirstName">
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Last Name</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Last Name"
          [(ngModel)]="companyExtendedModel.mainPOCLastName">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Title</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Title" [(ngModel)]="companyExtendedModel.mainPOCTitle">
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Phone Number *</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [mask]="'(000) 000-0000'" [placeholder]="'(###) ###-####'"
          placeholder="Phone Number" [(ngModel)]="companyExtendedModel.mainPOCWorkPhone">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Mobile Number</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [mask]="'(000) 000-0000'" [placeholder]="'(###) ###-####'"
          placeholder="Mobile Number" [(ngModel)]="companyExtendedModel.mainPOCMobilePhone">
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">NMLS# *</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="NMLS#" [(ngModel)]="companyExtendedModel.mainPOCNmls">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">FHA Lender Id</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" name="fhaLenderId" maxlength="10" [(ngModel)]="companyExtendedModel.companyModel.fhaLenderId" />
      </div>

      <div class="col-md-2 text-end">
        <label class="custom-control-label">FHA Sponsor Id</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" name="fhaSponsorId" [(ngModel)]="companyExtendedModel.companyModel.fhaSponsorId" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">VA Lender Id</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" name="vaLenderId" maxlength="10" [(ngModel)]="companyExtendedModel.companyModel.vaLenderId" />
      </div>

      <div class="col-md-2 text-end">
        <label class="custom-control-label">VA Sponsor Id</label>
      </div>
      <div class="col-md-4">
        <input class="form-control" name="vaSponsorId" [(ngModel)]="companyExtendedModel.companyModel.vaSponsorId" />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Email *</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Email" [(ngModel)]="companyExtendedModel.mainPOCEmail">
      </div>
      <div class="col-md-6"></div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Address</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Address" [(ngModel)]="companyExtendedModel.mainPOCAddress">
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">City</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="City" [(ngModel)]="companyExtendedModel.mainPOCCity">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">State</label>
      </div>
      <div class="col-md-4">
        <select class="form-control" [(ngModel)]="companyExtendedModel.mainPOCState">
          <option [ngValue]=""></option>
          <option *ngFor="let state of states" [ngValue]="state.code">
            {{ state.name }}
          </option>
        </select>
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Zip Code</label>
      </div>
      <div class="col-md-4">
        <zip-code-input [(ngModel)]="companyExtendedModel.mainPOCZipCode" [name]="'subjectPropertyZip'"
          [required]="false" [disabled]="false" [readonly]="false"
          (selectionChanged)="onZipCodeRelatedInfoChanged($event, '')"></zip-code-input>
      </div>
    </div>
  </div>

  <div class="fieldset-title mt-5">Company Information</div>
  <div class="pt-3">
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Legal Name *</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Legal Name"
          [(ngModel)]="companyExtendedModel.companyLegalName">
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Company DBA Name</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Company DBA Name"
          [(ngModel)]="companyExtendedModel.companyDBAName">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Federal Tax Id</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [(ngModel)]="companyExtendedModel.federalTaxId">
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Business Classification *</label>
      </div>
      <div class="col-md-4">
        <select class="form-control" [(ngModel)]="companyExtendedModel.businessType">
          <option [ngValue]="">-- Select One --</option>
          <option value="1">Sole Properietor</option>
          <option value="2">Partnership</option>
          <option value="3">Corporation</option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Location Type</label>
      </div>
      <div class="col-md-4">
        <select class="form-control" [(ngModel)]="companyExtendedModel.locationType">
          <option [ngValue]="">-- Select One --</option>
          <option value="1">Commercial Office</option>
          <option value="2">Shared Office</option>
          <option value="3">Virtual/Home office</option>
        </select>
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Phone Number *</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [mask]="'(000) 000-0000'" [placeholder]="'(###) ###-####'"
          [(ngModel)]="companyExtendedModel.phoneNumber">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Email *</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Email" [(ngModel)]="companyExtendedModel.email">
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Fax Number</label>
      </div>
      <div class="col-md-4">
        <input type="text" class="form-control" [mask]="'(000) 000-0000'" [placeholder]="'(###) ###-####'"
          [(ngModel)]="companyExtendedModel.faxNumber">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Company Type</label>
      </div>
      <div class="col-md-4">
        <select class="form-control" [(ngModel)]="companyExtendedModel.companyType">
          <option [ngValue]="">-- Select One --</option>
          <option value="1">Mortgage Broker</option>
          <option value="2">Mortgage Banker</option>
          <option value="3">Bank</option>
          <option value="4">Credit Union</option>
          <option value="5">Lender</option>
          <option value="6">Others</option>

          <option *ngIf="isSunLivingHack" value="7">Joint Venture</option>
          <option *ngIf="isSunLivingHack" value="8">Internal</option>
          <option *ngIf="isSunLivingHack" value="9">Ambassador</option>
        </select>
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">How Long In Business</label>
      </div>
      <div class="col-md-4">
        <select class="form-control" [(ngModel)]="companyExtendedModel.yearsOld">
          <option [ngValue]="">-- Select One --</option>
          <option value="0-1 year">less than a year</option>
          <option value="1-5 years">1-5 years</option>
          <option value="5-10 years">5-10 years</option>
          <option value="10+">10+</option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-2 text-end">
        <label class="custom-control-label"># Of Loan Officers</label>
      </div>
      <div class="col-md-4">
        <input type="number" class="form-control" placeholder="# Of Loan Officers"
          [(ngModel)]="companyExtendedModel.numberOfLoanOfficers">
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label"># Of Loans Under Management</label>
      </div>
      <div class="col-md-4">
        <input type="number" class="form-control" placeholder="# Of Loans Under Management"
          [(ngModel)]="companyExtendedModel.numberOfLoansUnderManagement">
      </div>
    </div>
  </div>
  <div class="text-end">
    <div class="col-md-12 my-1">
      <hr />
    </div>
    <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm"></span> Saving
      </span>
    </button>
  </div>
</ng-container>
<ng-template #loadingContent>
  <loading-indicator loadingMessage="Loading Company Profile..."
    [customClass]="'init-loading-indicator'"></loading-indicator>
</ng-template>
