import { Component, Injector, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContext, Mortgage, UserPermissions } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { PricingScenario } from './models/pricing/pricing-scenario.model';
import { PricingService } from './services/pricing.service';
import { Subscription } from 'rxjs';
import { PricingUtils } from './pricing-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/services/constants';
import { PricingScenarioChangesDialogComponent } from './components/pricing-scenario-changes-dialog/pricing-scenario-changes-dialog.component';
import { ScenarioComparisonSelectDialogComponent } from './components/scenario-comparison-select-dialog/scenario-comparison-select-dialog.component';
import { ScenarioComparisonWorksheetDialogComponent } from './components/scenario-comparison-worksheet-dialog/scenario-comparison-worksheet-dialog.component';
import { PricingRateCreditApplicationMethod } from './models/pricing/rate.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';

@Component({
  selector: 'pricing',
  templateUrl: 'pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})

export class PricingComponent extends ApplicationContextBoundComponent implements OnInit {

  mortgage: Mortgage;

  isTpo: boolean = false;

  isAmortizationVisible: boolean = false;

  amortizationType: string = '';

  applicationId: number;

  pricingScenarios: PricingScenario[] = [];

  tab: 'pricingDetails' | 'pricingHistory' | 'pricingScenarios' | 'amortization' = 'pricingDetails';

  protected testLenderpriceUrl: SafeResourceUrl;

  protected userPermissions: UserPermissions;

  private _loanInfoChangesSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _pricingService: PricingService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _localStorageService: LocalStorageService,
    private readonly _environment: EnvironmentService) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(context => {
      if (context.application) {
        this.initialize(context);
      }
    });
  }

  ngOnInit() {
    this.userPermissions = this.applicationContext.userPermissions;
    if (this.applicationContext.application) {
      this.initialize(this.applicationContext);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onScenariosChanged = (e: any) => {
    this.getScenarios();
  }

  onApplyScenarioToLoanClicked = (scenario: PricingScenario) => {
    this._pricingService.getMortgagePricingRequest(this.mortgage.mortgageId).subscribe(productSearchRequest => {
      const changes = PricingUtils.findMortgageModelChanges(scenario.pricingRequestPayload, productSearchRequest.request);

      const modalRef = this._modalService.open(
        PricingScenarioChangesDialogComponent,
        Constants.modalOptions.large
      );
      modalRef.componentInstance.changes = changes;
      modalRef.componentInstance.hasLenderCredit = scenario.adjustedPrice > 100;
      modalRef.result.then(
        (selectedCreditMethod: PricingRateCreditApplicationMethod) => {
          this._spinnerService.show();
          this._pricingService.applyScenarioToLoan(this.applicationId, scenario.pricingScenarioId,
            selectedCreditMethod || PricingRateCreditApplicationMethod.DoNotApplyCredit)
            .subscribe({
              next: (result) => {
                this.applicationContextService.reloadApplicationAndMortgagePostAction(this.applicationId).subscribe();
              }, error: err => {
                this._notifyService.showError(err.error || "An error occurred while applying scenario to loan.", "Error!");
              }
            }).add(() => this._spinnerService.hide());
        },
        () => { }
      );
    })
  }

  onLoanComparisonClicked = () => {
    const modalRef = this._modalService.open(ScenarioComparisonSelectDialogComponent, { ...Constants.modalOptions.large, scrollable: false });
    modalRef.componentInstance.scenarios = this.pricingScenarios;
    modalRef.result.then((selections: PricingScenario[]) => {
      const modalRef = this._modalService.open(ScenarioComparisonWorksheetDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.scenarios = selections;
      modalRef.componentInstance.appId = this.applicationId;
      modalRef.result.then(() => {
      }, err => { });
    },
      () => { }
    );
  }

  private initialize = (context: ApplicationContext) => {

    const authData = this._localStorageService.authorizationData;

    const lenderPriceTestUrl = `${window.location.protocol}//${window.location.host}/lenderprice-mock?appId=${context.application.applicationId}&token=${authData.token}&baseUrl=${this._environment.apiInfo.apiBaseUrl}`;
    this.testLenderpriceUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(lenderPriceTestUrl);

    this.mortgage = context.currentMortgage;
    this.isTpo = context.isTpo;
    this.applicationId = context.application?.applicationId;
    if (this.applicationId) {
      this.getScenarios();
    }
  }

  setAmortizationTabVisibility(isVisible: boolean) {
    this.isAmortizationVisible = isVisible;
  }

  setAmortizationType(amortizationType: string) {
    this.amortizationType = amortizationType;
  }

  private getScenarios = () => {
    this._pricingService.getPricingScenarios(this.applicationContext.application.applicationId).subscribe(scenarios => {
      this.pricingScenarios = scenarios;
    });
  }
}
