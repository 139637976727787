import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from '../core/services/environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class JobApiService {
  constructor(private readonly _environment: EnvironmentService,
    private readonly _http: HttpClient) { }

  convert = (file: File, format: string) => {
    const url = this._environment.apiInfo.jobApiBaseUrl + `api/video/convert/${format}`;
    var formData = new FormData();
    if (file) {
      formData.append("file", file);
    }

    const headers = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    const options: any = {
      headers: headers,
      observe: 'response',
      responseType: 'blob' as 'blob'
    }

    return this._http.post(url, formData, options);
  }
}
