<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-university me-2"></i> Asset Verification
        </h4>
      </div>
      <div class="card-body">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#orderVoa" role="tab" aria-selected="true">
              Order VOA
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#voaHistory" role="tab" aria-selected="false">
              VOA History
            </a>
          </li>
          <li class="nav-item" *ngIf="isImportAssetsTabVisible">
            <a class="nav-link" data-bs-toggle="tab" href="#voaAssets" role="tab" aria-selected="false">
              Import Assets
            </a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <ng-template [ngIf]="loadingData" [ngIfElse]="loading">
            <loading-indicator></loading-indicator>
          </ng-template>
          <ng-template #loading>
            <div class="tab-pane p-3 active" id="orderVoa" role="tabpanel">
              <voa-order [loanId]="loanId" [orders]="orders" (loadHistory)="loadHistory($event)"
                (prepareOrdersHistoryToView)="prepareOrdersHistoryToView($event)" (refreshedOrder)="refreshedOrder()">
              </voa-order>
            </div>
            <div class="tab-pane p-3" id="voaHistory" role="tabpanel">
              <voa-history [orderHistory]="orderHistory" (getSelectedReportIds)="getSelectedReportIds($event)"></voa-history>
            </div>
            <div class="tab-pane p-3" id="voaAssets" role="tabpanel" *ngIf="isImportAssetsTabVisible">
              <voa-import-assets [loanId]="loanId" [accountChekOrderId]="accountChekOrderId" [reportId]="reportId"
                [selectedBorrowerFromHistory]="selectedBorrowerFromHistory">
              </voa-import-assets>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
