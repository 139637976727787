<div class="grid-layout has-border">
    <div class="grid-header">
        <div class="grid-header-left">
            <div> CREDIT DETAILS </div>
        </div>
        <div class="grid-header-right">
        </div>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'L. Seller Credits'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="isReadOnly || inEditMode || isRefinance" class="col-md-4 col-xs-4" name="sellerPaidClosingCostsAmount"
            [(ngModel)]="mortgage.transactionDetail.sellerPaidClosingCostsAmount"
            (change)="sellerCreditChange()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'M. Other Credits'">
        </urla-grid-cell>
        <urla-currency-input class="col-md-4 col-xs-4" name="totalOtherCredit"
            [(ngModel)]="mortgage.calculatedStats.totalOtherCredit" [readonly]="true">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <div class="grid-layout-spacer col-md-1 col-xs-1">&nbsp;</div>
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-7 col-xs-7" [label]="'Lender Credit (if any)'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="isReadOnly" class="col-md-4 col-xs-4" name="lenderCredit"
          [(ngModel)]="mortgage.calculatedStats.lenderCredit" [readonly]="inEditMode || application?.channel == ChannelEnum.Wholesale"
            (change)="lenderCreditChange()">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row" *ngFor="let credit of mortgage.calculatedStats.purchaseCredits">
        <div class="grid-layout-spacer col-md-1 col-xs-1">&nbsp;</div>
        <urla-dropdown class="col-md-7 col-xs-7" [options]="purchaseCreditTypes" name="purchaseCreditType"
            [(ngModel)]="credit.purchaseCreditType" [readonly]="true">
        </urla-dropdown>
        <urla-currency-input [readonly]="isReadOnly" class="col-md-4 col-xs-4" [label]="'Housing'" name="purchaseCreditAmount"
            [(ngModel)]="credit.purchaseCreditAmount" [readonly]="true">
        </urla-currency-input>
    </div>
    <div class="grid-layout-row">
        <urla-grid-cell [disabled]="isReadOnly" class="col-md-8 col-xs-8" [label]="'N. TOTAL CREDITS (Total of L and M)'">
        </urla-grid-cell>
        <urla-currency-input [readonly]="isReadOnly" class="col-md-4 col-xs-4" name="totalCredit" [(ngModel)]="mortgage.calculatedStats.totalCredit"
            [readonly]="true">
        </urla-currency-input>
    </div>
</div>
