import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {DataService} from '../core/services/data.service';
import {LocalStorageService} from '../core/services/local-storage.service';
import {splitCamelCaseAndCapitals, toProperCase} from '../core/services/string-utils';
import {Utils} from '../core/services/utils';
import {MilitaryStatus, VerificationStatus} from '../models';
import {EnumItem} from '../models/enum-item.model';
import {FeeTypeEnum} from '../models/fee/fee-type.enum';
import {PricingVendor} from '../models/pricing/pricing-vendor';
import {EnumerationItem} from '../models/simple-enum-item.model';
import {WidgetType} from '../modules/dashboardv2/models/widget-config.model';
import {AccessLevel} from '../modules/dialer/models/dial-list.model';
import {AwsRegion} from '../modules/email-configuration/models';
import {EventType} from '../modules/events/models/event.model';
import {PayPeriod} from '../modules/leads/models/lead-employment.model';
import {Operator} from '../models/expressions/operator.model';

export interface CountryEnumerationItem {
  name: string;
  alpha2: string;
  alpha3: string;
  countryCode: string;
  iso_3166_2: string;
  region: string;
  subRegion: string;
  intermediateRegion: string;
  regionCode: string;
  subRegionCode: string;
  intermediateRegionCode: string;
}

const countries = [
  {
    name: 'United States',
    value: 'us',
    areaCode: '+1'
  },
  {
    name: 'Spain',
    value: 'es',
    areaCode: '+34'
  },
  {
    name: 'Germany',
    value: 'de',
    areaCode: '+49'
  },
  {
    name: 'South Korea',
    value: 'kr',
    areaCode: '+82'
  },
  {
    name: 'France',
    value: 'fr',
    areaCode: '+33 '
  },
  {
    name: 'Brazil',
    value: 'br',
    areaCode: '+55'
  },
  {
    name: 'Russia',
    value: 'ru',
    areaCode: '+7'
  },
  {
    name: 'China',
    value: 'cn',
    areaCode: '+86'
  }
];

const industryAffiliations = [
  {
    name: 'Federal Deposit Insurance Corporation',
    value: 'Fdic',
  },
  {
    name: 'National Credit Union Administration',
    value: 'Ncua',
  },
  {
    name: 'Equal Housing Lender',
    value: 'Ehl',
  },
  {
    name: 'Equal Housing Opportunity',
    value: 'Elo',
  },
  {
    name: 'Mortgage Bankers Association',
    value: 'Mba',
  }
];

function enumerationItemsFromEnum(
  enumObj: Record<string, string>,
): EnumerationItem<string>[] {
  return Object.values(enumObj).map(v => new EnumerationItem(
    toProperCase(splitCamelCaseAndCapitals(v)),
    v,
  ));
}

const verificationStatuses: EnumerationItem[]
  = enumerationItemsFromEnum(VerificationStatus);

const noOfUnits: EnumerationItem[] = [
  new EnumerationItem("1", "OneUnit"),
  new EnumerationItem("2", "TwoUnits"),
  new EnumerationItem("3", "ThreeUnits"),
  new EnumerationItem("4", "FourUnits")
]

const kpiTypes: EnumerationItem[] = [
  new EnumerationItem("Leads", "Leads"),
  new EnumerationItem("Applications", "Applications"),
  new EnumerationItem("Lead CreditPulls", "LeadCreditPulls"),
  new EnumerationItem("Lead Conversions", "LeadConversions"),
  new EnumerationItem("Application Credit Pulls", "ApplicationCreditPulls"),
  new EnumerationItem("Custom", "Custom"),
];

const groupByTypes: EnumerationItem[] = [
  new EnumerationItem("Branch", "Branch"),
  new EnumerationItem("Loan Purpose", "LoanPurpose"),
  new EnumerationItem("Loan Type", "LoanType"),
  new EnumerationItem("Loan Officer", "LoanOfficer"),
  new EnumerationItem("Lead Source", "LeadSource"),
  new EnumerationItem("State", "State"),
  new EnumerationItem("Status", "Status")
];

const communicationKpiTypes: EnumerationItem[] = [
  new EnumerationItem("Emails", "CommunicationEmails"),
  new EnumerationItem("SMS", "CommunicationSMS"),
  new EnumerationItem("Voice", "CommunicationVoice")
];

const docPrepTypes: EnumerationItem[] = [
  new EnumerationItem("General Warranty Deed", "GeneralWarrantyDeed"),
  new EnumerationItem("Special Warranty Deed", "SpecialWarrantyDeed"),
  new EnumerationItem("Mineral Rights", "MineralRights"),
  new EnumerationItem("Disclosure Of Lenders Council", "DisclosureOfLendersCouncil"),
  new EnumerationItem("Invoice", "Invoice"),
  new EnumerationItem("Renewal And Extension Exhibit", "RenewalAndExtensionExhibit"),
];

const docPrepPacketTypes: EnumerationItem[] = [
  new EnumerationItem("Purchase Package", "PurchasePackage"),
  new EnumerationItem("Refinance Package", "RefinancePackage"),
];

const documentDeliveryMethods: EnumerationItem[] = [
  new EnumerationItem("Courier Delivery Service", "CourierDeliveryService"),
  new EnumerationItem("Electronic Delivery", "ElectronicDelivery"),
  new EnumerationItem("In Person", "InPerson"),
  new EnumerationItem("Other", "Other"),
  new EnumerationItem("USPS First Class Mail ", "USPSFirstClassMail"),
];

const feeTypes: EnumerationItem[] = [
  new EnumerationItem('City Property Taxes', FeeTypeEnum.CityPropertyTaxes),
  new EnumerationItem('City Property Taxes Reserve', FeeTypeEnum.CityPropertyTaxesReserve),
  new EnumerationItem('County Property Taxes', FeeTypeEnum.CountyPropertyTaxes),
  new EnumerationItem('County Property Taxe sReserve', FeeTypeEnum.CountyPropertyTaxesReserve),
  new EnumerationItem('Mud Tax', FeeTypeEnum.MudTax),
  new EnumerationItem('Mud Tax Reserve', FeeTypeEnum.MudTaxReserve),
  new EnumerationItem('Village Town School Tax', FeeTypeEnum.VillageTownSchoolTax),
  new EnumerationItem('Village Town School Tax Reserve', FeeTypeEnum.VillageTownSchoolTaxReserve),
  new EnumerationItem('Homeowners Insurance', FeeTypeEnum.HomeownersInsurance),
  new EnumerationItem('Homeowners Insurance Reserve', FeeTypeEnum.HomeownersInsuranceReserve),
  new EnumerationItem('Mortgage Insurance', FeeTypeEnum.MortgageInsurance),
  new EnumerationItem('Mortgage Insurance Reserve', FeeTypeEnum.MortgageInsuranceReserve),
  new EnumerationItem('Flood Insurance', FeeTypeEnum.FloodInsurance),
  new EnumerationItem('Wind Hail Insurance', FeeTypeEnum.WindHailInsurance),
  new EnumerationItem('Hoa Dues', FeeTypeEnum.HoaDues),
  new EnumerationItem('Aggregate Accounting Adjustment', FeeTypeEnum.AggregateAccountingAdjustment),
  new EnumerationItem('Odd Days Interest', FeeTypeEnum.OddDaysInterest),
  new EnumerationItem(
    'Buyer Agent Real Estate Commission',
    FeeTypeEnum.BuyerAgentRealEstateCommission
  ),
  new EnumerationItem(
    'Seller Agent Real Estate Commission',
    FeeTypeEnum.SellerAgentRealEstateCommission
  ),
  new EnumerationItem('Real Estate Commission Total', FeeTypeEnum.RealEstateCommissionTotal),
  new EnumerationItem('UFMIP', FeeTypeEnum.Ufmip),
  new EnumerationItem('Va Funding Fee', FeeTypeEnum.VaFundingFee),
  new EnumerationItem('Discount Fee', FeeTypeEnum.DiscountFee),
  new EnumerationItem('USDA Guarantee Fee', FeeTypeEnum.UsdaGuaranteeFee),
  new EnumerationItem('Borough Property Tax', FeeTypeEnum.BoroughPropertyTax),
  new EnumerationItem('Borough Property Tax Reserve', FeeTypeEnum.BoroughPropertyTaxReserve),
  new EnumerationItem('Condominium Association Dues', FeeTypeEnum.CondominiumAssociationDues),
  new EnumerationItem(
    'Condominium Association Dues Reserve',
    FeeTypeEnum.CondominiumAssociationDuesReserve
  ),
  new EnumerationItem(
    'Condominium Association Special Assessment',
    FeeTypeEnum.CondominiumAssociationSpecialAssessment
  ),
  new EnumerationItem(
    'Condominium Association Special Assessment Reserve',
    FeeTypeEnum.CondominiumAssociationSpecialAssessmentReserve
  ),
  new EnumerationItem('Cooperative Association Dues', FeeTypeEnum.CooperativeAssociationDues),
  new EnumerationItem(
    'Cooperative Association Dues Reserve',
    FeeTypeEnum.CooperativeAssociationDuesReserve
  ),
  new EnumerationItem(
    'Cooperative Association Special Assessment',
    FeeTypeEnum.CooperativeAssociationSpecialAssessment
  ),
  new EnumerationItem(
    'Cooperative Association Special Assessment Reserve',
    FeeTypeEnum.CooperativeAssociationSpecialAssessmentReserve
  ),
  new EnumerationItem('District Property Tax', FeeTypeEnum.DistrictPropertyTax),
  new EnumerationItem('District Property Tax Reserve', FeeTypeEnum.DistrictPropertyTaxReserve),
  new EnumerationItem('Earthquake Insurance Premium', FeeTypeEnum.EarthquakeInsurancePremium),
  new EnumerationItem(
    'Earthquake Insurance Premium Reserve',
    FeeTypeEnum.EarthquakeInsurancePremiumReserve
  ),
  new EnumerationItem('Flood Insurance Premium', FeeTypeEnum.FloodInsurancePremium),
  new EnumerationItem('Flood Insurance Premium Reserve', FeeTypeEnum.FloodInsurancePremiumReserve),
  new EnumerationItem('Hail Insurance Premium', FeeTypeEnum.HailInsurancePremium),
  new EnumerationItem('Hail Insurance Premium Reserve', FeeTypeEnum.HailInsurancePremiumReserve),
  new EnumerationItem('Hazard Insurance Premium', FeeTypeEnum.HazardInsurancePremium),
  new EnumerationItem(
    'Hazar dInsurance Premium Reserve',
    FeeTypeEnum.HazardInsurancePremiumReserve
  ),
  new EnumerationItem('Homeowners Association Dues', FeeTypeEnum.HomeownersAssociationDues),
  new EnumerationItem(
    'Homeowners Association Dues Reserve',
    FeeTypeEnum.omeownersAssociationDuesReserve
  ),
  new EnumerationItem(
    'Homeowners Association Special Assessment',
    FeeTypeEnum.HomeownersAssociationSpecialAssessment
  ),
  new EnumerationItem(
    'Homeowners Association Special Assessment Reserve',
    FeeTypeEnum.HomeownersAssociationSpecialAssessmentReserve
  ),
  new EnumerationItem('School Property Tax', FeeTypeEnum.SchoolPropertyTax),
  new EnumerationItem('School Property Tax Reserve', FeeTypeEnum.SchoolPropertyTaxReserve),
  new EnumerationItem('State Property Tax', FeeTypeEnum.StatePropertyTax),
  new EnumerationItem('State Property Tax Reserve', FeeTypeEnum.StatePropertyTaxReserve),
  new EnumerationItem('Town Property Tax', FeeTypeEnum.TownPropertyTax),
  new EnumerationItem('Town Property Tax Reserve', FeeTypeEnum.TownPropertyTaxReserve),
  new EnumerationItem('Village Property Tax', FeeTypeEnum.VillagePropertyTax),
  new EnumerationItem('Village Property Tax Reserve', FeeTypeEnum.VillagePropertyTaxReserve),
  new EnumerationItem('Volcano Insurance Premium', FeeTypeEnum.VolcanoInsurancePremium),
  new EnumerationItem(
    'Volcano Insurance Premium Reserve',
    FeeTypeEnum.VolcanoInsurancePremiumReserve
  ),
  new EnumerationItem('Wind And Storm Insurance Premium', FeeTypeEnum.WindAndStormInsurancePremium),
  new EnumerationItem(
    'Wind And Storm Insurance Premium Reserve',
    FeeTypeEnum.WindAndStormInsurancePremiumReserve
  ),
  new EnumerationItem('Application Fee', FeeTypeEnum.ApplicationFee),
  new EnumerationItem('Appraisal Desk Review Fee', FeeTypeEnum.AppraisalDeskReviewFee),
  new EnumerationItem('Appraisal Fee', FeeTypeEnum.AppraisalFee),
  new EnumerationItem('Appraisal Field Review Fee', FeeTypeEnum.AppraisalFieldReviewFee),
  new EnumerationItem(
    'Appraisal Management Company Fee',
    FeeTypeEnum.AppraisalManagementCompanyFee
  ),
  new EnumerationItem('Asbestos Inspection Fee', FeeTypeEnum.AsbestosInspectionFee),
  new EnumerationItem('Assignment Preparation Fee', FeeTypeEnum.AssignmentPreparationFee),
  new EnumerationItem('Assumption Fee', FeeTypeEnum.AssumptionFee),
  new EnumerationItem('Attorney Fee', FeeTypeEnum.AttorneyFee),
  new EnumerationItem('Automated Underwriting Fee', FeeTypeEnum.AutomatedUnderwritingFee),
  new EnumerationItem('AVM Fee', FeeTypeEnum.AVMFee),
  new EnumerationItem('Bankruptcy Monitoring Fee', FeeTypeEnum.BankruptcyMonitoringFee),
  new EnumerationItem('Bond Fee', FeeTypeEnum.BondFee),
  new EnumerationItem('Bond Review Fee', FeeTypeEnum.BondReviewFee),
  new EnumerationItem('Certification Fee', FeeTypeEnum.CertificationFee),
  new EnumerationItem(
    'Chosen Interest Rate Credit Or Charge Total',
    FeeTypeEnum.ChosenInterestRateCreditOrChargeTotal
  ),
  new EnumerationItem('Commitment Fee', FeeTypeEnum.CommitmentFee),
  new EnumerationItem('Copy Or Fax Fee', FeeTypeEnum.CopyOrFaxFee),
  new EnumerationItem('Courier Fee', FeeTypeEnum.CourierFee),
  new EnumerationItem(
    'Credit Disability Insurance Premium',
    FeeTypeEnum.CreditDisabilityInsurancePremium
  ),
  new EnumerationItem('Credit Life Insurance Premium', FeeTypeEnum.CreditLifeInsurancePremium),
  new EnumerationItem(
    'Credit Property Insurance Premium',
    FeeTypeEnum.CreditPropertyInsurancePremium
  ),
  new EnumerationItem('Credit Report Fee', FeeTypeEnum.CreditReportFee),
  new EnumerationItem(
    'Credit Unemployment Insurance Premium',
    FeeTypeEnum.CreditUnemploymentInsurancePremium
  ),
  new EnumerationItem(
    'Debt Cancellation Insurance Premium',
    FeeTypeEnum.DebtCancellationInsurancePremium
  ),
  new EnumerationItem(
    'Debt Suspension Insurance Premium',
    FeeTypeEnum.DebtSuspensionInsurancePremium
  ),
  new EnumerationItem('Deed Preparation Fee', FeeTypeEnum.DeedPreparationFee),
  new EnumerationItem('Disaster Inspection Fee', FeeTypeEnum.DisasterInspectionFee),
  new EnumerationItem('Discount On Repairs Fee', FeeTypeEnum.DiscountOnRepairsFee),
  new EnumerationItem('Documentary Stamp Fee', FeeTypeEnum.DocumentaryStampFee),
  new EnumerationItem('Document Preparation Fee', FeeTypeEnum.DocumentPreparationFee),
  new EnumerationItem('Down Payment Protection Fee', FeeTypeEnum.DownPaymentProtectionFee),
  new EnumerationItem('Dry Wall Inspection Fee', FeeTypeEnum.DryWallInspectionFee),
  new EnumerationItem('Electrical Inspection Fee', FeeTypeEnum.ElectricalInspectionFee),
  new EnumerationItem('Electronic Document DeliveryFee', FeeTypeEnum.ElectronicDocumentDeliveryFee),
  new EnumerationItem('Environmental Inspection Fee', FeeTypeEnum.EnvironmentalInspectionFee),
  new EnumerationItem('Escrow Holdback Fee', FeeTypeEnum.EscrowHoldbackFee),
  new EnumerationItem('Escrow Service Fee', FeeTypeEnum.EscrowServiceFee),
  new EnumerationItem('Escrow Waiver Fee', FeeTypeEnum.EscrowWaiverFee),
  new EnumerationItem('Filing Fee', FeeTypeEnum.FilingFee),
  new EnumerationItem('Flood Certification', FeeTypeEnum.FloodCertification),
  new EnumerationItem('Foundation Inspection Fee', FeeTypeEnum.FoundationInspectionFee),
  new EnumerationItem('Heating Cooling Inspection Fee', FeeTypeEnum.HeatingCoolingInspectionFee),
  new EnumerationItem(
    'High Cost Mortgage Counseling Fee',
    FeeTypeEnum.HighCostMortgageCounselingFee
  ),
  new EnumerationItem('Home Inspection Fee', FeeTypeEnum.HomeInspectionFee),
  new EnumerationItem(
    'Homeowners Association Service Fee',
    FeeTypeEnum.HomeownersAssociationServiceFee
  ),
  new EnumerationItem('Home Warranty Fee', FeeTypeEnum.HomeWarrantyFee),
  new EnumerationItem('Item 203K Consultant Fee', FeeTypeEnum.Item203KConsultantFee),
  new EnumerationItem('Item 203K Discount On Repairs', FeeTypeEnum.Item203KDiscountOnRepairs),
  new EnumerationItem('Item 203K Inspection Fee', FeeTypeEnum.Item203KInspectionFee),
  new EnumerationItem('Item 203K Permits', FeeTypeEnum.Item203KPermits),
  new EnumerationItem(
    'Item 203K Supplemental Origination Fee',
    FeeTypeEnum.Item203KSupplementalOriginationFee
  ),
  new EnumerationItem('Item 203K Title Update', FeeTypeEnum.Item203KTitleUpdate),
  new EnumerationItem('Lead Inspection Fee', FeeTypeEnum.LeadInspectionFee),
  new EnumerationItem('Lenders Attorney Fee', FeeTypeEnum.LendersAttorneyFee),
  new EnumerationItem('Loan Discount Points', FeeTypeEnum.LoanDiscountPoints),
  new EnumerationItem('Loan Level Price Adjustment', FeeTypeEnum.LoanLevelPriceAdjustment),
  new EnumerationItem('Loan Origination Fee', FeeTypeEnum.LoanOriginationFee),
  new EnumerationItem('Loan Originator Compensation', FeeTypeEnum.LoanOriginatorCompensation),
  new EnumerationItem('Manual Underwriting Fee', FeeTypeEnum.ManualUnderwritingFee),
  new EnumerationItem(
    'Manufactured Housing Inspection Fee',
    FeeTypeEnum.ManufacturedHousingInspectionFee
  ),
  new EnumerationItem(
    'Manufactured Housing Processing Fee',
    FeeTypeEnum.ManufacturedHousingProcessingFee
  ),
  new EnumerationItem('MERS Registration Fee', FeeTypeEnum.MERSRegistrationFee),
  new EnumerationItem('Modification Fee', FeeTypeEnum.ModificationFee),
  new EnumerationItem('Mold Inspection Fee', FeeTypeEnum.MoldInspectionFee),
  new EnumerationItem('Mortgage Broker Fee', FeeTypeEnum.MortgageBrokerFee),
  new EnumerationItem(
    'Mortgage Surcharge County Or Parish',
    FeeTypeEnum.MortgageSurchargeCountyOrParish
  ),
  new EnumerationItem('Mortgage Surcharge Municipal', FeeTypeEnum.MortgageSurchargeMunicipal),
  new EnumerationItem('Mortgage Surcharge State', FeeTypeEnum.MortgageSurchargeState),
  new EnumerationItem('Mortgage Tax Credit Service Fee', FeeTypeEnum.MortgageTaxCreditServiceFee),
  new EnumerationItem('Multiple Loans Closing Fee', FeeTypeEnum.MultipleLoansClosingFee),
  new EnumerationItem('Municipal Lien Certificate Fee', FeeTypeEnum.MunicipalLienCertificateFee),
  new EnumerationItem('Notary Fee', FeeTypeEnum.NotaryFee),
  new EnumerationItem(
    'OtherSupplementalPropertyInsurancePremium',
    FeeTypeEnum.OtherSupplementalPropertyInsurancePremium
  ),
  new EnumerationItem(
    'OtherSupplementalPropertyInsurancePremiumReserve',
    FeeTypeEnum.OtherSupplementalPropertyInsurancePremiumReserve
  ),
  new EnumerationItem('Our Origination Charge Total', FeeTypeEnum.OurOriginationChargeTotal),
  new EnumerationItem('Payoff Request Fee', FeeTypeEnum.PayoffRequestFee),
  new EnumerationItem('Pest Inspection Fee', FeeTypeEnum.PestInspectionFee),
  new EnumerationItem('Plumbing Inspection Fee', FeeTypeEnum.PlumbingInspectionFee),
  new EnumerationItem(
    'Power Of Attorney Preparation Fee',
    FeeTypeEnum.PowerOfAttorneyPreparationFee
  ),
  new EnumerationItem('Power Of Attorney Recording Fee', FeeTypeEnum.PowerOfAttorneyRecordingFee),
  new EnumerationItem(
    'Preclosing Verification Control Fee',
    FeeTypeEnum.PreclosingVerificationControlFee
  ),
  new EnumerationItem('Processing Fee', FeeTypeEnum.ProcessingFee),
  new EnumerationItem('Program Guarantee Fee', FeeTypeEnum.ProgramGuaranteeFee),
  new EnumerationItem('Property Inspection Waiver Fee', FeeTypeEnum.PropertyInspectionWaiverFee),
  new EnumerationItem('Radon Inspection Fee', FeeTypeEnum.RadonInspectionFee),
  new EnumerationItem('Rate Lock Fee', FeeTypeEnum.RateLockFee),
  new EnumerationItem(
    'Real Estate Commission Buyers Broker',
    FeeTypeEnum.RealEstateCommissionBuyersBroker
  ),
  new EnumerationItem(
    'Real Estate Commission Sellers Broker',
    FeeTypeEnum.RealEstateCommissionSellersBroker
  ),
  new EnumerationItem('Reconveyance Fee', FeeTypeEnum.ReconveyanceFee),
  new EnumerationItem('Reconveyance Tracking Fee', FeeTypeEnum.ReconveyanceTrackingFee),
  new EnumerationItem('Recording Fee For Assignment', FeeTypeEnum.RecordingFeeForAssignment),
  new EnumerationItem('Recording Fee For Deed', FeeTypeEnum.RecordingFeeForDeed),
  new EnumerationItem('Recording Fee For Mortgage', FeeTypeEnum.RecordingFeeForMortgage),
  new EnumerationItem(
    'Recording Fee For Municipal Lie nCertificate',
    FeeTypeEnum.RecordingFeeForMunicipalLienCertificate
  ),
  new EnumerationItem('Recording Fee For Other Document', FeeTypeEnum.RecordingFeeForOtherDocument),
  new EnumerationItem('Recording Fee For Release', FeeTypeEnum.RecordingFeeForRelease),
  new EnumerationItem('Recording Fee For Subordination', FeeTypeEnum.RecordingFeeForSubordination),
  new EnumerationItem('Recording Fee Total', FeeTypeEnum.RecordingFeeTotal),
  new EnumerationItem('Recording Service Fee', FeeTypeEnum.RecordingServiceFee),
  new EnumerationItem('Redraw Fee', FeeTypeEnum.RedrawFee),
  new EnumerationItem('Reinspection Fee', FeeTypeEnum.ReinspectionFee),
  new EnumerationItem('Renovation Consultant Fee', FeeTypeEnum.RenovationConsultantFee),
  new EnumerationItem('Repairs Fee', FeeTypeEnum.RepairsFee),
  new EnumerationItem('Roof Inspection Fee', FeeTypeEnum.RoofInspectionFee),
  new EnumerationItem('Septic Inspection Fee', FeeTypeEnum.SepticInspectionFee),
  new EnumerationItem('Settlement Fee', FeeTypeEnum.SettlementFee),
  new EnumerationItem('Settlement Or Closing Fees Total', FeeTypeEnum.SettlementOrClosingFeesTotal),
  new EnumerationItem('Signing Agent Fee', FeeTypeEnum.SigningAgentFee),
  new EnumerationItem('Smoke Detector Inspection Fee', FeeTypeEnum.SmokeDetectorInspectionFee),
  new EnumerationItem('State Title Insurance Fee', FeeTypeEnum.StateTitleInsuranceFee),
  new EnumerationItem('Structural Inspection Fee', FeeTypeEnum.StructuralInspectionFee),
  new EnumerationItem('Subordination Fee', FeeTypeEnum.SubordinationFee),
  new EnumerationItem('Survey Fee', FeeTypeEnum.SurveyFee),
  new EnumerationItem('Tax Service Fee', FeeTypeEnum.TaxServiceFee),
  new EnumerationItem('Tax Stamp For City Deed', FeeTypeEnum.TaxStampForCityDeed),
  new EnumerationItem('Tax Stamp For City Mortgage', FeeTypeEnum.TaxStampForCityMortgage),
  new EnumerationItem('Tax Stamp For County Deed', FeeTypeEnum.TaxStampForCountyDeed),
  new EnumerationItem('Tax Stamp For County Mortgage', FeeTypeEnum.TaxStampForCountyMortgage),
  new EnumerationItem('Tax Stamp For State Deed', FeeTypeEnum.TaxStampForStateDeed),
  new EnumerationItem('Tax Stamp For Stat eMortgage', FeeTypeEnum.TaxStampForStateMortgage),
  new EnumerationItem('Tax Status Research Fee', FeeTypeEnum.TaxStatusResearchFee),
  new EnumerationItem(
    'Temporary Buydown Administration Fee',
    FeeTypeEnum.TemporaryBuydownAdministrationFee
  ),
  new EnumerationItem('Temporary Buydown Points', FeeTypeEnum.TemporaryBuydownPoints),
  new EnumerationItem('Title Abstract Fee', FeeTypeEnum.TitleAbstractFee),
  new EnumerationItem(
    'Title Borrower Closing Protection Letter Fee',
    FeeTypeEnum.TitleBorrowerClosingProtectionLetterFee
  ),
  new EnumerationItem('Title Certification Fee', FeeTypeEnum.TitleCertificationFee),
  new EnumerationItem('Title Closing Coordinatio nFee', FeeTypeEnum.TitleClosingCoordinationFee),
  new EnumerationItem('Title Closing Fee', FeeTypeEnum.TitleClosingFee),
  new EnumerationItem(
    'Title Closing Protection Letter Fee',
    FeeTypeEnum.TitleClosingProtectionLetterFee
  ),
  new EnumerationItem('Title Document Preparation Fee', FeeTypeEnum.TitleDocumentPreparationFee),
  new EnumerationItem('Title Endorsement Fee', FeeTypeEnum.TitleEndorsementFee),
  new EnumerationItem('Title Examination Fee', FeeTypeEnum.TitleExaminationFee),
  new EnumerationItem(
    'Title Final Policy Short Form Fee',
    FeeTypeEnum.TitleFinalPolicyShortFormFee
  ),
  new EnumerationItem('Title Insurance Binder Fee', FeeTypeEnum.TitleInsuranceBinderFee),
  new EnumerationItem('Title Insurance Fee', FeeTypeEnum.TitleInsuranceFee),
  new EnumerationItem('Title Lenders Coverage Premium', FeeTypeEnum.TitleLendersCoveragePremium),
  new EnumerationItem('Title Notary Fee', FeeTypeEnum.TitleNotaryFee),
  new EnumerationItem('Title Owners Coverage Premium', FeeTypeEnum.TitleOwnersCoveragePremium),
  new EnumerationItem('Title Services Fee Total', FeeTypeEnum.TitleServicesFeeTotal),
  new EnumerationItem('Title Services Sales Tax', FeeTypeEnum.TitleServicesSalesTax),
  new EnumerationItem(
    'Title Underwriting Issue Resolution Fee',
    FeeTypeEnum.TitleUnderwritingIssueResolutionFee
  ),
  new EnumerationItem('Transfer Tax Total', FeeTypeEnum.TransferTaxTotal),
  new EnumerationItem('Underwriting Fee', FeeTypeEnum.UnderwritingFee),
  new EnumerationItem('Verification Of Assets Fee', FeeTypeEnum.VerificationOfAssetsFee),
  new EnumerationItem('Verification Of Employment Fee', FeeTypeEnum.VerificationOfEmploymentFee),
  new EnumerationItem('Verification Of Income Fee', FeeTypeEnum.VerificationOfIncomeFee),
  new EnumerationItem(
    'Verification Of Residency Status Fee',
    FeeTypeEnum.VerificationOfResidencyStatusFee
  ),
  new EnumerationItem(
    'Verification Of Taxpayer Identification Fee',
    FeeTypeEnum.VerificationOfTaxpayerIdentificationFee
  ),
  new EnumerationItem('Verification Of Tax Return Fee', FeeTypeEnum.VerificationOfTaxReturnFee),
  new EnumerationItem('Water Testing Fee', FeeTypeEnum.WaterTestingFee),
  new EnumerationItem('Well Inspection Fee', FeeTypeEnum.WellInspectionFee),
  new EnumerationItem('Wire Transfer Fee', FeeTypeEnum.WireTransferFee),
  new EnumerationItem('Tax Stamp City County Total', FeeTypeEnum.TaxStampCityCountyTotal),
  new EnumerationItem('Tax Stamp State Total', FeeTypeEnum.TaxStampStateTotal),
];

const incomePayPeriods = [
  new EnumerationItem('Monthly', PayPeriod.Monthly),
  new EnumerationItem('Annually', PayPeriod.Annually),
  new EnumerationItem('Biweekly', PayPeriod.Biweekly),
  new EnumerationItem('Bimonthly', PayPeriod.Bimonthly),
  new EnumerationItem('Weekly', PayPeriod.Weekly),
  new EnumerationItem('Quarterly', PayPeriod.Quarterly),
  new EnumerationItem('Semiannually', PayPeriod.Semiannually),
  new EnumerationItem('Hourly', PayPeriod.Hourly),
  new EnumerationItem('Semimonthly', PayPeriod.Semimonthly),
];

const dateRanges = [
  new EnumerationItem('Today', 'Today'),
  new EnumerationItem('Yesterday', 'Yesterday'),
  new EnumerationItem('This Week', 'ThisWeek'),
  new EnumerationItem('Last Week', 'LastWeek'),
  new EnumerationItem('This Month', 'ThisMonth'),
  new EnumerationItem('Last Month', 'LastMonth'),
  new EnumerationItem('This Year', 'ThisYear'),
  new EnumerationItem('Last Year', 'LastYear'),
  new EnumerationItem('All Time', 'AllTime'),
  new EnumerationItem('Custom', 'Custom')
];

const dashboardTaskFilters = [
  new EnumerationItem('Lead Tasks - Due Today', 11),
  new EnumerationItem('Lead Tasks - Due Later', 12),

  new EnumerationItem('My Tasks - Due Today', 1),
  new EnumerationItem('My Tasks - Due Later', 2),

  new EnumerationItem('TPO Review - Escalation & COC', 18),
  new EnumerationItem('TPO Review - Condition Review', 17),

  new EnumerationItem('Borrower Tasks - Outstanding', 5),
  new EnumerationItem('Borrower Tasks - Pending', 6),
  new EnumerationItem('Borrower Tasks - Completed', 7),


  new EnumerationItem('Other Tasks - Due Today', 3),
  new EnumerationItem('Other Tasks - Due Later', 4),

  new EnumerationItem('Completed Tasks', 10)
]

const authorizationMethods = [
  new EnumerationItem('Internet', 'Internet'),
  new EnumerationItem('Phone', 'Phone'),
  new EnumerationItem('Face To Face', 'FaceToFace')
]

const companyStatuses = [
  new EnumerationItem('Active', 0),
  new EnumerationItem('Suspended', 1),
  new EnumerationItem('Declined', 2)
];

const widgetTypes = [
  new EnumerationItem('KPI-Single', WidgetType.KpiSingle),
  new EnumerationItem('KPI-Multi', WidgetType.KpiMulti),
  new EnumerationItem('Pipeline Distribution', WidgetType.LenderSpread),
  new EnumerationItem('Loan Pipeline Breakdown', WidgetType.PipelineBreakdown),
  new EnumerationItem('Lead Pipeline Breakdown', WidgetType.LeadPipelineBreakdown),
  new EnumerationItem('Important Days', WidgetType.ImportantDays),
  // new EnumerationItem('Recent Activity', WidgetType.RecentActivity),
  new EnumerationItem('Recent Communication', WidgetType.RecentCommunication),
  new EnumerationItem('Recent Loan', WidgetType.RecentLoan),
  new EnumerationItem('Task Counter', WidgetType.TaskCounter),
  new EnumerationItem('Upcoming Events', WidgetType.UpcomingEvents),
  // new EnumerationItem('Locks Expiring', WidgetType.LocksExpiring),
  new EnumerationItem('Loan Stats by Person', WidgetType.StatsByPerson),
  new EnumerationItem('Avg Days Between KPIs', WidgetType.AvgDaysBetweenKpis),
  new EnumerationItem('Lead Campaign Performance Report', WidgetType.LeadCampaignPerformanceReport),
  new EnumerationItem('Performance Report', WidgetType.PerformanceReport),
  new EnumerationItem('Classic Dashboard', WidgetType.ClassicDashboard),
  new EnumerationItem('Pull Through', WidgetType.PullThrough),
  new EnumerationItem('Turn Time', WidgetType.TurnTime),
  new EnumerationItem('Goals', WidgetType.Goals),
  new EnumerationItem('Communications Report', WidgetType.CommunicationsReport)
]

const states = [
  { name: 'AL', value: 'al' },
  { name: 'AK', value: 'ak' },
  { name: 'AZ', value: 'az' },
  { name: 'AR', value: 'ar' },
  { name: 'CA', value: 'ca' },
  { name: 'CO', value: 'co' },
  { name: 'CT', value: 'ct' },
  { name: 'DE', value: 'de' },
  { name: 'DC', value: 'dc' },
  { name: 'FL', value: 'fl' },
  { name: 'GA', value: 'ga' },
  { name: 'HI', value: 'hi' },
  { name: 'ID', value: 'id' },
  { name: 'IL', value: 'il' },
  { name: 'IN', value: 'in' },
  { name: 'IA', value: 'ia' },
  { name: 'KS', value: 'ks' },
  { name: 'KY', value: 'ky' },
  { name: 'LA', value: 'la' },
  { name: 'ME', value: 'me' },
  { name: 'MD', value: 'md' },
  { name: 'MA', value: 'ma' },
  { name: 'MI', value: 'mi' },
  { name: 'MN', value: 'mn' },
  { name: 'MS', value: 'ms' },
  { name: 'MO', value: 'mo' },
  { name: 'MT', value: 'mt' },
  { name: 'NE', value: 'ne' },
  { name: 'NV', value: 'nv' },
  { name: 'NH', value: 'nh' },
  { name: 'NJ', value: 'nj' },
  { name: 'NM', value: 'nm' },
  { name: 'NY', value: 'ny' },
  { name: 'NC', value: 'nc' },
  { name: 'ND', value: 'nd' },
  { name: 'OH', value: 'oh' },
  { name: 'OK', value: 'ok' },
  { name: 'OR', value: 'or' },
  { name: 'PA', value: 'pa' },
  { name: 'RI', value: 'ri' },
  { name: 'SC', value: 'sc' },
  { name: 'SD', value: 'sd' },
  { name: 'TN', value: 'tn' },
  { name: 'TX', value: 'tx' },
  { name: 'UT', value: 'ut' },
  { name: 'VT', value: 'vt' },
  { name: 'VA', value: 'va' },
  { name: 'WA', value: 'wa' },
  { name: 'WV', value: 'wv' },
  { name: 'WI', value: 'wi' },
  { name: 'WY', value: 'wy' },
];

const militaryStatusType = enumerationItemsFromEnum(MilitaryStatus);

const mortgageAppliedForTypes = [
  { value: 'VA', name: 'VA' },
  { value: 'FHA', name: 'FHA' },
  { value: 'Conventional', name: 'Conventional' },
  { value: 'USDA', name: 'USDA Rural Housing Service' },
  { value: 'OtherMortgage', name: 'Other Mortgage' },
];

const transactionTypes = [
  { name: 'Mortgage', value: 'Mortgage' },
  { name: 'Home Equity Investment', value: 'HomeEquityInvestment' },
  { name: 'Solar', value: 'Solar' },
  // { name: 'Auto Loan', value: 'AutoLoan' },
  // { name: 'Personal Loan', value: 'PersonalLoan' }
]

const mortgageLoanPurposeTypes = [
  { name: 'Refinance', value: 'Refinance' },
  { name: 'Purchase', value: 'Purchase' },
  { name: 'Construction Only', value: 'ConstructionOnly' },
  { name: 'Construction To Permanent', value: 'ConstructionToPermanent' },
  { name: 'Other Loan Purpose', value: 'Other' },
  { name: 'Mortgage Modification', value: 'MortgageModification' },
  { name: 'Unknown', value: 'Unknown' },
]

const armFixedTerms = [
  { name: '1 Month', value: 1 },
  { name: '3 Month', value: 3 },
  { name: '6 Month', value: 6 },
  { name: '1 Year', value: 12 },
  { name: '2 Year', value: 24 },
  { name: '3 Year', value: 36 },
  { name: '5 Year', value: 60 },
  { name: '6 Year', value: 72 },
  { name: '7 Year', value: 84 },
  { name: '10 Year', value: 120 },
  { name: '15 Year', value: 180 },
]

const loanTerms: EnumerationItem[] = [
  { name: '1 Month', value: 3 },
  { name: '6 Month', value: 6 },
  { name: '9 Month', value: 9 },
  { name: '1 Year', value: 12 },
  { name: '2 Year', value: 24 },
  { name: '3 Year', value: 36 },
  { name: '4 Year', value: 48 },
  { name: '5 Year', value: 60 },
  { name: '6 Year', value: 72 },
  { name: '7 Year', value: 84 },
  { name: '8 Year', value: 96 },
  { name: '9 Year', value: 108 },
  { name: '10 Year', value: 120 },
  { name: '12 Year', value: 144 },
  { name: '15 Year', value: 180 },
  { name: '20 Year', value: 240 },
  { name: '25 Year', value: 300 },
  { name: '30 Year', value: 360 },
  { name: '40 Year', value: 480 },
]

const taskStatuses = [
  { id: 400, value: 'Pending', name: 'Pending' },
  {
    id: 401,
    value: 'Submitted',
    name: 'Submitted',
  },
  { id: 402, value: 'Rejected', name: 'Rejected' },
  { id: 403, value: 'Approved', name: 'Approved' },
  {
    id: 404,
    value: 'NotApplicable',
    name: 'Not Applicable',
  },
  {
    id: 421,
    value: 'Requested',
    name: 'Requested',
  },
  {
    id: 423,
    value: 'ReviewReady',
    name: 'Review Ready',
  },
  {
    id: 422,
    value: 'Completed',
    name: 'Completed',
  },
  {
    id: 424,
    value: 'ConditionImportPending',
    name: 'Condition Import Pending',
  },
  {
    id: 425,
    value: 'ChangeOfCircumstancePending',
    name: 'COC Pending',
  },
  {
    id: 426,
    value: 'ChangeOfCircumstanceRejected',
    name: 'COC Rejected',
  },
  {
    id: 427,
    value: 'ChangeOfCircumstanceApproved',
    name: 'COC Approved',
  },
  {
    id: 428,
    value: 'EscalationPending',
    name: 'Escalation Pending',
  },
];

const taskTypes = [
  { name: "Request Document", value: "RequestDocument" },
  { name: "Perform Action", value: "PerformAction" },
  { name: "Online Application", value: "OnlineApplication" },
  { name: "Digital Asset Verification", value: "DigitalAssetVerification" },
  { name: "Digital Income Employment Verification", value: 'DigitalIncomeEmploymentVerification' },
  { name: "Generate Document", value: "GenerateDocument" },
  { name: "This Month", value: "RequestPayoff" },
  { name: "Request HOI", value: "RequestHoi" },
  { name: "Request Title", value: "RequestTitle" },
  { name: "Request Escrow", value: "RequestEscrow" },
  { name: "Request Appraisal", value: "RequestAppraisal" },
  { name: "Quick Client Update - Lead", value: "QuickClientUpdateReferralLead" },
  { name: "Quick Client Update - Pre-Approved", value: "QuickClientUpdatePreApproved" },
  { name: "Quick Client Update - In Process", value: "QuickClientUpdateInProcess" },
]

const loanStages = [
  { name: "Prospect", value: "Prospect", id: 10, groupName: "Prospect" },
  { name: "Application", value: "Application", id: 0, groupName: "Application" },
  { name: "Origination", value: "Origination", id: 1, groupName: "Application" },
  { name: "Setup", value: "Setup", id: 2, groupName: "Processing" },
  { name: "Processing", value: "Processing", id: 3, groupName: "Processing" },
  { name: "Underwriting", value: "Underwriting", id: 4, groupName: "Processing" },
  { name: "Approval", value: "Approval", id: 5, groupName: "Processing" },
  { name: "ClearToClose", value: "ClearToClose", id: 6, groupName: "Closing" },
  { name: "Completed", value: "Completed", id: 7, groupName: "Funded" },
  { name: "Withdrawn", value: "Withdrawn", id: 8, groupName: "Adversed" },
  { name: "Denied", value: "Denied", id: 9, groupName: "Adversed" },
  { name: "Suspended", value: "Suspended", id: 11, groupName: "Suspended" }
]

const documentFormats = [
  { name: '/Loan/(OriginalFileName as FileName)', value: 'originalfilename' },
  { name: '/Loan/(DocType - BorrowerLast, BorrowerFirst as FileName)', value: 'original' },
  { name: '/Loan/(DocType - BorrowerFirst BorrowerLast as FileName)', value: 'originalreverse' },
  { name: '/Loan/(BorrowerLast, BorrowerFirst - DocType as FileName)', value: 'original2' },
  { name: '/Loan/(BorrowerFirst BorrowerLast - DocType as FileName)', value: 'original2reverse' },
  { name: '/Loan/(BorrowerLast, BorrowerFirst - OriginalFileName as FileName)', value: 'borroweroriginalfilename' },
  { name: '/Loan/(BorrowerFirst BorrowerLast - OriginalFileName as FileName)', value: 'borroweroriginalfilenamereverse' },
  { name: '/Borrower/(DocType as FileName)', value: 'borrowerdoctype' },
  { name: '/Borrower/DocType/OriginalFileName', value: 'borrowerdoctypefilename' },
]

const timeFrames = [
  { name: "Today", value: "Today" },
  { name: "Yesterday", value: "Yesterday" },
  { name: "This Week", value: "ThisWeekToDate" },
  { name: "Last Week", value: "LastWeekToDate" },
  { name: "Last Week Total", value: "LastWeekTotal" },
  { name: "This Month", value: "ThisMonthToDate" },
  { name: "Last Month", value: "LastMonthToDate" },
  { name: "Last Month Total", value: "LastMonthTotal" },
  { name: "This Quarter", value: "thisQuarterToDate" },
  { name: "Last Quarter", value: "lastQuarterToDate" },
  { name: "Last Quarter Total", value: "LastQuarterTotal" },
  { name: "This Year", value: "ThisYearToDate" },
  { name: "Last Year", value: "LastYearToDate" },
  { name: "Last Year Total", value: "LastYearTotal" }
]

const futureTimeFrames = [
  { name: 'Today', value: 'Today' },
  { name: 'Next 2 Days', value: 'Next2Days' },
  { name: 'Next 3 Days', value: 'Next3Days' },
  { name: 'Next 7 Days', value: 'Next7Days' },
  { name: 'Next 14 Days', value: 'Next14Days' },
  { name: 'Remaining Part Of Week', value: 'RemainingPartOfWeek' },
  { name: 'Remaining Part Of Month', value: 'RemainingPartOfMonth' },
];

const groupByTimeFrames = [
  { name: "Day Of Month", value: "DayOfMonth" },
  { name: "Day Of Year", value: "DayOfYear" },
  { name: "Week", value: "Week" },
  { name: "Month", value: "Month" },
  { name: "Year", value: "Year" },
  { name: "Quarter", value: "Quarter" },
  { name: "Weekday", value: "Weekday" }
]

const quarters: EnumerationItem[] = [
  { name: "Q1", value: "QuarterOne" },
  { name: "Q2", value: "QuarterTwo" },
  { name: "Q3", value: "QuarterThree" },
  { name: "Q4", value: "QuarterFour" },
];

const mcrLoanTypes: EnumerationItem[] = [
  { name: "Conventional", value: "Conventional" },
  { name: "FHA Insured", value: "FHAInsured" },
  { name: "VA Guaranteed", value: "VAGuaranteed" },
  { name: "FSA_RHS Guaranteed", value: "FSA_RHSGuaranteed" },
];

const mcrChanels: EnumerationItem[] = [
  { name: "Retail", value: "Retail" },
  { name: "FHA Wholesale", value: "Wholesale" },
  { name: "Brokered", value: "Brokered" }
];

const chanels: EnumerationItem[] = [
  { name: "Retail", value: "Retail" },
  { name: "Wholesale", value: "Wholesale" },
  { name: "Broker", value: "Broker" },
  { name: "Correspondent", value: "Correspondent" },
  { name: "Non Delegated Correspondent", value: "NonDelegatedCorrespondent" },
];

const borrowerCounselingFormatType: EnumerationItem[] = [
  { name: "Face to Face", value: "FaceToFace" },
  { name: "Internet", value: "Internet" },
  { name: "Telephone", value: "Telephone" },
  { name: "Other", value: "Other" }
];

const languages: EnumerationItem[] = [
  { name: "English", value: "English" },
  { name: "Spanish", value: "Spanish" },
  { name: "Chinese", value: "Chinese" },
  { name: "Korean", value: "Korean" },
  { name: "Vietnamese", value: "Vietnamese" },
  { name: "Tagalog", value: "Tagalog" },
  { name: "Other", value: "Other" }
];

const rotationStrategies: EnumerationItem[] = [
  { name: "Random", value: "Random" },
  { name: "Round Robin ", value: "RoundRobin" },
  { name: "Local Presence ", value: "LocalPresence" }
];

const partyTypes: EnumerationItem[] = [
  { name: "Individual", value: "Individual" },
  { name: "Entity", value: "Entity" },
  { name: "Trust", value: "Trust" }
];

const partyTypesForExpressions: EnumerationItem[] = [
  { name: "Individual", value: 0 },
  { name: "Entity", value: 1 },
  { name: "Trust", value: 2 }
];

const deedTypes: EnumerationItem[] = [
  { name: "General Warranty Deed with VL", value: "GeneralWarrantyDeedWithVl" },
  { name: "Special Warranty Deed with VL", value: "SpecialWarrantyDeedWithVl" },
  { name: "Do Not Prepare", value: "DoNotPrepare" }
];

const entityTypes: EnumerationItem[] = [
  { name: "Limited Liability Company", value: "LimitedLiabilityCorporation" },
  { name: "Corporation", value: "Corporation" },
  { name: "Limited Partnership", value: "LimitedPartnership" },
  { name: "General Partnership", value: "GeneralPartnership" }
];

const partyEntityTypes: EnumerationItem[] = [
  { name: "Limited Liability Company", value: "LimitedLiabilityCorporation" },
  { name: "Corporation", value: "Corporation" },
  { name: "Limited Partnership", value: "LimitedPartnership" },
  { name: "General Partnership", value: "GeneralPartnership" }
];

const borrowerRoles: EnumerationItem[] = [
  { name: "Borrower", value: "Borrower" },
  { name: "Co-Signer", value: "CoSigner" },
  { name: "Title Only", value: "TitleOnly" },
  { name: "Non-Titled Spouse", value: "NonTitledSpouse" }
];

const mcrApplicationPipelinePositions: EnumerationItem[] = [
  { name: "In Process At Start Of Period", value: "InProcessAtStartOfPeriod" },
  { name: "Received", value: "Received" },
  { name: "Approved But Not Accepted", value: "ApprovedButNotAccepted" },
  { name: "Denied", value: "Denied" },
  { name: "Withdrawn", value: "Withdrawn" },
  { name: "Closed For Incompleteness", value: "ClosedForIncompleteness" },
  { name: "Pre-Approval Request Denied", value: "PreApprovalRequestDenied" },
  { name: "Pre-Approval Request Approved But Not Accepted", value: "PreApprovalRequestApprovedButNotAccepted" },
  { name: "Closed And Funded", value: "ClosedAndFunded" },
  { name: "In Process At End Of Period", value: "InProcessAtEndOfPeriod" }
];

const mcrPropertyTypes: EnumerationItem[] = [
  { name: "One To Four Family Dwelling", value: "OneToFourFamilyDwelling" },
  { name: "Manufactured Housing", value: "ManufacturedHousing" },
  { name: "Multifamily Dwelling", value: "MultifamilyDwelling" },
  { name: "FSA RHS Guaranteed", value: "FSA_RHSGuaranteed" },
];

const mcrLoanPurposes: EnumerationItem[] = [
  { name: "Home Purchase", value: "HomePurchase" },
  { name: "Home Improvement", value: "HomeImprovement" },
  { name: "Refinancing", value: "Refinancing" },
];

const mcrLienTypes: EnumerationItem[] = [
  { name: "First Lien", value: "FirstLien" },
  { name: "Subordinate Lien", value: "SubordinateLien" },
  { name: "Not Secured By A Lien", value: "NotSecuredByALien" },
];

const mcrMortgageDirections: EnumerationItem[] = [
  { name: "Forward", value: "Forward" },
  { name: "Reverse", value: "Reverse" },
];

const mcrReverseMortgageTypes: EnumerationItem[] = [
  { name: "HECM Standard", value: "HECMStandard" },
  { name: "HECM Saver", value: "HECMSaver" },
  { name: "Proprietary Other", value: "ProprietaryOther" },
];

const mcrReverseMortgagePurposes: EnumerationItem[] = [
  { name: "Home Purchase", value: "HomePurchase" },
  { name: "Other", value: "Other" },
];

const mcrQualifiedMortgageTypes: EnumerationItem[] = [
  { name: "Qualified Mortgage", value: "QualifiedMortgage" },
  { name: "Non Qualified Mortgage", value: "NonQualifiedMortgage" },
  { name: "Not Subject To QM", value: "NotSubjectToQM" },
];

const mcrMortgageServicingDispositions: EnumerationItem[] = [
  { name: "Intend To Retain Rights", value: "IntendToRetainRights" },
  { name: "Intend To Sell Rights", value: "IntendToSellRights" },
];

const titleHistoryEventTypes: EnumerationItem[] = [
  { name: "Order Created", value: "OrderCreated" },
  { name: "Order Updated", value: "OrderUpdated" },
  { name: "Order Deleted", value: "OrderDeleted" },
  { name: "Order Cancelled", value: "OrderCancelled" },
  { name: "Document Created", value: "DocumentCreated" },
  { name: "Document Updated", value: "DocumentUpdated" },
  { name: "Document Deleted", value: "DocumentDeleted" },
];

const disclosureTypes: EnumerationItem[] = [
  { name: "None", value: "None" },
  { name: "Initial", value: "Initial" },
  { name: "Revised", value: "Revised" }
];

const disclosureReasons: EnumerationItem[] = [
  { name: "24 Hour Advanced Preview", value: "Item24HourAdvancedPreview", alternateValue: 1 },
  { name: "Prepayment Penalty Added", value: "AdditionOfPrepaymentPenalty", alternateValue: 2 },
  { name: "Change in APR", value: "APR", alternateValue: 4 },
  { name: "Clerical Error Correction", value: "ClericalErrorCorrection", alternateValue: 8 },
  { name: "Delayed Settlement Date", value: "DelayedSettlementDate", alternateValue: 16 },
  { name: "Change in Circumstance - Eligibility", value: "Eligibility", alternateValue: 32 },
  { name: "Expiration (Intent to Proceed received after 10 business days)", value: "Expiration", alternateValue: 64 },
  { name: "Interest Rate dependent charges (Rate Lock)", value: "InterestRateDependentCharges", alternateValue: 128 },
  { name: "Change in Loan Product", value: "LoanProduct", alternateValue: 256 },
  { name: "Other", value: "Other", alternateValue: 512 },
  { name: "Revisions Requested by Borrower", value: "RevisionsRequestedByBorrower", alternateValue: 1024 },
  { name: "Change in Circumstance - Settlement Charges", value: "SettlementCharges", alternateValue: 2048 },
  { name: "Tolerance Cure", value: "ToleranceCure", alternateValue: 4096 },
];

const cocReasonTypes: EnumerationItem[] = [
  { name: "Loan Amount Change", value: "LoanAmountChange" },
  { name: "Loan Type Change", value: "LoanTypeChange" },
  { name: "Loan Program Change", value: "LoanProgramChange" },
  { name: "Borrower Income Not Verified Or Verified At Different Amount", value: "BorrowerIncomeNotVerifiedOrVerifiedAtDifferentAmount" },
  { name: "Appraised Value Different From Estimated Value", value: "AppraisedValueDifferentFromEstimatedValue" },
  { name: "Additional Service Is Necessary Based On Title Report", value: "AdditionalServiceIsNecessaryBasedOnTitleReport" },
  { name: "Recording Fees Increased From Unanticipated Documents", value: "RecordingFeesIncreasedFromUnanticipatedDocuments" },
  { name: "Borrower Taking Title Has Changed", value: "BorrowerTakingTitleHasChanged" },
  { name: "Borrower Added Or Dropped From Loan", value: "BorrowerAddedOrDroppedFromLoan" },
  { name: "Loan Locked", value: "LoanLocked" },
  { name: "Loan Lock Extended", value: "LoanLockExtended" },
  { name: "Change In Settlement Fee", value: "ChangeInSettlementFee" },
  { name: "Clerical Error", value: "ClericalError" },
  { name: "Other", value: "Other" },
];

const eventTypes: EnumerationItem[] = [
  { name: "Appointment", value: EventType.Appointment },
  { name: "Scheduled Closing", value: EventType.EstimatedClosing },
  { name: "Lock Expiration", value: EventType.LockExpiration }
]

const dialListAccessListTypes = [
  new EnumerationItem('Public', AccessLevel.Public),
  new EnumerationItem('Admin Only', AccessLevel.AdminOnly),
  new EnumerationItem('User Access List', AccessLevel.UserAccessList),
  new EnumerationItem('Role Access List', AccessLevel.RoleAccessList),
  new EnumerationItem('Branch Access List', AccessLevel.BranchAccessList)
]

const pricingVendors: readonly EnumerationItem<PricingVendor>[] = Object.freeze([
  { name: "Unknown", value: PricingVendor.Unknown },
  { name: "Lodasoft", value: PricingVendor.Lodasoft },
  { name: "OptimalBlue", value: PricingVendor.OptimalBlue },
  { name: "LoanPass", value: PricingVendor.LoanPass },
  { name: "LenderPrice", value: PricingVendor.LenderPrice },
  { name: "Polly", value: PricingVendor.Polly },
  { name: "MeridianLink", value: PricingVendor.MeridianLink },
]);

const feeProviders = [
  { name: "Ernst", value: "Ernst", displayName: "Ernst", order: 0, hidden: false },
  { name: "Lodestar", value: "Lodestar", displayName: "LodeStar", order: 1, hidden: false },
  { name: "MortgageCTO", value: "MortgageCTO", displayName: "MortgageCTO", order: 2, hidden: false },
]

const awsRegions: EnumerationItem[] = [
  ...Object.values(AwsRegion).map(key => {
    return new EnumerationItem(key, key)
  })
]

const compensationTypes: EnumerationItem[] = [
  {
    name: "Lender",
    value: 0,
  },
  {
    name: "Borrower",
    value: 1,
  },
  {
    name: "Correspondent",
    value: 2,
  }
]

const disclosurePathEnums: EnumerationItem[] = [
  {
    name: "Tbd",
    value: 0,
  },
  {
    name: "Lender",
    value: 1,
  },
  {
    name: "Broker",
    value: 2,
  }
]

const incomeVerificationMethods: EnumerationItem[] = [
  {
    name: "None",
    value: 0,
  },
  {
    name: "Full Document",
    value: 1,
  },
  {
    name: "Bank Statement(s)",
    value: 2,
  },
  {
    name: "Verification of Employment",
    value: 3,
  },
  {
    name: "Asset Qualification",
    value: 4,
  },
  {
    name: "Debt Service Coverage Ratio",
    value: 5,
  },
  {
    name: "1099",
    value: 6,
  },
  {
    name: "CPA P&L",
    value: 7,
  },
  {
    name: "Borrower P&L",
    value: 7,
    supportedPricingVendors: PricingVendor.LoanPassIframe
  },
  {
    name: "K-1",
    value: 8,
  },
  {
    name: "WVOE",
    value: 9,
  },
  {
    name: "Asset Depletion",
    value: 10,
  },
  {
    name: "No Ratio",
    value: 11,
  },
  {
    name: "Employment Contract",
    value: 12,
  },
  {
    name: "Workforce Re-entry",
    value: 13,
  },
  {
    name: "Business Bank Statement(s)",
    value: 14,
    supportedPricingVendors: PricingVendor.LoanPassIframe
  },
  {
    name: "Borrower P&L",
    value: 15,
    supportedPricingVendors: PricingVendor.LoanPassIframe
  }
]
const serviceTypesForGenerateSecret: EnumerationItem[] = [
  {
    name: "LOS Events Service",
    value: "LOSEventsService",
  },
  {
    name: "Third Party Callback",
    value: "ThirdPartyCallback",
  },
  {
    name: "LoanBeam Files Service",
    value: "LoanBeamFilesService",
  },
  {
    name: "StatesTitle Event Processing",
    value: "StatesTitleEventProcessing",
  },
  {
    name: "Document Splitting Service",
    value: "DocumentSplittingService",
  },
  {
    name: "General Service",
    value: "GeneralService",
  },
  {
    name: "Email Sending Service",
    value: "EmailSendingService",
  },
  {
    name: "Rock Connections",
    value: "RockConnections",
  },
  {
    name: "Experian",
    value: "Experian",
  },
  {
    name: "Mortgage Call Reports",
    value: "MortgageCallReports",
  },
  {
    name: "Document Signing",
    value: "DocumentSigning",
  },
  {
    name: "Reggora Event Processing",
    value: "ReggoraEventProcessing",
  },
  {
    name: "Dial List Special Number",
    value: "DialListSpecialNumber",
  },
  {
    name: "App Service User",
    value: "AppServiceUser",
  },
  {
    name: "Integration Test",
    value: "IntegrationTest",
  },
]

const incomeVerificationTypes: EnumerationItem[] = [
  {
    value: 0,
    name: "FullDoc"
  },
  {
    value: 1,
    name: "PersonalBankStmt1Mo"
  },
  {
    value: 2,
    name: "PersonalBankStmt6Mos"
  },
  {
    value: 3,
    name: "PersonalBankStmt12Mos"
  },
  {
    value: 4,
    name: "PersonalBankStmt18Mos"
  },
  {
    value: 5,
    name: "PersonalBankStmt24Mos"
  },
  {
    value: 6,
    name: "BusinessBankStmt1Mo"
  },
  {
    value: 7,
    name: "BusinessBankStmt6Mos"
  },
  {
    value: 8,
    name: "BusinessBankStmt12Mos"
  },
  {
    value: 9,
    name: "BusinessBankStmt18Mos"
  },
  {
    value: 10,
    name: "BusinessBankStmt24Mos"
  },
  {
    value: 11,
    name: "OneYearAltDoc"
  },
  {
    value: 12,
    name: "AssetRelated"
  },
  {
    value: 13,
    name: "InvestorDscr"
  },
  {
    value: 14,
    name: "RestrictedStock"
  },
  {
    value: 15,
    name: "InvestorNoRatio"
  },
  {
    value: 16,
    name: "PersonalBankStmt3Mos"
  },
  {
    value: 17,
    name: "BusinessBankStmt3Mos"
  },
  {
    value: 18,
    name: "TwoYearAltDoc"
  },
  {
    value: 19,
    name: "WrittenVOE"
  },
  {
    value: 20,
    name: "Stated"
  },
  {
    value: 21,
    name: "OneYear1099"
  },
  {
    value: 22,
    name: "TwoYear1099"
  },
  {
    value: 23,
    name: "PLOneYear"
  },
  {
    value: 24,
    name: "PLTwoYear"
  },
  {
    value: 25,
    name: "NoIncomeVerification"
  }
]

const documentationTypes: EnumerationItem[] = [
  {
    name: "Alternative",
    value: 0,
  },
  {
    name: "Full Documentation",
    value: 1,
  },
  {
    name: "Interest Rate Reduction Refinance Loan",
    value: 2,
  },
  {
    name: "Streamline Without Appraisal",
    value: 3,
  },
  {
    name: "203k",
    value: 4,
  },
  {
    name: "12 Mo. Personal Bank Statements",
    value: 5,
  },
  {
    name: "24 Mo. Personal Bank Statements",
    value: 6,
  },
  {
    name: "12 Mo. Business Bank Statements",
    value: 7,
  },
  {
    name: "24 Mo. Business Bank Statements",
    value: 8,
  },
  {
    name: "Other Bank Statements",
    value: 9,
  },
  {
    name: "1 Yr. Tax Returns",
    value: 10,
  },
  {
    name: "Verification Of Employment",
    value: 11,
  },
  {
    name: "Asset Utilization",
    value: 12,
  },
  {
    name: "Debt Service Coverage (DSCR)",
    value: 13,
  }
]

const mortgagePartyType: EnumerationItem[] = [
  {
    name: "Individual",
    value: 0,
  },
  {
    name: "Entity",
    value: 1,
  },
  {
    name: "Trust",
    value: 2,
  }
];

const mortgageEntityType: EnumerationItem[] = [
  {
    name: "LimitedLiabilityCorporation",
    value: 0,
  },
  {
    name: "Corporation",
    value: 1,
  },
  {
    name: "LimitedPartnership",
    value: 2,
  },
  {
    name: "GeneralPartnership",
    value: 3,
  }
];

const applicationCopyReason: EnumerationItem[] = [
  {
    name: "None",
    value: 0,
  },
  {
    name: "Reorigination",
    value: 1,
  },
  {
    name: "ChangeLender",
    value: 2,
  },
  {
    name: "Other",
    value: 3,
  },
  {
    name: "ReturningClient",
    value: 4,
  },
  {
    name: "FalloutReorigination",
    value: 5,
  },
];


const buydownTypes: EnumerationItem[] = [
  {
    name: "Buydown 1-0",
    value: '1-0',
  },
  {
    name: "Buydown 1-1",
    value: '1-1',
  },
  {
    name: "Buydown 2-1",
    value: '2-1',
  },
  {
    name: "Buydown 1.5-0.5",
    value: '1.5-0.5',
  },
  {
    name: "Buydown 3-2-1",
    value: '3-2-1',
  },
];

const encompassCreditProviders: EnumerationItem[] = [
  { name: "Avantus", value: "CBCTAvantus" },
  { name: "CBC Innovis, Inc", value: "CBCInnovis" },
  { name: "CoreLogic", value: "CoreLogic" },
  { name: "Equifax Mortgage Services", value: "Equifax" },
  { name: "Factual Data by CBC", value: "FactualDataCBC" },
  { name: "Factual Data Credit", value: "FactualData" },
  { name: "Informative Research", value: "InformativeResearch" },
  { name: "ACB Credit Solutions", value: "ACBCreditSolutions" },
  { name: "Accurate Financial Services", value: "AccurateFinancialServices" },
  { name: "ACRAnet Inc.", value: "ACRAnet" },
  { name: "Credit Information Service, Inc.", value: "CreditInformationServices" },
  { name: "Credit Information Systems", value: "CreditInformationSystems" },
  { name: "Data Facts, Inc.", value: "DataFacts" },
  { name: "DCI Credit Services, Inc", value: "DCICreditServices" },
  { name: "MFI Credit Solutions", value: "MFICreditSolutions" },
  { name: "Mortgage Reporting Center", value: "MortgageReportingCenter" },
  { name: "NCO Credit Services(Power by Synergistic)", value: "NCOCS" },
  { name: "Network Credit Services", value: "NetworkCreditServices" },
  { name: "NMR-SettlementOne", value: "CreditNMR-SettlementOne" },
  { name: "One Source Credit Reporting, LLC", value: "OneSourceCreditReporting" },
  { name: "Online Mortgage Reports", value: "OnlineMortgageReports" },
  { name: "San Antonio Retail Merchants Association(SARMA)", value: "SARMASL" },
  { name: "Sharper Lending", value: "SharperLending" },
  { name: "Strategic Information Resources, Inc", value: "StrategicInformationResources" },
  { name: "Covius Mortgage Solutions", value: "Covius" },
  { name: "Partner Credit & Verification Solutions(Old Republic)", value: "OldRepublic" },
  { name: "Transunion", value: "TransUnion" },
  { name: "Advantage Credit Bureau(aka DCI Credit - Services, Inc.)", value: "DCICreditServices" },
  { name: "Advantage Credit, Inc.", value: "AdvantageCredit" },
  { name: "Advantage Plus Credit Reporting, Inc.", value: "AdvantagePlusCreditReporting" },
  { name: "Alliance 2020, Inc.", value: "Alliance2020" },
  { name: "American Reporting Company, LLC (Powered by MeridianLink)", value: "AmericanReportingCompany" },
  { name: "Birchwood Credit Services, Inc.", value: "BirchwoodCreditServices" },
  { name: "Cal Coast Credit Reports", value: "CalCoastCreditReports" },
  { name: "Certified Credit Link, Inc.", value: "CertifiedCreditLink" },
  { name: "Certified Credit Reporting(Powered by MeridianLink)", value: "CertifiedCreditReporting" },
  { name: "CIC Mortgage Credit, Inc.", value: "CICMortgageCredit" },
  { name: "CIS Information Services", value: "CISInformationServices" },
  { name: "CISCO Credit(Powered by MeridianLink)", value: "CISCOCredit" },
  { name: "Clear Choice Credit", value: "ClearChoiceCredit" },
  { name: "Credit Link, LLC", value: "CreditLink" },
  { name: "Credit Plus(Powered by MeridianLink)", value: "CreditPlus" },
  { name: "Credit Technologies, Inc.", value: "CreditTechnologies" },
  { name: "CTI Credit Technology, Inc.", value: "CTI" },
  { name: "Data Facts, Inc.", value: "DataFactsInc" },
  { name: "Premium Credit Bureau", value: "PremiumcreditBureau" },
  { name: "Merchants Credit Bureau - Augusta", value: "MCBAugusta" },
  { name: "San Antonio Retail Merchants Association(SARMA) (Powered by MeridianLink)", value: "SARMA" },
  { name: "Service 1st Info Systems", value: "Service1stInfoSystems" },
  { name: "Settlement One Data, LLC", value: "SettlementOneDataLLC" },
  { name: "United One", value: "UnitedOne" },
  { name: "Universal Credit - National", value: "UniversalCredit" }
]

@Injectable()
export class EnumerationService {
  bindToNameAsValue = true;

  private _taskStatusEnums: any = undefined;
  private _mortgageEnums: any = undefined;
  private _commonEnums: any = undefined;
  private _enumValues: any = {};
  private _rawEnums: any = {};

  private _pricingEnums: any = undefined;
  private _mortgageInsuranceEnums: any = undefined;
  private _creditEnums: EnumItem[] = undefined;
  private _feeEnums: any = undefined;
  private _titleEnums: any = undefined;
  private _expressionEnums: any = undefined;
  private _customDataEnums: any = undefined;
  private _creditVendorEnums: any = undefined;
  private _configurationEnums: any = undefined;
  private _keyDateTypes: any = undefined;
  private _disclosureReasons: any = undefined;
  private _systemTaskTypes: any = undefined;
  private _wireRequest: any = undefined;

  private _documentSetEnums: any = undefined;
  private _mortgageLanguageEnums: any = undefined;
  private _appraisalEnums: any = undefined;
  private _titleHistoryEventTypes: any = undefined;
  private _lpaVerificationCompanyEnums: any = undefined;
  private _compensationTypes: any = undefined;
  private _documentEnums: any = undefined;
  private _allCountries: CountryEnumerationItem[] = undefined;
  private _allOperators: Operator[] = undefined;
  private _mcrEnums: any = undefined;

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _dataService: DataService
  ) { }

  getCommonEnumerations = (): Observable<any> => {
    if (this._commonEnums) {
      return of(this._commonEnums);
    }
    return this.doGetCommonEnumerations().pipe(
      map((result) => {
        this._commonEnums = result;
        return this._commonEnums;
      })
    );
  };

  getMortgageEnumerations = (): Observable<any> => {
    if (this._mortgageEnums) {
      return of(this._mortgageEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.Mortgage').pipe(
      map((result) => {
        this._mortgageEnums = result;
        return this._mortgageEnums;
      })
    );
  };

  getTaskStatusEnumerations = (): Observable<any> => {
    if (this._taskStatusEnums) {
      return of(this._taskStatusEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.TaskStatus').pipe(
      map((result) => {
        this._taskStatusEnums = result;
        return this._taskStatusEnums;
      })
    );
  };

  getMortgageCallReportEnumerations = (): Observable<any> => {
    if (this._mcrEnums) {
      return of(this._mcrEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.MortgageCallReports').pipe(
      map((result) => {
        this._mcrEnums = result;
        return this._mcrEnums;
      })
    );
  };

  getCreditEnumerations = (): Observable<EnumItem[]> => {
    if (this._creditEnums) {
      return of(this._creditEnums);
    }
    return this._dataService
      .post(`api/Lookup/enum/namespace/LE.Lodasoft.Common.Enums.Credit?api_key=lookup`, {})
      .pipe(
        map((result) => {
          this._creditEnums = result;
          return result;
        })
      );
  };

  getPricingEnumerations = (): Observable<any> => {
    if (this._pricingEnums) {
      return of(this._pricingEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Models.Pricing').pipe(
      map((result) => {
        this._pricingEnums = result;
        return this._pricingEnums;
      })
    );
  };

  getMortgageInsuranceEnumerations = (): Observable<any> => {
    if (this._mortgageInsuranceEnums) {
      return of(this._mortgageInsuranceEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Models.Mi').pipe(
      map((result) => {
        this._mortgageInsuranceEnums = result;
        return this._mortgageInsuranceEnums;
      })
    );
  };

  getLpaVerificationCompanies = (): Observable<any> => {
    if (this._lpaVerificationCompanyEnums) {
      return of(this._lpaVerificationCompanyEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.ThirdParty.Aus.LpaVerificationCompany').pipe(
      map((result) => {
        this._lpaVerificationCompanyEnums = result;
        return this._lpaVerificationCompanyEnums;
      })
    );
  };

  getFeeEnumerations = (): Observable<any> => {
    if (this._feeEnums) {
      return of(this._feeEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.Fees').pipe(
      map((result) => {
        this._feeEnums = result;
        return this._feeEnums;
      })
    );
  };

  getTitleEnumerations = (): Observable<any> => {
    if (this._titleEnums) {
      return of(this._titleEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.ThirdParty.Title').pipe(
      map((result) => {
        this._titleEnums = result;
        return this._titleEnums;
      })
    );
  };

  getExpressionEnumerations = (): Observable<any> => {
    if (this._expressionEnums) {
      return of(this._expressionEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Models.Expressions').pipe(
      map((result) => {
        this._expressionEnums = result;
        return this._expressionEnums;
      })
    );
  };

  getMortgageDocumentSetEnumerations = (): Observable<
    Array<EnumerationItem>
  > => {
    if (this._documentSetEnums) {
      return of(this._documentSetEnums);
    }
    return this.getEnumerations(
      'LE.Lodasoft.Common.Enums.Documents.MortgageDocumentSet'
    ).pipe(
      map((result) => {
        this._documentSetEnums = result;
        return this._documentSetEnums;
      })
    );
  };


  getMortgageLanguageEnumerations = (): Observable<
    Array<EnumerationItem>
  > => {
    if (this._mortgageLanguageEnums) {
      return of(this._mortgageLanguageEnums);
    }
    return this.getEnumerations(
      'LE.Lodasoft.Common.Enums.Mortgage.Language'
    ).pipe(
      map((result) => {
        this._mortgageLanguageEnums = result;
        return this._mortgageLanguageEnums;
      })
    );
  };

  getAppraisalEnumerations = (): Observable<
    Array<EnumerationItem>
  > => {
    if (this._appraisalEnums) {
      return of(this._appraisalEnums);
    }
    return this.getEnumerations(
      'LE.Lodasoft.Common.Enums.ThirdParty.Appraisal'
    ).pipe(
      map((result) => {
        this._appraisalEnums = result;
        return this._appraisalEnums;
      })
    );
  };

  getCustomDataEnumerations = (): Observable<any> => {
    if (this._customDataEnums) {
      return of(this._customDataEnums);
    }
    return this.getEnumerations('LE.Lodasoft.DataAccess.DbModels.Configuration.CustomData').pipe(
      map((result) => {
        this._customDataEnums = result;
        return this._customDataEnums;
      })
    );
  };

  getCreditVendorEnumerations = (): Observable<any> => {
    if (this._creditVendorEnums) {
      return of(this._creditVendorEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.Credit.CreditVendor').pipe(
      map((result) => {
        this._creditVendorEnums = result;
        return this._creditVendorEnums;
      })
    );
  };

  getConfigurationEnumerations = (): Observable<any> => {
    if (this._configurationEnums) {
      return of(this._configurationEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Models.Configuration').pipe(
      map((result) => {
        this._configurationEnums = result;
        return this._configurationEnums;
      })
    );
  };


  getKeyDateTypeEnumeration = (): Observable<any> => {
    if (this._keyDateTypes) {
      return of(this._keyDateTypes);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.KeyDateType').pipe(
      map((result) => {
        this._keyDateTypes = result?.KeyDateType || [];
        return this._keyDateTypes;
      })
    );
  };

  getDisclosureReasonEnumerations = (): Observable<any> => {
    if (this._disclosureReasons) {
      return of(this._disclosureReasons);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.Mortgage.Disclosure.DisclosureReason').pipe(
      map((result) => {
        this._disclosureReasons = result;
        return this._disclosureReasons;
      })
    );
  };

  getSystemTaskTypeEnumeration = (): Observable<any> => {
    if (this._systemTaskTypes) {
      return of(this._systemTaskTypes);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.TaskType').pipe(
      map((result) => {
        this._systemTaskTypes = result?.TaskType || [];
        this._systemTaskTypes.forEach((item: EnumerationItem) => {
          item.name = Utils.splitCamelCaseString(item.name);
        });
        return this._systemTaskTypes;
      })
    );
  };

  gerWireRequestEnumeration = (): Observable<any> => {
    if (this._wireRequest) {
      return of(this._wireRequest);
    }
    return this.getEnumerations('LE.Lodasoft.Services.WireRequest').pipe(
      map((result) => {
        this._wireRequest = result;
        return this._wireRequest;
      })
    );
  };

  getDocumentEnumeration = (): Observable<any> => {
    if (this._documentEnums) {
      return of(this._documentEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.Documents').pipe(
      map((result) => {
        this._documentEnums = result;
        return this._documentEnums;
      })
    );
  };


  get timeFrames(): EnumerationItem[] {
    return timeFrames;
  }

  get futureTimeFrames(): EnumerationItem[] {
    return futureTimeFrames;
  }

  get groupByTimeFrames(): EnumerationItem[] {
    return groupByTimeFrames;
  }

  get titleHistoryEventTypes(): EnumerationItem[] {
    return titleHistoryEventTypes;
  }

  get feeTypes(): EnumerationItem[] {
    return feeTypes;
  }

  getYesNoEnumItems = (): EnumerationItem[] => {
    let result: EnumerationItem[] = [];
    result.push(new EnumerationItem('Yes', true));
    result.push(new EnumerationItem('No', false));
    return result;
  };

  getIncomePayPeriods = (): EnumerationItem[] => {
    return incomePayPeriods;
  };

  getCompanyStatuses = (): EnumerationItem[] => {
    return companyStatuses;
  }

  getDialListAccessListTypes = (): EnumerationItem[] => {
    return dialListAccessListTypes;
  }

  getCompensationTypes = (): EnumerationItem[] => {
    return compensationTypes;
  }

  getDisclosurePaths = (): EnumerationItem[] => {
    return disclosurePathEnums;
  }

  getIncomeVerificationMethods = (): EnumerationItem[] => {
    return incomeVerificationMethods;
  }

  getIncomeVerificationTypes = (): EnumerationItem[] => {
    return incomeVerificationTypes;
  }

  getDocumentationTypes = (): EnumerationItem[] => {
    return documentationTypes;
  }

  getMortgagePartyType = (): EnumerationItem[] => {
    return mortgagePartyType;
  }

  getMortgageEntityType = (): EnumerationItem[] => {
    return mortgageEntityType;
  }

  getApplicationCopyReason = (): EnumerationItem[] => {
    return applicationCopyReason;
  }

  get pricingVendors(): readonly EnumerationItem<PricingVendor>[] {
    return pricingVendors;
  }

  get feeProviders(): EnumerationItem[] {
    return feeProviders;
  }

  get authorizationMethods(): EnumerationItem[] {
    return authorizationMethods;
  }

  get states(): EnumerationItem[] {
    return states;
  }

  get groupByTypes(): EnumerationItem[] {
    return groupByTypes;
  }

  get dateRanges(): EnumerationItem[] {
    return dateRanges;
  }

  get dashboardTaskFilters(): EnumerationItem[] {
    return dashboardTaskFilters;
  }

  get countries(): EnumerationItem[] {
    return countries;
  }

  get industryAffiliations(): EnumerationItem[] {
    return industryAffiliations;
  }

  get militaryStatusType(): EnumerationItem[] {
    return militaryStatusType;
  }

  get mortgageAppliedForTypes(): EnumerationItem[] {
    return mortgageAppliedForTypes;
  }

  get mortgageLoanPurposeTypes(): EnumerationItem[] {
    return mortgageLoanPurposeTypes;
  }

  get getServiceTypesForGenerateSecret(): EnumerationItem[] {
    return serviceTypesForGenerateSecret;
  }

  get armFixedTerms(): EnumerationItem[] {
    return armFixedTerms;
  }

  get loanTerms(): EnumerationItem[] {
    return loanTerms;
  }

  get transactionTypes(): EnumerationItem[] {
    return transactionTypes;
  }

  get taskStatuses(): EnumerationItem[] {
    return taskStatuses;
  }

  get loanStages(): EnumerationItem[] {
    return loanStages;
  }

  get taskTypes(): EnumerationItem[] {
    return taskTypes;
  }

  get documentFormats(): EnumerationItem[] {
    return documentFormats;
  }

  get kpiTypes(): EnumerationItem[] {
    return kpiTypes;
  }

  get noOfUnits(): EnumerationItem[] {
    return noOfUnits;
  }

  get verificationStatuses(): EnumerationItem[] {
    return verificationStatuses;
  }

  get widgetTypes(): EnumerationItem[] {
    return widgetTypes;
  }

  get communicationKpiTypes(): EnumerationItem[] {
    return communicationKpiTypes;
  }

  get docPrepTypes(): EnumerationItem[] {
    return docPrepTypes;
  }

  get docPrepPacketTypes(): EnumerationItem[] {
    return docPrepPacketTypes;
  }

  get docDeliveryMethods(): EnumerationItem[] {
    return documentDeliveryMethods;
  }

  get quarters(): EnumerationItem[] {
    return quarters;
  }

  get mcrLoanTypes(): EnumerationItem[] {
    return mcrLoanTypes;
  }

  get mcrChannels(): EnumerationItem[] {
    return mcrChanels;
  }

  get channels(): EnumerationItem[] {
    return chanels;
  }

  get borrowerCounselingFormatType(): EnumerationItem[] {
    return borrowerCounselingFormatType;
  }

  get languages(): EnumerationItem[] {
    return languages;
  }

  get rotationStrategies(): EnumerationItem[] {
    return rotationStrategies;
  }

  get partyTypes(): EnumerationItem[] {
    return partyTypes;
  }

  get partyTypesForExpressions(): EnumerationItem[] {
    return partyTypesForExpressions;
  }

  get deedTypes(): EnumerationItem[] {
    return deedTypes;
  }

  get entityTypes(): EnumerationItem[] {
    return entityTypes;
  }

  get partyEntityTypes(): EnumerationItem[] {
    return partyEntityTypes;
  }

  get borrowerRoles(): EnumerationItem[] {
    return borrowerRoles;
  }

  get mcrApplicationPipelinePositions(): EnumerationItem[] {
    return mcrApplicationPipelinePositions;
  }

  get mcrPropertyTypes(): EnumerationItem[] {
    return mcrPropertyTypes;
  }

  get mcrLoanPurposes(): EnumerationItem[] {
    return mcrLoanPurposes;
  }

  get mcrLienTypes(): EnumerationItem[] {
    return mcrLienTypes;
  }

  get mcrMortgageDirections(): EnumerationItem[] {
    return mcrMortgageDirections;
  }

  get mcrReverseMortgageTypes(): EnumerationItem[] {
    return mcrReverseMortgageTypes;
  }
  get mcrReverseMortgagePurposes(): EnumerationItem[] {
    return mcrReverseMortgagePurposes;
  }

  get mcrQualifiedMortgageTypes(): EnumerationItem[] {
    return mcrQualifiedMortgageTypes;
  }
  get mcrMortgageServicingDispositions(): EnumerationItem[] {
    return mcrMortgageServicingDispositions;
  }

  get disclosureTypes(): EnumerationItem[] {
    return disclosureTypes;
  }

  get disclosureReasons(): EnumerationItem[] {
    return disclosureReasons;
  }

  get cocReasonTypes(): EnumerationItem[] {
    return cocReasonTypes;
  }

  get eventTypes(): EnumerationItem[] {
    return eventTypes;
  }

  get awsRegions(): EnumerationItem[] {
    return awsRegions;
  }

  get compensationTypes(): EnumerationItem[] {
    return compensationTypes;
  }

  get buydownTypes(): EnumerationItem[] {
    return buydownTypes;
  }

  get creditProviders(): EnumerationItem[] {
    return encompassCreditProviders;
  }

  getEnumValue = (name: string): string => {
    return this._enumValues[name];
  };

  getRawEnums = (name: string): { displayName: string, name: string, value: string }[] => {
    if (!this._rawEnums[name]) {
      return []
    }
    return this._rawEnums[name];
  }

  getAllCountries = (): Observable<CountryEnumerationItem[]> => {
    if (this._allCountries) {
      return of(this._allCountries);
    }

    // countries has been populated from address "https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.json"
    return this._dataService.getRelativePath('./assets/countries.json').pipe(
      map((result) => {
        this._allCountries = result;
        return this._allCountries;
      })
    );
  }

  getAllOperators = (): Observable<Operator[]> => {
    if (this._allOperators) {
      return of(this._allOperators);
    }

    return this._dataService.getRelativePath('./assets/operators.json').pipe(
      map((result) => {
        this._allOperators = result;
        return this._allOperators;
      })
    );
  }

  getEnumerationsByType = (typeName: string): Observable<EnumerationItem[]> => {
    return this.getEnumerations(typeName)
      .pipe(
        map((result) => {
          return result[Object.keys(result)[0]];
        })
      );
  }

  private getEnumerations = (namespace: string): Observable<any> => {
    return this._dataService
      .post(`api/Lookup/enum/namespace/${namespace}?api_key=lookup`, {})
      .pipe(
        map((result) => {
          return this.getEnumerationResult(result);
        })
      );
  };

  private doGetCommonEnumerations = (): Observable<any> => {
    return this._dataService
      .post(`api/Lookup/enum/namespace/LE.Lodasoft.Common.Enums?matchNamespaceExactly=true`, {})
      .pipe(
        map((result) => {
          return this.getEnumerationResult(result);
        })
      );
  };

  private getEnumerationResult = (enumDefinitions: any) => {
    let enums = {};
    for (var enumType in enumDefinitions) {
      var enumeration = enumDefinitions[enumType];
      var pieces = enumType.split(/[\s.]+/);
      var enumName = pieces[pieces.length - 1];
      this._rawEnums[enumName] = enumeration;
      enums[enumName] = [];
      for (var i = 0; i <= enumeration.length - 1; i++) {
        var value = enumeration[i].value;
        if (this.bindToNameAsValue) {
          value = enumeration[i].name;
        }
        if (!enumeration[i].hidden) {
          var convertedEnumeration = new EnumerationItem(
            enumeration[i].displayName,
            value
          );
          convertedEnumeration.alternateValue = enumeration[i].name;
          convertedEnumeration.supportedPricingVendors = enumeration[i].supportedPricingVendors
          enums[enumName].push(convertedEnumeration);
        }
        if (!this._enumValues.hasOwnProperty(enumName + enumeration[i].name)) {
          this._enumValues[enumName + enumeration[i].name] = value;
        }
      }
    }
    return enums;
  };
}
