import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { LeadRouteEventType } from '../modules/leads/models/lead-event.model';
import { LeadRouteGroup } from '../modules/leads/models/lead-route-group.model';
import { LeadRouteHistory, LeadRouteType } from '../modules/leads/models/lead-route-history.model';
import { AvailableFreeForAllLead } from '../models/available-free-for-all-lead';

@Injectable({
  providedIn: 'root'
})
export class LeadRouteService {

  private _eventTracker = new BehaviorSubject<LeadRouteEvent>(null);

  constructor(private readonly _dataService: DataService) { }

  get events(): BehaviorSubject<LeadRouteEvent> {
    return this._eventTracker;
  }

  publish(param: LeadRouteEvent): void {
    this._eventTracker.next(param);
  }

  getAvailableLeads = (): Observable<AvailableFreeForAllLead[]> => {
    const url = 'api/leadroutes/get-available-leads';
    return this._dataService.post(url, {});
  };

  // return an object based on what was routed.  Could be agent, app, lead, custom contact list
  takeLead = (leadRouteHistoryId: number): Observable<any> => {
    const url = `api/leadroutes/take-lead/${leadRouteHistoryId}`;
    return this._dataService.post(url, {});
  }

  getLeadRouteGroups = (filterType: LeadRouteType, includeLeadRouteDetail: boolean = true, includeLeadRouteCounts: boolean = true, includeExpressions: boolean = true, includeUserRouteCounts: boolean = true, includeUserStateSettings: boolean = true): Observable<LeadRouteGroup[]> => {
    let url = `api/leadroutes/groups`;

    url += '?' + _.compact([
      filterType ? `filterType=${filterType}` : '',
      includeLeadRouteDetail ? `includeLeadRouteDetail=${includeLeadRouteDetail}` : '',
      includeLeadRouteCounts ? `includeLeadRouteCounts=${includeLeadRouteCounts}` : '',
      includeExpressions ? `includeExpressions=${includeExpressions}` : '',
      includeUserRouteCounts ? `includeUserRouteCounts=${includeUserRouteCounts}` : '',
      includeUserStateSettings ? `includeUserStateSettings=${includeUserStateSettings}` : ''
    ]).join('&');

    return this._dataService.get(url);
  }

  getLeadRouteHistoriesByGroup = (leadRouteGroupId: number, filterStartDate?: Date, filterEndDate?: Date): Observable<LeadRouteHistory[]> => {
    let url = "api/leadroutes/groups/" + leadRouteGroupId + "/history";

    if (filterStartDate || filterEndDate) {
      url += '?' + _.compact([
        filterStartDate ? `filterStartDate=${DateTime.fromJSDate(filterStartDate).toLocaleString(DateTime.DATE_SHORT, { locale: "en-US" })}` : '',
        filterEndDate ? `filterEndDate=${DateTime.fromJSDate(filterEndDate).toLocaleString(DateTime.DATE_SHORT, { locale: "en-US" })}` : '',
      ]).join('&');
    }

    return this._dataService.get(url);
  }

}


export class LeadRouteEvent {
  eventType: LeadRouteEventType
  data: AvailableFreeForAllLead;
}
