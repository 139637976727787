import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { EConsentHistoryPopoverComponent } from "./e-consent-history-popover.component";
import { TableModule } from "primeng/table";

@NgModule({
  imports: [
    SharedModule,
    TableModule
  ],
  declarations: [
    EConsentHistoryPopoverComponent
  ],
  exports: [
    EConsentHistoryPopoverComponent
  ],
  providers: [
  ]
})
export class EConsentHistoryPopoverModule {
  constructor() {
  }
}
