<div class="grid-layout has-border">
  <div class="grid-header">
    <div class="grid-header-left">
      {{ borrower.firstName + ' ' + borrower.lastName }}
    </div>
    <div class="grid-header-right"></div>
  </div>
  <div class="grid-layout-row px-2 mb-2">
    <urla-grid-cell [disabled]="isReadOnly" [ngClass]="{ 'bg-transparent': !isReadOnly }"
      class="col-md-10 grid-layout-text" [label]="
        'Has the Borrower(s) completed homebuyer education (group or web-based classes) within the last 12 months?'
      ">
    </urla-grid-cell>
    <urla-radio-group class="col-md-2" [ngClass]="{ 'bg-transparent': !isReadOnly }" [(ngModel)]="hasHomebuyerEducation"
      [name]="'hasHomebuyerEducation' + borrower.borrowerId" [readonly]="isReadOnly" [options]="yesNoOptions"
      (change)="onBorrowerCounselingEventChanged('Education')" layout="horizontal" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig">
    </urla-radio-group>
  </div>
  <div class="w-100" *ngIf="hasHomebuyerEducation">
    <ng-container *ngFor="let event of borrower.counselingEvents | filter: { counselingType: 'Education' }; let index = index">
      <urla-borrower-counseling-event [borrower]="borrower" [isReadOnly]="isReadOnly" [inEditMode]="inEditMode"
        [urlaFieldsConfig]="urlaFieldsConfig" [borrowerCounselingFormatTypes]="formatTypes" [event]="event" (attemptedToDeleteEvent)="onDelete($event)">
      </urla-borrower-counseling-event>
    </ng-container>
    <div class="grid-layout-row px-2">
      <div class="form-group">
        <button type="button" class="btn btn-sm btn-outline-primary" *ngIf="!isReadOnly"
          (click)="onAddNewCounselingEventClicked('Education')">
          <i class="fas fa-plus me-1"></i>
          New Event
        </button>
      </div>
    </div>
  </div>
  <div class="grid-layout-row px-2 mb-2">
    <urla-grid-cell [disabled]="isReadOnly" [ngClass]="{ 'bg-transparent': !isReadOnly }"
      class="col-md-10 grid-layout-text" [label]="
        'Has the Borrower(s) completed housing counseling (customized counselor-to-client services) within the last 12 months?'
      ">
    </urla-grid-cell>
    <urla-radio-group class="col-md-2" [ngClass]="{ 'bg-transparent': !isReadOnly }" [(ngModel)]="hasCounseling"
      [name]="'hasCounseling' + borrower.borrowerId" [readonly]="isReadOnly" [options]="yesNoOptions"
      (change)="onBorrowerCounselingEventChanged('Counseling')" layout="horizontal" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig">
    </urla-radio-group>
  </div>
  <div class="w-100" *ngIf="hasCounseling">
    <ng-container *ngFor="let event of borrower.counselingEvents | filter: { counselingType: 'Counseling' }; let index = index">
      <urla-borrower-counseling-event [borrower]="borrower" [isReadOnly]="isReadOnly" [inEditMode]="inEditMode"
        [urlaFieldsConfig]="urlaFieldsConfig" [borrowerCounselingFormatTypes]="formatTypes" [event]="event" (attemptedToDeleteEvent)="onDelete($event)">
      </urla-borrower-counseling-event>
    </ng-container>
    <div class="grid-layout-row px-2">
      <div class="form-group">
        <button type="button" class="btn btn-sm btn-outline-primary" *ngIf="!isReadOnly"
          (click)="onAddNewCounselingEventClicked('Counseling')">
          <i class="fas fa-plus me-1"></i>
          New Event
        </button>
      </div>
    </div>
  </div>
</div>
