import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export function createFadeAnimation(): AnimationTriggerMetadata {
  const fadeInStyle = style({
    opacity: 1,
  });
  const fadeOutStyle = style({
    opacity: 0,
  });
  const duration = '225ms';
  const fadeInTiming = `${duration} ease-out`;
  const fadeOutTiming = `${duration} ease-out`;

  return trigger('fade', [
    transition(':enter', [
      fadeOutStyle,
      animate(fadeInTiming, fadeInStyle),
    ]),
    transition(':leave', [
      fadeInStyle,
      animate(fadeOutTiming, fadeOutStyle),
    ]),
  ]);
}
