import { Component, Injector, Input, ViewChild, OnInit, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constants } from 'src/app/services/constants';
import { UrlaMortgage } from '../models/urla-mortgage.model';
import { UrlaSectionComponent } from '../urla-section/urla-section.component';
import { DeclarationExplanationType, MortgageBorrower } from 'src/app/models';
import { groupBy } from 'lodash';
import { MenuItemStatus } from '../../tpo/models/enums/menu-item-status.enum';
import { BorrowerValidityStatus } from '../borrower-information/borrower-info/borrower-info.component';
import { BorrowerTabSelectedEvent, TabbedBorrowerComponentType } from '../urla-main/urla-main.component';
import { scrollByElementTopAfterViewChecked } from '../services/urla-utils';
import { UtilityService } from '../services/utility.service';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';
import { createIsRequiredFunction } from '../urla-utils';
import { createExplanation } from './declarations-utils';
import { MortgageFieldConfig, MortgageModelFieldsConfig } from '../models/urla-fields-config.model';

@Component({
  selector: 'declarations',
  templateUrl: 'declarations.component.html',
  styleUrls: ['./declarations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeclarationsComponent extends UrlaSectionComponent implements OnInit {

  @ViewChild("declarationsForm")
  urlaSectionForm: NgForm;

  @Output()
  borrowerTabSelected: EventEmitter<BorrowerTabSelectedEvent> = new EventEmitter<BorrowerTabSelectedEvent>();

  @Input()
  set urlaFieldsConfig(urlaFieldsConfig: MortgageModelFieldsConfig) {
    if (urlaFieldsConfig) {
      this._urlaFieldsConfig = urlaFieldsConfig;
      this._isRequired = createIsRequiredFunction(this._urlaFieldsConfig);
    }
  }

  get urlaFieldsConfig(): any {
    return this._urlaFieldsConfig;
  }

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    if (mortgage && mortgage.mortgageId) {
      this._mortgage = mortgage;
      this.initialize();
    }
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  menuItem: string = Constants.menu.urlaMenuItems.declarations;

  borrowerValidityStatusInfo: Map<number, MenuItemStatus> = new Map<number, MenuItemStatus>();

  selectedBorrower: MortgageBorrower;

  borrowerGroups: MortgageBorrower[][] = [];

  isPurchase: boolean = false;

  protected getBorrowerDisplayName: (borrower: MortgageBorrower) => string = () => '';

  protected isStatusLoaded: boolean = false;

  private _mortgage: UrlaMortgage;

  private _urlaFieldsConfig = {};

  private _isRequired: (fieldName: string) => boolean;

  constructor(
    injector: Injector,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _utilityService: UtilityService,
    private readonly _urlaValidationService: UrlaValidationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getBorrowerDisplayName = this._utilityService.getBorrowerDisplayName;

    this.initialize();
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForDeclarations(this.mortgage);
    super.setMenuItemStatus(validityStatus);
  }

  onBorrowerSelected = (borrower: MortgageBorrower, isRedirection: boolean) => {
    this.selectedBorrower = borrower;
    this.borrowerTabSelected.emit({ selectedBorrower: this.selectedBorrower, sourceComponent: TabbedBorrowerComponentType.Declarations });

    if (isRedirection) {
      const order = 5;
      const element = document.querySelector(`#urlaAccordion > .accordion-item:nth-child(${order})`);
      scrollByElementTopAfterViewChecked(element as HTMLElement);
    }
  }

  selectBorrowerTab = (borrower: MortgageBorrower) => {
    this.selectedBorrower = borrower;
    this._cdRef.detectChanges();
  }

  isSelectedBorrower(borrower: MortgageBorrower): boolean {
    return this.selectedBorrower?.borrowerId === borrower.borrowerId;
  }

  onBorrowerValidityStatusChanged = (validityStatus: BorrowerValidityStatus) => {
    const borrower = this.mortgage.borrowers.find(b => b.borrowerId == validityStatus.borrowerId);
    if (borrower) {
      const borrowerBasedValidityStatus = this._urlaValidationService.getStatusForBorrowerDeclarations(borrower, this.isPurchase);
      this.borrowerValidityStatusInfo.set(validityStatus.borrowerId, borrowerBasedValidityStatus);
      this.validate();
      setTimeout(() => {
        this.isStatusLoaded = true;
        this._cdRef.detectChanges();
      });
    }
  }

  private initialize = () => {
    if (!this.selectedBorrower || !this._mortgage.borrowers.find(b => b === this.selectedBorrower)) {
      this.onBorrowerSelected(this._mortgage.borrowers[0], false);
    }
    this.adjustBorrowers(this._mortgage.borrowers);
    this._mortgage.borrowers.forEach(borrower => {
      this.initializeRequiredExplanations(borrower);
    });
    this.isPurchase = this._mortgage.subjectProperty.purposeOfLoan === "Purchase";
    if (this.mortgage?.borrowers && this.mortgage.borrowers.length) {
      this.mortgage.borrowers.forEach(borrower => {
        const borrowerBasedValidityStatus = this._urlaValidationService.getStatusForBorrowerDeclarations(borrower, this.isPurchase);
        this.borrowerValidityStatusInfo.set(borrower.borrowerId, borrowerBasedValidityStatus);
      });
    }
    this.isStatusLoaded = true;
  }

  private adjustBorrowers = (borrowers: MortgageBorrower[]) => {
    this.borrowerGroups = [];
    this.borrowerGroups = borrowers.length > 0 ? Object.values(groupBy(borrowers, 'printApplicationIndex')) : [];
  }

  private addExplanationIfRequiredAndNotExists = (borrower: MortgageBorrower, explanationType: DeclarationExplanationType, field: string) => {
    if (this._isRequired(field)) {
      if (borrower.declarations.explanations.find(e => e.explanationType === explanationType) === undefined) {
        const explanation = createExplanation(explanationType);
        if (explanation) {
          if (!borrower.declarations.explanations) {
            borrower.declarations.explanations = [];
          }
          borrower.declarations.explanations.push(explanation);
          //this._cdRef.detectChanges();
        }
      }
    }
  }

  private initializeRequiredExplanations = (borrower: MortgageBorrower) => {
    if (borrower.declarations.borrowedDownPayment) {
      this.addExplanationIfRequiredAndNotExists(borrower, DeclarationExplanationType.BorrowedDownPayment, "mortgage.borrower.declarations.borrowedDownPayment");
    }
    if (borrower.declarations.coSignedLoan) {
      this.addExplanationIfRequiredAndNotExists(borrower, DeclarationExplanationType.CoMakerEndorserOnNote, "mortgage.borrower.declarations.coSignedLoan");
    }
    if (borrower.declarations.haveJudgement) {
      this.addExplanationIfRequiredAndNotExists(borrower, DeclarationExplanationType.OutstandingJudgments, "mortgage.borrower.declarations.haveJudgement");
    }
    if (borrower.declarations.haveTaxDebt) {
      this.addExplanationIfRequiredAndNotExists(borrower, DeclarationExplanationType.DelinquencyOrDefault, "mortgage.borrower.declarations.haveTaxDebt");
    }
    if (borrower.declarations.haveLawsuit) {
      this.addExplanationIfRequiredAndNotExists(borrower, DeclarationExplanationType.PartyToLawsuit, "mortgage.borrower.declarations.haveLawsuit");
    }
    if (borrower.declarations.haveConveyedTitleInLieuOfForeclosure) {
      this.addExplanationIfRequiredAndNotExists(borrower, DeclarationExplanationType.ObligatedOnLoanForeclosedOrDeedInLieuOfJudgment, "mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure");
    }
    if (borrower.declarations.haveCompletedShortSale) {
      this.addExplanationIfRequiredAndNotExists(borrower, DeclarationExplanationType.Other, "mortgage.borrower.declarations.haveCompletedShortSale");
    }
    if (borrower.declarations.beenForclosed) {
      this.addExplanationIfRequiredAndNotExists(borrower, DeclarationExplanationType.DirectIndirectForeclosedPropertyPastSevenYears, "mortgage.borrower.declarations.beenForclosed");
    }
    if (borrower.declarations.declaredBankruptcy) {
      this.addExplanationIfRequiredAndNotExists(borrower, DeclarationExplanationType.DeclaredBankruptcyPastSevenYears, "mortgage.borrower.declarations.declaredBankruptcy");
    }
  }
}
