import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {EnumerationItem} from 'src/app/models/simple-enum-item.model';
import {EnumerationService} from 'src/app/services/enumeration-service';
import {Constants} from 'src/app/services/constants';
import {UrlaMortgage} from '../models/urla-mortgage.model';
import {UrlaSectionComponent} from '../urla-section/urla-section.component';
import {NgForm} from '@angular/forms';
import {UrlaValidationService} from 'src/app/services/urla-validation.service';
import {ApplicationContextService} from 'src/app/services/application-context.service';
import {Subscription} from 'rxjs';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'loan-property',
  templateUrl: 'loan-property.component.html'
})
export class LoanPropertyComponent extends UrlaSectionComponent implements OnInit, OnDestroy {

  @Input()
  mortgage: UrlaMortgage;

  @ViewChild("loanPropertyForm")
  urlaSectionForm: NgForm;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  isSubjectPropertyAddressHidden: boolean;

  @Input()
  isAppraisedValueHidden: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  isTpoUser: boolean = false;

  @Input()
  losIdentifier: string;

  @Output()
  loanPurposeChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loanAmountChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  presentValueChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  hasSubjectPropertyRentalIncomeChange = new EventEmitter<boolean>();

  @Output()
  borrowerReosChanged: EventEmitter<any> = new EventEmitter<any>();

  menuItem: string = Constants.menu.urlaMenuItems.loanAndProperty;

  lienPositionTypes: EnumerationItem[] = [];

  giftGrantSources: EnumerationItem[] = [];

  loanPurposes: EnumerationItem[] = [];

  refinancePurposes: EnumerationItem[] = [];

  refinancePrimaryPurposes: EnumerationItem[] = [];

  propertyTypes: EnumerationItem[] = [];

  propertyConstructionMethods: EnumerationItem[] = [];

  propertyConstructionStatuses: EnumerationItem[] = [];

  assetTypes: EnumerationItem[] = [];

  yesNoOptions: EnumerationItem[] = [];

  states: EnumerationItem[] = [];

  purchaseCreditTypes: EnumerationItem[] = [];

  downPaymentSourceTypes: EnumerationItem[] = [];

  protected hasApplicationReceivedKeyDate: boolean = false;

  private _contextSubscription: Subscription;
  private _keyDatesChangesSubscription: Subscription;

  get isPurchaseAndInvestmentOrSecondHomeOrMultiUnit(): boolean {
    const subjectProperty = this.mortgage.subjectProperty;

    return subjectProperty &&
      ((subjectProperty.purposeOfLoan == this._loanPurposePurchaseEnumValue || subjectProperty.purposeOfLoan == this._loanPurposeRefinanceEnumValue) &&
      (
        subjectProperty.propertyWillBe == this._propertyWillBeInvestmentEnumValue
        || subjectProperty.propertyWillBe == this._propertyWillBeSecondaryEnumValue
        || subjectProperty.propertyWillBe == this._propertyWillBePrimaryEnumValue
      ));
  }

  private _loanPurposePurchaseEnumValue: string;
  private _loanPurposeRefinanceEnumValue: string;
  private _propertyWillBePrimaryEnumValue: string;
  private _propertyWillBeSecondaryEnumValue: string;
  private _propertyWillBeInvestmentEnumValue: string;

  constructor(
    injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _utilsService: UtilityService,
    private readonly _urlaValidationService: UrlaValidationService,
    private readonly _applicationContextService: ApplicationContextService,
  ) {
    super(injector);
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
    this.states = this._enumsService.states;
    this._contextSubscription = this._applicationContextService.context.subscribe(context => {
      if (context.application) {
        this.hasApplicationReceivedKeyDate = !!context.applicationKeyDatesByType['applicationReceived']?.eventDate;
      }
    });
    this._keyDatesChangesSubscription = this._applicationContextService.keyDatesChanges.subscribe(keyDates => {
      this.hasApplicationReceivedKeyDate = !!keyDates['applicationReceived']?.eventDate;
    });
  }

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this._loanPurposePurchaseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Purchase);
      this._loanPurposeRefinanceEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Refinance);
      this._propertyWillBePrimaryEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.PrimaryResidence);
      this._propertyWillBeSecondaryEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.SecondaryResidence);
      this._propertyWillBeInvestmentEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.Investment);
      this.loanPurposes = enums[Constants.enumerations.loanPurposes];
      this.refinancePurposes = enums[Constants.enumerations.refinancePurposes];
      this.refinancePrimaryPurposes = enums[Constants.enumerations.refinancePrimaryPurposes];
      this.propertyTypes = enums[Constants.enumerations.propertyTypes];
      this.propertyConstructionMethods = enums[Constants.enumerations.propertyConstructionMethod];
      this.propertyConstructionStatuses = enums[Constants.enumerations.propertyConstructionStatus];
      this.lienPositionTypes = enums[Constants.enumerations.lienPositionType];
      this.giftGrantSources = enums[Constants.enumerations.giftGrantSource];
      this.assetTypes = enums[Constants.enumerations.assetTypes];
      this.purchaseCreditTypes = enums[Constants.enumerations.purchaseCreditTypes];
      this.downPaymentSourceTypes = enums[Constants.enumerations.downPaymentSourceTypes];
    })
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._contextSubscription?.unsubscribe();
    this._keyDatesChangesSubscription?.unsubscribe();
  }

  validate = () => {
    this._utilsService.updateUrlaFieldConfigBasedOnMortgage(this.urlaFieldsConfig, this.mortgage, !!this.losIdentifier);

    const validityStatus = this._urlaValidationService.getStatusForLoanProperty(this.mortgage, this.isAppraisedValueHidden);
    super.setMenuItemStatus(validityStatus);
    this.menuService.setStatus('qualifyingTheBorr', this._urlaValidationService.getStatusForQualifyingTheBorr(this.mortgage));
  }

  onLoanPurposeChanged = () => {
    this.loanPurposeChanged.emit();
  }

  onLoanAmountChanged = (newAmount: number) => {
    this.loanAmountChanged.emit(newAmount);
  }

  onPresentValueChanged = (newPresentValue: number) => {
    this.presentValueChanged.emit(newPresentValue);
  }

  onBorrowerReosChanged = () => {
    this.borrowerReosChanged.emit();
  }

}
