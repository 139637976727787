import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'qa-cancel-button',
  templateUrl: './qa-cancel-button.component.html',
})
export class QaCancelButtonComponent {
  @Input() text: 'Cancel' | 'Close' = 'Cancel';
  @Output() qaClick = new EventEmitter<void>();
}
