import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { assign, chain, cloneDeep, concat, isArray, merge } from 'lodash';
import { Utils } from 'src/app/core/services/utils';
import { finalize, Subscription } from 'rxjs';
import {
  AusDuAgenciesArray,
  DuHistory,
  LpaHistory,
  DuUnderwriteResponse,
  ThirdPartyCredential,
  LpaProperty,
  LpaErrorArray,
  CreditReport,
  ApplicationContext,
  AusDuAgency,
  Profile,
  AusLender,
  AusRequest,
  MortgageBorrower,
  SystemLevel,
  DoHistory,
  UserType,
  KeyDate
} from 'src/app/models';
import { CreditVendorSelectOption, DUProvider, DUProviderItem } from 'src/app/models/aus/du-providers.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanServicesService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { AusDuComponent } from '../../..';
import { FreddieMacUnderwriteRequest } from '../../models/freddie-mac-underwrite-request.model';
import { AusDoComponent } from '../aus-do/aus-do.component';
import { AusLpaComponent } from '../aus-lpa/aus-lpa.component';
import { Router } from "@angular/router";
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MenuService } from 'src/app/services/menu.service';
import { MenuItemStatus } from 'src/app/modules/tpo/models/enums/menu-item-status.enum';
import { Channel } from 'src/app/modules/leads/models/lead.model';
import { KeyDatesService } from 'src/app/services/key-dates.service';

//todo not sure about this solution for window.ActiveXObject to put in here
declare global {
  interface Window {
    ActiveXObject: any;
  }
}
@Component({
  selector: 'automated-underwriting-system',
  templateUrl: 'aus.component.html',
  styleUrls: ['./aus.component.scss'],
})
export class AusComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input() loanId: number;
  @Input() embedded: boolean = false;

  companyId: number;
  userId: string;
  isTPOUser: boolean;
  duResult: DuHistory | DuUnderwriteResponse;
  doResult: DoHistory;
  lpaResult: LpaHistory;
  lpaXML: any;
  lpaErrors: LpaErrorArray = [];
  duAgencies: AusDuAgenciesArray = [];
  doAgencies: AusDuAgenciesArray;
  duProviders: DUProvider;
  doProviders: DUProvider;
  ausLenders: Array<AusLender> = [];
  creditVendorList: Array<CreditVendorSelectOption> = [];
  doCreditVendorList: Array<CreditVendorSelectOption> = [];
  duHistory: DuHistory[] = [];
  doHistory: DoHistory[] = [];
  lpaHistory: LpaHistory[] = [];

  lastRanDu: DuHistory = new DuHistory();
  lastRanDo: DoHistory = new DoHistory();
  lastDoOrder: DuHistory = new DuHistory();
  lastRanLpa: LpaHistory = new LpaHistory();

  creditReportingHistory: CreditReport[] = [];
  mortgage: UrlaMortgage;
  userProfileSettings: Profile;
  duParams: any = {};
  doParams: any = {};
  lpaParams: FreddieMacUnderwriteRequest;

  ausVendorCopy: ThirdPartyCredential;
  lpaVendor: ThirdPartyCredential = new ThirdPartyCredential();
  duVendor: ThirdPartyCredential = new ThirdPartyCredential();
  doVendor: ThirdPartyCredential = new ThirdPartyCredential();
  lpaProperties: LpaProperty = new LpaProperty();
  isDesktopUnderwriterEnabled: boolean;
  isDOEnabled: boolean;
  isLpaEnabled: boolean;
  showLPASystemLevelLink: boolean;
  showDOSystemLevelLink = false;
  showDUSystemLevelLink = false;

  userDuCreds: Array<ThirdPartyCredential> = new Array<ThirdPartyCredential>();
  systemDuCreds: Array<ThirdPartyCredential> = new Array<ThirdPartyCredential>();

  userDoCreds: Array<ThirdPartyCredential> = new Array<ThirdPartyCredential>();
  systemDoCreds: Array<ThirdPartyCredential> = new Array<ThirdPartyCredential>();

  userLpaCreds: Array<ThirdPartyCredential> = new Array<ThirdPartyCredential>();
  systemLpaCreds: Array<ThirdPartyCredential> = new Array<ThirdPartyCredential>();
  technicalAffiliates: Array<any>;
  technicalAffiliateCodes: Array<any>;

  isLoadingLastRanDU: boolean = true;
  isLoadingLastRanDO: boolean = true;
  isLoadingLastRanLPA: boolean = true;
  runningLpaProcess: boolean;
  runningDuProcess: boolean;
  runningDoProcess: boolean;
  isDuHistoryOpened: boolean;
  isDoHistoryOpened: boolean;
  isLpaHistoryOpened: boolean;
  isGovernmentLoan: boolean = false;
  loanChannel: string = null;

  lpaErrorMessage: string;
  lpaVerificationCompanies: Array<EnumerationItem>;

  borrowerNames: string[] = [];

  forceEdit: boolean;
  editBrokerLender: boolean;

  protected isLoanReadOnly: boolean = false;

  private _loanInfoChangesSubscription: Subscription;
  xhttp: any = null;

  private _systemLevel: SystemLevel;

  lpaXSLFilePaths = {
    creditInFile: '/views/loan/partial/DualAus/LPA/CreditInfile.xsl',
    docChecklist: '/views/loan/partial/DualAus/LPA/DocCheckList.xsl',
    errors: '/views/loan/partial/DualAus/LPA/Errors.xsl',
    fullFeedback: '/views/loan/partial/DualAus/LPA/FullFeedback.xsl',
    hve: '/views/loan/partial/DualAus/LPA/hve.xsl',
    mergedCredit: '/views/loan/partial/DualAus/LPA/MergedCredit.xsl',
    mergedCreditCertificate:
      '/views/loan/partial/DualAus/LPA/MergedCreditCertificate.xsl',
  };

  private _forceEditChangesSubscription: Subscription;
  private _editBrokerLenderChangeSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _keyDatesService: KeyDatesService,
    private readonly _notificationService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _enumsService: EnumerationService,
    private readonly _menuService: MenuService,
    private readonly _router: Router,
  ) {
    super(injector);
  }

  ngOnInit() {
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.initialize(context);
      }
    });

    this.initialize(this.applicationContext);

    this._forceEditChangesSubscription = this._loanServicesService.forceEditChanges.subscribe(value => {
      this.forceEdit = value;
    });

    this._editBrokerLenderChangeSubscription = this._loanServicesService.editBrokerLenderChanges.subscribe(value => {
      this.editBrokerLender = value;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._forceEditChangesSubscription) {
      this._forceEditChangesSubscription.unsubscribe();
    }
    if (this._editBrokerLenderChangeSubscription) {
      this._editBrokerLenderChangeSubscription.unsubscribe();
    }
  }

  private initialize = (context: ApplicationContext) => {
    if (!this.loanId) {
      this.loanId = context.application.applicationId;
    }
    this.isTPOUser = context.userPermissions.userType == UserType.Tpo;

    this.isLoanReadOnly = context.applicationIsReadOnly;

    this.companyId = context.userPermissions.companyId;
    this.userId = context.userPermissions.userId;
    this.isDesktopUnderwriterEnabled = this.applicationContext.userPermissions.desktopUnderwriterEnabled;
    this.isDOEnabled = this.applicationContext.userPermissions.desktopOriginatorEnabled;
    this.isLpaEnabled = this.applicationContext.userPermissions.lpaEnabled;
    this.userProfileSettings = context.currentlyLoggedInUserProfile;
    this.loanChannel = this.applicationContext.application?.channel;

    this.mortgage = context.currentMortgage;
    if (this.mortgage) {
      this.isGovernmentLoan = this.mortgage.mortgageTerm && (this.mortgage.mortgageTerm.mortgageAppliedFor == 'FHA' || this.mortgage.mortgageTerm.mortgageAppliedFor == 'VA' || this.mortgage.mortgageTerm.mortgageAppliedFor == 'USDA');
    }
    this.populateEnums();

    this._systemLevelService.getSystemLevel().subscribe(systemLevel => {
      this._systemLevel = systemLevel;

      //filter hgere
      this.userDuCreds = this.userProfileSettings.thirdPartyCredentials
        .filter(el => el.vendorName === "DU" && el.credentialType === "AusVendor" && ((!this.loanChannel && !el.channels) || (el.channels && el.channels.indexOf(this.loanChannel) > -1)));

      this.systemDuCreds = this._systemLevel.thirdPartyCredentials
        .filter(el => el.vendorName === "DU" && el.credentialType === "AusVendor" && ((!this.loanChannel && !el.channels) || (el.channels && el.channels.indexOf(this.loanChannel) > -1)));

      this.userDoCreds = this.userProfileSettings.thirdPartyCredentials
        .filter(el => el.vendorName === "FannieMae" && el.credentialType === "AUSOriginator" && ((!this.loanChannel && !el.channels) || (el.channels && el.channels.indexOf(this.loanChannel) > -1)));

      this.systemDoCreds = this._systemLevel.thirdPartyCredentials
        .filter(el => el.vendorName === "FannieMae" && el.credentialType === "AUSOriginator" && ((!this.loanChannel && !el.channels) || (el.channels && el.channels.indexOf(this.loanChannel) > -1)));

      this.userLpaCreds = this.userProfileSettings.thirdPartyCredentials
        .filter(el => el.vendorName === "LPA" && el.credentialType === "AusVendor" && ((!this.loanChannel && !el.channels) || (el.channels && el.channels.indexOf(this.loanChannel) > -1)));

      this.systemLpaCreds = this._systemLevel.thirdPartyCredentials
        .filter(el => el.vendorName === "LPA" && el.credentialType === "AusVendor" && ((!this.loanChannel && !el.channels) || (el.channels && el.channels.indexOf(this.loanChannel) > -1)));

      this.getAndLoadServiceDetails(this.mortgage);

    });
  }

  //DU
  private setDuResult = <T>(duResult: T): T => {
    if (duResult) {
      duResult['isOldFormat'] = false;
      this.duParams.duCasefileId = duResult['vendorFileId'];
      this.duParams.submissionType = 'Resubmit';
      this.duParams.writeCreditCredsToUserProfile = false;
      this.duParams.writeAusCredsToUserProfile = false;
      this.duParams.brokerLenderId = duResult['brokerLenderId'];
      this.duParams.creditVendor = duResult['requestData']?.thirdPartyCreditProviderId;
      if (isArray(duResult['requestData']?.creditRequestDetail)) {
        this.updateDuCreditReferenceFromResultSet(duResult['requestData'].creditRequestDetail);
        duResult['refNoCsv'] = chain(duResult['requestData'].creditRequestDetail)
          .map(req => req.creditReferenceNumber)
          .compact()
          .uniq()
          .join(', ')
          .value()
      } else {
        duResult['refNoCsv'] = '';
      }
      duResult['documents'] = chain(duResult['documents'])
        .filter((document) => {
          return document.mimeType !== 'application/xml';
        })
        .map((document) => {
          document.extension = this._loanServicesService.getExtensionByMimeType(
            document.mimeType
          );
          return document;
        })
        .orderBy(doc => doc.fileName, 'desc')
        .value();
    } else {
      this.duParams.submissionType = 'New';
    }

    return duResult;
  };

  private setBrokerLenderFieldLock() {
    this.doParams.lockBrokerLenderField = this.doParams.hasHistory && this.doParams.brokerLenderId != '';
  }

  private setDoResult = <T>(doResult: T): T => {
    if (doResult) {
      this.doParams.vendorFileId = doResult['vendorFileId'];
      this.doParams.status = doResult['status'];
      this.doParams.isSuccessful = doResult['isSuccessful'];
      this.doParams.hasHistory = true;
      this.doParams.writeCreditCredsToUserProfile = false;
      this.doParams.writeAusCredsToUserProfile = false;
      this.doParams.submissionStatus = doResult['submissionStatus'];
      this.doParams.finalizeDo = this.doParams.submissionStatus === 'Final';
      if (isArray(doResult['requestData']?.creditRequestDetail)) {

        doResult['refNoCsv'] = chain(doResult['requestData'].creditRequestDetail)
          .map(req => req.creditReferenceNumber)
          .compact()
          .uniq()
          .join(', ')
          .value()
      } else {
        doResult['refNoCsv'] = '';
      }

      this.setBrokerLenderFieldLock();

      doResult['documents'] = chain(doResult['documents'])
        .filter((document) => {
          return document.mimeType !== 'application/xml';
        })
        .map((document) => {
          document.extension = this._loanServicesService.getExtensionByMimeType(
            document.mimeType
          );
          return document;
        })
        .orderBy(doc => doc.fileName, 'desc')
        .value();
    }
    return doResult;
  };

  private getDoCreditRequests(mortgage: UrlaMortgage) {
    return chain(mortgage.borrowers)
      .orderBy(borrower => [borrower.printApplicationIndex, borrower.jointWithBorrowerId], ['asc', 'desc'])
      .groupBy('printApplicationIndex')
      .map((value, key) => ({
        groupTitle: isNaN(Number(key)) ? 'App 0' : ('App ' + key),
        borrowerName: this.getBorrowerFullName(value),
        issueType: 'Reissue',
        creditReferenceNumber: this.getBorrowerCreditRef(value[0]),
        borrowerIds: value.map(borrower => borrower.contactId)
      }))
      .value();
  }
  private getDuCreditRequests(mortgage: UrlaMortgage) {
    return chain(mortgage.borrowers)
      .orderBy(borrower => [borrower.printApplicationIndex, borrower.jointWithBorrowerId], ['asc', 'desc'])
      .groupBy('printApplicationIndex')
      .map((value, key) => ({
        groupTitle: isNaN(Number(key)) ? 'App 0' : ('App ' + key),
        borrowerName: this.getBorrowerFullName(value),
        issueType: 'Reissue',
        creditReferenceNumber: this.getBorrowerCreditRef(value[0]),
        borrowerIds: value.map(borrower => borrower.contactId)
      }))
      .value();
  }

  private updateDuCreditReferenceFromResultSet(creditRequests) {
    this.duParams.creditRequests.forEach(cr => {
      const refNo = creditRequests.find(borr => cr.borrowerIds.includes(borr.borrowerId))?.creditReferenceNumber;
      cr.creditReferenceNumber = refNo || '';
      cr.issueType = 'Reissue';
    });
  }

  private onDuProcessSuccessfully = (result: DuHistory, reloadLoan: boolean = false) => {
    if (result.isSuccessful && reloadLoan) {
      this.applicationContextService.reloadLoan(this.applicationContext.application.applicationId).subscribe(context => {
      });
    }

    this._notificationService.showSuccess(
      'Run DU process successfully',
      'AUS Loan Service'
    );

    this.duResult = this.setDuResult<DuHistory>(result);
    this.duHistory.push(this.duResult);
    this.lastRanDu = cloneDeep(this.duResult);
    this.updateKeyDatesAfterRunAUS();
  };

  private loadDUCreditProviders() {
    if (!this.duAgencies || !this.duProviders) {
      return;
    }
    this.creditVendorList = concat([
      {
        grpName: 'My Providers',
        options: this.duProviders.userProfileCreditProviders.map((el: DUProviderItem) => (
          {
            name: `${el.key}${el.alias ? ' - ' + el.alias : ''}`,
            value: el.key,
            userName: el.userName,
            password: el.password
          }))
      },
      {
        grpName: 'Company Providers',
        options: this.duProviders.systemLevelCreditProviders.map((el: DUProviderItem) => (
          {
            name: `${el.key}${el.alias ? ' - ' + el.alias : ''}`,
            value: el.key,
            userName: el.userName,
            password: el.password
          }))
      },
      {
        grpName: 'Other Providers',
        options: this.duAgencies.map((el: AusDuAgency) => (
          {
            name: `${el.name}${el.alias ? ' - ' + el.alias : ''}`,
            value: el.name,
            userName: '',
            password: ''
          }))
      }
    ]);
  }

  private onDoProcessSuccessfully = (result: DuHistory) => {
    if (result.isSuccessful) {
      this.applicationContextService.reloadLoan(this.applicationContext.application.applicationId).subscribe(context => {
      });
    }
    this._notificationService.showSuccess('Run DO Request successfully.', 'AUS Loan Service');
    this.doResult = this.setDoResult<DuHistory>(result);

    this.doHistory.push(this.doResult);
    this.lastRanDo = cloneDeep(this.doResult);
    this.updateKeyDatesAfterRunAUS();
  };

  private loadBorrowerNames = () => {
    this.borrowerNames = [];
    for (let i = 0; i <= this.lpaParams.creditRequest.jointBorrowerCreditRequests.length - 1; i++) {
      const creditRequest = this.lpaParams.creditRequest.jointBorrowerCreditRequests[i];
      let borrowerName = "";
      creditRequest.borrowerIds.forEach(borrowerId => {
        const borrower = this.mortgage.borrowers.find(b => b.contactId === borrowerId);
        if (borrower) {
          const nameToAdd = Utils.getPersonsFullName(borrower);
          borrowerName = (borrowerName ? (borrowerName + ` & ${nameToAdd}`) : nameToAdd);
        }
      })
      this.borrowerNames.push(borrowerName);
    }
  }

  private onGotLpaParamsSuccessfully = (result) => {
    if (result.ausResponse.isSuccessful) {
      this.applicationContextService.reloadLoan(this.applicationContext.application.applicationId).subscribe(context => {
      });
    }
    this.lpaErrorMessage = '';
    this._notificationService.showSuccess(
      'Run LPA process successfully',
      'AUS Loan Service'
    );
    this.setLpaResult(result.ausResponse);

    this.lpaHistory.push(this.lpaResult);
    this.lastRanLpa = cloneDeep(this.lpaResult);
    this.updateKeyDatesAfterRunAUS();
  };

  private setLpaResult = (result: LpaHistory | any) => {
    let parser = new DOMParser();
    this.lpaResult = result;

    if (this.lpaResult.documents) {
      this.lpaResult.documents = this.lpaResult.documents.map((el) => {
        if (!el.fileName.includes('.')) {
          el['extension'] = this._loanServicesService.getExtensionByMimeType(
            el.mimeType
          );
        }
        return el;
      });
    }
    this.lpaXML = result.rawResponse
      ? parser.parseFromString(result.rawResponse, 'text/xml')
      : null;

    this.lpaParams = { ...this.lpaParams, ...result.requestData };

    this.generateCreditRequestForLpaIfMissing();

    if (!this.lpaParams.loanStatus)
      this.lpaParams.loanStatus = this.isTPOUser ? 'Underwriting' : 'Application';

    this.lpaParams.keyIdentifier = result.keyIdentifier || '';
    this.lpaParams.loanIdentifier = result.loanIdentifier || '';
    this.lpaErrors = result.errors || [];
    this.runningLpaProcess = false;
  };

  private generateCreditRequestForLpaIfMissing = () => {
    if (!this.lpaResult) {
      this.lpaResult = new LpaHistory();
    }

    if (!this.lpaResult.requestData) {
      this.lpaResult.requestData = new FreddieMacUnderwriteRequest();
    }

    if (!this.lpaResult.requestData.creditRequest ||
      !this.lpaResult.requestData.creditRequest.jointBorrowerCreditRequests ||
      !this.lpaResult.requestData.creditRequest.jointBorrowerCreditRequests.length) {
      this.generateCreditRequestForLpa();
    }

    if (!this.lpaParams.creditRequest.creditVendor && this.lpaResult.creditVendor) {
      this.lpaParams.creditRequest.creditVendor = this.lpaResult.creditVendor;
      if (this.lpaParams.creditRequest.creditVendor) {
        this.loadTechnicalAffiliates();
      }
    }
  }

  loadTechnicalAffiliates = () => {
    this.technicalAffiliates = [];

    if (!this.lpaParams.creditRequest.creditVendor) return;

    this._loanServicesService
      .getTechnicalAffiliates(this.lpaParams.creditRequest.creditVendor)
      .subscribe({
        next: this.onGotTechnicalAffiliatesSuccessfully,
        error: (err) => {
          this._notificationService.showError(
            err?.message || 'Unable to load technical affiliates', 'AUS Loan Service');
        }
      });
  };

  private onGotTechnicalAffiliatesSuccessfully = (result) => {
    this.technicalAffiliates = result.filter((a) => a.code);
    this.technicalAffiliateCodes = this.technicalAffiliates.map((a) => a.code);
    if (!this.lpaParams.creditRequest.technicalAffiliateCode)
      this.lpaParams.creditRequest.technicalAffiliateCode = '';

    if (!this.mortgage?.borrowers?.length) return;

    const primaryBorrowerCreditReports = this.creditReportingHistory.filter(
      (ele) => ele.borrowerId === this.mortgage.borrowers[0].contactId
    );
    if (!primaryBorrowerCreditReports.length) return;

    const creditTechnicalAffiliateId =
      primaryBorrowerCreditReports[0]
        .creditTechnicalAffiliateId;
    if (!creditTechnicalAffiliateId) return;

    const technicalAffiliate = this.technicalAffiliates.find(
      (el) => el.creditTechnicalAffiliateId === creditTechnicalAffiliateId
    );

    if (!technicalAffiliate) return;

    this.lpaParams.creditRequest.technicalAffiliateCode =
      technicalAffiliate.code;
    this.lpaParams.creditRequest.technicalAffiliateName = technicalAffiliate.name;
  };


  private generateCreditRequestForLpa = () => {
    this.lpaParams.creditRequest.jointBorrowerCreditRequests = [];
    this.mortgage.borrowers.forEach((borrower) => {
      //LPA Specific

      if (borrower.jointWithBorrowerId) {
        const primaryBorrower = this.mortgage.borrowers.find(b => b.borrowerId === borrower.jointWithBorrowerId);
        const mergedBorrowerIndex =
          this.lpaParams.creditRequest.jointBorrowerCreditRequests.findIndex(
            (el) => el.borrowerIds.indexOf(primaryBorrower.contactId) > -1
          );
        if (mergedBorrowerIndex > -1) {
          this.lpaParams.creditRequest.jointBorrowerCreditRequests[
            mergedBorrowerIndex
          ].borrowerIds.push(borrower.contactId);
          if (primaryBorrower) {
            this.lpaParams.creditRequest.jointBorrowerCreditRequests[mergedBorrowerIndex]
              .creditReferenceNumber = primaryBorrower.creditRefNumber;
          }
        } else {
          const primaryBorrower = this.mortgage.borrowers.find(b => b.borrowerId === borrower.jointWithBorrowerId);
          this.addBorrowerToLpaCreditRequest(primaryBorrower);
        }
      } else {
        this.addBorrowerToLpaCreditRequest(borrower);
      }
    });
    this.loadBorrowerNames();
  }

  private addBorrowerToLpaCreditRequest = (borrower) => {
    var refNumber = this.getBorrowerCreditRef(borrower);
    let borrowerCreditRequest = {
      creditRequestType: (refNumber ? 'Reissue' : 'Order'),
      borrowerIds: [borrower.contactId],
      creditReferenceNumber: refNumber
    }

    this.lpaParams.creditRequest.jointBorrowerCreditRequests.push(borrowerCreditRequest);
  };

  private getBorrowerCreditRef(borrower): string {
    if (borrower.creditRefNumber)
      return borrower.creditRefNumber;

    var borrCreditReports = this.creditReportingHistory.filter(x => x.borrowerId == borrower.contactId);
    if (borrCreditReports.length > 0)
      return borrCreditReports[0].refNumber;
    return null;
  }

  private getTechnicalAffiliate(borrower): string {

    var borrCreditReports = this.creditReportingHistory.filter(x => x.borrowerId == borrower.contactId);
    if (borrCreditReports.length > 0)
      return borrCreditReports[0].creditTechnicalAffiliateId?.toString();
    return null;
  }

  private getBorrowerFullName(borrowers: MortgageBorrower[]): string {
    return borrowers.map(b => `${b.lastName}, ${b.firstName}`).join(' & ');
  }

  private isGovtLoan(): boolean {
    if (!this.mortgage?.mortgageTerm?.mortgageAppliedFor) {
      return false;
    }
    return this.mortgage.mortgageTerm.mortgageAppliedFor === 'VA' ||
      this.mortgage.mortgageTerm.mortgageAppliedFor === 'FHA'
  }

  private loadDOCreditProviders() {
    if (!this.doAgencies || !this.doProviders) {
      return;
    }
    this.doCreditVendorList = concat([
      {
        grpName: 'My Providers',
        options: this.doProviders.userProfileCreditProviders.map((el: DUProviderItem) => (
          {
            name: `${el.key}${el.alias ? ' - ' + el.alias : ''}`,
            value: el.key,
            userName: el.userName,
            password: el.password
          }))
      },
      {
        grpName: 'Company Providers',
        options: this.doProviders.systemLevelCreditProviders.map((el: DUProviderItem) => (
          {
            name: `${el.key}${el.alias ? ' - ' + el.alias : ''}`,
            value: el.key,
            userName: el.userName,
            password: el.password
          }))
      },
      {
        grpName: 'Other Providers',
        options: this.doAgencies.map((el: AusDuAgency) => (
          {
            name: `${el.name}${el.alias ? ' - ' + el.alias : ''}`,
            value: el.name,
            userName: '',
            password: ''
          }))
      }
    ]);
  }

  private fetchDOProviders() {
    if (!this.isDOEnabled) {
      return;
    }
    this._loanServicesService.getDOProviders(this.userId, this.companyId).subscribe({
      next: (doProviders) => {
        this.doProviders = doProviders;
        this.loadDOCreditProviders();
      },
      error: (err) => {
        this.doProviders.systemLevelCreditProviders = [];
        this.doProviders.userProfileCreditProviders = [];
        this._notificationService.showError(err?.message || 'Unable to load DO Providers', 'AUS Loan Service');
      }
    })
  }

  private fetchDOAgencies() {
    if (!this.isDOEnabled || !this.doVendor) {
      return;
    }
    this._loanServicesService.getDOAgencies(true, this.doVendor.credentialId).subscribe({
      next: (doAgencies) => {
        this.doAgencies = doAgencies;
        if (!this.doAgencies.length) {
          this._notificationService.showWarning(
            'There are no credit agencies associated with your DO account. Please contact your Fannie Mae administrator',
            'AUS Loan Service'
          );
        }
        this.loadDOCreditProviders();
      },
      error: (err) => {
        if (err && err.status === 403) {
          this.showDOSystemLevelLink = true;
        }
        this._notificationService.showError(err?.message || 'Unable to load DO Agencies', 'AUS Loan Service');
      }
    });
  }

  private loadAUSLenders() {
    if (!this.doVendor) { return }
    this._loanServicesService.getDOLenders(this.doVendor.credentialId).subscribe({
      next: (lenders) => {
        if (!lenders) {
          lenders = [];
        }
        if (!lenders.length) {
          this._notificationService.showWarning(
            'There are no sponsoring lenders associated with your DO account. Please contact your Fannie Mae administrator',
            'AUS Loan Service'
          );
        }
        let lender = lenders.find(l => this.lastDoOrder?.submittingInstitutionId &&
          l.thirdPartyInstitutionId == this.lastDoOrder.submittingInstitutionId);
        if (lender) {
          this.ausLenders = lender.brokerSponsors;
          return;
        }
        const sysLevelDefault = this.doVendor?.thirdPartyKeyValuePairs.find(k => k.key === 'SubmittingInstitutionId')?.value;
        lender = lenders.find(l => sysLevelDefault && l.thirdPartyInstitutionId === sysLevelDefault)
        if (lender) {
          this.ausLenders = lender.brokerSponsors;
          return;
        }
        const defaultLender = lenders.find(l => l.isDefaultInstitution === true);
        this.ausLenders = defaultLender
          ? defaultLender.brokerSponsors
          : lenders.length > 0
            ? lenders[0].brokerSponsors
            : [];
      },
      error: (err) => {
        if (err && err.status === 403) {
          this.showDOSystemLevelLink = true;
        }
        this._notificationService.showError(err?.message || 'Unable to load DO Lenders', 'AUS Loan Service');
      }
    });
  }

  private fetchDUAgencies() {
    if (!this.isDesktopUnderwriterEnabled || !this.duVendor) {
      return;
    }
    this._loanServicesService.getDuAgencies(true, this.isTPOUser ? null : this.duVendor.credentialId).subscribe({
      next: (duAgencies) => {
        this.duAgencies = duAgencies;
        if (!this.duAgencies.length) {
          this._notificationService.showWarning(
            'There are no credit agencies associated with your DU account. Please contact your Fannie Mae administrator',
            'AUS Loan Service'
          );
        }
        this.loadDUCreditProviders();
      },
      error: (err) => {
        if (err && err.status === 403) {
          this.showDUSystemLevelLink = true;
        }
        this._notificationService.showError(err?.message || 'Unable to load DU Agencies', 'AUS Loan Service');
      }
    });
  }

  private fetchDUProviders() {
    if (!this.isDesktopUnderwriterEnabled) {
      return;
    }
    this._loanServicesService.getDUProviders(this.userId, this.companyId).subscribe({
      next: (duProviders) => {
        this.duProviders = duProviders;
        this.loadDUCreditProviders();
      },
      error: (err) => {
        this.duProviders.systemLevelCreditProviders = [];
        this.duProviders.userProfileCreditProviders = [];
        this._notificationService.showError(err?.message || 'Unable to load DU Providers', 'AUS Loan Service');
      }
    })
  }

  // shared DUAL and DU
  runDu = (forceOpenModal: boolean = false) => {
    if (!this.isDesktopUnderwriterEnabled) {
      return;
    }
    if (!this.isDuFieldsSet()) {
      if (forceOpenModal) {
        this.openDUModal();
      }
      return;
    }
    this.runningDuProcess = true;
    const request: AusRequest = {
      creditRequestDetail: this.processDuCreditRequestsForSubmit(),
      thirdPartyCreditProviderId: this.duParams.creditVendor,

      creditProviderUsernameOverride: this.duParams.creditVendorUserName || undefined,
      creditProviderPasswordOverride: this.duParams.creditVendorPassword || undefined,
      writeCreditCredsToUserProfile: this.duParams.writeCreditCredsToUserProfile || false,
      writeAusCredsToUserProfile: this.duParams.writeAusCredsToUserProfile || false,
      applicationId: this.duParams.applicationId,
      ausUsernameOverride: this.duParams.duUsernameOverride || undefined,
      ausPasswordOverride: this.duParams.duPasswordOverride || undefined
    }
    if (this.lastRanDu?.vendorFileId || this.duParams.duCasefileId) {
      request.thirdPartyOrderId = this.duParams.duCasefileId;
    }
    this._loanServicesService
      .runDuProcess(request)
      .pipe(finalize(() => { this.runningDuProcess = false }))
      .subscribe({
        next: (res) => this.onDuProcessSuccessfully(res, true),
        error: (err) => {
          if (err && err.status === 403) {
            this.showDUSystemLevelLink = true;
          }
          this.runningDuProcess = false;
          this._notificationService.showError(
            err?.message || 'Unable to run DU process',
            'AUS Loan Service'
          );
        }
      })
      .add(() => {
        this.applicationContextService.applicationTrackingStatusesChanged();
      });
  }

  // shared DUAL and LPA
  runLpa = (forceOpenModal: boolean = false) => {
    if (!this.isLpaEnabled) {
      return;
    }
    if (!this.isLpaValid()) {
      if (forceOpenModal) {
        this.openLPAModal();
      }
      return;
    }
    this.runningLpaProcess = true;
    const lpaParams = cloneDeep(this.lpaParams);

    if (
      this.lpaVendor &&
      (this.lpaVendor.userName !== this.ausVendorCopy?.userName ||
        this.lpaVendor.password !== this.ausVendorCopy?.password)
    ) {
      lpaParams.authUserId = this.lpaVendor.userName;
      lpaParams.authPassword = this.lpaVendor.password;
    }
    this._loanServicesService.runLpaProcess(lpaParams)
      .pipe(finalize(() => this.runningLpaProcess = false))
      .subscribe({
        next: this.onGotLpaParamsSuccessfully,
        error: (err) => {
          if (err && err.status === 403) {
            this.showLPASystemLevelLink = true;
          }
          this.lpaErrorMessage = err?.message || 'Unable to run LPA process';
          this.runningLpaProcess = false;
          this._notificationService.showError(this.lpaErrorMessage, 'AUS Loan Service');
        }
      })
      .add(() => {
        this.applicationContextService.applicationTrackingStatusesChanged();
      });
  }

  runDo = (forceOpenModal: boolean = false) => {
    if (this.doParams.submissionStatus === 'Final' || !this.isDOEnabled) {
      return;
    }
    if (!this.isDoCreditRefSet() || !this.isDoSponsoringLenderSet()) {
      if (forceOpenModal) {
        this.openDOModal();
      }
      return;
    }
    this.runningDoProcess = true;
    const request: AusRequest = {
      creditRequestDetail: this.processDoCreditRequestsForSubmit(),
      thirdPartyCreditProviderId: this.doParams.creditVendor,
      creditProviderUsernameOverride: this.doParams.creditVendorUserName || undefined,
      creditProviderPasswordOverride: this.doParams.creditVendorPassword || undefined,
      thirdPartyLenderId: this.doParams.brokerLenderId,
      finalizeOrder: this.doParams.finalizeDo,
      writeCreditCredsToUserProfile: this.doParams.writeCreditCredsToUserProfile || false,
      writeAusCredsToUserProfile: this.doParams.writeAusCredsToUserProfile || false,
      applicationId: this.doParams.applicationId,
      ausUsernameOverride: this.doParams.doUsernameOverride || undefined,
      ausPasswordOverride: this.doParams.doPasswordOverride || undefined
    }
    if (this.lastDoOrder?.vendorFileId || this.doParams.vendorFileId) {
      request.thirdPartyOrderId = this.doParams.vendorFileId;
    }
    this._loanServicesService.finalizeDO(request)
      .pipe(finalize(() => this.runningDoProcess = false))
      .subscribe({
        next: (result) => {
          this.onDoProcessSuccessfully(result);
        },
        error: (err) => {
          if (err && err.status === 403) {
            this.showDOSystemLevelLink = true;
          }
          this._notificationService.showError(err?.message || 'Unable to send DO request', 'DO Request');
        }
      })
      .add(() => {
        this.applicationContextService.applicationTrackingStatusesChanged();
      });
  }

  // DUAL
  runDualAus = () => {
    if (this.isDesktopUnderwriterEnabled) {
      this.runDu(true);
    } else if (this.isDOEnabled) {
      this.runDo(true);
    }

    if (this.isLpaEnabled) {
      this.runLpa(true);
    }
  };

  private processDuCreditRequestsForSubmit() {
    if (!this.duParams?.creditRequests) return [];

    const crRequest = [];
    this.duParams.creditRequests.forEach(cr => {
      cr.borrowerIds.forEach(borrowerId => {
        crRequest.push({
          creditReferenceNumber: cr.issueType === 'New'
            ? null : cr.creditReferenceNumber,
          borrowerId: borrowerId
        })
      });
    });
    return crRequest;
  }

  private processDoCreditRequestsForSubmit() {
    if (!this.doParams?.creditRequests) return [];

    const crRequest = [];
    this.doParams.creditRequests.forEach(cr => {
      cr.borrowerIds.forEach(borrowerId => {
        crRequest.push({
          creditReferenceNumber: cr.issueType === 'New'
            ? null : cr.creditReferenceNumber,
          borrowerId: borrowerId
        })
      });
    });
    return crRequest;
  }

  openDOModal() {
    const modalRef = this._modalService.open(AusDoComponent, Constants.modalOptions.large);
    modalRef.componentInstance.doParams = this.doParams;
    modalRef.componentInstance.creditVendorList = this.doCreditVendorList;
    modalRef.componentInstance.ausLenders = this.ausLenders;
    modalRef.componentInstance.allowedRolesToFinalizeDOFindingsConfig = this._systemLevel?.allowedRolesToFinalizeDOFindings;
    modalRef.componentInstance.forceEdit = this.forceEdit;
    modalRef.componentInstance.editBrokerLender = this.editBrokerLender;
    modalRef.componentInstance.isTPOUser = this.isTPOUser;
    modalRef.result.then((doParams: DuHistory) => {
      assign(this.doParams, doParams);
      this.runDo();
    }, () => {

    });
  }

  openDUModal() {
    const modalRef = this._modalService.open(AusDuComponent, Constants.modalOptions.large);
    const duParams = this.duParams;

    modalRef.componentInstance.duParams = duParams;
    modalRef.componentInstance.duVendor = this.duVendor;
    modalRef.componentInstance.creditVendorList = this.creditVendorList;
    modalRef.componentInstance.isTPOUser = this.isTPOUser;
    modalRef.componentInstance.isLoanReadOnly = this.isLoanReadOnly;

    modalRef.result.then((duParams: any) => {
      assign(this.duParams, duParams);
      this.runDu();
    }, (duHistory: DuHistory) => {
      if (duHistory) {
        this.onDuProcessSuccessfully(duHistory);
      }
    });
  }

  openLPAModal() {
    const modalRef = this._modalService.open(AusLpaComponent, Constants.modalOptions.large);
    modalRef.componentInstance.mortgage = this.mortgage;
    modalRef.componentInstance.isTPOUser = this.isTPOUser;
    modalRef.componentInstance.lpaParams = this.lpaParams;
    modalRef.componentInstance.creditReportingHistory = this.creditReportingHistory;
    modalRef.componentInstance.lpaVendor = this.lpaVendor;
    modalRef.componentInstance.lpaProperties = this.lpaProperties;
    modalRef.componentInstance.lpaVerificationCompanies = this.lpaVerificationCompanies;

    modalRef.result.then((lpaParams: any) => {
      if (!lpaParams.ausPasswordOverride) {
        delete lpaParams.ausPasswordOverride;
        delete lpaParams.ausUsernameOverride;
      }
      assign(this.lpaParams, lpaParams);
      this.runLpa();
    }, () => {

    });
  }

  setCreditVendorAndCreds(vendor: ThirdPartyCredential) {
    const vendorWithCreds = { creditVendor: '', creditVendorUserName: '', creditVendorPassword: '' };
    const configuredCreditProviders = vendor.thirdPartyKeyValuePairs.filter(el => el.key?.includes('CreditProvider'));
    const defaultCreditProvider = vendor.thirdPartyKeyValuePairs.find(el => el.key === 'DefaultCreditProvider');
    if (defaultCreditProvider) {
      vendorWithCreds.creditVendor = defaultCreditProvider.value;
      let matchFound = false;
      configuredCreditProviders.filter(el => el.key.startsWith('CreditProvider:'))
        .forEach(item => {
          if (matchFound) {
            return;
          }
          const providerName = item.key.split(':')[1];
          if (providerName === defaultCreditProvider.value) {
            vendorWithCreds.creditVendorUserName = item.userName;
            vendorWithCreds.creditVendorPassword = item.password;
            matchFound = true;
          }
        });
    }
    return vendorWithCreds;
  }

  downloadDuFile = (document) => {
    this.downloadFile(document, true);
  };

  downloadLpaFile = (document) => {
    this.downloadFile(document, false);
  };

  private downloadFile = (document, isDu: boolean) => {
    const duOrLpaHistoryDocumentId = isDu
      ? document.duHistoryDocumentId
      : document.lpaHistoryDocumentId;
    this._loanServicesService
      .downloadDualFormat(isDu, duOrLpaHistoryDocumentId)
      .subscribe({
        next: (res) => {
          let blob = new Blob([res], {
            type: document.mimeType,
          });
          let fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        },
        error: (err) => {
          this._notificationService.showError(
            err?.message || 'Unable to download a file',
            'AUS Loan Service'
          );
        }
      });
  }

  isDoCreditRefSet = () => {
    if (!this.isDOEnabled) {
      return false;
    }

    if (this.doParams.vendorFileId) {
      return true;
    }
    const hasMissingRefNumber = this.doParams.creditRequests
      .some(credit => credit.issueType === 'Reissue' && !credit.creditReferenceNumber);

    return (
      this.doParams.creditVendor &&
      !hasMissingRefNumber
    );
  };

  isDoSponsoringLenderSet = () => {
    if (!this.isDOEnabled) {
      return false;
    }

    if (this.doParams.vendorFileId) {
      return true;
    }

    return !this.isGovtLoan() || this.doParams.brokerLenderId;
  };

  isDuFieldsSet = () => {
    if (!this.isDesktopUnderwriterEnabled)
      return false;

    if (this.duParams.duCasefileId) {
      return true;
    }
    const hasMissingRefNumber = this.duParams.creditRequests
      .some(credit => credit.issueType === 'Reissue' && !credit.creditReferenceNumber);

    return this.duParams.creditVendor && !hasMissingRefNumber;
  };

  isLpaValid = () => {
    if (this.lpaParams.getMergedCreditReport) {
      const missingRefNumber = this.lpaParams.creditRequest.jointBorrowerCreditRequests
        .some(cr => cr.creditRequestType !== 'Order' && !cr.creditReferenceNumber)
      return !missingRefNumber &&
        !!this.lpaParams.creditRequest.creditVendor;
    }
    return true;
  };

  canRunDualAUS() {
    return (
      !this.isDesktopUnderwriterEnabled &&
      !this.isLpaEnabled &&
      !this.isDOEnabled ||
      (
        ((this.isDesktopUnderwriterEnabled && !this.isDuFieldsSet()) || (this.isDOEnabled && !this.isDoCreditRefSet() && !this.isDoSponsoringLenderSet())) &&
        ((this.isLpaEnabled && !this.isLpaValid()) || this.lpaErrorMessage || (this.lpaResult && this.lpaResult.status))
      )
    ) ||
      (
        this.runningLpaProcess ||
        this.runningDuProcess ||
        this.runningDoProcess
      )
  }

  displayLpaResults = (xslFilePath) => {
    const xslFile: Node = this.loadXMLDoc(xslFilePath);
    if (!xslFile) return console.log(`Unable to load ${xslFilePath}`);
    // code for IE
    if (window.ActiveXObject || this.xhttp.responseType == 'msxml-document') {
      const ex = this.lpaXML.transformNode(xslFile);
      document.getElementById('lpa-result').innerHTML = ex; //todo not sure why its hete (in admin V1 we had only in an old version of loan services)
    }
    // code for Chrome, Firefox, Opera, etc.
    else if (
      document.implementation &&
      document.implementation.createDocument
    ) {
      const xsltProcessor = new XSLTProcessor();
      xsltProcessor.importStylesheet(xslFile);
      const resultDocument = xsltProcessor.transformToFragment(
        this.lpaXML,
        document
      );
      const win = window.open();
      win.document.open();
      win.document.write(new XMLSerializer().serializeToString(resultDocument));
      win.document.close();
    }
  };

  private loadXMLDoc = (xslFilePath) => {
    this.xhttp = window.ActiveXObject
      ? new window.ActiveXObject('Msxml2.XMLHTTP')
      : new XMLHttpRequest();
    this.xhttp.open('GET', xslFilePath, false);

    try {
      this.xhttp.responseType = 'msxml-document';
    } catch (err) { } // Helping IE11

    this.xhttp.send('');
    return this.xhttp.responseXML;
  };

  private populateEnums = () => {
    this._enumsService.getLpaVerificationCompanies()
      .subscribe((vCompEnum) => {
        this.lpaVerificationCompanies = vCompEnum['LpaVerificationCompany'] || [];
      });
  }

  onShowHistoryClicked = (type: "DU" | "FannieMae" | "LPA") => {
    if (type === "DU") {
      this.isDuHistoryOpened = !this.isDuHistoryOpened;

      if (!this.isDuHistoryOpened) {
        this.duResult = this.setDuResult(this.lastRanDu);
      }
    }

    if (type === "FannieMae") {
      this.isDoHistoryOpened = !this.isDoHistoryOpened;

      if (!this.isDoHistoryOpened) {
        this.doResult = this.setDoResult(this.lastRanDo);
      }
    }

    if (type === "LPA") {
      this.isLpaHistoryOpened = !this.isLpaHistoryOpened;

      if (!this.isLpaHistoryOpened) {
        this.setLpaResult(this.lastRanLpa);
      }
    }
  }

  onDuHistoryCheckChanged = (e: any, item: DuHistory) => {
    if (e.target.checked) {
      this.showDuHistoryDetails(item);
    }
  }

  onDoHistoryCheckChanged = (e: any, item: DoHistory) => {
    if (e.target.checked) {
      this.showDoHistoryDetails(item);
    }
  }

  showDuHistoryDetails = (item: DuHistory) => {
    this.duResult = this.setDuResult(item);
  }

  showDoHistoryDetails = (item: DoHistory) => {
    this.doResult = this.setDoResult(item);
  }

  showLpaHistoryDetails = (item: LpaHistory) => {
    this.setLpaResult(item);
  }

  goToSystemLevel = () => {
    return this._router.navigateByUrl('/admin/system-level/integrations#aus');
  };

  private loadDoDetails = (mortgage: UrlaMortgage) => {
    this.doParams = {
      applicationId: this.loanId,
      creditRequests: this.getDoCreditRequests(mortgage),
      creditVendor: '',
      vendorFileId: null,
      brokerLenderId: '',
      writeCreditCredsToUserProfile: false,
      writeAusCredsToUserProfile: false,
      lockBrokerLenderField: false,
      finalizeDo: false,
      hasHistory: false,
      submissionStatus: false
    };

    this.isLoadingLastRanDO = true;
    this._loanServicesService.getDuHistory(this.loanId).subscribe({
      next: (ranDoHistory) => {
        this.doHistory = ranDoHistory || [];

        this.lastRanDo = (isArray(ranDoHistory) && ranDoHistory.length > 0) ? ranDoHistory[0] : null;

        this.doVendor = this.userDoCreds.length > 0 ? this.userDoCreds[0] : null;
        if (!this.doVendor) {
          this.doVendor = this.systemDoCreds.length > 0 ? this.systemDoCreds[0] : null;
        }

        if (this.lastRanDo) {
          this.lastRanDo = this.setDoResult<DoHistory>(this.lastRanDo);
        }

        if (this.doVendor && !this.doParams.creditVendor) {
          merge(this.doParams, this.setCreditVendorAndCreds(this.doVendor));
        }

        this.loadAUSLenders();

        this.fetchDOAgencies();
        this.fetchDOProviders();

        this._loanServicesService.getLatestDOOrder(this.loanId).subscribe({
          next: (lastDoOrder) => {
            this.lastDoOrder = lastDoOrder;
            this.doResult = this.setDoResult<DuHistory>(this.lastDoOrder);
            this.isLoadingLastRanDO = false;
            if (this.lastDoOrder?.requestData) {

              this.doParams.applicationId = this.lastDoOrder.requestData.applicationId;
              this.doParams.creditVendor = this.lastDoOrder.requestData.thirdPartyCreditProviderId;
              this.doParams.vendorFileId = this.lastDoOrder.vendorFileId;
              this.doParams.brokerLenderId = this.lastDoOrder.brokerLenderId || '';
              this.doParams.hasHistory = true;
              this.doParams.status = this.lastDoOrder.status;
              this.doParams.isSuccessful = this.lastDoOrder.isSuccessful;
              this.doParams.submissionStatus = this.lastDoOrder.submissionStatus;
              this.doParams.finalizeDo = this.lastDoOrder.submissionStatus === 'Final';

              this.setBrokerLenderFieldLock();
              if (this.doVendor && !this.doParams.creditVendor) {
                merge(this.doParams, this.setCreditVendorAndCreds(this.doVendor));
              }
            }
          },
          error: (err) => {
            if (err && err.status === 403) {
              this.showDUSystemLevelLink = true;
            }
            this.isLoadingLastRanDO = false;
            this._notificationService.showError(err?.message || 'Unable to Update DO Order from Fannie Mae', 'AUS Loan Service');
          }
        });
      },
      error: (error) => {
        this.isLoadingLastRanDO = false;
        this._notificationService.showError(error?.message || "Couldn't load DO history.", "Error!");
      }
    });
  }

  private loadDuDetails = (mortgage: UrlaMortgage) => {
    this.duParams = {
      submissionType: 'New',
      applicationId: this.loanId,
      duCasefileId: '',
      creditRequests: this.getDuCreditRequests(mortgage),
      creditVendor: '',
      writeCreditCredsToUserProfile: false,
      writeAusCredsToUserProfile: false
    };

    this.isLoadingLastRanDU = true;
    this._loanServicesService.getDuHistory(this.loanId).subscribe({
      next: (ranDuHistory) => {
        this.duHistory = ranDuHistory || [];
        this.lastRanDu = (isArray(ranDuHistory) && ranDuHistory.length > 0) ? ranDuHistory[0] : null;

        this.duVendor = this.userDuCreds.length > 0 ? this.userDuCreds[0] : null;
        if (!this.duVendor) {
          this.duVendor = this.systemDuCreds.length > 0 ? this.systemDuCreds[0] : null;
        }

        if (this.lastRanDu) {
          this.duResult = this.setDuResult<DuHistory>(this.lastRanDu);
        }
        if (this.duVendor && !this.duParams.creditVendor) {
          merge(this.duParams, this.setCreditVendorAndCreds(this.duVendor));
        }
        this.isLoadingLastRanDU = false;
        this.fetchDUAgencies();
        this.fetchDUProviders();
      },
      error: (error) => {
        this.isLoadingLastRanDU = false;
        this._notificationService.showError(error?.message || "Couldn't load DU history.", "Error!");
      }
    });
  }

  private loadLpaDetails = (mortgage: UrlaMortgage) => {
    this.lpaParams = new FreddieMacUnderwriteRequest();
    this.lpaParams.applicationId = this.loanId;

    this.generateCreditRequestForLpaIfMissing();

    this.isLoadingLastRanLPA = true;
    this._loanServicesService.getLpaHistory(this.loanId).subscribe({
      next: (ranLpaHistory) => {
        this.lpaHistory = ranLpaHistory || [];

        this.lastRanLpa = (isArray(ranLpaHistory) && ranLpaHistory.length > 0) ? ranLpaHistory[0] : null;

        this.lpaVendor = this.userLpaCreds.length > 0 ? this.userLpaCreds[0] : null;
        if (!this.lpaVendor) {
          this.lpaVendor = this.systemLpaCreds.length > 0 ? this.systemLpaCreds[0] : null;
        }

        this.ausVendorCopy = { ...this.lpaVendor };

        if (this.lpaVendor) {
          this.lpaProperties = this._systemLevelService.getLpaProperties(
            this.lpaVendor.thirdPartyKeyValuePairs
          );
        }

        if (this.lastRanLpa) {
          this.setLpaResult(this.lastRanLpa);
          this.loadBorrowerNames();
          this.isLoadingLastRanLPA = false;
        } else {
          this.lpaResult = new LpaHistory();
          this._loanServicesService.getLpaDefaultParams(this.loanId).subscribe(defaultRequest => {
            this.lpaParams = { ...this.lpaParams, ...defaultRequest };
            this.generateCreditRequestForLpaIfMissing();
          }, error => {
            this._notificationService.showError(error?.message || 'Unable to get default LPA parameters', 'AUS Loan Service');
          }).add(() => {
            this.isLoadingLastRanLPA = false;
          })
        }
      },
      error: (error) => {
        this.isLoadingLastRanLPA = false;
        this._notificationService.showError(error?.message || "Couldn't load LPA history.", "Error!");
      }
    });
  }

  private getAndLoadServiceDetails = (mortgage: UrlaMortgage) => {
    this._loanServicesService.getCreditReportHistoryForApplication(this.loanId)
      .subscribe({
        next: (creditReportingHistory) => {
          this.creditReportingHistory = creditReportingHistory || [];

          if (this.isDesktopUnderwriterEnabled) {
            this.loadDuDetails(mortgage);
          } else {
            this.isLoadingLastRanDU = false;
          }

          if (this.isDOEnabled) {
            this.loadDoDetails(mortgage);
          } else {
            this.isLoadingLastRanDO = false;
          }

          if (this.isLpaEnabled) {
            this.loadLpaDetails(mortgage);
          } else {
            this.isLoadingLastRanLPA = false;
          }
        },
        error: (error) => {
          this.isLoadingLastRanDU = false;
          this.isLoadingLastRanDO = false;
          this.isLoadingLastRanLPA = false;

          this._notificationService.showError(error?.message || "Couldn't load credit report history.", "Error!");
        }
      });
  }

  private updateKeyDatesAfterRunAUS = () => {
    this._keyDatesService.getKeyDates(this.loanId).subscribe({
      next: (keyDates) => {
        this.applicationContextService.updateKeyDates(keyDates as KeyDate[]);
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Couldn't load key dates.", "Error!");
      }
    })

  }
}
