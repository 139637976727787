import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {formViewProvider} from 'src/app/core/services/form-view.provider';
import {UrlaMortgage} from '../../models/urla-mortgage.model';
import {MortgageCalculationService} from '../../services/mortgage-calculation.service';
import {Observer, Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {MiOrFundingFeeUtils} from '../../../../shared/utils/mortgage/mi-or-funding-fee-utils';
import {Mortgage} from '../../../../models';
import {distinctUntilChangedWith} from '../../../../shared/utils/rxjs-utils';
import {NgxSpinnerService} from 'ngx-spinner';
import {MortgageService} from 'src/app/services/mortgage.service';
import {MortgageCalculationDetails} from 'src/app/models/mortgage-calculation-details.model';

@Component({
  selector: 'mortgage-loan-details',
  templateUrl: 'mortgage-loan-details.component.html',
  viewProviders: [formViewProvider],
})
export class MortgageLoanDetailsComponent implements OnInit, AfterViewChecked, OnDestroy {
  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input() set mortgage(value: UrlaMortgage) {
    this._mortgage = value;
    this.onFinanceAllChanged({emitEvent: false});
  }

  private _mortgage: UrlaMortgage;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Output()
  loanAmountChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  miAndFundingFeeChanged: EventEmitter<number> = new EventEmitter<number>();
  private readonly _emitMiAndFundingFeeChanged$ = new Subject<number>();
  private _emitMiAndFundingFeeChangedSubscription?: Subscription;
  private readonly _emitMiAndFundingFeeChangedDebounced$ = new Subject<number>();
  private _emitMiAndFundingFeeChangedDebouncedSubscription?: Subscription;

  protected miOrFundingFeeAmount: number = 0;
  private invalidateMiOrFundingFeeAmountField: (mortgage: Mortgage) => void =
    MiOrFundingFeeUtils.invalidateMiOrFundingFeeAmountField.bind(this);

  constructor(
    private readonly _calculationService: MortgageCalculationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _mortgageService: MortgageService
  ) {}

  ngOnInit() {
    this.subscribeToEmitMiAndFundingFeeChanged();
    this.subscribeToEmitMiAndFundingFeeChangedDebounced();
  }

  ngAfterViewChecked() {
    // TODO: Replace this temporary solution with a performant method to handle
    //  changes from the calculation dialog.
    this.invalidateMiOrFundingFeeAmountField(this._mortgage);
  }

  ngOnDestroy() {
    this._emitMiAndFundingFeeChangedSubscription?.unsubscribe();
    this._emitMiAndFundingFeeChangedDebouncedSubscription?.unsubscribe();
  }

  private subscribeToEmitMiAndFundingFeeChanged() {
    this._emitMiAndFundingFeeChangedSubscription?.unsubscribe();
    this._emitMiAndFundingFeeChangedSubscription = this._emitMiAndFundingFeeChanged$
      .pipe(distinctUntilChangedWith([this.miAndFundingFeeChanged]))
      .subscribe(value => this.miAndFundingFeeChanged.emit(value));
  }

  private subscribeToEmitMiAndFundingFeeChangedDebounced() {
    this._emitMiAndFundingFeeChangedDebouncedSubscription?.unsubscribe();
    this._emitMiAndFundingFeeChangedDebouncedSubscription =
      this._emitMiAndFundingFeeChangedDebounced$
        .pipe(debounceTime(1000), distinctUntilChangedWith([this.miAndFundingFeeChanged]))
        .subscribe(value => this.miAndFundingFeeChanged.emit(value));
  }

  protected emitMiAndFundingFeeChanged(
    value: number,
    options?: {
      debounce?: boolean;
    }
  ): void {
    if (options?.debounce) {
      this._emitMiAndFundingFeeChangedDebounced$.next(value);
    } else {
      this._emitMiAndFundingFeeChanged$.next(value);
    }
  }

  loanAmountChange = () => {
    this._spinner.show();
    const observer: Observer<MortgageCalculationDetails> = {
      next: calculationDetails => {
        this._mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest =
          calculationDetails.proposedExpenses.firstMortgagePrincipalAndInterest;

        this.mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest =
          calculationDetails.proposedExpenses.otherMortgageLoanPrincipalAndInterest;

        this._mortgage.calculatedStats.proposedMonthlyPaymentTotal =
          this._calculationService.calculateHousingExpenseTotal(
            this._mortgage.proposedHousingExpense
          );
        this.calculateTotalMortgage();
        this.loanAmountChanged.emit(this._mortgage.mortgageTerm.amount);
      },
      error: () => {},
      complete: () => {},
    };
    this._mortgageService
      .redoMortgageCalculationDetails(this.mortgage)
      .subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  };

  private calculateTotalMortgage = () => {
    const calculatedStats = this._mortgage.calculatedStats;

    calculatedStats.totalLoanOrDrawAmount = this._calculationService.calculateTotalLoanOrDrawAmount(
      this._mortgage
    );
    calculatedStats.totalMortgageLoans = this._calculationService.calculateTotalMortgage(
      this._mortgage
    );
    calculatedStats.totalMortgageLoansAndCredits =
      this._calculationService.calculateTotalMortgageLoansAndCredits(this._mortgage);
    calculatedStats.totalDueFromBorrowers = this._calculationService.calculateTotalDueFromBorrowers(
      this._mortgage
    );
    calculatedStats.cashFromOrToTheBorrower =
      this._calculationService.calculateCashFromOrToTheBorrower(this._mortgage);
  };

  protected onFinanceAllChanged(options?: {emitEvent?: boolean}): void {
    this.invalidateMiOrFundingFeeAmountField(this._mortgage);
    this.onMiOrFundingFeeAmountChange(this.miOrFundingFeeAmount, options);
  }

  protected onMiOrFundingFeeAmountChange(value: number, options?: {emitEvent?: boolean}): void {
    // TODO: If it isn't changed, can we return directly?
    //  For now, calculateTotalMortgage is still called as a precaution.
    const isChanged = this.miOrFundingFeeAmount !== value;
    this.miOrFundingFeeAmount = value;
    this.calculateTotalMortgage();

    // If the event is emitted even though there is no change, it may cause unnecessary repeated API
    // calls during initialization.
    if (isChanged && (options?.emitEvent ?? true)) {
      this.emitMiAndFundingFeeChanged(value, {debounce: true});
    }
  }
}
