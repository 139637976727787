import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { Address, BorrowerAlias, Employment, EmploymentTypeEnum, MortgageBorrower, RealEstateOwned } from 'src/app/models';
import { UrlaMortgage } from '../models/urla-mortgage.model';
import { MortgageFieldConfig } from '../models/urla-fields-config.model';
import { MortgageUtils } from 'src/app/shared/utils/mortgage/mortgage-utils';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

type NamedLikeBorrower = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nameSuffix?: string;
}

export enum UrlaRequiredState {
  None = 'none',
  Requested = 'requested',
  Required = 'required'
}
@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _domSanitizer: DomSanitizer
  ) { }

  /**
   * Retrieves the full name of a borrower.
   *
   * @param {NamedLikeBorrower|undefined} borrower - The borrower object.
   * @returns {string} - The full name of the borrower.
   *
   * @example
   *  const borrower = {
   *    firstName: 'John',
   *    middleName: 'Doe',
   *    lastName: 'Smith',
   *    nameSuffix: 'Jr.',
   *  };
   *
   *  console.log(getBorrowerFullName(borrower)); // Output: 'John Doe Smith Jr.'
   *
   * @example
   * const noSuffixBorrower = {
   *  firstName: 'John',
   *  middleName: 'Doe',
   *  lastName: 'Smith',
   * };
   *
   * console.log(getBorrowerFullName(noSuffixBorrower)); // Output: 'Jane Doe Smith'
   *
   * @example
   *  // In case of undefined borrower it will return an empty string
   *  console.log(getBorrowerFullName(undefined));  // Output: ''
   */
  getBorrowerFullName = (borrower: NamedLikeBorrower | undefined): string => {
    if (!borrower) return '';

    const {
      firstName,
      middleName,
      lastName,
      nameSuffix,
    } = borrower;

    return [
      firstName,
      middleName,
      lastName,
      nameSuffix,
    ].filter(Boolean).join(' ').trim();
  };

  /**
   * Retrieves the display name of a borrower.
   *
   * @param {NamedLikeBorrower|undefined} borrower - The borrower object.
   * @return {string} - The display name of the borrower.
   *
   * @example
   * const borrower: MortgageBorrower = {
   *   firstName: 'John',
   *   lastName: 'Doe',
   *   nameSuffix: 'III',
   * };
   * console.log(getBorrowerDisplayName(borrower)); // Output: "Doe, John III"
   *
   * @example
   * const noSuffixBorrower: MortgageBorrower = {
   *   firstName: 'Jane',
   *   lastName: 'Smith',
   * };
   * console.log(getBorrowerDisplayName(noSuffixBorrower)); // Output: "Smith, Jane"
   *
   * @example
   * // In case of undefined borrower it will return an empty string
   * console.log(getBorrowerDisplayName(undefined)); // Output: ""
   */
  getBorrowerDisplayName(borrower: NamedLikeBorrower | undefined): string {
    if (!borrower) return '';

    const {
      firstName,
      lastName,
      nameSuffix,
    } = borrower;

    const firstAndLastName = [
      lastName,
      firstName,
    ].filter(Boolean).join(', ');

    return [
      firstAndLastName,
      nameSuffix,
    ].filter(Boolean).join(' ').trim();
  }

  getUniqueId = () => {
    const uniqueId = Date.now() & 0xffffffff
    return uniqueId < 0 ? uniqueId : (uniqueId * -1);
  };

  /*
   * Compare two objects by reducing an array of keys in obj1, having the
   * keys in obj2 as the intial value of the result. Key points:
   *
   * - All keys of obj2 are initially in the result.
   *
   * - If the loop finds a key (from obj1, remember) not in obj2, it adds
   *   it to the result.
   *
   * - If the loop finds a key that are both in obj1 and obj2, it compares
   *   the value. If it's the same value, the key is removed from the result.
   */
  getObjectDiff(obj1: any, obj2: any) {
    obj1 = this.omitFalsyValues(obj1);
    obj2 = this.omitFalsyValues(obj2);
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (_.isMatchWith(obj1[key], obj2[key], this.customizer)) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));
    return diff;
  }

  // return isDirty
  logDiffs(namespace, orig, dirty) {
    if (orig == undefined || dirty == undefined || orig == null || dirty == null)
      return false;
    const diffs = this.getObjectDiff(orig, dirty);
    if (diffs.length == 0 || typeof orig !== 'object' || typeof dirty !== 'object')
      return false;
    diffs.forEach(diff => {
      if (!this.logDiffs(namespace + '.' + diff, orig[diff], dirty[diff])) {
        console.log(namespace + '.' + diff, orig[diff], dirty[diff]);
      }
    });
    return true;
  }

  getImageFileContentAsBase64 = (sourcePath: string, doneCallback: Function) => {
    return this._httpClient.get(sourcePath, { responseType: "blob" })
      .subscribe(response => {
        const file = new Blob([response], { type: response.type });
        let reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event) => {
          let base64 = event.target.result as string
          let resourceURL = this._domSanitizer.bypassSecurityTrustResourceUrl(base64);
          doneCallback(resourceURL);
        };
      })
  }

  loadDefaultQuickApplyFieldsConFig = (quickApplyFieldsConfig) => {
    quickApplyFieldsConfig['mortgage.borrower.firstName'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.middleName'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.lastName'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.nameSuffix'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.signingRole'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.primaryEmail'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.mobilePhone'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.entityVestingType'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.languagePreference'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.hasRefusedToProvideLanguage'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.homePhone'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.socialSecNum'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.creditRefNumber'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.dateOfBirth'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.authorizationMethod'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.maritalStatus'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.unmarriedAddendum'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.domesticRelationshipType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.unmarriedRelationshipTypeOther'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.alias.aliasType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.alias.firstName'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.alias.middleName'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.alias.lastName'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.alias.nameSuffix'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.address1'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.address2'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.city'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.state'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.zipCode'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.country'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.durationAtAddress'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.durationYears'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.durationMonths'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.rent'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.residencyAddress.isSubjectProperty'] = UrlaRequiredState.Requested;

    quickApplyFieldsConfig['mortgage.borrower.employment.employer'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.employment.position'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.employment.employmentType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.employment.employerPhone'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.employment.startDate'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.employment.endDate'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.employment.incomes.monthlyIncome'] = UrlaRequiredState.Requested;

    // quickApplyFieldsConfig['mortgage.borrower.employment.address.address1'] = UrlaRequiredState.Requested;
    // quickApplyFieldsConfig['mortgage.borrower.employment.address.address2'] = UrlaRequiredState.None;
    // quickApplyFieldsConfig['mortgage.borrower.employment.address.city'] = UrlaRequiredState.Requested;
    // quickApplyFieldsConfig['mortgage.borrower.employment.address.state'] = UrlaRequiredState.Requested;
    // quickApplyFieldsConfig['mortgage.borrower.employment.address.zipCode'] = UrlaRequiredState.Requested;
    // quickApplyFieldsConfig['mortgage.borrower.employment.address.country'] = UrlaRequiredState.Requested;
    // quickApplyFieldsConfig['mortgage.borrower.employment.aliases'] = UrlaRequiredState.Requested;
    // quickApplyFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork'] = UrlaRequiredState.None;
    // quickApplyFieldsConfig['mortgage.borrower.employment.selfEmployed'] = UrlaRequiredState.None;
    // quickApplyFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare'] = UrlaRequiredState.Requested;
    // quickApplyFieldsConfig['mortgage.borrower.employment.selfEmploymentForm'] = UrlaRequiredState.Requested;
    // quickApplyFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss'] = UrlaRequiredState.Requested;
    // quickApplyFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.borrower.declarations.residenceStatus'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.occupyProperty'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.haveJudgement'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.beenForclosed'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.declarations.explanation.description'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.sex'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.race'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] = UrlaRequiredState.Requested;

    // Borrower Military Service
    quickApplyFieldsConfig['mortgage.borrower.hasServedInMilitary'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.isActiveDutyMilitary'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.militaryStatusOtherDescription'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.isRetiredDischargedSeparatedFromMilitary'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.branchOfService'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.militaryServiceNumber'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.militaryServedAsName'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.militaryTourStartDate'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.militaryTourEndDate'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.isVAPurpleHeartRecipient'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.isActiveDutyOnDayFollowingDateOfSeparation'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.hasSeparatedFromMilitaryServiceDueToDisability'] = UrlaRequiredState.None;

    // Borrower VA
    quickApplyFieldsConfig['mortgage.borrower.caivrsIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.vaLocalTaxAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.vaStateTaxAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType'] = UrlaRequiredState.None;

    // Borrower Nearest Living Relative
    quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeName'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeEmail'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativePhone'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress1'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress2'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeCity'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeState'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeZipCode'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeCountry'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.nearestLivingRelativeRelationshipToBorrower'] = UrlaRequiredState.Requested;

    quickApplyFieldsConfig['mortgage.asset.assetType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.asset.depository'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.asset.accountNumber'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.asset.cashMarketValue'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.asset.giftOrGrantSource'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.asset.isDeposited'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.liability.typeOfLiability'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.liability.holderName'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.liability.mortgageType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.liability.lienPosition'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.liability.unpaidBalance'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.liability.partialPayoffAmount'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.liability.reoId'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.liability.accountNumber'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.address1'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.city'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.state'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.zipCode'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.county'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] = UrlaRequiredState.Required;
    quickApplyFieldsConfig['mortgage.realEstateOwned.currentUsageType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.isSubjectProperty'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.marketValue'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.realEstateOwned.percentOwned'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.realEstateOwned.vacancyFactor'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.realEstateOwned.grossRentalIncome'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.realEstateOwned.netRentalIncome'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.realEstateOwned.assetAccountId'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.amount'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.mortgageTerm.appraisedValue'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.mortgageTerm.universalLoanNumber'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.mortgageTerm.interestRate'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.mortgageTerm.noOfMonths'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.mortgageTerm.lienPosition'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.mortgageTerm.amortizationType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.paymentBillingFrequency'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.paymentRemittanceDay'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.isAffordableLoan'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.isCapitalizedLoan'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.isConvertible'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.hasENote'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.hasHomeBuyersHomeownershipEducationCertificate'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.hasPropertyInspectionWaiver'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.isRelocationLoan'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.isSharedEquityLoan'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeGracePeriodDays'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeRate'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeMinimumAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.lateChargeMaximumAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.partialPaymentAllowed'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.paymentFrequency'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.areDeficiencyRightsPreserved'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.isAssumable'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.isAssumed'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.hasDemandFeature'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.canLoanAmountIncrease'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.canPaymentIncrease'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.isDepositBalanceRequired'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.underwritingComments'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgageTerm.mersMortgageIdentifier'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.refiPurpose'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.propertyWillBe'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.address1'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.address2'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.city'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.state'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.zipCode'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.county'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.noOfUnits'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.constructionMethod'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.presentValue'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.occupancyPercent'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.refinanceProgram'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.attachmentType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.projectType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.projectDesignType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.propertyHasLienWithCleanEnergyProgram'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.improvementCost'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.estate'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.trustClassification'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.subjectProperty.isDeedRestricted'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription'] = UrlaRequiredState.Requested;

    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserCompanyName'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserName'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.effectiveDate'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalComments'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.estimatedRemainingEconomicLifeYears'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.subjectProperty.isEnergyEfficientHome'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.isDamagedByDisaster'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.propertyWillBeGroupHome'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.currentOccupancyType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.currentOccupantName'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.currentOwnerType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.currentUsageType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.msaIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.bathroomCount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.bedroomCount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.livableRoomCount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.projectName'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.floodProgramParticipationStatus'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.isOnNFIPMap'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.isInProtectedArea'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.subjectProperty.isInSpecialFloodHazardArea'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.relatedMortgage.lienType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.relatedMortgage.monthlyPayment'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.relatedMortgage.creditLimit'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.relatedMortgage.isHeloc'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.relatedMortgage.otherMortgageLoanPrincipalInterestTaxesAndInsurance'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.originatorInformation.organizationName'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.originatorInformation.organizationAddress1'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.originatorInformation.organizationAddress2'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.originatorInformation.organizationCity'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.originatorInformation.organizationState'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.originatorInformation.organizationZipCode'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.originatorInformation.name'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.originatorInformation.organizationNmls'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.originatorInformation.stateLicense'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.originatorInformation.organizationStateLicense'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.originatorInformation.email'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.originatorInformation.phone'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.originatorInformation.applicationSignedDate'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.proposedHousingExpense.realEstateTax'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.extension.balloonTermMonths'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.extension.interestOnlyTermMonths'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.extension.prepaymentPenaltyTermMonths'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.extension.initialBuydownRate'] = UrlaRequiredState.Requested;

    quickApplyFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.extension.hmdaPreapprovalType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.extension.relatedInvestorLoanId'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.transactionDetail.salesContractDate'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.ausData.negativeAmortizationType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.ausData.armIndexSourceType'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.ausData.armIndexCurrent'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.ausData.armMarginRate'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.ausData.subsequentPaymentAdjustmentMonths'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.ausData.freddieMacLoanPrograms'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.ausData.ausResult.caseIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.ausData.ausResult.ausSystem'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.ausData.ausResult.decisionDateTime'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.ausData.ausResult.ausResultValue'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.ausData.ausResult.recommendationDescription'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaAnnualPremiumPercent'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaBorrowerCertificationSalesPriceAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.applicationType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks'] = UrlaRequiredState.None;

    quickApplyFieldsConfig['mortgage.borrower.counselingEvent.completedDate'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.counselingEvent.format'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.counselingEvent.agencyName'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription'] = UrlaRequiredState.Requested;
    quickApplyFieldsConfig['mortgage.borrower.signingRole'] = UrlaRequiredState.Requested;

    quickApplyFieldsConfig['mortgage.transactionDetail.initialPITIPaymentAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestAndMIMonthlyPaymentAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestPaymentAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.transactionDetail.initialTaxAndInsurancePaymentAmount'] = UrlaRequiredState.None;
    quickApplyFieldsConfig['mortgage.transactionDetail.finalPaymentAmount'] = UrlaRequiredState.None;

    return quickApplyFieldsConfig;
  }

  loadDefaultUrlaFieldsConFig = (urlaFieldsConfig) => {
    urlaFieldsConfig['mortgage.borrower.firstName'] =  new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.middleName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.lastName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nameSuffix'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.primaryEmail'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.mobilePhone'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.languagePreference'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.hasRefusedToProvideLanguage'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.homePhone'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.socialSecNum'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.creditRefNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.dateOfBirth'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.authorizationMethod'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.maritalStatus'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.unmarriedAddendum'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.domesticRelationshipType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipTypeOther'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.entityVestingType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.alias.aliasType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.alias.firstName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.alias.middleName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.alias.lastName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.alias.nameSuffix'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.address1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.city'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.state'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.country'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.durationAtAddress'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.rent'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.isSubjectProperty'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.employer'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.position'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.startDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.endDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.address.address1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.address.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.address.city'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.address.state'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.address.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.address.country'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.employerPhone'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.aliases'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.selfEmployed'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentForm'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nonEmploymentIncomes.monthlyIncome'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.residenceStatus'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.occupyProperty'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.haveJudgement'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.beenForclosed'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.explanation.description'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.race'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    // Borrower Military Service
    urlaFieldsConfig['mortgage.borrower.hasServedInMilitary'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isActiveDutyMilitary'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.militaryStatus'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.militaryStatusOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isRetiredDischargedSeparatedFromMilitary'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.branchOfService'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.militaryServiceNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.militaryServedAsName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.militaryTourStartDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.militaryTourEndDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isFirstUseOfVaBenefit'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isVaFundingFeeExempt'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isSurvivingSpouseOfMilitary'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isVAPurpleHeartRecipient'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isActiveDutyOnDayFollowingDateOfSeparation'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasSeparatedFromMilitaryServiceDueToDisability'] = new MortgageFieldConfig(UrlaRequiredState.None);

    // Borrower VA
    urlaFieldsConfig['mortgage.borrower.caivrsIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaLocalTaxAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaStateTaxAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    // Borrower Nearest Living Relative
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeEmail'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativePhone'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress2'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCity'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeState'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeZipCode'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCountry'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeRelationshipToBorrower'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    urlaFieldsConfig['mortgage.asset.assetType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.asset.depository'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.asset.accountNumber'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.asset.cashMarketValue'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.asset.giftOrGrantSource'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.asset.isDeposited'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.liability.monthlyPayment'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.liability.holderName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.liability.accountNumber'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.liability.typeOfLiability'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.address1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.city'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.state'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.realEstateOwned.currentUsageType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.isSubjectProperty'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.marketValue'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.percentOwned'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.vacancyFactor'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.grossRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.netRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.assetAccountId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.amount'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.appraisedValue'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.universalLoanNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.interestRate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.noOfMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.lienPosition'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.amortizationType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.paymentBillingFrequency'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.paymentRemittanceDay'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isAffordableLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isCapitalizedLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isConvertible'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasENote'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasHomeBuyersHomeownershipEducationCertificate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasPropertyInspectionWaiver'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isRelocationLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isSharedEquityLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeGracePeriodDays'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeRate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMinimumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMaximumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentAllowed'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.paymentFrequency'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.areDeficiencyRightsPreserved'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isAssumable'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isAssumed'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasDemandFeature'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.canLoanAmountIncrease'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.canPaymentIncrease'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isDepositBalanceRequired'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.underwritingComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgageTerm.mersMortgageIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.refiPurpose'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.propertyWillBe'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.address1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.city'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.state'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.county'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.noOfUnits'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.constructionMethod'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.constructionStatus'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.presentValue'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.refinanceProgram'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.attachmentType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.projectType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.projectDesignType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.improvementCost'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.estate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.trustClassification'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.isDeedRestricted'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserCompanyName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.effectiveDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.estimatedRemainingEconomicLifeYears'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.subjectProperty.isEnergyEfficientHome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isDamagedByDisaster'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.propertyWillBeGroupHome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.currentOccupancyType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.currentOccupantName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.currentOwnerType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.currentUsageType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.msaIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.bathroomCount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.bedroomCount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.livableRoomCount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.projectName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.floodProgramParticipationStatus'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isOnNFIPMap'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isInProtectedArea'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isInSpecialFloodHazardArea'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.relatedMortgage.lienType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.relatedMortgage.monthlyPayment'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.relatedMortgage.creditLimit'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.relatedMortgage.isHeloc'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.relatedMortgage.otherMortgageLoanPrincipalInterestTaxesAndInsurance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationAddress1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.organizationAddress2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationCity'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.organizationState'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.organizationZipCode'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.name'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.organizationNmls'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.stateLicense'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicense'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.email'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.phone'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.applicationSignedDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.proposedHousingExpense.realEstateTax'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpenseDescription'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.balloonTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.interestOnlyTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.prepaymentPenaltyTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.initialBuydownRate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityTaxIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.filingLocation'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.stateOfOrganization'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.dateOfOrgFiling'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.signingPartyName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.signingPartyTitle'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.city'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.state'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.country'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.city'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.state'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.country'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.hmdaPreapprovalType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.relatedInvestorLoanId'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.transactionDetail.purchasePriceAmount'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.salesContractDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    urlaFieldsConfig['mortgage.ausData.negativeAmortizationType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.armIndexSourceType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.armIndexCurrent'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.armMarginRate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.subsequentPaymentAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.freddieMacLoanPrograms'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.ausData.ausResult.caseIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.ausSystem'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.decisionDateTime'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.ausResultValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.recommendationDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAnnualPremiumPercent'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaBorrowerCertificationSalesPriceAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.applicationType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingFirstPaymentDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaCashOutRefinanceType'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.borrower.counselingEvent.completedDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.format'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.agencyName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.signingRole'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    urlaFieldsConfig['mortgage.transactionDetail.initialPITIPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestAndMIMonthlyPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.initialTaxAndInsurancePaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.finalPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyMaximumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyPrincipalBalanceType'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['changeOfCircumstance.disclosureReason'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['changeOfCircumstance.disclosureReasonOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.disclosureReasonAdditionalDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.disclosureComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.changesOfCircumstanceReceivedDate'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['cchangeOfCircumstance.changeOfCircumstanceReason'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.feeDetail'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['legalDescriptions.fullDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.abbreviatedDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.otherUnparsedDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['legalDescriptions.governmentSurveyDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.landGrantDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.metesAndBoundsDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.nativeAmericanLandDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.rancheroDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.otherParsedDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['parcelIdentifications.parcelIdentificationNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.taxMapIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.taxParcelIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.assessorUnformattedIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.torrensCertificateIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.identificationTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);

    return urlaFieldsConfig;
  }

  loadDeepHavenUrlaFieldsConFig = (urlaFieldsConfig) => {
    urlaFieldsConfig['mortgage.borrower.firstName'] =  new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.middleName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.lastName'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.nameSuffix'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.mortgagePartyType'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.primaryEmail'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.mobilePhone'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.languagePreference'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.hasRefusedToProvideLanguage'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.homePhone'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.socialSecNum'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.creditRefNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.dateOfBirth'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.authorizationMethod'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.maritalStatus'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.unmarriedAddendum'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.domesticRelationshipType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipTypeOther'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.entityVestingType'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.alias.aliasType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.alias.firstName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.alias.middleName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.alias.lastName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.alias.nameSuffix'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.address1'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.city'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.state'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.country'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.durationAtAddress'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.rent'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.isSubjectProperty'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.employer'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.position'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.employment.startDate'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.employment.endDate'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.employment.address.address1'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.employment.address.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.address.city'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.employment.address.state'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.employment.address.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.employment.address.country'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.employment.employerPhone'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.employment.aliases'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.employment.selfEmployed'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentForm'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nonEmploymentIncomes.monthlyIncome'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.declarations.residenceStatus'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.occupyProperty'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.haveJudgement'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.beenForclosed'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.declarations.explanation.description'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.race'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    // Borrower Military Service
    urlaFieldsConfig['mortgage.borrower.hasServedInMilitary'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isActiveDutyMilitary'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.militaryStatus'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.militaryStatusOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isRetiredDischargedSeparatedFromMilitary'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.branchOfService'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.militaryServiceNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.militaryServedAsName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.militaryTourStartDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.militaryTourEndDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isFirstUseOfVaBenefit'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isVaFundingFeeExempt'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isSurvivingSpouseOfMilitary'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.isVAPurpleHeartRecipient'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isActiveDutyOnDayFollowingDateOfSeparation'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasSeparatedFromMilitaryServiceDueToDisability'] = new MortgageFieldConfig(UrlaRequiredState.None);

    // Borrower VA
    urlaFieldsConfig['mortgage.borrower.caivrsIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaLocalTaxAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaStateTaxAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    // Borrower Nearest Living Relative
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeEmail'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativePhone'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress2'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCity'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeState'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeZipCode'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCountry'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeRelationshipToBorrower'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    urlaFieldsConfig['mortgage.asset.assetType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.asset.depository'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.asset.accountNumber'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.asset.cashMarketValue'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.asset.giftOrGrantSource'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.asset.isDeposited'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.liability.monthlyPayment'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.liability.holderName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.liability.accountNumber'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.liability.typeOfLiability'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.address1'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.realEstateOwned.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.city'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.realEstateOwned.state'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.realEstateOwned.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.realEstateOwned.currentUsageType'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.realEstateOwned.isSubjectProperty'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.marketValue'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.percentOwned'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.vacancyFactor'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.grossRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.realEstateOwned.netRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.assetAccountId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.amount'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.mortgageTerm.appraisedValue'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.universalLoanNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.interestRate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.noOfMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.lienPosition'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.amortizationType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.paymentBillingFrequency'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.paymentRemittanceDay'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isAffordableLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isCapitalizedLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isConvertible'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasENote'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasHomeBuyersHomeownershipEducationCertificate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasPropertyInspectionWaiver'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isRelocationLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isSharedEquityLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeGracePeriodDays'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeRate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMinimumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMaximumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentAllowed'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.paymentFrequency'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.areDeficiencyRightsPreserved'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isAssumable'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isAssumed'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasDemandFeature'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.canLoanAmountIncrease'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.canPaymentIncrease'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isDepositBalanceRequired'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.underwritingComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgageTerm.mersMortgageIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.refiPurpose'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.propertyWillBe'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.subjectProperty.address1'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.subjectProperty.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.city'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.subjectProperty.state'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.subjectProperty.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.subjectProperty.county'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.noOfUnits'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.subjectProperty.constructionMethod'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.constructionStatus'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.presentValue'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.refinanceProgram'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.attachmentType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.projectType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.projectDesignType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.improvementCost'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.estate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.trustClassification'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.isDeedRestricted'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserCompanyName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.effectiveDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.estimatedRemainingEconomicLifeYears'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate'] = new MortgageFieldConfig(UrlaRequiredState.None);



    urlaFieldsConfig['mortgage.subjectProperty.isEnergyEfficientHome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.isDamagedByDisaster'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.propertyWillBeGroupHome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.currentOccupancyType'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.currentOccupantName'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.currentOwnerType'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.currentUsageType'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.msaIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.bathroomCount'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.bedroomCount'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.livableRoomCount'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.subjectProperty.projectName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.floodProgramParticipationStatus'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isOnNFIPMap'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isInProtectedArea'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isInSpecialFloodHazardArea'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.relatedMortgage.lienType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.relatedMortgage.monthlyPayment'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.relatedMortgage.creditLimit'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.relatedMortgage.isHeloc'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.relatedMortgage.otherMortgageLoanPrincipalInterestTaxesAndInsurance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationName'] = new MortgageFieldConfig(UrlaRequiredState.None, false, true);
    urlaFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationAddress1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.organizationAddress2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationCity'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.organizationState'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.organizationZipCode'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.name'] = new MortgageFieldConfig(UrlaRequiredState.None, false, true);
    urlaFieldsConfig['mortgage.originatorInformation.organizationNmls'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.stateLicense'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.stateLicenseState'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicense'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicenseState'] = new MortgageFieldConfig(UrlaRequiredState.None, true);
    urlaFieldsConfig['mortgage.originatorInformation.email'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.phone'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.originatorInformation.applicationSignedDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.proposedHousingExpense.realEstateTax'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpenseDescription'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.balloonTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.interestOnlyTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.prepaymentPenaltyTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.extension.initialBuydownRate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityName'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityTaxIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityType'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.filingLocation'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.stateOfOrganization'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.dateOfOrgFiling'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.signingPartyName'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.signingPartyTitle'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address1'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.city'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.state'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.country'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address1'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.city'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.state'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.country'] = new MortgageFieldConfig(UrlaRequiredState.Required);

    urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.hmdaPreapprovalType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.relatedInvestorLoanId'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.transactionDetail.purchasePriceAmount'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.salesContractDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    urlaFieldsConfig['mortgage.ausData.negativeAmortizationType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.armIndexSourceType'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.armIndexCurrent'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.armMarginRate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.subsequentPaymentAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.ausData.freddieMacLoanPrograms'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.ausData.ausResult.caseIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.ausSystem'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.decisionDateTime'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.ausResultValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.recommendationDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAnnualPremiumPercent'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaBorrowerCertificationSalesPriceAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.applicationType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaCashOutRefinanceType'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.borrower.counselingEvent.completedDate'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.format'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.agencyName'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
    urlaFieldsConfig['mortgage.borrower.signingRole'] = new MortgageFieldConfig(UrlaRequiredState.Requested);

    urlaFieldsConfig['mortgage.transactionDetail.initialPITIPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestAndMIMonthlyPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.initialTaxAndInsurancePaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.finalPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyMaximumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyPrincipalBalanceType'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['changeOfCircumstance.disclosureReason'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['changeOfCircumstance.disclosureReasonOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.disclosureReasonAdditionalDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.disclosureComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.changesOfCircumstanceReceivedDate'] = new MortgageFieldConfig(UrlaRequiredState.Required);
    urlaFieldsConfig['cchangeOfCircumstance.changeOfCircumstanceReason'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.feeDetail'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['legalDescriptions.fullDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.abbreviatedDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.otherUnparsedDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['legalDescriptions.governmentSurveyDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.landGrantDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.metesAndBoundsDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.nativeAmericanLandDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.rancheroDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.otherParsedDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['parcelIdentifications.parcelIdentificationNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.taxMapIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.taxParcelIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.assessorUnformattedIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.torrensCertificateIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.identificationTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);

    return urlaFieldsConfig;
  }

  updateUrlaFieldConfigBasedOnMortgage = (urlaFieldsConfig, mortgage: UrlaMortgage, isLinkedToLos: boolean) => {

    //HACK - REMOVE ME AFTER CONFIGURABLE
    if(mortgage.companyId == 229) {
      urlaFieldsConfig['mortgage.borrower.languagePreference'] = new MortgageFieldConfig(UrlaRequiredState.None);
      urlaFieldsConfig['mortgage.borrower.hasServedInMilitary'] = new MortgageFieldConfig(UrlaRequiredState.None);
    }

    urlaFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber'] = isLinkedToLos ? new MortgageFieldConfig(UrlaRequiredState.Requested) : new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.universalLoanNumber'] = isLinkedToLos ? new MortgageFieldConfig(UrlaRequiredState.Requested) : new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber'] = isLinkedToLos ? new MortgageFieldConfig(UrlaRequiredState.Requested) : new MortgageFieldConfig(UrlaRequiredState.None);

    //this validation should be geared toward loan operation... Only when drawing docs... LATER
    urlaFieldsConfig['mortgage.borrower.caivrsIdentifier'] = isLinkedToLos ? new MortgageFieldConfig(UrlaRequiredState.Requested) : new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType'] = mortgage.mortgageTerm.mortgageAppliedFor == "FHA" ? new MortgageFieldConfig(UrlaRequiredState.Required) : new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'] = mortgage.mortgageTerm.mortgageAppliedFor == "FHA" ? new MortgageFieldConfig(UrlaRequiredState.Required) : new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount'] = mortgage.mortgageTerm.mortgageAppliedFor == "VA" ? new MortgageFieldConfig(UrlaRequiredState.Required) : new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId'] = mortgage.mortgageTerm.mortgageAppliedFor == "FHA" ? new MortgageFieldConfig(UrlaRequiredState.Required) : new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId'] = mortgage.mortgageTerm.mortgageAppliedFor == "FHA" ? new MortgageFieldConfig(UrlaRequiredState.Required) : new MortgageFieldConfig(UrlaRequiredState.None);

    if(mortgage.subjectProperty.propertyWillBe == "Investment" && mortgage.subjectProperty.expectedNetMonthlyRentalIncome > 0){
      const borr = MortgageUtils.getPrimaryBorrowerOrFirst(mortgage);
      if(borr && (borr.hasOneYearLandlordExperience == null || borr.hasOneYearLandlordExperience == undefined)){
        urlaFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
      }
    }
    else {
      urlaFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = new MortgageFieldConfig(UrlaRequiredState.None);
    }

    switch (mortgage.mortgageTerm.mortgageAppliedFor) {
      case "VA":
        urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
        urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome'] = mortgage.subjectProperty.refinanceProgram == "InterestRateReductionRefinanceLoan" ? new MortgageFieldConfig(UrlaRequiredState.None) : new MortgageFieldConfig(UrlaRequiredState.Requested);
        break;

      case "FHA":
        urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
        urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
        break;

      case "Conventional":
        urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
        urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome'] = new MortgageFieldConfig(UrlaRequiredState.Requested);
        break;

      default:
        urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(UrlaRequiredState.None);
        urlaFieldsConfig['mortgage.borrower.employment.monthlyIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
        break;
    }

    return urlaFieldsConfig;
  }

  allFieldStateNone = () => {
    const urlaFieldsConfig = {};
    urlaFieldsConfig['mortgage.borrower.firstName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.middleName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.lastName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nameSuffix'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.primaryEmail'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.mobilePhone'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.languagePreference'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasRefusedToProvideLanguage'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.homePhone'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.socialSecNum'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.creditRefNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.dateOfBirth'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.authorizationMethod'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.maritalStatus'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.unmarriedAddendum'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.domesticRelationshipType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.unmarriedRelationshipTypeOther'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.alias.aliasType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.alias.firstName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.alias.middleName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.alias.lastName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.alias.nameSuffix'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.entityVestingType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.address1'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.city'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.state'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.country'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.durationAtAddress'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.rent'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.residencyAddress.isSubjectProperty'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.employer'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.position'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.startDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.endDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.address.address1'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.address.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.address.city'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.address.state'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.address.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.address.country'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.employerPhone'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.aliases'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.selfEmployed'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentForm'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nonEmploymentIncomes.monthlyIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.residenceStatus'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.occupyProperty'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.coSignedLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.haveJudgement'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.haveTaxDebt'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.haveLawsuit'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.beenForclosed'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.bankruptcyType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.declarations.explanation.description'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.sex'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.race'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname'] = new MortgageFieldConfig(UrlaRequiredState.None);

    // Borrower Military Service
    urlaFieldsConfig['mortgage.borrower.hasServedInMilitary'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isActiveDutyMilitary'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.militaryStatus'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.militaryStatusOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isRetiredDischargedSeparatedFromMilitary'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.branchOfService'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.militaryServiceNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.militaryServedAsName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.militaryTourStartDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.militaryTourEndDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isFirstUseOfVaBenefit'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isVaFundingFeeExempt'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isSurvivingSpouseOfMilitary'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isVAPurpleHeartRecipient'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isActiveDutyOnDayFollowingDateOfSeparation'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasSeparatedFromMilitaryServiceDueToDisability'] = new MortgageFieldConfig(UrlaRequiredState.None);

    // Borrower VA
    urlaFieldsConfig['mortgage.borrower.caivrsIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaLocalTaxAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaStateTaxAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType'] = new MortgageFieldConfig(UrlaRequiredState.None);

    // Borrower Nearest Living Relative
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeEmail'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativePhone'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress1'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeAddress2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCity'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeState'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeZipCode'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeCountry'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.nearestLivingRelativeRelationshipToBorrower'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.asset.assetType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.asset.depository'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.asset.accountNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.asset.cashMarketValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.asset.giftOrGrantSource'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.asset.isDeposited'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.liability.monthlyPayment'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.liability.holderName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.liability.accountNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.liability.typeOfLiability'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.address1'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.city'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.state'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.currentUsageType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.isSubjectProperty'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.marketValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.percentOwned'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.vacancyFactor'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.grossRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.netRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.realEstateOwned.assetAccountId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.amount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.appraisedValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.universalLoanNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.interestRate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.noOfMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lienPosition'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.amortizationType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.paymentBillingFrequency'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.paymentRemittanceDay'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isAffordableLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isCapitalizedLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isConvertible'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasENote'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasHomeBuyersHomeownershipEducationCertificate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasPropertyInspectionWaiver'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isRelocationLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isSharedEquityLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeGracePeriodDays'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeRate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMinimumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMaximumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentAllowed'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.paymentFrequency'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.areDeficiencyRightsPreserved'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isAssumable'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isAssumed'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.hasDemandFeature'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.canLoanAmountIncrease'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.canPaymentIncrease'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isDepositBalanceRequired'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.underwritingComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgageTerm.mersMortgageIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.subjectProperty.purposeOfLoan'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.refiPurpose'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.propertyWillBe'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.address1'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.address2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.city'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.state'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.zipCode'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.county'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.noOfUnits'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.constructionMethod'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.constructionStatus'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.presentValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.refinanceProgram'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.attachmentType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.projectType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.projectDesignType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.propertyHasLienWithCleanEnergyProgram'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.improvementCost'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.lotAcquiredDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.lotOriginalCost'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.lotAppraisedValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.estate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.titleWillBeHeldNames'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.trustClassification'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.communityLendingProductType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isDeedRestricted'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserCompanyName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.effectiveDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.estimatedRemainingEconomicLifeYears'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate'] = new MortgageFieldConfig(UrlaRequiredState.None);



    urlaFieldsConfig['mortgage.subjectProperty.isEnergyEfficientHome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isDamagedByDisaster'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.propertyWillBeGroupHome'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.currentOccupancyType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.currentOccupantName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.currentOwnerType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.currentUsageType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.msaIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.bathroomCount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.bedroomCount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.livableRoomCount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.projectName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.floodProgramParticipationStatus'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isOnNFIPMap'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isInProtectedArea'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.subjectProperty.isInSpecialFloodHazardArea'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.relatedMortgage.lienType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.relatedMortgage.monthlyPayment'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.relatedMortgage.creditLimit'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.relatedMortgage.isHeloc'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.relatedMortgage.otherMortgageLoanPrincipalInterestTaxesAndInsurance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationAddress1'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationAddress2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationCity'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationState'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationZipCode'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.name'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationNmls'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.stateLicense'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.organizationStateLicense'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.email'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.phone'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.originatorInformation.applicationSignedDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.realEstateTax'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpenseDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.balloonTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.interestOnlyTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.prepaymentPenaltyTermMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.initialBuydownRate'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.hmdaPreapprovalType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.extension.relatedInvestorLoanId'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.transactionDetail.purchasePriceAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.salesContractDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.ausData.negativeAmortizationType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.armIndexSourceType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.armIndexCurrent'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.armMarginRate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.subsequentPaymentAdjustmentMonths'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.freddieMacLoanPrograms'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.ausData.ausResult.caseIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.ausSystem'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.decisionDateTime'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.ausResultValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.recommendationDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAnnualPremiumPercent'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaBorrowerCertificationSalesPriceAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.applicationType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanBalance'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingReasonableAppraisedValue'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalInterestRate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaOriginalLoanTerm'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingLoanDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingFirstPaymentDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaExistingPrincipalAndInterest'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.governmentLoanDetail.vaCashOutRefinanceType'] = new MortgageFieldConfig(UrlaRequiredState.None);


    urlaFieldsConfig['mortgage.borrower.counselingEvent.completedDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.format'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.agencyName'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.borrower.signingRole'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['mortgage.transactionDetail.initialPITIPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestAndMIMonthlyPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.initialPrincipalAndInterestPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.initialTaxAndInsurancePaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['mortgage.transactionDetail.finalPaymentAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyMaximumAmount'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyPrincipalBalanceType'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['changeOfCircumstance.disclosureReason'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.disclosureReasonOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.disclosureReasonAdditionalDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.disclosureComments'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.changesOfCircumstanceReceivedDate'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['cchangeOfCircumstance.changeOfCircumstanceReason'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['changeOfCircumstance.feeDetail'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['legalDescriptions.fullDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.abbreviatedDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.otherUnparsedDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['legalDescriptions.governmentSurveyDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.landGrantDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.metesAndBoundsDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.nativeAmericanLandDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.rancheroDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['legalDescriptions.otherParsedDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);

    urlaFieldsConfig['parcelIdentifications.parcelIdentificationNumber'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.taxMapIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.taxParcelIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.assessorUnformattedIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.torrensCertificateIdentifier'] = new MortgageFieldConfig(UrlaRequiredState.None);
    urlaFieldsConfig['parcelIdentifications.identificationTypeOtherDescription'] = new MortgageFieldConfig(UrlaRequiredState.None);

    return urlaFieldsConfig;
  }

  loadFakeMortgageModel = (fakeMortgageModel) => {
    fakeMortgageModel.mortgageId = 1;
    fakeMortgageModel.borrowers.push(new MortgageBorrower())
    fakeMortgageModel.borrowers[0].borrowerId = 0;
    fakeMortgageModel.borrowers[0].firstName = 'Borrower';
    fakeMortgageModel.borrowers[0].lastName = 'Info';
    fakeMortgageModel.borrowers[0].aliases.push(new BorrowerAlias());
    fakeMortgageModel.borrowers[0].authorizedCreditCheck = true;
    fakeMortgageModel.borrowers[0].unmarriedRelationshipState = 'test';
    fakeMortgageModel.borrowers[0].employments.push(new Employment());
    fakeMortgageModel.borrowers[0].employments[0].employmentType = EmploymentTypeEnum.CurrentEmployer;
    fakeMortgageModel.borrowers[0].employments[0].selfEmployed = true;
    fakeMortgageModel.borrowers[0].employments[0].address = new Address();
    fakeMortgageModel.realEstateOwned.push(new RealEstateOwned());

    return fakeMortgageModel;
  }

  getCreditProviderMappings = (): CreditProvidersMapping[] => {
    return [
      { fannieId: '292', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'AccurateFinancialServices' },
      { fannieId: '31', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'ACRAnet' },

      // TODO: Sean verify: Mapped to SharperLending, cause name is "Advantage Credit Bureau - SharperLending"?
      { fannieId: '313', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'SharperLending' }, 

      // TODO: Sean verify: The name says "Advantage Credit Bureau (powered by MeridianLink)", but not in affiliate list, also maps to DCICreditServices on the Excel file
      { fannieId: '310', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'DCICreditServices' },

      // TODO: Sean verify: The name says "Advantage Credit, Inc. by Credit Interlink", but not in affiliate list, also maps to DCICreditServices on the Excel file,
      // Do we map the subvendor to AdvantageCredit?
      { fannieId: '308', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'AdvantageCredit' },

      { fannieId: '226', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 1, technicalAffiliateCode: '226', subLodaVendorName: null },
      { fannieId: '138', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 2, technicalAffiliateCode: '138', subLodaVendorName: null },
      { fannieId: '139', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 4, technicalAffiliateCode: '016', subLodaVendorName: null },
      { fannieId: '76', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 5, technicalAffiliateCode: '076', subLodaVendorName: null },
      { fannieId: '911', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'CBCInnovis' },

      // TODO: Sean verify: The name says "Advantage Credit Bureau (powered by MeridianLink)", but not in affiliate list, also maps to DCICreditServices on the Excel file
      // Fannie name is "Certified Credit Reporting, Inc.", but the affiliate name is "Certified Credit Reporting, Inc. via MeridianLink".
      // DO these really map?
      { fannieId: '911', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 7, technicalAffiliateCode: '071', subLodaVendorName: null },
      { fannieId: '128', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 8, technicalAffiliateCode: '128', subLodaVendorName: null },
      { fannieId: '86', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 10, technicalAffiliateCode: '086', subLodaVendorName: null },
      { fannieId: '1', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'CoreLogic' },
      { fannieId: '904', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'CoreLogic' },
      { fannieId: '298', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 9, technicalAffiliateCode: '298', subLodaVendorName: null },

      // TODO: Sean verify: The Fannie name says ""Credit Information Systems ML", but affiliate list has a name "Credit Information Systems - CIS"
      // Do these map??
      { fannieId: '311', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'CreditInformationSystems' },

      // TODO: Sean verify: The name says "Credit Interlink Test", but no tech affiliates and no Encompass provider
      { fannieId: '916', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

      { fannieId: '24', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 12, technicalAffiliateCode: '024', subLodaVendorName: null },
      { fannieId: '17', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 101, technicalAffiliateCode: '017', subLodaVendorName: null },

      // TODO: Sean verify: The name says "Credit Quick Services", but no tech affiliates and no Encompass provider
      { fannieId: '116', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },


      { fannieId: '287', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 15, technicalAffiliateCode: '181', subLodaVendorName: null },
      { fannieId: '161', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 16, technicalAffiliateCode: '161', subLodaVendorName: null },
      { fannieId: '301', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 29, technicalAffiliateCode: '304', subLodaVendorName: null },
      { fannieId: '4', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'Equifax' },
      { fannieId: '912', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'Equifax' },
      { fannieId: '3', lodaVendorName: 'FactualData', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },
      { fannieId: '303', lodaVendorName: 'FactualData', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },
      { fannieId: '918', lodaVendorName: 'FactualData', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },
      { fannieId: '130', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'Covius' },
      { fannieId: '17', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 58, technicalAffiliateCode: '017', subLodaVendorName: null },
      { fannieId: '2', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'InformativeResearch' },
      { fannieId: '913', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'InformativeResearch' },
      { fannieId: '305', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 18, technicalAffiliateCode: '309', subLodaVendorName: null },
      { fannieId: '309', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 100, technicalAffiliateCode: '328', subLodaVendorName: null },
      { fannieId: '78', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 20, technicalAffiliateCode: '078', subLodaVendorName: null },

      // TODO: Sean verify: The name says "MeridianLink Test", what technical affiliate should this map to?
      { fannieId: '909', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

      // TODO: Sean verify: The name says "MeridianLink, Inc", what technical affiliate should this map to?
      { fannieId: '281', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

      { fannieId: '277', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'MFICreditSolutions' },
      { fannieId: '6', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'NCOCS' },

      // TODO: Sean verify: The name says NCRA Test, but no tech affiliates and no Encompass provider
      { fannieId: '915', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

      // TODO: Sean verify: The name says ""Online Information Services", but no tech affiliates, is it the same as Online Mortgage Reports from Encompass?
      { fannieId: '204', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'OnlineMortgageReports' },

      // TODO: Sean verify: The name says "Partners Credit and Verification Solutions", but I took the encompass mapping from the initial excel file
      { fannieId: '251', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'OldRepublic' },

      { fannieId: '299', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 22, technicalAffiliateCode: '294', subLodaVendorName: null },
      { fannieId: '295', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 23, technicalAffiliateCode: '232', subLodaVendorName: null },
      { fannieId: '302', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 25, technicalAffiliateCode: '302', subLodaVendorName: null },
      { fannieId: '229', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 27, technicalAffiliateCode: '229', subLodaVendorName: null },

      // TODO: Sean verify: These 2 below are dupes with name "SettlementOne Data, LLC"
      { fannieId: '290', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 28, technicalAffiliateCode: '290', subLodaVendorName: null },
      { fannieId: '307', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 28, technicalAffiliateCode: '290', subLodaVendorName: null },

      { fannieId: '282', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'SharperLending' },
      { fannieId: '908', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'SharperLending' },

      // TODO: Sean verify: The name says "SL Solutions", but no tech affiliates and no Encompass provider, can this be SharpLending??
      { fannieId: '5', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

      // TODO: Sean verify: The name says "Test Credit Agency", what exactly is this??
      { fannieId: '200', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

      { fannieId: '201', lodaVendorName: 'CredCoHardPull', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },
      { fannieId: '270', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'TransUnion' },

      // TODO: Sean verify: The name says "Unisource Credit, LLC", but no tech affiliates and no Encompass provider
      { fannieId: '314', lodaVendorName: 'EncompassCredit', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: 'TransUnion' },

      { fannieId: '63', lodaVendorName: 'MeridianLinkHardPull', technicalAffiliateId: 31, technicalAffiliateCode: '063', subLodaVendorName: null },
      { fannieId: '51', lodaVendorName: 'Xactus', technicalAffiliateId: null, technicalAffiliateCode: null, subLodaVendorName: null },

    ];
  }

  private customizer = function (objValue: any, srcValue: any): boolean {
    if (typeof objValue === 'number' || typeof srcValue === 'number') {
      if ((objValue == null && srcValue != null) || (srcValue == null && objValue != null)) {
        return false;
      }
      return objValue.toString() === srcValue.toString();
    } else {
      return _.isEqual(objValue, srcValue);
    }
  }

  private omitFalsyValues = function (input: object): object {
    const excludes = ['', null, undefined];
    return Object.entries(input).reduce((nextInput, [key, value]) => {
      if (excludes.includes(value)) {
        return nextInput
      }

      if (_.isArray(value)) {
        const arrValue = value
        const nextValue = arrValue.map((arrItem) => {
          if (typeof arrItem === 'object') {
            return this.omitFalsyValues(arrItem);
          }
          return arrItem
        })
        nextInput[key] = nextValue;
        return nextInput;
      } else if (typeof value === 'object') {
        nextInput[key] = this.omitFalsyValues(value);
        return nextInput;
      }

      nextInput[key] = value;
      return nextInput;
    }, {})
  }
}

export class CreditProvidersMapping {
  fannieId: string;
  lodaVendorName: string;
  technicalAffiliateId: number;
  subLodaVendorName: string;
  technicalAffiliateCode: string;
}
