<div class="row m-0 px-0 py-2">
  <div class="col-8">
    <span class="text-nowrap header-font-size"><span class="fw-bold me-1">Total Assets: </span><span
        style="font-weight: bold; color: #8f4397; font-size: 15px;">{{ assetsTotal | currency }}</span>
        <span class="vertical-divider">
          <span></span>
        </span>
      </span>
    <span class="text-nowrap" style="color: #a4abc5;">Credits:
      <span class="fw-bold text-dark ms-1">{{
        creditsTotal | currency
        }}</span></span>
  </div>

  <div class="col-4 d-flex justify-content-end align-items-end pe-0">
    <div class="form-check">
      <input id="doesNotApply" class="form-check-input" type="checkbox" name="doesNotApply"
        [(ngModel)]="doesNotApply" />
      <label class="form-check-label text-dark" for="doesNotApply">
        Does not apply
      </label>
    </div>
    <button class="btn btn-sm btn-soft-danger ms-2" *ngIf="haveMultipleRowSelected()" (click)="bulkDeleteClicked()"><i
        class="fa fa-trash-alt me-1"></i> Delete Selected</button>
  </div>
</div>
<div class="qa-table-header-border"> </div>
<div class="table-responsive">
  <table class="table table-hover">
    <tbody>
      <ng-container *ngFor="let asset of assets; let i = index">
        <tr (mouseenter)="hoveredItems[asset.assetId] = true" (mouseleave)="hoveredItems[asset.assetId] = false">
          <td style="width: 20%">
            <span class="d-inline-flex align-items-center">
              <span class="me-2" style="width: 15px;">
                <div class="form-check" *ngIf="hoveredItems[asset.assetId] || selectedRows[asset.assetId]">
                  <input id="selectedRows-{{ asset.assetId }}" class="form-check-input" type="checkbox"
                    name="selectedRows-{{ asset.assetId }}" [(ngModel)]="selectedRows[asset.assetId]" />
                </div>
              </span>
              <i (click)="toggleExpandAsset(asset.assetId); existingItems[asset.assetId] = true"
                class="me-2 cursor {{
                  expandedItems[asset.assetId] ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
                }}"></i>
              <span class="d-inline-flex align-items-center">
                <select-input [(ngModel)]="asset.assetType" [optionValueKey]="'value'" [optionNameKey]="'name'"
                  [options]="allAssetTypes" [groupingEnabled]="true" name="assetTypes-{{ asset.assetId }}"
                  [editorMode]="'Inline'" [placeholder]="'Account Type'"
                  [disabled]="expandedItems[asset.assetId]"></select-input>
                <qa-badge variant='green' class="mx-1">
                  ASSET
                </qa-badge>
              </span>
            </span>
          </td>
          <td style="width: 20%">
            <span class="d-inline-flex align-items-center">
              <label class="me-1">of</label>
              <select-input [editorMode]="'Inline'" [isMultiple]="true" [required]="true" [optionValueKey]="'id'"
                [optionNameKey]="'text'" [placeholder]="'Account Owner'" [(ngModel)]="asset.owningBorrowerIds"
                [options]="possibleAccountOwners" name="owningBorrowerIds{{ asset.assetId }}"
                [disabled]="expandedItems[asset.assetId]"></select-input>
            </span>
          </td>
          <td style="width: 40%">
            <span class="d-inline-flex align-items-center" *ngIf="!isGiftOrGrantType(asset.assetType)">
              <label class="me-1">in</label>
              <text-input [(ngModel)]="asset.depository" name="depository{{ asset.assetId }}" [editorMode]="'Inline'"
                [required]="true" [placeholder]="'Institution'" [disabled]="expandedItems[asset.assetId]"></text-input>
              <qa-badge variant='green' class="mx-1" *ngIf="asset.assetInsertionType == 'DigitalVOA'" title="Digital(VOA)">
                VERIFIED
              </qa-badge>
              <qa-badge variant='blue' class="mx-1" *ngIf="asset.assetInsertionType != 'DigitalVOA'" title="Manual">
                MANUAL
              </qa-badge>
              <qa-badge variant='indigo' class="mx-1" *ngIf="isLiquidAsset(asset.assetType)">
                LIQUIDITY
              </qa-badge>
            </span>
          </td>
          <td class="text-end" style="width: 20%">
            <span class="d-inline-flex align-items-center">
              <currency-input [(ngModel)]="asset.cashMarketValue" (blur)="calculateSubTotal()"
                name="cashMarketValue-{{ asset.assetId }}" [editorMode]="'Inline'" [placeholder]="'Cash/Market Value'"
                [disabled]="expandedItems[asset.assetId]"></currency-input>
              <label class="ms-1">Value</label>
            </span>
          </td>
        </tr>
        <tr *ngIf="expandedItems[asset.assetId]">
          <td colspan="4">
            <qa-fi-asset-editor
              (cancel)='onClickCancelAsset(asset.assetId)'
              (close)='onClickCloseAsset(asset.assetId)'
              [assetTypes]='assetTypes'
              [asset]='asset'
              [mode]='existingItems[asset.assetId] ? "edit" : "create"'
              (assetChange)='onChangeAsset()'
              [giftGrantSources]='giftGrantSources'
              [giftGrantTypes]='giftGrantTypes'
              [otherAssetTypes]='otherAssetTypes'
              [possibleAccountOwners]='possibleAccountOwners'
            ></qa-fi-asset-editor>
          </td>
        </tr>
      </ng-container>

      <ng-container *ngFor="let credit of credits; let j = index">
        <tr (mouseenter)="hoveredItemsCredit[credit.purchaseCreditId] = true"
          (mouseleave)="hoveredItemsCredit[credit.purchaseCreditId] = false">
          <td colspan="3">
            <span class="d-inline-flex align-items-center">
              <span class="me-2" style="width: 15px;">
                <div class="form-check"
                  *ngIf="hoveredItemsCredit[credit.purchaseCreditId] || selectedRowsCredit[credit.purchaseCreditId]">
                  <input id="selectedRows-{{ credit.purchaseCreditId }}" class="form-check-input" type="checkbox"
                    name="selectedRows-{{ credit.purchaseCreditId }}"
                    [(ngModel)]="selectedRowsCredit[credit.purchaseCreditId]" />
                </div>
              </span>
              <i (click)="toggleExpandCredit(credit.purchaseCreditId); existingCredits[credit.purchaseCreditId] =
               true"
                class="me-2 cursor {{
                  expandedItemsCredit[credit.purchaseCreditId] ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
                }}"></i>
              <span class="d-inline-flex align-items-center">
                <select-input [(ngModel)]="credit.purchaseCreditType" [optionValueKey]="'value'"
                  [optionNameKey]="'name'" [options]="purchaseCreditTypes"
                  name="creditTypes-{{ credit.purchaseCreditId }}" [editorMode]="'Inline'" [placeholder]="'Credit Type'"
                  [disabled]="expandedItemsCredit[credit.purchaseCreditId]"></select-input>
                <qa-badge variant='blue' class="mx-1">
                  CREDIT
                </qa-badge>
              </span>
            </span>
          </td>
          <td class="text-end">
            <span class="d-inline-flex align-items-center">
              <currency-input [(ngModel)]="credit.purchaseCreditAmount" (blur)="calculateSubTotal()"
                name="cashMarketValue-{{ credit.purchaseCreditId }}" [editorMode]="'Inline'"
                [placeholder]="'Credit Amount'" [disabled]="expandedItemsCredit[credit.purchaseCreditId]"></currency-input>
              <label class="ms-1">Value</label>
            </span>
          </td>
        </tr>
        <tr *ngIf="expandedItemsCredit[credit.purchaseCreditId]">
          <td colspan="4">
            <qa-fi-credit-editor
              (cancel)='onClickCancelCredit(credit.purchaseCreditId)'
              (close)='onClickCloseCredit(credit.purchaseCreditId)'
              (creditChange)='onChangeCredit()'
              [creditTypes]='purchaseCreditTypes'
              [credit]='credit'
              [mode]='existingCredits[credit.purchaseCreditId] ? "edit" : "create"'
            ></qa-fi-credit-editor>
          </td>
        </tr>
      </ng-container>

      <tr *ngIf='!isCreatingItem'>
        <td colspan="4">
          <div class='d-flex gap-2'>
            <button type="button" class="btn btn-sm btn-soft-primary" (click)="addAssetClicked()">
              <i class="fas fa-plus me-2"></i> Asset
            </button>

            <button type="button" class="btn btn-sm btn-soft-primary" (click)="addCreditClicked()">
              <i class="fas fa-plus me-2"></i> Credit
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
