
<form #declarationsForm="ngForm">
  <div class="borrower-container border-0" *ngIf="mortgage.mortgageId != null">
    <ul class="nav nav-tabs d-flex" role="tablist" id="borrower-tabs" style="border-bottom: 1px solid #d5d9d9;">
      <li *ngFor="let borrowers of borrowerGroups" class="nav-item borrower-group group-button-list me-2">
        <ng-container *ngFor="let borrower of borrowers; let i = index; let last = last">
          <a class="nav-link" data-bs-toggle="tab" role="tab"
           (click)="onBorrowerSelected(borrower, true)" aria-selected="true"
           [ngClass]="{ active: isSelectedBorrower(borrower), 'pe-2': i % 2 == 0 && !last, 'ps-2': i % 2 == 1 }">
           <span class="tab-1003-val-icon">
            <i class="borrower-details-icon fas" *ngIf="!isReadOnly" [ngClass]="{
            'fa': isStatusLoaded && borrowerValidityStatusInfo.get(borrower.borrowerId) === 'success',
            'fa-check-circle': isStatusLoaded && borrowerValidityStatusInfo.get(borrower.borrowerId) === 'success',
            'text-success': isStatusLoaded && borrowerValidityStatusInfo.get(borrower.borrowerId) === 'success',

            'fa-question-circle': isStatusLoaded && borrowerValidityStatusInfo.get(borrower.borrowerId) === 'pending',
            'text-warning': isStatusLoaded && borrowerValidityStatusInfo.get(borrower.borrowerId) === 'pending',

            'fa-times-circle': isStatusLoaded && borrowerValidityStatusInfo.get(borrower.borrowerId) === 'error',
            'text-danger': isStatusLoaded && borrowerValidityStatusInfo.get(borrower.borrowerId) === 'error',

            'fa-circle-notch': !isStatusLoaded || borrowerValidityStatusInfo.get(borrower.borrowerId) === 'loading',
            'fa-spin': !isStatusLoaded || borrowerValidityStatusInfo.get(borrower.borrowerId) === 'loading'}">
            </i>
          </span>
            <span>
              {{getBorrowerDisplayName(borrower)}}
            </span>
          </a>
          <span *ngIf="!last" class="vertical-divider">
            <span></span>
          </span>
        </ng-container>
      </li>
    </ul>

    <div class="tab-content">
      <div *ngFor="let borrower of mortgage.borrowers | sort : 'isPrimary'">
        <div *ngIf="isSelectedBorrower(borrower)" class="tab-pane"
          [ngClass]="{ active: isSelectedBorrower(borrower)}" role="tabpanel">
          <borrower-declarations
            id="borrowerDeclarationsSection{{borrower.borrowerId}}"
            [borrower]="borrower"
            [isReadOnly]="isReadOnly"
            [inEditMode]="inEditMode"
            [isPurchase]="isPurchase"
            [urlaFieldsConfig]="urlaFieldsConfig"
            (borrowerValidityStatusChanged)="onBorrowerValidityStatusChanged($event)">
          </borrower-declarations>
        </div>
      </div>
    </div>
  </div>
</form>
<loading-indicator *ngIf="mortgage.mortgageId == null" [loadingMessage]="''"></loading-indicator>
