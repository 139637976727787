import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { UrlaBorrower } from '../../models/urla-mortgage.model';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { UrlaRequiredState } from '../../services/utility.service';
import { createCleanUpOtherDescriptionFunction, createIsRequiredFunction } from '../../urla-utils';
import { MilitaryBranch, MilitaryStatus } from '../../../../models';
import { concatMap, from, merge, Subscription, take } from 'rxjs';
import { MortgageService } from '../../../../services/mortgage.service';
import { MortgageAppliedForTypeEnum } from '../../../app-details/components/title-history/models/title-order.model';
import {
  NearestLivingRelativeEditorDrawerComponent,
} from '../nearest-living-relative-editor-drawer/nearest-living-relative-editor-drawer.component';
import { NearestLivingRelativeFormValue } from '../nearest-living-relative-editor/nearest-living-relative-editor.component';
import { MortgageFieldConfig } from '../../models/urla-fields-config.model';

@Component({
  selector: 'borrower-military-service',
  templateUrl: 'borrower-military-service.component.html',
  styleUrls: ['borrower-military-service.component.scss'],
  viewProviders: [formViewProvider],
})
export class BorrowerMilitaryServiceComponent implements OnInit, OnDestroy {

  @Input()
  borrower: UrlaBorrower;

  @Input()
  isReadOnly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: Record<string, MortgageFieldConfig>;

  @Input()
  mortgageAppliedFor: string;

  @ViewChild('nearestLivingRelativeEditorDrawer')
  nearestLivingRelativeEditorDrawer: NearestLivingRelativeEditorDrawerComponent;
  private _nearestLivingRelativeEditorDrawerSubscription: Subscription | null
    = null;

  private get isNearestLivingRelativeEditorDrawerOpen(): boolean {
    const closed =
      this._nearestLivingRelativeEditorDrawerSubscription?.closed ?? true;
    return !closed;
  }

  protected isVaBorrower: boolean = false;

  protected yesNoOptions: EnumerationItem[] = [];
  protected militaryStatusOptions: EnumerationItem[] = [];
  protected militaryBranchOptions: EnumerationItem[] = [];
  protected vaCertOccupancyOptions: EnumerationItem[] = [];

  private _initEnumerationItemSubscription: Subscription | null = null;
  private _appliedForChangeSubscription: Subscription | null = null;

  private cleanUpMilitaryStatusOtherDescription: () => void;
  private cleanUpMilitaryBranchOtherDescription: () => void;
  private cleanUpVaFields: (appliedFor: MortgageAppliedForTypeEnum) => void;

  protected isRequired: (fieldName: string) => boolean;

  protected readonly MilitaryStatus = MilitaryStatus;
  protected readonly BranchOfService = MilitaryBranch;

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _mortgageService: MortgageService,
  ) {
  }

  ngOnInit() {
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);

    this.initEnumerationItems();
    this.initCleanUpOtherDescriptionFunctions();
    this.subscribeToAppliedForChange();

    if(this.mortgageAppliedFor ==  MortgageAppliedForTypeEnum.VA){
      this.isVaBorrower = true;
    }
  }

  ngOnDestroy() {
    this._initEnumerationItemSubscription?.unsubscribe();
    this._appliedForChangeSubscription?.unsubscribe();
    this._nearestLivingRelativeEditorDrawerSubscription?.unsubscribe();
  }

  private initEnumerationItems(): void {
    this._initEnumerationItemSubscription?.unsubscribe();

    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
    this.militaryStatusOptions = this._enumsService.militaryStatusType;

    this._initEnumerationItemSubscription =
      this._enumsService.getMortgageEnumerations().subscribe((enums) => {
        this.militaryBranchOptions =
          enums[Constants.mortgageEnumerations.militaryBranch];

        this.vaCertOccupancyOptions = enums[Constants.mortgageEnumerations
          .vaBorrowerCertificationOccupancyType];
      });
    this._initEnumerationItemSubscription.add(() => {
      this._initEnumerationItemSubscription = null;
    });
  }

  private initCleanUpOtherDescriptionFunctions(): void {
    this.cleanUpMilitaryStatusOtherDescription = createCleanUpOtherDescriptionFunction({
      sourcePropertyName: 'militaryStatus',
      cleaningValue: MilitaryStatus.Other,
    }).bind(undefined, this.borrower);

    this.cleanUpMilitaryBranchOtherDescription = createCleanUpOtherDescriptionFunction({
      sourcePropertyName: 'branchOfService',
      cleaningValue: MilitaryBranch.Other,
    }).bind(undefined, this.borrower);

    this.cleanUpVaFields = createCleanUpVaFieldsFunction(this.borrower);
  }

  private subscribeToAppliedForChange() {
    this._appliedForChangeSubscription?.unsubscribe();

    this._appliedForChangeSubscription = this._mortgageService.appliedFor$
      .subscribe((value) => {
        this.isVaBorrower = value === MortgageAppliedForTypeEnum.VA;
        this.cleanUpVaFields(value);
      });
  }

  protected onMilitaryStatusChange(): void {
    const borrower = this.borrower;
    borrower.isActiveDutyMilitary = false;
    borrower.onlyNonActivatedReserveOrNationalGuard = false;
    borrower.isRetiredDischargedSeparatedFromMilitary = false;

    switch (borrower.militaryStatus) {
      case MilitaryStatus.ActiveDuty:
        borrower.isActiveDutyMilitary = true;
        break;
      case MilitaryStatus.ReserveNationalGuardNeverActivated:
        borrower.onlyNonActivatedReserveOrNationalGuard = true;
        break;
      case MilitaryStatus.Separated:
        borrower.isRetiredDischargedSeparatedFromMilitary = true;
        break;
    }

    this.cleanUpMilitaryStatusOtherDescription();
  };

  protected onIsServingUnitedStatesArmedForcesChange(): void {
    if (this.borrower.hasServedInMilitary) {
      this.borrower.isActiveDutyMilitary = null;
      this.borrower.onlyNonActivatedReserveOrNationalGuard = null;
      this.borrower.isRetiredDischargedSeparatedFromMilitary = null;
      this.borrower.isSurvivingSpouseOfMilitary = null;
      this.borrower.militaryTourEndDate = null;
      this.borrower.isFirstUseOfVaBenefit = null;
      this.borrower.isVaFundingFeeExempt = null;
    }
  };

  protected onChangeBranchOfService(): void {
    this.cleanUpMilitaryBranchOtherDescription();
  }

  protected onEditNearestLivingRelative(): void {
    this.showEditorDrawer().then((result) => {
      if (result == null) {
        return;
      }

      Object.assign(this.borrower, result);
    }).catch(console.error);
  }

  private showEditorDrawer(): Promise<NearestLivingRelativeFormValue | undefined> {
    let resolve: (value: NearestLivingRelativeFormValue | undefined) => void;
    let reject: (reason?: any) => void;
    const resultPromise =
      new Promise<NearestLivingRelativeFormValue | undefined>(
        (res, rej) => {
          resolve = res;
          reject = rej;
        });

    if (this.isNearestLivingRelativeEditorDrawerOpen) {
      reject(new Error('Cannot open editor drawer while it is already open'));
      return resultPromise;
    }

    const onClose = () => {
      this._nearestLivingRelativeEditorDrawerSubscription = null;
      // Callings resolve() multiple times is safe.
      resolve(undefined);
    };

    this._nearestLivingRelativeEditorDrawerSubscription =
      from(this.nearestLivingRelativeEditorDrawer.show(this.borrower)).pipe(
        concatMap(() => merge(
          this.nearestLivingRelativeEditorDrawer.cancel,
          this.nearestLivingRelativeEditorDrawer.save,
        ).pipe(take(1))),
      ).subscribe(resolve);
    this._nearestLivingRelativeEditorDrawerSubscription.add(onClose);

    return resultPromise;
  }
}

/**
 * Creates a function that cleans up VA fields if appliedFor is not VA.
 * @param {UrlaBorrower} borrower The borrower to clean up VA fields for.
 * @returns {(appliedFor: MortgageAppliedForTypeEnum) => void} The function that
 * cleans up VA fields.
 */
function createCleanUpVaFieldsFunction(
  borrower: UrlaBorrower,
): (appliedFor: MortgageAppliedForTypeEnum) => void {
  return function(appliedFor: MortgageAppliedForTypeEnum) {
    if (appliedFor === MortgageAppliedForTypeEnum.VA) {
      return;
    }

    // Delete VA fields
    delete borrower.caivrsIdentifier;
    delete borrower.isVAPastCreditRecordSatisfactory;
    delete borrower.hasFiledVADisabilityClaim;
    delete borrower.hasCurrentPreDischargeClaim;
    delete borrower.hasVABenefitRelatedIndebtedness;
    delete borrower.vaLocalTaxAmount;
    delete borrower.vaSocialSecurityTaxAmount;
    delete borrower.vaStateTaxAmount;
    delete borrower.vaBorrowerCertificationOccupancyType;

    // Delete Nearest Living Relative fields
    delete borrower.nearestLivingRelativeName;
    delete borrower.nearestLivingRelativeEmail;
    delete borrower.nearestLivingRelativePhone;
    delete borrower.nearestLivingRelativeAddress1;
    delete borrower.nearestLivingRelativeAddress2;
    delete borrower.nearestLivingRelativeCity;
    delete borrower.nearestLivingRelativeState;
    delete borrower.nearestLivingRelativeZipCode;
    delete borrower.nearestLivingRelativeCountry;
    delete borrower.nearestLivingRelativeRelationshipToBorrower;
  };
}
