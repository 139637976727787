import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address, ResidencyAddress, ResidencyType } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ResidencyAddressBaseComponent } from '../residency-address-base.component';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { createIsRequiredFunction } from '../../../urla-utils';

@Component({
  selector: 'non-present-address',
  templateUrl: 'non-present-address.component.html',
  viewProviders: [formViewProvider]
})
export class NonPresentAddressComponent extends ResidencyAddressBaseComponent implements OnInit {

  private _otherResidencyAddresses: ResidencyAddress[];

  @Input()
  isReadOnly: boolean = false;

  @Input()
  askForDurationAtAddress: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Output()
  attemptedToDeleteAddress: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Input()
  set otherResidencyAddresses(residencyAddresses: ResidencyAddress[]) {
    this._otherResidencyAddresses = residencyAddresses;

    if (!this.residencyAddress.address) {
      this.residencyAddress.address = new Address();
    }
    this.populateAddressesToCopyFrom();
  }

  get otherResidencyAddresses(): ResidencyAddress[] {
    return this._otherResidencyAddresses;
  }

  addressesToPickFrom: ResidencyAddress[] = [];
  housingTypes: EnumerationItem[] = [];

  mouseCursorOnDeleteButton: boolean = false;

  @Input()
  addressLabel: string;

  protected isRequired: (fieldName: string) => boolean;

  constructor(private readonly _enumService: EnumerationService) {
    super();
  }

  ngOnInit(): void {
    this._enumService.getMortgageEnumerations().subscribe(enums => {
      this.housingTypes = enums[Constants.enumerations.residencyBasisTypes];
    });

    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);
  }

  onDeleteClicked = () => {
    this.attemptedToDeleteAddress.emit(this.residencyAddress);
  }

  getFullAddress = (residencyAddress: ResidencyAddress): string => {
    const { residencyType, address: { address1, address2, city, state, zipCode } } = residencyAddress;

    let result = "";
    if (residencyType == ResidencyType.PresentAddress) {
      result += 'Present: ';
    } else {
      if (residencyType == ResidencyType.FormerAddress) {
        result += 'Former: ';
      } else {
        result += 'Mailing: ';
      }
    }
    result += `${address1 || '-'}, ${address2 || '-'}, ${city || '-'}, ${state || '-'}, ${zipCode || '-'}`;

    return result;
  }

  copyFrom = (residencyAddress: ResidencyAddress) => {
    this.residencyAddress.address.address1 = residencyAddress.address.address1;
    this.residencyAddress.address.address2 = residencyAddress.address.address2;
    this.residencyAddress.address.city = residencyAddress.address.city;
    this.residencyAddress.address.state = residencyAddress.address.state;
    this.residencyAddress.address.zipCode = residencyAddress.address.zipCode;
    this.residencyAddress.address.country = residencyAddress.address.country;
  }

  copyFromPresentAddress = () => {
    const presentAddress = this._otherResidencyAddresses.find(r => r.residencyType == ResidencyType.PresentAddress);
    this.copyFrom(presentAddress);
  }

  onCopyFromClicked = () => {
    this.populateAddressesToCopyFrom();
  }

  populateAddressesToCopyFrom = () => {

    const { address: { address1, city, state, zipCode } } = this.residencyAddress;

    this.addressesToPickFrom = this._otherResidencyAddresses.reduce((pV, cV) => {
      if (!cV.address || !cV.address.address1) {
        return pV;
      }

      if (cV.address.address1 == null || cV.address.city == null || cV.address.state == null || cV.address.zipCode == null) {
        return pV;
      }

      if (address1 === cV.address.address1 && city === cV.address.city && state === cV.address.state && zipCode === cV.address.zipCode) {
        return pV;
      }

      // prevent dups
      const pVIndex = pV.findIndex(({ address }) => address.address1 === cV.address.address1
        && address.city === cV.address.city
        && address.state === cV.address.state
        && address.zipCode === cV.address.zipCode);

      return pVIndex === -1 ? [...pV, cV] : pV;
    }, []);
  }
}
