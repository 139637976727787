import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'residual-chart',
  templateUrl: './residual-chart.component.html',
  styleUrls: ['./residual-chart.component.scss'],
})
export class ResidualChartComponent {
  @Output() close = new EventEmitter<void>();

  // FIXME: Implement the real data.
  protected minLoanAmount: number = randomResidualAmount();

  protected readonly residualIncomes: readonly ResidualIncome[] =
    createMockResidualIncomes();

  protected rebatePercentage: number = Math.random();

  protected readonly residualRegions: readonly ResidualRegion[]
    = createMockResidualRegions();
}

class ResidualIncome {
  readonly householdSize: HouseholdSize = null;
  readonly northeast: number = 0;
  readonly midwest: number = 0;
  readonly south: number = 0;
  readonly west: number = 0;

  private readonly _householdSizeLabel: string;
  get householdSizeLabel(): string {
    return this._householdSizeLabel;
  }

  constructor(init?: Partial<ResidualIncome>) {
    Object.assign(this, init);

    this._householdSizeLabel = this.householdSize !== HouseholdSize.SevenOrMore
      ? this.householdSize.toString()
      : '7 or more';
  }
}

enum HouseholdSize {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  SevenOrMore = 7,
}

class ResidualRegion {
  readonly type: ResidualRegionType = null;
  readonly states: readonly string[] = [];

  constructor(init?: Partial<ResidualRegion>) {
    Object.assign(this, init);
  }
}

enum ResidualRegionType {
  Northeast = 'Northeast',
  Midwest = 'Midwest',
  South = 'South',
  West = 'West',
}

/**
 * Returns a random residual amount between 0 and 9999 to generate mock data.
 * @returns {number}
 * FIXME: Remove this function when the real data is available.
 */
function randomResidualAmount(): number {
  return Math.floor(Math.random() * 10000);
}

function createMockResidualIncomes(): readonly ResidualIncome[] {
  return Object.freeze([
    new ResidualIncome({
      householdSize: HouseholdSize.One,
      northeast: randomResidualAmount(),
      midwest: randomResidualAmount(),
      south: randomResidualAmount(),
      west: randomResidualAmount(),
    }),
    new ResidualIncome({
      householdSize: HouseholdSize.Two,
      northeast: randomResidualAmount(),
      midwest: randomResidualAmount(),
      south: randomResidualAmount(),
      west: randomResidualAmount(),
    }),
    new ResidualIncome({
      householdSize: HouseholdSize.Three,
      northeast: randomResidualAmount(),
      midwest: randomResidualAmount(),
      south: randomResidualAmount(),
      west: randomResidualAmount(),
    }),
    new ResidualIncome({
      householdSize: HouseholdSize.Four,
      northeast: randomResidualAmount(),
      midwest: randomResidualAmount(),
      south: randomResidualAmount(),
      west: randomResidualAmount(),
    }),
    new ResidualIncome({
      householdSize: HouseholdSize.Five,
      northeast: randomResidualAmount(),
      midwest: randomResidualAmount(),
      south: randomResidualAmount(),
      west: randomResidualAmount(),
    }),
    new ResidualIncome({
      householdSize: HouseholdSize.Six,
      northeast: randomResidualAmount(),
      midwest: randomResidualAmount(),
      south: randomResidualAmount(),
      west: randomResidualAmount(),
    }),
    new ResidualIncome({
      householdSize: HouseholdSize.SevenOrMore,
      northeast: randomResidualAmount(),
      midwest: randomResidualAmount(),
      south: randomResidualAmount(),
      west: randomResidualAmount(),
    }),
  ]);
}

function createMockResidualRegions(): readonly ResidualRegion[] {
  return Object.freeze([
    Object.freeze(new ResidualRegion({
      type: ResidualRegionType.Northeast,
      states: Object.freeze([
        'Connecticut',
        'Maine',
        'Massachusetts',
        'New Hampshire',
        'New Jersey',
        'New York',
        'Pennsylvania',
        'Rhode Island',
        'Vermont',
      ]),
    })),
    Object.freeze(new ResidualRegion({
      type: ResidualRegionType.Midwest,
      states: Object.freeze([
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Michigan',
        'Minnesota',
        'Missouri',
        'Nebraska',
        'North Dakota',
        'Ohio',
        'South Dakota',
        'Wisconsin',
      ]),
    })),
    Object.freeze(new ResidualRegion({
      type: ResidualRegionType.South,
      states: Object.freeze([
        'Alabama',
        'Arkansas',
        'Delaware',
        'District of Columbia',
        'Florida',
        'Georgia',
        'Kentucky',
        'Louisiana',
        'Maryland',
        'Mississippi',
        'North Carolina',
        'Oklahoma',
        'Puerto Rico',
        'South Carolina',
        'Tennessee',
        'Texas',
        'Virginia',
        'West Virginia',
      ]),
    })),
    Object.freeze(new ResidualRegion({
      type: ResidualRegionType.West,
      states: Object.freeze([
        'Alaska',
        'Arizona',
        'California',
        'Colorado',
        'Hawaii',
        'Idaho',
        'Montana',
        'Nevada',
        'New Mexico',
        'Oregon',
        'Utah',
        'Washington',
        'Wyoming',
      ]),
    })),
  ]);
}
