<ng-container *ngIf="viewType == 1">
  <div class="row d-flex min-height-setter">
    <div
      [ngClass]="
        parameterPanelFullScreen
          ? 'col-12'
          : 'col-md-3 col-sm-12 col-lg-3 col-xlg-3 d-sm-block restricted-width'
      "
      class="card border-radius-reset pe-0 g-0 accordion mb-xs-1"
    >
      <h5 class="accordion-header d-sm-none m-0" id="headingTwo">
        <button
          class="accordion-button collapsed fw-semibold"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          Search Quotes
        </button>
      </h5>
      <div
        id="collapseTwo"
        class="accordion-collapse collapse show h-100"
        aria-labelledby="headingTwo"
      >
        <div class="d-flex flex-column" style="height: 100%">
          <div class="overflow-setter flex-grow-1">
            <loading-indicator
              *ngIf="!quoteSearchRequestInfo"
              [loadingMessage]="'Loading quote parameters, please wait...'"
            >
            </loading-indicator>
            <i
              class="expand-btn"
              [ngClass]="
                parameterPanelFullScreen ? 'ti-arrow-left' : 'ti-arrow-right'
              "
              (click)="parameterPanelFullScreen = !parameterPanelFullScreen"
            ></i>
            <mi-quote-search-parameters
              #quoteSearchParameters
              *ngIf="quoteSearchRequestInfo"
              [quoteSearchRequest]="quoteSearchRequestInfo"
              [channelOptions]="channels"
              [showMultipleColumns]="parameterPanelFullScreen"
            >
            </mi-quote-search-parameters>
          </div>
          <div class="text-center p-3 button-container">
            <button
              *ngIf="quoteSearchRequestInfo"
              type="button"
              class="btn btn-primary submit-button-style-child max-index"
              id="searchProducts"
              (click)="onSubmitClicked()"
            >
              <i class="fas fa-search me-1"></i> Search Quotes
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="!parameterPanelFullScreen"
      class="col-md-9 col-sm-12 col-lg-9 col-xlg-9 col-12 flex-grow-1 pe-0 ps-xs-0"
      style="height: 100%"
    >
      <div
        class="card rounded-2 search-result-container"
        *ngIf="!quoteRunResult"
      >
        <div class="card-body message-container">
          <h3 *ngIf="!isSubmitted" class="rounded-3">
            Please click 'Search Quotes' to see the mortgage insurance quotes.
          </h3>
          <h3 *ngIf="isSearching" class="rounded-3">Searching for Quotes...</h3>
        </div>
      </div>
      <mi-quote-search-results
        #searchResults
        *ngIf="quoteRunResult"
        [result]="quoteRunResult"
        (quoteSelected)="onQuoteSelected($event)"
        (quoteSelectedAfterReRun)="onQuoteSelectedAfterReRun($event)"
      >
      </mi-quote-search-results>
    </div>
  </div>
</ng-container>

<div class="row" *ngIf="viewType == 2">
  <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ active: tab === 'quoteParameters' }"
        (click)="selectTab('quoteParameters')"
        data-bs-toggle="tab"
        href="#quoteParameters"
        role="tab"
        aria-selected="true"
      >
        Pricing Parameters
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ active: tab === 'quoteResults' }"
        (click)="selectTab('quoteResults')"
        data-bs-toggle="tab"
        href="#quoteResults"
        role="tab"
        aria-selected="false"
      >
        Pricing Results
      </a>
    </li>
  </ul>

  <div class="tab-content">
    <div
      *ngIf="tab === 'quoteParameters'"
      class="tab-pane"
      [ngClass]="{ active: tab === 'quoteParameters' }"
      id="quoteParameters"
      role="tabpanel"
    >
      <loading-indicator
        *ngIf="!quoteSearchRequestInfo"
        [loadingMessage]="'Loading quote parameters, please wait...'"
      >
      </loading-indicator>
      <mi-quote-search-parameters
        #quoteSearchParameters
        *ngIf="quoteSearchRequestInfo"
        [quoteSearchRequest]="quoteSearchRequestInfo"
        [channelOptions]="channels"
        [showMultipleColumns]="true"
      >
      </mi-quote-search-parameters>
      <button
        *ngIf="quoteSearchRequestInfo"
        type="button"
        class="btn btn-primary submit-button-style-child max-index pull-right mt-3"
        id="searchProducts"
        (click)="onSubmitClicked()"
      >
        <i class="fas fa-search me-1"></i> Search Quotes
      </button>
    </div>
    <div
      *ngIf="tab === 'quoteResults'"
      class="tab-pane"
      [ngClass]="{ active: tab === 'quoteResults' }"
      id="quoteResults"
      role="tabpanel"
    >
      <mi-quote-search-results
        #searchResults
        *ngIf="quoteRunResult"
        [result]="quoteRunResult"
        [isTPO]="isTPO"
        (quoteSelected)="onQuoteSelected($event)"
        (quoteSelectedAfterReRun)="onQuoteSelectedAfterReRun($event)"
      >
      </mi-quote-search-results>
    </div>
  </div>
</div>
