<form>
    <div class='row'>
      <!-- Relative Name -->
      <div class='form-group col-lg-4'>
        <label for='relative-name'>Name</label>

        <text-input
          [(ngModel)]='formValue.nearestLivingRelativeName'
          id='relative-name'
          name='relativeName'
        ></text-input>
      </div>

      <!-- Relative Email -->
      <div class='form-group col-lg-4'>
        <label for='relative-email'>Email</label>

        <text-input
          [(ngModel)]='formValue.nearestLivingRelativeEmail'
          id='relative-email'
          name='relativeEmail'
        ></text-input>
      </div>

      <!-- Relative Phone -->
      <div class='form-group col-lg-4'>
        <label for='relative-phone'>Phone</label>

        <input
          id="relative-phone"
          type="text"
          name="relativePhone"
          class="form-control"
          [mask]="'(000) 000-0000'"
          [showMaskTyped]="true"
          [(ngModel)]="formValue.nearestLivingRelativePhone"
          #phoneNumberInput="ngModel"
          required
          [ngClass]="{
            'is-invalid': phoneNumberInput && phoneNumberInput.touched && phoneNumberInput.invalid
          }"/>
          <div class="invalid-feedback">This field is required</div>
      </div>

      <!-- Relative Relationship To Borrower -->
      <div class='form-group col-lg-4'>
        <label for='relative-relationship-to-borrower'>
          Relationship To Borrower
        </label>

        <text-input
          [(ngModel)]='formValue.nearestLivingRelativeRelationshipToBorrower'
          id='relative-relationship-to-borrower'
          name='relativeRelationshipToBorrower'
        ></text-input>
      </div>

      <!-- Relative Address 1 -->
      <div class='form-group col-lg-8'>
        <label for='relative-address-1'>Address 1</label>

        <text-input
          [(ngModel)]='formValue.nearestLivingRelativeAddress1'
          id='relative-address-1'
          name='relativeAddress1'
        ></text-input>
      </div>

      <!-- Relative City -->
      <div class='form-group col-lg-4'>
        <label for='relative-city'>City</label>

        <text-input
          [(ngModel)]='formValue.nearestLivingRelativeCity'
          id='relative-city'
          name='relativeCity'
        ></text-input>
      </div>

      <!-- Relative State -->
      <div class='form-group col-lg-2'>
        <label for="relative-state">State</label>

        <select-input
          [(ngModel)]='formValue.nearestLivingRelativeState'
          [options]='states'
          id='relative-state'
          name='relativeState'
          required
       ></select-input>
      </div>

      <!-- Relative Zip Code -->
      <div class='form-group col-lg-4'>
        <label for="relative-zip-code">Zip Code</label>
        <zip-code-input class="custom-input-right-side" [(ngModel)]="formValue.nearestLivingRelativeZipCode"
          [name]="'relative-zip-code'" [required]="false" (selectionChanged)="onZipCodeRelatedInfoChanged($event)">
        </zip-code-input>
      </div>

      <!-- Relative Country -->
      <div class='form-group col-lg-2'>
        <label for='relative-country'>Country</label>

        <text-input
          [(ngModel)]='formValue.nearestLivingRelativeCountry'
          id='relative-country'
          name='relativeCountry'
        ></text-input>
      </div>
    </div>

  <div class="row mb-3">
    <div class="col-md-12 text-end mt-4">
      <button class="btn btn-danger" type='button' (click)='onCancel()'>Cancel</button>
      <button class="btn btn-primary ms-2" (click)="onSave()">
        <i class='fa fa-save'></i>
        Save
      </button>
    </div>
  </div>
</form>
