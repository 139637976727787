<form>
  <!-- L2a. Unparsed Descriptions -->
  <div class='card p-3'>
    <h4 class='card-title mb-2'>
      L2a. Unparsed Descriptions
    </h4>

    <!-- Full Legal Description -->
    <div class='form-group'>
      <label for='full-legal-description'>
        Full Legal
      </label>

      <textarea-input
        [(ngModel)]='formValue.unparsedDescriptions.full.legalDescription'
        id='full-legal-description'
        name='fullLegalDescription'
        rows='2'
      ></textarea-input>
    </div>

    <!-- Abbreviated Legal Description -->
    <div class='form-group'>
      <label for='abbreviated-legal-description'>
        Abbreviated Legal
      </label>

      <textarea-input
        [(ngModel)]='formValue.unparsedDescriptions.abbreviated.legalDescription'
        id='abbreviated-legal-description'
        name='abbreviatedLegalDescription'
        rows='2'
      ></textarea-input>
    </div>

    <!-- Other Legal Descriptions -->
    <ng-container *ngFor='let otherLegalDescription of formValue.unparsedDescriptions.other; let i = index'>
      <div class='form-group'>
        <label
          class='d-flex justify-content-between'
          for='other-legal-description-{{ i }}'
        >
          Other Legal {{ i + 1 }}

          <!-- Remove Other Legal Description Button -->
          <button
            (click)='removeOtherUnparsedDescription(i)'
            class='btn btn-sm text-danger'
            title='Remove Other Legal Description'
            type='button'
          >
            <i class='fa fa-trash-alt'></i>
          </button>
        </label>

        <textarea-input
          [(ngModel)]='otherLegalDescription.legalDescriptionTypeOtherDescription'
          [id]='"other-legal-description-" + i'
          [name]='"otherLegalDescription" + i'
          rows='2'
        ></textarea-input>
      </div>
    </ng-container>

    <!-- Add Other Legal Description Button -->
    <div class='d-flex justify-content-end'>
      <button
        (click)='addOtherUnparsedDescription()'
        class='btn btn-sm btn-primary'
        type='button'
      >
        Add Other Legal Description
      </button>
    </div>
  </div>

  <!-- L2b. Parsed Descriptions -->
  <div class='card p-3'>
    <h4 class='card-title mb-2'>
      L2b. Parsed Descriptions
    </h4>

    <!-- Government Survey -->
    <div class='form-group'>
      <label for='government-survey'>
        Government Survey
      </label>

      <textarea-input
        [(ngModel)]='formValue.parsedDescriptions.governmentSurvey.unplattedLandSurveyTownshipIdentifier'
        id='government-survey'
        name='governmentSurvey'
        rows='2'
      ></textarea-input>
    </div>

    <!-- Land Grant -->
    <div class='form-group'>
      <label for='land-grant'>
        Land Grant
      </label>

      <textarea-input
        [(ngModel)]='formValue.parsedDescriptions.landGrant.unplattedLandSurveyTownshipIdentifier'
        id='land-grant'
        name='landGrant'
        rows='2'
      ></textarea-input>
    </div>

    <!-- Metes and Bounds -->
    <div class='form-group'>
      <label for='metes-and-bounds'>
        Metes and Bounds
      </label>

      <textarea-input
        [(ngModel)]='formValue.parsedDescriptions.metesAndBounds.unplattedLandSurveyTownshipIdentifier'
        id='metes-and-bounds'
        name='metesAndBounds'
        rows='2'
      ></textarea-input>
    </div>

    <!-- Native American Land -->
    <div class='form-group'>
      <label for='native-american-land'>
        Native American Land
      </label>

      <textarea-input
        [(ngModel)]='formValue.parsedDescriptions.nativeAmericanLand.unplattedLandSurveyTownshipIdentifier'
        id='native-american-land'
        name='nativeAmericanLand'
        rows='2'
      ></textarea-input>
    </div>

    <!-- Ranchero -->
    <div class='form-group'>
      <label for='ranchero'>
        Ranchero
      </label>

      <textarea-input
        [(ngModel)]='formValue.parsedDescriptions.ranchero.unplattedLandSurveyTownshipIdentifier'
        id='ranchero'
        name='ranchero'
        rows='2'
      ></textarea-input>
    </div>

    <!-- Other Legal Descriptions -->
    <ng-container *ngFor='let otherLegalDescription of formValue.parsedDescriptions.other; let i = index'>
      <div class='form-group'>
        <label
          class='d-flex justify-content-between'
          for='other-legal-description-{{ i }}'
        >
          Other Legal {{ i + 1 }}

          <!-- Remove Other Legal Description Button -->
          <button
            (click)='removeOtherParsedDescription(i)'
            class='btn btn-sm text-danger'
            title='Remove Other Legal Description'
            type='button'
          >
            <i class='fa fa-trash-alt'></i>
          </button>
        </label>

        <textarea-input
          [(ngModel)]='otherLegalDescription.unplattedLandTypeOtherDescription'
          [id]='"other-legal-description-" + i'
          [name]='"otherLegalDescription" + i'
          rows='2'
        ></textarea-input>
      </div>
    </ng-container>

    <!-- Add Other Legal Description Button -->
    <div class='d-flex justify-content-end'>
      <button
        (click)='addOtherParsedDescription()'
        class='btn btn-sm btn-primary'
        type='button'
      >
        Add Other Legal Description
      </button>
    </div>
  </div>

  <!-- Footer buttons -->
  <div class='d-flex justify-content-end gap-2'>
    <button class='btn btn-danger' (click)='onCancel()'>
      Cancel
    </button>

    <button class='btn btn-primary' (click)='onSave()'>
      Save
    </button>
  </div>
</form>
