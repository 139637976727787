<qa-page-body class="quick-apply">
  <ng-container slot='header'>
    Financial Info
  </ng-container>

  <ng-container slot='actions'>
    <save-button
      [isDisabled]="isLoanReadOnly"
      (onClick)='save()'
      [saving]='isSaving'>
    </save-button>
  </ng-container>
  <ng-container *ngIf='mortgage' slot='body'>
    <form #qaFinancialInfoForm="ngForm" novalidate id="qaFinancialInfo" name="qaFinancialInfo" >
      <qa-fi-income [mortgage]='mortgage'></qa-fi-income>
      <qa-fi-assets [mortgage]='mortgage'></qa-fi-assets>
      <qa-fi-reo [mortgage]='mortgage'></qa-fi-reo>
      <qa-fi-liabilities (invalidate)='invalidateDirtyState()'
                         [mortgage]='mortgage'>
      </qa-fi-liabilities>
    </form>
  </ng-container>
</qa-page-body>
