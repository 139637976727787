<div class="modal-header">
  <h5 class="modal-title">Gross Rental Income Calculator</h5>
  <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #grossRentForm="ngForm" novalidate id="grossRentForm" name="grossRentForm">
    <div class="form-group">
      <label for="netRentalIncome">Net Rental Income</label>
      <currency-input [(ngModel)]="netRentalIncome" name="netRentalIncome" (blur)="onNetRentalIncomeChanged()" [required]="true"></currency-input>
      <div class="invalid-feedback">This field is required</div>
    </div>
  </form>
</div>
<div class="modal-footer d-flex justify-content-between">
  <div>
    <span>
      Gross Rental Income: <b> {{ grossRentalIncome | currency }} </b>
    </span>
  </div>
  <div class="button-items">
    <button type="button" class="btn btn-primary right" (click)="onOkClicked()">
      Ok
    </button>
    <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">
      Cancel
    </button>
  </div>
</div>

