<ng-container *ngIf="viewType; else loading">
  <div class="row" *ngIf="viewType == 'management'">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block">
            <i class="fas fa-home me-2"></i> Appraisal Management
          </h4>
          <!-- <div class="float-end button-items">

                  </div> -->
        </div>
        <div class="card-body">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" (click)="switchTab('newAppraisal')">
              <a class="nav-link" [ngClass]="{ active: selectedTab === 'newAppraisal' }" data-bs-toggle="tab" role="tab"
                aria-selected="true">
                Create Appraisal
              </a>
            </li>
            <li class="nav-item" *ngIf="appraisals.length === 1" (click)="switchTab('appraisals', appraisals[0])">
              <a class="nav-link" [ngClass]="{ active: selectedTab === 'appraisals' }" data-bs-toggle="tab" role="tab"
                aria-selected="true">
                #{{ appraisals[0].thirdPartyOrderId }} -
                {{ appraisals[0].appraisalForms[0] }}
              </a>
            </li>
            <li class="nav-item" *ngIf="appraisals.length > 1">
              <a class="dropdown-toggle nav-link" data-bs-toggle="dropdown"
                [ngClass]="{ active: selectedTab === 'appraisals' }">
                Existing Appraisals <i class="fas fa-caret-down fs-5"></i>
              </a>
              <div class="dropdown-menu">
                <a *ngFor="let appraisal of appraisals" class="dropdown-item" [ngClass]="{
                    'fw-bold':
                      selectedTab === 'appraisals' &&
                      recentOrder.appraisalOrderId ===
                        appraisal.appraisalOrderId
                  }" (click)="switchTab('appraisals', appraisal)">
                  #{{ appraisal.thirdPartyOrderId }} -
                  {{ appraisal.appraisalForms[0] }}
                </a>
              </div>
            </li>
            <li class="nav-item" (click)="switchTab('history')">
              <a class="nav-link" [ngClass]="{ active: selectedTab === 'history' }" data-bs-toggle="tab" role="tab"
                aria-selected="true">
                History
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <ng-template [ngIf]="!loadingData" [ngIfElse]="loading">
              <div *ngIf="
                  selectedTab === 'appraisals' || selectedTab === 'newAppraisal'
                ">
                <appraisal-tab [appId]="appId" [appraisalFormTypes]="appraisalFormTypes"
                  [noteDeliveryGroups]="noteDeliveryGroups" [appraisalDocTypes]="appraisalDocTypes"
                  [conditionCategories]="conditionCategories" (createOrder)="onNewOrderCreated($event)"
                  (updateOrder)="onOrderUpdated($event)" [loanDocs]="loanDocs" [recentOrder]="recentOrder"
                  [createNewTabSelected]="selectedTab === 'newAppraisal'">
                </appraisal-tab>
              </div>
              <div *ngIf="selectedTab === 'history'">
                <appraisal-history-tab [appraisalHistory]="appraisals">
                </appraisal-history-tab>
              </div>
            </ng-template>
            <ng-template #loading>
              <loading-indicator></loading-indicator>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="viewType == 'reggora'">
    <reggora [applicationId]="appId"></reggora>
  </ng-container>
</ng-container>

<ng-template #loading>
  <loading-indicator></loading-indicator>
</ng-template>
