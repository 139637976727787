import { Component, Injector, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { CompanyExtended, ConfigurationCompany, SystemLevel } from 'src/app/models';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ZipCodeLookupResult } from '../../../../models/zipcode-lookup-result.model';
import { NotificationService } from '../../../../services/notification.service';
import { SystemLevelService } from '../../../../services/system-level.service';
import { State } from '../../../leads/components/lead-editor/lead-general-info/lead-general-info.component';

@Component({
  selector: 'company-profile-ext',
  templateUrl: './company-profile-ext.component.html'
})
export class CompanyProfileExtComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input() companyExtendedModel: CompanyExtended;

  states: State[] = [];
  isSaving: boolean;
  isSunLivingHack: boolean = false;
  isLoading: boolean;

  constructor(
    private readonly injector: Injector,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.companyExtendedModel) {
      this.isLoading = true;
      this._systemLevelService.getSystemLevel().subscribe((data: SystemLevel) => {
        const systemLevel = JSON.parse(JSON.stringify(data));
        this.companyExtendedModel = systemLevel.companyExtendedModel || new CompanyExtended();

        if(!this.companyExtendedModel.companyModel){
          this.companyExtendedModel.companyModel = new ConfigurationCompany();
        }

      }).add(() => this.isLoading = false);
    }
    this.states = Object.keys(this.applicationContext.globalConfig.states)
      .map(key => new State({ code: key, name: this.applicationContext.globalConfig.states[key] }));

    this.isSunLivingHack = this.applicationContext.userPermissions.companyId == 170;
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, modelNamePrefix: string) => {
    this.companyExtendedModel.mainPOCCity = zipCode.city;
    this.companyExtendedModel.mainPOCState = zipCode.state.toLocaleLowerCase();
  };

  save(): void {
    this.isSaving = true;
    this._systemLevelService.saveCompanyExtended(this.companyExtendedModel).pipe(finalize(() => {
      this.isSaving = false;
    })).subscribe({
      next: res => {
        this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
      },
      error: error => {
        this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
      }
    });
  }
}
