import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'encompass-disclosures-input-editors',
  templateUrl: './encompass-disclosures-input-editors.component.html',
  styleUrls: ['./encompass-disclosures-input-editors.component.scss']
})
export class EncompassDisclosuresInputEditorsComponent implements OnInit {

  @Input() type: string;
  @Input() formatFieldName: string;
  @Input() fieldId: string;
  @Input() object: any;
  @Input() values: any;

  readOnly: boolean = false;
  fieldFormat: string;

  constructor() { }

  ngOnInit(): void {
    const fieldKey = this.object[this.fieldId];
    this.fieldFormat = this.object[this.formatFieldName];

    if (this.values[fieldKey]) {
      // Loan Estimate - LE Date Issued
      if(fieldKey == 'LE1.X1' && !this.values[fieldKey].requestedValue && this.object[this.type] == 'DateTime'){
        this.values[fieldKey].requestedValue = new Date();
        this.readOnly = true;
      }
    }
  }

}
