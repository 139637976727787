import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { MortgageTerm, SubjectProperty } from 'src/app/models';

@Component({
  selector: 'loan-features-details',
  templateUrl: './loan-features-details.component.html',
  styleUrls: ['./loan-features-details.component.scss']
})
export class LoanFeaturesDetailsComponent implements OnInit {

  @Input()
  set subjectProperty(subjectProperty: SubjectProperty) {
    this._subjectProperty = cloneDeep(subjectProperty);
  }

  get subjectProperty(): SubjectProperty {
    return this._subjectProperty;
  }

  @Input()
  set mortgageTerm(mortgageTerm: MortgageTerm) {
    this._mortgageTerm = cloneDeep(mortgageTerm);
  }

  get mortgageTerm(): MortgageTerm {
    return this._mortgageTerm;
  }

  @Input()
  hasSaveCancelButtons: boolean = true;

  @Input()
  readonly: boolean = false;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApproved: EventEmitter<{subjectProperty: SubjectProperty, mortgageTerm: MortgageTerm}> = new EventEmitter<{subjectProperty: SubjectProperty, mortgageTerm: MortgageTerm}>();

  private _subjectProperty: SubjectProperty;

  private _mortgageTerm: MortgageTerm;


  prequalificationCheck: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  onSaveClicked = () => {
    this.changesApproved.emit({subjectProperty: this.subjectProperty, mortgageTerm: this.mortgageTerm});
  }

}
