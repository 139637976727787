export enum MenuItemStatus {
  Loading = "loading",
  Pending = "pending",
  Success = "success",
  Error = "error",
  None = "none"
}

export enum MenuItem {
  Credit = "Credit",
  Aus = "Aus",
  VOIE = "VOIE",
  VOA = "VOA",
  Appraisal = "Appraisal",
  Disclosure = "Disclosure",
  Pricing = "Pricing",
  Fees = "Fees",
  Submission = "Submission",
  CdRequest = "CdRequest",
}

export class MenuStatus {
  menuItem: MenuItem;
  menuItemStatus: MenuItemStatus;
}