<drawer
  (closed)='onCancel()'
  [name]='effectiveDrawerName'
  [options]='drawerOptions'
  [templateRef]='drawerTemplate'
  [title]='title'
></drawer>

<ng-template #drawerTemplate>
  <div class='p-3'>
    <legal-descriptions-editor
      (cancel)='onCancel()'
      (save)='onSave($event)'
      [value]='value'
    ></legal-descriptions-editor>
  </div>
</ng-template>
