import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { KeyDatesService } from 'src/app/services/key-dates.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoanConfigRoutingModule } from './loan-config-routing.module';
import {
  AdverseReasonComponent,
  LoanStatusComponent,
  LoanTypeComponent,
  SubStatusComponent,
  LoanPurposeComponent,
  UpsertLoanStatusDialog,
  UpsertLoanTypeDialog,
  UpsertSubStatusDialog,
  UpsertAdverseReasonDialog,
  UpsertLoanPurpose,
  LeadStatusAssociationComponent,
  UpsertLeadStatusAssociation,
  LeadStatusFlowComponent,
  TreeSortableLeadStatusComponent,
  LoanStatusAssociationComponent,
  UpsertLoanStatusAssociation,
  LoanStatusFlowComponent,
  TreeSortableLoanStatusComponent,
  KeyDatesComponent,
  KeyDateBoxComponent,
  UpsertKeyDateDialog,
  KeyDateGroupComponent,
  UpsertKeyDateGroupDialog,
} from './components';
import {
  AdverseReasonService,
  LoanStatusService,
  LoanTypeService,
  SubStatusService,
  LoanPurposeService,
  LeadStatusFlowService,
  LeadStatusAssociationService,
  LoanStatusAssociationService,
  LoanStatusFlowService,
} from './services';
import { TableModule } from 'primeng/table';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [ReactiveFormsModule, SharedModule, LoanConfigRoutingModule, TableModule, NgSelectModule],
  declarations: [
    LoanStatusComponent,
    UpsertLoanStatusDialog,
    SubStatusComponent,
    UpsertSubStatusDialog,
    LoanTypeComponent,
    UpsertLoanTypeDialog,
    AdverseReasonComponent,
    UpsertAdverseReasonDialog,
    LoanPurposeComponent,
    UpsertLoanPurpose,
    LeadStatusAssociationComponent,
    UpsertLeadStatusAssociation,
    LeadStatusFlowComponent,
    TreeSortableLeadStatusComponent,
    LoanStatusAssociationComponent,
    UpsertLoanStatusAssociation,
    LoanStatusFlowComponent,
    TreeSortableLoanStatusComponent,
    KeyDatesComponent,
    KeyDateGroupComponent,
    KeyDateBoxComponent,
    UpsertKeyDateDialog,
    UpsertKeyDateGroupDialog,
  ],
  providers: [
    LoanStatusService,
    SubStatusService,
    LoanTypeService,
    AdverseReasonService,
    LoanPurposeService,
    LeadStatusAssociationService,
    LeadStatusFlowService,
    LoanStatusAssociationService,
    LoanStatusFlowService,
    KeyDatesService,
  ],
})
export class LoanConfigModule { }
