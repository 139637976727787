<div class="modal-header">
  <h5 class="modal-title">Aliases</h5>
  <button
    (click)='onClickCancel()'
    aria-label='Close'
    class='btn-close'
    type='button'></button>
</div>

<div class="modal-body">
  <div
    *ngIf='errorMessage'
    class='alert alert-danger border-0'
    role='alert'
  >
    <strong>{{errorMessage.header}}</strong>

    {{errorMessage.message}}
  </div>

  <form
    #formElement='ngForm'
    id='aliasesForm'
    name='aliasesForm'
    novalidate
  >
    <h6>Alias</h6>

    <ul
      class='list-inline mb-0 text-muted'
      *ngFor='let item of formAliases; let index = index'
    >
      <li class='list-inline-item me-2'>
        <input
          #alias='ngModel'
          [(ngModel)]='item.value'
          [ngClass]="{'is-invalid' : alias && alias.touched  && alias.invalid}"
          class='form-control'
          id='alias{{index}}'
          name='alias{{index}}'
          required
        />
      </li>

      <li class='list-inline-item me-2'>
        <a
          (click)='onClickRemoveAlias(index)'
          style='cursor:pointer;'
        >
          <i class='fas fa-trash-alt text-primary'></i>
        </a>
      </li>

      <br><br>
    </ul>
  </form>

  <br>

  <a
    (click)='onClickAddAlias()'
    style='cursor:pointer;'
  >
    <i class='fas fa-plus me-1'></i>

    Add Alias
  </a>
</div>

<div class="modal-footer">
  <div class="button-items">
    <button
      (click)='onClickSave()'
      class='btn btn-primary right'
      type='button'
    >
      Save
    </button>
    <button
      (click)="onClickCancel()"
      class='btn btn-secondary right'
      type='button'>Cancel
    </button>
  </div>
</div>
