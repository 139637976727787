import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { SharedModule } from "src/app/shared/shared.module";
import { IdentifyingDocumentsModule } from "../app-details/modules/identifying-documents/identifying-documents.module";
import { BorrowerModule } from "../borrower/borrower.module";
import { AddressInfoComponent } from "./borrower-information/address-info/address-info.component";
import { NonPresentAddressComponent } from "./borrower-information/address-info/non-present-address/non-present-address.component";
import { PresentAddressComponent } from "./borrower-information/address-info/present-address/present-address.component";
import { AliasInfoComponent } from "./borrower-information/alias-info/alias-info.component";
import { BorrowerInfoComponent } from "./borrower-information/borrower-info/borrower-info.component";
import { BorrowersInfoComponent } from "./borrower-information/borrowers-info/borrowers-info.component";
import { ContactInfoComponent } from "./borrower-information/contact-info/contact-info.component";
import { DependentsDialogComponent } from './borrower-information/contact-info/dependents/dependents-dialog.component';
import { LanguagePreferencesComponent } from "./borrower-information/contact-info/language-preferences/language-preferences.componen";
import { BorrowerCreditAuthorizationComponent } from "./borrower-information/credit-authorization/borrower-credit-authorization.component";
import { EmployerNameNameComponent } from "./borrower-information/employment-income/employer-name-input/employer-name-input.component";
import { EmploymentAndIncomeComponent } from "./borrower-information/employment-income/employment-income.component";
import { EmploymentAliasesDialogComponent } from './borrower-information/employment-income/employment/employment-aliases-dialog/employment-aliases-dialog.component';
import { EmploymentComponent } from "./borrower-information/employment-income/employment/employment.component";
import { IncomeDialogComponent } from "./borrower-information/employment-income/income-dialog/income-dialog.component";
import { OtherIncomeComponent } from "./borrower-information/employment-income/other-income/other-income.component";
import { ManageBorrowersComponent } from './borrower-information/manage-borrowers/manage-borrowers.component';
import { UrlaDateInputComponent, UrlaDateTimeInputComponent, UrlaDropdownComponent, UrlaSsnTextComponent, UrlaTextAreaInputComponent, UrlaTextInputComponent } from "./common/components";
import { BorrowerPickerComponent } from "./common/components/borrower-picker/borrower-picker.component";
import { UrlaAddressComponent } from "./common/components/urla-address/urla-address.component";
import { UrlaBooleanCheckboxComponent } from './common/components/urla-boolean-checkbox/urla-boolean-checkbox.component';
import { UrlaCountryDropdownComponent } from "./common/components/urla-country-dropdown/urla-country-dropdown.component";
import { UrlaCurrencyInputComponent } from "./common/components/urla-currency-input/urla-currency-input.component";
import { UrlaDigitsInputComponent } from "./common/components/urla-digits-input/urla-digits-input.component";
import { UrlaGridCellComponent } from "./common/components/urla-grid-cell/urla-grid-cell.component";
import { UrlaMonthsYearsDurationComponent } from "./common/components/urla-months-years-duration/urla-months-years-duration.component";
import { UrlaPercentageInputComponent } from './common/components/urla-percentage-input/urla-percentage-input.component';
import { UrlaRadioGroupComponent } from "./common/components/urla-radio-group/urla-radio-group.component";
import { UrlaStateDropdownComponent } from "./common/components/urla-state-dropdown/urla-state-dropdown.component";
import { UrlaStreetAddressComponent } from "./common/components/urla-street-address/urla-street-address.component";
import { CityCountyPickerDialogComponent } from "./components/city-county-picker-dialog/city-county-picker-dialog.component";
import { MortgageExportDialogComponent } from "./components/mortgage-export-dialog/mortgage-export-dialog.component";
import { MortgageSaveDialogComponent } from "./components/mortgage-save-dialog/mortgage-save-dialog.component";
import { UrlaArmFeaturesComponent } from './components/urla-arm-features/urla-arm-features.component';
import { UrlaDeletableCellComponent } from './components/urla-deletable-cell/urla-deletable-cell.component';
import { AboutFinancesComponent } from "./declarations/borrower-declarations/about-finances/about-finances.component";
import { AboutPropertyComponent } from "./declarations/borrower-declarations/about-property/about-property.component";
import { AddExplanationButtonComponent } from './declarations/borrower-declarations/add-explanation-button/add-explanation-button.component';
import { BorrowerDeclarationsComponent } from "./declarations/borrower-declarations/borrower-declarations.component";
import { DeclarationExplanationEditorComponent } from './declarations/borrower-declarations/declaration-explanation-editor/declaration-explanation-editor.component';
import { DeclarationsComponent } from "./declarations/declarations.component";
import { BorrowerDemographicsComponent } from "./demographics/borrower-demographics/borrower-demographics.component";
import { DemographicsComponent } from "./demographics/demographics.component";
import { AssetDetailsModule } from "./financial-information/assets/asset-details/asset-details.module";
import { AssetsComponent } from "./financial-information/assets/assets.component";
import { FinancialInfoComponent } from "./financial-information/financial-info/financial-info.component";
import { AddEditLiabilityComponent } from './financial-information/liabilities/add-edit-liability/add-edit-liability.component';
import { EnterPayoffDialogComponent } from './financial-information/liabilities/enter-payoff-dialog/enter-payoff-dialog.component';
import { LiabilitiesComponent } from "./financial-information/liabilities/liabilities.component";
import { OtherAssetsComponent } from "./financial-information/other-assets/other-assets.component";
import { OtherLiabilitiesComponent } from "./financial-information/other-liabilities/other-liabilities.component";
import { HomeownershipEducationComponent } from './homeownership-education/homeownership-education.component';
import { UrlaBorrowerCounselingEventComponent } from './homeownership-education/urla-borrower-counseling-event/urla-borrower-counseling-event.component';
import { UrlaBorrowerHomeownershipEducationComponent } from './homeownership-education/urla-borrower-homeownership-education/urla-borrower-homeownership-education.component';
import { LoanOriginatorInfoComponent } from "./loan-originator-info/loan-originator-info.component";
import { GiftsOrGrantsComponent } from "./loan-property/gifts-or-grants/gifts-or-grants.component";
import { LoanPropertyInfoComponent } from "./loan-property/loan-property-info/loan-property-info.component";
import { SourceOfFundsDialogComponent } from "./loan-property/loan-property-info/source-of-funds-dialog/source-of-funds-dialog.component";
import { SubjectPropertyManufacturedHomeDetailsComponent } from './loan-property/loan-property-info/subject-property-manufactured-home-details/subject-property-manufactured-home-details.component';
import { UrlaSubjectPropertyValuationComponent } from './loan-property/loan-property-info/urla-subject-property-valuation/urla-subject-property-valuation.component';
import { ZipCodePickerDialogComponent } from "./loan-property/loan-property-info/zip-code-picker-dialog/zip-code-picker-dialog.component";
import { LoanPropertyComponent } from "./loan-property/loan-property.component";
import { OtherNewMortgageLoansComponent } from "./loan-property/other-new-mortgage-loans/other-new-mortgage-loans.component";
import { RelatedMortgageLoanEditorDrawer } from './loan-property/other-new-mortgage-loans/related-mortgage-loan-editor-drawer/related-mortgage-loan-editor-drawer.component';
import { RelatedMortgageLoanEditor } from './loan-property/other-new-mortgage-loans/related-mortgage-loan-editor/related-mortgage-loan-editor.component';
import { RentalIncomeOnPurchasesComponent } from "./loan-property/rental-income-on-purchases/rental-income-on-purchases.component";
import { BorrowerMilitaryServiceComponent } from "./military-service/borrower-military-service/borrower-military-service.component";
import { MilitaryServiceComponent } from "./military-service/military-service.component";
import { NearestLivingRelativeEditorDrawerComponent } from './military-service/nearest-living-relative-editor-drawer/nearest-living-relative-editor-drawer.component';
import { NearestLivingRelativeEditorComponent } from './military-service/nearest-living-relative-editor/nearest-living-relative-editor.component';
import { MortgageInsuranceCalculationDialogComponent } from './mortgage-insurance/mortgage-insurance-calculation-dialog/mortgage-insurance-calculation-dialog.component';
import { MortgageInsuranceDialogComponent } from './mortgage-insurance/mortgage-insurance-dialog/mortgage-insurance-dialog.component';
import { BalloonDetailsComponent } from './mortgage-loan-info/balloon-details/balloon-details.component';
import { BuydownDetailsComponent } from './mortgage-loan-info/buydown-details/buydown-details.component';
import { FloodDetailsComponent } from "./mortgage-loan-info/flood-details/flood-details.component";
import { GovernmentLoanInfoComponent } from "./mortgage-loan-info/government-loan-info/government-loan-info.component";
import { HoaDuesCondoFeesBreakdownDialogComponent } from "./mortgage-loan-info/hoa-dues-condo-fees-breakdpwn-dialog/hoa-dues-condo-fees-breakdown-dialog.component";
import { HomeownersInsuranceDetailsComponent } from "./mortgage-loan-info/homeowners-insurance-details/homeowners-insurance-details.component";
import { LateChargeModule } from "./mortgage-loan-info/late-charge/late-charge.module";
import { LoanFeaturesDetailsComponent } from "./mortgage-loan-info/loan-features-details/loan-features-details.component";
import { MortgageLoanInfoComponent } from "./mortgage-loan-info/mortgage-loan-info.component";
import { PrepaymentPenaltyDetailsModule } from "./mortgage-loan-info/prepayment-penalty-details/prepayment-penalty-details.module";
import { PropertyTaxBreakdownDialogComponent } from "./mortgage-loan-info/property-tax-breakdown-dialog/property-tax-breakdown-dialog.component";
import { SupplementalPropertyInsuranceBreakdownDialogComponent } from "./mortgage-loan-info/supplemental-property-insurance-breakdown-dialog/supplemental-property-insurance-breakdown-dialog.component";
import { PropertyLoanInfoComponent } from './property-loan-info/property-loan-info.component';
import { BorrowerQualificationComponent } from './qualifying-the-borr/borrower-qualification/borrower-qualification.component';
import { CalculatedTotalsComponent } from "./qualifying-the-borr/calculated-totals/calculated-totals.component";
import { CreditDetailsComponent } from "./qualifying-the-borr/credit-details/credit-details.component";
import { DueFromBorrowerComponent } from "./qualifying-the-borr/due-from-borrower/due-from-borrower.component";
import { MortgageLoanDetailsComponent } from "./qualifying-the-borr/mortgage-loan-details/mortgage-loan-details.component";
import { QualifyingTheBorrComponent } from "./qualifying-the-borr/qualifying-the-borr.component";
import { UnderwritingItemComponent } from "./qualifying-the-borr/underwriting/underwriting-item/underwriting-item.component";
import { UnderwritingComponent } from "./qualifying-the-borr/underwriting/underwriting.component";
import { ReoExpenseBreakdownComponent } from "./reo/reo-expense-breakdown-dialog/reo-expense-breakdown-dialog.component";
import { ReoGrossRentalCalculatorDialogComponent } from "./reo/reo-gross-rental-calculator-dialog/reo-gross-rental-calculator-dialog.component";
import { ReoItemComponent } from "./reo/reo-item/reo-item.component";
import { ReoComponent } from "./reo/reo.component";
import { SubjectPropertyInputComponent } from "./reo/subject-property-input/subject-property-input.component";
import {
  TitleInformationBorrowerComponent
} from './title-information/title-information-borrowers/title-information-borrower/title-information-borrower.component';
import { TitleInformationBorrowersComponent } from './title-information/title-information-borrowers/title-information-borrowers.component';
import { TitleInformationComponent } from './title-information/title-information.component';
import { ParcelIdentificationDetailsComponent } from './title-information/urla-legal-descriptions/parcel-identifications/parcel-identification-details/parcel-identification-details.component';
import { ParcelIdentificationsComponent } from './title-information/urla-legal-descriptions/parcel-identifications/parcel-identifications.component';
import { LegalDescriptionsComponent } from './title-information/urla-legal-descriptions/legal-descriptions/legal-descriptions.component';
import { UrlaLegalDescriptionsComponent } from "./title-information/urla-legal-descriptions/urla-legal-descriptions.component";
import { AddMortgageBorrowerDialogComponent } from './urla-main/add-mortgage-borrower-dialog/add-mortgage-borrower-dialog.component';
import { HazardInsuranceDetailsComponent } from "./urla-main/subject-property-taxes-insurance/hazard-insurance-details/hazard-insurance-details.component";
import { SubjectPropertyTaxesInsuranceComponent } from "./urla-main/subject-property-taxes-insurance/subject-property-taxes-insurance.component";
import { UrlaMainComponent } from "./urla-main/urla-main.component";
import { UrlaMenuComponent } from "./urla-menu/urla-menu.component";
import { UrlaRoutingModule } from "./urla-routing.module";
import { QualifyingIncomeComponent } from "./borrower-information/qualifying-income/qualifying-income.component";
import { LegalDescriptionsEditorComponent } from './title-information/urla-legal-descriptions/legal-descriptions/legal-descriptions-editor/legal-descriptions-editor.component';
import { LegalDescriptionsEditorDrawerComponent } from './title-information/urla-legal-descriptions/legal-descriptions/legal-descriptions-editor-drawer/legal-descriptions-editor-drawer.component';
import { EConsentHistoryPopoverModule } from "../app-details/modules/quick-apply/qa-borrowers-info/components/popovers/e-consent-history-popover/e-consent-history-popover.module";
import { UrlaEConsentComponent } from "./borrower-information/e-consent/urla-econsent.component";
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { LoanAmountCalculatorModule } from "../loan-amount-calculator/loan-amount-calculator.module";
import { BorrowingEntityInfoComponent } from "./borrowing-entity-info/borrowing-entity-info.component";
import { EditModeInputComponent } from "./common/components/edit-mode-input/edit-mode-input.component";

@NgModule({
  imports: [
    UrlaRoutingModule,
    SharedModule,
    BorrowerModule,
    NgSelectModule,
    IdentifyingDocumentsModule,
    AssetDetailsModule,
    LateChargeModule,
    PrepaymentPenaltyDetailsModule,
    EConsentHistoryPopoverModule,
    NgbPopoverModule,
    LoanAmountCalculatorModule
  ],
  declarations: [
    UrlaMenuComponent,
    UrlaMainComponent,
    UrlaTextInputComponent,
    BorrowerInfoComponent,
    ContactInfoComponent,
    BorrowersInfoComponent,
    ManageBorrowersComponent,
    UrlaDropdownComponent,
    UrlaSsnTextComponent,
    UrlaDateInputComponent,
    UrlaDateTimeInputComponent,
    UrlaGridCellComponent,
    UrlaRadioGroupComponent,
    AddressInfoComponent,
    UrlaDigitsInputComponent,
    UrlaStreetAddressComponent,
    UrlaStateDropdownComponent,
    UrlaCountryDropdownComponent,
    UrlaAddressComponent,
    UrlaMonthsYearsDurationComponent,
    UrlaCurrencyInputComponent,
    PresentAddressComponent,
    NonPresentAddressComponent,
    DependentsDialogComponent,
    AliasInfoComponent,
    DeclarationsComponent,
    BorrowerDeclarationsComponent,
    AboutPropertyComponent,
    AboutFinancesComponent,
    BorrowerDemographicsComponent,
    DemographicsComponent,
    MilitaryServiceComponent,
    BorrowerMilitaryServiceComponent,
    BorrowingEntityInfoComponent,
    LoanOriginatorInfoComponent,
    TitleInformationComponent,
    EmploymentAndIncomeComponent,
    EmploymentComponent,
    FinancialInfoComponent,
    AssetsComponent,
    OtherAssetsComponent,
    OtherLiabilitiesComponent,
    LiabilitiesComponent,
    EmployerNameNameComponent,
    LoanPropertyComponent,
    LoanPropertyInfoComponent,
    GiftsOrGrantsComponent,
    OtherNewMortgageLoansComponent,
    RentalIncomeOnPurchasesComponent,
    MortgageLoanInfoComponent,
    UrlaPercentageInputComponent,
    UrlaBooleanCheckboxComponent,
    IncomeDialogComponent,
    SourceOfFundsDialogComponent,
    PropertyLoanInfoComponent,
    ReoComponent,
    ReoItemComponent,
    SubjectPropertyInputComponent,
    BorrowerPickerComponent,
    OtherIncomeComponent,
    QualifyingTheBorrComponent,
    DueFromBorrowerComponent,
    MortgageLoanDetailsComponent,
    UnderwritingComponent,
    UnderwritingItemComponent,
    CreditDetailsComponent,
    CalculatedTotalsComponent,
    MortgageSaveDialogComponent,
    MortgageExportDialogComponent,
    PropertyTaxBreakdownDialogComponent,
    HoaDuesCondoFeesBreakdownDialogComponent,
    SupplementalPropertyInsuranceBreakdownDialogComponent,
    CityCountyPickerDialogComponent,
    MortgageInsuranceDialogComponent,
    MortgageInsuranceCalculationDialogComponent,
    AddMortgageBorrowerDialogComponent,
    SubjectPropertyTaxesInsuranceComponent,
    HazardInsuranceDetailsComponent,
    AddEditLiabilityComponent,
    EnterPayoffDialogComponent,
    GovernmentLoanInfoComponent,
    BorrowerCreditAuthorizationComponent,
    ZipCodePickerDialogComponent,
    ReoGrossRentalCalculatorDialogComponent,
    HomeownershipEducationComponent,
    UrlaBorrowerHomeownershipEducationComponent,
    UrlaBorrowerCounselingEventComponent,
    UrlaArmFeaturesComponent,
    UrlaSubjectPropertyValuationComponent,
    SubjectPropertyManufacturedHomeDetailsComponent,
    BorrowerQualificationComponent,
    DeclarationExplanationEditorComponent,
    AddExplanationButtonComponent,
    EmploymentAliasesDialogComponent,
    TitleInformationBorrowersComponent,
    TitleInformationBorrowerComponent,
    UrlaDeletableCellComponent,
    RelatedMortgageLoanEditor,
    RelatedMortgageLoanEditorDrawer,
    BalloonDetailsComponent,
    BuydownDetailsComponent,
    NearestLivingRelativeEditorComponent,
    NearestLivingRelativeEditorDrawerComponent,
    ReoExpenseBreakdownComponent,
    UrlaLegalDescriptionsComponent,
    UrlaTextAreaInputComponent,
    LegalDescriptionsComponent,
    ParcelIdentificationsComponent,
    ParcelIdentificationDetailsComponent,
    LoanFeaturesDetailsComponent,
    FloodDetailsComponent,
    LanguagePreferencesComponent,
    HomeownersInsuranceDetailsComponent,
    QualifyingIncomeComponent,
    LegalDescriptionsEditorComponent,
    LegalDescriptionsEditorDrawerComponent,
    UrlaEConsentComponent,
    EditModeInputComponent
  ],
  exports: [
    UrlaMenuComponent,
    UrlaMainComponent,
    UrlaTextInputComponent,
    BorrowerInfoComponent,
    ContactInfoComponent,
    BorrowersInfoComponent,
    ManageBorrowersComponent,
    UrlaDropdownComponent,
    UrlaSsnTextComponent,
    UrlaGridCellComponent,
    UrlaRadioGroupComponent,
    AddressInfoComponent,
    UrlaAddressComponent,
    UrlaDigitsInputComponent,
    UrlaStreetAddressComponent,
    UrlaStateDropdownComponent,
    UrlaCountryDropdownComponent,
    UrlaMonthsYearsDurationComponent,
    UrlaCurrencyInputComponent,
    PresentAddressComponent,
    NonPresentAddressComponent,
    DependentsDialogComponent,
    AliasInfoComponent,
    DeclarationsComponent,
    BorrowerDeclarationsComponent,
    AboutFinancesComponent,
    AboutPropertyComponent,
    BorrowerDemographicsComponent,
    DemographicsComponent,
    MilitaryServiceComponent,
    BorrowerMilitaryServiceComponent,
    BorrowingEntityInfoComponent,
    LoanOriginatorInfoComponent,
    TitleInformationComponent,
    EmploymentAndIncomeComponent,
    EmploymentComponent,
    EmployerNameNameComponent,
    LoanPropertyComponent,
    LoanPropertyInfoComponent,
    GiftsOrGrantsComponent,
    HazardInsuranceDetailsComponent,
    OtherNewMortgageLoansComponent,
    RentalIncomeOnPurchasesComponent,
    MortgageLoanInfoComponent,
    IncomeDialogComponent,
    SourceOfFundsDialogComponent,
    PropertyLoanInfoComponent,
    ReoComponent,
    ReoItemComponent,
    SubjectPropertyInputComponent,
    BorrowerPickerComponent,
    OtherIncomeComponent,
    QualifyingTheBorrComponent,
    DueFromBorrowerComponent,
    MortgageLoanDetailsComponent,
    UnderwritingComponent,
    UnderwritingItemComponent,
    CreditDetailsComponent,
    CalculatedTotalsComponent,
    MortgageSaveDialogComponent,
    MortgageExportDialogComponent,
    PropertyTaxBreakdownDialogComponent,
    HoaDuesCondoFeesBreakdownDialogComponent,
    SupplementalPropertyInsuranceBreakdownDialogComponent,
    CityCountyPickerDialogComponent,
    ZipCodePickerDialogComponent,
    ReoExpenseBreakdownComponent,
    UrlaLegalDescriptionsComponent,
    UrlaTextAreaInputComponent,
    LanguagePreferencesComponent,
    UrlaDateTimeInputComponent
  ],
  providers: []
})
export class UrlaModule {
}


