export class LoanPurpose {
  companyId: number;
  loanPurposeId: number;
  loanPurposeName: string;
  mortgageLoanPurpose?: string;
  transactionType?: TransactionType;
  enabledChannels?: string;
  order: number;
}


export enum TransactionType {
  Mortgage = 'Mortgage', 
  HomeEquityInvestment = 'HomeEquityInvestment', 
  Solar = 'Solar',
  AutoLoan = 'AutoLoan', 
  PersonalLoan = 'PersonalLoan'
}

export class LoanPurposeTrigger extends LoanPurpose {
  isSelected: boolean;
  loanStatusId: number;
  loanTypes: Array<number>;
}
