<div class="card">
  <div class="card-header d-flex justify-content-between">
    <h4 class="card-title">
      Coverage - {{index + 1}}
    </h4>
    <button class="btn btn-sm btn-outline-danger" (click)="onDeleteClicked()">
      <i class="fas fa-trash-alt me-1"></i> Delete
    </button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="form-group col-xs-12">
        <label class="mb-2" for="coverageType"> Coverage Type </label>
        <select class="form-select" name="coverageType" id="coverageType"
          [(ngModel)]="hazardInsuranceDetail.coverageType" disabled="true">
          <option> {{hazardInsuranceDetail.coverageType}} </option>
        </select>
      </div>
      <div class="form-group col-xs-12">
        <label for="coverageAmount" class="label-tax"> Coverage Amount </label>
        <currency-input name="coverageAmount" [(ngModel)]="hazardInsuranceDetail.coverageAmount" [placeholder]="'Amount'">
        </currency-input>
      </div>
      <div class="form-group col-xs-12 col-sm-6">
        <label for="expirationDate"> Expiration Date </label>
        <date-input name="expirationDate" id="expirationDate" [(ngModel)]="expirationDate"
          (ngModelChange)="onDateChanged()">
        </date-input>
      </div>
      <div class="form-group col-xs-12 col-sm-6">
        <label for="nextPremiumDueDate"> Next Premium Due Date </label>
        <date-input name="nextPremiumDueDate" id="nextPremiumDueDate" [(ngModel)]="nextPremiumDueDate"
          (ngModelChange)="onDateChanged()">
        </date-input>
      </div>
      <div class="form-group col-xs-12 col-sm-6">
        <label for="policyCancellationDate"> Policy Cancellation Date </label>
        <date-input name="policyCancellationDate" id="policyCancellationDate" [(ngModel)]="policyCancellationDate"
          (ngModelChange)="onDateChanged()">
        </date-input>
      </div>
      <div class="form-group col-xs-12 col-sm-6">
        <label for="policyIdentifier" class="label-tax"> Policy Identifier
        </label>
        <input type="text" name="policyIdentifier" class="form-control"
          [(ngModel)]="hazardInsuranceDetail.policyIdentifier">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        <h6> Property Has Insurance </h6>
      </div>
      <div class="col-6 mt-2 text-end">
        <div class="radio radio-info form-check-inline">
          <input type="radio" id="yes_propertyHasInsurance_{{index}}" [value]="true" name="propertyHasInsurance_{{index}}"
            [(ngModel)]="hazardInsuranceDetail.hasInsurance">
          <label for="yes_propertyHasInsurance_{{index}}"> Yes </label>
        </div>
        <div class="radio radio-info form-check-inline ms-3">
          <input type="radio" id="no_propertyHasInsurance_{{index}}" [value]="false" name="propertyHasInsurance_{{index}}"
            [(ngModel)]="hazardInsuranceDetail.hasInsurance">
          <label for="no_propertyHasInsurance_{{index}}"> No </label>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        <h6> Loan Requires Insurance </h6>
      </div>
      <div class="col-6 mt-2 text-end">
        <div class="radio radio-info form-check-inline">
          <input type="radio" id="yes_loanRequiresInsurance_{{index}}" [value]="true" name="loanRequiresInsurance_{{index}}"
            [(ngModel)]="hazardInsuranceDetail.isInsuranceRequired">
          <label for="yes_loanRequiresInsurance_{{index}}"> Yes </label>
        </div>
        <div class="radio radio-info form-check-inline ms-3">
          <input type="radio" id="no_loanRequiresInsurance_{{index}}" [value]="false" name="loanRequiresInsurance_{{index}}"
            [(ngModel)]="hazardInsuranceDetail.isInsuranceRequired">
          <label for="no_loanRequiresInsurance_{{index}}"> No </label>
        </div>
      </div>
    </div>
  </div>
</div>