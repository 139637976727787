<form (ngSubmit)='onSubmit()'>
  <div class='card'>
    <div class='card-body'>
      <!-- Creditor Name -->
      <div class='form-group'>
        <label for='creditor-name'>Creditor Name</label>
        <text-input
          [(ngModel)]='formValue.creditorName'
          name='creditorName'
          id='creditor-name'
        ></text-input>
      </div>

       <!-- Lender Loan Identifier -->
       <div class='form-group'>
        <label for='lender-loan-identifier'>Lender Loan Identifier</label>
        <text-input
          [(ngModel)]='formValue.lenderLoanIdentifier'
          name='lenderLoanIdentifier'
          id='lender-loan-identifier'
        ></text-input>
      </div>

       <!-- Agency Case Identifier -->
       <div class='form-group'>
        <label for='agency-case-identifier'>Agency Case Identifier</label>
        <text-input
          [(ngModel)]='formValue.agencyCaseIdentifier'
          name='agencyCaseIdentifier'
          id='agency-case-identifier'
        ></text-input>
      </div>

      <!-- Investor Loan Identifier -->
      <div class='form-group'>
        <label for='investor-loan-identifier'>Investor Loan Identifier</label>
        <text-input
          [(ngModel)]='formValue.investorLoanIdentifier'
          name='investorLoanIdentifier'
          id='investor-loan-identifier'
        ></text-input>
      </div>

       <!-- Assigned To Name -->
       <div class='form-group'>
        <label for='assigned-to-name'>Assigned To Name</label>
        <text-input
          [(ngModel)]='formValue.assignedToName'
          name='assignedToName'
          id='assigned-to-name'
        ></text-input>
      </div>

      <!-- Borrower Name -->
      <div class='form-group'>
        <label for='borrower-name'>Borrower Name</label>
        <text-input
          [(ngModel)]='formValue.borrowerName'
          name='borrowerName'
          id='borrower-name'
        ></text-input>
      </div>

      <!-- Lender Name -->
      <div class='form-group'>
        <label for='lender-name'>Lender Name</label>
        <text-input
          [(ngModel)]='formValue.lenderName'
          name='lenderName'
          id='lender-name'
        ></text-input>
      </div>

      <!-- Title Held By Name -->
      <div class='form-group'>
        <label for='title-held-by-name'>Title Held By Name</label>
        <text-input
          [(ngModel)]='formValue.titleHeldByName'
          name='titleHeldByName'
          id='title-held-by-name'
        ></text-input>
      </div>

      <!-- Trustee Name -->
      <div class='form-group'>
        <label for='trustee-name'>Trustee Name</label>
        <text-input
          [(ngModel)]='formValue.trusteeName'
          name='trusteeName'
          id='trustee-name'
        ></text-input>
      </div>

      <!-- Prior Recording Book Number -->
      <div class='form-group'>
        <label for='prior-recording-book-number'>
          Prior Recording Book Number
        </label>
        <text-input
          [(ngModel)]='formValue.priorRecordingBookNumber'
          name='priorRecordingBookNumber'
          id='prior-recording-book-number'
        ></text-input>
      </div>

      <!-- Prior Recording Instrument Number -->
      <div class='form-group'>
        <label for='prior-recording-instrument-number'>
          Prior Recording Instrument Number
        </label>
        <text-input
          [(ngModel)]='formValue.priorRecordingInstrumentNumber'
          name='priorRecordingInstrumentNumber'
          id='prior-recording-instrument-number'
        ></text-input>
      </div>

      <!-- Prior Recording Page Number -->
      <div class='form-group'>
        <label for='prior-recording-page-number'>
          Prior Recording Page Number
        </label>
        <text-input
          [(ngModel)]='formValue.priorRecordingPageNumber'
          name='priorRecordingPageNumber'
          id='prior-recording-page-number'
        ></text-input>
      </div>

      <!-- Prior Recording Jurisdiction Name -->
      <div class='form-group'>
        <label for='prior-recording-jurisdiction-name'>
          Prior Recording Jurisdiction Name
        </label>
        <text-input
          [(ngModel)]='formValue.priorRecordingJurisdictionName'
          name='priorRecordingJurisdictionName'
          id='prior-recording-jurisdiction-name'
        ></text-input>
      </div>

       <!-- Loan Term Months -->
       <div class='form-group'>
        <label for='loan-term-months'>Loan Term Months</label>
        <number-input
          [(ngModel)]='formValue.loanTermMonths'
          name='loanTermMonths'
          id='loan-term-months'
        ></number-input>
      </div>

      <!-- Remaining Term To Maturity Months -->
      <div class='form-group'>
        <label for='remaining-term'>Remaining Term To Maturity Months</label>
        <number-input
          [(ngModel)]='formValue.remainingTermToMaturityMonths'
          name='remainingTermToMaturityMonths'
          id='remaining-term'
        ></number-input>
      </div>

      <!-- Lien Type -->
      <div class='form-group'>
        <label for='lien-type'>Lien Type</label>
        <select-input
          [(ngModel)]='formValue.lienType'
          name='lienType'
          id='lien-type'
          [required]='true'
          [options]='lienTypeOptions'
        ></select-input>
      </div>

      <!-- Funds Source -->
      <div class='form-group'>
        <label for='funds-source'>Funds Source</label>
        <select-input
          [(ngModel)]='formValue.fundsSource'
          name='fundsSource'
          id='funds-source'
          [options]='fundsSourceOptions'
        ></select-input>
      </div>

      <!-- Amortization Type -->
      <div class='form-group'>
        <label for='amortization-type'>Amortization Type</label>
        <select-input
          [(ngModel)]='formValue.amortizationType'
          name='amortizationType'
          id='amortization-type'
          [options]='amortizationTypeOptions'
        ></select-input>
      </div>

      <!-- Mortgage Type -->
      <div class='form-group'>
        <label for='mortgage-type'>Mortgage Type</label>
        <select-input
          [(ngModel)]='formValue.mortgageType'
          name='mortgageType'
          id='mortgage-type'
          [options]='mortgageTypeOptions'
        ></select-input>
      </div>

      <!-- Loan Purpose -->
      <div class='form-group'>
        <label for='loan-purpose'>Loan Purpose</label>
        <select-input
          [(ngModel)]='formValue.loanPurpose'
          name='loanPurpose'
          id='loan-purpose'
          [options]='loanPurposeOptions'
        ></select-input>
      </div>

      <!-- Monthly Payment -->
      <div class='form-group'>
        <label for='monthly-payment'>Monthly Payment</label>
        <currency-input
          [(ngModel)]='formValue.monthlyPayment'
          name='monthlyPayment'
          id='monthly-payment'
          [required]='true'
        ></currency-input>
      </div>

      <!-- Loan or Draw Amount -->
      <div class='form-group'>
        <label for='loan-or-draw-amount'>Loan or Draw Amount</label>
        <currency-input
          [(ngModel)]='formValue.loanOrDrawAmount'
          name='loanOrDrawAmount'
          id='loan-or-draw-amount'
          [required]='true'
        ></currency-input>
      </div>

      <!-- Credit Limit -->
      <div class='form-group'>
        <label for='credit-limit'>Credit Limit</label>
        <currency-input
          [(ngModel)]='formValue.creditLimit'
          name='creditLimit'
          id='credit-limit'
        ></currency-input>
      </div>

      <!-- Unpaid Balance -->
      <div class='form-group'>
        <label for='unpaid-balance'>Unpaid Balance</label>
        <currency-input
          [(ngModel)]='formValue.unpaidBalance'
          name='unpaidBalance'
          id='unpaid-balance'
        ></currency-input>
      </div>

      <!-- Fee Summary Total Of All Payments -->
      <div class='form-group'>
        <label for='fee-summary-total'>Fee Summary Total Of All Payments</label>
        <currency-input
          [(ngModel)]='formValue.feeSummaryTotalOfAllPayments'
          name='feeSummaryTotalOfAllPayments'
          id='fee-summary-total'
        ></currency-input>
      </div>

      <!-- MI Premium Monthly Payment -->
      <div class='form-group'>
        <label for='mi-premium'>MI Premium Monthly Payment</label>
        <currency-input
          [(ngModel)]='formValue.miPremiumMonthlyPayment'
          name='miPremiumMonthlyPayment'
          id='mi-premium'
        ></currency-input>
      </div>

      <!-- VA Residual Income -->
      <div class='form-group'>
        <label for='va-residual-income'>VA Residual Income</label>
        <currency-input
          [(ngModel)]='formValue.vaResidualIncome'
          name='vaResidualIncome'
          id='va-residual-income'
        ></currency-input>
      </div>

      <!-- Balloon Payment Amount -->
      <div class='form-group'>
        <label for='balloon-payment'>Balloon Payment Amount</label>
        <currency-input
          [(ngModel)]='formValue.balloonPaymentAmount'
          name='balloonPaymentAmount'
          id='balloon-payment'
        ></currency-input>
      </div>

      <!-- Note Rate -->
      <div class='form-group'>
        <label for='note-rate'>Note Rate</label>
        <percent-input
          [(ngModel)]='formValue.noteRate'
          [maxValue]="100"
          name='noteRate'
          id='note-rate'
        ></percent-input>
      </div>

      <!-- LTV -->
      <div class='form-group'>
        <label for='ltv'>LTV</label>
        <percent-input
          [(ngModel)]='formValue.ltv'
          [maxValue]="200"
          name='ltv'
          id='ltv'
        ></percent-input>
      </div>

      <!-- Assignment Date -->
      <div class='form-group'>
        <label for='assignment-date'>Assignment Date</label>
        <date-input
          [(ngModel)]='formValue.assignmentDate'
          name='assignmentDate'
          id='assignment-date'
        ></date-input>
      </div>

       <!-- Note Date -->
       <div class='form-group'>
        <label for='note-date'>Note Date</label>
        <date-input
          [(ngModel)]='formValue.noteDate'
          name='noteDate'
          id='note-date'
        ></date-input>
      </div>

      <!-- Mortgage Date -->
      <div class='form-group'>
        <label for='mortgage-date'>Mortgage Date</label>
        <date-input
          [(ngModel)]='formValue.mortgageDate'
          name='mortgageDate'
          id='mortgage-date'
        ></date-input>
      </div>

      <!-- Prior Recording DateTime -->
      <div class='form-group'>
        <label for='prior-recording-date-time'>Prior Recording DateTime</label>
        <date-time-input
          [(ngModel)]='formValue.priorRecordingDateTime'
          [showTime]='true'
          name='priorRecordingDateTime'
          id='prior-recording-date-time'
        ></date-time-input>
      </div>

      <!-- is HELOC -->
      <div class="form-check form-switch form-switch-success">
        <input 
          class="form-check-input" 
          type="checkbox" 
          name="isHeloc" 
          id="is-heloc" 
          [(ngModel)]="formValue.isHeloc">
        <label class="form-check-label" for="is-heloc">Is HELOC</label>
      </div>

      <!-- Will be Refinanced Into Subject Loan -->
      <div class="form-check form-switch form-switch-success">
        <input 
          class="form-check-input" 
          type="checkbox" 
          name="willBeRefinancedIntoSubjectLoan" 
          id="will-be-refinanced" 
          [(ngModel)]="formValue.willBeRefinancedIntoSubjectLoan">
        <label class="form-check-label" for="will-be-refinanced">Will be Refinanced Into Subject Loan</label>
      </div>

      <!-- Will be Modified Into Subject Loan -->
      <div class="form-check form-switch form-switch-success">
        <input 
          class="form-check-input" 
          type="checkbox" 
          name="willBeModifiedIntoSubjectLoan" 
          id="will-be-modified" 
          [(ngModel)]="formValue.willBeModifiedIntoSubjectLoan">
        <label class="form-check-label" for="will-be-modified">Will be Modified Into Subject Loan</label>
      </div>

      <!-- Is Assumable -->
      <div class="form-check form-switch form-switch-success">
        <input 
          class="form-check-input" 
          type="checkbox" 
          name="isAssumable" 
          id="is-assumable" 
          [(ngModel)]="formValue.isAssumable">
        <label class="form-check-label" for="is-assumable">Is Assumable</label>
      </div>

      <!-- Is Lien Holder Same As Subject Loan -->
      <div class="form-check form-switch form-switch-success">
        <input 
          class="form-check-input" 
          type="checkbox" 
          name="isLienHolderSameAsSubjectLoan" 
          id="is-lien-holder-same" 
          [(ngModel)]="formValue.isLienHolderSameAsSubjectLoan">
        <label class="form-check-label" for="is-lien-holder-same">Is Lien Holder Same As Subject Loan</label>
      </div>

      <!-- Has Prepayment Penalty -->
      <div class="form-check form-switch form-switch-success">
        <input 
          class="form-check-input" 
          type="checkbox" 
          name="hasPrepaymentPenalty" 
          id="prepayment-penalty" 
          [(ngModel)]="formValue.hasPrepaymentPenalty">
        <label class="form-check-label" for="prepayment-penalty">Has Prepayment Penalty</label>
      </div>

      <!-- Is Deed Of Trust -->
      <div class="form-check form-switch form-switch-success">
        <input 
          class="form-check-input" 
          type="checkbox" 
          name="isDeedOfTrust" 
          id="is-deed-of-trust" 
          [(ngModel)]="formValue.isDeedOfTrust">
        <label class="form-check-label" for="is-deed-of-trust">Is Deed Of Trust</label>
      </div>

      <!-- Is Affordable Loan -->
      <div class="form-check form-switch form-switch-success">
        <input 
          class="form-check-input" 
          type="checkbox" 
          name="isAffordableLoan" 
          id="is-affordable-loan" 
          [(ngModel)]="formValue.isAffordableLoan">
        <label class="form-check-label" for="is-affordable-loan">Is Affordable Loan</label>
      </div>
      
      <!-- Is P&I Deferred First Five Years -->
      <div class="form-check form-switch form-switch-success">
        <input 
          class="form-check-input" 
          type="checkbox" 
          name="isPrincipalAndInterestDeferredFirstFiveYears" 
          id="is-principal-and-interest-deferred-first-five-years" 
          [(ngModel)]="formValue.isPrincipalAndInterestDeferredFirstFiveYears">
        <label class="form-check-label" for="is-principal-and-interest-deferred-first-five-years">Is P&I Deferred First Five Years</label>
      </div>
    </div>
  </div>

  <div class='modal-footer d-flex align-items-center gap-2'>
    <button (click)='onCancel()' class='btn btn-secondary' type='button'>
      Cancel
    </button>

    <button class='btn btn-primary'>
      <i class='fa fa-save'></i>
      Save
    </button>
  </div>
</form>
