import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';
import { MenuItemComponent } from '../../tpo/components/menu-item/menu-item.component';
import { Subscription } from 'rxjs';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DateTime } from 'luxon';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from 'src/app/services/constants';
import { MenuItemStatus } from '../../tpo/models/enums/menu-item-status.enum';
import { LoanService } from 'src/app/services/loan';

@Component({
  selector: 'urla-menu',
  templateUrl: 'urla-menu.component.html',
  styleUrls: ['./urla-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrlaMenuComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChildren(MenuItemComponent) menuItems:
    | QueryList<MenuItemComponent>
    | undefined;

  @Output()
  menuItemClicked: EventEmitter<string> = new EventEmitter<string>();

  isReadOnly: boolean = false;

  isMersEnabled: boolean = false;
  isRequiredLoanAndCaseNumbers: boolean = false;

  private _menuItemStatusSubscription: Subscription;
  private _menuItemActiveStatusChangeSubscription: Subscription;
  private _loanInfoChangesSubscription: Subscription;

  private _lastMenuStatusUpdateTimeStamp: DateTime;

  private _checkMenuStatusLoadInterval: NodeJS.Timeout;

  constructor(
    private readonly injector: Injector,
    private readonly _menuService: MenuService,
    private readonly _urlaValidationService: UrlaValidationService,
    private readonly _loanService: LoanService,
    private readonly _spinner: NgxSpinnerService) {
    super(injector);

    this._menuItemActiveStatusChangeSubscription = this._menuService.menuItemChangedToActive.subscribe(menuItemId => {
      this.menuItems.forEach(mi => {
        mi.isActive = (mi.id === menuItemId);
      })
    });

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.startMenuStatusUpdateListener();
      }
    });
  }

  ngOnInit(): void {

    if (this.applicationContext.isTpo) {
      this._loanService.getMenuStatuses(this.applicationContext.application.applicationId).subscribe((res: any) => {
        const statusItem = res[Constants.menu.servicesMenuItems.submission];
        const tpoSubmitted = statusItem && statusItem == MenuItemStatus.Success;
        if (tpoSubmitted) {
          this.isReadOnly = true;
        }
      });
    }

    this.isMersEnabled = this.applicationContext.userPermissions?.mersEnabled;
    this.isRequiredLoanAndCaseNumbers = this.applicationContext.application.channel !== 'Wholesale' || !this.applicationContext.isTpo;

    if (this.applicationContext.application.mortgageLoan.companyId == 229 || this.applicationContext.application.mortgageLoan.companyId == 276 ) {
      this._urlaValidationService.loadDeepHavenUrlaFieldsConFig();
    }

    this._menuService.setStatus('borrowerInfo', this._urlaValidationService.getStatusForBorrowersInfo(this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('financialInfo', this._urlaValidationService.getStatusForFinancialInfo(this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('reo', this._urlaValidationService.getStatusForReo(this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('loanAndProperty', this._urlaValidationService.getStatusForLoanProperty(this.applicationContext.application.mortgageLoan, false));
    this._menuService.setStatus('declarations', this._urlaValidationService.getStatusForDeclarations(this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('militaryService', this._urlaValidationService.getStatusForMilitaryService(this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('demographics', this._urlaValidationService.getStatusForDemographics(this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('loanOriginatorInfo', this._urlaValidationService.getStatusForLoanOriginatorInfo(this.applicationContext.application.mortgageLoan, this.isMersEnabled, this.isRequiredLoanAndCaseNumbers));
    this._menuService.setStatus('propertyLoanInfo', this._urlaValidationService.getStatusForPropertyLoanInfo(this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('titleInfo', this._urlaValidationService.getStatusForTitleInfo(this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('mortgageLoanInfo', this._urlaValidationService.getStatusForMortgageLoanInfo(this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('qualifyingTheBorr', this._urlaValidationService.getStatusForQualifyingTheBorr(this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('homeownershipEducation', this._urlaValidationService.getStatusForHomeownershipEducation(this.applicationContext.application.mortgageLoan));

    this.startMenuStatusUpdateListener();
    this._menuItemStatusSubscription = this._menuService.menuItemStatusChanged.subscribe(e => {
      const menuItem = this.menuItems.find(mi => mi.id === e.id);
      if (menuItem) {
        menuItem.status = e.status;
        this._lastMenuStatusUpdateTimeStamp = DateTime.local();
      }
    });
  }

  ngOnDestroy(): void {
    if (this._menuItemStatusSubscription) {
      this._menuItemStatusSubscription.unsubscribe();
    }
    if (this._menuItemActiveStatusChangeSubscription) {
      this._menuItemActiveStatusChangeSubscription.unsubscribe();
    }
    this._loanInfoChangesSubscription?.unsubscribe();
  }

  onMenuItemClicked = (menuItemId: string) => {
    // this.menuItems.filter(item => item.id != menuItemId).forEach(item => {item.isActive = false;});
    this.menuItemClicked.emit(menuItemId);
  }

  private startMenuStatusUpdateListener = () => {
    this._spinner.show("urla-menu-spinner");
    this._checkMenuStatusLoadInterval = setInterval(() => {
      const now = DateTime.local();
      // Find the difference in seconds between the last update and now
      const diffInSeconds = now.diff(this._lastMenuStatusUpdateTimeStamp, 'seconds').seconds;
      // If the difference is greater than 2 seconds, then we can assume that the menu status updates are done, and we can stop the interval and the
      // spinner
      if (diffInSeconds >= 1) {
        this._spinner.hide("urla-menu-spinner");
        clearInterval(this._checkMenuStatusLoadInterval);
      }
    }, 2000);
  }
}
