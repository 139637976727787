<form #formElement="ngForm">
  <div *ngIf="mortgage.mortgageId != null" class="borrower-container">
    <div class="grid-layout has-border">
      <div class="grid-header mb-1">
        <div class="grid-header-left">L2. Legal Descriptions</div>
        <div class="grid-header-right"></div>
      </div>

      <div class="grid-layout-row px-2 mb-2">
        <legal-descriptions
          class="col-12"
          [descriptions]="descriptions"
          [inEditMode]="inEditMode"
          [isReadOnly]="isReadOnly"
          [urlaFieldsConfig]="urlaFieldsConfig"
        ></legal-descriptions>
      </div>

      <div class="grid-layout-row px-2 mb-2">
        <parcel-identifications
          class="col-12"
          [parcelIdentifications]="descriptions.parcelIdentifications"
          [inEditMode]="inEditMode"
          [isReadOnly]="isReadOnly"
          [urlaFieldsConfig]="urlaFieldsConfig"
          (descriptionsChanged)="onParcelIdDescriptionsChanged($event)"
        ></parcel-identifications>
      </div>
    </div>
  </div>
</form>

<loading-indicator
  *ngIf="mortgage.mortgageId == null"
  [loadingMessage]="'Loading legal descriptions...'"
></loading-indicator>
