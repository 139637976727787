import { PayPeriod } from '../models/lead-employment.model';

export module LeadUtils {
  export function calculateMonthlyAmountByPayPeriod(
    amount?: number,
    payPeriod?: PayPeriod,
    hoursPerWeek?: number,
  ): number {
    if (amount == null || payPeriod == null) {
      return 0;
    }

    switch (payPeriod) {
      case PayPeriod.Monthly:
        return amount;
      case PayPeriod.Annually:
        return amount / 12;
      case PayPeriod.Biweekly:
        return amount * 26 / 12;
      case PayPeriod.Bimonthly:
        return amount / 2;
      case PayPeriod.Weekly:
        return amount * 52 / 12;
      case PayPeriod.Quarterly:
        return amount / 3;
      case PayPeriod.Semiannually:
        return amount / 6;
      case PayPeriod.Hourly:
        if (hoursPerWeek == null) {
          return 0;
        }

        const monthlyHours = hoursPerWeek * 52 / 12;
        return amount * monthlyHours;
      case PayPeriod.Semimonthly:
        return amount * 2;
      default:
        throw new Error(`Unknown pay period: ${payPeriod}`);
    }
  }

  export function calculateSelectedPayPeriodAmountByMonthlyAmount(
    amount?: number,
    payPeriod?: PayPeriod,
    hoursPerWeek?: number,
  ): number {
    if (amount == null || payPeriod == null) {
      return 0;
    }

    switch (payPeriod) {
      case PayPeriod.Monthly:
        return amount;
      case PayPeriod.Annually:
        return amount / 12;
      case PayPeriod.Biweekly:
        return amount / (26 / 12);
      case PayPeriod.Bimonthly:
        return amount * 2;
      case PayPeriod.Weekly:
        return amount / (52 / 12);
      case PayPeriod.Quarterly:
        return amount * 3;
      case PayPeriod.Semiannually:
        return amount * 6;
      case PayPeriod.Hourly:
        if (hoursPerWeek == null) {
          return 0;
        }

        const monthlyHours = hoursPerWeek / (52 / 12);
        return amount / monthlyHours;
      case PayPeriod.Semimonthly:
        return amount / 2;
      default:
        throw new Error(`Unknown pay period: ${payPeriod}`);
    }
  }


  export function calculateTotalMonthlyIncome(incomes: Array<{ monthlyIncome?: number }>) {
    return incomes.reduce(
      (total, income) => total + (income.monthlyIncome ?? 0),
      0,
    );
  }
}
