import { BorrowerVerificationReportType } from "./borrower-verification-document"

export class GenerateBorrowerVerificationReportRequest {
  thirdPartyOrderId: string;
  daysBack: number;
  harvestNewData: boolean;
  reportType: BorrowerVerificationReportType;
  applicationId: number;
  leadId: number;
  borrowerId: number;
}
