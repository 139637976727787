import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContext, LoanApplication } from 'src/app/models';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UrlaMortgage } from '../../urla/models/urla-mortgage.model';
import { CocReasonsService } from '../coc-details.service';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { cloneDeep } from 'lodash';
import { ChangeOfCircumstanceDetail } from '../../disclosure-tracking/models/disclosure-tracking.model';
import { Subscription } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'app-coc-reasons',
  templateUrl: './coc-reasons.component.html',
  styleUrls: ['./coc-reasons.component.scss']
})
export class CocReasonsComponent implements OnChanges, OnDestroy {
  @Input()
  disclosureTrackingId?: number;

  @Input()
  readonly?: boolean;

  mortgage: UrlaMortgage;
  application: LoanApplication;

  urlaFieldsConfig: any = {};
  cocDetailsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }
  selectedCocDetails: ChangeOfCircumstanceDetail;

  changeOfCircumstances: ChangeOfCircumstanceDetail[] = [];
  
  componentId: number;
  drawerName: string;

  private _baseChangeOfCircumstances: ChangeOfCircumstanceDetail[] = [];

  private _contextSubscription: Subscription;
  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly _ctxService: ApplicationContextService,
    private readonly _mortgageService: MortgageService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _cocReasonsService: CocReasonsService,
    private readonly _drawerService: DrawerService,
  ) {
    this.componentId = Utils.getUniqueId() * (-1);
    this.drawerName = `cocDetailsDrawer_${this.componentId}`;

    this._contextSubscription = this._ctxService.context.subscribe(ctx => {
      this._initialize(ctx);
    });

    this._loanInfoChangesSubscription = this._ctxService.loanInfoChanges.subscribe(ctx => {
      this._initialize(ctx);
    });
  }

  ngOnChanges(): void {
    this._drawerService.hide(this.drawerName);
    this._setChangeOfCoc();
  }

  ngOnDestroy(): void {
    this._contextSubscription.unsubscribe();
    this._loanInfoChangesSubscription.unsubscribe();
  }

  onEditClicked(cocDetails: ChangeOfCircumstanceDetail) {    
    this._closeDrawer();

    setTimeout(() => {
      this.selectedCocDetails = cloneDeep(cocDetails);
      this._drawerService.show(this.drawerName, 10);
    }, 100)
  }

  onAddNewClicked() {
    this._closeDrawer();

    setTimeout(() => {
      this.selectedCocDetails = new ChangeOfCircumstanceDetail();
      this._drawerService.show(this.drawerName, 10);
    }, 100)
  }

  onCocDetailsSaved(cocDetail: ChangeOfCircumstanceDetail) {
    const index = this._baseChangeOfCircumstances.findIndex(coc => coc.changeOfCircumstanceId === cocDetail.changeOfCircumstanceId);
    if (index === -1) {
      this._baseChangeOfCircumstances.push(cocDetail);
    } else {
      this._baseChangeOfCircumstances[index] = cocDetail;
    }

    this._setChangeOfCoc();
    this._closeDrawer();
  }

  onCocDetailsDrawerClosed() {
    this._closeDrawer();
  }

  private _closeDrawer() {
    this._drawerService.hide(this.drawerName, 10);
    this.selectedCocDetails = null;
  }

  onDeleteClicked(changeOfCircumstanceId: number) {
    this._spinner.show();
    this._cocReasonsService.deleteCocDetails(changeOfCircumstanceId).subscribe({
      next: () => {
        this._baseChangeOfCircumstances = this._baseChangeOfCircumstances.filter(c => c.changeOfCircumstanceId != changeOfCircumstanceId);
        this._setChangeOfCoc();

        this._notifyService.showSuccess("Change of circumstance deleted successfully.", "Success!");
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't delete change of circumstance.", "Error!")
      }
    }).add(() => {
      this._spinner.hide();
    })
  }

  private _initialize(ctx: ApplicationContext) {
    if (!ctx.application) {
      return;
    }
    
    this.application = ctx.application;
    this.mortgage = ctx.application.mortgageLoan;
    this.urlaFieldsConfig = this._mortgageService.urlaFieldsConfig;

    this._getChangeOfCircumstances();
  }

  private _getChangeOfCircumstances() {
    this._spinner.show();
    this._cocReasonsService.getCocDetails(this.application.mortgageId).subscribe({
      next: (response) => {
        this._baseChangeOfCircumstances = [...response];
        this._setChangeOfCoc();
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't load change of circumstances", "Error!");
      }
    }).add(() => {
      this._spinner.hide();
    })
  }

  private _setChangeOfCoc() {
    if (this.disclosureTrackingId) {
      this.changeOfCircumstances = this._baseChangeOfCircumstances.filter(res => res.disclosureTrackingId === this.disclosureTrackingId);
    } else {
      this.changeOfCircumstances = this._baseChangeOfCircumstances;
    }
  }
}
