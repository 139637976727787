import Swal, { SweetAlertResult } from 'sweetalert2';
import { from, Observable } from 'rxjs';
import { Employment, EmploymentTypeEnum } from '../../../../models';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

/**
 * Type of the value of a form group.
 */
export type FormValue<T> = {
  [K in keyof T]: T[K] extends FormControl<infer U>
    ? U
    : T[K] extends FormGroup<infer U>
      ? FormValue<U>
      : T[K] extends FormArray<infer U>
        ? FormValue<U>[]
        : never;
};

/**
 * Adds or removes a CSS class from a collection of elements conditionally.
 * @param {Object} options
 * @param options.collection The collection of elements to add/remove the class
 * from.
 * @param options.className The name of the CSS class to add/remove.
 * @param options.condition If true, the class will be added to all elements in
 * the collection. If false, it will be removed from all elements.
 */
export function setClassConditionally({
                                        collection,
                                        className,
                                        condition,
                                      }: {
  collection: any[];
  className: string;
  condition: boolean;
}) {
  for (let el of collection) {
    if (condition) {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  }
}

async function _showNotSavedDialog(): Promise<SweetAlertResult> {
  return Swal.fire({
    showDenyButton: true,
    title: 'Are you sure?',
    text: `You have changes that are not saved. Would you like to save your
      changes? If you choose Discard below, your changes will be lost.`,
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: 'Save & Continue!',
    cancelButtonText: 'Cancel',
    cancelButtonColor: '#dd6b55',
    denyButtonText: `Discard & Continue!`,
    reverseButtons: true,
  });
}

export function showNotSavedDialog(
  onConfirm?: () => Promise<boolean>,
): Observable<boolean> {
  const promise = _showNotSavedDialog().then(async (result) => {
    if (result.isConfirmed) {
      return onConfirm != null ? onConfirm() : true;
    } else if (result.isDenied) {
      return true;
    }
    return false;
  });

  return from(promise);
}

/**
 * Converts a number of months to a tuple of years and months.
 * @param {number} months The number of months to convert.
 * @returns {[number, number] | null} A tuple of years and months.
 * If {@link months} is NaN or negative, returns null.
 */
export function monthsToYearsAndMonths(months: number)
  : [number, number] | null {
  if (months == null || isNaN(months) || !isFinite(months) || months < 0) {
    return null;
  }

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;
  return [years, remainingMonths];
}

/**
 * Creates a string representation of a duration.
 * The dates must be valid.
 * @param {number} duration The duration in months.
 * @returns {string} A string representation of the employment duration.
 * @example
 * monthsAsYearsAndMonthsString(-1); // "- years - months"
 * monthsAsYearsAndMonthsString(0); // "0 years 0 months"
 * monthsAsYearsAndMonthsString(1); // "0 years 1 month"
 * monthsAsYearsAndMonthsString(12); // "1 year 0 months"
 * monthsAsYearsAndMonthsString(13); // "1 year 1 month"
 * monthsAsYearsAndMonthsString(14); // "1 year 2 months"
 * monthsAsYearsAndMonthsString(24); // "2 years 0 months"
 * monthsAsYearsAndMonthsString(25); // "2 years 1 month"
 * monthsAsYearsAndMonthsString(26); // "2 years 2 months"
 */
export function monthsAsYearsAndMonthsString(duration: number): string {
  const [years, months] = monthsToYearsAndMonths(duration)
  ?? ['\u2013', '\u2013'];
  const yearText = `${years} year${years === 1 ? '' : 's'}`;
  const monthText = `${months} month${months === 1 ? '' : 's'}`;

  return `${yearText} ${monthText}`;
}

/**
 * Calculates the duration of employment in the past months.
 * @param {Employment} employment The employment to calculate the duration of.
 * @returns {number | null} The duration of the employment in months.
 * If the employment has no start date, returns null.
 * If the employment has no end date, or {@link isCurrentEmployment}, returns
 * the duration up to the current date.
 */
export function calculateEmploymentDurationInMonths(
  employment: Employment,
): number | null {
  const startDate = employment.startDate;
  const endDate = isCurrentEmployment(employment)
    ? null // It will be treated as the current date.
    : employment.endDate;

  return calculateDurationInMonths(startDate, endDate);
}

/**
 * Calculates the duration of employment in the past months, or 0 if the
 * duration is invalid or negative.
 * @see calculateEmploymentDurationInMonths
 * @param {Employment} employment The employment to calculate the duration of.
 * @returns {number} The duration of the employment in months or 0 if the
 * duration is invalid or negative.
 */
export function calculateEmploymentDurationInMonthsSafe(
  employment: Employment,
): number {
  const duration = calculateEmploymentDurationInMonths(employment);
  return duration < 0 ? 0 : duration;
}

/**
 * Calculates the duration between two dates in months.
 * @param {Date | string | number} startDate The start date.
 * @param {Date | string | number} endDate The end date.
 * @returns {number | null} The duration between the two dates in months.
 * If {@link startDate} is invalid, returns null.
 * If {@link endDate} is invalid, returns the duration up to the current date.
 * If {@link startDate} is after {@link endDate}, returns a negative number.
 */
export function calculateDurationInMonths(
  startDate: number | string | Date,
  endDate: number | string | Date,
): number | null {
  const start = toSafeDate(startDate);
  if (start == null) {
    return null;
  }

  // If the employment has no end date, use the current date.
  const end = toSafeDate(endDate) ?? new Date();

  // Calculate the difference in months between the start and end dates.
  return (end.getFullYear() - start.getFullYear()) * 12
    + (end.getMonth() - start.getMonth());
}

/**
 * Calculates the duration of employment in the past months and returns it as a
 * string.
 * @see calculateEmploymentDurationInMonths
 * @see monthsAsYearsAndMonthsString
 *
 * @example
 * const employment = {
 *  startDate: new Date(2019, 0, 1),
 *  endDate: new Date(2020, 1, 1),
 * }
 *
 * calculateEmploymentDurationAsString(employment); // "1 year 1 month"
 *
 * @param {Employment} employment The employment to calculate the duration of.
 * @returns {string} The duration of the employment as a string.
 */
export function calculateEmploymentDurationAsString(
  employment: Employment,
): string {
  return monthsAsYearsAndMonthsString(
    calculateEmploymentDurationInMonths(employment),
  );
}

/**
 * Converts a date to a safe date. If the date is null, undefined, or 0, returns
 * undefined. If the date is invalid, returns undefined.
 * @param date The date to convert.
 * @returns The date as a Date object if it is valid, otherwise undefined.
 */
export function toSafeDate(date: number | string | Date): Date | undefined {
  // new Date(null) equivalent to new Date(0) (which is 1/1/1970). We treat this
  // as invalid, as probably it's not intentional.
  if (date == null || date === 0) {
    return undefined;
  }

  const dateObj = new Date(date);
  // If the date is invalid, getTime() will return NaN.
  if (isNaN(dateObj.getTime())) {
    return undefined;
  }

  return dateObj;
}

/**
 * Checks if employment is the current employment.
 * This is determined by checking if the employment type is
 * {@link EmploymentTypeEnum.CurrentEmployer}.
 * @param employment The employment to check.
 */
export function isCurrentEmployment(employment: Employment): boolean {
  return employment.employmentType === EmploymentTypeEnum.CurrentEmployer;
}
