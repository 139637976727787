import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { DocuViewareService } from 'src/app/services/docuvieware.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { TasksModule } from '../tasks/tasks.module';
import { DocFilesEditDialogComponent } from './components/doc-files-edit-dialog/doc-files-edit-dialog.component';
import { DocFilesComponent } from './components/doc-files/doc-files.component';
import { DocMappingForVendorDialogComponent } from './components/doc-mapping-for-vendor-dialog/doc-mapping-for-vendor-dialog.component';
import { LoanDocsAccordionComponent } from './components/loan-docs-accordion/loan-docs-accordion.component';
import { LoanDocsDialogComponent } from './components/loan-docs-dialog/loan-docs-dialog.component';
import { LoanDocsTrackingDialogComponent } from './components/loan-docs-tracking-dialog/loan-docs-tracking-dialog.component';
import { LoanDocsComponent } from './components/loan-docs.component';
import { ProcessAdrOcrDialogComponent } from './components/process-adr-ocr-dialog/process-adr-ocr-dialog.component';
import { SyncLodaDocWithLosDialogComponent } from './components/sync-loda-doc-with-los-dialog/sync-loda-doc-with-los-dialog.component';
import { SyncLodaLosDocsAccordionComponent } from './components/sync-loda-los-docs-accordion/sync-loda-los-docs-accordion.component';
import { LoanDocsRoutingModule } from './loan-docs-routing.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { GenerateDocumentDrawerComponent } from './components/generate-document-drawer/generate-document-drawer.component';
import { DocFilesEditDialogModule } from './components/doc-files-edit-dialog/doc-files-edit-dialog.module';
import { PurchaseAdviceComponent } from './components/purchase-advice/purchase-advice.component';

@NgModule({
    imports: [
        SharedModule,
        TableModule,
        LoanDocsRoutingModule,
        TasksModule,
        ProgressBarModule,
        DocFilesEditDialogModule
    ],
    declarations: [
        LoanDocsComponent,
        LoanDocsAccordionComponent,
        LoanDocsDialogComponent,
        DocFilesComponent,
        LoanDocsTrackingDialogComponent,
        ProcessAdrOcrDialogComponent,
        SyncLodaDocWithLosDialogComponent,
        SyncLodaLosDocsAccordionComponent,
        DocMappingForVendorDialogComponent,
        GenerateDocumentDrawerComponent,
        PurchaseAdviceComponent,
    ],
    exports: [
        LoanDocsComponent,
        LoanDocsAccordionComponent,
        LoanDocsDialogComponent,
        DocFilesComponent,
        LoanDocsTrackingDialogComponent,
        ProcessAdrOcrDialogComponent,
        SyncLodaDocWithLosDialogComponent,
        SyncLodaLosDocsAccordionComponent,
        DocMappingForVendorDialogComponent,
        GenerateDocumentDrawerComponent,
        PurchaseAdviceComponent,
    ],
    providers: [DocuViewareService]
})
export class LoanDocsModule {
  constructor() { }
}
