import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Event, EventType } from '../../models/event.model';
import { EventsService } from '../../services/events.service';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { EventEditorComponent } from '../event-editor/event-editor.component';

@Component({
  selector: 'event-editor-dialog',
  templateUrl: 'event-editor-dialog.component.html',
  styleUrls: ['event-editor-dialog.component.scss']
})

export class EventEditorDialogComponent implements OnInit {

  @ViewChild('eventEditor') eventEditor: EventEditorComponent | undefined;

  title: string = "Creating Event";
  borrowerName: string;
  displayAddress: string;

  inEditMode: boolean = false;
  isLoading: boolean = false;
  isDeleteLoading: boolean = false;

  @Input()
  event: Event;

  @Input()
  eventType: EventType;

  @Input()
  selectedDate: Date = new Date();

  @Input()
  showAddressAndBorrowerInfo: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private readonly _eventsService: EventsService) {
  }

  ngOnInit(): void {
    if (this.event && this.event.eventId) {
      this.title = "Editing Event - " + this.event.title;
    }
  }

  onSaveClicked = () => {
    this.isLoading = true;
    this.eventEditor.save();
  }

  onEventSaved = (updatedEvent: Event) => {
    this.isLoading = false;
    this.event = updatedEvent;
    this.activeModal.close(this.event);
  }

  onFormInvalid = () => {
    this.isLoading = false;
  }

  onDeleteClick(): void {
    this.isDeleteLoading = true;
    this._eventsService.deleteEvent(this.event.eventId, this.event.thirdPartyEventId)
    .pipe(finalize(() => {
      this.isDeleteLoading = false;
    })).subscribe({
      next: (response) => {
        this.activeModal.close(response);
      },
      error: (error) => {
        this.isDeleteLoading = false;
      }
    });
  }
}
