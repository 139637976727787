<div class="grid-layout has-border">
  <div class="grid-header">
    <div class="grid-header-left">
      <div class="mt-1"> UNDERWRITING </div>
    </div>
    <div class="grid-header-right">
      <button *ngIf="!isReadOnly && !inEditMode" class="btn btn-sm btn-soft-primary me-2" type="button" [outsideClick]="false" [popover]="newAusDataTemplate"
        #pop="bs-popover" data-bs-container="body" data-bs-toggle="popover" placement="bottom">
        <i class="fas fa-plus"></i> Add New
      </button>
      <ng-template #newAusDataTemplate>
        <form #newAusDataForm="ngForm" novalidate id="newAusDataForm" name="newAusDataForm">
          <div class="form-group">
            <label for="ausSystemType"> Aus System Type </label>
            <select class="form-select aus-system-type-select" [ngClass]="{
                  'is-invalid':
                  ausSystemInput &&
                  ausSystemInput.touched &&
                  ausSystemInput.invalid
                }" name="ausSystemType" #ausSystemInput="ngModel" required [(ngModel)]="selectedType">
              <option *ngFor="let type of ausSystemTypes" [ngValue]="type.value">
                {{ type.name }}
              </option>
            </select>
            <div class="invalid-feedback">Please select a type.</div>
          </div>
        </form>

        <div class="row mb-3">
          <div class="col-md-12 text-end">
            <button class="btn btn-primary btn-sm me-2" (click)="onAddNew(pop)">
              Save
            </button>
            <button class="btn btn-danger btn-sm" (click)="onCancel(pop)">Cancel</button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="grid-layout-row">
    <urla-dropdown class="col-sm-3 col-xs-6" name="freddieMacRefinanceProgram"
      label="Freddie Mac Refinance Program" [options]="freddieMacRefinanceProgram" [readonly]="isReadOnly"
      [(ngModel)]="mortgage.ausData.freddieMacRefinanceProgram" [inEditMode]="inEditMode"
      [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.ausData.freddieMacRefinanceProgram'"
      [required]="urlaFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram']?.required == 'required' || urlaFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram']?.required == 'requested'">
    </urla-dropdown>
    <div class="col-sm-3 col-xs-6 ms-dropdown-label-settings" style="background-color: white;" [ngClass]="{'bg-lightBcolor' : isReadOnly}">
      <label class="form-label mb-lg-0 text-start mb-2"> Freddie Mac Loan Program </label>
      <ng-multiselect-dropdown *ngIf="!inEditMode" name="freddieMacLoanPrograms" [placeholder]="'-- Select User(s) --'"
        [settings]="multiSelectSettings" [data]="freddieMacLoanPrograms" [(ngModel)]="selectedLoanPrograms"
        (ngModelChange)="onFreddieMacLoanProgramsChanged()" [disabled]="isReadOnly">
      </ng-multiselect-dropdown>
    </div>
    <urla-radio-group class="col-sm-3 col-xs-6" layout="horizontal" [label]="'Is Loan Manually Underwritten?'" [name]="'isLoanManuallyUnderwritten'"
        [(ngModel)]="mortgage.mortgageTerm.isLoanManuallyUnderwritten" [options]='yesNoOptions' [readonly]="isReadOnly" [inEditMode]="inEditMode"
        [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.mortgageTerm.isLoanManuallyUnderwritten'"
        [required]="urlaFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten']?.required == 'required' || urlaFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten']?.required == 'requested'" >
    </urla-radio-group>
    <urla-text-input [readonly]="isReadOnly" [label]="'Underwriting Comments'" class="col-sm-3 col-xs-6"
      [name]="'underwritingComments'" [(ngModel)]="mortgage.mortgageTerm.underwritingComments"
      [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
      [fieldBeingEdited]="'mortgage.mortgageTerm.underwritingComments'"
      [required]="urlaFieldsConfig['mortgage.mortgageTerm.underwritingComments']?.required == 'required' || urlaFieldsConfig['mortgage.mortgageTerm.underwritingComments']?.required == 'requested'">
    </urla-text-input>
  </div>
  <underwriting-item #ausResult
    *ngFor="let ausResult of mortgage.ausData.ausResults; let index = index"
    [index]="index"
    [ausResult]="ausResult"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [mortgage]="mortgage"
    [selectedType]="selectedType"
    [yesNoOptions]="yesNoOptions"
    [isReadOnly]="isReadOnly"
    [inEditMode]="inEditMode"
    (attemptedToDeleteAusResult)="onAttemptedToDeleteAusResult($event)">
  </underwriting-item>
</div>
