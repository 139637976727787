<div [ngClass]="{'row': showCoBo}" class="w-100 mx-0">
  <div class="card" id="borrowerDetails" [ngClass]="{'col-lg-6': showCoBo && showBorrowersBody}">
    <div class="card-header">
      Borrower Info
      <small>
        <a class="link ms-1" (click)="showBorrowersBody = !showBorrowersBody">
          {{showBorrowersBody ? 'Hide' : 'Show'}} Details
        </a>
      </small>
    </div>
    <div class="card-body" [hidden]="!showBorrowersBody">
      <!-- row1 -->
      <div class="row">
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="firstName">First Name</label>
          <input id="firstName" type="text" name="firstName" class="form-control" #firstName="ngModel"
            [ngClass]="{'is-invalid' : firstName && firstName.touched && firstName.invalid}" required
            [(ngModel)]="lead.firstName" #firstNameInput="ngModel" maxlength="40" />
          <div class="invalid-feedback">This field is required</div>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="middleName">Middle Name</label>
          <input id="middleName" name="middleName" type="text" class="form-control" [(ngModel)]="lead.middleName"
            maxlength="40" />
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="lastName">Last Name</label>
          <input id="lastName" name="lastName" type="text" class="form-control" #lastName="ngModel"
            [ngClass]="{'is-invalid' : lastName && lastName.touched && lastName.invalid}" required
            [(ngModel)]="lead.lastName" #lastNameInput="ngModel" maxlength="40" />
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="suffix">Suffix</label>
          <select id="suffix" name="suffix" class="form-select col-sm-4" [(ngModel)]="lead.suffix">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let suffix of suffixes" [ngValue]="suffix.value">
              {{ suffix.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="mobilePhone">Mobile Number &nbsp;&nbsp;
            <a [hidden]="!lead.mobilePhone" *ngIf="!dialerEnabled" [href]="'tel:' + lead.mobilePhone | safeUrl"><i
                class="fa fa-fw fa-phone text-muted"></i>
            </a>
            <a [hidden]="!lead.mobilePhone" *ngIf="dialerEnabled" (click)="dial(lead.mobilePhone, 'mobile', false)"><i
                class="fa fa-fw fa-phone text-muted"></i>
            </a>

            <a [hidden]="!lead.mobilePhone || !lead.leadId"  (click)="onOpenSmsChat(lead.mobilePhone)"><i
              class="fa fa-fw fa-sms text-muted"></i>
            </a>

          </label>
          <input class="form-control" id="mobilePhone" name="mobilePhone" type="text" [(ngModel)]="mobilePhone"
            (ngModelChange)="onPhoneChanged($event, 'mobilePhone')" mask="(000) 000-0000" [showMaskTyped]="true"
            #mobilePhoneNumber="ngModel"
            [ngClass]="{'is-invalid' : mobilePhoneNumber && mobilePhoneNumber.touched && mobilePhoneNumber.invalid}" />
          <div class="invalid-feedback">Invalid phone</div>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="phone">Home Number &nbsp;&nbsp;
            <a [hidden]="!lead.phone" *ngIf="!dialerEnabled" [href]="'tel:' + lead.phone | safeUrl">
              <i class="fa fa-fw fa-phone text-muted"></i>
            </a>
            <a [hidden]="!lead.phone" *ngIf="dialerEnabled" (click)="dial(lead.phone, 'home', false)">
              <i class="fa fa-fw fa-phone text-muted"></i>
            </a>
            <a [hidden]="!lead.phone || !lead.leadId"  (click)="onOpenSmsChat(lead.phone)"><i
              class="fa fa-fw fa-sms text-muted"></i>
            </a>
            <a [hidden]="!lead.mobilePhone" [ngClass]="{'ms-2': lead.phone}" (click)="onCopyFromMobileNumberClicked()">
              <i class="far fa-copy text-muted" title="Copy from Mobile Number"></i>
            </a>
          </label>
          <input class="form-control" id="phone" name="phone" type="text" [(ngModel)]="phone"
            (ngModelChange)="onPhoneChanged($event, 'phone')" mask="(000) 000-0000" [showMaskTyped]="true"
            #homePhoneNumber="ngModel"
            [ngClass]="{'is-invalid' : homePhoneNumber && homePhoneNumber.touched && homePhoneNumber.invalid}" />
          <div class="invalid-feedback">Invalid phone</div>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="workPhone">Work Number &nbsp;&nbsp;
            <a [hidden]="!lead.workPhone" *ngIf="!dialerEnabled" [href]="'tel:' + lead.workPhone | safeUrl"><i
                class="fa fa-fw fa-phone text-muted"></i>
            </a>
            <a [hidden]="!lead.workPhone" *ngIf="dialerEnabled" (click)="dial(lead.workPhone, 'work', false)"><i
                class="fa fa-fw fa-phone text-muted"></i>
            </a>
            <a [hidden]="!lead.workPhone || !lead.leadId"  (click)="onOpenSmsChat(lead.workPhone)"><i
              class="fa fa-fw fa-sms text-muted"></i>
            </a>
          </label>
          <input id="workPhone" class="form-control" name="workPhone" type="text" [(ngModel)]="workPhone"
            (ngModelChange)="onPhoneChanged($event, 'workPhone')" mask="(000) 000-0000" [showMaskTyped]="true"
            #workPhoneNumber="ngModel"
            [ngClass]="{'is-invalid' : workPhoneNumber && workPhoneNumber.touched && workPhoneNumber.invalid}" />
          <div class="invalid-feedback">Invalid phone</div>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="birthdate">Date of Birth</label>
          <date-input [name]="'birthDate'" [max]="maxDate" [min]="minDate" [id]="'birthDate'"
            [(ngModel)]="lead.birthDate">
          </date-input>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="email">Email Address</label>
          <input id="email" type="email" class="form-control" name="email" placeholder="example@domain.com" email trim
            [(ngModel)]="lead.email" #emailInput="ngModel" [ngClass]="{
              'is-invalid':
                emailInput &&
                emailInput.touched &&
                (isEqualsEmailValidate(lead.email, lead.coEmail) ||
                  !emailFormatValidate(lead.email))
            }" />
          <div for="email" class="invalid-feedback" *ngIf="isEqualsEmailValidate(lead.email, lead.coEmail)">
            Borrower and Co-Borrower must have different email
          </div>

          <div for="email" class="invalid-feedback" *ngIf="!emailFormatValidate(lead.email)">
            Please enter a valid email address
          </div>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="creditScore">Credit Score</label>
          <input id="creditScore" class="form-control numeric-without-arrows" name="creditScore" type="number" numeric
            [allowNegative]="false" [(ngModel)]="lead.creditScore" />
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="nickname">Nickname</label>
          <input id="nickname" class="form-control" name="nickname" type="text"
            [(ngModel)]="lead.nickname" />
        </div>
      </div>
      <!-- row 2 -->
      <div class="row">
        <div *ngIf="!isSsnHidden && !isSolar" class="form-group col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <label for="ssn">SSN</label>
          <input id="ssn" class="form-control" name="ssn" type="text" [(ngModel)]="lead.socialSecurityNumber"
            #ssnInput="ngModel" [ngClass]="{'is-invalid' : ssnInput && ssnInput.touched && ssnInput.invalid}"
            mask="000-00-0000" placeHolderCharacter="_" [showMaskTyped]="true" [conceal]='true' />
          <div class="invalid-feedback">Please enter a valid SSN</div>
        </div>

        <div class="form-group col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <label for="authorizedCreditCheck">Credit Check Authorized
          </label>
          <div class="d-flex align-items-center justify-content-start" style="height: 33.5px">
            <input id="authorizedCreditCheck" class="form-check-input"
              style="width: 18px; height: 18px;margin-top: -2px" name="authorizedCreditCheck" type="checkbox"
              [(ngModel)]="lead.authorizedCreditCheck" (ngModelChange)="onAuthorizationCheckChanged()" />
          </div>
        </div>

        <div class="form-group col-xs-12 col-sm-6 col-lg-3" *ngIf="lead.authorizedCreditCheck">
          <label for="authorizationMethod">Authorization Method</label>
          <select id="authorizationMethod" name="authorizationMethod" class="form-select col-sm-4" [(ngModel)]="lead.authorizationMethod"
          [ngClass]="{'is-invalid' : authorizationMethodInput && authorizationMethodInput.touched && authorizationMethodInput.invalid}"
          required #authorizationMethodInput="ngModel">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let authorizationMethod of authorizationMethods" [ngValue]="authorizationMethod.value">
              {{ authorizationMethod.name }}
            </option>
          </select>
          <div class="invalid-feedback"> This field is required </div>
        </div>

        <div class="form-group col-xs-12 col-sm-6 col-lg-3" *ngIf="lead.authorizedCreditCheck">
          <label for="dateAuthorizedCreditCheck">Date Auth. Credit Check</label>
          <date-input [name]="'dateAuthorizedCreditCheck'" [id]="'dateAuthorizedCreditCheck'"
            [(ngModel)]="lead.dateAuthorizedCreditCheck" [required]="true">
          </date-input>
        </div>

        <div *ngIf="lead.leadId && isPullCreditEnabled && lead.authorizedCreditCheck"
          class="form-group col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <button type="button" (click)="showCreditModal()" class="btn btn-primary" style="margin-top:20px">
            Run/View Credit
          </button>
        </div>
      </div>
      <!-- row 3 -->
      <div class="row" *ngIf="!isSolar">
        <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
          <label class="form-check form-check-inline form-check-solid me-5 mx-2">
            <input class="form-check-input" name="hasServedInMilitary" type="checkbox"
              [(ngModel)]="lead.hasServedInMilitary" />
            <span class="fs-6"><strong> Is Veteran / Surviving Spouse</strong></span>
          </label>
        </div>
      </div>
      <div *ngIf="lead.hasServedInMilitary">
        <div class="row px-2 mb-2">
          <label class="form-check form-check-inline form-check-solid me-5 mx-2">
            <input class="form-check-input" name="firstUseOfVaBenefit1" type="checkbox"
              [(ngModel)]="lead.isFirstUseOfVaBenefit" />
            <span class="fs-6"> First time use </span>
          </label>
        </div>
        <div class="row px-2 mb-2">
          <label class="form-check form-check-inline form-check-solid me-5 mx-2">
            <input class="form-check-input" name="vaFundingFeeExempt1" type="checkbox"
              [(ngModel)]="lead.isVaFundingFeeExempt" />
            <span class="fs-6"> Exempt from funding fee </span>
          </label>
        </div>
        <div class="row px-2 mb-2">
          <label class="form-check form-check-inline form-check-solid me-5 mx-2">
            <input class="form-check-input" name="survivingSpouse1" type="checkbox"
              [(ngModel)]="lead.isSurvivingSpouseOfMilitary" />
            <span class="fs-6">Surviving spouse</span>
          </label>
        </div>
      </div>
      <!-- row 3 -->
      <div class="row pt-2" *ngIf="!isSolar">
        <div class="col-md-6 py-2">
          <div class="border py-2" style="padding: 15px 10px 5px 10px;">
            <span class="group-label" style="background-color: #fff; padding: 3px; margin-top:-22px;position:absolute">
              Present Address
            </span>
            <div class="row" style="padding-top: 6px;">
              <div class="form-group col-xs-12 col-sm-8" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="presentStreet">Street Address
                </label>
                  <a class="dropdown-toggle ms-2" data-bs-toggle="dropdown"
                    aria-haspopup="false" aria-expanded="false" href="#" role="button">
                    Copy From...
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" (click)="copyAddressFromTo('mailing', 'present')">Mailing</a>
                    <a class="dropdown-item" (click)="copyAddressFromTo('subjectProperty', 'present')">Subject Property</a>
                  </div>
                <address-autocomplete-input
                  (addressChange)='handlePresentAddressChange($event)'
                  [(ngModel)]='lead.presentAddress1'
                  id='presentStreet'
                  name='presentStreet'
                ></address-autocomplete-input>
              </div>
              <div class="form-group col-xs-12 col-sm-4" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="presentPOBox">Unit/Suite</label>
                <input id="presentPOBox" name="presentPOBox" type="text" class="form-control"
                  [(ngModel)]="lead.presentAddress2" />
              </div>
              <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="presentCity">City</label>
                <input id="presentCity" name="presentCity" type="text" class="form-control"
                  [(ngModel)]="lead.presentCity" #presentCityInput="ngModel" />
                <div class="invalid-feedback">This field is required</div>
              </div>
              <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': showCoBo}">
                <div class="row">
                  <div class="col">
                    <label for="presentState">State</label>
                    <select id="presentState" name="presentState" class="form-select" placeholder="State"
                      [(ngModel)]="lead.presentState">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let state of states" [ngValue]="state.code">
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="presentZip">Zip Code</label>
                    <zip-code-input [(ngModel)]="lead.presentZip" [name]="'presentZip'" [required]="false"
                      [disabled]="false" [readonly]="false" [use9digits]="false"
                      (selectionChanged)="onZipCodeRelatedInfoChanged($event, 'present')"></zip-code-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- row 4 -->
        <div class="col-md-6 py-2">
          <div class="border py-2" style="padding: 15px 10px 5px 10px;">
            <span class="group-label" style="background-color: #fff; padding: 3px; margin-top:-22px;position:absolute">
              Mailing Address
            </span>
            <div class="row" style="padding-top: 6px;">
              <div class="form-group col-xs-12 col-sm-8" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="mailingStreet">Street Address
                  <a (click)="copyAddressFromTo('present', 'mailing')" class="ms-2">Copy from Present</a></label>
                <address-autocomplete-input
                  (addressChange)='handleMailingAddressChange($event)'
                  [(ngModel)]='lead.mailingAddress1'
                  id='mailingStreet'
                  name='mailingStreet'
                ></address-autocomplete-input>
              </div>
              <div class="form-group col-xs-12 col-sm-4" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="mailingPOBox">Unit/Suite</label>
                <input id="mailingPOBox" name="mailingPOBox" type="text" class="form-control"
                  [(ngModel)]="lead.mailingAddress2" />
              </div>
              <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="mailingCity">City</label>
                <input id="mailingCity" name="mailingCity" type="text" class="form-control"
                  [(ngModel)]="lead.mailingCity" #mailingCityInput="ngModel" />
              </div>
              <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': showCoBo}">
                <div class="row">
                  <div class="col">
                    <label for="mailingState">State</label>
                    <select id="mailingState" name="mailingState" class="form-select" placeholder="State"
                      [(ngModel)]="lead.mailingState">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let state of states" [ngValue]="state.code">
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="mailingZip">Zip Code</label>
                    <zip-code-input [(ngModel)]="lead.mailingZip" [name]="'mailingZip'" [required]="false"
                      [disabled]="false" [readonly]="false" [use9digits]="false"
                      (selectionChanged)="onZipCodeRelatedInfoChanged($event, 'mailing')"></zip-code-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="coBorrowerDetails" class="card" [ngClass]="{'col-lg-6': showCoBo && showBorrowersBody}" [hidden]="!showBorrowersBody">
    <div class="card-header">
      Co-Borrower Info
      <small>
        <a id="showCoborrower" (click)="showCoBo = !showCoBo" class="ms-1">{{ showCoBo ? 'Hide Co-Borrower' : 'Show Co-borrower' }}</a>
      </small>
    </div>
    <div class="card-body" [hidden]="!showCoBo">
      <div class="row">
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="cofirstName">First Name</label>
          <input id="cofirstName" name="cofirstName" type="text" class="form-control" [(ngModel)]="lead.coFirstName"
            #coFirstNameInput="ngModel" />
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="coMiddleName">Middle Name</label>
          <input id="coMiddleName" name="coMiddleName" type="text" class="form-control"
            [(ngModel)]="lead.coMiddleName" />
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="colastName">Last Name</label>
          <input id="colastName" name="colastName" type="text" class="form-control" [(ngModel)]="lead.coLastName"
            #colastNameInput="ngModel" />
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="coSuffix">Suffix</label>
          <select id="coSuffix" name="coSuffix" class="form-select col-sm-4" [(ngModel)]="lead.coSuffix">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let suffix of suffixes" [ngValue]="suffix.value">
              {{ suffix.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="coMobilePhone">Mobile Number &nbsp;&nbsp;
            <a [hidden]="!lead.coMobilePhone" *ngIf="!dialerEnabled" [href]="'tel:' + lead.coMobilePhone | safeUrl"><i
                class="fa fa-fw fa-phone text-muted"></i>
            </a>
            <a [hidden]="!lead.coMobilePhone" *ngIf="dialerEnabled"
              (click)="dial(lead.coMobilePhone, 'mobile', true)"><i class="fa fa-fw fa-phone text-muted"></i>
            </a>
            <a [hidden]="!lead.coMobilePhone || !lead.leadId"  (click)="onOpenSmsChat(lead.coMobilePhone, true)"><i
              class="fa fa-fw fa-sms text-muted"></i>
            </a>
          </label>
          <input class="form-control" id="coMobilePhone" name="coMobilePhone" type="text" [(ngModel)]="coMobilePhone"
            (ngModelChange)="onPhoneChanged($event, 'coMobilePhone')" mask="(000) 000-0000" [showMaskTyped]="true" />
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="coPhone">Home Number &nbsp;&nbsp;
            <a [hidden]="!lead.coPhone" *ngIf="!dialerEnabled" [href]="'tel:' + lead.coPhone | safeUrl"><i
                class="fa fa-fw fa-phone text-muted"></i>
            </a>
            <a [hidden]="!lead.coPhone" *ngIf="dialerEnabled" (click)="dial(lead.coPhone, 'home', true)"><i
                class="fa fa-fw fa-phone text-muted"></i>
            </a>
            <a [hidden]="!lead.coPhone || !lead.leadId"  (click)="onOpenSmsChat(lead.coPhone, true)"><i
              class="fa fa-fw fa-sms text-muted"></i>
            </a>
            <a [hidden]="!lead.coMobilePhone" [ngClass]="{'ms-2': lead.coMobilePhone}" (click)="onCopyFromCoMobileNumberClicked()">
              <i class="far fa-copy text-muted" title="Copy from Mobile Number"></i>
            </a>
          </label>
          <input class="form-control" id="coPhone" name="coPhone" type="text" [(ngModel)]="coPhone"
            (ngModelChange)="onPhoneChanged($event, 'coPhone')" mask="(000) 000-0000" [showMaskTyped]="true" />
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="coWorkPhone">Work Number &nbsp;&nbsp;
            <a [hidden]="!lead.coWorkPhone" *ngIf="!dialerEnabled" [href]="'tel:' + lead.coWorkPhone | safeUrl"><i
                class="fa fa-fw fa-phone text-muted"></i>
            </a>
            <a [hidden]="!lead.coWorkPhone" *ngIf="dialerEnabled" (click)="dial(lead.coWorkPhone, 'work', true)"><i
                class="fa fa-fw fa-phone text-muted"></i>
            </a>
            <a [hidden]="!lead.coWorkPhone || !lead.leadId"  (click)="onOpenSmsChat(lead.coWorkPhone, true)"><i
              class="fa fa-fw fa-sms text-muted"></i>
            </a>
          </label>
          <input id="coWorkPhone" name="coWorkPhone" class="form-control" type="text" [(ngModel)]="coWorkPhone"
            (ngModelChange)="onPhoneChanged($event, 'coWorkPhone')" mask="(000) 000-0000" [showMaskTyped]="true" />
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="coBirthdate">Date of Birth</label>
          <date-input [name]="'coBirthDate'" [id]="'coBirthDate'" [(ngModel)]="lead.coBirthDate" [max]="maxDate"
            [min]="minDate">
          </date-input>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="coemail">Email Address</label>
          <input id="coemail" type="email" name="coemail" class="form-control" placeholder="example@domain.com" email
            trim [(ngModel)]="lead.coEmail" #coemailInput="ngModel" [ngClass]="{
            'is-invalid':
              coemailInput &&
              coemailInput.touched &&
              (isEqualsEmailValidate(lead.email, lead.coEmail) ||
                !emailFormatValidate(lead.coEmail))
          }" />
          <div for="coemail" class="invalid-feedback" *ngIf="isEqualsEmailValidate(lead.email, lead.coEmail)">
            Borrower and Co-Borrower must have different email
          </div>

          <div for="coemail" class="invalid-feedback" *ngIf="!emailFormatValidate(lead.coEmail)">
            Please enter a valid email address
          </div>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="coCreditScore">Credit Score</label>
          <input id="coCreditScore" class="form-control numeric-without-arrows" name="coCreditScore" numeric
            [allowNegative]="false" [(ngModel)]="lead.coCreditScore" />
        </div>
      </div>
      <div class="row">
        <div *ngIf="!isSsnHidden && !isSolar" class="form-group col-xs-12 col-sm-6 col-lg-3">
          <label for="cossn">SSN</label>
          <input id="cossn" class="form-control" name="cossn" type="text" [(ngModel)]="lead.coSocialSecurityNumber"
            #coSsnInput="ngModel" [ngClass]="{'is-invalid' : coSsnInput && coSsnInput.touched && coSsnInput.invalid}"
            mask="000-00-0000" placeHolderCharacter="#" [showMaskTyped]="true" [conceal]='true' />
          <div class="invalid-feedback">Please enter a valid SSN</div>
        </div>


        <div class="form-group col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <label for="coAuthorizedCreditCheck">Credit Check Authorized
          </label>
          <div class="d-flex align-items-center justify-content-start" style="height: 33.5px">
            <input id="coAuthorizedCreditCheck" class="form-check-input"
            style="width: 18px; height: 18px;margin-top: -2px" name="coAuthorizedCreditCheck" type="checkbox"
            [(ngModel)]="lead.coAuthorizedCreditCheck" (ngModelChange)="onCoAuthorizationCheckChanged()" />
          </div>
        </div>

        <div class="form-group col-xs-12 col-sm-6 col-lg-3" *ngIf="lead.coAuthorizedCreditCheck">
          <label for="coAuthorizationMethod">Authorization Method</label>
          <select id="coAuthorizationMethod" name="coAuthorizationMethod" class="form-select col-sm-4" [(ngModel)]="lead.coAuthorizationMethod"
          [ngClass]="{'is-invalid' : coAuthorizationMethodInput && coAuthorizationMethodInput.touched && coAuthorizationMethodInput.invalid}"
          required #coAuthorizationMethodInput="ngModel"
          >
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let authorizationMethod of authorizationMethods" [ngValue]="authorizationMethod.value">
              {{ authorizationMethod.name }}
            </option>
          </select>
          <div class="invalid-feedback"> This field is required </div>
        </div>

        <div class="form-group col-xs-12 col-sm-6 col-lg-3" *ngIf="lead.coAuthorizedCreditCheck">
          <label for="dateCoAuthorizedCreditCheck">Date Auth. Credit Check</label>
          <date-input [name]="'coDateAuthorizedCreditCheck'" [id]="'coDateAuthorizedCreditCheck'"
            [(ngModel)]="lead.coDateAuthorizedCreditCheck" [disabled]="lead.coAuthorizedCreditCheck === 'Internet'" [required]="true">
          </date-input>
        </div>

        <div *ngIf="lead.leadId && isPullCreditEnabled && lead.coAuthorizedCreditCheck"
          class="form-group col-xs-12 col-sm-6 col-md-3 col-lg-3">
          <button type="button" (click)="showCreditModal()" class="btn btn-primary" style="margin-top:20px">
            Run/View Credit
          </button>
        </div>
      </div>
      <div class="row" *ngIf="!isSolar">
        <div class="form-group col-xs-12 col-sm-6 col-md-4 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
          <label class="form-check form-check-inline form-check-solid me-5 mx-2">
            <input class="form-check-input" name="coHasServedInMilitary" type="checkbox"
              [(ngModel)]="lead.coHasServedInMilitary" />
            <span class="fs-6"><strong> Is Veteran / Surviving Spouse</strong></span>
          </label>
        </div>
      </div>
      <div *ngIf="lead.coHasServedInMilitary">
        <div class="row px-2 mb-2">
          <label class="form-check form-check-inline form-check-solid me-5 mx-2">
            <input class="form-check-input" name="coIsFirstUseOfVaBenefit" type="checkbox"
              [(ngModel)]="lead.coIsFirstUseOfVaBenefit" />
            <span class="fs-6"> First time use </span>
          </label>
        </div>
        <div class="row px-2 mb-2">
          <label class="form-check form-check-inline form-check-solid me-5 mx-2">
            <input class="form-check-input" name="coIsVaFundingFeeExempt" type="checkbox"
              [(ngModel)]="lead.coIsVaFundingFeeExempt" />
            <span class="fs-6"> Exempt from funding fee </span>
          </label>
        </div>
        <div class="row px-2 mb-2">
          <label class="form-check form-check-inline form-check-solid me-5 mx-2">
            <input class="form-check-input" name="coIsSurvivingSpouseOfMilitary" type="checkbox"
              [(ngModel)]="lead.coIsSurvivingSpouseOfMilitary" />
            <span class="fs-6">Surviving spouse</span>
          </label>
        </div>
      </div>
      <div class="row pt-2" *ngIf="!isSolar">
        <div class="col-md-6 py-2">
          <div class="border py-2" style="padding: 15px 10px 5px 10px;">
            <span class="group-label" style="background-color: #fff; padding: 3px; margin-top:-22px;position:absolute">
              Present Address
            </span>
            <div class="row" style="padding-top: 6px;">
              <div class="form-group col-xs-12 col-sm-8" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="copresentStreet">Street Address</label>
                <a class="dropdown-toggle ms-2" data-bs-toggle="dropdown" aria-haspopup="false" aria-expanded="false"
                  href="#" role="button">
                  Copy From...
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <a class="dropdown-item" (click)="copyAddressFromTo('coMailing', 'coPresent')">Mailing</a>
                  <a class="dropdown-item" (click)="copyAddressFromTo('present', 'coPresent')">Borrower</a>
                </div>
                <address-autocomplete-input
                  (addressChange)='handleCoPresentAddressChange($event)'
                  [(ngModel)]='lead.coPresentAddress1'
                  id='copresentStreet'
                  name='copresentStreet'
                ></address-autocomplete-input>
              </div>
              <div class="form-group col-xs-12 col-sm-4" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="copresentPOBox">Unit/Suite</label>
                <input id="copresentPOBox" name="copresentPOBox" type="text" class="form-control"
                  [(ngModel)]="lead.coPresentAddress2" />
              </div>
              <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="copresentCity">City</label>
                <input id="copresentCity" name="copresentCity" type="text" class="form-control"
                  [(ngModel)]="lead.coPresentCity" #copresentCityInput="ngModel" />
              </div>
              <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': showCoBo}">
                <div class="row">
                  <div class="col">
                    <label for="copresentState">State</label>
                    <select id="copresentState" name="copresentState" class="form-select" placeholder="State"
                      [(ngModel)]="lead.coPresentState">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let state of states" [ngValue]="state.code">
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="copresentZip">Zip Code</label>
                    <zip-code-input [(ngModel)]="lead.coPresentZip" [name]="'copresentZip'" [required]="false"
                      [disabled]="false" [readonly]="false" [use9digits]="false"
                      (selectionChanged)="onZipCodeRelatedInfoChanged($event, 'coPresent')"></zip-code-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 py-2">
          <div class="border py-2" style="padding: 15px 10px 5px 10px;">
            <span class="group-label" style="background-color: #fff; padding: 3px; margin-top:-22px;position:absolute">
              Mailing Address
            </span>
            <div class="row" style="padding-top: 6px;">
              <div class="form-group col-xs-12 col-sm-8" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="comailingStreet">Street Address</label>
                <a class="dropdown-toggle ms-2" data-bs-toggle="dropdown" aria-haspopup="false" aria-expanded="false"
                  href="#" role="button">
                  Copy From...
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                  <a class="dropdown-item" (click)="copyAddressFromTo('coPresent', 'coMailing')">Present</a>
                  <a class="dropdown-item" (click)="copyAddressFromTo('mailing', 'coMailing')">Borrower</a>
                </div>
                <address-autocomplete-input
                  (addressChange)='handleCoMailingAddressChange($event)'
                  [(ngModel)]='lead.coMailingAddress1'
                  id='comailingStreet'
                  name='comailingStreet'
                ></address-autocomplete-input>
              </div>
              <div class="form-group col-xs-12 col-sm-4" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="comailingPOBox">Unit/Suite</label>
                <input id="comailingPOBox" name="comailingPOBox" type="text" class="form-control"
                  [(ngModel)]="lead.coMailingAddress2" />
              </div>
              <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': showCoBo}">
                <label for="comailingCity">City</label>
                <input id="comailingCity" type="text" name="comailingCity" class="form-control"
                  [(ngModel)]="lead.coMailingCity" #comailingCityInput="ngModel" />
              </div>
              <div class="form-group col-xs-12 col-sm-6" [ngClass]="{'col-lg-12': showCoBo}">
                <div class="row">
                  <div class="col">
                    <label for="comailingState">State</label>
                    <select id="comailingState" name="comailingState" class="form-select" placeholder="State"
                      [(ngModel)]="lead.coMailingState">
                      <option [ngValue]="null"></option>
                      <option *ngFor="let state of states" [ngValue]="state.code">
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="comailingZip">Zip Code</label>
                    <zip-code-input [(ngModel)]="lead.coMailingZip" [name]="'comailingZip'" [required]="false"
                      [disabled]="false" [readonly]="false" [use9digits]="false"
                      (selectionChanged)="onZipCodeRelatedInfoChanged($event, 'coMailing')"></zip-code-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
