import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NearestLivingRelativeFormValue } from '../nearest-living-relative-editor/nearest-living-relative-editor.component';
import { DrawerOptions, DrawerService, DrawerSize } from '../../../../shared/services/drawer.service';

const defaultDrawerName: string = 'nearestLivingRelativeEditorDrawer';

@Component({
  selector: 'nearest-living-relative-editor-drawer',
  templateUrl: './nearest-living-relative-editor-drawer.component.html',
  styleUrls: ['./nearest-living-relative-editor-drawer.component.scss']
})
export class NearestLivingRelativeEditorDrawerComponent implements OnChanges {
  @Input() drawerName?: string;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<NearestLivingRelativeFormValue> =
    new EventEmitter<NearestLivingRelativeFormValue>();

  protected effectiveDrawerName: string = defaultDrawerName;
  protected readonly drawerOptions: DrawerOptions = Object.freeze({
    size: DrawerSize.Large,
    containerWrapperId: null,
  });

  protected title: string = 'Nearest Living Relative';
  protected value: NearestLivingRelativeFormValue | undefined;

  constructor(
    private readonly _drawerService: DrawerService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const drawerNameChange = changes.drawerName;
    if (drawerNameChange != null) {
      const currentValue = drawerNameChange.currentValue;
      this.effectiveDrawerName = [defaultDrawerName, currentValue].filter(Boolean)
        .join('');
    }
  }

  public async show(value?: NearestLivingRelativeFormValue): Promise<void> {
    this.value = value;
    await this._drawerService.show(this.effectiveDrawerName);
  }

  public async hide(): Promise<void> {
    await this._drawerService.hide(this.effectiveDrawerName);
  }

  protected async onCancel(): Promise<void> {
    this.cancel.next();
    await this.hide();
  }

  protected async onSave(value: NearestLivingRelativeFormValue): Promise<void> {
    this.save.next(value);
    await this.hide();
  }
}
