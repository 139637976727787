import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { PurchaseCredit } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UrlaMortgage } from '../../../models/urla-mortgage.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';

@Component({
    selector: 'source-of-funds-dialog',
    templateUrl: 'source-of-funds-dialog.component.html',
    styleUrls: ['./source-of-funds-dialog.component.scss']
})

export class SourceOfFundsDialogComponent implements OnInit {

    @ViewChild('sourceOfFundsForm') sourceOfFundsForm: NgForm | undefined;

    @Input()
    purchaseCredits: Array<PurchaseCredit>;

    @Input()
    purchaseCreditTypes: EnumerationItem[];

    @Input()
    downPaymentSourceTypes: EnumerationItem[];

    data: UrlaMortgage;

    activeDeletionIndex: number = -1;

    totalDownPayment: number = 0;


    constructor(private readonly _enumsService: EnumerationService,
        public activeModal: NgbActiveModal) {
        
    }

    ngOnInit() {
        if (this.purchaseCredits) {
            this.purchaseCredits = this.purchaseCredits.map(item => ({ ...item }));
        } else {
            this.purchaseCredits = [];
            this.addAccount();
        }
        this.purchaseCreditTypes = _.orderBy(this.purchaseCreditTypes.filter(credit =>
            credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.EmployerAssistedHousing) ||
            credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LotEquity) ||
            credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.RelocationFunds) ||
            credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LeasePurchaseFund) ||
            credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.TradeEquity) ||
            credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.Other) ||
            credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.SweatEquity) ||
            credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.DepositOnSalesContract) ||
            credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.MIPremiumRefund) ||
            credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.SellerCredit)
          ), x => x.name);
    }

    addAccount = () => {
        this.purchaseCredits.push(new PurchaseCredit());
    }

    remove = (index: number) => {
        this.purchaseCredits.splice(index, 1);
    }

    validate() {
        let valid = true;
        this.purchaseCredits.forEach((purchaseCredit) => {
            if (!(purchaseCredit.sourceType && purchaseCredit.purchaseCreditAmount >= 0)) {
                valid = false;
            }
        });
        return valid;
    }

    onOkClicked = () => {
        if (this.sourceOfFundsForm) {
            this.sourceOfFundsForm.form.markAllAsTouched();
            if (this.sourceOfFundsForm.form.valid && this.validate()) {
                this.activeModal.close(this.purchaseCredits);
            }
        }
    }

    onDeletePurchaseCreditCancelClicked = () => {
        this.activeDeletionIndex = -1;
    }

    onDeletePurchaseCreditClicked = (index: number) => {
        this.activeDeletionIndex = index;
    }

    onDeletePurchaseCreditConfirmClicked = (purchaseCredit: PurchaseCredit) => {
        const index = this.purchaseCredits.indexOf(purchaseCredit);
        this.activeDeletionIndex = -1;
        if (index >= 0) {
            this.purchaseCredits.splice(index, 1);
        }
        this.onBalanceChanged();
    }

    onBalanceChanged = () => {
        this.totalDownPayment = this.purchaseCredits.reduce((total, credit) => total + (Number(credit.purchaseCreditAmount) || 0), 0)
    }

    onPurchaseCreditTypeChanged = (purchaseCredit) => {
        if (purchaseCredit.purchaseCreditType != 'Other') {
            purchaseCredit.purchaseCreditTypeOtherDescription = null;
            purchaseCredit.sourceTypeOtherDescription = null;
        }
    }
}
