import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessage } from '../../../../../../shared/models/error.model';
import { NotificationService } from '../../../../../../services/notification.service';

@Component({
  selector: 'employment-aliases-dialog',
  templateUrl: './employment-aliases-dialog.component.html',
  styleUrls: ['./employment-aliases-dialog.component.scss'],
})
export class EmploymentAliasesDialogComponent implements OnChanges {
  @Input() set aliases(value: string[]) {
    this.setFormAliases(value);
  }

  @ViewChild('formElement') formElement: NgForm;

  protected formAliases: { value: string }[] = [];
  protected errorMessage: ErrorMessage | undefined;

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _notificationService: NotificationService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const aliasesChange = changes.aliases;
    if (aliasesChange) {
      const aliases = aliasesChange.currentValue as string[] | undefined;
      this.setFormAliases(aliases);
    }
  }

  private setFormAliases(aliases: string[]): void {
    aliases = aliases || [];
    this.formAliases = aliases.map(alias => ({ value: alias }));
  }

  protected onClickCancel(): void {
    this._activeModal.dismiss('cancel');
  }

  protected onClickSave(): void {
    this.formElement.form.markAllAsTouched();

    if (this.formElement.valid) {
      const aliases = this.formAliases.map(alias => alias.value);
      this._activeModal.close(aliases);
    } else {
      this._notificationService.showError(
        'Please correct the errors on the form.',
        'Error');
    }
  }

  protected onClickAddAlias(): void {
    this.formAliases.push({ value: '' });
  }

  protected onClickRemoveAlias(index: number): void {
    this.formAliases.splice(index, 1);
  }
}
