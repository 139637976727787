<div>
    <div class="card">
        <div class="card-header">{{ mode === 'edit' ? 'Edit' : 'Add' }} Real Estate Owned</div>
        <div class="card-body">
            <ng-container *ngTemplateOutlet="formContent"></ng-container>
        </div>
        <div class="card-footer d-flex justify-content-end align-items-stretch gap-2">
          <qa-cancel-button (qaClick)='onClickCancel()'></qa-cancel-button>

          <qa-ok-button (qaClick)='onClickClose()'></qa-ok-button>
        </div>
    </div>
</div>

<ng-template #formContent>
    <form #form="ngForm" novalidate id="reoForm" name="reoForm" class="w-100">
        <div class="row">
            <div class="col-12 col-sm-6">
                <h5 class="fw-bold">
                    REO Details
                </h5>
                <div class="row mb-1">
                    <label for="reo_BorrowerIds_editor_{{ reo.reoId }}">Borrowers</label>
                    <ng-select class="custom-ng-select" name="reo_BorrowerIds_editor_{{ reo.reoId }}"
                               bindLabel="text" bindValue="id" [multiple]="true"
                               [(ngModel)]="reo.owningBorrowerIds" [closeOnSelect]="false"
                               (ngModelChange)="onAccountOwnersChanged(reo)" [items]="possibleAccountOwners"
                               #reoBorrowerIds="ngModel"
                               [ngClass]="{
                            'invalid-requested' : reoBorrowerIds && reoBorrowerIds.invalid && reoBorrowerIds.errors && reoBorrowerIds.errors.required
                              && quickApplyFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] == 'requested',
                            'invalid-required' : reoBorrowerIds && reoBorrowerIds.invalid && reoBorrowerIds.errors && reoBorrowerIds.errors.required
                              && quickApplyFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] == 'required'
                          }"
                               [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] == 'required' ||
                        quickApplyFieldsConfig['mortgage.realEstateOwned.borrowerPicker'] == 'requested'">
                    </ng-select>
                </div>
                <div class="row mb-1">
                    <div class="form-check form-switch ms-2 my-1">
                        <input class="form-check-input"
                                type="checkbox"
                                [(ngModel)]="reo.isSubjectProperty"
                                id="isSameAsSubjectProperty_{{reo.reoId}}"
                                name="isSameAsSubjectProperty_{{reo.reoId}}"
                                (change)="onIsSubjectPropertyChanged()">
                        <label> Is Subject Property </label>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="form-group col-xs-6 col-sm-8 pe-0">
                        <label for="reo_address1-editor-{{ reo.reoId }}">Street Address</label>
                      <address-autocomplete-input
                        #address='ngModel'
                        (addressChange)='handleAddressChange($event)'
                        [(ngModel)]='reo.address1'
                        [ngClass]="{
                                'invalid-requested' : address && address.invalid && address.errors && address.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.address1'] == 'requested',
                                'invalid-required' : address && address.invalid && address.errors && address.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.address1'] == 'required'
                              }"
                        [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.address1'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.address1'] == 'requested'"
                        class='custom-input-left-side'
                        id='reo_address1-editor-{{ reo.reoId }}'
                        name='reo_address1-editor-{{ reo.reoId }}'
                        placeholder=''
                      ></address-autocomplete-input>
                    </div>
                    <div class="form-group col-xs-6 col-sm-4 ps-0">
                        <label for="reo_address2-editor-{{ reo.reoId }}">Unit/Suite</label>
                        <input id="reo_address2-editor-{{ reo.reoId }}"
                               name="reo_address2-editor-{{ reo.reoId }}" type="text"
                               class="form-control custom-input-right-side" [(ngModel)]="reo.address2" />
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="form-group col-xs-6 col-sm-5 pe-0">
                        <label for="propertyCity">City</label>
                        <input id="propertyCity" name="propertyCity" type="text"
                            class="form-control custom-input-left-side" [(ngModel)]="reo.city"
                            [ngClass]="{
                                'invalid-requested' : reoCity && reoCity.invalid && reoCity.errors && reoCity.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.city'] == 'requested',
                                'invalid-required' : reoCity && reoCity.invalid && reoCity.errors && reoCity.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.city'] == 'required'
                              }"
                            #reoCity="ngModel"
                            [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.city'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.city'] == 'requested'" />
                    </div>
                    <div class="form-group col-xs-6 col-sm-3 px-0">
                        <label for="propertyState">State</label>
                        <select id="propertyState" name="propertyState"
                                class="form-select custom-input-left-side custom-input-right-side" placeholder="State"
                                [ngClass]="{
                                'invalid-requested' : reoState && reoState.invalid && reoState.errors && reoState.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.state'] == 'requested',
                                'invalid-required' : reoState && reoState.invalid && reoState.errors && reoState.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.state'] == 'required'
                              }"
                                #reoState="ngModel"
                                [(ngModel)]="reo.state" [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.state'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.state'] == 'requested'">
                            <option *ngFor="let state of states" [ngValue]="state.value">
                                {{ state.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-xs-6 col-sm-4 px-0">
                        <label for="propertyZip">Zip Code</label>
                        <zip-code-input [(ngModel)]="reo.zipCode" [name]="'propertyZip'"
                                        class="custom-input-right-side"
                                        (blur)="onZipCodeRelatedInfoChanged($event)"
                                        [required]="false"
                                        (selectionChanged)="onZipCodeRelatedInfoChanged($event)"
                                        #reoZipCode="ngModel"
                                        [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.zipCode'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.zipCode'] == 'requested'"
                                        [ngClass]="{
                            'invalid-requested' : reoZipCode && reoZipCode.invalid && reoZipCode.errors && reoZipCode.errors.required
                              && quickApplyFieldsConfig['mortgage.realEstateOwned.zipCode'] == 'requested',
                            'invalid-required' : reoZipCode && reoZipCode.invalid && reoZipCode.errors && reoZipCode.errors.required
                              && quickApplyFieldsConfig['mortgage.realEstateOwned.zipCode'] == 'required' }">
                        </zip-code-input>
                    </div>
                    <!-- <div class="form-group col-xs-6 col-sm-3 ps-0">
                        <label for="propertyCounty">County</label>
                        <input id="propertyCounty" name="propertyCounty" type="text"
                            class="form-control custom-input-right-side"
                            [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.county'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.county'] == 'requested'"
                            [ngClass]="{
                            'invalid-requested' : reoCounty && reoCounty.invalid && reoCounty.errors && reoCounty.errors.required
                            && quickApplyFieldsConfig['mortgage.realEstateOwned.county'] == 'requested',
                            'invalid-required' : reoCounty && reoCounty.invalid && reoCounty.errors && reoCounty.errors.required
                            && quickApplyFieldsConfig['mortgage.realEstateOwned.county'] == 'required'}"
                            #reoCounty="ngModel"
                            [(ngModel)]="reo.county" />
                    </div> -->
                </div>
                <div class="row mb-1">
                    <div class="form-group col-sm-12 ">
                        <label for="typeOfProperty">Property Type</label>
                        <select id="typeOfProperty" name="typeOfProperty" class="form-select"
                        [ngClass]="{
                            'invalid-requested' : propertyType && propertyType.invalid && propertyType.errors && propertyType.errors.required
                              && quickApplyFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] == 'requested',
                            'invalid-required' : propertyType && propertyType.invalid && propertyType.errors && propertyType.errors.required
                              && quickApplyFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] == 'required'
                          }"
                            [ngClass]="{'is-invalid': propertyType && propertyType.touched && propertyType.invalid}" #propertyType="ngModel"
                            [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.typeOfProperty'] == 'requested'"
                            [(ngModel)]="reo.typeOfProperty">
                            <option *ngFor="let reoPropertyType of reoPropertyTypes" [ngValue]="reoPropertyType.value">
                                {{ reoPropertyType.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="form-group col-xs-12 col-sm-6 pe-0">
                        <label for="dispositionStatus">Property Status</label>
                        <select id="dispositionStatus" name="dispositionStatus"
                                class="form-select custom-input-left-side"
                                [(ngModel)]="reo.dispositionStatus" (change)="onDispositionStatusChanged()"
                                [ngClass]="{
                                'invalid-requested' : propertyStatus && propertyStatus.invalid && propertyStatus.errors && propertyStatus.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] == 'requested',
                                'invalid-required' : propertyStatus && propertyStatus.invalid && propertyStatus.errors && propertyStatus.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] == 'required'
                              }"
                                #propertyStatus="ngModel"
                                [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.dispositionStatus'] == 'requested'">
                            <option *ngFor="let propertyStatusOption of propertyStatusOptions" [ngValue]="propertyStatusOption.value">
                                {{ propertyStatusOption.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-xs-12 col-sm-6 ps-0">
                        <label for="intendedUsageType">Proposed Usage</label>
                        <select id="intendedUsageType" name="intendedUsageType"
                                class="form-select custom-input-right-side"
                                [(ngModel)]="reo.intendedUsageType" (change)="onProposedOccupancyChanged()"
                                [ngClass]="{
                                'invalid-requested' : proposedUsage && proposedUsage.invalid && proposedUsage.errors && proposedUsage.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] == 'requested',
                                'invalid-required' : proposedUsage && proposedUsage.invalid && proposedUsage.errors && proposedUsage.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] == 'required'
                              }"
                                #proposedUsage="ngModel"
                                [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.intendedUsageType'] == 'requested'">
                            <option *ngFor="let intendedPropertyWillBeType of intendedPropertyWillBeTypes" [ngValue]="intendedPropertyWillBeType.value">
                                {{ intendedPropertyWillBeType.name }}
                            </option>
                        </select>
                    </div>

                </div>
                <div class="row mb-1">
                    <div class="form-group col-sm-6 pe-0">
                        <label class="form-label" for="marketValue">Current Market Value</label>
                        <currency-input [name]="'marketValue'" [allowDecimals]="false"
                                        [(ngModel)]="reo.marketValue" class="custom-input-left-side"
                                        [ngClass]="{
                                'invalid-requested' : currentMarketValue && currentMarketValue.invalid && currentMarketValue.errors && currentMarketValue.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.marketValue'] == 'requested',
                                'invalid-required' : currentMarketValue && currentMarketValue.invalid && currentMarketValue.errors && currentMarketValue.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.marketValue'] == 'required'
                              }"
                                        #currentMarketValue="ngModel"
                                        [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.marketValue'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.marketValue'] == 'requested'">
                        </currency-input>
                    </div>
                    <div class="form-group col-sm-6 ps-0">
                        <label class="form-label" for="unitCount">Owned Unit Count</label>
                        <number-input [allowNegative]="false" [(ngModel)]="reo.unitCount"
                          name="unitCount" class="custom-input-right-side">
                        </number-input>
                    </div>
                </div>
               <!--  <div class="row mb-1">
                    <div class="form-group col-sm-12">
                        <label class="form-label" for="accessoryDwellingUnitCount">Accessory Dwelling Unit Count</label> No Model
                        <number-input [allowNegative]="false"
                          name="accessoryDwellingUnitCount" >
                        </number-input>
                    </div>
                </div>-->
                <div class="row mb-1">
                    <div class="form-group col-sm-12 ">
                        <label for="currentUsageType">Current Usage</label>
                        <select id="currentUsageType" name="currentUsageType"
                            class="form-select" [(ngModel)]="reo.currentUsageType"
                            [ngClass]="{
                                'invalid-requested' : currentUsage && currentUsage.invalid && currentUsage.errors && currentUsage.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.currentUsageType'] == 'requested',
                                'invalid-required' : currentUsage && currentUsage.invalid && currentUsage.errors && currentUsage.errors.required
                                  && quickApplyFieldsConfig['mortgage.realEstateOwned.currentUsageType'] == 'required'
                              }"
                            #currentUsage="ngModel"
                            [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.currentUsageType'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.currentUsageType'] == 'requested'">
                            <option *ngFor="let currentPropertyWillBeType of currentPropertyWillBeTypes" [ngValue]="currentPropertyWillBeType.value">
                                {{ currentPropertyWillBeType.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <h5 class="fw-bold">
                    Net Monthly Earnings / Expenses (+/-)
                    <span></span>
                </h5>
                <div class="row mb-2">
                    <div class="card">
                        <div class="card-header p-1">
                            <div class="card-title" style="font-size: 13px;">Link liabilities</div>
                        </div>
                        <div class="card-body p-1">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td>BORROWER</td>
                                        <td>PAYMENT</td>
                                        <td>LIEN</td>
                                        <td>CREDITOR</td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="reoLiabilities && reoLiabilities.length > 0">
                                    <tr *ngFor="let liability of reoLiabilities">
                                        <td>
                                            <div class="d-flex">
                                                <input type="checkbox" name="check_{{liability.liabilityId}}"
                                                       [checked]="liability.reoId == reo.reoId" (change)="onChangeLiabilityLink(liability, $event)"
                                                       class="form-check-input me-1 mt-0 align-self-center"/>
                                                <select-input [editorMode]="'Inline'" [isMultiple]="true" [optionValueKey]="'id'"
                                                    [optionNameKey]="'text'" [(ngModel)]="liability.owningBorrowerIds"
                                                    [options]="possibleAccountOwners" name="liability_BorrowerIds_{{ liability.liabilityId }}"
                                                    [disabled]="true">
                                                </select-input>
                                            </div>
                                        </td>
                                        <td>
                                            {{liability.monthlyPayment | currency}}
                                        </td>
                                        <td>
                                            {{liability.lienPosition}}
                                        </td>
                                        <td>
                                            {{liability.holderName}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div>
                        <span class="fw-bold o-1">Monthly Expenses</span>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-1">
                        <label for="mortgagePayment" class="o-1">First Mortgage</label>
                        <currency-input
                          [name]="'mortgagePayment'"
                          [readonly]="true"
                          [(ngModel)]="reo.mortgagePayment">
                        </currency-input>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-1">
                        <label for="subordinateLien" class="o-1">Subordinate Lien(s)</label>
                        <currency-input
                          [name]="'subordinateLien'"
                          [(ngModel)]="reo.otherFinance"
                          (change)="onReoExpenseItemChanged()">
                        </currency-input>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-1">
                        <label for="homeownersInsurance" class="o-1">Homeowner's Insurance</label>
                        <currency-input
                          [name]="'homeownersInsurance'"
                          [(ngModel)]="reo.hazardInsurance"
                          (change)="onReoExpenseItemChanged()">
                        </currency-input>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-1">
                        <label for="propertyTaxes" class="o-1">Property Taxes</label>
                        <currency-input
                          [name]="'propertyTaxes'"
                          [(ngModel)]="reo.propertyTax"
                          (change)="onReoExpenseItemChanged()">
                        </currency-input>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-1">
                        <label for="mortgageInsurance" class="o-1">Mortgage Insurance</label>
                        <currency-input
                          [name]="'mortgageInsurance'"
                          [(ngModel)]="reo.mortgageInsurance"
                          (change)="onReoExpenseItemChanged()">
                        </currency-input>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-1">
                        <label for="associationOrProjectDues" class="o-1">Association / Project Dues</label>
                        <currency-input
                          [name]="'associationOrProjectDues'"
                          [(ngModel)]="reo.hoa"
                          (change)="onReoExpenseItemChanged()">
                        </currency-input>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-1">
                        <label for="other" class="o-1">Other</label>
                        <currency-input
                          [name]="'other'"
                          [(ngModel)]="reo.otherMonthlyExpense"
                          (change)="onReoExpenseItemChanged()">
                        </currency-input>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-1 mt-2">
                      <label for="total" class="fw-bold o-1">Total Misc. REO Expenses</label>
                      <span class="fw-bold me-2">{{ reo.monthlyMiscExpenses | currency}}</span>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-1 mt-2">
                        <label for="total" class="fw-bold o-1">Total Monthly PITI</label>
                        <span class="text-danger fw-bold me-2">{{ totalMonthlyReoExpenses | currency}}</span>
                    </div>
                </div>
                <hr>
                <div class="row mb-2" *ngIf="isInvestmentOrTwoToFourUnitProperty">
                    <div class="mb-1">
                        <span class="fw-bold o-1">Monthly Earnings</span>
                        <span class="ms-4 fw-bold text-success">{{reo.netRentalIncome | currency}}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-1">
                        <div>
                            <div class="radio radio-info form-check-inline ms-1">
                                <input type="radio" id="calculated_{{reo.reoId}}" [value]="'calculated'"
                                       name="calculated_{{reo.reoId}}" [(ngModel)]="monthlyEarningEnterType">
                                <label class="mb-0" for="calculated_{{reo.reoId}}"> Calculated </label>
                            </div>
                            <div class="radio radio-info form-check-inline ms-3">
                                <input type="radio" id="manual_{{reo.reoId}}" [value]="'manual'"
                                       name="manual_{{reo.reoId}}" [(ngModel)]="monthlyEarningEnterType">
                                <label class="mb-0" for="manual_{{reo.reoId}}"> Enter Manually </label>
                            </div>
                        </div>

                        <currency-input [name]="'reo_netRentalIncome-editor-{{ copyReo.reoId }}'" [allowDecimals]="false"
                                        [(ngModel)]="reo.netRentalIncome" *ngIf="monthlyEarningEnterType == 'manual'"
                                        [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.netRentalIncome'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.netRentalIncome'] == 'requested'">
                        </currency-input>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-1" *ngIf="monthlyEarningEnterType == 'calculated'">
                        <label for="reo_grossRentalIncome-editor-{{ reo.reoId }}" class="o-1">Monthly Gross Rental Income</label>
                        <currency-input [name]="'reo_grossRentalIncome-editor-{{ copyReo.reoId }}'" [allowDecimals]="false"
                                        [(ngModel)]="reo.grossRentalIncome" (blur)="calculateNetRentalIncome()"
                                        [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.grossRentalIncome'] == 'required' ||
                            quickApplyFieldsConfig['mortgage.realEstateOwned.grossRentalIncome'] == 'requested'">
                        </currency-input>
                    </div>
                    <div class="row pe-0">
                        <div class="col-sm-6">
                            <div class="d-flex justify-content-between align-items-center mb-1" *ngIf="monthlyEarningEnterType == 'calculated'">
                                <label style="min-width: 80px" for="reo_percentOwned-editor-{{ reo.reoId }}" class="o-1">% Owned</label>
                                <percent-input [name]="'reo_percentOwned-editor-{{ copyReo.reoId }}'"
                                               [(ngModel)]="reo.percentOwned" (blur)="calculateNetRentalIncome()"
                                               [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.percentOwned'] == 'required' ||
                                    quickApplyFieldsConfig['mortgage.realEstateOwned.percentOwned'] == 'requested'">
                                </percent-input>
                            </div>
                        </div>
                        <div class="col-sm-6 pe-0">
                            <div class="d-flex justify-content-between align-items-center mb-1" *ngIf="monthlyEarningEnterType == 'calculated'">
                                <label for="reo_vacancyFactor-editor-{{ reo.reoId }}" class="o-1">Vac. Factor</label>
                                <percent-input [name]="'reo_vacancyFactor-editor-{{ copyReo.reoId }}'"
                                               [(ngModel)]="reo.vacancyFactor" (blur)="calculateNetRentalIncome()"
                                               [required]="quickApplyFieldsConfig['mortgage.realEstateOwned.vacancyFactor'] == 'required' ||
                                    quickApplyFieldsConfig['mortgage.realEstateOwned.vacancyFactor'] == 'requested'">
                                </percent-input>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
