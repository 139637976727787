import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Address, Employment, EmploymentTypeEnum, Income, TypeOfIncomeEnum, VerificationStatus } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { v4 as uuidv4 } from 'uuid';
import { UrlaCurrencyInputComponent } from '../../../common/components/urla-currency-input/urla-currency-input.component';
import { EmploymentCalculatedStats, UrlaEmployment } from '../../../models/urla-mortgage.model';
import { EmployerNameNameComponent } from '../employer-name-input/employer-name-input.component';
import { IncomeDialogComponent } from '../income-dialog/income-dialog.component';
import { EmploymentAliasesDialogComponent } from './employment-aliases-dialog/employment-aliases-dialog.component';
import { MortgageFieldConfig } from '../../../models/urla-fields-config.model';

const retiredEmployerName = "Retired";

@Component({
  selector: 'employment',
  templateUrl: 'employment.component.html',
  styleUrls: ['employment.component.scss'],
  viewProviders: [formViewProvider]
})
export class EmploymentComponent implements OnInit {

  @ViewChild("monthlyIncome")
  monthlyIncomeCalculator: UrlaCurrencyInputComponent;

  @ViewChild("employerName")
  employerName: EmployerNameNameComponent;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  maxDate;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: Record<string, MortgageFieldConfig>;

  @Output()
  employmentDurationChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  isPrimaryChanged: EventEmitter<UrlaEmployment> = new EventEmitter<UrlaEmployment>();

  mouseCursorOnDeleteButton: boolean = false;

  yesNoOptions: EnumerationItem[] = [];
  ownershipShareTypes: EnumerationItem[] = [];
  selfEmploymentForms: EnumerationItem[] = [];
  verificationStatusTypes: EnumerationItem[] = [];

  isRetired: boolean = false;

  durationInMonths: number = 0;

  protected aliases: string[] = [];
  private _aliasesModalOptions: NgbModalOptions = {
    backdrop: 'static',
    centered: true,
    scrollable: true,
  };

  currentEmployerEnumValue: string = null;
  formerEmployerEnumValue: string = null;

  protected onIsRetiredChanged: (isRetired: boolean) => void = () => { };

  protected componentId: string;

  private _employment: UrlaEmployment;

  private _employmentIncomeTypes: EnumerationItem[] = [];

  private _dateFormat: string = 'MM/dd/yyyy';

  private _employmentIncomeTypeValues: string[] = [];

  @Input()
  set employment(employment: UrlaEmployment) {
    this._employment = employment;
    if (!this.inEditMode) {
      this.calculateTotalIncome();
      this.isRetired = this._employment.employer && this._employment.employer === "Retired";
      this.durationInMonths = this.calculateEmploymentDurationInMonths();
    }

    this.resetAlias();
  }

  get employment(): UrlaEmployment {
    return this._employment;
  }

  @Output()
  attemptedToDeleteEmployment: EventEmitter<Employment> = new EventEmitter<Employment>();

  @Output()
  incomesChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  switchEmploymentType: EventEmitter<any> = new EventEmitter<any>();

  protected readonly VerificationStatus = VerificationStatus;

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _modalService: NgbModal) {
    this.componentId = uuidv4();
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
    this.generateEmploymentIncomeTypeValuesList();
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.prepareEmploymentIncomeTypesList(enums);
      this.ownershipShareTypes = enums[Constants.enumerations.employmentOwnershipShare];
      this.selfEmploymentForms = enums[Constants.enumerations.selfEmploymentForm];
      this.verificationStatusTypes = this._enumsService.verificationStatuses;
      this.currentEmployerEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.EmploymentType.CurrentEmployer);
      this.formerEmployerEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.EmploymentType.FormerEmployer);
    });
  }

  ngOnInit() {
    this.initIsRetiredFunction();
  }

  private initIsRetiredFunction(): void {
    let formerEmployer: string = null;
    let formerSelfEmployed: boolean = null;
    let formerVerificationStatus: VerificationStatus = null;
    let formerAliases: string[] = null;

    this.onIsRetiredChanged = (isRetired: boolean) => {
      this.isRetired = isRetired;
      const employment = this._employment;
      if (isRetired) {
        const employer = employment.employer;

        // Save the current values, so we can restore them later if the user
        // unchecks the retired checkbox.
        if (employer !== retiredEmployerName) {
          formerEmployer = employer;
          formerSelfEmployed = employment.selfEmployed;
          formerVerificationStatus = employment.verificationStatus;
          formerAliases = employment.aliases;
        }

        employment.employer = retiredEmployerName;
        delete employment.selfEmployed;
        delete employment.verificationStatus;
        delete employment.aliases;
      } else {
        // Restore the saved values.
        employment.employer = formerEmployer;
        employment.selfEmployed = formerSelfEmployed;
        employment.verificationStatus = formerVerificationStatus;
        employment.aliases = formerAliases;
      }
    };
  }

  onMonthlyIncomeCalculatorClicked = () => {
    this.openIncomeCalculator();
  }

  onIsPrimaryChanged = (isPrimary: boolean) => {
    this._employment.isPrimary = isPrimary;
    this.isPrimaryChanged.emit(this._employment);
  }

  private resetAlias(): void {
    this.aliases = [...this._employment.aliases];
  }

  protected onClickEditAliases(): void {
    this.showEditAliasesModal().catch(console.error);
  }

  private async showEditAliasesModal(): Promise<void> {
    const modalRef = this._modalService.open(
      EmploymentAliasesDialogComponent,
      this._aliasesModalOptions);
    modalRef.componentInstance.aliases = this._employment.aliases;

    const result = await modalRef.result;

    if (result) {
      this._employment.aliases = result;
      this.resetAlias();
    }
  }

  onStartDateChanged = () => {
    this.durationInMonths = this.calculateEmploymentDurationInMonths();
    this.employmentDurationChanged.emit(this.durationInMonths);
  }

  onEndDateChanged = () => {
    this.durationInMonths = this.calculateEmploymentDurationInMonths();
    this.employmentDurationChanged.emit(this.durationInMonths);
  }

  onDeleteClicked = () => {
    this.attemptedToDeleteEmployment.emit(this.employment);
  }

  onEmployerAddressChanged = (address: Address) => {
    this.employment.address = address;
  }

  selfEmployedChanged = (selfEmployed: boolean) => {
    if (!selfEmployed) {
      this.employment.selfEmploymentMonthlyIncomeOrLoss = undefined;
      this.selfEmployedMonthlyIncomeChanged();
    }
  }

  selfEmployedMonthlyIncomeChanged = () => {
    this.incomesChanged.emit();
  }

  onSwitchedEmploymentType = () => {
    if (this.employment.employmentType == EmploymentTypeEnum.CurrentEmployer) {
      this.employment.employmentType = EmploymentTypeEnum.FormerEmployer;
    } else {
      this.employment.employmentType = EmploymentTypeEnum.CurrentEmployer;
      this.employment.endDate = null;
    }
    this.switchEmploymentType.emit();
  }

  

  private filterEmploymentIncomes = (incomeType: TypeOfIncomeEnum): boolean => {
    return incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Base) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Bonus) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Commissions) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Overtime) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryCombatPay) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryFlightPay) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryHazardPay) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryOverseasPay) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryPropPay) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryClothesAllowance) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryRationsAllowance) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryVariableHousingAllowance) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryQuartersAllowance) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.OtherTypesOfIncome);
  }

  private generateEmploymentIncomeTypeValuesList = () => {
    this._employmentIncomeTypeValues = [
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Base),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Bonus),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Commissions),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Overtime),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryCombatPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryFlightPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryHazardPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryOverseasPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryPropPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryClothesAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryRationsAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryVariableHousingAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryQuartersAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.OtherTypesOfIncome)
    ]
  }

  private prepareEmploymentIncomeTypesList = (enums: any) => {
    const incomeTypes = enums[Constants.enumerations.incomeType];
    this._employmentIncomeTypeValues.forEach(type => {
      const incomeType = incomeTypes.find(t => t.value === type);
      if (incomeType) {
        this._employmentIncomeTypes.push(incomeType);
      }
    })
  }

  private calculateEmploymentDurationInMonths = (): number => {
    if (!this._employment.startDate) {
      return 0;
    }
    let endDate = DateTime.now();
    const startDate = DateTime.fromFormat(this._employment.startDate, this._dateFormat);
    if (this._employment.employmentType === this.formerEmployerEnumValue) {
      if (!this._employment.endDate) {
        return 0;
      }
      endDate = DateTime.fromFormat(this._employment.endDate, this._dateFormat);
    }
    const diff = endDate.diff(startDate, ["months"]);
    return Math.round(diff.months);
  }

  private openIncomeCalculator = () => {
    const modalRef = this._modalService.open(IncomeDialogComponent, Constants.modalOptions.large);
    const editableIncomes = this._employment.incomes.filter(i => this.filterEmploymentIncomes(i.typeOfIncome));
    modalRef.componentInstance.incomeTypes = this._employmentIncomeTypes;
    modalRef.componentInstance.incomes = editableIncomes;
    modalRef.componentInstance.title = "Total Gross Income";
    modalRef.result.then((result) => {
      this.consolidateIncomes(result);
      this.calculateTotalIncome();
      this.incomesChanged.emit();
    }, (err) => {
      console.log(err);
    });
  }

  private calculateTotalIncome = () => {
    if (!this._employment.calculatedStats) {
      this._employment.calculatedStats = new EmploymentCalculatedStats();
    }
    this._employment.calculatedStats.monthlyIncome = 0;
    const editableIncomes = this._employment.incomes.filter(i => this.filterEmploymentIncomes(i.typeOfIncome));
    if (editableIncomes.length) {
      this._employment.calculatedStats.monthlyIncome = editableIncomes.map(a => a.monthlyIncome).reduce(function (a, b) {
        if (!a) a = 0;
        if (!b) b = 0;
        return a + b;
      });
    }
  }

  private consolidateIncomes = (editedIncomes: Income[]) => {
    editedIncomes.forEach(income => {
      // Add the ones that are not in the employment
      const existingOne = this._employment.incomes.find(i => i.incomeId == income.incomeId);
      if (!existingOne) {
        this._employment.incomes.push(income);
      } else {
        // Swap the ones that exist on both - hence, the edited ones
        const index = this._employment.incomes.indexOf(existingOne);
        if (index >= 0) {
          this._employment.incomes[index] = income;
        }
      }
    });
    // Remove the ones that exist on the employment, but not in the local array - hence, the deleted ones
    for (let ix: number = this._employment.incomes.length - 1; ix >= 0; ix--) {
      const income = this._employment.incomes[ix];
      const existOnLocalArray = editedIncomes.find(i => i.incomeId == income.incomeId);
      if (!existOnLocalArray) {
        this._employment.incomes.splice(ix, 1);
      }
    }
  }
}