<div class="modal-header">
    <h5 class="modal-title">Income Calculator</h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <form #incomeCalculateForm="ngForm" novalidate id="incomeCalculateForm" name="incomeCalculateForm" >
      <div class="loan-app main">
        <div class="grid-layout">
          <div class="grid-layout-row" >
            <urla-currency-input [allowNegative]="false" [label]="'Amount'" class="col-sm-3" [required]="true" name="amount"
              [(ngModel)]="income.amount" (ngModelChange)="onAmountChanged(income)"></urla-currency-input>
            <urla-dropdown [label]="'Pay Period'" class="col-sm-3" name="payPeriod" [required]="true"
              [(ngModel)]="income.payPeriod" (ngModelChange)="onAmountChanged(income)" [options]="incomePayPeriods"></urla-dropdown>
            <urla-digits-input name="hoursPerWeek" [label]="'Hrs/Week'" class="col-sm-3"
              [(ngModel)]="income.hoursPerWeek" (ngModelChange)="onAmountChanged(income)"
              [required]="income.payPeriod === PayPeriod.Hourly" [readonly]="income.payPeriod !== PayPeriod.Hourly">
            </urla-digits-input>
            <urla-currency-input [label]="'Monthly'" class="col-sm-3"
              [readonly]="true" name="monthlyIncome" [showBorder]="false"
              [(ngModel)]="income.monthlyIncome"></urla-currency-input>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer form-footer">
    <div class="row">
      <div class="col-xs-12 text-left">
        <div class="button-items" style="float: right;">
          <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">Cancel</button>
          <button type="button" class="btn btn-primary right" (click)="onOkClicked()">OK</button>
        </div>
      </div>
    </div>
  </div>
  