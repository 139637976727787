import { EventEmitter, Injectable, Output } from '@angular/core';
import { MortgageModelFieldsConfig } from '../modules/urla/models/urla-fields-config.model';
import { EnumerationService } from './enumeration-service';
import { MortgageService } from './mortgage.service';
import { MenuItemStatus } from '../modules/tpo/models/enums/menu-item-status.enum';
import { MenuItemStatusChangedEvent } from '../modules/tpo/models/menu-item-status-changed-event.model';
import { BorrowerRelationshipTitleType, ChannelEnum, DomesticRelationshipTypeEnum, EthnicityType, LoanApplication, MilitaryBranch, MilitaryStatus, MortgageBorrower, NativeAmericanLandsType, PropertyTitleType, RealEstateOwned, RecordingJurisdictionType, ResidencyBasis, ResidencyType } from '../models';
import { UrlaRequiredState, UtilityService } from '../modules/urla/services/utility.service';
import { Constants } from './constants';
import { UrlaMortgage } from '../modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from '../modules/urla/services/mortgage-calculation.service';
import { BorrowerType } from '../modules/leads/models/lead-credit.model';
import { LoanPurposeTypeEnum, MortgageAppliedForTypeEnum } from '../modules/app-details/components/title-history/models/title-order.model';
import { DateTime } from 'luxon';
import { getDaysForMonth } from 'src/utils';

@Injectable({
  providedIn: 'root'
})
export class UrlaValidationService {

  @Output()
  menuItemStatusChanged: EventEmitter<MenuItemStatusChangedEvent> = new EventEmitter<MenuItemStatusChangedEvent>();

  private _mortgageFieldsConfig: MortgageModelFieldsConfig;

  titleOnlyEnumValue: string = "";
  nonTitledSpouseEnumValue: string = "";
  private _loanPurposePurchaseEnumValue: string;
  private _propertyWillBePrimaryEnumValue: string;
  private _propertyWillBeSecondaryEnumValue: string;
  private _propertyWillBeInvestmentEnumValue: string;
  private _ethnicityTypeHispanicOrLatino: string;
  private _raceTypeAmericanIndianOrAlaskaNative: string;
  private _projectTypeCondominium: string;
  private _estateLeaseHoldEnumValue: string;
  private _formerEmployerEnumValue: string;
  private _amortizationTypeArmEnumValue: string;

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _mortgageService: MortgageService,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _utilsService: UtilityService) {
    this._mortgageFieldsConfig = this._mortgageService.urlaFieldsConfig;

    this.titleOnlyEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.SigningRole.TitleOnly);
    this.nonTitledSpouseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.SigningRole.NonTitledSpouse);
    this._loanPurposePurchaseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Purchase);
    this._propertyWillBePrimaryEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.PrimaryResidence);
    this._propertyWillBeSecondaryEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.SecondaryResidence);
    this._propertyWillBeInvestmentEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.Investment);
    this._ethnicityTypeHispanicOrLatino = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.ethnicityTypeHispanicOrLatino);
    this._raceTypeAmericanIndianOrAlaskaNative = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceTypeAmericanIndianOrAlaskaNative);
    this._projectTypeCondominium = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.projectTypeCondominium);
    this._estateLeaseHoldEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.EstateType.Leasehold);
    this._formerEmployerEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.EmploymentType.FormerEmployer);
    this._amortizationTypeArmEnumValue = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.isAmortizationTypeArm);
  }

  updateUrlaFieldConfigBasedOnMortgage = (mortgage: UrlaMortgage, application: LoanApplication) => {
    this._utilsService.updateUrlaFieldConfigBasedOnMortgage(this._mortgageFieldsConfig, mortgage, !!application.losIdentifier);
  }

  loadDeepHavenUrlaFieldsConFig = () => {
    this._mortgageFieldsConfig = this._mortgageService.deepHavenUrlaFieldsConfig;
  }

  getStatusForBorrowersInfo = (mortgage: UrlaMortgage, isTpo: boolean = false): MenuItemStatus => {
    let isCorrespondent = mortgage.channel === ChannelEnum.Correspondent;
    let state = MenuItemStatus.Success;

    if (mortgage.borrowers && mortgage.borrowers.length > 0) {
      mortgage.borrowers.forEach(borrower => {

        let borrowerState = this.getStatusForBorrowerInfo(borrower, isCorrespondent, isTpo)

        if (state == MenuItemStatus.Error) {
          return state;
        }
        else if (borrowerState == MenuItemStatus.Error || borrowerState == MenuItemStatus.Pending) {
          state = borrowerState;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerInfo = (borrower: MortgageBorrower, isCorrespondent: boolean, isTpo: boolean = false): MenuItemStatus => {
    let state = MenuItemStatus.Success;

    let contactInfoState = this.getStatusForBorrowerContactInfo(borrower, isCorrespondent, isTpo);
    let addressInfoState = this.getStatusForBorrowerAddressInfo(borrower);
    let employmentState = this.getStatusForBorrowerEmploymentInfo(borrower);
    let titleState = this.getStatusForBorrowerTitleInfo(borrower);

    if (contactInfoState == MenuItemStatus.Error || addressInfoState == MenuItemStatus.Error ||
      employmentState == MenuItemStatus.Error || titleState == MenuItemStatus.Error) {
      state = MenuItemStatus.Error;
    }
    else if (contactInfoState == MenuItemStatus.Pending || addressInfoState == MenuItemStatus.Pending ||
      employmentState == MenuItemStatus.Pending || titleState == MenuItemStatus.Pending) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForBorrowerContactInfo = (borrower: MortgageBorrower, isCorrespondent: boolean, isTpo: boolean = false) => {
    let state = MenuItemStatus.Success;

    let isTitleOnlyOrNonTitledSpouse = borrower.signingRole == this.titleOnlyEnumValue ||
      borrower.signingRole == this.nonTitledSpouseEnumValue;

    let unmarriedAddendum = borrower.domesticRelationshipType != null ||
      borrower.unmarriedRelationshipState != null;

    const borrowerAuthorizedCreditCheck = (borrower.authorizedCreditCheck == true);
    if (
      this._mortgageFieldsConfig['mortgage.borrower.firstName']?.required == UrlaRequiredState.Required && !(!!borrower.firstName) ||
      this._mortgageFieldsConfig['mortgage.borrower.middleName']?.required == UrlaRequiredState.Required && !(!!borrower.middleName) ||
      this._mortgageFieldsConfig['mortgage.borrower.lastName']?.required == UrlaRequiredState.Required && !(!!borrower.lastName) ||
      this._mortgageFieldsConfig['mortgage.borrower.nameSuffix']?.required == UrlaRequiredState.Required && !(!!borrower.nameSuffix) ||
      this._mortgageFieldsConfig['mortgage.borrower.signingRole']?.required == UrlaRequiredState.Required && !(!!borrower.signingRole) ||
      this._mortgageFieldsConfig['mortgage.borrower.primaryEmail']?.required == UrlaRequiredState.Required && !(!!borrower.primaryEmail) || (borrower.primaryEmail && this.checkIsInvalidEmail(borrower.primaryEmail)) ||
      this._mortgageFieldsConfig['mortgage.borrower.secondaryEmail']?.required == UrlaRequiredState.Required && !(!!borrower.secondaryEmail) || (borrower.secondaryEmail && this.checkIsInvalidEmail(borrower.secondaryEmail)) ||
      this._mortgageFieldsConfig['mortgage.borrower.mobilePhone']?.required == UrlaRequiredState.Required && !(!!borrower.mobilePhone) || (borrower.mobilePhone && this.checkIsInvalidPhoneNumber(borrower.mobilePhone)) ||
      this._mortgageFieldsConfig['mortgage.borrower.homePhone']?.required == UrlaRequiredState.Required && !(!!borrower.homePhone) || (borrower.homePhone && this.checkIsInvalidPhoneNumber(borrower.homePhone)) ||
      this._mortgageFieldsConfig['mortgage.borrower.socialSecNum']?.required == UrlaRequiredState.Required && !(!!borrower.socialSecNum) || (borrower.socialSecNum && this.checkIsInvalidSsn(borrower.socialSecNum)) ||
      this._mortgageFieldsConfig['mortgage.borrower.dateOfBirth']?.required == UrlaRequiredState.Required && !(!!borrower.dateOfBirth) || (borrower.dateOfBirth && this.checkIsInvalidDate(borrower.dateOfBirth)) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.residenceStatus']?.required == UrlaRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.declarations.residenceStatus) ||
      this._mortgageFieldsConfig['mortgage.borrower.maritalStatus']?.required == UrlaRequiredState.Required && !(!!borrower.maritalStatus) ||
      this._mortgageFieldsConfig['mortgage.borrower.entityVestingType']?.required == UrlaRequiredState.Required && !(!!borrower.entityVestingType) ||
      this._mortgageFieldsConfig['mortgage.borrower.languagePreference']?.required == UrlaRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !borrower.hasRefusedToProvideLanguage && !(!!borrower.languagePreference) ||
      this._mortgageFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck']?.required == UrlaRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrowerAuthorizedCreditCheck && !(!!borrower.dateAuthorizedCreditCheck) ||
      (!isTitleOnlyOrNonTitledSpouse && borrowerAuthorizedCreditCheck && borrower.dateAuthorizedCreditCheck && this.checkIsInvalidDate(borrower.dateAuthorizedCreditCheck)) ||
      this._mortgageFieldsConfig['mortgage.borrower.authorizationMethod']?.required == UrlaRequiredState.Required && !isTpo && !isTitleOnlyOrNonTitledSpouse && borrowerAuthorizedCreditCheck && !(!!borrower.authorizationMethod) ||
      this._mortgageFieldsConfig['mortgage.borrower.creditRefNumber']?.required == UrlaRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !isCorrespondent && !(!!borrower.creditRefNumber) ||
      this._mortgageFieldsConfig['mortgage.borrower.creditScore']?.required == UrlaRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.creditScore) ||
      this._mortgageFieldsConfig['mortgage.borrower.domesticRelationshipType']?.required == UrlaRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && unmarriedAddendum && !(!!borrower.domesticRelationshipType) ||
      this._mortgageFieldsConfig['mortgage.borrower.unmarriedRelationshipState']?.required == UrlaRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && unmarriedAddendum && !(!!borrower.unmarriedRelationshipState) ||
      this._mortgageFieldsConfig['mortgage.borrower.unmarriedRelationshipTypeOther']?.required == UrlaRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && unmarriedAddendum && borrower.domesticRelationshipType == DomesticRelationshipTypeEnum.Other && !(!!borrower.unmarriedRelationshipTypeOther)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (!isTitleOnlyOrNonTitledSpouse && borrower.aliases && borrower.aliases.length > 0) {
      borrower.aliases.forEach(alias => {
        if (
          this._mortgageFieldsConfig['mortgage.borrower.alias.aliasType']?.required == UrlaRequiredState.Required && !(!!alias.aliasType) ||
          this._mortgageFieldsConfig['mortgage.borrower.alias.firstName']?.required == UrlaRequiredState.Required && !(!!alias.firstName) ||
          this._mortgageFieldsConfig['mortgage.borrower.alias.middleName']?.required == UrlaRequiredState.Required && !(!!alias.middleName) ||
          this._mortgageFieldsConfig['mortgage.borrower.alias.lastName']?.required == UrlaRequiredState.Required && !(!!alias.lastName) ||
          this._mortgageFieldsConfig['mortgage.borrower.alias.nameSuffix']?.required == UrlaRequiredState.Required && !(!!alias.nameSuffix)
        ) {
          state = MenuItemStatus.Error;
          return state;
        }
      });
    }
    if (
      this._mortgageFieldsConfig['mortgage.borrower.firstName']?.required == UrlaRequiredState.Requested && !(!!borrower.firstName) ||
      this._mortgageFieldsConfig['mortgage.borrower.middleName']?.required == UrlaRequiredState.Requested && !(!!borrower.middleName) ||
      this._mortgageFieldsConfig['mortgage.borrower.lastName']?.required == UrlaRequiredState.Requested && !(!!borrower.lastName) ||
      this._mortgageFieldsConfig['mortgage.borrower.nameSuffix']?.required == UrlaRequiredState.Requested && !(!!borrower.nameSuffix) ||
      this._mortgageFieldsConfig['mortgage.borrower.signingRole']?.required == UrlaRequiredState.Requested && !(!!borrower.signingRole) ||
      this._mortgageFieldsConfig['mortgage.borrower.primaryEmail']?.required == UrlaRequiredState.Requested && !(!!borrower.primaryEmail) ||
      this._mortgageFieldsConfig['mortgage.borrower.secondaryEmail']?.required == UrlaRequiredState.Requested && !(!!borrower.secondaryEmail) ||
      this._mortgageFieldsConfig['mortgage.borrower.mobilePhone']?.required == UrlaRequiredState.Requested && !(!!borrower.mobilePhone) ||
      this._mortgageFieldsConfig['mortgage.borrower.homePhone']?.required == UrlaRequiredState.Requested && !(!!borrower.homePhone) ||
      this._mortgageFieldsConfig['mortgage.borrower.socialSecNum']?.required == UrlaRequiredState.Requested && !(!!borrower.socialSecNum) ||
      this._mortgageFieldsConfig['mortgage.borrower.dateOfBirth']?.required == UrlaRequiredState.Requested && !(!!borrower.dateOfBirth) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.residenceStatus']?.required == UrlaRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.declarations.residenceStatus) ||
      this._mortgageFieldsConfig['mortgage.borrower.maritalStatus']?.required == UrlaRequiredState.Requested && !(!!borrower.maritalStatus) ||
      this._mortgageFieldsConfig['mortgage.borrower.entityVestingType']?.required == UrlaRequiredState.Requested && !(!!borrower.entityVestingType) ||
      this._mortgageFieldsConfig['mortgage.borrower.languagePreference']?.required == UrlaRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && !borrower.hasRefusedToProvideLanguage && !(!!borrower.languagePreference) ||
      this._mortgageFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck']?.required == UrlaRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrowerAuthorizedCreditCheck && !(!!borrower.dateAuthorizedCreditCheck) ||
      this._mortgageFieldsConfig['mortgage.borrower.authorizationMethod']?.required == UrlaRequiredState.Requested && !isTpo && !isTitleOnlyOrNonTitledSpouse && borrowerAuthorizedCreditCheck && !(!!borrower.authorizationMethod) ||
      this._mortgageFieldsConfig['mortgage.borrower.creditRefNumber']?.required == UrlaRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && !isCorrespondent && !(!!borrower.creditRefNumber) ||
      this._mortgageFieldsConfig['mortgage.borrower.creditScore']?.required == UrlaRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.creditScore) ||
      this._mortgageFieldsConfig['mortgage.borrower.domesticRelationshipType']?.required == UrlaRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && unmarriedAddendum && !(!!borrower.domesticRelationshipType) ||
      this._mortgageFieldsConfig['mortgage.borrower.unmarriedRelationshipState']?.required == UrlaRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && unmarriedAddendum && !(!!borrower.unmarriedRelationshipState) ||
      this._mortgageFieldsConfig['mortgage.borrower.unmarriedRelationshipTypeOther']?.required == UrlaRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && unmarriedAddendum && borrower.domesticRelationshipType == 'Other' && !(!!borrower.unmarriedRelationshipTypeOther)

    ) {
      state = MenuItemStatus.Pending;
    }
    if (!isTitleOnlyOrNonTitledSpouse && borrower.aliases && borrower.aliases.length > 0) {
      borrower.aliases.forEach(alias => {
        if ((state = MenuItemStatus.Error) &&
          this._mortgageFieldsConfig['mortgage.borrower.alias.aliasType']?.required == UrlaRequiredState.Requested && !(!!alias.aliasType) ||
          this._mortgageFieldsConfig['mortgage.borrower.alias.firstName']?.required == UrlaRequiredState.Requested && !(!!alias.firstName) ||
          this._mortgageFieldsConfig['mortgage.borrower.alias.middleName']?.required == UrlaRequiredState.Requested && !(!!alias.middleName) ||
          this._mortgageFieldsConfig['mortgage.borrower.alias.lastName']?.required == UrlaRequiredState.Requested && !(!!alias.lastName) ||
          this._mortgageFieldsConfig['mortgage.borrower.alias.nameSuffix']?.required == UrlaRequiredState.Requested && !(!!alias.nameSuffix)
        ) {
          state = MenuItemStatus.Pending;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerAddressInfo = (borrower: MortgageBorrower): MenuItemStatus => {
    let numberOfMonths = 0;
    let numberOfYears = 0;

    let state = MenuItemStatus.Success;

    if (borrower.residencyAddresses && borrower.residencyAddresses.length > 0) {
      borrower.residencyAddresses.forEach(address => {
        numberOfMonths += Number(address.durationMonths) | 0;
        numberOfYears += Number(address.durationYears) || 0;
        if (address.residencyType && (
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.address1']?.required == UrlaRequiredState.Required && !(!!address.address?.address1) ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.address2']?.required == UrlaRequiredState.Required && !(!!address.address?.address2) ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.city']?.required == UrlaRequiredState.Required && !(!!address.address?.city) ||
          (this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.state']?.required == UrlaRequiredState.Required && !(!!address.address?.state) && address.address?.country == 'us') ||
          (this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.zipCode']?.required == UrlaRequiredState.Required && !(!!address.address?.zipCode) && address.address?.country == 'us') ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.country']?.required == UrlaRequiredState.Required && !(!!address.address?.country) ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis']?.required == UrlaRequiredState.Required && (address.residencyType == ResidencyType.FormerAddress ||
            (address.residencyType == ResidencyType.PresentAddress && (borrower.signingRole == 'Borrower' || borrower.signingRole == 'CoSigner'))) && !(!!address.residencyBasis) ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.durationAtAddress']?.required == UrlaRequiredState.Required && (address.residencyType == ResidencyType.FormerAddress ||
            (address.residencyType == ResidencyType.PresentAddress && (borrower.signingRole == 'Borrower' || borrower.signingRole == 'CoSigner'))) && !(!!address.durationMonths) && !(!!address.durationYears) ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.rent']?.required == UrlaRequiredState.Required && (address.residencyType == ResidencyType.FormerAddress ||
            (address.residencyType == ResidencyType.PresentAddress && (borrower.signingRole == 'Borrower' || borrower.signingRole == 'CoSigner'))) && address.residencyBasis == 'Rent' && !(!!address.rent)
        )) {
          state = MenuItemStatus.Error;
        }
        if (state !== MenuItemStatus.Error && address.residencyType && (
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.address1']?.required == UrlaRequiredState.Requested && !(!!address.address?.address1) ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.address2']?.required == UrlaRequiredState.Requested && !(!!address.address?.address2) ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.city']?.required == UrlaRequiredState.Requested && !(!!address.address?.city) ||
          (this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.state']?.required == UrlaRequiredState.Requested && !(!!address.address?.state) && address.address?.country == 'us') ||
          (this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.zipCode']?.required == UrlaRequiredState.Requested && !(!!address.address?.zipCode) && address.address?.country == 'us') ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.country']?.required == UrlaRequiredState.Requested && !(!!address.address?.country) ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis']?.required == UrlaRequiredState.Requested && (address.residencyType == ResidencyType.FormerAddress ||
            (address.residencyType == ResidencyType.PresentAddress && (borrower.signingRole == 'Borrower' || borrower.signingRole == 'CoSigner'))) && !(!!address.residencyBasis) ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.durationAtAddress']?.required == UrlaRequiredState.Requested && (address.residencyType == ResidencyType.FormerAddress ||
            (address.residencyType == ResidencyType.PresentAddress && (borrower.signingRole == 'Borrower' || borrower.signingRole == 'CoSigner'))) && !(!!address.durationMonths) && !(!!address.durationYears) ||
          this._mortgageFieldsConfig['mortgage.borrower.residencyAddress.rent']?.required == UrlaRequiredState.Requested && (address.residencyType == ResidencyType.FormerAddress ||
            (address.residencyType == ResidencyType.PresentAddress && (borrower.signingRole == 'Borrower' || borrower.signingRole == 'CoSigner'))) && address.residencyBasis == 'Rent' && !(!!address.rent)
        )) {
          state = MenuItemStatus.Pending;
        }
      });

      if (numberOfYears * 12 + numberOfMonths < 24) {
        state = MenuItemStatus.Error;
        return state;
      }
    }

    return state;
  }

  private calculateEmploymentDurationInMonths = (employment): number => {
    if (!employment.startDate) {
      return 0;
    }
    let endDate = DateTime.now();
    const startDate = DateTime.fromFormat(employment.startDate, 'MM/dd/yyyy');
    if (employment.employmentType === this._formerEmployerEnumValue) {
      if (!employment.endDate) {
        return 0;
      }
      endDate = DateTime.fromFormat(employment.endDate, 'MM/dd/yyyy');
    }
    const diff = endDate.diff(startDate, ["months"]);
    return Math.round(diff.months);
  }

  getStatusForBorrowerEmploymentInfo = (borrower: MortgageBorrower): MenuItemStatus => {
    let totalDurationOfEmploymentInMonths: number = 0;
    let maxDate = { year: new Date().getFullYear() + 5, month: new Date().getMonth() + 1, day: new Date().getDate() }
    let state = MenuItemStatus.Success;

    if (borrower.employments && borrower.employments.length > 0) {
      borrower.employments.forEach(employment => {
        totalDurationOfEmploymentInMonths += this.calculateEmploymentDurationInMonths(employment);
        if (!(employment.employer && employment.employer === "Retired") &&
          (
            (this._mortgageFieldsConfig['mortgage.borrower.employment.employer']?.required == UrlaRequiredState.Required && !(!!employment.employer)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.position']?.required == UrlaRequiredState.Required && !(!!employment.position)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.startDate']?.required == UrlaRequiredState.Required && !(!!employment.startDate)) || (employment.startDate && this.checkIsInvalidDate(employment.startDate)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.endDate']?.required == UrlaRequiredState.Required && employment.employmentType == this._formerEmployerEnumValue && !(!!employment.endDate)) ||
            (employment.employmentType == this._formerEmployerEnumValue && employment.startDate && !this.checkIsInvalidDate(employment.startDate) && employment.endDate && this.checkIsInvalidDate(employment.endDate, null, employment.startDate)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.address1']?.required == UrlaRequiredState.Required && !(!!employment.address.address1)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.address2']?.required == UrlaRequiredState.Required && !(!!employment.address.address2)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.city']?.required == UrlaRequiredState.Required && !(!!employment.address.city)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.state']?.required == UrlaRequiredState.Required && !(!!employment.address.state) && employment.address.country == 'us') ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.zipCode']?.required == UrlaRequiredState.Required && !(!!employment.address.zipCode) && employment.address.country == 'us') ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.country']?.required == UrlaRequiredState.Required && !(!!employment.address.country)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.employerPhone']?.required == UrlaRequiredState.Required && !(!!employment.employerPhone)) || (employment.employerPhone && this.checkIsInvalidPhoneNumber(employment.employerPhone)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork']?.required == UrlaRequiredState.Required && (employment.yearsInLineOfWork == null || employment.yearsInLineOfWork == undefined || employment.yearsInLineOfWork.toString() == '')) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.selfEmployed']?.required == UrlaRequiredState.Required && (employment.selfEmployed == null || employment.selfEmployed == undefined)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare']?.required == UrlaRequiredState.Required && employment.selfEmployed && !(!!employment.borrowerOwnershipShare)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.selfEmploymentForm']?.required == UrlaRequiredState.Required && employment.selfEmployed && employment.borrowerOwnershipShare === 'GreaterOrEqualTo25Percent' && !(!!employment.selfEmploymentForm)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss']?.required == UrlaRequiredState.Required && employment.selfEmployed && (employment.selfEmploymentMonthlyIncomeOrLoss == null || employment.selfEmploymentMonthlyIncomeOrLoss == undefined)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.verificationStatus']?.required == UrlaRequiredState.Required && !(!!employment.verificationStatus)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber']?.required == UrlaRequiredState.Required && !(!!employment.employerIdentificationNumber))
          )
        ) {
          state = MenuItemStatus.Error;
        }

        if (state !== MenuItemStatus.Error && !(employment.employer && employment.employer === "Retired") &&
          (
            (this._mortgageFieldsConfig['mortgage.borrower.employment.employer']?.required == UrlaRequiredState.Requested && !(!!employment.employer)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.position']?.required == UrlaRequiredState.Requested && !(!!employment.position)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.startDate']?.required == UrlaRequiredState.Requested && !(!!employment.startDate)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.endDate']?.required == UrlaRequiredState.Requested && employment.employmentType == this._formerEmployerEnumValue && !(!!employment.endDate)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.address1']?.required == UrlaRequiredState.Requested && !(!!employment.address.address1)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.address2']?.required == UrlaRequiredState.Requested && !(!!employment.address.address2)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.city']?.required == UrlaRequiredState.Requested && !(!!employment.address.city)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.state']?.required == UrlaRequiredState.Requested && !(!!employment.address.state)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.zipCode']?.required == UrlaRequiredState.Requested && !(!!employment.address.zipCode)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.address.country']?.required == UrlaRequiredState.Requested && !(!!employment.address.country)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.employerPhone']?.required == UrlaRequiredState.Requested && !(!!employment.employerPhone)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork']?.required == UrlaRequiredState.Requested && (employment.yearsInLineOfWork == null || employment.yearsInLineOfWork == undefined || employment.yearsInLineOfWork.toString() == '')) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.selfEmployed']?.required == UrlaRequiredState.Requested && (employment.selfEmployed == null || employment.selfEmployed == undefined)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare']?.required == UrlaRequiredState.Requested && employment.selfEmployed && !(!!employment.borrowerOwnershipShare)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.selfEmploymentForm']?.required == UrlaRequiredState.Requested && employment.selfEmployed && employment.borrowerOwnershipShare === 'GreaterOrEqualTo25Percent' && !(!!employment.selfEmploymentForm)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss']?.required == UrlaRequiredState.Requested && employment.selfEmployed && (employment.selfEmploymentMonthlyIncomeOrLoss == null || employment.selfEmploymentMonthlyIncomeOrLoss == undefined)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.verificationStatus']?.required == UrlaRequiredState.Requested && !(!!employment.verificationStatus)) ||
            (this._mortgageFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber']?.required == UrlaRequiredState.Requested && !(!!employment.employerIdentificationNumber))
          )
        ) {
          state = MenuItemStatus.Pending;
        }
      });

      if (totalDurationOfEmploymentInMonths < 24) {
        state = MenuItemStatus.Error;
        return state;
      }
    } else {
      if (!(borrower.signingRole == this.titleOnlyEnumValue || borrower.signingRole == this.nonTitledSpouseEnumValue) && !(borrower.companyId == 229 || borrower.companyId == 276)) {
        state = MenuItemStatus.Error;
        return state;
      }
    }

    return state;
  }

  getStatusForBorrowerTitleInfo = (borrower: MortgageBorrower) => {
    let state = MenuItemStatus.Success;
    if (
      this._mortgageFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType']?.required == UrlaRequiredState.Required && !(!!borrower.borrowerRelationshipTitleType) ||
      this._mortgageFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription']?.required == UrlaRequiredState.Required && borrower.borrowerRelationshipTitleType === BorrowerRelationshipTitleType.Other && !(!!borrower.borrowerRelationshipTitleTypeOtherDescription)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      this._mortgageFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType']?.required == UrlaRequiredState.Requested && !(!!borrower.borrowerRelationshipTitleType) ||
      this._mortgageFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription']?.required == UrlaRequiredState.Requested && borrower.borrowerRelationshipTitleType === BorrowerRelationshipTitleType.Other && !(!!borrower.borrowerRelationshipTitleTypeOtherDescription)
    ) {
      state = MenuItemStatus.Pending;
    }
    return state;
  }

  private isFilteredAsset = (assetType, assetInsertionType) => {
    if (assetInsertionType == 1) return true;

    return assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Bond) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CheckingAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BridgeLoanNotDeposited) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.LifeInsurance) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CertificateOfDepositTimeDeposit) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.IndividualDevelopmentAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MoneyMarketFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MutualFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RetirementFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SavingsAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Stock) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.TrustAccount);
  }

  isFilteredLiability = (liabilityType: string) => {
    return liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Alimony) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildSupport) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildCare) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.JobRelatedExpenses) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.SeparateMaintenanceExpense) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.OtherExpense);
  }

  private isFilteredAssetForGift = (assetType) => {

    return assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CashGift) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.GiftOfEquity) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Grant);
  }

  getStatusForFinancialInfo = (mortgage: UrlaMortgage): MenuItemStatus => {
    let liabilityTypeCollections = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.CollectionsJudgementsAndLiens);

    let state = MenuItemStatus.Success;
    if (mortgage.assets && mortgage.assets.length > 0) {
      let isAssetError = mortgage.assets.some(asset =>
        ((this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) && (
          this._mortgageFieldsConfig['mortgage.asset.assetType']?.required == UrlaRequiredState.Required && !(!!asset.assetType) ||
          this._mortgageFieldsConfig['mortgage.asset.depository']?.required == UrlaRequiredState.Required && !(!!asset.depository) ||
          this._mortgageFieldsConfig['mortgage.asset.accountNumber']?.required == UrlaRequiredState.Required && !(!!asset.accountNumber) ||
          this._mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == UrlaRequiredState.Required && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
        ) || ((!this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) && (
          this._mortgageFieldsConfig['mortgage.asset.assetType']?.required == UrlaRequiredState.Required && !(!!asset.assetType) ||
          this._mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == UrlaRequiredState.Required && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
        )
      );
      if (isAssetError) {
        state = MenuItemStatus.Error;
        return state;
      }
      let isAssetPending = mortgage.assets.some(asset =>
        ((this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) && (
          !asset.owningBorrowerIds || asset.owningBorrowerIds.length === 0 ||
          this._mortgageFieldsConfig['mortgage.asset.assetType']?.required == UrlaRequiredState.Requested && !(!!asset.assetType) ||
          this._mortgageFieldsConfig['mortgage.asset.depository']?.required == UrlaRequiredState.Requested && !(!!asset.depository) ||
          this._mortgageFieldsConfig['mortgage.asset.accountNumber']?.required == UrlaRequiredState.Requested && !(!!asset.accountNumber) ||
          this._mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == UrlaRequiredState.Requested && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
        ) ||
        ((!this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) && (
          !asset.owningBorrowerIds || asset.owningBorrowerIds.length === 0 ||
          this._mortgageFieldsConfig['mortgage.asset.assetType']?.required == UrlaRequiredState.Requested && !(!!asset.assetType) ||
          this._mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == UrlaRequiredState.Requested && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
        )
      );
      if (isAssetPending) {
        state = MenuItemStatus.Pending;
      }

    }

    if (mortgage.transactionDetail.purchaseCredits && mortgage.transactionDetail.purchaseCredits.length > 0) {
      let isCreditError = mortgage.transactionDetail.purchaseCredits.some(credit => (
        this._mortgageFieldsConfig['mortgage.asset.assetType']?.required == UrlaRequiredState.Required && !(!!credit.purchaseCreditType) ||
        this._mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == UrlaRequiredState.Required && (credit.purchaseCreditAmount == null || credit.purchaseCreditAmount == undefined))
      );
      if (isCreditError) {
        state = MenuItemStatus.Error;
        return state;
      }
      let isCreditPending = mortgage.transactionDetail.purchaseCredits.some(credit => (
        this._mortgageFieldsConfig['mortgage.asset.assetType']?.required == UrlaRequiredState.Requested && !(!!credit.purchaseCreditType) ||
        this._mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == UrlaRequiredState.Requested && (credit.purchaseCreditAmount == null || credit.purchaseCreditAmount == undefined))
      );
      if (isCreditPending) {
        state = MenuItemStatus.Pending;
      }
    }


    if (mortgage.liabilities && mortgage.liabilities.length > 0) {

      mortgage.liabilities.forEach(liability => {
        let liabilityState = this.getStatusForLiability(liability, liabilityTypeCollections);
        if (state == MenuItemStatus.Error) {
          return;
        }
        else if (liabilityState == MenuItemStatus.Error || liabilityState == MenuItemStatus.Pending) {
          state = liabilityState
        }
      })
    }

    return state;

  }

  getStatusForLiability = (liability, liabilityTypeCollections) => {
    let state = MenuItemStatus.Success;

    if (
      (this._mortgageFieldsConfig['mortgage.liability.typeOfLiability']?.required == UrlaRequiredState.Required && !(!!liability.typeOfLiability)) ||
      (this._mortgageFieldsConfig['mortgage.liability.holderName']?.required == UrlaRequiredState.Required && !(!!liability.holderName) && this.isFilteredLiability(liability.typeOfLiability)) ||
      (this._mortgageFieldsConfig['mortgage.liability.accountNumber']?.required == UrlaRequiredState.Required && !(!!liability.accountNumber) && this.isFilteredLiability(liability.typeOfLiability)) ||
      (this._mortgageFieldsConfig['mortgage.liability.monthlyPayment']?.required == UrlaRequiredState.Required && (liability.monthlyPayment == null || liability.monthlyPayment == undefined)
        && liability.unpaidBalance && liability.unpaidBalance > 0 && liability.typeOfLiability != liabilityTypeCollections && this.isFilteredLiability(liability.typeOfLiability))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (!liability.owningBorrowerIds) || (liability.owningBorrowerIds.length === 0) ||
      (this._mortgageFieldsConfig['mortgage.liability.typeOfLiability']?.required == UrlaRequiredState.Requested && !(!!liability.typeOfLiability)) ||
      (this._mortgageFieldsConfig['mortgage.liability.holderName']?.required == UrlaRequiredState.Requested && !(!!liability.holderName) && this.isFilteredLiability(liability.typeOfLiability)) ||
      (this._mortgageFieldsConfig['mortgage.liability.accountNumber']?.required == UrlaRequiredState.Requested && !(!!liability.accountNumber) && this.isFilteredLiability(liability.typeOfLiability)) ||
      (this._mortgageFieldsConfig['mortgage.liability.monthlyPayment']?.required == UrlaRequiredState.Requested && (liability.monthlyPayment == null || liability.monthlyPayment == undefined)
        && liability.unpaidBalance && liability.unpaidBalance > 0 && liability.typeOfLiability != liabilityTypeCollections && this.isFilteredLiability(liability.typeOfLiability))
    ) {
      state = MenuItemStatus.Pending;
    }
    return state;
  }

  private checkIfInvestmentOrTwoToFourUnitProperty = (reo: RealEstateOwned) => {
    if (['RetainForPrimaryOrSecondaryResidence', 'Retained', 'RentalProperty'].indexOf(reo.dispositionStatus) < 0) return false;
    return reo.typeOfProperty == "TwoToFourUnitProperty" || reo.intendedUsageType == "Investment" || reo.intendedUsageType == "SecondaryResidence";
  }

  getStatusForReo = (mortgage: UrlaMortgage): MenuItemStatus => {
    let state = MenuItemStatus.Success;

    if (mortgage.realEstateOwned && mortgage.realEstateOwned.length > 0) {

      mortgage.realEstateOwned.forEach(reo => {
        if (
          mortgage.borrowers.find(borr => borr.residencyAddresses.find(a =>
            a.residencyType === ResidencyType.PresentAddress &&
            a.residencyBasis !== ResidencyBasis.Own &&
            reo.address1?.toLocaleLowerCase() === a.address?.address1?.toLocaleLowerCase() &&
            reo.city?.toLocaleLowerCase() === a.address?.city?.toLocaleLowerCase() &&
            reo.state?.toLocaleLowerCase() === a.address?.state?.toLocaleLowerCase() &&
            reo.zipCode?.toLocaleLowerCase() === a.address?.zipCode?.toLocaleLowerCase())) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.address1']?.required == UrlaRequiredState.Required && !(!!reo.address1) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.address2']?.required == UrlaRequiredState.Required && !(!!reo.address2) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.city']?.required == UrlaRequiredState.Required && !(!!reo.city) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.state']?.required == UrlaRequiredState.Required && !(!!reo.state) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.zipCode']?.required == UrlaRequiredState.Required && !(!!reo.zipCode) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.borrowerPicker']?.required == UrlaRequiredState.Required && (reo.owningBorrowerIds == null || reo.owningBorrowerIds == undefined || reo.owningBorrowerIds.length == 0) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.typeOfProperty']?.required == UrlaRequiredState.Required && !(!!reo.typeOfProperty) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.currentUsageType']?.required == UrlaRequiredState.Required && !(!!reo.currentUsageType) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.dispositionStatus']?.required == UrlaRequiredState.Required && !(!!reo.dispositionStatus) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.intendedUsageType']?.required == UrlaRequiredState.Required && !(!!reo.intendedUsageType) && reo.dispositionStatus != 'Sold' ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.isSubjectProperty']?.required == UrlaRequiredState.Required && (reo.isSubjectProperty == null || reo.isSubjectProperty == undefined) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.assetAccountId']?.required == UrlaRequiredState.Required && !(!!reo.assetAccountId) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.marketValue']?.required == UrlaRequiredState.Required && (reo.marketValue == null || reo.marketValue == undefined) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses']?.required == UrlaRequiredState.Required && (reo.monthlyMiscExpenses == null || reo.monthlyMiscExpenses == undefined) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.percentOwned']?.required == UrlaRequiredState.Required && (reo.percentOwned == null || reo.percentOwned == undefined || reo.percentOwned.toString() == '') && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.vacancyFactor']?.required == UrlaRequiredState.Required && (reo.vacancyFactor == null || reo.vacancyFactor == undefined || reo.vacancyFactor.toString() == '') && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.grossRentalIncome']?.required == UrlaRequiredState.Required && (reo.grossRentalIncome == null || reo.grossRentalIncome == undefined) && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.netRentalIncome']?.required == UrlaRequiredState.Required && (reo.netRentalIncome == null || reo.netRentalIncome == undefined) && this.checkIfInvestmentOrTwoToFourUnitProperty(reo)
        ) {
          state = MenuItemStatus.Error;
        }

        if (state != MenuItemStatus.Error && (
          this._mortgageFieldsConfig['mortgage.realEstateOwned.address1']?.required == UrlaRequiredState.Requested && !(!!reo.address1) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.address2']?.required == UrlaRequiredState.Requested && !(!!reo.address2) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.city']?.required == UrlaRequiredState.Requested && !(!!reo.city) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.state']?.required == UrlaRequiredState.Requested && !(!!reo.state) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.zipCode']?.required == UrlaRequiredState.Requested && !(!!reo.zipCode) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.borrowerPicker']?.required == UrlaRequiredState.Requested && (reo.owningBorrowerIds == null || reo.owningBorrowerIds == undefined || reo.owningBorrowerIds.length == 0) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.typeOfProperty']?.required == UrlaRequiredState.Requested && !(!!reo.typeOfProperty) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.currentUsageType']?.required == UrlaRequiredState.Requested && !(!!reo.currentUsageType) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.dispositionStatus']?.required == UrlaRequiredState.Requested && !(!!reo.dispositionStatus) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.intendedUsageType']?.required == UrlaRequiredState.Requested && !(!!reo.intendedUsageType) && reo.dispositionStatus != 'Sold' ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.isSubjectProperty']?.required == UrlaRequiredState.Requested && (reo.isSubjectProperty == null || reo.isSubjectProperty == undefined) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.assetAccountId']?.required == UrlaRequiredState.Requested && !(!!reo.assetAccountId) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.marketValue']?.required == UrlaRequiredState.Requested && (reo.marketValue == null || reo.marketValue == undefined) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses']?.required == UrlaRequiredState.Requested && (reo.monthlyMiscExpenses == null || reo.monthlyMiscExpenses == undefined) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.percentOwned']?.required == UrlaRequiredState.Requested && (reo.percentOwned == null || reo.percentOwned == undefined || reo.percentOwned.toString() == '') && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.vacancyFactor']?.required == UrlaRequiredState.Requested && (reo.vacancyFactor == null || reo.vacancyFactor == undefined || reo.vacancyFactor.toString() == '') && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.grossRentalIncome']?.required == UrlaRequiredState.Requested && (reo.grossRentalIncome == null || reo.grossRentalIncome == undefined) && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          this._mortgageFieldsConfig['mortgage.realEstateOwned.netRentalIncome']?.required == UrlaRequiredState.Requested && (reo.netRentalIncome == null || reo.netRentalIncome == undefined) && this.checkIfInvestmentOrTwoToFourUnitProperty(reo)
        )) {
          state = MenuItemStatus.Pending;
        }
      })
    }

    return state;
  }

  private isSalesPriceExceedsAppraisedValue = (mortgage: UrlaMortgage) => {
    return (mortgage.mortgageTerm.mortgageAppliedFor === 'VA' || mortgage.mortgageTerm.mortgageAppliedFor === 'FHA') &&
      this._calculationService.isPurposeOfLoanPurchase(mortgage) && mortgage.transactionDetail.purchasePriceAmount && mortgage.mortgageTerm.appraisedValue &&
      mortgage.transactionDetail.purchasePriceAmount > mortgage.mortgageTerm.appraisedValue;
  }

  getStatusForLoanProperty = (mortgage: UrlaMortgage, isAppraisedValueHidden: boolean) => {
    let state = MenuItemStatus.Success;

    let loanPropertyInfoState = this.getStatusForLoanPropertyInfo(mortgage, isAppraisedValueHidden);
    let otherNewMortgageLoansState = this.getStatusForOtherNewMortgageLoans(mortgage);
    let rentalIncomeOnPurchasesState = this.getStatusForRentalIncomeOnPurchases(mortgage);
    let giftOrGrantsState = this.getStatusForGiftOrGrants(mortgage);

    if (loanPropertyInfoState == MenuItemStatus.Error || otherNewMortgageLoansState == MenuItemStatus.Error ||
      rentalIncomeOnPurchasesState == MenuItemStatus.Error || giftOrGrantsState == MenuItemStatus.Error) {
      state = MenuItemStatus.Error;
    }
    else if (loanPropertyInfoState == MenuItemStatus.Pending || otherNewMortgageLoansState == MenuItemStatus.Pending ||
      rentalIncomeOnPurchasesState == MenuItemStatus.Pending || giftOrGrantsState == MenuItemStatus.Pending) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForLoanPropertyInfo = (mortgage: UrlaMortgage, isAppraisedValueHidden: boolean) => {
    let state = MenuItemStatus.Success;

    if (
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.amount']?.required == UrlaRequiredState.Required && (mortgage.mortgageTerm.amount == null || mortgage.mortgageTerm.amount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.purposeOfLoan']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.purposeOfLoan)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.refiPurpose']?.required == UrlaRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refiPurpose)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.refinancePrimaryPurpose']?.required == UrlaRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refinancePrimaryPurpose)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.propertyWillBe']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.propertyWillBe)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.address1']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.address1)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.address2']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.address2)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.city']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.city)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.state']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.state)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.zipCode']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.zipCode)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.county']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.county)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.noOfUnits']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.noOfUnits == null || mortgage.subjectProperty.noOfUnits == undefined || mortgage.subjectProperty.noOfUnits.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionMethod']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.constructionMethod)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionMethodOtherDescription']?.required == UrlaRequiredState.Required && mortgage.subjectProperty.constructionMethod == 'Other' && !(!!mortgage.subjectProperty.constructionMethodOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionStatus']?.required == UrlaRequiredState.Required && this._calculationService.isPurposeOfLoanConstructionToPerm(mortgage) && !(!!mortgage.subjectProperty.constructionStatus)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence']?.required == UrlaRequiredState.Required && this._calculationService.isFhaLoan(mortgage) && (mortgage.subjectProperty.isFhaSecondaryResidence == null || mortgage.subjectProperty.isFhaSecondaryResidence == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.purchasePriceAmount']?.required == UrlaRequiredState.Required && this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.presentValue']?.required == UrlaRequiredState.Required && !this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.subjectProperty.presentValue == null || mortgage.subjectProperty.presentValue == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.appraisedValue']?.required == UrlaRequiredState.Required && !isAppraisedValueHidden && (mortgage.mortgageTerm.appraisedValue == null || mortgage.mortgageTerm.appraisedValue == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.isMixedUseProperty == null || mortgage.subjectProperty.isMixedUseProperty == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose']?.required == UrlaRequiredState.Required && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionLoanPurpose)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == UrlaRequiredState.Required && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionToPermanentClosingType))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.amount']?.required == UrlaRequiredState.Requested && (mortgage.mortgageTerm.amount == null || mortgage.mortgageTerm.amount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.purposeOfLoan']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.purposeOfLoan)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.refiPurpose']?.required == UrlaRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refiPurpose)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.refinancePrimaryPurpose']?.required == UrlaRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refinancePrimaryPurpose)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.propertyWillBe']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.propertyWillBe)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.address1']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.address1)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.address2']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.address2)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.city']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.city)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.state']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.state)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.zipCode']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.zipCode)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.county']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.county)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.noOfUnits']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.noOfUnits == null || mortgage.subjectProperty.noOfUnits == undefined || mortgage.subjectProperty.noOfUnits.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionMethod']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.constructionMethod)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionMethodOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty.constructionMethod == 'Other' && !(!!mortgage.subjectProperty.constructionMethodOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionStatus']?.required == UrlaRequiredState.Requested && this._calculationService.isPurposeOfLoanConstructionToPerm(mortgage) && !(!!mortgage.subjectProperty.constructionStatus)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence']?.required == UrlaRequiredState.Requested && this._calculationService.isFhaLoan(mortgage) && (mortgage.subjectProperty.isFhaSecondaryResidence == null || mortgage.subjectProperty.isFhaSecondaryResidence == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.purchasePriceAmount']?.required == UrlaRequiredState.Requested && this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.presentValue']?.required == UrlaRequiredState.Requested && !this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.subjectProperty.presentValue == null || mortgage.subjectProperty.presentValue == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.appraisedValue']?.required == UrlaRequiredState.Requested && !isAppraisedValueHidden && (mortgage.mortgageTerm.appraisedValue == null || mortgage.mortgageTerm.appraisedValue == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.isMixedUseProperty == null || mortgage.subjectProperty.isMixedUseProperty == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose']?.required == UrlaRequiredState.Requested && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionLoanPurpose)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == UrlaRequiredState.Requested && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionToPermanentClosingType))
    ) {
      state = MenuItemStatus.Pending;
    }

    if (this.isSalesPriceExceedsAppraisedValue(mortgage)) {
      if (mortgage.borrowers && mortgage.borrowers.length > 0) {
        mortgage.borrowers.forEach(borrower => {
          if (
            this._mortgageFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType']?.required == UrlaRequiredState.Required && (borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == null || borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == undefined)
          ) {
            state = MenuItemStatus.Error;
            return state;
          }
          if (
            this._mortgageFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType']?.required == UrlaRequiredState.Requested && (borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == null || borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == undefined)
          ) {
            state = MenuItemStatus.Pending;
          }
        });
      }
    }

    return state;
  }

  getStatusForOtherNewMortgageLoans = (mortgage: UrlaMortgage): MenuItemStatus => {
    let state = MenuItemStatus.Success;

    if (mortgage.relatedMortgages && mortgage.relatedMortgages.length > 0) {
      mortgage.relatedMortgages.forEach(relatedMortgage => {
        if (
          (this._mortgageFieldsConfig['mortgage.relatedMortgage.lienType']?.required == UrlaRequiredState.Required && !(!!relatedMortgage.lienType)) ||
          (this._mortgageFieldsConfig['mortgage.relatedMortgage.monthlyPayment']?.required == UrlaRequiredState.Required && (relatedMortgage.monthlyPayment == null || relatedMortgage.monthlyPayment == undefined)) ||
          (this._mortgageFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount']?.required == UrlaRequiredState.Required && (relatedMortgage.loanOrDrawAmount == null || relatedMortgage.loanOrDrawAmount == undefined)) ||
          (this._mortgageFieldsConfig['mortgage.relatedMortgage.creditLimit']?.required == UrlaRequiredState.Required && (relatedMortgage.creditLimit == null || relatedMortgage.creditLimit == undefined)) ||
          (this._mortgageFieldsConfig['mortgage.relatedMortgage.isHeloc']?.required == UrlaRequiredState.Required && (relatedMortgage.isHeloc == null || relatedMortgage.isHeloc == undefined))
        ) {
          state = MenuItemStatus.Error;
        }

        if ((state != MenuItemStatus.Error) && (
          this._mortgageFieldsConfig['mortgage.relatedMortgage.lienType']?.required == UrlaRequiredState.Requested && !(!!relatedMortgage.lienType) ||
          (this._mortgageFieldsConfig['mortgage.relatedMortgage.monthlyPayment']?.required == UrlaRequiredState.Requested && (relatedMortgage.monthlyPayment == null || relatedMortgage.monthlyPayment == undefined)) ||
          (this._mortgageFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount']?.required == UrlaRequiredState.Requested && (relatedMortgage.loanOrDrawAmount == null || relatedMortgage.loanOrDrawAmount == undefined)) ||
          (this._mortgageFieldsConfig['mortgage.relatedMortgage.creditLimit']?.required == UrlaRequiredState.Requested && (relatedMortgage.creditLimit == null || relatedMortgage.creditLimit == undefined)) ||
          (this._mortgageFieldsConfig['mortgage.relatedMortgage.isHeloc']?.required == UrlaRequiredState.Requested && (relatedMortgage.isHeloc == null || relatedMortgage.isHeloc == undefined))
        )) {
          state = MenuItemStatus.Pending;
        }
      });
    }
    return state;
  }

  getStatusForRentalIncomeOnPurchases = (mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    let borrower;

    if (mortgage.subjectProperty &&
      (mortgage.subjectProperty.purposeOfLoan == this._loanPurposePurchaseEnumValue &&
        (mortgage.subjectProperty.propertyWillBe == this._propertyWillBeInvestmentEnumValue ||
          mortgage.subjectProperty.propertyWillBe == this._propertyWillBeSecondaryEnumValue ||
          (mortgage.subjectProperty.noOfUnits > 1 && mortgage.subjectProperty.propertyWillBe == this._propertyWillBePrimaryEnumValue)
        )
      )) {

      borrower = mortgage.borrowers.find(b => b.typeOfBorrower === BorrowerType.PrimaryBorrower);
      if (!borrower && mortgage.borrowers.length) {
        borrower = mortgage.borrowers[0];
      }

      if (
        (this._mortgageFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience']?.required == UrlaRequiredState.Required && (borrower.hasOneYearLandlordExperience == null || borrower.hasOneYearLandlordExperience == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.expectedGrossMonthlyRentalIncome == null || mortgage.subjectProperty.expectedGrossMonthlyRentalIncome == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.expectedNetMonthlyRentalIncome == null || mortgage.subjectProperty.expectedNetMonthlyRentalIncome == undefined))
      ) {
        state = MenuItemStatus.Error;
        return state;
      }
      if (
        this._mortgageFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience']?.required == UrlaRequiredState.Requested && (borrower.hasOneYearLandlordExperience == null || borrower.hasOneYearLandlordExperience == undefined) ||
        (this._mortgageFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.expectedGrossMonthlyRentalIncome == null || mortgage.subjectProperty.expectedGrossMonthlyRentalIncome == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.expectedNetMonthlyRentalIncome == null || mortgage.subjectProperty.expectedNetMonthlyRentalIncome == undefined)) ||
        mortgage.subjectProperty.propertyWillBe == "Investment" && mortgage.subjectProperty.expectedNetMonthlyRentalIncome > 0 && (borrower.hasOneYearLandlordExperience == null || borrower.hasOneYearLandlordExperience == undefined)
      ) {
        state = MenuItemStatus.Pending;
      }
    }

    return state;
  }

  getStatusForGiftOrGrants = (mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    let assets = mortgage.assets.filter(a => this.isFilteredAssetForGift(a.assetType));

    if (assets.length > 0) {
      let isError = assets.some(asset =>
        (this._mortgageFieldsConfig['mortgage.asset.assetType']?.required == UrlaRequiredState.Required && !(!!asset.assetType)) ||
        (this._mortgageFieldsConfig['mortgage.asset.giftOrGrantSource']?.required == UrlaRequiredState.Required && !(!!asset.giftOrGrantSource)) ||
        (this._mortgageFieldsConfig['mortgage.asset.isDeposited']?.required == UrlaRequiredState.Required && (asset.isDeposited == null || asset.isDeposited == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == UrlaRequiredState.Required && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
      );
      if (isError) {
        state = MenuItemStatus.Error;
        return state;
      }

      let isPending = assets.some(asset =>
        !asset.owningBorrowerIds || asset.owningBorrowerIds.length === 0 ||
        this._mortgageFieldsConfig['mortgage.asset.assetType']?.required == UrlaRequiredState.Requested && !(!!asset.assetType) ||
        (this._mortgageFieldsConfig['mortgage.asset.giftOrGrantSource']?.required == UrlaRequiredState.Requested && !(!!asset.giftOrGrantSource)) ||
        (this._mortgageFieldsConfig['mortgage.asset.isDeposited']?.required == UrlaRequiredState.Requested && (asset.isDeposited == null || asset.isDeposited == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == UrlaRequiredState.Requested && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
      );
      if (isPending) {
        state = MenuItemStatus.Pending;
      }
    }


    return state;
  }

  getStatusForDeclarations = (mortgage: UrlaMortgage): MenuItemStatus => {
    let state = MenuItemStatus.Success;

    let isPurchase = this._calculationService.isPurposeOfLoanPurchase(mortgage);
    if (mortgage.borrowers && mortgage.borrowers.length > 0) {
      mortgage.borrowers.forEach(borrower => {
        let decState = this.getStatusForBorrowerDeclarations(borrower, isPurchase);
        if (state == MenuItemStatus.Error) {
          return;
        }
        else if (decState == MenuItemStatus.Error || decState == MenuItemStatus.Pending) {
          state = decState;
          return;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerDeclarations = (borrower: MortgageBorrower, isPurchase: boolean) => {
    let state = MenuItemStatus.Success;
    if (
      this._mortgageFieldsConfig['mortgage.borrower.declarations.occupyProperty']?.required == UrlaRequiredState.Required && (borrower.declarations.occupyProperty == null || borrower.declarations.occupyProperty == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership']?.required == UrlaRequiredState.Required && borrower.declarations.occupyProperty && (borrower.declarations.havePropertiesOwnership == null || borrower.declarations.havePropertiesOwnership == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.typeOfProperty']?.required == UrlaRequiredState.Required && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.typeOfProperty) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.heldTitleHow']?.required == UrlaRequiredState.Required && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.heldTitleHow) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer']?.required == UrlaRequiredState.Required && borrower.declarations.occupyProperty && (borrower.declarations.isFirstTimeHomeBuyer == null || borrower.declarations.isFirstTimeHomeBuyer == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller']?.required == UrlaRequiredState.Required && isPurchase && (borrower.declarations.haveRelationshipWithSeller == null || borrower.declarations.haveRelationshipWithSeller == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment']?.required == UrlaRequiredState.Required && (borrower.declarations.borrowedDownPayment == null || borrower.declarations.borrowedDownPayment == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount']?.required == UrlaRequiredState.Required && (borrower.declarations.undisclosedMoneyAmount == null || borrower.declarations.undisclosedMoneyAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage']?.required == UrlaRequiredState.Required && (borrower.declarations.applyingForOtherMortgage == null || borrower.declarations.applyingForOtherMortgage == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit']?.required == UrlaRequiredState.Required && (borrower.declarations.applyingForNonMortgageCredit == null || borrower.declarations.applyingForNonMortgageCredit == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram']?.required == UrlaRequiredState.Required && (borrower.declarations.propertyHasLienWithCleanEnergyProgram == null || borrower.declarations.propertyHasLienWithCleanEnergyProgram == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.coSignedLoan']?.required == UrlaRequiredState.Required && (borrower.declarations.coSignedLoan == null || borrower.declarations.coSignedLoan == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveJudgement']?.required == UrlaRequiredState.Required && (borrower.declarations.haveJudgement == null || borrower.declarations.haveJudgement == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveTaxDebt']?.required == UrlaRequiredState.Required && (borrower.declarations.haveTaxDebt == null || borrower.declarations.haveTaxDebt == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveLawsuit']?.required == UrlaRequiredState.Required && (borrower.declarations.haveLawsuit == null || borrower.declarations.haveLawsuit == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure']?.required == UrlaRequiredState.Required && (borrower.declarations.haveConveyedTitleInLieuOfForeclosure == null || borrower.declarations.haveConveyedTitleInLieuOfForeclosure == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale']?.required == UrlaRequiredState.Required && (borrower.declarations.haveCompletedShortSale == null || borrower.declarations.haveCompletedShortSale == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.beenForclosed']?.required == UrlaRequiredState.Required && (borrower.declarations.beenForclosed == null || borrower.declarations.beenForclosed == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy']?.required == UrlaRequiredState.Required && (borrower.declarations.declaredBankruptcy == null || borrower.declarations.declaredBankruptcy == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.bankruptcyType']?.required == UrlaRequiredState.Required && borrower.declarations.declaredBankruptcy && !(!!borrower.declarations.bankruptcyType)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (borrower.declarations.explanations && borrower.declarations.explanations.length > 0) {
      let isError = borrower.declarations.explanations.some(explanation =>
      (this._mortgageFieldsConfig['mortgage.borrower.declarations.explanation.description']?.required == UrlaRequiredState.Required
        && !!explanation.explanationType
        && !(!!explanation.description))
      );
      if (isError) {
        state = MenuItemStatus.Error;
        return state;
      }
    }
    if (
      this._mortgageFieldsConfig['mortgage.borrower.declarations.occupyProperty']?.required == UrlaRequiredState.Requested && (borrower.declarations.occupyProperty == null || borrower.declarations.occupyProperty == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership']?.required == UrlaRequiredState.Requested && borrower.declarations.occupyProperty && (borrower.declarations.havePropertiesOwnership == null || borrower.declarations.havePropertiesOwnership == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.typeOfProperty']?.required == UrlaRequiredState.Requested && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.typeOfProperty) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.heldTitleHow']?.required == UrlaRequiredState.Requested && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.heldTitleHow) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer']?.required == UrlaRequiredState.Requested && borrower.declarations.occupyProperty && (borrower.declarations.isFirstTimeHomeBuyer == null || borrower.declarations.isFirstTimeHomeBuyer == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller']?.required == UrlaRequiredState.Requested && isPurchase && (borrower.declarations.haveRelationshipWithSeller == null || borrower.declarations.haveRelationshipWithSeller == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment']?.required == UrlaRequiredState.Requested && (borrower.declarations.borrowedDownPayment == null || borrower.declarations.borrowedDownPayment == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount']?.required == UrlaRequiredState.Requested && (borrower.declarations.undisclosedMoneyAmount == null || borrower.declarations.undisclosedMoneyAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage']?.required == UrlaRequiredState.Requested && (borrower.declarations.applyingForOtherMortgage == null || borrower.declarations.applyingForOtherMortgage == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit']?.required == UrlaRequiredState.Requested && (borrower.declarations.applyingForNonMortgageCredit == null || borrower.declarations.applyingForNonMortgageCredit == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram']?.required == UrlaRequiredState.Requested && (borrower.declarations.propertyHasLienWithCleanEnergyProgram == null || borrower.declarations.propertyHasLienWithCleanEnergyProgram == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.coSignedLoan']?.required == UrlaRequiredState.Requested && (borrower.declarations.coSignedLoan == null || borrower.declarations.coSignedLoan == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveJudgement']?.required == UrlaRequiredState.Requested && (borrower.declarations.haveJudgement == null || borrower.declarations.haveJudgement == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveTaxDebt']?.required == UrlaRequiredState.Requested && (borrower.declarations.haveTaxDebt == null || borrower.declarations.haveTaxDebt == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveLawsuit']?.required == UrlaRequiredState.Requested && (borrower.declarations.haveLawsuit == null || borrower.declarations.haveLawsuit == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure']?.required == UrlaRequiredState.Requested && (borrower.declarations.haveConveyedTitleInLieuOfForeclosure == null || borrower.declarations.haveConveyedTitleInLieuOfForeclosure == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale']?.required == UrlaRequiredState.Requested && (borrower.declarations.haveCompletedShortSale == null || borrower.declarations.haveCompletedShortSale == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.beenForclosed']?.required == UrlaRequiredState.Requested && (borrower.declarations.beenForclosed == null || borrower.declarations.beenForclosed == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy']?.required == UrlaRequiredState.Requested && (borrower.declarations.declaredBankruptcy == null || borrower.declarations.declaredBankruptcy == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.declarations.bankruptcyType']?.required == UrlaRequiredState.Requested && borrower.declarations.declaredBankruptcy && !(!!borrower.declarations.bankruptcyType)
    ) {
      state = MenuItemStatus.Pending;
    }
    if (borrower.declarations.explanations && borrower.declarations.explanations.length > 0) {
      let isPending = borrower.declarations.explanations.some(explanation =>
      (this._mortgageFieldsConfig['mortgage.borrower.declarations.explanation.description']?.required == UrlaRequiredState.Requested
        && !!explanation.explanationType
        && !(!!explanation.description))
      );
      if (isPending) {
        state = MenuItemStatus.Pending;
      }
    }

    return state;
  }

  getStatusForMilitaryService = (mortgage: UrlaMortgage): MenuItemStatus => {
    let isVaBorrower = mortgage.mortgageTerm.mortgageAppliedFor === MortgageAppliedForTypeEnum.VA;

    let state = MenuItemStatus.Success;

    if (mortgage.borrowers && mortgage.borrowers.length > 0) {
      mortgage.borrowers.forEach(borrower => {
        let militaryState = this.getStatusForBorrowerMilitaryService(borrower, isVaBorrower);
        if (state == MenuItemStatus.Error) {
          return;
        }
        else if (militaryState == MenuItemStatus.Error || militaryState == MenuItemStatus.Pending) {
          state = militaryState;
          return;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerMilitaryService = (borrower: MortgageBorrower, isVaBorrower: boolean) => {
    let state = MenuItemStatus.Success;

    if (
      this._mortgageFieldsConfig['mortgage.borrower.hasServedInMilitary']?.required == UrlaRequiredState.Required && (borrower.hasServedInMilitary == null || borrower.hasServedInMilitary == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.isFirstUseOfVaBenefit']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && (borrower.isFirstUseOfVaBenefit == null || borrower.isFirstUseOfVaBenefit == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.isVaFundingFeeExempt']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && (borrower.isVaFundingFeeExempt == null || borrower.isVaFundingFeeExempt == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.isSurvivingSpouseOfMilitary']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && (borrower.isSurvivingSpouseOfMilitary == null || borrower.isSurvivingSpouseOfMilitary == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryStatus']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && !(!!borrower.militaryStatus) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryStatusOtherDescription']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && borrower.militaryStatus === MilitaryStatus.Other && !(!!borrower.militaryStatusOtherDescription) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryTourStartDate']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && !(!!borrower.militaryTourStartDate) ||
      (borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && borrower.militaryTourStartDate && this.checkIsInvalidDate(borrower.militaryTourStartDate)) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryTourEndDate']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && !(!!borrower.militaryTourEndDate) ||
      (borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && borrower.militaryTourEndDate && this.checkIsInvalidDate(borrower.militaryTourEndDate)) ||
      this._mortgageFieldsConfig['mortgage.borrower.branchOfService']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && !(!!borrower.branchOfService) ||
      this._mortgageFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.branchOfServiceOtherDescription) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryServiceNumber']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.militaryServiceNumber) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryServedAsName']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.militaryServedAsName) ||
      this._mortgageFieldsConfig['mortgage.borrower.caivrsIdentifier']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && isVaBorrower && !(!!borrower.caivrsIdentifier) ||
      this._mortgageFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && isVaBorrower && (borrower.isVAPastCreditRecordSatisfactory == null || borrower.isVAPastCreditRecordSatisfactory == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasFiledVADisabilityClaim == null || borrower.hasFiledVADisabilityClaim == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasCurrentPreDischargeClaim == null || borrower.hasCurrentPreDischargeClaim == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasVABenefitRelatedIndebtedness == null || borrower.hasVABenefitRelatedIndebtedness == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaSocialSecurityTaxAmount == null || borrower.vaSocialSecurityTaxAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.vaFederalTaxAmount']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaFederalTaxAmount == null || borrower.vaFederalTaxAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.vaStateTaxAmount']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaStateTaxAmount == null || borrower.vaStateTaxAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.vaLocalTaxAmount']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaLocalTaxAmount == null || borrower.vaLocalTaxAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType']?.required == UrlaRequiredState.Required && borrower.hasServedInMilitary && isVaBorrower && !(!!borrower.vaBorrowerCertificationOccupancyType)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }

    if (
      this._mortgageFieldsConfig['mortgage.borrower.hasServedInMilitary']?.required == UrlaRequiredState.Requested && (borrower.hasServedInMilitary == null || borrower.hasServedInMilitary == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.isFirstUseOfVaBenefit']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && (borrower.isFirstUseOfVaBenefit == null || borrower.isFirstUseOfVaBenefit == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.isVaFundingFeeExempt']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && (borrower.isVaFundingFeeExempt == null || borrower.isVaFundingFeeExempt == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.isSurvivingSpouseOfMilitary']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && (borrower.isSurvivingSpouseOfMilitary == null || borrower.isSurvivingSpouseOfMilitary == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryStatus']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && !(!!borrower.militaryStatus) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryStatusOtherDescription']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && borrower.militaryStatus === MilitaryStatus.Other && !(!!borrower.militaryStatusOtherDescription) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryTourStartDate']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && !(!!borrower.militaryTourStartDate) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryTourEndDate']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && !(!!borrower.militaryTourEndDate) ||
      this._mortgageFieldsConfig['mortgage.borrower.branchOfService']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && !(!!borrower.branchOfService) ||
      this._mortgageFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.branchOfServiceOtherDescription) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryServiceNumber']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.militaryServiceNumber) ||
      this._mortgageFieldsConfig['mortgage.borrower.militaryServedAsName']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.militaryServedAsName) ||
      this._mortgageFieldsConfig['mortgage.borrower.caivrsIdentifier']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && isVaBorrower && !(!!borrower.caivrsIdentifier) ||
      this._mortgageFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && isVaBorrower && (borrower.isVAPastCreditRecordSatisfactory == null || borrower.isVAPastCreditRecordSatisfactory == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasFiledVADisabilityClaim == null || borrower.hasFiledVADisabilityClaim == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasCurrentPreDischargeClaim == null || borrower.hasCurrentPreDischargeClaim == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasVABenefitRelatedIndebtedness == null || borrower.hasVABenefitRelatedIndebtedness == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaSocialSecurityTaxAmount == null || borrower.vaSocialSecurityTaxAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.vaFederalTaxAmount']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaFederalTaxAmount == null || borrower.vaFederalTaxAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.vaStateTaxAmount']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaStateTaxAmount == null || borrower.vaStateTaxAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.vaLocalTaxAmount']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaLocalTaxAmount == null || borrower.vaLocalTaxAmount == undefined) ||
      this._mortgageFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType']?.required == UrlaRequiredState.Requested && borrower.hasServedInMilitary && isVaBorrower && !(!!borrower.vaBorrowerCertificationOccupancyType)
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForDemographics = (mortgage: UrlaMortgage): MenuItemStatus => {
    let state = MenuItemStatus.Success;

    if (mortgage.borrowers && mortgage.borrowers.length > 0) {
      mortgage.borrowers.forEach(borrower => {
        let demoState = this.getStatusForBorrowerDemographics(borrower);
        if (state == MenuItemStatus.Error) {
          return;
        }
        else if (demoState == MenuItemStatus.Error || demoState == MenuItemStatus.Pending) {
          state = demoState;
          return;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerDemographics = (borrower: MortgageBorrower) => {
    let state = MenuItemStatus.Success;

    if (
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity']?.required == UrlaRequiredState.Required && !(!!borrower.governmentMonitors.ethnicity)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOrigin']?.required == UrlaRequiredState.Required && (!!borrower.governmentMonitors.ethnicity) &&
        borrower.governmentMonitors.ethnicity.includes(this._ethnicityTypeHispanicOrLatino) && !(!!borrower.governmentMonitors.ethnicityOrigin)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription']?.required == UrlaRequiredState.Required && (!!borrower.governmentMonitors.ethnicity) &&
        borrower.governmentMonitors.ethnicity.includes(EthnicityType.Other) && !(!!borrower.governmentMonitors.ethnicityOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.sex']?.required == UrlaRequiredState.Required && !(!!borrower.governmentMonitors.sex)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.race']?.required == UrlaRequiredState.Required && !(!!borrower.governmentMonitors.race)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.nativeAmericanTribeName']?.required == UrlaRequiredState.Required && (!!borrower.governmentMonitors.race) &&
        borrower.governmentMonitors.race.includes(this._raceTypeAmericanIndianOrAlaskaNative) && !(!!borrower.governmentMonitors.nativeAmericanTribeName)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.raceDesignation']?.required == UrlaRequiredState.Required && (!!borrower.governmentMonitors.race) && !(!!borrower.governmentMonitors.raceDesignation)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod']?.required == UrlaRequiredState.Required && !(!!borrower.governmentMonitors.applicationTakenMethod)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname']?.required == UrlaRequiredState.Required && (borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == null || borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname']?.required == UrlaRequiredState.Required && (borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == null || borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname']?.required == UrlaRequiredState.Required && (borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == null || borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == undefined))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }

    if (
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity']?.required == UrlaRequiredState.Requested && !(!!borrower.governmentMonitors.ethnicity)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOrigin']?.required == UrlaRequiredState.Requested && (!!borrower.governmentMonitors.ethnicity) &&
        borrower.governmentMonitors.ethnicity.includes(this._ethnicityTypeHispanicOrLatino) && !(!!borrower.governmentMonitors.ethnicityOrigin)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription']?.required == UrlaRequiredState.Requested && (!!borrower.governmentMonitors.ethnicity) &&
        borrower.governmentMonitors.ethnicity.includes(EthnicityType.Other) && !(!!borrower.governmentMonitors.ethnicityOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.sex']?.required == UrlaRequiredState.Requested && !(!!borrower.governmentMonitors.sex)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.race']?.required == UrlaRequiredState.Requested && !(!!borrower.governmentMonitors.race)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.nativeAmericanTribeName']?.required == UrlaRequiredState.Requested && (!!borrower.governmentMonitors.race) &&
        borrower.governmentMonitors.race.includes(this._raceTypeAmericanIndianOrAlaskaNative) && !(!!borrower.governmentMonitors.nativeAmericanTribeName)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.raceDesignation']?.required == UrlaRequiredState.Requested && (!!borrower.governmentMonitors.race) && !(!!borrower.governmentMonitors.raceDesignation)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod']?.required == UrlaRequiredState.Requested && !(!!borrower.governmentMonitors.applicationTakenMethod)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname']?.required == UrlaRequiredState.Requested && (borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == null || borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname']?.required == UrlaRequiredState.Requested && (borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == null || borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname']?.required == UrlaRequiredState.Requested && (borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == null || borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == undefined))
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  private hasStateLicense(value: string) {
    return value !== 'tx';
  }

  getStatusForLoanOriginatorInfo = (mortgage: UrlaMortgage, isMersEnabled: boolean, isRequiredLoanAndCaseNumbers: boolean) => {
    let state = MenuItemStatus.Success;

    if (
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber']?.required == UrlaRequiredState.Required && isRequiredLoanAndCaseNumbers && !(!!mortgage.mortgageTerm.lenderCaseNumber)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.mersMortgageIdentifier']?.required == UrlaRequiredState.Required && isMersEnabled && !(!!mortgage.mortgageTerm.mersMortgageIdentifier)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.universalLoanNumber']?.required == UrlaRequiredState.Required && isRequiredLoanAndCaseNumbers && !(!!mortgage.mortgageTerm.universalLoanNumber)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber']?.required == UrlaRequiredState.Required && isRequiredLoanAndCaseNumbers && ['VA', 'FHA', 'USDA'].indexOf(mortgage.mortgageTerm?.mortgageAppliedFor) > -1 && !(!!mortgage.mortgageTerm.agencyCaseNumber)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.lenderLoanNumber)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.closingAgentOrderNumber']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.closingAgentOrderNumber)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationName']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.organizationName)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationNmls']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.organizationNmls)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationStateLicenseState']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.organizationStateLicenseState)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationStateLicense']?.required == UrlaRequiredState.Required && this.hasStateLicense(mortgage.originatorInformation.organizationStateLicenseState) && !(!!mortgage.originatorInformation.organizationStateLicense)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationVaLenderId']?.required == UrlaRequiredState.Required && this._calculationService.isVaLoan(mortgage) && !(!!mortgage.originatorInformation.organizationVaLenderId)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationVaSponsorId']?.required == UrlaRequiredState.Required && this._calculationService.isVaLoan(mortgage) && !(!!mortgage.originatorInformation.organizationVaSponsorId)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId']?.required == UrlaRequiredState.Required && this._calculationService.isFhaLoan(mortgage) && !(!!mortgage.originatorInformation.originatorFhaLenderId)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId']?.required == UrlaRequiredState.Required && this._calculationService.isFhaLoan(mortgage) && !(!!mortgage.originatorInformation.originatorFhaSponsorId)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationAddress1']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.organizationAddress1)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationAddress2']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.organizationAddress2)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationCity']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.organizationCity)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationState']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.organizationState)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationZipCode']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.organizationZipCode)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.underwriterId']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.underwriterId)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.name']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.name)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.nmls']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.nmls)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.stateLicense']?.required == UrlaRequiredState.Required && this.hasStateLicense(mortgage.originatorInformation.stateLicenseState) && !(!!mortgage.originatorInformation.stateLicense)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.stateLicenseState']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.stateLicenseState)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.applicationSignedDate']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.applicationSignedDate)) || (mortgage.originatorInformation.applicationSignedDate && this.checkIsInvalidDate(mortgage.originatorInformation.applicationSignedDate)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.email']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.email)) ||
      (mortgage.originatorInformation.email && this.checkIsInvalidEmail(mortgage.originatorInformation.email)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.phone']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.phone)) || (mortgage.originatorInformation.phone && this.checkIsInvalidPhoneNumber(mortgage.originatorInformation.phone)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.fax']?.required == UrlaRequiredState.Required && !(!!mortgage.originatorInformation.fax)) || (mortgage.originatorInformation.fax && this.checkIsInvalidPhoneNumber(mortgage.originatorInformation.fax))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber']?.required == UrlaRequiredState.Requested && isRequiredLoanAndCaseNumbers && !(!!mortgage.mortgageTerm.lenderCaseNumber)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.mersMortgageIdentifier']?.required == UrlaRequiredState.Requested && isMersEnabled && !(!!mortgage.mortgageTerm.mersMortgageIdentifier)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.universalLoanNumber']?.required == UrlaRequiredState.Requested && isRequiredLoanAndCaseNumbers && !(!!mortgage.mortgageTerm.universalLoanNumber)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber']?.required == UrlaRequiredState.Requested && isRequiredLoanAndCaseNumbers && ['VA', 'FHA', 'USDA'].indexOf(mortgage.mortgageTerm?.mortgageAppliedFor) > -1 && !(!!mortgage.mortgageTerm.agencyCaseNumber)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.lenderLoanNumber)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.closingAgentOrderNumber']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.closingAgentOrderNumber)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationName']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.organizationName)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationNmls']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.organizationNmls)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationStateLicenseState']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.organizationStateLicenseState)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationStateLicense']?.required == UrlaRequiredState.Requested && this.hasStateLicense(mortgage.originatorInformation.organizationStateLicenseState) && !(!!mortgage.originatorInformation.organizationStateLicense)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationVaLenderId']?.required == UrlaRequiredState.Requested && this._calculationService.isVaLoan(mortgage) && !(!!mortgage.originatorInformation.organizationVaLenderId)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationVaSponsorId']?.required == UrlaRequiredState.Requested && this._calculationService.isVaLoan(mortgage) && !(!!mortgage.originatorInformation.organizationVaSponsorId)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId']?.required == UrlaRequiredState.Requested && this._calculationService.isFhaLoan(mortgage) && !(!!mortgage.originatorInformation.originatorFhaLenderId)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId']?.required == UrlaRequiredState.Requested && this._calculationService.isFhaLoan(mortgage) && !(!!mortgage.originatorInformation.originatorFhaSponsorId)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationAddress1']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.organizationAddress1)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationAddress2']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.organizationAddress2)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationCity']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.organizationCity)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationState']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.organizationState)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.organizationZipCode']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.organizationZipCode)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.underwriterId']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.underwriterId)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.name']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.name)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.nmls']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.nmls)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.stateLicense']?.required == UrlaRequiredState.Requested && this.hasStateLicense(mortgage.originatorInformation.stateLicenseState) && !(!!mortgage.originatorInformation.stateLicense)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.stateLicenseState']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.stateLicenseState)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.applicationSignedDate']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.applicationSignedDate)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.email']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.email)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.phone']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.phone)) ||
      (this._mortgageFieldsConfig['mortgage.originatorInformation.fax']?.required == UrlaRequiredState.Requested && !(!!mortgage.originatorInformation.fax))
    ) {
      state = MenuItemStatus.Pending;
    }
    return state;
  }

  getStatusForBorrowingEntityInfo = (mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;
    if (!mortgage.borrowers.some(x => x.mortgagePartyType == "Entity"))
      return state;

    if (
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityName'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityName)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityType'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityType)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityTaxIdentifier'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityTaxIdentifier)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.filingLocation'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.filingLocation)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.stateOfOrganization'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.stateOfOrganization)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.dateOfOrgFiling'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.dateOfOrgFiling)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address1'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.address1)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address2'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.address2)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.city'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.city)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.state'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.state)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.zipCode'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.zipCode)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address1'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.address1)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address2'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.address2)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.city'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.city)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.state'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.state)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.zipCode'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.zipCode)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.signingPartyName'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.signingPartyName)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.signingPartyTitle'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.signingPartyTitle)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.member1.name'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.member1?.name)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.member1.ownershipPercent'] == UrlaRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.member1?.ownershipPercent))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityName'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityName)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityType'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityType)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityTaxIdentifier'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityTaxIdentifier)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.filingLocation'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.filingLocation)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.stateOfOrganization'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.stateOfOrganization)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.dateOfOrgFiling'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.dateOfOrgFiling)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address1'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.address1)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address2'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.address2)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.city'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.city)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.state'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.state)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.zipCode'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.zipCode)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address1'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.address1)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address2'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.address2)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.city'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.city)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.state'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.state)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.zipCode'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.zipCode)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.signingPartyName'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.signingPartyName)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.signingPartyTitle'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.signingPartyTitle)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.member1.name'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.member1?.name)) ||
      (this._mortgageFieldsConfig['mortgage.extension.borrowingEntity.member1.ownershipPercent'] == UrlaRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.member1?.ownershipPercent))
    ) {
      state = MenuItemStatus.Pending;
    }
    return state;
  }

  getStatusForPropertyLoanInfo = (mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    const constructionLikePurposes = [
      LoanPurposeTypeEnum.ConstructionOnly as string,
      LoanPurposeTypeEnum.ConstructionToPermanent as string,
    ];

    if (
      (this._mortgageFieldsConfig['mortgage.subjectProperty.refinanceProgram']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.refinanceProgram)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.attachmentType']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.attachmentType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == UrlaRequiredState.Required && this._calculationService.isPurposeOfLoanConstructionToPerm(mortgage) && !(!!mortgage.subjectProperty.constructionToPermanentClosingType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.isPlannedUnitDevelopment == null || mortgage.subjectProperty.isPlannedUnitDevelopment == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.isPropertyNotInAProject == null || mortgage.subjectProperty.isPropertyNotInAProject == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.projectType']?.required == UrlaRequiredState.Required && mortgage.subjectProperty.isPropertyNotInAProject == false && !(!!mortgage.subjectProperty.projectType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.projectDesignType']?.required == UrlaRequiredState.Required && mortgage.subjectProperty && (mortgage.subjectProperty.projectType == this._projectTypeCondominium) && !(!!mortgage.subjectProperty.projectDesignType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.loanUnderwritingSubmitterType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription']?.required == UrlaRequiredState.Required && mortgage.mortgageTerm.loanUnderwritingSubmitterType == 'Other' && !(!!mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths']?.required == UrlaRequiredState.Required && (mortgage.mortgageTerm.loanTermMaximumMonths == null || mortgage.mortgageTerm.loanTermMaximumMonths == undefined || mortgage.mortgageTerm.loanTermMaximumMonths.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.partialPaymentApplicationMethodType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.paymentFrequency']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.paymentFrequency)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.lienPosition']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.lienPosition)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription']?.required == UrlaRequiredState.Required && mortgage.mortgageTerm.lienPosition == 'Other' && !(!!mortgage.mortgageTerm.lienPositionOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate']?.required == UrlaRequiredState.Required && (mortgage.mortgageTerm.borrowerRequestedInterestRate == null || mortgage.mortgageTerm.borrowerRequestedInterestRate == undefined || mortgage.mortgageTerm.borrowerRequestedInterestRate.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount']?.required == UrlaRequiredState.Required && (mortgage.mortgageTerm.borrowerRequestedLoanAmount == null || mortgage.mortgageTerm.borrowerRequestedLoanAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.livingAreaSquareFeet == null || mortgage.subjectProperty.livingAreaSquareFeet == undefined || mortgage.subjectProperty.livingAreaSquareFeet.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.condominiumOrPudDeclaration)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.currentOccupancyType']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.currentOccupancyType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.currentOccupantName']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.currentOccupantName)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.currentOwnerType']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.currentOwnerType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.msaIdentifier']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.msaIdentifier)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.currentUsageType']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.currentUsageType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.currentUsageTypeOtherDescription']?.required == UrlaRequiredState.Required && mortgage.subjectProperty.currentUsageType == 'Other' && !(!!mortgage.subjectProperty.currentUsageTypeOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.bathroomCount']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.bathroomCount == null || mortgage.subjectProperty.bathroomCount == undefined || mortgage.subjectProperty.bathroomCount.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.bedroomCount']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.bedroomCount == null || mortgage.subjectProperty.bedroomCount == undefined || mortgage.subjectProperty.bedroomCount.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.livableRoomCount']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.livableRoomCount == null || mortgage.subjectProperty.livableRoomCount == undefined || mortgage.subjectProperty.livableRoomCount.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.creditorServicingOfLoanStatementType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription']?.required == UrlaRequiredState.Required && mortgage.mortgageTerm.creditorServicingOfLoanStatementType == 'Other' && !(!!mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.projectName']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.projectType || mortgage.subjectProperty.isPlannedUnitDevelopment) && !(!!mortgage.subjectProperty.projectName)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.projectClassificationIdentifier)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.condominiumProjectStatus)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.improvementCost']?.required == UrlaRequiredState.Required && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.improvementCost == null || mortgage.subjectProperty.improvementCost == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.lotAcquiredDate']?.required == UrlaRequiredState.Required && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.lotAcquiredDate)) ||
      (constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && mortgage.subjectProperty.lotAcquiredDate && this.checkIsInvalidDate(mortgage.subjectProperty.lotAcquiredDate)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.lotOriginalCost']?.required == UrlaRequiredState.Required && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.lotOriginalCost == null || mortgage.subjectProperty.lotOriginalCost == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.lotAppraisedValue']?.required == UrlaRequiredState.Required && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.lotAppraisedValue == null || mortgage.subjectProperty.lotAppraisedValue == undefined))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (this._mortgageFieldsConfig['mortgage.subjectProperty.refinanceProgram']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.refinanceProgram)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.attachmentType']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.attachmentType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == UrlaRequiredState.Requested && this._calculationService.isPurposeOfLoanConstructionToPerm(mortgage) && !(!!mortgage.subjectProperty.constructionToPermanentClosingType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.isPlannedUnitDevelopment == null || mortgage.subjectProperty.isPlannedUnitDevelopment == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.isPropertyNotInAProject == null || mortgage.subjectProperty.isPropertyNotInAProject == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.projectType']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty.isPropertyNotInAProject == false && !(!!mortgage.subjectProperty.projectType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.projectDesignType']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty && (mortgage.subjectProperty.projectType == this._projectTypeCondominium) && !(!!mortgage.subjectProperty.projectDesignType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.loanUnderwritingSubmitterType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.mortgageTerm.loanUnderwritingSubmitterType == 'Other' && !(!!mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths']?.required == UrlaRequiredState.Requested && (mortgage.mortgageTerm.loanTermMaximumMonths == null || mortgage.mortgageTerm.loanTermMaximumMonths == undefined || mortgage.mortgageTerm.loanTermMaximumMonths.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.partialPaymentApplicationMethodType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.paymentFrequency']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.paymentFrequency)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.lienPosition']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.lienPosition)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.mortgageTerm.lienPosition == 'Other' && !(!!mortgage.mortgageTerm.lienPositionOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate']?.required == UrlaRequiredState.Requested && (mortgage.mortgageTerm.borrowerRequestedInterestRate == null || mortgage.mortgageTerm.borrowerRequestedInterestRate == undefined || mortgage.mortgageTerm.borrowerRequestedInterestRate.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount']?.required == UrlaRequiredState.Requested && (mortgage.mortgageTerm.borrowerRequestedLoanAmount == null || mortgage.mortgageTerm.borrowerRequestedLoanAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.livingAreaSquareFeet == null || mortgage.subjectProperty.livingAreaSquareFeet == undefined || mortgage.subjectProperty.livingAreaSquareFeet.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.condominiumOrPudDeclaration)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.currentOccupancyType']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.currentOccupancyType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.currentOccupantName']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.currentOccupantName)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.currentOwnerType']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.currentOwnerType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.msaIdentifier']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.msaIdentifier)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.currentUsageType']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.currentUsageType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.currentUsageTypeOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty.currentUsageType == 'Other' && !(!!mortgage.subjectProperty.currentUsageTypeOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.bathroomCount']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.bathroomCount == null || mortgage.subjectProperty.bathroomCount == undefined || mortgage.subjectProperty.bathroomCount.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.bedroomCount']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.bedroomCount == null || mortgage.subjectProperty.bedroomCount == undefined || mortgage.subjectProperty.bedroomCount.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.livableRoomCount']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.livableRoomCount == null || mortgage.subjectProperty.livableRoomCount == undefined || mortgage.subjectProperty.livableRoomCount.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.creditorServicingOfLoanStatementType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.mortgageTerm.creditorServicingOfLoanStatementType == 'Other' && !(!!mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.projectName']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.projectType || mortgage.subjectProperty.isPlannedUnitDevelopment) && !(!!mortgage.subjectProperty.projectName)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.projectClassificationIdentifier)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.condominiumProjectStatus)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.improvementCost']?.required == UrlaRequiredState.Requested && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.improvementCost == null || mortgage.subjectProperty.improvementCost == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.lotAcquiredDate']?.required == UrlaRequiredState.Requested && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.lotAcquiredDate)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.lotOriginalCost']?.required == UrlaRequiredState.Requested && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.lotOriginalCost == null || mortgage.subjectProperty.lotOriginalCost == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.lotAppraisedValue']?.required == UrlaRequiredState.Requested && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.lotAppraisedValue == null || mortgage.subjectProperty.lotAppraisedValue == undefined))
    ) {
      state = MenuItemStatus.Pending;
    }
    return state;
  }

  getStatusForTitleInfo = (mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    if (
      (this._mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld']?.required == UrlaRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.mannerTitleCurrentlyHeld)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription']?.required == UrlaRequiredState.Required && mortgage.subjectProperty.mannerTitleCurrentlyHeld === PropertyTitleType.Other && !(!!mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.mannerTitleWillBeHeld)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription']?.required == UrlaRequiredState.Required && mortgage.subjectProperty.mannerTitleWillBeHeld === PropertyTitleType.Other && !(!!mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.estate']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.estate)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate']?.required == UrlaRequiredState.Required && mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && !(!!mortgage.subjectProperty.leaseHoldExpirationDate)) ||
      (mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && mortgage.subjectProperty.leaseHoldExpirationDate && this.checkIsInvalidDate(mortgage.subjectProperty.leaseHoldExpirationDate)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate']?.required == UrlaRequiredState.Required && mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && !(!!mortgage.subjectProperty.groundLeaseExpirationDate)) ||
      (mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && mortgage.subjectProperty.groundLeaseExpirationDate && this.checkIsInvalidDate(mortgage.subjectProperty.groundLeaseExpirationDate)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.nativeAmericanLandsType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription']?.required == UrlaRequiredState.Required && mortgage.subjectProperty.nativeAmericanLandsType === NativeAmericanLandsType.Other && !(!!mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.titlePreliminaryReportDate)) || (mortgage.subjectProperty.titlePreliminaryReportDate && this.checkIsInvalidDate(mortgage.subjectProperty.titlePreliminaryReportDate)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames']?.required == UrlaRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.titleCurrentlyHeldInNames)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.titleReportItemsDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.titleReportRequiredEndorsementsDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.trustClassification']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.trustClassification)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.isDeedRestricted']?.required == UrlaRequiredState.Required && (mortgage.subjectProperty.isDeedRestricted == null || mortgage.subjectProperty.isDeedRestricted == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths']?.required == UrlaRequiredState.Required && mortgage.subjectProperty.isDeedRestricted && (mortgage.subjectProperty.deedRestrictionTermMonths == null || mortgage.subjectProperty.deedRestrictionTermMonths == undefined || mortgage.subjectProperty.deedRestrictionTermMonths.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.recordingJurisdictionName)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.recordingJurisdictionType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription']?.required == UrlaRequiredState.Required && mortgage.subjectProperty.recordingJurisdictionType === RecordingJurisdictionType.Other && !(!!mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (this._mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld']?.required == UrlaRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.mannerTitleCurrentlyHeld)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty.mannerTitleCurrentlyHeld === PropertyTitleType.Other && !(!!mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.mannerTitleWillBeHeld)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty.mannerTitleWillBeHeld === PropertyTitleType.Other && !(!!mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.estate']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.estate)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && !(!!mortgage.subjectProperty.leaseHoldExpirationDate)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && !(!!mortgage.subjectProperty.groundLeaseExpirationDate)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.nativeAmericanLandsType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty.nativeAmericanLandsType === NativeAmericanLandsType.Other && !(!!mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.titlePreliminaryReportDate)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames']?.required == UrlaRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.titleCurrentlyHeldInNames)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.titleReportItemsDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.titleReportRequiredEndorsementsDescription)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.trustClassification']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.trustClassification)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.isDeedRestricted']?.required == UrlaRequiredState.Requested && (mortgage.subjectProperty.isDeedRestricted == null || mortgage.subjectProperty.isDeedRestricted == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty.isDeedRestricted && (mortgage.subjectProperty.deedRestrictionTermMonths == null || mortgage.subjectProperty.deedRestrictionTermMonths == undefined || mortgage.subjectProperty.deedRestrictionTermMonths.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.recordingJurisdictionName)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.recordingJurisdictionType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty.recordingJurisdictionType === RecordingJurisdictionType.Other && !(!!mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription))
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForMortgageLoanInfo = (mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    if (
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.mortgageAppliedFor)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.communityLendingProductType']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.communityLendingProductType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType']?.required == UrlaRequiredState.Required && !(!!mortgage.subjectProperty.communitySecondsRepaymentType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.interestRate']?.required == UrlaRequiredState.Required && (mortgage.mortgageTerm.interestRate == null || mortgage.mortgageTerm.interestRate == undefined || mortgage.mortgageTerm.interestRate.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.noOfMonths']?.required == UrlaRequiredState.Required && (mortgage.mortgageTerm.noOfMonths == null || mortgage.mortgageTerm.noOfMonths == undefined || mortgage.mortgageTerm.noOfMonths.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.lienPosition']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.lienPosition)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.amortizationType']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.amortizationType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.loanAmortizationPeriodType)) ||
      ((['FHA', 'VA', 'USDA'].includes(mortgage.mortgageTerm.mortgageAppliedFor)) && (
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.borrowerFundingFeePercent == null || mortgage.governmentLoanDetail.borrowerFundingFeePercent == undefined || mortgage.governmentLoanDetail.borrowerFundingFeePercent.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.refundProratedUnearnedUpFront == null || mortgage.governmentLoanDetail.refundProratedUnearnedUpFront == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.roundToNearest50Dollars == null || mortgage.governmentLoanDetail.roundToNearest50Dollars == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments == null || mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing == null || mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.lenderPaidMortgageInsurance == null || mortgage.governmentLoanDetail.lenderPaidMortgageInsurance == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory == null || mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.sectionOfActType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate)) || (mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate && this.checkIsInvalidDate(mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears == null || mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears == undefined || mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.applicationType']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.applicationType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhavaLoanPurposeType)))
      ) ||
      ((mortgage.mortgageTerm.mortgageAppliedFor === 'FHA') && (
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount == null || mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaAssignmentDate)) || (mortgage.governmentLoanDetail.fhaAssignmentDate && this.checkIsInvalidDate(mortgage.governmentLoanDetail.fhaAssignmentDate)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaEndorsementDate)) || (mortgage.governmentLoanDetail.fhaEndorsementDate && this.checkIsInvalidDate(mortgage.governmentLoanDetail.fhaEndorsementDate)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == null || mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaInsuranceProgramType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount == null || mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount == null || mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount == null || mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.fhaMIRenewalRatePercent == null || mortgage.governmentLoanDetail.fhaMIRenewalRatePercent == undefined || mortgage.governmentLoanDetail.fhaMIRenewalRatePercent.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription']?.required == UrlaRequiredState.Required && mortgage.governmentLoanDetail.energyRelatedImprovementType == 'Other' && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier)))
      ) ||
      ((mortgage.mortgageTerm.mortgageAppliedFor === 'VA') && (
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementAmount']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.vaEntitlementAmount == null || mortgage.governmentLoanDetail.vaEntitlementAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.vaResidualIncomeAmount == null || mortgage.governmentLoanDetail.vaResidualIncomeAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaEntitlementIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier']?.required == UrlaRequiredState.Required && mortgage.subjectProperty.subjectPropertyValuation?.hasVAStaffAppraisalReviewValuationAdjustment && !(!!mortgage.subjectProperty.subjectPropertyValuation?.vaStaffAppraisalReviewerIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vAStaffAppraisalReviewValueNotificationDate']?.required == UrlaRequiredState.Required && mortgage.subjectProperty.subjectPropertyValuation?.hasVAStaffAppraisalReviewValuationAdjustment && !(!!mortgage.subjectProperty.subjectPropertyValuation?.vaStaffAppraisalReviewValueNotificationDate)) ||
        (mortgage.subjectProperty.subjectPropertyValuation?.hasVAStaffAppraisalReviewValuationAdjustment && mortgage.subjectProperty.subjectPropertyValuation?.vaStaffAppraisalReviewValueNotificationDate && this.checkIsInvalidDate(mortgage.subjectProperty.subjectPropertyValuation?.vaStaffAppraisalReviewValueNotificationDate)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount == null || mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaAppraisalType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalTypeOtherDescription']?.required == UrlaRequiredState.Required && mortgage.governmentLoanDetail.vaAppraisalType == 'Other' && !(!!mortgage.governmentLoanDetail.vaAppraisalTypeOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaLoanProcedureType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureTypeOtherDescription']?.required == UrlaRequiredState.Required && mortgage.governmentLoanDetail.vaLoanProcedureType == 'Other' && !(!!mortgage.governmentLoanDetail.vaLoanProcedureTypeOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaTitleVestingType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingTypeOtherDescription']?.required == UrlaRequiredState.Required && mortgage.governmentLoanDetail.vaTitleVestingType == 'Other' && !(!!mortgage.governmentLoanDetail.vaTitleVestingTypeOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == null || mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription']?.required == UrlaRequiredState.Required && mortgage.governmentLoanDetail.energyRelatedImprovementType == 'Other' && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount == null || mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount == null || mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaExaminerComments)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaClaimFolderIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards']?.required == UrlaRequiredState.Required && (mortgage.governmentLoanDetail.loanMeetsVACreditStandards == null || mortgage.governmentLoanDetail.loanMeetsVACreditStandards == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks']?.required == UrlaRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks)))
      ) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest']?.required == UrlaRequiredState.Required && (mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest == null || mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance']?.required == UrlaRequiredState.Required && (mortgage.proposedHousingExpense.homeownersInsurance == null || mortgage.proposedHousingExpense.homeownersInsurance == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance']?.required == UrlaRequiredState.Required && (mortgage.proposedHousingExpense.supplementalPropertyInsurance == null || mortgage.proposedHousingExpense.supplementalPropertyInsurance == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.realEstateTax']?.required == UrlaRequiredState.Required && (mortgage.proposedHousingExpense.realEstateTax == null || mortgage.proposedHousingExpense.realEstateTax == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees']?.required == UrlaRequiredState.Required && (mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees == null || mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense']?.required == UrlaRequiredState.Required && (mortgage.proposedHousingExpense.otherHousingExpense == null || mortgage.proposedHousingExpense.otherHousingExpense == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpenseDescription']?.required == UrlaRequiredState.Required && mortgage.proposedHousingExpense.otherHousingExpense && !(!!mortgage.proposedHousingExpense.otherHousingExpenseDescription)) ||

      (this._mortgageFieldsConfig['mortgage.extension.balloonTermMonths']?.required == UrlaRequiredState.Required && mortgage.extension.isBalloonPaymentRequiredToPayOffLoan && (mortgage.extension.balloonTermMonths == null || mortgage.extension.balloonTermMonths == undefined || mortgage.extension.balloonTermMonths.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.extension.interestOnlyTermMonths']?.required == UrlaRequiredState.Required && mortgage.extension.isInterestOnly && (mortgage.extension.interestOnlyTermMonths == null || mortgage.extension.interestOnlyTermMonths == undefined || mortgage.extension.interestOnlyTermMonths.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.ausData.negativeAmortizationType']?.required == UrlaRequiredState.Required && mortgage.extension.hasNegativeAmortization && !(!!mortgage.ausData.negativeAmortizationType)) ||
      (this._mortgageFieldsConfig['mortgage.extension.buydownDetail.initialBuydownRate']?.required == UrlaRequiredState.Required && mortgage.extension.buydownDetail.hasBuydown && (mortgage.extension.buydownDetail.initialBuydownRate == null || mortgage.extension.buydownDetail.initialBuydownRate == undefined || mortgage.extension.buydownDetail.initialBuydownRate.toString() == '')) ||

      ((mortgage.mortgageTerm.amortizationType == this._amortizationTypeArmEnumValue) && (
        (this._mortgageFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap']?.required == UrlaRequiredState.Required && (mortgage.ausData.rateAdjustmentPeriodicCap == null || mortgage.ausData.rateAdjustmentPeriodicCap == undefined || mortgage.ausData.rateAdjustmentPeriodicCap.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths']?.required == UrlaRequiredState.Required && (mortgage.ausData.firstRateAdjustmentMonths == null || mortgage.ausData.firstRateAdjustmentMonths == undefined || mortgage.ausData.firstRateAdjustmentMonths.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap']?.required == UrlaRequiredState.Required && (mortgage.ausData.subsequentRateAdjustmentPeriodicCap == null || mortgage.ausData.subsequentRateAdjustmentPeriodicCap == undefined || mortgage.ausData.subsequentRateAdjustmentPeriodicCap.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths']?.required == UrlaRequiredState.Required && (mortgage.ausData.subsequentRateAdjustmentMonths == null || mortgage.ausData.subsequentRateAdjustmentMonths == undefined || mortgage.ausData.subsequentRateAdjustmentMonths.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap']?.required == UrlaRequiredState.Required && (mortgage.ausData.armRateAdjustmentLifetimeCap == null || mortgage.ausData.armRateAdjustmentLifetimeCap == undefined || mortgage.ausData.armRateAdjustmentLifetimeCap.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armIndexCurrent']?.required == UrlaRequiredState.Required && (mortgage.ausData.armIndexCurrent == null || mortgage.ausData.armIndexCurrent == undefined || mortgage.ausData.armIndexCurrent.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armMarginRate']?.required == UrlaRequiredState.Required && (mortgage.ausData.armMarginRate == null || mortgage.ausData.armMarginRate == undefined || mortgage.ausData.armMarginRate.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armFullyIndexedRate']?.required == UrlaRequiredState.Required && (mortgage.ausData.armFullyIndexedRate == null || mortgage.ausData.armFullyIndexedRate == undefined || mortgage.ausData.armFullyIndexedRate.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armIndexSourceType']?.required == UrlaRequiredState.Required && (mortgage.ausData.armIndexSourceType == null || mortgage.ausData.armIndexSourceType == undefined || mortgage.ausData.armIndexSourceType.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armFloorRate']?.required == UrlaRequiredState.Required && (mortgage.ausData.armFloorRate == null || mortgage.ausData.armFloorRate == undefined || mortgage.ausData.armFloorRate.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths']?.required == UrlaRequiredState.Required && (mortgage.ausData.firstPaymentAdjustmentMonths == null || mortgage.ausData.firstPaymentAdjustmentMonths == undefined || mortgage.ausData.firstPaymentAdjustmentMonths.toString() == ''))

      ))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }

    if (
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.mortgageAppliedFor)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.communityLendingProductType']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.communityLendingProductType)) ||
      (this._mortgageFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType']?.required == UrlaRequiredState.Requested && !(!!mortgage.subjectProperty.communitySecondsRepaymentType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.interestRate']?.required == UrlaRequiredState.Requested && (mortgage.mortgageTerm.interestRate == null || mortgage.mortgageTerm.interestRate == undefined || mortgage.mortgageTerm.interestRate.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.noOfMonths']?.required == UrlaRequiredState.Requested && (mortgage.mortgageTerm.noOfMonths == null || mortgage.mortgageTerm.noOfMonths == undefined || mortgage.mortgageTerm.noOfMonths.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.lienPosition']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.lienPosition)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.amortizationType']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.amortizationType)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.loanAmortizationPeriodType)) ||
      ((['FHA', 'VA', 'USDA'].includes(mortgage.mortgageTerm.mortgageAppliedFor)) && (
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.borrowerFundingFeePercent == null || mortgage.governmentLoanDetail.borrowerFundingFeePercent == undefined || mortgage.governmentLoanDetail.borrowerFundingFeePercent.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.refundProratedUnearnedUpFront == null || mortgage.governmentLoanDetail.refundProratedUnearnedUpFront == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.roundToNearest50Dollars == null || mortgage.governmentLoanDetail.roundToNearest50Dollars == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments == null || mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing == null || mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.lenderPaidMortgageInsurance == null || mortgage.governmentLoanDetail.lenderPaidMortgageInsurance == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory == null || mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.sectionOfActType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears == null || mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears == undefined || mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.applicationType']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.applicationType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhavaLoanPurposeType)))
      ) ||
      ((mortgage.mortgageTerm.mortgageAppliedFor === 'FHA') && (
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount == null || mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaAssignmentDate)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaEndorsementDate)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == null || mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaInsuranceProgramType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount == null || mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount == null || mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount == null || mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.fhaMIRenewalRatePercent == null || mortgage.governmentLoanDetail.fhaMIRenewalRatePercent == undefined || mortgage.governmentLoanDetail.fhaMIRenewalRatePercent.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.governmentLoanDetail.energyRelatedImprovementType == 'Other' && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier)))
      ) ||
      ((mortgage.mortgageTerm.mortgageAppliedFor === 'VA') && (
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementAmount']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.vaEntitlementAmount == null || mortgage.governmentLoanDetail.vaEntitlementAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.vaResidualIncomeAmount == null || mortgage.governmentLoanDetail.vaResidualIncomeAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaEntitlementIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment && !(!!mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vAStaffAppraisalReviewValueNotificationDate']?.required == UrlaRequiredState.Requested && mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment && !(!!mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount == null || mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaAppraisalType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalTypeOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.governmentLoanDetail.vaAppraisalType == 'Other' && !(!!mortgage.governmentLoanDetail.vaAppraisalTypeOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaLoanProcedureType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureTypeOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.governmentLoanDetail.vaLoanProcedureType == 'Other' && !(!!mortgage.governmentLoanDetail.vaLoanProcedureTypeOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaTitleVestingType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingTypeOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.governmentLoanDetail.vaTitleVestingType == 'Other' && !(!!mortgage.governmentLoanDetail.vaTitleVestingTypeOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == null || mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementType)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription']?.required == UrlaRequiredState.Requested && mortgage.governmentLoanDetail.energyRelatedImprovementType == 'Other' && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount == null || mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount == null || mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaExaminerComments)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaClaimFolderIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards']?.required == UrlaRequiredState.Requested && (mortgage.governmentLoanDetail.loanMeetsVACreditStandards == null || mortgage.governmentLoanDetail.loanMeetsVACreditStandards == undefined)) ||
        (this._mortgageFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks']?.required == UrlaRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks)))
      ) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest']?.required == UrlaRequiredState.Requested && (mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest == null || mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance']?.required == UrlaRequiredState.Requested && (mortgage.proposedHousingExpense.homeownersInsurance == null || mortgage.proposedHousingExpense.homeownersInsurance == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance']?.required == UrlaRequiredState.Requested && (mortgage.proposedHousingExpense.supplementalPropertyInsurance == null || mortgage.proposedHousingExpense.supplementalPropertyInsurance == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.realEstateTax']?.required == UrlaRequiredState.Requested && (mortgage.proposedHousingExpense.realEstateTax == null || mortgage.proposedHousingExpense.realEstateTax == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees']?.required == UrlaRequiredState.Requested && (mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees == null || mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense']?.required == UrlaRequiredState.Requested && (mortgage.proposedHousingExpense.otherHousingExpense == null || mortgage.proposedHousingExpense.otherHousingExpense == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpenseDescription']?.required == UrlaRequiredState.Requested && mortgage.proposedHousingExpense.otherHousingExpense && !(!!mortgage.proposedHousingExpense.otherHousingExpenseDescription)) ||

      (this._mortgageFieldsConfig['mortgage.extension.balloonTermMonths']?.required == UrlaRequiredState.Requested && mortgage.extension.isBalloonPaymentRequiredToPayOffLoan && (mortgage.extension.balloonTermMonths == null || mortgage.extension.balloonTermMonths == undefined || mortgage.extension.balloonTermMonths.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.extension.interestOnlyTermMonths']?.required == UrlaRequiredState.Requested && mortgage.extension.isInterestOnly && (mortgage.extension.interestOnlyTermMonths == null || mortgage.extension.interestOnlyTermMonths == undefined || mortgage.extension.interestOnlyTermMonths.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.ausData.negativeAmortizationType']?.required == UrlaRequiredState.Requested && mortgage.extension.hasNegativeAmortization && !(!!mortgage.ausData.negativeAmortizationType)) ||
      (this._mortgageFieldsConfig['mortgage.extension.buydownDetail.initialBuydownRate']?.required == UrlaRequiredState.Requested && mortgage.extension.buydownDetail.hasBuydown && (mortgage.extension.buydownDetail.initialBuydownRate == null || mortgage.extension.buydownDetail.initialBuydownRate == undefined || mortgage.extension.buydownDetail.initialBuydownRate.toString() == '')) ||

      ((mortgage.mortgageTerm.amortizationType == this._amortizationTypeArmEnumValue) && (
        (this._mortgageFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap']?.required == UrlaRequiredState.Requested && (mortgage.ausData.rateAdjustmentPeriodicCap == null || mortgage.ausData.rateAdjustmentPeriodicCap == undefined || mortgage.ausData.rateAdjustmentPeriodicCap.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths']?.required == UrlaRequiredState.Requested && (mortgage.ausData.firstRateAdjustmentMonths == null || mortgage.ausData.firstRateAdjustmentMonths == undefined || mortgage.ausData.firstRateAdjustmentMonths.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap']?.required == UrlaRequiredState.Requested && (mortgage.ausData.subsequentRateAdjustmentPeriodicCap == null || mortgage.ausData.subsequentRateAdjustmentPeriodicCap == undefined || mortgage.ausData.subsequentRateAdjustmentPeriodicCap.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths']?.required == UrlaRequiredState.Requested && (mortgage.ausData.subsequentRateAdjustmentMonths == null || mortgage.ausData.subsequentRateAdjustmentMonths == undefined || mortgage.ausData.subsequentRateAdjustmentMonths.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap']?.required == UrlaRequiredState.Requested && (mortgage.ausData.armRateAdjustmentLifetimeCap == null || mortgage.ausData.armRateAdjustmentLifetimeCap == undefined || mortgage.ausData.armRateAdjustmentLifetimeCap.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armIndexCurrent']?.required == UrlaRequiredState.Requested && (mortgage.ausData.armIndexCurrent == null || mortgage.ausData.armIndexCurrent == undefined || mortgage.ausData.armIndexCurrent.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armMarginRate']?.required == UrlaRequiredState.Requested && (mortgage.ausData.armMarginRate == null || mortgage.ausData.armMarginRate == undefined || mortgage.ausData.armMarginRate.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armFullyIndexedRate']?.required == UrlaRequiredState.Requested && (mortgage.ausData.armFullyIndexedRate == null || mortgage.ausData.armFullyIndexedRate == undefined || mortgage.ausData.armFullyIndexedRate.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armIndexSourceType']?.required == UrlaRequiredState.Requested && (mortgage.ausData.armIndexSourceType == null || mortgage.ausData.armIndexSourceType == undefined || mortgage.ausData.armIndexSourceType.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.armFloorRate']?.required == UrlaRequiredState.Requested && (mortgage.ausData.armFloorRate == null || mortgage.ausData.armFloorRate == undefined || mortgage.ausData.armFloorRate.toString() == '')) ||
        (this._mortgageFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths']?.required == UrlaRequiredState.Requested && (mortgage.ausData.firstPaymentAdjustmentMonths == null || mortgage.ausData.firstPaymentAdjustmentMonths == undefined || mortgage.ausData.firstPaymentAdjustmentMonths.toString() == ''))

      ))
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForQualifyingTheBorr = (mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    if (
      (this._mortgageFieldsConfig['mortgage.transactionDetail.purchasePriceAmount']?.required == UrlaRequiredState.Required && !this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered']?.required == UrlaRequiredState.Required && (mortgage.extension.additionalBorrowerAssetsAreConsidered == null || mortgage.extension.additionalBorrowerAssetsAreConsidered == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered']?.required == UrlaRequiredState.Required && (mortgage.extension.additionalBorrowerAssetsAreConsidered == null || mortgage.extension.additionalBorrowerAssetsAreConsidered == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered']?.required == UrlaRequiredState.Required && (mortgage.extension.additionalBorrowerAssetsAreNotConsidered == null || mortgage.extension.additionalBorrowerAssetsAreNotConsidered == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount']?.required == UrlaRequiredState.Required && (mortgage.transactionDetail.otherPartyPaidClosingCostsAmount == null || mortgage.transactionDetail.otherPartyPaidClosingCostsAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount']?.required == UrlaRequiredState.Required && (mortgage.transactionDetail.personalPropertyInSalesContractAmount == null || mortgage.transactionDetail.personalPropertyInSalesContractAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.salesContractDate']?.required == UrlaRequiredState.Required && !(!!mortgage.transactionDetail.salesContractDate)) || (mortgage.transactionDetail.salesContractDate && this.checkIsInvalidDate(mortgage.transactionDetail.salesContractDate)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount']?.required == UrlaRequiredState.Required && (mortgage.transactionDetail.cashToSellerAtClosingAmount == null || mortgage.transactionDetail.cashToSellerAtClosingAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration']?.required == UrlaRequiredState.Required && !(!!mortgage.transactionDetail.estimatedClosingCostsExpiration)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount']?.required == UrlaRequiredState.Required && (mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount == null || mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount == undefined || mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.extension.hmdaPreapprovalType']?.required == UrlaRequiredState.Required && !(!!mortgage.extension.hmdaPreapprovalType)) ||
      (this._mortgageFieldsConfig['mortgage.extension.relatedInvestorLoanId']?.required == UrlaRequiredState.Required && !(!!mortgage.extension.relatedInvestorLoanId)) ||
      (this._mortgageFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram']?.required == UrlaRequiredState.Required && !(!!mortgage.ausData.freddieMacRefinanceProgram)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten']?.required == UrlaRequiredState.Required && (mortgage.mortgageTerm.isLoanManuallyUnderwritten == null || mortgage.mortgageTerm.isLoanManuallyUnderwritten == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.underwritingComments']?.required == UrlaRequiredState.Required && !(!!mortgage.mortgageTerm.underwritingComments))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }

    if (
      (this._mortgageFieldsConfig['mortgage.transactionDetail.purchasePriceAmount']?.required == UrlaRequiredState.Requested && !this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered']?.required == UrlaRequiredState.Requested && (mortgage.extension.additionalBorrowerAssetsAreConsidered == null || mortgage.extension.additionalBorrowerAssetsAreConsidered == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered']?.required == UrlaRequiredState.Requested && (mortgage.extension.additionalBorrowerAssetsAreNotConsidered == null || mortgage.extension.additionalBorrowerAssetsAreNotConsidered == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount']?.required == UrlaRequiredState.Requested && (mortgage.transactionDetail.otherPartyPaidClosingCostsAmount == null || mortgage.transactionDetail.otherPartyPaidClosingCostsAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount']?.required == UrlaRequiredState.Requested && (mortgage.transactionDetail.personalPropertyInSalesContractAmount == null || mortgage.transactionDetail.personalPropertyInSalesContractAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.salesContractDate']?.required == UrlaRequiredState.Requested && !(!!mortgage.transactionDetail.salesContractDate)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount']?.required == UrlaRequiredState.Requested && (mortgage.transactionDetail.cashToSellerAtClosingAmount == null || mortgage.transactionDetail.cashToSellerAtClosingAmount == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration']?.required == UrlaRequiredState.Requested && !(!!mortgage.transactionDetail.estimatedClosingCostsExpiration)) ||
      (this._mortgageFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount']?.required == UrlaRequiredState.Requested && (mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount == null || mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount == undefined || mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount.toString() == '')) ||
      (this._mortgageFieldsConfig['mortgage.extension.hmdaPreapprovalType']?.required == UrlaRequiredState.Requested && !(!!mortgage.extension.hmdaPreapprovalType)) ||
      (this._mortgageFieldsConfig['mortgage.extension.relatedInvestorLoanId']?.required == UrlaRequiredState.Requested && !(!!mortgage.extension.relatedInvestorLoanId)) ||
      (this._mortgageFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram']?.required == UrlaRequiredState.Requested && !(!!mortgage.ausData.freddieMacRefinanceProgram)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten']?.required == UrlaRequiredState.Requested && (mortgage.mortgageTerm.isLoanManuallyUnderwritten == null || mortgage.mortgageTerm.isLoanManuallyUnderwritten == undefined)) ||
      (this._mortgageFieldsConfig['mortgage.mortgageTerm.underwritingComments']?.required == UrlaRequiredState.Requested && !(!!mortgage.mortgageTerm.underwritingComments))
    ) {
      state = MenuItemStatus.Pending;
    }

    if (mortgage.ausData.ausResults && mortgage.ausData.ausResults.length > 0) {
      let isError = mortgage.ausData.ausResults.some(ausResult =>
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.caseIdentifier']?.required == UrlaRequiredState.Required && !(!!ausResult.caseIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.ausSystem']?.required == UrlaRequiredState.Required && !(!!ausResult.ausSystem)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription']?.required == UrlaRequiredState.Required && ausResult.ausSystem == 'Other' && !(!!ausResult.ausSystemOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.decisionDateTime']?.required == UrlaRequiredState.Required && !(!!ausResult.decisionDateTime)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.ausResultValue']?.required == UrlaRequiredState.Required && !(!!ausResult.ausResultValue)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.recommendationDescription']?.required == UrlaRequiredState.Required && !(!!ausResult.recommendationDescription)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver']?.required == UrlaRequiredState.Required && (ausResult.includesDocumentWaiver == null || ausResult.includesDocumentWaiver == undefined))
      );
      if (isError) {
        state = MenuItemStatus.Error;
        return state;
      }
      let isPending = mortgage.ausData.ausResults.some(ausResult =>
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.caseIdentifier']?.required == UrlaRequiredState.Requested && !(!!ausResult.caseIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.ausSystem']?.required == UrlaRequiredState.Requested && !(!!ausResult.ausSystem)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription']?.required == UrlaRequiredState.Requested && ausResult.ausSystem == 'Other' && !(!!ausResult.ausSystemOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.decisionDateTime']?.required == UrlaRequiredState.Requested && !(!!ausResult.decisionDateTime)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.ausResultValue']?.required == UrlaRequiredState.Requested && !(!!ausResult.ausResultValue)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.recommendationDescription']?.required == UrlaRequiredState.Requested && !(!!ausResult.recommendationDescription)) ||
        (this._mortgageFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver']?.required == UrlaRequiredState.Requested && (ausResult.includesDocumentWaiver == null || ausResult.includesDocumentWaiver == undefined))
      );
      if (isPending) {
        state = MenuItemStatus.Pending;
      }
    }
    return state;
  }

  getStatusForHomeownershipEducation = (mortgage: UrlaMortgage): MenuItemStatus => {
    let state = MenuItemStatus.Success;

    if (mortgage.borrowers && mortgage.borrowers.length > 0) {
      mortgage.borrowers.forEach(borrower => {
        let homeEduState = this.getStatusForBorrowerHomeownershipEducation(borrower);
        if (state == MenuItemStatus.Error) {
        }
        else if (homeEduState == MenuItemStatus.Error || homeEduState == MenuItemStatus.Pending) {
          state = homeEduState;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerHomeownershipEducation = (borrower: MortgageBorrower) => {
    let state = MenuItemStatus.Success;

    if (borrower.counselingEvents && borrower.counselingEvents.length > 0) {
      let isError = borrower.counselingEvents.some(counselingEvent =>
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.completedDate']?.required == UrlaRequiredState.Required && !(!!counselingEvent.completedDate)) || (counselingEvent.completedDate && this.checkIsInvalidDate(counselingEvent.completedDate)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.format']?.required == UrlaRequiredState.Required && !(!!counselingEvent.format)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription']?.required == UrlaRequiredState.Required && counselingEvent.format == 'Other' && !(!!counselingEvent.formatOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier']?.required == UrlaRequiredState.Required && !(!!counselingEvent.counselingAgencyIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.agencyName']?.required == UrlaRequiredState.Required && !(!!counselingEvent.counselingAgencyName)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1']?.required == UrlaRequiredState.Required && !(!!counselingEvent.counselingAgencyAddress1)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2']?.required == UrlaRequiredState.Required && !(!!counselingEvent.counselingAgencyAddress2)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity']?.required == UrlaRequiredState.Required && !(!!counselingEvent.counselingAgencyCity)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState']?.required == UrlaRequiredState.Required && !(!!counselingEvent.counselingAgencyState)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode']?.required == UrlaRequiredState.Required && !(!!counselingEvent.counselingAgencyZipCode))
      );
      if (isError) {
        state = MenuItemStatus.Error;
        return state;
      }
      let isPending = borrower.counselingEvents.some(counselingEvent =>
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.completedDate']?.required == UrlaRequiredState.Requested && !(!!counselingEvent.completedDate)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.format']?.required == UrlaRequiredState.Requested && !(!!counselingEvent.format)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription']?.required == UrlaRequiredState.Requested && counselingEvent.format == 'Other' && !(!!counselingEvent.formatOtherDescription)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier']?.required == UrlaRequiredState.Requested && !(!!counselingEvent.counselingAgencyIdentifier)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.agencyName']?.required == UrlaRequiredState.Requested && !(!!counselingEvent.counselingAgencyName)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1']?.required == UrlaRequiredState.Requested && !(!!counselingEvent.counselingAgencyAddress1)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2']?.required == UrlaRequiredState.Requested && !(!!counselingEvent.counselingAgencyAddress2)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity']?.required == UrlaRequiredState.Requested && !(!!counselingEvent.counselingAgencyCity)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState']?.required == UrlaRequiredState.Requested && !(!!counselingEvent.counselingAgencyState)) ||
        (this._mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode']?.required == UrlaRequiredState.Requested && !(!!counselingEvent.counselingAgencyZipCode))
      );
      if (isPending) {
        state = MenuItemStatus.Pending;
      }
    }
    return state;
  }

  getStatusForUrla = (mortgage: UrlaMortgage, isMersEnabled?: boolean, isRequiredLoanAndCaseNumbers?: boolean, isTpo: boolean = false) => {
    let borrowerInfoStatus = this.getStatusForBorrowersInfo(mortgage, isTpo);
    let financialInfoStatus = this.getStatusForFinancialInfo(mortgage);
    let reoStatus = this.getStatusForReo(mortgage);
    let loanPropertyInfoStatus = this.getStatusForLoanProperty(mortgage, false);
    let declarationsStatus = this.getStatusForDeclarations(mortgage);
    let militaryStatus = this.getStatusForMilitaryService(mortgage);
    let demographicsStatus = this.getStatusForDemographics(mortgage);
    let loanOriginatorInfoStatus = this.getStatusForLoanOriginatorInfo(mortgage, isMersEnabled || false, isRequiredLoanAndCaseNumbers || true);
    let propertyLoanInfoStatus = this.getStatusForPropertyLoanInfo(mortgage);
    let titleInfoStatus = this.getStatusForTitleInfo(mortgage);
    let mortgageLoanInfoStatus = this.getStatusForMortgageLoanInfo(mortgage);
    let qualifyingTheBorrStatus = this.getStatusForQualifyingTheBorr(mortgage);
    let homeownershipEducationStatus = this.getStatusForHomeownershipEducation(mortgage);

    if (borrowerInfoStatus == MenuItemStatus.Error || financialInfoStatus == MenuItemStatus.Error || reoStatus == MenuItemStatus.Error ||
      loanPropertyInfoStatus == MenuItemStatus.Error || declarationsStatus == MenuItemStatus.Error || militaryStatus == MenuItemStatus.Error ||
      demographicsStatus == MenuItemStatus.Error || loanOriginatorInfoStatus == MenuItemStatus.Error || propertyLoanInfoStatus == MenuItemStatus.Error ||
      titleInfoStatus == MenuItemStatus.Error || mortgageLoanInfoStatus == MenuItemStatus.Error || qualifyingTheBorrStatus == MenuItemStatus.Error ||
      homeownershipEducationStatus == MenuItemStatus.Error) {

      return MenuItemStatus.Error;
    }

    else if (borrowerInfoStatus == MenuItemStatus.Pending || financialInfoStatus == MenuItemStatus.Pending || reoStatus == MenuItemStatus.Pending ||
      loanPropertyInfoStatus == MenuItemStatus.Pending || declarationsStatus == MenuItemStatus.Pending || militaryStatus == MenuItemStatus.Pending ||
      demographicsStatus == MenuItemStatus.Pending || loanOriginatorInfoStatus == MenuItemStatus.Pending || propertyLoanInfoStatus == MenuItemStatus.Pending ||
      titleInfoStatus == MenuItemStatus.Pending || mortgageLoanInfoStatus == MenuItemStatus.Pending || qualifyingTheBorrStatus == MenuItemStatus.Pending ||
      homeownershipEducationStatus == MenuItemStatus.Pending) {

      return MenuItemStatus.Pending;
    }

    else {
      return MenuItemStatus.Success;
    }

  }

  checkIsInvalidPhoneNumber = (phoneNumber: string) => {
    let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return !re.test(phoneNumber);
  }


  checkIsInvalidEmail = (email: string) => {
    return !Constants.regexPatterns.email.test(email);
  }

  checkIsInvalidSsn = (ssn: string) => {
    return !Constants.regexPatterns.ssn.test(ssn);
  }

  checkIsInvalidDate = (date, maxDate?, minDate?) => {
    let invalidDate = false;
    if (date.length === 10) {
      invalidDate = this.validateFullDate(date);
      if (invalidDate) {
        return true;
      }
      invalidDate = this.validateDay(date);
      if (invalidDate) {
        return true;
      }
      invalidDate = this.validateMonth(date);
      if (invalidDate) {
        return true;
      }
      invalidDate = this.validateMax(date, maxDate);
      if (invalidDate) {
        return true;
      }
      invalidDate = this.validateMin(date, minDate);
      if (invalidDate) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  }

  private validateDay = (value): boolean => {
    const invalidDate = value.split('/')[1] > 31;
    return invalidDate;
  }

  private validateMonth = (value): boolean => {
    const invalidDate = value.split('/')[0] > 12;
    return invalidDate;
  }

  private validateFullDate = (value): boolean => {
    const invalidDate = this.isValidAsFullDate(value);
    return invalidDate;
  }

  private isValidAsFullDate(value) {
    const maxDays = getDaysForMonth(value.split('/')[0], value.split('/')[2]);
    const areDaysValid = value.split('/')[1] <= maxDays;
    return !Constants.regexPatterns.date.test(value) || !areDaysValid;
  }

  private validateMax(value, maxDateInput) {
    const date = DateTime.fromJSDate(new Date(value));
    const maxDate = Constants.regexPatterns.date.test(maxDateInput as any) ? DateTime.fromJSDate(new Date(maxDateInput)) : DateTime.fromJSDate(maxDateInput);
    const invalidDate = date > maxDate;
    return invalidDate;
  }

  private validateMin(value, minDateInput) {
    const date = DateTime.fromJSDate(new Date(value));
    const minDate = Constants.regexPatterns.date.test(minDateInput as any) ? DateTime.fromJSDate(new Date(minDateInput)) : DateTime.fromJSDate(minDateInput);
    const invalidDate = date < minDate;
    return invalidDate;
  }

}
