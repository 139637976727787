import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { finalize } from 'rxjs';
import { TaskMessage } from 'src/app/models';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import * as _ from 'lodash';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { Utils } from 'src/app/core/services/utils';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'task-note-display',
  templateUrl: 'task-note-display.component.html'
})

export class TaskNoteDisplayComponent implements OnInit {

  @Input()
  task: LoanDocDashboardTask;

  @Output()
  hover: EventEmitter<any> = new EventEmitter();

  noteImageProcessStatuses: Map<string, TaskImageProcessStatus> = new Map<string, TaskImageProcessStatus>();

  note: TaskMessage = new TaskMessage();

  internalNotes: TaskMessage[] = null;

  constructor(private readonly _messageService: MessageService,
    private readonly _utilityService: UtilityService,
    private readonly _notifyService: NotificationService) { }

  ngOnInit() {
    this.note['uniqueId'] = uuidv4();
    this.note.content = this.task.note;
    let originalNote = _.cloneDeep(this.note);
    this.note.content = MentionsUtils.generateDisplayHtmlWithMentions(this.note.content);

    const notesImgCount = this.replaceImagesWithLoaderIcons(this.note);

    const taskImageProcessingStatus: TaskImageProcessStatus = { numberOfImagesLeftToProcess: notesImgCount, processedContent: this.note.content }
    this.noteImageProcessStatuses.set(this.note['uniqueId'], taskImageProcessingStatus);

    this.generateDisplayHtmlWithImages(originalNote.content, originalNote);
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    Utils.viewImage(event.target.currentSrc);
  }

  onNoteHover = (row: LoanDocDashboardTask) => {

    if (this.internalNotes) {
      this.hover.emit();
      return; // load once
    }

    row['loadingNotes'] = true;
    this._messageService.getInternalTaskMessages(row.loanDocTaskId)
      .pipe(finalize(() => row['loadingNotes'] = false))
      .subscribe({
        next: (internalNotes) => {
          this.internalNotes = internalNotes;
          this.prepareNotesForDisplay(internalNotes);
          this.hover.emit();
        },
        error: (error) => {
          this._notifyService.showError(error?.message || "Couldnt load task notes", "Error");
        }
      })
  }

  private prepareNotesForDisplay = (internalNotes: TaskMessage[]) => {
    const originalInternalNotes = _.cloneDeep(internalNotes);

    for (let i = 0; i <= internalNotes.length - 1; i++) {
      const note = internalNotes[i];
      note.content = MentionsUtils.generateDisplayHtmlWithMentions(note.content);
      note['uniqueId'] = uuidv4();
      originalInternalNotes[i]['uniqueId'] = note['uniqueId'];
      const internalNotesImgCount = this.replaceImagesWithLoaderIcons(note);
      const taskImageProcessingStatus: TaskImageProcessStatus = { numberOfImagesLeftToProcess: internalNotesImgCount, processedContent: note.content }
      this.noteImageProcessStatuses.set(note['uniqueId'], taskImageProcessingStatus);
    }

    originalInternalNotes.forEach(note => {
      note.content = MentionsUtils.generateDisplayHtmlWithMentions(note.content);
      this.generateDisplayHtmlWithImages(note.content, note);
    });
  }

  private generateDisplayHtmlWithImages = (displayText: string, note: TaskMessage) => {

    const regexp = new RegExp(
      /<img class="task-note-image" src="(([\w]|[^a-zA-Z0-9\">])+)/g
    );
    let results = regexp.exec(displayText);
    if (!results || results.length < 2) {
      note.content = displayText;
      return displayText;
    }

    this._utilityService.getImageFileContentAsBase64(results[1], (response) => {
      results[1] = response.changingThisBreaksApplicationSecurity;

      const displayHtml = displayText.replace(
        results[0],
        `<img class="note-image" src="${results[1]}`
      );

      let imageProcessingStatus = this.noteImageProcessStatuses.get(note['uniqueId']);
      if (imageProcessingStatus) {
        if (imageProcessingStatus.numberOfImagesLeftToProcess) {
          imageProcessingStatus.numberOfImagesLeftToProcess--;
          if (imageProcessingStatus.numberOfImagesLeftToProcess === 0) {
            imageProcessingStatus.processedContent = displayHtml;
            return;
          }
        }
      }
      this.generateDisplayHtmlWithImages(displayHtml, note);
    })
  };

  private replaceImagesWithLoaderIcons = (note: TaskMessage): number => {

    if (!note.content) {
      return 0;
    }
    const imageTags = note.content.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/g);

    if (imageTags && imageTags.length) {
      imageTags.forEach(img => {
        note.content = note.content.replace(
          img,
          `<i class="fas fa-sync-alt fa-spin mx-1 mt-1" style="font-size: 1rem; margin-top: 4px; margin-bottom: 4px;"></i>`
        );
      });
      return imageTags.length;
    }
    return 0;
  };
}

export class TaskImageProcessStatus {
  processedContent: string;
  numberOfImagesLeftToProcess: number;
}
