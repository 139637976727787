<ng-container
  *ngLet='formGroup.controls.incomeDetails as formGroup'
  [formGroup]='formGroup'
>
  <!-- General Information -->
  <section
    *ngLet='formGroup.controls.generalInfo.controls as controls'
    [formGroup]='formGroup.controls.generalInfo'
    class='row p-3 pb-2 px-4 mb-0'
    style='
      background-color: #fafdff;
      margin-left: -1rem;
      margin-right: -1rem;
    '
  >
    <div class='col-12 section-body'>
      <div class='row gx-lg-5'>
        <!-- Agency Case Number -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='agency-case-number'
              class='col-lg-6'
            >
              Agency Case Number

              <i
                class='fas fa-info-circle ms-1'
                ngbTooltip='The Agency Case Number is the number assigned to the
                  loan application by the VA. This number is required to obtain
                  a Certificate of Eligibility (COE) from the VA.'
              ></i>
            </label>

            <input
              [formControl]='controls.agencyCaseNumber'
              class='form-control w-auto col-auto flex-fill'
              id='agency-case-number'
              type='text'
            />
          </div>
        </div>

        <!-- Mortgage Credit Certificate -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='mortgage-credit-certificate'
              class='col-lg-6'
            >
              Mortgage Credit Certificate

              <i
                class='fas fa-info-circle ms-1'
                ngbTooltip='The Mortgage Credit Certificate (MCC) is a tax credit
                  that reduces the amount of federal income tax you pay
                  every year.'
              ></i>
            </label>

            <input
              [formControl]='controls.mortgageCreditCertificate'
              class='form-control w-auto col-auto flex-fill'
              id='mortgage-credit-certificate'
              type='text'
            />
          </div>
        </div>

        <!-- VA Title Vesting Type -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='va-title-vesting-type'
              class='col-lg-6'
            >
              VA Title Vesting Type
            </label>

            <select
              class='form-select col-lg'
              id='va-title-vesting-type'
              [formControl]='controls.vaTitleVestingType'
            >
              <option [ngValue]='null'>Select</option>

              <option
                *ngFor='let option of vaTitleVestingTypeOptions'
                [ngValue]='option.value'
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- VA Entitlement -->
        <div
          *ngLet='controls.vaEntitlement.controls as controls'
          class='col-lg-6'
        >
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='va-entitlement-amount'
              class='col-auto'
            >
              VA Entitlement
            </label>

            <!-- Entitlement Type -->
            <select
              class='form-select col-auto w-auto ps-1 pe-4'
              id='va-entitlement-type'
              [formControl]='controls.isManual'
              style='
                background-position-x: calc(100% - 0.25rem);
                border-radius: 0 !important;
              '
            >
              <option [ngValue]='false'>Calculated</option>
              <option [ngValue]='true'>Manual</option>
            </select>

            <!-- Fill space between Entitlement Type and Entitlement Amount -->
            <div class='col-auto flex-fill'></div>

            <!-- Entitlement Amount -->
            <currency-input
              [(ngModel)]='formAdapter.incomeDetails.generalInfo.vaEntitlement.amount.value'
              [disabled]='controls.amount.disabled'
              [ngModelOptions]='{ standalone: true }'
              class='w-50 col-lg-6'
              id='va-entitlement-amount'
            ></currency-input>
          </div>
        </div>

        <!-- Seller Concessions -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='seller-concessions'
              class='col-lg-6'
            >
              Seller Concessions
            </label>

            <currency-input
              [(ngModel)]='formAdapter.incomeDetails.generalInfo.sellerConcessions.value'
              [disabled]='controls.sellerConcessions.disabled'
              [ngModelOptions]='{ standalone: true }'
              class='w-auto flex-fill'
              id='seller-concessions'
            ></currency-input>
          </div>
        </div>

        <!-- VA Existing Loan Balance -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='existing-loan-balance'
              class='col-lg-6'
            >
              VA Existing Loan Balance
            </label>

            <currency-input
              [(ngModel)]='formAdapter.incomeDetails.generalInfo.vaExistingLoanBalance.value'
              [ngModelOptions]='{ standalone: true }'
              class='w-auto flex-fill'
              id='existing-loan-balance'
            ></currency-input>
          </div>
        </div>

        <!-- VA Existing Reasonable Appraised Value -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='existing-reasonable-appraised-value'
              class='col-lg-6'
            >
              VA Existing Reasonable Appraised Value
            </label>

            <currency-input
              [(ngModel)]='formAdapter.incomeDetails.generalInfo.vaExistingReasonableAppraisedValue.value'
              [ngModelOptions]='{ standalone: true }'
              class='w-auto flex-fill'
              id='existing-reasonable-appraised-value'
            ></currency-input>
          </div>
        </div>

        <!-- VA Original Loan Amount -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='original-loan-amount'
              class='col-lg-6'
            >
              VA Original Loan Amount
            </label>

            <currency-input
              [(ngModel)]='formAdapter.incomeDetails.generalInfo.vaOriginalLoanAmount.value'
              [ngModelOptions]='{ standalone: true }'
              class='w-auto flex-fill'
              id='original-loan-amount'
            ></currency-input>
          </div>
        </div>

        <!-- VA Original Interest Rate -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='original-interest-rate'
              class='col-lg-6'
            >
              VA Original Interest Rate
            </label>

            <percent-input
              [(ngModel)]='formAdapter.incomeDetails.generalInfo.vaOriginalInterestRate.value'
              [ngModelOptions]='{ standalone: true }'
              class='w-auto flex-fill'
              id='original-interest-rate'
            ></percent-input>
          </div>
        </div>

        <!-- VA Original Loan Term -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='original-loan-term'
              class='col-lg-6'
            >
              VA Original Loan Term
            </label>

            <number-input
              [(ngModel)]='formAdapter.incomeDetails.generalInfo.vaOriginalLoanTerm.value'
              [ngModelOptions]='{ standalone: true }'
              class='w-auto flex-fill'
              id='original-loan-term'
            ></number-input>
          </div>
        </div>

        <!-- VA Existing Principal And Interest -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='existing-principal-and-interest'
              class='col-lg-6'
            >
              VA Existing Principal And Interest
            </label>

            <currency-input
              [(ngModel)]='formAdapter.incomeDetails.generalInfo.vaExistingPrincipalAndInterest.value'
              [ngModelOptions]='{ standalone: true }'
              class='w-auto flex-fill'
              id='existing-principal-and-interest'
            ></currency-input>
          </div>
        </div>

        <!-- VA Cash Out Refinance Type -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>
            <label
              for='cash-out-refinance-type'
              class='col-lg-6'
            >
              VA Cash Out Refinance Type
            </label>

            <select
              class='form-select col-lg'
              id='cash-out-refinance-type'
              [formControl]='controls.vaCashOutRefinanceType'
            >
              <option [ngValue]='null'>Select</option>

              <option
                *ngFor='let option of vaCashOutRefinanceTypeOptions'
                [ngValue]='option.value'
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- VA Benefit Using -->
        <div class='col-lg-6'>
          <div class='form-group row align-items-baseline pb-1 mb-1'>

            <label
              for='benefit-using'
              class='col-lg-6'
            >
              VA Benefit Using
            </label>

            <select
              [formControl]='controls.vaBenefitUsingBorrowerId'
              class='form-select col-lg'
              id='benefit-using'
            >
              <option
                *ngFor='let control of controls.borrowersInfo.controls'
                [ngValue]='control.controls.borrower.controls.id.value'
              >
                {{ control.controls.borrower.controls.name.value }}
              </option>
            </select>
          </div>
        </div>

        <!-- Is VA Funding Fee Exempt -->
        <div class='col-lg-6 d-flex'>
          <div class='form-group row align-items-baseline pb-1 mb-1 align-self-center w-100'>

            <label
              class='col-lg-6'
            >
              Exempt from funding fee
            </label>

            <ng-container *ngFor='let controls of controls.borrowersInfo.controls;'>
              <div class='col-lg d-flex gap-3' *ngIf="controls.controls.borrower.controls.id.value == formAdapter.incomeDetails.generalInfo.vaBenefitUsingBorrowerId.value ">
                <div class='form-check'>
                  <input
                    [formControl]='controls.controls.isVaFundingFeeExempt'
                    [value]='true'
                    class='form-check-input'
                    id='isVaFundingFeeExempt-yes'
                    name='isVaFundingFeeExempt'
                    type='radio'
                  >
      
                  <label
                    class='form-check-label'
                    for='isVaFundingFeeExempt-yes'
                  >
                    Yes
                  </label>
                </div>
      
                <div class='form-check'>
                  <input
                    [formControl]='controls.controls.isVaFundingFeeExempt'
                    [value]='false'
                    class='form-check-input'
                    id='isVaFundingFeeExempt-no'
                    name='isVaFundingFeeExempt'
                    type='radio'
                  >
      
                  <label
                    class='form-check-label'
                    for='isVaFundingFeeExempt-no'
                  >
                    No
                  </label>
                </div>
              </div>
            </ng-container>
            
          </div>
        </div>

        <!-- CAIVRIDs -->
        <ng-container *ngFor='let controls of controls.caivridInfo.controls; let i = index'>
          <!-- New row -->
          <div class='w-100'></div>

          <div class='col-lg-6' *ngLet='controls.controls as control'>
            <div class='form-group row align-items-baseline pb-1 mb-1'>
              <label
                for='caivrid-{{i}}'
                class='col-lg-6'
              >
                {{ control.borrower.controls.name.value | possessive }} CAIVRID #
              </label>

              <input
                [formControl]='control.caivrid'
                class='form-control w-auto col-auto flex-fill'
                id='caivrid-{{i}}'
                type='text'
              />
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>

  <!-- VA Residual Income -->
  <section
    *ngLet='formGroup.controls.vaResidualInfo.controls as controls'
    [formGroup]='formGroup.controls.vaResidualInfo'
    class='row p-3 mb-0 mt-3'
  >
    <div class='col-12 section-body'>
      <!-- Calculate / Enter Manually -->
      <div class='col-lg-11'>
        <div class='form-group row align-items-baseline border-bottom pb-1 mt-2 mb-0'>
          <h4 class='col-auto me-3'>
            VA Residual Income
          </h4>

          <div class='form-check col-auto'>
            <input
              [formControl]='controls.isManual'
              [value]='false'
              class='form-check-input'
              id='va-residual-income-calculate'
              type='radio'
            />

            <label
              class='form-check-label'
              for='va-residual-income-calculate'
            >
              Calculate
            </label>
          </div>

          <div class='form-check col-auto'>
            <input
              [formControl]='controls.isManual'
              [value]='true'
              class='form-check-input'
              id='va-residual-income-enter-manually'
              type='radio'
            />

            <label
              class='form-check-label'
              for='va-residual-income-enter-manually'
            >
              Enter Manually
            </label>
          </div>
        </div>
      </div>

      <!-- Calculated Sections (Default) -->
      <ng-container *ngIf='!formGroup.controls.vaResidualInfo.controls.isManual.value; else vaResidualManual'>
        <!-- 1., 2. and 3. Sections -->
        <ng-container *ngFor='let section of sections'>
          <qa-va-income-section
            [title]='section.title'
            [isCollapsible]='section.isCollapsible'
            [isCollapsed]='section.isCollapsed'
            value='{{ section.value | currency }}'
            [isEditable]='section.isEditable'
            (edit)='section.edit?.()'
          >
            <!-- Items -->
            <ng-container *ngTemplateOutlet='sectionList; context: {
          items: section.items
        }'></ng-container>

            <!-- Empty Section -->
            <ng-container *ngIf='section.items.length === 0'>
              <ng-container *ngTemplateOutlet='emptySection; context: {
            description: section.emptyDescription
          }'></ng-container>
            </ng-container>
          </qa-va-income-section>

          <ng-container *ngTemplateOutlet='sectionDivider'></ng-container>
        </ng-container>

        <!-- 4. Monthly Maintenance and Utilities -->
        <section class='col-lg-12 my-3'
                 *ngLet='formGroup.controls.vaResidualInfo.controls.monthlyMaintenanceAndUtilities.controls as controls'
                 [formGroup]='formGroup.controls.vaResidualInfo.controls.monthlyMaintenanceAndUtilities'>
          <div class='row mb-2'>
            <div
              class='col-auto row flex-grow-1 align-items-center px-0 ps-2'
            >
              <h5 class='col-auto mb-0'>
                4. Monthly Maintenance and Utilities
              </h5>

              <!-- Override Toggle -->
              <div class='col-auto form-check form-switch mb-0 ms-2'>
                <input
                  [formControl]='controls.isOverridden'
                  class='form-check-input'
                  id='monthly-maintenance-and-utilities-override'
                  type='checkbox'
                />

                <label
                  class='form-check-label'
                  for='monthly-maintenance-and-utilities-override'
                >
                  Override
                </label>
              </div>

              <!-- Filler -->
              <div class='col-auto flex-fill'></div>

              <!-- Total Monthly Maintenance and Utilities -->
              <b class='col-auto'>
                {{ 0 | currency }}
              </b>
            </div>

            <!-- Spacer for Edit Button -->
            <div class='col-lg-1'></div>
          </div>

          <!-- Maintenance and Utilities List -->
          <ul
            class='list-unstyled collapse show ps-3 mb-0'
            id='maintenance-and-utilities-list'
          >
            <ng-container *ngIf='!controls.isOverridden.value; else monthlyMaintenanceAndUtilitiesOverride'>
              <li class='mb-2'>
                <div class='row align-items-baseline'>
                  <label
                    class='col-auto'
                    for='total-square-footage'
                  >
                    Total Square Footage
                  </label>

                  <!-- Filler -->
                  <div class='col-auto flex-fill'></div>

                  <div class='col-auto pe-0'>
                    <number-input
                      [(ngModel)]='formAdapter.incomeDetails.vaResidualInfo.monthlyMaintenanceAndUtilities.totalSquareFootage.value'
                      [allowNegative]='false'
                      [disabled]='controls.totalSquareFootage.disabled'
                      [ngModelOptions]='{ standalone: true }'
                      id='total-square-footage'
                    ></number-input>
                  </div>

                  <!-- Spacer for Edit Button -->
                  <div class='col-lg-1'></div>
                </div>
              </li>

              <li class='mb-2'>
                <div class='row'>
                  <div class='col-auto'>
                    Square Footage X 14 cents per sq. ft.
                  </div>

                  <!-- Filler -->
                  <div class='col-auto flex-fill'></div>

                  <div class='col-auto pe-0'>
                    <span>{{ 0 | currency }}</span>
                  </div>

                  <!-- Spacer for Edit Button -->
                  <div class='col-lg-1'></div>
                </div>
              </li>
            </ng-container>

            <!-- Override -->
            <ng-template #monthlyMaintenanceAndUtilitiesOverride>
              <ng-container *ngLet='controls.overrides as overrides'>
                <li class='mb-2'>
                  <div class='row align-items-baseline'>
                    <label
                      class='col-auto'
                      for='maintenance-expenses'
                    >
                      Maintenance Expenses
                    </label>

                    <!-- Filler -->
                    <div class='col-auto flex-fill'></div>

                    <div class='col-auto pe-0'>
                      <currency-input
                        [(ngModel)]='formAdapter.incomeDetails.vaResidualInfo.monthlyMaintenanceAndUtilities.overrides.maintenanceExpenses.value'
                        [disabled]='overrides.controls.maintenanceExpenses.disabled'
                        [ngModelOptions]='{ standalone: true }'
                        id='maintenance-expenses'
                      ></currency-input>
                    </div>

                    <!-- Spacer for Edit Button -->
                    <div class='col-lg-1'></div>
                  </div>
                </li>

                <li class='mb-2'>
                  <div class='row align-items-baseline'>
                    <label
                      class='col-auto'
                      for='utilities-expenses'
                    >
                      Utilities Expenses
                    </label>

                    <!-- Filler -->
                    <div class='col-auto flex-fill'></div>

                    <div class='col-auto pe-0'>
                      <currency-input
                        [(ngModel)]='formAdapter.incomeDetails.vaResidualInfo.monthlyMaintenanceAndUtilities.overrides.utilitiesExpenses.value'
                        [disabled]='overrides.controls.utilitiesExpenses.disabled'
                        [ngModelOptions]='{ standalone: true }'
                        id='utilities-expenses'
                      ></currency-input>
                    </div>

                    <!-- Spacer for Edit Button -->
                    <div class='col-lg-1'></div>
                  </div>
                </li>
              </ng-container>
            </ng-template>
          </ul>
        </section>

        <ng-container *ngTemplateOutlet='sectionDivider'></ng-container>

        <!-- 5. Monthly Income Taxes -->
        <section class='col-lg-12 my-3'>
          <div class='row mb-2'>
            <div
              class='col-auto row flex-grow-1 align-items-center ps-2 pe-0'
            >
              <h5 class='col-auto mb-0'>
                5. Monthly Income Taxes
              </h5>

              <!-- Description -->
              <em class='col-auto flex-shrink-1 text-ellipsis'>
                (DU will use total tax value as Federal Income Tax and ignore
                other tax values)
              </em>

              <!-- Filler -->
              <div class='col-auto flex-fill'></div>

              <!-- Total Monthly Income Taxes -->
              <b class='col-auto'>
                {{ 0 | currency }}
              </b>
            </div>

            <!-- Spacer for Edit Button -->
            <div class='col-lg-1'></div>
          </div>

          <!-- Income Taxes List -->
          <ul
            *ngIf='formGroup.controls.vaResidualInfo.controls.monthlyIncomeTaxes.controls as controls; else emptyIncomeTaxes'
            class='list-unstyled collapse show ps-3 mb-0'
            id='income-taxes-list'
          >
            <li
              *ngFor='let control of controls; let i = index'
              class='mb-2'
            >
              <div class='row align-items-baseline'>
                <label
                  class='col-auto'
                  for='income-tax-{{i}}'
                >
                  {{ control.controls.name.value }}
                </label>

                <!-- Filler -->
                <div class='col-auto flex-fill'></div>

                <div class='col-auto pe-0'>
                  <currency-input
                    [(ngModel)]='formAdapter.incomeDetails.vaResidualInfo.monthlyIncomeTaxes[i].amount.value'
                    [disabled]='control.controls.amount.disabled'
                    [ngModelOptions]='{ standalone: true }'
                    id='income-tax-{{i}}'
                  ></currency-input>
                </div>

                <!-- Spacer for Edit Button -->
                <div class='col-lg-1'></div>
              </div>
            </li>
          </ul>

          <ng-template #emptyIncomeTaxes>
            <ng-container *ngTemplateOutlet='emptySection; context: {
              description: "No income taxes"
            }'></ng-container>
          </ng-template>
        </section>

        <ng-container *ngTemplateOutlet='sectionDivider'></ng-container>

        <!-- 6. Residual Income -->
        <qa-va-income-section
          [title]='residualIncomeSection.title'
          [isCollapsible]='residualIncomeSection.isCollapsible'
          [isCollapsed]='residualIncomeSection.isCollapsed'
          value='{{ residualIncomeSection.value | currency }}'
          [isEditable]='residualIncomeSection.isEditable'
          (edit)='residualIncomeSection.edit?.()'
        >
          <ng-container *ngTemplateOutlet='sectionList; context: {
        items: residualIncomeSection.items,
        hasGaps: !residualIncomeSection.isCollapsible
      }'></ng-container>
        </qa-va-income-section>

        <ng-container *ngTemplateOutlet='sectionDivider'></ng-container>

        <!-- 7. Debt-to-Income Ratio -->
        <section class='col-lg-12 mt-3'>
          <div class='row align-items-baseline'>
            <div
              class='d-flex col-auto row flex-grow-1 align-items-center ps-2 pe-0'
            >
              <h5 class='col-auto mb-0'>
                7. Debt-to-Income Ratio is (2) + (3) divided by (1)
                (VA guideline is 41%)
              </h5>

              <!-- Filler -->
              <div class='col-auto flex-fill'></div>

              <!-- Debt-to-Income Ratio -->
              <b class='col-auto'>
                {{ debtToIncomeRatio }}%
              </b>
            </div>

            <!-- Spacer for Edit Button -->
            <div class='col-lg-1'></div>
          </div>
        </section>
      </ng-container>

      <!-- Manual Entry -->
      <ng-template #vaResidualManual>
        <section class='col-12 section-body row gap-2 mt-3'>
          <!-- Residual Income -->
          <div class='d-flex flex-column gap-1 col-auto'>
            <label for='residual-income'>
              Residual Income
            </label>

            <currency-input
              [(ngModel)]='formAdapter.incomeDetails.vaResidualInfo.residualIncome.value'
              [disabled]='formGroup.controls.vaResidualInfo.controls.residualIncome.disabled'
              [ngModelOptions]='{ standalone: true }'
              id='residual-income'
            ></currency-input>
          </div>

          <div class='w-100'></div>

          <!-- Warning -->
          <qa-warning-note class='col-12 col-lg-9 ms-2'>
            The Residual Income amount entered will only be accepted by LPA.
            DO/DU ignores this income and instead uses a system-calculated
            value.
          </qa-warning-note>
        </section>
      </ng-template>
    </div>
  </section>

  <!-- Section Divider -->
  <ng-template #sectionDivider>
    <div class='col-lg-12'>
      <div class='row'>
        <div class='border-bottom flex-fill'></div>
        <div class='col-lg-1'></div>
      </div>
    </div>
  </ng-template>

  <!-- Section List Item -->
  <ng-template
    #listItem
    let-label='label'
    let-templateSubtitle='templateSubtitle'
    let-value='value'
  >
    <li class='mb-2'>
      <div class='row'>
        <div class='col-auto'>
          {{ label }}
        </div>

        <!-- Subtitle -->
        <div class='col-auto' *ngIf='templateSubtitle'>
          <ng-container *ngTemplateOutlet='templateSubtitle'>
          </ng-container>
        </div>

          <!-- Filler -->
        <div class='col'></div>

        <div class='col-auto pe-0'>
          <span>{{ value | currency }}</span>
        </div>

        <!-- Spacer for Edit Button -->
        <div class='col-lg-1'></div>
      </div>
    </li>
  </ng-template>

  <!-- Section List -->
  <ng-template #sectionList let-items='items' let-hasGaps='hasGaps'>
    <ul
      [ngClass]='{
      "ps-3": hasGaps,
      "mt-3": hasGaps
    }'
      class='list-unstyled collapse show mb-0'
    >
      <ng-container *ngFor='let item of items'>
        <ng-container *ngTemplateOutlet='listItem; context: item'>
        </ng-container>
      </ng-container>
    </ul>
  </ng-template>

  <!-- Empty Section Body -->
  <ng-template #emptySection let-description='description'>
    <div class='row'>
      <p class='col-lg-auto flex-grow-1 text-center text-muted'>
        {{description || 'No data'}}
      </p>

      <!-- Spacer for Edit Button -->
      <div class='col-lg-1'></div>
    </div>
  </ng-template>

  <ng-template #residualChartButton>
    <button
      (click)='openResidualChart()'
      class='btn text-primary p-0'
      type='button'
    >
      (Per Residual Chart)
    </button>
  </ng-template>

  <residual-chart-modal #residualChartModal>
  </residual-chart-modal>
</ng-container>
