<form #floodDetailsForm="ngForm" novalidate id="floodDetailsForm" name="floodDetailsForm" class="p-3">

    <div class="row mb-3">
        <div class="col-md-12">
            <label class="mb-2" for="floodProgramParticipationStatus">Flood Program Partic. Status</label>
            <select class="form-select" name="floodProgramParticipationStatus" id="floodProgramParticipationStatus"
                [(ngModel)]="subjectProperty.floodProgramParticipationStatus" [disabled]="readonly">
                <option [ngValue]="null">--Select One--</option>
                <option [ngValue]="option.value" *ngFor="let option of nFIPCommunityParticipationStatuses">
                    {{ option.name }}
                </option>
            </select>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                On NFIP Map
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isOnNFIPMap" id="yes_isOnNFIPMap"
                    [(ngModel)]="subjectProperty.isOnNFIPMap" [readonly]="readonly">
                <label [for]="readonly ? 'isOnNFIPMap' : 'yes_isOnNFIPMap'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isOnNFIPMap" name="isOnNFIPMap"
                    [(ngModel)]="subjectProperty.isOnNFIPMap" [readonly]="readonly">
                <label [for]="readonly ? 'isOnNFIPMap' : 'no_isOnNFIPMap'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                In Protected Area
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isInProtectedArea" id="yes_isInProtectedArea"
                    [(ngModel)]="subjectProperty.isInProtectedArea" [readonly]="readonly">
                <label [for]="readonly ? 'isInProtectedArea' : 'yes_isInProtectedArea'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isInProtectedArea" name="isInProtectedArea"
                    [(ngModel)]="subjectProperty.isInProtectedArea" [readonly]="readonly">
                <label [for]="readonly ? 'isInProtectedArea' : 'no_isInProtectedArea'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-9">
            <h6 class="mb-3">
                In Special Flood Hazard Area
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isInSpecialFloodHazardArea" id="yes_isInSpecialFloodHazardArea"
                    [(ngModel)]="subjectProperty.isInSpecialFloodHazardArea" [readonly]="readonly">
                <label [for]="readonly ? 'isInSpecialFloodHazardArea' : 'yes_isInSpecialFloodHazardArea'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isInSpecialFloodHazardArea" name="isInSpecialFloodHazardArea"
                    [(ngModel)]="subjectProperty.isInSpecialFloodHazardArea" [readonly]="readonly">
                <label [for]="readonly ? 'isInSpecialFloodHazardArea' : 'no_isInSpecialFloodHazardArea'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-3" *ngIf="hasSaveCancelButtons && !readonly">
        <div class="col-md-12 text-end">
          <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
          <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
            Save
          </button>
        </div>
    </div>
    
</form>