import { Component, Input, OnInit } from '@angular/core';
import { Asset, ChannelEnum, LoanApplication, PurchaseCredit, PurchaseCreditSourceType } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { UtilityService } from '../../services/utility.service';
import { Select2OptionData } from 'ng-select2';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { MortgageCalculationService } from '../../services/mortgage-calculation.service';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import {
  SourceOfFundsDialogComponent,
} from '../../loan-property/loan-property-info/source-of-funds-dialog/source-of-funds-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'other-assets',
  templateUrl: './other-assets.component.html',
  styleUrls: ['./other-assets.component.scss'],
  viewProviders: [formViewProvider]
})
export class OtherAssetsComponent implements OnInit {

  private _mortgage: UrlaMortgage;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    this.initializePossibleAccountOwners();
    this.initializeAssetsAndCredits();
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  application: LoanApplication;

  @Input()
  assetTypes: EnumerationItem[];

  @Input()
  purchaseCreditTypes: EnumerationItem[];

  @Input()
  downPaymentSourceTypes: EnumerationItem[];

  @Input()
  financialAssetsSubTotal: number;

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  giftTypes: EnumerationItem[];

  otherAssetTypes: EnumerationItem[];

  assetEditingIx: number;

  giftEditingIx: number;

  creditEditingIx: number;

  otherAssets: Array<Asset> = [];

  giftAssets: Array<Asset> = [];

  credits: Array<PurchaseCredit> = [];

  editedOtherAsset: Asset;

  editedGiftAsset: Asset;

  editedCredit: PurchaseCredit;

  possibleAccountOwners: Array<Select2OptionData> = [];

  otherAssetsSubTotal: number;
  creditsSubTotal: number;
  giftsSubTotal: number;

  thereIsAtLeastOneInvalidLineItem: boolean = false;
  isPurchase: boolean = false;

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
  };

  isNewAsset = false;
  isNewGift = false;
  isNewCredit = false;

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _utilityService: UtilityService,
    private readonly _modalService: NgbModal,
    private readonly _calculationService: MortgageCalculationService) { }

  ngOnInit(): void {
    this.isPurchase = this.mortgage.subjectProperty.purposeOfLoan && this.mortgage.subjectProperty.purposeOfLoan == "Purchase";

    this.giftTypes = _.orderBy(this.assetTypes.filter(asset =>
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CashGift) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.GiftOfEquity) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Grant)
    ), x => x.name);

    this.otherAssetTypes = _.orderBy(this.assetTypes.filter(asset =>
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CashOnHand) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.PendingNetSaleProceedsFromRealEstateAssets) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SaleOtherAssets) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SecuredBorrowedFundsNotDeposited) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.ProceedsFromSecuredLoan) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.ProceedsFromUnsecuredLoan) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.OtherLiquidAssets) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.OtherNonLiquidAssets) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Annuity) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Automobile) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.EarnestMoneyCashDepositTowardPurchase) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.NetWorthOfBusinessOwned) ||
      asset.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.ProceedsFromSaleOfNonRealEstateAsset)
    ), x => x.name);

    this.purchaseCreditTypes = _.orderBy(this.purchaseCreditTypes.filter(credit =>
      credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.EmployerAssistedHousing) ||
      credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LotEquity) ||
      credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.RelocationFunds) ||
      credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LeasePurchaseFund) ||
      credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.TradeEquity) ||
      credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.Other) ||
      credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.SweatEquity) ||
      credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.DepositOnSalesContract) ||
      credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.MIPremiumRefund) ||
      credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.SellerCredit) ||
      (credit.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.PurchaseCreditType.LenderCredit) && this.application.channel != ChannelEnum.Wholesale)
    ), x => x.name);

    this.assetEditingIx = -1;
    this.giftEditingIx = -1;
    this.creditEditingIx = -1;
  }

  isNotInOtherCategory = (assetType, assetInsertionType) => {
    if (assetInsertionType == 1) return true;

    const isAssetTypeInExpectedAssetTypes = (assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Bond) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CheckingAccount) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BridgeLoanNotDeposited) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.LifeInsurance) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CertificateOfDepositTimeDeposit) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.IndividualDevelopmentAccount) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MoneyMarketFund) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MutualFund) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RetirementFund) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SavingsAccount) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Stock) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.TrustAccount) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CashGift) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.GiftOfEquity) ||
      assetType === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Grant));
    return isAssetTypeInExpectedAssetTypes;
  }

  getBorrowerName = (borrowerId: string): string => {
    let borrower = this.possibleAccountOwners.find(owner => owner.id == borrowerId);
    if (borrower && borrower.text)
      return borrower.text;
    return ""
  }

  omitAsset = (asset: Asset, isOmit: boolean) => {
    asset.isDeposited = !isOmit;
    this.calculateSubTotal();
  }

  omitCredit = (credit: PurchaseCredit, isOmit: boolean) => {
    credit['isOmitted'] = isOmit;
    this.calculateSubTotal();
  }

  addAsset = (assetType: string) => {
    let asset = new Asset();
    if (assetType)
      asset.assetType = assetType;
    asset.assetId = this._utilityService.getUniqueId();
    asset.owningBorrowerIds = this.possibleAccountOwners && this.possibleAccountOwners.length == 1 ?
      [this.possibleAccountOwners[0].id] : [];
    asset.isDeposited = true;

    this.setAssetOwnerNames(asset);
    this.otherAssets.push(asset);
    this._mortgage.assets.push(asset);
    this.resetCreditEdit();
    this.resetGiftEdit();
    this.assetEditingIx = this.otherAssets.length - 1;
    this.isNewAsset = true;
    this.thereIsAtLeastOneInvalidLineItem = !asset.owningBorrowerIds.length;
    this.editedOtherAsset = _.cloneDeep(asset);
  }

  editOtherAsset = (index: number, asset: Asset) => {
    if (this.assetEditingIx != -1) {
      this.otherAssets[this.assetEditingIx] = this.editedOtherAsset;
      const assetInMainList = this.mortgage.assets.find(a => a.assetId == this.editedOtherAsset.assetId);
      if (assetInMainList) {
        const indexInMainList = this.mortgage.assets.indexOf(assetInMainList);
        if (indexInMainList >= 0) {
          this.mortgage.assets[indexInMainList] = this.editedOtherAsset;
        }
      }
      this.calculateSubTotal();
    }

    this.resetGiftEdit();
    this.resetCreditEdit();
    this.editedOtherAsset = _.cloneDeep(asset);
    this.assetEditingIx = index;
  }

  cancelEditAsset = () => {
    const asset = this.otherAssets[this.assetEditingIx];
    const indexInMainList = this.mortgage.assets.indexOf(asset);
    if (this.isNewAsset) {
      if (indexInMainList >= 0) {
        this._mortgage.assets.splice(indexInMainList, 1);
      }
      this.otherAssets.splice(this.assetEditingIx, 1);
      this.thereIsAtLeastOneInvalidLineItem = false;
    } else {
      if (indexInMainList >= 0) {
        this.mortgage.assets[indexInMainList] = this.editedOtherAsset;
      }
      this.otherAssets[this.assetEditingIx] = this.editedOtherAsset;
      this.thereIsAtLeastOneInvalidLineItem = !this.editedOtherAsset.owningBorrowerIds.length;
    }

    this.isNewAsset = false;
    this.calculateSubTotal();
    this.assetEditingIx = -1;
  }

  addGift = (giftType: string) => {
    let asset = new Asset();
    asset.assetType = giftType;
    asset.assetId = this._utilityService.getUniqueId();
    asset.owningBorrowerIds = this.possibleAccountOwners && this.possibleAccountOwners.length == 1 ?
      [this.possibleAccountOwners[0].id] : [];
    asset.isDeposited = true;

    this.setAssetOwnerNames(asset);
    this.giftAssets.push(asset);
    this._mortgage.assets.push(asset);
    this.resetAssetEdit();
    this.resetCreditEdit();
    this.giftEditingIx = this.giftAssets.length - 1;
    this.isNewGift = true;
    this.thereIsAtLeastOneInvalidLineItem = !asset.owningBorrowerIds.length;
    this.editedOtherAsset = _.cloneDeep(asset);
  }

  editGiftAsset = (index: number, asset: Asset) => {
    if (this.giftEditingIx != -1) {
      this.giftAssets[this.giftEditingIx] = this.editedGiftAsset;
      const assetInMainList = this.mortgage.assets.find(a => a.assetId == this.editedGiftAsset.assetId);
      if (assetInMainList) {
        const indexInMainList = this.mortgage.assets.indexOf(assetInMainList);
        if (indexInMainList >= 0) {
          this.mortgage.assets[indexInMainList] = this.editedGiftAsset;
        }
      }
      this.calculateSubTotal();
    }
    this.resetAssetEdit();
    this.resetCreditEdit();
    this.editedGiftAsset = _.cloneDeep(asset);
    this.giftEditingIx = index;
  }

  cancelEditGift = () => {
    const asset = this.giftAssets[this.giftEditingIx];
    const indexInMainList = this.mortgage.assets.indexOf(asset);
    if (this.isNewGift) {
      if (indexInMainList >= 0) {
        this._mortgage.assets.splice(indexInMainList, 1);
      }
      this.giftAssets.splice(this.giftEditingIx, 1);
      this.thereIsAtLeastOneInvalidLineItem = false;
    } else {
      if (indexInMainList >= 0) {
        this.mortgage.assets[indexInMainList] = this.editedGiftAsset;
      }
      this.giftAssets[this.giftEditingIx] = this.editedGiftAsset;
      this.thereIsAtLeastOneInvalidLineItem = !this.editedGiftAsset.owningBorrowerIds.length;
    }

    this.isNewGift = false;
    this.calculateSubTotal();
    this.giftEditingIx = -1;
  }

  addCredit = () => {
    let credit = new PurchaseCredit();
    credit.sourceType = PurchaseCreditSourceType.Other;
    credit.purchaseCreditTypeOtherDescription = 'Other';
    credit.purchaseCreditId = this._utilityService.getUniqueId();
    this.credits.push(credit);
    if (this._mortgage.transactionDetail.purchaseCredits == undefined) {
      this._mortgage.transactionDetail.purchaseCredits = [];
    }
    this._mortgage.transactionDetail.purchaseCredits.push(credit);
    this.resetAssetEdit();
    this.resetGiftEdit();
    this.creditEditingIx = this.credits.length - 1;
    this.isNewCredit = true;
    this.editedCredit = _.cloneDeep(credit);
  }

  editCredit = (index: number, credit: PurchaseCredit) => {
    if (this.creditEditingIx != -1) {
      this.credits[this.creditEditingIx] = this.editedCredit;
      const creditInMainList = this.mortgage.transactionDetail.purchaseCredits.find(c => c.purchaseCreditId == this.editedCredit.purchaseCreditId);
      if (creditInMainList) {
        const indexInMainList = this.mortgage.transactionDetail.purchaseCredits.indexOf(creditInMainList);
        if (indexInMainList >= 0) {
          this.mortgage.transactionDetail.purchaseCredits[indexInMainList] = this.editedCredit;
        }
      }
      this.calculateSubTotal();
    }
    this.resetAssetEdit();
    this.resetGiftEdit();
    this.editedCredit = _.cloneDeep(credit);
    this.creditEditingIx = index;
  }

  cancelEditCredit = () => {
    const credit = this.credits[this.creditEditingIx]
    const indexInMainList = this.mortgage.transactionDetail.purchaseCredits.indexOf(credit);

    if (this.isNewCredit) {
      if (indexInMainList >= 0) {
        this._mortgage.transactionDetail.purchaseCredits.splice(indexInMainList, 1);
      }
      this.credits.splice(this.creditEditingIx, 1);
    } else {
      if (indexInMainList >= 0) {
        this.mortgage.transactionDetail.purchaseCredits[indexInMainList] = this.editedCredit;
      }
      this.credits[this.creditEditingIx] = this.editedCredit;
    }

    this.isNewCredit = false;
    this.calculateSubTotal();
    this.creditEditingIx = -1
  }

  addPurchaseCredit = () => {
    this.openSourceOfFundsCalculator();
  }

  private openSourceOfFundsCalculator = () => {
    const modalRef = this._modalService.open(SourceOfFundsDialogComponent, Constants.modalOptions.large);
    modalRef.componentInstance.data = this.mortgage;
    modalRef.componentInstance.purchaseCredits = this.mortgage.transactionDetail.purchaseCredits;
    modalRef.componentInstance.purchaseCreditTypes = this.purchaseCreditTypes;
    modalRef.componentInstance.downPaymentSourceTypes = this.downPaymentSourceTypes;

    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        this.mortgage.transactionDetail.purchaseCredits = result;
        this._calculationService.calculateMortgageStatistics(this.mortgage);
        this.calculateSubTotal();
      }

    }, (err) => {
      console.log(err);
    });
  }

  onAccountOwnersChanged = (asset: Asset) => {
    this.thereIsAtLeastOneInvalidLineItem = !asset.owningBorrowerIds.length;
    this.setAssetOwnerNames(asset);
  }

  deleteAssetOrCredit = (removeIx: number, itemType: string) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        if (itemType == 'credit') {
          self.deleteCredit(removeIx);
        } else if (itemType == 'gift') {
          self.deleteGift(removeIx);
        } else {
          self.deleteAsset(removeIx);
        }
        self.calculateSubTotal();
      }
    });
  }

  private resetAssetEdit() {
    if (this.assetEditingIx != -1) {
      this.otherAssets[this.assetEditingIx] = this.editedOtherAsset;
      const assetInMainList = this.mortgage.assets.find(a => a.assetId == this.editedOtherAsset.assetId);
      if (assetInMainList) {
        const indexInMainList = this.mortgage.assets.indexOf(assetInMainList);
        if (indexInMainList >= 0) {
          this.mortgage.assets[indexInMainList] = this.editedOtherAsset;
        }
      }
      this.calculateSubTotal();
    }
    this.assetEditingIx = -1
  }

  private resetGiftEdit() {
    if (this.giftEditingIx != -1) {
      this.giftAssets[this.giftEditingIx] = this.editedGiftAsset;
      const assetInMainList = this.mortgage.assets.find(a => a.assetId == this.editedGiftAsset.assetId);
      if (assetInMainList) {
        const indexInMainList = this.mortgage.assets.indexOf(assetInMainList);
        if (indexInMainList >= 0) {
          this.mortgage.assets[indexInMainList] = this.editedGiftAsset;
        }
      }
      this.calculateSubTotal();
    }
    this.giftEditingIx = -1
  }

  private resetCreditEdit() {
    if (this.creditEditingIx != -1) {
      this.credits[this.creditEditingIx] = this.editedCredit;
      const creditInMainList = this.mortgage.transactionDetail.purchaseCredits.find(c => c.purchaseCreditId == this.editedCredit.purchaseCreditId);
      if (creditInMainList) {
        const indexInMainList = this.mortgage.transactionDetail.purchaseCredits.indexOf(creditInMainList);
        if (indexInMainList >= 0) {
          this.mortgage.transactionDetail.purchaseCredits[indexInMainList] = this.editedCredit;
        }
      }
      this.calculateSubTotal();
    }
    this.creditEditingIx = -1
  }

  private deleteAsset = (indexToRemove: number) => {
    if (indexToRemove === this.assetEditingIx) {
      this.assetEditingIx = -1;
    }
    const index = this.mortgage.assets.indexOf(this.otherAssets[indexToRemove]);
    if (index >= 0) {
      this.mortgage.assets.splice(index, 1);
    }
    this.otherAssets.splice(indexToRemove, 1);
    const oneWithEmptyAccountOwnersIndex = this.otherAssets.findIndex(a => !a.owningBorrowerIds.length);
    this.thereIsAtLeastOneInvalidLineItem = oneWithEmptyAccountOwnersIndex >= 0;
  }

  private deleteGift = (indexToRemove: number) => {
    if (indexToRemove === this.giftEditingIx) {
      this.giftEditingIx = -1;
    }
    const index = this.mortgage.assets.indexOf(this.giftAssets[indexToRemove]);
    if (index >= 0) {
      this.mortgage.assets.splice(index, 1);
    }
    this.giftAssets.splice(indexToRemove, 1);
    const oneWithEmptyAccountOwnersIndex = this.giftAssets.findIndex(a => !a.owningBorrowerIds.length);
    this.thereIsAtLeastOneInvalidLineItem = oneWithEmptyAccountOwnersIndex >= 0;
  }

  private deleteCredit = (indexToRemove: number) => {
    if (indexToRemove === this.creditEditingIx) {
      this.creditEditingIx = -1;
    }
    const index = this.mortgage.transactionDetail.purchaseCredits.indexOf(this.credits[indexToRemove]);
    if (index >= 0) {
      this.mortgage.transactionDetail.purchaseCredits.splice(index, 1);
    }
    this.credits.splice(indexToRemove, 1);
  }

  private setAssetOwnerNames = (asset: Asset) => {
    asset['owners'] = [];
    asset.owningBorrowerIds.forEach(borrowerId => {
      asset['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(borrowerId) });
    });
  }

  private calculateSubTotal = () => {
    let subTotalOA = 0;
    let subTotalCredit = 0;
    let subTotalGift = 0;

    this.otherAssets.forEach(asset => {
      if (asset.isDeposited) {
        subTotalOA += Number(asset.cashMarketValue || 0);
      }
    });

    this.credits.forEach(credit => {
      if (!credit['isOmitted']) {
        subTotalCredit += Number(credit.purchaseCreditAmount || 0);
      }
    });

    this.giftAssets.forEach(asset => {
      if (asset.isDeposited) {
        subTotalGift += Number(asset.cashMarketValue || 0);
      }
    });

    this.otherAssetsSubTotal = subTotalOA;
    this.creditsSubTotal = subTotalCredit;
    this.giftsSubTotal = subTotalGift;
    this._mortgage.calculatedStats.totalOtherCredit = this._calculationService.calculateTotalOtherCredit(this._mortgage);
    this._mortgage.calculatedStats.totalCredit = this._calculationService.calculateTotalCredit(this._mortgage);
    this._mortgage.calculatedStats.totalDueFromBorrowers = this._calculationService.calculateTotalDueFromBorrowers(this._mortgage);
    this._mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this._mortgage);
  }

  private initializeAssetsAndCredits = () => {
    this.otherAssets = [];
    if (this._mortgage.assets) {
      this._mortgage.assets.forEach(asset => {
        if (!this.isNotInOtherCategory(asset.assetType, asset.assetInsertionType)) {
          let borrIds = [];
          asset['owners'] = [];
          asset.owningBorrowerIds.forEach(borrowerId => {
            borrIds.push(borrowerId.toString());
            asset['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(borrowerId) });
          });
          asset.owningBorrowerIds = borrIds;
          this.otherAssets.push(asset);
        }
      });
    }
    if (this._mortgage.transactionDetail.purchaseCredits) {
      this.credits = _.clone(this._mortgage.transactionDetail.purchaseCredits);
    }
  }

  private initializePossibleAccountOwners = () => {
    this.possibleAccountOwners = [];
    if (this._mortgage.borrowers !== null) {
      this._mortgage.borrowers.forEach(borrower => {
        const borrowerFullName = this._utilityService.getBorrowerFullName(borrower);

        const possibleAccountOwner = {
          id: borrower.borrowerId.toString(),
          text: borrowerFullName
        };
        this.possibleAccountOwners.push(possibleAccountOwner);
      });
    }
  }
}
