import {
    Component,
    Injector,
    Input,
    OnInit
} from '@angular/core';
import {
    Configuration,
    ThirdPartyCredentialArray,
    ThirdPartyCredentialSchemaReadonlyArray,
    ThirdPartyCredentialType,
    UserThirdPartyCredential
} from '../../../../../models';
import { ActivatedRoute } from '@angular/router';
import { ApplicationContextBoundComponent } from '../../../../../shared/components';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ScopeType } from '../integrations.component';
import { combineLatest, ObservableInput } from 'rxjs';

@Component({
    selector: 'los-integration',
    templateUrl: './los.component.html',
    styleUrls: ['./los.component.scss'],
})
export class LosIntegrationComponent extends ApplicationContextBoundComponent  implements OnInit {
    @Input() scope: ScopeType;
    @Input() losEnabled: Configuration;

    userCompanyGuid: string;
    credentialSchema: ThirdPartyCredentialSchemaReadonlyArray;
    credentials: ThirdPartyCredentialArray | UserThirdPartyCredential;

    isLoading: boolean;

    constructor(
        private readonly injector: Injector,
        private readonly _route: ActivatedRoute,
        private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
        private readonly _notifyService: NotificationService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.userCompanyGuid = this._route.snapshot.params.id || this.applicationContext.currentlyLoggedInUser.userCompanyGuid;
        this.loadInitData();
    }

    private loadInitData() {
        this.isLoading = true;

        const requests: ObservableInput<any>[] = [this._thirdPartyCredentialsService.getCredentialSchemas(ThirdPartyCredentialType.LosVendor, this.scope)]

        if (this.scope === "Company") {
            requests.push(this._thirdPartyCredentialsService.getCredentialsForCompany(ThirdPartyCredentialType.LosVendor))
        } else {
            requests.push(this._thirdPartyCredentialsService.getCredentialsForUser(ThirdPartyCredentialType.LosVendor, this._route.snapshot.params.id || null));
        }

        combineLatest([...requests]).subscribe({
            next: ([schema, credentials]) => {
                if (this.scope === "Company") {
                    this.credentialSchema = schema || [];
                    this.credentials = credentials as ThirdPartyCredentialArray || [];
                } else {
                    this.credentialSchema = schema?.filter(schema => schema.hasUserCreds) || [];
                    this.credentials = credentials as UserThirdPartyCredential || new UserThirdPartyCredential();
                }
            },
            error: (error) => {
                this._notifyService.showError(error?.message || `Couldn't get ${ThirdPartyCredentialType.LosVendor} data`, "Error!");
            }
        }).add(() => this.isLoading = false);
    }
}
