<p-table #dt1 [columns]="selectedColumns" [value]="filteredLeads" [paginator]="true" [rows]="25"
  name="lead-list-table-v2" [rowsPerPageOptions]="[25, 50, 100]" [globalFilterFields]="globalFilterFields"
  [(selection)]="selectedRows" [autoLayout]="true" [showCurrentPageReport]="true"
  styleClass="p-datatable-gridlines p-datatable-sm"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [scrollable]="true" [reorderableColumns]="true"
  [scrollHeight]="scrollHeight" [selectionPageOnly]="true"
  stateStorage="local" stateKey="leads-table-state" (onStateSave)="onStateSave($event)"
  sortMode="multiple" [multiSortMeta]="!tableState ? null : tableState.multiSortMeta"
>
  <ng-template pTemplate="caption" *ngIf="leadTableConfig.isReassignLeadVisible ||
    leadTableConfig.isAddTodialListButtonVisible ||
    leadTableConfig.isExportToCsvVisible ||
    leadTableConfig.isColumnSelectionVisible ||
    leadTableConfig.isSearchButtonVisible">
    <div class="row mb-1">

      <div class="col-md-4 offset-md-4 col-12 d-flex justify-content-center mb-xs-2">
        <button *ngIf="leadTableConfig.isReassignLeadVisible" [disabled]="selectedRows.length == 0" class="btn btn-primary mx-2 hide-on-mobile" id="lead_reassignLeadBtn" (click)="openReassignModal()"> Reassign Lead</button>
        <app-add-to-dial-list
          *ngIf="leadTableConfig.isAddTodialListButtonVisible"
          [selectedRows]="selectedRows"
          [contactListType]="contactListType"
          [idFieldName]="'leadId'"
          (onAddedToDialList)="addedToDialList($event)"
        ></app-add-to-dial-list>
        <button *ngIf="leadTableConfig.isExportToCsvVisible && !isTpoUser" id="lead_exportToCSVBtn" class="btn btn-primary me-2 hide-on-mobile" (click)="exportToCSV()"> Export To CSV</button>
      </div>

      <div class="col-md-4 col-12 d-flex justify-content-end"
        [ngClass]="{
          'justify-content-xs-between': leadTableConfig.isSearchButtonVisible,
          'justify-content-xs-start': !leadTableConfig.isSearchButtonVisible
        }"
      >
        <!-- mobile -->
        <div class="btn-group hide-on-non-mobile">
          <button
            *ngIf="leadTableConfig.isReassignLeadVisible"
            type="button"
            class="btn text-primary"
            title="Reassign Lead"
            [disabled]="selectedRows.length == 0"
            (click)="openReassignModal()"
            style="pointer-events: auto"
          >
            <i class="fas fa-people-arrows"></i>
          </button>

          <button
            *ngIf="leadTableConfig.isExportToCsvVisible && !isTpoUser"
            type="button"
            class="btn text-primary"
            title="Export To CSV"
            (click)="exportToCSV()"
          >
            <i class="fas fa-file-csv"></i>
          </button>

          <button
            *ngIf="leadTableConfig.isColumnSelectionVisible"
            type="button"
            class="btn text-primary"
            data-bs-container="body"
            data-bs-toggle="popover"
            data-trigger="click"
            placement="bottom"
            (click)="openColumnSelectorForMobile()"
            [popover]="mobileColumnSelectorPopover"
            [outsideClick]="true"
            [containerClass]="'mobile-col-selector-popover'"
          >
            <i class="fa fa-cog"></i>
          </button>
          <ng-template #mobileColumnSelectorPopover>
            <p-multiSelect #mobileColumnSelector
              [options]="leadTableConfig.columns"
              [(ngModel)]="selectedColumns"
              (ngModelChange)="onColumnSelectionChanged()"
              optionLabel="header"
              selectedItemsLabel="{0} columns selected"
              name="selectedColumns"
              ngDefaultControl
              defaultLabel="Choose Columns">
            </p-multiSelect>
          </ng-template>
        </div>

        <!-- desktop -->
        <p-multiSelect *ngIf="leadTableConfig.isColumnSelectionVisible"
          [options]="leadTableConfig.columns"
          [(ngModel)]="selectedColumns"
          (ngModelChange)="onColumnSelectionChanged()"
          optionLabel="header"
          selectedItemsLabel="{0} columns selected"
          name="selectedColumns"
          ngDefaultControl
          defaultLabel="Choose Columns"
          class="hide-on-mobile">
        </p-multiSelect>

        <!-- desktop and mobile -->
        <span class="p-input-icon-right ms-2" *ngIf="leadTableConfig.isSearchButtonVisible">
          <i class="pi pi-search"></i>
          <input pInputText type="text" class="form-control h-100"
            [(ngModel)]="globalSearchString"
            (ngModelChange)="onGlobalSearchStringChanged($event)"
            placeholder="Search keyword" />
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th class="justify-content-center" *ngIf="!leadTableConfig.hideActionColumn">
        <p-tableHeaderCheckbox *ngIf="leadTableConfig.isRowSelectionVisible"></p-tableHeaderCheckbox>
        <span class="ms-2">{{leadTableConfig.isRowSelectionVisible ? 'Check All Visible' : 'Action'}}</span>
      </th>
      <th *ngFor="let col of columns" [pSortableColumn]="col.field" style="max-width: 120px;" pReorderableColumn>
        {{ col.header }}
        <p-sortIcon [field]="col.field" class="pull-right"></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngIf="!leadTableConfig.hideActionColumn">
        <span>
          <p-tableCheckbox [value]="rowData" *ngIf="leadTableConfig.isRowSelectionVisible"> </p-tableCheckbox>
          <span class="mx-1" *ngIf="leadTableConfig.isRowSelectionVisible"> | </span>
        </span>

        <span *ngIf="editRequestedForLead.observed">
          <i class="fa fa-pencil-alt cursor text-info" (click)="onEditLeadDetailsClicked(rowData)" title="Edit"></i>
        </span>

        <span *ngIf="leadTableConfig.isDeleteButtonVisible">
          <span class="mx-1"> | </span>
          <i class="fa fa-trash-alt cursor text-danger"
            (click)="onDeleteLeadClicked(rowData.leadId)" title="Delete" id="btnLeadDelete"></i>
        </span>
        <br />
        <span class="text-muted text-ellipsis cell-mw150"
          title="{{rowData.loanPurposeName}} {{rowData.loanTypeName ? ('- ' + rowData.loanTypeName) : ''}}">{{rowData.loanPurposeName}}
          {{rowData.loanTypeName ? ('- ' + rowData.loanTypeName) : ''}}</span>
      </td>
      <td *ngFor="let col of columns" [ngSwitch]="col.field">
        <span *ngSwitchCase="'lastName'">
          <span class="text-cell-primary">{{ rowData['displayName'] }}</span>
          <br />
          <span class="text-muted text-ellipsis cell-mw200">{{ rowData.subjectPropertyAddress1 || '--' }}
            {{rowData.subjectPropertyCity}}{{rowData.subjectPropertyCity ? ',' : ''}}
            {{rowData.subjectPropertyState ? rowData.subjectPropertyState.toUpperCase() : ''}}
            {{rowData.subjectPropertyZip}}</span>
        </span>

        <span *ngSwitchCase="'mobilePhone'">
          <table-cell-contact-info [contactInfo]="rowData" [recordType]="'Lead'" [recordId]="rowData.leadId"
            [dialerEnabled]="dialerEnabled"></table-cell-contact-info>
        </span>

        <span *ngSwitchCase="'loanAmount'">
          <span class="text-cell-primary">{{ rowData['interestRate'] ? getInterestRateText(rowData['interestRate']) :
            '--'}}</span>
          <br />
          <span class="text-muted">{{ rowData['loanAmount'] | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
        </span>

        <span *ngSwitchCase="'leadStatusId'">
          <span class="text-cell-primary">{{ rowData['leadStatusName'] }}</span>
          <br>
          <div class="text-muted text-ellipsis"
            style="cursor: pointer; max-width: 500px !important;"
            title="Edit Note"
            container="body"
            placement="bottom"
            data-trigger="click"
            adaptivePosition="true"
            [outsideClick]="false"
            [popover]="notesTemplate"
            #pop="bs-popover"
          >
            {{rowData.mostRecentNote ? rowData.mostRecentNote : '--'}}
          </div>
          <ng-template #notesTemplate>
            <edit-lead-note [lead]="rowData" (close)="pop.hide()"></edit-lead-note>
          </ng-template>
        </span>

        <span *ngSwitchCase="'leadCampaignId'">
          <div *ngIf="rowData.leadCampaignName || rowData.leadSource || rowData.leadRefId">
            <span class="text-cell-primary">{{ rowData.leadCampaignName ? rowData.leadCampaignName : '--' }}</span>
            <br />
            <span class="text-muted">{{ rowData.leadSource ? rowData.leadSource : '--' }}</span>
            <br />
            <span class="text-muted">{{ rowData.leadRefId ? ('Ref ID: ' + rowData.leadRefId) : '--' }}</span>
          </div>
        </span>


        <span *ngSwitchCase="'leadContactUserId'">
          <span class="text-cell-primary">{{ rowData['leadContactName'] }}</span>
          <br />
          <span class="text-muted">On: {{ rowData['leadContactAssignmentDate'] | date: 'short' }}</span>
        </span>

        <span *ngSwitchCase="'dateInserted'">
          <span class="text-cell-primary"> {{ rowData['dateInserted'] | date: 'short' }} </span>
        </span>


        <span *ngSwitchCase="'contactAttempts'">
          <span class="text-cell-primary">{{ rowData['contactAttempts'] ? rowData['contactAttempts'] : '--' }}</span>
          <br />
          <span class="text-muted">{{ rowData['redistributionCount'] ? rowData['redistributionCount'] : '--' }}</span>
        </span>

        <div *ngSwitchCase="'leadListIds'" style="max-width: max-content">
          <span *ngIf="rowData['referralSourceName']" class="text-cell-primary">{{ rowData['referralSourceName']
            }}</span>
          <br *ngIf="rowData['referralSourceName']" />
          <span class="badge rounded-pill m-1 row" style="background-color: #8f4397; float: left"
            *ngFor="let leadListId of rowData['leadListIds']">
            {{ getLeadListNameById(leadListId) }}
          </span>
        </div>

        <span *ngSwitchCase="'referralSource'">

        </span>

        <span *ngSwitchCase="'lastAttemptedContact'">
          <span> {{ rowData['lastAttemptedContact'] | date: 'short' }} </span>
        </span>

        <span *ngSwitchCase="'lastSuccessfulContact'">
          <span class="text-cell-primary">{{ rowData['lastAttemptedContact'] | date: 'short' }}</span>
          <br />
          <span class="text-muted">{{ rowData['lastSuccessfulContact'] ? (rowData['lastSuccessfulContact'] | date:
            'short') : '--' }}</span>
        </span>

        <span *ngSwitchCase="'leadContactAssignmentDate'">
          <span> {{ rowData['dateInserted'] | date: 'short' }} </span>
        </span>

        <span class="text-cell-primary" *ngSwitchDefault>
          {{ rowData[col.field] }}
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="selectedColumns.length + 1" class="text-center">
        There are no leads for the selected filters
      </td>
    </tr>
  </ng-template>
</p-table>
