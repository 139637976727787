import { ConversationTargetPersonContext } from "src/app/layout/admin/admin.component";

export class SendSms {
    from?: string;
    to: string;
    body: string;
    leadId?: number;
    applicationId?: number;
    agentId?: number;
    borrowerId?: number;
    appendSmsSignature: boolean;
    mediaFiles?: any[];
    docFileIds?: number[];
}

export interface SmsChatRequestedModel {
    userPhone: string,
    betweenPhone?: string,
    userName: string,
    smsMessage?: Partial<NewSmsHistoryPosted>,
    openDrawer?: boolean
    openChat?: boolean;
    external?: boolean;

    conversationId?: number;

    conversationTargetPersonContext?: ConversationTargetPersonContext;
}

export interface NewSmsHistoryPosted {
    id: number,
    conversationId: number,
    from: string,
    to: string,
    body: string,
    direction: string,
    dateInserted: string,
    dateUpdated: string,
    updatedBy?: string,
    insertedBy?: string
    accountSid: string,
    companyId: number,
    messageSid: string,
    smsMessageSid: string,
    senderName?: string
}

export interface NewGroupMmsHistoryPosted {
  id: number,
  conversationId: number,
  senderName?: string,
  senderNumber: string
  body: string,
  direction: string,
  dateInserted: string,
  dateUpdated: string,
  updatedBy?: string,
  insertedBy?: string
  companyId: number,
  messageSid: string,
  participantSid: string,
  smsMessageSid: string,
}
