import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { CocEntryModel } from '../../../models/coc/coc-entry.model';
import { CocEntryTypeEnum } from '../../../models/coc/coc-entry-type.enum';
import { CocSummaryModel } from '../../../models/coc/coc-summary.model';
import { NgForm } from '@angular/forms';
import { CocService } from '../../../services/coc.service';
import { NotificationService } from '../../../services/notification.service';
import { Constants } from '../../../services/constants';
import { EnumerationService } from '../../../services/enumeration-service';
import { EnumerationItem } from '../../../models/simple-enum-item.model';
import { LoanApplication, PropertyWillBeEnum } from '../../../models';
import { ApplicationContextBoundComponent } from '../../../shared/components';
import { Subscription } from "rxjs";

@Component({
  selector: 'coc-entry',
  templateUrl: 'coc-entry.component.html'
})

export class CocEntryComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('cocForm') cocForm: NgForm | undefined;

  isLoading: boolean = true;
  application: LoanApplication;
  reasonForChanges: string[] = ['Acts of God, war, disaster or other emergency',
    'Borrower requested change',
    'Changes or inaccuracies in information relating to the Borrower or transaction',
    'New information regarding the Borrower or transaction',
    'Other'];
  dateOfDiscovery: Date = new Date();
  requestedReason: string = '';
  entries: CocEntryModel[];
  summary: CocSummaryModel[];

  propertyTypeOptions: EnumerationItem[] = [];
  refinancePurposes: EnumerationItem[] = [];
  occupancyOptions: EnumerationItem[] = [
    { name: PropertyWillBeEnum[PropertyWillBeEnum.Investment], value: PropertyWillBeEnum[PropertyWillBeEnum.Investment] },
    { name: PropertyWillBeEnum[PropertyWillBeEnum['Primary Residence']], value: PropertyWillBeEnum[PropertyWillBeEnum['Primary Residence']] },
    { name: PropertyWillBeEnum[PropertyWillBeEnum['Secondary Residence']], value: PropertyWillBeEnum[PropertyWillBeEnum['Secondary Residence']] }
  ];
  yesNoOptions: EnumerationItem[] = [];
  loanPurposes: EnumerationItem[] = [];

  @Output() changeTab = new EventEmitter<string>();

  private _contextSubscription: Subscription;
  private _loanInfoChangesSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _cocService: CocService,
    private readonly _enumsService: EnumerationService,
    private _notifyService: NotificationService) {
    super(injector);
    this._contextSubscription = this.applicationContextService.context.subscribe(context => {
      this.application = context.application;
    });
    if (this.applicationContext.application) {
      this.application = this.applicationContext.application;
    }
  }

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.propertyTypeOptions = enums[Constants.mortgageEnumerations.propertyType];
      this.refinancePurposes = enums[Constants.enumerations.refinancePurposes];
      this.yesNoOptions = this._enumsService.getYesNoEnumItems();
      this.loanPurposes = enums[Constants.enumerations.loanPurposes];

      this.entries = [
        { name: 'Rate', isSelected: false, type: CocEntryTypeEnum.Percent, newValue: null, info: null, warning: null, options: null },
        { name: 'Compensation Plan Change', isSelected: false, type: CocEntryTypeEnum.Text, newValue: null, info: null, warning: null, options: null },
        { name: 'Shoppable Services', isSelected: false, type: CocEntryTypeEnum.Text, newValue: null, info: null, warning: null, options: null },
        { name: 'Product / Program / Term', isSelected: false, type: CocEntryTypeEnum.Text, newValue: null, info: null, warning: null, options: null },
        { name: 'Occupancy', isSelected: false, type: CocEntryTypeEnum.Select, newValue: null, info: null, warning: null, options: this.occupancyOptions },
        { name: 'Government Recording Fees', isSelected: false, type: CocEntryTypeEnum.Text, newValue: null, info: null, warning: null, options: null },
        {
          name: 'Mortgage Insurance Program', isSelected: false, type: CocEntryTypeEnum.Text, newValue: null,
          info: 'Note: This may result in the APR increasing by more than .125% of the last disclosed APR, requiring a redisclosure.', warning: null,
          options: null
        },
        { name: 'Property Type', isSelected: false, type: CocEntryTypeEnum.Select, newValue: null, info: null, warning: null, options: this.propertyTypeOptions },
        { name: 'Correspondent Discount', isSelected: false, type: CocEntryTypeEnum.Text, newValue: null, info: null, warning: null, options: null },
        { name: 'Transfer Taxes', isSelected: false, type: CocEntryTypeEnum.Number, newValue: null, info: null, warning: null, options: null },
        {
          name: 'Base Loan Amount', isSelected: false, type: CocEntryTypeEnum.Currency, newValue: null,
          info: 'Note: This amount should be the BASE Loan Amount - do not include financed fees (MI Premiums).',
          warning: ' ACTION ITEM: You have requested a change in Loan Amount. Please note that Transfer Taxes and certain Title Fees are allowed to change with this request.',
          options: null
        },
        { name: 'Purpose of Refinance', isSelected: false, type: CocEntryTypeEnum.Select, newValue: null, info: null, warning: null, options: this.refinancePurposes },
        { name: 'Required Services', isSelected: false, type: CocEntryTypeEnum.Text, newValue: null, info: null, warning: null, options: null },
        {
          name: 'Appraised Value', isSelected: false, type: CocEntryTypeEnum.Currency, newValue: null,
          info: 'ACTION ITEM: You have requested a change in Appraisal Amount. Please ensure that the most recent Appraisal is uploaded.',
          warning: null, options: null
        },
        { name: 'Purpose of Loan', isSelected: false, type: CocEntryTypeEnum.Select, newValue: null, info: null, warning: null, options: this.loanPurposes },
        {
          name: 'Title - Premium for Lender\'s Coverage', isSelected: false, type: CocEntryTypeEnum.Text, newValue: null, info: null, warning: null,
          options: null
        },
        {
          name: 'Sales Price', isSelected: false, type: CocEntryTypeEnum.Currency, newValue: null, info: null,
          warning: 'ACTION ITEM: You have requested a change in Sales Price. Please ensure that a current Purchase Agreement or Addendum is uploaded. Please review Transfer Tax Fees and re-disclosure if needed.',
          options: null
        },
        { name: 'Escrow / Impounds', isSelected: false, type: CocEntryTypeEnum.Select, newValue: null, info: null, warning: null, options: this.yesNoOptions },
        {
          name: 'Title - Owner\'s Title Policy (optional)', isSelected: false, type: CocEntryTypeEnum.Text, newValue: null,
          info: 'NOTE: Mortgage Insurance and Flood Insurance, if required, must always be escrowed even if the borrower elects to not escrow for their taxes and or insurance',
          warning: null, options: null
        },
        { name: 'Seller Paid Fees', isSelected: false, type: CocEntryTypeEnum.Currency, newValue: null, info: null, warning: null, options: null },
        { name: 'Other', isSelected: false, type: CocEntryTypeEnum.Text, newValue: null, info: null, warning: null, options: null },
      ];

      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          context.currentMortgage.borrowers.forEach(borrower => {
            let borrowerFullName = borrower.firstName + ' ' + borrower.lastName;
            this.entries.splice(1, 0,
              {
                name: 'Credit Scores - ' + borrowerFullName, isSelected: false, type: CocEntryTypeEnum.Number, newValue: null,
                info: 'ACTION ITEM: You have requested a change in Credit Score. Please contact the AUS Help Desk to confirm that an updated credit score is on file.',
                warning: null, options: null
              });
          });
        }
      });

      this.isLoading = false;
    });

    this.summary = [];
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._contextSubscription) {
      this._contextSubscription.unsubscribe();
    }
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  public get cocEntryTypeEnum(): typeof CocEntryTypeEnum {
    return CocEntryTypeEnum;
  }

  public get checkedEntryExists(): boolean {
    return this.entries.some(function (item) {
      return item.isSelected === true;
    });
  }

  onClearClicked() {
    this.requestedReason = '';
    this.entries.forEach((entry) => {
      entry.isSelected = false;
      entry.newValue = null;
    });

    this.summary = [];
  }

  onNextClicked() {
    this.cocForm.form.markAllAsTouched();
    if (this.cocForm.form.valid) {
      this.entries.forEach((entry) => {
        if (entry.isSelected) {
          let prefix = entry.type === CocEntryTypeEnum.Currency ? '$' : null;
          let suffix = entry.type === CocEntryTypeEnum.Percent ? '%' : null;
          let newValue = entry.newValue;
          if (prefix !== null) {
            newValue = prefix + newValue;
          }
          if (suffix !== null) {
            newValue = newValue + suffix;
          }

          this.summary.push({
            dateOfDiscovery: this.dateOfDiscovery,
            dateAdded: new Date(),
            requestedValue: newValue,
            reasonForChange: this.requestedReason,
            requestedChange: entry.name,
            requestType: 'Broker',
            unbound: false
          }
          );
        }
      });
    }
  }

  addAdditionalChanges() {
    this.summary = [];
  }

  submitChangesForApproval() {
    let changes = {};
    this.summary.forEach((item) => {
      changes[item.requestedChange + " Change"] = 'New Value: ' + item.requestedValue;
    });

    this._cocService.createCoc(this.application.applicationId, changes).subscribe(() => {
      this._notifyService.showSuccess('Changes for approval successfully created!', 'Info');
      this.onClearClicked();
      this.changeTab.next('history');
    }, (error) => {
      this._notifyService.showError(error.error || 'Unable to create Change of Circumstance Request ', 'Create Change of Circumstance Request');
    });
  }
}
