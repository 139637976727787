import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { cloneDeep } from 'lodash';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'asset-details',
  templateUrl: 'asset-details.component.html'
})
export class AssetDetailsComponent implements OnInit {

  @Input()
  set asset(asset: Asset) {
    this._asset = cloneDeep(asset);
  }

  get asset(): Asset {
    return this._asset;
  }

  @Input()
  hasSaveCancelButtons: boolean = true;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApproved: EventEmitter<Asset> = new EventEmitter<Asset>();

  protected verificationStatusOptions: EnumerationItem[] = [];
  protected giftOrGrantSourceOptions: EnumerationItem[] = [];

  private _asset: Asset;

  constructor(private readonly _enumerationService: EnumerationService) {
    this._enumerationService.getMortgageEnumerations().subscribe(enums => {
      this.verificationStatusOptions = this._enumerationService.verificationStatuses;
      this.giftOrGrantSourceOptions = enums[Constants.mortgageEnumerations.giftGrantSource]
    })
  }

  ngOnInit() { }

  onSaveClicked = () => {
    this.changesApproved.emit(this._asset);
  }

  onVerificationStatusChanged = () => {
    if (this._asset.verificationStatus !== 'Verified') {
      this._asset.verifiedBy = null;
    }
  }

  onGiftOrGrantSourceChanged = () => {
    if (this._asset.giftOrGrantSource !== 'Other') {
      this._asset.giftOrGrantSourceOtherDescription = null;
    }
  }
}
