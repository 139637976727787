import { AfterViewInit, Component, ElementRef, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { delay, firstValueFrom, from, Observable, of } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/core/route-guards/pending-changes.guard';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { setClassConditionally, showNotSavedDialog } from '../quick-apply-utils';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DiffChecker } from '../../../../../../utils/diff-checker';
import { NotificationService } from '../../../../../services/notification.service';

@Component({
  selector: 'qa-fha-info',
  templateUrl: './qa-fha-info.component.html',
  styleUrls: ['./qa-fha-info.component.scss'],
})
export class QuickApplyFHAInfoComponent extends ApplicationContextBoundComponent
  implements OnInit, AfterViewInit, ComponentCanDeactivate {
  @ViewChild('body')
  protected body: ElementRef<HTMLElement>;

  protected form: FormGroup;
  private _pristineFormValue: any;

  protected persons: readonly Person[];

  protected get isInitialized(): boolean {
    return this._pristineFormValue != null;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;

    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private _isLoading: boolean = false;

  protected get canSave(): boolean {
    return !this.isLoading && this.isDirty();
  }

  constructor(
    private readonly injector: Injector,
    private readonly _router: Router,
    private readonly _formBuilder: FormBuilder,
    private readonly _notificationService: NotificationService,
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.persons = [
      { name: 'Denis', caivrid: '1234567890' },
      { name: 'John' },
    ];

    this.initForm();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    setTimeout(() => this.onResize());
  }

  canDeactivate = (): boolean | Observable<boolean> => {
    // Don't check when session expires
    if (!this.applicationContext) {
      return true;
    }

    return !this.isDirty(true);
  };

  confirm(): boolean | Observable<boolean> {
    return from(showNotSavedDialog(() => this.save()));
  }

  @HostListener('window:resize')
  private onResize() {
    const el = this.body.nativeElement;

    setClassConditionally({
      collection: [el],
      className: 'fha-body--large',
      condition: el.offsetWidth >= 800,
    });
  }

  // FIXME: Duplicated from qa-va-info.component.ts
  private isDirty(logDiff: boolean = false): boolean {
    const current = this.form?.value;
    const previous = this._pristineFormValue;
    if (current == null || previous == null) {
      return false;
    }

    const diffCheckers = [
      new DiffChecker(
        previous,
        current,
        '',
      ),
    ];

    return diffCheckers
      .map((e) => e.calculateDiff(logDiff))
      .some(Boolean);
  }

  private initForm() {
    const initializer = new FormInitializer(this._formBuilder);
    this.form = this._formBuilder.group({
      hudFhaAddendum: initializer.createHudFhaAddendum(),
      otherInfo: initializer.createOtherInfo(this.persons),
    });
    this._pristineFormValue = this.form.value;
  }

  protected async save(): Promise<boolean> {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return false;
    }

    this.isLoading = true;

    try {
      // TODO: Save data
      // Mimic saving
      await firstValueFrom(of(null).pipe(
        delay(1000),
      ));

      this._pristineFormValue = this.form.value;

      this._notificationService.showSuccess(
        'The loan info has been saved successfully.',
        'Success',
      );

      return true;
    } catch (e) {
      console.error(e);
      const message = e?.message
        || 'An error occurred while saving the loan info.';

      this._notificationService.showError(
        message,
        'Error',
      );

      return false;
    } finally {
      this.isLoading = false;
    }
  }
}

class FormInitializer {
  constructor(
    private readonly _formBuilder: FormBuilder,
  ) {
  }

  createHudFhaAddendum(): FormGroup {
    return this._formBuilder.group({
      agencyCaseNumber: new FormControl(''),
      sectionOfTheAct: new FormControl(''),
      lenderOrMortgageIdCode: new FormControl(''),
      sponsorOrAgentIdCode: new FormControl(''),
    });
  }

  createPersonGroup(person: Person): FormGroup {
    return this._formBuilder.group({
      caivrid: new FormControl(person.caivrid),
    });
  }

  private createPersonsGroup(persons: readonly Person[]): FormGroup {
    const controls = persons.reduce((acc, person, i) => {
        acc[`person${i}`] = this.createPersonGroup(person);
        return acc;
      },
      {},
    );
    return this._formBuilder.group(controls);
  }

  createOtherInfo(persons: readonly Person[]): FormGroup {
    return this._formBuilder.group({
      positiveRentalHistory: new FormControl(''),
      mipRefundAmount: new FormControl(0),
      sellerConcession: new FormControl(''),
      sponsorOriginatorEin: new FormControl(''),
      mortgageCreditCert: new FormControl(''),
      persons: this.createPersonsGroup(persons),
    });
  }
}

class Person {
  constructor(
    public readonly name: string,
    public readonly caivrid?: string,
  ) {
  }
}
