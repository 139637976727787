<form #borrowerForm="ngForm" name="borrowerForm{{borrower.borrowerId}}" id="borrowerForm{{borrower.borrowerId}}">
  <contact-info
    [isReadOnly]="isReadOnly"
    [borrower]="borrower"
    [loanBorrower]="loanBorrower"
    [hiddenFields]="hiddenFields"
    [inEditMode]="inEditMode"
    [isCorrespondent]="isCorrespondent"
    [urlaFieldsConfig]="urlaFieldsConfig"
    (signingRoleChanged)="onSigningRoleChanged()"
    (borrowerValidityStatusChanged)="onBorrowerValidityStatusChanged()"
  ></contact-info>

  <div class="grid-layout-spacer col-sm-2 col-xs-6">&nbsp;</div>

  <address-info
    [isReadOnly]="isReadOnly"
    [borrower]="borrower"
    [isSubjectPropertyPresentAddress]="isSubjectPropertyPresentAddress"
    [isSubjectPropertyPickerVisibleForPresentAddress]="isSubjectPropertyPickerVisibleForPresentAddress"
    [inEditMode]="inEditMode"
    [urlaFieldsConfig]="urlaFieldsConfig"
    (presentStreetAddressChanged)="onPresentStreetAddressChanged($event)"
    (presentAddressCityBlurred)="onPresentAddressCityBlurred($event)"
    (presentAddressStateBlurred)="onPresentAddressStateBlurred($event)"
    (presentAddressZipCodeBlurred)="onPresentAddressZipCodeBlurred($event)"
    (residencyBasisChanged)="onPresidentAddressResidencyBasisChanged($event)"
    (presentAddressSubjectPropertyStatusChanged)="onPresentAddressSubjectPropertyStatusChanged($event)"
    (pushPresentAddressToSubjectPropertyClicked)="onPushPresentAddressToSubjectPropertyClicked()"
    (pullPresentAddressSubjectPropertyClicked)="onPullPresentAddressSubjectPropertyClicked($event)">
  </address-info>

  <div class="grid-layout-spacer col-sm-2 col-xs-6">&nbsp;</div>

  <employment-income *ngIf="!isTitleOnlyOrNonTitledSpouse"
    [isReadOnly]="isReadOnly" [borrower]="borrower" [applicationId]="applicationId" [systemLevel]="systemLevel"
    [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig">
  </employment-income>

  <qualifying-income *ngIf="isTitleOnlyOrNonTitledSpouse"
    [isReadOnly]="isReadOnly" [borrower]="borrower" [inEditMode]="inEditMode" 
    [urlaFieldsConfig]="urlaFieldsConfig">
  </qualifying-income>

  <div class="grid-layout-spacer col-sm-2 col-xs-6">&nbsp;</div>

  <title-information-borrower
    [borrower]='borrower'
    [inEditMode]='inEditMode'
    [isReadOnly]='isReadOnly'
    [urlaFieldsConfig]='urlaFieldsConfig'
  ></title-information-borrower>
</form>
