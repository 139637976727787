<div class='row align-items-center'>
  <!-- Title & Subtitle -->
  <div class='col'>
    <h5 class='mt-0 mb-1'>
      Table of Residual Incomes by Region
    </h5>

    <span class='text-muted'>
      For loan amounts of {{ minLoanAmount | currency }} and above
    </span>
  </div>

  <!-- Close Button -->
  <div class='col-auto'>
    <button
      (click)='close.emit()'
      aria-label='Close'
      title='Close'
      class='btn close-btn'
      type='button'
    ></button>
  </div>
</div>

<table class='table table-hover table-condensed mt-4'>
  <thead>
    <tr>
      <th>Household Size</th>
      <th>Northeast</th>
      <th>Midwest</th>
      <th>South</th>
      <th>West</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor='let rIncome of residualIncomes'>
      <td>{{ rIncome.householdSizeLabel }}</td>
      <td>{{ rIncome.northeast | currency }}</td>
      <td>{{ rIncome.midwest | currency }}</td>
      <td>{{ rIncome.south | currency }}</td>
      <td>{{ rIncome.west | currency }}</td>
    </tr>
  </tbody>
</table>

<qa-warning-note>
  Borrowers on Active Duty will get {{ rebatePercentage | percent }} rebate on
  the above values.
</qa-warning-note>

<h6 class='mt-4 mb-3'>
  Key to Geographic Regions Used in the Preceding Table
</h6>

<!-- Region - State Mapping -->
<div class='geo-regions'>
  <ng-container *ngFor='let region of residualRegions'>
    <b>{{ region.type }}</b>

    <span class='geo-regions__separator'></span>

    <div class='state-names'>
      <span
        *ngFor='let state of region.states'
        class='state-names__name'
      >
        {{ state }}
      </span>
    </div>
  </ng-container>
</div>
