import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'add-explanation-button',
  templateUrl: './add-explanation-button.component.html',
  styleUrls: ['./add-explanation-button.component.scss']
})
export class AddExplanationButtonComponent {
  @Output() clickAdd = new EventEmitter<void>();

  protected onClickAdd(): void {
    this.clickAdd.emit();
  }
}
