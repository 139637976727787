import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EditorMode } from '../../../../../../../shared/components';
import { Employment } from '../../../../../../../models';
import { NgForm } from '@angular/forms';
import { toSafeDate } from '../../../quick-apply-utils';

export interface QaFiEmploymentDateResult {
  startDate?: string;
  endDate?: string;
  hasErrors: boolean;
}

@Component({
  selector: 'qa-fi-employment-date',
  templateUrl: './qa-fi-employment-date.component.html',
  styleUrls: ['./qa-fi-employment-date.component.scss']
})
export class QaFiEmploymentDateComponent implements OnInit, OnChanges {
  @Input() employment?: Employment;

  @Output() save = new EventEmitter<QaFiEmploymentDateResult>();
  @Output() cancel = new EventEmitter<void>();

  @ViewChild('form') formElement: NgForm;

  protected readonly EditorMode = EditorMode;

  protected startDate: string | undefined;
  protected endDate: string | undefined;

  protected maxStartDate: Date = new Date(); // endDate or today
  protected minEndDate: Date | null = null; // starDate or null
  protected maxEndDate: Date = new Date(); // This is always today.

  ngOnInit(): void {
    // It will be initialized in ngOnChanges if the employment is provided
    // initially.
    if (this.employment == null) {
      this.initForm();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const employmentChanges = changes.employment;
    if (employmentChanges != null) {
      this.initForm(this.employment);
      setTimeout(() => this.formElement.form.markAllAsTouched());
    }
  }

  private initForm(employment?: Employment): void {
    this.startDate = employment?.startDate;
    this.endDate = employment?.endDate;

    // Set the initial values for the min and max dates.
    this.onChangeStartDate(this.startDate);
    this.onChangeEndDate(this.endDate);
  }

  protected onChangeStartDate(date: string | undefined): void {
    this.minEndDate = toValidationSafeDate(date);
  }

  protected onChangeEndDate(date: string | undefined): void {
    this.maxStartDate = toValidationSafeDate(date) || new Date();
  }

  protected onSave(): void {
    this.formElement.form.markAllAsTouched();

    this.save.emit({
      startDate: this.startDate || undefined,
      endDate: this.endDate || undefined,
      hasErrors: this.formElement.form.invalid,
    });
  }

  protected onCancel(): void {
    this.cancel.emit();
  }
}

function toValidationSafeDate(date: string | undefined): Date | null {
  if (date?.length !== 10)
    return null;

  return toSafeDate(date) ?? null;
}
