import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VaInfoForm, VaInfoFormAdapter } from '../qa-va-info.model';
import { UrlaBorrower, UrlaMortgage } from '../../../../../urla/models/urla-mortgage.model';
import { IncomeLike } from '../../qa-financial-info/income-like.model';
import { ApplicationContext, HousingExpense, Liability } from '../../../../../../models';
import { Constants, Enumerations } from '../../../../../../services/constants';
import { EnumerationService } from '../../../../../../services/enumeration-service';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumerationItem } from '../../../../../../models/simple-enum-item.model';
import { startWith, Subscription } from 'rxjs';
import { MortgageCalculationService } from '../../../../../urla/services/mortgage-calculation.service';
import { ApplicationContextService } from '../../../../../../services/application-context.service';
import { ResidualChartModalComponent } from './residual-chart-modal/residual-chart-modal.component';

@Component({
  selector: 'qa-va-income-details',
  templateUrl: './qa-va-income-details.component.html',
  styleUrls: ['./qa-va-income-details.component.scss']
})
export class QaVaIncomeDetailsComponent implements OnChanges, OnInit,
  AfterViewInit, OnDestroy {

  @Input() mortgage: UrlaMortgage;
  @Input() formGroup: FormGroup<VaInfoForm>;
  @Input() formAdapter: VaInfoFormAdapter;

  @ViewChild('residualChartButton')
  residualChartButton: TemplateRef<HTMLButtonElement>;
  @ViewChild('residualChartModal')
  residualChartModal: ResidualChartModalComponent;

  private _isViewInitialized: boolean = false;

  private _applicationContext: ApplicationContext;

  private _isNavigating: boolean = false;

  // Section values
  private _incomeLikes: readonly IncomeLike[] = [];
  private _liabilities: readonly Liability[] = [];
  private _housingExpense: HousingExpense | null = null;
  private _residualIncome: ResidualIncome | null = null;

  protected vaTitleVestingTypeOptions: EnumerationItem[] = [];
  protected vaCashOutRefinanceTypeOptions: EnumerationItem[] = [];

  protected sections: Section[] = [];
  protected residualIncomeSection: Section = new Section();
  private _resetSectionsTimeoutHandle: number | null = null;
  protected debtToIncomeRatio: number = 0;
  protected selectedBorrower: any;

  private _applicationContextSubscription: Subscription | null = null;
  private _initEnumerationsSubscription: Subscription | null = null;
  private _vaEntitlementIsManualSubscription: Subscription | null = null;
  private _monthlyMaintenanceAndUtilitiesIsOverriddenSubscription: Subscription | null = null;

  constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _enumerationService: EnumerationService,
    private readonly _calculationService: MortgageCalculationService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const mortgageChange = changes.mortgage;
    if (mortgageChange && mortgageChange.currentValue) {
      this.initMortgage(mortgageChange.currentValue);
    }

    const formGroupChange = changes.formGroup;
    if (formGroupChange && formGroupChange.currentValue) {
      this.initFormGroup();
    }
  }

  ngOnInit() {
    this.subscribeToApplicationContext();
    this.initEnumerations();

    if (this.mortgage == null) {
      this.initMortgage();
    }
  }

  ngAfterViewInit() {
    this._isViewInitialized = true;
  }

  ngOnDestroy() {
    this._initEnumerationsSubscription?.unsubscribe();
    this._vaEntitlementIsManualSubscription?.unsubscribe();
    this._monthlyMaintenanceAndUtilitiesIsOverriddenSubscription?.unsubscribe();
  }

  private subscribeToApplicationContext(): void {
    this._applicationContextSubscription?.unsubscribe();

    this._applicationContextSubscription = this._applicationContextService
      .context.subscribe({
        next: (context) => {
          this._applicationContext = context;
          this.initDebtToIncomeRatio();
        },
        complete: () => {
          this._applicationContextSubscription = null;
        },
      });
  }

  private initDebtToIncomeRatio(): void {
    const context = this._applicationContext;
    const calculations = context.currentMortgageCalculationDetails;
    this.debtToIncomeRatio = calculations.dti?.frontEndDti || 0;
  }

  private initEnumerations(): void {
    this._initEnumerationsSubscription?.unsubscribe();

    const initEnumerations = (enums: Enumerations) => {
      this.vaTitleVestingTypeOptions = enums[Constants.mortgageEnumerations.vaTitleVestingType];
      this.vaCashOutRefinanceTypeOptions = enums[Constants.mortgageEnumerations.vaCashOutRefinanceType];
    };

    this._initEnumerationsSubscription = this._enumerationService
      .getMortgageEnumerations().subscribe({
        next: initEnumerations,
        complete: () => {
          this._initEnumerationsSubscription = null;
        },
      });
  }

  private initFormGroup(): void {
    this.subscribeToVaEntitlementIsManual();
    this.subscribeToMonthlyMaintenanceAndUtilitiesIsOverridden();
  }

  private subscribeToVaEntitlementIsManual(): void {
    this._vaEntitlementIsManualSubscription?.unsubscribe();

    const vaEntitlement =
      this.formGroup.controls.incomeDetails.controls.generalInfo.controls.vaEntitlement;
    const vaEntitlementIsManual = vaEntitlement.controls.isManual;
    const valueChanges$ = vaEntitlementIsManual.valueChanges.pipe(
      startWith(vaEntitlementIsManual.value),
    );
    this._vaEntitlementIsManualSubscription = valueChanges$.subscribe({
      next: (isManual) => {
        const vaEntitlementAmount =
          vaEntitlement.controls.amount;
        if (isManual) {
          vaEntitlementAmount.enable();
        } else {
          vaEntitlementAmount.disable();
          vaEntitlementAmount.setValue(0);
        }
      },
      complete: () => {
        this._vaEntitlementIsManualSubscription = null;
      },
    });
  }

  private subscribeToMonthlyMaintenanceAndUtilitiesIsOverridden(): void {
    this._monthlyMaintenanceAndUtilitiesIsOverriddenSubscription?.unsubscribe();

    const monthlyMaintenanceAndUtilities =
      this.formGroup.controls.incomeDetails.controls.vaResidualInfo.controls.monthlyMaintenanceAndUtilities;
    const isOverridden = monthlyMaintenanceAndUtilities.controls.isOverridden;
    const valueChanges$ = isOverridden.valueChanges.pipe(
      startWith(isOverridden.value),
    );
    this._monthlyMaintenanceAndUtilitiesIsOverriddenSubscription = valueChanges$.subscribe({
      next: (isOverridden) => {
        const controls = monthlyMaintenanceAndUtilities.controls;
        const overrides = controls.overrides;
        if (isOverridden) {
          overrides.enable();
          controls.totalSquareFootage.setValue(0);
        } else {
          overrides.disable();
          overrides.setValue({
            maintenanceExpenses: 0,
            utilitiesExpenses: 0,
          });
        }
      },
      complete: () => {
        this._monthlyMaintenanceAndUtilitiesIsOverriddenSubscription = null;
      },
    });
  }

  private initMortgage(mortgage?: UrlaMortgage): void {
    mortgage ??= this.mortgage ?? new UrlaMortgage();

    // this.selectedBorrower = mortgage.borrowers.find(bor => bor.borrowerId == mortgage.governmentLoanDetail.vaBenefitUsingBorrowerId);

    const initIncomeLikes = (): void => {
      const borrowers = mortgage.borrowers;
      const createIncomeLike = (arrayReference: IncomeLike['arrayReference']) =>
        (income: IncomeLike['value']) =>
          new IncomeLike({ value: income, arrayReference });
      this._incomeLikes = borrowers.flatMap(borrower => [
        ...(borrower.nonEmploymentIncomes.map(createIncomeLike(borrower.nonEmploymentIncomes))),
        ...(borrower.employments.map(createIncomeLike(borrower.employments))),
      ]);
    };
    initIncomeLikes();

    this._liabilities = mortgage.liabilities;
    this._housingExpense = mortgage.proposedHousingExpense;
    // FIXME: Residual income is not available in the model
    this._residualIncome = new ResidualIncome();

    this.resetSections();
  }

  protected isCollapsed(collapseButton: HTMLElement): boolean {
    return collapseButton.classList.contains('collapsed');
  }

  private resetSections(): void {
    const resetSections = (): void => {
      const grossMonthlyIncome = this._incomeLikes.reduce((acc, income) => {
        const value = Number(income.monthlyIncome) || 0;
        acc.value += value;
        acc.items.push({ label: income.incomeTypeName, value });
        return acc;
      }, { value: 0, items: [] });

      const housingExpense = ((housingExpense: HousingExpense) => [
        {
          // FIXME: Rate is not available in the model
          label: `Principal & Interest Payment @ Rate ${0}%`,
          value: housingExpense.firstMortgagePrincipalAndInterest,
        },
        {
          label: 'Other Financing',
          value: housingExpense.otherMortgageLoanPrincipalAndInterest,
        },
        {
          label: 'Homeowners Insurance',
          value: housingExpense.homeownersInsurance,
        },
        {
          label: 'Supplemental',
          value: housingExpense.supplementalPropertyInsurance,
        },
        {
          label: 'Real Estate Tax',
          value: housingExpense.realEstateTax,
        },
        {
          label: 'Mortgage Insurance',
          value: housingExpense.mortgageInsurance,
        },
        {
          label: 'Association Dues',
          value: housingExpense.homeownersAssociationDuesAndCondominiumFees,
        },
        {
          label: 'Other',
          value: housingExpense.otherHousingExpense,
        },
      ])(this._housingExpense ?? new HousingExpense()).reduce((acc, item) => {
        const value = Number(item.value) || 0;
        acc.value += value;
        acc.items.push({ label: item.label, value });
        return acc;
      }, { value: 0, items: [] });

      const monthlyDebtAndObligations = {
        value: this._calculationService.calculateLiabilitySubTotal(this.mortgage)
          .monthlyPaymentSubTotal,
        items: this._liabilities.reduce((acc, liability) => {
          const value = Number(liability.monthlyPayment) || 0;
          acc.push({ label: liability.typeOfLiability, value });
          return acc;
        }, []),
      };

      this.sections = [
        new Section({
          title: '1. Gross Monthly Income',
          isCollapsible: true,
          value: grossMonthlyIncome.value,
          isEditable: true,
          edit: this.tryNavigateToFinancialInfo,
          items: grossMonthlyIncome.items,
          emptyDescription: 'No income',
        }),
        new Section({
          title: '2. Proposed Monthly Housing Expense (PITI)',
          isCollapsible: true,
          value: housingExpense.value,
          isEditable: true,
          edit: this.tryNavigateToLoanInfo,
          items: housingExpense.items,
          emptyDescription: 'No housing expense',
        }),
        new Section({
          title: '3. Monthly Debt and Obligations (Liabilities)',
          isCollapsible: true,
          value: monthlyDebtAndObligations.value,
          isEditable: true,
          edit: this.tryNavigateToFinancialInfo,
          items: monthlyDebtAndObligations.items,
          emptyDescription: 'No liabilities',
        }),
      ];

      this.residualIncomeSection = ((residualIncome) => new Section({
        title: '6. Residual Income',
        templateSubtitle: null,
        value: residualIncome.totalAmount,
        items: [
          {
            label: 'Amount Required',
            templateSubtitle: this.residualChartButton,
            value: residualIncome.amountRequired,
          },
          {
            label: 'Actual amount (1) minus (2), (3), (4), (5)',
            value: residualIncome.actualAmountRemaining,
          },
        ],
        emptyDescription: 'No data',
      }))(this._residualIncome ?? new ResidualIncome());
    }

    if (this._isViewInitialized) {
      resetSections();
    } else {
      // If there is a pending reset, cancel it.
      this._resetSectionsTimeoutHandle != null &&
      clearTimeout(this._resetSectionsTimeoutHandle);

      // Wait for the view to initialize before resetting the sections as they
      // depend on the view to be initialized.
      this._resetSectionsTimeoutHandle = setTimeout(() => {
        resetSections();
        this._resetSectionsTimeoutHandle = null;
      });
    }
  }

  private tryNavigateTo(navigate: (router: Router) => Promise<boolean>): void {
    if (this._isNavigating) {
      return;
    }

    this._isNavigating = true;
    (async () => {
      try {
        await navigate(this._router);
      } catch (e) {
        console.error(e);
      } finally {
        this._isNavigating = false;
      }
    })();
  }

  private tryNavigateToFinancialInfo = (): void => {
    this.tryNavigateTo(router => router.navigate(
      ['../qa-financial-info'],
      { relativeTo: this._route },
    ));
  };

  private tryNavigateToLoanInfo = (): void => {
    this.tryNavigateTo(router => router.navigate(
      ['../qa-loan-info'],
      { relativeTo: this._route },
    ));
  };

  protected openResidualChart(): void {
    this.residualChartModal.open();
  }
}

class ResidualIncome {
  totalAmount: number = 0;
  amountRequired: number = 0;
  actualAmountRemaining: number = 0;
}

class Section {
  title: string;
  templateSubtitle?: TemplateRef<any>;
  isCollapsible: boolean = false;
  isCollapsed: boolean = true;
  value: number = 0;
  isEditable: boolean = false;
  edit?: () => void;
  items: SectionListItem[] = [];
  emptyDescription: string = 'No data';

  constructor(init?: Partial<Section>) {
    Object.assign(this, init);
  }
}

interface SectionListItem {
  label: string;
  templateSubtitle?: TemplateRef<any>;
  value: number;
}
