import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import {
  CocReason,
  DisclosureTracking,
  DisclosureValidationResponse
} from "../models/disclosure-tracking.model";

@Injectable()
export class DisclosureTrackingService {

  constructor(private readonly _dataService: DataService) {
  }

  getDisclosureTrackingHistory = (mortgageId: number): Observable<DisclosureTracking[]> => {
    const url = `api/disclosure/${mortgageId}/tracking`;
    return this._dataService.get(url);
  }

  getCocReasons = (): Observable<CocReason[]> => {
    const url = `api/disclosure/coc-reasons`;
    return this._dataService.get(url);
  }

  updateDisclosure = (item: DisclosureTracking) => {
    const url = `api/disclosure/tracking/${item.disclosureTrackingId}`;
    return this._dataService.post(url, item);
  }

  checkIfDisclosureIsValid = (
    applicationId: number
  ): Observable<DisclosureValidationResponse> => {
    const url = `api/disclosure/${applicationId}/validation`;
    return this._dataService.get(url);
  };

  getLatestDisclosedFees = (mortgageId: number): Observable<Array<{ loanFeeHistoryId: number }>> => {
    const url = `api/disclosure/${mortgageId}/tracking/latest-disclosed-fees`;
    return this._dataService.get(url);
  }
}
