<form #subjectPropertyValuationForm="ngForm" novalidate id="subjectPropertyValuationForm"
  name="subjectPropertyValuationForm" class="p-3">
  <div class="row mb-3">
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="propertyInspectionType">Property Inspection Type</label>
      <select class="form-select" name="propertyInspectionType" id="propertyInspectionType"
        [(ngModel)]="subjectPropertyValuation.propertyInspectionType" (ngModelChange)="propertyInspectionTypeChange()"
        [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType']?.required == 'required' ||
        urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType']?.required == 'requested'"
        #propertyInspectionType_input="ngModel"
        [ngClass]="{'is-invalid' : propertyInspectionType_input && propertyInspectionType_input.touched && propertyInspectionType_input.invalid}">
        <option [ngValue]="null">-- Select One --</option>
        <option [ngValue]="option.value" *ngFor="let option of propertyInspectionTypes">
          {{ option.name }}
        </option>
      </select>
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="propertyInspectionTypeOtherDescription">&nbsp;</label>
      <input class="form-control" [disabled]="subjectPropertyValuation.propertyInspectionType != 'Other'"
        name="propertyInspectionTypeOtherDescription" placeholder="Other Description"
        [(ngModel)]="subjectPropertyValuation.propertyInspectionTypeOtherDescription"
        [required]="(urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType']?.required == 'required' ||
        urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.propertyInspectionType']?.required == 'requested' ) && subjectPropertyValuation.propertyInspectionType == 'Other' "
        #propertyInspectionTypeOtherDescription_input="ngModel"
        [ngClass]="{'is-invalid' : propertyInspectionTypeOtherDescription_input && propertyInspectionTypeOtherDescription_input.touched && propertyInspectionTypeOtherDescription_input.invalid}" />
      <div class="invalid-feedback">This field is required.</div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="appraisalFormType">Appraisal Form Type</label>
      <select class="form-select" name="appraisalFormType" id="appraisalFormType"
        [(ngModel)]="subjectPropertyValuation.appraisalFormType" (ngModelChange)="appraisalFormTypeChange()" [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType']?.required == 'required' ||
        urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType']?.required == 'requested'"
        #appraisalFormType_input="ngModel"
        [ngClass]="{'is-invalid' : appraisalFormType_input && appraisalFormType_input.touched && appraisalFormType_input.invalid}">
        <option [ngValue]="null">-- Select One --</option>
        <option [ngValue]="option.value" *ngFor="let option of appraisalFormTypes">
          {{ option.name }}
        </option>
      </select>
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="appraisalFormTypeOtherDescription">&nbsp;</label>
      <input class="form-control" [disabled]="subjectPropertyValuation.appraisalFormType != 'Other'"
        name="appraisalFormTypeOtherDescription" placeholder="Other Description"
        [(ngModel)]="subjectPropertyValuation.appraisalFormTypeOtherDescription"
        [required]="(urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType']?.required == 'required' ||
        urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalFormType']?.required == 'requested' ) && subjectPropertyValuation.appraisalFormType == 'Other' "
        #appraisalFormTypeOtherDescription_input="ngModel"
        [ngClass]="{'is-invalid' : appraisalFormTypeOtherDescription_input && appraisalFormTypeOtherDescription_input.touched && appraisalFormTypeOtherDescription_input.invalid}" />
      <div class="invalid-feedback">This field is required.</div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="appraisalMethodType">Appraisal Method Type</label>
      <select class="form-select" name="appraisalMethodType" id="appraisalMethodType"
        [(ngModel)]="subjectPropertyValuation.appraisalMethodType" (ngModelChange)="appraisalMethodTypeChange()" [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType']?.required == 'required' ||
        urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType']?.required == 'requested'"
        #appraisalMethodType_input="ngModel"
        [ngClass]="{'is-invalid' : appraisalMethodType_input && appraisalMethodType_input.touched && appraisalMethodType_input.invalid}">
        <option [ngValue]="null">-- Select One --</option>
        <option [ngValue]="option.value" *ngFor="let option of appraisalMethodTypes">
          {{ option.name }}
        </option>
      </select>
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="appraisalMethodTypeOtherDescription">&nbsp;</label>
      <input class="form-control" [disabled]="subjectPropertyValuation.appraisalMethodType != 'Other'"
        name="appraisalMethodTypeOtherDescription" placeholder="Other Description"
        [(ngModel)]="subjectPropertyValuation.appraisalMethodTypeOtherDescription"
        [required]="(urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType']?.required == 'required' ||
        urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalMethodType']?.required == 'requested' ) && subjectPropertyValuation.appraisalMethodType == 'Other' "
        #appraisalMethodTypeOtherDescription_input="ngModel"
        [ngClass]="{'is-invalid' : appraisalMethodTypeOtherDescription_input && appraisalMethodTypeOtherDescription_input.touched && appraisalMethodTypeOtherDescription_input.invalid}" />
      <div class="invalid-feedback">This field is required.</div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="effectiveDate">Effective Date</label>
      <date-input [name]="'effectiveDate'" [id]="'effectiveDate'" [(ngModel)]="subjectPropertyValuation.effectiveDate"
        [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.effectiveDate']?.required == 'required' ||
        urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.effectiveDate']?.required == 'requested'"></date-input>
    </div>
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="estimatedRemainingEconomicLifeYears">Estimated Remaining Economic Life Years</label>
      <input class="form-control" numeric [allowNegative]="false" name="estimatedRemainingEconomicLifeYears"
        [(ngModel)]="subjectPropertyValuation.estimatedRemainingEconomicLifeYears" number
        [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.estimatedRemainingEconomicLifeYears']?.required == 'required' ||
        urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.estimatedRemainingEconomicLifeYears']?.required == 'requested'" #estimatedRemainingEconomicLifeYears_input="ngModel"
        [ngClass]="{'is-invalid' : estimatedRemainingEconomicLifeYears_input && estimatedRemainingEconomicLifeYears_input.touched && estimatedRemainingEconomicLifeYears_input.invalid}">
      <div class="invalid-feedback">This field is required.</div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="appraiserCompanyName">Appraiser Company Name</label>
      <input class="form-control" name="appraiserCompanyName" [(ngModel)]="subjectPropertyValuation.appraiserCompanyName"
        [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserCompanyName']?.required == 'required' ||
      urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserCompanyName']?.required == 'requested'"
        #appraiserCompanyName_input="ngModel"
        [ngClass]="{'is-invalid' : appraiserCompanyName_input && appraiserCompanyName_input.touched && appraiserCompanyName_input.invalid}" />
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="appraiserName">Appraiser Name</label>
      <input class="form-control" name="appraiserName" [(ngModel)]="subjectPropertyValuation.appraiserName" [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserName']?.required == 'required' ||
      urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraiserName']?.required == 'requested'"
        #appraiserName_input="ngModel"
        [ngClass]="{'is-invalid' : appraiserName_input && appraiserName_input.touched && appraiserName_input.invalid}" />
      <div class="invalid-feedback">This field is required.</div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="appraisalIdentifier">Appraisal ID</label>
      <input class="form-control" name="appraisalIdentifier" [(ngModel)]="subjectPropertyValuation.appraisalIdentifier"
        [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalIdentifier']?.required == 'required' ||
      urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalIdentifier']?.required == 'requested'"
        #appraisalIdentifier_input="ngModel"
        [ngClass]="{'is-invalid' : appraisalIdentifier_input && appraisalIdentifier_input.touched && appraisalIdentifier_input.invalid}" />
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="hasVAStaffAppraisalReviewValuationAdjustment">Has VA Staff Appraisal Review?</label>
      <div class="form-check">
        <input class="form-check-input" name="hasVAStaffAppraisalReviewValuationAdjustment" type="checkbox"
          [(ngModel)]="subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment"
          [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment']?.required == 'required' ||
      urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment']?.required == 'requested'"
          #hasVAStaffAppraisalReviewValuationAdjustment_input="ngModel"
          [ngClass]="{'is-invalid' : hasVAStaffAppraisalReviewValuationAdjustment_input && hasVAStaffAppraisalReviewValuationAdjustment_input.touched && hasVAStaffAppraisalReviewValuationAdjustment_input.invalid}" />
        <div class="invalid-feedback">This field is required.</div>
      </div>
    </div>
  </div>
  <div class="row mb-3" *ngIf="subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment">
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="vaStaffAppraisalReviewerIdentifier">VA Staff Appraisal Reviewer ID</label>
      <input class="form-control" name="vaStaffAppraisalReviewerIdentifier"
        [(ngModel)]="subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier"
        [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier']?.required == 'required' ||
        urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier']?.required == 'requested'" #vaStaffAppraisalReviewerIdentifier_input="ngModel"
        [ngClass]="{'is-invalid' : vaStaffAppraisalReviewerIdentifier_input && vaStaffAppraisalReviewerIdentifier_input.touched && vaStaffAppraisalReviewerIdentifier_input.invalid}" />
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="form-group col-md-6 mb-0">
      <label class="mb-2" for="vaStaffAppraisalReviewValueNotificationDate">VA Staff Appr. Review Value Notification
        Date</label>
      <date-input [name]="'vaStaffAppraisalReviewValueNotificationDate'"
        [id]="'vaStaffAppraisalReviewValueNotificationDate'"
        [(ngModel)]="subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate"
        [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate']?.required == 'required' ||
        urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate']?.required == 'requested'"></date-input>
    </div>
  </div>
  <div class="row mb-3">
    <div class="form-group col-md-12 mb-0">
      <label class="form-label" for="appraisalComments">Appraisal Comments</label>
      <textarea id="appraisalComments" name="appraisalComments" class="form-control" style="height: 95px" rows="2"
        [(ngModel)]="subjectPropertyValuation.appraisalComments" [required]="urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalComments']?.required == 'required' ||
        urlaFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.appraisalComments']?.required == 'requested'"
        #appraisalComments_input="ngModel"
        [ngClass]="{'is-invalid' : appraisalComments_input && appraisalComments_input.touched && appraisalComments_input.invalid}"></textarea>
      <div class="invalid-feedback">This field is required.</div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-12 text-end">
      <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
      <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
        Save
      </button>
    </div>
  </div>
</form>
