import { Component, Input, OnInit } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { Asset } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { UtilityService } from '../../services/utility.service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

@Component({
  selector: 'gifts-or-grants',
  templateUrl: 'gifts-or-grants.component.html',
  styleUrls: ['./gifts-or-grants.component.scss'],
  viewProviders: [formViewProvider]
})
export class GiftsOrGrantsComponent implements OnInit {

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    if (mortgage && mortgage.mortgageId) {
      this._mortgage = mortgage;
      this.initialize();
    }
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  assetTypes: EnumerationItem[];

  @Input()
  yesNoOptions: EnumerationItem[];

  @Input()
  isReadOnly: boolean = false;

  @Input()
  giftGrantSources: EnumerationItem[];

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  possibleAccountOwners: Array<Select2OptionData> = [];

  assets: Array<Asset> = [];

  originalGiftOrGrant: Asset;

  omitsFromTotal: Array<number> = [];

  editingIx: number = -1;

  private _mortgage: UrlaMortgage;

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false
  };

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _utilityService: UtilityService) { }

  ngOnInit(): void {
    this.assetTypes = this.assetTypes.filter(t => this.filterAssetType(t.value));
  }

  onAssetOwnersChanged = (asset: Asset) => {
    this.setAssetOwnerName(asset);
  }

  getBorrowerName = (borrId: number) => {
    const borrower = this.possibleAccountOwners.find(owner => Number(owner.id) == borrId);
    return borrower?.text || '';
  }

  addGiftOrGrant = () => {
    let giftOrGrant = new Asset();
    giftOrGrant.assetId = this._utilityService.getUniqueId();
    this.assets.push(giftOrGrant);
    this.mortgage.assets.push(giftOrGrant);
    if (this.editingIx != -1) {
      this.assets[this.editingIx] = this.originalGiftOrGrant;
    }
    this.editGiftOrGrant(this.assets.length - 1, giftOrGrant);
  }

  editGiftOrGrant = (index: number, giftOrGrant: Asset) => {
    this.originalGiftOrGrant = _.cloneDeep(giftOrGrant);
    this.editingIx = index;
  }

  cancelEdit = () => {
    this.assets[this.editingIx] = this.originalGiftOrGrant;
    this.editingIx = -1;
  }

  deleteGiftOrGrant = (removeIx: number) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        self.editingIx = -1;
        const index = self.mortgage.assets.indexOf(self.assets[removeIx]);
        if (index >= 0) {
          self.mortgage.assets.splice(index, 1);
        }
        self.assets.splice(removeIx, 1);
      }
    });
  }

  omitGiftOrGrant = (giftOrGrant: Asset, isOmit: boolean) => {
    if (isOmit) {
      this.omitsFromTotal.push(giftOrGrant.assetId);
    } else {
      let index = this.omitsFromTotal.indexOf(giftOrGrant.assetId);
      this.omitsFromTotal.splice(index, 1);
    }
  }

  isOmitGiftOrGrant = (giftOrGrant: Asset) => {
    return this.omitsFromTotal.indexOf(giftOrGrant.assetId) > -1;
  }

  filterAssetType = (assetType: string): boolean => {
    return assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CashGift) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.GiftOfEquity) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Grant);
  }

  private setAssetOwnerName = (asset: Asset) => {
    asset['owners'] = [];
    asset.owningBorrowerIds.forEach(borrowerId => {
      asset['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(Number(borrowerId)) });
    })
  }

  private initialize = () => {
    this.possibleAccountOwners = [];
    if (this._mortgage.borrowers !== null) {
      this._mortgage.borrowers.forEach(borrower => {
        const borrowerFullName = this._utilityService.getBorrowerFullName(borrower);
        const possibleAccountOwner = {
          id: borrower.borrowerId.toString(),
          text: borrowerFullName
        };
        this.possibleAccountOwners.push(possibleAccountOwner);
      });
    }

    this.assets = this._mortgage.assets.filter(a => this.filterAssetType(a.assetType));

    this.assets.forEach(asset => {
      this.setAssetOwnerName(asset);
    });
  }
}
