<form #loanPropertyForm="ngForm">
  <div class="grid-layout has-border" *ngIf="mortgage.mortgageId != null">
      <loan-property-info
          [isReadOnly]="isReadOnly"
          [mortgage]="mortgage"
          [loanPurposes]="loanPurposes"
          [refinancePurposes]="refinancePurposes"
          [refinancePrimaryPurposes]="refinancePrimaryPurposes"
          [propertyTypes]="propertyTypes"
          [propertyConstructionMethods]="propertyConstructionMethods"
          [propertyConstructionStatuses]="propertyConstructionStatuses"
          [purchaseCreditTypes]="purchaseCreditTypes"
          [downPaymentSourceTypes]="downPaymentSourceTypes"
          [isSubjectPropertyAddressHidden]="isSubjectPropertyAddressHidden"
          [isAppraisedValueHidden]="isAppraisedValueHidden"
          [yesNoOptions]="yesNoOptions"
          [states]="states"
          [inEditMode]="inEditMode"
          [urlaFieldsConfig]="urlaFieldsConfig"
          [isTpoUser]="isTpoUser"
          [hasApplicationReceivedKeyDate]="hasApplicationReceivedKeyDate"
          (loanPurposeChanged)="onLoanPurposeChanged()"
          (loanAmountChanged)="onLoanAmountChanged($event)"
          (presentValueChanged)="onPresentValueChanged($event)"
          >
      </loan-property-info>
      <other-new-mortgage-loans
          [mortgage]="mortgage"
          [lienPositionTypes]="lienPositionTypes"
          [giftGrantSources]="giftGrantSources"
          [isReadOnly]="isReadOnly"
          [yesNoOptions]="yesNoOptions"
          [inEditMode]="inEditMode"
          [urlaFieldsConfig]="urlaFieldsConfig">
      </other-new-mortgage-loans>
      <rental-income-on-purchases
        *ngIf="isPurchaseAndInvestmentOrSecondHomeOrMultiUnit"
        (hasSubjectPropertyRentalIncomeChange)='hasSubjectPropertyRentalIncomeChange.emit($event)'
        (borrowerReosChanged)="onBorrowerReosChanged()"
        [mortgage]="mortgage"
        [isReadOnly]="isReadOnly"
        [inEditMode]="inEditMode"
        [urlaFieldsConfig]="urlaFieldsConfig">
      </rental-income-on-purchases>
      <gifts-or-grants
        [mortgage]="mortgage"
        [assetTypes]="assetTypes"
        [yesNoOptions]="yesNoOptions"
        [giftGrantSources]="giftGrantSources"
        [isReadOnly]="isReadOnly"
        [inEditMode]="inEditMode"
        [urlaFieldsConfig]="urlaFieldsConfig">
      </gifts-or-grants>
  </div>
</form>
<loading-indicator *ngIf="mortgage.mortgageId == null" [loadingMessage]="''"></loading-indicator>
