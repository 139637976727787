<form #titleForm="ngForm">
  <div *ngIf="mortgage.mortgageId != null" class="grid-layout has-border">
    <div class="grid-header">
      <div class="grid-header-left">
        <div>L2. Title Info</div>
      </div>

      <div class="grid-header-right"></div>
    </div>

    <div class="grid-layout-row">
      <!-- Manner Title Currently Held -->
      <urla-dropdown
        *ngIf="isRefinance"
        (ngModelChange)="onMannerTitleCurrentlyHeldChange()"
        [(ngModel)]="mortgage.subjectProperty.mannerTitleCurrentlyHeld"
        [inEditMode]="inEditMode"
        [options]="propertyTitleTypes"
        [readonly]="isReadOnly"
        [required]="
          isRequired('mortgage.subjectProperty.mannerTitleCurrentlyHeld')
        "
        [urlaFieldsConfig]="urlaFieldsConfig"
        class="col-12 col-lg-3"
        fieldBeingEdited="mortgage.subjectProperty.mannerTitleCurrentlyHeld"
        label="Manner Title Currently Held"
        name="mannerTitleCurrentlyHeld"
      ></urla-dropdown>

      <!-- Manner Title Currently Held Other Description -->
      <urla-text-input
        *ngIf="
          mortgage.subjectProperty.mannerTitleCurrentlyHeld ===
          PropertyTitleType.Other
        "
        [(ngModel)]="
          mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription
        "
        [inEditMode]="inEditMode"
        [readonly]="isReadOnly"
        [required]="
          isRequired(
            'mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription'
          )
        "
        [urlaFieldsConfig]="urlaFieldsConfig"
        class="col-12 col-lg-3"
        fieldBeingEdited="mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription"
        label="Manner Title Currently Held Other Description"
        name="mannerTitleCurrentlyHeldOtherDescription"
      ></urla-text-input>

      <!-- Manner Title Will Be Held -->
      <urla-dropdown
        [(ngModel)]="mortgage.subjectProperty.mannerTitleWillBeHeld"
        (ngModelChange)="onMannerTitleWillBeHeldChange()"
        [inEditMode]="inEditMode"
        [options]="titleTypes"
        [readonly]="isReadOnly"
        [required]="
          isRequired('mortgage.subjectProperty.mannerTitleWillBeHeld')
        "
        [urlaFieldsConfig]="urlaFieldsConfig"
        [ngClass]="{ 'col-3': isRefinance, 'col-6': !isRefinance }"
        fieldBeingEdited="mortgage.subjectProperty.mannerTitleWillBeHeld"
        label="Manner In Which Title Will Be Held"
        name="mannerTitleWillBeHeld"
      ></urla-dropdown>

        <!-- Manner Title Will Be Held Other Description -->
        <urla-text-input
        *ngIf="
          mortgage.subjectProperty.mannerTitleWillBeHeld ===
          PropertyTitleType.Other
        "
        [(ngModel)]="
          mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription
        "
        [inEditMode]="inEditMode"
        [readonly]="isReadOnly"
        [required]="
          isRequired(
            'mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription'
          )
        "
        [urlaFieldsConfig]="urlaFieldsConfig"
        [ngClass]="{ 'col-3': isRefinance, 'col-6': !isRefinance }"
        fieldBeingEdited="mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription"
        label="Manner Title Wil Be Held Other Description"
        name="mannerTitleWillBeHeldOtherDescription"
      ></urla-text-input>

      <!-- Estate Will Be Held In -->
      <urla-dropdown
        [(ngModel)]="mortgage.subjectProperty.estate"
        [inEditMode]="inEditMode"
        [options]="estateTypes"
        [readonly]="isReadOnly"
        [required]="isRequired('mortgage.subjectProperty.estate')"
        [urlaFieldsConfig]="urlaFieldsConfig"
        [ngClass]="{ 'col-3': isRefinance, 'col-6': !isRefinance }"
        fieldBeingEdited="mortgage.subjectProperty.estate"
        label="Estate Will Be Held In"
        name="estate"
        (change)="onEstateChanged()"
      ></urla-dropdown>
    </div>

    <div class="grid-layout-row" *ngIf="isLeaseHold">
      <!-- Leasehold Expiration Date -->
      <urla-date-input
        [(ngModel)]="mortgage.subjectProperty.leaseHoldExpirationDate"
        [inEditMode]="inEditMode"
        [readonly]="isReadOnly"
        [required]="
          isRequired('mortgage.subjectProperty.leaseHoldExpirationDate')
        "
        [urlaFieldsConfig]="urlaFieldsConfig"
        class="col-6"
        fieldBeingEdited="mortgage.subjectProperty.leaseHoldExpirationDate"
        label="Leasehold Expiration"
        name="leaseHoldExpirationDate"
      ></urla-date-input>

      <!-- Ground Lease Expiration Date -->
      <urla-date-input
        [(ngModel)]="mortgage.subjectProperty.groundLeaseExpirationDate"
        [inEditMode]="inEditMode"
        [readonly]="isReadOnly"
        [required]="
          isRequired('mortgage.subjectProperty.groundLeaseExpirationDate')
        "
        [urlaFieldsConfig]="urlaFieldsConfig"
        class="col-6"
        fieldBeingEdited="mortgage.subjectProperty.groundLeaseExpirationDate"
        label="Ground Lease Expiration"
        name="groundLeaseExpirationDate"
      ></urla-date-input>
    </div>

    <div class="grid-layout-row">
      <!-- Native American Lands Type -->
      <urla-dropdown
        (ngModelChange)="onNativeAmericanLandsTypeChange()"
        [(ngModel)]="mortgage.subjectProperty.nativeAmericanLandsType"
        [inEditMode]="inEditMode"
        [options]="nativeAmericanLandsTypes"
        [readonly]="isReadOnly"
        [required]="
          isRequired('mortgage.subjectProperty.nativeAmericanLandsType')
        "
        [urlaFieldsConfig]="urlaFieldsConfig"
        class="col-12 col-lg-5"
        fieldBeingEdited="mortgage.subjectProperty.nativeAmericanLandsType"
        label="Indian Country Land Tenure"
        name="nativeAmericanLandsType"
      ></urla-dropdown>

      <!-- Native American Lands Type Other Description -->
      <urla-text-input
        *ngIf="
          mortgage.subjectProperty.nativeAmericanLandsType ===
          NativeAmericanLandsType.Other
        "
        [(ngModel)]="
          mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription
        "
        [inEditMode]="inEditMode"
        [readonly]="isReadOnly"
        [required]="
          isRequired(
            'mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription'
          )
        "
        [urlaFieldsConfig]="urlaFieldsConfig"
        class="col-12 col-lg-7"
        fieldBeingEdited="mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription"
        label="Indian Country Land Other Description"
        name="nativeAmericanLandsTypeOtherDescription"
      ></urla-text-input>

      <!-- Spacer for Native American Lands Type Other Description -->
      <div
        *ngIf="
          mortgage.subjectProperty.nativeAmericanLandsType !==
          NativeAmericanLandsType.Other
        "
        class="grid-layout-spacer col-12 col-lg-7"
      ></div>
    </div>

    <div class="grid-layout-row">
      <!-- Title Will Be Held In What Names -->
      <urla-text-input
        [(ngModel)]="mortgage.subjectProperty.titleWillBeHeldNames"
        [inEditMode]="inEditMode"
        [readonly]="isReadOnly"
        [required]="isRequired('mortgage.subjectProperty.titleWillBeHeldNames')"
        [urlaFieldsConfig]="urlaFieldsConfig"
        class="col-12 col-lg-8"
        fieldBeingEdited="mortgage.subjectProperty.titleWillBeHeldNames"
        label="Title To Property Will Be Held In What Name(s)"
        name="titleWillBeHeldNames"
      ></urla-text-input>

      <!-- Title Preliminary Report Date -->
      <urla-date-input
        [(ngModel)]="mortgage.subjectProperty.titlePreliminaryReportDate"
        [inEditMode]="inEditMode"
        [readonly]="isReadOnly"
        [required]="
          isRequired('mortgage.subjectProperty.titlePreliminaryReportDate')
        "
        [urlaFieldsConfig]="urlaFieldsConfig"
        class="col-12 col-lg-4"
        fieldBeingEdited="mortgage.subjectProperty.titlePreliminaryReportDate"
        label="Title Preliminary Report Date"
        name="titlePreliminaryReportDate"
      ></urla-date-input>
    </div>

    <div *ngIf="isRefinance || inEditMode" class="grid-layout-row">
      <!-- Title Currently Held In What Name -->
      <urla-text-input
        [(ngModel)]="mortgage.subjectProperty.titleCurrentlyHeldInNames"
        [inEditMode]="inEditMode"
        [readonly]="isReadOnly"
        [required]="
          isRequired('mortgage.subjectProperty.titleCurrentlyHeldInNames')
        "
        [urlaFieldsConfig]="urlaFieldsConfig"
        class="col-12"
        fieldBeingEdited="mortgage.subjectProperty.titleCurrentlyHeldInNames"
        label="For Refinance, Title to the Property Is Currently Held In What Name(s)"
        name="titleCurrentlyHeldInNames"
      ></urla-text-input>
    </div>

    <ng-container>
      <div class="grid-layout-row">
        <!-- Title Report Items Description -->
        <urla-text-input
          [(ngModel)]="mortgage.subjectProperty.titleReportItemsDescription"
          [inEditMode]="inEditMode"
          [readonly]="isReadOnly"
          [required]="
            isRequired('mortgage.subjectProperty.titleReportItemsDescription')
          "
          [urlaFieldsConfig]="urlaFieldsConfig"
          class="col-12 col-lg-6"
          fieldBeingEdited="mortgage.subjectProperty.titleReportItemsDescription"
          label="Title Report Items Description"
          name="titleReportItemsDescription"
        ></urla-text-input>

        <!-- Title Report Required Endorsements Description -->
        <urla-text-input
          [(ngModel)]="
            mortgage.subjectProperty.titleReportRequiredEndorsementsDescription
          "
          [inEditMode]="inEditMode"
          [readonly]="isReadOnly"
          [required]="
            isRequired(
              'mortgage.subjectProperty.titleReportRequiredEndorsementsDescription'
            )
          "
          [urlaFieldsConfig]="urlaFieldsConfig"
          class="col-12 col-lg-6"
          fieldBeingEdited="mortgage.subjectProperty.titleReportRequiredEndorsementsDescription"
          label="Title Report Required Endorsements Description"
          name="titleReportRequiredEndorsementsDescription"
        ></urla-text-input>
      </div>

      <div class="grid-layout-row">
        <!-- Trust Classification -->
        <urla-dropdown
          [(ngModel)]="mortgage.subjectProperty.trustClassification"
          [inEditMode]="inEditMode"
          [options]="propertyTrustClassifications"
          [readonly]="isReadOnly"
          [required]="
            isRequired('mortgage.subjectProperty.trustClassification')
          "
          [urlaFieldsConfig]="urlaFieldsConfig"
          class="col-12 col-lg-4"
          fieldBeingEdited="mortgage.subjectProperty.trustClassification"
          label="Trust Information"
          name="trustClassification"
        ></urla-dropdown>

        <!-- Is Deed Restricted -->
        <urla-radio-group
          [(ngModel)]="mortgage.subjectProperty.isDeedRestricted"
          [inEditMode]="inEditMode"
          [options]="yesNoOptions"
          [readonly]="isReadOnly"
          [required]="isRequired('mortgage.subjectProperty.isDeedRestricted')"
          [urlaFieldsConfig]="urlaFieldsConfig"
          class="col-6 col-lg-4 d-block"
          fieldBeingEdited="mortgage.subjectProperty.isDeedRestricted"
          label="Is Deed Restricted?"
          name="isDeedRestricted"
        ></urla-radio-group>

        <!-- Deed Restriction Term Months -->
        <urla-digits-input
          *ngIf="mortgage.subjectProperty.isDeedRestricted"
          [(ngModel)]="mortgage.subjectProperty.deedRestrictionTermMonths"
          [inEditMode]="inEditMode"
          [readonly]="isReadOnly"
          [required]="
            isRequired('mortgage.subjectProperty.deedRestrictionTermMonths')
          "
          [urlaFieldsConfig]="urlaFieldsConfig"
          class="col-6 col-lg-4 d-block"
          fieldBeingEdited="mortgage.subjectProperty.deedRestrictionTermMonths"
          label="Deed Restriction Term (Months)"
          name="deedRestrictionTermMonths"
        ></urla-digits-input>

        <!-- Spacer for Deed Restriction Term Months -->
        <div
          *ngIf="!mortgage.subjectProperty.isDeedRestricted"
          class="grid-layout-spacer col-6 col-lg-4 d-block"
        ></div>
      </div>

      <div class="grid-layout-row">
        <!-- Recording Jurisdiction Name -->
        <urla-text-input
          [(ngModel)]="mortgage.subjectProperty.recordingJurisdictionName"
          [inEditMode]="inEditMode"
          [readonly]="isReadOnly"
          [required]="
            isRequired('mortgage.subjectProperty.recordingJurisdictionName')
          "
          [urlaFieldsConfig]="urlaFieldsConfig"
          class="col-12 col-lg-6 col-xl-4"
          fieldBeingEdited="mortgage.subjectProperty.recordingJurisdictionName"
          label="Recording Jurisdiction Name"
          name="recordingJurisdictionName"
        ></urla-text-input>

        <!-- Recording Jurisdiction Type -->
        <urla-dropdown
          (ngModelChange)="onRecordingJurisdictionTypeChange()"
          [(ngModel)]="mortgage.subjectProperty.recordingJurisdictionType"
          [inEditMode]="inEditMode"
          [options]="recordingJurisdictionTypes"
          [readonly]="isReadOnly"
          [required]="
            isRequired('mortgage.subjectProperty.recordingJurisdictionType')
          "
          [urlaFieldsConfig]="urlaFieldsConfig"
          class="col-12 col-lg-6 col-xl-3"
          fieldBeingEdited="mortgage.subjectProperty.recordingJurisdictionType"
          label="Recording Jurisdiction Type"
          name="recordingJurisdictionType"
        ></urla-dropdown>

        <!-- Recording Jurisdiction Type Other Description -->
        <urla-text-input
          *ngIf="
            mortgage.subjectProperty.recordingJurisdictionType ===
            RecordingJurisdictionType.Other
          "
          [(ngModel)]="
            mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription
          "
          [inEditMode]="inEditMode"
          [readonly]="isReadOnly"
          [required]="
            isRequired(
              'mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription'
            )
          "
          [urlaFieldsConfig]="urlaFieldsConfig"
          class="col-12 col-xl-5"
          fieldBeingEdited="mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription"
          label="Recording Jurisdiction Type Other Description"
          name="recordingJurisdictionTypeOtherDescription"
        ></urla-text-input>

        <!-- Spacer for Recording Jurisdiction Type Other Description -->
        <div
          *ngIf="
            mortgage.subjectProperty.recordingJurisdictionType !==
            RecordingJurisdictionType.Other
          "
          class="grid-layout-spacer col-12 col-xl-5"
        ></div>
      </div>

      <urla-legal-descriptions
        *ngIf="!dhHiddenHack"
        [mortgage]="mortgage"
        [inEditMode]="inEditMode"
        [isReadOnly]="isReadOnly"
        [urlaFieldsConfig]="urlaFieldsConfig"
      ></urla-legal-descriptions>

    </ng-container>
  </div>
</form>

<loading-indicator
  *ngIf="mortgage.mortgageId == null"
  loadingMessage="Loading title info..."
></loading-indicator>
