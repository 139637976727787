import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Injector, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { Company, LoanApplication, Profile } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { SendEmailSmsDialogComponent } from 'src/app/modules/correspondence/components/send-email-sms-dialog/send-email-sms-dialog.component';
import { InternalContactsService } from 'src/app/modules/internal-contacts/services/internal-contacts.service';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { FileAttachment } from 'src/app/shared/components/send-mms/send-mms.component';
import { MortgageService } from "../../../../services/mortgage.service";
import { PricingScenario } from '../../models/pricing/pricing-scenario.model';

@Component({
  selector: 'scenario-comparison-worksheet-dialog',
  templateUrl: './scenario-comparison-worksheet-dialog.component.html',
  styleUrls: ['./scenario-comparison-worksheet-dialog.component.scss']
})
export class ScenarioComparisonWorksheetDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  scenarios: PricingScenario[];

  @Input()
  appId: number;

  noOfUnits: string = '-';

  mortgage: UrlaMortgage;
  application: LoanApplication;

  usersAll: User[] = [];
  user: User;

  loggedInUser: Profile;
  loanPurpose: string;
  propertyType: string;
  purchasePrice: number;
  appraisedValue: number;
  downPayment: number;
  rate: number;
  apr: number;
  fico: number;
  occupancy: string;
  zipCode: string;
  state: string;
  loanAmount: number;
  waiveEscrows: boolean;
  lockTerm: number;
  userImage: string;
  licenseNo: string;
  company: Company;
  companyUrl: string = "";
  currentDate: Date;
  updatedDate: Date;

  ftcSectionAmounts: any = {};

  protected get userCellPhone(): string | undefined {
    return this.user?.cellPhone;
  }

  protected get loggedInUserCellPhone(): string | undefined {
    return this.loggedInUser?.userProfile.cellPhone;
  }

  protected get userEmail(): string | undefined {
    return this.user?.email
  }

  protected get loggedInUserEmail(): string | undefined {
    return this.loggedInUser?.userProfile.email;
  }

  inEmail: boolean = false;
  isFailed: boolean = false;

  imageProfile: SafeResourceUrl = 'assets/images/male.png';
  imageLogo: SafeResourceUrl = 'assets/images/loda-logo.png';
  imageApplyNow: SafeResourceUrl;

  defaultProfileImage: SafeResourceUrl;
  defaultCompanyLogo: SafeResourceUrl;

  protected isPurchase: boolean = false;
  protected isRefi: boolean = false;

  printConfig = [
    'sectionConvertedToPDF', {
      printMode: 'template-popup',
      pageTitle: 'Loan Comparison Worksheet',
      styles: [
        'img { margin-right: 10px }',
        'hr { display:none; }',
        '.cards-section .card { box-shadow: none; border-radius: 0; height: auto !important; }',
        '.row { --bs-gutter-x: 1rem;--bs-gutter-y: 0;display: flex;flex-wrap: wrap; font-size: xx-small; }',
        '.col-md-4 { flex: 0 0 auto;width: 33.33%;}',
        '.col-md-8 { flex: 0 0 auto;width: 66.67%;}',
        '.col-md-6 { flex: 0 0 auto;width: 50%; }',
        '.col-md-2 { flex: 0 0 auto;width: 16.66%; }',
        '.col-md-10 { flex: 0 0 auto;width: 83.34%; }',
        '.card-title, .worksheet-header, .style-23, .style-218 { font-size: xx-small }',
        '.style-10, .style-20 { font-size: small }',
        '.card-header { padding: 0.25rem 1rem !important; }',
        '.style-48 { margin-left: 3.2rem !important; }',
        '.style-57, .style-61, .style-65 { margin-left: 43px !important; }',
        '.style-78 { margin-left: 54px !important; }',
        '.card-body.border { margin-right: 1.5rem;margin-left: 0.5rem; }',
        '.style-24 { display: none !important; }',
        '.style-30 { float: right; }',
        '.style-999 * { font-family: "Roboto", sans-serif !important; font-size: x-small !important}',
        '.style-998 { position: fixed; left: 0; bottom: 0; width: 100%; }',
        '.style-86 { border-bottom: 1px solid black !important; }',
        '.style-18 { height: 100%; display: flex; justify-content: center; align-items: flex-start; font-size: large; }',
        '.style-3 { border-bottom: 1px solid rgb(36, 108, 234) !important; }',
        '.style-8 { margin-left: 43px !important }'
      ]
    }
  ];
  apiBaseUrl: string;

  constructor(
    private injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _enumsService: EnumerationService,
    private readonly _modalService: NgbModal,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _environment: EnvironmentService,
    private readonly elementRefService: ElementRef,
    private readonly _httpClient: HttpClient,
    private readonly _loanDocService: LoanDocService,
    private readonly _utilityService: UtilityService,
    private readonly _notificationService: NotificationService,
    private readonly _mortgageService: MortgageService,
    private readonly _mortgageCalculationsService: MortgageCalculationService
  ) {
    super(injector);
    this.apiBaseUrl = this._environment.apiInfo.apiBaseUrl;
    this._utilityService.getImageFileContentAsBase64("assets/images/male.png", (result: SafeResourceUrl) => {
      this.defaultProfileImage = result;
    });

    this._utilityService.getImageFileContentAsBase64("assets/images/users/user-5.jpg", (result2: SafeResourceUrl) => {
      this.imageApplyNow = result2;
    });

    this._utilityService.getImageFileContentAsBase64("assets/images/loda-logo.png", (result: SafeResourceUrl) => {
      this.defaultCompanyLogo = result;
    });
  }

  ngOnInit() {
    this.currentDate = new Date();
    this.applicationContextService.context.subscribe(context => {
      this.company = context.globalConfig.company.find(c => c.companyId == context.userPermissions.companyId);
      this.imageLogo = `${this.apiBaseUrl}company/co/logo/${this.company.companyId}`;
      this.mortgage = context.currentMortgage;
      this.application = context.application;
      this.usersAll = context.globalConfig.usersAll;
      this.loggedInUser = context.currentlyLoggedInUserProfile;

      this.ftcSectionAmounts = this._mortgageCalculationsService.getFtcSectionAmounts(this.mortgage);

      const urlsToParse = this.company.borrowerURL || this.company.companyURL;
      if (urlsToParse) {
        const urls = urlsToParse.split('|');
        if (urls && urls.length) {
          this.companyUrl = urls[0];
        }
      }

      if (this.appId) {
        this._spinnerService.show();
        this._internalContactsService.getInternalContacts(this.appId).subscribe(result => {
          const existOne = result.find(u => u.roleId == context.globalConfig.firstRole.roleId);
          if (existOne) {
            this.user = this.usersAll.find(u => u.userCompanyGuid === existOne.userId);
            if (this.user && this.user.userImage) {
              this.imageProfile = this.user.userImage;
            }
          }
        }).add(() => {
          this._spinnerService.hide();
        });
      } else {
        this.imageProfile = this.loggedInUser.userImage;
      }
      const states = Object.keys(context.globalConfig.states).map(
        (key) => new EnumerationItem(context.globalConfig.states[key], key)
      );
      const state = states.find(s => s.value == context.currentMortgage.subjectProperty.state);
      if (state) {
        this.state = state.name;
      }
    });

    this.setFieldValuesFromLoan();

  }

  onProfileImageDownloadFailed = () => {
    this.imageProfile = this.defaultProfileImage;
  }

  getDownPaymentFromScenario = (scenario: PricingScenario): number => {
    const pricingRequest = scenario.pricingRequestPayload;
    if (pricingRequest.loanInformation.loanPurpose === 'Refinance') {
      return pricingRequest.propertyInformation.appraisedValue - pricingRequest.loanInformation.baseLoanAmount;
    } else {
      return pricingRequest.propertyInformation.salesPrice - pricingRequest.loanInformation.baseLoanAmount;
    }
  }

  onCompanyLogoDownloadFailed = () => {
    this.imageLogo = this.defaultCompanyLogo;
    this.isFailed = true;
  }

  //This is not used for now until we figure out the server side rendering. It does not seem to do a good job right now.
  onSendAsImageInEmailClicked = () => {
    this._spinnerService.show();
    let html = this.elementRefService.nativeElement.innerHTML;
    const title = "Loan Comparison";

    const sourcePath: string = '/assets/css/loan-comparison-print.min.css';
    this._httpClient.get(sourcePath, { responseType: "text" }).subscribe(response => {
      let styleValue = response;
      const fullHtml: string = `<!DOCTYPE html><html><head><title>Loan Comparison Worksheet</title><style>${styleValue}</style></head><body>${html}</body></html>`;
      this._loanDocService.renderHtmlToFile(fullHtml, "JPG", title).subscribe((response) => {

        const file = new Blob([response.body], { type: 'image/jpeg' });

        const url = window.URL.createObjectURL(file);
        window.open(url);

        let reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event) => {
          const image = "<img src='" + event.target.result as string + "'>";
          const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
          modalRef.componentInstance.body = image;
          modalRef.componentInstance.isShowOnlyEmail = true;
          modalRef.result.then(() => {

          }, err => { });
        };

      }, (err) => {
        this._notificationService.showError(err?.message || "", "Error!");
      }).add(() => this._spinnerService.hide());
    })
  }

  onSendAsHtmlInEmailClicked = () => {
    this.inEmail = true;
    setTimeout(() => {
      let html = this.elementRefService.nativeElement.innerHTML;

      const sourcePath: string = '/assets/css/loan-comparison-print.min.css';
      this._httpClient.get(sourcePath, { responseType: "text" }).subscribe(response => {
        let styleValue = response;
        const fullHtml: string = `<!DOCTYPE html><html><head><title>Loan Comparison Worksheet</title><style>${styleValue}</style></head><body><br><br> ${html}</body></html>`;
        const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
        modalRef.componentInstance.body = fullHtml;
        modalRef.componentInstance.isShowOnlyEmail = true;
        this.inEmail = false;
        modalRef.result.then(() => {

        }, err => { });
      }, (err) => {
        this.inEmail = false;
        this._notificationService.showError(err?.message || "", "Error!");
      })
    })
  }

  //This is not used for now until we figure out the server side rendering. It does not seem to do a good job right now.
  onSendAsEmailClicked = () => {
    this._spinnerService.show();
    let html = this.elementRefService.nativeElement.innerHTML;
    const title = "Loan Comparison";

    const sourcePath: string = '/assets/css/loan-comparison-print.min.css';
    this._httpClient.get(sourcePath, { responseType: "text" }).subscribe(response => {
      let styleValue = response;
      const fullHtml: string = `<!DOCTYPE html><html><head><title>Loan Comparison Worksheet</title><style>${styleValue}</style></head><body>${html}</body></html>`;
      this._loanDocService.renderHtmlToLoanDoc(fullHtml, "PDF", title, this.application.applicationId, this.application.primaryBorrowerId).subscribe(loanDoc => {
        this._loanDocService.getLoanDoc(loanDoc.loanDocId).subscribe(lD => {
          let docFile = new DocFile();
          docFile.guid = lD.docFiles[0].guid;

          const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
          modalRef.componentInstance.attachments = [docFile];
          modalRef.componentInstance.isShowOnlyEmail = true;
          modalRef.result.then(() => {

          }, err => { });
        })
      }, (err) => {
        this._notificationService.showError(err?.message || "", "Error!");
      }).add(() => this._spinnerService.hide());
    })
  }

  //This is not used for now until we figure out the server side rendering. It does not seem to do a good job right now.
  onSendAsTextClicked = () => {
    this._spinnerService.show();
    let html = this.elementRefService.nativeElement.innerHTML;
    const title = "Loan Comparison";

    const sourcePath: string = '/assets/css/loan-comparison-print.min.css';
    this._httpClient.get(sourcePath, { responseType: "text" }).subscribe(response => {
      let styleValue = response;
      const fullHtml: string = `<!DOCTYPE html><html><head><title>Loan Comparison Worksheet</title><style>${styleValue}</style></head><body>${html}</body></html>`;
      this._loanDocService.renderHtmlToFile(fullHtml, "JPG", title).subscribe((response) => {

        let file = new File([response.body], 'Loan_Quote.jpeg', { type: 'image/jpeg' });

        const url = window.URL.createObjectURL(file);
        window.open(url);

        let reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event) => {
          const base64 = event.target.result;
          let attachment = new FileAttachment();
          attachment.base64 = base64;
          attachment.file = file;
          const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
          modalRef.componentInstance.fileAttachments = [attachment];
          modalRef.componentInstance.isShowOnlyMMS = true;
          modalRef.componentInstance.sendEmail = false;
          modalRef.result.then(() => {

          }, err => { });
        };

      }, (err) => {
        this._notificationService.showError(err?.message || "", "Error!");
      }).add(() => this._spinnerService.hide());
    })
  }

  private setFieldValuesFromLoan = () => {
    // From FTC
    if (this.application.productPricing['pricingRequestPayload']) {
      const request = JSON.parse(this.application.productPricing['pricingRequestPayload']);
      this.fico = request.RepresentativeCreditScore;
      this.waiveEscrows = request.LoanInformation.WaiveEscrows;
      const noOfUnitsOptions = this._enumsService.noOfUnits;
      const selectedNoOfUnits = noOfUnitsOptions.find(o => o.value == request.PropertyInformation.NumberOfUnits);
      if (selectedNoOfUnits) {
        this.noOfUnits = selectedNoOfUnits.name;
      }
    }

    this.purchasePrice = this.application.purchasePrice;
    this.appraisedValue = this.application.presentValue;
    this.downPayment = this._mortgageService.calculateDownPayment(this.mortgage);
    this.rate = this.application.productPricing.rate;
    this.apr = this.application.productPricing.apr;
    this.loanPurpose = this.mortgage.subjectProperty.purposeOfLoan;
    this.isPurchase = this._mortgageCalculationsService.isPurposeOfLoanPurchase(this.mortgage);
    this.isRefi = this._mortgageCalculationsService.isPurposeOfLoanRefinance(this.mortgage);
    this.propertyType = this.mortgage.subjectProperty.attachmentType + (this.mortgage.subjectProperty.isPlannedUnitDevelopment ? " - PUD" : (!this.mortgage.subjectProperty.isPropertyNotInAProject ? (' - ' + this.mortgage.subjectProperty.projectType) : ''));
    this.occupancy = this.mortgage.subjectProperty.propertyWillBe;
    this.zipCode = this.mortgage.subjectProperty.zipCode;
    this.loanAmount = this.application.loanAmount;
    this.lockTerm = this.application.productPricing.lockTerm;
    this.licenseNo = this.mortgage.originatorInformation.stateLicense;
    this.updatedDate = this.application.productPricing.assignDate ? new Date(this.application.productPricing.assignDate) : null;

    this.scenarios.forEach(scenario => {
      if (!scenario.monthlyHoa) {
        scenario.totalPayment += this.mortgage.proposedHousingExpense?.homeownersAssociationDues;
      }
      if (!scenario.monthlyTaxes) {
        scenario.totalPayment += this.mortgage.proposedHousingExpense?.realEstateTax;
      }
      if (!scenario.monthlyInsurance) {
        scenario.totalPayment += this.mortgage.proposedHousingExpense?.mortgageInsurance;
      }
      scenario.totalPayment += (this.mortgage.proposedHousingExpense?.otherMortgageLoanPrincipalAndInterest || 0);
      scenario.monthlyInsurance = scenario.monthlyInsurance | this.mortgage.proposedHousingExpense?.mortgageInsurance;
      scenario.monthlyTaxes = scenario.monthlyTaxes | this.mortgage.proposedHousingExpense?.realEstateTax;
      scenario.monthlyHoa = scenario.monthlyHoa | this.mortgage.proposedHousingExpense?.homeownersAssociationDues;
    })
  }
}
