import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { BreadcrumbEvent, BreadcrumbEventType, BreadcrumbService } from '../../services/bread-crumb.service';

export interface IBreadCrumb {
  label: string;
  url: string;
  isDisabled?: boolean;
}

@Component({
  selector: 'bread-crum',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit, OnDestroy {

  @Input() insideLoanContext: boolean;
  @Output() loanSummaryToggled = new EventEmitter<boolean>();

  public breadcrumbs: IBreadCrumb[]

  private _breadcrumbEventsSubscription: Subscription;
  private _routeEventsSubscription: Subscription;

  constructor(
    private readonly _breadcrumbService: BreadcrumbService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
  ) {
    this.breadcrumbs = this.buildBreadCrumb(this._activatedRoute.root);
    this._breadcrumbEventsSubscription = this._breadcrumbService.events.subscribe(e => {
      this.handleBreadcrumbEvent(e);
    })
  }

  ngOnInit() {
    this._routeEventsSubscription = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this._activatedRoute.root);
    });
  }

  ngOnDestroy(): void {
    if (this._breadcrumbEventsSubscription) {
      this._breadcrumbEventsSubscription.unsubscribe();
    }
    if (this._routeEventsSubscription) {
      this._routeEventsSubscription.unsubscribe();
    }
  }

  private buildBreadCrumb = (route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] => {
    let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
    let isDisabled = route.routeConfig && route.routeConfig.data && route.routeConfig.data.isDisabled;
    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    const routeParts: any = path.split('/');

    routeParts.forEach(routePart => {
      const isDynamicRoute = routePart.startsWith(':');
      if (isDynamicRoute && !!route.snapshot) {
        const paramName = routePart.split(':')[1];
        path = path.replace(routePart, route.snapshot.params[paramName]);
      }
    })

    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: IBreadCrumb = {
      label: label,
      url: nextUrl,
      isDisabled: isDisabled
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  private handleBreadcrumbEvent = (e: BreadcrumbEvent) => {
    switch (e.type) {
      case BreadcrumbEventType.OverrideRequested:
        return;
      case BreadcrumbEventType.AppendRequested:
        this.append(e.data);
        break;
    }
  }

  private append = (breadCrumbs: IBreadCrumb[]) => {
    this.breadcrumbs = _.unionBy(this.breadcrumbs, breadCrumbs, "label");
  }
}
