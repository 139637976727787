import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';
import { ExternalCompany, LosCredential, LosCredentialsArray, LosProviderFeature, Role, SearchResponseItem } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { ChannelService } from 'src/app/services/channel.service';
import { LosService } from 'src/app/services/los.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { AlignmentRoleUsers } from '../../../models/process-submission.model';
import { NewApplicationService } from '../../../services/new-application.service';

@Component({
  selector: 'import-from-los',
  templateUrl: './import-from-los.component.html',
  styleUrls: ['./import-from-los.component.scss'],
})
export class ImportFromLosComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input()
  globalConfig: GlobalConfig;

  @Input()
  enabledChannels: Array<EnumerationItem>;

  @Input()
  availableUsers: User[] = [];

  @Input()
  selectedChannel: string;

  @Input()
  selectedCompanyId: number | '';

  @Input()
  firstRole: Role;

  @Input()
  externalCompanies: ExternalCompany[];

  @Input()
  isTpoUser: boolean;

  @Input()
  isSuperAdmin: boolean;

  @Input()
  userCompanyGuid: string;

  @Input()
  tpoEnabledRoles: Role[] = [];

  @Output()
  importFromLos = new EventEmitter();

  losCredentialId: number = null;

  losProviders: LosCredentialsArray;

  refNumber: string;

  borrFirstName: string;

  borrLastName: string;

  searchingLos: boolean = false;

  selectedProvider: LosCredential | {};

  selectedLoanId: string;

  selectedLoan: Partial<SearchResponseItem>;

  loanList: SearchResponseItem[];

  primaryRoleUserId: string;

  avaliableSecondaryRoles: Role[] = [];
  tpoAlignmentRoleUsers: AlignmentRoleUsers = {};
  availableRoleUsers: User[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _newApplicationService: NewApplicationService,
    private readonly _losService: LosService,
    private readonly _channelService: ChannelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._losService
      .getLosCredentialsForImportLoanFromLos()
      .subscribe((vendors) => {
        this.losProviders = vendors || [];

        this.losProviders.forEach((provider) => {
          if (provider.vendorFeatures.indexOf(LosProviderFeature.Lookup) > -1) {
            provider['borrowerDisabled'] = true;
          }
        });

        if (this.losProviders.length === 1) {
          this.startWithOneVendor();
        }
      });

    this.enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(this.applicationContext.userPermissions.enabledChannels);
    if (!this.enabledChannels.length) {
      this.firstRole = this.globalConfig.firstRole;
      this.primaryRoleUserId = '';

      if (this.isTpoUser && this.firstRole) {
        this.avaliableSecondaryRoles = this.tpoEnabledRoles.filter(r => r.roleId != this.firstRole.roleId && r.order != 1 &&
          r.roleChannels.map(rc => rc.channel.toLocaleLowerCase()).includes(this.selectedChannel.toLowerCase()));
      }

      this.availableRoleUsers = cloneDeep(this.availableUsers);
    }
  }

  changeSelectedLosProvider = (newVal) => {
    this.losCredentialId = +newVal || null;
    this.getSelectedProvider();
    this.checkAndRemoveBorrowerNames();
  };

  getSelectedProvider() {
    this.selectedProvider =
      this.losProviders.find(
        (provider) => provider.credentialId === Number(this.losCredentialId)
      ) || {};
  }

  checkAndRemoveBorrowerNames() {
    if (this.selectedProvider['borrowerDisabled']) {
      this.borrFirstName = '';
      this.borrLastName = '';
    }
  }

  setSelectedLoan(loanNumber, losIdentifier) {
    this.selectedLoanId = losIdentifier;
    this.selectedLoan = {
      loanNumber: loanNumber || '',
      losIdentifier: losIdentifier,
    };
  }

  searchLos = () => {
    this.selectedLoanId = undefined;
    this.selectedLoan = undefined;
    this.searchingLos = true;

    var filter = this.losCredentialId + '?';
    if (this.refNumber && this.refNumber.length > 0)
      filter += 'loanNumber=' + this.refNumber + '&';
    if (this.borrFirstName && this.borrFirstName.length > 0)
      filter += 'borrowerFirstName=' + this.borrFirstName + '&';
    if (this.borrLastName && this.borrLastName.length > 0)
      filter += 'borrowerLastName=' + this.borrLastName + '&';

    this._losService.searchLos(filter)
      .pipe(finalize(() => this.searchingLos = false))
      .subscribe({
        next: (res) => {
          this.loanList = res;
        },
        error: (error) => {
          this._notificationService.showError(error?.message || "Couldn't search through LOS", "Error")
        }
      });
  };

  handleChannelChange = (newVal: string) => {
    this.selectedChannel = newVal;
    this.selectedCompanyId = '';

    if (newVal == 'Wholesale' || newVal == 'Correspondent') {
      this.availableRoleUsers = [];
      this.primaryRoleUserId = '';
    }

    this.firstRole = this.getFirstRoleByChannel(newVal);
    this.availableRoleUsers = cloneDeep(this.availableUsers);

    if (this.isTpoUser && this.firstRole) {
      this.avaliableSecondaryRoles = this.tpoEnabledRoles.filter(r => r.roleId != this.firstRole.roleId && r.order != 1 &&
        r.roleChannels.map(rc => rc.channel.toLocaleLowerCase()).includes(this.selectedChannel.toLowerCase()));
    }

    const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
    this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';
  };

  handleCompanyChange = (externalCompanyId: number) => {
    this.selectedCompanyId = externalCompanyId;
    if (!this.selectedCompanyId) {
      this.availableRoleUsers = [];
      return;
    }
    if (this.isTpoUser) {
      if (this.selectedCompanyId) {
        this.availableRoleUsers = this.availableUsers.filter(x => x.externalCompanyId == this.selectedCompanyId);
      } else {
        this.availableRoleUsers = [];
      }
      const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
      this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';
      return;
    }
    this._spinner.show();
    this._newApplicationService.getExternalCompanyUsers(this.selectedCompanyId)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (res) => {
          this.availableRoleUsers = res || [];
          const userExist = this.availableUsers.findIndex(u => u.userCompanyGuid === this.userCompanyGuid) > -1;
          this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';

        }, error: (err) => {
          this.primaryRoleUserId = "";
          this._notificationService.showError(err?.message || "Couldn't get users for selected company", "Error");
        }
      });
  }

  import = () => {
    const data = {
      losCredentialId: this.losCredentialId,
      selectedLoan: this.selectedLoan,
      channel: this.selectedChannel,
      primaryRoleUserId: this.primaryRoleUserId,
      additionalAlignmentRoleUsers: this.tpoAlignmentRoleUsers
    };
    this.importFromLos.emit(data);
  };

  private startWithOneVendor = () => {
    this.changeSelectedLosProvider(this.losProviders[0].credentialId);
  }

  private getFirstRoleByChannel = (channel) => {
    if (!channel) {
      return this.globalConfig.firstRole;
    }
    return this.globalConfig.channelRoles[channel.toLowerCase()][0];
  };
}
