import { Component, EventEmitter, Output } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { UrlaRadioGroupComponent } from '../../common/components/urla-radio-group/urla-radio-group.component';

@Component({
    selector: 'subject-property-input',
    templateUrl: 'subject-property-input.component.html',
    styleUrls: ['./subject-property-input.component.scss'],
    providers: [MakeProvider(SubjectPropertyInputComponent)],
})
export class SubjectPropertyInputComponent extends UrlaRadioGroupComponent {

    @Output()
    importLoanInfoClicked: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    pushToLoanInfoClicked: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
        super();
    }

    ngOnInit() {
      const disabled = this.disabled;
      const readonly = this.readonly;
    }

    onImportLoanInfoClicked = () => {
        this.importLoanInfoClicked.emit();
    }

    onPushLoanInfoClicked = () => {
        this.pushToLoanInfoClicked.emit();
    }
}
