<div class="row">
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="loanAmount">Loan Amount</label>
    <div class="d-flex align-items-center">
      <currency-input class="flex-grow-1" name="loanAmount" [ngModel]="lead.loanAmount" (ngModelChange)="
        lead.loanAmount = $event; calculate_LA_AV_DP('LoanAmount')
      "></currency-input>
      <!-- purchase -->
      <a (click)="onCalculatorClicked()" class="ms-2" *ngIf="lead.loanPurposeId == 1">
        <i class="fa fa-calculator"></i>
      </a>
    </div>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="appraisedValue">Purchase Price / Estimated Value</label>
    <currency-input name="appraisedValue" [ngModel]="lead.appraisedValue" (ngModelChange)="
        lead.appraisedValue = $event; lead.purchasePrice = lead.appraisedValue; calculate_LA_AV_DP('AppraisedValue')
      "></currency-input>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="cashoutAmount">Cash Out Amount</label>
    <currency-input name="cashoutAmount" [ngModel]="lead.cashOutAmount"
      (ngModelChange)="lead.cashOutAmount = $event; changevalue()"
      [disabled]="lead.loanPurposeId == 1"></currency-input>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-6': isDrawer}">
    <label for="downAmount">Down Amount</label>
    <currency-input name="downAmount" [ngModel]="lead.downPayment"
      (ngModelChange)="lead.downPayment = $event; calculate_LA_AV_DP('DownPayment')"
      [disabled]="lead.loanPurposeId == 2"></currency-input>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-3': isDrawer}">
    <label for="interestRate">Interest Rate</label>
    <percent-input name="interestRate" [(ngModel)]="lead.interestRate"></percent-input>
  </div>
  <div class="form-group col-xs-12 col-sm-6 col-lg-3" [ngClass]="{'col-lg-3': isDrawer}">
    <label for="totalLoanAmount">Total Loan Amount</label>
    <currency-input name="totalLoanAmount" [readonly]="true" [(ngModel)]="totalLoanAmount"></currency-input>
  </div>
  <div class="form-group col-xs-12 col-sm-3 col-lg-1" [ngClass]="{'col-lg-2': isDrawer}">
    <label for="ltv">LTV</label>
    <percent-input name="ltv" [(ngModel)]="ltv" [rateIsTrueToTextValue]="false" [readonly]="!lead.appraisedValue"
      (blur)="onLtvValueChanged()"></percent-input>
  </div>
  <div class="form-group col-xs-12 col-sm-3 col-lg-1" [ngClass]="{'col-lg-2': isDrawer}">
    <label for="cltv">CLTV</label>
    <percent-input name="cltv" [readonly]="true" [(ngModel)]="cltv" [rateIsTrueToTextValue]="false"></percent-input>
  </div>

  <div *ngIf="isPurchaseTransaction(lead.loanPurposeId, false)" class="form-group col-xs-12 col-sm-6 col-lg-3"
    [ngClass]="{'col-lg-6': isDrawer}">
    <label for="purchaseTimeFrame">Purchase Time Frame</label>
    <input id="purchaseTimeFrame" class="form-control" name="purchaseTimeFrame" placeholder="(eg. 1-3 Months)"
      type="text" [(ngModel)]="lead.purchaseTimeFrame" />
  </div>

  <div *ngIf="isPurchaseTransaction(lead.loanPurposeId, false)"
    class="form-group col-xs-12 col-sm-3 col-lg-1 d-flex flex-column" [ngClass]="{'col-lg-2': isDrawer}">
    <label for="firstTimeHomeBuyer">First Time Buyer</label>
    <input id="firstTimeHomeBuyer" class="form-check-input mt-auto" style="width: 18px; height: 18px"
      name="firstTimeHomeBuyer" type="checkbox" [(ngModel)]="lead.firstTimeHomeBuyer" />
  </div>

  <div *ngIf="isPurchaseTransaction(lead.loanPurposeId, false)"
    class="form-group col-xs-12 col-sm-3 col-lg-1 d-flex flex-column" [ngClass]="{'col-lg-2': isDrawer}">
    <label for="propertyFound">Property Found</label>
    <input id="propertyFound" class="form-check-input mt-auto" style="width: 18px; height: 18px" name="propertyFound"
      type="checkbox" [(ngModel)]="lead.propertyFound" />
  </div>
</div>
