import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Component({
  selector: 'app-invite-allowed-products-dialog',
  templateUrl: './invite-allowed-products-dialog.component.html',
  styleUrls: ['./invite-allowed-products-dialog.component.scss']
})
export class InviteAllowedProductsDialogComponent implements OnInit {

  @Input() allowedProductOptions: EnumerationItem[] = [];

  multiSelectSettings: IDropdownSettings = {
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };

  selectedOptions: EnumerationItem[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
