<ng-container *ngIf="result && result.errors.length > 0; else results">
  <p-table [value]="result.errors" [paginator]="true" [rows]="25" [rowsPerPageOptions]="[25, 50, 100]"
    [showCurrentPageReport]="true" [scrollable]="true" scrollHeight="flex"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    styleClass="p-datatable-gridlines p-datatable-sm">
    <ng-template pTemplate="caption"> Errors </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Message</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-error>
      <tr>
        <td>
          {{ error }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>

<ng-template #results>
  <p-table [value]="filteredResults" [paginator]="true" [rows]="25" [rowsPerPageOptions]="[25, 50, 100]"
    [showCurrentPageReport]="true" [scrollable]="true" scrollHeight="flex"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    styleClass="p-datatable-gridlines p-datatable-sm">
    <ng-template pTemplate="caption">
      <div class="d-flex align-items-center justify-content-between">
        <span>
          <h4>Mortgage Insurance Quotes</h4>
        </span>
        <span class="d-inline-flex">
          <button type="button" class="btn btn-secondary me-4" *ngIf="showReRunSearch" (click)="onReRunSearch()">Re-Run
            Search</button>
          <ul class="nav nav-pills ms-4" role="tablist" style="background-color: initial">
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{ active: tab == 'unknown' }"
                (click)="tab = 'unknown'; filterByPaymentType('Unknown')" data-bs-toggle="tab" role="tab">
                Unknown
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{ active: tab == 'monthly' }"
                (click)="tab = 'monthly'; filterByPaymentType('PeriodicMonthly')" data-bs-toggle="tab" role="tab">
                Monthly
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{ active: tab == 'split' }"
                (click)="tab = 'split'; filterByPaymentType('SplitPremium')" data-bs-toggle="tab" role="tab">
                Split/Buydown
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{ active: tab == 'single' }"
                (click)="tab = 'single'; filterByPaymentType('SinglePayment')" data-bs-toggle="tab" role="tab">
                Single
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{ active: tab == 'lender-paid' }" (click)="
                  tab = 'lender-paid';
                  filterByPaymentType('LenderPaidSinglePayment')
                " data-bs-toggle="tab" role="tab">
                Lender Paid
              </a>
            </li>
          </ul>
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th pSortableColumn="providerName">
          Vendor
          <p-sortIcon field="providerName" class="pull-right"></p-sortIcon>
        </th>
        <th pSortableColumn="premiumAmount">
          Premium Amount
          <p-sortIcon field="premiumAmount" class="pull-right"></p-sortIcon>
        </th>
        <th pSortableColumn="premiumRatePercent">
          Premium Rate %
          <p-sortIcon field="premiumRatePercent" class="pull-right"></p-sortIcon>
        </th>
        <th pSortableColumn="premiumUpfrontAmount">
          Premium Upfront Amount
          <p-sortIcon field="premiumUpfrontAmount" class="pull-right"></p-sortIcon>
        </th>
        <th pSortableColumn="premiumUpfrontPercent">
          Premium Upfront %
          <p-sortIcon field="premiumUpfrontPercent" class="pull-right"></p-sortIcon>
        </th>
        <th pSortableColumn="providerQuoteId">
          Quote ID
          <p-sortIcon field="providerQuoteId" class="pull-right"></p-sortIcon>
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-detail>
      <tr>
        <td>{{ detail.providerName }}</td>
        <td [attr.colspan]="detail.status == 'Success' ? 1 : 6">
          <ng-container *ngIf="detail.status != 'Success'">
            <span style="font-style: italic">
              No MI quote offered for
              <span class="text-primary">{{ detail.statusReasons?.length }} reasons</span>
              <i class="ms-2 cursor fa fa-chevron-{{
                  detail['isExpanded'] ? 'up' : 'down'
                }}" (click)="detail['isExpanded'] = !detail['isExpanded']"></i>
            </span>
            <ul *ngIf="detail['isExpanded']">
              <li *ngFor="let reason of detail.statusReasons">{{ reason }}</li>
            </ul>
          </ng-container>

          <span *ngIf="detail.status == 'Success'">{{
            detail.premiumAmount | currency
            }}</span>
        </td>
        <ng-container *ngIf="detail.status == 'Success'">
          <td>{{ detail.premiumRatePercent }} %</td>
          <td>{{ detail.premiumUpfrontAmount | currency }}</td>
          <td>{{ detail.premiumUpfrontPercent }} %</td>
          <td>{{ detail.providerQuoteId }}</td>
          <td>
            <a class="btn btn-xs btn-soft-primary" *ngIf="detail.quoteDetailUrl && !isTPO" title="View Quote"
              (click)="showQuoteDetails(detail)" href="javascript: void(0);">
              <i class="fa fa-eye"></i>
            </a>
            <button type="button"
              class="btn btn-{{selectedQuote && detail.providerQuoteId == selectedQuote.providerQuoteId ? 'secondary' : 'primary'}} ms-2"
              [disabled]="selectedQuote && detail.providerQuoteId == selectedQuote.providerQuoteId"
              (click)="onSelectQuote(detail)">Select{{selectedQuote && detail.providerQuoteId ==
              selectedQuote.providerQuoteId ? 'ed' : ''}}
            </button>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="7" class="text-center py-4">
          There are no quotes for the selected payment type
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>
