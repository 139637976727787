import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IneligibleProduct } from '../../models/pricing/ineligible-products-response.model';

@Component({
  selector: 'ineligible-product-detail-dialog',
  templateUrl: './ineligible-product-detail-dialog.component.html',
  styleUrls: ['./ineligible-product-detail-dialog.component.scss']
})
export class IneligibleProductDetailDialogComponent implements OnInit {

  @Input() product: IneligibleProduct;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
