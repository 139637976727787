export class CustomField {
  pricingCustomFieldId: number;
  pricingVendor: string;
  thirdPartyCustomFieldId: string;
  name: string;
  displayName: string;
  description: string;
  type: string = 'List';
  isRequired: boolean = true;
  defaultValue?: string;
  sortOrder: number;
  tempUniquId: number;
  options: Array<CustomFieldOption> = []
  constructor(vendor: 'Polly' | 'LenderPrice' | 'MeridianLink' | 'LoanPass' | 'LoanPassIframe'){
    this.pricingVendor = vendor;
    this.tempUniquId = Math.floor(Math.random() * Date.now())
  }
}

export class CustomFieldOption {
  value: number;
  description: string;
}
