import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { Conversation, ParticipantType } from '../../models/conversation.model';
import { ColorUtils } from 'src/app/shared/utils/color/utils';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'conversation-list-item',
  templateUrl: 'conversation-list-item.component.html',
  styleUrls: ['conversation-list-item.component.scss']
})
export class ConversationListItemComponent implements OnInit {

  @Output()
  conversationSelected: EventEmitter<Conversation> = new EventEmitter<Conversation>();

  @Input()
  set conversation(conversation: Conversation) {
    this._conversation = conversation;
    this._conversation.mostRecentMessagePreview = MentionsUtils.generateDisplayHtmlWithMentions(this.conversation.mostRecentMessagePreview);
    this._conversation.mostRecentMessageDate = conversation.mostRecentMessageDate;
    if (!this._conversation.contactAlias && conversation.externalName) {
      this._conversation.contactAlias = this._conversation.externalName.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
      this._conversation.contactAvatarColor = ColorUtils.normalizedColorFromString(conversation.externalName || uuidv4());
    }

    console.log("updates message");
  }

  get conversation(): Conversation {
    return this._conversation;
  }

  defaultColor: string = "#025c90";

  private _conversation: Conversation;

  constructor() { }

  ngOnInit() {
  }

  onConversationClicked = (conversation: Conversation) => {
    this.conversationSelected.emit(conversation);
    this.conversation.isActive = true;
  }

  checkValidationOfDate = (date: Date) => {
    return !isNaN(date.getTime());
  }
  protected readonly ParticipantType = ParticipantType;
}

