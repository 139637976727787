<form #loanFeaturesDetailsForm="ngForm" novalidate id="loanFeaturesDetailsForm" name="loanFeaturesDetailsForm"
    class="p-3">

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">Property is currently subject to a lien that could take priority over the first
                mortgage lien, such as a clean energy lien paid through property taxes (e.g., the Property Assessed
                Clean Energy program)
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio form-check-inline radio-info ">
                <input type="radio" id="yes_propertyHasLienWithCleanEnergyProgram"
                    [value]="true" name="propertyHasLienWithCleanEnergyProgram"
                    [(ngModel)]="subjectProperty.propertyHasLienWithCleanEnergyProgram" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_propertyHasLienWithCleanEnergyProgram'"> Yes </label>
            </div>
            <div class="radio form-check-inline radio-info ms-3">
                <input type="radio" [value]="false" id="no_propertyHasLienWithCleanEnergyProgram"
                    name="propertyHasLienWithCleanEnergyProgram"
                    [(ngModel)]="subjectProperty.propertyHasLienWithCleanEnergyProgram" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_propertyHasLienWithCleanEnergyProgram'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Is Affordable Loan
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isAffordableLoan" id="yes_isAffordableLoan1"
                    [(ngModel)]="mortgageTerm.isAffordableLoan" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_isAffordableLoan1'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isAffordableLoan1" name="isAffordableLoan"
                    [(ngModel)]="mortgageTerm.isAffordableLoan" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_isAffordableLoan1'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Is Capitalized Loan
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isCapitalizedLoan" id="yes_isCapitalizedLoan"
                    [(ngModel)]="mortgageTerm.isCapitalizedLoan" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_isCapitalizedLoan'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isCapitalizedLoan" name="isCapitalizedLoan"
                    [(ngModel)]="mortgageTerm.isCapitalizedLoan" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_isCapitalizedLoan'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Is Convertible
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isConvertible" id="yes_isConvertible"
                    [(ngModel)]="mortgageTerm.isConvertible" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_isConvertible'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isConvertible" name="isConvertible"
                    [(ngModel)]="mortgageTerm.isConvertible" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_isConvertible'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Has ENote
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="hasENote" id="yes_hasENote"
                    [(ngModel)]="mortgageTerm.hasENote" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_hasENote'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_hasENote" name="hasENote"
                    [(ngModel)]="mortgageTerm.hasENote" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_hasENote'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Has Home Buyers Homeownership Education Cert.
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="hasHomeBuyersHomeownershipEducationCertificate"
                    id="yes_hasHomeBuyersHomeownershipEducationCertificate"
                    [(ngModel)]="mortgageTerm.hasHomeBuyersHomeownershipEducationCertificate" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_hasHomeBuyersHomeownershipEducationCertificate'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_hasHomeBuyersHomeownershipEducationCertificate"
                    name="hasHomeBuyersHomeownershipEducationCertificate"
                    [(ngModel)]="mortgageTerm.hasHomeBuyersHomeownershipEducationCertificate" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_hasHomeBuyersHomeownershipEducationCertificate'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Has Property Inspection Waiver
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="hasPropertyInspectionWaiver"
                    id="yes_hasPropertyInspectionWaiver" [(ngModel)]="mortgageTerm.hasPropertyInspectionWaiver" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_hasPropertyInspectionWaiver'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_hasPropertyInspectionWaiver"
                    name="hasPropertyInspectionWaiver" [(ngModel)]="mortgageTerm.hasPropertyInspectionWaiver" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_hasPropertyInspectionWaiver'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Is Relocation Loan
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isRelocationLoan" id="yes_isRelocationLoan"
                    [(ngModel)]="mortgageTerm.isRelocationLoan" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_isRelocationLoan'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isRelocationLoan" name="isRelocationLoan"
                    [(ngModel)]="mortgageTerm.isRelocationLoan" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_isRelocationLoan'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Is Shared Equity Loan
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isSharedEquityLoan" id="yes_isSharedEquityLoan"
                    [(ngModel)]="mortgageTerm.isSharedEquityLoan" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_isSharedEquityLoan'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isSharedEquityLoan" name="isSharedEquityLoan"
                    [(ngModel)]="mortgageTerm.isSharedEquityLoan" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_isSharedEquityLoan'"> No </label>
            </div>
        </div>
    </div>
    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Partial Payment Allowed
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="partialPaymentAllowed" id="yes_partialPaymentAllowed"
                    [(ngModel)]="mortgageTerm.partialPaymentAllowed" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_partialPaymentAllowed'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_partialPaymentAllowed" name="partialPaymentAllowed"
                    [(ngModel)]="mortgageTerm.partialPaymentAllowed" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_partialPaymentAllowed'"> No </label>
            </div>
        </div>
    </div>
    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Are Deficiency Rights Preserved
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="areDeficiencyRightsPreserved" id="yes_areDeficiencyRightsPreserved"
                    [(ngModel)]="mortgageTerm.areDeficiencyRightsPreserved" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_areDeficiencyRightsPreserved'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_areDeficiencyRightsPreserved" name="areDeficiencyRightsPreserved"
                    [(ngModel)]="mortgageTerm.areDeficiencyRightsPreserved" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_areDeficiencyRightsPreserved'"> No </label>
            </div>
        </div>
    </div>
    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Is Assumable
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isAssumable" id="yes_isAssumable"
                    [(ngModel)]="mortgageTerm.isAssumable" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_isAssumable'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isAssumable" name="isAssumable"
                    [(ngModel)]="mortgageTerm.isAssumable" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_isAssumable'"> No </label>
            </div>
        </div>
    </div>
    <div class="row mb-2 bb" *ngIf="mortgageTerm.isAssumable">
        <div class="col-9">
            <h6 class="mb-3">
                Is Assumed
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isAssumed" id="yes_isAssumed"
                    [(ngModel)]="mortgageTerm.isAssumed" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_isAssumed'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isAssumed" name="isAssumed"
                    [(ngModel)]="mortgageTerm.isAssumed" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_isAssumed'"> No </label>
            </div>
        </div>
    </div>
    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Has Demand Feature
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="hasDemandFeature" id="yes_hasDemandFeature"
                    [(ngModel)]="mortgageTerm.hasDemandFeature" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_hasDemandFeature'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_hasDemandFeature" name="hasDemandFeature"
                    [(ngModel)]="mortgageTerm.hasDemandFeature" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_hasDemandFeature'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Can Loan Amount Increase
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="canLoanAmountIncrease" id="yes_canLoanAmountIncrease"
                    [(ngModel)]="mortgageTerm.canLoanAmountIncrease" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_canLoanAmountIncrease'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_canLoanAmountIncrease" name="canLoanAmountIncrease"
                    [(ngModel)]="mortgageTerm.canLoanAmountIncrease" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_canLoanAmountIncrease'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Can Payment Increase
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="canPaymentIncrease" id="yes_canPaymentIncrease"
                    [(ngModel)]="mortgageTerm.canPaymentIncrease" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_canPaymentIncrease'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_canPaymentIncrease" name="canPaymentIncrease"
                    [(ngModel)]="mortgageTerm.canPaymentIncrease" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_canPaymentIncrease'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Is Deposit Balance Required
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isDepositBalanceRequired" id="yes_isDepositBalanceRequired"
                    [(ngModel)]="mortgageTerm.isDepositBalanceRequired" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_isDepositBalanceRequired'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isDepositBalanceRequired" name="isDepositBalanceRequired"
                    [(ngModel)]="mortgageTerm.isDepositBalanceRequired" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_isDepositBalanceRequired'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Is Energy Efficient Home
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isEnergyEfficientHome" id="yes_isEnergyEfficientHome"
                    [(ngModel)]="subjectProperty.isEnergyEfficientHome" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_isEnergyEfficientHome'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isEnergyEfficientHome" name="isEnergyEfficientHome"
                    [(ngModel)]="subjectProperty.isEnergyEfficientHome" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_isEnergyEfficientHome'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2 bb">
        <div class="col-9">
            <h6 class="mb-3">
                Is Damaged By Disaster
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="isDamagedByDisaster" id="yes_isDamagedByDisaster"
                    [(ngModel)]="subjectProperty.isDamagedByDisaster" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_isDamagedByDisaster'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_isDamagedByDisaster" name="isDamagedByDisaster"
                    [(ngModel)]="subjectProperty.isDamagedByDisaster" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_isDamagedByDisaster'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-9">
            <h6 class="mb-3">
                Property Will Be Group Home
            </h6>
        </div>
        <div class="col-3 align-self-center">
            <div class="radio radio-info form-check-inline">
                <input type="radio" [value]="true" name="propertyWillBeGroupHome" id="yes_propertyWillBeGroupHome"
                    [(ngModel)]="subjectProperty.propertyWillBeGroupHome" [readonly]="readonly">
                <label [for]="readonly ? '' : 'yes_propertyWillBeGroupHome'"> Yes </label>
            </div>
            <div class="radio radio-info form-check-inline ms-3">
                <input type="radio" [value]="false" id="no_propertyWillBeGroupHome" name="propertyWillBeGroupHome"
                    [(ngModel)]="subjectProperty.propertyWillBeGroupHome" [readonly]="readonly">
                <label [for]="readonly ? '' : 'no_propertyWillBeGroupHome'"> No </label>
            </div>
        </div>
    </div>

    <div class="row mb-3" *ngIf="hasSaveCancelButtons && !readonly">
        <div class="col-md-12 text-end">
          <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
          <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
            Save
          </button>
        </div>
    </div>
</form>
