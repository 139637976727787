import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Utils } from 'src/app/core/services/utils';
import {
  ActivityType,
  Borrower,
  LeadStatus,
  LoanStatus,
  MessageDeliveryEnum,
} from 'src/app/models';
import { SmsHistory } from 'src/app/models/chat-sms.model';
import { MessageHistoryItem, RvmFileIdTypeEnum } from 'src/app/models/message.model';
import { User } from 'src/app/models/user/user.model';
import { CorrespondenceService } from 'src/app/modules/correspondence/services/correspondence.service';
import { DialRecordHistorySearchResponse } from 'src/app/modules/dialer/models/dial-record-history-search.model';
import { DialerEventType } from 'src/app/modules/dialer/models/dialer-event.model';
import {
  DialerEvent,
  DialerService,
} from 'src/app/modules/dialer/services/dialer.service';
import { LeadEvent } from 'src/app/modules/leads/models/lead-event.model';
import { LeadRouteHistory } from 'src/app/modules/leads/models/lead-route-history.model';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { EmailSmsPreviewDialogComponent } from 'src/app/modules/loan-activity/email-sms-preview-dialog/email-sms-preview-dialog.component';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { VoiceService } from 'src/app/services/voice.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import Swal, { SweetAlertResult } from 'sweetalert2';

enum ActivityViewType {
  calls = 'Calls',
  notes = 'Notes',
  voiceMails = 'Voice Mails',
  emails = 'Emails',
  sms = 'Sms',
  route = 'Route',
  statusChange = 'Status Changes',
  borrowerMessage = 'Borrower Messages',
  internalMessage = 'Internal Messages',
}

enum LeadActivityType {
  Call = 'Call',
  Route = 'Route',
  Event = 'Event',
}
class Activity {
  activityType: LeadActivityType | ActivityType;
  groupDate: string;
  activity:
    | LeadEvent
    | LeadRouteHistory
    | DialRecordHistorySearchResponse
    | MessageHistoryItem
    | SmsHistory;
  userFullName: string;
  messageTo: string = null;
  messageFrom: string = null;
  messageBody: string = null;
  subject: string = null;
  dateInserted: Date;

  constructor(
    activity?:
      | LeadEvent
      | LeadRouteHistory
      | DialRecordHistorySearchResponse
      | MessageHistoryItem
      | SmsHistory
  ) {
    if (activity) {
      this.activity = activity;
    }
  }
}

@Component({
  selector: 'lead-activity',
  templateUrl: './lead-activity.component.html',
  styleUrls: ['./lead-activity.component.scss'],
})
export class LeadActivityComponent
  extends ApplicationContextBoundComponent
  implements OnInit {
  @ViewChild('scrollMe') scrollMe: ElementRef | undefined;

  @ViewChild('messageEditor') messageEditorComponent: any;

  public get isReloadRequired(): boolean {
    return this._isReloadRequired;
  }

  @Input()
  public set isReloadRequired(value: boolean) {
    this._isReloadRequired = value;
    if (value) {
      this.ngOnInit();
    }
  }

  @Input() recordTypeId: number;
  @Input() recordTypeStatusId: number;
  @Input() mobilePhone: string;

  borrowers: Borrower[];

  message: string;
  isLoading: boolean = false;
  isDialMode: boolean = false;
  activities: Activity[] = [];
  activityViewBy: ActivityViewType[] = [];
  views: { checked: boolean; type: ActivityViewType }[] = [];
  selectedViewTypes: ActivityViewType[] = [];

  leadStatuses: LeadStatus[] = [];
  loanstatuses: LoanStatus[] = [];
  users: User[] = [];
  currentUserName: string;

  isSaving: boolean = false;
  losEnabled: boolean = false;

  note: string = '';

  groupedData: { groupDate: string; activities: Activity[] }[] = [];
  activityTab: string = 'All';
  tabCounts = {
    LoanChangeStatus: 0,
    BorrowerMessage: 0,
    InternalMessage: 0,
    Lead: 0,
    DocumentFile: 0,
    Paused: 0,
    Email: 0,
    Sms: 0,
    Voice: 0,
    LosMessage: 0,
  };
  activityTabs = {
    LoanChangeStatus: ['LoanChangeStatus', 'LeadChangeStatus'],
    BorrowerMessage: ['BorrowerMessage'],
    InternalMessage: ['InternalMessage'],
    Lead: ['Lead', 'route', 'call', 'Call'],
    DocumentFile: ['DocumentFile'],
    Email: ['Email'],
    Sms: ['sms', 'Sms'],
    Voice: ['Voice'],
    Paused: ['PauseEmailSms'],
    LosMessage: ['LosMessage'],
  };

  private _isReloadRequired: boolean;

  constructor(
    private readonly _leadsService: LeadsService,
    private readonly _voiceService: VoiceService,
    private readonly _dialerService: DialerService,
    private readonly _correspondenceService: CorrespondenceService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly _cdref: ChangeDetectorRef,
    private readonly injector: Injector
  ) {
    super(injector);

    this._dialerService.events.subscribe((e: DialerEvent) => {
      if (!e) {
        return;
      }

      switch (e.eventType) {
        case DialerEventType.LeadQuickNoteAdded:
          this.ngOnInit();
          break;
        case DialerEventType.LeadQuickSMSSended:
          this.ngOnInit();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.initViewByOptions();

    this.applicationContextService.context.subscribe((context) => {
      this.leadStatuses = context.globalConfig.leadStatus;
      this.users = context.globalConfig.users || [];
      this.losEnabled = context.userPermissions.losEnabled;
      this.borrowers = context.borrowers;

      this.currentUserName = this.getUserFullName(
        context.userPermissions.userId
      );

      this.initializeLeadActivity();
    });
  }

  onMessageChanged = (message: string) => {
    this.note = message;
  };

  onEnterHit = () => { };

  deleteNoteEvent = (leadActivity: LeadEvent) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to delete this note?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }

      // delete event
      this._leadsService.deleteLeadEvent(leadActivity.leadEventId)
        .subscribe({
          next: () => {
            this.initializeLeadActivity();
          },
          error: (err) => {
            const errorMessage = err && err.message ? err.message : 'An error occurred while deleting note.';
            this._notifyService.showError(errorMessage, 'Error!');
          }
        })
    });
  }

  onSaveClicked = () => {
    this._spinnerService.show();
    const leadEvent = new LeadEvent();
    leadEvent.leadId = this.recordTypeId;
    leadEvent.leadStatusId = this.recordTypeStatusId;
    leadEvent.note = this.note;
    leadEvent.type = 'note';
    this._leadsService
      .addLeadEvent(leadEvent)
      .subscribe(
        (result) => {
          this._leadsService.sendEventUpdatedLead(this.recordTypeId, this.note);
          this.note = '';
          this.messageEditorComponent.reset();
          this.initializeLeadActivity();
        },
        (error) => {
          const errorMessage =
            error && error.message
              ? error.message
              : 'An error occurred while adding a note.';
          this._notifyService.showError(errorMessage, 'Error!');
        }
      )
      .add(() => {
        this._spinnerService.hide();
      });
  };

  ngAfterViewChecked() {
    this._cdref.detectChanges();
  }

  listenToRecording = (voiceHistoryId: number): void => {
    this._voiceService.getCallRecording(voiceHistoryId).subscribe({
      next: (res) => {
        // trigger the mp3 to download
        window.open(res.mp3RecordingUri, '_blank');
      },
      error: () => { }
    });
  };

  downloadRecording = (voiceHistoryId: number): void => {
    this._voiceService.getCallRecording(voiceHistoryId).subscribe({
      next: (res) => {
        // trigger the mp3 to download
        this.downloadURI(res.mp3RecordingUri);
      },
      error: () => { }
    });
  };

  showEmailDetails = (messageHistoryItem: MessageHistoryItem) => {
    this._spinnerService.show();
    this._leadsService.getEmailDetail(messageHistoryItem.id).subscribe(
      (response) => {
        this._spinnerService.hide();
        const modalRef = this._modalService.open(
          EmailSmsPreviewDialogComponent,
          Constants.modalOptions.xlarge
        );
        modalRef.componentInstance.message = response;
      },
      (err) => {
        this._spinnerService.hide();
        this._notifyService.showError(err.message, 'Error');
      }
    );
  };

  downloadURI = (url: string, name?: string, ext?: string): void => {
    this._spinnerService.show();

    this._leadsService.downloadVoiceFile(url).subscribe(
      (data) => {
        const blob = new Blob([data], { type: 'audio/mpeg' });
        let downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);

        let fileName;
        if (name) {
          fileName = name;
        } else if (ext) {
          fileName = 'download-' + this.randomString() + '.' + ext;
        } else {
          fileName = 'download-' + this.randomString();
        }

        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        this._spinnerService.hide();
      },
      () => {
        this._spinnerService.hide();
      }
    );
  };

  changedView = (): void => {
    let checkedViews = this.views.filter((v) => v.checked);
    this.selectedViewTypes = checkedViews.map((v) => v.type);
    this.groupActivitiesByGroupDate();
    this.ngAfterViewChecked();

    this._localStorageService.setItem(
      'dialActivityViewBy',
      this.selectedViewTypes
    );
  };

  submitNewMessage = (): void => {
    if (this.message) {
      this.isSaving = true;

      let params = {
        leadId: this.recordTypeId,
        type: 'note',
        note: this.message,
        leadStatusId: this.recordTypeStatusId,
      } as LeadEvent;

      this._leadsService.addLeadEvent(params).subscribe((res) => {
        this.isSaving = false;

        let leadActivity = new Activity(res);

        leadActivity.activityType = LeadActivityType.Event;
        leadActivity.userFullName = this.currentUserName;
        leadActivity.groupDate = this.getFormattedGroupDate(res.dateInserted);
        leadActivity.dateInserted = res.dateInserted;

        this.activities.push(leadActivity);

        this.groupActivitiesByGroupDate();

        this.message = null;
      });
    }
  };

  secondsToHms = (seconds: number): string => {
    return Utils.secondsToHms(seconds);
  }


  viewImage = (base64Image) => {
    const parts = base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    const blob = new Blob([uInt8Array], { type: imageType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  private initializeLeadActivity = () => {
    let observable = forkJoin({
      eventActivities: this.getLeadEvents(),
      routeActivities: this.getLeadRouteHistories(),
      dialActivities: this.searchDialRecordHistory(),
      correspondenceActivities: this.getLeadEmailsAndSms(),
    }).pipe(
      map((activities) => {
        return activities.dialActivities.concat(
          activities.eventActivities,
          activities.correspondenceActivities,
          activities.routeActivities
        );
      })
    );

    if (observable) {
      observable.subscribe(
        (activities) => {
          this.activities = activities;
          this.groupActivitiesByGroupDate();
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  };

  private initViewByOptions = (): void => {
    if (this.isDialMode) {
      let dialActivityViewBy =
        this._localStorageService.getItem('dialActivityViewBy');
      if (dialActivityViewBy) {
        this.activityViewBy = dialActivityViewBy as ActivityViewType[];
      }
    } else {
      this.activityViewBy = Object.keys(ActivityViewType)
        .filter((key) => {
          return key != 'borrowerMessage';
        })
        .map((key) => ActivityViewType[key]) as ActivityViewType[];
    }

    this.views = this.activityViewBy.map((v) => {
      return { type: v, checked: true };
    });

    this.selectedViewTypes = this.activityViewBy;
  };

  private getLeadEmailsAndSms = (): Observable<Activity[]> => {
    return this._correspondenceService.getMessagesSentForLead(this.recordTypeId).pipe(
      map((res) => {
        return res.map((ele) => {
          let activity = new Activity(ele);
          activity.messageTo = ele.to;
          activity.messageFrom = ele.from;
          activity.messageBody = ele.body;
          activity.subject = ele.subject;

          switch (ele.delivery) {
            case MessageDeliveryEnum.Rvm:
              activity.activityType = ActivityType.Rvm;
              if (activity.activity) {
                (activity.activity as LeadEvent).type = (activity.activity as MessageHistoryItem).status;
              }
              break;
            case MessageDeliveryEnum.SMS:
              activity.activityType = ActivityType.Sms;
              break;
            default:
              activity.activityType = ActivityType.Email;
              break;
          }
          activity.userFullName = this.getUserFullName(ele.fromUserId);
          activity.groupDate = this.getFormattedGroupDate(ele.dateInserted);
          activity.dateInserted = ele.dateInserted;
          return activity;
        });
      })
    );
  };

  private getLeadEvents = (): Observable<Activity[]> => {
    return this._leadsService.getLeadEvents(this.recordTypeId).pipe(
      map((res) => {
        const events = _.orderBy(res.filter(a => a.type != 'email' && a.type != 'sms'), ['dateInserted'], ['desc']);
        return events.map((ele) => {
          let leadActivity = new Activity(ele);
          leadActivity.activityType = LeadActivityType.Event;
          leadActivity.userFullName = this.getUserFullName(ele.createdByUserId);
          leadActivity.groupDate = this.getFormattedGroupDate(
            ele.dateUpdated || ele.dateInserted
          );
          leadActivity.dateInserted = ele.dateUpdated || ele.dateInserted;
          return leadActivity;
        });
      })
    );
  };

  private getLeadRouteHistories = (): Observable<Activity[]> => {
    return this._leadsService.getLeadRouteHistories(this.recordTypeId).pipe(
      map((res) => {
        return res.map((ele) => {
          let leadActivity = new Activity(ele);
          leadActivity.activityType = LeadActivityType.Route;
          leadActivity.groupDate = this.getFormattedGroupDate(
            ele.dateUpdated || ele.dateInserted
          );
          leadActivity.userFullName = this.getUserFullName(ele.insertedBy);
          leadActivity.dateInserted = ele.dateUpdated || ele.dateInserted;
          return leadActivity;
        });
      })
    );
  };

  private searchDialRecordHistory = (): Observable<Activity[]> => {
    let searchItem = { leadId: this.recordTypeId };

    return this._dialerService.searchDialRecordHistory(searchItem, false).pipe(
      map((res) => {
        return res.map((ele) => {
          let leadActivity = new Activity(ele);

          leadActivity.activityType = LeadActivityType.Call;
          leadActivity.groupDate = this.getFormattedGroupDate(
            ele.recordHistory.dateUpdated || ele.recordHistory.dateInserted
          );
          leadActivity.userFullName = this.getUserFullName(
            ele.recordHistory.insertedBy
          );
          leadActivity.dateInserted =
            ele.recordHistory.dateUpdated || ele.recordHistory.dateInserted;
          return leadActivity;
        });
      })
    );
  };

  private sortArrayByDateField = (
    array: any[],
    fieldName: string,
    isAsc: boolean = true
  ): any[] => {
    let mutedArray = [...array];

    let sortedArray = mutedArray.sort((a, b) => {
      let dateA = new Date(a[fieldName]).getTime();
      let dateB = new Date(b[fieldName]).getTime();
      return isAsc ? dateA - dateB : dateB - dateA;
    });

    return sortedArray;
  };

  private groupActivitiesByGroupDate = (): void => {
    _.orderBy(this.activities, x => x.dateInserted);
    this.activities.forEach((a) => {
      a['typeDisplayName'] = this.getLeadActivityType(a);
      a.activity["note"] = a.activity["note"]?.replaceAll('\n', '<br/>');

      // a.activity['mediaFiles'] = [];
      // const activityLog = this.setImageIfExist(a.activity);
      // a.activity = activityLog || a.activity;
    });
    let groupedData = _.chain([...this.activities])
      .groupBy('groupDate')
      .map((value, key) => ({ groupDate: key, activities: value }))
      .value();

    let sortedGroups = this.sortArrayByDateField(
      groupedData,
      'groupDate',
      false
    );

    sortedGroups.forEach((group) => {
      group.activities = this.sortArrayByDateField(
        group.activities,
        'dateInserted',
        false
      ).filter((act) => act.type || act.activityType);
    });

    this.groupedData = sortedGroups;
  };

  private setImageIfExist = (log) => {
    if (
      log.note &&
      log.note.includes('data:image/png;base64') ||
      log.note.includes('data:image/jpg;base64') ||
      log.note.includes('data:image/jpeg;base64')
    ) {
      log['hasMediaFiles'] = true;
      const base64Img = log.note.substring(log.note.indexOf('[') + 1, log.note.indexOf(']'));
      log['mediaFiles'].push(base64Img);
      log.note = log.note.replace(`[${base64Img}]`, '');
      return this.setImageIfExist(log);
    } else {
      return log;
    }
  };

  private getLeadActivityType(leadActivity) {
    if (!leadActivity) return '';

    let type = '';
    let activityType = '';
    if (leadActivity.activityType) {
      activityType = leadActivity.activityType;
    }
    if (leadActivity.activity.type) {
      type = leadActivity.activity.type;
    }

    let result = '';
    if (
      activityType == 'Event' &&
      (type == 'phoneSuccess' || type == 'phoneAttempt') &&
      this.isSelectedViewType('Notes')
    ) {
      result = ActivityViewType.voiceMails;
    } else if (
      activityType === ActivityType.Rvm &&
      (type == 'Success' || type == 'Failure') &&
      this.isSelectedViewType(ActivityViewType.voiceMails)
    ) {
      result = ActivityViewType.voiceMails;
    } else if (
      activityType === 'Call' &&
      this.isSelectedViewType('Calls') &&
      leadActivity.activity.recordHistory
    ) {
      result = ActivityViewType.calls;
    } else if (activityType === 'Route' && this.isSelectedViewType('Route')) {
      result = ActivityViewType.route;
    } else if (
      activityType.toLocaleLowerCase() === 'sms' &&
      this.isSelectedViewType('Sms')
    ) {
      result = ActivityViewType.sms;
    } else if (
      type === 'ChangeStatus' &&
      this.isSelectedViewType('Status Changes')
    ) {
      result = ActivityViewType.statusChange;
    } else if (
      activityType === 'BorrowerMessage' &&
      this.isSelectedViewType('Borrower Messages')
    ) {
      result = ActivityViewType.borrowerMessage;
    } else if (
      activityType === 'InternalMessage' &&
      this.isSelectedViewType('Internal Messages')
    ) {
      result = ActivityViewType.internalMessage;
    } else if (type === 'note' && this.isSelectedViewType('Notes')) {
      result = ActivityViewType.notes;
    } else if (
      activityType.toLocaleLowerCase() === 'email' &&
      this.isSelectedViewType('Emails')
    ) {
      result = ActivityViewType.emails;
    }
    return result;
  }

  private getUserFullName = (searchUserId: string): string => {
    let defaultName = 'Unknown';
    if (!searchUserId) {
      return defaultName;
    }

    let found = this.users.find((usr) => usr.userCompanyGuid == searchUserId);
    if (found) {
      return found.firstName + ' ' + found.lastName;
    }

    if (this.borrowers && this.borrowers.length > 0) {
      let found2 = this.borrowers.find((usr) => usr.userId == searchUserId);
      return found2 ? found2.firstName + ' ' + found2.lastName : defaultName;
    }

    return defaultName;
  };

  private getFormattedGroupDate = (gDate: Date): string => {
    return gDate ? new Date(gDate).toLocaleDateString('en-US') : undefined;
  };

  private isSelectedViewType = (type): boolean => {
    return this.selectedViewTypes.includes(type);
  };

  private randomString = (): string => {
    return Math.random().toString(26).slice(2);
  };
}
