import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationMenu} from './application-menu';

export class MimeTypes {
  pdf: string = 'application/pdf';
  html: string = 'text/html';
  xml: string = 'text/xml';
  octetStream: string = 'application/octet-stream';
}

export class Authorization {
  authorizationDataKey: string = 'authorizationData';
  adminAuthorizationDataKey: string = 'adminAuthorizationData';
  expires: string = '.expires';
  passwordGrantType = 'password';
  adminScope = 'Admin';
  tpoScope = 'TPO';
  scope = 'scope';
  grantType = 'grant_type';
  email = 'email';
  password = 'password';
  companyGuid = 'companyGuid';
  userCompanyGuid = 'userCompanyGuid';
  twoFactorAuthCode = 'code';
  userType = 'userType';
  token = 'token';
  userName = 'userName';
}

export class FederatedAuth {
  clientId: string = 'client_id';
  redirectUrl: string = 'redirect_uri';
  state: string = 'state';
  scope: string = 'scope';
  responseType: string = "response_type";
}

export class MilitaryStatuses {
  isActiveDutyMilitary: string = 'isActiveDutyMilitary';
  onlyNonActivatedReserveOrNationalGuard: string =
    'onlyNonActivatedReserveOrNationalGuard';
  isRetiredDischargedSeparatedFromMilitary: string =
    'isRetiredDischargedSeparatedFromMilitary';
}
export class EnumItemNames {
  ethnicityTypeHispanicOrLatino: string = 'EthnicityTypeHispanicOrLatino';
  ethnicityOriginTypeOther: string = 'EthnicityOriginTypeOther';
  raceDesignationAsianIndian: string = 'RaceDesignationAsianIndian';
  raceDesignationChinese: string = 'RaceDesignationChinese';
  raceDesignationFilipino: string = 'RaceDesignationFilipino';
  raceDesignationJapanese: string = 'RaceDesignationJapanese';
  raceDesignationKorean: string = 'RaceDesignationKorean';
  raceDesignationVietnamese: string = 'RaceDesignationVietnamese';
  raceDesignationOtherAsian: string = 'RaceDesignationOtherAsian';
  raceDesignationGuamanianOrChamorro: string =
    'RaceDesignationGuamanianOrChamorro';
  raceDesignationNativeHawaiian: string = 'RaceDesignationNativeHawaiian';
  raceDesignationSamoan: string = 'RaceDesignationSamoan';
  raceDesignationOtherPacificIslander: string =
    'RaceDesignationOtherPacificIslander';
  raceTypeAsian: string = 'RaceTypeAsian';
  raceTypeNativeHawaiianOrOtherPacificIslander: string =
    'RaceTypeNativeHawaiianOrOtherPacificIslander';
  raceTypeAmericanIndianOrAlaskaNative: string =
    'RaceTypeAmericanIndianOrAlaskaNative';
  militaryStatuses: MilitaryStatuses = new MilitaryStatuses();
  applicationTakenMethodTypeFaceToFace: string =
    'ApplicationTakenMethodTypeFaceToFace';
  isAmortizationTypeArm: string = 'AmortizationTypeARM';
  projectTypeCondominium: string = 'ProjectTypeCondominium';
  projectTypeNotInAProject: string = 'ProjectTypeNotInAProject';
}

export class CommonEnumerations {
  agentType: string = 'AgentType';
  taskType: string = 'TaskType';
}
export class Enumerations {
  suffix: string = 'Suffix';
  residencyTypes: string = 'ResidencyTypes';
  maritalStatuses: string = 'MaritalStatusType';
  domesticRelationshipTypes: string = 'DomesticRelationshipType';
  residencyBasisTypes: string = 'ResidencyBasis';
  propertyTypesOwned: string = 'PriorPropertyUsageType';
  holdTitles: string = 'PriorPropertyTitleType';
  bankruptcyTypes: string = 'BankruptcyType';
  ethnicityType: string = 'EthnicityType';
  ethnicityOriginType: string = 'EthnicityOriginType';
  raceType: string = 'RaceType';
  sexType: string = 'SexType';
  raceDesignation: string = 'RaceDesignation';
  applicationTakenMethodType: string = 'ApplicationTakenMethodType';
  enumItemNames: EnumItemNames = new EnumItemNames();
  assetTypes: string = 'AssetType';
  lienPositionType: string = 'LienPositionType';
  loanAmortizationPeriodType: string = 'LoanAmortizationPeriodType';
  giftGrantSource: string = 'GiftGrantSource';
  purchaseCreditTypes: string = 'PurchaseCreditType';
  liabilityTypes: string = 'LiabilityType';
  loanPurposes: string = 'LoanPurposeType';
  refinancePurposes: string = 'RefinancePurposeType';
  refinancePrimaryPurposes: string = 'RefinancePrimaryPurposeType'
  propertyTypes: string = 'PropertyWillBeType';
  propertyConstructionMethod: string = 'PropertyConstructionMethod';
  propertyConstructionStatus: string = 'PropertyConstructionStatus'
  downPaymentSourceTypes: string = 'PurchaseCreditSourceType';
  incomeType: string = 'IncomeType';
  currentPropertyWillBeType: string = 'CurrentPropertyWillBeType';
  propertyStatusOptions: string = 'ReoStatusType';
  intendedPropertyWillBeType: string = 'IntendedPropertyWillBeType';
  reoPropertyTypes: string = 'GsePropertyType';
  employmentOwnershipShare: string = 'EmploymentOwnershipShare';
  feePercentOfFieldType: string = 'FeePercentOfField';

  wireRequestEthnicity: string = 'WireRequestEthnicity';
  wireRequestRace: string = 'WireRequestRace';
  wireRequestAmortizationType: string = 'WireRequestAmortizationType';
  wireRequestAppraisalType: string = 'WireRequestAppraisalType';
  wireRequestDocumentationType: string = 'WireRequestDocumentationType';
  wireRequestLoanType: string = 'WireRequestLoanType';
  wireRequestPropertyType: string = 'WireRequestPropertyType';
  wireRequestRateType: string = 'WireRequestRateType';
  wireRequestOccupancy: string = 'WireRequestOccupancy';
  wireRequestInvestorName: string = 'WireRequestInvestorName';
  wireRequestLienPosition: string = 'WireRequestLienPosition';

  selfEmploymentForm: string = "SelfEmploymentForm";
  verificationStatus: string = "VerificationStatus";
  borrowerCounsellingFormatType: string = "BorrowerCounselingFormatType";

  appraisalProvider: string = "AppraisalProvider";
}

export class PricingEnumerations {
  citizenship: string = 'Citizenship';
  loanTerm: string = 'LoanTerm';
  loanType: string = 'MortgageAppliedForType';
  yesNo: string = 'YesNo';
  documentationStatus: string = 'DocumentationStatus';
  incomeDocumentation: string = 'IncomeDocumentation';
  occupancy: string = 'Occupancy';
  armFixedTerm: string = 'ArmFixedTerm';
  numberOfUnits: string = 'NumberOfUnits';
  productType: string = 'ProductType';
  automatedUnderwritingSystem: string = 'AutomatedUnderwritingSystem';
  prepaymentPenalty: string = 'PrepaymentPenalty';
  typeOfVeteran: string = 'TypeOfVeteran';
  buyDown: string = 'Buydown';
  includeLoCompensationPricing: string = 'IncludeLoCompensationInPricing';
  compensationPercentBasedOn: string = 'CompensationBasedOn';
  documentationType: string = "DocumentationType";
  pricingEngineVendor: string = "PricingEngineVendor";
  incomeVerificationType: string = "IncomeVerificationType";
  incomeVerificationMethod: string = "IncomeVerificationMethod";
  bankStatementExpenseMethod: string = "BankStatementExpenseMethod";
  investorExperience: string = "InvestorExperience";
  bankruptcy: string = "Bankruptcy";
  bankruptcyType: string = "BankruptcyType";
  bankruptcyOutcome: string = "BankruptcyOutcome";
  housingEventType: string = "HousingEventType";
  seasoning: string = "Seasoning";
}
export class FeeEnumerations {
  pricingEngineVendor: string = 'PricingEngineVendor';
  feeType: string = 'FeeType';
  perDiemCalculationMethodType: string = 'PerDiemCalculationMethodType';
  feePaidTo: string = 'FeePaidTo';
  feePercentOfField: string = 'FeePercentOfField';
}

export class TitleEnumerations {
  orderType: string = "TitleOrderType";
  productType: string = "TitleProductType";
  documentType: string = "TitleDocumentType";
  orderStatus: string = "TitleOrderStatus";
  orderSyncType: string = "TitleOrderSyncType";
}

export class DocumentSetEnumerations {
  mortgageDocumentSet: string = "MortgageDocumentSet";
}

export class MortgageEnumerations {
  loanPurpose: string = 'LoanPurposeType';
  refinancePurpose: string = 'RefinancePurposeType';
  amortizationType: string = 'AmortizationType';
  propertyType: string = 'PropertyType';
  propertyTitleType: string = 'PropertyTitleType';
  mortgageAppliedForType: string = 'MortgageAppliedForType';
  subjectProperty: string = 'SubjectProperty';
  appraisalFormType: string = 'AppraisalFormType';
  //propertyInspectionType: string = "PropertyInspectionType";
  appraisalMethodType: string = 'AppraisalMethodType';
  estateType: string = 'EstateType';
  recordingJurisdictionType: string = 'RecordingJurisdictionType';
  titleType: string = 'PropertyTitleType';
  propertyTrustClassification: string = 'PropertyTrustClassification';
  nativeAmericanLandsType: string = 'NativeAmericanLandsType';
  productType: string = 'ProductType';
  lendingProductType: string = 'CommunityLendingProductType';
  secondsRepaymentType: string = 'CommunitySecondsRepaymentType';
  negativeAmortizationType: string = 'NegativeAmortizationType';
  armIndexType: string = 'ArmIndexType';
  armIndexSourceType: string = 'ArmIndexSourceType';
  governmentRefinanceType: string = 'GovernmentRefinanceType';
  constructionToPermanentClosingType: string = 'ConstructionToPermanentClosingType';
  projectType: string = 'ProjectType';
  propertyAttachmentType: string = 'PropertyAttachmentType';
  creditorServicingOfLoanStatementType: string = 'CreditorServicingOfLoanStatementType';
  paymentBillingStatementFrequencyType: string = 'PaymentBillingStatementFrequencyType';
  loanUnderwritingSubmitterType: string = 'LoanUnderwritingSubmitterType';
  lateChargeType: string = 'LateChargeType';
  partialPaymentApplicationMethodType: string = 'PartialPaymentApplicationMethodType';
  mortgagePaymentFrequency: string = 'MortgagePaymentFrequency';
  projectDesignType: string = 'ProjectDesignType';
  improvementStatusType: string = 'ImprovementStatusType';
  propertyWillBeType: string = 'PropertyWillBeType';
  landValueType: string = 'LandValueType';
  mortgageDocumentSet: string = 'MortgageDocumentSet';
  constructionMethodType: string = 'PropertyConstructionMethod';
  attachmentType: string = 'PropertyAttachmentType';
  manufacturedHomeWidthType: string = 'ManufacturedHomeWidthType';
  premiumRefundableType: string = 'MIPremiumRefundableType';
  renewalType: string = 'MIRenewalType';
  renewalCalculationType: string = 'MIRenewalCalculationType';
  premiumRatePlanType: string = 'MIPremiumRatePlanType';
  paymentFrequencyType: string = 'MIPaymentFrequencyType';
  durationType: string = 'MIDurationType';
  premiumSourceType: string = 'MIPremiumSource';
  lienPositionType: string = 'LienPositionType';
  governmentLoanSectionOfActType: string = 'GovernmentLoanSectionOfActType';
  fhaInsuranceProgramType: string = 'FhaInsuranceProgramType';
  vaFundingFeeExemptionType: string = 'VAFundingFeeExemptionType';
  language: string = 'Language';
  selfEmploymentForm: string = 'SelfEmploymentForm';
  ausSystemType: string = 'AusSystemType';
  freddieMacRefinanceProgram: string = 'FreddieMacRefinanceProgram';
  freddieMacLoanProgram: string = 'FreddieMacLoanProgram';
  giftGrantSource: string = 'GiftGrantSource';
  hazardInsuranceCoverageType: string = 'HazardInsuranceCoverageType';
  manufacturedHomeConditionType: string = 'ManufacturedHomeConditionType';
  certificateOfTitleType: string = 'CertificateOfTitleType';
  lotOwnershipType: string = 'LotOwnershipType';
  governmentLoanApplicationType: string = 'GovernmentLoanApplicationType';
  fHAAlimonyLiabilityTreatmentType: string = 'FHAAlimonyLiabilityTreatmentType';
  vaAppraisalType: string = 'VAAppraisalType';
  vaLoanProcedureType: string = 'VALoanProcedureType';
  vaTitleVestingType: string = 'VATitleVestingType';
  vaCashOutRefinanceType: string = 'VACashOutRefinanceType';
  propertyOccupancyType: string = 'PropertyOccupancyType';
  unitOwnedByType: string = 'UnitOwnedByType';
  currentPropertyWillBeType: string = 'CurrentPropertyWillBeType';
  condominiumProjectStatusType: string = 'CondominiumProjectStatusType';
  nFIPCommunityParticipationStatus: string = 'NFIPCommunityParticipationStatus';
  hMDAPreapprovalType: string = 'HMDAPreapprovalType';
  borrowerAliasType: string = 'BorrowerAliasType';
  borrowerRelationshipTitleType: string = 'BorrowerRelationshipTitleType';
  signingRole: string = 'SigningRole';
  mortgageEntityType: string = 'MortgageEntityType';
  mortgagePartyType: string = 'MortgagePartyType';
  informationalFormSeriesTranscriptRequestedPositionType: string =
    'InformationalFormSeriesTranscriptRequestedPositionType';
  prepaymentPenaltyPrincipalBalanceType: string = 'PrepaymentPenaltyPrincipalBalanceType';
  prepaymentPenaltyOptionType: string = 'PrepaymentPenaltyOptionType';
  qualifyingRateType: string = 'QualifyingRateType';
  qualifiedMortgageTemporaryGSEType: string = 'QualifiedMortgageTemporaryGSEType';
  abilityToRepayMethodType: string = 'AbilityToRepayMethodType';
  qualifiedMortgageType: string = 'QualifiedMortgageType';
  presumptionOfComplianceType: string = 'PresumptionOfComplianceType';
  abilityToRepayExemptionCreditorOrganizationType: string =
    'AbilityToRepayExemptionCreditorOrganizationType';
  abilityToRepayExemptionLoanProgramType: string = 'AbilityToRepayExemptionLoanProgramType';
  buydownCalculationType: string = 'BuydownCalculationType';
  militaryBranch: string = 'MilitaryBranch';
  vaBorrowerCertificationOccupancyType: string = 'VABorrowerCertificationOccupancyType';
  duAusRecommendation: string = 'DuAusRecommendation';
  lpaAusRecommendation: string = 'LpaAusRecommendation';
  entityVestingType: string = 'EntityVestingType';
}

export class MortgageInsuranceEnumerations {
  specialLoanProgram: string = 'MiSpecialLoanProgram';
  vendor: string = 'MiVendor';
}

export class RegexPatterns {
  email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  ssn = /^\d{3}-?\d{2}-?\d{4}$/;
  phone = /^[0-9]{10}$/;
  year = /^[0-9]{4}$/;
  date = /(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/((19|2[0-9])[0-9]{2})/;
  monthYear = /(0[1-9]|1[012])\/((19|2[0-9])[0-9]{2})/;
  time = /(0[12]|1[02]):([0-5][0-9]):([0-5][0-9]) ([aA]|[pP])[mM]/;
}
export class FeeSectionDisplayNames {
}
export class ModalOptions {

  large: NgbModalOptions = {
    size: 'lg',
    backdrop: 'static',
    centered: true,
    scrollable: true,
  };

  xlarge: NgbModalOptions = {
    size: 'xl',
    backdrop: 'static',
    centered: true,
    //windowClass: 'modal-window-class'
    scrollable: true,
  };

  medium: NgbModalOptions = {
    size: 'md',
    backdrop: 'static',
    centered: true,
    scrollable: true,
  };

  small: NgbModalOptions = {
    size: 'sm',
    backdrop: 'static',
    centered: true,
    scrollable: true,
  };

  fullScreen: NgbModalOptions = {
    size: 'xl',
    backdrop: 'static',
    centered: true,
    scrollable: true,
    windowClass: 'modal-fullscreen',
    modalDialogClass: 'modal-fullscreen'
  }

  ninetyPercentOfScreenWidth: NgbModalOptions = {
    size: 'xl',
    backdrop: 'static',
    centered: true,
    windowClass: 'modal-dialog-90pct-of-screen-width'
  }
}
export class Menu {
  servicesMenuItems = {
    credit: 'credit',
    aus: 'aus',
    voiVoe: 'voi-voe',
    voie: 'voie',
    voa: 'voa',
    appraisal: 'appraisal',
    disclosure: 'disclosure',
    pricing: 'pricing',
    submission: 'submission',
    fees: 'fees'
  };

  urlaMenuItems = {
    borrowerInfo: 'borrowerInfo',
    financialInfo: 'financialInfo',
    reo: 'reo',
    loanAndProperty: 'loanAndProperty',
    militaryService: 'militaryService',
    loanOriginatorInfo: 'loanOriginatorInfo',
    declarations: 'declarations',
    propertyLoanInfo: 'propertyLoanInfo',
    titleInfo: 'titleInfo',
    mortgageLoanInfo: 'mortgageLoanInfo',
    qualifyingTheBorr: 'qualifyingTheBorr',
    demographics: 'demographics',
    homeownershipEducation: 'homeownershipEducation',
  };
}
export class EnumerationValueNames {
  MortgageAppliedForType = {
    VA: 'MortgageAppliedForTypeVA',
    FHA: 'MortgageAppliedForTypeFHA',
    Conventional: 'MortgageAppliedForTypeConventional',
    USDA: 'MortgageAppliedForTypeUSDA',
    OtherMortgage: 'MortgageAppliedForTypeOtherMortgage',
  };

  AmortizationType = {
    ARM: 'AmortizationTypeARM',
    OtherAmortization: 'AmortizationTypeOtherAmortization',
    Fixed: 'AmortizationTypeFixedRate'
  };

  LoanPurposeType = {
    Purchase: 'LoanPurposeTypePurchase',
    Refinance: 'LoanPurposeTypeRefinance',
    ConstructionToPermanent: 'LoanPurposeTypeConstructionToPermanent',
    ConstructionOnly: 'LoanPurposeTypeConstructionOnly',
  };

  DomesticRelationshipType = {
    CivilUnion: 'DomesticRelationshipTypeCivilUnion',
    DomesticPartnership: 'DomesticRelationshipTypeDomesticPartnership',
    RegisteredReciprocalBeneficiaryRelationship: 'DomesticRelationshipTypeRegisteredReciprocalBeneficiaryRelationship',
    Other: 'DomesticRelationshipTypeOther',
  }

  EstateType = {
    FeeSimple: 'EstateTypeFeeSimple',
    Leasehold: 'EstateTypeLeasehold',
  };

  ResidencyBasis = {
    Rent: 'ResidencyBasisRent',
  };

  RecordingJurisdictionType = {
    County: 'RecordingJurisdictionTypeCounty',
    Other: 'RecordingJurisdictionTypeOther',
  }

  RaceType = {
    Asian: 'RaceTypeAsian',
    AmericanIndianOrAlaskaNative: 'RaceTypeAmericanIndianOrAlaskaNative',
    NativeHawaiianOrOtherPacificIslander:
      'RaceTypeNativeHawaiianOrOtherPacificIslander',
    BlackOrAfricanAmerican: 'RaceTypeBlackOrAfricanAmerican',
    White: 'RaceTypeWhite',
    InformationNotProvided: 'RaceTypeInformationNotProvided',
    NotApplicable: 'RaceTypeNotApplicable',
  };

  EthnicityType = {
    HispanicOrLatino: 'EthnicityTypeHispanicOrLatino',
    NotHispanicOrLatino: 'EthnicityTypeNotHispanicOrLatino',
    InformationNotProvided: 'EthnicityTypeInformationNotProvided',
    NotApplicable: 'EthnicityTypeNotApplicable',
  };

  SexType = {
    Female: 'SexTypeFemale',
    Male: 'SexTypeMale',
    InformationNotProvided: 'SexTypeInformationNotProvided',
    NotApplicable: 'SexTypeNotApplicable',
  };

  EthnicityOriginType = {
    Other: 'EthnicityOriginTypeOther',
    Mexican: 'EthnicityOriginTypeMexican',
    PuertoRican: 'EthnicityOriginTypePuertoRican',
    Cuban: 'EthnicityOriginTypeCuban',
  };

  AssetType = {
    CheckingAccount: 'AssetTypeCheckingAccount',
    SavingsAccount: 'AssetTypeSavingsAccount',
    Stock: 'AssetTypeStock',
    StockOptions: 'AssetTypeStockOptions',
    Bond: 'AssetTypeBond',
    LifeInsurance: 'AssetTypeLifeInsurance',
    RetirementFund: 'AssetTypeRetirementFund',
    NetWorthOfBusinessOwned: 'AssetTypeNetWorthOfBusinessOwned',
    Automobile: 'AssetTypeAutomobile',
    OtherLiquidAssets: 'AssetTypeOtherLiquidAssets',
    OtherNonLiquidAssets: 'AssetTypeOtherNonLiquidAssets',
    BridgeLoanNotDeposited: 'AssetTypeBridgeLoanNotDeposited',
    CertificateOfDepositTimeDeposit: 'AssetTypeCertificateOfDepositTimeDeposit',
    IndividualDevelopmentAccount: 'AssetTypeIndividualDevelopmentAccount',
    MoneyMarketFund: 'AssetTypeMoneyMarketFund',
    MutualFund: 'AssetTypeMutualFund',
    TrustAccount: 'AssetTypeTrustAccount',
    CashOnHand: 'AssetTypeCashOnHand',
    PendingNetSaleProceedsFromRealEstateAssets: 'AssetTypePendingNetSaleProceedsFromRealEstateAssets',
    SaleOtherAssets: 'AssetTypeSaleOtherAssets',
    SecuredBorrowedFundsNotDeposited: 'AssetTypeSecuredBorrowedFundsNotDeposited',
    ProceedsFromSecuredLoan: 'AssetTypeProceedsFromSecuredLoan',
    ProceedsFromUnsecuredLoan: 'AssetTypeProceedsFromUnsecuredLoan',
    CashGift: 'AssetTypeCashGift',
    GiftOfEquity: 'AssetTypeGiftOfEquity',
    Grant: 'AssetTypeGrant',
    ProceedsFromSaleOfNonRealEstateAsset: 'AssetTypeProceedsFromSaleOfNonRealEstateAsset',
    Annuity: 'AssetTypeAnnuity',
    Boat: 'AssetTypeBoat',
    BorrowerEstimatedTotalAssets: 'AssetTypeBorrowerEstimatedTotalAssets',
    BorrowerPrimaryHome: 'AssetTypeBorrowerPrimaryHome',
    EmployerAssistance: 'AssetTypeEmployerAssistance',
    EarnestMoneyCashDepositTowardPurchase: 'AssetTypeEarnestMoneyCashDepositTowardPurchase',
    RealEstateOwned: 'AssetTypeRealEstateOwned',
    RecreationalVehicle: 'AssetTypeRecreationalVehicle',
    SavingsBond: 'AssetTypeSavingsBond',
    SeverancePackage: 'AssetTypeSeverancePackage',
    GiftsNotDeposited: 'AssetTypeGiftsNotDeposited',
    GiftsTotal: 'AssetTypeGiftsTotal',
  };

  ApplicationTakenMethodType = {
    FaceToFace: 'ApplicationTakenMethodTypeFaceToFace',
  };

  ResidencyType = {
    PresentAddress: 'ResidencyTypePresentAddress',
    FormerAddress: 'ResidencyTypeFormerAddress',
    MailingAddress: 'ResidencyTypeMailingAddress',
  };

  LiabilityType = {
    Alimony: 'LiabilityTypeAlimony',
    ChildCare: 'LiabilityTypeChildCare',
    SeparateMaintenanceExpense: 'LiabilityTypeSeparateMaintenanceExpense',
    JobRelatedExpenses: 'LiabilityTypeJobRelatedExpenses',
    Revolving: 'LiabilityTypeRevolving',
    HELOC: 'LiabilityTypeHELOC',
    Installment: 'LiabilityTypeInstallment',
    Taxes: 'LiabilityTypeTaxes',
    CollectionsJudgementsAndLiens: 'LiabilityTypeCollectionsJudgementsAndLiens',
    LeasePayments: 'LiabilityTypeLeasePayments',
    MortgageLoan: 'LiabilityTypeMortgageLoan',
    Open30DayChargeAccount: 'LiabilityTypeOpen30DayChargeAccount',
    OtherLiability: 'LiabilityTypeOtherLiability',
    OtherExpense: 'LiabilityTypeOtherExpense',
    TaxLien: 'LiabilityTypeTaxLien',
    ChildSupport: 'LiabilityTypeChildSupport',
    CarMaintenance: 'LiabilityTypeCarMaintenance',
    CharitableContributions: 'LiabilityTypeCharitableContributions',
    Clothing: 'LiabilityTypeClothing',
    DryCleaning: 'LiabilityTypeDryCleaning',
    Entertainment: 'LiabilityTypeEntertainment',
    GroceryToiletry: 'LiabilityTypeGroceryToiletry',
    HealthInsurance: 'LiabilityTypeHealthInsurance',
    Medical: 'LiabilityTypeMedical',
    MiscellaneousLivingExpenses: 'LiabilityTypeMiscellaneousLivingExpenses',
    NetRentalExpense: 'LiabilityTypeNetRentalExpense',
    PayrollInsuranceDeduction: 'LiabilityTypePayrollInsuranceDeduction',
    PayrollMiscellaneousDeductions:
      'LiabilityTypePayrollMiscellaneousDeductions',
    PayrollProfitSharingDeduction: 'LiabilityTypePayrollProfitSharingDeduction',
    PayrollRetirementDeduction: 'LiabilityTypePayrollRetirementDeduction',
    PayrollTaxDeduction: 'LiabilityTypePayrollTaxDeduction',
    UnionDues: 'LiabilityTypeUnionDues',
  };

  IncomeType = {
    Base: 'IncomeTypeBase',
    Bonus: 'IncomeTypeBonus',
    Commissions: 'IncomeTypeCommissions',
    Overtime: 'IncomeTypeOvertime',
    MilitaryCombatPay: 'IncomeTypeMilitaryCombatPay',
    MilitaryFlightPay: 'IncomeTypeMilitaryFlightPay',
    MilitaryHazardPay: 'IncomeTypeMilitaryHazardPay',
    MilitaryOverseasPay: 'IncomeTypeMilitaryOverseasPay',
    MilitaryPropPay: 'IncomeTypeMilitaryPropPay',
    MilitaryClothesAllowance: 'IncomeTypeMilitaryClothesAllowance',
    MilitaryRationsAllowance: 'IncomeTypeMilitaryRationsAllowance',
    MilitaryVariableHousingAllowance: 'IncomeTypeMilitaryVariableHousingAllowance',
    MilitaryQuartersAllowance: 'IncomeTypeMilitaryQuartersAllowance',
    OtherTypesOfIncome: 'IncomeTypeOtherTypesOfIncome'
  };

  EmploymentType = {
    CurrentEmployer: 'EmploymentTypeCurrentEmployer',
    FormerEmployer: 'EmploymentTypeFormerEmployer',
  };

  PropertyTitleType = {
    CommunityProperty: 'PropertyTitleTypeCommunityProperty',
    Individual: 'PropertyTitleTypeIndividual',
    JointTenancyWithRightOfSurvivorship: 'PropertyTitleTypeJointTenancyWithRightOfSurvivorship',
    LeasedFee: 'PropertyTitleTypeLeasedFee',
    LifeEstate: 'PropertyTitleTypeLifeEstate',
    TenancyByTheEntirety: 'PropertyTitleTypeTenancyByTheEntirety',
    TenancyInCommon: 'PropertyTitleTypeTenancyInCommon',
    ToBeDecidedInEscrow: 'PropertyTitleTypeToBeDecidedInEscrow',
    Other: 'PropertyTitleTypeOther',
  };

  PayoffType = {
    None: 'PayoffTypeNone',
    Partial: 'PayoffTypePartial',
    Full: 'PayoffTypeFull',
  };

  RaceDesignation = {
    AsianIndian: 'RaceDesignationAsianIndian',
    Chinese: 'RaceDesignationChinese',
    Filipino: 'RaceDesignationFilipino',
    GuamanianOrChamorro: 'RaceDesignationGuamanianOrChamorro',
    Japanese: 'RaceDesignationJapanese',
    Korean: 'RaceDesignationKorean',
    NativeHawaiian: 'RaceDesignationNativeHawaiian',
    Samoan: 'RaceDesignationSamoan',
    Vietnamese: 'RaceDesignationVietnamese',
    OtherAsian: 'RaceDesignationOtherAsian',
    OtherPacificIslander: 'RaceDesignationOtherPacificIslander',
  };

  PurchaseCreditType = {
    EarnestMoney: 'PurchaseCreditTypeEarnestMoney',
    DepositOnSalesContract: 'PurchaseCreditTypeDepositOnSalesContract',
    EmployerAssistedHousing: 'PurchaseCreditTypeEmployerAssistedHousing',
    LotEquity: 'PurchaseCreditTypeLotEquity',
    RelocationFunds: 'PurchaseCreditTypeRelocationFunds',
    LeasePurchaseFund: 'PurchaseCreditTypeLeasePurchaseFund',
    TradeEquity: 'PurchaseCreditTypeTradeEquity',
    Other: 'PurchaseCreditTypeOther',
    LenderCredit: 'PurchaseCreditTypeLenderCredit',
    SweatEquity: 'PurchaseCreditTypeSweatEquity',
    SellerCredit: 'PurchaseCreditTypeSellerCredit',
    MIPremiumRefund: 'PurchaseCreditTypeMIPremiumRefund',
  };

  FeePercentOfFieldTypes = {
    LoanAmount: 'LoanAmount',
    TotalLoanAmount: 'TotalLoanAmount',
  };

  MaritalStatusType = {
    Married: 'MaritalStatusTypeMarried',
    Separated: 'MaritalStatusTypeSeparated',
    Single: 'MaritalStatusTypeSingle',
  };

  MilitaryStatusType = {
    isActiveDutyMilitary: 'isActiveDutyMilitary',
    onlyNonActivatedReserveOrNationalGuard:
      'onlyNonActivatedReserveOrNationalGuard',
    isRetiredDischargedSeparatedFromMilitary:
      'isRetiredDischargedSeparatedFromMilitary',
  };

  ProjectType = {
    Condominium: 'ProjectTypeCondominium',
    Cooperative: 'ProjectTypeCooperative',
    PlannedUnitDevelopment: 'ProjectTypePlannedUnitDevelopment',
    NotInAProject: 'ProjectTypeNotInAProject',
  };

  CommunityLendingProductType = {
    HFAPreferred: 'CommunityLendingProductTypeHFAPreferred',
    HFAPreferredRiskSharing:
      'CommunityLendingProductTypeHFAPreferredRiskSharing',
    HomeReady: 'CommunityLendingProductTypeHomeReady',
  };

  CommunitySecondsRepaymentType = {
    DeferredFullyForgiven: 'CommunityLendingProductTypeDeferredFullyForgiven',
    DeferredNotFullyForgiven:
      'CommunityLendingProductTypeDeferredNotFullyForgiven',
    NonDeferred: 'CommunityLendingProductTypeNonDeferred',
  };

  NegativeAmortizationType = {
    NoNegativeAmortization: 'NegativeAmortizationTypeNoNegativeAmortization',
    PotentialNegativeAmortization:
      'NegativeAmortizationTypePotentialNegativeAmortization',
    ScheduledNegativeAmortization:
      'NegativeAmortizationTypeScheduledNegativeAmortization',
  };

  LienPositionType = {
    FirstLien: 'LienPositionTypeFirstLien',
    SecondLien: 'LienPositionTypeSecondLien',
    Other: 'LienPositionTypeOther',
    HELOC: 'LienPositionTypeHELOC',
  };

  ArmIndexSourceType = {
    EleventhDistrictCOF: 'ArmIndexSourceTypeEleventhDistrictCOF',
    ThirtyDayAverageSOFR: 'ArmIndexSourceTypeThirtyDayAverageSOFR',
    DailyCDRate: 'ArmIndexSourceTypeDailyCDRate',
    FannieMae60DayRequiredNetYield:
      'ArmIndexSourceTypeFannieMae60DayRequiredNetYield',
    FannieMaeLIBOR: 'ArmIndexSourceTypeFannieMaeLIBOR',
    FederalCostOfFunds: 'ArmIndexSourceTypeFederalCostOfFunds',
    FreddieMac60DayRequiredNetYield:
      'ArmIndexSourceTypeFreddieMac60DayRequiredNetYield',
    FreddieMacLIBOR: 'ArmIndexSourceTypeFreddieMacLIBOR',
    MonthlyAverageCMT: 'ArmIndexSourceTypeMonthlyAverageCMT',
    NationalAverageContractRate:
      'ArmIndexSourceTypeNationalAverageContractRate',
    NationalMonthlyMedianCostOfFunds:
      'ArmIndexSourceTypeNationalMonthlyMedianCostOfFunds',
    TBillDailyValue: 'ArmIndexSourceTypeTBillDailyValue',
    WallStreetJournalLIBOR: 'ArmIndexSourceTypeWallStreetJournalLIBOR',
    WeeklyAvePrimeRate: 'ArmIndexSourceTypeWeeklyAvePrimeRate',
    WeeklyAverageCDRate: 'ArmIndexSourceTypeWeeklyAverageCDRate',
    WeeklyAverageCMT: 'ArmIndexSourceTypeWeeklyAverageCMT',
    WeeklyFiveYearTreasurySecuritiesConstantMaturityFRBH15:
      'ArmIndexSourceTypeWeeklyFiveYearTreasurySecuritiesConstantMaturityFRBH15',
    WeeklyOneYearTreasurySecuritiesConstantMaturityFRBH15:
      'ArmIndexSourceTypeWeeklyOneYearTreasurySecuritiesConstantMaturityFRBH15',
    WeeklyThreeYearTreasurySecuritiesConstantMaturityFRBH15:
      'ArmIndexSourceTypeWeeklyThreeYearTreasurySecuritiesConstantMaturityFRBH15',
  };

  LandValueType = {
    Appraised: 'LandValueTypeAppraised',
    Original: 'LandValueTypeOriginal',
  };

  PropertyWillBeType = {
    PrimaryResidence: 'PropertyWillBeTypePrimaryResidence',
    SecondaryResidence: 'PropertyWillBeTypeSecondaryResidence',
    Investment: 'PropertyWillBeTypeInvestment',
  };

  PropertyConstructionMethod = {
    Manufactured: 'PropertyConstructionMethodManufactured',
    MobileHome: 'PropertyConstructionMethodMobileHome',
    Modular: 'PropertyConstructionMethodModular',
    OnFrameModular: 'PropertyConstructionMethodOnFrameModular',
    Other: 'PropertyConstructionMethodOther',
    SiteBuilt: 'PropertyConstructionMethodSiteBuilt',
    MHAdvantage: 'PropertyConstructionMethodMHAdvantage',
  };

  LateChargeType = {
    NoLateCharges: 'LateChargeTypeNoLateCharges',
    FlatDollarAmount: 'LateChargeTypeFlatDollarAmount'
  }

  SigningRole = {
    TitleOnly: 'SigningRoleTitleOnly',
    NonTitledSpouse: 'SigningRoleNonTitledSpouse'
  }

  MortgageDocumentSet = {
    Predisclosure: 'MortgageDocumentSetPredisclosure',
    Redisclosure: 'MortgageDocumentSetRedisclosure'
  }
}

export const TIME_ZONES = [
  'Pacific Standard Time',
  'Central Standard Time',
  'Eastern Standard Time',
  'Mountain Standard Time',
  'Alaska Standard Time',
  'Hawaiian Standard Time',
  'Samoa Standard Time',
  'Atlantic Standard Time',
  'Chamorro Standard Time',
  'Wake Island Time Zone'
];

export class Constants {
  public static authorization: Authorization = new Authorization();
  public static federatedAuth: FederatedAuth = new FederatedAuth();
  public static menu: Menu = new Menu();
  public static applicationMenu: ApplicationMenu = new ApplicationMenu();
  public static enumerations: Enumerations = new Enumerations();
  public static commonEnumerations: CommonEnumerations = new CommonEnumerations();
  public static pricingEnumerations: PricingEnumerations =
    new PricingEnumerations();
  public static mortgageEnumerations: MortgageEnumerations =
    new MortgageEnumerations();
  public static mortgageInsuranceEnumerations: MortgageInsuranceEnumerations =
    new MortgageInsuranceEnumerations();
  public static feeEnumerations: FeeEnumerations =
    new FeeEnumerations();
  public static regexPatterns: RegexPatterns = new RegexPatterns();
  public static enumerationValueNames: EnumerationValueNames =
    new EnumerationValueNames();
  public static documentSetEnumerations: DocumentSetEnumerations = new DocumentSetEnumerations();
  public static titleEnumerations: TitleEnumerations = new TitleEnumerations();
  public static modalOptions: ModalOptions = new ModalOptions();
  public static timeZones = TIME_ZONES;
  public static mimeTypes = new MimeTypes();

  public static dirtyCheckIgnoreFieldsMetaDataField: string = "fields_to_ignore_for_dirty_check";

  public static defaultCkEditorConfig = {
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true
        }
      ]
    }
  }

  public static disclosureLoanPlansCacheKey: string = "LoanPlanCache";
}
