<form #form='ngForm' novalidate>
  <!-- Start Date -->
  <div class='qa-form-item'>
    <div class='form-group qa-control-group'>
      <label for='start-date'>Start</label>
      <date-input (ngModelChange)='onChangeStartDate($event)'
                  [(ngModel)]='startDate' [editorMode]='EditorMode.Classic'
                  [max]='maxStartDate' [required]='true' id='start-date'
                  name='startDate'>
      </date-input>
    </div>
  </div>

  <!-- End Date -->
  <div class='qa-form-item'>
    <div class='form-group qa-control-group'>
      <label for='end-date'>End</label>
      <date-input (ngModelChange)='onChangeEndDate($event)'
                  [(ngModel)]='endDate' [max]='maxEndDate' [min]='minEndDate'
                  [required]='true' id='end-date' name='endDate'>
      </date-input>
    </div>
  </div>
</form>

<div class='qa-footer'>
  <div class='d-flex justify-content-end gap-2'>
    <qa-cancel-button (qaClick)='onCancel()'></qa-cancel-button>

    <qa-ok-button (qaClick)='onSave()'></qa-ok-button>
  </div>
</div>
