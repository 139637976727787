<form #propertyLoanInfoForm="ngForm">
  <div *ngIf='mortgage.mortgageId != null' class='grid-layout has-border'>
    <div class="grid-header" *ngIf="!dhHiddenHack">
      <div class="grid-header-left">
        Community Property State
      </div>

      <div class="grid-header-left">
        Transaction Detail
      </div>
    </div>

    <div class="grid-layout-row" *ngIf="!dhHiddenHack">
      <urla-boolean-checkbox [(ngModel)]='mortgage.subjectProperty.propertyIsInCommunityPropertyState'
        [disabled]='isReadOnly || inEditMode' class='col-sm-6 col-xs-6'
        label='The property is in a community property state' layout='single'
        name='propertyIsInCommunityPropertyState'></urla-boolean-checkbox>

      <div class="col-sm-6 col-xs-6 grid-layout-text">
        <div class="grid-cell-tag">
          <div [ngClass]="{'bg-transparent': isReadOnly}" class='form-input'>
            <div class='cell-content'>
              <span>
                <urla-boolean-checkbox
                  [(ngModel)]='mortgage.subjectProperty.isConversionOfContractForDeedOrLandContract'
                  [disabled]='isReadOnly || inEditMode' label='Conversion of Contract for Deed or Land Contract'
                  name='isConversionOfContractForDeedOrLandContract'></urla-boolean-checkbox>

                <urla-boolean-checkbox [(ngModel)]='mortgage.subjectProperty.isRenovation'
                  [disabled]='isReadOnly || inEditMode' label='Renovation' name='isRenovation'></urla-boolean-checkbox>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-layout-row">
      <urla-dropdown [(ngModel)]='mortgage.subjectProperty.refinanceProgram' [inEditMode]='inEditMode'
        [options]='governmentRefinanceTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.refinanceProgram")' [urlaFieldsConfig]='urlaFieldsConfig'
        [ngClass]="{ 'col-12 col-lg-6': mortgage.subjectProperty.refinanceProgram != 'Other',
                      'col-12 col-lg-3': mortgage.subjectProperty.refinanceProgram === 'Other'}"
        fieldBeingEdited='mortgage.subjectProperty.refinanceProgram' label='Documentation Type'
        name='governmentRefinanceType'></urla-dropdown>

        <urla-text-input *ngIf="mortgage.subjectProperty.refinanceProgram === 'Other'"
        [(ngModel)]='mortgage.subjectProperty.refinanceProgramOther' [inEditMode]='inEditMode'
        [readonly]='isReadOnly' [required]='isRequired("mortgage.subjectProperty.refinanceProgramOther")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-12 col-lg-3'
        fieldBeingEdited='mortgage.subjectProperty.refinanceProgramOther'
        label='Documentation Type Other Descr.' name='refinanceProgramOther'></urla-text-input>


      <urla-dropdown [(ngModel)]='mortgage.subjectProperty.attachmentType' [inEditMode]='inEditMode'
        [options]='propertyAttachmentTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.attachmentType")' [urlaFieldsConfig]='urlaFieldsConfig'
        class="col-12 col-lg-{{isLoanPurposeConstructionToPerm ? '3' : '6'}}"
        fieldBeingEdited='mortgage.subjectProperty.attachmentType' label='Attachment Type'
        name='propertyAttachmentType'></urla-dropdown>

      <urla-dropdown *ngIf='isLoanPurposeConstructionToPerm'
        [(ngModel)]='mortgage.subjectProperty.constructionToPermanentClosingType' [inEditMode]='inEditMode'
        [options]='constructionToPermanentClosingTypes' [readonly]='isReadOnly'
        [required]='isLoanPurposeConstructionToPerm && isRequired("mortgage.subjectProperty.constructionToPermanentClosingType")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-12 col-lg-3'
        fieldBeingEdited='mortgage.subjectProperty.constructionToPermanentClosingType' label='Closing Type'
        name='constructionToPermanentClosingType'></urla-dropdown>
    </div>

    <div class="grid-layout-row">

      <urla-dropdown [(ngModel)]='mortgage.mortgageTerm.lienPosition' [inEditMode]='inEditMode'
        [options]='lienPositionTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.mortgageTerm.lienPosition")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-6' fieldBeingEdited='mortgage.mortgageTerm.lienPosition' label='Lien Position Type'
        name='lienPosition'></urla-dropdown>

      <urla-text-input *ngIf="mortgage.mortgageTerm.lienPosition == 'Other'"
        [(ngModel)]='mortgage.mortgageTerm.lienPositionOtherDescription' [inEditMode]='inEditMode'
        [readonly]='isReadOnly' [required]='isRequired("mortgage.mortgageTerm.lienPositionOtherDescription")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
        fieldBeingEdited='mortgage.mortgageTerm.lienPositionOtherDescription' label='Lien Position Other Desc.'
        name='lienPositionOtherDescription'></urla-text-input>

      <div *ngIf="mortgage.mortgageTerm.lienPosition != 'Other' && !inEditMode"
        class='grid-layout-spacer col-sm-3 col-xs-0'></div>

      <urla-dropdown (ngModelChange)='onCurrentUsageTypeChanged()'
        [(ngModel)]='mortgage.subjectProperty.currentUsageType' [inEditMode]='inEditMode'
        [options]='currentPropertyWillBeTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.currentUsageType")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-6' fieldBeingEdited='mortgage.subjectProperty.currentUsageType'
        label='Current Usage Type' name='currentUsageType'></urla-dropdown>

      <urla-text-input *ngIf="mortgage.subjectProperty.currentUsageType == 'Other'"
        [(ngModel)]='mortgage.subjectProperty.currentUsageTypeOtherDescription' [inEditMode]='inEditMode'
        [readonly]='isReadOnly' [required]='isRequired("mortgage.subjectProperty.currentUsageTypeOtherDescription")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
        fieldBeingEdited='mortgage.subjectProperty.currentUsageTypeOtherDescription'
        label='Current Usage Type Other Descr.' name='currentUsageTypeOtherDescription'></urla-text-input>

      <div *ngIf="mortgage.subjectProperty.currentUsageType != 'Other' && !inEditMode"
        class='grid-layout-spacer col-sm-3 col-xs-0'></div>

    </div>

    <div class="grid-layout-row">

      <urla-dropdown [(ngModel)]="mortgage.subjectProperty.isPlannedUnitDevelopment"
        [label]="'Is Planned Unit Dev. (PUD)?'" [options]="yesNoOptions" [unselectedValue]="null"
        name="isPlannedUnitDevelopmentYesNo" class='col-6 col-sm-3' [disabled]="isReadOnly || inEditMode"
        [required]='isRequired("mortgage.subjectProperty.isPlannedUnitDevelopment")'
        [urlaFieldsConfig]='urlaFieldsConfig' fieldBeingEdited='mortgage.subjectProperty.isPlannedUnitDevelopment'>
      </urla-dropdown>

      <urla-dropdown [(ngModel)]="isPropertyInAProject" (ngModelChange)='onPropertyIsInAProjectChange()'
        [label]="'Is Property in a Project?'" [options]="yesNoOptions" [unselectedValue]="null" class='col-6 col-sm-3'
        name="notInAProjectYesNo" [disabled]="isReadOnly || inEditMode"
        [required]='isRequired("mortgage.subjectProperty.isPropertyNotInAProject")'
        [urlaFieldsConfig]='urlaFieldsConfig' fieldBeingEdited='mortgage.subjectProperty.isPropertyNotInAProject'>
      </urla-dropdown>

      <urla-dropdown *ngIf='isPropertyInAProject === true || inEditMode'
        [(ngModel)]='mortgage.subjectProperty.projectType' (ngModelChange)='onProjectTypeChanged($event)'
        [inEditMode]='inEditMode' [options]='projectTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.projectType")' [urlaFieldsConfig]='urlaFieldsConfig'
        fieldBeingEdited='mortgage.subjectProperty.projectType' class='col-6 col-sm-3' label='Project Type'
        name='projectType'></urla-dropdown>

      <div *ngIf='isPropertyInAProject !== true && !inEditMode' class='grid-layout-spacer col-6 col-sm-3'></div>

      <urla-dropdown *ngIf='isProjectTypeCondominium || inEditMode'
        [(ngModel)]='mortgage.subjectProperty.projectDesignType' [inEditMode]='inEditMode'
        [options]='projectDesignTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.projectDesignType")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-sm-3' fieldBeingEdited='mortgage.subjectProperty.projectDesignType' label='Project Design Type'
        name='projectDesignType'></urla-dropdown>

      <div *ngIf='!isProjectTypeCondominium && !inEditMode' class='grid-layout-spacer col-6 col-sm-3'></div>
    </div>

    <div class="grid-layout-row">
      <urla-text-input *ngIf='mortgage.subjectProperty.projectType || mortgage.subjectProperty.isPlannedUnitDevelopment'
        [(ngModel)]='mortgage.subjectProperty.projectName' [inEditMode]='inEditMode' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.projectName")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-6' fieldBeingEdited='mortgage.subjectProperty.projectName' label='Project / PUD Name'
        name='projectName'></urla-text-input>

      <urla-text-input *ngIf='mortgage.subjectProperty.projectType'
        [(ngModel)]='mortgage.subjectProperty.projectClassificationIdentifier' [inEditMode]='inEditMode'
        [readonly]='isReadOnly' [required]='isRequired("mortgage.subjectProperty.projectClassificationIdentifier")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
        fieldBeingEdited='mortgage.subjectProperty.projectClassificationIdentifier' label='Project Classification ID'
        name='projectClassificationIdentifier'></urla-text-input>

      <div *ngIf='!mortgage.subjectProperty.projectType' class="grid-layout-spacer col-sm-3 col-xs-6"></div>

      <urla-dropdown *ngIf='mortgage.subjectProperty.projectType'
        [(ngModel)]='mortgage.subjectProperty.condominiumProjectStatus' [inEditMode]='inEditMode'
        [options]='condominiumProjectStatusTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.condominiumProjectStatus")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
        fieldBeingEdited='mortgage.subjectProperty.condominiumProjectStatus' label='Condominium Project Status'
        name='condominiumProjectStatus'></urla-dropdown>

      <div *ngIf='!mortgage.subjectProperty.projectType' class="grid-layout-spacer col-sm-3 col-xs-6"></div>

      <urla-text-input *ngIf='mortgage.subjectProperty.projectType'
        [(ngModel)]='mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier' [inEditMode]='inEditMode'
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
        fieldBeingEdited='mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier'
        label='FNM Condo. Prj. Manager Prj. ID' name='fnmCondominiumProjectManagerProjectIdentifier'></urla-text-input>

      <div *ngIf='!mortgage.subjectProperty.projectType' class="grid-layout-spacer col-sm-3 col-xs-6"></div>
    </div>

    <div class="grid-layout-row" *ngIf="!dhHiddenHack">

      <urla-digits-input [(ngModel)]='mortgage.mortgageTerm.loanTermMaximumMonths' [allowedNegative]='false'
        [inEditMode]='inEditMode' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.mortgageTerm.loanTermMaximumMonths")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-6' fieldBeingEdited='mortgage.mortgageTerm.loanTermMaximumMonths'
        label='MAX LOAN TERM (MOS)' name='loanTermMaximumMonths'></urla-digits-input>

      <urla-dropdown [(ngModel)]='mortgage.mortgageTerm.paymentFrequency' [inEditMode]='inEditMode'
        [options]='mortgagePaymentFrequencyTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.mortgageTerm.paymentFrequency")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-6' fieldBeingEdited='mortgage.mortgageTerm.paymentFrequency' label='Payment Frequency'
        name='paymentFrequency' [hidden]="dhHiddenHack"></urla-dropdown>
        
        <div *ngIf="dhHiddenHack" class="grid-layout-spacer col-sm-3 col-xs-6"></div>

      <urla-dropdown [(ngModel)]='mortgage.mortgageTerm.partialPaymentApplicationMethodType'
        [fieldBeingEdited]="'mortgage.mortgageTerm.partialPaymentApplicationMethodType'" [inEditMode]='inEditMode'
        [options]='partialPaymentApplicationMethodTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.mortgageTerm.partialPaymentApplicationMethodType")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6' label='Partial Payment App. Method Type'
        name='partialPaymentApplicationMethodType'>
      </urla-dropdown>
      <div class="grid-layout-spacer col-sm-3 col-xs-6"></div>
    </div>

    <div class="grid-layout-row" *ngIf="!dhHiddenHack">
      <urla-currency-input [(ngModel)]='mortgage.mortgageTerm.borrowerRequestedLoanAmount' [inEditMode]='inEditMode'
        [readonly]='isReadOnly' [required]='isRequired("mortgage.mortgageTerm.borrowerRequestedLoanAmount")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-md-3 col-xs-6'
        fieldBeingEdited='mortgage.mortgageTerm.borrowerRequestedLoanAmount' label='Borrower Requested Loan Amount'
        name='borrowerRequestedLoanAmount'></urla-currency-input>

      <urla-percentage-input [(ngModel)]='mortgage.mortgageTerm.borrowerRequestedInterestRate' [inEditMode]='inEditMode'
        [readonly]='isReadOnly' [required]='isRequired("mortgage.mortgageTerm.borrowerRequestedInterestRate")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-md-3 col-xs-6'
        fieldBeingEdited='mortgage.mortgageTerm.borrowerRequestedInterestRate' label='Borrower Requested Interest Rate'
        name='borrowerRequestedInterestRate'></urla-percentage-input>

        <div class="grid-layout-spacer col-sm-6 col-xs-0"></div>

    </div>

    <div class="grid-layout-row">

      <urla-dropdown [(ngModel)]='mortgage.subjectProperty.currentOccupancyType' [inEditMode]='inEditMode'
        [options]='propertyOccupancyTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.currentOccupancyType")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-6' fieldBeingEdited='mortgage.subjectProperty.currentOccupancyType'
        label='Current Occupancy Type' name='currentOccupancyType'></urla-dropdown>


      <!-- duplicate?? <urla-text-input [(ngModel)]='mortgage.subjectProperty.condominiumOrPudDeclaration' [inEditMode]='inEditMode'
      [readonly]='isReadOnly' [required]='isRequired("mortgage.subjectProperty.condominiumOrPudDeclaration")'
      [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
      fieldBeingEdited='mortgage.subjectProperty.condominiumOrPudDeclaration' label='Condominium/PUD Declaration'
      name='condominiumOrPudDeclaration'></urla-text-input>
 -->

      <urla-dropdown [(ngModel)]='mortgage.subjectProperty.currentOwnerType' [inEditMode]='inEditMode'
        [options]='unitOwnedByTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.currentOwnerType")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-6' fieldBeingEdited='mortgage.subjectProperty.currentOwnerType'
        label='Current Owner Type' name='currentOwnerType'></urla-dropdown>

      <urla-text-input [(ngModel)]='mortgage.subjectProperty.currentOccupantName' [inEditMode]='inEditMode'
        [readonly]='isReadOnly' [required]='isRequired("mortgage.subjectProperty.currentOccupantName")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
        fieldBeingEdited='mortgage.subjectProperty.currentOccupantName' label='Current Occupant Name'
        name='currentOccupantName'></urla-text-input>

      <div class="grid-layout-spacer col-sm-3 col-xs-6"></div>

    </div>

    <div class="grid-layout-row">

      <urla-digits-input [(ngModel)]='mortgage.subjectProperty.livingAreaSquareFeet' [allowedNegative]='false'
        [inEditMode]='inEditMode' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.livingAreaSquareFeet")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-6' fieldBeingEdited='mortgage.subjectProperty.livingAreaSquareFeet'
        label='Living Area Square Feet' name='livingAreaSquareFeet'></urla-digits-input>

      <urla-digits-input [(ngModel)]='mortgage.subjectProperty.bathroomCount' [allowedNegative]='false'
        [inEditMode]='inEditMode' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.bathroomCount")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-6' fieldBeingEdited='mortgage.subjectProperty.bathroomCount' label='Bathroom Count'
        name='bathroomCount'></urla-digits-input>

      <urla-digits-input [(ngModel)]='mortgage.subjectProperty.bedroomCount' [allowedNegative]='false'
        [inEditMode]='inEditMode' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.bedroomCount")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-6' fieldBeingEdited='mortgage.subjectProperty.bedroomCount' label='Bedroom Count'
        name='bedroomCount'></urla-digits-input>

      <urla-digits-input [(ngModel)]='mortgage.subjectProperty.livableRoomCount' [allowedNegative]='false'
        [inEditMode]='inEditMode' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.subjectProperty.livableRoomCount")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3 col-xs-6' fieldBeingEdited='mortgage.subjectProperty.livableRoomCount'
        label='Livable Room Count' name='livableRoomCount'></urla-digits-input>

    </div>

    <div class="grid-layout-row" *ngIf="!dhHiddenHack">
      <urla-dropdown [(ngModel)]='mortgage.mortgageTerm.loanUnderwritingSubmitterType' [inEditMode]='inEditMode'
        [options]='loanUnderwritingSubmitterTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.mortgageTerm.loanUnderwritingSubmitterType")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
        fieldBeingEdited='mortgage.mortgageTerm.loanUnderwritingSubmitterType' label='Loan Underwriting Submitter Type'
        name='loanUnderwritingSubmitterType'></urla-dropdown>

      <urla-text-input *ngIf="mortgage.mortgageTerm.loanUnderwritingSubmitterType == 'Other'"
        [(ngModel)]='mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription' [inEditMode]='inEditMode'
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
        fieldBeingEdited='mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription'
        label='Loan Underwriting Other Desc.' name='loanUnderwritingSubmitterTypeOtherDescription'></urla-text-input>

      <div *ngIf="mortgage.mortgageTerm.loanUnderwritingSubmitterType !== 'Other' && !inEditMode"
        class="grid-layout-spacer col-sm-3 col-xs-6">
        &nbsp;</div>

      <div class="grid-layout-spacer col-sm-6 col-xs-0"></div>
    </div>

    <div class="grid-layout-row" *ngIf="!dhHiddenHack">

      <urla-dropdown (ngModelChange)='onCreditorServicingOfLoanStatementTypeChanged()'
        [(ngModel)]='mortgage.mortgageTerm.creditorServicingOfLoanStatementType' [inEditMode]='inEditMode'
        [options]='creditorServicingOfLoanStatementTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.mortgageTerm.creditorServicingOfLoanStatementType")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
        fieldBeingEdited='mortgage.mortgageTerm.creditorServicingOfLoanStatementType' label='Intent To Service'
        name='creditorServicingOfLoanStatementType'></urla-dropdown>

      <urla-text-input *ngIf="mortgage.mortgageTerm.creditorServicingOfLoanStatementType == 'Other'"
        [(ngModel)]='mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription'
        [inEditMode]='inEditMode' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
        fieldBeingEdited='mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription'
        label='Intent To Service Descr.' name='creditorServicingOfLoanStatementTypeOtherDescription'></urla-text-input>

      <div *ngIf="mortgage.mortgageTerm.creditorServicingOfLoanStatementType != 'Other' && !inEditMode"
        class='grid-layout-spacer col-sm-3 col-xs-6'></div>
      <div class="grid-layout-spacer col-sm-3 col-xs-6"></div>

      <urla-text-input [(ngModel)]='mortgage.subjectProperty.msaIdentifier' [inEditMode]='inEditMode'
        [readonly]='isReadOnly' [required]='isRequired("mortgage.subjectProperty.msaIdentifier")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-3 col-xs-6'
        fieldBeingEdited='mortgage.subjectProperty.msaIdentifier' label='MSA ID' name='msaIdentifier'></urla-text-input>
    </div>

    <!-- Construction -->
    <div *ngIf='isConstructionLikePurpose' class='grid-header'>
      <div class='grid-header-left'>
        Construction
      </div>

      <div class='grid-header-right'>
        <!-- Just a spacer to make also the right side gray -->
      </div>

      <div class='grid-layout-row'>
        <urla-currency-input [(ngModel)]='mortgage.subjectProperty.improvementCost' [inEditMode]='inEditMode'
          [readonly]='isReadOnly' [required]='isRequired("mortgage.subjectProperty.improvementCost")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-lg-3'
          fieldBeingEdited='mortgage.subjectProperty.improvementCost' label='Construction Improvement Cost'
          name='improvementCost'></urla-currency-input>

        <urla-date-input [(ngModel)]='mortgage.subjectProperty.lotAcquiredDate' [inEditMode]='inEditMode'
          [readonly]='isReadOnly' [required]='isRequired("mortgage.subjectProperty.lotAcquiredDate")'
          [urlaFieldsConfig]='urlaFieldsConfig' class='col-6 col-lg-3'
          fieldBeingEdited='mortgage.subjectProperty.lotAcquiredDate' label='Lot Acquired'
          name='lotAcquiredDate'></urla-date-input>

        <urla-currency-input (ngModelChange)='landValueChange()' [(ngModel)]='mortgage.subjectProperty.lotOriginalCost'
          [inEditMode]='inEditMode' [readonly]='isReadOnly'
          [required]='isRequired("mortgage.subjectProperty.lotOriginalCost")' [urlaFieldsConfig]='urlaFieldsConfig'
          class='col-6 col-lg-3' fieldBeingEdited='mortgage.subjectProperty.lotOriginalCost' label='Lot Original Cost'
          name='lotOriginalCost'></urla-currency-input>

        <urla-currency-input (ngModelChange)='landValueChange()'
          [(ngModel)]='mortgage.subjectProperty.lotAppraisedValue' [inEditMode]='inEditMode' [readonly]='isReadOnly'
          [required]='isRequired("mortgage.subjectProperty.lotAppraisedValue")' [urlaFieldsConfig]='urlaFieldsConfig'
          class='col-6 col-lg-3' fieldBeingEdited='mortgage.subjectProperty.lotAppraisedValue'
          label='Lot Appraised Value' name='lotAcquireValue'></urla-currency-input>
      </div>
    </div>
  </div>
</form>

<loading-indicator *ngIf='mortgage.mortgageId == null'
  loadingMessage='Loading loan & property info...'></loading-indicator>