<div class="modal-header">
  <h5 class="modal-title">Custom Fields</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <form #loanPassCustomFieldsForm="ngForm" novalidate id="loanPassCustomFieldsForm">
    <loading-indicator *ngIf="loadingCustomFields" [loadingMessage]="'Loading Custom Fields...'"
      [customClass]="'no-shadow'"></loading-indicator>
    <ng-container *ngIf="!loadingCustomFields">
      <div class="row mb-4">
        <div class="col-md-12">
          <button class="btn btn-outline-primary" (click)="addCustomField()">
            Add Custom Field
          </button>
        </div>
      </div>
      <pricing-custom-fields [customFields]="customFields"></pricing-custom-fields>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="onSaveClicked()">
    Save
  </button>
</div>
