import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { Company, LoanApplication, Profile } from 'src/app/models';
import { FeeSectionEnum } from 'src/app/models/fee/fee-section.enum';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import {
  SendEmailSmsDialogComponent,
} from 'src/app/modules/correspondence/components/send-email-sms-dialog/send-email-sms-dialog.component';
import {
  InternalContactsService,
} from 'src/app/modules/internal-contacts/services/internal-contacts.service';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { FileAttachment } from 'src/app/shared/components/send-mms/send-mms.component';
import { PricingScenario } from '../../models/pricing/pricing-scenario.model';
import { MortgageService } from '../../../../services/mortgage.service';
import {
  MortgageCalculationService,
} from 'src/app/modules/urla/services/mortgage-calculation.service';
import {
  MortgageFundsToCloseCalculationDetails,
} from 'src/app/models/mortgage-calculation-details.model';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { UtilityService } from '../../../urla/services/utility.service';
import { uniqBy } from 'lodash';
import { InitialFeesWorksheetEmail } from '../initial-fees-worksheet-email/initial-fees-worksheet-email.component';

@Component({
  selector: 'initial-fees-worksheet-dialog',
  templateUrl: 'initial-fees-worksheet-dialog.component.html',
  styleUrls: ['./initial-fees-worksheet-dialog.component.scss']
})
export class InitialFeesWorksheetDialog extends ApplicationContextBoundComponent implements OnInit, AfterViewInit {

  @ViewChild(InitialFeesWorksheetEmail) email: InitialFeesWorksheetEmail;

  @Input()
  scenario: PricingScenario;

  @Input()
  set fees(fees: LoanFee[]) {
    this._fees = fees;
    const filterByHud = filterFeesByHud(fees);
    const filterOutSummaryFees = (fee: LoanFee) => !fee.isSummaryFee;

    this.prepaids = fees.filter(f => f.feeSection == FeeSectionEnum.Prepaids && !f.isSummaryFee);
    this.escrow = fees.filter(f => f.feeSection == FeeSectionEnum.Escrow && !f.isSummaryFee);
    this.governmentTaxesAndFees = fees.filter(f => f.feeSection == FeeSectionEnum.GovernmentTaxesAndFees && !f.isSummaryFee);
    this.originationFees = filterByHud(/^80[12]/).filter(filterOutSummaryFees);
    const services = fees.filter(f => f.feeSection == FeeSectionEnum.Services && !f.isSummaryFee);
    const servicesNoShop = fees.filter(f => f.feeSection == FeeSectionEnum.ServicesNoShop && !f.isSummaryFee);
    const other = fees.filter(f => f.feeSection == FeeSectionEnum.Other && !f.isSummaryFee);

    // FIXME: Investigate if fees filtered by HUD and other fees may overlap. `uniqBy` is used to
    //        prevent possible duplicates for now. Remove it if it's not necessary.
    this.otherFees = uniqBy([
      ...services,
      ...servicesNoShop,
      ...other,
      ...filterByHud(/^(?:80[03-9]|8[1-9]\d)/), // 8xx except 801 and 802
    ], 'loanFeeId').filter(filterOutSummaryFees);
  }

  private _fees: LoanFee[] = [];

  get fees(): LoanFee[] {
    return this._fees;
  }

  @Input()
  appId: number;

  noOfUnits: string;

  totalApproxMonthlyPayment: number;

  mortgage: UrlaMortgage;

  application: LoanApplication;

  ftcDetails: MortgageFundsToCloseCalculationDetails;

  prepaids: LoanFee[] = [];

  escrow: LoanFee[] = [];

  governmentTaxesAndFees: LoanFee[] = [];

  otherFees: LoanFee[] = [];

  originationFees: LoanFee[] = [];

  improvementsRenovationsAndRepairs: number = 0;

  totalPrepaidsAndEscrow: number;

  nonFinancedPmiMipFundingFee: number;

  totalOtherAndGovernmentFees: number;

  totalOriginationFees: number;

  totalOtherFees: number;

  totalFundsDueFromBorr: number;

  totalSubordinateLiens: number;

  downpaymentFunds: number;

  estimatedCashFromBorr: number;

  totalCreditsApplied: number;

  estimatedTotalPayOffs: number;
  refiMortgagePayoffs: number;

  usersAll: User[] = [];
  user: User;

  loggedInUser: Profile;
  loanPurpose: string;
  propertyType: string;
  purchasePrice: number;
  appraisedValue: number;
  rate: number;
  apr: number;
  fico: number;
  productName: string;
  occupancy: string;
  zipCode: string;
  state: string;
  loanAmount: number;
  totalLoanAmount: number;
  waiveEscrows: boolean;
  lockTerm: number;
  userImage: string;
  sellerCredits: number;
  emd: number;
  lenderCredits: number;
  otherCredits: number;
  licenseNo: string;
  companyUrl: string = "";
  currentDate: Date;
  updatedDate: Date;

  protected get userCellPhone(): string | undefined {
    return this.user?.workPhone || this.user?.cellPhone;
  }

  protected get loggedInUserCellPhone(): string | undefined {
    return this.loggedInUser?.userProfile.cellPhone;
  }

  protected get userEmail(): string | undefined {
    return this.user?.email
  }

  protected get loggedInUserEmail(): string | undefined {
    return this.loggedInUser?.userProfile.email;
  }

  protected forBorrowers?: string[];

  inEmail: boolean = false;
  isFailed: boolean = false;
  isRefinance: boolean;

  imageProfile: SafeResourceUrl = 'assets/images/male.png';
  imageLogo: SafeResourceUrl = 'assets/images/loda-logo.png';
  imageApplyNow: SafeResourceUrl;

  defaultProfileImage: SafeResourceUrl;
  defaultCompanyLogo: SafeResourceUrl;

  originationFeesHeight;
  taxesAndOtherGovernmentFeesFeesHeight;
  thirdPartyFeesHeight;
  prepaidAndInitialHeight;

  printConfig: any;
  apiBaseUrl: string;

  protected isPDFButtonEnabled: boolean = false;

  protected entityName: string = '-';
  protected entityNmls: string = '-';

  private _branchLogoUrl: string;
  private _companyLogoUrl: string;

  private _tryingCompanyLogoUrl: boolean = false;
  private _tryingBranchLogoUrl: boolean = false;

  constructor(
    injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _enumsService: EnumerationService,
    private readonly _modalService: NgbModal,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _environment: EnvironmentService,
    private readonly elementRefService: ElementRef,
    private readonly _httpClient: HttpClient,
    private readonly _loanDocService: LoanDocService,
    private readonly _notificationService: NotificationService,
    private readonly _mortgageService: MortgageService,
    private readonly _mortgageCalculationsService: MortgageCalculationService,
    private readonly _currencyPipe: CurrencyPipe,
    private readonly _percentPipe: PercentPipe,
    private readonly _utilityService: UtilityService,
  ) {
    super(injector);
    this.apiBaseUrl = this._environment.apiInfo.apiBaseUrl;
    this.defaultProfileImage = `${window.location.origin}/assets/images/male.png`;
    this.imageApplyNow = `${window.location.origin}/assets/images/users/user-5.jpg`;
    this.defaultCompanyLogo = `${window.location.origin}/assets/images/loda-logo.png`;
  }

  ngOnInit() {
    this.originationFees = this.originationFees.filter(fee => !!fee.calculatedValues?.totalFee);
    this.governmentTaxesAndFees = this.governmentTaxesAndFees.filter(fee => !!fee.calculatedValues?.borrowerTotal);
    this.otherFees = this.otherFees.filter(fee => !!fee.calculatedValues?.borrowerTotal);
    this.prepaids = this.prepaids.filter(fee => !!fee.calculatedValues?.borrowerTotal);
    this.escrow = this.escrow.filter(fee => !!fee.calculatedValues?.borrowerTotal);

    this.currentDate = new Date();
    this.applicationContextService.context.subscribe(context => {
      const company = context.globalConfig.company.find(c => c.companyId == context.userPermissions.companyId);
      this._companyLogoUrl = `${this.apiBaseUrl}company/co/logo/${company.companyId}`;
      this.entityName = company.companyName;
      this.entityNmls = company.nmls;
      this.mortgage = context.currentMortgage;
      this.ftcDetails = context.currentMortgageCalculationDetails?.fundsToClose;
      this.application = context.application;
      this.usersAll = context.globalConfig.usersAll;
      this.loggedInUser = context.currentlyLoggedInUserProfile;

      this.improvementsRenovationsAndRepairs = this.mortgage?.transactionDetail?.alterationsImprovementsAndRepairsAmount ?? 0;

      const borrowers = context.borrowers?.map(this._utilityService.getBorrowerFullName);
      if (borrowers.length > 0) {
        this.forBorrowers = borrowers;
      }

      let branch = null;
      if (this.application.branchId) {
        branch = context.globalConfig.branches.find(b => b.branchId == this.application.branchId);
        if (branch) {
          this._branchLogoUrl = `${this.apiBaseUrl}branch/co/logo/${branch.branchId}`;
          this._tryingBranchLogoUrl = true;
          this.imageLogo = this._branchLogoUrl;
          this.entityName = branch.branchName || this.entityName;
          this.entityNmls = branch.nmls || this.entityNmls;
        }
      } else {
        this._tryingCompanyLogoUrl = true;
        this.imageLogo = this._companyLogoUrl;
      }

      const urlsToParse = company.borrowerURL || branch?.branchURL || company.companyURL;
      if (urlsToParse) {
        const urls = urlsToParse.split('|');
        if (urls && urls.length) {
          this.companyUrl = urls[0];
        }
      }

      if (this.appId) {
        this._spinnerService.show();
        this._internalContactsService.getInternalContacts(this.appId).subscribe(result => {
          const existOne = result.find(u => u.roleId == context.globalConfig.firstRole.roleId);
          if (existOne) {
            this.user = this.usersAll.find(u => u.userCompanyGuid === existOne.userId);
            if (this.user && this.user.userImage) {
              this.imageProfile = this.user.userImage;
            }
          }
        }).add(() => {
          this._spinnerService.hide();
        });
      } else {
        this.imageProfile = this.loggedInUser.userImage;
      }
      const states = Object.keys(context.globalConfig.states).map(
        (key) => new EnumerationItem(context.globalConfig.states[key], key)
      );
      if (!this.scenario) {
        this.isRefinance = this._mortgageCalculationsService.isPurposeOfLoanRefinance(this.mortgage);

        const state = states.find(s => s.value == context.currentMortgage.subjectProperty.state);
        if (state) {
          this.state = state.name;
        }

        if (this.isRefinance) {
          this.downpaymentFunds = this.application?.loanAmount;
        } else {
          this.downpaymentFunds = this._mortgageService.calculateDownPayment(this.mortgage);
        }
      } else {
        const state = states.find(s => s.value == this.scenario.pricingRequestPayload.propertyInformation.state);
        if (state) {
          this.state = state.name;
        }

        const pricingRequest = this.scenario.pricingRequestPayload;
        this.isRefinance = pricingRequest.loanInformation.loanPurpose === "Refinance";
        if (this.isRefinance) {
          this.downpaymentFunds = pricingRequest.loanInformation.baseLoanAmount;
        } else {
          this.downpaymentFunds = pricingRequest.propertyInformation.salesPrice - pricingRequest.loanInformation.baseLoanAmount;
        }
      }
    });

    if (this.mortgage) {
      this.estimatedTotalPayOffs = this.ftcDetails?.debtsPaidOff_E || 0;
      if (this.isRefinance) {
        this.refiMortgagePayoffs = this.ftcDetails?.totalPaidOffForRefinance_D || 0;
      }
    }

    // Coming from the FTC - there is no scenario
    if (!this.scenario) {
      this.setFieldValuesFromLoan();
    } else {
      this.setFieldValuesFromScenario();
    }
  }

  ngAfterViewInit(): void {
    this.originationFeesHeight = document.getElementById('originationFeesCard').offsetHeight;
    this.taxesAndOtherGovernmentFeesFeesHeight = document.getElementById('originationFeesCard').offsetHeight;
    this.thirdPartyFeesHeight = document.getElementById('thirdPartyFeesCard').offsetHeight;
    this.prepaidAndInitialHeight = document.getElementById('prepaidAndInitialCard').offsetHeight;

    if (this.originationFeesHeight < this.taxesAndOtherGovernmentFeesFeesHeight) {
      if (this.taxesAndOtherGovernmentFeesFeesHeight < 375) {
        this.originationFeesHeight = this.taxesAndOtherGovernmentFeesFeesHeight;
      } else {
        this.originationFeesHeight = 375;
      }
    } else {
      if (this.originationFeesHeight < 375) {
        this.taxesAndOtherGovernmentFeesFeesHeight = this.originationFeesHeight;
      } else {
        this.taxesAndOtherGovernmentFeesFeesHeight = 375;
      }
    }

    if (this.thirdPartyFeesHeight < this.prepaidAndInitialHeight) {
      if (this.prepaidAndInitialHeight < 375) {
        this.thirdPartyFeesHeight = this.prepaidAndInitialHeight;
      } else {
        this.thirdPartyFeesHeight = 375;
      }
    } else {
      if (this.thirdPartyFeesHeight < 375) {
        this.prepaidAndInitialHeight = this.thirdPartyFeesHeight;
      } else {
        this.prepaidAndInitialHeight = 375;
      }
    }

    this.printConfig = [
      'sectionConvertedToPDF', {
        printMode: 'template-popup',
        pageTitle: 'Fee Worksheet',
        styles: [
          'img { margin-right: 10px }',
          'hr { display:none; }',
          '.cards-section .card { box-shadow: none; border-radius: 0;height: auto !important;}',
          '.row { --bs-gutter-x: 1rem;--bs-gutter-y: 0;display: flex;flex-wrap: wrap; font-size: xx-small; }',
          '.col-md-4 { flex: 0 0 auto;width: 33.33333%;}',
          '.col-md-6 { flex: 0 0 auto;width: 50%; }',
          '.card-title, .worksheet-header, .style-23, .style-218 { font-size: xx-small }',
          '.style-10, .style-20 { font-size: small }',
          '.card-header { padding: 0.25rem 1rem !important; }',
          '.style-48 { margin-left: 3.2rem !important; }',
          '.style-61, .style-65 { margin-left: 43px !important; }',
          '.style-53 { margin-left: 24px !important; }',
          '.style-57 { margin-left: 41px !important; }',
          '.style-78 { margin-left: 54px !important; }',
          '.style-36, .style-40 { margin-left: 25px !important; }',
          '.card-body.border { flex-grow: 0; }',
          '.style-24 {display: none !important; }',
          '.worksheet-header {border-bottom: 2px solid #246cea;min-height: 5em;}',
          '.style-232 {display: inline;list-style-type: none;padding: 0;margin: 0;}',
          '.style-232>li {display: inline;color: #303e67;}',
          '.style-6, .style-220 {height: 50px !important;width: 50px !important;font-size: 16px !important;}',
          '.style-30 {float: right}',
          `#prepaidAndInitialCard {height: auto !important}`,
          `#estimatedFundsToCloseCard {height: auto !important}`,
          `#estimatedProposedMonthlyHousingExpanseCard {height: auto !important}`,
          '.style-84 {flex-grow: 1; display: grid; grid-template-columns: 1fr 1fr; gap: 1rem; padding-left: 0.5rem; padding-right: 0.5rem !important;}',
          '.style-84.style-84 {margin-top: 1rem !important;}',
          '.cards-section .col-md-6 {display: flex; align-items: stretch; width: unset !important; padding: 0 !important;}',
          '.cards-section .col-md-6 > div {flex-grow: 1; margin: 0;}',
          '.style-5, .style-219 {padding: 0 !important; width: unset;}',
          '.style-215 {text-align: justify;}',
          '.style-0, .style-1, .style-2 {height: 100%}',
          '.style-2 {flex-direction: column;}',
          '.style-84 > * {padding: 0 !important;}',
          '.style-31 {width: calc(100% - 1rem); margin-left: 0.5rem; padding: 0.5rem}',
          '.reset-row, .style-2.style-2, .style-32.style-32, .style-84.style-84 {margin-right: 0; margin-left: 0; flex-wrap: nowrap;}',
          '@media print { .style-217 { break-inside: avoid; } }',
        ]
      }
    ];

    setTimeout(() => {
      this.isPDFButtonEnabled = true;
    }, 3000);
  }

  onProfileImageDownloadFailed = () => {
    this.imageProfile = this.defaultProfileImage;
  }

  onCompanyLogoDownloadFailed = () => {
    if (this._tryingBranchLogoUrl) {
      this._tryingBranchLogoUrl = false;
      this._tryingCompanyLogoUrl = true;
      this.imageLogo = this._companyLogoUrl;
    } else if (this._tryingCompanyLogoUrl) {
      this._tryingCompanyLogoUrl = false;
      this.imageLogo = this.defaultCompanyLogo;
      this.isFailed = true;
    }
  }

  //This is not used for now until we figure out the server side rendering. It does not seem to do a good job right now.
  onSendAsImageInEmailClicked = () => {
    this._spinnerService.show();
    let html = this.elementRefService.nativeElement.innerHTML;
    const title = "Loan Quote";

    const sourcePath: string = '/assets/css/loan-quote-print.min.css';
    this._httpClient.get(sourcePath, { responseType: "text" }).subscribe(response => {
      let styleValue = response;
      const fullHtml: string = `<!DOCTYPE html><html><head><title>Initial Fees Worksheet</title><style>${styleValue}</style></head><body>${html}</body></html>`;
      this._loanDocService.renderHtmlToFile(fullHtml, "JPG", title).subscribe((response) => {

        const file = new Blob([response.body], { type: 'image/jpeg' });

        const url = window.URL.createObjectURL(file);
        window.open(url);

        let reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event) => {
          const image = "<img src='" + event.target.result as string + "'>";
          const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
          modalRef.componentInstance.body = image;
          modalRef.componentInstance.isShowOnlyEmail = true;
          modalRef.result.then(() => {

          }, err => { });
        };

      }, (err) => {
        this._notificationService.showError(err?.message || "", "Error!");
      }).add(() => this._spinnerService.hide());
    })
  }

  onSendAsHtmlInEmailClickedV2 = () => {
    this.inEmail = true;
    setTimeout(() => {
      let html = this.email.getHtml();
      const styles = `
      <style>
       * {
      font-family: "Roboto", sans-serif !important;
    }
     table#personal-info td {
      display: block;
      width: 100%;
      text-align: center;
    }

    table#table-values {
      td {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem !important;
      }
    }

    .personal-info {
      margin-top: 10px;
    }

    .table-column {
      display: block;
      width: 100%;
    }
  }

  .sub-card-table td {
    padding-top: 0;
    padding-bottom: 0;
  }
        </style>`;

      const fullHtml: string = `<!DOCTYPE html><html><head><title>Initial Fees Worksheet</title>${styles}</head><body><br><br> ${html}</body></html>`;
      const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.body = fullHtml;
      modalRef.componentInstance.isShowOnlyEmail = true;
      this.inEmail = false;
      modalRef.result.then(() => {

      }, err => { });

    })
  }

  onSendAsHtmlInEmailClicked = () => {
    this.inEmail = true;
    setTimeout(() => {
      let html = this.elementRefService.nativeElement.innerHTML;
      const sourcePath: string = '/assets/css/loan-quote-print.min.css';
      this._httpClient.get(sourcePath, { responseType: "text" }).subscribe(response => {
        let styleValue = response;
        const fullHtml: string = `<!DOCTYPE html><html><head><title>Initial Fees Worksheet</title><style>${styleValue}</style></head><body><br><br> ${html}</body></html>`;
        const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
        modalRef.componentInstance.body = fullHtml;
        modalRef.componentInstance.isShowOnlyEmail = true;
        this.inEmail = false;
        modalRef.result.then(() => {

        }, err => { });
      }, (err) => {
        this.inEmail = false;
        this._notificationService.showError(err?.message || "", "Error!");
      })
    })
  }

  //This is not used for now until we figure out the server side rendering. It does not seem to do a good job right now.
  onSendAsEmailClicked = () => {
    this._spinnerService.show();
    let html = this.elementRefService.nativeElement.innerHTML;
    const title = "Loan Quote";

    const sourcePath: string = '/assets/css/loan-quote-print.min.css';
    this._httpClient.get(sourcePath, { responseType: "text" }).subscribe(response => {
      let styleValue = response;
      const fullHtml: string = `<!DOCTYPE html><html><head><title>Initial Fees Worksheet</title><style>${styleValue}</style></head><body>${html}</body></html>`;
      this._loanDocService.renderHtmlToLoanDoc(fullHtml, "PDF", title, this.application.applicationId, this.application.primaryBorrowerId).subscribe(loanDoc => {
        this._loanDocService.getLoanDoc(loanDoc.loanDocId).subscribe(lD => {
          let docFile = new DocFile();
          docFile.guid = lD.docFiles[0].guid;

          const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
          modalRef.componentInstance.attachments = [docFile];
          modalRef.componentInstance.isShowOnlyEmail = true;
          modalRef.result.then(() => {

          }, err => { });
        })
      }, (err) => {
        this._notificationService.showError(err?.message || "", "Error!");
      }).add(() => this._spinnerService.hide());
    })
  }

  //This is not used for now until we figure out the server side rendering. It does not seem to do a good job right now.
  onSendAsTextClicked = () => {
    this._spinnerService.show();
    let html = this.elementRefService.nativeElement.innerHTML;
    const title = "Loan Quote";

    const sourcePath: string = '/assets/css/loan-quote-print.min.css';
    this._httpClient.get(sourcePath, { responseType: "text" }).subscribe(response => {
      let styleValue = response;
      const fullHtml: string = `<!DOCTYPE html><html><head><title>Initial Fees Worksheet</title><style>${styleValue}</style></head><body>${html}</body></html>`;
      this._loanDocService.renderHtmlToFile(fullHtml, "JPG", title).subscribe((response) => {

        let file = new File([response.body], 'Loan_Quote.jpeg', { type: 'image/jpeg' });

        const url = window.URL.createObjectURL(file);
        window.open(url);

        let reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event) => {
          const base64 = event.target.result;
          let attachment = new FileAttachment();
          attachment.base64 = base64;
          attachment.file = file;
          const modalRef = this._modalService.open(SendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
          modalRef.componentInstance.fileAttachments = [attachment];
          modalRef.componentInstance.isShowOnlyMMS = true;
          modalRef.componentInstance.sendEmail = false;
          modalRef.result.then(() => {

          }, err => { });
        };

      }, (err) => {
        this._notificationService.showError(err?.message || "", "Error!");
      }).add(() => this._spinnerService.hide());
    })
  }

  getFeeNameWithFeePercent = (fee: LoanFee): string => {
    if (!!fee.borrowerFeePercent) {
      let percentText = this._percentPipe.transform(fee.borrowerFeePercent / 100, "1.3-3");
      return fee.name + " (" + percentText + ")";
    }
    else {
      return fee.name;
    }
  }

  protected signedCurrencyToString(value: number): string {
    const strValue = this._currencyPipe.transform(Math.abs(value));
    return value < 0 ? `(${strValue})` : strValue;
  }

  protected getBorrowerTotalAsString(fee: LoanFee): string {
    const signedTotal = getSignedBorrowerTotal(fee);
    return this.signedCurrencyToString(signedTotal);
  }

  private setFieldValuesFromLoan = () => {
    // From FTC
    if (this.application.productPricing && this.application.productPricing['pricingRequestPayload']) {
      const request = JSON.parse(this.application.productPricing['pricingRequestPayload']);
      this.waiveEscrows = request.LoanInformation.WaiveEscrows;
    }

    if (this.mortgage) {
      const creditScores = this.mortgage.borrowers.filter(b => b.creditScore).map(b => b.creditScore);
      if (creditScores && creditScores.length) {
        this.fico = Math.min(...creditScores);
      }
    }

    this.purchasePrice = this.application.purchasePrice;
    this.appraisedValue = this.application.presentValue;
    this.rate = this.application.productPricing?.rate;
    this.apr = this.application.productPricing?.apr;
    this.loanAmount = this.application.loanAmount;
    this.totalLoanAmount = this.mortgage.mortgageTerm?.totalLoanAmount;
    this.lockTerm = this.application.productPricing?.lockTerm;
    this.productName = this.application.productPricing?.productName;
    if (this.productName && this.application.productPricing?.investor)
      this.productName.replace(this.application.productPricing.investor, "")
    this.noOfUnits = this.mortgage.subjectProperty?.noOfUnits?.toString();

    this.loanPurpose = this.mortgage.subjectProperty.purposeOfLoan;
    this.propertyType = this.generatePropertyTypeLabel();
    this.occupancy = this.mortgage.subjectProperty.propertyWillBe;
    this.zipCode = this.mortgage.subjectProperty.zipCode;

    this.totalOriginationFees = addUpBorrowerTotals(this.originationFees);
    this.totalOtherFees = this.otherFees.reduce((prev, cur) => prev + (cur.calculatedValues?.borrowerTotal ?? 0), 0);
    this.totalOtherAndGovernmentFees = this.governmentTaxesAndFees.reduce((prev, cur) => prev + (cur.calculatedValues?.borrowerTotal ?? 0), 0);
    this.totalPrepaidsAndEscrow = (this.mortgage.transactionDetail.prepaidItemsEstimatedAmount ?? 0) + (this.mortgage.transactionDetail.prepaidEscrowsTotalAmount ?? 0);

    this.nonFinancedPmiMipFundingFee = (this.ftcDetails?.pmiMipFundingFee || 0) -
      (this.ftcDetails?.financedMip || 0);

    this.totalSubordinateLiens = (this.ftcDetails?.otherNewMortgageLoansOnSubjectProperty_J || 0);

    // ftcDetails.totalDueFromBorrowerAThroughG_H includes the purchase price, but not the down payment. To make the math consistent, subtract loan amount since the fee quote includes down payment
    // totalDueFromBorrowerAThroughG_H also includes financed UFMIP and totalMortgageLoansIThroughJ_K is based on total loan amount, so that should be subtracted as well.
    this.totalFundsDueFromBorr = (this.ftcDetails?.totalDueFromBorrowerAThroughG_H || 0) -
      (this.ftcDetails?.totalMortgageLoansIThroughJ_K || 0);

    this.sellerCredits = this.ftcDetails?.sellerCredits_L || 0;
    this.lenderCredits = this.ftcDetails?.lenderCredits || 0;
    this.emd = this.ftcDetails?.emd || 0;
    this.otherCredits = this.ftcDetails?.otherCredits_M || 0;

    this.totalCreditsApplied = this.sellerCredits + this.lenderCredits + this.emd + this.otherCredits;

    this.estimatedCashFromBorr = this.ftcDetails?.cashFromToBorrower ?? 0;

    const proposedHousingExpense = this.mortgage.proposedHousingExpense;
    this.totalApproxMonthlyPayment =
      (proposedHousingExpense.firstMortgagePrincipalAndInterest || 0) +
      (proposedHousingExpense.otherMortgageLoanPrincipalAndInterest || 0) +
      (proposedHousingExpense.homeownersInsurance || 0) +
      (proposedHousingExpense.realEstateTax || 0) +
      (proposedHousingExpense.mortgageInsurance || 0) +
      (proposedHousingExpense.homeownersAssociationDues || 0);

    this.licenseNo = this.mortgage.originatorInformation.stateLicense;
    this.updatedDate = this.application.productPricing?.assignDate ? new Date(this.application.productPricing.assignDate) : null;
  }

  private setFieldValuesFromScenario = () => {
    this.totalPrepaidsAndEscrow = this.prepaids.reduce((prev, cur) => prev + (cur.calculatedValues?.borrowerTotal || 0), 0) + this.escrow.reduce((prev, cur) => prev + (cur.calculatedValues?.borrowerTotal || 0), 0);
    this.totalOtherAndGovernmentFees = this.governmentTaxesAndFees.reduce((prev, cur) => prev + (cur.calculatedValues?.borrowerTotal || 0), 0);
    this.totalOriginationFees = addUpFeeTotals(this.originationFees);
    this.totalOtherFees = this.otherFees.reduce((prev, cur) => prev + (cur.calculatedValues?.borrowerTotal || 0), 0);
    this.rate = this.scenario.adjustedRate;
    this.apr = this.scenario.apr;
    this.loanPurpose = this.scenario.pricingRequestPayload.loanInformation.loanPurpose;
    this.fico = this.scenario.pricingRequestPayload.representativeCreditScore;
    this.productName = this.scenario.productName;
    this.purchasePrice = this.scenario.pricingRequestPayload.propertyInformation.salesPrice;
    this.appraisedValue = this.scenario.pricingRequestPayload.propertyInformation.appraisedValue;
    this.occupancy = this.scenario.pricingRequestPayload.propertyInformation.occupancy;
    this.zipCode = this.scenario.pricingRequestPayload.propertyInformation.zipCode;
    this.loanAmount = this.scenario.pricingRequestPayload.loanInformation.baseLoanAmount;
    this.waiveEscrows = this.scenario.pricingRequestPayload.loanInformation.waiveEscrows;
    this.updatedDate = this.scenario.dateUpdated ? new Date(this.scenario.dateUpdated) : null;
    this.lockTerm = this.scenario.lockTerm;
    this.noOfUnits = this.scenario.pricingRequestPayload.propertyInformation.numberOfUnits;

    // this.propertyType = ??

    if (!this.mortgage) {
      // From Quick Pricer
      this.totalApproxMonthlyPayment = (this.scenario.principalAndInterest || 0) + (this.scenario.monthlyInsurance || 0) + (this.scenario.monthlyMi || 0) + (this.scenario.monthlyHoa || 0) + (this.scenario.monthlyTaxes || 0) + (this.scenario.monthlyMi || 0) + (this.scenario.pricingRequestPayload.loanInformation.taxesAndInsuranceMonthly || 0);
      this.sellerCredits = 0;
      this.lenderCredits = 0;
      this.emd = 0;
      this.otherCredits = 0;
    } else {
      // From Loan Pricing
      const proposedHousingExpense = this.mortgage.proposedHousingExpense;
      this.totalApproxMonthlyPayment = (this.scenario ? (this.scenario.principalAndInterest || 0) :
        (proposedHousingExpense.firstMortgagePrincipalAndInterest || 0)) +
        (proposedHousingExpense.otherMortgageLoanPrincipalAndInterest || 0) +
        (proposedHousingExpense.homeownersInsurance || 0) +
        (proposedHousingExpense.realEstateTax || 0) +
        (proposedHousingExpense.mortgageInsurance || 0) +
        (proposedHousingExpense.homeownersAssociationDues || 0);

      this.sellerCredits = this.mortgage.transactionDetail.sellerPaidClosingCostsAmount || 0;
      this.lenderCredits = this.mortgage.calculatedStats.lenderCredit || 0;
      this.emd = this.mortgage.calculatedStats.emd || 0;
      this.otherCredits = this.mortgage.calculatedStats.totalOtherCredit || 0;
      this.licenseNo = this.mortgage.originatorInformation.stateLicense;
    }
    const downPayment = (this.isRefinance ? -1 : 1) * (this.downpaymentFunds || 0);
    this.totalFundsDueFromBorr = downPayment + this.totalOriginationFees + this.totalOtherFees + this.totalOtherAndGovernmentFees + this.totalPrepaidsAndEscrow + this.estimatedTotalPayOffs + this.improvementsRenovationsAndRepairs;
    this.totalCreditsApplied = this.sellerCredits + this.lenderCredits + this.emd + this.otherCredits;
    this.estimatedCashFromBorr = this.totalFundsDueFromBorr - this.totalCreditsApplied;
  }

  isFilteredLiability = (liabilityType: string) => {
    return liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Alimony) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildSupport) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.JobRelatedExpenses) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.SeparateMaintenanceExpense) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.OtherLiability);
  }

  private generatePropertyTypeLabel = (): string => {

    let propertyTypeLabel = "";

    if (!this.mortgage.subjectProperty.attachmentType) {
      return "";
    }

    const isPud = this.mortgage.subjectProperty.isPlannedUnitDevelopment === true;
    const isInProject = this.mortgage.subjectProperty.isPropertyNotInAProject === false;

    propertyTypeLabel = this.mortgage.subjectProperty.attachmentType;

    if (isPud) {
      propertyTypeLabel += " (PUD)";
    }

    if (isInProject && this.mortgage.subjectProperty.projectType) {
      propertyTypeLabel += (" - " + this.mortgage.subjectProperty.projectType);
    }

    return propertyTypeLabel;
  }
}

/**
 * Creates a function that filters fees that match the given regular expression
 * @private
 * @example
 * const findByHud = filterFeesByHud(fees);
 * const feesStartsWithHud80 = findByHud(/^80/);
 * @param {LoanFee[]} fees - The fees to filter
 */
function filterFeesByHud(
  fees: readonly LoanFee[],
): (regExp: RegExp) => LoanFee[] {
  return (regExp: RegExp) => fees.filter(fee =>
    typeof fee.hudNumber === 'string'
    && regExp.test(fee.hudNumber));
}

function getFeeSign(fee: LoanFee): number {
  return fee.isLoanCredit ? -1 : 1;
}

function getSignedBorrowerTotal(fee: LoanFee): number {
  const total = fee.calculatedValues?.borrowerTotal ?? 0;
  return getFeeSign(fee) * total;
}

function getSignedFeeTotal(fee: LoanFee): number {
  const total = fee.calculatedValues?.totalFee ?? 0;
  return getFeeSign(fee) * total;
}

function addUpFeeTotals(fees: LoanFee[]): number {
  return fees.reduce((sum, fee) => sum + getSignedFeeTotal(fee), 0);
}

function addUpBorrowerTotals(fees: LoanFee[]): number {
  return fees.reduce((sum, fee) => sum + getSignedBorrowerTotal(fee), 0);
}
