import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';
import { ResidencyAddressBaseComponent } from '../residency-address-base.component';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Address, ResidencyAddress, ResidencyType } from 'src/app/models';
import * as _ from 'lodash';
import { createIsRequiredFunction } from '../../../urla-utils';

@Component({
  selector: 'present-address',
  templateUrl: 'present-address.component.html',
  viewProviders: [formViewProvider]
})

export class PresentAddressComponent extends ResidencyAddressBaseComponent implements OnInit {

  @Input()
  index: number;

  @Input()
  otherResidencyAddresses: ResidencyAddress[] = [];

  @Output()
  residencyBasisChanged: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Output()
  subjectPropertyStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  pushAddressToSubjectPropertyClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pullAddressSubjectPropertyClicked: EventEmitter<ResidencyAddress> = new EventEmitter<ResidencyAddress>();

  @Input()
  isSubjectProperty: boolean = false;

  @Input()
  showHousingInfo: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  addressesToPickFrom: ResidencyAddress[] = [];
  housingTypes: EnumerationItem[] = [];
  yesNoOptions: EnumerationItem[] = [];

  protected isRequired: (fieldName: string) => boolean;

  constructor(private readonly _enumService: EnumerationService) {
    super();
  }

  ngOnInit() {
    this._enumService.getMortgageEnumerations().subscribe(enums => {
      this.housingTypes = enums[Constants.enumerations.residencyBasisTypes];
      this.yesNoOptions = this._enumService.getYesNoEnumItems();
    });
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);

    if (!this.residencyAddress.address) {
      this.residencyAddress.address = new Address();
    }
    this.populateAddressesToCopyFrom();
  }

  onPushAddressToSubjectPropertyClicked = () => {
    this.pushAddressToSubjectPropertyClicked.emit();
  }

  onPullAddressSubjectPropertyClicked = (address: ResidencyAddress) => {
    this.pullAddressSubjectPropertyClicked.emit(address);
  }

  onSubjectPropertyStatusChanged = () => {
    this.subjectPropertyStatusChanged.emit(this.isSubjectProperty);
  }

  getFullAddress = (residencyAddress: ResidencyAddress): string => {
    const { residencyType, address: { address1, address2, city, state, zipCode } } = residencyAddress;

    let result = "";
    if (residencyType == ResidencyType.PresentAddress) {
      result += 'Present: ';
    } else {
      if (residencyType == ResidencyType.FormerAddress) {
        result += 'Former: ';
      } else {
        result += 'Mailing: ';
      }
    }
    result += `${address1 || '-'}, ${address2 || '-'}, ${city || '-'}, ${state || '-'}, ${zipCode || '-'}`;

    return result;
  }

  copyFrom = (residencyAddress: ResidencyAddress) => {
    this.residencyAddress.address.address1 = residencyAddress.address.address1;
    this.residencyAddress.address.address2 = residencyAddress.address.address2;
    this.residencyAddress.address.city = residencyAddress.address.city;
    this.residencyAddress.address.state = residencyAddress.address.state;
    this.residencyAddress.address.zipCode = residencyAddress.address.zipCode;
    this.residencyAddress.address.country = residencyAddress.address.country;
  }

  onCopyFromClicked = () => {
    this.populateAddressesToCopyFrom();
  }

  onBorrowerPresentAddressResidencyBasisChanged = (residencyAddress: ResidencyAddress) => {
    this.residencyBasisChanged.emit(residencyAddress);
  }

  populateAddressesToCopyFrom = () => {
    const { address: { address1, city, state, zipCode } } = this.residencyAddress;

    this.addressesToPickFrom = this.otherResidencyAddresses.reduce((pV, cV) => {
      if (!cV.address || !cV.address.address1) {
        return pV;
      }

      if (cV.address.address1 == null || cV.address.city == null || cV.address.state == null || cV.address.zipCode == null) {
        return pV;
      }

      if (address1 === cV.address.address1 && city === cV.address.city && state === cV.address.state && zipCode === cV.address.zipCode) {
        return pV;
      }

      // prevent dups
      const pVIndex = pV.findIndex(({ address }) => address.address1 === cV.address.address1
        && address.city === cV.address.city
        && address.state === cV.address.state
        && address.zipCode === cV.address.zipCode);

      return pVIndex === -1 ? [...pV, cV] : pV;
    }, []);
  }
}
