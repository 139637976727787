import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { MortgageInsuranceDetail } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { MortgageCalculationService } from '../../services/mortgage-calculation.service';

@Component({
  selector: 'due-from-borrower',
  templateUrl: 'due-from-borrower.component.html',
  styleUrls: ['due-from-borrower.component.scss'],
  viewProviders: [formViewProvider]
})
export class DueFromBorrowerComponent implements OnInit {

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    if (mortgage) {
      this.isRefiLoan = this._calculationService.isPurposeOfLoanRefinance(mortgage);
      this._mortgage = mortgage;
    }
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  landValueType: EnumerationItem[] = [];

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Output()
  purchasePriceAmountChanged: EventEmitter<any> = new EventEmitter<any>();

  urlaFieldsConfig: {};
  dhHiddenHack: boolean = false;

  protected isRefiLoan: boolean;

  private _landValueTypeAppraised: string;
  private _landValueTypeOriginal: string;

  private _mortgage: UrlaMortgage;

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _calculationService: MortgageCalculationService) { }

  ngOnInit() {
    this.dhHiddenHack = this.mortgage.companyId == 229;
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.landValueType = enums[Constants.mortgageEnumerations.landValueType];
      this._landValueTypeAppraised = this._enumsService.getEnumValue(Constants.enumerationValueNames.LandValueType.Appraised);
      this._landValueTypeOriginal = this._enumsService.getEnumValue(Constants.enumerationValueNames.LandValueType.Original);
    })
    if (!this._mortgage.mortgageInsuranceDetail) {
      this._mortgage.mortgageInsuranceDetail = new MortgageInsuranceDetail();
    }
    setTimeout(() => {
      this._calculationService.calculateMortgageStatistics(this._mortgage);
    });
  }

  calculateTotalDue = () => {
    this._mortgage.transactionDetail.estimatedClosingCostsAmount =
      (this._mortgage.transactionDetail.prepaidItemsEstimatedAmount ?? 0) +
      (this._mortgage.transactionDetail.prepaidEscrowsTotalAmount ?? 0) +
      (this._mortgage.transactionDetail.estimatedClosingCostsExcludingPrepaidsAmount ?? 0) +
      (this._mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount ?? 0);

    this._mortgage.calculatedStats.estimatedClosingCostsAmount = this._calculationService.calculateEstimatedClosingCosts(this._mortgage)

    this._mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this._mortgage);
    this._mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this._mortgage);
  }

  landValueChange = () => {
    if (this._mortgage.subjectProperty && this._mortgage.subjectProperty.landValueType == this._landValueTypeAppraised) {
      this._mortgage.calculatedStats.landValue = this._mortgage.subjectProperty.lotAppraisedValue;
    } else if (this._mortgage.subjectProperty && this._mortgage.subjectProperty.landValueType == this._landValueTypeOriginal) {
      this._mortgage.calculatedStats.landValue = this._mortgage.subjectProperty.lotOriginalCost;
    } else {
      this._mortgage.calculatedStats.landValue = 0;
    }

    this._mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this._mortgage);
    this._mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this._mortgage);
  }

  onPurchasePriceAmountChange = () => {
    this.purchasePriceAmountChanged.emit(this._mortgage.transactionDetail.purchasePriceAmount);
  }
}
