import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ApplicationContext, HousingExpense } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { SystemLevelService } from 'src/app/services/system-level.service';

@Component({
  selector: 'current-mortgage-info',
  templateUrl: './current-mortgage-info.component.html',
  styleUrls: ['./current-mortgage-info.component.scss']
})
export class CurrentMortgageInfoComponent implements OnInit {

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    this.isRefinance = this.mortgage.subjectProperty?.purposeOfLoan == "Refinance";
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  private _mortgage: UrlaMortgage;

  @Input()
  context: ApplicationContext;

  totalLoanAmount: number = 0;
  ltv: number = 0;
  cltv: number = 0;
  isAppraisedValueHidden: boolean = false;
  loanPurposes: LoanPurpose[] = [];

  yesNoOptions: EnumerationItem[] = [];
  lienPositionTypes: EnumerationItem[] = [];
  giftGrantSources: EnumerationItem[] = [];

  downPayment: number = 0;
  isHeloc: boolean = false;

  totalHousingPayment: number = 0;
  isRefinance: boolean = false;

  showOtherReo: boolean = false;

  constructor(private readonly _configurationService: ConfigurationService,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _enumsService: EnumerationService,
    private readonly _utilityService: UtilityService) { }

  ngOnInit(): void {
    this._configurationService.getCompanyConfiguration('LoanHiddenFields').subscribe(loanHiddenFields => {
      let hiddenFields = loanHiddenFields ? loanHiddenFields['valueStr'] : '';
      this.isAppraisedValueHidden = hiddenFields.indexOf('Appraised Value') > -1
    });
    this.loanPurposes = this.context.globalConfig.loanPurpose;
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.lienPositionTypes = enums[Constants.enumerations.lienPositionType];
      this.giftGrantSources = enums[Constants.enumerations.giftGrantSource];

      if (!this.mortgage.currentHousingExpense) {
        this.mortgage.currentHousingExpense = new HousingExpense();
      }
    })


    this.changevalue();

    if (this.mortgage.mortgageTerm.appraisedValue > 0) {
      this.downPayment = this.mortgage.mortgageTerm.appraisedValue - this.mortgage.mortgageTerm.amount || 0;
    }
    this.isRefinance = this.mortgage.subjectProperty?.purposeOfLoan == "Refinance" // || mortgageLoanPurpose in loan purpose === "Refinance";
  }

  changevalue = () => {
    let total = this.mortgage.mortgageTerm.amount;
    this.totalLoanAmount = total;

    if (this.mortgage.mortgageTerm.appraisedValue == 0) {
      this.ltv = 0;
      this.cltv = 0;
    }
    else {
      const { ltv, cltv } = this._calculationService.calculateLtvAndCltv(this.mortgage);
      this.ltv = ltv
      this.cltv = cltv;
    }
  }

  calculateTotalHousingPayment = () => {
    setTimeout(() => {
      let subTotal = 0;
      subTotal += Number(this.mortgage.currentHousingExpense.firstMortgagePrincipalAndInterest) || 0;
      subTotal += Number(this.mortgage.currentHousingExpense.homeownersInsurance) || 0;
      subTotal += Number(this.mortgage.currentHousingExpense.realEstateTax) || 0;
      subTotal += Number(this.mortgage.currentHousingExpense.mortgageInsurance) || 0;
      subTotal += Number(this.mortgage.currentHousingExpense.homeownersAssociationDuesAndCondominiumFees) || 0;
      subTotal += Number(this.mortgage.currentHousingExpense.otherMortgageLoanPrincipalAndInterest) || 0;

      this.totalHousingPayment = subTotal;
    }, 100);
  }

  propertyOnBlur = (type: string) => {
    if (!this.mortgage.proposedHousingExpense[type]) {
      this.mortgage.proposedHousingExpense[type] = _.clone(this.mortgage.currentHousingExpense[type]);
    }
  }
}
