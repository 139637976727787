import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LegalDescriptions, ParcelIdentification } from 'src/app/models';
import { Constants } from '../../../../services/constants';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { UrlaRequiredState } from '../../services/utility.service';
import { UrlaSectionComponent } from '../../urla-section/urla-section.component';

@Component({
  selector: 'urla-legal-descriptions',
  templateUrl: './urla-legal-descriptions.component.html',
  styleUrls: ['./urla-legal-descriptions.component.scss']
})
export class UrlaLegalDescriptionsComponent extends UrlaSectionComponent implements OnInit {
  @Input() mortgage: UrlaMortgage;

  @Input() isReadOnly: boolean = false;

  @Input() inEditMode: boolean = false;

  @Input() urlaFieldsConfig: Record<string, UrlaRequiredState>;

  @Input() urlaSectionForm: NgForm;

  menuItem: string = Constants.menu.urlaMenuItems.titleInfo;

  @ViewChild('formElement') formElement: NgForm;

  protected descriptions: LegalDescriptions;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    const subjectProperty = this.mortgage.subjectProperty;
    // FIXME: legalDescriptions' type is LegalDescriptions[] in the API, but LegalDescriptions in the model
    const legalDescriptions = (subjectProperty.legalDescriptions as unknown as LegalDescriptions[]) ??= [];

    const descriptions = this.descriptions = legalDescriptions[0] ?? new LegalDescriptions();
    if (legalDescriptions.length === 0) {
      legalDescriptions.push(descriptions);
    }

    if (!descriptions.unparsedLegalDescriptions || !descriptions.unparsedLegalDescriptions.length) {
      descriptions.unparsedLegalDescriptions = [];
    }

    if (!descriptions.parsedLegalDescriptions || !descriptions.parsedLegalDescriptions.length) {
      descriptions.parsedLegalDescriptions = [];
    }

    if (!descriptions.parcelIdentifications || !descriptions.parcelIdentifications.length) {
      descriptions.parcelIdentifications = [];
    }
  }

  validate() {
    
  }

  protected onParcelIdDescriptionsChanged = (descriptions: ParcelIdentification[]) => {
    this.descriptions.parcelIdentifications = [...descriptions];
  }
}
