import { Component, Injector, Input, ViewChild, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constants } from 'src/app/services/constants';
import { MenuItemStatus } from '../../tpo/models/enums/menu-item-status.enum';
import { UrlaMortgage } from '../models/urla-mortgage.model';
import { UrlaSectionComponent } from '../urla-section/urla-section.component';
import { MortgageBorrower } from 'src/app/models';
import { groupBy } from 'lodash';
import { BorrowerValidityStatus } from '../borrower-information/borrower-info/borrower-info.component';
import { BorrowerTabSelectedEvent, TabbedBorrowerComponentType } from '../urla-main/urla-main.component';
import { scrollByElementTopAfterViewChecked } from '../services/urla-utils';
import { UtilityService } from '../services/utility.service';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';

@Component({
  selector: 'demographics',
  templateUrl: 'demographics.component.html',
  styleUrls: ['./demographics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemographicsComponent extends UrlaSectionComponent implements OnInit {

  @Output()
  borrowerTabSelected: EventEmitter<BorrowerTabSelectedEvent> = new EventEmitter<BorrowerTabSelectedEvent>();

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    if (mortgage && mortgage.mortgageId) {
      this._mortgage = mortgage;
      this.initialize();
    }
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  isReadOnly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @ViewChild("demographicsForm")
  urlaSectionForm: NgForm;

  menuItem: string = Constants.menu.urlaMenuItems.demographics;

  borrowerValidityStatusInfo: Map<number, MenuItemStatus> = new Map<number, MenuItemStatus>();

  selectedBorrower: MortgageBorrower;

  borrowerGroups: MortgageBorrower[][] = [];

  protected getBorrowerDisplayName: (borrower: MortgageBorrower) => string = () => '';

  protected isStatusLoaded: boolean = false;

  private _mortgage: UrlaMortgage;

  constructor(
    injector: Injector,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _utilityService: UtilityService,
    private readonly _urlaValidationService: UrlaValidationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getBorrowerDisplayName = this._utilityService.getBorrowerDisplayName;

    if (this.mortgage?.borrowers && this.mortgage.borrowers.length) {
      this.mortgage.borrowers.forEach(borrower => {
        const borrowerBasedValidityStatus = this._urlaValidationService.getStatusForBorrowerDemographics(borrower);
        this.borrowerValidityStatusInfo.set(borrower.borrowerId, borrowerBasedValidityStatus);
      });
    }
    this.isStatusLoaded = true;
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForDemographics(this.mortgage);
    super.setMenuItemStatus(validityStatus);
  }

  onBorrowerSelected = (borrower: MortgageBorrower, isRedirection: boolean) => {
    this.selectedBorrower = borrower;
    this.borrowerTabSelected.emit({ selectedBorrower: this.selectedBorrower, sourceComponent: TabbedBorrowerComponentType.Demographics });

    if (isRedirection) {
      const order = 8;
      const element = document.querySelector(`#urlaAccordion > .accordion-item:nth-child(${order})`);
      scrollByElementTopAfterViewChecked(element as HTMLElement);
    }
  }

  selectBorrowerTab = (borrower: MortgageBorrower) => {
    this.selectedBorrower = borrower;
    this._cdRef.detectChanges();
  }

  isSelectedBorrower(borrower: MortgageBorrower): boolean {
    return this.selectedBorrower?.borrowerId === borrower.borrowerId;
  }

  onBorrowerValidityStatusChanged = (validityStatus: BorrowerValidityStatus) => {
    const borrower = this.mortgage.borrowers.find(b => b.borrowerId == validityStatus.borrowerId);
    if (borrower) {
      const borrowerBasedValidityStatus = this._urlaValidationService.getStatusForBorrowerDemographics(borrower);
      this.borrowerValidityStatusInfo.set(validityStatus.borrowerId, borrowerBasedValidityStatus);
      this.validate();
      this.isStatusLoaded = true;
      setTimeout(() => {
        this._cdRef.detectChanges();
      });
    }
  }

  private initialize = () => {
    if (!this.selectedBorrower) {
      this.onBorrowerSelected(this._mortgage.borrowers[0], false);
    }
    this.adjustBorrowers(this._mortgage.borrowers);
  }

  private adjustBorrowers = (borrowers: MortgageBorrower[]) => {
    // First filter the borrowers based on signingRole
    const borrowersThatNeedToAnswerDemographicsQuestions =
      borrowers.filter(borrower => borrower.signingRole === 'Borrower' || borrower.signingRole === 'CoSigner');
    this.borrowerGroups = [];
    this.borrowerGroups = borrowersThatNeedToAnswerDemographicsQuestions.length > 0 ?
      Object.values(groupBy(borrowersThatNeedToAnswerDemographicsQuestions, 'printApplicationIndex')) : [];
  }
}
