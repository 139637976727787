<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title d-flex align-items-center justify-content-between">
          <span><i class="fas fa-file-pdf-o me-2"></i> Disclosures Wizard</span>
          <span>
            <button type="button" class="btn btn-primary me-2" [disabled]="thereAreNoLoanPlans"
              (click)="onShowAuditResultsClicked()">
              Show Audit Results
            </button>

            <button type="button" class="btn btn-secondary {{ !atFirstStep ? 'me-2' : '' }}"
              (click)="onDisclosureTrackingClicked()">
              Disclosure Tracking
            </button>

            <button *ngIf="!atFirstStep || hasCancelButton" type="button"
              class="btn btn-primary {{atFirstStep ? 'ms-2' : ''}}" (click)="onCancelClicked()">
              Cancel
            </button>
          </span>
        </h4>
      </div>
      <div class="card-body">
        <p-steps [model]="steps" [(activeIndex)]="activeStepIndex"></p-steps>
        <div class="pb-5 pt-3" [ngStyle]="{'min-height': isTpoUser ? '463px' : 'initial'}">
          <div *ngIf="thereAreNoLoanPlans"
            class="alert custom-alert custom-alert-warning icon-custom-alert shadow-sm my-3" role="alert">
            <div class="media">
              <i class="la la-exclamation-triangle alert-icon text-warning font-30 me-3 align-self-center"></i>
              <div class="media-body">
                <ng-container *ngIf="!cachedLoanPlans.length">
                  <h5 class="mb-1 fw-bold mt-0">No Loan Plans</h5>
                  <span>There are no loan plans. Please
                    check your disclosure credentials in System Level ->
                    Integrations or refresh loan plans</span>
                </ng-container>
                <ng-container *ngIf="cachedLoanPlans.length">
                  <h5 class="mb-1 fw-bold mt-0">Missing Loan Paramaters</h5>
                  <span>There are no suitable loan plans defined for this loan. Please
                    set required loan parameters (
                    <span *ngIf="!this.mortgage.mortgageTerm?.mortgageAppliedFor">Mortgage Applied For</span><span
                      *ngIf="!this.mortgage.mortgageTerm?.amortizationType"><span
                        *ngIf="!this.mortgage.mortgageTerm?.mortgageAppliedFor"> , </span>Amortization Type</span>
                    )</span>
                </ng-container>
              </div>
            </div>
          </div>
          <div *ngIf="activeStepIndexWithOffset == 0">
            <ng-template [ngIf]="!loadingData" [ngIfElse]="loading">
              <!-- result - audit/error -->
              <div class="row" *ngIf="isRecentDisclosure">
                <div class="col-md-12">
                  <div class="card-body px-0">
                    <div class="fieldset-title">Audit Messages</div>
                    <div class="p-2" *ngIf="recentDisclosure.disclosureResponse?.auditMessages">
                      <disclosure-message [isAudit]="true" [messages]="
                          recentDisclosure.disclosureResponse.auditMessages
                        "></disclosure-message>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #loading>
              <loading-indicator></loading-indicator>
            </ng-template>
          </div>
          <div *ngIf="activeStepIndexWithOffset == 1">
            <ng-template [ngIf]="!loadingData" [ngIfElse]="loading">
              <form id="disclosuresForm" #disclosuresForm="ngForm" class="w-100">
                <div class="row">
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <label class="control-label">Document Set</label>
                        <select class="form-select" title="{{ displayDocumentSetTooltip() }}" [ngClass]="{
                            'is-invalid':
                              documentSet &&
                              documentSet.touched &&
                              documentSet.invalid
                          }" name="documentSet" [(ngModel)]="recentDisclosure.requestData.documentSet"
                          [disabled]="!isRecentDisclosure" #documentSet="ngModel" required>
                          <option value="">-- Select One--</option>
                          <option *ngFor="let doc of documentSets | sort : 'order'" [ngValue]="doc.alternateValue">
                            {{ doc.name }}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Document set is required.
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label class="control-label">Worksheet Number</label>
                        <input class="form-control" name="worksheetNumber" title="{{
                            recentDisclosure.requestData.thirdPartyRecordId
                          }}" [(ngModel)]="
                            recentDisclosure.requestData.thirdPartyRecordId
                          " />
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-md-6">
                        <label class="control-label">Preferred Language </label>
                        <select class="form-select" [ngClass]="{
                            'is-invalid':
                              preferredLanguage &&
                              preferredLanguage.touched &&
                              preferredLanguage.invalid
                          }" name="preferredLanguage" [(ngModel)]="
                            recentDisclosure.requestData.preferredLanguage
                          " #preferredLanguage="ngModel" required>
                          <option value="">-- Select One--</option>
                          <option *ngFor="let language of preferredLanguages" [ngValue]="language.value">
                            {{ language.name }}
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          Preferred Language is required.
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label class="control-label">Flood Certification Type</label>
                        <select class="form-select" name="floodCertificationType" [(ngModel)]="
                            recentDisclosure.requestData.floodCertificationType
                          ">
                          <option value="">-- Select One--</option>
                          <option *ngFor="let enum of floodCertificationTypes" [ngValue]="enum.value">
                            {{ enum.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-md-6">
                        <label class="control-label" for="estimatedClosingCostsExpiration">
                          Est. Closing Costs Expiration
                        </label>
                        <date-input [id]="'estimatedClosingCostsExpiration'" [name]="'estimatedClosingCostsExpiration'"
                          [(ngModel)]="estimatedClosingCostsExpiration" [readonly]="true"></date-input>
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-md-6">
                        <div class="form-check">
                          <input id="includeLoanAnalysisReports" class="form-check-input"
                            name="includeLoanAnalysisReports" type="checkbox" [(ngModel)]="
                              recentDisclosure.requestData
                                .includeLoanAnalysisReports
                            " />
                          <label class="form-check-label" for="includeLoanAnalysisReports">Include Loan Analysis
                            Reports</label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-check">
                          <input id="usePrintFulfillment" class="form-check-input" name="usePrintFulfillment"
                            type="checkbox" [(ngModel)]="
                              recentDisclosure.requestData.usePrintFulfillment
                            " (ngModelChange)="onUsePrintFulFillmentChanged()" />
                          <label class="form-check-label" for="usePrintFulfillment">Use Print Fulfillment</label>
                        </div>
                      </div>
                    </div>


                    <div class="card" *ngIf="recentDisclosure.requestData.usePrintFulfillment">
                      <div class="card-header">Print Fulfillment</div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-6">
                            <label class="control-label">Deliver to Name</label>
                            <input class="form-control" name="printFulfillmentDeliverToName" title="{{
                                recentDisclosure.requestData
                                  .printFulfillmentDeliverToName
                              }}" [(ngModel)]="
                                recentDisclosure.requestData
                                  .printFulfillmentDeliverToName
                              " />
                          </div>
                          <div class="col-md-6">
                            <label class="control-label">Attention to Name</label>
                            <input class="form-control" name="printFulfillmentAttentionToName" title="{{
                                recentDisclosure.requestData
                                  .printFulfillmentAttentionToName
                              }}" [(ngModel)]="
                                recentDisclosure.requestData
                                  .printFulfillmentAttentionToName
                              " />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <label class="control-label">Delivery Method
                            </label>
                            <select class="form-select" name="deliveryMethod" [(ngModel)]="
                                recentDisclosure.requestData.deliveryMethod
                              ">
                              <option value="">-- Select One--</option>
                              <option *ngFor="let deliveryMethod of deliveryMethods" [ngValue]="deliveryMethod.value">
                                {{ deliveryMethod.name }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-6" *ngIf="
                              recentDisclosure.requestData.deliveryMethod ==
                              'Other'
                            ">
                            <label class="control-label">Other Description</label>
                            <input class="form-control" name="deliveryMethodOtherDescription"
                              #otherDescription="ngModel" required [(ngModel)]="
                                recentDisclosure.requestData
                                  .deliveryMethodOtherDescription
                              " [ngClass]="{
                                'is-invalid':
                                  otherDescription &&
                                  otherDescription.touched &&
                                  otherDescription.invalid
                              }" />
                            <div class="invalid-feedback">
                              Other Description is required.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row me-1">
                      <label class="control-label">Loan Plan</label>

                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <select class="form-select" title="{{ displayLoanPlanTooltip() }}" [ngClass]="{
                            'is-invalid':
                              loanPlan && loanPlan.touched && loanPlan.invalid
                          }" name="loanPlan" [(ngModel)]="recentDisclosure.requestData.loanPlanCode"
                            #loanPlan="ngModel" (ngModelChange)="handleLoanPlanChange()" required
                            [disabled]="refreshingPlans">
                            <option value="">-- Select One--</option>
                            <option *ngFor="let plan of loanPlans" [ngValue]="plan.code">
                              {{ plan.description }} ({{ plan.code }})
                            </option>
                          </select>
                          <div class="invalid-feedback">Loan plan is required.</div>
                        </div>

                        <i class="fas fa-sync ms-2 cursor" [ngClass]="{'fa-spin': refreshingPlans}"
                          title="Refresh Loan Plans from Doc Magic" (click)="onRefreshLoanPlans()"></i>
                      </div>

                    </div>
                    <div class="row my-4 mx-1" *ngIf="recentDisclosure.requestData.loanPlanCode">
                      <table class="table table-bordered dt-responsive loan-plan__table">
                        <thead class="table-light">
                          <tr>
                            <th colspan="2">Loan Plan Properties</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Loan Type</td>
                            <td>{{ selectedLoanPlan?.loanType }}</td>
                          </tr>
                          <tr>
                            <td>Rate Type</td>
                            <td>{{ selectedLoanPlan?.rateType }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </form>
            </ng-template>
            <ng-template #loading>
              <loading-indicator></loading-indicator>
            </ng-template>
          </div>
          <div *ngIf="activeStepIndexWithOffset == 2">
            <ng-template [ngIf]="!requesting" [ngIfElse]="loading">
              <!-- result - audit/error -->
              <div class="row" *ngIf="isRecentDisclosure">
                <div class="col-md-12">
                  <div class="card-body px-0">
                    <div class="fieldset-title" *ngIf="
                        recentDisclosure.disclosureResponse?.errors?.length
                      ">
                      Error Messages
                    </div>

                    <div class="p-2" *ngIf="
                        recentDisclosure.disclosureResponse?.errors?.length
                      ">
                      <disclosure-message [isAudit]="false"
                        [messages]="recentDisclosure.disclosureResponse.errors"></disclosure-message>
                    </div>

                    <div class="fieldset-title mt-2" *ngIf="
                        recentDisclosure.disclosureResponse?.auditMessages
                          ?.length
                      ">
                      Audit Messages
                    </div>

                    <div class="p-2" *ngIf="
                        recentDisclosure.disclosureResponse?.auditMessages
                          ?.length
                      ">
                      <disclosure-message [isAudit]="true" [messages]="
                          recentDisclosure.disclosureResponse.auditMessages
                        "></disclosure-message>
                    </div>

                    <div class="fieldset-title mt-2" *ngIf="
                        recentDisclosure.disclosureResponse?.documentSets
                          ?.length
                      ">
                      Document Sets
                    </div>

                    <div class="accordion" id="auditMessagesAccordion">
                      <ng-container *ngFor="
                          let docSet of recentDisclosure.disclosureResponse
                            .documentSets
                        ">
                        <disclosure-doc-set-accordion [docSet]="docSet"></disclosure-doc-set-accordion>
                      </ng-container>
                    </div>

                    <div class="p-2">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-check">
                            <input id="combineResponseDocs" class="form-check-input" name="combineResponseDocs"
                              type="checkbox" [(ngModel)]="
                                recentDisclosure.requestData
                                  .combineResponseDocuments
                              " />
                            <label class="form-check-label" for="combineResponseDocs">Combine Package into a Single
                              Document</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #loading>
              <loading-indicator></loading-indicator>
            </ng-template>
          </div>
          <div *ngIf="activeStepIndexWithOffset == 3">
            <ng-template [ngIf]="!requesting">
              <div class="fieldset-title" *ngIf="
              recentDisclosure.disclosureResponse?.errors?.length
            ">
                Error Messages
              </div>

              <div class="p-2" *ngIf="
              recentDisclosure.disclosureResponse?.errors?.length
            ">
                <disclosure-message [isAudit]="false"
                  [messages]="recentDisclosure.disclosureResponse.errors"></disclosure-message>
              </div>

              <div class="row mt-4" *ngIf="cachedDocumentSets?.length">
                <div class="col-md-12">
                  <div class="card-body px-0">
                    <div class="fieldset-title mb-2">Document Sets</div>

                    <div class="accordion" id="disclosureDocSetAccordion">
                      <ng-container
                        *ngFor="let docSet of cachedDocumentSets; let i = index">
                        <disclosure-doc-set-accordion [docSet]="docSet" [accordionOpened]="i == 0">
                        </disclosure-doc-set-accordion>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="cachedDocumentSets?.length">
                <div class="col-md-12">
                  <div class="form-check">
                    <input id="sendDisclosuresForESign" class="form-check-input" name="sendDisclosuresForESign"
                      type="checkbox" [(ngModel)]="
                        recentDisclosure.requestData
                          .shouldSendESignLinkToBorrowers
                      " (ngModelChange)="sendDisclosuresForESignChanged()" />
                    <label class="form-check-label" for="sendDisclosuresForESign">Send Disclosures for eSign</label>
                  </div>
                  <div class="form-check">
                    <input id="combineResponseDocs" class="form-check-input" name="combineResponseDocs" type="checkbox"
                      [(ngModel)]="
                        recentDisclosure.requestData
                          .combineResponseDocuments
                      " />
                    <label class="form-check-label" for="combineResponseDocs">Combine Package into a Single
                      Document</label>
                  </div>
                  <div *ngIf="!recentDisclosure.requestData.shouldSendESignLinkToBorrowers"
                    class="alert custom-alert custom-alert-warning icon-custom-alert shadow-sm my-3" role="alert">
                    <div class="media">
                      <i class="la la-exclamation-triangle alert-icon text-warning font-30 me-3 align-self-center"></i>
                      <div class="media-body h-100 d-flex align-items-center">
                        <span>Disclosures can only be sent at the same time as generating. If disclosures must be sent
                          for eSign, they will need to be regenerated</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="requesting">
              <loading-indicator></loading-indicator>
            </ng-template>
          </div>
          <div *ngIf="activeStepIndexWithOffset == 4">
            <ng-template [ngIf]="!requesting">
              <div class="fieldset-title" *ngIf="
              recentDisclosure.disclosureResponse?.errors?.length
            ">
                Error Messages
              </div>

              <div class="p-2" *ngIf="
              recentDisclosure.disclosureResponse?.errors?.length
            ">
                <disclosure-message [isAudit]="false"
                  [messages]="recentDisclosure.disclosureResponse.errors"></disclosure-message>
              </div>

              <div class="row text-center" *ngIf="isWizardCompleted">
                <h4 class="text-success">
                  Disclosures have been {{recentDisclosure.requestData.shouldSendESignLinkToBorrowers ? 'sent':
                  'generated'}} successfully
                </h4>
              </div>
              <div class="row mt-1" *ngIf="recentDisclosure?.disclosureResponse?.documentSets?.length">
                <div class="col-md-12">
                  <div class="card-body px-0">
                    <div class="fieldset-title mb-2">Document Sets</div>

                    <div class="accordion" id="disclosureDocSetAccordion">
                      <ng-container
                        *ngFor="let docSet of recentDisclosure.disclosureResponse.documentSets;let i = index">
                        <disclosure-doc-set-accordion [docSet]="docSet"
                          [accordionOpened]="i == 0"></disclosure-doc-set-accordion>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="requesting">
              <loading-indicator></loading-indicator>
            </ng-template>
          </div>
        </div>

        <div class="d-flex justify-content-center mb-2">
          <button type="button" [disabled]="atFirstStep || requesting || loadingData" class="btn btn-primary me-1"
            (click)="onBackClicked()">
            &lt; Back
          </button>
          <button class="btn btn-primary me-1" type="button" (click)="onNextClicked()"
            *ngIf="!(activeStepIndexWithOffset == 4 && isWizardCompleted)" [disabled]="
              requesting ||
              loadingData ||
              refreshingPlans ||
              thereAreNoLoanPlans ||
              (recentDisclosure.disclosureResponse?.errors?.length && [2,3,4].includes(activeStepIndexWithOffset))
            ">
            <span *ngIf="isStepRunning" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
            {{ isStepRunning ? 'Please wait...' : nextButtonLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<drawer [title]="'Disclosure Tracking Tool'" #disclosureTrackingDrawer [name]="'disclosureTrackingDrawer'"
  [templateRef]="disclosureTrackingDrawerContentRef" [options]="disclosureTrackingDrawerOptions"
  (closed)="onDisclosureTrackingDrawerClosed()">
</drawer>
<ng-template #disclosureTrackingDrawerContentRef>
  <div class="m-2">
    <disclosure-tracking [appId]="loanId" [mortgageId]="mortgage.mortgageId" [borrowers]="mortgage.borrowers"
      [usersAll]="context.globalConfig.usersAll" [timeZone]="context.currentlyLoggedInUserProfile.userProfile.timeZone"
      [refreshHistory]="refreshHistory">
    </disclosure-tracking>
  </div>
</ng-template>

<drawer [title]="'Audit Results'" #disclosureAuditResultsDrawer [name]="'disclosureAuditResultsDrawer'"
  [templateRef]="disclosureAuditResultsDrawerContentRef" [options]="disclosureAuditResultsDrawerOptions"
  (closed)="disclosureAuditResultsDrawerClosed()">
</drawer>
<ng-template #disclosureAuditResultsDrawerContentRef>
  <div class="m-2">
    <disclosure-audit-results *ngIf="recentDisclosure?.disclosureResponse?.auditMessages && !requesting"
      [messages]="recentDisclosure.disclosureResponse.auditMessages" [selectedCategories]="selectedAuditCategories"
      (refreshAudits)="onRefreshAudits($event)"></disclosure-audit-results>
    <loading-indicator *ngIf="requesting"></loading-indicator>
  </div>
</ng-template>
