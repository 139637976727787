<div [ngClass]="{'group-container-repeat': !mouseCursorOnDeleteButton,
  'group-container-repeat-pending-delete': mouseCursorOnDeleteButton}"
>
  <div
    *ngIf='event'
    class='grid-layout has-border'
  >
    <div
      *ngIf='!inEditMode'
      class='grid-layout-row'
      style='flex-direction: row-reverse;'
    >
      <a
        (click)='onDeleteClicked()'
        (mouseout)='mouseCursorOnDeleteButton=false'
        (mouseover)='mouseCursorOnDeleteButton=true'
        *ngIf='!isReadOnly'
        class='top-right-close link'
      >
        <i class='fa fa-times-circle'></i>
      </a>
    </div>

    <div class='grid-layout-row'>
      <!-- Completed Date -->
      <urla-date-input
        (blur)='onCompletionDateChanged()'
        [(ngModel)]='event.completedDate'
        [inEditMode]='inEditMode'
        [name]="'completedDate' + componentId"
        [ngClass]="{'col-6 col-sm-3': !(event.format == 'Other'), 'col-6 col-sm-2': event.format == 'Other'}"
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.counselingEvent.completedDate")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        fieldBeingEdited='mortgage.borrower.counselingEvent.completedDate'
        label='Date Completed'
      ></urla-date-input>

      <!-- Format -->
      <urla-dropdown
        [(ngModel)]='event.format'
        [inEditMode]='inEditMode'
        [name]="'format' + componentId"
        [ngClass]="{'col-6 col-sm-3': !(event.format == 'Other'), 'col-6 col-sm-2': event.format == 'Other'}"
        [options]='borrowerCounselingFormatTypes'
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.counselingEvent.format")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        fieldBeingEdited='mortgage.borrower.counselingEvent.format'
        label='What format was it in?'
      ></urla-dropdown>

      <!-- Other Description -->
      <urla-text-input
        *ngIf="event.format == 'Other'"
        [(ngModel)]='event.formatOtherDescription'
        [inEditMode]='inEditMode'
        [name]="'formatOtherDescription' + componentId"
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.counselingEvent.formatOtherDescription")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-sm-3'
        fieldBeingEdited='mortgage.borrower.counselingEvent.formatOtherDescription'
        label='Other Description'
      ></urla-text-input>

      <!-- Counseling Agency Identifier -->
      <urla-digits-input
        [(ngModel)]='event.counselingAgencyIdentifier'
        [allowedNegative]='false'
        [inEditMode]='inEditMode'
        [name]="'counselingAgencyIdentifier' + componentId"
        [ngClass]="{'col-6 col-sm-3': !(event.format == 'Other'), 'col-6 col-sm-2': event.format == 'Other'}"
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.counselingEvent.counselingAgencyIdentifier")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        fieldBeingEdited='mortgage.borrower.counselingEvent.counselingAgencyIdentifier'
        label='Agency ID'
      ></urla-digits-input>

      <!-- Counseling Agency Name -->
      <urla-text-input
        [(ngModel)]='event.counselingAgencyName'
        [inEditMode]='inEditMode'
        [name]="'agencyName' + componentId"
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.counselingEvent.agencyName")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-sm-3'
        fieldBeingEdited='mortgage.borrower.counselingEvent.agencyName'
        label='Agency Name'
      ></urla-text-input>
    </div>

    <div class='grid-layout-row'>
      <!-- Counseling Agency Address 1 -->
      <urla-street-address
        [(ngModel)]="event.counselingAgencyAddress1"
        [inEditMode]="inEditMode" 
        [name]="'counselingAgencyAddress1' + componentId"
        [readonly]="isReadOnly"
        [required]='isRequired("mortgage.borrower.counselingEvent.counselingAgencyAddress1")'
        [urlaFieldsConfig]="urlaFieldsConfig" 
        [zipcodeLookupEnabled]="true"
        class="col-12 col-lg-6"
        fieldBeingEdited='mortgage.borrower.counselingEvent.counselingAgencyAddress1'
        label='Agency Address 1'
        (addressChanged)="onCounselingAgencyAddressChanged($event)">
      </urla-street-address>

      <!-- Counseling Agency Address 2 -->
      <urla-text-input
        [(ngModel)]='event.counselingAgencyAddress2'
        [inEditMode]='inEditMode'
        [name]="'counselingAgencyAddress2' + componentId"
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.counselingEvent.counselingAgencyAddress2")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-12 col-lg-6'
        fieldBeingEdited='mortgage.borrower.counselingEvent.counselingAgencyAddress2'
        label='Agency Address 2'
      ></urla-text-input>
    </div>

    <div class='grid-layout-row'>
      <!-- Counseling Agency City -->
      <urla-text-input
        [(ngModel)]='event.counselingAgencyCity'
        [inEditMode]='inEditMode'
        [name]="'counselingAgencyCity' + componentId"
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.counselingEvent.counselingAgencyCity")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-lg-4'
        fieldBeingEdited='mortgage.borrower.counselingEvent.counselingAgencyCity'
        label='Agency City'
      ></urla-text-input>

      <!-- Counseling Agency State -->
      <urla-dropdown
        [(ngModel)]='event.counselingAgencyState'
        [inEditMode]='inEditMode'
        [name]="'counselingAgencyState' + componentId"
        [options]='stateOptions'
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.counselingEvent.counselingAgencyState")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-lg-4'
        fieldBeingEdited='mortgage.borrower.counselingEvent.counselingAgencyState'
        label='Agency State'
      ></urla-dropdown>

      <!-- Counseling Agency Zip Code -->
      <urla-text-input
        [(ngModel)]='event.counselingAgencyZipCode'
        [inEditMode]='inEditMode'
        [name]="'counselingAgencyZipCode' + componentId"
        [readonly]='isReadOnly'
        [required]='isRequired("mortgage.borrower.counselingEvent.counselingAgencyZipCode")'
        [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-6 col-lg-4'
        fieldBeingEdited='mortgage.borrower.counselingEvent.counselingAgencyZipCode'
        label='Agency Zip Code'
      ></urla-text-input>
    </div>
  </div>
</div>
