import { DocFile } from './doc-file.model';

export class LoanDoc {
  loanDocId: number;
  losLoanDocId?: string;
  voeHistoryId?: number;
  applicationId: number;
  borrowerId?: number;
  documentTypeId: number;
  title?: string;
  description: string;
  note: string;
  expirationDate: string;
  retask?: boolean;
  active?: boolean;
  docFiles: DocFile[];
  companyId?: number;
  insertedBy?: string;
  dateInserted?: string;
  updatedBy?: string;
  dateUpdated?: string;
  checked?: boolean;

  constructor(applicationId: number) {
    this.applicationId = applicationId;
    this.docFiles = [];
  }
}