<form #homeownersInsuranceDetailsForm="ngForm" novalidate id="homeownersInsuranceDetailsForm"
  name="homeownersInsuranceDetailsForm" class="p-3">

  <div class="row mb-5">
    <div class="row">
      <div class="form-group col-sm-6 col-xs-6">
        <label for="coverageAmount" class="label-tax"> Coverage Amount Rate </label>
        <percent-input name="coverageAmount" id="coverageAmount" [(ngModel)]="homeownersInsurance.coverageAmount"
          [digitsInfo]="'1.3-3'">
        </percent-input>
      </div>
      <div class="form-group col-sm-6 col-xs-6">
        <label for="coverageAmountDollar"> Coverage Amount Dollar </label>
        <currency-input name="coverageAmountDollar" id="coverageAmountDollar"
          [(ngModel)]="coverageAmountDollar"></currency-input>
      </div>
      <div class="form-group col-sm-6 col-xs-6">
        <label for="expirationDate"> Expiration Date </label>
        <date-input name="expirationDate" id="expirationDate" [(ngModel)]="expirationDate">
        </date-input>
      </div>
      <div class="form-group col-sm-6 col-xs-6">
        <label for="nextPremiumDueDate"> Next Premium Due Date </label>
        <date-input name="nextPremiumDueDate" id="nextPremiumDueDate" [(ngModel)]="nextPremiumDueDate">
        </date-input>
      </div>
      <div class="form-group col-sm-6 col-xs-6">
        <label for="policyCancellationDate"> Policy Cancellation Date </label>
        <date-input name="policyCancellationDate" id="policyCancellationDate" [(ngModel)]="policyCancellationDate">
        </date-input>
      </div>
      <div class="form-group col-sm-6 col-xs-6">
        <label for="policyIdentifier"> Policy Identifier </label>
        <input class="form-control" type="text" name="policyIdentifier" id="policyIdentifier"
          [(ngModel)]="homeownersInsurance.policyIdentifier">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-end">
      <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
      <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
        Save
      </button>
    </div>
  </div>
</form>