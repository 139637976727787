<div [ngClass]="{'group-container-repeat': !mouseCursorOnDeleteButton,
  'group-container-repeat-pending-delete': mouseCursorOnDeleteButton}"
>
  <div class='grid-layout'>
    <div class='grid-layout-row'>
      <button
        (click)='onClickDeleteButton()'
        (mouseout)='onMouseOutDeleteButton()'
        (mouseover)='onMouseOverDeleteButton()'
        *ngIf='!isReadOnly'
        class='btn top-right-close link'
        type='button'
      >
        <i class='fa fa-times-circle'></i>
      </button>
    </div>

    <ng-content></ng-content>
  </div>
</div>
