import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from 'src/app/core/services/utils';
import { AgentTableConfig } from 'src/app/modules/agents/components/agent-table/agent-table-config.model';
import { AgentTableComponent } from 'src/app/modules/agents/components/agent-table/agent-table.component';
import { AgentForTable } from 'src/app/modules/agents/models/agent-for-table.model';
import { Agent } from 'src/app/modules/app-details/models/agent.model';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { RowHeight } from 'src/app/shared/models/table-config.model';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { RemainContactService } from '../../services/remain-contact.service';
import { SelectAgentTypeDialogComponent } from './select-agent-type-dialog/select-agent-type-dialog.component';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Subscription } from 'rxjs';
import { AgentType } from 'src/app/modules/admin/company/models/agent-type.model';

@Component({
  templateUrl: './remain-contact.component.html',
  styleUrls: ['./remain-contact.component.scss']
})
export class RemainContactComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild("agentTable")
  agentTable: AgentTableComponent;

  appId: number;
  agentId: number = 0;

  agents: Agent[] = [];

  protected agentType: AgentType;

  agentTableConfig: AgentTableConfig;

  addAgentsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  loadingContacts: boolean;

  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _remainContactService: RemainContactService,
    private readonly _router: Router,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService,
    private readonly _drawerService: DrawerService,
    public _datePipe: DatePipe
  ) {
    super(injector);

    let navigation = this._router.getCurrentNavigation();
    if (navigation && navigation.extras.state) {
      this.agentType = navigation.extras.state.agentType;
    }
  }

  ngOnInit(): void {
    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          this.appId = context.application.applicationId;
          this._getAllRemainExternalContacts();
        }
      });
    } else {
      this.appId = this.applicationContext.application.applicationId;

      this._getAllRemainExternalContacts();
    }

    this.agentTableConfig = {
      columns: [
        { field: 'displayName', header: 'Name', order: 1, visible: true },
        { field: 'portalStatus', header: 'Portal Status', order: 2, visible: true },
        { field: 'contactInfo', header: 'Contact Info', order: 3, visible: true },
        { field: 'orgName', header: 'Company Name', order: 4, visible: true },
        { field: 'assistantName', header: 'Assistant Name / Info', order: 5, visible: true },
        { field: 'agentContactUserName', header: 'Agent Contact', order: 7, visible: true },
        { field: 'agentListNames', header: 'Tags', order: 8, visible: true },
        { field: 'dateCreated', header: 'Date Created', order: 9, visible: true },
      ],
      rowHeight: RowHeight['70px'],
      forAgent: false,
      forRemainContact: true,
      isButtonsVisible: true,
      extraGlobalFilterFields: ['fullName', 'tags', 'email', 'mobilePhone', 'homePhone'],
      scrollable: true
    };
  }

  ngOnDestroy() {
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onCancelClicked() {
    this._router.navigate(['admin/app-details/' + this.appId + '/external-contacts']);
  }

  onNewAgentEditorOpened = () => {
    this._drawerService.show("addAgentEditorDrawer", 10);
  }

  onNewAgentEditorClosed = () => {
    this._drawerService.hide("addAgentEditorDrawer", 10);
  }

  onBackClicked = () => {
    this._router.navigate(['admin/app-details/' + this.appId + '/external-contacts']);
  }

  viewSelectAgentTypeModal = (agentId: number) => {
    const modalRef = this._modalService.open(SelectAgentTypeDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.agentId = agentId;
    modalRef.componentInstance.selectingForConversion = false;
    modalRef.componentInstance.showRedirectToLoanButton = true;

    modalRef.result.then((result) => {
      if (result == 'cancel') return;

      this._remainContactService.selectAgentForExternalContact(this.appId, agentId, result.associateAgentAs).subscribe({
        next: (response) => {
          this._notifyService.showSuccess("Select External Contact Success", "Success");

          if (result.redirectToLoan) {
            this._router.navigate(['admin/app-details/' + this.appId + '/external-contacts']);
          }

          const index = this.agents.findIndex(agent => agent.agentId == agentId);
          this.agents.splice(index, 1);
        },
        error: (error) => {
          this._notifyService.showError(error ? error.message || error : "Select External Contact Fail", "Failure");
        }
      });
    })
  }

  onSelectAssociatedAgent = (agentId: number) => {
    this._remainContactService.selectAgentForExternalContact(this.appId, agentId, this.agentType.agentTypeId).subscribe({
      next: (response) => {
        this._notifyService.showSuccess("Select External Contact Success", "Success");
        this._router.navigate(['admin/app-details/' + this.appId + '/external-contacts']);
        const index = this.agents.findIndex(agent => agent.agentId == agentId);
        this.agents.splice(index, 1);
      },
      error: (error) => {
        this._notifyService.showError(error ? error.message || error : "Select External Contact Fail", "Failure");
      }
    });
  }

  onAgentEditedOrUpdated = (agent: AgentForTable) => {
    this.viewSelectAgentTypeModal(agent.agentId);

    agent['displayName'] = Utils.getPersonsDisplayName(agent);
    agent['fullName'] = Utils.getPersonsFullName(agent);
    //for export to csv we need the table source to contain the data. export does not work with html manipulated data
    agent['dateCreated'] = this._datePipe.transform(agent.dateInserted, 'short');
    agent['contactInfo'] = '';
    if (agent.mobilePhone) {
      agent['contactInfo'] = `M: ${agent.mobilePhone} `;
    }
    if (agent.email) {
      agent['contactInfo'] += `Email: ${agent.email}`;
    }
    if (agent.userProfile && agent.userProfile.confirmationDate) {
      agent['portalStatus'] = 'Live';
    } else if (agent.userProfile && !agent.userProfile.confirmationDate) {
      agent['portalStatus'] = 'Not Confirmed';
    } else if (!agent.userProfile) {
      agent['portalStatus'] = 'Not Invited';
    }

    this.agentTable.addAgent(agent);
  }

  private _getAllRemainExternalContacts = () => {
    this.loadingContacts = true;
    this._remainContactService.getAllRemainExternalContacts(this.appId).subscribe({
      next: (response) => {
        this.agents = response;
        this.agents.forEach(agent => {
          agent['displayName'] = Utils.getPersonsDisplayName(agent);
        })
      },
      error: (error) => {
        this._notifyService.showError(error ? error.message || error : "", "Error");
      }
    }).add(() => this.loadingContacts = false);
  }

}
