<div class="grid-layout has-border" *ngIf="liabilities; else loading">
    <div class="grid-header mb-1">
        <div class="grid-header-left">
            <div>2c. Liabilities - Credits, Debts, and Leases</div>
        </div>
        <div class="grid-header-right">
            <a class="btn btn-sm btn-soft-primary" role="button" (click)="addLiability()" *ngIf="!isReadOnly && !thereIsAtLeastOneInvalidLineItem && !inEditMode">
                <i class="fas fa-plus me-2"></i>Add Liability
            </a>
        </div>
    </div>
    <div class="row mb-2">
      <div style="border-bottom: none;">
            <table class="table mb-0">
                <thead class="table-light">
                    <tr>
                        <td class="col-1">Account Owner</td>
                        <td class="col-2">Account Type</td>
                        <td class="col-2">Creditor</td>
                        <td class="col-1">Account #</td>
                        <td class="col-1">REO</td>
                        <td class="col-1">MO Pmt</td>
                        <td class="col-1">MO Left</td>
                        <td class="col-1">Unpaid Bal</td>
                        <td class="col-1">Payoffs</td>
                        <td class="col-1 text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let liability of liabilities; let index = index;">
                        <td class="col-1">
                            <div class="owningBorrowerIds-readonly"
                                [ngClass]="{ 'ng-invalid-requested no-border' : liability.owningBorrowerIds.length === 0 }"
                                [ngStyle]="{height: liability.owningBorrowerIds.length === 0 ? '22px' : 'auto'}">
                                <span *ngFor="let owner of liability['owners']">{{owner.name}}<br></span>
                            </div>
                        </td>
                        <td class="col-2">
                            <urla-dropdown [(ngModel)]="liability.typeOfLiability" (change)="accountTypeOrBorrowerChange(liability)"
                                [options]="liabilityTypeOptions" name="typeOfLiability{{liability.liabilityId}}"
                                [readonly]="true" [showBorder]="false"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.liability.typeOfLiability'"
                                [required]="urlaFieldsConfig['mortgage.liability.typeOfLiability']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.liability.typeOfLiability']?.required == 'requested'">
                            </urla-dropdown>
                        </td>
                        <td class="col-2">
                            <urla-text-input name="holderName{{liability.liabilityId}}" [(ngModel)]="liability.holderName"
                                [readonly]="true" [showBorder]="false"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.liability.holderName'"
                                [required]="urlaFieldsConfig['mortgage.liability.holderName']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.liability.holderName']?.required == 'requested'">
                            </urla-text-input>
                        </td>
                        <td class="col-1">
                            <urla-text-input name="accountNumber{{liability.liabilityId}}" [(ngModel)]="liability.displayAccountNumber"
                                [readonly]="true" [showBorder]="false"
                                [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.liability.accountNumber'"
                                [required]="urlaFieldsConfig['mortgage.liability.accountNumber']?.required == 'required' ||
                                urlaFieldsConfig['mortgage.liability.accountNumber']?.required == 'requested'">
                            </urla-text-input>
                        </td>

                        <td class="col-1">
                            <urla-dropdown [(ngModel)]="liability.reoId" name="reoReadOnly{{liability.liabilityId}}"
                              [options]="possibleReoAddresses[liabilities.indexOf(liability)]"
                              [readonly]="true" [showBorder]="false">
                            </urla-dropdown>
                        </td>

                        <td class="col-1">
                            <urla-currency-input name="monthlyPayment{{liability.liabilityId}}" [(ngModel)]="liability.monthlyPayment"
                            [readonly]="true" [showBorder]="false" [isOmitted]="liability.isExcluded || liability.payoffType == 'Full'"
                            [urlaFieldsConfig]="urlaFieldsConfig" [fieldBeingEdited]="'mortgage.liability.monthlyPayment'"
                            [required]="liability.unpaidBalance && liability.unpaidBalance > 0 && liability.typeOfLiability != liabilityTypeCollections">
                            </urla-currency-input>
                        </td>
                        <td class="col-1">
                            <urla-digits-input name="monthsLeftToPay{{liability.liabilityId}}" [(ngModel)]="liability.monthsLeftToPay"
                            [readonly]="true" [showBorder]="false"></urla-digits-input>
                        </td>
                        <td class="col-1">
                            <urla-currency-input name="unpaidBalance{{liability.liabilityId}}" [(ngModel)]="liability.unpaidBalance"
                            [readonly]="true" [showBorder]="false" [isOmitted]="liability.isExcluded || liability.payoffType == 'Full'"></urla-currency-input>
                        </td>
                        <td class="col-1">
                            <urla-currency-input name="partialPayoffAmount{{liability.liabilityId}}" [(ngModel)]="liability.partialPayoffAmount"
                            [readonly]="true" [showBorder]="false" [isOmitted]="liability.isExcluded || liability.payoffType == 'Full'"></urla-currency-input>
                        </td>
                        <td class="col-1">
                            <div class="action" *ngIf="!isReadOnly">
                                <a title="Payoff Options" href="javascript: void(0);" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
                                 [ngClass]="{'dollar-color': liability.payoffType != 'None'}" *ngIf="liability.unpaidBalance" (click)="setMonthlyPaymentOptions(liability)">
                                    <i class="fa fa-dollar-sign"></i>
                                </a>
                                <div class="dropdown-menu" style="z-index: 9999;">
                                    <div class="dropdown-submenu">
                                      <a class="dropdown-item dropdown-toggle w-100">
                                        Set Payoff
                                        <i class="fas fa-caret-right ps-1 pull-right"></i>
                                      </a>
                                      <div class="dropdown-menu">
                                        <a class="dropdown-item w-100" role="button" (click)="setPayoffAmountToFull(liability)">Full</a>
                                        <a class="dropdown-item w-100" role="button" (click)="openPayoffDialog(liability)">Partial</a>
                                        <a class="dropdown-item w-100" role="button" (click)="setPayoffAmountToNone(liability)">None</a>
                                      </div>
                                    </div>
                                    <div class="dropdown-submenu">
                                      <a class="dropdown-item dropdown-toggle w-100">
                                        Monthly Payment
                                        <i class="fas fa-caret-right ps-1 pull-right"></i>
                                      </a>
                                      <div class="dropdown-menu">
                                        <a class="dropdown-item w-100" role="button" *ngFor="let option of monthlyPaymentOptions;" (click)="setMonthlyPayment(liability, option.value)">
                                          {{option.displayText}}
                                        </a>
                                      </div>
                                    </div>
                                </div>

                                <a title="Edit Liability" href="javascript: void(0);" *ngIf="!thereIsAtLeastOneInvalidLineItem" (click)="editLiability(liability, index)"><i class="fa fa-pencil-alt"></i></a>
                                <a title="Omit Liability from Calculations" href="javascript: void(0);" *ngIf="!liability.isExcluded" (click)="exclude(liability, true)"><i class="fa fa-ban"></i></a>
                                <a title="Include Liability in Calculations" href="javascript: void(0);" *ngIf="liability.isExcluded" (click)="exclude(liability, false)"><i class="fa fa-ban red-icon"></i></a>

                                <a title="Delete Liability" href="javascript: void(0);" (click)="deleteLiability(index)"><i class="fa fa-trash-alt"></i></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-end">Sub Total </td>
                        <td><strong>{{financialMonthlyPaymentSubTotal | currency }}</strong></td>
                        <td></td>
                        <td><strong>{{financialUnpaidBalanceSubTotal | currency }}</strong></td>
                        <td><strong>{{financialPartialPayoffAmount | currency }}</strong></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #loading>
    <loading-indicator [loadingMessage]="'Loading liabilities...'">
    </loading-indicator>
  </ng-template>

<drawer #addEditLiabilityDrawer [title]="'Liability'"
    [name]="'addEditLiabilityDrawer'" [templateRef]="addEditLiabilityRef" [options]="addEditLiabilityDrawerOptions">
</drawer>

<ng-template #addEditLiabilityRef>
    <add-edit-liability
      [liability]="editedLiability"
      [possibleReoAddresses]="possibleReoAddresses[liabilities.indexOf(editedLiability)]"
      [liabilityTypeOptions]="liabilityTypeOptions"
      [possibleAccountOwners]="possibleAccountOwners"
      [mortgage]="mortgage"
      (saveLiability)="saveLiability($event)"
      (closeDrawer)="closeDrawer()">
    </add-edit-liability>
</ng-template>
