import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { EnumerationItem } from '../../../../../../../models/simple-enum-item.model';
import { Income, TypeOfIncomeEnum } from '../../../../../../../models';
import { Constants, Enumerations } from '../../../../../../../services/constants';
import { EnumerationService } from '../../../../../../../services/enumeration-service';
import { firstValueFrom, map, race, startWith, Subject, Subscription } from 'rxjs';
import { NgForm, NgModel, ValidationErrors } from '@angular/forms';
import { PayPeriod } from '../../../../../../leads/models/lead-employment.model';
import { LeadUtils } from '../../../../../../leads/services/utils';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { resetObjectTo } from '../reset-object.to';
import * as _ from 'lodash';
import { autoId } from '../../../../../../../core/services/utils';
import calculateMonthlyAmountByPayPeriod = LeadUtils.calculateMonthlyAmountByPayPeriod;

type FormValue = Partial<Income>;

@Component({
  selector: 'qa-fi-income-editor',
  templateUrl: './qa-fi-income-editor.component.html',
  styleUrls: ['./qa-fi-income-editor.component.scss'],
})
export class QaFiIncomeEditorComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * The income object to edit. Can be omitted if the component is used
   * to create a new income. This will not be modified by the component.
   * Instead, the component will emit a new partial income object when the
   * form is saved.
   */
  @Input() income?: Income;
  private _originalIncome?: Income;

  @Input() externalError?: string | null;

  @Output() readonly close = new EventEmitter<void>();

  @Output() readonly cancel = new EventEmitter<void>();

  @Output() readonly update = new EventEmitter<Partial<Income>>();

  @ViewChild('form') formElement: NgForm;

  protected readonly PayPeriod = PayPeriod;
  private readonly _id: string = `qa-fi-income-editor-${autoId()}`;

  protected typeOfIncomeOptions: EnumerationItem[] = [];
  protected payPeriodItems: EnumerationItem[] = [];

  private _save$ = new Subject<void>();

  /**
   * The monthly income amount. This is calculated from the amount and pay
   * period fields.
   */
  private _monthlyIncomeSubscription?: Subscription;
  private _savingChangesSubscription?: Subscription;

  /**
   * The that was set before the pay period was changed from hourly.
   * This is used to restore the value when the pay period is changed back to
   * hourly.
   */
  private _previousHoursPerWeek: number = 0;

  private _destroyed$: Subject<void> = new Subject<void>();

  constructor(
    private readonly _enumService: EnumerationService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.initEnumerations();

    if (this.income == null) {
      this.setIncome();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const incomeChange = changes.income;

    if (incomeChange != null) {
      this.setIncome(incomeChange.currentValue);
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private async initEnumerations(): Promise<void> {
    const enums: Enumerations = await
      firstValueFrom(this._enumService.getMortgageEnumerations());

    this.initIncomeTypeItems(enums);
    this.initPayPeriodItems();
  }

  private initIncomeTypeItems(enums: Enumerations): void {
    const invalidIncomeTypes = new Set<TypeOfIncomeEnum>([
      TypeOfIncomeEnum.Base,
      TypeOfIncomeEnum.Bonus,
      TypeOfIncomeEnum.Commissions,
      TypeOfIncomeEnum.Overtime,
      TypeOfIncomeEnum.MilitaryBasePay,
      TypeOfIncomeEnum.MilitaryRationsAllowance,
      TypeOfIncomeEnum.MilitaryFlightPay,
      TypeOfIncomeEnum.MilitaryHazardPay,
      TypeOfIncomeEnum.MilitaryClothesAllowance,
      TypeOfIncomeEnum.MilitaryQuartersAllowance,
      TypeOfIncomeEnum.MilitaryPropPay,
      TypeOfIncomeEnum.MilitaryOverseasPay,
      TypeOfIncomeEnum.MilitaryCombatPay,
      TypeOfIncomeEnum.MilitaryVariableHousingAllowance,
    ]);

    this.typeOfIncomeOptions = enums[Constants.enumerations.incomeType]
      .filter(({value}) => !invalidIncomeTypes.has(value as TypeOfIncomeEnum));
  }

  private initPayPeriodItems(): void {
    this.payPeriodItems = this._enumService.getIncomePayPeriods();
  }

  private setIncome(income?: Income): void {
    // Edit or create new income mode.
    const isEditMode = income != null;

    income ??= new Income();
    income.hoursPerWeek ??= 0;
    income.payPeriod ??= PayPeriod.Monthly;
    income.amount ??= income.amount ?? income.monthlyIncome ?? 0;

    this.income = income;
    this._originalIncome = _.cloneDeep(this.income);

    setTimeout(() => {
      if (isEditMode) {
        this.formElement.form.markAllAsTouched();
      }

      this.subscribeToFormChanges();

      this.update.emit(this.income);
    });
  }

  private subscribeToFormChanges() {
    this.subscribeToMonthlyIncome();
    this.subscribeToSavingChanges();
  }

  private subscribeToMonthlyIncome(): void {
    const form = this.formElement.form;

    this._monthlyIncomeSubscription?.unsubscribe();
    this._monthlyIncomeSubscription = form.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        startWith(null),
      ).subscribe(() => this.resetMonthlyIncome(form.value));
  }

  private subscribeToSavingChanges(): void {
    this._savingChangesSubscription?.unsubscribe();
    const subscription = this._savingChangesSubscription = this.formElement.valueChanges.pipe(
      takeUntil(this._destroyed$),
    ).subscribe(() => {
      this.enqueueSave();
    });

    const cancelOrDestroy$ = race(
      this._destroyed$.pipe(map(() => true)),
      this.cancel.pipe(map(() => false)),
    ).pipe(
      tap((shouldSave) => {
        if (shouldSave) {
          // Prevent ExpressionChangedAfterItHasBeenCheckedError.
          setTimeout(() => {
            this.save();
          });
        }
      }),
    );

    subscription.add(
      this._save$.pipe(
        takeUntil(cancelOrDestroy$),
        debounceTime(200),
      ).subscribe(() => {
        this.save();
      }),
    );
  }

  /**
   * Re-calculates and sets the monthly income amount.
   */
  private resetMonthlyIncome(
    { amount, payPeriod, hoursPerWeek }: FormValue,
  ): void {
    this.income.monthlyIncome = calculateMonthlyAmountByPayPeriod(
      amount,
      payPeriod,
      hoursPerWeek,
    );
  }

  protected onChangePayPeriod(value: PayPeriod): void {
    if (value === PayPeriod.Hourly) {
      this.setHoursPerWeek(this._previousHoursPerWeek);
    } else {
      this._previousHoursPerWeek = this.income.hoursPerWeek ?? 0;
      this.setHoursPerWeek(0);
    }
  }

  /**
   * Sets the hoursPerWeek field to the specified value and updates the
   * validity of the control.
   */
  private setHoursPerWeek(value: number): void {
    const hoursPerWeekControl = this.formElement.form.get('hoursPerWeek');
    hoursPerWeekControl.patchValue(value);
    hoursPerWeekControl.updateValueAndValidity();
  }

  protected isInvalid(control: NgModel): boolean {
    return control != null && control.invalid && control.touched;
  }

  protected getErrors(control: NgModel): ValidationErrors | null {
    return control?.errors ?? null;
  }

  protected onClickCancel(): void {
    this.resetIncomeChanges();

    this.cancel.emit();
  }

  protected onClickClose(): void {
    this.close.emit();
  }

  private enqueueSave(): void {
    this._save$.next();
  }

  private save(): void {
    const form = this.formElement.form;
    form.markAllAsTouched();

    // amount and payPeriod are not real Income fields, so we need to omit them
    // from the form value.
    const { typeOfIncome, monthlyIncome, hoursPerWeek } = this.income;
    const value: Partial<Income> = {
      typeOfIncome,
      monthlyIncome,
      hoursPerWeek,
    };

    this.update.emit(value);
  }

  private resetIncomeChanges(): void {
    const income = this.income;
    if (income == null) {
      console.error('Cannot reset changes as the original income is null.');
      return;
    }

    const originalIncome = this._originalIncome;
    if (originalIncome == null) {
      console.error('Cannot reset changes as the original income is null.');
      return;
    }

    resetObjectTo(income, originalIncome);
  }

  protected id(elementId: string): string {
    return `${this._id}-${elementId}`;
  }
}
