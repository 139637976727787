import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Select2OptionData } from 'ng-select2';
import { finalize } from 'rxjs/operators';
import { FormFreeHistory, MortgageBorrower } from 'src/app/models';
import { VoaAssetReadonlyArray } from 'src/app/models/voa/voa-asset.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { LoanService, LoanServicesService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'voa-import-assets',
  templateUrl: 'voa-import-assets.component.html',
})
export class VoaImportAssetsComponent implements OnInit {

  @Input()
  order: FormFreeHistory;

  loading = false;
  importingAssets = false;
  borrowers: Array<MortgageBorrower> = [];
  assets: VoaAssetReadonlyArray = [];
  mulstiSelectData: Array<Select2OptionData> = [];

  selectedBorrowers: { [key: string]: Array<string> } = {};

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
  };

  private setBorrowersMultiselect() {
    this.mulstiSelectData = this.borrowers.map((borrower) => ({
      id: `${borrower.borrowerId}`,
      text: `${borrower.firstName} ${borrower.lastName}`,
    }));
  }

  private getData = () => {
    this.loading = true;
    this._loanServicesService
      .getAssets(this.order.accountChekOrderId, this.order.latestReportId, this.order.applicationId)
      .subscribe({
        next: (assets) => {
          this.assets = assets;
          this.assets.forEach(asset => {
            this.selectedBorrowers[asset.accountId] = asset.borrowerId ? [asset.borrowerId + ""] : [];
          })
          this._loanSevice
            .getMortgage(this.order.applicationId)
            .pipe(finalize(() => this.loading = false))
            .subscribe({
              next: (mortgage) => {
                this.borrowers = mortgage.borrowers;
                this.setBorrowersMultiselect();
              },
              error: (error) => {
                this._notificationService.showError(
                  error?.message || 'Unable to get mortgage info.',
                  'VOA Loan Service'
                );
              }
            });
        },
        error: (error) => {
          this.loading = false;
          this._notificationService.showError(
            error?.message || 'Unable to load data',
            'VOA Loan Service'
          );
        }
      })
  };

  isImportEnabled = () => {
    return this.assets.some((asset) => asset.isChecked);
  };

  importAssets = () => {
    const checkedAssets = this.assets.filter((asset) => asset.isChecked);
    const request = {};

    checkedAssets.forEach((d) => {
      if (this.selectedBorrowers[d.accountId]) {
        request[`${d.accountId}`] = this.selectedBorrowers[d.accountId];
      }
    });

    if (_.isEmpty(request) || Object.values(request)?.some((val: any) => val?.length === 0)) {
      return this._notificationService.showInfo("Please select at least one borrower for selected asset(s).", "Info");
    }

    this.importingAssets = true;
    this._loanServicesService
      .importAssets(
        this.order.accountChekOrderId,
        this.order.latestReportId,
        this.order.applicationId,
        request
      )
      .pipe(finalize(() => {
        this.importingAssets = false;
        this._contextService.applicationTrackingStatusesChanged();
      }))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Assets imported successfully',
            'VOA Loan Service'
          );
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message || 'Unable to import assets',
            'VOA Loan Service'
          );
        }
      });
  };

  ngOnChanges() {
    this.getData();
  }

  constructor(
    private readonly _loanSevice: LoanService,
    private readonly _loanServicesService: LoanServicesService,
    private readonly _contextService: ApplicationContextService,
    private readonly _notificationService: NotificationService
  ) {}

  ngOnInit() {}
}
