import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import Swal from 'sweetalert2';
import { Address, DocPrepTrustParty, MortgageBorrower, ResidencyAddress, ResidencyType } from 'src/app/models';
import { v4 as uuidv4 } from 'uuid';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { Utils } from 'src/app/core/services/utils';
import { MortgageSigner } from 'src/app/modules/loan-docs/models/external-contact.model';

@Component({
  selector: 'document-preparation-borrower-v2',
  templateUrl: 'document-preparation-borrower-v2.component.html',
  styleUrls: ['./document-preparation-borrower-v2.component.scss']
})

export class DocumentPreparationBorrowerV2Component implements OnInit {

  @Output()
  deleteBorrowerClicked: EventEmitter<MortgageBorrower> = new EventEmitter<MortgageBorrower>();

  @Input()
  borrower: MortgageBorrower;

  @Input()
  borrowerTypes: EnumerationItem[] = [];

  @Input()
  borrowerRoles: EnumerationItem[] = [];

  @Input()
  entityTypes: EnumerationItem[] = [];

  @Input()
  suffixes: EnumerationItem[] = [];

  @Input()
  maritalStatuses: EnumerationItem[] = [];

  @Input()
  index: number;

  protected uniqueId: string;
  protected states: EnumerationItem[] = [];

  mailingAddress: ResidencyAddress = new ResidencyAddress();

  constructor(private readonly _enumsService: EnumerationService) {
    this.uniqueId = uuidv4();
  }

  ngOnInit() {
    this.states = this._enumsService.states;
    this.mailingAddress = this.borrower.residencyAddresses.find(ra => ra.residencyType === ResidencyType.MailingAddress);

    if (!this.mailingAddress) {
      this.mailingAddress = new ResidencyAddress();
      this.mailingAddress.residencyType = ResidencyType.MailingAddress;
      this.borrower.residencyAddresses.push(this.mailingAddress);
    }
  }

  onDeleteBorrowerClicked = () => {
    this.deleteBorrowerClicked.emit(this.borrower);
  }

  protected onAddSignerClicked = () => {
    let newSigner = new MortgageSigner();
    if (!this.borrower.signers) {
      this.borrower.signers = [];
    }
    this.borrower.signers.push(newSigner);
  }

  protected onBorrowerTypeChanged = () => {
    if (this.borrower.mortgagePartyType !== 'Entity' && this.borrower.signers?.length) {
      this.borrower.signers = [];
    }
  }

  protected onDeleteSignerClicked = (index: number) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this signer?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        if (index >= 0) {
          self.borrower.signers.splice(index, 1);
        }
      }
    });
  }

  protected onAddTrustPartyClicked = () => {
    let newTrustParty = new DocPrepTrustParty();
    if (!this.borrower.trustParties) {
      this.borrower.trustParties = [];
    }
    this.borrower.trustParties.push(newTrustParty);
  }

  protected onPOAChanged = (isChecked: boolean) => {
    this.borrower.powerOfAttorney = isChecked;
  }

  protected onDeleteTrustPartyClicked = (index: number) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this trust party?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        if (index >= 0) {
          self.borrower.trustParties.splice(index, 1);
        }
      }
    });
  }

  protected handleAddressChange(e: Partial<Address>): void {
    const mailingAddress = this.mailingAddress.address;
    mailingAddress.address1 = ''; // to reset the last populated address.

    setTimeout(() => {
      mailingAddress.address1 = e.address1;
      mailingAddress.city = e.city;
      mailingAddress.state = e.state;
      mailingAddress.zipCode = e.zipCode;
    }, 200);
  }

  protected onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.mailingAddress.address.state = zipCode.state.toLowerCase();
      this.mailingAddress.address.city = Utils.toTitleCase(zipCode.city);
      this.mailingAddress.address.zipCode = zipCode.zipcode;
      this.mailingAddress.address.country = 'us';
    }
  }
}
