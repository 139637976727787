import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {DateTime} from 'luxon';
import {AusData} from 'src/app/models';
import {EnumerationItem} from 'src/app/models/simple-enum-item.model';
import {MortgageAppliedForTypeEnum} from 'src/app/modules/app-details/components/title-history/models/title-order.model';
import {ApplicationContextService} from 'src/app/services/application-context.service';
import {LoanService} from 'src/app/services/loan';
import {UrlaMortgage} from '../../models/urla-mortgage.model';

@Component({
  selector: 'urla-arm-features',
  templateUrl: './urla-arm-features.component.html',
  styleUrls: ['./urla-arm-features.component.scss'],
})
export class UrlaArmFeaturesComponent implements OnInit {
  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  set mortgage(value: UrlaMortgage) {
    this._mortgage = value;
    this.ausData = _.cloneDeep(value.ausData);
  }

  @Input()
  urlaFieldsConfig: {};

  @Input()
  armIndexTypes: EnumerationItem[] = [];

  @Input()
  armIndexSourceTypes: EnumerationItem[] = [];

  @Input()
  inEditMode: boolean = false;

  @Input()
  isReadOnly: boolean = false;

  @Output()
  changesApproved: EventEmitter<AusData> = new EventEmitter<AusData>();

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  interestChangeDateError: string = null;

  ausData: AusData;

  private _mortgage: UrlaMortgage;

  constructor(
    private readonly _ctxService: ApplicationContextService,
    private readonly _loanService: LoanService
  ) {}

  ngOnInit(): void {
    this.onInterestChangeDateControl();

    this._ctxService.keyDatesChanges.subscribe(() => {
      this.onInterestChangeDateControl();
    });
  }

  protected onOkClicked() {
    this.changesApproved.emit(this.ausData);
  }

  protected onInterestChangeDateControl() {
    this.interestChangeDateError = null;
    const adjustmentMonths = (this.ausData.firstPaymentAdjustmentMonths || 0) as number;

    if (
      adjustmentMonths &&
      this.mortgage.mortgageTerm.mortgageAppliedFor == MortgageAppliedForTypeEnum.FHA
    ) {
      this._loanService.getKeyDatesByType(this.mortgage.applicationId).subscribe({
        next: keyDates => {
          const hasFirstPaymentDate =
            !!keyDates['firstPayment'] && !!keyDates['firstPayment'].eventDate;

          if (hasFirstPaymentDate) {
            const interestChangeDate = DateTime.fromJSDate(
              new Date(keyDates['firstPayment'].eventDate)
            ).plus({
              months: adjustmentMonths,
            });

            if (![1, 4, 7, 10].includes(interestChangeDate.month)) {
              this.interestChangeDateError =
                'Interest Change Date must be either January, April, July or October';
            }
          }
        },
        error: err => {
          console.error(err);
        },
      });
    }
  }

  protected calculateArmFullyIndexedRate() {
    const rate =
      this.mortgage.mortgageTerm.qualifyingRate ?? this.mortgage.mortgageTerm.interestRate;
    const marginPlusIndex =
      Number(this.ausData.armIndexCurrent || 0) + Number(this.ausData.armMarginRate || 0);
    const armFullyIndexedRate = Math.min(
      Number(rate || 0) + Number(this.ausData.armRateAdjustmentLifetimeCap || 0),
      marginPlusIndex
    );

    this.ausData.armFullyIndexedRate = armFullyIndexedRate;
  }
}
