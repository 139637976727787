import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RealEstateOwned } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import Swal from 'sweetalert2';
import {
  MortgageCalculationService,
} from 'src/app/modules/urla/services/mortgage-calculation.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { resetArrayTo } from '../qa-fi-income/reset-object.to';
import { ReoEditorResult } from './qa-fi-reo-editor-v2/qa-fi-reo-editor-v2.component';

@Component({
  selector: 'qa-fi-reo',
  templateUrl: './qa-fi-reo.component.html',
  styleUrls: [
    './qa-fi-reo.component.scss',
    '../../styles/expansion-panel-table-utils.scss',
  ]
})
export class QaFiReoComponent implements OnInit, OnDestroy {

  private _mortgage: UrlaMortgage;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;

    // Lock the operation until the enums are initialized, as it is dependent on them.
    this._enumsInitialized$.pipe(
      takeUntil(this._destroyed$),
    ).subscribe({
      complete: () => {
        this.initializePossibleAccountOwners();
        this.initializeReo();

        this.clearCreatingItemId();
      }
    });
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  reoItems: RealEstateOwned[] = [];

  states: EnumerationItem[] = [];
  currentPropertyWillBeTypes: EnumerationItem[] = [];
  propertyStatusOptions: EnumerationItem[] = [];
  intendedPropertyWillBeTypes: EnumerationItem[] = [];
  reoPropertyTypes: EnumerationItem[] = [];
  yesNoOptions: EnumerationItem[] = [];

  doesNotApply: boolean = false;
  expandedItems: { [key: number]: boolean } = {};
  hoveredItems: { [key: number]: boolean } = {};
  selectedRows: { [key: number]: boolean } = {};
  editItems: { [key: number]: boolean } = {};

  possibleAccountOwners: Array<{
    id: number;
    text: string;
  }> = [];

  liabilityTypeHELOC: string;

  liabilityTypeMortgageLoan: string;

  totalMarketValue: number = 0;
  grossMonthlyExpense: number = 0;

  private creatingItemId: number | null = null;
  protected get isCreatingItem(): boolean {
    return this.creatingItemId != null;
  }

  private readonly _enumsInitialized$ = new BehaviorSubject<boolean>(false);
  private readonly _destroyed$ = new ReplaySubject<void>(1);

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _utilityService: UtilityService,
    private readonly _calculationService: MortgageCalculationService) {
  }

  ngOnInit(): void {
    this.initializeEnums();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private initializeEnums(): void {
    if (this._enumsInitialized$.value) {
      throw new Error('The enums have already been initialized.');
    }

    const enumsService = this._enumsService;

    this.states = enumsService.states;
    this.yesNoOptions = enumsService.getYesNoEnumItems();

    const liabilityType = Constants.enumerationValueNames.LiabilityType;
    this.liabilityTypeHELOC = enumsService.getEnumValue(liabilityType.HELOC);
    this.liabilityTypeMortgageLoan = enumsService.getEnumValue(liabilityType.MortgageLoan);

    enumsService.getMortgageEnumerations().pipe(
      takeUntil(this._destroyed$),
    ).subscribe({
      next: (enums) => {
        const enumKeys = Constants.enumerations;
        this.currentPropertyWillBeTypes = enums[enumKeys.currentPropertyWillBeType];
        this.propertyStatusOptions = enums[enumKeys.propertyStatusOptions];
        this.intendedPropertyWillBeTypes = enums[enumKeys.intendedPropertyWillBeType];
        this.reoPropertyTypes = enums[enumKeys.reoPropertyTypes];

        this._enumsInitialized$.next(true);
      },
      complete: () => this._enumsInitialized$.complete(),
    });
  }

  haveMultipleRowSelected = () => {
    return Object.keys(this.selectedRows).filter(r => this.selectedRows[r]).length > 0;
  }

  private clearCreatingItemId(): void {
    this.creatingItemId = null;
  }

  private onCollapseItem(collapsedItemId: number): void {
    if (this.creatingItemId === collapsedItemId) {
      this.creatingItemId = null;
    }
  }

  protected onChangeReo(result: ReoEditorResult): void {
    this.saveReo(result);
  }

  private saveReo(result: ReoEditorResult): void {
    const reo = result.newReo;
    const reoId = reo.reoId;
    const liabilities = result.reoLiabilities;

    if (liabilities?.length > 0) {
      liabilities.forEach(liability => {
        if (liability.reoId == reoId) {
          this._calculationService.sendLiabilityEvent(liability);
          this.mortgage.realEstateOwned.forEach(reo => {
            if (reo.liabilities && reo.liabilities.length > 0) {
              const index = reo.liabilities.findIndex(l => l.liabilityId == liability.liabilityId);
              if (index > -1) {
                reo.liabilities.splice(index, 1);
                this.mortgage.liabilities.push(liability);
              }
            }
          });
        }
      });

      if (reo.reoId > 0) {
        liabilities.forEach(liability => {
          const index = this.mortgage.liabilities.findIndex(l => l.liabilityId == liability.liabilityId);
          if (index > -1) {
            this.mortgage.liabilities[index].reoId = liability.reoId;
          }
        });
        reo.liabilities = [];
      } else {
        reo.liabilities.forEach(liability => {
          const index = this.mortgage.liabilities.findIndex(l => l.liabilityId == liability.liabilityId);
          if (index > -1) {
            this.mortgage.liabilities[index].reoId = liability.reoId;
            this.mortgage.liabilities.splice(index, 1);
          }
        });
      }
    }

    reo['status'] = this.getStatusName(reo.dispositionStatus);
    reo['currentOccupancy'] = this.getCurrentOccupancyName(reo.currentUsageType);
    this.updateMortgageReo();
    this._calculationService.sendLiabilityEvent();
    this.calculateSubTotal();
  }

  private updateMortgageReo(): void {
    resetArrayTo(this.mortgage.realEstateOwned, this.reoItems);
  }

  private calculateSubTotal(): void {
    this.totalMarketValue = 0;
    this.grossMonthlyExpense = 0;
    this.reoItems.forEach(reoItem => {
      this.totalMarketValue += (reoItem.marketValue || 0);
      this.grossMonthlyExpense += (Number(reoItem.monthlyMiscExpenses) || 0);
    });
  }

  protected onClickCloseReo(reo: RealEstateOwned): void {
    this.collapseReo(reo);
  }

  protected onClickCancelReo(reo: RealEstateOwned): void {
    this.collapseReo(reo);

    if (reo.reoId < 0 && !reo.address1) {
      this.removeReo(reo);
    }

    this.calculateSubTotal();
  }

  private removeReo(reo: RealEstateOwned): void {
    this.reoItems = this.reoItems.filter(a => a.reoId != reo.reoId);
    this.updateMortgageReo();
  }

  protected toggleExpandReo(reo: RealEstateOwned): void {
    if (this.expandedItems[reo.reoId]) {
      this.collapseReo(reo);
    } else {
      this.expandReo(reo);
    }
  }

  private expandReo(reo: RealEstateOwned): void {
    this.expandedItems[reo.reoId] = true;
  }

  private collapseReo(reo: RealEstateOwned): void {
    this.expandedItems[reo.reoId] = false;
    this.onCollapseItem(reo.reoId);
  }

  protected addReoClicked(): void {
    let reo = new RealEstateOwned();
    reo.reoId = this._utilityService.getUniqueId();
    reo.owningBorrowerIds = this.possibleAccountOwners && this.possibleAccountOwners.length == 1 ?
      [Number(this.possibleAccountOwners[0].id)] : [];
    this.onAccountOwnersChanged(reo);

    this.reoItems = [...this.reoItems, reo];
    this.updateMortgageReo();
    this.expandReo(reo);
    this.editItems[reo.reoId] = false;
    this.creatingItemId = reo.reoId;
  }

  bulkDeleteClicked = () => {
    const self = this;
    let selectedReoIds = Object.keys(this.selectedRows).filter(r => this.selectedRows[r]);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this ' + selectedReoIds.length + ' selected records ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {

        selectedReoIds.forEach(id => {
          delete self.expandedItems[id];
          delete self.selectedRows[id];
          delete self.hoveredItems[id];
          self.reoItems.splice(self.reoItems.findIndex(reo => reo.reoId == Number(id)), 1);
        });
        self.mortgage.realEstateOwned = [...self.reoItems];
        self.calculateSubTotal();
        self._calculationService.sendLiabilityEvent();
        self.reoItems.forEach(r => {
          self.expandedItems[r.reoId] = false;
        });
      }
    });
  }

  onAccountOwnersChanged = (reo: RealEstateOwned) => {
    reo['owners'] = [];
    reo.owningBorrowerIds.forEach(borrowerId => {
      reo['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(borrowerId) });
    });
  }

  private initializeReo = () => {
    this.reoItems = [];
    if (this._mortgage.realEstateOwned) {
      this._mortgage.realEstateOwned.forEach(reoItem => {
        let borrIds = [];
        Object.defineProperty(reoItem, Constants.dirtyCheckIgnoreFieldsMetaDataField, {
          value: ['owners', 'status', 'currentOccupancy']
        }
        );
        reoItem['owners'] = [];
        reoItem.owningBorrowerIds.forEach(borrowerId => {
          borrIds.push(borrowerId);
          reoItem['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(borrowerId) });
        });
        reoItem.owningBorrowerIds = borrIds;
        reoItem['status'] = this.getStatusName(reoItem.dispositionStatus);
        reoItem['currentOccupancy'] = this.getCurrentOccupancyName(reoItem.currentUsageType);
        this.reoItems.push(reoItem);
      });
    }
    this.calculateSubTotal();
  }

  private initializePossibleAccountOwners = () => {
    this.possibleAccountOwners = [];
    if (this._mortgage.borrowers !== null) {
      this._mortgage.borrowers.forEach(borrower => {
        const borrowerFullName = this._utilityService.getBorrowerFullName(borrower);

        const possibleAccountOwner = {
          id: borrower.borrowerId,
          text: borrowerFullName
        };
        this.possibleAccountOwners.push(possibleAccountOwner);
      });
    }
  }

  private getBorrowerName = (borrowerId: number): string => {
    let borrower = this.possibleAccountOwners.find(owner => owner.id == borrowerId);
    if (borrower && borrower.text)
      return borrower.text;
    return ""
  }

  private getStatusName = (dispositionStatus: string): string => {
    let element = this.propertyStatusOptions.find(propertyStatusOption => propertyStatusOption.value == dispositionStatus);
    if (element) {
      return element.name;
    }
    return "";
  }

  private getCurrentOccupancyName = (currentUsageType: string): string => {
    let element = this.currentPropertyWillBeTypes.find(currentPropertyWillBeType => currentPropertyWillBeType.value == currentUsageType);
    if (element) {
      return element.name;
    }
    return "";
  }
}
