<div class="modal-header">
  <h5 class="modal-title">Dependents</h5>
  <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="alert alert-danger border-0" role="alert" *ngIf="error">
    <strong>{{error.header}}</strong> {{error.message}}
  </div>
  <form #dependentForm="ngForm" novalidate id="dependentForm" name="dependentForm">
  <h6>Age</h6>
    <ul class="list-inline mb-0 text-muted" *ngFor="let dependent of modalDependents; let index = index">
      <li class="list-inline-item me-2">
        <input
          type="number"
          numeric
          [min]="0"
          [max]="130"
          class="form-control"
          [(ngModel)]="dependent.age"
          #dependentAge="ngModel"
          id="dependentAge_{{index}}"
          name="dependentAge_{{index}}"
          (change)="onDependentAgeChanged(dependent)"
          [ngClass]="{'is-invalid' : dependentAge && dependentAge.touched  && dependentAge.invalid}"
          required
        />
      </li>
      <li class="list-inline-item me-2">
        <a (click)="remove(index)" style="cursor:pointer;">
          <i class="fas fa-trash-alt"></i>
        </a>
      </li>
      <br><br>
    </ul>
  </form>
  <br>
  <a (click)="add()" style="cursor:pointer;"><i class="fas fa-plus me-1"></i>Add Dependent</a>
  <br><br>
  <label>Number of Dependents: <b>{{modalDependents.length}}</b></label>
</div>
<div class="modal-footer">
  <div class="button-items">
    <button type="button" class="btn btn-primary right" (click)="save()">Save</button>
    <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">Cancel</button>
  </div>
</div>
