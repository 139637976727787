<icon-button (onClick)='onButtonClick()' [isDisabled]='isDisabled'>
  <ng-container slot='icon'>
    <!-- spinner -->
    <span *ngIf='saving' aria-hidden='true' class='spinner-border'
          role='status'>
    </span>

    <!-- save icon -->
    <i *ngIf='!saving' class='fas fa-save'></i>
  </ng-container>

  {{isDisabled ? 'Save (Readonly)' : 'Save'}}
</icon-button>
