<drawer
  (closed)='onCancel()'
  [name]='drawerName'
  [options]='drawerOptions'
  [templateRef]='onMortgageLoanEditor'
  [title]='title'
></drawer>

<ng-template #onMortgageLoanEditor>
  <div class='p-3'>
    <related-mortgage-loan-editor
      (cancel)='onCancel()'
      (save)='onSave($event)'
      [value]='value'
    ></related-mortgage-loan-editor>
  </div>
</ng-template>
