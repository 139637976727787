<button
  [ngStyle]="{'width': width ? (width + 'px') : null}"
  style="border-radius: 15px"
  type="button"
  class="btn btn-outline-primary tippy-btn mb-xs-1"
  data-bs-container="body"
  data-bs-toggle="popover"
  placement="bottom"
  [popover]="filterTemplate"
  data-trigger="click"
  [outsideClick]="true"
  [ngClass]="{'selected-filter' : selected, 'unselected-filter': !selected, 'have-any-selection': selectedOptions.length}"
  [disabled]="disabled"
  (click)="onClicked()"
  [containerClass]="'genericFilter'"
  placement="bottom">
  <div class="d-flex">
    <span *ngIf="icon" class="fw-bolder button-item-text flex-grow-1">
      <i class={{icon}}></i>
    </span>
    <span *ngIf="label" class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
      {{label}}
    </span>
    <i class="fas fa-angle-down ms-2 mt-1"></i>
  </div>
</button>

<ng-template #filterTemplate>
  <div class="d-flex flex-column">
    <div class="d-flex filter-header-container">
      <h4 class="filter-header flex-grow-1">{{filterHeader}}</h4>
      <a style="margin-top: 3px" class="me-1" (click)="onClearAllClicked()">Clear All</a>
    </div>

    <input *ngIf="searchable" id="search" name="search" type="text"
           class="form-control search-input" placeholder="Search"
           [(ngModel)]="searchString"/>

    <p *ngIf="maxSelectedOptions != null" class="max-selected">
      <span>{{selectedOptions.length}}</span>
      <span>{{maxSelectedOptions}}</span>
      selected
    </p>

    <div *ngIf="!filterOptions.length" style="text-align: center" class="p-3">
      <label>{{noOptionsMessage}}</label>
    </div>

    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'multiSelect'">
        <!-- TODO: move to separete component -->
        <ng-template [ngIf]="showMoreFilterOptions" [ngIfElse]="others">
          <ng-container *ngFor="let group of filterOptions | groupBy: 'groupName'; let i = index;">
            <div class="filter-subheader mt-2 px-3 mb-1">
              {{group.key}}
            </div>

            <div *ngIf="showMoreFilterOptions.allowSelectAll" class="form-check mx-3">
              <input
                id="selectall"
                class="form-check-input"
                type="checkbox"
                name="selectall"
                [(ngModel)]="isAllBasedSelected"
                (ngModelChange)="onSelectAllClicked(true)"
              />
              <label class="form-check-label" for="selectall">
                - Select All -
              </label>
            </div>

            <div *ngFor="let filterOption of
                (sortOptionsByDisplayName ?
                  (group.value | filter: showMoreFilterOptions.baseOptionsFilter | sort: 'asc':'displayName') :
                  group.value | filter: showMoreFilterOptions.baseOptionsFilter); let j = index" class="form-check mx-3">
              <input
                id="option_{{i}}{{j}}"
                class="form-check-input"
                type="checkbox"
                name="option_{{index}}"
                [(ngModel)]="filterOption.isSelected"
                (click)="onCheckChanged($event, filterOption)"
              />
              <label class="form-check-label" for="option_{{i}}{{j}}" *ngIf="!filterOption.displayHtml">
                {{filterOption.displayName}}
              </label>
              <label style="display: inline-block" *ngIf="filterOption.displayHtml"
                [innerHTML]="filterOption.displayHtml">
              </label>

              <span *ngIf="filterOption.badge" class="badge bg-info mx-2">{{filterOption.badge}}</span>
            </div>

            <a *ngIf="showMoreFilterOptions.opositeOptionsFilter" class="mx-3" style="margin-top: 3px; margin-bottom: 2px;" href="javascript:void(0)" (click)="toggleShowMore()">{{showMore ? 'Hide' : 'Show'}} {{showMoreFilterOptions.label}}</a>

            <ng-container *ngIf="showMore">
              <div *ngIf="showMoreFilterOptions.allowSelectAll" class="form-check mx-3">
                <input
                  id="selectall_showMore"
                  class="form-check-input"
                  type="checkbox"
                  name="selectall_showMore"
                  [(ngModel)]="isAllOpositeSelected"
                  (ngModelChange)="onSelectAllClicked(null, true)"
                />
                <label class="form-check-label" for="selectall_showMore">
                  - Select All -
                </label>
              </div>

              <div *ngFor="let filterOption of
              (sortOptionsByDisplayName ?
                (group.value | filter: showMoreFilterOptions.opositeOptionsFilter | sort: 'asc':'displayName') :
                group.value | filter: showMoreFilterOptions.opositeOptionsFilter); let j = index" class="form-check mx-3">
                <input
                  id="more_option_{{i}}{{j}}"
                  class="form-check-input"
                  type="checkbox"
                  name="option_{{index}}"
                  [(ngModel)]="filterOption.isSelected"
                  (click)="onCheckChanged($event, filterOption)"
                />
                <label class="form-check-label" for="more_option_{{i}}{{j}}">
                  <s>{{filterOption.displayName}}</s>
                </label>

                <span *ngIf="filterOption.badge" class="badge bg-info mx-2">{{filterOption.badge}}</span>
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
        <ng-template #others>
          <ng-container *ngFor="let group of filterOptions | groupBy: 'groupName'; let i = index;">
            <div class="filter-subheader mt-2 px-3 mb-1">{{group.key}}</div>
            <div *ngFor="let filterOption of (sortOptionsByDisplayName ? (group.value | sort: 'asc':'displayName') : group.value); let j = index" class="form-check mx-3">
              <input
                id="option_{{i}}{{j}}"
                class="form-check-input"
                type="checkbox"
                name="option_{{index}}"
                [(ngModel)]="filterOption.isSelected"
                (click)="onCheckChanged($event, filterOption)"
              />
              <label class="form-check-label" for="option_{{i}}{{j}}">
                {{filterOption.displayName}}
              </label>

              <span *ngIf="filterOption.badge" class="badge bg-info mx-2">{{filterOption.badge}}</span>
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'singleSelect'">
        <ng-container *ngFor="let group of filterOptions | groupBy: 'groupName'; let i = index;">
          <div class="filter-subheader mt-2 px-3 mb-1">{{group.key}}</div>
          <div *ngFor="let filterOption of (sortOptionsByDisplayName ? (group.value | sort: 'asc':'displayName') : group.value); let j = index" class="form-check mx-3">
            <input
              id="option_{{i}}{{j}}"
              class="form-check-input"
              type="checkbox"
              name="option_{{index}}"
              [(ngModel)]="filterOption.isSelected"
              (click)="onCheckChanged($event, filterOption)"
              [disabled]="filterOption.isDisabled"
            />
            <label class="form-check-label" for="option_{{i}}{{j}}" *ngIf="!filterOption.displayHtml">
              {{filterOption.displayName}}
            </label>
            <label style="display: inline-block" *ngIf="filterOption.displayHtml" for="option_{{i}}{{j}}"
              [innerHTML]="filterOption.displayHtml | safeHtml">
            </label>

            <span *ngIf="filterOption.badge" class="badge bg-info mx-2">{{filterOption.badge}}</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
