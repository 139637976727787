<div class="form-input" *ngIf="options && !inEditMode" [ngClass]="{
  'ng-invalid-required' : required && value == null && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required' ,
  'ng-invalid-requested' : required && value == null && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
  'bg-transparent' : disabled || readonly
}">
  <span class="input-label" style="display: inline;" title="">
      <span class="input-label-overflow">{{label}}</span>
  </span>
  <div *ngIf="value && !disabled && !readonly" style="float: right; text-transform: none; padding-top: 2px;">
        <a href="javascript: void(0)" (click)="onImportLoanInfoClicked()">Import from 4a. Loan Info</a>
        <br>
        <a href="javascript: void(0)" (click)="onPushLoanInfoClicked()">Push to 4a. Loan Info</a>
  </div>
  <div class="radio-group radio-group-container">
      <label class="radio-group-item" *ngFor="let option of options" [for]="readonly ? name : name + '_' + option.value">
          <input
            #option
            type="radio"
            name="{{name}}_{{option.value}}"
            [id]="name + '_' + option.value"
            [(ngModel)]="value"
            [value]="option.value"
            [required]="required"
            [disabled]="disabled ? true : null"
            [readonly]="readonly ? true : null"/>
          {{option.name}}
      </label>
      <div *ngIf="readonly" class="disabled-overlay"></div>
  </div>
</div>
<edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
</edit-mode-input>
