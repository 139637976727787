import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { CityCountyState } from 'src/app/models/city-county-state.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { Channel } from 'src/app/modules/leads/models/lead.model';
import { CityCountyPickerDialogComponent } from 'src/app/modules/urla/components/city-county-picker-dialog/city-county-picker-dialog.component';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { ZipCodeService } from 'src/app/services/zipcode.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { EnabledBusinessChannel } from '../../models/business-channel.model';
import { CustomField } from '../../models/pricing/custom-fields.model';
import { PricingFieldAccess, PricingFieldSpec } from '../../models/pricing/pricing-field-spec.model';
import {
  CustomFieldValue,
  ExpandedGuidelines,
  ProductSearchRequest
} from '../../models/pricing/product-search-request-info.model';
import { PricingConfigurationService } from '../../services/pricing-configuration.service';
import { PricingTransactionType } from '../pricing-details/pricing-details.component';
import { SecondLientAmountCalculatorDialogComponent } from './second-lien-amount-calculator-dialog/second-lien-amount-calculator-dialog.component';

@Component({
  selector: 'pricing-parameters',
  templateUrl: './pricing-parameters.component.html',
  styleUrls: ['./pricing-parameters.component.scss'],
})
export class PricingParametersComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('searchForm')
  searchForm: NgForm;

  @Input()
  businessChannelId: number | string;

  @Input()
  channelSelectionEnabled: boolean = true;

  @Input()
  showMultipleColumns: boolean;

  @Input()
  transactionType: PricingTransactionType = null;

  @Input()
  set enabledChannels(enabledChannels: EnabledBusinessChannel[]) {
    this._enabledChannels = enabledChannels;
    this.groupSelectedVendorPricingProfiles();
    if (this.productSearchRequest) {
      if (this.selectedVendor == 'OptimalBlue') {
        if (this._enabledChannels.length > 0)
          this.businessChannelId = this._enabledChannels[0].pricingChannelId;
      }
      else {
        if (this._enabledChannels.length > 0) {
          this.selectedProfileChannel = this._enabledChannels[0];
          this.productSearchRequest.credentialId = this._enabledChannels[0].credentialId;
          this.productSearchRequest.pricingChannelId = this._enabledChannels[0].pricingChannelId;
        }
      }
    }
  }

  @Input()
  set allEnabledBusinessChannelPerVendor(allEnabledBusinessChannelPerVendor: any) {
    var enabledPricingVendors = Object.keys(allEnabledBusinessChannelPerVendor).map(x => x.toLowerCase());
    this.filteredPricingVendors = this.pricingVendorsEnum.filter(vendor => enabledPricingVendors.indexOf(vendor.value.toLowerCase()) > -1 && !vendor.value.includes("Iframe"));
    if (this.filteredPricingVendors.length > 0) {
      this.selectedVendor = this.filteredPricingVendors[0].value;
    }
  }

  @Input()
  disableCreditScore: boolean;

  @Input()
  applicationChannel: Channel | null;

  @Input()
  disableDti: boolean;

  @Input()
  set customFields(customFields: CustomField[]) {
    this._customFields = customFields;

    this._customFields.forEach((cf) => {
      const alreadyAdded = this.customFieldValues.hasOwnProperty(cf.thirdPartyCustomFieldId);
      if (!alreadyAdded) {
        if (cf.type === 'Checkbox') {
          this.customFieldValues[cf.thirdPartyCustomFieldId] = cf.defaultValue.toLowerCase() === 'true' || cf.defaultValue === '1';
        } else {
          this.customFieldValues[cf.thirdPartyCustomFieldId] = cf.defaultValue || null;
        }
      }
    });

    if (this.productSearchRequest && this.productSearchRequest.loanInformation) {
      this.productSearchRequest.loanInformation.customFieldValues = [];
      customFields.forEach(field => {
        this.productSearchRequest.loanInformation.customFieldValues.push(
          new CustomFieldValue(field.thirdPartyCustomFieldId, field.name, field.defaultValue)
        );
      })
    }
  }

  @Input()
  set productSearchRequest(request: ProductSearchRequest) {
    this._productSearchRequest = request;
    this.lastZipCodeSearch = request.propertyInformation.zipCode;
    if (this.productSearchRequest.loanInformation.loanPurpose !== 'Refinance') {
      this.removeRefinancePurpose();
    }

    if (this._productSearchRequest.loanInformation && this._productSearchRequest.loanInformation.baseLoanAmount) {
      this._productSearchRequest.loanInformation.baseLoanAmount = Math.floor(this._productSearchRequest.loanInformation.baseLoanAmount);
    }
    if (
      request.propertyInformation.zipCode &&
      !(request.propertyInformation.state && request.propertyInformation.county)
    ) {
      this.lookupZipCodeRelatedInfo(request.propertyInformation.zipCode);
    }

    if (this.amortizationTypeOptions) {
      this.initializeAmortizationTypesForDefaultSearchCriteria();
    }

    if (this.loanTermOptions) {
      this.initializeLoanTermsForDefaultSearchCriteria();
    }

    if (this.productTypeOptions) {
      this.initializeProductTypesForDefaultSearchCriteria();
    }

    this.fixDefaultValues();
    this.calculateLtvAndCltv();
  }

  @Input()
  set visibleFeatureFields(val: string[]) {
    this._visibleFeatureFields = val;
    if (!this._productSearchRequest || this._productSearchRequest.applicationId) {
      return;
    }
    if (this.multipleLoanTypesVisible && !this._productSearchRequest.loanTypes?.length) {
      const defaultLoanTypesForQuickPricer = ['Conventional', 'FHA', 'VA', 'USDA'];
      this.selectedLoanTypes = this.filteredLoanTypeOptions.filter(f => defaultLoanTypesForQuickPricer.includes(f.value))
      this.onMultipleLoanTypesChanged();
    } else {
      this._productSearchRequest.loanTypes = [];
    }
  }

  @Input()
  set fieldSpecs(val: PricingFieldSpec[]) {
    this._fieldSpecs = val;
    this.loadFieldConfigsForVendor();
  }

  @Input()
  even: boolean;

  @Input()
  canPriceApplyToLoan: boolean;

  @Output()
  businessChannelChanged: EventEmitter<{ type: string, id: number }> = new EventEmitter<{ type: string, id: number }>();

  @Output()
  vendorChanged: EventEmitter<string> = new EventEmitter<string>();

  desiredLockPeriodsList = [
    { value: 15, name: '15' },
    { value: 30, name: '30' },
    { value: 45, name: '45' },
    { value: 60, name: '60' },
    { value: 75, name: '75' },
  ];

  citizenshipOptions: EnumerationItem[] = [];
  yesNoOptions: EnumerationItem[] = [];
  loanPurposeOptions: EnumerationItem[] = [];
  refinancePurposeOptions: EnumerationItem[] = [];
  stateOptions: EnumerationItem[] = [];
  occupancyOptions: EnumerationItem[] = [];
  typeOfVeteranOptions: EnumerationItem[] = [];
  propertyTypeOptions: EnumerationItem[] = [];
  numberOfUnitsOptions: EnumerationItem[] = [];
  loanTermOptions: EnumerationItem[] = [];
  loanTypeOptions: EnumerationItem[] = [];
  filteredLoanTypeOptions: EnumerationItem[] = [];
  amortizationTypeOptions: EnumerationItem[] = [];
  armFixedTermOptions: EnumerationItem[] = [];
  productTypeOptions: EnumerationItem[] = [];
  filteredProductTypeOptions: EnumerationItem[] = [];
  projectTypeOptions: EnumerationItem[] = [];
  buyDownOptions: EnumerationItem[] = [];
  filteredBuyDownOptions: EnumerationItem[] = [];
  filteredDocumentationTypes: EnumerationItem[] = [];
  automatedUnderwritingSystemOptions: EnumerationItem[] = [];
  includeLoCompensationInPricingOptions: EnumerationItem[] = [];
  compensationPercentBasedOnOptions: EnumerationItem[] = [];
  documentationTypeOptions: EnumerationItem[] = [];
  constructionMethodOptions: EnumerationItem[] = [];
  attachmentTypeOptions: EnumerationItem[] = [];
  prepaymentPenaltyOptions: EnumerationItem[] = [];
  manufacturedHomeWidthTypeOptions: EnumerationItem[] = [];
  projectDesignTypeOptions: EnumerationItem[] = [];
  lienPositionTypeOptions: EnumerationItem[] = [];
  incomeVerificationType: EnumerationItem[] = [];
  bankStatementExpenseMethod: EnumerationItem[] = [];
  incomeVerificationMethod: EnumerationItem[] = [];
  investorExperience: EnumerationItem[] = [];
  bankruptcySeasoning: EnumerationItem[] = [];
  bankruptcyType: EnumerationItem[] = [];
  bankruptcyOutcome: EnumerationItem[] = [];
  housingEventType: EnumerationItem[] = [];
  housingEventSeasoning: EnumerationItem[] = [];
  pricingVendorsEnum: EnumerationItem[] = [];
  loanTerms: any = {};
  financeEntireAmount: boolean = true;
  months1224: EnumerationItem[] = [
    { name: '-- Select One --', value: null },
    { name: '12 Months', value: 12 },
    { name: '24 Months', value: 24 }
  ];

  filteredPricingVendors: EnumerationItem[] = [];

  manuallyEnterPmiMipFfGFeeDetails: boolean = false;

  customFieldValues: any = {};

  multiSelectSettings: IDropdownSettings = {};

  loanTypesMultiSelectSettings: IDropdownSettings = {};

  helocOrMortgageLoanType: string = "Mortgage";

  selectedAmortizationTypes: EnumerationItem[] = [
    { name: 'Fixed', value: 'FixedRate' },
  ];

  selectedLoanTerms: EnumerationItem[] = [
    { name: '30 Year', value: 360 },
  ];

  selectedArmFixedTerms: EnumerationItem[] = [];

  selectedProductTypes: EnumerationItem[] = [
    { name: 'Standard Products', value: 'StandardProducts' },
  ];

  selectedLoanTypes: EnumerationItem[] = [];

  isLoanInfoSectionInvalid: boolean = false;

  isBorrowerInfoSectionInvalid: boolean = false;

  isPropertyInfoSectionInvalid: boolean = false;

  isProductSearchCriteriaSectionInvalid: boolean = false;

  isAdditionalInfoSectionInvalid: boolean = false;

  isCustomSectionInvalid: boolean = false;

  selectedVendor: string;

  selectedProfileChannel: EnabledBusinessChannel;

  _enabledChannels: EnabledBusinessChannel[];

  groupedVendorProfiles: any[] = [];

  countyList: EnumerationItem[] = [];
  lastZipCodeSearch: string;

  _fieldSpecs: PricingFieldSpec[];

  readonlyFields: string[] = [];
  hiddenFields: string[] = [];
  hiddenEnumOptionsByField: Map<string, string[]> = new Map();

  private _productSearchRequest: ProductSearchRequest;
  private _visibleFeatureFields: string[] = [];

  private _customFields: CustomField[] = [];

  get visibleFeatureFields(): string[] {
    return this._visibleFeatureFields;
  }

  get productSearchRequest(): ProductSearchRequest {
    return this._productSearchRequest;
  }

  get customFields(): CustomField[] {
    return this._customFields;
  }

  get isPurchase(): boolean {
    const loanPurposePurchaseEnumValue = this._enumsService.getEnumValue(
      Constants.enumerationValueNames.LoanPurposeType.Purchase
    );
    return (
      this._productSearchRequest.loanInformation.loanPurpose ===
      loanPurposePurchaseEnumValue
    );
  }

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _zipCodeService: ZipCodeService,
    private readonly _modalService: NgbModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notifsService: NotificationService,
    private readonly _pricingConfigService: PricingConfigurationService,
    private readonly injector: Injector
  ) {
    super(injector);
    this.multiSelectSettings = {
      idField: 'value',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.loanTypesMultiSelectSettings = {
      idField: 'value',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };

    this._enumsService.getPricingEnumerations().subscribe((enums) => {
      this.citizenshipOptions =
        enums[Constants.pricingEnumerations.citizenship];
      this.yesNoOptions = enums[Constants.pricingEnumerations.yesNo];
      this.occupancyOptions = enums[Constants.pricingEnumerations.occupancy];
      this.typeOfVeteranOptions =
        enums[Constants.pricingEnumerations.typeOfVeteran];
      this.numberOfUnitsOptions =
        enums[Constants.pricingEnumerations.numberOfUnits];

      this.buyDownOptions = enums[Constants.pricingEnumerations.buyDown];
      this.automatedUnderwritingSystemOptions =
        enums[Constants.pricingEnumerations.automatedUnderwritingSystem];
      this.armFixedTermOptions = this._enumsService.armFixedTerms;
      this.documentationTypeOptions =
        enums[Constants.pricingEnumerations.documentationType];

      this.prepaymentPenaltyOptions =
        enums[Constants.pricingEnumerations.prepaymentPenalty];

      // We do these for the fields on the bottom, since these 2 fields come in from the server with text enum values as opposed to integer Ids.
      const productTypeOptions =
        enums[Constants.pricingEnumerations.productType];
      this.productTypeOptions = productTypeOptions.map(
        (o) => (o.value = new EnumerationItem(o.name, o.alternateValue, undefined, undefined, o.supportedPricingVendors))
      );
      this.loanTermOptions = this._enumsService.loanTerms;

      this.includeLoCompensationInPricingOptions =
        enums[Constants.pricingEnumerations.includeLoCompensationPricing];

      this.compensationPercentBasedOnOptions =
        enums[Constants.pricingEnumerations.compensationPercentBasedOn]

      this.stateOptions = this._enumsService.states;

      this.pricingVendorsEnum = enums[Constants.pricingEnumerations.pricingEngineVendor];

      this.incomeVerificationType = enums[Constants.pricingEnumerations.incomeVerificationType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.incomeVerificationMethod = enums[Constants.pricingEnumerations.incomeVerificationMethod]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.bankStatementExpenseMethod = enums[Constants.pricingEnumerations.bankStatementExpenseMethod]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.bankruptcySeasoning = enums[Constants.pricingEnumerations.seasoning]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.bankruptcyType = enums[Constants.pricingEnumerations.bankruptcyType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.bankruptcyOutcome = enums[Constants.pricingEnumerations.bankruptcyOutcome]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.housingEventType = enums[Constants.pricingEnumerations.housingEventType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.housingEventSeasoning = enums[Constants.pricingEnumerations.seasoning]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.investorExperience = enums[Constants.pricingEnumerations.investorExperience]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this._enumsService.getMortgageEnumerations().subscribe((enums) => {
        this.loanPurposeOptions =
          enums[Constants.mortgageEnumerations.loanPurpose];
        this.refinancePurposeOptions =
          enums[Constants.mortgageEnumerations.refinancePurpose];
        this.propertyTypeOptions =
          enums[Constants.mortgageEnumerations.propertyType];
        this.amortizationTypeOptions =
          enums[Constants.mortgageEnumerations.amortizationType];
        this.loanTypeOptions =
          enums[Constants.mortgageEnumerations.mortgageAppliedForType];
        this.constructionMethodOptions =
          enums[Constants.mortgageEnumerations.constructionMethodType];
        this.attachmentTypeOptions =
          enums[Constants.mortgageEnumerations.attachmentType];
        this.projectTypeOptions =
          enums[Constants.mortgageEnumerations.projectType];
        this.manufacturedHomeWidthTypeOptions =
          enums[Constants.mortgageEnumerations.manufacturedHomeWidthType];
        this.projectDesignTypeOptions =
          enums[Constants.mortgageEnumerations.projectDesignType];
        //this.lienPositionTypeOptions = enums[Constants.mortgageEnumerations.lienPositionType];
        this.lienPositionTypeOptions = [
          new EnumerationItem('First Lien', 'FirstLien'),
          new EnumerationItem('Second Lien', 'SecondLien'),
        ];
      });
    });
  }

  ngOnInit(): void {
    if (this.selectedVendor) {
      this.onSelectedVendorChanged(this.selectedVendor);
    }

    //To update the UI at initialize.
    this.initializeAmortizationTypesForDefaultSearchCriteria();
    this.initializeLoanTermsForDefaultSearchCriteria();
    this.initializeProductTypesForDefaultSearchCriteria();
    this.getCountyListBySelectedState();
  }

  getCountyListBySelectedState = () => {
    if (!this.productSearchRequest.propertyInformation.state) {
      this.countyList = [];
      return;
    }
    this._zipCodeService.getCountiesByState(this.productSearchRequest.propertyInformation.state)
      .subscribe({
        next: (list) => {
          this.countyList = list;
        },
        error: (err) => {
          this._notifsService.showError(err ? err.message : 'Unable to get counties for selected state.', 'Error!');
        }
      });
  }

  onCountySelect = ({ item }): void => {
    this.productSearchRequest.propertyInformation.countyFips = item.value;
  }

  validate = (): boolean => {
    this.isLoanInfoSectionInvalid = false;
    this.isBorrowerInfoSectionInvalid = false;
    this.isPropertyInfoSectionInvalid = false;
    this.isProductSearchCriteriaSectionInvalid = false;
    this.isAdditionalInfoSectionInvalid = false;
    this.isCustomSectionInvalid = false;
    let firstInvalidOneId: string = null;
    if (this.searchForm) {
      this.searchForm.form.markAllAsTouched();
      const isValid = this.searchForm.form.valid;
      if (!isValid) {
        for (var key in this.searchForm.form.controls) {
          if (this.searchForm.form.controls.hasOwnProperty(key)) {
            if (this.searchForm.form.controls[key].status === 'INVALID') {
              firstInvalidOneId = key;
              break;
            }
          }
        }
      }
      if (firstInvalidOneId) {
        this.scrollToElement(firstInvalidOneId);
      }
      return isValid;
    }
    return false;
  };

  // Kaan - this is a hack to make the dynamically changing min validator to work on Monthly Income
  showMonthlyIncome: boolean = true;
  onSelectedVendorChanged = (selectedVendor: string) => {
    this.productSearchRequest.credentialId = null;
    this.productSearchRequest.pricingChannelId = null;
    this.selectedProfileChannel = null;
    this.productSearchRequest.optimalBlueOriginatorId = null;
    this.showMonthlyIncome = false;
    this.filterDropDownsByVendor();
    if (selectedVendor === 'Polly') {
      this.productSearchRequest.loanInformation.loanType = null;
      this.productSearchRequest.loanTypes = [];
      this.selectedLoanTypes = [];
      this.productSearchRequest.loanTerms = [];
      this.selectedLoanTerms = [];
    }
    this.vendorChanged.emit(selectedVendor);
    setTimeout(() => {
      this.showMonthlyIncome = true;
    }, 200);
  }

  private filterDropDownsByVendor = () => {
    this.filteredLoanTypeOptions = this.loanTypeOptions.filter(this.filterBySupportedVendor);
    this.filteredProductTypeOptions = this.productTypeOptions.filter(this.filterBySupportedVendor);
    this.filteredBuyDownOptions = this.buyDownOptions.filter(this.filterBySupportedVendor);
    this.filteredDocumentationTypes = this.documentationTypeOptions.filter(this.filterBySupportedVendor);
  }

  private filterBySupportedVendor = (item: EnumerationItem) => {
    if (!item.supportedPricingVendors) {
      return false;
    }
    const supportedVendors = item.supportedPricingVendors.split(',').map(v => v.trim());
    return supportedVendors.includes('All') || supportedVendors.includes(this.selectedVendor);
  }

  onSecondLienAmountCalculatorClicked = () => {
    const modalRef = this._modalService.open(
      SecondLientAmountCalculatorDialogComponent,
      Constants.modalOptions.medium
    );
    modalRef.componentInstance.loanInformation =
      this.productSearchRequest.loanInformation;
    modalRef.componentInstance.loanType = this.helocOrMortgageLoanType;
    modalRef.result.then(
      (settings) => {
        this.productSearchRequest.loanInformation = settings.loanInformation;
        this.helocOrMortgageLoanType = settings.loanType;
        this.calculateLtvAndCltv();
      },
      (error) => { }
    );
  };

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    this.lastZipCodeSearch = zipCode.zipcode;
    if (zipCode) {
      this.productSearchRequest.propertyInformation.state =
        zipCode.state.toLowerCase();
      this.productSearchRequest.propertyInformation.county = _.toUpper(
        zipCode.county
      ); // titleCase string
      this.productSearchRequest.propertyInformation.countyFips = zipCode.countyFips;
      this.productSearchRequest.propertyInformation.zipCode = zipCode.zipcode;
    } else {
      this.productSearchRequest.propertyInformation.state = '';
      this.productSearchRequest.propertyInformation.county = '';
      this.productSearchRequest.propertyInformation.countyFips = '';
    }
    this.getCountyListBySelectedState();
  };

  onZipCodeBlur = (e) => {
    if (this.lastZipCodeSearch !== this.productSearchRequest.propertyInformation.zipCode) {
      this.productSearchRequest.propertyInformation.state = '';
      this.productSearchRequest.propertyInformation.county = '';
      this.productSearchRequest.propertyInformation.countyFips = '';
      this.countyList = [];
    }
  }

  isConstructionTypeManufactured = (): boolean => {
    const isManufactured =
      this.productSearchRequest.propertyInformation.constructionMethod ===
      'Manufactured';
    if (!isManufactured) {
      this.productSearchRequest.propertyInformation.manufacturedHomeWidthType =
        null;
    }
    return isManufactured;
  };

  isProjectTypeCondominium = (): boolean => {
    const isCondominium =
      this.productSearchRequest.propertyInformation.projectType ===
      'Condominium';
    if (!isCondominium) {
      this.productSearchRequest.propertyInformation.projectDesignType = null;
    }
    return isCondominium;
  };

  showNonWarrantableProjectCheckBox(): boolean {
    return this.productSearchRequest.propertyInformation.projectType &&
      this.visibleFeatureFields.includes('Property_NonWarrantableProject');
  }

  onOptimalBlueChannelChanged = () => {
    this._customFields = [];
    this.productSearchRequest.loanInformation.customFieldValues = [];
    const obOriginatorId = this._enabledChannels.find(c => c.pricingChannelId == this.businessChannelId)?.originatorId;
    this.productSearchRequest.optimalBlueOriginatorId = obOriginatorId;
    this.businessChannelChanged.emit({ type: this.selectedVendor, id: this.businessChannelId as number });
  };

  onPollyLenderPriceChannelChanged = (selectedChannel: EnabledBusinessChannel) => {
    this.productSearchRequest.credentialId = selectedChannel?.credentialId
    this.productSearchRequest.pricingChannelId = selectedChannel?.pricingChannelId;
    this.productSearchRequest.channel = selectedChannel?.channel;
    this.businessChannelChanged.emit({ type: this.selectedVendor, id: this.productSearchRequest.credentialId });
  }

  onLienTypeChanged = () => {
    if (this.productSearchRequest.loanInformation.lienType === 'FirstLien') {
      this.productSearchRequest.loanInformation.firstLienAmount = 0;
      if (this.isHomeEquityLoanType) {
        this.productSearchRequest.loanInformation.calculateTotalLoanAmount = true;
        this.productSearchRequest.loanInformation.secondLienAmount = 0;
        this.productSearchRequest.loanInformation.helocDrawnAmount = 0;
        this.productSearchRequest.loanInformation.helocLineAmount = 0;
      }
    } else if (this.productSearchRequest.loanInformation.lienType === 'SecondLien') {
      this.productSearchRequest.loanInformation.secondLienAmount = 0;
      this.productSearchRequest.loanInformation.helocDrawnAmount = 0;
      this.productSearchRequest.loanInformation.helocLineAmount = 0;
      this.productSearchRequest.loanInformation.calculateTotalLoanAmount = true;
    }
  }

  onChangeFinanceEntireAmount = () => {
    if (this.financeEntireAmount) {
      this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPaidinCash = 0;
    }
  };

  onManuallyEnterPmiMipFfGFeeDetailsChanged = () => {
    this.productSearchRequest.loanInformation.calculateTotalLoanAmount = !this.manuallyEnterPmiMipFfGFeeDetails;
  }

  onExemptFromFundingFeeChanged = () => {
    if (this.productSearchRequest.loanInformation.exemptFromVaFundingFee) {
      this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = 0;
      this.productSearchRequest.loanInformation.upfrontPmiMipFfGfAmount = 0;
      this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPaidinCash = 0;
      this.productSearchRequest.loanInformation.upfrontPmiMipFfGfFinancedAmount = 0;
    } else {
      this.calculateAndSetFundingFee();
    }
  };

  onCustomFieldValueChanged = (fieldId: string, fieldName: string) => {
    const valueToSet = this.customFieldValues[fieldId];
    if (!this.productSearchRequest.loanInformation.customFieldValues) {
      this.productSearchRequest.loanInformation.customFieldValues = [];
    }
    const loanInfoCustomField =
      this.productSearchRequest.loanInformation.customFieldValues.find(
        (cfv) => cfv.customFieldId === fieldId
      );
    if (loanInfoCustomField) {
      loanInfoCustomField.value = valueToSet;
    } else {
      let newCustomField = new CustomFieldValue(fieldId, fieldName, valueToSet);
      this.productSearchRequest.loanInformation.customFieldValues.push(
        newCustomField
      );
    }
  };

  onBaseLoanAmountChanged = () => {
    this.calculateLtvAndCltv();
    this.calculateTotalLoanAmount();
    this.calculatePmiFeeAmount();
  };

  onAppraisedValueChanged = () => {
    this.calculateLtvAndCltv();
  };

  onPurchasePriceChanged = () => {
    this.calculateLtvAndCltv();
  };

  onFirstLienAmountChanged = () => {
    this.calculateLtvAndCltv();
    this.onCltvValueChanged()
  }
  onSecondLienAmountChanged = () => {
    this.calculateLtvAndCltv();
  };

  onLoanPurposeChanged = () => {
    if (this.productSearchRequest.loanInformation.loanPurpose !== 'Refinance') {
      this.removeRefinancePurpose();
    }
    if (this.isVALoan) {
      this.calculateAndSetFundingFee();
    }
  }

  onRefiPurposeChanged = () => {
    if (this.isVALoan) {
      this.calculateAndSetFundingFee();
    }
  }

  onVaFirstTimeUseChanged = () => {
    this.calculateAndSetFundingFee();
  }

  onLoanTypeChanged = () => {
    if (!this.isVALoan) {
      this.productSearchRequest.borrowerInformation.typeOfVeteran = null;
      this.productSearchRequest.loanInformation.exemptFromVaFundingFee = null;
      this.productSearchRequest.borrowerInformation.vaFirstTimeUse = null;
      this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = 0;
      if (this.isHomeEquityLoanType) {
        this.productSearchRequest.loanInformation.calculateTotalLoanAmount = true;
        this.productSearchRequest.loanInformation.helocDrawnAmount = 0;
        this.productSearchRequest.loanInformation.helocLineAmount = 0;
        if (this.productSearchRequest.loanInformation.lienType === 'FirstLien') {
          this.productSearchRequest.loanInformation.secondLienAmount = 0;
        }
      }
    } else {
      this.calculateAndSetFundingFee();
    }
  }

  onMultipleLoanTypesChanged = () => {
    this.productSearchRequest.loanTypes = this.selectedLoanTypes.map(lt => lt.value);
    this.onLoanTypeChanged();
  }

  get isHomeEquityLoanType(): boolean {
    if (this.multipleLoanTypesVisible) {
      return this.productSearchRequest.loanTypes.includes('HomeEquity');
    }
    return this.productSearchRequest.loanInformation.loanType === 'HomeEquity';
  }

  get isVALoan(): boolean {
    if (this.multipleLoanTypesVisible) {
      return this.productSearchRequest.loanTypes.includes('VA');
    } else {
      return this.productSearchRequest.loanInformation.loanType === 'VA'
    }
  }

  get isFHA(): boolean {
    if (this.multipleLoanTypesVisible) {
      return _.isArray(this.productSearchRequest.loanTypes) ? this.productSearchRequest.loanTypes.includes('FHA') : false;
    }
    return this.productSearchRequest.loanInformation?.loanType === 'FHA';
  }


  get multipleLoanTypesVisible(): boolean {
    return this.visibleFeatureFields.includes('Settings_MultipleLoanTypes');
  }

  loanTermsChanged = () => {
    this.productSearchRequest.loanTerms = this.selectedLoanTerms.map(
      (lt) => lt.value
    );
  };

  amortizationTypeChanged = () => {
    this.productSearchRequest.amortizationTypes =
      this.selectedAmortizationTypes.map((aT) => aT.value);
  };

  armFixedTermChanged = () => {
    this.productSearchRequest.armFixedTerms = this.selectedArmFixedTerms.map(
      (aT) => aT.value
    );
  };

  productTypeChanged = () => {
    this.productSearchRequest.productTypes = this.selectedProductTypes.map(
      (sT) => sT.value
    );
  };

  private _ltvHasChanged: boolean = false;
  onLtvKeyDown = (e: any) => {
    if (e.key === 'Tab') {
      return;
    }
    this._ltvHasChanged = true;
  }

  onLtvValueChanged = () => {
    //do not re calculate if the value is not changed
    if (!this._ltvHasChanged) {
      return;
    }
    this.calculateBaseLoanAmount();
    if (this.isVALoan) {
      this.calculateAndSetFundingFee();
    }
    this._ltvHasChanged = false;
  };

  private _cltvHasChanged: boolean = false;
  onCltvKeyDown = (e: any) => {
    if (e.key === 'Tab') {
      return;
    }
    this._cltvHasChanged = true;
  }

  onCltvValueChanged = () => {
    //do not re calculate if the value is not changed
    if (!this._cltvHasChanged) {
      return;
    }
    if (this.productSearchRequest.loanInformation.lienType === 'FirstLien') {
      this.calculateSecondLienAmount();
    } else if (this.productSearchRequest.loanInformation.lienType === 'SecondLien') {
      this.calculateBaseLoanAmount();
    }
    this._cltvHasChanged = false;
  };

  private initializeAmortizationTypesForDefaultSearchCriteria = () => {
    this.selectedAmortizationTypes = [];
    if (this.productSearchRequest.amortizationTypes) {
      this.productSearchRequest.amortizationTypes.forEach((at) => {
        const selectedOption = this.amortizationTypeOptions.find(
          (o) => o.value === at
        );
        this.selectedAmortizationTypes.push(selectedOption);
      });
    } else {
      this.selectedAmortizationTypes = [];
      this.productSearchRequest.amortizationTypes = [];
    }
  };

  scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const section = element.getAttribute('section');
      setTimeout(() => {
        this.isLoanInfoSectionInvalid = section === 'loan-information';
        this.isBorrowerInfoSectionInvalid = section === 'borrower-info';
        this.isPropertyInfoSectionInvalid = section === 'property-info';
        this.isProductSearchCriteriaSectionInvalid =
          section === 'product-search-criteria';
        this.isAdditionalInfoSectionInvalid = section === 'additional-info';
        this.isCustomSectionInvalid = section === 'custom';
        setTimeout(() => {
          const parentElement = element.parentElement;
          let el = parentElement ? parentElement : element;
          if (el.innerText === "This field cannot be zero") {
            el = el.parentElement.parentElement ? el.parentElement.parentElement : el;
          }
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
        }, 150);
      }, 250);
    }
  };

  private fixDefaultValues = () => {
    if (!this._productSearchRequest.loanInformation.secondLienAmount) {
      this._productSearchRequest.loanInformation.secondLienAmount = 0;
    }
    if (!this._productSearchRequest.loanInformation.firstLienAmount) {
      this._productSearchRequest.loanInformation.firstLienAmount = 0;
    }
    if (!this._productSearchRequest.loanInformation.cashOutAmount) {
      this._productSearchRequest.loanInformation.cashOutAmount = 0;
    }
    if (!this.productSearchRequest.propertyInformation.appraisedValue) {
      this._productSearchRequest.propertyInformation.appraisedValue = 0;
    }
    if (!this._productSearchRequest.borrowerInformation.fico) {
      this._productSearchRequest.borrowerInformation.fico = null;
    }
    if (!this._productSearchRequest.representativeCreditScore) {
      this._productSearchRequest.representativeCreditScore = null;
    }
    if (
      !this._productSearchRequest.loanLevelDebtToIncomeRatio ||
      this._productSearchRequest.loanLevelDebtToIncomeRatio === 999.99
    ) {
      this.productSearchRequest.loanLevelDebtToIncomeRatio = null;
    } else if (this._productSearchRequest.loanLevelDebtToIncomeRatio >= 1) {
      this._productSearchRequest.loanLevelDebtToIncomeRatio = 1;
    }
    if (
      this._productSearchRequest.loanInformation.nonOccupyingCoBorrower == null
    ) {
      this._productSearchRequest.loanInformation.nonOccupyingCoBorrower = false;
    }
    if (!this._productSearchRequest.loanInformation.lienType) {
      this._productSearchRequest.loanInformation.lienType = 'FirstLien';
    }
    if (!this._productSearchRequest.loanInformation.expandedGuidelines) {
      this._productSearchRequest.loanInformation.expandedGuidelines = new ExpandedGuidelines();
    }

    if (this._productSearchRequest.propertyInformation.county && this._productSearchRequest.propertyInformation.county.includes("County")) {
      this._productSearchRequest.propertyInformation.county = this._productSearchRequest.propertyInformation.county.replace("County", "").trim().toUpperCase();
    }
  };

  private initializeLoanTermsForDefaultSearchCriteria = () => {
    this.selectedLoanTerms = [];
    if (this.productSearchRequest.loanTerms) {
      this.productSearchRequest.loanTerms.forEach((lT) => {
        const selectedOption = this.loanTermOptions.find((o) => o.value === lT);
        this.selectedLoanTerms.push(selectedOption);
      });
    } else {
      this.selectedLoanTerms = [];
      this.productSearchRequest.loanTerms = [];
    }
  };

  private initializeProductTypesForDefaultSearchCriteria = () => {
    this.selectedProductTypes = [];
    if (this.productSearchRequest.productTypes) {
      this.productSearchRequest.productTypes.forEach((pT) => {
        const selectedOption = this.productTypeOptions.find(
          (o) => o.value === pT
        );
        this.selectedProductTypes.push(selectedOption);
      });
    } else {
      this.selectedProductTypes = [];
      this.productSearchRequest.productTypes = [];
    }
  };

  private calculateAndSetFundingFee = () => {
    if (this.productSearchRequest.loanInformation.loanPurpose === 'Purchase') {
      const downPaymentPercent = 1 - this.productSearchRequest.loanInformation.ltv;
      if (this.productSearchRequest.borrowerInformation.vaFirstTimeUse) {
        if (downPaymentPercent < 0.05) {
          this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = 0.023;
        } else if (downPaymentPercent >= 0.05 && downPaymentPercent < 0.1) {
          this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = 0.0165;
        } else {
          this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = 0.014;
        }
      } else {
        if (downPaymentPercent < 0.05) {
          this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = 0.036;
        } else if (downPaymentPercent >= 0.05 && downPaymentPercent < 0.1) {
          this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = 0.0165;
        } else {
          this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = 0.014;
        }
      }
    } else if (
      this.productSearchRequest.loanInformation.loanPurpose === 'Refinance') {
      if (
        this.productSearchRequest.loanInformation.refinancePurpose === 'CashOutOther' ||
        this.productSearchRequest.loanInformation.refinancePurpose === 'CashOutLimited'
      ) {
        if (this.productSearchRequest.borrowerInformation.vaFirstTimeUse) {
          this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = 0.023;
        } else {
          this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = 0.036;
        }
      } else if (
        this.productSearchRequest.loanInformation.refinancePurpose === 'NoCashOutStreamlinedRefinance'
      ) {
        this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = 0.05;
      }
    }
  };

  private calculateTotalLoanAmount() {
    this.productSearchRequest.loanInformation.totalLoanAmount = this.productSearchRequest.loanInformation.baseLoanAmount +
      this.productSearchRequest.loanInformation.upfrontPmiMipFfGfFinancedAmount;
  }

  private calculatePmiFeeAmount() {
    this.productSearchRequest.loanInformation.upfrontPmiMipFfGfAmount =
      this.productSearchRequest.loanInformation.baseLoanAmount * this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent;
  }

  private calculateFinanceAmount() {
    this.productSearchRequest.loanInformation.upfrontPmiMipFfGfFinancedAmount =
      this.productSearchRequest.loanInformation.upfrontPmiMipFfGfAmount -
      this.productSearchRequest.loanInformation.upfrontPmiMipFfGfPaidinCash;
  }

  onUpfrontPmiMipFfGfFinancedAmountChanged = () => {
    this.calculateTotalLoanAmount();
  }

  onUpfrontPmiMipFfGfAmountChanged = () => {
    this.calculateFinanceAmount();
  }

  onUpfrontPmiMipFfGfPaidinCashChanged = () => {
    this.calculateFinanceAmount();
  }

  onUpfrontPmiMipFfGfPercentChanged = () => {
    this.calculatePmiFeeAmount();
  }

  private calculateLtvAndCltv = (): void => {
    const minValue = this.minOfSalesPriceAndAppraisedValue();
    if (minValue == 0) {
      this.productSearchRequest.loanInformation.ltv = 0;
      this.productSearchRequest.loanInformation.cltv = 0;
    } else {
      this.productSearchRequest.loanInformation.ltv =
        this.productSearchRequest.loanInformation.firstLienAmount > 0
          ? this.productSearchRequest.loanInformation.firstLienAmount / minValue
          : this.productSearchRequest.loanInformation.baseLoanAmount / minValue;
      if (
        (this.productSearchRequest.loanInformation.secondLienAmount == 0 ||
          !this.productSearchRequest.loanInformation.secondLienAmount)
        && (this.productSearchRequest.loanInformation.firstLienAmount == 0 ||
          !this.productSearchRequest.loanInformation.firstLienAmount)
        && (this.productSearchRequest.loanInformation.helocLineAmount == 0 ||
          !this.productSearchRequest.loanInformation.helocLineAmount)
      ) {
        this.productSearchRequest.loanInformation.cltv =
          this.productSearchRequest.loanInformation.ltv;
      }
      else if (this.productSearchRequest.loanInformation.firstLienAmount > 0) {
        this.productSearchRequest.loanInformation.cltv =
          (this.productSearchRequest.loanInformation.baseLoanAmount +
            this.productSearchRequest.loanInformation.firstLienAmount) /
          minValue;
      }
      else if (this.productSearchRequest.loanInformation.helocLineAmount > 0) {
        this.productSearchRequest.loanInformation.cltv =
          (this.productSearchRequest.loanInformation.baseLoanAmount +
            this.productSearchRequest.loanInformation.helocLineAmount) /
          minValue;
      } else {
        this.productSearchRequest.loanInformation.cltv =
          (this.productSearchRequest.loanInformation.baseLoanAmount +
            this.productSearchRequest.loanInformation.secondLienAmount) /
          minValue;
      }
    }
    if (this.isVALoan) {
      this.calculateAndSetFundingFee();
    }
  };

  private calculateBaseLoanAmount = () => {
    let minValue = this.minOfSalesPriceAndAppraisedValue();
    if (!minValue) {
      minValue = 0;
    }
    if (this.productSearchRequest.loanInformation.firstLienAmount > 0) {
      this.productSearchRequest.loanInformation.firstLienAmount =
        this.productSearchRequest.loanInformation.ltv * minValue;
    } else {
      this.productSearchRequest.loanInformation.baseLoanAmount =
        this.productSearchRequest.loanInformation.ltv * minValue;
    }
    if (
      (this.productSearchRequest.loanInformation.secondLienAmount == 0 ||
        !this.productSearchRequest.loanInformation.secondLienAmount)
      && (this.productSearchRequest.loanInformation.firstLienAmount == 0 ||
        !this.productSearchRequest.loanInformation.firstLienAmount)
      && (this.productSearchRequest.loanInformation.helocLineAmount == 0 ||
        !this.productSearchRequest.loanInformation.helocLineAmount)
    ) {
      this.productSearchRequest.loanInformation.cltv =
        this.productSearchRequest.loanInformation.ltv;
    }
  };

  private calculateSecondLienAmount = () => {
    const minValue = this.minOfSalesPriceAndAppraisedValue();
    this.productSearchRequest.loanInformation.secondLienAmount =
      this.productSearchRequest.loanInformation.cltv * minValue -
      this.productSearchRequest.loanInformation.baseLoanAmount;
    if (
      (this.productSearchRequest.loanInformation.secondLienAmount == 0 ||
        !this.productSearchRequest.loanInformation.secondLienAmount)
      && (this.productSearchRequest.loanInformation.firstLienAmount == 0 ||
        !this.productSearchRequest.loanInformation.firstLienAmount)
      && (this.productSearchRequest.loanInformation.helocLineAmount == 0 ||
        !this.productSearchRequest.loanInformation.helocLineAmount)
    ) {
      this.productSearchRequest.loanInformation.cltv =
        this.productSearchRequest.loanInformation.ltv;
    }
  };

  private minOfSalesPriceAndAppraisedValue = () => {
    if (this.productSearchRequest.loanInformation.loanPurpose === 'Purchase') {
      var salesPrice = this.productSearchRequest.propertyInformation.salesPrice
        ? this.productSearchRequest.propertyInformation.salesPrice
        : Number.MAX_VALUE;
      var appraisedValue = this.productSearchRequest.propertyInformation
        .appraisedValue
        ? this.productSearchRequest.propertyInformation.appraisedValue
        : Number.MAX_VALUE;
      var min = Math.min(salesPrice, appraisedValue);
      return min != Number.MAX_VALUE ? min : 0;
    } else {
      // Refinance
      return this.productSearchRequest.propertyInformation.appraisedValue;
    }
  };

  private lookupZipCodeRelatedInfo = (zipCode: string) => {
    this._zipCodeService.lookupZipCode(zipCode, false).subscribe((result) => {
      if (result.length > 1) {
        const modalRef = this._modalService.open(
          CityCountyPickerDialogComponent,
          Constants.modalOptions.medium
        );
        modalRef.componentInstance.optionsToPickFrom = result;
        modalRef.result.then((selectedStateAndCounty: CityCountyState) => {
          if (selectedStateAndCounty) {
            const selectedState = this.stateOptions.find(
              (state) => state.name === selectedStateAndCounty.state
            );
            if (selectedState) {
              this.productSearchRequest.propertyInformation.state =
                selectedState.value;
              this.productSearchRequest.propertyInformation.county =
                selectedStateAndCounty.county;
              this.productSearchRequest.propertyInformation.countyFips =
                selectedStateAndCounty.countyFips;
            }
          }
        });
      } else if (result.length === 1) {
        const selectedState = this.stateOptions.find(
          (state) => state.name === result[0].state
        );
        if (selectedState) {
          this.productSearchRequest.propertyInformation.state =
            selectedState.value;
          this.productSearchRequest.propertyInformation.county =
            result[0].county;
          this.productSearchRequest.propertyInformation.countyFips = result[0].countyFips;
        }
      }
    });
  };

  private getSplittedEnumValue = (enumValue: string): string => {
    return Utils.splitCamelCaseStringCorrectly(enumValue);
  }

  private removeRefinancePurpose = () => {
    this.productSearchRequest.loanInformation.refinancePurpose = null;
  }

  private groupSelectedVendorPricingProfiles() {
    if (!this._enabledChannels?.length) {
      return;
    }
    this.groupedVendorProfiles = _.chain(this._enabledChannels)
      .groupBy("externalCompanyId")
      .map((compGrp, compId) => {
        const externalCompanyName = compId === "undefined"
          ? ""
          : this.getExternalCompanyNameById(Number(compId));
        const branchGrping = !externalCompanyName ? compGrp : _.chain(compGrp)
          .groupBy("branchId")
          .map((branchGrp, branchId) => {
            const branchName = branchId === "undefined"
              ? ""
              : this.getBranchNameById(Number(branchId));
            return {
              branchName,
              branchCreds: branchGrp
            };
          }).value();
        return {
          externalCompanyName,
          externalCompanyCreds: branchGrping
        };
      })
      .orderBy("externalCompanyName")
      .value();
  }

  private getExternalCompanyNameById(externalCompanyId: number) {
    const matchingCompany = this.applicationContext?.globalConfig?.externalCompanies.find(ec => ec.externalCompanyId === externalCompanyId);
    return matchingCompany?.name || '';
  }

  private getBranchNameById(branchId: number) {
    const matchingBranch = this.applicationContext?.globalConfig?.branches.find(b => b.branchId === branchId);
    return matchingBranch?.branchName || '';
  }

  private loadFieldConfigsForVendor = () => {
    this.readonlyFields = [];
    this.hiddenFields = [];
    this.hiddenEnumOptionsByField.clear();

    if (this._fieldSpecs?.length > 0) {
      this._fieldSpecs.forEach(spec => {
        if (this.transactionType) {
          if (spec.repriceFieldAccess == PricingFieldAccess.ReadOnly) {
            this.readonlyFields.push(spec.fieldName);
          }

          if (spec.repriceFieldAccess == PricingFieldAccess.Hidden) {
            this.hiddenFields.push(spec.fieldName);
          }
        }
        else {
          if (spec.fieldAccess == PricingFieldAccess.ReadOnly) {
            this.readonlyFields.push(spec.fieldName);
          }

          if (spec.fieldAccess == PricingFieldAccess.Hidden) {
            this.hiddenFields.push(spec.fieldName);
          }
        }

        if (spec.hiddenEnumOptions?.length) {
          this.hiddenEnumOptionsByField.set(spec.fieldName, spec.hiddenEnumOptions);
        }

        if (spec.defaultValue) {
          let modelFieldName = spec.fieldName.split(".").map(p => Utils.lowerCaseFirstLetter(p)).join(".");
          let modelFieldNameWords = modelFieldName.split(".");

          let requestModelRef = modelFieldName;

          if (modelFieldNameWords.length == 2 && modelFieldNameWords[0] == "expandedGuidelines") {
            requestModelRef = "loanInformation." + modelFieldName;
          }

          const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
          const setFieldValue = (spec) => {
            // field type is List ?
            if (["amortizationType", "productType"].includes(requestModelRef)) {
              requestModelRef += "s";

              let defaultValueAsArray = spec.defaultValue.split(",") as string[];
              _.set(this.productSearchRequest, requestModelRef, defaultValueAsArray);

              if (requestModelRef == "productTypes") {
                this.initializeProductTypesForDefaultSearchCriteria();
              }
              else if (requestModelRef == "amortizationTypes") {
                this.initializeAmortizationTypesForDefaultSearchCriteria();
              }

            }
            else {
              if (["true", "false"].includes(spec.defaultValue)) {
                _.set(this.productSearchRequest, requestModelRef, Boolean(spec.defaultValue));
              }
              else if (isNumeric(spec.defaultValue)) {
                _.set(this.productSearchRequest, requestModelRef, Number(spec.defaultValue));
              }
              else {
                _.set(this.productSearchRequest, requestModelRef, spec.defaultValue);
              }
            }

            // console.log(spec.fieldName, Object.getOwnPropertyNames(this.productSearchRequest));
          }

          if (!this.canPriceApplyToLoan) { // isQuickPricer
            setFieldValue(spec);
          }
          else { // isLoan
            if (_.isNil(_.get(this.productSearchRequest, requestModelRef))) {
              setFieldValue(spec);
            }
          }


        }

      });

      this.amortizationTypeOptions = this.amortizationTypeOptions
        .filter(t => !this.hiddenEnumOptionsByField.get('AmortizationType')?.includes(t.alternateValue));
      this.filteredLoanTypeOptions = this.filteredLoanTypeOptions
        .filter(t => !this.hiddenEnumOptionsByField.get('LoanInformation.LoanType')?.includes(t.alternateValue));
      this.productTypeOptions = this.productTypeOptions
        .filter(t => !this.hiddenEnumOptionsByField.get('ProductType')?.includes(t.alternateValue));
    }
  }

}


