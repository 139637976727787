<nav class="navbar-custom navbar navbar-expand-lg">
  <div class="container-fluid tpo-mobile-menu-container-style">
    <a class="navbar-brand" href="#">
      <img [src]="companyLogoUrl" height="59" style="height:59px !important" alt="Company Logo" class="me-1">
    </a>
    <button class="nav-link button-menu-mobile d-none-non-mobile" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <i data-feather="menu" class="align-self-center topbar-icon topbar-icon-mobile tpo-topbar-icon-mobile"
        style="color: #d84d38!important;"></i>
    </button>

    <div class="collapse navbar-collapse tpo-mobile-menu-style justify-content-between d-none-mobile">

      <ul class="list-unstyled d-flex topbar-nav mb-0">
        <li class="dropdown" *ngIf="isNewApplicationEnabled || isNewLeadReferralEnabled">
          <a class="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <span><i class="fas fa-plus caret-style"></i> New</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <a *ngIf="isNewApplicationEnabled" class="dropdown-item" style="cursor:pointer;"
              [routerLink]="['/tpo/new-application']">
              <i class="far fa-flag mx-1"></i> New Application
            </a>
            <a *ngIf="isNewLeadReferralEnabled" class="dropdown-item" style="cursor:pointer;"
              [routerLink]="['/tpo/new-lead-referral']">
              <i class="fas fa-university mx-1"></i> New Lead Referral
            </a>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-effect waves-light" aria-current="page" [routerLink]="['/tpo/dashboard']"> Dashboard
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-effect waves-light" aria-current="page" [routerLink]="['/tpo/pipeline']"> Pipeline
          </a>
        </li>
        <li class="nav-item" [hidden]="!applicationContext.userPermissions.pricingEnabled">
          <a class="nav-link waves-effect waves-light" aria-current="page" [routerLink]="['/tpo/quick-pricer']">
            Quick Pricer </a>
        </li>
        <li class="nav-item" *ngIf="applicationContext.userPermissions.companyId == 222">
          <a class="nav-link waves-effect waves-light" aria-current="page" [routerLink]="['/tpo/appraisal-management']">
            Appraisal Management </a>
        </li>
        <li class="dropdown">
          <a class="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <span>Recent Apps <i class="fas fa-angle-down caret-style"></i></span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item" style="cursor:pointer;min-height: 20px;line-height: 20px;"
              *ngFor="let app of recentApps" [routerLink]="['/tpo/app-details/' + app.applicationId + '/loan-summary']">
              <i class="far fa-folder mx-1"></i>{{app.displayText}}
            </a>
          </ul>
        </li>
        <li class="dropdown" *ngIf="resourceLinks.length > 0">
          <a class="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <span id="resource-link">Resources <i class="fas fa-angle-down caret-style"></i></span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li *ngFor="let rl of resourceLinks">
              <a *ngIf="rl.url" class="dropdown-item" style="cursor:pointer;min-height: 20px;line-height: 20px;"
                href="{{rl.url || ''}}" target="_blank">
                {{rl.displayText}}
              </a>
              <a *ngIf="rl.routerLink" class="dropdown-item" style="cursor:pointer;min-height: 20px;line-height: 20px;"
                [routerLink]="[rl.routerLink]">
                {{rl.displayText}}
              </a>
            </li>


          </ul>
        </li>
      </ul>
      <ul class="list-unstyled d-flex topbar-nav mb-0 me-3">
        <li class="dropdown hide-phone">

        </li>

        <li class="dropdown notification-list">

        </li>

        <li class="dropdown" *ngIf="currentlyLoggedInUser">
          <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-bs-toggle="dropdown" href="#"
            role="button" aria-haspopup="false" aria-expanded="false">
            <span class="ms-1 nav-user-name pe-2">{{currentlyLoggedInUserProfile.firstName}}
            </span>
            <img [src]="userAvatarUrl" alt="profile-user" class="rounded-circle thumb-xs" />
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item classic-dropdown-item-padding font-14" [routerLink]="['/tpo/profile']">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-user align-self-center icon-xs icon-dual me-1">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg> Profile
            </a>
            <a class="dropdown-item classic-dropdown-item-padding font-14" [routerLink]="['/tpo/settings']">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-settings align-self-center icon-xs icon-dual me-1">
                <circle cx="12" cy="12" r="3"></circle>
                <path
                  d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                </path>
              </svg> Settings
            </a>
            <div class="dropdown-divider mb-1 mt-1"></div>

            <ng-container *ngIf="currentUserIsPrimaryRoleUser && wizardUrls.length === 1">
              <a class="dropdown-item classic-dropdown-item-padding font-14" (click)="copyLink(wizardUrls[0])">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-activity align-self-center icon-xs icon-dual me-1">
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
                </svg> Online App Link
              </a>
              <div class="dropdown-divider mb-1 mt-1"></div>
            </ng-container>
            <ng-container *ngIf="companies.length > 1">
              <a class="dropdown-item classic-dropdown-item-padding font-14" (click)="onSwitchCompanyClicked()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-zap icon-dual">
                  <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                </svg> Switch Companies
              </a>
            </ng-container>

            <div class="dropdown-divider mb-0"></div>
            <a class="dropdown-item classic-dropdown-item-padding font-14" (click)="onLogoutClicked()">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-power align-self-center icon-xs icon-dual me-1">
                <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                <line x1="12" y1="2" x2="12" y2="12"></line>
              </svg> Logout
            </a>
          </div>
        </li>
      </ul>
    </div>

    <div class="d-none-non-mobile flex-line-break"></div>

    <div class="collapse navbar-collapse d-none-non-mobile" id="navbarSupportedContent2">

      <ul class="list-unstyled d-flex topbar-nav mb-0 h-auto flex-column tpo-mobile-menu-style ">
        <li class="dropdown hide-phone">

        </li>

        <li class="dropdown notification-list">

        </li>

        <li class="dropdown" *ngIf="currentlyLoggedInUser">
          <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-bs-toggle="dropdown" href="#"
            role="button" aria-haspopup="false" aria-expanded="false">
            <i class="fas fa-angle-left caret-style" style="font-size: 15px;"></i>
            <img [src]="userAvatarUrl" alt="profile-user" class="rounded-circle thumb-xs ms-1" />
            <span class="ms-1 nav-user-name pe-2">{{currentlyLoggedInUserProfile.firstName}}
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item classic-dropdown-item-padding font-14" [routerLink]="['/tpo/profile']">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-user align-self-center icon-xs icon-dual me-1">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg> Profile
            </a>
            <a class="dropdown-item classic-dropdown-item-padding font-14" [routerLink]="['/tpo/settings']">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-settings align-self-center icon-xs icon-dual me-1">
                <circle cx="12" cy="12" r="3"></circle>
                <path
                  d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                </path>
              </svg> Settings
            </a>
            <div class="dropdown-divider mb-1 mt-1"></div>

            <ng-container *ngIf="currentUserIsPrimaryRoleUser && wizardUrls.length === 1">
              <a class="dropdown-item classic-dropdown-item-padding font-14" (click)="copyLink(wizardUrls[0])">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-activity align-self-center icon-xs icon-dual me-1">
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
                </svg> Online App Link
              </a>
              <div class="dropdown-divider mb-1 mt-1"></div>
            </ng-container>
            <ng-container *ngIf="companies.length > 1">
              <a class="dropdown-item classic-dropdown-item-padding font-14" (click)="onSwitchCompanyClicked()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-zap icon-dual">
                  <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                </svg> Switch Companies
              </a>
            </ng-container>

            <div class="dropdown-divider mb-0"></div>
            <a class="dropdown-item classic-dropdown-item-padding font-14" (click)="onLogoutClicked()">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-power align-self-center icon-xs icon-dual me-1">
                <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                <line x1="12" y1="2" x2="12" y2="12"></line>
              </svg> Logout
            </a>
          </div>
        </li>
        <li class="dropdown-divider mb-1 mt-1"></li>
        <li class="dropdown" *ngIf="isNewApplicationEnabled || isNewLeadReferralEnabled">
          <a class="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <span><i class="fas fa-plus caret-style"></i> New</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <a *ngIf="isNewApplicationEnabled" class="dropdown-item" style="cursor:pointer;"
              [routerLink]="['/tpo/new-application']">
              <i class="far fa-flag mx-1"></i> New Application
            </a>
            <a *ngIf="isNewLeadReferralEnabled" class="dropdown-item" style="cursor:pointer;"
              [routerLink]="['/tpo/new-lead-referral']">
              <i class="fas fa-university mx-1"></i> New Lead Referral
            </a>
          </ul>
        </li>
        <li class="dropdown-divider mb-1 mt-1"></li>
        <li class="nav-item">
          <a class="nav-link waves-effect waves-light" aria-current="page" [routerLink]="['/tpo/dashboard']"> Dashboard
          </a>
        </li>
        <li class="dropdown-divider mb-1 mt-1"></li>
        <li class="nav-item">
          <a class="nav-link waves-effect waves-light" aria-current="page" [routerLink]="['/tpo/pipeline']"> Pipeline
          </a>
        </li>
        <li class="dropdown-divider mb-1 mt-1"></li>
        <li class="nav-item" [hidden]="!applicationContext.userPermissions.pricingEnabled">
          <a class="nav-link waves-effect waves-light" aria-current="page" [routerLink]="['/tpo/quick-pricer']">
            Quick Pricer </a>
        </li>
        <li class="nav-item" *ngIf="applicationContext.userPermissions.companyId == 222">
          <a class="nav-link waves-effect waves-light" aria-current="page" [routerLink]="['/tpo/appraisal-management']">
            Appraisal Management </a>
        </li>
        <li class="dropdown-divider mb-1 mt-1"></li>
        <li class="dropdown">
          <a class="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <span><i class="fas fa-angle-left caret-style"></i> Recent Apps</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item" style="cursor:pointer;min-height: 20px;line-height: 20px;"
              *ngFor="let app of recentApps" [routerLink]="['/tpo/app-details/' + app.applicationId + '/loan-summary']">
              <i class="far fa-folder mx-1"></i>{{app.displayText}}
            </a>
          </ul>
        </li>
        <li class="dropdown" *ngIf="resourceLinks.length > 0">
          <a class="nav-link dropdown-toggle waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button"
            aria-haspopup="false" aria-expanded="false">
            <span id="resource-link">Resources <i class="fas fa-angle-down caret-style"></i></span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item" style="cursor:pointer;min-height: 20px;line-height: 20px;"
              *ngFor="let rl of resourceLinks" href="{{rl.url}}" target="_blank">
              {{rl.displayText}}
            </a>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>