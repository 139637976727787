<div class="modal-header">
    <h5 class="modal-title">Purchase Credit Calculator</h5>
    <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
    <form #sourceOfFundsForm="ngForm" novalidate id="sourceOfFundsForm" name="sourceOfFundsForm">
        <div class="loan-app">
            <div class="grid-layout">
                <div *ngFor="let purchaseCredit of purchaseCredits; let index = index">
                    <div class="grid-layout-row">
                        <div class="offset-6 col-3">
                            <urla-boolean-checkbox layout="single" [label]="'Closing Adjustment'"
                                name="isClosingAdjustment_{{index}}" [(ngModel)]="purchaseCredit.isClosingAdjustment">
                            </urla-boolean-checkbox>
                        </div>
                        <div class="col-3">
                            <urla-boolean-checkbox layout="single" [label]="'Paid Outside Closing'"
                                name="isPaidOutsideClosing_{{index}}" [(ngModel)]="purchaseCredit.isPaidOutsideClosing">
                            </urla-boolean-checkbox>
                        </div>
                    </div>
                    <div class="grid-layout-row mb-2">
                        <div class="col-sm-4">
                            <urla-dropdown [label]="'Down Payment Type'" name="downPaymentType_{{index}}"
                                [(ngModel)]="purchaseCredit.purchaseCreditType" [options]="purchaseCreditTypes"
                                (ngModelChange)="onPurchaseCreditTypeChanged(purchaseCredit)">
                            </urla-dropdown>
                        </div>
                        <div class="col-sm-4">
                            <urla-dropdown [label]="'Source Type'" name="sourceType_{{index}}" [required]="true"
                                [(ngModel)]="purchaseCredit.sourceType" #sourceType="ngModel"
                                [options]="downPaymentSourceTypes"
                                [ngClass]="{'is-invalid' : sourceType && sourceType.touched  && sourceType.invalid}">
                            </urla-dropdown>
                            <div class="invalid-feedback px-3">Please select a Source Type.</div>
                        </div>
                        <div class="col-sm-3">
                            <urla-currency-input [label]="'Amount'" name="purchaseCreditAmount_{{index}}"
                                [(ngModel)]="purchaseCredit.purchaseCreditAmount" #purchaseCreditAmount="ngModel"
                                [isZeroValid]="true" [required]="true" (ngModelChange)="onBalanceChanged()"
                                [ngClass]="{'is-invalid' : purchaseCreditAmount && purchaseCreditAmount.touched  && purchaseCreditAmount.invalid}">
                            </urla-currency-input>
                            <div class="invalid-feedback px-3">Please enter an Amount.</div>
                        </div>
                        <div class="col-sm-1 d-flex align-items-center pt-4 flex-row"
                            [ngClass]="{'mb-4': sourceType.touched && sourceType.invalid || purchaseCreditAmount.touched && purchaseCreditAmount.invalid}">
                            <a *ngIf="!(activeDeletionIndex === index)" class="table-icon-button"
                                (click)="onDeletePurchaseCreditClicked(index)"><i class="fa fa-trash-alt me-0"></i>
                            </a>
                            <a *ngIf="activeDeletionIndex === index" class="table-icon-button hoverSuccess"
                                (click)="onDeletePurchaseCreditConfirmClicked(purchaseCredit)"><i
                                    class="fa fa-check me-1"></i>
                            </a>
                            <a *ngIf="activeDeletionIndex === index" class="table-icon-button hoverDanger"
                                (click)="onDeletePurchaseCreditCancelClicked()"><i class="fa fa-times me-0"></i>
                            </a>
                        </div>
                    </div>
                    <div class="grid-layout-row mb-2"
                        *ngIf="purchaseCredit.purchaseCreditType == 'Other' || purchaseCredit.sourceType == 'Other'">
                        <div class="col-sm-4">
                          <urla-text-input
                            #purchaseCreditTypeOtherDescription='ngModel'
                            *ngIf="purchaseCredit.purchaseCreditType == 'Other'"
                            [(ngModel)]='purchaseCredit.purchaseCreditTypeOtherDescription'
                            [class.is-invalid]='purchaseCreditTypeOtherDescription.touched && purchaseCreditTypeOtherDescription.invalid'
                            [required]='true'
                            label='Credit Type Description'
                            name='purchaseCreditTypeOtherDescription_{{index}}'
                          ></urla-text-input>

                          <div class='invalid-feedback px-3'>Please enter a Credit Type Description.</div>
                        </div>
                        <div class="col-sm-4">
                          <urla-text-input
                            #sourceTypeOtherDescription='ngModel'
                            *ngIf="purchaseCredit.sourceType == 'Other'"
                            [(ngModel)]='purchaseCredit.sourceTypeOtherDescription'
                            [class.is-invalid]='sourceTypeOtherDescription.touched && sourceTypeOtherDescription.invalid'
                            [required]='true'
                            label='Source Type Description'
                            name='sourceTypeOtherDescription_{{index}}'
                          ></urla-text-input>

                          <div class='invalid-feedback px-3'>Please enter a Source Type Description.</div>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </form>
    <a (click)="addAccount()" role="button"><i class="fas fa-plus me-2"></i>Add Account</a>
</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <span> Total Purchase Credits: <b> {{totalDownPayment | currency}} </b> </span>
    </div>
    <div class="button-items">
        <button type="button" class="btn btn-primary right" (click)="onOkClicked()">Ok</button>
        <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">Cancel</button>
    </div>
</div>
