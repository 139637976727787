  <ng-container *ngIf="!isCompanyPulseProcessing">
    <loading-indicator *ngIf="!application" [loadingMessage]="'Loading loan summary, please wait...'"></loading-indicator>
    <div *ngIf="application">
      <div class="container">
        <div class="card">
          <div class="card-body">
            <div class="row g-3">
              <div class="col-xs-6 col-sm-6" *ngIf="borrowers && borrowers.length >= 1">
                <tpo-borrower [borrowers]="borrowers"></tpo-borrower>
              </div>
              <div class="col-xs-6 col-sm-6">
                <tpo-loan-status
                  [loanStatus]="loanStatus"
                  [application]="application"
                  [isTpoSubmitted]="isTpoSubmitted">
                </tpo-loan-status>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-xs-12 col-sm-12">
                <tpo-loan-detail [application]="application" [mortgage]="mortgage"
                  [mortgageCalculationDetails]="mortgageCalculationDetails">
                </tpo-loan-detail>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-xs-6 col-sm-6">
                <tpo-origination-info [application]="application"></tpo-origination-info>
              </div>
              <div class="col-xs-6 col-sm-6">
                <tpo-closing-info [application]="application"></tpo-closing-info>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isCompanyPulseProcessing">
    <tpo-loan-summary-v2></tpo-loan-summary-v2>
  </ng-container>
