import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { CreditReportHistoryComponent, CreditReportsComponent, CreditRunComponent } from ".";
import { ImportLiabilitiesComponent } from "./components/import-liabilities/import-liabilities.component";
import { CreditService } from "./services/credit.service";
import { CreditInquiryComponent } from './components/credit-inquiry/credit-inquiry.component';
import { BorrowerCreditInquiryComponent } from './components/borrower-credit-inquiry/borrower-credit-inquiry.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        CreditReportsComponent,
        CreditRunComponent,
        CreditReportHistoryComponent,
        ImportLiabilitiesComponent,
        CreditInquiryComponent,
        BorrowerCreditInquiryComponent
    ],
    exports: [
        CreditReportsComponent,
        CreditRunComponent,
        CreditReportHistoryComponent,
        ImportLiabilitiesComponent,
        CreditInquiryComponent,
        BorrowerCreditInquiryComponent
    ],
    providers: [
        CreditService
    ]
})
export class CreditModule {
  constructor() {
  }
}


