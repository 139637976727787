import { MortgageFieldConfig } from './models/urla-fields-config.model';
import { UrlaRequiredState } from './services/utility.service';

export type CleanUpDependentFieldFunction =
  (source: Record<string, any>) => void;

export interface CreateCleanUpDependentFieldFunctionParams {
  sourcePropertyName: string;
  dependentPropertyName: string;
  cleaningValue: any;
}

/**
 * Creates a function that will clean up a dependent field if the source field
 * has a specific value.
 * @param {CreateCleanUpDependentFieldFunctionParams} params The parameters for
 * the function.
 * @param {string} params.sourcePropertyName The name of the source property.
 * @param {string} params.dependentPropertyName The name of the dependent
 * property.
 * @param {any} params.cleaningValue The value that will trigger the dependent
 * field to be cleaned up.
 */
export function createCleanUpDependentFieldFunction(
  {
    sourcePropertyName,
    dependentPropertyName,
    cleaningValue,
  }: CreateCleanUpDependentFieldFunctionParams,
): CleanUpDependentFieldFunction {
  return function(source: Record<string, any>): void {
    const value = source[sourcePropertyName];
    if (value === cleaningValue || value == null) {
      delete source[dependentPropertyName];
    }
  };
}

export type CleanUpOtherDescriptionFunctionParams
  = Omit<CreateCleanUpDependentFieldFunctionParams, 'dependentPropertyName'>;

/**
 * Creates a function that will clean up the other description field if the
 * source field has a specific value.
 * @param {CleanUpOtherDescriptionFunctionParams} params The parameters for the
 * function.
 * @param {string} params.sourcePropertyName The name of the source property.
 * @param {any} params.cleaningValue The value that will trigger the dependent
 * field to be cleaned up.
 */
export function createCleanUpOtherDescriptionFunction(
  params: CleanUpOtherDescriptionFunctionParams,
): CleanUpDependentFieldFunction {
  return createCleanUpDependentFieldFunction({
    ...params,
    dependentPropertyName: `${params.sourcePropertyName}OtherDescription`,
  });
}

/**
 * Creates a function that will return whether a field is required.
 * @param {{[p: string]: MortgageFieldConfig}} config The configuration for the
 * function.
 * @returns {(fieldName: string) => boolean} The function that will return
 * whether a field is required.
 */
export function createIsRequiredFunction(
  config: {},
): (fieldName: string) => boolean {
  return function(fieldName: string): boolean {
    const value = config[fieldName]?.required;
    return value === 'required' || value === 'requested';
  };
}
