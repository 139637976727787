import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { PurchaseCredit } from 'src/app/models/mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { autoId } from '../../../../../../../core/services/utils';
import { map, race, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { resetObjectTo } from '../../qa-fi-income/reset-object.to';

@Component({
  selector: 'qa-fi-credit-editor',
  templateUrl: './qa-fi-credit-editor.component.html',
  styleUrls: ['./qa-fi-credit-editor.component.scss']
})
export class QaFiCreditEditorComponent implements OnInit, OnDestroy {

  @Input() credit: PurchaseCredit;
  @Output() creditChange = new EventEmitter<PurchaseCredit>();

  @Input() mode: 'create' | 'edit' = 'create';

  @Input() creditTypes: EnumerationItem[] = [];

  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('form') protected formElement: NgForm | undefined;

  private readonly _id: string = `qa-fi-credit-editor-${autoId()}`;
  private _originalCredit: PurchaseCredit;

  private _savingChangesSubscription?: Subscription;

  private readonly _save$ = new Subject<void>();
  private readonly _destroyed$ = new Subject<void>();

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false
  };

  ngOnInit(): void {
    this._originalCredit = _.cloneDeep(this.credit);

    setTimeout(() => {
      this.subscribeToSavingChanges();
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private subscribeToSavingChanges(): void {
    this._savingChangesSubscription?.unsubscribe();
    const subscription = this._savingChangesSubscription = this.formElement.valueChanges.pipe(
      takeUntil(this._destroyed$),
    ).subscribe(() => {
      this.enqueueSave();
    });

    const cancelOrDestroy$ = race(
      this._destroyed$.pipe(map(() => true)),
      this.cancel.pipe(map(() => false)),
    ).pipe(
      tap((shouldSave) => {
        if (shouldSave) {
          // Prevent ExpressionChangedAfterItHasBeenCheckedError.
          setTimeout(() => {
            this.emitUpdate();
          });
        }
      }),
    );

    subscription.add(
      this._save$.pipe(
        takeUntil(cancelOrDestroy$),
        debounceTime(200),
      ).subscribe(() => {
        this.emitUpdate();
      }),
    );
  }

  private enqueueSave(): void {
    this._save$.next();
  }

  private emitUpdate(): void {
    this.creditChange.emit(this.credit);
  }

  protected onClickCancel() {
    this.resetCreditChanges();

    this.cancel.emit();
  }

  private resetCreditChanges(): void {
    const credit = this.credit;
    if (credit == null) {
      console.error('Cannot reset changes as the credit is null.');
      return;
    }

    const originalCredit = this._originalCredit;
    if (originalCredit == null) {
      console.error('Cannot reset changes as the original credit is null.');
      return;
    }

    resetObjectTo(credit, originalCredit);
  }

  protected onClickClose(): void {
    this.close.emit();
  }

  protected id(elementId: string): string {
    return `${this._id}-${elementId}`;
  }
}
