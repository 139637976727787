import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, EventClickArg, EventDropArg, EventHoveringArg } from '@fullcalendar/core';
import { EventsService } from '../../services/events.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { CalendarOriginalEventModel, Event, EventType, EventVisibility } from '../../models/event.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateClickArg } from '@fullcalendar/interaction';
import { EventEditorDialogComponent } from '../event-editor-dialog/event-editor-dialog.component';
import { NotificationService } from '../../../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import { EventEditorComponent } from '../event-editor/event-editor.component';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { ActivatedRoute } from '@angular/router';
import { FilterOption } from 'src/app/shared/components/generic-filter/generic-filter.component';
import { Utils } from 'src/app/core/services/utils';
import { ViewLeadDrawerComponent } from 'src/app/modules/leads/components/dialogs/view-lead-drawer/view-lead-drawer.component';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';

@Component({
  selector: 'calendar-page',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("eventEditor")
  eventEditor: EventEditorComponent;

  @ViewChild("fullCalendar")
  fullCalendar: FullCalendarComponent;

  @ViewChild('createCalendarEventDrawer')
  createCalendarEventDrawer: DrawerComponent;

  showLoanEvents: boolean = false;

  expandLoanEvents: boolean = false;

  usersToFilterWith: FilterOption[] = [];

  selectedUserOptionsOnUserFilter: FilterOption[] = [];

  companyId: number;
  userId: string;

  expandAllEvents: boolean = false;
  drawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: 'drawer-wrapper'
  }

  editLeadDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    events: [],
    headerToolbar: {
      left: 'title',
      center: 'prev next today',
      right: 'dayGridMonth timeGridWeek timeGridDay'
    },
    editable: true,
    contentHeight: 350,
    height: 900,
    droppable: true,
    eventContent: (arg) => {
      const container = document.createElement('div');
      container.classList.add('custom-event_container');
      if (this.expandAllEvents) {
        container.classList.add('show-detail');
      }
      const containerForTitles = document.createElement('div');
      const titleDot = document.createElement('div');
      const titleEventTime = document.createElement('div');
      const titleEventTitle = document.createElement('div');
      const descPrag = document.createElement('p');

      if (arg.event.extendedProps.description) {
        if (arg.timeText) {
          titleDot.classList.add('fc-daygrid-event-dot');
        }
        titleEventTime.classList.add('fc-event-time');
        titleEventTitle.classList.add('fc-event-title');
        containerForTitles.classList.add('d-flex');
        titleEventTime.innerHTML = arg.timeText ? arg.timeText : "All Day";
        titleEventTitle.innerHTML = arg.event.title;

        let description = '';
        let loanPurpose = '';
        let primaryRoleContact = '';
        let borrowerName = '';
        let borrowerPhone = '';
        let recordId = '';
        if (arg.event.extendedProps?.description) {
          if (arg.event.extendedProps.thirdPartyEventId) {
            description = arg.event.extendedProps.description.replace(/<[^>]*>/g, '');
            description = description.replace(/(\r?\n){1,}/g, '\n');
          } else {
            description = arg.event.extendedProps.description;
          }
        }
        if (arg.event.extendedProps?.applicationId) {
          recordId = `, <br/><b>Loan Number</b>: ${Utils.padLeft(arg.event.extendedProps?.applicationId + '', '0', 9)}`;
        }
        if (arg.event.extendedProps?.leadId) {
          recordId = `, <br/><b>Lead Number</b>: ${arg.event.extendedProps?.leadId}`;
        }
        if (arg.event.extendedProps?.loanPurposeName) {
          loanPurpose = (recordId ? ', ' : ' ') + `<br/><b>Loan Purpose</b>: ${arg.event.extendedProps?.loanPurposeName}`;
        }
        if (arg.event.extendedProps?.primaryRoleContact) {
          primaryRoleContact = (recordId || loanPurpose ? ', ' : ' ') + `<br/><b>Loan Officer</b>: ${arg.event.extendedProps?.primaryRoleContact}`;
        }
        if (arg.event.extendedProps?.borrowerName) {
          borrowerName = (recordId || loanPurpose || primaryRoleContact ? ', ' : ' ') + `<br/><b>Borrower</b>: ${arg.event.extendedProps?.borrowerName}`;
        }
        if (arg.event.extendedProps?.borrowerPhone) {
          borrowerPhone = (recordId || loanPurpose || primaryRoleContact || borrowerName ? ', ' : ' ') + `<br/><b>Phone Number</b>: ${Utils.formatPhoneNumber(arg.event.extendedProps?.borrowerPhone)}`;
        }

        descPrag.innerHTML = `${description}${recordId}${loanPurpose}${primaryRoleContact}${borrowerName}${borrowerPhone}`;
        descPrag.style.whiteSpace = 'pre-line';
        descPrag.style.wordBreak = 'break-word';
        descPrag.style.fontSize = '11px';
        descPrag.style.fontWeight = '100';
        descPrag.style.textAlign = 'left';
        descPrag.style.display = 'none';

        titleEventTitle.style.whiteSpace = 'pre-line';
        titleEventTitle.style.wordBreak = 'break-word';
        titleEventTitle.style.textAlign = 'left';
        titleEventTitle.style.float = 'left';
        titleEventTitle.style.fontWeight = ' 500';

        titleDot.style.marginTop = '4px'

        containerForTitles.append(titleDot, titleEventTime, titleEventTitle);
        container.append(containerForTitles, descPrag);
      } else {
        return;
      }

      const arrayOfDomNodes = [container];
      return { domNodes: arrayOfDomNodes };
    },
    eventDrop: (event) => {
      this.dragDropEvent(event);
    },
    dateClick: (dateClickEvent) => {
      this.addEvent(dateClickEvent);
    },
    eventClick: (eventClickEvent) => {
      this.onEditEventClicked(eventClickEvent);
    },
    eventMouseEnter: (event: EventHoveringArg) => {
      if (this.expandAllEvents) {
        return;
      }
      event.el.classList.add('show-detail');
    },
    eventMouseLeave: (event: EventHoveringArg) => {
      if (this.expandAllEvents) {
        return;
      }
      event.el.classList.remove('show-detail');
    }
  };

  unSubscribe$: Subject<null> = new Subject<null>();

  private _eventId: number;

  private _originalEvents: Event[] = [];
  private _dynamicEventSubscriptions: any[] = [];

  constructor(private readonly injector: Injector,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _eventsService: EventsService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _utilityService: UtilityService,
    private readonly _drawerService: DrawerService,
    private readonly _notificationService: NotificationService) {
    super(injector);
  }

  ngOnInit(): void {
    this.companyId = this.applicationContext.userPermissions.companyId;
    this.userId = this.applicationContext.currentlyLoggedInUser.userCompanyGuid;
    this.loadEvents();
  }

  ngAfterViewInit(): void {
  }

  expandLoanEventsClicked = (): void => {
    this.expandAllEvents = !this.expandAllEvents;
    this.filterEvents(this.selectedUserOptionsOnUserFilter);
  }

  showLoanEventsClicked = (): void => {
    this.showLoanEvents = !this.showLoanEvents;

    if (this._originalEvents) {
      this.createUserList(this._originalEvents);
      this.filterEvents(this.selectedUserOptionsOnUserFilter);
    }
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(null);
    this.unSubscribe$.complete();

    this._dynamicEventSubscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  loadEvents(): void {
    this._spinner.show();
    this._eventsService.getEventsByDate()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((events) => {
        if (events?.length > 0) {
          this._originalEvents = events;
          let idSeed = this._utilityService.getUniqueId();
          this._originalEvents.forEach(e => {
            e['customEventId'] = e.eventId ? e.eventId : idSeed--;
          });
          this.initialEvent();
          this.createUserList(this._originalEvents);
          this.filterEvents(this.selectedUserOptionsOnUserFilter);
        }
      }, error => {

      }).add(() => {
        this._spinner.hide();
      })
  }

  convertEventTempForCalendar(eventList: Event[]): CalendarOriginalEventModel[] {
    const newList: CalendarOriginalEventModel[] = [];
    eventList.forEach((item) => {
      const newData = this.createTemplateForCalendar(item);
      newList.push(newData);
    });
    return newList;
  }

  createTemplateForCalendar(item: Event): any {
    /*
    const date = new Date();
    const d = date.getUTCDate();
    const m = date.getUTCMonth();
    const y = date.getUTCFullYear();
    const currentDateFirst = new Date(Date.UTC(y, m, d, 0, 0, 0));
    const currentDateLast = new Date(Date.UTC(y, m, d, 23, 59, 59));
    */

    let classNameColor;
    let icon = '';
    switch (item.eventType) {
      case 'Appointment':
        classNameColor = "event-color-appointments";
        break;
      case 'EstimatedClosing':
        classNameColor = "event-color-estimatedClosing";
        break;
      case 'LockExpiration':
        classNameColor = "event-color-lockExpiration";
        break;
    }

    let allowEdit = false;
    if (this.userId === item.userId || this.applicationContext.userPermissions.admin) {
      allowEdit = true;
    }

    if (this.applicationContext.userPermissions.superAdmin) {
      classNameColor = 'bg-color-greenLight';
      icon = 'fa-lock';
      allowEdit = false;
    } else if (item.eventType === 'EstimatedClosing' && this.userId !== item.userId && !this.applicationContext.userPermissions.admin) {
      classNameColor = 'bg-color-greenLight';
      icon = 'fa-lock';
      allowEdit = false;
    }
    let dateEnd = null;
    if (this.isValidDate(item.dateEnd)) {
      dateEnd = new Date(item.dateEnd);
    }
    let dateStart = null;
    if (this.isValidDate(item.dateStart)) {
      dateStart = new Date(item.dateStart);
    }
    if (item.systemAllDayEventDate || this.isoDateHasNoTimeSet(item.dateStart)) {
      if (item.systemAllDayEventDate) {
        dateStart = new Date(item.systemAllDayEventDate);
      }
      const d = dateStart.getUTCDate();
      const m = dateStart.getUTCMonth();
      const y = dateStart.getUTCFullYear();
      dateStart = new Date(y, m, d, 0, 0, 0);
    }

    return {
      customEventId: item['customEventId'],
      title: item.title,
      start: dateStart,
      description: item.description,
      end: dateEnd,
      className: ["event", classNameColor],
      readOnly: item.readOnly,
      allDay: item.allDay,
      icon: icon,
      eventType: item.eventType,
      eventRepeat: item.eventRepeat,
      remindBeforeMinutes: item.remindBeforeMinutes,
      remindToaster: item.remindToaster,
      remindSms: item.remindSms,
      remindEmail: item.remindEmail,
      location: item.location,
      privilege: item.privilege,
      companyId: item.companyId,
      userId: item.userId,
      editable: allowEdit,
      userName: item.userName,
      leadId: item.leadId,
      applicationId: item.applicationId,
      borrowerId: item.borrowerId,
      displayAddress: item.displayAddress,
      borrowerName: item.borrowerName,
      borrowerPhone: item.borrowerPhone,
      loanPurposeName: item.loanPurposeName,
      primaryRoleContact: item.primaryRoleContact,
      propertyWillBe: item.propertyWillBe,
      thirdPartyEventId: item.thirdPartyEventId,
    };
  }

  isAddingEvent: boolean = false;
  eventToEdit: Event = null;
  selectedDay: Date = null;

  private isValidDate = (dateString: string): boolean => {
    if (!dateString) {
      return false;
    }
    const date = Date.parse(dateString);
    return !isNaN(date);
  }

  private isoDateHasNoTimeSet = (dateString: string): boolean => {
    if (!dateString) {
      return;
    }
    const timePart: string = dateString.split('T')[1];
    return timePart === '00:00:00.000Z';
  }

  addEvent = (selectedDay: DateClickArg): void => {
    // this._drawerService.hide("createCalendarEventDrawer", 100);
    this.isAddingEvent = true;
    // this.eventToEdit = new Event();
    // this.eventToEdit.privilege = EventVisibility.Private;
    // this.eventToEdit.companyId = this.companyId;
    // this.eventToEdit.userId = this.userId;
    // this.eventToEdit.eventType = EventType.Appointment;
    // this.selectedDay = selectedDay.date;
    // this._drawerService.show("createCalendarEventDrawer", 200);
    // //this.openEventEditorModal(true, newEvent, selectedDay);

    const event = new Event();
    event.eventType = EventType.Appointment;
    event.companyId = this.companyId;
    event.userId = this.applicationContext.userPermissions.userId;
    event.privilege = EventVisibility.Private;
    const startDate = new Date(selectedDay.date);
    event.dateStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() + 1, 0, 0, 0).toISOString();
    event.dateEnd = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() + 1, 15, 0, 0).toISOString();

    const dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = EventEditorComponent;
    dynamicComponentInfo.parameters.set('event', event);

    this._drawerService.show("createCalendarEventDrawer", 200, "Set Appointment", dynamicComponentInfo, this.drawerOptions).then(() => {
      const subscription = this.createCalendarEventDrawer.componentInstance.eventSaved.subscribe((event) => {
        this.onEventSaved(event);
      });
      this._dynamicEventSubscriptions.push(subscription);
    });
  }

  onDrawerClosed = () => {
    this.isAddingEvent = false;
    this.eventToEdit = null;
    this.refreshView();
  }

  onOpenLead = (event: Event) => {
    this._drawerService.hide("createCalendarEventDrawer");
    console.log(event);
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = ViewLeadDrawerComponent;
    dynamicComponentInfo.parameters.set('leadId', event.leadId);
    this._drawerService.show("editLeadEditorDrawer", 100, "Editing Lead - " + event.borrowerName, dynamicComponentInfo);
  }

  onEventDeleted = (event: Event) => {
    this._drawerService.hide("createCalendarEventDrawer");
    const eventIndex = this._originalEvents.findIndex((item) => item['customEventId'] === event['customEventId']);
    this._originalEvents.splice(eventIndex, 1);
    this._notificationService.showSuccess('Event deleted successfully.', 'Success!');
    this.filterEvents(this.selectedUserOptionsOnUserFilter);
  }

  onEventSaved = (event: Event) => {
    this._drawerService.hide("createCalendarEventDrawer");
    if (this.isAddingEvent) {
      this._notificationService.showSuccess('Event created successfully.', 'Success!');
    } else { //update
      const eventIndex = this._originalEvents.findIndex((item) => item['customEventId'] === event['customEventId']);
      this._originalEvents.splice(eventIndex, 1);
      this._notificationService.showSuccess('Event updated successfully.', 'Success!');
    }
    this.isAddingEvent = false;
    this._originalEvents.push(event);

    this.filterEvents(this.selectedUserOptionsOnUserFilter);
  }

  openEventEditorModal(isAdd: boolean, event: Event, selectedDay: DateClickArg): void {
    const modalRef = this._modalService.open(EventEditorDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.event = event;

    if (isAdd) {
      modalRef.componentInstance.selectedDate = selectedDay.date;
    }

    modalRef.result.then((result) => {
      if (result) {
        if (isAdd) {
          this._notificationService.showSuccess('Event created successfully.', 'Success!');
        } else { //update
          const eventIndex = this._originalEvents.findIndex((item) => item['customEventId'] === result['customEventId']);
          this._originalEvents.splice(eventIndex, 1);
          this._notificationService.showSuccess('Event updated successfully.', 'Success!');
        }
        this._originalEvents.push(result);
      }
      else { //delete
        const eventIndex = this._originalEvents.findIndex((item) => item['customEventId'] === event['customEventId']);
        this._originalEvents.splice(eventIndex, 1);
        this._notificationService.showSuccess('Event deleted successfully.', 'Success!');
      }

      this.filterEvents(this.selectedUserOptionsOnUserFilter);
    }, (reason) => {
    });
  }

  dragDropEvent(eventDropEvent: EventDropArg): void {
    let delta;
    if (eventDropEvent.delta.days !== 0) {
      delta = eventDropEvent.delta.days;
    } else {
      delta = eventDropEvent.delta.milliseconds;
    }
    this._eventsService.dropEvent(Number(eventDropEvent.event.extendedProps['customEventId']), delta, eventDropEvent.event.extendedProps.thirdPartyEventId)
      .subscribe({
        next: () => {
          this._notificationService.showSuccess('Event time changed', 'Notification Alert');
        },
        error: () => {
          this._notificationService.showError('Event time change failed', 'Notification Alert');
        }
      }).add(() => {
        this.loadEvents();
      });
  }

  createUserList(events: Event[]): void {
    events.forEach((event) => {
      const userFound = this.usersToFilterWith.find(userOption => userOption.value === event.userId);
      if (event.userId !== null && !userFound) {
        const user = this.applicationContext.globalConfig.usersAll.find(e => e.userCompanyGuid === event.userId);
        if (user && event.userId) {
          const userOption = {
            value: event.userId, displayName: Utils.getPersonsDisplayName(user),
            groupName: "All Users",
          };
          this.usersToFilterWith.push(userOption);
        }
      }
    });
    const loggedInUser = {} as FilterOption;
    loggedInUser.groupName = 'All Users';
    loggedInUser.value = this.userId;
    loggedInUser.displayName = Utils.getPersonsDisplayName(this.applicationContext.currentlyLoggedInUserProfile.userProfile);
    loggedInUser.isSelected = true;

    const loggedInUserAlreadyInOptions = this.usersToFilterWith.find(option => option.value === loggedInUser.value);
    if (loggedInUserAlreadyInOptions) {
      loggedInUserAlreadyInOptions.isSelected = true;
    } else {
      this.usersToFilterWith.push(loggedInUser);
    }
    this.selectedUserOptionsOnUserFilter.push(loggedInUser);
    this.selectedUserOptionsOnUserFilter = [...this.selectedUserOptionsOnUserFilter];
  }

  filterEvents(selectedUserOptions: FilterOption[]): void {
    let eventList: any[];
    if (selectedUserOptions.length) {
      eventList = this._originalEvents.filter(ele => selectedUserOptions.map(o => o.value).indexOf(ele.userId) > -1);
    } else {
      eventList = [];// this.originalEvents;
    }
    if (!this.showLoanEvents) {
      eventList = eventList.filter(e => e['customEventId'] > 0 || e.eventType == 'Appointment');
    }
    const list = this.convertEventTempForCalendar(eventList);
    this.calendarOptions.eventSources = [];
    this.calendarOptions.eventSources.push(list as any);
  }

  onUsersFilterChanged = (selectedOptions: FilterOption[]) => {
    this.selectedUserOptionsOnUserFilter = [...selectedOptions];
    this.filterEvents(this.selectedUserOptionsOnUserFilter);
  }

  private onEditEventClicked = (selectedEvent: EventClickArg): void => {
    this._drawerService.hide("createCalendarEventDrawer", 100);
    setTimeout(() => {
      this.eventToEdit = this._originalEvents.find((event) => event['customEventId'] === Number(selectedEvent.event._def.extendedProps['customEventId']));

      const dynamicComponentInfo = new DynamicComponentInfo();
      dynamicComponentInfo.componentType = EventEditorComponent;
      dynamicComponentInfo.parameters.set('event', this.eventToEdit);

      this._drawerService.show("createCalendarEventDrawer", 200, "Edit Appointment", dynamicComponentInfo, this.drawerOptions).then(() => {
        this.createCalendarEventDrawer.componentInstance.openLead.subscribe(this.onOpenLead);

        const saveSubscription = this.createCalendarEventDrawer.componentInstance.eventSaved.subscribe((event) => {
          this.onEventSaved(event);
        });
        const deleteSubscription = this.createCalendarEventDrawer.componentInstance.eventDeleted.subscribe((event) => {
          this.onEventDeleted(event);
        });
        this._dynamicEventSubscriptions.push(saveSubscription);
        this._dynamicEventSubscriptions.push(deleteSubscription);
      });
    }, 300);
  }

  private refreshView = () => {
    // **TODO: major hack here - could not find a better way to refresh calendar after drawer close.
    // If anyone does find a solution - feel free to implement here!
    let calendarApi = this.fullCalendar.getApi();
    setTimeout(() => {
      if (calendarApi.currentData.currentViewType === 'timeGridDay') {
        $('.fc-timeGridDay-button').click();
      } else if (calendarApi.currentData.currentViewType === 'timeGridWeek') {
        $('.fc-timeGridWeek-button').click();
      } else {
        $('.fc-dayGridMonth-button').click();
      }
    }, 500);
  }

  private initialEvent = () => {
    this._activatedRoute.queryParams.subscribe((queryParams) => {
      this._activatedRoute.params.subscribe((routeParams) => {
        this._eventId = Number(this._activatedRoute.snapshot.paramMap.get('id'));
        if (this._eventId && !isNaN(this._eventId)) {
          const eventToEdit = this._originalEvents.find((event) => event['customEventId'] === this._eventId);
          this.eventToEdit = eventToEdit;
          this._drawerService.show("createCalendarEventDrawer", 200);
        }
      });
    });
  }
}

