import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TableModule } from 'primeng/table';
import { LayoutModule } from 'src/app/layout/layout.module';
import { TpoLayoutComponent } from 'src/app/layout/tpo/tpo-layout/tpo-layout.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EditPricingModule } from '../edit-pricing/edit-pricing.module';
import { ExportLoanDocsModule } from '../export-loan-docs/export-loan-docs.module';
import { InternalContactsModule } from '../internal-contacts/internal-contacts.module';
import { KeyDatesModule } from '../key-dates/key-dates.module';
import { LoanActivityModule } from '../loan-activity/loan-activity.module';
import { LoanDocsModule } from '../loan-docs/loan-docs.module';
import { EscalationTaskDialogComponent } from './components/escalation-history/escalation-task-dialog/escalation-task-dialog.component';
import { TpoQuickPricerComponent } from './components/tpo-quick-pricer/tpo-quick-pricer.component';
import { TpoSettingsComponent } from './components/tpo-settings/tpo-settings.component';
import { TpoNewApplicationComponent } from './components/tpo-new-application/tpo-new-application.component';
import { LoanSummaryBarModule } from '../loan-summary-bar/loan-summary-bar.module';
import { PipelineFilterModule } from '../pipeline/components/pipeline-filter/pipeline-filter.module';
import { TpoMenuV2Component } from './components/tpo-menu-v2/tpo-menu-v2.component';
import { UrlaModule } from '../urla/urla.module';
import { TpoAppraisalManagementComponent } from './components/tpo-appraisal-management/tpo-appraisal-management.component';
import { AppraisalModule } from '../loan-services/appraisal/appraisal.module';
import { LoanServicesModule } from '../loan-services/loan-services.module';
import { NewApplicationModule } from '../new-application/new-application.module';
import { PipelineModule } from '../pipeline/pipeline.module';
import { PricingModule } from '../pricing/pricing.module';
import { SubmissionModule } from '../submission/submission.module';
import { CreateEscalationDialogComponent } from './components/create-escalation-dialog/create-escalation-dialog.component';
import { EscalationHistoryComponent } from './components/escalation-history/escalation-history.component';
import { LoanSummaryCardComponent } from './components/loan-summary-card/loan-summary-card.component';
import { TpoLoanSummaryModule } from './components/loan-summary/tpo-loan-summary.module';
import { MenuItemStatusIndicatorComponent } from './components/menu-item-status-indicator/menu-item-status.indicator.component';
import { TpoAppDetailsComponent } from './components/tpo-app-details/tpo-app-details.component';
import { TpoCdRequestComponent } from './components/tpo-cd-request/tpo-cd-request.component';
import { TpoManualLockComponent } from './components/tpo-manual-lock/tpo-manual-lock.component';
import { TpoMenuComponent } from './components/tpo-menu/tpo-menu.component';
import { TpoPipelineComponent } from './components/tpo-pipeline/tpo-pipeline.component';
import { TpoVaPaymentComponent } from './components/tpo-va-payment/tpo-va-payment.component';
import { CreditCredentialsConfigurationModule } from '../credit-credentials-configuration/credit-credentials-configuration.module';

@NgModule({
  imports: [
    NgApexchartsModule,
    RouterModule,
    CommonModule,
    LoanServicesModule,
    SubmissionModule,
    PricingModule,
    EditPricingModule,
    TpoLoanSummaryModule,
    NewApplicationModule,
    SharedModule,
    PipelineModule,
    KeyDatesModule,
    LoanActivityModule,
    InternalContactsModule,
    LoanDocsModule,
    ExportLoanDocsModule,
    TableModule,
    LayoutModule,
    LoanSummaryBarModule,
    PipelineFilterModule,
    UrlaModule,
    AppraisalModule,
    CreditCredentialsConfigurationModule,
  ],
  declarations: [
    TpoMenuComponent,
    TpoMenuV2Component,
    MenuItemStatusIndicatorComponent,
    TpoAppDetailsComponent,
    TpoPipelineComponent,
    TpoNewApplicationComponent,
    LoanSummaryCardComponent,
    CreateEscalationDialogComponent,
    EscalationHistoryComponent,
    EscalationTaskDialogComponent,
    TpoQuickPricerComponent,
    TpoSettingsComponent,
    TpoAppraisalManagementComponent,
    TpoCdRequestComponent,
    TpoManualLockComponent,
    TpoVaPaymentComponent
  ],
  exports: [TpoLayoutComponent],
  providers: []
})
export class TpoModule {
  constructor() { }
}
