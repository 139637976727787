import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { LegalDescriptions, UnparsedLegalDescription, UnparsedLegalDescriptionTypeEnum } from 'src/app/models/mortgage.model';
import { UrlaRequiredState } from '../../../services/utility.service';
import { LegalDescriptionsEditorDrawerComponent } from './legal-descriptions-editor-drawer/legal-descriptions-editor-drawer.component';
import { getOrDefault, mapOf } from './legal-description-utils';
import { LegalDescriptionEditorValue } from './legal-descriptions-editor/legal-descriptions-editor.component';
import { concatMap, from, merge, Subscription, take } from 'rxjs';
import { MortgageFieldConfig } from '../../../models/urla-fields-config.model';

@Component({
  selector: 'legal-descriptions',
  templateUrl: './legal-descriptions.component.html',
  styleUrls: ['./legal-descriptions.component.scss'],
})
export class LegalDescriptionsComponent implements OnChanges, OnDestroy {
  @Input() descriptions: LegalDescriptions;

  @Input() isReadOnly: boolean = false;

  @Input() inEditMode: boolean = false;

  @Input() urlaFieldsConfig: Record<string, MortgageFieldConfig>;

  @ViewChild('legalDescriptionsEditorDrawer')
  legalDescriptionsEditorDrawer: LegalDescriptionsEditorDrawerComponent;

  protected formValue: LegalDescriptionsFormValue;

  private _descriptionsEditorDrawerSubscription: Subscription | null = null;

  protected get isDescriptionsEditorDrawerOpen(): boolean {
    const closed =
      this._descriptionsEditorDrawerSubscription?.closed ?? true;
    return !closed;
  }

  ngOnChanges(changes: SimpleChanges) {
    const descriptionsChange = changes.descriptions;
    if (descriptionsChange != null) {
      const currentValue = descriptionsChange.currentValue;
      this.resetFormValue(currentValue);
    }
  }

  ngOnDestroy() {
    this._descriptionsEditorDrawerSubscription?.unsubscribe();
  }

  private resetFormValue(descriptions?: LegalDescriptions): void {
    this.formValue = new LegalDescriptionsFormValue(descriptions ?? this.descriptions);
  }

  protected onClickEditFullLegalDescription(): void {
    this.showDescriptionsEditorDrawer().then((value) => {
      if (value != null) {
        Object.assign(this.descriptions, value);
        this.resetFormValue();
      }
    }).catch(console.error);
  }

  private showDescriptionsEditorDrawer(): Promise<LegalDescriptionEditorValue | undefined> {
    let resolve: (value: LegalDescriptionEditorValue | undefined) => void;
    let reject: (reason?: any) => void;
    const resultPromise =
      new Promise<LegalDescriptionEditorValue | undefined>((res, rej) => {
        resolve = res;
        reject = rej;
      });

    if (this.isDescriptionsEditorDrawerOpen) {
      reject(new Error('The legal descriptions editor drawer is already open.'));
      return resultPromise;
    }

    const onClose = () => {
      this._descriptionsEditorDrawerSubscription = null;
      resolve(undefined);
    }

    this._descriptionsEditorDrawerSubscription =
      from(this.legalDescriptionsEditorDrawer.show(this.descriptions)).pipe(
        concatMap(() => merge(
          this.legalDescriptionsEditorDrawer.cancel,
          this.legalDescriptionsEditorDrawer.save,
        ).pipe(take(1))),
      ).subscribe(resolve);
    this._descriptionsEditorDrawerSubscription.add(onClose);

    return resultPromise;
  }
}

class LegalDescriptionsFormValue {
  fullDescription: UnparsedLegalDescription;

  constructor(descriptions: LegalDescriptions) {
    const unparsedDescriptions = descriptions.unparsedLegalDescriptions ?? [];
    const unparsedDescriptionsByType = mapOf(unparsedDescriptions)('legalDescriptionType');
    const getUnparsedDescriptionOrDefault = getOrDefault(unparsedDescriptionsByType)(UnparsedLegalDescription);

    this.fullDescription = getUnparsedDescriptionOrDefault(UnparsedLegalDescriptionTypeEnum.Long);
  }
}
