<div class="card pt-1 pe-1 pb-1 mb-2 tpo-report-card sticky-button-bar sticky-button-bar-vertical"
  style="height: 3rem;">
  <div class="d-flex justify-content-between">
    <ng-container *ngIf="!showStickyBar">
      <ul class="nav nav-tabs" role="tablist" id="{{'borrower-info-tabs_' + borrower.borrowerId}}">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ active: subTab === 'basic_details' + borrower.borrowerId}"
            (click)="subTab = 'basic_details' + borrower.borrowerId; subTabChange.emit(subTab);" data-bs-toggle="tab"
            href="#{{'basic_details' + borrower.borrowerId}}" role="tab" aria-selected="true">
            <span class="tab-1003-val-icon">
              <i class="borrower-details-icon fas" [ngClass]="{
                'fa': basicInfoStatus === 'success',
                'fa-check-circle': basicInfoStatus === 'success',
                'text-success': basicInfoStatus === 'success',

                'fa-question-circle': basicInfoStatus === 'pending',
                'text-warning': basicInfoStatus === 'pending',

                'fa-times-circle': basicInfoStatus === 'error',
                'text-danger': basicInfoStatus === 'error',

                'fa-circle-notch': basicInfoStatus === 'loading',
                'fa-spin': basicInfoStatus === 'loading'
              }"></i>
            </span>
            Basic Details
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ active: subTab === 'declarations' + borrower.borrowerId}"
            (click)="subTab = 'declarations' + borrower.borrowerId; subTabChange.emit(subTab);" data-bs-toggle="tab"
            href="#{{'declarations' + borrower.borrowerId}}" role="tab" aria-selected="false">
            <span class="tab-1003-val-icon">
              <i class="borrower-details-icon fas" [ngClass]="{
                'fa': declarationsStatus === 'success',
                'fa-check-circle': declarationsStatus === 'success',
                'text-success': declarationsStatus === 'success',

                'fa-question-circle': declarationsStatus === 'pending',
                'text-warning': declarationsStatus === 'pending',

                'fa-times-circle': declarationsStatus === 'error',
                'text-danger': declarationsStatus === 'error',

                'fa-circle-notch': declarationsStatus === 'loading',
                'fa-spin': declarationsStatus === 'loading'
              }"></i>
            </span>
            Declarations
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ active: subTab === 'demographics' + borrower.borrowerId}"
            (click)="subTab = 'demographics' + borrower.borrowerId; subTabChange.emit(subTab);" data-bs-toggle="tab"
            href="#{{'demographics' + borrower.borrowerId}}" role="tab" aria-selected="false">
            <span class="tab-1003-val-icon">
              <i class="borrower-details-icon fas" [ngClass]="{
                'fa': demographicsStatus === 'success',
                'fa-check-circle': demographicsStatus === 'success',
                'text-success': demographicsStatus === 'success',

                'fa-question-circle': demographicsStatus === 'pending',
                'text-warning': demographicsStatus === 'pending',

                'fa-times-circle': demographicsStatus === 'error',
                'text-danger': demographicsStatus === 'error',

                'fa-circle-notch': demographicsStatus === 'loading',
                'fa-spin': demographicsStatus === 'loading'
              }"></i>
            </span>
            Demographics
          </a>
        </li>
      </ul>
      <span class="me-1">
        <button type="button"
          class="btn btn-outline-primary me-1"
          (click)="onOrderCreditDrawerToggled()">Order
          Credit</button>
        <button type="button" class="btn btn-primary"
          [disabled]="isLoanReadOnly"
          (click)="saveBorrowerInfo()">
          <i class="fas fa-save me-1"></i>
          {{isLoanReadOnly ? 'Save (Readonly)' : 'Save'}}
        </button>
      </span>
    </ng-container>
    <ng-container *ngIf="showStickyBar">
      <span [hidden]="" class="text-primary fw-bold mt-2" style="font-size: 15px;"> {{borrowerFullName}} /
        <a (click)="scrollToTab(subTab)"> {{activeTabName}} </a> /
        <a (click)="scrollToSection(this.visibleSection?.header)"> {{this.visibleSection?.header}} </a>
      </span>
      <span class="me-1">
        <button type="button" class="btn btn-outline-primary me-1" (click)="onOrderCreditDrawerToggled()">Order
          Credit</button>
        <button type="button" class="btn btn-primary" (click)="saveBorrowerInfo()">
          <i class="fas fa-save me-1"></i>
          Save
        </button>
      </span>
    </ng-container>
  </div>
</div>
<div class="tab-content my-3">
  <div class="tab-pane" [ngClass]="{ active: subTab === 'basic_details' + borrower.borrowerId}"
    id="{{'basic_details' + borrower.borrowerId}}" role="tabpanel">
    <div #borrowerDetails id="borrower-details">
      <form #qaBorrowerDetailsInfo="ngForm" novalidate id="qaBorrowerDetailsInfo" name="qaBorrowerDetailsInfo">
        <div #borrowerPersonalInfo class="card" id="{{'borrower-personal-info_' + borrower.borrowerId}}">
          <div class="card-header d-flex">
            <h4 class="card-title">
              <i class="fas fa-user me-2"></i>
              Personal Info for '{{borrower.firstName}} {{borrower.lastName}}'
            </h4>
            <div class="form-check ms-4">
              <input type="checkbox" class="form-check-input" id="eConsentAuthorized{{borrower.borrowerId}}"
                name="eConsentAuthorized{{borrower.borrowerId}}" [(ngModel)]="borrower.hasValidEConsent" readonly>
              <a class="e-consent-link" placement="bottom" [popover]="eConsentHistoryPopoverTemplate"
                containerClass="eConsent" #eConsentPopover="bs-popover" (onShown)="onShownPopover(eConsentPopover)"
                (onHidden)="onHiddenPopover()" [outsideClick]="true"> eConsent Authorized
              </a>
            </div>
            <ng-template #eConsentHistoryPopoverTemplate>
              <e-consent-history-popover [borrower]="borrower"> </e-consent-history-popover>
            </ng-template>

            <div class="form-check ms-4">
              <input type="checkbox" class="form-check-input" id="creditPullAuthorized{{borrower.borrowerId}}"
                name="creditPullAuthorized{{borrower.borrowerId}}" [(ngModel)]="borrower.authorizedCreditCheck"
                (change)="onAuthorizedCreditChanged()" [readonly]='borrower.authorizationMethod === "Internet"'>
              <label class="form-check-label"
                [for]="borrower.authorizationMethod === 'Internet' ? 'creditPullAuthorized' : 'creditPullAuthorized' + borrower.borrowerId">
                Credit Pull Authorized </label>
            </div>

            <div class="ms-auto">
              <button class="btn btn-sm btn-outline-primary" (click)="onIdentifyingDocumentsDrawerToggled()">
                Identifying Documents
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-xs-12 col-sm-3">
                <label for="firstName{{borrower.borrowerId}}"> First Name * </label>
                <input id="firstName{{borrower.borrowerId}}" name="firstName{{borrower.borrowerId}}"
                  section="personal-info" type="text" class="form-control" [(ngModel)]="borrower.firstName"
                  #firstNameValue="ngModel" [required]="quickApplyFieldsConfig['mortgage.borrower.firstName'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.firstName'] == 'requested'" [ngClass]="{
                  'invalid-requested' : firstNameValue && firstNameValue.invalid && firstNameValue.errors && firstNameValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.firstName'] == 'requested',
                  'invalid-required' : firstNameValue && firstNameValue.invalid && firstNameValue.errors && firstNameValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.firstName'] == 'required'
                }" />
              </div>
              <div class="form-group col-xs-12 col-sm-2">
                <label for="middleName{{borrower.borrowerId}}"> Middle </label>
                <input id="middleName{{borrower.borrowerId}}" name="middleName{{borrower.borrowerId}}" type="text"
                  class="form-control" [(ngModel)]="borrower.middleName" #middleNameValue="ngModel" [required]="quickApplyFieldsConfig['mortgage.borrower.middleName'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.middleName'] == 'requested'" [ngClass]="{
                  'invalid-requested' : middleNameValue && middleNameValue.invalid && middleNameValue.errors && middleNameValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.middleName'] == 'requested',
                  'invalid-required' : middleNameValue && middleNameValue.invalid && middleNameValue.errors && middleNameValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.middleName'] == 'required'
                }" />
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <label for="lastName{{borrower.borrowerId}}"> Last Name * </label>
                <input id="lastName{{borrower.borrowerId}}" name="lastName{{borrower.borrowerId}}"
                  section="personal-info" type="text" class="form-control" [(ngModel)]="borrower.lastName"
                  #lastNameValue="ngModel" [required]="quickApplyFieldsConfig['mortgage.borrower.lastName'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.lastName'] == 'requested'" [ngClass]="{
                  'invalid-requested' : lastNameValue && lastNameValue.invalid && lastNameValue.errors && lastNameValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.lastName'] == 'requested',
                  'invalid-required' : lastNameValue && lastNameValue.invalid && lastNameValue.errors && lastNameValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.lastName'] == 'required'
                }" />

              </div>
              <div class="form-group col-xs-12 col-sm-2">
                <label for="suffix{{borrower.borrowerId}}"> Suffix </label>
                <select id="suffix{{borrower.borrowerId}}" name="suffix{{borrower.borrowerId}}" class="form-select"
                  [(ngModel)]="borrower.nameSuffix" #suffix="ngModel" [required]="quickApplyFieldsConfig['mortgage.borrower.nameSuffix'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.nameSuffix'] == 'requested'" [ngClass]="{
                  'invalid-requested' : suffix && suffix.invalid && suffix.errors && suffix.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.nameSuffix'] == 'requested',
                  'invalid-required' : suffix && suffix.invalid && suffix.errors && suffix.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.nameSuffix'] == 'required'
                }">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="let opt of suffixes" [ngValue]="opt.value">
                    {{ opt.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-xs-12 col-sm-2">
                <label for="signingRole{{borrower.borrowerId}}"> Signing Role </label>
                <select id="signingRole{{borrower.borrowerId}}" name="signingRole{{borrower.borrowerId}}" class="form-select" (change)="onSigningRoleChanged()"
                  [(ngModel)]="borrower.signingRole" #signingRole="ngModel" [required]="quickApplyFieldsConfig['mortgage.borrower.signingRole'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.signingRole'] == 'requested'" [ngClass]="{
                  'invalid-requested' : signingRole && signingRole.invalid && signingRole.errors && signingRole.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.signingRole'] == 'requested',
                  'invalid-required' : signingRole && signingRole.invalid && signingRole.errors && signingRole.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.signingRole'] == 'required'
                }">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="let opt of signingRoleOptions" [ngValue]="opt.value">
                    {{ opt.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-xs-12 col-sm-3 ">
                <label for="ssn{{borrower.borrowerId}}"> Social Security Number * </label>
                <input id="ssn{{borrower.borrowerId}}" class="form-control" name="ssn{{borrower.borrowerId}}"
                  section="personal-info" type="text" [(ngModel)]="borrower.socialSecNum" mask="000-00-0000"
                  placeHolderCharacter="_" [showMaskTyped]="true" [conceal]='true' #ssnValue="ngModel" [required]="quickApplyFieldsConfig['mortgage.borrower.socialSecNum'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.socialSecNum'] == 'requested'" [ngClass]="{
                  'invalid-requested' : ssnValue && ssnValue.invalid && ssnValue.errors && ssnValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.socialSecNum'] == 'requested',
                  'invalid-required' : ssnValue && ssnValue.invalid && ssnValue.errors && ssnValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.socialSecNum'] == 'required'
                }" />
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <div class="row">
                  <div class="col-8 pe-0">
                    <label for="dob{{borrower.borrowerId}}"> Date of Birth *</label>
                    <date-input name="dob{{borrower.borrowerId}}" id="dob{{borrower.borrowerId}}"
                      [(ngModel)]="borrower.dateOfBirth" (ngModelChange)="onDateofBirthChanged()" #dob="ngModel"
                      [required]="quickApplyFieldsConfig['mortgage.borrower.dateOfBirth'] == 'required' ||
                    quickApplyFieldsConfig['mortgage.borrower.dateOfBirth'] == 'requested'" [ngClass]="{
                    'invalid-requested' : dob && dob.invalid && dob.errors && dob.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.dateOfBirth'] == 'requested',
                    'invalid-required' : dob && dob.invalid && dob.errors && dob.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.dateOfBirth'] == 'required'
                  }">
                    </date-input>
                  </div>
                  <div class="col-4 ps-0">
                    <label for="age{{borrower.borrowerId}}"> Age </label>
                    <input id="age{{borrower.borrowerId}}" numeric [allowNegative]="false"
                      name="age{{borrower.borrowerId}}" class="form-control custom-input-right-side" disabled
                      [(ngModel)]="dobAge" />
                  </div>
                </div>
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <label for="residencyType{{borrower.borrowerId}}"> Residency Type *
                </label>
                <select id="residencyType{{borrower.borrowerId}}" name="residencyType{{borrower.borrowerId}}"
                  section="personal-info" class="form-select" [(ngModel)]="borrower.declarations.residenceStatus"
                  #residencyTypeValue="ngModel" [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.residenceStatus'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.declarations.residenceStatus'] == 'requested'" [ngClass]="{
                  'invalid-requested' : residencyTypeValue && residencyTypeValue.invalid && residencyTypeValue.errors && residencyTypeValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.declarations.residenceStatus'] == 'requested',
                  'invalid-required' : residencyTypeValue && residencyTypeValue.invalid && residencyTypeValue.errors && residencyTypeValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.declarations.residenceStatus'] == 'required'
                }">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="let type of residencyTypes" [ngValue]="type.value">
                    {{ type.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <label for="maritalStatus{{borrower.borrowerId}}"> Marital Status *
                </label>
                <select id="maritalStatus{{borrower.borrowerId}}" name="maritalStatus{{borrower.borrowerId}}"
                  section="personal-info" class="form-select" [(ngModel)]="borrower.maritalStatus"
                  #maritalStatusValue="ngModel" (change)="onMaritalStatusChanged()" [required]="quickApplyFieldsConfig['mortgage.borrower.maritalStatus'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.maritalStatus'] == 'requested'" [ngClass]="{
                  'invalid-requested' : maritalStatusValue && maritalStatusValue.invalid && maritalStatusValue.errors && maritalStatusValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.maritalStatus'] == 'requested',
                  'invalid-required' : maritalStatusValue && maritalStatusValue.invalid && maritalStatusValue.errors && maritalStatusValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.maritalStatus'] == 'required'
                }">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="let status of maritalStatuses" [ngValue]="status.value">
                    {{ status.name }}
                  </option>
                </select>
                <div class="invalid-feedback">This field is required</div>
              </div>
              <div class="col-xs-12 col-sm-12 marital-status-container" *ngIf="borrower.maritalStatus === 'Single'">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="maritalStatusCheckbox{{borrower.borrowerId}}"
                    name="maritalStatusCheckbox{{borrower.borrowerId}}" [(ngModel)]="unmarriedAddendum"
                    (change)="onUnmarriedAddendumChanged()">
                  <label class="form-check-label" for="maritalStatusCheckbox{{borrower.borrowerId}}">
                    Borrower has unmarried partner with real property rights similar to those of a legal
                    spouse? </label>
                </div>
              </div>
              <div *ngIf="unmarriedAddendum" class="form-group col-xs-12 col-sm-3">
                <label for="relationshipType{{borrower.borrowerId}}"> Relationship Type *
                </label>
                <select id="relationshipType{{borrower.borrowerId}}" name="relationshipType{{borrower.borrowerId}}"
                  section="personal-info" class="form-select" [(ngModel)]="borrower.domesticRelationshipType"
                  #domesticRelationshipTypeValue="ngModel" [required]="quickApplyFieldsConfig['mortgage.borrower.domesticRelationshipType'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.domesticRelationshipType'] == 'requested'" [ngClass]="{
                  'invalid-requested' : domesticRelationshipTypeValue && domesticRelationshipTypeValue.invalid && domesticRelationshipTypeValue.errors && domesticRelationshipTypeValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.domesticRelationshipType'] == 'requested',
                  'invalid-required' : domesticRelationshipTypeValue && domesticRelationshipTypeValue.invalid && domesticRelationshipTypeValue.errors && domesticRelationshipTypeValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.domesticRelationshipType'] == 'required' }">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="let type of domesticRelationshipTypes" [ngValue]="type.value">
                    {{ type.name }}
                  </option>
                </select>
              </div>
              <div *ngIf="unmarriedAddendum" class="form-group col-xs-12 col-sm-3">
                <label for="relationshipState{{borrower.borrowerId}}"> Relationship State *
                </label>
                <select id="relationshipState{{borrower.borrowerId}}" name="relationshipState{{borrower.borrowerId}}"
                  section="personal-info" class="form-select" [(ngModel)]="borrower.unmarriedRelationshipState"
                  #unmarriedRelationshipStateValue="ngModel" [required]="quickApplyFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] == 'requested'" [ngClass]="{
                  'invalid-requested' : unmarriedRelationshipStateValue && unmarriedRelationshipStateValue.invalid && unmarriedRelationshipStateValue.errors && unmarriedRelationshipStateValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] == 'requested',
                  'invalid-required' : unmarriedRelationshipStateValue && unmarriedRelationshipStateValue.invalid && unmarriedRelationshipStateValue.errors && unmarriedRelationshipStateValue.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.unmarriedRelationshipState'] == 'required' }">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="let state of states" [ngValue]="state.value">
                    {{ state.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <label for="email{{borrower.borrowerId}}"> Email * </label>
                <div class='float-end d-flex align-items-center gap-2' *ngLet='{displayInfo: false} as infoState'>
                  <span *ngIf='infoState.displayInfo' class='text-muted'>
                    Copied to Clipboard
                  </span>

                  <button (click)='onClickCopyEmail(infoState)' [disabled]='!borrower.primaryEmail' class='btn p-0'
                    title='Copy Email to Clipboard'>
                    <i class="far fa-clone"></i>
                  </button>
                </div>
                <input id="email{{borrower.borrowerId}}" name="email{{borrower.borrowerId}}" type="text"
                  section="personal-info" class="form-control" [(ngModel)]="borrower.primaryEmail" #emailInput="ngModel"
                  placeholder="example@domain.com" email trim [required]="quickApplyFieldsConfig['mortgage.borrower.primaryEmail'] == 'required' ||
                    quickApplyFieldsConfig['mortgage.borrower.primaryEmail'] == 'requested'" [ngClass]="{
                    'invalid-requested' : emailInput && emailInput.invalid && emailInput.errors && emailInput.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.primaryEmail'] == 'requested',
                    'invalid-required' : emailInput && emailInput.invalid && emailInput.errors && emailInput.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.primaryEmail'] == 'required'
                  }" />

              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <label for="cellPhone{{borrower.borrowerId}}"> Cell Phone * </label>
                <input #borrowerCellPhone="ngModel" class="form-control" id="cellPhone{{borrower.borrowerId}}"
                  section="personal-info" name="cellPhone{{borrower.borrowerId}}" type="text"
                  [(ngModel)]="borrower.mobilePhone" [required]="quickApplyFieldsConfig['mortgage.borrower.mobilePhone'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.mobilePhone'] == 'requested'" [ngClass]="{
                  'invalid-requested' : borrowerCellPhone && borrowerCellPhone.invalid && borrowerCellPhone.errors && borrowerCellPhone.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.mobilePhone'] == 'requested',
                  'invalid-required' : borrowerCellPhone && borrowerCellPhone.invalid && borrowerCellPhone.errors && borrowerCellPhone.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.mobilePhone'] == 'required'
                }" mask="(000) 000-0000" [showMaskTyped]="true" />
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <div class="row">
                  <div class="col-8 pe-0">
                    <label for="cellPhone{{borrower.borrowerId}}"> Work Phone </label>
                    <input class="form-control custom-input-left-side" id="workPhone{{borrower.borrowerId}}"
                      name="workPhone{{borrower.borrowerId}}" type="text" [(ngModel)]="borrower.workPhone"
                      mask="(000) 000-0000" [showMaskTyped]="true" />
                  </div>
                  <div class="col-4 ps-0">
                    <label for="ext{{borrower.borrowerId}}"> Ext </label>
                    <input id="ext{{borrower.borrowerId}}" numeric [allowNegative]="false"
                      name="ext{{borrower.borrowerId}}" class="form-control custom-input-right-side"
                      [(ngModel)]="tbdWorkPhoneExt" />
                  </div>
                </div>
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <label for="homePhone{{borrower.borrowerId}}"> Home Phone * </label>
                <button (click)='onMobilePhoneCopy()' *ngIf="!!borrower.mobilePhone" class='btn p-0 float-end'
                    title='Copy from mobile phone'>
                    <i class="far fa-clone"></i>
                </button>
                <input class="form-control" id="homePhone{{borrower.borrowerId}}"
                  name="homePhone{{borrower.borrowerId}}" type="text" [(ngModel)]="borrower.homePhone"
                  mask="(000) 000-0000" [showMaskTyped]="true" #homePhone="ngModel" [required]="quickApplyFieldsConfig['mortgage.borrower.homePhone'] == 'required' ||
                    quickApplyFieldsConfig['mortgage.borrower.homePhone'] == 'requested'" [ngClass]="{
                    'invalid-requested' : homePhone && homePhone.invalid && homePhone.errors && homePhone.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.homePhone'] == 'requested',
                    'invalid-required' : homePhone && homePhone.invalid && homePhone.errors && homePhone.errors.required
                      && quickApplyFieldsConfig['mortgage.borrower.homePhone'] == 'required'
                  }" />
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <label for="numberOfDependents{{borrower.borrowerId}}"> Number of Dependents </label>
                <a (click)="onNumberofDependentsClicked()"> <i class="fas fa-pencil-alt float-end"></i> </a>
                <input id="numberOfDependents{{borrower.borrowerId}}" type="number" numeric [allowNegative]="false"
                  name="numberOfDependents{{borrower.borrowerId}}" class="form-control" (change)="onNoOfDependentsChanged()"
                  [(ngModel)]="borrower.dependentCount" />
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <label for="dependentsOfAges{{borrower.borrowerId}}"> Ages of Dependents </label>
                <input id="dependentsOfAges{{borrower.borrowerId}}" numeric [allowNegative]="false" disabled
                  name="dependentsOfAges{{borrower.borrowerId}}" class="form-control" [(ngModel)]="dependentAges" />
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <label for="creditRefNumber{{borrower.borrowerId}}"> Credit Ref # </label>
                <input id="creditRefNumber{{borrower.borrowerId}}" type="text"
                  name="creditRefNumber{{borrower.borrowerId}}" class="form-control"
                  [(ngModel)]="borrower.creditRefNumber"
                  [required]="(quickApplyFieldsConfig['mortgage.borrower.creditRefNumber'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.creditRefNumber'] == 'requested') && !isCorrespondent && loanBorrower?.scoreFromCreditReport"
                  [ngClass]="{
                    'invalid-requested' : !borrower.creditRefNumber && !isCorrespondent && loanBorrower?.scoreFromCreditReport
                      && quickApplyFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] == 'requested',
                    'invalid-required' : !borrower.creditRefNumber && !isCorrespondent && loanBorrower?.scoreFromCreditReport
                      && quickApplyFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] == 'required' }"/>
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <label for="estimatedCreditScore{{borrower.borrowerId}}"> Credit Score </label>
                <input id="estimatedCreditScore{{borrower.borrowerId}}" type="number" numeric [allowNegative]="false"
                  name="estimatedCreditScore{{borrower.borrowerId}}" class="form-control"
                  [readonly]="loanBorrower?.scoreFromCreditReport" [(ngModel)]="borrower.creditScore" />
              </div>
            </div>
            <div *ngIf="borrower.authorizedCreditCheck" class="row">
              <div class="form-group col-xs-12 col-sm-3">
                <label for="dateAuthCreditCheck{{borrower.borrowerId}}"> Credit Pull Authorized Date
                </label>
                <date-input name="dateAuthCreditCheck{{borrower.borrowerId}}" #dateAuthorizedCreditCheck="ngModel"
                  id="dateAuthCreditCheck{{borrower.borrowerId}}" [(ngModel)]="borrower.dateAuthorizedCreditCheck"
                  [readonly]='borrower.authorizationMethod === "Internet"'
                  [required]="quickApplyFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] == 'required' ||
                  quickApplyFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] == 'requested'" [ngClass]="{
                  'invalid-requested' : dateAuthorizedCreditCheck && dateAuthorizedCreditCheck.invalid && dateAuthorizedCreditCheck.errors && dateAuthorizedCreditCheck.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] == 'requested',
                  'invalid-required' : dateAuthorizedCreditCheck && dateAuthorizedCreditCheck.invalid && dateAuthorizedCreditCheck.errors && dateAuthorizedCreditCheck.errors.required
                    && quickApplyFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck'] == 'required' }">
                </date-input>
              </div>
              <div class="form-group col-xs-12 col-sm-3">
                <label for="authMethod{{borrower.borrowerId}}"> Authorization Method *
                </label>
                <select id="authMethod{{borrower.borrowerId}}" name="authMethod{{borrower.borrowerId}}"
                  section="personal-info" class="form-select" [(ngModel)]="borrower.authorizationMethod"
                  [disabled]='borrower.authorizationMethod === "Internet"'
                  #authMethodValue="ngModel" [required]="quickApplyFieldsConfig['mortgage.borrower.authorizationMethod'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.authorizationMethod'] == 'requested'" [ngClass]="{
                'invalid-requested' : authMethodValue && authMethodValue.invalid && authMethodValue.errors && authMethodValue.errors.required
                  && quickApplyFieldsConfig['mortgage.borrower.authorizationMethod'] == 'requested',
                'invalid-required' : authMethodValue && authMethodValue.invalid && authMethodValue.errors && authMethodValue.errors.required
                  && quickApplyFieldsConfig['mortgage.borrower.authorizationMethod'] == 'required' }">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="let authMethod of authorizationMethods" [ngValue]="authMethod.value">
                    {{ authMethod.name }}
                  </option>
                </select>
              </div>


            </div>
          </div>
        </div>
        <div #borrowerAddress class="card" id="{{'borrower-address_' + borrower.borrowerId}}">
          <div class="card-header d-flex justify-content-between">
            <h4 class="card-title">
              <i class="far fa-address-book me-2"></i>
              Address History for '{{borrower.firstName}} {{borrower.lastName}}'
            </h4>
            <span
              [ngClass]="{'text-success': numberOfYears * 12 + numberOfMonths >= 24, 'text-danger': numberOfYears * 12 + numberOfMonths < 24}">
              <i class="fa"
                [ngClass]="{'fa-check': numberOfYears * 12 + numberOfMonths >= 24, 'fa-times-circle': numberOfYears * 12 + numberOfMonths < 24}"></i>
              {{numberOfYears}} year(s) {{numberOfMonths}} month(s)
            </span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class='col-12'>
                <div class='present-address-section'>
                  <div>
                    <div class='form-check form-switch mb-0'>
                      <input
                        (change)='onIsSameAsSubjectPropertyAddressChanged()'
                        [(ngModel)]='isSameAsSubjectProperty'
                        class='form-check-input'
                        id='isSameAsSubjectProperty_{{borrower.borrowerId}}'
                        name='isSameAsSubjectProperty_{{borrower.borrowerId}}'
                        type='checkbox'
                      >

                      <label title='Same as Subject Property'>
                        Same as Subject Property
                      </label>
                    </div>

                    <qa-address-history
                      [quickApplyFieldsConfig]='quickApplyFieldsConfig'
                      (durationChange)='onChangeAddressDuration()'
                      [address]='presentAddress'
                      [mortgage]='mortgage'
                      (addressChange)='onChangePresentAddress()'
                      streetAddressLabel='Present Address'
                    ></qa-address-history>
                  </div>

                  <div
                    *ngIf='["FHA","VA","USDA"].includes(mortgage.mortgageTerm.mortgageAppliedFor)'
                    class='has-positive-rental-history-input'
                  >
                    <div class='form-check form-switch mb-0'>
                      <input
                        [(ngModel)]='mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory'
                        class='form-check-input'
                        id='borrowerHasPositiveRentalHistory_{{ borrower.borrowerId }}'
                        name='borrowerHasPositiveRentalHistory'
                        type='checkbox'
                      />
                      <label for='borrowerHasPositiveRentalHistory_{{ borrower.borrowerId }}'>
                        Borrower Has Positive History
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group col-xs-12 col-sm-12">
                <button type="button" class="btn btn-sm btn-outline-primary" (click)="onAddPreviousAddressClicked()">
                  <i class="fas fa-plus me-1"></i>
                  Previous Address </button>
              </div>

              <ng-container *ngFor='let address of formerAddresses'>
                <qa-address-history
                  [allowDelete]="true"
                  [quickApplyFieldsConfig]='quickApplyFieldsConfig'
                  (addressChange)='onChangeFormerAddress($event, address)'
                  [address]='address'
                  streetAddressLabel='Former Address'
                  (attemptedToDeleteAddress)='onAttemptedToDeleteAddress($event)'
                ></qa-address-history>
              </ng-container>

              <div class="form-group col-xs-12 col-sm-12">
                <div class="form-check">
                  <input
                    (ngModelChange)='toggleMailingAddress($event)'
                    [ngModel]='isMailingAddressDiffFromCurrentAddress'
                    class='form-check-input'
                    id='mailingAddress{{borrower.borrowerId}}'
                    name='mailingAddress{{borrower.borrowerId}}'
                    type='checkbox'
                  >
                  <label class="form-check-label" for="mailingAddress{{borrower.borrowerId}}">
                    Mailing address is <strong> NOT </strong> same as current address </label>
                </div>
              </div>

              <qa-address-history
                *ngIf='isMailingAddressDiffFromCurrentAddress'
                [quickApplyFieldsConfig]='quickApplyFieldsConfig'
                [address]='mailingAddress'
                (addressChange)='checkValidityStatusesForSections()'
                [showDuration]='false'
                [showHousing]='false'
                streetAddressLabel='Mailing Address'
              ></qa-address-history>
            </div>
          </div>
        </div>
        <div #borrowerEduAndCounseling class="card" id="{{'borrower-education-and-counseling_' + borrower.borrowerId}}">
          <div class="card-header">
            <h4 class="card-title">
              <i class="fas fa-suitcase me-2"></i>
              Homeownership Education and Housing Counselling
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-9">
                <h6> Has the Borrower(s) completed homebuyer education (group or web-based classes) within
                  the last 12 months? </h6>
              </div>
              <div class="col-3 align-self-center">
                <div class="radio radio-info form-check-inline">
                  <input type="radio" id="yes_{{borrower.borrowerId}}_1" [value]="true"
                    name="yes_{{borrower.borrowerId}}_1" [(ngModel)]="hasHomebuyerEducation">
                  <label for="yes_{{borrower.borrowerId}}_1"> Yes </label>
                </div>
                <div class="radio radio-info form-check-inline ms-3">
                  <input type="radio" id="no{{borrower.borrowerId}}_1" [value]="false"
                    name="no{{borrower.borrowerId}}_1" [(ngModel)]="hasHomebuyerEducation"
                    (change)="onBorrowerCounselingEventChanged('Education')">
                  <label for="no{{borrower.borrowerId}}_1"> No </label>
                </div>
              </div>
              <div *ngIf="hasHomebuyerEducation" class="col-12">
                <ng-container *ngFor="let event of borrower.counselingEvents; let index = index">
                  <qa-counseling-events *ngIf="event.counselingType === 'Education'" #counselingEvent [event]="event"
                    [index]="index" [borrower]="borrower" (attemptedToDeleteEvent)="onAttemptedToDeleteEvent($event)"
                    [borrowerCounselingFormatTypes]="borrowerCounselingFormatTypes">
                  </qa-counseling-events>
                </ng-container>

                <div class="form-group col-xs-12 col-sm-12">
                  <button type="button" class="btn btn-sm btn-outline-primary"
                    (click)="onAddNewCounselingEventClicked('Education')">
                    <i class="fas fa-plus me-1"></i>
                    New Event </button>
                </div>
              </div>
              <div class="col-9">
                <h6> Has the Borrower(s) completed housing counseling (customized counselor-to-client
                  services) within the last 12 months? </h6>
              </div>
              <div class="col-3 align-self-center">
                <div class="radio radio-info form-check-inline">
                  <input type="radio" id="yes_{{borrower.borrowerId}}_3" [value]="true"
                    name="yes_{{borrower.borrowerId}}_3" [(ngModel)]="hasCounseling">
                  <label for="yes_{{borrower.borrowerId}}_3"> Yes </label>
                </div>
                <div class="radio radio-info form-check-inline ms-3">
                  <input type="radio" id="no_{{borrower.borrowerId}}_3" [value]="false"
                    (change)="onBorrowerCounselingEventChanged('Counseling')" name="no_{{borrower.borrowerId}}_3"
                    [(ngModel)]="hasCounseling">
                  <label for="no_{{borrower.borrowerId}}_3"> No </label>
                </div>
              </div>
              <div *ngIf="hasCounseling" class="col-12">
                <ng-container *ngFor="let event of borrower.counselingEvents; let index = index">
                  <qa-counseling-events *ngIf="event.counselingType === 'Counseling'" #counselingEvent [event]="event"
                    [index]="index" [borrower]="borrower" (attemptedToDeleteEvent)="onAttemptedToDeleteEvent($event)"
                    [borrowerCounselingFormatTypes]="borrowerCounselingFormatTypes">
                  </qa-counseling-events>
                </ng-container>
                <div class="form-group col-xs-12 col-sm-12">
                  <button type="button" class="btn btn-sm btn-outline-primary"
                    (click)="onAddNewCounselingEventClicked('Counseling')">
                    <i class="fas fa-plus me-1"></i>
                    New Event </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div #borrowerLanguagePref class="col-md-6" id="{{'borrower-language-preferences_' + borrower.borrowerId}}">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">
                  <i class="fas fa-language me-2"></i>
                  Language Preferences
                </h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <h6> Mark the language you would prefer, if available: </h6>
                    <div *ngFor="let language of languageOptions">
                      <div class="radio radio-primary">
                        <input type="radio" [(ngModel)]="borrower.languagePreference"
                          id="language_{{borrower.borrowerId}}_{{language.name}}"
                          name="language_{{borrower.borrowerId}}_{{language.name}}" [value]="language.value"
                          (change)="onLanguagePreferenceChange()" [required]="quickApplyFieldsConfig['mortgage.borrower.languagePreference'] == 'required' ||
                        quickApplyFieldsConfig['mortgage.borrower.languagePreference'] == 'requested'"
                          [ngClass]="{
                        'invalid-requested' : borrower.languagePreference == null && quickApplyFieldsConfig['mortgage.borrower.languagePreference'] == 'requested',
                        'invalid-required' : borrower.languagePreference == null && quickApplyFieldsConfig['mortgage.borrower.languagePreference'] == 'required' }">
                        <label class="form-check-label" for="language_{{borrower.borrowerId}}_{{language.name}}">
                          {{language.name}} </label>
                      </div>
                    </div>
                    <div *ngIf="borrower.languagePreference == 'Other'" class="form-group col-xs-12 col-sm-12">
                      <div class="form-group col-12 col-md-6 col-lg-4">
                        <label for="langPrefDescription{{borrower.borrowerId}}"> Description * </label>
                        <input id="langPrefDescription{{borrower.borrowerId}}"
                          name="langPrefDescription{{borrower.borrowerId}}" type="text" class="form-control"
                          [(ngModel)]="borrower.languagePreferenceOtherDescription" #langPrefDescriptionValue="ngModel"
                          [required]="quickApplyFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] == 'required' ||
                        quickApplyFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] == 'requested'"
                          [ngClass]="{
                        'invalid-requested' : langPrefDescriptionValue && langPrefDescriptionValue.invalid && langPrefDescriptionValue.errors && langPrefDescriptionValue.errors.required
                          && quickApplyFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] == 'requested',
                        'invalid-required' : langPrefDescriptionValue && langPrefDescriptionValue.invalid && langPrefDescriptionValue.errors && langPrefDescriptionValue.errors.required
                          && quickApplyFieldsConfig['mortgage.borrower.languagePreferenceOtherDescription'] == 'required' }" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div #borrowerMilitaryInfo class="col-md-6" id="{{'borrower-military-info_' + borrower.borrowerId}}">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">
                  <i class="fas fa-angle-double-down me-2"></i>
                  Military Info
                </h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <h6> Did you (or your deceased spouse) ever serve, or are you currently serving, in the United
                    States Armed Forces? </h6>
                  <div class="radio radio-primary">
                    <input type="radio" name="radio_{{borrower.borrowerId}}" id="radio1_{{borrower.borrowerId}}"
                      value="noMilitaryStatus" [(ngModel)]="militaryStatus" (change)="onMilitaryStatusChange()"
                      [required]="quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'required' ||
                    quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'requested'"
                      [ngClass]="{
                    'invalid-requested' : militaryStatus == null && quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'requested',
                    'invalid-required' :militaryStatus == null && quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'required' }">
                    <label for="radio1_{{borrower.borrowerId}}">
                      No
                    </label>
                  </div>
                  <div class="radio radio-primary">
                    <input type="radio" name="radio_{{borrower.borrowerId}}" id="radio2_{{borrower.borrowerId}}"
                      value="isActiveDutyMilitary" [(ngModel)]="militaryStatus" (change)="onMilitaryStatusChange()"
                      [required]="quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'required' ||
                    quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'requested'"
                      [ngClass]="{
                    'invalid-requested' : militaryStatus == null && quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'requested',
                    'invalid-required' :militaryStatus == null && quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'required' }">
                    <label for="radio2_{{borrower.borrowerId}}">
                      Currently serving or active duty with projected expiration date of service/tour
                    </label>
                  </div>
                  <div *ngIf="militaryStatus == 'isActiveDutyMilitary'" class="row">
                    <div class="form-group col-md-6">
                      <label for="militaryTourStartDate{{borrower.borrowerId}}"> Estimated Start Date *
                      </label>
                      <date-input name="militaryTourStartDate{{borrower.borrowerId}}" #militaryTourStartDate
                        id="militaryTourStartDate{{borrower.borrowerId}}" [(ngModel)]="borrower.militaryTourStartDate"
                        [required]="quickApplyFieldsConfig['mortgage.borrower.militaryTourEndDate'] == 'required' ||
                      quickApplyFieldsConfig['mortgage.borrower.militaryTourEndDate'] == 'requested'" [ngClass]="{
                      'invalid-requested' : militaryTourStartDate && militaryTourStartDate.invalid && militaryTourStartDate.errors && militaryTourStartDate.errors.required
                        && quickApplyFieldsConfig['mortgage.borrower.militaryTourEndDate'] == 'requested',
                      'invalid-required' : militaryTourStartDate && militaryTourStartDate.invalid && militaryTourStartDate.errors && militaryTourStartDate.errors.required
                        && quickApplyFieldsConfig['mortgage.borrower.militaryTourEndDate'] == 'required' }">
                      </date-input>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="militaryTourEndDate{{borrower.borrowerId}}"> Expected Completion Date *
                      </label>
                      <date-input name="militaryTourEndDate{{borrower.borrowerId}}" #militaryTourEndDate
                        id="militaryTourEndDate{{borrower.borrowerId}}" [(ngModel)]="borrower.militaryTourEndDate"
                        [required]="quickApplyFieldsConfig['mortgage.borrower.militaryTourEndDate'] == 'required' ||
                      quickApplyFieldsConfig['mortgage.borrower.militaryTourEndDate'] == 'requested'" [ngClass]="{
                      'invalid-requested' : militaryTourEndDate && militaryTourEndDate.invalid && militaryTourEndDate.errors && militaryTourEndDate.errors.required
                        && quickApplyFieldsConfig['mortgage.borrower.militaryTourEndDate'] == 'requested',
                      'invalid-required' : militaryTourEndDate && militaryTourEndDate.invalid && militaryTourEndDate.errors && militaryTourEndDate.errors.required
                        && quickApplyFieldsConfig['mortgage.borrower.militaryTourEndDate'] == 'required' }">
                      </date-input>
                    </div>
                  </div>
                  <div class="radio radio-primary">
                    <input type="radio" name="radio_{{borrower.borrowerId}}" id="radio3_{{borrower.borrowerId}}"
                      value="isRetiredDischargedSeparatedFromMilitary" [(ngModel)]="militaryStatus"
                      (change)="onMilitaryStatusChange()" [required]="quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'required' ||
                    quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'requested'"
                      [ngClass]="{
                    'invalid-requested' : militaryStatus == null && quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'requested',
                    'invalid-required' :militaryStatus == null && quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'required' }">
                    <label for="radio3_{{borrower.borrowerId}}">
                      Currently retired, discharged or seperated from service
                    </label>
                  </div>
                  <div class="radio radio-primary">
                    <input type="radio" name="radio_{{borrower.borrowerId}}" id="radio4_{{borrower.borrowerId}}"
                      value="onlyNonActivatedReserveOrNationalGuard" [(ngModel)]="militaryStatus"
                      (change)="onMilitaryStatusChange()" [required]="quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'required' ||
                    quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'requested'"
                      [ngClass]="{
                    'invalid-requested' : militaryStatus == null && quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'requested',
                    'invalid-required' :militaryStatus == null && quickApplyFieldsConfig['mortgage.borrower.militaryStatus'] == 'required' }">
                    <label for="radio4_{{borrower.borrowerId}}" style="text-overflow: ellipsis; width: calc(95%);">
                      Only period of service was as a non-activated member of the Reserve or National Guard
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="tab-pane" [ngClass]="{ active: subTab === 'declarations' + borrower.borrowerId}"
    id="{{'declarations' + borrower.borrowerId}}" role="tabpanel">
    <div #borrowerDeclarationsInfo id="{{'borrower-declarations-info_' + borrower.borrowerId}}">
      <form #qaBorrowerDeclarationsInfo="ngForm" novalidate id="qaBorrowerDeclarationsInfo"
        name="qaBorrowerDeclarationsInfo">
        <qa-borrower-declarations [borrower]="borrower" [isPurchase]="isPurchase">
        </qa-borrower-declarations>
      </form>
    </div>
  </div>
  <div class="tab-pane" [ngClass]="{ active: subTab === 'demographics' + borrower.borrowerId}"
    id="{{'demographics' + borrower.borrowerId}}" role="tabpanel">
    <div #borrowerDemographicsInfo id="{{'borrower-demographics-info_' + borrower.borrowerId}}">
      <form #qaBorrowerDemographicsInfo="ngForm" novalidate id="qaBorrowerDemographicsInfo"
        name="qaBorrowerDemographicsInfo">
        <qa-borrower-demographics (invalidate)='invalidate.emit()' [borrower]="borrower">
        </qa-borrower-demographics>
      </form>
    </div>
  </div>
</div>

<drawer [title]="'Order Credit'" [name]="'orderCreditDrawerBorrowerInfo'" [templateRef]="orderCreditContentRef"
  [options]="orderCreditDrawerOptions">
</drawer>
<ng-template #orderCreditContentRef>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 mb-3">
        <div class="card-body pt-1">
          <span class="col-md-11 text-soft-pull credit-reporting-inner-html" [innerHtml]="creditReportingHtml"></span>
        </div>
        <div class="row" *ngFor="let borr of mortgage.borrowers">
          <div class="col-md-12">
            <div class="row soft-pull">
              <span class="col-md-7 ms-3 color-soft-pull">
                <strong>{{borr.firstName}}, {{borr.lastName}}</strong>
                 - Do we have your consent for the prequalification check?
              </span>
              <div class="col-md-4">
                <div class="radio-group">
                  <div class="radio radio-info form-check-inline">
                    <input type="radio" id="optionYes_{{borr.borrowerId}}" [value]="true" name="prequalificationCheck_{{borr.borrowerId}}"
                      [(ngModel)]="borr.authorizedCreditCheck" (ngModelChange)="onYesNoOptionChanged(borr)"
                      [disabled]="isPrequalificationCheckReadonly[borr.borrowerId]" [ngStyle]="{'cursor': isPrequalificationCheckReadonly[borr.borrowerId] ? 'default' : 'pointer'}">
                    <label for="optionYes_{{borr.borrowerId}}" class="o-1"> Yes </label>
                  </div>
                  <div class="radio radio-info form-check-inline ms-3">
                    <input type="radio" id="optionNo_{{borr.borrowerId}}" [value]="false" name="prequalificationCheck_{{borr.borrowerId}}"
                      [(ngModel)]="borr.authorizedCreditCheck" (ngModelChange)="onYesNoOptionChanged(borr)"
                      [disabled]="isPrequalificationCheckReadonly[borr.borrowerId]" [ngStyle]="{'cursor': isPrequalificationCheckReadonly[borr.borrowerId] ? 'default' : 'pointer'}">
                    <label for="optionNo_{{borr.borrowerId}}" class="o-1"> No </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-3">
          <loan-credit [mortgage]="mortgage" [embedded]="true"></loan-credit>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<drawer [title]="'Identifying Documents'" [name]="'identifyingDocumentsDrawer1' + borrower.borrowerId" [templateRef]="identifyingDocumentsContentRef1"
  [options]="identifyingDocumentsDrawerOptions">
</drawer>
<ng-template #identifyingDocumentsContentRef1>
  <identifying-documents [selectedBorrower]="borrower"></identifying-documents>
</ng-template>
