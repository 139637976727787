import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { LoanApplication } from 'src/app/models';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { LoanProduct } from 'src/app/models/config/product.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';
import { Subscription } from 'rxjs';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';

@Component({
  selector: 'tpo-loan-detail',
  templateUrl: 'tpo-loan-detail.component.html',
  styleUrls: ['./tpo-loan-detail.component.scss'],
})
export class TpoLoanDetailComponent implements OnInit, OnDestroy {

  private _application: LoanApplication;
  private _mortgage: UrlaMortgage;

  loanPurposes: LoanPurpose[] = [];
  loanTypes: LoanType[] = [];
  products: LoanProduct[] = [];

  loanType: string = '---';
  loanPurpose: string = '---';
  refinancePurpose: string = '---';
  product: string = '---';
  totalLoanAmount: number = 0;
  ltv: number = 0;
  cltv: number = 0;
  downPayment: number = 0;

  isPurchase: boolean = false;

  frontEndDti: number = 0;
  backEndDti: number = 0;

  @Input()
  set mortgageCalculationDetails(calcs: MortgageCalculationDetails) {
    this.ltv = calcs?.ltv?.ltv;
    this.cltv = calcs?.ltv?.cltv;
    this.totalLoanAmount = calcs?.ltv.totalLoanAmount;
    this.frontEndDti = calcs?.dti?.frontEndDti;
    this.backEndDti = calcs?.dti?.backEndDti;
  }

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    if (mortgage) {
      this._mortgage = mortgage;
      this.refinancePurpose = mortgage.subjectProperty?.refinancePrimaryPurpose || '---';
    }
  }

  @Input()
  set application(application: LoanApplication) {
    this._application = application;
    this.downPayment = this._application.downAmount;
    const typeOfLoan = this.loanTypes.find(
      (lt) => lt.loanTypeId == this._application.loanTypeId
    );
    if (typeOfLoan) {
      this.loanType = typeOfLoan.loanTypeName;
    }
    const purposeOfLoan = this.loanPurposes.find(
      (lt) => lt.loanPurposeId == this._application.loanPurposeId
    );
    if (purposeOfLoan) {
      this.loanPurpose = purposeOfLoan.loanPurposeName;
      this.isPurchase = purposeOfLoan?.mortgageLoanPurpose == "Purchase";
    }
    this.product = this._application.productPricing ? this._application.productPricing.productName : '---';
  }

  get application(): LoanApplication {
    return this._application;
  }

  private readonly _applicationContextSubscription: Subscription;

  constructor(private readonly _applicationContext: ApplicationContextService,
    private readonly _mortgageCalculationService: MortgageCalculationService,
  ) {
    this._applicationContextSubscription = this._applicationContext.context.subscribe((context) => {
      if (context.globalConfig) {
        this.loanPurposes = context.globalConfig.loanPurpose;
        this.loanTypes = context.globalConfig.loanType;
        this.products = context.globalConfig.product;
      }
    });
  }

  ngOnInit() { 
   if (this._application) {
    this.downPayment = this._application.downAmount;
    if (!this.downPayment && this._mortgage) {
      this.downPayment = this._mortgageCalculationService.calculateDownPayment(this._mortgage);
    }
   }
  }

  ngOnDestroy() {
    this._applicationContextSubscription?.unsubscribe();
  }
}
