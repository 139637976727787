<span *ngIf="contactInfo">
  <div class="contact-info">
    <a [ngClass]="{'inactive-link': isLinksDisabled}" [href]="'tel:' + contactInfo.mobilePhone | safeUrl" class="me-1"
      *ngIf="!dialerEnabled"><i class="fas fa-phone"></i> M:
      {{ contactInfo.mobilePhone || '--' | phone }}
    </a>

    <a [ngClass]="{'inactive-link': isLinksDisabled}" *ngIf="dialerEnabled"
      (click)="dial(contactInfo.mobilePhone, 'mobile')" class="me-1">
      <i class="fas fa-phone"></i> M:
      {{ contactInfo.mobilePhone || '--' | phone }}
    </a>

    <a *ngIf="contactInfo.mobilePhone && smsAlertsEnabled && !isLinksDisabled && smsEnabled"
      (click)="onOpenSmsChat(contactInfo, contactInfo.mobilePhone)">
      <i style="margin-left: 5px" class="fas fa-sms fa-lg"></i>
    </a>
  </div>

  <div class="contact-info" *ngIf="showHomePhone">
    <a [ngClass]="{'inactive-link': isLinksDisabled}" [href]="'tel:' + contactInfo.phone | safeUrl" class="me-1"
      *ngIf="!dialerEnabled"><i class="fas fa-phone"></i> H:
      {{ contactInfo.phone || '--' | phone }}
    </a>

    <a [ngClass]="{'inactive-link': isLinksDisabled}" *ngIf="dialerEnabled"
      (click)="dial(contactInfo.phone, 'home')" class="me-1">
      <i class="fas fa-phone"></i> H:
      {{ contactInfo.phone || '--' | phone }}
    </a>

    <a *ngIf="contactInfo.phone && smsAlertsEnabled && !isLinksDisabled && smsEnabled"
      (click)="onOpenSmsChat(contactInfo, contactInfo.phone)">
      <i style="margin-left: 5px" class="fas fa-sms fa-lg"></i>
    </a>
  </div>

  <div *ngIf="showFax">
    <span class="me-1"><i class="fas fa-phone"></i> F:
      {{ contactInfo.fax || '--' | phone }}
    </span>
  </div>

  <div [ngClass]="{'text-ellipsis': showFax || ellipsisShowingEnabled, 'cell-mw150': showFax , 'ellipsis-text-container' : ellipsisShowingEnabled}" [title]="contactInfo.email" class="contact-info" *ngIf="!isLinksDisabled">
    <a *ngIf="contactInfo.email && !isLodaEmail" [href]="'mailto:' + contactInfo.email | safeUrl" class="me-1"><i class="fas fa-envelope"></i>
      {{ contactInfo.email || '--' }}
    </a>
    <a *ngIf="contactInfo.email && isLodaEmail" (click)="onEmailClicked()" class="me-1"><i class="fas fa-envelope"></i>
      {{ contactInfo.email || '--' }}
    </a>
    <span *ngIf="!contactInfo.email" class="me-1"><i class="fas fa-envelope"></i>
      {{ contactInfo.email || '--' }}
    </span>
  </div>

</span>
