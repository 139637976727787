import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Adjustment } from '../../models/pricing/pricing-quote.model';

@Component({
  selector: 'view-adjustments-dialog',
  templateUrl: './view-adjustments-dialog.component.html',
  styleUrls: ['./view-adjustments-dialog.component.scss']
})
export class ViewAdjustmentsDialogComponent implements OnInit {

  @Input() adjustments: Adjustment[];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.adjustments.forEach(a => {
      a['formattedAmount'] = a.amount.toFixed(3);
    })
  }
}
