<form #mortgageLoanInfoForm="ngForm">
  <div class="grid-layout has-border" *ngIf="mortgage.mortgageId != null">
    <div class="grid-header">
      <div class="grid-header-left">
        <div>L3. Mortgage Loan Information</div>
      </div>
      <div class="grid-header-right"></div>
    </div>

    <div class="grid-layout-row">
      <!-- Mortgage Applied For -->
      <urla-dropdown [(ngModel)]='mortgage.mortgageTerm.mortgageAppliedFor' (change)='productTypeChanged()'
        [inEditMode]='inEditMode' [options]='productTypes' [readonly]='isReadOnly'
        [required]="isRequired('mortgage.mortgageTerm.mortgageAppliedFor')" [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-4' fieldBeingEdited='mortgage.mortgageTerm.mortgageAppliedFor' label='Mortgage Type Applied For'
        name='mortgageAppliedFor'></urla-dropdown>

      <!-- Community Lending Product Type -->
      <urla-dropdown [(ngModel)]='mortgage.subjectProperty.communityLendingProductType' [inEditMode]='inEditMode'
        [options]='lendingProductTypes' [readonly]='isReadOnly || dhHiddenHack'
        [required]="isRequired('mortgage.subjectProperty.communityLendingProductType')"
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-4'
        fieldBeingEdited='mortgage.subjectProperty.communityLendingProductType' label='Community Lending Product'
        name='communityLendingProductType'></urla-dropdown>

      <!-- Community Seconds Repayment Type -->
      <urla-dropdown [(ngModel)]='mortgage.subjectProperty.communitySecondsRepaymentType' [inEditMode]='inEditMode'
        [options]='secondsRepaymentTypes' [readonly]='isReadOnly || dhHiddenHack'
        [required]="isRequired('mortgage.subjectProperty.communitySecondsRepaymentType')"
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-4'
        fieldBeingEdited='mortgage.subjectProperty.communitySecondsRepaymentType'
        label='Community Seconds Repayment Type' name='communitySecondsRepaymentType'></urla-dropdown>
    </div>

    <div class="grid-layout-row">
      <!-- Interest Rate -->
      <urla-percentage-input [(ngModel)]='mortgage.mortgageTerm.interestRate'
        (change)='onInterestRateChanged()' [inEditMode]='inEditMode'
        [readonly]='isReadOnly || dhHiddenHack' [required]='isRequired("mortgage.mortgageTerm.interestRate")'
        [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-2' fieldBeingEdited='mortgage.mortgageTerm.interestRate'
        label='Note Rate' name='interestRate'>
      </urla-percentage-input>

      <!-- No. of Months -->
      <urla-digits-input [(ngModel)]='mortgage.mortgageTerm.noOfMonths'
        (blur)='onMortgageTermChanged()' [inEditMode]='inEditMode'
        [readonly]="isReadOnly  && !(isTpoUser && isTBDChecked) || dhHiddenHack"
        [required]='isRequired("mortgage.mortgageTerm.noOfMonths")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-2' fieldBeingEdited='mortgage.mortgageTerm.noOfMonths' label="Loan Term {{mortgage.mortgageTerm.loanAmortizationPeriodType == 'Month'
          || !mortgage.mortgageTerm.loanAmortizationPeriodType ? '(mo)' : ''}}" name='noOfMonths'></urla-digits-input>

      <!-- Lien Position -->
      <urla-dropdown [(ngModel)]='mortgage.mortgageTerm.lienPosition' [inEditMode]='inEditMode'
        [options]='lienPositionTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.mortgageTerm.lienPosition")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-2' fieldBeingEdited='mortgage.mortgageTerm.lienPosition' label='Lien Type'
        name='lienPosition'></urla-dropdown>

      <!-- Amortization Type -->
      <urla-dropdown (editClickEvent)='openArmFeatures()' [(ngModel)]='mortgage.mortgageTerm.amortizationType'
        [hasEditButton]='isArm()' [inEditMode]='inEditMode' [options]='amortizationTypes' [readonly]='isReadOnly'
        [required]='isRequired("mortgage.mortgageTerm.amortizationType")' [urlaFieldsConfig]='urlaFieldsConfig'
        class='col-sm-3' fieldBeingEdited='mortgage.mortgageTerm.amortizationType' label='Amortization Type'
        name='amortizationType'></urla-dropdown>

      <!-- Loan Amortization Period Type -->
      <urla-dropdown class='col-sm-3' name='loanAmortizationPeriodType' label='Loan Amortization Period Type'
        [options]='loanAmortizationPeriodTypes' [readonly]='isReadOnly || dhHiddenHack'
        [(ngModel)]='mortgage.mortgageTerm.loanAmortizationPeriodType' [inEditMode]='inEditMode'
        [urlaFieldsConfig]='urlaFieldsConfig' fieldBeingEdited='mortgage.mortgageTerm.loanAmortizationPeriodType'
        [required]='isRequired("mortgage.mortgageTerm.loanAmortizationPeriodType")'></urla-dropdown>
    </div>

    <government-loan-info *ngIf="['FHA','VA','USDA'].includes(mortgage.mortgageTerm.mortgageAppliedFor) || inEditMode"
      [inEditMode]='inEditMode' [isReadOnly]='isReadOnly' [mortgage]='mortgage'
      [urlaFieldsConfig]='urlaFieldsConfig'></government-loan-info>

    <div class="grid-header">
      <div class="grid-header-left">
        <div class="mb-2">
          <div class="row">
            <div [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}">Payment Breakdown</div>
            <div *ngIf="isRefinance" class="col-sm-4">Current</div>
            <div [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}">Proposed</div>
          </div>
        </div>

        <div class="row proposed-monthly-payment">
          <!-- First Mortgage (P&I) -->
          <div *ngIf='!inEditMode' class='grid-layout-row'>
            <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              label='First Mortgage (P&I)'>
            </urla-grid-cell>

            <urla-currency-input *ngIf="isRefinance"
              [(ngModel)]='mortgage.currentHousingExpense.firstMortgagePrincipalAndInterest'
              (ngModelChange)='calculateSubTotal()' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.currentHousingExpense.otherMortgageLoanPrincipalAndInterest")'
              class='col-sm-4' name='firstMortgagePrincipalAndInterestCurrent'>
            </urla-currency-input>

            <urla-currency-input [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              [(ngModel)]='mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest'
              (ngModelChange)='calculateSubTotal()' [readonly]='isReadOnly' disabled
              name='firstMortgagePrincipalAndInterest'>
            </urla-currency-input>
          </div>

          <!-- Other Mortgage Loan (P&I) -->
          <div class="grid-layout-row">
            <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              label='Subordinate (P&I)'>
            </urla-grid-cell>

            <urla-currency-input *ngIf="isRefinance"
              [(ngModel)]='mortgage.currentHousingExpense.otherMortgageLoanPrincipalAndInterest'
              (ngModelChange)='calculateSubTotal()' [inEditMode]='inEditMode' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.currentHousingExpense.otherMortgageLoanPrincipalAndInterest")'
              [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-4'
              fieldBeingEdited='mortgage.currentHousingExpense.otherMortgageLoanPrincipalAndInterest'
              name='otherMortgageLoanPrincipalAndInterestCurrent'></urla-currency-input>

            <urla-currency-input [(ngModel)]='mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest'
              (ngModelChange)='calculateSubTotal()' [disabled]='true' [inEditMode]='inEditMode' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest")'
              [urlaFieldsConfig]='urlaFieldsConfig' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              fieldBeingEdited='mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest'
              name='otherMortgageLoanPrincipalAndInterest'></urla-currency-input>

          </div>

          <!-- Homeowners Insurance -->
          <div class="grid-layout-row">
            <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              label='Homeowners Insurance'></urla-grid-cell>

            <urla-currency-input *ngIf="isRefinance" [(ngModel)]='mortgage.currentHousingExpense.homeownersInsurance'
              (calculatorClicked)='onHomeownersInsuranceClicked()' (change)='calculateMonthlyMiscExpenses()'
              (ngModelChange)='calculateSubTotal()' [hasCalculator]='false' [isEditableEvenIfItHasACalculator]='true'
              [inEditMode]='inEditMode' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.currentHousingExpense.homeownersInsurance")'
              [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-4'
              fieldBeingEdited='mortgage.currentHousingExpense.homeownersInsurance'
              name='homeownersInsuranceCurrent'></urla-currency-input>

            <urla-currency-input [(ngModel)]='mortgage.proposedHousingExpense.homeownersInsurance'
              (calculatorClicked)='onHomeownersInsuranceClicked()' (change)='calculateMonthlyMiscExpenses()'
              (ngModelChange)='calculateSubTotal()' [hasCalculator]='true' [isEditableEvenIfItHasACalculator]='true'
              [inEditMode]='inEditMode' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.proposedHousingExpense.homeownersInsurance")'
              [urlaFieldsConfig]='urlaFieldsConfig' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              fieldBeingEdited='mortgage.proposedHousingExpense.homeownersInsurance'
              name='homeownersInsurance'></urla-currency-input>
          </div>

          <!-- Supplemental Property Insurance -->
          <div class="grid-layout-row">
            <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              label='Supplemental Ins.' [hasEditButton]='!inEditMode' [readonly]='isReadOnly'
              (editClicked)="openFloodDetails()">
            </urla-grid-cell>

            <urla-currency-input *ngIf="isRefinance"
              (calculatorClicked)='onSupplementalPropertyInsuranceBreakDownClicked()'
              [(ngModel)]='mortgage.currentHousingExpense.supplementalPropertyInsurance'
              (change)='calculateMonthlyMiscExpenses()' (ngModelChange)='onSupplementalPropertyInsuranceChanged()'
              [hasCalculator]='false' [inEditMode]='inEditMode'
              [isEditableEvenIfItHasACalculator]='!isSupplementalPropertyInsuranceBrokenDown' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.currentHousingExpense.supplementalPropertyInsurance")'
              [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-4'
              fieldBeingEdited='mortgage.currentHousingExpense.supplementalPropertyInsurance'
              name='supplementalPropertyInsuranceCurrent'></urla-currency-input>

            <urla-currency-input (calculatorClicked)='onSupplementalPropertyInsuranceBreakDownClicked()'
              [(ngModel)]='mortgage.proposedHousingExpense.supplementalPropertyInsurance'
              (change)='calculateMonthlyMiscExpenses()' (ngModelChange)='onSupplementalPropertyInsuranceChanged()'
              [hasCalculator]='true' [inEditMode]='inEditMode'
              [isEditableEvenIfItHasACalculator]='!isSupplementalPropertyInsuranceBrokenDown' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.proposedHousingExpense.supplementalPropertyInsurance")'
              [urlaFieldsConfig]='urlaFieldsConfig' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              fieldBeingEdited='mortgage.proposedHousingExpense.supplementalPropertyInsurance'
              name='supplementalPropertyInsurance'></urla-currency-input>
          </div>

          <!-- Real Estate Tax -->
          <div class="grid-layout-row">
            <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              label='Property Taxes{{propertyTaxesFieldNameSuffix}}'>
            </urla-grid-cell>

            <urla-currency-input *ngIf="isRefinance" (calculatorClicked)='onPropertyTaxesBreakDownClicked()'
              [(ngModel)]='mortgage.currentHousingExpense.realEstateTax' (change)='calculateMonthlyMiscExpenses()'
              (ngModelChange)='onRealEstateTaxChanged()' [hasCalculator]='false' [inEditMode]='inEditMode'
              [isEditableEvenIfItHasACalculator]='!isRealEstateTaxBrokenDown' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.currentHousingExpense.realEstateTax")'
              [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-4'
              fieldBeingEdited='mortgage.currentHousingExpense.realEstateTax' name='realEstateTaxCurrent'>
            </urla-currency-input>

            <urla-currency-input (calculatorClicked)='onPropertyTaxesBreakDownClicked()'
              [(ngModel)]='mortgage.proposedHousingExpense.realEstateTax' (change)='calculateMonthlyMiscExpenses()'
              (ngModelChange)='onRealEstateTaxChanged()' [hasCalculator]='true' [inEditMode]='inEditMode'
              [isEditableEvenIfItHasACalculator]='!isRealEstateTaxBrokenDown' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.proposedHousingExpense.realEstateTax")'
              [urlaFieldsConfig]='urlaFieldsConfig' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              fieldBeingEdited='mortgage.proposedHousingExpense.realEstateTax' name='realEstateTax'>
            </urla-currency-input>
          </div>

          <!-- Mortgage Insurance -->
          <div *ngIf='!inEditMode && !dhHiddenHack' class='grid-layout-row'>
            <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              label='Mortgage Insurance'></urla-grid-cell>

            <urla-currency-input *ngIf="isRefinance" (calculatorClicked)='onMortgageInsuranceClicked()'
              [(ngModel)]='mortgage.currentHousingExpense.mortgageInsurance' (change)='calculateMonthlyMiscExpenses()'
              (ngModelChange)='calculateSubTotal()' [hasCalculator]='false' [readonly]='isReadOnly' class='col-sm-4'
              name='mortgageInsuranceCurrent'></urla-currency-input>

            <urla-currency-input (calculatorClicked)='onMortgageInsuranceClicked()'
              [(ngModel)]='mortgage.proposedHousingExpense.mortgageInsurance' (change)='calculateMonthlyMiscExpenses()'
              (ngModelChange)='calculateSubTotal()' [hasCalculator]='true' [readonly]='isReadOnly'
              [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              name='mortgageInsurance'></urla-currency-input>
          </div>

          <!-- Homeowners Association Dues and Condominium Fees -->
          <div class="grid-layout-row">
            <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              label='HOA/Project Dues'></urla-grid-cell>

            <urla-currency-input *ngIf="isRefinance" (calculatorClicked)='onHoaAndCondoFeesBreakDownClicked()'
              [(ngModel)]='mortgage.currentHousingExpense.homeownersAssociationDuesAndCondominiumFees'
              (change)='calculateMonthlyMiscExpenses()' (ngModelChange)='onHoaAndCodoFeesChanged()'
              [hasCalculator]='false' [inEditMode]='inEditMode'
              [isEditableEvenIfItHasACalculator]='!isHoaAndCondoFeesBrokenDown' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.currentHousingExpense.homeownersAssociationDuesAndCondominiumFees")'
              [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-4'
              fieldBeingEdited='mortgage.currentHousingExpense.homeownersAssociationDuesAndCondominiumFees'
              name='homeownersAssociationDuesAndCondominiumFeesCurrent'></urla-currency-input>

            <urla-currency-input (calculatorClicked)='onHoaAndCondoFeesBreakDownClicked()'
              [(ngModel)]='mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees'
              (change)='calculateMonthlyMiscExpenses()' (ngModelChange)='onHoaAndCodoFeesChanged()'
              [hasCalculator]='true' [inEditMode]='inEditMode'
              [isEditableEvenIfItHasACalculator]='!isHoaAndCondoFeesBrokenDown' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees")'
              [urlaFieldsConfig]='urlaFieldsConfig' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              fieldBeingEdited='mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees'
              name='homeownersAssociationDuesAndCondominiumFees'></urla-currency-input>
          </div>

          <!-- Other Housing Expense -->
          <div class="grid-layout-row">
            <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              style="line-height: 25px;">
              <div class="d-flex w-100 justify-content-between otherHousingExpenseDescription">
                <span class="align-self-center me-2">Other</span>

                <urla-text-input *ngIf="mortgage.proposedHousingExpense.otherHousingExpense"
                  [(ngModel)]="mortgage.proposedHousingExpense.otherHousingExpenseDescription"
                  [name]="'otherHousingExpenseDescription'" [placeholder]="'Description'" [readonly]='isReadOnly'
                  [required]='isRequired("mortgage.proposedHousingExpense.otherHousingExpenseDescription")'
                  fieldBeingEdited='mortgage.proposedHousingExpense.otherHousingExpenseDescription'
                  [urlaFieldsConfig]='urlaFieldsConfig'>
                </urla-text-input>
              </div>

            </urla-grid-cell>

            <urla-currency-input *ngIf="isRefinance" [(ngModel)]='mortgage.currentHousingExpense.otherHousingExpense'
              (ngModelChange)='calculateSubTotal(); onOtherHousingExpenseChanged()' [inEditMode]='inEditMode'
              [readonly]='isReadOnly' [required]='isRequired("mortgage.currentHousingExpense.otherHousingExpense")'
              [urlaFieldsConfig]='urlaFieldsConfig' class='col-sm-4'
              fieldBeingEdited='mortgage.currentHousingExpense.otherHousingExpense'
              name='otherHousingExpenseCurrent'></urla-currency-input>

            <urla-currency-input [(ngModel)]='mortgage.proposedHousingExpense.otherHousingExpense'
              (ngModelChange)='calculateSubTotal(); onOtherHousingExpenseChanged()' [inEditMode]='inEditMode'
              [readonly]='isReadOnly' [required]='isRequired("mortgage.proposedHousingExpense.otherHousingExpense")'
              [urlaFieldsConfig]='urlaFieldsConfig' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              fieldBeingEdited='mortgage.proposedHousingExpense.otherHousingExpense'
              name='otherHousingExpense'></urla-currency-input>
          </div>

          <!-- Proposed Monthly Payment Total -->
          <div *ngIf='!inEditMode' class='grid-layout-row'>
            <urla-grid-cell [disabled]='isReadOnly' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}"
              label='TOTAL'></urla-grid-cell>

            <urla-currency-input *ngIf="isRefinance" [(ngModel)]='mortgage.calculatedStats.currentMonthlyPaymentTotal'
              [readonly]='isReadOnly' class='col-sm-4' disabled name='currentMonthlyPaymentTotal'></urla-currency-input>

            <urla-currency-input [(ngModel)]='mortgage.calculatedStats.proposedMonthlyPaymentTotal'
              [readonly]='isReadOnly' [ngClass]="{'col-sm-4': isRefinance, 'col-sm-6': !isRefinance}" disabled
              name='proposedMonthlyPaymentTotal'></urla-currency-input>
          </div>
        </div>
      </div>

      <div class="grid-header-left">
        <div class="d-flex mb-2">
          <div class="col-sm-5">
            Loan Features
            <button *ngIf="!inEditMode" (click)='openLoanFeaturesDetails()'
              class='btn btn-xs btn-soft-primary float-end py-0 me-2'>
              ...
            </button>
          </div>
        </div>

        <div class="row loan-features">
          <div class="grid-layout-row">

            <!-- Escrow Waived -->
            <urla-boolean-checkbox [(ngModel)]='mortgage.escrowDetail.escrowsAreWaivedByLender'
              [readonly]='isReadOnly || inEditMode' class='col-sm-5' label='Escrow Waived' layout='single'
              name='escrowWaived'></urla-boolean-checkbox>
            <div class="col-sm-7"></div>
          </div>

          <div class="grid-layout-row" *ngIf="!dhHiddenHack">

            <!-- Is Balloon Payment Required to Pay Off Loan -->
            <urla-boolean-checkbox [(ngModel)]='mortgage.extension.isBalloonPaymentRequiredToPayOffLoan'
              (ngModelChange)='balloonPayment()' [readonly]='isReadOnly || inEditMode' class='col-sm-5' label='Balloon'
              layout='single' name='balloon' [hasEditButton]="mortgage.extension.isBalloonPaymentRequiredToPayOffLoan"
              (editClickEvent)="openBalloonDetails()"
              [editButtonEnabledEvenWhenReadOnly]="true"></urla-boolean-checkbox>

            <urla-grid-cell *ngIf='mortgage.extension.isBalloonPaymentRequiredToPayOffLoan || inEditMode'
              [disabled]='isReadOnly' class='col-sm-4' label='Balloon Term'></urla-grid-cell>

            <!-- Balloon Term Months -->
            <urla-digits-input *ngIf='mortgage.extension.isBalloonPaymentRequiredToPayOffLoan || inEditMode'
              [(ngModel)]='mortgage.extension.balloonTermMonths' [inEditMode]='inEditMode' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.extension.balloonTermMonths")' [urlaFieldsConfig]='urlaFieldsConfig'
              class='col-sm-3' fieldBeingEdited='mortgage.extension.balloonTermMonths'
              name='balloonTermMonths'>
            </urla-digits-input>

            <div *ngIf='!mortgage.extension.isBalloonPaymentRequiredToPayOffLoan && !inEditMode'
              class='grid-layout-spacer col-sm-7'></div>
          </div>

          <div class="grid-layout-row">
            <!-- Is Interest Only -->
            <urla-boolean-checkbox [(ngModel)]='mortgage.extension.isInterestOnly'
              (change)='onInterestOnlyChanged()' [readonly]='isReadOnly || inEditMode' class='col-sm-5'
              label='Interest Only' layout='single' name='isInterestOnly'>
            </urla-boolean-checkbox>

            <urla-grid-cell *ngIf='mortgage.extension.isInterestOnly || inEditMode' [disabled]='isReadOnly'
              class='col-sm-4' label='Interest Only Term'>
            </urla-grid-cell>

            <!-- Interest Only Term Months -->
            <urla-digits-input *ngIf='mortgage.extension.isInterestOnly || inEditMode'
              [(ngModel)]='mortgage.extension.interestOnlyTermMonths' [inEditMode]='inEditMode' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.extension.interestOnlyTermMonths")' [urlaFieldsConfig]='urlaFieldsConfig'
              class='col-sm-3' fieldBeingEdited='mortgage.extension.interestOnlyTermMonths'
              name='interestOnlyTermMonths'>
            </urla-digits-input>

            <div *ngIf='!mortgage.extension.isInterestOnly && !inEditMode' class='grid-layout-spacer col-sm-7'></div>
          </div>

          <div class="grid-layout-row" *ngIf="!dhHiddenHack">
            <!-- Has Negative Amortization -->
            <urla-boolean-checkbox [(ngModel)]='mortgage.extension.hasNegativeAmortization'
              [readonly]='isReadOnly || inEditMode' class='col-sm-5' label='Negative Amortization' layout='single'
              name='hasNegativeAmortization'></urla-boolean-checkbox>

            <urla-grid-cell *ngIf='mortgage.extension.hasNegativeAmortization || inEditMode' [disabled]='isReadOnly'
              class='col-sm-4' label='NegAm Option'></urla-grid-cell>

            <!-- Negative Amortization Type -->
            <urla-dropdown *ngIf='mortgage.extension.hasNegativeAmortization || inEditMode'
              [(ngModel)]='mortgage.ausData.negativeAmortizationType' [inEditMode]='inEditMode'
              [options]='negativeAmortizationTypes' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.ausData.negativeAmortizationType")' [urlaFieldsConfig]='urlaFieldsConfig'
              class='col-sm-3' fieldBeingEdited='mortgage.ausData.negativeAmortizationType'
              name='negativeAmortizationType'></urla-dropdown>

            <div *ngIf='!mortgage.extension.hasNegativeAmortization && !inEditMode' class='grid-layout-spacer col-sm-7'>
            </div>
          </div>

          <div class="grid-layout-row">
            <!-- Has Prepayment Penalty -->
            <urla-boolean-checkbox [(ngModel)]='mortgage.extension.isPenaltyChargedInEventOfPrepayment'
              [readonly]='isReadOnly || inEditMode'
              [hasEditButton]="!!mortgage.extension.isPenaltyChargedInEventOfPrepayment" class='col-sm-5'
              label='Prepayment Penalty' layout='single' name='isPenaltyChargedInEventOfPrepayment'
              (editClickEvent)="openPrepaymentPenaltyDetails()"
              [editButtonEnabledEvenWhenReadOnly]="true"></urla-boolean-checkbox>

            <div *ngIf='!mortgage.extension.isPenaltyChargedInEventOfPrepayment && !inEditMode'
              class='grid-layout-spacer col-sm-7'></div>
          </div>

          <div class="grid-layout-row" *ngIf="!dhHiddenHack">
            <!-- Has Buydown -->
            <urla-boolean-checkbox [(ngModel)]='mortgage.extension.buydownDetail.hasBuydown'
              [readonly]='isReadOnly || inEditMode' class='col-sm-5' label='Temporary Rate Buydown' layout='single'
              name='hasBuydown' [hasEditButton]="mortgage.extension.buydownDetail.hasBuydown"
              (editClickEvent)="openBuydownDetails()"
              [editButtonEnabledEvenWhenReadOnly]="true"></urla-boolean-checkbox>

            <urla-grid-cell *ngIf='mortgage.extension.buydownDetail.hasBuydown || inEditMode' [disabled]='isReadOnly'
              class='col-sm-4' label='Initial Buydown Rate'></urla-grid-cell>

            <!-- Initial Buydown Rate -->
            <urla-percentage-input [(ngModel)]='mortgage.extension.buydownDetail.initialBuydownRate'
              (ngModelChange)='calculateSubTotal()' *ngIf='mortgage.extension.buydownDetail.hasBuydown || inEditMode'
              [inEditMode]='inEditMode' [readonly]='isReadOnly'
              [required]='isRequired("mortgage.extension.initialBuydownRate")' [urlaFieldsConfig]='urlaFieldsConfig'
              class='col-sm-3' fieldBeingEdited='mortgage.extension.initialBuydownRate'
              name='initialBuydownRate'></urla-percentage-input>

            <div *ngIf='!mortgage.extension.hasBuydown && !inEditMode' class='grid-layout-spacer col-sm-7'></div>
          </div>

          <div class="grid-layout-row">
            <!-- Has Late Charge -->
            <urla-grid-cell [disabled]='isReadOnly' [hasEditButton]="!inEditMode" class='col-sm-5' label='Late Charge'
              [readonly]='isReadOnly' (editClicked)="onEditLateChargeDetailsClicked()"></urla-grid-cell>
            <div class='grid-layout-spacer col-sm-7'></div>
          </div>

          <div *ngIf="isArm() || inEditMode">
            <div class='row mt-2'>
              <div class='col-sm-5 col-xs-5'>
                ARM Features
                <button *ngIf="!inEditMode" (click)='openArmFeatures()' class='btn btn-sm btn-primary float-end'>
                  Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<loading-indicator *ngIf='mortgage.mortgageId == null'
  loadingMessage='Loading mortgage/loan info...'></loading-indicator>

<drawer title='Arm Features' name='armFeaturesDrawer' [options]='drawerOptions' #armFeaturesDrawer></drawer>

<drawer [title]="'Details'" #dynamicDrawer [name]="'mortgageLoanDynamicDrawer'" [options]="dynamicDrawerOptions"
  (closed)="onDynamicDrawerClosed()">
</drawer>

<!-- <drawer [title]="'Homeowners Insurance Details'" #dynamicDrawerHomeownersInsurance [name]="'homeownersInsuranceDynamicDrawer'" [options]="dynamicDrawerOptions"
  (closed)="onDynamicDrawerClosed()">
</drawer> -->