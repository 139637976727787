<div class="card">
  <div class="card-header">
    <h4 class="card-title">
      About This Property and Your Money for this Loan
    </h4>
  </div>
  <div class="card-body">
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> A. Will you occupy the property as your primary residence? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
          [ngClass]="{
          'ng-invalid-required' : borrower.declarations.occupyProperty == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.occupyProperty'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.occupyProperty == null && quickApplyFieldsConfig['mortgage.borrower.declarations.occupyProperty'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'occupyProperty' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'occupyProperty' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.occupyProperty"
                [value]="option.value"
                (change)="occupyPropertyChange(option.value)"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.occupyProperty'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.occupyProperty'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2" *ngIf="borrower.declarations.occupyProperty">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> A.1 If Yes, have you had an ownership interest in another property in the last three years? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.havePropertiesOwnership == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.havePropertiesOwnership == null && quickApplyFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'havePropertiesOwnership' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'havePropertiesOwnership' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.havePropertiesOwnership"
                [value]="option.value"
                (change)="havePropertiesOwnershipChange(option.value)"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2" *ngIf="borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> A1.1 What type of property did you own? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.typeOfProperty == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.typeOfProperty == null && quickApplyFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] == 'requested'}">
          <select id="propertyType{{borrower.borrowerId}}" name="propertyType{{borrower.borrowerId}}"
            [(ngModel)]="borrower.declarations.typeOfProperty"
            [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] == 'required' ||
            quickApplyFieldsConfig['mortgage.borrower.declarations.typeOfProperty'] == 'requested'">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let type of propertyTypesOwned" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2" *ngIf="borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> A1.2 How did you hold title to the property? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.heldTitleHow == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.heldTitleHow == null && quickApplyFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] == 'requested'}">
          <select id="heldTitleHow{{borrower.borrowerId}}" name="heldTitleHow{{borrower.borrowerId}}"
            [(ngModel)]="borrower.declarations.heldTitleHow"
            [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] == 'required' ||
            quickApplyFieldsConfig['mortgage.borrower.declarations.heldTitleHow'] == 'requested'">
            <option [ngValue]="null">-- Select One --</option>
            <option *ngFor="let type of holdTitles" [ngValue]="type.value">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2" *ngIf="isPurchase">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> A.2 Does the borrower qualify as a first time home buyer? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.isFirstTimeHomeBuyer == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.isFirstTimeHomeBuyer == null && quickApplyFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'isFirstTimeHomeBuyer' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'isFirstTimeHomeBuyer' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.isFirstTimeHomeBuyer"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> B. If this is a Purchase Transaction: Do you have a family relationship or business affiliation with
            the seller of the property? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.haveRelationshipWithSeller == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.haveRelationshipWithSeller == null && quickApplyFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'haveRelationshipWithSeller' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'haveRelationshipWithSeller' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.haveRelationshipWithSeller"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> C. Are you borrowing any money for this real estate transaction (e.g., money for your closing costs or
            down payment) or obtaining any money from another party, such as the seller or realtor, that you have not
            disclosed on this loan application? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.borrowedDownPayment == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.borrowedDownPayment == null && quickApplyFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'borrowedDownPayment' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'borrowedDownPayment' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.borrowedDownPayment"
                (change)="borrowedDownPaymentChange()"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2" *ngIf="borrower.declarations.borrowedDownPayment">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> C.1 If Yes, what is the amount of this money? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.undisclosedMoneyAmount == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.undisclosedMoneyAmount == null && quickApplyFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] == 'requested'}">
          <currency-input [name]="'undisclosedMoneyAmount'" [placeholder]="'$'" [isZeroValid]="false"
            [allowDecimals]="false" [(ngModel)]="borrower.declarations.undisclosedMoneyAmount"
            [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] == 'required' ||
            quickApplyFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount'] == 'requested'">
          </currency-input>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> D.1 Have you or will you be applying for a mortgage loan on another property (not the property securing
            this loan) on or before closing this transaction that is not disclosed on this loan application? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.applyingForOtherMortgage == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.applyingForOtherMortgage == null && quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'applyingForOtherMortgage' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'applyingForOtherMortgage' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.applyingForOtherMortgage"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> D.2 Have you or will you be applying for any new credit (e.g., installment loan, credit card, etc.) on
            or before closing this loan that is not disclosed on this application? </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.applyingForNonMortgageCredit == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.applyingForNonMortgageCredit == null && quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'applyingForNonMortgageCredit' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'applyingForNonMortgageCredit' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.applyingForNonMortgageCredit"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row grid-layout mb-2">
      <div class="col-10">
        <div class="grid-layout-left">
          <span> E. Will this property be subject to a lien that could take priority over the first mortgage lien, such
            as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?
          </span>
        </div>
      </div>
      <div class="col-2">
        <div class="grid-layout-right"
        [ngClass]="{
          'ng-invalid-required' : borrower.declarations.propertyHasLienWithCleanEnergyProgram == null &&
          quickApplyFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] == 'required' ,
          'ng-invalid-requested' : borrower.declarations.propertyHasLienWithCleanEnergyProgram == null && quickApplyFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] == 'requested'}">
          <div class="radio-group-container">
            <label class="radio-group-item" *ngFor="let option of yesNoOptions" [for]="'propertyHasLienWithCleanEnergyProgram' + borrower.borrowerId" >
              <input
                type="radio"
                [name]="'propertyHasLienWithCleanEnergyProgram' + borrower.borrowerId"
                [id]="borrower.borrowerId + '_' + option.value"
                [(ngModel)]="borrower.declarations.propertyHasLienWithCleanEnergyProgram"
                [value]="option.value"
                [required]="quickApplyFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] == 'required' ||
                quickApplyFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram'] == 'requested'"/>
                {{option.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
