<ng-container *ngIf="!isLoadingLoanData">
  <div *ngIf="!isUsedAsChildComponent"
    class="card pt-1 pe-1 pb-1 mb-2 tpo-report-card sticky-button-bar sticky-button-bar-vertical"
    [ngClass]="{'sticky-nav-tabs-open-dialer': secondPageActionBarVisible }">
    <div class="button-items">
      <div class="float-start">
        <div class="d-inline-flex ms-3 mt-2" style="padding-top: 2px;">

        </div>
      </div>
      <div class="float-end">
        <button type="button" class="btn nav nav-link me-2 d-inline" style="margin-bottom: 4px;margin-top: -3px;"
          [print]="[
      'loan-summary-pdf',
      {
        printMode: 'template-popup',
        pageTitle: getPersonFullNames([borrowers[0]?.borrowerId])
      }
    ]">
          Print as PDF
        </button>

        <button type="button" [disabled]="isLoanReadOnly" class="btn btn-primary" (mousedown)="saveLoanInfo()">
          <i class="fas fa-save"></i>
          {{isLoanReadOnly ? 'Save (Readonly)' : 'Save'}}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isUsedAsChildComponent"
    class="card pt-1 pe-1 pb-1 mb-2">
    <div class="button-items">
      <div class="float-start">
        <div class="d-inline-flex ms-3 mt-2" style="padding-top: 2px;">

        </div>
      </div>
      <div class="float-end">
        <a type="button" class="btn"
          [routerLink]="['/admin/app-details/' + application.applicationId]"> Open Application
        </a>
      </div>
    </div>
  </div>
  <div id="loan-summary-pdf" [ngClass]="{'modal-body modal-body--scroll': isUsedAsChildComponent}">
    <form #loanForm="ngForm" novalidate id="loanForm" name="loanForm">

      <loan-borrowers [isLoanReadOnly]="isLoanReadOnly" [actionButtonsVisible]="true" (deleted)="onBorrowerDeleted($event)"
        (updated)="onBorrowerUpdated($event)" (updatedPrimaryBorrower)="onUpdatedPrimaryBorrower($event)">
      </loan-borrowers>

      <div class="card" id="customInfo" *ngIf="companyId == 172 || companyId == 213">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-key me-2"></i> Custom Info</h4>
          <a class="link" (click)="showCDCardBody = !showCDCardBody">{{showCDCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showCDCardBody">
          <div class="row">
            <custom-data-info [application]="application" [customData]="customData"
              [isInClassicEditMode]="editModeToggle" [labelAlignment]="'left'">
            </custom-data-info>
          </div>
        </div>
      </div>

      <div class="card" id="loanAndPropertyInfo">
        <div class="card-header">
          <h4 class="card-title"><i class="fas fa-file-contract me-2"></i> Loan & Property Info</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">Loan Purpose</div>
                <div class="col-6">
                  <select-input [editorMode]='editorMode' [(ngModel)]='loanPurposeId' (blur)='onLoanPurposeIdChange()'
                    [options]='loanPurposesFiltered' name='ls_loanPurposeId' [editorMode]='editorMode' [required]='true'
                    [optionValueKey]="'loanPurposeId'" [optionNameKey]="'loanPurposeName'"
                    [placeholder]="'Loan Purpose'"></select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Loan Type</div>
                <div class="col-6">
                  <select-input [(ngModel)]="application.loanTypeId" [options]="loanTypesFiltered" name="ls_loanTypeId"
                    [editorMode]="editorMode" [required]="true" [optionValueKey]="'loanTypeId'"
                    (blur)="updateMortgageAppliedFor()" [optionNameKey]="'loanTypeName'"
                    [placeholder]="'Loan Type'"></select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Property Occupancy</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.subjectProperty.propertyWillBe" [options]="propertyOccupancyTypes"
                    name="ls_propertyOccupancy" [editorMode]="editorMode"
                    [placeholder]="'Property Occupancy'"></select-input>

                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Construction Method</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.subjectProperty.constructionMethod"
                    [options]="constructionMethodOptions" [ngbTooltip]="missingConstructionMethod"
                    class="inline-editor-text-wrapper" name="ls_constructionMethod" [editorMode]="editorMode"
                    [placeholder]="'Construction Method'"></select-input>
                  <ng-template #missingConstructionMethod>
                    {{ constructionMethodNamesByKey[mortgage.subjectProperty.constructionMethod]}}
                  </ng-template>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Loan FICO</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="ficoOnFile" class="fw-bold">{{ficoOnFile}}</span>
                    <span *ngIf="!ficoOnFile">--</span>
                  </span>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Loan Product</div>
                <div class="col-6">
                  <select-input [(ngModel)]="application.productPricing.productId"
                    (blur)="selectProduct(application.productPricing.productId)" [optionValueKey]="'productId'"
                    [optionNameKey]="'productName'" [options]="products" name="ls_loanProduct" [editorMode]="editorMode"
                    [placeholder]="'Loan Product'" [disabled]="application.productPricing.assignDate"></select-input>
                </div>
              </div>

              <div class="row py-1 lender">
                <div class="col-6">Lender</div>
                <div class="col-6">
                  <span class="d-flex justify-content-start align-items-center">
                    <select-input class="d-inline-block" [(ngModel)]='application.lenderId'
                      [ngStyle]="{flexGrow: isLenderSelectCollapsed ? 0 : 1}"
                      (editStateChange)="isLenderSelectCollapsed = !$event" [optionValueKey]="'lenderId'"
                      [optionNameKey]="'name'" [options]="lenders" name="ls_lender" [editorMode]="editorMode"
                      [placeholder]="'Lender'"></select-input>
                    <button *ngIf="isLenderSelectCollapsed && application.lenderId" (click)="showLenderInfoDialog()"
                      class="btn d-flex justify-content-center align-items-center ms-1 p-0" type="button">
                      <i class="fas fa-info-circle" style="color: var(--ck-color-button-on-color)"></i>
                    </button>
                  </span>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Interest Rate / APR</div>
                <div class="col-6">
                  <span class="d-flex justify-content-start align-items-center">
                    <i *ngIf="application &&
                    (
                      (application.productPricing && application.productPricing.lockStatus && application.productPricing.lockStatus == 'Accepted') ||
                      (!application.productPricing?.lockStatus && hasRateLockExpirationKeyDate)
                    )" class="fas fa-lock me-1" style="color: orange"></i>
                    <i *ngIf="application &&
                    (
                      (application.productPricing && application.productPricing.lockStatus &&  application.productPricing.lockStatus != 'Accepted') ||
                      (!application.productPricing?.lockStatus && !hasRateLockExpirationKeyDate)
                    )" class="fas fa-lock-open me-1" style="color: orange"></i>
                    <percent-input class="d-inline-block" [inlineTextClass]="'dark-success'"
                      [rateIsTrueToTextValue]="true" [(ngModel)]="application.productPricing.rate"
                      name="ls_pp_interestRate" [digitsInfo]="'1.3-3'" [editorMode]="editorMode"
                      [placeselect-inputholder]="'Rate'" [disabled]="application.productPricing.assignDate"
                      (blur)="onProductPricingRateChanged()"></percent-input>
                    <span class="mx-2">/</span>
                    <percent-input class="d-inline-block" [inlineTextClass]="'text-secondary'"
                      [rateIsTrueToTextValue]="true" [(ngModel)]="application.productPricing.apr" name="ls_apr"
                      [digitsInfo]="'1.3-3'" [editorMode]="editorMode" [placeholder]="'APR'"></percent-input>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Lock Expiration</div>
                <div class="col-6">
                  <span style="font-weight: bold !important;"> {{ lockExpirationDateString }} </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Comp Type</div>
                <div class="col-6">
                  <span class='d-flex justify-content-start align-items-center'>
                    <select-input class='d-inline-block' [(ngModel)]='application.productPricing.compType'
                      [ngStyle]='{flexGrow: isCompTypeSelectCollapsed ? 0 : 1}'
                      (editStateChange)='isCompTypeSelectCollapsed = !$event' [optionValueKey]="'value'"
                      [optionNameKey]="'name'" [options]='compTypes' name='ls_compType' [editorMode]="editorMode"
                      [placeholder]="'Comp Type'"></select-input>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Compensation</div>
                <div class="col-6">
                  <span>
                    <percent-input class="d-inline-block" [disabled]="application.productPricing.assignDate"
                      [(ngModel)]="application.productPricing.comp" name="ls_comp" [digitsInfo]="'1.3-3'"
                      [editorMode]="editorMode" [placeholder]="'Compensation'">
                    </percent-input>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Margin</div>
                <div class="col-6">
                  <span>
                    <number-input class="inline-editor-text-wrapper" [disabled]="application.productPricing.assignDate"
                      [(ngModel)]="application.productPricing.margin" name="ls_margin" [decimals]="'3'"
                      [editorMode]="editorMode" [placeholder]="'Margin'">
                    </number-input>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Rebate</div>
                <div class="col-6">
                  <span>
                    <number-input class="d-inline-block" [disabled]="application.productPricing.assignDate"
                      [(ngModel)]="application.productPricing.rebate" name="ls_rebate" [decimals]="'3'"
                      [allowNegative]="true" [editorMode]="editorMode" [placeholder]="'Rebate'"></number-input></span>
                </div>
              </div>

            </div>

            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">DTI (front / back)</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0 fw-bold">
                    <span [ngClass]="{
                      'dti-danger': mortgage.transactionDetail?.frontEndDti > 47,
                      'dti-warning':
                        mortgage.transactionDetail?.frontEndDti > 43 &&
                        mortgage.transactionDetail?.frontEndDti <= 47,
                      'dti-success': mortgage.transactionDetail?.frontEndDti <= 43
                    }">
                      {{
                      mortgage.transactionDetail?.frontEndDti > 999
                      ? '--'
                      : (mortgage.transactionDetail?.frontEndDti || 0 | number : '1.0-3')
                      }}%
                    </span>
                    /
                    <span [ngClass]="{
                      'dti-danger': mortgage.transactionDetail?.backEndDti > 47,
                      'dti-warning':
                        mortgage.transactionDetail?.backEndDti > 43 &&
                        mortgage.transactionDetail?.backEndDti <= 47,
                      'dti-success': mortgage.transactionDetail?.backEndDti <= 43
                    }">
                      {{
                      mortgage.transactionDetail?.backEndDti > 999
                      ? '--'
                      : (mortgage.transactionDetail?.backEndDti || 0 | number : '1.0-3')
                      }}%
                    </span>
                  </span>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">LTV</div>
                <div class="col-6">
                  <percent-input [(ngModel)]="requestedLtv" [rateIsTrueToTextValue]="true" name="ls_ltv"
                    [digitsInfo]="'1.3-3'" [editorMode]="editorMode" (blur)="onRequestedLtvChanged()"
                    [placeholder]="'LTV'"></percent-input>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">CLTV</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="mortgage.transactionDetail?.cltv" class="fw-bold">{{(mortgage.transactionDetail?.cltv /
                      100 | percent : '1.3-3') || '--'}}</span>
                  </span>
                </div>

              </div>
              <div class="row py-1">
                <div class="col-6">Monthly Debt</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0 text-warning">
                    <span *ngIf="totalMonthlyDebts" class="fw-bold">{{totalMonthlyDebts | currency}}</span>
                    <span *ngIf="!totalMonthlyDebts">--</span>
                  </span>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Unpaid Balance</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="unPaidBalanceTotal" class="fw-bold">{{unPaidBalanceTotal | currency}}</span>
                    <span *ngIf="!unPaidBalanceTotal">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Monthly Income</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0 text-success">
                    <span *ngIf="incomeTotal" class="fw-bold">{{incomeTotal | currency}}</span>
                    <span *ngIf="!incomeTotal">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Current Housing</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="currentHousingExpenseTotal" class="fw-bold">{{currentHousingExpenseTotal |
                      currency}}</span>
                    <span *ngIf="!currentHousingExpenseTotal">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Total Monthly Payments</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">

                    <span *ngIf="housingPaymentsTotal" class="fw-bold">{{housingPaymentsTotal | currency}}</span>
                    <span *ngIf="!housingPaymentsTotal">--</span>

                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Proposed Housing</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="proposedHousingExpenseTotal" class="fw-bold">{{proposedHousingExpenseTotal |
                      currency}}</span>
                    <span *ngIf="!proposedHousingExpenseTotal">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Total Monthly PITI</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="totalPiTi" class="fw-bold">{{totalPiTi | currency}}</span>
                    <span *ngIf="!totalPiTi">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Total Assets Value</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="assetTotal" class="fw-bold">{{assetTotal | currency}}</span>
                    <span *ngIf="!assetTotal">--</span>

                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Cash from/To Borrower</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0" [ngClass]="{
                    'text-danger': ftcDetails?.cashFromToBorrower < 0,
                    'text-success': ftcDetails?.cashFromToBorrower > 0
                  }">
                    <span *ngIf="ftcDetails?.cashFromToBorrower" class="fw-bold">
                      {{ftcDetails?.cashFromToBorrower | currency}}
                    </span>
                    <span *ngIf="!ftcDetails?.cashFromToBorrower">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Reserves (months)</div>
                <div class="col-6">
                  <number-input [allowNegative]="false" [(ngModel)]="mortgage.transactionDetail.reserves"
                    name="ls_monthReserves" [editorMode]="editorMode" [placeholder]="'Month Reserves'">
                  </number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Estimated Closing Date</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="application.estimatedClosingDate" class="fw-bold">{{application.estimatedClosingDate |
                      date: 'MM/dd/yyyy'}}</span>
                    <span *ngIf="!application.estimatedClosingDate">--</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" id="mortgageInfo">
        <div class="card-header">
          <h4 class="card-title"><i class="fas fa-file-contract me-2"></i> Mortgage Info</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="row py-1" *ngIf="!isRefi">
                <div class="col-6">Property Purchase Price</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0"
                    *ngIf="isRefi; else purchasePriceAmountInput">
                    --
                  </span>
                  <ng-template #purchasePriceAmountInput>
                    <currency-input [(ngModel)]="mortgage.transactionDetail.purchasePriceAmount"
                      (blur)="calculateTotalDue(); onPurchasePriceAmountChanged()" name="ls_propertyPurchasePrice"
                      [editorMode]="editorMode" [placeholder]="'Property Purchase Price'"></currency-input>
                  </ng-template>
                </div>
              </div>
              <div class="row py-1" [hidden]="!!mortgage.mortgageTerm.appraisedValue">
                <div class="col-6">Estimated Value</div>
                <div class="col-6">
                  <currency-input [(ngModel)]="mortgage.subjectProperty.presentValue" name="ls_estPropertyValue"
                    [editorMode]="editorMode" (blur)="onAppraisedPropertyValueChanged()"
                    [placeholder]="'Estimated Property Value'"></currency-input>
                </div>
              </div>
              <div class="row py-1" [hidden]="!mortgage.mortgageTerm.appraisedValue">
                <div class="col-6">Appraised Value</div>
                <div class="col-6">
                  <currency-input [(ngModel)]="mortgage.mortgageTerm.appraisedValue" name="ls_appraisedPropertyValue"
                    [editorMode]="editorMode" (blur)="onAppraisedPropertyValueChanged()"
                    [placeholder]="'Appraised Property Value'"></currency-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Base Loan Amount</div>
                <div class="col-6">
                  <currency-input [(ngModel)]="mortgage.mortgageTerm.amount" name="ls_baseLoanAmount"
                    [editorMode]="editorMode" [allowDecimals]="false" (blur)="onBaseLoanAmountChanged()"
                    [placeholder]="'Base Loan Amount'"></currency-input>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Subordinate Lien Amount</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">

                    <span *ngIf="mortgage.transactionDetail.subordinateLienAmount"
                      class="fw-bold">{{mortgage.transactionDetail.subordinateLienAmount | currency}}</span>
                    <span *ngIf="!mortgage.transactionDetail.subordinateLienAmount">--</span>

                  </span>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Lien Position</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.mortgageTerm.lienPosition" [options]="lienPositionTypes"
                    name="ls_lienPosition" [editorMode]="editorMode" [placeholder]="'Lien Position'"></select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Total Loan Amount</div>
                <div class="col-6">
                  <currency-input [(ngModel)]="mortgage.mortgageTerm.totalLoanAmount" name="ls_totalLoanAmount"
                    [disabled]="true" [editorMode]="editorMode" [allowDecimals]="false"
                    [placeholder]="'Total Loan Amount'">
                  </currency-input>
                </div>
              </div>
              <div class="row py-1" *ngIf="!isRefi">
                <div class="col-6">Down Payment</div>
                <div class="col-6">
                  <currency-input [(ngModel)]="downPayment" name="ls_downpaymentAmount" [editorMode]="editorMode"
                    [allowDecimals]="false" [placeholder]="'Down Payment Amount'" (blur)="onDownpaymentAmountChanged()">
                  </currency-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Amortization Type</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.mortgageTerm.amortizationType" [options]="amortizationTypes"
                    name="ls_amortizationType" [editorMode]="editorMode"
                    [placeholder]="'Amortization Type'"></select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Term (Months)</div>
                <div class="col-6">
                  <number-input [allowNegative]="false" [(ngModel)]="mortgage.mortgageTerm.noOfMonths"
                    name="ls_noOfMonths" [editorMode]="editorMode" 
                    (blur)="onMortgageTermChanged()"
                    [placeholder]="'Term (Months)'"></number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Mortgage Purpose</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.subjectProperty.purposeOfLoan" [options]="loanPurposeOptions"
                    name="ls_loanPurpose" [editorMode]="editorMode" [required]="true"
                    (blur)="onMortgagePurposeOfLoanChanged()" [placeholder]="'Loan Purpose'">
                  </select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Mortgage Type</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.mortgageTerm.mortgageAppliedFor"
                    [options]="mortgageAppliedForTypes" name="ls_mortgageAppliedFor" [editorMode]="editorMode"
                    (blur)="productTypeChanged()" [placeholder]="'Mortgage Type'"></select-input>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">Interest Rate</div>
                <div class="col-6">
                  <percent-input [(ngModel)]="mortgage.mortgageTerm.interestRate" [rateIsTrueToTextValue]="true"
                    [disabled]="application.productPricing.assignDate" name="ls_mortgageInterestRate"
                    [digitsInfo]="'1.3-3'" [editorMode]="editorMode" (blur)="onInterestRateChanged()"
                    [placeholder]="'Interest Rate'"></percent-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Qualifying Rate</div>
                <div class="col-6">
                  <percent-input [(ngModel)]="mortgage.ausData.armQualifyingRate" name="ls_armQualifyingRate"
                    [rateIsTrueToTextValue]="true" [editorMode]="editorMode"
                    [placeholder]="'Qualifying Rate'"></percent-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Interest Only (Months)</div>
                <div class="col-6">
                  <number-input [allowNegative]="false" [(ngModel)]="mortgage.extension.interestOnlyTermMonths"
                    name="ls_interestOnlyTermMonths" [editorMode]="editorMode"
                    [placeholder]="'Interest Only (Months)'"></number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Initial Fixed Period (Months)</div>
                <div class="col-6">
                  <number-input [allowNegative]="false" [(ngModel)]="mortgage.subjectProperty.constructionPeriodMonths"
                    name="ls_constructionPeriodMonths" [editorMode]="editorMode"
                    [placeholder]="'Initial Fixed Period (Months)'"></number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">ARM Margin</div>
                <div class="col-6">
                  <number-input [(ngModel)]="mortgage.ausData.armIndexMargin" name="ls_armIndexMargin"
                    [editorMode]="editorMode" [placeholder]="'ARM Margin'" [decimals]="'2'"></number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">ARM Index</div>
                <div class="col-6">
                  <number-input [(ngModel)]="mortgage.ausData.armIndexCurrent" name="ls_armIndexCurrent"
                    [editorMode]="editorMode" [placeholder]="'ARM Index'" [decimals]="'2'"></number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">MI Rate</div>
                <div class="col-6">
                  <percent-input [(ngModel)]="
                    mortgage.governmentLoanDetail.fhaMIRenewalRatePercent
                  " name="ls_miRate" [digitsInfo]="'1.3-3'" [editorMode]="editorMode"
                    [placeholder]="'MI Rate'"></percent-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Monthly MI</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="mortgage.proposedHousingExpense.mortgageInsurance"
                      class="fw-bold">{{mortgage.proposedHousingExpense.mortgageInsurance | currency}}</span>
                    <span *ngIf="!mortgage.proposedHousingExpense.mortgageInsurance">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Document Type</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.subjectProperty.refinanceProgram"
                    [options]="governmentRefinanceTypes" name="ls_governmentRefinanceTypes" [editorMode]="editorMode"
                    [placeholder]="'Document Type'"></select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Prepay Penalty</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.extension.isPenaltyChargedInEventOfPrepayment"
                    [options]="yesNoOptions" name="ls_isPenaltyChargedInEventOfPrepayment" [editorMode]="editorMode"
                    [placeholder]="'Prepay Penalty'"></select-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showPrequalDetailsSection" class="card" id="prequalParameters">
        <div class="card-header">
          <h4 class="card-title">
            <span><i class="fas fa-wrench me-2"></i> Prequal Parameters</span>
            <a href="javascript:void(0);" class="d-inline pull-right" style="color: #1761fd;"
              (click)="copyFromLoanDetails()">
              Copy from Loan Details
            </a>
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">Max Purchase Price</div>
                <div class="col-6">
                  <currency-input [(ngModel)]="prequalDetail.maxPurchasePrice" name="ls_maxPurchasePrice"
                    [editorMode]="editorMode" [placeholder]="'Max Purchase Price'"></currency-input>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">Min Down Payment %</div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-6">
                      <percent-input [(ngModel)]="prequalDetail.downPaymentPercent"
                        (ngModelChange)="onMinDownPaymentPercentChanged()" name="ls_downPaymentPercent"
                        [editorMode]="editorMode" [placeholder]="'%'"></percent-input>
                    </div>
                    <div class="col-6">
                      <currency-input [(ngModel)]="minDownPaymentDollar"
                        (ngModelChange)="onMinDownPaymentDollarChanged()" name="ls_downPaymentDollar"
                        [editorMode]="editorMode" [placeholder]="'$'"></currency-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">Loan Program Description</div>
                <div class="col-6">
                  <text-input [(ngModel)]="prequalDetail.loanProgramDescription" name="ls_loanProgramDescription"
                    [editorMode]="editorMode" [placeholder]="'Loan Program Description'"></text-input>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">Max Down Payment %</div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-6">
                      <percent-input [(ngModel)]="prequalDetail.maxDownPaymentPercent"
                        (ngModelChange)="onMaxDownPaymentPercentChanged()" name="ls_maxDownPaymentPercent"
                        [editorMode]="editorMode" [placeholder]="'%'"></percent-input>
                    </div>
                    <div class="col-6">
                      <currency-input [(ngModel)]="maxDownPaymentDollar"
                        (ngModelChange)="onMaxDownPaymentDollarChanged()" name="ls_maxDownPaymentDollar"
                        [editorMode]="editorMode" [placeholder]="'$'"></currency-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">Contingency Description</div>
                <div class="col-6">
                  <textarea-input [(ngModel)]="prequalDetail.contingencyDescription" name="ls_contingencyDescription"
                    [editorMode]="editorMode" [placeholder]="'Contingency Description'"></textarea-input>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">Interest Rate</div>
                <div class="col-6">
                  <percent-input [(ngModel)]="prequalDetail.interestRate" name="ls_interestRate"
                    [editorMode]="editorMode" [placeholder]="'Interest Rate'"></percent-input>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row py-1">
                <div class="col-3 col-md-3" style="padding-top:7px">DU/DO Approval<i placement="bottom"
                    class="fa fa-info-circle ms-1"
                    title="Select the DU Approval document that can optionally be included in the Pre-Qual letter generation."></i>
                </div>
                <div class="col-3">
                  <select-input *ngIf="!isLoadingAusDocs" [shiftInputToLeftWhenEditingInline]="true"
                    [(ngModel)]="duDocumentId" [options]="duDocFiles" name="duDocFileId"
                    (ngModelChange)="onDuDocFileIdChanged()" [editorMode]="editorMode" [groupingEnabled]="true"
                    [optionGroupByKey]="'groupName'" [optionValueKey]="'lsAusDocId'" [optionNameKey]="'lsAusDocName'"
                    [placeholder]="'DU Approval'"></select-input>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row py-1">
                <div class="col-3 col-md-3" style="padding-top:7px">LPA Approval<i placement="bottom"
                    class="fa fa-info-circle ms-1"
                    title="Select the LPA Approval document that can optionally be included in the Pre-Qual letter generation."></i>
                </div>
                <div class="col-3">
                  <select-input *ngIf="!isLoadingAusDocs" [shiftInputToLeftWhenEditingInline]="true"
                    [(ngModel)]="lpaDocumentId" [options]="lpaDocFiles" name="lpaDocFileId"
                    (ngModelChange)="onLpaDocFileIdChanged()" [editorMode]="editorMode" [groupingEnabled]="true"
                    [optionGroupByKey]="'groupName'" [optionValueKey]="'lsAusDocId'" [optionNameKey]="'lsAusDocName'"
                    [placeholder]="'LPA Approval'"></select-input>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row py-1">
                <div class="col-6 col-md-3" style="padding-top:7px">Proof Of Funds<i placement="bottom"
                    class="fa fa-info-circle ms-1"
                    title="Select the Proof Of Funds document that can optionally be included in the Pre-Qual letter generation."></i>
                </div>
                <div class="col-6">
                  <select-input *ngIf="!isLoadingAusDocs" [shiftInputToLeftWhenEditingInline]="true"
                    [(ngModel)]="prequalDetail.proofOfFundsDocFileId" [options]="proofOfFundsDocFiles"
                    name="proofOfFundsDocFileId" [editorMode]="editorMode" [optionValueKey]="'docFileId'"
                    [optionNameKey]="'lsLpaDocName'" [placeholder]="'Proof Of Funds'"></select-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="propertyInfo">
        <div class="col-md-6 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-header">
              <h4 class="card-title"><i class="fas fa-map-marked me-2"></i> Property Info</h4>
            </div>
            <div class="card-body">
              <div class="w-100 mt-2" style="height: 35vh !important" #mapElementContainer
                *ngIf="!isSubjectPropertyAddressHidden">
                <iframe *ngIf="mortgage.subjectProperty.address1" loading="lazy" allowfullscreen
                  referrerpolicy="no-referrer-when-downgrade" [src]="subjectPropertyAddressGoogleMapsUrl | safeUrl">
                </iframe>
                <div *ngIf="!mortgage.subjectProperty.address1"
                  class="h-100 w-100 bg-info d-flex align-items-center justify-content-center text-white fw-bold">
                  {{ 'No Address Selected' }}
                </div>
              </div>

              <div class="row pt-2" style="height: 50% !important;">
                <div class="col-md-6" *ngIf="!isSubjectPropertyAddressHidden">
                  <div class="row py-1 align-items-center">
                    <div class="col-5">Address</div>
                    <div class="col-7">
                      <address-input [shiftInputToLeftWhenEditingInline]="true"
                        [(ngModel)]="mortgage.subjectProperty.address1" name="ls_address" [editorMode]="editorMode"
                        (addressChanged)="onHandleAddressChange($event)" [placeholder]="'Address'">
                      </address-input>
                    </div>
                  </div>
                  <div class="row py-1 align-items-center">
                    <div class="col-5">City</div>
                    <div class="col-7">
                      <text-input [shiftInputToLeftWhenEditingInline]="true" [(ngModel)]="mortgage.subjectProperty.city"
                        name="ls_city" [editorMode]="editorMode" [placeholder]="'City'"></text-input>
                    </div>
                  </div>
                  <div class="row py-1 align-items-center">
                    <div class="col-5">State</div>
                    <div class="col-7">
                      <select-input [shiftInputToLeftWhenEditingInline]="true"
                        [(ngModel)]="mortgage.subjectProperty.state" [options]="states" name="ls_state"
                        [editorMode]="editorMode" [placeholder]="'State'"></select-input>
                    </div>
                  </div>
                  <div class="row py-1 align-items-center">
                    <div class="col-5">Zip</div>
                    <div class="col-7">
                      <zip-code-input [shiftInputToLeftWhenEditingInline]="true"
                        [(ngModel)]="mortgage.subjectProperty.zipCode" [name]="'ls_zipCode'" [editorMode]="editorMode"
                        (selectionChanged)="onZipCodeRelatedInfoChanged($event)" [inlinePlaceholder]="'Zip'">
                      </zip-code-input>
                    </div>
                  </div>
                  <div class="row py-1 align-items-center">
                    <div class="col-5">County</div>
                    <div class="col-7">
                      <text-input [shiftInputToLeftWhenEditingInline]="true"
                        [(ngModel)]="mortgage.subjectProperty.county" name="ls_county" [editorMode]="editorMode"
                        [placeholder]="'County'">
                      </text-input>
                    </div>
                  </div>
                </div>
                <div class="col-md-{{!isSubjectPropertyAddressHidden ? '6' : '12'}}">
                  <div class="row py-1 align-items-center">
                    <div class="col-5">Attachment</div>
                    <div class="col-7">
                      <select-input [shiftInputToLeftWhenEditingInline]="true"
                        [(ngModel)]="mortgage.subjectProperty.attachmentType" [options]="attachmentTypes"
                        name="ls_attachmentType" [editorMode]="editorMode"
                        [placeholder]="'Attachment Type'"></select-input>
                    </div>
                  </div>
                  <div class="row py-1 align-items-center">
                    <div class="col-5">Project Type</div>
                    <div class="col-7">
                      <select-input [shiftInputToLeftWhenEditingInline]="true"
                        [(ngModel)]="mortgage.subjectProperty.projectType" [options]="projectTypes"
                        name="ls_projectType" [editorMode]="editorMode" [placeholder]="'Project Type'"></select-input>
                    </div>
                  </div>
                  <div class="row py-1 align-items-center">
                    <div class="col-5">Occupancy</div>
                    <div class="col-7">
                      <select-input [shiftInputToLeftWhenEditingInline]="true"
                        [(ngModel)]="mortgage.subjectProperty.propertyWillBe" [options]="propertyOccupancyTypes"
                        name="ls_propertyOccupancy" [editorMode]="editorMode"
                        [placeholder]="'Occupancy'"></select-input>
                    </div>
                  </div>
                  <div class="row py-1 align-items-center">
                    <div class="col-5"># Of Units</div>
                    <div class="col-7">
                      <number-input [shiftInputToLeftWhenEditingInline]="true"
                        [(ngModel)]="mortgage.subjectProperty.noOfUnits" name="ls_noOfUnits" [editorMode]="editorMode"
                        [placeholder]="'Number Of Units'" [min]="1" [max]="10"></number-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-header">
              <h4 class="card-title"><i class="fas fa-hand-holding-usd me-2"></i> Housing
                Expenses</h4>
            </div>
            <div class="card-body">
              <div class="row" #housingExpenses>
                <table class="table table-hover table-borderless">
                  <thead>
                    <tr class="border-bottom">
                      <th class="text-secondary">Expense</th>
                      <th class="text-secondary" style="text-align: right" *ngIf="showCurrentLoanInfoSection">
                        Current
                      </th>
                      <th class="text-secondary" style="text-align: right">
                        Proposed
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Rent</td>
                      <td style="text-align: right" *ngIf="showCurrentLoanInfoSection">
                        <currency-input  [(ngModel)]="mortgage.currentHousingExpense.rent" class="d-flex justify-content-end"
                        name="ls_rentPrice" [editorMode]="editorMode" [placeholder]="'Rent'"></currency-input>
                      </td>
                      <td style="text-align: right">
                        <currency-input  [(ngModel)]="mortgage.proposedHousingExpense.rent" class="d-flex justify-content-end"
                        name="ls_phe_rentPrice" [editorMode]="editorMode" [placeholder]="'Rent'" [readonly]="isHomesiteHack"></currency-input>
                      </td>
                    </tr>
                    <tr>
                      <td>First Mortgage P&I</td>
                      <td style="text-align: right" *ngIf="showCurrentLoanInfoSection">
                        <currency-input  [(ngModel)]="mortgage.currentHousingExpense.firstMortgagePrincipalAndInterest" class="d-flex justify-content-end"
                        name="ls_firstMortgagePrincipalAndInterestPrice" [editorMode]="editorMode" [placeholder]="'First Mortgage P&I'"></currency-input>
                      </td>
                      <td style="text-align: right">
                        <currency-input  [(ngModel)]="mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest" class="d-flex justify-content-end"
                        name="ls_phe_firstMortgagePrincipalAndInterestPrice" [editorMode]="editorMode" [placeholder]="'First Mortgage P&I'" [readonly]="isHomesiteHack"></currency-input>
                      </td>
                    </tr>
                    <tr>
                      <td>Other Financing P&I</td>
                      <td style="text-align: right" *ngIf="showCurrentLoanInfoSection">
                        <currency-input  [(ngModel)]="mortgage.currentHousingExpense.otherMortgageLoanPrincipalAndInterest" class="d-flex justify-content-end"
                        name="ls_otherMortgageLoanPrincipalAndInterest" [editorMode]="editorMode" [placeholder]="'Other Financing P&I'"></currency-input>
                      </td>
                      <td style="text-align: right">
                        <currency-input  [(ngModel)]="mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest" class="d-flex justify-content-end"
                        name="ls_phe_otherMortgageLoanPrincipalAndInterest" [editorMode]="editorMode" [placeholder]="'Other Financing P&I'"></currency-input>
                      </td>
                    </tr>
                    <tr>
                      <td>Homeowners Insurance</td>
                      <td style="text-align: right" *ngIf="showCurrentLoanInfoSection">
                        <currency-input  [(ngModel)]="mortgage.currentHousingExpense.homeownersInsurance" class="d-flex justify-content-end"
                        name="ls_homeownersInsurance" [editorMode]="editorMode" [placeholder]="'Homeowners Insurance'"></currency-input>
                      </td>
                      <td style="text-align: right">
                        <currency-input  [(ngModel)]="mortgage.proposedHousingExpense.homeownersInsurance" class="d-flex justify-content-end"
                        name="ls_phe_homeownersInsurance" [editorMode]="editorMode" [placeholder]="'Homeowners Insurance'" [readonly]="isHomesiteHack"></currency-input>
                      </td>
                    </tr>
                    <tr>
                      <td>Supplemental Property Insurance</td>
                      <td style="text-align: right" *ngIf="showCurrentLoanInfoSection">
                        <currency-input  [(ngModel)]="mortgage.currentHousingExpense.supplementalPropertyInsurance" class="d-flex justify-content-end"
                        name="ls_supplementalPropertyInsurance" [editorMode]="editorMode" [placeholder]="'Supplemental Property Insurance'"></currency-input>
                      </td>
                      <td style="text-align: right">
                        <currency-input  [(ngModel)]="mortgage.proposedHousingExpense.supplementalPropertyInsurance" class="d-flex justify-content-end"
                        name="ls_phe_supplementalPropertyInsurance" [editorMode]="editorMode" [placeholder]="'Supplemental Property Insurance'" [readonly]="isHomesiteHack"></currency-input>
                      </td>
                    </tr>
                    <tr>
                      <td>Property Taxes</td>
                      <td style="text-align: right" *ngIf="showCurrentLoanInfoSection">
                        <currency-input  [(ngModel)]="mortgage.currentHousingExpense.realEstateTax" class="d-flex justify-content-end"
                        name="ls_realEstateTax" [editorMode]="editorMode" [placeholder]="'Real Estate Tax'"></currency-input>
                      </td>
                      <td style="text-align: right">
                        <currency-input  [(ngModel)]="mortgage.proposedHousingExpense.realEstateTax" class="d-flex justify-content-end"
                        name="ls_phe_realEstateTax" [editorMode]="editorMode" [placeholder]="'Real Estate Tax'" [readonly]="isHomesiteHack"></currency-input>
                      </td>
                    </tr>
                    <tr>
                      <td>Mortagage Insurance</td>
                      <td style="text-align: right" *ngIf="showCurrentLoanInfoSection">
                        <currency-input  [(ngModel)]="mortgage.currentHousingExpense.mortgageInsurance" class="d-flex justify-content-end"
                        name="ls_mortgageInsurance" [editorMode]="editorMode" [placeholder]="'Mortgage Insurance'"></currency-input>
                      </td>
                      <td style="text-align: right">
                        <currency-input  [(ngModel)]="mortgage.proposedHousingExpense.mortgageInsurance" class="d-flex justify-content-end"
                        name="ls_phe_mortgageInsurance" [editorMode]="editorMode" [placeholder]="'Mortgage Insurance'" [readonly]="isHomesiteHack"></currency-input>
                      </td>
                    </tr>
                    <tr>
                      <td>HOA Dues</td>
                      <td style="text-align: right" *ngIf="showCurrentLoanInfoSection">
                        <currency-input  [(ngModel)]="mortgage.currentHousingExpense.homeownersAssociationDuesAndCondominiumFees" class="d-flex justify-content-end"
                        name="ls_homeownersAssociationDuesAndCondominiumFees" [editorMode]="editorMode" [placeholder]="'HOA Dues'"></currency-input>
                      </td>
                      <td style="text-align: right">
                        <currency-input  [(ngModel)]="mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees" class="d-flex justify-content-end"
                        name="ls_phe_homeownersAssociationDuesAndCondominiumFees" [editorMode]="editorMode" [placeholder]="'HOA Dues'" [readonly]="isHomesiteHack"></currency-input>
                      </td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <td style="text-align: right" *ngIf="showCurrentLoanInfoSection">
                        <currency-input  [(ngModel)]="mortgage.currentHousingExpense.otherHousingExpense" class="d-flex justify-content-end"
                        name="ls_otherHousingExpense" [editorMode]="editorMode" [placeholder]="'Other Housing Expense'"></currency-input>
                      </td>
                      <td style="text-align: right">
                        <currency-input  [(ngModel)]="mortgage.proposedHousingExpense.otherHousingExpense" class="d-flex justify-content-end"
                        name="ls_phe_otherHousingExpense" [editorMode]="editorMode" [placeholder]="'Other Housing Expense'" [readonly]="isHomesiteHack"></currency-input>
                      </td>
                    </tr>
                    <tr style="font-size: larger">
                      <td style="font-weight: bold">Total</td>
                      <td style="text-align: right; font-weight: bold" *ngIf="showCurrentLoanInfoSection">
                        {{ (currentHousingExpenseTotal | currency) || '--' }}
                      </td>
                      <td style="text-align: right; font-weight: bold">
                        {{ (proposedHousingExpenseTotal | currency) || '--' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" id="totalQualifiedMonthlyIncome">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-hand-holding-usd me-2"></i> Total
            Qualified Monthly
            Income:
            <span class="text-success">{{
              (incomeTotal | currency) || '--'
              }}</span>
          </h4>
          <a class="link" (click)="showQMICardBody = !showQMICardBody">{{showQMICardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showQMICardBody">
          <div class="row">
            <div class="table-responsive">
              <table class="table  table-hover table-borderless">
                <thead>
                  <tr class="border-bottom">
                    <th style="width: 23%" class="text-secondary">Borrower</th>
                    <th class="text-secondary" style="width: 15%">Income Source</th>

                    <th colspan="2" class="text-secondary" style="text-align: right">
                      Monthly Income
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let income of incomes">
                    <td>{{ getBorrowerFullName(income.borrowerId, income.employmentId) }}</td>
                    <td>
                      {{ getWords(income.typeOfIncome) }}
                      <i class="fa fa-parking"></i>
                    </td>

                    <td colspan="2" style="text-align: right">
                      {{ (income.monthlyIncome | currency) || '--' }}
                    </td>
                  </tr>
                  <tr *ngIf="incomes.length == 0">
                    <td colspan="7" style="height: 50px; text-align: center">
                      No records found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card" id="totalMonthlyDebt">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-hand-holding-usd me-2"></i> Total
            Monthly Debt:
            <span class="text-warning">{{
              (liabilityTotal | currency) || '--'
              }}</span>
          </h4>
          <a class="link" (click)="showTMDCardBody = !showTMDCardBody">{{showTMDCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showTMDCardBody">
          <div class="row">
            <div class="table-responsive">
              <table class="table table-hover table-borderless">
                <thead>
                  <tr class="border-bottom">
                    <th class="text-secondary">Associated To</th>
                    <th class="text-secondary">Debt Type</th>
                    <th class="text-secondary">Creditor</th>
                    <th class="text-secondary" style="text-align: right">Payment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let liability of allIncludedLiabilities">
                    <td>{{ getPersonFullNames(liability.owningBorrowerIds) }}</td>
                    <td>
                      {{ getWords(liability.typeOfLiability) }}
                      <i class="fa fa-parking"></i>
                    </td>
                    <td>{{ liability.holderName }}</td>
                    <td style="text-align: right">
                      {{ (liability.monthlyPayment | currency) || '--' }}
                    </td>
                  </tr>
                  <tr *ngIf="allIncludedLiabilities.length == 0">
                    <td colspan="4" style="height: 50px; text-align: center">
                      No records found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card" id="totalAssets">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-hand-holding-usd me-2"></i> Total
            Assets:
            <span class="text-success">{{
              (assetTotal | currency) || '--'
              }}</span>
          </h4>
          <a class="link" (click)="showTACardBody = !showTACardBody">{{showTACardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showTACardBody">
          <div class="row">
            <div class="table-responsive">
              <table class="table table-hover table-borderless">
                <thead>
                  <tr class="border-bottom">
                    <th class="text-secondary">Associated To</th>
                    <th class="text-secondary">Account Type</th>
                    <th class="text-secondary">Bank / Institution</th>
                    <th class="text-secondary" style="text-align: right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let asset of mortgage.assets">
                    <td>{{ getPersonFullNames(asset.owningBorrowerIds) }}</td>
                    <td>{{ getWords(asset.assetType) }}</td>
                    <td>{{ getWords(asset.depository) }}</td>
                    <td style="text-align: right">
                      {{ (asset.cashMarketValue | currency) || '--' }}
                    </td>
                  </tr>
                  <tr *ngIf="mortgage.assets.length == 0">
                    <td colspan="4" style="height: 50px; text-align: center">
                      No records found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card" id="realEstateOwned">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-hand-holding-usd me-2"></i> Real
            Estate Owned:
            <span class="text-success">{{
              (reoTotal | currency) || '--'
              }}</span>
          </h4>
          <a class="link" (click)="showREOCardBody = !showREOCardBody">{{showREOCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showREOCardBody">
          <div class="row">
            <div class="table-responsive">
              <table class="table table-hover table-borderless">
                <thead>
                  <tr class="border-bottom">
                    <th class="text-secondary">Owned By</th>
                    <th class="text-secondary">Property Status</th>
                    <th class="text-secondary">Property Usage</th>
                    <th class="text-secondary" style="text-align: right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let reo of mortgage.realEstateOwned">
                    <td>{{ getPersonFullNames(reo.owningBorrowerIds) }}</td>
                    <td>{{ getWords(reo.dispositionStatus) }}</td>
                    <td>{{ getWords(reo.intendedUsageType) }}</td>
                    <td style="text-align: right">
                      {{ (reo.marketValue | currency) || '--' }}
                    </td>
                  </tr>
                  <tr *ngIf="mortgage.realEstateOwned.length == 0">
                    <td colspan="4" style="height: 50px; text-align: center">
                      No records found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card" id="purchaseCredits" *ngIf="showPrequalDetailsSection">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-hand-holding-usd me-2"></i>
            Purchase Credits:
            <span class="text-success">{{
              (purchaseCreditsTotal | currency) || '--'
              }}</span>
          </h4>
          <a class="link" (click)="showPCCardBody = !showPCCardBody">{{showPCCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showPCCardBody">
          <div class="row">
            <table class="table table-hover table-borderless">
              <thead>
                <tr class="border-bottom">
                  <th class="text-secondary">Account Type</th>
                  <th class="text-secondary" style="text-align: right">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let credit of mortgage.transactionDetail.purchaseCredits">
                  <td>{{ getWords(credit.purchaseCreditType) }}</td>
                  <td style="text-align: right">
                    {{ (credit?.purchaseCreditAmount | currency) || '--' }}
                  </td>
                </tr>
                <tr *ngIf="mortgage.transactionDetail?.purchaseCredits.length == 0">
                  <td colspan="4" style="height: 50px; text-align: center">
                    No records found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card" id="transactionDetails">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-hand-holding-usd me-2"></i>
            Transaction
            Details</h4>
          <a class="link" (click)="showTDCardBody = !showTDCardBody">{{showTDCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showTDCardBody">
          <div class="row">
            <table class="table table-hover table-borderless">
              <thead>
                <tr class="border-bottom">
                  <th class="text-secondary">Description</th>
                  <th class="text-secondary" style="text-align: right">Amount</th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <td>Purchase Price</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.salesContractPrice_A | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Alterations, Improvements, Repairs</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.improvementsRenovationsRepairs_B | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Land (if acquired separately)</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.landValue_C | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>For Refinance: Balance of Mortgage Loans on the Property to be paid off in the Transaction</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.totalPaidOffForRefinance_D | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Credit Cards and Other Debts Paid off</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.debtsPaidOff_E | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Borrower Closing Costs (including Prepaid and Initial Escrow Payments)</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.borrowerClosingCostsTotal_F | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Estimated Prepaid Items</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.estimatedPrepaidItems | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Estimated Escrow Items</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.estimatedEscrowItems | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Estimated Closing Costs</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.estimatedClosingCosts | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>PMI, MIP, Funding Fee</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.pmiMipFundingFee | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Discount (if borrower will pay)</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.discountPoints_G | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Total due from Borrower(s)</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.totalDueFromBorrowerAThroughG_H | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Loan Amount</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.loanAmount_I | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Loan Amount Excluding Financed Mortgage Insurance (or Mortgage Insurance Equivalent) *</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.loanAmountExcludingFinancedMip | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Financed Mortgage Insurance (or Mortgage Insurance Equivalent) Amount</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.financedMip | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Other New Mortgage Loans on the Property the Borrower(s) is Buying or Refinancing</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.otherNewMortgageLoansOnSubjectProperty_J | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Total mortgage loans (total of I and J)</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.totalMortgageLoansIThroughJ_K | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Seller Credits (if any)</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.sellerCredits_L | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Lender Credits (if any)</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.lenderCredits | currency) || '--' }}
                  </td>
                </tr>

                <tr *ngIf="isPurchase">
                  <td>Earnest Money Deposit (if any)</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.emd | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Other Credits</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.otherCredits_M | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Total credits</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.totalCreditsLThroughM_N | currency) || '--' }}
                  </td>
                </tr>

                <tr>
                  <td>Less total mortgage loans and total credits</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.totalMortgageLoansAndTotalCreditsNAndK | currency) || '--' }}
                  </td>
                </tr>

                <tr style="font-size: larger">
                  <td style="font-weight: bold">Cash from/to Borrower(s)</td>
                  <td style="text-align: right; font-weight: bold">
                    {{ (ftcDetails?.cashFromToBorrower | currency) || '--' }}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card" id="customInfo" *ngIf="companyId != 172 && companyId != 213">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-key me-2"></i> Custom Info</h4>
          <a class="link" (click)="showCDCardBody = !showCDCardBody">{{showCDCardBody
            ? 'Hide' : 'Show'}} Details</a>
        </div>
        <div class="card-body" *ngIf="showCDCardBody && customData">
          <div class="row">
            <custom-data-info 
              [application]="application" 
              [customData]="customData"
              [isInClassicEditMode]="editModeToggle" 
              [labelAlignment]="'left'">
            </custom-data-info>
          </div>
        </div>
      </div>
      <div class="row" id="channelAndBranchInfo">
        <div class="col-md-6 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-header">
              <h4 class="card-title"><i class="fas fa-building me-2"></i> {{enabledChannels.length ? 'Channel /' : ''}}
                Branch Info
              </h4>
            </div>
            <div class="card-body">
              <div class="row py-1" *ngIf="enabledChannels.length">
                <div class="col-6">Channel</div>
                <div class="col-6">
                  <select-input [(ngModel)]='channel' (ngModelChange)='onChannelChange()' [optionValueKey]="'name'"
                    [options]='enabledChannels' name='ls_channel' [editorMode]='editorMode'
                    [placeholder]="'Channel'"></select-input>
                </div>
              </div>
              <div class="row py-1"
                [hidden]="!application.channel || application.channel == 'Retail' || application.channel == 'Broker'">
                <div class="col-6">External Company</div>
                <div class="col-6">
                  <select-input [(ngModel)]="application.externalCompanyId" [optionValueKey]="'externalCompanyId'"
                    [optionNameKey]="'name'" [options]="externalCompanies" name="ls_externalCompany"
                    [editorMode]="editorMode" [placeholder]="'External Company'"
                    (blur)="onExternalCompanySelect(application.externalCompanyId)"></select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Branch</div>
                <div class="col-6">
                  <select-input-vscroll [(ngModel)]="application.branchId" [optionValueKey]="'branchId'"
                    [optionNameKey]="'branchName'" [options]="externalCompanyBranchesFiltered" name="ls_branch"
                    [editorMode]="editorMode" [placeholder]="'Branch'" class="branchEditor"></select-input-vscroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-stretch">
          <div class="card w-100">
            <div class="card-header">
              <h4 class="card-title"><i class="fas fa-user me-2"></i> Referral Info
                <button type="button" class="btn btn-outline-primary btn-sm pull-right"
                  (click)="onAddHomeInsuranceDialog()">
                  Add Referral
                </button>
              </h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="row py-1">
                    <div class="col-6">Referral Source</div>
                    <div class="col-6" *ngIf="agents">
                      <select-input-vscroll [(ngModel)]="application.referralSource" [optionValueKey]="'agentId'"
                        [optionNameKey]="'agentFullName'" [options]="agents" name="ls_referralSource"
                        [editorMode]="editorMode" [placeholder]="'Referral Source'"
                        (ngModelChange)="onReferralSourceChanged()"></select-input-vscroll>
                    </div>
                  </div>
                  <div class="row py-1">
                    <div class="col-6">Referred To</div>
                    <div class="col-6" *ngIf="agents">
                      <select-input-vscroll [(ngModel)]="application.referredTo" [optionValueKey]="'agentId'"
                        [optionNameKey]="'agentFullName'" [options]="agents" name="ls_referredTo"
                        [editorMode]="editorMode" [placeholder]="'Referred To'"></select-input-vscroll>
                    </div>
                  </div>
                  <div class="row py-1">
                    <div class="col-6">Inside Referral Source</div>
                    <div class="col-6">
                      <select-input [(ngModel)]="application.insideReferralSource" [optionValueKey]="'userCompanyGuid'"
                        [optionNameKey]="'userFullName'" [options]="users" name="ls_insideReferralSource"
                        [editorMode]="editorMode" [placeholder]="'Inside Referral Source'"></select-input>
                    </div>
                  </div>
                  <div class="row py-1">
                    <div class="col-6">Secondary Referral Source</div>
                    <div class="col-6" *ngIf="agents">
                      <select-input-vscroll [(ngModel)]="application.secondaryReferralSource"
                        (ngModelChange)="onSecondaryReferralSourceChanged()" [optionValueKey]="'agentId'"
                        [optionNameKey]="'agentFullName'" [options]="agents" name="ls_secondaryReferralSource"
                        [editorMode]="editorMode" [placeholder]="'Secondary Referral Source'"></select-input-vscroll>
                    </div>
                  </div>
                  <div class="row py-1">
                    <div class="col-6">Secondary Referral Company</div>
                    <div class="col-6">
                      <text-input [(ngModel)]="secondaryReferralCompanyName" name="ls_secReferralCompanyName"
                        [editorMode]="editorMode" [placeholder]="'Secondary Referral Company'" [disabled]="true">
                      </text-input>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row py-1">
                    <div class="col-6">Referral Company</div>
                    <div class="col-6">
                      <text-input [(ngModel)]="realtorCompanyName" name="ls_agentCompanyName" [editorMode]="editorMode"
                        [placeholder]="'Referral Company'" [disabled]="true"></text-input>
                    </div>
                  </div>
                  <div class="row py-1">
                    <div class="col-6">Lead Source</div>
                    <div class="col-6">
                      <text-input [(ngModel)]="application.leadSource" name="ls_leadSource" [editorMode]="editorMode"
                        [placeholder]="'Lead Source'"></text-input>
                    </div>
                  </div>
                  <div class="row py-1">
                    <div class="col-6">Lead Campaign</div>
                    <div class="col-6">
                      <select-input [(ngModel)]="application.leadCampaignId" [optionValueKey]="'leadCampaignId'"
                        [optionNameKey]="'name'" [options]="leadCampaigns" name="ls_leadCampaign"
                        [editorMode]="editorMode" [placeholder]="'Lead Campaign'"></select-input>
                    </div>
                  </div>
                  <div class="row py-1">
                    <div class="col-6">Lead Ref Id</div>
                    <div class="col-6">
                      <text-input [(ngModel)]="application.leadRefId" name="ls_leadRefId" [editorMode]="editorMode"
                        [placeholder]="'Lead Ref Id'"></text-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isUsedAsChildComponent" class="modal-footer w-100">
    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-secondary me-2" (click)="onCloseDrawer()">
        <i class="fa fa-close me-1"></i> Cancel
      </button>
      <button type="button" class="btn btn-primary" (click)="saveLoanInfo()">
        <span><i class="fa fa-save me-1"></i> Save</span>
      </button>
    </div>
  </div>
</ng-container>
<loading-indicator *ngIf="isLoadingLoanData"></loading-indicator>
