<form #lateChargeForm="ngForm" *ngIf="mortgageTerm" novalidate id="lateChargeForm" name="lateChargeForm" class="p-3">

  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="lateChargeType">Late Charge Type</label>
      <select class="form-select" name="lateChargeType" id="lateChargeType"
        [required]="urlaFieldsConfig['mortgage.mortgageTerm.lateChargeType']?.required == 'required' ||
          urlaFieldsConfig['mortgage.mortgageTerm.lateChargeType']?.required == 'requested'"
        [(ngModel)]="mortgageTerm.lateChargeType"
        (ngModelChange)="onLateChargeTypeChanged()" [disabled]="readonly">
        <option [ngValue]="null">--Select One--</option>
        <option [ngValue]="option.value" *ngFor="let option of lateChargeTypes">
          {{ option.name }}
        </option>
      </select>
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="verifiedBy">Late Charge Grace Period Days</label>
      <input class="form-control" numeric [allowNegative]="false" name="lateChargeGracePeriodDays"
        id="lateChargeGracePeriodDays" 
        [required]="urlaFieldsConfig['mortgage.mortgageTerm.lateChargeGracePeriodDays']?.required == 'required' ||
          urlaFieldsConfig['mortgage.mortgageTerm.lateChargeGracePeriodDays']?.required == 'requested'"
        [(ngModel)]="mortgageTerm.lateChargeGracePeriodDays" [readonly]="readonly"/>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="lateChargeRate">Late Charge Rate</label>
      <percent-input
        [digitsInfo]="'1.3-3'"
        id="lateChargeRate"
        name="lateChargeRate"
        [(ngModel)]="mortgageTerm.lateChargeRate"
        [disabled]="!isPercentBased"
        [readonly]="readonly"
        [required]="urlaFieldsConfig['mortgage.mortgageTerm.lateChargeRate']?.required == 'required' ||
          urlaFieldsConfig['mortgage.mortgageTerm.lateChargeRate']?.required == 'requested'"
    ></percent-input>
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="lateChargeAmount">Late Charge Amount</label>
      <currency-input
        id="lateChargeAmount"
        name="lateChargeAmount"
        [(ngModel)]="mortgageTerm.lateChargeAmount"
        [disabled]="isPercentBased"
        [readonly]="readonly"
        [required]="urlaFieldsConfig['mortgage.mortgageTerm.lateChargeAmount']?.required == 'required' ||
          urlaFieldsConfig['mortgage.mortgageTerm.lateChargeAmount']?.required == 'requested'"
    ></currency-input>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <label class="mb-2" for="lateChargeMinimumAmount">Late Charge Min. Amount</label>
      <currency-input
        id="lateChargeMinimumAmount"
        name="lateChargeMinimumAmount"
        [(ngModel)]="mortgageTerm.lateChargeMinimumAmount"
        [readonly]="readonly"
        [required]="urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMinimumAmount']?.required == 'required' ||
          urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMinimumAmount']?.required == 'requested'"
    ></currency-input>
    </div>
    <div class="col-md-6">
      <label class="mb-2" for="lateChargeMaximumAmount">Late Charge Max. Amount</label>
      <currency-input
        id="lateChargeMaximumAmount"
        name="lateChargeMaximumAmount"
        [(ngModel)]="mortgageTerm.lateChargeMaximumAmount"
        [readonly]="readonly"
        [required]="urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMaximumAmount']?.required == 'required' ||
          urlaFieldsConfig['mortgage.mortgageTerm.lateChargeMaximumAmount']?.required == 'requested'"
    ></currency-input>
    </div>
  </div>

  <div class="row mb-3" *ngIf="hasSaveCancelButtons && !readonly">
    <div class="col-md-12 text-end">
      <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
      <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
        Save
      </button>
    </div>
  </div>
</form>

