<div class='grid-layout has-border'>
  <div class='grid-header mb-1'>
    <div class='grid-header-left'>
      <div>5b. About Your Finances</div>
    </div>

    <div class='grid-header-right'></div>
  </div>

  <!-- F -->
  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text'
    >
      <span>F. Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?</span>
    </urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.coSignedLoan'
      [inEditMode]='inEditMode'
      [name]="'coSignedLoan' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.coSignedLoan")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.coSignedLoan'
      layout='horizontal'
    ></urla-radio-group>

    <add-explanation-button
      *ngIf='!isReadOnly && !inEditMode'
      (clickAdd)='onClickAddExplanation(DeclarationExplanationType.CoMakerEndorserOnNote)'
    ></add-explanation-button>
  </div>

  <!-- F Explanations -->
  <ng-container
    *ngTemplateOutlet='
      explanationsTemplate;
      context: { $implicit: DeclarationExplanationType.CoMakerEndorserOnNote }'
  ></ng-container>

  <!-- G -->
  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text'
    >
      <span>G. Are there any outstanding judgments against you?</span>
    </urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.haveJudgement'
      [inEditMode]='inEditMode'
      [name]="'haveJudgement' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.haveJudgement")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.haveJudgement'
      layout='horizontal'
    ></urla-radio-group>

    <add-explanation-button
      *ngIf='!isReadOnly && !inEditMode'
      (clickAdd)='onClickAddExplanation(DeclarationExplanationType.OutstandingJudgments)'
    ></add-explanation-button>
  </div>

  <!-- G Explanations -->
  <ng-container
    *ngTemplateOutlet='
      explanationsTemplate;
      context: { $implicit: DeclarationExplanationType.OutstandingJudgments }'
  ></ng-container>

  <!-- H -->
  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text'
    >
      <span>H. Are you currently delinquent or in default on a federal debt?</span>
    </urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.haveTaxDebt'
      [inEditMode]='inEditMode'
      [name]="'haveTaxDebt' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.haveTaxDebt")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.haveTaxDebt'
      layout='horizontal'
    ></urla-radio-group>

    <add-explanation-button
      *ngIf='!isReadOnly && !inEditMode'
      (clickAdd)='onClickAddExplanation(DeclarationExplanationType.DelinquencyOrDefault)'
    ></add-explanation-button>
  </div>

  <!-- H Explanations -->
  <ng-container
    *ngTemplateOutlet='
      explanationsTemplate;
      context: { $implicit: DeclarationExplanationType.DelinquencyOrDefault }'
  ></ng-container>

  <!-- I -->
  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text'
    >
      <span>I. Are you a party to a lawsuit in which you potentially have any personal financial liability?</span>
    </urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.haveLawsuit'
      [inEditMode]='inEditMode'
      [name]="'haveLawsuit' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.haveLawsuit")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.haveLawsuit'
      layout='horizontal'>
    </urla-radio-group>

    <add-explanation-button
      *ngIf='!isReadOnly && !inEditMode'
      (clickAdd)='onClickAddExplanation(DeclarationExplanationType.PartyToLawsuit)'
    ></add-explanation-button>
  </div>

  <!-- I Explanations -->
  <ng-container
    *ngTemplateOutlet='
      explanationsTemplate;
      context: { $implicit: DeclarationExplanationType.PartyToLawsuit }'
  ></ng-container>

  <!-- J -->
  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text'
    >
      <span>J. Have you conveyed title to any property in lieu of foreclosure in the past 7 years?</span>
    </urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.haveConveyedTitleInLieuOfForeclosure'
      [inEditMode]='inEditMode'
      [name]="'haveConveyedTitleInLieuOfForeclosure' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure'
      layout='horizontal'
    ></urla-radio-group>

    <add-explanation-button
      *ngIf='!isReadOnly && !inEditMode'
      (clickAdd)='onClickAddExplanation(DeclarationExplanationType.ObligatedOnLoanForeclosedOrDeedInLieuOfJudgment)'
    ></add-explanation-button>
  </div>

  <!-- J Explanations -->
  <ng-container
    *ngTemplateOutlet='
      explanationsTemplate;
      context: { $implicit: DeclarationExplanationType.ObligatedOnLoanForeclosedOrDeedInLieuOfJudgment }'
  ></ng-container>

  <!-- K -->
  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text'
    >
      <span>K. Within the past 7 years, have you completed a pre-foreclosure sale or short sale?</span>
    </urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.haveCompletedShortSale'
      [inEditMode]='inEditMode'
      [name]="'haveCompletedShortSale' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.haveCompletedShortSale")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.haveCompletedShortSale'
      layout='horizontal'
    ></urla-radio-group>

    <add-explanation-button
      *ngIf='!isReadOnly && !inEditMode'
      (clickAdd)='onClickAddExplanation(DeclarationExplanationType.Other)'
    ></add-explanation-button>
  </div>

  <!-- K Explanations -->
  <ng-container
    *ngTemplateOutlet='
      explanationsTemplate;
      context: { $implicit: DeclarationExplanationType.Other }'
  ></ng-container>

  <!-- L -->
  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text'
    >
      <span>L. Have you had property foreclosed upon in the last 7 years?</span>
    </urla-grid-cell>

    <urla-radio-group
      [(ngModel)]='borrower.declarations.beenForclosed'
      [inEditMode]='inEditMode'
      [name]="'beenForclosed' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.beenForclosed")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.beenForclosed'
      layout='horizontal'
    ></urla-radio-group>

    <add-explanation-button
      *ngIf='!isReadOnly && !inEditMode'
      (clickAdd)='onClickAddExplanation(DeclarationExplanationType.DirectIndirectForeclosedPropertyPastSevenYears)'
    ></add-explanation-button>
  </div>

  <!-- L Explanations -->
  <ng-container
    *ngTemplateOutlet='
      explanationsTemplate;
      context: { $implicit: DeclarationExplanationType.DirectIndirectForeclosedPropertyPastSevenYears }'
  ></ng-container>

  <!-- M -->
  <div class='grid-layout-row px-2 mb-2'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text'
    >
      <span>M. Have you declared bankruptcy within the past 7 years?</span>
    </urla-grid-cell>

    <urla-radio-group
      (change)='bankruptcyTypeChange()'
      [(ngModel)]='borrower.declarations.declaredBankruptcy'
      [inEditMode]='inEditMode'
      [name]="'declaredBankruptcy' + borrower.borrowerId"
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='yesNoOptions'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.declaredBankruptcy")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.declaredBankruptcy'
      layout='horizontal'
    ></urla-radio-group>

    <add-explanation-button
      *ngIf='!isReadOnly && !inEditMode'
      (clickAdd)='onClickAddExplanation(DeclarationExplanationType.DeclaredBankruptcyPastSevenYears)'
    ></add-explanation-button>
  </div>

  <!-- M Explanations -->
  <ng-container
    *ngTemplateOutlet='
      explanationsTemplate;
      context: { $implicit: DeclarationExplanationType.DeclaredBankruptcyPastSevenYears }'
  ></ng-container>

  <div class='grid-layout-row px-2 mb-2' *ngIf='borrower.declarations.declaredBankruptcy || inEditMode'>
    <urla-grid-cell
      [disabled]='isReadOnly'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      class='grid-layout-text'
    >
      <span>'M.1 If Yes, identify the type(s) of bankruptcy</span>
    </urla-grid-cell>

    <urla-dropdown
      [(ngModel)]='borrower.declarations.bankruptcyType'
      [inEditMode]='inEditMode'
      [ngClass]="{'bg-transparent': !isReadOnly}"
      [options]='bankruptcyType'
      [readonly]='isReadOnly'
      [required]='isRequired("mortgage.borrower.declarations.bankruptcyType")'
      [urlaFieldsConfig]='urlaFieldsConfig'
      fieldBeingEdited='mortgage.borrower.declarations.bankruptcyType'
      name='bankruptcyType'
    ></urla-dropdown>

    <div class='row-spacer' *ngIf='!isReadOnly && !inEditMode'></div>
  </div>
</div>

<ng-template let-explanationType #explanationsTemplate>
  <ng-container *ngIf='getExplanationGroup(explanationType) as explanationGroup'>
    <div
      *ngFor='let explanation of explanationGroup; let i = index; let first = first; let last = last'
      [class.mb-2]='last'
      [style.margin-top.rem]='first ? -0.5 : null'
      class='grid-layout-row'
    >
      <declaration-explanation-editor
        [explanation]='explanation'
        [explanationType]='explanationType'
        [urlaFieldsConfig]='urlaFieldsConfig'
        (delete)='onClickDeleteExplanation(explanation, i)'
      ></declaration-explanation-editor>
    </div>
  </ng-container>
</ng-template>
