import { UrlaMortgage } from "src/app/modules/urla/models/urla-mortgage.model";
import { CustomData, DataExchangeSyncDir, LoanApplication } from "../loan";
import { BorrowerDto } from "src/app/modules/contacts/models/borrower-dto.model";

export class LdeAppOpreationResult {
  readonly ldeRefNumber: string;
  readonly ldeIdentifier: string;
  readonly ldeVendor: string;
  readonly ldeSyncDir: DataExchangeSyncDir;
  readonly ldeInstanceId: string;
  readonly application: LoanApplication;
  readonly mortgage: UrlaMortgage;
  readonly loanBorrowers: BorrowerDto[];
  readonly customData: CustomData;
}

export interface LdeEvent {
  readonly applicationId: number
  readonly companyId: number
  readonly dateInserted: string
  readonly dateUpdated: string
  readonly description: string
  readonly entityId: number
  readonly entityKey: string
  readonly eventDirection: string
  readonly eventOperation: string
  readonly eventSource: string
  readonly eventType: string
  readonly insertedBy: string
  readonly ldeEntityId: string
  readonly ldeEventId: number
  readonly ldeIdentifier: string
  readonly ldeVendor: string
  readonly mortgageId: number
  readonly status: string
  readonly subStatus: string
  readonly updatedBy: string
}

export class LdeTransactionHistory {
  readonly applicationTime: string = ""
  readonly ldeSyncDir: string = ""
  readonly ldeLoanTime: string = ""
  readonly events: Array<LdeEvent> = []
}