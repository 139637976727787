import { createExplanation, ExplanationGroups, groupExplanations } from './declarations-utils';
import { DeclarationExplanation, DeclarationExplanationType, Declarations } from '../../../models';
import Swal from 'sweetalert2';
import { NotificationService } from '../../../services/notification.service';

/**
 * Manages the explanation groups for the declarations.
 */
export class ExplanationGroupManager {
  private readonly _notificationService: NotificationService;
  private readonly _declaration: Declarations;

  private _explanationGroups: ExplanationGroups;

  constructor(
    notificationService: NotificationService,
    declaration: Declarations,
  ) {
    this._notificationService = notificationService;
    this._declaration = declaration;

    this.resetExplanationGroups();
  }

  private resetExplanationGroups(
    explanations?: readonly DeclarationExplanation[],
  ): void {
    explanations ??= this._declaration.explanations;
    explanations = explanations || [];
    this._explanationGroups = groupExplanations(explanations);
  }

  public getExplanationGroup(
    explanationType: DeclarationExplanationType,
  ): readonly DeclarationExplanation[] {
    return this._explanationGroups[explanationType];
  }

  public addExplanation(
    explanationType: DeclarationExplanationType,
  ): void {
    const explanation = createExplanation(explanationType);
    this._declaration.explanations.push(explanation);
    this.resetExplanationGroups();
  }

  /**
   * Deletes the explanation with confirmation.
   * @param {DeclarationExplanation} explanation The explanation to delete.
   * @param {number} indexInGroup The index of the explanation in its group.
   */
  public async deleteExplanationWithConfirmation(
    explanation: DeclarationExplanation,
    indexInGroup: number,
  ): Promise<void> {
    // Delete without confirmation if the explanation is empty.
    const isEmpty = !explanation.description;
    if (!isEmpty && !(await this.showDeleteExplanationConfirmation())) {
      return;
    }

    const index = this.findExplanationIndex(
      explanation.explanationType,
      indexInGroup,
    );

    if (index < 0) {
      this._notificationService.showError(
        'The explanation could not be found.',
        'Error',
      );
      return;
    }

    const explanations = this._declaration.explanations;
    explanations.splice(index, 1);
    this.resetExplanationGroups(explanations);
  }

  /**
   * Shows the delete explanation confirmation.
   * @returns {Promise<boolean>} A promise that resolves to true if the
   * explanation should be deleted.
   */
  private async showDeleteExplanationConfirmation(): Promise<boolean> {
    // "Cancel" is used as "confirm" here because the colors are more suitable.
    const answer = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this explanation?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Cancel',
      cancelButtonText: 'Delete',
      focusCancel: true, // "Confirm", actually.
    });

    // "Cancel" actually means "confirm" here.
    return answer?.dismiss === Swal.DismissReason.cancel;
  }

  /**
   * Finds "indexInGroup" th explanation of the same type to find the index in
   * the main array.
   * @param {DeclarationExplanationType} explanationType The type of the
   * explanation to find.
   * @param {number} indexInGroup The index of the explanation in its group.
   * @returns {number} The index of the explanation in the main array.
   */
  private findExplanationIndex(
    explanationType: DeclarationExplanationType,
    indexInGroup: number,
  ): number {
    const explanations = this._declaration.explanations;
    let count = 0;
    for (let i = 0; i < explanations.length; i++) {
      const item = explanations[i];
      if (item.explanationType === explanationType) {
        if (count === indexInGroup) {
          return i;
        }
        count++;
      }
    }
    return -1;
  }
}
