import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Address, EmploymentTypeEnum, Income, TypeOfIncomeEnum } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { IncomeDialogComponent } from 'src/app/modules/urla/borrower-information/employment-income/income-dialog/income-dialog.component';
import { UrlaEmployment } from 'src/app/modules/urla/models/urla-mortgage.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import Swal from 'sweetalert2';

@Component({
  selector: 'employment-income-in-detail',
  templateUrl: 'employment-income-in-detail.component.html'
})
export class EmploymentIncomeInDetailComponent implements OnInit {

  @Output()
  incomesChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set employments(employments: UrlaEmployment[]) {
    if (!employments) {
      return;
    }
    this._employments = employments;
    this._employments.forEach(e => {
      this.calculateTotalIncomeForEmployment(e);
    });
    this.calculateTotalEmploymentBasedIncome();
  }

  get employments(): UrlaEmployment[] {
    return this._employments;
  }

  totalMonthlyIncome: number;

  ownershipShareTypes: EnumerationItem[] = [];

  editingIx: number = -1;

  isSelfEmployed: boolean = false;

  yesNoOptions: EnumerationItem[] = [];

  totalSelfEmploymentMonthlyIncomeOrLoss: number = 0;

  private _employments: UrlaEmployment[] = [];

  private _employmentIncomeTypeValues: string[] = [];

  private _employmentIncomeTypes: EnumerationItem[] = [];

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _modalService: NgbModal) {
    this.yesNoOptions = this._enumsService.getYesNoEnumItems();
    this.generateEmploymentIncomeTypeValuesList();
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.prepareEmploymentIncomeTypesList(enums);
      this.ownershipShareTypes = enums[Constants.enumerations.employmentOwnershipShare];
    });
  }

  ngOnInit() {
    this.checkSelfEmployed();
  }

  selfEmployedChanged = (isSelfEmployed: boolean) => {
    this.isSelfEmployed = isSelfEmployed;
    this.checkSelfEmployed();
    this.incomesChanged.emit();
  }

  addEmployment = () => {
    let employment = new UrlaEmployment();
    employment.address = new Address();
    employment.employmentType = EmploymentTypeEnum.CurrentEmployer;
    this.employments.push(employment);
  }

  selfEmploymentMonthlyIncomeOrLossChanged = () => {
    this.calculateTotalSelfEmploymentMonthlyIncomeOrLoss();
    this.incomesChanged.emit();
  }

  onCalculatorClicked = (employment: UrlaEmployment) => {
    const modalRef = this._modalService.open(IncomeDialogComponent, Constants.modalOptions.large);

    const editableIncomes = (employment && employment.incomes) ? employment.incomes.filter(i => this.filterEmploymentIncomes(i.typeOfIncome)) : [];
    modalRef.componentInstance.incomes = editableIncomes;
    modalRef.componentInstance.isFromIncomeAnalysis = true;
    modalRef.componentInstance.incomeTypes = this._employmentIncomeTypes;
    modalRef.componentInstance.title = "Total Gross Income";
    modalRef.result.then((result) => {
      if (result !== 'cancel') {
        this.consolidateIncomes(result, employment);
        this.calculateTotalIncomeForEmployment(employment);
        this.calculateTotalEmploymentBasedIncome();
        this.incomesChanged.emit();
      }
    }, (err) => {
    });
  }

  deleteEmploymentClicked = (deleteIx: number, employment: UrlaEmployment) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        if (deleteIx === self.editingIx) {
          self.editingIx = -1;
        }
        self.employments.splice(deleteIx, 1);
        self.calculateTotalIncomeForEmployment(employment);
        self.incomesChanged.emit();
      }
    });
  }

  private filterEmploymentIncomes = (incomeType: TypeOfIncomeEnum): boolean => {
    return incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Base) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Bonus) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Commissions) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Overtime) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryCombatPay) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryFlightPay) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryHazardPay) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryOverseasPay) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryPropPay) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryClothesAllowance) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryRationsAllowance) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryVariableHousingAllowance) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryQuartersAllowance) ||
      incomeType == this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.OtherTypesOfIncome);
  }

  private generateEmploymentIncomeTypeValuesList = () => {
    this._employmentIncomeTypeValues = [
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Base),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Bonus),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Commissions),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.Overtime),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryCombatPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryFlightPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryHazardPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryOverseasPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryPropPay),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryClothesAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryRationsAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryVariableHousingAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.MilitaryQuartersAllowance),
      this._enumsService.getEnumValue(Constants.enumerationValueNames.IncomeType.OtherTypesOfIncome)
    ]
  }

  private prepareEmploymentIncomeTypesList = (enums: any) => {
    const incomeTypes = enums[Constants.enumerations.incomeType];
    this._employmentIncomeTypeValues.forEach(type => {
      const incomeType = incomeTypes.find(t => t.value === type);
      if (incomeType) {
        this._employmentIncomeTypes.push(incomeType);
      }
    })
  }

  private calculateTotalIncomeForEmployment = (employment: UrlaEmployment) => {
    employment.calculatedStats.monthlyIncome = 0 + employment.selfEmploymentMonthlyIncomeOrLoss;
    const incomes = employment.incomes.filter(i => this.filterEmploymentIncomes(i.typeOfIncome));
    if (incomes.length > 0) {
      employment.calculatedStats.monthlyIncome = incomes.map(a => a.monthlyIncome).reduce(function (a, b) {
        if (!a) a = 0;
        if (!b) b = 0;
        return a + b;
      });
    }
  }

  private calculateTotalEmploymentBasedIncome = () => {
    this.totalMonthlyIncome = 0;
    this._employments.forEach(e => {
      this.totalMonthlyIncome += e.calculatedStats.monthlyIncome;
    })
  }

  private consolidateIncomes = (editedIncomes: Income[], employment: UrlaEmployment) => {
    editedIncomes.forEach(income => {
      // Add the ones that are not in the employment
      const existingOne = employment.incomes.find(i => i.incomeId == income.incomeId);
      if (!existingOne) {
        employment.incomes.push(income);
      } else {
        // Swap the ones that exist on both - hence, the edited ones
        const index = employment.incomes.indexOf(existingOne);
        if (index >= 0) {
          employment.incomes[index] = income;
        }
      }
    });
    // Remove the ones that exist on the employment, but not in the local array - hence, the deleted ones
    for (let ix: number = employment.incomes.length - 1; ix >= 0; ix--) {
      const income = employment.incomes[ix];
      const existOnLocalArray = editedIncomes.find(i => i.incomeId == income.incomeId);
      if (!existOnLocalArray) {
        employment.incomes.splice(ix, 1);
      }
    }
  }

  private checkSelfEmployed = () => {
    if (this.employments) {
      this.isSelfEmployed = this.employments.some(employment => employment.selfEmployed);
      if (this.isSelfEmployed) {
        this.calculateTotalSelfEmploymentMonthlyIncomeOrLoss();
      } else {
        this.totalSelfEmploymentMonthlyIncomeOrLoss = 0
      }
    }
  }

  private calculateTotalSelfEmploymentMonthlyIncomeOrLoss = () => {
    this.totalSelfEmploymentMonthlyIncomeOrLoss = 0;
    this.employments.forEach(employment => {
      if (employment.selfEmployed) {
        this.totalSelfEmploymentMonthlyIncomeOrLoss += employment.selfEmploymentMonthlyIncomeOrLoss || 0;
      }
    });
  }
}
