<form #prepaymentPenaltyForm="ngForm" *ngIf="!!details" novalidate id="prepaymentPenaltyForm"
  name="prepaymentPenaltyForm" class="p-3">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" name="isPrepaymentFinanceChargeRefundable" autocomplete="off"
          id="isPrepaymentFinanceChargeRefundable" [(ngModel)]="details.isPrepaymentFinanceChargeRefundable" [readonly]="readonly">
        <label class="form-check-label" [for]="readonly ? '' : 'isPrepaymentFinanceChargeRefundable'">
          Is Prepayment Finance Charge Refundable?
        </label>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-12 d-flex align-items-center">
      <label class="col-md-6" for="prepaymentPenaltyPrincipalBalanceType">Penalty Based On</label>
      <div class="col-md-6">
        <select class="form-select" name="prepaymentPenaltyPrincipalBalanceType"
          id="prepaymentPenaltyPrincipalBalanceType" [required]="urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyPrincipalBalanceType']?.required == 'required' ||
          urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyPrincipalBalanceType']?.required == 'requested'"
          [(ngModel)]="details.prepaymentPenaltyPrincipalBalanceType" #balanceType="ngModel"
          [ngClass]="{'is-invalid' : balanceType && balanceType.touched && balanceType.invalid}" [disabled]="readonly">
          <option [ngValue]="null">--Select One--</option>
          <option [ngValue]="option.value" *ngFor="let option of principalBalanceTypes">
            {{ option.name }}
          </option>
        </select>
        <div class="invalid-feedback">This field is required.</div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-12 d-flex align-items-center">
      <label class="col-md-6" for="prepaymentPenaltyMaximumAmount">Prepay Penalty Fee</label>
      <div class="col-md-6">
        <currency-input 
          id="prepaymentPenaltyMaximumAmount" 
          name="prepaymentPenaltyMaximumAmount"
          [(ngModel)]="details.prepaymentPenaltyMaximumAmount" 
          [disabled]="disabled" 
          [required]="urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyMaximumAmount']?.required == 'required' ||
            urlaFieldsConfig['prepaymentPenaltyDetail.prepaymentPenaltyMaximumAmount']?.required == 'requested'" 
          [readonly]="readonly">
        </currency-input>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-12 d-flex align-items-center">
      <label class="col-md-6" for="hardPrepaymentPeriod">Hard Prepayment Period</label>
      <div class="col-md-6">
        <input class="form-control" numeric [allowNegative]="false" name="hardPrepaymentPeriod"
          id="hardPrepaymentPeriod" [(ngModel)]="hardPrepaymentPeriod" [readonly]="true" />
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-12 d-flex align-items-center">
      <label class="col-md-6" for="prepaymentPenaltyExpirationMonthsCount">Prepayment Penalty Period</label>
      <div class="col-md-6">
        <input class="form-control" numeric [allowNegative]="false" name="prepaymentPenaltyExpirationMonthsCount"
          id="prepaymentPenaltyExpirationMonthsCount" [(ngModel)]="details.prepaymentPenaltyExpirationMonthsCount"
          [readonly]="true" />
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-12">
      <button class="btn btn-primary mb-2" (click)="addItem()" *ngIf="!readonly">
        Add
      </button>

      <div class="table-responsive">
        <table class="table mb-0 table-centered">
          <thead>
            <tr>
              <th style="width: 200px">Type</th>
              <th style="width: 100px">Period</th>
              <th style="width: 100px">Percent</th>
              <th style="text-align: center"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of details.penaltyItems;let i = index">
              <td>
                <select class="form-select" name="penaltyItems{{ i }}_optionType"
                  [(ngModel)]="item.prepaymentPenaltyOption" (ngModelChange)="calculateAmounts()" [required]="true" #optionType="ngModel"
                  [ngClass]="{'is-invalid' : optionType && optionType.touched && optionType.invalid}" [disabled]="readonly">
                  <option [ngValue]="null">-- Select One --</option>
                  <option *ngFor="let type of optionTypes" [ngValue]="type.value">
                    {{ type.name }}
                  </option>
                </select>
                <div class="invalid-feedback">This field is required.</div>
              </td>
              <td>
                <input class="form-control" numeric [allowNegative]="false" [required]="true" name="penaltyItems{{ i }}_termMonths"
                  id="penaltyItems{{ i }}_termMonths" [(ngModel)]="item.prepaymentPenaltyTermMonths"
                  (ngModelChange)="calculateAmounts()" #termMonths="ngModel"
                  [ngClass]="{'is-invalid' : termMonths && termMonths.touched && termMonths.invalid}" [readonly]="readonly"/>
                <div class="invalid-feedback">This field is required.</div>
              </td>
              <td>
                <percent-input [digitsInfo]="'1.3-3'" id="penaltyItems{{ i }}_percent"
                  name="penaltyItems{{ i }}_percent" [(ngModel)]="item.prepaymentPenaltyPercent"
                  (ngModelChange)="calculateAmounts()" [readonly]="readonly"></percent-input>
              </td>
              <td>
                <i *ngIf="!readonly" class="fa fa-trash-alt cursor text-danger" (click)="deleteItem(i)" title="Delete"></i>
              </td>
            </tr>
            <tr *ngIf="!details.penaltyItems.length">
              <td class="text-center" colspan="4">There are no items</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row mb-3" *ngIf="hasSaveCancelButtons && !readonly">
    <div class="col-md-12 text-end">
      <button class="btn btn-danger" (click)="cancelled.emit()">Cancel</button>
      <button class="btn btn-primary ms-2" (click)="onSaveClicked()">
        Save
      </button>
    </div>
  </div>
</form>
