import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { createIsRequiredFunction } from '../../urla-utils';
import { BuydownDetail, BuydownScheduleItem } from 'src/app/models';
import { cloneDeep } from 'lodash';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'app-buydown-details',
  templateUrl: './buydown-details.component.html',
  styleUrls: ['./buydown-details.component.scss']
})
export class BuydownDetailsComponent implements OnInit {

  @Input()
  set buydownDetail(buydownDetail: BuydownDetail) {
    this._buydownDetail = cloneDeep(buydownDetail);
  }

  get buydownDetail(): BuydownDetail {
    return this._buydownDetail;
  }

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  hasSaveCancelButtons: boolean = true;

  @Input()
  readonly: boolean = false;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApproved: EventEmitter<BuydownDetail> = new EventEmitter<BuydownDetail>();

  protected isRequired: (fieldName: string) => boolean;

  protected buydownCalculationTypes: EnumerationItem[] = [];

  private _buydownDetail: BuydownDetail;

  constructor(private readonly _enumerationService: EnumerationService) {
    this._enumerationService.getMortgageEnumerations().subscribe(enums => {
      this.buydownCalculationTypes = enums[Constants.mortgageEnumerations.buydownCalculationType]
    })
  }

  ngOnInit(): void {
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);
  }

  onSaveClicked = () => {
    this.changesApproved.emit(this._buydownDetail);
  }

  addBuydownSchedule = () => {
    let buydownScheduleItem = new BuydownScheduleItem();
    if (!this._buydownDetail.buydownSchedule) {
      this._buydownDetail.buydownSchedule = [];
    }
    this._buydownDetail.buydownSchedule.push(buydownScheduleItem);
  }

  removeBuydownSchedule = (index: number) => {
    this.buydownDetail.buydownSchedule.splice(index, 1);
    this.buydownDetail.buydownSchedule = [...this.buydownDetail.buydownSchedule];
  }
}
