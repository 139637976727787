import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { AutoCompleteItem } from 'src/app/models/expressions/auto-complete-item.model';
import { ExpressionConfig } from 'src/app/models/expressions/expression-config.model';
import { Operator } from 'src/app/models/expressions/operator.model';
import { EnumDropdownInfo } from 'src/app/models/loan-pass/enum-dropdown-info.model';
import { EnumType } from 'src/app/models/loan-pass/enum-type.model';
import { LoanPassEnumMapping } from 'src/app/models/loan-pass/loan-pass-enum-mapping.model';
import { LoanPassField } from 'src/app/models/loan-pass/loan-pass-field.model';
import { LoanPassMapping } from 'src/app/models/loan-pass/loan-pass-mapping.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BasicPropertyDetail } from 'src/app/modules/pricing/models/pricing/basic-property-detail.model';
import { Constants } from 'src/app/services/constants';
import { AlertComponent } from 'src/app/shared/components/alert/alert.component';
import { ExpressionEditorDialogComponent } from 'src/app/shared/components/expressions/expression-editor-dialog/expression-editor-dialog.component';

@Component({
  selector: 'loan-pass-enum-mapping-dialog',
  templateUrl: 'loan-pass-enum-mapping-dialog.component.html',
  styleUrls: ['loan-pass-enum-mapping-dialog.component.scss']
})

export class LoanPassEnumMappingDialogComponent implements OnInit {

  @ViewChild("alert") alert: AlertComponent | undefined;

  isEnumValuesLoading: boolean = false;

  loanPassEnumerationThatIsBeingMapped: EnumType;
  loanPassEnumerations: EnumType[] = [];

  loanPassField: LoanPassField;
  loanPassFields: LoanPassField[] = [];

  operators: Operator[];

  lodaFields: BasicPropertyDetail[] = [];

  isMappingFromLoanPassToLoda: boolean = false;

  lodaFieldEnumItems: EnumerationItem[] = [];
  lodaEnumerationThatIsBeingMapped: EnumDropdownInfo;

  idOfEnumSelectedAsDefault: number = -1;

  lodaEnumPricingFields: Array<BasicPropertyDetail | EnumDropdownInfo>;
  lodaField: BasicPropertyDetail;

  set loanPassFieldMapping(fieldMapping: LoanPassMapping) {
    this._fieldMapping = _.cloneDeep(fieldMapping);

    if (this.isMappingFromLoanPassToLoda) {
      this.populateEnumMappingsForLoanPassToLoda();
      this.onLoanPassEnumSelected();
    } else {
      this.populateEnumMappingsForLodaToLoanPass();
      this.onLodaEnumSelected();
    }
  }

  get loanPassFieldMapping(): LoanPassMapping {
    return this._fieldMapping;
  }

  get fieldMapping(): LoanPassMapping {
    return this._fieldMapping;
  }

  get isDestinationEnumSelected() {
    if (!this.isMappingFromLoanPassToLoda) {
      return !!this.lodaEnumerationThatIsBeingMapped;
    } else {
      return !!this.loanPassEnumerationThatIsBeingMapped;
    }
  }

  private _fieldMapping: LoanPassMapping;

  constructor(public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,

  ) {
  }

  ngOnInit() { }

  onOkClicked() {
    this._fieldMapping.enumMappings.forEach(enumMapping => {
      enumMapping.useAsDefault = false;
    });
    const defaultEnumMapping = this._fieldMapping.enumMappings.find(m => m.loanPassEnumMappingId == this.idOfEnumSelectedAsDefault);
    if (defaultEnumMapping) {
      defaultEnumMapping.useAsDefault = true;
    }

    this.activeModal.close(this._fieldMapping);
  }

  onLoanPassEnumSelected = () => {
    if (this.fieldMapping.loanPassEnumTypeId) {
      const selectedEnum = this.loanPassEnumerations.find(f => f.id == this.fieldMapping.loanPassEnumTypeId);
      this.fieldMapping.loanPassEnumTypeName = selectedEnum.name;
      this.loanPassEnumerationThatIsBeingMapped = selectedEnum;
    }
    else {
      this.loanPassEnumerationThatIsBeingMapped = null;
    }
  }

  onLodaEnumSelected = () => {
    if (this.fieldMapping.lodasoftEnumFullName) {
      const selectedEnum = (this.lodaEnumPricingFields as EnumDropdownInfo[]).find(f => f.fullName == this.fieldMapping.lodasoftEnumFullName);
      this.lodaEnumerationThatIsBeingMapped = selectedEnum as EnumDropdownInfo;
    }
    else {
      this.lodaEnumerationThatIsBeingMapped = null;
    }
  }

  onClickClearDefault = () => {
    this.idOfEnumSelectedAsDefault = null;
    this._fieldMapping.enumMappings.forEach(enumMapping => {
      enumMapping.useAsDefault = false;
    });
  }

  private populateEnumMappingsForLodaToLoanPass = () => {

    const fieldId = this._fieldMapping.loanPassFieldId.replace("field@", "");
    const selectedEnum = this.loanPassEnumerations.find(f => f.id == fieldId);

    if (selectedEnum && this._fieldMapping) {
      for (let i: number = 0; i <= selectedEnum.variants.length - 1; i++) {
        const variant = selectedEnum.variants[i];
        const mapping = this._fieldMapping.enumMappings.find(m => m.destinationVariantId == variant.id);
        if (!mapping) {
          let newEnumMap = new LoanPassEnumMapping();
          newEnumMap.loanPassEnumMappingId = (-1 * (new Date()).valueOf()) + i;
          newEnumMap.destinationVariantId = variant.id;
          newEnumMap.destinationVariantName = variant.name;
          newEnumMap.useAsDefault = false;
          this._fieldMapping.enumMappings.push(newEnumMap);
        }
      }
    }
    const defaultEnumMapping = this._fieldMapping.enumMappings.find(m => m.useAsDefault);
    if (defaultEnumMapping) {
      this.idOfEnumSelectedAsDefault = defaultEnumMapping.loanPassEnumMappingId;
    }
  }

  private populateEnumMappingsForLoanPassToLoda = () => {
    let mappings: LoanPassEnumMapping[] = [];
    if (this.lodaField && this._fieldMapping) {
      for (let i: number = 0; i <= this.lodaFieldEnumItems.length - 1; i++) {
        const variant = this.lodaFieldEnumItems[i] as EnumerationItem;
        const mapping = this._fieldMapping.enumMappings.find(m => m.destinationVariantName == variant.value.toString());
        if (!mapping) {
          let newEnumMap = new LoanPassEnumMapping();
          newEnumMap.loanPassEnumMappingId = (-1 * (new Date()).valueOf()) + i;
          newEnumMap.destinationVariantName = variant.name;
          newEnumMap.useAsDefault = false;
          mappings.push(newEnumMap);
        } else {
          mappings.push(mapping);
        }
      }
    }
    this._fieldMapping.enumMappings = mappings;
    const defaultEnumMapping = this._fieldMapping.enumMappings.find(m => m.useAsDefault);
    if (defaultEnumMapping) {
      this.idOfEnumSelectedAsDefault = defaultEnumMapping.loanPassEnumMappingId;
    }
  }

  onEditCustomExpressionClicked = (mapping: LoanPassEnumMapping) => {
    if (!this.isMappingFromLoanPassToLoda) {
      this.prepareExpressionEditorForLodaToLoanPass(mapping);
    } else {
      this.prepareExpressionEditorForLoanPassToLoda(mapping);
    }
  }

  prepareExpressionEditorForLoanPassToLoda = (mapping: LoanPassEnumMapping) => {
    const modalRef = this._modalService.open(ExpressionEditorDialogComponent, Constants.modalOptions.xlarge);
    let loanPassFieldNames = this.loanPassFields.map(f => new AutoCompleteItem('[' + f.name + ']', '[' + f.id + ']'));
    if (this.loanPassEnumerationThatIsBeingMapped) {
      loanPassFieldNames = [...loanPassFieldNames, ...this.loanPassEnumerationThatIsBeingMapped.variants.map(ei => new AutoCompleteItem("@" + ei.name, ei.id.toString()))];
    }
    modalRef.componentInstance.config = new ExpressionConfig(this.operators, loanPassFieldNames);
    modalRef.componentInstance.expression = mapping.customExpression;
    modalRef.result.then((result) => {
      if (result != null) {
        mapping.customExpression = result;
      }
    }, (res) => {
    });
  }

  prepareExpressionEditorForLodaToLoanPass = (mapping: LoanPassEnumMapping) => {
    const modalRef = this._modalService.open(ExpressionEditorDialogComponent, Constants.modalOptions.xlarge);
    let lodaFieldNames = this.lodaEnumPricingFields.map(p => new AutoCompleteItem('[' + p.name + ']', '[' + p.name + ']'));
    if (this.lodaEnumerationThatIsBeingMapped) {
      lodaFieldNames = [...lodaFieldNames, ...this.lodaEnumerationThatIsBeingMapped.enumValues.map(ei => new AutoCompleteItem("@" + ei.description, ei.name))];
    }
    modalRef.componentInstance.config = new ExpressionConfig(this.operators, lodaFieldNames);
    modalRef.componentInstance.expression = mapping.customExpression;
    modalRef.result.then((result) => {
      if (result != null) {
        mapping.customExpression = result;
      }
    }, (res) => {
    });
  }
}
