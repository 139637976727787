import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { AssetDetailsComponent } from "./asset-details.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    AssetDetailsComponent
  ],
  exports: [
    AssetDetailsComponent
  ],
  providers: [
  ]
})
export class AssetDetailsModule {
  constructor() {
  }
}


