import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Lookup } from 'src/app/modules/app-details/models/lookup.model';
import { AppDetailsService } from 'src/app/modules/app-details/services/app-details.service';
import { Constants } from 'src/app/services/constants';
import { isNullOrUndefined } from 'src/utils';
import { CustomInfoTemplateType } from '../custom-data-info.component';
import { CustomDataEditConfig } from './custom-data-edit-config.model';
import * as _ from 'lodash';

@Component({
  selector: 'custom-field-editor',
  templateUrl: './custom-field-editor.component.html',
  styleUrls: ['./custom-field-editor.component.scss'],
})
export class CustomFieldEditor implements OnInit {

  @ViewChild("multiSelectPopover")
  multiSelectPopover: PopoverDirective;

  @ViewChild("multiLinePopover")
  multiLinePopover: PopoverDirective;

  @Input()
  editConfig: CustomDataEditConfig;

  @Input()
  isInClassicEditMode: boolean = true;

  @Input()
  templateType: CustomInfoTemplateType = CustomInfoTemplateType.Label_LEFT;

  @Input()
  labelAlignment: 'left' | 'right' = 'right';

  multiSelectPossibleValues: Select2OptionData[];

  @Input()
  model: any = {};

  @Input()
  dataType?: string;

  @Input()
  readonly?: boolean = false;

  @Input()
  labelVisible?: boolean = true;

  multiselectSelectedValues: string[] = [];

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false
  };

  isEditActive: boolean = false;

  trimmedMultiSelectValue: string;
  multiLineFinalValue: string;

  private _lookupItems: Lookup[] = [];
  private _originalValue: string;

  constructor(
    private readonly _appService: AppDetailsService,
    private readonly _cdref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getLookUpTypes();
    this.validateDate();
    this._originalValue = this.model[this.editConfig.fieldName];
  }

  ngAfterViewChecked(): void {
    this._cdref.detectChanges();
  }

  onMultiSelectValueChanged = () => {
    this.model[this.editConfig.fieldName] = null;
    if (this.multiselectSelectedValues.length) {
      this.model[this.editConfig.fieldName] = this.multiselectSelectedValues.join(',');
    }
  }

  isFieldNullOrUndefined = (val): boolean => {
    return isNullOrUndefined(val)
  }

  private getLookUpTypes = () => {
    if (this.editConfig.editorType == 'Multiselect') {
      if (this.editConfig.lookupType == 'Custom') {
        this._lookupItems = JSON.parse(
          this.editConfig.serializedDropdownOptions || '[]'
        );
        this.multiSelectPossibleValues = this._lookupItems.map((i: Lookup) => {
          return {
            id: i.value,
            text: i.name
          }
        });
      } else {
        if (this.editConfig.lookupType) {
          this._appService
            .getLookUps(this.editConfig.lookupType)
            .subscribe((response) => {
              this._lookupItems = response;
              this.multiSelectPossibleValues = this._lookupItems.map((i: Lookup) => {
                return {
                  id: i.value,
                  text: i.name
                }
              });
            });
        } else {
          this._lookupItems = [];
          this.multiSelectPossibleValues = [];
        }
      }
      if (this.model[this.editConfig.fieldName]) {
        this.setMultiSelectValue();
        // if (Array.isArray(this.model[this.editConfig.fieldName])) {
        //   this.setMultiSelectValue();
        //   return;
        // }

        // this.model[this.editConfig.fieldName] = JSON.parse(this.model[this.editConfig.fieldName]);
        // if (Array.isArray(this.model[this.editConfig.fieldName])) {
        //   this.setMultiSelectValue();
        // }
      }
    }
    if (this.editConfig.editorType == 'Multiline') {
      this.setMultiLineValue();
    }
  }

  validateDate() {
    if (this.dataType === "DateTime") {
      if (this.model[this.editConfig.fieldName] && Constants.regexPatterns.time.test(this.model[this.editConfig.fieldName])) {
        this.model[this.editConfig.fieldName] = this.model[this.editConfig.fieldName].replace(Constants.regexPatterns.time, "").trim()
      }
    }

    if (['TimeZone', 'Date'].indexOf(this.editConfig.editorType) > -1) {
      const timestamp = Date.parse(this.model[this.editConfig.fieldName]);
      if (isNaN(timestamp)) {
        this.model[this.editConfig.fieldName] = null;
      }
    }
  }

  cancelClicked = () => {
    this.isEditActive = false;
    this.model[this.editConfig.fieldName] = this._originalValue;
    if (this.multiSelectPopover) {
      this.multiSelectPopover.hide();
      this.multiselectSelectedValues = this.model[this.editConfig.fieldName].map((el) => el.value);
    }
    if (this.multiLinePopover) {
      this.multiLinePopover.hide();
    }
  }

  applyClicked = () => {
    this.isEditActive = false;
    this._originalValue = this.model[this.editConfig.fieldName];
    if (this.multiSelectPopover) {
      this.multiSelectPopover.hide();
      this.setMultiSelectValue();
    }
    if (this.multiLinePopover) {
      this.multiLinePopover.hide();
      this.setMultiLineValue();
    }
  }

  editClicked = () => {
    if (!this.readonly) {
      this.isEditActive = true;
    }
  }

  private setMultiSelectValue = () => {
    this.multiselectSelectedValues = this.model[this.editConfig.fieldName]?.split(',');
    this.trimMultiSelectValues();
  }

  private setMultiLineValue = () => {
    this.multiLineFinalValue = this.model[this.editConfig.fieldName] || "--";
  }

  private trimMultiSelectValues = () => {
    const trimmedSelectedString = this.multiselectSelectedValues.length > 2
      ? _.take(this.multiselectSelectedValues, 2).join(';') + `... (+ ${this.multiselectSelectedValues.length - 2} more)`
      : this.multiselectSelectedValues.join(';');

    this.trimmedMultiSelectValue = trimmedSelectedString;
  }
}
