import { Component, Injector } from '@angular/core';
import { MortgageModelFieldsConfig } from 'src/app/modules/urla/models/urla-fields-config.model';
import { MortgageService } from 'src/app/services/mortgage.service';

@Component({
  template: ''
})
export abstract class QuickApplyFieldsConfigBoundComponent {

  protected quickApplyFieldsConfig: MortgageModelFieldsConfig;

  private _mortgageService: MortgageService;

  constructor(private readonly _injector: Injector) {
    this._mortgageService = this._injector.get(MortgageService);
    this.quickApplyFieldsConfig = this._mortgageService.quickApplyFieldsConfig;
  }
}
