<form #languagePreferencesForm="ngForm">
  <div class="form-input" [ngClass]="
      {
        'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
        'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
        'bg-transparent' : readonly
      }">

    <div class="d-flex">
      <div class="d-flex flex-column flex-grow-1">
        <span class="input-label" style="display: inline;" title="">
          <span class="input-label-overflow">{{label}}</span>
        </span>
        <select [hidden]="readonly" [(ngModel)]="value" #model="ngModel" name="{{name}}"
          [required]="required && !borrowerRefusesToProvideLanguagePreference"
          id="{{id}}" [ngClass]="{
            'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
            'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required'
          }" [attr.disabled]="disabled || !options || options.length === 0 ? '' : null"
          [attr.aria-disabled]="disabled || !options || options.length === 0 ? '' : null" class="input-element"
          (ngModelChange)="onPreferredLanguageChanged()">
          <option [ngValue]="null">Select One</option>
          <option [ngValue]="option.value" *ngFor="let option of options">{{option.name}}</option>
        </select>
        <input
          [hidden]="!readonly"
          type="text"
          value="{{getOptionLabel()}}"
          name="{{name}}_readonly"
          [ngClass]="
            {
              'ng-invalid-requested' : model && model.invalid && model.errors && model.errors.required && ((fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'requested') || (urlaFieldsConfig[fieldBeingEdited] != 'required' && required)),
              'ng-invalid-required' : model && model.invalid && model.errors && model.errors.required && fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
              'no-border' : !showBorder
            }"
          [readonly]="true"
          class="input-element"
        />

      </div>
      <div class="d-flex flex-column">
        <div style="display: grid;" class="ms-4">
          <label style="float: right; text-transform: none;">
            <input type="checkbox" class="me-1" (change)="onRefusalDecisionChanged($event)"
              [checked]="borrowerRefusesToProvideLanguagePreference"
              name="isRetired_{{name}}" [disabled]="readonly" />Refuse
          </label>
        </div>
      </div>
    </div>
  </div>
</form>
