import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BaseUrlaInputComponent } from '../base-urla-input.component';

@Component({
  selector: 'urla-radio-group',
  templateUrl: 'urla-radio-group.component.html',
  styleUrls: ['urla-radio-group.component.scss'],
  providers: [MakeProvider(UrlaRadioGroupComponent)]
})

export class UrlaRadioGroupComponent extends BaseUrlaInputComponent {

  @ViewChild('radioGroupInputForm')
  editorForm: NgForm;

  @Input()
  layout: string;

  @Input()
  options: EnumerationItem[] = [];

  constructor() {
    super();
  }
}
