import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/app/services/constants';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaMortgage } from '../models/urla-mortgage.model';
import { MortgageCalculationService } from '../services/mortgage-calculation.service';
import { NgForm } from '@angular/forms';
import { UrlaSectionComponent } from '../urla-section/urla-section.component';
import { UrlaRequiredState } from '../services/utility.service';
import { Subscription } from 'rxjs';
import { LoanPurposeTypeEnum } from '../../app-details/components/title-history/models/title-order.model';
import { createIsRequiredFunction } from '../urla-utils';
import { MortgageService } from '../../../services/mortgage.service';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';
import { MortgageFieldConfig } from '../models/urla-fields-config.model';

const constructionLikePurposes = Object.freeze(new Set<LoanPurposeTypeEnum>([
  LoanPurposeTypeEnum.ConstructionOnly,
  LoanPurposeTypeEnum.ConstructionToPermanent,
]));

@Component({
  selector: 'property-loan-info',
  templateUrl: './property-loan-info.component.html'
})
export class PropertyLoanInfoComponent extends UrlaSectionComponent
  implements OnInit, OnDestroy {

  @Input() mortgage: UrlaMortgage;

  @Input() isReadOnly: boolean;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: Record<string, MortgageFieldConfig>;

  @ViewChild("propertyLoanInfoForm")
  urlaSectionForm: NgForm;

  menuItem: string = Constants.menu.urlaMenuItems.propertyLoanInfo;

  governmentRefinanceTypes: EnumerationItem[] = [];

  constructionToPermanentClosingTypes: EnumerationItem[] = [];

  projectTypeCondominium: string;

  projectTypeNotInAProject: string;

  projectTypes: EnumerationItem[] = [];

  propertyAttachmentTypes: EnumerationItem[] = [];

  creditorServicingOfLoanStatementTypes: EnumerationItem[] = [];
  paymentBillingStatementFrequencyTypes: EnumerationItem[] = [];
  loanUnderwritingSubmitterTypes: EnumerationItem[] = [];
  lateChargeTypes: EnumerationItem[] = [];
  partialPaymentApplicationMethodTypes: EnumerationItem[] = [];
  mortgagePaymentFrequencyTypes: EnumerationItem[] = [];
  lienPositionTypes: EnumerationItem[] = [];
  propertyOccupancyTypes: EnumerationItem[] = [];
  unitOwnedByTypes: EnumerationItem[] = [];
  currentPropertyWillBeTypes: EnumerationItem[] = [];
  condominiumProjectStatusTypes: EnumerationItem[] = [];
  nFIPCommunityParticipationStatuses: EnumerationItem[] = [];

  projectDesignTypes: EnumerationItem[] = [];

  yesNoOptions: EnumerationItem[] = [];

  landValueTypeAppraised: string;

  landValueTypeOriginal: string;
  dhHiddenHack: boolean = false;

  protected isProjectTypeCondominium: boolean = false;
  protected isPropertyInAProject: boolean | null = null;
  protected isConstructionLikePurpose: boolean = false;
  protected isLoanPurposeConstructionToPerm: boolean = false;

  protected isRequired: (fieldName: string) => boolean;

  private _initEnumsSubscription: Subscription | null = null;
  private _initLoanPurposeChangesSubscription: Subscription | null = null;

  constructor(
    private readonly _enumsService: EnumerationService,
    private readonly _mortgageService: MortgageService,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _urlaValidationService: UrlaValidationService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.isRequired = createIsRequiredFunction(this.urlaFieldsConfig);
    this.dhHiddenHack = this.mortgage.companyId == 229 || this.mortgage.companyId == 276;
    this.initEnums();
    this.subscribeToLoanPurposeChanges();
  }

  ngOnDestroy() {
    this._initEnumsSubscription?.unsubscribe();
    this._initLoanPurposeChangesSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  validate = () => {
    const validityStatus = this._urlaValidationService.getStatusForPropertyLoanInfo(this.mortgage);
    super.setMenuItemStatus(validityStatus);
  }

  private initEnums() {
    this._initEnumsSubscription?.unsubscribe();

    this._initEnumsSubscription =
      this._enumsService.getMortgageEnumerations().subscribe((result) => {
        this.governmentRefinanceTypes = result[Constants.mortgageEnumerations.governmentRefinanceType];
        this.constructionToPermanentClosingTypes = result[Constants.mortgageEnumerations.constructionToPermanentClosingType];
        this.projectTypes = result[Constants.mortgageEnumerations.projectType];
        this.propertyAttachmentTypes = result[Constants.mortgageEnumerations.propertyAttachmentType];
        this.creditorServicingOfLoanStatementTypes = result[Constants.mortgageEnumerations.creditorServicingOfLoanStatementType];
        this.paymentBillingStatementFrequencyTypes = result[Constants.mortgageEnumerations.paymentBillingStatementFrequencyType];
        this.loanUnderwritingSubmitterTypes = result[Constants.mortgageEnumerations.loanUnderwritingSubmitterType];
        this.lateChargeTypes = result[Constants.mortgageEnumerations.lateChargeType];
        this.partialPaymentApplicationMethodTypes = result[Constants.mortgageEnumerations.partialPaymentApplicationMethodType];
        this.mortgagePaymentFrequencyTypes = result[Constants.mortgageEnumerations.mortgagePaymentFrequency];
        this.lienPositionTypes = result[Constants.mortgageEnumerations.lienPositionType];
        this.projectDesignTypes = result[Constants.mortgageEnumerations.projectDesignType];
        this.projectTypeCondominium = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.projectTypeCondominium);
        this.projectTypeNotInAProject = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.projectTypeNotInAProject);
        this.yesNoOptions = this._enumsService.getYesNoEnumItems();
        this.landValueTypeAppraised = this._enumsService.getEnumValue(Constants.enumerationValueNames.LandValueType.Appraised);
        this.landValueTypeOriginal = this._enumsService.getEnumValue(Constants.enumerationValueNames.LandValueType.Original);
        this.isProjectTypeCondominium = this.mortgage.subjectProperty && (this.mortgage.subjectProperty.projectType == this.projectTypeCondominium);

        if (this.mortgage.subjectProperty) {
          if (this.mortgage.subjectProperty.isPropertyNotInAProject === true) {
            this.isPropertyInAProject = false;
          } else if (this.mortgage.subjectProperty.isPropertyNotInAProject === false) {
            this.isPropertyInAProject = true;
          }
        }

        this.propertyOccupancyTypes = result[Constants.mortgageEnumerations.propertyOccupancyType];
        this.unitOwnedByTypes = result[Constants.mortgageEnumerations.unitOwnedByType];
        this.currentPropertyWillBeTypes = result[Constants.mortgageEnumerations.currentPropertyWillBeType];
        this.condominiumProjectStatusTypes = result[Constants.mortgageEnumerations.condominiumProjectStatusType];
        this.nFIPCommunityParticipationStatuses = result[Constants.mortgageEnumerations.nFIPCommunityParticipationStatus];
      });
    this._initEnumsSubscription.add(() => {
      this._initEnumsSubscription = null;
    });
  }

  private subscribeToLoanPurposeChanges(): void {
    this._initLoanPurposeChangesSubscription?.unsubscribe();

    this._initLoanPurposeChangesSubscription =
      this._mortgageService.loanPurpose$.subscribe(
        (purposeOfLoan: LoanPurposeTypeEnum | undefined) => {
          this.isConstructionLikePurpose =
            constructionLikePurposes.has(purposeOfLoan);
          this.isLoanPurposeConstructionToPerm =
            this._calculationService.isPurposeOfLoanConstructionToPerm(this.mortgage);
        });
    this._initLoanPurposeChangesSubscription.add(() => {
      this._initLoanPurposeChangesSubscription = null;
    });
  }

  constructionToPermanentChange(): void {
    let mortgage = this.mortgage;
    if (mortgage.subjectProperty && !mortgage.subjectProperty.isConstructionConversionOrConstructionToPermanent)
      mortgage.subjectProperty.constructionToPermanentClosingType = null;
  }

  onPropertyIsInAProjectChange(): void {
    this.mortgage.subjectProperty.projectType = null;
    this.isProjectTypeCondominium = false;
    if (this.mortgage.subjectProperty) {
      if (this.isPropertyInAProject === true) {
        this.mortgage.subjectProperty.isPropertyNotInAProject = false;
      } else if (this.isPropertyInAProject === false) {
        this.mortgage.subjectProperty.isPropertyNotInAProject = true;
      } else {
        this.mortgage.subjectProperty.isPropertyNotInAProject = null;
      }
    }
  }

  onProjectTypeChanged(projectType: string): void {
    let mortgage = this.mortgage;
    this.isProjectTypeCondominium = this.mortgage.subjectProperty && (projectType == this.projectTypeCondominium);
    if (!this.isProjectTypeCondominium && mortgage.subjectProperty) {
      mortgage.subjectProperty.projectDesignType = null;
    }

    if (mortgage.subjectProperty && !projectType) {
      if (!mortgage.subjectProperty.isPlannedUnitDevelopment) {
        mortgage.subjectProperty.projectName = null;
      }

      mortgage.subjectProperty.projectClassificationIdentifier = null;
      mortgage.subjectProperty.condominiumProjectStatus = null;
      mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier = null;
    }
  }

  landValueChange = () => {
    if (this.mortgage.subjectProperty && this.mortgage.subjectProperty.landValueType == this.landValueTypeAppraised) {
      this.mortgage.calculatedStats.landValue = this.mortgage.subjectProperty.lotAppraisedValue;
      this.mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this.mortgage);
      this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);
    }
    if (this.mortgage.subjectProperty && this.mortgage.subjectProperty.landValueType == this.landValueTypeOriginal) {
      this.mortgage.calculatedStats.landValue = this.mortgage.subjectProperty.lotOriginalCost;
      this.mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this.mortgage);
      this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);
    }
  }

  isAssumableChange = () => {
    if (!this.mortgage.mortgageTerm.isAssumable) {
      this.mortgage.mortgageTerm.isAssumed = null;
    }
  }

  onCurrentUsageTypeChanged = () => {
    if (this.mortgage.subjectProperty.currentUsageType != "Other") {
      this.mortgage.subjectProperty.currentUsageTypeOtherDescription = undefined;
    }
  }

  onCreditorServicingOfLoanStatementTypeChanged = () => {
    if (this.mortgage.mortgageTerm.creditorServicingOfLoanStatementType != "Other") {
      this.mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription = undefined;
    }
  }
}
