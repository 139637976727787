import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, finalize, of } from 'rxjs';
import { ThirdPartyCredential, ThirdPartyKeyValue } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { EnumType } from '../../../../../../models/loan-pass/enum-type.model';
import { LoanPassFieldMappingsService, LoanPassMappingType } from '../../../../../../services/loan-pass-field-mappings.service';
import { FieldMappingsDialogComponent } from '../loanpass-vendor-editor-dialog/field-mappings-dialog/field-mappings-dialog.component';
import { RepriceFieldsDialogComponent } from '../reprice-fields-dialog/reprice-fields-dialog.component';
import { LoanpassCustomFieldsDialogComponent } from '../loanpass-vendor-editor-dialog/loanpass-custom-fields-dialog/loanpass-custom-fields-dialog.component';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';

@Component({
  selector: 'loanpass-iframe-vendor-editor-dialog',
  templateUrl: 'loanpass-iframe-vendor-editor-dialog.component.html',
})
export class LoanPassIFrameVendorEditorDialogComponent implements OnInit, OnDestroy {

  @ViewChild('loanPassCredsForm')
  loanPassCredsForm: NgForm;

  @Input()
  userCompanyGuid: string

  @Input()
  inEditMode: boolean = true;

  @Input()
  branchId?: number;

  private _vendor: ThirdPartyCredential;

  get vendor(): ThirdPartyCredential {
    return this._vendor;
  }

  @Input() set vendor(value: ThirdPartyCredential) {
    this._vendor = value;

    if (this.vendor?.thirdPartyKeyValuePairs) {
      const urlCred = this.vendor.thirdPartyKeyValuePairs?.find(kvp => kvp.key == this.iframeURLKey);
      this.iframeURL = urlCred ? urlCred.value : null;

      const defaultProfileIdCred = this.vendor.thirdPartyKeyValuePairs?.find(kvp => kvp.key == this.defaultPricingProfileIdKey);
      this.defaultPricingProfileId = defaultProfileIdCred ? defaultProfileIdCred.value : null;

      const apiKeyCred = this.vendor.thirdPartyKeyValuePairs?.find(kvp => kvp.key == this.apiKeyItemKey);
      this.apiKey = apiKeyCred ? apiKeyCred.value : null;
    }

    this.fetchPricingProfileOptions();
  }

  @Input()
  scope: string;

  protected iframeURL: string = null;
  protected iframeURLKey: string = "IFrameUrl";

  protected defaultPricingProfileId: string = null;
  protected defaultPricingProfileIdKey = 'DefaultPricingProfileId';
  protected pricingProfileOptions: EnumType[] | null = null;

  protected apiKey: string = null;
  protected apiKeyItemKey: string = "ApiKey";

  private _fetchPricingProfileOptionsSubscription?: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _loanPassFieldMappingsService: LoanPassFieldMappingsService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this._fetchPricingProfileOptionsSubscription?.unsubscribe();
  }

  onFieldsMappingClicked = (mappingType: LoanPassMappingType) => {
    const modalRef = this._modalService.open(FieldMappingsDialogComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.credentialId = this.vendor.credentialId;
    modalRef.componentInstance.mappingType = mappingType;
    modalRef.componentInstance.vendor = PricingVendor.LoanPassIframe;
  }

  onCustomFieldsMappingClicked = () => {
    const modalRef = this._modalService.open(LoanpassCustomFieldsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.credentialId = this._vendor.credentialId;
    modalRef.componentInstance.vendor = "LoanPassIframe";
  }

  saveCredential = () => {

    this.loanPassCredsForm.form.markAllAsTouched();
    if (!this.loanPassCredsForm.form.valid) {
      return;
    }

    if (this.scope === 'User') {
      this.vendor.userId = this.userCompanyGuid;
    }

    if (this.scope === 'Branch') {
      this.vendor.branchId = String(this.branchId);
    }

    const thirdPartyKeyValuePairs = [...(this.vendor.thirdPartyKeyValuePairs ?? [])];

    const urlCred = this.vendor.thirdPartyKeyValuePairs.find(kvp => kvp.key == this.iframeURLKey);
    if (urlCred) {
      urlCred.value = this.iframeURL;
    }
    else {
      thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue(this.iframeURLKey, this.iframeURL));
    }

    const defaultProfileIdCred = this.vendor.thirdPartyKeyValuePairs.find(kvp => kvp.key == this.defaultPricingProfileIdKey);
    if (defaultProfileIdCred) {
      defaultProfileIdCred.value = this.defaultPricingProfileId;
    }
    else {
      thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue(this.defaultPricingProfileIdKey, this.defaultPricingProfileId));
    }

    const apiKeyCred = this.vendor.thirdPartyKeyValuePairs.find(kvp => kvp.key == this.apiKeyItemKey);
    if (apiKeyCred) {
      apiKeyCred.value = this.apiKey;
    }
    else {
      thirdPartyKeyValuePairs.push(new ThirdPartyKeyValue(this.apiKeyItemKey, this.apiKey));
    }

    const vendor = { ...this.vendor, thirdPartyKeyValuePairs };

    this._spinner.show();

    this._systemLevelService.saveCredential(vendor).pipe(
      finalize(() => this._spinner.hide()),
    ).subscribe({
      next: (result => {
        this.activeModal.close(result);
      }),
      error: (error => {
        this._notificationService.showError(error?.message || 'An error has been encountered when saving a credential', 'Error');
      }),
    });
  }

  onRepriceFieldsClicked = () => {
    const modalRef = this._modalService.open(RepriceFieldsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.credentialId = this.vendor.credentialId;
    modalRef.componentInstance.vendor = PricingVendor.LenderPriceIframe;
  }

  private fetchPricingProfileOptions() {
    this.pricingProfileOptions = null;

    this._fetchPricingProfileOptionsSubscription?.unsubscribe();
    this._fetchPricingProfileOptionsSubscription =
      (!!this.vendor.credentialId ? this._loanPassFieldMappingsService.getLoanPassFields(this.vendor.credentialId): of(null))
        .subscribe({
          next: (response) => {
            this.pricingProfileOptions = response?.pricingProfiles ?? [];
          },
          error: (error) => {
            const message = 'An error has been encountered when fetching pricing profile options';
            console.error(message, error);

            this._notificationService.showError(message, 'Error');

            this.pricingProfileOptions = [];
          },
        });
  }
}
