<div class="card">
  <div class="card-header">
    Rate Adjustment
  </div>
  <div class="card-body">
    <div class="grid-layout">
      <div class="grid-layout-row">
        <urla-grid-cell class="col-sm-6 col-xs-6" [label]="'1st Rate Adj Cap'" [disabled]="isReadOnly"></urla-grid-cell>
        <urla-percentage-input class="col-sm-6 col-xs-6" [readonly]="isReadOnly" name="rateAdjustmentPeriodicCap"
          [(ngModel)]="ausData.rateAdjustmentPeriodicCap" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
          [fieldBeingEdited]="'mortgage.ausData.rateAdjustmentPeriodicCap'" [required]="urlaFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap']?.required == 'required' ||
              urlaFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap']?.required == 'requested'">
        </urla-percentage-input>
      </div>
      <div class="grid-layout-row">
        <urla-grid-cell class="col-sm-6 col-xs-6" [label]="'1st Change Months'"
          [disabled]="isReadOnly"></urla-grid-cell>
        <urla-digits-input class="col-sm-6 col-xs-6" name="firstRateAdjustmentMonths"
          [(ngModel)]="ausData.firstRateAdjustmentMonths" [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
          [fieldBeingEdited]="'mortgage.ausData.firstRateAdjustmentMonths'" [required]="urlaFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths']?.required == 'required' ||
              urlaFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths']?.required == 'requested'">
        </urla-digits-input>
      </div>
      <div class="grid-layout-row mb-2">
        <urla-grid-cell class="col-sm-6 col-xs-6" [label]="'Subsequent Adj Cap'"
          [disabled]="isReadOnly"></urla-grid-cell>
        <urla-percentage-input class="col-sm-6 col-xs-6" [readonly]="isReadOnly"
          name="subsequentRateAdjustmentPeriodicCap" [(ngModel)]="ausData.subsequentRateAdjustmentPeriodicCap"
          [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
          [fieldBeingEdited]="'mortgage.ausData.subsequentRateAdjustmentPeriodicCap'" [required]="urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap']?.required == 'required' ||
              urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap']?.required == 'requested'">
        </urla-percentage-input>
      </div>
      <div class="grid-layout-row mb-2">
        <urla-grid-cell class="col-sm-6 col-xs-6" [label]="'Subsequent Period Months'"
          [disabled]="isReadOnly"></urla-grid-cell>
        <urla-digits-input class="col-sm-6 col-xs-6" [readonly]="isReadOnly"
          name="subsequentRateAdjustmentMonths" [(ngModel)]="ausData.subsequentRateAdjustmentMonths"
          [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
          [fieldBeingEdited]="'mortgage.ausData.subsequentRateAdjustmentMonths'" [required]="urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths']?.required == 'required' ||
              urlaFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths']?.required == 'requested'">
        </urla-digits-input>
      </div>
      <div class="grid-layout-row">
        <urla-grid-cell class="col-sm-6 col-xs-6" [label]="'Lifetime Cap'"
          [disabled]="isReadOnly"></urla-grid-cell>
        <urla-percentage-input class="col-sm-6 col-xs-6" [readonly]="isReadOnly" name="armRateAdjustmentLifetimeCap"
                               [(ngModel)]='ausData.armRateAdjustmentLifetimeCap' (ngModelChange)='calculateArmFullyIndexedRate()'
          [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
          [fieldBeingEdited]="'mortgage.ausData.armRateAdjustmentLifetimeCap'" [required]="urlaFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap']?.required == 'required' ||
              urlaFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap']?.required == 'requested'">
        </urla-percentage-input>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-header">
    Index Margin
  </div>
  <div class="card-body">
    <div class="grid-layout">
      <div class="grid-layout-row">
        <urla-grid-cell class="col-sm-6 col-xs-6" [label]="'Index Rate'" [disabled]="isReadOnly"></urla-grid-cell>
        <urla-percentage-input name="armIndexCurrent" class="col-sm-6 col-xs-6" [(ngModel)]="ausData.armIndexCurrent"
          (ngModelChange)="calculateArmFullyIndexedRate()" [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
          [fieldBeingEdited]="'mortgage.ausData.armIndexCurrent'" [required]="urlaFieldsConfig['mortgage.ausData.armIndexCurrent']?.required == 'required' ||
              urlaFieldsConfig['mortgage.ausData.armIndexCurrent']?.required == 'requested'">
        </urla-percentage-input>
      </div>
      <div class="grid-layout-row">
        <urla-grid-cell class="col-sm-6 col-xs-6" [label]="'Margin Rate'" [disabled]="isReadOnly"></urla-grid-cell>
        <urla-percentage-input class="col-sm-6 col-xs-6" name="armMarginRatePercentageInput" [readonly]="isReadOnly"
          [(ngModel)]="ausData.armMarginRate" (ngModelChange)="calculateArmFullyIndexedRate()" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
          [fieldBeingEdited]="'mortgage.ausData.armMarginRate'" [required]="urlaFieldsConfig['mortgage.ausData.armMarginRate']?.required == 'required' ||
              urlaFieldsConfig['mortgage.ausData.armMarginRate']?.required == 'requested'">
        </urla-percentage-input>
      </div>
      <div class="grid-layout-row">
        <urla-grid-cell class="col-sm-6 col-xs-6" [label]="'Adjusted Fully Indexed Rate'" [disabled]="isReadOnly"></urla-grid-cell>
        <urla-percentage-input class="col-sm-6 col-xs-6" [readonly]="true" name="armFullyIndexedRate"
          [(ngModel)]="ausData.armFullyIndexedRate" [inEditMode]="inEditMode">
        </urla-percentage-input>
      </div>
      <div class="grid-layout-row">
        <urla-grid-cell class='col-sm-6 col-xs-6' [label]="'Index Source'" [disabled]='isReadOnly'></urla-grid-cell>
        <urla-dropdown class='col-sm-6 col-xs-6' name='armIndexSourceType' [options]='armIndexSourceTypes' [readonly]='isReadOnly'
          [(ngModel)]="ausData.armIndexSourceType" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
          [fieldBeingEdited]="'mortgage.ausData.armIndexSourceType'" [required]="urlaFieldsConfig['mortgage.ausData.armIndexSourceType']?.required == 'required' ||
              urlaFieldsConfig['mortgage.ausData.armIndexSourceType']?.required == 'requested'">
        </urla-dropdown>
      </div>
      <div class='grid-layout-row'>
        <urla-grid-cell class='col-sm-6 col-xs-6' [label]="'Index Type'" [disabled]='isReadOnly'></urla-grid-cell>
        <urla-dropdown class='col-sm-6 col-xs-6' name='armIndexType' [options]='armIndexTypes' [readonly]='isReadOnly'
                       [(ngModel)]='ausData.armIndexType' [inEditMode]='inEditMode' [urlaFieldsConfig]='urlaFieldsConfig'
                       [fieldBeingEdited]="'mortgage.ausData.armIndexType'" [required]="urlaFieldsConfig['mortgage.ausData.armIndexType']?.required == 'required' ||
              urlaFieldsConfig['mortgage.ausData.armIndexType']?.required == 'requested'">
        </urla-dropdown>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-header">
    Floor Rate
  </div>
  <div class="card-body">
    <div class="grid-layout">
      <div class="grid-layout-row">
        <urla-grid-cell class="col-sm-6 col-xs-6" [label]="'Floor Rate'" [disabled]="isReadOnly"></urla-grid-cell>
        <urla-percentage-input class="col-sm-6 col-xs-6" [readonly]="isReadOnly" name="armFloorRate"
          [(ngModel)]="ausData.armFloorRate" [inEditMode]="inEditMode">
        </urla-percentage-input>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-header">
    Payment Adjustment
  </div>
  <div class="card-body">
    <div class="grid-layout">
      <div class="grid-layout-row">
        <urla-grid-cell class="col-sm-6 col-xs-6" [label]="'1st Payment Adj Months'" [disabled]="isReadOnly"></urla-grid-cell>
        <urla-digits-input class="col-sm-6 col-xs-6" name="firstPaymentAdjustmentMonths"
          [(ngModel)]="ausData.firstPaymentAdjustmentMonths" (ngModelChange)="onInterestChangeDateControl()"
          [readonly]="isReadOnly" [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig"
          [fieldBeingEdited]="'mortgage.ausData.firstPaymentAdjustmentMonths'" [required]="urlaFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths']?.required == 'required' ||
              urlaFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths']?.required == 'requested'">
        </urla-digits-input>
        <div class="px-2 invalid-feedback d-block" *ngIf="interestChangeDateError">
          {{ interestChangeDateError }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer form-footer">
  <div class="row">
    <div class="col-xs-6 text-left">
      <div class="button-items" style="float: right;" [hidden]="isReadOnly">
        <button type="button" class="btn btn-secondary" (click)="cancel.emit()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="onOkClicked()">OK</button>
      </div>
    </div>
  </div>
</div>
