import { Component, EventEmitter, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ResidualChartComponent } from '../residual-chart/residual-chart.component';
import { merge, Subscription, take } from 'rxjs';

const maxWidth = '40rem';

@Component({
  selector: 'residual-chart-modal',
  templateUrl: './residual-chart-modal.component.html',
  styleUrls: ['./residual-chart-modal.component.scss'],
})
export class ResidualChartModalComponent implements OnDestroy {
  @Output() closed = new EventEmitter<void>();

  @ViewChild('residualChart')
  residualChart: TemplateRef<ResidualChartComponent>;

  private readonly _modalOptions: NgbModalOptions = {
    centered: true,
  };

  private _openModalRef: NgbModalRef | null = null;

  private get _isModalOpen(): boolean {
    return this._openModalRef != null;
  }

  private _openModalRefSubscription: Subscription | null = null;

  constructor(
    private readonly _modalService: NgbModal,
  ) {
  }

  ngOnDestroy() {
    this.closed.complete();

    this._openModalRefSubscription?.unsubscribe();
  }

  open(): void {
    if (this._isModalOpen) {
      throw new Error('The modal is already open.');
    }

    const modalRef = this._openModalRef = this._modalService.open(
      this.residualChart,
      this._modalOptions,
    );
    this.setModalSize();

    this._openModalRefSubscription?.unsubscribe();
    this._openModalRefSubscription = merge(
      modalRef.closed,
      modalRef.dismissed,
    ).pipe(
      take(1),
    ).subscribe({
      next: () => {
        this._openModalRef = null;
        this.closed.next();
      },
      complete: () => {
        this._openModalRef = null;
        this._openModalRefSubscription = null;
      },
    });
  }

  close(): void {
    if (!this._isModalOpen) {
      throw new Error('The modal is not open.');
    }

    this._openModalRef!.close();
  }

  private setModalSize(): void {
    // This is a hack to set the max-width of the modal.
    const modalWindow = document.querySelector('ngb-modal-window');
    const modalDialog =
      modalWindow?.querySelector('.modal-dialog') as HTMLElement | null;
    if (modalDialog == null) {
      throw new Error('Could not find modal dialog element.');
    }

    modalDialog.style.maxWidth = maxWidth;
  }
}
