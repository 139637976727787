export enum OptionTypeEnum {
  Bool = 'Bool',
  Enum = 'Enum',
  Int = 'Int',
  Decimal = 'Decimal',
  Date = 'Date',
  String = 'String',
  MultiSelectEnum = 'MultiSelectEnum',
  Expression = 'Expression',
  Selector = 'Selector'
}

type Option = {
  display: string;
  value: string;
};

export interface ProviderOption {
  order: number;
  display: string;
  description: string;
  parentName: string | null;
  parentValue: boolean | null;
  name: string;
  type: OptionTypeEnum;
  required: boolean;
  default: object;
  allowedValues: Array<Option> | null;
}
