import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { AusResult } from 'src/app/models';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { formViewProvider } from 'src/app/core/services/form-view.provider';

@Component({
  selector: 'underwriting',
  templateUrl: 'underwriting.component.html',
  styleUrls: ['./underwriting.component.scss'],
  viewProviders: [formViewProvider],
})

export class UnderwritingComponent implements OnInit {

  @ViewChild('newAusDataForm')
  newAusDataForm: NgForm | undefined;

  @Input()
  mortgage: UrlaMortgage;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  ausSystemTypes: EnumerationItem[];

  @Input()
  freddieMacLoanPrograms: EnumerationItem[];

  @Input()
  freddieMacRefinanceProgram: EnumerationItem[];

  @Input()
  yesNoOptions: EnumerationItem[];

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  selectedType: string;

  protected selectedLoanPrograms: EnumerationItem[] = [];
  protected originalSelectedType: string;

  private _copyOfAusSystemTypes: EnumerationItem[];

  protected multiSelectSettings: IDropdownSettings = {
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };

  constructor() { }

  ngOnInit() {
    this._copyOfAusSystemTypes = _.cloneDeep(this.ausSystemTypes);

    if (this.mortgage.ausData?.freddieMacLoanPrograms) {
      this.mortgage.ausData.freddieMacLoanPrograms.forEach(lP => {
        const existingLoanProgram = this.freddieMacLoanPrograms.find(p => p.value === lP);
        if (existingLoanProgram) {
          this.selectedLoanPrograms.push(existingLoanProgram);
        }
      })
    }
    this.checkExistingAusSystemTypes();
  }

  onAddNew = (pop: any) => {
    if (!this.newAusDataForm) return;

    this.newAusDataForm.form.markAllAsTouched();
    if (!this.newAusDataForm.form.valid) return;

    this.originalSelectedType = this.selectedType;
    let newAusResultItem = new AusResult();
    newAusResultItem.ausSystem = this.selectedType;
    this.mortgage.ausData.ausResults.push(newAusResultItem);

    const selectedType = this.ausSystemTypes.findIndex(type => type.value === this.selectedType);
    this.ausSystemTypes.splice(selectedType, 1);
    this.selectedType = null;
    pop.hide();
  }

  onCancel = (pop: any) => {
    this.selectedType = null;
    pop.hide();
  }

  onAttemptedToDeleteAusResult = (ausResult: AusResult) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this aus result?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        const index = self.mortgage.ausData.ausResults.indexOf(ausResult);
        if (index >= 0) {
          self.mortgage.ausData.ausResults.splice(index, 1);
          self.checkExistingAusSystemTypes();
        }
      }
    });
  }

  private checkExistingAusSystemTypes = () => {
    if (this.mortgage.ausData?.ausResults?.length > 0) {
      this.ausSystemTypes = [...this._copyOfAusSystemTypes];
      this.mortgage.ausData.ausResults.forEach(ausResult => {
        if (ausResult.ausSystem) {
          const selectedTypeIndex = this.ausSystemTypes.findIndex(type => type.value === ausResult.ausSystem);
          if (selectedTypeIndex >= 0) {
            this.ausSystemTypes.splice(selectedTypeIndex, 1);
          }
        }
      })
    }
  }

  protected onFreddieMacLoanProgramsChanged = () => {
    this.mortgage.ausData.freddieMacLoanPrograms = this.selectedLoanPrograms.map((lP) => lP.value);
  }
}