import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Select2OptionData } from 'ng-select2';
import { Liability } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnterPayoffDialogComponent } from 'src/app/modules/urla/financial-information/liabilities/enter-payoff-dialog/enter-payoff-dialog.component';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import Swal from 'sweetalert2';

@Component({
  selector: 'liabilities-in-detail',
  templateUrl: './liabilities-in-detail.component.html',
  styleUrls: ['./liabilities-in-detail.component.scss']
})
export class LiabilitiesInDetailComponent implements OnInit {

  private _mortgage: UrlaMortgage;

  @Input()
  liabilityTypeOptions: EnumerationItem[] = [];

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;

    this.initializeLiabilities()
    this.initializePossibleAccountOwners();
  }

  @Output()
  liabilitiesChanged: EventEmitter<any> = new EventEmitter<any>();

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  liabilities: Liability[] = [];

  possibleAccountOwners: Array<{
    id: number,
    text: string
  }> = []

  borrowers = []

  editingIx: number = -1;

  editedLiability: Liability;

  thereIsAtLeastOneInvalidLineItem: boolean = false;

  financialMonthlyPaymentSubTotal: number;

  financialUnpaidBalanceSubTotal: number;

  financialPartialPayoffAmount: number;

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
  };

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _utilityService: UtilityService,
    private readonly _modalService: NgbModal,
    private readonly _calculationService: MortgageCalculationService) { }

  ngOnInit(): void {
  }

  static setMortgageDefaults(mortgage: UrlaMortgage) {
    const { liabilities } = mortgage;

    liabilities.forEach((liability) => {
      liability.holderName ??= "";
      // If there is no value, these are set to undefined, not 0
      //
      // liability.monthlyPayment ??= 0;
      // liability.unpaidBalance ??= 0;
      // liability.partialPayoffAmount ??= 0;
    });
  }

  initializeLiabilities = () => {
    if (this._mortgage && this._mortgage.liabilities) {
      this.liabilities = this._mortgage.liabilities.filter(l => this.liabilityTypeOptions.find(t => t.value == l.typeOfLiability));
      this.liabilities.forEach(liability => {
        let borrIds = [];
        liability.owningBorrowerIds.forEach(id => {
          borrIds.push(id.toString());
        });
        liability.owningBorrowerIds = borrIds;
      });
      this.calculateSubTotals();
    }
  }

  initializePossibleAccountOwners = () => {
    this.possibleAccountOwners = [];
    if (this._mortgage && this._mortgage.borrowers) {
      this._mortgage.borrowers.forEach(borrower => {
        const borrowerFullName = this._utilityService.getBorrowerFullName(borrower);
        let borrowerOption = new EnumerationItem(borrowerFullName, borrower.borrowerId);
        this.borrowers.push(borrowerOption);

        const possibleAccountOwner = {
          id: borrower.borrowerId,
          text: borrowerFullName
        }
        this.possibleAccountOwners.push(possibleAccountOwner);
      });
    }
  }

  getBorrowerName = (borrowerId: number) => {
    let borrower = this.possibleAccountOwners.find(owner => Number(owner.id) === borrowerId);
    if (borrower && borrower.text)
      return borrower.text;
    return '';
  }

  cancelEdit = () => {
    const liability = this.liabilities[this.editingIx];
    const indexInMainList = this.mortgage.liabilities.indexOf(liability);
    if (indexInMainList >= 0) {
      this.mortgage.liabilities[indexInMainList] = this.editedLiability;
    }
    this.liabilities[this.editingIx] = this.editedLiability;
    this.thereIsAtLeastOneInvalidLineItem = !this.editedLiability.owningBorrowerIds.length;
    this.calculateSubTotals();
    this.editingIx = -1
  }

  approveSave = (liability: Liability) => {
    this.editingIx = -1;
  }

  addLiability = (type?: string) => {
    let liability = new Liability();
    liability.liabilityId = this._utilityService.getUniqueId();
    liability.owningBorrowerIds = this.possibleAccountOwners && this.possibleAccountOwners.length == 1 ?
      [this.possibleAccountOwners[0].id] : [];
    if (type) {
      liability.typeOfLiability = type;
    }
    this.liabilities.push(liability);
    this.mortgage.liabilities.push(liability);
  };

  deleteLiability = (deleteIx: number) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        if (deleteIx === self.editingIx) {
          self.editingIx = -1;
        }
        let liability = self.liabilities[deleteIx];
        if (liability) {
          let indexInMortgageLiabilities = self.mortgage.liabilities.indexOf(liability);
          self.mortgage.liabilities.splice(indexInMortgageLiabilities, 1);
        }
        self.liabilities.splice(deleteIx, 1);
        self.calculateSubTotals();
        self.liabilitiesChanged.emit();
        const oneWithEmptyAccountOwnersIndex = self.liabilities.findIndex(a => !a.owningBorrowerIds.length);
        self.thereIsAtLeastOneInvalidLineItem = oneWithEmptyAccountOwnersIndex >= 0;
      }
    });
  }

  onLiabilityEdited = () => {
    this.calculateSubTotals();
    this.liabilitiesChanged.emit();
  }

  calculateSubTotals = () => {
    let subTotals = {
      monthlyPaymentSubTotal: 0,
      unpaidBalanceSubTotal: 0,
      partialPayoffAmount: 0
    }

    this.liabilities.forEach(liability => {
      subTotals.monthlyPaymentSubTotal += Number(liability.monthlyPayment) || 0;
      subTotals.unpaidBalanceSubTotal += Number(liability.unpaidBalance) || 0;
      subTotals.partialPayoffAmount += Number(liability.partialPayoffAmount) || 0;
    });

    this.financialMonthlyPaymentSubTotal = subTotals.monthlyPaymentSubTotal;
    this.financialUnpaidBalanceSubTotal = subTotals.unpaidBalanceSubTotal;
    this.financialPartialPayoffAmount = subTotals.partialPayoffAmount;
  }

  accountTypeOrBorrowerChange = (liability: Liability) => {
    this.thereIsAtLeastOneInvalidLineItem = !liability.owningBorrowerIds.length;
  }

  openPayoffDialog = (liability: Liability) => {
    const modalRef = this._modalService.open(EnterPayoffDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.partialPayoffAmount = liability.partialPayoffAmount;
    modalRef.componentInstance.unpaidBalance = liability.unpaidBalance;

    modalRef.result.then((result) => {
      if (!result) {
        result = 0;
      }
      liability.partialPayoffAmount = result;
      this.setPayoffTypeAndAmount(liability);
    });
  }

  setPayoffAmountToFull = (liability: Liability) => {
    liability.partialPayoffAmount = liability.unpaidBalance;
    liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full);
    this.mortgage.calculatedStats.totalPaidOffForRefinance = this._calculationService.calculateTotalPayOffForRefinance(this.mortgage);
  }

  setPayoffAmountToNone = (liability: Liability) => {
    liability.partialPayoffAmount = 0;
    liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.None);
  }

  setPayoffTypeAndAmount = (liability: Liability) => {
    if (_.isUndefined(liability.partialPayoffAmount) || _.isUndefined(liability.unpaidBalance))
      return;

    if (liability.partialPayoffAmount == null || liability.partialPayoffAmount == 0) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.None);
    }
    else if (Number(liability.unpaidBalance) > Number(liability.partialPayoffAmount)) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Partial);
    }
    else if (Number(liability.partialPayoffAmount) == Number(liability.unpaidBalance)) {
      liability.payoffType = this._enumsService.getEnumValue(Constants.enumerationValueNames.PayoffType.Full);
      this.mortgage.calculatedStats.totalPaidOffForRefinance = this._calculationService.calculateTotalPayOffForRefinance(this.mortgage);
    }
  }

  unpaidBalanceChange = (liability: Liability) => {
    if (!liability.unpaidBalance) {
      liability.partialPayoffAmount = 0;
    }
    if (liability.unpaidBalance < liability.partialPayoffAmount) {
      liability.partialPayoffAmount = liability.unpaidBalance;
    }
    this.setPayoffTypeAndAmount(liability);
  }

}
