import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'urla-deletable-cell',
  templateUrl: './urla-deletable-cell.component.html',
  styleUrls: ['./urla-deletable-cell.component.scss']
})
export class UrlaDeletableCellComponent implements OnInit {
  @Input() isReadOnly: boolean = false;

  @Output() onClickDelete = new EventEmitter<void>();

  protected mouseCursorOnDeleteButton: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  protected onClickDeleteButton(): void {
    this.onClickDelete.emit();
  }

  protected onMouseOverDeleteButton(): void {
    this.mouseCursorOnDeleteButton = true;
  }

  protected onMouseOutDeleteButton(): void {
    this.mouseCursorOnDeleteButton = false;
  }
}
