<div class="grid-layout">
  <div class="grid-layout-row" *ngFor="let income of incomes; let i = index">
    <div class="grid-cell-tag col-sm-1 ls-2 bg-color">
      <div class="form-input">
        <div class='cell-content action' *ngIf="!isReadOnly">
          <a *ngIf="indexToDelete !== i" (click)="onEditClicked(income)"><i
              class="fa fa-pencil-alt text-success"></i></a>
          <a *ngIf="indexToDelete !== i" (click)="indexToDelete = i;"><i class="fa fa-trash-alt"></i></a>
          <a *ngIf="i === indexToDelete" (click)="onDeleteConfirmed(i)"><i class="fa fa-check text-success"></i></a>
          <a *ngIf="i === indexToDelete" (click)="indexToDelete = -1;"><i class="fa fa-times text-danger"></i></a>
        </div>
      </div>
    </div>
    <div class="grid-cell-tag col-sm-9 bg-color">
      <div class="form-input">
        <div class='cell-content'>
          {{incomeTypeName(income)}}
        </div>
      </div>
    </div>
    <div class="grid-cell-tag col-sm-2 right-space">
      <div class="form-input">
        <div class='cell-content' [style.text-decoration]="income.isOmitted ? 'line-through' : 'none'">
          {{income.monthlyIncome | currency}} / month
        </div>
      </div>
    </div>
  </div>
</div>
