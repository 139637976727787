import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Asset, SystemLevel } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { UrlaMortgage } from '../../models/urla-mortgage.model';
import { UtilityService } from '../../services/utility.service';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import Swal from 'sweetalert2';
import { AssetDetailsComponent } from './asset-details/asset-details.component';

@Component({
  selector: 'assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
  viewProviders: [formViewProvider]
})
export class AssetsComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChild('assetDetailsDrawer')
  assetDetailsDrawer: DrawerComponent;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    this.applicationId = this._mortgage.applicationId;
    if (this._mortgage.borrowers && this._mortgage.borrowers.length) {
      this.borrowerIdForDigitalVerificationLink = this._mortgage.borrowers[0].borrowerId;
    }
    this.initializePossibleAccountOwners();
    this.initializeAssets();
    this.calculateSubTotal();
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  @Input()
  assetTypes: EnumerationItem[];

  @Output()
  subTotalChange: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  isReadOnly: boolean = false;

  @Input()
  inEditMode: boolean = false;

  @Input()
  urlaFieldsConfig: {};

  @Input()
  systemLevel: SystemLevel;

  assets: Array<Asset> = [];

  editingIx: number = -1;

  editedAsset: Asset;

  possibleAccountOwners: Array<Select2OptionData> = [];

  selectedOwnwers: string[] = [];

  subTotal: number = 0;

  thereIsAtLeastOneInvalidLineItem: boolean = false;

  isTpo: boolean = false;

  borrowerIdForDigitalVerificationLink: number = 0;
  applicationId: number = 0;

  assetDetailsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Medium,
    containerWrapperId: null
  };

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false
  };

  isNewAsset = false;

  isExceedFtc: boolean;

  private _mortgage: UrlaMortgage;

  private _eventSubscriptions: Subscription[] = [];

  constructor(private readonly injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _drawerService: DrawerService,
    private readonly _utilityService: UtilityService) {
    super(injector);
  }

  ngOnInit(): void {
    this.isTpo = this.applicationContext.isTpo;
    this.assetTypes = _.orderBy(this.assetTypes.filter(type =>
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Bond) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CheckingAccount) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BridgeLoanNotDeposited) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.LifeInsurance) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CertificateOfDepositTimeDeposit) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.IndividualDevelopmentAccount) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MoneyMarketFund) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MutualFund) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RetirementFund) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SavingsAccount) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.TrustAccount) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Annuity) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Boat) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BorrowerEstimatedTotalAssets) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BorrowerPrimaryHome) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.EmployerAssistance) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RealEstateOwned) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RecreationalVehicle) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SavingsBond) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SeverancePackage) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.PendingNetSaleProceedsFromRealEstateAssets) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Stock) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.StockOptions) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.GiftsTotal) ||
      type.value === this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.GiftsNotDeposited)
    ), x => x.name);
  }

  ngOnDestroy(): void {
    this._eventSubscriptions.forEach(subscription => {
      subscription?.unsubscribe();
    })
  }

  onAccountOwnersChanged = (asset: Asset) => {
    this.thereIsAtLeastOneInvalidLineItem = !asset.owningBorrowerIds.length;
    this.setAssetOwnerNames(asset);
  }

  isFilteredAsset = (assetType, assetInsertionType) => {
    if (assetInsertionType == 1) return true;

    return assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Bond) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CheckingAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BridgeLoanNotDeposited) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.LifeInsurance) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CertificateOfDepositTimeDeposit) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.IndividualDevelopmentAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MoneyMarketFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MutualFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RetirementFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SavingsAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Stock) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.TrustAccount);
  }

  getBorrowerName = (borrowerId: string) => {
    let borrower = this.possibleAccountOwners.find(owner => owner.id == borrowerId);
    if (borrower && borrower.text)
      return borrower.text;
    return '';
  }

  editAsset = (index: number, asset: Asset) => {
    if (this.editingIx != -1) {
      const indexInMainList = this._mortgage.assets.indexOf(asset);
      if (indexInMainList >= 0) {
        this._mortgage.assets[indexInMainList] = this.editedAsset;
      }
      this.assets[this.editingIx] = this.editedAsset;
      this.calculateSubTotal();
    }
    this.editedAsset = _.cloneDeep(asset);
    this.editingIx = index;
  }

  cancelEdit = () => {
    const asset = this.assets[this.editingIx];
    const indexInMainList = this._mortgage.assets.indexOf(asset);
    if (this.isNewAsset) {
      if (indexInMainList >= 0) {
        this._mortgage.assets.splice(indexInMainList, 1);
      }
      this.assets.splice(this.editingIx, 1);
      this.thereIsAtLeastOneInvalidLineItem = false;
    } else {
      if (indexInMainList >= 0) {
        this._mortgage.assets[indexInMainList] = this.editedAsset;
      }
      this.assets[this.editingIx] = this.editedAsset;
      this.thereIsAtLeastOneInvalidLineItem = !this.editedAsset.owningBorrowerIds.length;
    }

    this.calculateSubTotal();
    this.isNewAsset = false;
    this.editingIx = -1;
  }

  deleteAsset = (removeIx: number) => {
    const self = this;
    Swal.fire({
      title: 'Delete',
      text: 'Are you sure you\'d want to delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then(function (result: any) {
      if (result.value) {
        if (removeIx === self.editingIx) {
          self.editingIx = -1;
        }
        const index = self.mortgage.assets.indexOf(self.assets[removeIx]);
        if (index >= 0) {
          self.mortgage.assets.splice(index, 1);
        }
        self.assets.splice(removeIx, 1);
        const oneWithEmptyAccountOwnersIndex = self.assets.findIndex(a => !a.owningBorrowerIds.length);
        self.thereIsAtLeastOneInvalidLineItem = oneWithEmptyAccountOwnersIndex >= 0;
        self.calculateSubTotal();
      }
    });
  }

  omitAsset = (asset: Asset, isOmit: boolean) => {
    asset.isDeposited = !isOmit;
    this.calculateSubTotal();
  }

  addAsset = () => {
    let asset = new Asset();
    asset.assetId = this._utilityService.getUniqueId();
    asset.owningBorrowerIds = this.possibleAccountOwners && this.possibleAccountOwners.length == 1 ?
      [this.possibleAccountOwners[0].id] : [];
    asset.isDeposited = true;

    this.setAssetOwnerNames(asset);
    this.assets.push(asset);
    this._mortgage.assets.push(asset);
    if (this.editingIx != -1) {
      this.assets[this.editingIx] = this.editedAsset;
      const assetInMainList = this.mortgage.assets.find(a => a.assetId == this.editedAsset.assetId);
      if (assetInMainList) {
        const indexInMainList = this.mortgage.assets.indexOf(assetInMainList);
        if (indexInMainList >= 0) {
          this.mortgage.assets[indexInMainList] = this.editedAsset;
        }
      }
      this.calculateSubTotal();
    }
    this.editingIx = this.assets.length - 1;
    this.isNewAsset = true;
    this.editedAsset = _.cloneDeep(asset);
    this.thereIsAtLeastOneInvalidLineItem = !asset.owningBorrowerIds.length;
    this.calculateSubTotal();
  }

  onShowAssetDetailsClicked = (asset: Asset) => {
    this._drawerService.hide("assetDetailsDrawerOptions");
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = AssetDetailsComponent;
    dynamicComponentInfo.parameters.set('asset', asset);
    this._drawerService.show("assetDetailsDrawer", 100, "Asset Details", dynamicComponentInfo).then(() => {
      const cancelledEventSubscription = this.assetDetailsDrawer.componentInstance.cancelled.subscribe(() => {
        this._drawerService.hide("assetDetailsDrawer");
      });
      this._eventSubscriptions.push(cancelledEventSubscription);
      const changesApprovedEventSubscription = this.assetDetailsDrawer.componentInstance.changesApproved.subscribe((editedAsset) => {
        this._drawerService.hide("assetDetailsDrawer");
        const indexOfAddedThatIsEdited = this.assets.findIndex(a => a.assetId === editedAsset.assetId);
        if (indexOfAddedThatIsEdited >= 0) {
          this.assets[indexOfAddedThatIsEdited] = editedAsset;
          const assetInMainList = this.mortgage.assets.find(a => a.assetId == editedAsset.assetId);
          if (assetInMainList) {
            const indexInMainList = this.mortgage.assets.indexOf(assetInMainList);
            if (indexInMainList >= 0) {
              this.mortgage.assets[indexInMainList] = editedAsset;
            }
          }
        }
      });
      this._eventSubscriptions.push(changesApprovedEventSubscription);
    });
  }

  private calculateSubTotal = () => {
    this.subTotal = 0;

    this.assets.forEach(asset => {
      if (asset.isDeposited) {
        this.subTotal += Number(asset.cashMarketValue || 0);
      }
    });

    this.calculateIfTotalAssetsExceedsFtc();

    this.subTotalChange.emit(this.subTotal);
  }

  private setAssetOwnerNames = (asset: Asset) => {
    asset['owners'] = [];
    asset.owningBorrowerIds.forEach(borrowerId => {
      asset['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(borrowerId) });
    });
  }

  private initializeAssets = () => {
    this.assets = [];
    if (this._mortgage.assets) {
      this._mortgage.assets.forEach(asset => {
        if (this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) {
          let borrIds = [];
          asset['owners'] = [];
          asset.owningBorrowerIds.forEach(borrowerId => {
            borrIds.push(borrowerId.toString());
            asset['owners'].push({ borrowerId: borrowerId, name: this.getBorrowerName(borrowerId) });
          });
          asset.owningBorrowerIds = borrIds;
          this.assets.push(asset);
        }
      });
    }
  }

  private initializePossibleAccountOwners = () => {
    this.possibleAccountOwners = [];
    if (this._mortgage.borrowers !== null) {
      this._mortgage.borrowers.forEach(borrower => {
        const borrowerFullName = this._utilityService.getBorrowerFullName(borrower);

        const possibleAccountOwner = {
          id: borrower.borrowerId.toString(),
          text: borrowerFullName
        };
        this.possibleAccountOwners.push(possibleAccountOwner);
      });
    }
  }

  private calculateIfTotalAssetsExceedsFtc = () => {
    let cashMarketSubTotal = 0;
    this.mortgage?.assets?.forEach(asset => {
      cashMarketSubTotal += Number(asset.cashMarketValue || 0);
    });

    const ftc = this.applicationContext.currentMortgageCalculationDetails?.fundsToClose || {};
    this.isExceedFtc = cashMarketSubTotal >= ftc.cashFromToBorrower;
  }
}
