import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { Utils } from 'src/app/core/services/utils';
import { Address, BorrowerCounselingEvent, ChannelEnum, MortgageBorrower, ResidencyAddress, ResidencyType } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { UtilityService } from 'src/app/modules/urla/services/utility.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import Swal from 'sweetalert2';
import { Constants } from 'src/app/services/constants';
import { QuickApplyCounselingEventsComponent } from '../qa-counseling-events/qa-counseling-events.component';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DependentsDialogComponent } from 'src/app/modules/urla/borrower-information/contact-info/dependents/dependents-dialog.component';
import { ReplaySubject, Subscription, timer } from 'rxjs';
import { MenuService } from 'src/app/services/menu.service';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { InvalidateableComponent } from '../../../invalidateable-component';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import { formatDate } from '@angular/common';
import { QuickApplyFieldsConfigBoundComponent } from 'src/app/shared/components/quick-apply-fields-config-bound.component';
import { MenuItemStatus } from 'src/app/modules/tpo/models/enums/menu-item-status.enum';
import { copyToClipboard } from '../../../../../../../../utils';
import { NotificationService } from '../../../../../../../services/notification.service';
import { takeUntil } from 'rxjs/operators';
import { monthsToYearsAndMonths } from '../../../quick-apply-utils';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';

@Component({
  selector: 'qa-borrower-details',
  templateUrl: 'qa-borrower-details.component.html',
  styleUrls: ['./qa-borrower-details.component.scss']
})
export class QuickApplyBorrowerDetailsComponent extends QuickApplyFieldsConfigBoundComponent implements OnInit,
  AfterViewInit, InvalidateableComponent {

  @ViewChildren('counselingEvent') counselingEvents: QueryList<QuickApplyCounselingEventsComponent> | undefined;

  @ViewChild('qaBorrowerDetailsInfo') qaBorrowerDetailsInfoForm: NgForm | undefined;
  @ViewChild('qaBorrowerDeclarationsInfo') qaBorrowerDeclarationsForm: NgForm | undefined;
  @ViewChild('qaBorrowerDemographicsInfo') qaBorrowerDemographicsForm: NgForm | undefined;

  @ViewChild('borrowerDetails') borrowerDetails: ElementRef;
  @ViewChild('borrowerDeclarationsInfo') borrowerDeclarationsInfo: ElementRef;
  @ViewChild('borrowerDemographicsInfo') borrowerDemographicsInfo: ElementRef;
  @ViewChild('borrowerPersonalInfo') borrowerPersonalInfo: ElementRef;
  @ViewChild('borrowerAddress') borrowerAddress: ElementRef;
  @ViewChild('borrowerMilitaryInfo') borrowerMilitaryInfo: ElementRef;
  @ViewChild('borrowerEduAndCounseling') borrowerEduAndCounseling: ElementRef;
  @ViewChild('borrowerLanguagePref') borrowerLanguagePref: ElementRef;

  @Output()
  readonly invalidate: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  saveBorrowerButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  borrowerValidationStatusChanged: EventEmitter<ValidityStatus> = new EventEmitter<ValidityStatus>();

  @Input()
  set borrower(borrower: MortgageBorrower) {
    this._borrower = borrower;

    const residencyAddresses = borrower.residencyAddresses;
    if (residencyAddresses) {
      const presentAddresses
        = this.presentAddresses
        = residencyAddresses.filter(a => a.residencyType === ResidencyType.PresentAddress);

      if (!presentAddresses.length) {
        const presentAddress = new ResidencyAddress();
        presentAddress.residencyType = ResidencyType.PresentAddress;
        presentAddress.borrowerId = borrower.borrowerId;
        presentAddress.address = new Address();
        residencyAddresses.push(presentAddress);
        presentAddresses.push(presentAddress);
      } else {
        presentAddresses.forEach(address => {
          if (!address.durationYears && !address.durationMonths) {
            address.durationMonths = null;
            address.durationYears = null;
          }
        })
      }

      this.presentAddress = presentAddresses[0];
      this.setMailingAddress(residencyAddresses);
      this.setFormerAddresses(residencyAddresses);

      const presentAddress = this.presentAddress;

      if (!this.mailingAddress) {
        this.mailingAddress = new ResidencyAddress();
        this.mailingAddress.residencyType = ResidencyType.MailingAddress;
        this._borrower.residencyAddresses.push(this.mailingAddress);
        this.assignPresentAddressToMailingAddress();
      } else {
        if (this._compareAddress(presentAddress?.address, this.mailingAddress?.address)) {
          this.assignPresentAddressToMailingAddress();
        } else {
          this.isMailingAddressDiffFromCurrentAddress = true;
        }
      }
    } else {
      let presentAddress = new ResidencyAddress();
      presentAddress.address = new Address();
      presentAddress.residencyType = ResidencyType.PresentAddress;
      presentAddress.borrowerId = borrower.borrowerId;

      this.presentAddress = presentAddress;
      borrower.residencyAddresses = [this.presentAddress];
      this.presentAddresses.push(this.presentAddress);
    }
    this.calculateNumberOfYearsAndMonths();
    this.checkValidityStatusesForSections();

    if (!this.borrower.authorizationMethod) {
      const indexForInternetOption = this.authorizationMethods.findIndex(m => m.value === 'Internet');
      if (indexForInternetOption >= 0) {
        this.authorizationMethods.splice(indexForInternetOption, 1);
      }
    }

    this.invalidate.emit();
  }

  get borrower(): MortgageBorrower {
    return this._borrower;
  }

  @Input()
  loanBorrower: BorrowerDto;

  @Input()
  set mortgage(mortgage: UrlaMortgage) {
    this._mortgage = mortgage;
    this.isPurchase = this._mortgageCalculationService.isPurposeOfLoanPurchase(this._mortgage);
    this.isRefinance = this._mortgageCalculationService.isPurposeOfLoanRefinance(this._mortgage);
    this.isCorrespondent = this._mortgage.channel === ChannelEnum.Correspondent;  //TODO: REVISIT
  }

  get mortgage(): UrlaMortgage {
    return this._mortgage;
  }

  tbdWorkPhoneExt: number;
  tbdCheckboxEConsent: boolean;

  isCorrespondent: boolean;

  @Input()
  subTab: string;

  @Input()
  protected creditReportingHtml: string = "";

  @Input()
  isLoanReadOnly: boolean;

  @Output()
  subTabChange: EventEmitter<string> = new EventEmitter<string>();

  isMailingAddressDiffFromCurrentAddress: boolean = false;
  militaryStatus: string;
  loanProcessCommunicationValue: string = 'text';
  isOpenedNewDebt: string = 'false';
  alimonyOrChildSupport: string = 'false';
  renovationValue: string;
  residencyType: boolean;
  hasCounseling: boolean = false;
  hasHomebuyerEducation: boolean = false;
  dependentAges: string = '';
  dobAge: number;
  unmarriedAddendum: boolean = undefined;

  numberOfMonths: number = 0;
  numberOfYears: number = 0;
  hasMoreThan2YearsOfHistory: boolean | undefined = undefined;
  monthsOfHistoryForValidation: number | undefined = 2;

  isActiveDutyMilitary: string;
  onlyNonActivatedReserveOrNationalGuard: string;
  isRetiredDischargedSeparatedFromMilitary: string;
  showStickyBar: boolean = false;
  borrowerFullName: string;
  activeTabName: string;

  presentAddresses: ResidencyAddress[] = [];

  protected presentAddress: ResidencyAddress;

  protected mailingAddress: ResidencyAddress;

  formerAddresses: ResidencyAddress[] = [];

  isPrequalificationCheckReadonly: any = {};

  protected basicInfoStatus: MenuItemStatus;
  protected declarationsStatus: MenuItemStatus;
  protected demographicsStatus: MenuItemStatus;
  protected isSameAsSubjectProperty: boolean;
  protected titleOnlyEnumValue: string = "";
  protected nonTitledSpouseEnumValue: string = "";

  protected maritalStatuses: EnumerationItem[] = [];
  protected languageOptions: EnumerationItem[] = [];
  protected states: EnumerationItem[] = [];
  protected domesticRelationshipTypes: EnumerationItem[] = [];
  protected residencyTypes: EnumerationItem[] = [];
  protected suffixes: EnumerationItem[] = [];
  protected borrowerCounselingFormatTypes: EnumerationItem[] = [];
  protected authorizationMethods: EnumerationItem[] = [];
  protected signingRoleOptions: EnumerationItem[] = [];

  protected isRefinance: boolean;
  protected isPurchase: boolean;

  protected orderCreditDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  protected identifyingDocumentsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  private _mortgage: UrlaMortgage;
  private _borrower: MortgageBorrower;
  private _copyOfBorrower: MortgageBorrower;
  // private _shownPopover: PopoverDirective | undefined;

  private readonly _compareAddress = createAddressComparer(
    'address1',
    'city',
    'state',
    'zipCode',
  );

  private _sections: Section[] = [
    { header: 'Personal Info', id: 'borrower-personal-info', order: 1 },
    { header: 'Address', id: 'borrower-address', order: 2 },
    { header: 'Military Info', id: 'borrower-military-info', order: 3 },
    { header: 'Homeownership Education and Housing Counseling', id: 'borrower-education-and-counseling', order: 4 },
    { header: 'Language Preferences', id: 'borrower-language-preferences', order: 5 },
    { header: 'Declarations', id: 'borrower-declarations-info', order: 6 },
    { header: 'Demographics Information Of Borrower', id: 'borrower-demographics-info', order: 7 }
  ];

  private _visibleSections: Section[] = [this._sections[0]];
  private _dependentModalOptions: NgbModalOptions;

  private _borrowerBasicInfoFormValueChangesSubscription: Subscription;
  private _borrowerDemographicsFormValueChangesSubscription: Subscription;
  private _borrowerDeclarationsFormValueChangesSubscription: Subscription;

  protected visibleSection: Section = this._sections[0];

  private _destroyed$ = new ReplaySubject<void>(1);

  constructor(
    injector: Injector,
    private readonly _enumsService: EnumerationService,
    private readonly _utilsService: UtilityService,
    private readonly _modalService: NgbModal,
    private readonly _menuService: MenuService,
    private readonly _drawerService: DrawerService,
    private readonly _notificationService: NotificationService,
    private readonly _mortgageCalculationService: MortgageCalculationService
  ) {
    super(injector);

    this._dependentModalOptions = {
      backdrop: 'static',
      centered: true,
      scrollable: true
    };
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.maritalStatuses = enums[Constants.enumerations.maritalStatuses];
      this.languageOptions = this._enumsService.languages;
      this.states = this._enumsService.states;
      this.domesticRelationshipTypes = enums[Constants.enumerations.domesticRelationshipTypes];
      this.residencyTypes = enums[Constants.enumerations.residencyTypes];
      this.suffixes = enums[Constants.enumerations.suffix];
      this.signingRoleOptions = enums[Constants.mortgageEnumerations.signingRole];
      this.borrowerCounselingFormatTypes = enums[Constants.enumerations.borrowerCounsellingFormatType];
      this.authorizationMethods = cloneDeep(this._enumsService.authorizationMethods);
    })
  }

  ngOnInit() {
    this.borrowerFullName = this._utilsService.getBorrowerFullName(this.borrower);
    this.subTab = this.subTab || 'basic_details' + this.borrower.borrowerId;
    this.unmarriedAddendum = this.borrower.domesticRelationshipType != null || this.borrower.unmarriedRelationshipState != null;

    if (this.borrower.counselingEvents && this.borrower.counselingEvents.length > 0) {
      const isAnyCounselingEvent = this.borrower.counselingEvents.some((event) => event.counselingType === 'Counseling');
      const isAnyEducationgEvent = this.borrower.counselingEvents.some((event) => event.counselingType === 'Education');
      if (isAnyCounselingEvent) {
        this.hasCounseling = true;
      }
      if (isAnyEducationgEvent) {
        this.hasHomebuyerEducation = true;
      }
    }

    this.mortgage.borrowers.forEach(borrower => {
      if (borrower.authorizationMethod === "Internet") {
        this.isPrequalificationCheckReadonly[borrower.borrowerId.toString()] = true;
        borrower.authorizedCreditCheck = true;
      }
    });

    const { address1, city, state, zipCode } = this._mortgage.subjectProperty;
    const subjectPropertyAddress = { address1, city, state, zipCode };
    this.isSameAsSubjectProperty = this._compareAddress(subjectPropertyAddress, this.presentAddress.address);

    this.titleOnlyEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.SigningRole.TitleOnly);
    this.nonTitledSpouseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.SigningRole.NonTitledSpouse);
    this.isActiveDutyMilitary = Constants.enumerations.enumItemNames.militaryStatuses.isActiveDutyMilitary;
    this.onlyNonActivatedReserveOrNationalGuard = Constants.enumerations.enumItemNames.militaryStatuses.onlyNonActivatedReserveOrNationalGuard;
    this.isRetiredDischargedSeparatedFromMilitary = Constants.enumerations.enumItemNames.militaryStatuses.isRetiredDischargedSeparatedFromMilitary;

    if (this.borrower.isActiveDutyMilitary) {
      this.militaryStatus = this.isActiveDutyMilitary;
    } else if (this.borrower.onlyNonActivatedReserveOrNationalGuard) {
      this.militaryStatus = this.onlyNonActivatedReserveOrNationalGuard;
    } else if (this.borrower.isRetiredDischargedSeparatedFromMilitary) {
      this.militaryStatus = this.isRetiredDischargedSeparatedFromMilitary;
    } else {
      this.militaryStatus = 'noMilitaryStatus';
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._sections.forEach(section => {
        this.startObservingSectionVisibility(section.id);
      })
      this.startObservingBorrowerInfoVisibility();
    });

    this.subscribeToBasicInfoFormForValueChanges();
    this.subscribeToDeclarationsFormForValueChanges();
    this.subscribeToDemographicsFormForValueChanges();
    this.calculateNumberOfYearsAndMonths();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();

    this._borrowerBasicInfoFormValueChangesSubscription?.unsubscribe();
    this._borrowerDemographicsFormValueChangesSubscription?.unsubscribe();
    this._borrowerDeclarationsFormValueChangesSubscription?.unsubscribe();
  }

  onShownPopover(ref: PopoverDirective) {
    // const setRef = () => this._shownPopover = ref;

    // if (this._shownPopover) {
    //   this._shownPopover.hide();
    //   setTimeout(setRef);
    // } else {
    //   setRef();
    // }
  }

  onHiddenPopover() {
    // this._shownPopover = undefined;
  }

  onOrderCreditDrawerToggled = () => {
    this._drawerService.show("orderCreditDrawerBorrowerInfo", 10);
  }

  onIdentifyingDocumentsDrawerToggled = () => {
    this._drawerService.show("identifyingDocumentsDrawer1" + this.borrower.borrowerId, 10);
  }

  onYesNoOptionChanged = (borrower) => {
    // FIXME: If the today date is changed after component init, the dirty check
    //  will be broken.
    const today = new Date();
    borrower.dateAuthorizedCreditCheck = borrower.authorizedCreditCheck ? formatDate(today, 'MM/dd/yyyy', 'en-US') : undefined;
  }

  onMaritalStatusChanged = () => {
    if (this._borrower.maritalStatus !== "Single") {
      this.unmarriedAddendum = null;
      this.onUnmarriedAddendumChanged();
    }
  }

  onAuthorizedCreditChanged = () => {
    if (!this.borrower.authorizedCreditCheck) {
      this.borrower.dateAuthorizedCreditCheck = null;
      this.borrower.authorizationMethod = null;
    }
  }

  onUnmarriedAddendumChanged = () => {
    if (!this.unmarriedAddendum) {
      this.borrower.domesticRelationshipType = null;
      this.borrower.unmarriedRelationshipState = null;
    }
  }

  saveBorrowerInfo = () => {
    this.saveBorrowerButtonClicked.emit();
  }

  onNoOfDependentsChanged = () => {
    if (!this.borrower.dependentCount) {
      this.borrower.dependents = [];
      this.dependentAges = "";
      return;
    }
    const dependents = [...this.borrower.dependents];
    const numberOfDependentsDiff = this.borrower.dependentCount - dependents.length;
    if (numberOfDependentsDiff > 0) {
      for (let i = 1; i <= numberOfDependentsDiff; i++) {
        dependents.push(0);
      }
    } else if (numberOfDependentsDiff < 0) {
      const noOfDependents = dependents.length;
      for (let i = noOfDependents - 1; i > noOfDependents - 1 + numberOfDependentsDiff; i--) {
        dependents.splice(i, 1);
      }
    }
    this.showDependentsPopup(dependents);
  }

  onNumberofDependentsClicked = () => {
    const dependents = [...this.borrower.dependents];
    this.showDependentsPopup(dependents);
  }

  showDependentsPopup = (dependentAges: number[]) => {
    const modalRef = this._modalService.open(DependentsDialogComponent, this._dependentModalOptions);
    modalRef.componentInstance.dependents = dependentAges;
    modalRef.result.then((result) => {
      if (result !== 'cancel')
        this.borrower.dependents = result;
      this.initDependents();
    }, (err) => {
    });
  }

  onSigningRoleChanged = () => {
    let isTitleOnlyOrNonTitledSpouse = this.borrower.signingRole == this.titleOnlyEnumValue ||
      this.borrower.signingRole == this.nonTitledSpouseEnumValue;

    if (isTitleOnlyOrNonTitledSpouse) {
      this.borrower.employments = [];
      this.borrower.nonEmploymentIncomes = [];
    }
  }

  onDateofBirthChanged = () => {
    if (this.borrower.dateOfBirth) {
      const date = new Date(this.borrower.dateOfBirth);
      if (!isNaN(date.getTime())) {
        const ageDifMs = Date.now() - new Date(this.borrower.dateOfBirth).getTime();
        const ageDate = new Date(ageDifMs);
        this.dobAge = Math.abs(ageDate.getUTCFullYear() - 1970);
      } else {
        this.dobAge = null;
      }
    } else {
      this.dobAge = null;
    }
  }

  protected onChangeAddressDuration(): void {
    this.calculateNumberOfYearsAndMonths();
    this.checkValidityStatusesForSections();
  }

  onAddPreviousAddressClicked = (): void => {
    this.addAddress(ResidencyType.FormerAddress);
    this.setFormerAddresses(this.borrower.residencyAddresses);
    this.calculateNumberOfYearsAndMonths();
  }

  onAddNewCounselingEventClicked = (counselingType: string): void => {
    let newEvent = new BorrowerCounselingEvent();
    newEvent.borrowerId = this.borrower.borrowerId;
    newEvent.counselingType = counselingType;
    this._borrower.counselingEvents.push(newEvent);
  }

  onLanguagePreferenceChange = (): void => {
    if (this._borrower.languagePreference !== 'Other') {
      this._borrower.languagePreferenceOtherDescription = null;
    }
  }

  onMilitaryStatusChange = (): void => {
    this.borrower.isActiveDutyMilitary = false;
    this.borrower.onlyNonActivatedReserveOrNationalGuard = false;
    this.borrower.isRetiredDischargedSeparatedFromMilitary = false;

    if (this.militaryStatus == this.isActiveDutyMilitary) {
      this.borrower.isActiveDutyMilitary = true;
    }
    if (this.militaryStatus == this.onlyNonActivatedReserveOrNationalGuard) {
      this.borrower.onlyNonActivatedReserveOrNationalGuard = true;
    }
    if (this.militaryStatus == this.isRetiredDischargedSeparatedFromMilitary) {
      this.borrower.isRetiredDischargedSeparatedFromMilitary = true;
    }
    if (this.militaryStatus != this.isActiveDutyMilitary) {
      this.borrower.militaryTourStartDate = null;
      this.borrower.militaryTourEndDate = null;
    }
  }

  protected onIsSameAsSubjectPropertyAddressChanged(): void {
    if (this.isSameAsSubjectProperty) {
      this.assignAddress(this.presentAddress.address, this._mortgage.subjectProperty);
      this.presentAddress = { ...this.presentAddress };
      this.presentAddress[0] = this.presentAddress;
    }
  }

  private assignPresentAddressToMailingAddress(): void {
    this.assignResidencyAddress(this.mailingAddress, this.presentAddress);
  }

  private assignResidencyAddress(target: ResidencyAddress, source: ResidencyAddress): void {
    this.ensureHasAddress(target);
    this.ensureHasAddress(source); // FIXME: We might not want to modify the source address

    this.assignAddress(target.address!, source.address!);
  }

  private assignAddress(target: Address, source: Address): void {
    target.address1 = source.address1;
    target.city = source.city;
    target.state = source.state?.toLocaleLowerCase();
    target.zipCode = source.zipCode;
  }

  protected toggleMailingAddress(isMailingAddressDifferent: boolean): void {
    this.isMailingAddressDiffFromCurrentAddress = isMailingAddressDifferent;

    if (isMailingAddressDifferent) {
      this.resetMailingAddress();
      this.appendMailingAddress();
    } else {
      this.removeMailingAddress();
    }
  }

  private resetMailingAddress(): void {
    const address = this.mailingAddress.address;

    address.address1 = null;
    address.city = null;
    address.state = null;
    address.zipCode = null;
  }

  private appendMailingAddress(): void {
    const residencyAddresses = this._borrower.residencyAddresses;

    this.ensureHasAddress(this.mailingAddress);

    this.removeMailingAddress(); // Make sure we only have one mailing address.
    residencyAddresses.push(this.mailingAddress);
  }

  private removeMailingAddress(): void {
    const addresses = this._borrower.residencyAddresses;

    const index = addresses.findIndex(a => a.residencyType === ResidencyType.MailingAddress);
    if (index >= 0) {
      addresses.splice(index, 1);
    }
  }

  protected onAttemptedToDeleteAddress(address: ResidencyAddress): void {
    const deleteAddress = () => this.deleteAddress(address);

    // If the address is empty, we can delete it right away.
    if (isResidencyAddressEmpty(address)) {
      deleteAddress();
      return;
    }

    // "Cancel" is used as "confirm" here because the colors are more suitable.

    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this address?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Cancel',
      cancelButtonText: 'Delete',
      focusCancel: true, // "Confirm", actually.
    }).then((answer) => {
      // "Cancel" actually means "confirm" here.
      if (answer?.dismiss !== Swal.DismissReason.cancel) {
        return;
      }

      deleteAddress();
    });
  }

  private deleteAddress(address: ResidencyAddress): void {
    const index = this.borrower.residencyAddresses.indexOf(address);
    if (index < 0) {
      this._notificationService.showError('Unable to delete address.', 'Error');
      console.error('Cannot find index of address to delete.');
      return;
    }

    const borrower = this.borrower;
    borrower.residencyAddresses.splice(index, 1);
    this.setFormerAddresses(borrower.residencyAddresses);
    this.calculateNumberOfYearsAndMonths();
  }

  protected onAttemptedToDeleteEvent(event: BorrowerCounselingEvent): void {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you\'d like to delete this event?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then(function (result: any) {
      if (result.value) {
        const index = self._borrower.counselingEvents.indexOf(event);
        if (index >= 0) {
          self._borrower.counselingEvents.splice(index, 1);
        }
      }
    });
  }

  initDependents = () => {
    if (this.borrower.dependents) {
      this.borrower.dependentCount = this.borrower.dependents.length;
      this.dependentAges = this.borrower.dependents.join(', ');
    }
  }

  onMobilePhoneCopy = () => {
    this.borrower.homePhone = this.borrower.mobilePhone;
  }

  validate = () => {
    if (this.qaBorrowerDetailsInfoForm) {
      this.qaBorrowerDetailsInfoForm.form.markAllAsTouched();
    }
    return false;
  }

  private subscribeToBasicInfoFormForValueChanges = () => {
    this._borrowerBasicInfoFormValueChangesSubscription?.unsubscribe();
    if (this.qaBorrowerDetailsInfoForm) {
      this._borrowerBasicInfoFormValueChangesSubscription = this.qaBorrowerDetailsInfoForm.valueChanges.subscribe(() => {
        setTimeout(() => {
          this._menuService.setStatus('qaBorrowerInfo', this._menuService.getStatusForBorrowerInfo(this._mortgage));
          this.basicInfoStatus = this._menuService.getStatusForBorrowerBasicDetails(this._borrower, this.unmarriedAddendum);
          this.publishBorrowerValidationStatusChangedEvent();
        })
      })
    }
  }

  private subscribeToDeclarationsFormForValueChanges = () => {
    this._borrowerDeclarationsFormValueChangesSubscription?.unsubscribe();
    if (this.qaBorrowerDeclarationsForm) {
      this._borrowerDeclarationsFormValueChangesSubscription = this.qaBorrowerDeclarationsForm.valueChanges.subscribe(() => {
        setTimeout(() => {
          this._menuService.setStatus('qaBorrowerInfo', this._menuService.getStatusForBorrowerInfo(this._mortgage));
          this.declarationsStatus = this._menuService.getStatusForBorrowerDeclarations(this._borrower);
          this.publishBorrowerValidationStatusChangedEvent();
        })
      })
    }
  }

  private subscribeToDemographicsFormForValueChanges = () => {
    this._borrowerDemographicsFormValueChangesSubscription?.unsubscribe();
    if (this.qaBorrowerDemographicsForm) {
      this._borrowerDemographicsFormValueChangesSubscription = this.qaBorrowerDemographicsForm.valueChanges.subscribe(() => {
        setTimeout(() => {
          this._menuService.setStatus('qaBorrowerInfo', this._menuService.getStatusForBorrowerInfo(this._mortgage));
          this.demographicsStatus = this._menuService.getStatusForBorrowerDemographics(this._borrower);
          this.publishBorrowerValidationStatusChangedEvent();
        })
      })
    }
  }

  private publishBorrowerValidationStatusChangedEvent = () => {
    if (this.basicInfoStatus === MenuItemStatus.Error || this.declarationsStatus === MenuItemStatus.Error ||
      this.demographicsStatus === MenuItemStatus.Error) {
      this.borrowerValidationStatusChanged.emit({ borrowerId: this.borrower.borrowerId, validityStatus: MenuItemStatus.Error });
    } else if (this.basicInfoStatus === MenuItemStatus.Pending || this.declarationsStatus === MenuItemStatus.Pending ||
      this.demographicsStatus === MenuItemStatus.Pending) {
      this.borrowerValidationStatusChanged.emit({ borrowerId: this.borrower.borrowerId, validityStatus: MenuItemStatus.Pending });
    } else {
      this.borrowerValidationStatusChanged.emit({ borrowerId: this.borrower.borrowerId, validityStatus: MenuItemStatus.Success });
    }
  }

  /**
   * Ensures that the residencyAddress has a valid address. If the address is missing,
   * a new Address object will be created and added to the residencyAddress.
   *
   * @param {ResidencyAddress} residencyAddress - The residency address object.
   * @return {void}
   * @private
   */
  private ensureHasAddress(residencyAddress: ResidencyAddress): void {
    if (!residencyAddress.address) {
      residencyAddress.address = new Address();
    }
  }

  private setMailingAddress(addresses: ResidencyAddress[]) {
    this.mailingAddress = addresses.find(a => a.residencyType === ResidencyType.MailingAddress);
  }

  private setFormerAddresses(addresses: readonly ResidencyAddress[]) {
    this.formerAddresses = addresses.filter(a => a.residencyType === ResidencyType.FormerAddress);
  }

  protected onChangeFormerAddress(value: ResidencyAddress, oldValue: ResidencyAddress): void {
    const index = this.formerAddresses.indexOf(oldValue);
    if (index < 0) {
      this._notificationService.showError('Unable to update address.', 'Error');
      console.error('Cannot find index of former address to update.');
      return;
    }

    Object.assign(this.formerAddresses[index], value);
    this.onChangeAddressDuration();
  }

  protected onClickCopyEmail(infoState: { displayInfo: boolean, subscription?: Subscription }) {
    copyToClipboard(this.borrower.primaryEmail);

    this._notificationService.showInfo('Email copied to clipboard', 'Info');

    // Display the info text for 3 seconds.
    // Keep the subscription so that we can unsubscribe if the user clicks the
    // copy button multiple times quickly.
    infoState.displayInfo = true;
    infoState.subscription?.unsubscribe();

    infoState.subscription = timer(3000).pipe(
      takeUntil(this._destroyed$),
    ).subscribe(() => {
      infoState.displayInfo = false;
      delete infoState.subscription;
    });
  }

  protected scrollToTab = (tab: any) => {
    var sectionElement = this.getElementForTab(tab);
    if (sectionElement) {
      const y = sectionElement.getBoundingClientRect().top + window.scrollY - 200;
      window.scroll({
        top: y,
        behavior: 'smooth'
      });
    }
  }

  protected onBorrowerCounselingEventChanged = (counselingType: string): void => {
    if (this._borrower.counselingEvents && this._borrower.counselingEvents.length > 0) {
      for (let i = 0; i < this._borrower.counselingEvents.length; ++i) {
        if (this._borrower.counselingEvents[i].counselingType === counselingType) {
          this._borrower.counselingEvents.splice(i--, 1);
        }
      }
    }
  }

  protected scrollToSection = (tab: any) => {
    var sectionElement = this.getElementForBreadcrumb(tab);
    if (sectionElement) {
      const y = sectionElement.getBoundingClientRect().top + window.scrollY - 200;
      window.scroll({
        top: y,
        behavior: 'smooth'
      });
    }
  }

  protected onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, isMailingAddress?: boolean) => {
    if (zipCode) {
      if (!isMailingAddress) {
        this.presentAddress.address.state = zipCode.state.toLowerCase();
        this.presentAddress.address.city = Utils.toTitleCase(zipCode.city);
        this.presentAddress.address.zipCode = zipCode.zipcode;
        this.presentAddress.address.country = 'us';
      } else {
        this.mailingAddress.address.state = zipCode.state.toLowerCase();
        this.mailingAddress.address.city = Utils.toTitleCase(zipCode.city);
        this.mailingAddress.address.country = 'us';
      }
    }
  }

  protected handleAddressChange(e: Partial<Address>, isMailingAddress?: boolean): void {
    if (!isMailingAddress) {
      patchAddress(this.presentAddress.address, () => {
        this._copyOfBorrower = _.cloneDeep(this.borrower);
      });
    } else {
      patchAddress(this.mailingAddress.address);
    }
    return;

    function patchAddress(target: Partial<Address>, onAssign?: () => void): void {
      target.address1 = ''; // to reset the last populated address.

      setTimeout(() => {
        Object.assign(target, {
          address1: e.address1,
          city: e.city,
          state: e.state,
          zipCode: e.zipCode,
        });
        onAssign?.();
      }, 200);
    }
  }

  protected onChangePresentAddress(): void {
    this.checkValidityStatusesForSections();

    this.invalidateMailingAddressByPresentAddress();
  }

  protected checkValidityStatusesForSections(): void {
    this.basicInfoStatus = this._menuService.getStatusForBorrowerBasicDetails(this._borrower);
    this.declarationsStatus = this._menuService.getStatusForBorrowerDeclarations(this._borrower);
    this.demographicsStatus = this._menuService.getStatusForBorrowerDemographics(this._borrower);
    this.publishBorrowerValidationStatusChangedEvent();
  }

  private invalidateMailingAddressByPresentAddress() {
    if (!this.isMailingAddressDiffFromCurrentAddress) {
      this.assignPresentAddressToMailingAddress();
    }
  }

  private startObservingBorrowerInfoVisibility = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    };
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        const isVisible = entry.intersectionRatio > 0.2;
        this.showStickyBar = !isVisible;
      });
    }, options);
    const element = document.querySelector(`#borrower-tabs`)
    observer.observe(element);
  }

  private startObservingSectionVisibility = (sectionId: string) => {
    const options = {
      root: null,
      rootMargin: '-120px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    };
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (this.subTab == 'basic_details' + this.borrower.borrowerId) {
          this.activeTabName = 'Basic Details';
        }
        if (this.subTab == 'declarations' + this.borrower.borrowerId) {
          this.activeTabName = 'Declarations';
        }
        if (this.subTab == 'demographics' + this.borrower.borrowerId) {
          this.activeTabName = 'Demographics';
        }

        const sectionId = entry.target.id.split('_')[0];
        this.handleSectionVisibilityEvent(sectionId, entry.intersectionRatio > 0.2);
      });
    }, options);
    const element = document.querySelector(`#${sectionId}_${this.borrower.borrowerId}`)
    observer.observe(element);
  }

  private handleSectionVisibilityEvent = (sectionId: string, isVisible: boolean) => {

    const existingVisible = this._visibleSections.find(s => s.id === sectionId);

    const section = this._sections.find(s => s.id === sectionId);
    if (isVisible) {
      if (!existingVisible) {
        this._visibleSections.push(section);
      }
    } else {
      if (existingVisible) {
        const index = this._visibleSections.indexOf(existingVisible);
        if (index >= 0) {
          this._visibleSections.splice(index, 1);
        }
      }
    }
    this._visibleSections = _.orderBy(this._visibleSections, ['order'], ['asc'])
    this.visibleSection = this._visibleSections[0];
  }

  private getElementForBreadcrumb = (section: string): HTMLElement => {
    if (section === 'Personal Info') {
      return this.borrowerPersonalInfo.nativeElement;
    }
    if (section === 'Address') {
      return this.borrowerAddress.nativeElement;
    }
    if (section === 'Military Info') {
      return this.borrowerMilitaryInfo.nativeElement;
    }
    if (section === 'Homeownership Education and Housing Counseling') {
      return this.borrowerEduAndCounseling.nativeElement;
    }
    if (section === 'Language Preferences') {
      return this.borrowerLanguagePref.nativeElement;
    }
    return null;
  }

  private getElementForTab = (section: string): HTMLElement => {
    if (section.includes('basic_details')) {
      return this.borrowerDetails.nativeElement;
    }
    if (section.includes('declarations')) {
      return this.borrowerDeclarationsInfo.nativeElement;
    }
    if (section.includes('demographics')) {
      return this.borrowerDemographicsInfo.nativeElement;
    }
    return null;
  }

  private addAddress = (type: ResidencyType) => {
    let residencyAddress = new ResidencyAddress();
    residencyAddress.address = new Address();
    residencyAddress.residencyAddressId = this._utilsService.getUniqueId();
    residencyAddress.residencyType = type;
    residencyAddress.address.country = "us";
    this._borrower.residencyAddresses.push(residencyAddress);
  }

  protected calculateNumberOfYearsAndMonths() {
    const residencyAddresses = this._borrower.residencyAddresses
      .filter(a => a.residencyType !== ResidencyType.MailingAddress);

    const toSafeNumber = (value: string | number | undefined) =>
      Number(value) || 0;

    const totalDurationInMonths = residencyAddresses.reduce((total, address) =>
      total
      + toSafeNumber(address.durationYears) * 12
      + toSafeNumber(address.durationMonths),
      0);

    [
      this.numberOfYears,
      this.numberOfMonths,
    ] = monthsToYearsAndMonths(totalDurationInMonths);

    this.hasMoreThan2YearsOfHistory = this.numberOfYears >= 2;
    this.monthsOfHistoryForValidation = this.hasMoreThan2YearsOfHistory
      ? totalDurationInMonths
      : undefined;
  }
}

export class Section {
  header: string;
  id: string;
  order: number
}

export class ValidityStatus {
  borrowerId: number;
  validityStatus: MenuItemStatus;
}

// FIXME: Duplicate of the one in `borrowers-info.component.ts`.
function createAddressComparer(...keys: Array<keyof Address>) {
  return (
    a: Partial<Address> | undefined,
    b: Partial<Address> | undefined,
  ) => keys.every((k) => a?.[k]?.toString().toLowerCase() === b?.[k]?.toString().toLowerCase());
}

function isResidencyAddressEmpty(address: ResidencyAddress): boolean {
  return !address.address.address1
    && !address.residencyType
    && !address.address.city
    && !address.address.state
    && !address.address.zipCode
    && !address.durationYears
    && !address.durationMonths
    && !address.residencyBasis
    && !address.rent;
}
