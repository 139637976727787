import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mortgage-export-dialog',
  templateUrl: 'mortgage-export-dialog.component.html'
})

export class MortgageExportDialogComponent implements OnInit {

  message: string = "You have changes that are not saved. If you choose Discard below, " +
    "your changes will be lost.";

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

}
