
<form #reoForm="ngForm">
  <div class="grid-layout has-border" *ngIf="mortgage.mortgageId != null">
      <div class="grid-header">
          <div class="grid-header-left">
              <div>3a. Property You Own</div>
          </div>
          <div class="grid-header-right">
              <a class="btn btn-sm btn-soft-primary me-2" (click)="addReo()" role="button" *ngIf="!isReadOnly && !inEditMode">
                  <i class="fas fa-plus me-2"></i>Add REO
              </a>
          </div>
      </div>
      <reo-item #reoComponent *ngIf="currentReo" [reo]="currentReo" [states]="states" [isReadOnly]="isReadOnly"
          [currentPropertyWillBeType]="currentPropertyWillBeType" [propertyStatusOptions]="propertyStatusOptions"
          [intendedPropertyWillBeType]="intendedPropertyWillBeType" [reoPropertyTypes]="reoPropertyTypes"
          [liabilityTypeHELOC]="liabilityTypeHELOC" [liabilityTypeMortgageLoan]="liabilityTypeMortgageLoan"
          [borrowers]="mortgage.borrowers" [allLiabilities]="mortgage.liabilities"
          [yesNoOptions]="yesNoOptions" [mortgage]="mortgage"
          [inEditMode]="inEditMode" [urlaFieldsConfig]="urlaFieldsConfig">
      </reo-item>
  </div>
  <div class="grid-layout has-border" *ngIf="additionalReoItems.length >= 1">
      <div class="grid-header">
          <div class="grid-header-left">
              <div>3b. Additional Property You Own</div>
          </div>
          <div class="grid-header-right">
          </div>
      </div>
      <reo-item #reoComponent *ngFor="let additionalReo of additionalReoItems" [reo]="additionalReo" [states]="states" [isReadOnly]="isReadOnly"
          [currentPropertyWillBeType]="currentPropertyWillBeType" [propertyStatusOptions]="propertyStatusOptions"
          [intendedPropertyWillBeType]="intendedPropertyWillBeType" [reoPropertyTypes]="reoPropertyTypes"
          [liabilityTypeHELOC]="liabilityTypeHELOC" [liabilityTypeMortgageLoan]="liabilityTypeMortgageLoan"
          [borrowers]="mortgage.borrowers" [allLiabilities]="mortgage.liabilities"
          [yesNoOptions]="yesNoOptions" [mortgage]="mortgage" [urlaFieldsConfig]="urlaFieldsConfig"></reo-item>
  </div>
</form>
<loading-indicator *ngIf="mortgage.mortgageId == null" [loadingMessage]="'Loading REO information'"></loading-indicator>
