import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { Address } from '../../../../../../models';
import { QaAddressEditorComponent } from '../qa-address-editor/qa-address-editor.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { merge, Subscription } from 'rxjs';

const maxWidth = '35rem';

@Component({
  selector: 'qa-address-editor-modal',
  templateUrl: './qa-address-editor-modal.component.html',
  styleUrls: ['./qa-address-editor-modal.component.scss'],
})
export class QaAddressEditorModalComponent implements OnDestroy {
  @Output() closed = new EventEmitter<Address | undefined>();

  @ViewChild('addressEditor') addressEditor: QaAddressEditorComponent;

  protected address: Address;

  private readonly _modalOptions: NgbModalOptions = {
    backdrop: 'static',
    centered: true,
  };

  private _openModalRef: NgbModalRef | null = null;

  private get _isModalOpen(): boolean {
    return this._openModalRef != null;
  }

  private _openModalRefSubscription: Subscription | null = null;

  constructor(
    private readonly _modalService: NgbModal,
  ) {
  }

  ngOnDestroy() {
    this.closed.complete();

    this._openModalRefSubscription?.unsubscribe();
  }

  open(address?: Address): void {
    if (this._isModalOpen) {
      throw new Error('The modal is already open.');
    }

    this.address = address ?? new Address();

    const modalRef = this._openModalRef = this._modalService.open(
      this.addressEditor,
      this._modalOptions,
    );
    this.initModalView();

    this._openModalRefSubscription?.unsubscribe();
    this._openModalRefSubscription = merge(
      modalRef.closed,
      modalRef.dismissed,
    ).subscribe({
        next: (address?: Address) => {
          this._openModalRef = null;
          this.closed.next(address);
        },
      },
    );
  }

  close(): void {
    if (!this._isModalOpen) {
      throw new Error('The modal is not open.');
    }

    this._openModalRef?.dismiss();
  }

  protected save(): void {
    if (!this._isModalOpen) {
      throw new Error('The modal is not open.');
    }

    this._openModalRef.close(this.address);
  }

  private initModalView(): void {
    const modalWindow = document.querySelector('ngb-modal-window');

    // This is a hack to set the max-width of the modal.
    const setModalSize = () => {
      const modalDialog =
        modalWindow?.querySelector('.modal-dialog') as HTMLElement | null;
      if (modalDialog == null) {
        throw new Error('Could not find modal dialog element.');
      }

      modalDialog.style.maxWidth = maxWidth;
    }
    setModalSize();

    // This is a hack to hide the overflow of the modal.
    const hideModalOverflow = () => {
      const modalContent =
        modalWindow?.querySelector('.modal-content') as HTMLElement | null;
      if (modalContent == null) {
        throw new Error('Could not find modal content element.');
      }

      modalContent.style.overflow = 'hidden';
    }
    hideModalOverflow();
  }
}
