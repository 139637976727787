import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Utils } from 'src/app/core/services/utils';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { EnumerationService } from 'src/app/services/enumeration-service';

@Component({
  selector: 'nearest-living-relative-editor',
  templateUrl: './nearest-living-relative-editor.component.html',
  styleUrls: ['./nearest-living-relative-editor.component.scss']
})
export class NearestLivingRelativeEditorComponent implements OnChanges {
  /**
   * Can be omitted if a new {@link NearestLivingRelativeFormValue} is being
   * created.
   */
  @Input() value?: NearestLivingRelativeFormValue;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<NearestLivingRelativeFormValue> =
    new EventEmitter<NearestLivingRelativeFormValue>();

  protected formValue: NearestLivingRelativeFormValue =
    new NearestLivingRelativeFormValue();

  protected states: EnumerationItem[] = [];

  constructor(private readonly _enumsService: EnumerationService) {
    this.states = this._enumsService.states;
  }

  ngOnChanges(changes: SimpleChanges) {
    const valueChange = changes.value;
    if (valueChange != null) {
      const currentValue = valueChange.currentValue;
      this.formValue = new NearestLivingRelativeFormValue(currentValue);
    }
  }

  protected onSave(): void {
    this.save.next(this.formValue);
  }

  protected onCancel(): void {
    this.cancel.next();
  }

  protected onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.formValue.nearestLivingRelativeState = zipCode.state.toLowerCase();
      this.formValue.nearestLivingRelativeCity = Utils.toTitleCase(zipCode.city);
      this.formValue.nearestLivingRelativeZipCode = zipCode.zipcode;
      this.formValue.nearestLivingRelativeCountry = 'us';
    }
  } 
}

export class NearestLivingRelativeFormValue {
  nearestLivingRelativeName: string = '';
  nearestLivingRelativeEmail: string = '';
  nearestLivingRelativePhone: string = '';
  nearestLivingRelativeAddress1: string = '';
  nearestLivingRelativeCity: string = '';
  nearestLivingRelativeState: string = '';
  nearestLivingRelativeZipCode: string = '';
  nearestLivingRelativeCountry: string = '';
  nearestLivingRelativeRelationshipToBorrower: string = '';

  constructor(value?: Partial<NearestLivingRelativeFormValue>) {
    if (value) {
      Object.assign(this, value);
    }
  }
}
