<!-- <div class="row" *ngIf="userPermissions.companyId == 2">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-usd me-2"></i> LenderPrice Mock IFrame
        </h4>
      </div>
      <div class="card-body" style="height: 600px">
        <ng-container *ngIf="testLenderpriceUrl">
          <iframe sandbox="allow-same-origin allow-forms allow-scripts" [src]="testLenderpriceUrl" width="100%"
            height="100%" #iFrame>
          </iframe>
        </ng-container>
      </div>
    </div>
  </div>
</div> -->

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-usd me-2"></i> Pricing Details
        </h4>
        <div class="float-end button-items">
          <button class="btn btn-primary btn-sm" *ngIf="tab === 'pricingScenarios' && pricingScenarios.length"
            (click)="onLoanComparisonClicked()">Loan Comparison</button>
        </div>
      </div>
      <div class="card-body">
        <pricing-details [currentMortgage]="mortgage" [enableChannelSelection]="!isTpo"
          (setAmortizationTabVisibility)="setAmortizationTabVisibility($event)"
          (amortizationTypeAssigned)="setAmortizationType($event)" (scenariosChanged)="onScenariosChanged($event)">
        </pricing-details>
      </div>
    </div>
  </div>
</div>
