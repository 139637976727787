import { FormControl } from '@angular/forms';

/**
 * This class adapts the ngModel to the Reactive Forms API. It is used for the
 * custom form controls that do not support the Reactive Forms API.
 */
export class FormModelAdapter<TValue> {
  private readonly _control: FormControl<TValue>;

  constructor(control: FormControl<TValue>) {
    this._control = control;
  }

  get value(): TValue {
    return this._control.value;
  }

  set value(value: TValue) {
    this._control.setValue(value);
  }
}
