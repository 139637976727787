<div
  class="form-input z-auto"
  *ngIf="id && !inEditMode"
  [ngClass]="{
    'ng-invalid-requested':
      model &&
      model.invalid &&
      model.errors &&
      model.errors.required &&
      fieldBeingEdited &&
      urlaFieldsConfig[fieldBeingEdited]?.required == 'requested',
    'ng-invalid-required':
      model &&
      model.invalid &&
      model.errors &&
      model.errors.required &&
      fieldBeingEdited &&
      urlaFieldsConfig[fieldBeingEdited]?.required == 'required',
    'ng-invalid-date':
      model && model.invalid && model.errors && !model.errors?.required,
    'form-input': showBorder || (!showBorder && label),
    'no-border': !showBorder && !label,
    'bg-transparent': readonly
  }"
  [hidden]="fieldBeingEdited && urlaFieldsConfig[fieldBeingEdited]?.hidden"
>
  <span class="input-label" title="" *ngIf="label">
    <span class="input-label-overflow">{{ label }}</span>
  </span>
  <date-time-input
    [(ngModel)]='value'
    [showTime]='true'
    name="{{ name }}DateTime"
    id="{{ name }}-date-time"
    [readonly]="readonly"
  ></date-time-input>
</div>

<edit-mode-input *ngIf="inEditMode" 
    [label]="label" 
    [name]="name" 
    [urlaFieldsConfig]="urlaFieldsConfig" 
    [fieldBeingEdited]="fieldBeingEdited" 
    [showBorder]="showBorder">
</edit-mode-input>