<div class="card">
  <ng-template #spinner>
    <loading-indicator [loadingMessage]="'Loading external contact details...'"></loading-indicator>
  </ng-template>
  <div class="card-header d-flex justify-content-between">
    <h4 class="card-title"> Basic Information </h4>
    <a *ngIf="copyOfExternalContact.agent" (click)="onCopyFromAgentClicked()"> Copy from Agent
    </a>
  </div>
  <div class="card-body" *ngIf="copyOfExternalContact else spinner">
    <div class="row">
      <div class="col-md-4 form-group">
        <label for="firstName"> First Name </label>
        <input class="form-control" id="firstName" name="firstName" [(ngModel)]="copyOfExternalContact.firstName" />
      </div>
      <div class="col-md-4 form-group">
        <label for="lastName"> Last Name </label>
        <input class="form-control" id="lastName" name="lastName" [(ngModel)]="copyOfExternalContact.lastName" />
      </div>
      <div class="col-md-4 form-group">
        <div class="row">
          <div class="col-9 pe-0">
            <label for="workPhone"> Work Phone </label>
            <input class="form-control custom-input-left-side" id="workPhone" name="workPhone" type="text"
              [(ngModel)]="copyOfExternalContact.workPhone" mask="(000) 000-0000" [showMaskTyped]="true" />
          </div>
          <div class="col-3 ps-0">
            <label for="ext"> Ext </label>
            <input id="ext" numeric [allowNegative]="false" name="ext" class="form-control custom-input-right-side"
              [(ngModel)]="copyOfExternalContact.extPhone" />
          </div>
        </div>
      </div>
      <div class="col-md-4 form-group">
        <label for="mobilePhone"> Mobile Phone </label>
        <input class="form-control" id="mobilePhone" name="mobilePhone" type="text"
          [(ngModel)]="copyOfExternalContact.mobilePhone" mask="(000) 000-0000" [showMaskTyped]="true" />
      </div>
      <div class="col-md-4 form-group">
        <label for="fax"> Fax </label>
        <input class="form-control" id="fax" name="fax" type="text" [(ngModel)]="copyOfExternalContact.fax"
          mask="(000) 000-0000" [showMaskTyped]="true" />
      </div>
      <div class="col-md-4 form-group">
        <label for="suffix"> Suffix </label>
        <select id="suffix" name="suffix" class="form-select" [(ngModel)]="copyOfExternalContact.suffix">
          <option [ngValue]="null">-- Select One --</option>
          <option *ngFor="let opt of suffixes" [ngValue]="opt.value">
            {{ opt.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4 form-group">
        <label for="email"> Email </label>
        <input id="email" name="email" type="text" class="form-control" [(ngModel)]="copyOfExternalContact.email" email
          trim #emailInput="ngModel" [ngClass]="{'is-invalid': emailInput && emailInput.touched &&
        (!emailFormatValidate(copyOfExternalContact.email))}" />
        <div for="email" class="invalid-feedback" *ngIf="!emailFormatValidate(copyOfExternalContact.email)">
          Please enter a valid email address
        </div>
      </div>
      <div class="col-md-2 form-group">
        <label for="title"> Title </label>
        <input class="form-control" id="title" name="title" [(ngModel)]="copyOfExternalContact.title" />
      </div>
      <div class="col-md-2 form-group">
        <label for="maritalStatus"> Marital Status </label>
        <select id="maritalStatus" name="maritalStatus" class="form-select" placeholder="State"
          [(ngModel)]="copyOfExternalContact.maritalStatus">
          <option [ngValue]="null"></option>
          <option *ngFor="let status of maritalStatuses" [ngValue]="status.value">
            {{ status.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4 form-group">
        <label for="department"> Department </label>
        <input id="department" name="department" type="text" class="form-control"
          [(ngModel)]="copyOfExternalContact.department" />
      </div>

      <div class="col-md-4 form-group">
        <label for="nmlsId"> NMLS ID </label>
        <input class="form-control" id="nmlsId" name="nmlsId" [(ngModel)]="copyOfExternalContact.nmlsId" />
      </div>
      <div class="col-md-2 form-group">
        <label for="agentLicenseID"> State License ID </label>
        <input class="form-control" id="agentLicenseID" name="agentLicenseID"
          [(ngModel)]="copyOfExternalContact.stateLicenseId" />
      </div>
      <div class="col-md-2 form-group">
        <label for="stateLicenseState"> State License State </label>
        <select id="stateLicenseState" name="stateLicenseState" class="form-select" placeholder="State"
          [(ngModel)]="copyOfExternalContact.stateLicenseState"
          [disabled]="copyOfExternalContact.mailingCountry != null && copyOfExternalContact.mailingCountry !== 'United States of America'">
          <option [ngValue]="null"></option>
          <option *ngFor="let state of states" [ngValue]="state.value">
            {{ state.name }}
          </option>
        </select>
      </div>
      <div class="col-md-2 form-group">
        <label for="stateLicenseIssueDate"> License Issue Date </label>
        <date-input name="stateLicenseIssueDate" id="stateLicenseIssueDate" [containerBody]="true"
          [(ngModel)]="copyOfExternalContact.stateLicenseIssueDate">
        </date-input>
      </div>
      <div class="col-md-2 form-group">
        <label for="stateLicenseExpirationDate"> License Expiration Date </label>
        <date-input name="stateLicenseExpirationDate" id="stateLicenseExpirationDate" [containerBody]="true"
          [(ngModel)]="copyOfExternalContact.stateLicenseExpirationDate">
        </date-input>
      </div>

      <div class="col-md-4 form-group">
        <label for="mailingStreet"> Street Address </label>
        <address-autocomplete-input
          (addressChange)='handleAddressChangeForAgent($event)'
          [(ngModel)]='copyOfExternalContact.mailingStreet'
          id='mailingStreet'
          name='mailingStreet'
          placeholder=''
        ></address-autocomplete-input>
      </div>
      <div class="col-md-2 form-group">
        <label for="mailingCity"> City </label>
        <input id="mailingCity" name="mailingCity" type="text" class="form-control"
          [(ngModel)]="copyOfExternalContact.mailingCity" />
      </div>
      <div class="col-md-2 form-group">
        <label for="mailingPOBox"> PO Box </label>
        <input id="mailingPOBox" name="mailingPOBox" type="text" class="form-control"
          [(ngModel)]="copyOfExternalContact.mailingPOBox" />
      </div>
      <div class="col-md-4 form-group">
        <div class="row">
          <div class="col-md-4">
            <label for="mailingState"> State </label>
            <select id="mailingState" name="mailingState" class="form-select" placeholder="State"
              [(ngModel)]="copyOfExternalContact.mailingState"
              [disabled]="!!copyOfExternalContact.mailingCountry && copyOfExternalContact.mailingCountry !== 'us'">
              <option [ngValue]="null"></option>
              <option *ngFor="let state of states" [ngValue]="state.value">
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="col-md-8">
            <label for="mailingZip"> Zip Code </label>
            <zip-code-input [(ngModel)]="copyOfExternalContact.mailingZip" [name]="'mailingZip'"
              (selectionChanged)="onZipCodeRelatedInfoChangedForAgent($event)"
              [readonly]="!!copyOfExternalContact.mailingCountry && copyOfExternalContact.mailingCountry !== 'us'">
            </zip-code-input>
          </div>
        </div>
      </div>
      <div class="col-md-4 form-group">
        <label for="mailingCountry"> Country </label>
        <select id="mailingCountry" name="mailingCountry" class="form-select"
          [(ngModel)]="copyOfExternalContact.mailingCountry" (ngModelChange)="onCountryForAgentChanged()">
          <option [ngValue]="null">--Select One--</option>
          <option *ngFor="let country of countries" [ngValue]="country.code">
            {{ country.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4 form-group" [hidden]="copyOfExternalContact.agentType?.agentType !== 'Appraisal'">
        <div class="row">
          <div class="col-md-4">
            <label for="appraisalSubCommitteeAppraiserIdentifier"> Appraiser ID </label>
            <input class="form-control" id="appraisalSubCommitteeAppraiserIdentifier" name="appraisalSubCommitteeAppraiserIdentifier" [(ngModel)]="copyOfExternalContact.appraisalSubCommitteeAppraiserIdentifier" />
          </div>
        </div>
      </div>
      <div class="col-md-4 form-group" [hidden]="copyOfExternalContact.agentType?.agentType !== 'HUDFHAAgent'">
        <div class="row">
          <div class="col-md-4">
            <label for="fhaAgentIdentifier"> FHA Agent ID </label>
            <input class="form-control" id="fhaAgentIdentifier" name="fhaAgentIdentifier" [(ngModel)]="copyOfExternalContact.fhaAgentIdentifier" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 form-group">
        <label for="assistantName"> Assistant Name </label>
        <input class="form-control" id="assistantName" name="assistantName"
          [(ngModel)]="copyOfExternalContact.assistantName" />
      </div>
      <div class="col-md-4 form-group">
        <label for="assistantEmail"> Assistant Email </label>
        <input class="form-control" type="email" name="assistantEmail" #assistantEmail="ngModel" trim
          [ngClass]="{'is-invalid': assistantEmail && assistantEmail.touched &&
          (!emailFormatValidate(copyOfExternalContact.assistantEmail))}" email
          [(ngModel)]="copyOfExternalContact.assistantEmail" />
          <div for="assistantEmail" class="invalid-feedback" *ngIf="!emailFormatValidate(copyOfExternalContact.assistantEmail)">
            Please enter a valid email address
          </div>
      </div>
      <div class="col-md-4 form-group">
        <label for="assistantPhone"> Assistant Phone </label>
        <input class="form-control" [mask]="'(000) 000-0000'" [showMaskTyped]="true" name="assistantPhone"
          [(ngModel)]="copyOfExternalContact.assistantPhone" />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-4 form-group">
        <a (click)="showCompanyInfo = !showCompanyInfo" class="ms-2" style="color: #35aaef;"> Show Company Information
          <i class="ms-1 fs-smaller {{
          showCompanyInfo ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
        }}"></i>
        </a>
      </div>
    </div>
    <ng-container *ngIf="showCompanyInfo">
      <div class="row mt-4">
        <div class="col-md-4 form-group">
          <label for="companyName"> Company Name </label>
          <input id="companyName" name="companyName" type="text" class="form-control"
            [(ngModel)]="copyOfExternalContact.companyName" />
        </div>
        <div class="col-md-4 form-group">
          <div class="row">
            <div class="col-9 pe-0">
              <label for="companyPhone"> Company Phone </label>
              <input class="form-control custom-input-left-side" id="companyPhone" name="companyPhone" type="text"
                [(ngModel)]="copyOfExternalContact.companyPhone" mask="(000) 000-0000" [showMaskTyped]="true" />
            </div>
            <div class="col-3 ps-0">
              <label for="companyExtPhone"> Ext </label>
              <input id="companyExtPhone" numeric [allowNegative]="false" name="companyExtPhone"
                class="form-control custom-input-right-side" [(ngModel)]="copyOfExternalContact.companyExtPhone" />
            </div>
          </div>
        </div>
        <div class="col-md-4 form-group">
          <label for="companyFax"> Company Fax </label>
          <input class="form-control" id="companyFax" name="companyFax" type="text"
            [(ngModel)]="copyOfExternalContact.companyFax" mask="(000) 000-0000" [showMaskTyped]="true" />
        </div>
        <div class="col-md-4 form-group">
          <label for="companyEmail"> Company Email </label>
          <input id="companyEmail" name="companyEmail" type="text" class="form-control" #companyEmail="ngModel"
            [(ngModel)]="copyOfExternalContact.companyEmail" email trim [ngClass]="{'is-invalid': companyEmail && companyEmail.touched &&
            (!emailFormatValidate(copyOfExternalContact.companyEmail))}" />
          <div for="companyEmail" class="invalid-feedback"
            *ngIf="!emailFormatValidate(copyOfExternalContact.companyEmail)">
            Please enter a valid email address
          </div>
        </div>
        <div class="col-md-8 form-group">
          <div class="row">
            <div class="col-md-4">
              <label for="companyWebsite"> Company Website </label>
              <input id="companyWebsite" name="companyWebsite" type="text" class="form-control"
                [(ngModel)]="copyOfExternalContact.companyWebsite" />
            </div>
            <div class="col-md-4">
              <label for="lenderOwnershipShare"> Lender Ownership Share </label>
              <input class="form-control" numeric [allowNegative]="false" name="lenderOwnershipShare"
                [(ngModel)]="copyOfExternalContact.lenderOwnershipShare" />
            </div>
            <div class="col-md-4">
              <label for="natureOfRelationshipWithLender"> Nature of Relationship with Lender </label>
              <input id="natureOfRelationshipWithLender" name="natureOfRelationshipWithLender" type="text"
                class="form-control" [(ngModel)]="copyOfExternalContact.natureOfRelationshipWithLender" />
            </div>
          </div>
        </div>
        <div class="col-md-2 form-group">
          <label for="companyNMLSId"> Company NMLS ID </label>
          <input class="form-control" id="companyNMLSId" name="companyNMLSId"
            [(ngModel)]="copyOfExternalContact.companyNMLSId" />
        </div>
        <div class="col-md-2 form-group">
          <label for="companyEmployerIdentificationNumber" title="Company Employer ID Number"> Company Employer ID
          </label>
          <input class="form-control" id="companyEmployerIdentificationNumber"
            name="companyEmployerIdentificationNumber"
            [(ngModel)]="copyOfExternalContact.companyEmployerIdentificationNumber" />
        </div>
        <div class="col-md-2 form-group">
          <label for="companyStateLicenseId"> Company State License ID </label>
          <input class="form-control" id="companyStateLicenseId" name="companyStateLicenseId"
            [(ngModel)]="copyOfExternalContact.companyStateLicenseId" />
        </div>
        <div class="col-md-2 form-group">
          <label for="companyStateLicenseState"> State License State </label>
          <select id="companyStateLicenseState" name="companyStateLicenseState" class="form-select" placeholder="State"
            [(ngModel)]="copyOfExternalContact.companyStateLicenseState"
            [disabled]="copyOfExternalContact.companyCountry != null && copyOfExternalContact.companyCountry !== 'United States of America'">
            <option [ngValue]="null">--Select One--</option>
            <option *ngFor="let state of states" [ngValue]="state.value">
              {{ state.name }}
            </option>
          </select>
        </div>
        <div class="col-md-2 form-group">
          <label for="companyStateLicenseIssueDate"> State License Issue Date </label>
          <date-input name="companyStateLicenseIssueDate" id="companyStateLicenseIssueDate" [containerBody]="true"
            [(ngModel)]="copyOfExternalContact.companyStateLicenseIssueDate">
          </date-input>
        </div>
        <div class="col-md-2 form-group">
          <label for="companyStateLicenseExpirationDate" title="Company State License Expiration Date"> State
            License Exp. Date </label>
          <date-input name="companyStateLicenseExpirationDate" id="companyStateLicenseExpirationDate" [containerBody]="true"
            [(ngModel)]="copyOfExternalContact.companyStateLicenseExpirationDate">
          </date-input>
        </div>
        <div class="col-md-4 form-group">
          <label for="companyAddress1"> Street Address Line 1 </label>
          <address-autocomplete-input
            (addressChange)='handleAddressChangeForCompany($event)'
            [(ngModel)]='copyOfExternalContact.companyAddress1'
            id='companyAddress1'
            name='companyAddress1'
            placeholder=''
          ></address-autocomplete-input>
        </div>
        <div class="col-md-4 form-group">
          <label for="companyAddress2"> Street Address Line 2 </label>
          <input id="companyAddress2" name="companyAddress2" type="text" class="form-control" placeholder=""
            [(ngModel)]="copyOfExternalContact.companyAddress2" />
        </div>
        <div class="col-md-4 form-group">
          <label for="companyCity"> City </label>
          <input id="companyCity" name="companyCity" type="text" class="form-control"
            [(ngModel)]="copyOfExternalContact.companyCity" />
        </div>
        <div class="col-md-4 form-group">
          <div class="row">
            <div class="col-md-4">
              <label for="companyState"> State </label>
              <select id="companyState" name="companyState" class="form-select" placeholder="State"
                [(ngModel)]="copyOfExternalContact.companyState"
                [disabled]="copyOfExternalContact.companyCountry != null && copyOfExternalContact.companyCountry !== 'United States of America'">
                <option [ngValue]="null">--Select One--</option>
                <option *ngFor="let state of states" [ngValue]="state.value">
                  {{ state.name }}
                </option>
              </select>
            </div>
            <div class="col-md-8">
              <label for="companyZip"> Zip Code </label>
              <zip-code-input [(ngModel)]="copyOfExternalContact.companyZip" [name]="'companyZip'"
                (selectionChanged)="onZipCodeRelatedInfoChangedForCompany($event)"
                [readonly]="copyOfExternalContact.companyCountry != null && copyOfExternalContact.companyCountry !== 'United States of America'">
              </zip-code-input>
            </div>
          </div>
        </div>
        <div class="col-md-4 form-group">
          <label for="companyCountry"> Country </label>
          <select id="companyCountry" name="companyCountry" class="form-select"
            [(ngModel)]="copyOfExternalContact.companyCountry" (ngModelChange)="onCountryForCompanyChanged()">
            <option [ngValue]="null">--Select One--</option>
            <option *ngFor="let country of countries" [ngValue]="country.name">
              {{ country.name }}
            </option>
          </select>
        </div>
        <div class="col-md-8 form-group" [hidden]="copyOfExternalContact.agentType?.agentType !== 'IVESParticipant'">
          <div class="row">
            <div class="col-md-4">
              <label for="ivesParticipantIdentifier"> IVES Participant ID </label>
              <input class="form-control" id="ivesParticipantIdentifier" name="ivesParticipantIdentifier" [(ngModel)]="copyOfExternalContact.ivesParticipantIdentifier" />
            </div>
            <div class="col-md-4">
              <label for="ivesSecuredObjectRepositoryIdentifier"> IVES SOC ID </label>
              <input class="form-control" id="ivesSecuredObjectRepositoryIdentifier" name="ivesSecuredObjectRepositoryIdentifier" [(ngModel)]="copyOfExternalContact.ivesSecuredObjectRepositoryIdentifier" />
            </div>
          </div>
        </div>
        <div class="col-md-4 form-group" [hidden]="copyOfExternalContact.agentType?.agentType !== 'HUDFHAAgent'">
          <div class="row">
            <div class="col-md-4">
              <label for="fhaAgentCompanyIdentifier"> FHA Agent Company ID </label>
              <input class="form-control" id="fhaAgentCompanyIdentifier" name="fhaAgentCompanyIdentifier" [(ngModel)]="copyOfExternalContact.fhaAgentCompanyIdentifier" />
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end">
      <button class="btn btn-secondary me-2" (click)="onCancelClicked()">
        Cancel
      </button>
      <ng-container *ngIf="copyOfExternalContact.agentId else normal">
        <a class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" *ngIf="!isReadOnly" aria-haspopup="false"
          aria-expanded="false" href="#" role="button">
          <i class="fa fa-save"></i>
          Save <i class="fa fa-chevron-down ms-2"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <a class="dropdown-item" (click)="onSaveClicked(false)"> Save only to File </a>
          <a class="dropdown-item" (click)="onSaveClicked(true)"> Save and Update Agent Contact </a>
        </div>
      </ng-container>
      <ng-template #normal>
        <button class="btn btn-primary me-2" type="button" (click)="onSaveClicked(false)">
          <i class="fa fa-save"></i>
          Save
        </button>
      </ng-template>
    </div>
  </div>
</div>
